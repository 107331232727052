import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { AxiosErrorResponse, ResponseSingleResult, VoidFunctionType } from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { routesPath } from '../../../core/enums/pathEnum';
import { CreateRectificationReqType } from '../../../rectifications/create-rectification/types/createRectificationTypes';
import { rectificationsAPI } from '../../../api/rectificationsApi';
import { GetVehiclesResponse, VehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { vehiclesAPI } from '../../../api/vehiclesApi';

export const createRectificationThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, rectificationNumber: string | null }> | null,
{
  data: CreateRectificationReqType,
  navigate: NavigateFunction,
  reset: VoidFunctionType,
}
>(
  'post/Rectification',
  async ({
    data, navigate, reset,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.createRectification(data);
      const { rectificationNumber } = response.data.data;
      reset();
      rectificationNumber && dispatch(setSuccessMessage({ message: `Rectification "${rectificationNumber}" was successfully created.` }));
      dispatch(setLoading(false));
      navigate(`/${routesPath.RECTIFICATIONS}/${response.data.data.createdId}`);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCreateRectificationVehiclesListThunk = createAsyncThunk<GetVehiclesResponse, {
  filters: VehiclesDashboardFilters,
}>(
  'get/CreateRectificationVehiclesList',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehiclesList(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
