import React from 'react';
import './PartRequestsSkeletonItem.scss';

const PartRequestsSkeletonItem: React.FC = () => {
  return (
    <div className="partRequestsSkeletonItem skeleton-item">
      <div className="partRequestsSkeletonItem__title" />
      <div className="partRequestsSkeletonItem__info">
        <div className="partRequestsSkeletonItem__info-item" />
        <div className="partRequestsSkeletonItem__info-item" />
        <div className="partRequestsSkeletonItem__info-item" />
      </div>
      <div className="partRequestsSkeletonItem__icons" />
      <div className="partRequestsSkeletonItem__tag skeleton-line" />
      <div className="partRequestsSkeletonItem__count" />
    </div>
  );
};

export default PartRequestsSkeletonItem;
