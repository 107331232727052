import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVanShuttle } from '@fortawesome/free-solid-svg-icons';
import './EmptyVehiclesList.scss';

const EmptyVehiclesList:React.FC = () => {
  return (
    <li className="emptyVehiclesList">
      <FontAwesomeIcon icon={faVanShuttle} className="emptyVehiclesList__empty-icon" />
      <span className="emptyVehiclesList__empty-text">All vehicles</span>
    </li>
  );
};

export default EmptyVehiclesList;
