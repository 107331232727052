import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormSetError } from 'react-hook-form';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  GetPurchaseOrderResponse,
  PurchaseOrderDashFiltersType,
  PurchaseOrdersResponseResult,
} from '../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import {
  setDashboardLoading, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { stockApi } from '../../../api/stockApi';
import {
  AxiosErrorResponse, BooleanFunctionType, DownloadUriResponse, emptyPaging, initSmallPaging, ResponseResult,
  ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  GetPurchaseOrderPartsResponse,
  GetPurchaseOrderPartSummaryResponse,
  PurchaseOrderEventDtoBase,
  PurchaseOrderPartsListFiltersType,
  PurchaseOrderSummaryResponseType,
} from '../../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import { invoicingAPI } from '../../../api/invoicingApi';
import {
  GetNominalRecordsResponse,
  NominalRecordsFilters,
  NominalCodesSummaryFilters,
  PostNominalRecordRestModel,
  PutNominalRecordRestModel,
  GetNominalRecordsSummaryResponse,
} from '../../../invoicing/invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import { RootState } from '../../store';
import {
  setInvoicesAndCreditNotesFilters,
  setPurchasesViewOrderLinesFilters,
  setSalesInvoiceOrderLinesFilters,
} from '../../slices/invoicingSlice';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import {
  GetInvoiceDocumentsResponse,
  GetInvoiceDocumentsSummaryResponse,
  InvoicesAndCreditNotesFilters,
} from '../../../invoicing/invoicing-purchases/types/InvoicingPurchasesTypes';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { AddEditInvoiceFields } from '../../../invoicing/invoicing-purchases/types/AddInvoiceSchema';
import {
  getSalesOrderById,
  getSalesOrderPartRequestLinesThunk,
  getSalesOrderSummaryThunk,
  getSalesPartRequestLineSummary,
} from './salesThunks';
import { invoicesAndCreditNotesErrorHandle } from '../../../core/utils/invoicesAndCreditNotesErrorHandle';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { AddNominalRecordFields } from '../../../invoicing/invoicing-purchases/types/AddNominalRecordSchema';

export const getInvoicingPurchases = createAsyncThunk<PurchaseOrdersResponseResult,
{ filters: PurchaseOrderDashFiltersType, signal?: AbortSignal }
>(
  'get/InvoicingPurchases',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrders(filters, signal);
      dispatch(setDashboardLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoicingPurchaseOrderById = createAsyncThunk<GetPurchaseOrderResponse,
{ id: number }
>(
  'get/InvoicingPurchaseOrderById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrderById(id);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoicingPurchaseOrderLinesSummaryThunk = createAsyncThunk<ResponseSingleResult<PurchaseOrderSummaryResponseType>,
{ purchaseOrderId: number }>(
  'get/InvoicingPurchaseOrderLinesSummary',
  async ({ purchaseOrderId }, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderSummary(purchaseOrderId);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoicingPurchaseOrderLinesList = createAsyncThunk<ResponseSingleResult<GetPurchaseOrderPartsResponse>, {
  purchaseOrderId: number,
  filters?: PurchaseOrderPartsListFiltersType,
}>(
  'get/InvoicingPurchaseOrderLinesList',
  async ({ purchaseOrderId, filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPurchaseOrderPartsList(purchaseOrderId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeInvoicingPurchaseOrderStatusThunk = createAsyncThunk<ResponseSingleResult,
{ purchaseOrderId: number, statusTransition: number, onFinish: VoidFunctionType }>(
  'put/InvoicingPurchaseOrderStatus',
  async ({ purchaseOrderId, statusTransition, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.putPurchaseOrderById(purchaseOrderId, { statusTransition });
      onFinish();
      dispatch(setFetching(false));
      dispatch(getInvoicingPurchaseOrderById({ id: purchaseOrderId }));
      return response.data;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAssignedNominalRecords = createAsyncThunk<GetNominalRecordsResponse, {
  filters: NominalRecordsFilters,
}>(
  'get/NominalRecords',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const response = await invoicingAPI.fetchNominalRecords(filters);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalRecordPartSummary = createAsyncThunk<ResponseSingleResult<GetPurchaseOrderPartSummaryResponse>, {
  purchaseOrderId: number, purchaseOrderPartId: number,
}>(
  'get/NominalRecordPartSummary',
  async ({ purchaseOrderId, purchaseOrderPartId }, { rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.fetchPurchaseOrderPartSummary(purchaseOrderId, purchaseOrderPartId);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addNominalRecordThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  data: PostNominalRecordRestModel,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<AddNominalRecordFields>
}>(
  'post/NominalRecord',
  async ({ data, closeModal, setError }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const {
        invoicing: {
          assignedNominalCodesFilters,
          purchasesViewOrderLinesFilters,
          invoicingPurchaseOrder,
          salesOrderDetails,
          salesInvoiceOrderLinesFilters,
        },
      } = getState() as RootState;
      const response = await invoicingAPI.addNominalRecord(data);
      dispatch(getAssignedNominalRecords({ filters: { ...assignedNominalCodesFilters, ...initSmallPaging } }));

      // SALES FLOW
      if (data.partRequestLineId) {
        const salesPartRequestId = salesOrderDetails?.order.partRequestId;
        const orderId = salesOrderDetails?.order.id;
        if (salesInvoiceOrderLinesFilters) {
          dispatch(setSalesInvoiceOrderLinesFilters(undefined));
        } else {
          if (salesPartRequestId && orderId) {
            dispatch(getSalesOrderPartRequestLinesThunk({ partRequestId: salesPartRequestId }));
            dispatch(getSalesOrderSummaryThunk({ id: orderId }));
          }
        }
        salesPartRequestId && data.partRequestLineId && dispatch(getSalesPartRequestLineSummary({
          partRequestId: salesPartRequestId,
          lineId: data.partRequestLineId,
        }));
      } else {
        const purchaseOrderId = invoicingPurchaseOrder?.purchaseOrder?.id;
        // PURCHASES FLOW
        if (purchasesViewOrderLinesFilters) {
          dispatch(setPurchasesViewOrderLinesFilters(undefined));
        } else {
          if (purchaseOrderId) {
            dispatch(getInvoicingPurchaseOrderLinesList({ purchaseOrderId }));
            dispatch(getInvoicingPurchaseOrderLinesSummaryThunk({ purchaseOrderId }));
          }
        }
        data.purchaseOrderPartId && purchaseOrderId && dispatch(getNominalRecordPartSummary({
          purchaseOrderId,
          purchaseOrderPartId: data.purchaseOrderPartId,
        }));
      }
      dispatch(setSuccessMessage({ message: 'Nominal code record was successfully created.' }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const recordTypeError = error.response?.data.errors.find((el) => el.key === 'Type');
      if (recordTypeError) {
        setError('recordType', { type: 'Invalid value', message: recordTypeError.message });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editNominalRecordThunk = createAsyncThunk<ResponseSingleResult<null>, {
  id: number,
  data: PutNominalRecordRestModel,
  closeModal: VoidFunctionType,
  isSales?: boolean,
  affectedTableLineId?: number,
  setError: UseFormSetError<AddNominalRecordFields>,
}>(
  'put/NominalRecord',
  async ({
    id, data, closeModal, isSales, affectedTableLineId, setError,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const {
        invoicing: {
          assignedNominalCodesFilters,
          purchasesViewOrderLinesFilters,
          invoicingPurchaseOrder,
          salesOrderDetails,
          salesInvoiceOrderLinesFilters,
        },
      } = getState() as RootState;
      const response = await invoicingAPI.editNominalRecord(id, data);
      dispatch(getAssignedNominalRecords({ filters: { ...assignedNominalCodesFilters, ...initSmallPaging } }));
      if (isSales) {
        const orderId = salesOrderDetails?.order.id;
        const salesPartRequestId = salesOrderDetails?.order.partRequestId;
        if (salesInvoiceOrderLinesFilters) {
          dispatch(setSalesInvoiceOrderLinesFilters(undefined));
        } else {
          if (salesPartRequestId && orderId) {
            dispatch(getSalesOrderPartRequestLinesThunk({ partRequestId: salesPartRequestId }));
            dispatch(getSalesOrderSummaryThunk({ id: orderId }));
          }
        }
        salesPartRequestId && affectedTableLineId && dispatch(getSalesPartRequestLineSummary({
          partRequestId: salesPartRequestId,
          lineId: affectedTableLineId,
        }));
      } else {
        const purchaseOrderId = invoicingPurchaseOrder?.purchaseOrder.id;
        if (purchasesViewOrderLinesFilters) {
          dispatch(setPurchasesViewOrderLinesFilters(undefined));
        } else {
          if (purchaseOrderId) {
            dispatch(getInvoicingPurchaseOrderLinesList({ purchaseOrderId }));
            dispatch(getInvoicingPurchaseOrderLinesSummaryThunk({ purchaseOrderId }));
          }
        }
        purchaseOrderId && affectedTableLineId && dispatch(getNominalRecordPartSummary({
          purchaseOrderId,
          purchaseOrderPartId: affectedTableLineId,
        }));
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const recordTypeError = error.response?.data.errors.find((el) => el.key === 'Type');
      if (recordTypeError) {
        setError('recordType', { type: 'Invalid value', message: recordTypeError.message });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteNominalRecordThunk = createAsyncThunk<ResponseSingleResult<null>, {
  recordId: number,
  closeModal: VoidFunctionType,
  isSales?: boolean,
  affectedId?: number,
}>(
  'delete/NominalRecord',
  async ({
    recordId, closeModal, isSales, affectedId,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const {
        invoicing: {
          assignedNominalCodesFilters,
          purchasesViewOrderLinesFilters,
          invoicingPurchaseOrder,
          salesOrderDetails,
          salesInvoiceOrderLinesFilters,
        },
      } = getState() as RootState;
      const response = await invoicingAPI.deleteNominalRecord(recordId);
      dispatch(getAssignedNominalRecords({ filters: { ...assignedNominalCodesFilters, ...initSmallPaging } }));
      if (isSales) {
        const partRequestId = salesOrderDetails?.order.partRequestId;
        const orderId = salesOrderDetails?.order.id;
        if (salesInvoiceOrderLinesFilters) {
          dispatch(setSalesInvoiceOrderLinesFilters(undefined));
        } else {
          if (partRequestId && orderId) {
            dispatch(getSalesOrderPartRequestLinesThunk({ partRequestId }));
            dispatch(getSalesOrderSummaryThunk({ id: orderId }));
          }
        }
        partRequestId && affectedId && dispatch(getSalesPartRequestLineSummary({
          partRequestId,
          lineId: affectedId,
        }));
      } else {
        const purchaseOrderId = invoicingPurchaseOrder?.purchaseOrder.id;
        if (purchasesViewOrderLinesFilters) {
          dispatch(setPurchasesViewOrderLinesFilters(undefined));
        } else {
          if (purchaseOrderId) {
            dispatch(getInvoicingPurchaseOrderLinesList({ purchaseOrderId }));
            dispatch(getInvoicingPurchaseOrderLinesSummaryThunk({ purchaseOrderId }));
          }
        }
        purchaseOrderId && affectedId && dispatch(getNominalRecordPartSummary({
          purchaseOrderId,
          purchaseOrderPartId: affectedId,
        }));
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoicingPurchaseOrderTimeline = createAsyncThunk<ResponseResult<PurchaseOrderEventDtoBase[]>,
{ id: number, filters: ExtendedTimelineFilters, source: number, setIsLoading: BooleanFunctionType }
>(
  'get/InvoicingPurchaseOrderTimeline',
  async ({
    id, filters, source, setIsLoading,
  }, { rejectWithValue }) => {
    setIsLoading(true);
    try {
      const res = await stockApi.fetchPurchaseOrderTimeline(id, filters, source);
      setIsLoading(false);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setIsLoading(false);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentsSummaryThunk = createAsyncThunk<GetInvoiceDocumentsSummaryResponse, {
  invoiceDocumentIds: number[],
}>(
  'get/InvoiceDocumentsSummary',
  async ({ invoiceDocumentIds }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await invoicingAPI.fetchInvoiceDocumentsSummary(invoiceDocumentIds);
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const exportInvoiceDocumentsCsvFileThunk = createAsyncThunk<DownloadUriResponse, {
  invoiceDocumentIds: number[], markDraftAsExported: boolean, onFinish: VoidFunctionType,
}>(
  'get/exportInvoiceDocumentsCsvFile',
  async ({ invoiceDocumentIds, markDraftAsExported, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await invoicingAPI.exportInvoiceDocumentsCsvFile(invoiceDocumentIds, markDraftAsExported);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      onFinish();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

const actionsAfterFinish = (
  id: number,
  isSales: boolean,
  getState: () => unknown,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  onFinish?: VoidFunctionType,
) => {
  onFinish && onFinish();
  const { invoicing: { invoicesAndCreditNotesFilters } } = getState() as RootState;
  dispatch(setInvoicesAndCreditNotesFilters({ ...invoicesAndCreditNotesFilters, page: 1 }));
  if (isSales) {
    dispatch(getSalesOrderById({ id }));
    dispatch(getSalesOrderSummaryThunk({ id }));
  } else {
    dispatch(getInvoicingPurchaseOrderById({ id }));
    dispatch(getInvoicingPurchaseOrderLinesSummaryThunk({ purchaseOrderId: id }));
  }
};

export const getInvoicesAndCreditNotesThunk = createAsyncThunk<GetInvoiceDocumentsResponse, {
  filters: InvoicesAndCreditNotesFilters,
}>(
  'get/InvoicesAndCreditNotes',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await invoicingAPI.fetchInvoiceDocuments(filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteInvoicesAndCreditNotesThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, onFinish: VoidFunctionType, entityId: number, isSales: boolean,
}>(
  'delete/InvoiceDocument',
  async ({
    id, onFinish, entityId, isSales,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await invoicingAPI.deleteInvoiceDocument(id);
      dispatch(setFetching(false));
      actionsAfterFinish(entityId, isSales, getState, dispatch, onFinish);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editInvoicesAndCreditNotesThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  data: AddEditInvoiceFields,
  setError: UseFormSetError<AddEditInvoiceFields>,
  onFinish: VoidFunctionType,
  entityId: number,
  isSales: boolean,
  maxDate: string,
}>(
  'patch/InvoiceDocument',
  async ({
    id, data, setError, onFinish, entityId, isSales, maxDate,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await invoicingAPI.patchInvoiceDocument(id, data);
      dispatch(setFetching(false));
      actionsAfterFinish(entityId, isSales, getState, dispatch, onFinish);
      return res.data;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      invoicesAndCreditNotesErrorHandle(errors, setError, maxDate);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editInvoicesAndCreditNotesStatusThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, statusTransition: number, entityId: number, isSales: boolean,
}>(
  'patch/InvoiceDocumentStatus',
  async ({
    id, statusTransition, entityId, isSales,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await invoicingAPI.putInvoiceDocumentStatus(id, statusTransition);
      dispatch(setLoading(false));
      actionsAfterFinish(entityId, isSales, getState, dispatch);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addInvoicesAndCreditNotesThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, entityNumber: string }> | null,
{
  data: AddEditInvoiceFields & { pendingNominalRecordIds: number[] },
  id: number,
  onFinish: VoidFunctionType,
  setError: UseFormSetError<AddEditInvoiceFields>,
  entityType: 'Invoice' | 'Credit note',
  isSales: boolean,
  maxDate: string,
}>(
  'post/InvoiceDocument',
  async ({
    data, id, setError, onFinish, entityType, isSales, maxDate,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { invoicing: { invoiceNominalCodesFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await invoicingAPI.fetchNominalRecords({
        ...invoiceNominalCodesFilters, ...emptyPaging,
      });
      const filteredIds = data.pendingNominalRecordIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        const res = await invoicingAPI.postInvoiceDocument({
          ...data, pendingNominalRecordIds: filteredIds,
        });
        dispatch(setFetching(false));
        const { entityNumber } = res.data.data;
        dispatch(setSuccessMessage({ message: `${entityType} ${entityNumber} was successfully created`, toastId: Math.random() }));
        actionsAfterFinish(id, isSales, getState, dispatch, onFinish);
        return res.data;
      }
      dispatch(setFetching(false));
      actionsAfterFinish(id, isSales, getState, dispatch, onFinish);
      return null;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      invoicesAndCreditNotesErrorHandle(errors, setError, maxDate);
      return rejectWithValue(error.response?.data);
    }
  },
);

// TODO MDA: maybe move code to a separate file?
export const getInvoiceNominalCodesThunk = createAsyncThunk<GetNominalRecordsResponse, {
  filters: NominalRecordsFilters,
}>(
  'get/InvoiceNominalCodes',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await invoicingAPI.fetchNominalRecords(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalCodesSummary = createAsyncThunk<ResponseSingleResult<GetNominalRecordsSummaryResponse>, {
  filters: NominalCodesSummaryFilters,
}>(
  'get/NominalRecordsSummary',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await invoicingAPI.fetchNominalRecordsSummary(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
