import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import Tr from '../../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import Tag from '../../../../../../core/components/tag/Tag';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { PurchaseOrderPartTableType } from '../../../types/purchaseOrderViewPageTypes';
import { BooleanFunctionType, NumberFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import EditableNoteCell from './EditableNoteCell';
import { CANCELLED_TIP } from '../../../utils/data';

type PurchaseOrderPartsListBodyProps = {
  purchaseOrderPartsList: PurchaseOrderPartTableType[],
  setIsSelectPrice: BooleanFunctionType,
  setIsReceived: BooleanFunctionType,
  checkLine: NumberFunctionType,
  checkedKeys: number[],
  setItemToChange: (value?: number) => void,
  setIsDelete: VoidFunctionType,
  setEditMode: (id: number, partId: number) => void,
  colsCount: number,
  setEditModeForCustomLine: NumberFunctionType,
}

const PurchaseOrderPartsListBody: React.FC<PurchaseOrderPartsListBodyProps> = ({
  purchaseOrderPartsList, setIsSelectPrice, setIsReceived, checkLine, checkedKeys, setItemToChange,
  setIsDelete, setEditMode, colsCount, setEditModeForCustomLine,
}) => {
  const allowed = usePermission(PermissionEnum.StockPurchaseOrdersEditPartLineFieldsAndUnlockPartLineActions);
  const hiddenKeys = [
    'id', 'partId', 'unitOfMeasure',
    'isUsualLine', 'vat', 'canBeDeleted',
    'arePriceAndRequestedQuantityEditable',
    'areReceivedAndReturnedQuantitiesEditable',
  ];
  const nowrapValues = ['requestedQuantity', 'costPrice', 'net', 'gross', 'totalReceivedQuantity', 'cancelledQuantity', 'dueIn'];
  const receivedKeys = ['totalReceivedQuantity', 'cancelledQuantity', 'dueIn'];
  const quantityKeys = ['requestedQuantity', 'costPrice'];

  // USUAL line click
  const clickHandle = (key: string, id: number, partId: number) => {
    if (allowed) {
      if (quantityKeys.includes(key)) {
        setEditMode(id, partId);
        setIsSelectPrice(true);
      }
      if (receivedKeys.includes(key)) {
        setIsReceived(true);
        setItemToChange(id);
      }
    }
  };

  // CUSTOM line click
  const customLineClickHandle = (key: string, id: number) => {
    if (allowed) {
      if (quantityKeys.includes(key)) {
        setEditModeForCustomLine(id);
      }
    }
  };

  const isCellClickable = (key: string, el: PurchaseOrderPartTableType) => {
    if (allowed && quantityKeys.includes(key) && el.arePriceAndRequestedQuantityEditable) {
      return true;
    }
    return allowed && receivedKeys.includes(key) && el.areReceivedAndReturnedQuantitiesEditable;
  };

  const cancelledInfoIcon = () => {
    return <Tooltip
      title={CANCELLED_TIP}
      overlayClassName="tooltip-sm"
    >
      <FontAwesomeIcon icon={faInfoCircle} />
    </Tooltip>;
  };

  const renderValueHandle = (key: string, value: string | number | null, el: PurchaseOrderPartTableType) => {
    if (key === 'state') {
      return <Tag label={value} />;
    }
    if (key === 'partNumber' && el.isUsualLine) {
      return <PrivateLineLink
        permission={PermissionEnum.StockPartViewPage}
        to={`/${routesPath.STOCK}/part/${el.partId}`}
      >
        <TruncateTooltip value={value} table />
      </PrivateLineLink>;
    }
    if (key === 'partName') {
      return <TruncateTooltip value={value} table />;
    }
    if (key === 'notes') {
      return <EditableNoteCell
        allowedToManage={allowed}
        value={value}
        partId={el.id}
        notes={el.notes}
      />;
    } else {
      const isClickable = isCellClickable(key, el);

      const lineClickHandle = () => {
        if (el.isUsualLine) {
          // IMPORTANT TO SENT ORIGINAL PART ID
          el.partId && isClickable && clickHandle(key, el.id, el.partId);
        } else {
          customLineClickHandle(key, el.id);
        }
      };
      return (
        <span className={classNames({ 'td-content-with-tooltip': key === 'cancelledQuantity' })}>
          <span
            className={classNames({ 'table-clickable-content': isClickable })}
            onClick={lineClickHandle}
          >
            {value}
          </span>
          {key === 'cancelledQuantity' && cancelledInfoIcon()}
        </span>
      );
    }
  };
  return (
    <Tbody>
      {purchaseOrderPartsList.length > 0
        ? purchaseOrderPartsList.map((el) => <Tr key={el.id}>
          {allowed && (
            <TdNew>
              <Checkbox
                checked={el.canBeDeleted && checkedKeys.includes(el.id)}
                onChange={() => checkLine(el.id)}
                disabled={!el.canBeDeleted}
                table
              />
            </TdNew>
          )}
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return (
              <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
                {renderValueHandle(key, value as (string | number), el)}
              </TdNew>
            );
          })}
          {allowed && (
            <TdNew action>
              <Dropdown
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                menuItems={[
                  {
                    label: 'Delete line',
                    key: 'deletePartLine',
                    onClick: () => {
                      setIsDelete();
                      setItemToChange(el.id);
                    },
                    icon: <FontAwesomeIcon icon={faTrashAlt} />,
                  },
                ]}
                disabled={!el.canBeDeleted}
              />
            </TdNew>
          )}
        </Tr>)
        : <EmptyTableSection text="No records" colSpan={colsCount} />}
    </Tbody>
  );
};

export default PurchaseOrderPartsListBody;
