import React, { useEffect } from 'react';
import LoginForm from './components/LoginForm';
import './LoginContainer.scss';
import { useLocationHistoryState } from '../core/hooks/useLocationHistoryState';
import { routesPath } from '../core/enums/pathEnum';
import { useAppSelector } from '../store/hooks';
import { authTokenSelector } from '../store/selectors/coreSelectors';

const LoginContainer: React.FC = () => {
  const token = useAppSelector(authTokenSelector);
  const { backHandle } = useLocationHistoryState(false);
  useEffect(() => {
    if (token) {
      backHandle(routesPath.HOME);
    }
    // eslint-disable-next-line
  }, [token]);
  return (
    <div className="login">
      <LoginForm />
    </div>
  );
};

export default LoginContainer;
