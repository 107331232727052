import React, { useEffect, useState } from 'react';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { PermissionEnum, UNASSIGNED_TAG_NAME } from '../../../../core/enums/dictionariesEnums';
import Select from '../../../../core/components/select/Select';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { ContentModeEnum } from '../../../../enquiry/enquiry-detailed/enums/ContentModeEnum';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { usersFilterLookupSelector } from '../../../../store/selectors/coreSelectors';
import { isJobStatusCompletedSelector, jobDetailsSelector } from '../../../../store/selectors/jobsSelector';
import { changeJobAssigneesThunk } from '../../../../store/thunks/jobs/jobThunks';
import { JobDetailsFiltersType } from '../../types/jobViewTypes';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import usePermission from '../../../../permissions-handling/permissionHook';
import { TabOptionType } from '../../../../core/types/coreTypes';
import JobStatusChangeConfirmationModal from '../../../container/components/modals/JobStatusChangeConfirmationModal';
import { jobInitUIStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { UNKNOWN_USER } from '../../../../core/utils/regex';
import { useUsersDictionary } from '../../../../common/hooks/useUsersDictionary';
import { useJobStatusTransition } from '../../../container/hooks/useJobStatusTransition';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import QualityCheckModal from '../job-content/quality-check/quality-check-modal/QualityCheckModal';

type JobFiltersProps = {
  tabs: TabOptionType[];
  mode: string | null;
  setModeParam: (value: ContentModeEnum | string) => void;
  jobDetailsFilters: JobDetailsFiltersType;
}

const JobFilters: React.FC<JobFiltersProps> = ({
  tabs, mode, setModeParam, jobDetailsFilters,
}) => {
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const jobDetails = useAppSelector(jobDetailsSelector);
  const jobStatuses = useAppSelector(jobInitUIStatusesSelector);
  const isJobStatusCompleted = useAppSelector(isJobStatusCompletedSelector);
  const assignees = useAppSelector(usersFilterLookupSelector);
  const allowedToEdit = usePermission(PermissionEnum.JobEditFields);
  const [assigneeV, setAssigneeV] = useState<(number | string)[]>([]);

  const {
    isFailed,
    jobNewStatus,
    isConfirmModalVisible,
    modalTitle,
    modalText,
    modalConfirmLabel,
    confirmEditingHandler,
    editStatusActionViewPageHandler,
    cancelStatusConfirmationModal,
    comment,
    setComment,
    error,
    setError,
    fileList,
    setFileList,
    isProceedToQualityCheck,
    setIsProceedToQualityCheck,
    isRunCheck,
    setIsRunCheck,
  } = useJobStatusTransition({ jobDetailsFilters });

  const { userSearch, setUserSearch } = useUsersDictionary();

  useEffect(() => {
    if (jobDetails) {
      const assignees = jobDetails.assignees?.map((ass) => (ass.isDeleted ? UNKNOWN_USER : ass.id));
      setAssigneeV(assignees || []);
    }
  }, [jobDetails]);

  const availableStatuses = jobDetails?.job?.statusTransitions
    ? [...jobDetails.job.statusTransitions, jobDetails.job.jobStatus]
    : ([jobDetails?.job?.jobStatus] || []);
  const statusTransitions = availableStatuses?.map((st) => {
    const curr = jobStatuses.find((el) => el.value === st)?.label;
    return {
      label: curr || '',
      value: st || '',
    };
  });

  const isControlsDisabled = !allowedToEdit || isJobStatusCompleted;

  const assigneeChangeHandle = (v: number[]) => {
    const id = jobDetails?.job.id;
    id && dispatch(changeJobAssigneesThunk({
      id,
      assigneeIds: v,
    }));
  };

  const checkIsNewStatus = (value: (number | UNASSIGNED_TAG_NAME)[]) => {
    const isNewStatus = jobNewStatus === jobDetails?.job?.jobStatus;
    if (!isNewStatus) {
      if (value.length > 0) {
        const withoutUnassigned = value.filter((el) => el !== UNASSIGNED_TAG_NAME.UNASSIGNED);
        setAssigneeV(withoutUnassigned as number[]);
      }
    } else {
      const withoutUnassigned = value.filter((el) => el !== UNASSIGNED_TAG_NAME.UNASSIGNED);
      setAssigneeV(withoutUnassigned as number[]);
    }
  };
  return (
    <>
      <JobStatusChangeConfirmationModal
        title={modalTitle}
        text={modalText}
        extraQuestion={!isFailed}
        visible={isConfirmModalVisible}
        onCancel={cancelStatusConfirmationModal}
        cancelClick={cancelStatusConfirmationModal}
        confirmLabel={modalConfirmLabel}
        confirmClick={confirmEditingHandler}
        isFileAttach={isFailed}
        comment={comment}
        setComment={setComment}
        error={error}
        setError={setError}
        fileList={fileList}
        setFileList={setFileList}
      />
      <ConfirmationModal
        isVisible={isProceedToQualityCheck}
        customTitle="Proceed to quality check"
        customText="Would you like to start a quality check?"
        customCreateLabel="Run quality check"
        customCancelLabel="Later"
        onCancel={() => setIsProceedToQualityCheck(false)}
        confirmHandler={() => {
          setIsRunCheck(true);
          setIsProceedToQualityCheck(false);
        }}
      />
      <QualityCheckModal
        isVisible={isRunCheck}
        onCancel={() => setIsRunCheck(false)}
        readMode={false}
      />
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG && tabs.length > 0 && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={tabs}
            value={mode}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className="view-details-tabs-actions view-details-tabs-actions--large-tabs desktop-sticky-filters">
        {windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={tabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="view-details-tabs-actions__segmented"
          />
        )}
        <div className="view-details-tabs-actions__selects-wrapper">
          <Select
            mode="multiple"
            // eslint-disable-next-line
            // @ts-ignore
            value={assigneeV && assigneeV.length > 0 ? assigneeV as (number | string)[] : [UNASSIGNED_TAG_NAME.UNASSIGNED]}
            onChange={(value) => checkIsNewStatus(value as number[])}
            onBlur={() => {
              assigneeChangeHandle(assigneeV as number[]);
            }}
            options={assignees || []}
            showSearch
            showArrow
            onSearch={(val) => {
              if (val.length <= 250) {
                setUserSearch(val.trim());
              } else {
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            disabled={isControlsDisabled}
            onSelect={() => {
              userSearch && setUserSearch(undefined);
            }}
            className="view-details-tabs-actions__select--highlighted"
            maxTagCount="responsive"
            parentRender
          />
          <Select
            value={jobDetailsFilters.statusTransition || ''}
            onChange={(value) => editStatusActionViewPageHandler(value as number)}
            options={statusTransitions || []}
            parentRender
            disabled={isControlsDisabled}
          />
        </div>
      </div>
    </>
  );
};

export default JobFilters;
