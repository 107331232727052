import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  salesReportByCustomerColumnsSelector,
  salesReportByCustomerDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import SalesReportByCustomerColumns from './SalesReportByCustomerColumns';
import SalesReportByCustomerBody from './SalesReportByCustomerBody';
import './SalesReportByCustomerTable.scss';

const SalesReportByCustomerTable: React.FC = () => {
  const columns = useAppSelector(salesReportByCustomerColumnsSelector);
  const { totalCount } = useAppSelector(salesReportByCustomerDataSelector);

  return (
    <TableNew>
      <SalesReportByCustomerColumns columns={columns} totalCount={totalCount} />
      <SalesReportByCustomerBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default SalesReportByCustomerTable;
