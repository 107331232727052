import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import EnquiriesFilters from './components/enquiries-filters/EnquiriesFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { editEnquiryLayoutThunk } from '../../store/thunks/enquiry/enquiryThunks';
import { useEnquiryContainerSelectors } from './hooks/useEnquiryContainerSelectors';
import { EnquiryModes, EnquiryModesEnum } from './enums/dashboardViewsEnum';
import { EnquiriesNewResponseType } from './types/enquiryTypes';
import EnquiriesContent from './components/enquiries-content/EnquiriesContent';
import ColumnsConfigurationModal from '../../common/components/columns-configuration-modal/ColumnsConfigurationModal';
import {
  setAssigneesLookup,
  setColumnsPagination,
  setEnquiryCollections,
  setFilters,
} from '../../store/slices/enquiriesSlice';
import { getEnquiryWarningsThunk } from '../../store/thunks/coreThunk';
import { enquiriesFiltersSelector } from '../../store/selectors/enquiriesSelector';

const EnquiriesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const keywordParam = params.get('keyword');
  const filters = useAppSelector(enquiriesFiltersSelector);

  useEffect(() => {
    if (keywordParam && !filters.keyword) {
      dispatch(setFilters({ ...filters, keyword: keywordParam }));
    }
    // eslint-disable-next-line
  }, [keywordParam, filters]);

  // LOCAL STATE
  const [isEyeModal, setIsEyeModal] = useState<boolean>(false);

  const setModeParam = (mode: string) => {
    if (EnquiryModes.includes(mode as EnquiryModesEnum)) {
      params.append('page', mode);
    } else {
      params.delete('page');
    }
    navigate({ search: `?page=${mode}` }, { replace: true });
  };
  // STORE OPTIONS
  const {
    enquiryCollections,
    currentBusinessArea,
    permittedEnqDashStatuses,
    warnings,
  } = useEnquiryContainerSelectors();
  const [cards, setCards] = useState<EnquiriesNewResponseType | null>(null);

  useEffect(() => {
    setCards(enquiryCollections);
  }, [enquiryCollections]);

  useEffect(() => {
    if (!warnings.length) {
      dispatch(getEnquiryWarningsThunk());
    }

    return () => {
      dispatch(setColumnsPagination([]));
      dispatch(setEnquiryCollections(null));
      dispatch(setAssigneesLookup([]));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout scrollableMainArea={params.get('page') === EnquiryModesEnum.DASHBOARD}>
      <>
        <ColumnsConfigurationModal
          isVisible={isEyeModal}
          setIsVisible={setIsEyeModal}
          statusOptions={permittedEnqDashStatuses}
          saveHandle={(layoutOptions) => currentBusinessArea && dispatch(editEnquiryLayoutThunk({
            businessArea: currentBusinessArea,
            layoutOptions,
            closeModal: () => setIsEyeModal(false),
            permittedStatuses: permittedEnqDashStatuses,
          }))}
          collections={enquiryCollections}
        />
        <EnquiriesFilters
          page={params.get('page')}
          setPageParam={setModeParam}
          setIsEyeModal={setIsEyeModal}
        />
        <EnquiriesContent
          pageParam={params.get('page')}
          cards={cards}
          setCards={setCards}
        />
      </>
    </Layout>
  );
};

export default EnquiriesContainer;
