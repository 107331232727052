import React from 'react';
import FitmentTimeTable from './table/FitmentTimeTable';
import Switch from '../../../../../core/components/switch/Switch';
import { setFitmentTimeFilters, setIsTableView } from '../../../../../store/slices/reportsSlice';
import { getFitmentTimeReportChartThunk, getFitmentTimeReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import Segmented from '../../../../../core/components/segmented/Segmented';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { useFitmentTimeReportDefaultOrdering } from '../../../../hooks/useFitmentTimeReportDefaultOrdering';
import {
  fitmentTimeDataSelector,
  fitmentTimeReportFiltersSelector,
  isTableViewSelector,
  reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import FitmentTimeChart from './chart/FitmentTimeChart';
import { FITMENT_TIME_CHART_COUNT_OPTIONS } from './utils/data';

const FitmentTimeContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fitmentTimeReportFiltersSelector);
  const { totalCount } = useAppSelector(fitmentTimeDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const isTableView = useAppSelector(isTableViewSelector);

  const defaultPagingAndOrder = useFitmentTimeReportDefaultOrdering(false);

  return (
    <>
      <div className="actions-and-paging reportActionsAndPaging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                differenceMinutes, totalSpentMinutes, totalEstimatedMinutes, spentByAssigneeMinutes, ...rest
              } = filters;
              const newFilters = {
                ...rest,
                ...defaultPagingAndOrder,
                pageSize: val ? FITMENT_TIME_CHART_COUNT_OPTIONS[0] : defaultPagingAndOrder.pageSize,
              };
              dispatch(setFitmentTimeFilters(newFilters));
              if (reportIsVisible) {
                val
                  ? dispatch(getFitmentTimeReportChartThunk({ filters: newFilters }))
                  : dispatch(getFitmentTimeReportThunk({ filters: newFilters }));
              }
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView ? (
          <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setFitmentTimeFilters(newFilters));
              reportIsVisible && dispatch(getFitmentTimeReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
        )
          : <Segmented
            options={FITMENT_TIME_CHART_COUNT_OPTIONS}
            value={filters.pageSize}
            onChange={(value) => {
              const newFilters = { ...filters, pageSize: +value };
              dispatch(setFitmentTimeFilters(newFilters));
              reportIsVisible && dispatch(getFitmentTimeReportChartThunk({ filters: newFilters }));
            }}
          />}
      </div>
      {isTableView
        ? <FitmentTimeTable />
        : <FitmentTimeChart />}
    </>
  );
};

export default FitmentTimeContent;
