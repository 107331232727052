import React from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path,
} from 'react-hook-form';
import { DictionaryItem } from '../../../core/types/coreTypes';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Select from '../../../core/components/select/Select';

type VehicleCheckDecisionProps<T extends FieldValues> = {
  control: Control<T>,
  errors: FieldErrors<T>,
  options: DictionaryItem[],
  fieldName: string,
  disabled?: boolean,
}

const VehicleCheckDecision = <T extends FieldValues, >({
  control, errors, options, fieldName, disabled,
}: VehicleCheckDecisionProps<T>) => {
  return (
    <section className="details-form no-offset-top">
      <h2 className="info-grid__title">Vehicle check decision</h2>
      <Controller
        control={control}
        name={fieldName as Path<T>}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value as (string | number)}
            onChange={(val) => field.onChange(val)}
            options={options}
            error={errors[fieldName]?.message}
            className="details-form__field--lg"
            disabled={disabled}
          />
        )}
      />
    </section>
  );
};

export default VehicleCheckDecision;
