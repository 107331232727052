import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AttachmentsCellProps, BooleanFunctionType } from '../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  deleteRectificationAttachmentThunk, editRectificationAttachmentThunk,
  getRectificationAttachmentByIdThunk,
} from '../../../../../store/thunks/rectification/rectificationAttachmentsThunks';
import AttachmentsRow from '../../../../../common/components/attachments-table/AttachmentsRow';
import usePermission from '../../../../../permissions-handling/permissionHook';

const RectificationAttachmentsCell: React.FC<AttachmentsCellProps> = ({
  attach,
  openEditModal,
  checked,
  checkRow,
  disabledActions,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isModal, setModal] = useState(false);
  const allowedManage = usePermission(PermissionEnum.RectificationEditFields);
  const actions = [
    {
      label: 'Edit comment',
      icon: <FontAwesomeIcon icon={faEdit} />,
      key: 'editComment',
      onClick: () => openEditModal(),
    },
    {
      label: 'Delete file',
      key: 'deleteFile',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setModal(true),
    },
  ];

  const changeShowInLinkedJobsToggle = (attachmentId: number, value: boolean, setSwitchLoading: BooleanFunctionType) => {
    if (id) {
      dispatch(editRectificationAttachmentThunk({
        id: attachmentId,
        rectificationId: +id,
        data: { showInLinkedJobs: value },
        setSwitchLoading,
      }));
    }
  };
  return (
    <>
      <ConfirmationModal
        isVisible={isModal}
        onCancel={() => setModal(false)}
        confirmHandler={() => id && dispatch(deleteRectificationAttachmentThunk({
          rectificationId: +id,
          id: attach.id,
          closeModal: () => setModal(false),
        }))}
      />
      <AttachmentsRow
        allowedManage={!!allowedManage}
        attachment={attach}
        checked={checked}
        checkRows={checkRow}
        onClick={(attachId) => id && dispatch(getRectificationAttachmentByIdThunk({
          rectificationId: +id,
          id: attachId,
        }))}
        actions={actions}
        disabledActions={disabledActions}
        changeShowInLinkedJobsToggle={changeShowInLinkedJobsToggle}
      />
    </>
  );
};

export default RectificationAttachmentsCell;
