// TODO: 'eslint-disable' should be removed if forms will be developing
/* eslint-disable */

import React, { useState } from 'react';
import FormsColumns from './FormsColumns';
import { BooleanFunctionType, CoreAttachmentDto, NumberFunctionType } from '../../core/types/coreTypes';
import { useAppDispatch } from '../../store/hooks';
import EditCommentModal from '../../common/components/edit-attachment-comment/EditCommentModal';
import AttachmentsTable from '../../common/components/attachments-table/AttachmentsTable';
import FormsCell from './FormsCell';
import EmptyTableSection from '../../core/components/table-components/EmptyTableSection';

type FormsTableProps = {
  data: CoreAttachmentDto[];
  checkedKeys: number[];
  setCheckedKeys: (value: number[]) => void;
  isAll: boolean;
  setIsAll:BooleanFunctionType;
  openEditFolderModal: NumberFunctionType,
  setDeleteFolderModal: NumberFunctionType,
}

const FormsTable: React.FC<FormsTableProps> = ({
  data, checkedKeys, setCheckedKeys, isAll, setIsAll, openEditFolderModal, setDeleteFolderModal,
}) => {
  const dispatch = useAppDispatch();
  const [isEditModal, setEditModal] = useState<boolean>(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState<string>('');

  const checkAll = (value: boolean) => {
    if (data) {
      if (value) {
        setCheckedKeys && setCheckedKeys(data.map((el) => el?.id));
      } else {
        setCheckedKeys && setCheckedKeys([]);
      }
      setIsAll && setIsAll(value);
    }
  };
  const checkRows = (key: number) => {
    if (checkedKeys?.includes(key)) {
      const newArr = checkedKeys.filter((el) => el !== key);
      setCheckedKeys && setCheckedKeys(newArr);
      isAll && setIsAll && setIsAll(false);
    } else {
      setCheckedKeys && setCheckedKeys(checkedKeys ? [...checkedKeys, key] : [key]);
      if (data && checkedKeys?.length === data.length - 1) {
        setIsAll && setIsAll(true);
      }
    }
  };

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };
  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => console.debug('edit')}
        // editHandle={() => attachToEdit && dispatch(editEnquiryAttachmentCommentThunk({
        //   id: attachToEdit,
        //   comment: editedComment,
        //   onCancel: cancelHandle,
        // }))}
      />
      <AttachmentsTable>
        <FormsColumns
          isAll={isAll}
          checkAll={checkAll}
          totalElements={0}
          openEditFolderModal={openEditFolderModal}
          setDeleteFolderModal={setDeleteFolderModal}
        />
        {data.length > 0
          ? data.map((attachment) => (
            <FormsCell
              key={attachment.id}
              attach={attachment}
              openEditModal={() => {
                setAttachToEdit(attachment.id);
                setEditedComment(attachment.comment || '');
                setEditModal(true);
              }}
              clearCheckboxes={() => {
                isAll && setIsAll(false);
                setCheckedKeys([]);
              }}
              checked={checkedKeys?.includes(attachment.id)}
              checkRow={() => checkRows(attachment.id)}
            />
          ))
          : <EmptyTableSection text="No records" colSpan={6} />}
      </AttachmentsTable>
    </>
  );
};

export default FormsTable;
