import React, { useEffect, useMemo, useState } from 'react';
import { BooleanFunctionType, initDataList } from '../../../../../../core/types/coreTypes';
import { EnquiryEventDtoBase } from '../../../../types/enquiryDetailsTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import './QuoteLinesGroupModal.scss';
import Thead from '../../../../../../core/components/table-components/Thead';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  enquiryQuoteTimelineColumnsSelector,
  enquiryTimelineQuoteLinesFiltersSelector,
  enquiryTimelineQuoteLinesSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import QuoteLinesGroupColumns from './QuoteLinesGroupColumns';
import {
  getEnquiryTimelineQuoteLineAfterThunk,
  getEnquiryTimelineQuoteLineBeforeThunk,
} from '../../../../../../store/thunks/enquiry/detailsThunks';
import {
  setEnquiryTimelineQuoteLines,
  setEnquiryTimelineQuoteLinesFilters,
} from '../../../../../../store/slices/enquiriesSlice';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../core/components/table-components/Tr';
import QuoteLineGroupCell from './QuoteLineGroupCell';
import { InitialQuoteLinesFilters } from '../../../../../../store/initialValues';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Button from '../../../../../../core/components/button/Button';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import { getEnquiryTimelineQuoteLineGroupOrderingThunk } from '../../../../../../store/thunks/core/coreOrderingThunks';
import { enquiryTimelineQuoteLineGroupOrderingSelector } from '../../../../../../store/selectors/coreOrderingSelectors';

type QuoteLinesGroupsModalProps = {
  isVisible: boolean,
  setIsVisible: BooleanFunctionType,
  item: EnquiryEventDtoBase;
}

const QuoteLinesGroupsModal: React.FC<QuoteLinesGroupsModalProps> = ({ isVisible, setIsVisible, item }) => {
  const ordering = useAppSelector(enquiryTimelineQuoteLineGroupOrderingSelector);
  const [mode, setMode] = useState<string>('after');
  const dispatch = useAppDispatch();
  const enquiryTimelineQuoteLinesFilters = useAppSelector(enquiryTimelineQuoteLinesFiltersSelector);
  const enquiryTimelineQuoteLines = useAppSelector(enquiryTimelineQuoteLinesSelector);
  const columns = useAppSelector(enquiryQuoteTimelineColumnsSelector);

  useEffect(() => {
    if (!ordering.length && isVisible) {
      dispatch(getEnquiryTimelineQuoteLineGroupOrderingThunk());
    }
    // eslint-disable-next-line
  }, [ordering, isVisible]);

  const lines = useMemo(
    () => (mode === 'before' ? enquiryTimelineQuoteLines.before : enquiryTimelineQuoteLines.after),
    [mode, enquiryTimelineQuoteLines.before, enquiryTimelineQuoteLines.after],
  );
  const linesArr = useMemo(() => lines.items.map((el) => ({
    number: el.number,
    quoteCategoryName: el.quoteCategoryName,
    productNumber: el.productNumber || '-',
    productName: el.productName || '-',
    notes: el.notes || '-',
    quantity: el.quantity || 0,
    unitPriceString: el.unitPriceString || '0.00',
    netPriceString: el.netPriceString || '0.00',
    priceSources: el.priceSources,
    discount: el.discount || 0,
    tax: el.tax || 0,
    weight: el.weight || 0,
  })), [lines.items]);

  useEffect(() => {
    if (isVisible) {
      if (mode === 'before') {
        dispatch(setEnquiryTimelineQuoteLinesFilters({ ...enquiryTimelineQuoteLinesFilters, page: 1 }));
        item.groupId
        && dispatch(getEnquiryTimelineQuoteLineBeforeThunk({
          lineGroupId: item.groupId,
          filters: { ...enquiryTimelineQuoteLinesFilters, page: 1 },
        }));
      } else {
        dispatch(setEnquiryTimelineQuoteLinesFilters({ ...enquiryTimelineQuoteLinesFilters, page: 1 }));
        item.groupId
        && dispatch(getEnquiryTimelineQuoteLineAfterThunk({
          lineGroupId: item.groupId,
          filters: { ...enquiryTimelineQuoteLinesFilters, page: 1 },
        }));
      }
    }
    // eslint-disable-next-line
  }, [isVisible, item.groupId, mode, enquiryTimelineQuoteLinesFilters.filters, enquiryTimelineQuoteLinesFilters.order]);
  const closeModal = () => {
    setIsVisible(false);
    dispatch(setEnquiryTimelineQuoteLinesFilters(InitialQuoteLinesFilters));
    dispatch(setEnquiryTimelineQuoteLines({ before: initDataList, after: initDataList }));
  };

  return (
    <Modal visible={isVisible} onCancel={closeModal} title="Quote changes" width={1000}>
      <div className="event-modal-radio">
        <Button
          onClick={() => setMode('before')}
          label="Before"
          designType="light-inverse"
          isActivated={mode === 'before'}
          offsetRight
        />
        <Button
          onClick={() => setMode('after')}
          label="After"
          designType="light-inverse"
          isActivated={mode === 'after'}
        />
      </div>
      <TableNew>
        <Thead>
          <QuoteLinesGroupColumns columns={columns} totalElements={linesArr.length} />
        </Thead>
        <Tbody>
          {linesArr.length > 0
            ? linesArr.map((d, i) => <Tr key={i}>
              <QuoteLineGroupCell cell={d} />
            </Tr>)
            : <EmptyTableSection text="No records" colSpan={columns.length} />}
        </Tbody>
      </TableNew>
    </Modal>
  );
};

export default QuoteLinesGroupsModal;
