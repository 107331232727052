import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../store';
import { SkillsOrderingEnum } from '../../employees/skill-list/enums/skillsEnum';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { setUiNamesHandler } from '../../core/utils/setUiNamesHandler';
import {
  EmployeeDepartmentUiNamesEnum,
  EmployeeSalaryTypeEnum,
} from '../../employees/container/enums/employeeUiNamesEnum';
import { labelHandle } from '../../core/utils/labelHandle';
import { EmployeeStatusesEnum } from '../../employees/employee-view-page/enums/employeeStatusesEnum';
import { accountAccountRolesSelector, leaveRequestTypesUiSelector } from './coreSelectors';
import { leaveRequestStatusesUiSelector } from './coreStatusesSelectors';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';
import { getSortIndex } from '../../core/utils/getSortIndex';

export const skillsFiltersSelector = (state: RootState) => state.employees.skillsFilters;
export const employeesDashStatusesSelector = (state: RootState) => state.employees.employeeDashStatuses;
export const employeeDashJobTitlesSelector = (state: RootState) => state.employees.employeeDashJobTitles;
export const employeeUIJobTitlesSelector = createSelector(employeeDashJobTitlesSelector, (jobTypes) => {
  return jobTypes.map((el) => {
    if (!el.value) {
      return { ...el, label: '(blank)' };
    } else return el;
  });
});

const employeeDepartmentsInitSelector = (state: RootState) => state.employees.employeeDepartments;
export const employeeDepartmentsSelector = createSelector(employeeDepartmentsInitSelector, (departments) => {
  return setUiNamesHandler(departments, EmployeeDepartmentUiNamesEnum);
});

export const employeesFiltersSelector = (state: RootState) => state.employees.dashboardFilters;
export const employeesFiltersListSelector = createSelector(
  employeesFiltersSelector,
  employeeDepartmentsSelector,
  employeeUIJobTitlesSelector,
  (values, departments, jobTitles) => {
    return [
      {
        value: values.department || [],
        name: 'department',
        label: 'Department',
        noSearch: true,
        options: departments,
      },
      {
        value: values.jobTitle || [],
        name: 'jobTitle',
        label: 'Job title',
        options: jobTitles,
      },
    ];
  },
);

export const employeesDashboardSelector = (state: RootState) => state.employees.employeesDashboard;
export const employeeDashboardUiListSelector = createSelector(
  employeesDashboardSelector,
  employeeDepartmentsSelector,
  (dataList, departments) => {
    const { items, totalCount, pages } = dataList;
    const uiItems = items.map((el) => ({
      id: el.id,
      title: el.title || '',
      fullName: el.fullName || '',
      jobTitle: el.jobTitle || '-',
      email: el.email || '-',
      contactInfo: el.contactInfo || '-',
      department: departments.find((dep) => dep.value === el.department)?.label || el.department || '-',
    }));
    return {
      items: uiItems,
      totalCount,
      pages,
    };
  },
);

export const skillsCategoriesSelector = (state: RootState) => state.employees.skillsCategories;

export const skillsOrderingSelector = (state: RootState) => state.employees.skillsOrdering;
export const skillsColumnsSelector = createSelector(skillsOrderingSelector, (ordering) => {
  return [
    {
      title: 'Item',
      dataIndex: 'item',
      sorter: true,
      orderField: getSortIndex(SkillsOrderingEnum.Item, ordering),
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      sorter: true,
      orderField: getSortIndex(SkillsOrderingEnum.CreatedDate, ordering),
    },
    {
      title: 'Modified date',
      dataIndex: 'modifiedDate',
      sorter: true,
      orderField: getSortIndex(SkillsOrderingEnum.ModifiedDate, ordering),
    },
  ];
});
export const skillsListByCategorySelector = (state: RootState) => state.employees.skillCategoryInfo;
export const skillsUiListSelector = createSelector(skillsListByCategorySelector, ({ skills }) => {
  return skills.map((el) => ({
    id: el.id,
    item: el.name || '-',
    description: el.description || '-',
    createdDate: localDateFormatHandler('DD/MM/YYYY HH:mm', el.createdDate || ''),
    modifiedDate: localDateFormatHandler('DD/MM/YYYY HH:mm', el.modifiedDate || ''),
  }));
});

export const affectedEmployeeFiltersSelector = (state: RootState) => state.employees.affectedEmployeeFilters;
export const affectedSkillsEmployeesSelector = (state: RootState) => state.employees.affectedSkillsEmployees;
export const affectedCategoryEmployeesSelector = (state: RootState) => state.employees.affectedCategoryEmployees;

export const employeesStatusesSelector = (state: RootState) => state.employees.employeeStatuses;
export const employeesStatusCurrentSelector = createSelector(
  employeesStatusesSelector,
  (statuses) => statuses.find((status) => status.label === EmployeeStatusesEnum.Current)?.value,
);
export const employeesStatusArchivedSelector = createSelector(
  employeesStatusesSelector,
  (statuses) => statuses.find((status) => status.label === EmployeeStatusesEnum.Archived)?.value,
);

export const employeesSalaryTypesInitSelector = (state: RootState) => state.employees.employeeSalaryTypes;
export const employeesSalaryTypesSelector = createSelector(employeesSalaryTypesInitSelector, (salaryTypes) => {
  return setUiNamesHandler(salaryTypes, EmployeeSalaryTypeEnum);
});

export const employeesGendersSelector = (state: RootState) => state.employees.employeeGenders;
export const employeesSkillLevelsSelector = (state: RootState) => state.employees.employeeSkillLevels;

export const employeeSkillsDictionarySelector = (state: RootState) => state.employees.employeeSkillsDictionary;

export const employeeSkillsUiDictionarySelector = createSelector(employeeSkillsDictionarySelector, (dictionary) => {
  return dictionary?.map((el) => ({
    label: el.dropdownMakedValue,
    value: el.id,
  }));
});

export const employeeDetailsSelector = (state: RootState) => state.employees.employeeDetails;
export const employeeImageDataSelector = (state: RootState) => state.employees.employeeImageData;
export const employeeIceContactSelector = (state: RootState) => state.employees.employeeICEContact;

export const employeeDetailsHeadSelector = createSelector(
  employeeDetailsSelector,
  employeesStatusesSelector,
  (details, statuses) => [
    { label: '', value: details?.employee ? `${details?.employee.firstName} ${details?.employee.lastName}` : '-' },
    { label: 'Status', value: labelHandle(details?.employee.status, statuses) },
    { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.employee.createdDate || '') },
    { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.employee.modifiedDate || '') },
  ],
);

export const employeeMainInfoSelector = createSelector(
  employeeDetailsSelector,
  employeeDepartmentsSelector,
  accountAccountRolesSelector,
  (details, departments, roles) => [
    { label: 'Employee No', value: details?.employee.referenceNumber || '-' },
    { label: 'Department', value: departments.find((department) => department.value === details?.employee.department)?.label || '-' },
    { label: 'Job title', value: details?.employee.jobTitle || '-' },
    { label: 'Linked account role', value: roles.find((role) => role.value === details?.employee.linkedAccountRole)?.label || 'N/A' },
  ],
);

export const employeeAttachmentsSelector = (state: RootState) => state.employees.employeeAttachments;
export const employeeAttachmentsFilterSelector = (state: RootState) => state.employees.employeeAttachmentsFilters;

export const employeeSkillsSelector = (state: RootState) => state.employees.employeeSkills;
export const employeeEmploymentsSelector = (state: RootState) => state.employees.employeeEmployments;
export const employeeEmploymentsUISelector = createSelector(
  employeeEmploymentsSelector,
  employeeDepartmentsSelector,
  employeesSalaryTypesSelector,
  (employments, departments, salaryTypes) => {
    return employments?.map((empl) => ({
      id: empl.id,
      startDate: moment(empl.startDate).format('DD-MMM-YYYY'),
      endDate: empl.endDate ? moment(empl.endDate).format('DD-MMM-YYYY') : 'Present',
      jobTitle: empl.jobTitle,
      salaryValue: empl.salaryValue === null ? 'N/A' : empl.salaryValue.toFixed(2),
      salaryType: salaryTypes.find((el) => el.value === empl.salaryType)?.label.toLowerCase(),
      annualLeaveEntitlement: empl.annualLeaveEntitlement === null ? 'N/A' : empl.annualLeaveEntitlement,
      department: departments.find((el) => el.value === empl.department)?.label,
    }));
  },
);

export const employeeActivityFiltersSelector = (state: RootState) => state.employees.employeeActivityFilters;
export const employeeTimelineSelector = (state: RootState) => state.employees.employeeTimeline;

export const employeesLeaveRequestsFiltersSelector = (state: RootState) => state.employees.leaveRequestsFilters;
export const employeesLeaveRequestsSearchFiltersSelector = createSelector(
  employeesLeaveRequestsFiltersSelector,
  leaveRequestTypesUiSelector,
  employeeDepartmentsSelector,
  employeeUIJobTitlesSelector,
  (filters, leaveTypes, departments, jobTitles) => {
    return [
      {
        label: 'Leave type',
        name: 'leaveTypes',
        value: filters.leaveTypes,
        noSearch: true,
        options: leaveTypes,
      },
      {
        label: 'Department',
        name: 'departments',
        value: filters.departments,
        noSearch: true,
        options: departments,
      },
      {
        label: 'Job title',
        name: 'jobTitles',
        value: filters.jobTitles,
        options: jobTitles,
      },
    ];
  },
);

const employeesLeaveRequestsInitSelector = (state: RootState) => state.employees.employeesLeaveRequests;
export const employeesLeaveRequestsListSelector = createSelector(
  employeesLeaveRequestsInitSelector,
  leaveRequestTypesUiSelector,
  leaveRequestStatusesUiSelector,
  (data, types, statuses) => {
    return {
      pages: data.pages,
      totalCount: data.totalCount,
      items: data.items.map((el) => ({
        id: el.id,
        employeeName: el.employeeName || '-',
        employeeJobTitle: el.employeeJobTitle || '-',
        leaveType: types.find((type) => type.value === el.leaveType)?.label || '',
        startDate: localDateFormatHandler('DD/MM/YYYY', el.startDate || ''),
        endDate: localDateFormatHandler('DD/MM/YYYY', el.endDate || ''),
        leavePeriod: el.leavePeriod,
        status: el.status,
        statusName: statuses.find((status) => status.value === el.status)?.label || '',
        comment: el.comment,
      })),
    };
  },
);

export const employeesWorkCalendarFiltersSelector = (state: RootState) => state.employees.workCalendarFilters;
export const employeeWorkCalendarSearchFiltersSelector = createSelector(
  employeesWorkCalendarFiltersSelector,
  employeeDepartmentsSelector,
  employeeUIJobTitlesSelector,
  (filters, departments, jobTitles) => {
    return [
      {
        label: 'Department',
        name: 'departments',
        value: filters.departments,
        noSearch: true,
        options: departments,
      },
      {
        label: 'Job title',
        name: 'jobTitles',
        value: filters.jobTitles,
        options: jobTitles,
      },
    ];
  },
);

const employeeWorkCalendarDatesInitSelector = (state: RootState) => state.employees.workCalendarDates;
export const employeeWorkCalendarDatesSelector = createSelector(employeeWorkCalendarDatesInitSelector, (dates) => {
  return dates?.map((el) => localDateFormatHandler(DEFAULT_DATE_FORMAT, el)) || [];
});

export const employeeWorkCalendarUsersSelector = (state: RootState) => state.employees.workCalendarUsers;

export const employeeAffectedDatesSelector = (state: RootState) => state.employees.employeeAffectedDates;

const employeesTotalListSelector = (state: RootState) => state.employees.employeesTotalList;
export const employeesTotalDictionaryListSelector = createSelector(employeesTotalListSelector, (list) => {
  return list.items.map((el) => ({ label: el.fullName, value: el.id }));
});

export const employeeHolidaysFiltersSelector = (state: RootState) => state.employees.employeeHolidaysFilters;
const employeeHolidaysYearsInitSelector = (state: RootState) => state.employees.employeeHolidaysYears;
export const employeeHolidaysYearsDictionarySelector = createSelector(employeeHolidaysYearsInitSelector, (years) => {
  return years?.map((el) => ({ label: el, value: el })) || [];
});
const employeeHolidaysListInitSelector = (state: RootState) => state.employees.employeeHolidaysList;
export const employeeHolidaysListSelector = createSelector(employeeHolidaysListInitSelector, (data) => {
  return data.map(({ id, name, date }, i) => ({
    number: i + 1,
    id,
    name: name || '-',
    date: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, date),
  }));
});

const initEmployeesJobTitlesWithCountSelector = (state: RootState) => state.employees.employeesJobTitlesWithCount;
export const employeeJobTitlesWithCountSelector = createSelector(initEmployeesJobTitlesWithCountSelector, (values) => {
  return values.map((el) => ({ label: `${el.label} (${el.employeesCount})`, value: el.value })) || [];
});

const initEmployeesDepartmentsWithCountSelector = (state: RootState) => state.employees.employeesDepartmentsWithCount;
export const employeeDepartmentsWithCountSelector = createSelector(
  initEmployeesDepartmentsWithCountSelector,
  employeeDepartmentsSelector,
  (values, departments) => {
    const getDepartmentLabelHandle = (v: number) => departments.find((d) => d.value === v)?.label || '';
    return values.map((el) => ({ label: `${getDepartmentLabelHandle(el.value)} (${el.employeesCount})`, value: el.value })) || [];
  },
);
