import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType,
  CoreAttachmentDto,
  CoreAttachmentsFilters,
  initialCoreAttachmentsFilters,
  ResponseSingleResult,
  VoidFunctionType,
  AxiosErrorResponse,
  GetAttachmentFileResponse, GetAttachmentsResponse, emptyPaging,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import {
  setCustomerAttachments, setCustomerAttachmentsFilters,
} from '../../slices/customersSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { getCustomerById } from './customersViewPageThunks';
import { RootState } from '../../store';

export const getCustomerAttachments = createAsyncThunk<GetAttachmentsResponse,
{
  customerId: number,
  filters: CoreAttachmentsFilters,
}
>('get/CustomerAttachments', async ({ customerId, filters }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await customersAPI.fetchCustomerAttachments(customerId, filters);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const getCustomerAttachmentById = createAsyncThunk<
ResponseSingleResult<GetAttachmentFileResponse>,
{ customerId: number, id: number }
>('get/CustomerAttachmentById', async ({ customerId, id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await customersAPI.getCustomerAttachmentById(customerId, id);
    response.data.data && downloadCsv(response.data.data.uri);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const createCustomerAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{ customerId: number, list: CoreAttachListElType[], comment: string, onCancel: VoidFunctionType }
>('post/CustomerAttachment', async ({
  customerId, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { customers } = getState() as RootState;
    const { customerAttachmentsFilters } = customers;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await customersAPI.postCustomerAttachUploadUri(customerId, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await customersAPI.postCustomerAttach(customerId, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getCustomerById({ id: customerId }));
    dispatch(getCustomerAttachments({ customerId, filters: customerAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editCustomerAttachment = createAsyncThunk<
ResponseSingleResult,
{ customerId: number, id: number, comment: string, onCancel: VoidFunctionType }
>('patch/CustomerAttachment', async ({
  customerId, id, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { customers: { customerAttachments } } = getState() as RootState;
    const response = await customersAPI.editCustomerAttachment(customerId, id, comment);
    const newArr = customerAttachments.items.map((item: CoreAttachmentDto) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setCustomerAttachments(newArr));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteCustomerAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, customerId: number, closeModal: VoidFunctionType, }>(
  'delete/SingleCustomerAttachment',
  async ({
    id, customerId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.deleteCustomerAttachment(customerId, id);
      dispatch(getCustomerAttachments({
        customerId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setCustomerAttachmentsFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getCustomerById({ id: customerId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteMultipleCustomerAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  customerId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleCustomerAttachments',
  async ({
    ids, customerId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { customers: { customerAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await customersAPI.fetchCustomerAttachments(customerId, {
        ...customerAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await customersAPI.deleteMultipleCustomerAttachment(customerId, filteredIds);
      }
      dispatch(setCustomerAttachmentsFilters({ ...customerAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getCustomerById({ id: customerId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
