import { useAppDispatch } from '../../../store/hooks';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { setPricelistFilters } from '../../../store/slices/pricelistSlice';
import { setPurchaseOrdersFilters } from '../../../store/slices/purchaseOrderSlice';
import { PricelistDashFiltersType } from '../../../pricelists/dashboard/types/pricelistTypes';
import { PurchaseOrderDashFiltersType } from '../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';

type FiltersOptions = PurchaseOrderDashFiltersType | PricelistDashFiltersType;

export const useSupplierCardClick = () => {
  const dispatch = useAppDispatch();
  const cardClickHandle = (permission: PermissionEnum, filters: FiltersOptions) => {
    switch (permission) {
      case PermissionEnum.StockPurchaseOrders:
        dispatch(setPurchaseOrdersFilters(filters as PurchaseOrderDashFiltersType));
        break;
      case PermissionEnum.PricelistsDashboardPage:
        dispatch(setPricelistFilters(filters));
        break;
      default: break;
    }
  };
  return { cardClickHandle };
};
