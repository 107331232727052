import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import OrdersViewPageContainer from './OrdersViewPageContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { orderDetailsSelector } from '../../store/selectors/sharedSelectors';
import { allowedBaForUserSelector } from '../../store/selectors/coreSelectors';
import { getOrderById, getOrderLinksThunk, getOrderSummaryThunk } from '../../store/thunks/orders/viewPageOrderThunks';
import Spinner from '../../core/components/spinner/Spinner';
import ErrorPage from '../../common/components/error-pages/ErrorPage';
import { orderLinksFiltersSelector } from '../../store/selectors/ordersSelector';
import { OrderViewPageEnums } from './enums/orderViewPageEnums';
import { getOrderEventSources } from '../../store/thunks/coreThunk';

const OrderViewPageWrapper: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const orderDetails = useAppSelector(orderDetailsSelector);
  const allowedBa = useAppSelector(allowedBaForUserSelector);
  const orderLinksFilters = useAppSelector(orderLinksFiltersSelector);

  useEffect(() => {
    if (id) {
      dispatch(getOrderSummaryThunk({ id: +id }));
      dispatch(getOrderById({ id: +id }));
    }
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    dispatch(getOrderEventSources());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const modeParam = params.get('mode');
    const isLinksTab = modeParam === OrderViewPageEnums.ORDER_LINKS;
    if (id && isLinksTab) {
      dispatch(getOrderLinksThunk({ id: +id, filters: orderLinksFilters }));
    }
    // eslint-disable-next-line
  }, [id, orderLinksFilters, params]);

  if (!orderDetails) {
    return <Spinner />;
  }
  if (!allowedBa.find((el) => el.value === orderDetails.order.businessArea)) {
    return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
  }

  return (
    <OrdersViewPageContainer />
  );
};

export default OrderViewPageWrapper;
