import React, { useState } from 'react';
import { faUserCheck, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DropdownActionType } from '../../../common/types/commonTypes';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import {
  employeeDetailsSelector,
  employeesStatusArchivedSelector,
  employeesStatusCurrentSelector,
} from '../../../store/selectors/employeesSelector';

export const useEmployeeViewActions = (handleStatusChange: NumberFunctionType, handleDelete: VoidFunctionType) => {
  const currentStatus = useAppSelector(employeesStatusCurrentSelector);
  const archivedStatus = useAppSelector(employeesStatusArchivedSelector);
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const [allowedActions, setAllowedActions] = useState<DropdownActionType[]>([]);

  const actions = [
    {
      label: 'Mark as Current',
      key: 'mark-as-current',
      icon: <FontAwesomeIcon icon={faUserCheck} />,
      onClick: () => {
        currentStatus && handleStatusChange(currentStatus);
      },
      restrictedTo: PermissionEnum.EmployeeEditFields,
      disabled: employeeDetails?.employee.status === currentStatus,
    },
    {
      label: 'Mark as Archived',
      key: 'mark-as-archived',
      icon: <FontAwesomeIcon icon={faUserSlash} />,
      onClick: () => {
        archivedStatus && handleStatusChange(archivedStatus);
      },
      restrictedTo: PermissionEnum.EmployeeEditFields,
      disabled: employeeDetails?.employee.status === archivedStatus,
    },
    {
      label: 'Delete employee',
      key: 'delete-employee',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: handleDelete,
      restrictedTo: PermissionEnum.EmployeeDelete,
    },
  ];

  return {
    actions,
    allowedActions,
    setAllowedActions,
  };
};
