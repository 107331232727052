import React from 'react';

type SkeletonLinesListProps = {
  children: React.ReactNode,
  linesCount?: number,
}
const SkeletonLinesList: React.FC<SkeletonLinesListProps> = ({ children, linesCount = 10 }) => {
  return (
    <div className="skeleton-shimmer">
      {Array(linesCount)
        .fill(null)
        .map((_, i) => <React.Fragment key={i}>{children}</React.Fragment>)}
    </div>
  );
};

export default SkeletonLinesList;
