import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import InputWithButton from '../../../../core/components/input-with-button/InputWithButton';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import Pagination from '../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import {
  DateRangeTableFilterType, initSmallPaging, PickerValues,
} from '../../../../core/types/coreTypes';
import { InvoiceDocumentsFilters } from '../../../invoicing-purchases/types/InvoicingPurchasesTypes';
import { setInvoiceDocumentsFilters } from '../../../../store/slices/invoicingSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  invoiceDocumentsFiltersSelector, invoiceDocumentsSelector, salesSpecificEntitiesCheckboxFiltersSelector,
  specificEntitiesCheckboxFiltersSelector, specificEntitiesDateFiltersSelector,
} from '../../../../store/selectors/invoicingSelectors';

type SpecificEntitiesFiltersProps = {
  type: 'purchases' | 'sales',
}

const SpecificEntitiesFilters: React.FC<SpecificEntitiesFiltersProps> = ({ type }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(invoiceDocumentsFiltersSelector);
  const { totalCount } = useAppSelector(invoiceDocumentsSelector);
  const salesCheckboxFilters = useAppSelector(salesSpecificEntitiesCheckboxFiltersSelector);
  const purchasesCheckboxFilters = useAppSelector(specificEntitiesCheckboxFiltersSelector);
  const dateFilters = useAppSelector(specificEntitiesDateFiltersSelector);
  const [searchValue, setSearchValue] = useState('');
  const [keywordError, setKeywordError] = useState('');

  const initDropdownSearch = type === 'sales'
    ? { customerIds: '' }
    : { supplierIds: '' };

  const filtersHandle = (newFilters: InvoiceDocumentsFilters) => {
    dispatch(setInvoiceDocumentsFilters(newFilters));
  };

  const initialPickerValue: DateRangeTableFilterType = {
    invoiceDate: [null, null],
    dueDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values,
      page: 1,
    };
    filtersHandle(newFilters);
  };

  const onClear = () => {
    setPickerValue(initialPickerValue);
    filtersHandle({
      ...initSmallPaging,
      operationTypes: filters.operationTypes,
      pageSize: filters.pageSize,
      order: filters.order,
    });
  };

  return (
    <>
      <KeywordFiltersSection
        getFilteredResHandle={(values, field) => filtersHandle({ ...filters, [field]: values })}
        onClear={onClear}
        initDropdownSearch={initDropdownSearch}
        filters={type === 'sales' ? salesCheckboxFilters : purchasesCheckboxFilters}
        dateFilters={dateFilters}
        isSearch={false}
        isFullWidth
        pickerHandle={pickerHandle}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        offsetBottom
      />
      <InputWithButton
        value={searchValue}
        onChange={(e) => {
          const val = e.target.value;
          if (val.trim()) {
            setSearchValue(val);
            if (val.length <= 250) {
              keywordError && setKeywordError('');
            } else {
              setKeywordError(maxLengthMessageHandle(250));
            }
          } else {
            setSearchValue(val);
            filtersHandle({ ...filters, keyword: undefined });
          }
        }}
        onPressEnter={() => {
          !keywordError && filtersHandle({ ...filters, keyword: searchValue.trim() || undefined });
        }}
        onClick={() => !keywordError && filtersHandle({ ...filters, keyword: searchValue.trim() || undefined })}
        icon={<FontAwesomeIcon icon={faSearch} />}
        error={keywordError}
        placeholder={`Search by ${type === 'sales' ? 'order' : 'purchase order'} number or entity number`}
        offsetBottom
      />
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        totalItems={totalCount}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => filtersHandle({ ...filters, page, pageSize })}
        offsetBottom
      />
    </>
  );
};

export default SpecificEntitiesFilters;
