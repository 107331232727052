import React from 'react';
import {
  AdvancedMarker, InfoWindow, Pin, useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { BooleanFunctionType } from '../../../../../../core/types/coreTypes';
import { DARK_BLUE_COLOR, YELLOW_COLOR } from '../utils/data';

type VanlinersMarkerProps = {
  isSelected: boolean,
  setIsSelected: BooleanFunctionType,
  lat: number,
  lng: number,
}

const VanlinersMarker: React.FC<VanlinersMarkerProps> = ({
  isSelected, setIsSelected, lat, lng,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        position={{ lat, lng }}
        onClick={() => setIsSelected(true)}
      >
        <Pin
          background={DARK_BLUE_COLOR}
          borderColor={YELLOW_COLOR}
          glyphColor={YELLOW_COLOR}
          scale={1}
        />
      </AdvancedMarker>
      {isSelected && (
        <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => setIsSelected(false)}
        >
          <span>Unit 3, Ormside Close, Hindley Green, Wigan, WN2 4HR</span>
        </InfoWindow>
      )}
    </>
  );
};

export default VanlinersMarker;
