import React from 'react';
import classNames from 'classnames';
import './TermsOfUseLink.scss';

type TermsOfUseLinkProps = {
  className?: string;
}

const TermsOfUseLink: React.FC<TermsOfUseLinkProps> = ({ className }) => {
  return <a
    href="https://www.vanliners.co.uk/terms/"
    target="_blank"
    className={classNames('termsOfUseLink', className)}
    rel="noreferrer"
  >
    Terms of use
  </a>;
};

export default TermsOfUseLink;
