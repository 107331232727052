import React from 'react';
import classNames from 'classnames';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Tag from '../../../core/components/tag/Tag';

type VehicleInfoTagProps = {
  value: string,
  shrink?: boolean,
}

const VehicleInfoTag: React.FC<VehicleInfoTagProps> = ({ value, shrink }) => {
  return (
    <Tag
      light
      textSize="md"
      label={<TruncateTooltip
        value={value}
        className="vehicle-section__specific-tag-content"
        overlayClassName="truncate-table-tooltip"
      />}
      className={classNames('vehicle-section__specific-tag', { 'vehicle-section__specific-tag--shrink': shrink })}
    />
  );
};

export default VehicleInfoTag;
