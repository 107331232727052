import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { supplierSpendColumnsSelector, supplierSpendDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import SupplierSpendColumns from './SupplierSpendColumns';
import SupplierSpendBody from './SupplierSpendBody';
import './SupplierSpendTable.scss';

const SupplierSpendTable: React.FC = () => {
  const { totalCount } = useAppSelector(supplierSpendDataSelector);
  const columns = useAppSelector(supplierSpendColumnsSelector);

  return (
    <TableNew>
      <SupplierSpendColumns columns={columns} totalCount={totalCount} />
      <SupplierSpendBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default SupplierSpendTable;
