import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import AddInfoMessageModal from './AddInfoMessageModal';
import Restricted from '../../../permissions-handling/Restricted';
import Button from '../../../core/components/button/Button';
import Pagination from '../../../core/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  infoMessagesListFiltersSelector,
  infoMessagesListSelector,
  infoMessagesMaxDisplayOrderSelector,
} from '../../../store/selectors/settingsSelectors';
import { setInfoMessagesListFilters } from '../../../store/slices/settingsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../core/utils/regex';

const InfoMessagesListActions: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isAdd, setAdd] = useState(false);
  const infoMessagesList = useAppSelector(infoMessagesListSelector);
  const infoMessagesListFilters = useAppSelector(infoMessagesListFiltersSelector);
  const infoMessageMaxDisplayOrder = useAppSelector(infoMessagesMaxDisplayOrderSelector);

  const getPaginatedList = (page: number, pageSize: number) => {
    dispatch(setInfoMessagesListFilters({ ...infoMessagesListFilters, page, pageSize }));
  };

  return (
    <>
      <AddInfoMessageModal
        isVisible={isAdd}
        onClose={() => setAdd(false)}
        maxDisplayOrder={infoMessageMaxDisplayOrder + 1}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Restricted to={PermissionEnum.SettingsInfoMessagePage}>
            <Button
              label="Add message"
              onClick={() => setAdd(true)}
              htmlType="button"
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              designType="dark"
              reversed
            />
          </Restricted>
        </div>
        {infoMessagesList.totalCount > 0 && <Pagination
          currentPage={infoMessagesListFilters.page}
          pageSize={infoMessagesListFilters.pageSize}
          onChange={getPaginatedList}
          totalItems={infoMessagesList.totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />}
      </div>
    </>
  );
};

export default InfoMessagesListActions;
