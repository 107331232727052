import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Id, toast } from 'react-toastify';
import { ReactNode } from 'react';
import {
  getBusinessAreas,
  getCustomerAccountTypes,
  getCustomerTypes,
  getEnquirySources,
  getJobTypes,
  getPriorityLevels,
  getQuoteLinesTaxes,
  getEnquiryWarningsThunk,
  getAccountAccountTypes,
  getAccountAccountRoles,
  getAccountPermissionRoles,
  getPricelistTypes,
  getQuoteUnitsOfMeasure,
  getQuoteInfoSources,
  getJobCategories,
  getAttachmentsTypes,
  getLinksTypesThunk,
  getInAppNotificationsEntityTypes,
  getInAppNotificationsTypes,
  getPartRequestLinePriceSources,
  getRectificationFaultTypesThunk,
  getVehicleProfileTypesThunk,
  getVehicleFlowsThunk,
  getPartRequestLineTypes,
  getLeaveRequestTypesThunk,
  getDayPartsThunk,
  getVehicleCheckTypesThunk,
  getFullVehicleCheckDecisionsThunk,
  getHandoverVehicleCheckDecisionsThunk,
  getVehicleTransportationMethodsThunk,
  getWeatherConditionsThunk,
  getJobWarningsThunk,
  getOrderWarningsThunk,
  getRectificationWarningsThunk,
  getNominalCodeTypesThunk,
  getUnfinishedEntityType,
  getUsersListHadAJob,
  getFitterReturnsReportEntityType,
  getNominalRecordStates,
  getPurchaseOrderEventSources,
  getNominalRecordTypes,
  getInvoiceDocumentTypesThunk,
  getInvoiceDocumentOperationTypesThunk,
  getInvoiceDocumentStatusTransitionsThunk,
  getUsersListCoreDictionary,
  getVehicleSlidingDoorsQuantities,
  getVehicleInfoSources,
  getQuoteLinePriceSources,
  getRectificationTypesThunk,
  getOrderEventSources,
  getSalesByCustomerEntityTypesThunk,
  getSalesOffsiteEntityTypesThunk,
  getSuitableVehicleSourcesThunk,
  getVanlinersOfficeLocation,
  getPartRequestLineActivityTypesThunk,
  getNominalRecordVatTypesThunk,
  getCancelledLinesReportEntityTypesThunk,
  getPurchaseOrderPartAmountInputTypesThunk, getGoogleApiKey,
} from '../thunks/coreThunk';
import {
  AssigneeDto,
  CustomDictionaryItem,
  DictionaryItem, DictionaryResponse,
  LocationStateType,
  ResponseSingleResult, ServerErrorStatusCode,
} from '../../core/types/coreTypes';
import { UserAccountRoleDto } from '../../settings/users-dashboard/types/settingsTypes';
import { storage } from '../../common/utils/storage';
import { DefaultAddressDto } from '../../common/types/commonTypes';

interface ICoreState {
  currentServerError?: ServerErrorStatusCode,
  authToken: string | null,
  isAuth: boolean,
  isLoading: boolean,
  isFetching: boolean,
  isDashboardLoading: boolean,
  collapsedSidebar: boolean,
  vanlinersLocation: DefaultAddressDto | null,
  customerAccountTypes: DictionaryItem[],
  customerTypes: DictionaryItem[],
  jobTypes: CustomDictionaryItem[],
  priorityLevels: DictionaryItem[],
  enquirySources: DictionaryItem[],
  enquiryWarnings: DictionaryItem[],
  orderWarnings: DictionaryItem[],
  rectificationWarnings: DictionaryItem[],
  jobWarnings: DictionaryItem[],
  businessAreas: CustomDictionaryItem[],
  quoteLineTaxes: DictionaryItem[],
  currentBusinessArea?: number,
  accountAccountTypes: DictionaryItem[],
  accountAccountRoles: DictionaryItem[],
  permissionRoles: UserAccountRoleDto[],
  pricelistTypes: DictionaryItem[],
  quoteInfoSources: DictionaryItem[],
  quoteUnitsOfMeasure: DictionaryItem[],
  locationHistory: LocationStateType | null,
  jobCategories: DictionaryItem[],
  usersLookup: AssigneeDto[],
  usersListHadAJob: AssigneeDto[],
  attachmentsTypes: DictionaryItem[],
  linksTypes: DictionaryItem[],
  inAppNotificationsEntityTypes: DictionaryItem[],
  inAppNotificationsTypes: DictionaryItem[],
  partRequestLinePriceSources: DictionaryItem[],
  rectificationFaultTypes: DictionaryItem[],
  vehicleProfilesTypes: DictionaryItem[],
  vehicleFlows: DictionaryItem[],
  vehicleCheckTypes: DictionaryItem[],
  fullVehicleCheckDecisions: DictionaryItem[],
  handoverVehicleCheckDecisions: DictionaryItem[],
  partRequestLineTypes: DictionaryItem[],
  leaveRequestTypes: DictionaryItem[],
  dayParts: DictionaryItem[],
  vehicleTransportationMethods: DictionaryItem[],
  weatherConditions: DictionaryItem[],
  nominalCodeTypes: DictionaryItem[],
  unfinishedEntityType: DictionaryItem[],
  fitterReturnsEntityType: DictionaryItem[],
  salesByCustomerEntityTypes: DictionaryItem[],
  salesOffsiteEntityTypes: DictionaryItem[],
  nominalRecordStates: DictionaryItem[],
  nominalRecordTypes: DictionaryItem[],
  purchaseOrderEventSources: DictionaryItem[],
  invoiceDocumentTypes: DictionaryItem[],
  invoiceDocumentOperationTypes: DictionaryItem[],
  invoiceDocumentStatusTransitions: DictionaryItem[],
  vehicleSlidingDoorsQuantities: DictionaryItem[],
  vehicleInfoSources: DictionaryItem[],
  quoteLinePriceSources: DictionaryItem[],
  rectificationTypes: DictionaryItem[],
  orderEventSources: DictionaryItem[],
  suitableVehicleSources: DictionaryItem[],
  partRequestLineActivityTypes: DictionaryItem[],
  cancelledLinesReportEntityTypes: DictionaryItem[],
  nominalRecordVatTypes: DictionaryItem[],
  purchaseOrderPartAmountInputTypes: DictionaryItem[],
  googleApiKey: string,
}

export const initialCoreState: ICoreState = {
  currentServerError: undefined,
  authToken: storage.getAuthToken() || null,
  isAuth: false,
  isLoading: false,
  isFetching: false,
  isDashboardLoading: true,
  collapsedSidebar: false,
  vanlinersLocation: null,
  customerAccountTypes: [],
  customerTypes: [],
  jobTypes: [],
  priorityLevels: [],
  enquirySources: [],
  enquiryWarnings: [],
  jobWarnings: [],
  orderWarnings: [],
  rectificationWarnings: [],
  businessAreas: [],
  quoteLineTaxes: [],
  currentBusinessArea: undefined,
  accountAccountTypes: [],
  accountAccountRoles: [],
  permissionRoles: [],
  quoteInfoSources: [],
  quoteUnitsOfMeasure: [],
  pricelistTypes: [],
  locationHistory: null,
  jobCategories: [],
  usersLookup: [],
  usersListHadAJob: [],
  attachmentsTypes: [],
  linksTypes: [],
  inAppNotificationsEntityTypes: [],
  inAppNotificationsTypes: [],
  partRequestLinePriceSources: [],
  rectificationFaultTypes: [],
  vehicleProfilesTypes: [],
  vehicleFlows: [],
  vehicleCheckTypes: [],
  fullVehicleCheckDecisions: [],
  handoverVehicleCheckDecisions: [],
  partRequestLineTypes: [],
  leaveRequestTypes: [],
  dayParts: [],
  vehicleTransportationMethods: [],
  weatherConditions: [],
  nominalCodeTypes: [],
  unfinishedEntityType: [],
  fitterReturnsEntityType: [],
  salesByCustomerEntityTypes: [],
  salesOffsiteEntityTypes: [],
  nominalRecordStates: [],
  nominalRecordTypes: [],
  purchaseOrderEventSources: [],
  invoiceDocumentTypes: [],
  invoiceDocumentOperationTypes: [],
  invoiceDocumentStatusTransitions: [],
  vehicleSlidingDoorsQuantities: [],
  vehicleInfoSources: [],
  quoteLinePriceSources: [],
  rectificationTypes: [],
  orderEventSources: [],
  suitableVehicleSources: [],
  partRequestLineActivityTypes: [],
  cancelledLinesReportEntityTypes: [],
  nominalRecordVatTypes: [],
  purchaseOrderPartAmountInputTypes: [],
  googleApiKey: '',
};

export const coreSlice = createSlice({
  name: 'core',
  initialState: initialCoreState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string | null>) => {
      state.authToken = action.payload;
    },
    setCurrentServerError: (state, action: PayloadAction<ServerErrorStatusCode | undefined>) => {
      state.currentServerError = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    setDashboardLoading: (state, action: PayloadAction<boolean>) => {
      state.isDashboardLoading = action.payload;
    },
    setCollapsedSidebar: (state, action: PayloadAction<boolean>) => {
      state.collapsedSidebar = action.payload;
    },
    setCurrentArea: (state, action: PayloadAction<number | undefined>) => {
      state.currentBusinessArea = action.payload;
    },
    setPermissionRoles: (state, action: PayloadAction<UserAccountRoleDto[]>) => {
      state.permissionRoles = action.payload;
    },
    setLocationHistory: (state, action: PayloadAction<LocationStateType | null>) => {
      state.locationHistory = action.payload;
    },
    setSuccessMessage: (state, action: PayloadAction<{ message: string, toastId?: Id }>) => {
      toast.success(action.payload.message, { toastId: action.payload.toastId });
    },
    setErrorMessage: (state, action: PayloadAction<{ message: string | ReactNode, toastId?: Id }>) => {
      toast.error(action.payload.message, { toastId: action.payload.toastId });
    },
    setWarningMessage: (state, action: PayloadAction<{ message: string, toastId?: Id }>) => {
      toast.warning(action.payload.message, { toastId: action.payload.toastId });
    },
  },
  // Later, dispatch the thunk as needed in the app
  extraReducers: (builder) => {
    builder.addCase(getVanlinersOfficeLocation.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{defaultAddress: DefaultAddressDto }>>,
    ) => {
      state.vanlinersLocation = action.payload.data.defaultAddress;
    });
    builder.addCase(getCustomerTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.customerTypes = action.payload.data.items;
    });
    builder.addCase(getJobTypes.fulfilled, (state, action: PayloadAction<ResponseSingleResult<{ items: CustomDictionaryItem[] }>>) => {
      state.jobTypes = action.payload.data.items;
    });
    builder.addCase(getPriorityLevels.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.priorityLevels = action.payload.data.items;
    });
    builder.addCase(getEnquirySources.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquirySources = action.payload.data.items;
    });
    builder.addCase(getEnquiryWarningsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquiryWarnings = action.payload.data.items;
    });
    builder.addCase(getJobWarningsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.jobWarnings = action.payload.data.items;
    });
    builder.addCase(getOrderWarningsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.orderWarnings = action.payload.data.items;
    });
    builder.addCase(getRectificationWarningsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationWarnings = action.payload.data.items;
    });
    builder.addCase(getBusinessAreas.fulfilled, (state, action: PayloadAction<ResponseSingleResult<{ items: CustomDictionaryItem[] }>>) => {
      state.businessAreas = action.payload.data.items;
    });
    builder.addCase(getQuoteLinesTaxes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.quoteLineTaxes = action.payload.data.items;
    });
    builder.addCase(getCustomerAccountTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.customerAccountTypes = action.payload.data.items;
    });
    builder.addCase(getAccountAccountTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.accountAccountTypes = action.payload.data.items;
    });
    builder.addCase(getAccountAccountRoles.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.accountAccountRoles = action.payload.data.items;
    });
    builder.addCase(getAccountPermissionRoles.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ items: UserAccountRoleDto[] }>>,
    ) => {
      state.permissionRoles = action.payload.data.items;
    });
    builder.addCase(getPricelistTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistTypes = action.payload.data.items;
    });
    builder.addCase(getQuoteUnitsOfMeasure.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.quoteUnitsOfMeasure = action.payload.data.items;
    });
    builder.addCase(getQuoteInfoSources.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.quoteInfoSources = action.payload.data.items;
    });
    builder.addCase(getUsersListCoreDictionary.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ userAccounts: AssigneeDto[] }>>,
    ) => {
      state.usersLookup = action.payload.data.userAccounts;
    });
    builder.addCase(getUsersListHadAJob.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ userAccounts: AssigneeDto[] }>>,
    ) => {
      state.usersListHadAJob = action.payload.data.userAccounts;
    });
    builder.addCase(getJobCategories.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.jobCategories = action.payload.data.items;
    });
    builder.addCase(getAttachmentsTypes.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.attachmentsTypes = action.payload.data.items;
    });
    builder.addCase(getLinksTypesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.linksTypes = action.payload.data.items;
    });
    builder.addCase(getInAppNotificationsEntityTypes.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.inAppNotificationsEntityTypes = action.payload.data.items;
    });
    builder.addCase(getInAppNotificationsTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.inAppNotificationsTypes = action.payload.data.items;
    });
    builder.addCase(
      getPartRequestLinePriceSources.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.partRequestLinePriceSources = action.payload.data.items;
      },
    );
    builder.addCase(getRectificationFaultTypesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.rectificationFaultTypes = action.payload.data.items;
    });
    builder.addCase(getVehicleProfileTypesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.vehicleProfilesTypes = action.payload.data.items;
    });
    builder.addCase(getVehicleFlowsThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.vehicleFlows = action.payload.data.items;
    });
    builder.addCase(getVehicleCheckTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleCheckTypes = action.payload.data.items;
    });
    builder.addCase(getFullVehicleCheckDecisionsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.fullVehicleCheckDecisions = action.payload.data.items;
    });
    builder.addCase(getHandoverVehicleCheckDecisionsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.handoverVehicleCheckDecisions = action.payload.data.items;
    });
    builder.addCase(getVehicleTransportationMethodsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleTransportationMethods = action.payload.data.items;
    });
    builder.addCase(getWeatherConditionsThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.weatherConditions = action.payload.data.items;
    });
    builder.addCase(
      getPartRequestLineTypes.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.partRequestLineTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getLeaveRequestTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.leaveRequestTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getDayPartsThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.dayParts = action.payload.data.items;
      },
    );
    builder.addCase(
      getNominalCodeTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.nominalCodeTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getUnfinishedEntityType.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.unfinishedEntityType = action.payload.data.items;
      },
    );
    builder.addCase(
      getFitterReturnsReportEntityType.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.fitterReturnsEntityType = action.payload.data.items;
      },
    );
    builder.addCase(
      getSalesByCustomerEntityTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.salesByCustomerEntityTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getSalesOffsiteEntityTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.salesOffsiteEntityTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getNominalRecordStates.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.nominalRecordStates = action.payload.data.items;
      },
    );
    builder.addCase(
      getNominalRecordTypes.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.nominalRecordTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getPurchaseOrderEventSources.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.purchaseOrderEventSources = action.payload.data.items;
      },
    );
    builder.addCase(
      getInvoiceDocumentTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.invoiceDocumentTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getInvoiceDocumentOperationTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.invoiceDocumentOperationTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getInvoiceDocumentStatusTransitionsThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.invoiceDocumentStatusTransitions = action.payload.data.items;
      },
    );
    builder.addCase(
      getVehicleSlidingDoorsQuantities.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.vehicleSlidingDoorsQuantities = action.payload.data.items;
      },
    );
    builder.addCase(
      getVehicleInfoSources.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.vehicleInfoSources = action.payload.data.items;
      },
    );
    builder.addCase(
      getQuoteLinePriceSources.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.quoteLinePriceSources = action.payload.data.items;
      },
    );
    builder.addCase(
      getRectificationTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.rectificationTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getOrderEventSources.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.orderEventSources = action.payload.data.items;
      },
    );
    builder.addCase(
      getSuitableVehicleSourcesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.suitableVehicleSources = action.payload.data.items;
      },
    );
    builder.addCase(
      getPartRequestLineActivityTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.partRequestLineActivityTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getCancelledLinesReportEntityTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.cancelledLinesReportEntityTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getNominalRecordVatTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.nominalRecordVatTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getPurchaseOrderPartAmountInputTypesThunk.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.purchaseOrderPartAmountInputTypes = action.payload.data.items;
      },
    );
    builder.addCase(
      getGoogleApiKey.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ key: string }>>) => {
        state.googleApiKey = action.payload.data.key;
      },
    );
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setCurrentServerError,
  setAuthToken,
  setLoading,
  setFetching,
  setDashboardLoading,
  setCurrentArea,
  setCollapsedSidebar,
  setIsAuth,
  setPermissionRoles,
  setLocationHistory,
  setSuccessMessage,
  setErrorMessage,
  setWarningMessage,
} = coreSlice.actions;

export default coreSlice.reducer;
