import { CustomDictionaryItem, DictionaryItem } from '../types/coreTypes';

export const setUiNamesHandler = <T extends object, >(array: DictionaryItem[], enumValue: T): Array<DictionaryItem> => {
  return array.map((area) => {
    const newLabel = Object.keys(enumValue).find((key) => key === area.label);
    if (newLabel) {
      return { label: enumValue[newLabel as keyof object], value: area.value };
    } else return area;
  });
};

export const setUiNamesCustomHandler = <T extends object, >(array: CustomDictionaryItem[], enumValue: T) => {
  return array.map((area) => {
    const newLabel = Object.keys(enumValue).find((key) => key === area.label);
    if (newLabel) {
      return { label: enumValue[newLabel as keyof T], value: area.value, disabled: !area.isAllowed };
    } else return area;
  });
};
