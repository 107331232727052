import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  fitmentTimeReportFiltersSelector, fitmentTimeReportGlobalFiltersDateSelector, fitmentTimeReportGlobalFiltersSelector, isTableViewSelector,
} from '../../../../../store/selectors/reportsSelectors';
import {
  DateRangeTableFilterType, initDataList, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import {
  getFitmentTimeReportChartThunk,
  getFitmentTimeReportExcelThunk,
  getFitmentTimeReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import {
  setFitmentTimeData,
  setFitmentTimeDataChart,
  setFitmentTimeFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { useFitmentTimeReportDefaultOrdering } from '../../../../hooks/useFitmentTimeReportDefaultOrdering';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { initFitmentTimeData } from '../../../reports-content/production/fitment-time/utils/data';
import { getUsersListHadAJob } from '../../../../../store/thunks/coreThunk';
import { FitmentTimeReportFilters } from '../../../../types/FitmentTimeReportTypes';

const FitmentTimeGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const isTableView = useAppSelector(isTableViewSelector);
  const filters = useAppSelector(fitmentTimeReportFiltersSelector);
  const checkboxFilters = useAppSelector(fitmentTimeReportGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(fitmentTimeReportGlobalFiltersDateSelector);

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        keyword, customerIds, jobCategories, jobStatuses, assigneeIds, jobTypes, creationDate,
      } = filters;
      const hasSelectedCheckboxes = [customerIds, jobCategories, jobStatuses, assigneeIds, jobTypes].some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = creationDate && (creationDate.min || creationDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker || !!keyword?.trim().length;
    }
    return false;
  }, [filters]);

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { creationDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const defaultPagingAndOrdering = useFitmentTimeReportDefaultOrdering();

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getUsersListHadAJob({ keyword: undefined }));

    return () => {
      dispatch(setFitmentTimeFilters({ ...initPaging, ...defaultPagingAndOrdering }));
      dispatch(setFitmentTimeData(initFitmentTimeData));
    };
    // eslint-disable-next-line
  }, []);

  const resetTableFilters = {
    spentByAssigneeMinutes: undefined,
    totalSpentMinutes: undefined,
    totalEstimatedMinutes: undefined,
    differenceMinutes: undefined,
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: FitmentTimeReportFilters = {
      ...filters,
      ...resetTableFilters,
      [field]: values,
    };
    dispatch(setFitmentTimeFilters(newFilters));
    dispatch(setFitmentTimeData(initFitmentTimeData));
    setFitmentTimeDataChart(initDataList);
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setFitmentTimeFilters({ ...defaultPagingAndOrdering, pageSize: filters.pageSize }));
    dispatch(setFitmentTimeData(initFitmentTimeData));
    dispatch(setFitmentTimeDataChart(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setFitmentTimeFilters({
      ...filters,
      ...resetTableFilters,
      [field]: values,
    }));
    dispatch(setFitmentTimeData(initFitmentTimeData));
    dispatch(setFitmentTimeDataChart(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const setSearchValue = (value: string) => {
    dispatch(setFitmentTimeFilters({ ...filters, keyword: value }));
  };
  const pressEnterHandle = () => {
    if (!filters.keyword?.trim().length) {
      setSearchValue('');
    } else {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        differenceMinutes, totalSpentMinutes, totalEstimatedMinutes, spentByAssigneeMinutes, ...rest
      } = filters;
      isTableView
        ? dispatch(getFitmentTimeReportThunk({ filters }))
        : dispatch(getFitmentTimeReportChartThunk({ filters: rest }));
      dispatch(setReportIsVisible(true));
    }
  };

  const getReport = () => {
    if (isTableView) {
      dispatch(getFitmentTimeReportThunk({ filters }));
    } else {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        order, differenceMinutes, totalSpentMinutes, totalEstimatedMinutes, spentByAssigneeMinutes, ...rest
      } = filters;
      dispatch(getFitmentTimeReportChartThunk({ filters: { ...rest } }));
    }
    dispatch(setReportIsVisible(true));
  };

  const exportToExcel = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      spentByAssigneeMinutes, totalEstimatedMinutes, totalSpentMinutes, differenceMinutes, page, pageSize, ...restFilters
    } = filters;
    const { order } = defaultPagingAndOrdering;
    dispatch(getFitmentTimeReportExcelThunk({
      filters: {
        ...restFilters,
        order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch
        clearSearchValue={false}
        searchValue={filters.keyword}
        setSearchValue={setSearchValue}
        onPressEnter={pressEnterHandle}
        placeholder="Enter a job number"
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={getReport}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default FitmentTimeGlobalFilters;
