import { useEffect, useState } from 'react';
import { RectificationReportOrdering } from '../enum/ReportTableOrders';
import { setRectificationReportFilters } from '../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { rectificationReportFiltersSelector } from '../../store/selectors/reportsSelectors';
import { rectificationReportsOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getRectificationReportOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { initPaging } from '../../core/types/coreTypes';

export const useRectificationReportDefaultPaging = (shouldFetch = true) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(rectificationReportFiltersSelector);
  const ordering = useAppSelector(rectificationReportsOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    ordering.length === 0 && shouldFetch && dispatch(getRectificationReportOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === RectificationReportOrdering.CompletedDate)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setRectificationReportFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: true } : filters.order,
  };
};
