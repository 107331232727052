import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { jobPartsFiltersSelector } from '../../../../../store/selectors/jobsSelector';
import { setJobPartsFilters } from '../../../../../store/slices/jobsSlice';
import { SearchTableFilterType } from '../../../../../core/types/coreTypes';
import { ICustomColumn } from '../../../../../common/types/commonTypes';

type JobPartsColumnsProps = {
  columns: ICustomColumn[],
  totalElements: number,
}

const JobPartsColumns: React.FC<JobPartsColumnsProps> = ({ columns, totalElements }) => {
  const dispatch = useAppDispatch();
  const jobPartsFilters = useAppSelector(jobPartsFiltersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productName: jobPartsFilters?.productName,
    productNumber: jobPartsFilters?.productNumber,
    notes: jobPartsFilters?.notes,
  });

  const filters = [
    {
      dataIndex: 'categoryId',
      value: jobPartsFilters?.categories || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setJobPartsFilters({
        ...jobPartsFilters,
        categories: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'status',
      value: jobPartsFilters?.statuses || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setJobPartsFilters({
        ...jobPartsFilters,
        statuses: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (field === jobPartsFilters?.order?.field) {
      dispatch(setJobPartsFilters({
        ...jobPartsFilters,
        order: { ...jobPartsFilters.order, isAsc: !jobPartsFilters.order.isAsc },
      }));
    } else {
      dispatch(setJobPartsFilters({
        ...jobPartsFilters,
        order: { field, isAsc: true },
      }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setJobPartsFilters({
        ...jobPartsFilters,
        [field]: undefined,
      }));
    }
  };

  const searchPressHandle = (field: string) => {
    dispatch(setJobPartsFilters({
      ...jobPartsFilters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            filters={filters}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            totalElements={totalElements}
            tableFilters={jobPartsFilters}
            className={`jobPartsTh--${col.dataIndex}`}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default JobPartsColumns;
