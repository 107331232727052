import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import RegularInputNumber from '../../../../../core/components/input-number/RegularInputNumber';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT, maxCommonDecimal } from '../../../../../core/utils/regex';
import DatePickerComponent from '../../../../../core/components/date-picker/DatePicker';
import Button from '../../../../../core/components/button/Button';
import { useAppSelector } from '../../../../../store/hooks';
import { stockPartDetailsSelector } from '../../../../../store/selectors/stockSelectors';
import { integerQuantityMeasureValuesSelector, isFetchingSelector } from '../../../../../store/selectors/coreSelectors';
import { useAdjustQtyFooterActions } from './useAdjustQtyFooterActions';

type AdjustQtyFooterActionsProps = {
  currentStatus?: number,
}

const AdjustQtyFooterActions = ({ currentStatus }: AdjustQtyFooterActionsProps) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);

  const {
    fields, setFieldsHandle, addQtyHandle, disabledAddAction,
  } = useAdjustQtyFooterActions(currentStatus);

  return (
    <div className="adjustQty__addSection">
      {integerMeasures.includes(partDetails?.part.unitOfMeasure)
        ? <RegularInputNumber
          label="Quantity"
          value={fields.quantity}
          onChange={(e) => {
            const v = e.target.value;
            setFieldsHandle('quantity', v);
          }}
          max={999}
        />
        : <DecimalInputNumber
          label="Quantity"
          value={fields.quantity}
          onChange={(e) => {
            const v = e.target.value.replace(',', '.');
            setFieldsHandle('quantity', v);
          }}
          max={999.99}
        />}
      <DecimalInputNumber
        label="Cost price"
        value={fields.costPrice}
        onChange={(e) => {
          const v = e.target.value.replace(',', '.');
          setFieldsHandle('costPrice', v);
        }}
        max={maxCommonDecimal}
        maxDecimalPlaceLength={4}
      />
      <DecimalInputNumber
        label="Selling price"
        value={fields.sellingPrice}
        onChange={(e) => {
          const v = e.target.value.replace(',', '.');
          setFieldsHandle('sellingPrice', v);
        }}
        max={maxCommonDecimal}
      />
      <DatePickerComponent
        label="Added date"
        value={fields.addedDate ? moment(fields.addedDate) : undefined}
        format={DEFAULT_UI_DATE_FORMAT}
        onChange={(date) => {
          if (date) {
            const formatted = moment(date).format(DEFAULT_DATE_FORMAT);
            setFieldsHandle('addedDate', formatted);
          }
        }}
        disabledDate={(currentDate) => {
          const fiveYearsFromDate = moment().subtract(5, 'years');
          const tooEarly = moment(currentDate).add(+1, 'days') <= fiveYearsFromDate;
          const tooLate = currentDate > moment();
          return tooEarly || tooLate;
        }}
      />
      <Button
        label="Add quantity"
        onClick={() => addQtyHandle()}
        icon={<FontAwesomeIcon icon={faCirclePlus} />}
        className="adjustQty__add-btn"
        disabled={disabledAddAction}
        isLoading={isFetching}
        designType="dark"
        reversed
      />
    </div>
  );
};

export default AdjustQtyFooterActions;
