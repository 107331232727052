import { AddressFields } from '../types/commonTypes';
import { NewContactFormFields } from '../types/createNewContactSchema';

export const createContactUtil = (locationFields: AddressFields, values: NewContactFormFields) => {
  const location = { ...locationFields };
  Object.keys(location).forEach((k) => {
    if (location[k as keyof AddressFields]) {
      location[k as keyof AddressFields] = location[k as keyof AddressFields]?.trim() || null;
    } else {
      location[k as keyof AddressFields] = null;
    }
  });

  const valuesCopy = { ...values };
  Object.keys(valuesCopy).forEach((k) => {
    if (valuesCopy[k as keyof NewContactFormFields]) {
      valuesCopy[k as keyof NewContactFormFields] = valuesCopy[k as keyof NewContactFormFields]?.trim() || '';
    } else {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      valuesCopy[k as keyof NewContactFormFields] = null;
    }
  });

  return { location, valuesCopy };
};
