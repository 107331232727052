import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { RectificationReportTableLine } from '../../../../../types/RectificationReportsTypes';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';

type RectificationReportBodyProps = {
  data: RectificationReportTableLine[],
  columnsLength: number,
}
const RectificationReportBody: React.FC<RectificationReportBodyProps> = ({ columnsLength, data }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const hiddenKeys = ['orderId', 'vehicleId', 'id', 'customerId'];
  const nowrapKeys = ['costNet', 'salesNet', 'completedDate'];

  const renderTruncateLink = (value: string | number | null, to: string, permission: PermissionEnum) => {
    return (
      <PrivateLineLink to={to} permission={permission} fitContent>
        <TruncateTooltip
          value={value}
          table
        />
      </PrivateLineLink>
    );
  };

  const renderCellValue = (key: string, value: string | number | null, el: RectificationReportTableLine) => {
    const linkedNumberRender = () => {
      if (value && el.orderId) {
        return renderTruncateLink(
          value,
          `/${routesPath.ORDERS}/${el.orderId}`,
          PermissionEnum.OrderViewPage,
        );
      }
      if (value && el.vehicleId) {
        return renderTruncateLink(
          value,
          `/${routesPath.VEHICLES}/${el.vehicleId}`,
          PermissionEnum.VehicleViewPage,
        );
      } else return '-';
    };
    switch (key) {
      case 'linkedNumber':
        return linkedNumberRender();
      case 'rectificationNumber':
        return value && el.id
          ? renderTruncateLink(
            value,
            `/${routesPath.RECTIFICATIONS}/${el.id}`,
            PermissionEnum.RectificationViewPage,
          )
          : '-';
      case 'customer':
        return value && el.customerId
          ? renderTruncateLink(
            value,
            `/${routesPath.CUSTOMERS}/${el.customerId}`,
            PermissionEnum.CustomerViewPage,
          )
          : '-';
      case 'faultType':
        return <TruncateTooltip value={value} table />;
      default: return value;
    }
  };

  return (
    <Tbody>
      {data.length > 0 ? data.map((el) => (
        <Tr key={el.number}>
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
              {renderCellValue(key, value, el)}
            </TdNew>;
          })}
          <TdNew />
        </Tr>
      ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default RectificationReportBody;
