import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { VehiclesPageParamsEnum } from '../enums/VehiclesPageParamsEnum';
import VehiclesList from '../../dashboard/VehiclesList';
import VehicleProfiles from '../../vehicle-profiles/components/VehicleProfiles';

const VehiclesContent: React.FC = () => {
  const [params] = useSearchParams();
  const page = params.get('page');

  const renderVehiclesContent = () => {
    switch (page) {
      case VehiclesPageParamsEnum.DASHBOARD:
        return <VehiclesList />;
      case VehiclesPageParamsEnum.VEHICLE_PROFILES:
        return <VehicleProfiles />;
      default: return null;
    }
  };

  return <>{renderVehiclesContent()}</>;
};

export default VehiclesContent;
