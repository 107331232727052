import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse,
  DictionaryItem, emptyPaging,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../../core/types/coreTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import { PartsKitComponentDto, PartsKitsDto } from '../../../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { routesPath } from '../../../../core/enums/pathEnum';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import {
  PartDtoType,
  PatchPartsKitDataType, StockFiltersType,
} from '../../../../stock/search/types/stockSearchTypes';
import { ExtendedTimelineFilters } from '../../../../common/types/commonTypes';
import {
  DuplicateValue,
  ErrorsEnum,
  PleaseRefreshThePageError,
  SimultaneouslyStatusErrorEnum,
} from '../../../../core/enums/errorsEnum';
import {
  CustomPartDataType, PartsKitAffectedEntityDto, PartsKitAffectedItemsFilters, PartsKitComponentGroupsItemDto,
  PartsKitComponentsFiltersType,
  PartsKitComponentsSummaryType, PartsKitComponentsTimelineGroupFilters, PartsKitEventDtoBase,
} from '../../../../stock/parts-kits/view-page/types/partsKitViewPageTypes';
import { RootState } from '../../../store';
import { setPartsKitTimeline, setPartsKitTimelineFilters } from '../../../slices/partsKitSlice';
import { UserEventPartsKit } from '../../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../../slices/settingsSlice';
import {
  DuplicatePartsKitFields,
  PartsKitFields,
} from '../../../../stock/parts-kits/view-page/utils/PartsKitDetailsSchema';
import { PartCategoryDto } from '../../../../stock/part-categories/types/partCategoriesTypes';

export const getPartsKitById = createAsyncThunk<
ResponseSingleResult<{ partsKit: PartsKitsDto }> | null, { id: number }
>(
  'get/PartsKitById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitTimelineThunk = createAsyncThunk<
ResponseResult<PartsKitEventDtoBase[]> | null,
{ partsKitId: number, filters: ExtendedTimelineFilters }
>(
  'get/PartsKitTimeline',
  async ({ partsKitId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitTimeline(partsKitId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitAffectedThunk = createAsyncThunk<
ResponseResult<PartsKitAffectedEntityDto[]>,
{
  id: number,
  filters: PartsKitAffectedItemsFilters,
  complexAction?: VoidFunctionType,
  confirmAction?: VoidFunctionType,
}
>(
  'get/PartsKitAffected',
  async ({
    id, filters, confirmAction, complexAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.getPartsKitAffected(id, filters);
      dispatch(setLoading(false));
      const { totalCount } = response.data.data;
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const duplicatePartsKitById = createAsyncThunk<
ResponseSingleResult<{ createdId: number, partsKitName: string | null }> | null,
{
  id: number
  code: string,
  navigate: NavigateFunction,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<DuplicatePartsKitFields>,
}
>(
  'post/DuplicatePartsKit',
  async ({
    id, code, navigate, closeModal, setError,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.duplicatePartsKit(id, code);
      const { createdId, partsKitName } = response.data.data;
      closeModal();
      dispatch(setSuccessMessage({ message: `Parts kit ${partsKitName || ''} (duplicate) was successfully created` }));
      navigate(`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${createdId}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      const accountNoError = errors.find((err) => err.key === 'NewPartsKitCode');
      if (accountNoError) {
        setError('code', { type: `${accountNoError?.message}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const activatePartsKitThunk = createAsyncThunk<
ResponseSingleResult | null,
{ id: number }
>(
  'post/ActivatePartsKit',
  async ({ id }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitDetails, partsKitTimelineFilters } = partsKit;
      const name = partsKitDetails?.name;
      const response = await stockApi.activatePartsKit(id);
      dispatch(getPartsKitById({ id }));
      if (partsKitTimelineFilters.page === 1) {
        dispatch(getPartsKitTimelineThunk({ partsKitId: id, filters: partsKitTimelineFilters }));
      } else {
        dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: `"${name || ''}" parts kit  was successfully activated` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const inactivatePartsKitThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  showNotification?: boolean,
  closeModal: VoidFunctionType,
}
>(
  'post/InactivatePartsKit',
  async ({
    id, showNotification, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitDetails, partsKitTimelineFilters } = partsKit;
      const name = partsKitDetails?.name;
      const response = await stockApi.inactivatePartsKit(id);
      dispatch(getPartsKitById({ id }));
      if (partsKitTimelineFilters.page === 1) {
        dispatch(getPartsKitTimelineThunk({ partsKitId: id, filters: partsKitTimelineFilters }));
      } else {
        dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, page: 1 }));
      }
      closeModal();
      if (showNotification) {
        dispatch(setSuccessMessage({ message: `"${name || ''}" parts kit was successfully inactivated.` }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      dispatch(setLoading(false));
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartsKitThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  closeModal: VoidFunctionType,
  navigate: NavigateFunction,
  showNotification?: boolean,
}
>(
  'delete/PartsKit',
  async ({
    id, closeModal, navigate, showNotification,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.deletePartsKit(id);
      closeModal();
      if (showNotification) {
        dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      }
      navigate(`/stock?page=${routesPath.PARTS_KITS}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitAvailableCategoriesThunk = createAsyncThunk<
ResponseResult<PartCategoryDto[]> | null, { keyword?: string }
>(
  'get/PartsKitAvailableCategories',
  async ({ keyword }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartCategories({ keyword });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPartsKitThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  data: Partial<PatchPartsKitDataType>,
  closeModal?: VoidFunctionType,
  setError?: UseFormSetError<PartsKitFields>,
}
>(
  'patch/PartsKit',
  async ({
    id, data, closeModal, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit: { partsKitTimelineFilters } } = getState() as RootState;
      const response = await stockApi.patchStockPartsKit(id, data);
      dispatch(getPartsKitById({ id }));
      if (partsKitTimelineFilters.page === 1) {
        dispatch(getPartsKitTimelineThunk({ partsKitId: id, filters: partsKitTimelineFilters }));
      } else {
        dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, page: 1 }));
      }
      closeModal && closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const status = error.response?.data.status || '';
      const kitCodeErr = errors.find((err) => err.key === 'PartsKitCode');
      if (kitCodeErr) {
        setError && setError('partsKitCode', { type: `${DuplicateValue}`, message: kitCodeErr.message });
      } else if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitComponents = createAsyncThunk<
ResponseSingleResult<{ components: PartsKitComponentDto[], allItemIds: number[] }>,
{
  id: number,
  filters?: PartsKitComponentsFiltersType,
}
>(
  'get/PartsKitComponents',
  async ({ id, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitComponents(id, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitComponentTax = createAsyncThunk<
ResponseSingleResult<{ items: DictionaryItem[] }> | null>(
  'get/PartsKitComponentTax',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitComponentTax();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitComponentsSummary = createAsyncThunk<
ResponseSingleResult<PartsKitComponentsSummaryType> | null,
{ id: number }
>(
  'get/PartsKitComponentsSummary',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockComponentsSummary(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitComponentsParts = createAsyncThunk<
ResponseResult<PartDtoType[], { allItemIds: number[] }>,
{ filters: StockFiltersType }
>(
  'get/PartsKitComponentsParts',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addPartComponent = createAsyncThunk<
ResponseSingleResult<{ createdIds: number[] }> | null,
{ id: number, partIds: number[], closeModal: VoidFunctionType, }
>(
  'post/createPartComponent',
  async ({
    id, partIds, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit: { partsKitComponentsFilters, partsKitComponentsPartsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchStockParts({
        ...partsKitComponentsPartsFilters,
        ...emptyPaging,
      });
      const onFinish = () => {
        closeModal();
        dispatch(getPartsKitComponents({ id, filters: partsKitComponentsFilters }));
        dispatch(getPartsKitComponentsSummary({ id }));
        dispatch(setLoading(false));
      };
      const filteredIds = partIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      const response = await stockApi.createPartComponent(id, filteredIds);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addCustomPartComponent = createAsyncThunk<
ResponseSingleResult<{ createdId: number }> | null,
{ id: number, customPartData: CustomPartDataType, closeModal: VoidFunctionType }
>(
  'post/createCustomPartComponent',
  async ({
    id, customPartData, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitComponentsFilters } = partsKit;
      const response = await stockApi.createCustomComponent(id, customPartData);
      closeModal();
      dispatch(getPartsKitComponents({ id, filters: partsKitComponentsFilters }));
      dispatch(getPartsKitComponentsSummary({ id }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPartsKitComponentThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  partsKitId: number,
  data: Partial<CustomPartDataType>,
  closeModal?: VoidFunctionType,
}
>(
  'patch/PartComponent',
  async ({
    id, partsKitId, data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitComponentsFilters } = partsKit;
      const response = await stockApi.patchPartsKitComponent(id, data);
      closeModal && closeModal();
      dispatch(getPartsKitComponents({ id: partsKitId, filters: partsKitComponentsFilters }));
      dispatch(getPartsKitComponentsSummary({ id: partsKitId }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteComponentThunk = createAsyncThunk<
ResponseSingleResult | null,
{ id: number, partsKitId: number, closeModal: VoidFunctionType }
>(
  'delete/component',
  async ({
    id, partsKitId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitComponentsFilters } = partsKit;
      const response = await stockApi.deleteComponent(partsKitId, id);
      dispatch(getPartsKitComponents({ id: partsKitId, filters: partsKitComponentsFilters }));
      dispatch(getPartsKitComponentsSummary({ id: partsKitId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const bulkDeleteComponentsThunk = createAsyncThunk<ResponseSingleResult | null,
{ componentIds: number[], partsKitId: number, closeModal: VoidFunctionType }
>(
  'delete/ComponentsBulk',
  async ({
    componentIds, partsKitId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit: { partsKitComponentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartsKitComponents(partsKitId, {
        ...partsKitComponentsFilters, ...emptyPaging,
      });
      const onFinish = () => {
        dispatch(setLoading(false));
        closeModal();
        dispatch(getPartsKitComponents({ id: partsKitId, filters: partsKitComponentsFilters }));
        dispatch(getPartsKitComponentsSummary({ id: partsKitId }));
      };
      const filteredIds = componentIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      const response = await stockApi.bulkDeleteComponents(partsKitId, filteredIds);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postEnquiryTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partsKitId: number, content: string, setValue: VoidFunctionType,
}>('post/PartsKit/Note', async ({
  partsKitId, content, setValue,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const res = await stockApi.addPartsKitTimelineNote(content, partsKitId);
    const { partsKit: { partsKitTimelineFilters } } = getState() as RootState;
    setValue();
    if (partsKitTimelineFilters.page === 1) {
      dispatch(getPartsKitTimelineThunk({
        partsKitId,
        filters: partsKitTimelineFilters,
      }));
    } else {
      dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, page: 1 }));
    }
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(setLoading(false));
    return res.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    setValue();
    return rejectWithValue(error.response?.data);
  }
});

export const editPartsKitTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  noteId: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/PartsKitNote',
  async ({
    noteId, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.editPartsKitTimelineNote(content, noteId);
      const {
        partsKit: { partsKitTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventPartsKit[];
        const items = timeline.map((el) => (el.id === noteId ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = partsKitTimeline.items.map((el) => (el.id === noteId ? { ...el, content } : el));
        dispatch(setPartsKitTimeline({ ...partsKitTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartsKitTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }>(
  'delete/PartsKit/Note',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.deletePartsKitTimelineNote(id);
      const {
        partsKit: { partsKitTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitTimelineComponentsGroupBeforeThunk = createAsyncThunk<ResponseResult<PartsKitComponentGroupsItemDto[]>, {
  lineGroupId: number,
  filters: PartsKitComponentsTimelineGroupFilters,
}>(
  'get/PartsKit/TimelineComponentsGroupBefore',
  async ({ lineGroupId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.getPartsKitTimelineComponentsGroupBefore(lineGroupId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitTimelineComponentsGroupAfterThunk = createAsyncThunk<ResponseResult<PartsKitComponentGroupsItemDto[]>, {
  lineGroupId: number,
  filters: PartsKitComponentsTimelineGroupFilters,
}>(
  'get/PartsKit/TimelineComponentsGroupAfter',
  async ({ lineGroupId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.getPartsKitTimelineComponentsGroupAfter(lineGroupId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
