import { useEffect } from 'react';
import { getLeaveRequestStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';
import { getLeaveRequestOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { getDayPartsThunk, getLeaveRequestTypesThunk } from '../../store/thunks/coreThunk';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { leaveRequestStatusesUiSelector } from '../../store/selectors/coreStatusesSelectors';
import { leaveRequestOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { dayPartsUiSelector, leaveRequestTypesUiSelector } from '../../store/selectors/coreSelectors';

export const useLeaveRequestsComponents = () => {
  const dispatch = useAppDispatch();
  const leaveRequestStatuses = useAppSelector(leaveRequestStatusesUiSelector);
  const leaveRequestOrdering = useAppSelector(leaveRequestOrderingSelector);
  const leaveRequestTypes = useAppSelector(leaveRequestTypesUiSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);

  useEffect(() => {
    if (!leaveRequestStatuses.length) {
      dispatch(getLeaveRequestStatusesThunk());
    }
    if (!leaveRequestOrdering.length) {
      dispatch(getLeaveRequestOrderingThunk());
    }
    if (!leaveRequestTypes.length) {
      dispatch(getLeaveRequestTypesThunk());
    }
    if (!dayParts.length) {
      dispatch(getDayPartsThunk());
    }
    // eslint-disable-next-line
  }, []);
};
