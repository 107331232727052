import React, { useEffect, useState } from 'react';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import './EditReadyCollectedConfirmModal.scss';
import RegularInputNumber from '../../../../../core/components/input-number/RegularInputNumber';
import Input from '../../../../../core/components/input/Input';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isFetchingSelector, usersFilterLookupSelector } from '../../../../../store/selectors/coreSelectors';
import {
  editReadyCollectedModalQuantitiesSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import {
  patchEditReadyCollectedPartQuantities,
  splitPartQuantitiesThunk,
} from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { PatchPartQtyType } from '../../../../part-view-page/types/types';
import { maxCommonWhole } from '../../../../../core/utils/regex';
import { partQtyStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';

type EditReadyCollectedConfirmModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  confirmEditMode: 'split' | 'allocate' | 'ready' | 'collect' | 'edit-location' | 'reserved' | string,
  sourceId?: number,
  partQuantityId?: number,
  isLinesView?: boolean,
}

const EditReadyCollectedConfirmModal: React.FC<EditReadyCollectedConfirmModalProps> = ({
  visible, onCancel, confirmEditMode, sourceId, partQuantityId, isLinesView,
}) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const {
    allocated, picked, collected, reserved,
  } = useAppSelector(partQtyStatusesValuesSelector);
  const dataLines = useAppSelector(editReadyCollectedModalQuantitiesSelector);
  const potentialOwners = useAppSelector(usersFilterLookupSelector);
  const currentElToEdit = dataLines?.partQuantities?.items?.find((el) => el.id === partQuantityId);
  const currentQtyToEdit = currentElToEdit?.quantity;

  const [splitValues, setSplitValues] = useState<{ first: string, second: string }>({ first: '', second: '' });
  const [locationValue, setLocationValue] = useState<string>('');
  const [owner, setOwner] = useState<number | undefined>(undefined);
  const [ownerError, setOwnerError] = useState('');

  const closeModalHandle = () => {
    onCancel();
    setSplitValues({ first: '', second: '' });
    setLocationValue('');
    setOwner(undefined);
    setOwnerError('');
  };
  const confirmThunkHandle = (data: PatchPartQtyType, isReserved?: boolean) => {
    currentElToEdit && partQuantityId && sourceId && dispatch(patchEditReadyCollectedPartQuantities({
      partId: currentElToEdit.partId,
      id: partQuantityId,
      lineId: sourceId,
      data,
      closeModal: closeModalHandle,
      isReserved,
      isLinesView,
    }));
  };

  const splitHandle = () => {
    currentElToEdit && sourceId && partQuantityId && dispatch(splitPartQuantitiesThunk({
      partId: currentElToEdit.partId,
      id: partQuantityId,
      lineId: sourceId,
      quantities: [+splitValues.first, +splitValues.second],
      closeModal: closeModalHandle,
    }));
  };

  useEffect(() => {
    const isAllConditions = visible && confirmEditMode === 'split' && currentQtyToEdit;
    if (isAllConditions) {
      const firstValue = Math.round(currentQtyToEdit / 2);
      const secondValue = currentQtyToEdit - firstValue;
      setSplitValues({
        first: firstValue.toString(),
        second: secondValue.toString(),
      });
    }
  }, [visible, confirmEditMode, currentQtyToEdit]);
  const splitSum = (+splitValues.first) + (+splitValues.second);

  useEffect(() => {
    const isAllConditions = visible && confirmEditMode === 'edit-location' && currentElToEdit;
    if (isAllConditions) {
      const locationV = currentElToEdit?.locationComment;
      setLocationValue(locationV || '');
    }
  }, [visible, confirmEditMode, currentElToEdit]);

  const contentHandle = () => {
    switch (confirmEditMode) {
      case 'split':
        return {
          title: 'Split quantity',
          text: `This form allows to split a quantity line with ${currentQtyToEdit} pcs into two separate lines.`,
          label: 'Split',
          clickHandle: () => splitHandle(),
        };
      case 'edit-location':
        return {
          title: 'Edit location',
          text: '',
          label: 'Save',
          clickHandle: () => confirmThunkHandle({
            locationComment: locationValue ? locationValue.trim() : null,
          }),
        };
      case 'ready':
        return {
          title: 'Mark as Ready',
          text: `${currentQtyToEdit} pcs will be marked as ready for collection.`,
          label: 'Mark as ready',
          clickHandle: () => picked && confirmThunkHandle({
            status: picked,
            locationComment: locationValue ? locationValue.trim() : null,
          }),
        };
      case 'allocate':
        return {
          title: 'Mark as Allocated',
          text: `${currentQtyToEdit} pcs will be returned back to allocated bucket.`,
          label: 'Yes, mark as allocated',
          clickHandle: () => allocated && confirmThunkHandle({ status: allocated }),
        };
      case 'reserved':
        return {
          title: 'Mark as Reserved',
          text: `${currentQtyToEdit} pcs will be returned back to reserved bucket.`,
          label: 'Yes, mark as reserved',
          clickHandle: () => reserved && confirmThunkHandle({ status: reserved }, true),
        };
      default: return {
        title: 'Mark as Collected',
        text: `${currentQtyToEdit} pcs will be marked as collected.`,
        label: 'Mark as collected',
        clickHandle: () => {
          if (collected) {
            owner
              ? confirmThunkHandle({ status: collected, ownerId: owner })
              : setOwnerError(ErrorsEnum.REQUIRED);
          }
        },
      };
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={closeModalHandle}
      width={700}
      destroyOnClose
      title={contentHandle().title}
    >
      <div className="editConfirmWrap">
        <span className="editConfirmWrap__text">{contentHandle().text}</span>
        {confirmEditMode === 'allocate' && <span>Do you want to proceed?</span>}
        {confirmEditMode === 'split' && <div className="editConfirmWrap__split">
          <RegularInputNumber
            label="Line 1"
            value={splitValues.first}
            max={maxCommonWhole}
            onChange={(v) => setSplitValues({ ...splitValues, first: v.target.value })}
          />
          <RegularInputNumber
            label="Line 2"
            value={splitValues.second}
            max={maxCommonWhole}
            onChange={(v) => setSplitValues({ ...splitValues, second: v.target.value })}
          />
        </div>}
        {(confirmEditMode === 'ready' || confirmEditMode === 'edit-location')
          && <Input
            label="Location comment"
            value={locationValue}
            onChange={(v) => setLocationValue(v.target.value)}
          />}
        {confirmEditMode === 'collect'
          && <Select
            label="Owner"
            value={owner}
            options={potentialOwners}
            onChange={(v) => {
              setOwnerError('');
              setOwner(v as number);
            }}
            error={ownerError}
            parentRender
          />}
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={closeModalHandle}
          createLabel={contentHandle().label}
          createClick={contentHandle().clickHandle}
          offsetTop
          isLoading={isFetching}
          disabledCreate={confirmEditMode === 'split' && splitSum !== currentQtyToEdit}
        />
      </div>
    </Modal>
  );
};

export default EditReadyCollectedConfirmModal;
