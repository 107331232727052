import React from 'react';
import { BasicNoteEventProps } from '../../../../../core/types/coreTypes';
import { useNoteActivityLog } from '../../../../../common/hooks/useNoteActivityLog';
import { deleteUserActivityNote, editUserActivityNote } from '../../../../../store/thunks/settings/userViewPageThunks';
import NoteEvent from '../../../../../common/components/activity-events/note/NoteEvent';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';

const UserNoteEvent: React.FC<BasicNoteEventProps> = ({ id, content = '', initiator = '' }) => {
  const {
    dispatch,
    value,
    setValue,
    isEditModal,
    isConfirmationModal,
    setIsConfirmationModal,
    closeHandle,
    menuItems,
  } = useNoteActivityLog(content);

  const editHandle = () => {
    id && dispatch(editUserActivityNote({
      id,
      content: value.trim(),
      closeModal: closeHandle,
    }));
  };

  const deleteHandle = () => {
    id && dispatch(deleteUserActivityNote({
      id,
      closeModal: () => setIsConfirmationModal(false),
    }));
  };

  return (
    <NoteEvent
      content={content}
      menuItems={menuItems}
      value={value}
      setValue={setValue}
      isEditModal={isEditModal}
      closeEditModal={closeHandle}
      editHandle={editHandle}
      isConfirmationModal={isConfirmationModal}
      setIsConfirmationModal={setIsConfirmationModal}
      deleteHandle={deleteHandle}
      initiator={initiator}
      permission={PermissionEnum.UserEditDeleteNotesOfOtherUsersInActivityLog}
    />
  );
};

export default UserNoteEvent;
