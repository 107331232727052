import React from 'react';
import classNames from 'classnames';
import { CustomAny, DictionaryItem } from '../../../../../core/types/coreTypes';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import { JobPartsTableLine } from '../../../types/jobViewTypes';

type JobPartsChildLineProps = {
  hiddenKeys: string[],
  nowrapKeys: string[],
  renderCellValue: (key: string, value: CustomAny, unitOfMeasure: number) => React.ReactNode,
  childLine: Omit<JobPartsTableLine, 'childLines'>,
  deletedStatus?: DictionaryItem,
}
const JobPartsChildLine: React.FC<JobPartsChildLineProps> = ({
  hiddenKeys, nowrapKeys, renderCellValue, childLine, deletedStatus,
}) => {
  return (
    <Tr
      className={classNames({ 'suitableLine': childLine.status.toLowerCase() === deletedStatus?.label?.toLowerCase() })}
    >
      {Object.entries(childLine).map(([key, value]) => {
        if (hiddenKeys.includes(key)) return null;
        return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
          {renderCellValue(key, value, childLine.unitOfMeasure)}
        </TdNew>;
      })}
    </Tr>
  );
};

export default JobPartsChildLine;
