import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  GenericColumnType, GenericVoidFunctionType, infiniteScrollInitPaging,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setDashboardLoading, setLoading } from '../../slices/coreSlice';
import { rectificationsAPI } from '../../../api/rectificationsApi';
import {
  FetchRectificationsCollectionsParamsType,
  GetRectificationsResponseType, PagedRectificationCollectionDto, RectificationDto,
} from '../../../rectifications/dashboard/types/rectificationsTypes';
import { setColumnsPagination, setRectificationCollections } from '../../slices/rectificationsSlice';
import { rectificationStatusTransitionHelper } from '../../../rectifications/dashboard/utils/rectificationStatusTransitionHelper';
import { RootState } from '../../store';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';

export const getRectificationList = createAsyncThunk<
ResponseSingleResult<GetRectificationsResponseType> | null,
{ params: FetchRectificationsCollectionsParamsType, signal?: AbortSignal }
>(
  'get/RectificationsList',
  async ({ params, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    dispatch(setRectificationCollections(null));
    try {
      const response = await rectificationsAPI.fetchRectifications(params, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationCollectionsNextPortion = createAsyncThunk<
ResponseSingleResult<GetRectificationsResponseType> | null,
{ params: FetchRectificationsCollectionsParamsType, currentStatus: number,
  setNewPortion:(
  // eslint-disable-next-line
    status: number, data: RectificationDto[]) => void }>(
    'get/RectificationCollectionsNextPortion',
    async ({
      params, currentStatus, setNewPortion,
    }, { getState, dispatch, rejectWithValue }) => {
      dispatch(setLoading(true));
      try {
        const response = await rectificationsAPI.fetchRectifications(params);
        const { rectifications } = getState() as RootState;
        const { columnsPagination } = rectifications;
        const resCollections = response.data.data.rectificationCollections;
        const list = resCollections?.find((el) => el.status === currentStatus)?.rectifications || [];
        setNewPortion(currentStatus, list);
        const newPagination = columnsPagination.map((el) => {
          if (el.status === currentStatus) {
            return {
              ...el, currentPage: params.page,
            };
          } else return el;
        });
        dispatch(setColumnsPagination(newPagination));
        dispatch(setLoading(false));
        return response.data;
      } catch (err) {
        const error = err as AxiosErrorResponse;
        dispatch(setLoading(false));
        return rejectWithValue(error.response?.data);
      }
    },
    );

export const putRectificationStatusThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  statusTransition: number,
  sourceId: number,
  setColumns: VoidFunctionType,
  rejectionReason?: string,
  onClose?: VoidFunctionType,
  setColumnsLoading: GenericVoidFunctionType<number[]>,
}
>('put/rectificationStatus', async ({
  id, statusTransition, sourceId, setColumns, rejectionReason, onClose, setColumnsLoading,
}, { getState, dispatch, rejectWithValue }) => {
  try {
    const { rectifications, core } = getState() as RootState;
    const { currentBusinessArea } = core;
    const { columnsPagination, rectificationCollections, filters } = rectifications;
    setColumnsLoading([statusTransition, sourceId]);
    const response = await rectificationsAPI.changeRectificationStatus(id, statusTransition, rejectionReason);
    if (currentBusinessArea) {
      const newGetParams = {
        ...filters,
        ...infiniteScrollInitPaging,
        statuses: [statusTransition, sourceId],
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
      };
      const res = await rectificationsAPI.fetchRectifications(newGetParams);
      const collections = res.data.data.rectificationCollections;
      const refactoredCollections: PagedRectificationCollectionDto[] | undefined = collections?.map((el) => {
        const { pagesCount, ...rest } = el;
        return { ...rest, pages: pagesCount };
      });
      if (rectificationCollections && refactoredCollections?.length) {
        const { newPagination, newCollections } = rectificationStatusTransitionHelper(
          rectificationCollections,
          refactoredCollections,
          columnsPagination,
          statusTransition,
          sourceId,
        );
        dispatch(setRectificationCollections(newCollections));
        dispatch(setColumnsPagination(newPagination));
      }
      setColumnsLoading([]);
      onClose && onClose();
    }
    return response.data;
  } catch (err) {
    setColumnsLoading([]);
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    statusTransitionErrorHandle(errors, dispatch);
    onClose && onClose();
    setColumns();
    return rejectWithValue(error.response?.data);
  }
});

export const putRectificationLayoutThunk = createAsyncThunk<
ResponseSingleResult,
{
  data: Array<GenericColumnType<Array<RectificationDto>>> | null,
  closeModal: VoidFunctionType,
}
>('put/RectificationsColumnVisibility', async ({ data, closeModal }, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const {
      core: { currentBusinessArea },
      coreStatuses: { rectificationStatuses },
      rectifications: { filters },
    } = getState() as RootState;
    const newLayout = data?.map((l) => ({
      status: l.status,
      ordinalNumber: l.order,
      isVisible: !l.isHidden,
    })) || [];
    const response = await rectificationsAPI.changeRectificationsLayout(newLayout);
    dispatch(setLoading(false));
    dispatch(setRectificationCollections(data));
    currentBusinessArea && dispatch(getRectificationList({
      params: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: rectificationStatuses.map((st) => st.value),
        page: 1,
      },
    }));
    closeModal();
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
