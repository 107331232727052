import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useVehicleParams } from './hooks/useVehicleParams';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import VehicleViewHead from './components/vehicle-head/VehicleViewHead';
import './VehicleViewPageContainer.scss';
import VehicleFilters from './components/vehicle-filters/VehicleFilters';
import VehicleContent from './components/vehicle-content/VehicleContent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteVehicleThunk, getVehicleByIdThunk } from '../../store/thunks/vehicles/vehicleViewPageThunks';
import { VehicleContentModes, VehicleViewModeParamsEnum } from './enums/VehicleViewEnums';
import { vehicleDictionariesHandle } from '../../common/utils/vehicleDictionariesHandle';
import { getCustomers } from '../../store/thunks/customers/customersDashboardThunks';
import { vehicleViewPageCleanupHandle } from './utils/vehicleViewPageCleanupHandle';
import { vehicleDetailsSelector } from '../../store/selectors/vehiclesSelectors';
import { routesPath } from '../../core/enums/pathEnum';

const VehicleViewPageContainer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const [isConfirmationModal, setIsConformationModal] = useState(false);
  const {
    backHandle,
    mode,
    setModeParam,
    tabs,
  } = useVehicleParams();

  const actions = [
    {
      label: 'Delete vehicle',
      key: 'delete-vehicle',
      icon: <FontAwesomeIcon icon={faTrash} />,
      onClick: () => setIsConformationModal(true),
      disabled: !!vehicleDetails?.linksCount,
      restrictedTo: PermissionEnum.VehicleDelete,
    },
  ];

  const deleteConfirmHandle = () => {
    id && dispatch(deleteVehicleThunk({
      id: +id,
      closeModal: () => {
        setIsConformationModal(false);
        navigate(`/${routesPath.VEHICLES}`);
      },
    }));
  };

  useEffect(() => {
    if (mode && VehicleContentModes.includes(mode as VehicleViewModeParamsEnum)) {
      id && dispatch(getVehicleByIdThunk({ vehicleId: +id }));
    }
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    vehicleDictionariesHandle(dispatch);
    dispatch(getCustomers({ params: undefined }));
    return () => vehicleViewPageCleanupHandle(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          actions={actions}
          confirmHandler={deleteConfirmHandle}
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setIsConformationModal}
          goBack={backHandle}
          className="vehicleViewPageHead"
        >
          <VehicleViewHead />
        </DetailsHead>
      }
    >
      <VehicleFilters tabs={tabs} mode={mode} setModeParam={setModeParam} />
      <VehicleContent mode={mode} />
    </Layout>
  );
};

export default VehicleViewPageContainer;
