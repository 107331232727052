export enum RectificationReportOrdering {
  LinkedTo = 'LinkedTo',
  RectificationNo = 'RectificationNo',
  Customer = 'Customer',
  RectificationType = 'RectificationType',
  FaultType = 'FaultType',
  CostNet = 'CostNet',
  SalesNet = 'SalesNet',
  CompletedDate = 'CompletedDate'
}

export enum RectificationsByProductReportOrdering {
  Category = 'Category',
  Subcategory = 'Subcategory',
  PartCode = 'PartCode',
  PartName = 'PartName',
  UsedInRectifications = 'UsedInRectifications',
}

export enum NumberOfConvertedVehiclesReportOrdering {
  BusinessAreaThenJobType = 'BusinessAreaThenJobType',
  ConvertedVehicles = 'ConvertedVehicles',
}

export enum SalesOffsiteWorkingReportOrdering {
  EntityType = 'EntityType',
  EntityNumber = 'EntityNumber',
  EntityStatus = 'EntityStatus',
  Customer = 'Customer',
  Address = 'Address',
  CityTown = 'CityTown',
  PostalCode = 'PostalCode',
  CreationDate = 'CreationDate',
  DistanceFromOfficeMiles = 'DistanceFromOfficeMiles',
}

export enum SalesOfficeEmployeeReportOrdering {
  OrderNumber = 'OrderNumber',
  Customer = 'Customer',
  SalesNet = 'SalesNet',
  DaysInNewStatus = 'DaysInNewStatus',
  InitialAssignee = 'InitialAssignee',
  OrderStatus = 'OrderStatus',
  CreationDate = 'CreationDate',
  CancellationDate = 'CancellationDate',
}

export enum SalesReportByCustomerOrdering {
  EntityType = 'EntityType',
  EntityNumber = 'EntityNumber',
  Customer = 'Customer',
  BillingCustomer = 'BillingCustomer',
  ShippingCustomer = 'ShippingCustomer',
  CostNet = 'CostNet',
  SalesNet = 'SalesNet',
  CompletedDate = 'CompletedDate',
}

export enum SoldProductsByCustomerReportOrdering {
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  Quantity = 'Quantity',
  CostNet = 'CostNet',
  SalesNet = 'SalesNet',
}

export enum FullStockReportOrdering {
  Category = 'Category',
  Subcategory = 'Subcategory',
  PartCode = 'PartCode',
  PartName = 'PartName',
  Free = 'Free',
  FreeWithoutSellingPrices = 'FreeWithoutSellingPrices',
  Reserved = 'Reserved',
  Allocated = 'Allocated',
  Picked = 'Picked',
  Collected = 'Collected',
  DueIn = 'DueIn',
}

export enum UnfinishedEntitiesReportOrdering {
  EntityType = 'EntityType',
  EntityNo = 'EntityNo',
  Customer = 'Customer',
  EntityStatus = 'EntityStatus',
  SalesNet = 'SalesNet',
  CreationDate = 'CreationDate',
}

export enum RectificationsBySupplierReportOrdering {
  Supplier = 'Supplier',
  Category = 'Category',
  Subcategory = 'Subcategory',
  PartCode = 'PartCode',
  PartName = 'PartName',
  UsedInRectifications = 'UsedInRectifications',
}

export enum OutstandingOverduePurchaseOrdersReportOrdering {
  Supplier = 'Supplier',
  PurchaseOrderNumber = 'PurchaseOrderNumber',
  PurchaseOrderDate = 'PurchaseOrderDate',
  CostNet = 'CostNet',
  CostGross = 'CostGross',
  DueDate = 'DueDate',
  Assignee = 'Assignee',
}

export enum SupplierSpendReportOrdering {
  Supplier = 'Supplier',
  PurchaseOrderNumber = 'PurchaseOrderNumber',
  PurchaseOrderDate = 'PurchaseOrderDate',
  CostNet = 'CostNet',
  CostGross = 'CostGross',
}

export enum FitmentTimeReportOrdering {
  JobNumber = 'JobNumber',
  JobType = 'JobType',
  JobStatus = 'JobStatus',
  Assignee = 'Assignee',
  SpentByAssignee = 'SpentByAssignee',
  TotalSpent = 'TotalSpent',
  TotalEstimated = 'TotalEstimated',
  Difference = 'Difference',
  CreationDate = 'CreationDate',
}

export enum CancelledLinesOrdering {
  CustomerName = 'CustomerName',
  EntityNumber = 'EntityNumber',
  CategoryName = 'CategoryName',
  PartCode = 'PartCode',
  PartName = 'PartName',
  Quantity = 'Quantity',
  ActivityType = 'ActivityType',
  ActionInitiator = 'ActionInitiator',
  ActionReason = 'ActionReason',
  ActionDate = 'ActionDate',
}

export enum FitterReturnsEnum {
  Customer = 'Customer',
  EntityNumber = 'EntityNumber',
  Category = 'Category',
  PartCode = 'PartCode',
  PartName = 'PartName',
  Quantity = 'Quantity',
  DeletionInitiator = 'DeletionInitiator',
  DeletionReason = 'DeletionReason',
  DeletionDate = 'DeletionDate',
  DeletionConfirmed = 'DeletionConfirmed',
}
