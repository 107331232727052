import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  unfinishedEntitiesFiltersSelector, unfinishedEntitiesSalesNetRangeSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useUnfinishedEntitiesRange = () => {
  const filters = useAppSelector(unfinishedEntitiesFiltersSelector);
  const { salesNet } = useAppSelector(unfinishedEntitiesSalesNetRangeSelector);
  const [rangePatterns, setRangePatterns] = useState({
    salesNet: { min: salesNet.min, max: salesNet.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    salesNet: filters?.salesNet
      ? [filters.salesNet.min.toString(), filters.salesNet.max.toString()]
      : [rangePatterns.salesNet.min.toString(), rangePatterns.salesNet.max.toString()],
  });

  useEffect(() => {
    setRangePatterns({ salesNet: { min: salesNet.min, max: salesNet.max } });
    setRange({ salesNet: [salesNet.min.toString(), salesNet.max.toString()] });
  }, [salesNet.min, salesNet.max]);

  useEffect(() => {
    if (!filters?.salesNet) {
      setRange((prevState) => (
        { ...prevState, salesNet: [rangePatterns.salesNet.min.toString(), rangePatterns.salesNet.max.toString()] }));
    }
    // eslint-disable-next-line
    }, [filters?.salesNet]);

  return { range, setRange, rangePatterns };
};
