import React, { useState } from 'react';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import Tr from '../../../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setStockPartsFilters } from '../../../../../../store/slices/pricelistSlice';
import { SearchTableFilterType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { addPricePartsColumnsSelector } from '../../../../../../store/selectors/pricelistSelectors';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import { StockFiltersType } from '../../../../../../stock/search/types/stockSearchTypes';

type AddPartColumnsProps = {
  filters: StockFiltersType,
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
  totalElements: number;
}

const AddPartColumns: React.FC<AddPartColumnsProps> = ({
  filters, isAll, isIndeterminate, checkAll, totalElements,
}) => {
  const dispatch = useAppDispatch();
  const addPartColumns = useAppSelector(addPricePartsColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: filters.partCode,
    manufacturerCode: filters.manufacturerCode,
    name: filters.name,
    description: filters.description,
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      dispatch(setStockPartsFilters({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      }));
    } else {
      dispatch(setStockPartsFilters({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setStockPartsFilters({
        ...filters,
        [field]: undefined,
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setStockPartsFilters({
      ...filters,
      page: 1,
      [field]: searchVl[field]?.trim(),
    }));
  };
  return (
    <Tr>
      <ThNew checkbox>
        <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAll} disabled={totalElements === 0} table />
      </ThNew>
      {addPartColumns.map((col, i) => <ThWithControls
        key={i}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={totalElements}
        disabled={totalElements === 0}
        searchVl={searchVl}
        tableFilters={filters}
        className={`addPartToPricelistTh--${col.dataIndex}`}
        insideModal
      />)}
    </Tr>
  );
};

export default AddPartColumns;
