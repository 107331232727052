import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserContextDto } from '../../settings/users-dashboard/types/settingsTypes';
import {
  DataListType, initDataList, ResponseResult, ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  getAccountLeaveRequestsThunk, getAccountLeaveRequestThunk,
  getInAppNotificationsCountThunk,
  getInAppNotificationsThunk,
  getUserProfileThunk,
  userInfoThunk,
} from '../thunks/account/accountThunks';
import {
  InAppNotification, inAppNotificationsFiltersType, UserProfileDto,
} from '../../account/types/AccountTypes';
import { EmployeeLeaveRequestFilters, LeaveRequestDto } from '../../common/types/commonTypes';
import { initEmployeesLeaveRequestsFilters } from '../../employees/leave-request/types/EmployeesLeaveRequestsTypes';

interface IAccountState {
  currentUser: UserContextDto | null,
  userProfile: UserProfileDto | null,
  inAppNotifications: DataListType<InAppNotification[]>,
  inAppNotificationsFilters: inAppNotificationsFiltersType,
  inAppNotificationsCount: number,
  accountLeaveRequestsFilters: EmployeeLeaveRequestFilters,
  accountLeaveRequests: DataListType<LeaveRequestDto[]>,
  accountLeaveRequest: LeaveRequestDto | null,
}

export const initialAccountState: IAccountState = {
  currentUser: null,
  userProfile: null,
  inAppNotifications: initDataList,
  inAppNotificationsFilters: { page: 1, pageSize: 20 },
  inAppNotificationsCount: 0,
  accountLeaveRequestsFilters: initEmployeesLeaveRequestsFilters,
  accountLeaveRequests: initDataList,
  accountLeaveRequest: null,
};

export const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {
    setInAppNotificationsFilters: (state, action: PayloadAction<inAppNotificationsFiltersType>) => {
      state.inAppNotificationsFilters = action.payload;
    },
    setInAppNotifications: (state, action: PayloadAction<DataListType<InAppNotification[]>>) => {
      state.inAppNotifications = action.payload;
    },
    setAccountLeaveRequestsFilters: (state, action: PayloadAction<EmployeeLeaveRequestFilters>) => {
      state.accountLeaveRequestsFilters = action.payload;
    },
    setAccountLeaveRequest: (state, action: PayloadAction<LeaveRequestDto | null>) => {
      state.accountLeaveRequest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(userInfoThunk.fulfilled, (state, action: PayloadAction<ResponseSingleResult<{ userInfo: UserContextDto }>>) => {
      state.currentUser = action.payload.data.userInfo;
    });
    builder.addCase(
      getUserProfileThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ userProfile: UserProfileDto }>>) => {
        state.userProfile = action.payload.data.userProfile;
      },
    );
    builder.addCase(
      getInAppNotificationsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<InAppNotification[]>>) => {
        if (action.payload) {
          const { items, pages, totalCount } = action.payload.data;
          state.inAppNotifications = {
            items: state.inAppNotificationsFilters.page > 1
              ? [...state.inAppNotifications.items, ...items]
              : items,
            pages,
            totalCount,
          };
        } else {
          state.inAppNotifications = initDataList;
        }
      },
    );
    builder.addCase(
      getInAppNotificationsCountThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ count: number }>>) => {
        state.inAppNotificationsCount = action.payload.data.count;
      },
    );
    builder.addCase(
      getAccountLeaveRequestsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<LeaveRequestDto[]>>) => {
        state.accountLeaveRequests = action.payload.data;
      },
    );
    builder.addCase(
      getAccountLeaveRequestThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{leaveRequest: LeaveRequestDto}>>) => {
        state.accountLeaveRequest = action.payload.data.leaveRequest;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setInAppNotificationsFilters,
  setInAppNotifications,
  setAccountLeaveRequestsFilters,
  setAccountLeaveRequest,
} = accountSlice.actions;

export default accountSlice.reducer;
