import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  salesOffsiteWorkingFiltersDateSelector,
  salesOffsiteWorkingFiltersSelector,
  salesOffsiteWorkingGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import {
  setReportIsVisible,
  setSalesOffsiteWorkingData,
  setSalesOffsiteWorkingFilters,
} from '../../../../../store/slices/reportsSlice';
import {
  DateRangeTableFilterType, PickerValues,
} from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import {
  getSalesOffsiteWorkingReportExcelThunk, getSalesOffsiteWorkingReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { SalesOffsiteWorkingReportOrdering } from '../../../../enum/ReportTableOrders';
import { salesOffsiteReportOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { getSalesOffsiteReportOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { getSalesOffsiteEntityTypesThunk } from '../../../../../store/thunks/coreThunk';
import { initSalesOffsiteWorkingReportData } from '../../../reports-content/sales/sales-offsite-working/utils/data';
import { SalesOffsiteWorkingReportFilters } from '../../../../types/SalesOffsiteWorkingReportTypes';

const SalesOffsiteWorkingGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkboxFilters = useAppSelector(salesOffsiteWorkingGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(salesOffsiteWorkingFiltersDateSelector);
  const filters = useAppSelector(salesOffsiteWorkingFiltersSelector);
  const ordering = useAppSelector(salesOffsiteReportOrderingSelector);
  const isTableView = useAppSelector(isTableViewSelector);

  const defaultPagingAndOrder = useDefaultPagingAndOrdering({
    filters,
    ordering,
    initialOrdering: SalesOffsiteWorkingReportOrdering.CreationDate,
    isAsc: false,
    setFiltersAction: setSalesOffsiteWorkingFilters,
    getOrderingThunk: getSalesOffsiteReportOrderingThunk,
  });

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        entityTypes, businessAreas, customers, assignees, creationDate,
      } = filters;
      const hasSelectedCheckboxes = [entityTypes, businessAreas, customers, assignees].some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = creationDate && (creationDate.min || creationDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker;
    }
    return false;
  }, [filters]);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getSalesOffsiteEntityTypesThunk());

    return () => {
      dispatch(setSalesOffsiteWorkingFilters(defaultPagingAndOrder));
      dispatch(setSalesOffsiteWorkingData(initSalesOffsiteWorkingReportData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    creationDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: SalesOffsiteWorkingReportFilters = {
      ...filters,
      page: 1,
      [field]: values || undefined,
    };
    dispatch(setSalesOffsiteWorkingFilters(newFilters));
    dispatch(setSalesOffsiteWorkingData(initSalesOffsiteWorkingReportData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setSalesOffsiteWorkingFilters(defaultPagingAndOrder));
    dispatch(setSalesOffsiteWorkingData(initSalesOffsiteWorkingReportData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setSalesOffsiteWorkingFilters({
      ...filters,
      page: 1,
      address: undefined,
      cityTown: undefined,
      postalCode: undefined,
      distanceFromOfficeMiles: undefined,
      [field]: values,
    }));
    dispatch(setSalesOffsiteWorkingData(initSalesOffsiteWorkingReportData));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const { order } = defaultPagingAndOrder;
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      page, pageSize, address, cityTown, postalCode, ...rest
    } = filters;
    dispatch(getSalesOffsiteWorkingReportExcelThunk({
      filters: {
        ...rest,
        order,
      },
    }));
  };

  const setSearchValue = (value: string) => {
    dispatch(setSalesOffsiteWorkingFilters({ ...filters, entityNumber: value }));
  };

  const pressEnterHandle = () => {
    if (!filters.entityNumber?.trim().length) {
      setSearchValue('');
    } else {
      dispatch(getSalesOffsiteWorkingReportThunk({ filters }));
      dispatch(setReportIsVisible(true));
    }
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch
        clearSearchValue={false}
        searchValue={filters.entityNumber}
        setSearchValue={setSearchValue}
        onPressEnter={pressEnterHandle}
        placeholder="Enter an entity number"
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            const newFilters = {
              ...filters,
              order: isTableView ? filters.order : defaultPagingAndOrder.order,
              address: isTableView ? filters.address : undefined,
              distanceFromOfficeMiles: isTableView ? filters.distanceFromOfficeMiles : undefined,
              cityTown: isTableView ? filters.cityTown : undefined,
              postalCode: isTableView ? filters.postalCode : undefined,
            };
            dispatch(getSalesOffsiteWorkingReportThunk({ filters: newFilters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default SalesOffsiteWorkingGlobalFilters;
