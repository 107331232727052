import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const NotesSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};

export type HandoverFields = {
  mileageMiles: string,
  fuelLevelPercent: number,
  transportationMethod: number | undefined,
  weatherConditions: number | undefined,
  isExteriorClean: number | undefined,
  isInteriorClean: number | undefined,
  numberOfVanKeys: string,
  hasTools: number | undefined,
  hasSpareWheel: number | undefined,
  notes: string | null,
  isPdiCompleted: number | undefined,
  isValeted: number | undefined,
  handoverVehicleCheckDecision: number | undefined,
}

export const handoverDefaults: HandoverFields = {
  mileageMiles: '',
  fuelLevelPercent: 0,
  transportationMethod: undefined,
  weatherConditions: undefined,
  isExteriorClean: undefined,
  isInteriorClean: undefined,
  numberOfVanKeys: '',
  hasTools: undefined,
  hasSpareWheel: undefined,
  notes: null,
  isPdiCompleted: undefined,
  isValeted: undefined,
  handoverVehicleCheckDecision: undefined,
};
