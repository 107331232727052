import React from 'react';
import Tr from '../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import { UserNormalizedType } from '../../../types/settingsTypes';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import { routesPath } from '../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../../common/components/private-line-link/PrivateLineLink';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';

type UserListCellsProps = {
  checkedKeys: number[],
  checkRows: NumberFunctionType,
  data: UserNormalizedType[],
  allowedToDelete?: boolean,
  columnsLength: number,
}

const UserListCells: React.FC<UserListCellsProps> = ({
  data, checkedKeys, checkRows, allowedToDelete, columnsLength,
}) => {
  const cellsWithTooltip = ['businessArea', 'jobType'];
  const cellsToSkip = ['id', 'employeeId'];

  const renderCellHandle = (key: string, value: string | number | null, id: number, employeeId: number | null) => {
    if (key === 'name') {
      return (
        <TruncateTooltip
          value={value}
          table
        />
      );
    }
    if (key === 'email') {
      return <PrivateLineLink
        to={`/${routesPath.USER_PROFILE}/${id}`}
        permission={PermissionEnum.UserViewPage}
      >
        <TruncateTooltip
          value={value}
          table
        />
      </PrivateLineLink>;
    }
    if (key === 'refNo') {
      if (employeeId) {
        return <PrivateLineLink
          to={`/${routesPath.EMPLOYEES}/${employeeId}`}
          permission={PermissionEnum.EmployeeViewPage}
        >
          {value}
        </PrivateLineLink>;
      } else {
        return <span>-</span>;
      }
    } else {
      return cellsWithTooltip.includes(key)
        ? <TruncateTooltip
          value={value}
          table
        />
        : value;
    }
  };
  return (
    <>
      {data.length > 0
        ? data.map((d, i) => (
          <Tr key={i}>
            {allowedToDelete && (
              <TdNew>
                <Checkbox checked={checkedKeys?.includes(d.id)} onChange={() => checkRows(d.id)} table />
              </TdNew>
            )}
            {Object.entries(d).slice(1).map(([key, value]) => {
              if (cellsToSkip.includes(key)) return null;
              return (
                <TdNew key={key}>
                  {renderCellHandle(key, value, d.id, d.employeeId)}
                </TdNew>
              );
            })}
          </Tr>
        ))
        : <EmptyTableSection text="No records" colSpan={allowedToDelete ? columnsLength : columnsLength - 1} />}
    </>
  );
};

export default UserListCells;
