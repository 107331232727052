import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapLocationDot, faWarehouse } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../core/enums/pathEnum';
import { PermissionEnum, VehicleFlowsEnum } from '../../../core/enums/dictionariesEnums';
import './VehicleItem.scss';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { VehicleItemUi } from '../types/VehiclesDashdoardTypes';

type VehicleItemProps = {
  data: VehicleItemUi,
}

const VehicleItem: React.FC<VehicleItemProps> = ({ data }) => {
  const {
    id, vehicleFlow, manufacturer, modelDescription, regNumber, chassisNumber, customerName, status, address,
  } = data;

  const info = [
    { label: 'Reg No', value: regNumber },
    { label: 'Chassis No', value: chassisNumber },
    { label: 'Customer', value: customerName },
  ];
  const isOnSite = vehicleFlow === VehicleFlowsEnum.OnSite;

  return (
    <PrivateLineLink
      to={`/${routesPath.VEHICLES}/${id}`}
      permission={PermissionEnum.VehicleViewPage}
      className="vehicleItem"
    >
      <span className="vehicleItem__main">
        <span className="vehicleItem__icon-wrapper">
          <Tooltip
            title={address}
            overlayClassName="vehicleItem__address-tooltip"
            placement="topLeft"
          >
            <FontAwesomeIcon icon={isOnSite ? faWarehouse : faMapLocationDot} className="vehicleItem__icon" />
          </Tooltip>
        </span>
        <TruncateTooltip
          hideTooltipOnMobile
          value={manufacturer}
          className="vehicleItem__make"
          overlayClassName="vehicleItem__overlay"
        />
        <TruncateTooltip
          hideTooltipOnMobile
          value={modelDescription}
          className="vehicleItem__desc"
          overlayClassName="vehicleItem__overlay"
        />
      </span>
      <ul className="vehicleItem__info">
        {info.map((el) => (
          <li key={el.label} className="vehicleItem__info-item">
            <span className="vehicleItem__label">{el.label}</span>
            <TruncateTooltip hideTooltipOnMobile value={el.value} className="vehicleItem__value" overlayClassName="vehicleItem__overlay" />
          </li>
        ))}
      </ul>
      <span className="vehicleItem__tag-wrapper">
        <TruncateTooltip hideTooltipOnMobile value={status} className="tag vehicleItem__tag" overlayClassName="vehicleItem__tag-overlay" />
      </span>
    </PrivateLineLink>
  );
};

export default VehicleItem;
