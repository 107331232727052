import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faPencil, faTrashAlt, faFileImport,
} from '@fortawesome/free-solid-svg-icons';
import { faFile } from '@fortawesome/free-regular-svg-icons';
import Tbody from '../../../../../core/components/table-components/Tbody';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { invoicesAndCreditNotesSelector } from '../../../../../store/selectors/invoicingSelectors';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import Tr from '../../../../../core/components/table-components/Tr';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { invoiceDocumentStatusValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import { editInvoicesAndCreditNotesStatusThunk } from '../../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import Tag from '../../../../../core/components/tag/Tag';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { localDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';

type InvoicesAndCreditNotesBodyProps = {
  setIdToEdit: NumberFunctionType,
  setIdToDelete: NumberFunctionType,
}

const InvoicesAndCreditNotesBody: React.FC<InvoicesAndCreditNotesBodyProps> = ({ setIdToEdit, setIdToDelete }) => {
  const { id: entityId } = useParams();
  const dispatch = useAppDispatch();
  const { pathname } = useLocation();
  const { items } = useAppSelector(invoicesAndCreditNotesSelector);
  const { draft, exported } = useAppSelector(invoiceDocumentStatusValuesSelector);
  const isSales = pathname.includes('sales');

  const hiddenKeys = ['status', 'id', 'type'];
  const truncateKeys = ['entityNumber', 'xeroRef'];
  const nowrapKeys = ['net', 'vat', 'gross'];
  const dateKeys = ['invoiceDate', 'dueDate'];

  const statusChangeHandle = (id: number, status: number) => {
    let statusTransition: number | null = null;
    if (status === draft && exported) statusTransition = exported;
    if (status === exported && draft) statusTransition = draft;
    statusTransition && entityId && dispatch(editInvoicesAndCreditNotesStatusThunk({
      id,
      statusTransition,
      entityId: +entityId,
      isSales,
    }));
  };

  const getActions = (id: number, status: number) => {
    const exportedActions = [{
      label: 'Mark as Draft',
      key: 'mark-as-draft',
      icon: <FontAwesomeIcon icon={faFile} />,
      onClick: () => statusChangeHandle(id, status),
    }];
    const draftActions = [
      {
        label: 'Mark as Exported',
        key: 'mark-as-exported',
        icon: <FontAwesomeIcon icon={faFileImport} />,
        onClick: () => statusChangeHandle(id, status),
      },
      {
        label: 'Edit entity',
        key: 'edit-entity',
        icon: <FontAwesomeIcon icon={faPencil} />,
        onClick: () => setIdToEdit(id),
      },
      {
        label: 'Delete entity',
        key: 'delete-entity',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => setIdToDelete(id),
      },
    ];

    return status === draft ? draftActions : exportedActions;
  };

  const renderCellValue = (key: string, value: string | number) => {
    if (key === 'statusLabel') return <Tag label={value} />;
    if (truncateKeys.includes(key)) return <TruncateTooltip value={value} table />;
    if (dateKeys.includes(key) && typeof value === 'string') return localDateFormatHandler('DD-MMM-YYYY', value);
    return value;
  };

  return (
    <Tbody>
      {!items.length
        ? <EmptyTableSection text="No records" colSpan={11} />
        : items.map((el) => <Tr key={el.id}>
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return <TdNew
              key={key}
              className={nowrapKeys.includes(key) ? 'nowrap' : ''}
            >
              {renderCellValue(key, value)}
            </TdNew>;
          })}
          <TdNew action>
            <Dropdown
              icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
              menuItems={getActions(el.id, el.status)}
            />
          </TdNew>
        </Tr>)}
    </Tbody>
  );
};

export default InvoicesAndCreditNotesBody;
