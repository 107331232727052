export const InitialQuoteLinesFilters = {
  page: 1,
  pageSize: 100,
  order: undefined,
  filters: {
    categoryIds: undefined,
    productName: undefined,
    productNumber: undefined,
    notes: undefined,
    quantity: undefined,
    unitPrice: undefined,
    netPrice: undefined,
    discount: undefined,
    weight: undefined,
    taxes: undefined,
  },
};
