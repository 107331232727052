import React, { useEffect, useState } from 'react';
import { Map as MapElement, useApiIsLoaded, useMap } from '@vis.gl/react-google-maps';
import Marker from './Marker';
import VanlinersMarker from './VanlinersMarker';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  salesOffsiteWorkingDataWithLocationSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import { vanlinersLocationSelector } from '../../../../../../store/selectors/coreSelectors';
import { DefaultAddressDto } from '../../../../../../common/types/commonTypes';
import { summaryFieldValueHandle } from '../../../../../../core/utils/summaryFieldValueHandle';
import { DEFAULT_CENTER, DEFAULT_ZOOM } from '../utils/data';

const Map: React.FC = () => {
  const items = useAppSelector(salesOffsiteWorkingDataWithLocationSelector);
  const vanlinersLocation = useAppSelector(vanlinersLocationSelector);

  const [selectedMarker, setSelectedMarker] = useState<string | null>(null);
  const [isVanlinersSelected, setIsVanlinersSelected] = useState(false);
  const [polylines, setPolylines] = useState<google.maps.Polyline[]>([]);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [vanlinersMarker, setVanlinersMarker] = useState<{ address: string, lat: number, lng: number } | null>(null);

  const map = useMap();
  const apiLoaded = useApiIsLoaded();

  useEffect(() => {
    if (apiLoaded && vanlinersLocation?.jobAddressLongitude && vanlinersLocation.jobAddressLatitude) {
      const addressFields: (keyof DefaultAddressDto)[] = ['addressLine1', 'addressLine2', 'cityTown', 'region', 'postalCode'];
      setMapLoaded(true);
      setVanlinersMarker({
        address: summaryFieldValueHandle(addressFields),
        lat: vanlinersLocation.jobAddressLatitude,
        lng: vanlinersLocation.jobAddressLongitude,
      });
    }
  }, [apiLoaded, vanlinersLocation?.jobAddressLatitude, vanlinersLocation?.jobAddressLongitude]);

  const drawPolylines = () => {
    if (mapLoaded) {
      const vanlinersPosition = new google.maps.LatLng({ lat: 53.5271516, lng: -2.5436137 });

      polylines.forEach((polyline) => polyline.setMap(null));
      setPolylines([]);

      const newPolylines = items.map(({ lat, lng, entityNumber }) => {
        const polyline = new google.maps.Polyline({
          path: [
            vanlinersPosition,
            new google.maps.LatLng(lat, lng),
          ],
          strokeWeight: 1.5,
          strokeOpacity: selectedMarker === null ? 1 : selectedMarker === entityNumber ? 1 : 0.5,
        });
        polyline.setMap(map);
        return polyline;
      });
      setPolylines(newPolylines);
    }
  };

  useEffect(() => {
    setSelectedMarker(null);
    setIsVanlinersSelected(false);
    if (map && vanlinersMarker) {
      if (!items.length) {
        polylines.forEach((polyline) => polyline.setMap(null));
        setPolylines([]);
        map.panTo({ lat: vanlinersMarker.lat, lng: vanlinersMarker.lng });
      } else {
        const bounds = new google.maps.LatLngBounds();
        if (bounds) {
          bounds.extend({ lat: vanlinersMarker.lat, lng: vanlinersMarker.lng });
          items.forEach((marker) => {
            bounds.extend({ lat: marker.lat, lng: marker.lng });
          });
          map.fitBounds(bounds, 20);

          drawPolylines();
        }
      }
    }
    // eslint-disable-next-line
  }, [items]);

  useEffect(() => {
    if (mapLoaded) {
      setIsVanlinersSelected(false);
      drawPolylines();
    }
    // eslint-disable-next-line
  }, [selectedMarker]);

  return (
    <MapElement
      mapId="offsite-working-map"
      defaultZoom={DEFAULT_ZOOM}
      defaultCenter={DEFAULT_CENTER}
      gestureHandling="cooperative"
      onClick={() => {
        setSelectedMarker(null);
        setIsVanlinersSelected(false);
      }}
      clickableIcons={false}
      disableDefaultUI
    >
      {vanlinersMarker && (
        <VanlinersMarker
          lat={vanlinersMarker.lat}
          lng={vanlinersMarker.lng}
          isSelected={isVanlinersSelected}
          setIsSelected={(value) => {
            setIsVanlinersSelected(value);
            setSelectedMarker(null);
          }}
        />

      )}
      {items.map((m) => (
        <Marker
          key={m.entityNumber}
          lat={m.lat}
          lng={m.lng}
          entityNumber={m.entityNumber}
          distance={m.distance}
          address={m.address}
          selectedName={selectedMarker}
          onClick={(name) => setSelectedMarker(name)}
        />
      ))}
    </MapElement>
  );
};

export default Map;
