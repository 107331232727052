import { InvoicingPageParamsEnums, InvoicingViewsEnums } from '../enums/InvoicingPageParamsEnums';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export const invoicingContainerOptions = [
  {
    value: InvoicingPageParamsEnums.SALES,
    label: InvoicingViewsEnums.SALES,
    restrictedTo: PermissionEnum.InvoicingSales,
  },
  {
    value: InvoicingPageParamsEnums.PURCHASES,
    label: InvoicingViewsEnums.PURCHASES,
    restrictedTo: PermissionEnum.InvoicingPurchases,
  },
  {
    value: InvoicingPageParamsEnums.NOMINAL_CODES,
    label: InvoicingViewsEnums.NOMINAL_CODES,
    restrictedTo: PermissionEnum.InvoicingNominalCodes,
  },
];
