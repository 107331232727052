import React from 'react';
import Thead from '../../../../../core/components/table-components/Thead';
import PricesColumns from './PricesColumns';
import { NumberFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import { PricelistTablePartType, PricesFiltersType } from '../../../../dashboard/types/pricelistTypes';
import PricesPartsBody from './PricesPartsBody';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';

type PricesTableProps = {
  checkedKeys: number[],
  isAll: boolean,
  isIndeterminate: boolean,
  pricesFilters: PricesFiltersType,
  parts: PricelistTablePartType[],
  setEditModal: NumberFunctionType,
  totalCount: number,
  getAffectedHandle: (dataToAffect: { partIds: number[] }) => void,
  setItemToDelete: NumberFunctionType,
  checkAll: VoidFunctionType,
  checkRow: NumberFunctionType,
}

const PricesTable: React.FC<PricesTableProps> = ({
  checkedKeys, parts, checkRow,
  isAll, isIndeterminate, checkAll, pricesFilters, setEditModal, totalCount, getAffectedHandle, setItemToDelete,
}) => {
  return (
    <TableNew>
      <Thead>
        <PricesColumns
          checkAll={checkAll}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          pricesFilters={pricesFilters}
          totalCount={totalCount}
        />
      </Thead>
      <PricesPartsBody
        parts={parts}
        checkedKeys={checkedKeys}
        checkRow={checkRow}
        setEditModal={setEditModal}
        getAffectedHandle={getAffectedHandle}
        setItemToDelete={setItemToDelete}
      />
    </TableNew>
  );
};

export default PricesTable;
