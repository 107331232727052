import React from 'react';
import Switch from '../../../../../core/components/switch/Switch';
import Pagination from '../../../../../core/components/pagination/Pagination';
import RectificationReportTable from './rectification-report-table/RectificationReportTable';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  rectificationReportDataSelector,
  rectificationReportsColumnsSelector,
  rectificationReportFiltersSelector,
  reportIsVisibleSelector, isTableViewSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setIsTableView, setRectificationReportFilters } from '../../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { getSalesRectificationReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import Segmented from '../../../../../core/components/segmented/Segmented';
import RectificationReportChart from './rectification-report-chart/RectificationReportChart';
import { useRectificationReportDefaultPaging } from '../../../../hooks/useRectificationReportDefaultPaging';

const RectificationReportContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(rectificationReportFiltersSelector);
  const tableData = useAppSelector(rectificationReportDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const columns = useAppSelector(rectificationReportsColumnsSelector);
  const isTableView = useAppSelector(isTableViewSelector);

  const defaultPagingAndOrder = useRectificationReportDefaultPaging(false);

  const CHART_COUNT_OPTIONS = [10, 15, 20];

  return (
    <>
      <div className="actions-and-paging reportActionsAndPaging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { costNet, salesNet, ...restFilters } = filters;
              const newFilters = { ...restFilters, ...defaultPagingAndOrder };
              dispatch(setRectificationReportFilters(newFilters));
              reportIsVisible && dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView ? (
          <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={tableData.totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setRectificationReportFilters(newFilters));
              reportIsVisible && dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
        )
          : <Segmented
            options={CHART_COUNT_OPTIONS}
            value={filters.pageSize}
            onChange={(value) => {
              const newFilters = { ...filters, page: 1, pageSize: +value };
              dispatch(setRectificationReportFilters(newFilters));
              reportIsVisible && dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
            }}
          />}
      </div>
      {isTableView
        ? <RectificationReportTable
          columns={columns}
          data={tableData.items.slice(0, filters.pageSize)}
          totalCount={tableData.totalCount}
        />
        : <RectificationReportChart />}
    </>
  );
};

export default RectificationReportContent;
