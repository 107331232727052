import { useSearchParams } from 'react-router-dom';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { OrderViewPageEnums } from '../enums/orderViewPageEnums';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';
import { useAppSelector } from '../../../store/hooks';
import { orderDetailsSelector } from '../../../store/selectors/sharedSelectors';

export const useOrderParams = () => {
  const { backHandle } = useLocationHistoryState(true);
  const [params, setSearchParams] = useSearchParams();
  const orderDetails = useAppSelector(orderDetailsSelector);
  const order = orderDetails?.order;

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };
  const options = [
    {
      label: 'Details & activity',
      value: OrderViewPageEnums.ORDER_DETAILS,
    },
    {
      label: `Part request ${tabCountHandle(order?.partRequestLinesCount)}`,
      value: OrderViewPageEnums.ORDER_PART_REQUEST,
    },
    {
      label: `Contacts ${tabCountHandle(order?.contactsCount)}`,
      value: OrderViewPageEnums.ORDER_CONTACTS,
    },
    {
      label: `Attachments ${tabCountHandle(orderDetails?.attachmentsCount)}`,
      value: OrderViewPageEnums.ORDER_ATTACHMENTS,
    },
    {
      label: `Links ${tabCountHandle(orderDetails?.linksCount)}`,
      value: OrderViewPageEnums.ORDER_LINKS,
    },
  ];

  return {
    params,
    backHandle,
    setModeParam,
    options,
    setSearchParams,
  };
};
