import React from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path,
} from 'react-hook-form';
import { VehicleDto } from '../../../core/types/coreTypes';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import SliderWithMarks from '../../../core/components/slider/SliderWithMarks';
import ChosenVehicleProfile from '../../../common/components/vehicle-modal/ChosenVehicleProfile';
import { useAppSelector } from '../../../store/hooks';
import { vehicleProfilesTypesUiSelector } from '../../../store/selectors/coreSelectors';
import { maxEightNumbersWhole } from '../../../core/utils/regex';

type CommonVehicleCheckFieldsProps<T extends FieldValues> = {
  control: Control<T>,
  errors: FieldErrors<T>,
  vehicleDetails: VehicleDto | null,
  disabled?: boolean,
}

const CommonVehicleCheckFields = <T extends FieldValues, >({
  control, errors, vehicleDetails, disabled,
}: CommonVehicleCheckFieldsProps<T>) => {
  const vehicleProfileTypes = useAppSelector(vehicleProfilesTypesUiSelector);
  const sliderMarks = {
    0: 'E',
    50: '1 / 2',
    100: 'F',
  };

  const vehicleData = {
    id: vehicleDetails?.id || 1,
    number: 1,
    code: '',
    manufacturer: vehicleDetails?.manufacturer || null,
    group: vehicleDetails?.group || '-',
    modelDescription: vehicleDetails?.modelDescription || null,
    type: vehicleDetails?.type ? (vehicleProfileTypes.find((el) => el.value === vehicleDetails.type)?.label || '-') : '-',
    maxPayload: vehicleDetails?.maxPayload || 0,
    grossVehicleWeightKg: vehicleDetails?.grossVehicleWeightKg || 0,
    slidingDoorsQuantity: vehicleDetails?.slidingDoorsQuantity || 0,
    chassisNumber: vehicleDetails?.chassisNumber || '-',
    regNumber: vehicleDetails?.regNumber || '-',
  };
  return (
    <>
      <ChosenVehicleProfile
        chosenVehicleProfile={vehicleDetails ? vehicleData : null}
        readOnly
        noCode
        extraInfo
        columnsCount={3}
      />
      <Controller
        name={'mileageMiles' as Path<T>}
        control={control}
        render={({ field }) => (
          <RegularInputNumber
            value={field.value}
            onChange={(e) => {
              const v = e.target.value;
              field.onChange(v);
            }}
            max={maxEightNumbersWhole}
            error={errors.mileageMiles?.message}
            label="Mileage, miles"
            disabled={disabled}
          />
        )}
      />
      <Controller
        name={'fuelLevelPercent' as Path<T>}
        control={control}
        render={({ field }) => (
          <SliderWithMarks
            marks={sliderMarks}
            value={field.value}
            onChange={field.onChange}
            label="Fuel level"
            disabled={disabled}
          />
        )}
      />
    </>
  );
};

export default CommonVehicleCheckFields;
