import React from 'react';
import { EmployeesPageParamEnums } from '../enums/EmployeesPageParamsEnums';
import SkillListPage from '../../skill-list/SkillListPage';
import EmployeesListPage from '../../dashboard/EmployeesListPage';
import { StringFunctionType } from '../../../core/types/coreTypes';
import EmployeesLeaveRequestsPage from '../../leave-request/EmployeesLeaveRequestsPage';
import EmployeesWorkCalendarPage from '../../work-calendar/EmployeesWorkCalendarPage';
import { EmployeeSchedule } from '../../work-calendar/components/modals/EmployeesWorkingHoursModal';

type EmployeesContentProps = {
  view: string | null;
  setEmployeeStatusParam: StringFunctionType,
  employeeStatusParam: string | null;
  setEmployeeSchedule: (value: EmployeeSchedule | null) => void,
}

const EmployeesContent: React.FC<EmployeesContentProps> = ({
  view,
  setEmployeeStatusParam,
  employeeStatusParam,
  setEmployeeSchedule,
}) => {
  const contentRenderFn = () => {
    switch (view) {
      case EmployeesPageParamEnums.DASHBOARD:
        return <EmployeesListPage view={view} setEmployeeStatusParam={setEmployeeStatusParam} employeeStatusParam={employeeStatusParam} />;
      case EmployeesPageParamEnums.WORK_CALENDAR:
        return <EmployeesWorkCalendarPage setEmployeeSchedule={setEmployeeSchedule} />;
      case EmployeesPageParamEnums.LEAVE_REQUESTS:
        return <EmployeesLeaveRequestsPage />;
      case EmployeesPageParamEnums.SKILL_LIST:
        return <SkillListPage />;
      default: return null;
    }
  };
  return (
    <>
      {contentRenderFn()}
    </>
  );
};

export default EmployeesContent;
