import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetOrdersResponseType,
  OrderDto,
  OrdersFiltersType,
} from '../../orders/dashboard/types/ordersTypes';
import {
  DraftLinePartList,
  DraftLinePartsFiltersType,
  DraftLinePartsKitFiltersType,
  DraftLinePartsKitList,
  ExtendedTimelineFilters,
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters,
  initExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
} from '../../common/types/commonTypes';
import {
  OrderContactDto,
  OrderSummaryType,
  OrderLinksResType, OrderEventDtoBase, OrderAffectedEntityDto, OrderContactsList,
} from '../../orders/orders-view-page/types/OrderViewPageTypes';
import {
  AssigneeDto,
  CollectionColumnPaginationType,
  CoreAttachmentDto,
  CoreAttachmentsFilters, CoreAttachmentsList, CoreCustomerContactsList,
  DataListType,
  DictionaryItem,
  GenericColumnType, GetAttachmentsResponse, GetCustomerContactsResponse,
  infiniteScrollInitPaging, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters,
  initLinksFilters,
  initSmallPaging,
  LinksFiltersType,
  PagingType,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  getOrderCollections,
} from '../thunks/orders/dashOrdersThunks';
import { getOrderAttachmentsThunk } from '../thunks/orders/orderAttachmentsThunks';
import { CustomerContactFiltersType } from '../../customers/customer-view-page/types/customerVewPageTypes';
import {
  getGenerateEmailOrderContactsThunk,
  getOrderContactsThunk,
  getOrderExistingContactsThunk,
} from '../thunks/orders/orderContactsThunks';
import {
  getOrderAffectedEntities,
  getOrderLinksThunk,
  getOrderSummaryThunk,
  getOrderTimelineThunk,
} from '../thunks/orders/viewPageOrderThunks';
import {
  getOrderPartRequestLines,
  getPartsKitsToOrderThunk,
  getStockPartsToOrderThunk,
} from '../thunks/orders/orderPartRequestThunks';
import { PartDtoType } from '../../stock/search/types/stockSearchTypes';
import { PartsKitsDto } from '../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { VehicleProfilesFilters } from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

export interface IOrdersState {
  filters: OrdersFiltersType,
  orderAttachments: CoreAttachmentsList,
  orderAttachmentsFilters: CoreAttachmentsFilters,
  vehicleStatuses: DictionaryItem[],
  ordersDashAssignees: AssigneeDto[],
  orderCollections: Array<GenericColumnType<Array<OrderDto>>> | null,
  ordersColumnsPagination: Array<CollectionColumnPaginationType>,
  orderContacts: OrderContactsList,
  orderContactsPaging: PagingType,
  orderGenerateEmailContacts: OrderContactsList,
  orderGenerateEmailContactsPaging: PagingType,
  orderExistingContacts: CoreCustomerContactsList,
  orderExistingContactsPaging: CustomerContactFiltersType,
  orderSummary: OrderSummaryType | null,
  orderLinksFilters: LinksFiltersType,
  orderLinks: OrderLinksResType | null,
  currentPartReqSource?: number,
  orderPartReqDraftLinesFilters: DraftLinePartsFiltersType,
  orderPartRequestParts: DraftLinePartList,
  orderPartRequestPartsKit: DraftLinePartsKitList,
  orderPartRequestPartsKitFilters: DraftLinePartsKitFiltersType,
  partRequestLinesFilters: PartReqLinesFilters | null,
  partRequestLines: { items: PartRequestLineDto[], allItemIds: number[] },
  orderTimeline: DataListType<OrderEventDtoBase[]>
  orderTimelineFilters: ExtendedTimelineFilters,
  orderCatalogVehicleProfileFilters: VehicleProfilesFilters,
  orderAffectedEntities: DataListType<OrderAffectedEntityDto[]>,
}

export const initialOrdersState: IOrdersState = {
  filters: infiniteScrollInitPaging,
  ordersColumnsPagination: [],
  orderAttachments: initDataListWithIds,
  orderAttachmentsFilters: initialCoreAttachmentsFilters,
  vehicleStatuses: [],
  ordersDashAssignees: [],
  orderCollections: null,
  orderLinksFilters: initLinksFilters,
  orderSummary: null,
  orderContacts: initDataListWithIds,
  orderContactsPaging: infiniteScrollInitPaging,
  orderGenerateEmailContactsPaging: infiniteScrollInitPaging,
  orderExistingContacts: initDataListWithIds,
  orderGenerateEmailContacts: initDataListWithIds,
  orderExistingContactsPaging: infiniteScrollInitPaging,
  orderLinks: null,
  currentPartReqSource: undefined,
  orderPartReqDraftLinesFilters: initDraftLinePartsFilters,
  orderPartRequestParts: initDataList,
  orderPartRequestPartsKit: initDataList,
  orderPartRequestPartsKitFilters: initDraftLinePartsKitFilters,
  partRequestLinesFilters: null,
  partRequestLines: { items: [], allItemIds: [] },
  orderTimeline: initDataList,
  orderTimelineFilters: initExtendedTimelineFilters,
  orderCatalogVehicleProfileFilters: initSmallPaging,
  orderAffectedEntities: initDataList,
};

export const ordersSlice = createSlice({
  name: 'orders',
  initialState: initialOrdersState,
  reducers: {
    setDashFilters: (state, action: PayloadAction<OrdersFiltersType>) => {
      state.filters = action.payload;
    },
    setOrderColumnsPagination: (state, action: PayloadAction<Array<CollectionColumnPaginationType>>) => {
      state.ordersColumnsPagination = action.payload;
    },
    setOrdersAttachmentsFilter: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.orderAttachmentsFilters = action.payload;
    },
    setOrderCollections: (state, action: PayloadAction<Array<GenericColumnType<Array<OrderDto>>> | null>) => {
      state.orderCollections = action.payload;
    },
    setOrderAttachments: (state, action: PayloadAction<CoreAttachmentDto[]>) => {
      state.orderAttachments = { ...state.orderAttachments, items: action.payload };
    },
    setOrderLinksFilters: (state, action: PayloadAction<LinksFiltersType>) => {
      state.orderLinksFilters = action.payload;
    },
    setOrderSummary: (state, action: PayloadAction<OrderSummaryType | null>) => {
      state.orderSummary = action.payload;
    },
    setOrderContacts: (state, action: PayloadAction<OrderContactsList>) => {
      state.orderContacts = action.payload;
    },
    setOrderContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.orderContactsPaging = action.payload;
    },
    setGenerateEmailOrderContacts: (state, action: PayloadAction<OrderContactsList>) => {
      state.orderGenerateEmailContacts = action.payload;
    },
    setGenerateEmailOrderContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.orderGenerateEmailContactsPaging = action.payload;
    },
    setOrderExistingContacts: (state, action: PayloadAction<CoreCustomerContactsList>) => {
      state.orderExistingContacts = action.payload;
    },
    setOrderExistingContactsPaging: (state, action:PayloadAction<PagingType>) => {
      state.orderExistingContactsPaging = action.payload;
    },
    setOrderLinks: (state, action: PayloadAction<OrderLinksResType | null>) => {
      state.orderLinks = action.payload;
    },
    setCurrentPartReqSource: (state, action: PayloadAction<number | undefined>) => {
      state.currentPartReqSource = action.payload;
    },
    setOrderPartReqDraftLinesFilters: (state, action: PayloadAction<DraftLinePartsFiltersType>) => {
      state.orderPartReqDraftLinesFilters = action.payload;
    },
    setOrderPartRequestParts: (state, action: PayloadAction<DraftLinePartList>) => {
      state.orderPartRequestParts = action.payload;
    },
    setOrderPartsKitDraftLinesFilters: (state, action: PayloadAction<DraftLinePartsKitFiltersType>) => {
      state.orderPartRequestPartsKitFilters = action.payload;
    },
    setOrderPartsKitRequestParts: (state, action: PayloadAction<DraftLinePartsKitList>) => {
      state.orderPartRequestPartsKit = action.payload;
    },
    setPartRequestLinesFilters: (state, action: PayloadAction<PartReqLinesFilters | null>) => {
      state.partRequestLinesFilters = action.payload;
    },
    setPartRequestLines: (state, action: PayloadAction<{ items:PartRequestLineDto[], allItemIds: number[] }>) => {
      state.partRequestLines = action.payload;
    },
    setOrderTimeline: (state, action: PayloadAction<DataListType<OrderEventDtoBase[]>>) => {
      state.orderTimeline = action.payload;
    },
    setOrderTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.orderTimelineFilters = action.payload;
    },
    setOrderCatalogVehicleProfileFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.orderCatalogVehicleProfileFilters = action.payload;
    },
    setOrderAffectedEntities: (state, action: PayloadAction<DataListType<OrderAffectedEntityDto[]>>) => {
      state.orderAffectedEntities = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getOrderCollections.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetOrdersResponseType | null>>) => {
        const orderCollections = action.payload.data?.orderCollections;
        const assignees = action.payload.data?.assignees;
        const collectionsList = orderCollections?.map((el) => ({
          status: el.status,
          filteredTotalCount: el.filteredTotalCount,
          totalCount: el.totalCount,
          totalCost: el.totalCost,
          pages: el.pages,
          isHidden: el.isHidden,
          order: el.order,
          list: el.orders,
        }));
        state.ordersDashAssignees = assignees || [];
        state.orderCollections = collectionsList || null;
        state.ordersColumnsPagination = orderCollections?.map((col) => ({ status: col.status, pages: col.pages, currentPage: 1 })) || [];
      },
    );
    builder.addCase(
      getOrderAttachmentsThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.orderAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getOrderSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<OrderSummaryType> | null>) => {
        state.orderSummary = action.payload?.data || null;
      },
    );
    builder.addCase(
      getOrderContactsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<OrderContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.orderContacts = {
          items: state.orderContactsPaging.page > 1
            ? [...state.orderContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getGenerateEmailOrderContactsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<OrderContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.orderGenerateEmailContacts = {
          items: state.orderGenerateEmailContactsPaging.page > 1
            ? [...state.orderGenerateEmailContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getOrderExistingContactsThunk.fulfilled,
      (state, action: PayloadAction<GetCustomerContactsResponse>) => {
        const {
          items, ...rest
        } = action.payload.data;
        state.orderExistingContacts = {
          items: state.orderExistingContactsPaging.page > 1
            ? [...state.orderExistingContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getOrderLinksThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<OrderLinksResType> | null>) => {
        const data = action.payload?.data;
        state.orderLinks = data || null;
      },
    );
    builder.addCase(
      getOrderPartRequestLines.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartRequestLineDto[], { allItemIds: number[] }>>) => {
        const { items, allItemIds } = action.payload.data;
        state.partRequestLines = { items, allItemIds };
      },
    );
    builder.addCase(
      getStockPartsToOrderThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[]> | null>) => {
        const data = action.payload?.data;
        if (data) {
          state.orderPartRequestParts = data;
        } else {
          state.orderPartRequestParts = initDataList;
        }
      },
    );
    builder.addCase(
      getPartsKitsToOrderThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitsDto[]> | null>) => {
        const data = action.payload?.data;
        if (data) {
          state.orderPartRequestPartsKit = data;
        } else {
          state.orderPartRequestPartsKit = initDataList;
        }
      },
    );
    builder.addCase(
      getOrderTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<OrderEventDtoBase[]>>) => {
        state.orderTimeline = action.payload.data;
      },
    );
    builder.addCase(
      getOrderAffectedEntities.fulfilled,
      (state, action: PayloadAction<ResponseResult<OrderAffectedEntityDto[]>>) => {
        state.orderAffectedEntities = action.payload.data;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setDashFilters,
  setOrderColumnsPagination,
  setOrdersAttachmentsFilter,
  setOrderCollections,
  setOrderAttachments,
  setOrderLinksFilters,
  setOrderSummary,
  setOrderContacts,
  setOrderContactsPaging,
  setGenerateEmailOrderContacts,
  setGenerateEmailOrderContactsPaging,
  setOrderExistingContacts,
  setOrderExistingContactsPaging,
  setOrderLinks,
  setCurrentPartReqSource,
  setOrderPartRequestParts,
  setOrderPartReqDraftLinesFilters,
  setOrderPartsKitDraftLinesFilters,
  setOrderPartsKitRequestParts,
  setPartRequestLinesFilters,
  setPartRequestLines,
  setOrderTimeline,
  setOrderTimelineFilters,
  setOrderCatalogVehicleProfileFilters,
  setOrderAffectedEntities,
} = ordersSlice.actions;

export default ordersSlice.reducer;
