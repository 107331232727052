import React from 'react';
import { faTags } from '@fortawesome/free-solid-svg-icons';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import { partPricesColumns } from '../../../../../utils/data';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import Tr from '../../../../../../../core/components/table-components/Tr';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import PartViewPricesSection from './PartViewPricesSection';
import { PartPricesDto, PartQuantityDto } from '../../../../../types/types';

type PartViewPricesProps = {
  prices: PartPricesDto | undefined,
  isConsumable: boolean,
}
const PartViewPrices:React.FC<PartViewPricesProps> = ({ prices, isConsumable }) => {
  const checkQuantity = (el: PartQuantityDto[]) => el.some((price) => price.quantity > 0);
  const showStockRows = prices && !isConsumable && prices.stockPrices.length > 0 && checkQuantity(prices.stockPrices);
  const showDueInRows = prices && !isConsumable && prices.dueInPrices.length > 0 && checkQuantity(prices.dueInPrices);
  const showPricelistRows = prices && prices.pricelists.length > 0;
  const isTableEmpty = !showStockRows && !showDueInRows && !showPricelistRows;

  return (
    <div className="info-grid__column">
      <h2 className="info-grid__title">Prices</h2>
      <TableNew className="partViewPricesTable">
        <Thead>
          <Tr>
            {partPricesColumns.map((col) => <ThNew
              key={col.dataIndex}
              className={`partViewPricesTh--${col.dataIndex}`}
            >
              {col.title}
            </ThNew>)}
          </Tr>
        </Thead>
        <Tbody>
          {isTableEmpty
            ? <EmptyTableSection colSpan={4} text="No prices" icon={faTags} />
            : <>
              {showStockRows
                  && <PartViewPricesSection title="Stock" childrenRows={prices.stockPrices.filter((price) => price.quantity > 0)} />}
              {showDueInRows
                  && <PartViewPricesSection title="Due in" childrenRows={prices.dueInPrices.filter((price) => price.quantity > 0)} />}
              {showPricelistRows
                  && <PartViewPricesSection title="Pricelist" childrenRows={prices.pricelists} />}
            </>}
        </Tbody>
      </TableNew>
    </div>
  );
};

export default PartViewPrices;
