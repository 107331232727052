import React from 'react';
import { MissingSellingPricesNotification } from '../../../account/types/AccountTypes';
import { useAppSelector } from '../../../store/hooks';
import { QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import { qtyStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { labelHandle } from '../../utils/labelHandle';

type MissingSellingPricesMessageProps = {
  message: MissingSellingPricesNotification,
}

const MissingSellingPricesMessage: React.FC<MissingSellingPricesMessageProps> = ({ message }) => {
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const statuses = useAppSelector(qtyStatusesSelector);

  return (
    <div>
      <span className="messageWrap__text">The following parts have items in stock without selling prices:</span>
      <ul className="messageWrap__prices-list">
        {message.partQuantities.map((el) => (
          <li key={el.partCode}>
            <b>{el.partCode}</b>
            {' - '}
            {el.quantity}
            {' '}
            {labelHandle(el.unitOfMeasure, unitsOfMeasure)}
            {' in '}
            {labelHandle(el.quantityStatus, statuses)}
            {' status'}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MissingSellingPricesMessage;
