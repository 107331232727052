import React from 'react';
import Button from '../../../../../core/components/button/Button';
import Switch from '../../../../../core/components/switch/Switch';
import { setPartQuantities, setPartQuantitiesPaging } from '../../../../../store/slices/stockSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';
import { initDataList, initSmallPaging } from '../../../../../core/types/coreTypes';
import { partQtyStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import {
  partQuantitiesPagingSelector,
  partQuantitiesTableViewSelector,
} from '../../../../../store/selectors/stockSelectors';
import { adjustPartQtyOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { useAdjustQtyFooterActions } from './useAdjustQtyFooterActions';

interface AdjustQtyHeaderActionsProps {
  setCurrentStatus: (value?: number) => void,
  currentStatus?: number,
}

const AdjustQtyHeaderActions = ({ setCurrentStatus, currentStatus }: AdjustQtyHeaderActionsProps) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const { free, reserved } = useAppSelector(partQtyStatusesValuesSelector);
  const pagingOrdering = useAppSelector(partQuantitiesPagingSelector);
  const adjustPartQtyOrdering = useAppSelector(adjustPartQtyOrderingSelector);
  const { totalCount } = useAppSelector(partQuantitiesTableViewSelector);

  const { setFields, initAdjustQtyFields } = useAdjustQtyFooterActions(currentStatus);

  const setCurrentStatusHandle = (st: number) => {
    dispatch(setPartQuantitiesPaging({
      ...initSmallPaging,
      order: {
        field: adjustPartQtyOrdering[0].value,
        isAsc: true,
      },
      hasSellingPrice: pagingOrdering.hasSellingPrice,
    }));
    dispatch(setPartQuantities(initDataList));
    setFields(initAdjustQtyFields);
    setCurrentStatus(st);
  };
  return <>
    <div className="adjustQty__header">
      <div>
        <Button
          label="Free"
          onClick={() => free && setCurrentStatusHandle(free)}
          designType="light-inverse"
          isActivated={currentStatus === free}
          offsetRight
          disabled={isFetching}
        />
        <Button
          label="Reserved"
          onClick={() => reserved && setCurrentStatusHandle(reserved)}
          designType="light-inverse"
          isActivated={currentStatus === reserved}
          disabled={isFetching}
        />
      </div>
      <Switch
        checked={pagingOrdering.hasSellingPrice === false}
        onChange={(value) => {
          dispatch(setPartQuantitiesPaging({
            ...pagingOrdering,
            page: 1,
            hasSellingPrice: value ? false : undefined,
          }));
        }}
        textLeft="All records"
        textRight="Without selling prices"
        singleBackground
      />
    </div>
    <Pagination
      currentPage={pagingOrdering.page}
      pageSize={pagingOrdering.pageSize}
      totalItems={totalCount}
      onChange={(page, pageSize) => dispatch(setPartQuantitiesPaging({ ...pagingOrdering, page, pageSize }))}
      pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
      offsetBottom
    />
  </>;
};

export default AdjustQtyHeaderActions;
