import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog, faPencil, faPercent,
} from '@fortawesome/free-solid-svg-icons';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useParams } from 'react-router-dom';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import { QuoteLinesFilters } from '../../../../types/enquiryDetailsTypes';
import {
  RangeTableFiltersType,
  SearchTableFilterType,
  NumericRangeType,
  VoidFunctionType,
} from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setQuoteLinesFilters } from '../../../../../../store/slices/enquiriesSlice';
import Tr from '../../../../../../core/components/table-components/Tr';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { enableQuoteLineOptionsThunk } from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import { openedVehicleIdSelector, quoteVehiclesListSelector } from '../../../../../../store/selectors/enquiriesSelector';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import {
  commonMinDecimal, maxCommonDecimal, maxDiscount,
} from '../../../../../../core/utils/regex';
import { initQuoteLinesFilterRanges } from '../../../../utils/testData';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';

type QuoteLinesColumnsProps = {
  columns: ICustomColumn[];
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
  totalElements: number;
  quoteLinesFilters: QuoteLinesFilters;
  disabled: boolean;
  maxNetPrice: string;
}

const QuoteLinesColumns: React.FC<QuoteLinesColumnsProps> = ({
  columns, isAll, isIndeterminate, checkAll, totalElements, quoteLinesFilters, disabled, maxNetPrice,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const openedVehicleId = useAppSelector(openedVehicleIdSelector);
  const quoteVehiclesList = useAppSelector(quoteVehiclesListSelector);
  const allowedToManage = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productName: quoteLinesFilters.filters?.productName,
    productNumber: quoteLinesFilters.filters?.productNumber,
    notes: quoteLinesFilters.filters?.notes,
  });
  const [rangePatterns, seRangePatterns] = useState<RangeTableFiltersType<NumericRangeType>>({
    quantity: { min: commonMinDecimal, max: maxCommonDecimal },
    unitPrice: { min: commonMinDecimal, max: maxCommonDecimal },
    netPrice: { min: commonMinDecimal, max: 0 },
    weight: { min: commonMinDecimal, max: maxCommonDecimal },
    discount: { min: commonMinDecimal, max: maxDiscount },
  });

  const linesFilters = quoteLinesFilters.filters;
  const [range, setRange] = useState<RangeTableFiltersType>({
    quantity: linesFilters?.quantity
      ? [linesFilters?.quantity.min.toString(), linesFilters?.quantity.max.toString()]
      : initQuoteLinesFilterRanges.quantity,
    unitPrice: linesFilters?.unitPrice
      ? [linesFilters?.unitPrice.min.toString(), linesFilters?.unitPrice.max.toString()]
      : initQuoteLinesFilterRanges.unitPrice,
    netPrice: linesFilters?.netPrice
      ? [linesFilters?.netPrice.min.toString(), linesFilters?.netPrice.max.toString()]
      : ['0.00', '100.00'],
    weight: linesFilters?.weight
      ? [linesFilters?.weight.min.toString(), linesFilters?.weight.max.toString()]
      : initQuoteLinesFilterRanges.weight,
    discount: linesFilters?.discount
      ? [linesFilters?.discount.min.toString(), linesFilters?.discount.max.toString()]
      : initQuoteLinesFilterRanges.discount,
  });
  useEffect(() => {
    if (maxNetPrice) {
      seRangePatterns({ ...rangePatterns, netPrice: { ...rangePatterns.netPrice, max: Number(maxNetPrice) } });
      setRange({
        ...range,
        netPrice: ['0.00', maxNetPrice],
      });
    }
    // eslint-disable-next-line
  }, [maxNetPrice]);

  const filters = [
    {
      dataIndex: 'tax',
      value: quoteLinesFilters.filters?.taxes || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        filters: {
          ...quoteLinesFilters.filters,
          taxes: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        },
      })),
    },
    {
      dataIndex: 'quoteCategory',
      value: quoteLinesFilters.filters?.categoryIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        filters: {
          ...quoteLinesFilters.filters,
          categoryIds: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        },
      })),
    },
    {
      dataIndex: 'priceSources',
      value: quoteLinesFilters.filters?.priceSources || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        filters: {
          ...quoteLinesFilters.filters,
          priceSources: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        },
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (quoteLinesFilters.order?.field === field) {
      dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        order: { ...quoteLinesFilters.order, isAsc: !quoteLinesFilters.order?.isAsc },
      }));
    } else {
      dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        order: { field, isAsc: true },
      }));
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setQuoteLinesFilters({
        ...quoteLinesFilters,
        filters: {
          ...quoteLinesFilters.filters,
          [field]: undefined,
        },
      }));
    }
  };
  const searchPressHandle = (field: string) => {
    dispatch(setQuoteLinesFilters({
      ...quoteLinesFilters,
      filters: {
        ...quoteLinesFilters.filters,
        [field]: searchVl[field]?.trim(),
      },
    }));
  };
  const setRangeHandle = (field: string, value: [number, number]) => {
    dispatch(setQuoteLinesFilters({
      ...quoteLinesFilters,
      filters: {
        ...quoteLinesFilters.filters,
        [field]: {
          min: value[0],
          max: value[1],
        },
      },
    }));
  };

  const currentVehicle = useMemo(
    () => quoteVehiclesList?.find((el) => el.id === openedVehicleId),
    [quoteVehiclesList, openedVehicleId],
  );

  const enableNotes = () => {
    id && currentVehicle && dispatch(enableQuoteLineOptionsThunk({
      id: currentVehicle.id,
      enquiryId: +id,
      data: { enableNotes: !currentVehicle.enableNotes },
    }));
  };
  const enableDiscount = () => {
    id && currentVehicle && dispatch(enableQuoteLineOptionsThunk({
      id: currentVehicle.id,
      enquiryId: +id,
      data: { enableDiscounts: !currentVehicle.enableDiscounts },
    }));
  };

  const linesActions = useMemo(() => [
    {
      label: currentVehicle?.enableDiscounts ? 'Disable discounts' : 'Enable discounts',
      icon: <FontAwesomeIcon icon={faPercent} />,
      onClick: enableDiscount,
      key: 'discount',
    },
    {
      label: currentVehicle?.enableNotes ? 'Disable notes' : 'Enable notes',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: enableNotes,
      key: 'notes',
    },
    // eslint-disable-next-line
  ], [currentVehicle]);

  return (
    <Tr>
      {allowedToManage && (
        <ThNew checkbox>
          <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAll} disabled={totalElements === 0 || disabled} table />
        </ThNew>
      )}
      {columns.map((col, i) => <ThWithControls
        key={i}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        setRangeHandle={setRangeHandle}
        totalElements={totalElements}
        rangePatterns={rangePatterns}
        range={range}
        setRange={setRange}
        filters={filters}
        searchVl={searchVl}
        tableFilters={quoteLinesFilters}
        className={`quoteLinesTh--${col.dataIndex}`}
      />)}
      {allowedToManage && (
        <ThNew action>
          <Dropdown
            icon={<FontAwesomeIcon icon={faCog} />}
            menuItems={linesActions}
            disabled={disabled}
          />
        </ThNew>
      )}
    </Tr>
  );
};

export default QuoteLinesColumns;
