import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import AccountFinalizationForm from './components/AccountFinalizationForm';
import './AccountFinalization.scss';
import { useAppDispatch } from '../../store/hooks';
import { validateInviteTokenThunk } from '../../store/thunks/settings/accountFinalizeThunks';

export type AccountFinalizationData = {
  firstName: string,
  lastName: string,
  email: string,
}

const AccountFinalizationContainer: React.FC = () => {
  const { token } = useParams();
  const [data, setData] = useState<AccountFinalizationData>({ email: '', firstName: '', lastName: '' });
  const dispatch = useAppDispatch();
  useEffect(() => {
    token && dispatch(validateInviteTokenThunk({ token, setData }));
    // eslint-disable-next-line
  }, [token]);
  return (
    <AccountFinalizationForm data={data} token={token} />
  );
};

export default AccountFinalizationContainer;
