import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { CreateVehicleRequestData } from './createVehicleTypes';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import {
  LATIN_LETTERS_NUMBERS_SPACES_SPECIAL_CHARS,
  LETTERS_NUMBERS_DASH_REGEX,
  LETTERS_NUMBERS_DASH_SPACES_REGEX,
} from '../../../core/utils/regex';

export type CreateVehicleFields = Pick<
CreateVehicleRequestData, 'additionalInfo' | 'manufacturer' | 'modelDescription' | 'regNumber'
> & {
  customerId?: number,
  type?: number,
  vehicleFlow?: number,
  assigneeId?: number,
  group?: number,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: string | null,
  maxPayload: string | null,
  chassisNumber: string | null,
}

export const createVehicleDefaultFields: CreateVehicleFields = {
  customerId: undefined,
  manufacturer: null,
  type: undefined,
  group: undefined,
  modelDescription: null,
  slidingDoorsQuantity: 0,
  grossVehicleWeightKg: '0',
  maxPayload: '0',
  chassisNumber: '',
  regNumber: null,
  vehicleFlow: undefined,
  additionalInfo: null,
  assigneeId: undefined,
};

export const AdditionalInfoFieldValidationSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};

export const VehicleManufacturerFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 50, message: maxLengthMessageHandle(50) },
  pattern: { value: LATIN_LETTERS_NUMBERS_SPACES_SPECIAL_CHARS, message: ErrorsEnum.NO_NON_LATIN_LETTERS },
};

export const ModelDescriptionFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 200, message: rangeLengthMessageHandle([5, 200]) },
  minLength: { value: 5, message: rangeLengthMessageHandle([5, 200]) },
};

export const RegNoFieldValidationSchema = {
  maxLength: { value: 10, message: rangeLengthMessageHandle([3, 10]) },
  minLength: { value: 3, message: rangeLengthMessageHandle([3, 10]) },
  pattern: {
    value: LETTERS_NUMBERS_DASH_SPACES_REGEX,
    message: 'Entered value can only include letters, numbers, spaces or hyphens.',
  },
};

export const ChassisNumberFieldValidationSchema = {
  maxLength: { value: 18, message: rangeLengthMessageHandle([5, 18]) },
  minLength: { value: 5, message: rangeLengthMessageHandle([5, 18]) },
  pattern: { value: LETTERS_NUMBERS_DASH_REGEX, message: 'Entered value can only include letters, numbers or hyphens.' },
};
