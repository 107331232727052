import React, { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  assignedNominalCodesFiltersSelector,
  assignedNominalCodesSummarySelector, isInvoicedAmountGreaterThanReceivedSelector,
  nominalCodesFiltersSelector,
  nominalCodesTooltipsSelector, purchasesViewOrderLinesFiltersSelector, purchasesViewOrderLinesSelector,
  purchasesViewOrderTableLinesSelector,
} from '../../../../store/selectors/invoicingSelectors';
import AssignNominalCodesModal from '../../../common/components/assign-nominal-codes/AssignNominalCodesModal';
import AddNominalCodeModal from '../../../common/components/add-nominal-record/AddNominalCodeModal';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { setAssignedNominalCodesFilters, setNominalCodesFilters } from '../../../../store/slices/invoicingSlice';
import { nominalRecordTypesValuesSelector } from '../../../../store/selectors/coreSelectors';
import { deleteNominalRecordThunk, getInvoicingPurchaseOrderLinesList } from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import OrderLinesBody from '../../../common/components/order-lines-common/OrderLinesBody';

const InvoicingOrderLinesBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(assignedNominalCodesFiltersSelector);
  const linesFilters = useAppSelector(purchasesViewOrderLinesFiltersSelector);
  const lines = useAppSelector(purchasesViewOrderTableLinesSelector);
  const nominalCodesFilters = useAppSelector(nominalCodesFiltersSelector);
  const nominalCodesTooltips = useAppSelector(nominalCodesTooltipsSelector);
  const assignNominalCodesSummary = useAppSelector(assignedNominalCodesSummarySelector);
  const isInvoicedAmountGreaterThanReceived = useAppSelector(isInvoicedAmountGreaterThanReceivedSelector);
  const { invoice } = useAppSelector(nominalRecordTypesValuesSelector);
  const data = useAppSelector(purchasesViewOrderLinesSelector);

  const [affectedId, setAffectedId] = useState<number | undefined>(undefined);
  const [isAssignNominalCodes, setIsAssignNominalCodes] = useState(false);
  const [isAddNominalCode, setIsAddNominalCode] = useState(false);
  const [affectedNominalRecord, setAffectedNominalRecord] = useState<number | undefined>(undefined);
  const [isDeleteRecord, setIsDeleteRecord] = useState<boolean>(false);

  const affectedPartLine = data?.purchaseOrderParts?.find((line) => line.id === affectedId);
  const affectedPartLineProperties = affectedPartLine
    ? {
      partNumber: affectedPartLine.partNumber,
      partName: affectedPartLine.partName,
      categoryName: affectedPartLine.categoryName,
      subcategoryName: affectedPartLine.subcategoryName,
    }
    : undefined;

  return <>
    <AssignNominalCodesModal
      isVisible={isAssignNominalCodes}
      onCancel={() => setIsAssignNominalCodes(false)}
      setIsAddNominalCode={(v) => {
        invoice && dispatch(setNominalCodesFilters({ ...nominalCodesFilters, types: [invoice] }));
        setIsAddNominalCode(v);
      }}
      deleteConfirmationHandler={(id) => {
        setIsDeleteRecord(true);
        setAffectedNominalRecord(id);
      }}
      editRecordHandler={(id) => {
        setIsAddNominalCode(true);
        setAffectedNominalRecord(id);
      }}
      affectedLine={affectedPartLineProperties}
      summarySectionData={assignNominalCodesSummary}
      isInvoicedAmountGreaterThanReceived={isInvoicedAmountGreaterThanReceived}
    />
    <AddNominalCodeModal
      isVisible={isAddNominalCode}
      affectedId={affectedId}
      onCancel={() => {
        setIsAddNominalCode(false);
        affectedNominalRecord && setAffectedNominalRecord(undefined);
      }}
      affectedNominalRecord={affectedNominalRecord}
      editMode={!!affectedNominalRecord}
    />
    <ConfirmationModal
      isVisible={isDeleteRecord}
      onCancel={() => {
        setIsDeleteRecord(false);
        setAffectedNominalRecord(undefined);
      }}
      confirmHandler={() => {
        affectedNominalRecord && dispatch(deleteNominalRecordThunk({
          recordId: affectedNominalRecord,
          closeModal: () => {
            setIsDeleteRecord(false);
            setAffectedNominalRecord(undefined);
          },
        }));
      }}
    />
    <OrderLinesBody
      lines={lines}
      nominalCodesTooltips={nominalCodesTooltips}
      openNominalCodesModal={(id: number) => {
        dispatch(setAssignedNominalCodesFilters({ ...filters, purchaseOrderPartIds: [id] }));
        setIsAssignNominalCodes(true);
        setAffectedId(id);
      }}
      onPatchComplete={(purchaseOrderId) => dispatch(getInvoicingPurchaseOrderLinesList({ purchaseOrderId, filters: linesFilters }))}
    />
  </>;
};

export default InvoicingOrderLinesBody;
