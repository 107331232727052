import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { SuppliersFiltersType } from '../../types/SuppliersTypes';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { SuppliersViewEnum } from '../../enums/SuppliersViewEnum';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useSuppliersFilters } from '../../hooks/useSuppliersFilters';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { getSupplierStatusesThunk } from '../../../../store/thunks/core/coreStatusesThunks';

type SupplierFiltersProps = {
  view: string | number;
  setView: (value: string | number) => void;
  suppliersFilters: SuppliersFiltersType;
}

const SupplierFilters:React.FC<SupplierFiltersProps> = ({
  view,
  setView,
  suppliersFilters,
}) => {
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  const {
    filtersList,
    setSearchValue,
    searchValue,
    filteredSuppliersHandle,
    onClear,
    initDropdownSearch,
  } = useSuppliersFilters();

  const tabs = useMemo(() => [
    {
      value: SuppliersViewEnum.DASHBOARD,
      label: SuppliersViewEnum.DASHBOARD,
      restrictedTo: PermissionEnum.SupplierDashboardPage,
    },
    {
      value: SuppliersViewEnum.CREATE,
      label: SuppliersViewEnum.CREATE,
      restrictedTo: PermissionEnum.SupplierCreatePage,
    },
  ], []);
  const tabAllowed = useCallback((permission: PermissionEnum) => {
    return !!currentUser?.userContextRole?.permissions.includes(permission);
  }, [currentUser]);
  const allowedItems = useMemo(() => tabs.filter((el) => tabAllowed(el.restrictedTo)), [tabs, tabAllowed]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        filteredSuppliersHandle({ ...suppliersFilters, keyword: searchValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!searchValue?.trim() && suppliersFilters.keyword) {
        filteredSuppliersHandle({ ...suppliersFilters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    dispatch(getSupplierStatusesThunk());
    // eslint-disable-next-line
  }, [])

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-sm">
          <Selectable
            options={allowedItems}
            value={view}
            onChange={(value) => setView(value as string)}
          />
        </div>
      )}
      <section className="sections-and-filters sections-and-filters--mobile-offset desktop-sticky-filters desktop-sticky-filters--top-sm">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedItems}
            value={view}
            onChange={(value) => setView(value as string)}
          />
        )}
        <KeywordFiltersSection
          initDropdownSearch={initDropdownSearch}
          searchValue={searchValue}
          setSearchValue={(v) => {
            setSearchValue(v);
          }}
          filters={filtersList}
          getFilteredResHandle={(value, field) => filteredSuppliersHandle({
            ...suppliersFilters, [field]: value,
          })}
          onClear={onClear}
        />
      </section>
    </>
  );
};

export default SupplierFilters;
