import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Pagination from '../../core/components/pagination/Pagination';
import StockPartRequestsList from './components/view-by-part-requests/StockPartRequestsList';
import './StockPartRequests.scss';
import StockPartRequestsFilters from './components/StockPartRequestsFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  stockPartRequestLinkedEntitiesTypesSelector,
} from '../../store/selectors/stockPartRequestsSelector';
import { setPartRequestDashboard } from '../../store/slices/stockPartRequestSlice';
import { StringFunctionType } from '../../core/types/coreTypes';
import {
  getStockPartRequestLinkedEntityTypes,
} from '../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { StockPartRequestDashboardInit } from './types/stockPartRequestTypes';
import { getPartRequestStatuses } from '../../store/thunks/core/coreStatusesThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { partRequestStatusesUiSelector } from '../../store/selectors/coreStatusesSelectors';
import {
  PartRequestsUrlParamsNames,
  StockPartReqAllStatusEnum,
  StockPartRequestViewEnum,
} from './enums/stockPartRequestsEnum';
import StockPartRequestLinesView from './components/view-by-request-line/StockPartRequestLinesView';
import { useStockPartRequestsPaging } from './hooks/useStockPartRequestsPaging';
import { PurchaseUrlParamsNames, StockTabParamEnum } from '../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { StockPageParamEnums } from '../container/enums/stockEnums';

type StockPartRequestsProps = {
  tabType: string | null;
  partReqStatus: string | null;
  setPartRequestStatus: StringFunctionType;
}

const StockPartRequests: React.FC<StockPartRequestsProps> = ({ tabType, partReqStatus, setPartRequestStatus }) => {
  const dispatch = useAppDispatch();
  const partRequestStatuses = useAppSelector(partRequestStatusesUiSelector);
  const linkedEntitiesTypes = useAppSelector(stockPartRequestLinkedEntitiesTypesSelector);
  const [params, setSearchParams] = useSearchParams();
  const pageParam = params.get(PurchaseUrlParamsNames.PAGE);
  const currentViewParam = params.get('view');

  useEffect(() => {
    if (!currentViewParam) {
      setSearchParams({
        [PurchaseUrlParamsNames.PAGE]: pageParam || StockPageParamEnums.PART_REQUESTS,
        [PurchaseUrlParamsNames.TAB_TYPE]: tabType || StockTabParamEnum.ACTIVE,
        [PartRequestsUrlParamsNames.PART_REQ_STATUS]: partReqStatus || StockPartReqAllStatusEnum.ALL.toString(),
        view: StockPartRequestViewEnum.REQUESTS,
      });
    }
    // eslint-disable-next-line
  }, [currentViewParam, pageParam, partReqStatus, tabType]);

  const { currentPaging, currentTotalCount, pageChangeHandle } = useStockPartRequestsPaging();

  useEffect(() => {
    if (!partRequestStatuses.length) {
      dispatch(getPartRequestStatuses());
    }
    if (!linkedEntitiesTypes.length) {
      dispatch(getStockPartRequestLinkedEntityTypes());
    }

    return () => {
      dispatch(setPartRequestDashboard(StockPartRequestDashboardInit));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <div className="stockPartRequests">
      <StockPartRequestsFilters
        tabType={tabType}
        partReqStatus={partReqStatus}
        setPartRequestStatus={setPartRequestStatus}
        currentView={currentViewParam}
      />
      {currentViewParam === StockPartRequestViewEnum.REQUESTS && <div className="actions-and-paging">
        <Pagination
          currentPage={currentPaging.page}
          pageSize={currentPaging.pageSize}
          totalItems={currentTotalCount}
          showSizeChanger
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          showTotal
          onChange={(page, pageSize) => pageChangeHandle(page, pageSize)}
          className="actions-and-paging__paging"
          narrow
        />
      </div>}
      {currentViewParam === StockPartRequestViewEnum.REQUESTS && <StockPartRequestsList />}
      {currentViewParam === StockPartRequestViewEnum.LINES && <StockPartRequestLinesView />}
    </div>
  );
};

export default StockPartRequests;
