import React, { useEffect } from 'react';
import Switch from '../../../../../core/components/switch/Switch';
import {
  setIsTableView, setSalesOffsiteWorkingFilters,
} from '../../../../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  reportIsVisibleSelector, salesOffsiteWorkingDataSelector, salesOffsiteWorkingFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { getSalesOffsiteWorkingReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { initPaging } from '../../../../../core/types/coreTypes';
import Segmented from '../../../../../core/components/segmented/Segmented';
import SalesOffsiteWorkingTable from './table/SalesOffsiteWorkingTable';
import MapComponent from './map/MapComponent';
import { getGoogleApiKey, getVanlinersOfficeLocation } from '../../../../../store/thunks/coreThunk';
import { SalesOffsiteWorkingReportOrdering } from '../../../../enum/ReportTableOrders';
import { salesOffsiteReportOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';

const SalesOffsiteWorkingContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isTableView = useAppSelector(isTableViewSelector);
  const ordering = useAppSelector(salesOffsiteReportOrderingSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(salesOffsiteWorkingFiltersSelector);
  const { totalCount } = useAppSelector(salesOffsiteWorkingDataSelector);

  const CHART_COUNT_OPTIONS = [10, 15, 20];

  useEffect(() => {
    dispatch(getGoogleApiKey());
    dispatch(getVanlinersOfficeLocation());
    // eslint-disable-next-line
  }, []);

  const orderByCreationDate = ordering.find(({ label }) => label === SalesOffsiteWorkingReportOrdering.CreationDate)?.value;

  return (
    <>
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              const newFilters = {
                ...filters,
                order: !val
                  ? filters.order
                  : orderByCreationDate ? { field: orderByCreationDate, isAsc: false } : undefined,
                ...initPaging,
                address: !val ? filters.address : undefined,
                distanceFromOfficeMiles: !val ? filters.distanceFromOfficeMiles : undefined,
                cityTown: !val ? filters.cityTown : undefined,
                postalCode: !val ? filters.postalCode : undefined,
              };
              dispatch(setSalesOffsiteWorkingFilters({ ...filters, ...initPaging }));
              reportIsVisible && dispatch(getSalesOffsiteWorkingReportThunk({ filters: newFilters }));
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Map view"
            singleBackground
          />
        </div>
        {isTableView
          ? <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setSalesOffsiteWorkingFilters(newFilters));
              reportIsVisible && dispatch(getSalesOffsiteWorkingReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
          : <Segmented
            options={CHART_COUNT_OPTIONS}
            value={filters.pageSize}
            onChange={(value) => {
              const newFilters = { ...filters, pageSize: +value };
              dispatch(setSalesOffsiteWorkingFilters(newFilters));
              reportIsVisible && dispatch(getSalesOffsiteWorkingReportThunk({ filters: newFilters }));
            }}
          />}
      </div>
      {isTableView
        ? <SalesOffsiteWorkingTable />
        : <MapComponent />}
    </>
  );
};

export default SalesOffsiteWorkingContent;
