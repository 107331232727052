import React, { useEffect, useState } from 'react';
import { faEllipsisVertical, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import SkillsListSection from '../../../../../../common/components/skills-list-section/SkillsListSection';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import SkillsModal from '../../../../../../common/components/select-skill-and-level-modal/SkillsModal';
import { DraftSkillLine } from '../../../../../../common/types/commonTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { employeeSkillsSelector } from '../../../../../../store/selectors/employeesSelector';
import { patchEmployeeThunk, getEmployeeSkillsByIdThunk } from '../../../../../../store/thunks/employee/employeeViewPageThunk';
import { employeeAPI } from '../../../../../../api/employeeApi';

const EmployeeSkillsContent: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employeeSkills = useAppSelector(employeeSkillsSelector);
  const allowedToManageSkills = usePermission(PermissionEnum.EmployeeEditFields);

  const [isSkillsModal, setSkillsModal] = useState(false);
  const [skills, setSkills] = useState<DraftSkillLine[]>([]);

  useEffect(() => {
    id && dispatch(getEmployeeSkillsByIdThunk({ id: +id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (employeeSkills && employeeSkills.length > 0) {
      const draftList: DraftSkillLine[] = [];
      for (let i = 0; i < employeeSkills.length; i++) {
        const currentCategory = employeeSkills[i];
        currentCategory.skills?.forEach((sk) => {
          const skill = {
            id: sk.id,
            number: draftList.length + 1,
            name: sk.name,
            categoryName: currentCategory.categoryName,
            level: sk.level,
          };
          draftList.push(skill);
        });
      }
      setSkills(draftList);
    }
  }, [employeeSkills]);

  const saveSkillsSetup = async (list: DraftSkillLine[]) => {
    const { data: { data: { allItemIds } } } = await employeeAPI.fetchEmployeeSkillsDictionary();
    const filteredList = list.filter((item) => allItemIds.includes(item.id));
    id && dispatch(patchEmployeeThunk({
      employeeId: +id,
      data: {
        skills: filteredList.length > 0
          ? filteredList.map((sk) => ({
            skillId: sk.id,
            level: sk.level,
          }))
          : [],
      },
      onClose: () => {
        setSkillsModal(false);
      },
    }));
  };

  return (
    <>
      <SkillsModal
        visible={isSkillsModal}
        onCancel={() => setSkillsModal(false)}
        setSkills={setSkills}
        skills={skills}
        saveSkills={saveSkillsSetup}
      />
      <section className="info-grid__column skillsWrap">
        <h2 className="info-grid__title">Skills</h2>
        <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={[{
            key: 'select-skills',
            label: 'Select skills and levels',
            icon: <FontAwesomeIcon icon={faPen} />,
            onClick: () => setSkillsModal(true),
          }]}
          disabled={!allowedToManageSkills}
          className="skillsDropdown"
        />
        <SkillsListSection
          totalElements={skills.length}
          skills={skills}
        />
      </section>
    </>
  );
};

export default EmployeeSkillsContent;
