import React from 'react';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import { inactivatePartsKitThunk } from '../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';

type SimpleInactivationModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  name: string,
  id?: number,
}

const SimpleInactivationModal: React.FC<SimpleInactivationModalProps> = ({
  isVisible, onCancel, name, id,
}) => {
  const dispatch = useAppDispatch();
  return (
    <ConfirmationModal
      isVisible={isVisible}
      onCancel={onCancel}
      customTitle="Inactivate parts kit"
      customCreateLabel="Yes, inactivate"
      confirmHandler={() => id && dispatch(inactivatePartsKitThunk({
        id,
        showNotification: true,
        closeModal: () => onCancel(),
      }))}
      customText={<>
        You are about to inactivate
        {' '}
        <b>{`"${name}"`}</b>
        {' '}
        parts kit.
      </>}
    />
  );
};

export default SimpleInactivationModal;
