import React, { useState } from 'react';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';
import { SuitableForVehicleChangeEventData } from '../../../types/commonTypes';
import Modal from '../../../../core/components/modal/Modal';
import Button from '../../../../core/components/button/Button';
import OldSuitableForVehicles from './OldSuitableForVehicles';
import NewSuitableForVehicles from './NewSuitableForVehicles';
import EmptyVehiclesList from '../../../../stock/common/components/empty-vehicles-list/EmptyVehiclesList';
import './SuitableForVehiclesChangeEventModal.scss';

type SuitableForVehiclesChangeEventModalProps = {
  visible: boolean,
  setIsVisible: BooleanFunctionType,
  data: SuitableForVehicleChangeEventData,
}

const SuitableForVehiclesChangeEventModal: React.FC<SuitableForVehiclesChangeEventModalProps> = ({
  visible,
  setIsVisible,
  data,
}) => {
  const {
    newSuitableForVehicleGroups = [],
    oldSuitableForVehicleGroups = [],
    newSuitableForVehicles = [],
    oldSuitableForVehicles = [],
  } = data;
  enum Modes {
    BEFORE,
    AFTER,
  }
  const [mode, setMode] = useState<Modes>(Modes.AFTER);
  const isOld = mode === Modes.BEFORE;
  const isOldForAll = !oldSuitableForVehicleGroups.length && !oldSuitableForVehicles.length;
  const isNewForAll = !newSuitableForVehicleGroups.length && !newSuitableForVehicles.length;

  return (
    <Modal
      visible={visible}
      onCancel={() => {
        setIsVisible(false);
        setMode(Modes.AFTER);
      }}
      title="Suitable for vehicles changes"
      width={850}
      destroyOnClose
    >
      <div className="event-modal-radio">
        <Button
          onClick={() => setMode(Modes.BEFORE)}
          label="Before"
          designType="light-inverse"
          isActivated={isOld}
          offsetRight
        />
        <Button
          onClick={() => setMode(Modes.AFTER)}
          label="After"
          designType="light-inverse"
          isActivated={!isOld}
        />
      </div>
      {isOld
        ? isOldForAll
          ? <ul><EmptyVehiclesList /></ul>
          : <OldSuitableForVehicles data={{ oldSuitableForVehicleGroups, oldSuitableForVehicles }} />
        : isNewForAll
          ? <ul><EmptyVehiclesList /></ul>
          : <NewSuitableForVehicles data={{ newSuitableForVehicleGroups, newSuitableForVehicles }} />}
    </Modal>
  );
};

export default SuitableForVehiclesChangeEventModal;
