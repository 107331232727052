import { useState } from 'react';
import {
  setInitStockCategories,
  setInitStockSubcategories, setPricelistAttachments, setPricelistAttachmentsFilters,
  setPricelistDetails,
  setPricelistParts,
  setPricelistTimeline,
  setPricelistTimelineFilters,
  setPricesFilters, setStockCategories,
  setStockParts, setStockSubcategories,
} from '../../../store/slices/pricelistSlice';
import { useAppDispatch } from '../../../store/hooks';
import {
  initDataListWithIds, initDataList, initialCoreAttachmentsFilters, initPaging,
} from '../../../core/types/coreTypes';
import { initExtendedTimelineFilters, initVehProfilesList } from '../../../common/types/commonTypes';
import { setSuitableVehicleProfiles } from '../../../store/slices/sharedSlice';
import { getPartRequestStatuses, getPurchaseOrderStatus } from '../../../store/thunks/core/coreStatusesThunks';
import { getPricelistEventTypesThunk } from '../../../store/thunks/core/coreEventTypesThunks';
import { getCustomers } from '../../../store/thunks/customers/customersDashboardThunks';
import { getPricelistSuppliers } from '../../../store/thunks/pricelist/pricelistThunks';
import { getSupplierList } from '../../../store/thunks/suppliers/supplierThunks';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';

export const usePricelistActions = () => {
  const dispatch = useAppDispatch();
  const { controller, cancelRequest } = useCancelRequest();
  const [isDuplicate, setDuplicate] = useState<boolean>(false);
  const [isAffected, setAffected] = useState<boolean>(false);
  const [isAffectedParts, setAffectedParts] = useState<boolean>(false);
  const [isConfirm, setConfirm] = useState<boolean>(false);
  const [isSimpleInactivate, setSimpleInactivate] = useState<boolean>(false);

  const getPricelistDictionaries = () => {
    cancelRequest();
    dispatch(getPartRequestStatuses());
    dispatch(getPurchaseOrderStatus());
    dispatch(getPricelistEventTypesThunk());
    dispatch(getCustomers({ params: undefined }));
    dispatch(getPricelistSuppliers({ keyword: undefined }));
    dispatch(getSupplierList({ filters: undefined, isDashboard: undefined, signal: controller.current?.signal }));
  };

  const unmountHandle = () => {
    dispatch(setPricelistDetails(null));
    dispatch(setPricelistParts(initDataListWithIds));
    dispatch(setPricesFilters(initPaging));
    dispatch(setStockParts(initDataListWithIds));
    dispatch(setSuitableVehicleProfiles(initVehProfilesList));
    dispatch(setStockCategories([]));
    dispatch(setStockSubcategories([]));
    dispatch(setInitStockCategories(null));
    dispatch(setInitStockSubcategories(null));
    dispatch(setPricelistAttachments(initDataListWithIds));
    dispatch(setPricelistAttachmentsFilters(initialCoreAttachmentsFilters));
    dispatch(setPricelistTimeline(initDataList));
    dispatch(setPricelistTimelineFilters(initExtendedTimelineFilters));
  };

  return {
    isDuplicate,
    setDuplicate,
    isAffected,
    setAffected,
    isAffectedParts,
    setAffectedParts,
    isConfirm,
    setConfirm,
    isSimpleInactivate,
    setSimpleInactivate,
    getPricelistDictionaries,
    unmountHandle,
  };
};
