import { Moment } from 'moment';
import { UseFormSetValue } from 'react-hook-form';
import { EmployeeEmploymentDto } from '../types/employeeViewTypes';
import { AddEmploymentFields } from './AddEmploymentSchema';

export const rangeOverlap = (
  range1: Moment[],
  range2: Moment[],
) => {
  const [start1, end1] = range1;
  const [start2, end2] = range2;

  // check range1 is between range2
  const startFirst = start1.isBetween(start2, end2, 'day', '[]');
  const endFirst = end1.isBetween(start2, end2, 'day', '[]');

  // check range2 is between range1
  const startLast = start2.isBetween(start1, end1, 'day', '[]');
  const endLast = end2.isBetween(start1, end1, 'day', '[]');

  return startFirst || endFirst || startLast || endLast;
};

export const setDefaultEmploymentValues = (found: EmployeeEmploymentDto, setValue: UseFormSetValue<AddEmploymentFields>) => {
  setValue('jobTitle', found.jobTitle || '');
  setValue('department', found.department || '');
  setValue('startDate', found.startDate);
  setValue('endDate', found.endDate);
  setValue('salaryType', found.salaryType || '');
  setValue('salaryValue', found.salaryValue?.toString() || '');
  setValue('annualLeaveEntitlement', found.annualLeaveEntitlement?.toString() || '');
};
