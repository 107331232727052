export enum UserEventTypesEnum {
  UserAccountCreation = 'UserAccountCreation',
  UserAccountFieldUpdateOrAddingValue = 'UserAccountFieldUpdateOrAddingValue',
  UserAccountStatusChange = 'UserAccountStatusChange',
  UserAccountDeletion = 'UserAccountDeletion',
  Note = 'Note',
}

export enum UserActivityEnum {
  USER,
  CRM,
}

export const userActivityTypeOptions = [
  { label: 'User activity', value: UserActivityEnum.USER },
  { label: 'CRM activity', value: UserActivityEnum.CRM },
];
