import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, BooleanFunctionType, ResponseResult } from '../../../core/types/coreTypes';
import { setDashboardLoading, setLoading } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import {
  CustomerDto,
  CustomersListFetchType,
} from '../../../customers/customers-dashboard/types/customersDashboardTypes';

export const getCustomers = createAsyncThunk<
ResponseResult<CustomerDto[] | null>,
{
  params?: CustomersListFetchType,
  setIsFetching?: BooleanFunctionType,
  isDashboard?: boolean,
  signal?: AbortSignal,
}
>(
  'get/Customers',
  async ({
    params, setIsFetching, isDashboard, signal,
  }, { dispatch, rejectWithValue }) => {
    const handleLoading = (value: boolean) => {
      if (isDashboard) {
        dispatch(setDashboardLoading(value));
      } else {
        dispatch(setLoading(value));
        setIsFetching && setIsFetching(value);
      }
    };
    handleLoading(true);
    try {
      const response = await customersAPI.fetchCustomersList(params, signal);
      handleLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      setIsFetching && setIsFetching(false);
      return rejectWithValue(error.response?.data);
    }
  },
);
