import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getJobAttachmentByIdThunk, getJobAttachmentsThunk } from '../../../../../store/thunks/jobs/jobThunks';
import { jobAttachmentsFiltersSelector, jobAttachmentsSelector } from '../../../../../store/selectors/jobsSelector';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { setJobAttachFilters } from '../../../../../store/slices/jobsSlice';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import JobAttachmentsTable from '../../../../job-view-page/components/job-content/attachments/JobAttachmentsTable';

type JobLineExpandedAttachmentsProps = {
  jobId: number,
  narrowPagination?: boolean,
}

const JobLineExpandedAttachments: React.FC<JobLineExpandedAttachmentsProps> = ({ jobId, narrowPagination = true }) => {
  const dispatch = useAppDispatch();
  const jobAttachmentsFilters = useAppSelector(jobAttachmentsFiltersSelector);
  const { items, totalCount } = useAppSelector(jobAttachmentsSelector);
  useEffect(() => {
    dispatch(getJobAttachmentsThunk({
      id: jobId,
      params: jobAttachmentsFilters,
    }));
    // eslint-disable-next-line
  }, [jobAttachmentsFilters]);
  return (
    <div>
      <div className="actions-and-paging">
        {totalCount > 0 && <Pagination
          currentPage={jobAttachmentsFilters.page}
          pageSize={jobAttachmentsFilters.pageSize}
          onChange={(page, pageSize) => {
            dispatch(setJobAttachFilters({ ...jobAttachmentsFilters, page, pageSize }));
          }}
          totalItems={totalCount}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          narrow={narrowPagination}
          className="actions-and-paging__paging"
        />}
      </div>
      <JobAttachmentsTable
        attachments={items}
        checkedKeys={[]}
        isAll={false}
        indeterminate={false}
        // eslint-disable-next-line
        checkAllHandle={() => {}}
        // eslint-disable-next-line
        checkRow={() => {}}
        readOnly
        getAttachment={(attachId: number) => {
          dispatch(getJobAttachmentByIdThunk({ id: attachId, jobId }));
        }}
      />
    </div>
  );
};

export default JobLineExpandedAttachments;
