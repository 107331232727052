import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  rectificationsBySupplierFiltersDateSelector, rectificationsBySupplierFiltersSelector,
  rectificationsBySupplierGlobalFiltersSelector,
  rectificationsBySupplierTreeSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { useRectificationsBySupplierReportDefaultOrdering } from '../../../../hooks/useRectificationsBySupplierReportDefaultOrdering';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import {
  getRectificationsBySupplierReportExcelThunk,
  getRectificationsBySupplierReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import {
  setRectificationsBySupplierData,
  setRectificationsBySupplierFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import {
  DateRangeTableFilterType, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import { getCustomersDictionaryThunk, getSuppliersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { getRectificationFaultTypesThunk } from '../../../../../store/thunks/coreThunk';
import { initRectificationsBySupplierData } from '../../../reports-content/sales/rectifications-by-supplier/utils/data';
import { RectificationsBySupplierReportFilters } from '../../../../types/RectificationsBySupplierReportTypes';

const RectificationsBySupplierGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkboxFilters = useAppSelector(rectificationsBySupplierGlobalFiltersSelector);
  const treeFilters = useAppSelector(rectificationsBySupplierTreeSelector);
  const datePickerFilters = useAppSelector(rectificationsBySupplierFiltersDateSelector);
  const filters = useAppSelector(rectificationsBySupplierFiltersSelector);
  const globalFiltersNames = [...checkboxFilters, ...datePickerFilters].map((el) => el.name as keyof RectificationsBySupplierReportFilters);
  const isSelectedGlobalFilters = globalFiltersNames.some((field) => filters[field]);

  const [isExpanded, setIsExpanded] = useState(false);

  const defaultPagingAndOrder = useRectificationsBySupplierReportDefaultOrdering();

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getRectificationFaultTypesThunk());
    dispatch(getSuppliersDictionaryThunk({ filters: undefined }));

    return () => {
      dispatch(setRectificationsBySupplierFilters({ ...initPaging, ...defaultPagingAndOrder }));
      dispatch(setRectificationsBySupplierData(initRectificationsBySupplierData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    completedDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: RectificationsBySupplierReportFilters = {
      ...filters,
      page: 1,
      [field]: values || undefined,
    };
    dispatch(setRectificationsBySupplierFilters(newFilters));
    dispatch(setRectificationsBySupplierData(initRectificationsBySupplierData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setRectificationsBySupplierFilters({
      ...defaultPagingAndOrder,
      page: 1,
      pageSize: filters.pageSize,
    }));
    dispatch(setRectificationsBySupplierData(initRectificationsBySupplierData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setRectificationsBySupplierFilters({
      ...filters,
      page: 1,
      usedInRectifications: undefined,
      [field]: values,
    }));
    dispatch(setRectificationsBySupplierData(initRectificationsBySupplierData));
    dispatch(setReportIsVisible(false));
  };

  const treeChangeHandle = (checkedKeys: React.Key[]) => {
    const checkedSubcategories = checkedKeys.filter((el) => !el.toString().includes('category'));
    const values = checkedSubcategories.map((s) => +s);
    getFilteredResHandle(values, 'subcategories');
  };

  const exportToExcel = () => {
    const { order } = defaultPagingAndOrder;
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      page, pageSize, partCode, partName, usedInRectifications, ...rest
    } = filters;
    dispatch(getRectificationsBySupplierReportExcelThunk({
      filters: {
        ...rest,
        order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        treeFilters={treeFilters}
        treeChangeHandle={treeChangeHandle}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getRectificationsBySupplierReportThunk({
              filters,
            }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default RectificationsBySupplierGlobalFilters;
