import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import { routesPath } from '../../../core/enums/pathEnum';
import Layout from '../../../core/components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import InvoicingViewPageHead from './InvoicingViewPageHead';
import InvoicingViewStaticSection from './InvoicingViewStaticSection';
import InvoicingViewDynamicSection from './InvoicingViewDynamicSection';
import {
  setInvoicesAndCreditNotes,
  setInvoicesAndCreditNotesFilters,
  setInvoicingPurchaseOrder,
  setPurchaseViewOrderLinesSummary,
} from '../../../store/slices/invoicingSlice';
import {
  changeInvoicingPurchaseOrderStatusThunk,
  getInvoicingPurchaseOrderById,
  getInvoicingPurchaseOrderLinesList, getInvoicingPurchaseOrderLinesSummaryThunk,
} from '../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { InvoicingPageParamsEnums } from '../../container/enums/InvoicingPageParamsEnums';
import { invoicingPurchaseOrderSelector, purchasesViewOrderLinesFiltersSelector } from '../../../store/selectors/invoicingSelectors';
import { getPurchaseOrderPartsOrdering } from '../../../store/thunks/core/coreOrderingThunks';
import {
  getInvoiceDocumentTypesThunk,
  getNominalCodeTypesThunk,
  getNominalRecordStates,
  getNominalRecordTypes,
  getPurchaseOrderPartAmountInputTypesThunk,
} from '../../../store/thunks/coreThunk';
import { getInvoiceDocumentStatusesThunk } from '../../../store/thunks/core/coreStatusesThunks';
import { getPurchaseOrderPartVat } from '../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import PurchaseInvoicingActivityLogModal
  from './modals/purchase-invoicing-activity-log/PurchaseInvoicingActivityLogModal';
import { initDataList, initPaging } from '../../../core/types/coreTypes';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { purchaseOrderStatusObjSelector } from '../../../store/selectors/coreStatusesSelectors';

const InvoicingViewPage: React.FC = () => {
  const { id } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(purchasesViewOrderLinesFiltersSelector);
  const { backHandle } = useLocationHistoryState(true);
  const { canBeMarkedAsInvoiced } = useAppSelector(invoicingPurchaseOrderSelector) || {};
  const { completed } = useAppSelector(purchaseOrderStatusObjSelector);

  const [isActivityModal, setIsActivityModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const mode = params.get('mode');

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };

  const actions = [
    {
      label: 'Activity log',
      key: 'activity-log',
      icon: <FontAwesomeIcon icon={faChartLine} />,
      onClick: () => setIsActivityModal(true),
      restrictedTo: PermissionEnum.StockPurchaseOrdersViewPage,
    },
    {
      label: 'Mark as Invoiced',
      key: 'mark-as-invoiced',
      icon: <FontAwesomeIcon icon={faFileInvoice} />,
      onClick: () => setConfirmationModal(true),
      restrictedTo: PermissionEnum.StockPurchaseOrdersViewPage,
      disabled: !completed || !canBeMarkedAsInvoiced,
    },
  ];

  const handleMarkAsInvoiced = () => {
    id && completed && dispatch(changeInvoicingPurchaseOrderStatusThunk({
      purchaseOrderId: +id,
      statusTransition: completed,
      onFinish: () => setConfirmationModal(false),
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getInvoicingPurchaseOrderById({ id: +id }));
      dispatch(getInvoicingPurchaseOrderLinesSummaryThunk({ purchaseOrderId: +id }));
    }
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    if (id) {
      dispatch(getInvoicingPurchaseOrderLinesList({ purchaseOrderId: +id, filters }));
    }
    // eslint-disable-next-line
  }, [id, filters]);

  useEffect(() => {
    dispatch(getPurchaseOrderPartsOrdering());
    dispatch(getNominalRecordTypes());
    dispatch(getNominalRecordStates());
    dispatch(getInvoiceDocumentStatusesThunk());
    dispatch(getPurchaseOrderPartVat());
    dispatch(getInvoiceDocumentTypesThunk());
    dispatch(getNominalCodeTypesThunk());
    dispatch(getPurchaseOrderPartAmountInputTypesThunk());
    return () => {
      dispatch(setInvoicingPurchaseOrder(null));
      dispatch(setPurchaseViewOrderLinesSummary(null));
      dispatch(setInvoicesAndCreditNotesFilters(initPaging));
      dispatch(setInvoicesAndCreditNotes(initDataList));
    };
    // eslint-disable-next-line
  }, []);
  return <Layout subHeader={
    <DetailsHead
      goBack={() => backHandle(`/${routesPath.INVOICING}/page=${InvoicingPageParamsEnums.PURCHASES}`)}
      actions={actions}
      className="invoicingViewPageDetailsHead"
    >
      <InvoicingViewPageHead />
    </DetailsHead>
  }
  >
    <>
      <PurchaseInvoicingActivityLogModal isVisible={isActivityModal} onCancel={() => setIsActivityModal(false)} />
      <ConfirmationModal
        customTitle="Confirm"
        customText="The order will be marked as invoiced."
        isVisible={confirmationModal}
        onCancel={() => setConfirmationModal(false)}
        confirmHandler={handleMarkAsInvoiced}
        customCreateLabel="Yes, mark as invoiced"
        isProceedQuestion
      />
      <InvoicingViewStaticSection
        mode={mode}
        setModeParam={setModeParam}
      />
      <InvoicingViewDynamicSection mode={mode} />
    </>
  </Layout>;
};

export default InvoicingViewPage;
