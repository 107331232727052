import React, { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  getPartInfoForDistributeQty, getPartRequestByLineThunk,
} from '../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  stockPartRequestsLinesViewFiltersSelector,
} from '../../../../store/selectors/stockPartRequestsSelector';
import StockPartRequestLinesViewTable from './StockPartRequestLinesViewTable';
import AllocatePricelistQtyModal
  from '../../../stock-part-request-view/components/modals/allocate-pricelist-qty/AllocatePricelistQtyModal';
import EditReadyCollectedModal
  from '../../../stock-part-request-view/components/modals/edit-ready-collected/EditReadyCollectedModal';
import EditReadyCollectedConfirmModal
  from '../../../stock-part-request-view/components/modals/edit-ready-collected-confirm-modal/EditReadyCollectedConfirmModal';
import SelectProductSourceDistributeQty
  from '../../../stock-part-request-view/components/modals/select-product-source-distribute-qty/SelectProductSourceDistributeQty';
import ConfirmReturnedPartsModal
  from '../../../stock-part-request-view/components/modals/confirm-returned-parts/ConfirmReturnedPartsModal';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { usePartRequestLinesActions } from '../../../common/hooks/usePartRequestLinesActions';
import {
  getPartRequestLineStatuses,
} from '../../../../store/thunks/core/coreStatusesThunks';
import FillInDraftLineModal from '../../../../common/components/fill-in-draft-line-modal/FillInDraftLineModal';
import {
  setStockPartRequestDetails, setStockPartRequestsLinesViewFilters,
  setStockPartsToFillPartRequest,
  setStockPartsToFillPartRequestFilters,
} from '../../../../store/slices/stockPartRequestSlice';
import {
  initDraftLinePartsFilters,
} from '../../../../common/types/commonTypes';
import { initDataList } from '../../../../core/types/coreTypes';
import SelectProductSourceModal
  from '../../../../common/components/select-product-source-modal/SelectProductSourceModal';
import { setProductSourceDictionary, setProductSourceTotal } from '../../../../store/slices/sharedSlice';
import { useFillInDraftLineAndSelectProductSource } from '../../hooks/useFillInDraftLineAndSelectProductSource';
import LineDetailsModal from './LineDetailsModal';
import { partRequestLineStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { useCancelRequest } from '../../../../core/hooks/useCancelRequest';
import { PartRequestsUrlParamsNames } from '../../enums/stockPartRequestsEnum';
import { StockTabParamEnum } from '../../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';

const StockPartRequestLinesView: React.FC = () => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const tabType = searchParams.get(PartRequestsUrlParamsNames.TAB_TYPE);
  const isActive = tabType === StockTabParamEnum.ACTIVE;

  const [affectedLineId, setAffectedLineId] = useState<number | undefined>(undefined);

  const filters = useAppSelector(stockPartRequestsLinesViewFiltersSelector);
  const lineStatuses = useAppSelector(partRequestLineStatusesSelector);

  const { controller, cancelRequest } = useCancelRequest();

  const {
    currentLine,
    isAllocatePricelistQty, setIsAllocatePricelistQty,
    editReadyCollected, setEditReadyCollected,
    isDistributeQty, setDistributeQty,
    isMarkNotFromStock, setIsMarkNotFromStock, markAsNotFromStockHandler,
    isConfirmReturnedParts, setConfirmReturnedParts, affectedLineConfirmedData,
    isConfirmEdit, setIsConfirmEdit, confirmEditMode, setConfirmEditMode,
    partQtyId, setPartQtyId, fillInModal, setFillInModal, isViewDetails, setViewDetails,
  } = usePartRequestLinesActions(setAffectedLineId, affectedLineId, true);

  const {
    coreInfoSource, nonCoreInfoSources, infoSources,
    fillPartRequestFilters, fillPartRequestParts,
    productSourceTotal, productSourceDictionary,
    sourceModalLines, fetchPartsToFillPartReq,
    getRemapProductSourceDictionaryHandle, getRemapProductSourceTotalHandle,
    createPriceSourceHandle, cancelProductSourceModal,
    filledPartId, setFilledPartId,
    prodSourceModal, setProdSourceModal, infoSourceV, setInfoSourceV,
  } = useFillInDraftLineAndSelectProductSource(affectedLineId);

  useEffect(() => {
    if (!lineStatuses.length) {
      dispatch(getPartRequestLineStatuses());
    }
    return () => {
      cancelRequest();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    cancelRequest();
    dispatch(getPartRequestByLineThunk({ filters, signal: controller.current?.signal }));
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    dispatch(setStockPartRequestsLinesViewFilters({ ...filters, isActive, page: 1 }));
    // eslint-disable-next-line
  }, [isActive]);

  const setDistributeQtyHandle = useCallback((id: number) => {
    setAffectedLineId(id);
    dispatch(getPartInfoForDistributeQty({
      affectedLineId: id,
      setDistributeQtyModal: () => setDistributeQty(true),
    }));
  }, [dispatch, setDistributeQty]);

  return (
    <>
      <AllocatePricelistQtyModal
        visible={isAllocatePricelistQty}
        onCancel={() => setIsAllocatePricelistQty(false)}
        sourceId={affectedLineId}
        currentLine={currentLine}
        isLinesView
      />
      <EditReadyCollectedModal
        visible={editReadyCollected}
        onCancel={() => {
          setEditReadyCollected(false);
          setAffectedLineId(undefined);
        }}
        setConfirmEditMode={(v, partQuantityId) => {
          setConfirmEditMode(v);
          setIsConfirmEdit(true);
          setPartQtyId(partQuantityId);
        }}
        openAllocatePricelist={() => setIsAllocatePricelistQty(true)}
        currentLine={currentLine}
      />
      <EditReadyCollectedConfirmModal
        visible={isConfirmEdit}
        onCancel={() => {
          setIsConfirmEdit(false);
          setConfirmEditMode('');
          setPartQtyId(undefined);
        }}
        confirmEditMode={confirmEditMode}
        sourceId={affectedLineId}
        partQuantityId={partQtyId}
        isLinesView
      />
      <FillInDraftLineModal
        isVisible={fillInModal}
        onCancel={() => {
          setFillInModal(false);
          setAffectedLineId(undefined);
          dispatch(setStockPartRequestDetails(null));
        }}
        title="Fill in part request line"
        infoSources={infoSources}
        chooseInfoSource={(v) => setInfoSourceV(v)}
        currentInfoSource={infoSourceV}
        setInitFiltersAndTableData={() => {
          dispatch(setStockPartsToFillPartRequestFilters(initDraftLinePartsFilters));
          dispatch(setStockPartsToFillPartRequest(initDataList));
        }}
        isCorePart={coreInfoSource?.value === infoSourceV}
        isNonCorePart={nonCoreInfoSources?.value === infoSourceV}
        editedLine={affectedLineId}
        setProdSourceModal={(partId: number) => {
          setFilledPartId(partId);
          getRemapProductSourceDictionaryHandle(partId, () => setProdSourceModal(true));
        }}
        parts={fillPartRequestParts}
        setParts={(data) => dispatch(setStockPartsToFillPartRequest(data))}
        filters={fillPartRequestFilters}
        setFilters={(values) => dispatch(setStockPartsToFillPartRequestFilters(values))}
        fetchParts={(values, isShow) => fetchPartsToFillPartReq(values, isShow)}
      />
      <SelectProductSourceModal
        isVisible={prodSourceModal}
        onCancel={() => {
          cancelProductSourceModal();
          dispatch(setStockPartRequestDetails(null));
        }}
        closePartsModal={() => setFillInModal(false)}
        sourceName={currentLine?.productName || '-'}
        sourceId={filledPartId}
        editedLine={affectedLineId}
        setProductSourceTotal={(v) => dispatch(setProductSourceTotal(v))}
        setProductSourceDictionary={(v) => dispatch(setProductSourceDictionary(v))}
        lines={sourceModalLines}
        linesParts={fillPartRequestParts.items}
        productSourceDictionary={productSourceDictionary}
        createThunk={(data, closeModal) => createPriceSourceHandle(
          data,
          () => {
            closeModal();
            dispatch(setStockPartRequestDetails(null));
          },
        )}
        productSourceTotal={productSourceTotal}
        getProductSourceDictionary={(partId, openModal) => getRemapProductSourceDictionaryHandle(partId, openModal)}
        getProductSourceTotal={(partId, stockQty, manualQty, manualUnitPrice, pricelists) => {
          const params = {
            stockQuantity: stockQty,
            pricelists,
            manualInputQuantity: manualQty,
            manualInputUnitPrice: manualUnitPrice,
          };
          getRemapProductSourceTotalHandle(partId, params);
        }}
        hideManualInput
      />
      <SelectProductSourceDistributeQty
        onCancel={() => {
          setDistributeQty(false);
          setAffectedLineId(undefined);
          dispatch(setStockPartRequestDetails(null));
        }}
        visible={isDistributeQty}
        sourceName={currentLine?.productName || '-'}
        sourceId={affectedLineId}
        sourcePartRequestId={currentLine?.partRequestId}
        isLinesView
      />
      <ConfirmReturnedPartsModal
        isVisible={isConfirmReturnedParts}
        onCancel={() => {
          setConfirmReturnedParts(false);
          setAffectedLineId(undefined);
        }}
        partRequestId={currentLine?.partRequestId}
        sourceConfirmedData={affectedLineConfirmedData}
        sourceId={affectedLineId}
        isLinesView
      />
      <ConfirmationModal
        customTitle="Mark as Not for stock"
        customText="This part line will be marked as not for stock and will be hidden in submitted part request in Stock module."
        customCreateLabel="Yes, proceed"
        isVisible={isMarkNotFromStock}
        onCancel={() => {
          setIsMarkNotFromStock(false);
          setAffectedLineId(undefined);
        }}
        confirmHandler={markAsNotFromStockHandler}
      />
      <LineDetailsModal
        visible={isViewDetails}
        onCancel={() => {
          setAffectedLineId(undefined);
          setViewDetails(false);
        }}
        currentLine={currentLine}
      />
      <StockPartRequestLinesViewTable
        setDistributeQtyHandle={setDistributeQtyHandle}
        setEditReadyCollected={(id) => {
          setAffectedLineId(id);
          setEditReadyCollected(true);
        }}
        setIsMarkNotFromStock={(id) => {
          setAffectedLineId(id);
          setIsMarkNotFromStock(true);
        }}
        setConfirmReturnedParts={(id) => {
          setAffectedLineId(id);
          setConfirmReturnedParts(true);
        }}
        setRemapToExistingPart={(id: number) => {
          setAffectedLineId(id);
          setFillInModal(true);
        }}
        setViewDetails={(id: number) => {
          setAffectedLineId(id);
          setViewDetails(true);
        }}
      />
    </>
  );
};

export default StockPartRequestLinesView;
