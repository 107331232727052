import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import Tr from '../../../core/components/table-components/Tr';
import { VehicleProfilesFilters } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import {
  vehicleSearchProfilesColumnsSelector,
} from '../../../store/selectors/vehiclesSelectors';
import { useAppSelector } from '../../../store/hooks';
import { vehicleProfilesGroupsUiSelector } from '../../../store/selectors/sharedSelectors';
import { SearchTableFilterType } from '../../../core/types/coreTypes';

type ExistedVehicleCatalogColumnsProps = {
  totalCount: number,
  groups: number[],
  filters: VehicleProfilesFilters,
  filtersHandle: (filters: VehicleProfilesFilters) => void,
}

const ExistedVehicleCatalogColumns: React.FC<ExistedVehicleCatalogColumnsProps> = ({
  totalCount, filters, filtersHandle, groups,
}) => {
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    modelDescription: undefined,
  });
  const columns = useAppSelector(vehicleSearchProfilesColumnsSelector);
  const vehicleGroups = useAppSelector(vehicleProfilesGroupsUiSelector);
  const sortHandle = (field: number) => {
    filtersHandle({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order?.isAsc }
        : { field, isAsc: true },
    });
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...filters,
        [field]: undefined,
        page: 1,
      };
      filtersHandle(values);
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...filters, [field]: searchVl[field]?.trim(), page: 1 };
    filtersHandle(values);
  };

  const finalColumns = columns.map((col) => {
    if (col.dataIndex === 'group') {
      const filteredGroups = vehicleGroups.filter((el) => groups.includes(el.value));
      return { ...col, filters: filteredGroups };
    } else return col;
  });

  const filtersList = [
    {
      dataIndex: 'group',
      value: filters.group || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => filtersHandle({
        ...filters,
        group: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      }),
    },
  ];
  return (
    <Tr>
      {finalColumns.map((col) => <ThWithControls
        key={col.dataIndex}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={totalCount}
        disabled={totalCount === 0}
        searchVl={searchVl}
        tableFilters={filters}
        filters={filtersList}
        insideModal
        className={`existedVehicleCatalogColumnsTh--${col.dataIndex}`}
      />)}
      <ThNew className="existedVehicleCatalogColumnsTh--action"><span className="sr-only">Actions</span></ThNew>
    </Tr>
  );
};

export default ExistedVehicleCatalogColumns;
