import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import Select from '../../../core/components/select/Select';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { CustomersStatusesEnum } from '../../../core/enums/dictionariesEnums';
import VehicleManualFields from '../../../common/components/vehicle-modal/VehicleManualFields';
import Textarea from '../../../core/components/textarea/Textarea';
import { usersFilterLookupSelector } from '../../../store/selectors/coreSelectors';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import TruncateDropdownLabel from '../../../common/components/truncate-dropdown-label/TruncateDropdownLabel';
import AddressModal from '../../../common/components/address/AddressModal';
import { AddressFields, initAddressFields } from '../../../common/types/commonTypes';
import AddressField from '../../../common/components/address/AddressField';
import { useVehicleDictionaries } from '../../../common/hooks/useVehicleDictionaries';
import { vehiclesCustomersSortedListSelector } from '../../../store/selectors/vehiclesSelectors';
import CreateVehicleCatalogFlow from './CreateVehicleCatalogFlow';
import { VehicleProfileSearchType } from '../../vehicle-profiles/types/vehicleProfilesTypes';
import { CreateCatalogMode, CreateVehicleRequestData } from '../types/createVehicleTypes';
import {
  AdditionalInfoFieldValidationSchema,
  createVehicleDefaultFields,
  CreateVehicleFields,
} from '../types/createVehicleFormSchema';
import { createVehicleThunk } from '../../../store/thunks/vehicles/createVehicleThunks';
import VehicleInfoSource from '../../../common/components/vehicle-modal/VehicleInfoSource';
import CommonVehicleFields from '../../../common/components/vehicle-modal/CommonVehicleFields';
import { useUsersDictionary } from '../../../common/hooks/useUsersDictionary';
import { useCreateVehicleFromOrder } from '../hooks/useCreateVehicleFromOrder';

type CreateVehicleFormProps = {
  setCustomerKeyword: (v?: string) => void,
  searchError?: string,
  setSearchError: (v?: string) => void,
}

const CreateVehicleForm: React.FC<CreateVehicleFormProps> = ({
  setCustomerKeyword, searchError, setSearchError,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    vehicleInfoSources,
    vehicleCatalogSource,
    vehicleFlows,
    onSiteVehicleFlow,
  } = useVehicleDictionaries();

  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [chosenVehicleProfile, setChosenVehicleProfile] = useState<VehicleProfileSearchType | null>(null);
  const [infoSource, setInfoSource] = useState<number | undefined>(undefined);
  const [catalogMode, setCatalogMode] = useState<CreateCatalogMode>(undefined);

  const { userSearch, setUserSearch } = useUsersDictionary();

  useEffect(() => {
    if (!infoSource && vehicleCatalogSource) {
      setInfoSource(vehicleCatalogSource.value);
    }
  }, [infoSource, vehicleCatalogSource]);

  const usersLookup = useAppSelector(usersFilterLookupSelector);
  const usersUi = useMemo(() => usersLookup.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [usersLookup]);

  const customersList = useAppSelector(vehiclesCustomersSortedListSelector);
  const customersOptions = customersList.length > 0
    ? customersList.map((el) => ({
      label: <>
        <FontAwesomeIcon icon={el.customerStatus === CustomersStatusesEnum.Complete ? faClipboardCheck : faQuestionCircle} />
        {' '}
        {`${el.customerAccountNumber} ${el.name}`}
      </>,
      value: el.id,
    }))
    : [];
  const {
    control, handleSubmit, reset, setError, setValue, watch, clearErrors,
  } = useForm<CreateVehicleFields>({
    defaultValues: createVehicleDefaultFields,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const customerId = watch('customerId');
  const vehicleFlow = watch('vehicleFlow');
  const isVehicleFlowOnSite = vehicleFlow === onSiteVehicleFlow;

  const setDefaultValues = (value?: number, clearWholeForm?: boolean) => {
    if (clearWholeForm) {
      reset();
      setAddressFields(initAddressFields);
    } else {
      setValue('manufacturer', '');
      setValue('type', undefined);
      setValue('group', undefined);
      setValue('modelDescription', '');
    }
    setInfoSource(value);
    clearErrors();
    setChosenVehicleProfile(null);
    setCatalogMode(undefined);
  };

  const { orderId } = useCreateVehicleFromOrder({
    setValue,
    setChosenVehicleProfile,
    setInfoSource,
    setCatalogMode,
  });

  const onSubmit = (values: CreateVehicleFields) => {
    const location: AddressFields = { ...addressFields };
    Object.keys(location).forEach((k) => {
      if (location[k as keyof AddressFields]) {
        location[k as keyof AddressFields] = location[k as keyof AddressFields]?.trim() || null;
      }
    });
    const weightFields = {
      maxPayload: values.maxPayload ? +values.maxPayload : 0,
      grossVehicleWeightKg: values.grossVehicleWeightKg ? +values.grossVehicleWeightKg : 0,
    };
    let finalValues: CreateVehicleRequestData;
    if (infoSource === vehicleCatalogSource?.value && chosenVehicleProfile) {
      finalValues = {
        ...values,
        vehicleFlow: values.vehicleFlow as number,
        customerId: values.customerId as number,
        assigneeId: values.assigneeId as number,
        chassisNumber: values.chassisNumber || '',
        manufacturer: null,
        type: null,
        group: null,
        modelDescription: null,
        regNumber: values.regNumber && values.regNumber.trim() ? values.regNumber.trim() : null,
        additionalInfo: values.additionalInfo ? values.additionalInfo.trim() : null,
        ...weightFields,
        ...location,
        companyName: location.companyName || null,
        telephone: location.telephone || null,
        vehicleProfileId: chosenVehicleProfile?.id,
      };
    } else {
      finalValues = {
        ...values,
        vehicleFlow: values.vehicleFlow as number,
        customerId: values.customerId as number,
        assigneeId: values.assigneeId as number,
        type: values.type as number | null,
        group: values.group as number | null,
        chassisNumber: values.chassisNumber || '',
        additionalInfo: values.additionalInfo ? values.additionalInfo.trim() : null,
        regNumber: values.regNumber && values.regNumber.trim() ? values.regNumber.trim() : null,
        ...weightFields,
        ...location,
        companyName: location.companyName || null,
        telephone: location.telephone || null,
        vehicleProfileId: null,
      };
    }

    dispatch(createVehicleThunk({
      data: finalValues,
      navigate,
      orderId,
    }));
  };

  const fields = watch();
  const requiredManualFields = [fields.manufacturer, fields.type, fields.group, fields.modelDescription];
  const disabledCreateManual = Object.values(requiredManualFields).some((v) => !v);
  const disabledSubmitBtn = (infoSource === vehicleCatalogSource?.value) ? !chosenVehicleProfile : disabledCreateManual;
  return (
    <>
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        isOptionalFields
        title="Location"
        isExtendedFields
        addressFields={addressFields}
      />
      <form className="create-form details-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="customerId"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              onSearch={(val) => {
                if (val.trim()) {
                  if (val.length <= 250) {
                    setCustomerKeyword(val);
                  } else {
                    setSearchError(maxLengthMessageHandle(250));
                  }
                } else {
                  setCustomerKeyword(undefined);
                }
              }}
              onSelect={() => {
                setCustomerKeyword(undefined);
                searchError && setSearchError('');
              }}
              onBlur={() => {
                setCustomerKeyword(undefined);
                searchError && setSearchError('');
              }}
              label="Customer"
              options={customersOptions}
              className="details-form__field--lg"
              showArrow
              showSearch
              error={errors.customerId?.message || searchError}
            />
          )}
        />
        <VehicleInfoSource
          sources={vehicleInfoSources}
          onClick={(value) => setDefaultValues(value)}
          infoSource={infoSource}
          disabled={!customerId}
          className="details-form__field--lg"
        />
        {infoSource === vehicleCatalogSource?.value
          ? <CreateVehicleCatalogFlow
            disabled={!customerId}
            setChosenVehicleProfile={(profile) => {
              setChosenVehicleProfile(profile);
              setValue('grossVehicleWeightKg', profile?.grossVehicleWeightKg ? profile.grossVehicleWeightKg.toString() : '0');
              setValue('maxPayload', profile?.maxPayload ? profile.maxPayload.toString() : '0');
            }}
            chosenVehicleProfile={chosenVehicleProfile}
            catalogMode={catalogMode}
            setCatalogMode={setCatalogMode}
          />
          : <VehicleManualFields
            control={control}
            errors={errors}
            setError={setError}
            setValue={setValue}
            disabled={!customerId}
          />}
        <CommonVehicleFields
          control={control}
          errors={errors}
          disabled={!customerId}
          setValue={setValue}
          isChassisRequired
          clearErrors={clearErrors}
        />
        <Controller
          control={control}
          name="vehicleFlow"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Vehicle flow"
              options={vehicleFlows}
              className="details-form__field--lg"
              error={errors.vehicleFlow?.message}
              disabled={!customerId}
            />
          )}
        />
        <AddressField
          addressFields={addressFields}
          setAddressModal={(v) => {
            setAddressModal(v);
          }}
          title="Location"
          isIconVisible={Object.values(addressFields).some((el) => el)}
          disabled={!customerId}
        />
        <Controller
          name="additionalInfo"
          control={control}
          rules={AdditionalInfoFieldValidationSchema}
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={field.onChange}
              error={errors.additionalInfo?.message}
              label="Additional info"
              disabled={!customerId}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          name="assigneeId"
          control={control}
          rules={isVehicleFlowOnSite ? RequiredFieldSchema : undefined}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              options={usersUi}
              showSearch
              showArrow
              onSearch={(val) => {
                if (val.trim()) {
                  if (val.length <= 250) {
                    setUserSearch(val);
                  } else {
                    dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                  }
                } else {
                  setUserSearch(val);
                }
              }}
              onSelect={() => {
                userSearch && setUserSearch(undefined);
              }}
              onBlur={() => {
                userSearch && setUserSearch(undefined);
              }}
              error={errors.assigneeId?.message}
              label="Assigned to"
              className="details-form__field--lg"
              disabled={!customerId}
            />
          )}
        />
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          cancelClick={() => vehicleCatalogSource && setDefaultValues(vehicleCatalogSource?.value, true)}
          className="details-form__actions"
          disabledCancel={!customerId}
          disabledCreate={!customerId || disabledSubmitBtn}
        />
      </form>
    </>
  );
};

export default CreateVehicleForm;
