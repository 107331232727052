import { useRef, useState } from 'react';
import { UseFormClearErrors, UseFormReset } from 'react-hook-form';
import { CreateRectificationFlowEnum } from '../enums/createRectificationEnums';
import { serOrderDetails } from '../../../store/slices/sharedSlice';
import { setCustomerDetails } from '../../../store/slices/customersSlice';
import { initRequisite, RequisiteFields } from '../../../common/utils/data';
import { AddressFields, initAddressFields, RequisiteDto } from '../../../common/types/commonTypes';
import { useAppDispatch } from '../../../store/hooks';
import { getCreateRectificationVehiclesListThunk } from '../../../store/thunks/rectification/createRectificationThunks';
import { RectificationFormFields } from '../types/createRectificationFormSchema';

export const useCreateRectification = (
  reset: UseFormReset<RectificationFormFields>,
  clearErrors: UseFormClearErrors<RectificationFormFields>,
) => {
  const dispatch = useAppDispatch();
  const [flowValue, setFlowValue] = useState(CreateRectificationFlowEnum.USUAL);
  const [isAddressModal, setAddressModal] = useState(false);
  const [requisiteModal, setRequisiteModal] = useState(false);
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [requisiteFields, setRequisiteFields] = useState<RequisiteFields>({
    billing: initRequisite,
    shipping: initRequisite,
  });
  const [isSame, setIsSame] = useState(true);
  const [isManual, setIsManual] = useState({
    billing: false,
    shipping: false,
  });
  const [requisiteCustomerId, setRequisiteCustomerId] = useState<{ billing?: number, shipping?: number }>({
    billing: undefined,
    shipping: undefined,
  });
  const sameRef = useRef(false);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [searchVehicleV, setSearchVehicleV] = useState<string | undefined>(undefined);

  const requisiteSubmitHandler = (values: RequisiteDto, isManualInput: boolean, selectedEntityId?: number) => {
    const field = isShippingMode ? 'shipping' : 'billing';
    const data = { ...requisiteFields, [field]: { ...values } };
    setIsManual({ ...isManual, [field]: isManualInput });
    setRequisiteCustomerId({ ...requisiteCustomerId, [field]: selectedEntityId });
    setRequisiteFields(data);
    setRequisiteModal(false);
    setIsShippingMode(false);
    sameRef.current = false;
  };

  const getFilteredVehicles = (keyword?: string) => {
    dispatch(getCreateRectificationVehiclesListThunk({
      filters: {
        keyword,
        onlyAvailableForRectificationCreation: true,
      },
    }));
  };

  const clearFields = (flow: CreateRectificationFlowEnum) => {
    reset();
    clearErrors();
    dispatch(serOrderDetails(null));
    dispatch(setCustomerDetails(null));
    setRequisiteFields({ billing: initRequisite, shipping: initRequisite });
    setIsManual({ billing: false, shipping: false });
    setRequisiteCustomerId({ billing: undefined, shipping: undefined });
    setIsSame(true);
    sameRef.current = false;
    setAddressFields(initAddressFields);
    setSearchValue(undefined);
    setSearchVehicleV(undefined);
    setFlowValue(flow);
  };
  return {
    flowValue,
    searchValue,
    setSearchValue,
    searchVehicleV,
    setSearchVehicleV,
    isAddressModal,
    setAddressModal,
    setRequisiteModal,
    requisiteModal,
    setIsShippingMode,
    isShippingMode,
    clearFields,
    requisiteSubmitHandler,
    getFilteredVehicles,
    addressFields,
    setAddressFields,
    requisiteFields,
    setRequisiteFields,
    isSame,
    setIsSame,
    isManual,
    setIsManual,
    requisiteCustomerId,
    setRequisiteCustomerId,
    sameRef,
  };
};
