export const mockedOrderPartRequestSources = [
  {
    label: 'Core parts', value: 1,
  },
  {
    label: 'Non-core parts', value: 2,
  },
  {
    label: 'Parts kits', value: 3,
  },
  {
    label: 'Custom line', value: 4,
  },
];

export const mockedOrderPartRequestCustomSource = [
  {
    label: 'Custom line', value: 4,
  },
];
