import { useMemo, useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { DropResult } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { jobStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { modalConfirmLabelHandle, modalTextHandle, modalTitleHandle } from '../utils/statusTransitionModalUtils';
import { JobsResponseType } from '../../jobs/types/jobsTypes';
import { CoreAttachListElType, VoidFunctionType } from '../../../core/types/coreTypes';
import { setJobDetailsFilters } from '../../../store/slices/jobsSlice';
import { changeJobStatusThunk } from '../../../store/thunks/jobs/jobThunks';
import { jobDetailsSelector } from '../../../store/selectors/jobsSelector';
import { JobDetailsFiltersType } from '../../job-view-page/types/jobViewTypes';
import { editJobDashStatusThunk } from '../../../store/thunks/jobs/jobsDashboardThunks';
import { guidGenerator } from '../../../core/utils/guidGenerator';

type UseJobStatusTransitionRemasteredProps = {
  isDashboardFlow?: boolean,
  cards?: JobsResponseType | null,
  setCards?: (value: JobsResponseType | null) => void,
  jobCollections?: JobsResponseType | null,
  jobDetailsFilters?: JobDetailsFiltersType,
}

export const useJobStatusTransition = (props: UseJobStatusTransitionRemasteredProps) => {
  const dispatch = useAppDispatch();
  const {
    newStatus, inProduction, postponed, cancelled,
    pending, failed, jobQc, completed, readyForQc,
  } = useAppSelector(jobStatusesValuesSelector);

  const [isProceedToQualityCheck, setIsProceedToQualityCheck] = useState(false);
  const [isRunCheck, setIsRunCheck] = useState(false);

  // ---> VIEW PAGE
  const jobDetails = useAppSelector(jobDetailsSelector);
  const jobAssignees = jobDetails?.assignees?.map((ass) => ass.id);
  // <---

  // ---> DASHBOARD
  const [jobTransit, setJobTransit] = useState<{id: number, initStatus: number} | undefined>(undefined);
  const setDefaultValues = () => {
    setJobTransit(undefined);
  };
  // <---

  const [isPostponed, setIsPostponed] = useState<boolean>(false);
  const [isReadyForQC, setIsReadyForQC] = useState<boolean>(false);
  const [isPending, setIsPending] = useState<boolean>(false);
  const [isCancelled, setIsCancelled] = useState<boolean>(false);
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [isFailed, setIsFailed] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const isConfirmModalVisible = isPostponed || isCancelled || isReadyForQC || isPending || isCancelled || isCompleted || isFailed;

  const modalTitle = useMemo(() => modalTitleHandle(isFailed, isCompleted), [isFailed, isCompleted]);
  const modalText = useMemo(() => modalTextHandle(isPostponed, isReadyForQC, isPending, isCancelled, isCompleted), [
    isPostponed, isReadyForQC, isPending, isCancelled, isCompleted,
  ]);
  const modalConfirmLabel = useMemo(() => modalConfirmLabelHandle(isPostponed, isReadyForQC, isPending, isCancelled, isCompleted), [
    isPostponed, isReadyForQC, isPending, isCancelled, isCompleted,
  ]);

  const cancelStatusConfirmationModal = () => {
    if (isPostponed) setIsPostponed(false);
    if (isCancelled) setIsCancelled(false);
    if (isCompleted) setIsCompleted(false);
    if (isPending) setIsPending(false);
    if (isReadyForQC) setIsReadyForQC(false);
    if (isFailed) {
      setIsFailed(false);
      setComment('');
      error && setError('');
      setFileList([]);
    }
    if (props.isDashboardFlow) {
      setDefaultValues();
      props.setCards && props.jobCollections && props.setCards(props.jobCollections);
    }
  };

  const changeJobStatusOnViewPageThunkHandle = (
    status: number,
    list?: CoreAttachListElType[],
    comment?: string,
  ) => {
    if (jobDetails) {
      const id = jobDetails?.job.id;
      dispatch(setJobDetailsFilters({ ...props.jobDetailsFilters, statusTransition: status }));
      id && dispatch(changeJobStatusThunk({
        id,
        statusTransition: status,
        list,
        comment,
        setDefaultStatus: () => {
          dispatch(setJobDetailsFilters({
            assignees: jobAssignees,
            statusTransition: jobDetails.job.jobStatus,
          }));
        },
        onClose: () => cancelStatusConfirmationModal(),
        triggerProceedToQualityCheckModal: status === jobQc
          ? () => setIsProceedToQualityCheck(true)
          : undefined,
      }));
    }
  };

  const changeJobStatusOnDashboardThunkHandle = (
    setColumns: (value: JobsResponseType | null) => void,
    jobId?: number,
    sourceId?: number,
    destination?: number,
    list?: CoreAttachListElType[],
    comment?: string,
  ) => {
    const isFieldsFilled = destination && jobId && sourceId;
    isFieldsFilled && dispatch(editJobDashStatusThunk({
      id: jobId,
      statusTransition: destination,
      sourceId,
      setColumns: () => props.jobCollections && setColumns(props.jobCollections),
      list,
      comment,
      onClose: () => cancelStatusConfirmationModal(),
      triggerProceedToQualityCheckModal: destination === jobQc
        ? () => setIsProceedToQualityCheck(true)
        : undefined,
    }));
  };

  const changeStatusThunkHandle = (statusValue: number, list?: CoreAttachListElType[], comment?: string) => {
    const setColumns = () => props.cards && props.setCards && props.setCards(props.cards);
    if (props.isDashboardFlow) {
      changeJobStatusOnDashboardThunkHandle(setColumns, jobTransit?.id, jobTransit?.initStatus, statusValue, list, comment);
    } else {
      changeJobStatusOnViewPageThunkHandle(statusValue, list, comment);
    }
  };

  const checkStatusBeforeEditingHandler = (customHandle: VoidFunctionType, prevStatus?: number) => {
    const prevToPendingList = [readyForQc, inProduction, failed, postponed, cancelled];
    const isPrevToPending = prevToPendingList.includes(prevStatus);
    const isPrevToCancelled = (prevStatus === pending) || (prevStatus === failed);

    if (newStatus === postponed && prevStatus === inProduction) {
      setIsPostponed(true);
    } else if (newStatus === cancelled && isPrevToCancelled) {
      setIsCancelled(true);
    } else if (newStatus === readyForQc && prevStatus === inProduction) {
      setIsReadyForQC(true);
    } else if (newStatus === pending && isPrevToPending) {
      setIsPending(true);
    } else if (newStatus === completed && prevStatus === jobQc) {
      setIsCompleted(true);
    } else if (newStatus === failed && prevStatus === jobQc) {
      setIsFailed(true);
    } else {
      customHandle();
    }
  };

  const editStatusActionViewPageHandler = (newStatus: number) => {
    const prevStatus = jobDetails?.job.jobStatus;
    checkStatusBeforeEditingHandler(() => changeJobStatusOnViewPageThunkHandle(newStatus), prevStatus);
  };

  const editStatusActionDashboardHandler = (
    result: DropResult,
    setColumns: (value: JobsResponseType | null) => void,
  ) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const prevStatus = +source.droppableId;
    const newStatus = +destination.droppableId;

    checkStatusBeforeEditingHandler(
      () => newStatus && result && changeJobStatusOnDashboardThunkHandle(
        setColumns,
        +result.draggableId,
        +result.source.droppableId,
        newStatus,
      ),
      prevStatus,
    );
  };

  const confirmEditingHandler = () => {
    if (isPostponed && postponed) changeStatusThunkHandle(postponed);
    if (isReadyForQC && readyForQc) changeStatusThunkHandle(readyForQc);
    if (isPending && pending) changeStatusThunkHandle(pending);
    if (isCancelled && cancelled) changeStatusThunkHandle(cancelled);
    if (isCompleted && completed) changeStatusThunkHandle(completed);
    if (isFailed && failed) {
      const list = fileList.map((el) => ({
        fileName: el.name,
        uploadGuid: guidGenerator(),
        file: el,
      }));
      changeStatusThunkHandle(failed, list, comment);
    }
  };

  return {
    isFailed,
    setJobTransit,
    jobNewStatus: newStatus,
    isConfirmModalVisible,
    modalTitle,
    modalText,
    modalConfirmLabel,
    confirmEditingHandler,
    editStatusActionViewPageHandler,
    editStatusActionDashboardHandler,
    cancelStatusConfirmationModal,
    comment,
    setComment,
    error,
    setError,
    fileList,
    setFileList,
    isProceedToQualityCheck,
    setIsProceedToQualityCheck,
    isRunCheck,
    setIsRunCheck,
  };
};
