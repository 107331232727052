import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse,
  DownloadUriResponse,
  emptyPaging,
  initPaging,
  ResponseResult,
  ResponseSingleResult,
  StringFunctionType,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  PricelistDto,
  PricelistPartDto,
  PricesFiltersType,
} from '../../../pricelists/dashboard/types/pricelistTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { pricelistAPI } from '../../../api/pricelistApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  PartPricesFilters,
  PricelistAffectedDto,
  PricelistAffectedFilters,
  PricelistEventDtoBase,
  PricelistPartPriceDto,
  SavePricelistPartPriceDto,
  StockCategoriesFiltersType,
} from '../../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { setPricelistTimeline, setPricelistTimelineFilters, setPricesFilters } from '../../slices/pricelistSlice';
import {
  PleaseRefreshThePageError,
  SimultaneouslyStatusErrorEnum,
} from '../../../core/enums/errorsEnum';
import { RootState } from '../../store';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { PartDtoType, StockFiltersType } from '../../../stock/search/types/stockSearchTypes';
import { stockApi } from '../../../api/stockApi';
import { EnterPriceQtyModes } from '../../../stock/purchase-orders/view-page/enums/PurchaseOrderViewEnum';
import { PartCategoryDto, PartSubcategoryDto } from '../../../stock/part-categories/types/partCategoriesTypes';
import { UserEventPricelist } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';

const DUPLICATED_POSTFIX = '(duplicate)';

export const getPricelistTimelineThunk = createAsyncThunk<
ResponseResult<PricelistEventDtoBase[]>, {
  pricelistId: number, filters: ExtendedTimelineFilters
}>(
  'get/PricelistTimeline',
  async ({
    pricelistId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.fetchPricelistTimeline(pricelistId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistById = createAsyncThunk<
ResponseSingleResult<{ pricelist: PricelistDto }> | null,
{
  id: number,
}
>(
  'get/PricelistById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.fetchPricelistById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPricelistById = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  data: Partial<PricelistDto>,
  closeModal?: VoidFunctionType,
}
>(
  'patch/PricelistById',
  async ({
    id, data, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.patchPricelist(id, data);
      const { pricelist: { pricelistTimelineFilters } } = getState() as RootState;
      dispatch(getPricelistById({ id }));
      if (pricelistTimelineFilters.page === 1) {
        dispatch(getPricelistTimelineThunk({
          pricelistId: id,
          filters: pricelistTimelineFilters,
        }));
      } else {
        dispatch(setPricelistTimelineFilters({ ...pricelistTimelineFilters, page: 1 }));
      }
      closeModal && closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const duplicatePricelistById = createAsyncThunk<
ResponseSingleResult<{ createdId: number }> | null,
{
  id: number,
  navigate: NavigateFunction,
  closeModal: VoidFunctionType,
}
>(
  'post/DuplicatePricelist',
  async ({
    id, navigate, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { pricelist } = getState() as RootState;
      const { pricelistDetails } = pricelist;
      const response = await pricelistAPI.duplicatePricelist(id);
      closeModal();
      const message = `Pricelist "${pricelistDetails?.name || ''} ${pricelistDetails?.name?.includes(DUPLICATED_POSTFIX)
        ? ''
        : DUPLICATED_POSTFIX}" was successfully created`;
      dispatch(setSuccessMessage({ message }));
      navigate(`/${routesPath.PRICELISTS}/${response.data.data.createdId}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const activatePricelistThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
}
>(
  'post/ActivatePricelist',
  async ({ id }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.activatePricelist(id);
      const { pricelist: { pricelistTimelineFilters } } = getState() as RootState;
      dispatch(getPricelistById({ id }));
      if (pricelistTimelineFilters.page === 1) {
        dispatch(getPricelistTimelineThunk({
          pricelistId: id,
          filters: pricelistTimelineFilters,
        }));
      } else {
        dispatch(setPricelistTimelineFilters({ ...pricelistTimelineFilters, page: 1 }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const inactivatePricelistThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  closeModal: VoidFunctionType,
}
>(
  'post/InactivatePricelist',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.inactivatePricelist(id);
      const { pricelist: { pricelistTimelineFilters } } = getState() as RootState;
      dispatch(getPricelistById({ id }));
      if (pricelistTimelineFilters.page === 1) {
        dispatch(getPricelistTimelineThunk({
          pricelistId: id,
          filters: pricelistTimelineFilters,
        }));
      } else {
        dispatch(setPricelistTimelineFilters({ ...pricelistTimelineFilters, page: 1 }));
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistAffectedThunk = createAsyncThunk<ResponseResult<PricelistAffectedDto[]>,
{
  pricelistId: number,
  filters: PricelistAffectedFilters,
  confirmAction?: VoidFunctionType,
  complexAction?: VoidFunctionType,
}
>(
  'get/PricelistAffected',
  async ({
    pricelistId, filters, confirmAction, complexAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.getPricelistAffected(pricelistId, filters);
      const { totalCount } = response.data.data;
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      dispatch(setFetching(false));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistAffectedPartsThunk = createAsyncThunk<ResponseResult<PartDtoType[]>,
{
  filters: StockFiltersType,
  confirmAction?: VoidFunctionType,
  complexAction?: VoidFunctionType,
}
>(
  'get/PricelistAffectedParts',
  async ({
    filters, confirmAction, complexAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      const { totalCount } = response.data.data;
      dispatch(setLoading(false));
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePricelistThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  closeModal: VoidFunctionType,
  navigate: NavigateFunction,
}
>(
  'delete/Pricelist',
  async ({
    id, closeModal, navigate,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.deletePricelist(id);
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      navigate(`/${routesPath.PRICELISTS}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricesPartsThunk = createAsyncThunk<
ResponseResult<PricelistPartDto[], { allItemIds: number[] }>,
{
  id: number,
  filters: PricesFiltersType,
}
>(
  'get/PricesParts',
  async ({
    id, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.getPricesParts(id, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsThunk = createAsyncThunk<
ResponseResult<PartDtoType[], { allItemIds: number[] }>,
{
  pricelistId: number,
  filters: StockFiltersType,
}
>(
  'get/StockParts',
  async ({
    pricelistId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts({
        ...filters,
        canBeAddedToPricelistId: pricelistId,
      });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartPricelistCategories = createAsyncThunk<ResponseResult<PartCategoryDto[]>, {
  filters?: StockCategoriesFiltersType,
}>(
  'get/StockCategories',
  async ({ filters }, { rejectWithValue }) => {
    try {
      const res = await pricelistAPI.fetchPartPricelistCategories(filters);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartPricelistSubcategories = createAsyncThunk<ResponseResult<PartSubcategoryDto[]>, {
  filters?: StockCategoriesFiltersType
}>(
  'get/StockSubcategories',
  async ({ filters }, { rejectWithValue }) => {
    try {
      const res = await pricelistAPI.fetchPartPricelistSubcategories(filters);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const partBulkDeleteThunk = createAsyncThunk<null, {
  pricelistId: number, partIds: number[], closeModal: VoidFunctionType,
}>(
  'delete/Parts',
  async ({
    pricelistId, partIds, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { pricelist: { pricesFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await pricelistAPI.getPricesParts(pricelistId, {
        ...pricesFilters, ...emptyPaging,
      });
      const filteredIds = partIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await pricelistAPI.partBulkDelete(pricelistId, filteredIds);
      }
      closeModal();
      dispatch(setLoading(false));
      dispatch(setPricesFilters(initPaging));
      dispatch(getPricesPartsThunk({ id: pricelistId, filters: initPaging }));
      dispatch(getPricelistById({ id: pricelistId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const partDeleteThunk = createAsyncThunk<ResponseSingleResult, {
  pricelistId: number, partId: number, closeModal: VoidFunctionType,
}>(
  'delete/Part',
  async ({
    pricelistId, partId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await pricelistAPI.partSingleDelete(pricelistId, partId);
      closeModal();
      dispatch(setLoading(false));
      dispatch(setPricesFilters(initPaging));
      dispatch(getPricesPartsThunk({ id: pricelistId, filters: initPaging }));
      dispatch(getPricelistById({ id: pricelistId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartPricesThunk = createAsyncThunk<ResponseResult<PricelistPartPriceDto[]>, {
  pricelistId?: number,
  partId: number,
  filters?: PartPricesFilters,
  isHasPriceHandle?: StringFunctionType,
}>(
  'get/PartPrices',
  async ({
    pricelistId, partId, filters, isHasPriceHandle,
  }, { rejectWithValue }) => {
    try {
      const res = await pricelistAPI.fetchPartPrices(partId, pricelistId, filters);
      if (res.data.data.items.length > 0) {
        isHasPriceHandle && isHasPriceHandle(EnterPriceQtyModes.PRICELIST);
      } else {
        isHasPriceHandle && isHasPriceHandle(EnterPriceQtyModes.MANUAL);
      }
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const partPricelistCreateThunk = createAsyncThunk<ResponseSingleResult<{
  createdPrices: Array<{ productId: number, partId: number }>,
}> | null, {
  pricelistId: number, partIds: number[], closeModal: VoidFunctionType,
}>(
  'post/PartPricelist',
  async ({
    pricelistId, partIds, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    const onFinish = () => {
      closeModal();
      dispatch(setFetching(false));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      dispatch(setPricesFilters(initPaging));
      dispatch(getPricesPartsThunk({ id: pricelistId, filters: initPaging }));
      dispatch(getPricelistById({ id: pricelistId }));
    };
    dispatch(setFetching(true));
    try {
      const { pricelist: { addPartFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchStockParts({
        ...addPartFilters,
        ...emptyPaging,
        canBeAddedToPricelistId: pricelistId,
      });
      const filteredIds = partIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        const res = await pricelistAPI.partCreate(pricelistId, partIds);
        onFinish();
        return res.data;
      }
      onFinish();
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const putPartPricesThunk = createAsyncThunk<ResponseSingleResult, {
  pricelistId: number, partId: number, prices: Array<SavePricelistPartPriceDto>,
  closeModal: VoidFunctionType,
}>(
  'put/PartPrices',
  async ({
    pricelistId, partId, prices, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { pricelist } = getState() as RootState;
      const { pricesFilters } = pricelist;
      const res = await pricelistAPI.putPartPrices(pricelistId, partId, prices);
      dispatch(setLoading(false));
      closeModal();
      dispatch(getPricesPartsThunk({ id: pricelistId, filters: pricesFilters }));
      dispatch(getPricelistById({ id: pricelistId }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postPricelistTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  pricelistId: number, content: string, setValue: VoidFunctionType,
}>(
  'post/PricelistTimelineNote',
  async ({
    pricelistId, content, setValue,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.postPricelistTimelineNote(pricelistId, content);
      const { pricelist: { pricelistTimelineFilters } } = getState() as RootState;
      dispatch(setLoading(false));
      if (pricelistTimelineFilters.page === 1) {
        dispatch(getPricelistTimelineThunk({
          pricelistId,
          filters: pricelistTimelineFilters,
        }));
      } else {
        dispatch(setPricelistTimelineFilters({ ...pricelistTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      setValue();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editPricelistTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, content: string, closeModal: VoidFunctionType,
}>(
  'put/PricelistTimelineNote',
  async ({
    id, content, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await pricelistAPI.editPricelistTimelineNote(id, content);
      const {
        pricelist: { pricelistTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventPricelist[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = pricelistTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setPricelistTimeline({ ...pricelistTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePricelistTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/PricelistTimelineNote',
  async ({
    id, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await pricelistAPI.deletePricelistTimelineNote(id);
      const {
        pricelist: { pricelistTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setPricelistTimelineFilters({ ...pricelistTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generatePricelistToExcel = createAsyncThunk<DownloadUriResponse,
{ id: number }
>(
  'get/PricelistExcelFile',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.exportPricelistToExcel(id);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
