import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { vehicleDetailsHeadSelector } from '../../../../store/selectors/vehiclesSelectors';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

const VehicleViewHead: React.FC = () => {
  const vehicleHead = useAppSelector(vehicleDetailsHeadSelector);

  return (
    <>
      {vehicleHead.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />
      </div>)}
    </>
  );
};

export default VehicleViewHead;
