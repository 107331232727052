import React from 'react';
import { PartsKitEventDtoBase } from '../../../../types/partsKitViewPageTypes';
import CreationEvent from '../../../../../../../common/components/activity-events/creation/CreationEvent';
import StatusChangeEvent from '../../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import AttachmentEvents from '../../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import { useAppSelector } from '../../../../../../../store/hooks';
import { PartsKitEventTypesEnum } from '../../../../enums/PartsKitsViewPageEnums';
import PartsKitNoteEvent from './PartsKitNoteEvent';
import PartsKitFieldUpdateEvent from './PartsKitFieldUpdateEvent';
import PartsKitComponentEvent from './PartsKitComponentsEvent';
import { partsKitEventTypesSelector } from '../../../../../../../store/selectors/coreEventTypesSelectors';
import { partsKitsStatusesSelector } from '../../../../../../../store/selectors/coreStatusesSelectors';
import DeletionEvent from '../../../../../../../common/components/activity-events/deletion/DeletionEvent';
import SuitableForVehiclesChangeEvent
  from '../../../../../../../common/components/activity-events/suitable-for-vehicles-change/SuitableForVehiclesChangeEvent';

type PartsKitActivityEventProps = {
  item: PartsKitEventDtoBase
}

const PartsKitActivityEvent: React.FC<PartsKitActivityEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(partsKitEventTypesSelector);
  const partsKitsStatuses = useAppSelector(partsKitsStatusesSelector);

  const attachmentEvents = [
    PartsKitEventTypesEnum.AddingAttachmentWithoutComment,
    PartsKitEventTypesEnum.AddingAttachmentWithComment,
    PartsKitEventTypesEnum.EditingAttachmentComment,
    PartsKitEventTypesEnum.DeletingAttachment,
  ];

  const eventRenderFn = (item: PartsKitEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case event === PartsKitEventTypesEnum.Note:
        return <PartsKitNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === PartsKitEventTypesEnum.PartsKitCreation:
        return <CreationEvent entity="Parts kit" type="name" value={item.name} />;
      case event === PartsKitEventTypesEnum.PartsKitDeletion:
        return <DeletionEvent entity="Parts kit" />;
      case event === PartsKitEventTypesEnum.PartsKitFieldUpdateOrAddingValue:
        return <PartsKitFieldUpdateEvent
          field={item.field}
          oldValue={(item.oldValue as string | number | undefined)}
          newValue={(item.newValue as string | number | undefined)}
        />;
      case event === PartsKitEventTypesEnum.PartsKitStatusChange: {
        const prevStatus = partsKitsStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = partsKitsStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case event === PartsKitEventTypesEnum.PartsKitSuitableForVehiclesUpdateOrAddingValue: {
        const {
          oldSuitableForVehicleGroups, oldSuitableForVehicles, newSuitableForVehicleGroups, newSuitableForVehicles,
        } = item;
        return <SuitableForVehiclesChangeEvent data={{
          oldSuitableForVehicleGroups, oldSuitableForVehicles, newSuitableForVehicleGroups, newSuitableForVehicles,
        }}
        />;
      }
      case event === PartsKitEventTypesEnum.PartsKitComponentCreationChanges: {
        return <PartsKitComponentEvent item={item} />;
      }
      case (attachmentEvents.includes(event as PartsKitEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={(item.oldValue as string | number | undefined)}
          newValue={(item.newValue as string | number | undefined)}
        />;
      default: return null;
    }
  };

  return <>{eventRenderFn(item)}</>;
};

export default PartsKitActivityEvent;
