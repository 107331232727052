import React, { useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { AddressFields, DraftSkillLine, initAddressFields } from '../../../common/types/commonTypes';
import { StringFunctionType } from '../../../core/types/coreTypes';
import AddressModal from '../../../common/components/address/AddressModal';
import EmployeeDetailsSection from './EmploymentDetailsSection';
import EmployeeInfoSection from './EmployeeInfoSection';
import EmployeeICEContactSection from './EmployeeICEContactSection';
import EmployeeUserAccountSection from './EmployeeUsrAccountSection';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { createEmployeeThunk } from '../../../store/thunks/employee/createEmployeeThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';
import Restricted from '../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import usePermission from '../../../permissions-handling/permissionHook';
import { guidGenerator } from '../../../core/utils/guidGenerator';
import { fileAttachmentTypeSelector, internalAccountSelector } from '../../../store/selectors/coreSelectors';
import EmployeeSkilListSection from './EmployeeSkilListSection';
import SkillsModal from '../../../common/components/select-skill-and-level-modal/SkillsModal';
import { employeeDefaults, EmployeeFields, PostEmployeeRestModel } from '../../employee-view-page/utils/EmployeeSchema';

type CreateEmployeeFormProps = {
  addressFields: AddressFields,
  setAddressFields: (value: AddressFields) => void,
  iceAddressFields: AddressFields,
  setIceAddressFields: (value: AddressFields) => void,
  addressError: string,
  setAddressError: StringFunctionType,
  iceAddressError: string,
  setIceAddressError: StringFunctionType,
}

const CreateEmployeeForm: React.FC<CreateEmployeeFormProps> = ({
  addressFields, setAddressFields, iceAddressFields, setIceAddressFields,
  addressError, setIceAddressError, setAddressError, iceAddressError,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userCreationAllowed = usePermission(PermissionEnum.UserCreatePage);
  const fileAttachmentType = useAppSelector(fileAttachmentTypeSelector);
  const [isAddressModal, setAddressModal] = useState(false);
  const [isIceAddressModal, setIceAddressModal] = useState(false);
  const [isCreateUser, setIsCreateUser] = useState<number | undefined>(undefined);
  const [userCreateChoiceErr, setUserCreateChoiceErr] = useState<string>('');
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [isSkillsModal, setSkillsModal] = useState(false);
  const [skills, setSkills] = useState<DraftSkillLine[]>([]);

  const {
    control, handleSubmit, reset, watch, setValue, setError, clearErrors, trigger,
  } = useForm<EmployeeFields>({
    defaultValues: employeeDefaults,
    mode: 'all',
  });

  const internalAccount = useAppSelector(internalAccountSelector);

  const treatSpacesAsEmptyHandle = (v: string, field: keyof EmployeeFields, required = true) => {
    if (!v.trim()) {
      required ? setError(field, { type: 'required', message: ErrorsEnum.REQUIRED }) : clearErrors(field);
      setValue(field, employeeDefaults[field]);
    }
  };
  const checkAreAddressesEmpty = async () => {
    const keysToCheck = ['addressLine1', 'cityTown', 'postalCode'];
    const fieldsCheckUp = [];
    const fieldsIceCheckUp = [];
    Object.entries(addressFields).forEach(([key, v]) => {
      if (keysToCheck.includes(key) && v?.trim()) {
        fieldsCheckUp.push(1);
      }
    });
    Object.entries(iceAddressFields).forEach(([key, v]) => {
      if (keysToCheck.includes(key) && v?.trim()) {
        fieldsIceCheckUp.push(1);
      }
    });
    if (fieldsCheckUp.length !== keysToCheck.length) {
      setAddressError(ErrorsEnum.REQUIRED);
    }
    if (fieldsIceCheckUp.length !== keysToCheck.length) {
      setIceAddressError(ErrorsEnum.REQUIRED);
    }
    if (userCreationAllowed && isCreateUser === undefined) {
      setUserCreateChoiceErr(ErrorsEnum.REQUIRED);
    }
    await trigger();
  };
  const cancelClick = () => {
    reset();
    setAddressFields(initAddressFields);
    setIceAddressFields(initAddressFields);
    setAddressError('');
    setIceAddressError('');
    setUserCreateChoiceErr('');
    setIsCreateUser(undefined);
    setSkills([]);
    setFileList([]);
  };
  const { errors } = useFormState({ control });
  const onSubmit = (values: EmployeeFields) => {
    const {
      firstName, lastName, title,
      birthday, nationalInsuranceNumber,
      email, telephone, mobile,
      iceContactFullName, relationship, iceContactTelephone,
      jobTitle, startDate,
      salaryValue, annualLeaveEntitlement,
    } = values;
    const finalValues = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      title: title ? title.trim() : null,
      birthday: moment(birthday).format(DEFAULT_DATE_FORMAT),
      gender: values.gender as number,
      nationalInsuranceNumber,
      email,
      telephone: telephone ? telephone.trim() : null,
      mobile: mobile.trim(),
      addressLine1: addressFields.addressLine1,
      addressLine2: addressFields.addressLine2,
      cityTown: addressFields.cityTown,
      postalCode: addressFields.postalCode,
      region: addressFields.region,
      iceContactFullName: iceContactFullName.trim(),
      relationship: relationship.trim(),
      iceContactTelephone: iceContactTelephone ? iceContactTelephone.trim() : null,
      iceContactAddressLine1: iceAddressFields.addressLine1,
      iceContactAddressLine2: iceAddressFields.addressLine2,
      iceContactCityTown: iceAddressFields.cityTown,
      iceContactPostalCode: iceAddressFields.postalCode,
      iceContactRegion: iceAddressFields.region,
      jobTitle: jobTitle.trim(),
      department: values.department as number,
      startDate: moment(startDate).format(DEFAULT_DATE_FORMAT),
      salaryType: values.salaryType as number,
      salaryValue: +salaryValue,
      annualLeaveEntitlement: +annualLeaveEntitlement,
      skills: skills.length === 0
        ? null
        : skills.map((el) => ({ skillId: el.id, level: el.level })),
    };

    const userFields = isCreateUser === 1
      ? {
        email: finalValues.email,
        firstName: finalValues.firstName,
        lastName: finalValues.lastName,
        accountType: internalAccount,
        userAccountRole: {
          role: +values.userRole.split('-')[0],
          roleLevel: +values.userRole.split('-')[1],
        },
        canSeeOnlyAssignedEntities: values.userAssignedEntitiesOption === 1,
        allowedJobTypes: values.userJobType,
        allowedBusinessAreas: values.userBusinessAreas,
      }
      : undefined;

    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    !iceAddressError && !addressError && !userCreateChoiceErr && dispatch(createEmployeeThunk({
      data: finalValues as PostEmployeeRestModel,
      list,
      fileAttachmentType,
      navigate,
      closeForm: () => cancelClick(),
      setError,
      isUserCreate: isCreateUser === 1,
      userFields,
    }));
  };
  return (
    <>
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        isOptionalFields={false}
        title="Job location"
        isExtendedFields={false}
        addressFields={addressFields}
      />
      <AddressModal
        isVisible={isIceAddressModal}
        onCancel={() => {
          setIceAddressModal(false);
        }}
        onSubmit={(values) => {
          setIceAddressFields(values);
          setIceAddressModal(false);
        }}
        isOptionalFields={false}
        title="Address"
        isExtendedFields={false}
        addressFields={iceAddressFields}
      />
      <SkillsModal
        visible={isSkillsModal}
        onCancel={() => setSkillsModal(false)}
        setSkills={setSkills}
        skills={skills}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="createEmployeeForm create-form">
        <EmployeeInfoSection
          control={control}
          errors={errors}
          treatSpacesAsEmptyHandle={treatSpacesAsEmptyHandle}
          addressFields={addressFields}
          setAddressModal={setAddressModal}
          fileList={fileList}
          setFileList={setFileList}
          addressError={addressError}
          setAddressError={setAddressError}
        />
        <EmployeeDetailsSection
          control={control}
          errors={errors}
          treatSpacesAsEmptyHandle={treatSpacesAsEmptyHandle}
        />
        <EmployeeSkilListSection
          setSkillsModal={setSkillsModal}
          skills={skills}
        />
        <EmployeeICEContactSection
          control={control}
          errors={errors}
          setIceAddressModal={setIceAddressModal}
          iceAddressFields={iceAddressFields}
          addressError={iceAddressError}
          setAddressError={setIceAddressError}
          treatSpacesAsEmptyHandle={treatSpacesAsEmptyHandle}
        />
        <Restricted to={PermissionEnum.UserCreatePage}>
          <EmployeeUserAccountSection
            control={control}
            errors={errors}
            watch={watch}
            isCreateUser={isCreateUser}
            setIsCreateUser={setIsCreateUser}
            setUserCreateChoiceErr={setUserCreateChoiceErr}
            userCreateChoiceErr={userCreateChoiceErr}
            setValue={setValue}
          />
        </Restricted>
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          disabledCreate={(!!iceAddressError || !!addressError)}
          createClick={() => checkAreAddressesEmpty()}
          cancelClick={() => cancelClick()}
          className="details-form__actions"
        />
      </form>
    </>
  );
};

export default CreateEmployeeForm;
