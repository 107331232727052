import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  EmployeeActivityEventType,
  GetEmployeeIceContactResponse,
  GetEmployeeResponse, PatchEmployeeDto,
} from '../../../employees/employee-view-page/types/employeeViewTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import {
  DuplicateValue,
  ErrorsEnum,
  PleaseRefreshThePageError,
  SimultaneouslyStatusErrorEnum,
} from '../../../core/enums/errorsEnum';
import { getEmployeeAttachmentByIdThunk } from './employeeAttachmentsThunks';
import { setEmployeeActivityFilters, setEmployeeImageData, setEmployeeTimeline } from '../../slices/employeesSlice';
import { settingsAPI } from '../../../api/settingsApi';
import { EmployeeSkillsListItemDto } from '../../../employees/skill-list/types/skillsTypes';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { RootState } from '../../store';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { EmployeeFields } from '../../../employees/employee-view-page/utils/EmployeeSchema';
import { UserEventEmployee } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';

export const getEmployeeTimelineThunk = createAsyncThunk<
ResponseResult<EmployeeActivityEventType[]>,
{ employeeId: number, filters: ExtendedTimelineFilters }
>(
  'get/EmployeeTimeline',
  async ({ employeeId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeTimeline(employeeId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeByIdThunk = createAsyncThunk<ResponseSingleResult<GetEmployeeResponse>,
{
  employeeId: number,
}>(
  'get/EmployeeById',
  async ({ employeeId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeById(employeeId);
      const employeeImageId = response.data.data.images?.[0]?.id;
      if (employeeImageId) {
        dispatch(setEmployeeImageData({ id: employeeImageId }));
        dispatch(getEmployeeAttachmentByIdThunk({
          id: employeeImageId, employeeId, isEmployeeImage: true,
        }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeIceContactThunk = createAsyncThunk<ResponseSingleResult<GetEmployeeIceContactResponse>,
{ employeeId: number }>(
  'get/EmployeeIceContact',
  async ({ employeeId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeIceContact(employeeId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchEmployeeThunk = createAsyncThunk<ResponseSingleResult,
{
  employeeId: number,
  data: PatchEmployeeDto,
  onClose?: VoidFunctionType,
  setError?: UseFormSetError<EmployeeFields>,
}>(
  'patch/EmployeeById',
  async ({
    employeeId, data, onClose, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { employees: { employeeActivityFilters } } = getState() as RootState;
      const response = await employeeAPI.patchEmployeeById(employeeId, data);
      dispatch(setLoading(false));
      dispatch(getEmployeeByIdThunk({ employeeId }));
      if (employeeActivityFilters.page === 1) {
        dispatch(getEmployeeTimelineThunk({ employeeId, filters: employeeActivityFilters }));
      } else {
        dispatch(setEmployeeActivityFilters({ ...employeeActivityFilters, page: 1 }));
      }
      onClose && onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const status = error.response?.data.status || '';
      if (errors.find((el) => el.key === 'NationalInsuranceNumber')) {
        setError && setError('nationalInsuranceNumber', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      if (errors.find((el) => el.key === 'Email')) {
        setError && setError('email', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeThunk = createAsyncThunk<ResponseSingleResult,
{
  employeeId: number,
  linkedAccountId?: number,
  deleteLinkedAccount?: boolean,
  onClose: VoidFunctionType,
}>(
  'delete/Employee',
  async ({
    employeeId, linkedAccountId, deleteLinkedAccount, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.deleteEmployeeById(employeeId);
      if (deleteLinkedAccount && linkedAccountId) {
        await settingsAPI.deleteUserAccount(linkedAccountId);
        dispatch(setSuccessMessage({ message: 'The user was successfully deleted.' }));
      }
      onClose();
      dispatch(setSuccessMessage({ message: 'The employee was successfully deleted.' }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeSkillsByIdThunk = createAsyncThunk<
ResponseSingleResult<{ employeeSkills: EmployeeSkillsListItemDto[] }>,
{ id: number }
>(
  'get/EmployeeSkillsById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeSkillsById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postEmployeeTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  employeeId: number, content: string, setValue: VoidFunctionType,
}>('post/EmployeeNote', async ({
  employeeId, content, setValue,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const res = await employeeAPI.postEmployeeNote(employeeId, content);
    const { employees: { employeeActivityFilters } } = getState() as RootState;
    setValue();
    if (employeeActivityFilters.page === 1) {
      dispatch(getEmployeeTimelineThunk({
        employeeId,
        filters: employeeActivityFilters,
      }));
    } else {
      dispatch(setEmployeeActivityFilters({ ...employeeActivityFilters, page: 1 }));
    }
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(setLoading(false));
    return res.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    setValue();
    return rejectWithValue(error.response?.data);
  }
});

export const editEmployeeTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/EmployeeNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await employeeAPI.putEmployeeNote(id, content);
      const {
        employees: { employeeTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventEmployee[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = employeeTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setEmployeeTimeline({ ...employeeTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }>(
  'delete/Employee/Note',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await employeeAPI.deleteEmployeeNote(id);
      const {
        employees: { employeeActivityFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setEmployeeActivityFilters({ ...employeeActivityFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
