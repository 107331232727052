import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from '../../types/coreTypes';

type DecimalInputNumberProps = {
  value: string,
  disabled?: boolean,
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  label?: string;
  error?: string;
  placeholder?: string,
  autoFocus?: boolean,
  postfix?: string,
  className?: string,
  max: number,
  maxDecimalPlaceLength?: number,
  short?: boolean,
  tip?: string,
  isNegativeValueAllowed?: boolean,
}

const DecimalInputNumber: React.FC<DecimalInputNumberProps> = ({
  value,
  disabled,
  onBlur,
  onFocus,
  onChange,
  onPressEnter,
  label,
  error,
  placeholder,
  autoFocus,
  postfix,
  className,
  max,
  maxDecimalPlaceLength = 2,
  short,
  tip,
  isNegativeValueAllowed,
}) => {
  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
      autoFocus && ref.current?.select();
    }
  }, [ref, autoFocus]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    const regex = new RegExp(`^(0|[1-9]\\d{0,8}|[.,]\\d{0,${maxDecimalPlaceLength}})([.,]\\d{0,${maxDecimalPlaceLength}})?$`);

    if (newValue === '' || regex.test(newValue)) {
      if (newValue === '' || !max || parseFloat(newValue.replace(',', '.')) <= max) {
        onChange && onChange(e);
      }
    }
  };

  return (
    <div className={classNames(className, 'regularInputNumber')}>
      {label && <span className="regularInputNumber-label">
        {label}
        {tip && <Tooltip title={tip}>
          <FontAwesomeIcon icon={faCircleInfo} className="regularInputNumber-tip" />
        </Tooltip>}
      </span>}
      <div className={classNames('regularInputNumber__numFieldWrap', {
        'regularInputNumber__numFieldWrap--short': short,
        'regularInputNumber__numFieldWrap--negative': isNegativeValueAllowed,
      })}
      >
        {isNegativeValueAllowed && <span className={classNames({ 'disabled': disabled })}>-</span>}
        <input
          type="text"
          inputMode="decimal"
          min={isNegativeValueAllowed ? undefined : 0}
          value={value}
          lang="en-GB"
          className="regularInputNumber__numFieldWrap-field"
          ref={ref}
          onChange={handleChange}
          onBlur={(e) => {
            onBlur && onBlur(e);
          }}
          onFocus={(e) => {
            onFocus && onFocus(e);
          }}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              onPressEnter && onPressEnter(e);
              ref.current?.blur();
              e.preventDefault();
            }
          }}
          disabled={disabled}
          placeholder={placeholder}
        />
        {postfix && <span className="regularInputNumber__numFieldWrap-postfix">{postfix}</span>}
      </div>
      {error && <span className="regularInputNumber-error">{error}</span>}
    </div>
  );
};

export default DecimalInputNumber;
