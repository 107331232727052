import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { initDataListWithIds, initSmallPaging, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import Thead from '../../../../../core/components/table-components/Thead';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import TableLoader from '../../../../../core/components/table-components/TableLoader';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';
import {
  generatePurchaseOrderFiltersSelector,
  partRequestLinesForPurchaseOrderGenerationTableSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import GeneratePurchaseOrderColumns from './GeneratePurchaseOrderColumns';
import {
  setGeneratePurchaseOrderFilters,
  setPartRequestLinesForPurchaseOrderGeneration,
} from '../../../../../store/slices/stockPartRequestSlice';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import PriceSourceTag from '../../../../../common/components/price-source-tag/PriceSourceTag';
import {
  generatePurchaseOrderThunk,
  getPartRequestLinesForPurchaseOrderGeneration,
} from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import './GeneratePurchaseOrderModal.scss';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

type GeneratePurchaseOrderModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const GeneratePurchaseOrderModal: React.FC<GeneratePurchaseOrderModalProps> = ({ visible, onCancel }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const filters = useAppSelector(generatePurchaseOrderFiltersSelector);
  const { items, allItemIds, totalCount } = useAppSelector(partRequestLinesForPurchaseOrderGenerationTableSelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    id && visible && dispatch(getPartRequestLinesForPurchaseOrderGeneration({
      partRequestId: +id,
      filters: { ...filters, showAsPlainList: true },
    }));
    // eslint-disable-next-line
  }, [visible, id, filters]);

  const cancelHandle = () => {
    onCancel();
    dispatch(setGeneratePurchaseOrderFilters({ ...initSmallPaging, onlyAvailableForPurchaseOrderGeneration: true }));
    dispatch(setPartRequestLinesForPurchaseOrderGeneration(initDataListWithIds));
  };

  const generateHandle = () => {
    id && dispatch(generatePurchaseOrderThunk({
      partRequestLineIds: checkedKeys,
      partRequestId: +id,
      navigate,
      closeModal: cancelHandle,
    }));
  };
  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Generate purchase order"
      width={900}
      destroyOnClose
    >
      <div>
        <p className="confirmationText">Please select part lines, which should be included into generated purchase order:</p>
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => dispatch(setGeneratePurchaseOrderFilters({ ...filters, page, pageSize }))}
          showSizeChanger={false}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          showTotal
          offsetBottom
        />
        <TableNew>
          <Thead>
            <GeneratePurchaseOrderColumns
              totalCount={totalCount}
              filters={filters}
              filtersHandle={(v) => dispatch(setGeneratePurchaseOrderFilters(v))}
              isAll={isAll}
              isIndeterminate={isIndeterminate}
              totalElements={totalCount}
              checkAll={checkAllHandle}
            />
          </Thead>
          {isGlobalLoading
            ? <TableLoader colspan={6} />
            : <Tbody>
              {totalCount === 0
                ? <EmptyTableSection
                  colSpan={6}
                  text="No records"
                />
                : <>
                  {items.map((line, i) => <Tr key={i}>
                    <TdNew>
                      <Checkbox
                        checked={checkedKeys.includes(line.id)}
                        onChange={() => checkRowHandle(line.id)}
                        table
                      />
                    </TdNew>
                    {Object.entries(line).map(([key, value]) => {
                      if (key === 'id') return null;
                      return <TdNew key={key}>
                        {key === 'sourceId'
                          ? <>{(value as number[])?.map((el: number, i: number) => <PriceSourceTag key={i} source={el} />)}</>
                          : <TruncateTooltip
                            value={(value as string | number) || '-'}
                            table
                          />}
                      </TdNew>;
                    })}
                  </Tr>)}
                </>}
            </Tbody>}
        </TableNew>
        <ButtonActions
          createLabel="Generate"
          cancelClick={cancelHandle}
          createClick={generateHandle}
          disabledCreate={totalCount === 0 || !checkedKeys.length}
          isLoading={isFetching}
          offsetTop
        />
      </div>
    </Modal>
  );
};

export default GeneratePurchaseOrderModal;
