import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { NO_SPACES_REGEX } from '../../../core/utils/regex';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const OrderTextareaSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};

export const OrderCustomerRefNumberSchema = { maxLength: { value: 100, message: maxLengthMessageHandle(100) } };
export const XeroInvoiceUrlSchema = {
  maxLength: { value: 300, message: maxLengthMessageHandle(300) },
  pattern: { value: NO_SPACES_REGEX, message: ErrorsEnum.SPACES_ARE_NOT_ALLOWED },
};

export type OrderFields = {
  priorityLevel?: number,
  jobType?: number,
  isPaid: number
  orderDate: string | null,
  dueDate?: string | null,
  customerReferenceNumber: string | null,
  description: string | null,
  rejectionReason: string | null,
  xeroInvoiceId: string | null,
  xeroInvoiceUrl: string | null,
}

export const orderDetailsDefaults: OrderFields = {
  priorityLevel: undefined,
  jobType: undefined,
  isPaid: 0,
  orderDate: null,
  dueDate: null,
  description: '',
  customerReferenceNumber: '',
  rejectionReason: '',
  xeroInvoiceId: '',
  xeroInvoiceUrl: '',
};
