import React from 'react';
import { PricelistEventDtoBase } from '../../../../types/pricelistViewPageTypes';
import PricelistNoteEvent from './PricelistNoteEvent';
import { useAppSelector } from '../../../../../../store/hooks';
import { PricelistEventsEnum } from '../../../../enums/PricelistEventsEnum';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import PricelistFieldUpdateEvent from './PricelistFieldUpdateEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import PriceEvent from './price-event/PriceEvent';
import { pricelistEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import { pricelistStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';

type PricelistEventProps = {
  item: PricelistEventDtoBase,
}

const PricelistEvent: React.FC<PricelistEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(pricelistEventTypesSelector);
  const pricelistStatuses = useAppSelector(pricelistStatusesSelector);

  const attachmentEvents = [
    PricelistEventsEnum.AddingAttachmentWithoutComment,
    PricelistEventsEnum.AddingAttachmentWithComment,
    PricelistEventsEnum.EditingAttachmentComment,
    PricelistEventsEnum.DeletingAttachment,
  ];

  const priceEvents = [
    PricelistEventsEnum.PricelistProductCreation,
    PricelistEventsEnum.PricelistProductFieldUpdateOrAddingValue,
    PricelistEventsEnum.PricelistProductDeletion,
  ];

  const pricelistEventRenderFn = (item: PricelistEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case (event === PricelistEventsEnum.Note):
        return <PricelistNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case (event === PricelistEventsEnum.PricelistCreation):
        return <CreationEvent entity="Pricelist" type="name" value={item.name} />;
      case (event === PricelistEventsEnum.PricelistDeletion):
        return <DeletionEvent entity="Pricelist" />;
      case (event === PricelistEventsEnum.PricelistFieldUpdateOrAddingValue):
        return <PricelistFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (event === PricelistEventsEnum.PricelistStatusChange): {
        const prevStatus = pricelistStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = pricelistStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case (attachmentEvents.includes(event as PricelistEventsEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (priceEvents.includes(event as PricelistEventsEnum)):
        return <PriceEvent
          eventName={event}
          productName={item.productName}
          oldPrices={item.oldPrices}
          newPrices={item.newPrices}
        />;
      default: return null;
    }
  };

  return <>{pricelistEventRenderFn(item)}</>;
};

export default PricelistEvent;
