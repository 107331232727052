import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setAttachmentsTableFilters,
  setDetailsFilters,
  setEnqAttachments,
  setEnqTimeline,
  setEnqTimelineFilters,
  setEnquiryContacts,
  setEnquiryContactsPaging,
  setEnquiryDetails, setGenerateEmailContacts, setGenerateEmailContactsPaging,
  setOpenedVehicleId,
  setQuoteLines,
  setQuoteLinesFilters,
  setQuoteVehiclesList,
  setQuoteVehicleSummary,
} from '../../../store/slices/enquiriesSlice';
import { InitialQuoteLinesFilters } from '../../../store/initialValues';
import {
  infiniteScrollInitPaging,
  initDataList, initDataListWithIds,
  initialCoreAttachmentsFilters,
} from '../../../core/types/coreTypes';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const unmountDetailsHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setEnquiryDetails(undefined));
  dispatch(setDetailsFilters({
    assignedTo: undefined,
    statusTransition: undefined,
  }));
  dispatch(setAttachmentsTableFilters(initialCoreAttachmentsFilters));
  dispatch(setEnqAttachments([]));
  dispatch(setQuoteVehiclesList([]));
  dispatch(setQuoteLinesFilters(InitialQuoteLinesFilters));
  dispatch(setOpenedVehicleId(undefined));
  dispatch(setQuoteLines({ ...initDataListWithIds, maxNetPrice: '0' }));
  dispatch(setQuoteLinesFilters(InitialQuoteLinesFilters));
  dispatch(setEnqTimeline(initDataList));
  dispatch(setEnqTimelineFilters(initExtendedTimelineFilters));
  dispatch(setEnquiryContacts(initDataListWithIds));
  dispatch(setEnquiryContactsPaging(infiniteScrollInitPaging));
  dispatch(setGenerateEmailContacts(initDataListWithIds));
  dispatch(setGenerateEmailContactsPaging(infiniteScrollInitPaging));
  dispatch(setQuoteVehicleSummary(undefined));
};
