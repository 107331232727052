import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import SupplierEvent from './supplier-actitvity-events/SupplierEvent';
import Switch from '../../../../../core/components/switch/Switch';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import {
  supplierActivityLogFiltersSelector,
  supplierTimelineSelector,
} from '../../../../../store/selectors/supplierSelector';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setSupplierActivityLogFilters } from '../../../../../store/slices/supplierSlice';
import {
  addSupplierTimelineNoteThunk,
  getSupplierTimelineThunk,
} from '../../../../../store/thunks/suppliers/suppliersViewPageThunks';
import { getSupplierEventTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

const SupplierActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const allowedToAddNote = usePermission(PermissionEnum.SupplierViewPage);
  const timelineFilters = useAppSelector(supplierActivityLogFiltersSelector);
  const { items, totalCount } = useAppSelector(supplierTimelineSelector);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <SupplierEvent item={el} />,
  }));

  useEffect(() => {
    dispatch(getSupplierEventTypesThunk());
    // eslint-disable-next-line
    }, []);
  useEffect(() => {
    id && dispatch(getSupplierTimelineThunk({
      supplierId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
    }, [id, timelineFilters.mainOnly, timelineFilters.page]);

  const addNote = () => {
    id && dispatch(addSupplierTimelineNoteThunk({
      supplierId: +id, content: value.trim(), setValue: () => setValue(''),
    }));
  };
  return (
    <section className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(val) => dispatch(setSupplierActivityLogFilters({ ...timelineFilters, mainOnly: !val, page: 1 }))}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setSupplierActivityLogFilters({ ...timelineFilters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default SupplierActivitySection;
