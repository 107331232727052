export enum OrderEventEnum {
  Note = 'Note',
  OrderCreation = 'OrderCreation',
  OrderFieldUpdateOrAddingValue = 'OrderFieldUpdateOrAddingValue',
  OrderStatusChange = 'OrderStatusChange',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  ShippingDetailsUpdates = 'ShippingDetailsUpdates',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
  JobLocationUpdate = 'JobLocationUpdate',
  PartRequestLineCreationChanges = 'PartRequestLineCreationChanges',
  NominalRecordCreation = 'NominalRecordCreation',
  NominalRecordFieldUpdate = 'NominalRecordFieldUpdate',
  NominalRecordDeletion = 'NominalRecordDeletion',
  InvoiceDocumentCreation = 'InvoiceDocumentCreation',
  InvoiceDocumentFieldUpdate = 'InvoiceDocumentFieldUpdate',
  InvoiceDocumentDeletion = 'InvoiceDocumentDeletion',
  EditingAttachmentVisibility = 'EditingAttachmentVisibility',
}
