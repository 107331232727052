import React, { useEffect, useState } from 'react';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../core/components/layout/Layout';
import CustomersFilters from './components/customers-filters/CustomersFilters';
import CustomerLine from './components/customer-line/CustomerLine';
import './CustomersDashboardContainer.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getCustomers } from '../../store/thunks/customers/customersDashboardThunks';
import {
  customersDashFiltersSelector,
  customersListSelector,
} from '../../store/selectors/customersSelectors';
import { DashboardViewsEnum } from './enums/DashboardViewsEnum';
import { initDataList } from '../../core/types/coreTypes';
import { setCustomers } from '../../store/slices/customersSlice';
import EmptyContentList from '../../core/components/empty-content-list/EmptyContentList';
import SkeletonLinesList from '../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import CustomersSkeletonItem from './components/customers-skeleton/CustomersSkeletonItem';
import { isDashboardLoadingSelector } from '../../store/selectors/coreSelectors';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { useCancelRequest } from '../../core/hooks/useCancelRequest';

const CustomersDashboardContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(customersDashFiltersSelector);
  const {
    page, pageSize, status, customerType, keyword,
  } = filters;
  const list = useAppSelector(customersListSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);
  const [view, setView] = useState<string | number>(DashboardViewsEnum.Dashboard);
  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    cancelRequest();
    dispatch(getCustomers({
      params: {
        page,
        pageSize,
        keyword,
        customerType,
        customerStatus: status,
      },
      isDashboard: true,
      signal: controller.current?.signal,
    }));
    // eslint-disable-next-line
  }, [page, pageSize, status, customerType, keyword]);

  useEffect(() => {
    return () => {
      dispatch(setCustomers(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <CustomersFilters filters={filters} view={view} setView={setView} totalCount={list.totalCount} />
      {isLoading
        ? <SkeletonLinesList>
          <CustomersSkeletonItem />
        </SkeletonLinesList>
        : <>
          {list.totalCount > 0
            ? <ul className="lines-list">
              {list.items?.map((customer) => (
                <li key={customer.id} className="lines-list__item">
                  <CustomerLine customer={customer} />
                </li>
              ))}
            </ul>
            : <EmptyContentList icon={faBoxes} text="No records" />}
        </>}
    </Layout>
  );
};

export default CustomersDashboardContainer;
