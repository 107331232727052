import { useMemo, useState } from 'react';
import { SuitableForVehicleGroupsData, SuitableForVehiclesData } from '../../../../types/commonTypes';

export const useSuitableForVehiclePagination = (items: SuitableForVehicleGroupsData[] | SuitableForVehiclesData[]) => {
  const [page, setPage] = useState(1);

  const PAGE_SIZE = 5;
  const startIndex = page * PAGE_SIZE - PAGE_SIZE;
  const endIndex = page * PAGE_SIZE;

  const rowsMemo = useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return items.map(({ id, ...rest }, i) => ({ number: i + 1, ...rest }));
  }, [items]);

  const rows = rowsMemo.slice(startIndex, endIndex);

  return {
    PAGE_SIZE,
    page,
    setPage,
    rows,
    totalItems: items.length,
  };
};
