import React from 'react';
import { PricelistDto } from '../../../dashboard/types/pricelistTypes';
import Tag from '../../../../core/components/tag/Tag';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { pricelistStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type PricelistHeadProps = {
  details: PricelistDto | null
}

const PricelistHead: React.FC<PricelistHeadProps> = ({ details }) => {
  const pricelistStatuses = useAppSelector(pricelistStatusesSelector);
  const status = pricelistStatuses.find((el) => el.value === details?.status)?.label;
  const entities = [
    { label: '', value: details?.name },
    { label: 'Status', value: status },
    { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.createdAt || '') },
    { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.updatedAt || '') },
  ];
  return (
    <>
      {entities.map((ent, i) => <div key={i} className="head__inner-info-item head__inner-info-item--narrow">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Status'
          ? <Tag label={ent.value?.toString() || '-'} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default PricelistHead;
