import { useNavigate, useSearchParams } from 'react-router-dom';
import { StockPageParamEnums, StockPageParams } from '../enums/stockEnums';
import {
  PurchaseUrlParamsNames, StockTabParamEnum, StockTabParams,
} from '../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { purchaseOrderStatusSelector } from '../../../store/selectors/purchaseOrderSelectors';
import { useAppSelector } from '../../../store/hooks';
import { PartRequestsUrlParamsNames } from '../../stock-part-requests/enums/stockPartRequestsEnum';
import { stockPartReqStatusWithCountSelector } from '../../../store/selectors/stockPartRequestsSelector';

export const useStockPageParams = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const purchaseOrderStatus = useAppSelector(purchaseOrderStatusSelector);
  const stockPartRequestStatuses = useAppSelector(stockPartReqStatusWithCountSelector);

  const setPageParam = (mode: string) => {
    if (StockPageParams.includes(mode as StockPageParamEnums)) {
      params.append(PurchaseUrlParamsNames.PAGE, mode);
    } else {
      params.delete(PurchaseUrlParamsNames.PAGE);
    }
    navigate({ search: `?${PurchaseUrlParamsNames.PAGE}=${mode}` }, { replace: true });
  };

  const setPurchaseStatusParam = (mode: string) => {
    if (purchaseOrderStatus.find((st) => st.value.toString() === mode)) {
      if (params.get(PurchaseUrlParamsNames.PURCHASE_STATUS)) {
        params.set(PurchaseUrlParamsNames.PURCHASE_STATUS, mode);
      } else {
        params.append(PurchaseUrlParamsNames.PURCHASE_STATUS, mode);
      }
    } else {
      params.delete(PurchaseUrlParamsNames.PURCHASE_STATUS);
    }
    navigate({ search: params.toString() });
  };

  const setPurchaseTabTypeParam = (tabMode: string) => {
    const page = params.get(PurchaseUrlParamsNames.PAGE);
    const isOrderStatusIncluded = StockTabParams.includes(tabMode as StockTabParamEnum);
    if (page === StockPageParamEnums.PURCHASES && isOrderStatusIncluded) {
      if (params.get(PurchaseUrlParamsNames.TAB_TYPE)) {
        params.set(PurchaseUrlParamsNames.TAB_TYPE, tabMode);
      } else {
        params.append(PurchaseUrlParamsNames.TAB_TYPE, tabMode);
      }
      params.delete(PurchaseUrlParamsNames.PURCHASE_STATUS);
    } else {
      params.delete(PurchaseUrlParamsNames.TAB_TYPE);
    }
    navigate({ search: params.toString() });
  };

  const setStockPartReqParam = (mode: string) => {
    if (stockPartRequestStatuses.find((st) => st.value.toString() === mode)) {
      if (params.get(PartRequestsUrlParamsNames.PART_REQ_STATUS)) {
        params.set(PartRequestsUrlParamsNames.PART_REQ_STATUS, mode);
      } else {
        params.append(PartRequestsUrlParamsNames.PART_REQ_STATUS, mode);
      }
    } else {
      params.delete(PartRequestsUrlParamsNames.PART_REQ_STATUS);
    }
    navigate({ search: params.toString() });
  };
  const setStockPartReqTabTypeParam = (tabMode: string) => {
    const page = params.get(PartRequestsUrlParamsNames.PAGE);
    const isPartReqTabIncluded = StockTabParams.includes(tabMode as StockTabParamEnum);
    if (page === StockPageParamEnums.PART_REQUESTS && isPartReqTabIncluded) {
      if (params.get(PartRequestsUrlParamsNames.TAB_TYPE)) {
        params.set(PartRequestsUrlParamsNames.TAB_TYPE, tabMode);
      } else {
        params.append(PartRequestsUrlParamsNames.TAB_TYPE, tabMode);
      }
      params.delete(PartRequestsUrlParamsNames.PART_REQ_STATUS);
    } else {
      params.delete(PartRequestsUrlParamsNames.TAB_TYPE);
    }
    navigate({ search: params.toString() });
  };

  return {
    params,
    setPageParam,
    setPurchaseStatusParam,
    setPurchaseTabTypeParam,
    setStockPartReqParam,
    setStockPartReqTabTypeParam,
  };
};
