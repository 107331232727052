import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { BooleanFunctionType, StringFunctionType } from '../../../core/types/coreTypes';
import { TelephoneSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import AddressField from '../../../common/components/address/AddressField';
import { AddressFields } from '../../../common/types/commonTypes';
import {
  EmployeeFields,
  IceContactFullNameSchema,
  IceContactRelationshipSchema,
} from '../../employee-view-page/utils/EmployeeSchema';

type EmployeeICEContactSectionProps = {
  control: Control<EmployeeFields>,
  errors: FieldErrors<EmployeeFields>,
  iceAddressFields: AddressFields,
  setIceAddressModal: BooleanFunctionType,
  addressError: string,
  setAddressError: StringFunctionType,
  treatSpacesAsEmptyHandle: (v: string, field: keyof EmployeeFields, required?: boolean) => void,
}

const EmployeeICEContactSection: React.FC<EmployeeICEContactSectionProps> = ({
  control, errors, iceAddressFields, setIceAddressModal, setAddressError,
  addressError, treatSpacesAsEmptyHandle,
}) => {
  return (
    <section className="details-form">
      <h2 className="info-grid__title">ICE contact</h2>
      <Controller
        name="iceContactFullName"
        control={control}
        rules={IceContactFullNameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'iceContactFullName');
            }}
            error={errors.iceContactFullName?.message}
            label="Full name"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="relationship"
        control={control}
        rules={IceContactRelationshipSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'relationship');
            }}
            error={errors.relationship?.message}
            label="Relationship"
          />
        )}
      />
      <Controller
        name="iceContactTelephone"
        control={control}
        rules={TelephoneSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'iceContactTelephone', false);
            }}
            error={errors.iceContactTelephone?.message}
            label="Telephone"
          />
        )}
      />
      <AddressField
        addressFields={iceAddressFields}
        setAddressModal={(v) => {
          setIceAddressModal(v);
          addressError && setAddressError('');
        }}
        title="Address"
        isIconVisible={Object.values(iceAddressFields).some((el) => el)}
        addressError={addressError}
      />
    </section>
  );
};

export default EmployeeICEContactSection;
