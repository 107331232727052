import React from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path,
} from 'react-hook-form';
import ImageMarker, { Marker, MarkerComponentProps } from 'react-image-marker';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Select from '../../../core/components/select/Select';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { weatherConditionsSelector } from '../../../store/selectors/coreSelectors';
import Button from '../../../core/components/button/Button';
import VEHICLE_SCHEME_PNG from '../../../assets/images/vehicle-scheme.png';
import { YesNoOptions } from '../../../core/utils/testData';
import { setMarkersList } from '../../../store/slices/vehiclesSlice';
import { vehicleCheckSchemaUrlSelector } from '../../../store/selectors/vehiclesSelectors';
import { MarkersList } from '../../vehicle-view-page/components/vehicle-content/vehicle-inspection/types/VehicleInspectionTypes';

type VisualInspectionProps<T extends FieldValues> = {
  control: Control<T>,
  errors: FieldErrors<T>,
  markersList: MarkersList,
  disabled?: boolean,
  openMarkersDetails: VoidFunctionType,
}

const VisualInspection = <T extends FieldValues>({
  control, errors, markersList, disabled, openMarkersDetails,
}: VisualInspectionProps<T>) => {
  const dispatch = useAppDispatch();
  const weatherConditions = useAppSelector(weatherConditionsSelector);
  const vehicleCheckSchemaUrl = useAppSelector(vehicleCheckSchemaUrlSelector);
  const customMarkerRender = (props: MarkerComponentProps) => {
    return <div className="customImageMarker">{((props.itemNumber as number) + 1).toString()}</div>;
  };

  return (
    <section className="details-form vehicleCheck-section">
      <h2 className="info-grid__title">Visual inspection</h2>
      <Controller
        control={control}
        name={'weatherConditions' as Path<T>}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            label="Weather conditions"
            options={weatherConditions}
            error={errors.weatherConditions?.message}
            disabled={disabled}
          />
        )}
      />
      <Controller
        control={control}
        name={'isExteriorClean' as Path<T>}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            label="Exterior clean"
            options={YesNoOptions}
            error={errors.isExteriorClean?.message}
            disabled={disabled}
          />
        )}
      />
      <Controller
        control={control}
        name={'isInteriorClean' as Path<T>}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            label="Interior clean"
            options={YesNoOptions}
            error={errors.isInteriorClean?.message}
            disabled={disabled}
          />
        )}
      />
      <div className="inspectionMarker" id="capture" style={{ padding: 12 }}>
        <ImageMarker
          src={disabled
            ? `data:image/jpeg;base64,${vehicleCheckSchemaUrl}`
            : VEHICLE_SCHEME_PNG}
          markers={disabled ? [] : markersList.map((el) => el.marker as Marker)}
          onAddMarker={(marker: Marker) => {
            if (markersList.length < 15 && !disabled) {
              dispatch(setMarkersList([...markersList, { marker, comment: '' }]));
            }
          }}
          markerComponent={customMarkerRender}
        />
      </div>
      <Button
        label={`Show marker details (${markersList.length})`}
        designType="light-inverse"
        className="markersButton"
        onClick={openMarkersDetails}
      />
    </section>
  );
};

export default VisualInspection;
