import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import Button from '../../../core/components/button/Button';
import { EntityLinkRowType } from '../../../core/types/coreTypes';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../private-line-link/PrivateLineLink';

type LinksRowsProps = {
  parent: EntityLinkRowType,
  visibleParents: (string | number)[],
  setVisibleParents: (v: (string | number)[]) => void,
  parentLink: string,
  linkPermission: PermissionEnum,
}

const LinksRows: React.FC<LinksRowsProps> = ({
  parent, visibleParents, setVisibleParents, parentLink, linkPermission,
}) => {
  const parentName = parent.name;
  const kids = parent.children;
  const kidsLength = parent.children.length;

  const hiddenKeys = ['entityId', 'type'];
  const setParentsHandle = (name: string | number) => {
    if (visibleParents.includes(name)) {
      const newList = visibleParents.filter((p) => p !== name);
      setVisibleParents(newList);
    } else {
      setVisibleParents([...visibleParents, name]);
    }
  };
  return (
    <>
      <Tr className={kids.length === 0 ? 'trHidden' : ''}>
        <TdNew colSpan={4}>
          <div className="linksRows__parent-row">
            <Button
              icon={<FontAwesomeIcon icon={visibleParents.includes(parentName) ? faMinusCircle : faPlusCircle} />}
              onClick={() => setParentsHandle(parentName)}
              isExpand
            />
            <b>{`${parentName} (${kidsLength})`}</b>
          </div>
        </TdNew>
      </Tr>
      {kidsLength > 0 && kids.map((ch, i) => <Tr
        key={i}
        className={!visibleParents.includes(parentName) ? 'trHidden' : ''}
      >
        {Object.entries(ch).map(([key, value]) => {
          if (hiddenKeys.includes(key)) return null;
          return <TdNew key={key} offsetLeft={key === 'entityName'}>
            {(key === 'entityName')
              ? <PrivateLineLink
                to={`/${parentLink}/${ch.entityId}`}
                permission={linkPermission}
              >
                {value || '-'}
              </PrivateLineLink>
              : value}
          </TdNew>;
        })}
      </Tr>)}
    </>
  );
};

export default LinksRows;
