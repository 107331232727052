import React from 'react';
import { Radio as RadioButton } from 'antd';
import './Radio.scss';

type RadioProps = {
  label?: string;
  value: string | number;
  onChange?: (value: string | number) => void;
  disabled?: boolean;
  checked?: boolean,
}

const Radio: React.FC<RadioProps> = ({
  label,
  value,
  onChange,
  disabled,
  checked,
}) => (
  <RadioButton
    checked={checked}
    value={value}
    onChange={(e) => onChange && onChange(e.target.value)}
    disabled={disabled}
    className="radio"
  >
    {label}
  </RadioButton>
);

export default Radio;
