import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../../core/components/modal/Modal';
import {
  infiniteScrollInitPaging, initDataListWithIds, VoidFunctionType,
} from '../../../../../../core/types/coreTypes';
import Button from '../../../../../../core/components/button/Button';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import ContactLine from '../../../../../../common/components/contacts/ContactLine';
import { setGenerateEmailContacts, setGenerateEmailContactsPaging } from '../../../../../../store/slices/enquiriesSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  enquiryDetailsSelector,
  generateEmailContactsListSelector,
  generateEmailContactsPagingSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import { getGenerateEmailEnquiryContacts } from '../../../../../../store/thunks/enquiry/enquiryContactsThunks';
import { isFetchingSelector } from '../../../../../../store/selectors/coreSelectors';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';
import { generateEnquiryEmailPdfThunk } from '../../../../../../store/thunks/enquiry/detailsThunks';
import OpenEmailClientModal from '../../../../../../common/components/generate-email-modal/OpenEmailClientModal';

type GenerateEmailModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const GenerateEmailModal: React.FC<GenerateEmailModalProps> = ({ visible, onCancel }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const enquiryDetails = useAppSelector(enquiryDetailsSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const generateEmailContactsPaging = useAppSelector(generateEmailContactsPagingSelector);
  const generateEmailContactsList = useAppSelector(generateEmailContactsListSelector);
  const [emailHref, setEmailHref] = useState('');

  const {
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(generateEmailContactsList.allItemIds);

  const cancelHandle = () => {
    onCancel();
    dispatch(setGenerateEmailContacts(initDataListWithIds));
    dispatch(setGenerateEmailContactsPaging(infiniteScrollInitPaging));
  };
  const loadMore = () => {
    const newValues = { ...generateEmailContactsPaging, page: generateEmailContactsPaging.page + 1 };
    dispatch(setGenerateEmailContactsPaging(newValues));
  };

  useEffect(() => {
    if (visible && enquiryDetails) {
      dispatch(getGenerateEmailEnquiryContacts({
        id: enquiryDetails.enquiry.id,
        page: generateEmailContactsPaging.page,
        pageSize: generateEmailContactsPaging.pageSize,
        haveEmail: true,
      }));
    }
    // eslint-disable-next-line
  }, [visible, enquiryDetails, generateEmailContactsPaging]);

  const selectHandle = () => {
    const selectedContacts = generateEmailContactsList.items
      .filter(({ id }) => checkedKeys.includes(id))
      .map((el) => ({ id: el.id, email: el.email || '', name: `${el.firstName} ${el.lastName}` }));
    id && dispatch(generateEnquiryEmailPdfThunk({
      id: +id,
      selectedContacts,
      closeModal: cancelHandle,
      setEmailHref,
    }));
  };

  return (
    <>
      <OpenEmailClientModal emailHref={emailHref} onCancel={() => setEmailHref('')} />
      <Modal
        visible={visible}
        onCancel={cancelHandle}
        destroyOnClose
        width={800}
        title="Select contacts for email"
      >
        <section className="contacts">
          <Button
            label="Select all"
            onClick={checkAllHandle}
            disabled={isFetching}
            className="generateEmailSelectAll"
          />
          <Button
            label="Deselect all"
            onClick={uncheckAllHandle}
            disabled={isFetching}
          />
          <div className="contacts__wrapper generateContactsWrapper" id="contactsWrapper">
            <InfiniteScroll
              dataLength={generateEmailContactsList.items.length}
              next={loadMore}
              scrollableTarget="contactsWrapper"
              hasMore={generateEmailContactsPaging.page < generateEmailContactsList.pages}
              loader={<span className="contacts__loader">Loading...</span>}
            >
              <ul className="lines-list">
                {generateEmailContactsList.items.map((line) => (
                  <li
                    className="lines-list__item"
                    key={line.id}
                  >
                    <ContactLine
                      line={line}
                      checkedKeys={checkedKeys}
                      checkLine={checkRowHandle}
                      isFetching={isFetching}
                    />
                  </li>))}
              </ul>
            </InfiniteScroll>
          </div>
          <ButtonActions
            cancelLabel="Cancel"
            cancelClick={cancelHandle}
            createLabel={`Select contacts ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
            createClick={() => selectHandle()}
            disabledCreate={checkedKeys.length === 0}
            isLoading={isFetching}
          />
        </section>
      </Modal>
    </>
  );
};

export default GenerateEmailModal;
