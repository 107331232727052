import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Layout from '../../../core/components/layout/Layout';
import { routesPath } from '../../../core/enums/pathEnum';
import './ErrorPage.scss';
import { ErrorCodesEnum } from '../../enums/ErrorCodesEnum';
import { EmailsEnum } from '../../../core/enums/emailsEnum';
import { setCurrentServerError } from '../../../store/slices/coreSlice';
import { useAppDispatch } from '../../../store/hooks';

type ErrorPageProps = {
  errorStatusCode: { status?: string, httpCode?: number }
}

const ErrorPage: React.FC<ErrorPageProps> = ({ errorStatusCode }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const tokenErrors = [ErrorCodesEnum.RESET_TOKEN, ErrorCodesEnum.EXPIRED];
  const textRender = () => {
    if (errorStatusCode.status === ErrorCodesEnum.NOT_FOUND) {
      return <>
        <span className="errorPage__notFound">Not found</span>
        <div className="errorPage__words">
          <span className="errorPage__words-firstLine">The page you were looking for</span>
          <span className="errorPage__words-secLine">is not available</span>
        </div>
      </>;
    } else {
      return <>
        <span className="errorPage__notFound">Oops...</span>
        <div className="errorPage__words">
          <span className="errorPage__words-firstLine">Something went wrong</span>
        </div>
      </>;
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      navigate(`/${routesPath.HOME}`);
    }
    // eslint-disable-next-line
  }, [location.pathname]);
  return (
    <>
      {tokenErrors.includes(errorStatusCode.status as ErrorCodesEnum)
        ? <div className="errorPage">
          <>
            <span className="errorPage__notFound">Oops...</span>
            <div className="errorPage__words">
              {errorStatusCode.status === ErrorCodesEnum.EXPIRED
                ? <span className="errorPage__words">It seems that your invitation link has expired.</span>
                : <span className="errorPage__words">
                  It seems that your reset link has expired.
                  Please try again or contact
                  {' '}
                  <a className="errorPage__linkWrap-link" href={`mailto:${EmailsEnum.SUPPORT_COM}`}>{EmailsEnum.SUPPORT_COM}</a>
                </span>}
            </div>
          </>
          {errorStatusCode.status === ErrorCodesEnum.EXPIRED
          && <span className="errorPage__linkWrap">
            Please contact
            {' '}
            <a className="errorPage__linkWrap-link" href={`mailto:${EmailsEnum.SUPPORT}`}>{EmailsEnum.SUPPORT}</a>
          </span>}
        </div>
        : <Layout>
          <div className="errorPage">
            {textRender()}
            <span className="errorPage__linkWrap">
              Go to
              {' '}
              <Link
                className="errorPage__linkWrap-link"
                to={`/${routesPath.HOME}`}
                onClick={() => dispatch(setCurrentServerError(undefined))}
              >
                Home page
              </Link>
            </span>
          </div>
        </Layout>}
    </>
  );
};

export default ErrorPage;
