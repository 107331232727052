import moment from 'moment/moment';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';

export type SetWorkingHoursFields = {
  employeeIds: number[],
  departments: number[],
  jobTitles: string[],
  startDate: string,
  endDate: string,
  areWeekendsAndHolidaysNonWorking: boolean,
  scheduledWorkingHours: string,
  nonWorkingHours: string,
};

export const setWorkingHoursDefaults: SetWorkingHoursFields = {
  employeeIds: [],
  departments: [],
  jobTitles: [],
  startDate: moment().format(DEFAULT_DATE_FORMAT),
  endDate: moment().format(DEFAULT_DATE_FORMAT),
  areWeekendsAndHolidaysNonWorking: true,
  scheduledWorkingHours: '0.00',
  nonWorkingHours: '0.00',
};
