import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../../../store/hooks';
import { jobPartsTableLinesSelector } from '../../../../../store/selectors/jobsSelector';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import JobPartsLine from './JobPartsLine';
import { CustomAny } from '../../../../../core/types/coreTypes';
import PrivateLineLink from '../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../../core/components/tag/Tag';
import { QuoteUnitsOfMeasureSelector } from '../../../../../store/selectors/coreSelectors';
import Tbody from '../../../../../core/components/table-components/Tbody';
import JobPartsChildLine from './JobPartsChildLine';
import { JobPartsTableLine } from '../../../types/jobViewTypes';
import { PartQtyType } from '../../../../../stock/part-view-page/types/types';
import { partReqLineActionsStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';

type JobPartsBodyProps = {
  columnsLength: number,
}
const JobPartsBody: React.FC<JobPartsBodyProps> = ({ columnsLength }) => {
  const { deleted } = useAppSelector(partReqLineActionsStatusesSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const lines = useAppSelector(jobPartsTableLinesSelector);

  const hiddenKeys = [
    'id',
    'partId',
    'partsKitId',
    'unitOfMeasure',
    'partsKitComponentId',
    'partsKitComponentPartId',
    'childLines',
    'readyQuantities',
    'collectedQuantities',
  ];
  const nowrapKeys = ['quantity', 'readyQuantity', 'collectedQuantity'];

  const getQuantityValue = (quantity: number, unitOfMeasure: number) => {
    return `${quantity} ${unitsOfMeasure.find((unit) => unit.value === unitOfMeasure)?.label}`;
  };

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip value={value} table />;
  };
  const renderQuantities = (
    quantityValue: string | number,
    readyOrCollectedQuantities: PartQtyType[] | null,
    unitOfMeasure: number,
    type: 'collected' | 'ready',
  ) => {
    return <>
      {quantityValue}
      {readyOrCollectedQuantities && readyOrCollectedQuantities.length > 0 && <Tooltip
        title={
          <ul>
            {readyOrCollectedQuantities.map((q, i) => (
              <li key={i}>
                {type === 'ready'
                  ? `${getQuantityValue(q.quantity, unitOfMeasure)} ${q.locationComment ? `- ${q.locationComment}` : ''}`
                  : `${getQuantityValue(q.quantity, unitOfMeasure)} - ${q.owner?.firstName || ''} ${q.owner?.lastName || ''}`}
              </li>))}
          </ul>
        }
        overlayClassName="truncate-table-tooltip"
      >
        <FontAwesomeIcon icon={faCircleInfo} className="jobParts__line-tip" />
      </Tooltip>}
    </>;
  };
  const renderCellValue = (
    key: string,
    value: CustomAny,
    unitOfMeasure: number,
    line: JobPartsTableLine | Omit<JobPartsTableLine, 'childLines'>,
  ) => {
    switch (key) {
      case 'notes':
        return renderTruncateTooltip(value);
      case 'quantity':
        return getQuantityValue(value, unitOfMeasure);
      case 'readyQuantity':
        return renderQuantities(line.readyQuantity, line.readyQuantities, line.unitOfMeasure, 'ready');
      case 'collectedQuantity':
        return renderQuantities(line.collectedQuantity, line.collectedQuantities, line.unitOfMeasure, 'collected');
      case 'status':
        return <Tag label={value} />;
      case 'productName':
      case 'productNumber':
        if (line.partId || line.partsKitComponentPartId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/part/${line.partId || line.partsKitComponentPartId}`}
            permission={PermissionEnum.StockPartViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? line.productName : line.productNumber)}
          </PrivateLineLink>;
        }
        if (line.partsKitId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${line.partsKitId}`}
            permission={PermissionEnum.StockPartsKitViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? line.productName : line.productNumber)}
          </PrivateLineLink>;
        } else {
          return renderTruncateTooltip(key === 'productName' ? line.productName : line.productNumber);
        }
      default: return value;
    }
  };

  return (
    <Tbody>
      {lines.length > 0
        ? <>
          {lines.map((el) => <React.Fragment key={el.id}>
            <JobPartsLine
              key={el.id}
              hiddenKeys={hiddenKeys}
              nowrapKeys={nowrapKeys}
              renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, el)}
              line={el}
              deletedStatus={deleted}
            />
            {el.childLines && el.childLines.length > 0 && (
              el.childLines.map((ch) => <JobPartsChildLine
                key={ch.id}
                hiddenKeys={hiddenKeys}
                nowrapKeys={nowrapKeys}
                renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, ch)}
                childLine={ch}
                deletedStatus={deleted}
              />)
            )}
          </React.Fragment>)}
        </>
        : <EmptyTableSection text="No parts" colSpan={columnsLength} />}
    </Tbody>
  );
};

export default JobPartsBody;
