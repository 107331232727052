import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import Tbody from '../../../../core/components/table-components/Tbody';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import { assignNominalCodesSummaryColumns } from '../../../invoicing-purchases/utils/data';
import { AssignNominalCodesSummaryType } from '../../types/invoicingCommonTypes';

type AssignNominalCodesSummaryProps = {
  summarySectionData?: AssignNominalCodesSummaryType,
  isInvoicedAmountGreaterThanReceived: boolean,
}

const AssignNominalCodesSummary: React.FC<AssignNominalCodesSummaryProps> = ({
  summarySectionData,
  isInvoicedAmountGreaterThanReceived,
}) => {
  return (
    <TableNew small borderless>
      <Thead>
        <Tr>
          {assignNominalCodesSummaryColumns.map((col) => (
            <ThNew
              key={col.dataIndex}
              className={`assignNominalCodesSummaryTh--${col.dataIndex}`}
            >
              {col.title}
            </ThNew>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {!summarySectionData
          ? <EmptyTableSection text="No data" colSpan={assignNominalCodesSummaryColumns.length} />
          : summarySectionData.map((item, i) => <Tr key={i}>
            {Object.entries(item).map(([key, value]) => {
              return <TdNew key={key} className={classNames('nowrap', { 'fw-bold': key === 'status' })}>
                {value}
                {' '}
                {key === 'status' && value === 'Invoiced' && isInvoicedAmountGreaterThanReceived
                  && <Tooltip title="Invoiced amount is greater than received amount">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>}
              </TdNew>;
            })}
          </Tr>)}
      </Tbody>
    </TableNew>
  );
};

export default AssignNominalCodesSummary;
