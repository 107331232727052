import React from 'react';
import Button from '../../../../../core/components/button/Button';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { usersListFiltersSelector, usersListSelector } from '../../../../../store/selectors/settingsSelectors';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { setListFilters } from '../../../../../store/slices/settingsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import DeleteLinkedUserModal from './DeleteLinkedUserModal';
import { useUsersListInteractions } from '../../../hooks/useUsersListInteractions';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';

type UsersListActionsProps = {
  checkedKeys: number[],
  uncheckAll: VoidFunctionType,
}

const UsersListActions: React.FC<UsersListActionsProps> = ({ checkedKeys, uncheckAll }) => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector(usersListSelector);
  const usersListFilters = useAppSelector(usersListFiltersSelector);

  const {
    handleDeleteLinkedUser,
    deleteUsersHandler,
    inviteUsersHandler,
    resetUsersPasswordsHandler,
    actions,
    isInvite,
    setInvite,
    isReset,
    setReset,
    isDelete,
    setDelete,
    isDeleteLinkedUser,
    setIsDeleteLinkedUser,
    numberOfInviteEligibleUsers,
    numberOfResetEligibleUsers,
  } = useUsersListInteractions(checkedKeys, uncheckAll);

  const inviteText = `You are about to send email invitations to ${numberOfInviteEligibleUsers} user(s).`;
  const resetText = `You are about to reset password of ${numberOfResetEligibleUsers} user(s).`;
  return (
    <>
      <ConfirmationModal
        isVisible={isInvite}
        onCancel={() => setInvite(false)}
        confirmHandler={inviteUsersHandler}
        customTitle="Send invitation"
        customText={inviteText}
        customCreateLabel="Yes, send"
      />
      <ConfirmationModal
        isVisible={isReset}
        onCancel={() => setReset(false)}
        confirmHandler={resetUsersPasswordsHandler}
        customTitle="Reset password"
        customText={resetText}
        customCreateLabel="Yes, reset"
      />
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => setDelete(false)}
        confirmHandler={deleteUsersHandler}
      />
      <DeleteLinkedUserModal
        isVisible={isDeleteLinkedUser}
        onCancel={() => setIsDeleteLinkedUser(false)}
        deleteUserClick={(deleteLinkedAccount) => handleDeleteLinkedUser(deleteLinkedAccount)}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          {actions.map((act, i) => act.renderIf && <Button
            key={i}
            label={`${act.label} ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
            disabled={act.disabled}
            onClick={act.onClick}
            htmlType="button"
          />)}
        </div>
        {totalCount > 0 && <Pagination
          currentPage={usersListFilters.page}
          pageSize={usersListFilters.pageSize}
          onChange={(page, pageSize) => dispatch(setListFilters({ ...usersListFilters, page, pageSize }))}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
          className="actions-and-paging__paging"
        />}
      </div>
    </>
  );
};

export default UsersListActions;
