import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../core/components/table-components/Tr';
import Restricted from '../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import { PricelistTablePartType } from '../../../../dashboard/types/pricelistTypes';
import PricePartCell from './PricePartCell';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';

type PricesPartsBodyProps = {
  parts: PricelistTablePartType[],
  checkedKeys: number[],
  setEditModal: NumberFunctionType,
  getAffectedHandle: (dataToAffect: { partIds: number[] }) => void,
  setItemToDelete: NumberFunctionType,
  checkRow: NumberFunctionType,
}

const PricesPartsBody: React.FC<PricesPartsBodyProps> = ({
  parts, checkedKeys, checkRow,
  setEditModal, getAffectedHandle, setItemToDelete,
}) => {
  const allowedItems = (id: number) => {
    return [
      {
        label: 'Edit part price',
        key: 'edit-part-price',
        icon: <FontAwesomeIcon icon={faEdit} />,
        onClick: () => setEditModal(id),
      },
      {
        label: 'Delete part price',
        key: 'delete-part-price',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => {
          setItemToDelete(id);
          getAffectedHandle({ partIds: [id] });
        },
      },
    ];
  };

  return (
    <Tbody>
      {parts.length === 0
        ? <EmptyTableSection text="No records" colSpan={9} />
        : <>
          {parts.map((part, i) => <Tr key={i}>
            <Restricted to={PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions}>
              <TdNew>
                <Checkbox
                  checked={checkedKeys?.includes(part.id)}
                  onChange={() => checkRow(part.id)}
                  table
                />
              </TdNew>
            </Restricted>
            <PricePartCell part={part} setEditModal={() => setEditModal(part.id)} />
            <Restricted to={PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions}>
              <TdNew action>
                <Dropdown
                  icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                  menuItems={allowedItems(part.id)}
                />
              </TdNew>
            </Restricted>
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default PricesPartsBody;
