import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../core/components/button/Button';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import { DraftSkillLine } from '../../../common/types/commonTypes';
import SkillsListSection from '../../../common/components/skills-list-section/SkillsListSection';

type EmployeeSkillListSectionProps = {
  setSkillsModal: BooleanFunctionType,
  skills: DraftSkillLine[]
}

const EmployeeSkillListSection: React.FC<EmployeeSkillListSectionProps> = ({ setSkillsModal, skills }) => {
  return (
    <section className="details-form">
      <h2 className="info-grid__title">Skills</h2>
      <SkillsListSection
        totalElements={skills.length}
        skills={skills}
      />
      <Button
        label="Select skills and levels"
        icon={<FontAwesomeIcon icon={faEdit} />}
        onClick={() => setSkillsModal(true)}
        className="details-form__field--justify-start"
      />
    </section>
  );
};

export default EmployeeSkillListSection;
