import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  bulkDeleteComponentsThunk,
  deleteComponentThunk,
} from '../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { AddComponentModeEnum } from '../enums/PartsKitsViewPageEnums';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useSelectAll } from '../../../../core/hooks/useSelectAll';
import { partsKitComponentsSelector } from '../../../../store/selectors/partsKitSelectors';

export const usePartsKitAffected = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { allItemIds } = useAppSelector(partsKitComponentsSelector);

  const [checkedAddKeys, setCheckedAddKeys] = useState<number[]>([]);
  const [isDelete, setIsDelete] = useState(false);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [isAddWithoutPrice, setIsAddWithoutPrice] = useState<boolean>(false);
  const [currentMode, setCurrentMode] = useState<AddComponentModeEnum>(AddComponentModeEnum.CORE);
  const [itemToChange, setItemToChange] = useState<number | undefined>(undefined);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const confirmDelete = () => {
    if (itemToChange) {
      id && dispatch(deleteComponentThunk({
        id: itemToChange,
        partsKitId: +id,
        closeModal: () => {
          isDelete && setIsDelete(false);
          setItemToChange(undefined);
        },
      }));
    } else {
      id && dispatch(bulkDeleteComponentsThunk({
        componentIds: checkedKeys,
        partsKitId: +id,
        closeModal: () => {
          isDelete && setIsDelete(false);
        },
      }));
    }
  };

  const onCancelAddModal = () => {
    setIsAdd(false);
    setCurrentMode(AddComponentModeEnum.CORE);
    itemToChange && setItemToChange(undefined);
    setCheckedAddKeys([]);
  };

  return {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
    checkedAddKeys,
    setCheckedAddKeys,
    isDelete,
    setIsDelete,
    isAdd,
    setIsAdd,
    isAddWithoutPrice,
    setIsAddWithoutPrice,
    currentMode,
    setCurrentMode,
    itemToChange,
    setItemToChange,
    confirmDelete,
    onCancelAddModal,
  };
};
