// eslint-disable-next-line
export enum routesPath {
  ACCOUNT = 'account',
  ENQUIRIES = 'enquiries',
  ENQUIRY_SEARCH = 'enquiry-search',
  ENQUIRY_SPECIFICATION = 'enquiry-specification',
  CREATE_ENQUIRY = 'create-enquiry',
  ORDERS = 'orders',
  RECTIFICATIONS = 'rectifications',
  JOBS = 'jobs',
  LOGIN = 'login',
  VEHICLES = 'vehicles',
  FULL_VEHICLE_CHECK = 'full-vehicle-check',
  HANDOVER_VEHICLE_CHECK = 'handover-vehicle-check',
  WEIGHT_VEHICLE_CHECK = 'weight-vehicle-check',
  CREATE_VEHICLE = 'create-vehicle',
  STOCK = 'stock',
  PARTS_KITS='parts-kits',
  PRICELISTS = 'pricelists',
  CREATE_PRICELIST = 'create-pricelist',
  CUSTOMERS = 'customers',
  CREATE_CUSTOMER = 'create-customer',
  SUPPLIERS = 'suppliers',
  CREATE_SUPPLIERS = 'create-supplier',
  INVOICING = 'invoicing',
  EMPLOYEES = 'employees',
  CREATE_EMPLOYEE = 'create-employee',
  REPORTS = 'reports',
  SETTINGS = 'settings',
  USER_PROFILE = 'settings/user',
  CREATE_USER = 'create-user',
  ACCOUNT_FINALISATION = 'account-finalisation',
  HOME = 'greetings',
  CREATE_PART = 'create-part',
  CREATE_PARTS_KIT = 'create-parts-kit',
  PURCHASE_ORDER = 'purchase-order',
  CREATE_PURCHASE_ORDER = 'create-purchase-order',
  FORGOT_PASSWORD = 'forgot-password',
  RESET_PASSWORD = 'reset-password',
  CREATE_JOB = 'create-job',
  CREATE_RECTIFICATION = 'create-rectification',
  FORMS = 'forms',
}
