import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { setAuthToken, setIsAuth } from '../../store/slices/coreSlice';
import { storage } from '../../common/utils/storage';

export const removeTokenHandler = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  storage.removeAuthToken();
  dispatch(setIsAuth(false));
  dispatch(setAuthToken(null));
};

export const addTokenHandler = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, authToken: string) => {
  storage.addAuthToken(authToken);
  dispatch(setAuthToken(authToken));
};
