import React from 'react';
import Thead from '../../../../../../../core/components/table-components/Thead';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import ThWithControls from '../../../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import Tr from '../../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import Radio from '../../../../../../../core/components/radio/Radio';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  partSelectPricelistResultTableSelector,
  selectPricelistColumnsSelector,
} from '../../../../../../../store/selectors/stockSelectors';
import { PartPricesFilters } from '../../../../../../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import { getPartPricesThunk } from '../../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import { setSelectPricelistAndQtyFilters } from '../../../../../../../store/slices/stockSlice';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { purchaseOrderDetailsSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { OrderingType } from '../../../../../../../core/types/coreTypes';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';

type SelectPricelistTableProps = {
  selectPricelistAndQtyFilters?: PartPricesFilters,
  selectedPartId?: number,
  setSelectedPricelistId: (value?: number) => void,
  selectedPricelistId?: number,
}

const SelectPricelistTable: React.FC<SelectPricelistTableProps> = ({
  selectPricelistAndQtyFilters, selectedPartId, selectedPricelistId, setSelectedPricelistId,
}) => {
  const dispatch = useAppDispatch();
  const selectPricelistColumns = useAppSelector(selectPricelistColumnsSelector);
  const partSelectPricelistResultTable = useAppSelector(partSelectPricelistResultTableSelector);
  const purchaseOrderDetails = useAppSelector(purchaseOrderDetailsSelector);

  const setSortValue = (newOrder: OrderingType) => {
    const newFilters = {
      ...selectPricelistAndQtyFilters,
      priceDate: purchaseOrderDetails?.purchaseOrder.purchaseOrderDate,
      supplierId: purchaseOrderDetails?.purchaseOrder.supplierId,
      activeOnly: true,
      order: { ...newOrder },
    };
    dispatch(setSelectPricelistAndQtyFilters(newFilters));
    selectedPartId && dispatch(getPartPricesThunk({
      pricelistId: undefined,
      partId: selectedPartId,
      filters: newFilters,
    }));
  };

  const sortHandle = (field: number) => {
    if (selectPricelistAndQtyFilters?.order?.field === field) {
      const newV = { ...selectPricelistAndQtyFilters.order, isAsc: !selectPricelistAndQtyFilters.order?.isAsc };
      setSortValue(newV);
    } else {
      const newV = { field, isAsc: true };
      setSortValue(newV);
    }
  };
  return (
    <TableNew className="partPricelistWrap__table">
      <Thead>
        <Tr>
          <ThNew checkbox><span className="sr-only">Select items</span></ThNew>
          {selectPricelistColumns.map((col) => <ThWithControls
            key={col.dataIndex}
            col={col}
            sortHandle={sortHandle}
            tableFilters={selectPricelistAndQtyFilters}
            totalElements={partSelectPricelistResultTable.totalCount}
            disabled={partSelectPricelistResultTable.totalCount === 0}
            className={`selectPricelistTableTh--${col.dataIndex}`}
          />)}
        </Tr>
      </Thead>
      <Tbody>
        {partSelectPricelistResultTable.totalCount === 0
          ? <EmptyTableSection text="No records" colSpan={selectPricelistColumns.length + 1} />
          : partSelectPricelistResultTable.items.map((el, i) => <Tr key={i}>
            <>
              <TdNew>
                <Radio
                  checked={el.id === selectedPricelistId}
                  value={el.id || ''}
                  onChange={(v) => setSelectedPricelistId(v as number)}
                />
              </TdNew>
              {Object.entries(el).map(([key, value]) => {
                if (key === 'id') return null;
                return <TdNew key={key}>
                  {key === 'pricelistName' ? <TruncateTooltip value={value} table /> : value}
                </TdNew>;
              })}
            </>
          </Tr>)}
      </Tbody>
    </TableNew>
  );
};

export default SelectPricelistTable;
