import React, { useEffect, useState } from 'react';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppSelector } from '../../../store/hooks';
import { customersListSelector } from '../../../store/selectors/customersSelectors';
import { customerStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { CustomersStatusesEnum } from '../../../core/enums/dictionariesEnums';

export const useCustomerPricelistLookup = () => {
  const customersList = useAppSelector(customersListSelector);
  const customerStatuses = useAppSelector(customerStatusesSelector);

  const [customersLookup, setCustomersLookup] = useState<{ label: React.ReactNode; value: number; }[]>([]);
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [searchError, setSearchError] = useState<string>('');

  const statusMatchIconFn = (status: number) => {
    const statusMatch = customerStatuses.find((el) => el.value === status)?.label;
    return statusMatch === CustomersStatusesEnum.Complete ? faClipboardCheck : faQuestionCircle;
  };
  useEffect(() => {
    const arr = customersList.items.map((item) => ({
      label: <>
        <FontAwesomeIcon icon={statusMatchIconFn(item.customerStatus)} />
        {' '}
        {item.customerAccountNumber}
        {' '}
        {item.name}
      </>,
      value: item.id,
    }));
    setCustomersLookup(arr);
    // eslint-disable-next-line
    }, [customersList, customerStatuses]);

  return {
    customersLookup,
    searchValue,
    setSearchValue,
    searchError,
    setSearchError,
  };
};
