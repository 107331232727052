import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetRectificationResponseType } from '../../rectifications/dashboard/types/rectificationsTypes';
import { fetchRectificationByIdThunk } from '../thunks/rectification/rectificationViewPageThunks';
import {
  AddressSuggestionDto,
  CustomerDictionaryDto,
  DataListType,
  GetAddressSuggestionsResponse,
  initDataList, initSmallPaging,
  ResponseResult,
  ResponseSingleResult,
  SupplierDictionaryDto,
} from '../../core/types/coreTypes';
import { GetOrderResponse, OrderDetails } from '../../orders/dashboard/types/ordersTypes';
import { getOrderById } from '../thunks/orders/viewPageOrderThunks';
import {
  FetchRectificationsDictionaryParamsType, initSourceTargetProfilesFilters, initVehProfilesList,
  OrderDictionaryDto,
  OrderDictionaryFiltersType, PartProductDictionaryType,
  PartProductTotalType, PartRequestLineChangeGroupLineDto, RectificationDictionaryDto,
  RequisiteDto,
} from '../../common/types/commonTypes';
import {
  getOrderDictionary,
  getRectificationsDictionary,
} from '../thunks/coreThunk';
import {
  getPartCategoriesThunk,
  getPartSubcategoriesThunk,
  getPartSubcategoriesFilteredThunk,
  getCustomersDictionaryThunk,
  getSuitableVehicleProfiles,
  getVehicleProfilesManufacturers,
  getVehicleProfileGroupsThunk,
  getSuppliersDictionaryThunk,
  getPartProductSourceDictionaryThunk,
  getPartProductSourceTotalThunk,
  getPurchaseOrderViewsDictionaryThunk,
  getReadyCollectedPartQuantities,
  getCustomerBillingShippingRequisiteThunk,
  getSupplierBillingShippingRequisiteThunk,
  getSourceAvailableVehicleProfilesThunk,
  getTargetAvailableVehicleProfilesThunk,
  getAddressSuggestions,
} from '../thunks/shared/sharedThunks';
import { PartCategoryDto, PartSubcategoryDto } from '../../stock/part-categories/types/partCategoriesTypes';
import {
  AvailableVehicleProfilesData,
  GetVehicleProfilesResponse, initAvailableVehicleProfilesData, VehicleProfileGroupDto,
  VehicleProfilesDataList, VehicleProfilesFilters,
} from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import {
  GetPurchaseOrderDictionaryResponse,
  PurchaseOrderDictionaryDto,
} from '../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { GetPartQuantitiesDataType, GetPartQuantitiesParams } from '../../stock/part-view-page/types/types';
import { initRequisite } from '../../common/utils/data';
import { getPartRequestLineChangeGroupsThunk } from '../thunks/stock/stock-part-requests/stockPartRequestsThunks';

interface ISharedState {
  orderDetails: OrderDetails | null,
  rectificationDetails: GetRectificationResponseType | null,
  orderDictionaryFilters: OrderDictionaryFiltersType | undefined,
  ordersDictionary: OrderDictionaryDto[],
  rectificationDictionaryFilters: FetchRectificationsDictionaryParamsType | undefined,
  rectificationsDictionary: RectificationDictionaryDto[],
  sourceAvailableVehicleProfilesFilters: VehicleProfilesFilters,
  sourceAvailableVehicleProfiles: AvailableVehicleProfilesData,
  targetAvailableVehicleProfilesFilters: VehicleProfilesFilters,
  targetAvailableVehicleProfiles: AvailableVehicleProfilesData,
  partCategories: DataListType<PartCategoryDto[]>,
  partSubcategories: DataListType<PartSubcategoryDto[]>,
  partSubcategoriesFiltered: DataListType<PartSubcategoryDto[]>,
  customersDictionary: CustomerDictionaryDto[],
  suitableVehiclesProfiles: VehicleProfilesDataList,
  vehiclesProfilesManufacturers: string[],
  vehicleProfilesGroups: DataListType<VehicleProfileGroupDto[]>,
  vehicleFilteredProfilesGroups: DataListType<VehicleProfileGroupDto[]>,
  supplierDictionary: SupplierDictionaryDto[],
  productSourceDictionary: PartProductDictionaryType | null,
  productSourceTotal: PartProductTotalType | null,
  productSourceTotalInitCopy: PartProductTotalType | null,
  purchaseOrderViews: PurchaseOrderDictionaryDto[],
  readyCollectedPartQuantities: GetPartQuantitiesDataType | null,
  readyCollectedPartQuantitiesFilters: GetPartQuantitiesParams,
  requisiteData: RequisiteDto,
  addressSuggestions: AddressSuggestionDto[],
  partRequestChanges: PartRequestLineChangeGroupLineDto[],
}

export const initialSharedState: ISharedState = {
  orderDetails: null,
  rectificationDetails: null,
  orderDictionaryFilters: undefined,
  ordersDictionary: [],
  rectificationDictionaryFilters: undefined,
  rectificationsDictionary: [],
  sourceAvailableVehicleProfilesFilters: initSourceTargetProfilesFilters,
  targetAvailableVehicleProfilesFilters: initSourceTargetProfilesFilters,
  sourceAvailableVehicleProfiles: initAvailableVehicleProfilesData,
  targetAvailableVehicleProfiles: initAvailableVehicleProfilesData,
  partCategories: initDataList,
  partSubcategories: initDataList,
  partSubcategoriesFiltered: initDataList,
  customersDictionary: [],
  suitableVehiclesProfiles: initVehProfilesList,
  vehiclesProfilesManufacturers: [],
  vehicleProfilesGroups: initDataList,
  vehicleFilteredProfilesGroups: initDataList,
  supplierDictionary: [],
  productSourceDictionary: null,
  productSourceTotal: null,
  productSourceTotalInitCopy: null,
  purchaseOrderViews: [],
  readyCollectedPartQuantities: null,
  readyCollectedPartQuantitiesFilters: initSmallPaging,
  requisiteData: initRequisite,
  addressSuggestions: [],
  partRequestChanges: [],
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState: initialSharedState,
  reducers: {
    setRectificationDetails: (state, action: PayloadAction<GetRectificationResponseType | null>) => {
      state.rectificationDetails = action.payload;
    },
    serOrderDetails: (state, action: PayloadAction<OrderDetails | null>) => {
      state.orderDetails = action.payload;
    },
    setOrderDictionaryFilters: (state, action: PayloadAction<OrderDictionaryFiltersType | undefined>) => {
      state.orderDictionaryFilters = action.payload;
    },
    setOrdersDictionary: (state, action: PayloadAction<OrderDictionaryDto[]>) => {
      state.ordersDictionary = action.payload;
    },
    setRectificationDictionaryFilters: (state, action: PayloadAction<FetchRectificationsDictionaryParamsType | undefined>) => {
      state.rectificationDictionaryFilters = action.payload;
    },
    setRectificationDictionary: (state, action: PayloadAction<RectificationDictionaryDto[]>) => {
      state.rectificationsDictionary = action.payload;
    },
    setSourceAvailableVehiclesProfilesFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.sourceAvailableVehicleProfilesFilters = action.payload;
    },
    setTargetAvailableVehiclesProfilesFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.targetAvailableVehicleProfilesFilters = action.payload;
    },
    setSourceAvailableVehicleProfiles: (state, action: PayloadAction<AvailableVehicleProfilesData>) => {
      state.sourceAvailableVehicleProfiles = action.payload;
    },
    setTargetAvailableVehicleProfiles: (state, action: PayloadAction<AvailableVehicleProfilesData>) => {
      state.targetAvailableVehicleProfiles = action.payload;
    },
    setPartCategories: (state, action: PayloadAction<DataListType<PartCategoryDto[]>>) => {
      state.partCategories = action.payload;
    },
    setSuitableVehicleProfiles: (state, action: PayloadAction<VehicleProfilesDataList>) => {
      state.suitableVehiclesProfiles = action.payload;
    },
    setVehicleProfilesManufacturers: (state, action: PayloadAction<string[]>) => {
      state.vehiclesProfilesManufacturers = action.payload;
    },
    setProductSourceDictionary: (state, action: PayloadAction<PartProductDictionaryType | null>) => {
      state.productSourceDictionary = action.payload;
    },
    setProductSourceTotal: (state, action: PayloadAction<PartProductTotalType | null>) => {
      state.productSourceTotal = action.payload;
    },
    setProductSourceTotalInitCopy: (state, action: PayloadAction<PartProductTotalType | null>) => {
      state.productSourceTotalInitCopy = action.payload;
    },
    setRequisiteData: (state, action: PayloadAction<RequisiteDto>) => {
      state.requisiteData = action.payload;
    },
    setPurchaseOrderViews: (state, action: PayloadAction<PurchaseOrderDictionaryDto[]>) => {
      state.purchaseOrderViews = action.payload;
    },
    setReadyCollectedPartQuantitiesFilters: (state, action: PayloadAction<GetPartQuantitiesParams>) => {
      state.readyCollectedPartQuantitiesFilters = action.payload;
    },
    setReadyCollectedPartQuantities: (state, action: PayloadAction<GetPartQuantitiesDataType | null>) => {
      state.readyCollectedPartQuantities = action.payload;
    },
    setAddressSuggestions: (state, action: PayloadAction<AddressSuggestionDto[]>) => {
      state.addressSuggestions = action.payload;
    },
    setPartRequestChanges: (state, action: PayloadAction<PartRequestLineChangeGroupLineDto[]>) => {
      state.partRequestChanges = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchRectificationByIdThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetRectificationResponseType> | null>,
    ) => {
      state.rectificationDetails = action.payload?.data || null;
    });
    builder.addCase(
      getOrderById.fulfilled,
      (state, action: PayloadAction<GetOrderResponse>) => {
        state.orderDetails = action.payload.data;
      },
    );
    builder.addCase(getOrderDictionary.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ orders: OrderDictionaryDto[] }> | null>,
    ) => {
      state.ordersDictionary = action.payload?.data?.orders || [];
    });
    builder.addCase(getRectificationsDictionary.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ rectifications: RectificationDictionaryDto[] }> | null>,
    ) => {
      state.rectificationsDictionary = action.payload?.data?.rectifications || [];
    });
    builder.addCase(
      getSourceAvailableVehicleProfilesThunk.fulfilled,
      (state, action: PayloadAction<GetVehicleProfilesResponse>) => {
        state.sourceAvailableVehicleProfiles = action.payload.data;
      },
    );
    builder.addCase(
      getTargetAvailableVehicleProfilesThunk.fulfilled,
      (state, action: PayloadAction<GetVehicleProfilesResponse>) => {
        state.targetAvailableVehicleProfiles = action.payload.data;
      },
    );
    builder.addCase(
      getPartCategoriesThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartCategoryDto[]>>) => {
        state.partCategories = action.payload.data;
      },
    );
    builder.addCase(
      getPartSubcategoriesThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartSubcategoryDto[]>>) => {
        const { data } = action.payload;
        state.partSubcategories = data;
        state.partSubcategoriesFiltered = data;
      },
    );
    builder.addCase(
      getPartSubcategoriesFilteredThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartSubcategoryDto[]>>) => {
        state.partSubcategoriesFiltered = action.payload.data;
      },
    );
    builder.addCase(getCustomersDictionaryThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ customers: CustomerDictionaryDto[] | null }>>,
    ) => {
      state.customersDictionary = action.payload.data?.customers || [];
    });
    builder.addCase(getSuppliersDictionaryThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ suppliers: SupplierDictionaryDto[] }>>,
    ) => {
      state.supplierDictionary = action.payload.data.suppliers;
    });
    builder.addCase(
      getSuitableVehicleProfiles.fulfilled,
      (state, action: PayloadAction<GetVehicleProfilesResponse | null>) => {
        state.suitableVehiclesProfiles = action.payload?.data || initVehProfilesList;
      },
    );
    builder.addCase(
      getVehicleProfilesManufacturers.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ manufacturers: string[] }> | null>) => {
        state.vehiclesProfilesManufacturers = action.payload?.data?.manufacturers || [];
      },
    );
    builder.addCase(getVehicleProfileGroupsThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<VehicleProfileGroupDto[]> | null>,
    ) => {
      if (state.vehicleProfilesGroups.totalCount === 0) {
        state.vehicleProfilesGroups = action.payload?.data || initDataList;
      }
      state.vehicleFilteredProfilesGroups = action.payload?.data || initDataList;
    });
    builder.addCase(
      getPartProductSourceDictionaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<PartProductDictionaryType>>) => {
        state.productSourceDictionary = action.payload.data;
      },
    );
    builder.addCase(
      getPartProductSourceTotalThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<PartProductTotalType>>) => {
        state.productSourceTotal = action.payload.data;
        if (!state.productSourceTotalInitCopy) {
          state.productSourceTotalInitCopy = action.payload.data;
        }
      },
    );
    builder.addCase(
      getCustomerBillingShippingRequisiteThunk.fulfilled,
      (state, action: PayloadAction<RequisiteDto | null>) => {
        state.requisiteData = action.payload || initRequisite;
      },
    );
    builder.addCase(
      getSupplierBillingShippingRequisiteThunk.fulfilled,
      (state, action: PayloadAction<RequisiteDto>) => {
        state.requisiteData = action.payload;
      },
    );
    builder.addCase(
      getPurchaseOrderViewsDictionaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPurchaseOrderDictionaryResponse>>) => {
        state.purchaseOrderViews = action.payload.data.purchaseOrders;
      },
    );
    builder.addCase(
      getReadyCollectedPartQuantities.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartQuantitiesDataType>>) => {
        state.readyCollectedPartQuantities = action.payload.data;
      },
    );
    builder.addCase(
      getAddressSuggestions.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetAddressSuggestionsResponse>>) => {
        state.addressSuggestions = action.payload.data.suggestions;
      },
    );
    builder.addCase(
      getPartRequestLineChangeGroupsThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ lines: PartRequestLineChangeGroupLineDto[] }>>) => {
        state.partRequestChanges = action.payload.data.lines;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setRectificationDetails,
  serOrderDetails,
  setOrderDictionaryFilters,
  setOrdersDictionary,
  setRectificationDictionaryFilters,
  setRectificationDictionary,
  setSourceAvailableVehiclesProfilesFilters,
  setTargetAvailableVehiclesProfilesFilters,
  setSourceAvailableVehicleProfiles,
  setTargetAvailableVehicleProfiles,
  setPartCategories,
  setSuitableVehicleProfiles,
  setProductSourceDictionary,
  setProductSourceTotal,
  setProductSourceTotalInitCopy,
  setPurchaseOrderViews,
  setReadyCollectedPartQuantitiesFilters,
  setReadyCollectedPartQuantities,
  setRequisiteData,
  setAddressSuggestions,
  setPartRequestChanges,
} = sharedSlice.actions;

export default sharedSlice.reducer;
