import React, { useEffect, useState } from 'react';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import Input from '../../../../core/components/input/Input';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { stockPartSubcategoriesSelector } from '../../../../store/selectors/stockSelectors';
import {
  createPartSubcategoryThunk,
  editPartSubcategoryThunk,
} from '../../../../store/thunks/stock/categories/partCategoriesThunks';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';

type CreateEditPartSubcategoryModalProps = {
  visible: boolean,
  categoryId: number | undefined,
  onCancel: VoidFunctionType,
  editMode: boolean,
  subcategoryIdToEdit: number | null,
}
const CreateEditPartSubcategoryModal: React.FC<CreateEditPartSubcategoryModalProps> = ({
  visible, categoryId, onCancel, editMode, subcategoryIdToEdit,
}) => {
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(stockPartSubcategoriesSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const [name, setName] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    if (subcategoryIdToEdit) {
      const name = items.find((el) => el.id === subcategoryIdToEdit)?.name || '';
      setName(name);
    }
    // eslint-disable-next-line
  }, [subcategoryIdToEdit]);

  const cancelHandle = () => {
    setName('');
    setError('');
    onCancel();
  };
  const createHandle = () => {
    if (!error) {
      if (name.trim().length === 0) {
        setError(ErrorsEnum.REQUIRED);
      } else {
        editMode
          ? subcategoryIdToEdit && dispatch(editPartSubcategoryThunk({
            id: subcategoryIdToEdit,
            name,
            onClose: cancelHandle,
            setError,
          }))
          : categoryId && dispatch(createPartSubcategoryThunk({
            categoryId,
            name,
            onClose: cancelHandle,
            setError,
          }));
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title={`${editMode ? 'Edit' : 'Add'} subcategory`}
    >
      <Input
        label="Name"
        value={name}
        onChange={(e) => {
          const { value } = e.target;
          if (value.length === 0) {
            setError(ErrorsEnum.REQUIRED);
          } else if (value.length > 100) {
            setError(maxLengthMessageHandle(100));
          } else {
            error && setError('');
          }
          setName(value);
        }}
        error={error}
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel={editMode ? 'Save' : 'Add'}
        cancelClick={cancelHandle}
        createClick={createHandle}
        isLoading={isFetching}
        offsetTop
      />
    </Modal>
  );
};

export default CreateEditPartSubcategoryModal;
