import React from 'react';
import { InvoicingVewPageContentEnum } from '../enums/InvoicingVewPageContentEnum';
import InvoicingOrderLinesContent from './InvoicingOrderLinesContent';
import InvoicesAndCreditNotesContent from '../../common/components/invoices-and-credit-notes/InvoicesAndCreditNotesContent';

type InvoicingViewDynamicSectionProps = {
  mode: string | null,
}

const InvoicingViewDynamicSection: React.FC<InvoicingViewDynamicSectionProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case InvoicingVewPageContentEnum.ORDER_LINES:
        return <InvoicingOrderLinesContent />;
      case InvoicingVewPageContentEnum.INVOICES_AND_CREDIT_NOTES:
        return <InvoicesAndCreditNotesContent />;
      default: return null;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default InvoicingViewDynamicSection;
