import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided, DropResult,
} from 'react-beautiful-dnd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import {
  BooleanFunctionType, DictionaryItem, GenericColumnType,
} from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Switch from '../../../core/components/switch/Switch';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import './ColumnsConfigurationModal.scss';

type ColumnsConfigurationModalProps<T> = {
  isVisible: boolean,
  setIsVisible: BooleanFunctionType,
  statusOptions: DictionaryItem[],
  collections: GenericColumnType<T>[] | null,
  saveHandle: (layoutOptions: GenericColumnType<T>[] | null) => void,
  title?: string,
}

const ColumnsConfigurationModal = <T, >({
  isVisible,
  setIsVisible,
  statusOptions,
  collections,
  saveHandle,
  title = 'Choose column visibility and order',
}: ColumnsConfigurationModalProps<T>): JSX.Element => {
  const [layoutOptions, setLayoutOptions] = useState<GenericColumnType<T>[] | null>(null);

  useEffect(() => {
    collections && setLayoutOptions(collections);
  }, [collections]);

  const checkHandle = (status: number, checked: boolean) => {
    const newOptions = layoutOptions?.map((el) => {
      if (el.status === status) {
        return {
          ...el, isHidden: !checked,
        };
      } else return el;
    });
    newOptions && setLayoutOptions(newOptions);
  };
  const cancelHandle = () => {
    setIsVisible(false);
    setLayoutOptions(collections);
  };

  const onBeforeDragStart = () => {
    const statuses = document.querySelector('.statuses') as HTMLElement;
    statuses.style.height = `${statuses?.scrollHeight}px`;
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const resArr = layoutOptions ? Array.from(layoutOptions) : [];
      const [removed] = resArr.splice(result.source.index, 1);
      resArr.splice(result.destination.index, 0, removed);

      const arrD = resArr.map((el, i) => ({
        ...el,
        order: i + 1,
      }));
      setLayoutOptions(arrD);
    }
  };

  const visibleCols = layoutOptions?.filter((opt) => !opt.isHidden);
  const labelHandle = (status: number) => {
    return statusOptions.find((el) => el.value === status)?.label || '';
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={title}
    >
      <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId="droppableId">
          {(provided: DroppableProvided) => {
            return (
              <div
                className="statuses"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {layoutOptions?.map((st, i) => <Draggable draggableId={st.status.toString()} index={i} key={st.status}>
                  {(provided) => (
                    <div
                      key={st.status}
                      className="statuses-item"
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <div className="statuses-item__left">
                        <FontAwesomeIcon icon={faBars} className="listIcon" />
                        <span className="statuses-item__value">{labelHandle(st.status)}</span>
                      </div>
                      <Switch
                        checked={!st.isHidden}
                        onChange={(checked) => checkHandle(st.status, checked)}
                        disabled={visibleCols?.length === 1 && visibleCols[0].status === st.status}
                        className="statuses-item__switch"
                      />
                    </div>)}
                </Draggable>)}
              </div>
            );
          }}
        </Droppable>
      </DragDropContext>
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Save"
        cancelClick={cancelHandle}
        createClick={() => saveHandle(layoutOptions)}
      />
    </Modal>
  );
};

export default ColumnsConfigurationModal;
