import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import CategoryModal from '../../common/components/category-modal/CategoryModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { stockPartTypesSelector } from '../../../store/selectors/stockSelectors';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import Select from '../../../core/components/select/Select';
import { labelHandle } from '../../../core/utils/labelHandle';
import CategorySection from '../../common/components/category-section/CategorySection';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../core/utils/regex';
import {
  allowedShortBaForUserSelector,
  QuoteUnitsOfMeasureSelector,
  weightlessMeasureValuesSelector,
} from '../../../store/selectors/coreSelectors';
import StockVehicles from '../../common/components/stock-vehicles/StockVehicles';
import Button from '../../../core/components/button/Button';
import { createPartThunk } from '../../../store/thunks/stock/part/partsCreateThunks';
import { CreatePartReqType } from '../types/createPartTypes';
import {
  partCategoriesWithoutEmptyLookupSelector,
  partSubcategoriesLookupSelector,
} from '../../../store/selectors/sharedSelectors';
import SelectVehiclesModal from '../../common/components/select-vehicles-modal/SelectVehiclesModal';
import { useCreatePartSuitableVehicles } from '../../common/hooks/useCreatePartSuitableVehicles';
import {
  PartCodeSchema, partDefaults, PartFields, PartNameSchema,
} from '../utils/PartSchema';
import { YesNoOptions } from '../../../core/utils/testData';

const PartForm:React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const categoriesList = useAppSelector(partCategoriesWithoutEmptyLookupSelector);
  const partTypes = useAppSelector(stockPartTypesSelector);
  const allowedShortBaForUser = useAppSelector(allowedShortBaForUserSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const initialSubcategoriesList = useAppSelector(partSubcategoriesLookupSelector);
  const weightlessMeasures = useAppSelector(weightlessMeasureValuesSelector);

  const {
    control, handleSubmit, reset, setError, setValue, getValues, clearErrors, watch,
  } = useForm<PartFields>({
    defaultValues: partDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const {
    isSelectVehicles,
    setSelectVehicles,
    selectedSource,
    selectedIds,
    selectedGroupIds,
    resetSuitableVehicles,
    handleSave,
    groups,
  } = useCreatePartSuitableVehicles();

  const unitOfMeasure = watch('unitOfMeasure');
  const disabledWeight = unitOfMeasure && weightlessMeasures.includes(unitOfMeasure);

  const setDefaultValues = () => {
    if (unitsOfMeasure.length > 0) {
      setValue('unitOfMeasure', unitsOfMeasure[0].value);
    }
    if (partTypes.length > 0) {
      setValue('type', partTypes[0].value);
    }
  };

  useEffect(() => {
    setDefaultValues();
    // eslint-disable-next-line
  }, [unitsOfMeasure, partTypes]);

  const handleEditCategory = (categoryId: number, subcategoryId: number) => {
    setValue('categoryId', categoryId);
    setValue('subcategoryId', subcategoryId);
    setIsCategoryVisible(false);
    clearErrors(['categoryId', 'subcategoryId']);
  };

  const setRequiredError = (name: keyof PartFields) => {
    setError(name, { type: 'required', message: ErrorsEnum.REQUIRED });
  };

  const cancel = () => {
    reset();
    clearErrors();
    setDefaultValues();
    resetSuitableVehicles();
  };

  const onSubmit = (values: PartFields) => {
    const data: CreatePartReqType = {
      name: values.name.trim(),
      partCode: values.partCode.trim(),
      manufacturerCode: values.manufacturerCode.trim(),
      partType: values.type as number,
      isCorePart: values.corePart === 1,
      unitOfMeasure: values.unitOfMeasure as number,
      subcategoryId: values.subcategoryId as number,
      weight: disabledWeight ? 0 : Number(values.weight),
      businessAreas: values.businessAreas,
      suitableVehiclesSource: selectedSource,
      suitableForVehicleIds: selectedSource === groups ? null : (selectedIds || []),
      suitableForVehicleGroupIds: selectedSource === groups ? (selectedGroupIds || []) : null,
    };
    dispatch(createPartThunk({
      data,
      navigate,
      setError,
      reset,
    }));
  };

  return (
    <>
      <SelectVehiclesModal
        isVisible={isSelectVehicles}
        onClose={() => setSelectVehicles(false)}
        initSelectedSource={selectedSource}
        initSelectedIds={selectedIds}
        initSelectedGroupIds={selectedGroupIds}
        saveChanges={handleSave}
      />
      <CategoryModal
        visible={isCategoryVisible}
        onCancel={() => setIsCategoryVisible(false)}
        title="Select category and subcategory"
        editHandle={handleEditCategory}
        categoryId={getValues().categoryId}
        subcategoryId={getValues().subcategoryId}
        categories={categoriesList}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="create-form details-form">
        <Controller
          control={control}
          name="name"
          rules={PartNameSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('name', '');
                  setRequiredError('name');
                }
              }}
              error={errors?.name?.message}
              label="Part name"
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          name="partCode"
          control={control}
          rules={PartCodeSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              label="Part code"
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setRequiredError('partCode');
                  setValue('partCode', '');
                }
              }}
              error={errors.partCode?.message}
            />
          )}
        />
        <Controller
          name="manufacturerCode"
          control={control}
          rules={PartCodeSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              label="Manufacturer code"
              onChange={(e) => {
                field.onChange(e.target.value);
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setRequiredError('manufacturerCode');
                  setValue('manufacturerCode', '');
                }
              }}
              error={errors.manufacturerCode?.message}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={partTypes}
              value={labelHandle(field.value, partTypes)}
              label="Type"
              onChange={(v) => field.onChange(v)}
              error={errors?.type?.message}
            />
          )}
        />
        <Controller
          name="unitOfMeasure"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={unitsOfMeasure}
              value={labelHandle(field.value, unitsOfMeasure)}
              label="Unit of measure"
              onChange={(v) => field.onChange(v)}
              error={errors?.unitOfMeasure?.message}
            />
          )}
        />
        <Controller
          name="categoryId"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <CategorySection
              label="Category"
              value={labelHandle(field.value, categoriesList)}
              setIsVisible={() => setIsCategoryVisible(true)}
              error={errors?.categoryId?.message}
            />
          )}
        />
        <Controller
          name="subcategoryId"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <CategorySection
              label="Subcategory"
              value={labelHandle(field.value, initialSubcategoriesList || [])}
              setIsVisible={() => setIsCategoryVisible(true)}
              error={errors?.subcategoryId?.message}
            />
          )}
        />
        {disabledWeight
          ? <Input
            label="Weight, kg"
            value="-"
            disabled
          />
          : <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <DecimalInputNumber
                label="Weight, kg"
                value={field.value}
                max={maxCommonDecimal}
                onChange={(e) => {
                  field.onChange(e.target.value.replace(',', '.'));
                }}
              />
            )}
          />}
        <Controller
          name="corePart"
          control={control}
          render={({ field }) => (
            <Select
              label="Core part"
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
              }}
              options={YesNoOptions}
            />
          )}
        />
        <Controller
          control={control}
          name="businessAreas"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Part business area"
              options={allowedShortBaForUser}
              className="details-form__field--lg"
              mode="multiple"
              showArrow
              error={errors?.businessAreas && ErrorsEnum.REQUIRED}
            />
          )}
        />
        <StockVehicles
          profileGroupIds={selectedGroupIds}
          profileIds={selectedIds}
        />
        <Button
          label="Select vehicles"
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => setSelectVehicles(true)}
          className="details-form__field--justify-start"
        />
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          cancelClick={cancel}
          className="details-form__actions"
        />
      </form>
    </>
  );
};

export default PartForm;
