import React from 'react';

type CreationEventProps = {
  entity: string,
  type: 'number' | 'name' | 'code' | string,
  value?: string,
}

const CreationEvent: React.FC<CreationEventProps> = ({ entity, type, value }) => {
  return (
    <>
      {`${entity} was created with ${type === 'number' ? 'No' : type} `}
      <b>{value}</b>
    </>
  );
};

export default CreationEvent;
