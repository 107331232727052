import React, { useState } from 'react';
import ContactEventModal from './ContactEventModal';
import Button from '../../../../core/components/button/Button';
import { ContactEvent } from '../../../types/commonTypes';

type AddContactEventProps = {
  contact?: ContactEvent,
}

const AddContactEvent: React.FC<AddContactEventProps> = ({ contact }) => {
  const { firstName = '', lastName = '' } = contact || {};
  const [isContactModal, setContactModal] = useState(false);
  return (
    <>
      <ContactEventModal
        isVisible={isContactModal}
        onCancel={() => setContactModal(false)}
        contact={contact}
      />
      <>
        Contact
        <b>{` ${firstName} ${lastName} `}</b>
        was added
        {' '}
        <Button
          label="(see details)"
          onClick={() => setContactModal(true)}
          type="text"
        />
      </>
    </>
  );
};

export default AddContactEvent;
