import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { RcFile, UploadProps as GenericUploadProps } from 'antd/lib/upload';
import { Upload as UploadComponent } from 'antd';
import classNames from 'classnames';
import Button from '../button/Button';
import './Upload.scss';

type UploadProps = {
  name?: string;
  label?: string;
  accept?: string;
  disabled?: boolean;
  multiple?: boolean;
  onChange?: GenericUploadProps['onChange'],
  showUploadList?: boolean;
  beforeUpload: (files: RcFile[]) => void;
  fileList?: RcFile[],
  icon?: React.ReactNode,
  className?: string;
}

const Upload: React.FC<UploadProps> = ({
  name = 'file',
  label,
  accept,
  disabled,
  multiple,
  showUploadList,
  onChange,
  beforeUpload,
  fileList,
  icon,
  className,
}) => {
  return (
    <UploadComponent
      name={name}
      accept={accept}
      onChange={onChange}
      showUploadList={showUploadList}
      fileList={fileList}
      disabled={disabled}
      multiple={multiple}
      beforeUpload={(_, newFiles) => {
        beforeUpload(newFiles);
        return false;
      }}
      className={classNames(className, 'upload')}
    >
      <Button htmlType="button" icon={icon || <PlusOutlined />} label={label} className="upload-btn" />
    </UploadComponent>
  );
};

export default Upload;
