import React, { useEffect, useState } from 'react';
import CommonDetails from './forms/CommonDetails';
import { useAppSelector } from '../../../../../store/hooks';
import { enquiryDetailsRequisitesSelector, enquiryDetailsSelector } from '../../../../../store/selectors/enquiriesSelector';
import RequisiteModal from '../../../../../common/components/requisite/RequisteModal';
import { useEditEnquiryRequisite } from '../../../hooks/useEditEnquiryRequisite';
import { enquiryFinalStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';

const DetailsComponent: React.FC = () => {
  const details = useAppSelector(enquiryDetailsSelector);
  const {
    billing,
    shipping,
    isShippingSame,
    billingCustomerId,
    shippingCustomerId,
    isShippingDetailsManualInput,
    isBillingDetailsManualInput,
    customerId,
  } = useAppSelector(enquiryDetailsRequisitesSelector);

  const [isVisible, setIsVisible] = useState(false);
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [isSame, setIsSame] = useState(false);

  useEffect(() => {
    setIsSame(isShippingSame);
  }, [isShippingSame]);

  const cancelHandle = () => {
    setIsVisible(false);
    setIsShippingMode(false);
    isShippingSame && setIsSame(true);
  };

  const finalStatuses = useAppSelector(enquiryFinalStatusesSelector);
  const statusDisabled = finalStatuses.find((el) => el.value === details?.enquiry.status);

  const editHandle = useEditEnquiryRequisite(isShippingMode, cancelHandle);

  return (
    <section className="info-grid__column">
      <h2 className="info-grid__title">Details</h2>
      <RequisiteModal
        isVisible={isVisible}
        onCancel={cancelHandle}
        requisite={isShippingMode ? shipping : billing}
        submitHandle={editHandle}
        entityId={isVisible
          ? (isShippingMode ? shippingCustomerId : billingCustomerId) || customerId
          : undefined}
        entitySourceType="customer"
        isManual={isShippingMode ? isShippingDetailsManualInput : isBillingDetailsManualInput}
        isShipping={isShippingMode}
        statusDisabled={!!statusDisabled}
        isOptionalFields
      />
      <CommonDetails
        setIsVisible={setIsVisible}
        setIsShippingMode={setIsShippingMode}
        details={details?.enquiry}
        isSame={isSame}
        setIsSame={setIsSame}
      />
    </section>
  );
};

export default DetailsComponent;
