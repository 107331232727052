import React from 'react';
import { Tooltip } from 'antd';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';

type ActivityLabelProps = {
  time: string | null | undefined,
  initiator: string | null,
}
const ActivityLabel: React.FC<ActivityLabelProps> = ({ time, initiator }) => {
  return (
    <div className="timeline-info">
      <time dateTime={localDateFormatHandler('YYYY-MM-DD HH:mm:ss', time || '')}>
        <b className="timeline-info__time">{localDateFormatHandler('DD/MM/YYYY', time || '')}</b>
        <span>{' at '}</span>
        <Tooltip title={localDateFormatHandler('HH:mm:ss', time || '')}>
          <span>{`${localDateFormatHandler('HH:mm', time || '')}`}</span>
        </Tooltip>
      </time>
      <span className="timeline-info__initiator">
        by&nbsp;
        <TruncateTooltip
          value={initiator}
          placement="topLeft"
          className="timeline-info__initiator-name"
          overlayClassName="timeline-info__tooltip"
        />
      </span>
    </div>
  );
};

export default ActivityLabel;
