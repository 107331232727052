import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import { PurchaseOrderViewParamEnum } from '../enums/PurchaseOrderViewEnum';
import { tabCountHandle } from '../../../../core/utils/tabCountHandle';
import { useAppSelector } from '../../../../store/hooks';
import { purchaseOrderDetailsSelector } from '../../../../store/selectors/purchaseOrderSelectors';

export const usePurchaseOrderParam = () => {
  const { backHandle } = useLocationHistoryState(true);
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const details = useAppSelector(purchaseOrderDetailsSelector);

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };

  const options = [
    {
      label: 'Details & activity',
      value: PurchaseOrderViewParamEnum.DETAILS,
    },
    {
      label: `Parts list ${tabCountHandle(details?.purchaseOrder.partsCount)}`,
      value: PurchaseOrderViewParamEnum.PART_LINES,
    },
    {
      label: `Attachments ${tabCountHandle(details?.purchaseOrder.attachmentsCount)}`,
      value: PurchaseOrderViewParamEnum.ATTACHMENTS,
    },
  ];

  return {
    backHandle,
    navigate,
    params,
    setModeParam,
    options,
    setSearchParams,
  };
};
