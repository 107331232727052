import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  EnquiryColumnType,
  EnquiryDto,
  EnquiryFiltersType, EnquiryResponseDto,
} from '../../enquiry/enquiries/types/enquiryTypes';
import {
  DetailsFiltersType,
  EnquiryContactDto, EnquiryContactsList,
  EnquiryEventDtoBase,
  EnquiryQuoteLineDto,
  EnquiryQuoteVehicleDto, ExistingVehSpecificationsFiltersType, GetEnquiryQuoteLinesResponse,
  QuoteLinesFilters,
  QuoteLineTimelineType,
  QuoteTotalSummaryDto,
  QuoteVehicleSummaryDto,
} from '../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { getEnquiriesNew } from '../thunks/enquiry/enquiryThunks';
import {
  CoreAttachmentDto,
  CoreAttachmentsFilters,
  CollectionColumnPaginationType,
  DictionaryItem,
  EntityLinkType,
  GenericColumnType,
  initDataList,
  initialCoreAttachmentsFilters,
  DataListType,
  PagingType,
  ResponseResult,
  ResponseSingleResult,
  LinksFiltersType,
  initLinksFilters,
  AssigneeDto,
  infiniteScrollInitPaging,
  initSmallPaging,
  CoreAttachmentsList,
  initDataListWithIds, GetAttachmentsResponse, CoreCustomerContactsList,
} from '../../core/types/coreTypes';
import {
  fetchQuoteLinePartsKitThunk,
  fetchQuoteLinePartsThunk,
  fetchVehicleSummaryThunk,
  getEnquiryQuoteVehicleThunk,
  getEnquiryQuotLinesThunk,
  getQuoteLinesCanNotBeCopiedThunk,
} from '../thunks/enquiry/quoteConfiguratorThunks';
import {
  deleteEnquiryThunk,
  getEnquiryDetails, getEnquiryLinks,
  getEnquiryTimelineQuoteLineAfterThunk,
  getEnquiryTimelineQuoteLineBeforeThunk,
  getEnquiryTimelineThunk,
} from '../thunks/enquiry/detailsThunks';
import { getEnquiryAttachmentsThunk } from '../thunks/enquiry/attachmentsThunks';
import { InitialQuoteLinesFilters } from '../initialValues';
import {
  ContactDto,
  DraftLinePartList,
  DraftLinePartsFiltersType,
  DraftLinePartsKitFiltersType, DraftLinePartsKitList,
  ExtendedTimelineFilters,
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters,
  initExtendedTimelineFilters,
} from '../../common/types/commonTypes';
import {
  getEnquiryContacts,
  getExistingContactsThunk,
  getGenerateEmailEnquiryContacts,
} from '../thunks/enquiry/enquiryContactsThunks';
import { CustomerContactFiltersType } from '../../customers/customer-view-page/types/customerVewPageTypes';
import { VehicleProfilesFilters } from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { PartDtoType } from '../../stock/search/types/stockSearchTypes';
import { PartsKitsDto } from '../../stock/parts-kits/dashboard/types/partsKitsTypes';

interface IEnquiriesState {
  assigneesFilter: AssigneeDto[],
  filters: EnquiryFiltersType,
  columnsPagination: Array<CollectionColumnPaginationType>,
  detailsFilters: DetailsFiltersType,
  enquiryCollections: Array<GenericColumnType<Array<EnquiryDto>>> | null,
  enquiryDetails?: EnquiryResponseDto,
  quoteVehiclesList: EnquiryQuoteVehicleDto[],
  openedVehicleId?: number,
  quoteLines: DataListType<EnquiryQuoteLineDto[], { maxNetPrice: string, allItemIds: number[] }>,
  quoteLinesFilters: QuoteLinesFilters,
  quoteLinesCategories: DictionaryItem[],
  missingQuoteLines: DataListType<EnquiryQuoteLineDto[]>,
  enqAttachments: CoreAttachmentsList,
  enqAttachmentsFilters: CoreAttachmentsFilters,
  enquiryTimeline: DataListType<EnquiryEventDtoBase[]>,
  enquiryTimelineFilters: ExtendedTimelineFilters,
  enquiryTimelineQuoteLines: {
    after: DataListType<QuoteLineTimelineType[]>,
    before: DataListType<QuoteLineTimelineType[]>,
  },
  enquiryTimelineQuoteLinesFilters: QuoteLinesFilters,
  currentInfoSource?: number,
  quoteLinePartsFilters: DraftLinePartsFiltersType,
  quoteLineParts: DraftLinePartList,
  quoteLinePartsKit: DraftLinePartsKitList,
  quoteLinePartsKitFilters: DraftLinePartsKitFiltersType,
  quoteVehicleSummary?: { quoteVehicleSummary: QuoteVehicleSummaryDto, totalSummary: QuoteTotalSummaryDto },
  enquiryContacts: EnquiryContactsList,
  enquiryContactsPaging: PagingType,
  existedContacts: CoreCustomerContactsList,
  generateEmailContactsPaging: PagingType,
  generateEmailContactsList: EnquiryContactsList,
  enquiryExistedContactsPaging: CustomerContactFiltersType,
  enquiryLinksFilters: LinksFiltersType,
  enquiryLinks: Array<EntityLinkType>,
  existingVehicleSpecificationFilters: ExistingVehSpecificationsFiltersType,
  addVehicleProfileFilters: VehicleProfilesFilters,
}

export const initialEnquiryState: IEnquiriesState = {
  assigneesFilter: [],
  filters: infiniteScrollInitPaging,
  columnsPagination: [],
  detailsFilters: {
    assignedTo: undefined,
    statusTransition: undefined,
  },
  enquiryCollections: null,
  enquiryDetails: undefined,
  quoteVehiclesList: [],
  openedVehicleId: undefined,
  quoteLines: { ...initDataList, maxNetPrice: '0', allItemIds: [] },
  quoteLinesFilters: InitialQuoteLinesFilters,
  quoteLinesCategories: [],
  missingQuoteLines: initDataList,
  enqAttachments: initDataListWithIds,
  enqAttachmentsFilters: initialCoreAttachmentsFilters,
  enquiryTimeline: initDataList,
  enquiryTimelineFilters: initExtendedTimelineFilters,
  enquiryTimelineQuoteLines: {
    after: initDataList,
    before: initDataList,
  },
  enquiryTimelineQuoteLinesFilters: InitialQuoteLinesFilters,
  currentInfoSource: undefined,
  quoteLinePartsFilters: initDraftLinePartsFilters,
  quoteLineParts: initDataList,
  quoteLinePartsKitFilters: initDraftLinePartsKitFilters,
  quoteLinePartsKit: initDataList,
  quoteVehicleSummary: undefined,
  enquiryContacts: initDataListWithIds,
  enquiryContactsPaging: infiniteScrollInitPaging,
  existedContacts: initDataListWithIds,
  generateEmailContactsPaging: infiniteScrollInitPaging,
  generateEmailContactsList: initDataListWithIds,
  enquiryExistedContactsPaging: infiniteScrollInitPaging,
  enquiryLinksFilters: initLinksFilters,
  enquiryLinks: [],
  existingVehicleSpecificationFilters: initSmallPaging,
  addVehicleProfileFilters: initSmallPaging,
};

export const enquiriesSlice = createSlice({
  name: 'enquiries',
  initialState: initialEnquiryState,
  reducers: {
    setFilters: (state, action: PayloadAction<EnquiryFiltersType>) => {
      state.filters = action.payload;
    },
    setAssigneesLookup: (state, action: PayloadAction<Array<AssigneeDto>>) => {
      state.assigneesFilter = action.payload;
    },
    setColumnsPagination: (state, action: PayloadAction<Array<CollectionColumnPaginationType>>) => {
      state.columnsPagination = action.payload;
    },
    setDetailsFilters: (state, action: PayloadAction<DetailsFiltersType>) => {
      state.detailsFilters = action.payload;
    },
    setEnquiryCollections: (state, action: PayloadAction<Array<GenericColumnType<Array<EnquiryDto>>> | null>) => {
      state.enquiryCollections = action.payload;
    },
    setEnquiryDetails: (state, action: PayloadAction<EnquiryResponseDto | undefined>) => {
      state.enquiryDetails = action.payload;
    },
    setQuoteVehiclesList: (state, action: PayloadAction<EnquiryQuoteVehicleDto[]>) => {
      state.quoteVehiclesList = action.payload;
    },
    setOpenedVehicleId: (state, action: PayloadAction<number | undefined>) => {
      state.openedVehicleId = action.payload;
    },
    setQuoteLines: (state, action: PayloadAction<{
      items: EnquiryQuoteLineDto[],
      pages: number,
      totalCount: number,
      maxNetPrice: string,
      allItemIds: number[]
    }>) => {
      state.quoteLines = action.payload;
    },
    setQuoteLinesFilters: (state, action: PayloadAction<QuoteLinesFilters>) => {
      state.quoteLinesFilters = action.payload;
    },
    setAttachmentsTableFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.enqAttachmentsFilters = action.payload;
    },
    setEnqAttachments: (state, action: PayloadAction<CoreAttachmentDto[]>) => {
      state.enqAttachments = { ...state.enqAttachments, items: action.payload };
    },
    setEnqTimeline: (state, action: PayloadAction<DataListType<EnquiryEventDtoBase[]>>) => {
      state.enquiryTimeline = action.payload;
    },
    setEnqTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.enquiryTimelineFilters = action.payload;
    },
    setEnquiryTimelineQuoteLines: (
      state,
      action: PayloadAction<{
        after: DataListType<QuoteLineTimelineType[]>,
        before: DataListType<QuoteLineTimelineType[]>,
      }>,
    ) => {
      state.enquiryTimelineQuoteLines = action.payload;
    },
    setEnquiryTimelineQuoteLinesFilters: (state, action: PayloadAction<QuoteLinesFilters>) => {
      state.enquiryTimelineQuoteLinesFilters = action.payload;
    },
    setCurrentInfoSource: (state, action: PayloadAction<number | undefined>) => {
      state.currentInfoSource = action.payload;
    },
    setQuoteLinePartsFilters: (state, action: PayloadAction<DraftLinePartsFiltersType>) => {
      state.quoteLinePartsFilters = action.payload;
    },
    setQuoteLineParts: (state, action: PayloadAction<DraftLinePartList>) => {
      state.quoteLineParts = action.payload;
    },
    setQuoteLinePartsKitFilters: (state, action: PayloadAction<DraftLinePartsKitFiltersType>) => {
      state.quoteLinePartsKitFilters = action.payload;
    },
    setQuoteLinePartsKit: (state, action: PayloadAction<DataListType<PartsKitsDto[]>>) => {
      state.quoteLinePartsKit = action.payload;
    },
    setEnquiryContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.enquiryContactsPaging = action.payload;
    },
    setEnquiryExistedContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.enquiryExistedContactsPaging = action.payload;
    },
    setExistedContacts: (state, action: PayloadAction<CoreCustomerContactsList>) => {
      state.existedContacts = action.payload;
    },
    setEnquiryContacts: (state, action: PayloadAction<EnquiryContactsList>) => {
      state.enquiryContacts = action.payload;
    },
    setGenerateEmailContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.generateEmailContactsPaging = action.payload;
    },
    setGenerateEmailContacts: (state, action: PayloadAction<EnquiryContactsList>) => {
      state.generateEmailContactsList = action.payload;
    },
    setQuoteVehicleSummary: (state, action: PayloadAction<{
      quoteVehicleSummary: QuoteVehicleSummaryDto,
      totalSummary: QuoteTotalSummaryDto} | undefined
    >) => {
      state.quoteVehicleSummary = action.payload;
    },
    setEnquiryLinks: (state, action: PayloadAction<EntityLinkType[]>) => {
      state.enquiryLinks = action.payload;
    },
    setEnquiryLinksFilters: (state, action: PayloadAction<LinksFiltersType>) => {
      state.enquiryLinksFilters = action.payload;
    },
    setExistingVehicleSpecificationsFilters: (state, action: PayloadAction<ExistingVehSpecificationsFiltersType>) => {
      state.existingVehicleSpecificationFilters = action.payload;
    },
    setAddVehicleProfileFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.addVehicleProfileFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getEnquiriesNew.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{
        enquiryCollections: Array<EnquiryColumnType> | null,
        assignees: Array<AssigneeDto> | null,
      }>>) => {
        const { enquiryCollections, assignees } = action.payload.data;
        const collectionsList = enquiryCollections?.map((el) => ({
          status: el.status,
          filteredTotalCount: el.filteredTotalCount,
          totalCount: el.totalCount,
          totalCost: el.totalCost,
          pages: el.pages,
          isHidden: el.isHidden,
          order: el.order,
          list: el.enquiries,
        }));
        state.enquiryCollections = collectionsList || null;
        state.columnsPagination = enquiryCollections?.map((col) => ({ status: col.status, pages: col.pages, currentPage: 1 })) || [];
        state.assigneesFilter = assignees || [];
      },
    );
    builder.addCase(
      getEnquiryDetails.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<EnquiryResponseDto | null>>) => {
        state.enquiryDetails = action.payload.data || undefined;
      },
    );
    builder.addCase(deleteEnquiryThunk.fulfilled, (state) => {
      state.enquiryDetails = undefined;
    });
    builder.addCase(
      getEnquiryQuoteVehicleThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: EnquiryQuoteVehicleDto[] }>>) => {
        state.quoteVehiclesList = action.payload.data.items;
      },
    );
    builder.addCase(
      getEnquiryQuotLinesThunk.fulfilled,
      (state, action: PayloadAction<GetEnquiryQuoteLinesResponse>) => {
        const { categories, ...rest } = action.payload.data;
        state.quoteLines = rest;
        state.quoteLinesCategories = categories;
      },
    );
    builder.addCase(
      getQuoteLinesCanNotBeCopiedThunk.fulfilled,
      (state, action: PayloadAction<GetEnquiryQuoteLinesResponse>) => {
        state.missingQuoteLines = action.payload.data;
      },
    );
    builder.addCase(
      getEnquiryAttachmentsThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.enqAttachments = action.payload.data;
      },
    );
    builder.addCase(getEnquiryTimelineThunk.fulfilled, (state, action: PayloadAction<ResponseResult<EnquiryEventDtoBase[]>>) => {
      state.enquiryTimeline = action.payload.data;
    });
    builder.addCase(
      getEnquiryTimelineQuoteLineBeforeThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<QuoteLineTimelineType[]>>) => {
        const { items, pages, totalCount } = action.payload.data;
        if (items) {
          state.enquiryTimelineQuoteLines = {
            ...state.enquiryTimelineQuoteLines,
            before: {
              items: state.enquiryTimelineQuoteLinesFilters.page > 1
                ? [...state.enquiryTimelineQuoteLines.before.items, ...items]
                : items,
              pages,
              totalCount,
            },
          };
        }
      },
    );
    builder.addCase(
      getEnquiryTimelineQuoteLineAfterThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<QuoteLineTimelineType[]>>) => {
        const { items, pages, totalCount } = action.payload.data;
        if (items) {
          state.enquiryTimelineQuoteLines = {
            ...state.enquiryTimelineQuoteLines,
            after: {
              items: state.enquiryTimelineQuoteLinesFilters.page > 1 ? [...state.enquiryTimelineQuoteLines.after.items, ...items] : items,
              pages,
              totalCount,
            },
          };
        }
      },
    );
    builder.addCase(
      fetchQuoteLinePartsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<Array<PartDtoType>>>) => {
        state.quoteLineParts = action.payload.data;
      },
    );
    builder.addCase(
      fetchQuoteLinePartsKitThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<Array<PartsKitsDto>>>) => {
        state.quoteLinePartsKit = action.payload.data;
      },
    );
    builder.addCase(
      fetchVehicleSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{
        quoteVehicleSummary: QuoteVehicleSummaryDto, totalSummary: QuoteTotalSummaryDto
      }>>) => {
        state.quoteVehicleSummary = action.payload.data;
      },
    );
    builder.addCase(
      getEnquiryContacts.fulfilled,
      (state, action: PayloadAction<ResponseResult<EnquiryContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.enquiryContacts = {
          items: state.enquiryContactsPaging.page > 1
            ? [...state.enquiryContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getGenerateEmailEnquiryContacts.fulfilled,
      (state, action: PayloadAction<ResponseResult<EnquiryContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.generateEmailContactsList = {
          items: state.generateEmailContactsPaging.page > 1
            ? [...state.generateEmailContactsList.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getExistingContactsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<ContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.existedContacts = {
          items: state.enquiryExistedContactsPaging.page > 1
            ? [...state.existedContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getEnquiryLinks.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ orderLinks: Array<EntityLinkType> }> | null>) => {
        state.enquiryLinks = action.payload?.data.orderLinks || [];
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setFilters,
  setAssigneesLookup,
  setColumnsPagination,
  setDetailsFilters,
  setEnquiryCollections,
  setEnquiryDetails,
  setQuoteVehiclesList,
  setOpenedVehicleId,
  setQuoteLines,
  setQuoteLinesFilters,
  setAttachmentsTableFilters,
  setEnqAttachments,
  setEnqTimeline,
  setEnqTimelineFilters,
  setEnquiryTimelineQuoteLinesFilters,
  setEnquiryTimelineQuoteLines,
  setCurrentInfoSource,
  setQuoteLinePartsFilters,
  setQuoteLineParts,
  setQuoteLinePartsKitFilters,
  setQuoteLinePartsKit,
  setEnquiryContactsPaging,
  setEnquiryExistedContactsPaging,
  setExistedContacts,
  setEnquiryContacts,
  setQuoteVehicleSummary,
  setEnquiryLinksFilters,
  setEnquiryLinks,
  setGenerateEmailContacts,
  setGenerateEmailContactsPaging,
  setExistingVehicleSpecificationsFilters,
  setAddVehicleProfileFilters,
} = enquiriesSlice.actions;

export default enquiriesSlice.reducer;
