import React from 'react';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';

type EditFieldConfirmationModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  confirmHandler: VoidFunctionType,
  data: { field: 'partCode' | 'name', value: string } | null
}
const EditFieldConfirmationModal: React.FC<EditFieldConfirmationModalProps> = ({
  visible, onClose, confirmHandler, data,
}) => {
  return (
    <ConfirmationModal
      isVisible={visible}
      onCancel={onClose}
      confirmHandler={confirmHandler}
      customTitle="Confirm"
      customText={<>
        Part
        {' '}
        {`${data?.field === 'partCode' ? 'code' : 'name'}`}
        {' '}
        value will be updated to
        <b>{` "${data?.value}" `}</b>
        in all active entities in the system.
      </>}
      customProceedText={`Do you want to update part ${data?.field === 'partCode' ? 'code' : 'name'}?`}
      customCreateLabel="Yes, update"
    />
  );
};

export default EditFieldConfirmationModal;
