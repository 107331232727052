import React from 'react';
import { Spin } from 'antd';
import './Spinner.scss';
import classnames from 'classnames';

type SpinnerProps = {
  global?: boolean
  size?: 'small' | 'default' | 'large',
}
const Spinner:React.FC<SpinnerProps> = ({ global = true, size = 'large' }) => {
  return (
    <div className={classnames('spinner', { 'spinner--global': global })}><Spin size={size} /></div>
  );
};

export default Spinner;
