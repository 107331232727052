import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CoreAttachmentsFilters, CoreAttachmentsList,
  DataListType,
  DictionaryItem, DictionaryResponse, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters, initPaging, initSmallPaging,
  initUndefinedList,
  PagingType,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  PartsKitsDto,
} from '../../stock/parts-kits/dashboard/types/partsKitsTypes';
import {
  getPartQuantities,
  getPartTypes,
  getStockPartRequestsStatistic,
  getStockPartsStatistic,
  getStockPurchaseOrdersStatistic,
  getStockSearch,
  getStockSearchReorderLevels,
} from '../thunks/stock/stockThunks';
import {
  InitStockReorderLevelsFilters,
  PartDtoType,
  StockFiltersType,
} from '../../stock/search/types/stockSearchTypes';
import {
  getPartViewTimeline,
  getStockPartAffectedThunk,
  getStockPartByIdThunk,
  getStockPartViewPartsKits,
} from '../thunks/stock/part/partViewPageThunks';
import {
  AdjustQtyFiltersType,
  GetPartQuantitiesDataType,
  GetPartResponse, PartAffectedEntitiesFilters,
  PartAffectedEntityDto,
  PartQtyType, PartViewEventDtoBase,
} from '../../stock/part-view-page/types/types';
import { getPartAttachmentsThunk } from '../thunks/stock/part/partAttachmentsThunks';
import { PartPricesFilters } from '../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import {
  GetPartRequestStatisticsResponse,
  GetPartStatisticsResponse,
  GetPurchaseOrderStatisticsResponse,
} from '../../stock/dashboard/types/types';
import {
  PartSubcategoriesFilters,
  PartSubcategoryDto,
  AffectedCategoriesFiltersType,
} from '../../stock/part-categories/types/partCategoriesTypes';
import {
  getPartCategoryAffectedThunk, getPartSubcategoryAffectedThunk,
  getStockPartSubcategoriesThunk,
} from '../thunks/stock/categories/partCategoriesThunks';
import { AffectedEntityType, ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';
import { initAdjustPartQuantityFilters } from '../../stock/part-view-page/utils/data';

interface IStockState {
  stockSearchFilters: StockFiltersType,
  stockPartTypes: DictionaryItem[],
  stockPartsStatistic: GetPartStatisticsResponse | null,
  stockPurchaseOrderStatistic: GetPurchaseOrderStatisticsResponse | null,
  stockPartRequestStatistic: GetPartRequestStatisticsResponse | null,
  stockPartReorderLevels: DataListType<PartDtoType[]>,
  stockPartReorderLevelsFilters: StockFiltersType,
  stockSearchResult: DataListType<PartDtoType[] | undefined>,
  partDetails: GetPartResponse | null,
  partImageData: { uri: string | null, id: number | null },
  partAffectedEntitiesFilters: PartAffectedEntitiesFilters,
  partAffectedEntities: DataListType<PartAffectedEntityDto[]>,
  partViewPartsKitsList: DataListType<PartsKitsDto[]> | null,
  partViewPartsKitsFilters: PagingType,
  partAttachments: CoreAttachmentsList,
  partAttachmentsFilters: CoreAttachmentsFilters,
  selectPricelistAndQtyFilters?: PartPricesFilters,
  partQuantities: DataListType<PartQtyType[] | null>,
  partQuantitiesAvailablePurchaseOrders: Array<{ purchaseOrderId: number | null, purchaseOrderNumber: string | null }> | null,
  partQuantitiesPaging: AdjustQtyFiltersType,
  stockPartSubcategories: DataListType<PartSubcategoryDto[]>,
  stockPartSubcategoriesFilters: PartSubcategoriesFilters,
  stockPartAffectedCategories: DataListType<AffectedEntityType[]>,
  stockPartAffectedCategoriesFilters: AffectedCategoriesFiltersType,
  partViewPageTimeline: DataListType<PartViewEventDtoBase[]>,
  partViewPageTimelineFilters: ExtendedTimelineFilters,
}

export const initialStockState: IStockState = {
  stockPartsStatistic: null,
  stockPartRequestStatistic: null,
  stockPurchaseOrderStatistic: null,
  stockPartReorderLevels: initDataList,
  stockPartReorderLevelsFilters: InitStockReorderLevelsFilters,
  stockPartTypes: [],
  stockSearchFilters: initPaging,
  stockSearchResult: initUndefinedList, // do not use initDataList due to related logic with 'undefined' value
  partDetails: null,
  partImageData: { uri: null, id: null },
  partAffectedEntitiesFilters: initSmallPaging,
  partAffectedEntities: initDataList,
  partViewPartsKitsList: initDataList,
  partViewPartsKitsFilters: initPaging,
  partAttachments: initDataListWithIds,
  partAttachmentsFilters: initialCoreAttachmentsFilters,
  selectPricelistAndQtyFilters: initSmallPaging,
  partQuantities: initDataList,
  partQuantitiesAvailablePurchaseOrders: null,
  partQuantitiesPaging: initAdjustPartQuantityFilters,
  stockPartSubcategories: initDataList,
  stockPartSubcategoriesFilters: initPaging,
  stockPartAffectedCategories: initDataList,
  stockPartAffectedCategoriesFilters: initSmallPaging,
  partViewPageTimeline: initDataList,
  partViewPageTimelineFilters: initExtendedTimelineFilters,
};

export const stockSlice = createSlice({
  name: 'stock',
  initialState: initialStockState,
  reducers: {
    setPartViewPartsKitsList: (state, action: PayloadAction<DataListType<PartsKitsDto[]>>) => {
      state.partViewPartsKitsList = action.payload;
    },
    setPartViewPartsKitsFilters: (state, action: PayloadAction<{ page: number, pageSize: number }>) => {
      state.partViewPartsKitsFilters = action.payload;
    },
    setStockSearchFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.stockSearchFilters = action.payload;
    },
    setStockSearchResult: (state, action: PayloadAction<DataListType<PartDtoType[] | undefined>>) => {
      state.stockSearchResult = action.payload;
    },
    setPartsStatistic: (state, action: PayloadAction<GetPartStatisticsResponse | null>) => {
      state.stockPartsStatistic = action.payload;
    },
    setPurchaseOrdersStatistic: (state, action: PayloadAction<GetPurchaseOrderStatisticsResponse | null>) => {
      state.stockPurchaseOrderStatistic = action.payload;
    },
    setPartRequestsStatistic: (state, action: PayloadAction<GetPartRequestStatisticsResponse | null>) => {
      state.stockPartRequestStatistic = action.payload;
    },
    setPartReorderLevels: (state, action: PayloadAction<DataListType<PartDtoType[]>>) => {
      state.stockPartReorderLevels = action.payload;
    },
    setPartReorderLevelsFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.stockPartReorderLevelsFilters = action.payload;
    },
    setPartDetails: (state, action: PayloadAction<GetPartResponse | null>) => {
      state.partDetails = action.payload;
    },
    setPartImageData: (state, action: PayloadAction<{ uri?: string | null, id?: number | null}>) => {
      state.partImageData = { ...state.partImageData, ...action.payload };
    },
    setPartAffectedEntitiesFilters: (state, action: PayloadAction<PartAffectedEntitiesFilters>) => {
      state.partAffectedEntitiesFilters = action.payload;
    },
    setPartAffectedEntities: (state, action: PayloadAction<DataListType<PartAffectedEntityDto[]>>) => {
      state.partAffectedEntities = action.payload;
    },
    setPartAttachmentsFilter: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.partAttachmentsFilters = action.payload;
    },
    setPartAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.partAttachments = action.payload;
    },
    setSelectPricelistAndQtyFilters: (state, action: PayloadAction<PartPricesFilters | undefined>) => {
      state.selectPricelistAndQtyFilters = action.payload;
    },
    setPartQuantities: (state, action: PayloadAction<DataListType<PartQtyType[]>>) => {
      state.partQuantities = action.payload;
    },
    setPartQuantitiesPaging: (state, action: PayloadAction<AdjustQtyFiltersType>) => {
      state.partQuantitiesPaging = action.payload;
    },
    setPartQuantitiesAvailablePurchaseOrders: (
      state,
      action: PayloadAction<Array<{ purchaseOrderId: number | null; purchaseOrderNumber: string | null; }> | null>,
    ) => {
      state.partQuantitiesAvailablePurchaseOrders = action.payload;
    },
    setStockPartSubcategories: (state, action: PayloadAction<DataListType<PartSubcategoryDto[]>>) => {
      state.stockPartSubcategories = action.payload;
    },
    setPartSubcategoriesFilters: (state, action: PayloadAction<PartSubcategoriesFilters>) => {
      state.stockPartSubcategoriesFilters = action.payload;
    },
    setStockPartAffectedCategories: (state, action: PayloadAction<DataListType<AffectedEntityType[]>>) => {
      state.stockPartAffectedCategories = action.payload;
    },
    setStockPartAffectedCategoriesFilter: (state, action: PayloadAction<AffectedCategoriesFiltersType>) => {
      state.stockPartAffectedCategoriesFilters = action.payload;
    },
    setPartViewPageTimeline: (state, action: PayloadAction<DataListType<PartViewEventDtoBase[]>>) => {
      state.partViewPageTimeline = action.payload;
    },
    setPartViewPageTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.partViewPageTimelineFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPartTypes.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartTypes = action.payload?.data.items || [];
    });
    builder.addCase(getStockSearch.fulfilled, (state, action: PayloadAction<ResponseResult<PartDtoType[] | undefined> | null>) => {
      state.stockSearchResult = action.payload ? action.payload?.data : initDataList;
    });
    builder.addCase(
      getStockPartsStatistic.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartStatisticsResponse> | null>) => {
        state.stockPartsStatistic = action.payload?.data || null;
      },
    );
    builder.addCase(
      getStockPurchaseOrdersStatistic.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPurchaseOrderStatisticsResponse> | null>) => {
        state.stockPurchaseOrderStatistic = action.payload?.data || null;
      },
    );
    builder.addCase(
      getStockPartRequestsStatistic.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartRequestStatisticsResponse> | null>) => {
        state.stockPartRequestStatistic = action.payload?.data || null;
      },
    );
    builder.addCase(
      getStockSearchReorderLevels.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[]>>) => {
        if (action.payload) {
          const { items, pages, totalCount } = action.payload.data;
          state.stockPartReorderLevels = {
            items: state.stockPartReorderLevelsFilters.page > 1
              ? [...state.stockPartReorderLevels.items, ...items]
              : items,
            pages,
            totalCount,
          };
        } else {
          state.stockPartReorderLevels = initDataList;
        }
      },
    );
    builder.addCase(
      getStockPartByIdThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartResponse> | null>) => {
        state.partDetails = action.payload?.data || null;
      },
    );
    builder.addCase(
      getStockPartAffectedThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartAffectedEntityDto[]>>) => {
        state.partAffectedEntities = action.payload.data;
      },
    );
    builder.addCase(
      getPartAttachmentsThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.partAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getStockPartViewPartsKits.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitsDto[]> | null>) => {
        state.partViewPartsKitsList = action.payload?.data || null;
      },
    );
    builder.addCase(
      getPartQuantities.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartQuantitiesDataType> | null>) => {
        state.partQuantities = action.payload?.data.partQuantities || initDataList;
        if (!state.partQuantitiesAvailablePurchaseOrders) {
          state.partQuantitiesAvailablePurchaseOrders = action.payload?.data.availablePurchaseOrders || null;
        }
      },
    );
    builder.addCase(
      getStockPartSubcategoriesThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartSubcategoryDto[]>>) => {
        state.stockPartSubcategories = action.payload.data;
      },
    );
    builder.addCase(
      getPartSubcategoryAffectedThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<AffectedEntityType[]>>) => {
        state.stockPartAffectedCategories = action.payload.data;
      },
    );
    builder.addCase(
      getPartCategoryAffectedThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<AffectedEntityType[]>>) => {
        state.stockPartAffectedCategories = action.payload.data;
      },
    );
    builder.addCase(
      getPartViewTimeline.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartViewEventDtoBase[]>>) => {
        state.partViewPageTimeline = action.payload.data;
      },
    );
  },
});

export const {
  setPartViewPartsKitsList,
  setPartViewPartsKitsFilters,
  setStockSearchFilters,
  setStockSearchResult,
  setPartsStatistic,
  setPurchaseOrdersStatistic,
  setPartRequestsStatistic,
  setPartReorderLevels,
  setPartReorderLevelsFilters,
  setPartDetails,
  setPartImageData,
  setPartAffectedEntitiesFilters,
  setPartAffectedEntities,
  setPartAttachments,
  setPartAttachmentsFilter,
  setSelectPricelistAndQtyFilters,
  setPartQuantities,
  setPartQuantitiesPaging,
  setPartQuantitiesAvailablePurchaseOrders,
  setStockPartSubcategories,
  setPartSubcategoriesFilters,
  setStockPartAffectedCategories,
  setStockPartAffectedCategoriesFilter,
  setPartViewPageTimeline,
  setPartViewPageTimelineFilters,
} = stockSlice.actions;

export default stockSlice.reducer;
