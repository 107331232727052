import React, { useState } from 'react';
import Button from '../../../../core/components/button/Button';
import { SuitableForVehicleChangeEventData } from '../../../types/commonTypes';
import SuitableForVehiclesChangeEventModal from './SuitableForVehiclesChangeEventModal';

type SuitableForVehiclesChangeEventProps = {
  data: SuitableForVehicleChangeEventData;
}

const SuitableForVehiclesChangeEvent: React.FC<SuitableForVehiclesChangeEventProps> = ({ data }) => {
  const [vehicleModal, setVehicleModal] = useState(false);
  return (
    <>
      <SuitableForVehiclesChangeEventModal
        visible={vehicleModal}
        setIsVisible={setVehicleModal}
        data={data}
      />
      Suitable vehicles list was updated
      {' '}
      <Button
        label="(see details)"
        onClick={() => setVehicleModal(true)}
        type="text"
      />
    </>
  );
};

export default SuitableForVehiclesChangeEvent;
