import React, { useState } from 'react';
import Modal from '../../../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import Button from '../../../../../../../core/components/button/Button';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../../../../core/components/table-components/Thead';
import { editPartPriceColumns } from '../../../../../utils/data';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import Tr from '../../../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import { PricelistPartEventPriceDto } from '../../../../../types/pricelistViewPageTypes';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import { localDateFormatHandler } from '../../../../../../../core/utils/utcDateFormatHandler';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import './PriceChangeEventModal.scss';

type PriceChangeEventModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  oldPrices: PricelistPartEventPriceDto[],
  newPrices: PricelistPartEventPriceDto[],
}

const PriceChangeEventModal: React.FC<PriceChangeEventModalProps> = ({
  visible,
  onCancel,
  oldPrices,
  newPrices,
}) => {
  const [mode, setMode] = useState<'before' | 'after'>('after');
  const prices = mode === 'before' ? oldPrices : newPrices;
  const pricesWithOrderNumber = prices.map((el, i) => ({
    number: i + 1,
    costPrice: `£ ${el.costPrice || '0.00'}`,
    sellPrice: `£ ${el.sellPrice || '0.00'}`,
    startDate: el.effectiveStartDate ? localDateFormatHandler('DD/MM/YYYY', el.effectiveStartDate) : '∞',
    endDate: el.effectiveEndDate ? localDateFormatHandler('DD/MM/YYYY', el.effectiveEndDate) : '∞',
  }));

  return (
    <Modal visible={visible} onCancel={onCancel} width={900} title="Prices" destroyOnClose>
      <div className="event-modal-radio">
        <Button
          label="Before"
          onClick={() => setMode('before')}
          designType="light-inverse"
          isActivated={mode === 'before'}
          offsetRight
        />
        <Button
          label="After"
          onClick={() => setMode('after')}
          designType="light-inverse"
          isActivated={mode === 'after'}
        />
      </div>
      <TableNew small>
        <Thead>
          <Tr>
            {editPartPriceColumns.map((col) => (
              <ThNew
                key={col.dataIndex}
                className={`priceChangeEventTh--${col.dataIndex}`}
              >
                {col.title}
              </ThNew>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {pricesWithOrderNumber.length > 0
            ? pricesWithOrderNumber.map((el, i) => (
              <Tr key={i}>
                {Object.entries(el).map(([key, value]) => (
                  <TdNew key={key} className="nowrap">{value}</TdNew>
                ))}
              </Tr>
            ))
            : <EmptyTableSection text="No records" colSpan={editPartPriceColumns.length} />}
        </Tbody>
      </TableNew>
    </Modal>
  );
};

export default PriceChangeEventModal;
