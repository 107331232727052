import React, { useMemo } from 'react';
import { CustomAny, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector, QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import PriceSourceTag from '../price-source-tag/PriceSourceTag';
import Tag from '../../../core/components/tag/Tag';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../core/components/table-components/Thead';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import Tbody from '../../../core/components/table-components/Tbody';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import Modal from '../../../core/components/modal/Modal';
import { CustomPartReqLineType } from './PartRequestSubmissionButtons';
import { useSelectAll } from '../../../core/hooks/useSelectAll';

type SubmitCustomLinesModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  allCustomLines: CustomPartReqLineType[],
  submitRequestHandle: (checkedCells: number[], cancelHandle: VoidFunctionType) => void,
}

const columns = [
  {
    title: 'No',
    dataIndex: 'ordinalNumber',
  },
  {
    title: 'Category',
    dataIndex: 'categoryId',
  },
  {
    title: 'Product name',
    dataIndex: 'productName',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
  },
  {
    title: 'Source',
    dataIndex: 'priceSources',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];

const SubmitCustomLinesModal: React.FC<SubmitCustomLinesModalProps> = ({
  visible, onCancel, allCustomLines, submitRequestHandle,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);

  const visibleCells = columns.map((el) => el.dataIndex);
  const nowrapValues = ['quantity', 'unitPrice'];

  const allItemIds = useMemo(() => allCustomLines.map(({ id }) => id), [allCustomLines]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const cancelHandle = () => {
    onCancel();
    uncheckAllHandle();
  };

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip
      value={value}
      table
    />;
  };

  const cellRenderHandle = (key: string, value: CustomAny, unitOfMeasure: number) => {
    const measureLabel = unitsOfMeasure.find((el) => el.value === unitOfMeasure)?.label;
    switch (key) {
      case 'quantity':
        return `${value} ${measureLabel}`;
      case 'priceSources':
        return <div className="priceTags">{value.map((el: number) => <PriceSourceTag source={el} />)}</div>;
      case 'categoryId':
      case 'productName':
        return renderTruncateTooltip(value);
      case 'unitPrice':
        return value ? `£ ${value}` : '-';
      case 'status':
        return <Tag label={value} />;
      default: return value;
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Submit custom lines"
      width={1000}
      destroyOnClose
    >
      <p className="submitCustomLinesModal__text">
        Please select, which custom lines should be submitted as a part request into Stock module for processing:
      </p>
      <TableNew>
        <Thead>
          <ThNew checkbox>
            <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAllHandle} table />
          </ThNew>
          {columns.map((col) => (
            <ThNew
              key={col.dataIndex}
              className={`submitCustomLinesModalTh--${col.dataIndex}`}
            >
              {col.title}
            </ThNew>))}
        </Thead>
        <Tbody>
          {allCustomLines.map((cell, i: number) => <Tr key={i}>
            <TdNew>
              <Checkbox checked={checkedKeys.includes(cell.id)} onChange={() => checkRowHandle(cell.id)} table />
            </TdNew>
            {Object.entries(cell).map(([key, value]) => {
              if (visibleCells.includes(key)) {
                return <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
                  {cellRenderHandle(key, value, cell.unitOfMeasure)}
                </TdNew>;
              } else return null;
            })}
          </Tr>)}
        </Tbody>
      </TableNew>
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createLabel="Submit selected"
        createClick={() => submitRequestHandle(checkedKeys, cancelHandle)}
        isLoading={isFetching}
        offsetTop
      />
    </Modal>
  );
};

export default SubmitCustomLinesModal;
