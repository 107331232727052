import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import { setPartsKitTimelineFilters } from '../../../../../../store/slices/partsKitSlice';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  partsKitTimelineFiltersSelector,
  partsKitTimelineSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import PartsKitActivityEvent from './parts-kit-activity-events/PartsKitActivityEvent';
import Timeline from '../../../../../../core/components/timeline/Timeline';
import { getPartsKitEventTypesThunk } from '../../../../../../store/thunks/core/coreEventTypesThunks';
import Switch from '../../../../../../core/components/switch/Switch';
import {
  getPartsKitTimelineThunk,
  postEnquiryTimelineNoteThunk,
} from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import ActivityLabel from '../../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../../common/components/activity-events/ActivityNoteInput';

const PartsKitActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const partsKitTimelineFilters = useAppSelector(partsKitTimelineFiltersSelector);
  const { items, totalCount } = useAppSelector(partsKitTimelineSelector);
  const [value, setValue] = useState('');

  const allowedToAddNote = usePermission(PermissionEnum.StockPartsKitViewPage);

  const addNote = () => {
    id && dispatch(postEnquiryTimelineNoteThunk({
      partsKitId: +id, content: value.trim(), setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <PartsKitActivityEvent item={el} />,
  }));

  useEffect(() => {
    id && dispatch(getPartsKitTimelineThunk({
      partsKitId: +id,
      filters: partsKitTimelineFilters,
    }));
    // eslint-disable-next-line
    }, [id, partsKitTimelineFilters.mainOnly, partsKitTimelineFilters.page]);

  useEffect(() => {
    dispatch(getPartsKitEventTypesThunk());
    // eslint-disable-next-line
  }, []);
  return (
    <section className="kitsDetailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!partsKitTimelineFilters.mainOnly}
          onChange={(val) => dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, mainOnly: !val, page: 1 }))}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={partsKitTimelineFilters.page}
        pageSize={partsKitTimelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setPartsKitTimelineFilters({ ...partsKitTimelineFilters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default PartsKitActivitySection;
