import React, { useEffect, useState } from 'react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import EmployeesLeaveRequestsFilters from './components/leave-requests-filters/EmployeesLeaveRequestsFilters';
import EmptyContentList from '../../core/components/empty-content-list/EmptyContentList';
import EmployeesLeaveRequestItem from './components/leave-request-item/EmployeesLeaveRequestItem';
import { useLoadEmployeesLeaveRequestsData } from './hooks/useLoadEmployeesLeaveRequestsData';
import AccountLeaveRequestModal
  from '../../account/components/account-content/leave-requests/components/leave-request-modal/AccountLeaveRequestModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { accountLeaveRequestSelector } from '../../store/selectors/accountSelectors';
import {
  getAccountLeaveRequestThunk,
} from '../../store/thunks/account/accountThunks';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import {
  changeEmployeeLeaveRequestThunk, createEmployeeLeaveRequestThunk,
  deleteEmployeeLeaveRequestThunk,
} from '../../store/thunks/employee/employeesLeaveRequestsThunks';
import { getAllEmployeesListThunk } from '../../store/thunks/employee/employeesWorkCalendarThunks';
import { setEmployeesLeaveRequests, setEmployeesLeaveRequestsFilters } from '../../store/slices/employeesSlice';
import { initEmployeesLeaveRequestsFilters } from './types/EmployeesLeaveRequestsTypes';
import { initDataList } from '../../core/types/coreTypes';

const EmployeesLeaveRequestsPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const { items, totalCount } = useLoadEmployeesLeaveRequestsData();
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const leaveRequest = useAppSelector(accountLeaveRequestSelector);

  useEffect(() => {
    dispatch(getAllEmployeesListThunk({ filters: { hasLinkedAccount: true } }));

    return () => {
      dispatch(setEmployeesLeaveRequestsFilters(initEmployeesLeaveRequestsFilters));
      dispatch(setEmployeesLeaveRequests(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const getEditedLeaveRequestData = (id: number) => {
    dispatch(getAccountLeaveRequestThunk({ id }));
  };

  return (
    <>
      <AccountLeaveRequestModal
        visible={isModalOpen || !!leaveRequest}
        type={leaveRequest ? 'edit' : 'create'}
        setCreateModal={setModalOpen}
        changeHandle={(id, data, closeModal) => {
          dispatch(changeEmployeeLeaveRequestThunk({
            id,
            data,
            closeModal,
          }));
        }}
        createHandle={(data, type, closeModal) => {
          dispatch(createEmployeeLeaveRequestThunk({
            data,
            type,
            closeModal,
          }));
        }}
        withEmployeeSelection
      />
      <ConfirmationModal
        isVisible={!!idToDelete}
        onCancel={() => setIdToDelete(null)}
        confirmHandler={() => {
          idToDelete && dispatch(deleteEmployeeLeaveRequestThunk({
            id: idToDelete,
            closeModal: () => setIdToDelete(null),
          }));
        }}
      />
      <EmployeesLeaveRequestsFilters totalCount={totalCount} openCreationForm={() => setModalOpen(true)} />
      {items.length > 0
        ? <ul className="lines-list">
          {items.map((el) => <li className="lines-list__item" key={el.id}>
            <EmployeesLeaveRequestItem
              data={el}
              getEditedLeaveRequestData={() => getEditedLeaveRequestData(el.id)}
              setIdToDelete={setIdToDelete}
            />
          </li>)}
        </ul>
        : <EmptyContentList icon={faCalendar} text="No leave requests" />}
    </>
  );
};

export default EmployeesLeaveRequestsPage;
