import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import {
  orderStatusesValuesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import { OrderStatusUiEnum } from '../../../orders/dashboard/enums/ordersEnums';
import { routesPath } from '../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { toPoundCurrency } from '../../../common/utils/formatUtils';
import { OrderListDto } from '../../../orders/dashboard/types/ordersTypes';
import InvoicingDashboardLine from '../../common/components/dashboard-line/InvoicingDashboardLine';
import { InvoicingPageParamsEnums } from '../../container/enums/InvoicingPageParamsEnums';

type InvoicingSalesItemProps = {
  item: OrderListDto,
}

const InvoicingSalesItem: React.FC<InvoicingSalesItemProps> = ({ item }) => {
  const { invoicing, acceptedByCustomer, postCheckPassed } = useAppSelector(orderStatusesValuesSelector);

  const getStatusLabel = (status: number) => {
    if (status === postCheckPassed) return OrderStatusUiEnum.PostCheckPassed;
    if (status === acceptedByCustomer) return OrderStatusUiEnum.AcceptedByCustomer;
    if (status === invoicing) return OrderStatusUiEnum.Invoicing;
    return OrderStatusUiEnum.Completed;
  };

  const info = [
    { label: 'Order No', value: item.orderNumber || '-' },
    { label: 'Customer name', value: item.customerName || '-' },
    { label: 'Customer reference No', value: item.customerReferenceNumber || '-' },
  ];
  const price = [
    { label: 'Total gross', value: toPoundCurrency(item.totalGross) },
    { label: 'To be invoiced', value: toPoundCurrency(item.toBeInvoicedGross) },
    { label: 'Invoiced', value: toPoundCurrency(item.invoicedGross) },
  ];
  const entities = [
    { label: 'Draft', value: item.draftInvoiceDocumentsCount },
    { label: 'Exported', value: item.exportedInvoiceDocumentsCount },
  ];

  return (
    <InvoicingDashboardLine
      to={`/${routesPath.INVOICING}/${InvoicingPageParamsEnums.SALES}/${item.id}`}
      permission={PermissionEnum.InvoicingSalesViewPageWithInvoices}
      info={info}
      price={price}
      entities={entities}
      invoicingType="sales"
      status={getStatusLabel(item.status)}
    />
  );
};

export default InvoicingSalesItem;
