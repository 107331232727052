import React from 'react';
import './SkeletonPartsKits.scss';

type SkeletonPartsKitsProps = {
  count?: number,
}
const SkeletonPartsKits:React.FC<SkeletonPartsKitsProps> = ({ count = 5 }) => {
  return (
    <ul className="skeletonPartsKits">
      {Array(count).fill(null).map((el, i) => (
        <li key={i} className="skeletonPartsKits__item">
          <span className="skeletonPartsKits__img skeleton-box" />
          <span className="skeletonPartsKits__text">
            <span className="skeletonPartsKits__title skeleton-box" />
            <span className="skeletonPartsKits__description skeleton-box" />
          </span>
          <span className="skeletonPartsKits__category">
            <span className="skeletonPartsKits__category-text skeleton-box" />
          </span>
        </li>
      ))}
    </ul>
  );
};

export default SkeletonPartsKits;
