import React, { useLayoutEffect, useRef, useState } from 'react';
import { Pagination as PaginationComponent } from 'antd';
import './Pagination.scss';
import classNames from 'classnames';

type PaginationProps = {
  size?: 'default' | 'small';
  pageSize?: number;
  currentPage?: number;
  totalItems: number;
  onChange?: (page: number, pageSize: number) => void;
  onShowSizeChange?: (current: number, size: number) => void;
  showSizeChanger?: boolean;
  showQuickJumper?: boolean;
  pageSizeOptions?: string[];
  showLessItems?: boolean;
  className?: string;
  hideOnSinglePage?: boolean;
  showTotal?: boolean;
  narrow?: boolean;
  offsetBottom?: boolean;
}

const Pagination: React.FC<PaginationProps> = ({
  size = 'default',
  pageSize,
  currentPage,
  totalItems,
  onChange,
  onShowSizeChange,
  showSizeChanger = true,
  showQuickJumper,
  pageSizeOptions,
  className,
  hideOnSinglePage,
  showTotal = true,
  narrow = false,
  showLessItems = true,
  offsetBottom,
}) => {
  const [extraWidth, setExtraWidth] = useState(0);
  const paginationRef = useRef<null | HTMLDivElement>(null);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => {
    if (narrow && paginationRef.current) {
      let extraWidth = 0;
      const buttons = paginationRef.current.querySelectorAll('.ant-pagination-jump-prev, .ant-pagination-jump-next, .ant-pagination-item');
      // eslint-disable-next-line no-return-assign
      buttons.forEach((item) => extraWidth += item.clientWidth);
      setExtraWidth(extraWidth / 2);
    }
  });

  const styles = { '--extra-width': `${extraWidth}px` } as React.CSSProperties;

  return (
    <div
      ref={paginationRef}
      style={styles}
      className={classNames(className, 'pagination', {
        'pagination--offset-bottom': offsetBottom,
        'pagination--narrow': narrow,
        'pagination--empty': totalItems === 0,
        'pagination--no-size-changer': !showSizeChanger,
      })}
    >
      <PaginationComponent
        size={size}
        pageSize={pageSize}
        total={totalItems}
        current={currentPage}
        onChange={onChange}
        showSizeChanger={showSizeChanger}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={pageSizeOptions}
        showLessItems={showLessItems}
        showTotal={(total, range) => showTotal && `${range[0]}-${range[1]} of ${total} Rows`}
        showQuickJumper={showQuickJumper}
        hideOnSinglePage={hideOnSinglePage}
      />
    </div>
  );
};

export default Pagination;
