import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { PartsKitsDto } from '../../../../dashboard/types/partsKitsTypes';
import {
  getPartsKitAffectedThunk,
  getPartsKitAvailableCategoriesThunk,
} from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import PartsKitInfoForm from './PartsKitInfoForm';
import { setAffectedItemsPaging } from '../../../../../../store/slices/partsKitSlice';
import {
  partsKitAffectedItemsFiltersSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import { PartsKitDefaults, PartsKitFields } from '../../../utils/PartsKitDetailsSchema';

type PartKitsInfoProps = {
  partsKitDetails: PartsKitsDto | null,
}

const PartKitsInfo: React.FC<PartKitsInfoProps> = ({ partsKitDetails }) => {
  const dispatch = useAppDispatch();
  const affectedItemsFilters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const [categorySearchV, setCategorySearchV] = useState<string | undefined>(undefined);

  const {
    control, setValue, setError, clearErrors,
  } = useForm<PartsKitFields>({
    defaultValues: PartsKitDefaults,
    mode: 'all',
  });

  const fieldsToValidate = ['name', 'description', 'partsKitCode', 'categoryId', 'businessAreas'];

  useEffect(() => {
    if (partsKitDetails) {
      Object.keys(partsKitDetails).forEach((key: string) => {
        if (fieldsToValidate.includes(key)) {
          if (key === 'suitableForVehicles') {
            setValue('suitableForVehicleIds', partsKitDetails.suitableForVehicles?.map((el) => el.id) || []);
          } else {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            setValue(key, partsKitDetails[key]);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [partsKitDetails]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (categorySearchV) {
      const timeOutId = setTimeout(() => {
        dispatch(getPartsKitAvailableCategoriesThunk({
          keyword: categorySearchV.trim(),
        }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getPartsKitAvailableCategoriesThunk({
        keyword: undefined,
      }));
    }
    // eslint-disable-next-line
    }, [categorySearchV]);

  return (
    <section className="kitsDetailsWrap__details info-grid__column">
      <h2 className="info-grid__title">Details</h2>
      <PartsKitInfoForm
        control={control}
        setError={setError}
        setValue={setValue}
        setCategorySearchV={setCategorySearchV}
        clearErrors={clearErrors}
        affectedHandle={(action: VoidFunctionType, dataToAffect: number[]) => {
          const filters = { ...affectedItemsFilters, businessAreas: dataToAffect };
          dispatch(setAffectedItemsPaging(filters));
          partsKitDetails && dispatch(getPartsKitAffectedThunk({
            id: partsKitDetails.id,
            filters,
            complexAction: () => {
              setValue('businessAreas', partsKitDetails.businessAreas || []);
            },
            confirmAction: action,
          }));
        }}
        fieldsToValidate={fieldsToValidate}
      />
    </section>
  );
};

export default PartKitsInfo;
