import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  fitterReturnsFiltersDateSelector,
  fitterReturnsFiltersSelector,
  fitterReturnsGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import {
  DateRangeTableFilterType, initDataList, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import {
  setFitterReturnsReportData,
  setFitterReturnsReportFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { useFitterReturnsReportDefaultPaging } from '../../../../hooks/useFitterReturnsReportDefaultPaging';
import { getFitterReturnsReportEntityType } from '../../../../../store/thunks/coreThunk';
import {
  getPurchasingFitterReturnsReportExcelThunk,
  getPurchasingFitterReturnsReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';

const FitterReturnsGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fitterReturnsFiltersSelector);
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    partName, partCode, page, pageSize, order,
    deletionDate,
    ...rest
  } = filters;
  const checkboxFilters = useAppSelector(fitterReturnsGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(fitterReturnsFiltersDateSelector);
  const isSelectedGlobalFilters = useMemo(() => {
    const hasSelectedCheckboxes = [rest].some((el) => !!el && !!(el as number[]).length);
    const hasSelectedDatepicker = deletionDate && (deletionDate.min || deletionDate.max);
    return hasSelectedCheckboxes || hasSelectedDatepicker;
  }, [deletionDate, rest]);

  const defaultPagingAndOrdering = useFitterReturnsReportDefaultPaging();

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { deletionDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      partCode: undefined,
      partName: undefined,
      page: 1,
      [field]: values || undefined,
    };
    dispatch(setFitterReturnsReportFilters(newFilters));
    dispatch(setFitterReturnsReportData(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setFitterReturnsReportFilters({
      ...defaultPagingAndOrdering,
      ...initPaging,
    }));
    dispatch(setFitterReturnsReportData(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      partName, partCode, page, pageSize,
      ...rest
    } = filters;
    dispatch(getPurchasingFitterReturnsReportExcelThunk({ filters: rest }));
  };

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getFitterReturnsReportEntityType());
    return () => {
      dispatch(setFitterReturnsReportFilters({ ...initPaging, ...defaultPagingAndOrdering }));
      dispatch(setFitterReturnsReportData(initDataList));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={(values, field) => {
          dispatch(setFitterReturnsReportFilters({
            ...filters,
            partCode: undefined,
            partName: undefined,
            page: 1,
            [field]: values,
          }));
          dispatch(setFitterReturnsReportData(initDataList));
          dispatch(setReportIsVisible(false));
        }}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getPurchasingFitterReturnsReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default FitterReturnsGlobalFilters;
