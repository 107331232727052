import React, { useEffect } from 'react';
import {
  Controller, SubmitHandler, useForm, useFormState,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import RegularInputNumber from '../../../../core/components/input-number/RegularInputNumber';
import Select from '../../../../core/components/select/Select';
import Textarea from '../../../../core/components/textarea/Textarea';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { NominalCodeFields, NominalCodeFieldsScheme } from '../../types/nominalCodesTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isFetchingSelector, nominalCodeTypesSelector } from '../../../../store/selectors/coreSelectors';
import { nominalCodesFiltersSelector, nominalCodesTableUiSelector } from '../../../../store/selectors/invoicingSelectors';
import { createNominalCodeThunk, editNominalCodeThunk } from '../../../../store/thunks/invoicing/invoicingNominalCodesThunks';
import { maxCommonWhole } from '../../../../core/utils/regex';

type NominalCodeModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  idToEdit: number | null,
}
const NominalCodeModal: React.FC<NominalCodeModalProps> = ({ visible, onClose, idToEdit }) => {
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(nominalCodesTableUiSelector);
  const filters = useAppSelector(nominalCodesFiltersSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const types = useAppSelector(nominalCodeTypesSelector);
  const {
    control, handleSubmit, setValue, setError, reset,
  } = useForm<NominalCodeFields>({
    resolver: zodResolver(NominalCodeFieldsScheme),
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const onSubmit: SubmitHandler<NominalCodeFields> = (values) => {
    if (idToEdit) {
      dispatch(editNominalCodeThunk({
        id: idToEdit, data: values, closeModal: onClose, setError,
      }));
    } else {
      dispatch(createNominalCodeThunk({
        data: values, closeModal: onClose, setError,
      }));
    }
  };

  useEffect(() => {
    if (visible) {
      if (idToEdit) {
        const found = items.find((el) => el.id === idToEdit);
        if (found) {
          setValue('code', found.code);
          setValue('type', found.typeId);
          setValue('description', found.description);
        }
      } else {
        if (filters.types?.[0]) {
          setValue('type', filters.types[0]);
        }
      }
    }
  }, [idToEdit, items, setValue, visible, filters.types]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={`${idToEdit ? 'Edit' : 'Create new'} nominal code`}
      destroyOnClose
      afterClose={reset}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="nominalCodeForm">
        <Controller
          control={control}
          name="type"
          rules={{ required: ErrorsEnum.REQUIRED }}
          render={({ field }) => (
            <Select
              label="Type"
              value={field.value}
              onChange={field.onChange}
              options={types}
              error={errors.type?.message}
              parentRender
            />
          )}
        />
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <RegularInputNumber
              label="Code"
              value={field.value}
              onChange={field.onChange}
              error={errors.code?.message}
              max={maxCommonWhole}
              multipleLeadingZero
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              label="Description"
              value={field.value}
              onChange={field.onChange}
              error={errors.description?.message}
            />
          )}
        />
        <ButtonActions
          createLabel={idToEdit ? 'Save' : 'Create'}
          cancelClick={onClose}
          createType="submit"
          isLoading={isFetching}
          offsetTop
        />
      </form>
    </Modal>
  );
};

export default NominalCodeModal;
