import React from 'react';
import { UserEntityTimelineEvent, UserTimelineEvent } from '../../../types/activityTypes';
import OrderEvent from '../../../../../orders/orders-view-page/components/content/details/order-details-description/OrderEvent';
import { OrderEventDtoBase } from '../../../../../orders/orders-view-page/types/OrderViewPageTypes';
import UserEventText from './UserEventText';
import CustomerEvent
  // eslint-disable-next-line max-len
  from '../../../../../customers/customer-view-page/components/customer-dynamic-section/details&activity/customer-events-description/CustomerEvent';
import PricelistEvent
  // eslint-disable-next-line max-len
  from '../../../../../pricelists/pricelist-view-page/components/pricelist-content/details-and-activity/pricelist-event-description/PricelistEvent';
import RectificationEvent
  // eslint-disable-next-line max-len
  from '../../../../../rectifications/rectification-view-page/components/rectification-content/details/rectification-events-description/RectificationEvent';
import VehicleEvent
  // eslint-disable-next-line max-len
  from '../../../../../vehicles/vehicle-view-page/components/vehicle-content/vehicle-details-and-activity-section/vehicle-events/VehicleEvent';
import SupplierEvent
  // eslint-disable-next-line max-len
  from '../../../../../supplier/supplier-view-page/components/supplier-content/details-and-activity/supplier-actitvity-events/SupplierEvent';
import { CustomerActivityEventDto } from '../../../../../customers/customer-view-page/types/customerVewPageTypes';
import { RectificationEventDtoBase } from '../../../../../rectifications/rectification-view-page/types/rectificationViewTypes';
import { JobEventDtoBase } from '../../../../../job/job-view-page/types/jobViewTypes';
import JobEvent from '../../../../../job/job-view-page/components/job-content/details/job-event-description/JobEvent';
import EmployeeEvent from '../../../../../employees/employee-view-page/components/dynamic-area/components/activity-log/EmployeeEvent';
import { EmployeeActivityEventType } from '../../../../../employees/employee-view-page/types/employeeViewTypes';
import { PartViewEventDtoBase } from '../../../../../stock/part-view-page/types/types';
import { PartsKitEventDtoBase } from '../../../../../stock/parts-kits/view-page/types/partsKitViewPageTypes';
import { PurchaseOrderEventDtoBase } from '../../../../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import { PricelistEventDtoBase } from '../../../../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import { StockPartRequestEventDtoBase } from '../../../../../stock/stock-part-request-view/types/StockPartrequestViewTypes';
import { EnquiryEventDtoBase } from '../../../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { SupplierEventDtoBase } from '../../../../../supplier/supplier-view-page/types/supplierViewPageTypes';
import { VehicleActivityEventDto } from '../../../../../vehicles/vehicle-view-page/types/VehicleViewTypes';
import EnquiryEvent from '../../../../../enquiry/enquiry-detailed/components/enquiry-content/details&activity/EnquiryEvent';
import StockPartRequestEvent
  from '../../../../../stock/stock-part-request-view/components/modals/activity/part-request-event-description/StockPartRequestEvent';
import PurchaseOrderEvent from '../../../../../stock/purchase-orders/view-page/components/content/details-activity/PurchaseOrderEvent';
import PartViewActivityEvent from '../../../../../stock/part-view-page/components/dynamic-area/components/activity/PartViewActivityEvent';
import PartsKitActivityEvent
  from '../../../../../stock/parts-kits/view-page/components/content/details-activity/parts-kit-activity-events/PartsKitActivityEvent';
import UserEntityEvent from './UserEntityEvent';

type UserEventProps = {
  item: UserTimelineEvent;
}

const UserEvent: React.FC<UserEventProps> = ({ item }) => {
  const {
    entityType, entityName, entityId, ...rest
  } = item;

  const getEntityEvent = () => {
    switch (item.entityType) {
      case ('Customer'):
        return <CustomerEvent item={rest as CustomerActivityEventDto} />;
      case ('Order'):
        return <OrderEvent item={rest as OrderEventDtoBase} />;
      case ('Rectification'):
        return <RectificationEvent item={rest as RectificationEventDtoBase} />;
      case ('Job'):
        return <JobEvent item={rest as JobEventDtoBase} />;
      case ('Employee'):
        return <EmployeeEvent item={rest as EmployeeActivityEventType} />;
      case ('Part'):
        return <PartViewActivityEvent item={rest as PartViewEventDtoBase} />;
      case ('Parts kit'):
        return <PartsKitActivityEvent item={rest as PartsKitEventDtoBase} />;
      case ('Stock purchase order'):
      case ('Invoicing purchase order'):
        return <PurchaseOrderEvent item={rest as PurchaseOrderEventDtoBase} />;
      case ('Pricelist'):
        return <PricelistEvent item={rest as PricelistEventDtoBase} />;
      case ('Part request'):
        return <StockPartRequestEvent item={rest as StockPartRequestEventDtoBase} />;
      case ('Enquiry'):
        return <EnquiryEvent item={rest as EnquiryEventDtoBase} />;
      case ('Supplier'):
        return <SupplierEvent item={rest as SupplierEventDtoBase} />;
      case ('Vehicle'):
        return <VehicleEvent item={rest as VehicleActivityEventDto} />;
      case ('User account'):
        return <UserEntityEvent item={rest as UserEntityTimelineEvent} />;
      default: return null;
    }
  };

  return (
    <div>
      <UserEventText entityInfo={{ entityType, entityName, entityId }} />
      {getEntityEvent()}
    </div>
  );
};

export default UserEvent;
