import React from 'react';
import './VehicleSkeletonItem.scss';

const VehicleSkeletonItem: React.FC = () => {
  return (
    <div className="vehicleSkeletonItem skeleton-item">
      <div className="vehicleSkeletonItem__main-wrapper">
        <div className="vehicleSkeletonItem__main" />
      </div>
      <div className="vehicleSkeletonItem__info-wrapper">
        <div className="vehicleSkeletonItem__info" />
        <div className="vehicleSkeletonItem__info" />
        <div className="vehicleSkeletonItem__info" />
      </div>
      <div className="vehicleSkeletonItem__tag-wrapper">
        <div className="vehicleSkeletonItem__tag skeleton-line" />
      </div>
    </div>
  );
};

export default VehicleSkeletonItem;
