export enum SalesUrlParamsNames {
  PAGE = 'page',
  TAB_TYPE = 'tab_type',
  ORDER_STATUS = 'order_status',
}

export enum SalesAllStatusEnum { ALL = 100 }

export enum SalesViewTabEnum {
  ORDER_LINES = 'order-lines',
  INVOICES_AND_CREDIT_NOTES = 'invoices-and-credit-notes'
}

export const SalesViewTabEnumList = [
  SalesViewTabEnum.ORDER_LINES,
  SalesViewTabEnum.INVOICES_AND_CREDIT_NOTES,
];
