import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { ReportTableColumns } from '../../../../../types/RectificationReportsTypes';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, salesByCustomerFiltersSelector } from '../../../../../../store/selectors/reportsSelectors';
import { SearchTableFilterType } from '../../../../../../core/types/coreTypes';
import { setSalesByCustomerFilters } from '../../../../../../store/slices/reportsSlice';
import { getSalesByCustomerReportThunk } from '../../../../../../store/thunks/reports/reportsThunks';
import { useSalesReportByCustomerRanges } from '../../../../../hooks/useSalesReportByCustomerRanges';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';

const SalesReportByCustomerColumns: React.FC<ReportTableColumns> = ({ columns, totalCount }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(salesByCustomerFiltersSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    entityNumber: filters.entityNumber,
  });

  const { range, rangePatterns, setRange } = useSalesReportByCustomerRanges();

  const sortHandle = (field: number) => {
    const newFilters = {
      ...filters,
      order: filters.order?.field === field
        ? { field: filters.order.field, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    };
    dispatch(setSalesByCustomerFilters(newFilters));
    dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = { ...filters, [field]: undefined };
      dispatch(setSalesByCustomerFilters(newFilters));
      dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = { ...filters, [field]: searchVl[field]?.trim() };
    dispatch(setSalesByCustomerFilters(newFilters));
    dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const [min, max] = value;
    const newFilters = {
      ...filters,
      [field]: { min, max },
    };
    dispatch(setSalesByCustomerFilters(newFilters));
    dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            searchVl={searchVl}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            setRangeHandle={setRangeHandle}
            range={range}
            rangePatterns={rangePatterns}
            setRange={setRange}
            totalElements={totalCount}
            className={`salesReportByCustomerTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
          />
        ))}
        <ThNew action>
          <Dropdown
            disabled
            // disabled={!totalCount} //TODO MDA: uncomment when BE is ready
            menuItems={[
              {
                label: 'Export to Excel',
                icon: <FontAwesomeIcon icon={faFileExcel} />,
                key: 'export-to-excel',
                onClick: () => console.log('export to excel'),
              },
            ]}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default SalesReportByCustomerColumns;
