import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fullStockReportFiltersSelector } from '../../store/selectors/reportsSelectors';
import { fullStockReportOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getFullStockReportOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { FullStockReportOrdering } from '../enum/ReportTableOrders';
import { setFullStockReportFilters } from '../../store/slices/reportsSlice';
import { initPaging } from '../../core/types/coreTypes';

export const useFullStockReportDefaultOrdering = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fullStockReportFiltersSelector);
  const ordering = useAppSelector(fullStockReportOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    ordering.length === 0 && dispatch(getFullStockReportOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters?.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === FullStockReportOrdering.Category)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setFullStockReportFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: true } : filters?.order,
  };
};
