import instance from '../axiosConfig';
import { AxiosDictionaryResponse } from '../../core/types/coreTypes';

export const coreStatusesAPI = {
  fetchEnquiryStatus(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/enquiryStatuses');
  },
  fetchCustomerStatuses(): AxiosDictionaryResponse {
    return instance.get('/customer/app/customerStatuses');
  },
  fetchAccountAccountStatus(): AxiosDictionaryResponse {
    return instance.get('/account/app/accountStatus');
  },
  fetchQuotePricelistStatuses(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistStatuses');
  },
  fetchInfoMessageStatuses(): AxiosDictionaryResponse {
    return instance.get('/account/app/infoMessageStatus');
  },
  fetchPricelistStatuses(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistStatuses');
  },
  fetchPartsKitsStatuses(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsKitsStatuses');
  },
  fetchOrderStatus(): AxiosDictionaryResponse {
    return instance.get('/order/app/orderStatus');
  },
  fetchJobStatuses(): AxiosDictionaryResponse {
    return instance.get('/job/app/jobStatuses');
  },
  fetchPartRequestLineStatuses(): AxiosDictionaryResponse {
    return instance.get('/order/app/partRequestLineStatuses');
  },
  fetchInAppNotificationsStatuses(): AxiosDictionaryResponse {
    return instance.get('/account/app/notificationStatuses');
  },
  fetchVehicleStatuses(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleStatuses');
  },
  fetchRequestSubmissionStatuses(): AxiosDictionaryResponse {
    return instance.get('/order/app/requestSubmissionStatuses');
  },
  fetchStockPartRequestStatuses(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestStatuses');
  },
  fetchEmployeeStatuses(): AxiosDictionaryResponse {
    return instance.get('/employee/app/statuses');
  },
  fetchRectificationStatuses(): AxiosDictionaryResponse {
    return instance.get('/rectification/app/rectificationStatuses');
  },
  fetchPurchaseOrderStatus(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderStatus');
  },
  fetchStockPartsStatuses(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partStockStatuses');
  },
  fetchQuantitiesStatuses(): AxiosDictionaryResponse {
    return instance.get('/stock/app/quantityStatuses');
  },
  fetchStockPartRequestLineStatusTransition(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestLineStatusTransition');
  },
  fetchLeaveRequestStatuses(): AxiosDictionaryResponse {
    return instance.get('/employee/app/leaveRequestStatuses');
  },
  fetchInvoiceDocumentStatuses(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/invoiceDocumentStatuses');
  },
  fetchSupplierStatuses(): AxiosDictionaryResponse {
    return instance.get('/supplier/app/supplierStatuses');
  },
  fetchJobQualityCheckStatuses(): AxiosDictionaryResponse {
    return instance.get('/job/app/jobQualityCheckStatuses');
  },
};
