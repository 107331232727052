import React, { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Modal from '../../../core/components/modal/Modal';
import { initSmallPaging, VoidFunctionType } from '../../../core/types/coreTypes';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { PartRequestLineDto } from '../../types/commonTypes';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../core/components/table-components/Tbody';
import Pagination from '../../../core/components/pagination/Pagination';
import { DEFAULT_UI_DATE_FORMAT, SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { partQtyStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import {
  setPurchaseOrderViews,
  setReadyCollectedPartQuantities,
  setReadyCollectedPartQuantitiesFilters,
} from '../../../store/slices/sharedSlice';
import { adjustPartQtyOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { PartAdjustQtyOrderingEnum } from '../../../stock/part-view-page/enums/PartTypesEnum';
import { QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../core/utils/labelHandle';
import { toPoundCurrency } from '../../utils/formatUtils';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import {
  readyCollectedPartQuantitiesColumnsSelector,
  readyCollectedPartQuantitiesFiltersSelector,
  readyCollectedPartQuantitiesSelector,
} from '../../../store/selectors/sharedSelectors';
import { getReadyCollectedPartQuantities } from '../../../store/thunks/shared/sharedThunks';
import './ReadyCollectedPartsListModal.scss';
import ViewPageLink from '../view-page-link/ViewPageLink';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import { useDefaultPagingAndOrdering } from '../../../core/hooks/useDefaultPagingAndOrdering';
import { getQuantitiesOrdering } from '../../../store/thunks/core/coreOrderingThunks';

type ReadyCollectedPartsListModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  editedLineData?: PartRequestLineDto,
}

const ReadyCollectedPartsListModal: React.FC<ReadyCollectedPartsListModalProps> = ({
  visible, onCancel, editedLineData,
}) => {
  const hiddenKeys = ['id', 'purchaseOrderId'];
  const dispatch = useAppDispatch();
  const partQtyOrdering = useAppSelector(adjustPartQtyOrderingSelector);
  const filters = useAppSelector(readyCollectedPartQuantitiesFiltersSelector);
  const partQuantities = useAppSelector(readyCollectedPartQuantitiesSelector);
  const columns = useAppSelector(readyCollectedPartQuantitiesColumnsSelector);
  const measures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const { items, totalCount } = partQuantities;
  const { collected, archived, picked } = useAppSelector(partQtyStatusesValuesSelector);
  const [purchases, setPurchases] = useState<(number | string)[]>([]);

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters,
    initialPaging: initSmallPaging,
    ordering: partQtyOrdering,
    getOrderingThunk: getQuantitiesOrdering,
    initialOrdering: PartAdjustQtyOrderingEnum.AddedDate,
    setFiltersAction: setReadyCollectedPartQuantitiesFilters,
  });

  useEffect(() => {
    if (editedLineData && visible) {
      const purchaseOrdersValues = purchases.map((v) => {
        if (v === 'null') {
          return null;
        } else return v;
      });
      const partId = editedLineData.partId || editedLineData.partsKitComponentPartId;
      partId
      && collected
      && archived
      && picked
      && dispatch(getReadyCollectedPartQuantities({
        params: {
          ...filters,
          partId,
          statuses: filters.statuses ? filters.statuses : [collected, archived, picked],
          purchaseOrderIds: purchaseOrdersValues.length > 0 ? purchaseOrdersValues : undefined,
          partRequestLineId: editedLineData.submittedLineId || undefined,
        },
      }));
    }
    // eslint-disable-next-line
  }, [visible, filters, editedLineData, collected, archived]);

  const cancelHandle = () => {
    onCancel();
    dispatch(setReadyCollectedPartQuantities(null));
    dispatch(setReadyCollectedPartQuantitiesFilters(defaultPagingAndOrdering));
    dispatch(setPurchaseOrderViews([]));
    setPurchases([]);
  };

  const renderValueHandle = (key: string, value: string | number | null, purchaseOrderId: number | null) => {
    if (key === 'quantity') return `${value} ${labelHandle(editedLineData?.unitOfMeasure, measures)}`;
    if (key === 'costPrice') return toPoundCurrency(value);
    if (key === 'addedDate' || key === 'collectionDate') {
      return value ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, value as string) : '-';
    }
    if (key === 'purchaseOrderNo') {
      return <ViewPageLink
        entityType="Stock purchase order"
        entityId={purchaseOrderId}
      >
        <TruncateTooltip value={value} table />
      </ViewPageLink>;
    }
    if (key === 'owner') return <TruncateTooltip value={value} table />;
    return value;
  };

  const sortHandle = (field: number) => {
    dispatch(setReadyCollectedPartQuantitiesFilters({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    }));
  };

  const checkboxFilters = [
    {
      dataIndex: 'status',
      value: filters.statuses || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        dispatch(setReadyCollectedPartQuantitiesFilters({
          ...filters,
          statuses: checkedValues as number[],
          page: 1,
        }));
      },
    },
    {
      dataIndex: 'purchaseOrderNo',
      value: purchases,
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        dispatch(setReadyCollectedPartQuantitiesFilters({
          ...filters,
          page: 1,
        }));
        setPurchases(checkedValues as number[]);
      },
    },
    {
      dataIndex: 'owner',
      value: filters.ownerIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        dispatch(setReadyCollectedPartQuantitiesFilters({
          ...filters,
          ownerIds: checkedValues as number[],
          page: 1,
        }));
      },
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Ready/collected part list"
      width={1100}
    >
      <strong className="readyCollectedPartsListName">{editedLineData?.productName}</strong>
      <Pagination
        currentPage={filters.page}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        pageSize={filters.pageSize}
        totalItems={totalCount}
        onChange={(page, pageSize) => dispatch(setReadyCollectedPartQuantitiesFilters({ ...filters, page, pageSize }))}
        offsetBottom
      />
      <TableNew>
        <Thead>
          <Tr>
            {columns.map((col) => <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalCount}
              className={`readyCollectedPartsListTh--${col.dataIndex}`}
              sortHandle={sortHandle}
              disabledFilter={totalCount === 0}
              tableFilters={filters}
              filters={checkboxFilters}
              insideModal
            />)}
          </Tr>
        </Thead>
        <Tbody>
          {totalCount === 0
            ? <EmptyTableSection text="No records" colSpan={columns.length} />
            : <>
              {items.map((qty, i) => <Tr key={i}>
                {Object.entries(qty).map(([key, value]) => {
                  if (hiddenKeys.includes(key)) return null;
                  return <TdNew>{renderValueHandle(key, value, qty.purchaseOrderId)}</TdNew>;
                })}
              </Tr>)}
            </>}
        </Tbody>
      </TableNew>
    </Modal>
  );
};

export default ReadyCollectedPartsListModal;
