import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCar, faCirclePlus, faEdit, faLink,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  orderCatalogVehicleProfileFiltersSelector,
  orderCatalogVehicleProfilesSelector,
  orderDetailsVehicleSelector,
  orderVehUiDataSelector,
  partRequestLinesSelector,
} from '../../../../../store/selectors/ordersSelector';
import { vehicleStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { patchOrderThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import VehicleModal from '../../../../../common/components/vehicle-modal/VehicleModal';
import { setOrderCatalogVehicleProfileFilters } from '../../../../../store/slices/ordersSlice';
import { routesPath } from '../../../../../core/enums/pathEnum';
import LinkVehicleModal from '../../../../../common/components/link-vehicle-modal/LinkVehicleModal';
import { initVehiclesDashboardFilters } from '../../../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { orderDetailsSelector } from '../../../../../store/selectors/sharedSelectors';
import { vehiclesListInitSelector } from '../../../../../store/selectors/vehiclesSelectors';
import { currentUserPermissionsSelector } from '../../../../../store/selectors/accountSelectors';
import { AddVehicleRequest } from '../../../../../common/types/commonTypes';
import { vehicleCatalogSourceSelector } from '../../../../../store/selectors/coreSelectors';
import { useLocationHistoryState } from '../../../../../core/hooks/useLocationHistoryState';

type OrderVehicleSectionProps = {
  isCurrentStatusForbiddenToLinkVeh: boolean,
  isCurrentStatusForbiddenToEditVeh: boolean,
  isStatusAllowToEditVehRegNoAndChassisNo: boolean,
}

const OrderVehicleSection: React.FC<OrderVehicleSectionProps> = ({
  isCurrentStatusForbiddenToEditVeh,
  isCurrentStatusForbiddenToLinkVeh,
  isStatusAllowToEditVehRegNoAndChassisNo,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isConfirmLinking, setConfirmLinking] = useState<boolean>(false);
  const [vehAwaitingConfirmation, setVehAwaitingConfirmation] = useState<number | undefined>(undefined);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const vehicle = useAppSelector(orderDetailsVehicleSelector);
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);
  const { items: partRequestLines } = useAppSelector(partRequestLinesSelector);
  const vehiclesList = useAppSelector(vehiclesListInitSelector);
  const orderCatalogVehicleProfileFilters = useAppSelector(orderCatalogVehicleProfileFiltersSelector);
  const orderCatalogVehicleProfiles = useAppSelector(orderCatalogVehicleProfilesSelector);
  const currentUserPermissions = useAppSelector(currentUserPermissionsSelector);
  const vehicleCatalogSource = useAppSelector(vehicleCatalogSourceSelector);

  const { setLocationHistoryHandle } = useLocationHistoryState();
  const { pathname, search } = useLocation();

  const {
    vehicleId, name, info, specificInfo,
  } = useAppSelector(orderVehUiDataSelector);

  const vehicleStatus = useMemo(() => vehicleStatuses.find((el) => vehicle?.vehicleStatus === el.value)?.label || '-', [
    vehicleStatuses,
    vehicle,
  ]);

  const [isVehModal, setIsVehModal] = useState<boolean>(false);
  const [linkModal, setLinkModal] = useState<boolean>(false);
  const [editMode, setEditMode] = useState(false);
  const [isEditConfirmation, setIsEditConfirmation] = useState<boolean>(false);

  const cancelVehModal = () => {
    setEditMode(false);
    setIsVehModal(false);
  };
  const editVeh = useCallback((data: Partial<AddVehicleRequest>, setDefaultHandle: VoidFunctionType) => {
    if (isCurrentStatusForbiddenToEditVeh && isStatusAllowToEditVehRegNoAndChassisNo) {
      const finalData = {
        regNumber: data.regNumber,
        chassisNumber: data.chassisNumber,
      };
      id && dispatch(patchOrderThunk({
        id: +id,
        data: finalData,
        onClose: () => {
          setDefaultHandle();
          cancelVehModal();
        },
      }));
    } else {
      const commonFields = {
        vehicleInfoSource: data.vehicleInfoSource,
        slidingDoorsQuantity: data.slidingDoorsQuantity,
        grossVehicleWeightKg: data.grossVehicleWeightKg,
        maxPayload: data.maxPayload,
        regNumber: data.regNumber,
        chassisNumber: data.chassisNumber,
      };
      const finalData = data.vehicleInfoSource === vehicleCatalogSource?.value
        ? { ...commonFields, vehicleProfileId: data.vehicleProfileId }
        : {
          ...commonFields,
          vehicleProfileGroup: data.group || undefined,
          manufacturer: data.manufacturer,
          modelDescription: data.modelDescription,
          vehicleProfileType: data.type || undefined,
          vehicleProfileId: null,
        };
      id && dispatch(patchOrderThunk({
        id: +id,
        data: finalData,
        onClose: () => {
          setDefaultHandle();
          cancelVehModal();
        },
      }));
    }
    // eslint-disable-next-line
  }, [id, isCurrentStatusForbiddenToEditVeh, isStatusAllowToEditVehRegNoAndChassisNo, vehicleCatalogSource?.value]);

  const actionOptions = useMemo(() => {
    const onCreateVehicleClick = () => {
      setLocationHistoryHandle({ pathname, search });
      orderDetails && navigate(`/${routesPath.CREATE_VEHICLE}`, {
        state: {
          orderId: orderDetails.order.id,
          customerId: orderDetails.order.customerId,
          infoSource: orderDetails.order.vehicleInfoSource,
          manufacturer: orderDetails.order.manufacturer,
          type: orderDetails.order.vehicleProfileType,
          group: orderDetails.order.vehicleProfileGroup,
          modelDescription: orderDetails.order.modelDescription,
          grossVehicleWeightKg: orderDetails.order.grossVehicleWeightKg,
          maxPayload: orderDetails.order.maxPayload,
          chassisNumber: orderDetails.order.chassisNumber,
          regNumber: orderDetails.order.regNumber,
          slidingDoorsQuantity: orderDetails.order.slidingDoorsQuantity,
          vehicleProfileId: orderDetails.order.vehicleProfileId,
          vehicleProfileCode: orderDetails.order.vehicleProfileCode,
        },
      });
    };
    const vehWorkAroundList = vehicle?.vehicleStatus
      ? [{
        key: 'openVeh',
        label: 'Open vehicle page',
        icon: <FontAwesomeIcon icon={faCar} />,
        onClick: () => navigate(`/${routesPath.VEHICLES}/${vehicleId}`),
        restrictedTo: PermissionEnum.VehicleViewPage,
        disabled: false,
      }]
      : [{
        key: 'create-vehicle',
        label: 'Create vehicle',
        icon: <FontAwesomeIcon icon={faCirclePlus} />,
        onClick: onCreateVehicleClick,
        disabled: isCurrentStatusForbiddenToEditVeh,
        restrictedTo: PermissionEnum.VehicleCreate,
      },
      {
        key: 'edit',
        label: 'Edit vehicle draft',
        icon: <FontAwesomeIcon icon={faEdit} />,
        onClick: () => {
          setIsVehModal(true);
          setEditMode(true);
        },
        disabled: isCurrentStatusForbiddenToEditVeh && !isStatusAllowToEditVehRegNoAndChassisNo,
        restrictedTo: PermissionEnum.OrderEditFields,
      }];

    const allOptions = orderDetails?.order.hasAnyLinkedCompletedRectification
      ? [...vehWorkAroundList]
      : [{
        key: 'linkVeh',
        label: 'Link vehicle',
        icon: <FontAwesomeIcon icon={faLink} />,
        onClick: () => {
          setLinkModal(true);
        },
        disabled: isCurrentStatusForbiddenToLinkVeh,
        restrictedTo: PermissionEnum.OrderEditFields,
      },
      ...vehWorkAroundList,
      ];
    return allOptions.filter((option) => currentUserPermissions?.includes(option.restrictedTo));
    // eslint-disable-next-line
  }, [
    currentUserPermissions,
    orderDetails?.order,
    isCurrentStatusForbiddenToLinkVeh,
    isCurrentStatusForbiddenToEditVeh,
    vehicle?.vehicleStatus,
  ]);

  const initReqFilters = {
    ...initVehiclesDashboardFilters,
    pageSize: 5,
  };
  const selectNewVehicleFn = useCallback((vehId: number) => {
    const selectedVehCustomerId = vehiclesList.items?.find((el) => el.id === vehId)?.customerId;
    const orderCustomerId = orderDetails?.order?.customerId;

    if (selectedVehCustomerId !== orderCustomerId) {
      setConfirmLinking(true);
      setVehAwaitingConfirmation(vehId);
    } else {
      id && dispatch(patchOrderThunk({
        id: +id,
        data: {
          vehicleId: vehId,
        },
        onClose: () => {
          setLinkModal(false);
        },
      }));
    }
    // eslint-disable-next-line
  }, [id, orderDetails?.order?.customerId, vehiclesList.items]);

  const differentCustomersText = <>
    Order customer and vehicle customer do not match.
    {' '}
    <b>
      {`"${orderDetails?.order?.customerName || ''}" `}
    </b>
    and
    <b>
      {` "${vehiclesList.items?.find((el) => el.id === vehAwaitingConfirmation)?.customerName}"`}
    </b>
  </>;

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirmLinking}
        onCancel={() => {
          setConfirmLinking(false);
          setVehAwaitingConfirmation(undefined);
        }}
        confirmHandler={() => {
          id && vehAwaitingConfirmation && dispatch(patchOrderThunk({
            id: +id,
            data: {
              vehicleId: vehAwaitingConfirmation,
            },
            onClose: () => {
              setConfirmLinking(false);
              setVehAwaitingConfirmation(undefined);
              setLinkModal(false);
            },
          }));
        }}
        customTitle="Different customers"
        customText={differentCustomersText}
        customProceedText="Do you really want to select this vehicle?"
        customCreateLabel="Yes, proceed"
      />
      <VehicleModal
        isVisible={isVehModal}
        onCancel={() => cancelVehModal()}
        title="Edit vehicle draft"
        editMode={editMode}
        currentVeh={vehicle}
        nonDraftLinesLength={partRequestLines.find((item) => item.partId || item.partsKitId) ? 1 : 0}
        isEditConfirmation={isEditConfirmation}
        setIsEditConfirmation={setIsEditConfirmation}
        width={840}
        editVehThunkHandle={(data, setDefaultHandle) => editVeh(data, setDefaultHandle)}
        catalogVehicleProfilesFilters={orderCatalogVehicleProfileFilters}
        setCatalogVehicleProfilesFilters={(v) => dispatch(setOrderCatalogVehicleProfileFilters(v))}
        vehicleProfiles={orderCatalogVehicleProfiles}
        lineType="part request"
        disabledToEdit={isCurrentStatusForbiddenToEditVeh}
        disabledToEditRegNoAndChassisNo={!isStatusAllowToEditVehRegNoAndChassisNo}
      />
      <LinkVehicleModal
        visible={linkModal}
        onCancel={() => setLinkModal(false)}
        selectNewVehicleFn={selectNewVehicleFn}
        initVehiclesListReqFilters={initReqFilters}
        currentVehicleId={vehicleId}
      />
      <section className="info-grid__column orderVehSectionWrapper">
        <h2 className="info-grid__title">Vehicle</h2>
        <VehicleSection
          name={name}
          info={info}
          status={!vehicle?.vehicleStatus ? 'DRAFT' : vehicleStatus}
          menuItems={actionOptions}
          regNumber={specificInfo.regNumber}
          chassisNumber={specificInfo.chassisNumber}
          vehicleLocation={specificInfo.location}
        />
      </section>
    </>
  );
};

export default OrderVehicleSection;
