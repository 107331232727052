export enum AccountViewModeParams {
  ACCOUNT_DETAILS = 'account-details',
  LEAVE_REQUESTS = 'leave-requests',
}

export enum AccountViewModeLabels {
  ACCOUNT_DETAILS = 'Account details',
  LEAVE_REQUESTS = 'Leave requests',
}

export const accountContentModes = [
  AccountViewModeParams.ACCOUNT_DETAILS,
  AccountViewModeParams.LEAVE_REQUESTS,
];
