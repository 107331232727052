import React from 'react';
import CopyLineTo from './CopyLineTo';
import Modal from '../../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { EnquiryQuoteVehicleDto } from '../../../../types/enquiryDetailsTypes';

type CopyVehicleLineToModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  vehicle: EnquiryQuoteVehicleDto,
  lineId?: number,
  vehicles?: EnquiryQuoteVehicleDto[],
}

const CopyVehicleLineToModal: React.FC<CopyVehicleLineToModalProps> = ({
  visible, onCancel, vehicle, lineId, vehicles,
}) => {
  return (
    <Modal
      visible={visible}
      width={700}
      onCancel={onCancel}
      title="Choose vehicles to copy line to"
    >
      <CopyLineTo
        vehicles={vehicles?.filter((veh) => veh.id !== vehicle.id)}
        onCloseModal={onCancel}
        enquiryId={vehicle.enquiryId}
        lineId={lineId}
      />
    </Modal>
  );
};

export default CopyVehicleLineToModal;
