import React from 'react';
import {
  Control, Controller, FieldErrors,
} from 'react-hook-form';
import moment from 'moment';
import { RcFile } from 'antd/lib/upload';
import {
  EmailSchema,
  MobileSchema,
  RequiredFieldSchema,
  TelephoneSchema,
} from '../../../core/enums/errorsEnum';
import {
  ALLOWED_IMAGE_FORMATS, DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT,
} from '../../../core/utils/regex';
import Input from '../../../core/components/input/Input';
import DatePickerComponent from '../../../core/components/date-picker/DatePicker';
import { BooleanFunctionType, StringFunctionType } from '../../../core/types/coreTypes';
import Select from '../../../core/components/select/Select';
import AddressField from '../../../common/components/address/AddressField';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { employeesGendersSelector } from '../../../store/selectors/employeesSelector';
import { AddressFields } from '../../../common/types/commonTypes';
import DraggerUpload from '../../../core/components/upload/Dragger';
import { ForbiddenFileExtensions } from '../../../common/utils/forbiddenFileExtensions';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import {
  EmployeeFields,
  NameSchema,
  NationalInsuranceNumberSchema,
  TitleSchema,
} from '../../employee-view-page/utils/EmployeeSchema';

type EmployeeInfoSectionProps = {
  control: Control<EmployeeFields>,
  errors: FieldErrors<EmployeeFields>,
  treatSpacesAsEmptyHandle: (v: string, field: keyof EmployeeFields, required?: boolean,) => void,
  addressFields: AddressFields,
  setAddressModal: BooleanFunctionType,
  fileList: RcFile[],
  setFileList: (value: RcFile[]) => void,
  addressError: string,
  setAddressError: StringFunctionType,
}

const EmployeeInfoSection: React.FC<EmployeeInfoSectionProps> = ({
  control, errors, addressFields, setAddressModal,
  fileList, setFileList, addressError, setAddressError, treatSpacesAsEmptyHandle,
}) => {
  const dispatch = useAppDispatch();
  const employeesGenders = useAppSelector(employeesGendersSelector);
  const beforeUpload = (files: RcFile[]) => {
    const fileName = files[0].name;
    const isBigFile = files[0].size / 1024 / 1024 > 5;
    const currentExt = fileName.substring(fileName.lastIndexOf('.') + 1);
    const imagesExt = ALLOWED_IMAGE_FORMATS.map((el) => el.substring(el.lastIndexOf('/') + 1));
    const forbiddenExt = ForbiddenFileExtensions.includes(`.${currentExt.toUpperCase()}`)
            || !imagesExt.includes(currentExt.toUpperCase());
    if (isBigFile) {
      dispatch(setErrorMessage({ message: `File ${fileName} exceeds the maximum file size (5 MB).` }));
    }
    if (forbiddenExt) {
      dispatch(setErrorMessage({ message: `File ${fileName} has unsupported file format.` }));
    }
    if (isBigFile || forbiddenExt) {
      return false;
    } else {
      setFileList(files);
      return true;
    }
  };

  return (
    <section className="details-form">
      <h2 className="info-grid__title">Employee info</h2>
      <Controller
        name="firstName"
        control={control}
        rules={NameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'firstName');
            }}
            error={errors.firstName?.message}
            label="First name"
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={NameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'lastName');
            }}
            error={errors.lastName?.message}
            label="Last name"
          />
        )}
      />
      <Controller
        name="title"
        control={control}
        rules={TitleSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            error={errors.title?.message}
            label="Title"
          />
        )}
      />
      <Controller
        name="birthday"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <DatePickerComponent
            value={field.value ? moment(field.value) : undefined}
            onChange={(v) => {
              v && field.onChange(v.format(DEFAULT_DATE_FORMAT));
            }}
            label="Date of birth"
            error={errors.birthday?.message}
            format={DEFAULT_UI_DATE_FORMAT}
            disabledDate={(currentDate) => {
              const amountYearsFromDate = moment().subtract(120, 'years');
              const tooEarly = moment(currentDate).add(+1, 'days') <= amountYearsFromDate;
              const tooLate = currentDate > moment().subtract(10, 'years');
              return tooEarly || tooLate;
            }}
          />
        )}
      />
      <Controller
        name="gender"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={employeesGenders}
            error={errors.gender?.message}
            label="Gender"
          />
        )}
      />
      <Controller
        name="nationalInsuranceNumber"
        control={control}
        rules={NationalInsuranceNumberSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => field.onChange(e.target.value.toUpperCase())}
            error={errors.nationalInsuranceNumber?.message}
            label="N.I. number"
          />
        )}
      />
      <AddressField
        addressFields={addressFields}
        setAddressModal={(v) => {
          addressError && setAddressError('');
          setAddressModal(v);
        }}
        title="Address"
        isIconVisible={Object.values(addressFields).some((el) => el)}
        addressError={addressError}
      />
      <Controller
        name="email"
        control={control}
        rules={EmailSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            error={errors.email?.message}
            label="Email"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="telephone"
        control={control}
        rules={TelephoneSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'telephone', false);
            }}
            error={errors.telephone?.message}
            label="Telephone"
          />
        )}
      />
      <Controller
        name="mobile"
        control={control}
        rules={MobileSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'mobile');
            }}
            error={errors.mobile?.message}
            label="Mobile"
          />
        )}
      />
      <div className="details-form__field--lg">
        <DraggerUpload
          name="content"
          isImage
          imagePlaceholder="Upload employee's photo"
          beforeUpload={beforeUpload}
          disabled={fileList.length === 1}
          fileList={fileList}
          setFileList={setFileList}
        />
      </div>
    </section>
  );
};

export default EmployeeInfoSection;
