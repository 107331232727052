import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseSingleResult } from '../../../core/types/coreTypes';
import { setLoading } from '../../slices/coreSlice';
import { pricelistAPI } from '../../../api/pricelistApi';
import { PricelistSupplier } from '../../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';

export const getPricelistSuppliers = createAsyncThunk<
ResponseSingleResult<{suppliers: PricelistSupplier[]}> | null,
{
  keyword?: string,
}
>(
  'get/PricelistSuppliers',
  async ({ keyword }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.fetchPricelistSuppliers(keyword);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
