import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BooleanFunctionType, StringFunctionType } from '../../../../core/types/coreTypes';
import { routesPath } from '../../../../core/enums/pathEnum';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import DashboardFilters from '../../../../common/components/dashboard-filters/DashboardFilters';
import { JobModesEnum } from '../../../jobs/enums/jobsEnums';
import { useJobFiltersHandle } from '../../../jobs/hooks/useJobFiltersHandle';
import { jobsListPageOptions, jobsPageOptions } from '../../utils/data';
import { useAppDispatch } from '../../../../store/hooks';
import Segmented from '../../../../core/components/segmented/Segmented';
import { options } from '../../../jobs-calendar/utils/data';
import { setJobCalendarFilters, setJobListFilters, setJobsFilters } from '../../../../store/slices/jobsSlice';

type JobsFiltersProps = {
  page: string | null,
  setPageParam: StringFunctionType,
  setIsEyeModal: BooleanFunctionType;
}

const JobsFilters: React.FC<JobsFiltersProps> = ({
  page, setPageParam, setIsEyeModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();

  const { setLocationHistoryHandle } = useLocationHistoryState();

  const customActions = page === JobModesEnum.DASHBOARD
    ? [{ icon: faEye, onClick: () => setIsEyeModal(true) }]
    : [];

  const {
    isFiltersOpen,
    setIsFiltersOpen,
    initDropdownSearch,
    keywordValue,
    setKeywordValue,
    jobListFilters,
    jobsAdminListFilters,
    getFilteredJobs,
    getFilteredJobList,
    getFilteredCalendar,
    onClear,
    stateFilters,
    dashFiltersList,
    jobCalendarFilters,
    jobsCalendarFiltersArray,
    currentBusinessArea,
    isUserHasAdminPowers,
    currentUser,
    keywordParam,
    setInitSearchParams,
  } = useJobFiltersHandle();

  useEffect(() => {
    if (isUserHasAdminPowers) {
      if (!stateFilters.keyword && keywordParam) {
        dispatch(setJobsFilters({ ...stateFilters, keyword: keywordParam }));
      }
    } else {
      if (!jobListFilters.keyword && keywordParam) {
        dispatch(setJobListFilters({ ...jobListFilters, keyword: keywordParam }));
      }
    }
    // eslint-disable-next-line
  }, [isUserHasAdminPowers, keywordParam, stateFilters, jobListFilters]);

  const setPageParamHandle = (value: string) => {
    if (value === JobModesEnum.CREATE) {
      navigate(`/${routesPath.CREATE_JOB}`);
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
    } else {
      setPageParam(value as string);
    }
    setKeywordValue(undefined);
  };

  useEffect(() => {
    if (currentBusinessArea && page === JobModesEnum.DASHBOARD) {
      getFilteredJobs(stateFilters);
    }
    // eslint-disable-next-line
    }, [currentBusinessArea, page]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue) {
      const timeOutId = setTimeout(() => {
        if (page === JobModesEnum.JOB_LIST) {
          getFilteredJobList({ ...jobListFilters, keyword: keywordValue.trim() });
        } else if (page === JobModesEnum.CALENDAR) {
          getFilteredCalendar({ ...jobCalendarFilters, keyword: keywordValue.trim() });
        } else {
          (keywordValue !== stateFilters.keyword) && getFilteredJobs({ ...stateFilters, keyword: keywordValue.trim() });
        }
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (page === JobModesEnum.JOB_LIST) {
        if (!keywordValue?.trim() && jobListFilters.keyword) {
          getFilteredJobList({ ...jobListFilters, keyword: undefined });
        }
      } else if (page === JobModesEnum.CALENDAR) {
        if (!keywordValue?.trim() && jobCalendarFilters.keyword) {
          getFilteredCalendar({ ...jobCalendarFilters, keyword: undefined });
        }
      } else {
        if (!keywordValue?.trim() && stateFilters.keyword) {
          getFilteredJobs({ ...stateFilters, keyword: undefined });
        }
      }
    }
    // eslint-disable-next-line
  }, [keywordValue]);

  // admin = min JobListView --> open jobList / dashboard if there is permission
  // usual user = min JobListView --> open jobList

  const filtersOptions = page === JobModesEnum.JOB_LIST
    ? jobsAdminListFilters
    : page === JobModesEnum.DASHBOARD
      ? dashFiltersList
      : jobsCalendarFiltersArray;

  const customActionsPanel = page === JobModesEnum.CALENDAR
    ? <Segmented
      options={options}
      value={jobCalendarFilters.days}
      onChange={(v) => dispatch(setJobCalendarFilters({ ...jobCalendarFilters, days: v as number }))}
      className="jobsCalendarDatesRange"
    />
    : undefined;

  return (
    <>
      {currentUser && <DashboardFilters
        page={page}
        isFiltersOpen={isFiltersOpen}
        setIsFiltersOpen={setIsFiltersOpen}
        setPageParamHandle={setPageParamHandle}
        options={isUserHasAdminPowers ? jobsPageOptions : jobsListPageOptions}
        isHiddenClassname={!isUserHasAdminPowers}
        expandableFilters={page !== JobModesEnum.JOB_LIST}
        customButtons={customActions}
        customActionPanel={customActionsPanel}
      >
        <KeywordFiltersSection
          searchValue={keywordValue}
          setSearchValue={(str) => {
            setKeywordValue(str);
            if (keywordParam) {
              setInitSearchParams();
            }
          }}
          getFilteredResHandle={(values, field) => {
            if (page === JobModesEnum.JOB_LIST) {
              getFilteredJobList({ ...jobListFilters, [field]: values as number[] });
            } else if (page === JobModesEnum.CALENDAR) {
              getFilteredCalendar({ ...jobCalendarFilters, [field]: values as number[] });
            } else {
              getFilteredJobs({ ...stateFilters, [field]: values as number[] });
            }
          }}
          onClear={onClear}
          initDropdownSearch={initDropdownSearch}
          filters={filtersOptions}
          isFullWidth={page !== JobModesEnum.JOB_LIST}
        />
      </DashboardFilters>}
    </>
  );
};

export default JobsFilters;
