import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { orderDetailsSelector } from '../../../../store/selectors/sharedSelectors';
import {
  discardOrderPartReqLinesChangesThunk, submitOrderPartReqLinesThunk,
} from '../../../../store/thunks/orders/orderPartRequestThunks';
import {
  blockedOrderSubmitAndUsualLinesActionsStatusesSelector,
  orderPartReqUiLinesSelector,
  partRequestLinesSelector,
} from '../../../../store/selectors/ordersSelector';
import { CustomAny, VoidFunctionType } from '../../../../core/types/coreTypes';
import PartRequestSubmissionButtons from '../../../../common/components/common-part-request-components/PartRequestSubmissionButtons';
import { partRequestSubmissionStatusesUiSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { PartRequestSubmissionStatusesEnum } from '../../../../core/enums/dictionariesEnums';

const OrderPartRequestSubmissionButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const orderDetails = useAppSelector(orderDetailsSelector);
  const { items: partRequestLines } = useAppSelector(partRequestLinesSelector);
  const requestSubmissionStatuses = useAppSelector(partRequestSubmissionStatusesUiSelector);
  const linesArr = useAppSelector(orderPartReqUiLinesSelector);
  const blockedSubmitAndLinesActionsStatuses = useAppSelector(blockedOrderSubmitAndUsualLinesActionsStatusesSelector);

  const customLines = linesArr.filter((el) => !el.partId && !el.partsKitId);
  const childrenList = linesArr.map((el) => el.childLines).flat();
  const customChildrenLines: CustomAny[] = childrenList.filter((el) => !el?.partId && !el?.partsKitId && !el?.partsKitComponentPartId);
  const allCustomLines = customLines.concat(customChildrenLines);

  const orderSubmissionStatus = orderDetails?.requestSubmissionStatus;
  const submissionStatusLabel = requestSubmissionStatuses.find((el) => el.value === orderSubmissionStatus)?.label;
  const isPartReqSubmitted = orderDetails?.order.isPartRequestSubmitted;
  const isSubmittedStatus = submissionStatusLabel === PartRequestSubmissionStatusesEnum.Submitted;
  const isSubmittedWithChangesStatus = isPartReqSubmitted
      && submissionStatusLabel === PartRequestSubmissionStatusesEnum.SubmittedWithChanges;
  const isSubmitBtnDisabled = !partRequestLines
      || !orderDetails?.order?.status
      || partRequestLines.length === 0
      || blockedSubmitAndLinesActionsStatuses.includes(orderDetails?.order?.status);

  const submitRequestHandle = (checkedCells: number[] | null, cancelHandle: VoidFunctionType) => {
    orderDetails && dispatch(submitOrderPartReqLinesThunk({
      partRequestId: orderDetails.order.partRequestId,
      customLinesIds: checkedCells,
      closeModal: cancelHandle,
    }));
  };
  const discardRequestChangesHandle = (cancelHandle: VoidFunctionType) => {
    orderDetails && dispatch(discardOrderPartReqLinesChangesThunk({
      partRequestId: orderDetails.order.partRequestId,
      closeModal: cancelHandle,
    }));
  };
  return (
    <PartRequestSubmissionButtons
      discardRequestChangesHandle={discardRequestChangesHandle}
      submitRequestHandle={submitRequestHandle}
      isPartReqSubmitted={isPartReqSubmitted}
      isSubmittedStatus={isSubmittedStatus}
      isSubmittedWithChangesStatus={isSubmittedWithChangesStatus}
      isSubmitBtnDisabled={isSubmitBtnDisabled}
      submissionStatusLabel={submissionStatusLabel}
      lastSubmittedDate={orderDetails?.order.lastSubmittedDate}
      customLines={allCustomLines}
    />
  );
};

export default OrderPartRequestSubmissionButtons;
