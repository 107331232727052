import qs from 'qs';
import instance from './axiosConfig';
import {
  GetNominalCodesResponse,
  NominalCodeFields,
  NominalCodesFilters,
} from '../invoicing/nominal-codes/types/nominalCodesTypes';
import {
  AxiosCreatedIdResponse,
  AxiosDownloadUriResponse,
  AxiosGenericResponse,
  AxiosNullableResponse, AxiosSingleResponse,
} from '../core/types/coreTypes';
import {
  GetNominalRecordsResponse,
  NominalRecordsFilters,
  NominalCodesSummaryFilters,
  PostNominalRecordRestModel,
  PutNominalRecordRestModel,
  GetNominalRecordsSummaryResponse,
} from '../invoicing/invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import {
  GetInvoiceDocumentsResponse,
  GetInvoiceDocumentsSummaryResponse,
  InvoiceDocumentsFilters,
} from '../invoicing/invoicing-purchases/types/InvoicingPurchasesTypes';
import { AddEditInvoiceFields } from '../invoicing/invoicing-purchases/types/AddInvoiceSchema';

export const invoicingAPI = {
  fetchNominalCodes(params: NominalCodesFilters): AxiosGenericResponse<GetNominalCodesResponse> {
    return instance.get('/invoicing/nominalCodes', {
      params: {
        'Filter.Types': params.types,
        'Filter.Code': params.code,
        'Filter.Description': params.description,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  deleteNominalCode(id: number): AxiosNullableResponse {
    return instance.delete(`/invoicing/nominalCodes/${id}`);
  },
  postNominalCode(data: NominalCodeFields): AxiosCreatedIdResponse {
    return instance.post('/invoicing/nominalCodes', { ...data });
  },
  putNominalCode(id: number, data: NominalCodeFields): AxiosNullableResponse {
    return instance.put(`/invoicing/nominalCodes/${id}`, { ...data });
  },
  fetchNominalRecords(params: NominalRecordsFilters): AxiosGenericResponse<GetNominalRecordsResponse> {
    return instance.get('/invoicing/nominalRecords', {
      params: {
        'Filter.PurchaseOrderIds': params.purchaseOrderIds,
        'Filter.PurchaseOrderPartIds': params.purchaseOrderPartIds,
        'Filter.PartRequestLineIds': params.partRequestLineIds,
        'Filter.OrderIds': params.orderIds,
        'Filter.Types': params.types,
        'Filter.States': params.states,
        'Filter.ProductName': params.productName,
        'Filter.ProductNumber': params.productNumber,
        'Filter.NominalCodeIds': params.nominalCodeIds,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  addNominalRecord(data: PostNominalRecordRestModel): AxiosCreatedIdResponse {
    return instance.post('/invoicing/nominalRecords', { ...data });
  },
  editNominalRecord(id: number, data: PutNominalRecordRestModel): AxiosNullableResponse {
    return instance.put(`/invoicing/nominalRecords/${id}`, { ...data });
  },
  deleteNominalRecord(id: number): AxiosNullableResponse {
    return instance.delete(`/invoicing/nominalRecords/${id}`);
  },
  fetchNominalRecordsSummary(params: NominalCodesSummaryFilters): AxiosSingleResponse<GetNominalRecordsSummaryResponse> {
    return instance.get('/invoicing/nominalRecords/summary', {
      params: {
        PurchaseOrderId: params.purchaseOrderId,
        NominalRecordIds: params.nominalRecordIds,
        OrderId: params.orderId,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchInvoiceDocuments(params: InvoiceDocumentsFilters, type?: 'sales' | 'purchases'): AxiosGenericResponse<GetInvoiceDocumentsResponse> {
    return instance.get('/invoicing/invoiceDocuments', {
      params: {
        'Filter.PurchaseOrderIds': params.purchaseOrderIds,
        'Filter.OrderIds': params.orderIds,
        'Filter.OperationTypes': params.operationTypes,
        'Filter.SupplierIds': params.supplierIds,
        'Filter.CustomerIds': params.customerIds,
        'Filter.Types': params.types,
        'Filter.InvoiceDate.From': params.invoiceDate?.min,
        'Filter.InvoiceDate.To': params.invoiceDate?.max,
        'Filter.DueDate.From': params.dueDate?.min,
        'Filter.DueDate.To': params.dueDate?.max,
        'Filter.Statuses': params.statuses,
        'Filter.SearchParameters.Keyword': params.keyword,
        'Filter.SearchParameters.IncludeOrderNumber': params.keyword ? type === 'sales' : undefined,
        'Filter.SearchParameters.IncludePurchaseOrderNumber': params.keyword ? type === 'purchases' : undefined,
        'Filter.SearchParameters.IncludeEntityNumber': params.keyword ? true : undefined,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  deleteInvoiceDocument(id: number): AxiosNullableResponse {
    return instance.delete(`/invoicing/invoiceDocuments/${id}`);
  },
  patchInvoiceDocument(id: number, data: AddEditInvoiceFields): AxiosNullableResponse {
    return instance.patch(`/invoicing/invoiceDocuments/${id}`, { ...data });
  },
  putInvoiceDocumentStatus(id: number, statusTransition: number): AxiosNullableResponse {
    return instance.put(`/invoicing/invoiceDocuments/${id}/status`, { statusTransition });
  },
  postInvoiceDocument(
    data: AddEditInvoiceFields & { pendingNominalRecordIds: number[] },
  ): AxiosSingleResponse<{ createdId: number, entityNumber: string }> {
    return instance.post('/invoicing/invoiceDocuments', { ...data });
  },
  fetchInvoiceDocumentsSummary(invoiceDocumentIds: number[]): AxiosGenericResponse<GetInvoiceDocumentsSummaryResponse> {
    return instance.get('/invoicing/invoiceDocuments/summary', {
      params: { invoiceDocumentIds },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  exportInvoiceDocumentsCsvFile(invoiceDocumentIds: number[], markDraftAsExported: boolean): AxiosDownloadUriResponse {
    return instance.get('/invoicing/invoiceDocuments/csvFile', {
      params: { invoiceDocumentIds, markDraftAsExported },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
};
