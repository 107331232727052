import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { setFilters } from '../../../store/slices/enquiriesSlice';
import { setDashFilters } from '../../../store/slices/ordersSlice';
import { setRectificationDashFilters } from '../../../store/slices/rectificationsSlice';
import { useAppDispatch } from '../../../store/hooks';
import { setJobsFilters } from '../../../store/slices/jobsSlice';
import { EnquiryFiltersType } from '../../../enquiry/enquiries/types/enquiryTypes';
import { IRectificationsFilters } from '../../../rectifications/dashboard/types/rectificationsTypes';
import { OrdersFiltersType } from '../../../orders/dashboard/types/ordersTypes';
import { JobsFiltersType } from '../../../job/jobs/types/jobsTypes';

type FiltersTypeOptions = EnquiryFiltersType | OrdersFiltersType | IRectificationsFilters | JobsFiltersType

export const useCustomerCardClickHandle = () => {
  const dispatch = useAppDispatch();
  const cardClickHandle = (permission: PermissionEnum, filters: FiltersTypeOptions) => {
    switch (permission) {
      case PermissionEnum.EnquiryDashboardPage:
        dispatch(setFilters(filters));
        break;
      case PermissionEnum.OrderDashboard:
        dispatch(setDashFilters(filters));
        break;
      case PermissionEnum.RectificationDashboard:
        dispatch(setRectificationDashFilters(filters));
        break;
      case PermissionEnum.JobDashboard:
        dispatch(setJobsFilters(filters));
        break;
      default: break;
    }
  };
  return { cardClickHandle };
};
