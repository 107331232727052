import React from 'react';
import { AttachmentsColumnsProps } from '../../../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../../../store/hooks';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../../permissions-handling/permissionHook';
import { partsKitAttachmentsFiltersSelector } from '../../../../../../../store/selectors/partsKitSelectors';
import { setPartsKitAttachmentsFilter } from '../../../../../../../store/slices/partsKitSlice';
import AttachmentColumns from '../../../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../../../core/hooks/useCoreAttachmentsColumns';

const PartsKitAttachmentColumns:React.FC<AttachmentsColumnsProps> = ({
  isAll, checkAll, totalElements, indeterminate,
}) => {
  const allowedManage = usePermission(PermissionEnum.StockPartsKitEditFields);
  const attachmentsFilters = useAppSelector(partsKitAttachmentsFiltersSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachmentsFilters, setPartsKitAttachmentsFilter);

  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      totalElements={totalElements}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={attachmentsFilters}
    />
  );
};

export default PartsKitAttachmentColumns;
