import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import {
  BooleanFunctionType, DataListType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Button from '../../../core/components/button/Button';
import ExistedContactsList from './ExistedContactsList';
import { AddressFields, ContactDto } from '../../types/commonTypes';
import CreateNewContactForm from './CreateNewContactForm';
import { CustomerContactFiltersType } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import './AddContactModal.scss';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import { NewContactFormFields } from '../../types/createNewContactSchema';

type AddContactModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  closeModal: VoidFunctionType,
  addressFields: AddressFields,
  setLocation: BooleanFunctionType,
  setAddressFields: (values: AddressFields) => void,
  companyNameValue: string,
  createNewContact: (values: NewContactFormFields, reset: UseFormReset<NewContactFormFields>) => void,
  mode: 'new' | 'existed' | string,
  setMode: (value: 'new' | 'existed' | string) => void,
  existedContacts: DataListType<ContactDto[], { allItemIds: number[] }>;
  getExistingContacts: VoidFunctionType,
  existingPaging: CustomerContactFiltersType,
  addSelectedContacts: (ids: number[]) => void,
  setExistedContactsPaging: (filters: CustomerContactFiltersType) => void,
}

const AddContactModal: React.FC<AddContactModalProps> = ({
  onCancel,
  closeModal,
  isVisible,
  addressFields,
  setLocation,
  setAddressFields,
  companyNameValue,
  createNewContact,
  mode,
  setMode,
  existedContacts,
  getExistingContacts,
  existingPaging,
  addSelectedContacts,
  setExistedContactsPaging,
}) => {
  // eslint-disable-next-line no-nested-ternary
  const modalTitle = mode === 'new' ? 'Create contact' : mode === 'existed' ? 'Select contacts' : '';
  const isFetching = useAppSelector(isFetchingSelector);

  return (
    <Modal
      title={modalTitle}
      visible={isVisible}
      onCancel={closeModal}
      width={mode === 'existed' ? 1000 : 600}
      maskClosable={!isFetching}
      keyboard={!isFetching}
    >
      <div className="addContact">
        <div className={classNames('addContact__choice', { 'choiceHidden': mode })}>
          <Button
            label="Create a new customer contact"
            className="addContact__choice-item"
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            htmlType="button"
            onClick={() => setMode('new')}
            reversed
          />
          <Button
            label="Select existing customer contact(s)"
            className="addContact__choice-item"
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            htmlType="button"
            onClick={() => setMode('existed')}
            reversed
          />
        </div>
        {mode === 'new' && <CreateNewContactForm
          setMode={setMode}
          addressFields={addressFields}
          setAddressFields={setAddressFields}
          setLocation={setLocation}
          companyNameValue={companyNameValue}
          submitHandle={createNewContact}
          isLoading={isFetching}
        />}
        {mode === 'existed' && <ExistedContactsList
          onCancel={onCancel}
          existedContacts={existedContacts}
          getExistingContacts={getExistingContacts}
          existingPaging={existingPaging}
          addSelectedContacts={addSelectedContacts}
          setExistedContactsPaging={setExistedContactsPaging}
          isLoading={isFetching}
        />}
      </div>
    </Modal>
  );
};

export default AddContactModal;
