import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { VehiclesPageParamsEnum } from '../enums/VehiclesPageParamsEnum';
import { setVehicleProfilesFilters, setVehiclesDashboardFilters } from '../../../store/slices/vehiclesSlice';
import { initPaging } from '../../../core/types/coreTypes';
import {
  vehiclesDashboardFiltersSelector,
  vehiclesDashboardsSearchFiltersSelector,
  vehiclesProfilesFiltersSelector, vehiclesProfilesSearchFiltersSelector,
} from '../../../store/selectors/vehiclesSelectors';

export const useVehiclesFilters = (page: string | null) => {
  const dispatch = useAppDispatch();
  const dashboardFilters = useAppSelector(vehiclesDashboardFiltersSelector);
  const dashboardFiltersList = useAppSelector(vehiclesDashboardsSearchFiltersSelector);

  const vehiclesProfilesFilters = useAppSelector(vehiclesProfilesFiltersSelector);
  const vehiclesProfilesSearchFiltersList = useAppSelector(vehiclesProfilesSearchFiltersSelector);
  const defaultKeywordValue = page === VehiclesPageParamsEnum.DASHBOARD ? dashboardFilters.keyword : vehiclesProfilesFilters.keyword;

  // Used to prevent double vehicles request on mount when keyword is not empty
  const firstRenderRef = useRef(true);

  const [keywordValue, setKeywordValue] = useState<string | undefined>(defaultKeywordValue);
  const changeKeywordValue = (value: string | undefined) => {
    setKeywordValue(value);
    firstRenderRef.current = false;
  };

  const initDropdownSearch = page === VehiclesPageParamsEnum.DASHBOARD
    ? {
      manufacturers: '',
      customerIds: '',
      assigneeIds: '',
    }
    : {
      manufacturer: '',
      type: '',
      group: '',
    };

  const dashboardSelectableFilters = [
    dashboardFilters.manufacturers,
    dashboardFilters.customerIds,
    dashboardFilters.statuses,
    dashboardFilters.assigneeIds,
    dashboardFilters.vehicleFlows,
  ];
  const isAnyDashboardFilterSelected = !!dashboardFilters.keyword || dashboardSelectableFilters.some((el) => el && el.length > 0);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && !firstRenderRef.current) {
      const timeOutId = setTimeout(() => {
        if (page === VehiclesPageParamsEnum.DASHBOARD) {
          dispatch(setVehiclesDashboardFilters({ ...dashboardFilters, page: 1, keyword: keywordValue.trim() }));
        } else {
          dispatch(setVehicleProfilesFilters({ ...vehiclesProfilesFilters, page: 1, keyword: keywordValue.trim() }));
        }
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (page === VehiclesPageParamsEnum.DASHBOARD) {
        if (dashboardFilters.keyword && !keywordValue?.trim()) {
          dispatch(setVehiclesDashboardFilters({ ...dashboardFilters, keyword: undefined }));
        }
      } else {
        if (vehiclesProfilesFilters.keyword && !keywordValue?.trim()) {
          dispatch(setVehicleProfilesFilters({ ...vehiclesProfilesFilters, keyword: undefined }));
        }
      }
    }
    // eslint-disable-next-line
  }, [keywordValue]);

  useEffect(() => {
    if (page === VehiclesPageParamsEnum.DASHBOARD) {
      changeKeywordValue(dashboardFilters.keyword);
    } else {
      changeKeywordValue(vehiclesProfilesFilters.keyword);
    }
    firstRenderRef.current = true;
    // eslint-disable-next-line
  }, [page]);

  const clearFilters = () => {
    if (page === VehiclesPageParamsEnum.DASHBOARD) {
      const { order, isActive } = dashboardFilters;
      isAnyDashboardFilterSelected && dispatch(setVehiclesDashboardFilters({ ...initPaging, order, isActive }));
    } else {
      const isAnyFilterApplied = vehiclesProfilesFilters.keyword
          || vehiclesProfilesFilters.type
          || vehiclesProfilesFilters.manufacturer
          || vehiclesProfilesFilters.group;
      isAnyFilterApplied && dispatch(setVehicleProfilesFilters(initPaging));
    }
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    if (page === VehiclesPageParamsEnum.DASHBOARD) {
      const newFilters = { ...dashboardFilters, [field]: values, page: 1 };
      if (field === 'vehicleFlows') {
        dispatch(setVehiclesDashboardFilters({ ...newFilters, statuses: [] }));
      } else {
        dispatch(setVehiclesDashboardFilters(newFilters));
      }
    } else {
      dispatch(setVehicleProfilesFilters({ ...vehiclesProfilesFilters, [field]: values, page: 1 }));
    }
  };

  const filters = page === VehiclesPageParamsEnum.DASHBOARD ? dashboardFiltersList : vehiclesProfilesSearchFiltersList;

  return {
    clearFilters,
    filters,
    getFilteredResHandle,
    keywordValue,
    changeKeywordValue,
    initDropdownSearch,
    isAnyDashboardFilterSelected,
  };
};
