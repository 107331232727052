import React from 'react';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import Segmented from '../../../../core/components/segmented/Segmented';
import { SupplierViewPageEnums } from '../../enums/SupplierViewPageEnums';
import { useAppSelector } from '../../../../store/hooks';
import { supplierDetailsCardProperties } from '../../../../store/selectors/supplierSelector';
import { useSupplierCardClick } from '../../hooks/useSupplierCardClick';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';

type SupplierStaticSectionProps = {
  setModeParam: StringFunctionType,
  mode: string | null,
  allowedOptions: { label: string, value: SupplierViewPageEnums }[],
}

const SupplierStaticSection:React.FC<SupplierStaticSectionProps> = ({
  mode, allowedOptions, setModeParam,
}) => {
  const supplierProperties = useAppSelector(supplierDetailsCardProperties);
  const { cardClickHandle } = useSupplierCardClick();
  const { windowWidth } = useScreenWitdh();

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters">
          <Selectable
            options={allowedOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className="tabs-and-props desktop-sticky-filters">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="tabs-and-props__tabs"
          />
        )}
        {mode === SupplierViewPageEnums.SUPPLIER_DETAILS && <ul className="tabs-and-props__props tabs-and-props__props--3">
          {supplierProperties.map((el, i) => <li className="tabs-and-props__props-item">
            <PrivateLineLink
              permission={el.permission}
              notLink={el.value === 0}
              to={el.to}
              key={i}
              className="tabs-and-props__text"
              customClick={() => cardClickHandle(el.permission, el.filters)}
            >
              {`${el.label} - ${el.value}`}
            </PrivateLineLink>
          </li>)}
        </ul>}
      </div>
    </>
  );
};

export default SupplierStaticSection;
