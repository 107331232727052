import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getVehicleEventTypesThunk } from '../../store/thunks/core/coreEventTypesThunks';
import {
  getFullVehicleCheckDecisionsThunk,
  getHandoverVehicleCheckDecisionsThunk,
  getVehicleCheckTypesThunk, getVehicleInfoSources, getVehicleSlidingDoorsQuantities,
} from '../../store/thunks/coreThunk';

export const vehicleDictionariesHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(getVehicleSlidingDoorsQuantities());
  dispatch(getVehicleInfoSources());
  dispatch(getVehicleEventTypesThunk());
  dispatch(getVehicleCheckTypesThunk());
  dispatch(getHandoverVehicleCheckDecisionsThunk());
  dispatch(getFullVehicleCheckDecisionsThunk());
};
