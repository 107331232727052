import React from 'react';
import PartRequestSubmissionButtons
  from '../../../../common/components/common-part-request-components/PartRequestSubmissionButtons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { CustomAny, VoidFunctionType } from '../../../../core/types/coreTypes';
import { rectificationDetailsSelector } from '../../../../store/selectors/sharedSelectors';
import { partRequestSubmissionStatusesUiSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { PartRequestSubmissionStatusesEnum } from '../../../../core/enums/dictionariesEnums';
import {
  blockedSubmitRectificationAndLinesActionsStatusesSelector, isRectificationInNewStatusSelector,
  rectificationPartRequestLinesSelector,
  rectificationPartReqUiLinesSelector,
} from '../../../../store/selectors/rectificationsSelector';
import {
  discardRectificationPartReqLinesChangesThunk,
  submitRectificationPartReqLinesThunk,
} from '../../../../store/thunks/rectification/rectificationViewPageThunks';

const RectificationPartRequestSubmissionButtons: React.FC = () => {
  const dispatch = useAppDispatch();
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const partRequestId = rectificationDetails?.rectification?.partRequestId;
  const { items: partRequestLines } = useAppSelector(rectificationPartRequestLinesSelector);
  const requestSubmissionStatuses = useAppSelector(partRequestSubmissionStatusesUiSelector);
  const linesArr = useAppSelector(rectificationPartReqUiLinesSelector);
  const isRectificationInNewStatus = useAppSelector(isRectificationInNewStatusSelector);
  const blockedSubmitAndLinesActionsStatuses = useAppSelector(blockedSubmitRectificationAndLinesActionsStatusesSelector);

  const customLines = linesArr.filter((el: CustomAny) => !el.partId && !el.partsKitId);
  const childrenList = linesArr.map((el: CustomAny) => el.childLines).flat();
  const customChildrenLines = childrenList.filter((el: CustomAny) => !el.partId && !el.partsKitId && !el.partsKitComponentPartId);
  const allCustomLines = customLines.concat(customChildrenLines);

  const rectificationSubmissionStatus = rectificationDetails?.requestSubmissionStatus;
  const submissionStatusLabel = requestSubmissionStatuses.find((el) => el.value === rectificationSubmissionStatus)?.label;
  const isPartReqSubmitted = rectificationDetails?.rectification.isPartRequestSubmitted;
  const isSubmittedStatus = submissionStatusLabel === PartRequestSubmissionStatusesEnum.Submitted;
  const isSubmittedWithChangesStatus = isPartReqSubmitted
      && submissionStatusLabel === PartRequestSubmissionStatusesEnum.SubmittedWithChanges;
  const isSubmitBtnDisabled = !partRequestLines
      || partRequestLines.length === 0
      || blockedSubmitAndLinesActionsStatuses.includes(rectificationDetails?.rectification?.status);

  const submitRequestHandle = (checkedCells: number[] | null, cancelHandle: VoidFunctionType) => {
    partRequestId && dispatch(submitRectificationPartReqLinesThunk({
      partRequestId,
      customLinesIds: checkedCells,
      closeModal: cancelHandle,
    }));
  };
  const discardRequestChangesHandle = (cancelHandle: VoidFunctionType) => {
    partRequestId && dispatch(discardRectificationPartReqLinesChangesThunk({
      partRequestId,
      closeModal: cancelHandle,
    }));
  };
  return (
    <PartRequestSubmissionButtons
      discardRequestChangesHandle={discardRequestChangesHandle}
      submitRequestHandle={submitRequestHandle}
      isPartReqSubmitted={isPartReqSubmitted}
      isSubmittedStatus={isSubmittedStatus}
      isSubmittedWithChangesStatus={isSubmittedWithChangesStatus}
      isSubmitBtnDisabled={isSubmitBtnDisabled || isRectificationInNewStatus}
      submissionStatusLabel={submissionStatusLabel}
      lastSubmittedDate={rectificationDetails?.rectification?.lastSubmittedDate}
      customLines={allCustomLines}
    />
  );
};

export default RectificationPartRequestSubmissionButtons;
