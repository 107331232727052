import { OldNewLocationEventValues } from '../types/commonTypes';

export const getOldAndNewLocationValues = <T>(item: T & OldNewLocationEventValues) => ({
  oldJobCompanyName: item.oldJobCompanyName,
  oldJobAddressLine1: item.oldJobAddressLine1,
  oldJobAddressLine2: item.oldJobAddressLine2,
  oldJobCityTown: item.oldJobCityTown,
  oldJobRegion: item.oldJobRegion,
  oldJobPostalCode: item.oldJobPostalCode,
  oldJobTelephone: item.oldJobTelephone,
  newJobCompanyName: item.newJobCompanyName,
  newJobAddressLine1: item.newJobAddressLine1,
  newJobAddressLine2: item.newJobAddressLine2,
  newJobCityTown: item.newJobCityTown,
  newJobRegion: item.newJobRegion,
  newJobPostalCode: item.newJobPostalCode,
  newJobTelephone: item.newJobTelephone,
});
