export enum PricelistModeEnum {
  PRICELIST_DETAILS = 'details-and-activity',
  PRICELIST_PRICES = 'prices',
  PRICELIST_ATTACHMENTS = 'attachments',
}

export const PricelistModes = [
  PricelistModeEnum.PRICELIST_PRICES,
  PricelistModeEnum.PRICELIST_DETAILS,
  PricelistModeEnum.PRICELIST_ATTACHMENTS,
];

export enum PricelistPartsOrders {
  Category = 'Category',
  SubCategory = 'SubCategory',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  CostPrice = 'CostPrice',
  SellPrice = 'SellPrice',
  ModifiedDate = 'ModifiedDate',
}
