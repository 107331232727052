import React from 'react';
import Chart from '../../../../../../core/components/chart/Chart';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  rectificationReportDataChartSelector,
  rectificationReportFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../../store/selectors/reportsSelectors';

const RectificationReportChart: React.FC = () => {
  const filters = useAppSelector(rectificationReportFiltersSelector);
  const chartData = useAppSelector(rectificationReportDataChartSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      height={550}
      options={{
        chart: {
          type: 'bar',
          toolbar: {
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
            },
          },
          zoom: {
            enabled: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: {
          fontFamily: '"Montserrat", "Arial", sans-serif',
          fontSize: '15px',
        },
        tooltip: {
          x: {
            formatter(val: number): string {
              return chartData[val - 1].rectificationNumber as string;
            },
          },
        },
        xaxis: {
          tickPlacement: 'on',
          type: 'category',
          categories: chartData.map((el) => el.customer),
          labels: {
            trim: true,
            minHeight: 150,
            rotate: -60,
            rotateAlways: true,
          },
        },
      }}
      series={[{
        name: 'Cost net',
        data: chartData.map((el) => el.costNet).slice(0, filters.pageSize),
      }, {
        name: 'Sales net',
        data: chartData.map((el) => el.salesNet).slice(0, filters.pageSize),
      }]}
    />
  );
};

export default RectificationReportChart;
