import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import {
  AttachmentsFiltersProps,
} from '../../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../../store/hooks';
import Restricted from '../../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import Button from '../../../../../../core/components/button/Button';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import { setSupplierAttachmentsFilters } from '../../../../../../store/slices/supplierSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../../core/utils/regex';
import { scrollAttachTable } from '../../../../../../common/utils/scrollAttachTable';

const SupplierAttachmentFilters: React.FC<AttachmentsFiltersProps> = ({
  filters,
  totalItems,
  setCreateModal,
  setDeleteModal,
  checkedKeys,
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Restricted to={PermissionEnum.SupplierEditFields}>
          <>
            <Button
              label="Upload file"
              onClick={() => setCreateModal(true)}
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              designType="dark"
              reversed
            />
            <Button
              label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
              htmlType="button"
              onClick={() => setDeleteModal(true)}
              disabled={!checkedKeys.length}
            />
          </>
        </Restricted>
      </div>
      {totalItems > 0 && <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page, pageSize) => {
          scrollAttachTable(filters.page, page);
          dispatch(setSupplierAttachmentsFilters({ ...filters, page, pageSize }));
        }}
        totalItems={totalItems}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        narrow
        className="actions-and-paging__paging"
      />}
    </div>
  );
};

export default SupplierAttachmentFilters;
