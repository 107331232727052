import React from 'react';
import { OrderEventDtoBase } from '../../../../types/OrderViewPageTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { OrderEventEnum } from '../../../../enums/OrderEventEnum';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import OrderNoteEvent from './OrderNoteEvent';
import OrderFieldUpdateEvent from './OrderFieldUpdateEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { getOldAndNewLocationValues } from '../../../../../../common/utils/getOldAndNewLocationValues';
import JobLocationEvent from '../../../../../../common/components/activity-events/job-location/JobLocationEvent';
import BillingShippingEvents from '../../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import ContactEvents from '../../../../../../common/components/activity-events/contact/ContactEvents';
import { orderStatusSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { orderEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import PartRequestLineCreationEvent
  from '../../../../../../common/components/activity-events/part-request-lines-creation-event/PartRequestLineCreationEvent';
import InvoicesAndCreditNotesEvents
  from '../../../../../../common/components/activity-events/invoices-and-credit-notes/InvoicesAndCreditNotesEvents';
import { invoiceDocumentTypesSelector } from '../../../../../../store/selectors/coreSelectors';
import NominalRecordEvents
  from '../../../../../../invoicing/common/components/activity-log-common/NominalRecordEvents';
import { getPartRequestLineChangeGroupsThunk } from '../../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';

type OrderEventProps = {
  item: OrderEventDtoBase,
}

const OrderEvent: React.FC<OrderEventProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const orderEventTypes = useAppSelector(orderEventTypesSelector);
  const orderStatuses = useAppSelector(orderStatusSelector);
  const invoiceDocumentTypes = useAppSelector(invoiceDocumentTypesSelector);

  const attachmentEvents = [
    OrderEventEnum.AddingAttachmentWithoutComment,
    OrderEventEnum.AddingAttachmentWithComment,
    OrderEventEnum.EditingAttachmentComment,
    OrderEventEnum.DeletingAttachment,
    OrderEventEnum.EditingAttachmentVisibility,
  ];
  const contactEvents = [
    OrderEventEnum.AddingContact,
    OrderEventEnum.ContactChange,
    OrderEventEnum.RemovingContact,
  ];
  const billingShippingEvents = [
    OrderEventEnum.BillingDetailsUpdates,
    OrderEventEnum.ShippingDetailsUpdates,
  ];
  const invoicesAndCreditNotesEvents = [
    OrderEventEnum.InvoiceDocumentCreation,
    OrderEventEnum.InvoiceDocumentFieldUpdate,
    OrderEventEnum.InvoiceDocumentDeletion,
  ];

  const nominalRecordsEvents = [
    OrderEventEnum.NominalRecordCreation,
    OrderEventEnum.NominalRecordFieldUpdate,
    OrderEventEnum.NominalRecordDeletion,
  ];

  const orderEventRenderFn = (item: OrderEventDtoBase) => {
    const event = orderEventTypes.find((type) => type.value === item.orderEventType)?.label || '';
    switch (true) {
      case (event === OrderEventEnum.Note):
        return <OrderNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case (event === OrderEventEnum.OrderCreation):
        return <CreationEvent entity="Order" type="number" value={item.orderNumber} />;
      case (event === OrderEventEnum.OrderFieldUpdateOrAddingValue):
        return <OrderFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (event === OrderEventEnum.OrderStatusChange): {
        const prevStatus = orderStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = orderStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case (event === OrderEventEnum.JobLocationUpdate): {
        const locationValues = getOldAndNewLocationValues(item);
        return <JobLocationEvent {...locationValues} />;
      }
      case (event === OrderEventEnum.PartRequestLineCreationChanges):
        return <PartRequestLineCreationEvent
          getChangesHandle={() => {
            item.groupId && dispatch(getPartRequestLineChangeGroupsThunk({ groupId: item.groupId }));
          }}
        />;
      case (billingShippingEvents.includes(event as OrderEventEnum)):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (attachmentEvents.includes(event as OrderEventEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (contactEvents.includes(event as OrderEventEnum)):
        return <ContactEvents
          eventName={event}
          contact={item.contact}
          firstName={event === OrderEventEnum.AddingContact ? item.contact?.firstName : item.firstName}
          lastName={event === OrderEventEnum.AddingContact ? item.contact?.lastName : item.lastName}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (nominalRecordsEvents.includes(event as OrderEventEnum)): {
        return <NominalRecordEvents
          eventName={event}
          lineNumber={item.lineNumber || ''}
          productName={item.productName || ''}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      }
      case (invoicesAndCreditNotesEvents.includes(event as OrderEventEnum)): {
        const entityType = invoiceDocumentTypes.find((types) => types.value === item.invoiceDocumentType)?.label || '';
        return <InvoicesAndCreditNotesEvents
          eventName={event}
          entityType={entityType}
          entityNumber={item.invoiceDocumentEntityNumber || ''}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      }
      default: return null;
    }
  };

  return <>{orderEventRenderFn(item)}</>;
};

export default OrderEvent;
