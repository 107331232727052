import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { RectificationColumnType, RectificationDto } from '../../types/rectificationsTypes';
import {
  rectificationColumnsPaginationSelector, rectificationNewStatusSelector,
  rectificationsDashFiltersSelector,
  sortedRectificationCollectionsSelector,
} from '../../../../store/selectors/rectificationsSelector';
import DraggableCardsArea from '../../../../common/components/draggable-cards-column/DraggableCardsArea';
import { setRectificationCollections } from '../../../../store/slices/rectificationsSlice';
import { getRectificationCollectionsNextPortion } from '../../../../store/thunks/rectification/rectificationsDashThunks';
import {
  currentBusinessAreaSelector,
  isDashboardLoadingSelector,
  rectificationWarningsSelector,
} from '../../../../store/selectors/coreSelectors';
import { reformRectificationCardData } from '../../utils/reformRectificationCardData';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../permissions-handling/permissionHook';
import { useRectificationStatusChange } from '../../hooks/useRectificationStatusChange';
import RejectStatusModal from '../../../../common/components/entity-status-change-modals/RejectStatusModal';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import DashboardSkeleton from '../../../../common/components/skeletons/dashboard-skeleton/DashboardSkeleton';
import { useDragEndDashboardCards } from '../../../../common/hooks/useDragAndDropDashboardCards';

type RectificationsCardsColumnsProps = {
  cards: RectificationColumnType | null,
  setCards: (value: RectificationColumnType | null) => void,
}

const RectificationCardsColumns: React.FC<RectificationsCardsColumnsProps> = ({ cards, setCards }) => {
  const dispatch = useAppDispatch();
  const allowedToEdit = usePermission(PermissionEnum.RectificationEditFields);
  const rectificationColumnsPagination = useAppSelector(rectificationColumnsPaginationSelector);
  const rectificationsDashFilters = useAppSelector(rectificationsDashFiltersSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const rectificationCollections = useAppSelector(sortedRectificationCollectionsSelector);
  const warnings = useAppSelector(rectificationWarningsSelector);
  const statusNew = useAppSelector(rectificationNewStatusSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  const {
    isRejectStatus,
    setIsRejectStatus,
    isCompletedConfirmation,
    setCompletedConfirmation,
    isCancellationConfirmation,
    setCancellationConfirmation,
    setRectificationTransit,
    editStatus,
    confirmRejectedStatusChange,
    confirmCompletedStatusChange,
    confirmCancelledStatusChange,
    rejected,
    rectificationStatuses,
    columnsLoading,
  } = useRectificationStatusChange(setCards);

  const currentFiltersState = [
    rectificationsDashFilters?.assignedTo,
    rectificationsDashFilters?.jobType,
    rectificationsDashFilters?.warnings,
    rectificationsDashFilters?.rectificationType,
    rectificationsDashFilters?.priority,
  ];

  const setNewPortion = (status: number, data: Array<RectificationDto>) => {
    const newArr = cards?.map((el) => {
      if (el.status === status) {
        return {
          ...el,
          list: el.list ? [...el.list, ...data] : [...data],
        };
      } else {
        return el;
      }
    });
    newArr && dispatch(setRectificationCollections(newArr));
  };
  const getNewPortion = async (page: number, status: number) => {
    currentBusinessArea && dispatch(getRectificationCollectionsNextPortion({
      params: {
        ...rectificationsDashFilters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: [status],
        page,
      },
      currentStatus: status,
      setNewPortion,
    }));
  };

  const { onDragEnd } = useDragEndDashboardCards<RectificationDto>(editStatus);
  return (
    <>
      <RejectStatusModal
        isVisible={isRejectStatus}
        onCancel={() => {
          setIsRejectStatus(false);
          setCards(rectificationCollections);
          setRectificationTransit(undefined);
        }}
        onConfirm={(status, reason, onCancel) => confirmRejectedStatusChange(status, reason, onCancel)}
        rejectionStatus={rejected}
        customTitle="Move to Rejected by customer"
      />
      <ConfirmationModal
        isVisible={isCancellationConfirmation || isCompletedConfirmation}
        onCancel={() => {
          isCancellationConfirmation && setCancellationConfirmation(false);
          isCompletedConfirmation && setCompletedConfirmation(false);
          setCards(rectificationCollections);
          setRectificationTransit(undefined);
        }}
        customTitle="Confirm"
        customText={isCompletedConfirmation
          ? 'You are about to mark this rectification as "Completed" and after this it will be locked for editing. '
              + 'This operation cannot be undone.'
          : 'You are about to mark this rectification as "Cancelled" and all part lines will be removed from this rectification. '
              + 'This operation cannot be undone.'}
        customCreateLabel={isCompletedConfirmation ? 'Mark as completed' : 'Mark as cancelled'}
        confirmHandler={isCompletedConfirmation ? confirmCompletedStatusChange : confirmCancelledStatusChange}
      />
      {isLoading
        ? <DashboardSkeleton hasTotalCost />
        : <DraggableCardsArea
          columnsPagination={rectificationColumnsPagination}
          getNewPortion={getNewPortion}
          onDragEnd={onDragEnd}
          cards={cards}
          setCards={setCards}
          statusDictionary={rectificationStatuses}
          dashFilters={rectificationsDashFilters}
          currentFiltersState={currentFiltersState}
          reformCardData={(card: RectificationDto) => reformRectificationCardData(card, statusNew)}
          permissionToSeeCardPage={PermissionEnum.RectificationViewPage}
          isDragDisabled={!allowedToEdit}
          setCardTransit={setRectificationTransit}
          warnings={warnings}
          columnsLoading={columnsLoading}
        />}
    </>
  );
};

export default RectificationCardsColumns;
