import React from 'react';
import Switch from '../../../../../core/components/switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  numberOfConvertedVehiclesFiltersSelector,
  numberOfConvertedVehiclesDataSelector, isTableViewSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setIsTableView, setNumberOfConvertedVehiclesFilters } from '../../../../../store/slices/reportsSlice';
import NumberOfConvertedVehiclesTable from './table/NumberOfConvertedVehiclesTable';
import NumberOfConvertedVehiclesChart from './chart/NumberOfConvertedVehiclesChart';
import { getNumberOfConvertedVehiclesReportThunk } from '../../../../../store/thunks/reports/reportsThunks';

const NumberOfConvertedVehiclesContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(numberOfConvertedVehiclesFiltersSelector);
  const tableData = useAppSelector(numberOfConvertedVehiclesDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const isTableView = useAppSelector(isTableViewSelector);

  return (
    <>
      <div className="actions-and-paging reportActionsAndPaging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              if (val) {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { convertedVehicles, order, ...rest } = filters || {};
                reportIsVisible && dispatch(getNumberOfConvertedVehiclesReportThunk({ filters: rest }));
              } else {
                const newFilters = { ...filters, convertedVehicles: undefined };
                dispatch(setNumberOfConvertedVehiclesFilters(newFilters));
                reportIsVisible && dispatch(getNumberOfConvertedVehiclesReportThunk({ filters: newFilters }));
              }
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
      </div>
      {isTableView
        ? <NumberOfConvertedVehiclesTable
          data={tableData}
          totalCount={tableData.length}
        />
        : <NumberOfConvertedVehiclesChart />}
    </>
  );
};

export default NumberOfConvertedVehiclesContent;
