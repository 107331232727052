import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { leaveRequestOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import {
  accountLeaveRequestsFiltersSelector,
  accountLeaveRequestsListSelector,
  currentUserSelector,
} from '../../../../../store/selectors/accountSelectors';
import { getAccountLeaveRequestsThunk } from '../../../../../store/thunks/account/accountThunks';
import { LeaveRequestOrdering } from '../../../../../core/enums/dictionariesEnums';
import { setAccountLeaveRequestsFilters } from '../../../../../store/slices/accountSlice';
import { useLeaveRequestsComponents } from '../../../../../common/hooks/useLeaveRequestsComponents';

export const useLoadAccountLeaveRequestsData = () => {
  const dispatch = useAppDispatch();
  const leaveRequestOrdering = useAppSelector(leaveRequestOrderingSelector);
  const filters = useAppSelector(accountLeaveRequestsFiltersSelector);
  const { items, totalCount } = useAppSelector(accountLeaveRequestsListSelector);
  const currentUser = useAppSelector(currentUserSelector);

  useLeaveRequestsComponents();

  useEffect(() => {
    if (filters.order?.field && filters.employeeIds) {
      dispatch(getAccountLeaveRequestsThunk({ filters }));
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (!filters.order?.field && leaveRequestOrdering.length && currentUser?.employeeId) {
      const orderField = leaveRequestOrdering.find((el) => el.label === LeaveRequestOrdering.CreationDate)?.value;
      if (orderField) {
        dispatch(setAccountLeaveRequestsFilters({
          ...filters,
          order: { field: orderField, isAsc: false },
          employeeIds: [currentUser.employeeId],
        }));
      }
    }
    // eslint-disable-next-line
  }, [leaveRequestOrdering, currentUser?.employeeId]);

  return { items, totalCount, filters };
};
