import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DictionaryItem, DictionaryResponse } from '../../core/types/coreTypes';
import {
  getCustomerEventTypesThunk,
  getEmployeeEventTypesThunk,
  getEnquiryEventTypesThunk,
  getJobEventTypesThunk,
  getOrderEventTypesThunk,
  getPartRequestEventTypesThunk,
  getPartsKitEventTypesThunk,
  getPartViewEventTypesThunk,
  getPricelistEventTypesThunk,
  getPurchaseOrderEventTypesThunk,
  getRectificationEventTypesThunk,
  getSupplierEventTypesThunk,
  getVehicleEventTypesThunk,
  getUserEntityEventTypesThunk,
  getSoldProductsByCustomerReportEntityTypesThunk,
} from '../thunks/core/coreEventTypesThunks';

interface ICoreEventTypesState {
  enquiryEventTypes: DictionaryItem[],
  rectificationEventTypes: DictionaryItem[],
  orderEventTypes: DictionaryItem[],
  supplierEventTypes: DictionaryItem[],
  pricelistEventTypes: DictionaryItem[],
  jobEventTypes: DictionaryItem[],
  partsKitEventTypes: DictionaryItem[],
  employeeEventTypes: DictionaryItem[],
  partViewEventTypes: DictionaryItem[],
  purchaseOrderEventTypes: DictionaryItem[],
  customerEventTypes: DictionaryItem[],
  partRequestEventTypes: DictionaryItem[],
  vehicleEventTypes: DictionaryItem[],
  userEntityEventTypes: DictionaryItem[],
  soldProductsByCustomerReportEntityTypes: DictionaryItem[],
}

export const initialCoreEventTypesState: ICoreEventTypesState = {
  enquiryEventTypes: [],
  rectificationEventTypes: [],
  orderEventTypes: [],
  partsKitEventTypes: [],
  supplierEventTypes: [],
  pricelistEventTypes: [],
  jobEventTypes: [],
  employeeEventTypes: [],
  partViewEventTypes: [],
  purchaseOrderEventTypes: [],
  customerEventTypes: [],
  partRequestEventTypes: [],
  vehicleEventTypes: [],
  userEntityEventTypes: [],
  soldProductsByCustomerReportEntityTypes: [],
};

export const coreEventTypesSlice = createSlice({
  name: 'coreEventTypes',
  initialState: initialCoreEventTypesState,
  reducers: {},
  // Later, dispatch the thunk as needed in the app
  extraReducers: (builder) => {
    builder.addCase(getEnquiryEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquiryEventTypes = action.payload.data.items;
    });
    builder.addCase(getRectificationEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationEventTypes = action.payload.data.items;
    });
    builder.addCase(getOrderEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.orderEventTypes = action.payload.data.items;
    });
    builder.addCase(getPartsKitEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partsKitEventTypes = action.payload.data.items;
    });
    builder.addCase(getEmployeeEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.employeeEventTypes = action.payload.data.items;
    });
    builder.addCase(getSupplierEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.supplierEventTypes = action.payload.data.items;
    });
    builder.addCase(getPricelistEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistEventTypes = action.payload.data.items;
    });
    builder.addCase(getJobEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.jobEventTypes = action.payload.data.items;
    });
    builder.addCase(getPartViewEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partViewEventTypes = action.payload.data.items;
    });
    builder.addCase(getPurchaseOrderEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.purchaseOrderEventTypes = action.payload.data.items;
    });
    builder.addCase(getCustomerEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.customerEventTypes = action.payload.data.items;
    });
    builder.addCase(getPartRequestEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partRequestEventTypes = action.payload.data.items;
    });
    builder.addCase(getVehicleEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleEventTypes = action.payload.data.items;
    });
    builder.addCase(getUserEntityEventTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.userEntityEventTypes = action.payload.data.items;
    });
    builder.addCase(getSoldProductsByCustomerReportEntityTypesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.soldProductsByCustomerReportEntityTypes = action.payload.data.items;
    });
  },
});

export default coreEventTypesSlice.reducer;
