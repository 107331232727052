import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faPencil, faPercent } from '@fortawesome/free-solid-svg-icons';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import Tr from '../../../../../core/components/table-components/Tr';
import Thead from '../../../../../core/components/table-components/Thead';
import {
  SearchTableFilterType, VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  partRequestLinesFiltersSelector,
} from '../../../../../store/selectors/ordersSelector';
import { setPartRequestLinesFilters } from '../../../../../store/slices/ordersSlice';
import { patchOrderThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import { orderDetailsSelector } from '../../../../../store/selectors/sharedSelectors';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { ICustomColumn } from '../../../../../common/types/commonTypes';
import ReasonModal from '../../../../../common/components/reason-modal/ReasonModal';

type OrderPartReqColumnsProps = {
  columns: ICustomColumn[];
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
  totalElements: number;
  disabled: boolean;
}

const OrderPartReqColumns: React.FC<OrderPartReqColumnsProps> = ({
  checkAll, isAll, isIndeterminate, disabled, columns, totalElements,
}) => {
  const orderDetails = useAppSelector(orderDetailsSelector);
  const dispatch = useAppDispatch();
  const partRequestLinesFilters = useAppSelector(partRequestLinesFiltersSelector);

  const allowedToManageLines = usePermission(PermissionEnum.OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions);
  const allowedToManageCharge = usePermission(PermissionEnum.OrderMarkPartLineAsFreeOfChargeBilled);
  const anyAllowed = allowedToManageLines || allowedToManageCharge;

  const [reasonModal, setReasonModal] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productName: partRequestLinesFilters?.productName,
    productNumber: partRequestLinesFilters?.productNumber,
    notes: partRequestLinesFilters?.notes,
  });

  const filters = [
    {
      dataIndex: 'categoryId',
      value: partRequestLinesFilters?.categories || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        categories: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'priceSources',
      value: partRequestLinesFilters?.priceSources || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        priceSources: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'status',
      value: partRequestLinesFilters?.statuses || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        statuses: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (partRequestLinesFilters?.order?.field === field) {
      dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        order: { ...partRequestLinesFilters.order, isAsc: !partRequestLinesFilters.order?.isAsc },
      }));
    } else {
      dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        order: { field, isAsc: true },
      }));
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        [field]: undefined,
      }));
    }
  };
  const searchPressHandle = (field: string) => {
    dispatch(setPartRequestLinesFilters({
      ...partRequestLinesFilters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const enableNotes = () => {
    orderDetails?.order?.id && dispatch(patchOrderThunk({
      id: orderDetails.order.id,
      data: { enableNotes: !orderDetails.order.enableNotes },
    }));
  };
  const enableDiscount = () => {
    orderDetails?.order?.id && dispatch(patchOrderThunk({
      id: orderDetails.order.id,
      partRequestId: orderDetails.order.partRequestId,
      data: {
        enableDiscounts: !orderDetails.order.enableDiscounts,
        partRequestLineActionReason: reasonValue ? reasonValue.trim() : null,
      },
      openReasonModal: () => setReasonModal(true),
      onClose: () => reasonModal && setReasonModal(false),
    }));
  };

  const linesActions = useMemo(() => [
    {
      label: orderDetails?.order?.enableDiscounts ? 'Disable discounts' : 'Enable discounts',
      icon: <FontAwesomeIcon icon={faPercent} />,
      onClick: enableDiscount,
      key: 'switch-discount',
    },
    {
      label: orderDetails?.order?.enableNotes ? 'Disable notes' : 'Enable notes',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: enableNotes,
      key: 'switch-notes',
    },
    // eslint-disable-next-line
  ], [orderDetails?.order]);

  return (
    <>
      <ReasonModal
        visible={reasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => setReasonModal(false)}
        onSubmit={enableDiscount}
        type="update"
      />
      <Thead>
        <Tr>
          {allowedToManageLines && (
            <ThNew checkbox>
              <Checkbox
                checked={isAll}
                indeterminate={isIndeterminate}
                onChange={checkAll}
                disabled={totalElements === 0 || disabled}
                table
              />
            </ThNew>
          )}
          {columns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            totalElements={totalElements}
            disabled={disabled}
            filters={filters}
            searchVl={searchVl}
            tableFilters={partRequestLinesFilters}
            className={`orderPartRequestTh--${col.dataIndex}`}
          />)}
          {anyAllowed && (
            <ThNew action>
              {allowedToManageLines && (
                <Dropdown
                  icon={<FontAwesomeIcon icon={faCog} />}
                  menuItems={linesActions}
                  disabled={disabled}
                />
              )}
            </ThNew>
          )}
        </Tr>
      </Thead>
    </>
  );
};

export default OrderPartReqColumns;
