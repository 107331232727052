import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Radio as RadioButton, Tooltip } from 'antd';
import Radio from '../radio/Radio';
import './RadioGroup.scss';

type RadioGroupProps = {
  options: Array<{ label: string, value: string | number, disabled?: boolean }>;
  value: string | number | null;
  onChange?: (value: string | number) => void;
  name?: string;
  disabled?: boolean;
  optionType?: 'default' | 'button';
  label?: string;
  error?: string;
  className?: string;
  tip?: string,
}

const RadioGroup: React.FC<RadioGroupProps> = ({
  options,
  value,
  onChange,
  name,
  disabled,
  optionType,
  label,
  error,
  className,
  tip,
}) => {
  return (
    <div className={classnames(className, 'customRadioGroup')}>
      {label && <span className="customRadioGroup-label">
        {label}
        {tip && <Tooltip title={tip}>
          {' '}
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>}
      </span>}
      <RadioButton.Group
        name={name}
        value={value}
        onChange={(e) => onChange && onChange(e.target.value)}
        disabled={disabled}
        optionType={optionType}
        className="customRadioGroup-group"
      >
        {options.map((el) => <Radio key={el.value} value={el.value} label={el.label} disabled={el.disabled} />)}
      </RadioButton.Group>
      {error && <span className="customRadioGroup-error">{error}</span>}
    </div>
  );
};

export default RadioGroup;
