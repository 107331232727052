import React, { useEffect } from 'react';
import { DatePicker } from 'antd';
import { Moment } from 'moment';
import { BooleanFunctionType, FocusEventHandler } from '../../types/coreTypes';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';
import './RangePickerComponent.scss';

type RangePickerProps = {
  setIsPickerCanClosedHandler?: BooleanFunctionType;
  value?: [Moment | null, Moment | null];
  onChange?: (dates: [Moment | null, Moment | null] | null) => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  allowEmpty?: [boolean, boolean];
  disabled?: boolean;
  format?: string | string[];
  fieldRef?: React.MutableRefObject<null>;
  allowClear?: boolean;
  parentRender?: boolean,
  disabledDate?: (current: Moment) => boolean,
}

const { RangePicker } = DatePicker;

const RangePickerComponent: React.FC<RangePickerProps> = ({
  value,
  onChange,
  allowEmpty,
  onBlur,
  disabled,
  format,
  fieldRef,
  allowClear = true,
  setIsPickerCanClosedHandler,
  parentRender,
  disabledDate,
}) => {
  const { windowWidth } = useScreenWitdh();

  // we need to disable input on mobile since the visible keyboard leads to bugs
  // but it is still possible to open calendar by clicking on input
  useEffect(() => {
    const inputsArray = Array.from(document.querySelectorAll('.ant-picker-range input'));
    if (windowWidth <= responsiveEndpointsEnum.LG) {
      inputsArray.forEach((el) => el.setAttribute('disabled', 'true'));
    } else {
      !disabled && inputsArray.forEach((el) => el.removeAttribute('disabled'));
    }
  }, [disabled, windowWidth]);

  return (
    <RangePicker
      ref={fieldRef}
      value={value}
      onChange={(dates) => onChange && onChange(dates)}
      onBlur={(v) => {
        onBlur && onBlur(v);
      }}
      allowEmpty={allowEmpty}
      disabled={disabled}
      format={format}
      allowClear={allowClear}
      onOpenChange={(value) => {
        setIsPickerCanClosedHandler && setIsPickerCanClosedHandler(!value);
      }}
      getPopupContainer={parentRender ? (trigger) => trigger.parentElement as HTMLElement : undefined}
      disabledDate={disabledDate}
    />
  );
};

export default RangePickerComponent;
