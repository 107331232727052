import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import AffectedEntitiesModal from '../../../../../../common/components/affected-entities-modal/AffectedEntitiesModal';
import { initDataList, initSmallPaging, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  pricelistAffectedCellsDataSelector,
  pricelistAffectedFiltersSelector,
} from '../../../../../../store/selectors/pricelistSelectors';
import { setPricelistAffectedFilters, setPricelistAffectedItems } from '../../../../../../store/slices/pricelistSlice';
import { getPricelistAffectedThunk } from '../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import AffectedEntitiesWithLinkTable from '../../../../../../common/components/affected-entities-modal/AffectedEntitiesWithLinkTable';
import { isGlobalLoadingSelector } from '../../../../../../store/selectors/coreSelectors';

type PricelistAffectedEntitiesModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  pricelistName: string,
}

const PricelistAffectedEntitiesModal: React.FC<PricelistAffectedEntitiesModalProps> = ({
  visible,
  onClose,
  pricelistName,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const filters = useAppSelector(pricelistAffectedFiltersSelector);
  const { items, totalCount } = useAppSelector(pricelistAffectedCellsDataSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);

  useEffect(() => {
    if (visible && id) {
      dispatch(getPricelistAffectedThunk({
        pricelistId: +id,
        filters,
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const handleClose = () => {
    onClose();
    dispatch(setPricelistAffectedFilters(initSmallPaging));
    dispatch(setPricelistAffectedItems(initDataList));
  };

  const handlePagination = (page: number, pageSize: number) => {
    dispatch(setPricelistAffectedFilters({ ...filters, page, pageSize }));
  };

  const text = <>
    Operation cannot be finished, because pricelist
    {' '}
    <b>{`"${pricelistName}"`}</b>
    {' '}
    is used in multiple active entities in the system:
  </>;

  return (
    <AffectedEntitiesModal
      visible={visible}
      handleClose={handleClose}
      text={text}
      handlePagination={handlePagination}
      page={filters.page}
      pageSize={filters.pageSize}
      totalCount={totalCount}
    >
      <AffectedEntitiesWithLinkTable
        items={items}
        isLoading={isLoading}
      />
    </AffectedEntitiesModal>
  );
};

export default PricelistAffectedEntitiesModal;
