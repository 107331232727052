import React from 'react';
import EmployeeViewPageTabs from './components/EmployeeViewPageTabs';
import { useEmployeeViewTabs } from '../../hooks/useEmployeeViewTabs';
import EmployeeContent from './EmployeeContent';

const EmployeeDynamicAreaContainer: React.FC = () => {
  const {
    tabs,
    params,
    setEmployeeTabParams,
  } = useEmployeeViewTabs();

  return (
    <section>
      <EmployeeViewPageTabs tabs={tabs} currentTab={params.get('tab') || ''} setModeParams={setEmployeeTabParams} />
      <EmployeeContent mode={params.get('tab')} />
    </section>
  );
};

export default EmployeeDynamicAreaContainer;
