import React, { useEffect, useState } from 'react';
import mapValues from 'lodash/mapValues';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import Select from '../../../../../core/components/select/Select';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import Textarea from '../../../../../core/components/textarea/Textarea';
import {
  addressFieldsKeys,
  defaultVehicleDetailsFields,
  vehicleFieldsToValidateKeys,
} from '../../../utils/vehicleDataUtil';
import { CustomAny, VehicleDto } from '../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  vehicleAddressDetailsSelector,
  vehicleDetailsSelector,
} from '../../../../../store/selectors/vehiclesSelectors';
import Input from '../../../../../core/components/input/Input';
import AddressField from '../../../../../common/components/address/AddressField';
import { AddressFields, initAddressFields } from '../../../../../common/types/commonTypes';
import { useVehicleDictionaries } from '../../../../../common/hooks/useVehicleDictionaries';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import AddressModal from '../../../../../common/components/address/AddressModal';
import CommonVehicleFields from '../../../../../common/components/vehicle-modal/CommonVehicleFields';
import { customersListSelector } from '../../../../../store/selectors/customersSelectors';
import { customerCompleteStatusSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import VehicleManualFields from '../../../../../common/components/vehicle-modal/VehicleManualFields';
import ExistedChosenVehicleForm from '../../../../../common/components/vehicle-modal/ExistedChosenVehicleForm';
import { VehicleProfileSearchType } from '../../../../vehicle-profiles/types/vehicleProfilesTypes';
import { vehicleProfilesTypesUiSelector } from '../../../../../store/selectors/coreSelectors';
import { patchVehicleThunk } from '../../../../../store/thunks/vehicles/vehicleViewPageThunks';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import './VehicleDetailsSection.scss';
import { getCustomers } from '../../../../../store/thunks/customers/customersDashboardThunks';

const VehicleDetailsSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    vehicleCatalogSource,
    vehicleFlows,
    vehicleInRepairStatus,
  } = useVehicleDictionaries();
  const allowedToEdit = usePermission(PermissionEnum.VehicleEditFields);
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const vehicleDetailsLocation = useAppSelector(vehicleAddressDetailsSelector);
  const vehicleInfoSource = vehicleDetails?.vehicleInfoSource;
  const isVehicleSourceEqualsCatalog = vehicleInfoSource === vehicleCatalogSource?.value;
  const customerCompleteStatus = useAppSelector(customerCompleteStatusSelector);
  const vehicleProfileTypes = useAppSelector(vehicleProfilesTypesUiSelector);
  const isVehicleInRepair = vehicleDetails?.status === vehicleInRepairStatus;
  const [chosenVehicleProfile, setChosenVehicleProfile] = useState<VehicleProfileSearchType | null>(null);
  const [isChangeFlow, setIsChangeFlow] = useState(false);
  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [customerKeyword, setCustomerKeyword] = useState<string | undefined>(undefined);
  const [searchError, setSearchError] = useState<string | undefined>(undefined);

  const {
    control, setValue, setError, watch, clearErrors,
  } = useForm<CustomAny>({
    defaultValues: defaultVehicleDetailsFields,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const vehFlowValue = watch('vehicleFlow');

  useEffect(() => {
    if (vehicleDetails) {
      setValue('customerId', vehicleDetails.customerId);
      setValue('vehicleInfoSource', isVehicleSourceEqualsCatalog ? 'Catalog' : 'Manual input');
      setValue('slidingDoorsQuantity', vehicleDetails.slidingDoorsQuantity);
      setValue('grossVehicleWeightKg', vehicleDetails.grossVehicleWeightKg.toString());
      setValue('maxPayload', vehicleDetails.maxPayload.toString());
      setValue('chassisNumber', vehicleDetails.chassisNumber);
      setValue('regNumber', vehicleDetails.regNumber);
      setValue('vehicleFlow', vehicleDetails.vehicleFlow);
      setValue('additionalInfo', vehicleDetails.additionalInfo);
      setAddressFields(vehicleDetailsLocation);

      if (!isVehicleSourceEqualsCatalog) {
        setValue('manufacturer', vehicleDetails.manufacturer);
        setValue('type', vehicleDetails.type);
        setValue('group', vehicleDetails.group);
        setValue('modelDescription', vehicleDetails.modelDescription);
      } else {
        setChosenVehicleProfile({
          id: vehicleDetails.id,
          number: 1,
          code: vehicleDetails.vehicleCode,
          manufacturer: vehicleDetails.manufacturer,
          group: vehicleDetails.group || '-',
          modelDescription: vehicleDetails.modelDescription,
          type: vehicleProfileTypes.find((el) => el.value === vehicleDetails.type)?.label || vehicleDetails.type,
          maxPayload: vehicleDetails.maxPayload,
          grossVehicleWeightKg: vehicleDetails.grossVehicleWeightKg,
        });
      }
    }
    // eslint-disable-next-line
  }, [vehicleDetails]);

  const changeHandler = (values: Partial<VehicleDto>) => {
    vehicleDetails && dispatch(patchVehicleThunk({
      vehicleId: vehicleDetails?.id,
      data: values,
      closeModal: () => isChangeFlow && setIsChangeFlow(false),
      setPrevFlowValue: () => setValue('vehicleFlow', vehicleDetails.vehicleFlow),
    }));
  };

  const changeVehicleDetails = (values: Partial<VehicleDto>, key: CustomAny, isOptional?: boolean) => {
    if (vehicleDetails) {
      compareValuesHandle<VehicleDto>(values, key, vehicleDetails, () => changeHandler(values), false, isOptional);
    }
  };
  const changeAddressDetails = (fields: AddressFields, keys: Array<keyof VehicleDto>) => {
    if (vehicleDetails) {
      const finalField = mapValues(fields, (value) => (value === null ? '' : value));
      const keysNotEqual = keys.some((key) => vehicleDetails[key] !== finalField[key as keyof AddressFields]);

      if (keysNotEqual) {
        changeHandler(finalField);
      } else {
        setAddressModal(false);
      }
    }
  };

  const clearOtherErrorsFn = (field: string) => {
    clearOtherFieldsErrorFn(vehicleFieldsToValidateKeys, field, clearErrors, errors, vehicleDetails, setValue);
  };

  const customersList = useAppSelector(customersListSelector);
  const customersOptions = customersList.totalCount > 0
    ? customersList.items.map((el) => ({
      label: <>
        <FontAwesomeIcon icon={el.customerStatus === customerCompleteStatus ? faClipboardCheck : faQuestionCircle} />
        {' '}
        {`${el.customerAccountNumber} ${el.name}`}
      </>,
      value: el.id,
    }))
    : [];

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (customerKeyword) {
      const timeOutId = setTimeout(() => {
        dispatch(getCustomers({ params: { keyword: customerKeyword.trim(), excludeCompany: true } }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getCustomers({ params: { keyword: undefined } }));
    }
    // eslint-disable-next-line
  }, [customerKeyword]);

  useEffect(() => {
    return () => {
      setChosenVehicleProfile(null);
      setAddressFields(initAddressFields);
    };
  }, []);
  return (
    <>
      <ConfirmationModal
        isVisible={isChangeFlow}
        customTitle="Change vehicle flow"
        customText={<>
          You are about to change the status flow of a vehicle. As a result the status of a vehicle will be reset to
          <b>{' "New" '}</b>
          .
        </>}
        customCreateLabel="Yes, change flow"
        confirmHandler={() => changeVehicleDetails({ vehicleFlow: vehFlowValue }, 'vehicleFlow')}
        onCancel={() => {
          setIsChangeFlow(false);
          setValue('vehicleFlow', vehicleDetails?.vehicleFlow);
        }}
      />
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
          changeAddressDetails({ ...values }, addressFieldsKeys);
        }}
        isOptionalFields
        title="Location"
        isExtendedFields
        addressFields={addressFields}
      />
      <section className="info-grid__column vehicleDetailsSection">
        <h2 className="info-grid__title">Details</h2>
        <form className="details-form">
          <Controller
            name="customerId"
            control={control}
            rules={{
              required: ErrorsEnum.REQUIRED,
            }}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  clearOtherErrorsFn('customerId');
                  changeVehicleDetails({ customerId: v as number }, 'customerId');
                }}
                options={customersOptions}
                error={errors?.customerId?.message}
                label="Customer"
                className="details-form__field--lg"
                disabled={!allowedToEdit}
                showSearch
                onSearch={(val) => {
                  if (val.trim()) {
                    if (val.length <= 250) {
                      setCustomerKeyword(val);
                    } else {
                      setSearchError(maxLengthMessageHandle(250));
                    }
                  } else {
                    setCustomerKeyword(undefined);
                  }
                }}
                onSelect={() => {
                  setCustomerKeyword(undefined);
                  searchError && setSearchError('');
                }}
                onBlur={() => {
                  setCustomerKeyword(undefined);
                  searchError && setSearchError('');
                }}
              />
            )}
          />
          <Controller
            name="vehicleInfoSource"
            control={control}
            render={({ field }) => (
              <Input
                value={field.value}
                label="Vehicle info source"
                className="details-form__field--lg"
                disabled
              />
            )}
          />
          {isVehicleSourceEqualsCatalog
            ? <ExistedChosenVehicleForm
              chosenVehicleProfile={chosenVehicleProfile}
              className="details-form__field--lg"
              readOnly
            />
            : <VehicleManualFields
              control={control}
              errors={errors}
              setError={setError}
              setValue={setValue}
              disabled
              isModelDescriptionEditable={allowedToEdit}
              clearOtherErrorsFn={(key) => clearOtherErrorsFn(key)}
              editHandler={(v: Partial<VehicleDto>, key: string) => changeVehicleDetails(v, key)}
            />}
          <CommonVehicleFields
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            areWeightFieldsRequired
            clearOtherErrorsFn={(key) => clearOtherErrorsFn(key)}
            editHandler={(v: Partial<VehicleDto>, key: string) => changeVehicleDetails(v, key)}
            disabled={!allowedToEdit}
          />
          <Controller
            control={control}
            name="vehicleFlow"
            rules={{ required: ErrorsEnum.REQUIRED }}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  setIsChangeFlow(true);
                }}
                label="Vehicle flow"
                options={vehicleFlows}
                className="details-form__field--lg"
                error={errors?.vehicleFlow?.message}
                disabled={isVehicleInRepair || !allowedToEdit}
              />
            )}
          />
          <AddressField
            addressFields={addressFields}
            setAddressModal={(v) => {
              setAddressModal(v);
            }}
            title="Location"
            isIconVisible={Object.values(addressFields).some((el) => el)}
            disabled={!allowedToEdit}
          />
          <Controller
            name="additionalInfo"
            control={control}
            rules={{ maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } }}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsFn('additionalInfo');
                }}
                onBlur={() => !errors?.additionalInfo && changeVehicleDetails(
                  { additionalInfo: field.value?.trim() || '' },
                  'additionalInfo',
                  true,
                )}
                label="Additional info"
                error={errors?.additionalInfo?.message}
                disabled={!allowedToEdit}
                className="details-form__field--lg"
              />
            )}
          />
        </form>
      </section>
    </>
  );
};

export default VehicleDetailsSection;
