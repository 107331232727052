export enum NominalCodeTypes {
  Sale = 'Sale',
  Purchase = 'Purchase',
}

export enum NominalCodesOrdering {
  Code = 'Code',
  Description = 'Description',
  CreatedDate = 'CreatedDate',
}
