import React from 'react';
import {
  AdvancedMarker,
  InfoWindow, Pin,
  useAdvancedMarkerRef,
} from '@vis.gl/react-google-maps';
import { GenericVoidFunctionType } from '../../../../../../core/types/coreTypes';
import { DARK_BLUE_COLOR, YELLOW_COLOR } from '../utils/data';

type MarkerProps = {
  lat: number,
  lng: number,
  address: string,
  distance: number | string,
  entityNumber: string,
  selectedName: string | null,
  onClick: GenericVoidFunctionType<string | null>,
}

const Marker: React.FC<MarkerProps> = ({
  selectedName, lat, lng, entityNumber, address, distance, onClick,
}) => {
  const [markerRef, marker] = useAdvancedMarkerRef();

  const getColor = (initColor: string, colorWithOpacity: string) => {
    return selectedName === null
      ? initColor
      : selectedName === entityNumber
        ? initColor
        : colorWithOpacity;
  };

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => onClick(entityNumber)}
        position={{ lat, lng }}
        title={entityNumber}
        zIndex={selectedName === entityNumber ? 1 : null}
      >
        <Pin
          background={getColor(YELLOW_COLOR, 'rgba(255, 203, 17, 0.4)')}
          borderColor={getColor(DARK_BLUE_COLOR, 'rgba(58, 69, 87, 0.4)')}
          glyphColor={getColor(DARK_BLUE_COLOR, 'rgba(58, 69, 87, 0.4)')}
          scale={0.75}
        />
      </AdvancedMarker>
      {selectedName === entityNumber && (
        <InfoWindow
          anchor={marker}
          maxWidth={200}
          onCloseClick={() => onClick(null)}
        >
          <b>{entityNumber}</b>
          <br />
          <span>{address}</span>
          <br />
          <b>Distance from office:</b>
          <br />
          {distance}
        </InfoWindow>
      )}
    </>
  );
};

export default Marker;
