import React from 'react';
import { FieldUpdateEventProps } from '../../../../core/types/coreTypes';

const FieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  return (
    <>
      Field
      {' '}
      <b>{field}</b>
      {' '}
      was changed:
      {' '}
      {!!oldValue && <s>{`${oldValue}`}</s>}
      {!!newValue && <b>{` ${newValue}`}</b>}
    </>
  );
};

export default FieldUpdateEvent;
