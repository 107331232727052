import React from 'react';

type StockPartRequestDeleteEventProps = {
  productNumber?: string,
  partRequestLineNumber?: number,
}

const StockPartRequestDeleteEvent: React.FC<StockPartRequestDeleteEventProps> = ({ productNumber, partRequestLineNumber }) => {
  return (
    <>
      Line
      {' '}
      {partRequestLineNumber || 'N/A'}
      {' '}
      with part code
      {' '}
      {productNumber ? <b>{productNumber}</b> : 'N/A'}
      {' '}
      was deleted
    </>
  );
};

export default StockPartRequestDeleteEvent;
