import React, { useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faCheckCircle, faNoteSticky } from '@fortawesome/free-regular-svg-icons';
import { Tooltip } from 'antd';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { faEllipsisVertical, faPencil, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AccountLeaveRequestUi } from '../../types/AccountLeaveRequestsTypes';
import './AccountLeaveRequestItem.scss';
import Tag from '../../../../../../core/components/tag/Tag';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  leaveRequestsDraftStatusSelector,
  leaveRequestsPendingApprovalStatusSelector,
} from '../../../../../../store/selectors/coreStatusesSelectors';
import { changeAccountLeaveRequestStatusThunk, getAccountLeaveRequestThunk } from '../../../../../../store/thunks/account/accountThunks';

type AccountLeaveRequestItemProps = {
  data: AccountLeaveRequestUi,
  setIdToDelete: NumberFunctionType,
}
const AccountLeaveRequestItem: React.FC<AccountLeaveRequestItemProps> = ({ data, setIdToDelete }) => {
  const dispatch = useAppDispatch();
  const draftStatus = useAppSelector(leaveRequestsDraftStatusSelector);
  const pendingApprovalStatus = useAppSelector(leaveRequestsPendingApprovalStatusSelector);
  const isActive = [draftStatus, pendingApprovalStatus].includes(data.status);
  const isDraftStatus = data.status === draftStatus;

  const menuItems = useMemo(() => {
    if (!isActive) return [];
    return [
      {
        label: isDraftStatus ? 'Submit for approval' : 'Mark as Draft',
        key: 'change-status',
        icon: <FontAwesomeIcon icon={isDraftStatus ? faCheckCircle : faNoteSticky} />,
        onClick: () => draftStatus && pendingApprovalStatus && dispatch(changeAccountLeaveRequestStatusThunk({
          id: data.id,
          statusTransition: isDraftStatus ? pendingApprovalStatus : draftStatus,
        })),
      },
      {
        label: 'Edit leave request',
        key: 'edit-leave-request',
        icon: <FontAwesomeIcon icon={faPencil} />,
        onClick: () => dispatch(getAccountLeaveRequestThunk({ id: data.id })),
      },
      {
        label: 'Delete leave request',
        key: 'delete-leave-request',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => setIdToDelete(data.id),
      },
    ];
    // eslint-disable-next-line
  }, [isActive, draftStatus, pendingApprovalStatus, isDraftStatus]);

  return (
    <div className="accountLeaveRequestItem not-link">
      <FontAwesomeIcon icon={faCalendar} className="accountLeaveRequestItem__icon" />
      <div className="accountLeaveRequestItem__type">
        {data.leaveType}
        {data.comment && <Tooltip title={data.comment} overlayClassName="accountLeaveRequestItem__tooltip">
          <InfoCircleOutlined className="accountLeaveRequestItem__info-icon" />
        </Tooltip>}
      </div>
      <ul className="accountLeaveRequestItem__info">
        <li className="accountLeaveRequestItem__info-item">
          <span>Leave dates</span>
          <b>{`${data.startDate} - ${data.endDate}`}</b>
        </li>
        <li className="accountLeaveRequestItem__info-item">
          <span>Leave period</span>
          <b>{data.leavePeriod}</b>
        </li>
      </ul>
      <div className="accountLeaveRequestItem__tag-wrapper">
        <Tag label={data.statusName} />
      </div>
      <div className="accountLeaveRequestItem__actions-wrapper">
        {isActive && (
          <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={menuItems}
          />
        )}
      </div>
    </div>
  );
};

export default AccountLeaveRequestItem;
