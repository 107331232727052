import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import RegularInputNumber from '../../../../../../core/components/input-number/RegularInputNumber';
import { maxCommonDecimal, maxCommonWhole } from '../../../../../../core/utils/regex';
import DecimalInputNumber from '../../../../../../core/components/input-number/DecimalInputNumber';
import {
  integerQuantityMeasureValuesSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  getPartsKitAffectedThunk,
  patchPartsKitComponentThunk,
} from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import {
  partsKitAffectedItemsFiltersSelector, partsKitComponentTaxSelector, partsKitDetailsSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import Select from '../../../../../../core/components/select/Select';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { setErrorMessage } from '../../../../../../store/slices/coreSlice';
import { ErrorsEnum } from '../../../../../../core/enums/errorsEnum';

type EditableComponentCellProps = {
  value: string | number | null,
  cellKey: string,
  data: {
    id: number,
    number: number,
    categoryId: string | number,
    partNumber: string,
    partName: string,
    quantity: string | number,
    unitPrice: string,
    netPrice: string,
    tax: number,
    weight: string,
    unitOfMeasure: number,
  },
}

const EditableComponentCell: React.FC<EditableComponentCellProps> = ({ value, cellKey, data }) => {
  const { id } = useParams();
  const [editMode, setEditMode] = useState<boolean>(false);
  const [inputV, setInputV] = useState<string>('');
  const [taxValue, setTaxValue] = useState<number | undefined>(undefined);
  const dispatch = useAppDispatch();
  const taxes = useAppSelector(partsKitComponentTaxSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const affectedItemsFilters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);

  const confirmChange = (lineId: number, val: number, isTax?: boolean) => {
    id && dispatch(patchPartsKitComponentThunk({
      id: lineId,
      partsKitId: +id,
      data: isTax ? { tax: val } : { quantity: val },
      closeModal: () => {
        setEditMode(false);
        setInputV('');
      },
    }));
  };

  const changeHandle = (v: string | number) => setInputV(v as string);
  const changeTaxHandle = (v: number) => setTaxValue(v);
  const editRow = (lineId: number, val: number, isTax?: boolean) => {
    partsKitDetails && dispatch(getPartsKitAffectedThunk({
      id: partsKitDetails.id,
      filters: affectedItemsFilters,
      complexAction: () => setEditMode(false),
      confirmAction: () => confirmChange(lineId, val, isTax),
    }));
  };
  const numericFieldBlurHandle = (value: string) => {
    const unsetEditMode = () => {
      setEditMode(false);
      setInputV('');
    };
    if (value) {
      if (+value === +data.quantity) {
        unsetEditMode();
      } else {
        if (+value > 0) {
          editRow(data.id, +value);
        } else {
          dispatch(setErrorMessage({ message: ErrorsEnum.GREATER_THAN_ZERO, toastId: Math.random() }));
          unsetEditMode();
        }
      }
    } else {
      dispatch(setErrorMessage({ message: ErrorsEnum.REQUIRED, toastId: Math.random() }));
    }
  };

  useEffect(() => {
    if (editMode && cellKey === 'tax') {
      value && setTaxValue(value as number);
    }
    if (editMode && cellKey === 'quantity') {
      setInputV(value as string);
    }
  }, [editMode, value, cellKey]);

  const inputTypeRenderer = (key: string) => {
    if (key === 'tax') {
      return <Select
        options={taxes}
        value={taxValue}
        defaultOpen
        onChange={(v) => {
          if (v === data.tax) {
            setEditMode(false);
            setTaxValue(undefined);
          } else {
            changeTaxHandle(v as number);
            editRow(data.id, v as number, true);
          }
        }}
        onBlur={() => {
          if (taxValue === data.tax) {
            setEditMode(false);
            setTaxValue(undefined);
          }
        }}
      />;
    } else {
      if (integerMeasures.includes(data.unitOfMeasure)) {
        return <RegularInputNumber
          value={inputV}
          onChange={(e) => {
            const v = e.target.value;
            changeHandle(v);
          }}
          max={maxCommonWhole}
          autoFocus
          onBlur={(e) => {
            const v = e.target.value;
            numericFieldBlurHandle(v);
          }}
          className="table-inputNumber"
        />;
      } else {
        return <DecimalInputNumber
          value={inputV}
          onChange={(e) => {
            const v = e.target.value.replace(',', '.');
            changeHandle(v);
          }}
          max={maxCommonDecimal}
          autoFocus
          onBlur={(e) => {
            const v = e.target.value;
            numericFieldBlurHandle(v);
          }}
          className="table-inputNumber"
        />;
      }
    }
  };

  const renderValue = (key: string, value: string | number | boolean | null, measure?: number) => {
    if (key === 'quantity') {
      const unitOfMeasureLabel = unitsOfMeasure.find((m) => m.value === measure)?.label || '';
      return `${value} ${unitOfMeasureLabel}`;
    }
    if (key === 'tax') {
      if (value === null) {
        return '-';
      } else {
        return `${value} %`;
      }
    } else {
      return value || '-';
    }
  };

  return (
    <TdNew>
      {editMode
        ? <>{inputTypeRenderer(cellKey)}</>
        : <div
          className="editableComponentCell"
          onClick={() => setEditMode(true)}
        >
          {renderValue(cellKey, value, data.unitOfMeasure)}
        </div>}
    </TdNew>

  );
};

export default EditableComponentCell;
