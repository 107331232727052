import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { PatchUserAccountType, UserAccountDto } from '../../types/userProfileTypes';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import Select from '../../../../core/components/select/Select';
import Input from '../../../../core/components/input/Input';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  accountAccountTypesSelector, jobTypesSelector, permissionUiRolesSelector, shortBusinessAreasSelector,
} from '../../../../store/selectors/coreSelectors';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import { patchUserAccountThunk } from '../../../../store/thunks/settings/userViewPageThunks';
import { getAccountPermissionRoles } from '../../../../store/thunks/coreThunk';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { clearOtherFieldsErrorFn } from '../../../../core/utils/clearOtherFieldsErrorFn';
import {
  compareValuesHandle,
} from '../../../../core/utils/getDifferenceBetweenObjects';
import {
  EmailFieldSchema, NameFieldSchema, userDefaults, UserDetailsFormFields,
} from '../../../create-user/utils/UserShema';
import { YesNoOptions } from '../../../../core/utils/testData';

type UserDetailsProps = {
  userProfile: UserAccountDto | null,
}

const UserDetails: React.FC<UserDetailsProps> = ({ userProfile }) => {
  const dispatch = useAppDispatch();
  const allowedToEdit = usePermission(PermissionEnum.UserEditFields);
  const currentUser = useAppSelector(currentUserSelector);
  const accountTypes = useAppSelector(accountAccountTypesSelector);
  const accountRoles = useAppSelector(permissionUiRolesSelector);
  const baList = useAppSelector(shortBusinessAreasSelector);
  const jobTypes = useAppSelector(jobTypesSelector);
  const isUserLinkedToAnEmployee = !!userProfile?.employeeId;
  const {
    control, setValue, handleSubmit, clearErrors, setError, watch,
  } = useForm<UserDetailsFormFields>({
    defaultValues: {
      ...userDefaults,
      employeeReferenceNumber: null,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const jobType = watch('allowedJobTypes');
  const businessArea = watch('allowedBusinessAreas');
  const onSubmit = () => {
    console.debug('submit');
  };

  useEffect(() => {
    if (userProfile) {
      Object.keys(userProfile).forEach((key) => {
        if (key !== 'id') {
          if (key === 'canSeeOnlyAssignedEntities') {
            setValue('canSeeOnlyAssignedEntities', userProfile?.canSeeOnlyAssignedEntities ? 1 : 0);
          } else if (key === 'userAccountRole') {
            setValue('role', `${userProfile.userAccountRole.role}-${userProfile.userAccountRole.roleLevel}`);
          } else {
            // eslint-disable-next-line
            // @ts-ignore
            setValue(key, userProfile[key]);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [userProfile]);

  useEffect(() => {
    if (userProfile?.accountType) {
      dispatch(getAccountPermissionRoles({ accountType: userProfile.accountType }));
    }
    // eslint-disable-next-line
  }, [userProfile?.accountType]);

  const fieldsToValidate = ['firstName', 'lastName', 'allowedJobTypes', 'allowedBusinessAreas'];
  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, userProfile, setValue);
  };
  const patchUserInfo = (data: Partial<PatchUserAccountType>, key: keyof PatchUserAccountType, deepCompare?: boolean) => {
    if (userProfile) {
      const handler = () => {
        dispatch(patchUserAccountThunk({
          id: userProfile?.id,
          data,
        }));
      };
      compareValuesHandle<PatchUserAccountType>(data, key, userProfile, handler, deepCompare);
    }
  };
  return (
    <div className="info-grid__column">
      <h2 className="info-grid__title">Details</h2>
      <form className="userDetailsForm details-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="firstName"
          rules={NameFieldSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('firstName');
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('firstName', '');
                  setError('firstName', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  patchUserInfo({ firstName: v.trim() }, 'firstName');
                }
              }}
              error={errors?.firstName?.message}
              label="First name"
              disabled={!allowedToEdit || isUserLinkedToAnEmployee}
              suffix={isUserLinkedToAnEmployee && <Tooltip
                title="Please go to Employee view page to update this value."
                overlayClassName="userDetailsForm__tooltip"
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Tooltip>}
            />
          )}
        />
        <Controller
          control={control}
          name="lastName"
          rules={NameFieldSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('lastName');
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('lastName', '');
                  setError('lastName', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  patchUserInfo({ lastName: v.trim() }, 'lastName');
                }
              }}
              error={errors?.lastName?.message}
              disabled={!allowedToEdit || isUserLinkedToAnEmployee}
              label="Last name"
              suffix={isUserLinkedToAnEmployee && <Tooltip
                title="Please go to Employee view page to update this value."
                overlayClassName="userDetailsForm__tooltip"
              >
                <FontAwesomeIcon icon={faExclamationCircle} />
              </Tooltip>}
            />
          )}
        />
        <Controller
          control={control}
          name="email"
          rules={EmailFieldSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              error={errors?.email?.message}
              label="Email"
              disabled
            />
          )}
        />
        <Controller
          control={control}
          name="accountType"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={accountTypes}
              value={field.value}
              onChange={field.onChange}
              error={errors?.accountType?.message}
              label="Account type"
              disabled
            />
          )}
        />
        <Controller
          control={control}
          name="role"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('role');
                const value = v as string;
                patchUserInfo(
                  { userAccountRole: { role: +value.split('-')[0], roleLevel: +value.split('-')[1] } },
                  'userAccountRole',
                );
              }}
              options={accountRoles}
              showSearch
              isFilterOption
              error={errors?.role?.message}
              label="Role"
              disabled={currentUser?.id === userProfile?.id || !allowedToEdit}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="allowedJobTypes"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={jobTypes}
              mode="multiple"
              showArrow
              value={field.value}
              onBlur={() => patchUserInfo({ allowedJobTypes: jobType }, 'allowedJobTypes', true)}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('allowedJobTypes');
              }}
              onDeselect={(v) => {
                clearOtherErrorsHandle('allowedJobTypes');
                const newJobs = userProfile?.allowedJobTypes?.filter((type) => type !== v);
                if (newJobs?.length === 0) {
                  setError('allowedJobTypes', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  newJobs && patchUserInfo({ allowedJobTypes: newJobs }, 'allowedJobTypes', true);
                }
              }}
              error={errors?.allowedJobTypes && ErrorsEnum.REQUIRED}
              label="Allowed job types"
              disabled={currentUser?.id === userProfile?.id || !allowedToEdit}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="allowedBusinessAreas"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('allowedBusinessAreas');
              }}
              onBlur={() => patchUserInfo({ allowedBusinessAreas: businessArea }, 'allowedBusinessAreas', true)}
              onDeselect={(v) => {
                clearOtherErrorsHandle('allowedBusinessAreas');
                const newAreas = userProfile?.allowedBusinessAreas?.filter((area) => area !== v);
                if (newAreas?.length === 0) {
                  setError('allowedBusinessAreas', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  newAreas && patchUserInfo({ allowedBusinessAreas: newAreas }, 'allowedBusinessAreas', true);
                }
              }}
              options={baList}
              mode="multiple"
              showArrow
              error={errors?.allowedBusinessAreas && ErrorsEnum.REQUIRED}
              label="Allowed business areas"
              disabled={currentUser?.id === userProfile?.id || !allowedToEdit}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="canSeeOnlyAssignedEntities"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={YesNoOptions}
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('canSeeOnlyAssignedEntities');
                patchUserInfo({ canSeeOnlyAssignedEntities: v === 1 }, 'canSeeOnlyAssignedEntities');
              }}
              error={errors?.canSeeOnlyAssignedEntities?.message}
              label="Can see only assigned entities"
              disabled={currentUser?.id === userProfile?.id || !allowedToEdit}
            />
          )}
        />
        {userProfile?.employeeReferenceNumber && <Controller
          control={control}
          name="employeeReferenceNumber"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              error={errors?.employeeReferenceNumber?.message}
              label="Employee reference No"
              disabled
            />
          )}
        />}
      </form>
    </div>
  );
};

export default UserDetails;
