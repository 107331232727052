import React from 'react';
import Switch from '../../../../../core/components/switch/Switch';
import { getSalesByCustomerReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { setIsTableView, setSalesByCustomerFilters } from '../../../../../store/slices/reportsSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  reportIsVisibleSelector,
  salesByCustomerFiltersSelector, salesReportByCustomerDataSelector,
} from '../../../../../store/selectors/reportsSelectors';
import Segmented from '../../../../../core/components/segmented/Segmented';
import SalesReportByCustomerTable from './table/SalesReportByCustomerTable';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { SalesReportByCustomerOrdering } from '../../../../enum/ReportTableOrders';
import { getSalesByCustomerReportOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { salesByCustomerReportOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import SalesReportByCustomerChart from './chart/SalesReportByCustomerChart';

const SalesReportByCustomerContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const ordering = useAppSelector(salesByCustomerReportOrderingSelector);
  const filters = useAppSelector(salesByCustomerFiltersSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const isTableView = useAppSelector(isTableViewSelector);
  const { totalCount } = useAppSelector(salesReportByCustomerDataSelector);

  const CHART_COUNT_OPTIONS = [10, 15, 20];

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    shouldFetch: false,
    filters,
    initialOrdering: SalesReportByCustomerOrdering.CompletedDate,
    setFiltersAction: setSalesByCustomerFilters,
    getOrderingThunk: getSalesByCustomerReportOrderingThunk,
    ordering,
  });

  return (
    <>
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              const {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                entityNumber, costNet, salesNet, differenceNet, ...restFilters
              } = filters;
              const newFilters = { ...restFilters, ...defaultPagingAndOrdering };
              dispatch(setSalesByCustomerFilters(newFilters));
              reportIsVisible && dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView
          ? <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              dispatch(setSalesByCustomerFilters({ ...filters, page, pageSize }));
              reportIsVisible && dispatch(getSalesByCustomerReportThunk({ filters: { ...filters, page, pageSize } }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
          : <Segmented
            options={CHART_COUNT_OPTIONS}
            value={filters.pageSize}
            onChange={(value) => {
              const newFilters = { ...filters, page: 1, pageSize: +value };
              dispatch(setSalesByCustomerFilters(newFilters));
              reportIsVisible && dispatch(getSalesByCustomerReportThunk({ filters: newFilters }));
            }}
          />}
      </div>
      {isTableView
        ? <SalesReportByCustomerTable />
        : <SalesReportByCustomerChart />}
    </>
  );
};

export default SalesReportByCustomerContent;
