import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import EmptyVehiclesList from '../../../../../common/components/empty-vehicles-list/EmptyVehiclesList';
import { useAppSelector } from '../../../../../../store/hooks';
import { partsKitDetailsSelector, partsKitSuitableForSelector } from '../../../../../../store/selectors/partsKitSelectors';
import { vehicleProfilesGroupsUiWithoutAllSelector } from '../../../../../../store/selectors/sharedSelectors';

type PartKitsVehiclesProps = {
  openModal: VoidFunctionType,
}

const PartKitsVehicles: React.FC<PartKitsVehiclesProps> = ({ openModal }) => {
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);
  const partsKitSuitableFor = useAppSelector(partsKitSuitableForSelector);
  const availableVehicleGroups = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);

  const suitableForVehicleGroupIds = partsKitDetails?.suitableForVehicleGroupIds;
  const allowedToEdit = usePermission(PermissionEnum.StockPartsKitEditFields);

  const groupsList = availableVehicleGroups.items
    .filter((v) => suitableForVehicleGroupIds?.includes(v.id))
    .map((v) => ({ label: `Group ${v.name} (${v.vehicleProfilesCount})`, value: v.id }));
  const listToRender = suitableForVehicleGroupIds && suitableForVehicleGroupIds.length > 0 ? groupsList : partsKitSuitableFor;

  return (
    <section className="info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">
          Suitable for vehicles
        </h2>
        {allowedToEdit && <Dropdown
          menuItems={[
            {
              label: 'Select vehicles',
              icon: <FontAwesomeIcon icon={faPencil} />,
              key: 'selectVehicles',
              onClick: openModal,
            },
          ]}
          icon={<FontAwesomeIcon icon={faEllipsisV} />}
        />}
      </div>
      <ul className="suitable-vehicles-list">
        {listToRender.length > 0
          ? listToRender.map((veh) => <li key={veh.value}>
            {veh.label || 'N/A'}
          </li>)
          : <EmptyVehiclesList />}
      </ul>
    </section>
  );
};

export default PartKitsVehicles;
