import React from 'react';
import { UserAccountDto } from '../../types/userProfileTypes';
import UserDetails from './UserDetails';
import UserActivity from './UserActivity';
import './UserContent.scss';

type UserContentProps = {
  userProfile: UserAccountDto | null,
}

const UserContent: React.FC<UserContentProps> = ({ userProfile }) => {
  return (
    <div className="userContent info-grid">
      <UserDetails userProfile={userProfile} />
      <UserActivity />
    </div>
  );
};

export default UserContent;
