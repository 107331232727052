import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../core/components/button/Button';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import {
  PurchaseOrderDashFiltersType, PurchaseOrderDataListType,
} from '../../types/purchaseOrdersTypes';
import { setPurchaseOrdersFilters } from '../../../../../store/slices/purchaseOrderSlice';
import { useAppDispatch } from '../../../../../store/hooks';
import Restricted from '../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { BooleanFunctionType } from '../../../../../core/types/coreTypes';

type PurchaseActionsProps = {
  purchaseOrdersFilters: PurchaseOrderDashFiltersType,
  purchaseOrders: PurchaseOrderDataListType,
  setCreateModeModal: BooleanFunctionType,
}

const PurchaseActions: React.FC<PurchaseActionsProps> = ({ purchaseOrdersFilters, purchaseOrders, setCreateModeModal }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="actions-and-paging">
      <Restricted to={PermissionEnum.StockPurchaseOrdersCreate}>
        <div className="actions-and-paging__actions">
          <Button
            label="Create new purchase order"
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            onClick={() => {
              setCreateModeModal(true);
            }}
            designType="dark"
            reversed
          />
        </div>
      </Restricted>
      <Pagination
        showTotal
        currentPage={purchaseOrdersFilters.page}
        totalItems={purchaseOrders.totalCount}
        onChange={(page, pageSize) => {
          const newFilters = { ...purchaseOrdersFilters, page, pageSize };
          dispatch(setPurchaseOrdersFilters(newFilters));
        }}
        pageSize={purchaseOrdersFilters.pageSize}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        className="actions-and-paging__paging"
        narrow
      />
    </div>
  );
};

export default PurchaseActions;
