import React from 'react';
import { fieldNameEventHandle } from '../../../utils/activityLogUtils';

enum BillingShippingEventsEnum {
  BillingDetailsUpdates= 'BillingDetailsUpdates',
  ShippingDetailsUpdates = 'ShippingDetailsUpdates',
}

type BillingShippingEventsProps = {
  eventName: string,
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const BillingShippingEvents: React.FC<BillingShippingEventsProps> = ({
  eventName, field, oldValue, newValue,
}) => {
  return (
    <>
      Field
      {' '}
      <b>{fieldNameEventHandle(field)}</b>
      {' '}
      from
      {' '}
      <b>
        {eventName === BillingShippingEventsEnum.BillingDetailsUpdates
          ? 'Billing details'
          : 'Shipping details'}
      </b>
      {' '}
      was changed:
      {' '}
      {!!oldValue && <s>{`${oldValue}`}</s>}
      {!!newValue && <b>{` ${newValue}`}</b>}
    </>
  );
};

export default BillingShippingEvents;
