// eslint-disable-next-line max-len
export const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
export const PHONE_FAX_REGEX = /^[0-9()+# -]*$/;
export const NAME_REGEX = /^[a-zA-Z-'’ ]*$/;
export const ACCOUNT_NO_REGEX = /^[a-zA-Z0-9]*$/;
export const LETTERS_NUMBERS_DASH_REGEX = /^[a-zA-Z0-9-]*$/;
export const LETTERS_NUMBERS_DASH_SPACES_REGEX = /^[a-zA-Z0-9- ]*$/;
export const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(.){8,}$/;
export const NO_SPACES_REGEX = /^\S*$/;
export const ONLY_DIGITS = /^\d+$/;
export const ONLY_DIGITS_AND_ONE_DOT = /[0-9vc\.|\,]$/;
export const ONLY_DIGITS_ONE_DOT_AND_ONE_DASH = /[-0-9vc\.|\,]$/;
export const NI_NUMBER = /^(?!BG|GB|KN|NK|NT|TN|ZZ)[^DFIQUV][^DFIQUVO][0-9]{6}[ABCD]$/;
export const LATIN_LETTERS_NUMBERS_SPACES_SPECIAL_CHARS = /^[a-zA-Z0-9 !@#$%\^&*)(+=._-]*$/;

export const commonMinDecimal = 0.00;
export const commonMinWhole = 0;
export const maxCommonDecimal = 999999999.99;
export const maxCommonWhole = 999999999;
export const maxEightNumbersWhole = 99999999;
export const maxWeightWhole = 99999;
export const maxDiscount = 100.00;
export const maxDiscountWhole = 100;
export const maxReorderLevelsWhole = 999;
export const maxReorderLevelsDecimal = 999.99;
export const maxEightDigitsBeforeDot = 99999999.99;
export const maxTwoDigitsBeforeDot = 99.99;

export const AVAILABLE_NUMERIC_KEYS = [
  'backspace',
  'del',
  'arrowright',
  'arrowleft',
  'tab',
  'meta',
  'ctrl',
  'c',
  'v',
  '.',
  ',',
];

export const AVAILABLE_NUMERIC_NEGATIVE_KEYS = [...AVAILABLE_NUMERIC_KEYS, '-'];

export const SMALL_TABLES_PAGE_SIZES = ['5', '10', '20'];
export const BIG_TABLES_PAGE_SIZES = ['10', '20', '50'];
export const DEFAULT_TIMEZONE_DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ';
export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';
export const DEFAULT_UI_DATE_FORMAT = 'DD-MM-YYYY';
export const DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT = 'DD-MMM-YYYY';
export const DEFAULT_UI_DATE_FORMAT_WITH_TIME = 'DD-MM-YYYY HH:mm';

export const UNKNOWN_USER = 'Unknown user';

export const ALLOWED_IMAGE_FORMATS = ['image/JPG', 'image/JPEG', 'image/BMP', 'image/PNG', 'image/GIF'];
