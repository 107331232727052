import React from 'react';
import { initDataList, initSmallPaging } from '../../../core/types/coreTypes';
import ExistedVehicleCatalogSection from './ExistedVehicleCatalogSection';
import ExistedChosenVehicleForm from './ExistedChosenVehicleForm';
import { setVehicleProfiles } from '../../../store/slices/vehiclesSlice';
import { useAppDispatch } from '../../../store/hooks';
import { getVehicleProfiles } from '../../../store/thunks/vehicles/vehiclesProfilesThunk';
import { VehicleCatalogModes, VehicleProfilesUiList } from '../../types/commonTypes';
import {
  VehicleProfileSearchType,
  VehicleProfilesFilters,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import EmptySelectedVehicleProfile from './EmptySelectedVehicleProfile';

type VehicleCatalogSectionProps = {
  catalogMode?: VehicleCatalogModes,
  setCatalogMode: (v?: VehicleCatalogModes) => void,
  chosenVehicleProfile: VehicleProfileSearchType | null,
  setChosenVehicleProfile: (v: VehicleProfileSearchType | null) => void,
  catalogVehicleProfilesFilters: VehicleProfilesFilters,
  setCatalogVehicleProfilesFilters: (v: VehicleProfilesFilters) => void,
  vehicleProfiles: VehicleProfilesUiList,
  disabled?: boolean,
}

const VehicleCatalogSection: React.FC<VehicleCatalogSectionProps> = ({
  catalogMode, setCatalogMode, chosenVehicleProfile,
  setChosenVehicleProfile, catalogVehicleProfilesFilters,
  setCatalogVehicleProfilesFilters, vehicleProfiles, disabled,
}) => {
  const dispatch = useAppDispatch();

  const selectProfile = (veh: VehicleProfileSearchType | null) => {
    setChosenVehicleProfile(veh);
    setCatalogMode('found');
    dispatch(setVehicleProfiles({ ...initDataList, groups: null }));
    setCatalogVehicleProfilesFilters({ ...initSmallPaging });
  };

  const selectAnotherProfileHandle = () => {
    setChosenVehicleProfile(null);
    setCatalogMode('search');
  };
  return (
    <>
      {!catalogMode
        ? <EmptySelectedVehicleProfile onClick={() => setCatalogMode('search')} large />
        : <>
          { catalogMode === 'search'
            ? <ExistedVehicleCatalogSection
              dataList={vehicleProfiles.items}
              groups={vehicleProfiles.groups || []}
              totalCount={vehicleProfiles.totalCount}
              filters={catalogVehicleProfilesFilters}
              setFilters={(v) => setCatalogVehicleProfilesFilters(v)}
              setDefaultList={() => dispatch(setVehicleProfiles({ ...initDataList, groups: null }))}
              fetchProfiles={(filters) => dispatch(getVehicleProfiles({ filters }))}
              setChosenVehicleProfile={selectProfile}
            />
            : <ExistedChosenVehicleForm
              chosenVehicleProfile={chosenVehicleProfile}
              selectAnotherProfile={selectAnotherProfileHandle}
              readOnly={disabled}
            />}
        </>}
    </>
  );
};

export default VehicleCatalogSection;
