import React, { useMemo } from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
import Dropdown from '../dropdown/Dropdown';
import { useAppSelector } from '../../../store/hooks';
import { collapsedSidebarSelector } from '../../../store/selectors/coreSelectors';
import { BooleanFunctionType, VoidFunctionType } from '../../types/coreTypes';
import './DetailsHead.scss';
import Button from '../button/Button';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { DropdownActionType } from '../../../common/types/commonTypes';

type DetailsHeadProps = {
  children: JSX.Element;
  actions?: DropdownActionType[];
  isConfirmationModal?: boolean;
  setConfirmationModal?: BooleanFunctionType;
  confirmHandler?: VoidFunctionType;
  goBack: VoidFunctionType;
  className?: string;
  isCreatePage?: boolean,
}

const DetailsHead: React.FC<DetailsHeadProps> = ({
  children, actions, isConfirmationModal, setConfirmationModal, confirmHandler, goBack, className, isCreatePage,
}) => {
  const collapsed = useAppSelector(collapsedSidebarSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const allowedActions: Array<ItemType> | undefined = useMemo(() => {
    const permissions = currentUser?.userContextRole.permissions;
    return actions?.filter((act) => permissions?.includes(act.restrictedTo));
  }, [actions, currentUser?.userContextRole.permissions]);
  return (
    <>
      <ConfirmationModal
        isVisible={!!isConfirmationModal}
        onCancel={() => setConfirmationModal && setConfirmationModal(false)}
        confirmHandler={() => confirmHandler && confirmHandler()}
        isDelete
      />
      <div className={classnames(className, 'head', { 'headWide': collapsed, 'createPage': isCreatePage })}>
        <div className="head__inner">
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={() => goBack()}
          />
          {children}
          {allowedActions && allowedActions.length > 0 && <Dropdown
            className="dropdownActions"
            icon={<FontAwesomeIcon icon={faEllipsisV} className="dropdownActions-icon" />}
            menuItems={allowedActions}
            placement="bottomRight"
            parentRender
          />}
        </div>
      </div>
    </>
  );
};

export default DetailsHead;
