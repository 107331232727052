export const sourceProductColumns = [
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
  },
  {
    title: 'Net price',
    dataIndex: 'netPrice',
  },
];
