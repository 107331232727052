import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  reportIsVisibleSelector, soldProductsByCustomerChartOrderingSelector,
  soldProductsByCustomerDataSelector,
  soldProductsByCustomerFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { setIsTableView, setSoldProductsByCustomerFilters } from '../../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import SoldProductsByCustomerTable from './table/SoldProductsByCustomerTable';
import { getSoldProductsByCustomerReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import Switch from '../../../../../core/components/switch/Switch';
import SoldProductsByCustomerChart from './chart/SoldProductsByCustomerChart';
import Segmented from '../../../../../core/components/segmented/Segmented';
import { DictionaryItem } from '../../../../../core/types/coreTypes';
import { useSoldProductsByCustomerReportDefaultOrdering } from '../../../../hooks/useSoldProductsByCustomerReportDefaultOrdering';

const SoldProductsByCustomerContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(soldProductsByCustomerFiltersSelector);
  const tableData = useAppSelector(soldProductsByCustomerDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const isTableView = useAppSelector(isTableViewSelector);
  const chartOrderingOptions = useAppSelector(soldProductsByCustomerChartOrderingSelector);

  const CHART_COUNT = 20;

  const defaultPagingAndOrdering = useSoldProductsByCustomerReportDefaultOrdering(false);

  return (
    <>
      <div className="actions-and-paging reportActionsAndPaging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              const newFilters = {
                customerIds: filters.customerIds,
                businessAreas: filters.businessAreas,
                entityTypes: filters.entityTypes,
                partSubcategoryIds: filters.partSubcategoryIds,
                completedDate: filters.completedDate,
                ...defaultPagingAndOrdering,
                pageSize: val ? CHART_COUNT : defaultPagingAndOrdering.pageSize,
              };
              dispatch(setSoldProductsByCustomerFilters(newFilters));
              reportIsVisible && dispatch(getSoldProductsByCustomerReportThunk({ filters: newFilters }));
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView
          ? <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={tableData.totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setSoldProductsByCustomerFilters(newFilters));
              reportIsVisible && dispatch(getSoldProductsByCustomerReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
          : <div className="reportSegmentedWrapper">
            <span>Top 20 products by</span>
            <Segmented
              options={chartOrderingOptions as DictionaryItem[]}
              value={filters.order?.field || ''}
              onChange={(value) => {
                const newFilters = { ...filters, order: { field: +value, isAsc: false } };
                dispatch(setSoldProductsByCustomerFilters(newFilters));
                reportIsVisible && dispatch(getSoldProductsByCustomerReportThunk({ filters: newFilters }));
              }}
            />
          </div>}
      </div>
      {isTableView
        ? <SoldProductsByCustomerTable />
        : <SoldProductsByCustomerChart />}
    </>
  );
};

export default SoldProductsByCustomerContent;
