import React from 'react';
import { StockPartRequestEventsEnum } from '../../../../enums/StockPartRequestEventsEnum';

type StockPartRequestLinkPurchaseOrderEventProps = {
  partRequestLineNumber?: number,
  productNumber?: string,
  linkedPurchaseOrderNumbers?: string[],
  event: StockPartRequestEventsEnum,
  unlinkedPurchaseOrderNumbers?: string[],
}

const StockPartRequestLinkPurchaseOrderEvent: React.FC<StockPartRequestLinkPurchaseOrderEventProps> = ({
  linkedPurchaseOrderNumbers, partRequestLineNumber, productNumber, event, unlinkedPurchaseOrderNumbers,
}) => {
  const linkedElementsString = linkedPurchaseOrderNumbers ? linkedPurchaseOrderNumbers.join(', ') : 'N/A';
  const unlinkedElementsString = unlinkedPurchaseOrderNumbers ? unlinkedPurchaseOrderNumbers.join(', ') : 'N/A';
  return (
    <span>
      Line
      {' '}
      {partRequestLineNumber || 'N/A'}
      {' '}
      with part code
      {' '}
      {productNumber ? <b>{productNumber}</b> : 'N/A'}
      {' '}
      {event === StockPartRequestEventsEnum.PartRequestLineLinkPurchaseOrder
        ? 'was linked to'
        : 'was unlinked from'}
      {' '}
      {event === StockPartRequestEventsEnum.PartRequestLineLinkPurchaseOrder
        ? <b>{linkedElementsString}</b>
        : <b>{unlinkedElementsString}</b>}
      {' '}
      purchase order(s).
    </span>
  );
};

export default StockPartRequestLinkPurchaseOrderEvent;
