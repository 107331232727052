import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CustomAny } from '../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { EMAIL_REGEX, NAME_REGEX, PASSWORD_REGEX } from '../../../core/utils/regex';
import Input from '../../../core/components/input/Input';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import InputPassword from '../../../core/components/input-password/InputPassword';
import { finalizeUserProfileThunk } from '../../../store/thunks/settings/accountFinalizeThunks';
import { AccountFinalizationData } from '../AccountFinalizationContainer';
import LOGO from '../../../assets/images/logo-transparent.png';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import TermsOfUseLink from '../../../common/components/privacy-and-terms-buttons/TermsOfUseLink';
import PrivacyPolicyButton from '../../../common/components/privacy-and-terms-buttons/PrivacyPolicyButton';

type AccountFinalizationFormProps = {
  data: AccountFinalizationData,
  token?: string,
}

const AccountFinalizationForm: React.FC<AccountFinalizationFormProps> = ({ data, token }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const isFetching = useAppSelector(isFetchingSelector);
  const {
    control, handleSubmit, setError, setValue, watch, clearErrors,
  } = useForm<CustomAny>({
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: '',
      agreed: false,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const onSubmit = (values: CustomAny) => {
    token && data.email && dispatch(finalizeUserProfileThunk({
      token,
      data: {
        firstName: values.firstName.trim(),
        lastName: values.lastName.trim(),
        password: values.password,
      },
      email: data.email,
      navigate,
    }));
  };
  const password = watch('password');
  const agreed = watch('agreed');

  useEffect(() => {
    if (data) {
      setValue('email', data.email);
      setValue('firstName', data.firstName);
      setValue('lastName', data.lastName);
    }
    // eslint-disable-next-line
  }, [data]);
  return (
    <section className="accountFinalization">
      <div className="accountFinalization__form-wrapper">
        <img src={LOGO} alt="" className="accountFinalization__logo" />
        <h1 className="accountFinalization__title">Verify and accept invite</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="accountFinalization__inner accountFinalization__form">
          <Controller
            control={control}
            name="firstName"
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
              pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
            }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!v.trim()) {
                    setValue('firstName', '');
                    setError('firstName', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors?.firstName?.message}
                label="First name"
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="lastName"
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
              pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
            }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!v.trim()) {
                    setValue('lastName', '');
                    setError('lastName', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors?.lastName?.message}
                label="Last name"
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="email"
            rules={{
              required: ErrorsEnum.REQUIRED,
              pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address.' },
            }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                error={errors?.email?.message}
                label="Email"
                readOnly
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="password"
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
              pattern: { value: PASSWORD_REGEX, message: 'Please use a strong password.' },
            }}
            render={({ field }) => (
              <InputPassword
                value={field.value}
                onChange={field.onChange}
                error={errors?.confirmPassword?.type === 'notEquals' ? 'Entered passwords do not match.' : errors?.password?.message}
                label="Password"
                suffix={
                  <Tooltip
                    trigger="click"
                    title={
                      <div className="accountFinalization__tooltip">
                        <span>Password should be at least 8 characters long and include:</span>
                        <ul>
                          <li>one uppercase letter [A-Z]</li>
                          <li>one lowercase letter [a-z]</li>
                          <li>one number [0-9]</li>
                        </ul>
                      </div>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                }
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="confirmPassword"
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
              validate: {
                notEquals: (v) => v === password,
              },
            }}
            render={({ field }) => (
              <InputPassword
                value={field.value}
                onChange={field.onChange}
                error={errors?.confirmPassword?.type === 'notEquals' ? 'Entered passwords do not match.' : errors?.confirmPassword?.message}
                label="Confirm password"
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="agreed"
            rules={{
              required: ErrorsEnum.REQUIRED,
            }}
            render={({ field }) => (
              <Checkbox
                checked={field.value}
                onChange={field.onChange}
                className="accountFinalization__checkbox"
                label={<>
                  Agree to
                  {' '}
                  <TermsOfUseLink />
                  {' '}
                  and
                  {' '}
                  <PrivacyPolicyButton />
                </>}
              />
            )}
          />
          <ButtonActions
            createLabel="Accept invite"
            cancelLabel="Clear"
            createType="submit"
            disabledCreate={!agreed}
            cancelClick={() => {
              setValue('firstName', '');
              setValue('lastName', '');
              setValue('password', '');
              setValue('confirmPassword', '');
              setValue('agreed', false);
              clearErrors();
            }}
            className="accountFinalization__actions"
            isLoading={isFetching}
          />
        </form>
      </div>
    </section>
  );
};

export default AccountFinalizationForm;
