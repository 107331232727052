import React, { useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import EmployeeDetailsForm from './components/details-form/EmployeeDetailsForm';
import EmployeeMainInfo from './components/main-info/EmployeeMainInfo';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { employeeDetailsSelector, employeeImageDataSelector } from '../../../../store/selectors/employeesSelector';
import { ALLOWED_IMAGE_FORMATS } from '../../../../core/utils/regex';
import { ForbiddenFileExtensions } from '../../../../common/utils/forbiddenFileExtensions';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../core/utils/guidGenerator';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { addEmployeeImageThunk, deleteEmployeeAttachmentThunk } from '../../../../store/thunks/employee/employeeAttachmentsThunks';

const EmployeeStaticAreaContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const { uri, id: employeeImageId } = useAppSelector(employeeImageDataSelector);
  const [image, setImage] = useState<RcFile | null>(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const uploadImageHandle = async (img: RcFile, deletePreviousImage: boolean) => {
    setImage(null);
    const image = {
      fileName: img.name,
      uploadGuid: guidGenerator(),
      file: img,
    };
    employeeDetails && dispatch(addEmployeeImageThunk({
      employeeId: employeeDetails.employee.id,
      image,
      deletePreviousImage,
    }));
  };

  const deleteImageHandle = () => {
    employeeImageId && employeeDetails && dispatch(deleteEmployeeAttachmentThunk({
      employeeId: employeeDetails.employee.id,
      id: employeeImageId,
      isEmployeeImage: true,
      closeModal: () => setIsConfirmationVisible(false),
    }));
  };

  const beforeUpload = (files: RcFile[]) => {
    const fileName = files[0].name;
    const isBigFile = files[0].size / 1024 / 1024 > 5;
    const currentExt = fileName.substring(fileName.lastIndexOf('.') + 1);
    const imagesExt = ALLOWED_IMAGE_FORMATS.map((el) => el.substring(el.lastIndexOf('/') + 1));
    const forbiddenExt = ForbiddenFileExtensions.includes(`.${currentExt.toUpperCase()}`)
      || !imagesExt.includes(currentExt.toUpperCase());
    if (isBigFile) {
      dispatch(setErrorMessage({ message: `File ${fileName} exceeds the maximum file size (5 MB).` }));
    }
    if (forbiddenExt) {
      dispatch(setErrorMessage({ message: `File ${fileName} has unsupported file format.` }));
    }
    if (isBigFile || forbiddenExt) {
      return false;
    } else {
      setImage(files[0]);
      return true;
    }
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        onCancel={() => setIsConfirmationVisible(false)}
        confirmHandler={deleteImageHandle}
      />
      <section>
        <EmployeeMainInfo
          imageUri={uri}
          formats={ALLOWED_IMAGE_FORMATS.join(',')}
          beforeUpload={beforeUpload}
          onChange={(deletePrev) => image && uploadImageHandle(image, deletePrev)}
          setConfirm={() => setIsConfirmationVisible(true)}
        />
        <EmployeeDetailsForm />
      </section>
    </>
  );
};

export default EmployeeStaticAreaContainer;
