import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';
import { SalesUrlParamsNames, SalesViewTabEnum, SalesViewTabEnumList } from '../enums/salesEnums';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { salesOrderDetailsSelector } from '../../../store/selectors/invoicingSelectors';

type SalesViewStaticSectionProps = {
  tabParam: string | null,
  setTabParam: StringFunctionType,
}

const SalesViewStaticSection: React.FC<SalesViewStaticSectionProps> = ({ tabParam, setTabParam }) => {
  const { windowWidth } = useScreenWitdh();
  const [params, setSearchParams] = useSearchParams();
  const salesOrderDetails = useAppSelector(salesOrderDetailsSelector);

  const data = salesOrderDetails?.order;
  const invoiceDocumentsCount = salesOrderDetails?.invoiceDocumentsCount;

  const currentUser = useAppSelector(currentUserSelector);

  const tabsOptions = [
    {
      label: `Order lines ${tabCountHandle(data?.partRequestLinesCount)}`,
      value: SalesViewTabEnum.ORDER_LINES,
    },
    {
      label: `Invoices & credit notes ${tabCountHandle(invoiceDocumentsCount)}`,
      value: SalesViewTabEnum.INVOICES_AND_CREDIT_NOTES,
    },
  ];

  useEffect(() => {
    if (currentUser) {
      const tabParam = params.get(SalesUrlParamsNames.TAB_TYPE);
      const { permissions } = currentUser.userContextRole;
      if (!tabParam && permissions.includes(PermissionEnum.InvoicingSalesViewPageWithInvoices)) {
        setSearchParams({ [SalesUrlParamsNames.TAB_TYPE]: tabsOptions[0].value }, { replace: true });
      } else {
        if (!SalesViewTabEnumList.includes(tabParam as SalesViewTabEnum)) {
          setSearchParams({ [SalesUrlParamsNames.TAB_TYPE]: SalesViewTabEnum.ORDER_LINES }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={tabsOptions}
            value={tabParam || ''}
            onChange={(value) => setTabParam(value as string)}
          />
        </div>
      )}
      <div className="tabs-and-props desktop-sticky-filters">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={tabsOptions}
            value={tabParam || ''}
            onChange={(value) => setTabParam(value as string)}
            className="tabs-and-props__tabs"
          />
        )}
      </div>
    </>
  );
};

export default SalesViewStaticSection;
