import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import { DictionaryItem, VoidFunctionType } from '../../../core/types/coreTypes';
import { CustomerDto } from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import { CustomersStatusesEnum } from '../../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { customerStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ConsumerTypeEnum } from '../enums/createEnquiryEnum';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import { initRequisite, RequisiteFields } from '../../../common/utils/data';
import {
  CreateEnquiryFields,
  EnquiryCustomerAccNumberSchema,
  EnquiryCustomerStringName,
} from '../utils/CreateEnquirySchema';

type CustomerFieldsProps = {
  control: Control<CreateEnquiryFields>,
  customerVal: string,
  errors: FieldErrors<CreateEnquiryFields>,
  watch: UseFormWatch<CreateEnquiryFields>,
  setValue: UseFormSetValue<CreateEnquiryFields>,
  customers: CustomerDto[],
  customerTypes: DictionaryItem[],
  keyword?: string,
  setKeyword: (value?: string) => void,
  clearFields: VoidFunctionType,
  setRequisiteFields: (value: RequisiteFields) => void,
  setRequisiteCustomerId: (value: { billing?: number, shipping?: number }) => void,
}

const CustomerFields: React.FC<CustomerFieldsProps> = ({
  control, customerVal, errors, watch, setValue, customers, customerTypes, clearFields,
  keyword, setKeyword, setRequisiteFields, setRequisiteCustomerId,
}) => {
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<'error' | 'warning' | undefined>(undefined);
  const [searchValue, setSearchValue] = useState<string>('');
  const customerName = watch('name');
  const customerStatuses = useAppSelector(customerStatusesSelector);
  const { windowWidth } = useScreenWitdh();
  const customersOptions = customers.map((el) => {
    const currentStatus = customerStatuses.find((st) => st.value === el.customerStatus)?.label;
    return {
      value: el.id,
      label: <Tooltip title={windowWidth > responsiveEndpointsEnum.LG ? el.name : ''}>
        <div>
          <FontAwesomeIcon
            icon={currentStatus === CustomersStatusesEnum.Complete ? faClipboardCheck : faQuestionCircle}
          />
          {' '}
          {el.customerAccountNumber}
          {' '}
          {el.name}
        </div>
      </Tooltip>,
    };
  });
  useEffect(() => {
    const current = customers.find((el) => el.id === customerName);
    if (customerVal === ConsumerTypeEnum.EXISTED && current) {
      setValue('customerType', current.customerType);
      setValue('customerAccountNumber', current.customerAccountNumber || '');
    }
    // eslint-disable-next-line
  }, [customerName, customerVal, customers]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        setKeyword(searchValue);
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      setKeyword(undefined);
    }
    // eslint-disable-next-line
  }, [searchValue]);
  return (
    <>
      {customerVal === 'new'
        ? <Controller
          name="name"
          control={control}
          rules={{
            ...EnquiryCustomerStringName,
            validate: {
              onlySpaces: (v) => (v as string).trim().length > 0,
            },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              error={errors.name?.type === 'onlySpaces' ? ErrorsEnum.REQUIRED : errors.name?.message}
              label="Customer name"
            />
          )}
        />
        : <Controller
          name="name"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                setRequisiteFields({ billing: initRequisite, shipping: initRequisite });
                setRequisiteCustomerId({ billing: +v || undefined, shipping: +v || undefined });
                field.onChange(v);
              }}
              options={customersOptions}
              error={errors.name?.message}
              label="Customer name"
              showSearch
              allowClear
              status={status}
              onClear={() => {
                clearFields();
                setSearchValue('');
              }}
              onSearch={(val) => {
                if (val.length <= 250) {
                  status && setStatus(undefined);
                  setSearchValue(val.trim());
                } else {
                  setStatus('error');
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              }}
              onSelect={() => {
                keyword && setKeyword('');
              }}
            />
          )}
        />}
      <Controller
        name="customerType"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={customerTypes}
            error={errors.customerType?.message}
            label="Customer type"
            disabled={customerVal === ConsumerTypeEnum.EXISTED}
          />
        )}
      />
      <Controller
        name="customerAccountNumber"
        control={control}
        rules={EnquiryCustomerAccNumberSchema}
        render={({ field }) => (
          <Input
            value={field.value.toUpperCase()}
            onChange={field.onChange}
            error={errors.customerAccountNumber?.message}
            label="Customer account No"
            className="details-form__field--lg"
            disabled={customerVal === ConsumerTypeEnum.EXISTED}
          />
        )}
      />
    </>
  );
};

export default CustomerFields;
