import React from 'react';
import { StockPageParamEnums } from '../../enums/stockEnums';
import PartsKits from '../../../parts-kits/dashboard/PartsKits';
import StockSearch from '../../../search/StockSearch';
import PurchaseOrders from '../../../purchase-orders/dashboard/PurchaseOrders';
import StockDashboard from '../../../dashboard/StockDashboard';
import StockPartRequests from '../../../stock-part-requests/StockPartRequests';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import PartCategoriesContainer from '../../../part-categories/PartCategoriesContainer';

type StockContentProps = {
  view: string | null;
  tabType: string | null;
  purchaseStatus: string | null;
  setPurchaseStatus: StringFunctionType;
  partReqStatus: string | null;
  setPartRequestStatus: StringFunctionType;
}

const StockContent: React.FC<StockContentProps> = ({
  view, purchaseStatus, setPurchaseStatus, tabType, setPartRequestStatus, partReqStatus,
}) => {
  const contentRenderFn = () => {
    switch (view) {
      case StockPageParamEnums.DASHBOARD:
        return <StockDashboard />;
      case StockPageParamEnums.SEARCH:
        return <StockSearch />;
      case StockPageParamEnums.PART_REQUESTS:
        return <StockPartRequests
          tabType={tabType}
          partReqStatus={partReqStatus}
          setPartRequestStatus={setPartRequestStatus}
        />;
      case StockPageParamEnums.PURCHASES:
        return <PurchaseOrders
          purchaseStatus={purchaseStatus}
          setPurchaseStatus={setPurchaseStatus}
          tabType={tabType}
        />;
      case StockPageParamEnums.PART_CATEGORIES:
        return <PartCategoriesContainer />;
      case StockPageParamEnums.PARTS_KITS:
        return <PartsKits />;
      default: return null;
    }
  };

  return (
    <>
      {contentRenderFn()}
    </>
  );
};

export default StockContent;
