import React, { useEffect } from 'react';
import Thead from '../../../../../core/components/table-components/Thead';
import UserListColumns from './UserListColumns';
import Tbody from '../../../../../core/components/table-components/Tbody';
import UserListCells from './UserListCells';
import UsersListActions from './UsersListActions';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { usersListColumnsSelector, usersListFiltersSelector, usersListSelector } from '../../../../../store/selectors/settingsSelectors';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { userAccountsOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { getUserAccountsOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { setListFilters } from '../../../../../store/slices/settingsSlice';
import { UsersListOrdering } from '../../../enums/settingsEnums';
import { getSettingsUsersList } from '../../../../../store/thunks/settings/settingsDashboardThunks';

const UsersList: React.FC = () => {
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(usersListSelector);
  const usersListFilters = useAppSelector(usersListFiltersSelector);
  const ordering = useAppSelector(userAccountsOrderingSelector);
  const columns = useAppSelector(usersListColumnsSelector);
  const allowedToDelete = usePermission(PermissionEnum.UserDelete);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useDefaultPagingAndOrdering({
    filters: usersListFilters,
    ordering,
    getOrderingThunk: getUserAccountsOrderingThunk,
    setFiltersAction: setListFilters,
    initialOrdering: UsersListOrdering.Name,
  });

  useEffect(() => {
    usersListFilters.order?.field && dispatch(getSettingsUsersList({ filters: usersListFilters }));
    // eslint-disable-next-line
  }, [usersListFilters]);

  return (
    <>
      <UsersListActions
        checkedKeys={checkedKeys}
        uncheckAll={uncheckAllHandle}
      />
      <TableNew>
        <Thead>
          <UserListColumns
            userColumns={columns}
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            checkAll={checkAllHandle}
            totalElements={totalCount}
            allowedToDelete={allowedToDelete}
          />
        </Thead>
        <Tbody>
          <UserListCells
            checkedKeys={checkedKeys}
            data={items}
            checkRows={checkRowHandle}
            allowedToDelete={allowedToDelete}
            columnsLength={columns.length}
          />
        </Tbody>
      </TableNew>
    </>
  );
};

export default UsersList;
