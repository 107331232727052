import React, { useEffect, useState } from 'react';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { pricelistSuppliersSelector } from '../../../store/selectors/pricelistSelectors';
import { getPricelistSuppliers } from '../../../store/thunks/pricelist/pricelistThunks';
import TruncateDropdownLabel from '../../../common/components/truncate-dropdown-label/TruncateDropdownLabel';
import { verifiedSupplierStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

export const usePricelistSupplierLookup = () => {
  const dispatch = useAppDispatch();
  const suppliersList = useAppSelector(pricelistSuppliersSelector);
  const verifiedSupplierStatus = useAppSelector(verifiedSupplierStatusSelector);

  const [suppliersLookup, setSuppliersLookup] = useState<{ label: React.ReactNode; value: number; }[]>([]);
  const [searchValue, setSearchValue] = useState<string>('');
  const [searchError, setSearchError] = useState<string>('');

  useEffect(() => {
    const arr = suppliersList.map((item) => ({
      label: <TruncateDropdownLabel
        value={`${item.accountNumber} ${item.name}`}
        icon={item.status === verifiedSupplierStatus?.value ? faClipboardCheck : faQuestionCircle}
      />,
      value: item.id,
    }));
    setSuppliersLookup(arr);
    // eslint-disable-next-line
    }, [suppliersList]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        dispatch(getPricelistSuppliers({ keyword: searchValue.trim() }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getPricelistSuppliers({ keyword: undefined }));
    }
    // eslint-disable-next-line
  }, [searchValue]);

  return {
    suppliersLookup,
    searchValue,
    setSearchValue,
    searchError,
    setSearchError,
  };
};
