import React, { useEffect } from 'react';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import JobForm from './components/JobForm';
import './CreateJob.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  orderDictionaryFiltersSelector,
  rectificationDictionaryFiltersSelector,
} from '../../store/selectors/sharedSelectors';
import { getOrderDictionary, getRectificationsDictionary } from '../../store/thunks/coreThunk';
import {
  serOrderDetails, setOrderDictionaryFilters,
  setRectificationDetails,
  setRectificationDictionaryFilters,
} from '../../store/slices/sharedSlice';

const CreateJobContainer:React.FC = () => {
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState();
  const orderDictionaryFilters = useAppSelector(orderDictionaryFiltersSelector);
  const rectificationDictionaryFilters = useAppSelector(rectificationDictionaryFiltersSelector);

  useEffect(() => {
    dispatch(getOrderDictionary({
      filters: {
        ...orderDictionaryFilters,
        onlyInStatusAvailableForJobCreation: true,
        notInSupplyOnlyJobType: true,
      },
    }));
    // eslint-disable-next-line
  }, [orderDictionaryFilters]);
  useEffect(() => {
    dispatch(getRectificationsDictionary({
      filters: { ...rectificationDictionaryFilters, onlyAvailableForJobCreation: true },
    }));
    // eslint-disable-next-line
  }, [rectificationDictionaryFilters]);

  useEffect(() => {
    return () => {
      // set rectification details to null
      dispatch(serOrderDetails(null));
      dispatch(setRectificationDetails(null));
      dispatch(setRectificationDictionaryFilters(undefined));
      dispatch(setOrderDictionaryFilters(undefined));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new job</div>
    </DetailsHead>}
    >
      <JobForm orderDictionaryFilters={orderDictionaryFilters} rectificationDictionaryFilters={rectificationDictionaryFilters} />
    </Layout>
  );
};

export default CreateJobContainer;
