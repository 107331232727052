import React, { useState } from 'react';
import './StockSearchFilters.scss';
import SearchSimpleFilters from './SearchSimpleFilters';
import { StockFiltersType } from '../../types/stockSearchTypes';
import SearchAdvancedFilters from './SearchAdvancedFilters';
import { useStockSearchFiltersHandle } from '../../hooks/useStockSearchFiltersHandle';
import { KeywordFilterTreeType } from '../../../../core/types/coreTypes';

type StockSearchFiltersProps = {
  filters: StockFiltersType,
}

const StockSearchFilters: React.FC<StockSearchFiltersProps> = ({ filters }) => {
  const [isAdvanced, setIsAdvanced] = useState(false);
  const {
    filtersList,
    treeFiltersList,
    getFilteredResHandle,
    onClear,
    initDropdownSearch,
    dropdownSearchValue,
    setDropdownSearchValue,
  } = useStockSearchFiltersHandle();

  return (
    <div className="stockSearch__filters">
      <SearchSimpleFilters
        filters={filters}
        setIsAdvanced={setIsAdvanced}
        isAdvanced={isAdvanced}
        onClear={onClear}
      />
      <SearchAdvancedFilters
        filters={filters}
        treeFiltersList={treeFiltersList as unknown as KeywordFilterTreeType}
        onClear={onClear}
        filtersList={filtersList}
        getFilteredResHandle={getFilteredResHandle}
        initDropdownSearch={initDropdownSearch}
        setDropdownSearchValue={setDropdownSearchValue}
        dropdownSearchValue={dropdownSearchValue}
        isAdvanced={isAdvanced}
      />
    </div>
  );
};
export default StockSearchFilters;
