import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { AttachmentsCellProps } from '../../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { deleteJobAttachmentThunk, getJobAttachmentByIdThunk } from '../../../../../store/thunks/jobs/jobThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isJobStatusCompletedSelector } from '../../../../../store/selectors/jobsSelector';
import usePermission from '../../../../../permissions-handling/permissionHook';
import AttachmentsRow from '../../../../../common/components/attachments-table/AttachmentsRow';

const JobAttachmentCell: React.FC<AttachmentsCellProps> = ({
  attach,
  openEditModal,
  checked,
  checkRow,
  readOnly,
  getAttachment,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isModal, setModal] = useState<boolean>(false);
  const allowedManage = usePermission(PermissionEnum.JobEditFields);
  const isJobStatusCompleted = useAppSelector(isJobStatusCompletedSelector);

  const actions = [
    {
      label: 'Edit comment',
      icon: <FontAwesomeIcon icon={faEdit} />,
      key: 'editComment',
      onClick: () => openEditModal(),
    },
    {
      label: 'Delete file',
      key: 'deleteFile',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setModal(true),
    }];

  const handleGetAttachment = (attachId: number) => {
    if (getAttachment) {
      getAttachment(attachId);
    } else {
      id && dispatch(getJobAttachmentByIdThunk({
        id: attachId,
        jobId: +id,
      }));
    }
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isModal}
        onCancel={() => setModal(false)}
        confirmHandler={() => id && dispatch(deleteJobAttachmentThunk({
          id: attach.id,
          jobId: +id,
          closeModal: () => setModal(false),
        }))}
      />
      <AttachmentsRow
        allowedManage={!!allowedManage && !readOnly}
        attachment={attach}
        checked={checked}
        checkRows={checkRow}
        onClick={handleGetAttachment}
        actions={actions}
        disabledActions={isJobStatusCompleted}
      />
    </>
  );
};

export default JobAttachmentCell;
