import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import Select from '../../../../core/components/select/Select';
import { setErrorMessage, setLocationHistory } from '../../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { usersFilterLookupSelector, vehicleFlowValuesSelector } from '../../../../store/selectors/coreSelectors';
import { VehicleViewModeLabelsEnum, VehicleViewModeParamsEnum } from '../../enums/VehicleViewEnums';
import Segmented from '../../../../core/components/segmented/Segmented';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { vehicleDetailsSelector, vehicleStatusTransitionsUiSelector } from '../../../../store/selectors/vehiclesSelectors';
import { editVehicleStatusThunk, patchVehicleThunk } from '../../../../store/thunks/vehicles/vehicleViewPageThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { vehicleStatusValuesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { routesPath } from '../../../../core/enums/pathEnum';
import { UNKNOWN_USER } from '../../../../core/utils/regex';
import { useUsersDictionary } from '../../../../common/hooks/useUsersDictionary';

type VehicleFiltersProps = {
  tabs: { label: VehicleViewModeLabelsEnum | string, value: VehicleViewModeParamsEnum }[],
  mode: string | null,
  setModeParam: (mode: VehicleViewModeParamsEnum) => void,
}
const VehicleFilters: React.FC<VehicleFiltersProps> = ({ tabs, mode, setModeParam }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { windowWidth } = useScreenWitdh();
  const usersLookup = useAppSelector(usersFilterLookupSelector);
  const { offSite } = useAppSelector(vehicleFlowValuesSelector);
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const vehicleId = vehicleDetails?.id;
  const vehicleAssignee = vehicleDetails?.isAssigneeDeleted ? UNKNOWN_USER : (vehicleDetails?.assigneeId || undefined);
  const isVehicleFlowOffSite = vehicleDetails?.vehicleFlow === offSite;
  const statuses = useAppSelector(vehicleStatusTransitionsUiSelector);
  const {
    preCheckPassed, preCheckFailed, ready,
    postCheckFailed, bookedOut, handedOver, inRepair,
  } = useAppSelector(vehicleStatusValuesSelector);
  const fullCheckRequiredList = [preCheckPassed, preCheckFailed, ready, postCheckFailed];
  const handoverCheckRequiredList = [bookedOut, handedOver];

  const allowedToManage = usePermission(PermissionEnum.VehicleEditFields);
  const [isStatusConfirmation, setStatusConfirmation] = useState(false);
  const [statusCheckType, setStatusCheckType] = useState<'full' | 'handover' | string>('');

  const { setUserSearch } = useUsersDictionary();

  const editVehStatusHandle = (v: number) => {
    vehicleId && dispatch(editVehicleStatusThunk({
      vehicleId,
      status: v,
    }));
  };

  const changeStatusCheckHandle = (value: number) => {
    if (handoverCheckRequiredList.includes(value)) {
      setStatusCheckType('handover');
      setStatusConfirmation(true);
    } else if (fullCheckRequiredList.includes(value)) {
      setStatusCheckType('full');
      setStatusConfirmation(true);
    } else editVehStatusHandle(value);
  };

  const cancelConfirmationModal = () => {
    setStatusConfirmation(false);
    setStatusCheckType('');
  };

  const confirmModalHandler = () => {
    const navigateUrl = statusCheckType === 'handover'
      ? `/${routesPath.HANDOVER_VEHICLE_CHECK}/${vehicleId}`
      : statusCheckType === 'full'
        ? `/${routesPath.FULL_VEHICLE_CHECK}/${vehicleId}`
        : '';
    dispatch(setLocationHistory({ pathname: location.pathname, search: location.search }));
    navigateUrl && navigate(navigateUrl);
    cancelConfirmationModal();
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isStatusConfirmation}
        onCancel={cancelConfirmationModal}
        confirmHandler={confirmModalHandler}
        customTitle={statusCheckType === 'handover' ? 'Handover check' : 'Full vehicle check is required'}
        // eslint-disable-next-line max-len
        customText={`In order to change a vehicle status please run a ${statusCheckType === 'handover' ? 'handover' : 'full vehicle'} check.`}
        customProceedText={statusCheckType === 'handover'
          ? 'Note that a new status value will be assigned only after successful handover check.'
          : 'Note that a new status value will be assigned based on your check decision in a full vehicle check form.'}
        customCreateLabel="Proceed to check"
      />
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={tabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as VehicleViewModeParamsEnum)}
          />
        </div>
      )}
      <div className="vehicleFilters desktop-sticky-filters">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={tabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as VehicleViewModeParamsEnum)}
            className="vehicleFilters__segmented"
          />)}
        <div className="vehicleFilters__actions">
          <Select
            options={usersLookup}
            value={vehicleAssignee}
            onChange={(value) => vehicleId && dispatch(patchVehicleThunk({
              vehicleId,
              data: { assigneeId: value ? value as number : 0 },
            }))}
            showSearch
            onSearch={(val) => {
              if (val.length <= 250) {
                setUserSearch(val);
              } else {
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            onSelect={() => {
              setUserSearch(undefined);
            }}
            allowClear={isVehicleFlowOffSite}
            showArrow
            className="vehicleFilters__select-highlighted"
            disabled={!allowedToManage}
          />
          <Select
            options={statuses}
            value={vehicleDetails?.status}
            onChange={(value) => changeStatusCheckHandle(value as number)}
            showArrow
            disabled={!allowedToManage || (vehicleDetails?.status === inRepair)}
          />
        </div>
      </div>

    </>
  );
};

export default VehicleFilters;
