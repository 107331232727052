import React, { useMemo, useState } from 'react';
import './PartVehicleAndPrices.scss';
import { useParams } from 'react-router-dom';
import PartViewPrices from './prices/PartViewPrices';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  stockPartDetailsSelector,
  stockPartTypesValuesSelector,
} from '../../../../../../store/selectors/stockSelectors';
import PartViewVehicles from './vehicles/PartViewVehicles';
import SelectVehiclesModal, { AvailableVehiclesData } from '../../../../../common/components/select-vehicles-modal/SelectVehiclesModal';
import { vehicleProfilesGroupsUiWithoutAllSelector } from '../../../../../../store/selectors/sharedSelectors';
import { patchStockPartThunk } from '../../../../../../store/thunks/stock/part/partViewPageThunks';
import { suitableVehicleSourcesValuesSelector } from '../../../../../../store/selectors/coreSelectors';

const PartVehicleAndPrices:React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const { consumable } = useAppSelector(stockPartTypesValuesSelector);
  const [isSelectVehicles, setSelectVehicles] = useState(false);
  const availableVehicleGroups = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);
  const { groups } = useAppSelector(suitableVehicleSourcesValuesSelector);

  const saveChangesNew = (data: AvailableVehiclesData) => {
    id && dispatch(patchStockPartThunk({
      id: +id,
      data,
      closeModal: () => setSelectVehicles(false),
    }));
  };

  const initSelectedIds = useMemo(() => {
    if (partDetails?.part.suitableVehiclesSource === groups) return null;
    return partDetails?.part.suitableForVehicles?.map(({ id }) => id) || null;
  }, [partDetails?.part.suitableForVehicles, partDetails?.part.suitableVehiclesSource, groups]);

  const initSelectedGroupIds = useMemo(() => {
    if (partDetails?.part.suitableVehiclesSource !== groups) {
      return null;
    } else if (partDetails?.part.suitableForVehicleGroupIds?.length === 0) {
      return availableVehicleGroups.items.map(({ id }) => id);
    }
    return partDetails?.part.suitableForVehicleGroupIds;
  }, [partDetails?.part.suitableForVehicleGroupIds, partDetails?.part.suitableVehiclesSource, groups, availableVehicleGroups]);

  return (
    <>
      <SelectVehiclesModal
        isVisible={isSelectVehicles}
        onClose={() => setSelectVehicles(false)}
        saveChanges={saveChangesNew}
        initSelectedIds={initSelectedIds}
        initSelectedGroupIds={initSelectedGroupIds}
        initSelectedSource={partDetails?.part.suitableVehiclesSource || null}
      />
      <div className="partVehicleAndPrices">
        <PartViewVehicles openModal={() => setSelectVehicles(true)} />
        <PartViewPrices prices={partDetails?.prices} isConsumable={consumable === partDetails?.part.type} />
      </div>
    </>
  );
};

export default PartVehicleAndPrices;
