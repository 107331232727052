import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { useSearchParams } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import Button from '../../../core/components/button/Button';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import {
  BooleanFunctionType, StringFunctionType, TabOptionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import './DashboardFilters.scss';

type DashboardFiltersProps = {
  children: React.ReactNode,
  page: string | null,
  isFiltersOpen?: boolean,
  setIsFiltersOpen?: BooleanFunctionType,
  setPageParamHandle: StringFunctionType,
  options: TabOptionType[],
  isHiddenClassname?: boolean,
  className?: string,
  expandableFilters?: boolean,
  customButtons?: {icon: IconProp, onClick: VoidFunctionType}[],
  customActionPanel?: JSX.Element,
}
const DashboardFilters:React.FC<DashboardFiltersProps> = ({
  children, page, isFiltersOpen, setIsFiltersOpen,
  setPageParamHandle, options, customActionPanel,
  isHiddenClassname, className, expandableFilters = true, customButtons,
}) => {
  const [, setSearchParams] = useSearchParams();
  const { windowWidth } = useScreenWitdh();
  const [tabs, setTabs] = useState<TabOptionType[]>([]);
  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const allowedActions = options.filter((act) => permissions.includes(act.restrictedTo));
      const allowedActionsValues = allowedActions.map(({ value }) => value);
      setTabs(allowedActions);
      if (!page || (page && !allowedActionsValues.includes(page))) {
        setSearchParams({ page: allowedActions[0].value }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [currentUser, page, options]);

  return (
  // eslint-disable-next-line max-len
    <div className={classnames(className, 'dashboardFilters desktop-sticky-filters desktop-sticky-filters--top-sm mobile-sticky-filters mobile-sticky-filters--top-sm', {
      'dashboardFilters--expandable-filters': expandableFilters,
      'dashboardFiltersHidden': isHiddenClassname,
    })}
    >
      <div className="dashboardFilters__head">
        {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
          ? <Selectable
            options={tabs}
            value={page || ''}
            onChange={(value) => setPageParamHandle(value as string)}
          />
          : <Segmented
            options={tabs}
            value={page || ''}
            onChange={(value) => setPageParamHandle(value as string)}
          />}
        {expandableFilters
          ? <div className="dashboardFilters__extra-actions">
            {customButtons && customButtons.map((el, i) => (
              <Button
                key={i}
                icon={<FontAwesomeIcon
                  icon={el.icon}
                />}
                onClick={el.onClick}
                className="dashboardFilters__action"
              />
            ))}
            <Button
              icon={<FontAwesomeIcon
                icon={faFilter}
              />}
              onClick={() => setIsFiltersOpen && setIsFiltersOpen(!isFiltersOpen)}
              className="dashboardFilters__action"
              isActivated={isFiltersOpen}
            />
            {customActionPanel && <>{customActionPanel}</>}
          </div>
          : children}
      </div>
      {expandableFilters && isFiltersOpen && children}
    </div>
  );
};

export default DashboardFilters;
