import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import {
  orderVehUiDataSelector,
} from '../../../../../store/selectors/ordersSelector';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';

const OrderPartReqVehicle: React.FC = () => {
  const { name, info, specificInfo } = useAppSelector(orderVehUiDataSelector);
  return (
    <VehicleSection
      name={name}
      info={info}
      regNumber={specificInfo.regNumber}
      chassisNumber={specificInfo.chassisNumber}
      vehicleLocation={specificInfo.location}
      className="orderPartReqVehicle"
      bordered
      horizontal
      readonly
    />
  );
};

export default OrderPartReqVehicle;
