import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import RectificationPartReqVehicle from './RectificationPartReqVehicle';
import './RectificationPartRequest.scss';
import PartRequestSummarySection
  from '../../../../../common/components/common-part-request-components/PartRequestSummarySection';
import RectificationPartRequestTable from './RectificationPartRequestTable';
import { getPartRequestLineTypes } from '../../../../../store/thunks/coreThunk';
import { rectificationSummarySectionDataSelector } from '../../../../../store/selectors/rectificationsSelector';
import { getRequestSubmissionStatuses } from '../../../../../store/thunks/core/coreStatusesThunks';

const RectificationPartRequest: React.FC = () => {
  const dispatch = useAppDispatch();
  const { leftPartData, rightPartData } = useAppSelector(rectificationSummarySectionDataSelector);
  useEffect(() => {
    dispatch(getPartRequestLineTypes());
    dispatch(getRequestSubmissionStatuses());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <RectificationPartReqVehicle />
      <RectificationPartRequestTable />
      <PartRequestSummarySection leftPartData={leftPartData} rightPartData={rightPartData} />
    </>
  );
};

export default RectificationPartRequest;
