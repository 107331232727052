import { OnlyNumbersAndLettersFieldSchema, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const NameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 250, message: maxLengthMessageHandle(250) },
};

export const AccountNumberSchema = {
  ...RequiredFieldSchema,
  ...OnlyNumbersAndLettersFieldSchema,
  minLength: { value: 4, message: rangeLengthMessageHandle([4, 6]) },
  maxLength: { value: 6, message: rangeLengthMessageHandle([4, 6]) },
};

export const VatNumberSchema = {
  ...OnlyNumbersAndLettersFieldSchema,
  minLength: { value: 8, message: rangeLengthMessageHandle([8, 12]) },
  maxLength: { value: 12, message: rangeLengthMessageHandle([8, 12]) },
};

export const CompanyRegNoSchema = {
  ...OnlyNumbersAndLettersFieldSchema,
  minLength: { value: 8, message: rangeLengthMessageHandle([8, 10]) },
  maxLength: { value: 10, message: rangeLengthMessageHandle([8, 10]) },
};

export type CustomerFields = {
  name: string,
  customerType?: number,
  customerAccountNumber: string,
  vatNumber: string | null,
  companyRegistrationNumber: string | null,
  alwaysUseRequisites: number | undefined,
  accountType: number | undefined,
}

export const customerDefaults: CustomerFields = {
  name: '',
  customerType: undefined,
  customerAccountNumber: '',
  vatNumber: null,
  companyRegistrationNumber: null,
  alwaysUseRequisites: undefined,
  accountType: undefined,
};
