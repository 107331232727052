import React from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path,
} from 'react-hook-form';
import { RcFile } from 'antd/es/upload';
import Textarea from '../../../core/components/textarea/Textarea';
import DraggerUpload from '../../../core/components/upload/Dragger';
import AdditionalInfoAttachmentsSection from './AdditionalInfoAttachmentsSection';
import { NotesSchema } from '../utils/HandoverVehicleCheckSchema';

type AdditionalInfoProps<T extends FieldValues> = {
  control: Control<T>,
  errors: FieldErrors<T>,
  fileList: RcFile[],
  setFileList: (v: RcFile[]) => void,
  beforeUpload: (files: RcFile[]) => void,
  disabled?: boolean,
}

const AdditionalInfo = <T extends FieldValues>({
  control, errors, fileList, setFileList, beforeUpload, disabled,
}: AdditionalInfoProps<T>) => {
  return (
    <section className="details-form">
      <h2 className="info-grid__title">Additional info</h2>
      <Controller
        name={'notes' as Path<T>}
        control={control}
        rules={NotesSchema}
        render={({ field }) => (
          <Textarea
            value={field.value || ''}
            onChange={field.onChange}
            error={errors.notes?.message}
            label="Notes"
            className="details-form__field--lg"
            disabled={disabled}
          />
        )}
      />
      {disabled
        ? <AdditionalInfoAttachmentsSection />
        : <DraggerUpload
          name="content"
          multiple
          fileList={fileList}
          setFileList={setFileList}
          beforeUpload={beforeUpload}
          disabled={fileList.length === 20 || disabled}
          customMaxFilesText="Maximum 20 files."
          customMaxSizeText="Maximum 50 MB per one file."
          label="Attachments"
          className="details-form__field--lg additionalInfoVehicleCheck__uploader"
        />}
    </section>
  );
};

export default AdditionalInfo;
