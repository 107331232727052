import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import classNames from 'classnames';
import isEqual from 'lodash/isEqual';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import Modal from '../../../../core/components/modal/Modal';
import { partRequestChangesColumns, PartRequestLineActivityTypeEnum } from './eventDataUtil';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../core/components/table-components/Thead';
import Tbody from '../../../../core/components/table-components/Tbody';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setPartRequestChanges } from '../../../../store/slices/sharedSlice';
import { partRequestChangesSelector } from '../../../../store/selectors/sharedSelectors';
import './PartRequestChangesModal.scss';
import PriceSourceTag from '../../price-source-tag/PriceSourceTag';
import Tag from '../../../../core/components/tag/Tag';
import { TruncateTooltip } from '../../truncate-tooltip/TruncateTooltip';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';
import TableLoader from '../../../../core/components/table-components/TableLoader';

type PartRequestChangesModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  getChangesHandle: VoidFunctionType,
}

const PartRequestChangesModal: React.FC<PartRequestChangesModalProps> = ({ visible, onCancel, getChangesHandle }) => {
  const dispatch = useAppDispatch();
  const items = useAppSelector(partRequestChangesSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const { New, Updated, Deleted } = PartRequestLineActivityTypeEnum;

  const [hoveredLine, setHoveredLine] = useState<number | null>(null);

  useEffect(() => {
    if (visible) {
      getChangesHandle();
    }
    // eslint-disable-next-line
  }, [visible]);

  const renderPriceSources = (oldSources: number[] | null, newSources: number[] | null, isNewRow = false) => {
    let sources: number[] = [];
    const areSourcesEqual = isEqual(oldSources, newSources);
    if (!isNewRow && oldSources) sources = oldSources;
    else if (isNewRow && newSources && !areSourcesEqual) sources = newSources;
    if (sources.length) {
      return <div className={classNames('nowrap', {
        'new-sources': isNewRow,
        'crossed': !isNewRow && !areSourcesEqual,
      })}
      >
        {sources.map((s) => <PriceSourceTag key={s} source={s} />)}
      </div>;
    }
    return null;
  };

  const diffValue = (oldValue: string | null, newValue: string| null) => (oldValue !== newValue ? newValue : null);

  const nowrapKeys = useMemo(() => ['quantity', 'unitPrice', 'priceSources', 'discount', 'netPrice', 'tax', 'weight'], []);

  const renderValue = useCallback((el: typeof items[0], key: string, isNewRow: boolean) => {
    const keyAs = key as keyof typeof el.oldValue;
    if (keyAs === 'priceSources') {
      return renderPriceSources(el.oldValue.priceSources, el.newValue.priceSources, isNewRow);
    } else {
      const value = isNewRow ? diffValue(el.oldValue[keyAs], el.newValue[keyAs]) : el.oldValue[keyAs];
      if (nowrapKeys.includes(keyAs)) return value;
      return value
        ? <TruncateTooltip value={value} table />
        : null;
    }
  }, [nowrapKeys]);

  return (
    <Modal
      title="Part request changes"
      visible={visible}
      onCancel={onCancel}
      width={1600}
      afterClose={() => {
        dispatch(setPartRequestChanges([]));
      }}
    >
      <TableNew className="partRequestChangesTable" small disableHover>
        <Thead>
          <Tr>
            {partRequestChangesColumns.map(({ title, dataIndex }) => <ThNew
              key={dataIndex}
              className={`partRequestChangesTh--${dataIndex}`}
            >
              {title}
            </ThNew>)}
          </Tr>
        </Thead>
        {!isFetching
          ? <Tbody>
            {items.map((el) => <React.Fragment key={el.lineId}>
              {el.activityType !== New && Object.values(el.oldValue).some(Boolean) && <Tr
                onMouseEnter={() => setHoveredLine(el.lineId)}
                onMouseLeave={() => setHoveredLine(null)}
                className={classNames({
                  'hovered': hoveredLine === el.lineId,
                })}
              >
                <TdNew className="activity-type">
                  {el.activityType}
                  {el.actionReason && <Tooltip title={el.actionReason} overlayClassName="tooltip-sm">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>}
                </TdNew>
                <TdNew>{el.number}</TdNew>
                {Object.keys(el.oldValue).map((key) => {
                  const keyAs = key as keyof typeof el.oldValue;
                  return <TdNew
                    key={key}
                    className={classNames({
                      'crossed': el.activityType === Deleted || (keyAs !== 'priceSources' && el.oldValue[keyAs] !== el.newValue[keyAs]),
                      'nowrap': nowrapKeys.includes(keyAs),
                    })}
                  >
                    {renderValue(el, key, false)}
                  </TdNew>;
                })}
                <TdNew className="nowrap status">
                  {el.status.oldValue && <Tag crossed={el.activityType === Deleted} label={el.status.oldValue} />}
                  {el.status.oldValue !== el.status.newValue && el.status.newValue !== null && <>
                    <FontAwesomeIcon icon={faArrowRight} />
                    <Tag light label={el.status.newValue} />
                  </>}
                </TdNew>
              </Tr>}
              {(el.activityType === New || el.activityType === Updated) && <Tr
                onMouseEnter={() => setHoveredLine(el.lineId)}
                onMouseLeave={() => setHoveredLine(null)}
                className={classNames('highlight-new', {
                  'hovered': hoveredLine === el.lineId,
                  'updated': el.activityType === Updated,
                })}
              >
                <TdNew className="activity-type">
                  {el.activityType === New && el.activityType}
                  {el.activityType === New && el.actionReason && <Tooltip title={el.actionReason} overlayClassName="tooltip-sm">
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>}
                </TdNew>
                <TdNew>{el.activityType === New && el.number}</TdNew>
                {Object.keys(el.newValue).map((key) => {
                  return <TdNew
                    key={key}
                    className={classNames({ 'nowrap': nowrapKeys.includes(key) })}
                  >
                    {renderValue(el, key, true)}
                  </TdNew>;
                })}
                <TdNew>{el.activityType === New && <Tag light label={el.status.newValue} />}</TdNew>
              </Tr>}
            </React.Fragment>)}
          </Tbody>
          : <TableLoader colspan={partRequestChangesColumns.length} />}
      </TableNew>
    </Modal>
  );
};

export default PartRequestChangesModal;
