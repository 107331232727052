import qs from 'qs';
import instance from './axiosConfig';
import {
  AffectedEmployeeDto,
  AffectedEmployeesFiltersType, EmployeeSkillsListItemDto,
  SkillCategoryLookupDto, SkillDto,
  SkillsFilterType,
} from '../employees/skill-list/types/skillsTypes';
import { EmployeeListItemDto, employeesFiltersType } from '../employees/dashboard/types/employeesDashTypes';
import {
  AxiosCreatedAttachmentsResponse, AxiosCreatedIdResponse,
  AxiosDataListResponse,
  AxiosDictionaryResponse, AxiosGenericResponse, AxiosGetPagedAttachmentsResponse, AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  CreateUploadUriResponse,
  DictionaryStringValueItem, GetAttachmentFileResponse,
  PostAttachReqType,
  PostAttachReqWithType,
  UploadUriDto,
} from '../core/types/coreTypes';
import {
  EmployeeActivityEventType,
  EmployeeEmploymentDto,
  GetEmployeeIceContactResponse,
  GetEmployeeResponse,
  PatchEmployeeDto,
} from '../employees/employee-view-page/types/employeeViewTypes';
import {
  EmployeeLeaveRequestFilters,
  ExtendedTimelineFilters,
  LeaveRequestDto,
  SkillLookupDto,
} from '../common/types/commonTypes';
import {
  PostLeaveRequestRestModel,
  PutLeaveRequestRestModel,
} from '../account/components/account-content/leave-requests/types/AccountLeaveRequestsTypes';
import {
  EmployeeHolidaysFilters,
  EmployeeWorkCalendarFilters,
  PostHolidayRestModel,
  AffectedDatesAndPutEmployeeScheduleParams,
  GetHolidaysResponse,
  GetWorkCalendarResponse,
  AffectedScheduleDateDto, GetDepartmentsResponse, GetJobTitlesResponse,
} from '../employees/work-calendar/types/EmployeeWorkCalendarTypes';
import {
  CreateEmployeeEmploymentRestModel, PatchEmployeeEmploymentRestModel,
} from '../employees/employee-view-page/utils/AddEmploymentSchema';
import { PostEmployeeRestModel } from '../employees/employee-view-page/utils/EmployeeSchema';

export const employeeAPI = {
  fetchEmployeeGenders(): AxiosDictionaryResponse {
    return instance.get('/employee/app/genders');
  },
  fetchEmployeeDepartments(): AxiosDictionaryResponse {
    return instance.get('/employee/app/departments');
  },
  fetchEmployeeSalaryTypes(): AxiosDictionaryResponse {
    return instance.get('/employee/app/salaryTypes');
  },
  fetchEmployeeSkillLevels(): AxiosDictionaryResponse {
    return instance.get('/employee/app/skillLevels');
  },
  fetchEmployeeSkillsDictionary(keyword?: string): AxiosSingleResponse<{ skills: SkillLookupDto[], allItemIds: number[] }> {
    return instance.get('/employee/skills/views/dictionary', {
      params: {
        'Filter.Keyword': keyword,
      },
    });
  },
  fetchEmployeesDashTitles(): AxiosSingleResponse<{ items: DictionaryStringValueItem[] }> {
    return instance.get('/employee/employees/jobTitles');
  },
  fetchEmployeeDashboard(filters?: employeesFiltersType, signal?: AbortSignal): AxiosDataListResponse<EmployeeListItemDto[]> {
    return instance.get('/employee/employees', {
      params: {
        'Filter.SearchParameters.Keyword': filters?.keyword,
        'Filter.SearchParameters.IncludeFullName': filters?.includeFullName,
        'Filter.SearchParameters.IncludeReferenceNumber': filters?.includeReferenceNumber,
        'Filter.SearchParameters.IncludeNationalInsuranceNumber': filters?.includeNationalInsuranceNumber,
        'Filter.SearchParameters.IncludeEmail': filters?.includeEmail,
        'Filter.EmployeeStatus': filters?.status,
        'Filter.JobTitles': filters?.jobTitle,
        'Filter.Departments': filters?.department,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
        'Filter.HasLinkedUserAccount': filters?.hasLinkedAccount,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  createEmployee(data: PostEmployeeRestModel): AxiosSingleResponse<{ createdId: number, referenceNumber: number }> {
    return instance.post('/employee/employees', { ...data });
  },
  postEmployeePhoto(employeeId: number, attachment: PostAttachReqWithType): AxiosCreatedAttachmentsResponse {
    return instance.post(`/employee/employees/${employeeId}/attachments/image`, { ...attachment });
  },
  fetchSkillsCategories(): AxiosSingleResponse<{ categories: SkillCategoryLookupDto[] }> {
    return instance.get('/employee/skills/categories');
  },
  createNewCategory(name: string): AxiosCreatedIdResponse {
    return instance.post('/employee/skills/categories', { name });
  },
  editCategory(id: number, name: string): AxiosNullableResponse {
    return instance.put(`/employee/skills/categories/${id}`, { name });
  },
  deleteCategory(id: number): AxiosNullableResponse {
    return instance.delete(`/employee/skills/categories/${id}`);
  },
  fetchEmployeeSkills(filters: SkillsFilterType): AxiosSingleResponse<{ skills: SkillDto[], allItemIds: number[] }> {
    return instance.get('/employee/skills', {
      params: {
        SkillCategoryId: filters.categoryId,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': 1,
        'PagingOptions.ItemsPerPage': 100,
      },
    });
  },
  addSkillToCategory(
    categoryId: number,
    name: string,
    description: string | null,
  ): AxiosCreatedIdResponse {
    return instance.post('/employee/skills', { categoryId, name, description });
  },
  editSkill(
    skillId: number,
    categoryId: number,
    name: string,
    description: string | null,
  ): AxiosNullableResponse {
    return instance.put(`/employee/skills/${skillId}`, { categoryId, name, description });
  },
  deleteSkill(skillId: number): AxiosNullableResponse {
    return instance.delete(`/employee/skills/${skillId}`);
  },
  deleteSkills(ids: number[]): AxiosNullableResponse {
    return instance.delete('/employee/skills', { data: { skillIds: ids } });
  },
  fetchSkillAffectedEmployees(skillId: number[], filters: AffectedEmployeesFiltersType): AxiosDataListResponse<AffectedEmployeeDto[]> {
    return instance.get('/employee/skills/affectedEmployees', {
      params: {
        SkillIds: skillId,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchCategoryAffectedEmployees(
    categoryId: number,
    filters: AffectedEmployeesFiltersType,
  ): AxiosDataListResponse<AffectedEmployeeDto[]> {
    return instance.get('/employee/skills/categories/affectedEmployees', {
      params: {
        SkillCategoryId: categoryId,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  fetchEmployeeById(employeeId: number): AxiosSingleResponse<GetEmployeeResponse> {
    return instance.get(`/employee/employees/${employeeId}`);
  },
  deleteEmployeeById(employeeId: number): AxiosNullableResponse {
    return instance.delete(`/employee/employees/${employeeId}`);
  },
  deleteMultipleEmployees(employeeIds: number[]): AxiosNullableResponse {
    return instance.delete('/employee/employees', { data: { employeeIds } });
  },
  patchEmployeeById(employeeId: number, data: PatchEmployeeDto): AxiosNullableResponse {
    return instance.patch(`/employee/employees/${employeeId}`, { employee: { ...data } });
  },
  fetchEmployeeIceContact(employeeId: number): AxiosSingleResponse<GetEmployeeIceContactResponse> {
    return instance.get(`/employee/employees/${employeeId}/iceContact`);
  },
  fetchEmployeeAttachments(employeeId: number, filters: CoreAttachmentsFilters): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/employee/employees/${employeeId}/attachments`, {
      params: {
        'Filter.Name': filters.name,
        'Filter.MinUploadDate': filters.uploadDate?.min,
        'Filter.MaxUploadDate': filters.uploadDate?.max,
        'Filter.Comment': filters.comment,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getEmployeeAttachmentById(employeeId: number, id: number): AxiosSingleResponse<GetAttachmentFileResponse> {
    return instance.get(`/employee/employees/${employeeId}/attachments/${id}`);
  },
  postEmployeeAttachments(
    employeeId: number,
    attachments: PostAttachReqType[],
  ): AxiosSingleResponse<{ createdItems: Array<{ id: number }> }> {
    return instance.post(`/employee/employees/${employeeId}/attachments`, { objectId: employeeId, attachments });
  },
  postEmployeeAttachmentUploadUri(employeeId: number, uploadUriDtos: UploadUriDto[]): AxiosSingleResponse<CreateUploadUriResponse> {
    return instance.post(`/employee/employees/${employeeId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editEmployeeAttachment(employeeId: number, id: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/employee/employees/${employeeId}/attachments/${id}`, { comment });
  },
  deleteEmployeeAttachment(employeeId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/employee/employees/${employeeId}/attachments/${id}`);
  },
  deleteMultipleEmployeeAttachment(employeeId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/employee/employees/${employeeId}/attachments`, { data: { attachmentIds } });
  },
  fetchEmployeeSkillsById(id: number): AxiosSingleResponse<{ employeeSkills: EmployeeSkillsListItemDto[] }> {
    return instance.get(`/employee/employees/${id}/skills`);
  },
  fetchEmployeeEmployments(id: number): AxiosSingleResponse<{ employments: EmployeeEmploymentDto[] }> {
    return instance.get(`/employee/employees/${id}/employments`);
  },
  postEmployeeEmployments(
    employeeId: number,
    data: CreateEmployeeEmploymentRestModel,
  ): AxiosSingleResponse<{ createdId: number, referenceNumber: number }> {
    return instance.post(`/employee/employees/${employeeId}/employments`, { ...data });
  },
  patchEmployeeEmployment(employmentId: number, employment: PatchEmployeeEmploymentRestModel): AxiosNullableResponse {
    return instance.patch(`/employee/employees/employments/${employmentId}`, { ...employment });
  },
  deleteEmployeeEmployment(employmentId: number): AxiosNullableResponse {
    return instance.delete(`/employee/employees/employments/${employmentId}`);
  },
  fetchEmployeeTimeline(employeeId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<EmployeeActivityEventType[]> {
    return instance.get('/employee/employees/timeline', {
      params: {
        EmployeeId: employeeId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postEmployeeNote(employeeId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/employee/note', { employeeId, content });
  },
  putEmployeeNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/employee/note/${id}`, { content });
  },
  deleteEmployeeNote(id: number): AxiosNullableResponse {
    return instance.delete(`/employee/note/${id}`);
  },
  fetchEmployeeLeaveRequests(filters: EmployeeLeaveRequestFilters): AxiosDataListResponse<LeaveRequestDto[]> {
    return instance.get('/employee/leaveRequests', {
      params: {
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeEmployeeName': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeEmployeeReferenceNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeNationalInsuranceNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeEmail': filters.keyword ? true : undefined,
        'Filter.IsActive': filters.isActive,
        'Filter.EmployeeIds': filters.employeeIds,
        'Filter.ExceptStatuses': filters.exceptStatuses,
        'Filter.LeaveTypes': filters.leaveTypes,
        'Filter.Departments': filters.departments,
        'Filter.JobTitles': filters.jobTitles,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  postEmployeeLeaveRequest(data: PostLeaveRequestRestModel): AxiosCreatedIdResponse {
    return instance.post('/employee/leaveRequests', { ...data });
  },
  deleteEmployeeLeaveRequest(id: number): AxiosNullableResponse {
    return instance.delete(`/employee/leaveRequests/${id}`);
  },
  putEmployeeLeaveRequest(id: number, data: PutLeaveRequestRestModel): AxiosNullableResponse {
    return instance.put(`/employee/leaveRequests/${id}`, { ...data });
  },
  fetchEmployeeLeaveRequest(id: number): AxiosSingleResponse<{ leaveRequest: LeaveRequestDto }> {
    return instance.get(`/employee/leaveRequests/${id}`);
  },
  changeEmployeeLeaveRequestStatus(id: number, statusTransition: number): AxiosNullableResponse {
    return instance.put(`/employee/leaveRequests/${id}/status`, { statusTransition });
  },
  fetchEmployeeHolidays(filters: EmployeeHolidaysFilters): AxiosGenericResponse<GetHolidaysResponse> {
    return instance.get('/employee/holidays', {
      params: {
        'Filter.Year': filters.year,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postEmployeeHoliday(data: PostHolidayRestModel): AxiosCreatedIdResponse {
    return instance.post('/employee/holidays', { ...data });
  },
  deleteEmployeeHoliday(id: number): AxiosNullableResponse {
    return instance.delete(`/employee/holidays/${id}`);
  },
  fetchEmployeeWorkCalendar(filters: EmployeeWorkCalendarFilters): AxiosGenericResponse<GetWorkCalendarResponse> {
    return instance.get('/employee/schedules/views/workCalendar', {
      params: {
        'From': filters.from,
        'To': filters.to,
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeEmployeeName': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeEmployeeReferenceNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeNationalInsuranceNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeEmail': filters.keyword ? true : undefined,
        'Filter.Departments': filters.departments,
        'Filter.JobTitles': filters.jobTitles,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  putEmployeeSchedules(data: AffectedDatesAndPutEmployeeScheduleParams): AxiosNullableResponse {
    return instance.put('employee/schedules', { ...data });
  },
  fetchEmployeeAffectedDates(params: AffectedDatesAndPutEmployeeScheduleParams): AxiosSingleResponse<{
    affectedScheduleDates: AffectedScheduleDateDto[] | null,
  }> {
    return instance.get('/employee/schedules/affectedDates', {
      params: {
        'EmployeeIds': params.employeeIds,
        'Departments': params.departments,
        'JobTitles': params.jobTitles,
        'StartDate': params.startDate,
        'EndDate': params.endDate,
        'Dates': params.dates,
        'AreWeekendsAndHolidaysNonWorking': params.areWeekendsAndHolidaysNonWorking,
        'ScheduledWorkingHours': params.scheduledWorkingHours,
        'NonWorkingHours': params.nonWorkingHours,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchEmployeesDepartmentsWithEmployeesCount(): AxiosSingleResponse<GetDepartmentsResponse> {
    return instance.get('/employee/employees/departments', { params: { 'Filter.IsNull': false } });
  },
  fetchEmployeesJobTitlesWithEmployeesCount(): AxiosSingleResponse<GetJobTitlesResponse> {
    return instance.get('/employee/employees/jobTitles', { params: { 'Filter.IsNull': false } });
  },
};
