import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { supplierSpendFiltersSelector, supplierSpendRangesSelector } from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useSupplierSpendRanges = () => {
  const filters = useAppSelector(supplierSpendFiltersSelector);
  const { costNet, costGross } = useAppSelector(supplierSpendRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    costNet: { min: costNet.min, max: costNet.max },
    costGross: { min: costGross.min, max: costGross.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    costNet: filters.costNet
      ? [filters.costNet.min.toString(), filters.costNet.max.toString()]
      : [rangePatterns.costNet.min.toString(), rangePatterns.costNet.max.toString()],
    costGross: filters.costGross
      ? [filters.costGross.min.toString(), filters.costGross.max.toString()]
      : [rangePatterns.costGross.min.toString(), rangePatterns.costGross.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, costGross: { min: costGross.min, max: costGross.max } }));
    setRange((prevState) => ({ ...prevState, costGross: [costGross.min.toString(), costGross.max.toString()] }));
  }, [costGross.min, costGross.max]);

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, costNet: { min: costNet.min, max: costNet.max } }));
    setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
  }, [costNet.min, costNet.max]);

  useEffect(() => {
    if (!filters?.costGross) {
      setRange((prevState) => ({ ...prevState, costGross: [costGross.min.toString(), costGross.max.toString()] }));
    }
    if (!filters?.costNet) {
      setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.costGross, filters.costNet]);

  return { range, setRange, rangePatterns };
};
