import React, { useEffect } from 'react';
import Pagination from '../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { purchasesFiltersSelector, purchasesListSelector } from '../../../../store/selectors/invoicingSelectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { PagingType } from '../../../../core/types/coreTypes';
import { setPurchasesFilters } from '../../../../store/slices/invoicingSlice';
import InvoicingPurchasesList from './InvoicingPurchasesList';
import './InvoicingPurchases.scss';
import InvoicingPurchasesFilters from './InvoicingPurchasesFilters';
import { getInvoicingPurchases } from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { useCancelRequest } from '../../../../core/hooks/useCancelRequest';
import { setDashboardLoading } from '../../../../store/slices/coreSlice';

const InvoicingPurchases: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(purchasesFiltersSelector);
  const { page, pageSize } = filters;
  const { totalCount } = useAppSelector(purchasesListSelector);
  const { controller, cancelRequest } = useCancelRequest();

  const pageChangeHandle = (v: PagingType) => {
    const newFilters = { ...filters, ...v };
    dispatch(setPurchasesFilters(newFilters));
    cancelRequest();
    dispatch(getInvoicingPurchases({
      filters: newFilters,
      signal: controller.current?.signal,
    }));
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <InvoicingPurchasesFilters />
      <div className="actions-and-paging">
        <Pagination
          currentPage={page}
          pageSize={pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => pageChangeHandle({ page, pageSize })}
          showTotal
          showSizeChanger
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      <InvoicingPurchasesList />
    </>
  );
};

export default InvoicingPurchases;
