import { createAsyncThunk } from '@reduxjs/toolkit';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  AxiosErrorResponse, emptyPaging,
  NumberFunctionType,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  AddEnquiryVehicleRequest,
  CreatePartLineReqDto,
  EnquiryQuoteVehicleDto,
  GetEnquiryQuoteLinesResponse,
  MissingQuoteLinesParams,
  PatchEnquiryQuoteLineDto, PutQuoteVehicle,
  QuoteLinesFilters,
  QuoteLinesOptionsType,
  QuoteTotalSummaryDto,
  QuoteVehicleSummaryDto,
} from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import {
  setErrorMessage,
  setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { enquiryAPI } from '../../../api/enquiryApi';
import { setOpenedVehicleId } from '../../slices/enquiriesSlice';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { getEnquiryDetails } from './detailsThunks';
import {
  CreateCustomLineReqType,
} from '../../../common/types/commonTypes';
import { RootState } from '../../store';
import { stockApi } from '../../../api/stockApi';
import { PartDtoType, StockFiltersType } from '../../../stock/search/types/stockSearchTypes';
import { PartsKitsDto } from '../../../stock/parts-kits/dashboard/types/partsKitsTypes';

export const fetchVehicleSummaryThunk = createAsyncThunk<
ResponseSingleResult<{ quoteVehicleSummary: QuoteVehicleSummaryDto, totalSummary: QuoteTotalSummaryDto }>,
{ quoteVehicleId: number }
>(
  'get/VehicleSummary',
  async ({ quoteVehicleId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.fetchVehiclesSummary(quoteVehicleId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryQuoteVehicleThunk = createAsyncThunk<
ResponseSingleResult<{ items: EnquiryQuoteVehicleDto[] }>,
{ id: number, idToSet?: number, setVehTabParam?: NumberFunctionType }
>(
  'get/Enquiry/QuoteVehicles',
  async ({
    id, idToSet, setVehTabParam,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.getEnquiryQuoteVehicle(id);
      const { items } = response.data.data;
      const firstItem = items[0];
      const firstItemId = firstItem.id;
      dispatch(fetchVehicleSummaryThunk({
        quoteVehicleId: !idToSet ? firstItemId : idToSet,
      }));
      dispatch(setOpenedVehicleId(!idToSet ? firstItemId : idToSet));
      setVehTabParam && setVehTabParam(!idToSet ? firstItemId : idToSet);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEnquiryQuoteVehicleThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number } | null>,
{ data: AddEnquiryVehicleRequest, tabName: string, onClose: VoidFunctionType, reset: VoidFunctionType }
>(
  'post/Enquiry/QuoteVehicle',
  async ({
    data, tabName, onClose, reset,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries: { enquiryDetails } } = getState() as RootState;
      const enquiryId = enquiryDetails?.enquiry?.id;
      const response = await enquiryAPI.createEnquiryQuoteVehicle(data);
      const { createdId } = response.data.data;
      if (enquiryId) {
        dispatch(getEnquiryQuoteVehicleThunk({ id: enquiryId, idToSet: createdId }));
        dispatch(getEnquiryDetails({ id: enquiryId }));
      }
      dispatch(setLoading(false));
      dispatch(setSuccessMessage({ message: `Vehicle ${tabName} was successfully created.` }));
      onClose();
      reset();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const duplicateEnquiryQuoteVehicleThunk = createAsyncThunk<
ResponseSingleResult<{ createdIds: number[] } | null>,
{
  enquiryId: number,
  vehicleId: number,
  data: { amount: number, copyLines: boolean },
  onClose: VoidFunctionType,
}
>(
  'post/Enquiry/DuplicateQuoteVehicle',
  async ({
    enquiryId, vehicleId, data, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.duplicateEnquiryQuoteVehicle(vehicleId, data);
      const { createdIds } = response.data.data;
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(getEnquiryQuoteVehicleThunk({ id: enquiryId, idToSet: createdIds[createdIds.length - 1] }));
      onClose();
      dispatch(setSuccessMessage({ message: `You have successfully created ${data.amount} quote${data.amount > 1 ? 's' : ''}.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryQuotLinesThunk = createAsyncThunk<
GetEnquiryQuoteLinesResponse,
{
  vehicleId: number,
  params: QuoteLinesFilters,
}
>(
  'get/Enquiry/QuoteLines',
  async ({
    vehicleId, params,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.getEnquiryQuoteLine(vehicleId, params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

const commonQuoteAndVehicleUpdateHandle = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  vehicleId: number,
  linesFilters: QuoteLinesFilters,
) => {
  dispatch(getEnquiryQuotLinesThunk({ vehicleId, params: { ...linesFilters } }));
  dispatch(fetchVehicleSummaryThunk({ quoteVehicleId: vehicleId }));
};

export const getQuoteLinesCanNotBeCopiedThunk = createAsyncThunk<
GetEnquiryQuoteLinesResponse,
{
  filters: MissingQuoteLinesParams,
  openModal?: VoidFunctionType,
}
>(
  'get/Enquiry/MissingQuoteLines',
  async ({
    filters, openModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.getQuoteLinesCanNotBeCopied(filters);
      const { totalCount } = response.data.data;
      if (totalCount > 0 && openModal) {
        openModal();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEnquiryQuoteVehicleThunk = createAsyncThunk<
ResponseSingleResult,
{
  enquiryId: number,
  vehicleId: number,
  data: PutQuoteVehicle,
  onClose: VoidFunctionType,
}
>(
  'put/Enquiry/QuoteVehicle',
  async ({
    enquiryId, vehicleId, data, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.editEnquiryQuoteVehicle(vehicleId, data);
      dispatch(getEnquiryQuoteVehicleThunk({ id: enquiryId, idToSet: vehicleId }));
      commonQuoteAndVehicleUpdateHandle(dispatch, vehicleId, { page: 1, pageSize: 100 });
      dispatch(setLoading(false));
      onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEnquiryQuoteVehicleThunk = createAsyncThunk<ResponseSingleResult,
{ vehicleId: number, enquiryId: number, closeModal: VoidFunctionType }>(
  'delete/Enquiry/QuoteVehicle',
  async ({
    vehicleId, enquiryId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.deleteEnquiryQuoteVehicle(vehicleId);
      const { enquiries } = getState() as RootState;
      const vehicles = enquiries.quoteVehiclesList;
      const newItems = vehicles?.filter((veh: EnquiryQuoteVehicleDto) => veh.id !== vehicleId);
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      newItems && dispatch(getEnquiryQuoteVehicleThunk({
        id: enquiryId,
        idToSet: newItems[newItems.length - 1]?.id,
      }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const copyEnquiryQuotLineThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: {
  quoteVehicleId: number,
  quoteLineId: number,
}[]} | null>,
{ enquiryId: number, lineId: number, quoteVehicleIds: number[], onClose: VoidFunctionType }
>(
  'post/Enquiry/CopyQuoteLine',
  async ({
    enquiryId, lineId, quoteVehicleIds, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.copyEnquiryQuoteLine(lineId, quoteVehicleIds);
      dispatch(getEnquiryQuoteVehicleThunk({ id: enquiryId, idToSet: quoteVehicleIds[quoteVehicleIds.length - 1] }));
      onClose();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEnquiryQuotLineThunk = createAsyncThunk<
ResponseSingleResult,
{
  lineId: number,
  data: Partial<PatchEnquiryQuoteLineDto>,
  setFieldToEdit?: VoidFunctionType,
  closeModal?: VoidFunctionType,
}
>(
  'patch/Enquiry/QuoteLine',
  async ({
    lineId, data, setFieldToEdit, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    dispatch(setFetching(true));
    try {
      const response = await enquiryAPI.editEnquiryQuoteLine(lineId, data);
      const { enquiries } = getState() as RootState;
      const { openedVehicleId, quoteLinesFilters } = enquiries;
      if (openedVehicleId) {
        const linesFilters = {
          page: quoteLinesFilters.page,
          pageSize: quoteLinesFilters.pageSize,
          filters: { ...quoteLinesFilters.filters },
          sort: quoteLinesFilters.order,
        };
        commonQuoteAndVehicleUpdateHandle(dispatch, openedVehicleId, linesFilters);
      }
      setFieldToEdit && setFieldToEdit();
      closeModal && closeModal();
      dispatch(setLoading(false));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEnquiryQuotLineThunk = createAsyncThunk<
ResponseSingleResult,
{ lineId: number, closeModal: VoidFunctionType }
>(
  'delete/Enquiry/QuoteLine',
  async ({ lineId, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.deleteEnquiryQuoteLine(lineId);
      const { enquiries } = getState() as RootState;
      const { openedVehicleId } = enquiries;
      if (openedVehicleId) {
        commonQuoteAndVehicleUpdateHandle(dispatch, openedVehicleId, { page: 1, pageSize: 100 });
      }
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSelectedQuoteLinesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  quoteLineIds: number[],
  vehicleId: number,
  closeModal: VoidFunctionType,
}
>(
  'delete/Enquiry/SelectedQuoteLines',
  async ({
    quoteLineIds, vehicleId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    const onFinish = () => {
      commonQuoteAndVehicleUpdateHandle(dispatch, vehicleId, { page: 1, pageSize: 100 });
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
    };
    dispatch(setLoading(true));
    try {
      const { enquiries: { quoteLinesFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await enquiryAPI.getEnquiryQuoteLine(vehicleId, {
        ...quoteLinesFilters, ...emptyPaging,
      });
      const filteredIds = quoteLineIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      const response = await enquiryAPI.deleteEnquiryQuoteSelectedLines(filteredIds);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchQuoteLinePartsThunk = createAsyncThunk<
ResponseResult<Array<PartDtoType>>,
{ filters: StockFiltersType, signal?: AbortSignal }
>(
  'get/QuoteLineParts',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const fetchQuoteLinePartsKitThunk = createAsyncThunk<
ResponseResult<Array<PartsKitsDto>>,
{ filters: StockFiltersType, signal?: AbortSignal }
>(
  'get/QuoteLinePartsKit',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitsList(
        filters,
        !filters.keyword ? undefined : true,
        !filters.keyword ? undefined : true,
        !filters.keyword ? undefined : true,
        signal,
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const enableQuoteLineOptionsThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  enquiryId: number,
  data: Partial<QuoteLinesOptionsType>,
}
>(
  'patch/QuoteVehicleLinesOptions',
  async ({
    id, enquiryId, data,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries } = getState() as RootState;
      const { openedVehicleId, quoteLinesFilters } = enquiries;
      const response = await enquiryAPI.enableQuoteLinesOptions(id, data);
      dispatch(getEnquiryQuoteVehicleThunk({ id: enquiryId, idToSet: id }));
      openedVehicleId && dispatch(getEnquiryQuotLinesThunk({
        vehicleId: openedVehicleId,
        params: { ...quoteLinesFilters },
      }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const bulkPatchQuoteLineThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  quoteLineIds: number[],
  discount: number,
  vehicleId: number,
  closeModal: VoidFunctionType,
}
>(
  'patch/bulkPatchQuoteLine',
  async ({
    quoteLineIds, discount, vehicleId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const onFinish = () => {
        commonQuoteAndVehicleUpdateHandle(dispatch, vehicleId, { page: 1, pageSize: 100 });
        dispatch(setLoading(false));
        closeModal();
      };
      const { enquiries: { quoteLinesFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await enquiryAPI.getEnquiryQuoteLine(vehicleId, {
        ...quoteLinesFilters, ...emptyPaging,
      });
      const filteredIds = quoteLineIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      const response = await enquiryAPI.bulkPatchQuoteLinesOptions(quoteLineIds, discount);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPartsKitLineThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>,
{
  quoteVehicleId: number,
  partsKitId: number,
  closeModal: VoidFunctionType,
}
>(
  'post/PartKitLine',
  async ({
    quoteVehicleId, partsKitId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.createPartsKitLine(quoteVehicleId, partsKitId);
      commonQuoteAndVehicleUpdateHandle(dispatch, quoteVehicleId, { page: 1, pageSize: 100 });
      dispatch(setLoading(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const partsKitErr = errors.find((err) => err.key === 'QuoteVehicleId');
      if (partsKitErr) {
        dispatch(setErrorMessage({ message: partsKitErr.message, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createCustomLineThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>,
{
  data: CreateCustomLineReqType,
  closeModal: VoidFunctionType,
}
>(
  'post/CustomLine',
  async ({
    data, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    dispatch(setFetching(true));
    try {
      const response = await enquiryAPI.createCustomLine(data);
      dispatch(setLoading(false));
      dispatch(setFetching(false));
      commonQuoteAndVehicleUpdateHandle(dispatch, data.quoteVehicleId, { page: 1, pageSize: 100 });
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPartLineThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }> | null,
{
  data: CreatePartLineReqDto,
  closeModal: VoidFunctionType,
}
>(
  'post/CreatePartLine',
  async ({
    data, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await enquiryAPI.createPartLine(data);
      dispatch(setFetching(false));
      commonQuoteAndVehicleUpdateHandle(dispatch, data.quoteVehicleId, { page: 1, pageSize: 100 });
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const putQuotePartsKitLineThunk = createAsyncThunk<
ResponseSingleResult,
{
  quoteLineId: number,
  quoteVehicleId: number,
}
>(
  'put/QuotePartsKitLine',
  async ({ quoteLineId, quoteVehicleId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.putQuotePartsKitLine(quoteLineId);
      dispatch(setLoading(false));
      commonQuoteAndVehicleUpdateHandle(dispatch, quoteVehicleId, { page: 1, pageSize: 100 });
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
