import React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { BooleanFunctionType, StringFunctionType } from '../../../../core/types/coreTypes';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Select from '../../../../core/components/select/Select';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { OrderDto } from '../../../dashboard/types/ordersTypes';
import { orderStatusTransitionsListSelector } from '../../../../store/selectors/ordersSelector';
import { usersFilterLookupSelector } from '../../../../store/selectors/coreSelectors';
import { patchOrderThunk, putOrderStatusThunk } from '../../../../store/thunks/orders/viewPageOrderThunks';
import Selectable from '../../../../core/components/selectable/Selectable';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { OrderViewPageEnums } from '../../enums/orderViewPageEnums';
import OrderPartRequestSubmissionButtons from './OrderPartRequestSubmissionButtons';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import {
  orderStatusesValuesSelector,
} from '../../../../store/selectors/coreStatusesSelectors';
import { UNKNOWN_USER } from '../../../../core/utils/regex';
import { useUsersDictionary } from '../../../../common/hooks/useUsersDictionary';

type OrderContentTabsProps = {
  details?: OrderDto;
  mode: string | null;
  setModeParam: StringFunctionType;
  allowedTabs: { label: string, value: OrderViewPageEnums}[],
  allowedToEdit?: boolean,
  setIsRejectStatus: BooleanFunctionType,
  setInvoicingConfirmation: BooleanFunctionType,
  setCancellationConfirmation: BooleanFunctionType,
  isCurrentStatusArchived: boolean,
}

const OrderContentTabs:React.FC<OrderContentTabsProps> = ({
  details, mode, setModeParam, allowedTabs, allowedToEdit, setIsRejectStatus,
  setInvoicingConfirmation, setCancellationConfirmation, isCurrentStatusArchived,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const orderStatusTransitionsList = useAppSelector(orderStatusTransitionsListSelector);
  const usersFilterLookup = useAppSelector(usersFilterLookupSelector);
  const isRequestActionsAllowed = usePermission(PermissionEnum.OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions);
  const { rejectedByCustomer, invoicing, cancelled } = useAppSelector(orderStatusesValuesSelector);

  const { setUserSearch } = useUsersDictionary();

  const changeAssignee = (value: number) => {
    id && dispatch(patchOrderThunk({
      id: +id,
      data: {
        assigneeId: value,
      },
    }));
  };
  const changeStatus = (value: number) => {
    if (value === rejectedByCustomer) {
      setIsRejectStatus(true);
    } else if (value === invoicing) {
      setInvoicingConfirmation(true);
    } else if (value === cancelled) {
      setCancellationConfirmation(true);
    } else {
      id && dispatch(putOrderStatusThunk({
        id: +id,
        statusTransition: value,
      }));
    }
  };
  const disabledControls = !allowedToEdit || isCurrentStatusArchived;
  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && allowedTabs.length > 0 && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className={classNames('view-details-tabs-actions  desktop-sticky-filters', {
        'view-details-tabs-actions--large-tabs': allowedTabs.length > 4,
      })}
      >
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="view-details-tabs-actions__segmented"
          />
        )}

        {mode === OrderViewPageEnums.ORDER_PART_REQUEST
          ? <>{isRequestActionsAllowed && <OrderPartRequestSubmissionButtons />}</>
          : <div className="view-details-tabs-actions__selects-wrapper">
            <Select
              value={details?.isAssigneeDeleted ? UNKNOWN_USER : details?.assigneeId}
              options={usersFilterLookup}
              onChange={(value) => changeAssignee(value as number)}
              showArrow
              showSearch
              onSearch={(val) => {
                if (val.length <= 250) {
                  setUserSearch(val);
                } else {
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              }}
              onSelect={() => {
                setUserSearch(undefined);
              }}
              disabled={disabledControls}
              className="view-details-tabs-actions__select--highlighted"
              parentRender
            />
            <Select
              options={orderStatusTransitionsList}
              value={details?.status}
              onChange={(value) => changeStatus(value as number)}
              disabled={disabledControls}
              parentRender
            />
          </div>}
      </div>
    </>
  );
};

export default OrderContentTabs;
