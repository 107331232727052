import React from 'react';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type StockPartReqInfoItemProps = {
  label: string,
  value: string | number | null,
}

const StockPartReqInfoItem: React.FC<StockPartReqInfoItemProps> = ({ label, value }) => {
  return (
    <span className="stockPartReqLine__info-item">
      <span className="stockPartReqLine__info-label">{label}</span>
      <TruncateTooltip value={value} className="stockPartReqLine__info-value" overlayClassName="stockPartReqLine__info-tooltip" />
    </span>
  );
};

export default StockPartReqInfoItem;
