import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BooleanFunctionType, StringFunctionType } from '../../../../core/types/coreTypes';
import { routesPath } from '../../../../core/enums/pathEnum';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useRectificationsFilters } from '../../hooks/useRectificationsFilters';
import { DashboardViewEnum, RectificationModesEnum } from '../../enums/rectificationEnums';
import DashboardFilters from '../../../../common/components/dashboard-filters/DashboardFilters';

type RectificationsFiltersProps = {
  page: string | null,
  setPageParam: StringFunctionType,
  setIsEyeModal: BooleanFunctionType;
}

const RectificationsFilters: React.FC<RectificationsFiltersProps> = ({
  page, setPageParam, setIsEyeModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();

  const {
    isFiltersOpen,
    setIsFiltersOpen,
    initDropdownSearch,
    keywordValue,
    setKeywordValue,
    filtersList,
    getFilteredRes,
    onClear,
    stateFilters,
    currentBusinessArea,
    keywordSearchParam,
    setInitSearchParams,
  } = useRectificationsFilters();

  const options = [
    {
      value: RectificationModesEnum.DASHBOARD,
      label: DashboardViewEnum.DASHBOARD,
      restrictedTo: PermissionEnum.RectificationDashboard,
    },
    {
      value: RectificationModesEnum.CREATE,
      label: DashboardViewEnum.CREATE,
      restrictedTo: PermissionEnum.RectificationCreate,
    },
  ];
  const setPageParamHandle = (value: string) => {
    if (value === RectificationModesEnum.CREATE) {
      navigate(`/${routesPath.CREATE_RECTIFICATION}`);
      const locationObj = {
        pathname: location.pathname,
        search: location.search,
      };
      setLocationHistoryHandle(locationObj);
    } else {
      setPageParam(value as string);
    }
  };

  useEffect(() => {
    if (currentBusinessArea && page === RectificationModesEnum.DASHBOARD) {
      getFilteredRes(stateFilters);
    }
    // eslint-disable-next-line
  }, [currentBusinessArea, page]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && keywordValue !== stateFilters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredRes({ ...stateFilters, keyword: keywordValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!keywordValue?.trim() && stateFilters.keyword) {
        getFilteredRes({ ...stateFilters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [keywordValue, stateFilters.keyword]);
  return (
    <DashboardFilters
      page={page}
      isFiltersOpen={isFiltersOpen}
      setIsFiltersOpen={setIsFiltersOpen}
      setPageParamHandle={setPageParamHandle}
      options={options}
      customButtons={[{ icon: faEye, onClick: () => setIsEyeModal(true) }]}
    >
      <KeywordFiltersSection
        searchValue={keywordValue}
        setSearchValue={(v) => {
          setKeywordValue(v);
          if (keywordSearchParam) {
            setInitSearchParams();
          }
        }}
        getFilteredResHandle={(values, field) => getFilteredRes({ ...stateFilters, [field]: values as number[] })}
        onClear={onClear}
        className="rectificationsFilters__footer"
        initDropdownSearch={initDropdownSearch}
        filters={filtersList}
        isFullWidth
      />
    </DashboardFilters>
  );
};

export default RectificationsFilters;
