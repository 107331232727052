import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  allowedShortBaForUserSelector,
  shortBusinessAreasSelector,
  timeMeasureValuesSelector,
} from './coreSelectors';
import {
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector, suitableVehiclesProfilesSelector,
} from './sharedSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { finalSummaryValueHandle } from '../../stock/parts-kits/view-page/utils/finalPriceValueHandle';
import { setUiNamesHandler } from '../../core/utils/setUiNamesHandler';
import { PartsKitComponentsTaxUiEnum } from '../../stock/parts-kits/view-page/enums/PartsKitsViewPageEnums';
import { getSortIndex } from '../../core/utils/getSortIndex';
import {
  PartsKitComponentsOrders,
} from '../../stock/parts-kits/view-page/enums/PartsKitComponentsOrders';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { partsKitsStatusesSelector } from './coreStatusesSelectors';
import {
  partsKitComponentsOrderingSelector,
  stockPartsOrderingSelector,
} from './coreOrderingSelectors';
import { AffectedEntityLineUi } from '../../common/types/commonTypes';
import { labelHandle } from '../../core/utils/labelHandle';
import { StockPartsOrders } from '../../stock/search/enums/StockPartsOrders';
import { DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';

export const partsKitsFiltersSelector = (state: RootState) => state.partsKit.partsKitsFilters;
export const partsKitFiltersListSelector = createSelector(
  partsKitsFiltersSelector,
  allowedShortBaForUserSelector,
  partsKitsStatusesSelector,
  partCategoriesLookupSelector,
  (partsKitsFilters, businessAreas, partsKitsStatuses, stockCategories) => {
    return [
      {
        label: 'Category',
        name: 'categories',
        value: partsKitsFilters.categories || [],
        options: stockCategories,
      },
      {
        label: 'Business areas',
        name: 'businessAreas',
        value: partsKitsFilters.businessAreas || [],
        options: businessAreas,
      },
      {
        label: 'Status',
        name: 'statuses',
        value: partsKitsFilters.statuses || [],
        options: partsKitsStatuses,
      },
    ];
  },
);

export const partsKitsListSelector = (state: RootState) => state.partsKit.partsKitsLists;

export const partsKitComponentsSummarySelector = (state: RootState) => state.partsKit.partsKitComponentsSummary;
export const partsKitComponentsSummaryUISelector = createSelector(partsKitComponentsSummarySelector, (data) => {
  const fixHandle = (value: number) => value.toFixed(2);
  return [
    {
      label: 'Conversion weight',
      value: data && data?.conversionWeight ? `${fixHandle(data?.conversionWeight)} kg` : '-',
    },
    {
      label: 'Net',
      value: data ? finalSummaryValueHandle(data.minNetPrice, data.maxNetPrice) : '-',
    },
    {
      label: 'VAT',
      value: data ? finalSummaryValueHandle(data.minVat, data.maxVat) : '-',
    },
    {
      label: 'Gross',
      value: data ? finalSummaryValueHandle(data.minGross, data.maxGross) : '-',
    },
  ];
});

export const partsKitDetailsSelector = (state:RootState) => state.partsKit.partsKitDetails;
export const partsKitDetailsHeadSelector = createSelector(
  partsKitsStatusesSelector,
  partsKitDetailsSelector,
  partsKitComponentsSummaryUISelector,
  (statuses, details, summary) => {
    const status = statuses.find((el) => el.value === details?.status)?.label;
    const price = summary.find((el) => el.label === 'Net')?.value;
    return [
      { label: '', value: details?.name },
      {
        label: 'Price',
        value: price || '-',
      },
      { label: 'Status', value: status },
      { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.createdAt || '') },
      { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.updatedAt || '') },
    ];
  },
);
export const partsKitSuitableForSelector = createSelector(
  partsKitDetailsSelector,
  (details) => {
    return details?.suitableForVehicles?.map((veh) => ({
      value: veh.id,
      label: veh.modelDescription || 'N/A',
    })) || [];
  },
);

const partsKitAffectedItemsInitSelector = (state:RootState) => state.partsKit.partsKitAffectedItems;
export const partsKitAffectedItemsSelector = createSelector(
  shortBusinessAreasSelector,
  partsKitAffectedItemsInitSelector,
  (ba, data) => {
    const { items, pages, totalCount } = data;
    const entities: AffectedEntityLineUi[] = items.map((item) => ({
      entityId: item.entityId,
      entityType: item.entityType,
      entityNumber: item.entityNumber,
      createdAt: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.createdAt),
      entityStatus: item.entityStatus || '-',
      businessArea: labelHandle(item.businessAreas, ba).toString() || '-',
      partLinesCount: item.partLinesCount,
    }));
    return { pages, totalCount, items: entities };
  },
);
export const partsKitAffectedItemsFiltersSelector = (state:RootState) => state.partsKit.partsKitAffectedItemsFilters;
export const partsKitAttachmentsSelector = (state:RootState) => state.partsKit.partsKitAttachments;
export const partsKitAttachmentsFiltersSelector = (state:RootState) => state.partsKit.partsKitAttachmentsFilters;

const partsKitAvailableCategoriesInitSelector = (state: RootState) => state.partsKit.partsKitAvailableCategories;
export const partsKitAvailableCategoriesSelector = createSelector(partsKitAvailableCategoriesInitSelector, (stockCategories) => {
  return stockCategories?.map((el) => ({ label: el.name || '-', value: el.id }));
});

export const partsKitComponentsFiltersSelector = (state: RootState) => state.partsKit.partsKitComponentsFilters;

const partsKitComponentTaxInitSelector = (state: RootState) => state.partsKit.partsKitComponentTax;
export const partsKitComponentTaxSelector = createSelector(partsKitComponentTaxInitSelector, (taxes) => {
  return setUiNamesHandler(taxes || [], PartsKitComponentsTaxUiEnum);
});

export const kitComponentsColumnsSelector = createSelector(
  partsKitComponentTaxSelector,
  partsKitAvailableCategoriesSelector,
  partsKitComponentsOrderingSelector,
  (taxes, categories, ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        orderField: getSortIndex(PartsKitComponentsOrders.Category, ordering),
        filters: categories,
      },
      {
        title: 'Part No',
        dataIndex: 'partNumber',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(PartsKitComponentsOrders.PartNumber, ordering),
      },
      {
        title: 'Part name',
        dataIndex: 'name',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(PartsKitComponentsOrders.PartName, ordering),
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(PartsKitComponentsOrders.Quantity, ordering),
      },
      {
        title: 'Unit price',
        dataIndex: 'unitPrice',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(PartsKitComponentsOrders.UnitPrice, ordering),
      },
      {
        title: 'Net price',
        dataIndex: 'netPrice',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(PartsKitComponentsOrders.NetPrice, ordering),
      },
      {
        title: 'Tax',
        dataIndex: 'tax',
        sorter: true,
        orderField: getSortIndex(PartsKitComponentsOrders.Tax, ordering),
        filters: taxes,
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(PartsKitComponentsOrders.Weight, ordering),
      },
    ];
  },
);

export const partsKitTimelineFiltersSelector = (state: RootState) => state.partsKit.partsKitTimelineFilters;
export const partsKitTimelineSelector = (state: RootState) => state.partsKit.partsKitTimeline;
export const partsKitTimelineComponentsGroupFiltersSelector = (state: RootState) => state.partsKit.partsKitTimelineComponentsGroupFilters;
export const partsKitTimelineComponentsGroupSelector = (state: RootState) => state.partsKit.partsKitTimelineComponentsGroup;

export const partsKitComponentsPartsFiltersSelector = (state: RootState) => state.partsKit.partsKitComponentsPartsFilters;

export const corePartsColumnsSelector = createSelector(stockPartsOrderingSelector, (ordering) => {
  return [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartsOrders.PartCode, ordering),
    },
    {
      title: 'Manufacturer code',
      dataIndex: 'manufacturerCode',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartsOrders.ManufacturerCode, ordering),
    },
    {
      title: 'Part name',
      dataIndex: 'name',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartsOrders.Name, ordering),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(StockPartsOrders.Description, ordering),
    },
  ];
});

export const addComponentCoreFiltersListSelector = createSelector(
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector,
  suitableVehiclesProfilesSelector,
  allowedShortBaForUserSelector,
  partsKitComponentsPartsFiltersSelector,
  (categories, subcategories, vehicles, ba, values) => {
    return [
      {
        label: 'Category',
        value: values.categoryIds || [],
        options: categories || [],
        name: 'categoryIds',
        isSearch: true,
      },
      {
        label: 'Subcategory',
        value: values.subcategoryIds || [],
        options: subcategories || [],
        name: 'subcategoryIds',
        isSearch: true,
      },
      {
        label: 'Business area',
        value: values.businessAreas || [],
        options: ba || [],
        name: 'businessAreas',
        isSearch: false,
      },
      {
        label: 'Suitable for vehicles',
        value: values.vehicleIds || [],
        options: vehicles.items || [],
        name: 'vehicleIds',
        isSearch: true,
      },
    ];
  },
);
export const partsKitComponentsPartsSelector = (state: RootState) => state.partsKit.partsKitComponentsParts;
export const partsKitComponentsPartsTableSelector = createSelector(
  partsKitComponentsPartsSelector,
  partsKitComponentsPartsFiltersSelector,
  (data, filters) => {
    return {
      ...data,
      items: data.items.map((item, index) => ({
        id: item.id,
        number: getNo(filters.page, filters.pageSize, index),
        partCode: item.partCode || '-',
        manufacturerCode: item.manufacturerCode || '-',
        name: item.name || '-',
        description: item.description || '-',
      })),
    };
  },
);

export const partsKitComponentsSelector = (state: RootState) => state.partsKit.partsKitComponents;

export const partsKitComponentsUiSelector = createSelector(
  partsKitComponentTaxSelector,
  partCategoriesLookupSelector,
  partsKitComponentsSelector,
  timeMeasureValuesSelector,
  (tax, categories, data, timeMeasures) => {
    return data.components.map((comp) => ({
      id: comp.id,
      number: comp.number,
      categoryId: categories?.find((el) => el.value === comp.categoryId)?.label || comp.categoryId,
      partId: comp.partId,
      partNumber: comp.partNumber || '',
      partName: comp.partName || '-',
      quantity: comp.quantity || '0',
      unitPrice: comp.minUnitPriceString === comp.maxUnitPriceString
        ? `£ ${comp.minUnitPriceString || '0.00'}`
        : `£ ${comp.minUnitPriceString || '0.00'} - £ ${comp.maxUnitPriceString || '0.00'}`,
      netPrice: comp.minNetPriceString === comp.maxNetPriceString
        ? `£ ${comp.minNetPriceString || '0.00'}`
        : `£ ${comp.minNetPriceString || '0.00'} - £ ${comp.maxNetPriceString || '0.00'}`,
      tax: comp.tax,
      weight: timeMeasures.includes(comp.unitOfMeasure) ? '-' : `${comp.weight || 0} kg`,
      unitOfMeasure: comp.unitOfMeasure,
      isSuitableForAnyPartsKitVehicle: comp.isSuitableForAnyPartsKitVehicle,
      hasPrice: comp.hasPrice,
    }));
  },
);
