import React from 'react';
import classNames from 'classnames';
import DashboardCardSkeleton from './DashboardCardSkeleton';
import './DashboardSkeleton.scss';

export type DashboardSkeletonProps = {
  hasPrice?: boolean,
  hasTotalCost?: boolean,
  linkedEntities?: number,
  hasTrackingInfo?: boolean,
}

const DashboardSkeleton: React.FC<DashboardSkeletonProps> = ({ hasTotalCost, ...props }) => {
  return (
    <div className="dashboardSkeleton skeleton-shimmer">
      {Array(10).fill(null).map((_, i) => (
        <div className="dashboardSkeleton__column" key={i}>
          <div className={classNames('dashboardSkeleton__heading', { 'dashboardSkeleton__heading--with-cost': hasTotalCost })}>
            <div className="dashboardSkeleton__title skeleton-line--dark" />
            <div className="dashboardSkeleton__count skeleton-line--dark" />
            {hasTotalCost && <div className="dashboardSkeleton__cost" />}
          </div>
          {Array(5).fill(null).map((_, i) => (
            <DashboardCardSkeleton key={i} {...props} />
          ))}
        </div>
      ))}
    </div>
  );
};

export default DashboardSkeleton;
