import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { rectificationReportFiltersSelector, reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { setRectificationReportFilters } from '../../../../../../store/slices/reportsSlice';
import {
  getSalesRectificationReportExcelThunk,
  getSalesRectificationReportThunk,
} from '../../../../../../store/thunks/reports/reportsThunks';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import { useRectificationReportRanges } from '../../../../../hooks/useRectificationReportRanges';

type RectificationReportColumnsProps = {
  columns: ICustomColumn[],
  totalElements: number,
}

const RectificationReportColumns: React.FC<RectificationReportColumnsProps> = ({ columns, totalElements }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(rectificationReportFiltersSelector);

  const { range, rangePatterns, setRange } = useRectificationReportRanges();

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setRectificationReportFilters(newFilters));
      dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setRectificationReportFilters(newFilters));
      dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
    }
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const newFilters = {
      ...filters,
      [field]: { min: value[0], max: value[1] },
    };
    dispatch(setRectificationReportFilters(newFilters));
    dispatch(getSalesRectificationReportThunk({ filters: newFilters }));
  };

  const exportToExcel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, pageSize, ...restFilters } = filters;
    dispatch(getSalesRectificationReportExcelThunk({ filters: restFilters }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => <ThWithControls
          key={col.dataIndex}
          col={col}
          tableFilters={filters}
          sortHandle={sortHandle}
          setRangeHandle={setRangeHandle}
          range={range}
          rangePatterns={rangePatterns}
          setRange={setRange}
          totalElements={totalElements}
          className={`rectificationReportTh--${col.dataIndex}`}
          disabledFilter={!reportIsVisible}
        />)}
        <ThNew action>
          <Dropdown
            disabled={!totalElements}
            menuItems={[
              {
                label: 'Export to Excel',
                icon: <FontAwesomeIcon icon={faFileExcel} />,
                key: 'export-to-excel',
                onClick: exportToExcel,
              },
            ]}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default RectificationReportColumns;
