import { useEffect } from 'react';
import { AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { DictionaryItem, DictionaryResponse } from '../../../core/types/coreTypes';
import {
  customerEventTypesSelector,
  employeeEventTypesSelector,
  enquiryEventTypesSelector,
  jobEventTypesSelector,
  orderEventTypesSelector,
  partRequestEventTypesSelector,
  partsKitEventTypesSelector,
  partViewEventTypesSelector,
  pricelistEventTypesSelector,
  purchaseOrderEventTypesSelector,
  rectificationEventTypesSelector,
  supplierEventTypesSelector,
  userEntityEventTypesSelector,
  vehicleEventTypesSelector,
} from '../../../store/selectors/coreEventTypesSelectors';
import {
  getCustomerEventTypesThunk,
  getEmployeeEventTypesThunk,
  getEnquiryEventTypesThunk,
  getJobEventTypesThunk,
  getOrderEventTypesThunk,
  getPartRequestEventTypesThunk,
  getPartsKitEventTypesThunk,
  getPartViewEventTypesThunk,
  getPricelistEventTypesThunk,
  getPurchaseOrderEventTypesThunk,
  getRectificationEventTypesThunk,
  getSupplierEventTypesThunk,
  getUserEntityEventTypesThunk,
  getVehicleEventTypesThunk,
} from '../../../store/thunks/core/coreEventTypesThunks';
import {
  getDayPartsThunk,
  getFullVehicleCheckDecisionsThunk,
  getHandoverVehicleCheckDecisionsThunk,
  getInvoiceDocumentTypesThunk,
  getLeaveRequestTypesThunk,
  getPartRequestLineActivityTypesThunk,
  getRectificationFaultTypesThunk,
  getRectificationTypesThunk,
  getVehicleCheckTypesThunk,
} from '../../../store/thunks/coreThunk';
import {
  dayPartsUiSelector,
  fullVehicleCheckDecisionsSelector,
  handoverVehicleCheckDecisionsSelector,
  invoiceDocumentTypesSelector,
  leaveRequestTypesUiSelector,
  partRequestLineActivityTypesSelector,
  rectificationFaultTypesSelector,
  rectificationTypesSelector,
  vehicleCheckTypesSelector,
} from '../../../store/selectors/coreSelectors';
import { employeesStatusesSelector } from '../../../store/selectors/employeesSelector';
import { getEmployeeStatusesThunk } from '../../../store/thunks/employee/createEmployeeThunks';
import { purchaseOrderPartFailReasonSelector } from '../../../store/selectors/purchaseOrderSelectors';
import { getPurchaseOrderFailReason } from '../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import {
  invoiceDocumentStatusesSelector,
  jobQualityCheckStatusesSelector,
  leaveRequestStatusesUiSelector,
  partRequestLineStatusesSelector,
  partRequestStatusesUiSelector,
  stockPartStatusesSelector, supplierStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import {
  getInvoiceDocumentStatusesThunk,
  getJobQualityCheckStatusesThunk,
  getLeaveRequestStatusesThunk,
  getPartRequestLineStatuses,
  getPartRequestStatuses,
  getStockPartsStatuses,
  getSupplierStatusesThunk,
} from '../../../store/thunks/core/coreStatusesThunks';

export const useUserTimelineEventTypes = () => {
  const dispatch = useAppDispatch();

  const customerEventTypes = useAppSelector(customerEventTypesSelector);
  const orderEventTypes = useAppSelector(orderEventTypesSelector);
  const rectificationEventTypes = useAppSelector(rectificationEventTypesSelector);
  const jobEventTypes = useAppSelector(jobEventTypesSelector);
  const employeeEventTypes = useAppSelector(employeeEventTypesSelector);
  const partEventTypes = useAppSelector(partViewEventTypesSelector);
  const partsKitEventTypes = useAppSelector(partsKitEventTypesSelector);
  const purchaseOrderEventTypes = useAppSelector(purchaseOrderEventTypesSelector);
  const pricelistEventTypes = useAppSelector(pricelistEventTypesSelector);
  const partRequestEventTypes = useAppSelector(partRequestEventTypesSelector);
  const enquiryEventTypes = useAppSelector(enquiryEventTypesSelector);
  const supplierEventTypes = useAppSelector(supplierEventTypesSelector);
  const vehicleEventTypes = useAppSelector(vehicleEventTypesSelector);
  const userEntityEventTypes = useAppSelector(userEntityEventTypesSelector);

  const rectificationTypes = useAppSelector(rectificationTypesSelector);
  const rectificationFaultTypes = useAppSelector(rectificationFaultTypesSelector);
  const employeeStatuses = useAppSelector(employeesStatusesSelector);
  const partStatuses = useAppSelector(stockPartStatusesSelector);
  const purchaseOrderPartFailReason = useAppSelector(purchaseOrderPartFailReasonSelector);
  const invoiceDocumentStatuses = useAppSelector(invoiceDocumentStatusesSelector);
  const invoiceDocumentTypes = useAppSelector(invoiceDocumentTypesSelector);
  const partRequestStatuses = useAppSelector(partRequestStatusesUiSelector);
  const supplierStatuses = useAppSelector(supplierStatusesSelector);
  const vehicleCheckTypes = useAppSelector(vehicleCheckTypesSelector);
  const handoverCheckDecisions = useAppSelector(handoverVehicleCheckDecisionsSelector);
  const fullVehicleCheckDecisions = useAppSelector(fullVehicleCheckDecisionsSelector);
  const jobQualityChecksStatuses = useAppSelector(jobQualityCheckStatusesSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);
  const leaveRequestStatuses = useAppSelector(leaveRequestStatusesUiSelector);
  const leaveRequestTypes = useAppSelector(leaveRequestTypesUiSelector);
  const partRequestLineActivityTypes = useAppSelector(partRequestLineActivityTypesSelector);
  const partRequestLineStatuses = useAppSelector(partRequestLineStatusesSelector);

  const entities: [DictionaryItem[], AsyncThunk<DictionaryResponse, void, Record<string, unknown>>][] = [
    [customerEventTypes, getCustomerEventTypesThunk],
    [orderEventTypes, getOrderEventTypesThunk],
    [rectificationEventTypes, getRectificationEventTypesThunk],
    [jobEventTypes, getJobEventTypesThunk],
    [employeeEventTypes, getEmployeeEventTypesThunk],
    [partEventTypes, getPartViewEventTypesThunk],
    [partsKitEventTypes, getPartsKitEventTypesThunk],
    [purchaseOrderEventTypes, getPurchaseOrderEventTypesThunk],
    [pricelistEventTypes, getPricelistEventTypesThunk],
    [partRequestEventTypes, getPartRequestEventTypesThunk],
    [enquiryEventTypes, getEnquiryEventTypesThunk],
    [supplierEventTypes, getSupplierEventTypesThunk],
    [vehicleEventTypes, getVehicleEventTypesThunk],
    [userEntityEventTypes, getUserEntityEventTypesThunk],
  ];

  const dictionaries: [DictionaryItem[], AsyncThunk<DictionaryResponse, void, Record<string, unknown>>][] = [
    [rectificationTypes, getRectificationTypesThunk],
    [rectificationFaultTypes, getRectificationFaultTypesThunk],
    [employeeStatuses, getEmployeeStatusesThunk],
    [partStatuses, getStockPartsStatuses],
    [purchaseOrderPartFailReason, getPurchaseOrderFailReason],
    [invoiceDocumentStatuses, getInvoiceDocumentStatusesThunk],
    [invoiceDocumentTypes, getInvoiceDocumentTypesThunk],
    [partRequestStatuses, getPartRequestStatuses],
    [supplierStatuses, getSupplierStatusesThunk],
    [handoverCheckDecisions, getHandoverVehicleCheckDecisionsThunk],
    [fullVehicleCheckDecisions, getFullVehicleCheckDecisionsThunk],
    [vehicleCheckTypes, getVehicleCheckTypesThunk],
    [jobQualityChecksStatuses, getJobQualityCheckStatusesThunk],
    [dayParts, getDayPartsThunk],
    [leaveRequestStatuses, getLeaveRequestStatusesThunk],
    [leaveRequestTypes, getLeaveRequestTypesThunk],
    [partRequestLineActivityTypes, getPartRequestLineActivityTypesThunk],
    [partRequestLineStatuses, getPartRequestLineStatuses],
  ];

  useEffect(() => {
    [...entities, ...dictionaries].forEach(([dictionary, asyncThunk]) => {
      if (!dictionary.length) {
        dispatch(asyncThunk());
      }
    });
    // eslint-disable-next-line
  }, []);
};
