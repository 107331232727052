import React from 'react';
import Pagination from '../../../../../core/components/pagination/Pagination';
import {
  setFitterReturnsReportFilters,
} from '../../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  fitterReturnsFiltersSelector, fitterReturnsReportDataSelector,
  reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import FitterReturnsTable from './table/FitterReturnsTable';
import { getPurchasingFitterReturnsReportThunk } from '../../../../../store/thunks/reports/reportsThunks';

const FitterReturnsContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fitterReturnsFiltersSelector);
  const tableData = useAppSelector(fitterReturnsReportDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={tableData.totalCount}
          onChange={(page, pageSize) => {
            const newFilters = { ...filters, page, pageSize };
            dispatch(setFitterReturnsReportFilters(newFilters));
            reportIsVisible && dispatch(getPurchasingFitterReturnsReportThunk({ filters: newFilters }));
          }}
          className="actions-and-paging__paging"
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
        />
      </div>
      <FitterReturnsTable />
    </>
  );
};

export default FitterReturnsContent;
