import React from 'react';
import { RectificationViewModeParamsEnum } from '../../enums/RectificationViewEnums';
import RectificationAttachments from './attachments/RectificationAttachments';
import RectificationLinks from './links/RectificationLinks';
import RectificationContacts from './contacts/RectificationContacts';
import RectificationDetails from './details/RectificationDetails';
import RectificationPartRequest from './rectification-part-request/RectificationPartRequest';

type RectificationContentProps = {
  mode: string | null,
}

const RectificationContent: React.FC<RectificationContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case RectificationViewModeParamsEnum.RECTIFICATION_DETAILS:
        return <RectificationDetails />;
      case RectificationViewModeParamsEnum.RECTIFICATION_PART_REQUEST:
        return <RectificationPartRequest />;
      case RectificationViewModeParamsEnum.RECTIFICATION_CONTACTS:
        return <RectificationContacts />;
      case RectificationViewModeParamsEnum.RECTIFICATION_ATTACHMENTS:
        return <RectificationAttachments />;
      case RectificationViewModeParamsEnum.RECTIFICATION_LINKS:
        return <RectificationLinks />;
      default:
        return null;
    }
  };

  return (
    <>{renderContent()}</>
  );
};

export default RectificationContent;
