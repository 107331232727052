import React from 'react';
import { faAnglesDown, faAnglesUp, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch } from '../../../../store/hooks';
import { setStockSearchFilters } from '../../../../store/slices/stockSlice';
import { StockFiltersType } from '../../types/stockSearchTypes';
import Button from '../../../../core/components/button/Button';
import { BooleanFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import { getStockSearch } from '../../../../store/thunks/stock/stockThunks';
import ButtonWithBadge from '../../../../core/components/button-with-badge/ButtonWithBadge';
import InputWithButton from '../../../../core/components/input-with-button/InputWithButton';

type SearchSimpleFiltersProps = {
  filters: StockFiltersType,
  setIsAdvanced: BooleanFunctionType,
  isAdvanced: boolean,
  onClear: VoidFunctionType,
}

const SearchSimpleFilters: React.FC<SearchSimpleFiltersProps> = ({
  filters, setIsAdvanced, isAdvanced, onClear,
}) => {
  const dispatch = useAppDispatch();
  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    page, pageSize, keyword, partCode, manufacturerCode, name,
    ...rest
  } = filters;
  const disabledSearchButton = (!filters.keyword
      || filters.keyword.trim().length === 0)
      && !filters.categoryIds?.length
      && !filters.subcategoryIds?.length;
  return (
    <section className="stockSearch__simple">
      <InputWithButton
        value={filters.keyword}
        onChange={(e) => {
          const val = e.target.value;
          if (val.length <= 250) {
            dispatch(setStockSearchFilters({ ...filters, keyword: val, page: 1 }));
          } else {
            const croppedVal = val.slice(0, 250);
            dispatch(setStockSearchFilters({ ...filters, keyword: croppedVal, page: 1 }));
          }
        }}
        placeholder="Search by part code, manufacturer code, part name or description"
        className="stockSearch__simple-input"
        onPressEnter={() => {
          !disabledSearchButton && dispatch(getStockSearch({
            filters: {
              ...filters,
              keyword: filters.keyword?.trim(),
            },
          }));
        }}
        icon={<FontAwesomeIcon icon={faCircleXmark} />}
        visibleButton={!!(filters.keyword && filters.keyword.trim().length > 0)}
        onClick={onClear}
      />
      <ButtonWithBadge
        badge={Object.values(rest).some((val) => val && (val as (number | string)[]).length > 0)
        && Object.values(rest).filter((val) => val && (val as (number | string)[]).length > 0).length}
        icon={<FontAwesomeIcon icon={isAdvanced ? faAnglesUp : faAnglesDown} />}
        onClick={() => setIsAdvanced(!isAdvanced)}
        isActivated={isAdvanced}
        className="stockSearch__expand-btn"
      />
      <Button
        label="Search"
        icon={<FontAwesomeIcon icon={faSearch} />}
        disabled={disabledSearchButton}
        onClick={() => {
          dispatch(getStockSearch({ filters: { ...filters, keyword: filters.keyword?.trim() } }));
        }}
        className="stockSearch__search-btn"
        designType="dark"
        reversed
      />
    </section>
  );
};

export default SearchSimpleFilters;
