import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { NumberFunctionType, SearchTableFilterType } from '../../../core/types/coreTypes';
import {
  stockPartReqColumnsSelector,
  stockPartRequestLinesFiltersSelector,
  stockPartRequestLinesSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import { setStockPartRequestLinesFilters } from '../../../store/slices/stockPartRequestSlice';
import StockPartRequestLinesBody from './StockPartRequestLinesBody';

type StockPartReqTableProps = {
  setEditReadyCollected: NumberFunctionType,
  setDistributeQtyHandle: NumberFunctionType,
  setIsMarkNotFromStock: NumberFunctionType,
  setConfirmReturnedPartsHandle: NumberFunctionType,
  setRemapToExistingPart: NumberFunctionType,
}

const StockPartReqTable: React.FC<StockPartReqTableProps> = ({
  setEditReadyCollected,
  setDistributeQtyHandle,
  setIsMarkNotFromStock,
  setConfirmReturnedPartsHandle,
  setRemapToExistingPart,
}) => {
  const dispatch = useAppDispatch();
  const stockPartReqColumns = useAppSelector(stockPartReqColumnsSelector);
  const { totalCount } = useAppSelector(stockPartRequestLinesSelector);
  const stockPartRequestLinesFilters = useAppSelector(stockPartRequestLinesFiltersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productName: stockPartRequestLinesFilters?.productName,
    productNumber: stockPartRequestLinesFilters?.productNumber,
    notes: stockPartRequestLinesFilters?.notes,
  });

  const filters = [
    {
      dataIndex: 'categoryId',
      value: stockPartRequestLinesFilters?.categories || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        categories: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'priceSources',
      value: stockPartRequestLinesFilters?.priceSources || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        priceSources: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'status',
      value: stockPartRequestLinesFilters?.statuses || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        statuses: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (stockPartRequestLinesFilters?.order?.field === field) {
      dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        order: { ...stockPartRequestLinesFilters.order, isAsc: !stockPartRequestLinesFilters.order?.isAsc },
      }));
    } else {
      dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        order: { field, isAsc: true },
      }));
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setStockPartRequestLinesFilters({
        ...stockPartRequestLinesFilters,
        [field]: undefined,
      }));
    }
  };
  const searchPressHandle = (field: string) => {
    dispatch(setStockPartRequestLinesFilters({
      ...stockPartRequestLinesFilters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  return (
    <TableNew>
      <Thead>
        <Tr>
          {stockPartReqColumns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            totalElements={totalCount}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            disabled={totalCount === 0}
            filters={filters}
            tableFilters={stockPartRequestLinesFilters}
            className={`stockPartReqTh--${col.dataIndex}`}
          />)}
          <ThNew action><span className="sr-only">Actions</span></ThNew>
        </Tr>
      </Thead>
      <StockPartRequestLinesBody
        setEditReadyCollected={setEditReadyCollected}
        setDistributeQtyHandle={setDistributeQtyHandle}
        setConfirmReturnedPartsHandle={setConfirmReturnedPartsHandle}
        setIsMarkNotFromStock={setIsMarkNotFromStock}
        setRemapToExistingPart={setRemapToExistingPart}
        columnsLength={stockPartReqColumns.length}
      />
    </TableNew>
  );
};

export default StockPartReqTable;
