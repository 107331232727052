import { useContext } from 'react';
import PermissionContext from './PermissionContext';
import { PermissionEnum } from '../core/enums/dictionariesEnums';

const usePermission = (permission?: PermissionEnum) => {
  const { isAllowedTo } = useContext(PermissionContext);

  return permission ? isAllowedTo(permission) : false;
};

export default usePermission;
