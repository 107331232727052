import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import {
  AffectedEmployeeDto,
  AffectedEmployeesFiltersType,
  SkillCategoryLookupDto, SkillDto,
  SkillsFilterType,
} from '../../../employees/skill-list/types/skillsTypes';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setSkillsFilters } from '../../slices/employeesSlice';
import { RootState } from '../../store';

export const getSkillsCategoriesThunk = createAsyncThunk<
ResponseSingleResult<{ categories: SkillCategoryLookupDto[] }>>(
  'get/SkillsCategories',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchSkillsCategories();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSkillCategoryByIdThunk = createAsyncThunk<
ResponseSingleResult<{ skills: SkillDto[], allItemIds: number[] }>,
{ filters: SkillsFilterType }
>(
  'get/SkillCategoryById',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeSkills(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewSkillCategoryThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>,
{ name: string, closeModal: VoidFunctionType }
>(
  'post/SkillCategory',
  async ({ name, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees } = getState() as RootState;
      const { skillsFilters } = employees;
      const response = await employeeAPI.createNewCategory(name);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE, toastId: Math.random() }));
      dispatch(getSkillsCategoriesThunk());
      closeModal();
      dispatch(setSkillsFilters({ ...skillsFilters, categoryId: response.data.data.createdId }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editSkillCategoryThunk = createAsyncThunk<
ResponseSingleResult,
{ id: number, name: string, closeModal: VoidFunctionType, }
>(
  'put/SkillCategory',
  async ({
    id, name, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.editCategory(id, name);
      dispatch(getSkillsCategoriesThunk());
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSkillCategoryThunk = createAsyncThunk<
ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/SkillCategory',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees: { skillsFilters, skillsCategories } } = getState() as RootState;
      const response = await employeeAPI.deleteCategory(id);
      dispatch(getSkillsCategoriesThunk());
      dispatch(setSkillsFilters({ ...skillsFilters, categoryId: skillsCategories[0].id }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addSkillToCategoryThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>,
{
  categoryId: number,
  name: string,
  description: string | null,
  closeModal: VoidFunctionType,
}
>(
  'post/Skill',
  async ({
    categoryId, name, description, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees: { skillsFilters } } = getState() as RootState;
      const response = await employeeAPI.addSkillToCategory(categoryId, name, description);
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE, toastId: Math.random() }));
      dispatch(getSkillCategoryByIdThunk({ filters: skillsFilters }));
      dispatch(getSkillsCategoriesThunk());
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editSkillThunk = createAsyncThunk<
ResponseSingleResult,
{
  skillId: number,
  categoryId: number,
  name: string,
  description: string | null,
  closeModal: VoidFunctionType,
}
>(
  'put/Skill',
  async ({
    skillId, categoryId, name, description, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees: { skillsFilters } } = getState() as RootState;
      const response = await employeeAPI.editSkill(skillId, categoryId, name, description);
      closeModal();
      dispatch(getSkillCategoryByIdThunk({ filters: skillsFilters }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSkillThunk = createAsyncThunk<
ResponseSingleResult,
{
  skillId: number,
  closeModal: VoidFunctionType,
}
>(
  'delete/OneSkill',
  async ({ skillId, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees: { skillsFilters } } = getState() as RootState;
      const response = await employeeAPI.deleteSkill(skillId);
      closeModal();
      dispatch(getSkillCategoryByIdThunk({ filters: skillsFilters }));
      dispatch(getSkillsCategoriesThunk());
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSkillsThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  ids: number[],
  closeModal: VoidFunctionType,
}
>(
  'delete/MultipleSkills',
  async ({ ids, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { employees: { skillsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await employeeAPI.fetchEmployeeSkills(skillsFilters);
      const onFinish = () => {
        closeModal();
        dispatch(getSkillCategoryByIdThunk({ filters: skillsFilters }));
        dispatch(getSkillsCategoriesThunk());
        dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
        dispatch(setFetching(false));
      };
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      const response = await employeeAPI.deleteSkills(filteredIds);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSkillAffectedEmployees = createAsyncThunk<
ResponseResult<AffectedEmployeeDto[]> | null,
{
  skillId: number[],
  filters: AffectedEmployeesFiltersType,
  complexAction?: VoidFunctionType,
  confirmAction?: VoidFunctionType,
}
>(
  'get/SkillAffectedEmployees',
  async ({
    skillId, filters, complexAction, confirmAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchSkillAffectedEmployees(skillId, filters);
      const { totalCount } = response.data.data;
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCategoryAffectedEmployees = createAsyncThunk<
ResponseResult<AffectedEmployeeDto[]> | null,
{
  categoryId: number,
  filters: AffectedEmployeesFiltersType,
  complexAction?: VoidFunctionType,
  confirmAction?: VoidFunctionType,
}
>(
  'get/CategoryAffectedEmployees',
  async ({
    categoryId, filters, complexAction, confirmAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchCategoryAffectedEmployees(categoryId, filters);
      const { totalCount } = response.data.data;
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
