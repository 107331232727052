import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DictionaryItem, DictionaryResponse } from '../../core/types/coreTypes';
import {
  getAccountAccountStatus,
  getCustomerStatuses,
  getEnquiryStatus,
  getInAppNotificationStatuses,
  getInfoMessageStatuses,
  getInvoiceDocumentStatusesThunk, getJobQualityCheckStatusesThunk,
  getJobStatusesThunk,
  getLeaveRequestStatusesThunk,
  getOrderStatus,
  getPartRequestLineStatuses,
  getPartRequestStatuses,
  getPartsKitsStatuses,
  getPricelistStatuses,
  getPurchaseOrderStatus,
  getQuantitiesStatuses,
  getQuotePricelistStatuses,
  getRectificationStatusesThunk,
  getRequestSubmissionStatuses,
  getStockPartRequestLineStatusTransition,
  getStockPartsStatuses, getSupplierStatusesThunk,
  getVehicleStatuses,
} from '../thunks/core/coreStatusesThunks';

interface ICoreStatusesState {
  enquiryStatus: DictionaryItem[],
  customerStatuses: DictionaryItem[],
  accountAccountStatus: DictionaryItem[],
  infoMessageStatuses: DictionaryItem[],
  pricelistStatuses: DictionaryItem[],
  quotePricelistStatuses: DictionaryItem[],
  partsKitsStatuses: DictionaryItem[],
  jobStatuses: DictionaryItem[],
  orderStatus: DictionaryItem[],
  rectificationStatuses: DictionaryItem[],
  partRequestLineStatuses: DictionaryItem[],
  inAppNotificationStatuses: DictionaryItem[],
  vehicleStatuses: DictionaryItem[],
  requestSubmissionStatuses: DictionaryItem[],
  leaveRequestStatuses: DictionaryItem[],
  partRequestStatuses: DictionaryItem[],
  purchaseOrderStatus: DictionaryItem[],
  qtyStatuses: DictionaryItem[],
  stockPartStatuses: DictionaryItem[],
  stockPartRequestLineStatusTransition: DictionaryItem[],
  invoiceDocumentStatuses: DictionaryItem[],
  supplierStatuses: DictionaryItem[],
  jobQualityCheckStatuses: DictionaryItem[],
}

export const initialCoreStatusesState: ICoreStatusesState = {
  enquiryStatus: [],
  customerStatuses: [],
  accountAccountStatus: [],
  quotePricelistStatuses: [],
  infoMessageStatuses: [],
  pricelistStatuses: [],
  partsKitsStatuses: [],
  jobStatuses: [],
  orderStatus: [],
  rectificationStatuses: [],
  partRequestLineStatuses: [],
  inAppNotificationStatuses: [],
  vehicleStatuses: [],
  requestSubmissionStatuses: [],
  leaveRequestStatuses: [],
  partRequestStatuses: [],
  purchaseOrderStatus: [],
  qtyStatuses: [],
  stockPartStatuses: [],
  stockPartRequestLineStatusTransition: [],
  invoiceDocumentStatuses: [],
  supplierStatuses: [],
  jobQualityCheckStatuses: [],
};

export const coreStatusesSlice = createSlice({
  name: 'coreStatuses',
  initialState: initialCoreStatusesState,
  reducers: {},
  // Later, dispatch the thunk as needed in the app
  extraReducers: (builder) => {
    builder.addCase(getEnquiryStatus.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquiryStatus = action.payload.data.items;
    });
    builder.addCase(getCustomerStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.customerStatuses = action.payload.data.items;
    });
    builder.addCase(getAccountAccountStatus.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.accountAccountStatus = action.payload.data.items;
    });
    builder.addCase(getInfoMessageStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.infoMessageStatuses = action.payload.data.items;
    });
    builder.addCase(getPricelistStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistStatuses = action.payload.data.items;
    });
    builder.addCase(getQuotePricelistStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.quotePricelistStatuses = action.payload.data.items;
    });
    builder.addCase(getPartsKitsStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partsKitsStatuses = action.payload.data.items;
    });
    builder.addCase(getJobStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.jobStatuses = action.payload.data.items;
    });
    builder.addCase(getPartRequestLineStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partRequestLineStatuses = action.payload.data.items;
    });
    builder.addCase(getOrderStatus.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.orderStatus = action.payload.data.items;
    });
    builder.addCase(getRectificationStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationStatuses = action.payload.data.items;
    });
    builder.addCase(getInAppNotificationStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.inAppNotificationStatuses = action.payload.data.items;
    });
    builder.addCase(getVehicleStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleStatuses = action.payload.data.items;
    });
    builder.addCase(getRequestSubmissionStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.requestSubmissionStatuses = action.payload.data.items;
    });
    builder.addCase(getPartRequestStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partRequestStatuses = action.payload.data.items;
    });
    builder.addCase(getPurchaseOrderStatus.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.purchaseOrderStatus = action.payload.data.items;
    });
    builder.addCase(getQuantitiesStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.qtyStatuses = action.payload.data.items;
    });
    builder.addCase(getStockPartsStatuses.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartStatuses = action.payload.data.items;
    });
    builder.addCase(getStockPartRequestLineStatusTransition.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartRequestLineStatusTransition = action.payload.data.items;
    });
    builder.addCase(getLeaveRequestStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.leaveRequestStatuses = action.payload.data.items;
    });
    builder.addCase(getInvoiceDocumentStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.invoiceDocumentStatuses = action.payload.data.items;
    });
    builder.addCase(getSupplierStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.supplierStatuses = action.payload.data.items;
    });
    builder.addCase(getJobQualityCheckStatusesThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.jobQualityCheckStatuses = action.payload.data.items;
    });
  },
});

export default coreStatusesSlice.reducer;
