import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  outstandingOverduePurchasingReportDataSelector,
  outstandingOverduePurchasingReportFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setOutstandingOverduePurchasingReportFilters } from '../../../../../store/slices/reportsSlice';
import {
  getPurchasingOutstandingOverduePurchaseOrdersThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import Pagination from '../../../../../core/components/pagination/Pagination';
import OutstandingOverduePurchaseOrdersTable from './table/OutstandingOverduePurchaseOrdersTable';

const OutstandingOverduePurchaseOrdersContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(outstandingOverduePurchasingReportFiltersSelector);
  const tableData = useAppSelector(outstandingOverduePurchasingReportDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={tableData.totalCount}
          onChange={(page, pageSize) => {
            const newFilters = { ...filters, page, pageSize };
            dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
            reportIsVisible && dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
          }}
          className="actions-and-paging__paging"
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
        />
      </div>
      <OutstandingOverduePurchaseOrdersTable />
    </>
  );
};

export default OutstandingOverduePurchaseOrdersContent;
