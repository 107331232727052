import { AdjustQtyFiltersType } from '../types/types';
import { initSmallPaging } from '../../../core/types/coreTypes';

export const partPricesColumns = [
  {
    title: 'Price source',
    dataIndex: 'priceSource',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
  },
  {
    title: 'Cost price',
    dataIndex: 'costPrice',
  },
  {
    title: 'Selling price',
    dataIndex: 'sellingPrice',
  },
];

export const initAdjustPartQuantityFilters: AdjustQtyFiltersType = {
  ...initSmallPaging,
};
