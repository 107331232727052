import { CollectionColumnPaginationType, GenericColumnType } from '../../../core/types/coreTypes';
import { PagedRectificationCollectionDto, RectificationDto } from '../types/rectificationsTypes';

export const rectificationStatusTransitionHelper = (
  prevCollections: Array<GenericColumnType<Array<RectificationDto>>>,
  responseCollections: PagedRectificationCollectionDto[],
  oldPagination: Array<CollectionColumnPaginationType>,
  statusTransition: number,
  sourceId: number,
) => {
  const currentOld = responseCollections.find((el) => el.status === sourceId);
  const currentNew = responseCollections.find((el) => el.status === statusTransition);
  const newPagination = oldPagination.map((p) => {
    if (p.status === currentOld?.status) {
      return { ...p, pages: currentOld.pages, currentPage: 1 };
    }
    if (p.status === currentNew?.status) {
      return { ...p, pages: currentNew?.pages, currentPage: 1 };
    } else return p;
  });
  const newCollections = prevCollections.map((col) => {
    if (col.status === currentNew?.status) {
      const { rectifications, ordinalNumber, ...rest } = currentNew;
      return { ...rest, order: ordinalNumber, list: rectifications };
    }
    if (col.status === currentOld?.status) {
      const { rectifications, ordinalNumber, ...rest } = currentOld;
      return { ...rest, order: ordinalNumber, list: rectifications };
    } else return col;
  });
  return { newPagination, newCollections };
};
