import React from 'react';
import { Spin } from 'antd';
import Tr from './Tr';
import Tbody from './Tbody';

type TableLoaderProps = {
  colspan?: number,
}
const TableLoader: React.FC<TableLoaderProps> = ({ colspan = 5 }) => {
  return (
    <Tbody>
      <Tr>
        <td style={{ textAlign: 'center' }} colSpan={colspan}>
          <div className="partsTable__empty">
            <Spin />
          </div>
        </td>
      </Tr>
    </Tbody>
  );
};

export default TableLoader;
