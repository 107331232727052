import React from 'react';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import InvoicesAndCreditNotesColumns from './InvoicesAndCreditNotesColumns';
import InvoicesAndCreditNotesBody from './InvoicesAndCreditNotesBody';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import './InvoicesAndCreditNotesTable.scss';

type InvoicesAndCreditNotesTableProps = {
  setIdToEdit: NumberFunctionType,
  setIdToDelete: NumberFunctionType,
}
const InvoicesAndCreditNotesTable: React.FC<InvoicesAndCreditNotesTableProps> = ({ setIdToEdit, setIdToDelete }) => {
  return (
    <TableNew offsetBottom>
      <InvoicesAndCreditNotesColumns />
      <InvoicesAndCreditNotesBody setIdToEdit={setIdToEdit} setIdToDelete={setIdToDelete} />
    </TableNew>
  );
};

export default InvoicesAndCreditNotesTable;
