import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { rectificationsBySupplierFiltersSelector } from '../../store/selectors/reportsSelectors';
import { rectificationsBySupplierOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getRectificationsBySupplierOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { RectificationsBySupplierReportOrdering } from '../enum/ReportTableOrders';
import { setRectificationsBySupplierFilters } from '../../store/slices/reportsSlice';
import { initPaging } from '../../core/types/coreTypes';

export const useRectificationsBySupplierReportDefaultOrdering = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(rectificationsBySupplierFiltersSelector);
  const ordering = useAppSelector(rectificationsBySupplierOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    if (!ordering.length) {
      dispatch(getRectificationsBySupplierOrderingThunk());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === RectificationsBySupplierReportOrdering.UsedInRectifications)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setRectificationsBySupplierFilters({ ...filters, order: { field: defaultOrdering, isAsc: false } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: false } : filters.order,
  };
};
