import React from 'react';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';

type OrderFromFinalizedToSentModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  confirmHandle: VoidFunctionType,
}

const OrderFromFinalizedToSentModal: React.FC<OrderFromFinalizedToSentModalProps> = ({ isVisible, onCancel, confirmHandle }) => {
  return (
    <>
      <ConfirmationModal
        isVisible={isVisible}
        onCancel={onCancel}
        customTitle="Confirm"
        confirmHandler={confirmHandle}
        customCreateLabel="Confirm"
        customText="Please confirm, that you have sent an email with a purchase order to a supplier."
        customProceedText={<>
          Note that a list of parts and requested quantity will be locked for editing in
          <b>{' "Sent to supplier" '}</b>
          status.
        </>}
      />
    </>
  );
};

export default OrderFromFinalizedToSentModal;
