import React from 'react';
import './EmployeesSkeletonItem.scss';

const EmployeesSkeletonItem: React.FC = () => {
  return (
    <div className="employeesSkeletonItem skeleton-item">
      <div className="employeesSkeletonItem__info">
        <div className="employeesSkeletonItem__img" />
        <div className="employeesSkeletonItem__text" />
      </div>
      <div className="employeesSkeletonItem__contact" />
      <div className="employeesSkeletonItem__contact" />
      <div className="employeesSkeletonItem__department" />
    </div>
  );
};

export default EmployeesSkeletonItem;
