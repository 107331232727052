import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import JobViewHead from './components/job-head/JobViewHead';
import './JobViewPageContainer.scss';
import JobFilters from './components/job-filters/JobFilters';
import { JobContentModes, JobViewModeParamsEnum } from './enums/JobViewEnums';
import JobContent from './components/job-content/JobContent';
import { jobDetailsFiltersSelector, jobDetailsSelector } from '../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { tabCountHandle } from '../../core/utils/tabCountHandle';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { TabOptionType } from '../../common/types/commonTypes';
import { jobCleanUpHandle } from './utils/jobCleanUpHandle';
import { setJobDetailsFilters } from '../../store/slices/jobsSlice';
import { getJobEventTypesThunk } from '../../store/thunks/core/coreEventTypesThunks';
import { deleteJobThunk } from '../../store/thunks/jobs/jobThunks';
import { routesPath } from '../../core/enums/pathEnum';
import { jobStatusesValuesSelector } from '../../store/selectors/coreStatusesSelectors';
import { getJobQualityCheckStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';

const JobViewPageContainer: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { backHandle } = useLocationHistoryState(true);

  const currentUser = useAppSelector(currentUserSelector);
  const jobDetails = useAppSelector(jobDetailsSelector);
  const jobDetailsFilters = useAppSelector(jobDetailsFiltersSelector);
  const { newStatus } = useAppSelector(jobStatusesValuesSelector);

  const [allowedTabs, setAllowedTabs] = useState<TabOptionType[]>([]);

  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const disabledDeleteJob = jobDetails?.job.jobStatus !== newStatus || !!jobDetails?.job.spentMinutes;

  const actions = [
    {
      label: 'Delete job',
      key: 'deleteJob',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setConfirmationModal(true),
      restrictedTo: PermissionEnum.JobDelete,
      disabled: disabledDeleteJob,
    },
  ];
  const defaultTabs = [
    {
      label: 'Details & activity',
      value: JobViewModeParamsEnum.JOB_DETAILS,
      restrictedTo: PermissionEnum.JobViewPage,
    },
    {
      label: `Parts ${tabCountHandle(jobDetails?.partsCount)}`,
      value: JobViewModeParamsEnum.JOB_PARTS,
      restrictedTo: PermissionEnum.JobViewPage,
    },
    {
      label: 'Quality check',
      value: JobViewModeParamsEnum.JOB_QUALITY_CHECK,
      restrictedTo: PermissionEnum.JobViewPage,
    },
    {
      label: 'Time tracking',
      value: JobViewModeParamsEnum.JOB_TIME_TRACKING,
      restrictedTo: PermissionEnum.JobTimeTracking,
    },
    {
      label: `Attachments ${tabCountHandle(jobDetails?.attachmentsCount)}`,
      value: JobViewModeParamsEnum.JOB_ATTACHMENTS,
      restrictedTo: PermissionEnum.JobViewPage,
    },
    {
      label: `Links ${tabCountHandle(jobDetails?.linksCount)}`,
      value: JobViewModeParamsEnum.JOB_LINKS,
      restrictedTo: PermissionEnum.JobViewPage,
    },
  ];

  const setModeParam = (mode: JobViewModeParamsEnum | string) => {
    setSearchParams({ mode }, { replace: true });
  };

  useEffect(
    () => {
      if (currentUser) {
        const { permissions } = currentUser.userContextRole;
        const modeParam = params.get('mode');
        const tabs = defaultTabs.filter((act) => permissions.includes(act.restrictedTo));
        setAllowedTabs(tabs);
        if (!modeParam) {
          setSearchParams({ mode: defaultTabs[0].value }, { replace: true });
        } else {
          if (!JobContentModes.includes(modeParam as JobViewModeParamsEnum)) {
            setSearchParams({ mode: JobViewModeParamsEnum.JOB_DETAILS }, { replace: true });
          }
        }
      }
    }
    // eslint-disable-next-line
  , [jobDetails, currentUser],
  );

  useEffect(() => {
    if (jobDetails) {
      dispatch(setJobDetailsFilters({
        ...jobDetailsFilters,
        assignees: jobDetails?.assignees?.map((ass) => ass.id),
        statusTransition: jobDetails.job.jobStatus,
      }));
    }
    // eslint-disable-next-line
  }, [jobDetails]);

  useEffect(() => {
    dispatch(getJobEventTypesThunk());
    dispatch(getJobQualityCheckStatusesThunk());
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    return () => jobCleanUpHandle(dispatch);
    // eslint-disable-next-line
  }, []);

  const deleteJobHandle = () => {
    id && dispatch(deleteJobThunk({
      id: +id,
      onFinish: () => navigate(`/${routesPath.JOBS}`, { replace: true }),
    }));
  };

  return (
    <Layout
      subHeader={
        <DetailsHead
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          confirmHandler={deleteJobHandle}
          goBack={backHandle}
          className="jobViewPageHead"
          actions={actions}
        >
          <JobViewHead />
        </DetailsHead>
      }
    >
      <JobFilters
        tabs={allowedTabs}
        mode={params.get('mode')}
        setModeParam={setModeParam}
        jobDetailsFilters={jobDetailsFilters}
      />
      <JobContent mode={params.get('mode')} />
    </Layout>
  );
};

export default JobViewPageContainer;
