import React, { useEffect, useState } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { EntityLinkRowType } from '../../../core/types/coreTypes';
import Tbody from '../../../core/components/table-components/Tbody';
import LinksRows from './LinksRows';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';

type LinksBodyProps = {
  links: EntityLinkRowType[];
}

const LinksBody: React.FC<LinksBodyProps> = ({ links }) => {
  const [visibleParents, setVisibleParents] = useState<(string | number)[]>([]);
  useEffect(() => {
    const defaultOpenParents = links
      .filter((el) => el.children.length > 0)
      .map((el) => el.name);
    setVisibleParents(defaultOpenParents);
  }, [links]);
  const showTableData = links.length > 0 && links.some((el) => el.children.length > 0);
  return (
    <Tbody>
      {showTableData
        ? links.map((par) => <LinksRows
          key={par.name}
          parent={par}
          visibleParents={visibleParents}
          setVisibleParents={setVisibleParents}
          parentLink={par.parentLink}
          linkPermission={par.permission}
        />)
        : <EmptyTableSection icon={faLink} text="No links" />}
    </Tbody>
  );
};

export default LinksBody;
