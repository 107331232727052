import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DataListType,
  DictionaryItem,
  initDataList, initDataListWithIds, initPaging, initSmallPaging, ResponseResult, ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  AllocatePartQuantityFilters,
  ConfirmedPartRequestDto,
  ConfirmedPartRequestViewsParams,
  InitConfirmedPartRequestViewsParams,
  initStockPartRequestFilters,
  PartReqPartQuantityFilters,
  PartRequestByLineFilters,
  StockPartProductSourceTotal,
  StockPartRequestDashboardDataType,
  StockPartRequestDashboardInit,
  StockPartRequestDto,
  StockPartRequestFiltersType,
} from '../../stock/stock-part-requests/types/stockPartRequestTypes';
import {
  GetPartQuantitiesDataType,
  PartQtyType,
} from '../../stock/part-view-page/types/types';
import {
  DraftLinePartList,
  DraftLinePartsFiltersType,
  ExtendedTimelineFilters,
  initDraftLinePartsFilters,
  initExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
} from '../../common/types/commonTypes';
import { PartDtoType } from '../../stock/search/types/stockSearchTypes';
import {
  getAllocatePricelistPartQuantities,
  getEditReadyCollectedPartQuantities,
  getPartRequestByLineThunk,
  getPartRequestLinesForPurchaseOrderGeneration,
  getPartRequestsViewsConfirmed,
  getStockPartQuantitySources,
  getStockPartRequestById,
  getStockPartRequestLineProductSourceTotal,
  getStockPartRequestLines,
  getStockPartRequestLinkedEntityTypes,
  getStockPartRequestTimelineThunk,
  getStockPartsRequestDashboard,
  getStockPartsToPartReqThunk,
} from '../thunks/stock/stock-part-requests/stockPartRequestsThunks';
import {
  StockPartRequestEventDtoBase,
} from '../../stock/stock-part-request-view/types/StockPartrequestViewTypes';

interface IStockPartRequestState {
  stockPartRequestLinkedEntitiesTypes: DictionaryItem[],
  stockPartRequestsFilters: StockPartRequestFiltersType,
  stockPartRequestsLinesViewFilters: PartRequestByLineFilters,
  stockPartRequestsLinesView: DataListType<PartRequestLineDto[], { allAvailableForPurchaseOrderGenerationIds: number[] }>,
  stockPartRequestDashboard: StockPartRequestDashboardDataType,
  stockPartRequestDetails: StockPartRequestDto & { canPurchaseOrderBeGenerated: boolean, anyLineLinkedToPurchaseOrder: boolean } | null,
  stockPartRequestLines: DataListType<PartRequestLineDto[], { allItemIds: number[] }>,
  stockPartRequestLinesFilters: PartReqLinesFilters | undefined,
  stockPartProductSourceTotal: StockPartProductSourceTotal | null,
  editReadyCollectedModalFilters: PartReqPartQuantityFilters,
  editReadyCollectedModalQuantities: GetPartQuantitiesDataType | null,
  editReadyCollectedModalAvailablePurchaseOrders: Array<{ purchaseOrderId: number | null, purchaseOrderNumber: string | null }> | null,
  allocatedPartQuantities: DataListType<PartQtyType[] | null>,
  allocatedPartQuantitiesFilters?: AllocatePartQuantityFilters,
  stockPartsToFillPartRequestFilters: DraftLinePartsFiltersType,
  stockPartsToFillPartRequest: DraftLinePartList,
  stockPartQuantitySources: DictionaryItem[],
  stockPartRequestTimelineFilters: ExtendedTimelineFilters,
  stockPartRequestTimeline: DataListType<StockPartRequestEventDtoBase[]>,
  partRequestLinesForPurchaseOrderGeneration: DataListType<PartRequestLineDto[], { allItemIds: number[] }>,
  generatePurchaseOrderFilters: PartReqLinesFilters,
  stockPartRequestsViewsConfirmed: DataListType<ConfirmedPartRequestDto[], { allItemIds: string[] }>,
  confirmedPartRequestViewsParams: ConfirmedPartRequestViewsParams,
}

export const initialStockPartReqState: IStockPartRequestState = {
  stockPartRequestLinkedEntitiesTypes: [],
  stockPartRequestsFilters: initStockPartRequestFilters,
  stockPartRequestsLinesViewFilters: initPaging,
  stockPartRequestsLinesView: { ...initDataList, allAvailableForPurchaseOrderGenerationIds: [] },
  stockPartRequestDashboard: StockPartRequestDashboardInit,
  stockPartRequestDetails: null,
  stockPartRequestLines: initDataListWithIds,
  stockPartRequestLinesFilters: undefined,
  stockPartProductSourceTotal: null,
  editReadyCollectedModalFilters: initSmallPaging,
  editReadyCollectedModalQuantities: null,
  editReadyCollectedModalAvailablePurchaseOrders: null,
  allocatedPartQuantities: initDataList,
  allocatedPartQuantitiesFilters: undefined,
  stockPartsToFillPartRequestFilters: initDraftLinePartsFilters,
  stockPartsToFillPartRequest: initDataList,
  stockPartQuantitySources: [],
  stockPartRequestTimelineFilters: initExtendedTimelineFilters,
  stockPartRequestTimeline: initDataList,
  partRequestLinesForPurchaseOrderGeneration: initDataListWithIds,
  generatePurchaseOrderFilters: { ...initSmallPaging, onlyAvailableForPurchaseOrderGeneration: true },
  stockPartRequestsViewsConfirmed: initDataListWithIds,
  confirmedPartRequestViewsParams: InitConfirmedPartRequestViewsParams,
};

export const stockPartRequestSlice = createSlice({
  name: 'stockPartRequest',
  initialState: initialStockPartReqState,
  reducers: {
    setStockPartRequestsFilters: (state, action: PayloadAction<StockPartRequestFiltersType>) => {
      state.stockPartRequestsFilters = action.payload;
    },
    setStockPartRequestsLinesViewFilters: (state, action: PayloadAction<PartRequestByLineFilters>) => {
      state.stockPartRequestsLinesViewFilters = action.payload;
    },
    setPartRequestDashboard: (state, action: PayloadAction<StockPartRequestDashboardDataType>) => {
      state.stockPartRequestDashboard = action.payload;
    },
    setStockPartRequestDetails: (
      state,
      action: PayloadAction<StockPartRequestDto & { canPurchaseOrderBeGenerated: boolean, anyLineLinkedToPurchaseOrder: boolean } | null>,
    ) => {
      state.stockPartRequestDetails = action.payload;
    },
    setAllocatedPartQuantities: (state, action: PayloadAction<DataListType<PartQtyType[]>>) => {
      state.allocatedPartQuantities = action.payload;
    },
    setAllocatePartQuantitiesFilters: (state, action: PayloadAction<AllocatePartQuantityFilters | undefined>) => {
      state.allocatedPartQuantitiesFilters = action.payload;
    },
    setEditReadyCollectedModalFilters: (state, action: PayloadAction<PartReqPartQuantityFilters>) => {
      state.editReadyCollectedModalFilters = action.payload;
    },
    setEditReadyCollectedModalQuantities: (state, action: PayloadAction<GetPartQuantitiesDataType | null>) => {
      state.editReadyCollectedModalQuantities = action.payload;
    },
    setEditReadyCollectedModalAvailablePurchaseOrders: (
      state,
      action: PayloadAction<Array<{ purchaseOrderId: number | null; purchaseOrderNumber: string | null; }> | null>,
    ) => {
      state.editReadyCollectedModalAvailablePurchaseOrders = action.payload;
    },
    setStockPartRequestLines: (state, action: PayloadAction<DataListType<PartRequestLineDto[], {
      allItemIds: number[],
    }>>) => {
      state.stockPartRequestLines = action.payload;
    },
    setStockPartRequestLinesFilters: (state, action: PayloadAction<PartReqLinesFilters | undefined>) => {
      state.stockPartRequestLinesFilters = action.payload;
    },
    setStockPartProductSourceTotal: (state, action: PayloadAction<StockPartProductSourceTotal | null>) => {
      state.stockPartProductSourceTotal = action.payload;
    },
    setStockPartsToFillPartRequest: (state, action: PayloadAction<DraftLinePartList>) => {
      state.stockPartsToFillPartRequest = action.payload;
    },
    setStockPartsToFillPartRequestFilters: (state, action: PayloadAction<DraftLinePartsFiltersType>) => {
      state.stockPartsToFillPartRequestFilters = action.payload;
    },
    setStockPartRequestTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.stockPartRequestTimelineFilters = action.payload;
    },
    setStockPartRequestTimeline: (state, action: PayloadAction<DataListType<StockPartRequestEventDtoBase[]>>) => {
      state.stockPartRequestTimeline = action.payload;
    },
    setGeneratePurchaseOrderFilters: (state, action: PayloadAction<PartReqLinesFilters>) => {
      state.generatePurchaseOrderFilters = action.payload;
    },
    setPartRequestLinesForPurchaseOrderGeneration: (
      state,
      action: PayloadAction<DataListType<PartRequestLineDto[], { allItemIds: number[] }>>,
    ) => {
      state.partRequestLinesForPurchaseOrderGeneration = action.payload;
    },
    setConfirmedPartRequestViewsParams: (state, action: PayloadAction<ConfirmedPartRequestViewsParams>) => {
      state.confirmedPartRequestViewsParams = action.payload;
    },
    setStockPartRequestsViewsConfirmed: (
      state,
      action: PayloadAction<DataListType<ConfirmedPartRequestDto[], { allItemIds: string[] }>>,
    ) => {
      state.stockPartRequestsViewsConfirmed = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getStockPartQuantitySources.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }>>,
    ) => {
      state.stockPartQuantitySources = action.payload?.data?.items || [];
    });
    builder.addCase(
      getAllocatePricelistPartQuantities.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartQuantitiesDataType> | null>) => {
        state.allocatedPartQuantities = action.payload?.data.partQuantities || initDataList;
      },
    );
    builder.addCase(
      getEditReadyCollectedPartQuantities.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartQuantitiesDataType> | null>) => {
        state.editReadyCollectedModalQuantities = action.payload?.data || null;
        state.editReadyCollectedModalAvailablePurchaseOrders = action.payload?.data.availablePurchaseOrders || null;
      },
    );
    builder.addCase(getStockPartRequestLinkedEntityTypes.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }>>,
    ) => {
      state.stockPartRequestLinkedEntitiesTypes = action.payload.data.items;
    });
    builder.addCase(getStockPartsRequestDashboard.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<StockPartRequestDashboardDataType>>,
    ) => {
      state.stockPartRequestDashboard = action.payload.data;
    });
    builder.addCase(getPartRequestByLineThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<
      PartRequestLineDto[],
      { allAvailableForPurchaseOrderGenerationIds: number[] }
      >>,
    ) => {
      state.stockPartRequestsLinesView = action.payload.data;
    });
    builder.addCase(getStockPartRequestById.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{
        partRequest: StockPartRequestDto, canPurchaseOrderBeGenerated: boolean, anyLineLinkedToPurchaseOrder: boolean,
      }>>,
    ) => {
      const canPurchaseOrderBeGenerated = action.payload?.data?.canPurchaseOrderBeGenerated;
      const anyLineLinkedToPurchaseOrder = action.payload?.data?.anyLineLinkedToPurchaseOrder;
      const data = action.payload?.data?.partRequest;
      state.stockPartRequestDetails = data ? { ...data, canPurchaseOrderBeGenerated, anyLineLinkedToPurchaseOrder } : null;
    });
    builder.addCase(getStockPartRequestLines.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<
      PartRequestLineDto[],
      { allItemIds: number[] }
      >>,
    ) => {
      state.stockPartRequestLines = action.payload?.data;
    });
    builder.addCase(getPartRequestLinesForPurchaseOrderGeneration.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<PartRequestLineDto[], { allItemIds: number[] }>>,
    ) => {
      state.partRequestLinesForPurchaseOrderGeneration = action.payload.data;
    });
    builder.addCase(
      getStockPartRequestLineProductSourceTotal.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<StockPartProductSourceTotal> | null>) => {
        state.stockPartProductSourceTotal = action.payload?.data || null;
      },
    );
    builder.addCase(
      getStockPartsToPartReqThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[]> | null>) => {
        const data = action.payload?.data;
        if (data) {
          state.stockPartsToFillPartRequest = data;
        } else {
          state.stockPartsToFillPartRequest = initDataList;
        }
      },
    );
    builder.addCase(
      getStockPartRequestTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<StockPartRequestEventDtoBase[]>>) => {
        state.stockPartRequestTimeline = action.payload.data;
      },
    );
    builder.addCase(
      getPartRequestsViewsConfirmed.fulfilled,
      (state, action: PayloadAction<ResponseResult<ConfirmedPartRequestDto[], { allItemIds: string[] }>>) => {
        state.stockPartRequestsViewsConfirmed = action.payload.data;
      },
    );
  },
});

export const {
  setStockPartRequestsFilters,
  setEditReadyCollectedModalFilters,
  setPartRequestDashboard,
  setStockPartRequestDetails,
  setStockPartRequestLines,
  setStockPartRequestLinesFilters,
  setStockPartProductSourceTotal,
  setAllocatedPartQuantities,
  setAllocatePartQuantitiesFilters,
  setEditReadyCollectedModalQuantities,
  setEditReadyCollectedModalAvailablePurchaseOrders,
  setStockPartsToFillPartRequest,
  setStockPartsToFillPartRequestFilters,
  setStockPartRequestTimelineFilters,
  setStockPartRequestTimeline,
  setGeneratePurchaseOrderFilters,
  setPartRequestLinesForPurchaseOrderGeneration,
  setConfirmedPartRequestViewsParams,
  setStockPartRequestsViewsConfirmed,
  setStockPartRequestsLinesViewFilters,
} = stockPartRequestSlice.actions;

export default stockPartRequestSlice.reducer;
