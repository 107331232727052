import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faInfoCircle, faListOl } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import { useAppSelector } from '../../../../store/hooks';
import { NumberFunctionType } from '../../../../core/types/coreTypes';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import Tr from '../../../../core/components/table-components/Tr';
import Tag from '../../../../core/components/tag/Tag';
import ViewPageLink from '../../../../common/components/view-page-link/ViewPageLink';
import { InvoiceOrderLineTableType, NominalCodesTooltip } from '../../types/invoicingCommonTypes';
import { TablePartNominalRecord } from '../../../invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import { toPoundCurrency } from '../../../../common/utils/formatUtils';
import { nominalRecordStatesValuesSelector } from '../../../../store/selectors/coreSelectors';
import EditableNoteCell from '../../../../stock/purchase-orders/view-page/components/content/parts-list/EditableNoteCell';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';

type OrderLineElementProps = {
  item: InvoiceOrderLineTableType,
  openNominalCodesModal: NumberFunctionType,
  isSales?: boolean,
  nominalCodesTooltips?: NominalCodesTooltip[],
  isAssignNominalCodesEnabled: boolean,
  isChild?: boolean,
  onPatchComplete?: NumberFunctionType,
}

const OrderLineElement: React.FC<OrderLineElementProps> = ({
  item, openNominalCodesModal, isSales, nominalCodesTooltips, isAssignNominalCodesEnabled, isChild, onPatchComplete,
}) => {
  const hiddenChildKeys = ['unitOfMeasure', 'id', 'partId', 'requestedQty', 'cancelledQty', 'creditNominalRecordsNet'];
  const hiddenParentKeys = [
    'unitOfMeasure', 'id', 'partId', 'requestedQty', 'cancelledQty', 'creditNominalRecordsNet', 'childLines',
  ];
  const hiddenKeys = isChild ? hiddenChildKeys : hiddenParentKeys;
  const sterlingKeys = ['receivedNet', 'net', 'invoicedPending', 'unassignedNet'];

  const allowedToEditNote = usePermission(PermissionEnum.InvoicingPurchaseViewPageWithInvoices);

  const { pending } = useAppSelector(nominalRecordStatesValuesSelector);

  const nominalCodesTooltipFragment = (id: number) => {
    const found = nominalCodesTooltips?.find((el) => el.id === id)?.nominalRecords;
    return found
      ? <>
        {Object.keys(found.draft).length > 0 && <ul>
          {Object.values(found.draft).map((el, i) => <li key={i}>
            <span>{`${el.title} (${el.status})`}</span>
            {el.nominalCodes.length > 0 && <ul>{el.nominalCodes.map((el, i) => <li key={i}>{el}</li>)}</ul>}
          </li>)}
        </ul>}
        {Object.keys(found.exported).length > 0 && <ul>
          {Object.values(found.exported).map((el, i) => <li key={i}>
            <span>{`${el.title} (${el.status})`}</span>
            {el.nominalCodes.length > 0 && <ul>{el.nominalCodes.map((el, i) => <li key={i}>{el}</li>)}</ul>}
          </li>)}
        </ul>}
        {found.pending?.length ? <ul>
          <span>Pending</span>
          {found.pending.map((el, i) => <li key={i}>{el}</li>)}
        </ul> : ''}
      </>
      : '';
  };

  const actions = (id: number) => [
    {
      label: 'Assign nominal codes',
      icon: <FontAwesomeIcon icon={faListOl} />,
      key: 'assignNominalCodes',
      onClick: () => openNominalCodesModal(id),
    },
  ];

  const renderValueHandle = (key: string, value: string | number | TablePartNominalRecord[] | null) => {
    if (key === 'partNumber') {
      return <ViewPageLink entityType="Part" entityId={item.partId}>
        <TruncateTooltip value={value as string} table />
      </ViewPageLink>;
    }
    if (key === 'partName') {
      return <TruncateTooltip value={value as string} table />;
    }
    if (key === 'notes') {
      return <EditableNoteCell
        value={value as string | null}
        allowedToManage={allowedToEditNote}
        partId={item.id}
        notes={item.notes}
        onPatchComplete={onPatchComplete}
      />;
    }
    if (sterlingKeys.includes(key)) {
      const isToBeInvoicedMoreThanZero = key === 'unassignedNet' && (value as number) > 0;

      const isInvoicedAndPendingGreaterThanNetReceived = isSales
        ? key === 'invoicedPending' && item.invoicedPending > (item.net || 0)
        : key === 'invoicedPending' && item.invoicedPending > (item.receivedNet || 0);
      const greaterAmountMessage = isSales
        ? 'Invoiced amount is greater than order amount.'
        : 'Invoiced amount is greater than received amount.';
      const isCreditNominalRecordsNet = item.creditNominalRecordsNet;
      const isInvoicedAndPendingGreaterThanNetReceivedTip = isCreditNominalRecordsNet
        ? `${greaterAmountMessage} Invoiced amount excludes credit notes for ${toPoundCurrency(Math.abs(isCreditNominalRecordsNet))}.`
        : greaterAmountMessage;

      return <div className={classNames('td-content-with-tooltip', { 'fw-bold': isToBeInvoicedMoreThanZero })}>
        <span>{toPoundCurrency(value as number)}</span>
        {isInvoicedAndPendingGreaterThanNetReceived && <Tooltip
          title={isInvoicedAndPendingGreaterThanNetReceivedTip}
          overlayClassName="card-tooltip-overlay"
        >
          <FontAwesomeIcon icon={faInfoCircle} />
        </Tooltip>}
      </div>;
    }
    if (key === 'receivedQuantity' || key === 'quantity') {
      const { requestedQty, cancelledQty } = item;
      const result = requestedQty - cancelledQty;
      return isSales
        ? `${value as number} ${item.unitOfMeasure}`
        : `${value as number} of ${result} ${item.unitOfMeasure}`;
    }
    if (key === 'nominalCodes') {
      const DISPLAY_COUNT = 3;
      const codes = value as TablePartNominalRecord[];
      const extraCodesCount = codes.length - DISPLAY_COUNT;

      return <Tooltip title={nominalCodesTooltipFragment(item.id)} overlayClassName="card-tooltip-overlay">
        <span className="invoicingOrderLinesTd--nominal-codes">
          {codes.slice(0, DISPLAY_COUNT).map((v, i) => <Tag key={i} label={v.nominalCode} light={v.state === pending} />)}
          {extraCodesCount > 0 && `+ ${extraCodesCount}`}
        </span>
      </Tooltip>;
    } else {
      return value as string | number;
    }
  };
  return <Tr>
    {Object.entries(item).map(([key, value]) => {
      if (hiddenKeys.includes(key)) return null;
      return <TdNew key={key} className={classNames({ 'nowrap': [...sterlingKeys, 'receivedQuantity', 'nominalCodes'].includes(key) })}>
        {renderValueHandle(key, value)}
      </TdNew>;
    })}
    {isAssignNominalCodesEnabled && <TdNew action>
      <Dropdown
        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
        menuItems={actions(item.id)}
      />
    </TdNew>}
  </Tr>;
};

export default OrderLineElement;
