import React, { useEffect, useState } from 'react';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import CreateEmployeeForm from './CreateEmployeeForm';
import { AddressFields, initAddressFields } from '../../../common/types/commonTypes';
import {
  getEmployeeDepartmentsThunk,
  getEmployeeGendersThunk,
  getEmployeeSalaryTypesThunk, getEmployeeSkillLevelsThunk,
} from '../../../store/thunks/employee/createEmployeeThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAccountPermissionRoles } from '../../../store/thunks/coreThunk';
import { internalAccountSelector } from '../../../store/selectors/coreSelectors';
import { setPermissionRoles } from '../../../store/slices/coreSlice';
import './CreateEmployee.scss';
import { getSkillsOrderingThunk } from '../../../store/thunks/core/coreOrderingThunks';

const CreateEmployee: React.FC = () => {
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState();
  const internalAccount = useAppSelector(internalAccountSelector);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [addressError, setAddressError] = useState<string>('');
  const [iceAddressFields, setIceAddressFields] = useState<AddressFields>(initAddressFields);
  const [iceAddressError, setIceAddressError] = useState<string>('');

  useEffect(() => {
    internalAccount && dispatch(getAccountPermissionRoles({ accountType: internalAccount }));
    // eslint-disable-next-line
  }, [internalAccount]);

  useEffect(() => {
    dispatch(getEmployeeGendersThunk());
    dispatch(getEmployeeSalaryTypesThunk());
    dispatch(getEmployeeSkillLevelsThunk());
    dispatch(getSkillsOrderingThunk());
    dispatch(getEmployeeDepartmentsThunk());
    return () => {
      setAddressFields(initAddressFields);
      setIceAddressFields(initAddressFields);
      dispatch(setPermissionRoles([]));
      // dispatch(serOrderDetails(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout subHeader={
      <DetailsHead goBack={() => backHandle()} isCreatePage>
        <div className="createHead">Create new employee</div>
      </DetailsHead>
    }
    >
      <CreateEmployeeForm
        addressFields={addressFields}
        setAddressFields={setAddressFields}
        iceAddressFields={iceAddressFields}
        setIceAddressFields={setIceAddressFields}
        addressError={addressError}
        setAddressError={setAddressError}
        iceAddressError={iceAddressError}
        setIceAddressError={setIceAddressError}
      />
    </Layout>
  );
};

export default CreateEmployee;
