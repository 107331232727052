import { maxCommonDecimal, maxDiscount } from '../../../core/utils/regex';
import { RangeTableFiltersType } from '../../../core/types/coreTypes';

export const initQuoteLinesFilterRanges: RangeTableFiltersType = {
  quantity: ['0.00', maxCommonDecimal.toString()],
  unitPrice: ['0.00', maxCommonDecimal.toString()],
  netPrice: ['0.00', '0.00'],
  weight: ['0.00', maxCommonDecimal.toString()],
  discount: ['0.00', maxDiscount.toString()],
};

export const missingQuoteLinesColumns = [
  {
    title: 'Entity',
    dataIndex: 'entity',
  },
  {
    title: 'Product No',
    dataIndex: 'productNumber',
  },
  {
    title: 'Product name',
    dataIndex: 'productName',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
  },
];
