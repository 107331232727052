import React from 'react';
import './PurchaseOrdersSkeletonItem.scss';

const PurchaseOrdersSkeletonItem: React.FC = () => {
  return (
    <div className="purchaseOrdersSkeletonItem skeleton-item">
      <div className="purchaseOrdersSkeletonItem__title" />
      <div className="purchaseOrdersSkeletonItem__data" />
      <div className="purchaseOrdersSkeletonItem__data" />
      <div className="purchaseOrdersSkeletonItem__tag skeleton-line" />
      <div className="purchaseOrdersSkeletonItem__count" />
    </div>
  );
};

export default PurchaseOrdersSkeletonItem;
