import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum VehiclesViewsEnum {
  DASHBOARD = 'Dashboard',
  VEHICLE_PROFILES = 'Vehicle profiles',
  CREATE_NEW_VEHICLE = 'Create new vehicle',
}

export enum VehiclesPageParamsEnum {
  DASHBOARD = 'dashboard',
  VEHICLE_PROFILES = 'vehicle-profiles',
  CREATE_NEW_VEHICLE = 'create-new-vehicle',
}

export const vehiclePageParams = [
  { page: VehiclesPageParamsEnum.DASHBOARD, permission: PermissionEnum.VehicleDashboard },
  { page: VehiclesPageParamsEnum.VEHICLE_PROFILES, permission: PermissionEnum.VehicleProfiles },
  { page: VehiclesPageParamsEnum.CREATE_NEW_VEHICLE, permission: PermissionEnum.VehicleCreate },
];

export enum VehiclesDashboardStatusesEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}
