import { PartDtoType } from '../../search/types/stockSearchTypes';
import {
  AssigneeDto, DataListType, initPaging, OrderingType, PagingType, PagingWithOrdering,
} from '../../../core/types/coreTypes';
import { ActivityEventBasicFieldsType, EntityType, SuitableForVehicleChangeEventData } from '../../../common/types/commonTypes';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

type PartImage = {
  id: number,
  entityId: number,
  name: string | null,
  sizeBytes: number,
  uploadDate: string,
  comment: string | null,
  type: number,
}

export type PartQuantityDto = {
  costPrice: number,
  sellPrice: number | null,
  quantity: number,
  priceSource: string | null,
  addedDate: string,
}

export type PricelistDto = {
  costPrice: number,
  sellingPrice: number,
  name: string | null,
}

export type PartPricesDto = {
  stockPrices: PartQuantityDto[],
  dueInPrices: PartQuantityDto[],
  pricelists: PricelistDto[],
}

export type GetPartResponse = {
  part: PartDtoType,
  images: PartImage[],
  prices: PartPricesDto,
  attachmentsCount: number,
  purchaseOrdersCount: number,
  lessThenCriticalLevel: boolean | null,
  lessThenWarningLevel: boolean | null,
}

export type PartReorderLevels = {
  warningLevel: number | null,
  criticalLevel: number | null,
}

export type PartViewFields = {
  name: string,
  partCode: string,
  manufacturerCode: string,
  type: number | null,
  isCorePart: boolean,
  description: string,
  businessAreas: number[],
  unitOfMeasure: number | null,
  weight: string,
  categoryId: number | null,
  subcategoryId: number | null,
}

export const formInitialValues: PartViewFields = {
  name: '',
  partCode: '',
  manufacturerCode: '',
  type: null,
  isCorePart: false,
  description: '',
  businessAreas: [],
  unitOfMeasure: null,
  weight: '',
  categoryId: null,
  subcategoryId: null,
};

export const PartDescriptionSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};

export type PartAffectedEntitiesFilters = PagingType & { businessAreas?: number[] };

export type PartAffectedEntityDto = {
  entityId: number,
  entityType: EntityType,
  entityNumber: string,
  createdAt: string,
  entityStatus: string | null,
  businessAreas: number[],
  partLinesCount: number,
}

export type PartQtyBaseModelType = {
  costPrice: number,
  sellingPrice: number,
  quantity: number,
  addedDate: string,
  status: number,
}

export type PatchPartQtyType = {
  quantity?: number | null,
  status?: number,
  costPrice?: number | null,
  sellingPrice?: number | null,
  addedDate?: string | null,
  ownerId?: number | null,
  orderId?: number | null,
  locationComment?: string | null,
}

export type PartQtyType = {
  partId: number,
  id: number,
  partRequestLineId: number | null,
  costPrice: number,
  sellingPrice: number | null,
  quantity: number,
  addedDate: string,
  status: number,
  purchaseOrderId: number | null,
  purchaseOrderPartId: number | null,
  purchaseOrderNumber: string | null,
  owner: AssigneeDto,
  collectionDate: string | null,
  locationComment: string | null,
  source: number,
  orderId: number | null,
  orderNumber: string | null,
}

export type GetPartQuantitiesDataType = {
  partId: number,
  partQuantities: DataListType<PartQtyType[] | null>,
  availablePurchaseOrders: Array<{ purchaseOrderId: number | null, purchaseOrderNumber: string | null }> | null,
  statusQuantities: Array<{ status: number, quantity: number }> | null,
}

export type EditReadyCollectedUiTableData = {
  id: number,
  number: number,
  priceSource: string,
  quantity: number,
  locationComment?: string,
  costPrice: string,
  addedDate?: string,
  owner?: string,
  collectionDate?: string,
  purchaseOrderNumber?: string,
}

export const initPartPurchaseOrdersFilters = {
  ...initPaging,
  isActive: true,
};

export type AdjustQtyFiltersType = {
  [P in keyof PagingType]: PagingType[P]
} & {
  order?: OrderingType,
  hasSellingPrice?: boolean,
  linkedTo?: string,
}

export type PartQtyEventType = {
  addedDate: string,
  collectionDate: string | null,
  costPrice: number,
  id: number,
  locationComment: string | null,
  owner: string | null,
  ownerId: number | null,
  partId: number | null,
  purchaseOrderId: number | null,
  purchaseOrderNumber: string | null,
  quantity: number,
  sellPrice: number,
  source: number | null,
  status: number,
  linkedTo?: string,
}

export type PartViewEventDtoBase = ActivityEventBasicFieldsType & {
  partEventType: number,
  partCode: string | null,
  id?: number,
  status?: number,
  addedDate?: string,
  partQuantity: PartQtyEventType,
} & SuitableForVehicleChangeEventData;

export type GetPartQuantitiesParams = {
  partId?: number, // REQUIRED
  statuses?: number[],
  hasSellingPrice?: boolean,
  purchaseOrderIds?: Array<number | string | null>,
  ownerIds?: number[],
  partRequestLineId?: number,
  isAvailableForPartRequestId?: number,
  linkedTo?: string,
} & PagingWithOrdering
