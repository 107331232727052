import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { salesDashboardFiltersSelector, salesDashboardSelector } from '../../../store/selectors/invoicingSelectors';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { PagingType } from '../../../core/types/coreTypes';
import { setSalesDashboardFilters } from '../../../store/slices/invoicingSlice';
import Pagination from '../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import InvoicingSalesKeywordFilters from './InvoicingSalesKeywordFilters';
import InvoicingSalesList from './InvoicingSalesList';
import { getSalesOrdersList } from '../../../store/thunks/invoicing/salesThunks';
import { orderListOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { setDashboardLoading } from '../../../store/slices/coreSlice';

const InvoicingSalesDashboard: React.FC = () => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector(salesDashboardSelector);
  const filters = useAppSelector(salesDashboardFiltersSelector);
  const { page, pageSize } = filters;

  const { controller, cancelRequest } = useCancelRequest();
  const orderListOrdering = useAppSelector(orderListOrderingSelector);

  useEffect(() => {
    if (!filters.order && orderListOrdering.length) {
      const creationDateOrder = orderListOrdering.find((el) => el.label === 'CreationDate')?.value;
      creationDateOrder && dispatch(setSalesDashboardFilters({ ...filters, order: { isAsc: true, field: creationDateOrder } }));
    }
    // eslint-disable-next-line
  }, [orderListOrdering.length, filters.order]);

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  const pageChangeHandle = (v: PagingType) => {
    if (filters.order) {
      const newFilters = { ...filters, ...v };
      dispatch(setSalesDashboardFilters(newFilters));
      cancelRequest();
      dispatch(getSalesOrdersList({
        filters: newFilters,
        signal: controller.current?.signal,
      }));
    }
  };

  return (
    <>
      <InvoicingSalesKeywordFilters />
      <div className="actions-and-paging">
        <Pagination
          currentPage={page}
          pageSize={pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => pageChangeHandle({ page, pageSize })}
          showTotal
          showSizeChanger
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      <InvoicingSalesList />
    </>
  );
};

export default InvoicingSalesDashboard;
