import React from 'react';
import { JobEventsEnum } from '../../../../enums/JobEventsEnum';
import { JobEventDtoBase } from '../../../../types/jobViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { jobQualityCheckStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';

type JobQualityCheckEventProps = {
  event: JobEventsEnum,
  item: JobEventDtoBase,
}

const JobQualityCheckEvent: React.FC<JobQualityCheckEventProps> = ({ event, item }) => {
  const jobQualityChecksStatuses = useAppSelector(jobQualityCheckStatusesSelector);
  const renderQualityCheckEventFn = () => {
    switch (event) {
      case JobEventsEnum.JobQualityCheckCreation:
        return (
          <>
            Quality check was saved with
            {' '}
            <b>{labelHandle(item.status, jobQualityChecksStatuses)}</b>
            {' '}
            result
          </>
        );
      case JobEventsEnum.JobQualityCheckDeletion:
        return (
          <>
            Quality check with
            {' '}
            <b>{localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.checkDate)}</b>
            {' '}
            creation date and
            {' '}
            <b>{labelHandle(item.status, jobQualityChecksStatuses)}</b>
            {' '}
            result was deleted.
          </>
        );
      default: return null;
    }
  };
  return (
    <>{renderQualityCheckEventFn()}</>
  );
};

export default JobQualityCheckEvent;
