import React, { useEffect, useRef, useState } from 'react';
import { Divider, Tree } from 'antd';
import { DataNode } from 'antd/lib/tree';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import { useOnClickOutside } from '../../hooks/useOutsideClick';

type SelectableTreeProps = {
  label: string;
  treeData: DataNode[],
  value: number[] | undefined,
  onChange: (checkedKeys: React.Key[]) => void
}

const SelectableTree: React.FC<SelectableTreeProps> = ({
  label,
  treeData,
  value = [],
  onChange,
}) => {
  const [buttonLabel, setButtonLabel] = useState<string | React.ReactNode>(label);
  const [isVisible, setIsVisible] = useState(false);
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const ref = useRef(null);

  const firstLevelKeys = treeData.map(({ key }) => key);

  useOnClickOutside(ref, () => setIsVisible(false));

  useEffect(() => {
    if (value?.length) {
      const allChildrenArr = treeData.map((el) => el.children)
        .flat(1);
      const firstSelectedName = allChildrenArr.find((el) => el?.key === value[0])?.title;
      const length = value.length === 1 ? '' : `+${value.length - 1}`;
      const labelNode = <span className="selectableDropdown__selected-value">
        <span>{firstSelectedName as React.ReactNode}</span>
        <span>{length}</span>
      </span>;
      setButtonLabel(labelNode);
    } else {
      setButtonLabel(label);
    }
  }, [label, treeData, value]);
  return (
    <div className="selectableDropdown">
      <Button
        label={buttonLabel}
        icon={<FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: 12 }} />}
        className={classNames('dropdownButton', {
          'dropdownButton--selected': value && value.length,
        })}
        onClick={() => setIsVisible(true)}
        disableDefaultStyles
      />
      {isVisible && <div
        ref={ref}
        className="dropdownList dropdownList--tree"
      >
        <div className="dropdownList__content-wrap">
          <div className="dropdownList__options">
            <Tree
              treeData={treeData}
              checkedKeys={value}
              onCheck={(checked) => onChange(checked as number[])}
              expandedKeys={expandedKeys}
              onExpand={(category) => setExpandedKeys(category)}
              checkable
              selectable={false}
            />
          </div>
          <Divider />
          <div className="dropdownList__footer">
            <Button
              label={firstLevelKeys.length === expandedKeys.length ? 'Collapse all' : 'Expand all'}
              onClick={() => {
                firstLevelKeys.length === expandedKeys.length ? setExpandedKeys([]) : setExpandedKeys(firstLevelKeys);
              }}
              type="text"
            />
            <Button
              icon={<FontAwesomeIcon icon={faTimes} />}
              label="Clear"
              onClick={() => {
                value.length > 0 && onChange([]);
              }}
              type="text"
            />
          </div>
        </div>
      </div>}
    </div>
  );
};

export default SelectableTree;
