import React from 'react';
import './InvoicingSkeletonItem.scss';

const InvoicingSkeletonItem: React.FC = () => {
  return (
    <div className="invoicingSkeletonItem skeleton-item">
      <div className="invoicingSkeletonItem__icon" />
      <div className="invoicingSkeletonItem__info">
        <div className="invoicingSkeletonItem__data" />
        <div className="invoicingSkeletonItem__data" />
      </div>
      <div className="invoicingSkeletonItem__price">
        <div className="invoicingSkeletonItem__data" />
        <div className="invoicingSkeletonItem__data" />
        <div className="invoicingSkeletonItem__data" />
      </div>
      <div className="invoicingSkeletonItem__entities">
        <div className="invoicingSkeletonItem__data" />
        <div className="invoicingSkeletonItem__data" />
      </div>
      <div className="invoicingSkeletonItem__tag-wrapper">
        <div className="invoicingSkeletonItem__tag skeleton-line" />
      </div>
    </div>
  );
};

export default InvoicingSkeletonItem;
