import { useLocation, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { locationHistorySelector } from '../../store/selectors/coreSelectors';
import { setLocationHistory } from '../../store/slices/coreSlice';
import { LocationStateType } from '../types/coreTypes';
import { routesPath } from '../enums/pathEnum';

export const useLocationHistoryState = (viewPage?: boolean) => {
  const locationHistory = useAppSelector(locationHistorySelector);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const setLocationHistoryHandle = (newLocation: LocationStateType | null) => {
    dispatch(setLocationHistory(newLocation));
  };

  const navigateToParentPage = () => {
    const splittedPath = location.pathname.split('/');
    navigate(`/${splittedPath[1]}`, { replace: true });
  };

  const backHandle = (url?: string) => {
    if (locationHistory) {
      const { pathname, search } = locationHistory;
      const originLocationPath = location.pathname;
      const originLocationSearch = location.search;
      if (pathname === originLocationPath && search === originLocationSearch) {
        url ? navigate(`${url}`) : navigateToParentPage();
      } else {
        navigate(`${pathname}${search}`, { replace: true });
      }
    } else {
      if (viewPage) {
        navigateToParentPage();
      } else {
        if (url) {
          navigate(`/${url}`);
        } else {
          navigate(`/${routesPath.HOME}`);
        }
      }
    }
  };

  return {
    setLocationHistoryHandle,
    backHandle,
  };
};
