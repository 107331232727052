import React, { useEffect } from 'react';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  salesDashboardFiltersSelector,
} from '../../../store/selectors/invoicingSelectors';
import { SalesAllStatusEnum } from '../enums/salesEnums';
import { setSalesDashboardFilters } from '../../../store/slices/invoicingSlice';
import {
  StockTabParamEnum,
} from '../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import KeywordFiltersSection from '../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useSalesDashboardKeywordFilters } from '../hooks/useSalesDashboardKeywordFilters';
import { orderStatusesValuesSelector, orderStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

const InvoicingSalesKeywordFilters: React.FC = () => {
  const pageAllowed = usePermission(PermissionEnum.InvoicingPurchases);
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(salesDashboardFiltersSelector);
  const orderStatus = useAppSelector(orderStatusSelector);
  const {
    postCheckPassed, acceptedByCustomer, invoicing,
  } = useAppSelector(orderStatusesValuesSelector);

  const {
    extraFilters,
    dateFilters,
    tab,
    status,
    archivedStatus,
    setStatusHandle,
    setDefaultStatusParam,
    finalStatusOptions,
    searchValue,
    setSearchValue,
    getFilteredList,
    pickerValue,
    setPickerValue,
    pickerHandle,
  } = useSalesDashboardKeywordFilters();

  useEffect(() => {
    if (!status) {
      if (tab) {
        setDefaultStatusParam();
      }
    }
    // eslint-disable-next-line
  }, [tab, status, filters.statuses, archivedStatus]);

  useEffect(() => {
    if (pageAllowed) {
      if (status) {
        if (filters.statuses?.length) {
          getFilteredList(filters);
        } else {
          const newStatus = +status === SalesAllStatusEnum.ALL
            ? [postCheckPassed as number, acceptedByCustomer as number, invoicing as number]
            : [+status];
          const newFilters = {
            ...filters,
            statuses: newStatus,
            isActive: tab ? tab === StockTabParamEnum.ACTIVE : true,
          };
          if (newStatus.every((el) => el)) {
            getFilteredList(newFilters);
            dispatch(setSalesDashboardFilters(newFilters));
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [pageAllowed, orderStatus.length, status, tab, filters.statuses, filters.page, filters.pageSize]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue && searchValue !== filters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredList({ ...filters, keyword: searchValue.trim(), page: 1 });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!searchValue?.trim() && filters.keyword) {
        getFilteredList({ ...filters, keyword: undefined, page: 1 });
      }
    }
    // eslint-disable-next-line
  }, [searchValue, filters.keyword]);

  return (
    <div className="sections-and-filters sections-and-filters--offset">
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
        ? <Selectable
          options={finalStatusOptions}
          value={status ? +status : ''}
          onChange={(v) => setStatusHandle(+v)}
        />
        : <Segmented
          options={finalStatusOptions}
          value={status ? +status : ''}
          onChange={(v) => setStatusHandle(v)}
          className="purchaseOrderFilters__segmented"
        />}
      <KeywordFiltersSection
        searchValue={searchValue}
        setSearchValue={(v) => setSearchValue(v)}
        filters={filters.isActive ? extraFilters : undefined}
        dateFilters={dateFilters}
        getFilteredResHandle={(values, field) => {
          getFilteredList({
            ...filters,
            page: 1,
            [field]: values,
          });
        }}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        onClear={() => {
          getFilteredList({
            ...filters,
            page: 1,
            hasDraftInvoiceDocuments: undefined,
            keyword: undefined,
            createdDate: undefined,
          });
          setPickerValue({ createdDate: [null, null] });
        }}
        className="invoicingPurchasesFilters"
      />
    </div>
  );
};

export default InvoicingSalesKeywordFilters;
