import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Modal from '../../../../core/components/modal/Modal';
import { CustomAny, VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Select from '../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { labelHandle } from '../../../../core/utils/labelHandle';
import { partSubcategoriesFilteredLookupSelector } from '../../../../store/selectors/sharedSelectors';
import './CategoryModal.scss';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { getPartSubcategoriesFilteredThunk } from '../../../../store/thunks/shared/sharedThunks';

type CategoryModalProps = {
  title: string,
  visible: boolean,
  onCancel: VoidFunctionType,
  editHandle: (categoryId: number, subCategoryId: number) => void,
  categoryId: number | null,
  subcategoryId: number | null,
  categories: Array<{label: string, value: number}>
}

const CategoryModal:React.FC<CategoryModalProps> = ({
  title, visible, onCancel, editHandle, categoryId, subcategoryId, categories,
}) => {
  const dispatch = useAppDispatch();
  const subcategories = useAppSelector(partSubcategoriesFilteredLookupSelector);
  const [isLoading, setIsLoading] = useState(false);

  const {
    control, handleSubmit, reset, setValue, watch, clearErrors,
  } = useForm<CustomAny>({
    defaultValues: {
      categoryId: null,
      subcategoryId: null,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const category = watch('categoryId');

  useEffect(() => {
    if (visible) {
      setValue('categoryId', categoryId);
      setValue('subcategoryId', subcategoryId);
    }
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if (category) {
      setIsLoading(true);
      setValue('subcategoryId', null);
      dispatch(getPartSubcategoriesFilteredThunk({
        filters: {
          categoryIds: [category],
        },
      }));
    }
    // eslint-disable-next-line
  }, [category]);

  useEffect(() => {
    setIsLoading(false);
    if (visible && subcategories.length > 0) {
      setValue('subcategoryId', subcategories[0].value);
      clearErrors('subcategoryId');
    }
    // eslint-disable-next-line
  }, [subcategories]);

  const onSubmit = (values:CustomAny) => {
    editHandle(values.categoryId, values.subcategoryId);
  };

  const cancelHandle = () => {
    reset();
    onCancel();
  };
  return (
    <Modal visible={visible} title={title} onCancel={cancelHandle} className="categoryModal" destroyOnClose>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="categoryModal__content">
          <Controller
            name="categoryId"
            control={control}
            rules={{ required: ErrorsEnum.REQUIRED }}
            render={({ field }) => (
              <Select
                label="Category"
                options={categories}
                value={labelHandle(field.value, categories)}
                onChange={(v) => field.onChange(v)}
                showSearch
                isFilterOption
                error={errors?.categoryId?.message}
                parentRender
              />
            )}
          />
          <Controller
            name="subcategoryId"
            control={control}
            rules={{ required: ErrorsEnum.REQUIRED }}
            render={({ field }) => (
              <Select
                label="Subcategory"
                options={subcategories}
                value={labelHandle(field.value, subcategories)}
                onChange={(v) => field.onChange(v)}
                disabled={isLoading || !category}
                showSearch
                isFilterOption
                error={errors?.subcategoryId?.message}
                parentRender
              />
            )}
          />
        </div>
        <ButtonActions
          createLabel="Save"
          cancelLabel="Cancel"
          createType="submit"
          cancelClick={cancelHandle}
          disabledCreate={isLoading}
        />
      </form>
    </Modal>
  );
};

export default CategoryModal;
