import React, { useMemo } from 'react';
import Tr from '../../../core/components/table-components/Tr';
import { DraftLinePartsKitFiltersType, DraftLinePartsKitList } from '../../types/commonTypes';
import { getNo } from '../../../core/utils/entityNumberingHandle';
import Button from '../../../core/components/button/Button';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';

type PartsKitModeCellsProps = {
  partsKitParts?: DraftLinePartsKitList,
  partsKitFilters?: DraftLinePartsKitFiltersType,
  createPartsKitLine: (partsKitId: number) => void,
}

const PartsKitModeCells: React.FC<PartsKitModeCellsProps> = ({
  partsKitParts, partsKitFilters, createPartsKitLine,
}) => {
  const sortedList = useMemo(() => partsKitParts?.items.map((el, i) => ({
    number: partsKitFilters ? getNo(partsKitFilters?.page, partsKitFilters?.pageSize, i) : (i + 1),
    id: el.id,
    partsKitCode: el.partsKitCode,
    name: el.name,
    partsCount: el.itemsCount,
    description: el.description,
  })), [partsKitFilters, partsKitParts?.items]);

  const selectHandle = (partKitId: number) => {
    createPartsKitLine(partKitId);
  };

  return (
    <>
      {sortedList?.map((part, i) => <Tr key={i}>
        {Object.entries(part).map(([key, value]) => {
          if (key === 'id') return null;
          return <TdNew key={key}>
            {key === 'number'
              ? value
              : <TruncateTooltip value={value || '-'} table />}
          </TdNew>;
        })}
        <TdNew>
          <Button
            label="Select"
            onClick={() => selectHandle(part.id)}
            type="text"
          />
        </TdNew>
      </Tr>)}
    </>
  );
};

export default PartsKitModeCells;
