import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  pricelistAffectedFiltersSelector,
  pricelistAffectedPartsFiltersSelector,
  pricelistDetailsSelector,
} from '../../../../../store/selectors/pricelistSelectors';
import {
  getPricelistAffectedPartsThunk,
  getPricelistAffectedThunk,
  patchPricelistById,
} from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import { useCustomerPricelistLookup } from '../../../hooks/useCustomerPricelistLookup';
import { getCustomers } from '../../../../../store/thunks/customers/customersDashboardThunks';
import PricelistDetailsForm from './PricelistDetailsForm';
import { BooleanFunctionType, initSmallPaging } from '../../../../../core/types/coreTypes';
import { setPricelistAffectedFilters, setPricelistAffectedPartsFilters } from '../../../../../store/slices/pricelistSlice';
import { pricelistDefaults, PricelistFields } from '../../../../create-pricelist/utils/PricelistSchema';

type PricelistDetailsSectionProps = {
  setAffected: BooleanFunctionType,
  setAffectedParts: BooleanFunctionType,
}

const PricelistDetailsSection: React.FC<PricelistDetailsSectionProps> = ({ setAffected, setAffectedParts }) => {
  const dispatch = useAppDispatch();
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const pricelistAffectedFilters = useAppSelector(pricelistAffectedFiltersSelector);
  const pricelistAffectedPartsFilters = useAppSelector(pricelistAffectedPartsFiltersSelector);

  const { searchValue } = useCustomerPricelistLookup();

  const {
    control, setValue, setError, clearErrors,
  } = useForm<PricelistFields>({
    defaultValues: pricelistDefaults,
    mode: 'all',
  });

  const checkIfAffected = (dataToAffect: {
    customerIdsToRemove?: number[],
    customerIds?: number[],
    businessAreasToRemove?: number[]
    businessAreas?: number[],
  }) => {
    const id = pricelistDetails?.id;
    const {
      customerIdsToRemove, customerIds, businessAreasToRemove, businessAreas,
    } = dataToAffect;
    if (customerIdsToRemove || customerIds) {
      const filters = { ...pricelistAffectedFilters, customerIds: customerIdsToRemove };
      dispatch(setPricelistAffectedFilters(filters));
      id && dispatch(getPricelistAffectedThunk({
        pricelistId: id,
        filters,
        complexAction: () => {
          setAffected(true);
          setValue('customerIds', pricelistDetails.customerIds || []);
        },
        confirmAction: () => {
          dispatch(setPricelistAffectedFilters(initSmallPaging));
          dispatch(patchPricelistById({
            id,
            data: { customerIds },
          }));
        },
      }));
    } else if ((businessAreasToRemove || businessAreas) && id) {
      const filters = {
        ...pricelistAffectedPartsFilters, pricelistId: id, businessAreas: businessAreasToRemove, exceptBusinessAreas: businessAreas,
      };
      dispatch(setPricelistAffectedPartsFilters(filters));
      dispatch(getPricelistAffectedPartsThunk({
        filters,
        complexAction: () => {
          setAffectedParts(true);
          setValue('businessAreas', pricelistDetails.businessAreas || []);
        },
        confirmAction: () => {
          dispatch(setPricelistAffectedPartsFilters(initSmallPaging));
          dispatch(patchPricelistById({
            id,
            data: { businessAreas },
          }));
        },
      }));
    }
  };

  useEffect(() => {
    if (pricelistDetails) {
      setValue('name', pricelistDetails.name || '');
      setValue('businessAreas', pricelistDetails.businessAreas || []);
      setValue('customerIds', pricelistDetails.customerIds || []);
      const supplierV = pricelistDetails.isSupplierDeleted ? 'Unknown supplier' : pricelistDetails.supplierId;
      setValue('supplierId', supplierV || null);
      setValue('type', pricelistDetails.type || null);
      setValue('description', pricelistDetails.description || '');
    }
    // eslint-disable-next-line
  }, [pricelistDetails]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        dispatch(getCustomers({ params: { keyword: searchValue.trim() } }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getCustomers({ params: { keyword: undefined } }));
    }
    // eslint-disable-next-line
  }, [searchValue]);
  return (
    <PricelistDetailsForm
      control={control}
      clearErrors={clearErrors}
      setValue={setValue}
      setError={setError}
      checkIfAffected={checkIfAffected}
    />
  );
};

export default PricelistDetailsSection;
