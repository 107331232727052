import React from 'react';
import classNames from 'classnames';
import { JobCalendarTaskDto } from '../types/jobCalendarTypes';
import { useAppSelector } from '../../../store/hooks';
import { jobStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { jobCategoriesSelector } from '../../../store/selectors/coreSelectors';
import { setValueInHours } from '../../../core/utils/setValueInHours';

type JobLineTooltipContentProps = {
  info: JobCalendarTaskDto | null,
  multipleModeText?: string,
}

const NA = 'N/A';

const JobLineTooltipContent: React.FC<JobLineTooltipContentProps> = ({ info, multipleModeText }) => {
  const jobStatuses = useAppSelector(jobStatusesSelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const assigneesCount = info?.assigneesCount || 0;
  const isMoreThanOneAssignee = assigneesCount > 1;
  const correctElStatus = isMoreThanOneAssignee ? info?.parentStatus : info?.childStatus;
  const jobStatusLabel = jobStatuses.find((st) => correctElStatus === st.value)?.label;
  const assigneeStatusLabel = jobStatuses.find((st) => st.value === info?.childStatus)?.label;
  const isEstimateWasExceeded = (info?.estimatedMinutes || 0) < (info?.spentMinutesByAllAssignees || 0);

  const infoProperties = [
    { label: 'Job No: ', value: `${info?.jobNumber} ${isMoreThanOneAssignee ? `(${assigneesCount} assignees)` : ''}` || NA },
    { label: 'Job status: ', value: jobStatusLabel || NA },
    { label: 'Assignee status: ', value: assigneeStatusLabel || NA, isHidden: !isMoreThanOneAssignee },
    // eslint-disable-next-line max-len
    { label: 'Spent (current assignee): ', value: `${setValueInHours(info?.spentMinutesByCurrentUser)} h`, isHidden: !isMoreThanOneAssignee },
    { label: 'Spent (all assignees): ', value: `${setValueInHours(info?.spentMinutesByAllAssignees)} h` },
    // can be highlighted in red, if value < spent
    { label: 'Estimated: ', value: `${setValueInHours(info?.estimatedMinutes)} h`, isHighlighted: isEstimateWasExceeded },
    {
      label: 'Job category: ',
      value: info?.jobCategories
        .map((category) => jobCategories.find((el) => category === el.value)?.label || NA)
        .join(', '),
    },
    { label: 'Customer: ', value: info?.customerName || NA },
    { label: 'Linked to: ', value: info?.linkedNumber || NA },
  ];
  return <div className="jobTipInfo">
    <span className="jobTipInfo__title">
      Job info
      {multipleModeText}
    </span>
    <ul className="jobTipInfo__list">
      {infoProperties
        .filter((el) => !el.isHidden)
        .map((el) => <li key={el.label} className="jobTipInfo__item">
          <span className="jobTipInfo__label">{el.label}</span>
          <span className={classNames('jobTipInfo__value', { 'jobTipInfo__value--highlighted': !!el.isHighlighted })}>
            {el.value}
          </span>
        </li>)}
    </ul>
  </div>;
};

export default JobLineTooltipContent;
