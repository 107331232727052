import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse,
  DictionaryItem,
  emptyPaging,
  initSmallPaging,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../../core/types/coreTypes';
import {
  setDashboardLoading,
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import {
  AllocatePartQuantityFilters,
  AllocatePricelistQuantityDto,
  ConfirmedPartRequestDto,
  ConfirmedPartRequestViewsParams,
  GetPartRequestDashboardResponse,
  PartReqPartQuantityFilters,
  PartRequestByLineFilters,
  PutPartRequestLineStatusRestModel,
  StockPartProductSourceTotal,
  StockPartRequestFiltersType,
} from '../../../../stock/stock-part-requests/types/stockPartRequestTypes';
import { RootState } from '../../../store';
import { GetPartQuantitiesDataType, PatchPartQtyType } from '../../../../stock/part-view-page/types/types';
import {
  setEditReadyCollectedModalFilters,
  setStockPartRequestTimeline,
  setStockPartRequestTimelineFilters,
} from '../../../slices/stockPartRequestSlice';
import { PartDtoType, StockFiltersType } from '../../../../stock/search/types/stockSearchTypes';
import {
  ExtendedTimelineFilters,
  PartReqLinesFilters, PartRequestLineChangeGroupLineDto, PartRequestLineDto,
} from '../../../../common/types/commonTypes';
import {
  GetStockPartRequestResponse,
  StockPartRequestEventDtoBase,
} from '../../../../stock/stock-part-request-view/types/StockPartrequestViewTypes';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import { routesPath } from '../../../../core/enums/pathEnum';
import { CreatePurchaseOrderResponseType } from '../../../../stock/purchase-orders/create/types/createPurchaseOrderTypes';
import { ErrorCodesEnum } from '../../../../common/enums/ErrorCodesEnum';
import { UserEventPartRequest } from '../../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../../slices/settingsSlice';

export const getStockPartRequestLinkedEntityTypes = createAsyncThunk<ResponseSingleResult<{ items: DictionaryItem[] }>>(
  'get/StockPartRequestLinkedEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockPartRequestLinkedEntityType();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsRequestDashboard = createAsyncThunk<GetPartRequestDashboardResponse, {
  filters: StockPartRequestFiltersType, signal?: AbortSignal,
}>(
  'get/StockPartsRequestDashboard',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const res = await stockApi.fetchStockPartsRequestDashboard(filters, signal);
      dispatch(setDashboardLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestById = createAsyncThunk<ResponseSingleResult<GetStockPartRequestResponse>, { id: number }>(
  'get/StockPartsRequestById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockPartRequestById(id);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartQuantitySources = createAsyncThunk<ResponseSingleResult<{ items: DictionaryItem[] }>>(
  'get/StockPartRequestQuantitySources',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockPartQuantitySources();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestLines = createAsyncThunk<ResponseResult<
PartRequestLineDto[], { allItemIds: number[] }
>, {
  partRequestId?: number, filters?: PartReqLinesFilters, signal?: AbortSignal,
}>(
  'get/StockPartRequestLines',
  async ({ partRequestId, filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLines(
        {
          ...filters,
          partRequestIds: partRequestId ? [partRequestId] : undefined,
        },
        true,
        signal,
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestByLineThunk = createAsyncThunk<ResponseResult<
PartRequestLineDto[],
{ allAvailableForPurchaseOrderGenerationIds: number[] }
>,
{ filters: PartRequestByLineFilters, signal?: AbortSignal }
>(
  'get/PartRequestByLineThunk',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestByLine(filters, signal);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLinesForPurchaseOrderGeneration = createAsyncThunk<
ResponseResult<PartRequestLineDto[], { allItemIds: number[] }
>, {
  partRequestId: number, filters?: PartReqLinesFilters,
}>(
  'get/getPartRequestLinesForPurchaseOrderGeneration',
  async ({ partRequestId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLines({ ...filters, partRequestIds: [partRequestId] }, true);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const putStockPartRequestLineThunk = createAsyncThunk<ResponseSingleResult, {
  partRequestId: number,
  lineId: number,
  data: PutPartRequestLineStatusRestModel,
  closeModal: VoidFunctionType,
  customMessage?: string,
  isLinesView?: boolean,
}>(
  'put/StockPartRequestLine',
  async ({
    partRequestId, lineId, data, closeModal, customMessage, isLinesView,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { stockPartRequest: { stockPartRequestLinesFilters, stockPartRequestsLinesViewFilters } } = getState() as RootState;
      const res = await stockApi.putStockPartRequestLine(partRequestId, lineId, data);
      if (isLinesView) {
        dispatch(getPartRequestByLineThunk({ filters: stockPartRequestsLinesViewFilters }));
      } else {
        dispatch(getStockPartRequestLines({ partRequestId, filters: stockPartRequestLinesFilters }));
        dispatch(getStockPartRequestById({ id: partRequestId }));
      }
      closeModal();
      const successMessage = customMessage || 'Part line was successfully updated';
      dispatch(setSuccessMessage({
        message: successMessage,
        toastId: Math.random(),
      }));
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const isBadReqErrors = error.response?.data.status === ErrorCodesEnum.BAD_REQUEST;
      if (isBadReqErrors) {
        const errors = error.response?.data.errors || [];
        for (let i = 0; i < errors.length; i++) {
          const currentErr = errors[i];
          dispatch(setErrorMessage({ message: currentErr.message, toastId: Math.random() }));
        }
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteStockPartRequestLineThunk = createAsyncThunk<ResponseSingleResult, {
  partRequestId: number,
  lineId: number,
  closeModal: VoidFunctionType,
  isLinesView?: boolean,
}>(
  'delete/StockPartRequestLine',
  async ({
    partRequestId, lineId, closeModal, isLinesView,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { stockPartRequest: { stockPartRequestLinesFilters, stockPartRequestsLinesViewFilters } } = getState() as RootState;
      const res = await stockApi.deletePartReqLine(lineId);
      isLinesView
        ? dispatch(getPartRequestByLineThunk({ filters: stockPartRequestsLinesViewFilters }))
        : dispatch(getStockPartRequestLines({ partRequestId, filters: stockPartRequestLinesFilters }));
      closeModal();
      dispatch(setSuccessMessage({
        message: 'Part quantity was successfully processed and returned back to stock.',
        toastId: Math.random(),
      }));
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestLineProductSourceTotal = createAsyncThunk<ResponseSingleResult<StockPartProductSourceTotal> | null, {
  partRequestId: number,
  lineId: number,
  stockQty: number | null,
  pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  onFinish?: VoidFunctionType,
}>(
  'get/StockPartRequestLineProductSourceTotal',
  async ({
    partRequestId, lineId, stockQty, pricelists, onFinish,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockPartRequestLineProductSourceTotal(partRequestId, lineId, stockQty, pricelists);
      onFinish && onFinish();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEditReadyCollectedPartQuantities = createAsyncThunk<ResponseSingleResult<GetPartQuantitiesDataType>, {
  partId: number,
  lineId: number,
  filters: PartReqPartQuantityFilters,
}>(
  'get/EditReadyCollectedPartQuantities',
  async ({
    partId, lineId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.fetchEditReadyCollectedPartQuantities(partId, lineId, filters);
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchEditReadyCollectedPartQuantities = createAsyncThunk<ResponseSingleResult, {
  partId: number,
  // part qty id
  id: number,
  lineId: number,
  data: PatchPartQtyType,
  closeModal: VoidFunctionType,
  isReserved?: boolean,
  isLinesView?: boolean,
}>(
  'patch/EditReadyCollectedPartQuantities',
  async ({
    partId, id, lineId, data, closeModal, isReserved, isLinesView,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        stockPartRequest: {
          editReadyCollectedModalFilters,
          stockPartRequestLinesFilters,
          stockPartRequestsLinesViewFilters,
          stockPartRequestDetails,
        },
      } = getState() as RootState;
      const res = await stockApi.patchPartQuantities(partId, id, data);
      const filters = {
        order: editReadyCollectedModalFilters.order,
        statuses: isReserved ? editReadyCollectedModalFilters.statuses : [data.status as number],
        ...initSmallPaging,
      };
      dispatch(setEditReadyCollectedModalFilters(filters));
      dispatch(getEditReadyCollectedPartQuantities({
        partId,
        lineId,
        filters,
      }));
      if (isLinesView) {
        dispatch(getPartRequestByLineThunk({
          filters: stockPartRequestsLinesViewFilters,
        }));
      } else {
        stockPartRequestDetails?.id && dispatch(getStockPartRequestLines({
          partRequestId: stockPartRequestDetails?.id,
          filters: stockPartRequestLinesFilters,
        }));
      }

      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAllocatePricelistPartQuantities = createAsyncThunk<ResponseSingleResult<GetPartQuantitiesDataType>, {
  partId: number,
  filters: AllocatePartQuantityFilters,
}>(
  'get/AllocatePricelistPartQuantities',
  async ({ partId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchAllocatedPartQuantities(
        partId,
        filters,
      );
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postAllocatePricelistQuantityThunk = createAsyncThunk<ResponseSingleResult, {
  partRequestId: number,
  lineId: number,
  partId: number,
  data: Array<AllocatePricelistQuantityDto>,
  closeModal: VoidFunctionType,
  isLinesView?: boolean,
}>(
  'post/AllocatePricelistQuantity',
  async ({
    partRequestId, lineId, partId, data, closeModal, isLinesView,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        stockPartRequest: {
          editReadyCollectedModalFilters,
          stockPartRequestLinesFilters,
          stockPartRequestsLinesViewFilters,
        },
      } = getState() as RootState;
      const res = await stockApi.postAllocatePricelistQuantity(partRequestId, lineId, data);
      closeModal();
      dispatch(getEditReadyCollectedPartQuantities({
        partId,
        lineId,
        filters: { ...editReadyCollectedModalFilters, page: 1 },
      }));
      isLinesView
        ? dispatch(getPartRequestByLineThunk({ filters: stockPartRequestsLinesViewFilters }))
        : dispatch(getStockPartRequestLines({ partRequestId, filters: stockPartRequestLinesFilters }));
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const splitPartQuantitiesThunk = createAsyncThunk<ResponseSingleResult, {
  partId: number,
  // part qty id
  id: number,
  lineId: number,
  quantities: [number, number]
  closeModal: VoidFunctionType,
}>(
  'post/SplitPartQuantities',
  async ({
    partId, id, lineId, quantities, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { stockPartRequest: { editReadyCollectedModalFilters } } = getState() as RootState;
      const res = await stockApi.splitPartQuantities(partId, id, quantities);
      dispatch(getEditReadyCollectedPartQuantities({
        partId,
        lineId,
        filters: editReadyCollectedModalFilters,
      }));
      closeModal();
      dispatch(setSuccessMessage({ message: 'Part line was successfully split.', toastId: Math.random() }));
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsToPartReqThunk = createAsyncThunk<
ResponseResult<PartDtoType[]>,
{
  filters: StockFiltersType,
}
>(
  'get/StockPartsToPartReq',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestTimelineThunk = createAsyncThunk<
ResponseResult<StockPartRequestEventDtoBase[]>, {
  partRequestId: number, filters: ExtendedTimelineFilters,
}>(
  'get/StockPartRequestTimeline',
  async ({
    partRequestId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.fetchStockPartRequestTimeline(partRequestId, filters);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postStockPartRequestTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number, content: string, setValue: VoidFunctionType,
}>(
  'post/StockPartRequestTimelineNote',
  async ({
    partRequestId, content, setValue,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.postStockPartRequestTimelineNote(partRequestId, content);
      const { stockPartRequest: { stockPartRequestTimelineFilters } } = getState() as RootState;
      dispatch(setFetching(false));
      if (stockPartRequestTimelineFilters.page === 1) {
        dispatch(getStockPartRequestTimelineThunk({
          partRequestId,
          filters: stockPartRequestTimelineFilters,
        }));
      } else {
        dispatch(setStockPartRequestTimelineFilters({ ...stockPartRequestTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      setValue();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editStockPartRequestTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, content: string, closeModal: VoidFunctionType,
}>(
  'put/StockPartRequestTimelineNote',
  async ({
    id, content, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.editStockPartRequestTimelineNote(id, content);
      const {
        stockPartRequest: { stockPartRequestTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventPartRequest[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = stockPartRequestTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setStockPartRequestTimeline({ ...stockPartRequestTimeline, items }));
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteStockPartRequestTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/StockPartRequestTimelineNote',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.deleteStockPartRequestTimelineNote(id);
      const {
        stockPartRequest: { stockPartRequestTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setStockPartRequestTimelineFilters({ ...stockPartRequestTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generatePurchaseOrderThunk = createAsyncThunk<ResponseSingleResult<{
  createdPurchaseOrders: CreatePurchaseOrderResponseType[],
}> | null,
{ partRequestLineIds: number[], partRequestId?: number, navigate: NavigateFunction, closeModal?: VoidFunctionType, }
>(
  'post/generatePurchaseOrder',
  async ({
    partRequestLineIds, partRequestId, navigate, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const { stockPartRequest: { generatePurchaseOrderFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestLines({
        ...generatePurchaseOrderFilters,
        partRequestIds: partRequestId ? [partRequestId] : undefined,
        ...emptyPaging,
      }, true);
      const filteredIds = partRequestLineIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        const response = await stockApi.generatePurchaseOrder(filteredIds);
        const { createdPurchaseOrders } = response.data.data;
        const firstEl = createdPurchaseOrders[0];
        navigate(`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${firstEl.id}`);
        firstEl.purchaseOrderNumber && dispatch(setSuccessMessage({
          message: 'Purchase order was successfully created.',
          toastId: Math.random(),
        }));
        dispatch(setFetching(false));
        if (closeModal) {
          closeModal();
        }

        return response.data;
      }
      dispatch(setFetching(false));
      if (closeModal) {
        closeModal();
      }

      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      if (closeModal) {
        closeModal();
      }

      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generatePurchaseOrderBulkThunk = createAsyncThunk<ResponseSingleResult<{
  createdPurchaseOrders: CreatePurchaseOrderResponseType[],
}> | null,
{ partRequestLineIds: number[], navigate: NavigateFunction, closeModal?: VoidFunctionType, }
>(
  'post/generatePurchaseOrder',
  async ({
    partRequestLineIds, navigate, closeModal,
  }, {
    dispatch, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const { data: { data: { allAvailableForPurchaseOrderGenerationIds } } } = await stockApi.fetchPartRequestByLine({ ...emptyPaging });
      const filteredIds = partRequestLineIds.filter((id) => allAvailableForPurchaseOrderGenerationIds.includes(id));
      if (filteredIds.length) {
        const response = await stockApi.generatePurchaseOrder(filteredIds);
        const { createdPurchaseOrders } = response.data.data;
        const firstEl = createdPurchaseOrders[0];
        navigate(`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${firstEl.id}`);
        firstEl.purchaseOrderNumber && dispatch(setSuccessMessage({
          message: 'Purchase order was successfully created.',
          toastId: Math.random(),
        }));
        dispatch(setFetching(false));
        if (closeModal) {
          closeModal();
        }

        return response.data;
      }
      dispatch(setFetching(false));
      if (closeModal) {
        closeModal();
      }

      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      if (closeModal) {
        closeModal();
      }

      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestsViewsConfirmed = createAsyncThunk<ResponseResult<ConfirmedPartRequestDto[], { allItemIds: string[] }>,
{ params: ConfirmedPartRequestViewsParams }
>(
  'get/PartRequestsViewsConfirmed',
  async ({ params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartRequestsViewsConfirmed(params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPurchaseOrderBasedOnConfirmedPartRequestsThunk = createAsyncThunk<ResponseSingleResult<{
  createdPurchaseOrders: CreatePurchaseOrderResponseType[],
}> | null,
{ partRequestLineIds: string[], navigate: NavigateFunction, closeModal: VoidFunctionType, }
>(
  'post/createPurchaseOrderBasedOnConfirmedPartRequests',
  async ({
    partRequestLineIds, navigate, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const { stockPartRequest: { confirmedPartRequestViewsParams } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestsViewsConfirmed({
        ...confirmedPartRequestViewsParams, ...emptyPaging,
      });
      const filteredPartRequestLineIds = partRequestLineIds.filter((id) => allItemIds.includes(id));
      if (!filteredPartRequestLineIds.length) {
        dispatch(setFetching(false));
        closeModal();
        return null;
      }
      const productIds = filteredPartRequestLineIds
        .map((str) => str.split(','))
        .reduce((acc, val) => acc.concat(val), [])
        .map((numStr) => +numStr);
      const response = await stockApi.createPurchaseOrderBasedOnConfirmedPartRequests(productIds);
      const { createdPurchaseOrders } = response.data.data;
      const firstEl = createdPurchaseOrders[0];
      const createdElementsLength = createdPurchaseOrders.length;

      navigate(`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${firstEl.id}`);
      firstEl.purchaseOrderNumber && dispatch(setSuccessMessage({
        message: `The system created ${createdElementsLength} purchase order(s) based on selected part lines.`,
        toastId: Math.random(),
      }));
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPartReqLineNotesThunk = createAsyncThunk<ResponseSingleResult, {
  partRequestId: number,
  lineId: number,
  notes?: string | null,
  closeModal?: VoidFunctionType,
}>(
  'patch/PartReqLineNotes',
  async ({
    partRequestId, lineId, notes, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { stockPartRequest: { stockPartRequestsLinesViewFilters } } = getState() as RootState;
      const response = await stockApi.patchPartRequestLine(partRequestId, lineId, { notes });
      dispatch(getPartRequestByLineThunk({ filters: { ...stockPartRequestsLinesViewFilters } }));
      if (closeModal) {
        closeModal();
      }
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLineChangeGroupsThunk = createAsyncThunk<ResponseSingleResult<{ lines: PartRequestLineChangeGroupLineDto[] }>, {
  groupId: number,
}>(
  'get/partRequestLineChangeGroups',
  async ({ groupId }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.fetchPartRequestLineChangeGroups(groupId);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartInfoForDistributeQty = createAsyncThunk<null, {
  affectedLineId: number, setDistributeQtyModal: VoidFunctionType,
}>(
  'get/PartInfoForDistributeQty',
  async ({ affectedLineId, setDistributeQtyModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { stockPartRequest: { stockPartRequestsLinesView } } = getState() as RootState;
      const { items } = stockPartRequestsLinesView;
      const currentParent = items.find((line) => line.id === affectedLineId);
      const children = items.map((line) => line.childLines).flat();
      const currentChild = children?.find((line) => line?.id === affectedLineId);
      const exactPart = currentParent || currentChild;
      if (exactPart) {
        await dispatch(getStockPartRequestById({ id: exactPart.partRequestId }));
        dispatch(setLoading(false));
        setDistributeQtyModal();
      }
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
