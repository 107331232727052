import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  CoreAttachListElType,
  CoreAttachmentsFilters, emptyPaging, GetAttachmentFileResponse, GetAttachmentsResponse,
  initialCoreAttachmentsFilters,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { RootState } from '../../store';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import { setVehicleAttachments, setVehicleAttachmentsFilters } from '../../slices/vehiclesSlice';
import { getVehicleByIdThunk } from './vehicleViewPageThunks';

export const getVehicleAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{
  vehicleId: number,
  params: CoreAttachmentsFilters,
}
>(
  'get/VehicleAttachments',
  async ({ vehicleId, params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleAttachments(vehicleId, params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleAttachmentByIdThunk = createAsyncThunk<
ResponseSingleResult<GetAttachmentFileResponse>,
{ vehicleId: number, id: number }
>(
  'get/VehicleAttachmentById',
  async ({ vehicleId, id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleAttachmentById(vehicleId, id);
      downloadCsv(response.data.data.uri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createVehicleAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  vehicleId: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}
>('post/VehicleAttachment', async ({
  vehicleId, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { vehicles: { vehicleAttachmentsFilters } } = getState() as RootState;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await vehiclesAPI.postVehicleAttachmentUploadUri(vehicleId, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await vehiclesAPI.postVehicleAttachments(vehicleId, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getVehicleByIdThunk({ vehicleId }));
    dispatch(getVehicleAttachmentsThunk({ vehicleId, params: vehicleAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultipleVehicleAttachmentThunk = createAsyncThunk<null,
{
  vehicleId: number,
  ids: number[],
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleVehicleAttachments',
  async ({
    vehicleId, ids, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { vehicles: { vehicleAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await vehiclesAPI.fetchVehicleAttachments(vehicleId, {
        ...vehicleAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await vehiclesAPI.deleteMultipleVehicleAttachment(vehicleId, filteredIds);
      }
      dispatch(setVehicleAttachmentsFilters({ ...vehicleAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getVehicleByIdThunk({ vehicleId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteVehicleAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ vehicleId: number, id: number, closeModal: VoidFunctionType }>(
  'delete/SingleVehicleAttachment',
  async ({
    vehicleId, id, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.deleteVehicleAttachment(vehicleId, id);
      dispatch(getVehicleAttachmentsThunk({
        vehicleId,
        params: initialCoreAttachmentsFilters,
      }));
      dispatch(setVehicleAttachmentsFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getVehicleByIdThunk({ vehicleId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editVehicleAttachmentThunk = createAsyncThunk<
ResponseSingleResult,
{ vehicleId: number, id: number, comment: string, onCancel: VoidFunctionType }
>('patch/VehicleAttachment', async ({
  vehicleId, id, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { vehicles: { vehicleAttachments } } = getState() as RootState;
    const response = await vehiclesAPI.editVehicleAttachment(vehicleId, id, comment);
    const newArr = vehicleAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setVehicleAttachments({ ...vehicleAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
