import React from 'react';
import { VehicleActivityEventDto } from '../../../../types/VehicleViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  fullVehicleCheckDecisionsSelector,
  handoverVehicleCheckDecisionsSelector,
  vehicleCheckTypesValuesSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { VehicleEventEnum } from '../../../../enums/VehicleViewEnums';

type VehicleCheckEventProps = {
  item: VehicleActivityEventDto,
  event: VehicleEventEnum,
}

const VehicleCheckEvent: React.FC<VehicleCheckEventProps> = ({ item, event }) => {
  const { weight, handover, full } = useAppSelector(vehicleCheckTypesValuesSelector);
  const handoverCheckDecisions = useAppSelector(handoverVehicleCheckDecisionsSelector);
  const fullVehicleCheckDecisions = useAppSelector(fullVehicleCheckDecisionsSelector);

  const type = item.vehicleCheckType;
  const fullCheckDecision = item.fullVehicleCheckDecision;
  const handoverCheckDecision = item.handoverVehicleCheckDecision;
  const checkWeight = item.vehicleWeightKg;

  const createCheckInfoHandle = () => {
    switch (type) {
      case full:
      case handover:
        return <span>
          <b>{type === full ? 'Full vehicle check ' : 'Handover check'}</b>
          {' '}
          record was added with the following decision:
          {' '}
          <b>
            {`"${type === full
              ? fullVehicleCheckDecisions.find((el) => el.value === fullCheckDecision)?.label
              : handoverCheckDecisions.find((el) => el.value === handoverCheckDecision)?.label}"`}
          </b>
        </span>;
      case weight:
        return <span>
          <b>Weight check</b>
          {' '}
          record was added with
          <b>{` ${checkWeight} kg `}</b>
          value
        </span>;
      default: return null;
    }
  };

  return (
    <>
      {event === VehicleEventEnum.VehicleCheckCreation && <>{createCheckInfoHandle()}</>}
      {event === VehicleEventEnum.VehicleCheckDeletion && <span>
        <b>Weight check</b>
        {' '}
        record was deleted with
        <b>{` ${checkWeight} kg `}</b>
        value
      </span>}
    </>
  );
};

export default VehicleCheckEvent;
