import { VehicleStatusEnum } from '../../../core/enums/dictionariesEnums';

export const onSiteStatusesList = [
  VehicleStatusEnum.New,
  VehicleStatusEnum.BookedIn,
  VehicleStatusEnum.PreCheck,
  VehicleStatusEnum.PreCheckPassed,
  VehicleStatusEnum.PreCheckFailed,
  VehicleStatusEnum.InRepair,
  VehicleStatusEnum.Allocated,
  VehicleStatusEnum.InWorkshop,
  VehicleStatusEnum.WeightCheck,
  VehicleStatusEnum.PostCheck,
  VehicleStatusEnum.Ready,
  VehicleStatusEnum.PostCheckFailed,
  VehicleStatusEnum.AwaitingSubcontractorWork,
  VehicleStatusEnum.BookedOut,
];

export const offSiteStatusesList = [
  VehicleStatusEnum.New,
  VehicleStatusEnum.Allocated,
  VehicleStatusEnum.PreCheck,
  VehicleStatusEnum.PreCheckPassed,
  VehicleStatusEnum.PreCheckFailed,
  VehicleStatusEnum.JobBlocked,
  VehicleStatusEnum.JobInProgress,
  VehicleStatusEnum.PostCheck,
  VehicleStatusEnum.Ready,
  VehicleStatusEnum.PostCheckFailed,
  VehicleStatusEnum.AwaitingSubcontractorWork,
  VehicleStatusEnum.HandedOver,
];

export const archivedVehicleStatusesList = [
  VehicleStatusEnum.BookedOut,
  VehicleStatusEnum.HandedOver,
];
