import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { AttachmentsCellProps } from '../../../../../../../core/types/coreTypes';
import usePermission from '../../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import { useAppDispatch } from '../../../../../../../store/hooks';
import ConfirmationModal from '../../../../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  deletePartsKitAttachmentThunk,
  getPartsKitAttachmentById,
} from '../../../../../../../store/thunks/stock/parts-kit/partsKitAttachments';
import AttachmentsRow from '../../../../../../../common/components/attachments-table/AttachmentsRow';

const PartsKitAttachmentCell:React.FC<AttachmentsCellProps> = ({
  attach,
  openEditModal,
  checked,
  checkRow,
}) => {
  const { id } = useParams();
  const allowedManage = usePermission(PermissionEnum.StockPartsKitEditFields);

  const dispatch = useAppDispatch();
  const [isModal, setModal] = useState(false);
  const actions = [
    {
      label: 'Edit comment',
      icon: <FontAwesomeIcon icon={faEdit} />,
      key: 'editComment',
      onClick: () => openEditModal(),
    },
    {
      label: 'Delete file',
      key: 'deleteFile',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setModal(true),
    },
  ];

  return (
    <>
      <ConfirmationModal
        isVisible={isModal}
        onCancel={() => setModal(false)}
        confirmHandler={() => id && dispatch(deletePartsKitAttachmentThunk({
          id: attach.id,
          partsKitId: +id,
          closeModal: () => setModal(false),
        }))}
      />
      <AttachmentsRow
        allowedManage={!!allowedManage}
        attachment={attach}
        checked={checked}
        checkRows={checkRow}
        onClick={(attachId) => id && dispatch(getPartsKitAttachmentById({
          id: attachId,
          partsKitId: +id,
        }))}
        actions={actions}
      />
    </>
  );
};

export default PartsKitAttachmentCell;
