import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { weightlessMeasureValuesSelector } from '../../../store/selectors/coreSelectors';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { maxCommonDecimal, maxDiscount } from '../../../core/utils/regex';
import { useOnClickOutside } from '../../../core/hooks/useOutsideClick';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import PartReqEditableCellContent
  from './PartReqEditableCellContent';
import { PartReqLineUIType, PartRequestLineDto } from '../../types/commonTypes';
import { usePartReqCellInteractions } from '../../hooks/usePartReqCellInteractions';

type PartReqCellProps = {
  data: Omit<PartReqLineUIType, 'childLines'>;
  valueRenderer: (key: string, value: string | number) => string | number | JSX.Element;
  disabled: boolean;
  editQty: (id: number, isChildLine?: boolean) => void,
  openCustomModal: NumberFunctionType;
  allowedToManage?: boolean,
  initLinesList: PartRequestLineDto[],
  editInlineThunkHandle: (
    data: {[p: string]: string | number} | {notes: string},
    lineId: number,
    setFieldToEdit: VoidFunctionType,
  ) => void,
  isPartsKitLineHasAllocatedChild: boolean,
}

const PartReqCell: React.FC<PartReqCellProps> = ({
  data,
  valueRenderer,
  disabled,
  editQty,
  openCustomModal,
  allowedToManage,
  initLinesList,
  editInlineThunkHandle,
  isPartsKitLineHasAllocatedChild,
}) => {
  const weightlessMeasures = useAppSelector(weightlessMeasureValuesSelector);

  const {
    fieldToEdit,
    setFieldToEdit,
    editMode,
    setEditMode,
    cellClickHandle,
    cellRenderHandle,
    hiddenCells,
    isPriceAndQtyStatusDisabled,
  } = usePartReqCellInteractions(editQty, openCustomModal, data, valueRenderer, allowedToManage);

  const rules = {
    notes: { maxLength: 100, message: maxLengthMessageHandle(100) },
    weight: { message: 'Entered value should be max 99999.99', max: maxCommonDecimal },
    quantity: { message: 'Entered value should be max 99999', max: 99999 },
    discount: {
      message: 'Entered value should be within 0%-100% range',
      max: maxDiscount,
    },
  };
  const [errors, setErrors] = useState({
    notes: '',
    weight: '',
    quantity: '',
    discount: '',
  });
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setEditMode(false);
    setFieldToEdit && setFieldToEdit('');
  });

  useEffect(() => {
    Object.keys(errors).length > 0 && fieldToEdit && setErrors({
      notes: '',
      weight: '',
      quantity: '',
      discount: '',
    });
    // eslint-disable-next-line
  }, [fieldToEdit]);
  const nowrapValues = ['quantity', 'unitPrice', 'netPrice', 'tax', 'weight'];

  const isPartLine = !!data.partId;
  const isPartsKitParentLine = !!data.partsKitId;
  const isPartsKitChildLine = !!data.partsKitComponentId;
  const isCustomLine = !isPartLine && !isPartsKitParentLine && !isPartsKitChildLine;

  const getSectionModal = (key: string) => {
    return (
      (isCustomLine && ['categoryId', 'productName', 'quantity', 'unitPrice', 'tax', 'weight'].includes(key))
        || (isPartLine && ['quantity', 'unitPrice'].includes(key))
        || (isPartsKitChildLine && key === 'quantity')
    );
  };
  const getSectionActive = (key: string) => {
    return (
      key === 'notes'
        || (isPartsKitParentLine && key === 'quantity')
        || ([isPartLine, isPartsKitParentLine, isCustomLine].some(Boolean) && key === 'discount')
        || (isPartLine && ['tax', 'weight'].includes(key))
    );
  };
  const partsKitLineWitAllocatedChild = (key: string) => isPartsKitLineHasAllocatedChild && (key === 'quantity' || key === 'unitPrice');
  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (hiddenCells.includes(key)) return null;
        return <TdNew key={key}>
          {editMode && fieldToEdit === key
            ? <PartReqEditableCellContent
              initValue={(value === '-' || !value) ? '' : (value as number | string)}
              fieldToEdit={fieldToEdit}
              setFieldToEdit={setFieldToEdit}
              lineId={data.id}
              rules={rules}
              errors={errors}
              setErrors={setErrors}
              ref={ref}
              allowedToManage={allowedToManage}
              initLinesList={initLinesList}
              editThunkHandle={(obj) => {
                editInlineThunkHandle(obj, data.id, () => setFieldToEdit(''));
              }}
            />
            : <div
              className={classNames(
                'editSection',
                {
                  'editSection--disabled': disabled
                              || (isPartsKitParentLine && key === 'tax')
                              || (weightlessMeasures.includes(data.unitOfMeasure) && key === 'weight')
                              || isPriceAndQtyStatusDisabled(key)
                              || partsKitLineWitAllocatedChild(key),
                  'editSection--active': getSectionActive(key),
                  'table-clickable-content': getSectionModal(key),
                  'editSection--partskit-unit-crossed': data.isFree && ['unitPrice', 'netPrice'].includes(key),
                  'edit-Section--nowrap': nowrapValues.includes(key),
                },
              )}
              onClick={() => {
                const isNotAllowed = (key === 'tax' && data.partsKitId)
                          || (key === 'weight' && weightlessMeasures.includes(data.unitOfMeasure))
                          || partsKitLineWitAllocatedChild(key);
                if (isNotAllowed) {
                  return;
                }
                if (!disabled && !isPriceAndQtyStatusDisabled(key)) {
                  cellClickHandle(key);
                }
              }}
            >
              {cellRenderHandle(key, value)}
            </div>}
          <span>
            {(key === 'unitPrice' || key === 'netPrice') && data.isFree && 'Free'}
          </span>
        </TdNew>;
      })}
    </>
  );
};

export default PartReqCell;
