import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown, faChevronUp, faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleStop, faCirclePlay } from '@fortawesome/free-regular-svg-icons';
import { useSearchParams } from 'react-router-dom';
import { Tooltip } from 'antd';
import Button from '../../../core/components/button/Button';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../core/components/tag/Tag';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import JobLineContent from './JobLineContent';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { ReactComponent as JobsIcon } from '../../../assets/icons/jobs-icon.svg';
import { jobStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { playPauseJobThunk } from '../../../store/thunks/jobs/jobListThunks';
import { JobsSearchParamsNames, QueueCompletedModesEnum } from '../../jobs/enums/jobsEnums';
import { isCurrentRoleAdminSelector } from '../../../store/selectors/accountSelectors';
import { JobLineType } from '../types/jobListTypes';

type JobLineProps = {
  job: JobLineType,
  chevronClick: NumberFunctionType,
  openedJob?: number,
}

const JobLine: React.FC<JobLineProps> = ({ job, openedJob, chevronClick }) => {
  const [params] = useSearchParams();
  const tabType = params.get(JobsSearchParamsNames.TAB_TYPE);
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const isJobCompleted = tabType === QueueCompletedModesEnum.COMPLETED;
  const { pending, inProduction, postponed } = useAppSelector(jobStatusesValuesSelector);
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);

  const playPauseHandle = (statusTransition?: number) => {
    statusTransition && dispatch(playPauseJobThunk({
      jobId: job.id,
      statusTransition,
      isCurrentRoleAdmin,
    }));
  };
  return (
    <article className="jobLine not-link">
      <div className="jobLine__inner">
        {isJobCompleted
          ? <JobsIcon className="jobLine__icon" />
          : <Button
            icon={<FontAwesomeIcon icon={job.jobInitStatus === inProduction ? faCircleStop : faCirclePlay} />}
            className="jobLine__button jobLine__button--start"
            onClick={() => playPauseHandle(job.jobInitStatus === inProduction ? pending : inProduction)}
            disabled={job.jobInitStatus === postponed}
          />}
        <ul className="jobLine__info">
          <li className="jobLine__info-item">
            <span className="jobLine__info-label">Job No</span>
            <TruncateTooltip value={job.jobNumber} className="jobLine__info-value jobLine__info-value--number" />
          </li>
          <li className="jobLine__info-item">
            <span className="jobLine__info-label">Linked to</span>
            <TruncateTooltip value={job.linkedTo} className="jobLine__info-value" />
          </li>
          <li className="jobLine__info-item">
            <span className="jobLine__info-label">Job category</span>
            {job.jobCategories.length > 1
              ? <Tooltip
                title={<ul>
                  {job.jobCategories.map((el: string | number, i: number) => <li key={i}>{el}</li>)}
                </ul>}
                overlayClassName="card-tooltip-overlay"
              >
                <span className="jobLine__info-value jobLine__info-value--categories">
                  <span className="jobLine__first-category">{job.jobCategories[0]}</span>
                  <span>{`+${job.jobCategories.length - 1}`}</span>
                </span>
              </Tooltip>
              : <TruncateTooltip value={job.jobCategories[0]} className="jobLine__info-value" />}
          </li>
          {windowWidth < 1400 && (
            <li className="jobLine__info-item">
              <span className="jobLine__info-label">Description</span>
              <TruncateTooltip value={job.description || '-'} className="jobLine__info-value jobLine__info-value--description" />
            </li>
          )}
        </ul>
        {windowWidth >= 1400 && <TruncateTooltip
          value={job.description || '-'}
          className="jobLine__description"
          overlayClassName="truncate-table-tooltip"
        />}
        <div className="jobLine__additional-data">
          {job.isMultipleUserJob && <FontAwesomeIcon icon={faUsers} className="jobLine__multiple-icon" />}
          <Tag textSize="md" label={job.workQueueStatus} className="jobLine__tag" />
        </div>
        <Button
          icon={<FontAwesomeIcon icon={openedJob === job.id ? faChevronUp : faChevronDown} />}
          onClick={() => chevronClick(job.id)}
          className="jobLine__button jobLine__button--expand"
        />
      </div>
      {openedJob === job.id && <JobLineContent isJobCompleted={isJobCompleted} jobId={job.id} />}
    </article>
  );
};

export default JobLine;
