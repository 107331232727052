import React, { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import moment from 'moment';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  activeInvoicingPurchasesStatusSelector,
  archivedInvoicingPurchasesStatusSelector,
  purchasesFiltersSelector, purchasesListSelector,
} from '../../../../store/selectors/invoicingSelectors';
import {
  PurchaseOrderAllStatusEnum,
  PurchaseUrlParamsNames,
  StockTabParamEnum,
} from '../../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { useCancelRequest } from '../../../../core/hooks/useCancelRequest';
import { setPurchasesFilters } from '../../../../store/slices/invoicingSlice';
import { YesNoOptions } from '../../../../core/utils/testData';
import { PurchaseOrderDashFiltersType } from '../../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { getInvoicingPurchases } from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { DateRangeTableFilterType, PickerValues } from '../../../../core/types/coreTypes';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import {
  purchaseOrderStatusObjSelector,
  purchaseOrderStatusOriginSelector,
} from '../../../../store/selectors/coreStatusesSelectors';

const InvoicingPurchasesFilters: React.FC = () => {
  const pageAllowed = usePermission(PermissionEnum.InvoicingPurchases);
  const [searchParams, setSearchParams] = useSearchParams();
  const { controller, cancelRequest } = useCancelRequest();
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(purchasesFiltersSelector);
  const archivedPurchasesStatus = useAppSelector(archivedInvoicingPurchasesStatusSelector);
  const activePurchasesStatuses = useAppSelector(activeInvoicingPurchasesStatusSelector);
  const purchaseOrderStatusOrigin = useAppSelector(purchaseOrderStatusOriginSelector);
  const { received, partiallyReceived, sentToSupplier } = useAppSelector(purchaseOrderStatusObjSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const {
    minCreatedDate, maxCreatedDate, minDueDate, maxDueDate,
  } = useAppSelector(purchasesListSelector);

  const page = searchParams.get(PurchaseUrlParamsNames.PAGE);
  const tab = searchParams.get(PurchaseUrlParamsNames.TAB_TYPE);
  const status = searchParams.get(PurchaseUrlParamsNames.PURCHASE_STATUS);

  const setStatusParam = (value: string) => {
    page && tab && setSearchParams({
      page,
      [PurchaseUrlParamsNames.TAB_TYPE]: tab,
      [PurchaseUrlParamsNames.PURCHASE_STATUS]: value,
    });
  };

  const extraFilters = [
    {
      label: 'With draft entities',
      name: 'hasDraftInvoiceDocuments',
      value: filters.hasDraftInvoiceDocuments || [],
      noSearch: true,
      options: YesNoOptions,
    },
  ];
  const dateFilters = [
    {
      label: 'PO creation date',
      name: 'createdDate',
      value: filters.createdDate,
      disabledDate: (current: moment.Moment) => {
        return current.isBefore(moment(minCreatedDate).startOf('day'))
          || current.isAfter(moment(maxCreatedDate).endOf('day'));
      },
    },
    {
      label: 'PO due date',
      name: 'dueDate',
      value: filters.dueDate,
      disabledDate: (current: moment.Moment) => {
        return current.isBefore(minDueDate)
          || current.isAfter(moment(maxDueDate).endOf('day'));
      },
    },
  ];

  const getFilteredList = (newFilters: PurchaseOrderDashFiltersType, noThunkCall?: boolean) => {
    dispatch(setPurchasesFilters(newFilters));
    if (!noThunkCall) {
      cancelRequest();
      dispatch(getInvoicingPurchases({
        filters: {
          ...newFilters,
          assigneesIds: currentUser?.canSeeOnlyAssignedEntities && currentUser?.id
            ? [currentUser.id]
            : newFilters.assigneesIds || [],
        },
        signal: controller.current?.signal,
      }));
    }
  };

  const [purchaseSearchValue, setPurchaseSearchValue] = useState<string | undefined>(filters.keyword);
  const initialPickerValue: DateRangeTableFilterType = {
    createdDate: [null, null],
    dueDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values,
      page: 1,
    };
    getFilteredList(newFilters);
  };

  const finalOptions = useMemo(() => (tab && tab === StockTabParamEnum.ARCHIVED
    ? archivedPurchasesStatus
    : activePurchasesStatuses), [tab, activePurchasesStatuses, archivedPurchasesStatus]);

  const setStatusHandle = (status: number | string) => {
    setStatusParam(status.toString());
    const newFilters = {
      ...filters,
      page: 1,
      statuses: status === PurchaseOrderAllStatusEnum.ALL
        ? [received as number, partiallyReceived as number, sentToSupplier as number]
        : [+status],
    };
    getFilteredList(newFilters, true);
  };

  const setDefaultStatusParam = () => {
    if (filters.statuses?.length) {
      const statusToSet = filters.statuses.length === 3
        ? PurchaseOrderAllStatusEnum.ALL.toString()
        : filters.statuses[0].toString();
      setStatusParam(statusToSet);
    } else {
      if (tab === StockTabParamEnum.ARCHIVED && archivedPurchasesStatus.length) {
        const firstV = archivedPurchasesStatus[0].value;
        setStatusParam(firstV.toString());
      } else {
        setStatusParam(PurchaseOrderAllStatusEnum.ALL.toString());
      }
    }
  };

  useEffect(() => {
    if (!status) {
      if (tab) {
        setDefaultStatusParam();
      }
    }
    // eslint-disable-next-line
  }, [tab, status, filters.statuses, archivedPurchasesStatus]);

  useEffect(() => {
    if (pageAllowed && purchaseOrderStatusOrigin.length > 0) {
      if (status) {
        if (filters.statuses?.length) {
          getFilteredList(filters);
        } else {
          const newStatuses = +status === PurchaseOrderAllStatusEnum.ALL
            ? [received as number, partiallyReceived as number, sentToSupplier as number]
            : [+status];
          const newFilters = {
            ...filters,
            statuses: newStatuses,
            isActive: tab ? tab === StockTabParamEnum.ACTIVE : true,
          };
          getFilteredList(newFilters);
          dispatch(setPurchasesFilters(newFilters));
        }
      }
    }
    // eslint-disable-next-line
  }, [pageAllowed, purchaseOrderStatusOrigin.length, searchParams, filters.statuses, filters.page, filters.pageSize]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (purchaseSearchValue && purchaseSearchValue !== filters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredList({ ...filters, keyword: purchaseSearchValue.trim(), page: 1 });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!purchaseSearchValue?.trim() && filters.keyword) {
        getFilteredList({ ...filters, keyword: undefined, page: 1 });
      }
    }
    // eslint-disable-next-line
  }, [purchaseSearchValue, filters.keyword]);

  const onClearHandle = () => {
    getFilteredList({
      ...filters,
      page: 1,
      hasDraftInvoiceDocuments: undefined,
      keyword: undefined,
      dueDate: undefined,
      createdDate: undefined,
    });
    setPickerValue(initialPickerValue);
  };

  return (
    <div className="sections-and-filters sections-and-filters--offset">
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
        ? <Selectable
          options={finalOptions}
          value={status ? +status : ''}
          onChange={(v) => setStatusHandle(+v)}
        />
        : <Segmented
          options={finalOptions}
          value={status ? +status : ''}
          onChange={(v) => setStatusHandle(v)}
          className="purchaseOrderFilters__segmented"
        />}
      <KeywordFiltersSection
        searchValue={purchaseSearchValue}
        setSearchValue={(v) => {
          setPurchaseSearchValue(v);
        }}
        filters={filters.isActive ? extraFilters : undefined}
        dateFilters={dateFilters}
        getFilteredResHandle={(values, field) => {
          getFilteredList({
            ...filters,
            page: 1,
            [field]: values,
          });
        }}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        onClear={onClearHandle}
        className="invoicingPurchasesFilters"
      />
    </div>
  );
};

export default InvoicingPurchasesFilters;
