import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { allowedShortBaForUserSelector, QuoteUnitsOfMeasureSelector } from './coreSelectors';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { setUiNamesHandler } from '../../core/utils/setUiNamesHandler';
import {
  PurchaseOrderAllStatusEnum,
  PurchaseOrderFailReasonUiNames,
  PurchaseOrderStatusUiNames,
  PurchaseOrderVatUiNames,
  StockTabParamEnum,
} from '../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { PurchaseOrderPartStateEnum } from '../../stock/purchase-orders/view-page/enums/PurchaseOrderViewEnum';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { PurchaseOrderPartsOrderingEnum } from '../../stock/purchase-orders/view-page/enums/purchaseOrderEnums';
import { labelHandle } from '../../core/utils/labelHandle';
import { StockPartsOrders } from '../../stock/search/enums/StockPartsOrders';
import {
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector,
  suitableVehiclesProfilesSelector,
} from './sharedSelectors';
import {
  purchaseOrderStatusObjSelector,
  purchaseOrderStatusOriginSelector,
  stockPartStatusesSelector,
} from './coreStatusesSelectors';
import { purchaseOrderPartsOrderingSelector, stockPartsOrderingSelector } from './coreOrderingSelectors';
import { DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';
import { convertFromDeliveryToAddress } from '../../stock/purchase-orders/create/utils/convertAddressUtils';
import { toPoundCurrency } from '../../common/utils/formatUtils';

export const purchaseOrderMaxNumberSelector = (state: RootState) => state.purchaseOrder.purchaseOrderMaxNumber;
export const purchaseOrderSearchPartsSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartSearch;
export const purchaseOrderSearchPartsFiltersSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartSearchFilters;

export const addPartToOrderColumnsSelector = createSelector(stockPartsOrderingSelector, (ordering) => {
  return [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      orderField: getSortIndex(StockPartsOrders.PartCode, ordering),
      sorter: true,
      filterSearch: true,
    },
    {
      title: 'Manufacturer code',
      dataIndex: 'manufacturerCode',
      orderField: getSortIndex(StockPartsOrders.ManufacturerCode, ordering),
      sorter: true,
      filterSearch: true,
    },
    {
      title: 'Part name',
      dataIndex: 'name',
      orderField: getSortIndex(StockPartsOrders.Name, ordering),
      sorter: true,
      filterSearch: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      orderField: getSortIndex(StockPartsOrders.Description, ordering),
      sorter: true,
      filterSearch: true,
    },
  ];
});

export const purchaseOrdersAddPartColumnsSelector = createSelector(
  purchaseOrderSearchPartsFiltersSelector,
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector,
  allowedShortBaForUserSelector,
  suitableVehiclesProfilesSelector,
  (filters, categories, subcategories, ba, vehicles) => {
    return [
      {
        value: filters.categoryIds || [],
        name: 'categoryIds',
        label: 'Category',
        options: categories || [],
      },
      {
        value: filters.subcategoryIds || [],
        name: 'subcategoryIds',
        label: 'Subcategory',
        options: subcategories || [],
      },
      {
        value: filters.businessAreas || [],
        name: 'businessAreas',
        label: 'Business area',
        options: ba || [],
        noSearch: true,
      },
      {
        value: filters.vehicleIds || [],
        name: 'vehicleIds',
        label: 'Suitable for vehicles',
        options: vehicles.items || [],
      },
    ];
  },
);

export const purchaseOrderPartSearchTableModeResultSelector = createSelector(
  purchaseOrderSearchPartsFiltersSelector,
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector,
  allowedShortBaForUserSelector,
  stockPartStatusesSelector,
  purchaseOrderSearchPartsSelector,
  (filters, categories, subcategories, ba, statuses, data) => {
    const { page, pageSize } = filters;
    return {
      totalCount: data.totalCount,
      pages: data.pages,
      items: data.items?.map((item, i) => ({
        id: item.id,
        number: getNo(page, pageSize, i),
        partCode: item.partCode || '-',
        manufacturerCode: item.manufacturerCode || '-',
        name: item.name || '-',
        description: item.description || '-',
      })),
    };
  },
);

export const purchaseOrderAttachmentsFiltersSelector = (state:RootState) => state.purchaseOrder.purchaseOrderAttachmentsFilters;
export const purchaseOrderAttachmentsSelector = (state:RootState) => state.purchaseOrder.purchaseOrderAttachments;

const purchaseOrderPartVatInitSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartVat;
export const purchaseOrderPartVatSelector = createSelector(purchaseOrderPartVatInitSelector, (partVat) => {
  return setUiNamesHandler(partVat, PurchaseOrderVatUiNames);
});

const purchaseOrderPartFailReasonInitSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartFailReason;
export const purchaseOrderPartFailReasonSelector = createSelector(purchaseOrderPartFailReasonInitSelector, (failReason) => {
  return setUiNamesHandler(failReason, PurchaseOrderFailReasonUiNames);
});

export const purchaseOrderDetailsSelector = (state: RootState) => state.purchaseOrder.purchaseOrderDetails;
export const purchaseOrderHeadSelector = createSelector(purchaseOrderDetailsSelector, (purchaseOrderDetails) => {
  const details = purchaseOrderDetails?.purchaseOrder;
  return [
    { label: 'Purchase order No', value: details?.purchaseOrderNumber || '-' },
    { label: 'Supplier', value: details?.supplierName || '-' },
    { label: 'Supplier account No', value: details?.supplierAccountNumber || '-' },
    {
      label: 'Created date',
      value: details?.createdDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, details?.createdDate) : '-',
    },
    {
      label: 'Modified date',
      value: details?.modifiedDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, details?.modifiedDate) : '-',
    },
  ];
});
export const purchaseOrderAddressFieldsSelector = createSelector(purchaseOrderDetailsSelector, (purchaseOrderDetails) => {
  const details = purchaseOrderDetails?.purchaseOrder;
  const deliveryDetails = {
    deliveryCompanyName: details?.deliveryCompanyName || null,
    deliveryAddressLine1: details?.deliveryAddressLine1 || null,
    deliveryAddressLine2: details?.deliveryAddressLine2 || null,
    deliveryCityTown: details?.deliveryCityTown || null,
    deliveryRegion: details?.deliveryRegion || null,
    deliveryPostalCode: details?.deliveryPostalCode || null,
    deliveryTelephone: details?.deliveryTelephone || null,
  };
  return convertFromDeliveryToAddress(deliveryDetails);
});

export const purchaseOrderStatusTransitionSelector = createSelector(
  purchaseOrderStatusOriginSelector,
  purchaseOrderDetailsSelector,
  (status, details) => {
    return status.filter(
      (st) => details?.purchaseOrder.statusTransitions?.includes(st.value) || st.value === details?.purchaseOrder.status,
    );
  },
);

export const purchaseOrdersFiltersSelector = (state: RootState) => state.purchaseOrder.purchaseOrdersFilters;

export const purchaseOrdersSelector = (state: RootState) => state.purchaseOrder.purchaseOrders;
export const purchaseOrderStatusSelector = createSelector(
  purchaseOrderStatusOriginSelector,
  purchaseOrdersSelector,
  purchaseOrderStatusObjSelector,
  (items, po, statusObj) => {
    let allTotalActive = 0;
    const { completed } = statusObj;
    po.statusCounts.forEach((el) => {
      if (el.status !== completed) allTotalActive += el.count;
    });

    const allTotalActiveLabel = allTotalActive
      ? `(${allTotalActive})`
      : '';

    const getStatusCount = (status: number) => {
      const count = po.statusCounts.find((p) => p.status === status)?.count || 0;
      return count ? `(${count.toString()})` : '';
    };

    const newItems = items.map((item) => ({
      ...item,
      label: `${item.label} ${getStatusCount(item.value)}`,
    }));
    return [
      { label: `All ${allTotalActiveLabel}`, value: PurchaseOrderAllStatusEnum.ALL },
      ...newItems,
    ];
  },
);

export const activeOrderStatusSelector = createSelector(purchaseOrderStatusSelector, (statuses) => {
  return statuses.filter((st) => !st.label.includes(PurchaseOrderStatusUiNames.Completed));
});

export const archivedOrderStatusSelector = createSelector(purchaseOrderStatusSelector, (statuses) => {
  return statuses.filter((st) => st.label.includes(PurchaseOrderStatusUiNames.Completed));
});

export const purchaseOrdersOrderingSelector = (state: RootState) => state.purchaseOrder.purchaseOrdersOrdering;

const purchaseOrdersAssigneesInitSelector = (state: RootState) => state.purchaseOrder.purchaseOrdersAssignees;
export const purchaseOrdersAssigneesSelector = createSelector(purchaseOrdersAssigneesInitSelector, (list) => {
  return list.map((el) => ({ label: `${el.firstName} ${el.lastName}`, value: el.id }));
});

const purchaseOrderPartStateInitSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartState;
export const purchaseOrderPartStateSelector = createSelector(purchaseOrderPartStateInitSelector, (list) => {
  return setUiNamesHandler(list, PurchaseOrderPartStateEnum);
});

export const purchaseOrderPartsColumnsSelector = createSelector(
  purchaseOrderPartsOrderingSelector,
  purchaseOrderPartStateSelector,
  (ordering, state) => {
    return [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
      },
      {
        title: 'Product No',
        dataIndex: 'partNumber',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.ProductNumber, ordering),
        filterSearch: true,
      },
      {
        title: 'Product name',
        dataIndex: 'partName',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.ProductName, ordering),
        filterSearch: true,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.RequestedQuantity, ordering),
        isFilterRange: true,
      },
      {
        title: 'Cost price',
        dataIndex: 'costPrice',
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.CostPrice, ordering),
        sorter: true,
      },
      {
        title: 'Net',
        dataIndex: 'net',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.RequestedNet, ordering),
      },
      {
        title: 'Gross',
        dataIndex: 'gross',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.RequestedGross, ordering),
      },
      {
        title: 'Received',
        dataIndex: 'totalReceivedQuantity',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.TotalReceivedQuantity, ordering),
      },
      {
        title: 'Cancelled',
        dataIndex: 'cancelledQuantity',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.CancelledQuantity, ordering),
      },
      {
        title: 'Due in',
        dataIndex: 'dueIn',
        sorter: true,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.DueIn, ordering),
      },
      {
        title: 'State',
        dataIndex: 'state',
        sorter: true,
        filters: state,
        orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.State, ordering),
      },
    ];
  },
);

export const purchaseOrderPartsListSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartsList;
export const purchaseOrderPartsTableListSelector = createSelector(
  purchaseOrderPartsListSelector,
  purchaseOrderPartStateSelector,
  QuoteUnitsOfMeasureSelector,
  (data, state, unitsOfMeasure) => {
    const withUnitOfMeasure = (value: number | null, unitOfMeasure: number) => {
      if (value === null) return '-';
      const currentUnitOfMeasure = unitsOfMeasure.find(({ value }) => value === unitOfMeasure)?.label || '';
      return `${value} ${currentUnitOfMeasure}`;
    };

    return data.purchaseOrderParts.map((el) => ({
      id: el.id,
      ordinalNumber: el.ordinalNumber,
      partNumber: el.partNumber || '-',
      partName: el.partName,
      notes: el.notes,
      requestedQuantity: withUnitOfMeasure(el.requestedQuantity, el.unitOfMeasure),
      costPrice: toPoundCurrency(el.costPrice, '-', 4),
      net: toPoundCurrency(el.requestedNetString),
      gross: toPoundCurrency(el.requestedGrossString),
      totalReceivedQuantity: withUnitOfMeasure(el.totalReceivedQuantity, el.unitOfMeasure),
      cancelledQuantity: withUnitOfMeasure(el.cancelledQuantity, el.unitOfMeasure),
      dueIn: withUnitOfMeasure(el.dueIn, el.unitOfMeasure),
      state: labelHandle(el.state, state),
      partId: el.partId,
      unitOfMeasure: el.unitOfMeasure,
      isUsualLine: el.isUsualLine,
      vat: el.vatPercent,
      canBeDeleted: el.canBeDeleted,
      arePriceAndRequestedQuantityEditable: el.arePriceAndRequestedQuantityEditable,
      areReceivedAndReturnedQuantitiesEditable: el.areReceivedAndReturnedQuantitiesEditable,
    }));
  },
);
export const purchaseOrderPartsListFiltersSelector = (state: RootState) => state.purchaseOrder.purchaseOrderPartsListFilters;

export const purchaseOrderSummarySelector = (state: RootState) => state.purchaseOrder.purchaseOrderSummary;
export const partPurchaseOrdersFiltersSelector = (state: RootState) => state.purchaseOrder.partPurchaseOrdersFilters;
export const partPurchaseOrdersSelector = (state: RootState) => state.purchaseOrder.partPurchaseOrders;
export const partPurchaseOrdersStatusTabsSelector = createSelector(
  purchaseOrderStatusOriginSelector,
  partPurchaseOrdersFiltersSelector,
  purchaseOrderStatusObjSelector,
  partPurchaseOrdersSelector,
  (statuses, filters, statusObj, purchaseOrders) => {
    const { completed } = statusObj;
    const { statusCounts } = purchaseOrders;
    let activeCount = 0;
    statusCounts.forEach((el) => {
      if (el.status !== completed) activeCount += el.count;
    });
    const archivedCount = statusCounts.find((el) => el.status === completed)?.count || 0;
    const getLabel = (name: string, count: number) => {
      return count > 0 ? `${name} (${count})` : name;
    };
    return [
      { label: getLabel('Active', activeCount), value: StockTabParamEnum.ACTIVE },
      { label: getLabel('Archived', archivedCount), value: StockTabParamEnum.ARCHIVED },
    ];
  },
);
export const purchaseOrderTimelineFiltersSelector = (state: RootState) => state.purchaseOrder.purchaseOrderTimelineFilters;
export const purchaseOrderTimelineSelector = (state: RootState) => state.purchaseOrder.purchaseOrderTimeline;
