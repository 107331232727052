import React, { useCallback, useEffect, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartPlus, faCartArrowDown } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { stockPartTypesValuesSelector } from '../../../../../store/selectors/stockSelectors';
import { labelHandle } from '../../../../../core/utils/labelHandle';
import { StockPartStatusesEnum } from '../../../../../core/enums/dictionariesEnums';
import { PartDtoType } from '../../../../search/types/stockSearchTypes';
import { stockPartStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { getStockPartsStatuses } from '../../../../../store/thunks/core/coreStatusesThunks';

type QuantitySectionProps = {
  data: PartDtoType | undefined,
  total: number,
  lessThenCriticalLevel: boolean | null | undefined,
  lessThenWarningLevel: boolean | null | undefined,
}

const QuantitySection:React.FC<QuantitySectionProps> = ({
  data, total, lessThenCriticalLevel, lessThenWarningLevel,
}) => {
  const {
    status,
    type,
    free,
    picked,
    collected,
    allocated,
    dueIn,
    withoutSellingPrice,
  } = data || {};
  const dispatch = useAppDispatch();
  const statuses = useAppSelector(stockPartStatusesSelector);
  const partTypes = useAppSelector(stockPartTypesValuesSelector);
  const isConsumableType = type === partTypes.consumable;

  const quantities = useMemo(() => {
    const list = [
      {
        label: 'Free',
        value: isConsumableType ? '-' : free || '-',
      },
      {
        label: 'Allocated',
        value: isConsumableType ? '-' : allocated || '-',
      },
      {
        label: 'Picked',
        value: isConsumableType ? '-' : picked || '-',
      },
      {
        label: 'Collected',
        value: isConsumableType ? '-' : collected || '-',
      },
    ];
    if (withoutSellingPrice) {
      list.splice(1, 0, { label: 'Without selling price', value: withoutSellingPrice || '-' });
    }
    return list;
  }, [allocated, collected, free, isConsumableType, picked, withoutSellingPrice]);

  useEffect(() => {
    dispatch(getStockPartsStatuses());
    // eslint-disable-next-line
  }, []);

  const getTitle = useCallback(() => {
    if (status) {
      if (isConsumableType) {
        return labelHandle(status, statuses);
      }
      return !isConsumableType && status === 1 ? 'Total Quantity' : labelHandle(status, statuses);
    }
    return StockPartStatusesEnum.OutOfStock;
  }, [isConsumableType, status, statuses]);

  const renderIcon = () => {
    if (lessThenCriticalLevel) {
      return <Tooltip title="Critical level of free parts">
        <FontAwesomeIcon icon={faCartArrowDown} />
      </Tooltip>;
    }
    if (lessThenWarningLevel) {
      return <Tooltip title="Warning level of free parts">
        <FontAwesomeIcon icon={faCartPlus} />
      </Tooltip>;
    }
    return null;
  };

  return (
    <div className="quantitySection">
      <div className="quantitySection__content">
        <h2 className="quantitySection__title quantitySection__title--bordered">
          <span>{getTitle()}</span>
          {!isConsumableType && !!total && <span>{total}</span>}
        </h2>
        <ul className="quantitySection__list">
          {quantities.map((el) => (
            <li key={el.label} className="quantitySection__item">
              <span>
                {!isConsumableType && el.label === 'Free' && renderIcon()}
                {el.label}
              </span>
              <span>{el.value}</span>
            </li>
          ))}
        </ul>
      </div>
      <div className="quantitySection__content">
        <h2 className="quantitySection__title">
          <span>Due In</span>
          <span>{isConsumableType ? '-' : dueIn || '-'}</span>
        </h2>
      </div>
    </div>
  );
};

export default QuantitySection;
