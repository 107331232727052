import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ErrorsEnum } from '../../../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../../../core/utils/errorMessageHandle';
import Input from '../../../../../../../core/components/input/Input';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import { maxCommonDecimal } from '../../../../../../../core/utils/regex';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../../../../../core/components/select/Select';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  integerQuantityMeasureValuesSelector,
  isFetchingSelector, pcsMeasureSelector, purchaseOrderPartAmountInputTypesSelector, purchaseOrderPartAmountInputTypesValuesSelector,
  quoteLineTaxesSelector, QuoteUnitsOfMeasureSelector, twentyPercentTaxSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import { RangeTableFiltersType, VoidFunctionType, NumericRangeType } from '../../../../../../../core/types/coreTypes';
import {
  patchPurchaseOrderPartThunk,
  postPurchaseOrderPart,
} from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { purchaseOrderPartsListSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import RadioGroup from '../../../../../../../core/components/radio-group/RadioGroup';

type CustomPartLineFields = {
  productName: string,
  quantity: string,
  unitOfMeasure?: number,
  costPrice: string,
  tax?: number,
}

type CustomPartLineFormProps = {
  cancelHandle: VoidFunctionType,
  setQtyMinMax:(v: NumericRangeType) => void,
  setRange: (v: RangeTableFiltersType) => void,
  isVisible: boolean,
  editedCustomLine?: number,
}

const CustomPartLineForm: React.FC<CustomPartLineFormProps> = ({
  cancelHandle, setRange, setQtyMinMax, editedCustomLine, isVisible,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const taxes = useAppSelector(quoteLineTaxesSelector);
  const twentyPercentTax = useAppSelector(twentyPercentTaxSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const quoteUnitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const pcsMeasure = useAppSelector(pcsMeasureSelector);
  const integerQuantitiesMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const { purchaseOrderParts } = useAppSelector(purchaseOrderPartsListSelector);
  const amountTypes = useAppSelector(purchaseOrderPartAmountInputTypesSelector);
  const { negative, positive } = useAppSelector(purchaseOrderPartAmountInputTypesValuesSelector);

  const {
    handleSubmit, control, watch, setValue, reset, setError, clearErrors,
  } = useForm<CustomPartLineFields>({
    defaultValues: {
      productName: '',
      quantity: '0',
      unitOfMeasure: undefined,
      costPrice: '0.0000',
      tax: undefined,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const [selectedAmountType, setSelectedAmountType] = useState(positive);

  const isNegativeAmountType = selectedAmountType === negative;

  const measure = watch('unitOfMeasure');
  const quantity = watch('quantity');
  const costPrice = watch('costPrice');
  const tax = watch('tax');

  useEffect(() => {
    if (!measure && pcsMeasure) {
      setValue('unitOfMeasure', pcsMeasure);
    }
    // eslint-disable-next-line
  }, [measure, pcsMeasure]);

  useEffect(() => {
    if (tax === undefined && twentyPercentTax) {
      setValue('tax', twentyPercentTax);
    }
    // eslint-disable-next-line
  }, [tax, twentyPercentTax]);

  const netValue = useMemo(() => {
    const quantityV = +quantity;
    const costPriceV = +costPrice;
    return quantityV * costPriceV;
  }, [quantity, costPrice]);

  const priceValue = useMemo(() => {
    const quantityV = +quantity;
    const costPriceV = +costPrice;
    return quantityV * costPriceV + (quantityV * costPriceV * (tax ? tax * 0.01 : 0));
  }, [quantity, costPrice, tax]);

  const cancelFormClick = () => {
    reset();
    clearErrors();
    cancelHandle();
  };
  const onSubmit = (values: CustomPartLineFields) => {
    const { tax, ...rest } = values;
    const finalValues = {
      ...rest,
      vat: tax as number,
      productName: values.productName.trim(),
      costPrice: (isNegativeAmountType ? ((+values.costPrice) * -1) : +values.costPrice) || 0.00,
      quantity: +values.quantity || 0,
    };
    if (editedCustomLine) {
      id && editedCustomLine && dispatch(patchPurchaseOrderPartThunk({
        purchaseOrderId: +id,
        partId: editedCustomLine,
        values: { ...finalValues },
        closeModal: cancelFormClick,
        setRange,
        setQtyMinMax,
      }));
    } else {
      id && dispatch(postPurchaseOrderPart({
        purchaseOrderId: +id,
        values: finalValues,
        closeModal: cancelFormClick,
        successMessage: 'A custom part line was successfully added.',
        setQtyMinMax,
        setRange,
      }));
    }
  };

  useEffect(() => {
    if (editedCustomLine && isVisible) {
      const matchedElement = purchaseOrderParts.find((item) => item.id === editedCustomLine);
      if (matchedElement) {
        setValue('productName', matchedElement.partName || '');
        setValue('quantity', matchedElement.requestedQuantity.toString());
        setValue('unitOfMeasure', matchedElement.unitOfMeasure);
        setValue('costPrice', Math.abs(matchedElement.costPrice).toString());
        setValue('tax', matchedElement.vatPercent);
        setSelectedAmountType(matchedElement.amountInputType);
      }
    }
    // eslint-disable-next-line
  }, [editedCustomLine, isVisible]);

  useEffect(() => {
    if (!isVisible) {
      reset();
      clearErrors();
    }
    // eslint-disable-next-line
  }, [isVisible]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="customLineForm">
      <div className="customLineForm__section">
        <Controller
          name="productName"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            maxLength: { value: 200, message: maxLengthMessageHandle(200) },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={() => {
                if (!field.value.trim()) {
                  setError('productName', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('productName', '');
                }
              }}
              error={errors?.productName?.message}
              label="Product name"
              className="customLineForm__field--semi"
            />
          )}
        />
        <Controller
          name="quantity"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            min: {
              value: measure && integerQuantitiesMeasures.includes(+measure) ? 0 : 0.00,
              message: measure && integerQuantitiesMeasures.includes(+measure)
                ? 'The min allowed value is 0'
                : 'The min allowed value is 0.00',
            },
          }}
          render={({ field }) => (
            <>
              {measure && integerQuantitiesMeasures.includes(+measure)
                ? <RegularInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value;
                    field.onChange(v);
                  }}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                />
                : <DecimalInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value.replace(',', '.');
                    field.onChange(v);
                  }}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                />}
            </>
          )}
        />
        <Controller
          name="unitOfMeasure"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <Select
              options={quoteUnitsOfMeasure}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              error={errors.unitOfMeasure?.message}
              label="Unit of measure"
              parentRender
            />
          )}
        />
      </div>
      <RadioGroup
        optionType="button"
        options={amountTypes}
        value={selectedAmountType ?? null}
        label="Amount input type"
        onChange={(v) => setSelectedAmountType(+v)}
        className="customLineForm__radio"
      />
      <div className="customLineForm__section">
        <Controller
          name="costPrice"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <DecimalInputNumber
              value={field.value}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                field.onChange(v);
              }}
              max={maxCommonDecimal}
              error={errors.costPrice?.message}
              label="Cost price"
              maxDecimalPlaceLength={4}
              isNegativeValueAllowed={isNegativeAmountType}
            />
          )}
        />
        <Controller
          name="tax"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <Select
              options={taxes}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              error={errors.tax?.message}
              label="Tax"
              parentRender
            />
          )}
        />
        <DecimalInputNumber
          label="Gross"
          max={maxCommonDecimal}
          value={netValue.toFixed(2)}
          isNegativeValueAllowed={isNegativeAmountType}
          disabled
        />
        <DecimalInputNumber
          label="Gross"
          max={maxCommonDecimal}
          value={priceValue.toFixed(2)}
          isNegativeValueAllowed={isNegativeAmountType}
          disabled
        />
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        createType="submit"
        createLabel={editedCustomLine ? 'Save' : 'Add line'}
        disabledCreate={Object.keys(errors).length > 0}
        cancelClick={() => cancelFormClick()}
        isLoading={isFetching}
        offsetTop
      />
    </form>
  );
};

export default CustomPartLineForm;
