export const ForbiddenFileExtensions = [
  '.APK',
  '.AHK',
  '.JAR',
  '.CMD',
  '.IPA',
  '.RUN',
  '.SK',
  '.0XE',
  '.EXE',
  '.XBE',
  '.WORKFLOW',
  '.BIN',
  '.BMS',
  '.ELF',
  '.8CK',
  '.BAT',
  '.AIR',
  '.GADGET',
  '.APP',
  '.XAP',
  '.AC',
  '.WIDGET',
  '.U3P',
  '.COM',
  '.PIF',
  '.OUT',
  '.XLM',
  '.TPK',
  '.X86',
  '.GPE',
  '.SCR',
  '.73K',
  '.RXE',
  '.COMMAND',
  '.SCB',
  '.EX_',
  '.PAF.EXE',
  '.SCAR',
  '.CGI',
  '.SCRIPT',
  '.APPIMAGE',
  '.XEX',
  '.TCP',
  '.ACTION',
  '.EBS2',
  '.PS1',
  '.ACC',
  '.COFFEE',
  '.CELX',
  '.HTA',
  '.ISU',
  '.X86_64',
  '.RFU',
  '.FXP',
  '.VLX',
  '.ECF',
  '.VPM',
  '.89K',
  '.PLSC',
  '.XBAP',
  '.RPJ',
  '.RBF',
  '.WS',
  '.CACTION',
  '.FAS',
  '.ACTC',
  '.VBS',
  '.COF',
  '.DLD',
  '.SCPT',
  '.PHAR',
  '.M3G',
  '.ACR',
  '.MLX',
  '.A6P',
  '.JSX',
  '.WSH',
  '.VXP',
  '.EX_',
  '.MCR',
  '.JS',
  '.EX4',
  '.JSF',
  '.ESH',
  '.SERVER',
  '.SHB',
  '.DEK',
  '.BA_',
  '.A7R',
  '.FPI',
  '.PEX',
  '.IIM',
  '.ZL9',
  '.PLX',
  '.ICD',
  '.NEXE',
  '.PWC',
  '.WCM',
  '.EAR',
  '.PYC',
  '.MEL',
  '.MS',
  '.EPK',
  '.MM',
  '.EXE1',
  '.SNAP',
  '.APPLESCRIPT',
  '.EXE',
  '.PAF',
  '.MCR',
  '.EBM',
  '.EX5',
  '.SHORTCUT',
  '.UVM',
  '.RGS',
  '.VBSCRIPT',
  '.FRS',
  '.E_E',
  '.HMS',
  '.GM9',
  '.OTM',
  '.ATMX',
  '.PYO',
  '.SCT',
  '.SPR',
  '.MRC',
  '.WIDGET',
  '.KX',
  '.WIZ',
  '.CSH',
  '.CYW',
  '.EBS',
  '.GS',
  '.PRG',
  '.OSX',
  '.MRP',
  '.XQT',
  '.FKY',
  '.PRG',
  '.APP',
  '.AZW2',
  '.FAS',
  '.MXE',
  '.KIX',
  '.ACTM',
  '.APP',
  '.SEED',
  '.VEXE',
  '.CEL',
  '.ROX',
  '.TMS',
  '.ARSCRIPT',
  '.N',
  '.VBE',
  '.TIAPP',
  '.EZS',
  '.THM',
  '.BEAM',
  '.UDF',
  '.ITA',
  '.WPM',
  '.WPK',
  '.DMC',
  '.MCR',
  '.LS',
  '.S2A',
  '.PRC',
  '.SCA',
  '.SCPTD',
  '.MHM',
  '.MSL',
  '.IPK',
  '.MS',
  '.CHEAT',
  '.RFS',
  '.XYS',
  '.WSF',
  '.JSE',
  '.QIT',
  '.ES',
  '.LO',
  '.RBX',
  '.DS',
  '.EHAM',
  '.MEM',
  '.EBACMD',
  '.NCL',
  '.UPX',
  '.MAC',
  '.DXL',
  '.MAM',
  '.KSH',
  '.BTM',
  '.HAM',
  '.VDO',
  '.IPF',
  '.HPF',
  '.MIO',
  '.GPU',
  '.PVD',
  '.SAPK',
  '.YGH',
  '.EXOPC',
  '.SBS',
  '.ASB',
  '.AFMACROS',
  '.AFMACRO',
  '.MAMC',
  '.PRG',
  '.STS',
  '.QPX',
  '.ORE',
  '.EZT',
  '.SMM',
  '.FBA',
];
