import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setCustomerActivityLog,
  setCustomerActivityLogFilters,
  setCustomerAttachments,
  setCustomerAttachmentsFilters,
  setCustomerContactsFilters, setCustomerContactsList,
  setCustomerDetails,
} from '../../../store/slices/customersSlice';
import {
  infiniteScrollInitPaging,
  initDataList,
  initialCoreAttachmentsFilters, initPaging,
} from '../../../core/types/coreTypes';

export const clearCustomerData = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setCustomerDetails(null));
  dispatch(setCustomerActivityLogFilters(initPaging));
  dispatch(setCustomerActivityLog(initDataList));
  dispatch(setCustomerAttachments([]));
  dispatch(setCustomerAttachmentsFilters(initialCoreAttachmentsFilters));
  dispatch(setCustomerContactsFilters(infiniteScrollInitPaging));
  dispatch(setCustomerContactsList(initDataList));
};
