import React from 'react';
import { EntityEventInfo } from '../../../types/activityTypes';
import ViewPageLink from '../../../../../common/components/view-page-link/ViewPageLink';
import { EntityType } from '../../../../../common/types/commonTypes';

type UserEventTextProps = {
  entityInfo: EntityEventInfo,
}

const UserEventText: React.FC<UserEventTextProps> = ({ entityInfo }) => {
  const { entityType, entityName, entityId } = entityInfo;
  const purchaseOrderTypes: EntityType[] = ['Stock purchase order', 'Invoicing purchase order'];

  return (
    <div>
      {`${purchaseOrderTypes.includes(entityType) ? 'Purchase order' : entityType} `}
      <ViewPageLink entityType={entityType} entityId={entityId}><>{entityName}</></ViewPageLink>
      {': '}
    </div>
  );
};

export default UserEventText;
