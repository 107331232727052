import React, { useEffect } from 'react';
import Pagination from '../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Modal from '../../../../core/components/modal/Modal';
import { initDataList, VoidFunctionType } from '../../../../core/types/coreTypes';
import {
  affectedCategoryEmployeesSelector,
  affectedEmployeeFiltersSelector, affectedSkillsEmployeesSelector,
  skillsCategoriesSelector,
  skillsListByCategorySelector,
} from '../../../../store/selectors/employeesSelector';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  setAffectedCategoriesEmployees,
  setAffectedEmployeeFilters,
  setAffectedSkillsEmployees,
} from '../../../../store/slices/employeesSlice';
import { getCategoryAffectedEmployees, getSkillAffectedEmployees } from '../../../../store/thunks/employee/skillsThunks';
import Thead from '../../../../core/components/table-components/Thead';
import Tbody from '../../../../core/components/table-components/Tbody';
import Tr from '../../../../core/components/table-components/Tr';
import { affectedEmployeeColumns } from '../../types/skillsTypes';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';

type AffectedEmployeeModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  categoryToChange?: number,
  skillToChange?: number,
  checkedKeys: number[],
  confirmDelete: VoidFunctionType,
}

const AffectedEmployeeModal: React.FC<AffectedEmployeeModalProps> = ({
  isVisible, onCancel, categoryToChange, skillToChange, checkedKeys,
  confirmDelete,
}) => {
  const dispatch = useAppDispatch();
  const skillsCategories = useAppSelector(skillsCategoriesSelector);
  const { skills: skillsListByCategory } = useAppSelector(skillsListByCategorySelector);
  const affectedEmployeeFilters = useAppSelector(affectedEmployeeFiltersSelector);
  const currentSkill = skillsListByCategory.find((sk) => sk.id === skillToChange);
  const currentSkillList = checkedKeys.map((el) => skillsListByCategory.find((s) => s.id === el)?.name || el.toString())
    .map((el) => `"${el}"`);
  const affectedCategoryEmployees = useAppSelector(affectedCategoryEmployeesSelector);
  const affectedSkillsEmployees = useAppSelector(affectedSkillsEmployeesSelector);
  const isGlobalLoading = useAppSelector(isFetchingSelector);

  const cellsData = categoryToChange
    ? affectedCategoryEmployees
    : affectedSkillsEmployees;

  const currentCategory = skillsCategories?.find((sk) => sk.id === categoryToChange);
  const modalTitle = categoryToChange ? 'Delete category' : 'Delete skill item';

  const textTitle = categoryToChange
    ? <>
      Category
      <b>{` "${currentCategory?.name}" `}</b>
      is used in multiple employee profiles in the system.
    </>
    : checkedKeys.length > 0
      ? <>
        {`Skill item${currentSkillList.length > 1 ? 's' : ''}`}
        <b>{` ${currentSkillList.join(', ')} `}</b>
        {currentSkillList.length > 1 ? 'are' : 'is'}
        {' '}
        used in multiple employee profiles in the system.
      </>
      : <>
        Skill item
        <b>{` "${currentSkill?.name}" `}</b>
        is used in multiple employee profiles in the system.
      </>;
  const textSubtitle = categoryToChange
    // eslint-disable-next-line max-len
    ? 'All skills from this category will be removed from existing employee profiles and will not be available for selection in any new profiles.'
    // eslint-disable-next-line max-len
    : `This skill${checkedKeys.length > 0 ? 's' : ''} will be removed from existing employee profiles and will not be available for selection in any new profiles.`;

  useEffect(() => {
    if (categoryToChange) {
      dispatch(getCategoryAffectedEmployees({
        categoryId: categoryToChange,
        filters: affectedEmployeeFilters,
      }));
    } else if (checkedKeys.length > 0 && !skillToChange) {
      dispatch(getSkillAffectedEmployees({
        skillId: checkedKeys,
        filters: { ...affectedEmployeeFilters },
      }));
    } else if (skillToChange) {
      dispatch(getSkillAffectedEmployees({
        skillId: [skillToChange],
        filters: { ...affectedEmployeeFilters },
      }));
    }
    // eslint-disable-next-line
  }, [affectedEmployeeFilters.page, affectedEmployeeFilters.pageSize]);

  const cancelHandle = () => {
    onCancel();
    dispatch(setAffectedEmployeeFilters({ ...affectedEmployeeFilters, page: 1, pageSize: 5 }));
    dispatch(setAffectedSkillsEmployees(initDataList));
    dispatch(setAffectedCategoriesEmployees(initDataList));
  };
  return (
    <Modal
      title={modalTitle}
      visible={isVisible}
      onCancel={cancelHandle}
      width={800}
      destroyOnClose
    >
      <div className="affectedModal">
        <div className="affectedModal__description">
          <span>{textTitle}</span>
          <span>{textSubtitle}</span>
        </div>
        <b className="affectedModal__text">Affected employee profiles:</b>
        <Pagination
          onChange={(page, pageSize) => dispatch(setAffectedEmployeeFilters({ ...affectedEmployeeFilters, page, pageSize }))}
          currentPage={affectedEmployeeFilters.page}
          pageSize={affectedEmployeeFilters.pageSize}
          totalItems={cellsData.totalCount}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          showSizeChanger={false}
          offsetBottom
        />
        {cellsData && cellsData.totalCount > 0 && (
          <TableNew small offsetBottom>
            <Thead>
              {affectedEmployeeColumns.map((col) => (
                <ThNew className={`affectedEmployeeSkillTh--${col.dataIndex}`} key={col.dataIndex}>{col.title}</ThNew>
              ))}
            </Thead>
            <Tbody>
              {cellsData.items.map((cell, i) => <Tr key={i}>
                {Object.entries(cell).map(([key, value]) => <TdNew key={key}>
                  <TruncateTooltip value={value} table />
                </TdNew>)}
              </Tr>)}
            </Tbody>
          </TableNew>
        )}
        <span className="affectedModal__acceptance">Do you want to proceed?</span>
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createLabel="Yes, delete"
        createType="button"
        createClick={confirmDelete}
        isLoading={isGlobalLoading}
      />
    </Modal>
  );
};

export default AffectedEmployeeModal;
