import React from 'react';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import NominalCodesColumns from './NominalCodesColumns';
import { useAppSelector } from '../../../../store/hooks';
import { nominalCodesColumnsSelector } from '../../../../store/selectors/invoicingSelectors';
import { NominalCodeTableLine } from '../../types/nominalCodesTypes';
import NominalCodesBody from './NominalCodesBody';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { NumberFunctionType } from '../../../../core/types/coreTypes';

type NominalCodesTableProps = {
  data: NominalCodeTableLine[],
  totalElements: number,
  setIdToEdit: NumberFunctionType,
  setIdToDelete: NumberFunctionType,
}
const NominalCodesTable: React.FC<NominalCodesTableProps> = ({
  data, totalElements, setIdToEdit, setIdToDelete,
}) => {
  const columns = useAppSelector(nominalCodesColumnsSelector);
  const allowedToManage = usePermission(PermissionEnum.InvoicingManageNominalCodes);

  return (
    <TableNew>
      <NominalCodesColumns
        columns={columns}
        totalElements={totalElements}
        allowedToManage={allowedToManage}
      />
      <NominalCodesBody
        data={data}
        columnsLength={columns.length}
        allowedToManage={allowedToManage}
        setIdToEdit={setIdToEdit}
        setIdToDelete={setIdToDelete}
      />
    </TableNew>
  );
};

export default NominalCodesTable;
