import React from 'react';
import { FieldUpdateEventProps } from '../../../../../../../core/types/coreTypes';
import { fieldNameEventHandle } from '../../../../../../../common/utils/activityLogUtils';
import FieldUpdateEvent from '../../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../../core/utils/regex';
import { localDateFormatHandler } from '../../../../../../../core/utils/utcDateFormatHandler';

const PurchaseOrderFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'Date':
      case 'DueDate':
        return localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, value as string);
      default: return value;
    }
  };
  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field, { date: 'Purchase order' })}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default PurchaseOrderFieldUpdateEvent;
