import React, { useState } from 'react';
import Modal from '../../../core/components/modal/Modal';
import { StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Input from '../../../core/components/input/Input';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import './EditCommentModal.scss';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type EditCommentModalProps = {
  isVisible: boolean;
  onCancel: VoidFunctionType;
  editedComment: string;
  setEditedComment: StringFunctionType;
  editHandle: VoidFunctionType;
}

const EditCommentModal: React.FC<EditCommentModalProps> = ({
  isVisible, onCancel, editedComment, setEditedComment, editHandle,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const [error, setError] = useState<string>('');
  const cancelHandle = () => {
    setError('');
    onCancel();
  };
  return (
    <Modal visible={isVisible} onCancel={cancelHandle} title="Edit comment">
      <Input
        value={editedComment}
        onChange={(e) => {
          const val = e.target.value;
          if (val.length > 200) {
            setError(maxLengthMessageHandle(200));
          } else {
            setError('');
          }
          setEditedComment(val);
        }}
        label="Comment"
        error={error}
        className="editComment"
      />
      <ButtonActions
        createLabel="Save"
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createClick={editHandle}
        disabledCreate={!!error}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default EditCommentModal;
