import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseResult } from '../../../core/types/coreTypes';
import { pricelistAPI } from '../../../api/pricelistApi';
import { PricelistDashFiltersType, PricelistDto } from '../../../pricelists/dashboard/types/pricelistTypes';
import { setDashboardLoading } from '../../slices/coreSlice';

export const getPricelists = createAsyncThunk<
ResponseResult<PricelistDto[]> | null,
{
  filters: PricelistDashFiltersType
  signal?: AbortSignal,
}
>(
  'get/Pricelists',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const response = await pricelistAPI.fetchPricelists(filters, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
