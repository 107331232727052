import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import {
  AxiosErrorResponse,
  BooleanFunctionType,
  CoreAttachListElType,
  DictionaryResponse,
  PostAttachReqWithType,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { employeeAPI } from '../../../api/employeeApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { DuplicateValue, ErrorsEnum } from '../../../core/enums/errorsEnum';
import { settingsAPI } from '../../../api/settingsApi';
import { SkillLookupDto } from '../../../common/types/commonTypes';
import { coreStatusesAPI } from '../../../api/core/coreStatusesApi';
import { CreateUserAccountForEmployeeRequest } from '../../../settings/create-user/utils/UserShema';
import { EmployeeFields, PostEmployeeRestModel } from '../../../employees/employee-view-page/utils/EmployeeSchema';

export const getEmployeeStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchEmployeeStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeGendersThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeGenders',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeGenders();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeDepartmentsThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeDepartments',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeDepartments();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeSalaryTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeSalaryTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeSalaryTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeSkillLevelsThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeSkillLevels',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeSkillLevels();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeSkillsDictionaryThunk = createAsyncThunk<ResponseSingleResult<{ skills: SkillLookupDto[], allItemIds: number[] }>,
{ keyword?: string, setSkillsSelectLoading: BooleanFunctionType }
>(
  'get/EmployeeSkillsDictionary',
  async ({ keyword, setSkillsSelectLoading }, { rejectWithValue }) => {
    setSkillsSelectLoading(true);
    try {
      const response = await employeeAPI.fetchEmployeeSkillsDictionary(keyword);
      setSkillsSelectLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setSkillsSelectLoading(false);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEmployeeThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number, referenceNumber: number }> | null,
{
  data: PostEmployeeRestModel,
  list: CoreAttachListElType[],
  fileAttachmentType: number | undefined,
  navigate: NavigateFunction,
  closeForm: VoidFunctionType,
  setError: UseFormSetError<EmployeeFields>,
  isUserCreate: boolean,
  userFields?: CreateUserAccountForEmployeeRequest,
}
>(
  'post/Employee',
  async ({
    data, list, navigate, closeForm, fileAttachmentType, setError,
    isUserCreate, userFields,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.createEmployee(data);
      const { createdId } = response.data.data;
      if (list.length > 0) {
        const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
        const res = await employeeAPI.postEmployeeAttachmentUploadUri(createdId, uploadUriArr);
        if (res.data.success) {
          const attachments = await uploadAttachmentsAsyncHandle(
            list,
            '',
            res,
            dispatch,
            true,
            fileAttachmentType,
          );
          await employeeAPI.postEmployeePhoto(createdId, attachments[0] as PostAttachReqWithType);
        }
      }
      if (isUserCreate && userFields) {
        await settingsAPI.createUser({ ...userFields, employeeId: createdId });
        const message = `An invitation was sent to user "${data.firstName} ${data.lastName}"`;
        dispatch(setSuccessMessage({ message, toastId: Math.random() }));
      }
      closeForm();
      dispatch(setSuccessMessage({ message: `Employee "${data.firstName} ${data.lastName}" was successfully created.` }));
      navigate(`/${routesPath.EMPLOYEES}/${createdId}`, { replace: true });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const emailKey = 'Email';
      const insuranceNo = 'NationalInsuranceNumber';
      const found = errors.find((err) => err.key === emailKey);
      const foundInsuranceErr = errors.find((err) => err.key === insuranceNo);
      if (found || foundInsuranceErr) {
        window.scrollTo(0, 0);
        found && setError('email', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
        foundInsuranceErr && setError('nationalInsuranceNumber', { type: 'Duplicate', message: ErrorsEnum.UNIQUE_VALUE });
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
