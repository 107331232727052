import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { ServerErrorType } from '../types/coreTypes';
import { setErrorMessage } from '../../store/slices/coreSlice';
import { StatusTransitionError } from '../enums/errorsEnum';

export const statusTransitionErrorHandle = (errors: ServerErrorType[], dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  const statusTransitionErrors = errors.filter(({ key }) => key === StatusTransitionError);
  statusTransitionErrors.forEach(({ message }) => {
    const toastId = Math.random();
    dispatch(setErrorMessage({ message, toastId }));
  });
};
