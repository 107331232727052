import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  CoreAttachListElType, CoreAttachmentsFilters, emptyPaging, GetAttachmentsResponse,
  initialCoreAttachmentsFilters, ResponseSingleResult, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import {
  setPartAttachments,
  setPartAttachmentsFilter,
  setPartImageData,
} from '../../../slices/stockSlice';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import { downloadCsv } from '../../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../../common/utils/attachmentsHandlers';
import { getStockPartByIdThunk } from './partViewPageThunks';
import { RootState } from '../../../store';

export const getPartAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{
  partId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/PartAttachments',
  async ({ partId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartAttachments(partId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, partId: number, closeModal: VoidFunctionType, isPartImage?: boolean }>(
  'delete/SinglePartAttachment',
  async ({
    id, partId, closeModal, isPartImage,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.deletePartAttachment(id, partId);
      if (isPartImage) {
        dispatch(setPartImageData({ uri: null, id: null }));
        dispatch(setSuccessMessage({ message: 'The image was successfully deleted' }));
      } else {
        dispatch(getPartAttachmentsThunk({
          partId,
          filters: initialCoreAttachmentsFilters,
        }));
        dispatch(setPartAttachmentsFilter(initialCoreAttachmentsFilters));
        dispatch(getStockPartByIdThunk({ id: partId }));
        dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartAttachmentByIdThunk = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, partId: number, isPartImage?: boolean }
>('get/PartAttachmentById', async ({
  id, partId, isPartImage,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.getPartAttachmentById(id, partId);
    const { uri } = response.data.data;
    if (isPartImage) {
      dispatch(setPartImageData({ uri }));
    } else {
      downloadCsv(response.data.data.uri);
    }
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const addPartImageThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: { id: number }[] }>,
{ id: number, image: CoreAttachListElType, deletePreviousImage: boolean }
>('post/PartImage', async ({
  id, image, deletePreviousImage,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    if (deletePreviousImage) {
      const { stock } = getState() as RootState;
      if (stock.partImageData.id) {
        await stockApi.deletePartAttachment(stock.partImageData.id, id);
      }
    }
    const uploadUri = { fileName: image.fileName, uploadGuid: image.uploadGuid };
    const res = await stockApi.postPartAttachmentUploadUri(id, [uploadUri]);
    const uploadedImages = await uploadAttachmentsAsyncHandle(
      [image],
      '',
      res,
      dispatch,
    );
    const finalRes = await stockApi.addPartImage(id, uploadedImages[0]);
    const partImageId = finalRes.data.data.createdItems[0].id;
    dispatch(getPartAttachmentByIdThunk({
      id: partImageId, partId: id, isPartImage: true,
    }));
    dispatch(setPartImageData({ id: partImageId }));
    dispatch(setSuccessMessage({ message: 'The image was successfully added' }));
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const createPartAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: { id: number }[] }>,
{
  id: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}
>('post/PartAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { stock } = getState() as RootState;
    const { partAttachmentsFilters } = stock;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await stockApi.postPartAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await stockApi.postPartAttachment(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    id && dispatch(getPartAttachmentsThunk({ partId: id, filters: partAttachmentsFilters }));
    dispatch(getStockPartByIdThunk({ id }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editPartAttachmentThunk = createAsyncThunk<
ResponseSingleResult,
{ id: number, partId: number, comment: string, onCancel: VoidFunctionType }
>('patch/PartAttachment', async ({
  id, partId, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { stock: { partAttachments } } = getState() as RootState;
    const response = await stockApi.editPartAttachment(id, partId, comment);
    const newArr = partAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setPartAttachments({ ...partAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultiplePartAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  partId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultiplePartAttachments',
  async ({
    ids, partId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { stock: { partAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartAttachments(partId, {
        ...partAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await stockApi.deleteMultiplePartAttachment(filteredIds, partId);
      }
      dispatch(setPartAttachmentsFilter({ ...partAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getStockPartByIdThunk({ id: partId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
