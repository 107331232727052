import { useSearchParams } from 'react-router-dom';
import { RectificationViewModeParamsEnum } from '../enums/RectificationViewEnums';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { useAppSelector } from '../../../store/hooks';
import { rectificationDetailsSelector } from '../../../store/selectors/sharedSelectors';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';

export const useRectificationParams = () => {
  const { backHandle } = useLocationHistoryState(true);
  const [params, setSearchParams] = useSearchParams();
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const rectification = rectificationDetails?.rectification;

  const setModeParam = (mode: RectificationViewModeParamsEnum | string) => {
    setSearchParams({ mode }, { replace: true });
  };

  const tabs = [
    {
      label: 'Details & activity',
      value: RectificationViewModeParamsEnum.RECTIFICATION_DETAILS,
    },
    {
      label: `Part request ${tabCountHandle(rectification?.partRequestLinesCount)}`,
      value: RectificationViewModeParamsEnum.RECTIFICATION_PART_REQUEST,
    },
    {
      label: `Contacts ${tabCountHandle(rectification?.contactsCount)}`,
      value: RectificationViewModeParamsEnum.RECTIFICATION_CONTACTS,
    },
    {
      label: `Attachments ${tabCountHandle(rectificationDetails?.attachmentsCount)}`,
      value: RectificationViewModeParamsEnum.RECTIFICATION_ATTACHMENTS,
    },
    {
      label: `Links ${tabCountHandle(rectificationDetails?.linksCount)}`,
      value: RectificationViewModeParamsEnum.RECTIFICATION_LINKS,
    },
  ];

  return {
    params,
    backHandle,
    setModeParam,
    tabs,
    setSearchParams,
  };
};
