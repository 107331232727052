import React, { useState } from 'react';
import { ICustomColumn } from '../../../../common/types/commonTypes';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { nominalCodesFiltersSelector } from '../../../../store/selectors/invoicingSelectors';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import { setNominalCodesFilters } from '../../../../store/slices/invoicingSlice';
import { SearchTableFilterType } from '../../../../core/types/coreTypes';

type NominalCodesColumnsProps = {
  columns: ICustomColumn[],
  totalElements: number,
  allowedToManage: boolean | undefined,
}
const NominalCodesColumns: React.FC<NominalCodesColumnsProps> = ({ columns, totalElements, allowedToManage }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(nominalCodesFiltersSelector);
  const [codeColumn, ...restColumns] = columns;
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    code: '',
    description: '',
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setNominalCodesFilters(newFilters));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setNominalCodesFilters(newFilters));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setNominalCodesFilters({
        ...filters,
        [field]: undefined,
        page: 1,
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setNominalCodesFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
      page: 1,
    }));
  };

  return (
    <Thead>
      <Tr>
        <ThWithControls
          col={codeColumn}
          tableFilters={filters}
          totalElements={totalElements}
          sortHandle={sortHandle}
          searchVl={searchVl}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          className={`nominalCodesTh--${codeColumn.dataIndex}`}
        />
        {allowedToManage && (
          <ThNew action>
            <span className="sr-only">Actions</span>
          </ThNew>
        )}
        {restColumns.map((col) => <ThWithControls
          key={col.dataIndex}
          col={col}
          totalElements={totalElements}
          tableFilters={filters}
          sortHandle={sortHandle}
          searchVl={searchVl}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          className={`nominalCodesTh--${col.dataIndex}`}
        />)}
      </Tr>
    </Thead>
  );
};

export default NominalCodesColumns;
