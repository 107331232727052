import React from 'react';
import Modal from '../../../../core/components/modal/Modal';
import { BooleanFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import Button from '../../../../core/components/button/Button';
import './DeleteEmployeeModal.scss';
import { useAppSelector } from '../../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';

type DeleteEmployeeWithLinkedAccountModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  handleDeleteEmployee: BooleanFunctionType,
}
const DeleteEmployeeModal: React.FC<DeleteEmployeeWithLinkedAccountModalProps> = ({
  visible,
  onCancel,
  handleDeleteEmployee,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={600}
      title="Delete employee"
      className="deleteEmployeeModal"
    >
      <p className="deleteEmployeeModal__text">
        <span>This employee has a linked CRM account in the system.</span>
        <span>Do you want to delete CRM account as well?</span>
      </p>
      <div className="deleteEmployeeModal__actions">
        <Button
          label="Cancel"
          onClick={onCancel}
          designType="light-inverse"
          className="deleteEmployeeModal__button"
          disabled={isGlobalLoading}
        />
        <Button
          label="No, delete employee only"
          onClick={() => handleDeleteEmployee(false)}
          designType="light-inverse"
          className="deleteEmployeeModal__button"
          disabled={isGlobalLoading}
        />
        <Button
          label="Yes, delete all"
          onClick={() => handleDeleteEmployee(true)}
          designType="dark"
          className="deleteEmployeeModal__button"
          disabled={isGlobalLoading}
        />
      </div>
    </Modal>
  );
};

export default DeleteEmployeeModal;
