import React, { useEffect } from 'react';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  stockPartAffectedCategoriesColumnsSelector,
  stockPartAffectedCategoriesFiltersSelector, stockPartAffectedCategoriesListSelector,
  stockPartSubcategoriesSelector,
} from '../../../../store/selectors/stockSelectors';
import AffectedPartCategoriesTable from './AffectedPartCategoriesTable';
import Pagination from '../../../../core/components/pagination/Pagination';
import { setStockPartAffectedCategoriesFilter } from '../../../../store/slices/stockSlice';
import {
  getPartCategoryAffectedThunk,
  getPartSubcategoryAffectedThunk,
} from '../../../../store/thunks/stock/categories/partCategoriesThunks';
import { partCategoriesLookupSelector } from '../../../../store/selectors/sharedSelectors';

type AffectedPartCategoriesModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  categoryId: number | undefined,
  subcategoryId: number | undefined,
}
const AffectedPartCategoriesModal: React.FC<AffectedPartCategoriesModalProps> = ({
  visible,
  onCancel,
  categoryId,
  subcategoryId,
}) => {
  const dispatch = useAppDispatch();
  const columns = useAppSelector(stockPartAffectedCategoriesColumnsSelector);
  const { items, totalCount } = useAppSelector(stockPartAffectedCategoriesListSelector);
  const filters = useAppSelector(stockPartAffectedCategoriesFiltersSelector);

  const partCategories = useAppSelector(partCategoriesLookupSelector);
  const partSubcategories = useAppSelector(stockPartSubcategoriesSelector);

  const getName = () => {
    if (categoryId) {
      return partCategories.find((c) => c.value === categoryId)?.label || '';
    }
    if (subcategoryId) {
      return partSubcategories.items.find((s) => s.id === subcategoryId)?.name || '';
    }
    return '';
  };

  const textTitle = <>
    {categoryId ? 'Category' : 'Subcategory'}
    <b>{` "${getName()}" `}</b>
    is used in multiple entities in the system and cannot be deleted.
  </>;
  // eslint-disable-next-line max-len
  const textSubtitle = `Please remove this ${categoryId ? 'category as well as all child subcategories' : 'subcategory'} from all affected entities to unlock a possibility to delete this ${categoryId ? 'category' : 'subcategory'}.`;

  useEffect(() => {
    categoryId && dispatch(getPartCategoryAffectedThunk({
      categoryId,
      filters,
    }));
    subcategoryId && dispatch(getPartSubcategoryAffectedThunk({
      subcategoryId,
      filters,
    }));
    // eslint-disable-next-line
  }, [filters]);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={`Delete ${categoryId ? 'category' : 'subcategory'}`}
      width={900}
      destroyOnClose
    >
      <div className="affectedModal">
        <div className="affectedModal__description">
          <span>{textTitle}</span>
          <span>{textSubtitle}</span>
        </div>
        <b className="affectedModal__text">Affected entities:</b>

        <Pagination
          onChange={(page) => dispatch(setStockPartAffectedCategoriesFilter({ ...filters, page }))}
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          showSizeChanger={false}
          offsetBottom
        />
        <AffectedPartCategoriesTable
          columns={columns}
          data={items}
          filters={filters}
          totalCount={totalCount}
        />
      </div>
    </Modal>
  );
};

export default AffectedPartCategoriesModal;
