import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment/moment';
import { useNavigate } from 'react-router-dom';
import { RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import Input from '../../../../core/components/input/Input';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { useAppDispatch } from '../../../../store/hooks';
import {
  BooleanFunctionType, DictionaryItem,
} from '../../../../core/types/coreTypes';
import Select from '../../../../core/components/select/Select';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { UserContextDto } from '../../../../settings/users-dashboard/types/settingsTypes';
import RequisiteSection from '../../../../common/components/requisite/RequisiteSection';
import { AddressFields, RequisiteDto } from '../../../../common/types/commonTypes';
import { initRequisite } from '../../../../common/utils/data';
import AddressField from '../../../../common/components/address/AddressField';
import {
  createPurchaseOrderThunk,
  getPurchaseOrderSupplierRequisiteThunk,
} from '../../../../store/thunks/stock/purchase-orders/createPurchaseOrderThunks';
import { convertFromAddressToDelivery } from '../utils/convertAddressUtils';
import { getSupplierList } from '../../../../store/thunks/suppliers/supplierThunks';
import Textarea from '../../../../core/components/textarea/Textarea';
import {
  createPurchaseOrderDefaults,
  CreatePurchaseOrderFields,
  PurchaseDescriptionSchema,
} from '../utils/CreatePurchaseOrderSchema';
import { getUsersListCoreDictionary } from '../../../../store/thunks/coreThunk';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../core/utils/regex';

type CreatePurchaseOrderProps = {
  suppliersUi: { value: number, label: JSX.Element }[],
  usersLookup: DictionaryItem[],
  currentUser: UserContextDto | null,
  requisiteFields: RequisiteDto,
  setRequisiteFields: (value: RequisiteDto) => void,
  setRequisiteModal: BooleanFunctionType,
  setUserSearch: (value?: string) => void,
  addressFields: AddressFields | null,
  setAddressModal: BooleanFunctionType,
  setAddressFields: (value: AddressFields | null) => void,
  purchaseOrderNumber: string | null,
  isManual: boolean,
  requisiteSupplierId: number | undefined,
  setRequisiteSupplierId: (id: number | undefined) => void,
}

const CreatePurchaseOrderForm: React.FC<CreatePurchaseOrderProps> = ({
  suppliersUi,
  usersLookup,
  currentUser,
  requisiteFields,
  setRequisiteFields,
  setRequisiteModal,
  setUserSearch,
  addressFields,
  setAddressModal,
  setAddressFields,
  purchaseOrderNumber,
  isManual,
  requisiteSupplierId,
  setRequisiteSupplierId,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState('');

  const getOrderNumber = () => {
    const NUMBER_LENGTH = 6;
    if (!purchaseOrderNumber) return null;
    const incrementedNumber = +purchaseOrderNumber + 1;
    let numberString = incrementedNumber.toString();
    if (numberString.length < NUMBER_LENGTH) {
      const leadingZerosCount = NUMBER_LENGTH - numberString.length;
      numberString = '0'.repeat(leadingZerosCount) + numberString;
    }
    return numberString;
  };

  const {
    control, handleSubmit, reset, setValue, watch, clearErrors,
  } = useForm<CreatePurchaseOrderFields>({
    defaultValues: createPurchaseOrderDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const supplierId = watch('supplierId');

  const cancel = () => {
    setValue('supplierId', undefined);
    setValue('description', '');
    setValue('assigneeId', currentUser?.id);
    setRequisiteFields(initRequisite);
    setUserSearch(undefined);
    setAddressFields(null);
    setRequisiteSupplierId(undefined);
    clearErrors();
  };

  const onSubmit = (values: CreatePurchaseOrderFields) => {
    const deliveryFields = convertFromAddressToDelivery(addressFields);
    const coreValues = {
      supplierId: values.supplierId as number,
      assigneeId: values.assigneeId as number,
      description: values.description?.trim() || null,
    };
    const data = {
      ...coreValues,
      ...deliveryFields,
      billingDetails: isManual ? requisiteFields : null,
      billingSupplierId: requisiteSupplierId || null,
    };
    dispatch(createPurchaseOrderThunk({
      data,
      navigate,
      onClose: () => {
        cancel();
        reset();
      },
    }));
  };

  useEffect(() => {
    currentUser && setValue('assigneeId', currentUser.id);
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (supplierId) {
      setRequisiteSupplierId(supplierId);
      dispatch(getPurchaseOrderSupplierRequisiteThunk({ id: supplierId })).unwrap().then(({ data }) => {
        setRequisiteFields(data.billingRequisite);
      });
    }
    // eslint-disable-next-line
  }, [supplierId]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        dispatch(getSupplierList({ filters: { keyword: searchValue } }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getSupplierList({ filters: { keyword: undefined } }));
    }
    // eslint-disable-next-line
    }, [searchValue]);

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-form details-form">
      <Controller
        control={control}
        name="supplierId"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={suppliersUi}
            value={field.value}
            onChange={field.onChange}
            error={errors.supplierId?.message}
            label="Supplier"
            // eslint-disable-next-line consistent-return
            onSearch={(val) => {
              if (val.length <= 250) {
                setSearchValue(val.trim());
              } else {
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            onSelect={() => {
              searchValue && setSearchValue('');
              dispatch(getSupplierList({ filters: { keyword: undefined } }));
            }}
            showSearch
            className="details-form__field--lg"
          />
        )}
      />
      <Input
        value={getOrderNumber()}
        label="Purchase order No"
        className="details-form__field"
        disabled
      />
      <Input
        value={moment().format(DEFAULT_UI_DATE_FORMAT)}
        disabled
        label="Purchase order date"
        className="details-form__field"
      />
      <Controller
        control={control}
        name="description"
        rules={PurchaseDescriptionSchema}
        render={({ field }) => (
          <Textarea
            value={field.value || ''}
            onChange={field.onChange}
            error={errors.description?.message}
            label="Description"
            disabled={!supplierId}
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        control={control}
        name="assigneeId"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={usersLookup}
            value={field.value}
            onChange={field.onChange}
            error={errors.assigneeId?.message}
            showSearch
            showArrow
            onSearch={(val) => {
              if (val.length <= 250) {
                setUserSearch(val);
              } else {
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            onSelect={() => {
              setUserSearch('');
              dispatch(getUsersListCoreDictionary({ keyword: undefined }));
            }}
            disabled={!supplierId}
            label="Assigned to"
            className="details-form__field--lg"
          />
        )}
      />
      <AddressField
        addressFields={addressFields}
        setAddressModal={setAddressModal}
        title="Deliver to"
        disabled={!supplierId}
      />
      <RequisiteSection
        requisiteDetails={requisiteFields}
        setIsVisible={() => {
          setRequisiteModal(true);
        }}
        title="Billing details"
        disabled={!supplierId}
        className="details-form__field--lg"
      />
      <ButtonActions
        createLabel="Create"
        cancelLabel="Clear"
        createType="submit"
        cancelClick={cancel}
        className="details-form__actions"
      />
    </form>
  );
};

export default CreatePurchaseOrderForm;
