import React, { useMemo } from 'react';
import { PurchaseOrderPartDto } from '../../../../types/purchaseOrderViewPageTypes';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import { ErrorsEnum } from '../../../../../../../core/enums/errorsEnum';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import { useAppSelector } from '../../../../../../../store/hooks';
import {
  integerQuantityMeasureValuesSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import { StringFunctionType } from '../../../../../../../core/types/coreTypes';

type ReceivedNumericFieldProps = {
  currentPart?: PurchaseOrderPartDto,
  value: string,
  setValue: StringFunctionType,
  setError: StringFunctionType,
  err: string,
  disabled: boolean,
  label: string,
  tip: string,
}

const ReceivedNumericField: React.FC<ReceivedNumericFieldProps> = ({
  currentPart, value, setError, setValue, err, disabled, label, tip,
}) => {
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const measure = useMemo(() => unitsOfMeasure.find((el) => el.value === currentPart?.unitOfMeasure)?.label, [unitsOfMeasure, currentPart]);
  return (
    <>
      {integerMeasures.includes(currentPart?.unitOfMeasure)
        ? <RegularInputNumber
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            if (!val) {
              setError(ErrorsEnum.REQUIRED);
            } else {
              err && setError('');
            }
            setValue(val);
          }}
          max={currentPart?.requestedQuantity || 0}
          label={label}
          postfix={measure}
          tip={tip}
          error={err}
          disabled={disabled}
        />
        : <DecimalInputNumber
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            if (!val) {
              setError(ErrorsEnum.REQUIRED);
            } else {
              err && setError('');
            }
            setValue(val.replace(',', '.'));
          }}
          max={currentPart?.requestedQuantity || 0.00}
          label={label}
          postfix={measure}
          tip={tip}
          error={err}
          disabled={disabled}
        />}
    </>
  );
};

export default ReceivedNumericField;
