import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import { columns, hiddenKeys, nowrapKeys } from './utils';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import Tbody from '../../../../core/components/table-components/Tbody';
import StockPartRequestLine from '../../../stock-part-request-view/components/StockPartRequestLine';
import StockPartRequestChildLine from '../../../stock-part-request-view/components/StockPartRequestChildLine';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import { useSelectAll } from '../../../../core/hooks/useSelectAll';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  stockPartRequestsLinesViewFiltersSelector,
  stockPartRequestsLinesViewTableSelector,
} from '../../../../store/selectors/stockPartRequestsSelector';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../permissions-handling/permissionHook';
import { NumberFunctionType, SearchTableFilterType } from '../../../../core/types/coreTypes';
import {
  setStockPartRequestsLinesViewFilters,
} from '../../../../store/slices/stockPartRequestSlice';
import Button from '../../../../core/components/button/Button';
import Pagination from '../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { useStockPartRequestsPaging } from '../../hooks/useStockPartRequestsPaging';
import {
  generatePurchaseOrderBulkThunk,
} from '../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { useViewByLinesCellAndActionsRender } from '../../hooks/useViewByLinesCellAndActionsRender';
import './StockPartRequestLinesViewTable.scss';

type StockPartRequestLinesViewTableProps = {
  setDistributeQtyHandle: NumberFunctionType,
  setEditReadyCollected: NumberFunctionType,
  setIsMarkNotFromStock: NumberFunctionType,
  setConfirmReturnedParts: NumberFunctionType,
  setRemapToExistingPart: NumberFunctionType,
  setViewDetails: NumberFunctionType,
}

const StockPartRequestLinesViewTable = ({
  setDistributeQtyHandle,
  setEditReadyCollected,
  setIsMarkNotFromStock,
  setConfirmReturnedParts,
  setRemapToExistingPart,
  setViewDetails,
}: StockPartRequestLinesViewTableProps) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const filters = useAppSelector(stockPartRequestsLinesViewFiltersSelector);
  const {
    items, allAvailableForPurchaseOrderGenerationIds, totalCount,
  } = useAppSelector(stockPartRequestsLinesViewTableSelector);
  const allowedToManage = usePermission(PermissionEnum.StockPartRequestEditPartLineFieldsAndUnlockPartLineActions);

  const { currentPaging, currentTotalCount, pageChangeHandle } = useStockPartRequestsPaging();

  const {
    isAll, isIndeterminate, checkedKeys,
    checkAllHandle, checkRowHandle,
  } = useSelectAll(allAvailableForPurchaseOrderGenerationIds);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    linkedEntityNumber: filters?.linkedEntityNumber,
    productNumber: filters?.productNumber,
    linkedEntityStatus: filters?.linkedEntityStatus,
  });

  const onChangeSearchHandle = useCallback((v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setStockPartRequestsLinesViewFilters({
        ...filters,
        [field]: undefined,
      }));
    }
    // eslint-disable-next-line
  }, [filters, searchVl]);
  const searchPressHandle = useCallback((field: string) => {
    dispatch(setStockPartRequestsLinesViewFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
    // eslint-disable-next-line
  }, [filters, searchVl]);

  const {
    actionsRender, renderCellValue, deleted, archived,
  } = useViewByLinesCellAndActionsRender(
    setDistributeQtyHandle,
    setEditReadyCollected,
    setIsMarkNotFromStock,
    setConfirmReturnedParts,
    setRemapToExistingPart,
    setViewDetails,
  );

  const generateHandle = useCallback(() => {
    dispatch(generatePurchaseOrderBulkThunk({
      partRequestLineIds: checkedKeys,
      navigate,
    }));
    // eslint-disable-next-line
  }, [checkedKeys, navigate]);
  return (
    <>
      <div className="actions-and-paging">
        <Button
          label="Create purchase order"
          onClick={generateHandle}
          disabled={!checkedKeys.length}
        />
        <Pagination
          currentPage={currentPaging.page}
          pageSize={currentPaging.pageSize}
          totalItems={currentTotalCount}
          showSizeChanger
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          showTotal
          onChange={(page, pageSize) => pageChangeHandle(page, pageSize)}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      <TableNew scrollOffset={[20, 10]}>
        <Thead>
          <Tr>
            <ThNew checkbox>
              <Checkbox
                checked={isAll}
                indeterminate={isIndeterminate}
                onChange={checkAllHandle}
                disabled={!allAvailableForPurchaseOrderGenerationIds.length}
                table
              />
            </ThNew>
            {columns.map((col) => <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalCount}
              className={`stockPartRequestLinesViewTh--${col.dataIndex}`}
              disabled={totalCount === 0}
              onChangeSearchHandle={onChangeSearchHandle}
              searchPressHandle={searchPressHandle}
              searchVl={searchVl}
              tableFilters={filters}
            />)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Tr>
        </Thead>
        <Tbody>
          {totalCount > 0
            ? <>
              {items.map((el) => <React.Fragment key={el.id}>
                <StockPartRequestLine
                  el={el}
                  key={el.id}
                  hiddenKeys={[...hiddenKeys, 'childLines']}
                  nowrapKeys={nowrapKeys}
                  renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, el)}
                  actionsRender={(status, sourceId) => actionsRender(status, sourceId)}
                  deletedStatus={deleted}
                  archivedStatus={archived}
                  allowedToManage={allowedToManage}
                  checked={checkedKeys.includes(el.id)}
                  checkRowHandle={checkRowHandle}
                  isCheckboxRequired
                />
                {el.childLines && el.childLines.length > 0
                      && el.childLines.map((ch, i) => <StockPartRequestChildLine
                        key={i}
                        childLine={ch}
                        hiddenKeys={hiddenKeys}
                        nowrapKeys={nowrapKeys}
                        renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, ch)}
                        actionsRender={(status, sourceId) => actionsRender(status, sourceId, true)}
                        deletedStatus={deleted}
                        archivedStatus={archived}
                        allowedToManage={allowedToManage}
                        isCheckboxRequired
                        checked={checkedKeys.includes(ch.id)}
                        checkRowHandle={checkRowHandle}
                      />)}
              </React.Fragment>)}
            </>
            : <EmptyTableSection text="No records" colSpan={columns.length + 1} />}
        </Tbody>
      </TableNew>
    </>
  );
};

export default StockPartRequestLinesViewTable;
