import React from 'react';
import { QuoteLineTimelineType } from '../../../../types/enquiryDetailsTypes';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import PriceSourceTag from '../../../../../../common/components/price-source-tag/PriceSourceTag';

type QuoteLineGroupCellProps = {
  cell: QuoteLineTimelineType;
}

const QuoteLineGroupCell: React.FC<QuoteLineGroupCellProps> = ({ cell }) => {
  const truncatedValues = ['quoteCategoryName', 'productNumber', 'productName', 'notes'];
  const nowrapValues = ['number', 'quantity', 'unitPrice', 'priceSources', 'discount', 'netPrice', 'tax', 'weight'];
  const fields = {
    number: cell.number,
    quoteCategoryName: cell.quoteCategoryName,
    productNumber: cell.productNumber || '-',
    productName: cell.productName,
    notes: cell.notes,
    quantity: `${cell.quantity || 0} pcs`,
    unitPrice: `£ ${cell.unitPriceString || '0.00'}`,
    priceSources: <div className="priceTags">{cell.priceSources?.map((el: number) => <PriceSourceTag source={el} />)}</div>,
    discount: cell.discount,
    netPrice: `£ ${cell.netPriceString || '0.00'}`,
    tax: cell.tax !== null ? `${cell.tax}%` : '-',
    weight: `${cell.weight || 0} kg`,
  };
  return (
    <>
      {Object.entries(fields).map(([key, value]) => (
        <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
          {truncatedValues.includes(key)
            ? <TruncateTooltip value={value as string} table />
            : value}
        </TdNew>
      ))}
    </>
  );
};

export default QuoteLineGroupCell;
