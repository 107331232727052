import React from 'react';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import NoteEvent from '../../../../../../common/components/activity-events/note/NoteEvent';
import { deleteJobTimelineNoteThunk, editJobTimelineNoteThunk } from '../../../../../../store/thunks/jobs/jobThunks';
import { BasicNoteEventProps } from '../../../../../../core/types/coreTypes';
import { useNoteActivityLog } from '../../../../../../common/hooks/useNoteActivityLog';

const JobNoteEvent: React.FC<BasicNoteEventProps> = ({
  id, content = '', initiator, disabledManageNotes,
}) => {
  const {
    dispatch,
    value,
    setValue,
    isEditModal,
    isConfirmationModal,
    setIsConfirmationModal,
    closeHandle,
    menuItems,
  } = useNoteActivityLog(content);

  const editHandle = () => {
    id && dispatch(editJobTimelineNoteThunk({
      id,
      content: value.trim(),
      closeModal: closeHandle,
    }));
  };

  const deleteHandle = () => {
    id && dispatch(deleteJobTimelineNoteThunk({
      id,
      closeModal: () => setIsConfirmationModal(false),
    }));
  };

  return (
    <NoteEvent
      content={content}
      menuItems={menuItems}
      value={value}
      setValue={setValue}
      isEditModal={isEditModal}
      closeEditModal={closeHandle}
      editHandle={editHandle}
      isConfirmationModal={isConfirmationModal}
      setIsConfirmationModal={setIsConfirmationModal}
      deleteHandle={deleteHandle}
      initiator={initiator}
      permission={PermissionEnum.JobEditDeleteNotesOfOtherUsersInActivityLog}
      disabledManageNotes={disabledManageNotes}
    />
  );
};

export default JobNoteEvent;
