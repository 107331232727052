import React from 'react';
import UsersList from './users-list/UsersList';
import { SettingsPageParamEnum } from '../../enums/settingsEnums';
import Permissions from '../../../permissions/Permissions';
import InfoMessages from '../../../info-messages/InfoMessages';

type SettingsContentProps = {
  view: string;
  permissionModule?: number;
}

const SettingsContent: React.FC<SettingsContentProps> = ({
  view, permissionModule,
}) => {
  const contentRenderFn = () => {
    switch (view) {
      case SettingsPageParamEnum.USERS:
        return <UsersList />;
      case SettingsPageParamEnum.PERMISSIONS:
        return <Permissions permissionModule={permissionModule} />;
      case SettingsPageParamEnum.INFO_MESSAGES:
        return <InfoMessages />;
      default: return null;
    }
  };

  return (
    <>
      {contentRenderFn()}
    </>
  );
};

export default SettingsContent;
