import React from 'react';
import classNames from 'classnames';
import { PricelistTablePartType } from '../../../../dashboard/types/pricelistTypes';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { routesPath } from '../../../../../core/enums/pathEnum';
import PrivateLineLink from '../../../../../common/components/private-line-link/PrivateLineLink';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';

type PricePartCellProps = {
  part: PricelistTablePartType,
  setEditModal: VoidFunctionType,
}

const PricePartCell: React.FC<PricePartCellProps> = ({ part, setEditModal }) => {
  const allowedToManage = usePermission(PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions);
  const editedKeys = ['costPrice', 'sellPrice'];
  const truncatedKeys = ['categoryId', 'subcategoryId', 'productNumber', 'productName'];
  const linkKeys = ['productNumber', 'productName'];

  const renderValue = (key: string, value: string | number | null) => {
    const truncatedContent = (
      <TruncateTooltip
        value={value !== null ? value : '-'}
        table
      />
    );

    if (truncatedKeys.includes(key)) {
      if (linkKeys.includes(key)) {
        return <PrivateLineLink to={`/${routesPath.STOCK}/part/${part.id}`} permission={PermissionEnum.StockPartViewPage}>
          {truncatedContent}
        </PrivateLineLink>;
      }
      return truncatedContent;
    }
    if (editedKeys.includes(key)) {
      return <span
        onClick={() => allowedToManage && setEditModal()}
        className={classNames({ 'table-clickable-content': allowedToManage })}
      >
        {value}
      </span>;
    }
    return value;
  };
  return (
    <>
      {Object.entries(part).map(([key, value]) => {
        if (key === 'id') return null;
        return <TdNew key={key}>
          {renderValue(key, value)}
        </TdNew>;
      })}
    </>
  );
};

export default PricePartCell;
