import React from 'react';
import AccountForm from './form/AccountForm';
import { useAppSelector } from '../../../../store/hooks';
import { currentUserSelector, userProfileSelector } from '../../../../store/selectors/accountSelectors';

const AccountDetails: React.FC = () => {
  const userProfile = useAppSelector(userProfileSelector);
  const currentUser = useAppSelector(currentUserSelector);

  return (
    <AccountForm
      userProfile={userProfile}
      emptyOffsetTop={!!currentUser?.employeeId}
    />
  );
};

export default AccountDetails;
