import React from 'react';
import { PartViewEventDtoBase } from '../../../../types/types';
import { useAppSelector } from '../../../../../../store/hooks';
import { PartViewEventTypesEnum } from '../../../../enums/PartTypesEnum';
import { partViewEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import PartNoteEvent from './PartNoteEvent';
import PartFieldUpdateEvent from './PartFieldUpdateEvent';
import PartQuantityEvents from './PartQuantityEvents';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { stockPartStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import SuitableForVehiclesChangeEvent
  from '../../../../../../common/components/activity-events/suitable-for-vehicles-change/SuitableForVehiclesChangeEvent';

type PartViewActivityEventProps = {
  item: PartViewEventDtoBase,
}

const PartViewActivityEvent: React.FC<PartViewActivityEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(partViewEventTypesSelector);
  const partStatuses = useAppSelector(stockPartStatusesSelector);

  const attachmentEvents = [
    PartViewEventTypesEnum.AddingAttachmentWithoutComment,
    PartViewEventTypesEnum.AddingAttachmentWithComment,
    PartViewEventTypesEnum.EditingAttachmentComment,
    PartViewEventTypesEnum.DeletingAttachment,
  ];
  const partQuantityEvents = [
    PartViewEventTypesEnum.PartQuantityCreation,
    PartViewEventTypesEnum.PartQuantityFieldUpdateOrAddingValue,
    PartViewEventTypesEnum.PartQuantityDeletion,
  ];

  const eventRenderFn = (item: PartViewEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.partEventType)?.label || '';
    switch (true) {
      case event === PartViewEventTypesEnum.Note:
        return <PartNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === PartViewEventTypesEnum.PartCreation:
        return <CreationEvent entity="Part" type="code" value={item.partCode || ''} />;
      case event === PartViewEventTypesEnum.PartDeletion:
        return <DeletionEvent entity="Part" />;
      case event === PartViewEventTypesEnum.PartStatusChange: {
        const prevStatus = partStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = partStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case event === PartViewEventTypesEnum.PartFieldUpdateOrAddingValue:
        return <PartFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (partQuantityEvents.includes(event as PartViewEventTypesEnum)):
        return <PartQuantityEvents eventName={event} item={item} />;
      case (attachmentEvents.includes(event as PartViewEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case event === PartViewEventTypesEnum.PartSuitableForVehiclesUpdateOrAddingValue: {
        const {
          oldSuitableForVehicles, oldSuitableForVehicleGroups, newSuitableForVehicles, newSuitableForVehicleGroups,
        } = item;
        return <SuitableForVehiclesChangeEvent data={{
          oldSuitableForVehicles, oldSuitableForVehicleGroups, newSuitableForVehicles, newSuitableForVehicleGroups,
        }}
        />; }
      default: return null;
    }
  };
  return <>{eventRenderFn(item)}</>;
};

export default PartViewActivityEvent;
