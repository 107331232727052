import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType,
  CoreAttachmentsFilters,
  initialCoreAttachmentsFilters, ResponseSingleResult, VoidFunctionType, AxiosErrorResponse, GetAttachmentsResponse, emptyPaging,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { pricelistAPI } from '../../../api/pricelistApi';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  setPricelistAttachments,
  setPricelistAttachmentsFilters,
} from '../../slices/pricelistSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { getPricelistById } from './pricelistDetailsThunks';
import { RootState } from '../../store';

export const getPricelistAttachments = createAsyncThunk<GetAttachmentsResponse,
{
  pricelistId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/PricelistAttachments',
  async ({ pricelistId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.fetchPricelistAttachments(pricelistId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePricelistAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, pricelistId: number, closeModal: VoidFunctionType, }>(
  'delete/SinglePricelistAttachment',
  async ({
    id, pricelistId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.deletePricelistAttachment(pricelistId, id);
      dispatch(getPricelistAttachments({
        pricelistId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setPricelistAttachmentsFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getPricelistById({ id: pricelistId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistAttachmentById = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ pricelistId: number, id: number, }
>('get/PricelistAttachmentById', async ({ pricelistId, id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await pricelistAPI.getPricelistAttachmentById(pricelistId, id);
    downloadCsv(response.data.data.uri);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const createPricelistAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{ id: number, list: CoreAttachListElType[], comment: string, onCancel: VoidFunctionType }
>('post/PricelistAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { pricelist } = getState() as RootState;
    const { pricelistAttachmentsFilters } = pricelist;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await pricelistAPI.postPricelistAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await pricelistAPI.postPricelistAttachment(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    id && dispatch(getPricelistAttachments({ pricelistId: +id, filters: pricelistAttachmentsFilters }));
    dispatch(getPricelistById({ id: +id }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editPricelistAttachment = createAsyncThunk<
ResponseSingleResult,
{ pricelistId: number, id: number, comment: string, onCancel: VoidFunctionType }
>('patch/PricelistAttachment', async ({
  pricelistId, id, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { pricelist } = getState() as RootState;
    const { pricelistAttachments } = pricelist;
    const response = await pricelistAPI.editPricelistAttachment(pricelistId, id, comment);
    const newArr = pricelistAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setPricelistAttachments({ ...pricelistAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultiplePricelistAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  pricelistId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultiplePricelistAttachments',
  async ({
    ids, pricelistId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { pricelist: { pricelistAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await pricelistAPI.fetchPricelistAttachments(pricelistId, {
        ...pricelistAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (allItemIds.length) {
        await pricelistAPI.deleteMultiplePricelistAttachment(pricelistId, filteredIds);
      }
      dispatch(setPricelistAttachmentsFilters({ ...pricelistAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getPricelistById({ id: pricelistId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
