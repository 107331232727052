import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { orderStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import { salesOrderDetailsSelector } from '../../../store/selectors/invoicingSelectors';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../core/components/tag/Tag';
import './SalesViewPageHead.scss';

const SalesViewPageHead: React.FC = () => {
  const statuses = useAppSelector(orderStatusSelector);
  const salesOrderDetails = useAppSelector(salesOrderDetailsSelector);

  const data = salesOrderDetails?.order;

  const statusLabelHandler = () => {
    return data?.status
      ? statuses.find((el) => el.value === data.status)?.label || '-'
      : '-';
  };

  const dateLabelHandler = (date?: string) => {
    return date ? localDateFormatHandler('DD-MMM-YYYY', date) : '-';
  };

  const properties = [
    { label: 'Order No:', value: data?.orderNumber || '-' },
    { label: 'Customer', value: data?.customerName || '-' },
    { label: 'Customer reference No', value: data?.customerReferenceNumber || '-' },
    { label: 'Status', value: statusLabelHandler() },
    { label: 'Created date', value: dateLabelHandler(data?.createdDate) },
    { label: 'Modified date', value: dateLabelHandler(data?.modifiedDate) },
  ];
  return (
    <>
      {properties.map((prop, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={prop.label} className="head__inner-info-item__label" />
        {prop.label === 'Status'
          ? <Tag
            label={<TruncateTooltip value={prop.value?.toString() || '-'} overlayClassName="tooltip-sm" />}
            truncate
          />
          : <TruncateTooltip
            value={prop.value ? prop.value.toString() : '-'}
            className="head__inner-info-item__value"
            overlayClassName="tooltip-sm"
          />}
      </div>)}
    </>
  );
};

export default SalesViewPageHead;
