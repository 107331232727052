import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { coreInfoSourcesSelector, nonCoreInfoSourcesSelector } from '../../../store/selectors/coreSelectors';
import {
  coreNonCoreInfoSourcesSelector,
  isPartReqVehManualSelector,
  isStockPartReqJobTypeSupplySelector,
  stockPartRequestDetailsSelector, stockPartRequestLinesSelector, stockPartRequestsLinesViewInitSelector,
  stockPartsToFillPartRequestFiltersSelector,
  stockPartsToFillPartRequestSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import { stockPartRequestLineStatusTransitionValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import {
  DraftLinePartsFiltersType,
  PartProductSourceTotalParams,
  PatchProductSourceType,
} from '../../../common/types/commonTypes';
import { productSourceDictionarySelector, productSourceTotalSelector } from '../../../store/selectors/sharedSelectors';
import {
  getStockPartsToPartReqThunk,
  putStockPartRequestLineThunk,
} from '../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import {
  getPartProductSourceDictionaryThunk,
  getPartProductSourceTotalThunk,
} from '../../../store/thunks/shared/sharedThunks';
import { getStockPartRequestLineStatusTransition } from '../../../store/thunks/core/coreStatusesThunks';

export const useFillInDraftLineAndSelectProductSource = (sourceId?: number, isLinesView = false) => {
  const dispatch = useAppDispatch();

  const partRequestDetails = useAppSelector(stockPartRequestDetailsSelector);
  const coreInfoSource = useAppSelector(coreInfoSourcesSelector);
  const nonCoreInfoSources = useAppSelector(nonCoreInfoSourcesSelector);
  const infoSources = useAppSelector(coreNonCoreInfoSourcesSelector);
  const { confirm } = useAppSelector(stockPartRequestLineStatusTransitionValuesSelector);

  const fillPartRequestFilters = useAppSelector(stockPartsToFillPartRequestFiltersSelector);
  const fillPartRequestParts = useAppSelector(stockPartsToFillPartRequestSelector);
  const productSourceTotal = useAppSelector(productSourceTotalSelector);
  const productSourceDictionary = useAppSelector(productSourceDictionarySelector);

  const isPartReqVehManual = useAppSelector(isPartReqVehManualSelector);
  const isJobTypeSupply = useAppSelector(isStockPartReqJobTypeSupplySelector);

  const [sourceName, setSourceName] = useState<string>('');
  const [infoSourceV, setInfoSourceV] = useState<number | undefined>(undefined);
  const [prodSourceModal, setProdSourceModal] = useState(false);
  const [filledPartId, setFilledPartId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!confirm) {
      dispatch(getStockPartRequestLineStatusTransition());
    }
    // eslint-disable-next-line
  }, []);

  const { items: stockPartRequestLines } = useAppSelector(stockPartRequestLinesSelector);
  const { items: stockPartRequestLinesView } = useAppSelector(stockPartRequestsLinesViewInitSelector);
  const items = isLinesView ? stockPartRequestLinesView : stockPartRequestLines;
  const sourceModalLines = items.map((l) => ({
    id: l.id,
    name: l.productName || '',
    unitOfMeasure: l.unitOfMeasure,
    partId: l.partId,
    stockQuantity: 0,
    pricelists: l.requestedPricelists ? l.requestedPricelists?.map((el) => ({ ...el, quantity: 0 })) : [],
  }));

  const fetchPartsToFillPartReq = (filters: DraftLinePartsFiltersType, isShow: boolean) => {
    let vehicleIds: number[] | undefined;
    if (!isPartReqVehManual && !isJobTypeSupply && partRequestDetails?.vehicleProfileId) {
      vehicleIds = [partRequestDetails.vehicleProfileId];
    } else if (!isPartReqVehManual && isJobTypeSupply) {
      vehicleIds = (isShow && partRequestDetails?.vehicleProfileId) ? [partRequestDetails.vehicleProfileId] : undefined;
    } else {
      vehicleIds = undefined;
    }
    const values = {
      keyword: filters.keyword,
      isCorePart: filters.isCorePart,
      page: filters.page,
      pageSize: filters.pageSize,
      order: filters.order,
      partCode: filters.partCode,
      manufacturerCode: filters.manufacturerCode,
      name: filters.name,
      description: filters.description,
      vehicleIds,
    };
    dispatch(getStockPartsToPartReqThunk({
      filters: values,
    }));
  };

  const getRemapProductSourceDictionaryHandle = (partId: number, openModal: VoidFunctionType) => {
    if (partRequestDetails) {
      dispatch(getPartProductSourceDictionaryThunk({
        partId,
        params: {
          businessArea: partRequestDetails.businessArea,
          customerId: partRequestDetails.customerId,
          priceDate: partRequestDetails.orderDate,
        },
        openModal,
      }));
    }
  };
  const getRemapProductSourceTotalHandle = (partId: number, params: PartProductSourceTotalParams) => {
    if (partRequestDetails) {
      dispatch(getPartProductSourceTotalThunk({
        partId,
        params: {
          ...params,
          priceDate: partRequestDetails.orderDate,
        },
      }));
    }
  };

  const createPriceSourceHandle = (data: Partial<PatchProductSourceType>, closeModal: VoidFunctionType) => {
    const isAllConditions = partRequestDetails?.id && sourceId && filledPartId && confirm;
    isAllConditions && dispatch(putStockPartRequestLineThunk({
      partRequestId: partRequestDetails.id,
      lineId: sourceId,
      data: {
        partId: filledPartId,
        statusTransition: confirm,
        stockQuantity: data.stockQuantity || null,
        pricelists: data.pricelists || null,
      },
      closeModal,
      customMessage: 'Part item was successfully remapped.',
    }));
  };

  const cancelProductSourceModal = () => {
    setProdSourceModal(false);
    if (sourceName) {
      setSourceName('');
    }
    setFilledPartId(undefined);
  };

  return {
    sourceModalLines,
    coreInfoSource,
    nonCoreInfoSources,
    infoSources,
    fillPartRequestFilters,
    fillPartRequestParts,
    productSourceTotal,
    productSourceDictionary,
    fetchPartsToFillPartReq,
    getRemapProductSourceDictionaryHandle,
    getRemapProductSourceTotalHandle,
    createPriceSourceHandle,
    cancelProductSourceModal,
    sourceName,
    setSourceName,
    filledPartId,
    setFilledPartId,
    prodSourceModal,
    setProdSourceModal,
    infoSourceV,
    setInfoSourceV,
  };
};
