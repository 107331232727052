import React, {
  useCallback, useLayoutEffect, useRef, useState,
} from 'react';
import { Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import classNames from 'classnames';
import { useAppSelector } from '../../../store/hooks';
import { collapsedSidebarSelector } from '../../../store/selectors/coreSelectors';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import { VoidFunctionType } from '../../../core/types/coreTypes';

type TruncateTooltipProps = {
  value: string | number | undefined | null;
  onClick?: VoidFunctionType,
  className?: string;
  overlayClassName?: string,
  mouseEnterDelay?: number,
  mouseLeaveDelay?: number,
  hideTooltipOnMobile?: boolean,
  placement?: TooltipPlacement,
  table?: boolean,
}

export const TruncateTooltip: React.FC<TruncateTooltipProps> = ({
  value, onClick, className, overlayClassName, mouseEnterDelay, mouseLeaveDelay, hideTooltipOnMobile = false, placement, table,
}) => {
  const ref = useRef<HTMLSpanElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);
  const { windowWidth } = useScreenWitdh();
  const collapsedSidebar = useAppSelector(collapsedSidebarSelector);

  const isTruncatedHorizontally = (element: HTMLSpanElement) => element && element.clientWidth < element.scrollWidth;
  const isTruncatedVertically = (element: HTMLSpanElement) => element && element.clientHeight < element.scrollHeight;
  const isAnyTruncation = useCallback(
    (element: HTMLSpanElement) => isTruncatedHorizontally(element) || isTruncatedVertically(element),
    [],
  );

  useLayoutEffect(() => {
    if (hideTooltipOnMobile && windowWidth <= responsiveEndpointsEnum.LG) {
      setIsTruncated(false);
    } else {
      if (ref.current && isAnyTruncation(ref.current)) {
        setIsTruncated(true);
      } else {
        setIsTruncated(false);
      }
    }
  }, [hideTooltipOnMobile, isAnyTruncation, windowWidth, collapsedSidebar, value]);

  const handleClick = () => {
    onClick && onClick();
  };

  const element = <span
    ref={ref}
    onClick={handleClick}
    className={classNames(className, { 'truncate-table-value': table })}
  >
    {value}
  </span>;

  return isTruncated
    ? <Tooltip
      mouseEnterDelay={mouseEnterDelay}
      mouseLeaveDelay={mouseLeaveDelay}
      destroyTooltipOnHide
      title={value}
      overlayStyle={{ maxWidth: '400px' }}
      overlayClassName={classNames(overlayClassName, { 'truncate-table-tooltip': table })}
      placement={placement}
    >
      {element}
    </Tooltip> : element;
};
