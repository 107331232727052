import { routesPath } from './pathEnum';

export enum CreateKeyEnum {
  CREATE_ENQUIRY = 'createEnquiry',
  CREATE_USER = 'createUser',
  CREATE_CUSTOMER = 'createCustomer',
  CREATE_PRICELIST = 'createPricelist',
  CREATE_PART ='createPart',
  CREATE_PARTS_KIT='createPartsKit',
  CREATE_PURCHASE_ORDER = 'createPurchaseOrder',
  CREATE_SUPPLIER = 'createSupplier',
  CREATE_RECTIFICATION = 'createRectification',
  CREATE_JOB = 'createJob',
  CREATE_EMPLOYEE = 'createEmployee',
  CREATE_VEHICLE = 'createVehicle',
}

export const createUrls = {
  [CreateKeyEnum.CREATE_ENQUIRY]: routesPath.CREATE_ENQUIRY,
  [CreateKeyEnum.CREATE_USER]: routesPath.CREATE_USER,
  [CreateKeyEnum.CREATE_CUSTOMER]: routesPath.CREATE_CUSTOMER,
  [CreateKeyEnum.CREATE_PRICELIST]: routesPath.CREATE_PRICELIST,
  [CreateKeyEnum.CREATE_PART]: routesPath.CREATE_PART,
  [CreateKeyEnum.CREATE_PARTS_KIT]: routesPath.CREATE_PARTS_KIT,
  [CreateKeyEnum.CREATE_PURCHASE_ORDER]: routesPath.CREATE_PURCHASE_ORDER,
  [CreateKeyEnum.CREATE_SUPPLIER]: routesPath.CREATE_SUPPLIERS,
  [CreateKeyEnum.CREATE_RECTIFICATION]: routesPath.CREATE_RECTIFICATION,
  [CreateKeyEnum.CREATE_JOB]: routesPath.CREATE_JOB,
  [CreateKeyEnum.CREATE_EMPLOYEE]: routesPath.CREATE_EMPLOYEE,
  [CreateKeyEnum.CREATE_VEHICLE]: routesPath.CREATE_VEHICLE,
};
