import React, { useEffect } from 'react';
import OrderPartReqVehicle from './OrderPartReqVehicle';
import './OrderPartRequests.scss';
import OrderPartRequestTable from './OrderPartRequestTable';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import PartRequestSummarySection
  from '../../../../../common/components/common-part-request-components/PartRequestSummarySection';
import { orderSummarySectionDataSelector } from '../../../../../store/selectors/ordersSelector';
import { getPartRequestLineTypes } from '../../../../../store/thunks/coreThunk';
import { getRequestSubmissionStatuses } from '../../../../../store/thunks/core/coreStatusesThunks';

const OrderPartRequests: React.FC = () => {
  const dispatch = useAppDispatch();
  const { leftPartData, rightPartData } = useAppSelector(orderSummarySectionDataSelector);

  useEffect(() => {
    dispatch(getPartRequestLineTypes());
    dispatch(getRequestSubmissionStatuses());
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <OrderPartReqVehicle />
      <OrderPartRequestTable />
      <PartRequestSummarySection leftPartData={leftPartData} rightPartData={rightPartData} />
    </>
  );
};

export default OrderPartRequests;
