import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faLink } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';
import { DropdownActionType } from '../../../../../common/types/commonTypes';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { currentUserSelector } from '../../../../../store/selectors/accountSelectors';
import {
  vehicleStatusesSelector,
} from '../../../../../store/selectors/coreStatusesSelectors';
import {
  rectificationVehUiDataSelector,
  isRectificationStatusIsNotAllowedToLinkVehicle,
} from '../../../../../store/selectors/rectificationsSelector';
import { routesPath } from '../../../../../core/enums/pathEnum';
import { rectificationDetailsSelector } from '../../../../../store/selectors/sharedSelectors';
import { patchRectificationThunk } from '../../../../../store/thunks/rectification/rectificationViewPageThunks';
import LinkVehicleModal from '../../../../../common/components/link-vehicle-modal/LinkVehicleModal';
import { initSmallPaging } from '../../../../../core/types/coreTypes';

const RectificationVehicleSection: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const statusIsNotAllowedToLinkVehicle = useAppSelector(isRectificationStatusIsNotAllowedToLinkVehicle);
  const {
    vehicleId, name, info, vehicleStatus, regNumber, chassisNumber, vehicleLocation,
  } = useAppSelector(rectificationVehUiDataSelector);

  const [isLinkVehicle, setIsLinkVehicle] = useState<boolean>(false);
  const [allowedActions, setAllowedActions] = useState<DropdownActionType[]>([]);
  const vehicleStatusUi = vehicleStatuses.find((status) => status?.value === vehicleStatus)?.label;

  const actions = useMemo(() => {
    const basicList = vehicleStatus
      ? [{
        key: 'openVehiclePage',
        label: 'Open vehicle page',
        icon: <FontAwesomeIcon icon={faCar} />,
        onClick: () => navigate(`/${routesPath.VEHICLES}/${vehicleId}`),
        restrictedTo: PermissionEnum.VehicleViewPage,
      }]
      : [];
    return rectificationDetails?.rectification.isRepairVehicleFlow
      ? [
        {
          key: 'linkVehicle',
          label: 'Link vehicle',
          icon: <FontAwesomeIcon icon={faLink} />,
          onClick: () => setIsLinkVehicle(true),
          disabled: !!statusIsNotAllowedToLinkVehicle,
          restrictedTo: PermissionEnum.RectificationEditFields,
        },
        ...basicList,
      ]
      : basicList;
    // eslint-disable-next-line
  }, [
    statusIsNotAllowedToLinkVehicle,
    vehicleId, rectificationDetails?.rectification.status,
    rectificationDetails?.rectification.isRepairVehicleFlow,
  ]);

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const filteredAction = actions.filter((act) => permissions.includes(act.restrictedTo));
      setAllowedActions(filteredAction);
    }
  }, [currentUser, actions]);

  const initReqFilters = {
    ...initSmallPaging,
    onlyAvailableForRectificationCreation: true,
  };
  const selectNewVehicleFn = (vehId: number) => {
    const id = rectificationDetails?.rectification.id;
    id && dispatch(patchRectificationThunk({
      rectificationId: id,
      data: { vehicleId: vehId },
      onClose: () => {
        setIsLinkVehicle(false);
      },
    }));
  };
  return (
    <>
      <LinkVehicleModal
        visible={isLinkVehicle}
        onCancel={() => setIsLinkVehicle(false)}
        selectNewVehicleFn={selectNewVehicleFn}
        initVehiclesListReqFilters={initReqFilters}
        currentVehicleId={vehicleId}
      />
      <section className="info-grid__column">
        <h2 className="info-grid__title">Vehicle</h2>
        <VehicleSection
          name={name}
          info={info}
          status={vehicleStatusUi || 'Draft'}
          regNumber={regNumber}
          chassisNumber={chassisNumber}
          vehicleLocation={vehicleLocation}
          menuItems={allowedActions}
        />
      </section>
    </>
  );
};
export default RectificationVehicleSection;
