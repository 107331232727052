import React, { useState } from 'react';
import Button from '../../../../../../../core/components/button/Button';
import { PartsKitEventDtoBase } from '../../../../types/partsKitViewPageTypes';
import { useAppSelector } from '../../../../../../../store/hooks';
import { partsKitDetailsSelector } from '../../../../../../../store/selectors/partsKitSelectors';
import PartsKitTimelineComponentsGroupModal from './modal/PartsKitTimelineComponentsGroupModal';

type PartsKitComponentEventProps = {
  item: PartsKitEventDtoBase;
}

const PartsKitComponentEvent: React.FC<PartsKitComponentEventProps> = ({ item }) => {
  const [componentsModal, setComponentsModal] = useState(false);
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);
  return (
    <>
      <PartsKitTimelineComponentsGroupModal
        isVisible={componentsModal}
        setIsVisible={setComponentsModal}
        item={item}
      />
      Components
      {partsKitDetails
        ? <>
          {' '}
          for
          {' '}
          <b>{partsKitDetails.name}</b>
          {' '}
        </>
        : ' '}
      were updated
      {' '}
      <Button
        label="(see details)"
        onClick={() => setComponentsModal(true)}
        type="text"
      />
    </>
  );
};

export default PartsKitComponentEvent;
