import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { BooleanFunctionType, StringFunctionType } from '../../../../core/types/coreTypes';
import { DashboardViewsEnum, EnquiryModesEnum } from '../../enums/dashboardViewsEnum';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { routesPath } from '../../../../core/enums/pathEnum';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useEnquiryFiltersHandle } from '../../hooks/useEnquiryFiltersHandle';
import DashboardFilters from '../../../../common/components/dashboard-filters/DashboardFilters';

type EnquiriesFiltersProps = {
  setIsEyeModal: BooleanFunctionType;
  page: string | null,
  setPageParam: StringFunctionType,
}

const EnquiriesFilters: React.FC<EnquiriesFiltersProps> = ({
  setIsEyeModal,
  page,
  setPageParam,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();

  const {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    filtersList,
    keywordSearchParam,
    setInitSearchParams,
    getFilteredEnquiry,
    initDropdownSearch,
    onClear,
    stateFilters,
    currentBusinessArea,
  } = useEnquiryFiltersHandle();

  const options = [
    {
      value: EnquiryModesEnum.DASHBOARD,
      label: DashboardViewsEnum.DASHBOARD,
      restrictedTo: PermissionEnum.EnquiryDashboardPage,
    },
    {
      value: EnquiryModesEnum.SPECIFICATION,
      label: DashboardViewsEnum.SPECIFICATION,
      restrictedTo: PermissionEnum.TestModule,
    },
    {
      value: EnquiryModesEnum.CREATE_ENQUIRY,
      label: DashboardViewsEnum.CREATE_ENQUIRY,
      restrictedTo: PermissionEnum.EnquiryCreatePage,
    },
  ];
  const setPageParamHandle = (value: string) => {
    if (value === EnquiryModesEnum.CREATE_ENQUIRY) {
      navigate(`/${routesPath.CREATE_ENQUIRY}`);
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
    } else {
      setPageParam(value as string);
    }
  };

  useEffect(() => {
    if (currentBusinessArea && page === EnquiryModesEnum.DASHBOARD) {
      getFilteredEnquiry(stateFilters);
    }
    // eslint-disable-next-line
  }, [currentBusinessArea, page]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && keywordValue !== stateFilters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredEnquiry({ ...stateFilters, keyword: keywordValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (stateFilters.keyword && !keywordValue?.trim()) {
        getFilteredEnquiry({ ...stateFilters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [keywordValue, stateFilters.keyword]);

  return (
    <DashboardFilters
      page={page}
      isFiltersOpen={isFiltersOpen}
      setIsFiltersOpen={setIsFiltersOpen}
      setPageParamHandle={setPageParamHandle}
      options={options}
      customButtons={[{ icon: faEye, onClick: () => setIsEyeModal(true) }]}
    >
      <KeywordFiltersSection
        searchValue={keywordValue}
        setSearchValue={(str) => {
          setKeywordValue(str);
          if (keywordSearchParam) {
            setInitSearchParams();
          }
        }}
        getFilteredResHandle={(values, field) => getFilteredEnquiry({ ...stateFilters, [field]: values as number[] })}
        onClear={onClear}
        initDropdownSearch={initDropdownSearch}
        filters={filtersList}
        isFullWidth
      />
    </DashboardFilters>
  );
};

export default EnquiriesFilters;
