import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import RectificationViewPageContainer from './RectificationViewPageContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { rectificationDetailsSelector } from '../../store/selectors/sharedSelectors';
import {
  fetchRectificationByIdThunk,
  getRectificationSummaryThunk,
} from '../../store/thunks/rectification/rectificationViewPageThunks';
import Spinner from '../../core/components/spinner/Spinner';
import ErrorPage from '../../common/components/error-pages/ErrorPage';
import { allowedBaForUserSelector } from '../../store/selectors/coreSelectors';

const RectificationViewPageWrapper: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const allowedBa = useAppSelector(allowedBaForUserSelector);

  useEffect(() => {
    if (id) {
      dispatch(fetchRectificationByIdThunk({ rectificationId: +id }));
      dispatch(getRectificationSummaryThunk({ rectificationId: +id }));
    }
    // eslint-disable-next-line
  }, [id, params]);

  if (!rectificationDetails) {
    return <Spinner />;
  }
  if (!allowedBa.find((el) => el.value === rectificationDetails.rectification.businessArea)) {
    return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
  }

  return (
    <RectificationViewPageContainer />
  );
};

export default RectificationViewPageWrapper;
