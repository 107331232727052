import React, { useEffect, useMemo, useState } from 'react';
import Button from '../../../../../../../../core/components/button/Button';
import Thead from '../../../../../../../../core/components/table-components/Thead';
import Tbody from '../../../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../../../core/components/table-components/Tr';
import EmptyTableSection from '../../../../../../../../core/components/table-components/EmptyTableSection';
import Modal from '../../../../../../../../core/components/modal/Modal';
import { BooleanFunctionType, initDataList } from '../../../../../../../../core/types/coreTypes';
import {
  InitialPartsKitComponentsTimelineGroupFilters,
  PartsKitEventDtoBase,
} from '../../../../../types/partsKitViewPageTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../../../store/hooks';
import {
  kitComponentsColumnsSelector,
  partsKitTimelineComponentsGroupFiltersSelector,
  partsKitTimelineComponentsGroupSelector,
} from '../../../../../../../../store/selectors/partsKitSelectors';
import {
  setPartsKitTimelineComponentsGroup, setPartsKitTimelineComponentsGroupFilters,
} from '../../../../../../../../store/slices/partsKitSlice';
import {
  getPartsKitTimelineComponentsGroupAfterThunk,
  getPartsKitTimelineComponentsGroupBeforeThunk,
} from '../../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import PartsKitTimelineComponentsGroupColumns from './PartsKitTimelineComponentsGroupColumns';
import PartsKitTimelineComponentsGroupCell from './PartsKitTimelineComponentsGroupCell';
import TableNew from '../../../../../../../../core/components/table-components/table-new/TableNew';
import './PartsKitTimelineComponentsGroupModal.scss';
import { partsKitComponentsOrderingSelector } from '../../../../../../../../store/selectors/coreOrderingSelectors';

type PartsKitTimelineComponentsGroupModalProps = {
  isVisible: boolean,
  setIsVisible: BooleanFunctionType,
  item: PartsKitEventDtoBase;
}

const PartsKitTimelineComponentsGroupModal: React.FC<PartsKitTimelineComponentsGroupModalProps> = ({ isVisible, setIsVisible, item }) => {
  const [mode, setMode] = useState<string>('after');
  const dispatch = useAppDispatch();
  const kitComponentsColumns = useAppSelector(kitComponentsColumnsSelector);
  const groupFilters = useAppSelector(partsKitTimelineComponentsGroupFiltersSelector);
  const partsKitTimelineComponentsGroup = useAppSelector(partsKitTimelineComponentsGroupSelector);
  const partsKitComponentsOrdering = useAppSelector(partsKitComponentsOrderingSelector);

  const lines = useMemo(
    () => (mode === 'before' ? partsKitTimelineComponentsGroup.before : partsKitTimelineComponentsGroup.after),
    [mode, partsKitTimelineComponentsGroup.before, partsKitTimelineComponentsGroup.after],
  );
  const linesArr = useMemo(() => lines.items.map((el, i) => ({
    number: i + 1,
    categoryName: el.categoryName,
    partNumber: el.partNumber || '-',
    partName: el.partName || '-',
    quantity: el.quantity || 0,
    unitOfMeasure: el.unitOfMeasure,
    minUnitPrice: el.minUnitPrice || 0,
    maxUnitPrice: el.maxUnitPrice || 0,
    minNetPrice: el.minNetPrice || 0,
    maxNetPrice: el.maxNetPrice || 0,
    tax: el.tax || 0,
    weight: el.weight || 0,
  })), [lines.items]);

  useEffect(() => {
    if (isVisible) {
      if (partsKitComponentsOrdering) {
        if (mode === 'before') {
          const values = { ...groupFilters, page: 1, order: { field: partsKitComponentsOrdering[0].value, isAsc: true } };
          dispatch(setPartsKitTimelineComponentsGroupFilters(values));
          item.groupId && dispatch(getPartsKitTimelineComponentsGroupBeforeThunk({
            lineGroupId: item.groupId,
            filters: values,
          }));
        } else {
          const values = { ...groupFilters, page: 1, order: { field: partsKitComponentsOrdering[0].value, isAsc: true } };

          dispatch(setPartsKitTimelineComponentsGroupFilters(values));
          item.groupId && dispatch(getPartsKitTimelineComponentsGroupAfterThunk({
            lineGroupId: item.groupId,
            filters: values,
          }));
        }
      }
    }
    // eslint-disable-next-line
  }, [isVisible, item.groupId, mode]);
  const closeModal = () => {
    setIsVisible(false);
    dispatch(setPartsKitTimelineComponentsGroupFilters(InitialPartsKitComponentsTimelineGroupFilters));
    dispatch(setPartsKitTimelineComponentsGroup({ before: initDataList, after: initDataList }));
  };

  return (
    <Modal visible={isVisible} onCancel={closeModal} title="Parts kit components changes" width={1000}>
      <div className="event-modal-radio">
        <Button
          onClick={() => setMode('before')}
          label="Before"
          designType="light-inverse"
          isActivated={mode === 'before'}
          offsetRight
        />
        <Button
          onClick={() => setMode('after')}
          label="After"
          designType="light-inverse"
          isActivated={mode === 'after'}
        />
      </div>
      <TableNew small>
        <Thead>
          <PartsKitTimelineComponentsGroupColumns />
        </Thead>
        <Tbody>
          {linesArr.length > 0
            ? linesArr.map((d, i) => <Tr key={i}>
              <PartsKitTimelineComponentsGroupCell cell={d} />
            </Tr>)
            : <EmptyTableSection text="No records" colSpan={kitComponentsColumns.length} />}
        </Tbody>
      </TableNew>
    </Modal>
  );
};

export default PartsKitTimelineComponentsGroupModal;
