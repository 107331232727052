import React, { useEffect, useState } from 'react';
import PermissionsTable from './components/PermissionsTable';
import './Permissions.scss';
import { useAppDispatch } from '../../store/hooks';
import { getPermission, getPermissionRoles } from '../../store/thunks/settings/permissionsThunks';

type PermissionsProps = {
  permissionModule?: number;
}

const Permissions: React.FC<PermissionsProps> = ({ permissionModule }) => {
  const dispatch = useAppDispatch();
  const [permissionsLoading, setPermissionsLoading] = useState(true);
  const [rolesLoading, setRolesLoading] = useState(true);

  useEffect(() => {
    if (permissionModule) {
      dispatch(getPermission({
        module: permissionModule,
        onFinish: () => setPermissionsLoading(false),
      }));
      dispatch(getPermissionRoles({
        module: permissionModule,
        onFinish: () => setRolesLoading(false),
      }));
    }
    // eslint-disable-next-line
  }, [permissionModule]);
  return (
    <>
      {!permissionsLoading && !rolesLoading && <PermissionsTable permissionModule={permissionModule} /> }
    </>
  );
};

export default Permissions;
