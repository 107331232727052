import React from 'react';
import InfoMessagesList from './components/InfoMessagesList';
import './InfoMessages.scss';

const InfoMessages: React.FC = () => {
  return (
    <InfoMessagesList />
  );
};

export default InfoMessages;
