import { z } from 'zod';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { EMAIL_REGEX, PASSWORD_REGEX } from '../../../core/utils/regex';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const ResetPasswordSchema = z.object({
  email: RequiredStringSchema.trim()
    .regex(EMAIL_REGEX, ErrorsEnum.INVALID_EMAIL),
  newPassword: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(100, maxLengthMessageHandle(100))
    .regex(
      PASSWORD_REGEX,
      'Please use a strong password.',
    ),
  confirmNewPassword: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(100, maxLengthMessageHandle(100)),
}).refine((data) => data.newPassword === data.confirmNewPassword, {
  path: ['confirmNewPassword'],
  message: 'Entered passwords do not match.',
});

export type ResetPasswordFields = z.infer<typeof ResetPasswordSchema>;

export const resetPasswordDefaults: ResetPasswordFields = {
  email: '',
  newPassword: '',
  confirmNewPassword: '',
};
