import _ from 'lodash';
import { VoidFunctionType } from '../types/coreTypes';

export const getDifferenceBetweenValues = <T>(main: T, compareWith: T): boolean => {
  return main !== compareWith;
};

export const getDifferenceBetweenArrayValues = (main: number[] | string[], compareWith: number[] | string[]): boolean => {
  const isEqual = _.isEqual(main, compareWith);
  return !isEqual;
};

export const compareValuesHandle = <T>(
  data: Partial<T>,
  key: keyof T,
  details: T,
  handler: VoidFunctionType,
  deepCompare?: boolean,
  isFieldOptional?: boolean,
) => {
  let isNotEqual;
  if (deepCompare) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    isNotEqual = getDifferenceBetweenArrayValues(details[key], data[key]);
  } else {
    const finalV = (isFieldOptional && details[key] === null) ? (data[key] || null) : data[key];
    isNotEqual = getDifferenceBetweenValues(details[key], finalV);
  }
  isNotEqual && handler();
};
