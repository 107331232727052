import { useParams } from 'react-router-dom';
import { FieldErrors, UseFormClearErrors, UseFormSetValue } from 'react-hook-form';
import { useState } from 'react';
import { EnquiryDto } from '../../enquiries/types/enquiryTypes';
import { compareValuesHandle } from '../../../core/utils/getDifferenceBetweenObjects';
import { CommonDetailsFields, PutEnquiryLocationType } from '../types/enquiryDetailsTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { enquiryDetailsSelector, enquiryDetailsUiSelector } from '../../../store/selectors/enquiriesSelector';
import { AddressFields } from '../../../common/types/commonTypes';
import { clearOtherFieldsErrorFn } from '../../../core/utils/clearOtherFieldsErrorFn';
import { editEnquiryByIdThunk } from '../../../store/thunks/enquiry/detailsThunks';

export const useEditEnquiryDetails = (
  setValue: UseFormSetValue<CommonDetailsFields>,
  clearErrors: UseFormClearErrors<CommonDetailsFields>,
  errors: FieldErrors<CommonDetailsFields>,
) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const data = useAppSelector(enquiryDetailsSelector);
  const details = data?.enquiry;
  const detailsValues = useAppSelector(enquiryDetailsUiSelector);

  const [isLocationModal, setIsLocationModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);

  const fieldsToValidate = ['estimatedCost', 'description', 'quoteDate', 'specDescription', 'rejectionReason', 'customerReferenceNumber'];
  const clearOtherErrorsFn = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, details, setValue);
  };

  const setEnquiryDefaults = () => {
    if (details) {
      setValue('enquirySource', details.enquirySource);
      setValue('priorityLevel', details.priorityLevel);
      setValue('estimatedCost', details.estimatedCost ? details.estimatedCost.toString() : '0.00');
      setValue('jobType', details.jobType);
      setValue('description', details.description || '');
      setValue('specDescription', details.specDescription || '');
      setValue('rejectionReason', details.rejectionReason || '');
      setValue('quoteDate', details.quoteDate);
      setValue('customerReferenceNumber', details.customerReferenceNumber || '');
      setAddressFields({
        companyName: details.jobCompanyName || null,
        addressLine1: details.jobAddressLine1 || null,
        addressLine2: details.jobAddressLine2 || null,
        cityTown: details.jobCityTown || null,
        region: details.jobRegion || null,
        postalCode: details.jobPostalCode || null,
        telephone: details.jobTelephone || null,
      });
    }
  };

  const editField = (fields: Record<string, unknown>, key: keyof EnquiryDto, isOptional?: boolean, deepCompare?: boolean) => {
    if (details) {
      const handler = () => {
        id && dispatch(editEnquiryByIdThunk({
          id: +id,
          newValues: { ...detailsValues, ...fields },
          onClose: () => {
            if (isLocationModal) {
              setIsLocationModal(false);
            }
          },
        }));
      };
      compareValuesHandle<EnquiryDto>(fields, key, details, handler, deepCompare, isOptional);
    }
  };

  const editableJobLocationKeys = [
    'jobType', 'jobCompanyName', 'jobAddressLine1',
    'jobAddressLine2', 'jobCityTown', 'jobRegion', 'jobPostalCode', 'jobTelephone',
  ];
  const changeJobLocationDetails = (fields: PutEnquiryLocationType) => {
    if (details) {
      const keysNotEqual = editableJobLocationKeys
        .some((key) => detailsValues[key as keyof PutEnquiryLocationType] !== fields[key as keyof PutEnquiryLocationType]);
      if (keysNotEqual) {
        id && dispatch(editEnquiryByIdThunk({
          id: +id,
          newValues: {
            ...detailsValues,
            ...fields,
          },
          onClose: () => setIsLocationModal(false),
        }));
      } else {
        setIsLocationModal(false);
      }
    }
  };

  return {
    setEnquiryDefaults,
    editField,
    changeJobLocationDetails,
    isLocationModal,
    setIsLocationModal,
    addressFields,
    setAddressFields,
    clearOtherErrorsFn,
  };
};
