import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { accountAccountRolesSelector, leaveRequestTypesUiSelector } from './coreSelectors';
import { AccountUserRolesUiNames } from '../../core/enums/dictionariesEnums';
import { leaveRequestStatusesUiSelector } from './coreStatusesSelectors';

export const currentUserSelector = (state: RootState) => state.account.currentUser;
export const currentUserPermissionsSelector = createSelector(currentUserSelector, (user) => {
  return user?.userContextRole?.permissions;
});

export const userProfileSelector = (state: RootState) => state.account.userProfile;

export const inAppNotificationsFiltersSelector = (state: RootState) => state.account.inAppNotificationsFilters;
export const inAppNotificationsSelector = (state: RootState) => state.account.inAppNotifications;
export const inAppNotificationsCountSelector = (state: RootState) => state.account.inAppNotificationsCount;

export const accountLeaveRequestsFiltersSelector = (state: RootState) => state.account.accountLeaveRequestsFilters;
export const accountLeaveRequestsInitSelector = (state: RootState) => state.account.accountLeaveRequests;
export const accountLeaveRequestsListSelector = createSelector(
  accountLeaveRequestsInitSelector,
  leaveRequestTypesUiSelector,
  leaveRequestStatusesUiSelector,
  (data, types, statuses) => {
    return {
      pages: data.pages,
      totalCount: data.totalCount,
      items: data.items.map((el) => ({
        id: el.id,
        leaveType: types.find((type) => type.value === el.leaveType)?.label || '',
        startDate: localDateFormatHandler('DD/MM/YYYY', el.startDate || ''),
        endDate: localDateFormatHandler('DD/MM/YYYY', el.endDate || ''),
        leavePeriod: el.leavePeriod,
        status: el.status,
        statusName: statuses.find((status) => status.value === el.status)?.label || '',
        comment: el.comment,
      })),
    };
  },
);
export const accountLeaveRequestSelector = (state: RootState) => state.account.accountLeaveRequest;

export const isCurrentRoleAdminSelector = createSelector(accountAccountRolesSelector, currentUserSelector, (roles, user) => {
  const adminValue = roles.find((el) => el.label === AccountUserRolesUiNames.Admin)?.value;
  return adminValue === user?.userContextRole.role;
});
