import React from 'react';
import { faBoxesStacked } from '@fortawesome/free-solid-svg-icons';
import StockPartRequestLine from './StockPartRequestLine';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';
import {
  stockPartRequestDashboardUiSelector,
} from '../../../../store/selectors/stockPartRequestsSelector';
import { useAppSelector } from '../../../../store/hooks';
import { isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';
import SkeletonLinesList from '../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import PartRequestsSkeletonItem from '../part-requests-skeleton/PartRequestsSkeletonItem';

const StockPartRequestsList: React.FC = () => {
  const { totalCount, items } = useAppSelector(stockPartRequestDashboardUiSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <PartRequestsSkeletonItem />
        </SkeletonLinesList>
        : <ul className="lines-list">
          {totalCount > 0
            ? items.map((item) => <li key={item.id} className="lines-list__item">
              <StockPartRequestLine key={item.id} item={item} />
            </li>)
            : <EmptyContentList text="No part requests" icon={faBoxesStacked} />}
        </ul>}
    </>

  );
};

export default StockPartRequestsList;
