import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';

export type AccountLeaveRequestFields = {
  employeeId?: number | undefined,
  leaveType?: number | undefined,
  startDate: string,
  startDateDayPart?: number,
  endDate: string,
  endDateDayPart?: number,
  comment: string | null,
};

export const CommentSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};
