import React from 'react';
import { useSearchParams } from 'react-router-dom';
import RectificationGlobalFilters from './global-filters/sales/RectificationGlobalFilters';
import {
  ProductionReportsEnum,
  PurchasingReportsEnum,
  SalesReportsEnum,
  StockReportsEnum,
} from '../../enum/ReportsEnum';
import RectificationsByProductGlobalFilters from './global-filters/sales/RectificationsByProductGlobalFilters';
import NumberOfConvertedVehiclesGlobalFilters from './global-filters/sales/NumberOfConvertedVehiclesGlobalFilters';
import SalesOfficeEmployeeGlobalFilters from './global-filters/sales/SalesOfficeEmployeeGlobalFilters';
import FullStockGlobalFilters from './global-filters/stock/FullStockGlobalFilters';
import UnfinishedEntitiesGlobalFilters from './global-filters/sales/UnfinishedEntitiesGlobalFilters';
import RectificationsBySupplierGlobalFilters from './global-filters/sales/RectificationsBySupplierGlobalFilters';
import OutstandingOverduePurchaseOrdersGlobalFilters
  from './global-filters/purchasing/OutstandingOverduePurchaseOrdersGlobalFilters';
import SupplierSpendGlobalFilters from './global-filters/purchasing/SupplierSpendGlobalFilters';
import FitmentTimeGlobalFilters from './global-filters/production/FitmentTimeGlobalFilters';
import FitterReturnsGlobalFilters from './global-filters/purchasing/FitterReturnsGlobalFilters';
import SoldProductsByCustomerGlobalFilters from './global-filters/sales/SoldProductsByCustomerGlobalFilters';
import SalesReportByCustomerGlobalFilters from './global-filters/sales/SalesReportByCustomerGlobalFilters';
import SalesOffsiteWorkingGlobalFilters from './global-filters/sales/SalesOffsiteWorkingGlobalFilters';
import CancelledLinesGlobalFilters from './global-filters/purchasing/CancelledLinesGlobalFilters';

const ReportsGlobalFiltersContent: React.FC = () => {
  const [params] = useSearchParams();
  const report = params.get('report');

  const renderReportsGlobalFilters = () => {
    switch (report) {
      case ProductionReportsEnum.FITMENT_TIME_REPORT:
        return <FitmentTimeGlobalFilters />;
      case PurchasingReportsEnum.CANCELLED_LINES:
        return <CancelledLinesGlobalFilters />;
      case PurchasingReportsEnum.FITTER_RETURNS:
        return <FitterReturnsGlobalFilters />;
      case PurchasingReportsEnum.OUTSTANDING_OVERDUE_PURCHASE_ORDERS_REPORT:
        return <OutstandingOverduePurchaseOrdersGlobalFilters />;
      case PurchasingReportsEnum.SUPPLIER_SPEND:
        return <SupplierSpendGlobalFilters />;
      case SalesReportsEnum.RECTIFICATION_REPORT:
        return <RectificationGlobalFilters />;
      case SalesReportsEnum.RECTIFICATIONS_BY_PRODUCT:
        return <RectificationsByProductGlobalFilters />;
      case SalesReportsEnum.RECTIFICATIONS_BY_SUPPLIER:
        return <RectificationsBySupplierGlobalFilters />;
      case SalesReportsEnum.NUMBER_OF_CONVERTED_VEHICLES:
        return <NumberOfConvertedVehiclesGlobalFilters />;
      case SalesReportsEnum.OFF_SITE_WORKING:
        return <SalesOffsiteWorkingGlobalFilters />;
      case SalesReportsEnum.UNFINISHED:
        return <UnfinishedEntitiesGlobalFilters />;
      case SalesReportsEnum.SALES_OFFICE_EMPLOYEE_REPORT:
        return <SalesOfficeEmployeeGlobalFilters />;
      case SalesReportsEnum.SALES_REPORT_BY_CUSTOMER:
        return <SalesReportByCustomerGlobalFilters />;
      case SalesReportsEnum.SOLD_PRODUCT_BY_CUSTOMER:
        return <SoldProductsByCustomerGlobalFilters />;
      case StockReportsEnum.FULL_STOCK_REPORT:
        return <FullStockGlobalFilters />;
      default: return null;
    }
  };

  return <>{renderReportsGlobalFilters()}</>;
};

export default ReportsGlobalFiltersContent;
