import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faChartBar, faSliders } from '@fortawesome/free-solid-svg-icons';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { DropdownActionType } from '../../../common/types/commonTypes';
import { BooleanFunctionType, NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { stockPartStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';

export const useStockPartActions = (
  status: number | undefined,
  partTypes: {
    part: number | undefined,
    consumable: number | undefined,
  },
  setInOrOutOfStock: NumberFunctionType,
  setVisibleReorderModal: BooleanFunctionType,
  deletePartHandle: VoidFunctionType,
  setAdjustQtyModal: BooleanFunctionType,
) => {
  const { inStock, outOfStock } = useAppSelector(stockPartStatusesValuesSelector);

  const actions = [
    {
      label: 'Mark as In stock',
      key: 'mark-as-in-stock',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => inStock && setInOrOutOfStock(inStock),
      restrictedTo: PermissionEnum.StockPartEditFields,
      availableForTypes: [partTypes.consumable],
      disabled: !status || !inStock || status === inStock,
    },
    {
      label: 'Mark as Out of stock',
      key: 'mark-as-out-of-stock',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => outOfStock && setInOrOutOfStock(outOfStock),
      restrictedTo: PermissionEnum.StockPartEditFields,
      availableForTypes: [partTypes.consumable],
      disabled: !status || !outOfStock || status === outOfStock,
    },
    {
      label: 'Set reorder levels',
      key: 'set-reorder-levels',
      icon: <FontAwesomeIcon icon={faChartBar} />,
      onClick: () => setVisibleReorderModal(true),
      restrictedTo: PermissionEnum.StockPartSetReorderLevels,
      availableForTypes: [partTypes.part],
    },
    {
      label: 'Adjust quantity & prices',
      key: 'adjust-quantity',
      icon: <FontAwesomeIcon icon={faSliders} />,
      onClick: () => setAdjustQtyModal(true),
      restrictedTo: PermissionEnum.StockPartAdjustQuantity,
      availableForTypes: [partTypes.part],
    },
    {
      label: 'Delete part',
      key: 'delete-part',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => deletePartHandle(),
      restrictedTo: PermissionEnum.StockPartDelete,
      availableForTypes: [partTypes.part, partTypes.consumable],
    },
  ];
  const [allowedActions, setAllowedActions] = useState<DropdownActionType[]>([]);

  return {
    actions,
    allowedActions,
    setAllowedActions,
  };
};
