import { AddressFields, DeliveryFields } from '../../../../common/types/commonTypes';

export const convertFromAddressToDelivery = (address: AddressFields | null): DeliveryFields => ({
  deliveryCompanyName: address?.companyName || '',
  deliveryAddressLine1: address?.addressLine1 || '',
  deliveryAddressLine2: address?.addressLine2 || '',
  deliveryCityTown: address?.cityTown || '',
  deliveryRegion: address?.region || '',
  deliveryPostalCode: address?.postalCode || '',
  deliveryTelephone: address?.telephone || '',
});

export const convertFromDeliveryToAddress = (delivery: DeliveryFields): AddressFields => ({
  companyName: delivery.deliveryCompanyName || null,
  addressLine1: delivery.deliveryAddressLine1 || null,
  addressLine2: delivery.deliveryAddressLine2 || null,
  cityTown: delivery.deliveryCityTown || null,
  region: delivery.deliveryRegion || null,
  postalCode: delivery.deliveryPostalCode || null,
  telephone: delivery.deliveryTelephone || null,
});
