import React from 'react';
import classnames from 'classnames';
import Button from '../button/Button';
import './ButtonActions.scss';
import { VoidFunctionType } from '../../types/coreTypes';

type ButtonActionsProps = {
  cancelLabel?: string;
  createLabel: string;
  cancelClick: VoidFunctionType;
  createClick?: VoidFunctionType;
  middleActionLabel?: string;
  middleActionClick?: VoidFunctionType;
  disabledCreate?: boolean;
  disabledCancel?: boolean;
  className?: string;
  createType?: 'button' | 'submit' | 'reset';
  isLoading?: boolean;
  offsetTop?: boolean,
  middleActionDisabled?: boolean,
}

const ButtonActions: React.FC<ButtonActionsProps> = ({
  cancelLabel = 'Cancel',
  createLabel,
  cancelClick,
  createClick,
  middleActionClick,
  middleActionLabel,
  disabledCreate,
  disabledCancel,
  className,
  createType,
  isLoading,
  offsetTop,
  middleActionDisabled,
}) => {
  return (
    <div className={classnames(className, 'buttonActions', { 'buttonActions--offsetTop': offsetTop })}>
      <Button
        htmlType="button"
        label={cancelLabel}
        onClick={cancelClick}
        className="buttonActions__cancel"
        designType="light-inverse"
        disabled={disabledCancel || isLoading}
        isLoading={isLoading}
      />
      {middleActionLabel && <Button
        htmlType="button"
        label={middleActionLabel}
        onClick={middleActionClick}
        className="buttonActions__cancel"
        designType="dark"
        disabled={isLoading || middleActionDisabled}
        isLoading={isLoading}
      />}
      <Button
        htmlType={createType || 'button'}
        label={createLabel}
        onClick={createClick}
        className="buttonActions__create"
        designType="dark"
        disabled={disabledCreate || isLoading}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ButtonActions;
