import React from 'react';
import { fieldNameEventHandle, getReadableBoolean } from '../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  customerAccountTypesSelector,
  customerTypesSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const CustomerFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const accountTypes = useAppSelector(customerAccountTypesSelector);
  const customerTypes = useAppSelector(customerTypesSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'AccountType':
        return labelHandle(+value, accountTypes);
      case 'CustomerType':
        return labelHandle(+value, customerTypes);
      case 'IsShippingRequisiteSameAsBilling':
      case 'AlwaysUseRequisites':
        return getReadableBoolean(value);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default CustomerFieldUpdateEvent;
