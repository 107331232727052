import React from 'react';
import VehicleActivityLog from './VehicleActivityLog';
import VehicleDetailsSection from './VehicleDetailsSection';

const VehicleDetailsAndActivity: React.FC = () => {
  return (
    <div className="detailsWrap info-grid">
      <VehicleDetailsSection />
      <VehicleActivityLog />
    </div>
  );
};

export default VehicleDetailsAndActivity;
