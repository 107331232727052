import React from 'react';
import { PartViewEventDtoBase } from '../../../../types/types';
import { PartViewEventTypesEnum } from '../../../../enums/PartTypesEnum';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';
import { useAppSelector } from '../../../../../../store/hooks';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import { partQtyStatusesValuesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';

type PartQuantityEventsProps = {
  item: PartViewEventDtoBase,
  eventName: string,
}

const PartQuantityEvents: React.FC<PartQuantityEventsProps> = ({ item, eventName }) => {
  const { free } = useAppSelector(partQtyStatusesValuesSelector);
  const statusTextFn = (status: number) => {
    return status === free ? 'Free' : 'Reserved';
  };
  const renderTextEvent = () => {
    switch (eventName) {
      case PartViewEventTypesEnum.PartQuantityCreation:
        return <>
          <b>{statusTextFn(item.partQuantity.status)}</b>
          {' '}
          quantity record was created with
          {' '}
          <b>{localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.partQuantity.addedDate)}</b>
          {' '}
          added date
        </>;
      case PartViewEventTypesEnum.PartQuantityDeletion:
        return <>
          <b>{statusTextFn(item.partQuantity.status)}</b>
          {' '}
          quantity record with
          {' '}
          <b>{localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.partQuantity.addedDate)}</b>
          {' '}
          added date was deleted
        </>;
      default: return <>
        Field
        {' '}
        <b>{fieldNameEventHandle(item.field)}</b>
        {' '}
        was changed in
        {' '}
        <b>{item.status ? statusTextFn(item.status) : item.status}</b>
        {' '}
        quantity record with
        {' '}
        <b>{localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.addedDate)}</b>
        {' '}
        added date:
        {' '}
        {!!item.oldValue && <s>{`${item.oldValue}`}</s>}
        {!!item.newValue && <b>{` ${item.newValue}`}</b>}
      </>;
    }
  };
  return (
    <>{renderTextEvent()}</>
  );
};

export default PartQuantityEvents;
