import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseSingleResult, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { routesPath } from '../../../../core/enums/pathEnum';
import { CreatePartReqType, CreatePartResType } from '../../../../stock/create-part/types/createPartTypes';
import { stockApi } from '../../../../api/stockApi';
import { DuplicateValue, ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { PartFields } from '../../../../stock/create-part/utils/PartSchema';

export const createPartThunk = createAsyncThunk<
ResponseSingleResult<CreatePartResType> | null,
{
  data: CreatePartReqType,
  reset: VoidFunctionType,
  navigate: NavigateFunction,
  setError: UseFormSetError<PartFields>,
}
>('post/Part', async ({
  data, reset, navigate, setError,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.createPart(data);
    const { createdId, name } = response.data.data;
    dispatch(setSuccessMessage({ message: `Part ${name} was successfully created.` }));
    reset();
    navigate(`/${routesPath.STOCK}/part/${createdId}`, { replace: true });
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    if (errors.find((el) => el.key === 'PartCode')) {
      setError('partCode', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
    }
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
