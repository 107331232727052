import React from 'react';
import TotalPartQuantity from './components/total-part-quantity/TotalPartQuantity';
import PartReorderLevels from './components/part-reorder-levels/PartReorderLevels';
import './StockDashboard.scss';
import StockCardsSection from './components/cards-section/StockCardsSection';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

import {
  stockPurchaseOrdersLookupStatisticSelector,
  partRequestsStatisticLookupSelector,
} from '../../store/selectors/stockSelectors';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useLoadData } from './hooks/useLoadData';
import { setPurchaseOrdersFilters } from '../../store/slices/purchaseOrderSlice';
import {
  purchaseOrdersFiltersSelector,
} from '../../store/selectors/purchaseOrderSelectors';
import { stockPartRequestsFiltersSelector } from '../../store/selectors/stockPartRequestsSelector';
import { setStockPartRequestsFilters } from '../../store/slices/stockPartRequestSlice';

const StockDashboard = () => {
  const dispatch = useAppDispatch();
  const partRequests = useAppSelector(partRequestsStatisticLookupSelector);
  const partRequestFilters = useAppSelector(stockPartRequestsFiltersSelector);
  const purchaseOrders = useAppSelector(stockPurchaseOrdersLookupStatisticSelector);
  const purchaseOrdersFilters = useAppSelector(purchaseOrdersFiltersSelector);
  useLoadData();

  return (
    <div className="stockDashboard">
      <StockCardsSection
        title="Part requests"
        items={partRequests}
        permission={PermissionEnum.StockPartRequests}
        cardClick={(isActive, status) => {
          dispatch(setStockPartRequestsFilters({
            ...partRequestFilters,
            isActive,
            status: [status],
            page: 1,
          }));
        }}
      />
      <StockCardsSection
        title="Purchase orders"
        items={purchaseOrders}
        permission={PermissionEnum.StockPurchaseOrders}
        cardClick={(isActive: boolean, status: number) => dispatch(setPurchaseOrdersFilters({
          ...purchaseOrdersFilters,
          isActive,
          statuses: [status],
          page: 1,
        }))}
      />
      <div className="info-grid">
        <TotalPartQuantity />
        <PartReorderLevels />
      </div>
    </div>

  );
};

export default StockDashboard;
