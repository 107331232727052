import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Segmented from '../../../../core/components/segmented/Segmented';
import Select from '../../../../core/components/select/Select';
import { DetailsFiltersType } from '../../types/enquiryDetailsTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useFiltersItems } from '../../../enquiries/hooks/useFiltersItems';
import { editEnquiryByIdThunk, editEnquiryStatusThunk } from '../../../../store/thunks/enquiry/detailsThunks';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';
import { EnquiryResponseDto } from '../../../enquiries/types/enquiryTypes';
import { EnquiryStatusUiNamesEnum, PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ContentModeEnum } from '../../enums/ContentModeEnum';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import Selectable from '../../../../core/components/selectable/Selectable';
import { usersFilterLookupSelector } from '../../../../store/selectors/coreSelectors';
import usePermission from '../../../../permissions-handling/permissionHook';
import { useUsersDictionary } from '../../../../common/hooks/useUsersDictionary';

type EnquiryFiltersProps = {
  filters: DetailsFiltersType;
  mode: string | null;
  setModeParam: (value: ContentModeEnum | string) => void;
  details?: EnquiryResponseDto;
  setSentModal: BooleanFunctionType,
  setRejectModal: BooleanFunctionType,
  setAcceptedModal: BooleanFunctionType,
  allowedTabs: { label: string, value: ContentModeEnum }[],
}

const EnquiryFilters: React.FC<EnquiryFiltersProps> = ({
  filters, mode, setModeParam, details, setAcceptedModal,
  setRejectModal, setSentModal, allowedTabs,
}) => {
  const { id } = useParams();
  const allowedToManage = usePermission(PermissionEnum.EnquiryEditFields);
  const dispatch = useAppDispatch();
  const [status, setStatus] = useState<'error' | 'warning' | undefined>(undefined);
  const enquiryAssignees = useAppSelector(usersFilterLookupSelector);

  const { setUserSearch, getUsersFn } = useUsersDictionary();

  const enquiryCurrentStatus = details?.enquiry?.status;
  const enquiryStatusTransitions = details?.enquiry.statusTransitions;
  const { enquiryStatus } = useFiltersItems();

  const availableStatuses = enquiryStatusTransitions
    ? [...enquiryStatusTransitions, enquiryCurrentStatus]
    : [enquiryCurrentStatus];
  const statusTransitions = availableStatuses?.map((st) => {
    const curr = enquiryStatus.find((el) => el.value === st)?.label;
    return {
      label: curr || '',
      value: st || '',
    };
  });

  const { windowWidth } = useScreenWitdh();

  const editStatus = (status: number) => {
    const currentStatus = enquiryStatus.find((el) => el.value === enquiryCurrentStatus)?.label;
    const futureStatus = enquiryStatus.find((el) => el.value === status)?.label;
    if (currentStatus === EnquiryStatusUiNamesEnum.Finalised && futureStatus === EnquiryStatusUiNamesEnum.SentToCustomer) {
      setSentModal(true);
    } else if ((currentStatus === EnquiryStatusUiNamesEnum.Postponed || currentStatus === EnquiryStatusUiNamesEnum.SentToCustomer)
        && futureStatus === EnquiryStatusUiNamesEnum.Rejected) {
      setRejectModal(true);
    } else if (currentStatus === EnquiryStatusUiNamesEnum.SentToCustomer && futureStatus === EnquiryStatusUiNamesEnum.Accepted) {
      setAcceptedModal(true);
    } else {
      id && dispatch(editEnquiryStatusThunk({
        id: +id,
        rejectionReason: currentStatus === EnquiryStatusUiNamesEnum.Rejected && futureStatus === EnquiryStatusUiNamesEnum.New
          ? ''
          : undefined,
        statusTransition: status,
        isUpdateQuoteLines: mode === ContentModeEnum.ENQUIRY_QUOTE,
      }));
    }
  };
  return (
    <>
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG && allowedTabs.length > 0 && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={allowedTabs}
            value={mode}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className="view-details-tabs-actions view-details-tabs-actions desktop-sticky-filters ">
        {windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="view-details-tabs-actions__segmented"
          />
        )}
        <div className="view-details-tabs-actions__selects-wrapper">
          <Select
            value={filters.assignedTo}
            onChange={(value) => {
              id && dispatch(editEnquiryByIdThunk({
                id: +id,
                newValues: { assignedTo: value as number },
              }));
            }}
            options={enquiryAssignees}
            showSearch
            onSearch={(v) => {
              if (v.length <= 250) {
                status && setStatus(undefined);
                setUserSearch(v.trim());
              } else {
                setStatus('error');
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            onSelect={() => {
              setUserSearch('');
              status && setStatus(undefined);
              getUsersFn(undefined);
            }}
            className="view-details-tabs-actions__select--highlighted"
            parentRender
            disabled={!allowedToManage}
          />
          <Select
            value={filters.statusTransition}
            onChange={(value) => {
              editStatus(value as number);
            }}
            options={statusTransitions || []}
            parentRender
            disabled={!allowedToManage}
          />
        </div>
      </div>
    </>
  );
};

export default EnquiryFilters;
