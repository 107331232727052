import React from 'react';
import { PartsKitComponentGroupsItemDto } from '../../../../../types/partsKitViewPageTypes';
import { useAppSelector } from '../../../../../../../../store/hooks';
import { QuoteUnitsOfMeasureSelector } from '../../../../../../../../store/selectors/coreSelectors';
import TdNew from '../../../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../../../common/components/truncate-tooltip/TruncateTooltip';

type PartsKitTimelineComponentsGroupCellProps = {
  cell: Partial<PartsKitComponentGroupsItemDto>,
}

const PartsKitTimelineComponentsGroupCell: React.FC<PartsKitTimelineComponentsGroupCellProps> = ({ cell }) => {
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const priceStringHandle = (priceOne?: number | null, priceTwo?: number | null) => {
    if (priceOne === priceTwo) {
      return priceOne || '0.00';
    } else {
      return `${priceOne || '0.00'} ${priceTwo !== null && '-'} ${priceTwo || ''}`;
    }
  };

  const fields = {
    number: cell.number,
    categoryId: cell.categoryName,
    partNumber: cell.partNumber,
    partName: cell.partName,
    quantity: `${cell.quantity || 0} ${unitOfMeasures.find((el) => cell.unitOfMeasure === el.value)?.label}`,
    unitPrice: `£ ${priceStringHandle(cell.minUnitPrice, cell.maxUnitPrice)}`,
    netPrice: `£ ${priceStringHandle(cell.minNetPrice, cell.maxNetPrice)}`,
    tax: cell.tax !== null ? `${cell.tax}%` : '-',
    weight: `${cell.weight || 0} kg`,
  };

  const truncatedValues = ['categoryId', 'partNumber', 'partName'];
  const nowrapValues: string[] = ['number', 'quantity', 'unitPrice', 'netPrice', 'tax', 'weight'];

  return (
    <>
      {Object.entries(fields).map(([key, value]) => (
        <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
          {truncatedValues.includes(key)
            ? <TruncateTooltip value={value as string} table />
            : value}
        </TdNew>
      ))}
    </>
  );
};

export default PartsKitTimelineComponentsGroupCell;
