import React from 'react';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';

type SuitableForVehiclesChangeColumnsProps = {
  isGroup: boolean,
}

const SuitableForVehiclesChangeColumns: React.FC<SuitableForVehiclesChangeColumnsProps> = ({ isGroup }) => {
  const groupColumns = [
    { title: 'No', dataIndex: 'number' },
    { title: 'Group name', dataIndex: 'groupName' },
    { title: 'Number of vehicle profiles', dataIndex: 'numberOfVehicleProfiles' },
  ];
  const vehicleColumns = [
    { title: 'No', dataIndex: 'number' },
    { title: 'Code', dataIndex: 'code' },
    { title: 'Manufacturer', dataIndex: 'manufacturer' },
    { title: 'Model description', dataIndex: 'modelDescription' },
  ];
  const columns = isGroup ? groupColumns : vehicleColumns;

  return (
    <Thead>
      <Tr>
        {columns.map(({ title, dataIndex }) => (
          <ThNew key={dataIndex} className={`suitableForVehiclesChangeTh--${dataIndex}`}>{title}</ThNew>
        ))}
      </Tr>
    </Thead>
  );
};

export default SuitableForVehiclesChangeColumns;
