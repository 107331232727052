export enum PartTabsEnum {
  VEHICLESANDPRICES = 'vehicles-and-prices',
  PURCHASES = 'purchases',
  PARTSKITS = 'parts-kits',
  ACTIVITY = 'activity',
  ATTACHMENTS = 'attachments'
}

export enum PartModesLabelsEnum {
  VEHICLESANDPRICES = 'Vehicles & prices',
  PURCHASES = 'Purchase orders',
  PARTSKITS = 'Parts kits',
  ACTIVITY = 'Activity',
  ATTACHMENTS = 'Attachments'
}

export const PartTabs = [
  PartTabsEnum.VEHICLESANDPRICES,
  PartTabsEnum.PURCHASES,
  PartTabsEnum.PARTSKITS,
  PartTabsEnum.ACTIVITY,
  PartTabsEnum.ATTACHMENTS,
];

export enum PartQtyStatusEnum {
  Free = 'Free',
  Allocated = 'Allocated',
  Picked = 'Picked',
  Collected = 'Collected',
  Reserved = 'Reserved',
  Archived = 'Archived',
}

export enum PartAdjustQtyOrderingEnum {
  AddedDate = 'AddedDate',
  PurchaseOrderNumber = 'PurchaseOrderNumber',
  CollectionDate = 'CollectionDate',
  Owner = 'Owner',
}

export enum PartViewEventTypesEnum {
  Note = 'Note',
  PartCreation = 'PartCreation',
  PartFieldUpdateOrAddingValue = 'PartFieldUpdateOrAddingValue',
  PartStatusChange = 'PartStatusChange',
  PartDeletion = 'PartDeletion',
  PartQuantityCreation = 'PartQuantityCreation',
  PartQuantityFieldUpdateOrAddingValue = 'PartQuantityFieldUpdateOrAddingValue',
  PartQuantityDeletion = 'PartQuantityDeletion',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  PartSuitableForVehiclesUpdateOrAddingValue = 'PartSuitableForVehiclesUpdateOrAddingValue',
}
