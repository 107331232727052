import React from 'react';
import Tag from '../../../../core/components/tag/Tag';
import { GetCustomerResponseType } from '../../../customers-dashboard/types/customersDashboardTypes';
import { useAppSelector } from '../../../../store/hooks';
import { customerStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type CustomerHeadProps = {
  details: GetCustomerResponseType | null
}

const CustomerHead: React.FC<CustomerHeadProps> = ({ details }) => {
  const customerStatuses = useAppSelector(customerStatusesSelector);
  const status = customerStatuses.find((el) => el.value === details?.customer.customerStatus)?.label;
  const properties = [
    { label: 'Customer:', value: details?.customer.name },
    { label: 'Revenue', value: `£ ${details?.revenueString || 0}` },
    { label: 'Status', value: status ? status.toUpperCase() : '-' },
    { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.customer.createdAt || '') },
    { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.customer.updatedAt || '') },
  ];

  return (
    <>
      {properties.map((prop, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={prop.label} className="head__inner-info-item__label" />
        {prop.label === 'Status'
          ? <Tag label={prop.value?.toString() || '-'} />
          : <TruncateTooltip value={prop.value ? prop.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default CustomerHead;
