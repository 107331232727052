import React from 'react';
import JobAttachments from './attachments/JobAttachments';
import { JobViewModeParamsEnum } from '../../enums/JobViewEnums';
import JobTimeTracking from './time-tracking/JobTimeTracking';
import JobLinks from './links/JobLinks';
import JobDetailsAndActivity from './details/JobDetailsAndActivity';
import JobParts from './parts/JobParts';
import JobQualityCheck from './quality-check/JobQualityCheck';

type JobContentProps = {
  mode: string | null,
}

const JobContent: React.FC<JobContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case JobViewModeParamsEnum.JOB_DETAILS:
        return <JobDetailsAndActivity />;
      case JobViewModeParamsEnum.JOB_PARTS:
        return <JobParts />;
      case JobViewModeParamsEnum.JOB_QUALITY_CHECK:
        return <JobQualityCheck />;
      case JobViewModeParamsEnum.JOB_TIME_TRACKING:
        return <JobTimeTracking />;
      case JobViewModeParamsEnum.JOB_ATTACHMENTS:
        return <JobAttachments />;
      default: return <JobLinks />;
    }
  };
  return (
    <>
      {renderContent()}
    </>
  );
};

export default JobContent;
