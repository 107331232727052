import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV, faPencil } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import Dropdown from '../../../../../../../core/components/dropdown/Dropdown';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import EmptyVehiclesList from '../../../../../../common/components/empty-vehicles-list/EmptyVehiclesList';
import usePermission from '../../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import { useAppSelector } from '../../../../../../../store/hooks';
import { vehicleProfilesGroupsUiWithoutAllSelector } from '../../../../../../../store/selectors/sharedSelectors';
import { partSuitableForSelector, stockPartDetailsSelector } from '../../../../../../../store/selectors/stockSelectors';

type PartViewVehiclesProps = {
  openModal: VoidFunctionType,
}

const PartViewVehicles:React.FC<PartViewVehiclesProps> = ({ openModal }) => {
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const suitableForVehicles = useAppSelector(partSuitableForSelector);
  const availableVehicleGroups = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);

  const suitableForVehicleGroupIds = partDetails?.part.suitableForVehicleGroupIds;
  const allowedToManage = usePermission(PermissionEnum.StockPartEditFields);

  const groupsList = availableVehicleGroups.items
    .filter((v) => suitableForVehicleGroupIds?.includes(v.id))
    .map((v) => ({ label: `Group ${v.name} (${v.vehicleProfilesCount})`, value: v.id }));
  const listToRender = suitableForVehicleGroupIds && suitableForVehicleGroupIds.length > 0 ? groupsList : suitableForVehicles;

  return (
    <div className="info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">
          Suitable for vehicles
        </h2>
        {allowedToManage && <Dropdown
          menuItems={[
            {
              label: 'Select vehicles',
              icon: <FontAwesomeIcon icon={faPencil} />,
              key: 'selectVehicles',
              onClick: openModal,
            },
          ]}
          icon={<FontAwesomeIcon icon={faEllipsisV} />}
        />}
      </div>
      <ul className="suitable-vehicles-list">
        {listToRender.length > 0
          ? listToRender.map((veh) => <li key={veh.value}>
            {veh.label || 'N/A'}
          </li>)
          : <EmptyVehiclesList />}
      </ul>
    </div>
  );
};

export default PartViewVehicles;
