import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import classNames from 'classnames';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Tag from '../../../core/components/tag/Tag';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import './VehicleSection.scss';
import VehicleInfoTag from './VehicleInfoTag';

type VehicleSectionProps = {
  name: string,
  info: string,
  status?: string | null,
  regNumber: string | null | undefined,
  chassisNumber: string | null | undefined,
  vehicleLocation: string | null | undefined,
  readonly?: boolean,
  menuItems?: ItemType[]
  edge?: boolean,
  bordered?: boolean,
  horizontal?: boolean,
  className?: string,
}
const VehicleSection: React.FC<VehicleSectionProps> = ({
  name, info, status, regNumber, chassisNumber, vehicleLocation, readonly, menuItems, edge, bordered, horizontal, className,
}) => {
  const hasAnyVehicleSpecific = regNumber || chassisNumber || vehicleLocation;

  return (
    <div className={classNames(className, 'vehicle-section', {
      'vehicle-section--edge': edge,
      'vehicle-section--bordered': bordered,
      'vehicle-section--horizontal': horizontal,
    })}
    >
      <div className="vehicle-section__content">
        <div className="vehicle-section__info">
          <TruncateTooltip
            value={name}
            className="vehicle-section__text vehicle-section__text--bold"
          />
          <TruncateTooltip value={info} className="vehicle-section__text" />
        </div>
        {(status || !readonly) && <div className="vehicle-section__action">
          {status && <Tag textSize="md" label={status} className="vehicle-section__status" />}
          {!readonly && menuItems && menuItems.length > 0 && <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={menuItems}
          />}
        </div>}
      </div>
      {hasAnyVehicleSpecific && <div className="vehicle-section__specific">
        {regNumber && <VehicleInfoTag value={regNumber} />}
        {chassisNumber && <VehicleInfoTag value={chassisNumber} />}
        {vehicleLocation && <VehicleInfoTag value={vehicleLocation} shrink />}
      </div>}
    </div>
  );
};

export default VehicleSection;
