import React, { useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import Tr from '../../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  DateRangeTableFilterType, initPaging, PickerValues,
  RangeTableFiltersType,
  SearchTableFilterType, VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import { setPricesFilters } from '../../../../../store/slices/pricelistSlice';
import { PricesFiltersType } from '../../../../dashboard/types/pricelistTypes';
import {
  pricesTabColumnsSelector,
} from '../../../../../store/selectors/pricelistSelectors';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import Restricted from '../../../../../permissions-handling/Restricted';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import { commonMinDecimal, DEFAULT_DATE_FORMAT, maxCommonDecimal } from '../../../../../core/utils/regex';
import { getPricesPartsThunk } from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';

type PricesColumnsProps = {
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
  pricesFilters: PricesFiltersType,
  totalCount: number,
}

const initRanges: { [key: string]: [string, string] } = {
  sellPrice: ['0', '999999999.99'],
  costPrice: ['0', '999999999.99'],
};

const rangePatterns = {
  costPrice: { min: commonMinDecimal, max: maxCommonDecimal },
  sellPrice: { min: commonMinDecimal, max: maxCommonDecimal },
};

const PricesColumns: React.FC<PricesColumnsProps> = ({
  isAll, isIndeterminate, checkAll, pricesFilters, totalCount,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const pricesTabColumns = useAppSelector(pricesTabColumnsSelector);
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    modifiedDate: [
      pricesFilters.modifiedDate?.from ? moment(pricesFilters.modifiedDate.from, DEFAULT_DATE_FORMAT) : null,
      pricesFilters.modifiedDate?.to ? moment(pricesFilters.modifiedDate.to, DEFAULT_DATE_FORMAT) : null,
    ],
  });
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: pricesFilters.productNumber,
    productName: pricesFilters.productName,
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    sellPrice: pricesFilters.costPrice
      ? [pricesFilters.costPrice?.from.toString(), pricesFilters.costPrice?.to.toString()]
      : initRanges.sellPrice,
    costPrice: pricesFilters.sellPrice
      ? [pricesFilters.sellPrice?.from.toString(), pricesFilters.sellPrice?.to.toString()]
      : initRanges.costPrice,
  });

  useEffect(() => {
    if (isEqual(pricesFilters, initPaging)) {
      setPickerValue({ modifiedDate: [null, null] });
      setSearchVl({ productNumber: undefined, productName: undefined });
      setRange({ sellPrice: initRanges.sellPrice, costPrice: initRanges.costPrice });
    }
  }, [pricesFilters]);

  const getFilteredResult = (filters: PricesFiltersType) => {
    const { page, pageSize } = initPaging;
    dispatch(setPricesFilters({ ...filters, page, pageSize }));
    id && dispatch(getPricesPartsThunk({ id: +id, filters: { ...filters, page, pageSize } }));
  };

  const applyFiltersCheckbox = (checkedValues: CheckboxValueType[], fieldName: 'categoryIds' | 'subCategoryIds') => {
    const newFilters = {
      ...pricesFilters,
      [fieldName]: checkedValues.length > 0 ? checkedValues as number[] : undefined,
    };
    getFilteredResult(newFilters);
  };

  const filters = [
    {
      dataIndex: 'categoryId',
      value: pricesFilters.categoryIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => applyFiltersCheckbox(checkedValues, 'categoryIds'),
    },
    {
      dataIndex: 'subcategoryId',
      value: pricesFilters.subCategoryIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => applyFiltersCheckbox(checkedValues, 'subCategoryIds'),
    },
  ];

  const sortHandle = (field: number) => {
    if (pricesFilters.order?.field === field) {
      const newFilters = {
        ...pricesFilters,
        order: { ...pricesFilters.order, isAsc: !pricesFilters.order.isAsc },
      };
      getFilteredResult(newFilters);
    } else {
      const newFilters = {
        ...pricesFilters,
        order: { field, isAsc: true },
      };
      getFilteredResult(newFilters);
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = {
        ...pricesFilters,
        [field]: undefined,
      };
      getFilteredResult(newFilters);
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = {
      ...pricesFilters,
      [field]: searchVl[field]?.trim(),
    };
    getFilteredResult(newFilters);
  };
  const setRangeHandle = (field: string, value: [number, number]) => {
    const newFilters = {
      ...pricesFilters,
      [field]: {
        from: value[0],
        to: value[1],
      },
    };
    getFilteredResult(newFilters);
  };
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...pricesFilters,
      [field]: values ? { from: values?.min, to: values?.max } : undefined,
    };
    getFilteredResult(newFilters);
  };
  return (
    <Tr>
      <Restricted to={PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions}>
        <ThNew checkbox>
          <Checkbox checked={isAll} onChange={checkAll} indeterminate={isIndeterminate} table />
        </ThNew>
      </Restricted>
      {pricesTabColumns.map((col, i) => <ThWithControls
        key={i}
        col={col}
        className={`pricelistPricesTh--${col.dataIndex}`}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        setRangeHandle={setRangeHandle}
        totalElements={totalCount}
        disabled={totalCount === 0}
        range={range}
        rangePatterns={rangePatterns}
        setRange={setRange}
        filters={filters}
        searchVl={searchVl}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        tableFilters={pricesFilters}
        pickerHandle={pickerHandle}
      />)}
      <Restricted to={PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions}>
        <ThNew action><span className="sr-only">Actions</span></ThNew>
      </Restricted>
    </Tr>
  );
};

export default PricesColumns;
