import { useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { rectificationDetailsSelector } from '../../../store/selectors/sharedSelectors';
import { CustomAny } from '../../../core/types/coreTypes';
import {
  blockedDeletionOfRectificationCustomLinesStatusesSelector,
  blockedDeletionOfRectificationUsualLinesStatusesSelector, isRectificationInReadModeSelector,
  rectificationPartReqLinesColumnsSelector,
  rectificationPartRequestLinesSelector, rectificationPartReqUiLinesSelector,
} from '../../../store/selectors/rectificationsSelector';
import {
  mockedOrderPartRequestCustomSource,
  mockedOrderPartRequestSources,
} from '../../../orders/orders-view-page/utils/data';
import { allocatedPartReqLinesStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { useSelectAll } from '../../../core/hooks/useSelectAll';
import { usePartRequestLinesReason } from '../../../common/hooks/usePartRequestLinesReason';

export const useRectificationPartReqLines = () => {
  const { items: partReqOriginLines, allItemIds } = useAppSelector(rectificationPartRequestLinesSelector);
  const linesArr = useAppSelector(rectificationPartReqUiLinesSelector);
  const columns = useAppSelector(rectificationPartReqLinesColumnsSelector);
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const blockedManagingOfUsualLinesStatuses = useAppSelector(blockedDeletionOfRectificationUsualLinesStatusesSelector);
  const blockedManagingOfCustomLinesStatuses = useAppSelector(blockedDeletionOfRectificationCustomLinesStatusesSelector);
  const rectificationStatus = rectificationDetails?.rectification?.status;
  const isRectificationInReadMode = useAppSelector(isRectificationInReadModeSelector);
  const allocatedPartReqLinesStatuses = useAppSelector(allocatedPartReqLinesStatusesSelector);

  const isUsualLinesAddingBlockedByStatus = !rectificationStatus
      || blockedManagingOfUsualLinesStatuses.includes(rectificationStatus);
  const availableInfoSourcesByStatus = isUsualLinesAddingBlockedByStatus
    ? mockedOrderPartRequestCustomSource
    : mockedOrderPartRequestSources;

  const { setDeleteReasonModal, ...restReasonModal } = usePartRequestLinesReason();

  const [discountModal, setDiscountModal] = useState(false);
  const [partsModal, setPartsModal] = useState(false);

  const isLineActionDisabled = (id: number, isChildLine?: boolean) => {
    let currentLine;
    if (isChildLine) {
      const childLines = partReqOriginLines?.map((el) => el.childLines)
        .filter((el) => !!el)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        .reduce((a, b) => [...a, ...b]);
      currentLine = childLines?.find((el: CustomAny) => el.id === id);
    } else {
      currentLine = partReqOriginLines?.find((el: CustomAny) => el.id === id);
    }
    const noPartOrPartsKitId = !currentLine?.partId && !currentLine?.partsKitId;
    const isCurrentLineCustom = isChildLine
      ? noPartOrPartsKitId && !currentLine?.partsKitComponentPartId
      : noPartOrPartsKitId;
    return isCurrentLineCustom
      ? blockedManagingOfCustomLinesStatuses?.includes(rectificationStatus || 0)
      : blockedManagingOfUsualLinesStatuses?.includes(rectificationStatus || 0);
  };

  const allItemIdsWithoutDisabled = useMemo(() => {
    const itemsWithoutDisabled = partReqOriginLines.filter((el) => !isLineActionDisabled(el.id));
    return itemsWithoutDisabled.filter(({ id }) => allItemIds.includes(id)).map(({ id }) => id);
    // eslint-disable-next-line
  }, [partReqOriginLines, allItemIds]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIdsWithoutDisabled);

  const isPartsKitLineHasAllocatedChild = (id: number) => {
    const currentLine = partReqOriginLines?.find((el: CustomAny) => el.id === id);
    const isPartsKitLine = currentLine?.partsKitId;
    const lineChildren = currentLine?.childLines;
    const isAnyChildAllocated = lineChildren?.find((el) => allocatedPartReqLinesStatuses.find((st) => st.value === el.status));
    return !!(isPartsKitLine && isAnyChildAllocated);
  };

  const addRow = () => {
    setPartsModal(true);
  };

  const deleteSelected = () => {
    setDeleteReasonModal(true);
  };

  const footerActions = [
    {
      label: 'Add row',
      icon: faPlus,
      onClick: () => addRow(),
      isVisible: true,
    },
    {
      label: `Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`,
      onClick: () => deleteSelected(),
      disabled: checkedKeys.length === 0,
      isVisible: true,
    },
    {
      label: `Set discount for selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`,
      onClick: () => setDiscountModal(true),
      disabled: checkedKeys.length === 0,
      isVisible: rectificationDetails?.rectification?.enableDiscounts,
    },
  ];

  return {
    columns,
    linesArr,
    footerActions,
    checkAll: checkAllHandle,
    checkRowHandle,
    isAll,
    isIndeterminate,
    checkedKeys,
    setDiscountModal,
    discountModal,
    partsModal,
    setPartsModal,
    isLineActionDisabled,
    isPartsKitLineHasAllocatedChild,
    blockedManagingOfUsualLinesStatuses,
    isRectificationInReadMode,
    availableInfoSourcesByStatus,
    setDeleteReasonModal,
    ...restReasonModal,
  };
};
