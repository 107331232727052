import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const JobTitleSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
};

export type AddEmploymentFields = {
  jobTitle: string,
  department: string | number,
  startDate: string | null,
  endDate: string | null,
  salaryType: string | number,
  salaryValue: string,
  annualLeaveEntitlement: string,
};

export const addEmploymentDefaults: AddEmploymentFields = {
  jobTitle: '',
  department: '',
  startDate: null,
  endDate: null,
  salaryType: '',
  salaryValue: '0.00',
  annualLeaveEntitlement: '0.00',
};

type Employment = Pick<AddEmploymentFields, 'jobTitle' | 'startDate' | 'endDate'>

export type CreateEmployeeEmploymentRestModel = Employment & {
  department: number,
  annualLeaveEntitlement: number | null,
  salaryType: number,
  salaryValue: number | null,
}

export type PatchEmployeeEmploymentRestModel = CreateEmployeeEmploymentRestModel & {
  endDateIsEmpty: boolean | null,
  salaryValueIsEmpty: boolean | null,
  annualLeaveEntitlementIsEmpty: boolean | null,
}
