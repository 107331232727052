import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setLoading } from '../../../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../../../core/utils/guidGenerator';
import CreateAttachmentModal from '../../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import EmployeeAttachmentFilter from './filter/EmployeeAttachmentFilter';
import EmployeeAttachmentsTable from './table/EmployeeAttachmentsTable';
import { employeeAttachmentsFilterSelector, employeeAttachmentsSelector } from '../../../../../../store/selectors/employeesSelector';
import {
  createEmployeeAttachmentThunk,
  deleteMultipleEmployeeAttachmentsThunk, getEmployeeAttachmentsThunk,
} from '../../../../../../store/thunks/employee/employeeAttachmentsThunks';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';

const EmployeeViewAttachments = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(employeeAttachmentsSelector);
  const filters = useAppSelector(employeeAttachmentsFilterSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  useEffect(() => {
    id && dispatch(getEmployeeAttachmentsThunk({ employeeId: +id, filters }));
    // eslint-disable-next-line
  }, [id, filters]);

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createEmployeeAttachmentThunk({
      employeeId: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultipleEmployeeAttachmentsThunk({
      ids: checkedKeys,
      employeeId: +id,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <div className="employeeViewAttachments">
        <EmployeeAttachmentFilter
          filters={filters}
          totalItems={totalCount}
          setCreateModal={setCreateModal}
          setDeleteModal={setDeleteModal}
          checkedKeys={checkedKeys}
        />
        <EmployeeAttachmentsTable
          attachments={items}
          checkedKeys={checkedKeys}
          isAll={isAll}
          indeterminate={isIndeterminate}
          checkAllHandle={checkAllHandle}
          checkRow={checkRowHandle}
        />
      </div>
    </>
  );
};

export default EmployeeViewAttachments;
