import { RequisiteFields } from '../../../common/utils/data';

export const customerFormHandle = (requisiteFields: RequisiteFields) => {
  const billing = { ...requisiteFields.billing };
  const shipping = { ...requisiteFields.shipping };
  Object.keys(billing).forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (billing[k]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      billing[k] = billing[k].trim();
    }
  });

  Object.keys(shipping).forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (shipping[k]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      shipping[k] = shipping[k].trim();
    }
  });

  return {
    billing, shipping,
  };
};
