import qs from 'qs';
import instance from './axiosConfig';
import { CreateJobReqType, CreateJobResType } from '../job/create-job/types/createJobTypes';
import { JobListFilters, JobListResponseResult } from '../job/jobs-list/types/jobListTypes';
import {
  AxiosCreatedIdResponse,
  AxiosDataListResponse,
  AxiosGenericResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  CreateUploadUriResponse, EntityLinkType,
  GetAttachmentFileResponse,
  LinksFiltersType,
  PostAttachReqType,
  UploadUriDto,
} from '../core/types/coreTypes';
import {
  GetJobAttachmentsResponse,
  GetJobResponse,
  GetJobTimeTrackingResponse,
  JobEventDtoBase, JobQualityCheckDto,
  PatchJobDto, PostJobQualityCheckRestModel,
} from '../job/job-view-page/types/jobViewTypes';
import { GetJobDashboardResponse, JobsFiltersType } from '../job/jobs/types/jobsTypes';
import { ExtendedTimelineFilters } from '../common/types/commonTypes';
import {
  BulkPatchJobDto,
  JobCalendarDictionaryFilters,
  JobCalendarFilters, JobCalendarUserDto, JobDictionaryDto,
} from '../job/jobs-calendar/types/jobCalendarTypes';

export const jobsAPI = {
  createJob(data: CreateJobReqType): AxiosSingleResponse<CreateJobResType> {
    return instance.post('/job/jobs', { ...data });
  },
  deleteJob(id: number): AxiosNullableResponse {
    return instance.delete(`/job/jobs/${id}`);
  },
  fetchJobsList(businessArea: number, filters: JobListFilters): AxiosGenericResponse<JobListResponseResult> {
    return instance.get('/job/jobs/views/list', {
      params: {
        'BusinessArea': businessArea,
        'Filter.JobStatuses': filters.jobStatuses,
        'Filter.AssigneeIds': filters.assigneeIds,
        'Filter.Keyword': filters.keyword,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchJobById(id: number): AxiosSingleResponse<GetJobResponse> {
    return instance.get(`/job/jobs/${id}`);
  },
  changeJobStatus(id: number, statusTransition: number): AxiosNullableResponse {
    return instance.put(`/job/jobs/${id}/status`, { statusTransition });
  },
  changeJobDetails(id: number, patchData: PatchJobDto): AxiosNullableResponse {
    return instance.patch(`/job/jobs/${id}`, { job: { ...patchData } });
  },
  changeJobAssignees(id: number, assigneeIds: number[]): AxiosNullableResponse {
    return instance.put(`/job/jobs/${id}/assignees`, { assigneeIds });
  },
  fetchJobAttachments(jobId: number, params: CoreAttachmentsFilters): AxiosGenericResponse<GetJobAttachmentsResponse> {
    return instance.get(`/job/jobs/${jobId}/attachments`, {
      params: {
        'Filter.Name': params.name,
        'Filter.MinUploadDate': params.uploadDate?.min,
        'Filter.MaxUploadDate': params.uploadDate?.max,
        'Filter.Comment': params.comment,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchJobAttachmentById(id: number, jobId: number): AxiosSingleResponse<GetAttachmentFileResponse> {
    return instance.get(`/job/jobs/${jobId}/attachments/${id}`);
  },
  postJobAttachments(jobId: number, attachments: PostAttachReqType[]): AxiosSingleResponse<{ createdItems: Array<{ id: number }> }> {
    return instance.post(`/job/jobs/${jobId}/attachments`, { objectId: jobId, attachments });
  },
  postJobAttachmentUploadUri(jobId: number, uploadUriDtos: UploadUriDto[]): AxiosSingleResponse<CreateUploadUriResponse> {
    return instance.post(`/job/jobs/${jobId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editJobAttachment(jobId: number, id: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/job/jobs/${jobId}/attachments/${id}`, { comment });
  },
  deleteJobAttachment(jobId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/job/jobs/${jobId}/attachments/${id}`);
  },
  deleteMultipleJobAttachment(jobId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/job/jobs/${jobId}/attachments`, { data: { attachmentIds } });
  },
  fetchJobLinks(jobId: number, params: LinksFiltersType): AxiosSingleResponse<{ jobLinks: EntityLinkType[] }> {
    return instance.get(`/job/jobs/${jobId}/links`, {
      params: {
        'Filter.EntityName': params.entity,
        'Filter.CreatedDate.From': params.createdDate?.min,
        'Filter.CreatedDate.To': params.createdDate?.max,
        'Filter.ModifiedDate.From': params.modifiedDate?.min,
        'Filter.ModifiedDate.To': params.modifiedDate?.max,
        'OrderOptions.Field': params.order.field,
        'OrderOptions.Ascending': params.order.isAsc,
      },
    });
  },
  fetchJobsDashboard(filters: JobsFiltersType, signal?: AbortSignal): AxiosSingleResponse<GetJobDashboardResponse> {
    return instance.get('/job/jobs/views/dashboard', {
      params: {
        BusinessArea: filters.businessArea,
        ApplyPersonalFilter: filters.applyPersonalFilter,
        Statuses: filters.statuses,
        'Filter.Keyword': filters.keyword,
        'Filter.PriorityLevels': filters.priority,
        'Filter.Assignees': filters.assignedTo,
        'Filter.JobTypes': filters.jobType,
        'Filter.JobCategories': filters.jobCategories,
        'Filter.Warnings': filters.warnings,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  changeJobDashboardLayout(
    layoutSettings: Array<{
      status: number,
      ordinalNumber: number,
      isVisible: boolean
    }>,
  ): AxiosNullableResponse {
    return instance.put('/job/jobs/views/dashboard/layout', { layoutSettings });
  },
  fetchJobTimeTracking(id: number): AxiosSingleResponse<GetJobTimeTrackingResponse> {
    return instance.get(`/job/jobs/${id}/timetracking`);
  },
  fetchJobTimeline(jobId: number, filters: ExtendedTimelineFilters & { notesOnly: boolean }): AxiosDataListResponse<JobEventDtoBase[]> {
    return instance.get('/job/jobs/timeline', {
      params: {
        'JobId': jobId,
        'MainOnly': filters.mainOnly,
        'NotesOnly': filters.notesOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postJobTimelineNote(jobId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/job/note', {
      jobId,
      content,
    });
  },
  editJobTimelineNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/job/note/${id}`, { content });
  },
  deleteJobTimelineNote(id: number): AxiosNullableResponse {
    return instance.delete(`/job/note/${id}`);
  },
  fetchJobCalendar(filters: JobCalendarFilters): AxiosDataListResponse<JobCalendarUserDto[]> {
    return instance.get('/job/jobs/views/calendar', {
      params: {
        'Days': filters.days,
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeJobNumber': !!filters.keyword || undefined,
        'Filter.SearchParameters.IncludeCustomerName': !!filters.keyword || undefined,
        'Filter.SearchParameters.IncludeLinkedEntityNumber': !!filters.keyword || undefined,
        'Filter.JobCategories': filters.jobCategories,
        'Filter.PriorityLevels': filters.priorityLevels,
        'Filter.Assignees': filters.assignees,
        'Filter.JobTypes': filters.jobTypes,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchJobCalendarDictionary(filters: JobCalendarDictionaryFilters): AxiosSingleResponse<{ jobs: JobDictionaryDto[] }> {
    return instance.get('/job/jobs/views/dictionary', {
      params: {
        'Filter.AssigneeIds': filters.assignees,
        'Filter.JobStatuses': filters.jobStatuses,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  patchJobs(jobs: Partial<BulkPatchJobDto>[]): AxiosNullableResponse {
    return instance.patch('/job/jobs', { jobs });
  },
  getJobQualityChecks(jobId: number): AxiosSingleResponse<{ qualityChecks: JobQualityCheckDto[] }> {
    return instance.get(`/job/jobs/${jobId}/qualityChecks`);
  },
  createJobQualityCheck(jobId: number, data: PostJobQualityCheckRestModel): AxiosCreatedIdResponse {
    return instance.post(`/job/jobs/${jobId}/qualityChecks`, { ...data });
  },
  deleteJobQualityCheck(jobId: number, checkId: number): AxiosNullableResponse {
    return instance.delete(`/job/jobs/${jobId}/qualityChecks/${checkId}`);
  },
};
