import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  jobCalendarFiltersSelector,
  jobDashboardFiltersSelector,
  jobListFiltersSelector,
  jobsAdminListFiltersSelector,
  jobsCalendarFiltersArraySelector,
  jobsFiltersSelector,
  permittedStatusesSelector,
} from '../../../store/selectors/jobsSelector';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { JobsFiltersType } from '../types/jobsTypes';
import { setJobCalendarFilters, setJobListFilters, setJobsFilters } from '../../../store/slices/jobsSlice';
import { JobModesEnum, JobsSearchParamsNames } from '../enums/jobsEnums';
import { getJobsDashboardThunk } from '../../../store/thunks/jobs/jobsDashboardThunks';
import { getJobListThunk } from '../../../store/thunks/jobs/jobListThunks';
import {
  currentUserPermissionsSelector,
  currentUserSelector,
  isCurrentRoleAdminSelector,
} from '../../../store/selectors/accountSelectors';
import { JobListFilters } from '../../jobs-list/types/jobListTypes';
import { JobCalendarFilters } from '../../jobs-calendar/types/jobCalendarTypes';
import { getJobCalendarThunk } from '../../../store/thunks/jobs/jobCalendarThunks';
import { infiniteScrollInitPaging } from '../../../core/types/coreTypes';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export const useJobFiltersHandle = () => {
  const [params, setSearchParams] = useSearchParams();
  const pageParam = params.get(JobsSearchParamsNames.PAGE);
  const keywordParam = params.get('keyword');
  const dispatch = useAppDispatch();
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const stateFilters = useAppSelector(jobsFiltersSelector);
  const dashFiltersList = useAppSelector(jobDashboardFiltersSelector);
  const jobListFilters = useAppSelector(jobListFiltersSelector);
  const jobsAdminListFilters = useAppSelector(jobsAdminListFiltersSelector);
  const jobCalendarFilters = useAppSelector(jobCalendarFiltersSelector);
  const jobsCalendarFiltersArray = useAppSelector(jobsCalendarFiltersArraySelector);
  const permittedStatuses = useAppSelector(permittedStatusesSelector);

  const currentUser = useAppSelector(currentUserSelector);
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);
  const currentUserPermissions = useAppSelector(currentUserPermissionsSelector);
  const permissionsToBecomeAdmin = [
    PermissionEnum.JobDashboard,
    PermissionEnum.JobCreateNewJob,
    PermissionEnum.JobCalendar,
    PermissionEnum.JobViewPage,
  ];
  const isUserHasAdminPowers = useMemo(() => {
    const hasAtLeastOnePermission = permissionsToBecomeAdmin.filter((p) => currentUserPermissions?.includes(p));
    return isCurrentRoleAdmin || (!isCurrentRoleAdmin && hasAtLeastOnePermission.length > 0);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUserPermissions, isCurrentRoleAdmin]);

  const { controller, cancelRequest } = useCancelRequest();

  const initDropdownSearch = {
    jobCategory: '',
    priority: '',
    assignedTo: '',
    jobType: '',
    warnings: '',
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const defaultKeyword = useMemo(() => (pageParam === JobModesEnum.JOB_LIST
    ? jobListFilters.keyword
    : pageParam === JobModesEnum.DASHBOARD
      ? stateFilters.keyword
      : jobCalendarFilters.keyword), [pageParam, jobListFilters, stateFilters, jobCalendarFilters]);
  const [keywordValue, setKeywordValue] = useState<string | undefined>(defaultKeyword);

  const getJobListHandle = (filters: JobsFiltersType) => {
    if (currentBusinessArea) {
      if (isCurrentRoleAdmin) {
        dispatch(getJobListThunk({
          businessArea: currentBusinessArea,
          filters,
        }));
      } else {
        currentUser && dispatch(getJobListThunk({
          businessArea: currentBusinessArea,
          filters: { ...filters, assigneeIds: [currentUser?.id] },
        }));
      }
    }
  };

  const getFilteredJobs = (filters: JobsFiltersType) => {
    cancelRequest();
    dispatch(setJobsFilters({ ...filters, page: 1 }));
    currentBusinessArea && dispatch(getJobsDashboardThunk({
      filters: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: permittedStatuses.map((st) => st.value),
        page: 1,
      },
      signal: controller.current?.signal,
    }));
  };
  const getFilteredJobList = (filters: JobListFilters) => {
    const filtersList = { ...filters, page: 1 };
    dispatch(setJobListFilters(filtersList));
    getJobListHandle(filtersList);
  };
  const getFilteredCalendar = (filters: JobCalendarFilters) => {
    const values = { ...filters, page: 1 };
    dispatch(setJobCalendarFilters(values));
    dispatch(getJobCalendarThunk({ filters: values }));
  };

  const setInitSearchParams = () => {
    const defaultPageParam = isUserHasAdminPowers ? JobModesEnum.DASHBOARD : JobModesEnum.JOB_LIST;
    setSearchParams({ [JobsSearchParamsNames.PAGE]: pageParam || defaultPageParam });
  };

  const onClear = () => {
    if (pageParam === JobModesEnum.JOB_LIST) {
      const isFilterSet = jobListFilters.keyword || jobListFilters.assigneeIds;
      isFilterSet && getFilteredJobList({
        ...jobListFilters,
        page: 1,
        assigneeIds: undefined,
        keyword: undefined,
      });
    } else if (pageParam === JobModesEnum.CALENDAR) {
      const isFilterSet = jobCalendarFilters.keyword
          || jobCalendarFilters.assignees
          || jobCalendarFilters.jobCategories
          || jobCalendarFilters.jobTypes
          || jobCalendarFilters.priorityLevels;
      isFilterSet && getFilteredCalendar({ page: 1, pageSize: jobCalendarFilters.pageSize, days: jobCalendarFilters.days });
    } else {
      const isFilterSet = stateFilters.keyword
          || stateFilters.jobCategories
          || stateFilters.assignedTo
          || stateFilters.jobType
          || stateFilters.warnings
          || stateFilters.priority;
      isFilterSet && getFilteredJobs(infiniteScrollInitPaging);
    }
    setKeywordValue('');
    if (keywordParam) {
      setInitSearchParams();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    jobListFilters,
    jobsAdminListFilters,
    initDropdownSearch,
    onClear,
    getFilteredJobs,
    getFilteredJobList,
    getFilteredCalendar,
    stateFilters,
    dashFiltersList,
    jobCalendarFilters,
    jobsCalendarFiltersArray,
    currentBusinessArea,
    isUserHasAdminPowers,
    currentUser,
    keywordParam,
    setInitSearchParams,
  };
};
