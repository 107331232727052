import { EmployeeSkillsModalOrdering } from '../enums/skillsEnum';

export const skillsModalColumns = [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Category',
    dataIndex: 'categoryName',
    sorter: true,
    orderField: +EmployeeSkillsModalOrdering.Category,
  },
  {
    title: 'Skill item',
    dataIndex: 'name',
    sorter: true,
    orderField: +EmployeeSkillsModalOrdering.SkillItem,
  },
  {
    title: 'Level',
    dataIndex: 'level',
  },
];

export const skillsSectionColumns = [
  {
    title: 'Categories and items',
    dataIndex: 'categoryName',
    filterSearch: true,
  },
  {
    title: 'Skill level',
    dataIndex: 'level',
  },
];
