import React, { Key, useRef } from 'react';
import {
  faCar, faChevronLeft, faChevronRight, faEdit, faEllipsis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import Button from '../../../../../../core/components/button/Button';
import { EnquiryQuoteVehicleDto } from '../../../../types/enquiryDetailsTypes';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { DictionaryItem, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { enquiryAcceptedOrRejectedSelector } from '../../../../../../store/selectors/enquiriesSelector';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { useVehicleDictionaries } from '../../../../../../common/hooks/useVehicleDictionaries';

type VehicleInfoProps = {
  info: EnquiryQuoteVehicleDto;
  openDuplicateModal: VoidFunctionType;
  openConfirmModal: VoidFunctionType;
  openEditModal: VoidFunctionType;
}

const VehicleInfo: React.FC<VehicleInfoProps> = ({
  info,
  openDuplicateModal,
  openConfirmModal,
  openEditModal,
}) => {
  const allowedManageVeh = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const {
    vehicleSlidingDoorsQuantities,
    vehicleProfilesTypes,
    vehicleProfilesGroups,
  } = useVehicleDictionaries();

  const correctLabelHandle = (value: number | string, selector: DictionaryItem[]) => {
    return selector.find((el) => el.value === value)?.label || '';
  };

  const fields = [
    { label: 'Code', value: info.code || '-' },
    { label: 'Manufacturer', value: info.manufacturer || '-' },
    { label: 'Model description', value: info.modelDescription || '-' },
    { label: 'Type', value: correctLabelHandle(info.type || '', vehicleProfilesTypes) },
    { label: 'Reg No', value: info.regNumber || '-' },
    { label: 'Chassis No', value: info.chassisNumber || '-' },
    {
      label: 'Number of sliding doors',
      value: info.slidingDoorsQuantity !== null ? correctLabelHandle(info.slidingDoorsQuantity, vehicleSlidingDoorsQuantities) : '-',
    },
    { label: 'Group', value: correctLabelHandle(info.group || '', vehicleProfilesGroups) },
    { label: 'Gross vehicle weight (GVW)', value: info.grossVehicleWeightKg !== null ? `${info.grossVehicleWeightKg} kg` : '-' },
    { label: 'Maximum payload', value: info.maxPayload !== null ? `${info.maxPayload} kg` : '-' },
  ];
  const ref = useRef<HTMLDivElement>(null);
  const SCROLL_WIDTH = 180;
  const scrollLeft = () => {
    if (ref.current) {
      ref.current.scrollLeft += SCROLL_WIDTH;
    }
  };
  const scrollRight = () => {
    if (ref.current) {
      ref.current.scrollLeft -= SCROLL_WIDTH;
    }
  };

  const clickHandle = (key: Key) => {
    if (key === 'duplicate') {
      openDuplicateModal();
    } else if (key === 'editVehicle') {
      openEditModal();
    } else {
      openConfirmModal();
    }
  };

  return (
    <div className="vehicleContent-carousel">
      <Button
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        onClick={scrollRight}
        className="vehicleContent-carousel__btn"
      />
      <div className="vehicleContent-carousel__inner" ref={ref}>
        {fields.map((f, i) => <div key={i} className="vehicleContent-carousel__inner-field">
          <span>{f.label}</span>
          <span>{f.value}</span>
        </div>)}
      </div>
      <Button
        icon={<FontAwesomeIcon icon={faChevronRight} />}
        onClick={scrollLeft}
        className="vehicleContent-carousel__btn"
      />
      <Dropdown
        icon={<FontAwesomeIcon icon={faEllipsis} />}
        menuItems={[
          {
            label: 'Edit vehicle info',
            key: 'editVehicle',
            icon: <FontAwesomeIcon icon={faEdit} />,
          },
          {
            label: 'Duplicate vehicle',
            key: 'duplicate',
            icon: <FontAwesomeIcon icon={faCar} />,
          },
          {
            label: 'Delete vehicle',
            key: 'delete',
            icon: <FontAwesomeIcon icon={faTrashAlt} />,
          },
        ]}
        clickHandle={(key) => clickHandle(key)}
        disabled={enquiryAcceptedOrRejected || !allowedManageVeh}
        className={classNames('vehicleContent-carousel__dropdown', {
          'vehicleActionsDisabled': enquiryAcceptedOrRejected || !allowedManageVeh,
        })}
      />
    </div>
  );
};

export default VehicleInfo;
