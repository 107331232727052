import React from 'react';
import { PurchaseOrderEventTypesEnum } from '../../../../stock/purchase-orders/view-page/enums/PurchaseOrderViewEnum';
import { fieldNameEventHandle } from '../../../../common/utils/activityLogUtils';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { useAppSelector } from '../../../../store/hooks';
import { invoiceDocumentStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { invoiceDocumentTypesSelector } from '../../../../store/selectors/coreSelectors';

type NominalRecordEventsProps = {
  eventName: string,
  lineNumber: string | number,
  productName: string,
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const NominalRecordEvents: React.FC<NominalRecordEventsProps> = ({
  eventName, lineNumber, productName, field, oldValue, newValue,
}) => {
  const statuses = useAppSelector(invoiceDocumentStatusesSelector);
  const invoiceDocumentTypes = useAppSelector(invoiceDocumentTypesSelector);

  const valueHandle = (value: string | number) => {
    switch (field) {
      case 'InvoiceDate':
      case 'DueDate':
        return localDateFormatHandler('DD-MMM-YYYY', value as string);
      case 'Status':
        return statuses.find((status) => status.value === +value)?.label;
      case 'Type':
        return invoiceDocumentTypes.find((type) => type.value === +value)?.label;
      default: return value;
    }
  };
  const renderContent = () => {
    const createDeleteEvents = [PurchaseOrderEventTypesEnum.NominalRecordCreation, PurchaseOrderEventTypesEnum.NominalRecordDeletion];
    if (createDeleteEvents.includes(eventName as PurchaseOrderEventTypesEnum)) {
      return <>
        Nominal code record for Line
        {' '}
        <b>{`${lineNumber} `}</b>
        -
        {' '}
        <b>{`${productName} `}</b>
        was
        {' '}
        {eventName === PurchaseOrderEventTypesEnum.NominalRecordCreation ? 'created' : 'deleted'}
      </>;
    } else {
      return <>
        Nominal code record for Line
        {' '}
        <b>{`${lineNumber} `}</b>
        -
        {' '}
        Nominal code record
        {' '}
        <b>{`${fieldNameEventHandle(field)} `}</b>
        {' '}
        field was changed:
        {' '}
        {!!oldValue && <s>{`${valueHandle(oldValue)}`}</s>}
        {!!newValue && <b>{` ${valueHandle(newValue)}`}</b>}
      </>;
    }
  };
  return <>{renderContent()}</>;
};

export default NominalRecordEvents;
