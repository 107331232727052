import React from 'react';
import { RcFile } from 'antd/lib/upload';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import QuantitySection from './QuantitySection';
import DraggerUpload from '../../../../../core/components/upload/Dragger';
import Image from '../../../../../core/components/image/Image';
import { GetPartResponse } from '../../../types/types';
import Button from '../../../../../core/components/button/Button';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Upload from '../../../../../core/components/upload/Upload';

type PartMainInfoProps = {
  partDetails: GetPartResponse | null,
  formats: string,
  beforeUpload: (file: RcFile[]) => void,
  total: number,
  imageUri: string | null,
  onChange: BooleanFunctionType,
  setConfirm: VoidFunctionType,
}

const PartMainInfo:React.FC<PartMainInfoProps> = ({
  partDetails, formats, beforeUpload, total, imageUri, onChange, setConfirm,
}) => {
  return (
    <div className="partMainInfo">
      <div className="partMainInfo__img-wrapper">
        {imageUri
          ? <>
            <Image src={imageUri} />
            <Upload
              accept={formats}
              showUploadList={false}
              beforeUpload={beforeUpload}
              onChange={() => onChange(true)}
              icon={<FontAwesomeIcon icon={faCloudArrowUp} />}
              className="partMainInfo__change-btn"
            />
            <Button icon={<FontAwesomeIcon icon={faTrashAlt} />} className="partMainInfo__remove-btn" onClick={setConfirm} />
          </>
          : <DraggerUpload
            accept={formats}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={() => onChange(false)}
            isImage
          />}
      </div>
      <QuantitySection
        data={partDetails?.part}
        total={total}
        lessThenCriticalLevel={partDetails?.lessThenCriticalLevel}
        lessThenWarningLevel={partDetails?.lessThenWarningLevel}
      />
    </div>
  );
};

export default PartMainInfo;
