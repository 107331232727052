import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useOnClickOutside } from '../../../../../../core/hooks/useOutsideClick';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import QuoteEditableCellContent from './QuoteEditableCellContent';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import { useQuoteLineCell } from '../../../../hooks/useQuoteLineCell';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  weightlessMeasureValuesSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { maxCommonDecimal, maxDiscount } from '../../../../../../core/utils/regex';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { LineCellType } from './QuoteLinesCells';

type QuoteLineCellProps = {
  data: LineCellType
  valueRenderer: (key: string, value: string | number) => string | number | JSX.Element;
  disabled: boolean;
  editQty: NumberFunctionType;
  openCustomModal: NumberFunctionType;
  allowedToManage?: boolean,
}

const QuoteLineCell: React.FC<QuoteLineCellProps> = ({
  data,
  valueRenderer,
  disabled,
  editQty,
  openCustomModal,
  allowedToManage,
}) => {
  const weightlessMeasures = useAppSelector(weightlessMeasureValuesSelector);
  const {
    fieldToEdit,
    setFieldToEdit,
    editMode,
    setEditMode,
    cellClickHandle,
    cellRenderHandle,
    forbiddenToEdit,
    hiddenCells,
  } = useQuoteLineCell(editQty, openCustomModal, data, valueRenderer);

  const rules = {
    notes: { maxLength: 100, message: maxLengthMessageHandle(100) },
    weight: { message: 'The max allowed value is 999999999.99', max: maxCommonDecimal },
    quantity: { message: 'The max allowed value is 99999', max: 99999 },
    discount: {
      message: 'Entered value should be within 0%-100% range',
      max: maxDiscount,
    },
  };
  const [errors, setErrors] = useState({
    notes: '',
    weight: '',
    quantity: '',
    discount: '',
  });
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setEditMode(false);
    setFieldToEdit && setFieldToEdit('');
  });
  useEffect(() => {
    Object.keys(errors).length > 0 && fieldToEdit && setErrors({
      notes: '',
      weight: '',
      quantity: '',
      discount: '',
    });
    // eslint-disable-next-line
  }, [fieldToEdit]);
  const nowrapValues = ['quantity', 'unitPrice', 'netPrice', 'weight'];
  return (
    <>
      {Object.entries(data).map(([key, value]) => {
        if (hiddenCells.includes(key)) return null;
        return <TdNew key={key}>
          {editMode && fieldToEdit === key
            ? <QuoteEditableCellContent
              initValue={(!value || value) === '-' ? '' : value as (string | number)}
              fieldToEdit={fieldToEdit}
              setFieldToEdit={setFieldToEdit}
              lineId={data.id}
              rules={rules}
              errors={errors}
              setErrors={setErrors}
              ref={ref}
              allowedToManage={allowedToManage}
            />
            : <div
              className={classNames(
                'editSection',
                {
                  'editSection--disabled': disabled
                    || (key === 'tax' && data.partsKitId)
                    || (key === 'weight' && weightlessMeasures.includes(data.unitOfMeasure)),
                  'editSection--active': !forbiddenToEdit.includes(key),
                  'table-clickable-content': key === 'quantity' || key === 'unitPrice',
                  'editSection--partskit-quantity': key === 'quantity' && data.partsKitId,
                  'editSection--partskit-unit': key === 'unitPrice' && data.partsKitId,
                  'editSection--bold': key === 'weight' && data.isUsualLine && data.weight && data.productWeight
                    && data.weight !== data.productWeight,
                  'edit-Section--nowrap': nowrapValues.includes(key),
                },
              )}
              onClick={() => {
                if ((key === 'tax' && data.partsKitId) || (key === 'weight' && weightlessMeasures.includes(data.unitOfMeasure))) {
                  return;
                }
                if (!disabled) {
                  cellClickHandle(key);
                }
              }}
            >
              {cellRenderHandle(key, value)}
            </div>}
        </TdNew>;
      })}
    </>
  );
};

export default QuoteLineCell;
