import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import Modal from '../../../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import Button from '../../../../../../../core/components/button/Button';
import { useAppSelector } from '../../../../../../../store/hooks';
import { vehicleDetailsSelector } from '../../../../../../../store/selectors/vehiclesSelectors';
import { vehicleStatusValuesSelector } from '../../../../../../../store/selectors/coreStatusesSelectors';
import { routesPath } from '../../../../../../../core/enums/pathEnum';

type ChecksListModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const ChecksListModal: React.FC<ChecksListModalProps> = ({ visible, onCancel }) => {
  const navigate = useNavigate();
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const { ready } = useAppSelector(vehicleStatusValuesSelector);
  const vehicleId = vehicleDetails?.id;

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      width={600}
      className="checksListModal"
    >
      <div className="checksListModal__choice">
        <Button
          label="Full vehicle check"
          className="addContact__choice-item"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          htmlType="button"
          onClick={() => vehicleId && navigate(`/${routesPath.FULL_VEHICLE_CHECK}/${vehicleId}`)}
          reversed
        />
        <Button
          label="Handover check"
          className="addContact__choice-item"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          htmlType="button"
          onClick={() => vehicleId && navigate(`/${routesPath.HANDOVER_VEHICLE_CHECK}/${vehicleId}`)}
          reversed
          disabled={vehicleDetails?.status !== ready}
        />
        <Button
          label="Weight check"
          className="addContact__choice-item"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          htmlType="button"
          onClick={() => vehicleId && navigate(`/${routesPath.WEIGHT_VEHICLE_CHECK}/${vehicleId}`)}
          reversed
        />
      </div>
    </Modal>
  );
};

export default ChecksListModal;
