import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { JobQualityCheckPartTableLine } from '../../../../types/jobViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  jobQualityCheckDecisionSelector,
} from '../../../../../../store/selectors/coreStatusesSelectors';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import RadioGroup from '../../../../../../core/components/radio-group/RadioGroup';
import './QualityCheckPartsTable.scss';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';

type QualityCheckPartsTableProps = {
  data: JobQualityCheckPartTableLine[],
  values: Array<{ partRequestLineId: number, status: number | null }>,
  setValues: (v: Array<{ partRequestLineId: number, status: number | null }>) => void,
  page: number,
  readMode: boolean,
}

const QualityCheckPartsTable: React.FC<QualityCheckPartsTableProps> = ({
  data, values, setValues, page, readMode,
}) => {
  const checkResultsOptions = useAppSelector(jobQualityCheckDecisionSelector);
  const columns = [
    {
      title: 'No',
      dataIndex: 'ordinalNumber',
    },
    {
      title: 'Category',
      dataIndex: 'category',
    },
    {
      title: 'Product number',
      dataIndex: 'productNumber',
    },
    {
      title: 'Product name',
      dataIndex: 'productName',
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: '',
      dataIndex: 'checkResult',
    },
  ];
  const hiddenKeys = ['id', 'status', 'childLines'];

  const paginatedData = (page: number) => {
    const itemsPerPage = 5;

    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;

    return data.slice(start, end);
  };

  const renderCellValue = (key: string, value: string | number | null | undefined) => {
    if (['ordinalNumber', 'quantity'].includes(key)) return value;
    return <TruncateTooltip value={value} table />;
  };

  return (
    <TableNew>
      <Thead>
        <Tr>
          {columns.map(({ title, dataIndex }) => <ThNew
            key={dataIndex}
            className={`qualityCheckPartsTh--${dataIndex}`}
          >
            {title}
          </ThNew>)}
        </Tr>
      </Thead>
      <Tbody>
        {data.length > 0
          ? <>
            {paginatedData(page).map((d) => <React.Fragment key={d.id}>
              <Tr>
                {Object.entries(d).map(([key, value]) => {
                  if (hiddenKeys.includes(key)) return null;
                  return <TdNew key={key} className={key === 'quantity' ? 'nowrap' : ''}>
                    {renderCellValue(key, value as string | number)}
                  </TdNew>;
                })}
                <TdNew>
                  <RadioGroup
                    disabled={readMode}
                    optionType="button"
                    options={checkResultsOptions}
                    value={values.find((v) => v.partRequestLineId === d.id)?.status || null}
                    onChange={(value) => {
                      const newValues = values.map((v) => {
                        if (v.partRequestLineId === d.id) {
                          return { ...v, status: +value };
                        } else return v;
                      });
                      setValues(newValues);
                    }}
                  />
                </TdNew>
              </Tr>
              {d.childLines?.map((ch) => <Tr key={ch.id}>
                {Object.entries(ch).map(([key, value]) => {
                  if (hiddenKeys.includes(key)) return null;
                  return <TdNew key={key} className={key === 'quantity' ? 'nowrap' : ''}>
                    {renderCellValue(key, value)}
                  </TdNew>;
                })}
                <TdNew />
              </Tr>)}
            </React.Fragment>)}
          </>
          : <EmptyTableSection text="No records" colSpan={columns.length} />}
      </Tbody>
    </TableNew>
  );
};

export default QualityCheckPartsTable;
