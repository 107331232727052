import React, { useEffect, useState } from 'react';
import './SettingsContainer.scss';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import SettingsFilters from './components/settings-filters/SettingsFilters';
import SettingsContent from './components/settings-content/SettingsContent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPermissionModules } from '../../store/thunks/settings/settingsDashboardThunks';
import { useUserListDictionaries } from './hooks/useUserListDictionaries';
import { SettingsPageParamEnum, SettingsPageParams } from './enums/settingsEnums';
import { initDataListWithIds } from '../../core/types/coreTypes';
import { setCurrentPermissionModule, setUsersList } from '../../store/slices/settingsSlice';
import { currentPermissionModuleSelector } from '../../store/selectors/settingsSelectors';

const SettingsContainer: React.FC = () => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentPermissionModule = useAppSelector(currentPermissionModuleSelector);
  const {
    permissionModules,
  } = useUserListDictionaries();

  const setPageParam = (mode: string) => {
    if (SettingsPageParams.includes(mode as SettingsPageParamEnum)) {
      params.append('page', mode);
    } else {
      params.delete('page');
    }
    navigate({ search: `?page=${mode}` }, { replace: true });
  };

  const [isFilterMode, setFilterMode] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getPermissionModules());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (permissionModules && permissionModules.length > 0 && !currentPermissionModule) {
      dispatch(setCurrentPermissionModule(permissionModules[0].value));
    }
    // eslint-disable-next-line
  }, [permissionModules, currentPermissionModule]);
  useEffect(() => {
    return () => {
      dispatch(setUsersList(initDataListWithIds));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout scrollableMainArea={params.get('page') === SettingsPageParamEnum.PERMISSIONS}>
      <SettingsFilters
        view={params.get('page') as string}
        setView={setPageParam}
        isFilterMode={isFilterMode}
        setFilterMode={setFilterMode}
        permissionModule={currentPermissionModule}
        setPermissionModule={(v) => dispatch(setCurrentPermissionModule(v))}
      />
      <SettingsContent
        view={params.get('page') as string}
        permissionModule={currentPermissionModule}
      />
    </Layout>
  );
};

export default SettingsContainer;
