import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faCircleCheck,
  faEdit,
  faExchange,
  faInfoCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../store/hooks';
import { stockPartReqTableLinesSelector } from '../../../store/selectors/stockPartRequestsSelector';
import PriceSourceTag from '../../../common/components/price-source-tag/PriceSourceTag';
import Tag from '../../../core/components/tag/Tag';
import { QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import StockPartRequestLine from './StockPartRequestLine';
import { StockPartReqTableLineType } from '../../stock-part-requests/types/stockPartRequestTypes';
import StockPartRequestChildLine from './StockPartRequestChildLine';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import usePermission from '../../../permissions-handling/permissionHook';
import { partReqLineActionsStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';

type StockPartRequestLinesBodyProps = {
  setEditReadyCollected: NumberFunctionType,
  setDistributeQtyHandle: NumberFunctionType,
  setIsMarkNotFromStock: NumberFunctionType,
  setConfirmReturnedPartsHandle: NumberFunctionType,
  columnsLength: number,
  setRemapToExistingPart: NumberFunctionType,
}

const StockPartRequestLinesBody: React.FC<StockPartRequestLinesBodyProps> = ({
  setEditReadyCollected,
  setDistributeQtyHandle,
  setIsMarkNotFromStock,
  setConfirmReturnedPartsHandle,
  columnsLength,
  setRemapToExistingPart,
}) => {
  const stockPartReqTableLines = useAppSelector(stockPartReqTableLinesSelector);
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const allowedToManage = usePermission(PermissionEnum.StockPartRequestEditPartLineFieldsAndUnlockPartLineActions);
  const {
    unknownPart,
    partiallyReady,
    ready,
    confirmed,
    deleted,
    archived,
    pendingSource,
    toBeReviewed,
    partiallyCollected,
    collected,
  } = useAppSelector(partReqLineActionsStatusesSelector);

  const hiddenKeys = [
    'id', 'partId', 'partsKitId', 'unitOfMeasure',
    'partsKitComponentId', 'partsKitComponentPartId',
    'allocatedQuantity', 'deletionReason',
  ];
  const nowrapKeys = ['priceSources', 'quantity', 'readyQuantity', 'collectedQuantity'];

  const editReadyCollectedStatuses = (confirmed && ready && partiallyReady && collected && partiallyCollected)
    ? [
      confirmed.label, partiallyReady.label, ready.label,
      collected.label, partiallyCollected.label,
    ]
    : [];
  const reviewQtySourceStatuses = (pendingSource && toBeReviewed)
    ? [pendingSource.label, toBeReviewed.label]
    : [];
  const actionsRender = (
    status: string,
    sourceId: number,
    isChildLine?: boolean,
  ) => {
    const actions = [
      {
        label: 'Review quantity & source',
        key: 'reviewQtySource',
        icon: <FontAwesomeIcon icon={faCalculator} />,
        onClick: () => {
          setDistributeQtyHandle(sourceId);
        },
        allowedStatuses: reviewQtySourceStatuses,
      },
      {
        label: 'Edit ready/collected',
        icon: <FontAwesomeIcon icon={faEdit} />,
        key: 'editReadyCollected',
        onClick: () => setEditReadyCollected(sourceId),
        allowedStatuses: editReadyCollectedStatuses,
      },
      {
        label: 'Remap to existing part',
        icon: <FontAwesomeIcon icon={faExchange} />,
        key: 'remapToExistingPart',
        onClick: () => setRemapToExistingPart(sourceId),
        allowedStatuses: unknownPart ? [unknownPart.label] : [],
      },
      {
        label: 'Mark as Not for stock',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        key: 'markAsNotForStock',
        onClick: () => setIsMarkNotFromStock(sourceId),
        allowedStatuses: unknownPart ? [unknownPart.label] : [],
      },
      {
        label: 'Confirm returned parts',
        icon: <FontAwesomeIcon icon={faCircleCheck} />,
        key: 'confirmedReturnedParts',
        onClick: () => setConfirmReturnedPartsHandle(sourceId),
        allowedStatuses: deleted ? [deleted.label] : [],
      },
    ];
    return isChildLine
      ? actions.filter((act) => act.key !== 'remapToExistingPart' && act.allowedStatuses.includes(status))
      : actions.filter((act) => act.allowedStatuses.includes(status));
  };

  const isStatusDeleted = useCallback((status?: string) => {
    return status && status.toLowerCase() === deleted?.label?.toLowerCase();
  }, [deleted?.label]);

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip
      value={value}
      table
    />;
  };
  const renderCellValue = (
    key: string,
    value: unknown,
    unitOfMeasure: number,
    el: StockPartReqTableLineType | Omit<StockPartReqTableLineType, 'childLines'>,
  ): React.ReactNode | string | number => {
    switch (key) {
      case 'priceSources':
        return typeof value === 'string'
          ? <span>{value}</span>
          : (value as number[]).map((el: number, i: number) => <PriceSourceTag key={i} source={el} />);
      case 'notes':
        return renderTruncateTooltip(value as string | null);
      case 'quantity': {
        const isStatusAllowed = reviewQtySourceStatuses.includes(el.status);
        const clickable = isStatusAllowed && allowedToManage;
        return (
          <span
            className={clickable ? 'table-clickable-content' : ''}
            onClick={() => clickable && setDistributeQtyHandle(el.id)}
          >
            <span>{value as number}</span>
            {' '}
            {unitOfMeasures.find((unit) => unit.value === unitOfMeasure)?.label}
          </span>
        );
      }
      case 'readyQuantity':
      case 'collectedQuantity':
        if (isStatusDeleted(el.status)) {
          const text = <div>
            {el.deletionReason && <p>
              <span>Reason:</span>
              <span>{el.deletionReason}</span>
            </p>}
            <p>
              Please confirm that parts have been returned back to stock (via three-dot menu option).
              After confirmation the deleted line will be removed from this part request.
            </p>
          </div>;
          return (
            <span>
              <span>
                {value as string | number}
                {' '}
              </span>
              {typeof value === 'number' && value > 0 && <Tooltip title={text} overlayClassName="tooltip-sm no-tooltip-arrow">
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>}
            </span>
          );
        } else {
          const isStatusAllowed = editReadyCollectedStatuses.includes(el.status);
          const clickable = isStatusAllowed && allowedToManage;
          return <span
            className={clickable ? 'table-clickable-content' : ''}
            onClick={() => clickable && setEditReadyCollected(el.id)}
          >
            {value as (number | string)}
          </span>;
        }
      case 'status':
        return <Tag
          label={value as string}
          icon={(isStatusDeleted(el.status) && el.deletionReason)
            ? <Tooltip title={el.deletionReason} overlayClassName="tooltip-sm no-tooltip-arrow">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
            : undefined}
          rowReverse={!!isStatusDeleted(el.status)}
        />;
      case 'productName':
      case 'productNumber':
        if (el.partId || el.partsKitComponentPartId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/part/${el.partId || el.partsKitComponentPartId}`}
            permission={PermissionEnum.StockPartViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber)}
          </PrivateLineLink>;
        }
        if (el.partsKitId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${el.partsKitId}`}
            permission={PermissionEnum.StockPartsKitViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber)}
          </PrivateLineLink>;
        } else {
          return renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber);
        }
      case 'purchaseOrderNumber':
        return el.purchaseOrderNumber && el.purchaseOrderNumber.length
          ? el.purchaseOrderNumber.map((item, i) => <>
            <PrivateLineLink
              to={`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${item.id}`}
              permission={PermissionEnum.StockPurchaseOrdersViewPage}
            >
              {renderTruncateTooltip(item.purchaseOrderNumber)}
            </PrivateLineLink>
            {el.purchaseOrderNumber && i === el.purchaseOrderNumber.length - 1 ? '' : ', '}
          </>)
          : '-';
      default: return <>{value as string | number}</>;
    }
  };
  return (
    <Tbody>
      {stockPartReqTableLines.length > 0
        ? <>
          {stockPartReqTableLines.map((el) => <React.Fragment key={el.id}>
            <StockPartRequestLine
              el={el}
              key={el.id}
              hiddenKeys={[...hiddenKeys, 'childLines']}
              nowrapKeys={nowrapKeys}
              renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, el)}
              actionsRender={(status, sourceId) => actionsRender(status, sourceId)}
              deletedStatus={deleted}
              archivedStatus={archived}
              allowedToManage={allowedToManage}
            />
            {el.childLines && el.childLines.length > 0
            && el.childLines.map((ch, i) => <StockPartRequestChildLine
              key={i}
              childLine={ch}
              hiddenKeys={hiddenKeys}
              nowrapKeys={nowrapKeys}
              renderCellValue={(key, value, unitOfMeasure) => renderCellValue(key, value, unitOfMeasure, ch)}
              actionsRender={(status, sourceId) => actionsRender(status, sourceId, true)}
              deletedStatus={deleted}
              archivedStatus={archived}
              allowedToManage={allowedToManage}
            />)}
          </React.Fragment>)}
        </>
        : <EmptyTableSection text="No records" colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default StockPartRequestLinesBody;
