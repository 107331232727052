import { initDataList } from '../../../../../../core/types/coreTypes';
import { PurchasingOutstandingOverduePurchaseOrdersData } from '../../../../../types/OutstandingOverdueReportTypes';

export const initPurchasingOutstandingOverdueData: PurchasingOutstandingOverduePurchaseOrdersData = {
  ...initDataList,
  maxCostNet: 0,
  minCostNet: 0,
  maxCostGross: 0,
  minCostGross: 0,
};
