import React from 'react';
import classNames from 'classnames';
import './TableNew.scss';

type TableNewProps = {
  children: React.ReactNode,
  small?: boolean,
  hasFooter?: boolean,
  offsetBottom?: boolean,
  borderless?: boolean,
  disableHover?: boolean,
  className?: string,
  scrollOffset?: [number, number],
}

const TableNew: React.FC<TableNewProps> = ({
  children,
  small,
  hasFooter,
  offsetBottom,
  borderless,
  disableHover,
  className,
  scrollOffset = [],
}) => {
  const [desktop, mobile] = scrollOffset;
  const styles = {
    '--desktop-scroll-offsite': desktop ? `${desktop}px` : undefined,
    '--mobile-scroll-offsite': mobile ? `${mobile}px` : undefined,
  } as React.CSSProperties;

  return (
    <div
      style={styles}
      className={classNames('tableWrap', className, {
        'tableWrap--offset-bottom': offsetBottom,
        'tableWrap--borderless': borderless,
      })}
    >
      <table className={classNames('table-new', {
        'table-new--small': small,
        'table-new--has-footer': hasFooter,
        'table-new--disable-hover': disableHover,
      })}
      >
        {children}
      </table>
    </div>
  );
};

export default TableNew;
