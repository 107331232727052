import { initPaging } from '../../../core/types/coreTypes';
import { EmployeeLeaveRequestFilters } from '../../../common/types/commonTypes';

export const initEmployeesLeaveRequestsFilters: EmployeeLeaveRequestFilters = {
  ...initPaging,
  isActive: true,
};

export type EmployeeLeaveRequestUi = {
  id: number,
  employeeName: string,
  employeeJobTitle: string,
  leaveType: string,
  startDate: string,
  endDate: string,
  leavePeriod: number,
  status: number,
  statusName: string,
  comment: string | null,
}
