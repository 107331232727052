import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import {
  faM, faP, faQuestion, faS,
} from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { QuoteLinePriceSourceUiNames } from '../../../core/enums/dictionariesEnums';
import { quoteLinePriceSourcesSelector } from '../../../store/selectors/coreSelectors';

type PriceSourceTagProps = {
  source: number
}

type PriceTageProps = {
  icon: IconProp,
  tip?: string,
}

const PriceTag: React.FC<PriceTageProps> = ({ icon, tip }) => {
  return (
    <Tooltip title={tip} overlayClassName="truncate-table-tooltip">
      <div className="linePriceTag tag">
        <FontAwesomeIcon icon={icon} />
      </div>
    </Tooltip>
  );
};

const PriceSourceTag: React.FC<PriceSourceTagProps> = ({ source }) => {
  const quoteLinePriceSources = useAppSelector(quoteLinePriceSourcesSelector);
  const getIcon = () => {
    const currentSource = quoteLinePriceSources.find((s) => s.value === source);
    if (currentSource) {
      if (currentSource.label === QuoteLinePriceSourceUiNames.Stock) {
        return <PriceTag icon={faS} tip="Stock" />;
      }
      if (currentSource.label === QuoteLinePriceSourceUiNames.ManualInput) {
        return <PriceTag icon={faM} tip="Manual input" />;
      }
      if (currentSource.label === QuoteLinePriceSourceUiNames.Unknown) {
        // eslint-disable-next-line max-len
        const tip = "Some components of this parts kit don't have a price value on a quote date. Please try refreshing the price sources in a quote line menu.";
        return <PriceTag icon={faQuestion} tip={tip} />;
      } else return <PriceTag icon={faP} tip="Pricelist" />;
    } else return '-';
  };
  return (
    <>{getIcon()}</>
  );
};

export default PriceSourceTag;
