import React, { useEffect, useState } from 'react';
import { initDataList, initSmallPaging, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import QualityCheckPartsTable from './QualityCheckPartsTable';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  jobDetailsSelector, jobQualityChecksPartsLinesPagingSelector,
  jobQualityChecksPartsTableLinesSelector,
} from '../../../../../../store/selectors/jobsSelector';
import {
  createJobQualityCheckThunk,
  getJobPartLinesForQualityCheckModalThunk,
} from '../../../../../../store/thunks/jobs/jobThunks';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import {
  setJobQualityChecksPartsLines,
  setJobQualityChecksPartsLinesPaging,
} from '../../../../../../store/slices/jobsSlice';
import { isFetchingSelector } from '../../../../../../store/selectors/coreSelectors';
import { QualityCheckLines } from '../../../../types/jobViewTypes';

type QualityCheckModalProps = {
  onCancel: VoidFunctionType,
  isVisible: boolean,
  readMode: boolean,
  interactedId?: number,
  dashboardMode?: boolean,
}

const QualityCheckModal: React.FC<QualityCheckModalProps> = ({
  onCancel, isVisible, readMode, interactedId, dashboardMode,
}) => {
  const dispatch = useAppDispatch();
  const jobDetails = useAppSelector(jobDetailsSelector);
  const partsLines = useAppSelector(jobQualityChecksPartsTableLinesSelector);
  const paging = useAppSelector(jobQualityChecksPartsLinesPagingSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const [values, setValues] = useState<QualityCheckLines>([]);
  const cancelHandle = () => {
    onCancel();
    dispatch(setJobQualityChecksPartsLinesPaging(initSmallPaging));
    dispatch(setJobQualityChecksPartsLines(initDataList));
  };

  useEffect(() => {
    if (isVisible) {
      const partRequestId = jobDetails?.partRequest?.id;
      if (partRequestId) {
        dispatch(getJobPartLinesForQualityCheckModalThunk({
          partRequestId,
          params: readMode
            ? { jobQualityCheckId: interactedId, needToEvaluateJobQualityCheckStatus: true }
            : undefined,
        }));
      }
    }
    // eslint-disable-next-line
  }, [jobDetails, isVisible]);

  useEffect(() => {
    if (isVisible && partsLines.totalCount > 0) {
      const valuesList = partsLines.items.map((el) => ({
        partRequestLineId: el.id,
        status: readMode ? el.status : null,
      }));
      setValues(valuesList);
    }
  }, [isVisible, readMode, partsLines.totalCount, partsLines.items]);

  const saveCheckResults = () => {
    const jobId = jobDetails?.job?.id;
    if (values.length) {
      jobId && dispatch(createJobQualityCheckThunk({
        jobId,
        data: {
          jobQualityChecks: values as Array<{ partRequestLineId: number, status: number }>,
        },
        closeModal: cancelHandle,
        dashboardMode,
      }));
    } else {
      cancelHandle();
    }
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title="Quality check"
      width={1100}
    >
      <Pagination
        currentPage={paging.page}
        pageSize={paging.pageSize}
        showSizeChanger={false}
        onChange={(page) => dispatch(setJobQualityChecksPartsLinesPaging({ page, pageSize: 5 }))}
        totalItems={partsLines.totalCount}
        offsetBottom
      />
      <QualityCheckPartsTable
        data={partsLines.items}
        values={values}
        setValues={setValues}
        page={paging.page}
        readMode={readMode}
      />
      {!readMode && <ButtonActions
        createLabel="Save"
        createClick={saveCheckResults}
        disabledCreate={values.some((el) => !el.status)}
        cancelClick={cancelHandle}
        isLoading={isFetching}
        offsetTop
      />}
    </Modal>
  );
};

export default QualityCheckModal;
