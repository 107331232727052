import React, { useEffect, useState } from 'react';
import {
  Control, Controller, FieldErrors, UseFormSetError, UseFormSetValue,
} from 'react-hook-form';
import { DictionaryStringValueItem, VehicleDto } from '../../../core/types/coreTypes';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import { useAppSelector } from '../../../store/hooks';
import { vehicleProfilesTypesUiSelector } from '../../../store/selectors/coreSelectors';
import {
  vehicleManufacturersDictionarySelector,
  vehicleProfilesGroupsUiSelector,
} from '../../../store/selectors/sharedSelectors';
import Autocomplete from '../../../core/components/autocomplete/Autocomplete';
import {
  CreateVehicleFields, ModelDescriptionFieldValidationSchema,
  VehicleManufacturerFieldValidationSchema,
} from '../../../vehicles/create-vehicle/types/createVehicleFormSchema';

type VehicleManualFieldsProps = {
  control: Control<CreateVehicleFields>,
  errors: FieldErrors<CreateVehicleFields>,
  setError: UseFormSetError<CreateVehicleFields>,
  setValue: UseFormSetValue<CreateVehicleFields>
  disabled?: boolean,
  isModelDescriptionEditable?: boolean,
  editHandler?: (v: Partial<VehicleDto>, key: string) => void,
  clearOtherErrorsFn?: (key: string) => void,
}

const VehicleManualFields: React.FC<VehicleManualFieldsProps> = ({
  control, errors, setError, setValue, disabled,
  isModelDescriptionEditable, editHandler, clearOtherErrorsFn,
}) => {
  const manufacturersList = useAppSelector(vehicleManufacturersDictionarySelector);
  const [manufacturers, setManufacturers] = useState<DictionaryStringValueItem[]>([]);
  useEffect(() => {
    if (manufacturersList) {
      setManufacturers(manufacturersList);
    }
  }, [manufacturersList]);

  const vehicleProfilesGroups = useAppSelector(vehicleProfilesGroupsUiSelector);
  const vehicleProfilesTypes = useAppSelector(vehicleProfilesTypesUiSelector);
  return (
    <div className="vehicleManualFields">
      <Controller
        name="manufacturer"
        control={control}
        rules={VehicleManufacturerFieldValidationSchema}
        render={({ field }) => (
          <Autocomplete
            label="Manufacturer"
            value={field.value}
            onChange={(v) => {
              field.onChange(v);
              setError('manufacturer', { type: 'required', message: undefined });
            }}
            options={manufacturers}
            onSearch={(value) => {
              if (value.trim()) {
                const list = manufacturersList.filter((el) => el.label.toLowerCase().includes(value.trim().toLowerCase()));
                setManufacturers(list);
              } else setManufacturers(manufacturersList);
            }}
            onBlur={() => {
              if (!field.value?.trim()) {
                setError('manufacturer', { type: 'required', message: ErrorsEnum.REQUIRED });
                setValue('manufacturer', '');
              }
            }}
            error={errors.manufacturer?.message}
            disabled={disabled}
            parentRender
            className="vehicleManualFields__field--2"
          />
        )}
      />
      <Controller
        name="type"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={vehicleProfilesTypes}
            error={errors.type?.message}
            label="Type"
            parentRender
            disabled={disabled}
            className="vehicleManualFields__field--2"
          />
        )}
      />
      <Controller
        name="group"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={vehicleProfilesGroups}
            error={errors.group?.message}
            label="Group"
            parentRender
            disabled={disabled}
            className="vehicleManualFields__field--2"
          />
        )}
      />
      <Controller
        name="modelDescription"
        control={control}
        rules={ModelDescriptionFieldValidationSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(v) => {
              field.onChange(v);
              clearOtherErrorsFn && clearOtherErrorsFn('modelDescription');
            }}
            onBlur={() => {
              if (!field.value?.trim()) {
                setError('modelDescription', { type: 'required', message: ErrorsEnum.REQUIRED });
                setValue('modelDescription', '');
              } else {
                editHandler && !errors.modelDescription && editHandler({ modelDescription: field.value.trim() }, 'modelDescription');
              }
            }}
            error={errors.modelDescription?.message}
            label="Model description"
            className="vehicleManualFields__field--6"
            disabled={!isModelDescriptionEditable && disabled}
          />
        )}
      />
    </div>
  );
};

export default VehicleManualFields;
