import React from 'react';
import StockCardCounter from './StockCardCounter';
import './StockCardsSection.scss';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../permissions-handling/permissionHook';

type CardsSectionProps = {
  title: string,
  items: { label: string, value: number, url: string, isActive?: boolean, status?: number }[]
  permission: PermissionEnum,
  cardClick?: (isActive: boolean, status: number) => void,
}
const StockCardsSection:React.FC<CardsSectionProps> = ({
  title, items, permission, cardClick,
}) => {
  const allowed = usePermission(permission);
  return (
    <section className="stockCardsSection">
      <h2 className="stockCardsSection__title">{title}</h2>
      <div className="stockCardsSection__cards">
        {items.map((item) => (
          <StockCardCounter
            key={item.label}
            data={item}
            allowed={!!allowed}
            to={item.url}
            onClick={() => cardClick && item.status && cardClick(!!item.isActive, item.status)}
          />
        ))}
      </div>
    </section>
  );
};

export default StockCardsSection;
