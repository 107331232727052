import { Moment } from 'moment';
import { DataNode } from 'antd/lib/tree';
import { AxiosError, AxiosResponse } from 'axios';
import React from 'react';
import { RcFile } from 'antd/es/upload';
import { AttachmentsOrderingEnum, PermissionEnum } from '../enums/dictionariesEnums';
import { AddressFields, AddVehicleRequest, ContactDto } from '../../common/types/commonTypes';

// 1) Function types
export type VoidFunctionType = () => void;
export type GenericVoidFunctionType<T> = (value: T) => void;
export type StringFunctionType = GenericVoidFunctionType<string>;
export type NumberFunctionType = GenericVoidFunctionType<number>;
export type BooleanFunctionType = GenericVoidFunctionType<boolean>;
export type PromiseVoidFunctionType = () => Promise<void>;

// 2) event handlers & etc.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomAny = any;
export type ChangeEventHandler<T> = React.ChangeEventHandler<T>;
export type KeyboardEventHandler<T> = React.KeyboardEventHandler<T>;
export type FocusEventHandler<T> = React.FocusEventHandler<T>;

// 3) Components
export type TabOptionType = {
  label: string,
  value: string,
  restrictedTo: PermissionEnum,
}

export type PickerValues = { min: string| null, max: string | null };
export type NumericRangeType = { min: number, max: number }
export type StringRangeType = { min: string, max: string }

// 4) API res type

export interface DictionaryItem {
  value: number,
  label: string,
}

export interface DictionaryStringValueItem {
  value: string,
  label: string,
}

export interface CustomDictionaryItem extends DictionaryItem {
  isAllowed?: boolean,
}

export type KeywordFilterType = {
  label: string,
  name: string,
  value?: number[] | boolean[] | string[],
  options: DictionaryItem[] | CustomDictionaryItem[] | DictionaryStringValueItem[],
  noSearch?: boolean,
  noClear?: boolean,
}
export type KeywordFilterDatePickerType = {
  label: string,
  name: string,
  value?: { min?: string, max?: string },
  disabledDate?: (current: Moment) => boolean,
}
export type KeywordFilterTreeType = {
  label: string,
  name: string,
  value?: number[],
  options: DataNode[],
}

export type PagingType = { page: number, pageSize: number }
export type OrderingType = { field: number | undefined, isAsc: boolean }
export type PagingWithOrdering = PagingType & { order?: OrderingType };

// eslint-disable-next-line max-len
export type DataListType<T, E extends Record<string, unknown> = Record<string, unknown>> = { items: T, pages: number, totalCount: number } & E;
export const initDataList = { items: [], pages: 0, totalCount: 0 };
export const initUndefinedList = { items: undefined, pages: 0, totalCount: 0 };
export const initDataListWithIds = { ...initDataList, allItemIds: [] };
export const infiniteScrollInitPaging = { page: 1, pageSize: 50 };
export const initPaging = { page: 1, pageSize: 10 };
export const initSmallPaging = { page: 1, pageSize: 5 };
export const emptyPaging = { page: 0, pageSize: 0 };

export type ServerErrorStatusCode = { status?: string, httpCode?: number };
export type ServerErrorType = { key: string, message: string };
export type ResponseResult<D = null, E extends Record<string, unknown> = Record<string, unknown>> = {
  data: DataListType<D, E>,
  success: boolean,
  errors: Array<ServerErrorType>,
  status: string
}

export type ResponseSingleResult<D = null> = {
  data: D,
  success: boolean,
  errors: Array<ServerErrorType>,
  status: string
}

export type DictionaryResponse = ResponseSingleResult<{ items: DictionaryItem[] }>
export interface CoreAttachmentDto {
  id: number,
  enquiryId?: number,
  entityId: number,
  name: string | null,
  sizeBytes: number,
  uploadDate: string,
  comment: string | null,
  type?: number,
  isFromLinkedEntity: boolean,
  showInLinkedJobs: boolean,
}

export type GetAttachmentFileResponse = { name: string, uri: string, type?: number }

export type PatchAttachmentModel = { comment?: string, showInLinkedJobs?: boolean }

export type CreateUploadUriResponse = {
  uploadUriDtos: Array<{ uploadUri: string | null, uploadGuid: string }>
}

export type DownloadUriResponse = ResponseSingleResult<{ downloadUri: string }>

export type AxiosGenericResponse<T> = Promise<AxiosResponse<T>>
export type AxiosSingleResponse<T> = Promise<AxiosResponse<ResponseSingleResult<T>>>
// eslint-disable-next-line max-len
export type AxiosDataListResponse<T, E extends Record<string, unknown> = Record<string, unknown>> = Promise<AxiosResponse<ResponseResult<T, E>>>
export type AxiosDictionaryResponse = Promise<AxiosResponse<DictionaryResponse>>
export type AxiosDownloadUriResponse = AxiosSingleResponse<{ downloadUri: string }>
export type AxiosNullableResponse = AxiosSingleResponse<null>
export type AxiosCreatedIdResponse = AxiosSingleResponse<{ createdId: number }>
export type AxiosGetCustomerContactsResponse = AxiosDataListResponse<ContactDto[], { allItemIds: number[] }>;
export type AxiosGetPagedAttachmentsResponse = AxiosDataListResponse<CoreAttachmentDto[], { allItemIds: number[] }>;
export type AxiosGetAttachmentFileResponse = AxiosSingleResponse<GetAttachmentFileResponse>;
export type AxiosCreateUploadUriResponse = AxiosSingleResponse<CreateUploadUriResponse>;
export type AxiosCreatedAttachmentsResponse = AxiosSingleResponse<{ createdItems: Array<{ id: number }> }>;

export type AxiosErrorResponse = AxiosError<{ errors: ServerErrorType[], status: string }>

// 5) Others
export interface IProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: React.MouseEventHandler;
}
export type GenericObject<T, > = { [key: string]: T }

export type RangeTableFiltersType<T = [string, string]> = {
  [K: string]: T
}

export type SearchTableFilterType<T = string | undefined> = {
  [K: string]: T
}

export type DateRangeTableFilterType = {
  [K: string]: [Moment | null, Moment | null]
}

export type MyPick<T, K extends keyof T> = {
  [p in K]: T[p]
}

export type LocationStateType = {
  pathname: string,
  search: string,
}

export type GenericColumnType<T> = {
  status: number,
  filteredTotalCount: number,
  totalCount: number,
  totalCost?: number,
  pages: number,
  isHidden: boolean,
  order: number,
  list: T,
}

export type CollectionColumnPaginationType = {
  status: number,
  currentPage: number,
  pages: number,
}

export type EntityLinkType = {
  entityId: number,
  type: number,
  entityName: string | null,
  status: string | number,
  createdDate: string,
  modifiedDate: string,
}

export type EntityLinkRowType = {
  name: string | number,
  parentLink: string,
  permission: PermissionEnum,
  children: EntityLinkType[],
}

export type AssigneeDto = {
  id: number,
  firstName: string | null,
  lastName: string | null,
}

export type CoreAttachmentsFilters = {
  page: number,
  pageSize: number,
  order?: OrderingType,
  name?: string,
  uploadDate?: StringRangeType,
  comment?: string,
}

export const initialCoreAttachmentsFilters = {
  ...initPaging,
  order: { field: AttachmentsOrderingEnum.UploadDate, isAsc: true },
};

export type CoreAttachmentsList = DataListType<CoreAttachmentDto[], { allItemIds: number[] }>;
export type GetAttachmentsResponse = ResponseResult<CoreAttachmentDto[], { allItemIds: number[] }>;

export type CoreCustomerContactsList = DataListType<ContactDto[], { allItemIds: number[] }>;
export type GetCustomerContactsResponse = ResponseResult<ContactDto[], { allItemIds: number[] }>;

export type CoreAttachListElType = {
  fileName: string,
  uploadGuid: string,
  file: RcFile,
  uploadUri?: string,
}

export interface PostAttachReqType {
  fileName: string,
  uploadGuid: string,
  comment: string | null,
}

export interface PostAttachReqWithType extends PostAttachReqType {
  type: number,
}

export type LinksFiltersType = {
  order: OrderingType,
  entity?: string,
  createdDate?: StringRangeType,
  modifiedDate?: StringRangeType,
}
export const initLinksFilters = { order: { field: 1, isAsc: true } };

export type CoreEntityImageDataType = { id: number | null, uri: string | null };
export const initEntityImageData: CoreEntityImageDataType = { id: null, uri: null };

export type VehicleDto = {
  id: number,
  vehicleFlow: number,
  vehicleCode: string | null,
  manufacturer: string | null,
  modelDescription: string | null,
  regNumber: string | null,
  chassisNumber: string | null,
  additionalInfo: string | null,
  customerId: number,
  customerName: string | null,
  alwaysUseCustomerRequisites: boolean,
  vehicleInfoSource: number,
  status: number,
  creationDate: string,
  modifiedDate: string,
  assigneeId: number | null,
  assigneeFullName: string | null,
  isAssigneeDeleted: boolean | null,
  type: number,
  group: string | null,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: number,
  maxPayload: number,
  attachmentsCount: number,
  linksCount: number,
  statusTransitions: number[] | null,
} & AddressFields

export type LinkedVehicleDto = {
  vehicleStatus: number,
  vehicleInfoSource: number,
  vehicleProfileId: number | null,
  vehicleProfileCode: string | null,
  manufacturer: string | null,
  modelDescription: string | null,
  vehicleProfileType: number,
  vehicleProfileGroup: number,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: number | null,
  maxPayload: number | null,
  regNumber: string | null,
  chassisNumber: string | null,
  vehicleId: number | null,
  vehicleCompanyName: string | null,
  vehicleAddressLine1: string | null,
  vehicleAddressLine2: string | null,
  vehicleCityTown: string | null,
  vehicleRegion: string | null,
  vehiclePostalCode: string | null,
  vehicleTelephone: string | null,
}

export type VehicleModalEntityType = AddVehicleRequest & {
  code: string | null;
  tabName?: string | null;
}

export type CustomerDictionaryDto = {
  id: number
  name: string | null,
  accountNumber: string | null,
  status: number,
}

export type SupplierDictionaryDto = {
  id: number,
  supplierAccountNumber: string,
  supplierName: string,
}

export type BasicNoteEventProps = {
  id?: number;
  content?: string,
  initiator?: string | null,
  disabledManageNotes?: boolean,
}

export type FieldUpdateEventProps = {
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

export type UploadUriDto = { fileName: string, uploadGuid: string }

export type AttachmentsFiltersProps = {
  filters: CoreAttachmentsFilters,
  totalItems: number,
  setCreateModal: BooleanFunctionType,
  setDeleteModal: BooleanFunctionType,
  checkedKeys: number[],
  disabledActions?: boolean,
}

export type AttachmentsTableProps = {
  attachments: CoreAttachmentDto[];
  checkedKeys: number[];
  isAll: boolean;
  indeterminate: boolean,
  checkAllHandle: VoidFunctionType,
  checkRow: NumberFunctionType,
  disabledActions?: boolean,
  readOnly?: boolean,
  getAttachment?: NumberFunctionType,
}

export type AttachmentsColumnsProps = {
  isAll: boolean;
  indeterminate: boolean,
  checkAll: BooleanFunctionType;
  totalElements: number;
  disabledActions?: boolean,
  readOnly?: boolean,
}

export type AttachmentsCellProps = {
  attach: CoreAttachmentDto,
  openEditModal: VoidFunctionType,
  checked: boolean,
  checkRow: NumberFunctionType,
  disabledActions?: boolean,
  readOnly?: boolean,
  getAttachment?: NumberFunctionType,
}

export type AddressSuggestionDto = {
  id: string,
  address: string,
}
export type GetAddressSuggestionsResponse = { suggestions: AddressSuggestionDto[] }

export type AddressSuggestionDetailsDto = {
  companyName: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  region: string | null,
  postalCode: string | null,
}
export type GetAddressSuggestionResponse = { address: AddressSuggestionDetailsDto }
