import React from 'react';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { UserAccountDto } from '../../types/userProfileTypes';
import Tag from '../../../../core/components/tag/Tag';
import { accountAccountStatusSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type UserHeadProps = {
  user: UserAccountDto | null,
}

const UserHead: React.FC<UserHeadProps> = ({ user }) => {
  const accountStatus = useAppSelector(accountAccountStatusSelector);
  const entries = [
    { label: '', value: user ? `${user?.firstName} ${user?.lastName}` : '-' },
    { label: 'Status', value: accountStatus.find((st) => st.value === user?.status)?.label || '-' },
    { label: 'Created date', value: user?.createdAt ? localDateFormatHandler('DD-MMM-YYYY', user?.createdAt) : '-' },
    { label: 'Modified date:', value: user?.updatedAt ? localDateFormatHandler('DD-MMM-YYYY', user?.updatedAt) : '-' },
  ];
  return (
    <>
      {entries.map((ent, i) => <div
        key={i}
        className="head__inner-info-item head__inner-info-item--narrow"
      >
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Status'
          ? <Tag label={ent.value?.toString() || '-'} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default UserHead;
