import React from 'react';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../core/components/table-components/Tr';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tbody from '../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../store/hooks';
import { stockPartRequestsViewsConfirmedSelector } from '../../../../store/selectors/stockPartRequestsSelector';
import { StringFunctionType } from '../../../../core/types/coreTypes';

type SelectConfirmedPartRequestsBodyProps = {
  checkedKeys: string[],
  checkHandle: StringFunctionType,
}

const SelectConfirmedPartRequestsBody: React.FC<SelectConfirmedPartRequestsBodyProps> = ({ checkedKeys, checkHandle }) => {
  const hiddenKeys = ['id'];
  const { totalCount, items } = useAppSelector(stockPartRequestsViewsConfirmedSelector);

  return (
    <Tbody>
      {totalCount === 0
        ? <EmptyTableSection text="No records" colSpan={6} />
        : <>
          {items.map((part, i) => <Tr key={i}>
            <TdNew>
              <Checkbox
                checked={checkedKeys.includes(part.id)}
                onChange={() => checkHandle(part.id)}
                table
              />
            </TdNew>
            {Object.entries(part).map(([key, value]) => {
              if (hiddenKeys.includes(key)) return null;
              return <TdNew key={key}>
                {key === 'quantity'
                  ? value
                  : <TruncateTooltip
                    value={(value as string | number) || '-'}
                    table
                  />}
              </TdNew>;
            })}
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default SelectConfirmedPartRequestsBody;
