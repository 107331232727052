import React from 'react';
import { AttachmentsColumnsProps } from '../../../../../../core/types/coreTypes';
import { setAttachmentsTableFilters } from '../../../../../../store/slices/enquiriesSlice';
import { useAppSelector } from '../../../../../../store/hooks';
import { enqAttachmentsFiltersSelector } from '../../../../../../store/selectors/enquiriesSelector';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import AttachmentColumns from '../../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../../core/hooks/useCoreAttachmentsColumns';

const EnquiryAttachmentsColumns: React.FC<AttachmentsColumnsProps> = ({
  isAll,
  checkAll,
  totalElements,
  indeterminate,
  disabledActions,
}) => {
  const allowedManage = usePermission(PermissionEnum.EnquiryEditFields);
  const attachmentsFilters = useAppSelector(enqAttachmentsFiltersSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachmentsFilters, setAttachmentsTableFilters);

  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      disabledActions={disabledActions}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      totalElements={totalElements}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={attachmentsFilters}
    />
  );
};

export default EnquiryAttachmentsColumns;
