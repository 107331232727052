import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  cancelledLinesDataSelector,
  cancelledLinesFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { setCancelledLinesFilters } from '../../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { getCancelledLinesReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import CancelledLinesTable from './table/CancelledLinesTable';

const CancelledLinesContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(cancelledLinesFiltersSelector);
  const { totalCount } = useAppSelector(cancelledLinesDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => {
            const newFilters = {
              ...filters,
              page,
              pageSize,
            };
            dispatch(setCancelledLinesFilters(newFilters));
            reportIsVisible && dispatch(getCancelledLinesReportThunk({ filters: newFilters }));
          }}
          className="actions-and-paging__paging"
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
        />
      </div>
      <CancelledLinesTable />
    </>
  );
};

export default CancelledLinesContent;
