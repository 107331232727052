import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import OrdersAttachmentFilters from './filters/OrdersAttachmentFilters';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { orderAttachmentsFiltersSelector, orderAttachmentsSelector } from '../../../../../store/selectors/ordersSelector';
import CreateAttachmentModal from '../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import OrderAttachmentsTable from './table/OrderAttachmentTable';
import {
  createOrderAttachmentThunk, deleteMultipleOrderAttachmentThunk,
  getOrderAttachmentsThunk,
} from '../../../../../store/thunks/orders/orderAttachmentsThunks';
import { guidGenerator } from '../../../../../core/utils/guidGenerator';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

type OrderAttachmentsProps = {
  isCurrentStatusArchived: boolean,
}

const OrderAttachments: React.FC<OrderAttachmentsProps> = ({ isCurrentStatusArchived }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(orderAttachmentsSelector);
  const filters = useAppSelector(orderAttachmentsFiltersSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    id && dispatch(getOrderAttachmentsThunk({ orderId: +id, filters }));
    // eslint-disable-next-line
  },[id, filters]);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  const uploadHandle = async () => {
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createOrderAttachmentThunk({
      id: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultipleOrderAttachmentThunk({
      ids: checkedKeys,
      orderId: +id,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />

      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />

      <OrdersAttachmentFilters
        filters={filters}
        totalItems={totalCount}
        setCreateModal={setCreateModal}
        setDeleteModal={() => setDeleteModal(true)}
        checkedKeys={checkedKeys}
        disabledActions={isCurrentStatusArchived}
      />
      <OrderAttachmentsTable
        attachments={items}
        checkedKeys={checkedKeys}
        isAll={isAll}
        indeterminate={isIndeterminate}
        checkAllHandle={checkAllHandle}
        checkRow={checkRowHandle}
        disabledActions={isCurrentStatusArchived}
      />
    </>
  );
};

export default OrderAttachments;
