import { z } from 'zod';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { EMAIL_REGEX } from '../../../core/utils/regex';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const ForgotPasswordSchema = z.object({
  email: RequiredStringSchema.trim()
    .regex(EMAIL_REGEX, 'Please enter a valid email address.'),
});

export type ForgotPasswordFields = z.infer<typeof ForgotPasswordSchema>;

export const forgotPasswordDefaults: ForgotPasswordFields = {
  email: '',
};
