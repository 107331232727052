import React from 'react';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import {
  partsKitComponentsPartsFiltersSelector,
  partsKitComponentsPartsTableSelector,
} from '../../../../../../../store/selectors/partsKitSelectors';
import { useAppSelector } from '../../../../../../../store/hooks';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../../../core/components/checkbox/Checkbox';
import { NumberFunctionType } from '../../../../../../../core/types/coreTypes';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';

type CoreNonCoreBodyProps = {
  checkedKeys: number[],
  checkRow: NumberFunctionType,
}

const CoreNonCoreBody: React.FC<CoreNonCoreBodyProps> = ({ checkedKeys, checkRow }) => {
  const partsTable = useAppSelector(partsKitComponentsPartsTableSelector);
  const filters = useAppSelector(partsKitComponentsPartsFiltersSelector);

  return (
    <Tbody>
      {partsTable.totalCount === 0
        ? <EmptyTableSection
          text={filters.keyword && partsTable.totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
          colSpan={6}
        />
        : <>
          {partsTable.items.map((part, i) => <Tr key={i}>
            <TdNew>
              <Checkbox
                checked={checkedKeys.includes(part.id)}
                onChange={() => checkRow(part.id)}
                table
              />
            </TdNew>
            {Object.entries(part).map(([key, value]) => {
              if (key === 'id') return null;
              return <TdNew key={key}>
                <TruncateTooltip value={value} table />
              </TdNew>;
            })}
          </Tr>)}
        </>}
    </Tbody>
  );
};
export default CoreNonCoreBody;
