import { useAppSelector } from '../../../store/hooks';
import {
  assigneesFilterSelector,
  enquiriesFiltersSelector, permittedEnqDashStatusesSelector, sortedEnqCollectionsSelector,
} from '../../../store/selectors/enquiriesSelector';
import {
  currentBusinessAreaSelector, enquiryWarningsSelector,
} from '../../../store/selectors/coreSelectors';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';

export const useEnquiryContainerSelectors = () => {
  const enquiryCollections = useAppSelector(sortedEnqCollectionsSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const dashFilters = useAppSelector(enquiriesFiltersSelector);
  const assigneesDashLookup = useAppSelector(assigneesFilterSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const permittedEnqDashStatuses = useAppSelector(permittedEnqDashStatusesSelector);
  const warnings = useAppSelector(enquiryWarningsSelector);
  return {
    enquiryCollections,
    currentBusinessArea,
    dashFilters,
    assigneesDashLookup,
    currentUser,
    permittedEnqDashStatuses,
    warnings,
  };
};
