import { SalesOffsiteWorkingReportData } from '../../../../../types/SalesOffsiteWorkingReportTypes';
import { initDataList } from '../../../../../../core/types/coreTypes';

export const initSalesOffsiteWorkingReportData: SalesOffsiteWorkingReportData = {
  ...initDataList,
  minDistanceFromOfficeMiles: 0,
  maxDistanceFromOfficeMiles: 0,
};

export const DEFAULT_ZOOM = 7;
export const DEFAULT_CENTER = {
  lat: 53.5271516,
  lng: -2.5436137,
};
export const YELLOW_COLOR = 'rgb(255, 203, 17)';
export const DARK_BLUE_COLOR = 'rgb(58, 69, 87)';
