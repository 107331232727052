import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { purchaseOrderPartsListSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../../core/components/table-components/Tr';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import Dropdown from '../../../../../../../core/components/dropdown/Dropdown';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../../../../../core/components/select/Select';
import Button from '../../../../../../../core/components/button/Button';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import {
  integerQuantityMeasureValuesSelector, isFetchingSelector, isGlobalLoadingSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import { patchPurchaseOrderPartThunk } from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { ordersDictionarySelector } from '../../../../../../../store/selectors/sharedSelectors';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { getOrderDictionary } from '../../../../../../../store/thunks/coreThunk';
import { useCancelRequest } from '../../../../../../../core/hooks/useCancelRequest';

type LinkReservedToOrdersModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  itemToChange?: number,
}

const columns = [
  { dataIndex: 'ordinalNumber', title: 'No' },
  { dataIndex: 'linkedTo', title: 'Linked to' },
  { dataIndex: 'quantity', title: 'Quantity' },
];

type LinkedTableItem = {
  orderId: number,
  ordinalNumber: number,
  quantity: number,
  linkedTo: string | null,
  isAllocated: boolean,
}

const LinkReservedToOrdersModal: React.FC<LinkReservedToOrdersModalProps> = ({ visible, onCancel, itemToChange }) => {
  const { id } = useParams();
  const isFetching = useAppSelector(isFetchingSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const dispatch = useAppDispatch();
  const ordersDictionary = useAppSelector(ordersDictionarySelector);
  const { purchaseOrderParts } = useAppSelector(purchaseOrderPartsListSelector);
  const currentPart = useMemo(() => purchaseOrderParts.find((el) => el.id === itemToChange), [purchaseOrderParts, itemToChange]);
  const linkedToOrderReceivedReservedQuantities = currentPart?.linkedToOrderReceivedReservedQuantities;
  const reservedPartQuantity = currentPart?.receivedReservedQuantity;
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const measure = useMemo(() => unitsOfMeasure.find((el) => el.value === currentPart?.unitOfMeasure)?.label, [unitsOfMeasure, currentPart]);

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const [qty, setQty] = useState<string>('0');
  const [orderId, setOrderId] = useState<number | undefined>(undefined);
  const [values, setValues] = useState<Array<LinkedTableItem>>([]);

  useEffect(() => {
    if (visible) {
      const newValues = linkedToOrderReceivedReservedQuantities?.length
        ? linkedToOrderReceivedReservedQuantities.map((el, i) => ({
          orderId: el.orderId,
          ordinalNumber: i + 1,
          linkedTo: `${el.orderNumber} / ${el.customerName}`,
          quantity: el.quantity,
          isAllocated: el.isAllocated,
        }))
        : [];
      setValues(newValues);
    }
  }, [visible, linkedToOrderReceivedReservedQuantities]);

  const valuesQtySum = useMemo(() => {
    return values.length
      ? values
        .map((el) => el.quantity)
        .reduce((a, b) => (a + b))
      : 0;
  }, [values]);
  const maxQtyPossibleValue = useMemo(() => (reservedPartQuantity
    ? reservedPartQuantity - valuesQtySum
    : 0), [reservedPartQuantity, valuesQtySum]);
  const isActionDisabled = valuesQtySum >= (reservedPartQuantity || 0);

  const cleanUpHandle = () => {
    setOrderId(undefined);
    setQty('0');
  };
  const onClose = () => {
    cleanUpHandle();
    onCancel();
    setValues([]);
  };

  const linkReservedHandle = () => {
    const currentQtyAndOrder = { orderId: orderId as number, quantity: +qty, isAllocated: false };
    const prevList = values.map((el) => ({ orderId: el.orderId, quantity: el.quantity, isAllocated: el.isAllocated }));
    const newValues = values.length
      ? [...prevList, currentQtyAndOrder]
      : [currentQtyAndOrder];

    if (id && itemToChange) {
      dispatch(patchPurchaseOrderPartThunk({
        purchaseOrderId: +id,
        partId: itemToChange,
        values: {
          linkedToOrderReceivedReservedQuantities: newValues,
          // this prop is mandatory according to current BE implementation
          receivedReservedQuantity: reservedPartQuantity,
        },
        closeModal: () => {
          cleanUpHandle();
          setValues([]);
        },
      }));
    }
  };

  const unlinkReservedHandle = (itemId: number) => {
    const newValues = values.map((el) => {
      if (el.orderId === itemId) {
        return { orderId: el.orderId, quantity: 0, isAllocated: false };
      } else return { orderId: el.orderId, quantity: el.quantity, isAllocated: el.isAllocated };
    });
    if (id && itemToChange) {
      dispatch(patchPurchaseOrderPartThunk({
        purchaseOrderId: +id,
        partId: itemToChange,
        values: {
          linkedToOrderReceivedReservedQuantities: newValues,
          // this prop is mandatory according to current BE implementation
          receivedReservedQuantity: reservedPartQuantity,
        },
        closeModal: () => {
          cleanUpHandle();
          setValues([]);
        },
      }));
    }
  };

  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    let timeoutId: NodeJS.Timeout;
    if (searchValue) {
      timeoutId = setTimeout(() => {
        cancelRequest();
        dispatch(getOrderDictionary({
          filters: {
            keyword: searchValue,
            includeOrderNumber: true,
            includeCustomerName: true,
            includeEnquiryNumbers: true,
            onlyActive: true,
          },
          signal: controller.current?.signal,
        }));
      }, 1000);
    } else {
      cancelRequest();
      dispatch(getOrderDictionary({ filters: { onlyActive: true }, signal: controller.current?.signal }));
    }
    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title="Reserved quantity restrictions"
      width={800}
      destroyOnClose
    >
      <TableNew small>
        <Thead>
          <Tr>
            {columns.map(({ dataIndex, title }) => <ThNew
              key={dataIndex}
              className={`linkReservedToOrdersTh--${dataIndex}`}
            >
              {title}
            </ThNew>)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Tr>
        </Thead>
        <Tbody>
          {values.length > 0
            ? values.map((el, i) => <Tr key={i}>
              <>
                <TdNew>{el.ordinalNumber}</TdNew>
                <TdNew><TruncateTooltip value={el.linkedTo} table /></TdNew>
                <TdNew>{`${el.quantity} ${measure}`}</TdNew>
                <TdNew action>
                  <Dropdown
                    disabled={el.isAllocated}
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    menuItems={[
                      {
                        label: 'Unlink',
                        key: 'unlinkQty',
                        onClick: () => unlinkReservedHandle(el.orderId),
                        icon: <FontAwesomeIcon icon={faTrashAlt} />,
                      },
                    ]}
                  />
                </TdNew>
              </>
            </Tr>)
            : <EmptyTableSection colSpan={columns.length + 1} text="No records" />}
        </Tbody>
      </TableNew>
      <div className="linkReservedToOrders-actions">
        <Select
          options={ordersDictionary}
          value={orderId}
          label="Linked to"
          disabled={isActionDisabled}
          searchValue={searchValue}
          loading={isLoading}
          onSearch={(v) => setSearchValue(v)}
          onChange={(v) => setOrderId(+v)}
          showSearch
          parentRender
        />
        {integerMeasures.includes(currentPart?.unitOfMeasure)
          ? <RegularInputNumber
            value={qty}
            onChange={(e) => setQty(e.target.value)}
            label="Quantity"
            max={maxQtyPossibleValue}
            postfix={measure}
            disabled={isActionDisabled}
            className="linkReservedToOrders-input"
          />
          : <DecimalInputNumber
            value={qty}
            onChange={(e) => setQty(e.target.value.replace(',', '.'))}
            label="Quantity"
            max={maxQtyPossibleValue}
            postfix={measure}
            disabled={isActionDisabled}
            className="linkReservedToOrders-input"
          />}
        <Button
          label="Link reserved"
          disabled={isActionDisabled || !+qty || !orderId}
          onClick={linkReservedHandle}
          designType="dark"
          isLoading={isFetching}
        />
      </div>
    </Modal>
  );
};

export default LinkReservedToOrdersModal;
