import React, { useEffect, useState } from 'react';
import './RequisiteSection.scss';
import { faBuilding, faEnvelope, faUser } from '@fortawesome/free-regular-svg-icons';
import {
  faMapMarkerAlt, faPhoneAlt, faMobileRetro,
} from '@fortawesome/free-solid-svg-icons';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { summaryFieldValueHandle } from '../../../core/utils/summaryFieldValueHandle';
import { BooleanFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import { RequisiteDto } from '../../types/commonTypes';

type RequisiteSectionProps = {
  requisiteDetails: RequisiteDto| null,
  setIsVisible: VoidFunctionType;
  title: string;
  isShipping?: boolean;
  onCheck?: BooleanFunctionType;
  isSame?: boolean;
  disabled?: boolean;
  className?: string,
}

const RequisiteSection: React.FC<RequisiteSectionProps> = ({
  requisiteDetails, setIsVisible, title, isSame, onCheck, isShipping, disabled,
  className,
}) => {
  const [requisite, setRequisite] = useState<RequisiteDto | null>(null);
  useEffect(() => {
    if (requisiteDetails) {
      setRequisite(requisiteDetails);
    } else {
      setRequisite(null);
    }
  }, [requisiteDetails]);
  useEffect(() => {
    return () => {
      setRequisite(null);
    };
  }, []);

  const values = summaryFieldValueHandle([
    requisite?.addressLine1,
    requisite?.addressLine2,
    requisite?.cityTown,
    requisite?.region,
    requisite?.postalCode,
  ]);

  const name = () => {
    if (requisite?.firstName && requisite?.lastName) return `${requisite?.firstName} ${requisite?.lastName}`;
    if (requisite?.firstName && !requisite?.lastName) return `${requisite?.firstName}`;
    if (!requisite?.firstName && requisite?.lastName) return `${requisite?.lastName}`;
    else return '';
  };

  const entities = [
    { icon: faBuilding, label: requisite?.companyName },
    { icon: faUser, label: name() },
    { icon: faEnvelope, label: requisite?.email },
    { icon: faPhoneAlt, label: requisite?.telephone },
    { icon: faMobileRetro, label: requisite?.mobile },
    {
      icon: faMapMarkerAlt,
      label: values,
    },
  ];

  return (
    <div className={classnames(className, 'requisiteSection__billing')}>
      <span className="requisiteSection__billing-label">{title}</span>
      <div
        className={classnames('requisiteSection__billing-entities', { 'billingEntitiesDisabled': disabled || isSame })}
        onClick={() => {
          if (!disabled) {
            if (isShipping) {
              !isSame && setIsVisible();
            } else {
              setIsVisible();
            }
          }
        }}
        tabIndex={disabled || isSame ? -1 : 0}
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            !disabled && setIsVisible();
          }
        }}
        role="button"
      >
        {requisite && entities.map((ent, i) => <div
          className={classnames('requisiteSection__billing-entities-line', {
            'empty': !ent.label,
          })}
          key={i}
        >
          <FontAwesomeIcon icon={ent.icon} className="requisiteSection__billing-entities-line__icon" />
          <span className="requisiteSection__billing-entities-line__label">{ent.label}</span>
        </div>)}
      </div>
      {isShipping && <Checkbox
        label="Same as billing details"
        checked={isSame}
        disabled={disabled}
        onChange={(val) => onCheck && onCheck(val)}
        className="requisiteSection__billing-checkbox"
      />}
    </div>

  );
};

export default RequisiteSection;
