import React from 'react';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';
import PartViewPartsKitsItem from './PartViewPartsKitsItem';
import { PartsKitsDto } from '../../../../../../parts-kits/dashboard/types/partsKitsTypes';
import EmptyContentList from '../../../../../../../core/components/empty-content-list/EmptyContentList';

type PartViewPartsKitsListProps = {
  items: PartsKitsDto[] | undefined,
}
const PartViewPartsKitsList:React.FC<PartViewPartsKitsListProps> = ({ items }) => {
  return (
    <ul className="partViewPartsKitsList lines-list">
      {items && items.length > 0
        ? items.map((item) => (
          <li key={item.id} className="lines-list__item">
            <PartViewPartsKitsItem data={item} />
          </li>
        ))
        : <li>
          <EmptyContentList icon={faBoxOpen} text="No records" className="partViewPartsKitsList__empty" />
        </li>}
    </ul>
  );
};

export default PartViewPartsKitsList;
