import qs from 'qs';
import instance from './axiosConfig';
import {
  PricelistDashFiltersType,
  PricelistDto,
  PricelistPartDto,
  PricesFiltersType,
} from '../pricelists/dashboard/types/pricelistTypes';
import {
  PartPricesFilters,
  PricelistAffectedDto,
  PricelistAffectedFilters,
  PricelistEventDtoBase,
  PricelistPartPriceDto, PricelistSupplier,
  SavePricelistPartPriceDto, StockCategoriesFiltersType,
} from '../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import {
  AxiosCreatedAttachmentsResponse,
  AxiosCreatedIdResponse,
  AxiosCreateUploadUriResponse,
  AxiosDataListResponse, AxiosDownloadUriResponse,
  AxiosGetAttachmentFileResponse, AxiosGetPagedAttachmentsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  PostAttachReqType,
  UploadUriDto,
} from '../core/types/coreTypes';
import { ExtendedTimelineFilters } from '../common/types/commonTypes';
import { CreatePricelistRequest } from '../pricelists/create-pricelist/utils/PricelistSchema';

export const pricelistAPI = {
  fetchPricelists(filters: PricelistDashFiltersType, signal?: AbortSignal): AxiosDataListResponse<PricelistDto[]> {
    return instance.get(
      '/pricelist/pricelist',
      {
        params: {
          'Filter.Keyword': filters.keyword,
          'Filter.Types': filters.types,
          'Filter.BusinessAreas': filters.businessAreas,
          'Filter.Statuses': filters.statuses,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  createPricelist(data: CreatePricelistRequest): AxiosCreatedIdResponse {
    return instance.post('/pricelist/pricelist', { ...data });
  },
  fetchPricelistById(id: number): AxiosSingleResponse<{ pricelist: PricelistDto }> {
    return instance.get(`/pricelist/pricelist/${id}`);
  },
  patchPricelist(id: number, data: Partial<PricelistDto>): AxiosNullableResponse {
    return instance.patch(`/pricelist/pricelist/${id}`, { ...data });
  },
  duplicatePricelist(id: number): AxiosCreatedIdResponse {
    return instance.post(`/pricelist/pricelist/duplicate/${id}`);
  },
  activatePricelist(id: number): AxiosNullableResponse {
    return instance.post(`/pricelist/pricelist/activate/${id}`);
  },
  inactivatePricelist(id: number): AxiosNullableResponse {
    return instance.post(`/pricelist/pricelist/inactivate/${id}`);
  },
  deletePricelist(id: number): AxiosNullableResponse {
    return instance.delete(`/pricelist/pricelist/${id}`);
  },
  getPricelistAffected(pricelistId: number, filters: PricelistAffectedFilters): AxiosDataListResponse<PricelistAffectedDto[]> {
    const queryParams: string[] = [];
    if (filters.newPrices?.length) {
      for (let i = 0; i < filters.newPrices.length; i++) {
        const p = filters.newPrices[i];
        queryParams.push(
          // eslint-disable-next-line max-len
          `NewPrices[${i}].sellPrice=${p.sellPrice}&NewPrices[${i}].costPrice=${p.costPrice}&NewPrices[${i}].effectiveStartDate=${p.effectiveStartDate}`,
        );
      }
    }
    const queryString = queryParams.join('&');
    const pricesString = filters.newPrices ? queryString : 'NewPrices=null';
    const url = '/pricelist/pricelist/affected';
    const finalUrl = filters.newPrices ? `${url}?${pricesString}` : url;

    return instance.get(finalUrl, {
      params: {
        'PricelistId': pricelistId,
        'PartIds': filters.partIds,
        'CustomerIds': filters.customerIds,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getPricesParts(id: number, filters: PricesFiltersType): AxiosDataListResponse<PricelistPartDto[], { allItemIds: number[] }> {
    return instance.get('/pricelist/price/part', {
      params: {
        'PricelistId': id,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'Filter.Keyword': filters.keyword,
        'Filter.ProductNumber': filters.productNumber,
        'Filter.ProductName': filters.productName,
        'Filter.CategoryIds': filters.categoryIds,
        'Filter.SubcategoryIds': filters.subCategoryIds,
        'Filter.CostPriceFrom': filters.costPrice?.from,
        'Filter.CostPriceTo': filters.costPrice?.to,
        'Filter.SellPriceFrom': filters.sellPrice?.from,
        'Filter.SellPriceTo': filters.sellPrice?.to,
        'Filter.ModifiedDateFrom': filters.modifiedDate?.from,
        'Filter.ModifiedDateTo': filters.modifiedDate?.to,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPartPricelistCategories(filters?: StockCategoriesFiltersType) {
    return instance.get('/stock/parts/categories', {
      params: {
        'Filter.Keyword': filters?.keyword,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
      },
    });
  },
  fetchPartPricelistSubcategories(filters?: StockCategoriesFiltersType) {
    return instance.get('/stock/parts/categories/subcategories', {
      params: {
        'Filter.Keyword': filters?.keyword,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
      },
    });
  },
  partCreate(pricelistId: number, partIds: number[]): AxiosSingleResponse<{
    createdPrices: Array<{ productId: number, partId: number }>,
  }> {
    return instance.post('/pricelist/price/part', { pricelistId, partIds });
  },
  partBulkDelete(pricelistId: number, partIds: number[]): AxiosNullableResponse {
    return instance.delete('/pricelist/price/part', { data: { pricelistId, partIds } });
  },
  partSingleDelete(pricelistId: number, partId: number): AxiosNullableResponse {
    return instance.delete(`/pricelist/price/part/${partId}`, {
      params: {
        'pricelistId': pricelistId,
      },
    });
  },
  fetchPartPrices(partId: number, pricelistId?: number, filters?: PartPricesFilters): AxiosDataListResponse<PricelistPartPriceDto[]> {
    return instance.get('/pricelist/price', {
      params: {
        'PartId': partId,
        'PricelistId': pricelistId,
        'PriceDate': filters?.priceDate,
        'SupplierId': filters?.supplierId,
        'ActiveOnly': filters?.activeOnly,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
      },
    });
  },
  putPartPrices(pricelistId: number, partId: number, prices: Array<SavePricelistPartPriceDto>): AxiosNullableResponse {
    return instance.put('/pricelist/price', {
      pricelistId,
      partId,
      prices,
    });
  },
  deletePricelistAttachment(pricelistId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/pricelist/${pricelistId}/attachment/${id}`);
  },
  getPricelistAttachmentById(pricelistId: number, id: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/pricelist/${pricelistId}/attachment/${id}`);
  },
  postPricelistAttachmentUploadUri(pricelistId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/pricelist/${pricelistId}/attachment/uploadUri`, { uploadUriDtos });
  },
  postPricelistAttachment(pricelistId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/pricelist/${pricelistId}/attachment`, { objectId: pricelistId, attachments });
  },
  editPricelistAttachment(pricelistId: number, id: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/pricelist/${pricelistId}/attachment/${id}`, { comment });
  },
  deleteMultiplePricelistAttachment(pricelistId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/pricelist/${pricelistId}/attachment`, { data: { attachmentIds } });
  },
  fetchPricelistAttachments(
    pricelistId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/pricelist/${pricelistId}/attachment`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  fetchPricelistSuppliers(keyword?:string): AxiosSingleResponse<{ suppliers: PricelistSupplier[] }> {
    return instance.get('/pricelist/pricelist/supplier', {
      params: {
        Keyword: keyword,
      },
    });
  },
  fetchPricelistTimeline(pricelistId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<PricelistEventDtoBase[]> {
    return instance.get('/pricelist/pricelist/timeline', {
      params: {
        'PricelistId': pricelistId,
        'MainOnly': filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postPricelistTimelineNote(pricelistId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/pricelist/note', {
      pricelistId,
      content,
    });
  },
  editPricelistTimelineNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/pricelist/note/${id}`, { content });
  },
  deletePricelistTimelineNote(id: number): AxiosNullableResponse {
    return instance.delete(`/pricelist/note/${id}`);
  },
  exportPricelistToExcel(id: number): AxiosDownloadUriResponse {
    return instance.get(`/pricelist/pricelist/${id}/excelFile`);
  },
};
