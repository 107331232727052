import React from 'react';
import { Popover as PopoverComponent } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import classNames from 'classnames';
import Button from '../button/Button';
import './Popover.scss';

type PopoverProps = {
  content: React.ReactNode,
  label?: string,
  icon?: React.ReactNode,
  disabled?: boolean,
  placement?: TooltipPlacement,
  trigger?: string | string[],
  className?: string,
  overlayClassName?: string,
  buttonClassName?: string,
  maxWidth?: string,
  maxHeight?: string,
  disablePopoverArrow?: boolean,
  destroyTooltipOnHide?: boolean,
  onVisibleChange?: (visible: boolean) => void,
}

const Popover:React.FC<PopoverProps> = ({
  content,
  label,
  icon,
  disabled,
  destroyTooltipOnHide,
  placement,
  trigger,
  className,
  overlayClassName,
  buttonClassName,
  disablePopoverArrow,
  maxWidth,
  maxHeight,
  onVisibleChange,
}) => {
  return (
    <PopoverComponent
      trigger={trigger}
      content={content}
      placement={placement}
      destroyTooltipOnHide={destroyTooltipOnHide}
      className={className}
      overlayClassName={classNames(overlayClassName, 'custom-popover', { 'custom-popover--no-arrow': disablePopoverArrow })}
      overlayInnerStyle={{ maxHeight: maxHeight || '', maxWidth: maxWidth || '', overflow: 'auto' }}
      onVisibleChange={onVisibleChange}
    >
      <Button
        icon={icon}
        className={buttonClassName}
        disabled={disabled}
        label={label}
      />
    </PopoverComponent>
  );
};

export default Popover;
