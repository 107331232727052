import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { fitmentTimeDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';

const FitmentTimeBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const { items } = useAppSelector(fitmentTimeDataSelector);

  const nowrapKeys = ['spentByAssignee', 'totalSpent', 'totalEstimated', 'difference', 'creationDate'];

  const renderCellValue = (key: string, value: string | number) => {
    if (key === 'number' || nowrapKeys.includes(key)) return value;
    return <TruncateTooltip value={value} table />;
  };

  return (
    <Tbody>
      {items.length
        ? items.map((el) => (
          <Tr key={el.number}>
            {Object.entries(el).map(([key, value]) => (
              <TdNew key={key} nowrap={nowrapKeys.includes(key)}>
                {renderCellValue(key, value)}
              </TdNew>
            ))}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text="No records" colSpan={columnsLength} />}
    </Tbody>
  );
};

export default FitmentTimeBody;
