import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import JobPartsColumns from './JobPartsColumns';
import {
  jobDetailsSelector,
  jobPartsColumnsSelector,
  jobPartsFiltersSelector,
  jobPartsTableLinesSelector,
} from '../../../../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import './JobParts.scss';
import { getJobByIdThunk, getJobPartLinesThunk } from '../../../../../store/thunks/jobs/jobThunks';
import { getPartRequestLineStatuses } from '../../../../../store/thunks/core/coreStatusesThunks';
import { stockPartRequestLinesOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { JobPartsOrdering } from '../../../enums/JobViewEnums';
import { setJobPartsFilters } from '../../../../../store/slices/jobsSlice';
import JobPartsBody from './JobPartsBody';

type JobPartsProps = {
  childJobId?: number,
}
const JobParts: React.FC<JobPartsProps> = ({ childJobId }) => {
  const { id: parentJobId } = useParams();
  const dispatch = useAppDispatch();
  const jobPartsLinesOrdering = useAppSelector(stockPartRequestLinesOrderingSelector);
  const filters = useAppSelector(jobPartsFiltersSelector);
  const jobPartsLines = useAppSelector(jobPartsTableLinesSelector);
  const columns = useAppSelector(jobPartsColumnsSelector);
  const jobDetails = useAppSelector(jobDetailsSelector);
  const jobId = childJobId || parentJobId;

  useEffect(() => {
    dispatch(getPartRequestLineStatuses());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (jobPartsLinesOrdering && !filters?.order) {
      const defaultOrder = jobPartsLinesOrdering.find((el) => el.label === JobPartsOrdering.OrdinalNumber)?.value;
      if (defaultOrder) {
        dispatch(setJobPartsFilters({ ...filters, order: { field: defaultOrder, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [jobPartsLinesOrdering]);

  useEffect(() => {
    const partRequestId = jobDetails?.partRequest?.id;
    if (partRequestId) {
      if (filters) {
        dispatch(getJobPartLinesThunk({ partRequestId, filters }));
      }
    } else {
      jobId && dispatch(getJobByIdThunk({ id: +jobId }));
    }
    // eslint-disable-next-line
  }, [jobDetails?.partRequest?.id, filters]);

  return (
    <TableNew>
      <JobPartsColumns columns={columns} totalElements={jobPartsLines.length} />
      <JobPartsBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default JobParts;
