import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  salesByCustomerFiltersSelector, salesReportByCustomerRangesSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useSalesReportByCustomerRanges = () => {
  const filters = useAppSelector(salesByCustomerFiltersSelector);
  const { costNet, salesNet, differenceNet } = useAppSelector(salesReportByCustomerRangesSelector);
  const [rangePatterns, setRangePatterns] = useState({
    costNet: { min: costNet.min, max: costNet.max },
    salesNet: { min: salesNet.min, max: salesNet.max },
    differenceNet: { min: differenceNet.min, max: differenceNet.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    salesNet: filters.salesNet
      ? [filters.salesNet.min.toString(), filters.salesNet.max.toString()]
      : [rangePatterns.salesNet.min.toString(), rangePatterns.salesNet.max.toString()],
    costNet: filters.costNet
      ? [filters.costNet.min.toString(), filters.costNet.max.toString()]
      : [rangePatterns.costNet.min.toString(), rangePatterns.costNet.max.toString()],
    differenceNet: filters.differenceNet
      ? [filters.differenceNet.min.toString(), filters.differenceNet.max.toString()]
      : [rangePatterns.differenceNet.min.toString(), rangePatterns.differenceNet.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, salesNet: { min: salesNet.min, max: salesNet.max } }));
    setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
    // eslint-disable-next-line
  }, [salesNet.min, salesNet.max]);

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, costNet: { min: costNet.min, max: costNet.max } }));
    setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
    // eslint-disable-next-line
  }, [costNet.min, costNet.max]);

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, differenceNet: { min: differenceNet.min, max: differenceNet.max } }));
    setRange((prevState) => ({ ...prevState, differenceNet: [differenceNet.min.toString(), differenceNet.max.toString()] }));
    // eslint-disable-next-line
  }, [differenceNet.min, differenceNet.max]);

  useEffect(() => {
    if (!filters.salesNet) {
      setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
    }
    if (!filters.costNet) {
      setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
    }
    if (!filters.differenceNet) {
      setRange((prevState) => ({ ...prevState, differenceNet: [differenceNet.min.toString(), differenceNet.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.salesNet, filters.costNet, filters.differenceNet]);

  return { range, setRange, rangePatterns };
};
