import { startCase, capitalize } from 'lodash';
import { DictionaryItem } from '../../core/types/coreTypes';

export const fieldNameEventHandle = (fieldName: string | undefined, prefix?: { businessAreas?: string, name?: string, date?: string }) => {
  const specialFieldNames = {
    AlwaysUseRequisites: 'Always use customer\'s billing/shipping details',
    AssigneeFullName: 'Assignee',
    Birthday: 'Date of birth',
    BusinessAreas: prefix?.businessAreas ? `${prefix.businessAreas} business areas` : 'Business areas',
    CategoryName: 'Category',
    CityTown: 'City/town',
    CostPrice: 'Cost price',
    CustomerAccountNumber: 'Customer account No',
    CustomerName: 'Customer',
    CustomerNames: 'Customers',
    CustomerReferenceNumber: 'Customer reference No',
    Date: prefix?.date ? `${prefix.date} date` : 'Date',
    DeliveryCityTown: 'Delivery city/town',
    EstimatedMinutes: 'Estimated hours',
    Fao: 'FAO',
    GrossVehicleWeightKg: 'Gross vehicle weight, kg',
    IceContactAddressLine1: 'Address line 1 (ICE contact)',
    IceContactAddressLine2: 'Address line 2 (ICE contact)',
    IceContactCityTown: 'City/town (ICE contact)',
    IceContactFullName: 'Full name (ICE contact)',
    IceContactPostalCode: 'Postal code (ICE contact)',
    IceContactRegion: 'Region (ICE contact)',
    IceContactTelephone: 'Telephone (ICE contact)',
    IsCorePart: 'Core part',
    MaxPayload: 'Maximum payload',
    Name: prefix?.name ? `${prefix.name} name` : 'Name',
    NationalInsuranceNumber: 'N.I. number',
    OrdinalNumber: 'Line number',
    RegNumber: 'Reg No',
    Relationship: 'Relationship (ICE contact)',
    SellPrice: 'Selling price',
    SlidingDoorsQuantity: 'Number of sliding doors',
    SubcategoryId: 'Subcategory',
    SupplierAccountNumber: 'Supplier account No',
    UserAccountRole: 'Role',
    Vat: 'VAT',
    VatNumber: 'VAT number',
    XeroInvoiceId: 'Xero invoice ID',
    XeroInvoiceUrl: 'Xero invoice URL',
  };

  if (!fieldName) return '';
  return specialFieldNames[fieldName as keyof typeof specialFieldNames] || capitalize(startCase(fieldName));
};

export const getValuesFromJsonArray = (jsonValue: string, dictionary: DictionaryItem[] | undefined) => {
  const valueArray: number[] = jsonValue ? JSON.parse(jsonValue) : [];
  return valueArray
    .map((el) => dictionary?.find((ba) => ba.value === el)?.label || el)
    .join(', ');
};

export const getReadableBoolean = (value: string | number | boolean) => {
  return typeof value === 'number' || ['True', true].includes(value) ? 'Yes' : 'No';
};
