import React from 'react';
import { Drawer } from 'antd';
import { VoidFunctionType } from '../../types/coreTypes';
import './DrawerComponent.scss';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';

type DrawerComponentProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  closable?: boolean,
  title: string,
  children: JSX.Element,
}

const DrawerComponent: React.FC<DrawerComponentProps> = ({
  isVisible, onClose, closable, title, children,
}) => {
  const { windowWidth } = useScreenWitdh();
  return (
    <Drawer
      visible={isVisible}
      closable={closable}
      onClose={onClose}
      size={windowWidth < responsiveEndpointsEnum.LG ? 'default' : 'large'}
      title={title}
      destroyOnClose
    >
      {children}
    </Drawer>
  );
};

export default DrawerComponent;
