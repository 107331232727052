import React from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { inviteUserThunk } from '../../../../store/thunks/settings/userViewPageThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';

type InviteUserModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  userName: string,
  userId?: number,
}

const InviteUserModal: React.FC<InviteUserModalProps> = ({
  userName, onCancel, isVisible, userId,
}) => {
  const dispatch = useAppDispatch();
  const confirmHandler = () => {
    userId && dispatch(inviteUserThunk({
      id: +userId, userName, closeModal: () => onCancel(),
    }));
  };
  return (
    <ConfirmationModal
      customTitle="Send invitation"
      isVisible={isVisible}
      onCancel={onCancel}
      confirmHandler={confirmHandler}
      customCreateLabel="Yes, send"
      customText={<>
        You are about to send an email invitation to
        {' '}
        <b>{userName}</b>
        .
      </>}
    />
  );
};

export default InviteUserModal;
