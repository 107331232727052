import React, { useCallback, useMemo, useState } from 'react';
import { GenericObject, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';
import { ProductSourceEnum } from '../../../../../common/types/commonTypes';
import DistributeQtyTable from './DistributeQtyTable';
import './DistributeQtyModal.scss';
import {
  setStockPartProductSourceTotal,
} from '../../../../../store/slices/stockPartRequestSlice';
import { putStockPartRequestLineThunk } from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { stockPartRequestLineStatusTransitionValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { setProductSourceDictionary } from '../../../../../store/slices/sharedSlice';

type SelectProductSourceDistributeQtyProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  sourceName: string,
  sourceId?: number,
  sourcePartRequestId: number | undefined,
  isLinesView?: boolean,
}

const SelectProductSourceDistributeQty: React.FC<SelectProductSourceDistributeQtyProps> = ({
  visible, onCancel, sourceName, sourceId, sourcePartRequestId, isLinesView,
}) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const { confirm } = useAppSelector(stockPartRequestLineStatusTransitionValuesSelector);

  const [checkedCells, setCheckedCells] = useState<string[]>([ProductSourceEnum.STOCK]);
  const [values, setValues] = useState<GenericObject<string> | null>(null);
  const valuesList = useMemo(() => {
    if (values) {
      const checkedList = checkedCells.filter((cell) => cell !== ProductSourceEnum.MANUAL && cell !== ProductSourceEnum.STOCK);
      const pricelists = checkedList.map((l) => ({ key: +l, value: values[l] }));
      return [
        { key: ProductSourceEnum.STOCK, value: values?.stock },
        ...pricelists,
      ];
    } else {
      return null;
    }
  }, [values, checkedCells]);

  const backHandle = useCallback(() => {
    onCancel();
    setCheckedCells([ProductSourceEnum.STOCK]);
    setValues(null);
    dispatch(setStockPartProductSourceTotal(null));
    dispatch(setProductSourceDictionary(null));
  }, [dispatch, onCancel]);

  const confirmHandle = () => {
    const checkedList = checkedCells.filter((cell) => cell !== ProductSourceEnum.STOCK);
    const pricelistsData = (checkedList.length > 0 && values) ? checkedList.map((l) => ({ pricelistId: +l, quantity: +values[l] })) : [];
    const finalData = {
      pricelists: pricelistsData,
      stockQuantity: (checkedCells.includes(ProductSourceEnum.STOCK) && values) ? +values.stock : null,
    };
    sourcePartRequestId && sourceId && confirm && dispatch(putStockPartRequestLineThunk({
      partRequestId: sourcePartRequestId,
      lineId: sourceId,
      data: {
        statusTransition: confirm,
        ...finalData,
      },
      closeModal: backHandle,
      isLinesView,
    }));
  };
  return (
    <Modal
      visible={visible}
      onCancel={backHandle}
      title="Select product source and distribute quantity"
      destroyOnClose
      width={1100}
    >
      <div className="sourceModalInner">
        <section className="sourceModalInner__head">
          <span className="sourceModalInner__head-title">
            {sourceName}
          </span>
        </section>
        <DistributeQtyTable
          sourceId={sourceId}
          checkedCells={checkedCells}
          setCheckedCells={setCheckedCells}
          values={values}
          setValues={setValues}
          visible={visible}
          isLinesView={isLinesView}
        />
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Confirm"
          cancelClick={backHandle}
          createClick={confirmHandle}
          isLoading={isFetching}
          disabledCreate={checkedCells.length === 0 || !valuesList
            || valuesList.some((el) => checkedCells.includes(el.key.toString()) && !+el.value)}
          offsetTop
        />
      </div>
    </Modal>
  );
};

export default SelectProductSourceDistributeQty;
