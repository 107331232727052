import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, BooleanFunctionType, PagingType, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import {
  GetPartResponse, PartAffectedEntitiesFilters,
  PartAffectedEntityDto,
  PartReorderLevels, PartViewEventDtoBase, PartViewFields,
} from '../../../../stock/part-view-page/types/types';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import { PartDtoType } from '../../../../stock/search/types/stockSearchTypes';
import {
  DuplicateValue,
  ErrorsEnum,
  PleaseRefreshThePageError,
  SimultaneouslyStatusErrorEnum,
} from '../../../../core/enums/errorsEnum';
import { getPartAttachmentByIdThunk } from './partAttachmentsThunks';
import { setPartImageData, setPartViewPageTimeline, setPartViewPageTimelineFilters } from '../../../slices/stockSlice';
import { PartsKitsDto } from '../../../../stock/parts-kits/dashboard/types/partsKitsTypes';
import {
  PurchaseOrderDashFiltersType,
  PurchaseOrdersResponseResult,
} from '../../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { ExtendedTimelineFilters } from '../../../../common/types/commonTypes';
import { RootState } from '../../../store';
import { routesPath } from '../../../../core/enums/pathEnum';
import { StockPageParamEnums } from '../../../../stock/container/enums/stockEnums';
import { UserEventPart } from '../../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../../slices/settingsSlice';

export const getStockPartByIdThunk = createAsyncThunk<
ResponseSingleResult<GetPartResponse> | null,
{ id: number }
>(
  'get/StockPartById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockPartById(id);
      const partImageId = response.data.data.images[0]?.id;
      if (partImageId) {
        dispatch(setPartImageData({ id: partImageId }));
        dispatch(getPartAttachmentByIdThunk({
          id: partImageId, partId: id, isPartImage: true,
        }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteStockPartThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  closeModal: VoidFunctionType,
  navigate: NavigateFunction,
}
>(
  'delete/StockPart',
  async ({
    id, closeModal, navigate,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.deletePart(id);
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      navigate(`/${routesPath.STOCK}?page=${StockPageParamEnums.SEARCH}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status;
      if (status === SimultaneouslyStatusErrorEnum.NeedConfirmation) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartViewTimeline = createAsyncThunk<ResponseResult<PartViewEventDtoBase[]>,
{ partId: number, filters: ExtendedTimelineFilters }
>(
  'get/PartViewTimeline',
  async ({ partId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartViewTimeline(partId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchStockPartThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  data: Partial<PartDtoType>,
  closeModal?: VoidFunctionType,
  setError?: UseFormSetError<PartViewFields>,
}
>(
  'patch/Part',
  async ({
    id, data, closeModal, setError,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.patchStockPart(id, data);
      dispatch(getStockPartByIdThunk({ id }));
      closeModal && closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const status = error.response?.data.status || '';
      closeModal && closeModal();
      if (errors.find((el) => el.key === 'PartCode')) {
        setError && setError('partCode', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartAffectedThunk = createAsyncThunk<ResponseResult<
PartAffectedEntityDto[]>,
{
  id: number,
  filters: PartAffectedEntitiesFilters,
  complexAction?: VoidFunctionType,
  confirmAction?: VoidFunctionType,
}
>(
  'get/StockPartAffected',
  async ({
    id, filters, confirmAction, complexAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.getStockPartAffected(id, filters);
      const { totalCount } = response.data.data;
      if (totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartViewPartsKits = createAsyncThunk<ResponseResult<PartsKitsDto[]> | null,
{
  id: number,
  filters: PagingType,
  setIsFetching?: BooleanFunctionType,
}
>('get/PartViewPartsKits', async ({
  id, filters, setIsFetching,
}, { rejectWithValue }) => {
  try {
    const response = await stockApi.fetchPartViewPartsKits(id, filters);
    setIsFetching && setIsFetching(false);
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    setIsFetching && setIsFetching(false);
    return rejectWithValue(error.response?.data);
  }
});

export const putPartReorderLevels = createAsyncThunk<ResponseSingleResult<PartReorderLevels>, {
  id: number, data: PartReorderLevels,
}>(
  'put/PartReorderLevels',
  async ({ id, data }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.reorderLevels(id, data);
      dispatch(getStockPartByIdThunk({ id }));
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartPurchaseOrders = createAsyncThunk<PurchaseOrdersResponseResult,
{ filters: PurchaseOrderDashFiltersType }
>(
  'get/PartPurchaseOrders',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrders(filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postPartViewTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partId: number, content: string, setValue: VoidFunctionType,
}>('post/PartViewTimelineNote', async ({
  partId, content, setValue,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const res = await stockApi.createPartNote(partId, content);
    const { stock: { partViewPageTimelineFilters } } = getState() as RootState;
    setValue();
    if (partViewPageTimelineFilters.page === 1) {
      dispatch(getPartViewTimeline({
        partId,
        filters: partViewPageTimelineFilters,
      }));
    } else {
      dispatch(setPartViewPageTimelineFilters({ ...partViewPageTimelineFilters, page: 1 }));
    }
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(setLoading(false));
    return res.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    setValue();
    return rejectWithValue(error.response?.data);
  }
});

export const editPartViewTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/PartViewTimelineNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.editPartViewNote(id, content);
      const {
        stock: { partViewPageTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventPart[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = partViewPageTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setPartViewPageTimeline({ ...partViewPageTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartViewTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }>(
  'delete/PartViewTimelineNote',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.deletePartViewNote(id);
      const {
        stock: { partViewPageTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setPartViewPageTimelineFilters({ ...partViewPageTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
