import React, { useState } from 'react';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Textarea from '../../../core/components/textarea/Textarea';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import './EnquiryStatusChange.scss';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';

type RejectStatusModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  onConfirm: (status: number, reason: string, onCancel: VoidFunctionType) => void,
  isButtonDisabled?: boolean,
  rejectionStatus?: number,
  customTitle?: string,
}

const RejectStatusModal: React.FC<RejectStatusModalProps> = ({
  isVisible, onCancel, onConfirm, isButtonDisabled, rejectionStatus, customTitle = 'Move to rejected',
}) => {
  const [value, setValue] = useState<string>('');
  const [error, setError] = useState<string>('');
  const isFetching = useAppSelector(isFetchingSelector);

  const cancelHandle = () => {
    onCancel();
    setValue('');
    error && setError('');
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={customTitle}
    >
      <div className="rejectWrap">
        <Textarea
          label="Rejection reason"
          value={value}
          onChange={(e) => {
            const val = e.target.value;
            if (!val.trim()) {
              setError(ErrorsEnum.REQUIRED);
            } else if (val.length > 2000) {
              setError(maxLengthMessageHandle(2000));
            } else {
              setError('');
            }
            setValue(val);
          }}
          error={error}
          className="rejectWrap__textarea"
        />
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Move to rejected"
          cancelClick={cancelHandle}
          createClick={() => rejectionStatus && onConfirm(
            rejectionStatus,
            value,
            () => cancelHandle(),
          )}
          createType="button"
          disabledCreate={!!error || !value || isButtonDisabled}
          disabledCancel={isButtonDisabled}
          isLoading={isFetching}
        />
      </div>
    </Modal>
  );
};

export default RejectStatusModal;
