import React from 'react';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import SalesOrderLinesColumns from './SalesOrderLinesColumns';
import SalesOrderLinesBody from './SalesOrderLinesBody';

const SalesOrderLinesTable: React.FC = () => {
  return (
    <TableNew offsetBottom>
      <SalesOrderLinesColumns />
      <SalesOrderLinesBody />
    </TableNew>
  );
};

export default SalesOrderLinesTable;
