import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AttachmentsTableProps } from '../../../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../../../store/hooks';
import EditCommentModal from '../../../../../../../common/components/edit-attachment-comment/EditCommentModal';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import PartAttachmentCell from './PartAttachmentCell';
import PartAttachmentColumns from './PartAttachmentColumns';
import { editPartAttachmentThunk } from '../../../../../../../store/thunks/stock/part/partAttachmentsThunks';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import AttachmentsTable from '../../../../../../../common/components/attachments-table/AttachmentsTable';

const PartAttachmentTable:React.FC<AttachmentsTableProps> = ({
  attachments,
  checkedKeys,
  isAll,
  indeterminate,
  checkAllHandle,
  checkRow,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isEditModal, setEditModal] = useState<boolean>(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState<string>('');

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };

  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => attachToEdit && id && dispatch(editPartAttachmentThunk({
          id: attachToEdit,
          partId: +id,
          comment: editedComment.trim(),
          onCancel: cancelHandle,
        }))}
      />
      <AttachmentsTable>
        <PartAttachmentColumns
          isAll={isAll}
          indeterminate={indeterminate}
          checkAll={checkAllHandle}
          totalElements={attachments.length}
        />
        <Tbody>
          {attachments.length > 0
            ? attachments.map((attachment) => (
              <PartAttachmentCell
                key={attachment.id}
                attach={attachment}
                openEditModal={() => {
                  setAttachToEdit(attachment.id);
                  setEditedComment(attachment.comment || '');
                  setEditModal(true);
                }}
                checked={checkedKeys.includes(attachment.id)}
                checkRow={() => checkRow(attachment.id)}
              />))
            : <EmptyTableSection text="No records" colSpan={6} />}
        </Tbody>
      </AttachmentsTable>
    </>
  );
};

export default PartAttachmentTable;
