import { initDataList } from '../../../../../../core/types/coreTypes';
import { SalesOfficeEmployeeReportData } from '../../../../../types/SalesOfficeEmployeeReportTypes';

export const initSalesOfficeEmployeeReportData: SalesOfficeEmployeeReportData = {
  ...initDataList,
  minSalesNet: 0,
  maxSalesNet: 0,
  minDaysInNewStatus: 0,
  maxDaysInNewStatus: 0,
};
