import React from 'react';
import { ReactComponent as JobsIcon } from '../../../assets/icons/jobs-icon.svg';

const EmptyJobList: React.FC = () => {
  return (
    <div className="emptyJobList">
      <JobsIcon className="emptyJobList__iconJob" />
      <span className="emptyJobList__text">No jobs</span>
    </div>
  );
};

export default EmptyJobList;
