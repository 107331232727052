import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setJobAttachFilters,
  setJobAttachments,
  setJobDetails, setJobDetailsFilters, setJobLinks,
  setJobLinksFilters, setJobPartsFilters, setJobPartsLines, setJobTimeline, setJobTimelineFilters,
} from '../../../store/slices/jobsSlice';
import {
  initDataListWithIds, initDataList, initialCoreAttachmentsFilters, initLinksFilters,
} from '../../../core/types/coreTypes';
import { JobDetailsInitFilters } from '../types/jobViewTypes';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const jobCleanUpHandle = (
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  expandedLine?: boolean,
) => {
  dispatch(setJobLinksFilters(initLinksFilters));
  dispatch(setJobDetails(null));
  dispatch(setJobDetailsFilters(JobDetailsInitFilters));
  dispatch(setJobAttachments({ ...initDataListWithIds, allOwnItemIds: [] }));
  dispatch(setJobAttachFilters(expandedLine ? { ...initialCoreAttachmentsFilters, pageSize: 5 } : initialCoreAttachmentsFilters));
  dispatch(setJobLinks([]));
  dispatch(setJobTimeline(initDataList));
  dispatch(setJobTimelineFilters({ ...initExtendedTimelineFilters, notesOnly: !!expandedLine }));
  dispatch(setJobPartsFilters(undefined));
  dispatch(setJobPartsLines(initDataList));
};
