import { RectificationFormFields } from '../../create-rectification/types/createRectificationFormSchema';

type PickedRectificationFields = Pick<
RectificationFormFields,
'priorityLevel' | 'jobType' | 'rectificationType' | 'description'
>

export type RectificationDetailsFields = PickedRectificationFields & {
  customerReferenceNumber: string | null,
  rectificationDate: string | null,
  xeroInvoiceId?: string | null,
  xeroInvoiceUrl?: string | null,
  rejectionReason?: string | null,
  faultType?: number | null,
  dueDate: string | null,
}

export const rectificationDetailsDefaultFields: RectificationDetailsFields = {
  rectificationType: undefined,
  faultType: undefined,
  rectificationDate: null,
  priorityLevel: undefined,
  jobType: undefined,
  dueDate: null,
  description: null,
  customerReferenceNumber: '',
  xeroInvoiceId: null,
  xeroInvoiceUrl: null,
  rejectionReason: null,
};
