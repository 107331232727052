import { EnquiryColumnType, EnquiryDto } from '../types/enquiryTypes';
import { CollectionColumnPaginationType, GenericColumnType } from '../../../core/types/coreTypes';

export const enquiryDashboardCollectionsHelper = (
  prevCollections: Array<GenericColumnType<Array<EnquiryDto>>>,
  responseCollections: Array<EnquiryColumnType>,
  oldPagination: Array<CollectionColumnPaginationType>,
  statusTransition: number,
  sourceId: number,
) => {
  const currentOld = responseCollections.find((el) => el.status === sourceId);
  const currentNew = responseCollections.find((el) => el.status === statusTransition);
  const newPagination = oldPagination.map((p) => {
    if (p.status === currentOld?.status) {
      return { ...p, pages: currentOld.pages, currentPage: 1 };
    }
    if (p.status === currentNew?.status) {
      return { ...p, pages: currentNew?.pages, currentPage: 1 };
    } else return p;
  });
  const newEnquiryCollections = prevCollections.map((col) => {
    if (col.status === currentNew?.status) {
      const { enquiries, ...rest } = currentNew;
      return {
        ...rest,
        list: enquiries,
      };
    }
    if (col.status === currentOld?.status) {
      const { enquiries, ...rest } = currentOld;
      return {
        ...rest,
        list: enquiries,
      };
    } else return col;
  });
  return { newPagination, newEnquiryCollections };
};
