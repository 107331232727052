import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faGear, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { BooleanFunctionType, NumberFunctionType } from '../../../core/types/coreTypes';
import Tr from '../../../core/components/table-components/Tr';
import Thead from '../../../core/components/table-components/Thead';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { skillsColumnsSelector, skillsFiltersSelector } from '../../../store/selectors/employeesSelector';
import { setSkillsFilters } from '../../../store/slices/employeesSlice';
import usePermission from '../../../permissions-handling/permissionHook';
import ThNew from '../../../core/components/table-components/table-new/ThNew';

type SkillsTableHeadProps = {
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: BooleanFunctionType;
  totalElements: number;
  disabled: boolean;
  openEditCategory: NumberFunctionType,
  openDeleteModal: (categoryId?: number, skillId?: number) => void,
}

const SkillsTableHead: React.FC<SkillsTableHeadProps> = ({
  isAll, isIndeterminate, checkAll, totalElements, disabled, openEditCategory, openDeleteModal,
}) => {
  const dispatch = useAppDispatch();
  const skillsFilters = useAppSelector(skillsFiltersSelector);
  const skillsColumns = useAppSelector(skillsColumnsSelector);
  const allowedToManage = usePermission(PermissionEnum.EmployeeSkillsListPage);
  const sortHandle = (field: number) => {
    if (skillsFilters?.order?.field === field) {
      dispatch(setSkillsFilters({
        ...skillsFilters,
        order: { ...skillsFilters.order, isAsc: !skillsFilters.order.isAsc },
      }));
    } else {
      dispatch(setSkillsFilters({
        ...skillsFilters,
        order: { field, isAsc: true },
      }));
    }
  };

  const allowedActions = useCallback((id: number) => {
    return [
      {
        label: 'Edit category',
        icon: <FontAwesomeIcon icon={faEdit} />,
        key: 'editCategory',
        onClick: () => openEditCategory(id),
      },
      {
        label: 'Delete category',
        key: 'deleteCategory',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => openDeleteModal(id),
      },
    ];
    // eslint-disable-next-line
  }, []);

  return (
    <Thead>
      <Tr>
        {allowedToManage && (
          <ThNew checkbox>
            <Checkbox
              checked={isAll}
              indeterminate={isIndeterminate}
              onChange={checkAll}
              disabled={totalElements === 0 || disabled}
              table
            />
          </ThNew>
        )}
        {skillsColumns.map((col, i) => <ThWithControls
          key={i}
          col={col}
          sortHandle={sortHandle}
          totalElements={totalElements}
          disabled={disabled}
          tableFilters={skillsFilters}
          className={`skillsTableTh--${col.dataIndex}`}
        />)}
        {allowedToManage && (
          <ThNew action>
            <Dropdown
              menuItems={skillsFilters.categoryId ? allowedActions(skillsFilters.categoryId) : []}
              icon={<FontAwesomeIcon icon={faGear} />}
            />
          </ThNew>
        )}
      </Tr>
    </Thead>
  );
};

export default SkillsTableHead;
