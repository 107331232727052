import React from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import Thead from '../../../../core/components/table-components/Thead';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import { setStockPartAffectedCategoriesFilter } from '../../../../store/slices/stockSlice';
import Tbody from '../../../../core/components/table-components/Tbody';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { AffectedEntityType, ICustomColumn } from '../../../../common/types/commonTypes';
import { AffectedCategoriesFiltersType } from '../../types/partCategoriesTypes';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type AffectedPartCategoriesTableProps = {
  columns: ICustomColumn[],
  data: AffectedEntityType[],
  filters: AffectedCategoriesFiltersType
  totalCount: number,
}
export const AffectedPartCategoriesTable: React.FC<AffectedPartCategoriesTableProps> = ({
  columns, data, filters, totalCount,
}) => {
  const dispatch = useAppDispatch();

  const truncatedKeys = ['entityType', 'entityNumber', 'businessArea'];
  const nowrapKeys = ['createdAt', 'entityStatus'];

  const sortHandle = (field: number) => {
    if (field === filters?.order?.field) {
      dispatch(setStockPartAffectedCategoriesFilter({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      }));
    } else {
      dispatch(setStockPartAffectedCategoriesFilter({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };

  return (
    <TableNew>
      <Thead>
        <Tr>
          {columns.map((col) => (
            <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalCount}
              className={`affectedCategoriesTh--${col.dataIndex}`}
              tableFilters={filters}
              sortHandle={sortHandle}
            />
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {data.map((el, i) => (
          <Tr key={i}>
            {Object.entries(el).map(([key, value]) => (
              <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
                {truncatedKeys.includes(key) ? (
                  <TruncateTooltip
                    value={value as string}
                    table
                  />
                ) : value}
              </TdNew>
            ))}
          </Tr>
        ))}
      </Tbody>
    </TableNew>
  );
};

export default AffectedPartCategoriesTable;
