import React from 'react';
import { SupplierViewPageEnums } from '../../enums/SupplierViewPageEnums';
import SupplierDetailsAndActivity from './details-and-activity/SupplierDetailsAndActivity';
import SupplierAttachments from './attachments/SupplierAttachments';
import SupplierContacts from './contacts/SupplierContacts';

type SupplierContentProps = {
  mode: string | null,
}

const SupplierContent: React.FC<SupplierContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case SupplierViewPageEnums.SUPPLIER_DETAILS:
        return <SupplierDetailsAndActivity />;
      case SupplierViewPageEnums.SUPPLIER_ATTACHMENTS:
        return <SupplierAttachments />;
      case SupplierViewPageEnums.SUPPLIER_CONTACTS:
        return <SupplierContacts />;
      default: return <></>;
    }
  };

  return (
    <>{renderContent()}</>
  );
};

export default SupplierContent;
