import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AffectedEmployeeDto,
  AffectedEmployeesFiltersType, EmployeeSkillsListItemDto,
  initSkillsFilters,
  SkillCategoryLookupDto, SkillDto,
  SkillsFilterType,
} from '../../employees/skill-list/types/skillsTypes';
import { EmployeeListItemDto, employeesFiltersType } from '../../employees/dashboard/types/employeesDashTypes';
import {
  getCategoryAffectedEmployees, getSkillAffectedEmployees,
  getSkillCategoryByIdThunk,
  getSkillsCategoriesThunk,
} from '../thunks/employee/skillsThunks';
import {
  CoreAttachmentDto,
  CoreAttachmentsFilters, CoreAttachmentsList,
  CoreEntityImageDataType,
  DataListType,
  DictionaryItem, DictionaryResponse, DictionaryStringValueItem, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initEntityImageData,
  initialCoreAttachmentsFilters,
  initPaging,
  initSmallPaging,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  getEmployeeDepartmentsThunk,
  getEmployeeGendersThunk,
  getEmployeeSalaryTypesThunk,
  getEmployeeSkillLevelsThunk,
  getEmployeeSkillsDictionaryThunk,
  getEmployeeStatusesThunk,
} from '../thunks/employee/createEmployeeThunks';
import {
  EmployeeLeaveRequestFilters,
  ExtendedTimelineFilters, initExtendedTimelineFilters, LeaveRequestDto, SkillLookupDto,
} from '../../common/types/commonTypes';
import {
  getEmployeeDashboardThunk,
  getEmployeeDashStatusesThunk,
  getEmployeeDashTitlesThunk,
} from '../thunks/employee/employeesDashboardThunks';
import { getEmployeeAttachmentsThunk } from '../thunks/employee/employeeAttachmentsThunks';
import {
  EmployeeActivityEventType,
  EmployeeEmploymentDto,
  EmployeeIceContactResponseDto,
  GetEmployeeIceContactResponse,
  GetEmployeeResponse,
} from '../../employees/employee-view-page/types/employeeViewTypes';
import {
  getEmployeeByIdThunk,
  getEmployeeIceContactThunk,
  getEmployeeSkillsByIdThunk, getEmployeeTimelineThunk,
} from '../thunks/employee/employeeViewPageThunk';
import { getEmployeeEmploymentsThunk } from '../thunks/employee/employeeEmploymentsThunks';
import {
  initEmployeesLeaveRequestsFilters,
} from '../../employees/leave-request/types/EmployeesLeaveRequestsTypes';
import {
  AffectedScheduleDateDto, DepartmentDto,
  EmployeeHolidaysFilters,
  EmployeeWorkCalendarFilters,
  GetDepartmentsResponse,
  GetHolidaysResponse,
  GetJobTitlesResponse,
  GetWorkCalendarResponse,
  HolidayDto, JobTitleDto,
  WorkCalendarUserDto,
} from '../../employees/work-calendar/types/EmployeeWorkCalendarTypes';
import { getEmployeesLeaveRequestsThunk } from '../thunks/employee/employeesLeaveRequestsThunks';
import {
  getAllEmployeesListThunk,
  getEmployeeAffectedDatesThunk,
  getEmployeeHolidays, getEmployeesDepartmentsWithEmployeesCount, getEmployeesJobTitlesWithEmployeesCount,
  getEmployeeWorkCalendarThunk,
} from '../thunks/employee/employeesWorkCalendarThunks';
import { initEmployeeHolidaysFilters } from '../../employees/work-calendar/utils/data';

interface IEmployeesState {
  employeeDetails: GetEmployeeResponse | null,
  employeeImageData: CoreEntityImageDataType,
  employeeICEContact: EmployeeIceContactResponseDto | null,
  skillsFilters: SkillsFilterType,
  dashboardFilters: employeesFiltersType,
  employeeStatuses: DictionaryItem[],
  employeeGenders: DictionaryItem[],
  employeeDepartments: DictionaryItem[],
  employeeSalaryTypes: DictionaryItem[],
  employeeSkillLevels: DictionaryItem[],
  employeeSkillsDictionary: SkillLookupDto[],
  skillsCategories: SkillCategoryLookupDto[],
  skillCategoryInfo: { skills: SkillDto[], allItemIds: number[] },
  skillsOrdering: DictionaryItem[],
  affectedEmployeeFilters: AffectedEmployeesFiltersType,
  affectedSkillsEmployees: DataListType<AffectedEmployeeDto[]>,
  affectedCategoryEmployees: DataListType<AffectedEmployeeDto[]>,
  employeeDashStatuses: DictionaryItem[],
  employeeDashJobTitles: DictionaryStringValueItem[],
  employeesDashboard: DataListType<EmployeeListItemDto[]>
  employeeAttachments: CoreAttachmentsList,
  employeeAttachmentsFilters: CoreAttachmentsFilters,
  employeeSkills: EmployeeSkillsListItemDto[] | null,
  employeeEmployments: EmployeeEmploymentDto[] | null,
  employeeActivityFilters: ExtendedTimelineFilters,
  employeeTimeline: DataListType<EmployeeActivityEventType[]>,
  leaveRequestsFilters: EmployeeLeaveRequestFilters,
  employeesLeaveRequests: DataListType<LeaveRequestDto[]>,
  workCalendarFilters: EmployeeWorkCalendarFilters,
  workCalendarUsers: DataListType<WorkCalendarUserDto[] | null>,
  workCalendarDates: string[] | null,
  employeeAffectedDates: AffectedScheduleDateDto[] | null,
  employeesTotalList: DataListType<EmployeeListItemDto[]>,
  employeeHolidaysFilters: EmployeeHolidaysFilters,
  employeeHolidaysYears: number[] | null,
  employeeHolidaysList: HolidayDto[],
  employeesJobTitlesWithCount: JobTitleDto[],
  employeesDepartmentsWithCount: DepartmentDto[],
}

export const initialEmployeeState: IEmployeesState = {
  employeeDetails: null,
  employeeImageData: initEntityImageData,
  employeeICEContact: null,
  skillsFilters: initSkillsFilters,
  dashboardFilters: initPaging,
  skillsCategories: [],
  skillCategoryInfo: { skills: [], allItemIds: [] },
  skillsOrdering: [],
  affectedEmployeeFilters: initSmallPaging,
  affectedSkillsEmployees: initDataList,
  affectedCategoryEmployees: initDataList,
  employeeStatuses: [],
  employeeGenders: [],
  employeeDepartments: [],
  employeeSalaryTypes: [],
  employeeSkillLevels: [],
  employeeSkillsDictionary: [],
  employeeDashStatuses: [],
  employeeDashJobTitles: [],
  employeesDashboard: initDataList,
  employeeSkills: null,
  employeeAttachments: initDataListWithIds,
  employeeAttachmentsFilters: initialCoreAttachmentsFilters,
  employeeEmployments: null,
  employeeActivityFilters: initExtendedTimelineFilters,
  employeeTimeline: initDataList,
  leaveRequestsFilters: initEmployeesLeaveRequestsFilters,
  workCalendarFilters: { page: 1, pageSize: 20 },
  workCalendarUsers: initDataList,
  workCalendarDates: [],
  employeeAffectedDates: null,
  employeesTotalList: initDataList,
  employeesLeaveRequests: initDataList,
  employeeHolidaysFilters: initEmployeeHolidaysFilters,
  employeeHolidaysYears: null,
  employeeHolidaysList: [],
  employeesJobTitlesWithCount: [],
  employeesDepartmentsWithCount: [],
};

export const employeesSlice = createSlice({
  name: 'employees',
  initialState: initialEmployeeState,
  reducers: {
    setEmployeeDetails: (state, action: PayloadAction<GetEmployeeResponse | null>) => {
      state.employeeDetails = action.payload;
    },
    setEmployeeImageData: (state, action: PayloadAction<Partial<CoreEntityImageDataType>>) => {
      state.employeeImageData = { ...state.employeeImageData, ...action.payload };
    },
    setEmployeeIceContact: (state, action: PayloadAction<EmployeeIceContactResponseDto | null>) => {
      state.employeeICEContact = action.payload;
    },
    setSkillsFilters: (state, action:PayloadAction<SkillsFilterType>) => {
      state.skillsFilters = action.payload;
    },
    setDashboardFilters: (state, action: PayloadAction<employeesFiltersType>) => {
      state.dashboardFilters = action.payload;
    },
    setEmployeeDashboard: (state, action: PayloadAction<DataListType<EmployeeListItemDto[]>>) => {
      state.employeesDashboard = action.payload;
    },
    setSkillCategoryInfo: (state, action: PayloadAction<{ skills: SkillDto[], allItemIds: number[] }>) => {
      state.skillCategoryInfo = action.payload;
    },
    setAffectedEmployeeFilters: (state, action: PayloadAction<AffectedEmployeesFiltersType>) => {
      state.affectedEmployeeFilters = action.payload;
    },
    setAffectedSkillsEmployees: (state, action: PayloadAction<DataListType<AffectedEmployeeDto[]>>) => {
      state.affectedSkillsEmployees = action.payload;
    },
    setAffectedCategoriesEmployees: (state, action: PayloadAction<DataListType<AffectedEmployeeDto[]>>) => {
      state.affectedCategoryEmployees = action.payload;
    },
    setEmployeeSkills: (state, action: PayloadAction<EmployeeSkillsListItemDto[] | null>) => {
      state.employeeSkills = action.payload;
    },
    setEmployeeAttachments: (state, action: PayloadAction<CoreAttachmentDto[]>) => {
      state.employeeAttachments = { ...state.employeeAttachments, items: action.payload };
    },
    setEmployeeAttachmentsFilter: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.employeeAttachmentsFilters = action.payload;
    },
    setEmployeeEmployments: (state, action: PayloadAction<EmployeeEmploymentDto[] | null>) => {
      state.employeeEmployments = action.payload;
    },
    setEmployeeActivityFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.employeeActivityFilters = action.payload;
    },
    setEmployeeTimeline: (state, action: PayloadAction<DataListType<EmployeeActivityEventType[]>>) => {
      state.employeeTimeline = action.payload;
    },
    setEmployeesLeaveRequestsFilters: (state, action: PayloadAction<EmployeeLeaveRequestFilters>) => {
      state.leaveRequestsFilters = action.payload;
    },
    setEmployeesLeaveRequests: (state, action: PayloadAction<DataListType<LeaveRequestDto[]>>) => {
      state.employeesLeaveRequests = action.payload;
    },
    setEmployeesWorkCalendarFilters: (state, action: PayloadAction<EmployeeWorkCalendarFilters>) => {
      state.workCalendarFilters = action.payload;
    },
    setEmployeeAffectedDates: (state, action: PayloadAction<AffectedScheduleDateDto[] | null>) => {
      state.employeeAffectedDates = action.payload;
    },
    setEmployeeHolidaysFilters: (state, action: PayloadAction<EmployeeHolidaysFilters>) => {
      state.employeeHolidaysFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getEmployeeByIdThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetEmployeeResponse>>,
    ) => {
      state.employeeDetails = action.payload.data;
    });
    builder.addCase(getEmployeeIceContactThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetEmployeeIceContactResponse>>,
    ) => {
      state.employeeICEContact = action.payload.data.employeeIceContact;
    });
    builder.addCase(getEmployeeStatusesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.employeeStatuses = action.payload?.data.items || [];
    });
    builder.addCase(getEmployeeGendersThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.employeeGenders = action.payload?.data.items || [];
    });
    builder.addCase(getEmployeeDepartmentsThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.employeeDepartments = action.payload?.data.items || [];
    });
    builder.addCase(getEmployeeSalaryTypesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.employeeSalaryTypes = action.payload?.data.items || [];
    });
    builder.addCase(getEmployeeSkillLevelsThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      state.employeeSkillLevels = action.payload?.data.items || [];
    });
    builder.addCase(getEmployeeSkillsDictionaryThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ skills: SkillLookupDto[], allItemIds: number[] }>>,
    ) => {
      state.employeeSkillsDictionary = action.payload.data.skills;
    });
    builder.addCase(getSkillsCategoriesThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ categories: SkillCategoryLookupDto[] }>>,
    ) => {
      const dataList = action.payload?.data.categories;
      state.skillsCategories = dataList || [];
      if (!state.skillsFilters.categoryId && dataList?.length > 0) {
        state.skillsFilters = { ...state.skillsFilters, categoryId: dataList[0].id };
      }
    });
    builder.addCase(getSkillCategoryByIdThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ skills: SkillDto[], allItemIds: number[] }>>,
    ) => {
      state.skillCategoryInfo = action.payload.data;
    });
    builder.addCase(getSkillAffectedEmployees.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<AffectedEmployeeDto[]> | null>,
    ) => {
      const dataList = action.payload?.data;
      state.affectedSkillsEmployees = dataList || initDataList;
    });
    builder.addCase(getCategoryAffectedEmployees.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<AffectedEmployeeDto[]> | null>,
    ) => {
      const dataList = action.payload?.data;
      state.affectedCategoryEmployees = dataList || initDataList;
    });
    builder.addCase(getEmployeeDashStatusesThunk.fulfilled, (
      state,
      action: PayloadAction<DictionaryResponse>,
    ) => {
      const dataList = action.payload?.data.items;
      state.employeeDashStatuses = dataList || [];
      if (!state.dashboardFilters.status && dataList && dataList?.length > 0) {
        state.dashboardFilters = { ...state.dashboardFilters, status: dataList[0].value };
      }
    });
    builder.addCase(getEmployeeDashboardThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<EmployeeListItemDto[]> | null>,
    ) => {
      const dataList = action.payload?.data;
      state.employeesDashboard = dataList || initDataList;
    });
    builder.addCase(getEmployeeDashTitlesThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ items: DictionaryStringValueItem[] }>>,
    ) => {
      state.employeeDashJobTitles = action.payload?.data.items;
    });
    builder.addCase(getEmployeeAttachmentsThunk.fulfilled, (
      state,
      action: PayloadAction<GetAttachmentsResponse>,
    ) => {
      state.employeeAttachments = action.payload.data;
    });
    builder.addCase(getEmployeeSkillsByIdThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ employeeSkills: EmployeeSkillsListItemDto[] }> | null>,
    ) => {
      const dataList = action.payload?.data.employeeSkills;
      state.employeeSkills = dataList || null;
    });
    builder.addCase(getEmployeeEmploymentsThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ employments: EmployeeEmploymentDto[] }> | null>,
    ) => {
      const dataList = action.payload?.data.employments;
      state.employeeEmployments = dataList || null;
    });
    builder.addCase(getEmployeeTimelineThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<EmployeeActivityEventType[]> | null>,
    ) => {
      const dataList = action.payload?.data;
      state.employeeTimeline = dataList || initDataList;
    });
    builder.addCase(getEmployeesLeaveRequestsThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<LeaveRequestDto[]>>,
    ) => {
      state.employeesLeaveRequests = action.payload.data;
    });
    builder.addCase(getEmployeeHolidays.fulfilled, (
      state,
      action: PayloadAction<GetHolidaysResponse>,
    ) => {
      const { items, years } = action.payload.data;
      state.employeeHolidaysYears = years;
      state.employeeHolidaysList = items;
    });
    builder.addCase(getEmployeeWorkCalendarThunk.fulfilled, (
      state,
      action: PayloadAction<GetWorkCalendarResponse>,
    ) => {
      const { dates, ...rest } = action.payload.data;
      state.workCalendarUsers = rest;
      state.workCalendarDates = dates;
    });
    builder.addCase(getEmployeeAffectedDatesThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ affectedScheduleDates: AffectedScheduleDateDto[] | null }>>,
    ) => {
      state.employeeAffectedDates = action.payload.data.affectedScheduleDates;
    });
    builder.addCase(getAllEmployeesListThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<EmployeeListItemDto[]>>,
    ) => {
      state.employeesTotalList = action.payload.data;
    });
    builder.addCase(getEmployeesDepartmentsWithEmployeesCount.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetDepartmentsResponse>>,
    ) => {
      state.employeesDepartmentsWithCount = action.payload.data.departments;
    });
    builder.addCase(getEmployeesJobTitlesWithEmployeesCount.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetJobTitlesResponse>>,
    ) => {
      state.employeesJobTitlesWithCount = action.payload.data.items;
    });
  },
});

export const {
  setEmployeeDetails,
  setEmployeeImageData,
  setEmployeeIceContact,
  setSkillsFilters,
  setDashboardFilters,
  setEmployeeDashboard,
  setSkillCategoryInfo,
  setAffectedEmployeeFilters,
  setAffectedSkillsEmployees,
  setAffectedCategoriesEmployees,
  setEmployeeAttachments,
  setEmployeeAttachmentsFilter,
  setEmployeeSkills,
  setEmployeeEmployments,
  setEmployeeActivityFilters,
  setEmployeeTimeline,
  setEmployeesLeaveRequestsFilters,
  setEmployeesLeaveRequests,
  setEmployeesWorkCalendarFilters,
  setEmployeeAffectedDates,
  setEmployeeHolidaysFilters,
} = employeesSlice.actions;

export default employeesSlice.reducer;
