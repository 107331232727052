import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import Modal from '../../../core/components/modal/Modal';
import { AddInfoMessageFormFields } from '../types/infoMessagesTypes';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import Input from '../../../core/components/input/Input';
import Textarea from '../../../core/components/textarea/Textarea';
import Select from '../../../core/components/select/Select';
import { getDisplayOrders } from '../utils/displayOrder';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addInfoMessageThunk,
  getInfoMessageMaxDisplayOrder,
  getInfoMessagesList,
} from '../../../store/thunks/settings/infoMessagesThunks';
import { infoMessagesListFiltersSelector } from '../../../store/selectors/settingsSelectors';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type AddMessageModalProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  maxDisplayOrder: number,
}

const AddInfoMessageModal: React.FC<AddMessageModalProps> = ({
  isVisible, onClose, maxDisplayOrder,
}) => {
  const dispatch = useAppDispatch();
  const infoMessageListFilters = useAppSelector(infoMessagesListFiltersSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const {
    control, handleSubmit, reset, setError, setValue,
  } = useForm<AddInfoMessageFormFields>({
    defaultValues: {
      title: '',
      message: '',
      displayOrder: undefined,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  useEffect(() => {
    setValue('displayOrder', undefined);
    // eslint-disable-next-line
  }, [maxDisplayOrder]);

  const onCloseHandler = () => {
    reset();
    onClose();
  };

  const updateState = () => {
    dispatch(getInfoMessagesList({ filters: infoMessageListFilters }));
    dispatch(getInfoMessageMaxDisplayOrder());
  };

  const onSubmit = (values: AddInfoMessageFormFields) => {
    dispatch(addInfoMessageThunk(
      {
        data: {
          title: values.title.trim(),
          message: values.message.trim(),
          displayOrder: values.displayOrder as number,
        },
        onClose: onCloseHandler,
        updateState,
      },
    ));
  };

  return (
    <Modal
      title="Create new info message"
      visible={isVisible}
      onCancel={onCloseHandler}
      width={700}
      destroyOnClose
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="infoMessagesForm">
          <Controller
            control={control}
            name="title"
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
            }}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!v.trim()) {
                    setValue('title', '');
                    setError('title', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors?.title?.message}
                label="Title"
              />
            )}
          />
          <Controller
            name="message"
            control={control}
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 500, message: maxLengthMessageHandle(500) },
            }}
            render={({ field }) => (
              <Textarea
                value={field.value}
                onChange={field.onChange}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!v.trim()) {
                    setValue('message', '');
                    setError('message', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors?.message?.message}
                label="Message"
              />
            )}
          />
          <Controller
            control={control}
            name="displayOrder"
            rules={{
              required: ErrorsEnum.REQUIRED,
            }}
            render={({ field }) => {
              return (
                <Select
                  options={getDisplayOrders(maxDisplayOrder)}
                  value={field.value}
                  onChange={field.onChange}
                  error={errors?.displayOrder?.message}
                  label="Display order"
                  parentRender
                />
              );
            }}
          />
        </div>
        <ButtonActions
          createLabel="Create"
          cancelLabel="Cancel"
          createType="submit"
          cancelClick={onCloseHandler}
          isLoading={isGlobalLoading}
        />
      </form>
    </Modal>
  );
};

export default AddInfoMessageModal;
