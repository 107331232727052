import React, { useEffect, useState } from 'react';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { StockFiltersType } from '../../types/stockSearchTypes';
import { setStockSearchFilters } from '../../../../store/slices/stockSlice';
import { getStockSearch } from '../../../../store/thunks/stock/stockThunks';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import { SearchTableFilterType } from '../../../../core/types/coreTypes';
import { stockPartsColumnsSelector } from '../../../../store/selectors/stockSelectors';

type StockSearchColumnsProps = {
  filters: StockFiltersType,
  resultTotal: number,
}

const StockSearchColumns: React.FC<StockSearchColumnsProps> = ({ filters, resultTotal }) => {
  const dispatch = useAppDispatch();
  const stockSearchColumns = useAppSelector(stockPartsColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: filters.partCode,
    manufacturerCode: filters.manufacturerCode,
    name: filters.name,
  });

  useEffect(() => {
    setSearchVl({
      partCode: filters.partCode ? filters.partCode : undefined,
      manufacturerCode: filters.manufacturerCode ? filters.manufacturerCode : undefined,
      name: filters.name ? filters.name : undefined,
    });
  }, [filters]);

  const getFilteredStock = (newFilters: StockFiltersType) => {
    dispatch(setStockSearchFilters({ ...newFilters, page: 1 }));
    dispatch(getStockSearch({
      filters: { ...newFilters, page: 1 },
    }));
  };

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newValues = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      };
      getFilteredStock(newValues);
    } else {
      const newValues = {
        ...filters,
        order: { field, isAsc: true },
      };
      getFilteredStock(newValues);
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    const newValues = {
      ...filters,
      [field]: undefined,
    };
    if (!v) {
      getFilteredStock(newValues);
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = {
      ...filters,
      [field]: searchVl[field] ? searchVl[field]?.trim() : '',
    };
    getFilteredStock(newFilters);
  };
  return (
    <Thead>
      <Tr>
        {stockSearchColumns.map((col, i) => <ThWithControls
          key={i}
          col={col}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          totalElements={resultTotal}
          searchVl={searchVl}
          className={`stockSearchTh--${col.dataIndex}`}
          disabledFilter={!filters.keyword}
          tableFilters={filters}
        />)}
      </Tr>
    </Thead>
  );
};

export default StockSearchColumns;
