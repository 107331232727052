import React, { useMemo } from 'react';
import { DraftLinePartList, DraftLinePartsFiltersType } from '../../types/commonTypes';
import Tr from '../../../core/components/table-components/Tr';
import { getNo } from '../../../core/utils/entityNumberingHandle';
import Button from '../../../core/components/button/Button';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';

type PartsModeTableCellsProps = {
  setProdSourceModal: (id: number, name: string) => void,
  parts: DraftLinePartList,
  filters: DraftLinePartsFiltersType,
}

const PartsModeTableCells: React.FC<PartsModeTableCellsProps> = ({ parts, setProdSourceModal, filters }) => {
  const sortedList = useMemo(() => parts.items.map((el, i) => ({
    number: getNo(filters.page, filters.pageSize, i),
    id: el.id,
    partCode: el.partCode,
    manufacturerCode: el.manufacturerCode,
    partName: el.name,
    description: el.description,
  })), [parts.items, filters.page, filters.pageSize]);
  return (
    <>
      {sortedList.map((part, i) => <Tr key={i}>
        {Object.entries(part).map(([key, value]) => {
          if (key === 'id') return null;
          return <TdNew key={key}>
            {key === 'number'
              ? value
              : <TruncateTooltip value={value || '-'} table />}
          </TdNew>;
        })}
        <TdNew>
          <Button
            label="Select"
            onClick={() => setProdSourceModal(part.id, part.partName || '')}
            type="text"
          />
        </TdNew>
      </Tr>)}
    </>
  );
};

export default PartsModeTableCells;
