import { useState } from 'react';
import { StockFiltersType } from '../types/stockSearchTypes';
import { setStockSearchFilters, setStockSearchResult } from '../../../store/slices/stockSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { stockSearchFiltersList, stockSearchTreeFiltersList } from '../../../store/selectors/stockSelectors';
import { initDataList, initPaging, SearchTableFilterType } from '../../../core/types/coreTypes';

export const useStockSearchFiltersHandle = () => {
  const dispatch = useAppDispatch();

  const filtersList = useAppSelector(stockSearchFiltersList);
  const treeFiltersList = useAppSelector(stockSearchTreeFiltersList);
  const getFilteredResHandle = (filters: StockFiltersType) => {
    dispatch(setStockSearchFilters(filters));
  };

  const initDropdownSearch = { vehicleIds: '' };

  const [dropdownSearchValue, setDropdownSearchValue] = useState<SearchTableFilterType>(initDropdownSearch);

  const onClear = () => {
    setDropdownSearchValue(initDropdownSearch);
    getFilteredResHandle(initPaging);
    dispatch(setStockSearchResult(initDataList));
  };

  return {
    filtersList,
    treeFiltersList,
    initDropdownSearch,
    getFilteredResHandle,
    onClear,
    setDropdownSearchValue,
    dropdownSearchValue,
  };
};
