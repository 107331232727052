import React, { useState } from 'react';
import { RcFile } from 'antd/lib/upload';
import PartViewPageForm from './components/PartViewPageForm';
import PartMainInfo from './components/PartMainInfo';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  stockPartDetailsSelector,
  stockPartImageDataSelector,
  stockPartTotalQuantitySelector,
} from '../../../../store/selectors/stockSelectors';
import './StaticAreaContainer.scss';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../core/utils/guidGenerator';
import { addPartImageThunk, deletePartAttachmentThunk } from '../../../../store/thunks/stock/part/partAttachmentsThunks';
import { ForbiddenFileExtensions } from '../../../../common/utils/forbiddenFileExtensions';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { ALLOWED_IMAGE_FORMATS } from '../../../../core/utils/regex';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';

type StaticAreaContainerProps = {
  setVisibleAffectedModal: BooleanFunctionType,
}

const StaticAreaContainer: React.FC<StaticAreaContainerProps> = ({ setVisibleAffectedModal }) => {
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const totalQuantity = useAppSelector(stockPartTotalQuantitySelector);
  const { uri, id: partImageId } = useAppSelector(stockPartImageDataSelector);
  const dispatch = useAppDispatch();
  const [image, setImage] = useState<RcFile | null>(null);
  const [isConfirmationVisible, setIsConfirmationVisible] = useState(false);

  const uploadImageHandle = async (img: RcFile, deletePreviousImage: boolean) => {
    setImage(null);
    const image = {
      fileName: img.name,
      uploadGuid: guidGenerator(),
      file: img,
    };
    partDetails && dispatch(addPartImageThunk({
      id: partDetails.part.id,
      image,
      deletePreviousImage,
    }));
  };

  const deleteImageHandle = () => {
    partImageId && partDetails && dispatch(deletePartAttachmentThunk({
      id: partImageId,
      partId: partDetails?.part.id,
      isPartImage: true,
      closeModal: () => setIsConfirmationVisible(false),
    }));
  };

  const beforeUpload = (files: RcFile[]) => {
    const fileName = files[0].name;
    const isBigFile = files[0].size / 1024 / 1024 > 5;
    const currentExt = fileName.substring(fileName.lastIndexOf('.') + 1);
    const imagesExt = ALLOWED_IMAGE_FORMATS.map((el) => el.substring(el.lastIndexOf('/') + 1));
    const forbiddenExt = ForbiddenFileExtensions.includes(`.${currentExt.toUpperCase()}`)
      || !imagesExt.includes(currentExt.toUpperCase());
    if (isBigFile) {
      dispatch(setErrorMessage({ message: `File ${fileName} exceeds the maximum file size (5 MB).` }));
    }
    if (forbiddenExt) {
      dispatch(setErrorMessage({ message: `File ${fileName} has unsupported file format.` }));
    }
    if (isBigFile || forbiddenExt) {
      return false;
    } else {
      setImage(files[0]);
      return true;
    }
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirmationVisible}
        onCancel={() => setIsConfirmationVisible(false)}
        confirmHandler={deleteImageHandle}
      />
      <section>
        <PartMainInfo
          imageUri={uri}
          partDetails={partDetails}
          total={totalQuantity}
          formats={ALLOWED_IMAGE_FORMATS.join(',')}
          beforeUpload={beforeUpload}
          onChange={(deletePrev) => image && uploadImageHandle(image, deletePrev)}
          setConfirm={() => setIsConfirmationVisible(true)}
        />
        <PartViewPageForm partDetails={partDetails} setVisibleAffectedModal={setVisibleAffectedModal} />
      </section>
    </>
  );
};

export default StaticAreaContainer;
