import {
  OrderingType, PagingWithOrdering, StringRangeType,
} from '../../../core/types/coreTypes';

export type PartCategoryDto = {
  id: number,
  name: string | null,
  createdAt: string | null,
  updatedAt: string | null,
  subcategoriesCount: number,
};

export type PartCategoriesFilters = { keyword?: string, page?: number, pageSize?: number }

export type PartSubcategoriesFilters = {
  page?: number,
  pageSize?: number,
  order?: OrderingType,
  categoryIds?: number[],
  keyword?: string,
  createdAt?: StringRangeType,
  updatedAt?: StringRangeType,
}

export type PartSubcategoryDto = {
  id: number,
  name: string | null,
  categoryId: number,
  createdAt: string | null,
  updatedAt: string | null,
}

export enum PartSubcategoriesOrderingEnum {
  SubcategoryName = 'Name',
  CreatedDate = 'CreatedAt',
  ModifiedDate = 'UpdatedAt',
}

export type AffectedCategoriesFiltersType = PagingWithOrdering;

export enum AffectedCategoriesOrdering {
  EntityType = 'EntityType',
}
