import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  cancelledLinesFiltersDateSelector,
  cancelledLinesFiltersSelector,
  cancelledLinesGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { cancelledLinesReportOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { getPurchasingCancelledLinesReportOrdering } from '../../../../../store/thunks/core/coreOrderingThunks';
import {
  setCancelledLinesData,
  setCancelledLinesFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import { CancelledLinesOrdering } from '../../../../enum/ReportTableOrders';
import {
  DateRangeTableFilterType, initDataList, PickerValues,
} from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import { getCancelledLinesReportExcelThunk, getCancelledLinesReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { getCancelledLinesReportEntityTypesThunk, getPartRequestLineActivityTypesThunk } from '../../../../../store/thunks/coreThunk';

const CancelledLinesGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(cancelledLinesFiltersSelector);
  const checkboxFilters = useAppSelector(cancelledLinesGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(cancelledLinesFiltersDateSelector);
  const ordering = useAppSelector(cancelledLinesReportOrderingSelector);

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { actionDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        customerIds, businessAreas, entityTypes, actionInitiatorIds, activityTypes, actionDate,
      } = filters;
      const hasSelectedCheckboxes = [customerIds, businessAreas, entityTypes, actionInitiatorIds, activityTypes]
        .some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = actionDate && (actionDate.min || actionDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker;
    }
    return false;
  }, [filters]);

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters,
    ordering,
    getOrderingThunk: getPurchasingCancelledLinesReportOrdering,
    setFiltersAction: setCancelledLinesFilters,
    initialOrdering: CancelledLinesOrdering.ActionDate,
    isAsc: false,
  });

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getCancelledLinesReportEntityTypesThunk());
    dispatch(getPartRequestLineActivityTypesThunk());

    return () => {
      dispatch(setCancelledLinesFilters(defaultPagingAndOrdering));
      dispatch(setCancelledLinesData(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setCancelledLinesFilters({
      ...filters,
      entityNumber: undefined,
      partCode: undefined,
      partName: undefined,
      page: 1,
      [field]: values,
    }));
    dispatch(setCancelledLinesData(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      entityNumber: undefined,
      partCode: undefined,
      partName: undefined,
      page: 1,
      [field]: values,
    };
    dispatch(setCancelledLinesFilters(newFilters));
    dispatch(setCancelledLinesData(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setCancelledLinesFilters(defaultPagingAndOrdering));
    dispatch(setCancelledLinesData(initDataList));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      entityNumber, partCode, partName, page, pageSize, ...rest
    } = filters;
    dispatch(getCancelledLinesReportExcelThunk({ filters: rest }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getCancelledLinesReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default CancelledLinesGlobalFilters;
