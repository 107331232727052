import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import Button from '../../../../../core/components/button/Button';
import { generateOrderPdfThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import './ExportPdfModal.scss';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';

type ExportPdfModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
}

const ExportPdfModal: React.FC<ExportPdfModalProps> = ({ visible, onClose }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const handleClick = (isProforma: boolean) => {
    id && dispatch(generateOrderPdfThunk({ id: +id, isProformaExportTemplate: isProforma, onFinish: onClose }));
  };

  const buttons = [
    {
      label: 'Order export',
      onClick: () => handleClick(false),
    },
    {
      label: 'Proforma export',
      onClick: () => handleClick(true),
    },
  ];

  return (
    <Modal
      visible={visible}
      onCancel={() => !isGlobalLoading && onClose()}
      width={600}
      className="exportPdfModal"
      maskClosable={!isGlobalLoading}
    >
      <div className="exportPdfModal__choice">
        {buttons.map(({ label, onClick }) => (
          <Button
            key={label}
            label={label}
            onClick={onClick}
            icon={<FontAwesomeIcon icon={faArrowRight} />}
            htmlType="button"
            className="exportPdfModal__choice-item"
            disabled={isGlobalLoading}
            reversed
          />
        ))}
      </div>
    </Modal>
  );
};

export default ExportPdfModal;
