import React from 'react';
import { UseFormSetError } from 'react-hook-form';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import { patchCustomerThunk } from '../../../../../store/thunks/customers/customersViewPageThunks';
import './CustomerDetailsActivity.scss';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { CustomerFields } from '../../../../create-customer/utils/CustomerSchema';

type EditCustomerProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  onCancel: VoidFunctionType,
  customerName: string,
  fieldName: 'name' | 'customerType' | 'customerAccountNumber' | undefined,
  customerId?: number,
  fieldsSet: {
    name: string,
    customerType: number,
    customerAccountNumber: string,
  },
  setError?: UseFormSetError<CustomerFields>,
}

const EditCustomerConfirmationModal: React.FC<EditCustomerProps> = ({
  isVisible, onClose, onCancel, customerName, fieldName, fieldsSet, customerId, setError,
}) => {
  const dispatch = useAppDispatch();
  const fieldsLabels = {
    name: 'Customer name',
    customerType: 'Customer type',
    customerAccountNumber: 'Customer account No',
  };
  const editHandle = () => {
    customerId && fieldName && dispatch(patchCustomerThunk({
      id: customerId,
      data: { [fieldName]: fieldsSet[fieldName] },
      onClose: () => onClose(),
      setError,
    }));
  };

  return (
    <ConfirmationModal
      customTitle="Confirm"
      isVisible={isVisible}
      onCancel={onCancel}
      confirmHandler={editHandle}
      customCreateLabel="Yes, update"
      customText={<>
        Customer
        <b>{` "${customerName}" `}</b>
        is used in multiple entities in the system.
        <br />
        Please note that
        <b>{` "${fieldsLabels[fieldName as keyof typeof fieldsLabels]}" `}</b>
        value will be updated to a new value in all linked entities.
      </>}
    />
  );
};

export default EditCustomerConfirmationModal;
