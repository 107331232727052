import React from 'react';
import classnames from 'classnames';
import { Link, useLocation } from 'react-router-dom';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import usePermission from '../../../permissions-handling/permissionHook';
import { VoidFunctionType } from '../../../core/types/coreTypes';

type PrivateLineLinkProps = {
  children: React.ReactNode,
  to: string,
  permission: PermissionEnum,
  className?: string,
  customClick?: VoidFunctionType,
  fitContent?: boolean,
  notLink?: boolean,
}
const PrivateLineLink: React.FC<PrivateLineLinkProps> = ({
  children, to, className, permission, customClick, fitContent, notLink,
}) => {
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const { pathname, search } = useLocation();
  const allowed = usePermission(permission);

  return (
    allowed && !notLink
      ? <Link
        to={to}
        onClick={(event) => {
          const { target } = event;
          if (target instanceof Element && target.classList.contains('ant-tooltip-inner')) {
            event.preventDefault();
          } else {
            setLocationHistoryHandle({ pathname, search });
            customClick && customClick();
          }
        }}
        className={classnames(className, 'privateLineLink', { 'fit-content': fitContent })}
      >
        {children}
      </Link>
      : <div className={classnames('not-link', className)}>{children}</div>
  );
};

export default PrivateLineLink;
