import React, { useCallback } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { CustomAny } from '../../../core/types/coreTypes';
import Tr from '../../../core/components/table-components/Tr';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import Button from '../../../core/components/button/Button';
import { RoleTreeNode } from './PermissionsTable';
import { patchRolePermissions } from '../../../store/thunks/settings/permissionsThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { adminRoleSelector } from '../../../store/selectors/settingsSelectors';
import { PermissionPermissions } from '../../users-dashboard/utils/data';
import { getPermissionNames, hasKids } from '../../users-dashboard/utils/permissionHelpers';
import usePermission from '../../../permissions-handling/permissionHook';

type PermissionBodyProps = {
  openedNodes: string[],
  openNodeHandler: CustomAny,
  roles: RoleTreeNode[],
  tree: CustomAny,
  permissionModule?: number;
}

const PermissionBody: React.FC<PermissionBodyProps> = ({
  openedNodes, openNodeHandler, roles, tree, permissionModule,
}) => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const adminRole = useAppSelector(adminRoleSelector);

  const checkHandle = (v: string) => {
    const splitRes = v.split('-');
    const foundPar = roles.find((parRole) => parRole.role === +splitRes[0]);
    const kids = foundPar?.colSpan || [];
    const foundKid = kids.find((k) => k.roleLevel === +splitRes[1]);
    const kidPermissions = foundKid?.permissions || [];
    if (kidPermissions.includes(splitRes[2])) {
      const kids = hasKids(tree, splitRes[2]);

      if (kids) {
        const names = getPermissionNames(kids);
        const newArr = kidPermissions.filter((k) => !names.includes(k));
        permissionModule && dispatch(patchRolePermissions({
          roles: [
            {
              role: +splitRes[0],
              roleLevel: +splitRes[1],
              module: +permissionModule,
              permissions: newArr,
            },
          ],
          module: permissionModule,
        }));
      } else {
        const newArr = kidPermissions.filter((k) => k !== splitRes[2]);
        permissionModule && dispatch(patchRolePermissions({
          roles: [
            {
              role: +splitRes[0],
              roleLevel: +splitRes[1],
              module: +permissionModule,
              permissions: newArr,
            },
          ],
          module: permissionModule,
        }));
      }
    } else {
      permissionModule && dispatch(patchRolePermissions({
        roles: [
          {
            role: +splitRes[0],
            roleLevel: +splitRes[1],
            module: +permissionModule,
            permissions: [...kidPermissions, splitRes[2]],
          },
        ],
        module: permissionModule,
      }));
    }
  };

  const isPermissionLevelAllowed = useCallback((permission: PermissionEnum) => {
    if (PermissionPermissions.includes(permission)) {
      return currentUser?.userContextRole.role === adminRole?.role.role;
    } else return true;
  }, [currentUser, adminRole]);

  const isChecked = (parentRole: RoleTreeNode, childRole: RoleTreeNode, testPermission: string) => {
    const foundRole = parentRole.colSpan?.find((c) => c.role === childRole.role && childRole.roleLevel === c.roleLevel);
    const foundRolePermissions = foundRole?.permissions;
    return foundRolePermissions && foundRolePermissions.length > 0 ? foundRolePermissions.includes(testPermission) : false;
  };
  const isDisabled = (parentPermission: string | null, childRole: RoleTreeNode) => {
    return parentPermission && !childRole.permissions.includes(parentPermission);
  };
  const allowedToChange = usePermission(PermissionEnum.UserEditPermissions);
  const renderSubNodes = useCallback((subNodes: CustomAny) => {
    return (
      <>
        {subNodes?.map((node: CustomAny) => <>
          <Tr
            className={classNames('permissionTr', {
              'visible': (node.parentPermission && openedNodes.includes(node.parentPermission)) || !node.parentPermission,
            })}
            key={`${node.parentPermission}-${node.level}-${node.permission}`}
          >
            <th
              className={classNames('permissionTh', `permissionTh--level-${node.level}`)}
            >
              {node.level > 1 && node.level < 5 && node.children && <Button
                icon={<FontAwesomeIcon
                  icon={openedNodes.includes(node.permission) ? faMinusCircle : faPlusCircle}
                />}
                onClick={() => openNodeHandler(node)}
                isExpand
              />}
              <span>{node.name}</span>
            </th>
            <td className="permissionTd">
              <div className="checkWrap">
                <div className="checkWrap-inner checkWrap-inner--admin">
                  <Checkbox
                    checked={roles.length > 0 && roles[0].permissions.includes(node.permission)}
                    value={roles.length > 0 ? `${roles[0].role}-${roles[0].roleLevel}-${node.permission}` : ''}
                    onChange={() => checkHandle(`${roles[0].role}-${roles[0].roleLevel}-${node.permission}`)}
                    disabled
                    className="permCheckbox"
                  />
                </div>
              </div>
            </td>
            {roles.slice(1)
              .map((role: RoleTreeNode) => role.colSpan?.map((el: RoleTreeNode, i: number) => {
                const isBoxDisabled = isDisabled(node.parentPermission, el)
                    || !isPermissionLevelAllowed(node.permission)
                    || !allowedToChange;
                const boxKey = `${el.role}-${el.roleLevel}-${i}`;
                const boxValue = `${el.role}-${el.roleLevel}-${node.permission}`;

                return <td
                  key={boxKey}
                  className={classNames('permissionTd', { 'permissionTd--level-1': el.roleLevel === 1 })}
                >
                  <div className="checkWrap">
                    <div className="checkWrap-inner">
                      <Checkbox
                        checked={isChecked(role, el, node.permission)}
                        value={boxValue}
                        onChange={() => checkHandle(boxValue)}
                        className="permCheckbox"
                        disabled={isBoxDisabled}
                      />
                    </div>
                  </div>
                </td>;
              }))}
          </Tr>
          {renderSubNodes(node.children)}
        </>)}
      </>
    );
    // eslint-disable-next-line
  }, [roles, openedNodes]);
  return (
    <>
      {renderSubNodes(tree)}
    </>
  );
};

export default PermissionBody;
