import React from 'react';
import { capitalize, startCase } from 'lodash';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';
import { fieldNameEventHandle, getReadableBoolean } from '../../../../../../common/utils/activityLogUtils';

type StockPartRequestFieldUpdateEventProps = FieldUpdateEventProps & { productNumber?: string, partRequestLineNumber?: number };
const StockPartRequestFieldUpdateEvent: React.FC<StockPartRequestFieldUpdateEventProps> = ({
  field, oldValue, newValue, productNumber, partRequestLineNumber,
}) => {
  const valueHandle = (value: string | number) => {
    if (value === 'True' || value === 'False') {
      return getReadableBoolean(value);
    }
    return Number.isNaN(+value) ? capitalize(startCase(value.toString())) : value;
  };

  return (
    <>
      In line
      {' '}
      {partRequestLineNumber || 'N/A'}
      {' '}
      with part code
      {' '}
      {productNumber ? <b>{productNumber}</b> : 'N/A'}
      {' '}
      field
      {' '}
      <b>{fieldNameEventHandle(field)}</b>
      {' '}
      was changed:
      {' '}
      {!!oldValue && <s>{`${valueHandle(oldValue)}`}</s>}
      {!!newValue && <b>{` ${valueHandle(newValue)}`}</b>}
    </>
  );
};

export default StockPartRequestFieldUpdateEvent;
