import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { ReportTableColumns } from '../../../../../types/RectificationReportsTypes';
import { SearchTableFilterType } from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { cancelledLinesFiltersSelector, reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import { setCancelledLinesFilters } from '../../../../../../store/slices/reportsSlice';
import {
  getCancelledLinesReportExcelThunk,
  getCancelledLinesReportThunk,
} from '../../../../../../store/thunks/reports/reportsThunks';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import Thead from '../../../../../../core/components/table-components/Thead';

const CancelledLinesColumns: React.FC<ReportTableColumns> = ({ columns, totalCount }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(cancelledLinesFiltersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    entityNumber: filters.entityNumber,
    partCode: filters.partCode,
    partName: filters.partName,
  });

  useEffect(() => {
    if (!reportIsVisible) {
      setSearchVl({
        entityNumber: undefined,
        partCode: undefined,
        partName: undefined,
      });
    }
  }, [reportIsVisible]);

  const sortHandle = (field: number) => {
    const newFilters = {
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    };
    dispatch(setCancelledLinesFilters(newFilters));
    dispatch(getCancelledLinesReportThunk({ filters: newFilters }));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = { ...filters, [field]: undefined, page: 1 };
      dispatch(setCancelledLinesFilters(newFilters));
      dispatch(getCancelledLinesReportThunk({ filters: newFilters }));
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = { ...filters, [field]: searchVl[field]?.trim(), page: 1 };
    dispatch(setCancelledLinesFilters(newFilters));
    dispatch(getCancelledLinesReportThunk({ filters: newFilters }));
  };

  const exportToExcel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, pageSize, ...rest } = filters;
    dispatch(getCancelledLinesReportExcelThunk({ filters: rest }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            totalElements={totalCount}
            className={`cancelledLinesTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
          />
        ))}
        <ThNew action>
          <Dropdown
            disabled={!totalCount}
            menuItems={[
              {
                label: 'Export to Excel',
                icon: <FontAwesomeIcon icon={faFileExcel} />,
                key: 'export-to-excel',
                onClick: exportToExcel,
              },
            ]}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default CancelledLinesColumns;
