import moment from 'moment';
import { useSearchParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import { YesNoOptions } from '../../../core/utils/testData';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  activeSalesStatusSelector,
  archivedSalesStatusSelector,
  salesDashboardFiltersSelector,
  salesDashboardSelector,
} from '../../../store/selectors/invoicingSelectors';
import { SalesAllStatusEnum, SalesUrlParamsNames } from '../enums/salesEnums';
import { DateRangeTableFilterType, PickerValues } from '../../../core/types/coreTypes';
import { StockTabParamEnum } from '../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { setSalesDashboardFilters } from '../../../store/slices/invoicingSlice';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { OrderViewsFilters } from '../../../orders/dashboard/types/ordersTypes';
import { getSalesOrdersList } from '../../../store/thunks/invoicing/salesThunks';
import { orderStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';

export const useSalesDashboardKeywordFilters = () => {
  const dispatch = useAppDispatch();
  const { controller, cancelRequest } = useCancelRequest();
  const {
    postCheckPassed, acceptedByCustomer, invoicing,
  } = useAppSelector(orderStatusesValuesSelector);

  const [searchParams, setSearchParams] = useSearchParams();
  const page = searchParams.get(SalesUrlParamsNames.PAGE);
  const tab = searchParams.get(SalesUrlParamsNames.TAB_TYPE);
  const status = searchParams.get(SalesUrlParamsNames.ORDER_STATUS);
  const archivedStatus = useAppSelector(archivedSalesStatusSelector);
  const activeStatus = useAppSelector(activeSalesStatusSelector);

  const filters = useAppSelector(salesDashboardFiltersSelector);
  const { minCreatedDate, maxCreatedDate } = useAppSelector(salesDashboardSelector);

  const finalStatusOptions = useMemo(() => (tab && tab === StockTabParamEnum.ARCHIVED
    ? archivedStatus
    : activeStatus), [tab, activeStatus, archivedStatus]);

  const setStatusParam = (value: string) => {
    page && tab && setSearchParams({
      page,
      [SalesUrlParamsNames.TAB_TYPE]: tab,
      [SalesUrlParamsNames.ORDER_STATUS]: value,
    });
  };

  const getFilteredList = (newFilters: OrderViewsFilters, noThunkCall?: boolean) => {
    dispatch(setSalesDashboardFilters(newFilters));
    if (!noThunkCall) {
      cancelRequest();
      dispatch(getSalesOrdersList({
        filters: { ...newFilters, order: filters.order || { field: 1, isAsc: true } },
        signal: controller.current?.signal,
      }));
    }
  };

  const setStatusHandle = (status: number | string) => {
    setStatusParam(status.toString());
    const newFilters = {
      ...filters,
      page: 1,
      statuses: status === SalesAllStatusEnum.ALL
        ? [postCheckPassed as number, acceptedByCustomer as number, invoicing as number]
        : [+status],
    };
    getFilteredList(newFilters, true);
  };

  const setDefaultStatusParam = () => {
    if (filters.statuses?.length) {
      const statusToSet = filters.statuses.length === 3
        ? SalesAllStatusEnum.ALL.toString()
        : filters.statuses[0].toString();
      setStatusParam(statusToSet);
    } else {
      if (tab === StockTabParamEnum.ARCHIVED && archivedStatus.length) {
        const firstV = archivedStatus[0].value;
        setStatusParam(firstV.toString());
      } else {
        setStatusParam(SalesAllStatusEnum.ALL.toString());
      }
    }
  };

  const extraFilters = [
    {
      label: 'With draft entities',
      name: 'hasDraftInvoiceDocuments',
      value: filters.hasDraftInvoiceDocuments || [],
      noSearch: true,
      options: YesNoOptions,
    },
  ];
  const dateFilters = [
    {
      label: 'Creation date',
      name: 'createdDate',
      value: filters.createdDate,
      disabledDate: (current: moment.Moment) => {
        return current.isBefore(moment(minCreatedDate).startOf('day'))
                    || current.isAfter(moment(maxCreatedDate).endOf('day'));
      },
    },
  ];

  const [searchValue, setSearchValue] = useState<string | undefined>(filters.keyword);
  const initialPickerValue: DateRangeTableFilterType = {
    createdDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values,
      page: 1,
    };
    getFilteredList(newFilters);
  };

  return {
    extraFilters,
    dateFilters,
    tab,
    status,
    archivedStatus,
    setStatusHandle,
    setDefaultStatusParam,
    finalStatusOptions,
    searchValue,
    setSearchValue,
    getFilteredList,
    pickerValue,
    setPickerValue,
    pickerHandle,
  };
};
