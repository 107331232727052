import { locationRequisiteFormHandle } from '../../../core/utils/locationRequisiteFormHandle';
import { AddressFields } from '../../../common/types/commonTypes';
import { ConsumerTypeEnum } from '../enums/createEnquiryEnum';
import { CreateEnquiryFields } from './CreateEnquirySchema';
import { useAppSelector } from '../../../store/hooks';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { customerDetailsSelector } from '../../../store/selectors/customersSelectors';
import { RequisiteFields } from '../../../common/utils/data';

export const useCreateEnquiry = () => {
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const customerDetails = useAppSelector(customerDetailsSelector);
  const alwaysUseCustomerRequisites = customerDetails?.customer?.alwaysUseRequisites;

  const createEnquiryFinalDataHelper = (
    values: CreateEnquiryFields,
    customerVal: ConsumerTypeEnum,
    isSame: boolean,
    addressFields: AddressFields | null,
    requisiteFields: RequisiteFields,
    requisiteCustomerId: { billing?: number, shipping?: number },
    isManual: { billing: boolean, shipping: boolean },
  ) => {
    const { billing, shipping } = requisiteFields;
    const location = locationRequisiteFormHandle<AddressFields | null>(addressFields);
    const customerExistingName = customerDetails?.customer?.name;
    const trimmedValues = {
      ...values,
      customerName: customerVal === ConsumerTypeEnum.NEW ? (values.name as string).trim() : customerExistingName,
      customerAccountNumber: values.customerAccountNumber.trim(),
      description: values.description ? values.description.trim() : null,
    };
    const finalNewData = {
      ...trimmedValues,
      customerType: values.customerType as number,
      enquirySource: values.enquirySource as number,
      priorityLevel: values.priorityLevel as number,
      jobType: values.jobType as number,
      assignedTo: values.assignedTo as number,
      businessArea: currentBusinessArea as number,
      estimatedCost: +values.estimatedCost,
      jobCompanyName: location?.companyName || null,
      jobAddressLine1: location?.addressLine1 || null,
      jobAddressLine2: location?.addressLine2 || null,
      jobCityTown: location?.cityTown || null,
      jobRegion: location?.region || null,
      jobPostalCode: location?.postalCode || null,
      jobTelephone: location?.telephone || null,
      billingDetails: billing,
      shippingDetails: isSame ? null : shipping,
      isShippingRequisiteSameAsBilling: isSame,
    };

    const finalExistingData = {
      ...finalNewData,
      billingDetails: (alwaysUseCustomerRequisites || (requisiteCustomerId.billing && !isManual.billing))
        ? null
        : finalNewData.billingDetails,
      shippingDetails: (alwaysUseCustomerRequisites || (requisiteCustomerId.shipping && !isManual.shipping))
        ? null
        : finalNewData.shippingDetails,
      billingCustomerId: alwaysUseCustomerRequisites
        ? null
        : (requisiteCustomerId.billing || customerDetails?.customer?.id),
      shippingCustomerId: alwaysUseCustomerRequisites
        ? null
        : isSame ? null : (requisiteCustomerId.shipping || customerDetails?.customer?.id),
      isShippingRequisiteSameAsBilling: alwaysUseCustomerRequisites
        ? null
        : finalNewData.isShippingRequisiteSameAsBilling,
      customerId: customerDetails?.customer?.id,
      customerAccountNumber: null,
    };

    return { finalNewData, finalExistingData };
  };

  return { createEnquiryFinalDataHelper };
};
