import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { RootState } from '../../../store/store';
import {
  setVehicleAttachments,
  setVehicleAttachmentsFilters,
  setVehicleChecks,
  setVehicleChecksFilters,
  setVehicleDetails,
  setVehicleLinks,
  setVehicleLinksFilters, setVehicleTimeline, setVehicleTimelineFilters,
} from '../../../store/slices/vehiclesSlice';
import {
  initDataListWithIds,
  initDataList, initialCoreAttachmentsFilters, initLinksFilters, initPaging,
} from '../../../core/types/coreTypes';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const vehicleViewPageCleanupHandle = (dispatch: ThunkDispatch<RootState, undefined, AnyAction>) => {
  dispatch(setVehicleDetails(null));
  dispatch(setVehicleAttachmentsFilters(initialCoreAttachmentsFilters));
  dispatch(setVehicleAttachments(initDataListWithIds));
  dispatch(setVehicleLinksFilters(initLinksFilters));
  dispatch(setVehicleLinks([]));
  dispatch(setVehicleChecksFilters(initPaging));
  dispatch(setVehicleChecks(initDataList));
  dispatch(setVehicleTimeline(initDataList));
  dispatch(setVehicleTimelineFilters(initExtendedTimelineFilters));
};
