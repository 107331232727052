import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import PartViewPageTabs from './components/PartViewPageTabs';
import DynamicContent from './components/DynamicContent';
import { PartTabs, PartTabsEnum } from '../../enums/PartTypesEnum';
import { useAppSelector } from '../../../../store/hooks';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import './DynamicAreaContainer.scss';
import { useStockPartTabs } from '../../hooks/useStockPartTabs';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';

const DynamicAreaContainer:React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const currentUser = useAppSelector(currentUserSelector);

  const { tabs } = useStockPartTabs();

  useEffect(() => {
    if (currentUser) {
      const partTabParam = params.get('part_tab');
      const { permissions } = currentUser.userContextRole;
      if (!partTabParam && permissions.includes(PermissionEnum.StockPartViewPage)) {
        setSearchParams({ part_tab: tabs[0].value }, { replace: true });
      } else {
        if (!PartTabs.includes(partTabParam as PartTabsEnum)) {
          setSearchParams({ part_tab: PartTabsEnum.VEHICLESANDPRICES }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, tabs]);

  const setPartTabParam = (partTab: string) => {
    setSearchParams({ part_tab: partTab }, { replace: true });
  };

  return (
    <section>
      <PartViewPageTabs tabs={tabs} partTab={params.get('part_tab') as string} setModeParams={setPartTabParam} />
      <DynamicContent mode={params.get('part_tab') as string} />
    </section>
  );
};

export default DynamicAreaContainer;
