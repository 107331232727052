export const maxLengthMessageHandle = (length: number, max = true) => {
  return `Entered value should have ${max ? 'max ' : ''}${length} characters`;
};

export const invalidValueMessageHandle = (field: string) => {
  return `Enter a valid ${field}.`;
};

export const rangeLengthMessageHandle = (range: [number, number]) => {
  return `Entered value should have ${range[0]} to ${range[1]} characters`;
};

export const nameMessageHandle = () => {
  return 'Entered value should not include numbers or special characters except for "-" or "\'".';
};

export const maxDateMessageHandle = (date: string) => {
  return `Entered value should be before ${date}`;
};
