import React from 'react';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../truncate-tooltip/TruncateTooltip';
import Tbody from '../../../../core/components/table-components/Tbody';

type VehicleRow = {
  number: number,
  code: string,
  manufacturer: string,
  modelDescription: string,
}
type GroupRow = {
  number: number,
  name: string,
  vehicleProfilesCount: number,
}

type SuitableForVehiclesChangeBodyProps = {
  rows: Array<VehicleRow | GroupRow>,
}

const SuitableForVehiclesChangeBody: React.FC<SuitableForVehiclesChangeBodyProps> = ({ rows }) => {
  return (
    <Tbody>
      {rows.map((el) => (
        <Tr key={el.number}>
          {Object.entries(el).map(([key, value]) => (
            <TdNew key={key}>
              {key === 'number'
                ? value
                : <TruncateTooltip value={value} table />}
            </TdNew>
          ))}
        </Tr>
      ))}
    </Tbody>
  );
};

export default SuitableForVehiclesChangeBody;
