import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxesStacked, faPencil } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import StockPartReqInfoItem from './StockPartReqInfoItem';
import { jobTypeIconFn, priorityLevelHandle } from '../../../../core/utils/cardHandlers';
import { useAppSelector } from '../../../../store/hooks';
import { jobTypesSelector, priorityLevelsSelector } from '../../../../store/selectors/coreSelectors';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { StockPartRequestUiType } from '../../types/stockPartRequestTypes';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../core/utils/regex';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';

type StockPartRequestLineProps = {
  item: StockPartRequestUiType,
}

const StockPartRequestLine: React.FC<StockPartRequestLineProps> = ({ item }) => {
  const infoData = [
    { label: 'Linked to', value: item.linkedEntityNumber },
    { label: 'Entity status', value: item.linkedEntityStatus || '' },
    { label: 'Entity owner', value: `${item.linkedEntityOwnerFirstName} ${item.linkedEntityOwnerLastName}` },
    { label: 'Due date', value: item.dueDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.dueDate) : '-' },
  ];
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(jobTypesSelector);
  return (
    <PrivateLineLink
      to={`part-request/${item.id}`}
      permission={PermissionEnum.StockPartRequestViewPage}
      className="stockPartReqLine"
    >
      <span className="stockPartReqLine__title">
        <FontAwesomeIcon icon={faBoxesStacked} className="stockPartReqLine__icon" />
        <TruncateTooltip value={item.partRequestNumber} className="stockPartReqLine__title-value" overlayClassName="card-tooltip-overlay" />
      </span>
      <span className="stockPartReqLine__info">
        {infoData.map((el, i) => <StockPartReqInfoItem
          key={i}
          label={el.label}
          value={el.value}
        />)}
      </span>
      <span className="stockPartReqLine__icons">
        <Tooltip title={priorityLevels.find((p) => p.value === item.priorityLevel)?.label} overlayClassName="card-tooltip-overlay">
          <span className="stockPartReqLine__round-icon">
            <FontAwesomeIcon
              icon={priorityLevelHandle(priorityLevels, item.priorityLevel)}
              className="itemPriority-icon"
            />
          </span>
        </Tooltip>
        <Tooltip title={jobTypes.find((j) => j.value === item.jobType)?.label} overlayClassName="card-tooltip-overlay">
          <span>
            <img width={24} src={jobTypeIconFn(jobTypes, item.jobType)} alt="" className="itemJobType" />
          </span>
        </Tooltip>
        {item.numberOfModifications > 0 && <Tooltip
          title={`Modified ${item.numberOfModifications} time(s)`}
          overlayClassName="card-tooltip-overlay"
        >
          <span className="stockPartReqLine__round-icon">
            <FontAwesomeIcon
              icon={faPencil}
              className="itemPriority-icon"
            />
          </span>
        </Tooltip>}
      </span>
      <span className="stockPartReqLine__status">
        <TruncateTooltip value={item.status} className="stockPartReqLine__tag tag tag--text-md" overlayClassName="card-tooltip-overlay" />
      </span>
      <span className="stockPartReqLine__count">
        <span className="stockPartReqLine__count-label">Items</span>
        <span className="stockPartReqLine__count-value">{item.itemsCount}</span>
      </span>
    </PrivateLineLink>
  );
};

export default StockPartRequestLine;
