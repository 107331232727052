// TODO MDA: refactor this (move trim to address modal?)
export const locationRequisiteFormHandle = <L, >(locationFields: L) => {
  const location = { ...locationFields };
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  Object.keys(location).forEach((k) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (location[k]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      location[k] = location[k].trim();
    }
  });

  return location;
};
