import React, { useEffect } from 'react';
import ActivityComponent from './ActivityComponent';
import DetailsComponent from './DetailsComponent';
import './DetailsWrapper.scss';
import { useAppDispatch } from '../../../../../store/hooks';
import { getEnquiryEventTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';

const DetailsWrapper: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getEnquiryEventTypesThunk());
    // eslint-disable-next-line
  }, []);
  return (
    <div className="detailsWrap info-grid">
      <DetailsComponent />
      <ActivityComponent />
    </div>
  );
};

export default DetailsWrapper;
