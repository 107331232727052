import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum EmployeeUrlParamsNames {
  PAGE = 'page',
  STATUS = 'status',
}

export enum EmployeesViewsEnums {
  DASHBOARD = 'Dashboard',
  WORKING_HOURS = 'Working hours',
  LEAVE_REQUESTS = 'Leave requests',
  SKILL_LIST = 'Skill list',
  CREATE_NEW_EMPLOYEE = 'Create new employee',
}

export enum EmployeesPageParamEnums {
  DASHBOARD = 'dashboard',
  WORK_CALENDAR = 'working-hours',
  LEAVE_REQUESTS = 'leave-requests',
  SKILL_LIST = 'skill-list',
  CREATE_NEW_EMPLOYEE = 'create-new-employee',
}

export const EmployeesPageParams = [
  EmployeesPageParamEnums.DASHBOARD,
  EmployeesPageParamEnums.WORK_CALENDAR,
  EmployeesPageParamEnums.LEAVE_REQUESTS,
  EmployeesPageParamEnums.SKILL_LIST,
  EmployeesPageParamEnums.CREATE_NEW_EMPLOYEE,
];

export const EmployeesPageParamsAndPermissions = [
  { page: EmployeesPageParamEnums.DASHBOARD, permission: PermissionEnum.EmployeeDashboard },
  { page: EmployeesPageParamEnums.WORK_CALENDAR, permission: PermissionEnum.EmployeeWorkingHours },
  { page: EmployeesPageParamEnums.LEAVE_REQUESTS, permission: PermissionEnum.EmployeeLeaveRequests },
  { page: EmployeesPageParamEnums.SKILL_LIST, permission: PermissionEnum.EmployeeSkillsListPage },
  { page: EmployeesPageParamEnums.CREATE_NEW_EMPLOYEE, permission: PermissionEnum.EmployeeCreatePage },
];
