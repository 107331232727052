import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Button from '../../../core/components/button/Button';
import { StringFunctionType } from '../../../core/types/coreTypes';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal, maxCommonWhole } from '../../../core/utils/regex';
import './QuantityComponent.scss';

type QuantityComponentProps = {
  disabled?: boolean,
  disabledPlus?: boolean,
  disabledMinus?: boolean,
  value: string,
  setValue: StringFunctionType,
  isDecimals: boolean,
  className?: string,
  maxInputValue?: number,
}

const QuantityComponent: React.FC<QuantityComponentProps> = ({
  disabled, disabledPlus, disabledMinus, value, setValue, isDecimals, className, maxInputValue,
}) => {
  return (
    <div className={classNames(className, 'sourceQty')}>
      <Button
        disabled={+value === 0 || disabledMinus || disabled}
        className="sourceQty__minus"
        icon={<FontAwesomeIcon icon={faMinus} />}
        onClick={() => {
          if (+value !== 0) {
            if (isDecimals) {
              const substracted = (+value - 0.01).toFixed(2).toString();
              setValue(substracted);
            } else {
              const substracted = (+value - 1).toString();
              setValue(substracted);
            }
          }
        }}
      />
      {isDecimals
        ? <DecimalInputNumber
          value={value}
          onChange={(e) => setValue(e.target.value.replace(',', '.'))}
          max={maxInputValue || maxCommonDecimal}
          disabled={disabled}
          autoFocus={!disabled}
          className="sourceQty__value"
        />
        : <RegularInputNumber
          value={value}
          onChange={(e) => setValue(e.target.value)}
          max={maxInputValue || maxCommonWhole}
          disabled={disabled}
          autoFocus={!disabled}
          className="sourceQty__value"
        />}
      <Button
        disabled={disabledPlus || disabled}
        className="sourceQty__plus"
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => {
          if (!value || +value < maxCommonWhole) {
            if (isDecimals) {
              const incremented = ((+value || 0) + 0.01).toFixed(2).toString();
              setValue(incremented);
            } else {
              const incremented = ((+value || 0) + 1).toString();
              setValue(incremented);
            }
          }
        }}
      />
    </div>
  );
};

export default QuantityComponent;
