import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import Pagination from '../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Thead from '../../../core/components/table-components/Thead';
import TableLoader from '../../../core/components/table-components/TableLoader';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import ExistedVehicleCatalogColumns from './ExistedVehicleCatalogColumns';
import ExistedVehicleCatalogBody from './ExistedVehicleCatalogBody';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import {
  VehicleProfileSearchType,
  VehicleProfilesFilters,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

type ExistedVehicleCatalogSectionProps = {
  totalCount: number,
  groups: number[],
  filters: VehicleProfilesFilters,
  setFilters: (v: VehicleProfilesFilters) => void,
  setDefaultList: VoidFunctionType,
  dataList: VehicleProfileSearchType[],
  fetchProfiles: (v: VehicleProfilesFilters) => void,
  setChosenVehicleProfile: (v: VehicleProfileSearchType | null) => void,
}

const ExistedVehicleCatalogSection: React.FC<ExistedVehicleCatalogSectionProps> = ({
  totalCount, filters, setFilters, setDefaultList, groups,
  dataList, fetchProfiles, setChosenVehicleProfile,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const [error, setError] = useState<string>('');

  const filtersHandle = (filters: VehicleProfilesFilters) => {
    setFilters({ ...filters });
    fetchProfiles({ ...filters });
  };

  const searchTriggerFn = () => {
    if (filters.keyword) {
      filtersHandle({ ...filters, keyword: filters.keyword.trim(), page: 1 });
    }
  };

  return (
    <div className="existedVehicleCatalogSection">
      <InputWithButton
        value=""
        onChange={(e) => {
          const val = e.target.value;
          if (!val.trim() && totalCount !== 0) {
            setDefaultList();
          }
          if (val.length > 100) {
            setError(maxLengthMessageHandle(100));
          } else {
            error && setError('');
          }
          setFilters({ ...filters, keyword: val });
        }}
        onPressEnter={() => {
          const searchValue = filters.keyword;
          if (!searchValue?.trim()) {
            setError(ErrorsEnum.REQUIRED);
            setFilters({ ...filters, keyword: '' });
          } else {
            searchValue.trim() && !error && searchTriggerFn();
          }
        }}
        placeholder="Search by vehicle code, manufacturer or model description"
        icon={<FontAwesomeIcon icon={faSearch} />}
        error={error}
        onClick={() => {
          const searchValue = filters.keyword;
          searchValue?.trim() && !error && searchTriggerFn();
        }}
        buttonDisabled={!filters.keyword?.trim() || !!error}
        className="existedVehicleCatalogSection__input"
      />
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page, pageSize) => filtersHandle({ ...filters, page, pageSize })}
        totalItems={totalCount}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        className="existedVehicleCatalogSection__pagination"
      />
      <TableNew>
        <Thead>
          <ExistedVehicleCatalogColumns
            totalCount={totalCount}
            filters={filters}
            filtersHandle={filtersHandle}
            groups={groups}
          />
        </Thead>
        {isFetching
          ? <TableLoader colspan={6} />
          : <ExistedVehicleCatalogBody
            vehicleList={dataList}
            totalCount={totalCount}
            searchValue={filters.keyword || ''}
            setChosenVehicleProfile={setChosenVehicleProfile}
          />}
      </TableNew>
    </div>
  );
};

export default ExistedVehicleCatalogSection;
