import React from 'react';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tbody from '../../../../core/components/table-components/Tbody';
import { NumberFunctionType } from '../../../../core/types/coreTypes';
import Tag from '../../../../core/components/tag/Tag';
import { useAppSelector } from '../../../../store/hooks';
import { invoiceDocumentsSelector } from '../../../../store/selectors/invoicingSelectors';

type SpecificEntitiesBodyProps = {
  checkRow: NumberFunctionType,
  checkedKeys: number[],
}

const SpecificEntitiesBody: React.FC<SpecificEntitiesBodyProps> = ({ checkedKeys, checkRow }) => {
  const { items, totalCount } = useAppSelector(invoiceDocumentsSelector);

  const nowrapKeys = ['net', 'gross'];
  const truncateKeys = ['itemName', 'itemNumber', 'entityNumber'];

  const renderCellValue = (key: string, value: string | number | null) => {
    if (truncateKeys.includes(key)) return <TruncateTooltip value={value} table />;
    if (key === 'status') return <Tag label={value} />;
    return value;
  };

  return (
    <Tbody>
      {totalCount === 0
        ? <EmptyTableSection text="No records" colSpan={8} />
        : <>
          {items.map((el, i) => <Tr key={i}>
            <TdNew>
              <Checkbox
                checked={checkedKeys.includes(el.id)}
                onChange={() => checkRow(el.id)}
                table
              />
            </TdNew>
            <>
              {Object.entries(el).map(([key, value]) => {
                if (key === 'id') return null;
                return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
                  {renderCellValue(key, value)}
                </TdNew>;
              })}
            </>
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default SpecificEntitiesBody;
