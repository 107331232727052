import React from 'react';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const SupplierFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={oldValue || ''}
      newValue={newValue || ''}
    />
  );
};

export default SupplierFieldUpdateEvent;
