import React from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { deactivateAccountThunk } from '../../../../store/thunks/settings/userViewPageThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';

type InactivateUserModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  userName: string,
  userId?: number
}

const InactivateUserModal: React.FC<InactivateUserModalProps> = ({
  userName, onCancel, isVisible, userId,
}) => {
  const dispatch = useAppDispatch();
  const confirmHandler = () => {
    userId && dispatch(deactivateAccountThunk({
      id: +userId, userName, closeModal: () => onCancel(),
    }));
  };
  return (
    <ConfirmationModal
      customTitle="Inactivate account"
      isVisible={isVisible}
      onCancel={onCancel}
      confirmHandler={confirmHandler}
      customCreateLabel="Yes, inactivate"
      customText={<>
        You are about to inactivate
        {' '}
        <b>{userName}</b>
        {' '}
        user account.
      </>}
    />
  );
};

export default InactivateUserModal;
