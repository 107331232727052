import React, { useState } from 'react';
import classNames from 'classnames';
import AccountField from './AccountField';
import EditNameModal from '../modals/EditNameModal';
import './AccountForm.scss';
import EditPasswordModal from '../modals/EditPasswordModal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { UserProfileDto, UserProfilePatchRequest } from '../../../../types/AccountTypes';
import { labelHandle } from '../../../../../core/utils/labelHandle';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { accountAccountRolesSelector } from '../../../../../store/selectors/coreSelectors';
import { patchUserProfileThunk } from '../../../../../store/thunks/account/accountThunks';

type AccountFormProps = {
  userProfile: UserProfileDto | null,
  emptyOffsetTop: boolean,
}

const AccountForm:React.FC<AccountFormProps> = ({ userProfile, emptyOffsetTop }) => {
  const dispatch = useAppDispatch();

  const [visibleName, setVisibleName] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [nameType, setNameType] = useState<'first' | 'last' | undefined>(undefined);
  const [name, setName] = useState<string | undefined>(undefined);
  const roles = useAppSelector(accountAccountRolesSelector);
  const isProfileLinked = !!userProfile?.linkedEmployeeId;

  const openNameModal = (type: 'first' | 'last') => {
    if (userProfile) {
      const firstName = userProfile.firstName ? userProfile.firstName : '';
      const lastName = userProfile.lastName ? userProfile.lastName : '';
      setName(type === 'first' ? firstName : lastName);
    }
    setNameType(type);
    setVisibleName(true);
  };

  return (
    <>
      <EditNameModal
        name={nameType}
        onConfirm={(name: string, commonActions: VoidFunctionType) => {
          const data = {} as UserProfilePatchRequest;
          if (nameType === 'first') {
            data.firstName = name.trim();
          } else {
            data.lastName = name.trim();
          }
          userProfile && dispatch(patchUserProfileThunk({
            id: userProfile.id,
            nameType: nameType || '',
            data,
            onClose: commonActions,
          }));
        }}
        onCancel={() => {
          setNameType(undefined);
          setVisibleName(false);
        }}
        value={name}
        visible={visibleName}
      />
      <EditPasswordModal
        userProfileId={userProfile?.id}
        visible={visiblePassword}
        onCancel={() => setVisiblePassword(false)}
        destroyOnClose
      />
      <div className={classNames('create-form details-form', { 'create-form--no-offset-top': emptyOffsetTop })}>
        <AccountField
          label="First name"
          value={userProfile?.firstName || ''}
          onClick={() => openNameModal('first')}
          disabled={isProfileLinked}
        />
        <AccountField
          label="Last name"
          value={userProfile?.lastName || ''}
          onClick={() => openNameModal('last')}
          disabled={isProfileLinked}
        />
        <AccountField label="Email" value={userProfile?.email || ''} />
        <AccountField label="Role" value={labelHandle(userProfile?.role, roles).toString()} />
        <AccountField label="Password" value="*************" onClick={() => setVisiblePassword(true)} />
      </div>
    </>

  );
};

export default AccountForm;
