import React from 'react';
import classNames from 'classnames';
import Tr from '../../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import { PartQuantityDto, PricelistDto } from '../../../../../types/types';
import { useAppSelector } from '../../../../../../../store/hooks';
import { stockPartDetailsSelector } from '../../../../../../../store/selectors/stockSelectors';
import { labelHandle } from '../../../../../../../core/utils/labelHandle';
import { QuoteUnitsOfMeasureSelector } from '../../../../../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { toPoundCurrency } from '../../../../../../../common/utils/formatUtils';

type ChildrenData = PartQuantityDto | PricelistDto
type PartViewPricesChildRowProps = {
  data: ChildrenData
  isHidden: boolean,
}
const PartViewPricesChildRow:React.FC<PartViewPricesChildRowProps> = ({ isHidden, data }) => {
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const measure = labelHandle(partDetails?.part.unitOfMeasure, unitsOfMeasure);
  const isPricelistRow = (row: ChildrenData): row is PricelistDto => {
    return (row as PricelistDto).name !== undefined;
  };

  return (
    <Tr className={classNames('partViewPricesChildRow', { hidden: isHidden })}>
      <TdNew>
        <TruncateTooltip
          value={isPricelistRow(data) ? data.name || '-' : data.priceSource || '-'}
          table
        />
      </TdNew>
      <TdNew>{isPricelistRow(data) ? '-' : `${data.quantity} ${measure}`}</TdNew>
      <TdNew>{toPoundCurrency(data.costPrice, '-', 4)}</TdNew>
      <TdNew>{toPoundCurrency(isPricelistRow(data) ? data.sellingPrice : data.sellPrice)}</TdNew>
    </Tr>
  );
};

export default PartViewPricesChildRow;
