import { createSelector } from '@reduxjs/toolkit';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { RootState } from '../store';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { routesPath } from '../../core/enums/pathEnum';
import { pricelistFiltersSelector } from './pricelistSelectors';
import { PricelistsPageParamsEnum } from '../../pricelists/dashboard/enums/PricelistsViewEnum';
import { archivedOrderStatusSelector, purchaseOrdersFiltersSelector } from './purchaseOrderSelectors';
import {
  PurchaseOrderAllStatusEnum,
  PurchaseUrlParamsNames,
  StockTabParamEnum,
} from '../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { StockPageParamEnums } from '../../stock/container/enums/stockEnums';
import { supplierStatusesSelector, verifiedSupplierStatusSelector } from './coreStatusesSelectors';

export const suppliersFiltersSelector = (state: RootState) => state.supplier.supplierFilers;
export const suppliersFiltersListSelector = createSelector(
  suppliersFiltersSelector,
  supplierStatusesSelector,
  (
    suppliersFilters,
    supplierStatuses,
  ) => {
    return [
      {
        label: 'Status',
        name: 'statuses',
        value: suppliersFilters.statuses || [],
        options: supplierStatuses,
      },
    ];
  },
);
export const suppliersListSelector = (state: RootState) => state.supplier.supplierList;
export const suppliersLookupSelector = createSelector(
  suppliersListSelector,
  verifiedSupplierStatusSelector,
  (list, verifiedStatus) => {
    return list.items.map((el) => ({
      value: el.id,
      icon: el.supplierStatus === verifiedStatus?.value ? faClipboardCheck : faQuestionCircle,
      label: `${el.supplierAccountNumber} ${el.name}`,
    }));
  },
);
export const supplierDetailsSelector = (state: RootState) => state.supplier.supplierDetails;
export const supplierLinkedEntitiesSelector = createSelector(supplierDetailsSelector, (supplierDetails) => [
  supplierDetails?.supplier.activePurchaseOrdersCount,
  supplierDetails?.supplier.completedPurchaseOrdersCount,
  supplierDetails?.supplier.pricelistsCount,
]);
export const supplierDetailsCardProperties = createSelector(
  supplierDetailsSelector,
  purchaseOrdersFiltersSelector,
  pricelistFiltersSelector,
  archivedOrderStatusSelector,
  (details, purchaseOrdersFilters, pricelistFilters, archivedPOStatuses) => {
    const supplierDetails = details?.supplier;
    const supplierName = supplierDetails?.name || undefined;
    const completedPOStatus = archivedPOStatuses.length ? archivedPOStatuses[0].value : 6;
    return [
      {
        label: 'Active purchases',
        value: supplierDetails?.activePurchaseOrdersCount || 0,
        permission: PermissionEnum.StockPurchaseOrders,
        // eslint-disable-next-line max-len
        to: `/${routesPath.STOCK}?${PurchaseUrlParamsNames.PAGE}=${StockPageParamEnums.PURCHASES}&${PurchaseUrlParamsNames.TAB_TYPE}=${StockTabParamEnum.ACTIVE}&${PurchaseUrlParamsNames.PURCHASE_STATUS}=${PurchaseOrderAllStatusEnum.ALL}&keyword=${supplierName}`,
        filters: {
          ...purchaseOrdersFilters, status: undefined, isActive: true, keyword: supplierName,
        },
        isActivePurchaseOrder: 'true',
      },
      {
        label: 'Completed purchases',
        value: supplierDetails?.completedPurchaseOrdersCount || 0,
        permission: PermissionEnum.StockPurchaseOrders,
        // eslint-disable-next-line max-len
        to: `/${routesPath.STOCK}?${PurchaseUrlParamsNames.PAGE}=${StockPageParamEnums.PURCHASES}&${PurchaseUrlParamsNames.TAB_TYPE}=${StockTabParamEnum.ARCHIVED}&${PurchaseUrlParamsNames.PURCHASE_STATUS}=${completedPOStatus}&keyword=${supplierName}`,
        filters: { ...purchaseOrdersFilters, isActive: false, keyword: supplierName },
        isActivePurchaseOrder: 'false',
      },
      {
        label: 'Pricelists',
        value: supplierDetails?.pricelistsCount || 0,
        permission: PermissionEnum.PricelistsDashboardPage,
        to: `/${routesPath.PRICELISTS}?page=${PricelistsPageParamsEnum.DASHBOARD}&keyword=${supplierName}`,
        filters: { ...pricelistFilters, keyword: supplierName },
        isActivePurchaseOrder: null,
      },
    ];
  },
);
export const supplierAttachmentsSelector = (state: RootState) => state.supplier.supplierAttachments;
export const supplierAttachmentFiltersSelector = (state: RootState) => state.supplier.supplierAttachmentsFilters;
export const supplierContactsSelector = (state: RootState) => state.supplier.supplierContactsList;
export const supplierContactsFilterSelector = (state: RootState) => state.supplier.supplierContactsFilters;

export const supplierActivityLogFiltersSelector = (state: RootState) => state.supplier.supplierActivityLogFilters;

export const supplierTimelineSelector = (state: RootState) => state.supplier.supplierTimeline;
