import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AttachmentsTableProps } from '../../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../../store/hooks';
import EditCommentModal from '../../../../../../common/components/edit-attachment-comment/EditCommentModal';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import OrderAttachmentCell from './OrderAttachmentCell';
import { editOrderAttachment } from '../../../../../../store/thunks/orders/orderAttachmentsThunks';
import OrderAttachmentColumns from './OrderAttachmentColumns';
import AttachmentsTable from '../../../../../../common/components/attachments-table/AttachmentsTable';

const OrderAttachmentsTable:React.FC<AttachmentsTableProps> = ({
  attachments,
  checkedKeys,
  isAll,
  indeterminate,
  checkAllHandle,
  checkRow,
  disabledActions,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isEditModal, setEditModal] = useState(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState('');

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };

  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => id && attachToEdit && dispatch(editOrderAttachment({
          id: attachToEdit,
          orderId: +id,
          data: { comment: editedComment.trim() },
          onCancel: cancelHandle,
        }))}
      />
      <AttachmentsTable>
        <OrderAttachmentColumns
          isAll={isAll}
          indeterminate={indeterminate}
          checkAll={checkAllHandle}
          totalElements={attachments.length}
          disabledActions={disabledActions}
        />
        <Tbody>
          {attachments.length > 0
            ? attachments.map((attachment) => (
              <OrderAttachmentCell
                key={attachment.id}
                attach={attachment}
                openEditModal={() => {
                  setAttachToEdit(attachment.id);
                  setEditedComment(attachment.comment || '');
                  setEditModal(true);
                }}
                checked={checkedKeys.includes(attachment.id)}
                checkRow={() => checkRow(attachment.id)}
                disabledActions={disabledActions}
              />
            ))
            : <EmptyTableSection text="No records" colSpan={7} />}
        </Tbody>
      </AttachmentsTable>
    </>
  );
};

export default OrderAttachmentsTable;
