import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  rectificationReportFiltersSelector, rectificationReportRangesSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useRectificationReportRanges = () => {
  const filters = useAppSelector(rectificationReportFiltersSelector);
  const { costNet, salesNet } = useAppSelector(rectificationReportRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    costNet: { min: costNet.min, max: costNet.max },
    salesNet: { min: salesNet.min, max: salesNet.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    costNet: filters.costNet
      ? [filters.costNet.min.toString(), filters.costNet.max.toString()]
      : [rangePatterns.costNet.min.toString(), rangePatterns.costNet.max.toString()],
    salesNet: filters.salesNet
      ? [filters.salesNet.min.toString(), filters.salesNet.max.toString()]
      : [rangePatterns.salesNet.min.toString(), rangePatterns.salesNet.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, salesNet: { min: salesNet.min, max: salesNet.max } }));
    setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
  }, [salesNet.min, salesNet.max]);

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, costNet: { min: costNet.min, max: costNet.max } }));
    setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
  }, [costNet.min, costNet.max]);

  useEffect(() => {
    if (!filters?.salesNet) {
      setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
    }
    if (!filters?.costNet) {
      setRange((prevState) => ({ ...prevState, costNet: [costNet.min.toString(), costNet.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.salesNet, filters.costNet]);

  return { range, setRange, rangePatterns };
};
