import React from 'react';
import classNames from 'classnames';
import { VoidFunctionType } from '../../../../core/types/coreTypes';

type CategorySectionProps = {
  label: string,
  value: string | number,
  setIsVisible: VoidFunctionType,
  disabled?: boolean,
  error?:string,
}

const CategorySection:React.FC<CategorySectionProps> = ({
  label, value, setIsVisible, disabled, error,
}) => {
  const openModal = () => {
    if (!disabled) {
      setIsVisible();
    }
  };
  return (
    <div className="categorySection">
      <span className="categorySection__label">{label}</span>
      <div
        className={classNames('categorySection__value', { 'categorySection__value--disabled': disabled })}
        onClick={openModal}
        onKeyDown={(e) => {
          if (e.key === 'Enter') {
            openModal();
          }
        }}
        role="button"
        tabIndex={disabled ? -1 : 0}
      >
        {value}
      </div>
      {error && <span className="categorySection__error">{error}</span>}
    </div>
  );
};

export default CategorySection;
