import React, { useEffect, useMemo, useState } from 'react';
import {
  Controller, ControllerRenderProps, useForm, useFormState,
} from 'react-hook-form';
import { initDataList, initPaging, VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import RadioGroup from '../../../../core/components/radio-group/RadioGroup';
import {
  AddNominalRecordDefaults,
  AddNominalRecordFields,
} from '../../../invoicing-purchases/types/AddNominalRecordSchema';
import Select from '../../../../core/components/select/Select';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import DecimalInputNumber from '../../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../../core/utils/regex';
import './AddNominalCodeModal.scss';
import {
  isFetchingSelector,
  isGlobalLoadingSelector, nominalCodeTypesValuesSelector,
  nominalRecordTypesSelector,
  nominalRecordTypesValuesSelector, nominalRecordVatTypesSelector, purchaseOrderPartAmountInputTypesValuesSelector, vatTypesValuesSelector,
} from '../../../../store/selectors/coreSelectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getNominalCodesThunk } from '../../../../store/thunks/invoicing/invoicingNominalCodesThunks';
import {
  assignedNominalCodesSelector,
  nominalCodesDictionarySelector,
  nominalCodesFiltersSelector,
  purchasesViewOrderLinesSelector,
} from '../../../../store/selectors/invoicingSelectors';
import {
  setNominalCodes,
  setNominalCodesFilters,
} from '../../../../store/slices/invoicingSlice';
import {
  addNominalRecordThunk,
  editNominalRecordThunk,
} from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { purchaseOrderPartVatSelector } from '../../../../store/selectors/purchaseOrderSelectors';
import { getNominalRecordVatTypesThunk } from '../../../../store/thunks/coreThunk';

type AddNominalCodeModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  editMode: boolean,
  affectedNominalRecord?: number,
  affectedId?: number,
  isSales?: boolean,
}

const AddNominalCodeModal: React.FC<AddNominalCodeModalProps> = ({
  onCancel, isVisible, editMode, affectedNominalRecord, affectedId, isSales,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const lines = useAppSelector(purchasesViewOrderLinesSelector);
  const nominalCodesFilters = useAppSelector(nominalCodesFiltersSelector);
  const nominalRecordTypes = useAppSelector(nominalRecordTypesSelector);
  const purchaseOrderPartVat = useAppSelector(purchaseOrderPartVatSelector);
  const { invoice, credit } = useAppSelector(nominalRecordTypesValuesSelector);
  const { positive, negative } = useAppSelector(purchaseOrderPartAmountInputTypesValuesSelector);
  const nominalCodesDictionary = useAppSelector(nominalCodesDictionarySelector);
  const { items } = useAppSelector(assignedNominalCodesSelector);
  const { purchaseType, saleType } = useAppSelector(nominalCodeTypesValuesSelector);
  const vatTypes = useAppSelector(nominalRecordVatTypesSelector);
  const { percent } = useAppSelector(vatTypesValuesSelector);
  const [vatInputType, setVatInputType] = useState<number | null>(null);
  const {
    control, handleSubmit, watch, setValue, reset, trigger, clearErrors, setError,
  } = useForm<AddNominalRecordFields>({
    defaultValues: AddNominalRecordDefaults,
  });

  const { errors, isSubmitted } = useFormState({ control });

  const onClose = () => {
    onCancel();
    dispatch(setNominalCodesFilters({ ...initPaging }));
    dispatch(setNominalCodes(initDataList));
  };
  const afterClose = () => {
    reset();
    clearErrors();
    setVatInputType(percent || null);
    setValue('recordType', invoice);
    setValue('nominalCode', undefined);
  };

  const [grossValue, setGrossValue] = useState('0.00');

  const recordType = watch('recordType');
  const isRecordTypeEqualsCredit = recordType === credit;
  const net = watch('net');
  const vatPercent = watch('vatPercent');
  const vatAmount = watch('vatAmount');

  const isVatTypeEqualsPercent = vatInputType === percent;
  const VAT_ERROR_MESSAGE = '"VAT amount" max value should not be greater then "Net" value';

  const isRecordTypeDisabled = useMemo(() => {
    if (isSales) return false;
    const found = lines?.purchaseOrderParts.find((el) => el.id === affectedId);
    return !!(found && !found.partId && found.amountInputType);
  }, [affectedId, isSales, lines?.purchaseOrderParts]);

  const onSubmit = (values: AddNominalRecordFields) => {
    if (values.recordType && values.nominalCode) {
      const data = {
        type: values.recordType,
        nominalCodeId: values.nominalCode,
        net: isRecordTypeEqualsCredit ? +values.net * -1 : +values.net,
        vatPercent: isVatTypeEqualsPercent ? values.vatPercent : null,
        vatAmount: isVatTypeEqualsPercent ? null : isRecordTypeEqualsCredit ? +values.vatAmount * -1 : +values.vatAmount,
      };

      if (editMode && affectedNominalRecord) {
        dispatch(editNominalRecordThunk({
          id: affectedNominalRecord,
          data,
          closeModal: onClose,
          isSales,
          affectedTableLineId: affectedId,
          setError,
        }));
      } else {
        if (affectedId) {
          const belongingId = isSales
            ? { partRequestLineId: affectedId }
            : { purchaseOrderPartId: affectedId };
          dispatch(addNominalRecordThunk({
            data: {
              ...data,
              ...belongingId,
            },
            closeModal: onClose,
            setError,
          }));
        }
      }
    }
  };

  useEffect(() => {
    dispatch(getNominalRecordVatTypesThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isSubmitted) {
      trigger('vatAmount');
    }
    // eslint-disable-next-line
  }, [net]);

  useEffect(() => {
    if (isSubmitted) {
      trigger('net');
    }
    // eslint-disable-next-line
  }, [isRecordTypeEqualsCredit]);

  useEffect(() => {
    if (isSubmitted) {
      trigger('vatAmount');
    }
    // eslint-disable-next-line
  }, [vatAmount]);

  useEffect(() => {
    if (!recordType && invoice) {
      setValue('recordType', invoice);
    }
  }, [recordType, invoice, setValue]);

  useEffect(() => {
    if (isVisible && !isSales) {
      const found = lines?.purchaseOrderParts.find((el) => el.id === affectedId);
      if (found && !found.partId) {
        if (found.amountInputType === positive) setValue('recordType', invoice);
        if (found.amountInputType === negative) setValue('recordType', credit);
      }
    }
  }, [affectedId, credit, invoice, isSales, isVisible, lines?.purchaseOrderParts, negative, positive, setValue]);

  useEffect(() => {
    if (percent && !vatInputType && !editMode) {
      setVatInputType(percent);
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && (purchaseType || saleType)) {
      const filtersValues = {
        types: [(isSales ? saleType : purchaseType) as number],
        order: nominalCodesFilters.order,
      };
      dispatch(getNominalCodesThunk({
        filters: filtersValues,
      }));
    }
    // eslint-disable-next-line
  }, [isVisible, nominalCodesFilters]);

  useEffect(() => {
    const newGross = ((+net || 0) + (+vatAmount || 0)).toFixed(2);
    setGrossValue(newGross);
  }, [net, vatAmount]);

  const setClosestPercentFromAmount = (netAmountValue = net, vatAmountValue = vatAmount) => {
    if (!isVatTypeEqualsPercent && purchaseOrderPartVat.length) {
      const possibleVatValues = purchaseOrderPartVat.map(({ value }) => value);
      const diffInPercent = (+vatAmountValue / +netAmountValue) * 100;
      let closest = possibleVatValues[0];
      let minDiff = Math.abs(diffInPercent - closest);

      possibleVatValues.forEach((vatValue) => {
        const diff = Math.abs(+diffInPercent - vatValue);
        if (diff < minDiff) {
          closest = vatValue;
          minDiff = diff;
        }
      });
      setValue('vatPercent', closest);
    }
  };

  const setAmountFromPercent = (netValue = net, vatPercentValue = vatPercent) => {
    const newVatAmountValue = (+netValue * ((vatPercentValue || 0) / 100)).toFixed(2);
    setValue('vatAmount', newVatAmountValue);
  };

  const handleNetChange = (field: ControllerRenderProps<AddNominalRecordFields, 'net'>, value: string) => {
    field.onChange(value);
    setClosestPercentFromAmount(value, undefined);
    isVatTypeEqualsPercent && setAmountFromPercent(value, undefined);
  };

  const handleVatPercentChange = (field: ControllerRenderProps<AddNominalRecordFields, 'vatPercent'>, value: number) => {
    field.onChange(value);
    isVatTypeEqualsPercent && setAmountFromPercent(undefined, value);
  };

  const handleVatAmountChange = (field: ControllerRenderProps<AddNominalRecordFields, 'vatAmount'>, value: string) => {
    field.onChange(value);
    setClosestPercentFromAmount(undefined, value);
  };

  useEffect(() => {
    if (editMode && isVisible) {
      const found = items.find((el) => el.id === affectedNominalRecord);
      if (found) {
        setVatInputType(found.vatType);
        purchaseType && dispatch(setNominalCodesFilters({ ...nominalCodesFilters, types: [purchaseType] }));
        setValue('recordType', found.type);
        setValue('nominalCode', found.nominalCodeId);
        setValue('net', Math.abs(found.net).toString());
        setValue('vatPercent', found.vatPercent);
        setValue('vatAmount', Math.abs(found.vatAmount).toString());
      }
    }
    // eslint-disable-next-line
  }, [isVisible, editMode, affectedNominalRecord]);

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      title={editMode ? 'Edit nominal record' : 'Add nominal record'}
      width={700}
      afterClose={afterClose}
    >
      <form className="addNominalCodeForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="recordType"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <RadioGroup
              label="Record type"
              options={nominalRecordTypes}
              onChange={(v) => {
                field.onChange(v);
              }}
              value={field.value || ''}
              optionType="button"
              className="addNominalCodeForm__radio"
              error={errors.recordType?.message}
              disabled={isRecordTypeDisabled}
            />
          )}
        />
        <Controller
          control={control}
          name="nominalCode"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              label="Nominal code"
              options={nominalCodesDictionary}
              onChange={field.onChange}
              value={field.value}
              error={errors.nominalCode?.message}
              loading={isLoading}
              parentRender
              showSearch
              isFilterOption
              className="addNominalCodeForm__select"
            />
          )}
        />
        <RadioGroup
          optionType="button"
          options={vatTypes}
          value={vatInputType}
          onChange={(value) => {
            setVatInputType(+value);
            if (+value === percent) {
              setAmountFromPercent();
            } else {
              setClosestPercentFromAmount();
            }
          }}
          className="addNominalCodeForm__radio"
        />
        <Controller
          control={control}
          name="net"
          rules={{
            required: ErrorsEnum.REQUIRED,
            min: {
              value: 0.01,
              message: isRecordTypeEqualsCredit
                ? 'Entered value should be within -999,999.99 / - 0.01 range'
                : 'Entered value should be within 0.01 - 999,999.99 range',
            },
            max: {
              value: maxCommonDecimal,
              message: isRecordTypeEqualsCredit
                ? 'Entered value should be within -999,999.99 / - 0.01 range'
                : 'Entered value should be within 0.01 - 999,999.99 range',
            },
          }}
          render={({ field }) => (
            <DecimalInputNumber
              label="Net"
              max={maxCommonDecimal}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                handleNetChange(field, v);
              }}
              value={field.value}
              error={errors.net?.message}
              isNegativeValueAllowed={isRecordTypeEqualsCredit}
            />
          )}
        />
        <Controller
          control={control}
          name="vatPercent"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              label="VAT, %"
              options={purchaseOrderPartVat}
              onChange={(v) => handleVatPercentChange(field, +v)}
              value={field.value}
              error={errors.vatPercent?.message}
              parentRender
              disabled={!isVatTypeEqualsPercent}
            />
          )}
        />
        <Controller
          control={control}
          name="vatAmount"
          rules={{
            validate: (value) => {
              return +value <= +net || VAT_ERROR_MESSAGE;
            },
          }}
          render={({ field }) => (
            <DecimalInputNumber
              label="VAT amount"
              value={field.value}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                handleVatAmountChange(field, v);
              }}
              max={maxCommonDecimal}
              error={errors.vatAmount?.message}
              disabled={isVatTypeEqualsPercent}
              isNegativeValueAllowed={isRecordTypeEqualsCredit}
            />
          )}
        />
        <DecimalInputNumber
          label="Gross"
          max={maxCommonDecimal}
          value={grossValue}
          isNegativeValueAllowed={isRecordTypeEqualsCredit}
          disabled
        />
        <ButtonActions
          createLabel={editMode ? 'Save' : 'Add record'}
          createType="submit"
          cancelClick={onClose}
          isLoading={isFetching}
          className="addNominalCodeForm__buttons"
          offsetTop
        />
      </form>
    </Modal>
  );
};

export default AddNominalCodeModal;
