import React from 'react';
import './Tag.scss';
import { Tag as TagComponent } from 'antd';
import classnames from 'classnames';

type TagProps = {
  label: string | React.ReactNode;
  color?: string;
  light?: boolean;
  closable?: boolean;
  onClose?: () => void;
  closeIcon?: React.ReactNode;
  icon?: React.ReactNode;
  visible?: boolean;
  className?: string;
  textSize?: 'md' | 'lg';
  truncate?: boolean,
  rowReverse?: boolean,
  crossed?: boolean,
}

const Tag: React.FC<TagProps> = ({
  label,
  color,
  light,
  closable,
  closeIcon,
  icon,
  visible = true,
  onClose,
  className,
  textSize,
  truncate,
  rowReverse,
  crossed,
}) => {
  return (
    <TagComponent
      color={color}
      closable={closable}
      closeIcon={closeIcon}
      icon={icon}
      visible={visible}
      onClose={onClose}
      className={classnames(
        className,
        'tag',
        {
          'tag--light': light,
          'tag--text-md': textSize === 'md',
          'tag--truncate': truncate,
          'tag--reversed': rowReverse,
          'tag--crossed': crossed,
        },
      )}
    >
      {label}
    </TagComponent>
  );
};

export default Tag;
