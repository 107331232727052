import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { soldProductsByCustomerFiltersSelector } from '../../store/selectors/reportsSelectors';
import { soldProductsByCustomerOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getSoldProductsByCustomerReportOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { SoldProductsByCustomerReportOrdering } from '../enum/ReportTableOrders';
import { setSoldProductsByCustomerFilters } from '../../store/slices/reportsSlice';
import { initPaging } from '../../core/types/coreTypes';

export const useSoldProductsByCustomerReportDefaultOrdering = (shouldFetch = true) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(soldProductsByCustomerFiltersSelector);
  const ordering = useAppSelector(soldProductsByCustomerOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    if (!ordering.length && shouldFetch) {
      dispatch(getSoldProductsByCustomerReportOrderingThunk());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === SoldProductsByCustomerReportOrdering.Quantity)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setSoldProductsByCustomerFilters({ ...filters, order: { field: defaultOrdering, isAsc: false } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: false } : filters.order,
  };
};
