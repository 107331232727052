import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import CreateAttachmentModal from '../../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  purchaseOrderAttachmentsFiltersSelector,
  purchaseOrderAttachmentsSelector, purchaseOrderDetailsSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import PurchaseOrderAttachmentFilters from './filters/PurchaseOrderAttachmentFilters';
import PurchaseOrdersAttachmentsTable from './table/PurchaseOrdersAttachmentsTable';
import {
  createPurchaseOrderAttachmentThunk, deleteMultiplePurchaseOrderAttachmentThunk,
  getPurchaseOrderAttachments,
} from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderAttachmentsThunks';
import { guidGenerator } from '../../../../../../core/utils/guidGenerator';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';
import { purchaseOrderStatusObjSelector } from '../../../../../../store/selectors/coreStatusesSelectors';

const PurchaseOrderAttachments: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { completed } = useAppSelector(purchaseOrderStatusObjSelector);
  const purchaseOrderDetails = useAppSelector(purchaseOrderDetailsSelector);
  const { items, allItemIds, totalCount } = useAppSelector(purchaseOrderAttachmentsSelector);
  const filters = useAppSelector(purchaseOrderAttachmentsFiltersSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');

  const isPurchaseCompleted = useMemo(() => purchaseOrderDetails?.purchaseOrder.status === completed, [purchaseOrderDetails, completed]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    id && dispatch(getPurchaseOrderAttachments({ purchaseOrderId: +id, filters }));
    // eslint-disable-next-line
  }, [id, filters]);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  const uploadHandle = async () => {
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createPurchaseOrderAttachmentThunk({
      id: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultiplePurchaseOrderAttachmentThunk({
      purchaseOrderId: +id,
      ids: checkedKeys,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <PurchaseOrderAttachmentFilters
        filters={filters}
        totalItems={totalCount}
        setCreateModal={setCreateModal}
        setDeleteModal={() => setDeleteModal(true)}
        checkedKeys={checkedKeys}
        disabledActions={isPurchaseCompleted}
      />
      <PurchaseOrdersAttachmentsTable
        attachments={items}
        checkedKeys={checkedKeys}
        isAll={isAll}
        indeterminate={isIndeterminate}
        checkAllHandle={checkAllHandle}
        checkRow={checkRowHandle}
        disabledActions={isPurchaseCompleted}
      />
    </>
  );
};

export default PurchaseOrderAttachments;
