import React from 'react';
import classNames from 'classnames';
import { CustomAny, DictionaryItem } from '../../../../../core/types/coreTypes';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import { JobPartsTableLine } from '../../../types/jobViewTypes';

type JobPartsLineProps = {
  hiddenKeys: string[],
  nowrapKeys: string[],
  renderCellValue: (key: string, value: CustomAny, unitOfMeasure: number) => React.ReactNode,
  line: JobPartsTableLine,
  deletedStatus?: DictionaryItem,
}
const JobPartsLine: React.FC<JobPartsLineProps> = ({
  hiddenKeys, nowrapKeys, renderCellValue, line, deletedStatus,
}) => {
  return (
    <Tr
      className={classNames({ 'suitableLine': line.status.toLowerCase() === deletedStatus?.label?.toLowerCase() })}
    >
      {Object.entries(line).map(([key, value]) => {
        if (hiddenKeys.includes(key)) return null;
        return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
          {renderCellValue(key, value, line.unitOfMeasure)}
        </TdNew>;
      })}
    </Tr>
  );
};

export default JobPartsLine;
