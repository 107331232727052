import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  soldProductsByCustomerColumnsSelector,
  soldProductsByCustomerDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import SoldProductsByCustomerColumns from './SoldProductsByCustomerColumns';
import SoldProductsByCustomerBody from './SoldProductsByCustomerBody';
import './SoldProductsByCustomerTable.scss';

const SoldProductsByCustomerTable: React.FC = () => {
  const { totalCount } = useAppSelector(soldProductsByCustomerDataSelector);
  const columns = useAppSelector(soldProductsByCustomerColumnsSelector);

  return (
    <TableNew>
      <SoldProductsByCustomerColumns columns={columns} totalCount={totalCount} />
      <SoldProductsByCustomerBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default SoldProductsByCustomerTable;
