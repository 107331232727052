import React, { useEffect } from 'react';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import Layout from '../../core/components/layout/Layout';
import CreateRectificationForm from './components/CreateRectificationForm';
import { orderDictionaryFiltersSelector } from '../../store/selectors/sharedSelectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getOrderDictionary, getRectificationFaultTypesThunk, getRectificationTypesThunk } from '../../store/thunks/coreThunk';
import { serOrderDetails } from '../../store/slices/sharedSlice';
import { setCustomerDetails } from '../../store/slices/customersSlice';

const CreateRectificationContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState();
  const orderDictionaryFilters = useAppSelector(orderDictionaryFiltersSelector);

  useEffect(() => {
    dispatch(getOrderDictionary({
      filters: {
        ...orderDictionaryFilters,
        onlyInStatusAvailableForJobCreation: false,
        notInSupplyOnlyJobType: true,
      },
    }));
    // eslint-disable-next-line
  }, [orderDictionaryFilters]);

  useEffect(() => {
    dispatch(getRectificationTypesThunk());
    dispatch(getRectificationFaultTypesThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      dispatch(serOrderDetails(null));
      dispatch(setCustomerDetails(null));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout subHeader={
      <DetailsHead goBack={() => backHandle()} isCreatePage>
        <div className="createHead">Create new rectification</div>
      </DetailsHead>
    }
    >
      <CreateRectificationForm />
    </Layout>
  );
};

export default CreateRectificationContainer;
