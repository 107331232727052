import React, { useMemo, useState } from 'react';
import { StringFunctionType } from '../../../../../../../core/types/coreTypes';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal, maxCommonWhole } from '../../../../../../../core/utils/regex';
import Input from '../../../../../../../core/components/input/Input';
import Select from '../../../../../../../core/components/select/Select';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import {
  integerQuantityMeasureValuesSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import { useAppSelector } from '../../../../../../../store/hooks';
import { purchaseOrderPartVatSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { partPricesSelector } from '../../../../../../../store/selectors/pricelistSelectors';

type PartPricelistMeasuresProps = {
  quantityVal: string,
  setQuantityVal: StringFunctionType,
  vatVal: number | undefined,
  setVatVal: (value?: number) => void,
  selectedPricelistId?: number,
  selectedPartUnitOfMeasure?: number,
  minItemToChangeQty: number,
  itemToChange?: number,
}

const PartPricelistMeasures: React.FC<PartPricelistMeasuresProps> = ({
  vatVal, setVatVal, setQuantityVal, quantityVal, itemToChange,
  selectedPricelistId, selectedPartUnitOfMeasure, minItemToChangeQty,
}) => {
  const [qtyError, setQtyError] = useState('');
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const purchaseOrderPartVat = useAppSelector(purchaseOrderPartVatSelector);
  const partSelectPricelistResultTable = useAppSelector(partPricesSelector);
  const selectedPricelistCost = partSelectPricelistResultTable.items.find((el) => el.pricelistId === selectedPricelistId)?.costPrice;

  const netValue = useMemo(() => (+quantityVal || 0.00) * (selectedPricelistCost || 0.00), [
    quantityVal, selectedPricelistCost,
  ]);
  const grossValue = useMemo(() => netValue + (netValue * (vatVal === undefined ? 0.2 : (vatVal / 100))), [netValue, vatVal]);
  const minQtyMessage = `The min allowed value is ${minItemToChangeQty}`;
  return (
    <section className="partPricelistWrap__measures">
      {integerMeasures.includes(selectedPartUnitOfMeasure)
        ? <RegularInputNumber
          value={quantityVal}
          onChange={(e) => {
            const v = e.target.value;
            if (itemToChange && (+v < minItemToChangeQty)) {
              setQtyError(minQtyMessage);
            } else {
              qtyError && setQtyError('');
            }
            setQuantityVal(e.target.value);
          }}
          max={maxCommonWhole}
          label="Quantity"
          postfix={unitsOfMeasure.find((el) => el.value === selectedPartUnitOfMeasure)?.label || ''}
          disabled={!selectedPricelistId}
          error={qtyError}
        />
        : <DecimalInputNumber
          value={quantityVal}
          onChange={(e) => {
            const v = e.target.value.replace(',', '.');
            if (itemToChange && (+v < minItemToChangeQty)) {
              setQtyError(minQtyMessage);
            } else {
              qtyError && setQtyError('');
            }
            setQuantityVal(v);
          }}
          max={maxCommonDecimal}
          label="Quantity"
          postfix={unitsOfMeasure.find((el) => el.value === selectedPartUnitOfMeasure)?.label || ''}
          disabled={!selectedPricelistId}
          error={qtyError}
        />}
      <Select
        value={vatVal}
        onChange={(v) => {
          setVatVal(v as number);
        }}
        label="VAT"
        disabled={!selectedPricelistId}
        options={purchaseOrderPartVat}
        parentRender
      />
      <Input
        label="Net"
        prefix="£"
        disabled
        value={netValue.toFixed(2).toString()}
      />
      <Input
        label="Gross"
        prefix="£"
        disabled
        value={grossValue.toFixed(2).toString()}
      />
    </section>
  );
};

export default PartPricelistMeasures;
