import React from 'react';
import classNames from 'classnames';
import './CommonPartRequest.scss';

type ArrayOfLabeledValues = Array<{ label: string, value?: string | number | null }>;

type PartRequestSummarySectionProps = {
  leftPartData: ArrayOfLabeledValues,
  rightPartData: ArrayOfLabeledValues,
}

const PartRequestSummarySection: React.FC<PartRequestSummarySectionProps> = ({ leftPartData, rightPartData }) => {
  return (
    <div className="summaryOrderReq">
      <div className="summaryOrderReq-title">
        Summary
      </div>
      <div className="summaryOrderReq-linesWrap">
        <div className="reqVehSum">
          {leftPartData.map((d, i) => <div
            key={i}
            className="reqSumLine"
          >
            <span className="reqSumLabel">{d.label}</span>
            <span className="reqSumValue">
              {(!d.value && d.value !== 0) ? '-' : d.value}
              {(!d.value && d.value !== 0) ? '' : ' kg'}
            </span>
          </div>)}
        </div>
        <div className="reqVehSum">
          {rightPartData.map((d, i) => <div
            key={i}
            className={classNames('reqSumLine', { 'reqSumLine--bold': d.label === 'Gross' })}
          >
            <span>{d.label}</span>
            <span className="reqSumValue">
              {!d.value ? '' : '£ '}
              {!d.value ? '-' : d.value}
            </span>
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default PartRequestSummarySection;
