import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  fullVehicleCheckDecisionsSelector,
  handoverVehicleCheckDecisionsSelector,
  vehicleCheckTypesSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { vehicleChecksOrderingSelector } from '../../../../../../store/selectors/coreOrderingSelectors';
import {
  getFullVehicleCheckDecisionsThunk,
  getHandoverVehicleCheckDecisionsThunk,
  getVehicleCheckTypesThunk,
} from '../../../../../../store/thunks/coreThunk';
import { getVehicleChecksOrderingThunk } from '../../../../../../store/thunks/core/coreOrderingThunks';
import { VehicleChecksOrderingEnum } from '../enums/VehicleInspectionEnum';
import { setVehicleChecksFilters } from '../../../../../../store/slices/vehiclesSlice';
import { getVehicleChecksThunk } from '../../../../../../store/thunks/vehicles/VehicleInspectionThunks';
import { vehicleChecksFiltersSelector, vehicleChecksSelector } from '../../../../../../store/selectors/vehiclesSelectors';

export const useLoadInspectionData = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const checkTypes = useAppSelector(vehicleCheckTypesSelector);
  const checksOrdering = useAppSelector(vehicleChecksOrderingSelector);
  const fullCheckDecisions = useAppSelector(fullVehicleCheckDecisionsSelector);
  const handoverCheckDecisions = useAppSelector(handoverVehicleCheckDecisionsSelector);

  const filters = useAppSelector(vehicleChecksFiltersSelector);
  const { items, totalCount } = useAppSelector(vehicleChecksSelector);

  useEffect(() => {
    if (!checkTypes.length) {
      dispatch(getVehicleCheckTypesThunk());
    }
    if (!checksOrdering.length) {
      dispatch(getVehicleChecksOrderingThunk());
    }
    if (!fullCheckDecisions.length) {
      dispatch(getFullVehicleCheckDecisionsThunk());
    }
    if (!handoverCheckDecisions.length) {
      dispatch(getHandoverVehicleCheckDecisionsThunk());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (checksOrdering.length && !filters.order?.field) {
      const defaultOrdering = checksOrdering.find((el) => el.label === VehicleChecksOrderingEnum.CheckDate)?.value;
      if (defaultOrdering) {
        dispatch(setVehicleChecksFilters({ ...filters, order: { field: defaultOrdering, isAsc: false } }));
      }
    }
    // eslint-disable-next-line
  }, [checksOrdering.length]);

  useEffect(() => {
    if (filters.order?.field && id) {
      dispatch(getVehicleChecksThunk({ id: +id, filters }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const handlePaginationChange = (page: number, pageSize: number) => {
    dispatch(setVehicleChecksFilters({ ...filters, page, pageSize }));
  };

  return {
    id,
    dispatch,
    filters,
    items,
    totalCount,
    handlePaginationChange,
  };
};
