import React, { PropsWithChildren } from 'react';
import { useSearchParams } from 'react-router-dom';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { PartRequestsUrlParamsNames, StockPartRequestViewEnum } from '../enums/stockPartRequestsEnum';
import { PurchaseUrlParamsNames } from '../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';

const ViewOptions: React.FC<PropsWithChildren> = ({ children }) => {
  const { windowWidth } = useScreenWitdh();
  const [params, setSearchParams] = useSearchParams();
  const currentViewParam = params.get('view');
  const pageParam = params.get(PurchaseUrlParamsNames.PAGE);
  const tabType = params.get(PurchaseUrlParamsNames.TAB_TYPE);
  const partReqStatus = params.get(PartRequestsUrlParamsNames.PART_REQ_STATUS);
  const setCurrentViewParam = (value: string) => {
    setSearchParams({
      page: pageParam as string,
      [PurchaseUrlParamsNames.TAB_TYPE]: tabType as string,
      [PartRequestsUrlParamsNames.PART_REQ_STATUS]: partReqStatus as string,
      view: value,
    });
  };

  const options = [
    { label: 'View by part request', value: 'requests' },
    { label: 'View by request line', value: 'lines' },
  ];
  return (
    <div className="sections-and-filters sections-and-filters--offset">
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
        ? <Selectable
          options={options}
          value={currentViewParam || ''}
          onChange={(v) => setCurrentViewParam(v as StockPartRequestViewEnum)}
        />
        : <Segmented
          options={options}
          value={currentViewParam || ''}
          onChange={(v) => setCurrentViewParam(v as StockPartRequestViewEnum)}
        />}
      {children}
    </div>
  );
};

export default ViewOptions;
