import { RootState } from '../store';

export const enquiryEventTypesSelector = (state: RootState) => state.coreEventTypes.enquiryEventTypes;

export const rectificationEventTypesSelector = (state: RootState) => state.coreEventTypes.rectificationEventTypes;

export const orderEventTypesSelector = (state: RootState) => state.coreEventTypes.orderEventTypes;

export const partsKitEventTypesSelector = (state: RootState) => state.coreEventTypes.partsKitEventTypes;

export const supplierEventTypesSelector = (state: RootState) => state.coreEventTypes.supplierEventTypes;

export const pricelistEventTypesSelector = (state: RootState) => state.coreEventTypes.pricelistEventTypes;

export const jobEventTypesSelector = (state: RootState) => state.coreEventTypes.jobEventTypes;

export const employeeEventTypesSelector = (state: RootState) => state.coreEventTypes.employeeEventTypes;

export const partViewEventTypesSelector = (state: RootState) => state.coreEventTypes.partViewEventTypes;

export const purchaseOrderEventTypesSelector = (state: RootState) => state.coreEventTypes.purchaseOrderEventTypes;

export const customerEventTypesSelector = (state: RootState) => state.coreEventTypes.customerEventTypes;

export const partRequestEventTypesSelector = (state: RootState) => state.coreEventTypes.partRequestEventTypes;

export const vehicleEventTypesSelector = (state: RootState) => state.coreEventTypes.vehicleEventTypes;

export const userEntityEventTypesSelector = (state: RootState) => state.coreEventTypes.userEntityEventTypes;

// eslint-disable-next-line max-len
export const soldProductsByCustomerReportEntityTypesSelector = (state: RootState) => state.coreEventTypes.soldProductsByCustomerReportEntityTypes;
