import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faEllipsisVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import Tbody from '../../../core/components/table-components/Tbody';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { vehicleProfilesTableViewSelector } from '../../../store/selectors/vehiclesSelectors';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';

type VehiclesProfilesBodyProps = {
  openEditModal: NumberFunctionType,
  openDeleteModal: NumberFunctionType,
}

const VehiclesProfilesBody: React.FC<VehiclesProfilesBodyProps> = ({ openEditModal, openDeleteModal }) => {
  const truncateValues = ['modelDescription', 'type', 'group'];
  const { items, totalCount } = useAppSelector(vehicleProfilesTableViewSelector);

  return (
    <Tbody>
      {totalCount === 0
        ? <EmptyTableSection
          colSpan={6}
          text="No records"
        />
        : <>
          {items.map((cell, i) => <Tr key={i}>
            {Object.entries(cell).map(([key, value]) => {
              if (key === 'id') {
                return null;
              } else {
                return <TdNew key={key} className={truncateValues.includes(key) ? '' : 'nowrap'}>
                  {truncateValues.includes(key)
                    ? <TruncateTooltip
                      value={value as string}
                      table
                    />
                    : value}
                </TdNew>;
              }
            })}
            <TdNew action>
              <Dropdown
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                menuItems={[
                  {
                    label: 'Edit vehicle profile',
                    icon: <FontAwesomeIcon icon={faEdit} />,
                    key: 'editVehProfile',
                    onClick: () => openEditModal(cell.id),
                  },
                  {
                    label: 'Delete vehicle profile',
                    icon: <FontAwesomeIcon icon={faTrashAlt} />,
                    key: 'Delete vehicle profile',
                    onClick: () => openDeleteModal(cell.id),
                  },
                ]}
                disabled={false}
              />
            </TdNew>
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default VehiclesProfilesBody;
