import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ACCOUNT_NO_REGEX } from '../../../../core/utils/regex';

export interface CreatePartsKitFields {
  name: string,
  code: string,
  categoryId?: number,
  businessAreas: number[],
}

export const PartKitDefaults: CreatePartsKitFields = {
  name: '',
  code: '',
  categoryId: undefined,
  businessAreas: [],
};

export const PartsKitNameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 250, message: maxLengthMessageHandle(250) },
};

export const PartsKitCodeSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 6, message: rangeLengthMessageHandle([4, 6]) },
  minLength: { value: 4, message: rangeLengthMessageHandle([4, 6]) },
  pattern: { value: ACCOUNT_NO_REGEX, message: ErrorsEnum.ONLY_NUMBERS_LETTERS },
};

type PartsKitPick = Pick<CreatePartsKitFields, 'name' | 'businessAreas'>

export type CreatePartsKitReqType = PartsKitPick & {
  partsKitCode: string,
  categoryId: number,
  suitableVehiclesSource: number | null,
  suitableForVehicleIds: number[] | null,
  suitableForVehicleGroupIds: number[] | null,
}
