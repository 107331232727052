import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../core/components/button/Button';
import Modal from '../../../core/components/modal/Modal';
import {
  BooleanFunctionType, VehicleModalEntityType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import './VehicleModal.scss';
import VehicleBlankSection from './VehicleBlankSection';
import ExistingVehicleSpecification from './ExistingVehicleSpecification';
import { AddVehicleRequest, VehicleProfilesUiList } from '../../types/commonTypes';
import { VehicleProfilesFilters } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

type VehicleModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  title: string,
  width?: number,
  editMode: boolean,
  currentVeh?: VehicleModalEntityType;
  createVehThunkHandle?: (data: AddVehicleRequest, resetHandle: VoidFunctionType) => void,
  editVehThunkHandle: (data: AddVehicleRequest, setDefaultHandle: VoidFunctionType) => void,
  nonDraftLinesLength?: number,
  isEditConfirmation?: boolean,
  setIsEditConfirmation?: BooleanFunctionType,
  catalogVehicleProfilesFilters: VehicleProfilesFilters,
  setCatalogVehicleProfilesFilters: (v: VehicleProfilesFilters) => void,
  vehicleProfiles: VehicleProfilesUiList,
  lineType: string,
  disabledToEdit?: boolean,
  disabledToEditRegNoAndChassisNo?: boolean,
}

const VehicleModal: React.FC<VehicleModalProps> = ({
  isVisible, onCancel, title, width, editMode, currentVeh,
  createVehThunkHandle, editVehThunkHandle, nonDraftLinesLength,
  isEditConfirmation, setIsEditConfirmation, catalogVehicleProfilesFilters,
  setCatalogVehicleProfilesFilters, vehicleProfiles, lineType, disabledToEdit, disabledToEditRegNoAndChassisNo,
}) => {
  const [vehicleSelectMode, setVehicleSelectMode] = useState<'blank' | 'existing' | undefined>(undefined);
  const modalTitle = vehicleSelectMode
    ? vehicleSelectMode === 'blank'
      ? title
      : 'Select vehicle specification'
    : undefined;

  useEffect(() => {
    if (editMode) {
      setVehicleSelectMode('blank');
    }
  }, [editMode]);

  const cancelModalHandle = () => {
    setVehicleSelectMode(undefined);
    onCancel();
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelModalHandle}
      width={width}
      destroyOnClose
      title={modalTitle}
      className="vehicleSourcesModal"
    >
      {!vehicleSelectMode && <div className="vehicleSourcesModal__choice">
        <Button
          label="Fill a blank form"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          className="vehicleSourcesModal__choice-item"
          onClick={() => setVehicleSelectMode('blank')}
          htmlType="button"
          reversed
        />
        <Button
          label="Select existing vehicle specification"
          className="vehicleSourcesModal__choice-item"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          onClick={() => setVehicleSelectMode('existing')}
          htmlType="button"
          reversed
          disabled
        />
      </div>}
      {vehicleSelectMode === 'blank' && <VehicleBlankSection
        setVehicleSelectMode={setVehicleSelectMode}
        editMode={editMode}
        currentVeh={currentVeh}
        createVehThunkHandle={createVehThunkHandle}
        editVehThunkHandle={editVehThunkHandle}
        nonDraftLinesLength={nonDraftLinesLength}
        isEditConfirmation={isEditConfirmation}
        setIsEditConfirmation={setIsEditConfirmation}
        catalogVehicleProfilesFilters={catalogVehicleProfilesFilters}
        setCatalogVehicleProfilesFilters={setCatalogVehicleProfilesFilters}
        vehicleProfiles={vehicleProfiles}
        lineType={lineType}
        cancelModalHandle={cancelModalHandle}
        disabledToEdit={disabledToEdit}
        disabledToEditRegNoAndChassisNo={disabledToEditRegNoAndChassisNo}
      />}
      {vehicleSelectMode === 'existing' && <ExistingVehicleSpecification
        setVehicleSelectMode={setVehicleSelectMode}
      />}
    </Modal>
  );
};

export default VehicleModal;
