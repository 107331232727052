import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faList, faPoundSign, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { ICustomColumn, PartReqLineUIType, PartRequestLineDto } from '../../types/commonTypes';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { currentUserPermissionsSelector } from '../../../store/selectors/accountSelectors';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import Tr from '../../../core/components/table-components/Tr';
import PartReqCell from './PartReqCell';
import usePermission from '../../../permissions-handling/permissionHook';
import { partRequestLineValueRenderer } from '../../utils/partRequestLineValueRenderer';
import {
  partReqLineActionsStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';

type PartRequestLineProps = {
  line: PartReqLineUIType,
  checkedKeys: number[],
  checkRows: NumberFunctionType,
  columns: ICustomColumn[],
  editQty: (id: number, isChildLine?: boolean) => void,
  openCustomModal: NumberFunctionType,
  openDeleteModal: NumberFunctionType,
  openMarkAsFree: NumberFunctionType,
  isLineActionDisabled: (v: number) => boolean,
  isPartsKitLineHasAllocatedChild: (v: number) => boolean,
  initLinesList: PartRequestLineDto[],
  permissionToManageLines: PermissionEnum,
  permissionToManageCharge: PermissionEnum,
  editInlineThunkHandle: (
    data: {[p: string]: string | number} | {notes: string},
    lineId: number,
    setFieldToEdit: VoidFunctionType,
  ) => void,
  openReadyCollectedListModal?: NumberFunctionType,
}

const PartRequestLine: React.FC<PartRequestLineProps> = ({
  line,
  columns,
  checkedKeys,
  checkRows,
  openMarkAsFree,
  openCustomModal,
  openDeleteModal,
  editQty,
  isLineActionDisabled,
  initLinesList,
  permissionToManageLines,
  permissionToManageCharge,
  editInlineThunkHandle,
  isPartsKitLineHasAllocatedChild,
  openReadyCollectedListModal,
}) => {
  const currentUserPermissions = useAppSelector(currentUserPermissionsSelector);
  const allowedToManageLines = usePermission(permissionToManageLines);
  const allowedToManageCharge = usePermission(permissionToManageCharge);
  const anyAllowed = allowedToManageLines || allowedToManageCharge;
  const {
    partiallyReady, ready, partiallyCollected, collected, archived,
  } = useAppSelector(partReqLineActionsStatusesSelector);
  const readyCollectedActionStatuses = [
    partiallyReady?.value, ready?.value,
    partiallyCollected?.value, collected?.value, archived?.value,
  ];

  const markFree = (key: number) => {
    openMarkAsFree(key);
  };

  const deleteLine = (key: number) => {
    openDeleteModal(key);
  };

  const allowedItems = (id: number) => {
    const currentLine = initLinesList?.find((el) => el.id === id);
    const currentLineStatus = currentLine?.status;
    const isReadyCollectedActionAllowed = readyCollectedActionStatuses.includes(currentLineStatus);
    const freeCondition = currentLine?.isFree;
    const markName = !freeCondition ? 'Mark as Free of charge' : 'Mark as Billed';
    const disabledAction = isLineActionDisabled(id);
    const baseActions = [
      {
        label: markName,
        key: 'mark-as-free',
        icon: <FontAwesomeIcon icon={faPoundSign} />,
        onClick: () => markFree(id),
        restrictedTo: permissionToManageCharge,
        disabled: disabledAction || !allowedToManageCharge,
      },
      {
        label: 'Delete line',
        key: 'delete-line',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => deleteLine(id),
        restrictedTo: permissionToManageLines,
        disabled: disabledAction,
      },
    ];
    const extendedActions = [
      {
        label: markName,
        key: 'mark-as-free',
        icon: <FontAwesomeIcon icon={faPoundSign} />,
        onClick: () => markFree(id),
        restrictedTo: permissionToManageCharge,
        disabled: disabledAction || !allowedToManageCharge,
      },
      {
        label: 'Ready/collected part list',
        key: 'ready-collected-parts-list',
        icon: <FontAwesomeIcon icon={faList} />,
        onClick: () => openReadyCollectedListModal && openReadyCollectedListModal(id),
        restrictedTo: permissionToManageLines,
        disabled: false,
      },
      {
        label: 'Delete line',
        key: 'delete-line',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => deleteLine(id),
        restrictedTo: permissionToManageLines,
        disabled: disabledAction,
      },
    ];

    const actions = isReadyCollectedActionAllowed
      ? extendedActions
      : baseActions;
    return actions.filter((action) => currentUserPermissions?.find((el) => el === action.restrictedTo));
  };

  // eslint-disable-next-line
  const { childLines, ...rest } = line;
  return (
    <Tr
      className={classNames({ 'suitableLine': line.isProductSuitable !== null && !line.isProductSuitable })}
    >
      {allowedToManageLines && (
        <TdNew>
          <Checkbox
            checked={checkedKeys?.includes(line.id)}
            onChange={() => checkRows(line.id)}
            table
            disabled={isLineActionDisabled(line.id)}
          />
        </TdNew>
      )}
      <PartReqCell
        data={rest}
        valueRenderer={(key: string, value: string | number) => partRequestLineValueRenderer(key, value, columns)}
        disabled={!allowedToManageLines || isLineActionDisabled(line.id)}
        isPartsKitLineHasAllocatedChild={isPartsKitLineHasAllocatedChild(line.id)}
        editQty={editQty}
        openCustomModal={openCustomModal}
        allowedToManage={allowedToManageLines}
        initLinesList={initLinesList}
        editInlineThunkHandle={editInlineThunkHandle}
      />
      {anyAllowed && (
        <TdNew action>
          <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={allowedItems(line.id)}
          />
        </TdNew>
      )}
    </Tr>
  );
};

export default PartRequestLine;
