import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../core/utils/errorMessageHandle';

export type CustomPartModeFields = {
  productName: string,
  quantity: string,
  unitOfMeasure?: number,
  unitPrice: string,
  tax?: number,
  quoteCategoryId?: number,
  weight: string,
};

export const CustomPartModeDefaults: CustomPartModeFields = {
  productName: '',
  quantity: '',
  unitOfMeasure: undefined,
  unitPrice: '',
  tax: undefined,
  quoteCategoryId: undefined,
  weight: '',
};

export const ProductNameFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 200, message: maxLengthMessageHandle(200) },
};

export const QuantityFieldValidationSchema = (isIntegerQtyMeasure: boolean) => ({
  required: ErrorsEnum.REQUIRED,
  min: {
    value: isIntegerQtyMeasure ? 0 : 0.00,
    message: isIntegerQtyMeasure ? 'The min allowed value is 0' : 'The min allowed value is 0.00',
  },
});
