import { useState } from 'react';
import { PartsKitsFiltersType } from '../../parts-kits/dashboard/types/partsKitsTypes';
import { setPartsKitsFilters } from '../../../store/slices/partsKitSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { partsKitFiltersListSelector } from '../../../store/selectors/partsKitSelectors';
import { initPaging } from '../../../core/types/coreTypes';

export const useStockPartsKitFilters = () => {
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);

  const filters = useAppSelector(partsKitFiltersListSelector);
  const dispatch = useAppDispatch();

  const initDropdownSearch = {
    categories: '',
    businessAreas: '',
    statuses: '',
  };
  const getFilteredPartsKit = (filters: PartsKitsFiltersType) => {
    dispatch(setPartsKitsFilters(filters));
  };
  const onClear = () => {
    setSearchValue('');
    getFilteredPartsKit(initPaging);
  };

  return {
    filters,
    setSearchValue,
    searchValue,
    initDropdownSearch,
    onClear,
    getFilteredPartsKit,
  };
};
