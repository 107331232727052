import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { rectificationDetailsHeadSelector } from '../../../../store/selectors/rectificationsSelector';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../core/components/tag/Tag';

const RectificationViewHead: React.FC = () => {
  const rectificationHead = useAppSelector(rectificationDetailsHeadSelector);

  return (
    <>
      {rectificationHead.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Customer type'
          ? <Tag label={ent.value?.toString() || '-'} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default RectificationViewHead;
