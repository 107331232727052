import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { addPartFiltersListSelector } from '../../../store/selectors/pricelistSelectors';
import { setStockPartsFilters } from '../../../store/slices/pricelistSlice';
import { initSmallPaging } from '../../../core/types/coreTypes';
import { StockFiltersType } from '../../../stock/search/types/stockSearchTypes';

export const useAddPartFilters = () => {
  const dispatch = useAppDispatch();
  const filtersList = useAppSelector(addPartFiltersListSelector);

  const initDropdownSearch = {
    vehicle: '',
    category: '',
    subcategory: '',
  };

  const getFilteredResult = (filters: StockFiltersType) => {
    dispatch(setStockPartsFilters({ ...filters, page: 1 }));
  };

  const onClear = () => {
    getFilteredResult(initSmallPaging);
  };

  return {
    initDropdownSearch,
    filtersList,
    getFilteredResult,
    onClear,
  };
};
