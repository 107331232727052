import {
  DataListType,
  initDataList,
  initPaging,
  initSmallPaging,
  LinkedVehicleDto,
  OrderingType,
  PagingType, PagingWithOrdering, ServerErrorType, StringRangeType,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { StatusCountDto } from '../../../customers/customer-view-page/types/customerVewPageTypes';

export type StockPartRequestDto = {
  id: number,
  partRequestNumber: string,
  businessArea: number,
  customerId: number,
  orderDate: string,
  creationDate: string | null,
  modifiedDate: string | null,
  dueDate: string | null,
  linkedEntityType: number,
  linkedEntityNumber: string | null,
  linkedEntityStatus: number,
  linkedEntityOwnerFirstName: string | null,
  linkedEntityOwnerLastName: string | null,
  priorityLevel: number,
  jobType: number,
  numberOfModifications: number,
  status: number,
  itemsCount: number,
  customerName: string | null,
  enableNotes: boolean,
} & Omit<LinkedVehicleDto, 'vehicleId' | 'vehicleStatus'>;

export type StockPartRequestUiType = {
  id: number,
  status?: string,
  partRequestNumber: string,
  itemsCount: number,
  linkedEntityType: number,
  linkedEntityNumber: string | null,
  linkedEntityStatus?: string,
  linkedEntityOwnerFirstName: string | null,
  linkedEntityOwnerLastName: string | null,
  priorityLevel: number,
  jobType: number,
  dueDate: string | null,
  creationDate: string | null,
  customerName: string | null,
  numberOfModifications: number,
}

export type StockPartRequestDashboardDataType = DataListType<StockPartRequestDto[]> & {
  statusCounts: StatusCountDto[] | null,
  minDueDate: string | null,
  maxDueDate: string | null,
}

export type GetPartRequestDashboardResponse = {
  data: {
    items: StockPartRequestDto[],
    pages: number,
    totalCount: number,
    statusCounts: StatusCountDto[] | null,
    minDueDate: string | null,
    maxDueDate: string | null,
  },
  success: boolean,
  errors: Array<ServerErrorType>,
  status: string
}

export const StockPartRequestDashboardInit = {
  ...initDataList,
  statusCounts: null,
  minDueDate: null,
  maxDueDate: null,
};

export type StockPartRequestFiltersType = {
  isActive: boolean,
  keyword?: string,
  status?: number[],
  dueDate?: StringRangeType,
} & PagingType

export const initStockPartRequestFilters = {
  ...initPaging,
  isActive: true,
};

export type PartRequestLinePurchaseOrderDto = {
  id: number,
  purchaseOrderNumber: string | null,
}

export type StockPartReqTableLineType = {
  id: number,
  ordinalNumber?: number | string,
  categoryId?: string,
  productNumber: string,
  productName: string,
  purchaseOrderNumber?: PartRequestLinePurchaseOrderDto[]
  notes?: string
  priceSources: number[] | string,
  quantity: number,
  readyQuantity: number | string,
  collectedQuantity: number | string,
  allocatedQuantity?: number | string,
  status: string,
  unitOfMeasure: number,
  partId: number | null,
  partsKitId: number | null,
  partsKitComponentId: number | null,
  partsKitComponentPartId: number | null,
  customer?: string,
  linkedTo?: string,
  partRequestNumber?: string,
  linkedEntityStatus?: string | number,
  canPurchaseOrderBeGenerated?: boolean,
  deletionReason: string | null,
  childLines: Omit<StockPartReqTableLineType, 'childLines'>[],
}

export type StockPartRequestLineActionType = {
  allowedStatuses: string[],
  onClick: VoidFunctionType,
  icon: JSX.Element,
  label: string,
  key: string
}

export type SourceConfirmedDataType = {
  readyQuantity: number | string,
  collectedQuantity: number | string,
  allocatedQuantity: number | string,
  unitOfMeasure?: number | string,
}

export type PutPartRequestLineStatusRestModel = {
  statusTransition?: number,
  partId?: number,
  stockQuantity?: number | null,
  pricelists?: Array<{
    pricelistId: number,
    quantity: number,
  }> | null,
}

export type StockPartProductSourceTotal = {
  stockCostPriceMin: string | null,
  stockCostPriceMax: string | null,
  stockSellPriceMin: string | null,
  stockSellPriceMax: string | null,
  pricelistTotals: Array<{
    pricelistId: number,
    costPrice: number,
    sellPrice: number,
    costPriceString: string | null,
    sellPriceString: string | null,
  }> | null,
  manualInputSellPrice: string | null,
  requestedQuantity: string | null,
  confirmedQuantity: string | null,
  quantityDifference: string | null,
  requestedCostNet: string | null,
  confirmedCostNet: string | null,
  costNetDifference: string | null,
  requestedSellNet: string | null,
  confirmedSellNet: string | null,
  sellNetDifference: string | null,
}

export type PartReqPartQuantityFilters = {
  statuses?: number[],
  ownerIds?: number[],
  partRequestLineId?: number,
  purchaseOrderIds?: number[] | string[],
  isAvailableForPartRequestId?: number,
  linkedTo?: string,
} & PagingWithOrdering

export type AllocatePartQuantityFilters = {
  order?: OrderingType,
  statuses?: number[],
  isAvailableForPartRequestId?: number,
  linkedTo?: string,
}
export type AllocatePricelistQuantityDto = {
  partQuantityId: number | null,
  quantity: number,
  remaining?: number,
}

export type ConfirmedPartRequestViewsParams = {
  supplierName?: string,
  partNumber?: string,
  partRequestNumbers?: string,
  linkedNumbers?: string,
  order?: OrderingType,
} & PagingType

export const InitConfirmedPartRequestViewsParams = { ...initSmallPaging };

export type ConfirmedPartRequestDto = {
  id: string,
  supplierName: string | null,
  partNumber: string | null,
  quantity: number,
  partRequestNumbers: string[] | null,
  linkedNumbers: string[] | null,
}

export type PartRequestByLineFilters = {
  keyword?: string,
  productNumber?: string,
  linkedEntityNumber?: string,
  linkedEntityStatus?: string,
  statuses?: number[],
  isActive?: boolean,
} & PagingType
