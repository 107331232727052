// TODO: 'eslint-disable' should be removed if forms will be developing
/* eslint-disable */

import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { CoreAttachmentDto, VoidFunctionType } from '../../core/types/coreTypes';
import { useAppDispatch } from '../../store/hooks';
import usePermission from '../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import AttachmentsRow from '../../common/components/attachments-table/AttachmentsRow';

type FormsCellProps = {
  attach: CoreAttachmentDto,
  openEditModal: VoidFunctionType,
  clearCheckboxes: VoidFunctionType,
  checked: boolean,
  checkRow: VoidFunctionType,
}

const FormsCell:React.FC<FormsCellProps> = ({
  attach,
  openEditModal,
  clearCheckboxes,
  checked,
  checkRow,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isModal, setModal] = useState<boolean>(false);
  const allowedManage = usePermission(PermissionEnum.TestModule);
  const actions = [
    {
      label: 'Edit comment',
      icon: <FontAwesomeIcon icon={faEdit} />,
      key: 'edit-comment',
      onClick: () => openEditModal(),
    },
    {
      label: 'Delete file',
      key: 'delete-file',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setModal(true),
    },
  ];

  return (
    <>
      <ConfirmationModal
        isVisible={isModal}
        onCancel={() => setModal(false)}
        // confirmHandler={() => id && dispatch(deleteEmployeeAttachmentThunk({
        //   id: attach.id,
        //   employeeId: +id,
        //   closeModal: () => {
        //     setModal(false);
        //     clearCheckboxes();
        //   },
        // }))}
        confirmHandler={() => console.log('confirm')}
      />
      <AttachmentsRow
        allowedManage={!!allowedManage}
        attachment={attach}
        checked={checked}
        checkRows={checkRow}
        // onClick={(attachId) => id && dispatch(getEmployeeAttachmentByIdThunk({
        //   id: attachId,
        //   employeeId: +id,
        // }))}
        onClick={() => console.log('click')}
        actions={actions}
      />
    </>
  );
};

export default FormsCell;
