import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import { StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

type ActivityNoteInputProps = {
  value: string,
  setValue: StringFunctionType,
  addNote: VoidFunctionType,
  disabledInput?: boolean,
  disabledButton?: boolean,
}
const ActivityNoteInput: React.FC<ActivityNoteInputProps> = ({
  value,
  setValue,
  addNote,
  disabledInput,
  disabledButton,
}) => {
  const [error, setError] = useState('');

  return (
    <InputWithButton
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        if (e.target.value.length > 200) {
          setError(maxLengthMessageHandle(200));
        } else {
          setError('');
        }
      }}
      onBlur={(e) => {
        const v = e.target.value;
        if (!v.trim()) {
          setValue('');
        }
      }}
      placeholder="Add a note..."
      error={error}
      icon={<FontAwesomeIcon icon={faPaperPlane} />}
      onClick={() => value.trim() && !error && addNote()}
      disabled={disabledInput}
      buttonDisabled={!value.trim() || !!error || disabledButton}
    />
  );
};

export default ActivityNoteInput;
