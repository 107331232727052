import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import CustomerHead from './components/customer-head/CustomerHead';
import CustomerStaticSection from './components/customer-static-section/CustomerStaticSection';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  deleteCustomerThunk,
  getCustomerById,
} from '../../store/thunks/customers/customersViewPageThunks';
import { customerDetailsSelector } from '../../store/selectors/customersSelectors';
import { CustomerContentEnum, CustomerContentEnumList } from './enums/customerContentEnum';
import CustomerDynamicSection from './components/customer-dynamic-section/CustomerDynamicSection';
import { clearCustomerData } from './utils/clearCustomerData';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import './CustomerViewPageContainer.scss';
import { tabCountHandle } from '../../core/utils/tabCountHandle';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { getCustomerEventTypesThunk } from '../../store/thunks/core/coreEventTypesThunks';

const CustomerViewPageContainer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const { backHandle } = useLocationHistoryState(true);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const customerDetails = useAppSelector(customerDetailsSelector);
  const [isConfirmationModal, setConfirmationModal] = useState(false);

  const customerLinkedEntities = [
    customerDetails?.customer.enquiriesAmount,
    customerDetails?.customer.jobsAmount,
    customerDetails?.customer.rectificationsAmount,
    customerDetails?.customer.ordersAmount,
    customerDetails?.customer.vehicleAmount,
    customerDetails?.customer.pricelistsAmount,
  ];

  const tabsOptions = [
    {
      label: 'Details & activity',
      value: CustomerContentEnum.CUSTOMER_DETAILS,
    },
    {
      label: `Contacts ${tabCountHandle(customerDetails?.customer.contactsAmount)}`,
      value: CustomerContentEnum.CUSTOMER_CONTACTS,
    },
    {
      label: `Attachments ${tabCountHandle(customerDetails?.customer.attachmentsAmount)}`,
      value: CustomerContentEnum.CUSTOMER_ATTACHMENTS,
    },
  ];
  useEffect(() => {
    if (currentUser) {
      const modeParam = params.get('mode');
      const { permissions } = currentUser.userContextRole;
      if (!modeParam && permissions.includes(PermissionEnum.CustomerViewPage)) {
        setSearchParams({ mode: tabsOptions[0].value }, { replace: true });
      } else {
        if (!CustomerContentEnumList.includes(modeParam as CustomerContentEnum)) {
          setSearchParams({ mode: CustomerContentEnum.CUSTOMER_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, customerDetails]);

  const actions = [
    {
      label: 'Delete customer',
      key: 'delete-customer',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setConfirmationModal(true),
      restrictedTo: PermissionEnum.CustomerDelete,
      disabled: customerLinkedEntities.some((el) => !!el),
    },
  ];

  useEffect(() => {
    id && dispatch(getCustomerById({ id: +id }));
    // eslint-disable-next-line
  }, [id, params]);

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };

  useEffect(() => {
    dispatch(getCustomerEventTypesThunk());
    return () => {
      clearCustomerData(dispatch);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          actions={actions}
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          confirmHandler={() => id && dispatch(deleteCustomerThunk({ id: +id, navigate }))}
          className="customerViewPageDetailsHead"
          goBack={backHandle}
        >
          <CustomerHead details={customerDetails} />
        </DetailsHead>
      }
    >
      <CustomerStaticSection
        mode={params.get('mode')}
        setModeParam={setModeParam}
        allowedOptions={tabsOptions}
      />
      <CustomerDynamicSection mode={params.get('mode')} />
    </Layout>
  );
};

export default CustomerViewPageContainer;
