import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { CustomPartDataType } from './partsKitViewPageTypes';

export type CustomComponentFields = Pick<CustomPartDataType, 'productName'> & {
  quantity: string,
  unitOfMeasure?: number,
  unitPrice: string,
  tax?: number,
  categoryId?: number,
  weight: string
};

export const customComponentFormFieldsDefaults = {
  productName: '',
  quantity: '0',
  unitOfMeasure: undefined,
  unitPrice: '0.00',
  tax: undefined,
  categoryId: undefined,
  weight: '0.00',
};

export const ProductNameFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 200, message: maxLengthMessageHandle(200) },
};

export const QuantityFiledValidationSchema = (isIntegerQuantitiesMeasure: boolean) => ({
  required: ErrorsEnum.REQUIRED,
  min: {
    value: isIntegerQuantitiesMeasure ? 0 : 0.00,
    message: isIntegerQuantitiesMeasure ? 'The min allowed value is 0' : 'The min allowed value is 0.00',
  },
});
