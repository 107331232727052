import React from 'react';
import { JobCalendarTaskDto } from '../types/jobCalendarTypes';
import { jobPostponedStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import { useAppSelector } from '../../../store/hooks';

type JobLineProgressBarProps = {
  job: JobCalendarTaskDto,
}

const JobLineProgressBar: React.FC<JobLineProgressBarProps> = ({ job }) => {
  const jobPostponedStatus = useAppSelector(jobPostponedStatusSelector);
  const totalSpent = job.spentMinutesByAllAssignees;
  const estimation = job.estimatedMinutes;
  const jobProgress = `${((totalSpent / estimation) * 100).toFixed(0)}%`;
  const status = job.childStatus;
  const isTaskPostponed = jobPostponedStatus === status;
  const barState = () => {
    const areValuesGreaterThanZero = totalSpent > 0 && estimation > 0;
    if (isTaskPostponed) {
      return 'disabledProgress';
    } else {
      if (totalSpent === 0) return 'noProgress';
      if (areValuesGreaterThanZero && totalSpent < estimation) return 'someProgress';
      if (areValuesGreaterThanZero && totalSpent === estimation) return 'done';
      if (totalSpent > estimation) return 'overspent';
      else return '';
    }
  };

  const styles = { '--job-progress': jobProgress } as React.CSSProperties;
  return (
    <div className={`jobLineProgressBar job__timer ${barState()}`} style={styles} />
  );
};

export default JobLineProgressBar;
