import { useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import {
  currentVehicleSelector,
  quoteLinesColumnsSelector,
  quoteLinesSelector, quoteTableLinesSelector,
} from '../../../store/selectors/enquiriesSelector';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useSelectAll } from '../../../core/hooks/useSelectAll';

export const useQuoteLinesTable = (enquiryAcceptedOrRejected: boolean) => {
  const allowedToManage = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);
  const currentVehicle = useAppSelector(currentVehicleSelector);
  const { allItemIds } = useAppSelector(quoteLinesSelector);
  const columns = useAppSelector(quoteLinesColumnsSelector);
  const linesArr = useAppSelector(quoteTableLinesSelector);

  const [deleteLineModal, setDeleteLineModal] = useState(false);
  const [discountModal, setDiscountModal] = useState(false);

  const [partsModal, setPartsModal] = useState(false);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
    checkedKeysCount,
  } = useSelectAll(allItemIds);

  const footerActions = [
    {
      label: 'Add row',
      icon: faPlus,
      onClick: () => setPartsModal(true),
      disabled: enquiryAcceptedOrRejected || !allowedToManage,
      isVisible: true,
    },
    {
      label: `Delete selected ${checkedKeysCount}`,
      onClick: () => setDeleteLineModal(true),
      disabled: checkedKeys.length === 0 || enquiryAcceptedOrRejected || !allowedToManage,
      isVisible: true,
    },
    {
      label: `Set discount for selected ${checkedKeysCount}`,
      onClick: () => setDiscountModal(true),
      disabled: checkedKeys.length === 0 || enquiryAcceptedOrRejected || !allowedToManage,
      isVisible: currentVehicle?.enableDiscounts,
    },
  ];

  return {
    columns,
    linesArr,
    footerActions,
    deleteLineModal,
    setDeleteLineModal,
    isAll,
    isIndeterminate,
    checkAllHandle,
    checkRowHandle,
    checkedKeys,
    setDiscountModal,
    discountModal,
    partsModal,
    setPartsModal,
  };
};
