import { useEffect, useState } from 'react';
import { PatchPartReqLineDto } from '../types/commonTypes';
import { VoidFunctionType } from '../../core/types/coreTypes';

type DataForReasonToPatch = {
  lineId: number,
  data: PatchPartReqLineDto,
  setFieldToEdit?: VoidFunctionType,
  closeModal?: VoidFunctionType,
}

export const usePartRequestLinesReason = () => {
  const [dataToPatch, setDataToPatch] = useState<DataForReasonToPatch | null>(null);
  const [patchReasonModal, setPatchReasonModal] = useState(false);
  const [discountReasonModal, setDiscountReasonModal] = useState(false);
  const [deleteReasonModal, setDeleteReasonModal] = useState(false);

  useEffect(() => {
    if (!patchReasonModal) {
      setDataToPatch(null);
    }
  }, [patchReasonModal]);

  return {
    patchReasonModal,
    setPatchReasonModal,
    dataToPatch,
    setDataToPatch,
    discountReasonModal,
    setDiscountReasonModal,
    deleteReasonModal,
    setDeleteReasonModal,
  };
};
