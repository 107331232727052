import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  supplierAttachmentFiltersSelector,
  supplierAttachmentsSelector,
} from '../../../../../store/selectors/supplierSelector';
import { CustomAny } from '../../../../../core/types/coreTypes';
import CreateAttachmentModal from '../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import SupplierAttachmentFilters from './filters/SupplierAttachmentFilters';
import SupplierAttachmentsTable from './table/SupplierAttachmentsTable';
import {
  createSuppliersAttachmentThunk, deleteMultipleSupplierAttachmentThunk,
  getSuppliersAttachments,
} from '../../../../../store/thunks/suppliers/suppliersAttachmentsThunks';
import { setLoading } from '../../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../../core/utils/guidGenerator';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

const SupplierAttachments: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(supplierAttachmentsSelector);
  const filters = useAppSelector(supplierAttachmentFiltersSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<CustomAny>([]);
  const [comment, setComment] = useState('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    id && dispatch(getSuppliersAttachments({ supplierId: +id, filters }));
    // eslint-disable-next-line
    }, [id, filters]);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    const list = fileList.map((el: CustomAny) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createSuppliersAttachmentThunk({
      id: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultipleSupplierAttachmentThunk({
      ids: checkedKeys,
      supplierId: +id,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <SupplierAttachmentFilters
        filters={filters}
        totalItems={totalCount}
        setCreateModal={setCreateModal}
        setDeleteModal={() => setDeleteModal(true)}
        checkedKeys={checkedKeys}
      />

      <SupplierAttachmentsTable
        attachments={items}
        checkedKeys={checkedKeys}
        isAll={isAll}
        indeterminate={isIndeterminate}
        checkAllHandle={checkAllHandle}
        checkRow={checkRowHandle}
      />
    </>
  );
};

export default SupplierAttachments;
