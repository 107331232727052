import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IUser,
  UserListFiltersType,
} from '../../settings/users-dashboard/types/settingsTypes';
import { getSettingsUsersList, getPermissionModules } from '../thunks/settings/settingsDashboardThunks';
import {
  DataListType,
  DictionaryItem, DictionaryResponse, initDataList, initDataListWithIds, initPaging, ResponseResult, ResponseSingleResult,
} from '../../core/types/coreTypes';
import { UserAccountDto } from '../../settings/user-profile/types/userProfileTypes';
import { getUserAccountThunk, getUserEntityTimelineThunk, getUserTimelineThunk } from '../thunks/settings/userViewPageThunks';
import { UserAccountPermissionDto, UserAccountRolePermissionDto } from '../../settings/permissions/types/permissionTypes';
import { getPermission, getPermissionRoles } from '../thunks/settings/permissionsThunks';
import {
  IInfoMessage, InfoMessageMaxDisplayOrderDto,
  InfoMessagesFiltersType,
} from '../../settings/info-messages/types/infoMessagesTypes';
import { getInfoMessageMaxDisplayOrder, getInfoMessagesList } from '../thunks/settings/infoMessagesThunks';
import { UserEntityTimelineEvent, UserTimelineEvent } from '../../settings/user-profile/types/activityTypes';
import { ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';

interface ISettingsState {
  listFilters: UserListFiltersType,
  usersList: DataListType<IUser[], { allItemIds: number[] }>,
  userProfile: UserAccountDto | null,
  permissions: UserAccountPermissionDto[] | null,
  rolePermissions: UserAccountRolePermissionDto[] | null,
  infoMessagesFilters: InfoMessagesFiltersType,
  infoMessagesList: DataListType<IInfoMessage[]>,
  infoMessageMaxDisplayOrder: number,
  permissionModules: Array<DictionaryItem>,
  currentPermissionModule?: number,
  userTimeline: DataListType<UserTimelineEvent[]>,
  userTimelineFilters: ExtendedTimelineFilters,
  userEntityTimeline: DataListType<UserEntityTimelineEvent[]>
  userEntityTimelineFilters: ExtendedTimelineFilters,
}

export const initialSettingsState: ISettingsState = {
  listFilters: initPaging,
  usersList: initDataListWithIds,
  userProfile: null,
  permissions: null,
  rolePermissions: null,
  infoMessagesFilters: initPaging,
  infoMessagesList: initDataList,
  infoMessageMaxDisplayOrder: 0,
  permissionModules: [],
  currentPermissionModule: undefined,
  userTimeline: initDataList,
  userTimelineFilters: initExtendedTimelineFilters,
  userEntityTimeline: initDataList,
  userEntityTimelineFilters: initExtendedTimelineFilters,
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: initialSettingsState,
  reducers: {
    setListFilters: (state, action: PayloadAction<UserListFiltersType>) => {
      state.listFilters = action.payload;
    },
    setUsersList: (state, action: PayloadAction<DataListType<IUser[], { allItemIds: number[] }>>) => {
      state.usersList = action.payload;
    },
    setUserProfile: (state, action: PayloadAction<UserAccountDto | null>) => {
      state.userProfile = action.payload;
    },
    setInfoMessagesListFilters: (state, action: PayloadAction<InfoMessagesFiltersType>) => {
      state.infoMessagesFilters = action.payload;
    },
    setRolePermissions: (state, action: PayloadAction<UserAccountRolePermissionDto[] | null>) => {
      state.rolePermissions = action.payload;
    },
    setCurrentPermissionModule: (state, action: PayloadAction<number | undefined>) => {
      state.currentPermissionModule = action.payload;
    },
    setUserTimeline: (state, action: PayloadAction<DataListType<UserTimelineEvent[]>>) => {
      state.userTimeline = action.payload;
    },
    setUserTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.userTimelineFilters = action.payload;
    },
    setUserEntityTimeline: (state, action: PayloadAction<DataListType<UserEntityTimelineEvent[]>>) => {
      state.userEntityTimeline = action.payload;
    },
    setUserEntityTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.userEntityTimelineFilters = action.payload;
    },
  },
  // Later, dispatch the thunk as needed in the app
  extraReducers: (builder) => {
    builder.addCase(getSettingsUsersList.fulfilled, (state, action: PayloadAction<ResponseResult<IUser[], { allItemIds: number[] }>>) => {
      state.usersList = action.payload.data;
    });
    builder.addCase(
      getUserAccountThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ userAccount: UserAccountDto}> | null>) => {
        state.userProfile = action.payload?.data.userAccount || null;
      },
    );
    builder.addCase(
      getPermission.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: UserAccountPermissionDto[] }> | null>) => {
        state.permissions = action.payload?.data.items || null;
      },
    );
    builder.addCase(
      getPermissionRoles.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: UserAccountRolePermissionDto[] }> | null>) => {
        state.rolePermissions = action.payload?.data.items || null;
      },
    );
    builder.addCase(
      getInfoMessageMaxDisplayOrder.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<InfoMessageMaxDisplayOrderDto> | null>) => {
        state.infoMessageMaxDisplayOrder = action.payload?.data.maxDisplayOrder || 0;
      },
    );
    builder.addCase(getInfoMessagesList.fulfilled, (state, action: PayloadAction<ResponseResult<IInfoMessage[]> | null>) => {
      state.infoMessagesList = action.payload ? action.payload?.data : initDataList;
    });
    builder.addCase(getPermissionModules.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.permissionModules = action.payload.data.items;
    });
    builder.addCase(getUserTimelineThunk.fulfilled, (state, action: PayloadAction<ResponseResult<UserTimelineEvent[]>>) => {
      state.userTimeline = action.payload.data;
    });
    builder.addCase(getUserEntityTimelineThunk.fulfilled, (state, action: PayloadAction<ResponseResult<UserEntityTimelineEvent[]>>) => {
      state.userEntityTimeline = action.payload.data;
    });
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setListFilters,
  setUserProfile,
  setInfoMessagesListFilters,
  setUsersList,
  setRolePermissions,
  setCurrentPermissionModule,
  setUserTimeline,
  setUserTimelineFilters,
  setUserEntityTimeline,
  setUserEntityTimelineFilters,
} = settingsSlice.actions;

export default settingsSlice.reducer;
