import React from 'react';
import './PartsKitDetailsAndActivity.scss';
import PartsKitDetailsSection from './PartsKitDetailsSection';
import PartsKitActivitySection from './PartsKitActivitySection';

const PartsKitDetailsAndActivity: React.FC = () => {
  return (
    <div className="kitsDetailsWrap info-grid">
      <PartsKitDetailsSection />
      <PartsKitActivitySection />
    </div>
  );
};

export default PartsKitDetailsAndActivity;
