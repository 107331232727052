import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../../../core/components/button/Button';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import Tr from '../../../../../../../core/components/table-components/Tr';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';

type PartViewPricesParentRowProps = {
  title: string,
  isHidden: boolean,
  handleClick: VoidFunctionType
}
const PartViewPricesParentRow:React.FC<PartViewPricesParentRowProps> = ({ title, isHidden, handleClick }) => {
  return (
    <Tr className="partViewPricesParentRow">
      <TdNew colSpan={4}>
        <Button
          icon={<FontAwesomeIcon icon={isHidden ? faPlusCircle : faMinusCircle} />}
          onClick={handleClick}
          isExpand
        />
        <span>{title}</span>
      </TdNew>
    </Tr>
  );
};

export default PartViewPricesParentRow;
