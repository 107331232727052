import {
  FieldErrors, FieldValues, Path, UseFormSetError,
} from 'react-hook-form';
import { ServerErrorType, VoidFunctionType } from '../types/coreTypes';
import { DuplicateValue, ErrorsEnum } from '../enums/errorsEnum';

export const checkIsFormHasDuplicateError = <T extends FieldValues>(errors: FieldErrors<T>, field: string) => {
  if (!errors[field]) return false;
  return errors[field].type === DuplicateValue;
};

export const duplicateServerErrorsHelper = <T extends FieldValues>(
  errors: Array<ServerErrorType>,
  setError?: UseFormSetError<T>,
  onClose?: VoidFunctionType,
) => {
  const duplicatesArr = errors.filter((el: ServerErrorType) => el.message === DuplicateValue);
  if (duplicatesArr.length > 0) {
    onClose && onClose();
    errors.forEach((err) => {
      const keyErr = err.key.charAt(0).toLowerCase() + err.key.slice(1);
      setError && setError(keyErr as Path<T>, { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
    });
  }
};
