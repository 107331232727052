import { useEffect, useState } from 'react';
import { NumberOfConvertedVehiclesReportOrdering } from '../enum/ReportTableOrders';
import { setNumberOfConvertedVehiclesFilters } from '../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { numberOfConvertedVehiclesFiltersSelector } from '../../store/selectors/reportsSelectors';
import { numberOfConvertedVehiclesOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getNumberOfConvertedVehiclesReportOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';

export const useNumberOfConvertedVehiclesDefaultOrdering = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(numberOfConvertedVehiclesFiltersSelector);
  const ordering = useAppSelector(numberOfConvertedVehiclesOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    ordering.length === 0 && dispatch(getNumberOfConvertedVehiclesReportOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters?.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === NumberOfConvertedVehiclesReportOrdering.BusinessAreaThenJobType)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setNumberOfConvertedVehiclesFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    order: defaultOrder ? { field: defaultOrder, isAsc: true } : filters?.order,
  };
};
