import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { SelectEmployeesOptionsEnum } from '../../enums/workCalendarEnums';
import { RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import Select from '../../../../core/components/select/Select';
import { useAppSelector } from '../../../../store/hooks';
import {
  employeeDepartmentsWithCountSelector, employeeJobTitlesWithCountSelector,
  employeesTotalDictionaryListSelector,
} from '../../../../store/selectors/employeesSelector';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { EmployeeSchedule } from './EmployeesWorkingHoursModal';
import { SetWorkingHoursFields } from '../../utils/SetWorkingHoursSchema';

type WorkingHoursEmployeeModeContentProps = {
  selectEmployeeMode: SelectEmployeesOptionsEnum,
  control: Control<SetWorkingHoursFields>,
  errors: FieldErrors<SetWorkingHoursFields>,
  employeeSchedule: EmployeeSchedule | null,
  employeesKeyword: string,
  setEmployeesKeyword: StringFunctionType,
}

const WorkingHoursEmployeeModeContent: React.FC<WorkingHoursEmployeeModeContentProps> = ({
  selectEmployeeMode, control, errors, employeeSchedule, employeesKeyword, setEmployeesKeyword,
}) => {
  const employees = useAppSelector(employeesTotalDictionaryListSelector);
  const departments = useAppSelector(employeeDepartmentsWithCountSelector);
  const jobTitles = useAppSelector(employeeJobTitlesWithCountSelector);
  return (
    <>
      {!employeeSchedule && <>
        {selectEmployeeMode === SelectEmployeesOptionsEnum.BY_DEPARTMENT && <Controller
          control={control}
          name="departments"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={departments}
              onChange={field.onChange}
              value={field.value}
              mode="multiple"
              label="Department"
              error={(errors.departments as FieldErrors)?.message} // https://github.com/react-hook-form/react-hook-form/issues/987
              maxTagCount="responsive"
              showArrow
              showSearch
              isFilterOption
              parentRender
              className="details-form__field--lg employeesWorkingHours__employees"
            />
          )}
        />}
        {selectEmployeeMode === SelectEmployeesOptionsEnum.BY_JOB_TITLE && <Controller
          control={control}
          name="jobTitles"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={jobTitles}
              onChange={field.onChange}
              value={field.value}
              mode="multiple"
              label="Job title"
              error={(errors.jobTitles as FieldErrors)?.message}
              maxTagCount="responsive"
              showArrow
              isFilterOption
              showSearch
              parentRender
              className="details-form__field--lg employeesWorkingHours__employees"
            />
          )}
        />}
      </>}
      {selectEmployeeMode === SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE && <Controller
        control={control}
        name="employeeIds"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={employees}
            onChange={field.onChange}
            value={field.value}
            mode="multiple"
            label="Affected employees"
            error={(errors.employeeIds as FieldErrors)?.message}
            maxTagCount="responsive"
            onSearch={(value) => setEmployeesKeyword(value)}
            onBlur={() => {
              if (employeesKeyword.length > 0) {
                setEmployeesKeyword('');
              }
            }}
            onSelect={() => {
              if (employeesKeyword.length > 0) {
                setEmployeesKeyword('');
              }
            }}
            showArrow
            showSearch
            parentRender
            className="details-form__field--lg employeesWorkingHours__employees"
            disabled={!!employeeSchedule}
          />
        )}
      />}
    </>
  );
};

export default WorkingHoursEmployeeModeContent;
