import React, { useEffect, useState } from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import Input from '../../../../core/components/input/Input';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Textarea from '../../../../core/components/textarea/Textarea';
import { addSkillToCategoryThunk, editSkillThunk } from '../../../../store/thunks/employee/skillsThunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { skillsFiltersSelector, skillsListByCategorySelector } from '../../../../store/selectors/employeesSelector';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';

type AddSkillItemModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  editMode?: boolean,
  skillToChange?: number,
}

const AddSkillItemModal: React.FC<AddSkillItemModalProps> = ({
  isVisible, onCancel, editMode, skillToChange,
}) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string | undefined>(undefined);
  const [err, setErr] = useState<string | undefined>(undefined);
  const [descr, setDescr] = useState<string | undefined>(undefined);
  const [descrErr, setDescrErr] = useState<string | undefined>(undefined);
  const skillsFilters = useAppSelector(skillsFiltersSelector);
  const { skills: skillsList } = useAppSelector(skillsListByCategorySelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const setNameHandle = (v: string) => {
    if (!v.trim()) {
      setErr(ErrorsEnum.REQUIRED);
    } else if (v.length > 200) {
      setErr(maxLengthMessageHandle(200));
    } else err && setErr(undefined);
    setName(v);
  };
  const setDescrHandle = (v: string) => {
    if (v.length > 2000) {
      setDescrErr(maxLengthMessageHandle(2000));
    } else descrErr && setDescrErr(undefined);
    setDescr(v);
  };

  const cancelHandle = () => {
    setName(undefined);
    setDescr(undefined);
    err && setErr(undefined);
    descrErr && setDescrErr(undefined);
    onCancel();
  };
  const createHandle = () => {
    if (!name) {
      setErr(ErrorsEnum.REQUIRED);
    } else {
      const noErr = !err && !descrErr;
      if (skillsFilters.categoryId && noErr) {
        const data = {
          categoryId: skillsFilters.categoryId,
          name: name.trim(),
          description: descr ? descr.trim() : null,
          closeModal: cancelHandle,
        };
        if (editMode && skillToChange) {
          dispatch(editSkillThunk({ ...data, skillId: skillToChange }));
        } else {
          dispatch(addSkillToCategoryThunk({ ...data }));
        }
      }
    }
  };

  useEffect(() => {
    if (editMode) {
      const currentSkill = skillsList?.find((el) => el.id === skillToChange);
      if (currentSkill) {
        setName(currentSkill.name || '');
        setDescr(currentSkill.description || '');
      }
    }
  }, [editMode, skillToChange, skillsList]);
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={editMode ? 'Edit skill item' : 'Add skill item'}
    >
      <Input
        label="Name"
        value={name}
        onBlur={(e) => {
          const v = e.target.value;
          if (!v.trim()) {
            setErr(ErrorsEnum.REQUIRED);
            setName(undefined);
          }
        }}
        onChange={(e) => {
          setNameHandle(e.target.value);
        }}
        error={err}
        className="createCategoryInput"
      />
      <Textarea
        label="Description"
        value={descr || ''}
        onChange={(e) => setDescrHandle(e.target.value)}
        error={descrErr}
        className="createCategoryInput"
      />
      <ButtonActions
        createLabel={editMode ? 'Save' : 'Create'}
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createClick={createHandle}
        isLoading={isFetching}
      />
    </Modal>
  );
};

export default AddSkillItemModal;
