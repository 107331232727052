import { useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ReportsModesEnum } from '../enum/ReportsEnum';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserPermissionsSelector } from '../../store/selectors/accountSelectors';
import {
  allReportsValues,
  productionReportTypes,
  purchasingReportTypes,
  reportsModes,
  salesReportTypes,
  stockReportTypes,
} from '../utils/data';
import { DictionaryStringValueItem } from '../../core/types/coreTypes';
import { setIsTableView, setReportIsVisible } from '../../store/slices/reportsSlice';

export const useReportsParams = () => {
  const dispatch = useAppDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const permissions = useAppSelector(currentUserPermissionsSelector);
  const mode = searchParams.get('mode');
  const report = searchParams.get('report');

  const [allowedModes, setAllowedModes] = useState<DictionaryStringValueItem[]>([]);
  const [allowedReports, setAllowedReports] = useState<DictionaryStringValueItem[]>([]);

  useEffect(() => {
    if (report) {
      dispatch(setReportIsVisible(false));
      dispatch(setIsTableView(true));
    }
    // eslint-disable-next-line
  }, [report]);

  const isAllowed = (permission: PermissionEnum) => permissions?.includes(permission);

  const filterAllowedReports = <T>(reports: Array<{label: string, value: T, restrictedTo: PermissionEnum}>) => {
    return (
      reports.filter((el) => isAllowed(el.restrictedTo))
        .map(({ label, value }) => ({ label, value }))
    );
  };

  const getAllowedReports = () => {
    switch (mode) {
      case ReportsModesEnum.PRODUCTION: {
        const allowed = filterAllowedReports(productionReportTypes);
        setAllowedReports(allowed);
        break;
      }
      case ReportsModesEnum.PURCHASING: {
        const allowed = filterAllowedReports(purchasingReportTypes);
        setAllowedReports(allowed);
        break;
      }
      case ReportsModesEnum.SALES: {
        const allowed = filterAllowedReports(salesReportTypes);
        setAllowedReports(allowed);
        break;
      }
      case ReportsModesEnum.STOCK: {
        const allowed = filterAllowedReports(stockReportTypes);
        setAllowedReports(allowed);
        break;
      }
      default: break;
    }
  };

  useEffect(() => {
    const permittedModes = reportsModes.filter((el) => isAllowed(el.restrictedTo));
    const modesWithPermittedReports = permittedModes
      .filter((el) => el.permissionsList.some((l) => isAllowed(l)))
      .map(({ label, value }) => ({ label, value }));
    setAllowedModes(modesWithPermittedReports);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!mode && allowedModes.length > 0) {
      setSearchParams({ mode: allowedModes[0].value }, { replace: true });
    } else {
      if (!allowedModes.find((el) => el.value === mode) && allowedModes.length > 0) {
        setSearchParams({ mode: allowedModes[0].value }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [allowedModes, mode]);

  useEffect(() => {
    getAllowedReports();
    // eslint-disable-next-line
  }, [mode]);

  useEffect(() => {
    if (mode && !!allowedReports.length) {
      if (!report || !allReportsValues.includes(report)) {
        setSearchParams({ mode, report: allowedReports[0].value }, { replace: true });
      } else if (report && !allowedReports.find((el) => el.value === report)) {
        setSearchParams({ mode, report: allowedReports[0].value }, { replace: true });
      } else {
        setSearchParams({ mode, report });
      }
    }
    // eslint-disable-next-line
  }, [allowedReports]);

  const setModeParam = (mode: string) => {
    setSearchParams({ mode });
  };

  const setReportParam = (report: string) => {
    mode && setSearchParams({ mode, report });
  };

  return {
    mode,
    allowedModes,
    setModeParam,
    report,
    allowedReports,
    setReportParam,
  };
};
