import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { DictionaryItem } from '../../../core/types/coreTypes';
import { EnquiriesNewResponseType } from '../types/enquiryTypes';
import { EnquiryStatusUiNamesEnum } from '../../../core/enums/dictionariesEnums';
import { editEnquiryThunk } from '../../../store/thunks/enquiry/enquiryThunks';
import { useAppDispatch } from '../../../store/hooks';

export const useStatusTransition = (enquiryStatus: DictionaryItem[], enquiryCollections: EnquiriesNewResponseType | null) => {
  const dispatch = useAppDispatch();
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [isSentModal, setSentModal] = useState(false);
  const [isRejectModal, setRejectModal] = useState(false);
  const [isAcceptedModal, setAcceptedModal] = useState(false);
  const [enquiryTransit, setEnquiryTransit] = useState<{id: number, initStatus: number} | undefined>(undefined);
  const [transitVehicleCount, setTransitVehicleCount] = useState<number | undefined>(undefined);
  const [columnsLoading, setColumnsLoading] = useState<number[]>([]);

  const editStatus = (
    result: DropResult,
    setColumns: (value: EnquiriesNewResponseType | null) => void,
  ) => {
    if (!result.destination) return;
    const { source, destination } = result;
    const newStatus = enquiryStatus.find((el) => el.label === EnquiryStatusUiNamesEnum.New)?.value;
    const currentStatus = enquiryStatus.find((el) => el.value === +source.droppableId)?.label;
    const futureStatus = enquiryStatus.find((el) => el.value === +destination.droppableId)?.label;

    if (currentStatus === EnquiryStatusUiNamesEnum.Finalised && futureStatus === EnquiryStatusUiNamesEnum.SentToCustomer) {
      setSentModal(true);
    } else if ((currentStatus === EnquiryStatusUiNamesEnum.Postponed || currentStatus === EnquiryStatusUiNamesEnum.SentToCustomer)
            && futureStatus === EnquiryStatusUiNamesEnum.Rejected) {
      setRejectModal(true);
    } else if (currentStatus === EnquiryStatusUiNamesEnum.SentToCustomer && futureStatus === EnquiryStatusUiNamesEnum.Accepted) {
      setAcceptedModal(true);
    } else if (currentStatus === EnquiryStatusUiNamesEnum.Rejected && futureStatus === EnquiryStatusUiNamesEnum.New) {
      newStatus && dispatch(editEnquiryThunk({
        id: +result.draggableId,
        statusTransition: newStatus,
        sourceId: +result.source.droppableId,
        setColumns: () => setColumns(enquiryCollections),
        rejectionReason: '',
        setColumnsLoading,
      }));
    } else {
      result.destination && dispatch(editEnquiryThunk({
        id: +result.draggableId,
        statusTransition: +result.destination.droppableId,
        sourceId: +result.source.droppableId,
        setColumns: () => setColumns(enquiryCollections),
        setColumnsLoading,
      }));
    }
  };

  const setDefaultValues = () => {
    setEnquiryTransit(undefined);
    setTransitVehicleCount(undefined);
  };

  return {
    editStatus,
    setDefaultValues,
    isSentModal,
    setSentModal,
    isRejectModal,
    setRejectModal,
    isAcceptedModal,
    setAcceptedModal,
    enquiryTransit,
    setEnquiryTransit,
    transitVehicleCount,
    setTransitVehicleCount,
    isButtonDisabled,
    setButtonDisabled,
    columnsLoading,
    setColumnsLoading,
  };
};
