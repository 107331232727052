import React, { useEffect, useState } from 'react';
import { initSmallPaging, VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import Button from '../../../../core/components/button/Button';
import DraftEntitiesContent from './DraftEntitiesContent';
import SpecificEntitiesContent from './SpecificEntitiesContent';
import './SelectInvoicingEntitiesForExport.scss';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getInvoiceDocumentsOrderingThunk } from '../../../../store/thunks/core/coreOrderingThunks';
import { useDefaultPagingAndOrdering } from '../../../../core/hooks/useDefaultPagingAndOrdering';
import { invoiceDocumentsFiltersSelector } from '../../../../store/selectors/invoicingSelectors';
import { invoiceDocumentsOrderingSelector } from '../../../../store/selectors/coreOrderingSelectors';
import { setInvoiceDocuments, setInvoiceDocumentsFilters } from '../../../../store/slices/invoicingSlice';
import { getCustomersDictionaryThunk, getSuppliersDictionaryThunk } from '../../../../store/thunks/shared/sharedThunks';
import { getInvoiceDocumentStatusesThunk } from '../../../../store/thunks/core/coreStatusesThunks';
import { initInvoiceDocumentsData } from '../../../invoicing-purchases/types/InvoicingPurchasesTypes';
import { InvoiceDocumentsOrdering } from '../../../container/enums/InvoicingSharedEnums';
import { getInvoiceDocumentsThunk } from '../../../../store/thunks/invoicing/commonInvoicesThunks';
import { getInvoiceDocumentTypesThunk } from '../../../../store/thunks/coreThunk';
import { invoiceDocumentStatusValuesSelector } from '../../../../store/selectors/coreStatusesSelectors';

type SelectInvoicingEntitiesForExportProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  type: 'purchases' | 'sales',
}

enum ModalContent {
  Draft = 'Draft',
  Specific_Entities = 'Specific entities',
}

const SelectInvoicingEntitiesForExport: React.FC<SelectInvoicingEntitiesForExportProps> = ({ visible, onCancel, type }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(invoiceDocumentsFiltersSelector);
  const ordering = useAppSelector(invoiceDocumentsOrderingSelector);
  const { draft } = useAppSelector(invoiceDocumentStatusValuesSelector);
  const [contentType, setContentType] = useState(ModalContent.Draft);

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters,
    ordering,
    initialPaging: initSmallPaging,
    getOrderingThunk: getInvoiceDocumentsOrderingThunk,
    initialOrdering: InvoiceDocumentsOrdering.CreationDate,
    setFiltersAction: setInvoiceDocumentsFilters,
  });

  useEffect(() => {
    dispatch(getInvoiceDocumentTypesThunk());
    dispatch(getSuppliersDictionaryThunk({ filters: undefined }));
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getInvoiceDocumentStatusesThunk());
    // eslint-disable-next-line
  }, []);

  const cleanUpHandle = () => {
    dispatch(setInvoiceDocumentsFilters({
      ...initSmallPaging,
      ...defaultPagingAndOrdering,
      operationTypes: filters.operationTypes,
    }));
    dispatch(setInvoiceDocuments(initInvoiceDocumentsData));
  };

  useEffect(() => {
    cleanUpHandle();
    // eslint-disable-next-line
  }, [contentType]);

  useEffect(() => {
    if (visible) {
      dispatch(getInvoiceDocumentsThunk({
        filters: {
          ...filters, statuses: contentType === ModalContent.Draft ? [draft as number] : undefined,
        },
        type,
      }));
    }
    // eslint-disable-next-line
  }, [visible, filters, draft]);

  const closeHandle = () => {
    onCancel();
    cleanUpHandle();
  };

  return (
    <Modal
      title="Select entities for export"
      visible={visible}
      onCancel={closeHandle}
      width={970}
      destroyOnClose
      afterClose={() => {
        setContentType(ModalContent.Draft);
      }}
    >
      <div className="entitiesForExport__buttons">
        <Button
          label={ModalContent.Draft}
          onClick={() => setContentType(ModalContent.Draft)}
          isActivated={contentType === ModalContent.Draft}
          htmlType="button"
          designType="light-inverse"
          offsetRight
        />
        <Button
          label={ModalContent.Specific_Entities}
          onClick={() => setContentType(ModalContent.Specific_Entities)}
          isActivated={contentType === ModalContent.Specific_Entities}
          htmlType="button"
          designType="light-inverse"
        />
      </div>
      {contentType === ModalContent.Draft
        ? <DraftEntitiesContent onCancel={closeHandle} origin={type} />
        : <SpecificEntitiesContent onCancel={closeHandle} type={type} />}
    </Modal>
  );
};

export default SelectInvoicingEntitiesForExport;
