import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { EmployeeLeaveRequestFilters, LeaveRequestDto } from '../../../common/types/commonTypes';
import {
  setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import { RootState } from '../../store';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setEmployeesLeaveRequestsFilters } from '../../slices/employeesSlice';
import {
  PostLeaveRequestRestModel,
  PutLeaveRequestRestModel,
} from '../../../account/components/account-content/leave-requests/types/AccountLeaveRequestsTypes';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';

export const getEmployeesLeaveRequestsThunk = createAsyncThunk<ResponseResult<LeaveRequestDto[]>,
{ filters: EmployeeLeaveRequestFilters }
>(
  'get/EmployeesLeaveRequests',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeLeaveRequests(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeEmployeesLeaveRequestStatusThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, statusTransition: number }
>(
  'put/EmployeesLeaveRequestStatus',
  async ({ id, statusTransition }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.changeEmployeeLeaveRequestStatus(id, statusTransition);
      dispatch(setLoading(false));
      const { employees: { leaveRequestsFilters } } = getState() as RootState;
      dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, page: 1 }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeLeaveRequestThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/EmployeeLeaveRequest',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.deleteEmployeeLeaveRequest(id);
      dispatch(setFetching(false));
      const { employees: { leaveRequestsFilters } } = getState() as RootState;
      if (leaveRequestsFilters.page > 1) {
        dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, page: 1 }));
      } else {
        dispatch(getEmployeesLeaveRequestsThunk({ filters: leaveRequestsFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEmployeeLeaveRequestThunk = createAsyncThunk<ResponseSingleResult<{createdId: number}>,
{ data: PostLeaveRequestRestModel, type: string, closeModal: VoidFunctionType }
>(
  'post/EmployeeLeaveRequest',
  async ({
    data, type, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.postEmployeeLeaveRequest(data);
      const { employees: { leaveRequestsFilters } } = getState() as RootState;
      if (leaveRequestsFilters.page > 1 || !leaveRequestsFilters.isActive) {
        dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, isActive: true, page: 1 }));
      } else {
        dispatch(getEmployeesLeaveRequestsThunk({ filters: leaveRequestsFilters }));
      }
      dispatch(setSuccessMessage({ message: `Leave request "${type}" was successfully created.`, toastId: Math.random() }));
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeEmployeeLeaveRequestThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, data: PutLeaveRequestRestModel, closeModal: VoidFunctionType }
>(
  'put/EmployeeLeaveRequest',
  async ({
    id, data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.putEmployeeLeaveRequest(id, data);
      dispatch(setFetching(false));
      const { employees: { leaveRequestsFilters } } = getState() as RootState;
      dispatch(getEmployeesLeaveRequestsThunk({ filters: leaveRequestsFilters }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);
