import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, supplierSpendDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';

const SupplierSpendBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { totalCount, items } = useAppSelector(supplierSpendDataSelector);

  const nowrapKeys = ['costNet', 'costGross', 'purchaseOrderDate'];
  const truncateKeys = ['supplier', 'purchaseOrderNumber', 'categories', 'subcategories'];

  return (
    <Tbody>
      {totalCount > 0
        ? items.map((el, i) => (
          <Tr key={i}>
            {Object.entries(el).map(([key, value]) => {
              return <TdNew key={key} nowrap={nowrapKeys.includes(key)}>
                {truncateKeys.includes(key)
                  ? <TruncateTooltip value={value} table />
                  : value}
              </TdNew>;
            })}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default SupplierSpendBody;
