import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faPoundSign, faShare, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import Tr from '../../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import QuoteLineCell from './QuoteLineCell';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  enquiryAcceptedOrRejectedSelector, openedVehicleIdSelector,
  quoteVehiclesListSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import { BooleanFunctionType, NumberFunctionType } from '../../../../../../core/types/coreTypes';
import { currentUserSelector } from '../../../../../../store/selectors/accountSelectors';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { putQuotePartsKitLineThunk } from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import { unknownPriceSourceSelector } from '../../../../../../store/selectors/coreSelectors';

export type LineCellType = {
  id: number,
  number: number,
  quoteCategory: string | undefined,
  productNumber: string,
  productName: string,
  quantity: number,
  unitPrice: string,
  netPrice: string,
  tax: number | string,
  weight: number,
  productWeight: number | null,
  notes?: string | null,
  discount?: number | null,
  isProductSuitable?: boolean | null,
  isUsualLine?: boolean,
  partsKitId?: number | null,
  partId?: number | null,
  unitOfMeasure?: number,
  priceSources?: number[] | string,
}

type QuoteLinesCellsProps = {
  linesArr: LineCellType[],
  setLineId: (value: number | undefined) => void,
  setCopyLineModal: BooleanFunctionType;
  checkedKeys: number[],
  checkRows: NumberFunctionType,
  columns: ICustomColumn[],
  editQty: NumberFunctionType,
  openCustomModal: NumberFunctionType,
  openDeleteModal: NumberFunctionType,
}

const QuoteLinesCells: React.FC<QuoteLinesCellsProps> = ({
  linesArr, setLineId, setCopyLineModal, checkedKeys, checkRows, openCustomModal,
  columns, editQty,
  openDeleteModal,
}) => {
  const dispatch = useAppDispatch();
  const allowedToManage = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const quoteVehiclesList = useAppSelector(quoteVehiclesListSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const openedVehicleId = useAppSelector(openedVehicleIdSelector);
  const unknownPriceSource = useAppSelector(unknownPriceSourceSelector);

  const deleteLine = (key: number) => {
    openDeleteModal(key);
  };

  const valueRenderer = (key: string, value: string | number) => {
    const found = columns.find((col) => col.dataIndex === key);
    if (found?.prefix) return `${found?.prefix} ${value}`;
    if (found?.suffix) return `${value} ${found?.suffix}`;
    if (found?.isLink) return <Link to={`${found.isLink}/${value}`}>{value}</Link>;
    else return value;
  };

  const actionItems = useCallback((id: number, isPartsKitLine: number | null | undefined, priceSources?: number[]) => {
    const isUnknownPriceSource = priceSources?.find((el) => el === unknownPriceSource?.value);
    const refreshPriceAction = {
      label: 'Refresh prices',
      icon: <FontAwesomeIcon icon={faPoundSign} />,
      key: 'refreshPrices',
      onClick: () => {
        openedVehicleId && dispatch(putQuotePartsKitLineThunk({
          quoteLineId: id,
          quoteVehicleId: openedVehicleId,
        }));
      },
    };
    const basicList = [
      {
        label: 'Copy line to',
        icon: <FontAwesomeIcon icon={faShare} />,
        key: 'copyLine',
        onClick: () => {
          if (!!quoteVehiclesList && quoteVehiclesList?.length >= 2) {
            setLineId(id);
            setCopyLineModal(true);
          }
        },
        disabled: !!quoteVehiclesList && quoteVehiclesList?.length < 2,
      },
      {
        label: 'Delete line',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        key: 'deleteLine',
        onClick: () => deleteLine(id),
      },
    ];
    const listWithRefresh = [refreshPriceAction, ...basicList];
    return (isPartsKitLine && !!isUnknownPriceSource) ? listWithRefresh : basicList;
    // eslint-disable-next-line
  }, [currentUser, quoteVehiclesList]);

  return (
    <>
      {linesArr.map((d, i) => (
        <Tr key={i} className={classNames({ 'suitableLine': d.isUsualLine && d.isProductSuitable !== null && !d.isProductSuitable })}>
          {allowedToManage && (
            <TdNew>
              <Checkbox
                disabled={enquiryAcceptedOrRejected}
                checked={checkedKeys.includes(d.id)}
                onChange={() => checkRows(d.id)}
                table
              />
            </TdNew>
          )}
          <QuoteLineCell
            data={d}
            valueRenderer={(key: string, value: string | number) => valueRenderer(key, value)}
            disabled={enquiryAcceptedOrRejected || !allowedToManage}
            editQty={editQty}
            openCustomModal={openCustomModal}
            allowedToManage={allowedToManage}
          />
          {allowedToManage && (
            <TdNew action>
              <Dropdown
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                menuItems={actionItems(d.id, d.partsKitId, typeof d.priceSources !== 'string' ? d.priceSources : undefined)}
                disabled={enquiryAcceptedOrRejected}
              />
            </TdNew>
          )}
        </Tr>))}
    </>
  );
};

export default QuoteLinesCells;
