export enum EmployeeViewModeParamsEnum {
  EMPLOYEE_SKILLS = 'skills',
  EMPLOYEE_ICE_CONTACT = 'ice-contact',
  EMPLOYEE_EMPLOYMENT = 'employment',
  EMPLOYEE_LEAVE_REQUESTS = 'leave-requests',
  EMPLOYEE_ACTIVITY = 'activity',
  EMPLOYEE_ATTACHMENTS = 'attachments',
}

export enum EmployeeViewModeLabelsEnum {
  EMPLOYEE_SKILLS = 'Skills',
  EMPLOYEE_ICE_CONTACT = 'ICE contact',
  EMPLOYEE_EMPLOYMENT = 'Employment',
  EMPLOYEE_LEAVE_REQUESTS = 'Leave requests',
  EMPLOYEE_ACTIVITY = 'Activity',
  EMPLOYEE_ATTACHMENTS = 'Attachments',
}

export const EmployeeContentTabs = [
  EmployeeViewModeParamsEnum.EMPLOYEE_SKILLS,
  EmployeeViewModeParamsEnum.EMPLOYEE_ICE_CONTACT,
  EmployeeViewModeParamsEnum.EMPLOYEE_EMPLOYMENT,
  EmployeeViewModeParamsEnum.EMPLOYEE_LEAVE_REQUESTS,
  EmployeeViewModeParamsEnum.EMPLOYEE_ACTIVITY,
  EmployeeViewModeParamsEnum.EMPLOYEE_ATTACHMENTS,
];

export enum EmployeeEventTypesEnum {
  EmployeeCreation = 'EmployeeCreation',
  EmployeeFieldUpdateOrAddingValue = 'EmployeeFieldUpdateOrAddingValue',
  EmployeeStatusChange = 'EmployeeStatusChange',
  Note = 'Note',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  EmploymentCreation = 'EmploymentCreation',
  EmploymentFieldUpdateOrAddingValue = 'EmploymentFieldUpdateOrAddingValue',
  EmploymentDeletion = 'EmploymentDeletion',
  EmployeeDeletion = 'EmployeeDeletion',
  LeaveRequestCreation = 'LeaveRequestCreation',
  LeaveRequestFieldUpdateOrAddingValue= 'LeaveRequestFieldUpdateOrAddingValue',
  LeaveRequestStatusChange = 'LeaveRequestStatusChange',
  LeaveRequestDeletion = 'LeaveRequestDeletion',
}
