import React, { useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import Input from '../../../core/components/input/Input';
import RequisiteSection from '../../../common/components/requisite/RequisiteSection';
import RequisiteModal from '../../../common/components/requisite/RequisteModal';
import { initRequisite } from '../../../common/utils/data';
import { RequisiteDto } from '../../../common/types/commonTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useAppDispatch } from '../../../store/hooks';
import { createSupplierThunk } from '../../../store/thunks/suppliers/suppliersCreateThunks';
import { supplierDefaults, SupplierFields, SupplierSchema } from '../utils/SupplierSchema';

const SupplierForm:React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [isVisible, setIsVisible] = useState(false);
  const [requisites, setRequisites] = useState<RequisiteDto>(initRequisite);

  const {
    control, handleSubmit, reset, setError, setValue,
  } = useForm<SupplierFields>({
    resolver: zodResolver(SupplierSchema),
    defaultValues: supplierDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const onSubmit = (values: SupplierFields) => {
    const trimmedValues = {
      ...values,
      name: values.name.trim(),
      supplierAccountNumber: values.supplierAccountNumber.trim(),
    };
    dispatch(createSupplierThunk({
      data: {
        ...trimmedValues,
        billingDetails: requisites,
      },
      navigate,
      setError,
      reset,
    }));
  };
  return (
    <>
      <RequisiteModal
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        submitHandle={(values) => {
          setRequisites(values);
          setIsVisible(false);
        }}
        isOptionalFields
        requisite={requisites}
      />
      <form className="formWrap details-form create-form" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="name"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('name', '');
                }
              }}
              error={errors.name?.message}
              label="Supplier name"
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="supplierAccountNumber"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              error={errors.supplierAccountNumber?.message}
              label="Supplier account No"
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="vatNumber"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value.toUpperCase());
              }}
              error={errors.vatNumber?.message}
              label="VAT number"
            />
          )}
        />
        <Controller
          control={control}
          name="companyRegistrationNumber"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => field.onChange(e.target.value.toUpperCase())}
              error={errors.companyRegistrationNumber?.message}
              label="Company registration number"
            />
          )}
        />
        <RequisiteSection
          title="Billing details"
          setIsVisible={() => setIsVisible(true)}
          requisiteDetails={requisites || null}
        />
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          cancelClick={() => {
            reset();
            setRequisites(initRequisite);
          }}
          className="details-form__actions"
        />
      </form>
    </>
  );
};

export default SupplierForm;
