import React from 'react';
import { Checkbox as CheckboxInput } from 'antd';
import classnames from 'classnames';
import { BooleanFunctionType } from '../../types/coreTypes';
import './Checkbox.scss';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';

type CheckboxProps = {
  value?: string | number | boolean;
  checked?: boolean;
  indeterminate?: boolean,
  onChange?: BooleanFunctionType;
  label?: React.ReactNode;
  truncateLabel?: boolean,
  disabled?: boolean;
  className?: string;
  table?: boolean;
}

const Checkbox: React.FC<CheckboxProps> = ({
  checked,
  indeterminate,
  onChange,
  value,
  label,
  truncateLabel,
  disabled,
  className,
  table,
}) => (
  <CheckboxInput
    onChange={(e) => onChange && onChange(e.target.checked)}
    checked={checked}
    indeterminate={indeterminate}
    value={value}
    disabled={disabled}
    className={classnames(className, 'checkbox', { 'checkbox--table': table })}
  >
    {label && truncateLabel
      ? <TruncateTooltip
        className="checkbox-label checkbox-label--truncated"
        value={label as string}
        mouseLeaveDelay={0}
        mouseEnterDelay={0}
        overlayClassName="checkbox__tooltip"
      />
      : <span className="checkbox-label">{label}</span>}
  </CheckboxInput>
);

export default Checkbox;
