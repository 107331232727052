import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faEnvelope, faRedo, faUserCheck, faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import Layout from '../../core/components/layout/Layout';
import UserHead from './components/user-head/UserHead';
import { userProfileSelector } from '../../store/selectors/settingsSelectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deleteUserAccountThunk, getUserAccountThunk } from '../../store/thunks/settings/userViewPageThunks';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import ResetUserPasswordModal from './components/modals/ResetUserPasswordModal';
import ActivateUserModal from './components/modals/ActivateUserModal';
import InactivateUserModal from './components/modals/InactivateUserModal';
import InviteUserModal from './components/modals/InviteUserModal';
import {
  inactiveStatusSelector,
  ineligibleActivateStatusesSelector,
  ineligibleInactivateStatusesSelector,
  ineligibleInviteUsersStatusesSelector,
  ineligibleResetUsersStatusesSelector,
} from '../../store/selectors/coreStatusesSelectors';
import { setUserProfile } from '../../store/slices/settingsSlice';
import UserContent from './components/user-content/UserContent';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import DeleteLinkedUserModal from '../users-dashboard/components/settings-content/users-list/DeleteLinkedUserModal';
import usePermission from '../../permissions-handling/permissionHook';

const UserProfileContainer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { backHandle } = useLocationHistoryState(true);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const userProfile = useAppSelector(userProfileSelector);
  const ineligibleResetUsersStatuses = useAppSelector(ineligibleResetUsersStatusesSelector);
  const ineligibleInviteUsersStatuses = useAppSelector(ineligibleInviteUsersStatusesSelector);
  const ineligibleActivateStatuses = useAppSelector(ineligibleActivateStatusesSelector);
  const ineligibleInactivateStatuses = useAppSelector(ineligibleInactivateStatusesSelector);
  const inactiveStatus = useAppSelector(inactiveStatusSelector);
  const allowedToDeleteEmployee = usePermission(PermissionEnum.EmployeeDelete);

  const [isInvite, setIsInvite] = useState(false);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [isActivate, setIsActivate] = useState(false);
  const [isInactivate, setIsInactivate] = useState(false);
  const [isDeleteLinkedUser, setIsDeleteLinkedUser] = useState(false);

  const handleDeleteClick = () => {
    if (allowedToDeleteEmployee) {
      const linkedAccountId = userProfile?.employeeId;
      linkedAccountId ? setIsDeleteLinkedUser(true) : setIsConfirm(true);
    } else {
      setIsConfirm(true);
    }
  };

  const handleDeleteUser = (deleteLinkedAccount: boolean) => {
    const linkedAccountId = userProfile?.employeeId;
    if (deleteLinkedAccount && linkedAccountId) {
      userProfile?.id && dispatch(deleteUserAccountThunk({
        id: userProfile?.id,
        deleteLinkedAccount,
        linkedAccountId,
        onClose: () => setIsDeleteLinkedUser(false),
        navigate,
      }));
    } else {
      userProfile?.id && dispatch(deleteUserAccountThunk({
        id: userProfile?.id,
        onClose: () => setIsDeleteLinkedUser(false),
        navigate,
      }));
    }
  };

  const actions = useMemo(() => [
    {
      label: 'Send invitation',
      key: '23',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      onClick: () => setIsInvite(true),
      disabled: !!ineligibleInviteUsersStatuses.find((el) => el.value === userProfile?.status) || !!(id && currentUser?.id === +id),
      restrictedTo: PermissionEnum.UserEditFields,
    },
    {
      label: 'Reset password',
      key: 'd3',
      icon: <FontAwesomeIcon icon={faRedo} />,
      onClick: () => setIsReset(true),
      disabled: !!ineligibleResetUsersStatuses.find((el) => el.value === userProfile?.status) || !!(id && currentUser?.id === +id),
      restrictedTo: PermissionEnum.UserEditFields,
    },
    {
      label: 'Activate account',
      key: '4f3',
      icon: <FontAwesomeIcon icon={faUserCheck} />,
      onClick: () => setIsActivate(true),
      disabled: !!ineligibleActivateStatuses.find((el) => el.value === userProfile?.status)
          || (userProfile?.status === inactiveStatus?.value && !userProfile?.wasActive)
          || !!(id && currentUser?.id === +id),
      restrictedTo: PermissionEnum.UserEditFields,
    },
    {
      label: 'Inactivate account',
      key: '3rf',
      icon: <FontAwesomeIcon icon={faUserSlash} />,
      onClick: () => setIsInactivate(true),
      disabled: !!ineligibleInactivateStatuses.find((el) => el.value === userProfile?.status) || !!(id && currentUser?.id === +id),
      restrictedTo: PermissionEnum.UserEditFields,
    },
    {
      label: 'Delete user',
      key: '3fr4',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: handleDeleteClick,
      restrictedTo: PermissionEnum.UserDelete,
      disabled: !!(id && currentUser?.id === +id),
    },
    // eslint-disable-next-line
  ], [
    ineligibleInviteUsersStatuses,
    ineligibleResetUsersStatuses,
    ineligibleActivateStatuses,
    userProfile?.wasActive,
    userProfile?.status,
    ineligibleInactivateStatuses,
    currentUser?.id,
    id,
    inactiveStatus?.value,
  ]);
  useEffect(() => {
    id && dispatch(getUserAccountThunk({ id: +id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setUserProfile(null));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout subHeader={
      <DetailsHead goBack={backHandle} actions={actions}>
        <UserHead user={userProfile} />
      </DetailsHead>
    }
    >
      <InviteUserModal
        isVisible={isInvite}
        onCancel={() => setIsInvite(false)}
        userName={`${userProfile?.firstName} ${userProfile?.lastName}`}
        userId={id ? +id : undefined}
      />
      <ActivateUserModal
        isVisible={isActivate}
        onCancel={() => setIsActivate(false)}
        userName={`${userProfile?.firstName} ${userProfile?.lastName}`}
        userId={id ? +id : undefined}
      />
      <InactivateUserModal
        isVisible={isInactivate}
        onCancel={() => setIsInactivate(false)}
        userName={`${userProfile?.firstName} ${userProfile?.lastName}`}
        userId={id ? +id : undefined}
      />
      <ResetUserPasswordModal
        isVisible={isReset}
        onCancel={() => setIsReset(false)}
        userName={`${userProfile?.firstName} ${userProfile?.lastName}`}
        userId={id ? +id : undefined}
      />
      <ConfirmationModal
        isVisible={isConfirm}
        onCancel={() => setIsConfirm(false)}
        confirmHandler={() => userProfile?.id && dispatch(deleteUserAccountThunk({
          id: userProfile?.id,
          onClose: () => setIsConfirm(false),
          navigate,
        }))}
      />
      <DeleteLinkedUserModal
        isVisible={isDeleteLinkedUser}
        onCancel={() => setIsDeleteLinkedUser(false)}
        deleteUserClick={(deleteLinkedAccount) => handleDeleteUser(deleteLinkedAccount)}
      />
      <UserContent userProfile={userProfile} />
    </Layout>
  );
};

export default UserProfileContainer;
