import React, { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import isEqual from 'lodash/isEqual';
import Tr from '../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import {
  initPaging, SearchTableFilterType, VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { usersListFiltersSelector } from '../../../../../store/selectors/settingsSelectors';
import { setListFilters } from '../../../../../store/slices/settingsSlice';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import { ICustomColumn } from '../../../../../common/types/commonTypes';

type UserListColumnsProps = {
  userColumns: ICustomColumn[];
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
  totalElements: number;
  allowedToDelete?: boolean,
}

const UserListColumns: React.FC<UserListColumnsProps> = ({
  userColumns, isAll, isIndeterminate, checkAll, totalElements, allowedToDelete,
}) => {
  const dispatch = useAppDispatch();
  const usersListFilters = useAppSelector(usersListFiltersSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    email: usersListFilters.email,
    name: usersListFilters.name,
    refNo: usersListFilters.refNo,
  });

  useEffect(() => {
    if (isEqual(usersListFilters, initPaging)) {
      setSearchVl({ email: undefined, name: undefined, refNo: undefined });
    }
  }, [usersListFilters]);

  const filters = [
    {
      dataIndex: 'canSeeOnlyAssignedEntities',
      value: usersListFilters?.canSeeOnlyAssignedEntities || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const newFilters = {
          ...usersListFilters,
          canSeeOnlyAssignedEntities: checkedValues.length > 0 ? checkedValues as boolean[] : undefined,
        };
        dispatch(setListFilters(newFilters));
      },
    },
  ];

  const sortHandle = (field: number) => {
    const newFilters = usersListFilters.order?.field === field
      ? { ...usersListFilters, order: { field, isAsc: !usersListFilters.order.isAsc } }
      : { ...usersListFilters, order: { field, isAsc: true } };
    dispatch(setListFilters(newFilters));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const newFilters = {
        ...usersListFilters,
        page: 1,
        [field]: undefined,
      };
      dispatch(setListFilters(newFilters));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const newFilters = {
      ...usersListFilters,
      page: 1,
      [field]: searchVl[field]?.trim(),
    };
    dispatch(setListFilters(newFilters));
  };
  return (
    <Tr>
      {allowedToDelete && (
        <ThNew checkbox>
          <Checkbox checked={isAll} onChange={checkAll} disabled={totalElements === 0} indeterminate={isIndeterminate} table />
        </ThNew>
      )}
      {userColumns.map((col, i) => <ThWithControls
        key={i}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={totalElements}
        filters={filters}
        searchVl={searchVl}
        tableFilters={usersListFilters}
        className={`usersListTh--${col.dataIndex}`}
      />)}
    </Tr>
  );
};

export default UserListColumns;
