import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import {
  DictionaryItem, initDataList, initPaging, initSmallPaging, OrderingType, PagingType, PagingWithOrdering,
} from '../../core/types/coreTypes';
import { VehicleProfileSearchType } from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { PartQtyType } from '../../stock/part-view-page/types/types';
import { PartRequestLinePurchaseOrderDto } from '../../stock/stock-part-requests/types/stockPartRequestTypes';
import { PartDtoType, StockFiltersType } from '../../stock/search/types/stockSearchTypes';
import { PartsKitsDto, PartsKitsFiltersType } from '../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { InvoicingOperationLineNominalRecordDto } from '../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';

export type EntityType =
  'Customer' |
  'Order' |
  'Rectification' |
  'Job' |
  'Employee' |
  'Part' |
  'Parts kit' |
  'Stock purchase order' |
  'Invoicing purchase order' |
  'Pricelist' |
  'Part request' |
  'Enquiry' |
  'Supplier' |
  'Vehicle' |
  'User account'

export type RequisiteDto = {
  firstName: string | null,
  lastName: string | null,
  email: string | null,
  mobile: string | null,
  telephone: string | null,
  companyName: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  region: string | null,
  postalCode: string | null,
}

export type TabOptionType = {
  label: string,
  value: string,
  restrictedTo: PermissionEnum,
  disabled?: boolean,
}

export type DropdownActionType = {
  label: string,
  key: string,
  icon: JSX.Element,
  onClick: () => void,
  restrictedTo: PermissionEnum,
  disabled?: boolean,
}

export type AffectedEntityType = {
  businessArea?: number | string,
  businessAreas?: number[],
  createdAt: string,
  entityNumber: string,
  entityStatus: string | null | number,
  entityType: string | null,
  partLinesCount?: number | null,
}

export type AffectedEntityLineUi = {
  entityId: number,
  entityType: EntityType,
  entityNumber: string,
  createdAt: string,
  entityStatus: string,
  businessArea?: string,
  partLinesCount?: number,
}

export interface AddContactFields {
  firstName: string,
  lastName: string,
  position: string,
  email: string,
  telephone: string,
  mobile: string,
}

export type AddressFields = {
  companyName?: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  region: string | null,
  postalCode: string | null,
  telephone?: string | null,
}

type JobAddressCoordinates = {
  jobAddressLatitude: number | null,
  jobAddressLongitude: number | null,
}

export type DefaultAddressDto = AddressFields & { id: number } & JobAddressCoordinates

export const initAddressFields = {
  companyName: null,
  addressLine1: null,
  addressLine2: null,
  cityTown: null,
  region: null,
  postalCode: null,
  telephone: null,
};

export type DeliveryFields = {
  deliveryCompanyName: string | null,
  deliveryAddressLine1: string | null,
  deliveryAddressLine2: string | null,
  deliveryCityTown: string | null,
  deliveryRegion: string | null,
  deliveryPostalCode: string | null,
  deliveryTelephone: string | null,
}

export type SuitableVehicleProfileDto = {
  id: number,
  code: string | null,
  manufacturer: string | null,
  modelDescription: string | null,
}

export type LinkedCardEntityType = {
  label: string,
  icon: IconDefinition,
  value: string | null,
  current?: number,
  max?: number,
}

export type DraggableCardType = {
  id: number,
  entityNumber: string | null,
  customerName: string | null,
  customerType?: number,
  // TODO: change naming after BE updates
  jobCategory?: string | string[],
  jobType: number,
  priorityLevel: number,
  cost?: string | null,
  assigneeFullName: string | null,
  assigneesList?: Array<string>,
  isAssigneeDeleted: boolean,
  warnings: number[] | null,
  vehicleStatus?: number | null,
  isPartRequestSubmitted?: boolean,
  readyCollectedPartsCount?: number,
  submittedPartsCount?: number,
  completedJobsCount?: number,
  totalJobsCount?: number,
  isFreeRectification?: boolean,
  linkedEntities?: Array<LinkedCardEntityType>,
  timeTracking?: Array<{ label: string, value: string }>
  showDraftVehicleWarning?: boolean,
  dueDate?: string | null,
}

export type SkillLookupDto = {
  id: number,
  name: string | null,
  categoryName: string | null,
  dropdownMakedValue: string | null,
}

export type DraftSkillLine = {
  id: number,
  number?: number,
  name: string | null,
  categoryName: string | null,
  level: number,
}

export type OrderDictionaryFiltersType = {
  keyword?: string,
  includeOrderNumber?: boolean,
  includeCustomerName?: boolean,
  includeEnquiryNumbers?: boolean,
  onlyInStatusAvailableForJobCreation?: boolean,
  notInSupplyOnlyJobType?: boolean,
  onlyActive?: boolean,
}

export type OrderDictionaryDto = {
  id: number,
  orderNumber: string | null,
  customerName: string | null,
}

export type FetchRectificationsDictionaryParamsType = {
  keyword?: string,
  includeRectificationNumber?: boolean,
  includeCustomerName?: boolean,
  includeEnquiryNumbers?: boolean,
  onlyAvailableForJobCreation?: boolean,
}

export type RectificationDictionaryDto = {
  id: number,
  rectificationNumber: string | null,
  customerName: string | null,
}

export type PatchProductSourceType = {
  stockQuantity: number | null;
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  takeFromStock: boolean | null,
  useManualInput: boolean | null,
  reason: string | null,
}

type PartProductSourcePricelistTotalResponseDto = {
  pricelistId: number,
  unitPriceString: string | null,
  netPriceString: string | null,
}

export type PartProductSourceDictionaryParams = {
  businessArea: number,
  customerId: number,
  priceDate: string,
}

export type PartProductSourceTotalFetchParams = {
  stockQuantity: number | null,
  pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  priceDate: string,
}

export type PartProductSourceTotalParams = Omit<PartProductSourceTotalFetchParams, 'priceDate'>;

export type PartProductTotalType = {
  stockNetPrice: string | null,
  pricelistTotals: PartProductSourcePricelistTotalResponseDto[],
  manualInputNetPrice: string | null,
  totalQuantity: number,
  totalUnitPrice: string | null,
  minUnitPrice: string | null,
  maxUnitPrice: string | null,
  totalNetPrice: string | null,
}

export type SourcePricelistDto = {
  id: number,
  name: string | null,
  unitPrice: number,
}

export type PartProductDictionaryType = {
  stockQuantityAvailable: number,
  stockQuantitySelected?: number,
  minStockUnitPrice?: number,
  maxStockUnitPrice?: number,
  pricelists: Array<SourcePricelistDto> | null
}

export enum ProductSourceEnum {
  STOCK = 'Stock',
  MANUAL = 'Manual',
}

type PickStockFiltersForDraftPartLines = Pick<StockFiltersType,
'keyword' |
'isCorePart' |
'partCode' |
'manufacturerCode' |
'name' |
'description'
>;

export type DraftLinePartsFiltersType = Partial<PickStockFiltersForDraftPartLines> & PagingWithOrdering
export const initDraftLinePartsFilters = {
  ...initSmallPaging,
  keyword: '',
  isCorePart: [true],
  partCode: undefined,
  manufacturerCode: undefined,
  name: undefined,
  description: undefined,
  order: undefined,
};
export type DraftLinePartList = { items: Array<PartDtoType>, pages: number, totalCount: number }

type PickStockFiltersForDraftPartsKitLines = Pick<PartsKitsFiltersType,
'keyword' |
'partsKitCode' |
'name' |
'description'
>;

export type DraftLinePartsKitFiltersType = Partial<PickStockFiltersForDraftPartsKitLines> & PagingWithOrdering
export const initDraftLinePartsKitFilters = {
  keyword: '',
  ...initSmallPaging,
  partsKitCode: undefined,
  name: undefined,
  description: undefined,
  order: undefined,
};

export type DraftLinePartsKitList = { items: Array<PartsKitsDto>, pages: number, totalCount: number }

export type CustomLinePartDto = {
  productName: string | null;
  quantity: number;
  unitOfMeasure: number
  unitPrice: string | null;
  tax: number | null;
  quoteCategoryId: number | null;
  weight: number | null;
}

export interface CustomLineReqType {
  productName: string,
  quantity: number,
  unitOfMeasure: number,
  unitPrice: number,
  tax: number,
  quoteCategoryId: number,
  weight: number | null,
  reason?: string | null,
}

export interface CreateCustomLineReqType extends CustomLineReqType {
  quoteVehicleId: number,
}

export interface CommonCreatePartLineReqDto {
  partId: number,
  stockQuantity: number | null,
  pricelists: Array<{ pricelistId: number, quantity: number | null }> | null,
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  reason?: string | null,
}

export type CreatePartRequestPartsKitLine = {
  partsKitId: number,
  orderId?: number | null,
  rectificationId?: number | null,
  reason?: string | null,
}

export type PatchPartReqLineDto = {
  isFree?: boolean | null,
  notes?: string | null,
  quantity?: number | null,
  unitPrice?: number | null,
  discount?: number | null,
  tax?: number | null,
  weight?: number | null,
  quoteCategoryId?: number | null,
  productName?: string | null,
  unitOfMeasure?: number | null,
  stockQuantity?: number | null,
  pricelists?: Array<{
    pricelistId: number,
    quantity: number,
  }> | null,
  manualInputQuantity?: number | null,
  manualInputUnitPrice?: number | null,
  takeFromStock?: boolean | null,
  useManualInput?: boolean | null,
  reason?: string | null,
}

export type BulkPatchReqLinesDataType = {
  partRequestLineIds: number[],
  patchPartRequestLineDto: PatchPartReqLineDto,
}

export interface ExtendedTimelineFilters extends PagingType {
  mainOnly: boolean,
  source?: number,
}
export const initExtendedTimelineFilters: ExtendedTimelineFilters = {
  ...initPaging,
  mainOnly: true,
};

export interface ContactDto {
  id: number,
  customerId: number | null,
  firstName: string | null,
  lastName: string | null,
  position: string | null,
  email: string | null,
  telephone: string | null,
  mobile: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  postalCode: string | null,
  region: string | null,
}

export interface ContactEvent extends Omit<ContactDto, 'id'> {
  isDeleted: boolean,
  customerName: string | null,
  companyName: string | null,
}

export type VehicleCatalogModes = 'search' | 'found';
export interface AddVehicleRequest {
  vehicleProfileId: number | null,
  vehicleInfoSource: number,
  manufacturer: string | null,
  modelDescription: string | null,
  type: number | null,
  group: number | null,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: number | null,
  maxPayload: number | null,
  regNumber: string | null,
  chassisNumber: string | null,
}
export type VehicleProfilesUiList = { items: VehicleProfileSearchType[], totalCount: number, pages: number, groups: number[] | null };
export const initVehProfilesList = { ...initDataList, groups: null };
export const initSourceTargetProfilesFilters = { page: 1, pageSize: 2000, isSuitableForAll: false };

export type PartRequestLinePricelistDto = { id: number, quantity: number, unitPrice: number };

export type PartRequestLineDto = {
  id: number,
  type: number,
  partId: number | null,
  partsKitId: number | null,
  partsKitComponentId: number | null,
  partsKitComponentPartId: number | null,
  ordinalNumber: number | string,
  categoryId: number,
  categoryName: string | null,
  rectificationId: number | null,
  productNumber: string | null,
  productName: string | null,
  notes: string | null,
  requestedQuantity: number,
  confirmedQuantity: number,
  unitOfMeasure: number,
  status: number,
  priceSources: number[] | null,
  isFree: boolean | null,
  orderId: number | null,
  quantity: number,
  unitPrice: number,
  discount: number | null,
  netPrice: number,
  tax: number | null,
  weight: number | null,
  isProductSuitable: boolean | null,
  unitPriceString: string | null,
  netPriceString: string | null,
  stockQuantity: number | null,
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  pricelists: Array<PartRequestLinePricelistDto> | null,
  requestedPricelists: Array<PartRequestLinePricelistDto> | null,
  confirmedPricelists: Array<PartRequestLinePricelistDto> | null,
  readyQuantity: number,
  collectedQuantity: number,
  allocatedQuantity?: number,
  toBeAllocatedQuantity: number,
  requestedStockQuantity: number | null,
  confirmedStockQuantity: number | null,
  requestedManualInputQuantity: number | null,
  readyQuantities: PartQtyType[] | null,
  collectedQuantities: PartQtyType[] | null,
  suppliers?: string[] | null,
  purchaseOrders?: PartRequestLinePurchaseOrderDto[] | null,
  linkedEntityNumber: string | null,
  linkedEntityStatusString: string | null,
  customerName: string | null,
  partRequestId: number,
  partRequestNumber: string | null,
  canPurchaseOrderBeGenerated: boolean,
  deletionReason: string | null,
  submittedLineId: number | null,
  subcategoryName: string | null,
  invoiceNominalRecordsNet: number | null,
  creditNominalRecordsNet: number | null,
  unassignedNet: number | null,
  nominalRecords: Array<InvoicingOperationLineNominalRecordDto> | null,
  nominalCodes: DictionaryItem[] | null,
  jobQualityCheckStatus: number,
  areNotesEditable: boolean,
  childLines: PartRequestLineDto[] | null,
}

export type ProductSourceLine = {
  id: number,
  name: string,
  unitOfMeasure: number,
  partId?: number | null,
  partsKitComponentId?: number | null,
  partsKitComponentPartId?: number | null,
  stockQuantity: number | null,
  manualInputQuantity?: number | null,
  manualInputUnitPrice?: number | null
  pricelists: {id: number, quantity: number}[] | null,
  childLines?: ProductSourceLine[],
}

export type PartReqLineUIType = {
  id: number,
  ordinalNumber: string | number,
  categoryId: string | number,
  productNumber: string,
  productName: string,
  notes?: string,
  quantity: number,
  unitPrice: string,
  priceSources: string | number[],
  discount?: number,
  netPrice: string,
  tax: number | string,
  weight: number,
  status: string | number,
  partsKitId: number | null,
  partId: number | null,
  partsKitComponentId: number | null,
  partsKitComponentPartId: number | null,
  unitOfMeasure: number,
  isProductSuitable: boolean | null,
  isFree: boolean | null,
  childLines: Omit<PartReqLineUIType, 'childLines'>[],
}

export type PartReqLinesFilters = {
  partRequestIds?: number[],
  order?: OrderingType,
  isCustomLine?: boolean,
  categories?: number[],
  nominalCodeIds?: number[],
  productNumber?: string,
  productName?: string,
  notes?: string,
  priceSources?: number[],
  statuses?: number[],
  page?: number,
  pageSize?: number,
  purchaseOrderNumber?: string,
  onlyAvailableForPurchaseOrderGeneration?: boolean,
  jobQualityCheckId?: number,
  needToEvaluateJobQualityCheckStatus?: boolean,
  showAsPlainList?: boolean,
}

export type AfterLinesActionsParams = {
  id: number,
  partRequestId: number,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  partRequestLinesFilters: PartReqLinesFilters | null,
}

export type EmployeeLeaveRequestFilters = {
  keyword?: string,
  isActive?: boolean,
  employeeIds?: number[],
  exceptStatuses?: number[],
  leaveTypes?: number[],
  departments?: number[],
  jobTitles?: number[],
} & PagingWithOrdering

export type LeaveRequestDto = {
  id: number,
  employeeId: number,
  status: number,
  leaveType: number,
  startDate: string,
  startDateDayPart: number,
  endDate: string,
  endDateDayPart: number,
  leavePeriod: number,
  comment: string | null,
  employeeName: string | null,
  employeeJobTitle: string | null,
}

export type ValueDiffDto<T> = {
  oldValue: T | null;
  newValue: T | null;
};

export type PartRequestLineChangeGroupLineDto = {
  lineId: number,
  activityType: number | null,
  actionReason: string | null,
  parentOrdinalNumber: number | null,
  ordinalNumber: number,
  isFree: ValueDiffDto<boolean>;
  categoryName: ValueDiffDto<string>;
  productNumber: ValueDiffDto<string>;
  productName: ValueDiffDto<string>;
  notes: ValueDiffDto<string>;
  quantity: ValueDiffDto<number>;
  unitOfMeasure: ValueDiffDto<number>;
  unitPrice: ValueDiffDto<number>;
  discount: ValueDiffDto<number>;
  netPrice: ValueDiffDto<number>;
  tax: ValueDiffDto<number>;
  weight: ValueDiffDto<number>;
  status: ValueDiffDto<number>;
  priceSources: ValueDiffDto<number[]>;
  unitPriceString: ValueDiffDto<string>;
  netPriceString: ValueDiffDto<string>;
};

export type OldNewLocationEventValues = {
  oldJobCompanyName: string | null,
  oldJobAddressLine1: string | null,
  oldJobAddressLine2: string | null,
  oldJobCityTown: string | null,
  oldJobRegion: string | null,
  oldJobPostalCode: string | null,
  oldJobTelephone: string | null,
  newJobCompanyName: string | null,
  newJobAddressLine1: string | null,
  newJobAddressLine2: string | null,
  newJobCityTown: string | null,
  newJobRegion: string | null,
  newJobPostalCode: string | null,
  newJobTelephone: string | null,
}

export interface ICustomColumn {
  title: string;
  dataIndex: string;
  sorter?: boolean;
  filterSearch?: boolean;
  filters?: Array<{ label: string, value: number | string | boolean }>;
  filterName?: string;
  isFilterRange?: boolean;
  isTime?: boolean,
  rangeWithoutInputs?: boolean,
  isDateRange?: boolean;
  prefix?: string;
  suffix?: string;
  isLink?: string;
  orderField?: number,
}

export type ActivityEventBasicFieldsType<T = string | number> = {
  dateTime?: string | null,
  initiator: string | null,
  initiatorId?: number,
  // field change
  field?: string,
  // status change & field change
  oldValue?: T,
  newValue?: T,
  // attachments
  comment?: string,
  fileName?: string,
  // notes
  content?: string,
}

export type SuitableForVehiclesData = {
  code: string,
  id: number,
  manufacturer: string,
  modelDescription: string,
}
export type SuitableForVehicleGroupsData = {
  id: number,
  name: string,
  vehicleProfilesCount: number
};

export type SuitableForVehicleChangeEventData = {
  newSuitableForVehicleGroups?: SuitableForVehicleGroupsData[],
  oldSuitableForVehicleGroups?: SuitableForVehicleGroupsData[],
  newSuitableForVehicles?: SuitableForVehiclesData[],
  oldSuitableForVehicles?: SuitableForVehiclesData[],
}

export type OrderDiffVersion = {
  id: number,
  orderNumber: string | null,
  description: string | null,
  customerId: number,
  customerName: string | null,
  customerAccountNumber: string | null,
  customerType: number,
  businessArea: number,
  alwaysUseCustomerRequisites: boolean,
  assigneeId: number,
  assigneeFullName: string | null,
  assigneeFirstName: string | null,
  assigneeLastName: string | null,
  isAssigneeDeleted: boolean,
  status: number,
  cost: number,
  jobType: number,
  priorityLevel: number,
  orderDate: string,
  jobCompanyName: string | null,
  jobTelephone: string | null,
  jobAddressLine1: string | null,
  jobAddressLine2: string | null,
  jobCityTown: string | null,
  jobRegion: string | null,
  jobPostalCode: string | null,
  billingRequisiteId: number | null,
  shippingRequisiteId: number | null,
  isShippingRequisiteSameAsBilling: boolean | null,
  customerReferenceNumber: string | null,
  rejectionReason: string | null,
  xeroInvoiceId: string | null,
  xeroInvoiceUrl: string | null,
  isPartRequestSubmitted: boolean,
  vehicleInfoSource: number,
  vehicleProfileId: number | null,
  manufacturer: string | null,
  modelDescription: string | null,
  vehicleProfileType: number,
  vehicleProfileGroup: string | null,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: number,
  maxPayload: number,
  regNumber: string | null,
  chassisNumber: string | null,
  vehicleId: number | null,
  enableNotes: boolean,
  enableDiscounts: boolean,
}

export type RectificationDiffVersion = {
  id: number,
  customerId: number,
  rectificationNumber: string | null,
  linkedNumber: string | null,
  customerName: string | null,
  faultType: number,
  customerAccountNo: string | null,
  customerType: number,
  assigneeId: number | null,
  assigneeFullName: string | null,
  isAssigneeDeleted: boolean,
  rectificationType: number,
  status: number,
  cost: number,
  jobType: number,
  priorityLevel: number,
  customerReferenceNumber: string | null,
  rejectionReason: string | null,
  xeroInvoiceId: string | null,
  xeroInvoiceUrl: string | null,
  rectificationDate: string | null,
  vehicleId: number | null,
  jobTelephone: string | null,
  jobCompanyName: string | null,
  jobAddressLine1: string | null,
  jobAddressLine2: string | null,
  jobCityTown: string | null,
  jobRegion: string | null,
  jobPostalCode: string | null,
  description: string | null,
  billingRequisiteId: number | null,
  shippingRequisiteId: number | null,
  isShippingRequisiteSameAsBilling: boolean | null,
  vehicleStatus: number,
  vehicleInfoSource: number,
  vehicleProfileId: number | null,
  manufacturer: string | null,
  modelDescription: string | null,
  vehicleProfileType: number,
  vehicleProfileGroup: string | null,
  slidingDoorsQuantity: number,
  grossVehicleWeightKg: number,
  maxPayload: number,
  regNumber: string | null,
  chassisNumber: string | null,
  vehicleCompanyName: string | null,
  vehicleAddressLine1: string | null,
  vehicleAddressLine2: string | null,
  vehicleCityTown: string | null,
  vehicleRegion: string | null,
  vehiclePostalCode: string | null,
  vehicleTelephone: string | null,
}

export type GetPartRequestSummaryReviewParams = {
  id: number,
  partRequestLineIds?: number[],
  data?: PatchPartReqLineDto,
  enableDiscounts?: boolean,
  priceDate?: string,
}
