import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { invoiceNominalCodesColumnsSelector, invoiceNominalCodesFiltersSelector } from '../../../../../store/selectors/invoicingSelectors';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import { SearchTableFilterType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import { setInvoiceNominalCodesFilters } from '../../../../../store/slices/invoicingSlice';

type AddInvoiceColumnsProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  totalCount: number,
}

const AddInvoiceColumns: React.FC<AddInvoiceColumnsProps> = ({
  isAll, isIndeterminate, checkAll, totalCount,
}) => {
  const dispatch = useAppDispatch();
  const columns = useAppSelector(invoiceNominalCodesColumnsSelector);
  const tableFilters = useAppSelector(invoiceNominalCodesFiltersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: tableFilters.productNumber,
    productName: tableFilters.productName,
  });

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setInvoiceNominalCodesFilters({
        ...tableFilters,
        [field]: undefined,
        page: 1,
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };

  const searchPressHandle = (field: string) => {
    dispatch(setInvoiceNominalCodesFilters({
      ...tableFilters,
      [field]: searchVl[field]?.trim(),
      page: 1,
    }));
  };

  const filtersList = [
    {
      dataIndex: 'nominalCodeIds',
      value: tableFilters?.nominalCodeIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const newValues = {
          ...tableFilters,
          nominalCodeIds: checkedValues.length ? checkedValues as number[] : undefined,
        };
        dispatch(setInvoiceNominalCodesFilters(newValues));
      },
    },
  ];

  return (
    <Thead>
      <Tr>
        <ThNew checkbox>
          <Checkbox
            disabled={!totalCount}
            checked={isAll}
            indeterminate={isIndeterminate}
            onChange={checkAll}
            table
          />
        </ThNew>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            totalElements={totalCount}
            tableFilters={tableFilters}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            filters={filtersList}
            insideModal
            className={`addInvoiceNominalCodesTh--${col.dataIndex}`}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default AddInvoiceColumns;
