import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DataListType, initDataList, initPaging, PagingType, ResponseResult,
} from '../../core/types/coreTypes';
import {
  RectificationReportFilters,
  GetSalesRectificationReportResponse,
  SalesRectificationReportData,

} from '../../reports/types/RectificationReportsTypes';
import {
  getFitmentTimeReportChartThunk,
  getFitmentTimeReportThunk,
  getFullStockReportThunk,
  getNumberOfConvertedVehiclesReportThunk, getPurchasingFitterReturnsReportThunk,
  getPurchasingOutstandingOverduePurchaseOrdersThunk,
  getRectificationsByProductReportThunk,
  getRectificationsBySupplierReportThunk,
  getSalesOfficeEmployeeReportChartThunk,
  getSalesOfficeEmployeeReportThunk,
  getSalesRectificationReportThunk,
  getSalesByCustomerReportThunk,
  getSoldProductsByCustomerReportThunk,
  getSupplierSpendReportThunk,
  getUnfinishedEntitiesReportThunk, getSalesOffsiteWorkingReportThunk, getCancelledLinesReportThunk,
} from '../thunks/reports/reportsThunks';
import { initSalesOfficeEmployeeReportData } from '../../reports/components/reports-content/sales/sales-office-employee/utils/data';
import {
  initRectificationReportData,
} from '../../reports/components/reports-content/sales/rectification/utils/data';
import {
  initRectificationsByProductReportData,
} from '../../reports/components/reports-content/sales/rectifications-by-product/utils/data';
import {
  GetUnfinishedEntitiesReportDto,
  GetSalesUnfinishedEntitiesReportResponse,
  UnfinishedEntitiesReportFilters, UnfinishedEntitiesReportData,
} from '../../reports/types/UnfinishedEntitiesReportTypes';
import { initRectificationsBySupplierData } from '../../reports/components/reports-content/sales/rectifications-by-supplier/utils/data';
import {
  OutstandingOverduePurchasingFilters, PurchasingOutstandingOverduePurchaseOrdersData,
  PurchasingOutstandingOverduePurchaseOrdersReportResponse,
} from '../../reports/types/OutstandingOverdueReportTypes';
import {
  GetPurchasingSupplierSpendReportResponse,
  SupplierSpendData,
  SupplierSpendFilters,
} from '../../reports/types/SupplierSpendReportTypes';
import { initFitmentTimeData } from '../../reports/components/reports-content/production/fitment-time/utils/data';
import {
  FitterReturnsData,
  FitterReturnsFilters,
  GetPurchasingFitterReturnsReportDto,
} from '../../reports/types/FitterReturnsTypes';
import {
  GetSalesSoldProductsByCustomerReportResponse,
  SoldProductsByCustomerData,
  SoldProductsByCustomerFilters,
} from '../../reports/types/SoldProductsByCustomerTypes';
import {
  FitmentTimeData,
  FitmentTimeReportFilters,
  GetProductionFitmentTimeReportTableResponse, ProductionFitmentTimeReportChartParentJobDto,
} from '../../reports/types/FitmentTimeReportTypes';
import {
  GetSalesRectificationByProductReportResponse, RectificationsByProductReportFilters,
  SalesRectificationsByProductReportData,
} from '../../reports/types/RectificationsByProductTypes';
import {
  GetSalesNumberOfConvertedVehiclesReportResponse, NumberOfConvertedVehiclesReportData,
  NumberOfConvertedVehiclesReportFilters,
} from '../../reports/types/NumberOfConvertedVehiclesReportTypes';
import { initNumberOfConvertedVehiclesData } from '../../reports/components/reports-content/sales/number-of-converted-vehicles/utils/data';
import {
  GetSalesOfficeEmployeeChartDataReportDto,
  GetSalesOfficeEmployeeReportResponse, SalesOfficeEmployeeReportData, SalesOfficeEmployeeReportFilters,
} from '../../reports/types/SalesOfficeEmployeeReportTypes';
import { FullStockReportFilters, GetStockFullStockReportDto } from '../../reports/types/FullStockReportTypes';
import {
  GetSalesRectificationBySupplierReportResponse,
  RectificationBySupplierData, RectificationsBySupplierReportFilters,
} from '../../reports/types/RectificationsBySupplierReportTypes';
import { initUnfinishedEntitiesReportData } from '../../reports/components/reports-content/sales/sales-unfinished-entities/utils/data';
import {
  initPurchasingOutstandingOverdueData,
} from '../../reports/components/reports-content/purchasing/outstanding-overdue-purchase-orders/utils/data';
import { initSoldProductsByCustomerData } from '../../reports/components/reports-content/sales/sold-products-by-customer/utils/data';
import { initSupplierSpendData } from '../../reports/components/reports-content/purchasing/supplier-spend/utils/data';
import {
  GetSalesByCustomerReportResponse,
  SalesByCustomerReportData,
  SalesByCustomerReportFilters,
} from '../../reports/types/SalesReportByCustomerTypes';
import { initSalesReportByCustomerData } from '../../reports/components/reports-content/sales/sales-report-by-customer/utils/data';
import { initSalesOffsiteWorkingReportData } from '../../reports/components/reports-content/sales/sales-offsite-working/utils/data';
import {
  GetSalesOffsiteWorkingReportResponse,
  SalesOffsiteWorkingReportData,
  SalesOffsiteWorkingReportFilters,
} from '../../reports/types/SalesOffsiteWorkingReportTypes';
import { CancelledLinesReportDto, CancelledLinesReportFilters } from '../../reports/types/CancelledLinesReportType';

interface IReportsState {
  reportIsVisible: boolean,
  isTableView: boolean,
  fitmentTimeFilters: FitmentTimeReportFilters,
  fitmentTimeData: FitmentTimeData,
  fitmentTimeDataChart: DataListType<ProductionFitmentTimeReportChartParentJobDto[]>,
  rectificationReportFilters: RectificationReportFilters,
  rectificationReportData: SalesRectificationReportData,
  rectificationsByProductFilters: RectificationsByProductReportFilters,
  rectificationsByProductData: SalesRectificationsByProductReportData,
  numberOfConvertedVehiclesFilters: NumberOfConvertedVehiclesReportFilters | undefined,
  numberOfConvertedVehiclesData: NumberOfConvertedVehiclesReportData,
  salesOffsiteWorkingFilters: SalesOffsiteWorkingReportFilters
  salesOffsiteWorkingData: SalesOffsiteWorkingReportData,
  unfinishedEntitiesFilters: UnfinishedEntitiesReportFilters,
  unfinishedEntitiesData: DataListType<GetUnfinishedEntitiesReportDto[]> & {maxSalesNet: number, minSalesNet: number},
  salesOfficeEmployeeFilters: SalesOfficeEmployeeReportFilters,
  salesOfficeEmployeeData: SalesOfficeEmployeeReportData,
  salesOfficeEmployeeChartPaging: PagingType,
  salesOfficeEmployeeDataChart: DataListType<GetSalesOfficeEmployeeChartDataReportDto[]>,
  salesByCustomerFilters: SalesByCustomerReportFilters,
  salesReportByCustomerData: SalesByCustomerReportData,
  soldProductsBySupplierFilters: SoldProductsByCustomerFilters,
  soldProductsBySupplierData: SoldProductsByCustomerData,
  fullStockReportFilters: FullStockReportFilters,
  fullStockReportData: DataListType<GetStockFullStockReportDto[]>,
  rectificationsBySupplierFilters: RectificationsBySupplierReportFilters,
  rectificationsBySupplierData: RectificationBySupplierData
  supplierSpendFilters: SupplierSpendFilters,
  supplierSpendData: SupplierSpendData,
  outstandingOverduePurchasingReportFilters: OutstandingOverduePurchasingFilters,
  outstandingOverduePurchasingReportData: PurchasingOutstandingOverduePurchaseOrdersData,
  cancelledLinesFilters: CancelledLinesReportFilters,
  cancelledLinesData: DataListType<CancelledLinesReportDto[]>,
  fitterReturnsFilters: FitterReturnsFilters,
  fitterReturnsData: FitterReturnsData,
}

export const initialReportsState: IReportsState = {
  reportIsVisible: false,
  isTableView: true,
  fitmentTimeFilters: initPaging,
  fitmentTimeData: initFitmentTimeData,
  fitmentTimeDataChart: initDataList,
  rectificationReportFilters: initPaging,
  rectificationReportData: initRectificationReportData,
  rectificationsByProductFilters: initPaging,
  rectificationsByProductData: initRectificationsByProductReportData,
  numberOfConvertedVehiclesFilters: undefined,
  numberOfConvertedVehiclesData: initNumberOfConvertedVehiclesData,
  salesOffsiteWorkingFilters: initPaging,
  salesOffsiteWorkingData: initSalesOffsiteWorkingReportData,
  unfinishedEntitiesFilters: initPaging,
  unfinishedEntitiesData: initUnfinishedEntitiesReportData,
  salesOfficeEmployeeFilters: initPaging,
  salesOfficeEmployeeData: initSalesOfficeEmployeeReportData,
  salesOfficeEmployeeChartPaging: initPaging,
  salesOfficeEmployeeDataChart: initDataList,
  salesByCustomerFilters: initPaging,
  salesReportByCustomerData: initSalesReportByCustomerData,
  soldProductsBySupplierFilters: initPaging,
  soldProductsBySupplierData: initSoldProductsByCustomerData,
  fullStockReportFilters: initPaging,
  fullStockReportData: initDataList,
  rectificationsBySupplierFilters: initPaging,
  rectificationsBySupplierData: initRectificationsBySupplierData,
  supplierSpendFilters: initPaging,
  supplierSpendData: initSupplierSpendData,
  outstandingOverduePurchasingReportFilters: initPaging,
  outstandingOverduePurchasingReportData: initPurchasingOutstandingOverdueData,
  cancelledLinesFilters: initPaging,
  cancelledLinesData: initDataList,
  fitterReturnsFilters: initPaging,
  fitterReturnsData: initDataList,
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: initialReportsState,
  reducers: {
    setReportIsVisible: (state, action: PayloadAction<boolean>) => {
      state.reportIsVisible = action.payload;
    },
    setIsTableView: (state, action: PayloadAction<boolean>) => {
      state.isTableView = action.payload;
    },
    setFitmentTimeFilters: (state, action: PayloadAction<FitmentTimeReportFilters>) => {
      state.fitmentTimeFilters = action.payload;
    },
    setFitmentTimeData: (state, action: PayloadAction<FitmentTimeData>) => {
      state.fitmentTimeData = action.payload;
    },
    setFitmentTimeDataChart: (state, action: PayloadAction<DataListType<ProductionFitmentTimeReportChartParentJobDto[]>>) => {
      state.fitmentTimeDataChart = action.payload;
    },
    setRectificationReportFilters: (state, action: PayloadAction<RectificationReportFilters>) => {
      state.rectificationReportFilters = action.payload;
    },
    setRectificationReportData: (state, action: PayloadAction<SalesRectificationReportData>) => {
      state.rectificationReportData = action.payload;
    },
    setRectificationsByProductFilters: (state, action: PayloadAction<RectificationsByProductReportFilters>) => {
      state.rectificationsByProductFilters = action.payload;
    },
    setRectificationsByProductData: (state, action: PayloadAction<SalesRectificationsByProductReportData>) => {
      state.rectificationsByProductData = action.payload;
    },
    setNumberOfConvertedVehiclesFilters: (state, action: PayloadAction<NumberOfConvertedVehiclesReportFilters | undefined>) => {
      state.numberOfConvertedVehiclesFilters = action.payload;
    },
    setNumberOfConvertedVehiclesData: (state, action: PayloadAction<NumberOfConvertedVehiclesReportData>) => {
      state.numberOfConvertedVehiclesData = action.payload;
    },
    setSalesOffsiteWorkingFilters: (state, action: PayloadAction<SalesOffsiteWorkingReportFilters>) => {
      state.salesOffsiteWorkingFilters = action.payload;
    },
    setSalesOffsiteWorkingData: (state, action: PayloadAction<SalesOffsiteWorkingReportData>) => {
      state.salesOffsiteWorkingData = action.payload;
    },
    setUnfinishedEntitiesFilters: (state, action: PayloadAction<UnfinishedEntitiesReportFilters>) => {
      state.unfinishedEntitiesFilters = action.payload;
    },
    setUnfinishedEntitiesData: (state, action: PayloadAction<UnfinishedEntitiesReportData>) => {
      state.unfinishedEntitiesData = action.payload;
    },
    setSalesOfficeEmployeeFilters: (state, action: PayloadAction<SalesOfficeEmployeeReportFilters>) => {
      state.salesOfficeEmployeeFilters = action.payload;
    },
    setSalesOfficeEmployeeData: (state, action: PayloadAction<SalesOfficeEmployeeReportData>) => {
      state.salesOfficeEmployeeData = action.payload;
    },
    setSalesOfficeEmployeeChartPaging: (state, action: PayloadAction<PagingType>) => {
      state.salesOfficeEmployeeChartPaging = action.payload;
    },
    setSalesOfficeEmployeeDataChart: (state, action: PayloadAction<DataListType<GetSalesOfficeEmployeeChartDataReportDto[]>>) => {
      state.salesOfficeEmployeeDataChart = action.payload;
    },
    setSalesByCustomerFilters: (state, action: PayloadAction<SalesByCustomerReportFilters>) => {
      state.salesByCustomerFilters = action.payload;
    },
    setSalesByCustomerData: (state, action: PayloadAction<SalesByCustomerReportData>) => {
      state.salesReportByCustomerData = action.payload;
    },
    setSoldProductsByCustomerFilters: (state, action: PayloadAction<SoldProductsByCustomerFilters>) => {
      state.soldProductsBySupplierFilters = action.payload;
    },
    setSoldProductsByCustomerData: (state, action: PayloadAction<SoldProductsByCustomerData>) => {
      state.soldProductsBySupplierData = action.payload;
    },
    setFullStockReportFilters: (state, action: PayloadAction<FullStockReportFilters>) => {
      state.fullStockReportFilters = action.payload;
    },
    setFullStockReportData: (state, action: PayloadAction<DataListType<GetStockFullStockReportDto[]>>) => {
      state.fullStockReportData = action.payload;
    },
    setRectificationsBySupplierFilters: (state, action: PayloadAction<RectificationsBySupplierReportFilters>) => {
      state.rectificationsBySupplierFilters = action.payload;
    },
    setRectificationsBySupplierData: (state, action: PayloadAction<RectificationBySupplierData>) => {
      state.rectificationsBySupplierData = action.payload;
    },
    setSupplierSpendReportFilters: (state, action: PayloadAction<SupplierSpendFilters>) => {
      state.supplierSpendFilters = action.payload;
    },
    setSupplierSpendReportData: (state, action: PayloadAction<SupplierSpendData>) => {
      state.supplierSpendData = action.payload;
    },
    setOutstandingOverduePurchasingReportFilters: (state, action: PayloadAction<OutstandingOverduePurchasingFilters>) => {
      state.outstandingOverduePurchasingReportFilters = action.payload;
    },
    setOutstandingOverduePurchasingReportData: (state, action: PayloadAction<PurchasingOutstandingOverduePurchaseOrdersData>) => {
      state.outstandingOverduePurchasingReportData = action.payload;
    },
    setCancelledLinesFilters: (state, action: PayloadAction<CancelledLinesReportFilters>) => {
      state.cancelledLinesFilters = action.payload;
    },
    setCancelledLinesData: (state, action: PayloadAction<DataListType<CancelledLinesReportDto[]>>) => {
      state.cancelledLinesData = action.payload;
    },
    setFitterReturnsReportFilters: (state, action: PayloadAction<FitterReturnsFilters>) => {
      state.fitterReturnsFilters = action.payload;
    },
    setFitterReturnsReportData: (state, action: PayloadAction<FitterReturnsData>) => {
      state.fitterReturnsData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getFitmentTimeReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetProductionFitmentTimeReportTableResponse>,
    ) => {
      state.fitmentTimeData = action.payload.data;
    });
    builder.addCase(getFitmentTimeReportChartThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<ProductionFitmentTimeReportChartParentJobDto[]>>,
    ) => {
      state.fitmentTimeDataChart = action.payload.data;
    });
    builder.addCase(getSalesRectificationReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesRectificationReportResponse>,
    ) => {
      state.rectificationReportData = action.payload.data;
    });
    builder.addCase(getRectificationsByProductReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesRectificationByProductReportResponse>,
    ) => {
      state.rectificationsByProductData = action.payload.data;
    });
    builder.addCase(getNumberOfConvertedVehiclesReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesNumberOfConvertedVehiclesReportResponse>,
    ) => {
      state.numberOfConvertedVehiclesData = action.payload.data;
    });
    builder.addCase(getSalesOffsiteWorkingReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesOffsiteWorkingReportResponse>,
    ) => {
      state.salesOffsiteWorkingData = action.payload.data;
    });
    builder.addCase(getSalesOfficeEmployeeReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesOfficeEmployeeReportResponse>,
    ) => {
      state.salesOfficeEmployeeData = action.payload.data;
    });
    builder.addCase(getSalesOfficeEmployeeReportChartThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<GetSalesOfficeEmployeeChartDataReportDto[]>>,
    ) => {
      state.salesOfficeEmployeeDataChart = action.payload.data;
    });
    builder.addCase(getSoldProductsByCustomerReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesSoldProductsByCustomerReportResponse>,
    ) => {
      state.soldProductsBySupplierData = action.payload.data;
    });
    builder.addCase(getFullStockReportThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<GetStockFullStockReportDto[]>>,
    ) => {
      state.fullStockReportData = action.payload.data;
    });
    builder.addCase(getUnfinishedEntitiesReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesUnfinishedEntitiesReportResponse>,
    ) => {
      state.unfinishedEntitiesData = action.payload.data;
    });
    builder.addCase(getRectificationsBySupplierReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesRectificationBySupplierReportResponse>,
    ) => {
      state.rectificationsBySupplierData = action.payload.data;
    });
    builder.addCase(getSupplierSpendReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetPurchasingSupplierSpendReportResponse>,
    ) => {
      state.supplierSpendData = action.payload.data;
    });
    builder.addCase(getPurchasingOutstandingOverduePurchaseOrdersThunk.fulfilled, (
      state,
      action: PayloadAction<PurchasingOutstandingOverduePurchaseOrdersReportResponse>,
    ) => {
      state.outstandingOverduePurchasingReportData = action.payload.data;
    });
    builder.addCase(getCancelledLinesReportThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<CancelledLinesReportDto[]>>,
    ) => {
      state.cancelledLinesData = action.payload.data;
    });
    builder.addCase(getPurchasingFitterReturnsReportThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<GetPurchasingFitterReturnsReportDto[]>>,
    ) => {
      state.fitterReturnsData = action.payload.data;
    });
    builder.addCase(getSalesByCustomerReportThunk.fulfilled, (
      state,
      action: PayloadAction<GetSalesByCustomerReportResponse>,
    ) => {
      state.salesReportByCustomerData = action.payload.data;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setReportIsVisible,
  setIsTableView,
  setFitmentTimeFilters,
  setFitmentTimeData,
  setFitmentTimeDataChart,
  setRectificationReportFilters,
  setRectificationReportData,
  setRectificationsByProductFilters,
  setRectificationsByProductData,
  setNumberOfConvertedVehiclesFilters,
  setNumberOfConvertedVehiclesData,
  setSalesOffsiteWorkingFilters,
  setSalesOffsiteWorkingData,
  setSalesOfficeEmployeeFilters,
  setSalesOfficeEmployeeData,
  setSalesOfficeEmployeeChartPaging,
  setSalesOfficeEmployeeDataChart,
  setSalesByCustomerFilters,
  setSalesByCustomerData,
  setSoldProductsByCustomerFilters,
  setSoldProductsByCustomerData,
  setFullStockReportFilters,
  setFullStockReportData,
  setUnfinishedEntitiesFilters,
  setUnfinishedEntitiesData,
  setRectificationsBySupplierFilters,
  setRectificationsBySupplierData,
  setSupplierSpendReportFilters,
  setSupplierSpendReportData,
  setOutstandingOverduePurchasingReportFilters,
  setOutstandingOverduePurchasingReportData,
  setCancelledLinesFilters,
  setCancelledLinesData,
  setFitterReturnsReportFilters,
  setFitterReturnsReportData,
} = reportsSlice.actions;

export default reportsSlice.reducer;
