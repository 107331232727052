import React, { useEffect } from 'react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  currentBusinessAreaSelector,
  isDashboardLoadingSelector,
  jobCategoriesSelector,
  jobWarningsSelector,
} from '../../../../store/selectors/coreSelectors';
import { DictionaryItem } from '../../../../core/types/coreTypes';
import { JobDto, JobsFiltersType, JobsResponseType } from '../../types/jobsTypes';
import {
  jobsColumnsPaginationSelector,
  sortedJobCollectionsSelector,
} from '../../../../store/selectors/jobsSelector';
import { setJobDetails, setJobsCollections } from '../../../../store/slices/jobsSlice';
import { getJobsDashboardNewPortion } from '../../../../store/thunks/jobs/jobsDashboardThunks';
import DraggableCardsArea from '../../../../common/components/draggable-cards-column/DraggableCardsArea';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import JobStatusChangeConfirmationModal from '../../../container/components/modals/JobStatusChangeConfirmationModal';
import usePermission from '../../../../permissions-handling/permissionHook';
import DashboardSkeleton from '../../../../common/components/skeletons/dashboard-skeleton/DashboardSkeleton';
import { UNKNOWN_USER } from '../../../../core/utils/regex';
import { useJobStatusTransition } from '../../../container/hooks/useJobStatusTransition';
import { useDragEndDashboardCards } from '../../../../common/hooks/useDragAndDropDashboardCards';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import QualityCheckModal
  from '../../../job-view-page/components/job-content/quality-check/quality-check-modal/QualityCheckModal';
import { getJobQualityCheckStatusesThunk } from '../../../../store/thunks/core/coreStatusesThunks';

type EnquiryColumnsProps = {
  cards: JobsResponseType | null;
  setCards: (value: JobsResponseType | null) => void;
  jobStatuses: DictionaryItem[];
  dashboardFilters: JobsFiltersType;
}

const JobColumns: React.FC<EnquiryColumnsProps> = ({
  cards, setCards, dashboardFilters, jobStatuses,
}) => {
  const allowedToEdit = usePermission(PermissionEnum.JobEditFields);
  const dispatch = useAppDispatch();
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const jobColumnsPagination = useAppSelector(jobsColumnsPaginationSelector);
  const sortedJobCollections = useAppSelector(sortedJobCollectionsSelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const warnings = useAppSelector(jobWarningsSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  useEffect(() => {
    dispatch(getJobQualityCheckStatusesThunk());
    // eslint-disable-next-line
  }, []);

  const {
    editStatusActionDashboardHandler,
    setJobTransit,
    isFailed,
    isConfirmModalVisible,
    modalTitle,
    modalText,
    modalConfirmLabel,
    confirmEditingHandler,
    cancelStatusConfirmationModal,
    comment,
    setComment,
    error,
    setError,
    fileList,
    setFileList,
    isProceedToQualityCheck,
    setIsProceedToQualityCheck,
    isRunCheck,
    setIsRunCheck,
  } = useJobStatusTransition({
    isDashboardFlow: true,
    cards,
    setCards,
    jobCollections: sortedJobCollections,
  });

  const currentFiltersState = [
    dashboardFilters?.assignedTo,
    dashboardFilters?.jobType,
    dashboardFilters?.warnings,
    dashboardFilters?.jobCategories,
    dashboardFilters?.priority,
  ];

  const setNewPortion = (status: number, data: Array<JobDto>) => {
    const newArr = cards?.map((el) => {
      if (el.status === status) {
        return {
          ...el,
          list: [...el.list, ...data],
        };
      } else {
        return el;
      }
    });
    newArr && dispatch(setJobsCollections(newArr));
  };
  const getNewPortion = async (page: number, status: number) => {
    currentBusinessArea && dispatch(getJobsDashboardNewPortion({
      filters: {
        ...dashboardFilters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: [status],
        page,
      },
      currentStatus: status,
      setNewPortion,
    }));
  };

  const { onDragEnd } = useDragEndDashboardCards<JobDto>(editStatusActionDashboardHandler);

  const reformCardData = (card: JobDto) => {
    const assigneesLength = card.assignees?.length;
    const firstAssignee = card.assignees ? card.assignees[0] : undefined;
    const firstName = firstAssignee
      ? firstAssignee.isDeleted
        ? UNKNOWN_USER
        : `${firstAssignee.firstName} ${firstAssignee.lastName}`
      : '';
    const assigneeLabel = () => {
      if (!assigneesLength || assigneesLength === 0) {
        return 'Unassigned';
      }
      if (assigneesLength && assigneesLength > 0) {
        return `${firstName}`;
      } else return '';
    };
    const assigneesList = card.assignees && card.assignees.length > 1
      ? card.assignees.map(({ firstName, lastName, isDeleted }) => (isDeleted ? UNKNOWN_USER : `${firstName} ${lastName}`))
      : undefined;

    return {
      id: card.id,
      entityNumber: card.jobNumber,
      customerName: card.customerName,
      jobCategory: card.jobCategories.map((category) => jobCategories.find((cat) => cat.value === category)?.label || ''),
      jobType: card.jobType,
      priorityLevel: card.priorityLevel,
      assigneeFullName: assigneeLabel(),
      assigneesList,
      isAssigneeDeleted: false,
      warnings: card.warnings,
      linkedEntities: [
        {
          label: 'Linked to',
          icon: faLink,
          value: card.linkedNumber,
        },
      ],
      timeTracking: [
        {
          label: 'Spent',
          value: `${((card.spentMinutes || 0) / 60).toFixed(2)} h`,
        },
        {
          label: 'Estimated',
          value: card.estimatedMinutes ? `${(card.estimatedMinutes / 60).toFixed(2)} h` : '-',
        },
        {
          label: 'Progress',
          value: `${card.progressPercent || 0} %`,
        },
      ],
    };
  };

  return (
    <>
      <JobStatusChangeConfirmationModal
        title={modalTitle}
        text={modalText}
        extraQuestion={!isFailed}
        visible={isConfirmModalVisible}
        onCancel={cancelStatusConfirmationModal}
        cancelClick={cancelStatusConfirmationModal}
        confirmLabel={modalConfirmLabel}
        confirmClick={confirmEditingHandler}
        isFileAttach={isFailed}
        comment={comment}
        setComment={setComment}
        error={error}
        setError={setError}
        fileList={fileList}
        setFileList={setFileList}
      />
      <ConfirmationModal
        isVisible={isProceedToQualityCheck}
        customTitle="Proceed to quality check"
        customText="Would you like to start a quality check?"
        customCreateLabel="Run quality check"
        customCancelLabel="Later"
        onCancel={() => {
          setIsProceedToQualityCheck(false);
          dispatch(setJobDetails(null));
        }}
        confirmHandler={() => {
          setIsRunCheck(true);
          setIsProceedToQualityCheck(false);
        }}
        isProceedQuestion={false}
      />
      <QualityCheckModal
        isVisible={isRunCheck}
        onCancel={() => {
          setIsRunCheck(false);
          dispatch(setJobDetails(null));
        }}
        readMode={false}
        dashboardMode
      />
      {isLoading
        ? <DashboardSkeleton hasPrice={false} linkedEntities={1} hasTrackingInfo />
        : <DraggableCardsArea
          columnsPagination={jobColumnsPagination}
          getNewPortion={getNewPortion}
          onDragEnd={onDragEnd}
          cards={cards}
          setCards={setCards}
          statusDictionary={jobStatuses}
          dashFilters={dashboardFilters}
          currentFiltersState={currentFiltersState}
          reformCardData={(card: JobDto) => reformCardData(card)}
          setCardTransit={setJobTransit}
          permissionToSeeCardPage={PermissionEnum.JobViewPage}
          permissionToSeeEstimates={PermissionEnum.JobTimeTrackingSummary}
          isDragDisabled={!allowedToEdit}
          warnings={warnings}
        />}
    </>
  );
};

export default JobColumns;
