import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  fullStockReportDataSelector,
  fullStockReportFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../store/selectors/reportsSelectors';
import Pagination from '../../../../core/components/pagination/Pagination';
import { setFullStockReportFilters } from '../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { getFullStockReportThunk } from '../../../../store/thunks/reports/reportsThunks';
import FullStockTable from './table/FullStockTable';

const FullStockContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fullStockReportFiltersSelector);
  const { totalCount } = useAppSelector(fullStockReportDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => {
            const newFilters = { ...filters, page, pageSize };
            dispatch(setFullStockReportFilters(newFilters));
            reportIsVisible && dispatch(getFullStockReportThunk({ filters: newFilters }));
          }}
          className="actions-and-paging__paging"
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
        />
      </div>
      <FullStockTable />
    </>
  );
};

export default FullStockContent;
