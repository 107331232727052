import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../core/components/layout/Layout';
import DetailsHead from '../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../core/hooks/useLocationHistoryState';
import { routesPath } from '../core/enums/pathEnum';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getUserProfileThunk } from '../store/thunks/account/accountThunks';
import { currentUserSelector } from '../store/selectors/accountSelectors';
import { accountContentModes, AccountViewModeParams } from './enums/AccountViewEnums';
import AccountFilters from './components/acount-filters/AccountFilters';
import AccountContent from './components/account-content/AccountContent';

const AccountContainer: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState(false);
  const currentUser = useAppSelector(currentUserSelector);
  const mode = params.get('mode');

  useEffect(() => {
    currentUser && dispatch(getUserProfileThunk({ id: currentUser.id }));
    // eslint-disable-next-line
    }, [currentUser]);

  useEffect(() => {
    if (
      !mode
      || (mode && !accountContentModes.includes(mode as AccountViewModeParams))
      || (mode && currentUser && !currentUser.employeeId)
    ) {
      setSearchParams({ mode: AccountViewModeParams.ACCOUNT_DETAILS }, { replace: true });
    }
    // eslint-disable-next-line
  }, [mode, currentUser]);

  return (
    <Layout subHeader={
      <DetailsHead
        goBack={() => backHandle(routesPath.HOME)}
        className="createPage"
      >
        <div className="createHead">My profile</div>
      </DetailsHead>
    }
    >
      <>
        {currentUser?.employeeId && <AccountFilters />}
        <AccountContent mode={mode} isLinkedToEmployee={!!currentUser?.employeeId} />
      </>
    </Layout>
  );
};

export default AccountContainer;
