import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { customerTypesSelector } from './coreSelectors';
import { customerStatusesSelector } from './coreStatusesSelectors';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { routesPath } from '../../core/enums/pathEnum';
import { EnquiryModesEnum } from '../../enquiry/enquiries/enums/dashboardViewsEnum';
import { enquiriesFiltersSelector } from './enquiriesSelector';
import { ordersDashFiltersSelector } from './ordersSelector';
import { rectificationsDashFiltersSelector } from './rectificationsSelector';
import { OrdersPageParamsEnum } from '../../orders/dashboard/enums/OrdersViewsEnum';
import { RectificationModesEnum } from '../../rectifications/dashboard/enums/rectificationEnums';
import { JobModesEnum } from '../../job/jobs/enums/jobsEnums';
import { jobsFiltersSelector } from './jobsSelector';
import { initRequisite } from '../../common/utils/data';

export const customersDashFiltersSelector = (state: RootState) => state.customers.filters;
export const customerFiltersListSelector = createSelector(
  customersDashFiltersSelector,
  customerTypesSelector,
  customerStatusesSelector,
  (filters, customerTypes, customerStatuses) => {
    return [
      {
        label: 'Customer Type',
        name: 'customerType',
        value: filters.customerType,
        options: customerTypes,
      },
      {
        label: 'Status',
        name: 'status',
        value: filters.status,
        options: customerStatuses,
      },
    ];
  },
);
export const customersListSelector = (state: RootState) => state.customers.customersList;
export const customersListForVehiclesCreationSelector = createSelector(customersListSelector, (customers) => {
  const sortList = customers.totalCount > 0
    ? customers.items.sort((a, b) => (a.name || '').localeCompare((b.name || '')))
    : [];
  return sortList.length > 0
    ? sortList.map((el) => ({
      status: el.customerStatus,
      customerAccountNumber: el.customerAccountNumber,
      name: el.name,
    }))
    : [];
});
export const customerDetailsSelector = (state: RootState) => state.customers.customersDetails;
export const customerDetailsRequisitesSetSelector = createSelector(customerDetailsSelector, (details) => {
  return {
    customerId: details?.customer.id,
    billing: details?.billingRequisite ?? initRequisite,
    shipping: details?.shippingRequisite ?? initRequisite,
    isShippingSame: details?.customer.isShippingRequisiteSameAsBilling ?? true,
    alwaysUseRequisites: details?.customer.alwaysUseRequisites ?? false,
  };
});

export const customerDetailsCardProperties = createSelector(
  customerDetailsSelector,
  enquiriesFiltersSelector,
  ordersDashFiltersSelector,
  rectificationsDashFiltersSelector,
  jobsFiltersSelector,
  (customerDetails, enquiriesFilters, ordersFilters, rectificationsFilters, jobsDashFilters) => {
    const details = customerDetails?.customer;
    const customerName = details?.name || undefined;
    return [
      {
        label: 'Enquiries',
        value: details?.enquiriesAmount || 0,
        permission: PermissionEnum.EnquiryDashboardPage,
        to: `/${routesPath.ENQUIRIES}?page=${EnquiryModesEnum.DASHBOARD}&keyword=${customerName}`,
        filters: { ...enquiriesFilters, keyword: customerName },
      },
      {
        label: 'Orders',
        value: details?.ordersAmount || 0,
        permission: PermissionEnum.OrderDashboard,
        to: `/${routesPath.ORDERS}?page=${OrdersPageParamsEnum.DASHBOARD}&keyword=${customerName}`,
        filters: { ...ordersFilters, keyword: customerName },
      },
      {
        label: 'Rectifications',
        value: details?.rectificationsAmount || 0,
        permission: PermissionEnum.RectificationDashboard,
        to: `/${routesPath.RECTIFICATIONS}?page=${RectificationModesEnum.DASHBOARD}&keyword=${customerName}`,
        filters: { ...rectificationsFilters, keyword: customerName },
      },
      {
        label: 'Jobs',
        value: details?.jobsAmount || 0,
        permission: PermissionEnum.JobDashboard,
        to: `/${routesPath.JOBS}?page=${JobModesEnum.DASHBOARD}&keyword=${customerName}`,
        filters: { ...jobsDashFilters, keyword: customerName },
      },
    ];
  },
);
export const customerAttachmentsSelector = (state: RootState) => state.customers.customerAttachments;
export const customerAttachmentsFiltersSelector = (state: RootState) => state.customers.customerAttachmentsFilters;

export const customerActivityLogSelector = (state: RootState) => state.customers.customerActivityLog;
export const customerActivityLogFiltersSelector = (state: RootState) => state.customers.customerActivityLogFilters;
export const customerContactsFiltersSelector = (state: RootState) => state.customers.customerContactsFilters;
export const customerContactsListSelector = (state: RootState) => state.customers.customerContactsList;
