import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import { initDataList } from '../../../core/types/coreTypes';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import Pagination from '../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Thead from '../../../core/components/table-components/Thead';
import TableLoader from '../../../core/components/table-components/TableLoader';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import { DraftLinePartList, DraftLinePartsFiltersType, initDraftLinePartsFilters } from '../../types/commonTypes';
import { useAppSelector } from '../../../store/hooks';
import { currentBusinessAreaSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import PartsModeColumns from './PartsModeColumns';
import PartsModeBody from './PartsModeBody';
import TableNew from '../../../core/components/table-components/table-new/TableNew';

type PartsModeProps = {
  filters: DraftLinePartsFiltersType,
  setFilters: (v: DraftLinePartsFiltersType) => void,
  parts: DraftLinePartList,
  setParts: (v: DraftLinePartList) => void,
  currentSource?: number,
  fetchParts: (filters: DraftLinePartsFiltersType, isShow: boolean) => void,
  setProdSourceModal: (id: number, name: string) => void,
  originName?: string,
  isSuitableForCheckboxShown?: boolean,
}

const PartsMode: React.FC<PartsModeProps> = ({
  filters, setFilters, parts, setParts, currentSource, fetchParts,
  setProdSourceModal, originName, isSuitableForCheckboxShown,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const businessArea = useAppSelector(currentBusinessAreaSelector);

  const [error, setError] = useState<string>('');
  const [isShow, setIsShow] = useState(false);

  const filtersHandle = (filters: DraftLinePartsFiltersType) => {
    if (businessArea) {
      setFilters({ ...filters });
      fetchParts({ ...filters }, isShow);
    }
  };
  const searchTriggerFn = () => {
    fetchParts({ ...filters, keyword: filters.keyword?.trim() }, isShow);
  };
  useEffect(() => {
    error && setError('');
    setParts(initDataList);
    setFilters(initDraftLinePartsFilters);
    setIsShow(false);
    // eslint-disable-next-line
  }, [currentSource]);
  return (
    <div className="partsTable">
      <InputWithButton
        value={filters.keyword}
        onChange={(e) => {
          const val = e.target.value;
          if (!val.trim() && parts.totalCount !== 0) {
            setParts(initDataList);
          }
          if (val.length > 100) {
            setError(maxLengthMessageHandle(100));
          } else {
            error && setError('');
          }
          if (!val.trim()) {
            isShow && setIsShow(false);
          }
          setFilters({ ...filters, keyword: val });
        }}
        onPressEnter={() => {
          const searchValue = filters.keyword;
          if (!searchValue?.trim()) {
            setError(ErrorsEnum.REQUIRED);
            setFilters({ ...filters, keyword: '' });
          } else {
            searchValue?.trim() && !error && searchTriggerFn();
          }
        }}
        placeholder="Search by part code, manufacturer code, part name or description"
        onClick={() => {
          const searchValue = filters.keyword;
          searchValue?.trim() && !error && searchTriggerFn();
        }}
        icon={<FontAwesomeIcon icon={faSearch} />}
        error={error}
        offsetBottom
      />
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page, pageSize) => {
          filtersHandle({ ...filters, page, pageSize });
        }}
        totalItems={parts.totalCount}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        offsetBottom
      />
      <TableNew>
        <Thead>
          <PartsModeColumns
            filters={filters}
            filtersHandle={filtersHandle}
            partsTotalCount={parts.totalCount}
          />
        </Thead>
        {isGlobalLoading
          ? <TableLoader colspan={6} />
          : <PartsModeBody
            searchValue={filters.keyword}
            parts={parts}
            setProdSourceModal={setProdSourceModal}
            filters={filters}
          />}
      </TableNew>
      {isSuitableForCheckboxShown && <div className="partsTable__showBox">
        <Checkbox
          checked={isShow}
          onChange={(v) => {
            if (businessArea && filters.keyword?.trim()) {
              setIsShow(v);
              fetchParts(filters, v);
            }
          }}
          disabled={!filters.keyword}
          label={`Show only parts, which are suitable for ${originName} vehicle`}
        />
      </div>}
    </div>
  );
};

export default PartsMode;
