import React from 'react';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import Thead from '../../../../core/components/table-components/Thead';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../store/hooks';
import { invoiceDocumentsSelector } from '../../../../store/selectors/invoicingSelectors';
import { purchasingSpecificEntitiesColumns, salesSpecificEntitiesColumns } from '../../../invoicing-purchases/utils/data';

type SpecificEntitiesColumnsProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  type: 'purchases' | 'sales',
}

const SpecificEntitiesColumns: React.FC<SpecificEntitiesColumnsProps> = ({
  isAll, isIndeterminate, checkAll, type,
}) => {
  const { totalCount } = useAppSelector(invoiceDocumentsSelector);
  const columns = type === 'sales'
    ? salesSpecificEntitiesColumns
    : purchasingSpecificEntitiesColumns;

  return (
    <Thead>
      <Tr>
        <ThNew checkbox>
          <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAll} disabled={!totalCount} table />
        </ThNew>
        {columns.map((col) => <ThNew
          key={col.dataIndex}
          className={`specificEntitiesTh--${col.dataIndex}`}
        >
          {col.title}
        </ThNew>)}
      </Tr>
    </Thead>
  );
};

export default SpecificEntitiesColumns;
