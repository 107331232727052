import React from 'react';
import InvoicingOrderLinesTable from './components/InvoicingOrderLinesTable';
import './components/InvoicingOrderLinesContent.scss';
import { purchaseViewOrderLinesSummarySelector } from '../../../store/selectors/invoicingSelectors';
import { useAppSelector } from '../../../store/hooks';
import InvoicingSummary from '../../common/components/invoicing-summary/InvoicingSummary';

const InvoicingOrderLinesContent: React.FC = () => {
  const purchaseViewOrderLinesSummary = useAppSelector(purchaseViewOrderLinesSummarySelector);
  return (
    <>
      <InvoicingOrderLinesTable />
      <InvoicingSummary summary={purchaseViewOrderLinesSummary} />
    </>
  );
};

export default InvoicingOrderLinesContent;
