import {
  CustomAny,
  DataListType, DictionaryItem, initDataListWithIds, PagingWithOrdering, ResponseResult,
} from '../../../../core/types/coreTypes';
import {
  PurchaseOrderPartDto,
  InvoicingOperationLineNominalRecordDto,
} from '../../../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';

export type NominalRecordsFilters = {
  purchaseOrderIds?: number[],
  purchaseOrderPartIds?: number[],
  orderIds?: number[],
  partRequestLineIds?: number[],
  types?: number[],
  states?: number[],
  productName?: string,
  productNumber?: string,
  nominalCodeIds?: number[],
} & PagingWithOrdering

export type NominalRecordDto = {
  ordinalNumber: number,
  nominalCodeId: number,
  invoiceDocumentId: number | null,
  type: number,
  purchaseOrderPartId: number | null,
  state: number,
  net: number,
  vatType: number,
  vatPercent: number,
  vatAmount: number,
  id: number,
  code: string,
  description: string,
  invoiceDocumentNumber: string | null,
  productName: string,
  productNumber: string | null,
}

export type GetNominalRecordsResponse = ResponseResult<NominalRecordDto[], {
  nominalCodes: DictionaryItem[], allItemIds: number[]
}>

export type NominalCodesData = DataListType<NominalRecordDto[], {
  nominalCodes: DictionaryItem[], allItemIds: number[]
}>
export const initNominalCodesData = { ...initDataListWithIds, nominalCodes: [] };

export type PostNominalRecordRestModel = {
  type: number,
  nominalCodeId: number,
  net: number,
  vatPercent: number | null,
  vatAmount: number | null,
  purchaseOrderPartId?: number | null,
  partRequestLineId?: number | null,
}

export type PutNominalRecordRestModel = Omit<PostNominalRecordRestModel, 'purchaseOrderPartId' | 'partRequestLineId'>

export type TablePartNominalRecord = Pick<InvoicingOperationLineNominalRecordDto, 'invoiceDocumentId' | 'nominalCode' | 'state'>;

export type InvoicingPurchaseOrderPartTableLine = Pick<
PurchaseOrderPartDto,
'id' | 'creditNominalRecordsNet'
> & {
  orderingNumber: number | string,
  partNumber: string,
  partName: string,
  receivedQuantity: number,
  net: string,
  invoicedPending: number,
  nominalCodes: Array<TablePartNominalRecord>,
  unitOfMeasure: string,
  partId: number | null,
  notes: string | null,
  requestedQty: number,
  cancelledQty: number,
  receivedNet?: number | null,
  childLines?: CustomAny[] | null,
}

export type NominalCodesSummaryFilters = {
  purchaseOrderId?: number,
  orderId?: number
  nominalRecordIds: number[],
}

export type NominalRecordsSummary = {
  totalNet: string | null,
  selectedNet: string | null,
  remainingNet: string | null,
}

export type GetNominalRecordsSummaryResponse = {
  pendingInvoiceSummary: NominalRecordsSummary,
  pendingCreditSummary: NominalRecordsSummary,
}
