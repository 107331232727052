import React, { useEffect, useState } from 'react';
import { isVisible } from '@testing-library/user-event/dist/utils';
import CoreNonCoreFilters from './CoreNonCoreFilters';
import CoreNonCoreTable from './CoreNonCoreTable';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  partsKitAffectedItemsFiltersSelector,
  partsKitComponentsPartsSelector,
} from '../../../../../../../store/selectors/partsKitSelectors';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import {
  BooleanFunctionType,
  initDataListWithIds,
  VoidFunctionType,
} from '../../../../../../../core/types/coreTypes';
import {
  setPartsKitComponentsParts,
  setPartsKitComponentsPartsFilters,
} from '../../../../../../../store/slices/partsKitSlice';
import { PartsKitComponentsPartsFiltersInit } from '../../../../types/partsKitViewPageTypes';
import {
  addPartComponent,
  getPartsKitAffectedThunk,
} from '../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';
import { useSelectAll } from '../../../../../../../core/hooks/useSelectAll';

type CoreNonCoreComponentProps = {
  partsKitId: number,
  onCancel: VoidFunctionType,
  setCheckedAddKeys: (val: number[]) => void,
  mode: AddComponentModeEnum,
  isLoading?: boolean,
  setIsAddWithoutPrice: BooleanFunctionType,
}

const CoreNonCoreComponent: React.FC<CoreNonCoreComponentProps> = ({
  partsKitId, onCancel,
  setCheckedAddKeys, mode, isLoading, setIsAddWithoutPrice,
}) => {
  const dispatch = useAppDispatch();
  const affectedItemsPaging = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const parts = useAppSelector(partsKitComponentsPartsSelector);
  const [error, setError] = useState<string>('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(parts.allItemIds);

  // This is a workaround to avoid prop drilling of all useSelectAll hook (checked keys should be passed to AddComponentsWithoutPriceModal)
  useEffect(() => {
    setCheckedAddKeys([...checkedKeys]);
  }, [checkedKeys, setCheckedAddKeys]);

  const cancelClick = () => {
    onCancel();
    dispatch(setPartsKitComponentsPartsFilters(PartsKitComponentsPartsFiltersInit));
    dispatch(setPartsKitComponentsParts(initDataListWithIds));
    error && setError('');
  };

  const confirmAddPart = () => {
    const checkedParts = parts.items.filter((el) => checkedKeys.includes(el.id));
    const partsWithNoPrice = checkedParts.filter((p) => !p.hasPrice);
    if (partsWithNoPrice.length > 0) {
      setIsAddWithoutPrice(true);
    } else {
      dispatch(addPartComponent({
        id: partsKitId,
        partIds: checkedKeys,
        closeModal: cancelClick,
      }));
    }
  };

  useEffect(() => {
    if (!isVisible) {
      cancelClick();
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    uncheckAllHandle();
    error && setError('');
    // eslint-disable-next-line
  }, [mode]);
  return (
    <div className="addComponent__content">
      <CoreNonCoreFilters
        cleanKeys={uncheckAllHandle}
        error={error}
        setError={setError}
        mode={mode}
      />
      <CoreNonCoreTable
        mode={mode}
        checkedKeys={checkedKeys}
        checkAll={checkAllHandle}
        checkRow={checkRowHandle}
        isAll={isAll}
        isIndeterminate={isIndeterminate}
      />
      <ButtonActions
        createLabel={`Add selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
        cancelLabel="Cancel"
        cancelClick={cancelClick}
        createClick={() => {
          partsKitId && dispatch(getPartsKitAffectedThunk({
            id: partsKitId,
            filters: affectedItemsPaging,
            confirmAction: confirmAddPart,
          }));
        }}
        createType="button"
        disabledCreate={checkedKeys.length === 0}
        isLoading={isLoading}
        offsetTop
      />
    </div>
  );
};

export default CoreNonCoreComponent;
