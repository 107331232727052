import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalculator,
  faCircleCheck, faCircleInfo,
  faEdit,
  faExchange,
  faInfoCircle,
  faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Tooltip } from 'antd';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import PriceSourceTag from '../../../common/components/price-source-tag/PriceSourceTag';
import { StockPartReqTableLineType } from '../types/stockPartRequestTypes';
import Tag from '../../../core/components/tag/Tag';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useAppSelector } from '../../../store/hooks';
import { partReqLineActionsStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import usePermission from '../../../permissions-handling/permissionHook';

export const useViewByLinesCellAndActionsRender = (
  setDistributeQtyHandle: NumberFunctionType,
  setEditReadyCollected: NumberFunctionType,
  setIsMarkNotFromStock: NumberFunctionType,
  setConfirmReturnedParts: NumberFunctionType,
  setRemapToExistingPart: NumberFunctionType,
  setViewDetails: NumberFunctionType,
) => {
  const allowedToManage = usePermission(PermissionEnum.StockPartRequestEditPartLineFieldsAndUnlockPartLineActions);

  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);

  const {
    unknownPart, partiallyReady, ready,
    confirmed, deleted, archived, pendingSource,
    toBeReviewed, partiallyCollected, collected,
  } = useAppSelector(partReqLineActionsStatusesSelector);
  const partReqLineActionsStatusesList = [
    unknownPart?.label, partiallyReady?.label, ready?.label,
    confirmed?.label, deleted?.label, archived?.label, pendingSource?.label,
    toBeReviewed?.label, partiallyCollected?.label, collected?.label,
  ];

  const editReadyCollectedStatuses = (confirmed && ready && partiallyReady && collected && partiallyCollected)
    ? [
      confirmed.label, partiallyReady.label, ready.label,
      collected.label, partiallyCollected.label,
    ]
    : [];
  const reviewQtySourceStatuses = (pendingSource && toBeReviewed)
    ? [pendingSource.label, toBeReviewed.label]
    : [];

  const actionsRender = (
    status: string,
    sourceId: number,
    isChildLine?: boolean,
  ) => {
    const actions = [
      {
        label: 'Review quantity & source',
        key: 'reviewQtySource',
        icon: <FontAwesomeIcon icon={faCalculator} />,
        onClick: () => setDistributeQtyHandle(sourceId),
        allowedStatuses: reviewQtySourceStatuses,
      },
      {
        label: 'Edit ready/collected',
        icon: <FontAwesomeIcon icon={faEdit} />,
        key: 'editReadyCollected',
        onClick: () => setEditReadyCollected(sourceId),
        allowedStatuses: editReadyCollectedStatuses,
      },
      {
        label: 'Remap to existing part',
        icon: <FontAwesomeIcon icon={faExchange} />,
        key: 'remapToExistingPart',
        onClick: () => setRemapToExistingPart(sourceId),
        allowedStatuses: unknownPart ? [unknownPart.label] : [],
      },
      {
        label: 'Mark as Not for stock',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        key: 'markAsNotForStock',
        onClick: () => setIsMarkNotFromStock(sourceId),
        allowedStatuses: unknownPart ? [unknownPart.label] : [],
      },
      {
        label: 'Confirm returned parts',
        icon: <FontAwesomeIcon icon={faCircleCheck} />,
        key: 'confirmedReturnedParts',
        onClick: () => setConfirmReturnedParts(sourceId),
        allowedStatuses: deleted ? [deleted.label] : [],
      },
      {
        label: 'View details',
        icon: <FontAwesomeIcon icon={faCircleInfo} />,
        key: 'viewDetails',
        onClick: () => setViewDetails(sourceId),
        allowedStatuses: partReqLineActionsStatusesList as string[],
      },
    ];
    return isChildLine
      ? actions.filter((act) => act.key !== 'remapToExistingPart' && act.allowedStatuses.includes(status))
      : actions.filter((act) => act.allowedStatuses.includes(status));
  };

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip
      value={value}
      table
    />;
  };
  const renderCellValue = (
    key: string,
    value: unknown,
    unitOfMeasure: number,
    el: StockPartReqTableLineType | Omit<StockPartReqTableLineType, 'childLines'>,
  ): React.ReactNode => {
    switch (key) {
      case 'priceSources':
        return typeof value === 'string'
          ? <span>{value}</span>
          : (value as number[]).map((el: number, i: number) => <PriceSourceTag key={i} source={el} />);
      case 'quantity': {
        const isStatusAllowed = reviewQtySourceStatuses.includes(el.status);
        const clickable = isStatusAllowed && allowedToManage;
        return (
          <span
            className={clickable ? 'table-clickable-content' : ''}
            onClick={() => clickable && setDistributeQtyHandle(el.id)}
          >
            {value as number}
            {' '}
            {unitOfMeasures.find((unit) => unit.value === unitOfMeasure)?.label}
          </span>
        );
      }
      case 'readyQuantity':
      case 'collectedQuantity':
        if (el.status && el.status.toLowerCase() === deleted?.label?.toLowerCase()) {
          // eslint-disable-next-line max-len
          const text = 'Please confirm that parts have been returned back to stock (via three-dot menu option). After confirmation the deleted line will be removed from this part request.';
          return (
            <span>
              {value as (number | string)}
              {' '}
              <Tooltip title={text}>
                <FontAwesomeIcon icon={faInfoCircle} />
              </Tooltip>
            </span>
          );
        } else {
          const isStatusAllowed = editReadyCollectedStatuses.includes(el.status);
          const clickable = isStatusAllowed && allowedToManage;
          return <span
            className={clickable ? 'table-clickable-content' : ''}
            onClick={() => clickable && setEditReadyCollected(el.id)}
          >
            {value as (number | string)}
          </span>;
        }
      case 'status':
        return <Tag
          label={value as string}
          icon={deleted && (deleted.label === el.status) && el.deletionReason
            ? <Tooltip title={el.deletionReason} overlayClassName="tooltip-sm no-tooltip-arrow">
              <FontAwesomeIcon icon={faInfoCircle} />
            </Tooltip>
            : undefined}
          rowReverse
        />;
      case 'productName':
      case 'productNumber':
        if (el.partId || el.partsKitComponentPartId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/part/${el.partId || el.partsKitComponentPartId}`}
            permission={PermissionEnum.StockPartViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber)}
          </PrivateLineLink>;
        }
        if (el.partsKitId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${el.partsKitId}`}
            permission={PermissionEnum.StockPartsKitViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber)}
          </PrivateLineLink>;
        } else {
          return renderTruncateTooltip(key === 'productName' ? el.productName : el.productNumber);
        }
      case 'ordinalNumber':
        return <>{value}</>;
      default: return renderTruncateTooltip(value as string | null);
    }
  };

  return {
    actionsRender,
    renderCellValue,
    deleted,
    archived,
  };
};
