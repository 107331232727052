import React, { useState } from 'react';
import Modal from '../../../../../../core/components/modal/Modal';
import {
  DictionaryItem, NumberFunctionType, VoidFunctionType,
} from '../../../../../../core/types/coreTypes';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../../../../core/enums/errorsEnum';
import Select from '../../../../../../core/components/select/Select';

type FaultTypeModalProps = {
  isVisible: boolean,
  rectificationFaultTypes: DictionaryItem[],
  onCancel: VoidFunctionType,
  saveHandler: NumberFunctionType,
}
const FaultTypeModal: React.FC<FaultTypeModalProps> = ({
  isVisible, rectificationFaultTypes, onCancel, saveHandler,
}) => {
  const [value, setValue] = useState<number | undefined>(undefined);
  const [error, setError] = useState('');

  const closeModal = () => {
    onCancel();
    setValue(undefined);
    setError('');
  };

  return (
    <Modal visible={isVisible} onCancel={closeModal} title="Add fault type" destroyOnClose>
      <Select
        value={value}
        options={rectificationFaultTypes}
        label="Fault type"
        onChange={(v) => {
          error && setError('');
          setValue(+v);
        }}
        error={error}
        parentRender
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Save"
        cancelClick={closeModal}
        createClick={() => {
          if (!value) {
            setError(ErrorsEnum.REQUIRED);
          } else {
            saveHandler(value);
          }
        }}
        offsetTop
      />
    </Modal>
  );
};

export default FaultTypeModal;
