import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { getOrderStatus, getPartRequestLineStatuses } from '../../../store/thunks/core/coreStatusesThunks';
import { getOrderEventTypesThunk } from '../../../store/thunks/core/coreEventTypesThunks';

export const orderViewPageDictionariesHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(getOrderEventTypesThunk());
  dispatch(getOrderStatus());
  dispatch(getPartRequestLineStatuses());
};
