import React, { useEffect } from 'react';
import Layout from '../../core/components/layout/Layout';
import StockContent from './components/stock-content/StockContent';
import StockFilters from './components/stock-filters/StockFilters';
import './StockContainer.scss';
import { getPartTypes } from '../../store/thunks/stock/stockThunks';
import { useAppDispatch } from '../../store/hooks';
import { PurchaseUrlParamsNames } from '../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { useStockPageParams } from './hooks/useStockPageParams';
import { PartRequestsUrlParamsNames } from '../stock-part-requests/enums/stockPartRequestsEnum';

const StockContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getPartTypes());
    // eslint-disable-next-line
  }, []);

  const {
    params,
    setPageParam,
    setPurchaseStatusParam,
    setPurchaseTabTypeParam,
    setStockPartReqParam,
    setStockPartReqTabTypeParam,
  } = useStockPageParams();
  return (
    <Layout>
      <StockFilters
        view={params.get(PurchaseUrlParamsNames.PAGE)}
        setView={setPageParam}
        purchaseTabType={params.get(PurchaseUrlParamsNames.TAB_TYPE)}
        partRequestsTabType={params.get(PartRequestsUrlParamsNames.TAB_TYPE)}
        setPurchaseTabTypeParam={setPurchaseTabTypeParam}
        setStockPartReqTabTypeParam={setStockPartReqTabTypeParam}
      />
      <StockContent
        view={params.get(PurchaseUrlParamsNames.PAGE)}
        purchaseStatus={params.get(PurchaseUrlParamsNames.PURCHASE_STATUS)}
        tabType={params.get(PurchaseUrlParamsNames.TAB_TYPE)}
        setPurchaseStatus={setPurchaseStatusParam}
        partReqStatus={params.get(PartRequestsUrlParamsNames.PART_REQ_STATUS)}
        setPartRequestStatus={setStockPartReqParam}
      />
    </Layout>
  );
};

export default StockContainer;
