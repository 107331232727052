import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowDownShortWide, faFilter, faSearch } from '@fortawesome/free-solid-svg-icons';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import moment from 'moment';
import classNames from 'classnames';
import Button from '../button/Button';
import Input from '../input/Input';
import Slider from '../slider/Slider';
import CheckboxGroup from '../checkbox-group/CheckboxGroup';
import {
  CustomAny, NumericRangeType,
  DateRangeTableFilterType, RangeTableFiltersType, SearchTableFilterType, NumberFunctionType, StringFunctionType,
} from '../../types/coreTypes';
import RangePickerComponent from '../range-picker/RangePickerComponent';
import { commonMinDecimal, DEFAULT_TIMEZONE_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../utils/regex';
import TableControl from './TableControl';
import ThNew from './table-new/ThNew';
import { ICustomColumn } from '../../../common/types/commonTypes';

type DropdownFilterType = {
  dataIndex: string,
  value: CheckboxValueType[],
  onChangeHandle: (checkedValues: CheckboxValueType[]) => void,
}

type ThWithControlsProps = {
  col: ICustomColumn,
  sortHandle?: NumberFunctionType,
  searchVl?: SearchTableFilterType,
  onChangeSearchHandle?: (v: string, field: string) => void,
  searchPressHandle?: StringFunctionType
  totalElements: number,
  disabled?: boolean,
  setRangeHandle?: (field: string, value: [number, number]) => void,
  rangePatterns?: RangeTableFiltersType<NumericRangeType>,
  range?: RangeTableFiltersType,
  setRange?: (value: RangeTableFiltersType) => void,
  rangeStep?: number,
  filters?: DropdownFilterType[],
  className?: string,
  pickerValue?: DateRangeTableFilterType,
  setPickerValue?: (value: DateRangeTableFilterType) => void,
  pickerHandle?: (field: string, values?: {min: string | null, max: string | null}) => void,
  tableFilters?: CustomAny,
  disabledFilter?: boolean,
  insideModal?: boolean,
}

const ThWithControls: React.FC<ThWithControlsProps> = ({
  col, sortHandle, onChangeSearchHandle, searchPressHandle, rangePatterns,
  setRangeHandle, totalElements, disabled, range, setRange, rangeStep, disabledFilter,
  searchVl, filters, className, pickerValue, setPickerValue, pickerHandle, tableFilters,
  insideModal,
}) => {
  const activeSortIcon = col.orderField && (tableFilters?.order?.field === col.orderField);
  const descSortIcon = activeSortIcon && !tableFilters?.order?.isAsc;

  return (
    <ThNew key={col.dataIndex} className={className}>
      <div className="thContent">
        {col.title}
        {col.sorter && <Button
          icon={<FontAwesomeIcon icon={faArrowDownShortWide} />}
          onClick={() => sortHandle && col.orderField && sortHandle(col.orderField)}
          className={classNames('table__button', {
            'table__button--active': activeSortIcon,
            'table__button--disabled': totalElements === 0 || disabled,
            'table__button--order-desc': descSortIcon,
          })}
          disabled={totalElements === 0 || disabled}
        />}
        {col.filterSearch && <>
          <TableControl
            // filter values may be directly in tableFilters or in tableFilters.filters
            activeControl={tableFilters && (!!tableFilters?.[col.dataIndex] || !!tableFilters?.filters?.[col.dataIndex])}
            icon={<FontAwesomeIcon icon={faSearch} className="table__icon" />}
            disabled={disabledFilter}
            insideModal={insideModal}
            totalItems={totalElements}
            content={
              <Input
                value={searchVl ? searchVl[col.dataIndex] : ''}
                onChange={(e) => onChangeSearchHandle && onChangeSearchHandle(e.target.value, col.dataIndex)}
                onPressEnter={() => searchPressHandle && searchPressHandle(col.dataIndex)}
                className="table-searchInput-field"
                placeholder="Enter search keyword"
              />
            }
          />
        </>}
        {col.isFilterRange && <>
          <TableControl
            activeControl={range?.[col.dataIndex] && !!rangePatterns?.[col.dataIndex]
              && ((+range[col.dataIndex][0] !== rangePatterns[col.dataIndex].min)
                || (+range[col.dataIndex][1] !== rangePatterns[col.dataIndex].max))}
            icon={<FontAwesomeIcon icon={faFilter} className="table__icon" />}
            disabled={disabledFilter}
            insideModal={insideModal}
            totalItems={totalElements}
            content={
              <Slider
                step={rangeStep || 0.01}
                isTime={col.isTime}
                // eslint-disable-next-line max-len
                value={range && range[col.dataIndex] ? [range[col.dataIndex][0].toString(), range[col.dataIndex][1].toString()] : ['0', '0']}
                onChange={(value) => {
                  setRange && setRange({ ...range, [col.dataIndex]: [value[0], value[1]] });
                }}
                min={rangePatterns ? rangePatterns[col.dataIndex].min : commonMinDecimal}
                max={rangePatterns ? rangePatterns[col.dataIndex].max : 0.00}
                onAfterChange={(value) => setRangeHandle && setRangeHandle(col.dataIndex, [+value[0], +value[1]])}
              />
            }
          />
        </>}
        {col.filters && <>
          <TableControl
            activeControl={!!filters?.find((el) => el.dataIndex === col.dataIndex)?.value.length}
            icon={<FontAwesomeIcon icon={faFilter} className="table__icon" />}
            maxWidth="250px"
            maxHeight="350px"
            disabled={disabledFilter || col.filters.length === 0}
            insideModal={insideModal}
            totalItems={totalElements}
            content={
              <CheckboxGroup
                options={col.filters || []}
                value={filters?.find((el) => el.dataIndex === col.dataIndex)?.value || []}
                onChange={(checkedValues) => {
                  const targetChangeHandle = filters?.find((el) => el.dataIndex === col.dataIndex)?.onChangeHandle;
                  targetChangeHandle && targetChangeHandle(checkedValues);
                }}
              />
            }
          />
        </>}
        {col.isDateRange && <>
          <TableControl
            disabled={disabledFilter}
            activeControl={tableFilters?.[col.dataIndex] && Object.values(tableFilters[col.dataIndex]).some(Boolean)}
            icon={<FontAwesomeIcon icon={faFilter} className="table__icon" id="rangeIcon" />}
            insideModal={insideModal}
            totalItems={totalElements}
            content={
              <RangePickerComponent
                allowEmpty={[true, true]}
                value={pickerValue ? pickerValue[col.dataIndex] : undefined}
                onChange={(v) => {
                  if (v) {
                    setPickerValue && setPickerValue({ ...pickerValue, [col.dataIndex]: v });
                    const formattedValues = [
                      v[0] ? moment(v[0]).startOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT) : v[0],
                      v[1] ? moment(v[1]).endOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT) : v[1],
                    ];
                    const values = {
                      min: formattedValues[0],
                      max: formattedValues[1],
                    };
                    pickerHandle && pickerHandle(col.dataIndex, values);
                  } else {
                    setPickerValue && setPickerValue({ ...pickerValue, [col.dataIndex]: [null, null] });
                    pickerHandle && pickerHandle(col.dataIndex, undefined);
                  }
                }}
                format={DEFAULT_UI_DATE_FORMAT}
              />
            }
          />
        </>}
      </div>
    </ThNew>
  );
};

export default ThWithControls;
