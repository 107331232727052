import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../permissions-handling/permissionHook';
import RequisiteSection from '../../../../../common/components/requisite/RequisiteSection';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  supplierDetailsSelector,
  supplierLinkedEntitiesSelector,
} from '../../../../../store/selectors/supplierSelector';
import Input from '../../../../../core/components/input/Input';
import RequisiteModal from '../../../../../common/components/requisite/RequisteModal';
import { patchSupplierThunk } from '../../../../../store/thunks/suppliers/suppliersViewPageThunks';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { checkIsFormHasDuplicateError } from '../../../../../core/utils/checkIsDuplicateError';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  PatchSupplierRestModel, supplierDefaults, SupplierFields, SupplierSchema,
} from '../../../../create-supplier/utils/SupplierSchema';
import { RequisiteDto } from '../../../../../common/types/commonTypes';

type FieldsToEdit = {
  field: keyof Pick<SupplierFields, 'name' | 'supplierAccountNumber'> | null,
  label: string,
  value: string,
}

const SupplierDetailsSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const allowedToEdit = usePermission(PermissionEnum.SupplierEditFields);
  const details = useAppSelector(supplierDetailsSelector);
  const supplierLinkedEntities = useAppSelector(supplierLinkedEntitiesSelector);
  const [fieldToEdit, setFieldToEdit] = useState<FieldsToEdit>({ field: null, label: '', value: '' });
  const [isConfirm, setIsConfirm] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const { supplier: supplierDetails, billingRequisite = null } = details || {};

  const {
    control, setValue, setError, clearErrors,
  } = useForm<SupplierFields>({
    resolver: zodResolver(SupplierSchema),
    defaultValues: supplierDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const fieldsToValidate = ['name', 'supplierAccountNumber', 'vatNumber', 'companyRegistrationNumber'];
  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, supplierDetails, setValue);
  };

  useEffect(() => {
    if (supplierDetails) {
      Object.keys(supplierDetails).forEach((key) => {
        if (fieldsToValidate.includes(key)) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(key, supplierDetails[key]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierDetails]);

  const closeAffectedModal = () => {
    setIsConfirm(false);
    setFieldToEdit({ field: null, label: '', value: '' });
  };

  const editField = (field: Partial<SupplierFields>, key: keyof SupplierFields, isOptional?: boolean) => {
    if (supplierDetails) {
      const details = {
        ...supplierDetails,
        name: supplierDetails.name || '',
      };
      const handler = () => {
        dispatch(patchSupplierThunk({
          id: supplierDetails.id,
          data: { ...field },
          setError,
          onClose: () => {
            closeAffectedModal();
            clearErrors();
          },
        }));
      };
      compareValuesHandle<PatchSupplierRestModel>(field, key, details, handler, false, isOptional);
    }
  };

  const editRequisite = (requisite: RequisiteDto) => {
    supplierDetails && dispatch(patchSupplierThunk({
      id: supplierDetails.id,
      data: { billingRequisite: requisite },
      onClose: () => setIsVisible(false),
    }));
  };

  const textContent = {
    text: <>
      Supplier
      {' '}
      <b>{`"${supplierDetails?.name}"`}</b>
      {' '}
      is used in multiple entities in the system.
    </>,
    confirmationText: <>
      Please note that
      {' '}
      <b>{`"${fieldToEdit?.label}"`}</b>
      {' '}
      value will be updated to a new value in all linked entities.
    </>,
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirm}
        onCancel={() => {
          const { field } = fieldToEdit;
          field && setValue(field, supplierDetails?.[field] || '');
          closeAffectedModal();
        }}
        confirmHandler={() => {
          const { field } = fieldToEdit;
          field && editField({ [field]: fieldToEdit.value.trim() }, field);
        }}
        customTitle="Confirm"
        customText={textContent.text}
        customProceedText={textContent.confirmationText}
        customCreateLabel="Yes, update"
      />
      <RequisiteModal
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
        }}
        requisite={billingRequisite}
        submitHandle={editRequisite}
      />
      <section className="supplierDetailsActivity__details info-grid__column">
        <h2 className="info-grid__title">Details</h2>
        <form className="details-form">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                label="Supplier name"
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('name');
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!value.trim()) {
                    setValue('name', '');
                  }
                  if (!errors.name && value !== supplierDetails?.name) {
                    if (supplierLinkedEntities.some((el) => !!el)) {
                      setFieldToEdit({ field: field.name, label: 'Supplier name', value });
                      setIsConfirm(true);
                    } else {
                      if (value.trim()) {
                        editField({ [field.name]: value.trim() }, 'name');
                      }
                    }
                  }
                }}
                error={errors.name?.message}
                disabled={!allowedToEdit}
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            control={control}
            name="supplierAccountNumber"
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('supplierAccountNumber');
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!errors.supplierAccountNumber && value !== supplierDetails?.supplierAccountNumber) {
                    if (supplierLinkedEntities.some((el) => !!el)) {
                      setFieldToEdit({ field: field.name, label: 'Supplier account No', value });
                      setIsConfirm(true);
                    } else {
                      editField({ [field.name]: value }, 'supplierAccountNumber');
                    }
                  }
                }}
                error={errors.supplierAccountNumber?.message}
                disabled={!allowedToEdit}
                label="Supplier account No"
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            control={control}
            name="vatNumber"
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('vatNumber');
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!checkIsFormHasDuplicateError(errors, 'vatNumber') && field.value !== supplierDetails?.vatNumber) {
                    editField({ [field.name]: value }, 'vatNumber', true);
                  }
                }}
                error={errors.vatNumber?.message}
                disabled={!allowedToEdit}
                label="VAT number"
              />
            )}
          />
          <Controller
            control={control}
            name="companyRegistrationNumber"
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('companyRegistrationNumber');
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!checkIsFormHasDuplicateError(errors, 'companyRegistrationNumber')
                    && field.value !== supplierDetails?.companyRegistrationNumber) {
                    editField({ [field.name]: value }, 'companyRegistrationNumber', true);
                  }
                }}
                error={errors.companyRegistrationNumber?.message}
                disabled={!allowedToEdit}
                label="Company registration number"
              />
            )}
          />
          <RequisiteSection
            title="Billing details"
            requisiteDetails={billingRequisite}
            setIsVisible={() => {
              setIsVisible(true);
              clearOtherErrorsHandle('billingDetails');
            }}
            disabled={!allowedToEdit}
          />
        </form>
      </section>
    </>
  );
};

export default SupplierDetailsSection;
