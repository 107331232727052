import React, { useEffect } from 'react';
import Layout from '../core/components/layout/Layout';
import './HomePage.scss';
import Carousel from '../core/components/carousel/Carousel';
import { currentUserSelector } from '../store/selectors/accountSelectors';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import JARVIS_LOGO from '../assets/images/jarvis-logo.png';
import USER from '../assets/images/user.png';
import { infoMessagesListFiltersSelector, infoMessagesListSelector } from '../store/selectors/settingsSelectors';
import { getInfoMessagesList } from '../store/thunks/settings/infoMessagesThunks';
import { infoMessagesStatusValuesSelector } from '../store/selectors/coreStatusesSelectors';
import { useDefaultPagingAndOrdering } from '../core/hooks/useDefaultPagingAndOrdering';
import { infoMessagesOrderingSelector } from '../store/selectors/coreOrderingSelectors';
import { getInfoMessagesOrderingThunk } from '../store/thunks/core/coreOrderingThunks';
import { setInfoMessagesListFilters } from '../store/slices/settingsSlice';
import { InfoMessagesOrdering } from '../settings/info-messages/utils/data';

const HomePage: React.FC = () => {
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const infoMessagesFilters = useAppSelector(infoMessagesListFiltersSelector);
  const infoMessagesOrdering = useAppSelector(infoMessagesOrderingSelector);
  const infoMessagesList = useAppSelector(infoMessagesListSelector);
  const { published } = useAppSelector(infoMessagesStatusValuesSelector);

  useDefaultPagingAndOrdering({
    filters: infoMessagesFilters,
    ordering: infoMessagesOrdering,
    getOrderingThunk: getInfoMessagesOrderingThunk,
    setFiltersAction: setInfoMessagesListFilters,
    initialOrdering: InfoMessagesOrdering.DisplayOrder,
  });

  useEffect(() => {
    infoMessagesFilters.order?.field && currentUser && published && dispatch(getInfoMessagesList({
      filters: {
        ...infoMessagesFilters,
        page: 1,
        pageSize: 1000,
        status: [published],
      },
    }));
    // eslint-disable-next-line
  }, [currentUser, infoMessagesFilters.order?.field, published]);

  return (
    <Layout mainClassName="layoutHomepage">
      <div className="homepage">
        {infoMessagesList.items.length > 0
            && <Carousel slidesContent={infoMessagesList.items.map((item) => item.message)} />}
        <div className="homepage__text">
          <img src={USER} alt="" className="homepage__user" />
          <span className="homepage__text-line homepage__text-line-name">
            Hi,
            <span className="homepage__text-name">
              {' '}
              {currentUser?.firstName}
              {' '}
              {currentUser?.lastName}
              !
            </span>
          </span>
          <span className="homepage__text-line">
            Welcome to
            {' '}
          </span>
          <img src={JARVIS_LOGO} alt="" className="homepage__logo" />
        </div>
      </div>
    </Layout>
  );
};

export default HomePage;
