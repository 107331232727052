export const partRequestChangesColumns = [
  { title: 'Activity', dataIndex: 'activity' },
  { title: 'No', dataIndex: 'number' },
  { title: 'Category', dataIndex: 'category' },
  { title: 'Product No', dataIndex: 'productNumber' },
  { title: 'Product name', dataIndex: 'productName' },
  { title: 'Notes', dataIndex: 'notes' },
  { title: 'Qty', dataIndex: 'quantity' },
  { title: 'Unit price', dataIndex: 'unitPrice' },
  { title: 'Source', dataIndex: 'priceSources' },
  { title: 'Discount', dataIndex: 'discount' },
  { title: 'Net price', dataIndex: 'netPrice' },
  { title: 'Tax', dataIndex: 'tax' },
  { title: 'Weight', dataIndex: 'weight' },
  { title: 'Status', dataIndex: 'status' },
];

export enum PartRequestLineActivityTypeEnum {
  New = 'New',
  Updated = 'Updated',
  Deleted = 'Deleted',
}
