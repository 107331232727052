import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import Switch from '../../../../../../core/components/switch/Switch';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../../core/components/timeline/Timeline';
import { setEmployeeActivityFilters } from '../../../../../../store/slices/employeesSlice';
import {
  employeeActivityFiltersSelector,
  employeeTimelineSelector,
} from '../../../../../../store/selectors/employeesSelector';
import EmployeeEvent from './EmployeeEvent';
import {
  getEmployeeTimelineThunk,
  postEmployeeTimelineNoteThunk,
} from '../../../../../../store/thunks/employee/employeeViewPageThunk';
import ActivityLabel from '../../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../../common/components/activity-events/ActivityNoteInput';
import { useEmployeeActivityDictionaries } from '../../../../hooks/useEmployeeActivityDictionaries';

const EmployeeActivityLog: React.FC = () => {
  const [value, setValue] = useState('');
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const timelineFilters = useAppSelector(employeeActivityFiltersSelector);
  const { items, totalCount } = useAppSelector(employeeTimelineSelector);
  const allowedToAddNote = usePermission(PermissionEnum.EmployeeViewPage);

  useEmployeeActivityDictionaries();

  useEffect(() => {
    id && dispatch(getEmployeeTimelineThunk({
      employeeId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
  }, [id, timelineFilters]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <EmployeeEvent item={el} />,
  }));

  const addNote = () => {
    id && dispatch(postEmployeeTimelineNoteThunk({
      employeeId: +id, content: value.trim(), setValue: () => setValue(''),
    }));
  };
  return (
    <section className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(val) => dispatch(setEmployeeActivityFilters({ ...timelineFilters, mainOnly: !val, page: 1 }))}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setEmployeeActivityFilters({ ...timelineFilters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default EmployeeActivityLog;
