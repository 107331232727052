import React from 'react';
import { Switch as SwitchComponent } from 'antd';
import classnames from 'classnames';
import { BooleanFunctionType } from '../../types/coreTypes';
import './Switch.scss';

type SwitchProps = {
  checked: boolean;
  onChange: BooleanFunctionType;
  disabled?: boolean;
  loading?: boolean;
  textLeft?: string;
  textRight?: string;
  className?: string;
  singleBackground?: boolean,
}

const Switch: React.FC<SwitchProps> = ({
  checked,
  onChange,
  disabled,
  loading,
  textLeft,
  textRight,
  className,
  singleBackground,
}) => (
  <div className={classnames(className, 'switch')}>
    {textLeft && <span className="switch__labelLeft">{textLeft}</span>}
    <SwitchComponent
      checked={checked}
      onChange={onChange}
      disabled={disabled}
      loading={loading}
      className={classnames('switch__component', { 'switch__component--single-background': singleBackground })}
    />
    {textRight && <span className="switch__labelRight">{textRight}</span>}
  </div>
);

export default Switch;
