import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import EmptyJobList from './EmptyJobList';
import JobLine from './JobLine';
import { jobListFiltersSelector, jobListUiSelector } from '../../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setJobListFilters } from '../../../store/slices/jobsSlice';
import { jobCleanUpHandle } from '../../job-view-page/utils/jobCleanUpHandle';

const JobListComponent: React.FC = () => {
  const dispatch = useAppDispatch();
  const jobListFilters = useAppSelector(jobListFiltersSelector);
  const jobList = useAppSelector(jobListUiSelector);
  const [openedJob, setOpenedJob] = useState<number | undefined>(undefined);

  const chevronClick = (jobId: number) => {
    jobCleanUpHandle(dispatch, true);
    if (openedJob === jobId) {
      setOpenedJob(undefined);
    } else setOpenedJob(jobId);
  };

  const loadMore = () => {
    const newValues = { ...jobListFilters, page: jobListFilters.page + 1 };
    dispatch(setJobListFilters(newValues));
  };
  return (
    jobList.totalCount
      ? <InfiniteScroll
        dataLength={jobList.items.length}
        next={loadMore}
        hasMore={jobListFilters.page < jobList.pages}
        loader={<span className="contacts__loader">Loading...</span>}
      >
        <ul className="lines-list">
          {jobList.items.map((job) => (
            <li className="lines-list__item" key={job.id}>
              <JobLine job={job} openedJob={openedJob} chevronClick={chevronClick} />
            </li>
          ))}
        </ul>
      </InfiniteScroll>
      : <EmptyJobList />
  );
};

export default JobListComponent;
