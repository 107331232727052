import { RequisiteDto } from '../types/commonTypes';

export type RequisiteFields = { billing: RequisiteDto, shipping: RequisiteDto }
export const initRequisite = {
  firstName: null,
  lastName: null,
  email: null,
  telephone: null,
  mobile: null,
  companyName: null,
  addressLine1: null,
  addressLine2: null,
  cityTown: null,
  region: null,
  postalCode: null,
};
