import React from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { Tabs } from 'antd';
import { faFolder } from '@fortawesome/free-regular-svg-icons';
import { mockedFolders } from '../utils/data';
import Button from '../../core/components/button/Button';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setCurrentFolder } from '../../store/slices/formsSlice';
import { formsCurrentFolderSelector } from '../../store/selectors/formsSelectors';
import { BooleanFunctionType, NumberFunctionType } from '../../core/types/coreTypes';
import { TruncateTooltip } from '../../common/components/truncate-tooltip/TruncateTooltip';
import FormsTabPaneContent from './FormsTabPaneContent';
import EmptyContentList from '../../core/components/empty-content-list/EmptyContentList';

const { TabPane } = Tabs;

type FormsFoldersProps = {
  setCreateFolder: BooleanFunctionType,
  checkedKeys: number[],
  setIsAll: BooleanFunctionType,
  setCheckedKeys: (v: number[]) => void,
  setCreateModal: BooleanFunctionType,
  setDeleteModal: BooleanFunctionType,
  setMoveToFolder: BooleanFunctionType,
  isAll: boolean,
  setIsEditFolder: BooleanFunctionType,
  setFolderToChange: NumberFunctionType,
}

const FormsFolders: React.FC<FormsFoldersProps> = ({
  setCreateFolder,
  checkedKeys,
  setCheckedKeys,
  setIsAll,
  setCreateModal,
  setDeleteModal,
  setMoveToFolder,
  isAll,
  setIsEditFolder,
  setFolderToChange,
}) => {
  const dispatch = useAppDispatch();
  const formsCurrentFolder = useAppSelector(formsCurrentFolderSelector);
  const currentFolderHandle = (v: string) => {
    dispatch(setCurrentFolder(+v));
  };
  const MOCK_FILES_COUNT = 23;
  return (
    <Tabs
      renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile={false} />
      )}
      tabPosition="top"
      activeKey={formsCurrentFolder?.toString()}
      onChange={currentFolderHandle}
      className={classnames('formsFolders', 'scrolling-tabs', { 'scrolling-tabs--empty': mockedFolders.length === 0 })}
      popupClassName="scrolling-tabs__popup"
      tabBarExtraContent={
        <Button
          label="Create folder"
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          onClick={() => setCreateFolder(true)}
          designType="dark"
          reversed
        />
      }
      destroyInactiveTabPane
    >
      {mockedFolders.length ? mockedFolders.map((el) => (
        <TabPane
          tab={<span className="scrolling-tabs__tab-button">
            <TruncateTooltip value={el.label} className="scrolling-tabs__tab-label" hideTooltipOnMobile />
            {!!MOCK_FILES_COUNT && <span className="scrolling-tabs__count-value">{`(${MOCK_FILES_COUNT})`}</span>}
          </span>}
          key={el.value}
        >
          <FormsTabPaneContent
            checkedKeys={checkedKeys}
            setIsAll={setIsAll}
            setCheckedKeys={setCheckedKeys}
            setCreateModal={setCreateModal}
            setDeleteModal={setDeleteModal}
            setMoveToFolder={setMoveToFolder}
            isAll={isAll}
            setIsEditFolder={setIsEditFolder}
            setCreateFolder={setCreateFolder}
            setFolderToChange={setFolderToChange}
          />
        </TabPane>
      ))
        : <EmptyContentList icon={faFolder} text="Please create a file folder" />}
    </Tabs>
  );
};

export default FormsFolders;
