import React from 'react';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import EmptyContentList from '../empty-content-list/EmptyContentList';
import InAppNotificationsContent from './InAppNotificationsContent';
import { useAppSelector } from '../../../store/hooks';
import { inAppNotificationsSelector } from '../../../store/selectors/accountSelectors';
import { BooleanFunctionType, VoidFunctionType } from '../../types/coreTypes';

type InAppNotificationsWrapperProps = {
  setIsClearAllMessages: BooleanFunctionType,
  onClose: VoidFunctionType,
}

const InAppNotificationsWrapper: React.FC<InAppNotificationsWrapperProps> = ({ setIsClearAllMessages, onClose }) => {
  const { totalCount } = useAppSelector(inAppNotificationsSelector);

  return (
    <>
      {totalCount > 0
        ? <InAppNotificationsContent setIsClearAllMessages={setIsClearAllMessages} onClose={onClose} />
        : <EmptyContentList icon={faBell} text="No new notifications" className="emptyNotifications" />}
    </>
  );
};

export default InAppNotificationsWrapper;
