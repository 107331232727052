import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../core/components/button/Button';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';

type AccountFieldProps = {
  label: string,
  value: string,
  onClick?: VoidFunctionType,
  disabled?: boolean,
}
const AccountField:React.FC<AccountFieldProps> = ({
  label, value, onClick, disabled,
}) => {
  return (
    <div className="accountField details-form__field--lg">
      <span className="accountField__label">{label}</span>
      <div className="accountField__content">
        <TruncateTooltip value={value} className="accountField__value" />
        {!disabled && onClick && (
          <Button
            icon={<FontAwesomeIcon icon={faPencilAlt} />}
            onClick={onClick}
            className="accountField__button"
          />
        )}
      </div>
    </div>
  );
};

export default AccountField;
