import React, { useEffect, useState } from 'react';
import { VoidFunctionType } from '../../core/types/coreTypes';
import Modal from '../../core/components/modal/Modal';
import ButtonActions from '../../core/components/button-actions/ButtonActions';
import Select from '../../core/components/select/Select';
import { useAppSelector } from '../../store/hooks';
import { formsCurrentFolderSelector } from '../../store/selectors/formsSelectors';

type ChangeFolderForFilesProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  checkedKeys: number[],
}

const ChangeFolderForFilesModal: React.FC<ChangeFolderForFilesProps> = ({
  visible, onCancel, checkedKeys,
}) => {
  const formsCurrentFolder = useAppSelector(formsCurrentFolderSelector);

  const [folderV, setFolderV] = useState<number | undefined>(undefined);
  const createHandle = () => {
    console.log('change folder', folderV, checkedKeys);
  };
  const cancelHandle = () => {
    setFolderV(undefined);
    onCancel();
  };

  useEffect(() => {
    if (visible && formsCurrentFolder) {
      setFolderV(formsCurrentFolder);
    }
  }, [formsCurrentFolder, visible]);
  return (
    <Modal title="Change folder" visible={visible} onCancel={cancelHandle}>
      <Select
        label="File folder"
        value={folderV}
        onChange={(value) => {
          setFolderV(value as number);
        }}
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Create"
        cancelClick={cancelHandle}
        createClick={createHandle}
      />
    </Modal>
  );
};

export default ChangeFolderForFilesModal;
