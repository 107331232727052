import { z } from 'zod';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { commonVehicleCheckDefaults, CommonVehicleCheckSchema } from './CommonVehicleCheckSchema';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const WeightVehicleSchema = z.object({
  vehicleWeightKg: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED),
});

export const WeightVehicleCheckSchema = WeightVehicleSchema.merge(CommonVehicleCheckSchema);

export type WeightCheck = z.infer<typeof WeightVehicleCheckSchema>;

export const weightCheckDefaults: WeightCheck = {
  ...commonVehicleCheckDefaults,
  vehicleWeightKg: '',
};
