import { createAsyncThunk } from '@reduxjs/toolkit';
import { setDashboardLoading, setFetching, setLoading } from '../../slices/coreSlice';
import {
  AxiosErrorResponse, BooleanFunctionType, DictionaryItem, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { ordersAPI } from '../../../api/ordersApi';
import {
  GetOrderListResponse,
  GetOrderResponse,
  OrderViewsFilters,
} from '../../../orders/dashboard/types/ordersTypes';
import { stockApi } from '../../../api/stockApi';
import { GetPartRequestLineStateSummaryResponse } from '../../../stock/stock-part-request-view/types/StockPartrequestViewTypes';
import { OrderEventDtoBase, OrderSummaryType } from '../../../orders/orders-view-page/types/OrderViewPageTypes';
import {
  ExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
} from '../../../common/types/commonTypes';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';

export const getSalesOrdersList = createAsyncThunk<GetOrderListResponse,
{ filters: OrderViewsFilters, signal?: AbortSignal }
>(
  'get/SalesOrdersList',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const res = await ordersAPI.fetchOrderViewsList(filters, signal);
      dispatch(setDashboardLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOrderById = createAsyncThunk<GetOrderResponse,
{ id: number }
>(
  'get/SalesOrderById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await ordersAPI.fetchOrderById(id);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeOrderStatusThunk = createAsyncThunk<ResponseSingleResult,
{ orderId: number, statusTransition: number, onFinish: VoidFunctionType}>(
  'put/SalesOrderStatus',
  async ({ orderId, statusTransition, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await ordersAPI.changeOrderStatus(orderId, statusTransition);
      onFinish();
      dispatch(setFetching(false));
      dispatch(getSalesOrderById({ id: orderId }));
      return response.data;
    } catch (err) {
      dispatch(setFetching(false));
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOrderSummaryThunk = createAsyncThunk<
ResponseSingleResult<OrderSummaryType>, { id: number }
>('get/SalesOrderSummary', async ({ id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await ordersAPI.fetchOrderSummary(id);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const getSalesOrderPartRequestLinesThunk = createAsyncThunk<ResponseResult<PartRequestLineDto[], {
  allItemIds: number[],
  nominalCodes: DictionaryItem[],
}>, {
  partRequestId: number, filters?: PartReqLinesFilters,
}>(
  'get/SalesOrderPartRequestLines',
  async ({ partRequestId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLines({ ...filters, partRequestIds: [partRequestId] }, false);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesPartRequestLineSummary = createAsyncThunk<ResponseSingleResult<GetPartRequestLineStateSummaryResponse>,
{ partRequestId: number, lineId: number }
>(
  'get/SalesPartRequestLineSummary',
  async ({ partRequestId, lineId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartRequestLineSummary(partRequestId, lineId);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOrderTimelineThunk = createAsyncThunk<ResponseResult<OrderEventDtoBase[]>, {
  orderId: number, source: number, filters: ExtendedTimelineFilters, setIsLoading: BooleanFunctionType,
}>(
  'get/SalesOrderTimeline',
  async ({
    orderId, source, filters, setIsLoading,
  }, { rejectWithValue }) => {
    setIsLoading(true);
    try {
      const response = await ordersAPI.fetchOrderTimeline(orderId, filters, source);
      setIsLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setIsLoading(false);
      return rejectWithValue(error.response?.data);
    }
  },
);
