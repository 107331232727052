import { useParams, useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { employeeAttachmentsFilterSelector } from '../../../store/selectors/employeesSelector';
import { EmployeeViewModeParamsEnum } from '../enums/EmployeeViewEnums';
import { getEmployeeAttachmentsThunk } from '../../../store/thunks/employee/employeeAttachmentsThunks';
import { getEmployeeIceContactThunk, getEmployeeSkillsByIdThunk } from '../../../store/thunks/employee/employeeViewPageThunk';

export const useFetchCurrentTabData = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const attachmentsFilters = useAppSelector(employeeAttachmentsFilterSelector);
  const [params] = useSearchParams();
  const tab = params.get('tab');

  const fetchSkills = () => {
    id && dispatch(getEmployeeSkillsByIdThunk({
      id: +id,
    }));
  };

  const fetchAttachments = () => {
    id && dispatch(getEmployeeAttachmentsThunk({
      employeeId: +id,
      filters: attachmentsFilters,
    }));
  };

  const fetchIceContact = () => {
    id && dispatch(getEmployeeIceContactThunk({
      employeeId: +id,
    }));
  };

  const fetchCurrentTabData = () => {
    switch (tab) {
      case EmployeeViewModeParamsEnum.EMPLOYEE_SKILLS:
        return fetchSkills();
      case EmployeeViewModeParamsEnum.EMPLOYEE_ICE_CONTACT:
        return fetchIceContact();
      case EmployeeViewModeParamsEnum.EMPLOYEE_ATTACHMENTS:
        return fetchAttachments();
      default:
        return null;
    }
  };

  return { fetchCurrentTabData, tab };
};
