import React from 'react';
import { kitComponentsColumnsSelector } from '../../../../../../../../store/selectors/partsKitSelectors';
import { useAppSelector } from '../../../../../../../../store/hooks';
import Tr from '../../../../../../../../core/components/table-components/Tr';
import ThNew from '../../../../../../../../core/components/table-components/table-new/ThNew';

const PartsKitTimelineComponentsGroupColumns: React.FC = () => {
  const kitComponentsColumns = useAppSelector(kitComponentsColumnsSelector);
  const groupColumns = kitComponentsColumns.map(({ title, dataIndex }) => ({ title, dataIndex }));
  return (
    <Tr>
      {groupColumns.map((col, i) => <ThNew
        key={i}
        className={`partsKitTimelineComponentsTh--${col.dataIndex}`}
      >
        {col.title}
      </ThNew>)}
    </Tr>
  );
};

export default PartsKitTimelineComponentsGroupColumns;
