import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle, faCopy, faFileExcel, faTimesCircle, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { PricelistModeEnum } from '../enums/PricelistModeEnum';
import { PermissionEnum, PricelistStatusUiNames } from '../../../core/enums/dictionariesEnums';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';
import {
  activatePricelistThunk, generatePricelistToExcel,
  getPricelistAffectedThunk,
} from '../../../store/thunks/pricelist/pricelistDetailsThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { pricelistStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import {
  pricelistAffectedFiltersSelector,
  pricelistDetailsSelector,
  pricelistPartsSelector,
} from '../../../store/selectors/pricelistSelectors';
import { BooleanFunctionType } from '../../../core/types/coreTypes';

export const usePricelistDetailsTabsAndActions = (
  setAffected: BooleanFunctionType,
  setConfirm: BooleanFunctionType,
  setSimpleInactivate: BooleanFunctionType,
  setDuplicate: BooleanFunctionType,
) => {
  const dispatch = useAppDispatch();
  const pricelistAffectedFilters = useAppSelector(pricelistAffectedFiltersSelector);
  const pricelistStatuses = useAppSelector(pricelistStatusesSelector);
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const pricelistParts = useAppSelector(pricelistPartsSelector);
  const id = pricelistDetails?.id;
  const checkStatusHandler = (statusId: number | undefined, statusUiName: string) : boolean => {
    const currentStatus = pricelistStatuses.find((el) => el.value === statusId);
    return currentStatus?.label === statusUiName;
  };
  const options = [
    {
      label: 'Details & activity',
      value: PricelistModeEnum.PRICELIST_DETAILS,
    },
    {
      label: 'Prices',
      value: PricelistModeEnum.PRICELIST_PRICES,
    },
    {
      label: `Attachments ${tabCountHandle(pricelistDetails?.attachmentsCount)}`,
      value: PricelistModeEnum.PRICELIST_ATTACHMENTS,
    },
  ];

  const actions = [
    {
      label: 'Duplicate pricelist',
      key: '23',
      icon: <FontAwesomeIcon icon={faCopy} />,
      onClick: () => setDuplicate(true),
      restrictedTo: PermissionEnum.PricelistEditFields,
    },
    {
      label: 'Activate pricelist',
      key: 'd3',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => id && dispatch(activatePricelistThunk({ id })),
      restrictedTo: PermissionEnum.PricelistEditFields,
      disabled: checkStatusHandler(pricelistDetails?.status, PricelistStatusUiNames.Active),
    },
    {
      label: 'Inactivate pricelist',
      key: '4f3',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => id && dispatch(getPricelistAffectedThunk({
        pricelistId: id,
        filters: pricelistAffectedFilters,
        complexAction: () => setAffected(true),
        confirmAction: () => setSimpleInactivate(true),
      })),
      restrictedTo: PermissionEnum.PricelistEditFields,
      disabled: checkStatusHandler(pricelistDetails?.status, PricelistStatusUiNames.Inactive),
    },
    {
      label: 'Export to Excel',
      key: 'excelPricelist',
      icon: <FontAwesomeIcon icon={faFileExcel} />,
      onClick: () => id && dispatch(generatePricelistToExcel({ id: +id })),
      restrictedTo: PermissionEnum.PricelistExportToExcel,
      disabled: pricelistParts.totalCount === 0,
    },
    {
      label: 'Delete pricelist',
      key: '3fr4',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => id && dispatch(getPricelistAffectedThunk({
        pricelistId: id,
        filters: pricelistAffectedFilters,
        complexAction: () => setAffected(true),
        confirmAction: () => setConfirm(true),
      })),
      restrictedTo: PermissionEnum.PricelistDelete,
    },
  ];

  return {
    actions,
    options,
  };
};
