import React, { useEffect, useState } from 'react';
import Modal from '../../core/components/modal/Modal';
import { VoidFunctionType } from '../../core/types/coreTypes';
import ButtonActions from '../../core/components/button-actions/ButtonActions';
import Input from '../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { mockedFolders } from '../utils/data';

type CreateFolderModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  editMode?: boolean,
  folderToChange?: number,
}

const CreateFolderModal: React.FC<CreateFolderModalProps> = ({
  visible, onCancel, editMode, folderToChange,
}) => {
  const [nameV, setNameV] = useState<string>('');
  const [nameErr, setNameErr] = useState<string>('');
  const createHandle = () => {
    // !! CHECK if --> Entered value is already being used in the system
    console.log('create', nameV.trim());
  };
  const cancelHandle = () => {
    setNameErr('');
    setNameV('');
    onCancel();
  };

  useEffect(() => {
    if (editMode && folderToChange) {
      const currentName = mockedFolders.find((f) => f.value === folderToChange)?.label;
      currentName && setNameV(currentName);
    }
  }, [editMode, folderToChange]);
  return (
    <Modal title="Create file folder" visible={visible} onCancel={cancelHandle}>
      <Input
        label="Name"
        value={nameV}
        onChange={(e) => {
          const v = e.target.value;
          if (v.trim().length === 0) {
            setNameErr(ErrorsEnum.REQUIRED);
          } else {
            if (v.length > 50) {
              setNameErr(maxLengthMessageHandle(50));
            } else {
              nameErr && setNameErr('');
            }
          }
          setNameV(e.target.value);
        }}
        error={nameErr}
        className="createFolderInput"
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Create"
        cancelClick={cancelHandle}
        createClick={createHandle}
      />
    </Modal>
  );
};

export default CreateFolderModal;
