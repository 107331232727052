import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { invoiceDocumentStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { fieldNameEventHandle } from '../../../utils/activityLogUtils';

enum InvoicesAndCreditNotesEventsEnum {
  InvoiceDocumentCreation = 'InvoiceDocumentCreation',
  InvoiceDocumentFieldUpdate = 'InvoiceDocumentFieldUpdate',
  InvoiceDocumentDeletion = 'InvoiceDocumentDeletion',
}

type InvoicesAndCreditNotesEventsProps = {
  eventName: string,
  entityType: string,
  entityNumber: string,
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const InvoicesAndCreditNotesEvents: React.FC<InvoicesAndCreditNotesEventsProps> = ({
  eventName,
  entityType,
  entityNumber,
  field,
  oldValue,
  newValue,
}) => {
  const statuses = useAppSelector(invoiceDocumentStatusesSelector);

  const valueHandle = (value: string | number) => {
    switch (field) {
      case 'InvoiceDate':
      case 'DueDate':
        return localDateFormatHandler('DD-MMM-YYYY', value as string);
      case 'Status':
        return statuses.find((status) => status.value === +value)?.label;
      default: return value;
    }
  };

  const renderContent = () => {
    switch (eventName) {
      case InvoicesAndCreditNotesEventsEnum.InvoiceDocumentCreation:
        return <>
          {`${entityType} `}
          <b>{`${entityNumber} `}</b>
          was created
        </>;
      case InvoicesAndCreditNotesEventsEnum.InvoiceDocumentFieldUpdate:
        return <>
          <b>{`${fieldNameEventHandle(field)} `}</b>
          field of
          <b>{` ${entityNumber} `}</b>
          {`${entityType} was changed: `}
          {!!oldValue && <s>{`${valueHandle(oldValue)}`}</s>}
          {!!newValue && <b>{` ${valueHandle(newValue)}`}</b>}
        </>;
      case InvoicesAndCreditNotesEventsEnum.InvoiceDocumentDeletion:
        return <>
          {`${entityType} `}
          <b>{`${entityNumber} `}</b>
          was deleted
        </>;
      default:
        return null;
    }
  };
  return <>{renderContent()}</>;
};

export default InvoicesAndCreditNotesEvents;
