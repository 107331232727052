import React from 'react';
import KitComponentsColumns from './KitComponentsColumns';
import KitComponentsCells from './KitComponentsCells';
import { NumberFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { partsKitComponentsUiSelector } from '../../../../../../store/selectors/partsKitSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';

type KitComponentsTableProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkRow: NumberFunctionType,
  checkedKeys: number[],
  setIsDelete: NumberFunctionType,
  openCustomEdit: NumberFunctionType,
}

const KitComponentsTable: React.FC<KitComponentsTableProps> = ({
  isAll, isIndeterminate, checkAll, checkRow, checkedKeys, setIsDelete, openCustomEdit,
}) => {
  const partsKitComponents = useAppSelector(partsKitComponentsUiSelector);

  return (
    <TableNew>
      <KitComponentsColumns
        totalElements={partsKitComponents?.length || 0}
        disabled={false}
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAll}
      />
      <KitComponentsCells
        checkedKeys={checkedKeys}
        checkHandle={checkRow}
        setIsDelete={setIsDelete}
        openCustomEdit={openCustomEdit}
      />
    </TableNew>
  );
};

export default KitComponentsTable;
