import React from 'react';
import Switch from '../../../../../core/components/switch/Switch';
import {
  setIsTableView,
  setUnfinishedEntitiesFilters,
} from '../../../../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  reportIsVisibleSelector, unfinishedEntitiesDataSelector,
  unfinishedEntitiesFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import UnfinishedEntitiesTable from './table/UnfinishedEntitiesTable';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { getUnfinishedEntitiesReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import SalesUnfinishedEntitiesChart from './chart/SalesUnfinishedEntitiesChart';
import { initPaging } from '../../../../../core/types/coreTypes';
import Segmented from '../../../../../core/components/segmented/Segmented';
import { useUnfinishedEntitiesDefaultOrdering } from '../../../../hooks/useUnfinishedEntitiesDefaultOrdering';

const SalesUnfinishedEntitiesContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const isTableView = useAppSelector(isTableViewSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(unfinishedEntitiesFiltersSelector);
  const { totalCount } = useAppSelector(unfinishedEntitiesDataSelector);

  const CHART_COUNT_OPTIONS = [15, 20, 25];

  const defaultPagingAndOrdering = useUnfinishedEntitiesDefaultOrdering(false);

  return (
    <>
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { salesNet, ...rest } = filters;
              const newFilters = {
                ...rest,
                ...defaultPagingAndOrdering,
                pageSize: val ? CHART_COUNT_OPTIONS[0] : initPaging.pageSize,
              };
              dispatch(setUnfinishedEntitiesFilters(newFilters));
              reportIsVisible && dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView
          ? <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setUnfinishedEntitiesFilters(newFilters));
              reportIsVisible && dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
          : <Segmented
            options={CHART_COUNT_OPTIONS}
            value={filters.pageSize}
            onChange={(value) => {
              const newFilters = { ...filters, pageSize: +value };
              dispatch(setUnfinishedEntitiesFilters(newFilters));
              reportIsVisible && dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
            }}
          />}
      </div>
      {isTableView
        ? <UnfinishedEntitiesTable />
        : <SalesUnfinishedEntitiesChart />}
    </>
  );
};

export default SalesUnfinishedEntitiesContent;
