import React, { useEffect, useState } from 'react';
import InfoMessagesListActions from './InfoMessagesListActions';
import Thead from '../../../core/components/table-components/Thead';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import InfoMessagesListColumns from './InfoMessagesListColumns';
import Tbody from '../../../core/components/table-components/Tbody';
import InfoMessagesListCells from './InfoMessagesListCells';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getInfoMessageMaxDisplayOrder, getInfoMessagesList } from '../../../store/thunks/settings/infoMessagesThunks';
import {
  infoMessagesListFiltersSelector,
  infoMessagesListSelector,
  infoMessagesMaxDisplayOrderSelector,
} from '../../../store/selectors/settingsSelectors';
import EditInfoMessageModal from './EditInfoMessageModal';
import { IInfoMessage } from '../types/infoMessagesTypes';
import { setInfoMessagesListFilters } from '../../../store/slices/settingsSlice';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import { useDefaultPagingAndOrdering } from '../../../core/hooks/useDefaultPagingAndOrdering';
import { getInfoMessagesOrderingThunk } from '../../../store/thunks/core/coreOrderingThunks';
import { InfoMessagesOrdering } from '../utils/data';
import { infoMessagesOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';

const InfoMessagesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const infoMessagesList = useAppSelector(infoMessagesListSelector);
  const infoMessagesListFilters = useAppSelector(infoMessagesListFiltersSelector);
  const infoMessagesListOrdering = useAppSelector(infoMessagesOrderingSelector);
  const infoMessageMaxDisplayOrder = useAppSelector(infoMessagesMaxDisplayOrderSelector);
  const [isEditModal, setEditModal] = useState<boolean>(false);
  const [currentInfoMessage, setCurrentInfoMessage] = useState<IInfoMessage | null>(null);
  const infoMessageListFilters = useAppSelector(infoMessagesListFiltersSelector);

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters: infoMessagesListFilters,
    ordering: infoMessagesListOrdering,
    getOrderingThunk: getInfoMessagesOrderingThunk,
    setFiltersAction: setInfoMessagesListFilters,
    initialOrdering: InfoMessagesOrdering.DisplayOrder,
  });

  useEffect(() => {
    dispatch(getInfoMessageMaxDisplayOrder());

    return () => {
      dispatch(setInfoMessagesListFilters(defaultPagingAndOrdering));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    infoMessagesListFilters.order?.field && dispatch(getInfoMessagesList({ filters: infoMessagesListFilters }));
    // eslint-disable-next-line
  }, [infoMessagesListFilters]);

  const updateState = (pagination?:boolean) => {
    if (pagination && infoMessagesList.items.length - 1 === 0) {
      dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        page: infoMessageListFilters.page > 1 ? infoMessageListFilters.page - 1 : 1,
      }));
    } else {
      dispatch(getInfoMessagesList({ filters: infoMessageListFilters }));
      dispatch(getInfoMessageMaxDisplayOrder());
    }
  };

  return (
    <>
      <EditInfoMessageModal
        isVisible={isEditModal}
        onCancel={() => setEditModal(false)}
        maxDisplayOrder={infoMessageMaxDisplayOrder}
        infoMessage={currentInfoMessage}
      />
      <InfoMessagesListActions />
      <TableNew>
        <Thead>
          <InfoMessagesListColumns />
        </Thead>
        <Tbody>
          {infoMessagesList.items.length > 0
            ? infoMessagesList.items.map((item, i) => (
              <InfoMessagesListCells
                key={i}
                data={item}
                openEditModal={() => {
                  setEditModal(true);
                  setCurrentInfoMessage(item);
                }}
                updateState={updateState}
              />
            ))
            : <EmptyTableSection text="No records" />}
        </Tbody>
      </TableNew>
    </>
  );
};

export default InfoMessagesList;
