import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import { useAppDispatch } from '../../../store/hooks';
import LOGO from '../../../assets/images/logo-transparent.png';
import Input from '../../../core/components/input/Input';
import InputPassword from '../../../core/components/input-password/InputPassword';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { resetPasswordConfirmThunk } from '../../../store/thunks/account/accountThunks';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { routesPath } from '../../../core/enums/pathEnum';
import { resetPasswordDefaults, ResetPasswordFields, ResetPasswordSchema } from '../utils/ResetPasswordSchema';

type ResetPasswordContainerProps = {
  email: string,
  token?: string
}
const ResetPasswordForm:React.FC<ResetPasswordContainerProps> = ({ token, email }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setLocationHistoryHandle } = useLocationHistoryState();

  const {
    control, handleSubmit, reset, setValue,
  } = useForm<ResetPasswordFields>({
    resolver: zodResolver(ResetPasswordSchema),
    defaultValues: resetPasswordDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const onSubmit = (values: ResetPasswordFields) => {
    token && dispatch(resetPasswordConfirmThunk({
      token,
      email,
      newPassword: values.newPassword,
      navigate: () => {
        setLocationHistoryHandle(null);
        navigate(`/${routesPath.HOME}`);
      },
    }));
  };
  useEffect(() => {
    if (email) {
      setValue('email', email);
    }
    // eslint-disable-next-line
  }, [email]);
  return (
    <section className="accountFinalization">
      <div className="accountFinalization__form-wrapper">
        <img src={LOGO} alt="" className="accountFinalization__logo" />
        <h1 className="accountFinalization__title">Create a new password</h1>
        <form onSubmit={handleSubmit(onSubmit)} className="accountFinalization__inner accountFinalization__form">
          <Controller
            control={control}
            name="email"
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                error={errors.email?.message}
                label="Email"
                readOnly
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="newPassword"
            render={({ field }) => (
              <InputPassword
                value={field.value}
                onChange={field.onChange}
                error={errors.confirmNewPassword?.type === 'custom' ? 'Entered passwords do not match.' : errors.newPassword?.message}
                label="New password"
                suffix={
                  <Tooltip
                    trigger="click"
                    title={
                      <div className="accountFinalization__tooltip">
                        <span>Password should be at least 8 characters long and include:</span>
                        <ul>
                          <li>one uppercase letter [A-Z]</li>
                          <li>one lowercase letter [a-z]</li>
                          <li>one number [0-9]</li>
                        </ul>
                      </div>
                    }
                  >
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </Tooltip>
                }
                className="accountFinalization__field"
              />
            )}
          />
          <Controller
            control={control}
            name="confirmNewPassword"
            render={({ field }) => (
              <InputPassword
                value={field.value}
                onChange={field.onChange}
                error={errors.confirmNewPassword?.type}
                label="Confirm new password"
                className="accountFinalization__field"
              />
            )}
          />
          <ButtonActions
            createLabel="Save"
            cancelLabel="Clear"
            createType="submit"
            cancelClick={() => {
              reset();
              setValue('email', email);
            }}
            className="accountFinalization__actions"
          />
        </form>
      </div>
    </section>
  );
};

export default ResetPasswordForm;
