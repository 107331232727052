import React from 'react';
import { SupplierDto } from '../../../dashboard/types/SuppliersTypes';
import Tag from '../../../../core/components/tag/Tag';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { useAppSelector } from '../../../../store/hooks';
import { labelHandle } from '../../../../core/utils/labelHandle';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { supplierStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';

type SuppliersHeadProps = {
  details: SupplierDto | null,
}

const SupplierHead: React.FC<SuppliersHeadProps> = ({ details }) => {
  const supplierStatuses = useAppSelector(supplierStatusesSelector);
  const properties = [
    { label: 'Supplier', value: details?.name },
    { label: 'Status', value: labelHandle(details?.supplierStatus, supplierStatuses) || '-' },
    { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.createdAt || '') },
    { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.updatedAt || '') },
  ];

  return (
    <>
      {properties.map((ent, i) => <div key={i} className="head__inner-info-item head__inner-info-item--narrow">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Status'
          ? <Tag label={ent.value?.toString() || '-'} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default SupplierHead;
