import React from 'react';
import { Control, Controller } from 'react-hook-form';
import moment from 'moment/moment';
import DatePicker, { DateObject } from 'react-multi-date-picker';
import DatePanel from 'react-multi-date-picker/plugins/date_panel';
import classNames from 'classnames';
import DatePickerComponent from '../../../../core/components/date-picker/DatePicker';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../../../core/utils/regex';
import { EmployeeSchedule } from './EmployeesWorkingHoursModal';
import { AffectedPeriodOptionsEnum } from '../../enums/workCalendarEnums';
import { SetWorkingHoursFields } from '../../utils/SetWorkingHoursSchema';

type WorkingHoursAffectedPeriodModeContentProps = {
  employeeSchedule: EmployeeSchedule | null,
  control: Control<SetWorkingHoursFields>,
  startDate: string,
  endDate: string,
  affectedPeriodMode: AffectedPeriodOptionsEnum,
  datesValues: DateObject[],
  setDatesValues: (v: DateObject[]) => void,
  datesError: string,
  setDatesError: StringFunctionType,
}

const WorkingHoursAffectedPeriodModeContent: React.FC<WorkingHoursAffectedPeriodModeContentProps> = ({
  employeeSchedule, control, startDate, endDate, affectedPeriodMode, datesValues, setDatesValues,
  datesError, setDatesError,
}) => {
  const MIN_DATE = '2000/01/01';
  const isDatePeriodMode = affectedPeriodMode === AffectedPeriodOptionsEnum.DATE_PERIOD;

  return (
    <div className={classNames('details-form__field--lg', { 'employeesWorkingHours__period': isDatePeriodMode })}>
      {isDatePeriodMode
        ? <>
          <Controller
            control={control}
            name="startDate"
            render={({ field }) => (
              <DatePickerComponent
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  value && field.onChange(value.format(DEFAULT_DATE_FORMAT));
                }}
                format={DEFAULT_UI_DATE_FORMAT}
                disabledDate={(currentDate) => {
                  const tooEarly = currentDate.isBefore(MIN_DATE);
                  const tooLate = currentDate.isAfter(endDate);
                  return tooEarly || tooLate;
                }}
              />
            )}
          />
          <span>-</span>
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePickerComponent
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  value && field.onChange(value.format(DEFAULT_DATE_FORMAT));
                }}
                format={DEFAULT_UI_DATE_FORMAT}
                disabledDate={(currentDate) => {
                  const tooEarly = currentDate.isBefore(startDate);
                  const tooLate = currentDate.isAfter(moment().add(+3, 'years'));
                  return tooEarly || tooLate;
                }}
              />
            )}
          />
        </>
        : <div>
          <DatePicker
            multiple
            value={datesValues}
            onChange={(v) => {
              setDatesValues(v as DateObject[]);
              datesError && setDatesError('');
            }}
            minDate={MIN_DATE}
            maxDate={new DateObject().add(3, 'year')}
            plugins={[<DatePanel />]}
            format={DEFAULT_UI_DATE_FORMAT}
            sort
            weekStartDayIndex={1}
            placeholder="Select specific dates"
            disabled={!!employeeSchedule}
          />
          {datesError && <span>{datesError}</span>}
        </div>}
    </div>
  );
};

export default WorkingHoursAffectedPeriodModeContent;
