import React from 'react';

type RectificationPartsSectionItemProps = {
  label: string,
  count: number,
}

const RectificationPartsSectionItem: React.FC<RectificationPartsSectionItemProps> = ({ label, count }) => {
  return (
    <p className="jobs-and-parts-section__line">
      <span>{label}</span>
      <span className="jobs-and-parts-section__line-value">{count}</span>
    </p>
  );
};

export default RectificationPartsSectionItem;
