import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Controller, Path, useForm, useFormState,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  vehicleAddressDetailsSelector,
  vehicleCheckDetailsSelector,
  vehicleDetailsSelector,
} from '../../../store/selectors/vehiclesSelectors';
import { getVehicleByIdThunk } from '../../../store/thunks/vehicles/vehicleViewPageThunks';
import AddressModal from '../../../common/components/address/AddressModal';
import CommonVehicleCheckFields from './CommonVehicleCheckFields';
import AddressField from '../../../common/components/address/AddressField';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import { maxWeightWhole } from '../../../core/utils/regex';
import {
  createVehicleCheckThunk,
  getVehicleCheckByIdThunk,
} from '../../../store/thunks/vehicles/VehicleInspectionThunks';
import { SaveVehicleCheckDto } from '../../vehicle-view-page/types/VehicleViewTypes';
import { isFetchingSelector, vehicleCheckTypesValuesSelector } from '../../../store/selectors/coreSelectors';
import { useVehicleCheckFormHelper } from '../hooks/useVehicleCheckFormHelper';
import { getVehicleCheckTypesThunk } from '../../../store/thunks/coreThunk';
import { WeightCheck, weightCheckDefaults, WeightVehicleCheckSchema } from '../utils/WeghtVehicleCheckSchema';

type WeightVehicleCheckProps = {
  readMode?: boolean,
}

const WeightVehicleCheck: React.FC<WeightVehicleCheckProps> = ({ readMode }) => {
  const { id, checkId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const { backHandle } = useLocationHistoryState(false);
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const vehicleAddress = useAppSelector(vehicleAddressDetailsSelector);
  const { weight } = useAppSelector(vehicleCheckTypesValuesSelector);
  const vehicleCheckDetails = useAppSelector(vehicleCheckDetailsSelector);

  const {
    handleSubmit, control, reset, clearErrors, setValue,
  } = useForm<WeightCheck>({
    resolver: zodResolver(WeightVehicleCheckSchema),
    defaultValues: weightCheckDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const {
    backUrl, checkDate,
    cleanUpForm, isAddressModal,
    setAddressModal, addressFields,
    setAddressFields, addressError,
    setAddressError, locationHelper,
    numericKeys, setAddressFilledValues,
  } = useVehicleCheckFormHelper(reset, clearErrors, setValue, readMode);

  const onSubmit = (values: WeightCheck) => {
    const { areAllRequiredFields, location } = locationHelper();

    if (areAllRequiredFields) {
      setAddressError(ErrorsEnum.REQUIRED);
    } else {
      const finalData = {
        vehicleCheckType: weight,
        mileageMiles: +values.mileageMiles,
        fuelLevelPercent: values.fuelLevelPercent,
        vehicleWeightKg: +values.vehicleWeightKg,
        ...location,
      };
      id && weight && dispatch(createVehicleCheckThunk({
        vehicleId: +id,
        vehicleCheckData: finalData as SaveVehicleCheckDto,
        attachmentsList: [],
        navigateToInspectionTab: () => {
          cleanUpForm();
          navigate(`/${backUrl}`);
        },
      }));
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getVehicleByIdThunk({ vehicleId: +id }));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (vehicleAddress && !readMode) {
      setAddressFields(vehicleAddress);
    }
    // eslint-disable-next-line
  }, [vehicleAddress, readMode]);

  useEffect(() => {
    dispatch(getVehicleCheckTypesThunk());
    return () => {
      cleanUpForm();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readMode && checkId && id) {
      dispatch(getVehicleCheckByIdThunk({ vehicleId: +id, vehicleCheckId: +checkId }));
    }
    // eslint-disable-next-line
  }, [readMode, id, checkId]);

  useEffect(() => {
    if (readMode && vehicleCheckDetails) {
      Object.entries(vehicleCheckDetails).forEach(([key, value]) => {
        if (numericKeys.includes(key)) {
          setValue(key as Path<WeightCheck>, value !== null ? value.toString() : '');
        } else {
          setValue(key as Path<WeightCheck>, value as (string | number));
        }
      });
      setAddressFilledValues();
    }
    // eslint-disable-next-line
  }, [readMode, vehicleCheckDetails]);

  return (
    <Layout
      subHeader={
        <DetailsHead goBack={() => backHandle(backUrl)} isCreatePage>
          <div className="createHead">{`Weight check${checkDate}`}</div>
        </DetailsHead>
      }
    >
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        isOptionalFields={false}
        title="Location"
        isExtendedFields
        addressFields={addressFields}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="create-form checkForm">
        <section className="details-form">
          <h2 className="info-grid__title">Vehicle details</h2>
          <CommonVehicleCheckFields
            control={control}
            errors={errors}
            vehicleDetails={vehicleDetails}
            disabled={readMode}
          />
          <Controller
            control={control}
            name="vehicleWeightKg"
            render={({ field }) => (
              <RegularInputNumber
                value={field.value}
                onChange={(val) => field.onChange(val)}
                label="Vehicle weight, kg"
                max={maxWeightWhole}
                error={errors.vehicleWeightKg?.message}
                className="details-form__field--lg"
                disabled={readMode}
              />
            )}
          />
          <AddressField
            addressFields={addressFields}
            setAddressModal={setAddressModal}
            title="Location"
            addressError={addressError}
            disabled={readMode}
          />
        </section>
        {!readMode && <ButtonActions
          createLabel="Save"
          createType="submit"
          cancelLabel="Clear"
          cancelClick={() => cleanUpForm(true)}
          isLoading={isFetching}
          offsetTop
        />}
      </form>
    </Layout>
  );
};

export default WeightVehicleCheck;
