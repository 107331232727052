import React from 'react';
import { AttachmentsColumnsProps } from '../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../store/hooks';
import { setRectificationAttachmentsFilters } from '../../../../../store/slices/rectificationsSlice';
import { rectificationAttachmentsFiltersSelector } from '../../../../../store/selectors/rectificationsSelector';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../permissions-handling/permissionHook';
import AttachmentColumns from '../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../core/hooks/useCoreAttachmentsColumns';

const RectificationAttachmentsColumns: React.FC<AttachmentsColumnsProps> = ({
  isAll,
  checkAll,
  totalElements,
  indeterminate,
  disabledActions,
}) => {
  const allowedManage = usePermission(PermissionEnum.RectificationEditFields);
  const attachFilters = useAppSelector(rectificationAttachmentsFiltersSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachFilters, setRectificationAttachmentsFilters);

  return (
    <AttachmentColumns
      disabledActions={disabledActions}
      allowedManage={!!allowedManage}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      totalElements={totalElements}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      tableFilters={attachFilters}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      isManageAttachmentsVisibilityInJobs
    />
  );
};

export default RectificationAttachmentsColumns;
