import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { SettingsPageParamEnum } from '../../enums/settingsEnums';
import Segmented from '../../../../core/components/segmented/Segmented';
import { BooleanFunctionType, TabOptionType } from '../../../../core/types/coreTypes';
import Button from '../../../../core/components/button/Button';
import Selectable from '../../../../core/components/selectable/Selectable';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import { useUserListDictionaries } from '../../hooks/useUserListDictionaries';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { settingsDashOptions } from '../../utils/data';
import { useUsersFiltersHandle } from '../../hooks/useUsersFiltersHandle';

type SettingsFiltersProps = {
  view: string | number;
  setView: (value: string) => void;
  isFilterMode: boolean;
  setFilterMode: BooleanFunctionType;
  setPermissionModule: (value?: number) => void,
  permissionModule?: number,
}

const SettingsFilters: React.FC<SettingsFiltersProps> = ({
  view, setView, isFilterMode, setFilterMode,
  setPermissionModule, permissionModule,
}) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const {
    usersListFilters,
    permissionModules,
    currentUser,
  } = useUserListDictionaries();
  const { windowWidth } = useScreenWitdh();

  const {
    searchValue,
    setSearchValue,
    filtersList,
    getFilteredList,
    onClear,
    initDropdownSearch,
  } = useUsersFiltersHandle();

  const [tabs, setTabs] = useState<TabOptionType[]>([]);
  const setViewHandle = (value: string) => {
    if (value === SettingsPageParamEnum.CREATE) {
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
      navigate('/create-user');
    } else {
      setView(value);
    }
  };

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const allowedActions = settingsDashOptions.filter((act) => permissions.includes(act.restrictedTo));
      setTabs(allowedActions);
      if (!view) {
        params.append('page', allowedActions[0].value);
        navigate({ search: `?page=${allowedActions[0].value}` }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [currentUser, params]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        getFilteredList({ ...usersListFilters, keyword: searchValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!searchValue?.trim() && usersListFilters.keyword) {
        getFilteredList({ ...usersListFilters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [searchValue]);
  return (
  // eslint-disable-next-line max-len
    <div className="settingsFilters desktop-sticky-filters desktop-sticky-filters--top-sm mobile-sticky-filters mobile-sticky-filters--top-sm">
      <div className="settingsFilters__head">
        {windowWidth && windowWidth <= responsiveEndpointsEnum.LG
          ? <Selectable
            options={tabs}
            value={view}
            onChange={(value) => setViewHandle(value as string)}
            parentRender={false}
          />
          : <Segmented
            options={tabs}
            value={view}
            onChange={(value) => setViewHandle(value as string)}
          />}
        {view === SettingsPageParamEnum.USERS
            && <Button
              icon={<FontAwesomeIcon icon={faFilter} />}
              onClick={() => setFilterMode(!isFilterMode)}
              isActivated={isFilterMode}
            />}
        {view === SettingsPageParamEnum.PERMISSIONS
            && <Selectable
              value={permissionModule}
              onChange={(val) => {
                const table = document.querySelector('.permissions-table');
                table?.scrollTo(0, 0);
                setPermissionModule(+val);
              }}
              options={permissionModules}
              parentRender={false}
              className="settingsFilters__permission-modules"
            />}
      </div>
      {isFilterMode && view === SettingsPageParamEnum.USERS && <div className="settingsFilters__footer">
        <KeywordFiltersSection
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          filters={filtersList}
          getFilteredResHandle={(values, field) => getFilteredList({
            ...usersListFilters,
            [field]: values,
          })}
          onClear={onClear}
          initDropdownSearch={initDropdownSearch}
          isFullWidth
        />
      </div>}
    </div>
  );
};

export default SettingsFilters;
