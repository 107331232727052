import qs from 'qs';
import instance from './axiosConfig';
import { IUser, PatchRolePermissionsReqType, UserListFiltersType } from '../settings/users-dashboard/types/settingsTypes';
import { FinalizeReqDataType } from '../settings/account-finalization/types/accountFinalizationTypes';
import { PatchUserAccountType, UserAccountDto } from '../settings/user-profile/types/userProfileTypes';
import {
  AddInfoMessageType, IInfoMessage,
  InfoMessageMaxDisplayOrderDto,
  InfoMessagesFiltersType,
} from '../settings/info-messages/types/infoMessagesTypes';
import {
  AxiosCreatedIdResponse, AxiosDataListResponse,
  AxiosDictionaryResponse,
  AxiosNullableResponse, AxiosSingleResponse,
} from '../core/types/coreTypes';
import { AccountFinalizationData } from '../settings/account-finalization/AccountFinalizationContainer';
import { UserAccountPermissionDto, UserAccountRolePermissionDto } from '../settings/permissions/types/permissionTypes';
import { CreateUserAccountForEmployeeRequest, CreateUserAccountRequest } from '../settings/create-user/utils/UserShema';
import { ExtendedTimelineFilters } from '../common/types/commonTypes';
import { UserEntityTimelineEvent, UserTimelineEvent } from '../settings/user-profile/types/activityTypes';

export const settingsAPI = {
  fetchPermissionModules(): AxiosDictionaryResponse {
    return instance.get('/account/app/permissionModule');
  },
  fetchAccountUsers(filters: UserListFiltersType): AxiosDataListResponse<IUser[], { allItemIds: number[] }> {
    return instance.get('/account/userAccount', {
      params: {
        'Filter.Keyword': filters.keyword,
        'Filter.Name': filters.name,
        'Filter.Email': filters.email,
        'Filter.EmployeeReferenceNumber': filters.refNo,
        'Filter.Roles': filters.role,
        'Filter.Statuses': filters.status,
        'Filter.JobTypes': filters.jobType,
        'Filter.AccountTypes': filters.accountType,
        'Filter.BusinessAreas': filters.businessArea,
        'Filter.ViewAssignedOnly': filters.canSeeOnlyAssignedEntities,
        'Filter.Permissions': filters.permissions,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  inviteUsers(userAccountIds: number[]): AxiosNullableResponse {
    return instance.post('/account/userAccount/resend', { userAccountIds });
  },
  deleteUsers(userAccountIds: number[]): AxiosNullableResponse {
    return instance.delete('/account/userAccount', { data: { userAccountIds } });
  },
  resetUsersPassword(userAccountIds: number[]): AxiosNullableResponse {
    return instance.post('/account/userAccount/resetPassword', { userAccountIds });
  },
  createUser(data: CreateUserAccountRequest | CreateUserAccountForEmployeeRequest): AxiosCreatedIdResponse {
    return instance.post('/account/userAccount', { ...data });
  },
  validateInviteToken(token: string): AxiosSingleResponse<AccountFinalizationData> {
    return instance.post(`/account/userAccount/validate/${token}`);
  },
  finaliseUserProfile(token: string, data: FinalizeReqDataType): AxiosNullableResponse {
    return instance.post(`/account/userAccount/finalise/${token}`, { ...data });
  },
  getUserAccount(id: number): AxiosSingleResponse<{ userAccount: UserAccountDto}> {
    return instance.get(`/account/userAccount/${id}`);
  },
  patchUserAccount(id: number, data: Partial<PatchUserAccountType>): AxiosNullableResponse {
    return instance.patch(`/account/userAccount/${id}`, { ...data });
  },
  deleteUserAccount(id: number): AxiosNullableResponse {
    return instance.delete(`/account/userAccount/${id}`);
  },
  activateUserAccount(id: number): AxiosNullableResponse {
    return instance.post(`/account/userAccount/activate/${id}`);
  },
  deactivateUserAccount(id: number): AxiosNullableResponse {
    return instance.post(`/account/userAccount/inactivate/${id}`);
  },
  fetchPermissionRoles(module?: number): AxiosSingleResponse<{ items: UserAccountRolePermissionDto[] }> {
    return instance.get('/account/userAccountPermission/rolePermissions', {
      params: {
        'Filter.Module': module,
      },
    });
  },
  fetchPermissions(module: number): AxiosSingleResponse<{ items: UserAccountPermissionDto[] }> {
    return instance.get('/account/userAccountPermission', {
      params: {
        'Filter.Module': module,
      },
    });
  },
  patchPermissions(roles: PatchRolePermissionsReqType[]): AxiosNullableResponse {
    return instance.patch('/account/userAccountPermission/rolePermissions', { roles });
  },
  getInfoMessageMaxDisplayOrder(): AxiosSingleResponse<InfoMessageMaxDisplayOrderDto> {
    return instance.get('/settings/infoMessage/maxDisplayOrder');
  },
  fetchInfoMessages(filters: InfoMessagesFiltersType): AxiosDataListResponse<IInfoMessage[]> {
    return instance.get('/settings/infoMessage', {
      params: {
        'Filter.Title': filters.title,
        'Filter.Message': filters.message,
        'Filter.Statuses': filters.status,
        'Filter.DisplayOrders': filters.displayOrder,
        'Filter.CreatedAtFrom': filters.createDate?.from,
        'Filter.CreatedAtTo': filters.createDate?.at,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  addInfoMessage(data: AddInfoMessageType): AxiosCreatedIdResponse {
    return instance.post('/settings/infoMessage', { ...data });
  },
  editInfoMessage(id:number, data: AddInfoMessageType): AxiosNullableResponse {
    return instance.patch(`/settings/infoMessage/${id}`, { ...data });
  },
  deleteInfoMessage(id: number): AxiosNullableResponse {
    return instance.delete(`/settings/infoMessage/${id}`);
  },
  publishInfoMessage(id: number): AxiosNullableResponse {
    return instance.post(`/settings/infoMessage/publish/${id}`);
  },
  unpublishInfoMessage(id: number): AxiosNullableResponse {
    return instance.post(`/settings/infoMessage/unpublish/${id}`);
  },
  fetchUserTimeline(userId: number, filters: ExtendedTimelineFilters, signal?: AbortSignal): AxiosDataListResponse<UserTimelineEvent[]> {
    return instance.get('/account/userAccount/timeline', {
      params: {
        UserId: userId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
    });
  },
  fetchUserEntityTimeline(
    userId: number,
    filters: ExtendedTimelineFilters,
    signal?: AbortSignal,
  ): AxiosDataListResponse<UserEntityTimelineEvent[]> {
    return instance.get('/account/userAccount/userAccountEntityEventsTimeline', {
      params: {
        UserId: userId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
    });
  },
  createUserActivityNote(userAccountId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/account/userAccount/note', { userAccountId, content });
  },
  putUserActivityNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/account/userAccount/note/${id}`, { id, content });
  },
  deleteUserActivityNote(id: number): AxiosNullableResponse {
    return instance.delete(`/account/userAccount/note/${id}`);
  },
};
