import qs from 'qs';
import moment from 'moment';
import instance from './axiosConfig';
import {
  GetSalesRectificationReportResponse,
  RectificationReportExcelFilters,
  RectificationReportFilters,

} from '../reports/types/RectificationReportsTypes';
import {
  AxiosDataListResponse, AxiosDownloadUriResponse, AxiosGenericResponse, PagingType,
} from '../core/types/coreTypes';
import { DEFAULT_TIMEZONE_DATE_FORMAT } from '../core/utils/regex';
import {
  GetSalesUnfinishedEntitiesReportResponse,
  UnfinishedEntitiesReportFilters,
} from '../reports/types/UnfinishedEntitiesReportTypes';
import {
  OutstandingOverduePurchasingExcelFilters,
  OutstandingOverduePurchasingFilters,
  PurchasingOutstandingOverduePurchaseOrdersReportResponse,
} from '../reports/types/OutstandingOverdueReportTypes';
import {
  GetPurchasingSupplierSpendReportResponse,
  SupplierSpendFilters,
  SupplierSpendReportExcelFilters,
} from '../reports/types/SupplierSpendReportTypes';
import {
  FitterReturnsFilters,
  GetPurchasingFitterReturnsReportDto,
} from '../reports/types/FitterReturnsTypes';
import {
  GetSalesSoldProductsByCustomerReportResponse,
  SoldProductsByCustomerExcelFilters,
  SoldProductsByCustomerFilters,
} from '../reports/types/SoldProductsByCustomerTypes';
import {
  FitmentTimeReportFilters,
  FitmentTimeReportFiltersChart,
  FitmentTimeReportFiltersExcel, GetProductionFitmentTimeReportTableResponse, ProductionFitmentTimeReportChartParentJobDto,
} from '../reports/types/FitmentTimeReportTypes';
import {
  GetSalesRectificationByProductReportResponse,
  RectificationsByProductReportExcelFilters, RectificationsByProductReportFilters,
} from '../reports/types/RectificationsByProductTypes';
import {
  GetSalesNumberOfConvertedVehiclesReportResponse,
  NumberOfConvertedVehiclesReportFilters,
} from '../reports/types/NumberOfConvertedVehiclesReportTypes';
import {
  GetSalesOfficeEmployeeChartDataReportDto,
  GetSalesOfficeEmployeeReportResponse,
  SalesOfficeEmployeeReportChartFilters,
  SalesOfficeEmployeeReportExcelFilters,
  SalesOfficeEmployeeReportFilters,
} from '../reports/types/SalesOfficeEmployeeReportTypes';
import { FullStockReportExcelFilters, FullStockReportFilters, GetStockFullStockReportDto } from '../reports/types/FullStockReportTypes';
import {
  GetSalesRectificationBySupplierReportResponse,
  RectificationsBySupplierReportExcelFilters, RectificationsBySupplierReportFilters,
} from '../reports/types/RectificationsBySupplierReportTypes';
import { GetSalesByCustomerReportResponse, SalesByCustomerReportFilters } from '../reports/types/SalesReportByCustomerTypes';
import {
  GetSalesOffsiteWorkingReportResponse,
  SalesOffsiteWorkingReportExcelFilters,
  SalesOffsiteWorkingReportFilters,
} from '../reports/types/SalesOffsiteWorkingReportTypes';
import {
  CancelledLinesReportDto,
  CancelledLinesReportExcelFilters,
  CancelledLinesReportFilters,
} from '../reports/types/CancelledLinesReportType';

export const reportsAPI = {
  fetchProductionFitmentTimeReport(params: FitmentTimeReportFilters): AxiosGenericResponse<GetProductionFitmentTimeReportTableResponse> {
    return instance.get('/report/productionReports/fitmentTimeReportTable', {
      params: {
        'Filter.SearchParameters.Keyword': params.keyword || undefined,
        'Filter.SearchParameters.IncludeJobNumber': params.keyword ? true : undefined,
        'Filter.CustomerIds': params.customerIds,
        'Filter.JobCategories': params.jobCategories,
        'Filter.JobTypes': params.jobTypes,
        'Filter.JobStatuses': params.jobStatuses,
        'Filter.AssigneeIds': params.assigneeIds,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'Filter.SpentByAssigneeMinutes.From': params.spentByAssigneeMinutes?.min,
        'Filter.SpentByAssigneeMinutes.To': params.spentByAssigneeMinutes?.max,
        'Filter.TotalSpentMinutes.From': params.totalSpentMinutes?.min,
        'Filter.TotalSpentMinutes.To': params.totalSpentMinutes?.max,
        'Filter.TotalEstimatedMinutes.From': params.totalEstimatedMinutes?.min,
        'Filter.TotalEstimatedMinutes.To': params.totalEstimatedMinutes?.max,
        'Filter.DifferenceMinutes.From': params.differenceMinutes?.min,
        'Filter.DifferenceMinutes.To': params.differenceMinutes?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchProductionFitmentTimeReportExcel(params: FitmentTimeReportFiltersExcel): AxiosDownloadUriResponse {
    return instance.get('/report/productionReports/fitmentTimeReportExcel', {
      params: {
        'Filter.SearchParameters.Keyword': params.keyword || undefined,
        'Filter.SearchParameters.IncludeJobNumber': params.keyword ? true : undefined,
        'Filter.CustomerIds': params.customerIds,
        'Filter.JobCategories': params.jobCategories,
        'Filter.JobTypes': params.jobTypes,
        'Filter.JobStatuses': params.jobStatuses,
        'Filter.AssigneeIds': params.assigneeIds,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'Filter.SpentByAssigneeMinutes.From': params.spentByAssigneeMinutes?.min,
        'Filter.SpentByAssigneeMinutes.To': params.spentByAssigneeMinutes?.max,
        'Filter.TotalSpentMinutes.From': params.totalSpentMinutes?.min,
        'Filter.TotalSpentMinutes.To': params.totalSpentMinutes?.max,
        'Filter.TotalEstimatedMinutes.From': params.totalEstimatedMinutes?.min,
        'Filter.TotalEstimatedMinutes.To': params.totalEstimatedMinutes?.max,
        'Filter.DifferenceMinutes.From': params.differenceMinutes?.min,
        'Filter.DifferenceMinutes.To': params.differenceMinutes?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchProductionFitmentTimeReportChart(
    params: FitmentTimeReportFiltersChart,
  ): AxiosDataListResponse<ProductionFitmentTimeReportChartParentJobDto[]> {
    return instance.get('/report/productionReports/fitmentTimeReportChart', {
      params: {
        'Filter.SearchParameters.Keyword': params.keyword || undefined,
        'Filter.SearchParameters.IncludeJobNumber': params.keyword ? true : undefined,
        'Filter.CustomerIds': params.customerIds,
        'Filter.JobCategories': params.jobCategories,
        'Filter.JobTypes': params.jobTypes,
        'Filter.JobStatuses': params.jobStatuses,
        'Filter.AssigneeIds': params.assigneeIds,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesRectificationReport(params: RectificationReportFilters): AxiosGenericResponse<GetSalesRectificationReportResponse> {
    return instance.get('/report/salesReports/rectificationReport', {
      params: {
        'Filter.RectificationTypes': params.rectificationTypes,
        'Filter.Customers': params.customers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.JobAssignees': params.jobAssignees,
        'Filter.MinCostNet': params.costNet?.min,
        'Filter.MaxCostNet': params.costNet?.max,
        'Filter.MinSalesNet': params.salesNet?.min,
        'Filter.MaxSalesNet': params.salesNet?.max,
        'Filter.MinCompletedDate': params.completedDate?.min,
        'Filter.MaxCompletedDate': params.completedDate?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesRectificationReportExcel(params: RectificationReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/rectificationReportExcel', {
      params: {
        'Filter.RectificationTypes': params.rectificationTypes,
        'Filter.Customers': params.customers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.JobAssignees': params.jobAssignees,
        'Filter.MinCostNet': params.costNet?.min,
        'Filter.MaxCostNet': params.costNet?.max,
        'Filter.MinSalesNet': params.salesNet?.min,
        'Filter.MaxSalesNet': params.salesNet?.max,
        'Filter.MinCompletedDate': params.completedDate?.min,
        'Filter.MaxCompletedDate': params.completedDate?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationsByProductReport(
    params: RectificationsByProductReportFilters,
  ): AxiosGenericResponse<GetSalesRectificationByProductReportResponse> {
    return instance.get('/report/salesReports/rectificationByProductReport', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'Filter.MinUsedInRectifications': params.usedInRectifications?.min,
        'Filter.MaxUsedInRectifications': params.usedInRectifications?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationsByProductExcelReport(
    params: RectificationsByProductReportExcelFilters,
  ): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/rectificationByProductReportExcel', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'Filter.MinUsedInRectifications': params.usedInRectifications?.min,
        'Filter.MaxUsedInRectifications': params.usedInRectifications?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchNumberOfConvertedVehiclesReport(
    params?: NumberOfConvertedVehiclesReportFilters,
  ): AxiosGenericResponse<GetSalesNumberOfConvertedVehiclesReportResponse> {
    return instance.get('/report/salesReports/salesNumberOfConvertedVehiclesReport', {
      params: {
        'Filter.Customers': params?.customers,
        'Filter.CompletedDate.From': params?.completedDate?.min,
        'Filter.CompletedDate.To': params?.completedDate?.max,
        'Filter.BusinessAreas': params?.businessAreas,
        'Filter.JobTypes': params?.jobTypes,
        'Filter.VehicleProfileCode': params?.vehicleProfileCode,
        'Filter.MinConvertedVehicles': params?.convertedVehicles?.min,
        'Filter.MaxConvertedVehicles': params?.convertedVehicles?.max,
        'OrderOptions.Field': params?.order?.field,
        'OrderOptions.Ascending': params?.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesOffsiteReport(
    params: SalesOffsiteWorkingReportFilters,
  ): AxiosGenericResponse<GetSalesOffsiteWorkingReportResponse> {
    return instance.get('/report/salesReports/salesOffsiteWorkingReport', {
      params: {
        'Filter.EntityTypes': params.entityTypes,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Customers': params.customers,
        'Filter.Assignees': params.assignees,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'Filter.DistanceFromOfficeMiles.From': params.distanceFromOfficeMiles?.min,
        'Filter.DistanceFromOfficeMiles.To': params.distanceFromOfficeMiles?.max,
        'Filter.EntityNumber': params.entityNumber,
        'Filter.Address': params.address,
        'Filter.CityTown': params.cityTown,
        'Filter.PostalCode': params.postalCode,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesOffsiteReportExcel(params: SalesOffsiteWorkingReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/salesOffsiteWorkingReportExcel', {
      params: {
        'Filter.EntityTypes': params.entityTypes,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Customers': params.customers,
        'Filter.Assignees': params.assignees,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'Filter.EntityNumber': params.entityNumber,
        'Filter.Address': params.address,
        'Filter.CityTown': params.cityTown,
        'Filter.PostalCode': params.postalCode,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesOfficeEmployeeReport(params: SalesOfficeEmployeeReportFilters): AxiosGenericResponse<GetSalesOfficeEmployeeReportResponse> {
    return instance.get('/report/salesReports/salesOfficeEmployeeReport', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max || moment().endOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT),
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.InitialAssignees': params.initialAssignees,
        'Filter.CancellationInitiators': params.cancellationInitiators,
        'Filter.OrderNumber': params.orderNumber,
        'Filter.MinSalesNet': params.salesNet?.min,
        'Filter.MaxSalesNet': params.salesNet?.max,
        'Filter.MinDaysInNewStatus': params.daysInNewStatus?.min,
        'Filter.MaxDaysInNewStatus': params.daysInNewStatus?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesOfficeEmployeeReportExcel(params: SalesOfficeEmployeeReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/salesOfficeEmployeeReportExcel', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max || moment().endOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT),
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.InitialAssignees': params.initialAssignees,
        'Filter.CancellationInitiators': params.cancellationInitiators,
        'Filter.OrderNumber': params.orderNumber,
        'Filter.MinSalesNet': params.salesNet?.min,
        'Filter.MaxSalesNet': params.salesNet?.max,
        'Filter.MinDaysInNewStatus': params.daysInNewStatus?.min,
        'Filter.MaxDaysInNewStatus': params.daysInNewStatus?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesOfficeEmployeeReportChart(
    params: SalesOfficeEmployeeReportChartFilters,
  ): AxiosDataListResponse<GetSalesOfficeEmployeeChartDataReportDto[]> {
    return instance.get('/report/salesReports/salesOfficeEmployeeReportChart', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max || moment().endOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT),
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.InitialAssignees': params.initialAssignees,
        'Filter.CancellationInitiators': params.cancellationInitiators,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSalesReportByCustomer(
    params: SalesByCustomerReportFilters,
  ): AxiosGenericResponse<GetSalesByCustomerReportResponse> {
    return instance.get('/report/salesReports/salesByCustomerReport', {
      params: {
        'Filter.EntityTypes': params.entityTypes,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Customers': params.customerIds,
        'Filter.BillingCustomers': params.billingCustomerIds,
        'Filter.ShippingCustomers': params.shippingCustomerIds,
        'Filter.JobTypes': params.jobTypes,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.SalesNet.From': params.salesNet?.min,
        'Filter.SalesNet.To': params.salesNet?.max,
        'Filter.CostNet.From': params.costNet?.min,
        'Filter.CostNet.To': params.costNet?.max,
        'Filter.DifferenceNet.From': params.differenceNet?.min,
        'Filter.DifferenceNet.To': params.differenceNet?.max,
        'Filter.EntityNumber': params.entityNumber,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSoldProductsByCustomerReport(
    params: SoldProductsByCustomerFilters,
  ): AxiosGenericResponse<GetSalesSoldProductsByCustomerReportResponse> {
    return instance.get('/report/salesReports/salesSoldProductsByCustomerReport', {
      params: {
        'Filter.CustomerIds': params.customerIds,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.EntityTypes': params.entityTypes,
        'Filter.PartSubcategoryIds': params.partSubcategoryIds,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.ProductNumber': params.productNumber,
        'Filter.ProductName': params.productName,
        'Filter.Quantity.From': params.quantity?.min,
        'Filter.Quantity.To': params.quantity?.max,
        'Filter.CostNet.From': params.costNet?.min,
        'Filter.CostNet.To': params.costNet?.max,
        'Filter.SalesNet.From': params.salesNet?.min,
        'Filter.SalesNet.To': params.salesNet?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSoldProductsByCustomerReportExcel(params: SoldProductsByCustomerExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/salesSoldProductsByCustomerReportExcel', {
      params: {
        'Filter.CustomerIds': params.customerIds,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.EntityTypes': params.entityTypes,
        'Filter.PartSubcategoryIds': params.partSubcategoryIds,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.ProductNumber': params.productNumber,
        'Filter.ProductName': params.productName,
        'Filter.Quantity.From': params.quantity?.min,
        'Filter.Quantity.To': params.quantity?.max,
        'Filter.CostNet.From': params.costNet?.min,
        'Filter.CostNet.To': params.costNet?.max,
        'Filter.SalesNet.From': params.salesNet?.min,
        'Filter.SalesNet.To': params.salesNet?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchFullStockReport(params: FullStockReportFilters): AxiosDataListResponse<GetStockFullStockReportDto[]> {
    return instance.get('/report/stockReports/fullStockReport', {
      params: {
        'Filter.PartName': params.partName,
        'Filter.IsCorePart': params.corePart,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.SuitableForVehicles': params.suitableForVehicles,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchUnfinishedEntitiesReport(params: UnfinishedEntitiesReportFilters): AxiosGenericResponse<GetSalesUnfinishedEntitiesReportResponse> {
    return instance.get('/report/salesReports/unfinishedEntitiesReport', {
      params: {
        'Filter.EntityTypes': params.entityType,
        'Filter.Customers': params.customers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Assignees': params.assigneeIds,
        'Filter.CreationDate.From': params.creationDate?.min,
        'Filter.CreationDate.To': params.creationDate?.max,
        'Filter.MinSalesNet': params.salesNet?.min,
        'Filter.MaxSalesNet': params.salesNet?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchFullStockReportExcel(params: FullStockReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/stockReports/fullStockReportExcel', {
      params: {
        'Filter.PartName': params.partName,
        'Filter.IsCorePart': params.corePart,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.SuitableForVehicles': params.suitableForVehicles,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationsBySupplierReport(
    params: RectificationsBySupplierReportFilters,
  ): AxiosGenericResponse<GetSalesRectificationBySupplierReportResponse> {
    return instance.get('/report/salesReports/rectificationBySupplierReport', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.Suppliers': params.suppliers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'Filter.MinUsedInRectifications': params.usedInRectifications?.min,
        'Filter.MaxUsedInRectifications': params.usedInRectifications?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationsBySupplierReportExcel(params: RectificationsBySupplierReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/salesReports/rectificationBySupplierReportExcel', {
      params: {
        'Filter.Customers': params.customers,
        'Filter.Suppliers': params.suppliers,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.FaultTypes': params.faultTypes,
        'Filter.Assignees': params.assignees,
        'Filter.CompletedDate.From': params.completedDate?.min,
        'Filter.CompletedDate.To': params.completedDate?.max,
        'Filter.Subcategories': params.subcategories,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'Filter.MinUsedInRectifications': params.usedInRectifications?.min,
        'Filter.MaxUsedInRectifications': params.usedInRectifications?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingSupplierSpendReport(
    params: SupplierSpendFilters,
  ): AxiosGenericResponse<GetPurchasingSupplierSpendReportResponse> {
    return instance.get('/report/purchasingReports/purchasingSupplierSpendReport', {
      params: {
        'Filter.Suppliers': params.suppliers,
        'Filter.Subcategories': params.subcategories,
        'Filter.PurchaseOrderDate.From': params.purchaseOrderDate?.min,
        'Filter.PurchaseOrderDate.To': params.purchaseOrderDate?.max,
        'Filter.PurchaseOrderNumber': params.purchaseOrderNumber,
        'Filter.CostNet.From': params.costNet?.min,
        'Filter.CostNet.To': params.costNet?.max,
        'Filter.CostGross.From': params.costGross?.min,
        'Filter.CostGross.To': params.costGross?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingSupplierSpendReportExcel(params: SupplierSpendReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/purchasingReports/purchasingSupplierSpendReportExcel', {
      params: {
        'Filter.Suppliers': params.suppliers,
        'Filter.Subcategories': params.subcategories,
        'Filter.PurchaseOrderDate.From': params.purchaseOrderDate?.min,
        'Filter.PurchaseOrderDate.To': params.purchaseOrderDate?.max,
        'Filter.PurchaseOrderNumber': params.purchaseOrderNumber,
        'Filter.CostNet.From': params.costNet?.min,
        'Filter.CostNet.To': params.costNet?.max,
        'Filter.CostGross.From': params.costGross?.min,
        'Filter.CostGross.To': params.costGross?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingOutstandingOverduePurchaseOrdersReport(
    params: OutstandingOverduePurchasingFilters,
  ): AxiosGenericResponse<PurchasingOutstandingOverduePurchaseOrdersReportResponse> {
    return instance.get('/report/purchasingReports/purchasingOutstandingOverduePurchaseOrdersReport', {
      params: {
        'Filter.Assignees': params.assigneeIds,
        'Filter.Suppliers': params.supplierIds,
        'Filter.PurchaseOrderDate.From': params.purchaseOrderDate?.min,
        'Filter.PurchaseOrderDate.To': params.purchaseOrderDate?.max,
        'Filter.DueDate.From': params.dueDate?.min,
        'Filter.DueDate.To': params.dueDate?.max,
        'Filter.PurchaseOrderNumber': params.purchaseOrderNumber,
        'Filter.MinCostNet': params.costNet?.min,
        'Filter.MaxCostNet': params.costNet?.max,
        'Filter.MinCostGross': params.costGross?.min,
        'Filter.MaxCostGross': params.costGross?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingOutstandingOverduePurchaseOrdersReportExcel(params: OutstandingOverduePurchasingExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/purchasingReports/purchasingOutstandingOverduePurchaseOrdersReportExcel', {
      params: {
        'Filter.Assignees': params.assigneeIds,
        'Filter.Suppliers': params.supplierIds,
        'Filter.PurchaseOrderDate.From': params.purchaseOrderDate?.min,
        'Filter.PurchaseOrderDate.To': params.purchaseOrderDate?.max,
        'Filter.DueDate.From': params.dueDate?.min,
        'Filter.DueDate.To': params.dueDate?.max,
        'Filter.PurchaseOrderNumber': params.purchaseOrderNumber,
        'Filter.MinCostNet': params.costNet?.min,
        'Filter.MaxCostNet': params.costNet?.max,
        'Filter.MinCostGross': params.costGross?.min,
        'Filter.MaxCostGross': params.costGross?.max,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchCancelledLinesReport(params: CancelledLinesReportFilters): AxiosDataListResponse<CancelledLinesReportDto[]> {
    return instance.get('/report/purchasingReports/purchasingCancelledLinesReport', {
      params: {
        'Filter.CustomerIds': params.customerIds,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.EntityTypes': params.entityTypes,
        'Filter.ActionInitiatorIds': params.actionInitiatorIds,
        'Filter.ActionDate.From': params.actionDate?.min,
        'Filter.ActionDate.To': params.actionDate?.max,
        'Filter.ActivityTypes': params.activityTypes,
        'Filter.EntityNumber': params.entityNumber,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchCancelledLinesReportExcel(params: CancelledLinesReportExcelFilters): AxiosDownloadUriResponse {
    return instance.get('/report/purchasingReports/purchasingCancelledLinesReportExcel', {
      params: {
        'Filter.CustomerIds': params.customerIds,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.EntityTypes': params.entityTypes,
        'Filter.ActionInitiatorIds': params.actionInitiatorIds,
        'Filter.ActionDate.From': params.actionDate?.min,
        'Filter.ActionDate.To': params.actionDate?.max,
        'Filter.ActivityTypes': params.activityTypes,
        'Filter.EntityNumber': params.entityNumber,
        'Filter.PartCode': params.partCode,
        'Filter.PartName': params.partName,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingFitterReturnsReport(params: FitterReturnsFilters): AxiosDataListResponse<GetPurchasingFitterReturnsReportDto[]> {
    const deletionConfirmed = params.deletionConfirmed?.length
      ? params.deletionConfirmed.map((el) => el === 1)
      : [];
    return instance.get('/report/purchasingReports/purchasingFitterReturnsReport', {
      params: {
        'Filter.EntityAssignees': params.assignees,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Customers': params.customerIds,
        'Filter.EntityTypes': params.entityType,
        'Filter.DeletionDate.From': params.deletionDate?.min,
        'Filter.DeletionDate.To': params.deletionDate?.max,
        'Filter.PartCode': params.partCode,
        'Filter.JobTypes': params.jobType,
        'Filter.DeletionInitiators': params.deletionInitiators,
        'Filter.DeletionConfirmed': deletionConfirmed,
        'Filter.PartName': params.partName,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchasingFitterReturnsReportExcel(params: Omit<FitterReturnsFilters, keyof PagingType>): AxiosDownloadUriResponse {
    const deletionConfirmed = params.deletionConfirmed?.length
      ? params.deletionConfirmed.map((el) => el === 1)
      : [];
    return instance.get('/report/purchasingReports/purchasingFitterReturnsReportExcel', {
      params: {
        'Filter.EntityAssignees': params.assignees,
        'Filter.BusinessAreas': params.businessAreas,
        'Filter.Customers': params.customerIds,
        'Filter.EntityTypes': params.entityType,
        'Filter.DeletionDate.From': params.deletionDate?.min,
        'Filter.DeletionDate.To': params.deletionDate?.max,
        'Filter.PartCode': params.partCode,
        'Filter.JobTypes': params.jobType,
        'Filter.DeletionInitiators': params.deletionInitiators,
        'Filter.DeletionConfirmed': deletionConfirmed,
        'Filter.PartName': params.partName,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
};
