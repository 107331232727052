import React from 'react';
import Thead from '../../../../../../core/components/table-components/Thead';
import AddPartColumns from './AddPartColumns';
import AddPartBody from './AddPartBody';
import { NumberFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import { PartDtoType, StockFiltersType } from '../../../../../../stock/search/types/stockSearchTypes';

type AddPartTableProps = {
  filters: StockFiltersType,
  stockParts: PartDtoType[],
  totalCount: number,
  checkRow: NumberFunctionType,
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkedKeys: number[],
}

const AddPartTable: React.FC<AddPartTableProps> = ({
  filters, stockParts, isAll, isIndeterminate, checkAll, checkedKeys, totalCount, checkRow,
}) => {
  return (
    <TableNew>
      <Thead>
        <AddPartColumns
          filters={filters}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          checkAll={checkAll}
          totalElements={totalCount}
        />
      </Thead>
      <AddPartBody
        filters={filters}
        stockParts={stockParts}
        checkedKeys={checkedKeys}
        checkRow={checkRow}
      />
    </TableNew>
  );
};

export default AddPartTable;
