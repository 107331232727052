import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { InvoicingVewPageContentEnum, InvoicingVewPageContentEnumList } from '../enums/InvoicingVewPageContentEnum';
import {
  invoicingPurchaseOrderSelector,
  purchasesViewOrderLinesSelector,
} from '../../../store/selectors/invoicingSelectors';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';

type InvoicingViewStaticSectionProps = {
  setModeParam: StringFunctionType,
  mode: string | null,
}

const InvoicingViewStaticSection: React.FC<InvoicingViewStaticSectionProps> = ({ mode, setModeParam }) => {
  const { windowWidth } = useScreenWitdh();
  const currentUser = useAppSelector(currentUserSelector);
  const [params, setSearchParams] = useSearchParams();
  const data = useAppSelector(purchasesViewOrderLinesSelector);
  const invoicingPurchaseOrder = useAppSelector(invoicingPurchaseOrderSelector);
  const totalCount = data?.purchaseOrderParts?.length || 0;

  const tabsOptions = [
    {
      label: `Order lines ${tabCountHandle(totalCount)}`,
      value: InvoicingVewPageContentEnum.ORDER_LINES,
    },
    {
      label: `Invoices & credit notes ${tabCountHandle(invoicingPurchaseOrder?.purchaseOrder.invoiceDocumentsCount)}`,
      value: InvoicingVewPageContentEnum.INVOICES_AND_CREDIT_NOTES,
    },
  ];
  useEffect(() => {
    if (currentUser) {
      const modeParam = params.get('mode');
      const { permissions } = currentUser.userContextRole;
      if (!modeParam && permissions.includes(PermissionEnum.InvoicingPurchases)) {
        setSearchParams({ mode: tabsOptions[0].value }, { replace: true });
      } else {
        if (!InvoicingVewPageContentEnumList.includes(modeParam as InvoicingVewPageContentEnum)) {
          setSearchParams({ mode: InvoicingVewPageContentEnum.ORDER_LINES }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={tabsOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className="tabs-and-props desktop-sticky-filters">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={tabsOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="tabs-and-props__tabs"
          />
        )}
      </div>
    </>
  );
};

export default InvoicingViewStaticSection;
