import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { jobTrackingColumns } from '../../../utils/data';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import Tr from '../../../../../core/components/table-components/Tr';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Tfoot from '../../../../../core/components/table-components/Tfoot';
import { jobTimeTrackingWorkLogsSelector } from '../../../../../store/selectors/jobsSelector';
import { useAppSelector } from '../../../../../store/hooks';
import { GetJobTimeTrackingResponse, JobTimeTrackingWorkflowDto } from '../../../types/jobViewTypes';
import Thead from '../../../../../core/components/table-components/Thead';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';

type JobTimeTrackingTableProps = {
  jobTimeTracking: GetJobTimeTrackingResponse | null,
}
const JobTimeTrackingTable: React.FC<JobTimeTrackingTableProps> = ({ jobTimeTracking }) => {
  const worklogs = useAppSelector(jobTimeTrackingWorkLogsSelector);
  const [summary, setSummary] = useState({
    spent: 0,
    estimated: 0,
    remaining: 0,
  });
  useEffect(() => {
    if (jobTimeTracking) {
      const { estimated, remaining, worklogs } = jobTimeTracking;
      const spentList = worklogs.map((el) => (el.jobSpentMinutes ? +(el.jobSpentMinutes / 60).toFixed(2) : 0));
      const spentListLength = spentList.length;
      const isOneOrNoAssignee = spentListLength <= 1;

      const summarySpent = isOneOrNoAssignee
        ? spentListLength === 0
          ? 0
          : (+spentList[0].toFixed(2) || 0)
        : +spentList.reduce((w1, w2) => (w1 || 0) + (w2 || 0)).toFixed(2) || 0;
      const summaryEstimated = estimated ? +(+estimated / 60).toFixed(2) : 0;
      const summaryRemaining = remaining ? +(+remaining / 60).toFixed(2) : 0;

      setSummary({
        spent: summarySpent,
        estimated: summaryEstimated,
        remaining: summaryRemaining,
      });
    }
  }, [jobTimeTracking]);

  const renderCell = (key: string, value: string | number | boolean | null, data: JobTimeTrackingWorkflowDto) => {
    switch (key) {
      case 'jobAssignee':
        return (
          <TdNew lg>
            <TruncateTooltip
              value={value as string}
              className={classNames(
                'jobTimeTrackingTable__assignee',
                { 'jobTimeTrackingTable__deleted-assignee': data.isAssigneeDeleted },
              )}
              table
            />
          </TdNew>
        );
      case 'jobSpentMinutes':
        return <TdNew
          className={classNames({ 'jobTimeTrackingTable__deleted-assignee': data.isAssigneeDeleted })}
          lg
        >
          {`${((value || 0) as number / 60).toFixed(2)} h`}
        </TdNew>;
      default: return null;
    }
  };

  return (
    <TableNew className="jobTimeTrackingTable" hasFooter>
      <Thead>
        <Tr>
          {jobTrackingColumns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            totalElements={worklogs?.length || 0}
            className={`jobTrackingTh--${col.dataIndex}`}
          />)}
        </Tr>
      </Thead>
      <Tbody>
        {worklogs && worklogs.length > 0
          ? worklogs.map((d, i) => <Tr key={i}>
            <>
              {Object.entries(d).map(([key, value]) => renderCell(key, value, d))}
              <TdNew />
              <TdNew />
            </>
          </Tr>)
          : <EmptyTableSection text="No records" />}
      </Tbody>
      <Tfoot>
        <Tr>
          <TdNew>Total</TdNew>
          {Object.values(summary).map((v, i) => <TdNew key={i}>
            {v}
            {' '}
            h
          </TdNew>)}
        </Tr>
      </Tfoot>
    </TableNew>
  );
};

export default JobTimeTrackingTable;
