import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import './PartViewPage.scss';
import StaticAreaContainer from './components/static-area/StaticAreaContainer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  stockPartDetailsSelector,
  stockPartTotalQuantitySelector,
  stockPartTypesValuesSelector,
} from '../../store/selectors/stockSelectors';
import {
  deleteStockPartThunk,
  getStockPartAffectedThunk,
  getStockPartByIdThunk,
  patchStockPartThunk,
} from '../../store/thunks/stock/part/partViewPageThunks';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import ReorderLevelsModal from './components/modals/set-reorder-levels/ReorderLevelsModal';
import DeleteBlockedModal from './components/modals/delete-action-is-blocked/DeleteBlockedModal';
import { QuoteUnitsOfMeasureSelector } from '../../store/selectors/coreSelectors';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import { deletePartQuantities, getPartTypes } from '../../store/thunks/stock/stockThunks';
import { useStockPartActions } from './hooks/useStockPartActions';
import DynamicAreaContainer from './components/dynamic-area/DynamicAreaContainer';
import { TruncateTooltip } from '../../common/components/truncate-tooltip/TruncateTooltip';
import { routesPath } from '../../core/enums/pathEnum';
import AdjustPartQtyModal from './components/modals/adjust-part-quantities/AdjustPartQtyModal';
import { StockPageParamEnums } from '../container/enums/stockEnums';
import { cleanUpPartViewHandle } from './utils/cleanUpPartViewHandle';
import { initSmallPaging } from '../../core/types/coreTypes';
import PartAffectedEntitiesModal from './components/modals/part-affected-entities/PartAffectedEntitiesModal';
import EditPriceModal from './components/modals/edit-price/EditPriceModal';
import { getSupplierStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';
import EditLinkedToModal from './components/modals/edit-linked-to/EditLinkedToModal';
import { getOrderDictionary } from '../../store/thunks/coreThunk';

const PartViewPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const totalQuantity = useAppSelector(stockPartTotalQuantitySelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const partTypes = useAppSelector(stockPartTypesValuesSelector);
  const { backHandle } = useLocationHistoryState(true);
  const [visibleConfirmationModal, setVisibleConfirmationModal] = useState(false);
  const [visibleAffectedModal, setVisibleAffectedModal] = useState(false);
  const [visibleReorderModal, setVisibleReorderModal] = useState(false);
  const [visibleDeleteBlockedModal, setVisibleDeleteBlockedModal] = useState(false);
  const [adjustQtyModal, setAdjustQtyModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState<number | undefined>(undefined);
  const [isDeleteQty, setIsDeleteQty] = useState(false);
  const [qtyToChange, setQtyToChange] = useState<number | undefined>(undefined);
  const [isEditQtyModal, setIsEditQtyModal] = useState(false);
  const [isEditLinkedToModal, setIsEditLinkedToModal] = useState(false);

  const deletePartHandle = () => {
    if (partDetails && partDetails.part.type === partTypes.part) {
      totalQuantity
        ? setVisibleDeleteBlockedModal(true)
        : id && dispatch(getStockPartAffectedThunk({
          id: +id,
          filters: initSmallPaging,
          complexAction: () => setVisibleAffectedModal(true),
          confirmAction: () => setVisibleConfirmationModal(true),
        }));
    } else {
      id && dispatch(getStockPartAffectedThunk({
        id: +id,
        filters: initSmallPaging,
        complexAction: () => setVisibleAffectedModal(true),
        confirmAction: () => setVisibleConfirmationModal(true),
      }));
    }
  };

  const setInOrOutOfStock = (status: number) => {
    id && dispatch(patchStockPartThunk({ id: +id, data: { status } }));
  };

  const {
    actions, allowedActions, setAllowedActions,
  } = useStockPartActions(
    partDetails?.part.status,
    partTypes,
    setInOrOutOfStock,
    setVisibleReorderModal,
    deletePartHandle,
    setAdjustQtyModal,
  );

  useEffect(() => {
    if (partDetails) {
      const actionsList = actions.filter((act) => {
        return act.availableForTypes.includes(partDetails.part.type);
      });
      setAllowedActions(actionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [partDetails, partTypes]);

  useEffect(() => {
    id && dispatch(getStockPartByIdThunk({ id: +id }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, params]);

  useEffect(() => {
    dispatch(getPartTypes());
    dispatch(getSupplierStatusesThunk());
    dispatch(getOrderDictionary({ filters: { onlyActive: true } }));

    return () => {
      cleanUpPartViewHandle(dispatch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout subHeader={
      <DetailsHead
        goBack={() => backHandle(`/${routesPath.STOCK}?page=${StockPageParamEnums.SEARCH}`)}
        actions={allowedActions}
        className="partViewPageHead"
      >
        <h2 className="partViewPage__title">
          <TruncateTooltip value={partDetails?.part.name || '-'} className="partViewPage__title-text" />
        </h2>
      </DetailsHead>
    }
    >
      <ConfirmationModal
        isVisible={visibleConfirmationModal}
        onCancel={() => setVisibleConfirmationModal(false)}
        confirmHandler={() => {
          id && dispatch(deleteStockPartThunk({
            id: +id,
            closeModal: () => setVisibleConfirmationModal(false),
            navigate,
          }));
        }}
      />
      <PartAffectedEntitiesModal
        visible={visibleAffectedModal}
        onClose={() => setVisibleAffectedModal(false)}
        partName={partDetails?.part.name || '-'}
      />
      <DeleteBlockedModal
        visible={visibleDeleteBlockedModal}
        onCancel={() => setVisibleDeleteBlockedModal(false)}
        partName={partDetails?.part.name}
        total={totalQuantity}
        unitOfMeasure={unitsOfMeasure.find((unit) => unit.value === partDetails?.part.unitOfMeasure)?.label}
      />
      <ReorderLevelsModal
        visible={visibleReorderModal}
        onCancel={() => setVisibleReorderModal(false)}
      />
      <EditPriceModal
        visible={isEditQtyModal}
        qtyToChange={qtyToChange}
        onClose={() => {
          setIsEditQtyModal(false);
          setQtyToChange(undefined);
        }}
      />
      <AdjustPartQtyModal
        onCancel={() => setAdjustQtyModal(false)}
        isVisible={adjustQtyModal}
        currentStatus={currentStatus}
        setCurrentStatus={setCurrentStatus}
        setIsDeleteQty={setIsDeleteQty}
        setQtyToChange={setQtyToChange}
        setIsEditQtyModal={setIsEditQtyModal}
        setIsEditLinkedToModal={setIsEditLinkedToModal}
      />
      <EditLinkedToModal
        visible={isEditLinkedToModal}
        onCancel={() => setIsEditLinkedToModal(false)}
        qtyId={qtyToChange}
        setQtyId={setQtyToChange}
      />
      <ConfirmationModal
        isVisible={isDeleteQty}
        onCancel={() => setIsDeleteQty(false)}
        confirmHandler={() => {
          if (id && currentStatus && qtyToChange) {
            dispatch(deletePartQuantities({
              partId: +id,
              id: qtyToChange,
              onFinish: () => {
                setIsDeleteQty(false);
                setQtyToChange(undefined);
              },
              statusToRefetch: currentStatus,
            }));
          }
        }}
      />
      <div className="partViewPage">
        <StaticAreaContainer setVisibleAffectedModal={setVisibleAffectedModal} />
        <DynamicAreaContainer />
      </div>
    </Layout>
  );
};

export default PartViewPage;
