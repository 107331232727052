import React from 'react';
import { EnquiryEventDtoBase } from '../../../types/enquiryDetailsTypes';
import { useAppSelector } from '../../../../../store/hooks';
import EnquiryFieldUpdateEvent from './event-description-items/EnquiryFieldUpdateEvent';
import EnquiryNoteEvent from './event-description-items/EnquiryNoteEvent';
import QuoteLineCreationEvent from './event-description-items/QuoteLineCreationEvent';
import { EnquiryEventTypesEnum } from '../../../enums/EnquiryEventTypes';
import CreationEvent from '../../../../../common/components/activity-events/creation/CreationEvent';
import StatusChangeEvent from '../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import BillingShippingEvents from '../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import AttachmentEvents from '../../../../../common/components/activity-events/attachment/AttachmentEvents';
import ContactEvents from '../../../../../common/components/activity-events/contact/ContactEvents';
import JobLocationEvent from '../../../../../common/components/activity-events/job-location/JobLocationEvent';
import { getOldAndNewLocationValues } from '../../../../../common/utils/getOldAndNewLocationValues';
import VehicleEvents from '../../../../../common/components/activity-events/vehicle/VehicleEvents';
import { enquiryStatusSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { enquiryEventTypesSelector } from '../../../../../store/selectors/coreEventTypesSelectors';
import DeletionEvent from '../../../../../common/components/activity-events/deletion/DeletionEvent';

type EnquiryEventProps = {
  item: EnquiryEventDtoBase
}

const EnquiryEvent: React.FC<EnquiryEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(enquiryEventTypesSelector);
  const enquiryStatus = useAppSelector(enquiryStatusSelector);

  const attachmentEvents = [
    EnquiryEventTypesEnum.AddingAttachmentWithoutComment,
    EnquiryEventTypesEnum.AddingAttachmentWithComment,
    EnquiryEventTypesEnum.EditingAttachmentComment,
    EnquiryEventTypesEnum.DeletingAttachment,
  ];
  const contactEvents = [
    EnquiryEventTypesEnum.AddingContact,
    EnquiryEventTypesEnum.ContactChange,
    EnquiryEventTypesEnum.RemovingContact,
  ];
  const billingShippingEvents = [
    EnquiryEventTypesEnum.BillingDetailsUpdates,
    EnquiryEventTypesEnum.ShippingDetailsUpdates,
  ];
  const vehicleEvents = [
    EnquiryEventTypesEnum.AddingVehicle,
    EnquiryEventTypesEnum.EditingVehicleDetails,
    EnquiryEventTypesEnum.DeletingVehicle,
  ];

  const enquiryEventRenderFn = (item: EnquiryEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.enquiryEventType)?.label || '';
    switch (true) {
      case event === EnquiryEventTypesEnum.Note:
        return <EnquiryNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === EnquiryEventTypesEnum.EnquiryCreation:
        return <CreationEvent entity="Enquiry" type="number" value={item.enquiryNumber} />;
      case event === EnquiryEventTypesEnum.EnquiryDeletion:
        return <DeletionEvent entity="Enquiry" />;
      case event === EnquiryEventTypesEnum.EnquiryFieldUpdateOrAddingValue:
        return <EnquiryFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === EnquiryEventTypesEnum.EnquiryStatusChange: {
        const prevStatus = enquiryStatus.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = enquiryStatus.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case event === EnquiryEventTypesEnum.QuoteLineCreationChanges:
        return <QuoteLineCreationEvent item={item} />;
      case event === EnquiryEventTypesEnum.JobLocationUpdate: {
        const locationValues = getOldAndNewLocationValues(item);
        return <JobLocationEvent {...locationValues} />;
      }
      case (billingShippingEvents.includes(event as EnquiryEventTypesEnum)):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (attachmentEvents.includes(event as EnquiryEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (contactEvents.includes(event as EnquiryEventTypesEnum)):
        return <ContactEvents
          eventName={event}
          contact={item.contact}
          firstName={event === EnquiryEventTypesEnum.AddingContact ? item.contact?.firstName : item.firstName}
          lastName={event === EnquiryEventTypesEnum.AddingContact ? item.contact?.lastName : item.lastName}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (vehicleEvents.includes(event as EnquiryEventTypesEnum)):
        return <VehicleEvents event={event} item={item} vehicleName={item.tabName} />;
      default: return null;
    }
  };

  return <>{enquiryEventRenderFn(item)}</>;
};

export default EnquiryEvent;
