import { faBell, faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  useEffect, useMemo, useState,
} from 'react';
import { Key } from 'antd/lib/table/interface';
import { useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import Button from '../button/Button';
import Dropdown from '../dropdown/Dropdown';
import Segmented from '../segmented/Segmented';
import './Header.scss';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';
import {
  allowedBaForUserSelector,
  allowedShortBaForUserSelector,
  collapsedSidebarSelector,
  currentBusinessAreaSelector,
} from '../../../store/selectors/coreSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  setCollapsedSidebar, setCurrentArea, setCurrentServerError,
} from '../../../store/slices/coreSlice';
import { setColumnsPagination, setEnquiryCollections, setFilters } from '../../../store/slices/enquiriesSlice';
import { currentUserSelector, inAppNotificationsCountSelector } from '../../../store/selectors/accountSelectors';
import { routesPath } from '../../enums/pathEnum';
import { CreateKeyEnum, createUrls } from '../../enums/createKeysEnum';
import USER_ICON from '../../../assets/images/user-icon.png';
import { useLocationHistoryState } from '../../hooks/useLocationHistoryState';
import { headerCreateActions } from '../../utils/testData';
import Selectable from '../selectable/Selectable';
import SMALL_LOGO from '../../../assets/icons/Logo-small.svg';
import { infiniteScrollInitPaging, VoidFunctionType } from '../../types/coreTypes';
import CreatePurchaseOrderModesModal from '../../../stock/purchase-orders/create/CreatePurchaseOrderModesModal';
import { removeTokenHandler } from '../../utils/removeTokenHandler';

type HeaderProps = {
  openDrawer: VoidFunctionType,
}

const Header: React.FC<HeaderProps> = ({ openDrawer }) => {
  const dispatch = useAppDispatch();
  const allowedBaForUser = useAppSelector(allowedBaForUserSelector);
  const allowedShortBaForUser = useAppSelector(allowedShortBaForUserSelector);
  const currentArea = useAppSelector(currentBusinessAreaSelector);
  const inAppNotificationsCount = useAppSelector(inAppNotificationsCountSelector);
  const [createModeModal, setCreateModeModal] = useState(false);

  useEffect(() => {
    if (allowedBaForUser && allowedBaForUser.length > 0 && !currentArea) {
      dispatch(setCurrentArea(allowedBaForUser[0].value));
    }
    // eslint-disable-next-line
  }, [allowedBaForUser, currentArea]);
  const collapsed = useAppSelector(collapsedSidebarSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();

  const navigateToCreatePage = (key: Key) => {
    navigate(`/${createUrls[key as keyof typeof createUrls]}`, { replace: true });
    const locationObj = {
      pathname: location.pathname,
      search: location.search,
    };
    setLocationHistoryHandle(locationObj);
  };

  const createHandle = (key: Key) => {
    if (key === CreateKeyEnum.CREATE_PURCHASE_ORDER) {
      setCreateModeModal(true);
    } else {
      navigateToCreatePage(key);
    }
    dispatch(setCurrentServerError(undefined));
  };

  const { windowWidth } = useScreenWitdh();
  const [createActions, setCreateActions] = useState<{ label: string, key: string }[]>([]);

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const allowedActions = headerCreateActions.filter((act) => permissions.includes(act.requires));
      setCreateActions(allowedActions);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  const navigateToProfile = () => {
    if (currentUser) {
      setLocationHistoryHandle({ pathname: location.pathname, search: location.search });
      navigate(`/${routesPath.ACCOUNT}`);
      dispatch(setCurrentServerError(undefined));
    }
  };
  const signOutHandle = () => {
    removeTokenHandler(dispatch);
    navigate('/login');
    dispatch(setCurrentServerError(undefined));
  };

  const openNotification = () => {
    openDrawer();
  };

  const businessAreasOptions = useMemo(() => {
    if (windowWidth <= responsiveEndpointsEnum.LG) return allowedShortBaForUser;
    if (!collapsed) return allowedShortBaForUser;
    return windowWidth > responsiveEndpointsEnum.LG && windowWidth < responsiveEndpointsEnum.XL
      ? allowedShortBaForUser
      : allowedBaForUser;
  }, [allowedBaForUser, allowedShortBaForUser, windowWidth, collapsed]);

  const changeCurrentArea = (value: number | string) => {
    dispatch(setEnquiryCollections(null));
    dispatch(setColumnsPagination([]));
    dispatch(setFilters(infiniteScrollInitPaging));
    dispatch(setCurrentArea(+value));
    dispatch(setCurrentServerError(undefined));
  };

  return (
    <>
      <CreatePurchaseOrderModesModal
        visible={createModeModal}
        onCancel={() => {
          setCreateModeModal(false);
        }}
      />
      <div className={classNames('header', {
        'headerNotCollapsed': collapsed,
      })}
      >
        <div className="headerLeft">
          <button className="headerLeft__collapse-btn" type="button" onClick={() => dispatch(setCollapsedSidebar(false))}>
            <img src={SMALL_LOGO} alt="" />
          </button>
          {windowWidth && windowWidth <= responsiveEndpointsEnum.LG
            ? <Selectable
              value={currentArea}
              options={businessAreasOptions}
              onChange={changeCurrentArea}
              size="sm"
            />
            : <Segmented
              options={businessAreasOptions}
              value={currentArea || ''}
              onChange={changeCurrentArea}
            />}
          {createActions.length > 0 && <Dropdown
            label={windowWidth > responsiveEndpointsEnum.LG ? 'Create' : ''}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            menuItems={createActions}
            clickHandle={createHandle}
            className="headerDropdown"
            parentRender
          />}
        </div>
        <div className="headerRight">
          <div className="headerRight__actions">
            <Button
              icon={<FontAwesomeIcon icon={faBell} />}
              onClick={() => openNotification()}
              countValue={inAppNotificationsCount}
              className="headerRight__bellIcon-button"
            />
          </div>
          <div className="headerRight__user">
            <div className="headerRight__user-wrapper">
              <img src={USER_ICON} alt="" className="headerRight__user-icon" />
              <Dropdown
                label={`${currentUser?.firstName} ${currentUser?.lastName}`}
                menuItems={[
                  {
                    label: 'Profile',
                    key: 'profile',
                    onClick: navigateToProfile,
                  },
                  {
                    type: 'divider',
                  },
                  {
                    label: 'Sign out',
                    key: 'sign-out',
                    onClick: signOutHandle,
                  },
                ]}
                className="headerRight__user-dropdown"
                overlayClassName="headerRight__user-menu"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
