import React from 'react';
import AddContactEvent from './AddContactEvent';
import { fieldNameEventHandle } from '../../../utils/activityLogUtils';
import { ContactEvent } from '../../../types/commonTypes';

enum ContactEventsEnum {
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
}

type ContactEventsProps = {
  eventName: string,
  contact?: ContactEvent,
  firstName?: string | null,
  lastName?: string | null,
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const ContactEvents: React.FC<ContactEventsProps> = ({
  eventName,
  contact,
  firstName,
  lastName,
  field,
  oldValue,
  newValue,
}) => {
  const renderContactEventFn = () => {
    switch (eventName) {
      case ContactEventsEnum.AddingContact:
        return <AddContactEvent contact={contact} />;
      case ContactEventsEnum.ContactChange:
        return (
          <>
            Field
            {' '}
            <b>{fieldNameEventHandle(field)}</b>
            {' '}
            of
            <b>{` ${firstName} ${lastName} `}</b>
            contact was changed:
            {' '}
            {!!oldValue && <s>{`${oldValue}`}</s>}
            {!!newValue && <b>{` ${newValue}`}</b>}
          </>
        );
      case ContactEventsEnum.RemovingContact:
        return (
          <>
            <b>{`${firstName} ${lastName}`}</b>
            {' '}
            contact was removed
          </>
        );
      default: return null;
    }
  };

  return (
    <>{renderContactEventFn()}</>
  );
};

export default ContactEvents;
