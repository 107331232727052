import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormReset, UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import {
  CreateCustomerReqType,
  CreateCustomerResType,
} from '../../../customers/create-customer/types/createCustomerTypes';
import { routesPath } from '../../../core/enums/pathEnum';
import { duplicateServerErrorsHelper } from '../../../core/utils/checkIsDuplicateError';
import { CustomerFields } from '../../../customers/create-customer/utils/CustomerSchema';

export const createCustomerThunk = createAsyncThunk<
ResponseSingleResult<CreateCustomerResType>,
{
  data: CreateCustomerReqType,
  reset: UseFormReset<CustomerFields>,
  navigate: NavigateFunction,
  setError: UseFormSetError<CustomerFields>,
}
>('post/Customer', async ({
  data, reset, navigate, setError,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await customersAPI.createCustomer(data);
    const { createdId, createdName } = response.data.data;
    dispatch(setSuccessMessage({ message: `Customer ${createdName} was successfully created.` }));
    reset();
    navigate(`/${routesPath.CUSTOMERS}/${createdId}`, { replace: true });
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    const errors = error.response?.data.errors || [];
    duplicateServerErrorsHelper(errors, setError);
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
