import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, infiniteScrollInitPaging, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import {
  CreateCustomerContactResponse,
  CustomerContactFiltersType, NewCustomerContactReqDataType,
} from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { getCustomerById } from './customersViewPageThunks';
import { ContactDto } from '../../../common/types/commonTypes';
import { setCustomerContactsFilters } from '../../slices/customersSlice';
import { RootState } from '../../store';

export const getCustomerContactsList = createAsyncThunk<ResponseResult<ContactDto[]> | null,
{customerId: number,
  filters: CustomerContactFiltersType,
}
>(
  'get/CustomerContactsList',
  async ({ customerId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerContacts(customerId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteCustomerContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ customerId: number, contactId: number, closeModal: VoidFunctionType }>(
  'delete/Customer/Contact',
  async ({
    contactId, closeModal, customerId,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { customers } = getState() as RootState;
      const { customerContactsFilters } = customers;
      const response = await customersAPI.deleteCustomerContact(contactId);
      const newFilters = { ...customerContactsFilters, page: 1, pageSize: 50 };
      if (customerContactsFilters.page > 1) {
        window.scrollTo(0, 0);
        dispatch(setCustomerContactsFilters(newFilters));
      }
      dispatch(getCustomerContactsList({ customerId, filters: newFilters }));
      dispatch(getCustomerById({ id: customerId }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewCustomerContactThunk = createAsyncThunk<ResponseSingleResult<CreateCustomerContactResponse>,
{ data: NewCustomerContactReqDataType, closeModal: VoidFunctionType }>(
  'post/Customer/NewContact',
  async ({
    data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { customers } = getState() as RootState;
      const { customerContactsFilters } = customers;
      const response = await customersAPI.createNewCustomerContact(data);
      const newFilters = { ...customerContactsFilters, ...infiniteScrollInitPaging };
      if (customerContactsFilters.page > 1) {
        dispatch(setCustomerContactsFilters(newFilters));
        window.scrollTo(0, 0);
      }
      dispatch(getCustomerContactsList({
        customerId: data.customerId, filters: newFilters,
      }));
      dispatch(getCustomerById({ id: data.customerId }));
      closeModal();
      dispatch(setSuccessMessage({ message: `Contact ${data.firstName} ${data.lastName} was successfully created.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editCustomerContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ contactId:number, data: NewCustomerContactReqDataType, closeModal: VoidFunctionType, }>(
  'edit/Customer/Contact',
  async ({
    data, closeModal, contactId,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { customers } = getState() as RootState;
      const { customerContactsFilters } = customers;
      const response = await customersAPI.editCustomerContact(contactId, data);
      const newFilters = { ...customerContactsFilters, ...infiniteScrollInitPaging };
      if (customerContactsFilters.page > 1) {
        dispatch(setCustomerContactsFilters(newFilters));
        window.scrollTo(0, 0);
      }
      dispatch(getCustomerContactsList({
        customerId: data.customerId, filters: newFilters,
      }));
      closeModal();
      dispatch(setSuccessMessage({ message: `Contact ${data.firstName} ${data.lastName} was successfully updated.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
