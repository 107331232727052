import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType, CoreAttachmentDto, CoreAttachmentsFilters,
  initialCoreAttachmentsFilters, ResponseSingleResult, VoidFunctionType, AxiosErrorResponse, GetAttachmentsResponse, emptyPaging,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { SuppliersAPI } from '../../../api/suppliersApi';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setSupplierAttachments, setSupplierAttachmentsFilters } from '../../slices/supplierSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { getSupplierByIdThunk } from './suppliersViewPageThunks';
import { RootState } from '../../store';

export const getSuppliersAttachments = createAsyncThunk<GetAttachmentsResponse,
{
  supplierId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/SuppliersAttachments',
  async ({ supplierId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSuppliersAttachments(supplierId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createSuppliersAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  id: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}
>('post/SuppliersAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { supplier } = getState() as RootState;
    const { supplierAttachmentsFilters } = supplier;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await SuppliersAPI.postSupplierAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await SuppliersAPI.postSupplierAttachment(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getSupplierByIdThunk({ id }));
    dispatch(getSuppliersAttachments({ supplierId: +id, filters: supplierAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editSupplierAttachment = createAsyncThunk<
ResponseSingleResult,
{ id: number, supplierId: number, comment: string, onCancel: VoidFunctionType }
>('patch/SupplierAttachment', async ({
  id, supplierId, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { supplier: { supplierAttachments } } = getState() as RootState;
    const response = await SuppliersAPI.editSupplierAttachment(id, supplierId, comment);
    const newArr = supplierAttachments.items.map((item: CoreAttachmentDto) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setSupplierAttachments({ ...supplierAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteSupplierAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, supplierId: number, closeModal: VoidFunctionType }>(
  'delete/SingleSupplierAttachment',
  async ({
    id, supplierId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.deleteSupplierAttachment(id, supplierId);
      dispatch(getSuppliersAttachments({
        supplierId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setSupplierAttachmentsFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getSupplierByIdThunk({ id: supplierId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteMultipleSupplierAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  supplierId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleSupplierAttachments',
  async ({
    ids, supplierId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier: { supplierAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await SuppliersAPI.fetchSuppliersAttachments(supplierId, {
        ...supplierAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await SuppliersAPI.deleteMultipleSupplierAttachment(supplierId, filteredIds);
      }
      dispatch(setSupplierAttachmentsFilters({ ...supplierAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getSupplierByIdThunk({ id: supplierId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierAttachmentById = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, supplierId: number }
>('get/SupplierAttachmentById', async ({ id, supplierId }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await SuppliersAPI.getSupplierAttachmentById(id, supplierId);
    downloadCsv(response.data.data.uri);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
