import { initialSettingsState } from '../../store/slices/settingsSlice';
import { CreateKeyEnum } from '../enums/createKeysEnum';
import { PermissionEnum } from '../enums/dictionariesEnums';
import { initialSharedState } from '../../store/slices/sharedSlice';
import { initialCoreState } from '../../store/slices/coreSlice';
import { initialEnquiryState } from '../../store/slices/enquiriesSlice';
import { initialAccountState } from '../../store/slices/accountSlice';
import { initialJobsState } from '../../store/slices/jobsSlice';
import { initialCustomersState } from '../../store/slices/customersSlice';
import { initialPricelistState } from '../../store/slices/pricelistSlice';
import { initialStockState } from '../../store/slices/stockSlice';
import { purchaseOrderInitialState } from '../../store/slices/purchaseOrderSlice';
import { initialPartsKitState } from '../../store/slices/partsKitSlice';
import { initialOrdersState } from '../../store/slices/ordersSlice';
import { initialRectificationState } from '../../store/slices/rectificationsSlice';
import { initialEmployeeState } from '../../store/slices/employeesSlice';
import { initialSupplierState } from '../../store/slices/supplierSlice';
import { initialVehiclesState } from '../../store/slices/vehiclesSlice';
import { initialCoreEventTypesState } from '../../store/slices/coreEventTypesSlice';
import { initialCoreOrderingState } from '../../store/slices/coreOrderingSlice';
import { initialCoreStatusesState } from '../../store/slices/coreStatusesSlice';

export const serverErrorResponse = {
  response: { data: { errors: [{ key: 'string', message: 'Error string' }] } },
};

export const testDictionaryItem = { label: 'test', value: 1 };

export const dictionaryResponseMock = {
  data: {
    data: {
      items: [testDictionaryItem],
    },
    success: true,
    errors: [],
    status: '200',
  },
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
};

export const nullishSingleResponseMock = {
  data: {
    data: null,
    success: true,
    errors: [],
    status: '200',
  },
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
};

export const downloadUriResponseMock = {
  data: {
    data: { downloadUri: 'www.uri.com' },
    success: true,
    errors: [],
    status: '200',
  },
  status: 200,
  statusText: 'OK',
  headers: {},
  config: {},
};

export const initRequisiteMock = {
  addressLine1: 'Address 1',
  addressLine2: 'Address 2',
  cityTown: 'City',
  companyName: 'Company',
  email: 'han_solo@vanliners.com',
  firstName: 'Firstname',
  lastName: 'Lastname',
  mobile: '+2332244323',
  postalCode: '123456',
  region: 'Region',
  telephone: '+2332244323',
};

export const preloadedState = {
  core: { ...initialCoreState, currentBusinessArea: 1 },
  coreEventTypes: initialCoreEventTypesState,
  coreOrdering: initialCoreOrderingState,
  coreStatuses: initialCoreStatusesState,
  account: {
    ...initialAccountState,
    currentUser: {
      id: 3,
      email: 'email',
      firstName: '1',
      lastName: '1',
      accountType: 2,
      userContextRole: {
        role: 1,
        roleLevel: 1,
        permissions: [PermissionEnum.JobListView],
      },
      status: 1,
      canSeeOnlyAssignedEntities: false,
      employeeReferenceNumber: null,
      employeeId: null,
      allowedBusinessAreas: [1, 2, 3, 4],
      allowedJobs: [1, 2],
      wasActive: true,
    },
    isAppInitialized: true,
    userProfile: null,
    inAppNotifications: {
      pages: 1,
      totalCount: 1,
      items: [
        {
          'notificationType': 1,
          'initiator': {
            'firstName': 'John',
            'lastName': 'Smith',
          },
          'newAssignees': [
            {
              'firstName': 'John',
              'lastName': 'Smith',
            },
          ],
          'entityType': 1,
          'entityId': 38126,
          'entityNumber': 'E24-VLC-NTQKA5',
          'notificationId': 3177,
          'createdDate': '2024-03-11T13:49:45.7471694',
          'readDate': null,
        },
      ],
    },
  },
  enquiries: {
    ...initialEnquiryState,
    columnsPagination: [
      { status: 1, currentPage: 1, pages: 2 },
      { status: 2, currentPage: 1, pages: 2 },
    ],
    enquiryCollections: [
      {
        isHidden: false,
        order: 1,
        pages: 2,
        status: 1,
        totalCount: 6,
        enquiries: [{
          assignedTo: 111,
          assignedToFullName: 'NOT IMPLEMENTED',
          createdDate: '2022-08-18T14:15:05.5646054',
          customerId: 73,
          customerShortName: 'Peter Parker Jr',
          customerType: 2,
          description: '90',
          enquiryNumber: 'eqn num 1',
          enquirySource: 3,
          estimatedCost: 0,
          id: 1504,
          isShippingRequisiteSameAsBilling: false,
          jobAddressLine1: 'Address Line 1',
          jobAddressLine2: 'Address Line 2',
          jobCityTown: 'CityTown',
          jobPostalCode: 'Postal',
          jobRegion: 'region',
          jobType: 3,
          modifiedDate: '2022-08-23T11:07:09.6791257',
          priorityLevel: 2,
          status: 1,
          warnings: [],
        }],
      },
      {
        isHidden: false,
        order: 1,
        pages: 2,
        status: 2,
        totalCount: 6,
        enquiries: [{
          assignedTo: 111,
          assignedToFullName: 'NOT IMPLEMENTED',
          createdDate: '2022-08-18T14:15:05.5646054',
          customerId: 73,
          customerShortName: 'Peter Parker Jr',
          customerType: 2,
          description: '90',
          enquiryNumber: 'eqn num 1',
          enquirySource: 3,
          estimatedCost: 0,
          id: 1505,
          isShippingRequisiteSameAsBilling: false,
          jobAddressLine1: 'Address Line 1',
          jobAddressLine2: 'Address Line 2',
          jobCityTown: 'CityTown',
          jobPostalCode: 'Postal',
          jobRegion: 'region',
          jobType: 3,
          modifiedDate: '2022-08-23T11:07:09.6791257',
          priorityLevel: 2,
          status: 1,
          warnings: [],
        }],
      },
    ],
  },
  jobs: {
    ...initialJobsState,
    jobTimeline: {
      items: [{
        jobEventType: 1,
        id: 13,
        content: 'note content',
        dateTime: '2023-05-15T06:42:56.0076792',
        initiator: 'John Smith',
      }],
      page: 1,
      totalCount: 1,
    },
  },
  customers: {
    ...initialCustomersState,
    customersDetails: {
      customer: {
        accountType: 1,
        alwaysUseRequisites: true,
        companyRegistrationNumber: 'AABB',
        createdAt: null,
        customerAccountNumber: 'ACC109090',
        customerStatus: 2,
        customerType: 2,
        enquiriesAmount: 0,
        id: 1,
        isShippingRequisiteSameAsBilling: true,
        jobsAmount: 0,
        name: 'Peter Parker Jr',
        ordersAmount: 0,
        rectificationsAmount: 0,
        updatedAt: '2022-08-22T15:17:00.2556328',
        vatNumber: '77777776',
      },
      revenue: 0,
    },
  },
  settings: {
    ...initialSettingsState,
    rolePermissions: [
      {
        role: {
          fullName: 'role',
          role: 1,
          roleLevel: 2,
          accountType: 1,
          isReadOnly: false,
        },
        permissions: ['1', '2', '3', '4'],
      },
    ],
  },
  pricelist: {
    ...initialPricelistState,
    pricelistTimeline: {
      items: [{
        eventType: 1,
        id: 13,
        content: 'note content',
        dateTime: '2023-05-15T06:42:56.0076792',
        initiator: 'John Smith',
      }],
      page: 1,
      totalCount: 1,
    },
  },
  stock: initialStockState,
  purchaseOrder: purchaseOrderInitialState,
  partsKit: {
    ...initialPartsKitState,
    partsKitDetails: {
      id: 1,
      name: 'name',
      description: 'test',
      partsKitCode: 'test',
      status: 1,
      isVlbKit: false,
      categoryId: 1,
      manufacturerCode: 'test',
      unitOfMeasure: 1,
      businessAreas: [1],
      itemsCount: 1,
      componentsCount: 1,
      attachmentsCount: 1,
      minPrice: 1,
      maxPrice: 10,
      createdAt: 'test',
      updatedAt: 'test',
      suitableForVehicles: null,
    },
    partsKitTimeline: {
      items: [
        {
          eventType: 1,
          dateTime: '2000-01-01',
          initiator: 'test',
          content: '1',
          id: 1,
        },
      ],
      pages: 1,
      totalCount: 1,
    },
  },
  orders: {
    ...initialOrdersState,
    orderTimeline: {
      items: [{
        orderEventType: 1,
        id: 13,
        content: 'note content',
        dateTime: '2023-05-15T06:42:56.0076792',
        initiator: 'John Smith',
      }],
      page: 1,
      totalCount: 1,
    },
    orderCollections: [
      {
        status: 1,
        filteredTotalCount: 1,
        totalCount: 1,
        pages: 1,
        isHidden: false,
        order: 1,
        list: [
          {
            id: 12,
            orderNumber: 'test',
            customerId: 1,
            customerName: 'test',
            customerAccountNumber: 'test',
            customerType: 1,
            createdDate: 'test',
            modifiedDate: 'test',
            assigneeId: 1,
            assigneeFullName: 'test',
            isAssigneeDeleted: false,
            status: 1,
            cost: 1,
            jobType: 1,
            priorityLevel: 1,
            partRequestLinesCount: 1,
            contactsCount: 1,
            isPartRequestSubmitted: false,
            readyCollectedPartsCount: 0,
            submittedPartsCount: 0,
            completedJobsCount: 1,
            totalJobsCount: 2,
            vehicleStatus: 2,
            statusTransitions: [1],
            warnings: [1],
          },
        ],
      },
    ],
    ordersColumnsPagination: [
      {
        status: 1,
        currentPage: 1,
        pages: 50,
      },
      {
        status: 2,
        currentPage: 1,
        pages: 50,
      },
    ],
  },
  rectifications: {
    ...initialRectificationState,
    rectificationTimeline: {
      items: [{
        rectificationEventType: 1,
        id: 13,
        content: 'note content',
        dateTime: '2023-05-15T06:42:56.0076792',
        initiator: 'John Smith',
      }],
      page: 1,
      totalCount: 1,
    },
  },
  employees: {
    ...initialEmployeeState,
    skillsCategories: [{ value: 100, label: '100' }],
    employeeDetails: {
      images: null,
      employee: {
        id: 123,
        firstName: 'test',
        lastName: 'test',
        title: 'test',
        attachmentCount: 1,
        birthday: '12-12-2000',
        gender: 1,
        nationalInsuranceNumber: null,
        email: null,
        telephone: null,
        mobile: null,
        addressLine1: null,
        addressLine2: null,
        cityTown: null,
        postalCode: null,
        region: null,
        jobTitle: null,
        linkedAccountId: null,
        linkedAccountRole: 1,
        department: 2,
        status: 2,
        createdDate: '12-12-2000',
        modifiedDate: '12-12-2000',
        referenceNumber: 456,
      },
    },
    employeeTimeline: {
      items: [
        {
          eventType: 1,
          dateTime: '12-12-2020',
          initiator: 'string',
          firstName: 'test',
          lastName: 'test',
        },
        {
          eventType: 1,
          dateTime: '12-12-2020',
          initiator: 'string',
          content: 'test',
          id: 12,
        },
      ],
      pages: 1,
      totalCount: 2,
    },
  },
  shared: {
    ...initialSharedState,
    rectificationDetails: {
      rectification: {
        costString: '11111.00',
        id: 72,
        rectificationNumber: 'R23-VLC-M20TT2',
        orderNumber: 'O23-VLC-VYBDP9',
        customerName: 'Best Company LLC',
        customerAccountNo: 'BEST11',
        customerType: 2,
        assigneeId: 8147,
        assigneeFullName: 'John Smith',
        isAssigneeDeleted: false,
        rectificationType: 1,
        status: 1,
        jobType: 1,
        priorityLevel: 2,
        isPartRequestSubmitted: true,
        readyCollectedPartsCount: 2,
        submittedPartsCount: 3,
        completedJobsCount: 4,
        totalJobsCount: 5,
        partRequestLinesCount: 5,
        contactsCount: 4,
        modifiedDate: '2023-01-10T00:00:00',
        createdDate: '2023-01-10T00:00:00',
        statusTransitions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19],
        warnings: [1],
      },
    },
  },
  supplier: {
    ...initialSupplierState,
    supplierDetails: {
      id: 1,
      createdAt: '2022-11-29T14:14:49.4793324',
      updatedAt: '2022-11-29T16:15:23.0156984',
      attachmentsAmount: 0,
      contactsAmount: 0,
      name: 'new name',
      supplierType: 1,
      supplierAccountNumber: 'QWERTY',
      vatNumber: null,
      companyRegistrationNumber: null,
      supplierStatus: 1,
    },
  },
  vehicles: { ...initialVehiclesState },
};

export const headerCreateActions = [
  {
    label: 'Enquiry',
    key: CreateKeyEnum.CREATE_ENQUIRY,
    requires: PermissionEnum.EnquiryCreatePage,
  },
  {
    label: 'Customer',
    key: CreateKeyEnum.CREATE_CUSTOMER,
    requires: PermissionEnum.CustomerCreatePage,
  },
  {
    label: 'User',
    key: CreateKeyEnum.CREATE_USER,
    requires: PermissionEnum.UserCreatePage,
  },
  {
    label: 'Pricelist',
    key: CreateKeyEnum.CREATE_PRICELIST,
    requires: PermissionEnum.PricelistCreatePage,
  },
  {
    label: 'Part',
    key: CreateKeyEnum.CREATE_PART,
    requires: PermissionEnum.StockCreateNewPart,
  },
  {
    label: 'Parts kit',
    key: CreateKeyEnum.CREATE_PARTS_KIT,
    requires: PermissionEnum.StockCreateNewPartsKit,
  },
  {
    label: 'Purchase order',
    key: CreateKeyEnum.CREATE_PURCHASE_ORDER,
    requires: PermissionEnum.StockPurchaseOrdersCreate,
  },
  {
    label: 'Supplier',
    key: CreateKeyEnum.CREATE_SUPPLIER,
    requires: PermissionEnum.SupplierCreatePage,
  },
  {
    label: 'Rectification',
    key: CreateKeyEnum.CREATE_RECTIFICATION,
    requires: PermissionEnum.RectificationCreate,
  },
  {
    label: 'Job',
    key: CreateKeyEnum.CREATE_JOB,
    requires: PermissionEnum.JobCreateNewJob,
  },
  {
    label: 'Employee',
    key: CreateKeyEnum.CREATE_EMPLOYEE,
    requires: PermissionEnum.EmployeeCreatePage,
  },
  {
    label: 'Vehicle',
    key: CreateKeyEnum.CREATE_VEHICLE,
    requires: PermissionEnum.VehicleCreate,
  },
];

export const YesNoOptions = [
  { label: 'Yes', value: 1 },
  { label: 'No', value: 0 },
];
