import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormReset } from 'react-hook-form';
import { AxiosErrorResponse, ResponseSingleResult } from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { pricelistAPI } from '../../../api/pricelistApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { CreatePricelistRequest, PricelistFields } from '../../../pricelists/create-pricelist/utils/PricelistSchema';

export const createPricelistThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }> | null,
{
  data: CreatePricelistRequest,
  navigate: NavigateFunction,
  reset: UseFormReset<PricelistFields>,
}
>(
  'post/Pricelist',
  async ({
    data, navigate, reset,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await pricelistAPI.createPricelist(data);
      reset();
      dispatch(setSuccessMessage({ message: `Pricelist "${data.name}" was successfully created.` }));
      dispatch(setLoading(false));
      navigate(`/${routesPath.PRICELISTS}/${response.data.data.createdId}`);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
