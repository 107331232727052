import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setOrderAttachments, setOrderContacts, setOrderContactsPaging, setOrderLinks, setOrderLinksFilters,
  setOrdersAttachmentsFilter,
  setOrderSummary, setOrderTimeline, setOrderTimelineFilters,
} from '../../../store/slices/ordersSlice';
import {
  infiniteScrollInitPaging, initDataList, initDataListWithIds, initialCoreAttachmentsFilters, initLinksFilters,
} from '../../../core/types/coreTypes';
import { serOrderDetails } from '../../../store/slices/sharedSlice';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const orderViewPageCleanUpHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(serOrderDetails(null));
  dispatch(setOrderAttachments([]));
  dispatch(setOrdersAttachmentsFilter(initialCoreAttachmentsFilters));
  dispatch(setOrderSummary(null));
  dispatch(setOrderLinks(null));
  dispatch(setOrderLinksFilters(initLinksFilters));
  dispatch(setOrderTimeline(initDataList));
  dispatch(setOrderTimelineFilters(initExtendedTimelineFilters));
  dispatch(setOrderContacts(initDataListWithIds));
  dispatch(setOrderContactsPaging(infiniteScrollInitPaging));
};
