import React from 'react';
import { routesPath } from '../../enums/pathEnum';
import { ReactComponent as EnquiryIcon } from '../../../assets/icons/enquiry-icon.svg';
import { ReactComponent as OrdersIcon } from '../../../assets/icons/orders-icon.svg';
import { ReactComponent as RectificationsIcon } from '../../../assets/icons/rectifications-icon.svg';
import { ReactComponent as JobsIcon } from '../../../assets/icons/jobs-icon.svg';
import { ReactComponent as VehiclesIcon } from '../../../assets/icons/vehicles-icon.svg';
import { ReactComponent as StockIcon } from '../../../assets/icons/stock-icon.svg';
import { ReactComponent as PriceListIcon } from '../../../assets/icons/pricelist-icon.svg';
import { ReactComponent as CustomersIcon } from '../../../assets/icons/customers-icon.svg';
import { ReactComponent as SuppliersIcon } from '../../../assets/icons/suppliers-icon.svg';
import { ReactComponent as EmployeesIcon } from '../../../assets/icons/employees-icon.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/icons/reports-icon.svg';
import { ReactComponent as InvoicingIcon } from '../../../assets/icons/invoicing-icon.svg';
import { PermissionEnum } from '../../enums/dictionariesEnums';

export const sidebarData = [
  {
    name: 'Enquiries',
    path: `/${routesPath.ENQUIRIES}`,
    icon: <EnquiryIcon />,
    title: 'Enquiries',
    restrictedTo: PermissionEnum.EnquiryModule,
  },
  {
    name: 'Orders',
    path: `/${routesPath.ORDERS}`,
    icon: <OrdersIcon />,
    title: 'Orders',
    restrictedTo: PermissionEnum.OrderModule,
  },
  {
    name: 'Rectifications',
    path: `/${routesPath.RECTIFICATIONS}`,
    icon: <RectificationsIcon />,
    title: 'Rectifications',
    restrictedTo: PermissionEnum.RectificationModule,
  },
  {
    name: 'Jobs',
    path: `/${routesPath.JOBS}`,
    icon: <JobsIcon />,
    title: 'Jobs',
    restrictedTo: PermissionEnum.JobModule,
  },
  {
    name: 'Vehicles',
    path: `/${routesPath.VEHICLES}`,
    icon: <VehiclesIcon />,
    title: 'Vehicles',
    restrictedTo: PermissionEnum.VehicleModule,
  },
  {
    name: 'Stock',
    path: `/${routesPath.STOCK}`,
    icon: <StockIcon />,
    title: 'Stock',
    restrictedTo: PermissionEnum.StockModule,
  },
  {
    name: 'Pricelists',
    path: `/${routesPath.PRICELISTS}`,
    icon: <PriceListIcon />,
    title: 'Pricelists',
    restrictedTo: PermissionEnum.PricelistsModule,
  },
  {
    name: 'Customers',
    path: `/${routesPath.CUSTOMERS}`,
    icon: <CustomersIcon />,
    title: 'Customers',
    restrictedTo: PermissionEnum.CustomerModule,
  },
  {
    name: 'Suppliers',
    path: `/${routesPath.SUPPLIERS}`,
    icon: <SuppliersIcon />,
    title: 'Suppliers',
    restrictedTo: PermissionEnum.SupplierModule,
  },
  {
    name: 'Invoicing',
    path: `/${routesPath.INVOICING}`,
    icon: <InvoicingIcon />,
    title: 'Invoicing',
    restrictedTo: PermissionEnum.InvoicingModule,
  },
  {
    name: 'Employees',
    path: `/${routesPath.EMPLOYEES}`,
    icon: <EmployeesIcon />,
    title: 'Employees',
    restrictedTo: PermissionEnum.EmployeeModule,
  },
  {
    name: 'Reports',
    path: `/${routesPath.REPORTS}`,
    icon: <ReportsIcon />,
    title: 'Reports',
    restrictedTo: PermissionEnum.ReportModule,
  },
  // {
  //   name: 'Forms',
  //   path: `/${routesPath.FORMS}`,
  //   icon: <FormsIcon />,
  //   title: 'Forms',
  //   restrictedTo: PermissionEnum.EnquiryModule,
  // },
];
