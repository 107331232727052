import React from 'react';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';

type JobCreateConfirmationProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  entityType?: string,
  entityNo?: string | null,
  entityStatus?: string,
  createHandle: VoidFunctionType,
}

const JobCreateConfirmation: React.FC<JobCreateConfirmationProps> = ({
  isVisible, onCancel, entityType, entityNo, entityStatus, createHandle,
}) => {
  const text = `Selected ${entityType} ${entityNo || ''} is currently in "${entityStatus}" status.
                After creating a new job this ${entityType} will be automatically moved into "Job in progress" status.`;
  return (
    <Modal title="Confirmation" visible={isVisible} onCancel={onCancel} destroyOnClose width={700}>
      <p className="jobCreateConfirmation">
        <span>{text}</span>
        <span>Do you want to proceed?</span>
      </p>
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Yes, create job"
        createClick={createHandle}
        cancelClick={onCancel}
      />
    </Modal>
  );
};

export default JobCreateConfirmation;
