import React from 'react';
import RectificationVehicleSection from './RectificationVehicleSection';
import RectificationJobsAndPartsSection from './RectificationJobsAndPartsSection';
import RectificationDetailsSection from './RectificationDetailsSection';
import RectificationActivitySection from './RectificationActivitySection';

const RectificationDetails: React.FC = () => {
  return (
    <div className="info-grid">
      <RectificationVehicleSection />
      <RectificationJobsAndPartsSection />
      <RectificationDetailsSection />
      <RectificationActivitySection />
    </div>
  );
};

export default RectificationDetails;
