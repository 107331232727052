import React from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { SearchTableFilterType } from '../../../core/types/coreTypes';
import { VehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { useAppSelector } from '../../../store/hooks';
import { vehiclesListUniqueStatusesOptionsSelector } from '../../../store/selectors/vehiclesSelectors';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import ThNew from '../../../core/components/table-components/table-new/ThNew';

type LinkVehicleModalColumnsProps = {
  statusesFilter: number[],
  setStatusesFilter: (v: number[]) => void,
  fetchVehicles: (filters: VehiclesDashboardFilters) => void,
  filters: VehiclesDashboardFilters,
  totalCount: number,
  searchVl: SearchTableFilterType<string | undefined>,
  setSearchVl: (v: SearchTableFilterType<string | undefined>) => void,
  filtersHandle: (filters: VehiclesDashboardFilters) => void,
}

const LinkVehicleModalColumns: React.FC<LinkVehicleModalColumnsProps> = ({
  setStatusesFilter, statusesFilter, fetchVehicles, filters,
  totalCount, setSearchVl, searchVl, filtersHandle,
}) => {
  const vehiclesListUniqueStatusesOptions = useAppSelector(vehiclesListUniqueStatusesOptionsSelector);
  const statusesColumnsOptions = vehiclesListUniqueStatusesOptions || [];
  const defaultVehStatuses = vehiclesListUniqueStatusesOptions.map((el) => el.value);
  const columns = [
    {
      title: 'No',
      dataIndex: 'ordinalNumber',
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      sorter: true,
      filterSearch: true,
    },
    {
      title: 'Model description',
      dataIndex: 'modelDescription',
    },
    {
      title: 'Reg No',
      dataIndex: 'regNumber',
    },
    {
      title: 'Chassis No',
      dataIndex: 'chassisNumber',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      filters: statusesColumnsOptions,
    },
  ];

  const filtersList = [
    {
      dataIndex: 'status',
      value: statusesFilter,
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const v = checkedValues.length > 0 ? checkedValues as number[] : undefined;
        setStatusesFilter(v || []);
        fetchVehicles({ ...filters, statuses: v || defaultVehStatuses, page: 1 });
      },
    },
  ];

  const sortHandle = (field: number) => {
    filtersHandle({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    });
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...filters,
        [field]: undefined,
        page: 1,
      };
      filtersHandle(values);
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...filters, [field]: searchVl[field]?.trim(), page: 1 };
    filtersHandle(values);
  };
  return (
    <Tr>
      {columns.map((col) => <ThWithControls
        key={col.dataIndex}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={totalCount}
        disabled={totalCount === 0}
        disabledFilter={!filters.keyword}
        searchVl={searchVl}
        className={`linkVehicleTh--${col.dataIndex}`}
        tableFilters={filters}
        filters={filtersList}
        insideModal
      />)}
      <ThNew className="linkVehicleTh--action"><span className="sr-only">Action</span></ThNew>
    </Tr>
  );
};

export default LinkVehicleModalColumns;
