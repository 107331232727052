import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendar, faCheckCircle, faTimesCircle, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import './EmployeesLeaveRequestItem.scss';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Tooltip } from 'antd';
import { faEllipsisVertical, faPen, faRotateRight } from '@fortawesome/free-solid-svg-icons';
import Tag from '../../../../core/components/tag/Tag';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  leaveRequestsApprovedStatusSelector,
  leaveRequestsPendingApprovalStatusSelector,
  leaveRequestsRejectedStatusSelector,
} from '../../../../store/selectors/coreStatusesSelectors';
import { EmployeeLeaveRequestUi } from '../../types/EmployeesLeaveRequestsTypes';
import { changeEmployeesLeaveRequestStatusThunk } from '../../../../store/thunks/employee/employeesLeaveRequestsThunks';
import { NumberFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';

type EmployeesLeaveRequestItemProps = {
  data: EmployeeLeaveRequestUi,
  getEditedLeaveRequestData: VoidFunctionType,
  setIdToDelete: NumberFunctionType,
}
const EmployeesLeaveRequestItem: React.FC<EmployeesLeaveRequestItemProps> = ({ data, getEditedLeaveRequestData, setIdToDelete }) => {
  const dispatch = useAppDispatch();
  const pendingApprovalStatus = useAppSelector(leaveRequestsPendingApprovalStatusSelector);
  const approvedStatus = useAppSelector(leaveRequestsApprovedStatusSelector);
  const rejectedStatus = useAppSelector(leaveRequestsRejectedStatusSelector);
  const menuItems = data.status === pendingApprovalStatus ? [
    {
      label: 'Approve request',
      key: 'approve-request',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => approvedStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: approvedStatus,
      })),
    },
    {
      label: 'Reject request',
      key: 'reject-request',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => rejectedStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: rejectedStatus,
      })),
    },
    {
      label: 'Edit request',
      key: 'edit-request',
      icon: <FontAwesomeIcon icon={faPen} />,
      onClick: getEditedLeaveRequestData,
    },
    {
      label: 'Delete request',
      key: 'delete-request',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setIdToDelete(data.id),
    },
  ] : [
    {
      label: 'Mark as Pending approval',
      key: 'mark-as-pending-approval',
      icon: <FontAwesomeIcon icon={faRotateRight} />,
      onClick: () => pendingApprovalStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: pendingApprovalStatus,
      })),
    },
  ];

  return (
    <div className="employeesLeaveRequestItem not-link">
      <FontAwesomeIcon icon={faCalendar} className="employeesLeaveRequestItem__icon" />
      <div className="employeesLeaveRequestItem__employee">
        <TruncateTooltip
          value={data.employeeName}
          className="employeesLeaveRequestItem__name"
          overlayClassName="employeesLeaveRequestItem__tooltip"
        />
        <TruncateTooltip
          value={data.employeeJobTitle}
          className="employeesLeaveRequestItem__role"
          overlayClassName="employeesLeaveRequestItem__tooltip"
        />
      </div>
      <div className="employeesLeaveRequestItem__type">
        {data.leaveType}
        {data.comment && <Tooltip title={data.comment} overlayClassName="employeesLeaveRequestItem__tooltip">
          <InfoCircleOutlined className="employeesLeaveRequestItem__info-icon" />
        </Tooltip>}
      </div>
      <ul className="employeesLeaveRequestItem__info">
        <li className="employeesLeaveRequestItem__info-item">
          <span>Leave dates</span>
          <b>{`${data.startDate} - ${data.endDate}`}</b>
        </li>
        <li className="employeesLeaveRequestItem__info-item">
          <span>Leave period</span>
          <b>{data.leavePeriod}</b>
        </li>
      </ul>
      <div className="employeesLeaveRequestItem__tag-wrapper">
        <Tag label={data.statusName} />
      </div>
      <div className="employeesLeaveRequestItem__actions-wrapper">
        <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={menuItems}
        />
      </div>
    </div>
  );
};

export default EmployeesLeaveRequestItem;
