import { DictionaryItem, DictionaryStringValueItem } from '../types/coreTypes';

export const labelHandle = (
  value: string | number | number[] | null | undefined,
  selector: (DictionaryItem | DictionaryStringValueItem)[],
): string | number => {
  if (!value) {
    return '';
  }
  if (typeof value === 'number' || typeof value === 'string') {
    return selector.find((el) => el.value === value)?.label || value;
  } else {
    const arr = value.map((el) => labelHandle(el, selector));
    return arr.join(', ');
  }
};
