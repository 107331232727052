import React from 'react';
import { AccountViewModeParams } from '../../enums/AccountViewEnums';
import AccountDetails from './details/AccountDetails';
import AccountLeaveRequests from './leave-requests/AccountLeaveRequests';

type AccountContentProps = {
  mode: string | null,
  isLinkedToEmployee: boolean,
}
const AccountContent: React.FC<AccountContentProps> = ({ mode, isLinkedToEmployee }) => {
  const renderAccountContent = () => {
    switch (mode) {
      case AccountViewModeParams.ACCOUNT_DETAILS:
        return <AccountDetails />;
      case AccountViewModeParams.LEAVE_REQUESTS:
        return isLinkedToEmployee ? <AccountLeaveRequests /> : null;
      default: return null;
    }
  };

  return <>{renderAccountContent()}</>;
};

export default AccountContent;
