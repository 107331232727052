import React from 'react';
import './CustomersSkeletonItem.scss';

const CustomersSkeletonItem: React.FC = () => {
  return (
    <div className="customersSkeletonItem skeleton-item">
      <div className="customersSkeletonItem__info">
        <div className="customersSkeletonItem__img" />
        <div className="customersSkeletonItem__text" />
      </div>
      <div className="customersSkeletonItem__tag skeleton-line" />
      <div className="customersSkeletonItem__list">
        {Array(4).fill(null).map((_, i) => <div key={i} className="customersSkeletonItem__list-item" />)}
      </div>
    </div>
  );
};

export default CustomersSkeletonItem;
