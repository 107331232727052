import React, { useEffect, useState } from 'react';
import VehicleProfilesActions from './VehicleProfilesActions';
import './VehicleProfiles.scss';
import CreateVehicleProfileModal from './CreateVehicleProfileModal';
import VehicleProfilesList from './VehicleProfilesList';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteVehicleProfileThunk, getVehicleProfiles } from '../../../store/thunks/vehicles/vehiclesProfilesThunk';
import { vehiclesProfilesFiltersSelector, vehiclesProfilesSelector } from '../../../store/selectors/vehiclesSelectors';
import { vehicleProfilesOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { setVehicleProfilesFilters } from '../../../store/slices/vehiclesSlice';

const VehicleProfiles: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(vehiclesProfilesFiltersSelector);
  const { totalCount } = useAppSelector(vehiclesProfilesSelector);
  const vehicleProfilesOrdering = useAppSelector(vehicleProfilesOrderingSelector);
  const [editMode, setEditMode] = useState(false);
  const [isVehicleModal, setVehicleModal] = useState(false);
  const [isDelete, setDeleteModal] = useState(false);
  const [vehicleIdToEdit, setVehicleIdToEdit] = useState<number | undefined>(undefined);

  const closeDeleteModal = () => {
    setDeleteModal(false);
    setVehicleIdToEdit(undefined);
  };

  useEffect(() => {
    if (!filters.order && vehicleProfilesOrdering.length > 0) {
      dispatch(setVehicleProfilesFilters({
        ...filters,
        order: { field: vehicleProfilesOrdering[0].value, isAsc: true },
      }));
    }
    // eslint-disable-next-line
  }, [filters.order, vehicleProfilesOrdering.length]);

  useEffect(() => {
    filters.order && dispatch(getVehicleProfiles({
      filters,
    }));
    // eslint-disable-next-line
  }, [filters]);
  return (
    <>
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={closeDeleteModal}
        confirmHandler={() => vehicleIdToEdit && dispatch(deleteVehicleProfileThunk({
          id: vehicleIdToEdit,
          closeModal: () => closeDeleteModal(),
        }))}
      />
      <CreateVehicleProfileModal
        visible={isVehicleModal}
        onCancel={() => {
          setVehicleModal(false);
          editMode && setEditMode(false);
        }}
        title={editMode ? 'Edit vehicle profile' : 'Create vehicle profile'}
        editMode={editMode}
        vehicleIdToEdit={vehicleIdToEdit}
      />
      <div className="vehicleProfiles">
        <VehicleProfilesActions setVehicleModal={setVehicleModal} filters={filters} totalCount={totalCount} />
        <VehicleProfilesList
          openEditModal={(id: number) => {
            setEditMode(true);
            setVehicleModal(true);
            setVehicleIdToEdit(id);
          }}
          openDeleteModal={(id: number) => {
            setDeleteModal(true);
            setVehicleIdToEdit(id);
          }}
        />
      </div>
    </>
  );
};

export default VehicleProfiles;
