import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DictionaryResponse,
} from '../../../core/types/coreTypes';
import { coreEventTypesAPI } from '../../../api/core/coreEventTypesApi';
import { setLoading } from '../../slices/coreSlice';

export const getEnquiryEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/EnquiryEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchEnquiryEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreEventTypesAPI.fetchRectificationEventTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/OrderEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreEventTypesAPI.fetchOrderEventTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSupplierEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/SupplierEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreEventTypesAPI.fetchSupplierEventTypes();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getPricelistEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PricelistEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreEventTypesAPI.fetchPricelistEventTypes();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getJobEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/JobEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreEventTypesAPI.fetchJobEventTypes();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PartsKitEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreEventTypesAPI.fetchPartsKitEventTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/EmployeeEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreEventTypesAPI.fetchEmployeeEventTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchPurchaseOrderEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartViewEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PartViewEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreEventTypesAPI.fetchPartViewEventTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/CustomerEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchCustomerEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchPartRequestEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchVehicleEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserEntityEventTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/UserEventTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchUserEntityEventTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSoldProductsByCustomerReportEntityTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/SoldProductsByCustomerReportEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreEventTypesAPI.fetchSoldProductsByCustomerReportEntityTypes();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
