import React from 'react';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  fitterReturnsReportDataSelector,
  reportIsVisibleSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import { EntityType } from '../../../../../../common/types/commonTypes';
import ViewPageLink from '../../../../../../common/components/view-page-link/ViewPageLink';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';

const FitterReturnsBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { totalCount, items } = useAppSelector(fitterReturnsReportDataSelector);

  const hiddenKeys = ['customerId', 'entityType', 'entityId', 'partId'];
  const nowrapKeys = ['deletionDate', 'quantity'];
  const truncateKeys = ['category', 'partName', 'deletionInitiator', 'deletionReason'];

  const renderLink = (entity: EntityType, value: string | number | null, id: number) => (
    <ViewPageLink entityType={entity} entityId={id}>
      <TruncateTooltip value={value} table />
    </ViewPageLink>
  );

  const renderCellValue = (
    key: string,
    value: string | number | null,
    item: { customerId: number, entityId: number, entityType: string, partId: number },
  ) => {
    if (key === 'customerName') return renderLink('Customer', value, item.customerId);
    if (key === 'partCode') return renderLink('Part', value, item.partId);
    if (key === 'entityNumber') return renderLink(item.entityType as EntityType, value, item.entityId);
    if (truncateKeys.includes(key)) return <TruncateTooltip value={value} table />;
    return value;
  };

  return (
    <Tbody>
      {totalCount > 0
        ? items.map((el, i) => <Tr key={i}>
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return <TdNew key={key} nowrap={nowrapKeys.includes(key)}>
              {renderCellValue(key, value, el)}
            </TdNew>;
          })}
          <TdNew />
        </Tr>)
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default FitterReturnsBody;
