import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useEmployeeViewActions } from './hooks/useEmployeeViewActions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import EmployeeViewHead from './components/employee-head/EmployeeViewHead';
import './EmployeeViewPageContaner.scss';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import DeleteEmployeeModal from './components/modals/DeleteEmployeeModal';
import EmployeeStaticAreaContainer from './components/static-area/EmployeeStaticAreaContainer';
import EmployeeDynamicAreaContainer from './components/dynamic-area/EmployeeDynamicAreaContainer';
import {
  getEmployeeDepartmentsThunk,
  getEmployeeGendersThunk, getEmployeeSalaryTypesThunk,
  getEmployeeSkillLevelsThunk,
  getEmployeeStatusesThunk,
} from '../../store/thunks/employee/createEmployeeThunks';
import { deleteEmployeeThunk, getEmployeeByIdThunk, patchEmployeeThunk } from '../../store/thunks/employee/employeeViewPageThunk';
import {
  setEmployeeActivityFilters,
  setEmployeeDetails,
  setEmployeeEmployments,
  setEmployeeIceContact,
  setEmployeeImageData,
  setEmployeeSkills, setEmployeesLeaveRequests, setEmployeesLeaveRequestsFilters, setEmployeeTimeline,
} from '../../store/slices/employeesSlice';
import { initDataList, initEntityImageData } from '../../core/types/coreTypes';
import { employeeDetailsSelector } from '../../store/selectors/employeesSelector';
import { useFetchCurrentTabData } from './hooks/useFetchCurrentTabData';
import { routesPath } from '../../core/enums/pathEnum';
import usePermission from '../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { initExtendedTimelineFilters } from '../../common/types/commonTypes';
import { getSkillsOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { initEmployeesLeaveRequestsFilters } from '../leave-request/types/EmployeesLeaveRequestsTypes';

const EmployeeViewPageContainer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const { backHandle } = useLocationHistoryState(true);
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const allowedToDeleteUser = usePermission(PermissionEnum.UserDelete);

  const [isConformationModal, setIsConfirmationModal] = useState(false);
  const [isDeleteEmployeeModal, setIsDeleteEmployeeModal] = useState(false);

  const { fetchCurrentTabData, tab } = useFetchCurrentTabData();

  useEffect(() => {
    dispatch(getEmployeeStatusesThunk());
    dispatch(getEmployeeGendersThunk());
    dispatch(getEmployeeDepartmentsThunk());
    dispatch(getEmployeeSkillLevelsThunk());
    dispatch(getSkillsOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    id && tab && dispatch(getEmployeeByIdThunk({ employeeId: +id }));
    // eslint-disable-next-line
  }, [tab]);

  const handleStatusChange = (status: number) => {
    id && dispatch(patchEmployeeThunk({
      employeeId: +id,
      data: { status },
      onClose: fetchCurrentTabData,
    }));
  };

  const handleDeleteClick = () => {
    if (allowedToDeleteUser) {
      const linkedAccountId = employeeDetails?.employee.linkedAccountId;
      linkedAccountId ? setIsDeleteEmployeeModal(true) : setIsConfirmationModal(true);
    } else {
      setIsConfirmationModal(true);
    }
  };

  const handleDeleteEmployee = (deleteLinkedAccount: boolean) => {
    const linkedAccountId = employeeDetails?.employee.linkedAccountId;
    if (deleteLinkedAccount && linkedAccountId) {
      id && dispatch(deleteEmployeeThunk({
        employeeId: +id,
        deleteLinkedAccount,
        linkedAccountId,
        onClose: () => {
          setIsDeleteEmployeeModal(false);
          navigate(`/${routesPath.EMPLOYEES}`, { replace: true });
        },
      }));
    } else {
      id && dispatch(deleteEmployeeThunk({
        employeeId: +id,
        onClose: () => {
          setIsDeleteEmployeeModal(false);
          navigate(`/${routesPath.EMPLOYEES}`, { replace: true });
        },
      }));
    }
  };

  const {
    actions,
    allowedActions,
    setAllowedActions,
  } = useEmployeeViewActions(handleStatusChange, handleDeleteClick);

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const actionsList = actions.filter((act) => permissions.includes(act.restrictedTo));
      setAllowedActions(actionsList);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, employeeDetails?.employee]);

  useEffect(() => {
    dispatch(getEmployeeSalaryTypesThunk());
    dispatch(getEmployeeDepartmentsThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setEmployeeDetails(null));
      dispatch(setEmployeeImageData(initEntityImageData));
      dispatch(setEmployeeIceContact(null));
      dispatch(setEmployeeSkills(null));
      dispatch(setEmployeeEmployments(null));
      dispatch(setEmployeeActivityFilters(initExtendedTimelineFilters));
      dispatch(setEmployeeTimeline(initDataList));
      dispatch(setEmployeesLeaveRequestsFilters(initEmployeesLeaveRequestsFilters));
      dispatch(setEmployeesLeaveRequests(initDataList));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          goBack={backHandle}
          actions={allowedActions}
          className="employeeViewPageDetailsHead"
        >
          <EmployeeViewHead />
        </DetailsHead>
      }
    >
      <ConfirmationModal
        isVisible={isConformationModal}
        onCancel={() => setIsConfirmationModal(false)}
        confirmHandler={() => id && dispatch(deleteEmployeeThunk({
          employeeId: +id,
          onClose: () => {
            setIsConfirmationModal(false);
            navigate(`/${routesPath.EMPLOYEES}`, { replace: true });
          },
        }))}
      />
      <DeleteEmployeeModal
        visible={isDeleteEmployeeModal}
        onCancel={() => setIsDeleteEmployeeModal(false)}
        handleDeleteEmployee={(deleteLinkedAccount: boolean) => handleDeleteEmployee(deleteLinkedAccount)}
      />
      <div className="employeeViewPage">
        <EmployeeStaticAreaContainer />
        <EmployeeDynamicAreaContainer />
      </div>
    </Layout>
  );
};

export default EmployeeViewPageContainer;
