import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  salesOffsiteWorkingColumnsSelector,
  salesOffsiteWorkingDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import SalesOffsiteWorkingColumns from './SalesOffsiteWorkingColumns';
import SalesOffsiteWorkingBody from './SalesOffsiteWorkingBody';
import './SalesOffsiteWorkingTable.scss';

const SalesOffsiteWorkingTable: React.FC = () => {
  const columns = useAppSelector(salesOffsiteWorkingColumnsSelector);
  const { totalCount } = useAppSelector(salesOffsiteWorkingDataSelector);

  return (
    <TableNew>
      <SalesOffsiteWorkingColumns columns={columns} totalCount={totalCount} />
      <SalesOffsiteWorkingBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default SalesOffsiteWorkingTable;
