import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse, BooleanFunctionType, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { settingsAPI } from '../../../api/settingsApi';
import { PatchUserAccountType, UserAccountDto } from '../../../settings/user-profile/types/userProfileTypes';
import { routesPath } from '../../../core/enums/pathEnum';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { UserEntityTimelineEvent, UserTimelineEvent } from '../../../settings/user-profile/types/activityTypes';
import { RootState } from '../../store';
import {
  setUserEntityTimeline, setUserEntityTimelineFilters,
} from '../../slices/settingsSlice';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';

export const getUserAccountThunk = createAsyncThunk<ResponseSingleResult<{ userAccount: UserAccountDto}> | null,
{ id: number }
>(
  'get/UserAccount',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.getUserAccount(id);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchUserAccountThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, data: Partial<PatchUserAccountType> }
>(
  'patch/UserAccount',
  async ({ id, data }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.patchUserAccount(id, data);
      const { settings: { userEntityTimelineFilters } } = getState() as RootState;
      dispatch(setUserEntityTimelineFilters({ ...userEntityTimelineFilters, page: 1 }));
      dispatch(getUserAccountThunk({ id }));
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteUserAccountThunk = createAsyncThunk<ResponseSingleResult | null,
{
  id: number,
  deleteLinkedAccount?: boolean,
  linkedAccountId?: number,
  onClose: VoidFunctionType,
  navigate: NavigateFunction,
}
>(
  'delete/UserAccount',
  async ({
    id, deleteLinkedAccount, linkedAccountId, onClose, navigate,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await settingsAPI.deleteUserAccount(id);
      if (deleteLinkedAccount && linkedAccountId) {
        await employeeAPI.deleteEmployeeById(linkedAccountId);
        dispatch(setSuccessMessage({ message: 'The employee was successfully deleted.' }));
      }
      onClose();
      dispatch(setFetching(false));
      navigate(`/${routesPath.SETTINGS}`, { replace: true });
      dispatch(setSuccessMessage({ message: 'The user was successfully deleted.' }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const activateAccountThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, userName: string, closeModal: VoidFunctionType }
>(
  'post/ActivateAccount',
  async ({
    id, userName, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.activateUserAccount(id);
      dispatch(setLoading(false));
      dispatch(getUserAccountThunk({ id }));
      closeModal();
      dispatch(setSuccessMessage({ message: `${userName} user account was successfully activated.` }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deactivateAccountThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, userName: string, closeModal: VoidFunctionType }
>(
  'post/DeactivateAccount',
  async ({
    id, userName, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.deactivateUserAccount(id);
      dispatch(setLoading(false));
      dispatch(getUserAccountThunk({ id }));
      dispatch(setSuccessMessage({ message: `${userName} user account was successfully inactivated.` }));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const resetUserPasswordThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, userName: string, closeModal: VoidFunctionType }
>(
  'post/ResetUserPassword',
  async ({
    id, userName, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.resetUsersPassword([id]);
      dispatch(setLoading(false));
      dispatch(getUserAccountThunk({ id }));
      dispatch(setSuccessMessage({ message: `A password reset link was successfully sent to ${userName}.` }));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const inviteUserThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, userName: string, closeModal: VoidFunctionType }
>(
  'post/InviteUser',
  async ({
    id, userName, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.inviteUsers([id]);
      dispatch(setLoading(false));
      dispatch(getUserAccountThunk({ id }));
      dispatch(setSuccessMessage({ message: `An invitation was successfully sent to ${userName}.` }));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserTimelineThunk = createAsyncThunk<ResponseResult<UserTimelineEvent[]>,
{ userId: number, filters: ExtendedTimelineFilters, setIsLoading?: BooleanFunctionType, signal?: AbortSignal }
>(
  'get/UserTimeline',
  async ({
    userId, filters, setIsLoading, signal,
  }, { rejectWithValue }) => {
    setIsLoading && setIsLoading(true);
    try {
      const response = await settingsAPI.fetchUserTimeline(userId, filters, signal);
      setIsLoading && setIsLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserEntityTimelineThunk = createAsyncThunk<ResponseResult<UserEntityTimelineEvent[]>,
{ userId: number, filters: ExtendedTimelineFilters, setIsLoading?: BooleanFunctionType, signal?: AbortSignal }
>(
  'get/UserEntityTimeline',
  async ({
    userId, filters, setIsLoading, signal,
  }, { rejectWithValue }) => {
    setIsLoading && setIsLoading(true);
    try {
      const response = await settingsAPI.fetchUserEntityTimeline(userId, filters, signal);
      setIsLoading && setIsLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postUserActivityNote = createAsyncThunk<ResponseSingleResult<{ createdId: number }> | null,
{ userAccountId: number, content: string, setValue: VoidFunctionType, }>(
  'post/UserActivityNote',
  async ({
    userAccountId, content, setValue,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await settingsAPI.createUserActivityNote(userAccountId, content);
      const { settings: { userEntityTimelineFilters } } = getState() as RootState;
      setValue();
      dispatch(setUserEntityTimelineFilters({ ...userEntityTimelineFilters, page: 1 }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editUserActivityNote = createAsyncThunk<ResponseSingleResult | null,
{ id: number, content: string, closeModal: VoidFunctionType, }>(
  'put/UserActivityNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await settingsAPI.putUserActivityNote(id, content);
      const { settings: { userEntityTimeline } } = getState() as RootState;
      const items = userEntityTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
      dispatch(setUserEntityTimeline({ ...userEntityTimeline, items }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteUserActivityNote = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/UserActivityNote',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await settingsAPI.deleteUserActivityNote(id);
      const { settings: { userEntityTimelineFilters } } = getState() as RootState;
      dispatch(setUserEntityTimelineFilters({ ...userEntityTimelineFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
