import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { CustomerDto } from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import { CreateVehicleRequestData } from '../../../vehicles/create-vehicle/types/createVehicleTypes';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { patchOrderThunk } from '../orders/viewPageOrderThunks';

export const getCustomersForVehicleCreation = createAsyncThunk<
ResponseResult<CustomerDto[]>,
{ keyword?: string }
>(
  'get/CustomersForVehicleCreation',
  async ({ keyword }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomersList({ keyword });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createVehicleThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, modelDescription: string }>,
{
  data: CreateVehicleRequestData,
  navigate: NavigateFunction,
  orderId?: number,
}
>(
  'post/Vehicle',
  async ({
    data, navigate, orderId,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.createVehicle(data);
      const { createdId, modelDescription } = response.data.data;
      if (orderId) {
        await dispatch(patchOrderThunk({ id: orderId, data: { vehicleId: createdId } }));
        navigate(`/${routesPath.ORDERS}/${orderId}`, { replace: true });
      } else {
        navigate(`/${routesPath.VEHICLES}/${createdId}`, { replace: true });
      }
      const message = `Vehicle ${modelDescription} was successfully created.`;
      dispatch(setSuccessMessage({ message, toastId: Math.random() }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
