import React from 'react';

type HiddenJobTypeElementProps = {
  slotsCount: number,
}

const HiddenJobTypeElement: React.FC<HiddenJobTypeElementProps> = ({ slotsCount }) => {
  return (
    <div
      className="transparentGap"
      style={{ gridColumn: `span ${slotsCount}` }}
    />
  );
};

export default HiddenJobTypeElement;
