import React from 'react';
import { jobLinksFiltersSelector } from '../../../../../store/selectors/jobsSelector';
import { useAppSelector } from '../../../../../store/hooks';
import { setJobLinksFilters } from '../../../../../store/slices/jobsSlice';
import LinksColumns from '../../../../../common/components/links-table/LinksColumns';
import { useLinks } from '../../../../../common/hooks/useLinks';

type JobLinksColumnsProps = {
  totalCount: number
}

const JobLinksColumns: React.FC<JobLinksColumnsProps> = ({ totalCount }) => {
  const jobLinksFilters = useAppSelector(jobLinksFiltersSelector);

  const {
    onChangeSearchHandle,
    searchPressHandle,
    searchVl,
    pickerValue,
    setPickerValue,
    pickerHandle,
  } = useLinks(jobLinksFilters, setJobLinksFilters);

  return (
    <LinksColumns
      totalCount={totalCount}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={jobLinksFilters}
    />
  );
};

export default JobLinksColumns;
