import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setPartAttachmentsFilter,
  setPartDetails,
  setPartImageData, setPartViewPageTimeline, setPartViewPageTimelineFilters, setPartViewPartsKitsFilters,
  setPartViewPartsKitsList,
} from '../../../store/slices/stockSlice';
import {
  initDataList, initialCoreAttachmentsFilters, initPaging,
} from '../../../core/types/coreTypes';
import { initPartPurchaseOrdersFilters } from '../types/types';
import { setPartPurchaseOrders, setPartPurchaseOrdersFilters } from '../../../store/slices/purchaseOrderSlice';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { PurchaseOrdersInitDataList } from '../../purchase-orders/dashboard/types/purchaseOrdersTypes';

export const cleanUpPartViewHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setPartDetails(null));
  dispatch(setPartImageData({ uri: null, id: null }));
  dispatch(setPartAttachmentsFilter(initialCoreAttachmentsFilters));
  dispatch(setPartViewPartsKitsList(initDataList));
  dispatch(setPartViewPartsKitsFilters(initPaging));
  dispatch(setPartPurchaseOrdersFilters(initPartPurchaseOrdersFilters));
  dispatch(setPartPurchaseOrders(PurchaseOrdersInitDataList));
  dispatch(setPartViewPageTimeline(initDataList));
  dispatch(setPartViewPageTimelineFilters(initExtendedTimelineFilters));
};
