import React from 'react';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import JobsPartsSectionItem from './JobsPartsSectionItem';
import { useAppSelector } from '../../../../../store/hooks';
import { orderJobPartsStatusesListSelector } from '../../../../../store/selectors/ordersSelector';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import { ReactComponent as JobsIcon } from '../../../../../assets/icons/jobs-icon.svg';

const OrderJobsAndPartsSection: React.FC = () => {
  const { partsReqStatusesUi, jobStatusesUi } = useAppSelector(orderJobPartsStatusesListSelector);

  return (
    <div className="jobs-and-parts-section">
      <section className="info-grid__column jobs-and-parts-section__details">
        <h2 className="info-grid__title">Jobs</h2>
        <div className="jobs-and-parts-section__lines">
          {jobStatusesUi && jobStatusesUi.length > 0
            ? jobStatusesUi.map((j, i) => <JobsPartsSectionItem key={i} label={j.label} count={j.value} />)
            : <EmptyContentList Image={JobsIcon} text="No linked jobs" size="sm" offsetTop={false} />}
        </div>
      </section>
      <section className="info-grid__column jobs-and-parts-section__details">
        <h2 className="info-grid__title">Parts</h2>
        <div className="jobs-and-parts-section__lines">
          {partsReqStatusesUi && partsReqStatusesUi.length > 0
            ? partsReqStatusesUi.map((p, i) => <JobsPartsSectionItem key={i} label={p.label} count={p.value} />)
            : <EmptyContentList icon={faCubes} text="No parts" size="sm" offsetTop={false} />}
        </div>
      </section>
    </div>
  );
};

export default OrderJobsAndPartsSection;
