import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { partRequestEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import { StockPartRequestEventDtoBase } from '../../../../types/StockPartrequestViewTypes';
import { StockPartRequestEventsEnum } from '../../../../enums/StockPartRequestEventsEnum';
import StockPartRequestNoteEvent from './StockPartRequestNoteEvent';
import StockPartRequestFieldUpdateEvent from './StockPartRequestFieldUpdateEvent';
import StockPartRequestDeleteEvent from './StockPartRequestDeleteEvent';
import StockPartRequestLinkPurchaseOrderEvent from './StockPartRequestLinkPurchaseOrderEvent';

type StockPartRequestEventProps = {
  item: StockPartRequestEventDtoBase,
}

const StockPartRequestEvent: React.FC<StockPartRequestEventProps> = ({ item }) => {
  const stockPartRequestEventTypes = useAppSelector(partRequestEventTypesSelector);
  const linkingPurchaseOrderEvent = [
    StockPartRequestEventsEnum.PartRequestLineLinkPurchaseOrder,
    StockPartRequestEventsEnum.PartRequestLineUnlinkPurchaseOrder,
  ];

  const stockPartRequestEventRenderFn = (item: StockPartRequestEventDtoBase) => {
    const event = stockPartRequestEventTypes.find((type) => type.value === item.partRequestEventType)?.label || '';
    switch (true) {
      case event === StockPartRequestEventsEnum.Note:
        return <StockPartRequestNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === StockPartRequestEventsEnum.PartRequestLineFieldUpdateOrAddingValue:
        return <StockPartRequestFieldUpdateEvent
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
          productNumber={item.productNumber}
          partRequestLineNumber={item.partRequestLineNumber}
        />;
      case event === StockPartRequestEventsEnum.PartRequestLineDelete:
        return <StockPartRequestDeleteEvent
          productNumber={item.productNumber}
          partRequestLineNumber={item.partRequestLineNumber}
        />;
      case linkingPurchaseOrderEvent.includes(event as StockPartRequestEventsEnum):
        return <StockPartRequestLinkPurchaseOrderEvent
          event={event as StockPartRequestEventsEnum}
          partRequestLineNumber={item.partRequestLineNumber}
          productNumber={item.productNumber}
          linkedPurchaseOrderNumbers={item.linkedPurchaseOrderNumbers}
          unlinkedPurchaseOrderNumbers={item.unlinkedPurchaseOrderNumbers}
        />;
      default: return null;
    }
  };

  return <>{stockPartRequestEventRenderFn(item)}</>;
};

export default StockPartRequestEvent;
