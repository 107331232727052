import React, { useEffect } from 'react';
import AffectedEntitiesModal from '../../../../../../common/components/affected-entities-modal/AffectedEntitiesModal';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import { initDataList, initSmallPaging, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setPricelistAffectedParts, setPricelistAffectedPartsFilters } from '../../../../../../store/slices/pricelistSlice';
import {
  pricelistAffectedPartsFiltersSelector,
  pricelistAffectedPartsSelector,
} from '../../../../../../store/selectors/pricelistSelectors';
import { getPricelistAffectedPartsThunk } from '../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { isGlobalLoadingSelector, shortBusinessAreasSelector } from '../../../../../../store/selectors/coreSelectors';
import TableLoader from '../../../../../../core/components/table-components/TableLoader';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import './PricelistAffectedPartsModal.scss';

type PricelistAffectedPartsModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
}
const PricelistAffectedPartsModal: React.FC<PricelistAffectedPartsModalProps> = ({
  visible,
  onClose,
}) => {
  const dispatch = useAppDispatch();
  const shortBusinessAreas = useAppSelector(shortBusinessAreasSelector);
  const filters = useAppSelector(pricelistAffectedPartsFiltersSelector);
  const { items, totalCount } = useAppSelector(pricelistAffectedPartsSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);

  const columns = [
    { title: 'Product No', dataIndex: 'productNumber' },
    { title: 'Product name', dataIndex: 'productName' },
    { title: 'Business area', dataIndex: 'businessArea' },
  ];

  useEffect(() => {
    if (visible) {
      dispatch(getPricelistAffectedPartsThunk({ filters }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const handleClose = () => {
    onClose();
    dispatch(setPricelistAffectedPartsFilters(initSmallPaging));
    dispatch(setPricelistAffectedParts(initDataList));
  };
  const handlePagination = (page: number, pageSize: number) => {
    dispatch(setPricelistAffectedPartsFilters({ ...filters, page, pageSize }));
  };

  const text = <>
    Operation cannot be finished, because the following pricelist parts have
    {' '}
    <b>{labelHandle(filters.businessAreas, shortBusinessAreas)}</b>
    {' '}
    business area value(s):
  </>;

  const renderTruncateTooltip = (value: string | number) => {
    return <TruncateTooltip value={value} table />;
  };

  const renderCellValue = (key: string, value: string | number, id: number) => {
    if (key === 'productName') {
      return <PrivateLineLink to={`/${routesPath.STOCK}/part/${id}`} permission={PermissionEnum.StockPartViewPage} fitContent>
        {renderTruncateTooltip(value)}
      </PrivateLineLink>;
    }
    return renderTruncateTooltip(value);
  };

  return (
    <AffectedEntitiesModal
      visible={visible}
      text={text}
      handleClose={handleClose}
      handlePagination={handlePagination}
      page={filters.page}
      pageSize={filters.pageSize}
      totalCount={totalCount}
    >
      <TableNew small>
        <Thead>
          <Tr>
            {columns.map((col) => (
              <ThNew
                key={col.dataIndex}
                className={`pricelistAffectedPartsTh--${col.dataIndex}`}
              >
                {col.title}
              </ThNew>
            ))}
          </Tr>
        </Thead>
        {isLoading
          ? <TableLoader colspan={columns.length} />
          : <Tbody>
            {items.map((el) => (
              <Tr key={el.productNumber}>
                {Object.entries(el).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key}>{renderCellValue(key, value, el.id)}</TdNew>;
                })}
              </Tr>
            ))}
          </Tbody>}

      </TableNew>
    </AffectedEntitiesModal>
  );
};

export default PricelistAffectedPartsModal;
