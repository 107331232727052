export enum VehicleChecksOrderingEnum {
  VehicleCheckType = 'VehicleCheckType',
  CheckDate = 'CheckDate',
}

export enum VehicleCheckTypesEnum {
  Full = 'Full vehicle check',
  Weight = 'Weight check',
  Handover = 'Handover check'
}

export enum FullVehicleCheckDecisionsEnum {
  CheckPassedNoDamage = 'Check passed (no damage)',
  CheckPassedMinorDamageFixingIsNotRequired = 'Check passed (minor damage, fixing is not required)',
  CheckFailedMinorDamageFixingIsRequired = 'Check failed (minor damage, fixing is required)',
  CheckFailedMajorDamageFixingIsRequired = 'Check failed (major damage, fixing is required)',
}

export enum HandoverVehicleCheckDecisionsEnum {
  CheckPassed = 'Check passed',
  CheckFailed = 'Check failed',
}

export enum WeatherConditionsEnum {
  Good = 'Good',
  Average = 'Average',
  Poor = 'Poor',
  VeryPoor = 'Very poor',
}
