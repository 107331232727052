import React from 'react';
import { useSearchParams } from 'react-router-dom';
import './ReportsContent.scss';
import {
  ProductionReportsEnum, PurchasingReportsEnum, SalesReportsEnum, StockReportsEnum,
} from '../../enum/ReportsEnum';
import RectificationsByProductContent from './sales/rectifications-by-product/RectificationsByProductContent';
import RectificationsBySupplierContent from './sales/rectifications-by-supplier/RectificationsBySupplierContent';
import NumberOfConvertedVehiclesContent from './sales/number-of-converted-vehicles/NumberOfConvertedVehiclesContent';
import SalesUnfinishedEntitiesContent from './sales/sales-unfinished-entities/SalesUnfinishedEntitiesContent';
import SalesOfficeEmployeeContent from './sales/sales-office-employee/SalesOfficeEmployeeContent';
import FullStockContent from './stock/FullStockContent';
import FitmentTimeContent from './production/fitment-time/FitmentTimeContent';
import OutstandingOverduePurchaseOrdersContent
  from './purchasing/outstanding-overdue-purchase-orders/OutstandingOverduePurchaseOrdersContent';
import SupplierSpendContent from './purchasing/supplier-spend/SupplierSpendContent';
import FitterReturnsContent from './purchasing/fitter-returns/FitterReturnsContent';
import SoldProductsByCustomerContent from './sales/sold-products-by-customer/SoldProductsByCustomerContent';
import RectificationReportContent from './sales/rectification/RectificationReportContent';
import SalesReportByCustomerContent from './sales/sales-report-by-customer/SalesReportByCustomerContent';
import SalesOffsiteWorkingContent from './sales/sales-offsite-working/SalesOffsiteWorkingContent';
import CancelledLinesContent from './purchasing/cancelled-lines/CancelledLinesContent';

const ReportsContent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const report = searchParams.get('report');
  const renderReportsContent = () => {
    switch (report) {
      case ProductionReportsEnum.FITMENT_TIME_REPORT:
        return <FitmentTimeContent />;
      case PurchasingReportsEnum.CANCELLED_LINES:
        return <CancelledLinesContent />;
      case PurchasingReportsEnum.FITTER_RETURNS:
        return <FitterReturnsContent />;
      case PurchasingReportsEnum.OUTSTANDING_OVERDUE_PURCHASE_ORDERS_REPORT:
        return <OutstandingOverduePurchaseOrdersContent />;
      case PurchasingReportsEnum.SUPPLIER_SPEND:
        return <SupplierSpendContent />;
      case SalesReportsEnum.RECTIFICATIONS_BY_PRODUCT:
        return <RectificationsByProductContent />;
      case SalesReportsEnum.RECTIFICATIONS_BY_SUPPLIER:
        return <RectificationsBySupplierContent />;
      case SalesReportsEnum.NUMBER_OF_CONVERTED_VEHICLES:
        return <NumberOfConvertedVehiclesContent />;
      case SalesReportsEnum.OFF_SITE_WORKING:
        return <SalesOffsiteWorkingContent />;
      case SalesReportsEnum.RECTIFICATION_REPORT:
        return <RectificationReportContent />;
      case SalesReportsEnum.UNFINISHED:
        return <SalesUnfinishedEntitiesContent />;
      case SalesReportsEnum.SALES_OFFICE_EMPLOYEE_REPORT:
        return <SalesOfficeEmployeeContent />;
      case SalesReportsEnum.SALES_REPORT_BY_CUSTOMER:
        return <SalesReportByCustomerContent />;
      case SalesReportsEnum.SOLD_PRODUCT_BY_CUSTOMER:
        return <SoldProductsByCustomerContent />;
      case StockReportsEnum.FULL_STOCK_REPORT:
        return <FullStockContent />;
      default: return null;
    }
  };

  return (
    <div className="reportsContent">
      {renderReportsContent()}
    </div>
  );
};

export default ReportsContent;
