import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import PartsListFilters from './PartsListFilters';
import PurchaseOrderPartsListSummary from './PurchaseOrderPartsListSummary';
import './PurchaseOrderPartsList.scss';
import AddPartToOrderModal from '../modals/add-part-to-order/AddPartToOrderModal';
import SelectPartPricelistModal from '../modals/select-part-pricelist/SelectPartPricelistModal';
import PartsListTable from './PartsListTable';
import RecievedQuantityModal from '../modals/received-qty/RecievedQuantityModal';
import {
  getPurchaseOrderFailReason,
  getPurchaseOrderPartVat,
} from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { selectPricelistAndQtyFiltersSelector } from '../../../../../../store/selectors/stockSelectors';
import { getPartPricesThunk } from '../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import { setSelectPricelistAndQtyFilters } from '../../../../../../store/slices/stockSlice';
import { partPricesDefaultOrderingSelector } from '../../../../../../store/selectors/coreOrderingSelectors';
import { initDataList, initSmallPaging, RangeTableFiltersType } from '../../../../../../core/types/coreTypes';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import { usePurchaseOrderPartsTable } from '../../../hooks/usePurchaseOrderPartsTable';
import { setPartPrices } from '../../../../../../store/slices/pricelistSlice';
import {
  getPurchaseOrderPartsList,
  getPurchaseOrderPartState,
} from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { maxCommonDecimal } from '../../../../../../core/utils/regex';
import {
  purchaseOrderDetailsSelector,
  purchaseOrderPartsListFiltersSelector, purchaseOrderPartsListSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import {
  setPurchaseOrderPartSearch,
  setPurchaseOrderPartSearchFilters,
} from '../../../../../../store/slices/purchaseOrderSlice';
import {
  getPricelistPricesOrdering,
  getPurchaseOrderPartsOrdering,
} from '../../../../../../store/thunks/core/coreOrderingThunks';
import { EnterPriceQtyModes } from '../../../enums/PurchaseOrderViewEnum';
import { purchaseOrderStatusObjSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';
import LinkReservedToOrdersModal from '../modals/link-reserved-to-orders/LinkReservedToOrdersModal';
import { getOrderDictionary, getPurchaseOrderPartAmountInputTypesThunk } from '../../../../../../store/thunks/coreThunk';

const PurchaseOrderPartsList: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const selectPricelistAndQtyFilters = useAppSelector(selectPricelistAndQtyFiltersSelector);
  const filters = useAppSelector(purchaseOrderPartsListFiltersSelector);
  const partPricesDefaultOrdering = useAppSelector(partPricesDefaultOrderingSelector);
  const details = useAppSelector(purchaseOrderDetailsSelector);
  const { purchaseOrderParts, allItemIds } = useAppSelector(purchaseOrderPartsListSelector);
  const { completed } = useAppSelector(purchaseOrderStatusObjSelector);

  const purchaseOrderDetails = details?.purchaseOrder;

  const forbiddenToAddDeleteUsualLines = useMemo(() => {
    const forbiddenStatuses = [completed];
    return forbiddenStatuses.includes(purchaseOrderDetails?.status);
  }, [completed, purchaseOrderDetails?.status]);

  const [enterPriceQtyMode, setEnterPriceQtyMode] = useState<EnterPriceQtyModes | string>('');
  const [editedCustomLine, setEditedCustomLine] = useState<number | undefined>(undefined);
  const [isAdd, setIsAdd] = useState<boolean>(false);
  const [selectedPartId, setSelectedPartId] = useState<number | undefined>(undefined);
  const [isSelectPrice, setIsSelectPrice] = useState<boolean>(false);
  const [isReceived, setIsReceived] = useState<boolean>(false);
  const [isLinkToOrder, setIsLinkToOrder] = useState<boolean>(false);

  const [qtyMinMax, setQtyMinMax] = useState<{ min: number, max: number} | undefined>(undefined);
  const [range, setRange] = useState<RangeTableFiltersType>({
    quantity: filters?.quantity
      ? [filters?.quantity.min.toString(), filters?.quantity.max.toString()]
      : ['0.00', maxCommonDecimal.toString()],
  });

  const allDeletableItemIds = useMemo(() => {
    const deletablePartsIds = purchaseOrderParts.filter(({ canBeDeleted }) => canBeDeleted).map(({ id }) => id);
    return allItemIds.filter((id) => deletablePartsIds.includes(id));
  }, [purchaseOrderParts, allItemIds]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allDeletableItemIds);

  const {
    isDelete,
    setIsDelete,
    itemToChange,
    setItemToChange,
    deleteHandle,
  } = usePurchaseOrderPartsTable(checkedKeys, setQtyMinMax, setRange);

  useEffect(() => {
    id && dispatch(getPurchaseOrderPartsList({
      purchaseOrderId: +id, setQtyMinMax, setRange,
    }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    dispatch(getPurchaseOrderFailReason());
    dispatch(getPurchaseOrderPartsOrdering());
    dispatch(getPricelistPricesOrdering());
    dispatch(getPurchaseOrderPartVat());
    dispatch(getPurchaseOrderPartState());
    dispatch(getOrderDictionary({ filters: { onlyActive: true } }));
    dispatch(getPurchaseOrderPartAmountInputTypesThunk());
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <AddPartToOrderModal
        onCancel={() => {
          setIsAdd(false);
          dispatch(setPurchaseOrderPartSearch(initDataList));
          dispatch(setPurchaseOrderPartSearchFilters({ ...initSmallPaging, isCorePart: [true] }));
          editedCustomLine && setEditedCustomLine(undefined);
        }}
        isVisible={isAdd}
        setIsSelectPrice={(partId: number) => {
          dispatch(getPartPricesThunk({
            pricelistId: undefined,
            partId,
            filters: {
              ...selectPricelistAndQtyFilters,
              priceDate: purchaseOrderDetails?.purchaseOrderDate,
              supplierId: purchaseOrderDetails?.supplierId,
              activeOnly: true,
              order: partPricesDefaultOrdering ? { field: partPricesDefaultOrdering, isAsc: true } : undefined,
            },
            isHasPriceHandle: (v: string) => {
              setSelectedPartId(partId);
              setIsSelectPrice(true);
              setEnterPriceQtyMode(v);
            },
          }));
        }}
        setQtyMinMax={setQtyMinMax}
        setRange={setRange}
        editedCustomLine={editedCustomLine}
        forbiddenToAddUsualLines={forbiddenToAddDeleteUsualLines}
      />
      <SelectPartPricelistModal
        isVisible={isSelectPrice}
        selectedPartId={selectedPartId}
        itemToChange={itemToChange}
        enterPriceQtyMode={enterPriceQtyMode}
        setEnterPriceQtyMode={setEnterPriceQtyMode}
        onCancel={() => {
          setEnterPriceQtyMode('');
          setSelectedPartId(undefined);
          setItemToChange(undefined);
          setIsSelectPrice(false);
          dispatch(setSelectPricelistAndQtyFilters(initSmallPaging));
          dispatch(setPartPrices(initDataList));
          editedCustomLine && setEditedCustomLine(undefined);
        }}
        closeAddPartModal={() => {
          setIsAdd(false);
          dispatch(setPurchaseOrderPartSearch(initDataList));
          dispatch(setPurchaseOrderPartSearchFilters({ ...initSmallPaging, isCorePart: [true] }));
        }}
        setQtyMinMax={setQtyMinMax}
        setRange={setRange}
      />
      <LinkReservedToOrdersModal
        visible={isLinkToOrder}
        itemToChange={itemToChange}
        onCancel={() => setIsLinkToOrder(false)}
      />
      <RecievedQuantityModal
        isVisible={isReceived}
        onCancel={() => {
          setIsReceived(false);
          setItemToChange(undefined);
        }}
        itemToChange={itemToChange}
        setIsLinkToOrder={() => setIsLinkToOrder(true)}
      />
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setIsDelete(false);
          setItemToChange(undefined);
        }}
        confirmHandler={() => id && deleteHandle(+id)}
      />
      <PartsListFilters
        setIsAdd={setIsAdd}
        checkedKeys={checkedKeys}
        setIsDelete={() => setIsDelete(true)}
      />
      <PartsListTable
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        disabledSelectAll={!allDeletableItemIds.length || forbiddenToAddDeleteUsualLines}
        checkAll={checkAllHandle}
        checkRow={checkRowHandle}
        checkedKeys={checkedKeys}
        setIsSelectPrice={setIsSelectPrice}
        setIsReceived={setIsReceived}
        filters={filters}
        setItemToChange={setItemToChange}
        setIsDelete={() => setIsDelete(true)}
        range={range}
        setRange={setRange}
        qtyMinMax={qtyMinMax}
        setQtyMinMax={setQtyMinMax}
        setEditModeForCustomLine={(id: number) => {
          setIsAdd(true);
          setEditedCustomLine(id);
          dispatch(setPurchaseOrderPartSearchFilters({ ...initSmallPaging, isCorePart: undefined }));
        }}
        setEditMode={(id: number, partId: number) => {
          dispatch(getPartPricesThunk({
            pricelistId: undefined,
            partId,
            filters: {
              ...selectPricelistAndQtyFilters,
              priceDate: purchaseOrderDetails?.purchaseOrderDate,
              supplierId: purchaseOrderDetails?.supplierId,
              activeOnly: true,
              order: partPricesDefaultOrdering ? { field: partPricesDefaultOrdering, isAsc: true } : undefined,
            },
            isHasPriceHandle: () => {
              setSelectedPartId(partId);
              setIsSelectPrice(true);
              setItemToChange(id);
            },
          }));
        }}
      />
      <PurchaseOrderPartsListSummary />
    </>
  );
};

export default PurchaseOrderPartsList;
