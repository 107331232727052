export type AddEditInvoiceFields = {
  entityNumber: string | undefined,
  invoiceDate: string,
  dueDate: string,
  xeroRef: string,
}

export const defaultAddInvoiceValues = {
  entityNumber: '',
  invoiceDate: '',
  dueDate: '',
  xeroRef: '',
};
