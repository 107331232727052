import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tbody from '../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tfoot from '../../../../../core/components/table-components/Tfoot';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import Button from '../../../../../core/components/button/Button';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import {
  currentRectificationPartReqSourceSelector,
  currentSourceTypeSelector,
  rectificationDraftPartRequestPartsKitFiltersSelector,
  rectificationDraftPartRequestPartsKitSelector,
  rectificationPartReqDraftLinesFiltersSelector,
  rectificationPartRequestLinesSelector,
  rectificationPartRequestPartsSelector,
} from '../../../../../store/selectors/rectificationsSelector';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import AddDiscountModal from '../../../../../common/components/add-discount-modal/AddDiscountModal';
import { useRectificationPartReqLines } from '../../../hooks/useRectificationPartReqLines';
import { CustomAny, VoidFunctionType } from '../../../../../core/types/coreTypes';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import {
  productSourceDictionarySelector, productSourceTotalInitCopySelector,
  productSourceTotalSelector,
  rectificationDetailsSelector,
} from '../../../../../store/selectors/sharedSelectors';
import { useAddRectificationPartReqLine } from '../../../hooks/useAddRectificationPartReqLine';
import FillInDraftLineModal from '../../../../../common/components/fill-in-draft-line-modal/FillInDraftLineModal';
import {
  setRectificationCurrentPartReqSource,
  setRectificationPartReqDraftLinesFilters,
  setRectificationPartRequestDraftParts,
  setRectificationPartsKitDraftLinesFilters,
  setRectificationPartsKitRequestParts,
} from '../../../../../store/slices/rectificationsSlice';
import RectificationPartReqColumns from './RectificationPartReqColumns';
import PartRequestBodyCells from '../../../../../common/components/common-part-request-components/PartRequestBodyCells';
import MarkAsFreeOfChargeModal
  from '../../../../../common/components/common-part-request-components/MarkAsFreeForChargeModal';
import SelectProductSourceModal
  from '../../../../../common/components/select-product-source-modal/SelectProductSourceModal';
import { PartProductSourceTotalParams, PatchPartReqLineDto, PatchProductSourceType } from '../../../../../common/types/commonTypes';
import {
  addRectificationPartLineThunk,
  bulkDeleteRectificationPartReqLinesThunk,
  deleteRectificationPartReqSingleLineThunk,
  patchBulkRectificationPartReqLines, patchRectificationPartRequestLineThunk,
} from '../../../../../store/thunks/rectification/rectificationViewPageThunks';
import { setErrorMessage } from '../../../../../store/slices/coreSlice';
import { partRequestLineUiHandle } from '../../../../../common/utils/partRequestLineValueRenderer';
import { getPartProductSourceDictionaryThunk, getPartProductSourceTotalThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { setProductSourceDictionary, setProductSourceTotal, setProductSourceTotalInitCopy } from '../../../../../store/slices/sharedSlice';
import ReadyCollectedPartsListModal
  from '../../../../../common/components/ready-collected-parts-list-modal/ReadyCollectedPartsListModal';
import ReasonModal from '../../../../../common/components/reason-modal/ReasonModal';

const RectificationPartRequestTable: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isReadyCollectedModal, setIsReadyCollectedModal] = useState(false);
  const {
    columns, linesArr, footerActions,
    checkAll,
    checkRowHandle,
    isAll,
    isIndeterminate,
    checkedKeys,
    setDiscountModal,
    discountModal,
    partsModal, setPartsModal,
    isLineActionDisabled, isRectificationInReadMode,
    availableInfoSourcesByStatus, isPartsKitLineHasAllocatedChild,
    patchReasonModal,
    setPatchReasonModal,
    dataToPatch,
    setDataToPatch,
    discountReasonModal,
    setDiscountReasonModal,
    deleteReasonModal,
    setDeleteReasonModal,
  } = useRectificationPartReqLines();
  const {
    isCore, isNonCore, isCustom, isPartsKit,
  } = useAppSelector(currentSourceTypeSelector);

  const {
    editedLine, setEditedLine,
    sourceName, setSourceName,
    sourceId, setSourceId,
    prodSourceModal, setProdSourceModal, setProdSourceModalHandle,
    initCustomLine, setInitFiltersAndTableData,
    isRectificationVehManual, isJobTypeSupply,
    fetchParts, fetchPartsKitParts, createPartsKitLineThunk,
    createCustomHandle,
    childLineMode, setChildLineMode,
    editMode, setEditMode,
    isMarkAsFree, setIsMarkAsFree,
    newLineReasonModal,
    setNewLineReasonModal,
    newLineReasonData,
  } = useAddRectificationPartReqLine();

  const { items: rectificationPartRequestLines } = useAppSelector(rectificationPartRequestLinesSelector);
  const lines = partRequestLineUiHandle(rectificationPartRequestLines);
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const id = rectificationDetails?.rectification?.id;
  const partRequestId = rectificationDetails?.rectification?.partRequestId;
  const currentPartReqSource = useAppSelector(currentRectificationPartReqSourceSelector);
  const allowedToManage = usePermission(PermissionEnum.RectificationPartRequestEditPartLineFieldsAndUnlockPartLineActions);

  const draftPartRequestParts = useAppSelector(rectificationPartRequestPartsSelector);
  const draftPartReqPartsFilters = useAppSelector(rectificationPartReqDraftLinesFiltersSelector);
  const draftPartsKits = useAppSelector(rectificationDraftPartRequestPartsKitSelector);
  const draftPartPartsKitsFilters = useAppSelector(rectificationDraftPartRequestPartsKitFiltersSelector);
  const productSourceDictionary = useAppSelector(productSourceDictionarySelector);
  const productSourceTotal = useAppSelector(productSourceTotalSelector);
  const productSourceTotalInitCopy = useAppSelector(productSourceTotalInitCopySelector);

  const [reasonValue, setReasonValue] = useState('');

  const setDiscountHandle = (discountVal: number, closeModal: VoidFunctionType, reason: string) => {
    id && partRequestId && dispatch(patchBulkRectificationPartReqLines({
      partRequestId,
      rectificationId: id,
      data: {
        partRequestLineIds: checkedKeys,
        patchPartRequestLineDto: {
          discount: discountVal,
          reason: reason.trim() || null,
        },
      },
      closeModal,
      openReasonModal: () => setDiscountReasonModal(true),
    }));
  };

  const openDeleteModalHandle = (id: number) => {
    setDeleteReasonModal(true);
    setEditedLine(id);
  };

  const deleteConfirmationHandle = () => {
    if (editedLine) {
      id && dispatch(deleteRectificationPartReqSingleLineThunk({
        rectificationId: id,
        lineId: editedLine,
        closeModal: () => {
          setDeleteReasonModal(false);
          setEditedLine(undefined);
        },
        deletionReason: reasonValue.trim(),
      }));
    } else {
      checkedKeys.length > 0 && id && partRequestId && dispatch(bulkDeleteRectificationPartReqLinesThunk({
        rectificationId: id,
        partRequestId,
        partRequestLineIds: checkedKeys,
        reason: reasonValue.trim(),
        closeModal: () => {
          setDeleteReasonModal(false);
        },
      }));
    }
  };

  const getProductSourceDictionaryHandle = (partId: number, openModal: VoidFunctionType) => {
    rectificationDetails?.rectification.rectificationDate && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: rectificationDetails.rectification.businessArea,
        customerId: rectificationDetails.rectification.customerId,
        priceDate: rectificationDetails.rectification.rectificationDate,
      },
      openModal,
    }));
  };
  const getProductSourceTotalHandle = (partId: number, params: PartProductSourceTotalParams) => {
    rectificationDetails?.rectification.rectificationDate && dispatch(getPartProductSourceTotalThunk({
      partId,
      params: {
        ...params,
        priceDate: rectificationDetails.rectification.rectificationDate,
      },
    }));
  };

  const createPriceSourceHandle = (data: Partial<PatchProductSourceType>, closeModal: VoidFunctionType) => {
    partRequestId && sourceId && dispatch(addRectificationPartLineThunk({
      partRequestId,
      data: {
        partId: sourceId,
        stockQuantity: data.stockQuantity || null,
        pricelists: data.pricelists || null,
        manualInputQuantity: data.manualInputQuantity || null,
        manualInputUnitPrice: data.manualInputUnitPrice || null,
        reason: data.reason?.trim() ? data.reason.trim() : undefined,
      },
      closeModal,
    }));
  };

  const patchLineHandle = useCallback((
    lineId: number,
    data: PatchPartReqLineDto,
    setFieldToEdit?: VoidFunctionType,
    closeModal?: VoidFunctionType,
  ) => {
    partRequestId && dispatch(patchRectificationPartRequestLineThunk({
      partRequestId,
      lineId,
      data: { ...data, reason: data.reason?.trim() || null },
      setFieldToEdit,
      closeModal: () => {
        patchReasonModal && setPatchReasonModal(false);
        closeModal && closeModal();
      },
      openReasonModal: () => {
        setDataToPatch({
          lineId, data, setFieldToEdit, closeModal,
        });
        setPatchReasonModal(true);
      },
    }));
    // eslint-disable-next-line
  }, [dataToPatch, partRequestId, patchReasonModal, setDataToPatch, setPatchReasonModal]);

  const editPriceSourceHandle = (data: CustomAny, closeModal: VoidFunctionType, totalAmount?: number) => {
    const isChildLineQtyCheck = childLineMode
        && totalAmount
        && productSourceTotalInitCopy?.totalQuantity
        && (totalAmount > productSourceTotalInitCopy?.totalQuantity);

    if (isChildLineQtyCheck) {
      // setTimeout is used to prevent notification premature closure
      setTimeout(() => dispatch(setErrorMessage({
        message: `Total quantity for this parts kit component should be equal to ${productSourceTotalInitCopy?.totalQuantity}.`,
        toastId: Math.random(),
      })), 1000);
    } else {
      if (childLineMode) {
        const children = lines.map((line) => line.childLines).flat();
        const exactChild = children?.find((line) => line?.id === editedLine);
        exactChild?.id && patchLineHandle(exactChild?.id, data, undefined, closeModal);
      } else {
        editedLine && patchLineHandle(editedLine, data, undefined, closeModal);
      }
    }
  };

  const cancelProductSourceModal = useCallback(() => {
    setProdSourceModal(false);
    setSourceName('');
    setSourceId(undefined);
    editMode && setEditMode(false);
    editedLine && setEditedLine(undefined);
    childLineMode && setChildLineMode(false);
    dispatch(setProductSourceTotalInitCopy(null));
    dispatch(setProductSourceTotal(null));
    dispatch(setProductSourceDictionary(null));
  // eslint-disable-next-line
  }, [childLineMode, editMode, editedLine, setChildLineMode, setEditMode, setEditedLine, setProdSourceModal, setSourceId, setSourceName]);

  const editedLineData = useMemo(() => {
    const children = rectificationPartRequestLines.map((line) => line.childLines).flat();
    const exactChild = children?.find((line) => line?.id === editedLine);
    const exactParent = rectificationPartRequestLines.find((el) => el.id === editedLine);

    return exactChild || exactParent;
  }, [editedLine, rectificationPartRequestLines]);

  const handleSubmitNewLineWithReason = useCallback(() => {
    const { partsKitId, customLineValues, closeModal } = newLineReasonData || {};
    if (partsKitId && closeModal) {
      createPartsKitLineThunk(partsKitId, closeModal, reasonValue);
    }
    if (customLineValues && closeModal) {
      createCustomHandle(customLineValues, closeModal, reasonValue);
    }
  }, [createCustomHandle, createPartsKitLineThunk, newLineReasonData, reasonValue]);

  const handleSubmitPatchLineWithReason = useCallback(() => {
    if (dataToPatch) {
      const {
        lineId, data, setFieldToEdit, closeModal,
      } = dataToPatch;
      patchLineHandle(lineId, { ...data, reason: reasonValue.trim() }, setFieldToEdit, closeModal);
    }
  }, [dataToPatch, patchLineHandle, reasonValue]);

  return (
    <>
      <AddDiscountModal
        isVisible={discountModal}
        onCancel={() => setDiscountModal(false)}
        setDiscount={(discountVal, closeModal) => setDiscountHandle(discountVal, closeModal, reasonValue)}
        discountReasonModal={discountReasonModal}
        setDiscountReasonModal={setDiscountReasonModal}
        discountReasonValue={reasonValue}
        setDiscountReasonValue={setReasonValue}
      />
      <FillInDraftLineModal
        isVisible={partsModal}
        onCancel={() => {
          setPartsModal(false);
          setEditedLine(undefined);
          editMode && setEditMode(false);
        }}
        title="Fill in rectification line"
        infoSources={availableInfoSourcesByStatus}
        chooseInfoSource={(v) => dispatch(setRectificationCurrentPartReqSource(v))}
        currentInfoSource={currentPartReqSource}
        setInitFiltersAndTableData={setInitFiltersAndTableData}
        disabledSourceBtnLabel="Custom line"
        editMode={editMode}
        editedLine={editedLine}
        isCorePart={isCore}
        isCustom={isCustom}
        isNonCorePart={isNonCore}
        isPartKit={isPartsKit}
        setProdSourceModal={(partId: number, name: string) => setProdSourceModalHandle(partId, name)}
        parts={draftPartRequestParts}
        setParts={(data) => dispatch(setRectificationPartRequestDraftParts(data))}
        filters={draftPartReqPartsFilters}
        setFilters={(values) => dispatch(setRectificationPartReqDraftLinesFilters(values))}
        isSuitableForCheckboxShown={!isRectificationVehManual && isJobTypeSupply}
        fetchParts={(filters, isShow) => fetchParts(filters, isShow)}
        partsKitFilters={draftPartPartsKitsFilters}
        setPartsKitFilters={(filters) => dispatch(setRectificationPartsKitDraftLinesFilters({ ...filters }))}
        partsKitParts={draftPartsKits}
        setPartsKitParts={(list) => dispatch(setRectificationPartsKitRequestParts(list))}
        fetchPartsKitParts={(values, isShow) => fetchPartsKitParts(values, isShow)}
        createPartsKitLineThunk={(partsKitId, closeModal) => createPartsKitLineThunk(partsKitId, closeModal, reasonValue)}
        editCustomHandle={(values, closeModal) => editedLine && patchLineHandle(editedLine, values, closeModal)}
        createCustomHandle={(values, closeModal) => createCustomHandle(values, closeModal, reasonValue)}
        initCustomLine={initCustomLine}
        originName="an rectification"
      />
      <SelectProductSourceModal
        isVisible={prodSourceModal}
        onCancel={() => cancelProductSourceModal()}
        closePartsModal={() => setPartsModal(false)}
        sourceName={sourceName}
        sourceId={sourceId}
        editMode={editMode}
        editedLine={editedLine}
        childLineMode={childLineMode}
        setProductSourceTotal={(v) => dispatch(setProductSourceTotal(v))}
        setProductSourceDictionary={(v) => dispatch(setProductSourceDictionary(v))}
        lines={lines}
        linesParts={draftPartRequestParts.items}
        productSourceDictionary={productSourceDictionary}
        editThunk={(data, closeModal, totalAmount) => editPriceSourceHandle(data, closeModal, totalAmount)}
        createThunk={(data, closeModal) => createPriceSourceHandle(data, closeModal)}
        productSourceTotal={productSourceTotal}
        getProductSourceDictionary={(partId, openModal) => getProductSourceDictionaryHandle(partId, openModal)}
        getProductSourceTotal={(partId, stockQty, manualQty, manualUnitPrice, pricelists) => {
          const params = {
            stockQuantity: stockQty,
            pricelists,
            manualInputQuantity: manualQty,
            manualInputUnitPrice: manualUnitPrice,
          };
          getProductSourceTotalHandle(partId, params);
        }}
        reasonRequired
      />
      <MarkAsFreeOfChargeModal
        visible={isMarkAsFree}
        onCancel={() => {
          setIsMarkAsFree(false);
          setEditedLine(undefined);
        }}
        currentLineId={editedLine}
        partRequestLines={rectificationPartRequestLines}
        entityName="rectification"
        confirmThunk={(data) => {
          const closeHandle = () => {
            setIsMarkAsFree(false);
            setEditedLine(undefined);
          };
          editedLine && patchLineHandle(editedLine, data, undefined, closeHandle);
        }}
      />
      <ReadyCollectedPartsListModal
        visible={isReadyCollectedModal}
        onCancel={() => {
          setIsReadyCollectedModal(false);
          setEditedLine(undefined);
        }}
        editedLineData={editedLineData}
      />
      <ReasonModal
        visible={deleteReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => {
          setEditedLine(undefined);
          setDeleteReasonModal(false);
        }}
        onSubmit={deleteConfirmationHandle}
        type="delete"
      />
      <ReasonModal
        visible={newLineReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => setNewLineReasonModal(false)}
        type="new"
        onSubmit={handleSubmitNewLineWithReason}
      />
      <ReasonModal
        visible={patchReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => {
          setPatchReasonModal(false);
        }}
        onSubmit={handleSubmitPatchLineWithReason}
        type="update"
      />
      <TableNew className="rectificationPartRequestTable table-with-editable-cells">
        <RectificationPartReqColumns
          columns={columns}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          checkAll={checkAll}
          totalElements={linesArr?.length || 0}
          disabled={!linesArr || linesArr.length === 0 || isRectificationInReadMode}
        />
        <Tbody>
          {linesArr?.length === 0
            ? <EmptyTableSection text="No records" colSpan={allowedToManage ? columns.length + 2 : columns.length} />
            : <PartRequestBodyCells
              checkedKeys={checkedKeys}
              checkRows={checkRowHandle}
              columns={columns}
              isLineActionDisabled={isLineActionDisabled}
              isPartsKitLineHasAllocatedChild={isPartsKitLineHasAllocatedChild}
              editQty={(id: number, isChildLine?: boolean) => {
                setProdSourceModal(true);
                setEditMode(true);
                setEditedLine(id);
                isChildLine && setChildLineMode(true);
              }}
              openCustomModal={(num: number) => {
                setEditedLine(num);
                setEditMode(true);
                setPartsModal(true);
              }}
              openDeleteModal={(id: number) => {
                openDeleteModalHandle(id);
              }}
              openMarkAsFree={(id: number) => {
                setEditedLine(id);
                setIsMarkAsFree(true);
              }}
              openReadyCollectedListModal={(id) => {
                setEditedLine(id);
                setIsReadyCollectedModal(true);
              }}
              initLinesList={rectificationPartRequestLines}
              linesArr={linesArr}
              permissionToManageLines={PermissionEnum.RectificationPartRequestEditPartLineFieldsAndUnlockPartLineActions}
              permissionToManageCharge={PermissionEnum.RectificationMarkPartLineAsFreeOfChargeBilled}
              editInlineThunkHandle={(data, lineId, setFieldToEdit) => {
                lineId && patchLineHandle(lineId, data, setFieldToEdit);
              }}
            />}
        </Tbody>
        {allowedToManage && (
          <Tfoot>
            <Tr>
              <TdNew colSpan={columns.length + 1}>
                {footerActions.map((a) => a.isVisible && <Button
                  type="text"
                  key={a.label}
                  label={a.label}
                  icon={a.icon && <FontAwesomeIcon icon={a.icon} />}
                  onClick={a.onClick}
                  disabled={a.disabled || isRectificationInReadMode}
                />)}
              </TdNew>
            </Tr>
          </Tfoot>
        )}
      </TableNew>
    </>
  );
};
export default RectificationPartRequestTable;
