import React from 'react';
import classNames from 'classnames';

type TdNewProps = {
  children?: React.ReactNode,
  action?: boolean,
  className?: string,
  colSpan?: number,
  alignCenter?: boolean,
  lg?: boolean,
  offsetLeft?: boolean,
  nowrap?: boolean,
}

const TdNew: React.FC<TdNewProps> = ({
  children, action, className, colSpan, alignCenter, lg, offsetLeft, nowrap,
}) => {
  return (
    <td
      colSpan={colSpan}
      className={classNames('table-new-td', {
        'table-new-td--action': action,
        'table-new-td--centered': alignCenter,
        'table-new-td--lg': lg,
        'table-new-td--offset-left': offsetLeft,
        'nowrap': nowrap,
      }, className)}
    >
      {children}
    </td>
  );
};

export default TdNew;
