import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import UnfinishedEntitiesBody from './UnfinishedEntitiesBody';
import UnfinishedEntitiesColumns from './UnfinishedEntitiesColumns';
import { unfinishedEntitiesColumnsSelector } from '../../../../../../store/selectors/reportsSelectors';
import { useAppSelector } from '../../../../../../store/hooks';
import './UnfinishedEntitiesTable.scss';

const UnfinishedEntitiesTable: React.FC = () => {
  const columns = useAppSelector(unfinishedEntitiesColumnsSelector);
  return (
    <TableNew>
      <UnfinishedEntitiesColumns columns={columns} />
      <UnfinishedEntitiesBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default UnfinishedEntitiesTable;
