import React from 'react';
import { AffectedEntityLineUi, ICustomColumn } from '../../types/commonTypes';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import TableLoader from '../../../core/components/table-components/TableLoader';
import Tbody from '../../../core/components/table-components/Tbody';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import { partAffectedEntitiesColumns } from './data';
import './AffectedEntitiesWithLinkTable.scss';
import ViewPageLink from '../view-page-link/ViewPageLink';

type AffectedEntitiesWithLinksTableProps = {
  items: AffectedEntityLineUi[],
  isLoading?: boolean,
  columns?: ICustomColumn[],
}

const AffectedEntitiesWithLinkTable: React.FC<AffectedEntitiesWithLinksTableProps> = ({
  items,
  isLoading,
  columns,
}) => {
  const renderCellContent = (key: string, value: string | number, entity: AffectedEntityLineUi) => {
    const { entityId, entityType } = entity;
    if (key === 'entityType') return entityType === 'Stock purchase order' ? 'Purchase order' : entityType;
    if (key === 'entityNumber' && typeof value === 'string') {
      return (
        <ViewPageLink entityType={entityType} entityId={entityId}>
          <TruncateTooltip value={value} table />
        </ViewPageLink>);
    }
    return <TruncateTooltip value={value} table />;
  };

  const columnsList = columns || partAffectedEntitiesColumns;

  return (
    <TableNew small>
      <Thead>
        <Tr>
          {columnsList.map((col) => (
            <ThNew
              key={col.dataIndex}
              className={`affectedEntitiesTh--${col.dataIndex}`}
            >
              {col.title}
            </ThNew>))}
        </Tr>
      </Thead>
      {isLoading
        ? <TableLoader colspan={columnsList.length} />
        : <Tbody>
          {items.map((el, i) => (
            <Tr key={i}>
              {Object.entries(el).map(([key, value]) => {
                if (key === 'entityId') return null;
                return <TdNew key={key}>{renderCellContent(key, value, el)}</TdNew>;
              })}
            </Tr>))}
        </Tbody>}
    </TableNew>
  );
};

export default AffectedEntitiesWithLinkTable;
