import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import {
  maxCommonDecimal, maxCommonWhole, maxReorderLevelsDecimal, maxReorderLevelsWhole,
} from '../../../../../core/utils/regex';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { integerQuantityMeasureValuesSelector } from '../../../../../store/selectors/coreSelectors';
import RegularInputNumber from '../../../../../core/components/input-number/RegularInputNumber';
import './ReorderLevelsModal.scss';
import { stockPartDetailsSelector } from '../../../../../store/selectors/stockSelectors';
import { putPartReorderLevels } from '../../../../../store/thunks/stock/part/partViewPageThunks';

type ReorderLevelsModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const ReorderLevelsModal:React.FC<ReorderLevelsModalProps> = ({ visible, onCancel }) => {
  const dispatch = useAppDispatch();
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const partDetails = useAppSelector(stockPartDetailsSelector);
  const measure = partDetails?.part.unitOfMeasure;
  const isIntegerMeasure = integerMeasures.includes(measure);
  const {
    control, handleSubmit, reset, watch, setValue, trigger, clearErrors,
  } = useForm<{ warningLevel: string, criticalLevel: string }>({
    defaultValues: {
      warningLevel: partDetails?.part.warningReorderLevel?.toString(),
      criticalLevel: partDetails?.part.criticalReorderLevel?.toString(),
    },
    mode: 'all',
  });
  const warning = watch('warningLevel');
  const critical = watch('criticalLevel');
  const { errors } = useFormState({ control });

  useEffect(() => {
    trigger();
    // eslint-disable-next-line
  }, [warning, critical]);

  const cancelHandle = () => {
    reset();
    onCancel();
  };

  useEffect(() => {
    if (partDetails?.part) {
      setValue('warningLevel', partDetails.part.warningReorderLevel?.toString() || '');
      setValue('criticalLevel', partDetails.part.criticalReorderLevel?.toString() || '');
    }
    // eslint-disable-next-line
  }, [partDetails?.part]);

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line
  }, [visible]);

  const reorderHandle = (values: { warningLevel: string, criticalLevel: string }) => {
    const { warningLevel, criticalLevel } = values;
    partDetails && dispatch(putPartReorderLevels({
      id: partDetails.part.id,
      data: {
        warningLevel: +warningLevel || null,
        criticalLevel: +criticalLevel || null,
      },
    }));
    onCancel();
  };

  // eslint-disable-next-line max-len
  const limitationMessage = `Entered value should be within 0 - ${isIntegerMeasure ? maxReorderLevelsWhole : maxReorderLevelsDecimal} range`;

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Set reorder levels"
      width={700}
      className="reorderLevelsModal"
      destroyOnClose
    >
      <form onSubmit={handleSubmit(reorderHandle)} className="reorderLevelsModal__form">
        <p className="reorderLevelsModal__text">This form allows to set warning and critical reorder levels for a free part quantity.</p>
        <p className="reorderLevelsModal__text">
          Corresponding users from Management and Production departments will be notified,
          if a free part quantity is less than or equal to reorder level values.
        </p>
        <Controller
          control={control}
          name="warningLevel"
          rules={{
            min: {
              value: +critical ? +critical + 0.01 : 0,
              message: 'Entered value should be greater than critical level',
            },
            max: {
              value: isIntegerMeasure ? maxReorderLevelsWhole : maxReorderLevelsDecimal,
              message: limitationMessage,
            },
          }}
          render={({ field }) => (
            <div className="reorderLevelsModal__input-wrapper">
              {isIntegerMeasure
                ? <RegularInputNumber
                  label="Warning level"
                  value={field.value}
                  onChange={field.onChange}
                  max={maxCommonWhole}
                  error={errors && errors.warningLevel?.message}
                />
                : <DecimalInputNumber
                  label="Warning level"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value.replace(',', '.'))}
                  max={maxCommonDecimal}
                  error={errors && errors.warningLevel?.message}
                />}
            </div>
          )}
        />
        <Controller
          control={control}
          name="criticalLevel"
          rules={{
            min: {
              value: 0,
              message: limitationMessage,
            },
            max: {
              value: +warning ? +warning - 0.01 : isIntegerMeasure ? maxReorderLevelsWhole : maxReorderLevelsDecimal,
              message: +warning
                ? 'Entered value should be less than warning level'
                : limitationMessage,
            },
          }}
          render={({ field }) => (
            <div className="reorderLevelsModal__input-wrapper">
              {isIntegerMeasure
                ? <RegularInputNumber
                  label="Critical level"
                  value={field.value}
                  onChange={field.onChange}
                  max={maxCommonWhole}
                  error={errors && errors.criticalLevel?.message}
                />
                : <DecimalInputNumber
                  label="Critical level"
                  value={field.value}
                  onChange={(e) => field.onChange(e.target.value.replace(',', '.'))}
                  max={maxCommonDecimal}
                  error={errors && errors.criticalLevel?.message}
                />}
            </div>
          )}
        />
        <ButtonActions
          cancelLabel="Cancel"
          createType="submit"
          createLabel="Save"
          cancelClick={cancelHandle}
          className="reorderLevelsModal__buttons"
        />
      </form>
    </Modal>
  );
};

export default ReorderLevelsModal;
