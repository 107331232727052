import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum OrdersViewsEnum {
  DASHBOARD = 'Dashboard',
}

export enum OrdersPageParamsEnum {
  DASHBOARD = 'dashboard',
}

export const OrdersPageParamsList = [OrdersPageParamsEnum.DASHBOARD];
export const OrdersPageParamsAndPermissions = [
  { page: OrdersPageParamsEnum.DASHBOARD, permission: PermissionEnum.OrderDashboard },
];
