import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EnquiryFiltersType } from '../types/enquiryTypes';
import { setFilters } from '../../../store/slices/enquiriesSlice';
import { getEnquiriesNew } from '../../../store/thunks/enquiry/enquiryThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  enquiriesFiltersSelector,
  enquiryDashFiltersListSelector,
  permittedEnqDashStatusesSelector,
} from '../../../store/selectors/enquiriesSelector';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import { infiniteScrollInitPaging } from '../../../core/types/coreTypes';
import { EnquiryModesEnum } from '../enums/dashboardViewsEnum';

export const useEnquiryFiltersHandle = () => {
  const dispatch = useAppDispatch();
  const [params, setSearchParams] = useSearchParams();
  const keywordSearchParam = params.get('keyword');
  const stateFilters = useAppSelector(enquiriesFiltersSelector);
  const filtersList = useAppSelector(enquiryDashFiltersListSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const permittedEnqDashStatuses = useAppSelector(permittedEnqDashStatusesSelector);

  const { controller, cancelRequest } = useCancelRequest();

  const initDropdownSearch = {
    priority: '',
    assignedTo: '',
    jobType: '',
    customerType: '',
    warnings: '',
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [keywordValue, setKeywordValue] = useState<string | undefined>(stateFilters.keyword);

  const getFilteredEnquiry = (filters: EnquiryFiltersType) => {
    cancelRequest();
    dispatch(setFilters({ ...filters, page: 1 }));
    currentBusinessArea && dispatch(getEnquiriesNew({
      filters: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: permittedEnqDashStatuses.map((st) => st.value),
        page: 1,
      },
      signal: controller.current?.signal,
    }));
  };

  const setInitSearchParams = () => {
    setSearchParams({ page: params.get('page') || EnquiryModesEnum.DASHBOARD });
  };

  const onClear = () => {
    getFilteredEnquiry({ ...infiniteScrollInitPaging });
    setKeywordValue('');
    if (keywordSearchParam) {
      setInitSearchParams();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    initDropdownSearch,
    filtersList,
    keywordSearchParam,
    setInitSearchParams,
    getFilteredEnquiry,
    onClear,
    stateFilters,
    currentBusinessArea,
  };
};
