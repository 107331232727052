import React, { useState } from 'react';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import './DuplicateVehicle.scss';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import RegularInputNumber from '../../../../../../core/components/input-number/RegularInputNumber';
import { ErrorsEnum } from '../../../../../../core/enums/errorsEnum';
import Modal from '../../../../../../core/components/modal/Modal';
import { maxDiscountWhole } from '../../../../../../core/utils/regex';
import { useAppSelector } from '../../../../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../../../../store/selectors/coreSelectors';

type DuplicateVehicleProps = {
  isDuplicateModal: boolean,
  onCancel: VoidFunctionType;
  value: string;
  setValue: (v: string) => void;
  isCopy: boolean;
  setCopy: BooleanFunctionType;
  duplicateHandle: VoidFunctionType;
};

const DuplicateVehicle: React.FC<DuplicateVehicleProps> = ({
  isDuplicateModal, onCancel, value, setValue, isCopy, setCopy, duplicateHandle,
}) => {
  const [error, setError] = useState<string>('');
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  return (
    <Modal
      width={700}
      visible={isDuplicateModal}
      onCancel={onCancel}
      title="Duplicate vehicle quote"
      destroyOnClose
    >
      <div className="duplicateVehicle">
        <span className="duplicateVehicle__text">This form allows to create additional quotes using the current one as an example.</span>
        <span className="duplicateVehicle__text">Please enter the number of required additional vehicle quotes:</span>
        <RegularInputNumber
          className="duplicateVehicle__input"
          value={value}
          onChange={(e) => {
            const v = e.target.value;
            setValue(v);
          }}
          max={maxDiscountWhole}
          error={error}
        />
        <Checkbox
          checked={isCopy}
          onChange={(e) => setCopy(e)}
          label="Copy quote lines"
          className="duplicateVehicle__checkbox"
        />
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Create quotes"
          cancelClick={() => {
            onCancel();
            error && setError('');
          }}
          disabledCreate={!value || +value === 0}
          createClick={() => {
            if (!value) {
              setError(ErrorsEnum.REQUIRED);
            } else {
              !error && duplicateHandle();
            }
          }}
          isLoading={isGlobalLoading}
        />
      </div>
    </Modal>
  );
};

export default DuplicateVehicle;
