import React from 'react';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLocation, useNavigate } from 'react-router-dom';
import Pagination from '../../../../core/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { partsKitsFiltersSelector, partsKitsListSelector } from '../../../../store/selectors/partsKitSelectors';
import { setPartsKitsFilters } from '../../../../store/slices/partsKitSlice';
import Restricted from '../../../../permissions-handling/Restricted';
import Button from '../../../../core/components/button/Button';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import { routesPath } from '../../../../core/enums/pathEnum';

const PartsKitsListActions: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const partsKitsFilters = useAppSelector(partsKitsFiltersSelector);
  const partsKitsList = useAppSelector(partsKitsListSelector);

  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Restricted to={PermissionEnum.StockCreateNewPartsKit}>
          <Button
            label="Create new parts kit"
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            onClick={() => {
              setLocationHistoryHandle({
                pathname: location.pathname,
                search: location.search,
              });
              navigate(`/${routesPath.CREATE_PARTS_KIT}`, { replace: true });
            }}
            designType="dark"
            reversed
          />
        </Restricted>
      </div>
      <Pagination
        currentPage={partsKitsFilters.page}
        pageSize={partsKitsFilters.pageSize}
        totalItems={partsKitsList.totalCount}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => dispatch(setPartsKitsFilters({ ...partsKitsFilters, page, pageSize }))}
        className="actions-and-paging__paging"
        narrow
      />
    </div>
  );
};

export default PartsKitsListActions;
