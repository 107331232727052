import React from 'react';
import { StringFunctionType } from '../../../core/types/coreTypes';
import EditableCell from '../editable-cell/EditableCell';
import { useAppSelector } from '../../../store/hooks';
import { PartRequestLineDto } from '../../types/commonTypes';
import { partCategoriesLookupSelector } from '../../../store/selectors/sharedSelectors';

type PartReqEditableCellContentProps = {
  initValue: string | number;
  fieldToEdit?: string;
  lineId: number;
  setFieldToEdit: StringFunctionType,
  rules: {
    notes: { maxLength: number, message: string },
    weight: { message: string, max: number },
    quantity: { message: string, max: number },
    discount: { message: string, max: number },
  },
  errors: { notes: string, weight: string, quantity: string, discount: string },
  setErrors: (value: { notes: string, weight: string, quantity: string, discount: string }) => void,
  ref: React.MutableRefObject<null>,
  allowedToManage?: boolean,
  initLinesList: PartRequestLineDto[],
  editThunkHandle: (data: {[p: string]: string | number} | {notes: string}) => void,
}

const PartReqEditableCellContent: React.FC<PartReqEditableCellContentProps> = ({
  initValue,
  fieldToEdit,
  lineId,
  setErrors,
  errors,
  setFieldToEdit,
  rules,
  ref,
  allowedToManage,
  initLinesList,
  editThunkHandle,
}) => {
  const currentLine = initLinesList.find((l) => l.id === lineId);
  const categories = useAppSelector(partCategoriesLookupSelector);
  return (
    <EditableCell
      initValue={initValue}
      fieldToEdit={fieldToEdit}
      setFieldToEdit={setFieldToEdit}
      errors={errors}
      setErrors={setErrors}
      rules={rules}
      ref={ref}
      allowedToManage={allowedToManage}
      currentLine={currentLine}
      categoryColKey="categoryId"
      categoryEntityKey="categoryId"
      categoriesOptions={categories || []}
      editThunkHandle={(obj) => editThunkHandle(obj)}
      forbiddenKeys={['categoryId', 'tax']}
    />
  );
};

export default PartReqEditableCellContent;
