import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import { Spin } from 'antd';
import { useOnClickOutside } from '../../../../../../core/hooks/useOutsideClick';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import Input from '../../../../../../core/components/input/Input';
import { patchPurchaseOrderPartThunk } from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { useAppDispatch } from '../../../../../../store/hooks';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';

type EditableNoteCellProps = {
  allowedToManage?: boolean,
  value: string | number | null,
  partId: number,
  notes: string | null,
  onPatchComplete?: NumberFunctionType,
}

const EditableNoteCell: React.FC<EditableNoteCellProps> = ({
  allowedToManage, value, partId, notes, onPatchComplete,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [editedNote, setEditedNote] = useState<number | undefined>(undefined);
  const [inputNotesV, setInputNotesV] = useState<string>('');
  const [error, setError] = useState<string>('');
  const changeHandle = (v: string | number) => setInputNotesV(v as string);
  const editRow = (val: string) => {
    id && dispatch(patchPurchaseOrderPartThunk({
      purchaseOrderId: +id,
      partId,
      values: { notes: val.trim() },
      closeModal: () => setEditedNote(undefined),
      onPatchComplete: onPatchComplete ? () => onPatchComplete(+id) : undefined,
      setIsLoading,
    }));
  };
  const ref = useRef(null);
  useOnClickOutside(ref, () => {
    setEditedNote(undefined);
  });

  useEffect(() => {
    if (editedNote) {
      setInputNotesV((value as string) || '');
    }
    // eslint-disable-next-line
  }, [editedNote]);

  const renderNoteCell = () => {
    if (editedNote && editedNote === partId) {
      return <Spin spinning={isLoading}>
        <Input
          fieldRef={ref}
          value={inputNotesV}
          autoFocus
          disabled={!allowedToManage}
          onChange={(e) => {
            const val = e.target.value;
            if (val.length > 100) {
              setError(maxLengthMessageHandle(100));
            } else {
              error && setError('');
            }
            changeHandle(e.target.value);
          }}
          onBlur={() => {
            if (!error) {
              if (inputNotesV !== (notes || '')) {
                editRow(inputNotesV);
              } else {
                changeHandle('');
                setEditedNote(undefined);
              }
            } else {
              setError('');
              setEditedNote(undefined);
            }
          }}
          className={classNames('table-input', { 'errorTableInput': error })}
        />
      </Spin>;
    } else {
      return <span
        className={classNames('editSection', { 'editSection--active': allowedToManage })}
        onClick={() => allowedToManage && setEditedNote(partId)}
      >
        <TruncateTooltip value={value || '-'} table />
      </span>;
    }
  };
  return (
    <div>{renderNoteCell()}</div>
  );
};

export default EditableNoteCell;
