import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment/moment';
import { zodResolver } from '@hookform/resolvers/zod';
import { BooleanFunctionType } from '../../../../../../core/types/coreTypes';
import Textarea from '../../../../../../core/components/textarea/Textarea';
import Input from '../../../../../../core/components/input/Input';
import DatePickerComponent from '../../../../../../core/components/date-picker/DatePicker';
import RequisiteSection from '../../../../../../common/components/requisite/RequisiteSection';
import AddressField from '../../../../../../common/components/address/AddressField';
import {
  purchaseOrderAddressFieldsSelector,
  purchaseOrderDetailsSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { clearOtherFieldsErrorFn } from '../../../../../../core/utils/clearOtherFieldsErrorFn';
import {
  compareValuesHandle,
} from '../../../../../../core/utils/getDifferenceBetweenObjects';
import { patchPurchaseOrderByIdThunk } from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { utcDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { AddressFields } from '../../../../../../common/types/commonTypes';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';
import { PatchPurchaseOrderRestModel } from '../../../types/purchaseOrderViewPageTypes';
import { purchaseOrderStatusObjSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import {
  Purchase,
  purchaseDetailsDefaults,
  PurchaseDetailsSchema,
} from '../../../utils/PurchaseDetailsSchema';

type PurchaseDetailsProps = {
  setRequisiteModal: BooleanFunctionType,
  setLocationModal: BooleanFunctionType,
  setAddressFields: React.Dispatch<React.SetStateAction<AddressFields | null>>,
}

const PurchaseDetails: React.FC<PurchaseDetailsProps> = ({ setRequisiteModal, setLocationModal, setAddressFields }) => {
  const allowedToEdit = usePermission(PermissionEnum.StockPurchaseOrdersEditFields);
  const dispatch = useAppDispatch();
  const details = useAppSelector(purchaseOrderDetailsSelector);
  const purchaseOrderAddressFields = useAppSelector(purchaseOrderAddressFieldsSelector);
  const {
    sentToSupplier, partiallyReceived, received, completed,
  } = useAppSelector(purchaseOrderStatusObjSelector);

  const { purchaseOrder: purchaseOrderDetails, billingRequisite = null } = details || {};

  const notEditStatuses = [completed];
  const notEditWithSentStatuses = [completed, received, sentToSupplier, partiallyReceived];
  const {
    control, setValue, clearErrors, watch,
  } = useForm<Purchase>({
    resolver: zodResolver(PurchaseDetailsSchema),
    defaultValues: purchaseDetailsDefaults,
    mode: 'all',
  });
  const purchaseOrderDateWatch = watch('purchaseOrderDate');
  const { errors } = useFormState({ control });
  const fieldsToValidate = ['description', 'purchaseOrderDate', 'deliverTo', 'billingRequisite', 'dueDate', 'fao', 'specialInstructions'];
  const clearOtherErrorsFn = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, purchaseOrderDetails, setValue);
  };

  const setEntityValuesHandle = () => {
    setValue('description', purchaseOrderDetails?.description || null);
    setValue('purchaseOrderDate', purchaseOrderDetails?.purchaseOrderDate || '');
    setValue('fao', purchaseOrderDetails?.fao || null);
    setValue('specialInstructions', purchaseOrderDetails?.specialInstructions || null);
    setValue('dueDate', purchaseOrderDetails?.dueDate || '');
  };

  const setInitFormValues = () => {
    setEntityValuesHandle();
    setAddressFields(purchaseOrderAddressFields);
  };

  const patchHandle = (id: number, data: PatchPurchaseOrderRestModel) => {
    dispatch(patchPurchaseOrderByIdThunk({
      id,
      data: { ...data },
      setInitFormValues,
    }));
  };
  const editField = (
    data: PatchPurchaseOrderRestModel,
    dataKey: keyof PatchPurchaseOrderRestModel,
    isOptional?: boolean,
  ) => {
    if (purchaseOrderDetails) {
      const handler = () => {
        patchHandle(purchaseOrderDetails.id, data);
      };
      compareValuesHandle<PatchPurchaseOrderRestModel>(data, dataKey, purchaseOrderDetails, handler, false, isOptional);
    }
  };

  useEffect(() => {
    if (purchaseOrderDetails) {
      setEntityValuesHandle();
    }
    // eslint-disable-next-line
  }, [purchaseOrderDetails]);

  return (
    <section className="info-grid__column">
      <h2 className="info-grid__title">Details</h2>
      <form className="details-form">
        <Controller
          control={control}
          name="description"
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsFn('description');
              }}
              error={errors.description?.message}
              onBlur={() => {
                !errors.description && editField({ description: field.value?.trim() || null }, 'description', true);
              }}
              disabled={notEditStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
              label="Description"
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="purchaseOrderDate"
          render={({ field }) => (
            <DatePickerComponent
              value={field.value ? moment(field.value) : undefined}
              onChange={(v) => {
                const formatted = moment(v).format(DEFAULT_DATE_FORMAT);
                field.onChange(formatted);
                clearOtherErrorsFn('purchaseOrderDate');
                const finalVl = utcDateFormatHandler('', formatted);
                purchaseOrderDetails && !errors.purchaseOrderDate && patchHandle(purchaseOrderDetails.id, { date: finalVl });
              }}
              disabledDate={(currentDate) => {
                const tooEarly = moment(currentDate).add(+1, 'days') <= moment(purchaseOrderDetails?.createdDate);
                const tooLate = currentDate > moment();
                return tooEarly || tooLate;
              }}
              disabled={notEditWithSentStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
              format={DEFAULT_UI_DATE_FORMAT}
              label="Purchase order date"
              className="details-form__field"
            />
          )}
        />
        <Controller
          control={control}
          name="dueDate"
          render={({ field }) => (
            <DatePickerComponent
              value={field.value ? moment(field.value) : undefined}
              onChange={(v) => {
                const formatted = v ? moment(v).format(DEFAULT_DATE_FORMAT) : '';
                field.onChange(formatted);
                clearOtherErrorsFn('dueDate');
                const finalVl = formatted ? utcDateFormatHandler('', formatted) : '';
                const finalObj = finalVl
                  ? { dueDate: finalVl }
                  : { dueDate: null, dueDateIsEmpty: true };
                purchaseOrderDetails && patchHandle(purchaseOrderDetails.id, finalObj);
              }}
              disabledDate={(currentDate) => {
                const tooEarly = moment(currentDate) < moment(purchaseOrderDateWatch);
                const tooLate = currentDate > moment().add(+6, 'months');
                return tooEarly || tooLate;
              }}
              disabled={notEditWithSentStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
              format={DEFAULT_UI_DATE_FORMAT}
              label="Due date"
              className="details-form__field"
              allowClear
            />
          )}
        />
        <Input
          value={purchaseOrderDetails?.totalGross || '0.00'}
          disabled
          label="Total cost"
          className="details-form__field"
        />
        <Controller
          control={control}
          name="fao"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsFn('fao');
              }}
              onBlur={(e) => {
                const val = e.target.value;
                !errors.fao && editField({ fao: val.trim() }, 'fao', true);
              }}
              disabled={notEditStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
              label="FAO"
              className="details-form__field"
              error={errors.fao?.message}
            />
          )}
        />
        <AddressField
          addressFields={purchaseOrderAddressFields || null}
          setAddressModal={() => {
            setLocationModal(true);
            clearOtherErrorsFn('deliverTo');
          }}
          title="Deliver to"
          disabled={notEditStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
        />
        <RequisiteSection
          requisiteDetails={billingRequisite}
          setIsVisible={() => {
            setRequisiteModal(true);
            clearOtherErrorsFn('billingDetails');
          }}
          disabled={notEditStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
          title="Billing details"
          className="details-form__field"
        />
        <Controller
          control={control}
          name="specialInstructions"
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsFn('specialInstructions');
              }}
              error={errors.specialInstructions?.message}
              onBlur={(e) => {
                const val = e.target.value;
                !errors.specialInstructions && editField({ specialInstructions: val.trim() }, 'specialInstructions', true);
              }}
              disabled={notEditStatuses.includes(purchaseOrderDetails?.status) || !allowedToEdit}
              label="Special instructions"
              className="details-form__field"
            />
          )}
        />
      </form>
    </section>
  );
};

export default PurchaseDetails;
