import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import OrdersFilters from './components/orders-filters/OrdersFilters';
import OrdersCardsColumns from './components/orders-cards-columns/OrdersCardsColumns';
import { OrdersPageParamsEnum, OrdersPageParamsList } from './enums/OrdersViewsEnum';
import { OrderColumnType } from './types/ordersTypes';
import ColumnsConfigurationModal from '../../common/components/columns-configuration-modal/ColumnsConfigurationModal';
import {
  putColumnVisibilityThunk,
} from '../../store/thunks/orders/dashOrdersThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { orderWarningsSelector } from '../../store/selectors/coreSelectors';
import { DictionaryItem } from '../../core/types/coreTypes';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import {
  ordersDashFiltersSelector,
  sortedOrderCollectionsSelector, xeroFieldsOrderStatuses,
} from '../../store/selectors/ordersSelector';
import { setDashFilters, setOrderCollections, setOrderColumnsPagination } from '../../store/slices/ordersSlice';
import { orderStatusSelector } from '../../store/selectors/coreStatusesSelectors';
import { getOrderWarningsThunk } from '../../store/thunks/coreThunk';

const OrdersContainer: React.FC = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const keywordParam = params.get('keyword');
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector(ordersDashFiltersSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const orderStatus = useAppSelector(orderStatusSelector);
  const { completed } = useAppSelector(xeroFieldsOrderStatuses);
  const orderCollections = useAppSelector(sortedOrderCollectionsSelector);
  const orderWarnings = useAppSelector(orderWarningsSelector);

  const [isEyeModal, setIsEyeModal] = useState<boolean>(false);
  const [cards, setCards] = useState<OrderColumnType | null>(null);

  useEffect(() => {
    if (keywordParam && !stateFilters.keyword) {
      dispatch(setDashFilters({ ...stateFilters, keyword: keywordParam }));
    }
    // eslint-disable-next-line
  }, [keywordParam, stateFilters]);

  const setModeParam = (mode: string) => {
    if (OrdersPageParamsList.includes(mode as OrdersPageParamsEnum)) {
      params.append('page', mode);
    } else {
      params.delete('page');
    }
    navigate({ search: `?page=${mode}` }, { replace: true });
  };

  const statusesHandle = useMemo(() => {
    const permissions = currentUser?.userContextRole.permissions;
    let statuses: DictionaryItem[] = orderStatus;
    if (!permissions?.includes(PermissionEnum.OrderShowOrdersInCompletedStatus)) {
      statuses = statuses.filter((st) => st.value !== completed);
    }
    return statuses;
  }, [currentUser, orderStatus, completed]);

  useEffect(() => {
    setCards(orderCollections);
  }, [orderCollections]);

  useEffect(() => {
    if (!orderWarnings.length) {
      dispatch(getOrderWarningsThunk());
    }

    return () => {
      dispatch(setOrderColumnsPagination([]));
      dispatch(setOrderCollections(null));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout scrollableMainArea>
      <ColumnsConfigurationModal
        isVisible={isEyeModal}
        setIsVisible={setIsEyeModal}
        statusOptions={statusesHandle}
        collections={orderCollections}
        saveHandle={(layoutOptions) => dispatch(putColumnVisibilityThunk({
          data: layoutOptions,
          closeModal: () => setIsEyeModal(false),
        }))}
      />
      <OrdersFilters
        pageParam={params.get('page')}
        setPageParam={setModeParam}
        setIsEyeModal={setIsEyeModal}
      />
      <OrdersCardsColumns
        setCards={setCards}
        cards={cards}
      />
    </Layout>
  );
};

export default OrdersContainer;
