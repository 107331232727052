import React from 'react';
import { AttachmentsColumnsProps } from '../../../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../../../store/hooks';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../../permissions-handling/permissionHook';
import { employeeAttachmentsFilterSelector } from '../../../../../../../store/selectors/employeesSelector';
import { setEmployeeAttachmentsFilter } from '../../../../../../../store/slices/employeesSlice';
import AttachmentColumns from '../../../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../../../core/hooks/useCoreAttachmentsColumns';

const EmployeeAttachmentsColumns:React.FC<AttachmentsColumnsProps> = ({
  isAll,
  checkAll,
  totalElements,
  indeterminate,
}) => {
  const allowedManage = usePermission(PermissionEnum.EmployeeEditFields);
  const attachmentsFilters = useAppSelector(employeeAttachmentsFilterSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachmentsFilters, setEmployeeAttachmentsFilter);

  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      totalElements={totalElements}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={attachmentsFilters}
    />
  );
};

export default EmployeeAttachmentsColumns;
