import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../core/components/button/Button';
import { NumberFunctionType } from '../../../../core/types/coreTypes';

type WorkCalendarLinePaginationProps = {
  onPageChange: NumberFunctionType,
  currentPage: number,
  totalPages: number,
}

const WorkCalendarLinePagination: React.FC<WorkCalendarLinePaginationProps> = ({ onPageChange, currentPage, totalPages }) => {
  return (
    <div className="workCalendarLine__col workCalendarLine__pagination col-fixed-left">
      <Button
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => onPageChange(-1)}
        disabled={currentPage === 1}
      />
      <span>{`${currentPage} of ${totalPages}`}</span>
      <Button
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        onClick={() => onPageChange(+1)}
        disabled={currentPage >= totalPages}
      />
    </div>
  );
};

export default WorkCalendarLinePagination;
