import React from 'react';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import PurchaseOrderItem from './PurchaseOrderItem';
import { PurchaseOrderDataListType } from '../../types/purchaseOrdersTypes';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import { useAppSelector } from '../../../../../store/hooks';
import { isDashboardLoadingSelector } from '../../../../../store/selectors/coreSelectors';
import SkeletonLinesList from '../../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import PurchaseOrdersSkeletonItem from '../purchase-skeleton/PurchaseOrdersSkeletonItem';

type PurchaseOrdersListProps = {
  purchaseOrders: PurchaseOrderDataListType,
}
const PurchaseOrdersList: React.FC<PurchaseOrdersListProps> = ({ purchaseOrders }) => {
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <PurchaseOrdersSkeletonItem />
        </SkeletonLinesList>
        : purchaseOrders.totalCount === 0
          ? <EmptyContentList text="No purchase orders" icon={faCartPlus} />
          : <ul className="lines-list">
            {purchaseOrders.items.map((item, i) => (
              <li key={i} className="lines-list__item">
                <PurchaseOrderItem item={item} />
              </li>))}
          </ul>}
      {}
    </>
  );
};

export default PurchaseOrdersList;
