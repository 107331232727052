import React from 'react';

type JobsPartsSectionItemProps = {
  label: string,
  count: number,
}

const JobsPartsSectionItem: React.FC<JobsPartsSectionItemProps> = ({ label, count }) => {
  return (
    <p className="jobs-and-parts-section__line">
      <span>{label}</span>
      <span className="jobs-and-parts-section__line-value">{count}</span>
    </p>
  );
};

export default JobsPartsSectionItem;
