import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  allowedShortBaForUserSelector,
  pricelistTypesSelector, shortBusinessAreasSelector,
} from './coreSelectors';
import { PricelistPartsOrders } from '../../pricelists/pricelist-view-page/enums/PricelistModeEnum';
import { PricesAvailablePartsOrdering } from '../../pricelists/pricelist-view-page/enums/PricesAvailablePartsOrdering';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { suitableVehiclesProfilesSelector } from './sharedSelectors';
import {
  enquiryStatusSelector,
  orderStatusSelector, partRequestStatusesUiSelector,
  pricelistStatusesSelector, purchaseOrderStatusOriginSelector,
  rectificationStatusesSelector,
} from './coreStatusesSelectors';
import { pricelistPartsAvailableOrderingSelector, pricelistPartsOrderingSelector } from './coreOrderingSelectors';
import { labelHandle } from '../../core/utils/labelHandle';
import { AffectedEntityLineUi, EntityType } from '../../common/types/commonTypes';
import { DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';

export const pricelistFiltersSelector = (state: RootState) => state.pricelist.pricelistFilters;
export const pricelistFiltersListSelector = createSelector(
  pricelistFiltersSelector,
  pricelistStatusesSelector,
  allowedShortBaForUserSelector,
  pricelistTypesSelector,
  (
    pricelistFilters,
    pricelistStatuses,
    shortBusinessAreas,
    pricelistTypes,
  ) => {
    return [
      {
        label: 'Type',
        name: 'types',
        value: pricelistFilters.types || [],
        options: pricelistTypes,
      },
      {
        label: 'Business areas',
        name: 'businessAreas',
        value: pricelistFilters.businessAreas || [],
        options: shortBusinessAreas,
      },
      {
        label: 'Status',
        name: 'statuses',
        value: pricelistFilters.statuses || [],
        options: pricelistStatuses,
      },
    ];
  },
);

export const pricelistsSelector = (state: RootState) => state.pricelist.pricelists;
export const pricelistDetailsSelector = (state: RootState) => state.pricelist.pricelistDetails;
export const pricelistAffectedFiltersSelector = (state: RootState) => state.pricelist.pricelistAffectedFilters;
const pricelistAffectedItemsSelector = (state: RootState) => state.pricelist.pricelistAffectedItems;
export const pricelistAffectedCellsDataSelector = createSelector(
  shortBusinessAreasSelector,
  enquiryStatusSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
  partRequestStatusesUiSelector,
  purchaseOrderStatusOriginSelector,
  pricelistAffectedItemsSelector,
  (
    ba,
    enqStatuses,
    orderStatuses,
    rectificationStatuses,
    partRequestStatuses,
    purchaseOrderStatuses,
    data,
  ) => {
    const { items, totalCount, pages } = data;
    const getStatusLabel = (type: EntityType, value: number | null) => {
      switch (type) {
        case 'Enquiry': return labelHandle(value, enqStatuses);
        case 'Order': return labelHandle(value, orderStatuses);
        case 'Rectification': return labelHandle(value, rectificationStatuses);
        case 'Part request': return labelHandle(value, partRequestStatuses);
        case 'Stock purchase order': return labelHandle(value, purchaseOrderStatuses);
        default: return value;
      }
    };
    const entities: AffectedEntityLineUi[] = items.map((el) => ({
      entityId: el.id,
      entityType: el.entityType || '-',
      entityNumber: el.entityNumber || '-',
      createdAt: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.createdAt),
      entityStatus: getStatusLabel(el.entityType, el.entityStatus)?.toString() || '-',
      businessArea: labelHandle(el.businessArea, ba).toString() || '-',
      partLinesCount: el.partLinesCount,
    }));

    return { items: entities, totalCount, pages };
  },
);

export const pricelistAffectedPartsFiltersSelector = (state: RootState) => state.pricelist.pricelistAffectedPartsFilters;

const pricelistAffectedPartsInitSelector = (state: RootState) => state.pricelist.pricelistAffectedParts;

export const pricelistAffectedPartsSelector = createSelector(
  shortBusinessAreasSelector,
  pricelistAffectedPartsInitSelector,
  (ba, data) => {
    const { items, totalCount, pages } = data;
    return {
      pages,
      totalCount,
      items: items.map((el) => ({
        id: el.id,
        productNumber: el.partCode || '-',
        productName: el.name || '-',
        businessArea: labelHandle(el.businessAreas, ba).toString(),
      })),
    };
  },
);

const initSubCategoriesSelector = (state: RootState) => state.pricelist.initSubCategories;
const initSubCategoriesLookupSelector = createSelector(initSubCategoriesSelector, (items) => {
  return items?.map((el) => ({ label: el.name || '-', value: el.id }));
});
export const stockSubcategoriesSelector = (state: RootState) => state.pricelist.stockSubcategories;
export const stockSubcategoriesLookupSelector = createSelector(stockSubcategoriesSelector, (items) => {
  return items.map((el) => ({ label: el.name || '-', value: el.id }));
});

const initCategoriesSelector = (state: RootState) => state.pricelist.initCategories;
export const initCategoriesLookupSelector = createSelector(initCategoriesSelector, (items) => {
  return items?.map((el) => ({ label: el.name || '-', value: el.id }));
});
export const stockCategoriesSelector = (state: RootState) => state.pricelist.stockCategories;
export const stockCategoriesLookupSelector = createSelector(stockCategoriesSelector, (items) => {
  return items.map((el) => ({ label: el.name || '-', value: el.id }));
});

export const pricesFiltersSelector = (state: RootState) => state.pricelist.pricesFilters;

export const pricelistPartsSelector = (state: RootState) => state.pricelist.pricelistParts;
export const pricelistPartsForTableSelector = createSelector(
  initCategoriesLookupSelector,
  initSubCategoriesLookupSelector,
  pricelistPartsSelector,
  (categories, subCategories, data) => {
    const items = data.items.map((item) => ({
      ...item,
      categoryId: categories?.find((el) => el.value === item.categoryId)?.label || item.categoryId,
      subcategoryId: subCategories?.find((el) => el.value === item.subcategoryId)?.label || item.subcategoryId,
      costPrice: item.costPrice ? `£ ${item.costPrice}` : '£ 0.00',
      sellPrice: item.sellPrice ? `£ ${item.sellPrice}` : '£ 0.00',
      modifiedDate: item.modifiedDate ? localDateFormatHandler('DD/MM/YYYY HH:mm', item.modifiedDate) : '-',
    }));
    return { ...data, items };
  },
);

export const stockPartsSelector = (state: RootState) => state.pricelist.stockParts;
export const addPartFiltersSelector = (state: RootState) => state.pricelist.addPartFilters;
export const addPartFiltersListSelector = createSelector(
  addPartFiltersSelector,
  pricelistDetailsSelector,
  allowedShortBaForUserSelector,
  stockCategoriesLookupSelector,
  stockSubcategoriesLookupSelector,
  suitableVehiclesProfilesSelector,
  (filters, details, shortBa, stockCategories, stockSubcategories, suitableVehicles) => {
    return [
      {
        label: 'Category',
        name: 'categoryIds',
        value: filters.categoryIds || [],
        options: stockCategories,
      },
      {
        label: 'Subcategory',
        name: 'subcategoryIds',
        value: filters.subcategoryIds || [],
        options: stockSubcategories,
      },
      {
        label: 'Business area',
        name: 'businessAreas',
        value: filters.businessAreas || [],
        options: shortBa.filter((ba) => details?.businessAreas?.includes(ba.value)),
      },
      {
        label: 'Core part',
        name: 'isCorePart',
        value: filters.isCorePart || [],
        options: [
          { label: 'Yes', value: 'true' },
          { label: 'No', value: 'false' },
        ],
      },
      {
        label: 'Suitable for vehicles',
        name: 'vehicleIds',
        value: filters.vehicleIds || [],
        options: suitableVehicles.items,
      },
    ];
  },
);

export const partPricesSelector = (state: RootState) => state.pricelist.partPrices;
export const partPriceTableSelector = createSelector(partPricesSelector, (items) => {
  const newItems = items.items.map((el) => ({
    id: el.id,
    costPrice: el.costPrice,
    sellPrice: el.sellPrice,
    effectiveStartDate: el.effectiveStartDate,
    effectiveEndDate: el.effectiveEndDate,
  }));
  return { items: newItems, totalCount: items.totalCount, pages: items.pages };
});

export const pricelistAttachmentsSelector = (state: RootState) => state.pricelist.pricelistAttachments;
export const pricelistAttachmentsFiltersSelector = (state: RootState) => state.pricelist.pricelistAttachmentsFilters;
export const pricelistSuppliersSelector = (state: RootState) => state.pricelist.pricelistSuppliers;

export const addPricePartsColumnsSelector = createSelector(pricelistPartsAvailableOrderingSelector, (ordering) => {
  return [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sortName: 'PartCode',
      sorter: true,
      orderField: getSortIndex(PricesAvailablePartsOrdering.PartCode, ordering),
      filterSearch: true,
    },
    {
      title: 'Manufacturer code',
      dataIndex: 'manufacturerCode',
      sortName: 'ManufacturerCode',
      sorter: true,
      orderField: getSortIndex(PricesAvailablePartsOrdering.ManufacturerCode, ordering),
      filterSearch: true,
    },
    {
      title: 'Part name',
      dataIndex: 'name',
      sortName: 'Name',
      sorter: true,
      orderField: getSortIndex(PricesAvailablePartsOrdering.Name, ordering),
      filterSearch: true,
    },
    {
      title: 'Description',
      dataIndex: 'description',
      sortName: 'Description',
      sorter: true,
      orderField: getSortIndex(PricesAvailablePartsOrdering.Description, ordering),
      filterSearch: true,
    },
  ];
});

export const pricesTabColumnsSelector = createSelector(
  stockCategoriesLookupSelector,
  stockSubcategoriesLookupSelector,
  pricelistPartsOrderingSelector,
  (categories, subCategories, ordering) => {
    return [
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.Category, ordering),
        filters: categories,
      },
      {
        title: 'Subcategory',
        dataIndex: 'subcategoryId',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.SubCategory, ordering),
        filters: subCategories,
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.ProductNumber, ordering),
        filterSearch: true,
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.ProductName, ordering),
        filterSearch: true,
      },
      {
        title: 'Cost price',
        dataIndex: 'costPrice',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.CostPrice, ordering),
        isFilterRange: true,
      },
      {
        title: 'Selling price',
        dataIndex: 'sellPrice',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.SellPrice, ordering),
        isFilterRange: true,
      },
      {
        title: 'Modified date',
        dataIndex: 'modifiedDate',
        sorter: true,
        orderField: getSortIndex(PricelistPartsOrders.ModifiedDate, ordering),
        isDateRange: true,
      },
    ];
  },
);

export const pricelistTimelineSelector = (state: RootState) => state.pricelist.pricelistTimeline;
export const pricelistTimelineFiltersSelector = (state: RootState) => state.pricelist.pricelistTimelineFilters;
