import { faCubes, faLink, faTools } from '@fortawesome/free-solid-svg-icons';
import { RectificationDto } from '../types/rectificationsTypes';
import { RectificationTypeEnum } from '../enums/rectificationEnums';

export const reformRectificationCardData = (card: RectificationDto, statusNew: number | undefined) => {
  return {
    id: card.id,
    entityNumber: card.rectificationNumber,
    customerName: card.customerName,
    customerType: card.customerType,
    jobType: card.jobType,
    priorityLevel: card.priorityLevel,
    cost: card.costString,
    assigneeFullName: card.assigneeFullName,
    isAssigneeDeleted: card.isAssigneeDeleted,
    warnings: card.warnings,
    isPartRequestSubmitted: card.isPartRequestSubmitted,
    readyCollectedPartsCount: 0,
    submittedPartsCount: 0,
    completedJobsCount: card.completedJobsCount,
    totalJobsCount: card.totalJobsCount,
    isFreeRectification: !!(card.rectificationType && card.rectificationType === RectificationTypeEnum.FREE),
    dueDate: card.dueDate,
    linkedEntities: [
      {
        label: 'Linked to',
        icon: faLink,
        value: card.linkedNumber || '-',
      },
      {
        label: 'Parts',
        icon: faCubes,
        value: card.isPartRequestSubmitted
          ? null
          : 'Not submitted',
        current: card.collectedNotForStockPartsCount,
        max: card.partRequestLinesCount,
      },
      {
        label: 'Jobs',
        icon: faTools,
        value: null,
        current: card.completedJobsCount,
        max: card.totalJobsCount,
      },
    ],
    showDraftVehicleWarning: card.status !== statusNew,
  };
};
