import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import Tbody from '../../../../core/components/table-components/Tbody';
import { NominalCodeTableLine } from '../../types/nominalCodesTypes';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../core/components/table-components/Tr';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { NumberFunctionType } from '../../../../core/types/coreTypes';

type NominalCodesBodyProps = {
  data: NominalCodeTableLine[],
  columnsLength: number,
  allowedToManage: boolean | undefined,
  setIdToEdit: NumberFunctionType,
  setIdToDelete: NumberFunctionType,
}
const NominalCodesBody: React.FC<NominalCodesBodyProps> = ({
  data, columnsLength, allowedToManage, setIdToEdit, setIdToDelete,
}) => {
  const hiddenKeys: Array<keyof NominalCodeTableLine> = ['id', 'typeId'];

  return (
    <Tbody>
      {data.length > 0
        ? data.map((el) => <Tr key={el.id}>
          <TdNew>
            {el.code}
          </TdNew>
          {/* TODO MDA: according to spec, there should be possibility to disable dropdown, currently BE is not ready */}
          {allowedToManage && (
            <TdNew action>
              <Dropdown
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                menuItems={[
                  {
                    label: 'Edit nominal code',
                    onClick: () => setIdToEdit(el.id),
                    key: 'edit',
                  },
                  {
                    label: 'Delete nominal code',
                    onClick: () => setIdToDelete(el.id),
                    key: 'delete',
                  },
                ]}
              />
            </TdNew>
          )}
          {Object.entries(el).slice(1).map(([key, value]) => {
            if (hiddenKeys.includes(key as keyof NominalCodeTableLine)) return null;
            return <TdNew key={key}>
              {key === 'description'
                ? <TruncateTooltip value={value} table />
                : value}
            </TdNew>;
          })}
        </Tr>)
        : <EmptyTableSection text="No data" colSpan={allowedToManage ? columnsLength + 1 : columnsLength} />}
    </Tbody>
  );
};

export default NominalCodesBody;
