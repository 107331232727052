import { Link } from 'react-router-dom';
import React from 'react';
import { ICustomColumn, PartRequestLineDto } from '../types/commonTypes';

export const partRequestLineValueRenderer = (key: string, value: string | number, columns: ICustomColumn[]) => {
  const found = columns.find((col) => col.dataIndex === key);
  if (found?.prefix) return `${found?.prefix} ${value}`;

  if (found?.suffix) return `${value} ${found?.suffix}`;

  if (found?.isLink) return <Link to={`${found.isLink}/${value}`}>{value}</Link>;

  else return value;
};

export const partRequestLineUiHandle = (lines: PartRequestLineDto[]) => {
  return lines.map((l) => ({
    id: l.id,
    name: l.productName || '',
    unitOfMeasure: l.unitOfMeasure,
    partId: l.partId,
    stockQuantity: l.stockQuantity,
    manualInputQuantity: l.manualInputQuantity,
    manualInputUnitPrice: l.manualInputUnitPrice,
    pricelists: l.pricelists,
    childLines: l.childLines && l.childLines.length > 0
      ? l.childLines?.map((l) => ({
        id: l.id,
        name: l.productName || '',
        unitOfMeasure: l.unitOfMeasure,
        partId: l.partsKitComponentPartId,
        stockQuantity: l.stockQuantity,
        manualInputQuantity: l.manualInputQuantity,
        manualInputUnitPrice: l.manualInputUnitPrice,
        pricelists: l.pricelists,
        childLines: [],
      }))
      : [],
  }));
};
