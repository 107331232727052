import React, { useEffect, useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useParams } from 'react-router-dom';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  BooleanFunctionType, NumberFunctionType,
  RangeTableFiltersType,
  SearchTableFilterType, VoidFunctionType, NumericRangeType,
} from '../../../../../../core/types/coreTypes';
import { maxCommonDecimal } from '../../../../../../core/utils/regex';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import {
  purchaseOrderPartsColumnsSelector, purchaseOrderPartsListFiltersSelector,
  purchaseOrderPartsTableListSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import PurchaseOrderPartsListBody from './PurchaseOrderPartsListBody';
import { PurchaseOrderPartsListFiltersType } from '../../../types/purchaseOrderViewPageTypes';
import { setPurchaseOrderPartsListFilters } from '../../../../../../store/slices/purchaseOrderSlice';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { getPurchaseOrderPartsList } from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';

type PartsListTableProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  disabledSelectAll: boolean,
  checkAll: VoidFunctionType,
  checkRow: NumberFunctionType,
  checkedKeys: number[],
  setIsSelectPrice: BooleanFunctionType,
  setIsReceived: BooleanFunctionType,
  filters?: PurchaseOrderPartsListFiltersType,
  setItemToChange: (value?: number) => void,
  setIsDelete: VoidFunctionType,
  setEditMode: (id: number, partId: number) => void,
  qtyMinMax?: NumericRangeType,
  setQtyMinMax: (v?: NumericRangeType) => void,
  range: RangeTableFiltersType,
  setRange: (v: RangeTableFiltersType) => void,
  setEditModeForCustomLine: NumberFunctionType,
}

const PartsListTable: React.FC<PartsListTableProps> = ({
  isAll, isIndeterminate, disabledSelectAll, checkAll, checkRow, setIsSelectPrice, setIsReceived, filters, checkedKeys,
  setItemToChange, setIsDelete, setEditMode, setQtyMinMax, qtyMinMax, range, setRange, setEditModeForCustomLine,
}) => {
  const allowed = usePermission(PermissionEnum.StockPurchaseOrdersEditPartLineFieldsAndUnlockPartLineActions);
  const dispatch = useAppDispatch();
  const purchaseOrderPartsList = useAppSelector(purchaseOrderPartsTableListSelector);
  const purchaseOrderPartsColumns = useAppSelector(purchaseOrderPartsColumnsSelector);
  const purchaseOrderPartsListFilters = useAppSelector(purchaseOrderPartsListFiltersSelector);
  const { id } = useParams();

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: filters?.partNumber,
    productName: filters?.partName,
  });

  useEffect(() => {
    if (!filters) {
      setSearchVl({ productNumber: undefined, productName: undefined });
      setQtyMinMax(undefined);
      setRange({ quantity: ['0.00', maxCommonDecimal.toString()] });
    }
    // eslint-disable-next-line
  }, [filters]);

  const getFilteredResult = (filters: PurchaseOrderPartsListFiltersType) => {
    dispatch(setPurchaseOrderPartsListFilters({ ...filters }));
    id && dispatch(getPurchaseOrderPartsList({
      purchaseOrderId: +id, filters, withoutRangeFilterUpdate: true,
    }));
  };

  const filtersList = [
    {
      dataIndex: 'state',
      value: purchaseOrderPartsListFilters?.states || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const newValues = {
          ...purchaseOrderPartsListFilters,
          states: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        };
        getFilteredResult(newValues);
      },
    },
  ];

  const sortHandle = (field: number) => {
    if (purchaseOrderPartsListFilters?.order?.field === field) {
      const newFilters = {
        ...purchaseOrderPartsListFilters,
        order: { ...purchaseOrderPartsListFilters.order, isAsc: !purchaseOrderPartsListFilters.order?.isAsc },
      };
      getFilteredResult(newFilters);
    } else {
      const newFilters = {
        ...purchaseOrderPartsListFilters,
        order: { field, isAsc: true },
      };
      getFilteredResult(newFilters);
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = {
        ...purchaseOrderPartsListFilters,
        [field]: undefined,
      };
      getFilteredResult(newFilters);
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = {
      ...purchaseOrderPartsListFilters,
      [field]: searchVl[field]?.trim(),
    };
    getFilteredResult(newFilters);
  };
  const setRangeHandle = (field: string, value: [number, number]) => {
    const newFilters = {
      ...purchaseOrderPartsListFilters,
      [field]: {
        min: value[0],
        max: value[1],
      },
    };
    getFilteredResult(newFilters);
  };

  const colsCount = allowed ? purchaseOrderPartsColumns.length + 2 : purchaseOrderPartsColumns.length;

  return (
    <TableNew className="table-with-editable-cells">
      <Thead>
        <Tr>
          {allowed && (
            <ThNew checkbox>
              <Checkbox checked={isAll} indeterminate={isIndeterminate} disabled={disabledSelectAll} onChange={checkAll} table />
            </ThNew>
          )}
          {purchaseOrderPartsColumns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            setRangeHandle={setRangeHandle}
            totalElements={purchaseOrderPartsList?.length || 0}
            disabled={purchaseOrderPartsList?.length === 0}
            rangePatterns={qtyMinMax
              ? { quantity: { min: qtyMinMax?.min, max: qtyMinMax?.max } }
              : undefined}
            range={range}
            setRange={setRange}
            filters={filtersList}
            searchVl={searchVl}
            tableFilters={filters}
            className={`partsListTh--${col.dataIndex}`}
          />)}
          {allowed && (
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          )}
        </Tr>
      </Thead>
      <PurchaseOrderPartsListBody
        purchaseOrderPartsList={purchaseOrderPartsList}
        setIsSelectPrice={setIsSelectPrice}
        setIsReceived={setIsReceived}
        checkLine={checkRow}
        checkedKeys={checkedKeys}
        setItemToChange={setItemToChange}
        setIsDelete={setIsDelete}
        setEditMode={setEditMode}
        colsCount={colsCount}
        setEditModeForCustomLine={setEditModeForCustomLine}
      />
    </TableNew>
  );
};

export default PartsListTable;
