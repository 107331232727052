import React from 'react';
import Segmented from '../../../../core/components/segmented/Segmented';
import Selectable from '../../../../core/components/selectable/Selectable';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { PricelistModeEnum } from '../../enums/PricelistModeEnum';

type PricelistContentTabsProps = {
  mode: string | null;
  setModeParam: StringFunctionType;
  allowedTabs: { label: string, value: PricelistModeEnum }[],
}

const PricelistContentTabs: React.FC<PricelistContentTabsProps> = ({ mode, setModeParam, allowedTabs }) => {
  const { windowWidth } = useScreenWitdh();

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
        ? <div className="mobile-sticky-filters">
          <Selectable
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
        : <div className="desktop-sticky-filters">
          <Segmented
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>}
    </>
  );
};

export default PricelistContentTabs;
