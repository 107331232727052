import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import '../PartViewPartsKits.scss';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { PartsKitsDto } from '../../../../../../parts-kits/dashboard/types/partsKitsTypes';
import { labelHandle } from '../../../../../../../core/utils/labelHandle';
import { useAppSelector } from '../../../../../../../store/hooks';
import { partCategoriesLookupSelector } from '../../../../../../../store/selectors/sharedSelectors';
import { PartsKitsStatusUiNames, PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import { routesPath } from '../../../../../../../core/enums/pathEnum';
import { partsKitsStatusesSelector } from '../../../../../../../store/selectors/coreStatusesSelectors';
import PrivateLineLink from '../../../../../../../common/components/private-line-link/PrivateLineLink';

type PartViewPartsKitsItemProps = {
  data: PartsKitsDto,
}
const PartViewPartsKitsItem:React.FC<PartViewPartsKitsItemProps> = ({ data }) => {
  const categoriesList = useAppSelector(partCategoriesLookupSelector);
  const partsKitsStatuses = useAppSelector(partsKitsStatusesSelector);

  const activeStatus = partsKitsStatuses.find((status) => status.label === PartsKitsStatusUiNames.Active)?.value;

  return (
    <PrivateLineLink
      to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${data.id}`}
      permission={PermissionEnum.StockPartsKitViewPage}
      className="partViewPartsKitsItem"
    >
      <span className="partViewPartsKitsItem__title">
        <FontAwesomeIcon icon={faBox} className="partViewPartsKitsItem__icon" />
        <span className="partViewPartsKitsItem__id">{data.partsKitCode}</span>
        <TruncateTooltip value={data.name || ''} className="partViewPartsKitsItem__name" />
      </span>
      <span className="partViewPartsKitsItem__status-wrapper">
        <FontAwesomeIcon
          icon={data.status === activeStatus ? faCheckCircle : faTimesCircle}
          className="partViewPartsKitsItem__status-icon"
        />
      </span>
      <span className="partViewPartsKitsItem__category">{labelHandle(data.categoryId, categoriesList)}</span>
    </PrivateLineLink>
  );
};

export default PartViewPartsKitsItem;
