import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { cancelledLinesColumnsSelector, cancelledLinesDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import CancelledLinesColumns from './CancelledLinesColumns';
import CancelledLinesBody from './CancelledLinesBody';
import './CancelledLinesTable.scss';

const CancelledLinesTable: React.FC = () => {
  const columns = useAppSelector(cancelledLinesColumnsSelector);
  const { totalCount } = useAppSelector(cancelledLinesDataSelector);

  return (
    <TableNew>
      <CancelledLinesColumns columns={columns} totalCount={totalCount} />
      <CancelledLinesBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default CancelledLinesTable;
