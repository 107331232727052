import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, emptyPaging, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { PartDtoType, StockFiltersType } from '../../../stock/search/types/stockSearchTypes';
import { stockApi } from '../../../api/stockApi';
import { PartsKitsDto, PartsKitsFiltersType } from '../../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { getOrderById, getOrderSummaryThunk } from './viewPageOrderThunks';
import {
  AfterLinesActionsParams,
  BulkPatchReqLinesDataType,
  CommonCreatePartLineReqDto, CreatePartRequestPartsKitLine,
  CustomLineReqType,
  PartReqLinesFilters,
  PartRequestLineDto,
  PatchPartReqLineDto,
} from '../../../common/types/commonTypes';
import { RootState } from '../../store';

export const getOrderPartRequestLines = createAsyncThunk<
ResponseResult<PartRequestLineDto[], { allItemIds: number[] }>,
{ partRequestId: number, filters: PartReqLinesFilters }
>('get/OrderPartRequestLines', async ({ partRequestId, filters }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.fetchPartRequestLines({ ...filters, partRequestIds: [partRequestId] });
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const getStockPartsToOrderThunk = createAsyncThunk<
ResponseResult<PartDtoType[]>,
{
  filters: StockFiltersType,
}
>(
  'get/StockPartsToOrder',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitsToOrderThunk = createAsyncThunk<ResponseResult<PartsKitsDto[]>, {
  filters: PartsKitsFiltersType,
}>(
  'get/PartsKitsToOrder',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitsList(
        filters,
        true,
        true,
        true,
      );
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

const afterLinesInteractionsHandle = (params: AfterLinesActionsParams, withoutSummary?: boolean) => {
  const {
    id, partRequestId, partRequestLinesFilters, dispatch,
  } = params;
  dispatch(getOrderById({ id }));
  partRequestLinesFilters && dispatch(getOrderPartRequestLines({
    partRequestId,
    filters: partRequestLinesFilters,
  }));
  !withoutSummary && dispatch(getOrderSummaryThunk({ id }));
};

export const addPartsKitLineToOrderThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CreatePartRequestPartsKitLine,
  closeModal: VoidFunctionType,
}>(
  'post/PartsKitLineToOrder',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const response = await stockApi.addPartsKitLineToPartRequest(partRequestId, data);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const partsKitErrors = errors.filter((err) => err.key === 'Quantity' || err.key === 'PartsKitId');
      if (partsKitErrors.length > 0) {
        dispatch(setErrorMessage({ message: partsKitErrors[0].message, toastId: Math.random() }));
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addCustomLineToOrderThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CustomLineReqType,
  closeModal: VoidFunctionType,
}>(
  'post/CustomLineToOrder',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const response = await stockApi.addCustomLineToPartRequest(partRequestId, data);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchOrderPartReqLineThunk = createAsyncThunk<ResponseSingleResult | null, {
  partRequestId: number,
  lineId: number,
  data: PatchPartReqLineDto,
  setFieldToEdit?: VoidFunctionType,
  closeModal?: VoidFunctionType,
  openReasonModal?: VoidFunctionType,
}>(
  'patch/OrderPartReqLine',
  async ({
    partRequestId, lineId, data, setFieldToEdit, closeModal, openReasonModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      if (!data.reason) {
        const {
          data: { data: { isCostChanged } },
        } = await stockApi.getPartRequestSummaryReview({ id: partRequestId, partRequestLineIds: [lineId], data });
        if (isCostChanged) {
          openReasonModal && openReasonModal();
          dispatch(setFetching(false));
          return null;
        }
      }
      const response = await stockApi.patchPartRequestLine(partRequestId, lineId, data);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params);
      }
      setFieldToEdit && setFieldToEdit();
      closeModal && closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const actionReasonError = errors.find((el) => el.key === 'Reason');
      const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
      if (actionReasonError) {
        const { message } = actionReasonError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        setFieldToEdit && setFieldToEdit();
      }
      if (priceDateUpdateUiError) {
        const { message } = priceDateUpdateUiError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        setFieldToEdit && setFieldToEdit();
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addPartLineToOrderThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  partRequestId: number,
  data: CommonCreatePartLineReqDto,
  closeModal: VoidFunctionType,
}>(
  'post/PartLineToOrder',
  async ({
    partRequestId, data, closeModal,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const response = await stockApi.addPartLineToPartRequest(partRequestId, data);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchBulkPartReqLines = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  orderId: number,
  data: BulkPatchReqLinesDataType,
  closeModal: VoidFunctionType,
  openReasonModal: VoidFunctionType,
}
>(
  'patch/bulkPartReqLines',
  async ({
    partRequestId, orderId, data, closeModal, openReasonModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { orders: { partRequestLinesFilters } } = getState() as RootState;
      const params = {
        id: orderId,
        partRequestId,
        dispatch,
        partRequestLinesFilters,
      };
      const onFinish = () => {
        afterLinesInteractionsHandle(params);
        closeModal();
        dispatch(setLoading(false));
      };
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestLines({
        ...partRequestLinesFilters,
        partRequestIds: [partRequestId],
        ...emptyPaging,
      });
      const filteredIds = data.partRequestLineIds.filter((id) => allItemIds.includes(id));
      if (!filteredIds.length) {
        onFinish();
        return null;
      }
      if (!data.patchPartRequestLineDto.reason) {
        const {
          data: { data: { isCostChanged } },
        } = await stockApi.getPartRequestSummaryReview({
          id: partRequestId,
          partRequestLineIds: filteredIds,
          data: data.patchPartRequestLineDto,
        });
        if (isCostChanged) {
          dispatch(setLoading(false));
          openReasonModal();
          return null;
        }
      }
      const response = await stockApi.bulkPatchPartReqLines(partRequestId, { ...data, partRequestLineIds: filteredIds });
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const actionReasonError = errors.find((el) => el.key === 'Reason');
      if (actionReasonError) {
        const { message } = actionReasonError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        closeModal();
      }

      const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
      if (priceDateUpdateUiError) {
        const { message } = priceDateUpdateUiError;
        dispatch(setErrorMessage({ message, toastId: Math.random() }));
        closeModal();
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteOrderPartReqSingleLineThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  orderId: number,
  lineId: number,
  closeModal: VoidFunctionType,
  deletionReason: string,
}
>(
  'delete/SinglePartReqLine',
  async ({
    orderId, lineId, closeModal, deletionReason,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const partRequestId = orderDetails?.order?.partRequestId;
      const response = await stockApi.deletePartReqLine(lineId, deletionReason);
      if (partRequestId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params);
      }
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const bulkDeleteOrderPartReqLinesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  orderId: number,
  partRequestId: number,
  partRequestLineIds: number[],
  reason: string,
  closeModal: VoidFunctionType,
}
>(
  'delete/bulkPartReqLines',
  async ({
    orderId, partRequestId, partRequestLineIds, reason, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { orders: { partRequestLinesFilters } } = getState() as RootState;
      const params = {
        id: orderId,
        partRequestId,
        dispatch,
        partRequestLinesFilters,
      };
      const onFinish = () => {
        afterLinesInteractionsHandle(params);
        dispatch(getOrderSummaryThunk({ id: orderId }));
        closeModal();
        dispatch(setLoading(false));
      };
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartRequestLines({
        ...partRequestLinesFilters,
        partRequestIds: [partRequestId],
        ...emptyPaging,
      });
      const filteredList = partRequestLineIds.filter((el) => allItemIds.includes(el));
      if (!filteredList.length) {
        onFinish();
        return null;
      }
      const response = await stockApi.bulkDeletePartReqLines(filteredList, reason);
      onFinish();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const submitOrderPartReqLinesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  customLinesIds: number[] | null,
  closeModal: VoidFunctionType,
}
>(
  'put/SubmitOrderPartReqLines',
  async ({
    partRequestId, customLinesIds, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const response = await stockApi.submitPartReqLines(partRequestId, customLinesIds);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params, true);
      }
      closeModal();
      dispatch(setSuccessMessage({ message: 'The part request was successfully submitted.', toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const discardOrderPartReqLinesChangesThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  partRequestId: number,
  closeModal: VoidFunctionType,
}
>(
  'put/discardOrderPartReqLinesChanges',
  async ({
    partRequestId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { orders, shared } = getState() as RootState;
      const { partRequestLinesFilters } = orders;
      const { orderDetails } = shared;
      const response = await stockApi.discardPartReqLinesChanges(partRequestId);
      const orderId = orderDetails?.order?.id;
      if (orderId) {
        const params = {
          id: orderId,
          partRequestId,
          dispatch,
          partRequestLinesFilters,
        };
        afterLinesInteractionsHandle(params, true);
      }
      closeModal();
      dispatch(setSuccessMessage({ message: 'The changes were successfully discarded.', toastId: Math.random() }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
