import React, { useState } from 'react';
import classNames from 'classnames';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import WorkingHoursCard from './WorkingHoursCard';
import { WorkCalendarUserDto } from '../../types/EmployeeWorkCalendarTypes';
import { useAppSelector } from '../../../../store/hooks';
import { employeeDepartmentsSelector } from '../../../../store/selectors/employeesSelector';
import { EmployeeSchedule } from '../modals/EmployeesWorkingHoursModal';

type WorkCalendarLineProps = {
  data: WorkCalendarUserDto,
  employeeId: number,
  setEmployeeSchedule: (value: EmployeeSchedule | null) => void,
}
const WorkCalendarLine: React.FC<WorkCalendarLineProps> = ({ data, employeeId, setEmployeeSchedule }) => {
  const employeeDepartments = useAppSelector(employeeDepartmentsSelector);

  const [isHovered, setIsHovered] = useState(false);

  return (
    <>
      <div
        className={classNames('workCalendarLine__col col-fixed-left', { 'col-hovered': isHovered })}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <TruncateTooltip
          value={`${data.firstName} ${data.lastName}`}
          className="workCalendarLine__name"
          overlayClassName="truncate-table-tooltip"
        />
        <TruncateTooltip
          value={employeeDepartments.find((el) => el.value === data.department)?.label || '-'}
          className="workCalendarLine__role"
          overlayClassName="truncate-table-tooltip"
        />
      </div>
      {data.schedules?.map((el) => (
        <div
          key={el.date}
          className={classNames('workCalendarLine__col', { 'col-hovered': isHovered })}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
        >
          <WorkingHoursCard
            totalWorkingHours={el.totalWorkingHours}
            scheduledWorkingHours={el.scheduledWorkingHours}
            nonWorkingHours={el.nonWorkingHours}
            leaveRequest={el.approvedLeaveRequestLeaveType}
            dayPart={el.approvedLeaveRequestDayPart}
            onClick={() => {
              const employeeSchedule: EmployeeSchedule = {
                id: employeeId,
                approvedLeaveRequestLeaveType: el.approvedLeaveRequestLeaveType,
                date: el.date,
                areWeekendsAndHolidaysNonWorking: el.areWeekendsAndHolidaysNonWorking,
                scheduledWorkingHours: el.scheduledWorkingHours,
                nonWorkingHours: el.nonWorkingHours,
              };
              setEmployeeSchedule(employeeSchedule);
            }}
          />
        </div>
      ))}
    </>
  );
};

export default WorkCalendarLine;
