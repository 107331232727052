import React from 'react';
import classNames from 'classnames';

type TheadProps = {
  children: React.ReactNode;
  className?: string;
}

const Thead: React.FC<TheadProps> = ({ children, className }) => {
  return (
    <thead className={classNames(className, 'table-head')}>{children}</thead>
  );
};

export default Thead;
