import React from 'react';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import InvoicingOrderLinesColumns from './InvoicingOrderLinesColumns';
import InvoicingOrderLinesBody from './InvoicingOrderLinesBody';

const InvoicingOrderLinesTable: React.FC = () => {
  return <TableNew className="table-with-editable-cells" offsetBottom>
    <InvoicingOrderLinesColumns />
    <InvoicingOrderLinesBody />
  </TableNew>;
};

export default InvoicingOrderLinesTable;
