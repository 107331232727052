import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import { RectificationColumnType } from './types/rectificationsTypes';
import { RectificationModes, RectificationModesEnum } from './enums/rectificationEnums';
import RectificationsContent from './components/rectification-content/RectificationsContent';
import ColumnsConfigurationModal from '../../common/components/columns-configuration-modal/ColumnsConfigurationModal';
import RectificationsFilters from './components/rectification-filters/RectificationsFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { rectificationTypesSelector, rectificationWarningsSelector } from '../../store/selectors/coreSelectors';
import {
  putRectificationLayoutThunk,
} from '../../store/thunks/rectification/rectificationsDashThunks';
import {
  rectificationCompletedStatusSelector, rectificationsDashFiltersSelector, sortedRectificationCollectionsSelector,
} from '../../store/selectors/rectificationsSelector';
import { DictionaryItem } from '../../core/types/coreTypes';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import {
  setColumnsPagination,
  setRectificationCollections,
  setRectificationDashFilters,
} from '../../store/slices/rectificationsSlice';
import { rectificationStatusesSelector } from '../../store/selectors/coreStatusesSelectors';
import { getRectificationTypesThunk, getRectificationWarningsThunk } from '../../store/thunks/coreThunk';

const RectificationsContainer:React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const stateFilters = useAppSelector(rectificationsDashFiltersSelector);
  const rectificationStatuses = useAppSelector(rectificationStatusesSelector);
  const rectificationCompletedStatus = useAppSelector(rectificationCompletedStatusSelector);
  const rectificationCollections = useAppSelector(sortedRectificationCollectionsSelector);
  const rectificationTypes = useAppSelector(rectificationTypesSelector);
  const rectificationWarnings = useAppSelector(rectificationWarningsSelector);
  const [cards, setCards] = useState<RectificationColumnType | null>(null);
  const [params] = useSearchParams();
  const keywordParam = params.get('keyword');

  const [isEyeModal, setIsEyeModal] = useState<boolean>(false);

  useEffect(() => {
    if (keywordParam && !stateFilters.keyword) {
      dispatch(setRectificationDashFilters({ ...stateFilters, keyword: keywordParam }));
    }
    // eslint-disable-next-line
  }, [keywordParam, stateFilters]);

  const setModeParam = (mode: string) => {
    if (RectificationModes.includes(mode as RectificationModesEnum)) {
      params.append('page', mode);
    } else {
      params.delete('page');
    }
    navigate({ search: `?page=${mode}` }, { replace: true });
  };

  const statusesHandle = useMemo(() => {
    const permissions = currentUser?.userContextRole.permissions;
    let statuses: DictionaryItem[] = [...rectificationStatuses];
    if (!permissions?.includes(PermissionEnum.RectificationShowInCompletedStatus)) {
      statuses = statuses.filter((st) => st.value !== rectificationCompletedStatus);
    }
    return statuses;
  }, [currentUser, rectificationStatuses, rectificationCompletedStatus]);

  useEffect(() => {
    setCards(rectificationCollections);
  }, [rectificationCollections]);

  useEffect(() => {
    if (!rectificationTypes.length) {
      dispatch(getRectificationTypesThunk());
    }
    if (!rectificationWarnings.length) {
      dispatch(getRectificationWarningsThunk());
    }

    return () => {
      dispatch(setRectificationCollections(null));
      dispatch(setColumnsPagination([]));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout scrollableMainArea>
      <ColumnsConfigurationModal
        isVisible={isEyeModal}
        setIsVisible={() => setIsEyeModal(false)}
        statusOptions={statusesHandle}
        collections={rectificationCollections}
        saveHandle={(layoutOptions) => dispatch(putRectificationLayoutThunk({
          data: layoutOptions,
          closeModal: () => setIsEyeModal(false),
        }))}
      />
      <RectificationsFilters
        page={params.get('page')}
        setPageParam={setModeParam}
        setIsEyeModal={setIsEyeModal}
      />
      <RectificationsContent
        pageParam={params.get('page')}
        cards={cards}
        setCards={setCards}
      />
    </Layout>
  );
};

export default RectificationsContainer;
