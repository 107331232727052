import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PricesActions from './PricesActions';
import './PricelistPrices.scss';
import PricesTable from './PricesTable';
import {
  pricelistAffectedFiltersSelector,
  pricelistDetailsSelector, pricelistPartsForTableSelector,
  pricesFiltersSelector,
} from '../../../../../store/selectors/pricelistSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  getPartPricelistCategories,
  getPartPricelistSubcategories,
  getPricelistAffectedThunk,
  partBulkDeleteThunk,
  partDeleteThunk, putPartPricesThunk,
} from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import AddPartModal from '../modals/add-part/AddPartModal';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import EditPartModal from '../modals/edit-part/EditPartModal';
import {
  setPartPrices, setPricelistAffectedFilters,
  setPricelistAffectedItems,
} from '../../../../../store/slices/pricelistSlice';
import { ExtendedPricePart, SavePricelistPartPriceDto } from '../../../types/pricelistViewPageTypes';
import { utcDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';
import { BooleanFunctionType, initDataList, initSmallPaging } from '../../../../../core/types/coreTypes';
import { DEFAULT_DATE_FORMAT } from '../../../../../core/utils/regex';
import { setErrorMessage } from '../../../../../store/slices/coreSlice';
import { getSuitableVehicleProfiles } from '../../../../../store/thunks/shared/sharedThunks';
import { vehicleProfilesModelDescrOrderSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import {
  getPricelistAvailablePartsOrdering,
  getPricelistPartsOrdering,
} from '../../../../../store/thunks/core/coreOrderingThunks';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

type PricelistPricesProps = {
  setAffected: BooleanFunctionType,
}
const PricelistPrices: React.FC<PricelistPricesProps> = ({ setAffected }) => {
  const dispatch = useAppDispatch();
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const { items, allItemIds, totalCount } = useAppSelector(pricelistPartsForTableSelector);
  const pricesFilters = useAppSelector(pricesFiltersSelector);
  const vehModelDescrOrder = useAppSelector(vehicleProfilesModelDescrOrderSelector);
  const pricelistAffectedFilters = useAppSelector(pricelistAffectedFiltersSelector);

  const [addPartModal, setAddPartModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<number | undefined>(undefined);
  const [editModal, setEditModal] = useState<boolean>(false);
  const [isPriceChangeModal, setPriceChangeModal] = useState<boolean>(false);
  const [pricesCopy, setPricesCopy] = useState<ExtendedPricePart[]>([]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    dispatch(getPartPricelistCategories({ filters: undefined }));
    dispatch(getPartPricelistSubcategories({ filters: undefined }));
    dispatch(getPricelistPartsOrdering());
    dispatch(getPricelistAvailablePartsOrdering());
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    vehModelDescrOrder && dispatch(getSuitableVehicleProfiles({
      filters: { order: { field: vehModelDescrOrder, isAsc: true } },
    }));
    // eslint-disable-next-line
  }, [vehModelDescrOrder]);

  const confirmDeleteHandle = () => {
    if (itemToDelete) {
      pricelistDetails && dispatch(partDeleteThunk({
        pricelistId: pricelistDetails.id,
        partId: itemToDelete,
        closeModal: () => {
          setDelete(false);
          setItemToDelete(undefined);
        },
      }));
    } else {
      pricelistDetails && dispatch(partBulkDeleteThunk({
        pricelistId: pricelistDetails.id,
        partIds: checkedKeys,
        closeModal: () => setDelete(false),
      }));
    }
  };

  const cancelAffectedModal = () => {
    setAffected(false);
    dispatch(setPricelistAffectedItems(initDataList));
  };
  const closeAfterConfirm = () => {
    setEditModal(false);
    setItemToDelete(undefined);
    dispatch(setPartPrices(initDataList));
    setAffected(false);
    dispatch(setPricelistAffectedItems(initDataList));
  };

  const updatePrices = () => {
    const pricesArr = pricesCopy.map((el) => {
      if (el.isDraft) {
        return {
          priceId: null,
          costPrice: el.costPrice || 0.00,
          sellPrice: el.sellPrice || 0.00,
          effectiveStartDate: utcDateFormatHandler(DEFAULT_DATE_FORMAT, el.effectiveStartDate || undefined),
        };
      } else {
        return {
          priceId: el.id,
          costPrice: el.costPrice || 0.00,
          sellPrice: el.sellPrice || 0.00,
          effectiveStartDate: utcDateFormatHandler(DEFAULT_DATE_FORMAT, el.effectiveStartDate || undefined),
        };
      }
    });
    const currentDate = moment().format(DEFAULT_DATE_FORMAT);
    const isTherePriceForCurrentDate = pricesCopy.length > 0
      ? pricesCopy.find((el) => {
        if (el.effectiveEndDate && el.effectiveStartDate) {
          return moment(currentDate).isBetween(el.effectiveStartDate, el.effectiveEndDate)
              || moment(currentDate).isSame(el.effectiveStartDate)
              || moment(currentDate).isSame(el.effectiveEndDate);
        } else {
          return moment(currentDate).isSameOrAfter(el.effectiveStartDate);
        }
      })
      : null;

    if (isTherePriceForCurrentDate) {
      pricelistDetails && itemToDelete && dispatch(putPartPricesThunk({
        partId: itemToDelete,
        pricelistId: pricelistDetails?.id,
        prices: pricesArr,
        closeModal: () => {
          closeAfterConfirm();
          isPriceChangeModal && setPriceChangeModal(false);
        },
      }));
    } else {
      setTimeout(() => {
        dispatch(setErrorMessage({ message: 'Price value is required for a current date.', toastId: Math.random() }));
      }, 1000);
      cancelAffectedModal();
      isPriceChangeModal && setPriceChangeModal(false);
    }
  };

  const getAffectedHandle = (dataToAffect: { partIds: number[], newPrices?: SavePricelistPartPriceDto[] }) => {
    const { partIds, newPrices } = dataToAffect;
    if (pricelistDetails) {
      const filters = { ...pricelistAffectedFilters, partIds, newPrices };
      dispatch(setPricelistAffectedFilters(filters));
      pricelistDetails && dispatch(getPricelistAffectedThunk({
        pricelistId: pricelistDetails.id,
        filters,
        complexAction: () => setAffected(true),
        confirmAction: () => {
          if (newPrices) {
            setPriceChangeModal(true);
          } else {
            setDelete(true);
          }
          dispatch(setPricelistAffectedFilters(initSmallPaging));
        },
      }));
    }
  };

  return (
    <>
      <AddPartModal
        isVisible={addPartModal}
        onCancel={() => setAddPartModal(false)}
        pricelistId={pricelistDetails?.id}
      />
      <EditPartModal
        isVisible={editModal}
        onCancel={() => {
          setEditModal(false);
          setItemToDelete(undefined);
          dispatch(setPartPrices(initDataList));
        }}
        partId={itemToDelete}
        pricesCopy={pricesCopy}
        setPricesCopy={setPricesCopy}
        getAffectedHandle={getAffectedHandle}
      />
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setDelete(false);
          itemToDelete && setItemToDelete(undefined);
        }}
        confirmHandler={confirmDeleteHandle}
      />
      <ConfirmationModal
        isVisible={isPriceChangeModal}
        onCancel={() => {
          setPriceChangeModal(false);
        }}
        customTitle="Update part prices"
        customText="You are about to update price information for a part."
        customCreateLabel="Yes, update"
        confirmHandler={updatePrices}
      />
      <div className="pricesWrap">
        <PricesActions
          checkedKeys={checkedKeys}
          totalCount={totalCount}
          pricesFilters={pricesFilters}
          setAddPartModal={setAddPartModal}
          getAffectedHandle={getAffectedHandle}
        />
        <PricesTable
          checkedKeys={checkedKeys}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          checkAll={checkAllHandle}
          checkRow={checkRowHandle}
          pricesFilters={pricesFilters}
          parts={items}
          totalCount={totalCount}
          setItemToDelete={setItemToDelete}
          setEditModal={(id: number) => {
            setEditModal(true);
            setItemToDelete(id);
          }}
          getAffectedHandle={getAffectedHandle}
        />
      </div>
    </>
  );
};

export default PricelistPrices;
