import React from 'react';
import { Image as ImageComponent } from 'antd';

type ImageProps = {
  src?: string,
  width?: string | number,
  height?: string | number,
}

const Image:React.FC<ImageProps> = ({ src, width, height }) => {
  return (
    <ImageComponent src={src} width={width} height={height} />
  );
};

export default Image;
