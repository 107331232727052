import React from 'react';
import { useAppSelector } from '../../../store/hooks';
import { vehiclesListLinkVehicleTableSelector } from '../../../store/selectors/vehiclesSelectors';
import { getNo } from '../../../core/utils/entityNumberingHandle';
import Tbody from '../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Button from '../../../core/components/button/Button';
import { VehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { NumberFunctionType } from '../../../core/types/coreTypes';

type LinkVehicleModalBodyProps = {
  filters: VehiclesDashboardFilters,
  selectNewVehicleFn: NumberFunctionType,
  currentVehicleId?: number | null,
}

const LinkVehicleModalBody: React.FC<LinkVehicleModalBodyProps> = ({
  filters, selectNewVehicleFn, currentVehicleId,
}) => {
  const { items, totalCount } = useAppSelector(vehiclesListLinkVehicleTableSelector);

  const numberedList = items.map((el, i) => {
    const { id, ...rest } = el;
    return {
      id,
      ordinalNumber: getNo(filters.page || 1, filters.pageSize || 5, i),
      ...rest,
    };
  });
  return (
    <Tbody>
      {totalCount === 0
        ? <EmptyTableSection
          colSpan={7}
          text={filters.keyword && totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
        />
        : <>
          {numberedList.map((veh, i) => <Tr key={i}>
            {Object.entries(veh).map(([key, value]) => {
              if (key === 'id') return null;
              return <TdNew key={key}>
                {key === 'ordinalNumber'
                  ? value
                  : <TruncateTooltip
                    value={value || '-'}
                    table
                  />}
              </TdNew>;
            })}
            {veh.id !== currentVehicleId && <TdNew>
              <Button
                label="Select"
                onClick={() => selectNewVehicleFn(veh.id)}
                type="text"
              />
            </TdNew>}
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default LinkVehicleModalBody;
