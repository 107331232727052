import React from 'react';
import { Dropdown, Menu } from 'antd';
import classnames from 'classnames';
import { DownOutlined } from '@ant-design/icons';
import Button from '../button/Button';
import './Selectable.scss';

type SelectableProps = {
  options: {label: string, value: string | number, disabled?: boolean}[],
  onChange: (value: number | string) => void,
  value: number | string | undefined | null,
  className?: string,
  overlayClassName?: string,
  size?: 'sm' | 'lg'
  parentRender?: boolean,
  disabled?: boolean,
}
const Selectable:React.FC<SelectableProps> = ({
  options, onChange, value, className, overlayClassName, size, parentRender = true, disabled,
}) => {
  const menuItems = options.map(({ label, value, disabled }) => ({ label, key: value, disabled }));
  const menu = (
    <Menu
      items={menuItems}
      onClick={({ key }) => {
        onChange(key);
      }}
      selectedKeys={[value?.toString() || '']}
      className="selectable__menu"
    />
  );

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      getPopupContainer={parentRender ? (triggerNode: HTMLElement) => triggerNode.parentNode as HTMLElement : undefined}
      className={classnames('selectable', className, {
        'selectable--sm': size === 'sm',
        'selectable--lg': size === 'lg',
      })}
      disabled={disabled}
      overlayClassName={overlayClassName}
    >
      <Button
        label={menuItems.find(({ key }) => key.toString() === value?.toString())?.label || ''}
        icon={<DownOutlined />}
        disableDefaultStyles
      />
    </Dropdown>
  );
};

export default Selectable;
