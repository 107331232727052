import React from 'react';
import { AttachmentsColumnsProps } from '../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../store/hooks';
import { customerAttachmentsFiltersSelector } from '../../../../../store/selectors/customersSelectors';
import { setCustomerAttachmentsFilters } from '../../../../../store/slices/customersSlice';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../permissions-handling/permissionHook';
import AttachmentColumns from '../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../core/hooks/useCoreAttachmentsColumns';

const CustomerAttachColumns: React.FC<AttachmentsColumnsProps> = ({
  isAll, checkAll, totalElements, indeterminate,
}) => {
  const allowedManage = usePermission(PermissionEnum.CustomerEditFields);
  const attachmentsFilters = useAppSelector(customerAttachmentsFiltersSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachmentsFilters, setCustomerAttachmentsFilters);

  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      totalElements={totalElements}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={attachmentsFilters}
    />
  );
};

export default CustomerAttachColumns;
