import React from 'react';
import {
  Control, Controller, FieldErrors, UseFormClearErrors, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import moment from 'moment';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Select from '../../../core/components/select/Select';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { RectificationTypeEnum } from '../../dashboard/enums/rectificationEnums';
import AddressField from '../../../common/components/address/AddressField';
import RequisiteSection from '../../../common/components/requisite/RequisiteSection';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import { RequisiteFields } from '../../../common/utils/data';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  allowedJobTypesForUserSelector,
  jobTypesWithoutSupplyOnlySelector,
  priorityLevelsSelector,
  rectificationFaultTypesSelector, rectificationTypesSelector,
} from '../../../store/selectors/coreSelectors';
import { AddressFields } from '../../../common/types/commonTypes';
import { CreateRectificationFlowEnum } from '../enums/createRectificationEnums';
import Textarea from '../../../core/components/textarea/Textarea';
import { RectificationFormFields } from '../types/createRectificationFormSchema';
import DatePicker from '../../../core/components/date-picker/DatePicker';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';

type CreateRectificationCommonFieldsProps = {
  flowValue: CreateRectificationFlowEnum,
  control: Control<RectificationFormFields>,
  errors: FieldErrors<RectificationFormFields>,
  watch: UseFormWatch<RectificationFormFields>,
  setValue: UseFormSetValue<RectificationFormFields>,
  clearErrors: UseFormClearErrors<RectificationFormFields>,
  usersUi: {value: number, label: JSX.Element}[],
  searchUserV?: string,
  setSearchUserV: (v?: string) => void,
  getFilteredAssignees: (keyword?: string) => void,
  requisiteFields: RequisiteFields,
  disabledRequisiteSection: boolean,
  isSame: boolean,
  onCheckSameBox: BooleanFunctionType,
  setRequisiteModal: (isShipping: boolean) => void,
  setAddressModal: BooleanFunctionType,
  addressFields: AddressFields,
  disabledField: boolean,
}

const CreateRectificationCommonFields: React.FC<CreateRectificationCommonFieldsProps> = ({
  flowValue, control, errors, usersUi, watch, getFilteredAssignees,
  setSearchUserV, searchUserV, requisiteFields, setValue, clearErrors, disabledRequisiteSection,
  isSame, setRequisiteModal, onCheckSameBox, setAddressModal, addressFields, disabledField,
}) => {
  const dispatch = useAppDispatch();
  const allowedJobTypesForUser = useAppSelector(allowedJobTypesForUserSelector);
  const jobTypesWithoutSupplyOnly = useAppSelector(jobTypesWithoutSupplyOnlySelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const rectificationTypes = useAppSelector(rectificationTypesSelector);
  const rectificationFaultTypes = useAppSelector(rectificationFaultTypesSelector);
  const rectificationType = watch('rectificationType');
  return (
    <>
      <Controller
        name="priorityLevel"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={priorityLevels}
            error={errors.priorityLevel?.message}
            label="Priority level"
            disabled={disabledField}
          />
        )}
      />
      <Controller
        name="jobType"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={flowValue === CreateRectificationFlowEnum.USUAL
              ? allowedJobTypesForUser
              : jobTypesWithoutSupplyOnly}
            error={errors.jobType?.message}
            label="Job type"
            disabled={disabledField}
          />
        )}
      />
      <Controller
        name="assigneeId"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            onSearch={(val) => {
              if (val) {
                if (val.length <= 250) {
                  setSearchUserV(val);
                } else {
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              } else {
                setSearchUserV(val);
                getFilteredAssignees(undefined);
              }
            }}
            onSelect={() => {
              searchUserV && getFilteredAssignees(undefined);
              setSearchUserV(undefined);
            }}
            searchValue={searchUserV}
            options={usersUi}
            showSearch
            error={errors.assigneeId?.message}
            label="Assigned to"
            disabled={disabledField}
          />
        )}
      />
      <Controller
        name="rectificationType"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(v) => {
              if (+v === RectificationTypeEnum.PAID) {
                setValue('faultType', undefined);
                clearErrors('faultType');
              }
              field.onChange(v);
            }}
            options={rectificationTypes}
            error={errors.rectificationType?.message}
            label="Rectification type"
            disabled={disabledField}
          />
        )}
      />
      <Controller
        name="faultType"
        control={control}
        rules={{
          required: rectificationType === RectificationTypeEnum.FREE && ErrorsEnum.REQUIRED,
        }}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={rectificationFaultTypes}
            error={errors.faultType?.message}
            label="Fault value"
            className="details-form__field--lg"
            disabled={disabledField || !rectificationType}
          />
        )}
      />
      <AddressField
        addressFields={addressFields}
        setAddressModal={setAddressModal}
        title="Job location"
        disabled={disabledField}
        isIconVisible={Object.values(addressFields).some((el) => el)}
      />
      <Controller
        name="dueDate"
        control={control}
        render={({ field }) => (
          <DatePicker
            value={field.value ? moment(field.value) : undefined}
            onChange={(value) => {
              const formatted = value ? value.format(DEFAULT_DATE_FORMAT) : '';
              field.onChange(formatted);
            }}
            error={errors.dueDate?.message}
            disabledDate={(currentDate) => {
              const today = moment();
              const tooEarly = moment(currentDate).add(1, 'day') < today;
              const tooLate = currentDate > today.add(24, 'months');
              return tooEarly || tooLate;
            }}
            label="Due date"
            format="DD/MM/YYYY"
            allowClear
            disabled={disabledField}
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={{
          maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
        }}
        render={({ field }) => (
          <Textarea
            value={field.value || ''}
            onChange={field.onChange}
            error={errors.description?.message}
            label="Description"
            className="details-form__field--lg"
            disabled={disabledField}
          />
        )}
      />
      <RequisiteSection
        requisiteDetails={requisiteFields.billing}
        setIsVisible={() => setRequisiteModal(false)}
        title="Billing details"
        disabled={disabledRequisiteSection || disabledField}
      />
      <RequisiteSection
        requisiteDetails={requisiteFields.shipping}
        setIsVisible={() => setRequisiteModal(true)}
        isShipping
        title="Shipping details"
        onCheck={(val) => onCheckSameBox(val)}
        isSame={isSame}
        disabled={disabledRequisiteSection || disabledField}
      />
    </>
  );
};

export default CreateRectificationCommonFields;
