export enum VehicleViewModeParamsEnum {
  VEHICLE_DETAILS = 'details-and-activity',
  VEHICLE_INSPECTION = 'inspection',
  VEHICLE_ATTACHMENTS = 'attachments',
  VEHICLE_LINKS = 'links',
}

export enum VehicleViewModeLabelsEnum {
  VEHICLE_DETAILS = 'Details & activity',
  VEHICLE_INSPECTION = 'Inspection',
  VEHICLE_ATTACHMENTS = 'Attachments',
  VEHICLE_LINKS = 'Links',
}

export const VehicleContentModes = [
  VehicleViewModeParamsEnum.VEHICLE_DETAILS,
  VehicleViewModeParamsEnum.VEHICLE_INSPECTION,
  VehicleViewModeParamsEnum.VEHICLE_ATTACHMENTS,
  VehicleViewModeParamsEnum.VEHICLE_LINKS,
];

export enum VehicleEventEnum {
  Note = 'Note',
  VehicleCreation = 'VehicleCreation',
  VehicleFieldUpdateOrAddingValue = 'VehicleFieldUpdateOrAddingValue',
  VehicleStatusChange = 'VehicleStatusChange',
  VehicleDeletion = 'VehicleDeletion',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  VehicleCheckCreation = 'VehicleCheckCreation',
  VehicleCheckDeletion = 'VehicleCheckDeletion',
}
