import axios, { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  AxiosErrorResponse,
  CoreAttachListElType,
  CreateUploadUriResponse, ResponseSingleResult,
} from '../../core/types/coreTypes';
import { setCurrentServerError, setLoading } from '../../store/slices/coreSlice';

export const bytesToMegabytes = (size: number) => {
  return `${(size / 1024).toFixed(2)} kb`;
};

export const uploadAttachmentsAsyncHandle = async (
  list: CoreAttachListElType[],
  comment: string,
  res: AxiosResponse<ResponseSingleResult<CreateUploadUriResponse>>,
  dispatch: ThunkDispatch<unknown, unknown, AnyAction>,
  isFile?: boolean,
  fileAttachmentType?: number,
) => {
  const uriListArr = list.map((el) => {
    const f = res.data.data.uploadUriDtos.find((a) => a.uploadGuid === el.uploadGuid);
    if (f) {
      return {
        ...el, uploadUri: f.uploadUri,
      };
    } else return el;
  });
  const promises = uriListArr.map((l) => {
    return l.uploadUri && axios({
      method: 'put',
      url: l.uploadUri,
      data: l.file,
      headers: { 'x-ms-blob-type': 'BlockBlob' },
    });
  });

  try {
    await Promise.all(promises);
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const httpCode = error.response?.status;
    const stringifiedCode = error.response?.data.status;
    dispatch(setLoading(false));
    dispatch(setCurrentServerError({ status: stringifiedCode, httpCode }));
    return Promise.reject(err);
  }
  if (isFile) {
    return uriListArr.map((el) => ({
      comment,
      fileName: el.fileName,
      uploadGuid: el.uploadGuid,
      type: fileAttachmentType,
    }));
  } else {
    return uriListArr.map((el) => ({
      comment,
      fileName: el.fileName,
      uploadGuid: el.uploadGuid,
    }));
  }
};
