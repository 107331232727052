import { useEffect, useState } from 'react';

export const useSelectAll = (allAvailableIds: number[] = []) => {
  const [isAll, setIsAll] = useState(false);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);

  const totalCount = allAvailableIds.length;

  useEffect(() => {
    if (totalCount) {
      const hasNoCheckedKeys = !!allAvailableIds.filter((id) => !checkedKeys.includes(id)).length;
      if (!hasNoCheckedKeys) {
        setIsAll(true);
        setIsIndeterminate(false);
      } else {
        setIsAll(false);
        checkedKeys.length ? setIsIndeterminate(true) : setIsIndeterminate(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedKeys]);

  useEffect(() => {
    if (checkedKeys.length) {
      setCheckedKeys(checkedKeys.filter((id) => allAvailableIds.includes(id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allAvailableIds]);

  useEffect(() => {
    if (!totalCount) {
      setCheckedKeys([]);
      setIsIndeterminate(false);
      setIsAll(false);
    }
  }, [totalCount]);

  const checkAllHandle = () => {
    if (totalCount) {
      if (isIndeterminate) {
        setCheckedKeys(allAvailableIds);
        setIsAll(true);
        setIsIndeterminate(false);
      } else if (isAll) {
        setCheckedKeys([]);
        setIsAll(false);
      } else if (!isAll) {
        setCheckedKeys(allAvailableIds);
        setIsAll(true);
      }
    }
  };

  const uncheckAllHandle = () => {
    setCheckedKeys([]);
  };

  const checkRowHandle = (id: number) => {
    if (checkedKeys.includes(id)) {
      const newIds = checkedKeys.filter((el) => el !== id);
      setCheckedKeys(newIds);
    } else {
      setCheckedKeys([...checkedKeys, id]);
    }
  };

  const checkedKeysCount = checkedKeys.length ? `(${checkedKeys.length})` : '';

  return {
    isAll,
    isIndeterminate,
    checkedKeys,
    setCheckedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
    checkedKeysCount,
  };
};
