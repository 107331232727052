import React from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../core/components/button/Button';
import { DEFAULT_DATE_FORMAT } from '../../../../core/utils/regex';
import { setEmployeesWorkCalendarFilters } from '../../../../store/slices/employeesSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { employeesWorkCalendarFiltersSelector, employeeWorkCalendarDatesSelector } from '../../../../store/selectors/employeesSelector';
import WorkCalendarDateCol from './WorkCalendarDateCol';

const WorkCalendarHead: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(employeesWorkCalendarFiltersSelector);
  const dates = useAppSelector(employeeWorkCalendarDatesSelector);

  return (
    <>
      <div className="employeeWorkCalendar__col-head col-fixed-left">
        <Button
          disabled={dates.length <= 0}
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          onClick={() => {
            const startOfPreviousWeek = moment(filters.from).subtract(7, 'days').format(DEFAULT_DATE_FORMAT);
            const endOfPreviousWeek = moment(filters.to).subtract(7, 'days').format(DEFAULT_DATE_FORMAT);
            dispatch(setEmployeesWorkCalendarFilters({ ...filters, from: startOfPreviousWeek, to: endOfPreviousWeek }));
          }}
        />
      </div>
      {dates.length > 0
        ? dates.map((el) => <WorkCalendarDateCol data={el} key={el} />)
        : Array(7).fill(null).map((el, i) => <WorkCalendarDateCol key={i} isLoading />)}
      <div className="grid-header fixed-right employeeWorkCalendar__col-head col-fixed-right">
        <Button
          disabled={dates.length <= 0}
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          onClick={() => {
            const startOfNextWeek = moment(filters.from).add(7, 'days').format(DEFAULT_DATE_FORMAT);
            const endOfNextWeek = moment(filters.to).add(7, 'days').format(DEFAULT_DATE_FORMAT);
            dispatch(setEmployeesWorkCalendarFilters({ ...filters, from: startOfNextWeek, to: endOfNextWeek }));
          }}
        />
      </div>
    </>
  );
};

export default WorkCalendarHead;
