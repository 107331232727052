import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, DictionaryItem, DictionaryResponse, ResponseSingleResult,
} from '../../../../core/types/coreTypes';
import { stockApi } from '../../../../api/stockApi';
import {
  PurchaseOrderDashFiltersType,
  PurchaseOrdersResponseResult,
} from '../../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { setDashboardLoading } from '../../../slices/coreSlice';

export const getPurchaseOrderPartVat = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderPartVat',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderPartVat();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderFailReason = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderFailReason',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderFailReason();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrdersOrdering = createAsyncThunk<ResponseSingleResult<{ items: DictionaryItem[] }>>(
  'get/PurchaseOrdersOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrdersOrdering();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrders = createAsyncThunk<PurchaseOrdersResponseResult,
{ filters: PurchaseOrderDashFiltersType, signal?: AbortSignal }
>(
  'get/PurchaseOrders',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrders(filters, signal, true);
      dispatch(setDashboardLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
