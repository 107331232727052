import React from 'react';
import { PricelistModeEnum } from '../../enums/PricelistModeEnum';
import PricelistDetailsAndActivity from './details-and-activity/PricelistDetailsAndActivity';
import PricelistPrices from './prices/PricelistPrices';
import PricelistAttachments from './attachments/PricelistAttachments';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';

type PricelistContentProps = {
  mode: string | null,
  setAffected: BooleanFunctionType,
  setAffectedParts: BooleanFunctionType,
}

const PricelistContent: React.FC<PricelistContentProps> = ({
  mode, setAffected, setAffectedParts,
}) => {
  const renderContent = () => {
    switch (mode) {
      case PricelistModeEnum.PRICELIST_DETAILS:
        return <PricelistDetailsAndActivity setAffected={setAffected} setAffectedParts={setAffectedParts} />;
      case PricelistModeEnum.PRICELIST_PRICES:
        return <PricelistPrices setAffected={setAffected} />;
      default: return <PricelistAttachments />;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default PricelistContent;
