import React from 'react';
import { summaryFieldValueHandle } from '../../../../core/utils/summaryFieldValueHandle';

type JobLocationEventProps = {
  oldJobCompanyName?: string | null,
  oldJobAddressLine1?: string | null,
  oldJobAddressLine2?: string | null,
  oldJobCityTown?: string | null,
  oldJobRegion?: string | null,
  oldJobPostalCode?: string | null,
  oldJobTelephone?: string | null,
  newJobCompanyName?: string | null,
  newJobAddressLine1?: string | null,
  newJobAddressLine2?: string | null,
  newJobCityTown?: string | null,
  newJobRegion?: string | null,
  newJobPostalCode?: string | null,
  newJobTelephone?: string | null,
}
const JobLocationEvent: React.FC<JobLocationEventProps> = ({ ...location }) => {
  const oldLocation = summaryFieldValueHandle([
    location.oldJobCompanyName,
    location.oldJobAddressLine1,
    location.oldJobAddressLine2,
    location.oldJobCityTown,
    location.oldJobRegion,
    location.oldJobPostalCode,
    location.oldJobTelephone,
  ]);
  const newLocation = summaryFieldValueHandle([
    location.newJobCompanyName,
    location.newJobAddressLine1,
    location.newJobAddressLine2,
    location.newJobCityTown,
    location.newJobRegion,
    location.newJobPostalCode,
    location.newJobTelephone,
  ]);
  return (
    <>
      Field
      {' '}
      <b>Job location</b>
      {' '}
      was changed:
      {' '}
      {!!oldLocation && <s>{`${oldLocation}`}</s>}
      {!!newLocation && <b>{` ${newLocation}`}</b>}
    </>
  );
};

export default JobLocationEvent;
