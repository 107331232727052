import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { PricelistsViewEnum } from '../../enums/PricelistsViewEnum';
import { useAppSelector } from '../../../../store/hooks';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { PricelistDashFiltersType } from '../../types/pricelistTypes';
import { usePricelistDashFilters } from '../../hooks/usePricelistDashFilters';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';

type PricelistsFiltersProps = {
  view: string | number;
  setView: (value: string | number) => void;
  pricelistFilters: PricelistDashFiltersType;
}

const PricelistsFilters: React.FC<PricelistsFiltersProps> = ({ view, setView, pricelistFilters }) => {
  const { windowWidth } = useScreenWitdh();
  const currentUser = useAppSelector(currentUserSelector);

  const {
    searchValue,
    setSearchValue,
    filters,
    getFilteredPricelists,
    onClear,
    initDropdownSearch,
    keywordSearchParam,
    setInitSearchParams,
  } = usePricelistDashFilters();

  const tabs = useMemo(() => [
    {
      value: PricelistsViewEnum.DASHBOARD,
      label: PricelistsViewEnum.DASHBOARD,
      restrictedTo: PermissionEnum.PricelistsDashboardPage,
    },
    {
      value: PricelistsViewEnum.CREATE,
      label: PricelistsViewEnum.CREATE,
      restrictedTo: PermissionEnum.PricelistCreatePage,
    },
  ], []);
  const tabAllowed = useCallback((permission: PermissionEnum) => {
    return !!currentUser?.userContextRole?.permissions.includes(permission);
  }, [currentUser]);
  const allowedItems = useMemo(() => tabs.filter((el) => tabAllowed(el.restrictedTo)), [tabs, tabAllowed]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue && searchValue !== pricelistFilters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredPricelists({
          ...pricelistFilters, keyword: searchValue.trim(), page: 1,
        });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!searchValue?.trim() && pricelistFilters.keyword) {
        getFilteredPricelists({
          ...pricelistFilters, keyword: undefined, page: 1,
        });
      }
    }
    // eslint-disable-next-line
  }, [searchValue, pricelistFilters.keyword]);

  return (
    <>
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-sm">
          <Selectable
            options={allowedItems}
            value={view}
            onChange={(value) => setView(value as string)}
          />
        </div>
      )}
      <section className="priceListFilters sections-and-filters desktop-sticky-filters desktop-sticky-filters--top-sm">
        {windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedItems}
            value={view}
            onChange={(value) => setView(value)}
          />
        )}
        <KeywordFiltersSection
          searchValue={searchValue}
          setSearchValue={(v) => {
            if (keywordSearchParam) {
              setInitSearchParams();
            }
            setSearchValue(v);
          }}
          filters={filters}
          getFilteredResHandle={(value, field) => getFilteredPricelists({
            ...pricelistFilters, [field]: value, page: 1,
          })}
          onClear={onClear}
          initDropdownSearch={initDropdownSearch}
        />
      </section>
    </>
  );
};

export default PricelistsFilters;
