import React, { useEffect } from 'react';
import classNames from 'classnames';
import VirtualList from 'rc-virtual-list';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../checkbox/Checkbox';
import Select from '../select/Select';
import {
  DictionaryItem,
  GenericVoidFunctionType,
  StringFunctionType,
} from '../../types/coreTypes';
import EmptyTransferList from './EmptyTransferList';
import Spinner from '../spinner/Spinner';
import EmptyVehiclesList from '../../../stock/common/components/empty-vehicles-list/EmptyVehiclesList';
import { useAppSelector } from '../../../store/hooks';
import {
  sourceAvailableVehicleProfilesFiltersSelector,
  vehicleManufacturersDictionarySelector,
} from '../../../store/selectors/sharedSelectors';
import { useSelectAll } from '../../hooks/useSelectAll';
import InputWithButton from '../input-with-button/InputWithButton';

type TransferListProps = {
  searchValue: string,
  setSearchValue: StringFunctionType,
  searchHandle: StringFunctionType,
  isLoading: boolean,
  checkboxLabel?: string,
  isTarget?: boolean,
  searchPlaceholder?: string,
  list: DictionaryItem[],
  handleManufacturerChange?: StringFunctionType,
  allItemIds: number[],
  setIdsToTransfer: GenericVoidFunctionType<number[]>,
  disabledSearch?: boolean,
}

const TransferList: React.FC<TransferListProps> = ({
  searchValue,
  setSearchValue,
  searchHandle,
  isLoading,
  checkboxLabel, isTarget,
  searchPlaceholder,
  list,
  handleManufacturerChange,
  allItemIds,
  setIdsToTransfer,
  disabledSearch,
}) => {
  const { manufacturer } = useAppSelector(sourceAvailableVehicleProfilesFiltersSelector);
  const manufacturers = useAppSelector(vehicleManufacturersDictionarySelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    setIdsToTransfer(checkedKeys);
    // eslint-disable-next-line
  }, [checkedKeys]);

  return (
    <div className="transferSection__column">
      <InputWithButton
        className="transferSection__search"
        placeholder={searchPlaceholder}
        value={searchValue}
        onChange={(e) => {
          const { value } = e.target;
          if (!value.trim().length) {
            setSearchValue('');
            searchHandle('');
          } else if (value.trim().length <= 250) {
            setSearchValue(value);
          }
        }}
        disabled={disabledSearch}
        icon={<FontAwesomeIcon icon={faSearch} />}
        onPressEnter={() => searchHandle(searchValue.trim())}
        onClick={() => searchHandle(searchValue.trim())}
        buttonDisabled={!searchValue.trim().length}
      />
      <div className="transferItems">
        <div className="transferItems__head">
          <Checkbox
            table
            label={checkboxLabel}
            checked={isAll}
            indeterminate={isIndeterminate}
            onChange={checkAllHandle}
            disabled={!list.length}
          />
          {handleManufacturerChange && <Select
            options={manufacturers}
            value={manufacturer}
            onChange={(val) => handleManufacturerChange(val as string)}
            className="transferItems__head-filter"
            parentRender
          />}
        </div>
        <div className={classNames('transferItems__list', {
          'transferItems__list--empty': !list.length,
          'transferItems__list--loading': isLoading,
        })}
        >
          {isLoading && <Spinner global={false} />}
          {list && list.length > 0
            ? <VirtualList
              data={list}
              height={handleManufacturerChange ? 316 : 358}
              itemHeight={35}
              itemKey={({ value }) => value}
            >
              {((el) => <li className="transferItems__list-item">
                <Checkbox
                  label={el.label}
                  value={el.value}
                  checked={checkedKeys.includes(el.value)}
                  onChange={() => checkRowHandle(el.value)}
                  truncateLabel
                />
              </li>)}
            </VirtualList>
            : <>
              {isTarget && !searchValue
                ? <EmptyVehiclesList />
                : <EmptyTransferList />}
            </>}
        </div>
      </div>
    </div>
  );
};

export default TransferList;
