import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  rectificationReportFiltersSelector,
  rectificationReportsGlobalFiltersDateSelector,
  rectificationReportGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setRectificationReportData, setRectificationReportFilters, setReportIsVisible } from '../../../../../store/slices/reportsSlice';
import { RectificationReportFilters } from '../../../../types/RectificationReportsTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  DateRangeTableFilterType, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import { getRectificationFaultTypesThunk, getRectificationTypesThunk } from '../../../../../store/thunks/coreThunk';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { getSalesRectificationReportExcelThunk, getSalesRectificationReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { useRectificationReportDefaultPaging } from '../../../../hooks/useRectificationReportDefaultPaging';
import { initRectificationReportData } from '../../../reports-content/sales/rectification/utils/data';

const RectificationGlobalFilters = () => {
  const dispatch = useAppDispatch();
  const checkboxFilters = useAppSelector(rectificationReportGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(rectificationReportsGlobalFiltersDateSelector);
  const filters = useAppSelector(rectificationReportFiltersSelector);
  const globalFiltersNames = [...checkboxFilters, ...datePickerFilters].map((el) => el.name as keyof RectificationReportFilters);
  const isSelectedGlobalFilters = globalFiltersNames.some((field) => filters[field]);

  const [isExpanded, setIsExpanded] = useState(false);

  const defaultPagingAndOrder = useRectificationReportDefaultPaging();

  useEffect(() => {
    dispatch(getRectificationTypesThunk());
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getRectificationFaultTypesThunk());

    return () => {
      dispatch(setRectificationReportFilters({ ...initPaging, ...defaultPagingAndOrder }));
      dispatch(setRectificationReportData(initRectificationReportData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    completedDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: RectificationReportFilters = {
      ...filters,
      page: 1,
      [field]: values || undefined,
    };
    dispatch(setRectificationReportFilters(newFilters));
    dispatch(setRectificationReportData(initRectificationReportData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setRectificationReportFilters({
      ...defaultPagingAndOrder,
      pageSize: filters.pageSize,
    }));
    dispatch(setRectificationReportData(initRectificationReportData));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      costNet, salesNet, page, pageSize, ...restFilters
    } = filters;
    const { order } = defaultPagingAndOrder;
    dispatch(getSalesRectificationReportExcelThunk({
      filters: {
        ...restFilters,
        order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={(values, field) => {
          dispatch(setRectificationReportFilters({
            ...filters,
            page: 1,
            costNet: undefined,
            salesNet: undefined,
            [field]: values,
          }));
          dispatch(setRectificationReportData(initRectificationReportData));
          dispatch(setReportIsVisible(false));
        }}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getSalesRectificationReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default RectificationGlobalFilters;
