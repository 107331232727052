import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { EmployeeContentTabs, EmployeeViewModeLabelsEnum, EmployeeViewModeParamsEnum } from '../enums/EmployeeViewEnums';
import { useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { employeeDetailsSelector } from '../../../store/selectors/employeesSelector';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';

export const useEmployeeViewTabs = () => {
  const [params, setSearchParams] = useSearchParams();
  const currentUser = useAppSelector(currentUserSelector);
  const employeeDetails = useAppSelector(employeeDetailsSelector);

  const tabs = [
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_SKILLS,
      label: EmployeeViewModeLabelsEnum.EMPLOYEE_SKILLS,
    },
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_ICE_CONTACT,
      label: EmployeeViewModeLabelsEnum.EMPLOYEE_ICE_CONTACT,
    },
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_EMPLOYMENT,
      label: EmployeeViewModeLabelsEnum.EMPLOYEE_EMPLOYMENT,
    },
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_LEAVE_REQUESTS,
      label: EmployeeViewModeLabelsEnum.EMPLOYEE_LEAVE_REQUESTS,
    },
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_ACTIVITY,
      label: EmployeeViewModeLabelsEnum.EMPLOYEE_ACTIVITY,
    },
    {
      value: EmployeeViewModeParamsEnum.EMPLOYEE_ATTACHMENTS,
      label: `${EmployeeViewModeLabelsEnum.EMPLOYEE_ATTACHMENTS} ${tabCountHandle(employeeDetails?.employee.attachmentCount)}`,
    },
  ];

  useEffect(() => {
    if (currentUser) {
      const tabParam = params.get('tab');
      const { permissions } = currentUser.userContextRole;
      if (!tabParam && permissions.includes(PermissionEnum.EmployeeViewPage)) {
        setSearchParams({ tab: tabs[0].value }, { replace: true });
      } else {
        if (!EmployeeContentTabs.includes(tabParam as EmployeeViewModeParamsEnum)) {
          setSearchParams({ tab: EmployeeViewModeParamsEnum.EMPLOYEE_SKILLS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, tabs]);

  const setEmployeeTabParams = (tab: string) => {
    setSearchParams({ tab }, { replace: true });
  };

  return {
    tabs,
    params,
    setEmployeeTabParams,
  };
};
