import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  EnquiriesNewResponseType, EnquiryDto, EnquiryFiltersType,
} from '../../types/enquiryTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  editEnquiryThunk, editStatusToAcceptedThunk,
  getEnquiriesNewPortion,
} from '../../../../store/thunks/enquiry/enquiryThunks';
import { currentBusinessAreaSelector, isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';
import { CoreAttachListElType, DictionaryItem, VoidFunctionType } from '../../../../core/types/coreTypes';
import { columnsPaginationSelector } from '../../../../store/selectors/enquiriesSelector';
import { setEnquiryCollections } from '../../../../store/slices/enquiriesSlice';
import FinalizedSentModal from '../../../../common/components/entity-status-change-modals/FinalizedSentModal';
import RejectStatusModal from '../../../../common/components/entity-status-change-modals/RejectStatusModal';
import SentToAcceptedModal from '../../../../common/components/entity-status-change-modals/SentToAcceptedModal';
import { useEnquiryContainerSelectors } from '../../hooks/useEnquiryContainerSelectors';
import { useStatusTransition } from '../../hooks/useStatusTransition';
import DraggableCardsArea from '../../../../common/components/draggable-cards-column/DraggableCardsArea';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { routesPath } from '../../../../core/enums/pathEnum';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import usePermission from '../../../../permissions-handling/permissionHook';
import { enquiryRejectedStatusSelector } from '../../../../store/selectors/coreStatusesSelectors';
import DashboardSkeleton from '../../../../common/components/skeletons/dashboard-skeleton/DashboardSkeleton';
import { useDragEndDashboardCards } from '../../../../common/hooks/useDragAndDropDashboardCards';

type EnquiryColumnsProps = {
  cards: EnquiriesNewResponseType | null;
  setCards: (value: EnquiriesNewResponseType | null) => void;
  enquiryStatus: DictionaryItem[];
  dashFilters: EnquiryFiltersType;
}

const EnquiryColumns: React.FC<EnquiryColumnsProps> = ({
  cards, setCards, dashFilters, enquiryStatus,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const { enquiryCollections, warnings } = useEnquiryContainerSelectors();

  const allowedToEdit = usePermission(PermissionEnum.EnquiryEditFields);
  const dispatch = useAppDispatch();
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const columnsPagination = useAppSelector(columnsPaginationSelector);
  const enquiryRejectedStatus = useAppSelector(enquiryRejectedStatusSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  const {
    editStatus,
    setDefaultValues,
    isSentModal,
    setSentModal,
    isRejectModal,
    setRejectModal,
    isAcceptedModal,
    setAcceptedModal,
    enquiryTransit,
    setEnquiryTransit,
    transitVehicleCount,
    setTransitVehicleCount,
    isButtonDisabled,
    setButtonDisabled,
    columnsLoading,
    setColumnsLoading,
  } = useStatusTransition(
    enquiryStatus,
    enquiryCollections,
  );

  const currentFiltersState = [
    dashFilters?.assignedTo,
    dashFilters?.jobType,
    dashFilters?.warnings,
    dashFilters?.customerType,
    dashFilters?.priority,
  ];

  const setNewPortion = (status: number, data: Array<EnquiryDto>) => {
    const newArr = cards?.map((el) => {
      if (el.status === status) {
        return {
          ...el,
          list: [...el.list, ...data],
        };
      } else {
        return el;
      }
    });
    newArr && dispatch(setEnquiryCollections(newArr));
  };
  const getNewPortion = async (page: number, status: number) => {
    currentBusinessArea && dispatch(getEnquiriesNewPortion({
      filters: {
        ...dashFilters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: [status],
        page,
      },
      currentStatus: status,
      setNewPortion,
    }));
  };
  const reformCardData = (card: EnquiryDto) => {
    return {
      id: card.id,
      entityNumber: card.enquiryNumber,
      customerName: card.customerShortName,
      customerType: card.customerType,
      jobType: card.jobType,
      priorityLevel: card.priorityLevel,
      cost: card.estimatedCostString,
      assigneeFullName: card.assignedToFullName,
      isAssigneeDeleted: card.isAssigneeDeleted,
      warnings: card.warnings,
    };
  };
  const { onDragEnd } = useDragEndDashboardCards<EnquiryDto>(editStatus);

  return (
    <>
      <FinalizedSentModal
        isVisible={isSentModal}
        onCancel={() => {
          setSentModal(false);
          setCards(enquiryCollections);
          setDefaultValues();
        }}
        isButtonDisabled={isButtonDisabled}
        onConfirm={(status: number) => {
          setButtonDisabled(true);
          enquiryTransit && dispatch(editEnquiryThunk({
            id: enquiryTransit.id,
            statusTransition: status,
            sourceId: enquiryTransit.initStatus,
            setColumns: () => setCards(enquiryCollections),
            onClose: () => {
              setSentModal(false);
              setDefaultValues();
              setButtonDisabled(false);
            },
            setColumnsLoading,
          }));
        }}
      />
      <RejectStatusModal
        isVisible={isRejectModal}
        isButtonDisabled={isButtonDisabled}
        rejectionStatus={enquiryRejectedStatus?.value}
        onCancel={() => {
          setRejectModal(false);
          setCards(enquiryCollections);
          setDefaultValues();
        }}
        onConfirm={(status: number, reason: string, onCancel: VoidFunctionType) => {
          setButtonDisabled(true);
          enquiryTransit && dispatch(editEnquiryThunk({
            id: enquiryTransit.id,
            statusTransition: status,
            sourceId: enquiryTransit.initStatus,
            setColumns: () => setCards(enquiryCollections),
            rejectionReason: reason,
            onClose: () => {
              setRejectModal(false);
              setDefaultValues();
              onCancel && onCancel();
              setButtonDisabled(false);
            },
            setColumnsLoading,
          }));
        }}
      />
      <SentToAcceptedModal
        isVisible={isAcceptedModal}
        isButtonDisabled={isButtonDisabled}
        onCancel={() => {
          setAcceptedModal(false);
          setDefaultValues();
          setCards(enquiryCollections);
        }}
        vehiclesNumber={transitVehicleCount || 0}
        onConfirm={(
          list: CoreAttachListElType[],
          comment: string,
          cancelHandle: VoidFunctionType,
          status: number,
        ) => {
          setButtonDisabled(true);
          enquiryTransit && dispatch(editStatusToAcceptedThunk({
            id: enquiryTransit.id,
            statusTransition: status,
            setColumns: () => setCards(enquiryCollections),
            list,
            comment,
            cancelHandle: () => {
              cancelHandle();
              setAcceptedModal(false);
              setDefaultValues();
              setButtonDisabled(false);
            },
            navigateToCreatedOrder: (orderId: number) => {
              navigate(`/${routesPath.ORDERS}/${orderId}`);
              const locationObj = {
                pathname: location.pathname,
                search: location.search,
              };
              setLocationHistoryHandle(locationObj);
            },
          }));
        }}
      />
      {isLoading
        ? <DashboardSkeleton linkedEntities={0} hasTotalCost />
        : <DraggableCardsArea
          columnsPagination={columnsPagination}
          getNewPortion={getNewPortion}
          onDragEnd={onDragEnd}
          cards={cards}
          setCards={setCards}
          statusDictionary={enquiryStatus}
          dashFilters={dashFilters}
          currentFiltersState={currentFiltersState}
          reformCardData={(card: EnquiryDto) => reformCardData(card)}
          setCardTransit={setEnquiryTransit}
          setTransitVehicleCount={setTransitVehicleCount}
          noLinkedEntities
          permissionToSeeCardPage={PermissionEnum.EnquiryViewPage}
          isDragDisabled={!allowedToEdit}
          warnings={warnings}
          columnsLoading={columnsLoading}
        />}
    </>
  );
};

export default EnquiryColumns;
