import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../core/components/button/Button';
import { NumberFunctionType } from '../../../core/types/coreTypes';

type JobCalendarPaginationColProps = {
  onPageChange: NumberFunctionType,
  currentPage: number,
  totalPages: number,
}

const JobCalendarPaginationCol: React.FC<JobCalendarPaginationColProps> = ({ onPageChange, currentPage, totalPages }) => {
  return (
    <div className="jobCalendarPaginationCol">
      <Button
        icon={<FontAwesomeIcon icon={faArrowLeft} />}
        onClick={() => onPageChange(-1)}
        disabled={currentPage === 1}
      />
      <span>{`${currentPage} of ${totalPages}`}</span>
      <Button
        icon={<FontAwesomeIcon icon={faArrowRight} />}
        onClick={() => onPageChange(+1)}
        disabled={currentPage >= totalPages}
      />
    </div>
  );
};

export default JobCalendarPaginationCol;
