import React from 'react';
import classNames from 'classnames';

type JobLineExpandedDetailsFieldProps = {
  label: string,
  value: string,
  isWide: boolean,
}

const JobLineExpandedDetailsField: React.FC<JobLineExpandedDetailsFieldProps> = ({ label, value, isWide }) => {
  return (
    <div className={classNames('expandedField', { 'expandedField--wide': isWide })}>
      <span>{label}</span>
      <span className="expandedField__value">{value || '-'}</span>
    </div>
  );
};

export default JobLineExpandedDetailsField;
