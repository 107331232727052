import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { EMAIL_REGEX, NAME_REGEX } from '../../../core/utils/regex';

export const NameFieldSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
  pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
};

export const EmailFieldSchema = {
  ...RequiredFieldSchema,
  pattern: { value: EMAIL_REGEX, message: 'Please enter a valid email address.' },
};

export interface CreateUserFormFields {
  firstName: string,
  lastName: string,
  email: string,
  accountType?: number,
  role?: string,
  canSeeOnlyAssignedEntities?: number,
  allowedJobTypes?: number[],
  allowedBusinessAreas?: number[],
}

export interface UserDetailsFormFields extends CreateUserFormFields {
  employeeReferenceNumber: string | null,
}

type CreateUser = Pick<
CreateUserFormFields,
'firstName' | 'lastName' | 'email' | 'accountType' | 'allowedJobTypes' | 'allowedBusinessAreas'
>

export type CreateUserAccountRequest = CreateUser & {
  userAccountRole: {
    role: number,
    roleLevel: number,
  },
  canSeeOnlyAssignedEntities: boolean,
}

export type CreateUserAccountForEmployeeRequest = CreateUserAccountRequest & {
  employeeId?: number,
}

export const userDefaults = {
  firstName: '',
  lastName: '',
  email: '',
  accountType: undefined,
  role: undefined,
  canSeeOnlyAssignedEntities: undefined,
  allowedJobTypes: undefined,
  allowedBusinessAreas: undefined,
};
