import React from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { activateAccountThunk } from '../../../../store/thunks/settings/userViewPageThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';

type ActivateUserModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  userName: string,
  userId?: number,
};

const ActivateUserModal: React.FC<ActivateUserModalProps> = ({
  userName, onCancel, isVisible, userId,
}) => {
  const dispatch = useAppDispatch();
  const confirmHandler = () => {
    userId && dispatch(activateAccountThunk({
      id: +userId, userName, closeModal: () => onCancel(),
    }));
  };
  return (
    <ConfirmationModal
      customTitle="Activate account"
      isVisible={isVisible}
      onCancel={onCancel}
      confirmHandler={confirmHandler}
      customCreateLabel="Yes, activate"
      customText={<>
        You are about to activate
        {' '}
        <b>{userName}</b>
        {' '}
        user account.
      </>}
    />
  );
};

export default ActivateUserModal;
