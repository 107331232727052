import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import './CustomerLine.scss';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import Tag from '../../../../core/components/tag/Tag';
import { CustomerDto } from '../../types/customersDashboardTypes';
import { useAppSelector } from '../../../../store/hooks';
import { customerTypesSelector } from '../../../../store/selectors/coreSelectors';
import { CustomersStatusesEnum, CustomerTypeEnum, PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { customerStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';

type CustomerLineProps = {
  customer: CustomerDto,
}

const CustomerLine: React.FC<CustomerLineProps> = ({ customer }) => {
  const infoProps = [
    { label: 'Enquiries', value: customer.enquiriesAmount || 0 },
    { label: 'Orders', value: customer.ordersAmount || 0 },
    { label: 'Rectifications', value: customer.rectificationsAmount || 0 },
    { label: 'Jobs', value: customer.jobsAmount || 0 },
  ];
  const customerTypes = useAppSelector(customerTypesSelector);
  const customerStatuses = useAppSelector(customerStatusesSelector);
  const currentType = customerTypes.find((el) => el.value === customer.customerType)?.label;
  const shortType = currentType === CustomerTypeEnum.CORPORATE ? 'CORP' : 'IND';
  const currentStatus = customerStatuses.find((el) => el.value === customer.customerStatus)?.label;
  return (
    <PrivateLineLink
      to={`${customer.id}`}
      className="customerLine"
      permission={PermissionEnum.CustomerViewPage}
    >
      <span className="customerLine__title">
        <FontAwesomeIcon
          icon={currentStatus === CustomersStatusesEnum.Complete ? faClipboardCheck : faQuestionCircle}
          className="customerLine__icon"
        />
        <span className="customerLine__id">{customer.customerAccountNumber}</span>
        <span className="customerLine__name">{customer.name}</span>
      </span>
      <Tag textSize="md" className="customerLine__tag" label={shortType || ''} />
      <ul className="customerLine__info-list">
        {infoProps.map((prop, i) => <li key={i} className="customerLine__info-item">
          <span className="customerLine__info-label">{prop.label}</span>
          <span className="customerLine__info-value">{prop.value}</span>
        </li>)}
      </ul>
    </PrivateLineLink>
  );
};

export default CustomerLine;
