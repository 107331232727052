import React, { useEffect, useState } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faFileInvoice } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import { routesPath } from '../../../core/enums/pathEnum';
import { InvoicingPageParamsEnums } from '../../container/enums/InvoicingPageParamsEnums';
import Layout from '../../../core/components/layout/Layout';
import SalesViewPageHead from './SalesViewPageHead';
import SalesViewStaticSection from './SalesViewStaticSection';
import SalesViewDynamicSection from './SalesViewDynamicSection';
import {
  setSalesOrderPartRequestLines,
  setSalesInvoiceOrderLinesFilters,
  setSalesOrderDetails, setSalesOrderDetailsSummary,
  setSalesPartRequestLineSummary, setInvoicesAndCreditNotesFilters, setInvoicesAndCreditNotes,
} from '../../../store/slices/invoicingSlice';
import {
  changeOrderStatusThunk,
  getSalesOrderById,
  getSalesOrderPartRequestLinesThunk,
  getSalesOrderSummaryThunk,
} from '../../../store/thunks/invoicing/salesThunks';
import { SalesUrlParamsNames } from '../enums/salesEnums';
import {
  salesOrderDetailsSelector,
  salesOrderPartRequestLinesFiltersSelector,
} from '../../../store/selectors/invoicingSelectors';
import {
  getNominalCodeTypesThunk, getInvoiceDocumentTypesThunk, getNominalRecordStates, getNominalRecordTypes,
} from '../../../store/thunks/coreThunk';
import { getPurchaseOrderPartVat } from '../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import SalesOrderActivityLogModal from './activity-log/SalesOrderActivityLogModal';
import { getInvoiceDocumentStatusesThunk } from '../../../store/thunks/core/coreStatusesThunks';
import { initDataList, initPaging } from '../../../core/types/coreTypes';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { orderStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';

const SalesViewPage: React.FC = () => {
  const { id } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();

  const { backHandle } = useLocationHistoryState(true);
  const salesOrderDetails = useAppSelector(salesOrderDetailsSelector);
  const orderLinesFilters = useAppSelector(salesOrderPartRequestLinesFiltersSelector);
  const { completed } = useAppSelector(orderStatusesValuesSelector);

  const [isActivityModal, setIsActivityModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const setTabParam = (tabParam: string) => {
    // TODO: change to 'mode'?
    setSearchParams({ tab_type: tabParam }, { replace: true });
  };

  const actions = [
    {
      label: 'Activity log',
      key: 'activity-log',
      icon: <FontAwesomeIcon icon={faChartLine} />,
      onClick: () => setIsActivityModal(true),
      restrictedTo: PermissionEnum.OrderViewPage,
    },
    {
      label: 'Mark as Invoiced',
      key: 'mark-as-invoiced',
      icon: <FontAwesomeIcon icon={faFileInvoice} />,
      onClick: () => setConfirmationModal(true),
      restrictedTo: PermissionEnum.StockPurchaseOrdersViewPage,
      disabled: !completed || !salesOrderDetails?.canBeMarkedAsInvoiced,
    },
  ];

  const handleMarkAsInvoiced = () => {
    id && completed && dispatch(changeOrderStatusThunk({
      orderId: +id,
      statusTransition: completed,
      onFinish: () => setConfirmationModal(false),
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getSalesOrderById({ id: +id }));
      dispatch(getSalesOrderSummaryThunk({ id: +id }));
    }
    // eslint-disable-next-line
    }, [id, params]);

  useEffect(() => {
    const orderPartRequestId = salesOrderDetails?.order.partRequestId;
    orderPartRequestId && dispatch(getSalesOrderPartRequestLinesThunk({
      partRequestId: orderPartRequestId,
      filters: orderLinesFilters,
    }));
    // eslint-disable-next-line
    }, [salesOrderDetails?.order.partRequestId, orderLinesFilters]);

  useEffect(() => {
    dispatch(getNominalRecordTypes());
    dispatch(getNominalRecordStates());
    dispatch(getInvoiceDocumentStatusesThunk());
    dispatch(getPurchaseOrderPartVat());
    dispatch(getInvoiceDocumentTypesThunk());
    dispatch(getNominalCodeTypesThunk());
    return () => {
      dispatch(setSalesOrderDetails(null));
      dispatch(setSalesOrderDetailsSummary(null));
      dispatch(setSalesPartRequestLineSummary(null));
      dispatch(setSalesInvoiceOrderLinesFilters(undefined));
      dispatch(setSalesOrderPartRequestLines(null));
      dispatch(setInvoicesAndCreditNotesFilters(initPaging));
      dispatch(setInvoicesAndCreditNotes(initDataList));
    };
    // eslint-disable-next-line
    }, []);
  return (
    <Layout subHeader={
      <DetailsHead
        goBack={() => backHandle(`/${routesPath.INVOICING}?${SalesUrlParamsNames.PAGE}=${InvoicingPageParamsEnums.SALES}`)}
        actions={actions}
        className="salesViewPageDetailsHead"
      >
        <SalesViewPageHead />
      </DetailsHead>
    }
    >
      <>
        <SalesOrderActivityLogModal isVisible={isActivityModal} onCancel={() => setIsActivityModal(false)} />
        <ConfirmationModal
          customTitle="Confirm"
          customText="The order will be marked as invoiced."
          isVisible={confirmationModal}
          onCancel={() => setConfirmationModal(false)}
          confirmHandler={handleMarkAsInvoiced}
          customCreateLabel="Yes, mark as invoiced"
          isProceedQuestion
        />
        <SalesViewStaticSection
          tabParam={params.get(SalesUrlParamsNames.TAB_TYPE)}
          setTabParam={setTabParam}
        />
        <SalesViewDynamicSection tabParam={params.get(SalesUrlParamsNames.TAB_TYPE)} />
      </>
    </Layout>
  );
};

export default SalesViewPage;
