import React from 'react';
import { PermissionEnum } from '../core/enums/dictionariesEnums';

type PermissionContextType = {
  isAllowedTo: (permission: PermissionEnum) => boolean | undefined;
}

// Default behaviour for the Permission Provider Context
// i.e. if for whatever reason the consumer is used outside of a provider.
// The permission will not be granted unless a provider says otherwise
const defaultBehaviour: PermissionContextType = {
  isAllowedTo: () => undefined,
};

// Create the context
const PermissionContext = React.createContext<PermissionContextType>(defaultBehaviour);

export default PermissionContext;
