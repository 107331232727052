import { DropResult } from 'react-beautiful-dnd';
import { GenericColumnType } from '../../core/types/coreTypes';

type HookColumnsType<T> = GenericColumnType<T[]>[] | null;

export const useDragEndDashboardCards = <R, >(
  editHandler: (result: DropResult, setColumns: (value: HookColumnsType<R>) => void) => void,
) => {
  const onDragEnd = async (
    result: DropResult,
    columns: HookColumnsType<R>,
    setColumns: (value: HookColumnsType<R>) => void,
  ): Promise<void> => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId !== destination.droppableId) {
      const sourceColumn = columns?.find((c) => +source.droppableId === c.status);
      const destColumn = columns?.find((c) => +destination.droppableId === c.status);
      const sourceItems = sourceColumn ? [...sourceColumn.list] : [];
      const destItems = destColumn ? [...destColumn.list] : [];
      const [removed] = sourceItems.splice(source.index, 1);
      destItems.splice(destination.index, 0, removed);
      const newArr = columns?.map((el) => {
        if (el.status === +source.droppableId) {
          return {
            ...el,
            list: sourceItems,
          };
        }
        if (el.status === +destination.droppableId) {
          return {
            ...el,
            list: destItems.map((child) => ({ ...child, status: el.status })),
          };
        } else return el;
      });
      if (newArr) {
        setColumns(newArr);
        editHandler(result, setColumns);
      }
    }
  };
  return { onDragEnd };
};
