import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum SettingsViewsEnums {
  USERS = 'Users list',
  PERMISSIONS = 'Permissions',
  CREATE = 'Create new user',
  INFO_MESSAGES = 'Info messages'
}

export enum SettingsPageParamEnum {
  USERS = 'users-list',
  PERMISSIONS = 'permissions',
  CREATE = 'create-new-user',
  INFO_MESSAGES = 'info-messages'
}

export const SettingsPageParams = [
  SettingsPageParamEnum.USERS,
  SettingsPageParamEnum.PERMISSIONS,
  SettingsPageParamEnum.CREATE,
  SettingsPageParamEnum.INFO_MESSAGES,
];

export const SettingsPageParamsAndPermissions = [
  { page: SettingsPageParamEnum.USERS, permission: PermissionEnum.UserListPage },
  { page: SettingsPageParamEnum.PERMISSIONS, permission: PermissionEnum.UserPermissionsPage },
  { page: SettingsPageParamEnum.CREATE, permission: PermissionEnum.UserCreatePage },
  { page: SettingsPageParamEnum.INFO_MESSAGES, permission: PermissionEnum.SettingsInfoMessagePage },
];

export enum UserAccountTypeEnum {
  INT = 'internal',
  EXT = 'external'
}

export enum UserAccountStatusEnum {
  ACTIVE = 'active',
  INACTIVE = 'inactive'
}

export enum UsersListOrdering {
  Name = 'Name',
  Email = 'Email',
  FirstName = 'FirstName',
  LastName = 'LastName',
  AccountType = 'AccountType',
  Role = 'Role',
  ViewAssignedOnly = 'ViewAssignedOnly',
  RefNo = 'RefNo',
  Status = 'Status',
  CreatedAt = 'CreatedAt',
}
