export const summaryFieldValueHandle = (fields: Array<string | number | null | undefined>, emptyStr?: string) => {
  const notEmptyArr = fields.filter((el) => el && typeof el !== 'number' && !!el.trim());
  let finalString = '';
  for (let i = 0; i < notEmptyArr.length; i++) {
    if (i !== notEmptyArr.length - 1) {
      finalString += `${notEmptyArr[i]}, `;
    } else {
      finalString += `${notEmptyArr[i]}`;
    }
  }
  const emptyMock = emptyStr || '';
  return finalString || emptyMock;
};
