import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DictionaryResponse,
} from '../../../core/types/coreTypes';
import { coreOrderingAPI } from '../../../api/core/coreOrderingApi';
import { setLoading } from '../../slices/coreSlice';

export const getCustomerContactsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/CustomerContactsOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreOrderingAPI.fetchCustomerContactsOrdering();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAttachmentsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/AttachmentOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchAttachmentsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/InAppNotificationsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreOrderingAPI.fetchInAppNotificationsOrdering();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestLinesOrdering = createAsyncThunk<DictionaryResponse>(
  'get/StockPartRequestLinesOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchStockPartRequestLinesOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getVehiclesOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehiclesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchVehiclesOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getFitmentTimeReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/FitmentTimeReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchFitmentTimeReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getRectificationReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchRectificationReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getRectificationByProductOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationByProductReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchRectificationByProductReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getRectificationsBySupplierOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationBySupplierReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchRectificationBySupplierReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getNumberOfConvertedVehiclesReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/NumberOfConvertedVehiclesReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchNumberOfConvertedVehiclesReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSalesOffsiteReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SalesOffsiteReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchSalesOffsiteReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSalesOfficeEmployeeReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SalesOfficeEmployeeReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchSalesOfficeEmployeeReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSalesByCustomerReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SalesReportByCustomerOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchSalesByCustomerReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSoldProductsByCustomerReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SoldProductsByCustomerReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchSoldProductsByCustomerReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getSupplierSpendReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SupplierSpendReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchSupplierSpendReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getFullStockReportOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/FullStockReportOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchFullStockReportOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartSubcategoryOrderingThunk = createAsyncThunk<DictionaryResponse
>(
  'get/PartSubcategoriesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPartSubcategoryOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartAffectedCategoryOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/PartAffectedCategoryOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPartAffectedCategoryOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleProfilesOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleProfilesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchVehicleProfileOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleChecksOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleChecksOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchVehicleChecksOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getLeaveRequestOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/LeaveRequestOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchLeaveRequestOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAffectedEmployeeOrdering = createAsyncThunk<DictionaryResponse>(
  'get/AffectedEmployeeOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchAffectedEmployeeOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalCodesOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/NominalCodesOrdering',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchNominalCodesOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSkillsOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/SkillsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchSkillsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistPartsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/getPricelistPartsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPricelistPartsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistAvailablePartsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PricelistAvailablePartsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPricelistAvailablePartsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistPricesOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PricelistPricesOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreOrderingAPI.fetchPricelistPricesOrdering();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuantitiesOrdering = createAsyncThunk<DictionaryResponse>(
  'get/QuantitiesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchQuantitiesOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/StockPartsOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreOrderingAPI.fetchStockPartsOrdering();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsKitsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/StockPartsKitsOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreOrderingAPI.fetchStockPartsKitOrdering();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartReqQuantitiesOrdering = createAsyncThunk<DictionaryResponse>(
  'get/StockPartReqQuantitiesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreOrderingAPI.fetchQuantitiesOrdering();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderPartsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderPartsOrdering',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreOrderingAPI.fetchPurchaseOrderPartsOrdering();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitComponentsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PartsKitComponentsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPartsKitComponentsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getHolidaysOrdering = createAsyncThunk<DictionaryResponse>(
  'get/HolidaysOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchHolidaysOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getConfirmedPartRequestsOrdering = createAsyncThunk<DictionaryResponse>(
  'get/ConfirmedPartRequestsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchConfirmedPartRequestsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUnfinishedEntitiesOrdering = createAsyncThunk<DictionaryResponse>(
  'get/UnfinishedEntitiesOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchUnfinishedEntitiesReportOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingOutstandingOverduePurchaseOrdersReportOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PurchasingOutstandingOverduePurchaseOrdersReportOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPurchasingOutstandingOverduePurchaseOrdersReportOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingCancelledLinesReportOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PurchasingCancelledLinesReportOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchCancelledLinesReportOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingFitterReturnsReportOrdering = createAsyncThunk<DictionaryResponse>(
  'get/PurchasingFitterReturnsReportOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchFitterReturnsReportOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInfoMessagesOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/InfoMessagesOrderingThunk',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchInfoMessagesOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserAccountsOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/UserAccountsOrderingThunk',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchUserAccountsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryQuoteLinesOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/EnquiryQuoteLinesOrderingThunk',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchEnquiryQuoteLinesOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryTimelineQuoteLineGroupOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/EnquiryTimelineQuoteLineGroupOrderingThunk',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchEnquiryTimelineQuoteLineGroupOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistsOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/PricelistsOrderingThunk',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchPricelistsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalRecordsOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/NominalRecordsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchNominalRecordsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentsOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/InvoiceDocumentsOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchInvoiceDocumentsOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderListOrderingThunk = createAsyncThunk<DictionaryResponse>(
  'get/OrderListOrdering',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreOrderingAPI.fetchOrderListOrdering();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
