import React from 'react';
import { AutoComplete } from 'antd';
import classNames from 'classnames';
import { CustomAny, FocusEventHandler, StringFunctionType } from '../../types/coreTypes';
import './Autocomplete.scss';

type AutocompleteComponentProps = {
  label: string,
  value?: string | number | null,
  // eslint-disable-next-line max-len
  onChange?: (value: (string | number), option: ({label: string, value: string | number} | {label: string, value: string | number}[])) => void,
  onBlur?: FocusEventHandler<HTMLElement>,
  options?: { label: string, value: string | number }[],
  onSearch?: StringFunctionType,
  error?: string,
  disabled?: boolean,
  className?: string,
  placeholder?: string,
  getPopupContainer?: (props: CustomAny) => HTMLElement,
  parentRender?: boolean,
}

const AutocompleteComponent: React.FC<AutocompleteComponentProps> = ({
  label, value, onChange, error, disabled, onBlur,
  className, placeholder, options, onSearch, getPopupContainer, parentRender,
}) => {
  return (
    <div className={classNames(className, 'autocompleteCustom')}>
      {label && <span className="autocompleteCustom__label">{label}</span>}
      <AutoComplete
        value={value}
        options={options}
        onSearch={(value) => {
          onSearch && onSearch(value);
        }}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        disabled={disabled}
        getPopupContainer={parentRender ? (trigger) => trigger.parentElement : getPopupContainer}
        className="autocompleteCustom__field"
      />
      {error && <span className="autocompleteCustom__error">{error}</span>}
    </div>
  );
};

export default AutocompleteComponent;
