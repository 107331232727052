import React from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTruck } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../core/components/button/Button';
import { VoidFunctionType } from '../../../core/types/coreTypes';

type EmptySelectedVehicleProfileProps = {
  onClick?: VoidFunctionType,
  large?: boolean,
  bordered?: boolean,
  disabled?: boolean,
  className?: string,
}
const EmptySelectedVehicleProfile: React.FC<EmptySelectedVehicleProfileProps> = ({
  onClick, large, bordered, disabled, className,
}) => {
  return (
    <div className={classNames(
      'emptySelectedVehicleProfile',
      {
        'emptySelectedVehicleProfile--large': large,
        'emptySelectedVehicleProfile--disabled': disabled,
        'emptySelectedVehicleProfile--bordered': bordered,
      },
      className,
    )}
    >
      <FontAwesomeIcon icon={faTruck} className="emptySelectedVehicleProfile__icon" />
      <span className="emptySelectedVehicleProfile__text">Please select a vehicle profile</span>
      <Button
        label="Select profile"
        icon={<FontAwesomeIcon icon={faSearch} />}
        onClick={onClick}
        disabled={disabled}
      />
    </div>
  );
};

export default EmptySelectedVehicleProfile;
