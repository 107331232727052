import React from 'react';
import { PurchaseOrderEventDtoBase } from '../../../../types/purchaseOrderViewPageTypes';
import { PurchaseOrderEventTypesEnum } from '../../../../enums/PurchaseOrderViewEnum';
import { purchaseOrderPartFailReasonSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppSelector } from '../../../../../../../store/hooks';
import { QuoteUnitsOfMeasureSelector } from '../../../../../../../store/selectors/coreSelectors';
import { fieldNameEventHandle } from '../../../../../../../common/utils/activityLogUtils';

type PurchaseOrderPartEventsProps = {
  eventName: string,
  item: PurchaseOrderEventDtoBase,
}

const PurchaseOrderPartEvents: React.FC<PurchaseOrderPartEventsProps> = ({ eventName, item }) => {
  const failReasons = useAppSelector(purchaseOrderPartFailReasonSelector);
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);

  const renderTextContent = () => {
    switch (eventName) {
      case PurchaseOrderEventTypesEnum.PurchaseOrderPartCreation:
      case PurchaseOrderEventTypesEnum.PurchaseOrderPartDeletion:
        return <>
          Part
          {' '}
          <b>{item.purchaseOrderPartName}</b>
          {' '}
          was
          {' '}
          {eventName === PurchaseOrderEventTypesEnum.PurchaseOrderPartDeletion ? 'deleted from' : 'added to'}
          {' '}
          a purchase order
        </>;
      case PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityCreation:
      case PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityDeletion:
        return <>
          A record with
          {' '}
          {item.quantity}
          {' '}
          {unitOfMeasures.find((el) => el.value === item.unitOfMeasure)?.label || ''}
          {' '}
          of part
          {' '}
          {item.purchaseOrderPartName}
          {' '}
          was
          {' '}
          {eventName === PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityCreation
            ? 'added to'
            : 'removed from'}
          {' '}
          <b>Returned to supplier or cancelled</b>
          {' '}
          section
          {eventName === PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityCreation && <>
            {' '}
            with the following reason:
            {' '}
            <b>{failReasons.find((el) => el.value === item.reason)?.label || item.reason}</b>
          </>}
        </>;
      default: return <>
        Field
        {' '}
        <b>{fieldNameEventHandle(item.field)}</b>
        {' '}
        was changed in part
        {' '}
        {item.purchaseOrderPartName}
        :
        {!!item.oldValue && <s>{` ${item.oldValue}`}</s>}
        {!!item.newValue && <b>{` ${item.newValue}`}</b>}
      </>;
    }
  };
  return (
    <div>{renderTextContent()}</div>
  );
};

export default PurchaseOrderPartEvents;
