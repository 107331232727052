import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { setVehiclesList, setVehiclesListUniqueStatuses } from '../../../store/slices/vehiclesSlice';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { VehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { getVehiclesListThunk } from '../../../store/thunks/vehicles/vehiclesDashboardThunks';
import {
  initDataList, NumberFunctionType, SearchTableFilterType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import Thead from '../../../core/components/table-components/Thead';
import { vehiclesListLinkVehicleTableSelector } from '../../../store/selectors/vehiclesSelectors';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import TableLoader from '../../../core/components/table-components/TableLoader';
import Modal from '../../../core/components/modal/Modal';
import Pagination from '../../../core/components/pagination/Pagination';
import LinkVehicleModalColumns from './LinkVehicleModalColumns';
import LinkVehicleModalBody from './LinkVehicleModalBody';
import './LinkVehicleModal.scss';

type LinkVehicleModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  selectNewVehicleFn: NumberFunctionType,
  initVehiclesListReqFilters: VehiclesDashboardFilters,
  currentVehicleId?: number | null,
}

const LinkVehicleModal: React.FC<LinkVehicleModalProps> = ({
  visible, onCancel, selectNewVehicleFn, initVehiclesListReqFilters, currentVehicleId,
}) => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector(vehiclesListLinkVehicleTableSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const [error, setError] = useState<string>('');
  const [statusesFilter, setStatusesFilter] = useState<number[]>([]);

  const [filters, setFilters] = useState<VehiclesDashboardFilters>(initVehiclesListReqFilters);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    manufacturer: undefined,
  });

  const fetchVehicles = (filters: VehiclesDashboardFilters) => {
    dispatch(getVehiclesListThunk({ filters, basicSearchParams: true }));
  };
  const searchTriggerFn = () => {
    const values = { ...filters, keyword: filters.keyword?.trim(), page: 1 };
    fetchVehicles(values);
  };

  const filtersHandle = (values: VehiclesDashboardFilters) => {
    const newValues = { ...values, page: values.page && values.page > 1 ? values.page : 1 };
    setFilters(newValues);
    fetchVehicles(newValues);
  };

  const cancelHandle = () => {
    error && setError('');
    dispatch(setVehiclesList(initDataList));
    dispatch(setVehiclesListUniqueStatuses([]));
    setFilters(initVehiclesListReqFilters);
    setSearchVl({ manufacturer: undefined });
    setStatusesFilter([]);
    onCancel();
  };

  useEffect(() => {
    if (!visible) {
      cancelHandle();
    }
    // eslint-disable-next-line
  }, [visible]);
  return (
    <Modal
      title="Link vehicle"
      visible={visible}
      onCancel={cancelHandle}
      width={1100}
      destroyOnClose
    >
      <section>
        <InputWithButton
          value={filters.keyword}
          onChange={(e) => {
            const val = e.target.value;
            if (!val.trim()) {
              dispatch(setVehiclesList(initDataList));
              setFilters(initVehiclesListReqFilters);
              setStatusesFilter([]);
            } else {
              if (val.length > 100) {
                setError(maxLengthMessageHandle(100));
              } else {
                error && setError('');
              }
              setFilters({ ...filters, keyword: val });
            }
          }}
          onPressEnter={() => {
            const searchValue = filters.keyword;
            if (!searchValue?.trim()) {
              setError(ErrorsEnum.REQUIRED);
              setFilters(initVehiclesListReqFilters);
              setStatusesFilter([]);
            } else {
              searchValue?.trim() && !error && searchTriggerFn();
              setFilters({ ...filters, page: 1 });
            }
          }}
          onClick={() => {
            const searchValue = filters.keyword;
            searchValue?.trim() && !error && searchTriggerFn();
          }}
          placeholder="Search by model description, registration number or chassis number"
          icon={<FontAwesomeIcon icon={faSearch} />}
          error={error}
          offsetBottom
        />
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          onChange={(page, pageSize) => {
            filtersHandle({ ...filters, page, pageSize });
          }}
          totalItems={totalCount}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          offsetBottom
        />
        <TableNew>
          <Thead>
            <LinkVehicleModalColumns
              statusesFilter={statusesFilter}
              setStatusesFilter={(values) => {
                setStatusesFilter(values);
                setFilters({ ...filters, statuses: values, page: 1 });
              }}
              fetchVehicles={fetchVehicles}
              filters={filters}
              totalCount={totalCount}
              searchVl={searchVl}
              setSearchVl={setSearchVl}
              filtersHandle={filtersHandle}
            />
          </Thead>
          {isGlobalLoading
            ? <TableLoader colspan={7} />
            : <LinkVehicleModalBody
              filters={filters}
              selectNewVehicleFn={(vehId) => selectNewVehicleFn(vehId)}
              currentVehicleId={currentVehicleId}
            />}
        </TableNew>
      </section>
    </Modal>
  );
};

export default LinkVehicleModal;
