import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faCubes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Tr from './Tr';
import TdNew from './table-new/TdNew';

type EmptyTableSectionProps = {
  text: string,
  colSpan?: number,
  icon?: IconDefinition,
  size?: 'lg',
}

const EmptyTableSection: React.FC<EmptyTableSectionProps> = ({
  text, colSpan = 5, icon = faCubes, size,
}) => {
  return (
    <Tr className="emptyTableSection">
      <TdNew alignCenter colSpan={colSpan}>
        <div className={classNames('partsTable__empty', { 'partsTable__empty--lg': size === 'lg' })}>
          <FontAwesomeIcon className="partsTable__empty-icon" icon={icon} />
          <span className="partsTable__empty-text">{text}</span>
        </div>
      </TdNew>
    </Tr>
  );
};

export default EmptyTableSection;
