import React from 'react';
import { BasicNoteEventProps } from '../../../../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import NoteEvent from '../../../../../../../common/components/activity-events/note/NoteEvent';
import {
  deletePurchaseOrderNoteThunk,
  editPurchaseOrderNoteThunk,
} from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { useNoteActivityLog } from '../../../../../../../common/hooks/useNoteActivityLog';

const PurchaseOrderNoteEvent: React.FC<BasicNoteEventProps> = ({ id, content = '', initiator = '' }) => {
  const {
    dispatch,
    value,
    setValue,
    isEditModal,
    isConfirmationModal,
    setIsConfirmationModal,
    closeHandle,
    menuItems,
  } = useNoteActivityLog(content);

  const editHandle = () => {
    id && dispatch(editPurchaseOrderNoteThunk({
      id,
      content: value.trim(),
      closeModal: closeHandle,
    }));
  };

  const deleteHandle = () => {
    id && dispatch(deletePurchaseOrderNoteThunk({
      id,
      closeModal: () => setIsConfirmationModal(false),
    }));
  };

  return (
    <NoteEvent
      content={content}
      menuItems={menuItems}
      value={value}
      setValue={setValue}
      isEditModal={isEditModal}
      closeEditModal={closeHandle}
      editHandle={editHandle}
      isConfirmationModal={isConfirmationModal}
      setIsConfirmationModal={setIsConfirmationModal}
      deleteHandle={deleteHandle}
      initiator={initiator}
      permission={PermissionEnum.StockPurchaseOrderEditDeleteNotesOfOtherUsersInActivityLog}
    />
  );
};

export default PurchaseOrderNoteEvent;
