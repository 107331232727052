import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from '../button/Button';
import DrawerMessage from './DrawerMessage';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { inAppNotificationsFiltersSelector, inAppNotificationsSelector } from '../../../store/selectors/accountSelectors';
import { BooleanFunctionType, VoidFunctionType } from '../../types/coreTypes';
import { setInAppNotificationsFilters } from '../../../store/slices/accountSlice';
import { changeAllNotificationsStatusThunk } from '../../../store/thunks/account/accountThunks';
import { inAppReadStatusSelector, inAppUnreadStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

type InAppNotificationsContentProps = {
  setIsClearAllMessages: BooleanFunctionType,
  onClose: VoidFunctionType,
}

const InAppNotificationsContent: React.FC<InAppNotificationsContentProps> = ({ setIsClearAllMessages, onClose }) => {
  const dispatch = useAppDispatch();
  const { items, pages, totalCount } = useAppSelector(inAppNotificationsSelector);
  const inAppReadStatus = useAppSelector(inAppReadStatusSelector);
  const inAppUnreadStatus = useAppSelector(inAppUnreadStatusSelector);
  const inAppNotificationsFilters = useAppSelector(inAppNotificationsFiltersSelector);

  const loadMore = () => {
    const newValues = { ...inAppNotificationsFilters, page: inAppNotificationsFilters.page + 1 };
    dispatch(setInAppNotificationsFilters(newValues));
  };

  const markAllAsStatusHandler = () => {
    const allRead = items.every((el) => el.readDate);
    if (allRead) {
      inAppUnreadStatus && dispatch(changeAllNotificationsStatusThunk({
        status: inAppUnreadStatus,
        closeDrawer: onClose,
      }));
    } else {
      inAppReadStatus && dispatch(changeAllNotificationsStatusThunk({
        status: inAppReadStatus,
        closeDrawer: onClose,
      }));
    }
  };
  return (
    <>
      <div className="drawerActions">
        <Button
          label={items.every((el) => el.readDate) ? 'Marks all as Unread' : 'Marks all as Read'}
          type="text"
          onClick={() => markAllAsStatusHandler()}
        />
        <Button
          label="Clear all"
          type="text"
          onClick={() => setIsClearAllMessages(true)}
        />
      </div>
      <div id="notificationsWrapperDiv" className="drawer-list-wrapper">
        <InfiniteScroll
          dataLength={items.length}
          next={loadMore}
          hasMore={inAppNotificationsFilters.page < pages}
          loader={<span className="contacts__loader">Loading...</span>}
          scrollableTarget="notificationsWrapperDiv"
        >
          {totalCount > 0 && <ul className="drawer-list">
            {items.map((n) => <DrawerMessage key={n.notificationId} message={n} closeDrawer={onClose} className="drawer-list__item" />)}
          </ul>}
        </InfiniteScroll>
      </div>
    </>
  );
};

export default InAppNotificationsContent;
