import React from 'react';

type DeletionEventProps = {
  entity: string,
}

const DeletionEvent: React.FC<DeletionEventProps> = ({ entity }) => {
  return <>{`${entity} was deleted`}</>;
};

export default DeletionEvent;
