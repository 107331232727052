import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { useAppSelector } from '../../../../store/hooks';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import { StockPageParamEnums } from '../../enums/stockEnums';
import { StringFunctionType, TabOptionType } from '../../../../core/types/coreTypes';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import {
  PurchaseUrlParamsNames,
} from '../../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { dashOptions } from '../../utils/data';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import PartsKitsFiltersContent from './PartsKitsFiltersContent';
import PurchaseOrdersFiltersContent from './PurchaseOrdersFiltersContent';
import PartRequestsFiltersContent from './PartRequestsFiltersContent';

type StockFiltersProps = {
  view: string | null;
  setView: (value: string) => void;
  purchaseTabType: string | null;
  partRequestsTabType: string | null;
  setPurchaseTabTypeParam: StringFunctionType;
  setStockPartReqTabTypeParam: StringFunctionType;
}

const StockFilters: React.FC<StockFiltersProps> = ({
  view, setView, purchaseTabType, partRequestsTabType,
  setPurchaseTabTypeParam, setStockPartReqTabTypeParam,
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const location = useLocation();
  const { windowWidth } = useScreenWitdh();
  const currentUser = useAppSelector(currentUserSelector);

  const [tabs, setTabs] = useState<TabOptionType[]>([]);

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const allowedActions = dashOptions.filter((act) => permissions.includes(act.restrictedTo));
      setTabs(allowedActions);

      if (!view) {
        params.append(PurchaseUrlParamsNames.PAGE, allowedActions[0].value);
        navigate({ search: `?${PurchaseUrlParamsNames.PAGE}=${allowedActions[0].value}` }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [currentUser, view]);

  const setViewHandle = (value: string) => {
    if (value === StockPageParamEnums.CREATE_PART) {
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
      navigate('/create-part');
    } else {
      setView(value);
    }
  };
  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG_L && (
        <div className="stockFiltersMobile mobile-sticky-filters mobile-sticky-filters--top-sm">
          <Selectable
            options={tabs}
            value={view || ''}
            onChange={(value) => {
              setViewHandle(value as string);
            }}
          />
          {view === StockPageParamEnums.PURCHASES && <PurchaseOrdersFiltersContent
            screenSize="sm"
            purchaseTabType={purchaseTabType}
            setPurchaseTabTypeParam={setPurchaseTabTypeParam}
          />}
          {view === StockPageParamEnums.PART_REQUESTS && <PartRequestsFiltersContent
            screenSize="sm"
            partReqTabType={purchaseTabType}
            setStockPartReqTabTypeParam={setStockPartReqTabTypeParam}
          />}
        </div>
      )}
      <section className={classNames(
        'stockFiltersTabs sections-and-filters desktop-sticky-filters desktop-sticky-filters--top-sm',
        { 'partsKitView': view === StockPageParamEnums.PARTS_KITS },
      )}
      >
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG_L && (
          <>
            <Segmented
              options={tabs}
              value={view || ''}
              onChange={(value) => setViewHandle(value as string)}
            />
            {view === StockPageParamEnums.PURCHASES && <PurchaseOrdersFiltersContent
              purchaseTabType={purchaseTabType}
              setPurchaseTabTypeParam={setPurchaseTabTypeParam}
            />}
            {view === StockPageParamEnums.PART_REQUESTS && <PartRequestsFiltersContent
              partReqTabType={partRequestsTabType}
              setStockPartReqTabTypeParam={setStockPartReqTabTypeParam}
            />}
          </>
        )}
        {view === StockPageParamEnums.PARTS_KITS && <PartsKitsFiltersContent />}
      </section>
    </>
  );
};

export default StockFilters;
