import React, { useEffect, useState } from 'react';
import {
  useLocation, useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import './EnquiryDeatailsContainer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCopy, faEnvelope, faFileExcel, faFilePdf, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../core/components/layout/Layout';
import EnquiryHead from './components/enquiry-head/EnquiryHead';
import EnquiryFilters from './components/enquiry-filters/EnquiryFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  detailsFiltersSelector,
  enquiryContactsPagingSelector, enquiryContactsSelector,
  enquiryDetailsSelector,
} from '../../store/selectors/enquiriesSelector';
import EnquiryContent from './components/enquiry-content/EnquiryContent';
import { setDetailsFilters } from '../../store/slices/enquiriesSlice';
import {
  changeEnquiryCustomerThunk,
  deleteEnquiryThunk,
  editEnquiryStatusThunk,
  generateEnquiryExcelThunk, generateEnquiryPdfThunk,
} from '../../store/thunks/enquiry/detailsThunks';
import { ContentModeEnum, EnquiryContentModes } from './enums/ContentModeEnum';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { routesPath } from '../../core/enums/pathEnum';
import { useEnquiryDetailsActions } from './hooks/useEnquiryDetailsActions';
import { unmountDetailsHandle } from './utils/unmountDetailsHandle';
import FinalizedSentModal from '../../common/components/entity-status-change-modals/FinalizedSentModal';
import RejectStatusModal from '../../common/components/entity-status-change-modals/RejectStatusModal';
import SentToAcceptedModal from '../../common/components/entity-status-change-modals/SentToAcceptedModal';
import { CoreAttachListElType, initDataList, VoidFunctionType } from '../../core/types/coreTypes';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { tabCountHandle } from '../../core/utils/tabCountHandle';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { enquiryAcceptedStatusSelector, enquiryRejectedStatusSelector } from '../../store/selectors/coreStatusesSelectors';
import { editStatusToAcceptedThunk } from '../../store/thunks/enquiry/enquiryThunks';
import { vehicleDictionariesHandle } from '../../common/utils/vehicleDictionariesHandle';
import { getEnquiryContacts } from '../../store/thunks/enquiry/enquiryContactsThunks';
import GenerateEmailModal from './components/enquiry-content/details&activity/modals/GenerateEmailModal';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import MissingQuoteLinesModal from './components/MissingQuoteLinesModal';
import { UNKNOWN_USER } from '../../core/utils/regex';
import ChangeCustomerModal from '../../common/components/change-customer/ChangeCustomerModal';

const EnquiryDetailsContainer: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const [params, setSearchParams] = useSearchParams();
  const { backHandle } = useLocationHistoryState(true);

  const setModeParam = (mode: ContentModeEnum | string) => {
    setSearchParams({ mode }, { replace: true });
  };

  const dispatch = useAppDispatch();

  const currentUser = useAppSelector(currentUserSelector);
  const filters = useAppSelector(detailsFiltersSelector);
  const details = useAppSelector(enquiryDetailsSelector);
  const acceptedStatus = useAppSelector(enquiryAcceptedStatusSelector);
  const enquiryContactsPaging = useAppSelector(enquiryContactsPagingSelector);
  const { totalCount, items } = useAppSelector(enquiryContactsSelector);
  const enquiryAcceptedStatus = useAppSelector(enquiryAcceptedStatusSelector);
  const enquiryRejectedStatus = useAppSelector(enquiryRejectedStatusSelector);

  const enquiryId = details?.enquiry?.id;
  const enquiryStatus = details?.enquiry?.status;
  const isEnquiryInAcceptedOrRejectedStatus = [enquiryAcceptedStatus?.value, enquiryRejectedStatus?.value].includes(enquiryStatus);
  const quoteVehicleCount = details?.enquiry?.quoteVehicleCount;
  const isGenerateEmailDisabled = !quoteVehicleCount || (totalCount === 0 || !items.find((el) => el.email));
  const isDeleteActionDisabled = enquiryStatus === acceptedStatus?.value;

  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const [isDuplicateConfirmation, setIsDuplicateConfirmation] = useState(false);
  const {
    duplicate,
    isMissingLines,
    setIsMissingLines,
    deleteHandler,
    changeCustomerModal,
    setChangeCustomerModal,
  } = useEnquiryDetailsActions(setConfirmationModal, setIsDuplicateConfirmation);
  const [isSentModal, setSentModal] = useState(false);
  const [isRejectModal, setRejectModal] = useState(false);
  const [isAcceptedModal, setAcceptedModal] = useState(false);
  const [generateEmailModal, setGenerateEmailModal] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);

  const actions = [
    {
      label: 'Change customer',
      key: 'changeCustomer',
      icon: <FontAwesomeIcon icon={faPen} />,
      onClick: () => setChangeCustomerModal(true),
      restrictedTo: PermissionEnum.EnquiryChangeCustomer,
      disabled: isEnquiryInAcceptedOrRejectedStatus,
    },
    {
      label: 'Duplicate enquiry',
      key: 'duplicateEnquiry',
      icon: <FontAwesomeIcon icon={faCopy} />,
      onClick: () => setIsDuplicateConfirmation(true),
      restrictedTo: PermissionEnum.EnquiryDuplicate,
    },
    {
      label: 'Generate email',
      key: 'generateEnquiryEmail',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      onClick: () => setGenerateEmailModal(true),
      disabled: isGenerateEmailDisabled,
      restrictedTo: PermissionEnum.EnquiryExportToPdf,
    },
    {
      label: 'Export to PDF',
      key: 'pdfEnquiry',
      icon: <FontAwesomeIcon icon={faFilePdf} />,
      onClick: () => id && dispatch(generateEnquiryPdfThunk({ id: +id })),
      restrictedTo: PermissionEnum.EnquiryExportToPdf,
      disabled: !quoteVehicleCount,
    },
    {
      label: 'Export to Excel',
      key: 'excelEnquiry',
      icon: <FontAwesomeIcon icon={faFileExcel} />,
      onClick: () => id && dispatch(generateEnquiryExcelThunk({ id: +id })),
      restrictedTo: PermissionEnum.EnquiryExportToExcel,
      disabled: !quoteVehicleCount,
    },
    {
      label: 'Delete enquiry',
      key: 'deleteEnquiry',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: deleteHandler,
      restrictedTo: PermissionEnum.EnquiryDelete,
      disabled: isDeleteActionDisabled,
    },
  ];
  const options = [
    { label: 'Details & activity', value: ContentModeEnum.ENQUIRY_DETAILS },
    {
      label: `Quote ${tabCountHandle(details?.enquiry?.quoteVehicleCount)}`,
      value: ContentModeEnum.ENQUIRY_QUOTE,
    },
    {
      label: `Contacts ${tabCountHandle(details?.enquiry?.contactsCount)}`,
      value: ContentModeEnum.ENQUIRY_CONTACTS,
    },
    {
      label: `Attachments ${tabCountHandle(details?.enquiry?.attachmentsCount)}`,
      value: ContentModeEnum.ENQUIRY_ATTACHMENTS,
    },
    {
      label: `Links ${tabCountHandle(details?.enquiry?.linksCount)}`,
      value: ContentModeEnum.ENQUIRY_LINKS,
    },
  ];

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const modeParam = params.get('mode');
      if (!modeParam && permissions.includes(PermissionEnum.EnquiryViewPage)) {
        setSearchParams({ mode: options[0].value }, { replace: true });
      } else {
        if (!EnquiryContentModes.includes(modeParam as ContentModeEnum)) {
          setSearchParams({ mode: ContentModeEnum.ENQUIRY_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, details]);

  useEffect(() => {
    id && dispatch(getEnquiryContacts({
      id: +id, page: enquiryContactsPaging.page, pageSize: enquiryContactsPaging.pageSize,
    }));
    // eslint-disable-next-line
  }, [id, enquiryContactsPaging]);
  useEffect(() => {
    if (details) {
      dispatch(setDetailsFilters({
        ...filters,
        assignedTo: details.enquiry.isAssigneeDeleted ? UNKNOWN_USER : details.enquiry.assignedTo,
        statusTransition: details.enquiry.status,
      }));
    }
    // eslint-disable-next-line
  }, [details]);

  useEffect(() => {
    vehicleDictionariesHandle(dispatch);
    return () => {
      unmountDetailsHandle(dispatch);
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout
      subHeader={
        <DetailsHead
          actions={actions}
          confirmHandler={() => {
            enquiryId && dispatch(deleteEnquiryThunk({
              id: enquiryId,
              navigateTo: () => navigate(`/${routesPath.ENQUIRIES}`),
            }));
          }}
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          goBack={backHandle}
          className="enquiryViewPageHead"
        >
          <EnquiryHead enquiry={details} />
        </DetailsHead>
      }
    >
      <ChangeCustomerModal
        visible={changeCustomerModal}
        onCancel={() => setChangeCustomerModal(false)}
        changeThunk={(customerId, cancelHandle) => enquiryId && dispatch(changeEnquiryCustomerThunk({
          enquiryId,
          customerId,
          closeModal: () => {
            setChangeCustomerModal(false);
            cancelHandle();
          },
        }))}
        note="Note that after changing a customer all enquiry contacts and billing/shipping details will be updated."
        filters={{ enquiryIdCanBeAssigned: enquiryId }}
        affectedItems={initDataList}
      />
      <ConfirmationModal
        isVisible={isDuplicateConfirmation}
        onCancel={() => setIsDuplicateConfirmation(false)}
        confirmHandler={duplicate}
        customTitle="Duplicate"
        customText="You are about to create a duplicate of displayed enquiry."
        customCreateLabel="Yes, duplicate"
      />
      <MissingQuoteLinesModal
        visible={isMissingLines}
        onCancel={() => setIsMissingLines(false)}
      />
      <FinalizedSentModal
        isVisible={isSentModal}
        isButtonDisabled={isButtonDisabled}
        onCancel={() => setSentModal(false)}
        onConfirm={(status: number) => {
          setButtonDisabled(true);
          id && dispatch(editEnquiryStatusThunk({
            id: +id,
            statusTransition: status,
            onClose: () => {
              setSentModal(false);
              setButtonDisabled(false);
            },
          }));
        }}
      />
      <RejectStatusModal
        isVisible={isRejectModal}
        isButtonDisabled={isButtonDisabled}
        onCancel={() => setRejectModal(false)}
        rejectionStatus={enquiryRejectedStatus?.value}
        onConfirm={(status: number, reason: string, onCancel: VoidFunctionType) => {
          setButtonDisabled(true);
          id && dispatch(editEnquiryStatusThunk({
            id: +id,
            statusTransition: status,
            rejectionReason: reason,
            onClose: () => {
              setRejectModal(false);
              onCancel();
              setButtonDisabled(false);
            },
          }));
        }}
      />
      <SentToAcceptedModal
        isVisible={isAcceptedModal}
        isButtonDisabled={isButtonDisabled}
        onCancel={() => setAcceptedModal(false)}
        vehiclesNumber={details?.enquiry?.quoteVehicleCount || 0}
        onConfirm={(
          list: CoreAttachListElType[],
          comment: string,
          cancelHandle: VoidFunctionType,
          status: number,
        ) => {
          setButtonDisabled(true);
          id && dispatch(editStatusToAcceptedThunk({
            id: +id,
            statusTransition: status,
            cancelHandle: () => {
              cancelHandle();
              setButtonDisabled(false);
            },
            list,
            comment,
            navigateToCreatedOrder: (orderId: number) => {
              navigate(`/${routesPath.ORDERS}/${orderId}`);
              const locationObj = {
                pathname: location.pathname,
                search: location.search,
              };
              setLocationHistoryHandle(locationObj);
            },
          }));
        }}
      />
      <GenerateEmailModal
        onCancel={() => setGenerateEmailModal(false)}
        visible={generateEmailModal}
      />
      <EnquiryFilters
        filters={filters}
        mode={params.get('mode')}
        setModeParam={setModeParam}
        details={details}
        setSentModal={setSentModal}
        setRejectModal={setRejectModal}
        setAcceptedModal={setAcceptedModal}
        allowedTabs={options}
      />
      <EnquiryContent mode={params.get('mode')} />
    </Layout>
  );
};

export default EnquiryDetailsContainer;
