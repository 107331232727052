import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Segmented from '../../../../../core/components/segmented/Segmented';
import {
  PurchaseOrderAllStatusEnum,
  StockTabParamEnum,
  PurchaseUrlParamsNames,
} from '../../enums/PurchaseOrderStatusEnum';
import { useScreenWitdh } from '../../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../../core/components/selectable/Selectable';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  activeOrderStatusSelector,
  archivedOrderStatusSelector,
  purchaseOrdersAssigneesSelector,
} from '../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { PurchaseOrderDashFiltersType } from '../../types/purchaseOrdersTypes';
import { setPurchaseOrdersFilters } from '../../../../../store/slices/purchaseOrderSlice';
import { getPurchaseOrders } from '../../../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import { useCancelRequest } from '../../../../../core/hooks/useCancelRequest';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { currentUserSelector } from '../../../../../store/selectors/accountSelectors';
import { StockPageParamEnums } from '../../../../container/enums/stockEnums';

type PurchaseOrdersFiltersProps = {
  purchaseStatus: string | null;
  setPurchaseStatus: (value: string) => void;
  tabType: string | null;
  purchaseOrdersFilters: PurchaseOrderDashFiltersType;
  keywordParam: string | null;
}

const PurchaseOrdersFilters: React.FC<PurchaseOrdersFiltersProps> = ({
  tabType, setPurchaseStatus, purchaseStatus, purchaseOrdersFilters, keywordParam,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setSearchParams] = useSearchParams();
  const setInitSearchParams = () => {
    setSearchParams({
      [PurchaseUrlParamsNames.PAGE]: params.get(PurchaseUrlParamsNames.PAGE) || StockPageParamEnums.PURCHASES,
      [PurchaseUrlParamsNames.TAB_TYPE]: params.get(PurchaseUrlParamsNames.TAB_TYPE) || StockTabParamEnum.ACTIVE,
      [PurchaseUrlParamsNames.PURCHASE_STATUS]: params.get(PurchaseUrlParamsNames.PURCHASE_STATUS)
        || PurchaseOrderAllStatusEnum.ALL.toString(),
    });
  };
  const { windowWidth } = useScreenWitdh();
  const [purchaseSearchValue, setPurchaseSearchValue] = useState<string | undefined>(purchaseOrdersFilters.keyword);
  const usersLookup = useAppSelector(purchaseOrdersAssigneesSelector);
  const activeOrderStatus = useAppSelector(activeOrderStatusSelector);
  const archievedOrderStatus = useAppSelector(archivedOrderStatusSelector);
  const pageAllowed = usePermission(PermissionEnum.StockPurchaseOrders);
  const { controller, cancelRequest } = useCancelRequest();
  const currentUser = useAppSelector(currentUserSelector);

  const finalOptions = useMemo(() => (tabType && tabType === StockTabParamEnum.ARCHIVED
    ? archievedOrderStatus
    : activeOrderStatus), [tabType, activeOrderStatus, archievedOrderStatus]);

  const getFilteredList = (newFilters: PurchaseOrderDashFiltersType, noThunkCall?: boolean) => {
    dispatch(setPurchaseOrdersFilters(newFilters));
    if (purchaseOrdersFilters.order && !noThunkCall && currentUser?.id) {
      cancelRequest();
      dispatch(getPurchaseOrders({
        filters: {
          ...newFilters,
          assigneesIds: currentUser?.canSeeOnlyAssignedEntities && currentUser?.id
            ? [currentUser.id]
            : newFilters.assigneesIds || [],
        },
        signal: controller.current?.signal,
      }));
    }
  };

  useEffect(() => {
    if (pageAllowed) {
      const status = params.get(PurchaseUrlParamsNames.PURCHASE_STATUS);
      const tab = params.get(PurchaseUrlParamsNames.TAB_TYPE);
      if (status) {
        if (purchaseOrdersFilters.statuses?.length) {
          getFilteredList(purchaseOrdersFilters);
        } else {
          const newStatus = +status === PurchaseOrderAllStatusEnum.ALL ? undefined : +status;
          const filters = {
            ...purchaseOrdersFilters,
            statuses: newStatus ? [newStatus] : undefined,
            isActive: tab ? tab === StockTabParamEnum.ACTIVE : true,
          };
          getFilteredList(filters);
          dispatch(setPurchaseOrdersFilters(filters));
        }
      }
    }
    // eslint-disable-next-line
  }, [pageAllowed, params, purchaseOrdersFilters.statuses, purchaseOrdersFilters.order, purchaseOrdersFilters.page, purchaseOrdersFilters.pageSize]);

  const setDefaultStatusParam = useCallback(() => {
    if (purchaseOrdersFilters.statuses?.length) {
      params.append(PurchaseUrlParamsNames.PURCHASE_STATUS, purchaseOrdersFilters.statuses[0].toString());
    } else {
      if (archievedOrderStatus.length) {
        if (tabType === StockTabParamEnum.ARCHIVED) {
          const firstV = archievedOrderStatus[0].value;
          params.append(PurchaseUrlParamsNames.PURCHASE_STATUS, firstV.toString());
        } else {
          params.append(PurchaseUrlParamsNames.PURCHASE_STATUS, PurchaseOrderAllStatusEnum.ALL.toString());
        }
      }
    }
    navigate({ search: params.toString() });
    // eslint-disable-next-line
  }, [archievedOrderStatus, params, purchaseOrdersFilters.statuses, tabType]);
  const setPurchaseStatusHandle = (status: number | string) => {
    setPurchaseStatus(status.toString());
    const newFilters = {
      ...purchaseOrdersFilters,
      page: 1,
      statuses: status === PurchaseOrderAllStatusEnum.ALL ? undefined : [+status],
    };
    getFilteredList(newFilters, true);
  };
  const orderActiveFilters = [
    {
      label: 'Assigned to',
      name: 'assignedTo',
      value: purchaseOrdersFilters.assigneesIds || [],
      options: usersLookup,
    },
  ];

  useEffect(() => {
    if (!purchaseStatus) {
      if (tabType) {
        setDefaultStatusParam();
      }
    }
    // eslint-disable-next-line
    }, [tabType, purchaseStatus, purchaseOrdersFilters.statuses]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (purchaseSearchValue && purchaseSearchValue !== purchaseOrdersFilters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredList({
          ...purchaseOrdersFilters,
          page: 1,
          keyword: purchaseSearchValue.trim(),
        });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!purchaseSearchValue?.trim() && purchaseOrdersFilters.keyword) {
        getFilteredList({
          ...purchaseOrdersFilters,
          page: 1,
          keyword: undefined,
        });
      }
    }
    // eslint-disable-next-line
  }, [purchaseSearchValue, purchaseOrdersFilters.keyword]);
  return (
    <section className="purchaseOrderFilters sections-and-filters sections-and-filters--offset">
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG
        ? <Selectable
          options={finalOptions}
          value={purchaseStatus ? +purchaseStatus : ''}
          onChange={(v) => setPurchaseStatusHandle(+v)}
        />
        : <Segmented
          options={finalOptions}
          value={purchaseStatus ? +purchaseStatus : ''}
          onChange={(v) => setPurchaseStatusHandle(v)}
          className="purchaseOrderFilters__segmented"
        />}
      <KeywordFiltersSection
        initDropdownSearch={{ assignedTo: '' }}
        searchValue={purchaseSearchValue}
        setSearchValue={(v) => {
          setPurchaseSearchValue(v);
          if (keywordParam) {
            setInitSearchParams();
          }
        }}
        filters={orderActiveFilters}
        getFilteredResHandle={(values) => getFilteredList({ ...purchaseOrdersFilters, page: 1, assigneesIds: values })}
        onClear={() => {
          getFilteredList({
            ...purchaseOrdersFilters, page: 1, assigneesIds: undefined, keyword: undefined,
          });
          if (keywordParam) {
            setInitSearchParams();
          }
        }}
      />
    </section>
  );
};

export default PurchaseOrdersFilters;
