import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Select from '../../../../../core/components/select/Select';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import AddressModal from '../../../../../common/components/address/AddressModal';
import {
  JobDetailsDefaultFields, JobDetailsDto, JobDetailsFields, PatchJobDto,
} from '../../../types/jobViewTypes';
import Input from '../../../../../core/components/input/Input';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isGlobalLoadingSelector,
  jobCategoriesSelector, jobTypesValuesSelector,
  jobTypesWithoutSupplyOnlySelector,
  priorityLevelsSelector,
} from '../../../../../store/selectors/coreSelectors';
import AddressField from '../../../../../common/components/address/AddressField';
import { AddressFields } from '../../../../../common/types/commonTypes';
import {
  isJobStatusCompletedSelector,
  jobDetailsSelector,
} from '../../../../../store/selectors/jobsSelector';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { changeJobDetailsThunk } from '../../../../../store/thunks/jobs/jobThunks';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import Textarea from '../../../../../core/components/textarea/Textarea';
import { jobStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';

const JobDetailsSection: React.FC = () => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const dispatch = useAppDispatch();
  const jobTypes = useAppSelector(jobTypesWithoutSupplyOnlySelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const { offSite } = useAppSelector(jobTypesValuesSelector);
  const jobDetails = useAppSelector(jobDetailsSelector);
  const { newStatus } = useAppSelector(jobStatusesValuesSelector);
  const isJobStatusCompleted = useAppSelector(isJobStatusCompletedSelector);

  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);
  const [isAddressModal, setIsAddressModal] = useState(false);
  const {
    control, setValue, watch, clearErrors, setError,
  } = useForm<JobDetailsFields>({
    defaultValues: JobDetailsDefaultFields,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const jobType = watch('jobType');

  useEffect(() => {
    if (jobDetails) {
      const { job } = jobDetails;
      setValue('priorityLevel', job.priorityLevel);
      setValue('jobCategories', job.jobCategories);
      setValue('jobType', job.jobType);
      setValue('description', job.description || '');
      setAddressFields({
        companyName: job.jobCompanyName || null,
        addressLine1: job.jobAddressLine1 || null,
        addressLine2: job.jobAddressLine2 || null,
        cityTown: job.jobCityTown || null,
        region: job.jobRegion || null,
        postalCode: job.jobPostalCode || null,
        telephone: job.jobTelephone || null,
      });
    }
    // eslint-disable-next-line
  }, [jobDetails]);

  const allowedToEdit = usePermission(PermissionEnum.JobEditFields);

  const fieldsToValidate = ['description'];
  const clearOtherErrorsFn = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, jobDetails?.job, setValue);
  };

  // eslint-disable-next-line consistent-return
  const checkIsAddressEmpty = (values: AddressFields) => {
    const keysToCheck = ['companyName', 'addressLine1', 'addressLine2', 'cityTown', 'postalCode', 'telephone'];
    const fieldsCheckUp = [];
    Object.entries(values).forEach(([key, v]) => {
      if (keysToCheck.includes(key) && v?.trim()) {
        fieldsCheckUp.push(1);
      }
    });
    return fieldsCheckUp.length !== keysToCheck.length;
  };

  const editField = (values: PatchJobDto, key: keyof PatchJobDto, deepCompare?: boolean, isOptional?: boolean) => {
    if (jobDetails) {
      const handler = () => {
        dispatch(changeJobDetailsThunk({
          id: jobDetails.job.id,
          patchData: values,

          closeModal: () => isAddressModal && setIsAddressModal(false),
        }));
      };
      compareValuesHandle<PatchJobDto>(values, key, jobDetails.job, handler, deepCompare, isOptional);
    }
  };

  const changeAddressDetails = (data: PatchJobDto, keys: Array<string>) => {
    if (jobDetails) {
      const keysNotEqual = keys.some((key) => jobDetails.job[key as keyof JobDetailsDto] !== data[key as keyof PatchJobDto]);
      if (keysNotEqual) {
        dispatch(changeJobDetailsThunk({
          id: jobDetails.job.id,
          patchData: data,
          closeModal: () => isAddressModal && setIsAddressModal(false),
        }));
      } else {
        setIsAddressModal(false);
      }
    }
  };

  const isStatusNew = jobDetails?.job.jobStatus === newStatus;
  const isControlsDisabled = !allowedToEdit || isJobStatusCompleted;
  return <>
    <AddressModal
      title="Job location"
      isVisible={isAddressModal}
      onCancel={() => {
        const isSmthEmpty = addressFields ? checkIsAddressEmpty(addressFields) : true;
        if (jobType === offSite && !isStatusNew && isSmthEmpty) {
          setValue('jobType', jobDetails?.job.jobType);
        }
        setIsAddressModal(false);
      }}
      addressFields={addressFields}
      onSubmit={(values) => {
        setAddressFields(values);
        changeAddressDetails({
          jobType: jobType === offSite ? offSite : jobDetails?.job.jobType,
          jobCompanyName: values.companyName?.trim() || '',
          jobAddressLine1: values.addressLine1?.trim() || '',
          jobAddressLine2: values.addressLine2?.trim() || '',
          jobCityTown: values.cityTown?.trim() || '',
          jobRegion: values.region?.trim() || '',
          jobPostalCode: values.postalCode?.trim() || '',
          jobTelephone: values.telephone?.trim() || '',
        }, [
          'jobType', 'jobCompanyName', 'jobAddressLine1',
          'jobAddressLine2', 'jobCityTown', 'jobRegion', 'jobPostalCode', 'jobTelephone',
        ]);
      }}
      isOptionalFields={isStatusNew || (!isStatusNew && jobType !== offSite)}
    />
    <section className="info-grid__column jobDetailsSection">
      <h2 className="info-grid__title">Details</h2>
      <form className="details-form">
        <Input
          value={jobDetails?.job.linkedNumber || ''}
          label="Linked to"
          disabled
        />
        <Controller
          name="priorityLevel"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsFn('priorityLevel');
                editField({ priorityLevel: v as number }, 'priorityLevel');
              }}
              options={priorityLevels}
              label="Priority level"
              disabled={isControlsDisabled}
            />
          )}
        />
        <Controller
          name="jobCategories"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsFn('jobCategories');
              }}
              onBlur={() => {
                !errors.jobCategories && editField({ jobCategories: field.value as number[] }, 'jobCategories', true);
              }}
              onDeselect={() => {
                if (field.value?.length === 1) {
                  setError('jobCategories', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              error={errors.jobCategories && ErrorsEnum.REQUIRED}
              options={jobCategories}
              label="Job category"
              disabled={isControlsDisabled}
              mode="multiple"
              showArrow
              loading={isLoading}
            />
          )}
        />
        <Controller
          name="jobType"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                const isSmthEmpty = addressFields ? checkIsAddressEmpty(addressFields) : true;
                if (v === offSite && !isStatusNew && isSmthEmpty) {
                  setIsAddressModal(true);
                  field.onChange(v);
                } else {
                  field.onChange(v);
                  editField({ jobType: v as number }, 'jobType');
                }
                clearOtherErrorsFn('jobType');
              }}
              options={jobTypes}
              disabled={isControlsDisabled}
              label="Job type"
            />
          )}
        />
        <AddressField
          title="Job location"
          addressFields={addressFields}
          setAddressModal={() => {
            setIsAddressModal(true);
            clearOtherErrorsFn('jobLocation');
          }}
          disabled={isControlsDisabled}
        />
        <Controller
          name="description"
          control={control}
          rules={{ maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } }}
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsFn('description');
              }}
              onBlur={() => !errors?.description && editField(
                { description: field.value?.trim() || '' },
                'description',
                false,
                true,
              )}
              label="Description"
              error={errors?.description?.message}
              disabled={isControlsDisabled}
              className="details-form__field--lg"
            />
          )}
        />
      </form>
    </section>
  </>;
};

export default JobDetailsSection;
