import {
  AssigneeDto,
  DataListType,
  initDataList,
  initPaging,
  PagingWithOrdering,
  ResponseResult,
  ResponseSingleResult,
  StringRangeType,
} from '../../../../core/types/coreTypes';
import { StatusCountDto } from '../../../../customers/customer-view-page/types/customerVewPageTypes';
import { RequisiteDto } from '../../../../common/types/commonTypes';

export type PurchaseOrderDashFiltersType = {
  isActive: boolean,
  partId?: number,
  keyword?: string,
  assigneesIds?: number[],
  hasDraftInvoiceDocuments?: number[],
  dueDate?: StringRangeType,
  createdDate?: StringRangeType,
  statuses?: number[],
} & PagingWithOrdering

export const InitPurchaseOrderDashFilters = {
  isActive: true,
  ...initPaging,
};

export type PurchaseOrderDto = {
  id: number,
  purchaseOrderNumber: string | null,
  supplierId: number,
  supplierName: string | null,
  supplierAccountNumber: string | null,
  supplierStatus: number,
  createdDate: string,
  modifiedDate: string,
  partsCount: number,
  attachmentsCount: number,
  assigneeId: number,
  assigneeFullName: string | null,
  isAssigneeDeleted: boolean,
  status: number,
  description: string | null,
  purchaseOrderDate: string,
  deliveryAddressLine1: string | null,
  deliveryAddressLine2: string | null,
  deliveryCityTown: string | null,
  deliveryRegion: string | null,
  deliveryPostalCode: string | null,
  deliveryCompanyName: string | null,
  deliveryTelephone: string | null,
  statusTransitions: number[] | null,
  hasPartQuantity: boolean,
  fao: string | null,
  specialInstructions: string | null,
  dueDate: string | null,
  totalGross: string | null,
  toBeInvoicedGross: string | null,
  invoicedGross: string | null,
  draftInvoiceDocumentsCount: number,
  exportedInvoiceDocumentsCount: number,
  invoiceDocumentsCount: number,
  billingSupplierId: number | null,
  isBillingDetailsManualInput: boolean,
}

export type PurchaseOrderDetails = {
  purchaseOrder: PurchaseOrderDto,
  areLinesCreatable: boolean,
  maxInvoiceEntitySequenceNumber: number | null,
  maxCreditNoteEntitySequenceNumber: number | null,
  billingRequisite: RequisiteDto,
  canBeMarkedAsInvoiced: boolean,
}

export type GetPurchaseOrderResponse = ResponseSingleResult<PurchaseOrderDetails>

export type StockPurchaseOrderDetails = Omit<PurchaseOrderDetails,
'maxCreditNoteEntitySequenceNumber' | 'maxInvoiceEntitySequenceNumber' | 'canBeMarkedAsInvoiced'>;

type PurchaseOrdersData = {
  statusCounts: StatusCountDto[],
  assignees: AssigneeDto[] | null,
  minDueDate: string | null,
  maxDueDate: string | null,
  minCreatedDate: string | null,
  maxCreatedDate: string | null,
}

export type PurchaseOrdersResponseResult = ResponseResult<PurchaseOrderDto[], PurchaseOrdersData>

export type PurchaseOrderDataListType = DataListType<PurchaseOrderDto[], PurchaseOrdersData>

export const PurchaseOrdersInitDataList = {
  ...initDataList,
  assignees: null,
  statusCounts: [],
  minDueDate: null,
  maxDueDate: null,
  minCreatedDate: null,
  maxCreatedDate: null,
};

export type PurchaseOrderDictionaryDto = {
  id: number,
  purchaseOrderNumber: string | null,
}

export type GetPurchaseOrderDictionaryResponse = { purchaseOrders: Array<PurchaseOrderDictionaryDto> }
