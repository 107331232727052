import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import KeywordFiltersSection from '../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  existingVehicleSpecificationFiltersListSelector,
  existingVehicleSpecificationFiltersSelector,
} from '../../../store/selectors/enquiriesSelector';
import { useAppSelector } from '../../../store/hooks';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Pagination from '../../../core/components/pagination/Pagination';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import Thead from '../../../core/components/table-components/Thead';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Tbody from '../../../core/components/table-components/Tbody';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import Button from '../../../core/components/button/Button';

type ExistingVehicleSpecificationProps = {
  setVehicleSelectMode: (v?: 'blank' | 'existing') => void,
}

const columns = [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Specification code',
    dataIndex: 'specificationCode',
  },
  {
    title: 'Specification name',
    dataIndex: 'specificationName',
  },
  {
    title: 'Items',
    dataIndex: 'items',
  },
  {
    title: 'Description',
    dataIndex: 'description',
  },
];

const testData = {
  items: [
    {
      id: 1,
      number: 1,
      specificationCode: 'test',
      specificationName: 'test',
      items: 1,
      description: 'test',
    },
  ],
  totalCount: 1,
  pages: 1,
};

const ExistingVehicleSpecification: React.FC<ExistingVehicleSpecificationProps> = ({ setVehicleSelectMode }) => {
  const filtersValues = useAppSelector(existingVehicleSpecificationFiltersSelector);
  const filtersList = useAppSelector(existingVehicleSpecificationFiltersListSelector);
  const initDropdownSearch = {
    businessAreas: '',
    vehicleSpecifications: '',
  };
  const [error, setError] = useState<string>('');

  const searchHandle = () => {
    // const searchValue = filtersValues.keyword;
    // if (!searchValue?.trim()) {
    //   setError(ErrorsEnum.REQUIRED);
    //   dispatch(setPartsKitComponentsPartsFilters({ ...filters, keyword: undefined }));
    // } else {
    //   searchValue?.trim() && !error && searchTriggerFn();
    // }
  };
  return (
    <section>
      <KeywordFiltersSection
        filters={filtersList}
        isSearch={false}
        initDropdownSearch={initDropdownSearch}
        getFilteredResHandle={(value, field) => console.debug('ff')}
        onClear={() => console.debug('f')}
      />
      <InputWithButton
        value={filtersValues.keyword}
        icon={<FontAwesomeIcon icon={faSearch} />}
        onChange={(e) => {
          const val = e.target.value;
          // if (!val.trim() && parts.totalCount !== 0) {
          //   dispatch(setPartsKitComponentsParts(initDataList));
          // }
          // if (val.length > 100) {
          //   setError(maxLengthMessageHandle(100));
          // } else {
          //   error && setError('');
          // }
          // cleanKeys();
          // dispatch(setPartsKitComponentsPartsFilters({ ...filters, keyword: val }));
        }}
        onPressEnter={searchHandle}
        onClick={searchHandle}
        placeholder="Search by specification code, name or description"
        error={error}
        className="addComponent__content-filters__search"
        buttonDisabled={!filtersValues.keyword}
      />
      <Pagination
        currentPage={filtersValues.page}
        onChange={(page, pageSize) => {
          // dispatch(setPartsKitComponentsPartsFilters({ ...filters, page, pageSize }));
          // filters.keyword && dispatch(getPartsKitComponentsParts({
          //   id: partsKitId,
          //   filters: {
          //     ...filters,
          //     page,
          //     pageSize,
          //   },
          // }));
          // cleanKeys();
        }}
        totalItems={testData.totalCount}
        pageSize={filtersValues.pageSize}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        className="addComponent__content-filters__pages"
      />
      <TableNew>
        <Thead>
          <Tr>
            {columns.map((col) => <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={testData.totalCount}
              className={`addComponentCoreNonCoreTh--${col.dataIndex}`}
              insideModal
            />)}
            <ThNew><span className="sr-only">THIS WILL BE CHANGED</span></ThNew>
          </Tr>
        </Thead>
        <Tbody>
          {testData.totalCount === 0
            ? <EmptyTableSection
              text={filtersValues.keyword && testData.totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
              colSpan={6}
            />
            : <>
              {testData.items.map((d, i) => <Tr key={i}>
                {Object.entries(d).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key}>
                    <TruncateTooltip value={value} table />
                  </TdNew>;
                })}
                <TdNew>
                  <Button
                    type="text"
                    label="Select"
                    onClick={() => console.debug('select')}
                  />
                </TdNew>
              </Tr>)}
            </>}
        </Tbody>
      </TableNew>
    </section>
  );
};

export default ExistingVehicleSpecification;
