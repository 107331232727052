import { createSelector } from '@reduxjs/toolkit';
import moment from 'moment';
import { RootState } from '../store';
import {
  invoiceDocumentTypesSelector,
  nominalCodeTypesSelector,
  nominalRecordStatesSelector,
  nominalRecordStatesValuesSelector, nominalRecordTypesSelector, nominalRecordTypesValuesSelector,
  QuoteUnitsOfMeasureSelector,
} from './coreSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  nominalCodesOrderingSelector,
  purchaseOrderPartsOrderingSelector,
  stockPartRequestLinesOrderingSelector,
} from './coreOrderingSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { NominalCodesOrdering } from '../../invoicing/nominal-codes/enums/nominalCodesEnums';
import { tabCountHandle } from '../../core/utils/tabCountHandle';
import {
  PurchaseOrderAllStatusEnum,
} from '../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import {
  invoiceDocumentStatusesSelector, orderStatusesValuesSelector, orderStatusSelector,
  purchaseOrderStatusObjSelector,
  purchaseOrderStatusOriginSelector,
} from './coreStatusesSelectors';
import { PurchaseOrderPartsOrderingEnum } from '../../stock/purchase-orders/view-page/enums/purchaseOrderEnums';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { DEFAULT_UI_DATE_FORMAT_WITH_TIME, maxCommonDecimal } from '../../core/utils/regex';
import { customersDictionaryLookupSelector, supplierDictionaryLookupSelector } from './sharedSelectors';
import { toPoundCurrency } from '../../common/utils/formatUtils';
import { InvoicingOperationLineNominalRecordDto } from '../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import { NominalRecordObj } from '../../invoicing/common/types/invoicingCommonTypes';
import { StockPartReqLineOrderingEnum } from '../../stock/stock-part-request-view/enums/StockPartRequestViewPageEnums';

export const nominalCodesFiltersSelector = (state: RootState) => state.invoicing.nominalCodesFilters;
const nominalCodesInitSelector = (state: RootState) => state.invoicing.nominalCodes;
export const nominalCodesTableUiSelector = createSelector(
  nominalCodesInitSelector,
  nominalCodeTypesSelector,
  (data, types) => {
    return {
      ...data,
      items: data.items.map((el) => ({
        code: el.code,
        description: el.description,
        typeId: el.type,
        type: types.find((type) => type.value === el.type)?.label || '-',
        createdDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.createdDate),
        id: el.id,
      })),
    };
  },
);

export const nominalCodesDictionarySelector = createSelector(nominalCodesInitSelector, (data) => {
  return data.items.map((item) => ({
    label: `${item.code} - ${item.description}`,
    value: item.id,
  }));
});

export const nominalCodesColumnsSelector = createSelector(
  nominalCodesOrderingSelector,
  (ordering) => [
    {
      title: 'Code',
      dataIndex: 'code',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(NominalCodesOrdering.Code, ordering),
    },
    {
      title: 'Description',
      dataIndex: 'description',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(NominalCodesOrdering.Description, ordering),
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      sorter: true,
      orderField: getSortIndex(NominalCodesOrdering.CreatedDate, ordering),
    },
  ],
);

const nominalCodesTypeCountsSelector = (state: RootState) => state.invoicing.nominalCodesTypeCounts;

export const nominalCodeTypesWithCountsSelector = createSelector(
  nominalCodeTypesSelector,
  nominalCodesTypeCountsSelector,
  (types, counts) => {
    return types.map((el) => {
      const count = counts?.find((c) => c.type === el.value)?.count || null;
      return { ...el, label: `${el.label} ${tabCountHandle(count)}` };
    });
  },
);

export const salesDashboardFiltersSelector = (state: RootState) => state.invoicing.salesDashboardFilters;
export const salesDashboardSelector = (state: RootState) => state.invoicing.salesDashboard;
export const salesOrderDetailsSelector = (state: RootState) => state.invoicing.salesOrderDetails;

const salesOrderDetailsSummarySelector = (state: RootState) => state.invoicing.salesOrderDetailsSummary;
export const salesOrderDetailsSummaryUISelector = createSelector(salesOrderDetailsSummarySelector, (summary) => {
  const netDataProperties = [
    { label: 'Total', value: toPoundCurrency(summary?.netWithDiscount) },
    {
      label: 'Invoicing & pending',
      value: toPoundCurrency(summary?.invoicedAndPendingNet),
      tooltipValue: summary?.creditNominalRecordsNetAbs ?? '',
    },
    { label: 'Unassigned', value: toPoundCurrency(summary?.unassignedNet) },
  ];

  const grossDataProperties = [
    { label: 'Total', value: toPoundCurrency(summary?.gross) },
    {
      label: 'Invoicing & pending',
      value: toPoundCurrency(summary?.invoicedAndPendingGross),
      tooltipValue: summary?.creditNominalRecordsGrossAbs ?? '',
    },
    { label: 'Unassigned', value: toPoundCurrency(summary?.unassignedGross) },
  ];
  return summary
    ? {
      netDataProperties,
      grossDataProperties,
      creditNominalRecordsGrossAbs: summary.creditNominalRecordsGrossAbs,
      creditNominalRecordsNetAbs: summary.creditNominalRecordsNetAbs,
    }
    : null;
});
export const salesViewCreditNotesSummarySelector = createSelector(salesOrderDetailsSummarySelector, (summary) => {
  const netDataProperties = [
    { label: 'Exported', value: toPoundCurrency(summary?.exportedInvoicesNet) },
    { label: 'Draft', value: toPoundCurrency(summary?.draftInvoicesNet) },
    { label: 'Total', value: toPoundCurrency(summary?.netWithDiscount), tooltipValue: summary?.creditNominalRecordsNetAbs ?? '' },
    { label: 'Remaining', value: toPoundCurrency(summary?.unassignedNet) },
  ];

  const grossDataProperties = [
    { label: 'Exported', value: toPoundCurrency(summary?.exportedInvoicesGross) },
    { label: 'Draft', value: toPoundCurrency(summary?.draftInvoicesGross) },
    { label: 'Total', value: toPoundCurrency(summary?.gross), tooltipValue: summary?.creditNominalRecordsGrossAbs ?? '' },
    { label: 'Remaining', value: toPoundCurrency(summary?.unassignedGross) },
  ];
  return summary
    ? {
      netDataProperties,
      grossDataProperties,
    }
    : null;
});

export const salesOrderPartRequestLinesFiltersSelector = (state: RootState) => state.invoicing.salesInvoiceOrderLinesFilters;
export const salesOrderPartRequestLinesSelector = (state: RootState) => state.invoicing.salesOrderPartRequestLines;
export const salesNominalCodesTooltipsSelector = createSelector(
  salesOrderPartRequestLinesSelector,
  nominalRecordStatesValuesSelector,
  invoiceDocumentStatusesSelector,
  (lines, states, invoiceStatuses) => {
    const { pending, exported, draft } = states;
    return lines?.items.map(({ id, nominalRecords }) => {
      const pendingRecords = nominalRecords?.filter((el) => el.state === pending);
      const exportedRecords = nominalRecords?.filter((el) => el.state === exported);
      const draftRecords = nominalRecords?.filter((el) => el.state === draft);

      const exportedRecordsTree: {[key: string]: NominalRecordObj} = {};
      const draftRecordsTree: {[key: string]: NominalRecordObj} = {};

      const positiveNetHandle = (net: number) => (net < 0 ? Math.abs(net) : net);
      const recordsTreeHandle = (tree: {[key: string]: NominalRecordObj}, records?: InvoicingOperationLineNominalRecordDto[]) => {
        if (records?.length) {
          for (let i = 0; i < records.length; i++) {
            const currentRecord = records[i];
            const {
              invoiceDocumentId, nominalCode, invoiceDocumentNumber, invoiceDocumentStatus, net,
            } = currentRecord;

            if (invoiceDocumentId) {
              if (!tree[invoiceDocumentId]) {
                tree[invoiceDocumentId] = {
                  title: invoiceDocumentNumber || 'N/A',
                  status: invoiceStatuses.find((el) => el.value === invoiceDocumentStatus)?.label || '-',
                  nominalCodes: [`${nominalCode || 'N/A'} - £ ${positiveNetHandle(net)}`],
                };
              } else {
                tree[invoiceDocumentId].nominalCodes.push(`${nominalCode} - £ ${positiveNetHandle(net)}`);
              }
            }
          }
        }
      };

      recordsTreeHandle(exportedRecordsTree, exportedRecords);
      recordsTreeHandle(draftRecordsTree, draftRecords);

      const pendingRecordsTexts = pendingRecords?.map((el) => `${el.nominalCode} - £ ${positiveNetHandle(el.net)}`);
      return {
        id,
        nominalRecords: {
          draft: draftRecordsTree,
          exported: exportedRecordsTree,
          pending: pendingRecordsTexts,
        },
      };
    });
  },
);
export const salesOrderPartRequestTableLinesSelector = createSelector(
  salesOrderPartRequestLinesSelector,
  QuoteUnitsOfMeasureSelector,
  (data, unitsOfMeasure) => {
    return data?.items?.map((el) => ({
      id: el.id,
      partId: el.partId,
      orderingNumber: el.ordinalNumber,
      partNumber: el.productNumber || '-',
      partName: el.productName || '-',
      receivedQuantity: el.quantity || 0,
      net: el.netPriceString || '0.00',
      invoicedPending: el.invoiceNominalRecordsNet || 0,
      unassignedNet: el.unassignedNet,
      nominalCodes: el.nominalRecords?.map(({
        invoiceDocumentId,
        nominalCode = '-',
        state,
      }) => ({ invoiceDocumentId, nominalCode, state })) || [],
      unitOfMeasure: unitsOfMeasure.find((unit) => unit.value === el.unitOfMeasure)?.label || '',
      notes: el.notes,
      requestedQty: el.requestedQuantity,
      cancelledQty: 0,
      creditNominalRecordsNet: el.creditNominalRecordsNet || 0,
      childLines: el.childLines && el.childLines.length
        ? el.childLines.map((ch) => ({
          id: ch.id,
          partId: ch.partsKitComponentPartId,
          orderingNumber: `${el.ordinalNumber}.${ch.ordinalNumber}`,
          partNumber: ch.productNumber || '-',
          partName: ch.productName || '-',
          receivedQuantity: ch.quantity || 0,
          net: ch.netPriceString || '0.00',
          invoicedPending: ch.invoiceNominalRecordsNet || 0,
          unassignedNet: ch.unassignedNet,
          nominalCodes: ch.nominalRecords?.map(({
            invoiceDocumentId,
            nominalCode = '-',
            state,
          }) => ({ invoiceDocumentId, nominalCode, state })) || [],
          unitOfMeasure: unitsOfMeasure.find((unit) => unit.value === ch.unitOfMeasure)?.label || '',
          notes: ch.notes,
          requestedQty: ch.requestedQuantity,
          cancelledQty: 0,
          creditNominalRecordsNet: ch.creditNominalRecordsNet || 0,
        }))
        : null,
    }));
  },
);

export const salesOrderTimelineFiltersSelector = (state: RootState) => state.invoicing.salesOrderTimelineFilters;
export const salesOrderTimelineSelector = (state: RootState) => state.invoicing.salesOrderTimeline;

export const salesInvoiceOrderLinesColumnsSelector = createSelector(
  stockPartRequestLinesOrderingSelector,
  salesOrderPartRequestLinesSelector,
  (ordering, initData) => [
    {
      title: 'No',
      dataIndex: 'orderingNumber',
    },
    {
      title: 'Product No',
      dataIndex: 'productNumber',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductNumber, ordering),
    },
    {
      title: 'Product name',
      dataIndex: 'productName',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.ProductName, ordering),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
    },
    {
      title: 'Net total',
      dataIndex: 'net',
      sorter: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.NetPrice, ordering),
    },
    {
      title: 'Invoiced & pending',
      dataIndex: 'invoicedPending',
    },
    {
      title: 'Unassigned',
      dataIndex: 'unassignedNet',
      sorter: true,
      orderField: getSortIndex(StockPartReqLineOrderingEnum.UnassignedNet, ordering),
    },
    {
      title: 'Nominal codes',
      dataIndex: 'nominalCodes',
      filters: initData?.nominalCodes || [],
    },
  ],
);

export const salesStatusesWithCountSelector = createSelector(
  salesDashboardSelector,
  orderStatusSelector,
  orderStatusesValuesSelector,
  (list, statusOrigin, statusObj) => {
    const { postCheckPassed, acceptedByCustomer, invoicing } = statusObj;
    const statusesToCount = [postCheckPassed, acceptedByCustomer, invoicing];
    let allTotalActive = 0;

    list.statusCounts.forEach((el) => {
      if (statusesToCount.includes(el.status)) allTotalActive += el.count;
    });

    const allTotalActiveLabel = allTotalActive
      ? `(${allTotalActive})`
      : '';

    const getStatusCount = (status?: number) => {
      const count = list.statusCounts.find((p) => p.status === status)?.count || 0;
      return count ? `(${count.toString()})` : '';
    };

    const newItems = statusOrigin.map((item) => ({
      ...item,
      label: `${item.label} ${getStatusCount(item.value)}`,
    }));

    return [
      { label: `All ${allTotalActiveLabel}`, value: PurchaseOrderAllStatusEnum.ALL },
      ...newItems,
    ];
  },
);

export const activeSalesStatusSelector = createSelector(
  salesStatusesWithCountSelector,
  orderStatusesValuesSelector,
  (statuses, statusObj) => {
    const all = PurchaseOrderAllStatusEnum.ALL;
    const {
      acceptedByCustomer, invoicing, postCheckPassed,
    } = statusObj;
    const activeStatuses = [acceptedByCustomer, invoicing, postCheckPassed, all];
    return statuses.filter((st) => activeStatuses.includes(st.value));
  },
);

export const archivedSalesStatusSelector = createSelector(
  salesStatusesWithCountSelector,
  orderStatusesValuesSelector,
  (statuses, statusObj) => {
    const { completed } = statusObj;
    return statuses.filter((st) => st.value === completed);
  },
);

export const purchasesFiltersSelector = (state: RootState) => state.invoicing.purchasesFilters;
export const purchasesListSelector = (state: RootState) => state.invoicing.purchasesList;
export const invoicingPurchasesStatusesWithCountSelector = createSelector(
  purchasesListSelector,
  purchaseOrderStatusOriginSelector,
  purchaseOrderStatusObjSelector,
  (list, statusOrigin, statusObj) => {
    const {
      received, partiallyReceived, sentToSupplier,
    } = statusObj;
    const statusesToCount = [received, partiallyReceived, sentToSupplier];
    let allTotalActive = 0;

    list.statusCounts.forEach((el) => {
      if (statusesToCount.includes(el.status)) allTotalActive += el.count;
    });

    const allTotalActiveLabel = allTotalActive
      ? `(${allTotalActive})`
      : '';

    const getStatusCount = (status?: number) => {
      const count = list.statusCounts.find((p) => p.status === status)?.count || 0;
      return count ? `(${count.toString()})` : '';
    };

    const newItems = statusOrigin.map((item) => ({
      ...item,
      label: `${item.label} ${getStatusCount(item.value)}`,
    }));

    return [
      { label: `All ${allTotalActiveLabel}`, value: PurchaseOrderAllStatusEnum.ALL },
      ...newItems,
    ];
  },
);

export const activeInvoicingPurchasesStatusSelector = createSelector(
  invoicingPurchasesStatusesWithCountSelector,
  purchaseOrderStatusObjSelector,
  (statuses, statusObj) => {
    const { statusNew, finalised, completed } = statusObj;
    const restrictedStatuses = [statusNew, finalised, completed];
    return statuses.filter((st) => !restrictedStatuses.includes(st.value));
  },
);

export const archivedInvoicingPurchasesStatusSelector = createSelector(
  invoicingPurchasesStatusesWithCountSelector,
  purchaseOrderStatusObjSelector,
  (statuses, statusObj) => {
    const { completed } = statusObj;
    return statuses.filter((st) => st.value === completed);
  },
);

export const purchasesViewOrderLinesFiltersSelector = (state: RootState) => state.invoicing.purchasesViewOrderLinesFilters;
export const purchasesViewOrderLinesSelector = (state: RootState) => state.invoicing.purchasesViewOrderLines;
export const purchasesViewOrderTableLinesSelector = createSelector(
  purchasesViewOrderLinesSelector,
  QuoteUnitsOfMeasureSelector,
  (data, unitsOfMeasure) => {
    return data?.purchaseOrderParts.map((el, i) => ({
      id: el.id,
      partId: el.partId,
      orderingNumber: i + 1,
      partNumber: el.partNumber || '-',
      partName: el.partName || '-',
      notes: el.notes,
      receivedQuantity: el.totalReceivedQuantity,
      receivedNet: el.receivedNet || 0,
      net: el.requestedNetString || '0.00',
      invoicedPending: el.invoiceNominalRecordsNet || 0,
      unassignedNet: el.unassignedNet,
      nominalCodes: el.nominalRecords.map(({ invoiceDocumentId, nominalCode = '-', state }) => ({ invoiceDocumentId, nominalCode, state })),
      unitOfMeasure: unitsOfMeasure.find((unit) => unit.value === el.unitOfMeasure)?.label || '',
      requestedQty: el.requestedQuantity,
      cancelledQty: el.cancelledQuantity,
      creditNominalRecordsNet: el.creditNominalRecordsNet || 0,
    }));
  },
);

export const purchasesOrderLinesRangeValuesSelector = createSelector(
  purchasesViewOrderLinesSelector,
  (data) => {
    return {
      net: { min: data?.minRequestedNet || 0.00, max: data?.maxRequestedNet || maxCommonDecimal },
      unassignedNet: { min: data?.minUnassignedNet || 0.00, max: data?.maxUnassignedNet || maxCommonDecimal },
    };
  },
);

export const purchasesViewOrderLinesColumnsSelector = createSelector(
  purchaseOrderPartsOrderingSelector,
  purchasesViewOrderLinesSelector,
  (ordering, initData) => [
    {
      title: 'No',
      dataIndex: 'orderingNumber',
    },
    {
      title: 'Product No',
      dataIndex: 'partNumber',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.ProductNumber, ordering),
    },
    {
      title: 'Product name',
      dataIndex: 'partName',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.ProductName, ordering),
    },
    {
      title: 'Notes',
      dataIndex: 'notes',
    },
    {
      title: 'Received qty',
      dataIndex: 'receivedQuantity',
    },
    {
      title: 'Net received',
      dataIndex: 'netReceived',
    },
    {
      title: 'Net total',
      dataIndex: 'net',
      sorter: true,
      orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.RequestedNet, ordering),
    },
    {
      title: 'Invoiced & pending',
      dataIndex: 'invoicedPending',
    },
    {
      title: 'Unassigned',
      dataIndex: 'unassignedNet',
      sorter: true,
      orderField: getSortIndex(PurchaseOrderPartsOrderingEnum.UnassignedNet, ordering),
    },
    {
      title: 'Nominal codes',
      dataIndex: 'nominalCodes',
      filters: initData?.nominalCodes
        ? initData?.nominalCodes.map((el) => ({ ...el, label: el.label || 'N/A' }))
        : [],
    },
  ],
);

export const nominalCodesTooltipsSelector = createSelector(
  purchasesViewOrderLinesSelector,
  nominalRecordStatesValuesSelector,
  invoiceDocumentStatusesSelector,
  (lines, stateValues, invoiceStatuses) => {
    const { pending, exported, draft } = stateValues;
    return lines?.purchaseOrderParts.map(({ id, nominalRecords }) => {
      const pendingRecords = nominalRecords?.filter((el) => el.state === pending);
      const exportedRecords = nominalRecords?.filter((el) => el.state === exported);
      const draftRecords = nominalRecords?.filter((el) => el.state === draft);

      const exportedRecordsTree: {[key: string]: NominalRecordObj} = {};
      const draftRecordsTree: {[key: string]: NominalRecordObj} = {};

      const positiveNetHandle = (net: number) => (net < 0 ? Math.abs(net) : net);
      const recordsTreeHandle = (tree: {[key: string]: NominalRecordObj}, records: InvoicingOperationLineNominalRecordDto[]) => {
        for (let i = 0; i < records.length; i++) {
          const currentRecord = records[i];
          const {
            invoiceDocumentId, nominalCode, invoiceDocumentNumber, invoiceDocumentStatus, net,
          } = currentRecord;

          if (invoiceDocumentId) {
            if (!tree[invoiceDocumentId]) {
              tree[invoiceDocumentId] = {
                title: invoiceDocumentNumber || 'N/A',
                status: invoiceStatuses.find((el) => el.value === invoiceDocumentStatus)?.label || '-',
                nominalCodes: [`${nominalCode || 'N/A'} - £ ${positiveNetHandle(net)}`],
              };
            } else {
              tree[invoiceDocumentId].nominalCodes.push(`${nominalCode} - £ ${positiveNetHandle(net)}`);
            }
          }
        }
      };

      exportedRecords.length && recordsTreeHandle(exportedRecordsTree, exportedRecords);
      draftRecords.length && recordsTreeHandle(draftRecordsTree, draftRecords);

      const pendingRecordsTexts = pendingRecords?.map((el) => `${el.nominalCode} - £ ${positiveNetHandle(el.net)}`);
      return {
        id,
        nominalRecords: {
          draft: draftRecordsTree,
          exported: exportedRecordsTree,
          pending: pendingRecordsTexts,
        },
      };
    });
  },
);

export const invoicingPurchaseOrderSelector = (state: RootState) => state.invoicing.invoicingPurchaseOrder;
const purchaseViewOrderLinesInitSummarySelector = (state: RootState) => state.invoicing.purchaseViewOrderLinesSummary;
export const purchaseViewOrderLinesSummarySelector = createSelector(
  purchaseViewOrderLinesInitSummarySelector,
  (summary) => {
    const netDataProperties = [
      { label: 'Total', value: toPoundCurrency(summary?.totalNet) },
      { label: 'Received', value: toPoundCurrency(summary?.receivedNet) },
      {
        label: 'Invoicing & pending',
        value: toPoundCurrency(summary?.invoicedAndPendingNet),
        tooltipValue: summary?.creditNominalRecordsNetAbs ?? '',
      },
      { label: 'Unassigned', value: toPoundCurrency(summary?.unassignedNet) },
    ];

    const grossDataProperties = [
      { label: 'Total', value: toPoundCurrency(summary?.totalGross) },
      { label: 'Received', value: toPoundCurrency(summary?.receivedGross) },
      {
        label: 'Invoicing & pending',
        value: toPoundCurrency(summary?.invoicedAndPendingGross),
        tooltipValue: summary?.creditNominalRecordsNetAbs ?? '',
      },
      { label: 'Unassigned', value: toPoundCurrency(summary?.unassignedGross) },
    ];
    return summary
      ? {
        netDataProperties,
        grossDataProperties,
        creditNominalRecordsGrossAbs: summary.creditNominalRecordsGrossAbs,
        creditNominalRecordsNetAbs: summary.creditNominalRecordsNetAbs,
      }
      : null;
  },
);

export const purchaseViewCreditNotesSummarySelector = createSelector(
  purchaseViewOrderLinesInitSummarySelector,
  (summary) => {
    const netDataProperties = [
      { label: 'Exported', value: toPoundCurrency(summary?.exportedInvoicesNet) },
      { label: 'Draft', value: toPoundCurrency(summary?.draftInvoicesNet) },
      { label: 'Total', value: toPoundCurrency(summary?.totalNet), tooltipValue: summary?.creditNominalRecordsNetAbs ?? '' },
      { label: 'Remaining', value: toPoundCurrency(summary?.unassignedNet) },
    ];

    const grossDataProperties = [
      { label: 'Exported', value: toPoundCurrency(summary?.exportedInvoicesGross) },
      { label: 'Draft', value: toPoundCurrency(summary?.draftInvoicesGross) },
      { label: 'Total', value: toPoundCurrency(summary?.totalGross), tooltipValue: summary?.creditNominalRecordsGrossAbs ?? '' },
      { label: 'Remaining', value: toPoundCurrency(summary?.unassignedGross) },
    ];
    return summary
      ? {
        netDataProperties,
        grossDataProperties,
      }
      : null;
  },
);

export const assignedNominalCodesFiltersSelector = (state: RootState) => state.invoicing.assignedNominalCodesFilters;
export const assignedNominalCodesSelector = (state: RootState) => state.invoicing.assignedNominalCodes;
export const assignNominalCodesTableSelector = createSelector(
  assignedNominalCodesSelector,
  assignedNominalCodesFiltersSelector,
  nominalRecordStatesSelector,
  nominalRecordTypesSelector,
  (assignedData, filters, recordsState, recordTypes) => {
    return {
      ...assignedData,
      items: assignedData.items.map((el) => ({
        id: el.id,
        orderingNumber: el.ordinalNumber,
        type: recordTypes.find((type) => type.value === el.type)?.label || el.type,
        code: el.code || '-',
        description: el.description || '-',
        net: toPoundCurrency(el.net),
        vat: toPoundCurrency(el.vatAmount),
        invoiceDocumentNumber: el.invoiceDocumentNumber || '-',
        state: recordsState.find((state) => state.value === el.state)?.label || el.state,
      })),
    };
  },
);

export const isThereCreditNominalRecordSelector = createSelector(
  nominalRecordTypesValuesSelector,
  assignedNominalCodesSelector,
  ({ credit }, { items }) => {
    return !!items.find((el) => el.type === credit);
  },
);

const assignedNominalCodesInitSummarySelector = (state: RootState) => state.invoicing.assignedNominalCodesSummary;
export const assignedNominalCodesSummarySelector = createSelector(
  assignedNominalCodesInitSummarySelector,
  isThereCreditNominalRecordSelector,
  (data, isThereCreditNominalRecord) => {
    const defaultValues = [
      {
        status: 'Requested',
        quantity: data?.requestedSummary.quantityString || '0.00',
        net: `£ ${data?.requestedSummary.netString || '0.00'}`,
        vat: `£ ${data?.requestedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.requestedSummary.grossString || 0.00}`,
      },
      {
        status: 'Received',
        quantity: data?.receivedSummary.quantityString || '0.00',
        net: `£ ${data?.receivedSummary.netString || '0.00'}`,
        vat: `£ ${data?.receivedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.receivedSummary.grossString || 0.00}`,
      },
      {
        status: 'Invoiced',
        quantity: data?.invoicedSummary.quantityString || '0.00',
        net: `£ ${data?.invoicedSummary.netString || '0.00'}`,
        vat: `£ ${data?.invoicedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.invoicedSummary.grossString || 0.00}`,
      },
      {
        status: 'Pending',
        quantity: data?.pendingSummary.quantityString || '0.00',
        net: `£ ${data?.pendingSummary.netString || '0.00'}`,
        vat: `£ ${data?.pendingSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.pendingSummary.grossString || 0.00}`,
      },
      {
        status: 'Unassigned',
        quantity: data?.unassignedSummary.quantityString || '0.00',
        net: `£ ${data?.unassignedSummary.netString || '0.00'}`,
        vat: `£ ${data?.unassignedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.unassignedSummary.grossString || 0.00}`,
      },
    ];
    const creditedRecord = {
      status: 'Credited',
      quantity: data?.creditedSummary.quantityString || '0.00',
      net: `£ ${data?.creditedSummary.netString || '0.00'}`,
      vat: `£ ${data?.creditedSummary.vatValueString || '0.00'}`,
      gross: `£ ${data?.creditedSummary.grossString || 0.00}`,
    };

    const valuesWithCredited = [
      ...defaultValues.slice(0, 3),
      creditedRecord,
      ...defaultValues.slice(3),
    ];

    const summaryValues = isThereCreditNominalRecord
      ? valuesWithCredited
      : defaultValues;
    return data
      ? summaryValues
      : undefined;
  },
);

const initSalesPartRequestLineSummarySelector = (state: RootState) => state.invoicing.salesPartRequestLineSummary;
export const isInvoicedAmountGreaterThanOrderSelector = createSelector(initSalesPartRequestLineSummarySelector, (data) => {
  return data
    ? (data.orderSummary.quantity || 0) < (data.invoicedSummary.quantity || 0)
    : false;
});
export const salesPartRequestLineSummarySelector = createSelector(
  initSalesPartRequestLineSummarySelector,
  isThereCreditNominalRecordSelector,
  (data, isThereCreditNominalRecord) => {
    const defaultValues = [
      {
        status: 'Order',
        quantity: data?.orderSummary?.quantityString || '0.00',
        net: `£ ${data?.orderSummary?.netString || '0.00'}`,
        vat: `£ ${data?.orderSummary?.vatValueString || '0.00'}`,
        gross: `£ ${data?.orderSummary?.grossString || 0.00}`,
      },
      {
        status: 'Invoiced',
        quantity: data?.invoicedSummary.quantityString || '0.00',
        net: `£ ${data?.invoicedSummary.netString || '0.00'}`,
        vat: `£ ${data?.invoicedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.invoicedSummary.grossString || 0.00}`,
      },
      {
        status: 'Pending',
        quantity: data?.pendingSummary.quantityString || '0.00',
        net: `£ ${data?.pendingSummary.netString || '0.00'}`,
        vat: `£ ${data?.pendingSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.pendingSummary.grossString || 0.00}`,
      },
      {
        status: 'Unassigned',
        quantity: data?.unassignedSummary.quantityString || '0.00',
        net: `£ ${data?.unassignedSummary.netString || '0.00'}`,
        vat: `£ ${data?.unassignedSummary.vatValueString || '0.00'}`,
        gross: `£ ${data?.unassignedSummary.grossString || 0.00}`,
      },
    ];
    const creditedRecord = {
      status: 'Credited',
      quantity: data?.creditedSummary.quantityString || '0.00',
      net: `£ ${data?.creditedSummary.netString || '0.00'}`,
      vat: `£ ${data?.creditedSummary.vatValueString || '0.00'}`,
      gross: `£ ${data?.creditedSummary.grossString || 0.00}`,
    };

    const valuesWithCredited = [
      ...defaultValues.slice(0, 3),
      creditedRecord,
      ...defaultValues.slice(3),
    ];

    const summaryValues = isThereCreditNominalRecord
      ? valuesWithCredited
      : defaultValues;
    return data
      ? summaryValues
      : undefined;
  },
);

export const isInvoicedAmountGreaterThanReceivedSelector = createSelector(assignedNominalCodesInitSummarySelector, (data) => {
  return data
    ? (data.receivedSummary.quantity || 0) < (data.invoicedSummary.quantity || 0)
    : false;
});

export const purchasingInvoiceTimelineFiltersSelector = (state: RootState) => state.invoicing.purchasingInvoiceTimelineFilters;
export const purchasingInvoiceTimelineSelector = (state: RootState) => state.invoicing.purchasingInvoiceTimeline;

export const invoiceDocumentsSummarySelector = (state: RootState) => state.invoicing.invoiceDocumentsSummary;
export const invoiceDocumentsFiltersSelector = (state: RootState) => state.invoicing.invoiceDocumentsFilters;

const invoiceDocumentsInitSelector = (state: RootState) => state.invoicing.invoiceDocuments;
export const invoiceDocumentsSelector = createSelector(
  invoiceDocumentsFiltersSelector,
  invoiceDocumentsInitSelector,
  invoiceDocumentStatusesSelector,
  (
    { page, pageSize },
    { items, ...rest },
    statuses,
  ) => {
    return {
      ...rest,
      items: items.map((el, i) => ({
        id: el.id,
        number: getNo(page, pageSize, i),
        itemName: el.supplierName || el.customerName || '-',
        itemNumber: el.purchaseOrderNumber || el.orderNumber,
        entityNumber: el.entityNumber,
        net: toPoundCurrency(el.net),
        gross: toPoundCurrency(el.gross),
        status: statuses.find((status) => status.value === el.status)?.label || '-',
      })),
    };
  },
);

export const salesSpecificEntitiesCheckboxFiltersSelector = createSelector(
  invoiceDocumentsFiltersSelector,
  customersDictionaryLookupSelector,
  invoiceDocumentTypesSelector,
  invoiceDocumentStatusesSelector,
  (
    filters,
    customers,
    types,
    statuses,
  ) => [
    {
      label: 'Customer',
      name: 'customerIds',
      value: filters.customerIds,
      options: customers,
    },
    {
      label: 'Entity type',
      name: 'types',
      value: filters.types,
      noSearch: true,
      options: types,
    },
    {
      label: 'Status',
      name: 'statuses',
      value: filters.statuses,
      noSearch: true,
      options: statuses,
    },
  ],
);

export const specificEntitiesCheckboxFiltersSelector = createSelector(
  invoiceDocumentsFiltersSelector,
  supplierDictionaryLookupSelector,
  invoiceDocumentTypesSelector,
  invoiceDocumentStatusesSelector,
  (
    filters,
    suppliers,
    types,
    statuses,
  ) => [
    {
      label: 'Supplier',
      name: 'supplierIds',
      value: filters.supplierIds,
      options: suppliers,
    },
    {
      label: 'Entity type',
      name: 'types',
      value: filters.types,
      noSearch: true,
      options: types,
    },
    {
      label: 'Status',
      name: 'statuses',
      value: filters.statuses,
      noSearch: true,
      options: statuses,
    },
  ],
);

export const specificEntitiesDateFiltersSelector = createSelector(
  invoiceDocumentsFiltersSelector,
  invoiceDocumentsInitSelector,
  (filters, {
    minInvoiceDate, maxInvoiceDate, minDueDate, maxDueDate,
  }) => [
    {
      label: 'Invoice date',
      name: 'invoiceDate',
      value: filters.invoiceDate,
      disabledDate: (current: moment.Moment) => current.isBefore(minInvoiceDate) || current.isAfter(moment(maxInvoiceDate).endOf('day')),
    },
    {
      label: 'Due date',
      name: 'dueDate',
      value: filters.dueDate,
      disabledDate: (current: moment.Moment) => current.isBefore(minDueDate) || current.isAfter(moment(maxDueDate).endOf('day')),
    },
  ],
);

export const invoicesAndCreditNotesFiltersSelector = (state: RootState) => state.invoicing.invoicesAndCreditNotesFilters;

const invoicesAndCreditNotesInitSelector = (state: RootState) => state.invoicing.invoicesAndCreditNotes;
export const invoicesAndCreditNotesSelector = createSelector(
  invoicesAndCreditNotesInitSelector,
  invoicesAndCreditNotesFiltersSelector,
  invoiceDocumentTypesSelector,
  invoiceDocumentStatusesSelector,
  (data, { page, pageSize }, types, statuses) => {
    return {
      ...data,
      items: data.items.map((el, i) => ({
        id: el.id,
        number: getNo(page, pageSize, i),
        entityNumber: el.entityNumber,
        typeLabel: types.find(({ value }) => value === el.type)?.label || '-',
        type: el.type,
        xeroRef: el.xeroRef,
        invoiceDate: el.invoiceDate,
        dueDate: el.dueDate,
        net: toPoundCurrency(el.net),
        vat: toPoundCurrency(el.vatValue),
        gross: toPoundCurrency(el.gross),
        statusLabel: statuses.find(({ value }) => value === el.status)?.label || '-',
        status: el.status,
      })),
    };
  },
);

export const invoiceNominalCodesFiltersSelector = (state: RootState) => state.invoicing.invoiceNominalCodesFilters;
const invoiceNominalCodesInitSelector = (state: RootState) => state.invoicing.invoiceNominalCodes;
export const invoiceNominalCodesSelector = createSelector(
  invoiceNominalCodesFiltersSelector,
  invoiceNominalCodesInitSelector,
  nominalRecordStatesSelector,
  ({ page, pageSize }, data, states) => {
    return {
      ...data,
      items: data.items.map((el, i) => ({
        id: el.id,
        number: getNo(page, pageSize, i),
        productNumber: el.productNumber || '-',
        productName: el.productName,
        nominalCodeIds: el.code,
        description: el.description,
        net: toPoundCurrency(el.net),
        vat: toPoundCurrency(el.vatAmount),
        state: states.find(({ value }) => value === el.state)?.label || '',
      })),
    };
  },
);

export const invoiceNominalCodesColumnsSelector = createSelector(
  invoiceNominalCodesFiltersSelector,
  invoiceNominalCodesInitSelector,
  (filters, data) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        filterSearch: true,
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        filterSearch: true,
      },
      {
        title: 'Code',
        dataIndex: 'nominalCodeIds',
        filters: data.nominalCodes,
        value: filters.nominalCodeIds,
      },
      {
        title: 'Description',
        dataIndex: 'description',
      },
      {
        title: 'Net',
        dataIndex: 'net',
      },
      {
        title: 'VAT',
        dataIndex: 'vat',
      },
      {
        title: 'State',
        dataIndex: 'state',
      },
    ];
  },
);

export const invoiceNominalCodesSummarySelector = (state: RootState) => state.invoicing.invoiceNominalCodesSummary;
