import React from 'react';
import Layout from '../core/components/layout/Layout';
import ReportsFilters from './components/reports-filters/ReportsFilters';
import './ReportsContainer.scss';
import ReportsContent from './components/reports-content/ReportsContent';

const ReportsContainer: React.FC = () => {
  return (
    <Layout>
      <ReportsFilters />
      <ReportsContent />
    </Layout>
  );
};

export default ReportsContainer;
