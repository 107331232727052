import React from 'react';
import classNames from 'classnames';

type ThNewProps = {
  children: React.ReactNode,
  checkbox?: boolean,
  action?: boolean,
  className?: string,
  colSpan?: number,
  alignCenter?: boolean,
}

const ThNew: React.FC<ThNewProps> = ({
  children,
  checkbox,
  action,
  className,
  colSpan,
  alignCenter,
}) => {
  return (
    <th
      colSpan={colSpan}
      className={classNames('table-new-th', {
        'table-new-th--checkbox': checkbox,
        'table-new-th--action': action,
        'table-new-th--centered': alignCenter,
      }, className)}
    >
      {children}
    </th>
  );
};

export default ThNew;
