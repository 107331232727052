import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType, CoreAttachmentsFilters,
  initialCoreAttachmentsFilters, ResponseSingleResult, VoidFunctionType, AxiosErrorResponse, GetAttachmentsResponse, emptyPaging,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import { uploadAttachmentsAsyncHandle } from '../../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import { setPurchaseOrderAttachments, setPurchaseOrderAttachmentsFilter } from '../../../slices/purchaseOrderSlice';
import { downloadCsv } from '../../../../core/utils/downloadFileHandler';
import { getPurchaseOrderById } from './purchaseOrderDetailsThunks';
import { RootState } from '../../../store';

export const getPurchaseOrderAttachments = createAsyncThunk<GetAttachmentsResponse,
{
  purchaseOrderId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/PurchaseOrderAttachments',
  async ({ purchaseOrderId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPurchaseOrderAttachments(purchaseOrderId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPurchaseOrderAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{ id: number, list: CoreAttachListElType[], comment: string, onCancel: VoidFunctionType }
>('post/PurchaseOrderAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { purchaseOrder } = getState() as RootState;
    const { purchaseOrderAttachmentsFilters } = purchaseOrder;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await stockApi.postPurchaseOrderAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await stockApi.postPurchaseOrderAttachment(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getPurchaseOrderById({ id }));
    dispatch(getPurchaseOrderAttachments({ purchaseOrderId: +id, filters: purchaseOrderAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultiplePurchaseOrderAttachmentThunk = createAsyncThunk<null,
{
  purchaseOrderId:number,
  ids: number[],
  closeModal: VoidFunctionType,
}>(
  'delete/MultiplePurchaseOrderAttachment',
  async ({
    purchaseOrderId, ids, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { purchaseOrder: { purchaseOrderAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPurchaseOrderAttachments(purchaseOrderId, {
        ...purchaseOrderAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await stockApi.deleteMultiplePurchaseOrderAttachment(purchaseOrderId, filteredIds);
      }
      dispatch(setPurchaseOrderAttachmentsFilter({ ...purchaseOrderAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderAttachmentByIdThunk = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, purchaseOrderById: number }
>('get/PurchaseOrderAttachmentById', async ({ id, purchaseOrderById }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.getPurchaseOrderAttachmentById(id, purchaseOrderById);
    downloadCsv(response.data.data.uri);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editPurchaseOrderAttachmentThunk = createAsyncThunk<
ResponseSingleResult,
{ id: number, purchaseOrderId:number, comment: string, onCancel: VoidFunctionType }
>('patch/PurchaseOrderAttachment', async ({
  id, purchaseOrderId, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { purchaseOrder } = getState() as RootState;
    const { purchaseOrderAttachments } = purchaseOrder;
    const response = await stockApi.editPurchaseOrderAttachment(id, purchaseOrderId, comment);
    const newArr = purchaseOrderAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setPurchaseOrderAttachments({ ...purchaseOrderAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deletePurchaseOrderAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, purchaseOrderId:number, closeModal: VoidFunctionType, }>(
  'delete/PurchaseOrderAttachment',
  async ({
    id, purchaseOrderId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.deletePurchaseOrderAttachment(id, purchaseOrderId);
      dispatch(getPurchaseOrderAttachments({
        purchaseOrderId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setPurchaseOrderAttachmentsFilter(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
