export enum PurchaseOrderPartVatEnum {
  ZeroPercent = '0%',
  FivePercent = '5%',
  TwentyPercent = '20%',
}

export enum PurchaseOrderPartsOrderingEnum {
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  RequestedQuantity = 'RequestedQuantity',
  CostPrice = 'CostPrice',
  RequestedNet = 'RequestedNet',
  RequestedGross = 'RequestedGross',
  TotalReceivedQuantity = 'TotalReceivedQuantity',
  CancelledQuantity = 'CancelledQuantity',
  DueIn = 'DueIn',
  State = 'State',
  UnassignedNet = 'UnassignedNet',
}
