import React, { useEffect } from 'react';
import StockSearchTable from './components/search-table/StockSearchTable';
import StockSearchFilters from './components/search-filters/StockSearchFilters';
import {
  stockSearchFiltersSelector,
  stockTableModeResultSelector,
} from '../../store/selectors/stockSelectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Pagination from '../../core/components/pagination/Pagination';
import { setStockSearchFilters } from '../../store/slices/stockSlice';
import { getStockSearch } from '../../store/thunks/stock/stockThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { vehicleProfilesModelDescrOrderSelector } from '../../store/selectors/coreOrderingSelectors';
import { getSuitableVehicleProfiles } from '../../store/thunks/shared/sharedThunks';
import { getStockPartsStatuses } from '../../store/thunks/core/coreStatusesThunks';
import { getStockPartsOrdering } from '../../store/thunks/core/coreOrderingThunks';

const StockSearch: React.FC = () => {
  const dispatch = useAppDispatch();
  const stockSearchFilters = useAppSelector(stockSearchFiltersSelector);
  const stockSearchResult = useAppSelector(stockTableModeResultSelector);
  const vehModelDescrOrder = useAppSelector(vehicleProfilesModelDescrOrderSelector);
  useEffect(() => {
    dispatch(getStockPartsStatuses());
    dispatch(getStockPartsOrdering());
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    vehModelDescrOrder && dispatch(getSuitableVehicleProfiles({
      filters: { order: { field: vehModelDescrOrder, isAsc: true } },
    }));
    // eslint-disable-next-line
  }, [vehModelDescrOrder]);
  return (
    <div className="stockSearch">
      <StockSearchFilters filters={stockSearchFilters} />
      <Pagination
        currentPage={stockSearchFilters.page}
        pageSize={stockSearchFilters.pageSize}
        totalItems={stockSearchResult.totalCount}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => {
          dispatch(setStockSearchFilters({ ...stockSearchFilters, pageSize, page }));
          (stockSearchFilters.keyword || stockSearchFilters.subcategoryIds?.length) && dispatch(getStockSearch({
            filters: { ...stockSearchFilters, page, pageSize },
          }));
        }}
        className="stockSearch__paging actions-and-paging__paging"
        narrow
      />
      <StockSearchTable filters={stockSearchFilters} stockSearchResult={stockSearchResult} />
    </div>
  );
};

export default StockSearch;
