import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { AttachmentsTableProps } from '../../../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../../../store/hooks';
import EditCommentModal from '../../../../../../../common/components/edit-attachment-comment/EditCommentModal';
import { editPartsKitAttachment } from '../../../../../../../store/thunks/stock/parts-kit/partsKitAttachments';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import PartsKitAttachmentCell from './PartsKitAttachmentCell';
import PartsKitAttachmentColumns from './PartsKitAttachmentColumns';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import AttachmentsTable from '../../../../../../../common/components/attachments-table/AttachmentsTable';

const PartsKitAttachmentTable:React.FC<AttachmentsTableProps> = ({
  attachments,
  checkedKeys,
  isAll,
  indeterminate,
  checkAllHandle,
  checkRow,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isEditModal, setEditModal] = useState(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState('');

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };

  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => attachToEdit && id && dispatch(editPartsKitAttachment({
          id: attachToEdit,
          partsKitId: +id,
          comment: editedComment.trim(),
          onCancel: cancelHandle,
        }))}
      />
      <AttachmentsTable>
        <PartsKitAttachmentColumns
          isAll={isAll}
          indeterminate={indeterminate}
          checkAll={checkAllHandle}
          totalElements={attachments.length}
        />
        <Tbody>
          {attachments.length > 0
            ? attachments.map((attachment) => (
              <PartsKitAttachmentCell
                key={attachment.id}
                attach={attachment}
                openEditModal={() => {
                  setAttachToEdit(attachment.id);
                  setEditedComment(attachment.comment || '');
                  setEditModal(true);
                }}
                checked={checkedKeys.includes(attachment.id)}
                checkRow={() => checkRow(attachment.id)}
              />))
            : <EmptyTableSection text="No records" colSpan={6} />}
        </Tbody>
      </AttachmentsTable>
    </>
  );
};

export default PartsKitAttachmentTable;
