import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../core/components/modal/Modal';
import { initSmallPaging, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Input from '../../../../../core/components/input/Input';
import './EditReadyCollected.scss';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Button from '../../../../../core/components/button/Button';
import EditReadyCollectedTable from './EditReadyCollectedTable';
import {
  setEditReadyCollectedModalAvailablePurchaseOrders,
  setEditReadyCollectedModalFilters, setEditReadyCollectedModalQuantities,
} from '../../../../../store/slices/stockPartRequestSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  editReadyCollectedModalFiltersSelector,
  editReadyCollectedModalQuantitiesSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import { isFetchingSelector, QuoteUnitsOfMeasureSelector } from '../../../../../store/selectors/coreSelectors';
import { PartReqPartQuantityFilters } from '../../../../stock-part-requests/types/stockPartRequestTypes';
import { getEditReadyCollectedPartQuantities } from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { getStockPartReqQuantitiesOrdering } from '../../../../../store/thunks/core/coreOrderingThunks';
import { editReadyCollectedModalOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { partQtyStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { PartRequestLineDto } from '../../../../../common/types/commonTypes';

type EditReadyCollectedModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  setConfirmEditMode: (v: 'split' | 'allocate' | 'ready' | 'collect' | string, partQuantityId: number) => void,
  openAllocatePricelist: VoidFunctionType,
  currentLine?: PartRequestLineDto | null,
}

const EditReadyCollectedModal: React.FC<EditReadyCollectedModalProps> = ({
  visible, onCancel, setConfirmEditMode, openAllocatePricelist, currentLine,
}) => {
  const { id } = useParams();
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(editReadyCollectedModalFiltersSelector);
  const dataLines = useAppSelector(editReadyCollectedModalQuantitiesSelector);
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const editReadyCollectedModalOrdering = useAppSelector(editReadyCollectedModalOrderingSelector);
  const { allocated, picked, collected } = useAppSelector(partQtyStatusesValuesSelector);

  const currentUnitOfMeasure = unitOfMeasures.find((el) => el.value === currentLine?.unitOfMeasure)?.label || '';

  useEffect(() => {
    if (visible) {
      dispatch(getStockPartReqQuantitiesOrdering());
    }
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if (!filters.order && editReadyCollectedModalOrdering.length > 0) {
      dispatch(setEditReadyCollectedModalFilters({ ...filters, order: { field: editReadyCollectedModalOrdering[0].value, isAsc: true } }));
    }
    // eslint-disable-next-line
  }, [filters.order, editReadyCollectedModalOrdering.length]);

  useEffect(() => {
    if (visible && !filters.statuses?.length && allocated) {
      dispatch(setEditReadyCollectedModalFilters({ ...filters, statuses: [allocated] }));
    }
    // eslint-disable-next-line
  }, [visible, filters.statuses, allocated]);

  useEffect(() => {
    if (visible && filters.statuses?.length && filters.order && currentLine?.id) {
      const partId = currentLine?.partId || currentLine?.partsKitComponentPartId;
      const purchaseOrdersIdsFilter = filters.purchaseOrderIds;
      const finalFilters = {
        ...filters,
        isAvailableForPartRequestId: id ? +id : undefined,
        purchaseOrderIds: purchaseOrdersIdsFilter && purchaseOrdersIdsFilter.length > 0
          ? (purchaseOrdersIdsFilter.length === 1 && purchaseOrdersIdsFilter[0] === 'null')
            ? ['']
            : purchaseOrdersIdsFilter
          : undefined,
      };

      partId && filters.statuses.length && dispatch(getEditReadyCollectedPartQuantities({
        partId,
        lineId: currentLine.id,
        filters: finalFilters,
      }));
    }
    // eslint-disable-next-line
  }, [visible, filters, currentLine?.id]);

  const getStatusQuantityLabel = (status?: number) => {
    const qty = dataLines?.statusQuantities?.find((el) => el.status === status)?.quantity;
    return qty && qty > 0 ? `(${qty})` : '';
  };
  const isAllocatedBtnVisible = filters.statuses && filters.statuses.find((el) => el === allocated);
  const isAllocatedBtnEnabled = currentLine?.toBeAllocatedQuantity && currentLine?.toBeAllocatedQuantity > 0;

  const setInitTableValues = (filters: PartReqPartQuantityFilters) => {
    dispatch(setEditReadyCollectedModalQuantities(null));
    dispatch(setEditReadyCollectedModalFilters(filters));
    dispatch(setEditReadyCollectedModalAvailablePurchaseOrders(null));
  };
  const cancelModalHandle = () => {
    onCancel();
    setInitTableValues(initSmallPaging);
  };

  const switchStatus = (status: number) => {
    setInitTableValues({ ...filters, statuses: [status] });
  };
  return (
    <Modal
      visible={visible}
      onCancel={cancelModalHandle}
      destroyOnClose
      title="Edit ready/collected"
      width={980}
    >
      <div className="editReadyCollectedWrap">
        <div className="editReadyCollectedWrap__inputs">
          <Input
            label="Requested quantity"
            value={currentLine?.requestedQuantity}
            readOnly
          />
          <Input
            label="To be allocated"
            value={currentLine?.toBeAllocatedQuantity}
            readOnly
          />
        </div>
        <div className="editReadyCollectedWrap__tabs">
          <Button
            label={`Allocated ${getStatusQuantityLabel(allocated)}`}
            onClick={() => allocated && switchStatus(allocated)}
            designType="light-inverse"
            isActivated={!!filters.statuses?.find((el) => el === allocated)}
            disabled={isFetching}
          />
          <Button
            label={`Ready ${getStatusQuantityLabel(picked)}`}
            onClick={() => picked && switchStatus(picked)}
            designType="light-inverse"
            isActivated={!!filters.statuses?.find((el) => el === picked)}
            disabled={isFetching}
          />
          <Button
            label={`Collected ${getStatusQuantityLabel(collected)}`}
            onClick={() => collected && switchStatus(collected)}
            designType="light-inverse"
            isActivated={!!filters.statuses?.find((el) => el === collected)}
            disabled={isFetching}
          />
        </div>
        <Pagination
          currentPage={filters.page}
          totalItems={dataLines?.partQuantities?.totalCount || 0}
          pageSize={filters.pageSize}
          onChange={(page, pageSize) => dispatch(setEditReadyCollectedModalFilters({ ...filters, page, pageSize }))}
          showTotal
          showSizeChanger
          className="editReadyCollectedWrap__pagination"
        />
        <EditReadyCollectedTable
          setConfirmEditMode={setConfirmEditMode}
          className={classNames('editReadyCollectedWrap__table', {
            'allocated': !!filters.statuses?.find((el) => el === allocated),
            'ready': !!filters.statuses?.find((el) => el === picked),
            'collected': !!filters.statuses?.find((el) => el === collected),
          })}
          currentUnitOfMeasure={currentUnitOfMeasure}
        />
        {isAllocatedBtnVisible && <Button
          type="text"
          htmlType="button"
          label="Allocate pricelist quantity"
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => openAllocatePricelist()}
          disabled={!isAllocatedBtnEnabled}
        />}
      </div>
    </Modal>
  );
};

export default EditReadyCollectedModal;
