import React, { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { jobLinksFiltersSelector, jobLinksRowsSelector } from '../../../../../store/selectors/jobsSelector';
import { getJobLinksThunk } from '../../../../../store/thunks/jobs/jobLinksThunks';
import JobLinksColumns from '../../../../job-view-page/components/job-content/links/JobLinksColumns';
import LinksBody from '../../../../../common/components/links-table/LinksBody';
import LinksTable from '../../../../../common/components/links-table/LinksTable';

type JobLineExpandedLinksProps = {
  jobId: number,
}

const JobLineExpandedLinks: React.FC<JobLineExpandedLinksProps> = ({ jobId }) => {
  const jobLinksFilters = useAppSelector(jobLinksFiltersSelector);
  const jobLinks = useAppSelector(jobLinksRowsSelector);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(getJobLinksThunk({ jobId, params: jobLinksFilters }));
    // eslint-disable-next-line
  }, [jobLinksFilters]);
  return (
    <div>
      <LinksTable>
        <JobLinksColumns totalCount={jobLinks.length} />
        <LinksBody links={jobLinks} />
      </LinksTable>
    </div>
  );
};

export default JobLineExpandedLinks;
