import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PricelistDashFiltersType,
  PricelistDto, PricelistPartDto, PricesFiltersType,
} from '../../pricelists/dashboard/types/pricelistTypes';
import {
  CoreAttachmentsFilters, CoreAttachmentsList,
  DataListType, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters, initPaging,
  initSmallPaging,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import { getPricelists } from '../thunks/pricelist/pricelistDashboardThunks';
import {
  getPartPricelistCategories, getPartPricelistSubcategories,
  getPartPricesThunk, getPricelistAffectedPartsThunk, getPricelistAffectedThunk,
  getPricelistById, getPricelistTimelineThunk,
  getPricesPartsThunk, getStockPartsThunk,
} from '../thunks/pricelist/pricelistDetailsThunks';
import {
  PricelistAffectedDto, PricelistAffectedFilters,
  PricelistEventDtoBase,
  PricelistPartPriceDto, PricelistSupplier,
} from '../../pricelists/pricelist-view-page/types/pricelistViewPageTypes';
import { getPricelistAttachments } from '../thunks/pricelist/pricelistAttachmentsThunks';
import { getPricelistSuppliers } from '../thunks/pricelist/pricelistThunks';
import { ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';
import { PartDtoType, StockFiltersType } from '../../stock/search/types/stockSearchTypes';
import { PartCategoryDto, PartSubcategoryDto } from '../../stock/part-categories/types/partCategoriesTypes';

export interface IPricelistState {
  pricelistFilters: PricelistDashFiltersType,
  pricelists: DataListType<PricelistDto[]>,
  pricelistDetails: PricelistDto | null,
  pricelistAffectedFilters: PricelistAffectedFilters,
  pricelistAffectedItems: DataListType<PricelistAffectedDto[]>,
  pricelistAffectedPartsFilters: StockFiltersType,
  pricelistAffectedParts: DataListType<PartDtoType[]>
  pricesFilters: PricesFiltersType,
  pricelistParts: DataListType<PricelistPartDto[], { allItemIds: number[] }>,
  stockParts: DataListType<PartDtoType[], { allItemIds: number[] }>,
  addPartFilters: StockFiltersType,
  initCategories: Array<PartCategoryDto> | null,
  initSubCategories: Array<PartSubcategoryDto> | null,
  stockCategories: Array<PartCategoryDto>,
  stockSubcategories: Array<PartSubcategoryDto>,
  partPrices: DataListType<PricelistPartPriceDto[]>,
  pricelistAttachments: CoreAttachmentsList,
  pricelistAttachmentsFilters: CoreAttachmentsFilters,
  pricelistSuppliers: Array<PricelistSupplier>,
  pricelistTimeline: DataListType<PricelistEventDtoBase[]>,
  pricelistTimelineFilters: ExtendedTimelineFilters,
}

export const initialPricelistState: IPricelistState = {
  pricelistFilters: initPaging,
  pricelists: initDataList,
  pricelistDetails: null,
  pricelistAffectedFilters: initSmallPaging,
  pricelistAffectedItems: initDataList,
  pricelistAffectedPartsFilters: initSmallPaging,
  pricelistAffectedParts: initDataList,
  pricesFilters: initPaging,
  pricelistParts: initDataListWithIds,
  stockParts: initDataListWithIds,
  addPartFilters: initSmallPaging,
  stockCategories: [],
  stockSubcategories: [],
  initCategories: null,
  initSubCategories: null,
  partPrices: initDataList,
  pricelistAttachments: initDataListWithIds,
  pricelistAttachmentsFilters: initialCoreAttachmentsFilters,
  pricelistSuppliers: [],
  pricelistTimeline: initDataList,
  pricelistTimelineFilters: initExtendedTimelineFilters,
};

export const pricelistSlice = createSlice({
  name: 'pricelist',
  initialState: initialPricelistState,
  reducers: {
    setPricelistFilters: (state, action: PayloadAction<PricelistDashFiltersType>) => {
      state.pricelistFilters = action.payload;
    },
    setPricelists: (state, action: PayloadAction<DataListType<PricelistDto[]>>) => {
      state.pricelists = action.payload;
    },
    setPricelistDetails: (state, action: PayloadAction<PricelistDto | null>) => {
      state.pricelistDetails = action.payload;
    },
    setPricelistAffectedFilters: (state, action: PayloadAction<PricelistAffectedFilters>) => {
      state.pricelistAffectedFilters = action.payload;
    },
    setPricelistAffectedItems: (state, action: PayloadAction<DataListType<PricelistAffectedDto[]>>) => {
      state.pricelistAffectedItems = action.payload;
    },
    setPricelistAffectedPartsFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.pricelistAffectedPartsFilters = action.payload;
    },
    setPricelistAffectedParts: (state, action: PayloadAction<DataListType<PartDtoType[]>>) => {
      state.pricelistAffectedParts = action.payload;
    },
    setPricesFilters: (state, action: PayloadAction<PricesFiltersType>) => {
      state.pricesFilters = action.payload;
    },
    setPricelistParts: (state, action: PayloadAction<DataListType<PricelistPartDto[], { allItemIds: number[] }>>) => {
      state.pricelistParts = action.payload;
    },
    setStockParts: (state, action: PayloadAction<DataListType<PartDtoType[], { allItemIds: number[] }>>) => {
      state.stockParts = action.payload;
    },
    setStockPartsFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.addPartFilters = action.payload;
    },
    setStockCategories: (state, action: PayloadAction<Array<PartCategoryDto>>) => {
      state.stockCategories = action.payload;
    },
    setStockSubcategories: (state, action: PayloadAction<Array<PartSubcategoryDto>>) => {
      state.stockSubcategories = action.payload;
    },
    setInitStockCategories: (state, action: PayloadAction<Array<PartCategoryDto> | null>) => {
      state.initCategories = action.payload;
    },
    setInitStockSubcategories: (state, action: PayloadAction<Array<PartSubcategoryDto> | null>) => {
      state.initSubCategories = action.payload;
    },
    setPartPrices: (state, action: PayloadAction<DataListType<PricelistPartPriceDto[]>>) => {
      state.partPrices = action.payload;
    },
    setPricelistAttachmentsFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.pricelistAttachmentsFilters = action.payload;
    },
    setPricelistAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.pricelistAttachments = action.payload;
    },
    setPricelistTimeline: (state, action: PayloadAction<DataListType<PricelistEventDtoBase[]>>) => {
      state.pricelistTimeline = action.payload;
    },
    setPricelistTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.pricelistTimelineFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getPricelists.fulfilled, (state, action: PayloadAction<ResponseResult<PricelistDto[]> | null>) => {
      state.pricelists = action.payload ? action.payload?.data : initDataList;
    });
    builder.addCase(
      getPricelistById.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ pricelist: PricelistDto }> | null>) => {
        state.pricelistDetails = action.payload?.data.pricelist || null;
      },
    );
    builder.addCase(
      getPricelistAffectedThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PricelistAffectedDto[]>>) => {
        state.pricelistAffectedItems = action.payload.data;
      },
    );
    builder.addCase(
      getPricelistAffectedPartsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[]>>) => {
        state.pricelistAffectedParts = action.payload.data;
      },
    );
    builder.addCase(
      getPricesPartsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PricelistPartDto[], { allItemIds: number[] }>>) => {
        state.pricelistParts = action.payload.data;
      },
    );
    builder.addCase(
      getStockPartsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[], { allItemIds: number[] }>>) => {
        state.stockParts = action.payload.data;
      },
    );
    builder.addCase(getPartPricelistCategories.fulfilled, (state, action: PayloadAction<ResponseResult<PartCategoryDto[]>>) => {
      state.stockCategories = action.payload.data.items;
      if (!state.initCategories) {
        state.initCategories = action.payload.data.items;
      }
    });
    builder.addCase(getPartPricelistSubcategories.fulfilled, (state, action: PayloadAction<ResponseResult<PartSubcategoryDto[]>>) => {
      state.stockSubcategories = action.payload.data.items;
      if (!state.initSubCategories) {
        state.initSubCategories = action.payload.data.items;
      }
    });
    builder.addCase(getPartPricesThunk.fulfilled, (state, action: PayloadAction<ResponseResult<PricelistPartPriceDto[]>>) => {
      state.partPrices = action.payload ? { ...action.payload.data } : initDataList;
    });
    builder.addCase(getPricelistAttachments.fulfilled, (state, action: PayloadAction<GetAttachmentsResponse>) => {
      state.pricelistAttachments = action.payload.data;
    });
    builder.addCase(
      getPricelistSuppliers.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{'suppliers': PricelistSupplier[] | null}> | null>) => {
        const suppliers = action.payload ? action.payload.data.suppliers : [];
        state.pricelistSuppliers = suppliers || [];
      },
    );
    builder.addCase(
      getPricelistTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PricelistEventDtoBase[]>>) => {
        state.pricelistTimeline = action.payload.data;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setPricelistFilters,
  setPricelistDetails,
  setPricelistAffectedFilters,
  setPricelistAffectedItems,
  setPricelistAffectedPartsFilters,
  setPricelistAffectedParts,
  setPricesFilters,
  setStockPartsFilters,
  setStockParts,
  setStockCategories,
  setStockSubcategories,
  setInitStockCategories,
  setInitStockSubcategories,
  setPartPrices,
  setPricelistParts,
  setPricelistAttachments,
  setPricelistAttachmentsFilters,
  setPricelists,
  setPricelistTimeline,
  setPricelistTimelineFilters,
} = pricelistSlice.actions;

export default pricelistSlice.reducer;
