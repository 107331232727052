import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { enquiryAPI } from '../../../api/enquiryApi';
import {
  CreateEnquiryFormFieldsType,
  CreateEnquiryResponse,
} from '../../../enquiry/create-enquiry/types/createEnquiryTypes';
import { routesPath } from '../../../core/enums/pathEnum';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { setFetching, setSuccessMessage } from '../../slices/coreSlice';
import { CreateEnquiryFields } from '../../../enquiry/create-enquiry/utils/CreateEnquirySchema';

export const createEnquiryThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>,
{
  data: CreateEnquiryFormFieldsType,
  navigate: NavigateFunction,
  setError: UseFormSetError<CreateEnquiryFields>,
  clearForm: VoidFunctionType,
}
>(
  'post/EnquiryCreate',
  async ({
    data, navigate, setError, clearForm,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const enquiryRes = await enquiryAPI.createEnquiry(data);
      dispatch(setFetching(false));
      const enquiryId = enquiryRes.data.data.createdId;
      const { enquiryNumber, customerName } = enquiryRes.data.data;

      const commonActionsHandle = () => {
        dispatch(setSuccessMessage({ message: `Customer ${customerName} was successfully created.` }));
        dispatch(setSuccessMessage({ message: `Enquiry ${enquiryNumber} was successfully created` }));
        navigate(`/${routesPath.ENQUIRIES}/${enquiryId}`, { replace: true });
      };
      commonActionsHandle();
      clearForm();

      return enquiryRes.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const accountNoError = errors.find((err) => err.key === 'CustomerAccountNumber');
      dispatch(setFetching(false));
      if (accountNoError) {
        setError('customerAccountNumber', { type: `${accountNoError.message}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEnquiryWithoutCustomerThunk = createAsyncThunk<
ResponseSingleResult<CreateEnquiryResponse>,
{
  data: CreateEnquiryFormFieldsType,
  navigate: NavigateFunction,
  clearForm: VoidFunctionType,
}
>(
  'post/EnquiryCreateWithoutCustomer',
  async ({
    data, navigate, clearForm,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const enquiryRes = await enquiryAPI.createEnquiry(data);
      dispatch(setFetching(false));
      const enquiryId = enquiryRes.data.data.createdId;
      const { enquiryNumber } = enquiryRes.data.data;

      const commonActionsHandle = () => {
        dispatch(setSuccessMessage({ message: `Enquiry ${enquiryNumber} was successfully created` }));
        navigate(`/${routesPath.ENQUIRIES}/${enquiryId}`, { replace: true });
      };
      commonActionsHandle();
      clearForm();

      return enquiryRes.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
