import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  ResponseSingleResult,
  GenericVoidFunctionType,
  AxiosErrorResponse,
} from '../../../core/types/coreTypes';
import { settingsAPI } from '../../../api/settingsApi';
import { FinalizeReqDataType } from '../../../settings/account-finalization/types/accountFinalizationTypes';
import { accountAPI } from '../../../api/accountApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { setFetching } from '../../slices/coreSlice';
import { addTokenHandler } from '../../../core/utils/removeTokenHandler';
import { AccountFinalizationData } from '../../../settings/account-finalization/AccountFinalizationContainer';

export const validateInviteTokenThunk = createAsyncThunk<ResponseSingleResult<{
  email: string, firstName: string, lastName: string,
}> | null,
{ token: string, setData: GenericVoidFunctionType<AccountFinalizationData> }
>(
  'post/ValidateInvite',
  async ({ token, setData }, { rejectWithValue }) => {
    try {
      const res = await settingsAPI.validateInviteToken(token);
      const { email, firstName, lastName } = res.data.data;
      const data = { email, firstName, lastName };
      setData(data);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const finalizeUserProfileThunk = createAsyncThunk<ResponseSingleResult,
{ token: string, data: FinalizeReqDataType, email: string, navigate: NavigateFunction }
>(
  'post/FinalizeProfile',
  async ({
    token, data, email, navigate,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await settingsAPI.finaliseUserProfile(token, data);
      dispatch(setFetching(false));
      if (res.data.success) {
        const loginResponse = await accountAPI.login(email, data.password);
        const { authToken } = loginResponse.data.data;
        addTokenHandler(dispatch, authToken);
        navigate(`/${routesPath.HOME}`, { replace: true });
      }
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
