import React, { useCallback } from 'react';
import { PermissionEnum } from '../core/enums/dictionariesEnums';
import PermissionContext from './PermissionContext';

type PermissionProviderProps = {
  permissions?: PermissionEnum[],
  children: JSX.Element,
}

// This provider is intended to be surrounding the whole application.
// It should receive a method to fetch permissions as parameter
const PermissionProvider: React.FC<PermissionProviderProps> = ({ permissions, children }) => {
// Creates a method that returns whether the requested permission is granted to the current user
  const isAllowedTo = useCallback((permission: PermissionEnum) => permissions?.includes(permission), [permissions]);

  // This component will render its children wrapped around a PermissionContext's provider whose
  // value is set to the method defined above
  // eslint-disable-next-line react/jsx-no-constructed-context-values
  return <PermissionContext.Provider value={{ isAllowedTo }}>{children}</PermissionContext.Provider>;
};

export default PermissionProvider;
