import React from 'react';
import { useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { AccountViewModeLabels, AccountViewModeParams } from '../../enums/AccountViewEnums';
import { leaveRequestsStatusCategories } from '../account-content/leave-requests/utils/data';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { accountLeaveRequestsFiltersSelector } from '../../../store/selectors/accountSelectors';
import { setAccountLeaveRequestsFilters } from '../../../store/slices/accountSlice';
import './AccountFilters.scss';
import { LeaveRequestsStatusCategoriesEnum } from '../../../core/enums/dictionariesEnums';

const AccountFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const [params, setSearchParams] = useSearchParams();
  const leaveRequestsFilters = useAppSelector(accountLeaveRequestsFiltersSelector);
  const mode = params.get('mode');

  const tabs = [
    {
      label: AccountViewModeLabels.ACCOUNT_DETAILS,
      value: AccountViewModeParams.ACCOUNT_DETAILS,
    },
    {
      label: AccountViewModeLabels.LEAVE_REQUESTS,
      value: AccountViewModeParams.LEAVE_REQUESTS,
    },
  ];

  const mobileView = !!windowWidth && windowWidth <= responsiveEndpointsEnum.LG;

  const changeLeaveRequestsStatus = (value: string | number) => {
    dispatch(setAccountLeaveRequestsFilters({
      ...leaveRequestsFilters,
      page: 1,
      isActive: value === LeaveRequestsStatusCategoriesEnum.ACTIVE,
    }));
  };

  return (
    <div className={classNames('accountFilters sections-and-filters', {
      'mobile-sticky-filters mobile-sticky-filters--top-md': mobileView,
      'desktop-sticky-filters': !mobileView,
    })}
    >
      {mobileView
        ? <Selectable
          options={tabs}
          onChange={(value) => setSearchParams({ mode: value as string })}
          value={mode || ''}
        />
        : <Segmented
          options={tabs}
          onChange={(value) => setSearchParams({ mode: value as string })}
          value={mode || ''}
        />}
      {mode === AccountViewModeParams.LEAVE_REQUESTS && (
        mobileView
          ? <Selectable
            options={leaveRequestsStatusCategories}
            value={leaveRequestsFilters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
            onChange={changeLeaveRequestsStatus}
            size="sm"
          />
          : <Segmented
            options={leaveRequestsStatusCategories}
            value={leaveRequestsFilters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
            onChange={changeLeaveRequestsStatus}
          />
      )}
    </div>
  );
};

export default AccountFilters;
