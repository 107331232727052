import React, { useEffect, useState } from 'react';
import { Divider } from 'antd';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  invoiceDocumentsSelector,
  invoiceDocumentsSummarySelector,
} from '../../../../store/selectors/invoicingSelectors';
import {
  exportInvoiceDocumentsCsvFileThunk,
  getInvoiceDocumentsSummaryThunk,
} from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { toPoundCurrency } from '../../../../common/utils/formatUtils';
import { isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';

type DraftEntitiesContentProps = {
  onCancel: VoidFunctionType,
  origin: 'purchases' | 'sales',
}

const DraftEntitiesContent: React.FC<DraftEntitiesContentProps> = ({ onCancel, origin }) => {
  const dispatch = useAppDispatch();
  const { allCreditNoteIds, allInvoiceIds, allItemIds } = useAppSelector(invoiceDocumentsSelector);
  const { draftInvoicesSummary, draftCreditNotesSummary } = useAppSelector(invoiceDocumentsSummarySelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const [isInvoices, setIsInvoices] = useState(false);
  const [isCreditNotes, setIsCreditNotes] = useState(false);
  const [isMarkAsExportedAfterCSV, setIsMarkAsExportedAfterCSV] = useState(true);
  const isExportAllowed = (isInvoices && !!draftInvoicesSummary?.invoiceDocumentsCount)
    || (isCreditNotes && !!draftCreditNotesSummary?.invoiceDocumentsCount);

  useEffect(() => {
    if (allItemIds.length) {
      dispatch(getInvoiceDocumentsSummaryThunk({ invoiceDocumentIds: allItemIds }));
    }
    // eslint-disable-next-line
  }, [allItemIds]);

  const submitHandle = () => {
    let invoiceDocumentIds: number[] = [];
    if (isInvoices) invoiceDocumentIds = invoiceDocumentIds.concat(allInvoiceIds);
    if (isCreditNotes) invoiceDocumentIds = invoiceDocumentIds.concat(allCreditNoteIds);
    dispatch(exportInvoiceDocumentsCsvFileThunk({
      invoiceDocumentIds,
      markDraftAsExported: isMarkAsExportedAfterCSV,
      onFinish: onCancel,
    }));
  };

  const getLabel = (type: 'invoices' | 'credit notes') => {
    let title = origin === 'sales' ? 'Draft sales ' : 'Draft purchase ';
    let grossValue = '';
    if (type === 'invoices') {
      title += `${type} (${draftInvoicesSummary?.invoiceDocumentsCount || 0} entities`;
      const gross = toPoundCurrency(draftInvoicesSummary?.totalGross);
      grossValue = `${gross === '-' ? ')' : ` - ${gross})`}`;
    }
    if (type === 'credit notes') {
      title += `${type} (${draftCreditNotesSummary?.invoiceDocumentsCount || 0} entities`;
      const gross = toPoundCurrency(draftCreditNotesSummary?.totalGross);
      grossValue = `${gross === '-' ? ')' : ` - ${gross})`}`;
    }
    return `${title}${grossValue}`;
  };

  return (
    <div className="draftEntitiesContent">
      <Checkbox
        label={getLabel('invoices')}
        checked={isInvoices}
        onChange={(v) => setIsInvoices(v)}
        className="entitiesForExport__checkbox"
      />
      <Checkbox
        label={getLabel('credit notes')}
        checked={isCreditNotes}
        onChange={(v) => setIsCreditNotes(v)}
        className="entitiesForExport__checkbox"
      />
      <Divider type="horizontal" />
      <Checkbox
        label={'Mark selected draft entities as "Exported" after generation of a CSV file'}
        checked={isMarkAsExportedAfterCSV}
        onChange={(v) => setIsMarkAsExportedAfterCSV(v)}
        className="entitiesForExport__checkbox"
      />
      <ButtonActions
        createLabel="Export selected"
        createType="button"
        cancelClick={onCancel}
        createClick={submitHandle}
        disabledCreate={!isExportAllowed}
        isLoading={isLoading}
        offsetTop
      />
    </div>
  );
};

export default DraftEntitiesContent;
