import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  rectificationsByProductColumnsSelector,
  rectificationsByProductDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import RectificationsByProductColumns from './RectificationsByProductColumns';
import RectificationsByProductBody from './RectificationsByProductBody';
import './RectificationsByProductReportTable.scss';

const RectificationsByProductTable: React.FC = () => {
  const columns = useAppSelector(rectificationsByProductColumnsSelector);
  const { items, totalCount } = useAppSelector(rectificationsByProductDataSelector);

  return (
    <TableNew>
      <RectificationsByProductColumns columns={columns} totalElements={totalCount} />
      <RectificationsByProductBody data={items} columnsLength={columns.length} />
    </TableNew>
  );
};

export default RectificationsByProductTable;
