import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../../../../../store/hooks';
import { setStockParts, setStockPartsFilters } from '../../../../../../store/slices/pricelistSlice';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import { useAddPartFilters } from '../../../../hooks/useAddPartFilters';
import KeywordFiltersSection from '../../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import InputWithButton from '../../../../../../core/components/input-with-button/InputWithButton';
import { initDataListWithIds } from '../../../../../../core/types/coreTypes';
import { StockFiltersType } from '../../../../../../stock/search/types/stockSearchTypes';

type AddPartFiltersProps = {
  filters: StockFiltersType,
}

const AddPartFilters: React.FC<AddPartFiltersProps> = ({ filters }) => {
  const dispatch = useAppDispatch();
  const {
    initDropdownSearch,
    filtersList,
    getFilteredResult,
    onClear,
  } = useAddPartFilters();
  const [mainValue, setMainValue] = useState<string>('');
  const [mainError, setMainError] = useState<string>('');

  useEffect(() => {
    return () => {
      setMainValue('');
    };
  }, []);
  return (
    <section className="addPartModal__filters">
      <KeywordFiltersSection
        getFilteredResHandle={(values, field) => getFilteredResult({ ...filters, [field]: values as number[] })}
        onClear={onClear}
        initDropdownSearch={initDropdownSearch}
        filters={filtersList}
        isSearch={false}
        isFullWidth
        className="addPartModal__filters-list"
      />
      <InputWithButton
        value={mainValue}
        onChange={(e) => {
          const val = e.target.value;
          if (val.trim()) {
            setMainValue(val);
            if (val.length <= 250) {
              mainError && setMainError('');
            } else {
              setMainError(maxLengthMessageHandle(250));
            }
          } else {
            setMainValue(val);
            getFilteredResult({ ...filters, keyword: undefined });
            dispatch(setStockParts(initDataListWithIds));
          }
        }}
        onPressEnter={() => {
          !mainError && getFilteredResult({ ...filters, keyword: mainValue.trim() });
        }}
        onClick={() => !mainError && dispatch(setStockPartsFilters({ ...filters, keyword: mainValue.trim() }))}
        icon={<FontAwesomeIcon icon={faSearch} className="addPartModal__filters-icon" />}
        error={mainError}
        placeholder="Search by part code, manufacturer code or part name"
      />
    </section>
  );
};

export default AddPartFilters;
