import React, { useCallback, useEffect, useMemo } from 'react';
import { CustomAny, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { pcsMeasureSelector, QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import {
  PartProductDictionaryType,
  PartProductTotalType,
  ProductSourceEnum,
  ProductSourceLine,
} from '../../types/commonTypes';
import SourceQtyLine from './SourceQtyLine';

type SelectProductSourceCellsProps = {
  sourceId?: number,
  productSourceTotal: PartProductTotalType | null,
  checkedCells: string[],
  setCheckedCells: (value: string[]) => void,
  values: CustomAny,
  setValues: (val: CustomAny) => void,
  editMode?: boolean,
  editedLine?: number,
  getProductSourceDictionary: (partId: number, openModal: VoidFunctionType) => void,
  getProductSourceTotal: (
    partId: number,
    stockQty: number | null,
    manualQty: number | null,
    manualUnitPrice: number | null,
    pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  ) => void,
  lines: ProductSourceLine[],
  linesParts: { id: number, unitOfMeasure: number }[],
  productSourceDictionary: PartProductDictionaryType | null,
  hideManualInput?: boolean,
}

const SelectProductSourceCells: React.FC<SelectProductSourceCellsProps> = ({
  sourceId,
  productSourceTotal,
  checkedCells,
  setCheckedCells,
  values, setValues,
  editedLine, editMode,
  getProductSourceDictionary,
  getProductSourceTotal,
  lines, linesParts,
  productSourceDictionary,
  hideManualInput,
}) => {
  const quoteUnitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const pcsMeasure = useAppSelector(pcsMeasureSelector);
  const unitOfMeasure = useMemo(() => {
    if (editMode) {
      const partFound = lines.find((line) => line.id === editedLine);
      return partFound?.unitOfMeasure;
    } else {
      return linesParts.find((el) => el.id === sourceId)?.unitOfMeasure;
    }
  }, [editMode, editedLine, lines, linesParts, sourceId]);

  const isWholeNumbers = useMemo(() => pcsMeasure === unitOfMeasure, [unitOfMeasure, pcsMeasure]);

  useEffect(() => {
    if (editMode) {
      const current = lines.find((line) => line.id === editedLine);
      const setValuesHandle = () => {
        let listV = {};
        const keys: string[] = [];
        if (current?.stockQuantity) {
          keys.push(ProductSourceEnum.STOCK);
        }
        if (current?.manualInputQuantity) {
          keys.push(ProductSourceEnum.MANUAL);
        }
        if (current?.pricelists && current?.pricelists?.length > 0) {
          const listKeys = current.pricelists.map((pr) => pr.id.toString());
          listKeys.forEach((key) => {
            keys.push(key);
            listV = { ...listV, [key]: current.pricelists?.find((el) => el.id === +key)?.quantity || 0 };
          });
        }
        setValues({
          ...listV,
          stock: current?.stockQuantity,
          manual: current?.manualInputQuantity,
          manualUnit: current?.manualInputUnitPrice,
        });
        setCheckedCells(keys);
      };
      const pricelists = current?.pricelists && current?.pricelists.length > 0
        ? current?.pricelists.map((l) => ({ pricelistId: l.id, quantity: l.quantity }))
        : null;
      if (current?.partId) {
        current?.partId && getProductSourceDictionary(current?.partId, setValuesHandle);

        current?.partId && getProductSourceTotal(
          current?.partId,
          current?.stockQuantity || null,
          current?.manualInputQuantity || null,
          current?.manualInputUnitPrice || null,
          pricelists,
        );
      }
    }
    // eslint-disable-next-line
  }, [editMode, editedLine]);

  useEffect(() => {
    if (productSourceDictionary && !editMode) {
      let val: Record<string | number, number> = {};
      const pricelistsNames = productSourceDictionary.pricelists?.map((el) => el.id);
      pricelistsNames?.forEach((el) => {
        val[el] = 0;
      });
      val = {
        ...val,
        stock: 0,
        manual: 0,
        manualUnit: 0,
      };
      setValues(val);
    }
    // eslint-disable-next-line
  }, [productSourceDictionary]);

  const measureLabel = useMemo(() => quoteUnitsOfMeasure?.find((el) => el.value === unitOfMeasure)?.label, [
    unitOfMeasure, quoteUnitsOfMeasure,
  ]);

  const availableTooltip = `Please note that this stock part is currently selected 
  in other enquiries (${productSourceDictionary?.stockQuantitySelected} 
  of ${productSourceDictionary?.stockQuantityAvailable} ${measureLabel}).`;

  const stockUnitPrice = useMemo(() => {
    if (productSourceDictionary) {
      const isMaxMinEqual = productSourceDictionary?.minStockUnitPrice === productSourceDictionary?.maxStockUnitPrice;
      const max = productSourceDictionary?.maxStockUnitPrice || 0;
      const min = productSourceDictionary?.minStockUnitPrice || 0;
      return isMaxMinEqual
        ? `${min}`
        : `${min}-${max}`;
    } else return 0;
  }, [productSourceDictionary]);

  const checkHandle = (value: string) => {
    if (checkedCells.includes(value)) {
      const newArr = checkedCells.filter((el) => el !== value);
      setCheckedCells(newArr);
    } else {
      setCheckedCells([...checkedCells, value]);
    }
  };

  const getTotal = (v: CustomAny) => {
    const current = lines.find((line) => line.id === editedLine);
    const checkedList = checkedCells
      .filter((cell) => cell !== ProductSourceEnum.MANUAL && cell !== ProductSourceEnum.STOCK)
      .filter((pr) => v[pr] > 0);
    const partId = editMode ? current?.partId : sourceId;
    partId && values !== null && getProductSourceTotal(
      partId,
      v.stock,
      v.manual,
      v.manualUnit,
      checkedList.length > 0 ? checkedList.map((l) => ({ pricelistId: +l, quantity: v[l] })) : null,
    );
  };

  const getPricelistTotal = useCallback(
    (id: number) => productSourceTotal?.pricelistTotals.find((l) => l.pricelistId === id)?.netPriceString,
    [productSourceTotal],
  );

  return (
    <>
      <SourceQtyLine
        sourceBoxValue={ProductSourceEnum.STOCK}
        checked={checkedCells.includes(ProductSourceEnum.STOCK)}
        sourceBoxOnChange={() => {
          if (checkedCells.includes(ProductSourceEnum.STOCK)) {
            setValues({ ...values, stock: '0' });
            getTotal({ ...values, stock: 0 });
          }
          checkHandle(ProductSourceEnum.STOCK);
        }}
        disabledQty={!checkedCells.includes(ProductSourceEnum.STOCK)}
        text={`Take from stock (${
          productSourceDictionary?.stockQuantityAvailable === undefined ? '-' : productSourceDictionary?.stockQuantityAvailable
        } ${measureLabel} available)`}
        availableTooltip={productSourceDictionary?.stockQuantitySelected && productSourceDictionary?.stockQuantitySelected > 0
          ? availableTooltip
          : ''}
        disabledPlus={!values || +values.stock === productSourceDictionary?.stockQuantityAvailable}
        qtyValue={values ? values.stock : '0'}
        setQtyValue={(v: string) => {
          const stockAvailable = productSourceDictionary?.stockQuantityAvailable;
          if (stockAvailable !== undefined) {
            setValues({ ...values, stock: v });
            setTimeout(() => {
              if (v && +v > stockAvailable) {
                setValues({ ...values, stock: stockAvailable });
                getTotal({ ...values, stock: stockAvailable });
              } else {
                getTotal({ ...values, stock: +v || 0 });
              }
            }, 100);
          }
        }}
        isDecimals={!isWholeNumbers}
        unitPrice={stockUnitPrice}
        netPrice={productSourceTotal?.stockNetPrice}
      />
      {productSourceDictionary?.pricelists?.map((pr) => <SourceQtyLine
        key={pr.id}
        checked={(pr.id && checkedCells.includes(pr.id.toString())) || false}
        sourceBoxValue={pr.name || ''}
        sourceBoxOnChange={() => {
          if (checkedCells.includes(pr.id.toString())) {
            setValues({ ...values, [pr.id]: '0' });
            getTotal({ ...values, [pr.id]: 0 });
          }
          checkHandle(pr.id.toString());
        }}
        text={`Purchase from supplier - ${pr.name}`}
        disabledQty={!pr.id || !checkedCells.includes(pr.id.toString())}
        disabledMinus={values && (!values || !values[pr.id] || values[pr.id] === 0)}
        qtyValue={values ? values[pr.id] : '0'}
        setQtyValue={(v: string) => {
          setValues({ ...values, [pr.id]: v });
          getTotal({ ...values, [pr.id]: +v || 0 });
        }}
        isDecimals={!isWholeNumbers}
        unitPrice={pr.unitPrice}
        netPrice={getPricelistTotal(pr.id)}
      />)}
      {!hideManualInput && <SourceQtyLine
        sourceBoxValue={ProductSourceEnum.MANUAL}
        checked={checkedCells.includes(ProductSourceEnum.MANUAL)}
        sourceBoxOnChange={() => {
          if (checkedCells.includes(ProductSourceEnum.MANUAL)) {
            setValues({ ...values, manual: '0', manualUnit: '0' });
            getTotal({ ...values, manual: 0, manualUnit: 0 });
          }
          checkHandle(ProductSourceEnum.MANUAL);
        }}
        text="Manual input"
        disabledQty={!checkedCells.includes(ProductSourceEnum.MANUAL)}
        disabledMinus={values && (!values.manual || values.manual === 0)}
        qtyValue={values ? values.manual : 0}
        setQtyValue={(v: string) => {
          setValues({ ...values, manual: v });
          getTotal({ ...values, manual: +v || 0 });
        }}
        isDecimals={!isWholeNumbers}
        isManual
        manualDisabled={!checkedCells.includes(ProductSourceEnum.MANUAL)}
        manualValue={(values?.manualUnit !== null && values?.manualUnit !== undefined) ? values.manualUnit.toString() : ''}
        manualUnitPriceChange={(v) => {
          setValues({ ...values, manualUnit: v });
          getTotal({ ...values, manualUnit: +v || 0 });
        }}
        netPrice={productSourceTotal?.manualInputNetPrice}
      />}
    </>
  );
};

export default SelectProductSourceCells;
