import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import InputWithButton from '../../../../../../../core/components/input-with-button/InputWithButton';
import Pagination from '../../../../../../../core/components/pagination/Pagination';
import {
  partsKitComponentsPartsFiltersSelector, partsKitComponentsPartsSelector,
} from '../../../../../../../store/selectors/partsKitSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  initDataListWithIds, StringFunctionType,
  VoidFunctionType,
} from '../../../../../../../core/types/coreTypes';
import { maxLengthMessageHandle } from '../../../../../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../../../../../core/enums/errorsEnum';
import {
  setPartsKitComponentsParts,
  setPartsKitComponentsPartsFilters,
} from '../../../../../../../store/slices/partsKitSlice';
import { getPartsKitComponentsParts } from '../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import KeywordFiltersSection from '../../../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useAddComponentFilters } from '../../../../hooks/useAddComponentFilters';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../../../core/utils/regex';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';

type CoreNonCoreFiltersProps = {
  cleanKeys: VoidFunctionType,
  error: string,
  setError: StringFunctionType,
  mode: AddComponentModeEnum,
}

const CoreNonCoreFilters: React.FC<CoreNonCoreFiltersProps> = ({
  cleanKeys, setError, error, mode,
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(partsKitComponentsPartsFiltersSelector);
  const parts = useAppSelector(partsKitComponentsPartsSelector);

  const {
    initDropdownSearch,
    filtersList,
    getFilteredResult,
    onClear,
  } = useAddComponentFilters();

  const searchTriggerFn = () => {
    dispatch(getPartsKitComponentsParts({
      filters: { ...filters, keyword: filters.keyword?.trim(), page: 1 },
    }));
  };

  const searchHandle = () => {
    const searchValue = filters.keyword;
    if (!searchValue?.trim()) {
      setError(ErrorsEnum.REQUIRED);
      dispatch(setPartsKitComponentsPartsFilters({ ...filters, keyword: undefined }));
    } else {
      searchValue?.trim() && !error && searchTriggerFn();
    }
  };

  return (
    <section className="addComponent__content-filters">
      <KeywordFiltersSection
        getFilteredResHandle={(values, field) => getFilteredResult({ ...filters, [field]: values as number[] })}
        onClear={onClear}
        initDropdownSearch={initDropdownSearch}
        filters={filtersList}
        isSearch={false}
        isFullWidth
        conditionToClean={!!mode}
      />
      <InputWithButton
        value={filters.keyword}
        icon={<FontAwesomeIcon icon={faSearch} />}
        onChange={(e) => {
          const val = e.target.value;
          if (!val.trim() && parts.totalCount !== 0) {
            dispatch(setPartsKitComponentsParts(initDataListWithIds));
          }
          if (val.length > 100) {
            setError(maxLengthMessageHandle(100));
          } else {
            error && setError('');
          }
          cleanKeys();
          dispatch(setPartsKitComponentsPartsFilters({ ...filters, keyword: val }));
        }}
        onPressEnter={searchHandle}
        onClick={searchHandle}
        placeholder="Search by part code, manufacturer code, part name or description"
        error={error}
        className="addComponent__content-filters__search"
        buttonDisabled={!filters.keyword}
      />
      <Pagination
        currentPage={filters.page}
        onChange={(page, pageSize) => {
          dispatch(setPartsKitComponentsPartsFilters({ ...filters, page, pageSize }));
          filters.keyword && dispatch(getPartsKitComponentsParts({
            filters: {
              ...filters,
              page,
              pageSize,
            },
          }));
        }}
        totalItems={parts.totalCount}
        pageSize={filters.pageSize}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        className="addComponent__content-filters__pages"
      />
    </section>
  );
};

export default CoreNonCoreFilters;
