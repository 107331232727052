import React, { useCallback, useState } from 'react';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useAppSelector } from '../../../store/hooks';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import { QuoteUnitsOfMeasureSelector, timeMeasureValuesSelector } from '../../../store/selectors/coreSelectors';
import { routesPath } from '../../../core/enums/pathEnum';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import usePermission from '../../../permissions-handling/permissionHook';
import PriceSourceTag from '../../../common/components/price-source-tag/PriceSourceTag';
import { LineCellType } from '../components/enquiry-content/qoute/table/QuoteLinesCells';

export const useQuoteLineCell = (
  editQty: NumberFunctionType,
  openCustomModal: NumberFunctionType,
  data: LineCellType,
  valueRenderer: (key: string, value: string | number) => string | number | JSX.Element,
) => {
  const forbiddenToEdit = ['number', 'netPrice', 'quoteCategory', 'productNumber', 'productName', 'quantity', 'unitPrice', 'priceSources'];
  const hiddenCells = [
    'id', 'isDraft', 'isProductSuitable', 'isUsualLine', 'rules', 'partId', 'productWeight', 'partsKitId', 'unitOfMeasure',
  ];

  const [fieldToEdit, setFieldToEdit] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const timeMeasures = useAppSelector(timeMeasureValuesSelector);
  const allowedToManage = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);

  const measureResult = useCallback(
    (value?: number) => unitsOfMeasure.find((unit) => unit.value === value)?.label,
    [unitsOfMeasure],
  );

  const cellClickHandle = (key: string) => {
    if ((key === 'quantity' || key === 'unitPrice')
        && !data.partsKitId
        && data.isUsualLine
        && allowedToManage
    ) {
      editQty(data.id);
    }
    if ((key === 'quantity' || key === 'unitPrice') && !data.isUsualLine && allowedToManage) {
      openCustomModal(data.id);
    }
    if (key === 'quantity' && data.partsKitId && allowedToManage) {
      setFieldToEdit && setFieldToEdit(key);
      setEditMode(true);
    }
    if (!forbiddenToEdit.includes(key)) {
      const case1 = key === 'weight' && allowedToManage;
      const case2 = key === 'discount' && allowedToManage;
      const case3 = key !== 'discount' && key !== 'weight';

      if (case1 || case2 || case3) {
        setFieldToEdit && setFieldToEdit(key);
        setEditMode(true);
      }
    }
  };

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip value={value} table />;
  };

  const cellRenderHandle = (key: string, value: unknown) => {
    const hyphenTextCondition = (key === 'weight' && timeMeasures.includes(data.unitOfMeasure))
        || (key === 'tax' && (data.partsKitId || data.tax === null));
    if (key === 'quantity') {
      return `${data.quantity} ${measureResult(data.unitOfMeasure)}`;
    }
    if (hyphenTextCondition) {
      return '-';
    }
    if (key === 'notes') {
      return renderTruncateTooltip(value as string | null);
    }
    if (key === 'priceSources') {
      return <div className="priceTags">
        {typeof value === 'string'
          ? value as string
          : (value as number[]).map((el) => <PriceSourceTag source={el} />)}
      </div>;
    }
    if ((key === 'productName' || key === 'productNumber')) {
      if (data.partId) {
        return <PrivateLineLink to={`/${routesPath.STOCK}/part/${data.partId}`} permission={PermissionEnum.StockPartViewPage}>
          {renderTruncateTooltip(key === 'productName' ? `${data.productName}` : data.productNumber)}
        </PrivateLineLink>;
      }
      if (data.partsKitId) {
        return <PrivateLineLink
          to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${data.partsKitId}`}
          permission={PermissionEnum.StockPartsKitViewPage}
        >
          {renderTruncateTooltip(key === 'productName' ? data.productName : data.productNumber)}
        </PrivateLineLink>;
      } else {
        return renderTruncateTooltip(value as string | null);
      }
    } else {
      return valueRenderer(key, value as string | number);
    }
  };

  return {
    fieldToEdit,
    setFieldToEdit,
    editMode,
    setEditMode,
    cellClickHandle,
    forbiddenToEdit,
    hiddenCells,
    cellRenderHandle,
  };
};
