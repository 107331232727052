import { z } from 'zod';
import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { PASSWORD_REGEX } from '../../core/utils/regex';
import { maxLengthMessageHandle } from '../../core/utils/errorMessageHandle';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const PasswordSchema = z.object({
  password: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED),
  newPassword: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(100, maxLengthMessageHandle(100))
    .regex(
      PASSWORD_REGEX,
      'Please use a strong password.',
    ),
  confirmNewPassword: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(100, maxLengthMessageHandle(100)),
})
  .refine((data) => data.newPassword === data.confirmNewPassword, {
    path: ['confirmNewPassword'],
    message: 'Entered passwords do not match.',
  });

export type EditPassword = z.infer<typeof PasswordSchema>;

export const editPasswordDefaults: EditPassword = {
  password: '',
  newPassword: '',
  confirmNewPassword: '',
};
