import React from 'react';
import { Tooltip } from 'antd';
import { numberFormatter } from '../../../../common/utils/formatUtils';
import { useAppSelector } from '../../../../store/hooks';
import { partsStatisticLookupSelector } from '../../../../store/selectors/stockSelectors';

const TotalPartQuantity = () => {
  const partsQuantity = useAppSelector(partsStatisticLookupSelector);

  return (
    <section className="totalPartQuantity info-grid__column">
      <h2 className="info-grid__title">Total part quantity</h2>
      <ul className="totalPartQuantity__list">
        {partsQuantity.map((el) => {
          return (
            <li key={el.label} className="totalPartQuantity__item">
              <span>{el.label}</span>
              <Tooltip
                title={el.value > 999 ? numberFormatter(el.value, { notation: 'standard' }) : ''}
                mouseLeaveDelay={0}
                destroyTooltipOnHide
              >
                <span className="totalPartQuantity__value">{numberFormatter(el.value)}</span>
              </Tooltip>
            </li>
          );
        })}
      </ul>
    </section>
  );
};

export default TotalPartQuantity;
