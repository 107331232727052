import React, { useEffect, useState } from 'react';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided, DropResult,
} from 'react-beautiful-dnd';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { JobDictionaryDtoWithOrdinalNumber } from '../types/jobCalendarTypes';
import { jobCalendarDictionarySelector } from '../../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { jobStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { patchJobsThunk } from '../../../store/thunks/jobs/jobCalendarThunks';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import ChangeJobsOrderItem from './ChangeJobsOrderItem';

type ChangeJobsOrderModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  userName: string,
}
const ChangeJobsOrderModal: React.FC<ChangeJobsOrderModalProps> = ({
  isVisible, onCancel, userName,
}) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const { postponed } = useAppSelector(jobStatusesValuesSelector);
  const jobCalendarDictionary = useAppSelector(jobCalendarDictionarySelector);
  const [copyList, setCopyList] = useState<JobDictionaryDtoWithOrdinalNumber[]>([]);

  useEffect(() => {
    const listWithOrdinalNumber = jobCalendarDictionary
      .filter((el) => el.status !== postponed)
      .map((el, i) => ({ ...el, ordinalNumber: i + 1 }));
    jobCalendarDictionary.length > 0 && setCopyList(listWithOrdinalNumber);
    // eslint-disable-next-line
  }, [jobCalendarDictionary]);

  const postponedTasksFromCopyList = jobCalendarDictionary.length > 0
    ? jobCalendarDictionary.filter((el) => el.status === postponed)
    : [];

  const cancelHandle = () => {
    onCancel();
    // init data
    setCopyList([]);
  };

  const onBeforeDragStart = () => {
    const jobsList = document.querySelector('.jobs-list') as HTMLElement;
    jobsList.style.height = `${jobsList?.scrollHeight}px`;
  };

  const onDragEnd = async (result: DropResult) => {
    if (!result.destination) return;
    const { source, destination } = result;

    if (source.droppableId === destination.droppableId) {
      const resArr = copyList ? Array.from(copyList) : [];
      const [removed] = resArr.splice(result.source.index, 1);
      resArr.splice(result.destination.index, 0, removed);

      const finalList = resArr.map((el, i) => ({
        ...el,
        ordinalNumber: i + 1,
      }));
      setCopyList(finalList);
    }
  };

  const saveHandle = (values: JobDictionaryDtoWithOrdinalNumber[]) => {
    const finalData = values.map((v) => ({ id: v.id, ordinalNumber: v.ordinalNumber }));
    dispatch(patchJobsThunk({
      jobs: finalData,
      closeModal: cancelHandle,
    }));
  };
  return <Modal
    visible={isVisible}
    onCancel={cancelHandle}
    title={`Change job order for ${userName}`}
    width={860}
    destroyOnClose
  >
    {jobCalendarDictionary.length > 0
      ? <DragDropContext onBeforeDragStart={onBeforeDragStart} onDragEnd={(result) => onDragEnd(result)}>
        <Droppable droppableId="droppableId">
          {(provided: DroppableProvided) => {
            return (
              <div
                className="jobs-list"
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {copyList?.map((item, i) => <Draggable
                  draggableId={item.id.toString()}
                  index={i}
                  key={item.id}
                >
                  {(provided) => (
                    <div
                      key={item.id}
                      className=""
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      <ChangeJobsOrderItem item={item} />
                    </div>)}
                </Draggable>)}
              </div>
            );
          }}
        </Droppable>
        {postponedTasksFromCopyList?.map((item, i) => <ChangeJobsOrderItem key={i} item={item} disabledMove />)}
      </DragDropContext>
      : <EmptyContentList icon={faBoxes} offsetTop={false} size="sm" text="No records" />}
    <ButtonActions
      cancelLabel="Cancel"
      createLabel="Save"
      cancelClick={cancelHandle}
      createClick={() => saveHandle(copyList)}
      isLoading={isFetching}
      offsetTop
    />
  </Modal>;
};

export default ChangeJobsOrderModal;
