import React from 'react';
import { faBoxes } from '@fortawesome/free-solid-svg-icons';
import { PricelistDto } from '../../types/pricelistTypes';
import PricelistItem from './PricelistItem';
import { useAppSelector } from '../../../../store/hooks';
import PricelistSkeletonItem from '../pricelist-skeleton/PricelistSkeletonItem';
import SkeletonLinesList from '../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import { isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';

type PricelistLinesProps = {
  pricelists: {items: PricelistDto[], pages: number, totalCount: number},
}

const PricelistLines: React.FC<PricelistLinesProps> = ({ pricelists }) => {
  const isLoading = useAppSelector(isDashboardLoadingSelector);
  return (
    isLoading
      ? <SkeletonLinesList>
        <PricelistSkeletonItem />
      </SkeletonLinesList>
      : <>
        {pricelists.totalCount > 0
          ? <ul className="lines-list">
            {pricelists.items.map((item, i) => (
              <li className="lines-list__item" key={i}>
                <PricelistItem key={item.id} item={item} />
              </li>
            ))}
          </ul>
          : <EmptyContentList icon={faBoxes} text="No records" />}
      </>
  );
};

export default PricelistLines;
