import React, { useEffect, useState } from 'react';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { CustomAny, NumberFunctionType, SearchTableFilterType } from '../../../../../../../core/types/coreTypes';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import Button from '../../../../../../../core/components/button/Button';
import { StockFiltersType } from '../../../../../../search/types/stockSearchTypes';
import { setPurchaseOrderPartSearchFilters } from '../../../../../../../store/slices/purchaseOrderSlice';
import { getStockSearch } from '../../../../../../../store/thunks/stock/stockThunks';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import {
  purchaseOrderPartSearchTableModeResultSelector, addPartToOrderColumnsSelector,
} from '../../../../../../../store/selectors/purchaseOrderSelectors';

type AddPartToOrderTableProps = {
  setIsSelectPrice: NumberFunctionType,
  filters: StockFiltersType,
  getFilteredRes: (value: (string | number[]), field: string) => void,
  conditionToClean: CustomAny,
}

const AddPartToOrderTable: React.FC<AddPartToOrderTableProps> = ({
  setIsSelectPrice, filters, getFilteredRes, conditionToClean,
}) => {
  const dispatch = useAppDispatch();
  const addPartToOrderColumns = useAppSelector(addPartToOrderColumnsSelector);
  const searchResult = useAppSelector(purchaseOrderPartSearchTableModeResultSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: '',
    manufacturerCode: '',
    name: '',
    description: '',
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newV = { ...filters, order: { ...filters.order, isAsc: !filters.order?.isAsc } };
      dispatch(setPurchaseOrderPartSearchFilters(newV));
      filters.keyword && dispatch(getStockSearch({
        filters: newV,
        isPurchaseOrderPage: true,
      }));
    } else {
      const newV = { ...filters, order: { field, isAsc: true } };
      dispatch(setPurchaseOrderPartSearchFilters(newV));
      filters.keyword && dispatch(getStockSearch({
        filters: newV,
        isPurchaseOrderPage: true,
      }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      getFilteredRes('', field);
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    getFilteredRes(searchVl[field]?.trim() || '', field);
  };

  useEffect(() => {
    setSearchVl({
      partCode: '',
      manufacturerCode: '',
      name: '',
      description: '',
    });
    // eslint-disable-next-line
  }, [conditionToClean]);
  return (
    <TableNew>
      <Thead>
        <Tr>
          {addPartToOrderColumns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            totalElements={searchResult.totalCount}
            disabled={searchResult.totalCount === 0}
            searchVl={searchVl}
            tableFilters={filters}
            className={`addPartToOrderTh--${col.dataIndex}`}
            insideModal
          />)}
          <ThNew className="addPartToOrderTh--action"><span className="sr-only">Action</span></ThNew>
        </Tr>
      </Thead>
      <Tbody>
        {searchResult.totalCount > 0
          ? <>
            {searchResult.items?.map((el, i) => <Tr key={i}>
              <>
                {Object.entries(el).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key}>
                    <TruncateTooltip
                      value={value}
                      table
                      hideTooltipOnMobile
                    />
                  </TdNew>;
                })}
                <TdNew>
                  <Button
                    type="text"
                    label="Select"
                    onClick={() => setIsSelectPrice(el.id)}
                  />
                </TdNew>
              </>
            </Tr>)}
          </>
          : <EmptyTableSection
            colSpan={addPartToOrderColumns.length + 1}
            text={filters.keyword && searchResult.totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
          />}
      </Tbody>
    </TableNew>
  );
};

export default AddPartToOrderTable;
