import React from 'react';
import { APIProvider } from '@vis.gl/react-google-maps';
import Map from './Map';
import './Map.scss';
import { useAppSelector } from '../../../../../../store/hooks';
import { googleApiKeySelector } from '../../../../../../store/selectors/coreSelectors';

const MapComponent: React.FC = () => {
  const googleApiKey = useAppSelector(googleApiKeySelector);

  return (
    <div className="map-wrapper">
      <div className="map-content">
        <APIProvider
          apiKey={googleApiKey}
          libraries={['marker']}
        >
          <Map />
        </APIProvider>
      </div>
    </div>
  );
};

export default MapComponent;
