import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { initDataList } from '../../../core/types/coreTypes';
import InputWithButton from '../../../core/components/input-with-button/InputWithButton';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import Pagination from '../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Thead from '../../../core/components/table-components/Thead';
import TableLoader from '../../../core/components/table-components/TableLoader';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import { useAppSelector } from '../../../store/hooks';
import { currentBusinessAreaSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import {
  DraftLinePartsKitFiltersType,
  DraftLinePartsKitList,
  initDraftLinePartsKitFilters,
} from '../../types/commonTypes';
import PartsKitModeBody from './PartsKitModeBody';
import PartsKitModeColumns from './PartsKitModeColumns';
import TableNew from '../../../core/components/table-components/table-new/TableNew';

type PartsKitModeProps = {
  currentSource?: number,
  partsKitFilters?: DraftLinePartsKitFiltersType,
  setPartsKitFilters?: (v: DraftLinePartsKitFiltersType) => void,
  partsKitParts?: DraftLinePartsKitList,
  setPartsKitParts?: (v: DraftLinePartsKitList) => void,
  fetchPartsKitParts?: (filters: DraftLinePartsKitFiltersType, isShow: boolean) => void,
  createPartsKitLine: (partsKitId: number) => void,
  originName?: string,
  isSuitableForCheckboxShown?: boolean,
}

const PartsKitMode: React.FC<PartsKitModeProps> = ({
  currentSource, partsKitFilters, partsKitParts,
  setPartsKitFilters, setPartsKitParts, fetchPartsKitParts,
  createPartsKitLine, originName, isSuitableForCheckboxShown,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const businessArea = useAppSelector(currentBusinessAreaSelector);
  const [error, setError] = useState<string>('');
  const [isShow, setIsShow] = useState(false);

  const filtersHandle = (filters: DraftLinePartsKitFiltersType) => {
    if (businessArea) {
      setPartsKitFilters && setPartsKitFilters({ ...filters });
      fetchPartsKitParts && fetchPartsKitParts({ ...filters }, isShow);
    }
  };
  const searchTriggerFn = () => {
    if (businessArea) {
      fetchPartsKitParts && partsKitFilters && fetchPartsKitParts({
        ...partsKitFilters,
        keyword: partsKitFilters.keyword?.trim(),
      }, isShow);
    }
  };
  useEffect(() => {
    error && setError('');
    setPartsKitParts && setPartsKitParts(initDataList);
    setPartsKitFilters && setPartsKitFilters(initDraftLinePartsKitFilters);
    // eslint-disable-next-line
  }, [currentSource]);
  return (
    <>
      <InputWithButton
        value={partsKitFilters?.keyword}
        onChange={(e) => {
          const val = e.target.value;
          if (!val.trim() && partsKitParts?.totalCount !== 0) {
            setPartsKitParts && setPartsKitParts(initDataList);
            setPartsKitFilters && partsKitFilters && setPartsKitFilters({ ...partsKitFilters, keyword: '' });
          }
          if (val.length > 100) {
            setError(maxLengthMessageHandle(100));
          } else {
            error && setError('');
          }
          if (!val.trim()) {
            isShow && setIsShow(false);
          }
          setPartsKitFilters && partsKitFilters && setPartsKitFilters({ ...partsKitFilters, keyword: val });
        }}
        onPressEnter={() => {
          const val = partsKitFilters?.keyword;
          if (!val?.trim()) {
            setError(ErrorsEnum.REQUIRED);
            setPartsKitFilters && partsKitFilters && setPartsKitFilters({ ...partsKitFilters, keyword: '' });
          } else {
            val.trim() && !error && searchTriggerFn();
          }
        }}
        placeholder="Search by parts kit code, parts kit name or description"
        onClick={() => {
          const val = partsKitFilters?.keyword;
          val?.trim() && !error && searchTriggerFn();
        }}
        icon={<FontAwesomeIcon icon={faSearch} />}
        error={error}
        offsetBottom
      />
      <Pagination
        currentPage={partsKitFilters?.page}
        pageSize={partsKitFilters?.pageSize}
        onChange={(page, pageSize) => {
          partsKitFilters && filtersHandle({ ...partsKitFilters, page, pageSize });
        }}
        totalItems={partsKitParts?.totalCount || 0}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        offsetBottom
      />
      <TableNew>
        <Thead>
          <PartsKitModeColumns partsKitTotal={partsKitParts?.totalCount || 0} filters={partsKitFilters} filtersHandle={filtersHandle} />
        </Thead>
        {isGlobalLoading
          ? <TableLoader colspan={6} />
          : <PartsKitModeBody
            searchValue={partsKitFilters?.keyword}
            partsKitParts={partsKitParts}
            partsKitFilters={partsKitFilters}
            createPartsKitLine={createPartsKitLine}
          />}
      </TableNew>
      {isSuitableForCheckboxShown && <div className="partsTable__showBox">
        <Checkbox
          checked={isShow}
          onChange={(v) => {
            if (businessArea) {
              setIsShow(v);
              fetchPartsKitParts && partsKitFilters && fetchPartsKitParts(partsKitFilters, v);
            }
          }}
          disabled={!partsKitFilters?.keyword?.trim()}
          label={`Show only parts, which are suitable for ${originName} vehicle`}
        />
      </div>}
    </>
  );
};

export default PartsKitMode;
