import React, { useEffect } from 'react';
import Chart from '../../../../../../core/components/chart/Chart';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  salesOfficeEmployeeDataChartSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import { setSalesOfficeEmployeeChartPaging, setSalesOfficeEmployeeDataChart } from '../../../../../../store/slices/reportsSlice';
import { initDataList, initPaging } from '../../../../../../core/types/coreTypes';

const SalesOfficeEmployeeChart: React.FC = () => {
  const dispatch = useAppDispatch();
  const { series, categories } = useAppSelector(salesOfficeEmployeeDataChartSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  useEffect(() => {
    return () => {
      dispatch(setSalesOfficeEmployeeChartPaging(initPaging));
      dispatch(setSalesOfficeEmployeeDataChart(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      series={series}
      height={550}
      options={{
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories,
        },
        fill: {
          opacity: 1,
        },
      }}
    />
  );
};

export default SalesOfficeEmployeeChart;
