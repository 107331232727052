import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { EmployeesPageParamEnums, EmployeesViewsEnums } from '../enums/EmployeesPageParamsEnums';

export const employeesContainerOptions = [
  {
    value: EmployeesPageParamEnums.DASHBOARD,
    label: EmployeesViewsEnums.DASHBOARD,
    restrictedTo: PermissionEnum.EmployeeDashboard,
  },
  {
    value: EmployeesPageParamEnums.WORK_CALENDAR,
    label: EmployeesViewsEnums.WORKING_HOURS,
    restrictedTo: PermissionEnum.EmployeeWorkingHours,
  },
  {
    value: EmployeesPageParamEnums.LEAVE_REQUESTS,
    label: EmployeesViewsEnums.LEAVE_REQUESTS,
    restrictedTo: PermissionEnum.EmployeeLeaveRequests,
  },
  {
    value: EmployeesPageParamEnums.SKILL_LIST,
    label: EmployeesViewsEnums.SKILL_LIST,
    restrictedTo: PermissionEnum.EmployeeSkillsListPage,
  },
  {
    value: EmployeesPageParamEnums.CREATE_NEW_EMPLOYEE,
    label: EmployeesViewsEnums.CREATE_NEW_EMPLOYEE,
    restrictedTo: PermissionEnum.EmployeeCreatePage,
  },
];
