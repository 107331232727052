import qs from 'qs';
import instance from './axiosConfig';
import {
  AddEnquiryVehicleRequest,
  CreatePartLineReqDto,
  EnquiryContactDto,
  EnquiryEventDtoBase,
  EnquiryQuoteVehicleDto, GetEnquiryQuoteLinesResponse,
  MissingQuoteLinesParams,
  NewContactReqDataType, PatchEnquiryQuoteLineDto, PatchEnquiryRestModel, PutQuoteVehicle,
  QuoteLinesFilters,
  QuoteLinesOptionsType,
  QuoteLineTimelineType,
  QuoteTotalSummaryDto,
  QuoteVehicleSummaryDto,
  UpdateEnquiryStatusResponse,
} from '../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { CreateEnquiryFormFieldsType, CreateEnquiryResponse } from '../enquiry/create-enquiry/types/createEnquiryTypes';
import {
  CreateCustomLineReqType, DefaultAddressDto,
  ExtendedTimelineFilters,
} from '../common/types/commonTypes';
import {
  CoreAttachmentsFilters,
  PostAttachReqType,
  LinksFiltersType,
  UploadUriDto,
  AxiosSingleResponse,
  AssigneeDto,
  AxiosDataListResponse,
  EntityLinkType,
  AxiosDownloadUriResponse,
  AxiosNullableResponse,
  AxiosCreatedIdResponse,
  AxiosGetAttachmentFileResponse,
  AxiosCreateUploadUriResponse,
  AxiosCreatedAttachmentsResponse,
  AxiosGenericResponse,
  AxiosGetPagedAttachmentsResponse,
} from '../core/types/coreTypes';
import {
  EnquiryColumnType,
  EnquiryFiltersExtended,
  EnquiryResponseDto,
} from '../enquiry/enquiries/types/enquiryTypes';

export const enquiryAPI = {
  fetchNewEnquiriesList(
    filters: EnquiryFiltersExtended,
    signal?: AbortSignal,
  ): AxiosSingleResponse<{ enquiryCollections: Array<EnquiryColumnType> | null, assignees: Array<AssigneeDto> | null }> {
    return instance.get(
      'enquiry/enquiry',
      {
        signal,
        params: {
          BusinessArea: filters.businessArea,
          ApplyPersonalFilter: filters.applyPersonalFilter,
          Statuses: filters.statuses,
          'Filter.Keyword': filters.keyword,
          'Filter.PriorityLevels': filters.priority,
          'Filter.Assignees': filters.assignedTo,
          'Filter.JobTypes': filters.jobType,
          'Filter.CustomerTypes': filters.customerType,
          'Filter.Warnings': filters.warnings,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  editEnquiryUserLayout(
    businessArea: number,
    layoutSettings: Array<{
      enquiryStatus: number,
      order: number,
      isVisible: boolean
    }>,
  ): AxiosSingleResponse<{ createdIds: number[], updatedIds: number[] }> {
    return instance.put('enquiry/layoutSetting/userLayout', { businessArea, layoutSettings });
  },
  getEnquiryById(id: number): AxiosSingleResponse<EnquiryResponseDto | null> {
    return instance.get(`enquiry/enquiry/${id}`);
  },
  editEnquiryById(
    id: number,
    newValues: PatchEnquiryRestModel,
  ): AxiosNullableResponse {
    return instance.patch(`enquiry/enquiry/${id}`, { ...newValues });
  },
  editEnquiryStatus(id: number, statusTransition: number, rejectionReason?: string): AxiosSingleResponse<UpdateEnquiryStatusResponse> {
    return instance.put(`enquiry/enquiry/${id}/status`, { statusTransition, rejectionReason });
  },
  duplicateEnquiry(id: number): AxiosSingleResponse<{ createdId: number, enquiryNumber: string | null }> {
    return instance.post(`enquiry/enquiry/${id}`);
  },
  deleteEnquiry(id: number): AxiosNullableResponse {
    return instance.delete(`enquiry/enquiry/${id}`);
  },
  getEnquiryQuoteVehicle(id: number): AxiosSingleResponse<{ items: EnquiryQuoteVehicleDto[] }> {
    return instance.get('/enquiry/quoteVehicle', { params: { EnquiryId: id } });
  },
  createEnquiryQuoteVehicle(data: AddEnquiryVehicleRequest): AxiosCreatedIdResponse {
    return instance.post('/enquiry/quoteVehicle', data);
  },
  duplicateEnquiryQuoteVehicle(
    vehicleId: number,
    data: { amount: number, copyLines: boolean },
  ): AxiosSingleResponse<{ createdIds: number[] }> {
    return instance.post(`/enquiry/quoteVehicle/${vehicleId}`, data);
  },
  editEnquiryQuoteVehicle(vehicleId: number, data: PutQuoteVehicle): AxiosNullableResponse {
    return instance.put(`/enquiry/quoteVehicle/${vehicleId}`, data);
  },
  deleteEnquiryQuoteVehicle(vehicleId: number): AxiosNullableResponse {
    return instance.delete(`/enquiry/quoteVehicle/${vehicleId}`);
  },
  getEnquiryQuoteLine(
    vehicleId: number,
    params: QuoteLinesFilters,
  ): AxiosGenericResponse<GetEnquiryQuoteLinesResponse> {
    return instance.get(
      '/enquiry/quoteLine',
      {
        params: {
          'Filter.QuoteVehicleId': vehicleId,
          'PagingOptions.Page': params.page,
          'PagingOptions.ItemsPerPage': params.pageSize,
          'OrderOptions.Field': params.order?.field,
          'OrderOptions.Ascending': params.order?.isAsc,
          'Filter.ProductNumber': params.filters?.productNumber,
          'Filter.CategoryIds': params.filters?.categoryIds,
          'Filter.ProductName': params.filters?.productName,
          'Filter.Notes': params.filters?.notes,
          'Filter.MinQuantity': params.filters?.quantity?.min,
          'Filter.MaxQuantity': params.filters?.quantity?.max,
          'Filter.MinUnitPrice': params.filters?.unitPrice?.min,
          'Filter.MaxUnitPrice': params.filters?.unitPrice?.max,
          'Filter.MinNetPrice': params.filters?.netPrice?.min,
          'Filter.MaxNetPrice': params.filters?.netPrice?.max,
          'Filter.MinDiscount': params.filters?.discount?.min,
          'Filter.MaxDiscount': params.filters?.discount?.max,
          'Filter.MinWeight': params.filters?.weight?.min,
          'Filter.MaxWeight': params.filters?.weight?.max,
          'Filter.Taxes': params.filters?.taxes,
          'Filter.PriceSources': params.filters?.priceSources,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  getQuoteLinesCanNotBeCopied(filters: MissingQuoteLinesParams): AxiosGenericResponse<GetEnquiryQuoteLinesResponse> {
    return instance.get(
      '/enquiry/quoteLine',
      {
        params: {
          'Filter.EnquiryId': filters.enquiryId,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
          'Filter.CanBeCopied': filters.canBeCopied,
        },
      },
    );
  },
  copyEnquiryQuoteLine(
    lineId: number,
    quoteVehicleIds: number[],
  ): AxiosSingleResponse<{ createdItems: Array<{ quoteVehicleId: number, quoteLineId: number }> }> {
    return instance.post(`/enquiry/quoteLine/${lineId}`, { quoteVehicleIds });
  },
  editEnquiryQuoteLine(lineId: number, data: Partial<PatchEnquiryQuoteLineDto>): AxiosNullableResponse {
    return instance.patch(`/enquiry/quoteLine/${lineId}`, data);
  },
  deleteEnquiryQuoteLine(lineId: number): AxiosNullableResponse {
    return instance.delete(`/enquiry/quoteLine/${lineId}`);
  },
  deleteEnquiryQuoteSelectedLines(quoteLineIds: number[]): AxiosNullableResponse {
    return instance.delete('/enquiry/quoteLine', { data: { quoteLineIds } });
  },
  getEnquiryAttachments(enquiryId: number, filters: CoreAttachmentsFilters): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/enquiry/enquiries/${enquiryId}/attachments`, {
      params: {
        'Filter.Name': filters.name,
        'Filter.MinUploadDate': filters.uploadDate?.min,
        'Filter.MaxUploadDate': filters.uploadDate?.max,
        'Filter.Comment': filters.comment,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
    });
  },
  getEnquiryAttachmentById(enquiryId: number, attachmentId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/enquiry/enquiries/${enquiryId}/attachments/${attachmentId}`);
  },
  postEnquiryAttachUploadUri(enquiryId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/enquiry/enquiries/${enquiryId}/attachments/uploadUri`, { uploadUriDtos });
  },
  postEnquiryAttach(enquiryId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/enquiry/enquiries/${enquiryId}/attachments`, { objectId: enquiryId, attachments });
  },
  editEnquiryAttachmentComment(enquiryId: number, attachmentId: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/enquiry/enquiries/${enquiryId}/attachments/${attachmentId}`, { comment });
  },
  deleteEnquiryAttachment(enquiryId: number, attachmentId: number): AxiosNullableResponse {
    return instance.delete(`/enquiry/enquiries/${enquiryId}/attachments/${attachmentId}`);
  },
  deleteMultipleEnquiryAttachment(enquiryId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/enquiry/enquiries/${enquiryId}/attachments`, { data: { attachmentIds } });
  },
  getEnquiryTimeline(enquiryId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<EnquiryEventDtoBase[]> {
    return instance.get('/enquiry/enquiryTimeline', {
      params: {
        EnquiryId: enquiryId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postEnquiryNote(enquiryId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/enquiry/note', { EnquiryId: enquiryId, Content: content });
  },
  putEnquiryNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/enquiry/note/${id}`, { Content: content });
  },
  deleteEnquiryNote(id: number): AxiosNullableResponse {
    return instance.delete(`/enquiry/note/${id}`);
  },
  getEnquiryTimelineQuoteLineBefore(lineGroupId: number, filters?: QuoteLinesFilters): AxiosDataListResponse<QuoteLineTimelineType[]> {
    return instance.get('/enquiry/enquiryTimeline/quoteLineGroupBefore', {
      params: {
        QuoteLineGroupId: lineGroupId,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'Filter.CategoryIds': filters?.filters?.categoryIds,
        'Filter.ProductName': filters?.filters?.productName,
        'Filter.ProductNumber': filters?.filters?.productNumber,
        'Filter.Notes': filters?.filters?.notes,
        'Filter.MinQuantity': filters?.filters?.quantity?.min,
        'Filter.MaxQuantity': filters?.filters?.quantity?.max,
        'Filter.MinUnitPrice': filters?.filters?.unitPrice?.min,
        'Filter.MaxUnitPrice': filters?.filters?.unitPrice?.min,
        'Filter.MinNetPrice': filters?.filters?.netPrice?.min,
        'Filter.MaxNetPrice': filters?.filters?.netPrice?.max,
        'Filter.MinDiscount': filters?.filters?.discount?.min,
        'Filter.MaxDiscount': filters?.filters?.discount?.max,
        'Filter.MinWeight': filters?.filters?.weight?.min,
        'Filter.MaxWeight': filters?.filters?.weight?.max,
        'Filter.Taxes': filters?.filters?.taxes,
        'Filter.PriceSources': filters?.filters?.priceSources,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  getEnquiryTimelineQuoteLineAfter(lineGroupId: number, filters?: QuoteLinesFilters): AxiosDataListResponse<QuoteLineTimelineType[]> {
    return instance.get('/enquiry/enquiryTimeline/quoteLineGroupAfter', {
      params: {
        QuoteLineGroupId: lineGroupId,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'Filter.CategoryIds': filters?.filters?.categoryIds,
        'Filter.ProductName': filters?.filters?.productName,
        'Filter.ProductNumber': filters?.filters?.productNumber,
        'Filter.Notes': filters?.filters?.notes,
        'Filter.MinQuantity': filters?.filters?.quantity?.min,
        'Filter.MaxQuantity': filters?.filters?.quantity?.max,
        'Filter.MinUnitPrice': filters?.filters?.unitPrice?.min,
        'Filter.MaxUnitPrice': filters?.filters?.unitPrice?.min,
        'Filter.MinNetPrice': filters?.filters?.netPrice?.min,
        'Filter.MaxNetPrice': filters?.filters?.netPrice?.max,
        'Filter.MinDiscount': filters?.filters?.discount?.min,
        'Filter.MaxDiscount': filters?.filters?.discount?.max,
        'Filter.MinWeight': filters?.filters?.weight?.min,
        'Filter.MaxWeight': filters?.filters?.weight?.max,
        'Filter.Taxes': filters?.filters?.taxes,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  createEnquiry(data: CreateEnquiryFormFieldsType): AxiosSingleResponse<CreateEnquiryResponse> {
    return instance.post('/enquiry/enquiry', { ...data });
  },
  fetchVehiclesSummary(quoteVehicleId: number): AxiosSingleResponse<{
    quoteVehicleSummary: QuoteVehicleSummaryDto,
    totalSummary: QuoteTotalSummaryDto,
  }> {
    return instance.get('/enquiry/quoteVehicle/summary', { params: { QuoteVehicleId: quoteVehicleId } });
  },
  enableQuoteLinesOptions(id: number, data: Partial<QuoteLinesOptionsType>): AxiosNullableResponse {
    return instance.patch(`/enquiry/quoteVehicle/${id}`, { ...data });
  },
  bulkPatchQuoteLinesOptions(quoteLineIds: number[], discount: number): AxiosNullableResponse {
    return instance.patch('/enquiry/quoteLine', { quoteLineIds, patchEnquiryQuoteLineDto: { discount } });
  },
  createPartsKitLine(quoteVehicleId: number, partsKitId: number): AxiosCreatedIdResponse {
    return instance.post('/enquiry/quoteLine/PartsKitLine', { quoteVehicleId, partsKitId });
  },
  putQuotePartsKitLine(quoteLineId: number): AxiosNullableResponse {
    return instance.put(`/enquiry/quoteLine/partsKitLine/${quoteLineId}/refreshPrice`);
  },
  createCustomLine(data: CreateCustomLineReqType): AxiosCreatedIdResponse {
    return instance.post('/enquiry/quoteLine/CustomLine', { ...data });
  },
  createPartLine(data: CreatePartLineReqDto): AxiosCreatedIdResponse {
    return instance.post('/enquiry/quoteLine/partLine', { ...data });
  },
  generateEnquiryExcel(id: number): AxiosDownloadUriResponse {
    return instance.get(`/enquiry/enquiry/excelFile/${id}`);
  },
  fetchEnquiryContacts(
    id: number,
    page: number,
    pageSize: number,
    haveEmail?: boolean,
  ): AxiosDataListResponse<EnquiryContactDto[], { allItemIds: number[] }> {
    return instance.get('/enquiry/contact/enquiryContact', {
      params: {
        EnquiryId: id,
        'PagingOptions.Page': page,
        'PagingOptions.ItemsPerPage': pageSize,
        'Filter.HaveEmail': haveEmail !== undefined ? haveEmail : null,
      },
    });
  },
  removeEnquiryContact(enquiryId: number, customerContactId: number): AxiosNullableResponse {
    return instance.delete('/enquiry/contact', { data: { enquiryId, customerContactId } });
  },
  createNewContact(data: NewContactReqDataType): AxiosNullableResponse {
    return instance.post('/enquiry/contact/new', { ...data });
  },
  addExistedContacts(enquiryId: number, customerContactIds: number[]): AxiosNullableResponse {
    return instance.post('/enquiry/contact/existing', { enquiryId, customerContactIds });
  },
  fetchEnquiryLinks(enquiryId: number, filters: LinksFiltersType): AxiosSingleResponse<{ orderLinks: Array<EntityLinkType> }> {
    return instance.get(`/enquiry/enquiries/${enquiryId}/links`, {
      params: {
        'Filter.EntityName': filters.entity,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'Filter.ModifiedDate.From': filters.modifiedDate?.min,
        'Filter.ModifiedDate.To': filters.modifiedDate?.max,
        'OrderOptions.Field': filters.order.field,
        'OrderOptions.Ascending': filters.order.isAsc,
      },
    });
  },
  exportEnquiryPdf(id: number): AxiosDownloadUriResponse {
    return instance.get(`/enquiry/enquiry/pdfFile/${id}`);
  },
  fetchDefaultEnquiryJobLocation(): AxiosSingleResponse<{ defaultAddress: DefaultAddressDto }> {
    return instance.get('/address/defaultAddress/enquiryJobLocation');
  },
  changeEnquiryCustomer(enquiryId: number, customerId: number): AxiosNullableResponse {
    return instance.put(`/enquiry/enquiry/${enquiryId}/customer`, { customerId });
  },
};
