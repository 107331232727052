import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DictionaryResponse, emptyPaging,
  ResponseResult, ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { IUser, UserListFiltersType } from '../../../settings/users-dashboard/types/settingsTypes';
import { settingsAPI } from '../../../api/settingsApi';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { setListFilters } from '../../slices/settingsSlice';
import { RootState } from '../../store';
import { employeeAPI } from '../../../api/employeeApi';

export const getSettingsUsersList = createAsyncThunk<ResponseResult<IUser[], { allItemIds: number[] }>,
{ filters: UserListFiltersType }
>(
  'get/settingsUsersList',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.fetchAccountUsers(filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const inviteUsersThunk = createAsyncThunk<ResponseSingleResult,
{ userAccountIds: number[], closeModal: VoidFunctionType }
>(
  'post/InviteUsers',
  async ({ userAccountIds, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { settings } = getState() as RootState;
      const { listFilters } = settings;
      const res = await settingsAPI.inviteUsers(userAccountIds);
      dispatch(setLoading(false));
      dispatch(getSettingsUsersList({ filters: listFilters }));
      closeModal();
      dispatch(setSuccessMessage({ message: `An invitation was sent to ${userAccountIds.length} user(s).` }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const resetUsersPasswordsThunk = createAsyncThunk<ResponseSingleResult,
{ userAccountIds: number[], closeModal: VoidFunctionType }
>(
  'post/ResetUsersPasswords',
  async ({ userAccountIds, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { settings } = getState() as RootState;
      const { listFilters } = settings;
      const res = await settingsAPI.resetUsersPassword(userAccountIds);
      dispatch(setLoading(false));
      dispatch(getSettingsUsersList({ filters: listFilters }));
      closeModal();
      dispatch(setSuccessMessage({ message: `A password reset link was sent to ${userAccountIds.length} user(s).` }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteUsersThunk = createAsyncThunk<null,
{
  userAccountIds: number[],
  deleteLinkedAccount?: boolean,
  linkedEmployeeAccountIds?: number[],
  closeModal: VoidFunctionType,
}
>(
  'delete/Users',
  async ({
    userAccountIds, deleteLinkedAccount, linkedEmployeeAccountIds, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { settings: { listFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await settingsAPI.fetchAccountUsers({
        ...listFilters, ...emptyPaging,
      });
      const filteredIds = userAccountIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await settingsAPI.deleteUsers(filteredIds);
        if (deleteLinkedAccount && linkedEmployeeAccountIds && linkedEmployeeAccountIds.length > 0) {
          await employeeAPI.deleteMultipleEmployees(linkedEmployeeAccountIds);
          dispatch(setSuccessMessage({ message: 'The employees were successfully deleted.' }));
        }
      }
      dispatch(setFetching(false));
      dispatch(setListFilters({ ...listFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: 'The users were successfully deleted.' }));
      closeModal();
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPermissionModules = createAsyncThunk<DictionaryResponse>(
  'get/permissionModules',
  async (_, { rejectWithValue }) => {
    try {
      const res = await settingsAPI.fetchPermissionModules();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
