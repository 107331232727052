import React from 'react';
import { setPurchaseOrderAttachmentsFilter } from '../../../../../../../store/slices/purchaseOrderSlice';
import {
  AttachmentsColumnsProps,
} from '../../../../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../../permissions-handling/permissionHook';
import { useAppSelector } from '../../../../../../../store/hooks';
import {
  purchaseOrderAttachmentsFiltersSelector,
} from '../../../../../../../store/selectors/purchaseOrderSelectors';
import AttachmentColumns from '../../../../../../../common/components/attachments-table/AttachmentColumns';
import { useCoreAttachmentsColumns } from '../../../../../../../core/hooks/useCoreAttachmentsColumns';

const PurchaseOrdersAttachmentsColumns: React.FC<AttachmentsColumnsProps> = ({
  isAll,
  checkAll,
  totalElements,
  indeterminate,
  disabledActions,
}) => {
  const allowedManage = usePermission(PermissionEnum.StockPurchaseOrdersEditFields);
  const attachmentsFilters = useAppSelector(purchaseOrderAttachmentsFiltersSelector);

  const {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  } = useCoreAttachmentsColumns(attachmentsFilters, setPurchaseOrderAttachmentsFilter);

  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      disabledActions={disabledActions}
      isAll={isAll}
      indeterminate={indeterminate}
      checkAll={checkAll}
      totalElements={totalElements}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={attachmentsFilters}
    />
  );
};

export default PurchaseOrdersAttachmentsColumns;
