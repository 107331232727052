import { SalesByCustomerReportData } from '../../../../../types/SalesReportByCustomerTypes';
import { initDataList } from '../../../../../../core/types/coreTypes';

export const initSalesReportByCustomerData: SalesByCustomerReportData = {
  ...initDataList,
  minCostNet: 0,
  maxCostNet: 0,
  minSalesNet: 0,
  maxSalesNet: 0,
  minDifferenceNet: 0,
  maxDifferenceNet: 0,
};
