import React from 'react';
import { CustomerContentEnum } from '../../enums/customerContentEnum';
import CustomerDetailsAndActivity from './details&activity/CustomerDetailsAndActivity';
import CustomerAttachments from './attachments/CustomerAttachments';
import CustomerContacts from './contacts/CustomerContacts';

type CustomerDynamicSectionProps = {
  mode: string | null,
}

const CustomerDynamicSection: React.FC<CustomerDynamicSectionProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case CustomerContentEnum.CUSTOMER_DETAILS:
        return <CustomerDetailsAndActivity />;
      case CustomerContentEnum.CUSTOMER_CONTACTS:
        return <CustomerContacts />;
      default: return <CustomerAttachments />;
    }
  };
  return (
    <>
      {renderContent()}
    </>
  );
};

export default CustomerDynamicSection;
