import React, { useEffect } from 'react';
import { initDataListWithIds, initSmallPaging, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import { addPartFiltersSelector, stockPartsSelector } from '../../../../../../store/selectors/pricelistSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import {
  setStockParts,
  setStockPartsFilters,
} from '../../../../../../store/slices/pricelistSlice';
import AddPartTable from './AddPartTable';
import {
  getPartPricelistCategories,
  getPartPricelistSubcategories,
  getStockPartsThunk,
  partPricelistCreateThunk,
} from '../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import './AddPartModal.scss';
import AddPartFilters from './AddPartFilters';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../../core/utils/regex';
import { isFetchingSelector } from '../../../../../../store/selectors/coreSelectors';
import { getSuitableVehicleProfiles } from '../../../../../../store/thunks/shared/sharedThunks';
import { vehicleProfilesModelDescrOrderSelector } from '../../../../../../store/selectors/coreOrderingSelectors';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';

type AddPartModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  pricelistId?: number,
}

const AddPartModal: React.FC<AddPartModalProps> = ({ onCancel, isVisible, pricelistId }) => {
  const dispatch = useAppDispatch();
  const addPartFilters = useAppSelector(addPartFiltersSelector);
  const { items, allItemIds, totalCount } = useAppSelector(stockPartsSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const vehModelDescrOrder = useAppSelector(vehicleProfilesModelDescrOrderSelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    setCheckedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    isVisible && addPartFilters.keyword && pricelistId
    && dispatch(getStockPartsThunk({ pricelistId, filters: addPartFilters }));
    // eslint-disable-next-line
  }, [addPartFilters, pricelistId, isVisible]);

  useEffect(() => {
    if (isVisible && vehModelDescrOrder) {
      dispatch(getSuitableVehicleProfiles({
        filters: {
          order: { field: vehModelDescrOrder, isAsc: true },
        },
      }));
    }
    // eslint-disable-next-line
  }, [isVisible, vehModelDescrOrder]);

  useEffect(() => {
    if (isVisible) {
      dispatch(getPartPricelistSubcategories({ filters: undefined }));
      dispatch(getPartPricelistCategories({ filters: undefined }));
    }
    // eslint-disable-next-line
  }, [isVisible]);
  const cancelHandle = () => {
    onCancel();
    dispatch(setStockParts(initDataListWithIds));
    dispatch(setStockPartsFilters(initSmallPaging));
    setCheckedKeys([]);
  };

  const addSelected = () => {
    pricelistId && dispatch(partPricelistCreateThunk({
      pricelistId,
      partIds: checkedKeys,
      closeModal: cancelHandle,
    }));
  };

  return (
    <Modal title="Add part to pricelist" visible={isVisible} onCancel={cancelHandle} width={1280} destroyOnClose>
      <div className="addPartModal">
        <AddPartFilters filters={addPartFilters} />
        <div className="addPartModal__pagination">
          <Pagination
            currentPage={addPartFilters.page}
            pageSize={addPartFilters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              dispatch(setStockPartsFilters({ ...addPartFilters, page, pageSize }));
            }}
            pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
            narrow
          />
        </div>
        <AddPartTable
          filters={addPartFilters}
          stockParts={items}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          checkRow={checkRowHandle}
          totalCount={totalCount}
          checkAll={checkAllHandle}
          checkedKeys={checkedKeys}
        />
        <ButtonActions
          cancelLabel="Cancel"
          createLabel={`Add selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
          createType="button"
          cancelClick={cancelHandle}
          disabledCreate={checkedKeys.length === 0}
          createClick={addSelected}
          isLoading={isFetching}
          offsetTop
        />
      </div>
    </Modal>
  );
};

export default AddPartModal;
