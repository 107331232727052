import React from 'react';
import SupplierActivitySection from './SupplierActivitySection';
import SupplierDetailsSection from './SupplierDetailsSection';

const SupplierDetailsAndActivity: React.FC = () => {
  return (
    <div className="info-grid">
      <SupplierDetailsSection />
      <SupplierActivitySection />
    </div>
  );
};

export default SupplierDetailsAndActivity;
