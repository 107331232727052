import React from 'react';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  jobCategoriesSelector,
  jobTypesSelector,
  priorityLevelsSelector,
} from '../../../../../../store/selectors/coreSelectors';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';
import { toOptionalFixed } from '../../../../../../common/utils/formatUtils';

const JobFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const jobTypes = useAppSelector(jobTypesSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'EstimatedMinutes':
        return toOptionalFixed((+value / 60), 2);
      case 'Assignees': {
        if (typeof value !== 'string') return value;
        return value
          .replace(/"/g, '')
          .replace(/,/g, ', ')
          .slice(1, -1);
      }
      case 'PriorityLevel': {
        return labelHandle(+value, priorityLevels);
      }
      case 'JobCategories': {
        return labelHandle(JSON.parse(value as string), jobCategories);
      }
      case 'JobType': {
        return labelHandle(+value, jobTypes);
      }
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default JobFieldUpdateEvent;
