import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudArrowUp } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { RcFile } from 'antd/lib/upload';
import { useAppSelector } from '../../../../../../store/hooks';
import { employeeMainInfoSelector } from '../../../../../../store/selectors/employeesSelector';
import Image from '../../../../../../core/components/image/Image';
import Upload from '../../../../../../core/components/upload/Upload';
import Button from '../../../../../../core/components/button/Button';
import { ALLOWED_IMAGE_FORMATS } from '../../../../../../core/utils/regex';
import DraggerUpload from '../../../../../../core/components/upload/Dragger';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';

type EmployeeMainInfoProps = {
  formats: string,
  beforeUpload: (file: RcFile[]) => void,
  imageUri: string | null,
  onChange: BooleanFunctionType,
  setConfirm: VoidFunctionType,
}

const EmployeeMainInfo: React.FC<EmployeeMainInfoProps> = ({
  formats, beforeUpload, imageUri, onChange, setConfirm,
}) => {
  const info = useAppSelector(employeeMainInfoSelector);
  const allowedToManage = usePermission(PermissionEnum.EmployeeEditFields);
  return (
    <div className="employeeMainInfo">
      <div className="employeeMainInfo__img-wrapper">
        {imageUri
          ? <>
            <Image src={imageUri} />
            {allowedToManage && <>
              <Upload
                accept={ALLOWED_IMAGE_FORMATS.join(',')}
                showUploadList={false}
                beforeUpload={beforeUpload}
                onChange={() => onChange(true)}
                icon={<FontAwesomeIcon icon={faCloudArrowUp} />}
                className="employeeMainInfo__change-btn"
              />
              <Button
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                className="employeeMainInfo__remove-btn"
                onClick={setConfirm}
              />
            </>}
          </>
          : <DraggerUpload
            accept={formats}
            showUploadList={false}
            beforeUpload={beforeUpload}
            onChange={() => onChange(false)}
            disabled={!allowedToManage}
            isImage
          />}
      </div>
      <ul className="employeeMainInfo__list">
        {info.map((el) => (
          <li className="employeeMainInfo__item" key={el.label}>
            <span className="employeeMainInfo__label">{el.label}</span>
            <TruncateTooltip value={el.value} className="employeeMainInfo__value" overlayClassName="employeeMainInfo__tooltip" />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default EmployeeMainInfo;
