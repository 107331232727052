import React, { useEffect, useState } from 'react';
import Layout from '../../core/components/layout/Layout';
import CreateEnquiryForm from './components/CreateEnquiryForm';
import './CreateEnquiry.scss';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { customersListSelector } from '../../store/selectors/customersSelectors';
import { getCustomers } from '../../store/thunks/customers/customersDashboardThunks';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';

const CreateEnquiry: React.FC = () => {
  const dispatch = useAppDispatch();
  const customers = useAppSelector(customersListSelector);
  const { backHandle } = useLocationHistoryState();
  const [keyword, setKeyword] = useState<string | undefined>(undefined);
  useEffect(() => {
    dispatch(getCustomers({
      params: {
        excludeCompany: true,
        keyword,
      },
    }));
    // eslint-disable-next-line
  }, [keyword]);
  return (
    <Layout subHeader={
      <DetailsHead goBack={() => backHandle()} isCreatePage>
        <div className="createHead">Create new enquiry</div>
      </DetailsHead>
    }
    >
      <CreateEnquiryForm customers={customers.items} setKeyword={setKeyword} keyword={keyword} />
    </Layout>
  );
};

export default CreateEnquiry;
