export enum OrderViewPageEnums {
  ORDER_DETAILS = 'details-and-activity',
  ORDER_PART_REQUEST = 'part-request',
  ORDER_CONTACTS = 'contacts',
  ORDER_ATTACHMENTS = 'attachments',
  ORDER_LINKS = 'links',
}

export const OrderModes = [
  OrderViewPageEnums.ORDER_DETAILS,
  OrderViewPageEnums.ORDER_PART_REQUEST,
  OrderViewPageEnums.ORDER_CONTACTS,
  OrderViewPageEnums.ORDER_ATTACHMENTS,
  OrderViewPageEnums.ORDER_LINKS,
];

export enum OrderPartRequestLineStatusEnum {
  PendingSource = 'Pending source',
  ToBeReviewed = 'To be reviewed',
  UnknownPart = 'Unknown part',
  NotForStock = 'Not for stock',
  Confirmed = 'Confirmed',
  PartiallyReady = 'Partially ready',
  Ready = 'Ready',
  PartiallyCollected = 'Partially collected',
  Collected = 'Collected',
  Archived = 'Archived',
  Deleted = 'Deleted',
}

export enum OrderPartReqLinesOrdering {
  OrdinalNumber = 'OrdinalNumber',
  Category = 'Category',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  NetPrice = 'NetPrice',
  Status = 'Status',
}

export enum PartRequestLinePriceSources {
  Stock = 'Stock',
  Pricelist = 'Pricelist',
  ManualInput = 'Manual input',
}

export enum OrderEventSource {
  Order = 'Order',
  Invoicing = 'Invoicing',
}
