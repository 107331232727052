import React from 'react';
import { EntityType } from '../../types/commonTypes';
import { viewPageLinksAndPermissions } from '../../utils/viewPageLinksAndPermissions';
import PrivateLineLink from '../private-line-link/PrivateLineLink';

type ViewPageLinkProps = {
  entityType: EntityType,
  entityId: number | null,
  children: React.ReactElement,
}
const ViewPageLink: React.FC<ViewPageLinkProps> = ({ entityType, entityId, children }) => {
  const foundModule = viewPageLinksAndPermissions.find(({ name }) => name === entityType);

  return (
    foundModule && entityId ? <PrivateLineLink
      to={`/${foundModule.to}/${entityId}`}
      permission={foundModule.permission}
      className="entityName"
    >
      {children}
    </PrivateLineLink> : children
  );
};

export default ViewPageLink;
