import React from 'react';
import { SupplierEventDtoBase } from '../../../../types/supplierViewPageTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import BillingShippingEvents
  from '../../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import { SupplierEventTypesEnum } from '../../../../enums/SupplierViewPageEnums';
import SupplierNoteEvent from './SupplierNoteEvent';
import SupplierFieldUpdateEvent from './SupplierFieldUpdateEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { supplierEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import ContactEvents from '../../../../../../common/components/activity-events/contact/ContactEvents';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';
import { supplierStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';

type SupplierEventProps = {
  item: SupplierEventDtoBase,
}

const SupplierEvent: React.FC<SupplierEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(supplierEventTypesSelector);
  const supplierStatuses = useAppSelector(supplierStatusesSelector);

  const attachmentEvents = [
    SupplierEventTypesEnum.AddingAttachmentWithoutComment,
    SupplierEventTypesEnum.AddingAttachmentWithComment,
    SupplierEventTypesEnum.EditingAttachmentComment,
    SupplierEventTypesEnum.DeletingAttachment,
  ];
  const contactEvents = [
    SupplierEventTypesEnum.AddingContact,
    SupplierEventTypesEnum.ContactChange,
    SupplierEventTypesEnum.RemovingContact,
  ];
  const billingShippingEvents = [
    SupplierEventTypesEnum.BillingDetailsUpdates,
  ];

  const supplierEventRenderFn = (item: SupplierEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case event === SupplierEventTypesEnum.Note:
        return <SupplierNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === SupplierEventTypesEnum.SupplierCreation:
        return <CreationEvent entity="Supplier" type="name" value={item.name} />;
      case event === SupplierEventTypesEnum.SupplierDeletion:
        return <DeletionEvent entity="Supplier" />;
      case event === SupplierEventTypesEnum.SupplierFieldUpdateOrAddingValue:
        return <SupplierFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === SupplierEventTypesEnum.SupplierStatusChange: {
        const prevStatus = supplierStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = supplierStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case (billingShippingEvents.includes(event as SupplierEventTypesEnum)):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (attachmentEvents.includes(event as SupplierEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (contactEvents.includes(event as SupplierEventTypesEnum)):
        return <ContactEvents
          eventName={event}
          contact={item.contact}
          firstName={event === SupplierEventTypesEnum.AddingContact ? item.contact?.firstName : item.firstName}
          lastName={event === SupplierEventTypesEnum.AddingContact ? item.contact?.lastName : item.lastName}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      default: return null;
    }
  };
  return <>{supplierEventRenderFn(item)}</>;
};

export default SupplierEvent;
