import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import Button from '../../../../../../core/components/button/Button';
import { BooleanFunctionType, StringFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import DatePickerComponent from '../../../../../../core/components/date-picker/DatePicker';
import Tr from '../../../../../../core/components/table-components/Tr';
import { DraftValuesType } from './EditPartModal';
import DecimalInputNumber from '../../../../../../core/components/input-number/DecimalInputNumber';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT, maxCommonDecimal } from '../../../../../../core/utils/regex';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';

type AddPriceFieldsProps = {
  setError: StringFunctionType,
  error: string,
  draftValues: DraftValuesType,
  setDraftValues: (value: DraftValuesType) => void,
  disabledSave: boolean,
  setPricesCopy: VoidFunctionType,
  setIsConfirmation: BooleanFunctionType,
}

const AddPriceFields: React.FC<AddPriceFieldsProps> = ({
  error, setError, setDraftValues, draftValues, disabledSave,
  setPricesCopy, setIsConfirmation,
}) => {
  const addHandle = () => {
    const replacedSell = draftValues.sellPrice?.replace(',', '.');
    const replacedCost = draftValues.costPrice?.replace(',', '.');
    if (replacedSell && replacedCost) {
      if (+replacedSell < +replacedCost) {
        setIsConfirmation(true);
      } else {
        setPricesCopy();
      }
    }
  };
  return (
    <Tr>
      <TdNew colSpan={6}>
        <div className="priceFootWrapper">
          <DecimalInputNumber
            label="Enter cost price"
            value={draftValues.costPrice || ''}
            onChange={(e) => {
              error && setError('');
              const v = e.target.value.replace(',', '.');
              setDraftValues({ ...draftValues, costPrice: v });
            }}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val) {
                setDraftValues({ ...draftValues, costPrice: '0.00' });
              }
            }}
            max={maxCommonDecimal}
          />
          <DecimalInputNumber
            label="Enter selling price"
            value={draftValues.sellPrice || ''}
            onChange={(e) => {
              error && setError('');
              const v = e.target.value.replace(',', '.');
              setDraftValues({ ...draftValues, sellPrice: v });
            }}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val) {
                setDraftValues({ ...draftValues, sellPrice: '0.00' });
              }
            }}
            max={maxCommonDecimal}
            error={error}
          />
          <DatePickerComponent
            label="Enter start date"
            value={draftValues.startDate ? moment(draftValues.startDate) : undefined}
            onChange={(v) => {
              setDraftValues({ ...draftValues, startDate: v ? moment(v).format(DEFAULT_DATE_FORMAT) : undefined });
            }}
            format={DEFAULT_UI_DATE_FORMAT}
            disabledDate={(currentDate) => {
              return (moment().add(-5, 'years') >= currentDate)
                || (moment().add(+5, 'years') <= currentDate);
            }}
          />
          <Button
            label="Add price"
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            onClick={addHandle}
            disabled={disabledSave}
            className="priceFootWrapper__add-btn"
            designType="dark"
            reversed
          />
        </div>
      </TdNew>
    </Tr>
  );
};

export default AddPriceFields;
