import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  allowedJobTypesForUserSelector,
  customerTypesSelector,
  linksTypesSelector, linkTypesValuesSelector, orderWarningsSelector,
  partRequestLinePriceSourcesSelector,
  priorityLevelsSelector,
  QuoteInfoSourcesSelector,
  supplyOnlyJobTypesSelector, vehicleInfoSourcesSelector,
  vehicleProfilesTypesUiSelector,
} from './coreSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { OrderStatusUiEnum } from '../../orders/dashboard/enums/ordersEnums';
import { OrderPartReqLinesOrdering } from '../../orders/orders-view-page/enums/orderViewPageEnums';
import { QuoteInfoSourcesUiNames, VehicleInfoSourcesUiNames } from '../../core/enums/dictionariesEnums';
import { linksReformHandler, linksUiViewHandle } from '../../core/utils/linksReformHandler';
import {
  orderDetailsSelector,
  partCategoriesLookupSelector,
  vehicleProfilesGroupsUiSelector,
} from './sharedSelectors';
import { vehiclesProfilesSelector } from './vehiclesSelectors';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { summaryFieldValueHandle } from '../../core/utils/summaryFieldValueHandle';
import {
  enquiryStatusSelector,
  jobInitUIStatusesSelector,
  jobStatusesSelector,
  orderStatusSelector,
  partRequestLineDeletedStatusSelector,
  partRequestLineStatusesSelector, partRequestStatusesUiSelector,
  rectificationStatusesSelector, vehicleStatusesSelector,
} from './coreStatusesSelectors';
import { stockPartRequestLinesOrderingSelector } from './coreOrderingSelectors';
import { labelHandle } from '../../core/utils/labelHandle';
import { AffectedEntityLineUi, EntityType, PartReqLineUIType } from '../../common/types/commonTypes';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';
import { getRequisiteInfo } from '../../common/utils/requisiteHelper';

export const orderNewStatusSelector = createSelector(orderStatusSelector, (statuses) => {
  return statuses.find((el) => el.label === OrderStatusUiEnum.New)?.value;
});

export const xeroFieldsOrderStatuses = createSelector(orderStatusSelector, (statuses) => {
  const completed = statuses.find((el) => el.label === OrderStatusUiEnum.Completed)?.value;
  const invoicing = statuses.find((el) => el.label === OrderStatusUiEnum.Invoicing)?.value;
  const acceptedByCustomer = statuses.find((el) => el.label === OrderStatusUiEnum.AcceptedByCustomer)?.value;
  return { completed, invoicing, acceptedByCustomer };
});

export const rejectionReasonOrderStatusesSelector = createSelector(orderStatusSelector, (statuses) => {
  const rejectedByCustomer = statuses.find((el) => el.label === OrderStatusUiEnum.RejectedByCustomer)?.value;
  const reasonReview = statuses.find((el) => el.label === OrderStatusUiEnum.ReasonReview)?.value;
  const cancelled = statuses.find((el) => el.label === OrderStatusUiEnum.Cancelled)?.value;
  return { rejectedByCustomer, reasonReview, cancelled };
});

export const orderReadModeStatusesSelector = createSelector(orderStatusSelector, (statuses) => {
  const invoicing = statuses.find((el) => el.label === OrderStatusUiEnum.Invoicing)?.value;
  const completed = statuses.find((el) => el.label === OrderStatusUiEnum.Completed)?.value;
  const cancelled = statuses.find((el) => el.label === OrderStatusUiEnum.Cancelled)?.value;
  return [invoicing, cancelled, completed];
});

export const blockedOrderSubmitAndUsualLinesActionsStatusesSelector = createSelector(orderStatusSelector, (statuses) => {
  const listToCheck = [
    OrderStatusUiEnum.ReadyForPostCheck,
    OrderStatusUiEnum.PostCheckPassed,
    OrderStatusUiEnum.PostCheckFailed,
    OrderStatusUiEnum.AcceptedByCustomer,
    OrderStatusUiEnum.RejectedByCustomer,
    OrderStatusUiEnum.Invoicing,
    OrderStatusUiEnum.Completed,
    OrderStatusUiEnum.Cancelled,
  ];
  return statuses.filter((st) => listToCheck.includes(st.label as OrderStatusUiEnum)).map((el) => el.value);
});

const ordersDashAssigneesSelector = (state: RootState) => {
  return state.orders.ordersDashAssignees.map((el) => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));
};

export const ordersDashFiltersSelector = (state: RootState) => state.orders.filters;
export const ordersDashFiltersListSelector = createSelector(
  ordersDashFiltersSelector,
  priorityLevelsSelector,
  ordersDashAssigneesSelector,
  allowedJobTypesForUserSelector,
  customerTypesSelector,
  orderWarningsSelector,
  (
    values,
    priorityLevels,
    users,
    jobTypes,
    customerTypes,
    warnings,
  ) => {
    return [
      {
        value: values?.priority || [],
        name: 'priority',
        label: 'Priority',
        options: priorityLevels,
      },
      {
        value: values?.assignedTo || [],
        name: 'assignedTo',
        label: 'Assigned to',
        options: users,
      },
      {
        value: values?.jobType || [],
        name: 'jobType',
        label: 'Job type',
        options: jobTypes,
      },
      {
        value: values?.customerType || [],
        name: 'customerType',
        label: 'Customer type',
        options: customerTypes,
      },
      {
        value: values.warnings || [],
        name: 'warnings',
        label: 'Warnings',
        options: warnings,
      },
    ];
  },
);

export const ordersColumnsPaginationSelector = (state: RootState) => state.orders.ordersColumnsPagination;
export const ordersCollectionsSelector = (state: RootState) => state.orders.orderCollections;
export const sortedOrderCollectionsSelector = createSelector(ordersCollectionsSelector, (collections) => {
  const newArr = collections ? [...collections] : null;
  return newArr?.sort((a, b) => a.order - b.order) || null;
});

export const orderDetailsHeadSelector = createSelector(
  orderDetailsSelector,
  customerTypesSelector,
  (details, customerTypes) => {
    const order = details?.order;
    const customerType = customerTypes.find((el) => el.value === order?.customerType)?.label;
    return [
      { label: 'Order No', value: order?.orderNumber },
      {
        label: 'Customer',
        value: order?.customerName || '-',
      },
      { label: 'Customer account No', value: order?.customerAccountNumber || '-' },
      { label: 'Customer type', value: customerType || '-' },
      { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', order?.createdDate || '') },
      { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', order?.modifiedDate || '') },
    ];
  },
);
export const orderDetailsIsJobTypeSupplySelector = createSelector(
  orderDetailsSelector,
  supplyOnlyJobTypesSelector,
  (details, supplyOnlyJobTypes) => {
    return supplyOnlyJobTypes.includes(details?.order?.jobType);
  },
);
export const orderDetailsRequisitesSelector = createSelector(orderDetailsSelector, (details) => {
  return getRequisiteInfo(details?.billingRequisite, details?.shippingRequisite, details?.order);
});

export const orderDetailsJobLocationSelector = createSelector(orderDetailsSelector, (details) => {
  const order = details?.order;
  return {
    companyName: order?.jobCompanyName || null,
    addressLine1: order?.jobAddressLine1 || null,
    addressLine2: order?.jobAddressLine2 || null,
    cityTown: order?.jobCityTown || null,
    region: order?.jobRegion || null,
    postalCode: order?.jobPostalCode || null,
    telephone: order?.jobTelephone || null,
  };
});

export const orderDetailsVehicleSelector = createSelector(orderDetailsSelector, (details) => {
  const order = details?.order;
  return order ? {
    vehicleStatus: order.vehicleStatus,
    vehicleInfoSource: order.vehicleInfoSource,
    manufacturer: order.manufacturer,
    modelDescription: order.modelDescription,
    slidingDoorsQuantity: order.slidingDoorsQuantity,
    grossVehicleWeightKg: order.grossVehicleWeightKg,
    maxPayload: order.maxPayload,
    regNumber: order.regNumber,
    chassisNumber: order.chassisNumber,
    vehicleId: order.vehicleId,
    vehicleProfileId: order.vehicleProfileId,
    vehicleCompanyName: order.vehicleCompanyName,
    vehicleAddressLine1: order.vehicleAddressLine1,
    vehicleAddressLine2: order.vehicleAddressLine2,
    vehicleCityTown: order.vehicleCityTown,
    vehicleRegion: order.vehicleRegion,
    vehiclePostalCode: order.vehiclePostalCode,
    vehicleTelephone: order.vehicleTelephone,
    group: order.vehicleProfileGroup,
    type: order.vehicleProfileType,
    code: order.vehicleProfileCode,
  } : undefined;
});

export const isOrderVehManualSelector = createSelector(
  vehicleInfoSourcesSelector,
  orderDetailsVehicleSelector,
  (sources, veh) => {
    const manualSource = sources.find((el) => el.label === VehicleInfoSourcesUiNames.ManualInput);
    return manualSource?.value === veh?.vehicleInfoSource;
  },
);
export const orderVehUiDataSelector = createSelector(
  orderDetailsVehicleSelector,
  (vehicle) => {
    const manufacturer = vehicle?.manufacturer ? `${vehicle.manufacturer} ` : '';
    const modelDescription = vehicle?.modelDescription ? `${vehicle.modelDescription} ` : '';
    const vehicleLocation = {
      vehicleCompanyName: vehicle?.vehicleCompanyName,
      vehicleAddressLine1: vehicle?.vehicleAddressLine1,
      vehicleAddressLine2: vehicle?.vehicleAddressLine2,
      vehicleCityTown: vehicle?.vehicleCityTown,
      vehicleRegion: vehicle?.vehicleRegion,
      vehiclePostalCode: vehicle?.vehiclePostalCode,
      vehicleTelephone: vehicle?.vehicleTelephone,
    };
    const specificInfo = {
      regNumber: vehicle?.regNumber,
      chassisNumber: vehicle?.chassisNumber,
      location: summaryFieldValueHandle(Object.values(vehicleLocation)),
    };

    return {
      vehicleId: vehicle?.vehicleId, name: manufacturer, info: modelDescription, specificInfo,
    };
  },
);

export const orderJobPartsStatusesListSelector = createSelector(
  orderDetailsSelector,
  jobInitUIStatusesSelector,
  partRequestLineStatusesSelector,
  partRequestLineDeletedStatusSelector,
  (details, jobsStatuses, partReqStatuses, deletedStatusValue) => {
    const order = details?.order;
    const jobStatusesList = order?.jobStatusCounts;
    const jobStatusesWithValue = jobStatusesList?.filter((el) => el.count);
    const jobStatusesUi = jobStatusesWithValue?.map((j) => ({
      label: jobsStatuses.find((st) => st.value === j.status)?.label || '-',
      value: j.count,
    })) || [];
    const partsReqStatusesList = details?.partRequestLineStatusCounts ?? [];
    const statusesListWithoutDeleted = partsReqStatusesList?.filter((el) => el.status !== deletedStatusValue);
    const statusesWithValue = statusesListWithoutDeleted.filter((el) => el.count);
    const partsReqStatusesUi = statusesWithValue.map((j) => ({
      label: partReqStatuses.find((st) => st.value === j.status)?.label || '-',
      value: j.count,
    }));

    return { jobStatusesUi, partsReqStatusesUi };
  },
);

export const orderStatusTransitionsListSelector = createSelector(orderStatusSelector, orderDetailsSelector, (statuses, details) => {
  const order = details?.order;
  const currentStatus = details?.order?.status;
  const currentStatusLabel = statuses?.find((el) => el.value === currentStatus)?.label || '';
  const currentStatusItem = { label: currentStatusLabel, value: currentStatus || '' };
  const availableStatuses = statuses.filter((st) => order?.statusTransitions?.includes(st.value));
  return (availableStatuses && currentStatus)
    ? [...availableStatuses, currentStatusItem]
    : [];
});

export const orderAttachmentsSelector = (state:RootState) => state.orders.orderAttachments;
export const orderAttachmentsFiltersSelector = (state:RootState) => state.orders.orderAttachmentsFilters;
export const orderSummarySelector = (state: RootState) => state.orders.orderSummary;
export const orderSummarySectionDataSelector = createSelector(orderSummarySelector, (orderSummary) => {
  const leftPartData = [
    {
      value: orderSummary?.grossVehicleWeightKg,
      label: 'Gross vehicle weight',
    },
    { value: orderSummary?.conversionWeightKg, label: 'Conversion weight' },
    {
      value: orderSummary?.maximumPayloadKg,
      label: 'Maximum payload',
    },
    {
      value: orderSummary?.remainingPayloadKg,
      label: 'Remaining payload',
    },
  ];

  const rightPartData = [
    { value: orderSummary?.net, label: 'Net' },
    { value: orderSummary?.vat, label: 'VAT' },
    {
      value: orderSummary?.discount,
      label: 'Discount',
    },
    { value: orderSummary?.gross, label: 'Gross' },
  ];
  return { leftPartData, rightPartData };
});

export const orderContactsSelector = (state: RootState) => state.orders.orderContacts;
export const orderContactsPagingSelector = (state: RootState) => state.orders.orderContactsPaging;
export const orderGenerateEmailContactsSelector = (state: RootState) => state.orders.orderGenerateEmailContacts;
export const orderGenerateEmailContactsPagingSelector = (state: RootState) => state.orders.orderGenerateEmailContactsPaging;
export const orderExistingContactsSelector = (state: RootState) => state.orders.orderExistingContacts;
export const orderExistingContactsPagingSelector = (state: RootState) => state.orders.orderExistingContactsPaging;

export const orderLinksFiltersSelector = (state: RootState) => state.orders.orderLinksFilters;
export const orderLinksSelector = (state: RootState) => state.orders.orderLinks?.enquiryLinks;
export const orderLinksRowsSelector = createSelector(
  orderLinksSelector,
  enquiryStatusSelector,
  rectificationStatusesSelector,
  jobStatusesSelector,
  vehicleStatusesSelector,
  linksTypesSelector,
  linkTypesValuesSelector,
  (
    data,
    enqStatuses,
    rectificationStatuses,
    jobStatuses,
    vehicleStatuses,
    types,
    linkTypesValues,
  ) => {
    const { rectificationLinkType, jobLinkType, vehicleLinkType } = linkTypesValues;
    const inheritanceArr = linksReformHandler(data || []);
    const statusLabel = (status: string | number, type?: number) => {
      if (type === rectificationLinkType) {
        return rectificationStatuses.find((st) => st.value === status)?.label || status;
      }
      if (type === jobLinkType) {
        return jobStatuses.find((st) => st.value === status)?.label || status;
      }
      if (type === vehicleLinkType) {
        return vehicleStatuses.find((st) => st.value === status)?.label || status;
      } else return enqStatuses.find((st) => st.value === status)?.label || status;
    };
    return linksUiViewHandle(inheritanceArr, statusLabel, types);
  },
);

export const partRequestLinesFiltersSelector = (state: RootState) => state.orders.partRequestLinesFilters;
export const partRequestLinesSelector = (state: RootState) => state.orders.partRequestLines;

export const orderPartRequestPartsSelector = (state: RootState) => state.orders.orderPartRequestParts;
export const orderPartReqDraftLinesFiltersSelector = (state: RootState) => state.orders.orderPartReqDraftLinesFilters;

export const orderPartRequestPartsKitSelector = (state: RootState) => state.orders.orderPartRequestPartsKit;
export const orderPartRequestPartsKitFiltersSelector = (state: RootState) => state.orders.orderPartRequestPartsKitFilters;

export const currentPartReqSourceSelector = (state: RootState) => state.orders.currentPartReqSource;

export const currentOrderInfoSourceValueSelector = createSelector(
  QuoteInfoSourcesSelector,
  currentPartReqSourceSelector,
  (sources, currentSource) => {
    return {
      isCore: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.CorePart,
      isNonCore: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.NonCorePart,
      isCustom: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.Custom,
      isPartsKit: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.PartsKit,
    };
  },
);

export const orderPartReqLinesColumnsSelector = createSelector(
  stockPartRequestLinesOrderingSelector,
  partCategoriesLookupSelector,
  partRequestLinePriceSourcesSelector,
  partRequestLineStatusesSelector,
  orderDetailsSelector,
  (ordering, categories, priceSources, statuses, details) => {
    let newCols = [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
        sorter: true,
        orderField: getSortIndex(OrderPartReqLinesOrdering.OrdinalNumber, ordering),
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        filters: categories,
        orderField: getSortIndex(OrderPartReqLinesOrdering.Category, ordering),
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(OrderPartReqLinesOrdering.ProductNumber, ordering),
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(OrderPartReqLinesOrdering.ProductName, ordering),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        filterSearch: true,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
      },
      {
        title: 'Unit price',
        dataIndex: 'unitPrice',
        prefix: '£',
      },
      {
        title: 'Source',
        dataIndex: 'priceSources',
        filters: priceSources,
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        suffix: '%',
      },
      {
        title: 'Net price',
        dataIndex: 'netPrice',
        sorter: true,
        orderField: getSortIndex(OrderPartReqLinesOrdering.NetPrice, ordering),
        prefix: '£',
      },
      {
        title: 'Tax',
        dataIndex: 'tax',
        suffix: '%',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        suffix: 'kg',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: true,
        filters: statuses,
        orderField: getSortIndex(OrderPartReqLinesOrdering.Status, ordering),
      },
    ];
    if (!details?.order?.enableDiscounts) {
      newCols = newCols.filter((col) => col.dataIndex !== 'discount');
    }
    if (!details?.order?.enableNotes) {
      newCols = newCols.filter((col) => col.dataIndex !== 'notes');
    }
    return newCols;
  },
);
export const orderPartReqUiLinesSelector = createSelector(
  partRequestLinesSelector,
  partCategoriesLookupSelector,
  orderDetailsSelector,
  partRequestLineStatusesSelector,
  (data, stockCategories, details, statuses) => {
    let list: PartReqLineUIType[] = data.items.map((el) => ({
      id: el.id,
      ordinalNumber: el.ordinalNumber,
      categoryId: stockCategories?.find((cat) => cat.value === el.categoryId)?.label || el.categoryId,
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      notes: el.notes || '-',
      quantity: el.quantity,
      unitPrice: el.unitPriceString || '0.00',
      priceSources: el.partsKitId ? [] : (el.priceSources || '-'),
      discount: el.discount || 0,
      netPrice: el.netPriceString || '0.00',
      tax: el.tax === null ? '-' : el.tax,
      weight: el.weight || 0,
      status: (el.partsKitId && el.childLines && el.childLines.length > 0)
        ? ' '
        : statuses.find((st) => el.status === st.value)?.label.toUpperCase() || el.status,
      partsKitId: el.partsKitId,
      partId: el.partId,
      partsKitComponentId: el.partsKitComponentId,
      partsKitComponentPartId: el.partsKitComponentPartId,
      unitOfMeasure: el.unitOfMeasure,
      isProductSuitable: el.isProductSuitable,
      isFree: el.isFree,
      childLines: el.childLines
        ? el.childLines.map((ch) => ({
          id: ch.id,
          ordinalNumber: `${el.ordinalNumber}.${ch.ordinalNumber}`,
          categoryId: stockCategories?.find((cat) => cat.value === ch.categoryId)?.label || ch.categoryId,
          productNumber: ch.productNumber || '-',
          productName: ch.productName || '-',
          notes: ch.notes || '-',
          quantity: ch.quantity,
          unitPrice: ch.unitPriceString || '0.00',
          priceSources: ch.priceSources || '-',
          discount: ch.discount || 0,
          netPrice: ch.netPriceString || '0.00',
          tax: ch.tax === null ? '-' : ch.tax,
          weight: ch.weight || 0,
          status: statuses.find((st) => ch.status === st.value)?.label.toUpperCase() || ch.status,
          partsKitId: ch.partsKitId,
          partId: ch.partId,
          partsKitComponentId: ch.partsKitComponentId,
          partsKitComponentPartId: ch.partsKitComponentPartId,
          unitOfMeasure: ch.unitOfMeasure,
          isProductSuitable: ch.isProductSuitable,
          isFree: ch.isFree,
        }))
        : [],
    }));
    if (!details?.order?.enableDiscounts) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      list = list.map(({ discount, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ discount, ...rest }) => rest) };
      });
    }
    if (!details?.order?.enableNotes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      list = list.map(({ notes, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ notes, ...rest }) => rest) };
      });
    }
    return list;
  },
);

export const orderTimelineSelector = (state: RootState) => state.orders.orderTimeline;
export const orderTimelineFiltersSelector = (state: RootState) => state.orders.orderTimelineFilters;

export const orderCatalogVehicleProfileFiltersSelector = (state: RootState) => state.orders.orderCatalogVehicleProfileFilters;
export const orderCatalogVehicleProfilesSelector = createSelector(
  vehiclesProfilesSelector,
  orderCatalogVehicleProfileFiltersSelector,
  vehicleProfilesGroupsUiSelector,
  vehicleProfilesTypesUiSelector,
  (data, filters, groups, types) => {
    return {
      ...data,
      items: data.items.map((item, index) => ({
        id: item.id,
        number: getNo(filters.page, filters.pageSize, index),
        code: item.code,
        manufacturer: item.manufacturer,
        group: groups.find((el) => el.value === item.group)?.label || item.group,
        modelDescription: item.modelDescription,
        type: types.find((type) => type.value === item.type)?.label || item.type,
        maxPayload: item.maxPayload || 0,
        grossVehicleWeightKg: item.grossVehicleWeightKg || 0,
      })),
    };
  },
);

const orderAffectedEntitiesSelector = (state: RootState) => state.orders.orderAffectedEntities;
export const orderAffectedEntitiesCellsSelector = createSelector(
  rectificationStatusesSelector,
  partRequestStatusesUiSelector,
  jobStatusesSelector,
  orderAffectedEntitiesSelector,
  (
    rectificationStatuses,
    partRequestStatuses,
    jobStatuses,
    data,
  ) => {
    const { items, totalCount, pages } = data;
    const getStatusLabel = (type: EntityType, value: number | null) => {
      switch (type) {
        case 'Rectification': return labelHandle(value, rectificationStatuses);
        case 'Part request': return labelHandle(value, partRequestStatuses);
        case 'Job': return labelHandle(value, jobStatuses);
        default: return value;
      }
    };
    const entities: AffectedEntityLineUi[] = items.map((el) => ({
      entityId: el.id,
      entityType: el.entityType || '-',
      entityNumber: el.entityNumber || '-',
      createdAt: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.createdAt),
      entityStatus: getStatusLabel(el.entityType, el.entityStatus)?.toString() || '-',
    }));

    return { items: entities, totalCount, pages };
  },
);
