import React, { useEffect, useState } from 'react';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import {
  getStockPartRequestTimelineThunk,
  postStockPartRequestTimelineNoteThunk,
} from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  stockPartRequestTimelineFiltersSelector,
  stockPartRequestTimelineSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import Modal from '../../../../../core/components/modal/Modal';
import { initDataList, VoidFunctionType } from '../../../../../core/types/coreTypes';
import StockPartRequestEvent from './part-request-event-description/StockPartRequestEvent';
import Switch from '../../../../../core/components/switch/Switch';
import { setStockPartRequestTimeline, setStockPartRequestTimelineFilters } from '../../../../../store/slices/stockPartRequestSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';
import Spinner from '../../../../../core/components/spinner/Spinner';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import { getPartRequestEventTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';
import { partRequestEventTypesSelector } from '../../../../../store/selectors/coreEventTypesSelectors';
import { initExtendedTimelineFilters } from '../../../../../common/types/commonTypes';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

type PartRequestActivityModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  id?: number,
}
const PartRequestActivityModal: React.FC<PartRequestActivityModalProps> = ({ visible, onClose, id }) => {
  const dispatch = useAppDispatch();
  const timelineFilters = useAppSelector(stockPartRequestTimelineFiltersSelector);
  const { items, totalCount } = useAppSelector(stockPartRequestTimelineSelector);
  const eventTypes = useAppSelector(partRequestEventTypesSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const [value, setValue] = useState('');

  const allowedToAddNote = usePermission(PermissionEnum.StockPartRequestViewPage);

  useEffect(() => {
    if (visible && id) {
      dispatch(getStockPartRequestTimelineThunk({
        partRequestId: id,
        filters: timelineFilters,
      }));
    }
    // eslint-disable-next-line
  }, [visible, timelineFilters]);

  useEffect(() => {
    if (visible && !eventTypes.length) {
      dispatch(getPartRequestEventTypesThunk());
    }
    // eslint-disable-next-line
  }, [visible, eventTypes.length]);

  const addNote = () => {
    id && dispatch(postStockPartRequestTimelineNoteThunk({
      partRequestId: id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <StockPartRequestEvent item={el} />,
  }));

  const closeActivityModal = () => {
    onClose();
    dispatch(setStockPartRequestTimelineFilters(initExtendedTimelineFilters));
    dispatch(setStockPartRequestTimeline(initDataList));
  };

  return (
    <Modal visible={visible} onCancel={closeActivityModal} width={800} title="Activity" className="timeline-modal">
      <div className="timeline-modal__actions">
        <Pagination
          currentPage={timelineFilters.page}
          totalItems={totalCount}
          pageSize={timelineFilters.pageSize}
          onChange={(page) => {
            dispatch(setStockPartRequestTimelineFilters({
              ...timelineFilters,
              page,
            }));
          }}
          showSizeChanger={false}
          showTotal={false}
        />
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(value) => {
            dispatch(setStockPartRequestTimelineFilters({
              ...timelineFilters,
              mainOnly: !value,
              page: 1,
            }));
          }}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <div className="timeline-modal__timeline-wrapper">
        {isFetching && <Spinner global={false} />}
        {!items.length && <EmptyContentList icon={faWaveSquare} text="No data" size="sm" offsetTop={false} />}
        <Timeline timelineList={timelineList} />
      </div>
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </Modal>
  );
};

export default PartRequestActivityModal;
