import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faCalendarDays } from '@fortawesome/free-regular-svg-icons';
import Modal from '../../../../core/components/modal/Modal';
import { NumberFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../core/components/table-components/Thead';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import Tbody from '../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import './EmployeesHolidaysModal.scss';
import Select from '../../../../core/components/select/Select';
import Input from '../../../../core/components/input/Input';
import DatePickerComponent from '../../../../core/components/date-picker/DatePicker';
import Button from '../../../../core/components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../../../core/utils/regex';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { addEmployeeHoliday, getEmployeeHolidays } from '../../../../store/thunks/employee/employeesWorkCalendarThunks';
import { holidaysOrderingSelector } from '../../../../store/selectors/coreOrderingSelectors';
import { getHolidaysOrdering } from '../../../../store/thunks/core/coreOrderingThunks';
import {
  employeeHolidaysFiltersSelector,
  employeeHolidaysListSelector,
  employeeHolidaysYearsDictionarySelector,
} from '../../../../store/selectors/employeesSelector';
import { setEmployeeHolidaysFilters } from '../../../../store/slices/employeesSlice';
import { HolidaysOrderingEnum } from '../../enums/workCalendarEnums';
import { holidayColumns } from '../../utils/data';
import TableLoader from '../../../../core/components/table-components/TableLoader';

type EmployeesHolidaysModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  setHolidayIdToDelete: NumberFunctionType,
}
const EmployeesHolidaysModal: React.FC<EmployeesHolidaysModalProps> = ({
  visible,
  onCancel,
  setHolidayIdToDelete,
}) => {
  const dispatch = useAppDispatch();
  const holidaysOrdering = useAppSelector(holidaysOrderingSelector);
  const holidaysFilters = useAppSelector(employeeHolidaysFiltersSelector);
  const years = useAppSelector(employeeHolidaysYearsDictionarySelector);
  const holidaysList = useAppSelector(employeeHolidaysListSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const currentYear = new Date().getFullYear();

  const [name, setName] = useState('');
  const [date, setDate] = useState('');
  const [nameError, setNameError] = useState('');
  const [dateError, setDateError] = useState('');

  useEffect(() => {
    if (!holidaysOrdering.length) {
      dispatch(getHolidaysOrdering());
    } else {
      const defaultField = holidaysOrdering.find((el) => el.label === HolidaysOrderingEnum.Date)?.value;
      if (defaultField) {
        dispatch(setEmployeeHolidaysFilters({ ...holidaysFilters, year: currentYear, order: { field: defaultField, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [holidaysOrdering]);

  useEffect(() => {
    if (visible && holidaysFilters.order?.field) {
      dispatch(getEmployeeHolidays({ filters: holidaysFilters }));
    }
    // eslint-disable-next-line
  }, [visible, holidaysFilters]);

  const clearInputs = () => {
    setDate('');
    setName('');
    setDateError('');
    setNameError('');
  };

  const handleAddHoliday = () => {
    if (!date) {
      setDateError(ErrorsEnum.REQUIRED);
    } else if (!name.trim().length) {
      setNameError(ErrorsEnum.REQUIRED);
      setName('');
    } else {
      dispatch(addEmployeeHoliday({
        data: {
          name: name.trim(),
          date,
        },
        clearInputs,
        yearOfHoliday: moment(date).year(),
        setDateError,
      }));
    }
  };

  const handleClose = () => {
    dispatch(setEmployeeHolidaysFilters({ ...holidaysFilters, year: currentYear }));
    clearInputs();
    onCancel();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title="Holidays"
      width={800}
      destroyOnClose
    >
      <Select
        value={holidaysFilters.year || years[0]?.value || currentYear}
        options={years}
        onChange={(value) => dispatch(setEmployeeHolidaysFilters({ ...holidaysFilters, year: value as number }))}
        parentRender
        className="employeesHolidays__year"
      />
      <div className="table-fixed-footer">
        <TableNew small hasFooter>
          <Thead>
            <Tr>
              {holidayColumns.map((col) => (
                <ThNew key={col.dataIndex} className={`employeesHolidaysTh--${col.dataIndex}`}>
                  {col.name}
                </ThNew>
              ))}
              <ThNew action><span className="sr-only">Actions</span></ThNew>
            </Tr>
          </Thead>
          {isGlobalLoading
            ? <TableLoader colspan={4} />
            : <Tbody>
              {holidaysList.length > 0
                ? holidaysList.map((el) => (
                  <Tr key={el.id}>
                    {Object.entries(el).map(([key, value]) => {
                      if (key === 'id') return null;
                      return <TdNew key={key}>
                        <TruncateTooltip value={value} table />
                      </TdNew>;
                    })}
                    <TdNew action>
                      <Dropdown
                        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                        menuItems={[{ label: 'Delete holiday', key: 'delete-holiday', onClick: () => setHolidayIdToDelete(el.id) }]}
                      />
                    </TdNew>
                  </Tr>))
                : <EmptyTableSection colSpan={4} text="No holidays" icon={faCalendarDays} />}
            </Tbody>}
        </TableNew>
        <div className="table-fixed-footer__footer">
          <div className="employeesHolidays__actions">
            <Input
              label="Name"
              value={name}
              onChange={(e) => {
                const { value } = e.target;
                if (value.trim().length > 100) {
                  setNameError(maxLengthMessageHandle(100));
                } else {
                  setNameError('');
                }
                setName(e.target.value);
              }}
              error={nameError}
            />
            <DatePickerComponent
              label="Date"
              onChange={(value) => {
                dateError && setDateError('');
                setDate(value?.format(DEFAULT_DATE_FORMAT) || '');
              }}
              value={date ? moment(date) : undefined}
              disabledDate={(currentDate) => {
                const MIN_DATE = '2000-01-01';
                const tooEarly = currentDate.isBefore(MIN_DATE);
                const tooLate = currentDate.isAfter(moment().add(+5, 'years'));
                return tooEarly || tooLate;
              }}
              format={DEFAULT_UI_DATE_FORMAT}
              error={dateError}
            />
            <Button
              label="Add holiday"
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              className="employeesHolidays__add-btn"
              designType="dark"
              isLoading={isFetching}
              onClick={handleAddHoliday}
              disabled={!name || !date || !!nameError || isGlobalLoading}
              reversed
            />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EmployeesHolidaysModal;
