import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { SettingsPageParamEnum, SettingsViewsEnums } from '../enums/settingsEnums';

export const FORBIDDEN_RESET_PASS_MESSAGE = 'A password reset link cannot be sent to selected users.';
export const FORBIDDEN_INVITATION_MESSAGE = 'An invitation cannot be sent to selected users.';
export const FORBIDDEN_DELETE_USERS_MESSAGE = 'Selected users can not be deleted.';

export const PermissionPermissions = [
  PermissionEnum.UserPermissionsPage,
  PermissionEnum.UserEditPermissions,
];

export const settingsDashOptions = [
  {
    label: SettingsViewsEnums.USERS,
    value: SettingsPageParamEnum.USERS,
    restrictedTo: PermissionEnum.UserListPage,
  },
  {
    label: SettingsViewsEnums.PERMISSIONS,
    value: SettingsPageParamEnum.PERMISSIONS,
    restrictedTo: PermissionEnum.UserPermissionsPage,
  },
  {
    label: SettingsViewsEnums.CREATE,
    value: SettingsPageParamEnum.CREATE,
    restrictedTo: PermissionEnum.UserCreatePage,
  },
  {
    label: SettingsViewsEnums.INFO_MESSAGES,
    value: SettingsPageParamEnum.INFO_MESSAGES,
    restrictedTo: PermissionEnum.SettingsInfoMessagePage,
  },
];
