import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  customerContactsFiltersSelector, customerContactsListSelector,
} from '../../../../../store/selectors/customersSelectors';
import CustomerContactModal from './CustomerContactModal';
import {
  deleteCustomerContactThunk,
  getCustomerContactsList,
} from '../../../../../store/thunks/customers/customerContactsThunks';
import CustomerContactLine from './CustomerContactLine';
import { setCustomerContactsFilters } from '../../../../../store/slices/customersSlice';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import Button from '../../../../../core/components/button/Button';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import AddressModal from '../../../../../common/components/address/AddressModal';
import { AddressFields, ContactDto, initAddressFields } from '../../../../../common/types/commonTypes';
import { customerDefaultContactsOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { infiniteScrollInitPaging } from '../../../../../core/types/coreTypes';

const CustomerContacts:React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const contactList = useAppSelector(customerContactsListSelector);
  const filters = useAppSelector(customerContactsFiltersSelector);
  const defaultContactsOrdering = useAppSelector(customerDefaultContactsOrderingSelector);
  const allowedCreate = usePermission(PermissionEnum.CustomerEditFields);

  const [isContactModal, setContactModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<ContactDto>();
  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);

  useEffect(() => {
    if (defaultContactsOrdering && !filters.order) {
      dispatch(setCustomerContactsFilters({
        ...filters,
        order: { ...defaultContactsOrdering },
      }));
    }
    // eslint-disable-next-line
  }, [defaultContactsOrdering, filters]);

  useEffect(() => {
    id && filters.order && dispatch(getCustomerContactsList({
      customerId: +id,
      filters,
    }));
    // eslint-disable-next-line
  }, [id, filters.order]);

  useEffect(() => {
    return () => {
      dispatch(setCustomerContactsFilters(infiniteScrollInitPaging));
    };
    // eslint-disable-next-line
  }, []);

  const loadMore = () => {
    const newValues = { ...filters, page: filters.page + 1 };
    dispatch(setCustomerContactsFilters(newValues));
    id && dispatch(getCustomerContactsList({
      customerId: +id,
      filters: newValues,
    }));
  };

  useEffect(() => {
    if (selectedContact) {
      setAddressFields({
        addressLine1: selectedContact.addressLine1,
        addressLine2: selectedContact.addressLine2,
        cityTown: selectedContact.cityTown,
        region: selectedContact.region,
        postalCode: selectedContact.postalCode,
      });
    } else {
      setAddressFields(initAddressFields);
    }
  }, [selectedContact]);

  return (
    <>
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setDelete(false);
          setSelectedContact(undefined);
        }}
        confirmHandler={() => selectedContact && id && dispatch(deleteCustomerContactThunk({
          customerId: selectedContact?.customerId || +id,
          contactId: selectedContact.id,
          closeModal: () => {
            setDelete(false);
            setSelectedContact(undefined);
          },
        }))}
      />
      <CustomerContactModal
        isVisible={isContactModal}
        onClose={() => setContactModal(false)}
        addressFields={addressFields}
        setAddress={setAddressModal}
        setAddressFields={setAddressFields}
        contact={selectedContact}
        setSelectedContact={(contact: ContactDto | undefined) => setSelectedContact(contact)}
      />
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        addressFields={addressFields}
        isExtendedFields={false}
      />
      <section className="contacts">
        {allowedCreate && <Button
          label="Add contact"
          onClick={() => setContactModal(true)}
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          htmlType="button"
          designType="dark"
          reversed
        />}
        <div className="contacts__wrapper">
          {contactList.items && contactList.items?.length > 0
            ? <InfiniteScroll
              dataLength={contactList.items.length}
              next={loadMore}
              hasMore={filters.page < contactList.pages}
              loader={<span className="contacts__loader">Loading...</span>}
            >
              <ul className="lines-list">
                {contactList.items.map((line) => (
                  <li
                    className="lines-list__item"
                    key={line.id}
                  >
                    <CustomerContactLine
                      line={line}
                      setDelete={() => setDelete(true)}
                      setEdit={() => setContactModal(true)}
                      setSelectedContact={(contact: ContactDto) => setSelectedContact(contact)}
                    />
                  </li>))}
              </ul>
            </InfiniteScroll>
            : <EmptyContentList icon={faAddressCard} text="Please add a contact to this customer" />}
        </div>
      </section>
    </>
  );
};

export default CustomerContacts;
