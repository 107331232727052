import { useEffect, useState } from 'react';
import { ActionCreatorWithPayload, AsyncThunk } from '@reduxjs/toolkit';
import { useAppDispatch } from '../../store/hooks';
import {
  DictionaryItem, DictionaryResponse, initPaging, OrderingType, PagingType,
} from '../types/coreTypes';

type Params<T> = {
  shouldFetch?: boolean,
  filters: T,
  ordering: DictionaryItem[],
  getOrderingThunk?: AsyncThunk<DictionaryResponse, void, Record<string, never>>,
  setFiltersAction: ActionCreatorWithPayload<T>
  initialOrdering: string,
  initialPaging?: PagingType,
  isAsc?: boolean,
}
export const useDefaultPagingAndOrdering = <T extends { order?: OrderingType }>(params: Params<T>) => {
  const {
    shouldFetch = true,
    ordering,
    getOrderingThunk,
    setFiltersAction,
    filters,
    initialOrdering,
    initialPaging = initPaging,
    isAsc = true,
  } = params;
  const dispatch = useAppDispatch();
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    shouldFetch && !ordering.length && getOrderingThunk && dispatch(getOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const foundOrderingValue = ordering.find((el) => el.label === initialOrdering)?.value;
      if (foundOrderingValue) {
        setDefaultOrder(foundOrderingValue);
        dispatch(setFiltersAction({ ...filters, order: { field: foundOrderingValue, isAsc } }));
      }
    }
    // eslint-disable-next-line
  }, [ordering]);

  return {
    ...initialPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc } : filters.order,
  };
};
