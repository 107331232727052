import { createSelector } from '@reduxjs/toolkit';
import { DataNode } from 'antd/lib/tree';
import { RootState } from '../store';
import { partQtyStatusesValuesSelector, partRequestLineStatusesSelector, qtyStatusesSelector } from './coreStatusesSelectors';
import {
  partRequestLineActivityTypesSelector,
  partRequestLinePriceSourcesSelector,
  QuoteUnitsOfMeasureSelector,
  usersLookupListSelector,
} from './coreSelectors';
import { PartAdjustQtyOrderingEnum, PartQtyStatusEnum } from '../../stock/part-view-page/enums/PartTypesEnum';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { labelHandle } from '../../core/utils/labelHandle';
import { adjustPartQtyOrderingSelector } from './coreOrderingSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { toPoundCurrency } from '../../common/utils/formatUtils';
import { ValueDiffDto } from '../../common/types/commonTypes';

export const rectificationDetailsSelector = (state: RootState) => state.shared.rectificationDetails;
export const orderDetailsSelector = (state: RootState) => state.shared.orderDetails;

export const orderDictionaryFiltersSelector = (state: RootState) => state.shared.orderDictionaryFilters;

const ordersDictionaryInitSelector = (state: RootState) => state.shared.ordersDictionary;
export const ordersDictionarySelector = createSelector(ordersDictionaryInitSelector, (orders) => {
  return orders.map((el) => ({
    label: `${el.orderNumber}${el.orderNumber && el.customerName ? ' / ' : ''}${el.customerName}`,
    value: el.id,
  }));
});

export const rectificationDictionaryFiltersSelector = (state: RootState) => state.shared.rectificationDictionaryFilters;

const rectificationDictionaryInitSelector = (state: RootState) => state.shared.rectificationsDictionary;
export const rectificationDictionarySelector = createSelector(rectificationDictionaryInitSelector, (dictionary) => {
  return dictionary.map((el) => ({
    label: `${el.rectificationNumber}${el.rectificationNumber && el.customerName ? ' / ' : ''}${el.customerName}`,
    value: el.id,
  }));
});

export const partCategoriesInitSelector = (state: RootState) => state.shared.partCategories;
export const partCategoriesSelector = createSelector(
  partCategoriesInitSelector,
  (data) => data.items,
);
export const partCategoriesLookupSelector = createSelector(
  partCategoriesInitSelector,
  (data) => data.items.map((el) => ({
    label: el.name || '',
    value: el.id,
  })),
);
export const partCategoriesWithoutEmptyLookupSelector = createSelector(
  partCategoriesInitSelector,
  (data) => data.items
    .filter((el) => el.subcategoriesCount > 0)
    .map((el) => ({
      label: el.name || '',
      value: el.id,
    })),
);

const partSubcategoriesInitSelector = (state: RootState) => state.shared.partSubcategories;
export const partSubcategoriesLookupSelector = createSelector(
  partSubcategoriesInitSelector,
  (data) => data.items.map((el) => ({
    label: el.name || '',
    value: el.id,
  })),
);

const partSubcategoriesFilteredInitSelector = (state: RootState) => state.shared.partSubcategoriesFiltered;
export const partSubcategoriesFilteredLookupSelector = createSelector(
  partSubcategoriesFilteredInitSelector,
  (data) => data.items.map((el) => ({
    label: el.name || '',
    value: el.id,
  })),
);

export const partCategoriesTreeSelector = createSelector(
  partCategoriesSelector,
  partSubcategoriesInitSelector,
  (categories, subcategories) => {
    const { items } = subcategories;
    const treeData: DataNode[] = categories.filter((c) => c.subcategoriesCount > 0).map((c) => {
      const subs = items.filter((s) => s.categoryId === c.id);
      return {
        title: c.name || '-',
        key: `category-${c.id}`,
        children: subs.map((sub) => ({
          title: sub.name || '',
          key: sub.id,
        })),
      };
    });
    return treeData;
  },
);

const customersDictionaryInitSelector = (state: RootState) => state.shared.customersDictionary;
export const customersDictionaryLookupSelector = createSelector(customersDictionaryInitSelector, (customer) => {
  return customer.map((el) => ({
    label: `${el.accountNumber || ''} ${el.name || ''}`,
    value: el.id,
  }));
});

const supplierDictionaryInitSelector = (state: RootState) => state.shared.supplierDictionary;
export const supplierDictionaryLookupSelector = createSelector(supplierDictionaryInitSelector, (supplier) => {
  return supplier.map((el) => ({
    label: `${el.supplierAccountNumber} ${el.supplierName}`,
    value: el.id,
  }));
});

const initSuitableVehiclesProfiles = (state: RootState) => state.shared.suitableVehiclesProfiles;
export const suitableVehiclesProfilesSelector = createSelector(initSuitableVehiclesProfiles, (list) => {
  const arr = list?.items.map((item) => ({
    value: item.id,
    label: item.modelDescription || item.manufacturer || 'N/A',
  }));
  return { items: arr, totalCount: list?.totalCount || 0, pages: list?.pages || 0 };
});

export const vehiclesProfilesManufacturersSelector = (state: RootState) => state.shared.vehiclesProfilesManufacturers;
export const vehicleManufacturersDictionarySelector = createSelector(vehiclesProfilesManufacturersSelector, (list) => {
  return list.map((el) => ({ label: el, value: el }));
});

export const vehicleProfilesGroupsSelector = (state: RootState) => state.shared.vehicleProfilesGroups;
export const vehicleProfilesGroupsUiSelector = createSelector(vehicleProfilesGroupsSelector, (items) => {
  return items.items.map((el) => ({ label: el.name || 'N/A', value: el.id }));
});

export const vehicleProfilesGroupsUiWithoutAllSelector = createSelector(vehicleProfilesGroupsSelector, (data) => {
  return { pages: data.pages, totalCount: data.totalCount, items: data.items.filter((el) => el.name !== 'All') };
});

export const productSourceDictionarySelector = (state: RootState) => state.shared.productSourceDictionary;
export const productSourceTotalSelector = (state: RootState) => state.shared.productSourceTotal;
export const productSourceTotalInitCopySelector = (state: RootState) => state.shared.productSourceTotalInitCopy;

const initPurchaseOrderViewsSelector = (state: RootState) => state.shared.purchaseOrderViews;
export const purchaseOrderViewsSelector = createSelector(initPurchaseOrderViewsSelector, (list) => {
  return list.map((el) => ({ label: el.purchaseOrderNumber || '(Blank)', value: el.id }));
});

export const addressSuggestionsSelector = (state: RootState) => state.shared.addressSuggestions;

export const readyCollectedPartQuantitiesFiltersSelector = (state: RootState) => state.shared.readyCollectedPartQuantitiesFilters;
const initReadyCollectedPartQuantitiesSelector = (state: RootState) => state.shared.readyCollectedPartQuantities;
export const readyCollectedPartQuantitiesSelector = createSelector(
  initReadyCollectedPartQuantitiesSelector,
  readyCollectedPartQuantitiesFiltersSelector,
  qtyStatusesSelector,
  partRequestLinePriceSourcesSelector,
  (data, filters, statuses, sources) => {
    // we do it manually cause there is a difference in statuses spelling
    const renamedStatuses = statuses.map((el) => {
      if (el.label === PartQtyStatusEnum.Picked) {
        return { ...el, label: 'Ready' };
      } else return el;
    });
    const newItems = data?.partQuantities?.items
      ? data.partQuantities.items.map((item, i) => ({
        id: item.id,
        number: getNo(filters.page, filters.pageSize, i),
        source: labelHandle(item.source, sources),
        quantity: item.quantity,
        costPrice: item.costPrice,
        addedDate: item.addedDate,
        status: labelHandle(item.status, renamedStatuses),
        purchaseOrderNo: item.purchaseOrderNumber || '-',
        purchaseOrderId: item.purchaseOrderId,
        owner: item.owner ? `${item.owner.firstName || ''} ${item.owner.lastName || ''}` : '-',
        collectionDate: item.collectionDate,
      }))
      : [];
    return {
      items: newItems,
      pages: data?.partQuantities?.pages || 0,
      totalCount: data?.partQuantities?.totalCount || 0,
    };
  },
);
export const readyCollectedPartQuantitiesColumnsSelector = createSelector(
  adjustPartQtyOrderingSelector,
  initReadyCollectedPartQuantitiesSelector,
  partQtyStatusesValuesSelector,
  usersLookupListSelector,
  (ordering, data, statuses, users) => {
    const { collected, archived, picked } = statuses;
    return [
      { title: 'No', dataIndex: 'ordinalNumber' },
      { title: 'Source', dataIndex: 'source' },
      { title: 'Quantity', dataIndex: 'quantity' },
      { title: 'Cost price', dataIndex: 'costPrice' },
      {
        title: 'Added date',
        dataIndex: 'addedDate',
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.AddedDate, ordering),
      },
      {
        title: 'Status',
        dataIndex: 'status',
        filters: [
          { label: 'Ready', value: picked as number },
          { label: 'Collected', value: collected as number },
          { label: 'Archived', value: archived as number },
        ],
      },
      {
        title: 'Purchase order No',
        dataIndex: 'purchaseOrderNo',
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.PurchaseOrderNumber, ordering),
        filters: data?.availablePurchaseOrders
          ? data.availablePurchaseOrders.map((el) => ({
            label: el.purchaseOrderNumber || '(Blank)',
            value: el.purchaseOrderId || 'null',
          }))
          : [],
      },
      {
        title: 'Owner',
        dataIndex: 'owner',
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.Owner, ordering),
        filters: users.map((el) => ({ label: `${el.firstName} ${el.lastName}`, value: el.id })),
      },
      {
        title: 'Collection date',
        dataIndex: 'collectionDate',
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.CollectionDate, ordering),
      },
    ];
  },
);

export const billingShippingRequisitesSelector = (state: RootState) => state.shared.requisiteData;

export const sourceAvailableVehicleProfilesFiltersSelector = (state: RootState) => state.shared.sourceAvailableVehicleProfilesFilters;
export const targetAvailableVehicleProfilesFiltersSelector = (state: RootState) => state.shared.targetAvailableVehicleProfilesFilters;

const sourceAvailableVehicleProfilesInitSelector = (state: RootState) => state.shared.sourceAvailableVehicleProfiles;
export const sourceAvailableVehicleProfilesSelector = createSelector(sourceAvailableVehicleProfilesInitSelector, (data) => {
  return {
    ...data,
    items: data.items.map((el) => ({
      value: el.id,
      label: `${el.code} / ${el.modelDescription}`,
    })),
  };
});

const targetAvailableVehicleProfilesInitSelector = (state: RootState) => state.shared.targetAvailableVehicleProfiles;
export const targetAvailableVehicleProfilesSelector = createSelector(targetAvailableVehicleProfilesInitSelector, (data) => {
  return {
    ...data,
    items: data.items.map((el) => ({
      value: el.id,
      label: `${el.code} / ${el.modelDescription}`,
    })),
  };
});

const partRequestChangesInitSelector = (state: RootState) => state.shared.partRequestChanges;
export const partRequestChangesSelector = createSelector(
  partRequestChangesInitSelector,
  QuoteUnitsOfMeasureSelector,
  partRequestLineStatusesSelector,
  partRequestLineActivityTypesSelector,
  (data, measures, statuses, activityTypes) => {
    const getMeasureLabel = (unitValue: number | null) => measures.find(({ value }) => value === unitValue)?.label || '';

    const getOldQuantity = (oldQuantity: number | null, oldUnitOfMeasure: number | null) => {
      if (oldQuantity !== null) {
        return `${oldQuantity} ${getMeasureLabel(oldUnitOfMeasure)}`;
      }
      if (oldUnitOfMeasure) {
        return getMeasureLabel(oldUnitOfMeasure);
      }
      return null;
    };
    const getNewQuantity = (quantity: ValueDiffDto<number>, unitOfMeasure: ValueDiffDto<number>) => {
      if (unitOfMeasure.newValue) {
        const unitOfMeasureValue = getMeasureLabel(unitOfMeasure.newValue);
        return `${quantity.newValue || quantity.oldValue} ${unitOfMeasureValue}`;
      }
      if (quantity.newValue !== null) {
        const unitOfMeasureValue = getMeasureLabel(unitOfMeasure.newValue || unitOfMeasure.oldValue);
        return `${quantity.newValue} ${unitOfMeasureValue}`;
      }
      return null;
    };

    const getPrice = (isFree: boolean | null, price: number | null) => {
      if (isFree) return 'Free';
      return price !== null ? toPoundCurrency(price) : null;
    };

    const getNumber = (ordinalNumber: number | null, parentOrdinalNumber: number | null) => {
      if (!ordinalNumber) return null;
      if (!parentOrdinalNumber) return `${ordinalNumber}`;
      return `${parentOrdinalNumber}.${ordinalNumber}`;
    };

    return data.map((el) => {
      return {
        lineId: el.lineId,
        activityType: activityTypes.find(({ value }) => value === el.activityType)?.label || null,
        actionReason: el.actionReason,
        number: getNumber(el.ordinalNumber, el.parentOrdinalNumber),
        oldValue: {
          categoryName: el.categoryName.oldValue,
          productNumber: el.productNumber.oldValue,
          productName: el.productName.oldValue,
          notes: el.notes.oldValue,
          quantity: getOldQuantity(el.quantity.oldValue, el.unitOfMeasure.oldValue),
          unitPrice: getPrice(el.isFree.oldValue, el.unitPrice.oldValue),
          priceSources: el.priceSources.oldValue,
          discount: el.discount.oldValue ? `${el.discount.oldValue} %` : null,
          netPrice: getPrice(el.isFree.oldValue, el.netPrice.oldValue),
          tax: el.tax.oldValue ? `${el.tax.oldValue} %` : null,
          weight: el.weight.oldValue ? `${el.weight.oldValue} kg` : null,
        },
        newValue: {
          categoryName: el.categoryName.newValue,
          productNumber: el.productNumber.newValue,
          productName: el.productName.newValue,
          notes: el.notes.newValue,
          quantity: getNewQuantity(el.quantity, el.unitOfMeasure),
          unitPrice: getPrice(el.isFree.newValue, el.unitPrice.newValue),
          priceSources: el.priceSources.newValue,
          discount: el.discount.newValue ? `${el.discount.newValue} %` : null,
          netPrice: getPrice(el.isFree.newValue, el.netPrice.newValue),
          tax: el.tax.newValue ? `${el.tax.newValue} %` : null,
          weight: el.weight.newValue ? `${el.weight.newValue} kg` : null,
        },
        status: {
          oldValue: statuses.find(({ value }) => value === el.status.oldValue)?.label || null,
          newValue: statuses.find(({ value }) => value === el.status.newValue)?.label || null,
        },
      };
    });
  },
);
