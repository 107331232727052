import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Textarea from '../../../../core/components/textarea/Textarea';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { PartRequestLineDto } from '../../../../common/types/commonTypes';
import { patchPartReqLineNotesThunk } from '../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { isFetchingSelector, quoteLinePriceSourcesSelector } from '../../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../../core/utils/labelHandle';
import { partRequestLineStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';

interface LineDetailsModalProps {
  visible: boolean,
  onCancel: VoidFunctionType,
  currentLine: PartRequestLineDto | null | undefined,
}

const LineDetailsModal = ({ visible, onCancel, currentLine }: LineDetailsModalProps) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const priceSources = useAppSelector(quoteLinePriceSourcesSelector);
  const partRequestLineStatuses = useAppSelector(partRequestLineStatusesSelector);

  const areNotesEditable = useMemo(() => currentLine?.areNotesEditable, [currentLine?.areNotesEditable]);
  const getPriceSourcesLabels = useCallback((sources: number[]) => {
    const sourceLabelHandler = (source: number) => labelHandle(source, priceSources);
    return sources.map((item) => (sourceLabelHandler(item))).join(', ');
  }, [priceSources]);

  const properties = useMemo(() => [
    { label: 'Customer', value: currentLine?.customerName },
    { label: 'Part request No', value: currentLine?.partRequestNumber },
    { label: 'Linked to', value: currentLine?.linkedEntityNumber },
    { label: 'Linked to status', value: currentLine?.linkedEntityStatusString ? currentLine.linkedEntityStatusString : '-' },
    { label: 'Category', value: currentLine?.categoryName },
    { label: 'Product No', value: currentLine?.productNumber || '-' },
    { label: 'Product name', value: currentLine?.productName },
    { label: 'Source', value: currentLine?.priceSources ? getPriceSourcesLabels(currentLine.priceSources) : '-' },
    { label: 'Qty', value: currentLine?.confirmedQuantity },
    { label: 'Ready', value: currentLine?.readyQuantity },
    { label: 'Collected', value: currentLine?.collectedQuantity },
    { label: 'Status', value: labelHandle(currentLine?.status, partRequestLineStatuses) },
  ], [
    currentLine?.categoryName, currentLine?.collectedQuantity,
    currentLine?.customerName, currentLine?.linkedEntityNumber,
    currentLine?.partRequestNumber, currentLine?.linkedEntityStatusString,
    currentLine?.priceSources, currentLine?.productName,
    currentLine?.productNumber, currentLine?.confirmedQuantity,
    currentLine?.readyQuantity, currentLine?.status,
    getPriceSourcesLabels, partRequestLineStatuses,
  ]);

  const [notesValue, setNotesValue] = useState<string>('');
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (visible && currentLine) {
      const lineNotes = currentLine.notes;
      if (lineNotes && !notesValue) {
        setNotesValue(lineNotes);
      }
    }
  }, [visible, currentLine, notesValue]);

  const onChangeHandle = useCallback((v: string) => {
    if (v.trim().length > 250) {
      setError(maxLengthMessageHandle(250));
    } else setError('');
    setNotesValue(v);
  }, []);

  const cancelHandle = useCallback(() => {
    onCancel();
    setError('');
    setNotesValue('');
  }, [onCancel]);

  const saveHandle = useCallback(() => {
    if (currentLine) {
      if (notesValue !== currentLine.notes) {
        dispatch(patchPartReqLineNotesThunk({
          lineId: currentLine?.id,
          partRequestId: currentLine?.partRequestId,
          notes: notesValue.trim(),
          closeModal: cancelHandle,
        }));
      } else cancelHandle();
    }
    // eslint-disable-next-line
  }, [cancelHandle, currentLine, notesValue]);
  return (
    <Modal visible={visible} onCancel={cancelHandle} title="Details" width={760} className="viewDetails">
      <ul className="viewDetails__content">
        {properties.map((el) => <li key={el.label} className="viewDetails__item">
          <span className="viewDetails__label">{el.label}</span>
          <span className="viewDetails__value">{el.value}</span>
        </li>)}
      </ul>
      <Textarea
        value={notesValue}
        onChange={(e) => {
          const v = e.target.value;
          onChangeHandle(v);
        }}
        error={error}
        label="Notes"
        disabled={!areNotesEditable}
      />
      <ButtonActions
        createLabel="Save"
        createClick={saveHandle}
        cancelClick={cancelHandle}
        isLoading={isFetching}
        offsetTop
      />
    </Modal>
  );
};

export default LineDetailsModal;
