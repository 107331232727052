import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  AccountUserRolesUiNames,
  AttachmentTypesUiNames,
  BusinessAreaUiNamesEnum,
  CustomerAccountTypeEnum,
  DayPartsEnum,
  EnquirySourceUiNamesEnum,
  JobCategoriesUiNames,
  JobTypeUiNamesEnum,
  LeaveRequestTypesEnum,
  LeaveRequestTypesShortEnum,
  LinksTypesUiNames,
  NominalRecordVatTypesEnum,
  PurchaseOrderPartAmountInputTypesEnum,
  QuoteInfoSourcesUiNames,
  QuoteLinePriceSourceUiNames,
  QuoteLineTaxUiNames,
  QuoteUnitsOfMeasureUiNames,
  ShortBusinessUiNames,
  VehicleFlowsEnum,
  VehicleInfoSourcesUiNames,
  VehicleProfileTypesEnum,
  VehicleSlidingDoorsQuantitiesUiNames,
  WarningsUiNamesEnum,
} from '../../core/enums/dictionariesEnums';
import { setUiNamesCustomHandler, setUiNamesHandler } from '../../core/utils/setUiNamesHandler';
import { OrderEventSource, PartRequestLinePriceSources } from '../../orders/orders-view-page/enums/orderViewPageEnums';
import { RectificationFaultTypesNameEnum } from '../../rectifications/dashboard/enums/rectificationEnums';
import {
  FullVehicleCheckDecisionsEnum,
  HandoverVehicleCheckDecisionsEnum,
  VehicleCheckTypesEnum,
  WeatherConditionsEnum,
} from '../../vehicles/vehicle-view-page/components/vehicle-content/vehicle-inspection/enums/VehicleInspectionEnum';
import { OrderWarningsUiEnum } from '../../orders/dashboard/enums/ordersEnums';
import { DictionaryItem } from '../../core/types/coreTypes';
import { NominalCodeTypes } from '../../invoicing/nominal-codes/enums/nominalCodesEnums';
import { InAppNotificationTypesEnum } from '../../account/enums/inAppNotificationTypesEnum';
import { PurchaseOrderEventSourceEnum } from '../../stock/purchase-orders/view-page/enums/PurchaseOrderViewEnum';
import {
  InvoiceDocumentOperationTypesEnum,
  InvoiceDocumentTypes,
  NominalRecordStates,
} from '../../invoicing/container/enums/InvoicingSharedEnums';
import { SuitableVehicleSources } from '../../stock/common/components/select-vehicles-modal/enums/SuitableVehicleSources';
import { PartRequestLineActivityTypeEnum } from '../../common/components/activity-events/part-request-lines-creation-event/eventDataUtil';

export const currentServerErrorSelector = (state: RootState) => state.core.currentServerError;
export const authTokenSelector = (state: RootState) => state.core.authToken;
export const isAuthSelector = (state: RootState) => state.core.isAuth;
export const isGlobalLoadingSelector = (state: RootState) => state.core.isLoading;
export const isFetchingSelector = (state: RootState) => state.core.isFetching;
export const isDashboardLoadingSelector = (state: RootState) => state.core.isDashboardLoading;
export const collapsedSidebarSelector = (state: RootState) => state.core.collapsedSidebar;
export const currentBusinessAreaSelector = (state: RootState) => state.core.currentBusinessArea;

const customerAccountTypesInitSelector = (state: RootState) => state.core.customerAccountTypes;
export const customerAccountTypesSelector = createSelector(customerAccountTypesInitSelector, (accountTypes) => {
  return setUiNamesHandler(accountTypes, CustomerAccountTypeEnum);
});

export const customerAccountTypesValuesSelector = createSelector(customerAccountTypesSelector, (accountTypes) => {
  return {
    nonCredit: accountTypes.find((el) => el.label === CustomerAccountTypeEnum.NonCredit)?.value,
    credit: accountTypes.find((el) => el.label === CustomerAccountTypeEnum.Credit)?.value,
    other: accountTypes.find((el) => el.label === CustomerAccountTypeEnum.Other)?.value,
  };
});

export const customerNonCreditAccountTypeSelector = createSelector(customerAccountTypesSelector, (accountTypes) => {
  return accountTypes.find((el) => el.label === CustomerAccountTypeEnum.NonCredit)?.value;
});

export const customerTypesSelector = (state: RootState) => state.core.customerTypes;

const jobTypesInitSelector = (state: RootState) => state.core.jobTypes;
export const jobTypesSelector = createSelector(jobTypesInitSelector, (types) => {
  return setUiNamesCustomHandler(types, JobTypeUiNamesEnum);
});

export const jobTypesValuesSelector = createSelector(jobTypesSelector, (data) => {
  return {
    onSite: data.find((el) => el.label === JobTypeUiNamesEnum.OnSite)?.value,
    offSite: data.find((el) => el.label === JobTypeUiNamesEnum.OffSite)?.value,
    subcontractorOnSite: data.find((el) => el.label === JobTypeUiNamesEnum.SubcontractorOnSite)?.value,
    subcontractorOffSite: data.find((el) => el.label === JobTypeUiNamesEnum.SubcontractorOffSite)?.value,
    supplyOnlyCollection: data.find((el) => el.label === JobTypeUiNamesEnum.SupplyOnlyCollection)?.value,
    supplyOnlyDirectShipment: data.find((el) => el.label === JobTypeUiNamesEnum.SupplyOnlyDirectShipment)?.value,
    supplyOnlyOffSite: data.find((el) => el.label === JobTypeUiNamesEnum.SupplyOnlyOffSite)?.value,
  };
});

export const supplyOnlyJobTypesSelector = createSelector(
  jobTypesValuesSelector,
  ({ supplyOnlyCollection, supplyOnlyOffSite, supplyOnlyDirectShipment }) => [
    supplyOnlyCollection, supplyOnlyOffSite, supplyOnlyDirectShipment,
  ],
);

export const allowedJobTypesForUserSelector = createSelector(jobTypesInitSelector, (types) => {
  const filtered = types.filter((el) => el.isAllowed);
  return setUiNamesCustomHandler(filtered, JobTypeUiNamesEnum);
});

export const jobTypesWithoutSupplyOnlySelector = createSelector(
  allowedJobTypesForUserSelector,
  supplyOnlyJobTypesSelector,
  (data, supplyOnlyJobTypes) => {
    return data.filter((el) => !supplyOnlyJobTypes.includes(el.value));
  },
);

export const priorityLevelsSelector = (state: RootState) => state.core.priorityLevels;

const enquirySourcesInitSelector = (state: RootState) => state.core.enquirySources;
export const enquirySourcesSelector = createSelector(enquirySourcesInitSelector, (sources) => {
  return setUiNamesHandler(sources, EnquirySourceUiNamesEnum);
});

const enquiryWarningsInitSelector = (state: RootState) => state.core.enquiryWarnings;
export const enquiryWarningsSelector = createSelector(enquiryWarningsInitSelector, (warnings) => {
  return setUiNamesHandler(warnings, WarningsUiNamesEnum);
});

const orderWarningsInitSelector = (state: RootState) => state.core.orderWarnings;
export const orderWarningsSelector = createSelector(orderWarningsInitSelector, (warnings) => {
  return setUiNamesHandler(warnings, OrderWarningsUiEnum);
});

const rectificationWarningsInitSelector = (state: RootState) => state.core.rectificationWarnings;
export const rectificationWarningsSelector = createSelector(rectificationWarningsInitSelector, (warnings) => {
  return setUiNamesHandler(warnings, WarningsUiNamesEnum);
});

const jobWarningsInitSelector = (state: RootState) => state.core.jobWarnings;
export const jobWarningsSelector = createSelector(jobWarningsInitSelector, (warnings) => {
  return setUiNamesHandler(warnings, WarningsUiNamesEnum);
});

const businessAreasInitSelector = (state: RootState) => state.core.businessAreas;
export const businessAreasSelector = createSelector(businessAreasInitSelector, (list) => {
  return setUiNamesCustomHandler(list, BusinessAreaUiNamesEnum);
});
export const shortBusinessAreasSelector = createSelector(businessAreasInitSelector, (list) => {
  return setUiNamesCustomHandler(list, ShortBusinessUiNames);
});
export const shortBusinessAreasWithoutVLRSelector = createSelector(shortBusinessAreasSelector, (list) => {
  const businessAreaVLR = list.find((el) => el.label === ShortBusinessUiNames.Rhino)?.value;
  return businessAreaVLR ? list.filter((el) => el.value !== businessAreaVLR) : list;
});
export const allowedShortBaForUserSelector = createSelector(businessAreasInitSelector, (list) => {
  const filtered = list.filter((el) => el.isAllowed);
  return setUiNamesCustomHandler(filtered, ShortBusinessUiNames);
});
export const allowedBaForUserSelector = createSelector(businessAreasInitSelector, (list) => {
  const filtered = list.filter((el) => el.isAllowed);
  return setUiNamesCustomHandler(filtered, BusinessAreaUiNamesEnum);
});

const quoteLineTaxesInitSelector = (state: RootState) => state.core.quoteLineTaxes;
export const quoteLineTaxesSelector = createSelector(quoteLineTaxesInitSelector, (taxes) => {
  return setUiNamesHandler(taxes, QuoteLineTaxUiNames);
});

export const twentyPercentTaxSelector = createSelector(quoteLineTaxesSelector, (taxes) => {
  return taxes.find((tax) => tax.label === QuoteLineTaxUiNames.TwentyPercent)?.value;
});

export const accountAccountTypesSelector = (state: RootState) => state.core.accountAccountTypes;

const accountAccountRolesInitSelector = (state: RootState) => state.core.accountAccountRoles;
export const accountAccountRolesSelector = createSelector(accountAccountRolesInitSelector, (accountRoles) => {
  return setUiNamesHandler(accountRoles, AccountUserRolesUiNames);
});
export const internalAccountSelector = createSelector(accountAccountTypesSelector, (data) => {
  return data.find((el) => el.label === 'Internal')?.value;
});

export const adminRoleSelector = createSelector(accountAccountRolesSelector, (roles) => {
  return roles.find((el) => el.label === AccountUserRolesUiNames.Admin)?.value;
});

const permissionAccountRolesSelector = (state: RootState) => state.core.permissionRoles;
export const permissionUiRolesSelector = createSelector(permissionAccountRolesSelector, (items) => {
  return items.map((item) => ({ label: item.fullName, value: `${item.role}-${item.roleLevel}` }));
});

export const uniqueAccountRolesWithoutLevels = createSelector(permissionAccountRolesSelector, (roles) => {
  const list: DictionaryItem[] = [];
  roles.forEach((role) => {
    if (!list.find((el) => el.value === role.role)) {
      const nameWithoutLevel = role.fullName ? role.fullName.split(' (')[0] : '';
      list.push({ label: nameWithoutLevel, value: role.role });
    }
  });
  return list;
});

export const pricelistTypesSelector = (state: RootState) => state.core.pricelistTypes;
export const generalPricelistTypeSelector = createSelector(pricelistTypesSelector, (items) => {
  return items.find((el) => el.label === 'General');
});

export const customerPricelistTypeSelector = createSelector(pricelistTypesSelector, (items) => {
  return items.find((el) => el.label === 'Customer');
});

const QuoteInfoSourcesInitSelector = (state: RootState) => {
  return setUiNamesHandler(state.core.quoteInfoSources, QuoteInfoSourcesUiNames);
};
export const QuoteInfoSourcesSelector = createSelector(QuoteInfoSourcesInitSelector, (infoSources) => {
  return setUiNamesHandler(infoSources, QuoteInfoSourcesUiNames);
});

export const coreInfoSourcesSelector = createSelector(QuoteInfoSourcesSelector, (sources) => {
  return sources?.find((el) => el.label === QuoteInfoSourcesUiNames.CorePart);
});

export const nonCoreInfoSourcesSelector = createSelector(QuoteInfoSourcesSelector, (sources) => {
  return sources?.find((el) => el.label === QuoteInfoSourcesUiNames.NonCorePart);
});

const quoteUnitsOfMeasureSourceInitSelector = (state: RootState) => state.core.quoteUnitsOfMeasure;
export const QuoteUnitsOfMeasureSelector = createSelector(quoteUnitsOfMeasureSourceInitSelector, (items) => {
  return setUiNamesHandler(items, QuoteUnitsOfMeasureUiNames);
});

export const pcsMeasureSelector = createSelector(QuoteUnitsOfMeasureSelector, (items) => {
  return items.find((el) => el.label === QuoteUnitsOfMeasureUiNames.Pcs)?.value;
});
export const gMeasureSelector = createSelector(QuoteUnitsOfMeasureSelector, (items) => {
  return items.find((el) => el.label === QuoteUnitsOfMeasureUiNames.G)?.value;
});
export const kgMeasureSelector = createSelector(QuoteUnitsOfMeasureSelector, (items) => {
  return items.find((el) => el.label === QuoteUnitsOfMeasureUiNames.Kg)?.value;
});
export const hourMeasureSelector = createSelector(QuoteUnitsOfMeasureSelector, (items) => {
  return items.find((el) => el.label === QuoteUnitsOfMeasureUiNames.Hour)?.value;
});
export const minuteMeasureSelector = createSelector(QuoteUnitsOfMeasureSelector, (items) => {
  return items.find((el) => el.label === QuoteUnitsOfMeasureUiNames.Minute)?.value;
});
export const weightlessMeasureValuesSelector = createSelector(
  gMeasureSelector,
  kgMeasureSelector,
  hourMeasureSelector,
  minuteMeasureSelector,
  (...measures) => measures.filter(Boolean),
);
export const timeMeasureValuesSelector = createSelector(
  minuteMeasureSelector,
  hourMeasureSelector,
  (...measures) => measures.filter(Boolean),
);
export const weightMeasureValuesSelector = createSelector(
  gMeasureSelector,
  kgMeasureSelector,
  (...measures) => measures.filter(Boolean),
);

export const integerQuantityMeasureValuesSelector = createSelector(
  pcsMeasureSelector,
  gMeasureSelector,
  (...measures) => measures.filter(Boolean),
);

export const locationHistorySelector = (state: RootState) => state.core.locationHistory;

const jobCategoriesInitSelector = (state: RootState) => state.core.jobCategories;
export const jobCategoriesSelector = createSelector(jobCategoriesInitSelector, (list) => {
  return setUiNamesHandler(list, JobCategoriesUiNames);
});

export const usersLookupListSelector = (state: RootState) => state.core.usersLookup;
export const usersFilterLookupSelector = createSelector(usersLookupListSelector, (data) => {
  return data.map((item) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.id,
  }));
});

const usersListHadAJobSelector = (state: RootState) => state.core.usersListHadAJob;
export const usersListHadAJobLookupSelector = createSelector(usersListHadAJobSelector, (data) => {
  return data.map((item) => ({
    label: `${item.firstName} ${item.lastName}`,
    value: item.id,
  }));
});

export const usersMentionsSelector = createSelector(usersLookupListSelector, (data) => {
  return data.map((item) => ({
    display: `${item.firstName} ${item.lastName}`,
    id: item.id,
  }));
});

const attachmentsTypesInitSelector = (state: RootState) => state.core.attachmentsTypes;
export const attachmentsTypesSelector = createSelector(attachmentsTypesInitSelector, (list) => {
  return setUiNamesHandler(list, AttachmentTypesUiNames);
});

export const fileAttachmentTypeSelector = createSelector(attachmentsTypesSelector, (types) => {
  return types.find((t) => t.label === AttachmentTypesUiNames.File)?.value;
});

export const imageAttachmentTypeSelector = createSelector(attachmentsTypesSelector, (types) => {
  return types.find((t) => t.label === AttachmentTypesUiNames.Image)?.value;
});

const linksTypesInitSelector = (state: RootState) => state.core.linksTypes;
export const linksTypesSelector = createSelector(linksTypesInitSelector, (list) => {
  return setUiNamesHandler(list, LinksTypesUiNames);
});

export const linkTypesValuesSelector = createSelector(linksTypesSelector, (types) => {
  return {
    rectificationLinkType: types.find((t) => t.label === LinksTypesUiNames.Rectification)?.value,
    enquiryLinkType: types.find((t) => t.label === LinksTypesUiNames.Enquiry)?.value,
    jobLinkType: types.find((t) => t.label === LinksTypesUiNames.Job)?.value,
    orderLinkType: types.find((t) => t.label === LinksTypesUiNames.Order)?.value,
    vehicleLinkType: types.find((t) => t.label === LinksTypesUiNames.Vehicle)?.value,
  };
});

export const partRequestLinePriceSourcesSelector = (state: RootState) => {
  return setUiNamesHandler(state.core.partRequestLinePriceSources, PartRequestLinePriceSources);
};
export const inAppNotificationsEntityTypesSelector = (state: RootState) => state.core.inAppNotificationsEntityTypes;
const inAppNotificationsTypesSelector = (state: RootState) => state.core.inAppNotificationsTypes;
export const inAppNotificationTypeValuesSelector = createSelector(inAppNotificationsTypesSelector, (types) => {
  return {
    assigneeChange: types.find((el) => el.label === InAppNotificationTypesEnum.AssigneeChange)?.value,
    missingSellingPrices: types.find((el) => el.label === InAppNotificationTypesEnum.MissingSellingPrices)?.value,
  };
});
const rectificationInitFaultTypesSelector = (state: RootState) => state.core.rectificationFaultTypes;
export const rectificationFaultTypesSelector = createSelector(rectificationInitFaultTypesSelector, (faultTypes) => {
  return setUiNamesHandler(faultTypes, RectificationFaultTypesNameEnum);
});

const vehicleProfilesTypesSelector = (state: RootState) => state.core.vehicleProfilesTypes;
export const vehicleProfilesTypesUiSelector = createSelector(vehicleProfilesTypesSelector, (items) => {
  return setUiNamesHandler(items, VehicleProfileTypesEnum);
});
const vehicleFlowsSelector = (state: RootState) => state.core.vehicleFlows;
export const vehicleFlowsUiSelector = createSelector(vehicleFlowsSelector, (items) => {
  return setUiNamesHandler(items, VehicleFlowsEnum);
});

export const vehicleFlowValuesSelector = createSelector(vehicleFlowsUiSelector, (items) => {
  return {
    onSite: items.find((el) => el.label === VehicleFlowsEnum.OnSite)?.value,
    offSite: items.find((el) => el.label === VehicleFlowsEnum.OffSite)?.value,
  };
});

const vehicleCheckTypesInitSelector = (state: RootState) => state.core.vehicleCheckTypes;
export const vehicleCheckTypesSelector = createSelector(vehicleCheckTypesInitSelector, (types) => {
  return setUiNamesHandler(types, VehicleCheckTypesEnum);
});
export const vehicleCheckTypesValuesSelector = createSelector(vehicleCheckTypesSelector, (types) => {
  return {
    full: types.find((el) => el.label === VehicleCheckTypesEnum.Full)?.value,
    weight: types.find((el) => el.label === VehicleCheckTypesEnum.Weight)?.value,
    handover: types.find((el) => el.label === VehicleCheckTypesEnum.Handover)?.value,
  };
});

const fullVehicleCheckDecisionsInitSelector = (state: RootState) => state.core.fullVehicleCheckDecisions;
export const fullVehicleCheckDecisionsSelector = createSelector(fullVehicleCheckDecisionsInitSelector, (types) => {
  return setUiNamesHandler(types, FullVehicleCheckDecisionsEnum);
});
const handoverVehicleCheckDecisionsInitSelector = (state: RootState) => state.core.handoverVehicleCheckDecisions;
export const handoverVehicleCheckDecisionsSelector = createSelector(handoverVehicleCheckDecisionsInitSelector, (types) => {
  return setUiNamesHandler(types, HandoverVehicleCheckDecisionsEnum);
});

export const vehicleTransportationMethodsSelector = (state: RootState) => state.core.vehicleTransportationMethods;
const weatherConditionsInitSelector = (state: RootState) => state.core.weatherConditions;
export const weatherConditionsSelector = createSelector(weatherConditionsInitSelector, (list) => {
  return setUiNamesHandler(list, WeatherConditionsEnum);
});

const leaveRequestTypesInitSelector = (state: RootState) => state.core.leaveRequestTypes;
export const leaveRequestTypesUiSelector = createSelector(leaveRequestTypesInitSelector, (types) => {
  return setUiNamesHandler(types, LeaveRequestTypesEnum);
});
export const leaveRequestTypesUiShortSelector = createSelector(leaveRequestTypesInitSelector, (types) => {
  return setUiNamesHandler(types, LeaveRequestTypesShortEnum);
});

const dayPartsInitSelector = (state: RootState) => state.core.dayParts;
export const dayPartsUiSelector = createSelector(dayPartsInitSelector, (parts) => {
  return (setUiNamesHandler(parts, DayPartsEnum));
});
export const dayPartAllDaySelector = createSelector(dayPartsUiSelector, (parts) => {
  return parts.find((el) => el.label === DayPartsEnum.AllDay)?.value;
});

const nominalCodeTypesInitSelector = (state: RootState) => state.core.nominalCodeTypes;
export const nominalCodeTypesSelector = createSelector(nominalCodeTypesInitSelector, (types) => {
  return setUiNamesHandler(types, NominalCodeTypes);
});

export const nominalCodeTypesValuesSelector = createSelector(nominalCodeTypesSelector, (types) => {
  return {
    saleType: types.find((el) => el.label === 'Sale')?.value,
    purchaseType: types.find((el) => el.label === 'Purchase')?.value,
  };
});

export const unfinishedEntityTypeSelector = (state: RootState) => state.core.unfinishedEntityType;

export const fitterReturnsEntityTypeSelector = (state: RootState) => state.core.fitterReturnsEntityType;

export const salesByCustomerEntityTypesSelector = (state: RootState) => state.core.salesByCustomerEntityTypes;

export const salesOffsiteEntityTypesSelector = (state: RootState) => state.core.salesOffsiteEntityTypes;

export const nominalRecordStatesSelector = (state: RootState) => state.core.nominalRecordStates;
export const nominalRecordStatesValuesSelector = createSelector(nominalRecordStatesSelector, (states) => {
  return {
    pending: states.find((el) => el.label === NominalRecordStates.Pending)?.value,
    draft: states.find((el) => el.label === NominalRecordStates.Draft)?.value,
    exported: states.find((el) => el.label === NominalRecordStates.Exported)?.value,
  };
});
export const nominalRecordTypesSelector = (state: RootState) => state.core.nominalRecordTypes;
export const nominalRecordTypesValuesSelector = createSelector(nominalRecordTypesSelector, (recordTypes) => {
  return {
    invoice: recordTypes.find((el) => el.label === 'Invoice')?.value,
    credit: recordTypes.find((el) => el.label === 'Credit')?.value,
  };
});
const purchaseOrderEventSourcesSelector = (state: RootState) => state.core.purchaseOrderEventSources;
export const purchaseOrderEventSourcesValuesSelector = createSelector(purchaseOrderEventSourcesSelector, (data) => {
  const stockSource = data.find((el) => el.label === PurchaseOrderEventSourceEnum.Stock)?.value;
  const invoicingSource = data.find((el) => el.label === PurchaseOrderEventSourceEnum.Invoicing)?.value;

  return { stockSource, invoicingSource };
});

const invoiceDocumentTypesInitSelector = (state: RootState) => state.core.invoiceDocumentTypes;
export const invoiceDocumentTypesSelector = createSelector(invoiceDocumentTypesInitSelector, (types) => {
  return setUiNamesHandler(types, InvoiceDocumentTypes);
});
export const invoiceDocumentTypeValuesSelector = createSelector(invoiceDocumentTypesSelector, (types) => {
  return {
    invoice: types.find(({ label }) => label === InvoiceDocumentTypes.Invoice)?.value,
    creditNote: types.find(({ label }) => label === InvoiceDocumentTypes.CreditNote)?.value,
  };
});

const invoiceDocumentOperationTypesInitSelector = (state: RootState) => state.core.invoiceDocumentOperationTypes;
export const invoiceDocumentOperationTypesValuesSelector = createSelector(invoiceDocumentOperationTypesInitSelector, (types) => {
  return {
    sale: types.find(({ label }) => label === InvoiceDocumentOperationTypesEnum.Sale)?.value,
    purchase: types.find(({ label }) => label === InvoiceDocumentOperationTypesEnum.Purchase)?.value,
  };
});
const vehicleSlidingDoorsQuantitiesInitSelector = (state: RootState) => state.core.vehicleSlidingDoorsQuantities;
export const vehicleSlidingDoorsQuantitiesSelector = createSelector(vehicleSlidingDoorsQuantitiesInitSelector, (doors) => {
  return setUiNamesHandler(doors, VehicleSlidingDoorsQuantitiesUiNames);
});

const vehicleInfoSourcesInitSelector = (state: RootState) => state.core.vehicleInfoSources;
export const vehicleInfoSourcesSelector = createSelector(vehicleInfoSourcesInitSelector, (infoSources) => {
  return setUiNamesHandler(infoSources, VehicleInfoSourcesUiNames);
});
export const vehicleCatalogSourceSelector = createSelector(vehicleInfoSourcesSelector, (items) => {
  return items.find((el) => el.label === VehicleInfoSourcesUiNames.Catalog);
});

export const vehicleManualInputSelector = createSelector(vehicleInfoSourcesSelector, (items) => {
  return items.find((el) => el.label === VehicleInfoSourcesUiNames.ManualInput);
});

const quoteLinePriceSourcesInitSelector = (state: RootState) => state.core.quoteLinePriceSources;
export const quoteLinePriceSourcesSelector = createSelector(quoteLinePriceSourcesInitSelector, (priceSources) => {
  return setUiNamesHandler(priceSources, QuoteLinePriceSourceUiNames);
});
export const pricelistPriceSourcesSelector = createSelector(quoteLinePriceSourcesInitSelector, (priceSources) => {
  return priceSources?.find((el) => el.label === QuoteLinePriceSourceUiNames.Pricelist)?.value;
});
export const unknownPriceSourceSelector = createSelector(quoteLinePriceSourcesSelector, (sources) => {
  return sources.find((el) => el.label === QuoteLinePriceSourceUiNames.Unknown);
});

export const rectificationTypesSelector = (state: RootState) => state.core.rectificationTypes;

export const orderEventSourcesSelector = (state: RootState) => state.core.orderEventSources;
export const orderEventSourcesValuesSelector = createSelector(orderEventSourcesSelector, (data) => {
  const orderSource = data.find((el) => el.label === OrderEventSource.Order)?.value;
  const invoicingSource = data.find((el) => el.label === OrderEventSource.Invoicing)?.value;

  return { orderSource, invoicingSource };
});

const suitableVehicleSourcesInitSelector = (state: RootState) => state.core.suitableVehicleSources;
export const suitableVehicleSourcesSelector = createSelector(suitableVehicleSourcesInitSelector, (sources) => {
  return setUiNamesHandler(sources, SuitableVehicleSources);
});
export const suitableVehicleSourcesValuesSelector = createSelector(suitableVehicleSourcesSelector, (sources) => ({
  byCode: sources.find((s) => s.label === SuitableVehicleSources.VehiclesByCode)?.value || null,
  byModel: sources.find((s) => s.label === SuitableVehicleSources.VehiclesByModel)?.value || null,
  groups: sources.find((s) => s.label === SuitableVehicleSources.VehicleGroups)?.value || null,
}));

export const vanlinersLocationSelector = (state: RootState) => state.core.vanlinersLocation;

export const partRequestLineActivityTypesInitSelector = (state: RootState) => state.core.partRequestLineActivityTypes;
export const partRequestLineActivityTypesSelector = createSelector(partRequestLineActivityTypesInitSelector, (types) => {
  return setUiNamesHandler(types, PartRequestLineActivityTypeEnum);
});

export const cancelledLinesReportEntityTypesSelector = (state: RootState) => state.core.cancelledLinesReportEntityTypes;

const nominalRecordVatTypesInitSelector = (state: RootState) => state.core.nominalRecordVatTypes;
export const nominalRecordVatTypesSelector = createSelector(nominalRecordVatTypesInitSelector, (vatTypes) => {
  return setUiNamesHandler(vatTypes, NominalRecordVatTypesEnum);
});
export const vatTypesValuesSelector = createSelector(nominalRecordVatTypesSelector, (vatTypes) => ({
  percent: vatTypes.find((type) => type.label === NominalRecordVatTypesEnum.Percentage)?.value,
  amount: vatTypes.find((type) => type.label === NominalRecordVatTypesEnum.Amount)?.value,
}));

export const purchaseOrderPartAmountInputTypesSelector = (state: RootState) => state.core.purchaseOrderPartAmountInputTypes;
export const purchaseOrderPartAmountInputTypesValuesSelector = createSelector(purchaseOrderPartAmountInputTypesSelector, (types) => ({
  positive: types.find((type) => type.label === PurchaseOrderPartAmountInputTypesEnum.Positive)?.value,
  negative: types.find((type) => type.label === PurchaseOrderPartAmountInputTypesEnum.Negative)?.value,
}));

export const googleApiKeySelector = (state: RootState) => state.core.googleApiKey;
