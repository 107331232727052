import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import classNames from 'classnames';
import './EmptyContentList.scss';

type EmptyContentListProps = {
  text?: string;
  icon?: IconProp;
  Image?: React.FC<React.SVGProps<SVGSVGElement>>, // use it if there is no needed font-awesome icons
  className?: string;
  size?: 'sm',
  offsetTop?: boolean,
}

const EmptyContentList: React.FC<EmptyContentListProps> = ({
  icon, Image, text, className, size, offsetTop = true,
}) => {
  return (
    <div className={classNames(className, 'empty-content', {
      'empty-content--sm': size === 'sm',
      'empty-content--no-offset-top': !offsetTop,
    })}
    >
      {icon && <FontAwesomeIcon icon={icon} className="empty-content__icon" />}
      {Image && <Image className="empty-content__icon" />}
      <span className="empty-content__text">{text}</span>
    </div>
  );
};

export default EmptyContentList;
