import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { dayPartsUiSelector, leaveRequestTypesUiSelector } from '../../../store/selectors/coreSelectors';
import { getDayPartsThunk, getLeaveRequestTypesThunk } from '../../../store/thunks/coreThunk';
import { leaveRequestStatusesUiSelector } from '../../../store/selectors/coreStatusesSelectors';
import { getLeaveRequestStatusesThunk } from '../../../store/thunks/core/coreStatusesThunks';
import { employeeEventTypesSelector } from '../../../store/selectors/coreEventTypesSelectors';
import { getEmployeeEventTypesThunk } from '../../../store/thunks/core/coreEventTypesThunks';

export const useEmployeeActivityDictionaries = () => {
  const dispatch = useAppDispatch();
  const employeeEventTypes = useAppSelector(employeeEventTypesSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);
  const leaveRequestTypes = useAppSelector(leaveRequestTypesUiSelector);
  const leaveRequestStatuses = useAppSelector(leaveRequestStatusesUiSelector);

  useEffect(() => {
    if (!employeeEventTypes.length) {
      dispatch(getEmployeeEventTypesThunk());
    }
    if (!dayParts.length) {
      dispatch(getDayPartsThunk());
    }
    if (!leaveRequestTypes.length) {
      dispatch(getLeaveRequestTypesThunk());
    }
    if (!leaveRequestStatuses.length) {
      dispatch(getLeaveRequestStatusesThunk());
    }
    // eslint-disable-next-line
  }, []);
};
