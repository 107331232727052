import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, unfinishedEntitiesDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import ViewPageLink from '../../../../../../common/components/view-page-link/ViewPageLink';
import { UnfinishedEntitiesTableLine } from '../../../../../types/UnfinishedEntitiesReportTypes';
import { EntityType } from '../../../../../../common/types/commonTypes';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';

const UnfinishedEntitiesBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { items, totalCount } = useAppSelector(unfinishedEntitiesDataSelector);

  const hiddenKeys = ['customerId', 'entityId'];
  const nowrapKeys = ['number', 'salesNet', 'creationDate'];

  const renderLink = (entity: EntityType, name: string, id: number | null) => (
    <ViewPageLink entityType={entity} entityId={id}>
      <TruncateTooltip value={name} table />
    </ViewPageLink>
  );

  const renderCellValue = (key: string, value: string | number | null, el: UnfinishedEntitiesTableLine) => {
    if (nowrapKeys.includes(key)) return value;
    if (key === 'customerName') return renderLink('Customer', `${value}`, el.customerId);
    if (key === 'entityNumber') return renderLink(el.entityType as EntityType, `${value}`, el.entityId);
    return <TruncateTooltip value={value} table />;
  };

  return (
    <Tbody>
      {totalCount > 0 ? items.map((el) => (
        <Tr key={el.number}>
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
              {renderCellValue(key, value, el)}
            </TdNew>;
          })}
        </Tr>
      ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength} />}
    </Tbody>
  );
};

export default UnfinishedEntitiesBody;
