import React from 'react';
import './PartsKitsSkeletonItem.scss';

const PartsKitsSkeletonItem: React.FC = () => {
  return (
    <div className="partsKitsSkeletonItem skeleton-item">
      <div className="partsKitsSkeletonItem__info">
        <div className="partsKitsSkeletonItem__img" />
        <div className="partsKitsSkeletonItem__text" />
      </div>
      <div className="partsKitsSkeletonItem__tag skeleton-line" />
      <div className="partsKitsSkeletonItem__list">
        <div className="partsKitsSkeletonItem__list-item" />
        <div className="partsKitsSkeletonItem__list-item" />
        <div className="partsKitsSkeletonItem__list-item" />
      </div>
    </div>
  );
};

export default PartsKitsSkeletonItem;
