import { useState } from 'react';
import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import moment from 'moment/moment';
import { useAppDispatch } from '../../store/hooks';
import {
  DateRangeTableFilterType, LinksFiltersType, PickerValues, SearchTableFilterType,
} from '../../core/types/coreTypes';
import { DEFAULT_DATE_FORMAT } from '../../core/utils/regex';

export const useLinks = (filters: LinksFiltersType, actionCreator: ActionCreatorWithPayload<LinksFiltersType>) => {
  const dispatch = useAppDispatch();
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({ entity: filters.entity || '' });
  const createdDateFilter = filters.createdDate;
  const modifiedDateFilter = filters.modifiedDate;

  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    createdDate: [
      createdDateFilter?.min ? moment(createdDateFilter.min, DEFAULT_DATE_FORMAT) : null,
      createdDateFilter?.max ? moment(createdDateFilter.max, DEFAULT_DATE_FORMAT) : null,
    ],
    modifiedDate: [
      modifiedDateFilter?.min ? moment(modifiedDateFilter.min, DEFAULT_DATE_FORMAT) : null,
      modifiedDateFilter?.max ? moment(modifiedDateFilter.max, DEFAULT_DATE_FORMAT) : null,
    ],
  });

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(actionCreator({
        ...filters,
        [field]: '',
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };

  const searchPressHandle = (field: string) => {
    dispatch(actionCreator({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values || undefined,
    };
    dispatch(actionCreator(newFilters));
  };

  return {
    onChangeSearchHandle,
    searchPressHandle,
    searchVl,
    pickerValue,
    setPickerValue,
    pickerHandle,
  };
};
