import React from 'react';
import { Badge } from 'antd';
import { VoidFunctionType } from '../../types/coreTypes';
import Button from '../button/Button';

type ButtonWithBadgeProps = {
  label?: string;
  onClick?: VoidFunctionType;
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  icon?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  badge?: React.ReactNode;
  overflowCount?: number;
  designType?: 'light-inverse' | 'dark',
  isActivated?: boolean,
}

const ButtonWithBadge: React.FC<ButtonWithBadgeProps> = ({
  label,
  onClick,
  type = 'default',
  icon,
  disabled,
  className,
  badge,
  overflowCount,
  designType,
  isActivated,
}) => {
  return (
    <Badge
      count={badge || undefined}
      overflowCount={overflowCount}
      color="#FFCF1F"
      style={{ color: '#2C4251' }}
    >
      <Button
        onClick={onClick}
        htmlType="button"
        type={type}
        icon={icon}
        disabled={disabled}
        className={className}
        label={label}
        designType={designType}
        isActivated={isActivated}
      />
    </Badge>
  );
};

export default ButtonWithBadge;
