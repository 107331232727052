import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSearchParams } from 'react-router-dom';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  currentSourceSelector, currentSourceValueSelector,
  enquiryAcceptedOrRejectedSelector,
  enquiryDetailsSelector,
  isCurrentVehManualSelector,
  openedVehicleIdSelector,
  quoteLinePartsFiltersSelector,
  quoteLinePartsKitFiltersSelector,
  quoteLinePartsKitSelector,
  quoteLinePartsSelector,
  quoteLinesFiltersSelector,
  quoteLinesSelector, quoteLinesTableViewSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import {
  BooleanFunctionType, initDataList, VoidFunctionType,
} from '../../../../../../core/types/coreTypes';
import {
  CreatePartLineReqDto,
  EnquiryQuoteVehicleDto,
  PatchEnquiryQuoteLineDto,
} from '../../../../types/enquiryDetailsTypes';
import QuoteLinesColumns from './QuoteLinesColumns';
import Tfoot from '../../../../../../core/components/table-components/Tfoot';
import Button from '../../../../../../core/components/button/Button';
import './QuoteTable.scss';
import QuoteLinesCells from './QuoteLinesCells';
import { useQuoteLinesTable } from '../../../../hooks/useQuoteLinesTable';
import AddDiscountModal from '../../../../../../common/components/add-discount-modal/AddDiscountModal';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import {
  bulkPatchQuoteLineThunk,
  createCustomLineThunk,
  createPartLineThunk,
  createPartsKitLineThunk,
  deleteEnquiryQuotLineThunk,
  deleteSelectedQuoteLinesThunk,
  editEnquiryQuotLineThunk,
  fetchQuoteLinePartsKitThunk,
  fetchQuoteLinePartsThunk,
} from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import Tr from '../../../../../../core/components/table-components/Tr';
import SelectProductSourceModal from '../../../../../../common/components/select-product-source-modal/SelectProductSourceModal';
import {
  setCurrentInfoSource,
  setQuoteLineParts,
  setQuoteLinePartsFilters,
  setQuoteLinePartsKit,
  setQuoteLinePartsKitFilters,
} from '../../../../../../store/slices/enquiriesSlice';
import {
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters, PartProductSourceTotalParams,
  PatchProductSourceType,
} from '../../../../../../common/types/commonTypes';
import FillInDraftLineModal from '../../../../../../common/components/fill-in-draft-line-modal/FillInDraftLineModal';
import { currentBusinessAreaSelector, QuoteInfoSourcesSelector } from '../../../../../../store/selectors/coreSelectors';
import { PermissionEnum, QuoteInfoSourcesUiNames } from '../../../../../../core/enums/dictionariesEnums';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { getPartProductSourceDictionaryThunk, getPartProductSourceTotalThunk } from '../../../../../../store/thunks/shared/sharedThunks';
import { setProductSourceDictionary, setProductSourceTotal } from '../../../../../../store/slices/sharedSlice';
import { productSourceDictionarySelector, productSourceTotalSelector } from '../../../../../../store/selectors/sharedSelectors';
import { useCancelRequest } from '../../../../../../core/hooks/useCancelRequest';
import { activePartsKitStatusSelector } from '../../../../../../store/selectors/coreStatusesSelectors';

type QuoteLinesTableProps = {
  vehicle: EnquiryQuoteVehicleDto;
  setLineId: (value: number | undefined) => void;
  setCopyLineModal: BooleanFunctionType;
}

const QuoteLinesTable: React.FC<QuoteLinesTableProps> = ({
  vehicle, setLineId, setCopyLineModal,
}) => {
  const { controller, cancelRequest } = useCancelRequest();

  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const details = useAppSelector(enquiryDetailsSelector);
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const quoteLines = useAppSelector(quoteLinesSelector);
  const lines = useAppSelector(quoteLinesTableViewSelector);
  const quoteLineParts = useAppSelector(quoteLinePartsSelector);
  const linesParts = quoteLineParts.items.map((lp) => ({ id: lp.id, unitOfMeasure: lp.unitOfMeasure }));
  const productSourceDictionary = useAppSelector(productSourceDictionarySelector);
  const quoteLinesFilters = useAppSelector(quoteLinesFiltersSelector);
  const openedVehicleId = useAppSelector(openedVehicleIdSelector);
  const productSourceTotal = useAppSelector(productSourceTotalSelector);
  const quoteLinePartsFilters = useAppSelector(quoteLinePartsFiltersSelector);
  const isCurrentVehManual = useAppSelector(isCurrentVehManualSelector);
  const activePartsKitStatus = useAppSelector(activePartsKitStatusSelector);
  const vehicleId = searchParams.get('vehicle');

  const {
    columns,
    linesArr,
    footerActions,
    isAll,
    isIndeterminate,
    checkAllHandle,
    checkRowHandle,
    checkedKeys,
    setDiscountModal,
    discountModal,
    deleteLineModal,
    setDeleteLineModal,
    partsModal,
    setPartsModal,
  } = useQuoteLinesTable(enquiryAcceptedOrRejected);

  const [prodSourceModal, setProdSourceModal] = useState(false);
  const [sourceName, setSourceName] = useState<string>('');
  const [sourceId, setSourceId] = useState<number | undefined>(undefined);
  const [editMode, setEditMode] = useState(false);
  const [editedLine, setEditedLine] = useState<number | undefined>(undefined);
  const businessArea = useAppSelector(currentBusinessAreaSelector);
  const quoteInfoSources = useAppSelector(QuoteInfoSourcesSelector);
  const currentInfoSource = useAppSelector(currentSourceSelector);
  const {
    isCorePart, isNonCorePart, isCustom, isPartKit,
  } = useAppSelector(currentSourceValueSelector);
  const quoteLinePartsKit = useAppSelector(quoteLinePartsKitSelector);
  const quoteLinePartsKitFilters = useAppSelector(quoteLinePartsKitFiltersSelector);

  const allowedToManage = usePermission(PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions);

  const deleteConfirmationHandle = () => {
    if (editedLine) {
      dispatch(deleteEnquiryQuotLineThunk({
        lineId: editedLine,
        closeModal: () => {
          setDeleteLineModal(false);
          setEditedLine(undefined);
        },
      }));
    } else {
      !!checkedKeys.length && vehicleId && dispatch(deleteSelectedQuoteLinesThunk({
        quoteLineIds: checkedKeys,
        vehicleId: +vehicleId,
        closeModal: () => {
          setDeleteLineModal(false);
        },
      }));
    }
  };
  const editPriceSourceHandle = (data: Partial<PatchEnquiryQuoteLineDto>, closeModal: VoidFunctionType) => {
    editedLine && dispatch(editEnquiryQuotLineThunk({
      lineId: editedLine,
      data: { ...data },
      closeModal,
    }));
  };
  const createPriceSourceHandle = (data: Partial<PatchProductSourceType>, closeModal: VoidFunctionType) => {
    openedVehicleId && sourceId && dispatch(createPartLineThunk({
      data: {
        partId: sourceId,
        quoteVehicleId: openedVehicleId,
        ...data,
      } as CreatePartLineReqDto,
      closeModal,
    }));
  };
  const getProductSourceDictionaryHandle = (partId: number, openModal: VoidFunctionType) => {
    details?.enquiry && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: details.enquiry.businessArea,
        customerId: details.enquiry.customerId,
        priceDate: details.enquiry.quoteDate,
      },
      openModal,
    }));
  };
  const getProductSourceTotalHandle = (partId: number, params: PartProductSourceTotalParams) => {
    details?.enquiry && dispatch(getPartProductSourceTotalThunk({
      partId,
      params: {
        ...params,
        priceDate: details.enquiry.quoteDate,
      },
    }));
  };
  const cancelProductSourceModal = () => {
    setProdSourceModal(false);
    setSourceName('');
    setSourceId(undefined);
    editMode && setEditMode(false);
    editedLine && setEditedLine(undefined);
    dispatch(setProductSourceTotal(null));
    dispatch(setProductSourceDictionary(null));
  };
  const currentInitLine = useMemo(() => quoteLines.items.find((el) => el.id === editedLine), [editedLine, quoteLines.items]);

  return (
    <>
      <AddDiscountModal
        isVisible={discountModal}
        onCancel={() => setDiscountModal(false)}
        setDiscount={(discountVal, closeModal) => {
          vehicle.id && dispatch(bulkPatchQuoteLineThunk({
            quoteLineIds: checkedKeys,
            discount: discountVal,
            vehicleId: vehicle.id,
            closeModal,
          }));
        }}
      />
      <FillInDraftLineModal
        isVisible={partsModal}
        isEnquiry
        onCancel={() => {
          setPartsModal(false);
          setEditedLine(undefined);
          editMode && setEditMode(false);
        }}
        title="Fill in quote line"
        infoSources={quoteInfoSources}
        chooseInfoSource={(v) => dispatch(setCurrentInfoSource(v))}
        currentInfoSource={currentInfoSource}
        setInitFiltersAndTableData={() => {
          dispatch(setQuoteLinePartsFilters(initDraftLinePartsFilters));
          dispatch(setQuoteLineParts(initDataList));
          dispatch(setQuoteLinePartsKitFilters(initDraftLinePartsKitFilters));
          dispatch(setQuoteLinePartsKit(initDataList));
        }}
        disabledSourceBtnLabel={QuoteInfoSourcesUiNames.Custom}
        isCorePart={isCorePart}
        isCustom={isCustom}
        isNonCorePart={isNonCorePart}
        isPartKit={isPartKit}
        editMode={editMode}
        editedLine={editedLine}
        setProdSourceModal={(partId: number, name: string) => {
          setSourceName(name);
          setSourceId(partId);
          details?.enquiry && dispatch(getPartProductSourceDictionaryThunk({
            partId,
            params: {
              businessArea: details.enquiry.businessArea,
              customerId: details.enquiry.customerId,
              priceDate: details.enquiry.quoteDate,
            },
            openModal: () => setProdSourceModal(true),
          }));
        }}
        parts={quoteLineParts}
        setParts={(data) => dispatch(setQuoteLineParts(data))}
        filters={quoteLinePartsFilters}
        setFilters={(values) => dispatch(setQuoteLinePartsFilters(values))}
        isSuitableForCheckboxShown={!isCurrentVehManual}
        fetchParts={(values, isShow) => {
          const filters = {
            ...values,
            businessAreas: businessArea ? [businessArea] : undefined,
            onlySuitableForQuoteVehicleId: isShow ? openedVehicleId : undefined,
          };
          businessArea && dispatch(fetchQuoteLinePartsThunk({ filters, signal: controller.current?.signal }));
        }}
        partsKitFilters={quoteLinePartsKitFilters}
        setPartsKitFilters={(filters) => dispatch(setQuoteLinePartsKitFilters({ ...filters }))}
        partsKitParts={quoteLinePartsKit}
        setPartsKitParts={(list) => dispatch(setQuoteLinePartsKit(list))}
        fetchPartsKitParts={(values, isShow) => {
          const filters = {
            ...values,
            statuses: activePartsKitStatus ? [activePartsKitStatus] : undefined,
            businessAreas: businessArea ? [businessArea] : undefined,
            onlySuitableForQuoteVehicleId: isShow ? openedVehicleId : undefined,
          };
          cancelRequest();
          businessArea && dispatch(fetchQuoteLinePartsKitThunk({ filters, signal: controller.current?.signal }));
        }}
        createPartsKitLineThunk={(partsKitId, closeModal) => {
          openedVehicleId && dispatch(createPartsKitLineThunk({
            quoteVehicleId: openedVehicleId, partsKitId, closeModal,
          }));
        }}
        editCustomHandle={(values, closeModal) => editedLine && dispatch(editEnquiryQuotLineThunk({
          lineId: editedLine,
          data: values,
          closeModal,
        }))}
        createCustomHandle={(values, closeModal) => openedVehicleId && dispatch(createCustomLineThunk({
          data: { ...values, quoteVehicleId: openedVehicleId },
          closeModal,
        }))}
        initCustomLine={currentInitLine
          ? {
            productName: currentInitLine.productName,
            quantity: currentInitLine.quantity,
            unitOfMeasure: currentInitLine.unitOfMeasure,
            unitPrice: currentInitLine.unitPriceString,
            tax: currentInitLine.tax,
            quoteCategoryId: currentInitLine.quoteCategoryId,
            weight: currentInitLine.weight,
          }
          : undefined}
        originName="a quote"
      />
      <SelectProductSourceModal
        isVisible={prodSourceModal}
        onCancel={() => cancelProductSourceModal()}
        closePartsModal={() => setPartsModal(false)}
        sourceName={sourceName}
        sourceId={sourceId}
        editMode={editMode}
        editedLine={editedLine}
        setProductSourceTotal={(v) => dispatch(setProductSourceTotal(v))}
        setProductSourceDictionary={(v) => dispatch(setProductSourceDictionary(v))}
        lines={lines}
        linesParts={linesParts}
        productSourceDictionary={productSourceDictionary}
        editThunk={(data, closeModal) => editPriceSourceHandle(data, closeModal)}
        createThunk={(data, closeModal) => createPriceSourceHandle(data, closeModal)}
        productSourceTotal={productSourceTotal}
        getProductSourceDictionary={(partId, openModal) => getProductSourceDictionaryHandle(partId, openModal)}
        getProductSourceTotal={(partId, stockQty, manualQty, manualUnitPrice, pricelists) => {
          const params = {
            stockQuantity: stockQty,
            pricelists,
            manualInputQuantity: manualQty,
            manualInputUnitPrice: manualUnitPrice,
          };
          getProductSourceTotalHandle(partId, params);
        }}
      />
      <ConfirmationModal
        isVisible={deleteLineModal}
        onCancel={() => {
          setDeleteLineModal(false);
          setEditedLine(undefined);
        }}
        confirmHandler={deleteConfirmationHandle}
        isDelete
      />
      <TableNew className="table-with-editable-cells" hasFooter={allowedToManage} offsetBottom>
        <Thead>
          <QuoteLinesColumns
            columns={columns}
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            checkAll={checkAllHandle}
            totalElements={quoteLines.items.length || 0}
            maxNetPrice={quoteLines.maxNetPrice}
            quoteLinesFilters={quoteLinesFilters}
            disabled={enquiryAcceptedOrRejected}
          />
        </Thead>
        <Tbody>
          {linesArr.length === 0
            ? <EmptyTableSection text="No records" colSpan={allowedToManage ? columns.length + 2 : columns.length} />
            : <QuoteLinesCells
              linesArr={linesArr}
              setLineId={setLineId}
              setCopyLineModal={setCopyLineModal}
              checkedKeys={checkedKeys}
              checkRows={checkRowHandle}
              columns={columns}
              editQty={(id: number) => {
                setProdSourceModal(true);
                setEditMode(true);
                setEditedLine(id);
              }}
              openCustomModal={(num: number) => {
                setEditedLine(num);
                setEditMode(true);
                setPartsModal(true);
              }}
              openDeleteModal={(id: number) => {
                setDeleteLineModal(true);
                setEditedLine(id);
              }}
            />}
        </Tbody>
        {allowedToManage && (
          <Tfoot>
            <Tr>
              <TdNew colSpan={columns.length + (allowedToManage ? 2 : 0)}>
                {footerActions.map((a) => a.isVisible && <Button
                  key={a.label}
                  label={a.label}
                  icon={a.icon && <FontAwesomeIcon icon={a.icon} />}
                  onClick={a.onClick}
                  disabled={a.disabled}
                  type="text"
                />)}
              </TdNew>
            </Tr>
          </Tfoot>
        )}
      </TableNew>
    </>
  );
};

export default QuoteLinesTable;
