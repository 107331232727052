import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Button from '../../../core/components/button/Button';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import { VehicleProfilesFilters } from '../types/vehicleProfilesTypes';
import { setVehicleProfilesFilters } from '../../../store/slices/vehiclesSlice';
import { useAppDispatch } from '../../../store/hooks';

type VehicleProfilesActionsProps = {
  setVehicleModal: BooleanFunctionType,
  filters: VehicleProfilesFilters,
  totalCount: number,
}

const VehicleProfilesActions: React.FC<VehicleProfilesActionsProps> = ({ setVehicleModal, filters, totalCount }) => {
  const dispatch = useAppDispatch();
  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Button
          label="Create vehicle profile"
          icon={<FontAwesomeIcon icon={faPlusCircle} />}
          onClick={() => setVehicleModal(true)}
          reversed
        />
      </div>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        totalItems={totalCount}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => dispatch(setVehicleProfilesFilters({ ...filters, page, pageSize }))}
        className="actions-and-paging__paging"
        narrow
      />
    </div>
  );
};

export default VehicleProfilesActions;
