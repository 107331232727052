import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Input from '../../../../../core/components/input/Input';
import Select from '../../../../../core/components/select/Select';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  QuoteUnitsOfMeasureSelector,
  shortBusinessAreasSelector,
  weightlessMeasureValuesSelector,
} from '../../../../../store/selectors/coreSelectors';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import { BooleanFunctionType, initSmallPaging, VoidFunctionType } from '../../../../../core/types/coreTypes';
import CategoryModal from '../../../../common/components/category-modal/CategoryModal';
import CategorySection from '../../../../common/components/category-section/CategorySection';
import { stockPartAffectedEntitiesFiltersSelector, stockPartTypesSelector } from '../../../../../store/selectors/stockSelectors';
import { labelHandle } from '../../../../../core/utils/labelHandle';
import {
  formInitialValues, GetPartResponse, PartDescriptionSchema, PartViewFields,
} from '../../../types/types';
import { getStockPartAffectedThunk, patchStockPartThunk } from '../../../../../store/thunks/stock/part/partViewPageThunks';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../../../core/utils/regex';
import { PartDtoType } from '../../../../search/types/stockSearchTypes';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import { useFetchCurrentTabData } from '../../../hooks/useFetchCurrentTabData';
import {
  partCategoriesLookupSelector,
  partCategoriesWithoutEmptyLookupSelector,
  partSubcategoriesLookupSelector,
} from '../../../../../store/selectors/sharedSelectors';
import Textarea from '../../../../../core/components/textarea/Textarea';
import { setPartAffectedEntitiesFilters } from '../../../../../store/slices/stockSlice';
import EditFieldConfirmationModal from '../../modals/edit-field-confitmation/EditFieldConfirmationModal';
import { PartCodeSchema, PartNameSchema } from '../../../../create-part/utils/PartSchema';

type PartViewPageFormProps = {
  partDetails: GetPartResponse | null,
  setVisibleAffectedModal: BooleanFunctionType,
}

const PartViewPageForm: React.FC<PartViewPageFormProps> = ({ partDetails, setVisibleAffectedModal }) => {
  const [isCategoryVisible, setIsCategoryVisible] = useState(false);
  const [fieldToEdit, setFieldToEdit] = useState<{ field: 'name' | 'partCode', value: string } | null>(null);
  const allowedToEdit = usePermission(PermissionEnum.StockPartEditFields);
  const allowedToEditPartCode = usePermission(PermissionEnum.StockPartEditCode);
  const allowedToEditName = usePermission(PermissionEnum.StockPartEditName);
  const dispatch = useAppDispatch();
  const affectedFilters = useAppSelector(stockPartAffectedEntitiesFiltersSelector);
  const partTypes = useAppSelector(stockPartTypesSelector);
  const shortBusinessAreas = useAppSelector(shortBusinessAreasSelector);
  const partCategories = useAppSelector(partCategoriesLookupSelector);
  const notEmptyCategories = useAppSelector(partCategoriesWithoutEmptyLookupSelector);
  const initialSubcategoriesList = useAppSelector(partSubcategoriesLookupSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const weightlessMeasures = useAppSelector(weightlessMeasureValuesSelector);
  const disabledWeight = weightlessMeasures.includes(partDetails?.part.unitOfMeasure);

  const { fetchCurrentTabData, partTab } = useFetchCurrentTabData(partDetails?.part.id);

  const {
    control, setValue, setError, clearErrors,
  } = useForm<PartViewFields>({
    defaultValues: formInitialValues,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const fieldsToValidate = [
    'name',
    'partCode',
    'manufacturerCode',
    'isCorePart',
    'type',
    'unitOfMeasure',
    'description',
    'categoryId',
    'subcategoryId',
    'weight',
    'businessAreas',
  ];

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line
  }, [partTab]);

  useEffect(() => {
    if (partDetails?.part) {
      Object.keys(partDetails.part).forEach((key) => {
        if (fieldsToValidate.includes(key)) {
          if (key === 'weight' && partDetails.part.weight === 0) {
            setValue('weight', '0.00');
          } else {
            // eslint-disable-next-line
            // @ts-ignore
            setValue(key, partDetails.part[key]);
          }
        }
      });
    }
    // eslint-disable-next-line
  }, [partDetails?.part]);

  const editField = (data: Partial<PartDtoType>, key: keyof PartDtoType, deepCompare?: boolean, isOptional?: boolean) => {
    if (partDetails?.part) {
      const handler = () => {
        dispatch(patchStockPartThunk({
          id: partDetails.part.id,
          data,
          closeModal: () => {
            fetchCurrentTabData();
            setFieldToEdit(null);
          },
          setError,
        }));
      };
      compareValuesHandle<PartDtoType>(data, key, partDetails.part, handler, deepCompare, isOptional);
    }
  };

  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, partDetails?.part, setValue);
  };

  const openCategoryModal = () => {
    clearOtherErrorsHandle('categoryId');
    clearOtherErrorsHandle('subcategoryId');
    setIsCategoryVisible(true);
  };

  const handleEditCategory = (categoryId: number, subcategoryId: number) => {
    if (partDetails?.part) {
      setValue('categoryId', categoryId);
      setValue('subcategoryId', subcategoryId);
      setIsCategoryVisible(false);
      dispatch(patchStockPartThunk({
        id: partDetails.part.id,
        data: { subcategoryId },
        closeModal: fetchCurrentTabData,
      }));
    }
  };

  const affectedHandle = (action: VoidFunctionType, dataToAffect: number[]) => {
    const filters = { ...affectedFilters, businessAreas: dataToAffect };
    dispatch(setPartAffectedEntitiesFilters(filters));
    partDetails && dispatch(getStockPartAffectedThunk({
      id: partDetails.part.id,
      filters,
      complexAction: () => {
        setVisibleAffectedModal(true);
        setValue('businessAreas', partDetails.part.businessAreas || []);
      },
      confirmAction: () => {
        dispatch(setPartAffectedEntitiesFilters(initSmallPaging));
        action();
      },
    }));
  };

  const checkPartAffected = (data: Partial<PartDtoType>, key: keyof PartDtoType, dataToAffect: number[], deepCompare: boolean) => {
    if (partDetails?.part) {
      const handler = () => {
        affectedHandle(() => editField(data, key), dataToAffect);
      };
      compareValuesHandle<PartDtoType>(data, key, partDetails.part, handler, deepCompare);
    }
  };

  return (
    <>
      <EditFieldConfirmationModal
        visible={!!fieldToEdit?.field}
        confirmHandler={() => {
          fieldToEdit && editField({ [fieldToEdit.field]: fieldToEdit.value }, fieldToEdit.field);
        }}
        onClose={() => {
          fieldToEdit && setValue(fieldToEdit.field, partDetails?.part[fieldToEdit.field] || '');
          setFieldToEdit(null);
        }}
        data={fieldToEdit}
      />
      <CategoryModal
        visible={isCategoryVisible}
        onCancel={() => setIsCategoryVisible(false)}
        title="Select category and subcategory"
        editHandle={handleEditCategory}
        categoryId={partDetails && partDetails.part.categoryId}
        subcategoryId={partDetails && partDetails.part.subcategoryId}
        categories={notEmptyCategories}
      />
      <form className="partViewPageForm details-form">
        <Controller
          name="name"
          control={control}
          rules={PartNameSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              label="Part name"
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('name');
              }}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value.length === 0) {
                  setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('name', '');
                } else {
                  !errors.name && value !== partDetails?.part.name && setFieldToEdit({ field: field.name, value });
                }
              }}
              error={errors.name?.message}
              disabled={!allowedToEditName}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          name="partCode"
          control={control}
          rules={PartCodeSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              label="Part code"
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('partCode');
              }}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value.length === 0) {
                  setError('partCode', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('partCode', '');
                } else {
                  !errors.partCode && value !== partDetails?.part.partCode && setFieldToEdit({ field: field.name, value });
                }
              }}
              error={errors.partCode?.message}
              disabled={!allowedToEditPartCode}
            />
          )}
        />
        <Controller
          name="manufacturerCode"
          control={control}
          rules={PartCodeSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              label="Manufacturer code"
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('manufacturerCode');
              }}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (value.length === 0) {
                  setError('manufacturerCode', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('manufacturerCode', '');
                } else {
                  !errors.manufacturerCode && editField({ manufacturerCode: value }, 'manufacturerCode');
                }
              }}
              error={errors.manufacturerCode?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="type"
          control={control}
          render={({ field }) => (
            <Select
              value={labelHandle(field.value, partTypes)}
              label="Type"
              onChange={(v) => field.onChange(v)}
              disabled
            />
          )}
        />
        <Controller
          name="unitOfMeasure"
          control={control}
          render={({ field }) => (
            <Select
              value={labelHandle(field.value, unitsOfMeasure)}
              label="Unit of measure"
              onChange={(v) => field.onChange(v)}
              disabled
            />
          )}
        />
        <Controller
          name="categoryId"
          control={control}
          render={({ field }) => (
            <CategorySection
              label="Category"
              value={labelHandle(field.value, partCategories)}
              setIsVisible={openCategoryModal}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="subcategoryId"
          control={control}
          render={({ field }) => (
            <CategorySection
              label="Subcategory"
              value={labelHandle(field.value, initialSubcategoriesList || [])}
              setIsVisible={openCategoryModal}
              disabled={!allowedToEdit}
            />
          )}
        />
        {disabledWeight
          ? <Input
            label="Weight, kg"
            value="-"
            disabled
          />
          : <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <DecimalInputNumber
                label="Weight, kg"
                value={field.value}
                max={maxCommonDecimal}
                onChange={(e) => {
                  field.onChange(e.target.value.replace(',', '.'));
                  clearOtherErrorsHandle('weight');
                }}
                onBlur={(e) => {
                  const zeroValues = ['', '0', '0.', '0.0', '0.00'];
                  if (zeroValues.includes(e.target.value)) {
                    setValue('weight', '0.00');
                  }
                  editField({ weight: +e.target.value }, 'weight', false, true);
                }}
                disabled={!allowedToEdit}
              />
            )}
          />}
        <Controller
          name="isCorePart"
          control={control}
          render={({ field }) => (
            <Select
              label="Core part"
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('isCorePart');
                editField({ isCorePart: v as boolean }, 'isCorePart');
              }}
              options={[{ label: 'Yes', value: true }, { label: 'No', value: false }]}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          control={control}
          name="description"
          rules={PartDescriptionSchema}
          render={({ field }) => (
            <Textarea
              label="Description"
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('description');
              }}
              onBlur={() => {
                const value = field.value?.trim() || '';
                !errors.description && editField({ description: value }, 'description', false, true);
              }}
              error={errors.description?.message}
              disabled={!allowedToEdit}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="businessAreas"
          render={({ field }) => (
            <Select
              label="Part business area"
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('businessAreas');
              }}
              onBlur={() => {
                if (!errors?.businessAreas) {
                  const hasSomeBaRemoved = partDetails?.part.businessAreas?.some((el) => !field.value.includes(el));
                  if (hasSomeBaRemoved) {
                    checkPartAffected(
                      { businessAreas: field.value },
                      'businessAreas',
                      field.value,
                      true,
                    );
                  } else {
                    editField({ businessAreas: field.value }, 'businessAreas', true);
                  }
                }
              }}
              onDeselect={() => {
                if (field.value.length === 1) {
                  setError('businessAreas', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              options={shortBusinessAreas}
              mode="multiple"
              error={errors.businessAreas && ErrorsEnum.REQUIRED}
              showArrow
              disabled={!allowedToEdit}
              className="details-form__field--lg"
            />
          )}
        />
      </form>
    </>
  );
};

export default PartViewPageForm;
