import { capitalize } from 'lodash';
import {
  VehiclesPageParamsEnum, VehiclesDashboardStatusesEnum, VehiclesViewsEnum,
} from '../enums/VehiclesPageParamsEnum';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export const vehiclesContainerOptions = [
  {
    value: VehiclesPageParamsEnum.DASHBOARD,
    label: VehiclesViewsEnum.DASHBOARD,
    restrictedTo: PermissionEnum.VehicleDashboard,
  },
  {
    value: VehiclesPageParamsEnum.VEHICLE_PROFILES,
    label: VehiclesViewsEnum.VEHICLE_PROFILES,
    restrictedTo: PermissionEnum.VehicleProfiles,
  },
  {
    value: VehiclesPageParamsEnum.CREATE_NEW_VEHICLE,
    label: VehiclesViewsEnum.CREATE_NEW_VEHICLE,
    restrictedTo: PermissionEnum.VehicleCreate,
  },
];

export const vehiclesDashboardStatusesOptions = Object.values(VehiclesDashboardStatusesEnum).map((el) => ({
  label: capitalize(el),
  value: el,
}));
