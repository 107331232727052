import React, { useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import './SelectProductSourceModal.scss';
import { GenericObject, VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import {
  PartProductDictionaryType,
  PartProductTotalType,
  PatchProductSourceType, ProductSourceEnum,
  ProductSourceLine,
} from '../../types/commonTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import SelectProductSourceTable from './SelectProductSourceTable';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import { PatchEnquiryQuoteLineDto } from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import ReasonModal from '../reason-modal/ReasonModal';

type ProductSourceModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  sourceName: string,
  sourceId?: number,
  closePartsModal: VoidFunctionType,
  editMode?: boolean,
  editedLine?: number,
  childLineMode?: boolean,
  setProductSourceTotal: (value: PartProductTotalType | null) => void,
  setProductSourceDictionary: (value: PartProductDictionaryType | null) => void,
  editThunk?: (data: Partial<PatchEnquiryQuoteLineDto>, closeModal: VoidFunctionType, totalAmount?: number) => void,
  createThunk: (data: Partial<PatchProductSourceType>, closeModal: VoidFunctionType) => void,
  productSourceTotal: PartProductTotalType | null,
  getProductSourceDictionary: (partId: number, openModal: VoidFunctionType) => void,
  getProductSourceTotal: (
    partId: number,
    stockQty: number | null,
    manualQty: number | null,
    manualUnitPrice: number | null,
    pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  ) => void,
  lines: ProductSourceLine[],
  linesParts: { id: number, unitOfMeasure: number }[],
  productSourceDictionary: PartProductDictionaryType | null,
  hideManualInput?: boolean,
  reasonRequired?: boolean,
}

const SelectProductSourceModal: React.FC<ProductSourceModalProps> = ({
  isVisible, onCancel, sourceName, sourceId,
  closePartsModal, editMode, editedLine,
  setProductSourceDictionary, setProductSourceTotal,
  editThunk, createThunk, productSourceTotal, getProductSourceDictionary,
  getProductSourceTotal, productSourceDictionary, linesParts, lines, hideManualInput, childLineMode,
  reasonRequired,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const [checkedCells, setCheckedCells] = useState<string[]>([ProductSourceEnum.STOCK]);
  const [values, setValues] = useState<GenericObject<string> | null>(null);

  const [reasonModal, setReasonModal] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const children = lines
    .map((line) => (line.childLines ? line.childLines : []))
    .flat();
  const current = childLineMode
    ? children.find((line) => line?.id === editedLine)
    : lines.find((line) => line.id === editedLine);

  const valuesList = useMemo(() => {
    if (values && isVisible) {
      const checkedList = checkedCells.filter((cell) => cell !== ProductSourceEnum.MANUAL && cell !== ProductSourceEnum.STOCK);
      const pricelists = checkedList.map((l) => ({ key: +l, value: values[l] }));
      return [
        { key: ProductSourceEnum.STOCK, value: values?.stock },
        { key: ProductSourceEnum.MANUAL, value: values?.manual },
        { key: ProductSourceEnum.MANUAL, value: values?.manualUnit },
        ...pricelists,
      ];
    } else {
      return null;
    }
  }, [values, checkedCells, isVisible]);

  const backHandle = () => {
    onCancel();
    setCheckedCells([ProductSourceEnum.STOCK]);
    setValues(null);
    setProductSourceTotal(null);
    setProductSourceDictionary(null);
  };
  const createHandle = () => {
    const checkedList = checkedCells.filter((cell) => cell !== ProductSourceEnum.MANUAL && cell !== ProductSourceEnum.STOCK);
    const commonData = {
      stockQuantity: checkedCells.includes(ProductSourceEnum.STOCK) ? (+(values?.stock || 0)) : null,
      manualInputQuantity: checkedCells.includes(ProductSourceEnum.MANUAL) ? +(values?.manual || 0) : null,
      manualInputUnitPrice: checkedCells.includes(ProductSourceEnum.MANUAL) ? +(values?.manualUnit || 0) : null,
    };
    if (editMode) {
      const pricelistsData = checkedList.length > 0 ? checkedList.map((l) => ({ pricelistId: +l, quantity: values ? +values[l] : 0 })) : [];
      const data = {
        takeFromStock: checkedCells.includes(ProductSourceEnum.STOCK),
        useManualInput: checkedCells.includes(ProductSourceEnum.MANUAL),
        pricelists: pricelistsData,
        ...commonData,
      };
      const pricelistsTotal = pricelistsData.length > 0
        ? pricelistsData.map((el) => el.quantity).reduce((a, b) => a + b)
        : 0;
      const totalAmount = (commonData.stockQuantity || 0)
          + (commonData.manualInputQuantity || 0)
          + pricelistsTotal;
      editThunk && editThunk(data, backHandle, totalAmount);
    } else {
      if (reasonRequired && !reasonModal) {
        setReasonModal(true);
        return;
      }
      const data = {
        pricelists: checkedList.length > 0 ? checkedList.map((l) => ({ pricelistId: +l, quantity: values ? +values[l] : 0 })) : null,
        ...commonData,
        reason: reasonValue.trim(),
      };
      const closeModal = () => {
        backHandle();
        closePartsModal();
        setReasonModal(false);
      };
      createThunk(data, closeModal);
    }
  };
  return (
    <>
      <ReasonModal
        visible={reasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => setReasonModal(false)}
        onSubmit={createHandle}
        type="new"
      />
      <Modal visible={isVisible} onCancel={backHandle} title="Select product source and quantity" width={950} destroyOnClose>
        <div className="sourceModalInner">
          <section className="sourceModalInner__head">
            {!editMode && <FontAwesomeIcon
              icon={faChevronLeft}
              className="sourceModalInner__head-icon"
              onClick={() => backHandle()}
            />}
            <span className="sourceModalInner__head-title">
              {editMode ? current?.name : sourceName}
            </span>
          </section>
          <SelectProductSourceTable
            sourceId={sourceId}
            checkedCells={checkedCells}
            setCheckedCells={setCheckedCells}
            values={values}
            setValues={setValues}
            editMode={editMode}
            editedLine={editedLine}
            productSourceTotal={productSourceTotal}
            getProductSourceDictionary={getProductSourceDictionary}
            getProductSourceTotal={getProductSourceTotal}
            lines={childLineMode ? children : lines}
            linesParts={linesParts}
            productSourceDictionary={productSourceDictionary}
            hideManualInput={hideManualInput}
          />
          <ButtonActions
            cancelLabel="Cancel"
            createLabel={editMode ? 'Save' : 'Add part'}
            cancelClick={backHandle}
            createClick={createHandle}
            isLoading={isFetching}
            disabledCreate={checkedCells.length === 0 || !valuesList
              || valuesList.some((el) => checkedCells.includes(el.key.toString()) && !+el.value)}
            offsetTop
          />
        </div>
      </Modal>
    </>
  );
};

export default SelectProductSourceModal;
