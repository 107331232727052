import React from 'react';
import Modal from '../../../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { partsKitComponentsPartsSelector } from '../../../../../../../store/selectors/partsKitSelectors';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import { addPartComponent } from '../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { isGlobalLoadingSelector } from '../../../../../../../store/selectors/coreSelectors';

type AddComponentsWithoutPriceModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  checkedAddKeys: number[],
  onConfirm: VoidFunctionType,
  partsKitId: number,
}

const AddComponentsWithoutPriceModal: React.FC<AddComponentsWithoutPriceModalProps> = ({
  isVisible, onCancel, checkedAddKeys, onConfirm, partsKitId,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const dispatch = useAppDispatch();
  const parts = useAppSelector(partsKitComponentsPartsSelector);
  const checkedParts = parts.items.filter((el) => checkedAddKeys.includes(el.id));
  const partsWithNoPrice = checkedParts.filter((p) => !p.hasPrice);

  const confirmHandle = () => {
    dispatch(addPartComponent({
      id: partsKitId,
      partIds: checkedAddKeys,
      closeModal: () => {
        onConfirm();
        onCancel();
      },
    }));
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      title="Add components without price"
    >
      <div className="addWithoutPriceWrap">
        <p className="addWithoutPriceWrap__text">
          <span>
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            The following parts don't have any price values as of today:
          </span>
          <ul className="addWithoutPriceWrap__text-list">
            {partsWithNoPrice.map((p) => <li>{`${p.partCode} - ${p.name}`}</li>)}
          </ul>
          <span>Do you still want to add them as parts kit components?</span>
        </p>
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={onCancel}
          createClick={confirmHandle}
          createLabel="Yes, add components"
          isLoading={isGlobalLoading}
        />
      </div>
    </Modal>
  );
};

export default AddComponentsWithoutPriceModal;
