import { DictionaryItem, EntityLinkType } from '../types/coreTypes';
import { localDateFormatHandler } from './utcDateFormatHandler';
import { LinksPath, LinksTypesUiNames, PermissionEnum } from '../enums/dictionariesEnums';
import { DEFAULT_UI_DATE_FORMAT_WITH_TIME } from './regex';

export const linksReformHandler = (data: EntityLinkType[]) => {
  let inheritanceArr: Array<{ parent: number, children: EntityLinkType[] }> = [];
  for (let i = 0; i < data.length; i++) {
    const currentItem = data[i];
    const currentType = currentItem.type;
    const existedParent = inheritanceArr.find((el) => el.parent === currentType);
    if (existedParent) {
      inheritanceArr = inheritanceArr.map((el) => {
        if (el.parent === currentType) {
          return { ...el, children: [...el.children, currentItem] };
        } else return el;
      });
    } else {
      inheritanceArr = [...inheritanceArr, { parent: currentType, children: [currentItem] }];
    }
  }
  return inheritanceArr;
};

export const linksUiViewHandle = (
  arr: Array<{ parent: number, children: EntityLinkType[] }>,
  statusLabel: (status: number | string, type?: number) => string | number,
  types: DictionaryItem[],
) => {
  const nameHandle = (parent: number) => types.find((t) => t.value === parent)?.label || parent;
  const linkHandle = (parent: number) => {
    const current = Object.entries(LinksPath).find(([key]) => key === nameHandle(parent));
    return current ? current[1] : '';
  };
  const permitHandle = (parent: number) => {
    const nameHandle = (parent: number) => types.find((t) => t.value === parent)?.label || parent;
    const name = nameHandle(parent);
    switch (name) {
      case LinksTypesUiNames.Job:
        return PermissionEnum.JobViewPage;
      case LinksTypesUiNames.Rectification:
        return PermissionEnum.RectificationViewPage;
      case LinksTypesUiNames.Order:
        return PermissionEnum.OrderViewPage;
      case LinksTypesUiNames.Vehicle:
        return PermissionEnum.VehicleViewPage;
      default: return PermissionEnum.EnquiryViewPage;
    }
  };
  const sortedArr = [...arr].sort((a, b) => nameHandle(a.parent).toString().localeCompare(nameHandle(b.parent).toString()));
  return sortedArr.map((el) => {
    return {
      name: nameHandle(el.parent),
      parentLink: linkHandle(el.parent),
      permission: permitHandle(el.parent),
      children: el.children.map((el) => ({
        ...el,
        status: statusLabel(el.status, el.type),
        createdDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.createdDate),
        modifiedDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.modifiedDate),
      })),
    };
  });
};
