import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseResult } from '../../../core/types/coreTypes';
import { SupplierDto, SuppliersFiltersType } from '../../../supplier/dashboard/types/SuppliersTypes';
import { SuppliersAPI } from '../../../api/suppliersApi';
import { setDashboardLoading, setLoading } from '../../slices/coreSlice';

export const getSupplierList = createAsyncThunk<ResponseResult<SupplierDto[]>, {
  filters?: SuppliersFiltersType,
  isDashboard?: boolean,
  signal?: AbortSignal,
}>(
  'get/SupplierList',
  async ({
    filters, isDashboard, signal,
  }, { rejectWithValue, dispatch }) => {
    isDashboard ? dispatch(setDashboardLoading(true)) : dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSupplierList(filters, signal);
      isDashboard ? dispatch(setDashboardLoading(false)) : dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      !isDashboard && dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
