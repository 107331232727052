import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import Input from '../../../../../../core/components/input/Input';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../../core/enums/errorsEnum';
import Select from '../../../../../../core/components/select/Select';
import {
  employeeDepartmentsSelector,
  employeeEmploymentsSelector,
  employeesSalaryTypesSelector,
} from '../../../../../../store/selectors/employeesSelector';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import DatePicker from '../../../../../../core/components/date-picker/DatePicker';
import DecimalInputNumber from '../../../../../../core/components/input-number/DecimalInputNumber';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_UI_DATE_FORMAT,
  maxEightDigitsBeforeDot,
  maxTwoDigitsBeforeDot,
} from '../../../../../../core/utils/regex';
import RadioGroup from '../../../../../../core/components/radio-group/RadioGroup';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import {
  createEmployeeEmploymentThunk,
  editEmployeeEmploymentThunk,
} from '../../../../../../store/thunks/employee/employeeEmploymentsThunks';
import { isFetchingSelector } from '../../../../../../store/selectors/coreSelectors';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import { rangeOverlap, setDefaultEmploymentValues } from '../../../../utils/employmentUtils';
import {
  addEmploymentDefaults,
  AddEmploymentFields,
  JobTitleSchema,
} from '../../../../utils/AddEmploymentSchema';

type AddEmploymentProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  editMode?: boolean,
  lineToChange?: number,
}

const AddEmployment: React.FC<AddEmploymentProps> = ({
  visible, onCancel, editMode, lineToChange,
}) => {
  const [areDatesOverlapped, setDatesOverlapped] = useState(false);

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const employeeEmployments = useAppSelector(employeeEmploymentsSelector);
  const employeesSalaryTypes = useAppSelector(employeesSalaryTypesSelector);
  const employeeDepartments = useAppSelector(employeeDepartmentsSelector);
  const {
    control, reset, handleSubmit, setError, setValue, watch, clearErrors,
  } = useForm<AddEmploymentFields>({
    defaultValues: addEmploymentDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const submitHelper = (values: AddEmploymentFields) => {
    const close = () => {
      reset();
      onCancel();
      areDatesOverlapped && setDatesOverlapped(false);
    };
    const finalValues = {
      ...values,
      department: values.department as number,
      salaryType: values.salaryType as number,
      salaryValue: values.salaryValue ? +values.salaryValue : null,
      annualLeaveEntitlement: values.annualLeaveEntitlement ? +values.annualLeaveEntitlement : null,
    };
    const finalEditValues = {
      ...values,
      endDateIsEmpty: !values.endDate,
      department: values.department as number,
      salaryType: values.salaryType as number,
      salaryValue: values.salaryValue ? +values.salaryValue : null,
      salaryValueIsEmpty: !values.salaryValue,
      annualLeaveEntitlement: values.annualLeaveEntitlement ? +values.annualLeaveEntitlement : null,
      annualLeaveEntitlementIsEmpty: !values.annualLeaveEntitlement,
    };
    if (editMode) {
      lineToChange && id && dispatch(editEmployeeEmploymentThunk({
        employmentId: lineToChange,
        employeeId: +id,
        employment: { ...finalEditValues },
        closeModal: close,
      }));
    } else {
      id && dispatch(createEmployeeEmploymentThunk({
        employeeId: +id,
        data: { ...finalValues },
        closeModal: close,
      }));
    }
  };
  const onSubmit = (values: AddEmploymentFields) => {
    const defaultEndDate = moment().add(+1, 'month');
    const employmentsCopy = employeeEmployments ? [...employeeEmployments] : [];
    const employmentsRecords = editMode
      ? employmentsCopy.filter((el) => el.id !== lineToChange)
      : [...employmentsCopy];
    const allDatesRanges = employmentsRecords.length > 0
      ? employmentsRecords.map((el) => ([moment(el.startDate), el.endDate ? moment(el.endDate) : defaultEndDate]))
      : [];
    const currentDates = values.endDate
      ? [moment(values.startDate), moment(values.endDate)]
      : [moment(values.startDate), defaultEndDate];
    let isOverlap = false;
    if (allDatesRanges && allDatesRanges.length > 0) {
      for (let i = 0; i < allDatesRanges.length; i++) {
        const iteratedRange = allDatesRanges[i];
        const result = rangeOverlap(iteratedRange, currentDates);
        if (result) {
          isOverlap = true;
          break;
        }
      }
    }
    if (isOverlap) {
      setDatesOverlapped(true);
    } else {
      submitHelper({
        ...values,
        startDate: moment(values.startDate).format(DEFAULT_DATE_FORMAT),
        endDate: values.endDate
          ? moment(values.endDate).format(DEFAULT_DATE_FORMAT)
          : null,
      });
    }
  };

  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const watchedValues = watch();

  const minInitAvailableDate = moment().subtract(120, 'years');
  const maxInitAvailableDate = moment().add(1, 'months');

  useEffect(() => {
    if (visible) {
      if (editMode && lineToChange) {
        const found = employeeEmployments?.find((el) => el.id === lineToChange);
        if (found) {
          setDefaultEmploymentValues(found, setValue);
        }
      }
    } else {
      reset();
      setValue('startDate', null);
      setValue('endDate', null);
    }
    // eslint-disable-next-line
  }, [editMode, lineToChange, visible]);

  const cancelHandle = () => {
    onCancel();
    reset();
    setValue('startDate', null);
    setValue('endDate', null);
    clearErrors();
  };
  return (
    <>
      <ConfirmationModal
        isVisible={areDatesOverlapped}
        onCancel={() => {
          setDatesOverlapped(false);
          cancelHandle();
        }}
        confirmHandler={() => {
          submitHelper({
            ...watchedValues,
            startDate: moment(watchedValues.startDate).format(DEFAULT_DATE_FORMAT),
            endDate: watchedValues.endDate
              ? moment(watchedValues.endDate).format(DEFAULT_DATE_FORMAT)
              : null,
          });
        }}
        customTitle="Confirm"
        customText="Please note that entered employment period overlaps with another employment record."
        customCreateLabel="Yes"
      />
      <Modal
        visible={visible}
        onCancel={cancelHandle}
        destroyOnClose
        title={editMode ? 'Edit employment record' : 'Add employment record'}
        width={700}
      >
        <form onSubmit={handleSubmit(onSubmit)} className="addEmployment">
          <Controller
            control={control}
            name="jobTitle"
            rules={JobTitleSchema}
            render={({ field }) => (
              <Input
                label="Job title"
                value={field.value}
                onChange={(e) => {
                  if (errors?.jobTitle) {
                    setError('jobTitle', { type: 'required', message: '' });
                  }
                  field.onChange(e);
                }}
                onBlur={(event) => {
                  const { value } = event.target;
                  if (!value.trim()) {
                    setValue('jobTitle', '');
                    setError('jobTitle', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors.jobTitle?.message}
                className="addEmployment__field--wide"
              />
            )}
          />
          <Controller
            control={control}
            name="department"
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                options={employeeDepartments}
                label="Department"
                value={field.value}
                onChange={field.onChange}
                error={errors.department?.message}
                className="addEmployment__field--wide"
                parentRender
              />
            )}
          />
          <Controller
            control={control}
            name="startDate"
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <DatePicker
                label="Start date"
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  const formatted = value ? moment(value).format(DEFAULT_DATE_FORMAT) : null;
                  field.onChange(formatted);
                }}
                error={errors.startDate?.message}
                format={DEFAULT_UI_DATE_FORMAT}
                disabledDate={(currentDate) => {
                  const tooEarly = currentDate.isBefore(minInitAvailableDate, 'days');
                  const tooLate = endDate
                    ? currentDate.isAfter(moment(endDate), 'days')
                    : currentDate.isAfter(maxInitAvailableDate, 'days');
                  return tooEarly || tooLate;
                }}
              />
            )}
          />
          <Controller
            control={control}
            name="endDate"
            render={({ field }) => (
              <DatePicker
                label="End date"
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  const formatted = value ? moment(value).format(DEFAULT_DATE_FORMAT) : null;
                  field.onChange(formatted);
                }}
                format={DEFAULT_UI_DATE_FORMAT}
                disabledDate={(currentDate) => {
                  const tooLate = currentDate.isAfter(maxInitAvailableDate, 'days');
                  const tooEarly = startDate
                    ? currentDate.isBefore(moment(startDate), 'days')
                    : currentDate.isBefore(minInitAvailableDate, 'days');
                  return tooEarly || tooLate;
                }}
                allowClear
              />
            )}
          />
          <Controller
            name="salaryType"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <RadioGroup
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                }}
                options={employeesSalaryTypes}
                optionType="button"
                label="Salary type"
                error={errors.salaryType?.message}
                className="addEmployment__field--wide"
              />
            )}
          />
          <Controller
            control={control}
            name="salaryValue"
            render={({ field }) => (
              <DecimalInputNumber
                label="Salary value"
                value={field.value}
                onChange={field.onChange}
                max={maxEightDigitsBeforeDot}
              />
            )}
          />
          <Controller
            control={control}
            name="annualLeaveEntitlement"
            render={({ field }) => (
              <DecimalInputNumber
                label="Annual leave entitlement"
                value={field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                max={maxTwoDigitsBeforeDot}
              />
            )}
          />
          <ButtonActions
            cancelLabel="Cancel"
            cancelClick={cancelHandle}
            createLabel={editMode ? 'Save' : 'Add record'}
            createType="submit"
            isLoading={isFetching}
            className="addEmployment__field--wide"
          />
        </form>
      </Modal>
    </>
  );
};

export default AddEmployment;
