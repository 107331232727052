import React, { useEffect, useState } from 'react';
import PartCategories from './components/PartCategories';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  setPartSubcategoriesFilters,
  setStockPartAffectedCategories,
  setStockPartAffectedCategoriesFilter, setStockPartSubcategories,
} from '../../store/slices/stockSlice';
import CreateEditPartCategoryModal from './components/modals/CreateEditPartCategoryModal';
import CreateEditPartSubcategoryModal from './components/modals/CreateEditPartSubcategoryModal';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import './PartCategoriesContainer.scss';
import AffectedPartCategoriesModal from './components/modals/AffectedPartCategoriesModal';
import { getPartAffectedCategoryOrderingThunk, getPartSubcategoryOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import {
  stockPartSubcategoriesFiltersSelector,
} from '../../store/selectors/stockSelectors';
import {
  deletePartCategoryThunk,
  deletePartSubcategoryThunk,
} from '../../store/thunks/stock/categories/partCategoriesThunks';
import { initDataList, initPaging, initSmallPaging } from '../../core/types/coreTypes';
import { getPartCategoriesThunk } from '../../store/thunks/shared/sharedThunks';

const PartCategoriesContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const { categoryIds } = useAppSelector(stockPartSubcategoriesFiltersSelector);
  const [isCreateEditCategory, setIsCreateEditCategory] = useState(false);
  const [categoryEditMode, setCategoryEditMode] = useState(false);
  const [isCreateEditSubcategory, setIsCreateEditSubcategory] = useState(false);
  const [subcategoryEditMode, setSubcategoryEditMode] = useState(false);
  const [subcategoryIdToEdit, setSubcategoryIdToEdit] = useState<number | null>(null);
  const [isAffectedModal, setIsAffectedModal] = useState<{categoryId?: number, subcategoryId?: number}>({});
  const [isDeleteModal, setIsDeleteModal] = useState<{categoryId?: number, subcategoryId?: number}>({});

  const categoryId = categoryIds?.[0];

  useEffect(() => {
    dispatch(getPartCategoriesThunk({ filters: undefined }));
    dispatch(getPartSubcategoryOrderingThunk());
    dispatch(getPartAffectedCategoryOrderingThunk());

    return () => {
      dispatch(setStockPartSubcategories(initDataList));
      dispatch(setPartSubcategoriesFilters(initPaging));
    };
    // eslint-disable-next-line
  }, []);

  const confirmDeleteHandle = () => {
    if (isDeleteModal.categoryId) {
      dispatch(deletePartCategoryThunk({
        id: isDeleteModal.categoryId,
        onClose: () => setIsDeleteModal({}),
      }));
    } else if (isDeleteModal.subcategoryId) {
      dispatch(deletePartSubcategoryThunk({
        id: isDeleteModal.subcategoryId,
        closeModal: () => setIsDeleteModal({}),
      }));
    }
  };

  const openEditCategoryModal = () => {
    setCategoryEditMode(true);
    setIsCreateEditCategory(true);
  };
  const openEditSubcategoryModal = (id: number) => {
    setSubcategoryEditMode(true);
    setSubcategoryIdToEdit(id);
    setIsCreateEditSubcategory(true);
  };

  return (
    <>
      <CreateEditPartCategoryModal
        visible={isCreateEditCategory}
        categoryId={categoryId}
        onCancel={() => {
          setCategoryEditMode(false);
          setIsCreateEditCategory(false);
        }}
        editMode={categoryEditMode}
      />
      <CreateEditPartSubcategoryModal
        visible={isCreateEditSubcategory}
        onCancel={() => {
          setSubcategoryEditMode(false);
          setSubcategoryIdToEdit(null);
          setIsCreateEditSubcategory(false);
        }}
        editMode={subcategoryEditMode}
        subcategoryIdToEdit={subcategoryIdToEdit}
        categoryId={categoryId}
      />
      <ConfirmationModal
        isVisible={Boolean(isDeleteModal.categoryId) || Boolean(isDeleteModal.subcategoryId)}
        onCancel={() => setIsDeleteModal({})}
        confirmHandler={confirmDeleteHandle}
      />
      <AffectedPartCategoriesModal
        visible={Boolean(isAffectedModal.categoryId) || Boolean(isAffectedModal.subcategoryId)}
        categoryId={isAffectedModal.categoryId}
        subcategoryId={isAffectedModal.subcategoryId}
        onCancel={() => {
          setIsAffectedModal({});
          dispatch(setStockPartAffectedCategories(initDataList));
          dispatch(setStockPartAffectedCategoriesFilter(initSmallPaging));
        }}
      />
      <PartCategories
        setIsCreateCategory={setIsCreateEditCategory}
        setIsCreateSubcategory={setIsCreateEditSubcategory}
        setIsDeleteModal={setIsDeleteModal}
        setIsAffectedModal={setIsAffectedModal}
        openEditCategoryModal={openEditCategoryModal}
        openEditSubcategoryModal={openEditSubcategoryModal}
      />
    </>
  );
};

export default PartCategoriesContainer;
