import React, { useEffect, useState } from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import Input from '../../../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { createNewSkillCategoryThunk, editSkillCategoryThunk } from '../../../../store/thunks/employee/skillsThunks';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { skillsCategoriesSelector } from '../../../../store/selectors/employeesSelector';
import { isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';

type CreateCategoryModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  editMode?: boolean,
  categoryToChange?: number,
}

const CreateCategoryModal: React.FC<CreateCategoryModalProps> = ({
  onCancel, isVisible, editMode, categoryToChange,
}) => {
  const dispatch = useAppDispatch();
  const skillsCategories = useAppSelector(skillsCategoriesSelector);
  const isFetching = useAppSelector(isGlobalLoadingSelector);
  const [name, setName] = useState<string | undefined>(undefined);
  const [err, setErr] = useState<string | undefined>(undefined);
  const setNameHandle = (v: string) => {
    if (!v.trim()) {
      setErr(ErrorsEnum.REQUIRED);
    } else if (v.length > 200) {
      setErr(maxLengthMessageHandle(200));
    } else err && setErr(undefined);
    setName(v);
  };

  const cancelHandle = () => {
    setName(undefined);
    err && setErr(undefined);
    onCancel();
  };

  const createHandle = () => {
    if (!name) {
      setErr(ErrorsEnum.REQUIRED);
    } else {
      if (!err) {
        const data = {
          name,
          closeModal: () => cancelHandle(),
        };
        if (editMode && categoryToChange) {
          dispatch(editSkillCategoryThunk({ ...data, id: categoryToChange }));
        } else {
          dispatch(createNewSkillCategoryThunk({ ...data }));
        }
      }
    }
  };

  useEffect(() => {
    if (editMode && categoryToChange) {
      const current = skillsCategories.find((el) => el.id === categoryToChange);
      current && setName(current.name || '');
    }
  }, [editMode, categoryToChange, skillsCategories, isVisible]);
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={editMode ? 'Edit skill category' : 'Create skill category'}
    >
      <Input
        label="Name"
        value={name}
        onBlur={(e) => {
          const v = e.target.value;
          if (!v.trim()) {
            setErr(ErrorsEnum.REQUIRED);
            setName(undefined);
          }
        }}
        onChange={(e) => {
          setNameHandle(e.target.value);
        }}
        error={err}
        className="createCategoryInput"
      />
      <ButtonActions
        createLabel={editMode ? 'Save' : 'Create'}
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createClick={createHandle}
        isLoading={isFetching}
      />
    </Modal>
  );
};

export default CreateCategoryModal;
