export enum PurchaseUrlParamsNames {
  PAGE = 'page',
  TAB_TYPE = 'tab_type',
  PURCHASE_STATUS = 'purchase_status',
}

export enum PurchaseOrderStatusUiNames {
  New = 'New',
  Finalised = 'Finalised',
  SentToSupplier = 'Sent to supplier',
  PartiallyReceived = 'Partially received',
  Received = 'Received',
  Completed = 'Completed'
}

export enum PurchaseOrderAllStatusEnum { ALL = 100 }

export enum StockTabParamEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export const StockTabParams = [StockTabParamEnum.ACTIVE, StockTabParamEnum.ARCHIVED];

export enum PurchaseOrderFailReasonUiNames {
  Faulty = 'Faulty',
  NotReceivedFromSupplier = 'Not received from supplier',
  WrongPartCodeModification = 'Wrong part code/modification',
  Cancelled = 'Cancelled'
}

export enum PurchaseOrderVatUiNames {
  ZeroPercent = '0%',
  FivePercent = '5%',
  TwentyPercent = '20%',
}
