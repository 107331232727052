import React, { useEffect, useRef, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { debounce } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../core/components/modal/Modal';
import { AddressSuggestionDetailsDto, VoidFunctionType } from '../../../core/types/coreTypes';
import Input from '../../../core/components/input/Input';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { EMAIL_REGEX, NAME_REGEX, PHONE_FAX_REGEX } from '../../../core/utils/regex';
import { RequisiteDto } from '../../types/commonTypes';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import './RequisiteSection.scss';
import { requisiteHelper } from '../../utils/requisiteHelper';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import { initRequisite } from '../../utils/data';
import {
  getCustomerBillingShippingRequisiteThunk,
  getCustomersDictionaryThunk, getSupplierBillingShippingRequisiteThunk,
  getSuppliersDictionaryThunk,
} from '../../../store/thunks/shared/sharedThunks';
import Select from '../../../core/components/select/Select';
import {
  billingShippingRequisitesSelector,
  customersDictionaryLookupSelector,
  supplierDictionaryLookupSelector,
} from '../../../store/selectors/sharedSelectors';
import Spinner from '../../../core/components/spinner/Spinner';
import Button from '../../../core/components/button/Button';
import { capitalizeFirstLetter } from '../../../core/utils/capitalizeFirstLetter';
import { setRequisiteData } from '../../../store/slices/sharedSlice';
import AddressSuggestionsModal from '../address-suggestions-modal/AddressSuggestionsModal';

type RequisiteModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  requisite: RequisiteDto | null;
  isOptionalFields?: boolean,
  submitHandle: (requisite: RequisiteDto, isManual: boolean, selectedEntityId?: number) => void,
  statusDisabled?: boolean,
  entityId?: number,
  entitySourceType?: 'supplier' | 'customer',
  isShipping?: boolean,
  isManual?: boolean,
}

const RequisiteModal: React.FC<RequisiteModalProps> = ({
  isVisible,
  onCancel,
  requisite,
  isOptionalFields,
  submitHandle,
  statusDisabled,
  entityId,
  entitySourceType,
  isShipping = false,
  isManual = false,
}) => {
  const dispatch = useAppDispatch();
  const suppliers = useAppSelector(supplierDictionaryLookupSelector);
  const customers = useAppSelector(customersDictionaryLookupSelector);
  const requisiteData = useAppSelector(billingShippingRequisitesSelector);
  const [loadingData, setLoadingData] = useState(false);
  const [selectedEntityId, setSelectedEntityId] = useState<number | undefined>(undefined);
  const [manualInput, setManualInput] = useState(false);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const disabledInput = !!entitySourceType && !manualInput;

  const [searchValue, setSearchValue] = useState('');

  const {
    handleSubmit, control, setValue, reset, clearErrors,
  } = useForm<RequisiteDto>({
    defaultValues: initRequisite,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const [suggestionModal, setSuggestionModal] = useState(false);

  const handleSelectSuggested = (address: AddressSuggestionDetailsDto) => {
    setValue('addressLine1', address.addressLine1);
    setValue('addressLine2', address.addressLine2);
    setValue('companyName', address.companyName);
    setValue('cityTown', address.cityTown);
    setValue('region', address.region);
    setValue('postalCode', address.postalCode);
    setSuggestionModal(false);
  };

  const getEntityRequisite = (id: number) => {
    entitySourceType === 'customer' && dispatch(getCustomerBillingShippingRequisiteThunk({ id, isShipping, setLoadingData }));
    entitySourceType === 'supplier' && dispatch(getSupplierBillingShippingRequisiteThunk({ id, setLoadingData }));
  };

  useEffect(() => {
    if (isVisible && !!entitySourceType) {
      entitySourceType === 'customer' && dispatch(getCustomersDictionaryThunk({ filters: { keyword: undefined } }));
      entitySourceType === 'supplier' && dispatch(getSuppliersDictionaryThunk({ filters: { keyword: undefined } }));
      setManualInput(isManual);
      setSelectedEntityId(entityId);

      if (isShipping && !isManual && entityId) {
        getEntityRequisite(entityId);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  useEffect(() => {
    if (isVisible && !!entitySourceType) {
      const keys = Object.keys(requisiteData) as (keyof RequisiteDto)[];
      keys.forEach((key) => {
        setValue(key, requisiteData[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requisiteData]);

  useEffect(() => {
    if (isVisible && requisite) {
      const keys = Object.keys(requisite) as (keyof RequisiteDto)[];
      keys.forEach((key) => {
        setValue(key, requisite[key]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible, requisite]);

  const handleSearch = (keyword: string | undefined) => {
    entitySourceType === 'customer' && dispatch(getCustomersDictionaryThunk({ filters: { keyword } }));
    entitySourceType === 'supplier' && dispatch(getSuppliersDictionaryThunk({ filters: { keyword } }));
  };
  const debouncedSearch = useRef(debounce(handleSearch, 1000)).current;

  const onSubmit = (values: RequisiteDto) => {
    const trimmedValues = requisiteHelper(values);
    submitHandle(trimmedValues, manualInput, selectedEntityId);
  };

  const buttons = [
    { label: `${capitalizeFirstLetter(entitySourceType || '')} record`, isManual: false },
    { label: 'Manual input', isManual: true },
  ];

  const fields = [
    {
      value: 'firstName',
      label: 'First name',
      rules: {
        maxLength: { value: 100, message: maxLengthMessageHandle(100) },
        pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
        required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
      },
    },
    {
      value: 'lastName',
      label: 'Last name',
      rules: {
        maxLength: { value: 100, message: maxLengthMessageHandle(100) },
        pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
        required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
      },
    },
    {
      value: 'email',
      label: 'Email',
      rules: {
        required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
        pattern: { value: EMAIL_REGEX, message: 'Enter a valid email address' },
      },
    },
    {
      value: 'telephone',
      label: 'Telephone',
      rules: {
        maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
        minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
        required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
        pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
      },
    },
    {
      value: 'mobile',
      label: 'Mobile',
      rules: {
        maxLength: { value: 30, message: ErrorsEnum.INVALID_MOBILE },
        minLength: { value: 7, message: ErrorsEnum.INVALID_MOBILE },
        pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_MOBILE },
      },
    },
  ];

  return (
    <>
      <AddressSuggestionsModal
        visible={suggestionModal}
        onClose={() => setSuggestionModal(false)}
        handleSelectSuggested={handleSelectSuggested}
      />
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        title={isShipping ? 'Shipping details' : 'Billing details'}
        width={700}
        afterClose={() => {
          reset();
          setManualInput(false);
          setSelectedEntityId(undefined);
          dispatch(setRequisiteData(initRequisite));
        }}
      >
        {!!entitySourceType && <>
          <Select
            options={entitySourceType === 'supplier' ? suppliers : customers}
            label={capitalizeFirstLetter(entitySourceType)}
            showSearch
            onSearch={(v) => {
              setSearchValue(v);
              if (!v.trim().length) {
                debouncedSearch.cancel();
                handleSearch(undefined);
              } else {
                debouncedSearch(v);
              }
            }}
            onChange={(v) => {
              clearErrors();
              setSelectedEntityId(+v);
              getEntityRequisite(+v);
            }}
            value={selectedEntityId}
            searchValue={searchValue}
            parentRender
          />
          <div className="billingModalSource">
            <span>
              Data source
            </span>
            <div>
              {buttons.map(({
                label,
                isManual,
              }) => (
                <Button
                  key={label}
                  label={label}
                  onClick={() => {
                    setManualInput(isManual);
                    clearErrors();
                    !isManual && selectedEntityId && getEntityRequisite(selectedEntityId);
                  }}
                  isActivated={manualInput === isManual}
                  designType="light-inverse"
                  offsetRight={!isManual}
                />
              ))}
            </div>
          </div>
        </>}
        <form className="billingModal" onSubmit={handleSubmit(onSubmit)}>
          <div className="billingModal__inner">
            {loadingData && <Spinner global={false} />}
            {fields.map((el, i) => <Controller
              key={i}
              name={`${el.value}` as keyof RequisiteDto}
              control={control}
              rules={{ ...el.rules }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val.trim()) {
                      field.onChange(val);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  label={el.label}
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-ignore
                  error={errors[el.value]?.message}
                  disabled={disabledInput}
                />
              )}
            />)}
            <Controller
              name="companyName"
              control={control}
              rules={{
                required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
                maxLength: {
                  value: 200,
                  message: maxLengthMessageHandle(200),
                },
              }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val.trim()) {
                      field.onChange(val);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  label="Company name"
                  error={errors.companyName?.message}
                  disabled={disabledInput}
                />
              )}
            />
            <Controller
              name="addressLine1"
              control={control}
              rules={{
                required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
                maxLength: {
                  value: 200,
                  message: maxLengthMessageHandle(200),
                },
              }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val.trim()) {
                      field.onChange(val);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  label="Address line 1"
                  error={errors.addressLine1?.message}
                  disabled={disabledInput}
                />
              )}
            />
            <Controller
              name="addressLine2"
              control={control}
              rules={{
                maxLength: {
                  value: 200,
                  message: maxLengthMessageHandle(200),
                },
              }}
              render={({ field }) => (
                <Input
                  value={field.value}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (val.trim()) {
                      field.onChange(val);
                    } else {
                      field.onChange(null);
                    }
                  }}
                  label="Address line 2"
                  error={errors.addressLine2?.message}
                  disabled={disabledInput}
                />
              )}
            />
            <div className="billingModal__city">
              <Controller
                name="cityTown"
                control={control}
                rules={{
                  required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
                  maxLength: {
                    value: 100,
                    message: maxLengthMessageHandle(100),
                  },
                }}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.trim()) {
                        field.onChange(val);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    label="City/town"
                    error={errors.cityTown?.message}
                    disabled={disabledInput}
                  />
                )}
              />
              <Controller
                name="region"
                control={control}
                rules={{
                  maxLength: {
                    value: 50,
                    message: maxLengthMessageHandle(50),
                  },
                }}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.trim()) {
                        field.onChange(val);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    label="Region"
                    error={errors.region?.message}
                    className="billingModal__inner__section-city-field"
                    disabled={disabledInput}
                  />
                )}
              />
            </div>
            <div className="billingModal__postal">
              <Controller
                name="postalCode"
                control={control}
                rules={{
                  required: (!isOptionalFields || statusDisabled) && ErrorsEnum.REQUIRED,
                  maxLength: {
                    value: 8,
                    message: rangeLengthMessageHandle([5, 8]),
                  },
                  minLength: {
                    value: 5,
                    message: rangeLengthMessageHandle([5, 8]),
                  },
                }}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.trim()) {
                        field.onChange(val);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    label="Postal code"
                    error={errors.postalCode?.message}
                    disabled={disabledInput}
                  />
                )}
              />
              <Button
                icon={<FontAwesomeIcon icon={faSearch} />}
                onClick={() => setSuggestionModal(true)}
                disabled={disabledInput}
              />
            </div>
          </div>
          <ButtonActions
            cancelLabel="Cancel"
            createLabel="Save"
            createType="submit"
            disabledCreate={loadingData}
            cancelClick={onCancel}
            isLoading={isGlobalLoading}
            offsetTop
          />
        </form>
      </Modal>
    </>
  );
};

export default RequisiteModal;
