import React from 'react';
import classnames from 'classnames';
import Button from '../button/Button';
import Input from '../input/Input';
import {
  ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, VoidFunctionType,
} from '../../types/coreTypes';
import './InputWithButton.scss';

type InputWithButtonsProps = {
  value?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  placeholder?: string;
  error?: string;
  onClick?: VoidFunctionType;
  icon?: React.ReactNode;
  disabled?: boolean,
  buttonDisabled?: boolean;
  className?: string;
  visibleButton?: boolean,
  offsetBottom?: boolean,
}

const InputWithButton: React.FC<InputWithButtonsProps> = ({
  value,
  onChange,
  onBlur,
  onPressEnter,
  placeholder,
  error,
  onClick,
  icon,
  disabled,
  buttonDisabled,
  className,
  visibleButton = true,
  offsetBottom,
}) => {
  return (
    <div className={classnames(className, 'inputWithButton', { 'inputWithButton--offset-bottom': offsetBottom })}>
      <Input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        error={error}
        onPressEnter={onPressEnter}
        disabled={disabled}
        className={classnames('inputWithButton__input', { 'inputWithButton__input--hidden-button': !visibleButton })}
      />
      {visibleButton && <Button
        icon={icon}
        onClick={onClick}
        disabled={buttonDisabled || disabled}
        className="inputWithButton__button"
      />}
    </div>
  );
};

export default InputWithButton;
