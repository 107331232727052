import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  supplierSpendFiltersDateSelector,
  supplierSpendFiltersSelector,
  supplierSpendGlobalFiltersSelector, supplierSpendFiltersTreeSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { getSuppliersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { setReportIsVisible, setSupplierSpendReportData, setSupplierSpendReportFilters } from '../../../../../store/slices/reportsSlice';
import { DateRangeTableFilterType, PickerValues } from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { useSupplierSpendReportDefaultOrdering } from '../../../../hooks/useSupplierSpendReportDefaultOrdering';
import { getSupplierSpendReportExcelThunk, getSupplierSpendReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { initSupplierSpendData } from '../../../reports-content/purchasing/supplier-spend/utils/data';

const SupplierSpendGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(supplierSpendFiltersSelector);
  const checkboxFilters = useAppSelector(supplierSpendGlobalFiltersSelector);
  const treeFilters = useAppSelector(supplierSpendFiltersTreeSelector);
  const datePickerFilters = useAppSelector(supplierSpendFiltersDateSelector);

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { purchaseOrderDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const isSelectedGlobalFilters = useMemo(() => {
    const {
      suppliers, subcategories, purchaseOrderDate,
    } = filters;
    const hasSelectedCheckboxes = [suppliers, subcategories].some((el) => !!el && !!el.length);
    const hasSelectedDatepicker = purchaseOrderDate && (purchaseOrderDate.min || purchaseOrderDate.max);
    return hasSelectedCheckboxes || hasSelectedDatepicker;
    // eslint-disable-next-line
  }, [filters.suppliers, filters.subcategories, filters.purchaseOrderDate]);

  const defaultPagingAndOrdering = useSupplierSpendReportDefaultOrdering();

  useEffect(() => {
    dispatch(getSuppliersDictionaryThunk({ filters: undefined }));

    return () => {
      dispatch(setSupplierSpendReportFilters(defaultPagingAndOrdering));
      dispatch(setSupplierSpendReportData(initSupplierSpendData));
    };
    // eslint-disable-next-line
  }, []);

  const pickerHandle = (field: string, values?: PickerValues) => {
    dispatch(setSupplierSpendReportFilters({
      ...filters,
      purchaseOrderNumber: undefined,
      costNet: undefined,
      costGross: undefined,
      [field]: values,
    }));
    dispatch(setSupplierSpendReportData(initSupplierSpendData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setSupplierSpendReportFilters({
      ...filters,
      purchaseOrderNumber: undefined,
      costNet: undefined,
      costGross: undefined,
      [field]: values,
    }));
    dispatch(setSupplierSpendReportData(initSupplierSpendData));
    dispatch(setReportIsVisible(false));
  };

  const treeChangeHandle = (checkedKeys: React.Key[]) => {
    const checkedSubcategories = checkedKeys.filter((el) => !el.toString().includes('category'));
    const values = checkedSubcategories.map((s) => +s);
    getFilteredResHandle(values, 'subcategories');
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setSupplierSpendReportFilters(defaultPagingAndOrdering));
    dispatch(setSupplierSpendReportData(initSupplierSpendData));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const { order } = defaultPagingAndOrdering;
    const { suppliers, subcategories, purchaseOrderDate } = filters;
    dispatch(getSupplierSpendReportExcelThunk({
      filters: {
        suppliers, subcategories, purchaseOrderDate, order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        treeFilters={treeFilters}
        treeChangeHandle={treeChangeHandle}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getSupplierSpendReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default SupplierSpendGlobalFilters;
