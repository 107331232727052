import React from 'react';
import Segmented from '../../../../core/components/segmented/Segmented';
import Selectable from '../../../../core/components/selectable/Selectable';
import { CustomerContentEnum } from '../../enums/customerContentEnum';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { useAppSelector } from '../../../../store/hooks';
import { customerDetailsCardProperties } from '../../../../store/selectors/customersSelectors';
import { useCustomerCardClickHandle } from '../../utils/customerCardClickHandle';

type CustomerStaticSectionProps = {
  setModeParam: StringFunctionType,
  mode: string | null,
  allowedOptions: { label: string, value: CustomerContentEnum }[],
}

const CustomerStaticSection: React.FC<CustomerStaticSectionProps> = ({
  setModeParam, mode, allowedOptions,
}) => {
  const { cardClickHandle } = useCustomerCardClickHandle();
  const customerProperties = useAppSelector(customerDetailsCardProperties);
  const { windowWidth } = useScreenWitdh();

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={allowedOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className="tabs-and-props desktop-sticky-filters">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedOptions}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="tabs-and-props__tabs"
          />
        )}
        {mode === CustomerContentEnum.CUSTOMER_DETAILS && <ul className="tabs-and-props__props tabs-and-props__props--4">
          {customerProperties.map((el, i) => <li className="tabs-and-props__props-item">
            <PrivateLineLink
              permission={el.permission}
              notLink={el.value === 0}
              to={el.to}
              key={i}
              className="tabs-and-props__text"
              customClick={() => cardClickHandle(el.permission, el.filters)}
            >
              {`${el.label} - ${el.value}`}
            </PrivateLineLink>
          </li>)}
        </ul>}
      </div>
    </>
  );
};

export default CustomerStaticSection;
