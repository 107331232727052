import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection
  from '../../../../../../core/components/table-components/EmptyTableSection';
import AddPartCells from './AddPartCells';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import { PartDtoType, StockFiltersType } from '../../../../../../stock/search/types/stockSearchTypes';

type AddPartBodyProps = {
  stockParts: PartDtoType[],
  checkedKeys: number[],
  checkRow: NumberFunctionType,
  filters: StockFiltersType,
}

const AddPartBody: React.FC<AddPartBodyProps> = ({
  stockParts, checkedKeys, checkRow, filters,
}) => {
  return (
    <Tbody>
      {stockParts.length === 0
        ? <EmptyTableSection text={!filters.keyword ? 'Please enter a search parameter' : 'No records'} colSpan={6} />
        : <AddPartCells
          stockParts={stockParts}
          checkedKeys={checkedKeys}
          checkRow={checkRow}
        />}
    </Tbody>
  );
};

export default AddPartBody;
