import React from 'react';
import moment from 'moment/moment';

type WorkCalendarDateColPops = {
  data?: string,
  isLoading?: boolean
}
const WorkCalendarDateCol: React.FC<WorkCalendarDateColPops> = ({ data, isLoading }) => {
  return (
    <div className="employeeWorkCalendar__col-head">
      {isLoading
        ? <>
          <div className="workCalendarHeader__date-skeleton skeleton-box" />
          <div className="workCalendarHeader__day-skeleton skeleton-box" />
        </>
        : <>
          <b className="workCalendarHeader__date">{moment(data).format('DD MMM YY')}</b>
          <span className="workCalendarHeader__day">{moment(data).format('ddd')}</span>
        </>}
    </div>
  );
};

export default WorkCalendarDateCol;
