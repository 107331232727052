import React, { useEffect, useState } from 'react';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { initDataList, VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { orderEventSourcesValuesSelector } from '../../../../store/selectors/coreSelectors';
import { getOrderEventTypesThunk } from '../../../../store/thunks/core/coreEventTypesThunks';
import Pagination from '../../../../core/components/pagination/Pagination';
import {
  setSalesOrderTimeline, setSalesOrderTimelineFilters,
} from '../../../../store/slices/invoicingSlice';
import Spinner from '../../../../core/components/spinner/Spinner';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';
import Timeline from '../../../../core/components/timeline/Timeline';
import Modal from '../../../../core/components/modal/Modal';
import { initExtendedTimelineFilters } from '../../../../common/types/commonTypes';
import {
  salesOrderTimelineFiltersSelector,
  salesOrderTimelineSelector,
} from '../../../../store/selectors/invoicingSelectors';
import ActivityLabel from '../../../../common/components/activity-events/ActivityLabel';
import { getSalesOrderTimelineThunk } from '../../../../store/thunks/invoicing/salesThunks';
import OrderEvent
  from '../../../../orders/orders-view-page/components/content/details/order-details-description/OrderEvent';
import { orderEventTypesSelector } from '../../../../store/selectors/coreEventTypesSelectors';

type SalesOrderActivityLogModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
}

const SalesOrderActivityLogModal: React.FC<SalesOrderActivityLogModalProps> = ({ onCancel, isVisible }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const timelineFilters = useAppSelector(salesOrderTimelineFiltersSelector);
  const { items, totalCount } = useAppSelector(salesOrderTimelineSelector);
  const { invoicingSource } = useAppSelector(orderEventSourcesValuesSelector);

  const eventTypes = useAppSelector(orderEventTypesSelector);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (isVisible && !eventTypes.length) {
      dispatch(getOrderEventTypesThunk());
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    if (id && isVisible && invoicingSource) {
      dispatch(getSalesOrderTimelineThunk({
        orderId: +id, filters: timelineFilters, source: invoicingSource, setIsLoading,
      }));
    }
    // eslint-disable-next-line
    }, [id, isVisible, invoicingSource, timelineFilters.mainOnly, timelineFilters.page]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <OrderEvent item={el} />,
  }));

  const cancelHandle = () => {
    onCancel();
    dispatch(setSalesOrderTimeline(initDataList));
    dispatch(setSalesOrderTimelineFilters({ ...initExtendedTimelineFilters, mainOnly: false }));
  };
  return (
    <Modal visible={isVisible} title="Activity" onCancel={cancelHandle} width={800} className="timeline-modal">
      <div className="timeline-modal__actions">
        <Pagination
          currentPage={timelineFilters.page}
          pageSize={timelineFilters.pageSize}
          onChange={(page) => {
            dispatch(setSalesOrderTimelineFilters({
              ...timelineFilters,
              page,
            }));
          }}
          totalItems={totalCount}
          showSizeChanger={false}
          showTotal={false}
        />
      </div>
      <div className="timeline-modal__timeline-wrapper">
        {isLoading && <Spinner global={false} />}
        {!totalCount && <EmptyContentList icon={faWaveSquare} text="No data" size="sm" offsetTop={false} />}
        <Timeline timelineList={timelineList} />
      </div>
    </Modal>
  );
};

export default SalesOrderActivityLogModal;
