import React, { useEffect } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import ContactLine from './ContactLine';
import Button from '../../../core/components/button/Button';
import { DataListType, VoidFunctionType } from '../../../core/types/coreTypes';
import { CustomerContactFiltersType } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { ContactDto } from '../../types/commonTypes';
import { useSelectAll } from '../../../core/hooks/useSelectAll';

type ExistedContactsListProps = {
  onCancel: VoidFunctionType,
  existedContacts: DataListType<ContactDto[], { allItemIds: number[] }>,
  getExistingContacts: VoidFunctionType,
  existingPaging: CustomerContactFiltersType,
  addSelectedContacts: (ids: number[]) => void,
  setExistedContactsPaging: (filters: CustomerContactFiltersType) => void,
  isLoading?: boolean,
}

const ExistedContactsList: React.FC<ExistedContactsListProps> = ({
  onCancel, existedContacts, getExistingContacts, existingPaging, addSelectedContacts, setExistedContactsPaging, isLoading,
}) => {
  const {
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(existedContacts.allItemIds);

  useEffect(() => {
    getExistingContacts();
    // eslint-disable-next-line
  }, [existingPaging.page]);

  const loadMore = () => {
    const newValues = { ...existingPaging, page: existingPaging.page + 1 };
    setExistedContactsPaging(newValues);
  };
  return (
    <div className="existedList">
      <div className="existedList__actions">
        <Button label="Select all" onClick={checkAllHandle} offsetRight />
        <Button label="Deselect all" onClick={uncheckAllHandle} />
      </div>
      <div id="scrollableExistedDiv" className="existedList__scrollableDiv">
        <InfiniteScroll
          dataLength={existedContacts.items.length}
          next={loadMore}
          hasMore={existingPaging.page < existedContacts.pages}
          loader={<div>Loading...</div>}
          scrollableTarget="scrollableExistedDiv"
        >
          {existedContacts.totalCount > 0
            ? <ul className="lines-list">
              {existedContacts.items?.map((line) => (
                <li key={line.id} className="lines-list__item">
                  <ContactLine
                    line={line}
                    checkedKeys={checkedKeys}
                    checkLine={checkRowHandle}
                  />
                </li>
              ))}
            </ul>
            : <span>No records</span>}
        </InfiniteScroll>
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={onCancel}
        disabledCreate={checkedKeys.length === 0}
        createType="button"
        createLabel={`Add selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
        createClick={() => addSelectedContacts(checkedKeys)}
        isLoading={isLoading}
      />
    </div>
  );
};

export default ExistedContactsList;
