import React, { useState } from 'react';
import Button from '../../../../core/components/button/Button';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import PartRequestChangesModal from './PartRequestChangesModal';

type PartRequestLineCreationEventProps = {
  getChangesHandle: VoidFunctionType,
}

const PartRequestLineCreationEvent: React.FC<PartRequestLineCreationEventProps> = ({ getChangesHandle }) => {
  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <PartRequestChangesModal
        visible={isModal}
        onCancel={() => setIsModal(false)}
        getChangesHandle={getChangesHandle}
      />
      Part request lines were updated
      {' '}
      <Button
        label="(see details)"
        onClick={() => setIsModal(true)}
        type="text"
      />
    </>
  );
};

export default PartRequestLineCreationEvent;
