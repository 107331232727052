import React from 'react';
import { toOptionalFixed } from '../../../../../../common/utils/formatUtils';

type JobWorklogsEventProps = {
  assignee?: string,
  oldTimeSpent?: string | null,
  newTimeSpent?: string | null,
}

const JobWorklogsEvent: React.FC<JobWorklogsEventProps> = ({ assignee, oldTimeSpent, newTimeSpent }) => {
  const minutesToHours = (minutes: string) => toOptionalFixed((+minutes / 60), 2);
  return (
    <>
      Time spent by
      {' '}
      <b>{assignee}</b>
      {' '}
      was updated:
      {' '}
      {!!oldTimeSpent && <s>{`${minutesToHours(oldTimeSpent)} h`}</s>}
      {!!newTimeSpent && <b>{` ${minutesToHours(newTimeSpent)} h`}</b>}
    </>
  );
};

export default JobWorklogsEvent;
