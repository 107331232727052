import React, { useEffect, useState } from 'react';
import { faTools } from '@fortawesome/free-solid-svg-icons';
import { BooleanFunctionType } from '../../../../../../core/types/coreTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import Button from '../../../../../../core/components/button/Button';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../core/components/table-components/Tr';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { EmployeeActivityEventType, SkillEventDataType } from '../../../../types/employeeViewTypes';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import { useAppSelector } from '../../../../../../store/hooks';
import { employeesSkillLevelsSelector } from '../../../../../../store/selectors/employeesSelector';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import './SkillsUpdateModal.scss';
import { skillsModalColumns } from '../../../../../skill-list/utils/dataUtil';

type SkillsUpdateModalProps = {
  isVisible: boolean,
  setIsVisible: BooleanFunctionType,
  item: EmployeeActivityEventType;
}

type SkillEventLineType = {
  number: number,
  categoryName: string,
  name: string,
  level: string | undefined,
}

const SkillsUpdateModal: React.FC<SkillsUpdateModalProps> = ({ isVisible, setIsVisible, item }) => {
  const [mode, setMode] = useState<string>('after');
  const [lines, setLines] = useState<SkillEventLineType[]>([]);
  const columns = skillsModalColumns.map(({ dataIndex, title }) => ({ dataIndex, title }));
  const skillLevels = useAppSelector(employeesSkillLevelsSelector);

  const reformListHandle = (parsedStr: SkillEventDataType[]) => {
    return parsedStr.length > 0
      ? parsedStr.map((el, i) => ({
        number: i + 1,
        categoryName: el.CategoryName,
        name: el.Name,
        level: skillLevels.find((lev) => lev.value === el.SkillLevel)?.label,
      }))
      : [];
  };

  useEffect(() => {
    if (isVisible) {
      if (mode === 'before') {
        const parsedStr: SkillEventDataType[] = JSON.parse(item.oldValue as string);
        const listBefore = reformListHandle(parsedStr);
        setLines(listBefore);
      } else {
        const parsedStr = JSON.parse(item.newValue as string);
        const listAfter = reformListHandle(parsedStr);
        setLines(listAfter);
      }
    }
    // eslint-disable-next-line
  }, [mode, isVisible]);

  const closeModal = () => {
    setIsVisible(false);
    setLines([]);
  };

  const truncatedValues = ['categoryName', 'name'];

  return (
    <Modal visible={isVisible} onCancel={closeModal} title="Skills and levels" width={1000} destroyOnClose>
      <div className="event-modal-radio">
        <Button
          onClick={() => setMode('before')}
          label="Before"
          designType="light-inverse"
          isActivated={mode === 'before'}
          offsetRight
        />
        <Button
          onClick={() => setMode('after')}
          label="After"
          designType="light-inverse"
          isActivated={mode === 'after'}
        />
      </div>
      <TableNew small>
        <Thead>
          <Tr>
            {columns.map((col, i) => <ThNew
              key={i}
              className={`skillsUpdateEventTh--${col.dataIndex}`}
            >
              {col.title}
            </ThNew>)}
          </Tr>
        </Thead>
        <Tbody>
          {lines.length > 0
            ? lines.map((d, i) => <Tr key={i}>
              <>
                {Object.entries(d).map(([key, value]) => <TdNew key={key}>
                  {truncatedValues.includes(key)
                    ? <TruncateTooltip value={value} table />
                    : value}
                </TdNew>)}
              </>
            </Tr>)
            : <EmptyTableSection icon={faTools} text="No skills" colSpan={6} />}
        </Tbody>
      </TableNew>
    </Modal>
  );
};

export default SkillsUpdateModal;
