import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  partViewPageTimelineFiltersSelector,
  partViewPartsKitsFiltersSelector,
  stockPartAttachmentsFilterSelector,
} from '../../../store/selectors/stockSelectors';
import { getPartAttachmentsThunk } from '../../../store/thunks/stock/part/partAttachmentsThunks';
import { PartTabsEnum } from '../enums/PartTypesEnum';
import { getPartPurchaseOrders, getPartViewTimeline, getStockPartViewPartsKits } from '../../../store/thunks/stock/part/partViewPageThunks';
import { partPurchaseOrdersFiltersSelector } from '../../../store/selectors/purchaseOrderSelectors';
import { setPartViewPageTimelineFilters } from '../../../store/slices/stockSlice';

export const useFetchCurrentTabData = (partId: number | undefined) => {
  const dispatch = useAppDispatch();
  const attachmentsFilters = useAppSelector(stockPartAttachmentsFilterSelector);
  const partsKitsFilters = useAppSelector(partViewPartsKitsFiltersSelector);
  const purchaseOrdersFilters = useAppSelector(partPurchaseOrdersFiltersSelector);
  const timelineFilters = useAppSelector(partViewPageTimelineFiltersSelector);
  const [params] = useSearchParams();
  const partTab = params.get('part_tab');

  const fetchPurchaseOrders = () => {
    partId && dispatch(getPartPurchaseOrders({
      filters: { ...purchaseOrdersFilters, partId },
    }));
  };

  const fetchPartsKits = () => {
    partId && dispatch(getStockPartViewPartsKits({
      id: partId,
      filters: partsKitsFilters,
    }));
  };

  const fetchAttachments = () => {
    partId && dispatch(getPartAttachmentsThunk({
      partId,
      filters: attachmentsFilters,
    }));
  };

  const fetchTimeline = () => {
    if (partId) {
      if (timelineFilters.page === 1) {
        partId && dispatch(getPartViewTimeline({
          partId,
          filters: timelineFilters,
        }));
      } else {
        dispatch(setPartViewPageTimelineFilters({ ...timelineFilters, page: 1 }));
      }
    }
  };

  const fetchCurrentTabData = () => {
    switch (partTab) {
      case PartTabsEnum.PURCHASES:
        return fetchPurchaseOrders();
      case PartTabsEnum.PARTSKITS:
        return fetchPartsKits();
      case PartTabsEnum.ATTACHMENTS:
        return fetchAttachments();
      case PartTabsEnum.ACTIVITY:
        return fetchTimeline();
      default:
        return null;
    }
  };

  return { fetchCurrentTabData, partTab };
};
