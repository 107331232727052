import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  salesOffsiteWorkingDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';
import ViewPageLink from '../../../../../../common/components/view-page-link/ViewPageLink';
import { EntityType } from '../../../../../../common/types/commonTypes';

const SalesOffsiteWorkingBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const { items } = useAppSelector(salesOffsiteWorkingDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  const hiddenKeys = ['entityId', 'customerId', 'lat', 'lng'];
  const truncateKeys = ['address', 'cityTown', 'entityStatus'];
  const linkKeys = ['entityNumber', 'customerName'];

  const renderCellValue = (key: string, value: string | number | null, el: typeof items[number]) => {
    if (linkKeys.includes(key) && el.entityType) {
      let id: number | null = null;
      let entityType: EntityType = 'Customer';
      if (key === 'customerName') id = el.customerId;
      if (key === 'entityNumber') {
        id = el.entityId;
        entityType = el.entityType as EntityType;
      }
      return <ViewPageLink entityType={entityType} entityId={id}>
        <TruncateTooltip value={value} table />
      </ViewPageLink>;
    }
    if (truncateKeys.includes(key)) return <TruncateTooltip value={value} table />;
    return value;
  };

  return (
    <Tbody>
      {items.length
        ? items.map((el) => (
          <Tr key={el.number}>
            {Object.entries(el).map(([key, value]) => {
              if (hiddenKeys.includes(key)) return null;
              return <TdNew key={key}>{renderCellValue(key, value, el)}</TdNew>;
            })}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default SalesOffsiteWorkingBody;
