import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBuilding, faCubes } from '@fortawesome/free-solid-svg-icons';
import { PricelistDto } from '../../types/pricelistTypes';
import {
  generalPricelistTypeSelector,
  shortBusinessAreasSelector,
} from '../../../../store/selectors/coreSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { DictionaryItem } from '../../../../core/types/coreTypes';
import Tag from '../../../../core/components/tag/Tag';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { pricelistStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';

type PricelistItemProps = {
  item: PricelistDto,
}

const PricelistItem: React.FC<PricelistItemProps> = ({ item }) => {
  const generalPricelistType = useAppSelector(generalPricelistTypeSelector);
  const pricelistStatuses = useAppSelector(pricelistStatusesSelector);
  const shortBusinessAreas = useAppSelector(shortBusinessAreasSelector);

  const getLabelHandle = (value: number | number[], selector: DictionaryItem[]) => {
    if (typeof value === 'number') {
      return selector.find((el) => el.value === value)?.label || '-';
    } else {
      const arr: string[] = value.map((el) => getLabelHandle(el, selector));
      return arr.join(', ');
    }
  };

  return (
    <PrivateLineLink
      to={`${item.id}`}
      permission={PermissionEnum.PricelistsViewPage}
      className="pricelistLine"
    >
      <span className="pricelistLine__title">
        <FontAwesomeIcon icon={item.type === generalPricelistType?.value ? faCubes : faBuilding} className="pricelistLine__icon" />
        <span className="pricelistLine__name">{item.name}</span>
      </span>
      <span className="pricelistLine__status">
        <Tag textSize="md" label={getLabelHandle(item.status, pricelistStatuses)} className="pricelistLine__tag" />
      </span>
      <span className="pricelistLine__info">
        <span className="pricelistLine__areas">{getLabelHandle(item.businessAreas as number[], shortBusinessAreas)}</span>
        <span className="pricelistLine__count">
          <span className="pricelistLine__count-label">Items</span>
          <span className="pricelistLine__count-value">{item.itemsCount}</span>
        </span>
      </span>
    </PrivateLineLink>
  );
};

export default PricelistItem;
