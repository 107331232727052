import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Button from '../../../../../core/components/button/Button';
import { PricesFiltersType } from '../../../../dashboard/types/pricelistTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import { setPricesFilters } from '../../../../../store/slices/pricelistSlice';
import { BooleanFunctionType } from '../../../../../core/types/coreTypes';
import Restricted from '../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { getPricesPartsThunk } from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';

type PricesActionsProps = {
  checkedKeys: number[],
  totalCount: number,
  pricesFilters: PricesFiltersType,
  setAddPartModal: BooleanFunctionType,
  getAffectedHandle: (dataToAffect: { partIds: number[] }) => void,
}

const PricesActions: React.FC<PricesActionsProps> = ({
  checkedKeys, totalCount, pricesFilters, setAddPartModal, getAffectedHandle,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Restricted to={PermissionEnum.PricelistEditPriceFieldsAndUnlockPriceActions}>
          <>
            <Button
              label="Add part"
              onClick={() => setAddPartModal(true)}
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              designType="dark"
              reversed
            />
            <Button
              label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
              disabled={checkedKeys.length === 0}
              onClick={() => getAffectedHandle({ partIds: checkedKeys })}
            />
          </>
        </Restricted>
      </div>
      {totalCount > 0 && <Pagination
        currentPage={pricesFilters.page}
        pageSize={pricesFilters.pageSize}
        onChange={(page, pageSize) => {
          dispatch(setPricesFilters({ ...pricesFilters, page, pageSize }));
          id && dispatch(getPricesPartsThunk({ id: +id, filters: { ...pricesFilters, page, pageSize } }));
        }}
        totalItems={totalCount}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        className="actions-and-paging__paging"
        narrow
      />}
    </div>
  );
};

export default PricesActions;
