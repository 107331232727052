import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import Layout from '../../core/components/layout/Layout';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import RectificationViewHead from './components/rectification-head/RectificationViewHead';
import './RectificationViewPageContainer.scss';
import RectificationContent from './components/rectification-content/RectificationContent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import RectificationFilters from './components/rectification-filters/RectificationFilters';
import { useRectificationParams } from './hooks/useRectificationParams';
import { rectificationDetailsSelector } from '../../store/selectors/sharedSelectors';
import { rectificationViewPageCleanupHandle } from './utils/rectificationViewPageCleanupHandle';
import { getRectificationFaultTypesThunk, getRectificationTypesThunk } from '../../store/thunks/coreThunk';
import RejectStatusModal from '../../common/components/entity-status-change-modals/RejectStatusModal';
import { VoidFunctionType } from '../../core/types/coreTypes';
import {
  generateRectificationPdfThunk,
  getRectificationPartRequestLines,
  putRectificationStatusThunk,
} from '../../store/thunks/rectification/rectificationViewPageThunks';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import {
  rectificationContactsPagingSelector,
  rectificationContactsSelector,
  rectificationPartRequestLinesFiltersSelector,
} from '../../store/selectors/rectificationsSelector';
import { RectificationContentModes, RectificationViewModeParamsEnum } from './enums/RectificationViewEnums';
import { setPartRequestLinesFilters } from '../../store/slices/rectificationsSlice';
import { rectificationStatusChangeConfirmationStatusesSelector } from '../../store/selectors/coreStatusesSelectors';
import { getPartRequestLineStatuses } from '../../store/thunks/core/coreStatusesThunks';
import { stockPartRequestLinesOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getRectificationEventTypesThunk } from '../../store/thunks/core/coreEventTypesThunks';
import { getRectificationContactsThunk } from '../../store/thunks/rectification/rectificationContactsThunks';
import RectificationGenerateEmailModal from './components/rectification-content/details/modals/RectificationGenerateEmailModal';

const RectificationViewPageContainer = () => {
  const dispatch = useAppDispatch();
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const { rejected, completed, cancelled } = useAppSelector(rectificationStatusChangeConfirmationStatusesSelector);
  const rectificationPartRequestLinesFilters = useAppSelector(rectificationPartRequestLinesFiltersSelector);
  const linesOrdering = useAppSelector(stockPartRequestLinesOrderingSelector);
  const rectificationContactsPaging = useAppSelector(rectificationContactsPagingSelector);
  const rectificationContacts = useAppSelector(rectificationContactsSelector);

  const {
    backHandle,
    tabs,
    setModeParam,
    params,
    setSearchParams,
  } = useRectificationParams();

  const currentUser = useAppSelector(currentUserSelector);

  const [isConfirmationModal, setConfirmationModal] = useState(false);
  const [isRejectStatus, setIsRejectStatus] = useState(false);
  const [isCompletedConfirmation, setCompletedConfirmation] = useState(false);
  const [isCancellationConfirmation, setCancellationConfirmation] = useState(false);
  const [generateEmailModal, setGenerateEmailModal] = useState(false);

  const rectificationId = rectificationDetails?.rectification.id;

  const confirmRejectedStatusChange = (status: number, reason: string, onClose: VoidFunctionType) => {
    rectificationDetails?.rectification?.id && rejected && dispatch(putRectificationStatusThunk({
      rectificationId: rectificationDetails?.rectification?.id,
      statusTransition: status,
      onCloseModal: onClose,
      rejectionReason: reason,
    }));
  };

  const confirmCompletedStatusChange = () => {
    rectificationDetails?.rectification?.id && completed && dispatch(putRectificationStatusThunk({
      rectificationId: rectificationDetails?.rectification?.id,
      statusTransition: completed,
      onCloseModal: () => setCompletedConfirmation(false),
    }));
  };
  const confirmCancelledStatusChange = () => {
    rectificationDetails?.rectification?.id && cancelled && dispatch(putRectificationStatusThunk({
      rectificationId: rectificationDetails?.rectification?.id,
      statusTransition: cancelled,
      onCloseModal: () => setCancellationConfirmation(false),
    }));
  };

  const actions = [
    {
      label: 'Export to PDF',
      key: 'export-to-pdf',
      icon: <FontAwesomeIcon icon={faFilePdf} />,
      onClick: () => rectificationId && dispatch(generateRectificationPdfThunk({ id: rectificationId })),
      disabled: !rectificationDetails?.rectification.partRequestLinesCount,
      restrictedTo: PermissionEnum.RectificationExportToPDF,
    },
    {
      label: 'Generate email',
      key: 'generate-email',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      onClick: () => setGenerateEmailModal(true),
      // eslint-disable-next-line max-len
      disabled: !rectificationDetails?.rectification.partRequestLinesCount || (rectificationContacts.totalCount === 0) || !rectificationContacts.items.find((el) => el.email),
      restrictedTo: PermissionEnum.RectificationExportToPDF,
    },
  ];

  useEffect(
    () => {
      if (currentUser) {
        const modeParam = params.get('mode');
        const { permissions } = currentUser.userContextRole;
        if (!modeParam && permissions.includes(PermissionEnum.RectificationViewPage)) {
          setSearchParams({ mode: tabs[0].value }, { replace: true });
        } else {
          if (!RectificationContentModes.includes(modeParam as RectificationViewModeParamsEnum)) {
            setSearchParams({ mode: RectificationViewModeParamsEnum.RECTIFICATION_DETAILS }, { replace: true });
          }
        }
      }
    },
    // eslint-disable-next-line
    [currentUser, rectificationDetails],
  );

  useEffect(() => {
    if (linesOrdering.length > 0 && !rectificationPartRequestLinesFilters?.order) {
      dispatch(setPartRequestLinesFilters({
        ...rectificationPartRequestLinesFilters,
        order: { field: linesOrdering[0].value, isAsc: true },
      }));
    }
    // eslint-disable-next-line
  }, [linesOrdering, rectificationPartRequestLinesFilters?.order]);

  useEffect(() => {
    const partRequestId = rectificationDetails?.rectification?.partRequestId;
    if (partRequestId && rectificationPartRequestLinesFilters?.order) {
      dispatch(getRectificationPartRequestLines({
        partRequestId,
        filters: rectificationPartRequestLinesFilters,
      }));
    }
    // eslint-disable-next-line
  }, [rectificationDetails?.rectification?.partRequestId, rectificationPartRequestLinesFilters]);

  useEffect(() => {
    rectificationId && dispatch(getRectificationContactsThunk({ rectificationId, ...rectificationContactsPaging }));
    // eslint-disable-next-line
  }, [rectificationContactsPaging]);

  useEffect(() => {
    dispatch(getRectificationTypesThunk());
    dispatch(getRectificationFaultTypesThunk());
    dispatch(getPartRequestLineStatuses());
    dispatch(getRectificationEventTypesThunk());

    return () => {
      rectificationViewPageCleanupHandle(dispatch);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          actions={actions}
          confirmHandler={() => console.log('to be done delete confirmation')}
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setConfirmationModal}
          goBack={backHandle}
          className="rectificationViewPageHead"
        >
          <RectificationViewHead />
        </DetailsHead>
      }
    >
      <RejectStatusModal
        isVisible={isRejectStatus}
        onCancel={() => setIsRejectStatus(false)}
        onConfirm={confirmRejectedStatusChange}
        rejectionStatus={rejected}
        customTitle="Move to Rejected by customer"
      />
      <ConfirmationModal
        isVisible={isCancellationConfirmation || isCompletedConfirmation}
        onCancel={() => {
          isCancellationConfirmation && setCancellationConfirmation(false);
          isCompletedConfirmation && setCompletedConfirmation(false);
        }}
        customTitle="Confirm"
        customText={isCompletedConfirmation
          ? 'You are about to mark this rectification as "Completed" and after this it will be locked for editing. '
              + 'This operation cannot be undone.'
          : 'You are about to mark this rectification as "Cancelled" and all part lines will be removed from this rectification. '
              + 'This operation cannot be undone.'}
        customCreateLabel={isCompletedConfirmation ? 'Mark as completed' : 'Mark as cancelled'}
        confirmHandler={isCompletedConfirmation ? confirmCompletedStatusChange : confirmCancelledStatusChange}
      />
      <RectificationGenerateEmailModal
        visible={generateEmailModal}
        setVisible={setGenerateEmailModal}
      />
      <RectificationFilters
        rectification={rectificationDetails?.rectification}
        tabs={tabs}
        mode={params.get('mode')}
        setModeParam={setModeParam}
        setIsRejectStatus={setIsRejectStatus}
        setCancellationConfirmation={setCancellationConfirmation}
        setCompletedConfirmation={setCompletedConfirmation}
      />
      <RectificationContent mode={params.get('mode')} />
    </Layout>
  );
};

export default RectificationViewPageContainer;
