import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { invoiceNominalCodesSummarySelector } from '../../../../../store/selectors/invoicingSelectors';
import { toPoundCurrency } from '../../../../../common/utils/formatUtils';

type AddInvoiceTotalProps = {
  isCreditNotesModalType: boolean,
}

const AddInvoiceTotal: React.FC<AddInvoiceTotalProps> = ({ isCreditNotesModalType }) => {
  const summaryData = useAppSelector(invoiceNominalCodesSummarySelector);
  const summaryByModalType = isCreditNotesModalType ? summaryData?.pendingCreditSummary : summaryData?.pendingInvoiceSummary;
  const data = [
    { label: 'Total pending', value: toPoundCurrency(summaryByModalType?.totalNet) },
    { label: 'Selected pending', value: toPoundCurrency(summaryByModalType?.selectedNet) },
    { label: 'Remaining pending', value: toPoundCurrency(summaryByModalType?.remainingNet) },
  ];

  return (
    <ul className="addInvoiceTotal">
      {data.map(({ label, value }) => <li key={label} className="addInvoiceTotal__item">
        <span>{label}</span>
        <span className="nowrap">{value}</span>
      </li>)}
    </ul>
  );
};

export default AddInvoiceTotal;
