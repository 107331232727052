import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { OrderColumnType } from '../types/ordersTypes';
import { putOrderStatusThunk } from '../../../store/thunks/orders/dashOrdersThunks';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { sortedOrderCollectionsSelector } from '../../../store/selectors/ordersSelector';
import {
  orderStatusesValuesSelector,
  orderStatusSelector,
} from '../../../store/selectors/coreStatusesSelectors';

export const useOrdersStatusChange = (setCards: (value: OrderColumnType | null) => void) => {
  const dispatch = useAppDispatch();
  const orderStatus = useAppSelector(orderStatusSelector);
  const { rejectedByCustomer, invoicing, cancelled } = useAppSelector(orderStatusesValuesSelector);
  const orderCollections = useAppSelector(sortedOrderCollectionsSelector);

  const [isInvoicingConfirmation, setInvoicingConfirmation] = useState(false);
  const [isCancellationConfirmation, setCancellationConfirmation] = useState(false);
  const [isRejectStatus, setIsRejectStatus] = useState(false);
  const [orderTransit, setOrderTransit] = useState<{id: number, initStatus: number} | undefined>(undefined);
  const [columnsLoading, setColumnsLoading] = useState<number[]>([]);

  const statusChangeHandle = (
    id: number,
    statusTransition: number,
    sourceId: number,
    onClose?: VoidFunctionType,
    rejectionReason?: string,
  ) => {
    dispatch(putOrderStatusThunk({
      id,
      statusTransition,
      sourceId,
      setColumns: () => setCards(orderCollections),
      rejectionReason,
      onClose,
      setColumnsLoading,
    }));
  };

  const editStatus = (
    result: DropResult,
    setColumns: (value: OrderColumnType | null) => void,
  ) => {
    if (!result.destination) return;
    const statusTransition = +result.destination.droppableId;
    const sourceId = +result.source.droppableId;

    if (statusTransition === rejectedByCustomer) {
      setIsRejectStatus(true);
    } else if (statusTransition === cancelled) {
      setCancellationConfirmation(true);
    } else if (statusTransition === invoicing) {
      setInvoicingConfirmation(true);
    } else {
      result.destination && statusChangeHandle(
        +result.draggableId,
        statusTransition,
        sourceId,
        () => {
          setColumns(orderCollections);
        },
      );
    }
  };

  const confirmRejectedStatusChange = (status: number, reason: string, onCancel: VoidFunctionType) => {
    const onClose = () => {
      onCancel();
      setOrderTransit(undefined);
      setIsRejectStatus(false);
    };
    orderTransit && statusChangeHandle(
      orderTransit.id,
      status,
      orderTransit.initStatus,
      onClose,
      reason,
    );
  };
  const confirmInvoicingStatusChange = () => {
    const onClose = () => {
      setOrderTransit(undefined);
      setInvoicingConfirmation(false);
    };
    orderTransit && invoicing && statusChangeHandle(
      orderTransit.id,
      invoicing,
      orderTransit.initStatus,
      onClose,
    );
  };
  const confirmCancelledStatusChange = () => {
    const onClose = () => {
      setOrderTransit(undefined);
      setCancellationConfirmation(false);
    };
    orderTransit && cancelled && statusChangeHandle(
      orderTransit.id,
      cancelled,
      orderTransit.initStatus,
      onClose,
    );
  };
  return {
    isRejectStatus,
    setIsRejectStatus,
    isInvoicingConfirmation,
    setInvoicingConfirmation,
    isCancellationConfirmation,
    setCancellationConfirmation,
    orderTransit,
    setOrderTransit,
    editStatus,
    confirmRejectedStatusChange,
    confirmInvoicingStatusChange,
    confirmCancelledStatusChange,
    rejectedByCustomer,
    orderStatus,
    columnsLoading,
  };
};
