import { faH, faL, faM } from '@fortawesome/free-solid-svg-icons';
import { DictionaryItem } from '../types/coreTypes';
import {
  CustomerTypeEnum, JobTypeUiNamesEnum, PriorityLevelEnum,
} from '../enums/dictionariesEnums';
import MAP_ICON from '../../assets/icons/map.svg';
import WAREHOUSE_ICON from '../../assets/icons/warehouse.svg';
import TRUCK_ICON from '../../assets/icons/truck.svg';
import TOOLBOX_ICON from '../../assets/icons/toolbox.svg';

export const priorityLevelHandle = (priorityLevels: DictionaryItem[], priority: number) => {
  const currentValue = priorityLevels.find((p) => p.value === priority);
  if (currentValue?.label === PriorityLevelEnum.LOW) return faL;
  if (currentValue?.label === PriorityLevelEnum.MEDIUM) return faM;
  else return faH;
};

export const customerTypeNameFn = (customerTypes: Array<DictionaryItem>, type: number) => {
  const currentValue = customerTypes.find((c) => c.value === type);
  if (currentValue?.label === CustomerTypeEnum.INDIVIDUAL) return 'IND';
  else return 'CORP';
};

export const jobTypeIconFn = (jobTypes: Array<DictionaryItem>, type: number) => {
  const currentValue = jobTypes.find((j) => j.value === type);
  const subcontractorTypes = [JobTypeUiNamesEnum.SubcontractorOnSite, JobTypeUiNamesEnum.SubcontractorOffSite];

  if (currentValue?.label === JobTypeUiNamesEnum.OffSite) return MAP_ICON;
  if (currentValue?.label === JobTypeUiNamesEnum.OnSite) return WAREHOUSE_ICON;
  if (subcontractorTypes.includes(currentValue?.label as JobTypeUiNamesEnum)) return TRUCK_ICON;
  else return TOOLBOX_ICON;
};
