import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faFilePdf, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router-dom';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import PurchaseOrderHead from './components/head/PurchaseOrderHead';
import PurchaseOrderContentTabs from './components/content-tabs/PurchaseOrderContentTabs';
import { usePurchaseOrderParam } from './hooks/usePurchaseOrderParam';
import PurchaseOrderContent from './components/content/PurchaseOrderContent';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import OrderFromFinalizedToSentModal from './components/modals/OrderFromFinalizedToSentModal';
import './PurchaseOrderViewPageContainer.scss';
import {
  deletePurchaseOrderThunk, generatePurchaseOrderEmailThunk,
  generatePurchaseOrderPdfThunk, getDefaultPurchaseOrderDeliverAddress,
  getPurchaseOrderById,
  getPurchaseOrderPartsList,
  getPurchaseOrderPartSummaryThunk,
  patchPurchaseOrderByIdThunk,
  putPurchaseOrderByIdThunk,
} from '../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import AddressModal from '../../../common/components/address/AddressModal';
import { AddressFields, DeliveryFields, RequisiteDto } from '../../../common/types/commonTypes';
import {
  purchaseOrderAddressFieldsSelector,
  purchaseOrderDetailsSelector,
  purchaseOrderPartsListSelector,
} from '../../../store/selectors/purchaseOrderSelectors';
import RequisiteModal from '../../../common/components/requisite/RequisteModal';
import {
  PurchaseOrderAllStatusEnum,
  StockTabParamEnum,
  PurchaseUrlParamsNames,
} from '../dashboard/enums/PurchaseOrderStatusEnum';
import { StockPageParamEnums } from '../../container/enums/stockEnums';
import { convertFromAddressToDelivery } from '../create/utils/convertAddressUtils';
import { purchaseOrderCleanUpHandle } from './utils/purchaseOrderCleanUpHandle';
import { PurchaseOrderModes, PurchaseOrderViewParamEnum } from './enums/PurchaseOrderViewEnum';
import { purchaseOrderStatusObjSelector } from '../../../store/selectors/coreStatusesSelectors';
import OpenEmailClientModal from '../../../common/components/generate-email-modal/OpenEmailClientModal';

const PurchaseOrderViewPageContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [requisiteModal, setRequisiteModal] = useState(false);
  const [locationModal, setLocationModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);
  const [isConfirm, setConfirm] = useState(false);
  const [isStatusModal, setStatusModal] = useState(false);
  const [emailHref, setEmailHref] = useState('');
  const purchaseOrderAddressFields = useAppSelector(purchaseOrderAddressFieldsSelector);
  const details = useAppSelector(purchaseOrderDetailsSelector);
  const { statusNew, finalised, sentToSupplier } = useAppSelector(purchaseOrderStatusObjSelector);
  const { purchaseOrderParts } = useAppSelector(purchaseOrderPartsListSelector);

  const { purchaseOrder: purchaseOrderDetails = null, billingRequisite = null } = details || {};

  const allowedStatusChangeArr = [statusNew, finalised, sentToSupplier];

  const isRequisiteOptional = (purchaseOrderDetails?.status === statusNew) || (purchaseOrderDetails?.status === finalised);

  const {
    backHandle,
    navigate,
    params,
    setModeParam,
    options,
    setSearchParams,
  } = usePurchaseOrderParam();
  const headActions = [
    {
      label: 'Export to PDF',
      key: 'exportPurchaseOrder',
      icon: <FontAwesomeIcon icon={faFilePdf} />,
      onClick: () => id && dispatch(generatePurchaseOrderPdfThunk({ id: +id })),
      restrictedTo: PermissionEnum.StockPurchaseOrdersExportToPdf,
      disabled: purchaseOrderParts.length === 0,
    },
    {
      label: 'Generate email',
      key: 'generate-email',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      onClick: () => id && dispatch(generatePurchaseOrderEmailThunk({ id: +id, setEmailHref })),
      disabled: !purchaseOrderDetails?.partsCount,
      restrictedTo: PermissionEnum.StockPurchaseOrdersExportToPdf,
    },
    {
      label: 'Delete purchase order',
      key: 'deletePurchaseOrder',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setConfirm(true),
      restrictedTo: PermissionEnum.StockPurchaseOrdersDelete,
      disabled: !allowedStatusChangeArr.includes(purchaseOrderDetails?.status) || purchaseOrderDetails?.hasPartQuantity,
    },
  ];

  useEffect(() => {
    if (id) {
      dispatch(getPurchaseOrderById({ id: +id }));
      dispatch(getPurchaseOrderPartsList({
        purchaseOrderId: +id,
        withoutRangeFilterUpdate: true,
      }));
      if (params.get('mode') === PurchaseOrderViewParamEnum.PART_LINES) {
        dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId: +id }));
      }
    }
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    const modeParam = params.get('mode');
    if (!modeParam) {
      setSearchParams({ mode: options[0].value }, { replace: true });
    } else {
      if (!PurchaseOrderModes.includes(modeParam as PurchaseOrderViewParamEnum)) {
        setSearchParams({ mode: PurchaseOrderViewParamEnum.DETAILS }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [params]);

  useEffect(() => {
    purchaseOrderAddressFields && setAddressFields(purchaseOrderAddressFields);
  }, [purchaseOrderAddressFields]);

  const changeBillingDetails = (requisite: RequisiteDto, isManual: boolean, selectedEntityId?: number) => {
    id && dispatch(patchPurchaseOrderByIdThunk({
      id: +id,
      data: {
        billingRequisite: isManual ? requisite : null,
        isBillingDetailsManualInput: isManual,
        billingSupplierId: selectedEntityId,
      },
      onCloseModal: () => setRequisiteModal(false),
    }));
  };
  const purchaseDeliverToChangeFn = (locationFields: AddressFields | null) => {
    if (purchaseOrderDetails) {
      const deliveryFieldsFinal = convertFromAddressToDelivery(locationFields);
      const keysToCheck: Array<keyof DeliveryFields> = [
        'deliveryCompanyName',
        'deliveryAddressLine1',
        'deliveryAddressLine2',
        'deliveryCityTown',
        'deliveryRegion',
        'deliveryPostalCode',
        'deliveryTelephone',
      ];
      const keysNotEqual = keysToCheck.some((key) => (purchaseOrderDetails[key] || '') !== deliveryFieldsFinal[key]);
      if (keysNotEqual) {
        dispatch(patchPurchaseOrderByIdThunk({
          id: purchaseOrderDetails.id,
          data: { ...deliveryFieldsFinal },
          onCloseModal: () => setLocationModal(false),
        }));
      } else {
        setLocationModal(false);
      }
    }
  };

  // eslint-disable-next-line max-len
  const navigateLink = `/stock?${PurchaseUrlParamsNames.PAGE}=${StockPageParamEnums.PURCHASES}&${PurchaseUrlParamsNames.TAB_TYPE}=${StockTabParamEnum.ACTIVE}&${PurchaseUrlParamsNames.PURCHASE_STATUS}=${PurchaseOrderAllStatusEnum.ALL}`;

  useEffect(() => {
    return () => {
      purchaseOrderCleanUpHandle(dispatch);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={<DetailsHead
        goBack={backHandle}
        actions={headActions}
        className="purchaseOrderViewPageDetailsHead"
      >
        <PurchaseOrderHead />
      </DetailsHead>}
    >
      <OpenEmailClientModal emailHref={emailHref} onCancel={() => setEmailHref('')} />
      <OrderFromFinalizedToSentModal
        isVisible={isStatusModal}
        onCancel={() => setStatusModal(false)}
        confirmHandle={() => purchaseOrderDetails && sentToSupplier && dispatch(putPurchaseOrderByIdThunk({
          id: purchaseOrderDetails?.id,
          data: { statusTransition: sentToSupplier },
          closeModal: () => setStatusModal(false),
        }))}
      />
      <ConfirmationModal
        isVisible={isConfirm}
        onCancel={() => setConfirm(false)}
        confirmHandler={() => purchaseOrderDetails && dispatch(deletePurchaseOrderThunk({
          id: purchaseOrderDetails?.id,
          closeModal: () => setConfirm(false),
          navigate: () => navigate(navigateLink),
        }))}
      />
      <RequisiteModal
        isVisible={requisiteModal}
        onCancel={() => {
          setRequisiteModal(false);
        }}
        isOptionalFields={isRequisiteOptional}
        submitHandle={changeBillingDetails}
        requisite={billingRequisite}
        isShipping={false}
        entitySourceType="supplier"
        entityId={purchaseOrderDetails?.billingSupplierId || purchaseOrderDetails?.supplierId}
        isManual={purchaseOrderDetails?.isBillingDetailsManualInput}
      />
      <AddressModal
        title="Deliver to"
        isVisible={locationModal}
        onCancel={() => {
          setLocationModal(false);
          setAddressFields(purchaseOrderAddressFields);
        }}
        isOptionalFields={isRequisiteOptional}
        addressFields={addressFields}
        onSubmit={purchaseDeliverToChangeFn}
        isDefaultOptionAvailable
        setDefaultAddressFields={() => dispatch(getDefaultPurchaseOrderDeliverAddress({
          setAddressFields,
        }))}
      />
      <PurchaseOrderContentTabs
        allowedTabs={options}
        mode={params.get('mode')}
        setModeParam={setModeParam}
        purchaseOrderDetails={purchaseOrderDetails}
        setStatusModal={setStatusModal}
      />
      <PurchaseOrderContent
        mode={params.get('mode')}
        setRequisiteModal={setRequisiteModal}
        setLocationModal={setLocationModal}
        setAddressFields={setAddressFields}
      />
    </Layout>
  );
};

export default PurchaseOrderViewPageContainer;
