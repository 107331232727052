import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import Tag from '../../../../../core/components/tag/Tag';
import { routesPath } from '../../../../../core/enums/pathEnum';
import { useAppSelector } from '../../../../../store/hooks';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../../common/components/private-line-link/PrivateLineLink';
import { PurchaseOrderDto } from '../../types/purchaseOrdersTypes';
import {
  purchaseOrderStatusOriginSelector,
  supplierStatusesSelector,
  verifiedSupplierStatusSelector,
} from '../../../../../store/selectors/coreStatusesSelectors';

type PurchaseOrderItemProps = {
  item: PurchaseOrderDto,
}
const PurchaseOrderItem:React.FC<PurchaseOrderItemProps> = ({ item }) => {
  const {
    id,
    purchaseOrderNumber,
    supplierStatus,
    supplierName,
    assigneeFullName,
    status,
    partsCount,
  } = item;
  const purchaseOrderStatusOrigin = useAppSelector(purchaseOrderStatusOriginSelector);
  const supplierStatuses = useAppSelector(supplierStatusesSelector);
  const verifiedSupplierStatus = useAppSelector(verifiedSupplierStatusSelector);

  const poStatusLabel = () => {
    const label = purchaseOrderStatusOrigin.find((st) => st.value === status)?.label;
    if (label) {
      return label;
    } else return '-';
  };
  const supplierStatusLabel = supplierStatuses.find((el) => el.value === supplierStatus)?.label;

  return (
    <PrivateLineLink
      to={`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${id}`}
      permission={PermissionEnum.StockPurchaseOrdersViewPage}
      className="purchaseOrderItem"
    >
      <span className="purchaseOrderItem__title">
        <FontAwesomeIcon
          icon={faCartArrowDown}
          className="purchaseOrderItem__icon"
        />
        <span className="purchaseOrderItem__number">{purchaseOrderNumber}</span>
      </span>
      <span className="purchaseOrderItem__data purchaseOrderItem__data--supplier">
        <span>Supplier</span>
        <span className="purchaseOrderItem__name-wrapper">
          <Tooltip title={supplierStatusLabel || ''}>
            <FontAwesomeIcon
              icon={supplierStatus === verifiedSupplierStatus?.value ? faClipboardCheck : faQuestionCircle}
            />
          </Tooltip>
          <TruncateTooltip value={supplierName} className="purchaseOrderItem__name" />
        </span>
      </span>
      <span className="purchaseOrderItem__data">
        <span className="purchaseOrderItem__subtitle">Assigned to</span>
        <TruncateTooltip value={assigneeFullName} className="purchaseOrderItem__name" />
      </span>
      <span className="purchaseOrderItem__tag-wrapper">
        <Tag textSize="md" className="purchaseOrderItem__tag" label={poStatusLabel()} />
      </span>
      <span className="purchaseOrderItem__count">
        <span className="purchaseOrderItem__count-label">Items</span>
        <span className="purchaseOrderItem__count-value">{partsCount}</span>
      </span>
    </PrivateLineLink>
  );
};

export default PurchaseOrderItem;
