import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AddressSuggestionDetailsDto,
  AxiosErrorResponse,
  BooleanFunctionType,
  CustomerDictionaryDto, GenericVoidFunctionType, GetAddressSuggestionResponse, GetAddressSuggestionsResponse,
  ResponseResult,
  ResponseSingleResult, StringFunctionType,
  SupplierDictionaryDto,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading } from '../../slices/coreSlice';
import { stockApi } from '../../../api/stockApi';
import {
  PartCategoriesFilters,
  PartCategoryDto,
  PartSubcategoriesFilters,
  PartSubcategoryDto,
} from '../../../stock/part-categories/types/partCategoriesTypes';
import { customersAPI } from '../../../api/customersApi';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import {
  GetVehicleProfilesResponse,
  SuitableVehicleProfilesFilters,
  VehicleProfileGroupDto,
  VehicleProfileGroupsParams, VehicleProfilesFilters,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import {
  CustomersDictionaryFiltersType,
} from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import { SuppliersAPI } from '../../../api/suppliersApi';
import {
  PartProductDictionaryType,
  PartProductSourceDictionaryParams,
  PartProductSourceTotalFetchParams,
  PartProductTotalType, RequisiteDto,
} from '../../../common/types/commonTypes';
import { GetPurchaseOrderDictionaryResponse } from '../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { GetPartQuantitiesDataType, GetPartQuantitiesParams } from '../../../stock/part-view-page/types/types';
import { coreAPI } from '../../../api/coreAPI';
import { postalCodeErrorHandle } from '../../../core/utils/postalCodeErrorHandle';

export const getSourceAvailableVehicleProfilesThunk = createAsyncThunk<GetVehicleProfilesResponse, {
  filters: VehicleProfilesFilters, setSourceLoading: BooleanFunctionType, signal?: AbortSignal,
}>(
  'get/SourceAvailableVehicleProfiles',
  async ({ filters, setSourceLoading, signal }, { rejectWithValue }) => {
    setSourceLoading(true);
    try {
      const response = await vehiclesAPI.fetchVehiclesProfiles(filters, signal);
      setSourceLoading(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getTargetAvailableVehicleProfilesThunk = createAsyncThunk<GetVehicleProfilesResponse, {
  filters: VehicleProfilesFilters, setTargetLoading?: BooleanFunctionType,
}>(
  'get/TargetAvailableVehicleProfiles',
  async ({ filters, setTargetLoading }, { rejectWithValue }) => {
    setTargetLoading?.(true);
    try {
      const response = await vehiclesAPI.fetchVehiclesProfiles(filters);
      setTargetLoading?.(false);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartCategoriesThunk = createAsyncThunk<ResponseResult<PartCategoryDto[]>, {
  filters?: PartCategoriesFilters,
}>(
  'get/PartCategories',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartCategories(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartSubcategoriesThunk = createAsyncThunk<ResponseResult<PartSubcategoryDto[]>>(
  'get/PartSubcategories',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartSubcategories();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartSubcategoriesFilteredThunk = createAsyncThunk<ResponseResult<PartSubcategoryDto[]>, {
  filters?: PartSubcategoriesFilters,
}>(
  'get/PartSubcategoriesFiltered',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartSubcategories(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomersDictionaryThunk = createAsyncThunk<ResponseSingleResult<{ customers: CustomerDictionaryDto[] | null}>,
{ filters?: CustomersDictionaryFiltersType }
>(
  'get/CustomersDictionary',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomersDictionary(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSuppliersDictionaryThunk = createAsyncThunk<ResponseSingleResult<{ suppliers: SupplierDictionaryDto[] }>,
{ filters?: { keyword?: string } }>(
  'get/SupplierDictionary',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSupplierDictionary(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSuitableVehicleProfiles = createAsyncThunk<
GetVehicleProfilesResponse,
{
  filters: SuitableVehicleProfilesFilters,
}
>(
  'get/SuitableVehicleProfiles',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchSuitableVehiclesProfiles(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleProfilesManufacturers = createAsyncThunk<
ResponseSingleResult<{ manufacturers: string[] }>,
{
  keyword?: string,
}
>(
  'get/VehicleProfileManufacturers',
  async ({ keyword }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleProfileManufacturers(keyword);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleProfileGroupsThunk = createAsyncThunk<ResponseResult<VehicleProfileGroupDto[]> | null, {
  params?: VehicleProfileGroupsParams,
}>(
  'get/VehicleProfileGroups',
  async ({ params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleProfileGroups(params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartProductSourceDictionaryThunk = createAsyncThunk<ResponseSingleResult<PartProductDictionaryType>, {
  partId: number, params: PartProductSourceDictionaryParams, openModal?: VoidFunctionType, handle?: VoidFunctionType,
}>(
  'get/PartProductSourceDictionary',
  async ({
    partId, params, openModal, handle,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartProductSourceDictionary(partId, params);
      dispatch(setLoading(false));
      openModal && openModal();
      handle && handle();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartProductSourceTotalThunk = createAsyncThunk<ResponseSingleResult<PartProductTotalType>, {
  partId: number, params: PartProductSourceTotalFetchParams, onFinish?: VoidFunctionType,
}>(
  'get/PartProductSourceTotal',
  async ({ partId, params, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartProductSourceTotal(partId, params);
      if (onFinish) {
        onFinish();
      }
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderViewsDictionaryThunk = createAsyncThunk<ResponseSingleResult<GetPurchaseOrderDictionaryResponse>, {
  partRequestLineId: number,
}>(
  'get/PurchaseOrderViewsDictionary',
  async ({ partRequestLineId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrderViewsDictionary(partRequestLineId);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getReadyCollectedPartQuantities = createAsyncThunk<ResponseSingleResult<GetPartQuantitiesDataType>, {
  params: GetPartQuantitiesParams,
}>(
  'get/OrderRectification/ReadyCollectedPartQuantities',
  async ({ params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartQuantities(params);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAddressSuggestions = createAsyncThunk<ResponseSingleResult<GetAddressSuggestionsResponse>, {
  keyword: string, setLoading: BooleanFunctionType,
}>(
  'get/AddressSuggestions',
  async ({ keyword, setLoading }, { dispatch, rejectWithValue }) => {
    setLoading(true);
    try {
      const response = await coreAPI.fetchAddressSuggestions(keyword);
      setLoading(false);
      return response.data;
    } catch (err) {
      setLoading(false);
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      postalCodeErrorHandle(errors, dispatch);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAddressSuggestionsById = createAsyncThunk<ResponseSingleResult<GetAddressSuggestionResponse>, {
  id: string, setSelectedIdLoading: StringFunctionType, handleSelectSuggested: GenericVoidFunctionType<AddressSuggestionDetailsDto>
}>(
  'get/AddressSuggestionsById',
  async ({ id, setSelectedIdLoading, handleSelectSuggested }, { dispatch, rejectWithValue }) => {
    setSelectedIdLoading(id);
    try {
      const response = await coreAPI.fetchAddressSuggestionsById(id);
      setSelectedIdLoading('');
      handleSelectSuggested(response.data.data.address);
      return response.data;
    } catch (err) {
      setSelectedIdLoading('');
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      postalCodeErrorHandle(errors, dispatch);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerBillingShippingRequisiteThunk = createAsyncThunk<RequisiteDto | null,
{ id: number, isShipping: boolean, setLoadingData: BooleanFunctionType }
>(
  'get/CustomerBillingShippingRequisite',
  async ({
    id, isShipping, setLoadingData,
  }, { rejectWithValue }) => {
    setLoadingData(true);
    try {
      const response = await customersAPI.fetchCustomerById(id);
      const { billingRequisite, shippingRequisite, customer: { isShippingRequisiteSameAsBilling } } = response.data.data;
      setLoadingData(false);
      return isShipping
        ? isShippingRequisiteSameAsBilling ? billingRequisite : shippingRequisite
        : billingRequisite;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setLoadingData(false);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierBillingShippingRequisiteThunk = createAsyncThunk<RequisiteDto,
{ id: number, setLoadingData: BooleanFunctionType }
>(
  'get/SupplierBillingShippingRequisite',
  async ({
    id, setLoadingData,
  }, { rejectWithValue }) => {
    setLoadingData(true);
    try {
      const response = await SuppliersAPI.fetchSupplierById(id);
      const { billingRequisite } = response.data.data;
      setLoadingData(false);
      return billingRequisite;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setLoadingData(false);
      return rejectWithValue(error.response?.data);
    }
  },
);
