import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  setOutstandingOverduePurchasingReportData,
  setOutstandingOverduePurchasingReportFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import {
  DateRangeTableFilterType, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  outstandingOverduePurchasingReportFiltersSelector,
  outstandingOverduePurchasingReportGlobalFiltersDateSelector,
  outstandingOverduePurchasingReportGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import {
  OutstandingOverduePurchasingFilters,
} from '../../../../types/OutstandingOverdueReportTypes';
import {
  getPurchasingOutstandingOverduePurchaseOrdersExcelThunk,
  getPurchasingOutstandingOverduePurchaseOrdersThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { usePurchasingOutstandingOverdueReportDefaultPaging } from '../../../../hooks/usePurchasingOutstandingOverdueReportDefaultPaging';
import { getSuppliersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { initPurchasingOutstandingOverdueData } from '../../../reports-content/purchasing/outstanding-overdue-purchase-orders/utils/data';

const OutstandingOverduePurchaseOrdersGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(outstandingOverduePurchasingReportFiltersSelector);
  const checkboxFilters = useAppSelector(outstandingOverduePurchasingReportGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(outstandingOverduePurchasingReportGlobalFiltersDateSelector);
  const globalFiltersNames = [...checkboxFilters, ...datePickerFilters].map((el) => el.name as keyof OutstandingOverduePurchasingFilters);
  const isSelectedGlobalFilters = globalFiltersNames.some((field) => filters[field]);

  const defaultPagingAndOrdering = usePurchasingOutstandingOverdueReportDefaultPaging();

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = {
    purchaseOrderDate: [null, null],
    dueDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      page: 1,
      [field]: values || undefined,
    };
    dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
    dispatch(setOutstandingOverduePurchasingReportData(initPurchasingOutstandingOverdueData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setOutstandingOverduePurchasingReportFilters({
      ...defaultPagingAndOrdering,
      ...initPaging,
    }));
    dispatch(setOutstandingOverduePurchasingReportData(initPurchasingOutstandingOverdueData));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const {
      supplierIds,
      assigneeIds,
      purchaseOrderDate,
      dueDate,
    } = filters;
    dispatch(getPurchasingOutstandingOverduePurchaseOrdersExcelThunk({
      filters: {
        supplierIds,
        assigneeIds,
        purchaseOrderDate,
        dueDate,
      },
    }));
  };

  useEffect(() => {
    dispatch(getSuppliersDictionaryThunk({ filters: undefined }));
    return () => {
      dispatch(setOutstandingOverduePurchasingReportFilters({ ...initPaging, ...defaultPagingAndOrdering }));
      dispatch(setOutstandingOverduePurchasingReportData(initPurchasingOutstandingOverdueData));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={(values, field) => {
          dispatch(setOutstandingOverduePurchasingReportFilters({
            ...filters,
            page: 1,
            [field]: values,
          }));
          dispatch(setOutstandingOverduePurchasingReportData(initPurchasingOutstandingOverdueData));
          dispatch(setReportIsVisible(false));
        }}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default OutstandingOverduePurchaseOrdersGlobalFilters;
