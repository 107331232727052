import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getSourceAvailableVehicleProfilesThunk, getTargetAvailableVehicleProfilesThunk } from '../../store/thunks/shared/sharedThunks';
import {
  sourceAvailableVehicleProfilesFiltersSelector,
  targetAvailableVehicleProfilesFiltersSelector,
} from '../../store/selectors/sharedSelectors';
import {
  setSourceAvailableVehicleProfiles,
  setSourceAvailableVehiclesProfilesFilters,
  setTargetAvailableVehicleProfiles,
  setTargetAvailableVehiclesProfilesFilters,
} from '../../store/slices/sharedSlice';
import { getSuitableVehicleSourcesThunk } from '../../store/thunks/coreThunk';
import { suitableVehicleSourcesSelector, suitableVehicleSourcesValuesSelector } from '../../store/selectors/coreSelectors';
import { initAvailableVehicleProfilesData } from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { initSourceTargetProfilesFilters } from '../../common/types/commonTypes';
import { useCancelRequest } from './useCancelRequest';

type Params = {
  initSelectedSource: number | null,
  initSelectedIds: number[] | null,
  initSelectedGroupIds: number[] | null,
  visible: boolean,
}

export const useAvailableVehicleProfiles = (params: Params) => {
  const {
    initSelectedSource,
    initSelectedIds,
    initSelectedGroupIds,
    visible,
  } = params;
  const dispatch = useAppDispatch();

  const sources = useAppSelector(suitableVehicleSourcesSelector);
  const { byCode, groups } = useAppSelector(suitableVehicleSourcesValuesSelector);
  const sourceFilters = useAppSelector(sourceAvailableVehicleProfilesFiltersSelector);
  const targetFilters = useAppSelector(targetAvailableVehicleProfilesFiltersSelector);

  const [sourceLoading, setSourceLoading] = useState(false);
  const [targetLoading, setTargetLoading] = useState(false);

  const [selectedSource, setSelectedSource] = useState(initSelectedSource || byCode);
  const [targetIds, setTargetIds] = useState(initSelectedIds || []);
  const [groupIds, setGroupIds] = useState(initSelectedGroupIds || []);

  const [sourceSearchValue, setSourceSearchValue] = useState('');
  const [targetSearchValue, setTargetSearchValue] = useState('');

  const isCurrentSourceGroups = selectedSource === groups;

  const getResultData = () => ({
    suitableVehiclesSource: selectedSource,
    suitableForVehicleIds: isCurrentSourceGroups ? null : targetIds,
    suitableForVehicleGroupIds: isCurrentSourceGroups ? groupIds : null,
  });

  useEffect(() => {
    if (!initSelectedSource && byCode) {
      setSelectedSource(byCode);
    } else if (initSelectedSource) {
      setSelectedSource(initSelectedSource);
    }
  }, [initSelectedSource, byCode]);

  useEffect(() => {
    setTargetIds(initSelectedIds || []);
  }, [initSelectedIds]);

  useEffect(() => {
    setGroupIds(initSelectedGroupIds || []);
  }, [initSelectedGroupIds]);

  useEffect(() => {
    dispatch(getSuitableVehicleSourcesThunk());
    // eslint-disable-next-line
  }, []);

  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    if (visible) {
      cancelRequest();
      dispatch(getSourceAvailableVehicleProfilesThunk({
        filters: { ...sourceFilters, exceptIds: targetIds },
        setSourceLoading,
        signal: controller.current?.signal,
      }));
    }
    // eslint-disable-next-line
  }, [sourceFilters]);

  useEffect(() => {
    if (visible && targetIds.length) {
      dispatch(getTargetAvailableVehicleProfilesThunk({ filters: { ...targetFilters, ids: targetIds }, setTargetLoading }));
    }
    // eslint-disable-next-line
  }, [targetFilters]);

  useEffect(() => {
    if (visible && !isCurrentSourceGroups) {
      dispatch(getSourceAvailableVehicleProfilesThunk({ filters: { ...sourceFilters, exceptIds: targetIds }, setSourceLoading }));
      if (targetIds.length) {
        dispatch(getTargetAvailableVehicleProfilesThunk({ filters: { ...targetFilters, ids: targetIds }, setTargetLoading }));
      } else {
        dispatch(setTargetAvailableVehicleProfiles(initAvailableVehicleProfilesData));
        dispatch(setTargetAvailableVehiclesProfilesFilters(initSourceTargetProfilesFilters));
        setTargetSearchValue('');
      }
    }
    // eslint-disable-next-line
  }, [targetIds]);

  useEffect(() => {
    if (visible && !isCurrentSourceGroups && targetIds.length) {
      dispatch(getTargetAvailableVehicleProfilesThunk({ filters: { ...targetFilters, ids: targetIds }, setTargetLoading }));
    }
    // eslint-disable-next-line
  }, [visible]);

  const handleReset = () => {
    cancelRequest();
    setSelectedSource(initSelectedSource || byCode);
    setGroupIds(initSelectedGroupIds || []);
    setTargetIds(initSelectedIds || []);
    setSourceSearchValue('');
    setTargetSearchValue('');
    dispatch(setTargetAvailableVehiclesProfilesFilters(initSourceTargetProfilesFilters));
    dispatch(setSourceAvailableVehiclesProfilesFilters(initSourceTargetProfilesFilters));
    dispatch(setSourceAvailableVehicleProfiles(initAvailableVehicleProfilesData));
    dispatch(setTargetAvailableVehicleProfiles(initAvailableVehicleProfilesData));
  };

  return {
    sources,
    selectedSource,
    setSelectedSource,
    isCurrentSourceGroups,
    targetIds,
    setTargetIds,
    groupIds,
    setGroupIds,
    sourceSearchValue,
    setSourceSearchValue,
    targetSearchValue,
    setTargetSearchValue,
    getResultData,
    handleReset,
    sourceLoading,
    setSourceLoading,
    targetLoading,
  };
};
