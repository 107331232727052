import { routesPath } from './pathEnum';

export enum EnquiryStatusUiNamesEnum {
  New = 'New',
  ReadyForProcessing = 'Ready for processing',
  InProgress = 'In progress',
  MissingInformation = 'Missing information',
  Postponed = 'Postponed',
  Finalised = 'Finalised',
  SentToCustomer = 'Sent to customer',
  Accepted = 'Accepted',
  Rejected = 'Rejected'
}

export enum BusinessAreaUiNamesEnum {
  VlBody = 'VL Body',
  Rhino = 'Rhino',
  VlConversions = 'VL Conversions',
  VlVehicle = 'VL Vehicle',
  Accessories = 'Accessories',
}

export enum ShortBusinessUiNames {
  VlConversions = 'VLC',
  VlBody = 'VLB',
  Rhino = 'VLR',
  Accessories = 'VLA',
  VlVehicle = 'VLV',
}

export enum JobTypeUiNamesEnum {
  OnSite = 'On-site',
  OffSite = 'Off-site',
  SupplyOnlyDirectShipment = 'Supply only (direct shipment)',
  SubcontractorOnSite = 'Subcontractor (on-site)',
  SupplyOnlyOffSite = 'Supply only (off-site)',
  SupplyOnlyCollection = 'Supply only (collection)',
  SubcontractorOffSite = 'Subcontractor (off-site)',
}

export enum EnquirySourceUiNamesEnum {
  Email = 'Email',
  PhoneCall = 'Phone call',
  Other = 'Other',
}

export enum WarningsUiNamesEnum {
  InactiveFor10To14Days = 'Inactive for 10-14 days',
  InactiveFor15PlusDays = 'Inactive for 15+ days',
  VehicleIsNotLinked = 'Vehicle is not linked',
  DueDateIsNear = 'Due date is near',
}

export enum PriorityLevelEnum {
  LOW = 'Low',
  MEDIUM = 'Medium',
  HIGH = 'High',
}

export enum CustomerTypeEnum {
  INDIVIDUAL = 'Individual',
  CORPORATE = 'Corporate',
}

export enum CustomerAccountTypeEnum {
  Credit = 'Credit',
  NonCredit = 'Non-credit',
  Other = 'Other'
}

export enum VehicleStatusEnum {
  New = 'New',
  BookedIn = 'Booked in',
  PreCheck = 'Pre-check',
  PreCheckPassed = 'Pre-check passed',
  PreCheckFailed = 'Pre-check failed',
  InRepair = 'In repair',
  Allocated = 'Allocated',
  InWorkshop = 'In workshop',
  WeightCheck = 'Weight check',
  PostCheck = 'Post-check',
  Ready = 'Ready',
  PostCheckFailed = 'Post-check failed',
  AwaitingSubcontractorWork = 'Awaiting subcontractor work',
  BookedOut = 'Booked out',
  JobBlocked = 'Job blocked',
  JobInProgress = 'Job in progress',
  HandedOver = 'Handed over',
}

export enum VehicleProfileTypesEnum {
  Car = 'Car',
  Cdv = 'CDV',
  Chassis = 'Chassis',
  Na = 'N/A',
  Panel = 'Panel',
  PickUp = 'Pick up',
  Truck = 'Truck',
}

export enum VehicleFlowsEnum {
  OnSite = 'On-site',
  OffSite = 'Off-site',
}

export enum QuoteLineTaxUiNames {
  NoTax = '0%',
  FivePercent = '5%',
  TwentyPercent = '20%',
}

export enum CustomersStatusesEnum {
  Complete = 'Complete',
  Incomplete = 'Incomplete',
}

export enum VehicleSlidingDoorsQuantitiesUiNames {
  Zero = '0',
  One = '1',
  Two = '2',
}

export enum VehicleInfoSourcesUiNames {
  Catalog = 'Catalog',
  ManualInput = 'Manual input',
}

export enum QuoteLinePriceSourceUiNames {
  Stock = 'Stock',
  Pricelist = 'Pricelist',
  ManualInput = 'Manual input',
  Unknown = 'Unknown',
}

export enum AccountUserRolesUiNames {
  Admin = 'Admin',
  Hr = 'Hr',
  Management = 'Management',
  Production = 'Production',
  Sales = 'Sales',
  WoodManufacturing = 'Wood manufacturing',
  Subcontractor = 'Subcontractor',
  Customer = 'Customer',
}

export enum AccountUserStatusUiNames {
  Invited = 'Invited',
  InviteExpired = 'Invite expired',
  Active = 'Active',
  Inactive = 'Inactive',
  Reset = 'Reset',
}

export const ineligibleInviteStatusesList = [
  AccountUserStatusUiNames.Active, AccountUserStatusUiNames.Reset,
];

export const ineligibleResetStatusesList = [
  AccountUserStatusUiNames.Invited, AccountUserStatusUiNames.InviteExpired, AccountUserStatusUiNames.Inactive,
];

export const ineligibleActivateStatusesList = [
  AccountUserStatusUiNames.InviteExpired, AccountUserStatusUiNames.Active,
];

export const ineligibleInactivateStatusesList = [AccountUserStatusUiNames.Inactive];

export enum QuotePricelistStatusUiNames {
  Active = 'Active',
  UnderRevision = 'Under revision',
  Inactive = 'Inactive'
}

export enum QuoteInfoSourcesUiNames {
  CorePart = 'Core parts',
  NonCorePart = 'Non-core parts',
  PartsKit = 'Parts kits',
  Custom = 'Custom line',
}

export enum QuoteUnitsOfMeasureUiNames {
  Pcs = 'pcs',
  G = 'g',
  Kg = 'kg',
  Ml = 'ml',
  L = 'l',
  Mm = 'mm',
  Cm = 'cm',
  M = 'm',
  M2 = 'm2',
  M3 = 'm3',
  Hour = 'hour',
  Minute = 'minute',
}

// TODO: Remove TestModule when all permissions will be implemented
export enum PermissionEnum {
  TestModule = 'TestModule',
  EnquiryModule = 'EnquiryModule',
  EnquiryDashboardPage = 'EnquiryDashboardPage',
  EnquiryShowAccepted = 'EnquiryShowAccepted',
  EnquiryShowRejected = 'EnquiryShowRejected',
  EnquiryViewPage = 'EnquiryViewPage',
  EnquiryEditFields = 'EnquiryEditFields',
  EnquiryDuplicate = 'EnquiryDuplicate',
  EnquiryDelete = 'EnquiryDelete',
  EnquiryEditDeleteOtherNotes = 'EnquiryEditDeleteOtherNotes',
  EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions = 'EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions',
  // EnquirySpecificationsPage = 'EnquirySpecificationsPage',
  EnquiryCreatePage = 'EnquiryCreatePage',
  EnquiryCreateCustomerEnquiry = 'EnquiryCreateCustomerEnquiry',
  EnquiryExportToExcel = 'EnquiryExportToExcel',
  EnquiryExportToPdf = 'EnquiryExportToPdf',
  EnquiryChangeCustomer = 'EnquiryChangeCustomer',

  CustomerModule = 'CustomerModule',
  CustomerDashboardPage = 'CustomerDashboardPage',
  CustomerViewPage = 'CustomerViewPage',
  CustomerEditFields = 'CustomerEditFields',
  CustomerDelete = 'CustomerDelete',
  CustomerEditDeleteNotesOfOtherUsersInActivityLog = 'CustomerEditDeleteNotesOfOtherUsersInActivityLog',
  CustomerCreatePage = 'CustomerCreatePage',
  CustomerEditAccountType = 'CustomerEditAccountType',

  SettingsModule = 'SettingsModule',
  UserListPage = 'UserListPage',
  UserViewPage = 'UserViewPage',
  UserEditFields = 'UserEditFields',
  UserDelete = 'UserDelete',
  UserPermissionsPage = 'UserPermissionsPage',
  UserEditPermissions = 'UserEditPermissions',
  UserCreatePage = 'UserCreatePage',
  UserEditDeleteNotesOfOtherUsersInActivityLog = 'UserEditDeleteNotesOfOtherUsersInActivityLog',
  SettingsInfoMessagePage = 'SettingsInfoMessagePage',

  StockModule = 'StockModule',
  StockDashboard = 'StockDashboard',
  StockSearchPage = 'StockSearchPage',
  StockPartViewPage = 'StockPartViewPage',
  StockPartEditFields = 'StockPartEditFields',
  // StockPartMarkAsInStock = 'StockMarkAsInStock',
  // StockPartMarkAsOutOfStock = 'StockPartMarkAsOutOfStock',
  StockPartSetReorderLevels = 'StockPartSetReorderLevels',
  StockPartAdjustQuantity = 'StockPartAdjustQuantity',
  StockPartDelete = 'StockPartDelete',
  StockPartEditCode = 'StockPartEditCode',
  StockPartEditName = 'StockPartEditName',
  StockPartEditDeleteNotesOfOtherUsersInActivityLog = 'StockPartEditDeleteNotesOfOtherUsersInActivityLog',
  StockPartRequests = 'StockPartRequests',
  StockPartRequestViewPage = 'StockPartRequestViewPage',
  StockPartRequestEditPartLineFieldsAndUnlockPartLineActions = 'StockPartRequestEditPartLineFieldsAndUnlockPartLineActions',
  StockPartRequestEditDeleteNotesOfOtherUsersInActivityLog = 'StockPartRequestEditDeleteNotesOfOtherUsersInActivityLog',
  StockPurchaseOrders = 'StockPurchaseOrders',
  StockPurchaseOrdersViewPage = 'StockPurchaseOrdersViewPage',
  StockPurchaseOrdersExportToPdf = 'StockPurchaseOrdersExportToPdf',
  StockPurchaseOrdersDelete = 'StockPurchaseOrdersDelete',
  StockPurchaseOrdersEditFields = 'StockPurchaseOrdersEditFields',
  StockPurchaseOrdersEditPartLineFieldsAndUnlockPartLineActions = 'StockPurchaseOrdersEditPartLineFieldsAndUnlockPartLineActions',
  StockPurchaseOrderEditDeleteNotesOfOtherUsersInActivityLog = 'StockPurchaseOrderEditDeleteNotesOfOtherUsersInActivityLog',
  StockPurchaseOrdersCreate = 'StockPurchaseOrdersCreate',
  StockPartsKits = 'StockPartsKits',
  StockPartsKitViewPage = 'StockPartsKitViewPage',
  StockPartsKitEditFields = 'StockPartsKitEditFields',
  StockPartsKitDelete = 'StockPartsKitDelete',
  StockPartsKitEditDeleteNotesOfOtherUsersInActivityLog = 'StockPartsKitEditDeleteNotesOfOtherUsersInActivityLog',
  StockCreateNewPartsKit = 'StockCreateNewPartsKit',
  StockPartsKitEditComponentFieldsAndUnlockComponentActions = 'StockPartsKitEditComponentFieldsAndUnlockComponentActions',
  StockCreateNewPart = 'StockCreateNewPart',
  StockPartCategories = 'StockPartCategories',

  PricelistsModule = 'PricelistsModule',
  PricelistsDashboardPage = 'PricelistsDashboardPage',
  PricelistsViewPage = 'PricelistViewPage',
  PricelistEditFields = 'PricelistEditFields',
  PricelistDelete = 'PricelistDelete',
  PricelistCreatePage = 'PricelistCreatePage',
  PricelistEditDeleteNotesOfOtherUsersInActivityLog = 'PricelistEditDeleteNotesOfOtherUsersInActivityLog',
  PricelistEditPriceFieldsAndUnlockPriceActions = 'PricelistEditPriceFieldsAndUnlockPriceActions',
  PricelistExportToExcel = 'PricelistExportToExcel',

  SupplierModule = 'SupplierModule',
  SupplierDashboardPage = 'SupplierDashboardPage',
  SupplierViewPage = 'SupplierViewPage',
  SupplierEditFields = 'SupplierEditFields',
  SupplierDelete = 'SupplierDelete',
  SupplierCreatePage = 'SupplierCreatePage',
  SupplierEditDeleteNotesOfOtherUsersInActivityLog = 'SupplierEditDeleteNotesOfOtherUsersInActivityLog',

  OrderModule = 'OrderModule',
  OrderDashboard = 'OrderDashboard',
  OrderShowOrdersInCompletedStatus = 'OrderShowOrdersInCompletedStatus',
  OrderViewPage = 'OrderViewPage',
  OrderExportToExcel = 'OrderExportToExcel',
  OrderExportToPdf = 'OrderExportToPdf',
  OrderDelete = 'OrderDelete',
  OrderEditFields = 'OrderEditFields',
  OrderMarkPartLineAsFreeOfChargeBilled = 'OrderMarkPartLineAsFreeOfChargeBilled',
  OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions = 'OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions',
  OrderEditDeleteNotesOfOtherUsersInActivityLog = 'OrderEditDeleteNotesOfOtherUsersInActivityLog',
  OrderChangeCustomer = 'OrderChangeCustomer',

  RectificationModule = 'RectificationModule',
  RectificationDashboard = 'RectificationDashboard',
  RectificationShowInCompletedStatus = 'RectificationShowInCompletedStatus',
  RectificationViewPage = 'RectificationViewPage',
  RectificationEditFields = 'RectificationEditFields',
  RectificationExportToExcel = 'RectificationExportToExcel',
  RectificationExportToPDF = 'RectificationExportToPDF',
  RectificationDelete = 'RectificationDelete',
  RectificationMarkPartLineAsFreeOfChargeBilled = 'RectificationMarkPartLineAsFreeOfChargeBilled',
  RectificationCreate = 'RectificationCreate',
  RectificationPartRequestEditPartLineFieldsAndUnlockPartLineActions = 'RectificationPartRequestEditPartLineFieldsAndUnlockPartLineActions',
  RectificationEditDeleteNotesOfOtherUsersInActivityLog = 'RectificationEditDeleteNotesOfOtherUsersInActivityLog',

  JobModule = 'JobModule',
  JobListView = 'JobListView',
  JobDashboard = 'JobDashboard',
  JobTimeTrackingSummary = 'JobTimeTrackingSummary',
  JobShowInCompletedStatus = 'JobShowInCompletedStatus',
  JobShowInCancelledStatus = 'JobShowInCancelledStatus',
  JobCalendar = 'JobCalendar',
  JobCreateNewJob = 'JobCreateNewJob',
  JobViewPage = 'JobViewPage',
  JobEditFields = 'JobEditFields',
  JobTimeTracking = 'JobTimeTracking',
  JobDelete = 'JobDelete',
  JobEditDeleteNotesOfOtherUsersInActivityLog = 'JobEditDeleteNotesOfOtherUsersInActivityLog',

  EmployeeModule = 'EmployeeModule',
  EmployeeDashboard = 'EmployeeDashboard',
  EmployeeViewPage = 'EmployeeViewPage',
  EmployeeEditFields = 'EmployeeEditFields',
  EmployeeLeaveRequests = 'EmployeeLeaveRequests',
  EmployeeSkillsListPage = 'EmployeeSkillsListPage',
  EmployeeCreatePage = 'EmployeeCreatePage',
  EmployeeDelete = 'EmployeeDelete',
  EmployeeWorkingHours = 'EmployeeWorkingHours',
  EmployeeEditDeleteNotesOfOtherUsersInActivityLog = 'EmployeeEditDeleteNotesOfOtherUsersInActivityLog',

  ReportModule = 'ReportModule',
  ReportHRPage = 'ReportHRPage',
  ReportManagementPage = 'ReportManagementPage',
  ReportProductionPage = 'ReportProductionPage',
  ReportPurchasingPage = 'ReportPurchasingPage',
  ReportSalesPage = 'ReportSalesPage',
  ReportRectificationReport = 'ReportRectificationReport',
  ReportRectificationByProductReport = 'ReportRectificationByProductReport',
  ReportRectificationBySupplierReport = 'ReportRectificationBySupplierReport',
  ReportSalesNumberOfConvertedVehiclesReport = 'ReportSalesNumberOfConvertedVehiclesReport',
  ReportSalesOfficeEmployeeReport = 'ReportSalesOfficeEmployeeReport',
  ReportStockPage = 'ReportStockPage',
  ReportStockFullReport = 'ReportStockFullReport',
  ReportUnfinishedEntitiesReport = 'ReportUnfinishedEntitiesReport',
  ReportProductionFitmentTimeReport = 'ReportProductionFitmentTimeReport',
  ReportPurchasingOutstandingOverduePurchaseOrdersReport = 'ReportPurchasingOutstandingOverduePurchaseOrdersReport',
  ReportPurchasingSupplierSpendReport = 'ReportPurchasingSupplierSpendReport',
  ReportPurchasingFitterReturnsReport = 'ReportPurchasingFitterReturnsReport',
  ReportSoldProductsByCustomer = 'ReportSoldProductsByCustomer',
  ReportSalesByCustomerReport = 'ReportSalesByCustomerReport',
  ReportSalesOffsiteWorkingReport = 'ReportSalesOffsiteWorkingReport',
  ReportPurchasingCancelledLinesReport = 'ReportPurchasingCancelledLinesReport',

  VehicleModule = 'VehicleModule',
  VehicleCreate = 'VehicleCreate',
  VehicleDashboard = 'VehicleDashboard',
  VehicleViewPage = 'VehicleViewPage',
  VehicleEditFields = 'VehicleEditFields',
  VehicleProfiles = 'VehicleProfiles',
  VehicleEditDeleteNotesOfOtherUsersInActivityLog = 'VehicleEditDeleteNotesOfOtherUsersInActivityLog',
  VehicleDelete = 'VehicleDelete',

  InvoicingModule = 'InvoicingModule',
  InvoicingSales = 'InvoicingSales',
  InvoicingPurchases = 'InvoicingPurchases',
  InvoicingNominalCodes = 'InvoicingNominalCodes',
  InvoicingManageNominalCodes = 'InvoicingManageNominalCodes',
  InvoicingPurchaseViewPageWithInvoices = 'InvoicingPurchaseViewPageWithInvoices',
  InvoicingExportPurchaseInvoices = 'InvoicingExportPurchaseInvoices',
  InvoicingSalesViewPageWithInvoices = 'InvoicingSalesViewPageWithInvoices',
  InvoicingExportSalesInvoices = 'InvoicingExportSalesInvoices',
}

export enum InfoMessageStatusUiNameEnum{
  Published = 'Published',
  Unpublished = 'Unpublished'
}

export enum PricelistStatusUiNames {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum PartsKitsStatusUiNames {
  Active = 'Active',
  Inactive = 'Inactive'
}

export enum SupplierStatusUiNames {
  Unverified = 'Unverified',
  Verified = 'Verified',
}

export enum StockPartStatusesEnum {
  InStock = 'In stock',
  OutOfStock = 'Out of stock',
}

export enum PartRequestLineStatusTransitionEnum {
  NotForStock = 'NotForStock',
  Confirm = 'Confirm',
}

export enum StockPartTypesEnum {
  Part = 'Part',
  Consumable = 'Consumable',
}
export enum JobCategoriesUiNames {
  AccessoriesFitting = 'Accessories fitting',
  ElectricalSystems = 'Electrical systems',
  GeneralFitting = 'General fitting',
  KitProduction = 'Kit production',
}

export enum AttachmentsOrdering {
  Name = 'Name',
  Size = 'Size',
  UploadDate = 'UploadDate',
  Comment = 'Comment',
}

export enum AttachmentsOrderingEnum {
  Name = 1,
  Size,
  UploadDate,
  Comment,
}

export enum AttachmentTypesUiNames {
  File = 'File',
  Image = 'Image',
}

export enum LinksTypesUiNames {
  Enquiry = 'Enquiries',
  Order = 'Orders',
  Job = 'Jobs',
  Rectification = 'Rectifications',
  Vehicle = 'Vehicle',
}

export const LinksPath = {
  [LinksTypesUiNames.Enquiry]: routesPath.ENQUIRIES,
  [LinksTypesUiNames.Order]: routesPath.ORDERS,
  [LinksTypesUiNames.Job]: routesPath.JOBS,
  [LinksTypesUiNames.Rectification]: routesPath.RECTIFICATIONS,
  [LinksTypesUiNames.Vehicle]: routesPath.VEHICLES,
};

export enum UNASSIGNED_TAG_NAME { UNASSIGNED = 'Unassigned' }

export enum PartRequestSubmissionStatusesEnum {
  InitialDraftEmpty = 'Initial draft empty',
  InitialDraftWithLines = 'Initial draft with lines',
  Submitted = 'Submitted',
  SubmittedWithChanges = 'Submitted with changes',
}

export enum LeaveRequestTypesEnum {
  AnnualLeave = 'Annual leave',
  SickLeave = 'Sick leave',
  UnpaidLeave = 'Unpaid leave',
  Other = 'Other',
}
export enum LeaveRequestTypesShortEnum {
  AnnualLeave = 'AL',
  SickLeave = 'SL',
  UnpaidLeave = 'UL',
  Other = 'OT',
}
export enum LeaveRequestsStatusesUiEnum {
  Draft = 'Draft',
  PendingApproval = 'Pending approval',
  Rejected = 'Rejected',
  Approved = 'Approved',
}

export enum LeaveRequestsStatusCategoriesEnum {
  ACTIVE = 'active',
  ARCHIVED = 'archived',
}

export enum DayPartsEnum {
  AllDay = 'All day',
  Am = 'AM',
  Pm = 'PM',
}

export enum LeaveRequestOrdering {
  CreationDate = 'CreationDate'
}

export enum JobCheckStatusesEnum {
  NA = 'N/A',
  Pass = 'Passed',
  Fail = 'Failed',
}

export enum JobCheckDecision {
  NA = 'N/A',
  Pass = 'Pass',
  Fail = 'Fail',
}

export enum NominalRecordVatTypesEnum {
  Percentage = 'VAT, %',
  Amount = 'Amount',
}

export enum PurchaseOrderPartAmountInputTypesEnum {
  Positive = 'Positive',
  Negative = 'Negative',
}
