import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { summaryFieldValueHandle } from '../../../core/utils/summaryFieldValueHandle';
import { AddressFields } from '../../types/commonTypes';
import { BooleanFunctionType } from '../../../core/types/coreTypes';

type AddressFieldProps = {
  addressFields: AddressFields | null;
  setAddressModal?: BooleanFunctionType;
  title?: string;
  disabled?: boolean;
  isIconVisible?: boolean;
  className?: string,
  addressError?: string,
}

const AddressField: React.FC<AddressFieldProps> = ({
  addressFields, setAddressModal, title = 'Address', disabled,
  isIconVisible = true, className, addressError,
}) => {
  const address = addressFields ? summaryFieldValueHandle(Object.values(addressFields), ' ') : '';
  return (
    <div className={classNames(className, 'location-field details-form__field--lg')}>
      <span className="location-field__label">{title}</span>
      <div
        className={classNames('location-field__input', { 'location-field__input--disabled': disabled })}
        onClick={() => !disabled && setAddressModal && setAddressModal(true)}
        onKeyDown={(e) => {
          if (e.code === 'Enter' || e.code === 'Space') {
            e.preventDefault();
            !disabled && setAddressModal && setAddressModal(true);
          }
        }}
        tabIndex={disabled ? -1 : 0}
        role="button"
      >
        {
          isIconVisible && <FontAwesomeIcon icon={faMapMarkerAlt} className="location-field__icon" />
        }
        <address className="location-field__value">{address}</address>
      </div>
      {addressError && <span className="location-field__error">{addressError}</span>}
    </div>
  );
};

export default AddressField;
