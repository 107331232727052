import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { StockPartTypesEnum } from '../../core/enums/dictionariesEnums';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { labelHandle } from '../../core/utils/labelHandle';
import {
  allowedShortBaForUserSelector,
  pricelistTypesSelector,
  QuoteUnitsOfMeasureSelector,
  shortBusinessAreasSelector,
} from './coreSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { partPricesSelector } from './pricelistSelectors';
import { StockPartsOrders } from '../../stock/search/enums/StockPartsOrders';
import { PricelistPricesOrderingEnum } from '../../pricelists/dashboard/enums/pricelistPricesOrderingEnum';
import {
  PurchaseOrderStatusUiNames,
  PurchaseUrlParamsNames,
  StockTabParamEnum,
} from '../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { routesPath } from '../../core/enums/pathEnum';
import { StockPageParamEnums } from '../../stock/container/enums/stockEnums';
import { PartAdjustQtyOrderingEnum } from '../../stock/part-view-page/enums/PartTypesEnum';
import { getSortIndex } from '../../core/utils/getSortIndex';
import {
  partCategoriesLookupSelector, partCategoriesTreeSelector, partSubcategoriesFilteredLookupSelector,
  partSubcategoriesLookupSelector,
  suitableVehiclesProfilesSelector,
} from './sharedSelectors';
import { AffectedCategoriesOrdering, PartSubcategoriesOrderingEnum } from '../../stock/part-categories/types/partCategoriesTypes';
import { PartRequestsUrlParamsNames, StockPartReqStatusesEnum } from '../../stock/stock-part-requests/enums/stockPartRequestsEnum';
import {
  purchaseOrderStatusObjSelector,
  partRequestStatusObjSelector, stockPartStatusesSelector,
} from './coreStatusesSelectors';
import {
  adjustPartQtyOrderingSelector,
  partCategoryAffectedOrderingSelector,
  partPricesOrderingSelector,
  partSubcategoryOrderingSelector, stockPartsOrderingSelector,
} from './coreOrderingSelectors';
import { AffectedEntityLineUi } from '../../common/types/commonTypes';
import { DEFAULT_UI_DATE_FORMAT } from '../../core/utils/regex';
import { toPoundCurrency } from '../../common/utils/formatUtils';

export const stockPartTypesSelector = (state: RootState) => state.stock.stockPartTypes;
export const stockPartTypesValuesSelector = createSelector(stockPartTypesSelector, (types) => ({
  part: types.find((type) => type.label === StockPartTypesEnum.Part)?.value,
  consumable: types.find((type) => type.label === StockPartTypesEnum.Consumable)?.value,
}));

export const stockPartsColumnsSelector = createSelector(stockPartsOrderingSelector, (ordering) => {
  return [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.PartCode, ordering),
      filterSearch: true,
    },
    {
      title: 'Manufacturer code',
      dataIndex: 'manufacturerCode',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.ManufacturerCode, ordering),
      filterSearch: true,
    },
    {
      title: 'Part name',
      dataIndex: 'name',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.Name, ordering),
      filterSearch: true,
    },
    {
      title: 'Category',
      dataIndex: 'categoryId',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.Category, ordering),
      filterName: 'categoryIds',
      filterOptions: 'stockCategories',
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategoryId',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.Subcategory, ordering),
      filterName: 'subcategoryIds',
      filterOptions: 'subCategories',
    },
    {
      title: 'Business area',
      dataIndex: 'businessAreas',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      orderField: getSortIndex(StockPartsOrders.Status, ordering),
      filterName: 'statuses',
      filterOptions: 'partsKitsStatuses',
    },
  ];
});
export const stockSearchFiltersSelector = (state: RootState) => state.stock.stockSearchFilters;
export const stockSearchTreeFiltersList = createSelector(stockSearchFiltersSelector, partCategoriesTreeSelector, (filtersValues, tree) => {
  return {
    label: 'Categories/Subcategories',
    name: 'subcategoryIds',
    options: tree,
    value: filtersValues.subcategoryIds || [],
  };
});

export const stockSearchFiltersList = createSelector(
  stockSearchFiltersSelector,
  partCategoriesLookupSelector,
  partSubcategoriesFilteredLookupSelector,
  stockPartTypesSelector,
  allowedShortBaForUserSelector,
  stockPartStatusesSelector,
  suitableVehiclesProfilesSelector,
  (
    filters,
    stockCategories,
    subCategories,
    partTypes,
    shortBusinessAreas,
    partStatuses,
    suitableVehicles,
  ) => {
    return [
      {
        value: filters.types || [],
        name: 'types',
        label: 'Type',
        options: partTypes || [],
        noSearch: true,
      },
      {
        value: filters.isCorePart,
        name: 'isCorePart',
        label: 'Core part',
        options: [
          { value: 'true', label: 'Yes' },
          { value: 'false', label: 'No' },
        ],
        noSearch: true,
      },
      {
        value: filters.businessAreas,
        name: 'businessAreas',
        label: 'Business area',
        options: shortBusinessAreas || [],
        noSearch: true,
      },
      {
        value: filters.statuses,
        name: 'statuses',
        label: 'Status',
        options: partStatuses || [],
        noSearch: true,
      },
      {
        value: filters.vehicleIds,
        name: 'vehicleIds',
        label: 'Suitable for vehicles',
        options: suitableVehicles.items || [],
      },
    ];
  },
);

export const stockSearchResultSelector = (state: RootState) => state.stock.stockSearchResult;
export const stockTableModeResultSelector = createSelector(
  stockSearchFiltersSelector,
  partCategoriesLookupSelector,
  partSubcategoriesLookupSelector,
  shortBusinessAreasSelector,
  stockPartStatusesSelector,
  stockSearchResultSelector,
  (filters, categories, subcategories, ba, statuses, data) => {
    const { page, pageSize } = filters;
    return {
      totalCount: data.totalCount,
      pages: data.pages,
      items: data.items?.map((item, i) => ({
        id: item.id,
        number: getNo(page, pageSize, i),
        partCode: item.partCode,
        manufacturerCode: item.manufacturerCode,
        name: item.name,
        categoryId: item.categoryId ? labelHandle(item.categoryId, categories) : item.categoryId,
        subcategoryId: item.subcategoryId ? labelHandle(item.subcategoryId, subcategories || []) : item.subcategoryId,
        businessAreas: item.businessAreas !== null ? labelHandle(item.businessAreas, ba) : item.businessAreas,
        status: labelHandle(item.status, statuses),
      })),
    };
  },
);

export const stockPartDetailsSelector = (state:RootState) => state.stock.partDetails;
export const partSuitableForSelector = createSelector(
  stockPartDetailsSelector,
  (details) => {
    return details?.part.suitableForVehicles?.map((veh) => ({
      value: veh.id,
      label: veh.modelDescription || 'N/A',
    })) || [];
  },
);
export const stockPartImageDataSelector = (state: RootState) => state.stock.partImageData;
export const stockPartTotalQuantitySelector = createSelector(
  stockPartDetailsSelector,
  (partDetails) => {
    const {
      free, allocated, picked, collected, withoutSellingPrice,
    } = partDetails?.part || {};
    return (free || 0) + (withoutSellingPrice || 0) + (allocated || 0) + (picked || 0) + (collected || 0);
  },
);
export const stockPartAffectedEntitiesFiltersSelector = (state: RootState) => state.stock.partAffectedEntitiesFilters;
const stockPartAffectedItemsInitSelector = (state: RootState) => state.stock.partAffectedEntities;
export const stockPartAffectedListSelector = createSelector(
  shortBusinessAreasSelector,
  stockPartAffectedItemsInitSelector,
  (ba, data) => {
    const { items, pages, totalCount } = data;
    const entities: AffectedEntityLineUi[] = items.map((item) => ({
      entityId: item.entityId,
      entityType: item.entityType,
      entityNumber: item.entityNumber,
      createdAt: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.createdAt),
      entityStatus: item.entityStatus || '-',
      businessArea: labelHandle(item.businessAreas, ba).toString(),
      partLinesCount: item.partLinesCount,
    }));

    return { pages, totalCount, items: entities };
  },
);

export const partViewPartsKitsList = (state: RootState) => state.stock.partViewPartsKitsList;
export const partViewPartsKitsFiltersSelector = (state: RootState) => state.stock.partViewPartsKitsFilters;
export const stockPartAttachmentsSelector = (state:RootState) => state.stock.partAttachments;
export const stockPartAttachmentsFilterSelector = (state:RootState) => state.stock.partAttachmentsFilters;

const partsStatisticSelector = (state: RootState) => state.stock.stockPartsStatistic;
export const partsStatisticLookupSelector = createSelector(partsStatisticSelector, (partsStatistic) => [
  {
    label: 'Free',
    value: partsStatistic?.free || 0,
  },
  {
    label: 'Allocated',
    value: partsStatistic?.allocated || 0,
  },
  {
    label: 'Picked',
    value: partsStatistic?.picked || 0,
  },
  {
    label: 'Collected',
    value: partsStatistic?.collected || 0,
  },
  {
    label: 'Reserved',
    value: partsStatistic?.reserved || 0,
  },
  {
    label: 'Due in',
    value: partsStatistic?.dueIn || 0,
  },
]);

const partRequestsStatisticSelector = (state: RootState) => state.stock.stockPartRequestStatistic;
export const partRequestsStatisticLookupSelector = createSelector(
  partRequestsStatisticSelector,
  partRequestStatusObjSelector,
  (statistic, statusObj) => {
    const {
      statusNew, archived, collected, inProgress,
    } = statusObj;
    const { STOCK } = routesPath;
    const { PAGE, TAB_TYPE, PART_REQ_STATUS } = PartRequestsUrlParamsNames;
    const { ACTIVE, ARCHIVED } = StockTabParamEnum;
    const { PART_REQUESTS } = StockPageParamEnums;
    const rootPath = `/${STOCK}?${PAGE}=${PART_REQUESTS}`;
    const activeStatusLink = `${rootPath}&${TAB_TYPE}=${ACTIVE}&${PART_REQ_STATUS}`;
    const archivedStatusLink = `${rootPath}&${TAB_TYPE}=${ARCHIVED}&${PART_REQ_STATUS}`;

    return [
      {
        label: StockPartReqStatusesEnum.New,
        value: statistic?.new || 0,
        url: `${activeStatusLink}=${statusNew}`,
        isActive: true,
        status: statusNew,
      },
      {
        label: StockPartReqStatusesEnum.InProgress,
        value: statistic?.inProgress || 0,
        url: `${activeStatusLink}=${inProgress}`,
        isActive: true,
        status: inProgress,
      },
      {
        label: StockPartReqStatusesEnum.Collected,
        value: statistic?.collected || 0,
        url: `${activeStatusLink}=${collected}`,
        isActive: true,
        status: collected,
      },
      {
        label: StockPartReqStatusesEnum.Archived,
        value: statistic?.archived || 0,
        url: `${archivedStatusLink}=${archived}`,
        isActive: false,
        status: archived,
      },
    ];
  },
);

const stockPurchaseOrdersStatisticSelector = (state: RootState) => state.stock.stockPurchaseOrderStatistic;
export const stockPurchaseOrdersLookupStatisticSelector = createSelector(
  stockPurchaseOrdersStatisticSelector,
  purchaseOrderStatusObjSelector,
  (statistic, statusObj) => {
    const {
      statusNew, finalised, sentToSupplier, partiallyReceived, received, completed,
    } = statusObj;
    const { STOCK } = routesPath;
    const { PAGE, TAB_TYPE, PURCHASE_STATUS } = PurchaseUrlParamsNames;
    const { ACTIVE, ARCHIVED } = StockTabParamEnum;
    const { PURCHASES } = StockPageParamEnums;
    const rootPath = `/${STOCK}?${PAGE}=${PURCHASES}`;
    const activeStatusLink = `${rootPath}&${TAB_TYPE}=${ACTIVE}&${PURCHASE_STATUS}`;
    const archivedStatusLink = `${rootPath}&${TAB_TYPE}=${ARCHIVED}&${PURCHASE_STATUS}`;

    return [
      {
        label: PurchaseOrderStatusUiNames.New,
        value: statistic?.new || 0,
        url: `${activeStatusLink}=${statusNew}`,
        isActive: true,
        status: statusNew,
      },
      {
        label: PurchaseOrderStatusUiNames.Finalised,
        value: statistic?.finalised || 0,
        url: `${activeStatusLink}=${finalised}`,
        isActive: true,
        status: finalised,
      },
      {
        label: PurchaseOrderStatusUiNames.SentToSupplier,
        value: statistic?.sentToSupplier || 0,
        url: `${activeStatusLink}=${sentToSupplier}`,
        isActive: true,
        status: sentToSupplier,
      },
      {
        label: PurchaseOrderStatusUiNames.PartiallyReceived,
        value: statistic?.partiallyReceived || 0,
        url: `${activeStatusLink}=${partiallyReceived}`,
        isActive: true,
        status: partiallyReceived,
      },
      {
        label: PurchaseOrderStatusUiNames.Received,
        value: statistic?.received || 0,
        url: `${activeStatusLink}=${received}`,
        isActive: true,
        status: received,
      },
      {
        label: PurchaseOrderStatusUiNames.Completed,
        value: statistic?.completed || 0,
        url: `${archivedStatusLink}=${completed}`,
        isActive: false,
        status: completed,
      },
    ];
  },
);

export const stockPartReorderLevelsSelector = (state: RootState) => state.stock.stockPartReorderLevels;
export const stockPartReorderLevelsFiltersSelector = (state: RootState) => state.stock.stockPartReorderLevelsFilters;
export const stockPartReorderLevelsTypesSelector = createSelector(stockPartsOrderingSelector, (partsOrdering) => {
  const getSortIndex = (field: StockPartsOrders) => partsOrdering.find((el) => el.label === field)?.value;
  return {
    warning: getSortIndex(StockPartsOrders.WarningReorderShortage),
    critical: getSortIndex(StockPartsOrders.CriticalReorderShortage),
  };
});

export const selectPricelistAndQtyFiltersSelector = (state: RootState) => state.stock.selectPricelistAndQtyFilters;
export const selectPricelistColumnsSelector = createSelector(partPricesOrderingSelector, (ordering) => {
  return [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Pricelist name',
      dataIndex: 'name',
      sorter: true,
      orderField: getSortIndex(PricelistPricesOrderingEnum.PricelistName, ordering),
    },
    {
      title: 'Pricelist type',
      dataIndex: 'pricelistType',
      sorter: true,
      orderField: getSortIndex(PricelistPricesOrderingEnum.PricelistType, ordering),
    },
    {
      title: 'Cost price',
      dataIndex: 'costPrice',
      sorter: true,
      orderField: getSortIndex(PricelistPricesOrderingEnum.CostPrice, ordering),
    },
  ];
});

export const partSelectPricelistResultTableSelector = createSelector(
  partPricesSelector,
  selectPricelistAndQtyFiltersSelector,
  pricelistTypesSelector,
  (items, filters, types) => {
    const newItems = items.items.map((el, index) => ({
      id: el.pricelistId,
      number: getNo(filters?.page || 1, filters?.pageSize || 5, index),
      pricelistName: el.pricelistName,
      pricelistType: types.find((type) => el.pricelistType === type.value)?.label || el.pricelistType,
      costPrice: `£ ${el.costPrice || 0}`,
    }));
    return { items: newItems, totalCount: items.totalCount, pages: items.pages };
  },
);

export const partQuantitiesPagingSelector = (state: RootState) => state.stock.partQuantitiesPaging;

export const partQuantitiesSelector = (state: RootState) => state.stock.partQuantities;

const partQuantitiesAvailablePurchaseOrdersInitSelector = (state: RootState) => state.stock.partQuantitiesAvailablePurchaseOrders;
export const partQuantitiesAvailablePurchaseOrdersSelector = createSelector(partQuantitiesAvailablePurchaseOrdersInitSelector, (list) => {
  return list?.map((p) => ({
    value: p.purchaseOrderId || 'null',
    label: p.purchaseOrderNumber || '(Blank)',
  }));
});

export const partQuantitiesTableViewSelector = createSelector(
  partQuantitiesSelector,
  partQuantitiesPagingSelector,
  QuoteUnitsOfMeasureSelector,
  stockPartDetailsSelector,
  (data, paging, measures, partDetails) => {
    const unitOfMeasure = measures.find((m) => m.value === partDetails?.part.unitOfMeasure)?.label;
    const newItems = data.items?.map((item, i) => ({
      id: item.id,
      number: getNo(paging.page, paging.pageSize, i),
      quantity: `${item.quantity} ${unitOfMeasure || ''}`,
      costPrice: toPoundCurrency(item.costPrice, '-', 4),
      sellingPrice: toPoundCurrency(item.sellingPrice),
      addedDate: localDateFormatHandler('DD/MM/YYYY', item.addedDate),
      purchaseOrderNo: item.purchaseOrderNumber || '-',
      orderId: item.orderId,
      linkedTo: item.orderNumber,
    }));
    return {
      items: newItems,
      pages: data.pages,
      totalCount: data.totalCount,
    };
  },
);
export const adjustQtyColumnsSelector = createSelector(
  partQuantitiesAvailablePurchaseOrdersSelector,
  adjustPartQtyOrderingSelector,
  (data, ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Cost price',
        dataIndex: 'costPrice',
      },
      {
        title: 'Selling price',
        dataIndex: 'sellingPrice',
      },
      {
        title: 'Added date',
        dataIndex: 'addedDate',
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.AddedDate, ordering),
      },
      {
        title: 'Purchase order No',
        dataIndex: 'purchaseOrderNo',
        filters: data || [],
        sorter: true,
        orderField: getSortIndex(PartAdjustQtyOrderingEnum.PurchaseOrderNumber, ordering),
      },
      {
        title: 'Linked to',
        dataIndex: 'linkedTo',
        filterSearch: true,
      },
    ];
  },
);

export const stockPartSubcategoriesSelector = (state: RootState) => state.stock.stockPartSubcategories;
export const stockPartSubcategoriesColumnsSelector = createSelector(
  partSubcategoryOrderingSelector,
  (ordering) => {
    return [
      {
        title: 'Subcategory',
        dataIndex: 'keyword',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(PartSubcategoriesOrderingEnum.SubcategoryName, ordering),
      },
      {
        title: 'Created date',
        dataIndex: 'createdAt',
        sorter: true,
        isDateRange: true,
        orderField: getSortIndex(PartSubcategoriesOrderingEnum.CreatedDate, ordering),
      },
      {
        title: 'Modified date',
        dataIndex: 'updatedAt',
        sorter: true,
        isDateRange: true,
        orderField: getSortIndex(PartSubcategoriesOrderingEnum.ModifiedDate, ordering),
      },
    ];
  },
);
export const stockPartSubcategoriesFiltersSelector = (state: RootState) => state.stock.stockPartSubcategoriesFilters;

export const stockPartAffectedCategoriesFiltersSelector = (state: RootState) => state.stock.stockPartAffectedCategoriesFilters;
const stockPartAffectedCategoriesInitSelector = (state: RootState) => state.stock.stockPartAffectedCategories;
export const stockPartAffectedCategoriesListSelector = createSelector(
  shortBusinessAreasSelector,
  stockPartAffectedCategoriesInitSelector,
  (businessAreas, affectedData) => ({
    pages: affectedData.pages,
    totalCount: affectedData.totalCount,
    items: affectedData.items.map((el) => ({
      entityType: el.entityType,
      entityNumber: el.entityNumber,
      createdAt: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.createdAt),
      entityStatus: el.entityStatus || '-',
      businessArea: el.businessAreas?.map((ba) => businessAreas.find((b) => b.value === ba)?.label || '-').join(', ') || '-',
    })),
  }),
);

export const stockPartAffectedCategoriesColumnsSelector = createSelector(
  partCategoryAffectedOrderingSelector,
  (ordering) => [
    {
      title: 'Entity type',
      dataIndex: 'entityType',
      sorter: true,
      orderField: getSortIndex(AffectedCategoriesOrdering.EntityType, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
    },
    {
      title: 'Created date',
      dataIndex: 'createdAt',
    },
    {
      title: 'Entity status',
      dataIndex: 'entityStatus',
    },
    {
      title: 'Business area',
      dataIndex: 'businessArea',
    },
  ],
);

export const partViewPageTimelineSelector = (state: RootState) => state.stock.partViewPageTimeline;
export const partViewPageTimelineFiltersSelector = (state: RootState) => state.stock.partViewPageTimelineFilters;
