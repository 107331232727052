import React from 'react';
import { EmployeeEventTypesEnum } from '../../../../enums/EmployeeViewEnums';
import { EmployeeActivityEventType } from '../../../../types/employeeViewTypes';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { useAppSelector } from '../../../../../../store/hooks';
import { leaveRequestStatusesUiSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import { dayPartsUiSelector, leaveRequestTypesUiSelector } from '../../../../../../store/selectors/coreSelectors';

type LeaveRequestEventProps = {
  event: EmployeeEventTypesEnum,
  item: EmployeeActivityEventType,
}

const LeaveRequestEvent: React.FC<LeaveRequestEventProps> = ({ event, item }) => {
  const statuses = useAppSelector(leaveRequestStatusesUiSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);
  const types = useAppSelector(leaveRequestTypesUiSelector);

  const leaveRequestInfo = `Leave request 
  (${localDateFormatHandler('DD/MM/YYYY', item.startDate)} - ${localDateFormatHandler('DD/MM/YYYY', item.endDate)}) `;

  const getStatus = (value: string | number | undefined) => {
    return labelHandle(value, statuses).toString();
  };

  const valueHandle = (value: string | number | undefined, dayPart: number | null | undefined) => {
    if (!value) return '';
    switch (item.field) {
      case 'StartDate':
      case 'EndDate':
        return <span className="nowrap">
          {localDateFormatHandler('DD/MM/YYYY', value.toString())}
          {!!dayPart && ` (${labelHandle(dayPart, dayParts)})`}
        </span>;
      case 'LeaveType':
        return labelHandle(+value, types);
      default: return value;
    }
  };

  const renderLeaveRequestEvent = () => {
    switch (event) {
      case EmployeeEventTypesEnum.LeaveRequestCreation:
        return <>
          {leaveRequestInfo}
          was created
        </>;
      case EmployeeEventTypesEnum.LeaveRequestFieldUpdateOrAddingValue:
        return <>
          {leaveRequestInfo}
          field
          {' '}
          <b>{fieldNameEventHandle(item.field)}</b>
          {' '}
          was changed:
          {' '}
          {!!item.oldValue && <s>{valueHandle(item.oldValue, item.oldDayPart)}</s>}
          {!!item.newValue && <b>
            {' '}
            {valueHandle(item.newValue, item.newDayPart)}
          </b>}
        </>;
      case EmployeeEventTypesEnum.LeaveRequestStatusChange:
        return <>
          {leaveRequestInfo}
          status changed:
          <StatusChangeEvent prevStatus={getStatus(item.oldValue)} nextStatus={getStatus(item.newValue)} />
        </>;
      case EmployeeEventTypesEnum.LeaveRequestDeletion:
        return <>
          {leaveRequestInfo}
          was deleted
        </>;
      default: return null;
    }
  };

  return <>{renderLeaveRequestEvent()}</>;
};

export default LeaveRequestEvent;
