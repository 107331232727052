import React, { useState } from 'react';
import {
  faEllipsisVertical, faPencil, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import Tr from '../../../core/components/table-components/Tr';
import { IInfoMessage } from '../types/infoMessagesTypes';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { infoMessagesStatusValuesSelector, infoMessageStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import {
  deleteInfoMessageThunk,
  publishInfoMessageThunk, unpublishInfoMessageThunk,
} from '../../../store/thunks/settings/infoMessagesThunks';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import usePermission from '../../../permissions-handling/permissionHook';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../core/components/table-components/table-new/TdNew';

type InfoMessagesListCellsProps = {
  data: IInfoMessage,
  openEditModal: VoidFunctionType;
  updateState: VoidFunctionType;
}

const InfoMessagesListCells: React.FC<InfoMessagesListCellsProps> = ({ data, openEditModal, updateState }) => {
  const dispatch = useAppDispatch();
  const [isDelete, setDelete] = useState(false);
  const infoMessageStatuses = useAppSelector(infoMessageStatusesSelector);
  const { published, unpublished } = useAppSelector(infoMessagesStatusValuesSelector);
  const [currentInfoMessage, setCurrentInfoMessage] = useState<IInfoMessage>();
  const [isPublish, setPublish] = useState(false);
  const allowedEdit = usePermission(PermissionEnum.SettingsInfoMessagePage);

  const deleteInfoMessageHandler = () => {
    currentInfoMessage?.id && dispatch(deleteInfoMessageThunk(
      {
        id: currentInfoMessage.id,
        updateState,
        onClose: () => setDelete(false),
      },
    ));
  };

  const publishInfoMessagesHandler = () => {
    currentInfoMessage?.id && dispatch(publishInfoMessageThunk({
      id: currentInfoMessage.id,
      updateState,
    }));
    setPublish(false);
  };

  const unPublishInfoMessagesHandler = (infoMessage:IInfoMessage) => {
    dispatch(unpublishInfoMessageThunk({
      id: infoMessage.id,
      updateState,
    }));
  };

  const truncateValues = ['title', 'message'];

  const line = {
    id: data.id,
    title: data.title,
    message: data.message,
    displayOrder: data.displayOrder,
    status: infoMessageStatuses.find((el) => el.value === data.status)?.label || '',
    createdAt: localDateFormatHandler('DD-MMM-YYYY', data.createdAt),
  };

  const actions = [
    {
      label: 'Edit message',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: () => openEditModal(),
      key: 'editMessage',
    },
    {
      label: 'Publish message',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      key: 'publishMessages',
      onClick: () => {
        setCurrentInfoMessage(data);
        setPublish(true);
      },
      disabled: data.status === published,
    },
    {
      label: 'Unpublish message',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      key: 'unpublishMessage',
      onClick: () => {
        unPublishInfoMessagesHandler(data);
      },
      disabled: data.status === unpublished,
    },
    {
      label: 'Delete message',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      key: 'deleteMessage',
      onClick: () => {
        setCurrentInfoMessage(data);
        setDelete(true);
      },
    },
  ];

  return (
    <>
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => setDelete(false)}
        confirmHandler={deleteInfoMessageHandler}
      />
      <ConfirmationModal
        customTitle="Publish message"
        isVisible={isPublish}
        onCancel={() => setPublish(false)}
        confirmHandler={publishInfoMessagesHandler}
        customText={<>
          You are about to publish
          <b>{` "${currentInfoMessage?.title}" `}</b>
          message.
        </>}
        customCreateLabel="Yes, publish"
      />
      <Tr>
        {Object.entries(line).map(([key, value]) => {
          if (key === 'id') return null;
          return <TdNew key={key}>
            {truncateValues.includes(key)
              ? <TruncateTooltip
                value={value}
                onClick={() => allowedEdit && openEditModal()}
                className="table-clickable-content"
                table
              />
              : value}
          </TdNew>;
        })}
        <TdNew action>
          <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={actions}
            disabled={!allowedEdit}
          />
        </TdNew>
      </Tr>
    </>
  );
};

export default InfoMessagesListCells;
