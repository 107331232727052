import React from 'react';
import PurchaseDetails from './PurchaseDetails';
import PurchaseActivity from './PurchaseActivity';
import { BooleanFunctionType } from '../../../../../../core/types/coreTypes';
import { AddressFields } from '../../../../../../common/types/commonTypes';

type PurchaseDetailsAndActivityProps = {
  setRequisiteModal: BooleanFunctionType,
  setLocationModal: BooleanFunctionType,
  setAddressFields: React.Dispatch<React.SetStateAction<AddressFields | null>>
}

const PurchaseDetailsAndActivity: React.FC<PurchaseDetailsAndActivityProps> = ({
  setRequisiteModal,
  setLocationModal,
  setAddressFields,
}) => {
  return (
    <div className="info-grid">
      <PurchaseDetails setRequisiteModal={setRequisiteModal} setLocationModal={setLocationModal} setAddressFields={setAddressFields} />
      <PurchaseActivity />
    </div>
  );
};

export default PurchaseDetailsAndActivity;
