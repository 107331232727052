import React from 'react';
import Chart from '../../../../../../core/components/chart/Chart';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  fitmentTimeDataChartNewSelector,
  reportIsVisibleSelector,
} from '../../../../../../store/selectors/reportsSelectors';

const FitmentTimeChart: React.FC = () => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const {
    series, categories, jobNumbers, subJobs,
  } = useAppSelector(fitmentTimeDataChartNewSelector);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      series={series}
      height={550}
      options={{
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories,
          labels: { trim: true },
        },
        yaxis: {
          title: { text: 'Hours' },
          labels: {
            formatter: (value) => value.toFixed(0),
          },
        },
        tooltip: {
          enabled: true,
          custom: ({ series, seriesIndex, dataPointIndex }) => {
            const value = series[seriesIndex][dataPointIndex];
            const jobNumber = jobNumbers[dataPointIndex];
            const spentList = subJobs[dataPointIndex];
            return '<div class="customBarTooltip">'
              + `<span class="customBarTooltip__entity">${jobNumber}</span>`
              + `${seriesIndex === 0 ? spentList.map(({ name, spent }) => `<span>${`${name}: ${spent} h`}</span>`).join('') : ''}`
              + `<span>${seriesIndex === 0 ? 'Total spent' : 'Total estimated'}: ${value} h</span>`
              + '</div>';
          },
        },
      }}
    />
  );
};

export default FitmentTimeChart;
