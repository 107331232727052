import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import EnquiryDetailsContainer from './EnquiryDetailsContainer';
import Spinner from '../../core/components/spinner/Spinner';
import ErrorPage from '../../common/components/error-pages/ErrorPage';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { enquiryDetailsSelector } from '../../store/selectors/enquiriesSelector';
import { allowedBaForUserSelector } from '../../store/selectors/coreSelectors';
import { getEnquiryDetails } from '../../store/thunks/enquiry/detailsThunks';

const EnquiryViewPageWrapper: React.FC = () => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const enquiryDetails = useAppSelector(enquiryDetailsSelector);
  const businessAreas = useAppSelector(allowedBaForUserSelector);

  useEffect(() => {
    id && dispatch(getEnquiryDetails({ id: +id }));
    // eslint-disable-next-line
  }, [id, params]);

  if (!enquiryDetails) {
    return <Spinner />;
  }
  if (!businessAreas.find((el) => el.value === enquiryDetails?.enquiry.businessArea)) {
    return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
  }

  return (
    <EnquiryDetailsContainer />
  );
};

export default EnquiryViewPageWrapper;
