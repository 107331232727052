import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faEllipsisVertical, faScrewdriverWrench, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import Tr from '../../../core/components/table-components/Tr';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../core/components/table-components/Tbody';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { skillsUiListSelector } from '../../../store/selectors/employeesSelector';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

type SkillsTableBodyProps = {
  checkedKeys: number[],
  checkRows: NumberFunctionType,
  disabled: boolean,
  openEditSkill: NumberFunctionType,
  openDeleteModal: (categoryId?: number, skillId?: number) => void,
}

const SkillsTableBody: React.FC<SkillsTableBodyProps> = ({
  checkedKeys, checkRows, disabled, openEditSkill, openDeleteModal,
}) => {
  const skillsData = useAppSelector(skillsUiListSelector);
  const allowedToManage = usePermission(PermissionEnum.EmployeeSkillsListPage);
  const nowrapValues = ['createdDate', 'modifiedDate'];

  const allowedActions = (id: number) => {
    return [
      {
        label: 'Edit skill item',
        icon: <FontAwesomeIcon icon={faEdit} />,
        key: 'editSkill',
        onClick: () => openEditSkill(id),
      },
      {
        label: 'Delete skill item',
        key: 'deleteSkill',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => {
          openDeleteModal(undefined, id);
        },
      },
    ];
  };
  const truncateKeys = ['item', 'description'];
  return (
    <Tbody>
      {skillsData.length > 0
        ? skillsData.map((d, i) => <Tr key={i}>
          {allowedToManage && (
            <TdNew>
              <Checkbox disabled={disabled} checked={checkedKeys.includes(d.id)} onChange={() => checkRows(d.id)} table />
            </TdNew>
          )}
          {Object.entries(d).map(([key, value]) => {
            if (key === 'id') return null;
            return <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
              {truncateKeys.includes(key)
                ? <TruncateTooltip value={value} table />
                : value}
            </TdNew>;
          })}
          {allowedToManage && (
            <TdNew action>
              <Dropdown
                menuItems={allowedActions(d.id)}
                icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
              />
            </TdNew>
          )}
        </Tr>)
        : <EmptyTableSection icon={faScrewdriverWrench} text="No skills" />}
    </Tbody>
  );
};

export default SkillsTableBody;
