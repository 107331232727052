import React from 'react';
import { Slider } from 'antd';
import { SliderMarks } from 'antd/es/slider';

type SliderWithMarksProps = {
  marks: SliderMarks,
  value: number,
  onChange: (value: number) => void,
  label?: string,
  disabled?: boolean,
}

const SliderWithMarks: React.FC<SliderWithMarksProps> = ({
  marks, value, onChange, label, disabled,
}) => {
  return (
    <div>
      {label && <span className="slider-label">{label}</span>}
      <Slider
        marks={marks}
        value={value}
        onChange={onChange}
        disabled={disabled}
      />
    </div>
  );
};

export default SliderWithMarks;
