import { useState } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  rectificationStatusChangeConfirmationStatusesSelector, rectificationStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { sortedRectificationCollectionsSelector } from '../../../store/selectors/rectificationsSelector';
import { putRectificationStatusThunk } from '../../../store/thunks/rectification/rectificationsDashThunks';
import { RectificationColumnType } from '../types/rectificationsTypes';

export const useRectificationStatusChange = (setCards: (value: RectificationColumnType | null) => void) => {
  const dispatch = useAppDispatch();
  const rectificationStatuses = useAppSelector(rectificationStatusesSelector);
  const { rejected, completed, cancelled } = useAppSelector(rectificationStatusChangeConfirmationStatusesSelector);

  const collections = useAppSelector(sortedRectificationCollectionsSelector);

  const [isCompletedConfirmation, setCompletedConfirmation] = useState(false);
  const [isCancellationConfirmation, setCancellationConfirmation] = useState(false);
  const [isRejectStatus, setIsRejectStatus] = useState(false);
  const [rectificationTransit, setRectificationTransit] = useState<{id: number, initStatus: number} | undefined>(undefined);
  const [columnsLoading, setColumnsLoading] = useState<number[]>([]);

  const statusChangeHandle = (
    id: number,
    statusTransition: number,
    sourceId: number,
    onClose?: VoidFunctionType,
    rejectionReason?: string,
  ) => {
    dispatch(putRectificationStatusThunk({
      id,
      statusTransition,
      sourceId,
      setColumns: () => setCards(collections),
      rejectionReason,
      onClose,
      setColumnsLoading,
    }));
  };

  const editStatus = (
    result: DropResult,
    setColumns: (value: RectificationColumnType | null) => void,
  ) => {
    if (!result.destination) return;
    const statusTransition = +result.destination.droppableId;
    const sourceId = +result.source.droppableId;

    if (statusTransition === rejected) {
      setIsRejectStatus(true);
    } else if (statusTransition === cancelled) {
      setCancellationConfirmation(true);
    } else if (statusTransition === completed) {
      setCompletedConfirmation(true);
    } else {
      result.destination && statusChangeHandle(
        +result.draggableId,
        statusTransition,
        sourceId,
        () => {
          setColumns(collections);
        },
      );
    }
  };

  const confirmRejectedStatusChange = (status: number, reason: string, onCancel: VoidFunctionType) => {
    const onClose = () => {
      onCancel();
      setRectificationTransit(undefined);
      setIsRejectStatus(false);
    };
    rectificationTransit && statusChangeHandle(
      rectificationTransit.id,
      status,
      rectificationTransit.initStatus,
      onClose,
      reason,
    );
  };
  const confirmCompletedStatusChange = () => {
    const onClose = () => {
      setRectificationTransit(undefined);
      setCompletedConfirmation(false);
    };
    rectificationTransit && completed && statusChangeHandle(
      rectificationTransit.id,
      completed,
      rectificationTransit.initStatus,
      onClose,
    );
  };
  const confirmCancelledStatusChange = () => {
    const onClose = () => {
      setRectificationTransit(undefined);
      setCancellationConfirmation(false);
    };
    rectificationTransit && cancelled && statusChangeHandle(
      rectificationTransit.id,
      cancelled,
      rectificationTransit.initStatus,
      onClose,
    );
  };
  return {
    isRejectStatus,
    setIsRejectStatus,
    isCompletedConfirmation,
    setCompletedConfirmation,
    isCancellationConfirmation,
    setCancellationConfirmation,
    setRectificationTransit,
    editStatus,
    confirmRejectedStatusChange,
    confirmCompletedStatusChange,
    confirmCancelledStatusChange,
    rejected,
    rectificationStatuses,
    columnsLoading,
  };
};
