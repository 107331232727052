import { useParams } from 'react-router-dom';
import { UseFormClearErrors, UseFormReset, UseFormSetValue } from 'react-hook-form';
import { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { routesPath } from '../../../core/enums/pathEnum';
import { VehicleViewModeParamsEnum } from '../../vehicle-view-page/enums/VehicleViewEnums';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { vehicleCheckDetailsSelector } from '../../../store/selectors/vehiclesSelectors';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { AddressFields, initAddressFields } from '../../../common/types/commonTypes';
import {
  setMarkersList,
  setVehicleCheckAttachments,
  setVehicleCheckDetails, setVehicleCheckSchemaUrl,
  setVehicleDetails,
} from '../../../store/slices/vehiclesSlice';
import { CustomAny, initDataListWithIds } from '../../../core/types/coreTypes';

export const useVehicleCheckFormHelper = (
  reset: UseFormReset<CustomAny>,
  clearErrors: UseFormClearErrors<CustomAny>,
  setValue: UseFormSetValue<CustomAny>,
  readMode?: boolean,
  formWithMarkers?: boolean,
) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const vehicleCheckDetails = useAppSelector(vehicleCheckDetailsSelector);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isDrawerReadMode, setDrawerReadMode] = useState(false);
  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [addressError, setAddressError] = useState<string>('');
  const [fileList, setFileList] = useState<RcFile[]>([]);

  const checkDate = readMode
    ? ` (${localDateFormatHandler('DD/MM/YYYY HH:mm', vehicleCheckDetails?.checkDate)})`
    : '';
  const backUrl = `${routesPath.VEHICLES}/${id}?mode=${VehicleViewModeParamsEnum.VEHICLE_INSPECTION}`;

  const locationHelper = () => {
    const location = { ...addressFields };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Object.keys(location).forEach((k) => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      if (location[k]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        location[k] = location[k].trim();
      }
    });

    const locationKeysToCheck = ['addressLine1', 'cityTown', 'postalCode'];
    const fieldsCheckUp: number[] = [];
    Object.entries(location).forEach(([key, v]) => {
      if (locationKeysToCheck.includes(key) && v?.trim()) {
        fieldsCheckUp.push(1);
      }
    });

    const areAllRequiredFields = fieldsCheckUp.length !== locationKeysToCheck.length;

    return { location, areAllRequiredFields };
  };

  const numericKeys = ['mileageMiles', 'numberOfVanKeys'];
  const yesNoKeys = [
    'isExteriorClean',
    'isInteriorClean',
    'hasTools',
    'hasSpareWheel',
    'isPdiCompleted',
    'isValeted',
  ];
  const yesNoSetValueHelper = (key: string, value: boolean | null) => {
    setValue(key, value ? 'Yes' : 'No');
  };

  const setAddressFilledValues = () => {
    if (readMode && vehicleCheckDetails) {
      setAddressFields({
        companyName: vehicleCheckDetails.companyName,
        addressLine1: vehicleCheckDetails.addressLine1,
        addressLine2: vehicleCheckDetails.addressLine2,
        cityTown: vehicleCheckDetails.cityTown,
        region: vehicleCheckDetails.region,
        postalCode: vehicleCheckDetails.postalCode,
        telephone: vehicleCheckDetails.telephone,
      });
    }
  };

  const setMarkersFilledList = () => {
    const markers = vehicleCheckDetails?.markers;
    if (markers && markers.length > 0) {
      const list = markers.map((el) => ({
        comment: el.comment || '',
        marker: null,
      }));
      dispatch(setMarkersList(list));
    }
  };

  const openMarkersDetails = () => {
    setDrawerVisible(true);
    readMode && setDrawerReadMode(true);
  };

  const closeDrawer = () => {
    setDrawerVisible(false);
    isDrawerReadMode && setDrawerReadMode(false);
  };

  const cleanUpMarkersState = () => {
    dispatch(setMarkersList([]));
    closeDrawer();
  };

  const cleanUpForm = (keepVehDetails?: boolean) => {
    formWithMarkers && cleanUpMarkersState();
    reset();
    clearErrors();
    setAddressError('');
    setAddressFields(initAddressFields);
    setFileList([]);
    !keepVehDetails && dispatch(setVehicleDetails(null));
    dispatch(setVehicleCheckDetails(null));
    dispatch(setVehicleCheckAttachments(initDataListWithIds));
    dispatch(setVehicleCheckSchemaUrl(''));
  };
  return {
    backUrl,
    checkDate,
    cleanUpForm,
    openMarkersDetails,
    closeDrawer,
    cleanUpMarkersState,
    isAddressModal,
    setAddressModal,
    addressFields,
    setAddressFields,
    addressError,
    setAddressError,
    fileList,
    setFileList,
    locationHelper,
    numericKeys,
    yesNoKeys,
    yesNoSetValueHelper,
    setAddressFilledValues,
    setMarkersFilledList,
    isDrawerVisible,
    isDrawerReadMode,
  };
};
