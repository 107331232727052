import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Spin } from 'antd';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Modal from '../../../core/components/modal/Modal';
import { AddressSuggestionDetailsDto, GenericVoidFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { getAddressSuggestions, getAddressSuggestionsById } from '../../../store/thunks/shared/sharedThunks';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import Tr from '../../../core/components/table-components/Tr';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import { addressSuggestionsSelector } from '../../../store/selectors/sharedSelectors';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Thead from '../../../core/components/table-components/Thead';
import Tbody from '../../../core/components/table-components/Tbody';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import './AddressSuggestionsModal.scss';
import Button from '../../../core/components/button/Button';
import Input from '../../../core/components/input/Input';
import { setAddressSuggestions } from '../../../store/slices/sharedSlice';
import TableLoader from '../../../core/components/table-components/TableLoader';

type AddressSuggestionsModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  handleSelectSuggested: GenericVoidFunctionType<AddressSuggestionDetailsDto>,
}

const columns = [
  { dataIndex: 'number', title: 'No' },
  { dataIndex: 'address', title: 'Address' },
];

const AddressSuggestionsModal: React.FC<AddressSuggestionsModalProps> = ({
  visible,
  onClose,
  handleSelectSuggested,
}) => {
  const dispatch = useAppDispatch();
  const suggestions = useAppSelector(addressSuggestionsSelector);

  const [loading, setLoading] = useState(false);
  const [selectedIdLoading, setSelectedIdLoading] = useState('');
  const [searchValue, setSearchValue] = useState('');

  const handleSearch = () => {
    dispatch(getAddressSuggestions({
      keyword: searchValue.trim(),
      setLoading,
    }));
  };

  const handleSelect = (id: string) => {
    dispatch(getAddressSuggestionsById({
      id,
      setSelectedIdLoading,
      handleSelectSuggested,
    }));
  };

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      width={750}
      afterClose={() => {
        setSearchValue('');
        setLoading(false);
        setSelectedIdLoading('');
        dispatch(setAddressSuggestions([]));
      }}
      destroyOnClose
    >
      <Button
        icon={<FontAwesomeIcon icon={faChevronLeft} />}
        className="addressSuggestions-back"
        onClick={onClose}
      />
      <div className="addressSuggestions-search">
        <Input
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          placeholder="Search by partial address or postal code"
          onPressEnter={() => searchValue.trim().length >= 2 && handleSearch()}
        />
        <Button
          label="Search"
          onClick={handleSearch}
          designType="dark"
          icon={<FontAwesomeIcon icon={faSearch} />}
          disabled={searchValue.trim().length < 2}
          reversed
        />
      </div>
      <TableNew small>
        <Thead>
          <Tr>
            {columns.map(({ dataIndex, title }) => <ThNew key={dataIndex} className={`addressSuggestionsTh--${dataIndex}`}>{title}</ThNew>)}
            <ThNew className="addressSuggestionsTh--actions"><span className="sr-only">Action</span></ThNew>
          </Tr>
        </Thead>
        {loading
          ? <TableLoader colspan={3} />
          : <Tbody>
            {suggestions.length
              ? suggestions.map((el, i) => (
                <Tr key={el.id}>
                  <TdNew>{i + 1}</TdNew>
                  <TdNew><TruncateTooltip value={el.address} table /></TdNew>
                  <TdNew className="addressSuggestions-select-col">
                    {selectedIdLoading === el.id
                      ? <Spin size="small" />
                      : <Button
                        label="Select"
                        onClick={() => handleSelect(el.id)}
                        type="text"
                        disabled={!!selectedIdLoading}
                      />}
                  </TdNew>
                </Tr>
              ))
              : <EmptyTableSection text="No records" colSpan={3} />}
          </Tbody>}

      </TableNew>
    </Modal>
  );
};

export default AddressSuggestionsModal;
