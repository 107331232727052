import React, { useState } from 'react';
import moment from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import Thead from '../../../core/components/table-components/Thead';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  stockPartAffectedCategoriesFiltersSelector,
  stockPartSubcategoriesColumnsSelector,
  stockPartSubcategoriesFiltersSelector,
} from '../../../store/selectors/stockSelectors';
import {
  DateRangeTableFilterType, PickerValues, SearchTableFilterType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { setPartSubcategoriesFilters } from '../../../store/slices/stockSlice';
import Tr from '../../../core/components/table-components/Tr';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';
import { getPartCategoryAffectedThunk } from '../../../store/thunks/stock/categories/partCategoriesThunks';

type PartSubcategoriesColumnsProps = {
  totalItems: number,
  openEditCategoryModal: VoidFunctionType,
  setIsDeleteModal: (params: { categoryId?: number, subcategoryId?: number }) => void,
  setIsAffectedModal: (params: { categoryId?: number, subcategoryId?: number }) => void

}
const PartSubcategoriesColumns: React.FC<PartSubcategoriesColumnsProps> = ({
  totalItems,
  openEditCategoryModal,
  setIsDeleteModal,
  setIsAffectedModal,
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(stockPartSubcategoriesFiltersSelector);
  const affectedFilters = useAppSelector(stockPartAffectedCategoriesFiltersSelector);
  const [name, ...restCols] = useAppSelector(stockPartSubcategoriesColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    keyword: filters.keyword,
  });
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    createdAt: [
      filters.createdAt?.min ? moment(filters.createdAt.min, DEFAULT_DATE_FORMAT) : null,
      filters.createdAt?.max ? moment(filters.createdAt.max, DEFAULT_DATE_FORMAT) : null,
    ],
    updatedAt: [
      filters.updatedAt?.min ? moment(filters.updatedAt.min, DEFAULT_DATE_FORMAT) : null,
      filters.updatedAt?.max ? moment(filters.updatedAt.max, DEFAULT_DATE_FORMAT) : null,
    ],
  });

  const handleDeleteCategoryClick = () => {
    const categoryId = filters.categoryIds?.[0];
    categoryId && dispatch(getPartCategoryAffectedThunk({
      categoryId,
      filters: affectedFilters,
      openDeleteModal: () => setIsDeleteModal({ categoryId }),
      openAffectedModal: () => setIsAffectedModal({ categoryId }),
    }));
  };

  const categoryActions = [
    {
      label: 'Edit category',
      key: 'edit-category',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: openEditCategoryModal,
    },
    {
      label: 'Delete category',
      key: 'delete-category',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: handleDeleteCategoryClick,
    },
  ];

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      dispatch(setPartSubcategoriesFilters({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      }));
    } else {
      dispatch(setPartSubcategoriesFilters({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      dispatch(setPartSubcategoriesFilters({
        ...filters,
        [field]: undefined,
      }));
    }
  };

  const searchPressHandle = (field: string) => {
    dispatch(setPartSubcategoriesFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values || undefined,
    };
    dispatch(setPartSubcategoriesFilters(newFilters));
  };

  return (
    <Thead>
      <Tr>
        <ThWithControls
          col={name}
          totalElements={totalItems}
          sortHandle={sortHandle}
          searchVl={searchVl}
          tableFilters={filters}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          className={`partSubcategoriesTh--${name.dataIndex}`}
        />
        <ThNew action>
          <span className="sr-only">Actions</span>
        </ThNew>
        {restCols.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            totalElements={totalItems}
            sortHandle={sortHandle}
            tableFilters={filters}
            pickerValue={pickerValue}
            setPickerValue={setPickerValue}
            pickerHandle={pickerHandle}
            className={`partSubcategoriesTh--${col.dataIndex}`}
          />
        ))}
        <ThNew action>
          <Dropdown
            menuItems={categoryActions}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default PartSubcategoriesColumns;
