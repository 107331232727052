import React, { useEffect, useRef } from 'react';
import moment from 'moment/moment';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import WorkCalendarLine from './components/calendar/WorkCalendarLine';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { employeesWorkCalendarFiltersSelector, employeeWorkCalendarUsersSelector } from '../../store/selectors/employeesSelector';
import {
  getEmployeesDepartmentsWithEmployeesCount, getEmployeesJobTitlesWithEmployeesCount,
  getEmployeeWorkCalendarThunk,
} from '../../store/thunks/employee/employeesWorkCalendarThunks';
import { DEFAULT_DATE_FORMAT } from '../../core/utils/regex';
import { setEmployeesWorkCalendarFilters } from '../../store/slices/employeesSlice';
import WorkCalendarHead from './components/calendar/WorkCalendarHead';
import { EmployeeSchedule } from './components/modals/EmployeesWorkingHoursModal';
import EmptyContentList from '../../core/components/empty-content-list/EmptyContentList';
import WorkCalendarLineSkeleton from './components/calendar/WorkCalendarLineSkeleton';
import WorkCalendarLinePagination from './components/calendar/WorkCalendarLinePagination';
import './EmployeesWorkCalendarPage.scss';
import { useLeaveRequestsComponents } from '../../common/hooks/useLeaveRequestsComponents';

type EmployeesWorkCalendarPageProps = {
  setEmployeeSchedule: (value: EmployeeSchedule | null) => void,
}
const EmployeesWorkCalendarPage: React.FC<EmployeesWorkCalendarPageProps> = ({ setEmployeeSchedule }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(employeesWorkCalendarFiltersSelector);
  const usersLines = useAppSelector(employeeWorkCalendarUsersSelector);
  const tableRef = useRef<HTMLDivElement>(null);

  const areFiltersApplied = (filters.keyword && filters.keyword.trim().length > 0)
    || (filters.departments && filters.departments.length > 0)
    || (filters.jobTitles && filters.jobTitles.length > 0);

  useLeaveRequestsComponents();

  useEffect(() => {
    if (!filters.from || !filters.to) {
      const currentDay = moment();
      const newFilters = {
        ...filters,
        from: currentDay.clone().startOf('isoWeek').format(DEFAULT_DATE_FORMAT),
        to: currentDay.clone().endOf('isoWeek').format(DEFAULT_DATE_FORMAT),
      };
      dispatch(setEmployeesWorkCalendarFilters(newFilters));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters.from && filters.to) {
      dispatch(getEmployeeWorkCalendarThunk({
        filters,
        scrollTop: () => tableRef.current?.scrollTo({ top: 0, behavior: 'smooth' }),
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    dispatch(getEmployeesDepartmentsWithEmployeesCount());
    dispatch(getEmployeesJobTitlesWithEmployeesCount());
    // eslint-disable-next-line
  }, []);

  return (
    <div className="employeeWorkCalendarWrap" ref={tableRef}>
      <div className="employeeWorkCalendar">
        <WorkCalendarHead />
        {areFiltersApplied && !usersLines.totalCount && (
          <>
            <div
              className={classNames('workCalendarLine__col col-fixed-left')}
            >
              <EmptyContentList icon={faUsers} text="No records" />
            </div>
            {Array(7).fill(null).map((_, i) => <div key={i} className={classNames('workCalendarLine__col')} />)}
          </>
        )}
        {!areFiltersApplied && !usersLines.totalCount && (
          <WorkCalendarLineSkeleton />
        )}
        {usersLines.items?.map((el) => <WorkCalendarLine
          key={el.employeeId}
          employeeId={el.employeeId}
          data={el}
          setEmployeeSchedule={setEmployeeSchedule}
        />)}
        {!!usersLines.totalCount && (
          <WorkCalendarLinePagination
            currentPage={filters.page}
            totalPages={Math.ceil(usersLines.totalCount / filters.pageSize)}
            onPageChange={(value) => dispatch(setEmployeesWorkCalendarFilters({ ...filters, page: filters.page + value }))}
          />
        )}
      </div>
    </div>
  );
};

export default EmployeesWorkCalendarPage;
