export enum CustomerContentEnum {
  CUSTOMER_DETAILS = 'details-and-activity',
  CUSTOMER_CONTACTS = 'contacts',
  CUSTOMER_ATTACHMENTS = 'attachments',
}

export const CustomerContentEnumList = [
  CustomerContentEnum.CUSTOMER_DETAILS,
  CustomerContentEnum.CUSTOMER_CONTACTS,
  CustomerContentEnum.CUSTOMER_ATTACHMENTS,
];
