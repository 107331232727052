import instance from './axiosConfig';
import {
  inAppNotificationsFiltersType,
  NotificationDtoBase,
  UserProfileDto,
  UserProfilePatchRequest,
} from '../account/types/AccountTypes';
import { AxiosDataListResponse, AxiosNullableResponse, AxiosSingleResponse } from '../core/types/coreTypes';
import { UserContextDto } from '../settings/users-dashboard/types/settingsTypes';

export const accountAPI = {
  login(email: string, password: string): AxiosSingleResponse<{ authToken: string }> {
    return instance.post('/account/auth/login', { email, password });
  },
  resetPassword(email: string): AxiosNullableResponse {
    return instance.post('/account/auth/password/reset', { email });
  },
  resetPasswordConfirm(token: string, newPassword:string): AxiosNullableResponse {
    return instance.put(`/account/auth/password/${token}`, { newPassword });
  },
  userInfo(): AxiosSingleResponse<{ userInfo: UserContextDto }> {
    return instance.get('/account/auth/userInfo');
  },
  changePassword(id:number, currentPassword:string, newPassword:string): AxiosNullableResponse {
    return instance.put(`/account/userProfiles/${id}/password`, { currentPassword, newPassword });
  },
  userProfile(id: number): AxiosSingleResponse<{ userProfile: UserProfileDto }> {
    return instance.get(`/account/userProfiles/${id}`);
  },
  patchUserProfile(id:number, data: Partial<UserProfilePatchRequest>): AxiosNullableResponse {
    return instance.patch(`/account/userProfiles/${id}`, { ...data });
  },
  getNewToken(): AxiosSingleResponse<{ authToken: string }> {
    return instance.get('/account/userProfiles/token');
  },
  validateResetToken(token: string): AxiosSingleResponse<{ email: string }> {
    return instance.post(`/account/auth/password/validate/${token}`);
  },
  fetchInAppNotifications(filters: inAppNotificationsFiltersType): AxiosDataListResponse<NotificationDtoBase[]> {
    return instance.get('/account/notifications', {
      params: {
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  fetchInAppNotificationsCount(status: number): AxiosSingleResponse<{ count: number }> {
    return instance.get('/account/notifications/count', {
      params: {
        'Filter.Status': status,
      },
    });
  },
  changeOneNotificationStatus(id: number, status: number): AxiosNullableResponse {
    return instance.put(`/account/notifications/${id}/status`, { status });
  },
  changeAllNotificationsStatuses(status: number): AxiosNullableResponse {
    return instance.put('/account/notifications/status', { status });
  },
  deleteOneNotification(id: number): AxiosNullableResponse {
    return instance.delete(`/account/notifications/${id}`);
  },
  deleteAllNotifications(): AxiosNullableResponse {
    return instance.delete('/account/notifications');
  },
};
