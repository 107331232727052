import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { RcFile } from 'antd/es/upload';
import CreateAttachmentModal from '../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import Restricted from '../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import Button from '../../../../../core/components/button/Button';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { scrollAttachTable } from '../../../../../common/utils/scrollAttachTable';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { vehicleAttachmentsFiltersSelector, vehicleAttachmentsSelector } from '../../../../../store/selectors/vehiclesSelectors';
import {
  createVehicleAttachmentThunk,
  deleteMultipleVehicleAttachmentThunk,
  getVehicleAttachmentsThunk,
} from '../../../../../store/thunks/vehicles/vehicleAttachmentsThunks';
import { setLoading } from '../../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../../core/utils/guidGenerator';
import { setVehicleAttachmentsFilters } from '../../../../../store/slices/vehiclesSlice';
import VehicleAttachmentsTable from './VehicleAttachmentsTable';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

const VehicleAttachments: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const attachFilters = useAppSelector(vehicleAttachmentsFiltersSelector);
  const { items, allItemIds, totalCount } = useAppSelector(vehicleAttachmentsSelector);

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');
  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    id && dispatch(getVehicleAttachmentsThunk({ vehicleId: +id, params: attachFilters }));
    // eslint-disable-next-line
  }, [id, attachFilters]);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createVehicleAttachmentThunk({
      vehicleId: +id,
      list,
      comment: comment.trim(),
      onCancel: cancelHandle,
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultipleVehicleAttachmentThunk({
      vehicleId: +id,
      ids: checkedKeys,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        uploadHandle={uploadHandle}
        fileList={fileList}
        setFileList={setFileList}
        comment={comment}
        setComment={setComment}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <div className="attachments">
        <div className="attachments__head actions-and-paging">
          <div className="attachmentsActions actions-and-paging__actions">
            <Restricted to={PermissionEnum.VehicleEditFields}>
              <>
                <Button
                  label="Upload file"
                  onClick={() => setCreateModal(true)}
                  icon={<FontAwesomeIcon icon={faCirclePlus} />}
                  designType="dark"
                  reversed
                />
                <Button
                  label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
                  onClick={() => setDeleteModal(true)}
                  disabled={!checkedKeys.length}
                />
              </>
            </Restricted>
          </div>
          {totalCount > 0 && <Pagination
            currentPage={attachFilters.page}
            pageSize={attachFilters.pageSize}
            onChange={(page, pageSize) => {
              scrollAttachTable(attachFilters.page, page);
              dispatch(setVehicleAttachmentsFilters({ ...attachFilters, page, pageSize }));
            }}
            totalItems={totalCount}
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
            className="actions-and-paging__paging"
          />}
        </div>
        <VehicleAttachmentsTable
          attachments={items}
          checkedKeys={checkedKeys}
          isAll={isAll}
          indeterminate={isIndeterminate}
          checkAllHandle={checkAllHandle}
          checkRow={checkRowHandle}
        />
      </div>
    </>
  );
};

export default VehicleAttachments;
