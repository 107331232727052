import React from 'react';
import {
  CustomAny, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import { TruncateTooltip } from '../../truncate-tooltip/TruncateTooltip';
import './VehicleAddEventModal.scss';
import { useVehicleDictionaries } from '../../../hooks/useVehicleDictionaries';
import VehicleInfoSource from '../../vehicle-modal/VehicleInfoSource';
import ChosenVehicleProfile from '../../vehicle-modal/ChosenVehicleProfile';

type VehicleAddEventModalProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  item: CustomAny,
  vehicleName?: string,
}

const VehicleAddEventModal: React.FC<VehicleAddEventModalProps> = ({
  isVisible, onClose, item, vehicleName = '',
}) => {
  const {
    vehicleInfoSources,
    vehicleProfilesGroups,
    vehicleProfilesTypes,
    vehicleCatalogSource,
  } = useVehicleDictionaries();
  const vehicleProfile = {
    id: 1,
    number: 1,
    code: item.code,
    manufacturer: item.manufacturer,
    group: vehicleProfilesGroups.find((el) => el.value === item.group)?.label || item.group,
    modelDescription: item.modelDescription,
    type: vehicleProfilesTypes.find((el) => el.value === item.type)?.label || item.type,
    maxPayload: item.maxPayload,
    grossVehicleWeightKg: item.grossVehicleWeightKg,
  };
  const manualFields = [
    { label: 'Manufacturer', value: vehicleProfile.manufacturer, gridSize: 2 },
    { label: 'Type', value: vehicleProfile.type, gridSize: 2 },
    { label: 'Group', value: vehicleProfile.group, gridSize: 2 },
    { label: 'Model description', value: vehicleProfile.modelDescription, gridSize: 6 },
  ];

  const commonFields = [
    { label: 'Number of sliding doors', value: item.slidingDoorsQuantity, gridSize: 2 },
    { label: 'Gross vehicle weight (GVW), kg', value: item.grossVehicleWeightKg, gridSize: 2 },
    { label: 'Maximum payload', value: item.maxPayload, gridSize: 2 },
    { label: 'Reg No', value: item.regNumber, gridSize: 3 },
    { label: 'Chassis No', value: item.chassisNumber, gridSize: 3 },
  ];
  return (
    <Modal
      visible={isVisible}
      title={vehicleName}
      onCancel={onClose}
      width={840}
      className="vehicleAddEventModal"
    >
      <VehicleInfoSource
        sources={vehicleInfoSources}
        infoSource={item.vehicleInfoSource}
        offsetBottom
        disabled
      />
      {item.vehicleInfoSource === vehicleCatalogSource?.value
        ? <ChosenVehicleProfile
          chosenVehicleProfile={vehicleProfile}
          readOnly
        />
        : <div className="vehicleAddEventModal__fields vehicleManualFields">
          {manualFields.map((field) => (
            <div className={`vehicleAddEventModal__field vehicleManualFields__field--${field.gridSize}`} key={field.label}>
              <span className="vehicleFields__label">{field.label}</span>
              <TruncateTooltip
                value={field.value}
                className="vehicleAddEventModal__value"
                overlayClassName="vehicleAddEventModal__tooltip"
              />
            </div>
          ))}
        </div>}
      <div className="vehicleAddEventModal__fields commonVehicleFields">
        {commonFields.map((field) => (
          <div className={`vehicleAddEventModal__field commonVehicleFields__field--${field.gridSize}`} key={field.label}>
            <span className="vehicleFields__label">{field.label}</span>
            <TruncateTooltip
              value={field.value}
              className="vehicleAddEventModal__value"
              overlayClassName="vehicleAddEventModal__tooltip"
            />
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default VehicleAddEventModal;
