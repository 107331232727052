import React, { useEffect, useState } from 'react';
import moment from 'moment/moment';
import {
  Controller, SubmitHandler, useForm, useFormState,
} from 'react-hook-form';
import classNames from 'classnames';
import { DateObject } from 'react-multi-date-picker';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { DEFAULT_DATE_FORMAT } from '../../../../core/utils/regex';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import './EmployeesWorkingHoursModal.scss';
import DecimalInputNumber from '../../../../core/components/input-number/DecimalInputNumber';
import Input from '../../../../core/components/input/Input';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { employeeAffectedDatesSelector } from '../../../../store/selectors/employeesSelector';
import {
  editEmployeeScheduleThunk,
  getAllEmployeesListThunk,
  getEmployeeAffectedDatesThunk,
} from '../../../../store/thunks/employee/employeesWorkCalendarThunks';
import { setEmployeeAffectedDates } from '../../../../store/slices/employeesSlice';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';
import Spinner from '../../../../core/components/spinner/Spinner';
import { toOptionalFixed } from '../../../../common/utils/formatUtils';
import Button from '../../../../core/components/button/Button';
import { useEmployeeWorkingHoursModal } from '../../hooks/useEmployeeWorkingHoursModal';
import WorkingHoursEmployeeModeContent from './WorkingHoursEmployeeModeContent';
import WorkingHoursAffectedPeriodModeContent from './WorkingHoursAffectedPeriodModeContent';
import { AffectedPeriodOptionsEnum, SelectEmployeesOptionsEnum } from '../../enums/workCalendarEnums';
import { setWorkingHoursDefaults, SetWorkingHoursFields } from '../../utils/SetWorkingHoursSchema';

export type EmployeeSchedule = {
  id: number,
  date: string,
  approvedLeaveRequestLeaveType: number | null,
  areWeekendsAndHolidaysNonWorking: boolean | null,
  scheduledWorkingHours: number | null,
  nonWorkingHours: number | null,
}

type EmployeesWorkingHoursModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  employeeSchedule: EmployeeSchedule | null,
}

const EmployeesWorkingHoursModal: React.FC<EmployeesWorkingHoursModalProps> = ({
  visible,
  onCancel,
  employeeSchedule,
}) => {
  const dispatch = useAppDispatch();
  const affectedDates = useAppSelector(employeeAffectedDatesSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const {
    control, handleSubmit, watch, setValue, clearErrors, reset,
  } = useForm<SetWorkingHoursFields>({
    defaultValues: setWorkingHoursDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const [totalHours, setTotalHours] = useState(0);
  const [employeesKeyword, setEmployeesKeyword] = useState('');

  const {
    selectEmployeeMode, setSelectEmployeeMode,
    selectEmployeesOptions, cleanUpEmployeeModeContent,
    affectedPeriodMode, setAffectedPeriodMode,
    affectedPeriodOptions, cleanUpAffectedModeContent,
    datesValues, setDatesValues,
    datesError, setDatesError,
  } = useEmployeeWorkingHoursModal();

  const selectedPeriodIsWeekendOrHoliday = affectedDates?.every((day) => day.isWeekendOrHoliday);
  const hasApprovedLeaveRequest = !!employeeSchedule?.approvedLeaveRequestLeaveType;

  const employeeIds = watch('employeeIds');
  const departmentsIds = watch('departments');
  const jobTitlesIds = watch('jobTitles');

  const areWeekendsAndHolidaysNonWorking = watch('areWeekendsAndHolidaysNonWorking');
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const scheduledWorkingHours = watch('scheduledWorkingHours');
  const nonWorkingHours = watch('nonWorkingHours');

  const disabledHoursInputs = selectedPeriodIsWeekendOrHoliday && areWeekendsAndHolidaysNonWorking;

  useEffect(() => {
    if (employeeSchedule) {
      setSelectEmployeeMode(SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE);
      setAffectedPeriodMode(AffectedPeriodOptionsEnum.SPECIFIC_DATES);
      setValue('employeeIds', [employeeSchedule.id]);
      const specificDate = new DateObject(employeeSchedule.date);
      setDatesValues([specificDate]);
      setValue('areWeekendsAndHolidaysNonWorking', employeeSchedule.areWeekendsAndHolidaysNonWorking === null
        ? true
        : employeeSchedule.areWeekendsAndHolidaysNonWorking);
      setValue('scheduledWorkingHours', employeeSchedule.scheduledWorkingHours
        ? employeeSchedule.scheduledWorkingHours.toString()
        : '0.00');
      setValue('nonWorkingHours', employeeSchedule.nonWorkingHours ? employeeSchedule.nonWorkingHours.toString() : '0.00');
    }
    // eslint-disable-next-line
  }, [employeeSchedule]);

  useEffect(() => {
    const diff = (+scheduledWorkingHours) - (+nonWorkingHours);
    if (diff < 0) {
      setTotalHours(0);
    } else {
      setTotalHours(diff);
    }
  }, [scheduledWorkingHours, nonWorkingHours]);

  const affectedEmployeesValues = employeeIds.length > 0 || departmentsIds.length > 0 || jobTitlesIds.length > 0;
  const affectedDatesValues = affectedPeriodMode === AffectedPeriodOptionsEnum.DATE_PERIOD
    ? (!!startDate && !!endDate)
    : (datesValues.length > 0);
  const conditionToGetAffectedData = visible
    && affectedEmployeesValues
    && affectedDatesValues
    && scheduledWorkingHours !== ''
    && nonWorkingHours !== ''
    && (+scheduledWorkingHours >= +nonWorkingHours);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (conditionToGetAffectedData) {
      const timeoutId = setTimeout(() => {
        const employees = {
          employeeIds: selectEmployeeMode === SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE ? employeeIds : undefined,
          departments: selectEmployeeMode === SelectEmployeesOptionsEnum.BY_DEPARTMENT ? departmentsIds : undefined,
          jobTitles: selectEmployeeMode === SelectEmployeesOptionsEnum.BY_JOB_TITLE ? jobTitlesIds : undefined,
        };
        const dates = affectedPeriodMode === AffectedPeriodOptionsEnum.DATE_PERIOD
          ? { startDate, endDate }
          : { dates: datesValues.map((d) => d.format(DEFAULT_DATE_FORMAT)) };
        const params = {
          ...employees,
          ...dates,
          scheduledWorkingHours: +scheduledWorkingHours || 0.00,
          nonWorkingHours: +nonWorkingHours || 0.00,
          areWeekendsAndHolidaysNonWorking: !!areWeekendsAndHolidaysNonWorking,
        };
        dispatch(getEmployeeAffectedDatesThunk({ params }));
      }, 300);

      return () => clearTimeout(timeoutId);
    } else {
      dispatch(setEmployeeAffectedDates(null));
    }
    // eslint-disable-next-line
  }, [
    employeeIds, departmentsIds, startDate,
    jobTitlesIds, endDate, datesValues, scheduledWorkingHours,
    nonWorkingHours, areWeekendsAndHolidaysNonWorking,
  ]);

  useEffect(() => {
    if (disabledHoursInputs) {
      setValue('scheduledWorkingHours', '0.00');
      setValue('nonWorkingHours', '0.00');
    }
    // eslint-disable-next-line
  }, [disabledHoursInputs]);

  const handleClose = () => {
    dispatch(setEmployeeAffectedDates(null));
    onCancel();
    reset();
    clearErrors();
    setAffectedPeriodMode(AffectedPeriodOptionsEnum.DATE_PERIOD);
    setSelectEmployeeMode(SelectEmployeesOptionsEnum.BY_DEPARTMENT);
    setDatesValues([]);
  };

  const onSubmit: SubmitHandler<SetWorkingHoursFields> = (values) => {
    const data = {
      employeeIds: selectEmployeeMode === SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE ? values.employeeIds : null,
      departments: selectEmployeeMode === SelectEmployeesOptionsEnum.BY_DEPARTMENT ? values.departments : null,
      jobTitles: selectEmployeeMode === SelectEmployeesOptionsEnum.BY_JOB_TITLE ? values.jobTitles : null,
      startDate: affectedPeriodMode === AffectedPeriodOptionsEnum.DATE_PERIOD ? startDate : null,
      endDate: affectedPeriodMode === AffectedPeriodOptionsEnum.DATE_PERIOD ? endDate : null,
      dates: affectedPeriodMode === AffectedPeriodOptionsEnum.SPECIFIC_DATES
        ? datesValues.map((d) => d.format(DEFAULT_DATE_FORMAT))
        : null,
      areWeekendsAndHolidaysNonWorking: values.areWeekendsAndHolidaysNonWorking,
      scheduledWorkingHours: +values.scheduledWorkingHours,
      nonWorkingHours: +values.nonWorkingHours,
    };
    if (affectedPeriodMode === AffectedPeriodOptionsEnum.SPECIFIC_DATES && datesValues.length === 0) {
      setDatesError(ErrorsEnum.REQUIRED);
    } else {
      dispatch(editEmployeeScheduleThunk({ data, closeModal: handleClose }));
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      if (employeesKeyword.trim()) {
        const timeOutId = setTimeout(() => {
          dispatch(getAllEmployeesListThunk({
            filters: {
              keyword: employeesKeyword,
              includeFullName: true,
              includeReferenceNumber: false,
              includeNationalInsuranceNumber: false,
              includeEmail: false,
            },
          }));
        }, 400);
        return () => clearTimeout(timeOutId);
      } else {
        dispatch(getAllEmployeesListThunk({ filters: undefined, setEmployeesSelectLoading: undefined }));
      }
    }
    // eslint-disable-next-line
  }, [visible, employeesKeyword]);

  return (
    <Modal
      visible={visible}
      onCancel={handleClose}
      title="Set working hours"
      width={700}
    >
      <form onSubmit={handleSubmit(onSubmit)} className="details-form employeesWorkingHours">
        <div className="selectEmployeeOptions">
          {selectEmployeesOptions.map((option, i) => <Button
            key={i}
            label={option.label}
            onClick={() => {
              !employeeSchedule && cleanUpEmployeeModeContent(option.value, setValue);
            }}
            designType="light-inverse"
            offsetRight={i !== 2}
            className={employeeSchedule && i !== 2 ? 'hiddenOptionButton' : ''}
            isActivated={selectEmployeeMode === option.value}
          />)}
        </div>
        <WorkingHoursEmployeeModeContent
          selectEmployeeMode={selectEmployeeMode}
          errors={errors}
          control={control}
          employeesKeyword={employeesKeyword}
          setEmployeesKeyword={setEmployeesKeyword}
          employeeSchedule={employeeSchedule}
        />
        <div className="selectEmployeeOptions">
          {affectedPeriodOptions.map((option, i) => <Button
            key={i}
            label={option.label}
            onClick={() => {
              !employeeSchedule && cleanUpAffectedModeContent(option.value, setValue);
            }}
            designType="light-inverse"
            offsetRight={i !== 2}
            isActivated={affectedPeriodMode === option.value}
            className={employeeSchedule && i === 0 ? 'hiddenOptionButton' : ''}
          />)}
        </div>
        <WorkingHoursAffectedPeriodModeContent
          employeeSchedule={employeeSchedule}
          control={control}
          affectedPeriodMode={affectedPeriodMode}
          endDate={endDate}
          startDate={startDate}
          datesValues={datesValues}
          setDatesValues={setDatesValues}
          datesError={datesError}
          setDatesError={setDatesError}
        />
        <Controller
          control={control}
          name="areWeekendsAndHolidaysNonWorking"
          render={({ field }) => (
            <Checkbox
              className="details-form__field--lg employeesWorkingHours__checkbox"
              label="Weekends and holidays are non-working for selected period"
              checked={field.value}
              onChange={field.onChange}
              disabled={hasApprovedLeaveRequest}
            />
          )}
        />
        <Controller
          control={control}
          name="scheduledWorkingHours"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DecimalInputNumber
              label="Scheduled working hours per day"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                if (+value >= +nonWorkingHours && errors.nonWorkingHours?.type === 'greaterThatWorkingHours') {
                  clearErrors('nonWorkingHours');
                }
                field.onChange(value);
              }}
              onBlur={() => {
                if (+scheduledWorkingHours < +nonWorkingHours) {
                  setValue('nonWorkingHours', scheduledWorkingHours);
                  clearErrors('nonWorkingHours');
                }
              }}
              error={errors.scheduledWorkingHours?.message}
              max={12}
              disabled={disabledHoursInputs || hasApprovedLeaveRequest}
            />
          )}
        />
        <Controller
          control={control}
          name="nonWorkingHours"
          rules={{
            ...RequiredFieldSchema,
            validate: {
              greaterThatWorkingHours: (v) => +v <= +scheduledWorkingHours || 'Value should be less or equal to working hours.',
            },
          }}
          render={({ field }) => (
            <DecimalInputNumber
              label="Non-working hours per day"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value);
              }}
              error={errors.nonWorkingHours?.message}
              max={12}
              disabled={disabledHoursInputs || hasApprovedLeaveRequest}
            />
          )}
        />
        <Input
          label="Total working hours per day"
          value={Number.isInteger(totalHours) ? totalHours.toString() : toOptionalFixed(totalHours, 2).toString()}
          disabled
          className="details-form__field--lg"
        />
        <div className="details-form__field--lg">
          <span>The following dates will be updated:</span>
          <ul className={classNames('employeesWorkingHours__list', {
            'employeesWorkingHours__list--loading': isGlobalLoading,
            'employeesWorkingHours__list--disabled': hasApprovedLeaveRequest,
          })}
          >
            {isGlobalLoading
              ? <>{!hasApprovedLeaveRequest && <li><Spinner global={false} /></li>}</>
              : affectedDates?.map((el) => {
                const date = moment(el.date).format('DD MMM YYYY - dddd');
                return <li key={date}>{`${date} (${el.totalWorkingHours}h)`}</li>;
              })}
          </ul>
        </div>
        {!hasApprovedLeaveRequest && (
          <ButtonActions
            cancelLabel="Cancel"
            createLabel="Save"
            createType="submit"
            cancelClick={handleClose}
            disabledCreate={isGlobalLoading}
            isLoading={isFetching}
            className="details-form__field--lg"
          />
        )}
      </form>
    </Modal>
  );
};

export default EmployeesWorkingHoursModal;
