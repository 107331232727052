import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { employeeDetailsHeadSelector } from '../../../../store/selectors/employeesSelector';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../core/components/tag/Tag';

export const EmployeeViewHead: React.FC = () => {
  const employeeHead = useAppSelector(employeeDetailsHeadSelector);

  return (
    <>
      {employeeHead.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Status'
          ? <Tag label={ent.value || '-'} />
          : <TruncateTooltip value={ent.value ? ent.value : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default EmployeeViewHead;
