import React from 'react';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../core/components/tag/Tag';
import { invoicingPurchaseOrderSelector } from '../../../store/selectors/invoicingSelectors';
import { useAppSelector } from '../../../store/hooks';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { purchaseOrderStatusSelector } from '../../../store/selectors/purchaseOrderSelectors';
import './InvoicingViewPageHead.scss';

const InvoicingViewPageHead: React.FC = () => {
  const data = useAppSelector(invoicingPurchaseOrderSelector);
  const statuses = useAppSelector(purchaseOrderStatusSelector);

  const statusLabelHandler = () => {
    return data?.purchaseOrder.status
      ? statuses.find((el) => el.value === data?.purchaseOrder.status)?.label || '-'
      : '-';
  };

  const dateLabelHandler = (date?: string) => {
    return date ? localDateFormatHandler('DD-MMM-YYYY', date) : '-';
  };
  const properties = [
    { label: 'Purchase order No:', value: data?.purchaseOrder.purchaseOrderNumber || '-' },
    { label: 'Supplier', value: data?.purchaseOrder.supplierName || '-' },
    { label: 'Status', value: statusLabelHandler() },
    { label: 'Created date', value: dateLabelHandler(data?.purchaseOrder.createdDate) },
    { label: 'Modified date', value: dateLabelHandler(data?.purchaseOrder.modifiedDate) },
  ];
  return (
    <>
      {properties.map((prop, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={prop.label} className="head__inner-info-item__label" />
        {prop.label === 'Status'
          ? <Tag label={prop.value?.toString() || '-'} />
          : <TruncateTooltip value={prop.value ? prop.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default InvoicingViewPageHead;
