export enum StockPartReqLineOrderingEnum {
  OrdinalNumber = 'OrdinalNumber',
  Category = 'Category',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  NetPrice = 'NetPrice',
  Status = 'Status',
  Quantity = 'Quantity',
  UnassignedNet = 'UnassignedNet',
}
