import {
  DataListType, initDataList,
  OrderingType, PagingWithOrdering, ResponseResult, ResponseSingleResult, StringRangeType,
} from '../../../core/types/coreTypes';

export type VehicleProfilesFilters = {
  keyword?: string,
  type?: number[],
  manufacturer?: string[],
  group?: number[],
  modelDescription?: string,
  createdDate?: StringRangeType,
  modifiedDate?: StringRangeType,
  ids?: number[],
  exceptIds?: number[],
  isSuitableForAll?: boolean,
  includeCode?: boolean,
  includeManufacturer?: boolean,
  includeModelDescription?: boolean,
} & PagingWithOrdering

export type SuitableVehicleProfilesFilters = {
  keyword?: string,
  order?: OrderingType,
}

export type VehicleProfileDto = {
  id: number,
  code: string | null,
  manufacturer: string | null,
  type: number,
  group: number,
  modelDescription: string | null,
  grossVehicleWeightKg: number | null,
  maxPayload: number | null,
  createdDate: string,
  modifiedDate: string,
}

export type VehicleProfilesDataList = DataListType<VehicleProfileDto[]> & { groups: number[] | null };
export type VehicleProfileSearchType = {
  id: number,
  number?: number,
  code: string | null,
  manufacturer: string | null,
  group: string | number,
  modelDescription: string | null,
  type: string | number,
  maxPayload: number | null,
  grossVehicleWeightKg: number | null,
  slidingDoorsQuantity?: number,
  chassisNumber?: string,
  regNumber?: string | null,
};

export type GetVehicleProfilesResponse = ResponseResult<VehicleProfileDto[], {
  groups: number[],
  totalCountWithoutKeywordSearch: number,
  allItemIds: number[],
}>

export type AvailableVehicleProfilesData = DataListType<VehicleProfileDto[], {
  groups: number[],
  totalCountWithoutKeywordSearch: number,
  allItemIds: number[],
}>

export const initAvailableVehicleProfilesData: AvailableVehicleProfilesData = {
  ...initDataList, allItemIds: [], groups: [], totalCountWithoutKeywordSearch: 0,
};

export interface PutVehicleProfileRestModel {
  code: string,
  modelDescription: string,
  type: number,
  grossVehicleWeightKg: number,
  maxPayload: number,
}

export interface CreateVehicleProfileRequest extends PutVehicleProfileRestModel {
  manufacturer: string,
  groupId: number,
}

export type VehicleProfileGroupsParams = {
  areVehiclesSuitableForAll?: boolean,
  page?: number,
  pageSize?: number,
  order?: OrderingType,
}

export type VehicleProfileGroupDto = {
  id: number,
  name: string | null,
  vehicleProfilesCount: number,
}

export type VehicleProfileDictionaryFilters = {
  keyword?: string,
  includeModelDescription?: boolean,
  includeRegNumber?: boolean,
  includeChassisNumber?: boolean,
  includeLocation?: boolean,
  includeLinkedEntityNumber?: boolean,
  manufacturers?: string[],
  manufacturerKeyword?: string,
  customerIds?: number[],
  statuses?: number[],
  vehicleFlows?: number[],
  assigneeIds?: number[],
  isActive?: boolean,
  onlyAvailableForRectificationCreation: boolean,
}

export type VehicleProfileDictionaryDto = {
  id: number,
  modelDescription: string,
}

export type GetVehicleProfilesDictionaryResponse = ResponseSingleResult<{ items: VehicleProfileDictionaryDto[] }>
