import React, { useState } from 'react';
import UserTimeline from './timeline/UserTimeline';
import UserEntityTimeline from './timeline/UserEntityTimeline';
import Selectable from '../../../../core/components/selectable/Selectable';
import { UserActivityEnum, userActivityTypeOptions } from '../../enums/UserTimeline';
import { setUserEntityTimelineFilters, setUserTimelineFilters } from '../../../../store/slices/settingsSlice';
import Switch from '../../../../core/components/switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { userEntityTimelineFiltersSelector, userTimelineFiltersSelector } from '../../../../store/selectors/settingsSelectors';

const UserActivity: React.FC = () => {
  const dispatch = useAppDispatch();
  const userTimelineFilters = useAppSelector(userTimelineFiltersSelector);
  const userEntityTimelineFilters = useAppSelector(userEntityTimelineFiltersSelector);

  const [type, setType] = useState(UserActivityEnum.USER);
  const isUserEntityView = type === UserActivityEnum.USER;

  const switchHandle = (value: boolean) => {
    const initValues = { mainOnly: !value, page: 1 };
    if (isUserEntityView) {
      dispatch(setUserEntityTimelineFilters({ ...userEntityTimelineFilters, ...initValues }));
    } else {
      dispatch(setUserTimelineFilters({ ...userTimelineFilters, ...initValues }));
    }
  };

  return (
    <div className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <Selectable
          options={userActivityTypeOptions}
          onChange={(value) => setType(+value)}
          value={type}
          className="userActivitySelect"
        />
        <Switch
          checked={isUserEntityView ? !userEntityTimelineFilters.mainOnly : !userTimelineFilters.mainOnly}
          onChange={switchHandle}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      {isUserEntityView
        ? <UserEntityTimeline />
        : <UserTimeline />}
    </div>
  );
};

export default UserActivity;
