import React from 'react';
import { EnquiryModesEnum } from '../../enums/dashboardViewsEnum';
import EnquirySearch from '../../../enquiry-search/EnquirySearch';
import EnquirySpecification from '../../../enquiry-specification/EnquirySpecification';
import EnquiryColumns from '../enquiries-columns/EnquiriesColumns';
import { EnquiriesNewResponseType } from '../../types/enquiryTypes';
import { useAppSelector } from '../../../../store/hooks';
import { enquiryStatusSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { enquiriesFiltersSelector } from '../../../../store/selectors/enquiriesSelector';

type EnquiriesContentProps = {
  pageParam: string | null,
  cards: EnquiriesNewResponseType | null,
  setCards: (value: EnquiriesNewResponseType | null) => void,
}

const EnquiriesContent: React.FC<EnquiriesContentProps> = ({ pageParam, cards, setCards }) => {
  const enquiryStatus = useAppSelector(enquiryStatusSelector);
  const dashFilters = useAppSelector(enquiriesFiltersSelector);
  const contentRenderFn = () => {
    switch (pageParam) {
      case EnquiryModesEnum.DASHBOARD:
        return <EnquiryColumns
          cards={cards}
          setCards={setCards}
          enquiryStatus={enquiryStatus}
          dashFilters={dashFilters}
        />;
      case EnquiryModesEnum.SEARCH:
        return <EnquirySearch />;
      case EnquiryModesEnum.SPECIFICATION:
        return <EnquirySpecification />;
      default: return null;
    }
  };

  return (
    <>{contentRenderFn()}</>
  );
};

export default EnquiriesContent;
