import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum DashboardViewEnum {
  DASHBOARD = 'Dashboard',
  JOB_LIST = 'Job list',
  CALENDAR = 'Job calendar',
  CREATE = 'Create new job',
}

export enum JobActivityEnum {
  ACTIVE = 'active',
  ARCHIVE = 'archive',
}

export enum JobModesEnum {
  DASHBOARD = 'dashboard',
  JOB_LIST = 'job-list',
  CALENDAR = 'job-calendar',
  CREATE = 'create-new-job',
}

export const JobsModes = [
  JobModesEnum.DASHBOARD, JobModesEnum.JOB_LIST, JobModesEnum.CALENDAR, JobModesEnum.CREATE,
];

export const JobsPageParamsAndPermissions = [
  { page: JobModesEnum.DASHBOARD, permission: PermissionEnum.JobDashboard },
  { page: JobModesEnum.JOB_LIST, permission: PermissionEnum.JobListView },
  { page: JobModesEnum.CALENDAR, permission: PermissionEnum.JobCalendar },
  { page: JobModesEnum.CREATE, permission: PermissionEnum.JobCreateNewJob },
];

export enum JobStatusesUiNames {
  New = 'New',
  Pending = 'Pending',
  InProduction = 'In production',
  Postponed = 'Postponed',
  ReadyForQc = 'Ready for QC',
  JobQc = 'Job QC',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
}

export enum JobListAllStatusEnum { ALL = 100 }

export const workQueueNames = ['All', JobStatusesUiNames.Pending, JobStatusesUiNames.InProduction, JobStatusesUiNames.Postponed];
export const completedNames = [
  JobStatusesUiNames.ReadyForQc,
  JobStatusesUiNames.JobQc,
  JobStatusesUiNames.Failed,
  JobStatusesUiNames.Cancelled,
  JobStatusesUiNames.Completed,
];

export enum JobsSearchParamsNames {
  PAGE = 'page',
  TAB_TYPE = 'tab_type',
  JOB_STATUS = 'job_status',
}

export enum QueueCompletedModesEnum {
  WORK_QUEUE = 'work-queue',
  COMPLETED = 'completed',
}

export const JobListTabParams = [QueueCompletedModesEnum.WORK_QUEUE, QueueCompletedModesEnum.COMPLETED];
