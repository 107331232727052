import React from 'react';
import Modal from '../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Pagination from '../../../core/components/pagination/Pagination';
import './AffectedEntitiesModal.scss';

type AffectedEntitiesModalProps = {
  visible: boolean,
  handleClose: VoidFunctionType,
  text: React.ReactNode,
  handlePagination: (page: number, pageSize: number) => void,
  page: number,
  pageSize: number,
  totalCount: number,
  children: React.ReactNode,
  title?: string,
}

const AffectedEntitiesModal: React.FC<AffectedEntitiesModalProps> = ({
  visible,
  handleClose,
  title,
  text,
  handlePagination,
  page,
  pageSize,
  totalCount,
  children,
}) => {
  return (
    <Modal
      width={900}
      visible={visible}
      onCancel={handleClose}
      title={title || 'Affected entities'}
      className="affectedEntitiesModal"
      destroyOnClose
    >
      <span className="affectedEntitiesModal__text">{text}</span>
      <Pagination
        onChange={handlePagination}
        currentPage={page}
        pageSize={pageSize}
        totalItems={totalCount}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        showSizeChanger={false}
        className="affectedEntitiesModal__pagination"
      />
      {children}
    </Modal>
  );
};

export default AffectedEntitiesModal;
