import { OrderingType, PagingType } from '../../../core/types/coreTypes';

export type SkillDto = {
  id: number,
  categoryId: number | null,
  name: string | null,
  description: string | null,
  createdDate: string | null,
  modifiedDate: string | null,
}

export interface SkillCategoryLookupDto {
  id: number,
  name: string | null,
  skillsCount?: number,
}

export type SkillsFilterType = {
  categoryId?: number,
  order?: OrderingType,
}

export const initSkillsFilters = {
  categoryId: undefined,
  order: undefined,
};

export type AffectedEmployeesFiltersType = {
  order?: OrderingType,
} & PagingType

export type AffectedEmployeeDto = {
  no: number,
  firstName: string | null,
  lastName: string | null,
  email: string | null,
}

export const affectedEmployeeColumns = [
  {
    title: 'No',
    dataIndex: 'no',
  },
  {
    title: 'First name',
    dataIndex: 'firstName',
  },
  {
    title: 'Last name',
    dataIndex: 'lastName',
  },
  {
    title: 'Email',
    dataIndex: 'email',
  },
];

export type EmployeeSkillDto = {
  level: number,
  id: number,
  categoryId: number | null,
  name: string | null,
}
export type EmployeeSkillsListItemDto = {
  categoryName: string | null,
  skills: Array<EmployeeSkillDto> | null,
}
