import React, { useState } from 'react';
import { StringFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { useAppSelector } from '../../../../store/hooks';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';
import Input from '../../../../core/components/input/Input';

type EditNoteEventModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  editHandle: VoidFunctionType,
  value: string,
  setValue: StringFunctionType,
}

const EditNoteEventModal: React.FC<EditNoteEventModalProps> = ({
  isVisible,
  onCancel,
  editHandle,
  value,
  setValue,
}) => {
  const [error, setError] = useState('');
  const isFetching = useAppSelector(isFetchingSelector);

  const closeModal = () => {
    onCancel();
    setError('');
  };

  // TODO: remove commented code after task implementation. it's left just as an example
  return (
    <Modal visible={isVisible} onCancel={closeModal} title="Edit note">
      {/* <MentionsInputComponent */}
      <Input
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          if (e.target.value.trim().length > 200) {
            setError('Entered value should have max 200 characters.');
          } else {
            setError('');
          }
        }}
        error={error}
        // singleLine
      />
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={closeModal}
        createLabel="Save"
        createClick={() => editHandle()}
        disabledCreate={!value.trim() || !!error}
        isLoading={isFetching}
        offsetTop
      />
    </Modal>);
};

export default EditNoteEventModal;
