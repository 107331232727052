import React, { useEffect } from 'react';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import PartForm from './components/PartForm';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { getPartTypes } from '../../store/thunks/stock/stockThunks';

const CreatePartContainer:React.FC = () => {
  const { backHandle } = useLocationHistoryState();

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    dispatch(getPartTypes());
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new part</div>
    </DetailsHead>}
    >
      <PartForm />
    </Layout>
  );
};

export default CreatePartContainer;
