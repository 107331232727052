import {
  initSmallPaging, OrderingType, PagingType, NumericRangeType, PagingWithOrdering,
} from '../../../../core/types/coreTypes';
import {
  ActivityEventBasicFieldsType,
  EntityType,
  SuitableForVehicleChangeEventData,
} from '../../../../common/types/commonTypes';

export type PartsKitComponentsFiltersType = {
  order?: OrderingType,
  categoryIds?: number[],
  partNumber?: string,
  name?: string,
  quantity?: NumericRangeType,
  unitPrice?: NumericRangeType,
  netPrice?: NumericRangeType,
  taxes?: number[],
  weight?: NumericRangeType,
  comment?: string,
}

export type CustomPartDataType = {
  weight?: number | null,
  productName: string,
  quantity: number,
  unitOfMeasure: number,
  unitPrice: number,
  tax: number,
  categoryId: number,
}

export const PartsKitComponentsPartsFiltersInit = {
  ...initSmallPaging,
  isCorePart: [true],
};

export type PartsKitComponentsSummaryType = {
  conversionWeight: number | null,
  minNetPrice: string | null,
  maxNetPrice: string | null,
  minVat: string | null,
  maxVat: string | null,
  minGross: string | null,
  maxGross: string | null,
}

export type PartsKitEventDtoBase = {
  eventType: number,
  name?: string,
  id?: number,
  groupId?: number, // components
} & ActivityEventBasicFieldsType & SuitableForVehicleChangeEventData;

export type PartsKitComponentsTimelineGroupFilters = PagingWithOrdering

export type PartsKitComponentGroupsItemDto = {
  id: number,
  isDeleted: boolean | null,
  partsKitComponentId: number | null,
  changeGroupId: number | null,
  partId: number | null,
  unitOfMeasure: number,
  categoryId: number | null,
  number: number | null,
  dateTime: string | null,
  categoryName: string | null,
  partNumber: string | null,
  partName: string | null,
  maxUnitPrice: number | null,
  maxNetPrice: number | null,
  minUnitPrice: number | null,
  minNetPrice: number | null,
  tax: number,
  weight: number | null,
  quantity: number | null,
}

export const InitialPartsKitComponentsTimelineGroupFilters = {
  page: 1,
  pageSize: 1000,
};

export type PartsKitAffectedItemsFilters = {
  businessAreas?: number[],
} & PagingType

export type PartsKitAffectedEntityDto = {
  entityId: number,
  entityType: EntityType,
  entityNumber: string,
  createdAt: string,
  entityStatus: string | null,
  businessAreas: number[],
  partLinesCount: number,
}
