import React from 'react';
import { OrderViewPageEnums } from '../../enums/orderViewPageEnums';
import OrderAttachments from './attachments/OrderAttachments';
import OrderDetailsContent from './details/OrderDetails';
import OrderLinks from './links/OrderLinks';
import OrderContacts from './contacts/OrderContacts';
import OrderPartRequests from './part-requests/OrderPartRequests';
import { useAppSelector } from '../../../../store/hooks';
import {
  orderForbiddenStatusesToEditDraftVehicleSelector,
  orderForbiddenStatusesToLinkVehicleSelector,
  orderStatusesValuesSelector,
} from '../../../../store/selectors/coreStatusesSelectors';
import { orderDetailsSelector } from '../../../../store/selectors/sharedSelectors';

type OrderContentProps = {
  mode: string | null,
}

const OrdersContent: React.FC<OrderContentProps> = ({ mode }) => {
  const orderDetails = useAppSelector(orderDetailsSelector);
  const { invoicing, completed, cancelled } = useAppSelector(orderStatusesValuesSelector);
  const forbiddenStatusesToLinkVehicle = useAppSelector(orderForbiddenStatusesToLinkVehicleSelector);
  const forbiddenStatusesToEditDraftVehicle = useAppSelector(orderForbiddenStatusesToEditDraftVehicleSelector);
  const isCurrentStatusArchived = [invoicing, completed, cancelled].includes(orderDetails?.order?.status);
  const isStatusAllowToEditVehRegNoAndChassisNo = [invoicing, completed].includes(orderDetails?.order?.status);

  const isCurrentStatusCancelled = orderDetails?.order?.status === cancelled;
  const isCurrentStatusForbiddenToEditVeh = forbiddenStatusesToEditDraftVehicle.includes(orderDetails?.order?.status);
  const isCurrentStatusForbiddenToLinkVeh = forbiddenStatusesToLinkVehicle.includes(orderDetails?.order?.status);
  const renderContent = () => {
    switch (mode) {
      case OrderViewPageEnums.ORDER_DETAILS:
        return <OrderDetailsContent
          orderDetails={orderDetails}
          isCurrentStatusArchived={isCurrentStatusArchived}
          isCurrentStatusCancelled={isCurrentStatusCancelled}
          isCurrentStatusForbiddenToLinkVeh={isCurrentStatusForbiddenToLinkVeh}
          isCurrentStatusForbiddenToEditVeh={isCurrentStatusForbiddenToEditVeh}
          isStatusAllowToEditVehRegNoAndChassisNo={isStatusAllowToEditVehRegNoAndChassisNo}
        />;
      case OrderViewPageEnums.ORDER_PART_REQUEST:
        return <OrderPartRequests />;
      case OrderViewPageEnums.ORDER_CONTACTS:
        return <OrderContacts isCurrentStatusArchived={isCurrentStatusArchived} />;
      case OrderViewPageEnums.ORDER_ATTACHMENTS:
        return <OrderAttachments isCurrentStatusArchived={isCurrentStatusArchived} />;
      default: return <OrderLinks />;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default OrdersContent;
