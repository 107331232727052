import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../core/components/button/Button';
import { BIG_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import Pagination from '../../../core/components/pagination/Pagination';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { stockPartSubcategoriesFiltersSelector } from '../../../store/selectors/stockSelectors';
import { setPartSubcategoriesFilters } from '../../../store/slices/stockSlice';

type PartCategoryActionsProps = {
  setIsCreateSubcategory: BooleanFunctionType,
  totalItems: number,
}
const PartCategoryActions: React.FC<PartCategoryActionsProps> = ({
  setIsCreateSubcategory,
  totalItems,
}) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(stockPartSubcategoriesFiltersSelector);

  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Button
          label="Add subcategory"
          onClick={() => setIsCreateSubcategory(true)}
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          designType="dark"
          reversed
        />
      </div>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page, pageSize) => {
          dispatch(setPartSubcategoriesFilters({ ...filters, page, pageSize }));
        }}
        totalItems={totalItems}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        narrow
        className="actions-and-paging__paging"
      />
    </div>
  );
};

export default PartCategoryActions;
