import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { rectificationsBySupplierFiltersSelector, rectificationsBySupplierRangesSelector } from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useRectificationsBySupplierReportRanges = () => {
  const filters = useAppSelector(rectificationsBySupplierFiltersSelector);
  const { usedInRectifications } = useAppSelector(rectificationsBySupplierRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    usedInRectifications: { min: usedInRectifications.min, max: usedInRectifications.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    usedInRectifications: filters.usedInRectifications
      ? [filters.usedInRectifications.min.toString(), filters.usedInRectifications.max.toString()]
      : [rangePatterns.usedInRectifications.min.toString(), rangePatterns.usedInRectifications.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, usedInRectifications: { min: usedInRectifications.min, max: usedInRectifications.max } }));
    setRange((prevState) => (
      { ...prevState, usedInRectifications: [usedInRectifications.min.toString(), usedInRectifications.max.toString()] }));
    // eslint-disable-next-line
  }, [usedInRectifications.min, usedInRectifications.max]);

  useEffect(() => {
    if (!filters?.usedInRectifications) {
      setRange((prevState) => (
        { ...prevState, usedInRectifications: [usedInRectifications.min.toString(), usedInRectifications.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.usedInRectifications]);

  return { range, setRange, rangePatterns };
};
