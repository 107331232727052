import React from 'react';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import Tr from '../../../core/components/table-components/Tr';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import {
  BooleanFunctionType, CoreAttachmentsFilters,
  DateRangeTableFilterType, NumberFunctionType,
  SearchTableFilterType,
} from '../../../core/types/coreTypes';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { useAppSelector } from '../../../store/hooks';
import { attachColumnsSelector } from '../../../store/selectors/coreOrderingSelectors';
import Thead from '../../../core/components/table-components/Thead';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import ThNew from '../../../core/components/table-components/table-new/ThNew';

type AttachmentColumnsType = {
  allowedManage: boolean,
  disabledActions?: boolean,
  isAll: boolean,
  checkAll: BooleanFunctionType,
  totalElements: number,
  sortHandle: NumberFunctionType,
  onChangeSearchHandle: (value: string, field: string) => void,
  searchPressHandle: (field: string) => void,
  searchVl?: SearchTableFilterType,
  tableFilters: CoreAttachmentsFilters,
  pickerValue?: DateRangeTableFilterType,
  setPickerValue?: (value: DateRangeTableFilterType) => void,
  pickerHandle?: (field: string, values?: {min: string | null, max: string | null}) => void,
  extraActions?: Array<ItemType>,
  indeterminate?: boolean,
  isManageAttachmentsVisibilityInJobs?: boolean,
}

const AttachmentColumns: React.FC<AttachmentColumnsType> = ({
  allowedManage,
  disabledActions,
  isAll,
  checkAll,
  totalElements,
  sortHandle,
  onChangeSearchHandle,
  searchPressHandle,
  searchVl,
  tableFilters,
  pickerValue,
  setPickerValue,
  pickerHandle,
  extraActions,
  indeterminate,
  isManageAttachmentsVisibilityInJobs,
}) => {
  const initColumns = useAppSelector(attachColumnsSelector);
  const columns = isManageAttachmentsVisibilityInJobs
    ? initColumns
    : initColumns.filter((col) => col.dataIndex !== 'showInLinkedJobs');
  const [nameCol, ...restCols] = columns;

  return (
    <Thead>
      <Tr>
        {allowedManage && (
          <ThNew checkbox>
            <Checkbox
              checked={isAll}
              indeterminate={indeterminate}
              onChange={checkAll}
              disabled={totalElements === 0 || disabledActions}
              table
            />
          </ThNew>
        )}
        <ThWithControls
          col={nameCol}
          totalElements={totalElements}
          className={`attachmentsTh--${nameCol.dataIndex}`}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          disabled={totalElements === 0}
          searchVl={searchVl}
          tableFilters={tableFilters}
        />
        {allowedManage && (
          <ThNew action>
            <span className="sr-only">Actions</span>
          </ThNew>
        )}
        {restCols.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            totalElements={totalElements}
            className={`attachmentsTh--${col.dataIndex}`}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            disabled={totalElements === 0}
            tableFilters={tableFilters}
            pickerValue={pickerValue}
            setPickerValue={setPickerValue}
            pickerHandle={pickerHandle}
          />
        ))}
        {extraActions && extraActions.length > 0 && (
          <ThNew className="attachmentsTh--extra-actions">
            <Dropdown
              menuItems={extraActions}
              icon={<FontAwesomeIcon icon={faGear} />}
            />
          </ThNew>
        )}
      </Tr>
    </Thead>
  );
};

export default AttachmentColumns;
