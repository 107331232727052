import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../core/enums/errorsEnum';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_UI_DATE_FORMAT,
  maxCommonDecimal,
} from '../../../../../core/utils/regex';
import { OrderDetails, OrderDto } from '../../../../dashboard/types/ordersTypes';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  allowedJobTypesForUserSelector,
  customerAccountTypesValuesSelector,
  jobTypesValuesSelector,
  priorityLevelsSelector,
} from '../../../../../store/selectors/coreSelectors';
import Textarea from '../../../../../core/components/textarea/Textarea';
import AddressField from '../../../../../common/components/address/AddressField';
import RequisiteSection from '../../../../../common/components/requisite/RequisiteSection';
import Input from '../../../../../core/components/input/Input';
import {
  orderDetailsJobLocationSelector,
  orderDetailsRequisitesSelector,
  orderSummarySelector, rejectionReasonOrderStatusesSelector, xeroFieldsOrderStatuses,
} from '../../../../../store/selectors/ordersSelector';
import RequisiteModal from '../../../../../common/components/requisite/RequisteModal';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { JobTypeUiNamesEnum, PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { patchOrderThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import { PatchOrderDto } from '../../../types/OrderViewPageTypes';
import { utcDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import { AddressFields, RequisiteDto } from '../../../../../common/types/commonTypes';
import AddressModal from '../../../../../common/components/address/AddressModal';
import {
  OrderCustomerRefNumberSchema,
  orderDetailsDefaults, OrderFields, OrderTextareaSchema, XeroInvoiceUrlSchema,
} from '../../../utils/OrderDetailsFormSchema';
import { YesNoOptions } from '../../../../../core/utils/testData';
import { orderStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import DatePickerComponent from '../../../../../core/components/date-picker/DatePicker';
import ReasonModal from '../../../../../common/components/reason-modal/ReasonModal';

type OrderDetailsSectionProps = {
  orderDetails: OrderDetails | null;
  isCurrentStatusArchived: boolean,
  isCurrentStatusCancelled: boolean,
}

const OrderDetailsSection: React.FC<OrderDetailsSectionProps> = ({ orderDetails, isCurrentStatusArchived, isCurrentStatusCancelled }) => {
  const allowedToEdit = usePermission(PermissionEnum.OrderEditFields);
  const dispatch = useAppDispatch();

  const [isLocationModal, setIsLocationModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [isSame, setIsSame] = useState<boolean>(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(allowedJobTypesForUserSelector);
  const { offSite } = useAppSelector(jobTypesValuesSelector);
  const { newStatus, booking, expensesReview } = useAppSelector(orderStatusesValuesSelector);
  const { reasonReview, rejectedByCustomer, cancelled } = useAppSelector(rejectionReasonOrderStatusesSelector);
  const { completed, acceptedByCustomer, invoicing } = useAppSelector(xeroFieldsOrderStatuses);
  const orderJobLocation = useAppSelector(orderDetailsJobLocationSelector);
  const orderSummary = useAppSelector(orderSummarySelector);
  const { nonCredit } = useAppSelector(customerAccountTypesValuesSelector);
  const orderStatus = orderDetails?.order?.status;
  const customerAccountType = orderDetails?.order?.customerAccountType;
  const {
    billing,
    shipping,
    isShippingSame,
    isShippingDetailsManualInput,
    isBillingDetailsManualInput,
    shippingCustomerId,
    billingCustomerId,
    alwaysUseCustomerRequisites,
    customerId,
  } = useAppSelector(orderDetailsRequisitesSelector);

  const isPaidFieldDisabled = customerAccountType === nonCredit
    ? ![newStatus, booking, expensesReview].includes(orderStatus)
    : [invoicing, cancelled, completed].includes(orderStatus);

  const xeroStatuses = [completed, invoicing, acceptedByCustomer];
  const rejectionReasonStatuses = [rejectedByCustomer, reasonReview, cancelled];
  const isXero = xeroStatuses.includes(orderStatus);
  const rejectionReasonRequired = orderStatus === rejectedByCustomer;
  const rejectionReasonMode = rejectionReasonStatuses.includes(orderStatus);

  const isDueDateDisabled = orderStatus !== newStatus;

  const {
    control, setValue, getValues, clearErrors, setError, watch,
  } = useForm<OrderFields>({
    defaultValues: orderDetailsDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const jobType = watch('jobType');

  const isOrderStatusNew = orderDetails?.order.status === newStatus;
  const optionalAddressFields = isOrderStatusNew
        || (isOrderStatusNew && (jobType !== offSite));

  const handleJobTypeError = (newJobTypeValue: number) => {
    setValue('jobType', orderDetails?.order.jobType);
    const newJobTypeName = jobTypes.find((type) => type.value === newJobTypeValue)?.label;
    return `Job type cannot be changed to ${newJobTypeName}.`;
  };

  const changeDetails = (data: PatchOrderDto, key: keyof PatchOrderDto, deepCompare?: boolean, isOptional?: boolean) => {
    if (orderDetails) {
      const { order } = orderDetails;
      const handler = () => {
        dispatch(patchOrderThunk({
          id: order.id,
          data,
          handleJobTypeError,
          partRequestId: orderDetails.order.partRequestId,
          openReasonModal: () => setReasonModal(true),
          // eslint-disable-next-line
            // @ts-ignore
          setInitValue: () => setValue(key, order[key as keyof OrderDto]),
        }));
      };
      compareValuesHandle<PatchOrderDto>(data, key, order, handler, deepCompare, isOptional);
    }
  };

  const fieldsToValidate = ['orderDate', 'rejectionReason', 'description', 'xeroInvoiceId', 'xeroInvoiceUrl', 'isPaid'];
  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, orderDetails?.order, setValue);
  };

  useEffect(() => {
    if (orderDetails) {
      const order = orderDetails?.order;
      setValue('orderDate', order.orderDate);
      setValue('jobType', order.jobType);
      setValue('dueDate', order.dueDate);
      setValue('isPaid', order?.isPaid ? 1 : 0);
      setValue('priorityLevel', order.priorityLevel);
      setValue('description', order.description);
      setValue('customerReferenceNumber', order.customerReferenceNumber);
      setValue('rejectionReason', order.rejectionReason);
      setIsSame(!!order.isShippingRequisiteSameAsBilling);
      setValue('xeroInvoiceId', order.xeroInvoiceId);
      setValue('xeroInvoiceUrl', order.xeroInvoiceUrl);
    }
    // eslint-disable-next-line
  }, [orderDetails]);

  useEffect(() => {
    orderJobLocation && setAddressFields(orderJobLocation);
  }, [orderJobLocation]);

  const closeRequisiteModal = () => {
    setIsVisible(false);
    setIsShippingMode(false);
  };

  const editHandle = (requisite: RequisiteDto, isManual: boolean, selectedEntityId?: number) => {
    const data = isShippingMode
      ? {
        shippingRequisite: isManual ? requisite : null,
        shippingCustomerId: selectedEntityId,
        isShippingDetailsManualInput: isManual,
        isShippingRequisiteSameAsBilling: false,
      }
      : {
        billingRequisite: isManual ? requisite : null,
        billingCustomerId: selectedEntityId,
        isBillingDetailsManualInput: isManual,
      };

    orderDetails?.order.id && dispatch(patchOrderThunk({
      id: orderDetails.order.id,
      data,
      onClose: closeRequisiteModal,
    }));
  };

  const changeJobLocationHandle = (data: PatchOrderDto, keys: Array<string>) => {
    if (orderDetails) {
      const location = data ? { ...data } : null;
      if (location) {
        Object.keys(location).forEach((k) => {
          if (k !== 'jobType') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            location[k as keyof PatchOrderDto] = location[k as keyof PatchOrderDto] ? location[k as keyof PatchOrderDto]?.trim() : '';
          }
        });
        const keysNotEqual = keys.some((key) => orderDetails.order[key as keyof OrderDto] !== location[key as keyof PatchOrderDto]);
        if (keysNotEqual) {
          dispatch(patchOrderThunk({
            id: orderDetails.order.id,
            data: location,
            onClose: () => setIsLocationModal(false),
          }));
        } else {
          setIsLocationModal(false);
        }
      }
    }
  };

  const disabledField = !allowedToEdit || isCurrentStatusArchived;
  const isDescriptionOrCustomerRefNoDisabled = !allowedToEdit || isCurrentStatusCancelled;
  return (
    <>
      <RequisiteModal
        isVisible={isVisible}
        onCancel={() => {
          closeRequisiteModal();
          isShippingSame && setIsSame(true);
        }}
        requisite={isShippingMode ? shipping : billing}
        submitHandle={editHandle}
        entityId={isVisible
          ? (isShippingMode ? shippingCustomerId : billingCustomerId) || customerId
          : undefined}
        entitySourceType="customer"
        isManual={isShippingMode ? isShippingDetailsManualInput : isBillingDetailsManualInput}
        isShipping={isShippingMode}
      />
      <AddressModal
        title="Job location"
        isVisible={isLocationModal}
        onCancel={() => {
          setIsLocationModal(false);
          setAddressFields(orderJobLocation);
          setValue('jobType', orderDetails?.order.jobType);
        }}
        isOptionalFields={optionalAddressFields}
        addressFields={addressFields}
        onSubmit={(values) => {
          changeJobLocationHandle({
            jobType: jobType === offSite ? offSite : orderDetails?.order.jobType,
            jobCompanyName: values.companyName,
            jobAddressLine1: values.addressLine1,
            jobAddressLine2: values.addressLine2,
            jobCityTown: values.cityTown,
            jobRegion: values.region,
            jobPostalCode: values.postalCode,
            jobTelephone: values.telephone,
          }, [
            'jobType', 'jobCompanyName', 'jobAddressLine1',
            'jobAddressLine2', 'jobCityTown', 'jobRegion', 'jobPostalCode', 'jobTelephone',
          ]);
        }}
      />
      <ReasonModal
        visible={reasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => {
          setReasonModal(false);
          setValue('orderDate', moment(orderDetails?.order.orderDate).format(DEFAULT_DATE_FORMAT));
        }}
        onSubmit={() => {
          const formatted = moment(getValues('orderDate')).format(DEFAULT_DATE_FORMAT);
          changeDetails({ orderDate: utcDateFormatHandler('', formatted) }, 'orderDate');
        }}
        type="update"
      />
      <section className="info-grid__column">
        <h2 className="info-grid__title">Details</h2>
        <form className="details-form">
          <DecimalInputNumber
            label="Cost"
            value={orderSummary?.gross || '0.00'}
            disabled
            max={maxCommonDecimal}
          />
          <Controller
            name="orderDate"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <DatePickerComponent
                value={field.value ? moment(field.value) : undefined}
                onChange={(v) => {
                  if (v) {
                    const formatted = moment(v).format(DEFAULT_DATE_FORMAT);
                    field.onChange(formatted);
                    clearOtherErrorsHandle('orderDate');
                    !errors.orderDate && changeDetails({ orderDate: utcDateFormatHandler('', formatted) }, 'orderDate');
                  }
                }}
                label="Order date"
                error={errors.orderDate?.message}
                format={DEFAULT_UI_DATE_FORMAT}
                disabled={disabledField}
                disabledDate={(currentDate) => {
                  const tooEarly = moment(currentDate).add(+1, 'days') <= moment(orderDetails?.order.createdDate);
                  const tooLate = currentDate > moment();
                  return tooEarly || tooLate;
                }}
              />
            )}
          />
          <Controller
            name="priorityLevel"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  clearOtherErrorsHandle('priorityLevel');
                  changeDetails({ priorityLevel: v as number }, 'priorityLevel');
                }}
                options={priorityLevels}
                error={errors.priorityLevel?.message}
                label="Priority level"
                disabled={disabledField}
              />
            )}
          />
          <Controller
            name="jobType"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  const isOffSite = jobTypes?.find((el) => el.value === v)?.label;
                  if (isOffSite === JobTypeUiNamesEnum.OffSite
                        && orderDetails?.order.status !== newStatus
                        && Object.values(orderJobLocation).some((el) => !el)
                  ) {
                    setIsLocationModal(true);
                    field.onChange(v);
                  } else {
                    field.onChange(v);
                    changeDetails({ jobType: v as number }, 'jobType');
                  }
                  clearOtherErrorsHandle('jobType');
                }}
                options={jobTypes}
                error={errors.jobType?.message}
                disabled={disabledField}
                label="Job type"
              />
            )}
          />
          <Controller
            name="isPaid"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  clearOtherErrorsHandle('isPaid');
                  changeDetails({ isPaid: v === 1 }, 'isPaid');
                }}
                options={YesNoOptions}
                error={errors.isPaid?.message}
                disabled={isPaidFieldDisabled}
                className="details-form__field--lg"
                label="Paid"
              />
            )}
          />
          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <DatePickerComponent
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  const formatted = value ? value.format(DEFAULT_DATE_FORMAT) : '';
                  field.onChange(formatted);
                  changeDetails({ dueDate: utcDateFormatHandler('', formatted) }, 'dueDate');
                }}
                disabledDate={(currentDate) => {
                  const entityDate = moment(orderDetails?.order?.orderDate);
                  const tooEarly = currentDate < entityDate;
                  const tooLate = currentDate > entityDate.add(24, 'months');
                  return tooEarly || tooLate;
                }}
                error={errors.dueDate?.message}
                label="Due date"
                format="DD/MM/YYYY"
                disabled={disabledField || isDueDateDisabled}
                className="details-form__field--lg"
              />
            )}
          />
          <AddressField
            addressFields={orderJobLocation}
            setAddressModal={() => setIsLocationModal(true)}
            disabled={disabledField}
            title="Job location"
          />
          <Controller
            name="description"
            control={control}
            rules={OrderTextareaSchema}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('description');
                }}
                onBlur={() => !errors.description && changeDetails(
                  { description: field.value?.trim() },
                  'description',
                  false,
                  true,
                )}
                label="Description"
                error={errors.description?.message}
                disabled={isDescriptionOrCustomerRefNoDisabled}
                className="details-form__field--lg"
              />
            )}
          />
          <RequisiteSection
            requisiteDetails={billing}
            setIsVisible={() => {
              setIsVisible(true);
              clearOtherErrorsHandle('billing');
            }}
            title="Billing details"
            disabled={alwaysUseCustomerRequisites || disabledField}
          />
          <RequisiteSection
            requisiteDetails={shipping}
            disabled={alwaysUseCustomerRequisites || disabledField}
            setIsVisible={() => {
              if (!isShippingSame) {
                setIsVisible(true);
                setIsShippingMode(true);
                clearOtherErrorsHandle('shipping');
              }
            }}
            title="Shipping details"
            isSame={isSame}
            isShipping
            onCheck={(val: boolean) => {
              if (val) {
                orderDetails?.order.id && dispatch(patchOrderThunk({
                  id: orderDetails.order.id,
                  data: { isShippingRequisiteSameAsBilling: true },
                }));
              } else {
                setIsShippingMode(true);
                setIsVisible(true);
              }
              setIsSame(val);
            }}
          />
          <Controller
            name="customerReferenceNumber"
            control={control}
            rules={OrderCustomerRefNumberSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  clearOtherErrorsHandle('customerReferenceNumber');
                }}
                onBlur={(e) => !errors.customerReferenceNumber && changeDetails(
                  { customerReferenceNumber: e.target.value.trim() },
                  'customerReferenceNumber',
                  false,
                  true,
                )}
                disabled={isDescriptionOrCustomerRefNoDisabled}
                error={errors.customerReferenceNumber?.message}
                label="Customer reference No"
              />
            )}
          />
          {rejectionReasonMode && <Controller
            name="rejectionReason"
            control={control}
            rules={{
              required: rejectionReasonRequired && ErrorsEnum.REQUIRED,
              ...OrderTextareaSchema,
            }}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                onChange={(e) => {
                  clearOtherErrorsHandle('rejectionReason');
                  field.onChange(e.target.value);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (!val.trim() && rejectionReasonRequired) {
                    setError('rejectionReason', { type: 'required', message: ErrorsEnum.REQUIRED });
                    setValue('rejectionReason', '');
                  }
                  if (!errors.rejectionReason) {
                    changeDetails({ rejectionReason: e.target.value.trim() }, 'rejectionReason');
                  }
                }}
                label="Rejection reason"
                error={errors.rejectionReason?.message}
                disabled={disabledField}
              />
            )}
          />}
          {isXero && <>
            <Controller
              control={control}
              name="xeroInvoiceId"
              rules={OrderCustomerRefNumberSchema}
              render={({ field }) => (
                <Input
                  value={field.value}
                  disabled={disabledField}
                  label="Xero invoice ID"
                  onChange={(v) => {
                    field.onChange(v);
                    clearOtherErrorsHandle('xeroInvoiceId');
                  }}
                  onBlur={(e) => !errors.xeroInvoiceId && changeDetails(
                    { xeroInvoiceId: e.target.value.trim() },
                    'xeroInvoiceId',
                  )}
                  error={errors.xeroInvoiceId?.message}
                />
              )}
            />
            <Controller
              control={control}
              name="xeroInvoiceUrl"
              rules={XeroInvoiceUrlSchema}
              render={({ field }) => (
                <Input
                  value={field.value}
                  disabled={disabledField}
                  label="Xero invoice URL"
                  onChange={(v) => {
                    field.onChange(v);
                    clearOtherErrorsHandle('xeroInvoiceUrl');
                  }}
                  onBlur={(e) => !errors.xeroInvoiceUrl && changeDetails(
                    { xeroInvoiceUrl: e.target.value.trim() },
                    'xeroInvoiceUrl',
                  )}
                  error={errors.xeroInvoiceUrl?.message}
                />
              )}
            />
          </>}
        </form>
      </section>
    </>
  );
};
export default OrderDetailsSection;
