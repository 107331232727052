import { Menu } from 'antd';
import classnames from 'classnames';
import React, {
  useRef, useCallback, useEffect, useMemo,
} from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from '../../enums/pathEnum';
import Button from '../button/Button';
import { sidebarData } from './data';
import './SideBar.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { collapsedSidebarSelector } from '../../../store/selectors/coreSelectors';
import { setCollapsedSidebar, setCurrentServerError } from '../../../store/slices/coreSlice';
import DoubleAngleIcon from '../../../assets/icons/angle-double-icon.svg';
import { ReactComponent as SettingsIcon } from '../../../assets/icons/settings-icon.svg';
import BIG_LOGO from '../../../assets/icons/vanliners-logo.svg';
import SMALL_LOGO from '../../../assets/icons/Logo-small.svg';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import { PermissionEnum } from '../../enums/dictionariesEnums';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import TermsOfUseLink from '../../../common/components/privacy-and-terms-buttons/TermsOfUseLink';
import PrivacyPolicyButton from '../../../common/components/privacy-and-terms-buttons/PrivacyPolicyButton';

const SideBar: React.FC = () => {
  const currentUser = useAppSelector(currentUserSelector);
  const settingsAllowed = currentUser?.userContextRole.permissions.includes(PermissionEnum.SettingsModule);
  const settingsPagesPermissions = [
    PermissionEnum.UserListPage,
    PermissionEnum.UserPermissionsPage,
    PermissionEnum.SettingsInfoMessagePage,
    PermissionEnum.UserCreatePage,
  ];
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const ref = useRef(null);
  const clickHandle = (path: string) => {
    navigate(path);
  };
  const collapsed = useAppSelector(collapsedSidebarSelector);
  const toggleCollapsed = () => {
    dispatch(setCollapsedSidebar(!collapsed));
  };
  const isAllowedFn = useCallback((permission: PermissionEnum) => {
    return !!currentUser?.userContextRole?.permissions.includes(permission);
  }, [currentUser]);

  const removeCurrentServerError = () => {
    dispatch(setCurrentServerError(undefined));
  };

  useEffect(() => {
    if (windowWidth < responsiveEndpointsEnum.LG) {
      dispatch(setCollapsedSidebar(true));
    }
    // eslint-disable-next-line
  }, [windowWidth]);

  useOnClickOutside(ref, () => {
    if (windowWidth < responsiveEndpointsEnum.LG) {
      dispatch(setCollapsedSidebar(true));
    }
  });

  // eslint-disable-next-line
  const allowedBarData = useMemo(() => sidebarData.filter((d) => isAllowedFn(d.restrictedTo)), [currentUser]);
  const isOneSettingsPagePermitted = useMemo(() => {
    const pages = currentUser?.userContextRole.permissions.filter((el) => settingsPagesPermissions.includes(el));
    return pages && pages.length > 0;
    // eslint-disable-next-line
  }, [currentUser?.userContextRole.permissions]);

  const sidebarLogo = !!windowWidth && windowWidth < responsiveEndpointsEnum.LG ? BIG_LOGO : collapsed ? SMALL_LOGO : BIG_LOGO;

  return (<div ref={ref}>
    <div className={classnames('sidebarWrapper', {
      'sidebarWrapperCollapsed': collapsed,
    })}
    >
      <Menu
        mode="inline"
        inlineCollapsed={collapsed}
        className="sidebar"
        theme="dark"
        onClick={(info) => {
          clickHandle(info.key);
        }}
      >
        <li className="sidebar__head">
          <Link to={`/${routesPath.HOME}`} onClick={removeCurrentServerError} className="sidebar__head-link">
            <img src={sidebarLogo} alt="" className="sidebar__head__logo" />
          </Link>
        </li>
        {allowedBarData.map((el) => <Menu.Item
          key={el.path}
          title={el.title}
          tabIndex={0}
          onClick={() => {
            windowWidth < responsiveEndpointsEnum.LG && toggleCollapsed();
            removeCurrentServerError();
          }}
          className={classnames({ 'ant-menu-item-selected': pathname.includes(el.path) })}
        >
          <div className="svgWrap">
            {el.icon}
          </div>
          {!collapsed && <span className="linkName">{el.name}</span>}
        </Menu.Item>)}
        {settingsAllowed && isOneSettingsPagePermitted && <Menu.Item
          key={`/${routesPath.SETTINGS}`}
          title="Settings"
          className={classnames('settingsLink', { 'ant-menu-item-selected': pathname.includes(`/${routesPath.SETTINGS}`) })}
          tabIndex={0}
          onClick={() => {
            windowWidth < responsiveEndpointsEnum.LG && toggleCollapsed();
            removeCurrentServerError();
          }}
        >
          <div className="svgWrap">
            <SettingsIcon />
          </div>
          {!collapsed && <span className="settingsTitle">Settings</span>}
        </Menu.Item>}
        <li className={classnames('sidebar__buttonWrap', {
          'collapsed': collapsed,
        })}
        >
          {!collapsed && <div>
            <TermsOfUseLink className="sidebar__terms-link" />
            <PrivacyPolicyButton />
          </div>}
          <Button
            icon={<img className="btn-img" src={DoubleAngleIcon} alt="" />}
            onClick={toggleCollapsed}
            className="sidebar__expand-btn"
          />
        </li>
      </Menu>
    </div>
  </div>);
};

export default SideBar;
