import React from 'react';
import classNames from 'classnames';
import { partsKitComponentsSummaryUISelector } from '../../../../../../store/selectors/partsKitSelectors';
import { useAppSelector } from '../../../../../../store/hooks';

const KitComponentsSummary: React.FC = () => {
  const summary = useAppSelector(partsKitComponentsSummaryUISelector);
  return (
    <section className="summary-row">
      <h2 className="summary-row__title">Summary</h2>
      <div className="summary-row__sections">
        {summary.map((el, i) => (
          <div key={i} className={classNames('summary-row__data', { 'summary-row__data--bold': el.label === 'Gross' })}>
            <span>{el.label}</span>
            <span>{el.value}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default KitComponentsSummary;
