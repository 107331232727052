import { useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { activePartsKitStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import {
  CustomLineReqType,
  DraftLinePartsFiltersType, DraftLinePartsKitFiltersType,
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters,
} from '../../../common/types/commonTypes';
import { initDataList, VoidFunctionType } from '../../../core/types/coreTypes';
import {
  isRectificationJobTypeSupplySelector,
  isRectificationVehManualSelector,
  rectificationDetailsVehicleSelector, rectificationPartRequestLinesSelector,
} from '../../../store/selectors/rectificationsSelector';
import {
  setRectificationPartReqDraftLinesFilters,
  setRectificationPartRequestDraftParts, setRectificationPartsKitDraftLinesFilters, setRectificationPartsKitRequestParts,
} from '../../../store/slices/rectificationsSlice';
import {
  addCustomLineToRectificationThunk,
  addPartsKitLineToRectificationThunk,
  getPartsKitsForRectificationThunk,
  getStockPartsForRectificationThunk,
} from '../../../store/thunks/rectification/rectificationViewPageThunks';
import { rectificationDetailsSelector } from '../../../store/selectors/sharedSelectors';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { getPartProductSourceDictionaryThunk } from '../../../store/thunks/shared/sharedThunks';

export const useAddRectificationPartReqLine = () => {
  const dispatch = useAppDispatch();
  const [editedLine, setEditedLine] = useState<number | undefined>(undefined);
  const [sourceName, setSourceName] = useState<string>('');
  const [sourceId, setSourceId] = useState<number | undefined>(undefined);
  const [prodSourceModal, setProdSourceModal] = useState(false);
  const [childLineMode, setChildLineMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isMarkAsFree, setIsMarkAsFree] = useState<boolean>(false);

  const [newLineReasonModal, setNewLineReasonModal] = useState(false);
  const [newLineReasonData, setNewLineReasonData] = useState<{
    partsKitId?: number,
    customLineValues?: CustomLineReqType,
    closeModal: VoidFunctionType,
  } | null>(null);

  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const partRequestId = rectificationDetails?.rectification?.partRequestId;
  const rectificationId = rectificationDetails?.rectification?.id;
  const { items: partRequestLines } = useAppSelector(rectificationPartRequestLinesSelector);

  const isJobTypeSupply = useAppSelector(isRectificationJobTypeSupplySelector);
  const isRectificationVehManual = useAppSelector(isRectificationVehManualSelector);
  const rectificationDetailsVehicle = useAppSelector(rectificationDetailsVehicleSelector);
  const activePartsKitStatus = useAppSelector(activePartsKitStatusSelector);
  const currentBA = useAppSelector(currentBusinessAreaSelector);

  const currentInitLine = useMemo(() => partRequestLines.find((el) => el.id === editedLine), [editedLine, partRequestLines]);

  const setInitFiltersAndTableData = () => {
    dispatch(setRectificationPartReqDraftLinesFilters(initDraftLinePartsFilters));
    dispatch(setRectificationPartRequestDraftParts(initDataList));
    dispatch(setRectificationPartsKitDraftLinesFilters(initDraftLinePartsKitFilters));
    dispatch(setRectificationPartsKitRequestParts(initDataList));
  };

  const setProdSourceModalHandle = (partId: number, name: string) => {
    setSourceName(name);
    setSourceId(partId);
    rectificationDetails?.rectification.rectificationDate && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: rectificationDetails.rectification.businessArea,
        customerId: rectificationDetails.rectification.customerId,
        priceDate: rectificationDetails.rectification.rectificationDate,
      },
      openModal: () => setProdSourceModal(true),
    }));
  };

  const fetchParts = (filters: DraftLinePartsFiltersType, isShow: boolean) => {
    let vehicleIds: number[] | undefined;
    if (!isRectificationVehManual && !isJobTypeSupply && rectificationDetailsVehicle.vehicleProfileId) {
      vehicleIds = [rectificationDetailsVehicle.vehicleProfileId];
    } else if (!isRectificationVehManual && isJobTypeSupply) {
      vehicleIds = (isShow && rectificationDetailsVehicle.vehicleProfileId) ? [rectificationDetailsVehicle.vehicleProfileId] : undefined;
    } else {
      vehicleIds = undefined;
    }
    const values = {
      keyword: filters.keyword,
      isCorePart: filters.isCorePart,
      page: filters.page,
      pageSize: filters.pageSize,
      order: filters.order,
      partCode: filters.partCode,
      manufacturerCode: filters.manufacturerCode,
      name: filters.name,
      description: filters.description,
      businessAreas: currentBA ? [currentBA] : undefined,
      vehicleIds,
    };
    dispatch(getStockPartsForRectificationThunk({
      filters: values,
    }));
  };

  const fetchPartsKitParts = (values: DraftLinePartsKitFiltersType, isShow: boolean) => {
    let vehicleIds: number[] | undefined;
    if (!isRectificationVehManual && !isJobTypeSupply && rectificationDetailsVehicle.vehicleProfileId) {
      vehicleIds = [rectificationDetailsVehicle.vehicleProfileId];
    } else if (!isRectificationVehManual && isJobTypeSupply) {
      vehicleIds = (isShow && rectificationDetailsVehicle.vehicleProfileId) ? [rectificationDetailsVehicle.vehicleProfileId] : undefined;
    } else {
      vehicleIds = undefined;
    }
    const newValues = {
      page: values.page,
      pageSize: values.pageSize,
      order: values.order,
      keyword: values.keyword,
      statuses: activePartsKitStatus ? [activePartsKitStatus] : undefined,
      description: values.description,
      partsKitCode: values.partsKitCode,
      name: values.name,
      businessAreas: currentBA ? [currentBA] : undefined,
      vehicleIds,
    };
    dispatch(getPartsKitsForRectificationThunk({
      filters: newValues,
    }));
  };

  const createPartsKitLineThunk = (partsKitId: number, closeModal: VoidFunctionType, reason: string) => {
    if (!newLineReasonModal) {
      setNewLineReasonData({ partsKitId, closeModal });
      setNewLineReasonModal(true);
    } else {
      partRequestId && rectificationId && dispatch(addPartsKitLineToRectificationThunk({
        partRequestId,
        data: {
          partsKitId,
          rectificationId: rectificationDetails?.rectification?.id,
          reason: reason.trim() || null,
        },
        closeModal: () => {
          closeModal();
          setNewLineReasonModal(false);
          setNewLineReasonData(null);
        },
      }));
    }
  };

  const createCustomHandle = (values: CustomLineReqType, closeModal: VoidFunctionType, reason: string) => {
    if (!newLineReasonModal) {
      setNewLineReasonData({ customLineValues: values, closeModal });
      setNewLineReasonModal(true);
    } else {
      rectificationId && partRequestId && dispatch(addCustomLineToRectificationThunk({
        partRequestId,
        data: { ...values, reason: reason.trim() || null },
        closeModal: () => {
          closeModal();
          setNewLineReasonModal(false);
          setNewLineReasonData(null);
        },
      }));
    }
  };

  const initCustomLine = currentInitLine
    ? {
      productName: currentInitLine.productName,
      quantity: currentInitLine.quantity,
      unitOfMeasure: currentInitLine.unitOfMeasure,
      unitPrice: currentInitLine.unitPriceString,
      tax: currentInitLine.tax,
      quoteCategoryId: currentInitLine.categoryId,
      weight: currentInitLine.weight,
    }
    : undefined;

  return {
    editedLine,
    setEditedLine,
    sourceName,
    setSourceName,
    sourceId,
    setSourceId,
    prodSourceModal,
    setProdSourceModal,
    setProdSourceModalHandle,
    initCustomLine,
    setInitFiltersAndTableData,
    isRectificationVehManual,
    isJobTypeSupply,
    fetchParts,
    fetchPartsKitParts,
    createPartsKitLineThunk,
    createCustomHandle,
    childLineMode,
    setChildLineMode,
    editMode,
    setEditMode,
    isMarkAsFree,
    setIsMarkAsFree,
    newLineReasonModal,
    setNewLineReasonModal,
    newLineReasonData,
  };
};
