import React, { useEffect, useState } from 'react';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Checkbox from '../../../../../../../core/components/checkbox/Checkbox';
import ThWithControls from '../../../../../../../core/components/table-components/ThWithControls';
import Tr from '../../../../../../../core/components/table-components/Tr';
import { SearchTableFilterType, VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  corePartsColumnsSelector,
  partsKitComponentsPartsFiltersSelector,
  partsKitComponentsPartsSelector,
} from '../../../../../../../store/selectors/partsKitSelectors';
import { setPartsKitComponentsPartsFilters } from '../../../../../../../store/slices/partsKitSlice';
import { getPartsKitComponentsParts } from '../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';

type CoreNonCoreColumnsProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  mode: AddComponentModeEnum,
}

const CoreNonCoreColumns: React.FC<CoreNonCoreColumnsProps> = ({
  isAll, isIndeterminate, checkAll, mode,
}) => {
  const dispatch = useAppDispatch();
  const parts = useAppSelector(partsKitComponentsPartsSelector);
  const filters = useAppSelector(partsKitComponentsPartsFiltersSelector);
  const addCoreComponentsColumns = useAppSelector(corePartsColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: '',
    manufacturerCode: '',
    name: '',
    description: '',
  });
  const sortHandle = (field: number) => {
    if (filters?.order?.field === field) {
      dispatch(setPartsKitComponentsPartsFilters({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      }));
      dispatch(getPartsKitComponentsParts({
        filters: {
          ...filters,
          order: { ...filters.order, isAsc: !filters.order.isAsc },
        },
      }));
    } else {
      dispatch(setPartsKitComponentsPartsFilters({
        ...filters,
        order: { field, isAsc: true },
      }));
      dispatch(getPartsKitComponentsParts({
        filters: {
          ...filters,
          order: { field, isAsc: true },
        },
      }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setPartsKitComponentsPartsFilters({
        ...filters,
        [field]: '',
        page: 1,
      }));
      dispatch(getPartsKitComponentsParts({
        filters: {
          ...filters,
          [field]: undefined,
          page: 1,
        },
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(getPartsKitComponentsParts({
      filters: {
        ...filters,
        [field]: searchVl[field]?.trim(),
        page: 1,
      },
    }));
  };

  useEffect(() => {
    setSearchVl({
      partCode: '',
      manufacturerCode: '',
      name: '',
      description: '',
    });
    // eslint-disable-next-line
  }, [mode]);
  return (
    <Thead>
      <Tr>
        <ThNew checkbox>
          <Checkbox
            checked={isAll}
            indeterminate={isIndeterminate}
            onChange={checkAll}
            disabled={parts.totalCount === 0}
            table
          />
        </ThNew>
        {addCoreComponentsColumns.map((col) => <ThWithControls
          key={col.dataIndex}
          col={col}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          totalElements={parts.totalCount}
          searchVl={searchVl}
          disabled={parts.totalCount === 0 || !filters.keyword}
          disabledFilter={!filters.keyword}
          className={`addComponentCoreNonCoreTh--${col.dataIndex}`}
          insideModal
        />)}
      </Tr>
    </Thead>
  );
};

export default CoreNonCoreColumns;
