import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { DictionaryStringValueItem, VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Select from '../../../core/components/select/Select';
import Input from '../../../core/components/input/Input';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxWeightWhole } from '../../../core/utils/regex';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import {
  CodeFieldsValidationSchema,
  CreateVehicleProfileDefaults,
  CreateVehicleProfileFields, ManufacturerFieldValidationSchema, ModelDescriptionFieldValidationSchema,
} from '../utils/vehicleProfilesDataUtil';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createVehicleProfileThunk, editVehicleProfileThunk } from '../../../store/thunks/vehicles/vehiclesProfilesThunk';
import {
  vehicleManufacturersDictionarySelector,
  vehicleProfilesGroupsUiSelector,
} from '../../../store/selectors/sharedSelectors';
import Autocomplete from '../../../core/components/autocomplete/Autocomplete';
import {
  isFetchingSelector,
  vehicleProfilesTypesUiSelector,
} from '../../../store/selectors/coreSelectors';
import { vehiclesProfilesSelector } from '../../../store/selectors/vehiclesSelectors';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import { CreateVehicleProfileRequest, VehicleProfileDto } from '../types/vehicleProfilesTypes';

type CreateVehicleProfileModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  title: string,
  editMode: boolean,
  vehicleIdToEdit?: number,
}

const CreateVehicleProfileModal: React.FC<CreateVehicleProfileModalProps> = ({
  visible, onCancel, title, editMode, vehicleIdToEdit,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const vehicleProfiles = useAppSelector(vehiclesProfilesSelector);
  const vehicleProfilesGroups = useAppSelector(vehicleProfilesGroupsUiSelector);
  const vehicleProfilesTypes = useAppSelector(vehicleProfilesTypesUiSelector);
  const manufacturersList = useAppSelector(vehicleManufacturersDictionarySelector);
  const [manufacturers, setManufacturers] = useState<DictionaryStringValueItem[]>([]);

  useEffect(() => {
    if (visible && manufacturersList) {
      setManufacturers(manufacturersList);
    }
  }, [visible, manufacturersList]);

  const {
    control, handleSubmit, reset, setError, setValue, clearErrors,
  } = useForm<CreateVehicleProfileFields>({
    defaultValues: CreateVehicleProfileDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const closeModalAfterThunk = () => {
    onCancel();
    reset();
    clearErrors();
  };

  const onSubmit = (values: CreateVehicleProfileFields) => {
    if (editMode) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { groupId, manufacturer, ...rest } = values;
      vehicleIdToEdit && dispatch(editVehicleProfileThunk({
        id: vehicleIdToEdit,
        data: {
          ...rest,
          type: rest.type as number,
          grossVehicleWeightKg: +rest.grossVehicleWeightKg,
          maxPayload: +rest.maxPayload,
          modelDescription: rest.modelDescription.trim(),
        },
        closeModal: closeModalAfterThunk,
        setError,
      }));
    } else {
      dispatch(createVehicleProfileThunk({
        data: {
          ...values,
          type: values.type as number,
          groupId: values.groupId as number,
          grossVehicleWeightKg: +values.grossVehicleWeightKg,
          maxPayload: +values.maxPayload,
          modelDescription: values.modelDescription.trim(),
        },
        closeModal: closeModalAfterThunk,
        setError,
      }));
    }
  };

  useEffect(() => {
    if (editMode) {
      const fountMatch = vehicleProfiles.items.find((el) => el.id === vehicleIdToEdit);
      if (fountMatch) {
        const unusedKeys = ['id', 'createdDate', 'modifiedDate'];
        Object.keys(fountMatch).forEach((key: string) => {
          if (!unusedKeys.includes(key)) {
            if (key === 'group') {
              setValue('groupId', fountMatch.group);
            } else {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setValue(key as keyof CreateVehicleProfileRequest, fountMatch[key as keyof VehicleProfileDto]);
            }
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [editMode, vehicleIdToEdit, vehicleProfiles.items]);

  const closeModal = () => {
    onCancel();
    reset();
    clearErrors();
  };
  return (
    <Modal
      visible={visible}
      onCancel={closeModal}
      destroyOnClose
      title={title}
      width={840}
    >
      <form className="createVehicleProfileForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="code"
          rules={CodeFieldsValidationSchema}
          render={({ field }) => (
            <Input
              label="Code"
              value={field.value.toUpperCase()}
              onChange={field.onChange}
              error={errors.code?.message}
              className="createVehicleProfileForm__full-row"
            />
          )}
        />
        <Controller
          name="manufacturer"
          control={control}
          rules={ManufacturerFieldValidationSchema}
          render={({ field }) => (
            <Autocomplete
              label="Manufacturer"
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                setError('manufacturer', { type: 'required', message: undefined });
              }}
              options={manufacturers}
              onSearch={(value) => {
                if (value.trim()) {
                  const list = manufacturersList.filter((el) => el.label.toLowerCase().includes(value.trim().toLowerCase()));
                  setManufacturers(list);
                } else setManufacturers(manufacturersList);
              }}
              onBlur={() => {
                if (!field.value.trim()) {
                  setError('manufacturer', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('manufacturer', '');
                }
              }}
              error={errors.manufacturer?.message}
              parentRender
              disabled={editMode}
              className="createVehicleProfileForm__full-row"
            />
          )}
        />
        <Controller
          control={control}
          name="modelDescription"
          rules={ModelDescriptionFieldValidationSchema}
          render={({ field }) => (
            <Input
              label="Model description"
              value={field.value}
              onChange={field.onChange}
              onBlur={() => {
                if (!field.value.trim()) {
                  setError('modelDescription', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('modelDescription', '');
                } else setValue('modelDescription', field.value.trim());
              }}
              error={errors.modelDescription?.message}
              className="createVehicleProfileForm__full-row"
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              label="Type"
              value={field.value}
              options={vehicleProfilesTypes}
              onChange={field.onChange}
              error={errors.type?.message}
              parentRender
            />
          )}
        />
        <Controller
          control={control}
          name="groupId"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              label="Group"
              value={field.value}
              options={vehicleProfilesGroups}
              onChange={field.onChange}
              error={errors.groupId?.message}
              parentRender
              disabled={editMode}
            />
          )}
        />
        <Controller
          control={control}
          name="grossVehicleWeightKg"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <RegularInputNumber
              label="Gross vehicle weight (GVW), kg"
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val) {
                  setValue('grossVehicleWeightKg', '0');
                }
              }}
              error={errors.grossVehicleWeightKg?.message}
              max={maxWeightWhole}
            />
          )}
        />
        <Controller
          control={control}
          name="maxPayload"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <RegularInputNumber
              label="Maximum payload"
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val) {
                  setValue('maxPayload', '0');
                }
              }}
              error={errors.maxPayload?.message}
              max={maxWeightWhole}
            />
          )}
        />
        <ButtonActions
          createLabel={editMode ? 'Save' : 'Create'}
          cancelLabel="Cancel"
          createType="submit"
          cancelClick={closeModal}
          className="createVehicleProfileForm__full-row"
          offsetTop
          isLoading={isFetching}
        />
      </form>
    </Modal>
  );
};

export default CreateVehicleProfileModal;
