import React from 'react';
import Layout from '../../core/components/layout/Layout';
import VehiclesFilters from './components/VehiclesFilters';
import './VehiclesContainer.scss';
import VehiclesContent from './components/VehiclesContent';

const VehiclesContainer = () => {
  return (
    <Layout>
      <VehiclesFilters />
      <VehiclesContent />
    </Layout>
  );
};

export default VehiclesContainer;
