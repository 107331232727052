import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import 'moment/locale/en-gb';
import locale from 'antd/es/locale/en_GB';
import './core/styles/global.scss';
import { routesPath } from './core/enums/pathEnum';
import { useAppDispatch, useAppSelector } from './store/hooks';
import { setIsAuth, setLocationHistory } from './store/slices/coreSlice';
import PermissionProvider from './permissions-handling/PermissionProvider';
import { currentUserSelector } from './store/selectors/accountSelectors';
import { userInfoThunk } from './store/thunks/account/accountThunks';
import Spinner from './core/components/spinner/Spinner';
import 'react-toastify/dist/ReactToastify.css';
import AppWrapper from './AppWrapper';
import { useScreenHeight } from './core/hooks/useScreenHeight';
import ErrorPage from './common/components/error-pages/ErrorPage';
import { authTokenSelector, currentServerErrorSelector } from './store/selectors/coreSelectors';

function App() {
  const dispatch = useAppDispatch();
  const { pathname, search } = useLocation();
  const navigate = useNavigate();
  const currentServerError = useAppSelector(currentServerErrorSelector);
  const token = useAppSelector(authTokenSelector);

  const currentUser = useAppSelector(currentUserSelector);
  useScreenHeight();

  const checkUrl = () => {
    return !pathname.includes(`/${routesPath.ACCOUNT_FINALISATION}`)
        && !pathname.includes(`/${routesPath.FORGOT_PASSWORD}`)
        && !pathname.includes(`/${routesPath.RESET_PASSWORD}`);
  };

  useEffect(() => {
    if (token) {
      dispatch(setIsAuth(true));
      dispatch(userInfoThunk());
    } else {
      if (pathname === '/login') {
        dispatch(setLocationHistory({ pathname: `/${routesPath.HOME}`, search: '' }));
      } else {
        dispatch(setLocationHistory({ pathname, search }));
      }
      if (checkUrl()) {
        const prevPage = `${pathname}${search}`;
        navigate(`/${routesPath.LOGIN}`, { state: prevPage });
      }
    }
    // eslint-disable-next-line
  }, [token]);
  if (!currentUser && pathname !== '/login' && checkUrl()) {
    return <Spinner />;
  }

  return (
    <ConfigProvider locale={locale}>
      <PermissionProvider permissions={currentUser?.userContextRole.permissions}>
        <div className="App">
          {currentServerError && currentServerError.httpCode !== 401
            ? <ErrorPage errorStatusCode={currentServerError} />
            : <AppWrapper />}
        </div>
      </PermissionProvider>
    </ConfigProvider>
  );
}

export default App;
