import { useMemo, useState } from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import {
  blockedOrderSubmitAndUsualLinesActionsStatusesSelector,
  orderPartReqLinesColumnsSelector,
  orderPartReqUiLinesSelector, orderReadModeStatusesSelector,
  partRequestLinesSelector,
} from '../../../store/selectors/ordersSelector';
import { orderDetailsSelector } from '../../../store/selectors/sharedSelectors';
import { mockedOrderPartRequestCustomSource, mockedOrderPartRequestSources } from '../utils/data';
import { allocatedPartReqLinesStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { useSelectAll } from '../../../core/hooks/useSelectAll';
import { usePartRequestLinesReason } from '../../../common/hooks/usePartRequestLinesReason';

export const useOrderPartRequestsLines = () => {
  const { items: partReqOriginLines, allItemIds } = useAppSelector(partRequestLinesSelector);
  const linesArr = useAppSelector(orderPartReqUiLinesSelector);
  const columns = useAppSelector(orderPartReqLinesColumnsSelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const blockedSubmitAndUsualLinesActionsStatuses = useAppSelector(blockedOrderSubmitAndUsualLinesActionsStatusesSelector);
  const blockedManagingOfCustomLinesStatuses = useAppSelector(orderReadModeStatusesSelector);
  const allocatedPartReqLinesStatuses = useAppSelector(allocatedPartReqLinesStatusesSelector);

  const orderStatus = orderDetails?.order?.status;

  const isOrderReadMode = blockedManagingOfCustomLinesStatuses.includes(orderStatus);
  const isUsualLinesAddingBlockedByStatus = orderStatus
      && blockedSubmitAndUsualLinesActionsStatuses.includes(orderStatus);

  const availableInfoSourcesByStatus = isUsualLinesAddingBlockedByStatus
    ? mockedOrderPartRequestCustomSource
    : mockedOrderPartRequestSources;

  const { setDeleteReasonModal, ...restReasonModal } = usePartRequestLinesReason();

  const [discountModal, setDiscountModal] = useState(false);
  const [partsModal, setPartsModal] = useState(false);

  const isLineActionDisabled = (id: number, isChildLine?: boolean) => {
    let currentLine;
    if (isChildLine) {
      const childLines = partReqOriginLines?.map((el) => el.childLines)
        .filter((el) => !!el)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
        .reduce((a, b) => [...a, ...b]);
      currentLine = childLines?.find((el) => el.id === id);
    } else {
      currentLine = partReqOriginLines?.find((el) => el.id === id);
    }
    const noPartOrPartsKitId = !currentLine?.partId && !currentLine?.partsKitId;
    const isCurrentLineCustom = isChildLine
      ? noPartOrPartsKitId && !currentLine?.partsKitComponentPartId
      : noPartOrPartsKitId;
    return isCurrentLineCustom
      ? blockedManagingOfCustomLinesStatuses?.includes(orderStatus || 0)
      : blockedSubmitAndUsualLinesActionsStatuses?.includes(orderStatus || 0);
  };

  const allItemIdsWithoutDisabled = useMemo(() => {
    const itemsWithoutDisabled = partReqOriginLines.filter((el) => !isLineActionDisabled(el.id));
    return itemsWithoutDisabled.filter(({ id }) => allItemIds.includes(id)).map(({ id }) => id);
    // eslint-disable-next-line
  }, [partReqOriginLines, allItemIds]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIdsWithoutDisabled);

  const isPartsKitLineHasAllocatedChild = (id: number) => {
    const currentLine = partReqOriginLines?.find((el) => el.id === id);
    const isPartsKitLine = currentLine?.partsKitId;
    const lineChildren = currentLine?.childLines;
    const isAnyChildAllocated = lineChildren?.find((el) => allocatedPartReqLinesStatuses.find((st) => st.value === el.status));
    return !!(isPartsKitLine && isAnyChildAllocated);
  };

  const addRow = () => {
    setPartsModal(true);
  };

  const deleteSelected = () => {
    setDeleteReasonModal(true);
  };

  const footerActions = [
    {
      label: 'Add row',
      icon: faPlus,
      onClick: () => addRow(),
      isVisible: true,
    },
    {
      label: `Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`,
      onClick: () => deleteSelected(),
      disabled: checkedKeys.length === 0,
      isVisible: true,
    },
    {
      label: `Set discount for selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`,
      onClick: () => setDiscountModal(true),
      disabled: checkedKeys.length === 0,
      isVisible: orderDetails?.order?.enableDiscounts,
    },
  ];

  return {
    columns,
    linesArr,
    footerActions,
    checkAll: checkAllHandle,
    checkRowHandle,
    isAll,
    isIndeterminate,
    checkedKeys,
    setDiscountModal,
    discountModal,
    partsModal,
    setPartsModal,
    isLineActionDisabled,
    availableInfoSourcesByStatus,
    isOrderReadMode,
    isPartsKitLineHasAllocatedChild,
    setDeleteReasonModal,
    ...restReasonModal,
  };
};
