import instance from '../axiosConfig';
import { AxiosDictionaryResponse } from '../../core/types/coreTypes';

export const coreEventTypesAPI = {
  fetchEnquiryEventTypes(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/enquiryEventTypes');
  },
  fetchRectificationEventTypes(): AxiosDictionaryResponse {
    return instance.get('/rectification/app/rectificationEventTypes');
  },
  fetchOrderEventTypes(): AxiosDictionaryResponse {
    return instance.get('/order/app/orderEventType');
  },
  fetchPartsKitEventTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsKitEventType');
  },
  fetchPartRequestEventTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestEventType');
  },
  fetchSupplierEventTypes(): AxiosDictionaryResponse {
    return instance.get('/supplier/app/supplierEventType');
  },
  fetchPricelistEventTypes(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistEventType');
  },
  fetchJobEventTypes(): AxiosDictionaryResponse {
    return instance.get('/job/app/jobEventType');
  },
  fetchEmployeeEventTypes(): AxiosDictionaryResponse {
    return instance.get('/employee/app/employeeEventType');
  },
  fetchPurchaseOrderEventTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderEventType');
  },
  fetchPartViewEventTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partEventType');
  },
  fetchCustomerEventTypes(): AxiosDictionaryResponse {
    return instance.get('/customer/app/customerEventTypes');
  },
  fetchVehicleEventTypes(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleEventType');
  },
  fetchUserEntityEventTypes(): AxiosDictionaryResponse {
    return instance.get('/account/app/userAccountEventTypes');
  },
  fetchSoldProductsByCustomerReportEntityTypes(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesSoldProductsByCustomerReportEntityType');
  },
};
