import React from 'react';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import Button from '../../../core/components/button/Button';
import { VehicleProfileSearchType } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

type ExistedVehicleCatalogCellsProps = {
  vehicleList: VehicleProfileSearchType[]
  setChosenVehicleProfile: (v: VehicleProfileSearchType | null) => void,
}

const ExistedVehicleCatalogCells: React.FC<ExistedVehicleCatalogCellsProps> = ({ vehicleList, setChosenVehicleProfile }) => {
  const hiddenKeys = ['id', 'type', 'maxPayload', 'grossVehicleWeightKg'];

  return (
    <>
      {vehicleList.map((veh, i) => <Tr key={i}>
        {Object.entries(veh).map(([key, value]) => {
          if (hiddenKeys.includes(key)) return null;
          return <TdNew key={key}>
            {key === 'number'
              ? value
              : <TruncateTooltip
                value={(value as string) || '-'}
                table
              />}
          </TdNew>;
        })}
        <TdNew>
          <Button
            label="Select"
            onClick={() => setChosenVehicleProfile(veh)}
            type="text"
          />
        </TdNew>
      </Tr>)}
    </>
  );
};

export default ExistedVehicleCatalogCells;
