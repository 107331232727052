import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { transformRoles } from '../../settings/users-dashboard/utils/permissionHelpers';
import {
  accountAccountTypesSelector,
  jobTypesSelector,
  shortBusinessAreasSelector,
  accountAccountRolesSelector,
} from './coreSelectors';
import { getDisplayOrders } from '../../settings/info-messages/utils/displayOrder';
import { accountAccountStatusSelector, infoMessageStatusesSelector } from './coreStatusesSelectors';
import { labelHandle } from '../../core/utils/labelHandle';
import { infoMessagesOrderingSelector, userAccountsOrderingSelector } from './coreOrderingSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { InfoMessagesOrdering } from '../../settings/info-messages/utils/data';
import { UsersListOrdering } from '../../settings/users-dashboard/enums/settingsEnums';

export const usersListFiltersSelector = (state: RootState) => state.settings.listFilters;

export const usersListColumnsSelector = createSelector(userAccountsOrderingSelector, (ordering) => [
  {
    title: 'Name',
    dataIndex: 'name',
    orderField: getSortIndex(UsersListOrdering.Name, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Email',
    dataIndex: 'email',
    orderField: getSortIndex(UsersListOrdering.Email, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Account type',
    dataIndex: 'accountType',
    orderField: getSortIndex(UsersListOrdering.AccountType, ordering),
    sorter: true,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    orderField: getSortIndex(UsersListOrdering.Status, ordering),
    sorter: true,
  },
  {
    title: 'Business area',
    dataIndex: 'businessArea',
  },
  {
    title: 'Job type',
    dataIndex: 'jobType',
  },
  {
    title: 'Role',
    dataIndex: 'role',
    orderField: getSortIndex(UsersListOrdering.Role, ordering),
    sorter: true,
  },
  {
    title: 'View assigned only',
    dataIndex: 'canSeeOnlyAssignedEntities',
    orderField: getSortIndex(UsersListOrdering.ViewAssignedOnly, ordering),
    sorter: true,
    filters: [
      { value: true, label: 'Yes' },
      { value: false, label: 'No' },
    ],
  },
  {
    title: 'Employee reference No',
    dataIndex: 'refNo',
    sorter: true,
    filterSearch: true,
    orderField: getSortIndex(UsersListOrdering.RefNo, ordering),
  },
]);

export const usersDashFiltersListSelector = createSelector(
  usersListFiltersSelector,
  accountAccountTypesSelector,
  accountAccountStatusSelector,
  shortBusinessAreasSelector,
  jobTypesSelector,
  accountAccountRolesSelector,
  (
    usersListFilters,
    accountAccountTypes,
    accountAccountStatus,
    businessAreas,
    jobTypes,
    accountAccountRoles,
  ) => {
    return [
      {
        label: 'Account type',
        name: 'accountType',
        value: usersListFilters.accountType,
        options: accountAccountTypes,
      },
      {
        label: 'Status',
        name: 'status',
        value: usersListFilters.status,
        options: accountAccountStatus,
      },
      {
        label: 'Business area',
        name: 'businessArea',
        value: usersListFilters.businessArea,
        options: businessAreas,
      },
      {
        label: 'Job type',
        name: 'jobType',
        value: usersListFilters.jobType,
        options: jobTypes,
      },
      {
        label: 'Role',
        name: 'role',
        value: usersListFilters.role,
        options: accountAccountRoles,
      },
    ];
  },
);

const usersListInitSelector = (state: RootState) => state.settings.usersList;
export const usersListSelector = createSelector(
  shortBusinessAreasSelector,
  jobTypesSelector,
  accountAccountTypesSelector,
  accountAccountStatusSelector,
  usersListInitSelector,
  (shortBusinessAreas, jobTypes, accountAccountTypes, accountAccountStatus, data) => {
    return {
      ...data,
      items: data.items.map((el) => ({
        id: el.id,
        name: `${el.firstName} ${el.lastName}`,
        email: el.email,
        accountType: labelHandle(el.accountType, accountAccountTypes),
        status: labelHandle(el.status, accountAccountStatus),
        businessArea: labelHandle(el.allowedBusinessAreas, shortBusinessAreas),
        jobType: labelHandle(el.allowedJobTypes, jobTypes),
        role: el.userAccountRole.fullName || '-',
        canSeeOnlyAssignedEntities: !el.canSeeOnlyAssignedEntities ? 'No' : 'Yes',
        refNo: el.employeeReferenceNumber || '',
        employeeId: el.employeeId,
      })),
    };
  },
);
export const userProfileSelector = (state: RootState) => state.settings.userProfile;
export const permissionsSelector = (state: RootState) => state.settings.permissions;

export const permissionTreeSelector = createSelector(permissionsSelector, (items) => {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line no-return-assign,no-sequences
  const helper = items?.reduce((h, o) => (h[o.permission] = { ...o }, h), Object.create(null));

  return items?.reduce((t, node) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const current = helper[node.permission];

    if (!current.parentPermission) { // if it doesn't have a parent push to root
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      t.push(current);
    } else {
      // add the children array to the parent, if it doesn't exist
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      helper[node.parentPermission].children || (helper[node.parentPermission].children = []);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      helper[node.parentPermission].children.push(current); // push the current item to the parent children array
    }

    return t;
    // eslint-disable-next-line
  }, []);
});

export const permissionRolesSelector = (state: RootState) => state.settings.rolePermissions;
export const transformedPermissionRoles = createSelector(permissionRolesSelector, (items) => {
  return transformRoles(items);
});
export const infoMessagesListFiltersSelector = (state: RootState) => state.settings.infoMessagesFilters;
export const infoMessagesListSelector = (state: RootState) => state.settings.infoMessagesList;
export const infoMessagesMaxDisplayOrderSelector = (state: RootState) => state.settings.infoMessageMaxDisplayOrder;

export const adminRoleSelector = createSelector(permissionRolesSelector, (items) => {
  return items?.find((item) => item.role.fullName === 'Admin');
});

export const permissionModulesSelector = (state: RootState) => state.settings.permissionModules;

export const infoMessagesColumnsSelector = createSelector(
  infoMessageStatusesSelector,
  infoMessagesMaxDisplayOrderSelector,
  infoMessagesOrderingSelector,
  (statuses, displayOrder, ordering) => [
    {
      title: 'Title',
      dataIndex: 'title',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(InfoMessagesOrdering.Title, ordering),
    },
    {
      title: 'Message',
      dataIndex: 'message',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(InfoMessagesOrdering.Message, ordering),
    },
    {
      title: 'Display order',
      dataIndex: 'displayOrder',
      sorter: true,
      filters: getDisplayOrders(displayOrder),
      orderField: getSortIndex(InfoMessagesOrdering.DisplayOrder, ordering),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      sorter: true,
      filters: statuses,
      orderField: getSortIndex(InfoMessagesOrdering.Status, ordering),
    },
    {
      title: 'Created date',
      dataIndex: 'createDate',
      sorter: true,
      isDateRange: true,
      orderField: getSortIndex(InfoMessagesOrdering.CreatedAt, ordering),
    },
  ],
);

export const currentPermissionModuleSelector = (state: RootState) => state.settings.currentPermissionModule;

export const userTimelineSelector = (state: RootState) => state.settings.userTimeline;
export const userTimelineFiltersSelector = (state: RootState) => state.settings.userTimelineFilters;

export const userEntityTimelineSelector = (state: RootState) => state.settings.userEntityTimeline;
export const userEntityTimelineFiltersSelector = (state: RootState) => state.settings.userEntityTimelineFilters;
