import { SalesRectificationReportData } from '../../../../../types/RectificationReportsTypes';
import { initDataList } from '../../../../../../core/types/coreTypes';

export const initRectificationReportData: SalesRectificationReportData = {
  ...initDataList,
  minCostNet: 0,
  maxCostNet: 0,
  minSalesNet: 0,
  maxSalesNet: 0,
};
