import React, { useEffect, useState } from 'react';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import SpecificEntitiesTable from './SpecificEntitiesTable';
import SpecificEntitiesFilters from './SpecificEntitiesFilters';
import { useSelectAll } from '../../../../core/hooks/useSelectAll';
import SpecificEntitiesSummary from './SpecificEntitiesSummary';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  invoiceDocumentsSelector,
} from '../../../../store/selectors/invoicingSelectors';
import { setInvoiceDocumentsSummary } from '../../../../store/slices/invoicingSlice';
import { initInvoiceDocumentsSummary } from '../../../invoicing-purchases/types/InvoicingPurchasesTypes';
import {
  exportInvoiceDocumentsCsvFileThunk,
  getInvoiceDocumentsSummaryThunk,
} from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';

type SpecificEntitiesContentProps = {
  onCancel: VoidFunctionType,
  type: 'purchases' | 'sales',
}

const SpecificEntitiesContent: React.FC<SpecificEntitiesContentProps> = ({ onCancel, type }) => {
  const dispatch = useAppDispatch();
  const { allItemIds } = useAppSelector(invoiceDocumentsSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const [isMarkAsExportedAfterCSV, setIsMarkAsExportedAfterCSV] = useState(true);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    if (checkedKeys.length) {
      dispatch(getInvoiceDocumentsSummaryThunk({ invoiceDocumentIds: checkedKeys }));
    } else {
      dispatch(setInvoiceDocumentsSummary(initInvoiceDocumentsSummary));
    }
    // eslint-disable-next-line
  }, [checkedKeys]);

  const submitHandle = () => {
    dispatch(exportInvoiceDocumentsCsvFileThunk({
      invoiceDocumentIds: checkedKeys,
      markDraftAsExported: isMarkAsExportedAfterCSV,
      onFinish: onCancel,
    }));
  };

  return (
    <>
      <SpecificEntitiesFilters type={type} />
      <SpecificEntitiesTable
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkedKeys={checkedKeys}
        checkRow={checkRowHandle}
        checkAll={checkAllHandle}
        type={type}
      />
      <SpecificEntitiesSummary />
      <Checkbox
        label={'Mark selected draft entities as "Exported" after generation of a CSV file'}
        checked={isMarkAsExportedAfterCSV}
        onChange={(v) => setIsMarkAsExportedAfterCSV(v)}
        className="entitiesForExport__checkbox"
      />
      <ButtonActions
        createLabel={`Export selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
        createType="button"
        cancelClick={onCancel}
        createClick={submitHandle}
        isLoading={isLoading}
        offsetTop
      />
    </>
  );
};

export default SpecificEntitiesContent;
