import React, { useEffect } from 'react';
import {
  Controller, SubmitHandler, useForm, useFormState,
} from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../../../core/utils/regex';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import './EditPriceModal.scss';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { partQuantitiesSelector } from '../../../../../store/selectors/stockSelectors';
import { patchPartQuantities } from '../../../../../store/thunks/stock/stockThunks';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';

type EditPriceFields = {
  costPrice: string,
  sellingPrice: string,
}

type EditPriceModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  qtyToChange: number | undefined,
}
const EditPriceModal: React.FC<EditPriceModalProps> = ({ visible, onClose, qtyToChange }) => {
  const dispatch = useAppDispatch();
  const { id: partId } = useParams();
  const { items } = useAppSelector(partQuantitiesSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const {
    control, handleSubmit, setValue, reset,
  } = useForm<EditPriceFields>();
  const { errors } = useFormState({ control });

  const handleClose = () => {
    onClose();
    reset();
  };

  const onSubmit: SubmitHandler<EditPriceFields> = (values) => {
    if (partId && qtyToChange) {
      dispatch(patchPartQuantities({
        partId: +partId,
        id: qtyToChange,
        data: {
          costPrice: +values.costPrice,
          sellingPrice: +values.sellingPrice,
        },
        onClose,
      }));
    }
  };

  useEffect(() => {
    if (visible && qtyToChange) {
      const found = items?.find((el) => el.id === qtyToChange);
      if (found) {
        setValue('costPrice', `${found.costPrice}`);
        setValue('sellingPrice', found.sellingPrice ? `${found.sellingPrice}` : '');
      }
    }
  }, [visible, qtyToChange, items, setValue]);

  return (
    <Modal
      visible={visible}
      title="Edit price"
      onCancel={handleClose}
      destroyOnClose
    >
      <form onSubmit={handleSubmit(onSubmit)} className="editPriceForm">
        <Controller
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            validate: {
              greaterThanZero: (v) => +v > 0 || ErrorsEnum.GREATER_THAN_ZERO,
            },
          }}
          name="costPrice"
          render={({ field }) => (
            <DecimalInputNumber
              label="Cost price"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value.replace(',', '.'));
              }}
              max={maxCommonDecimal}
              error={errors.costPrice?.message}
              maxDecimalPlaceLength={4}
            />
          )}
        />
        <Controller
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            validate: {
              greaterThanZero: (v) => +v > 0 || ErrorsEnum.GREATER_THAN_ZERO,
            },
          }}
          name="sellingPrice"
          render={({ field }) => (
            <DecimalInputNumber
              label="Selling price"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value.replace(',', '.'));
              }}
              max={maxCommonDecimal}
              error={errors.sellingPrice?.message}
            />
          )}
        />
        <ButtonActions
          createLabel="Save"
          cancelClick={handleClose}
          createType="submit"
          isLoading={isFetching}
          offsetTop
        />
      </form>
    </Modal>
  );
};

export default EditPriceModal;
