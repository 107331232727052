import React from 'react';
import { DraftLinePartsKitFiltersType, DraftLinePartsKitList } from '../../types/commonTypes';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../core/components/table-components/Tbody';
import PartsKitModeCells from './PartsKitModeCells';

type PartsKitModeBodyProps = {
  partsKitParts?: DraftLinePartsKitList,
  searchValue?: string,
  partsKitFilters?: DraftLinePartsKitFiltersType,
  createPartsKitLine: (partsKitId: number) => void,
}

const PartsKitModeBody: React.FC<PartsKitModeBodyProps> = ({
  partsKitParts, searchValue, partsKitFilters, createPartsKitLine,
}) => {
  return (
    <Tbody>
      {partsKitParts?.totalCount === 0
        ? <EmptyTableSection
          colSpan={6}
          text={searchValue && partsKitParts.totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
        />
        : <PartsKitModeCells
          partsKitParts={partsKitParts}
          partsKitFilters={partsKitFilters}
          createPartsKitLine={createPartsKitLine}
        />}
    </Tbody>
  );
};

export default PartsKitModeBody;
