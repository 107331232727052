import React from 'react';
import {
  Control, Controller, FieldErrors, UseFormClearErrors, UseFormSetError, UseFormSetValue, useFormState,
} from 'react-hook-form';
import { ErrorsEnum } from '../../../../../../core/enums/errorsEnum';
import Input from '../../../../../../core/components/input/Input';
import Select from '../../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { shortBusinessAreasSelector } from '../../../../../../store/selectors/coreSelectors';
import {
  partsKitAvailableCategoriesSelector,
  partsKitDetailsSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { patchPartsKitThunk } from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { clearOtherFieldsErrorFn } from '../../../../../../core/utils/clearOtherFieldsErrorFn';
import { compareValuesHandle } from '../../../../../../core/utils/getDifferenceBetweenObjects';
import { PartsKitsDto } from '../../../../dashboard/types/partsKitsTypes';
import Textarea from '../../../../../../core/components/textarea/Textarea';
import { PartsKitCodeSchema, PartsKitNameSchema } from '../../../../create/types/CreatePartsKitTypes';
import { PartsKitDescriptionSchema, PartsKitFields } from '../../../utils/PartsKitDetailsSchema';

type PartsKitInfoFormProps = {
  control: Control<PartsKitFields>,
  setValue: UseFormSetValue<PartsKitFields>,
  setError: UseFormSetError<PartsKitFields>,
  setCategorySearchV: React.Dispatch<React.SetStateAction<string | undefined>>,
  affectedHandle: (action: VoidFunctionType, dataToAffect: number[]) => void,
  clearErrors: UseFormClearErrors<PartsKitFields>,
  fieldsToValidate: string[],
}

const PartsKitInfoForm: React.FC<PartsKitInfoFormProps> = ({
  control, setValue, setError, setCategorySearchV,
  affectedHandle, clearErrors, fieldsToValidate,
}) => {
  const dispatch = useAppDispatch();
  const baList = useAppSelector(shortBusinessAreasSelector);
  const partsKitCategories = useAppSelector(partsKitAvailableCategoriesSelector);
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);
  const allowedToEdit = usePermission(PermissionEnum.StockPartsKitEditFields);

  const { errors } = useFormState({ control });

  const clearOtherErrorsFn = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, partsKitDetails, setValue);
  };

  const patchHandle = (id: number, data: Partial<PartsKitsDto>) => {
    dispatch(patchPartsKitThunk({
      id,
      data: {
        partsKitId: id,
        ...data,
      },
      setError,
    }));
  };
  const editField = (data: Partial<PartsKitsDto>, key: keyof PartsKitsDto, deepCompare?: boolean, isOptional?: boolean) => {
    if (partsKitDetails) {
      const handler = () => {
        patchHandle(partsKitDetails.id, data);
      };
      compareValuesHandle<PartsKitsDto>(data, key, partsKitDetails, handler, deepCompare, isOptional);
    }
  };
  const checkPartsKitAffected = (data: Partial<PartsKitsDto>, key: keyof PartsKitsDto, dataToAffect: number[], deepCompare?: boolean) => {
    if (partsKitDetails) {
      const handler = () => {
        affectedHandle(() => patchHandle(partsKitDetails.id, data), dataToAffect);
      };
      compareValuesHandle<PartsKitsDto>(data, key, partsKitDetails, handler, deepCompare);
    }
  };
  return (
    <form className="details-form">
      <Controller
        name="name"
        control={control}
        rules={PartsKitNameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(v) => {
              field.onChange(v);
              clearOtherErrorsFn('name');
            }}
            onBlur={(e) => {
              const val = e.target.value;
              if (val.trim().length === 0) {
                setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
                setValue('name', '');
              } else {
                !errors.name && editField({ name: val.trim() }, 'name');
              }
            }}
            error={errors.name?.message}
            disabled={!allowedToEdit}
            label="Parts kit name"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="partsKitCode"
        control={control}
        rules={PartsKitCodeSchema}
        render={({ field }) => (
          <Input
            label="Parts kit code"
            value={field.value?.toUpperCase()}
            onChange={(e) => {
              field.onChange(e);
              clearOtherErrorsFn('partsKitCode');
            }}
            onBlur={(e) => {
              const v = e.target.value;
              if (v.trim().length === 0) {
                setError('partsKitCode', { type: 'required', message: ErrorsEnum.REQUIRED });
                setValue('partsKitCode', '');
              } else {
                !errors.partsKitCode && editField({ partsKitCode: v.trim() }, 'partsKitCode');
              }
            }}
            disabled={!allowedToEdit}
            error={errors.partsKitCode?.message}
          />
        )}
      />
      <Controller
        name="categoryId"
        control={control}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(v) => {
              field.onChange(v);
              clearOtherErrorsFn('categoryId');
              editField({ categoryId: v as number }, 'categoryId');
            }}
            options={partsKitCategories}
            showSearch
            onSearch={(val) => {
              setCategorySearchV(val);
            }}
            onSelect={() => {
              setCategorySearchV(undefined);
            }}
            disabled={!allowedToEdit}
            label="Category"
          />
        )}
      />
      <Controller
        name="description"
        control={control}
        rules={PartsKitDescriptionSchema}
        render={({ field }) => (
          <Textarea
            value={field.value || ''}
            onChange={(e) => {
              field.onChange(e.target.value);
              clearOtherErrorsFn('description');
            }}
            onBlur={() => !errors.description
                && editField({ description: field.value?.trim() || null }, 'description', false, true)}
            label="Description"
            disabled={!allowedToEdit}
            error={errors.description?.message}
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="businessAreas"
        control={control}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(v) => {
              field.onChange(v);
              clearOtherErrorsFn('businessAreas');
            }}
            onBlur={() => {
              if (!errors.businessAreas && field.value.length > 0) {
                const businessAreasToRemove = partsKitDetails?.businessAreas?.filter((area) => !field.value.includes(area));
                if (businessAreasToRemove?.length === 0) {
                  editField({ businessAreas: field.value }, 'businessAreas', true);
                } else {
                  checkPartsKitAffected(
                    { businessAreas: field.value },
                    'businessAreas',
                    field.value as number[],
                    true,
                  );
                }
              }
            }}
            onDeselect={(v) => {
              const newAreas = partsKitDetails?.businessAreas?.filter((area) => area !== v);
              if (newAreas?.length === 0) {
                setError('businessAreas', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            disabled={!allowedToEdit}
            mode="multiple"
            options={baList}
            error={(errors.businessAreas as FieldErrors)?.message}
            label="Parts kit business areas"
            className="details-form__field--lg"
            showArrow
          />
        )}
      />
    </form>
  );
};

export default PartsKitInfoForm;
