import React, { useState } from 'react';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import CustomerForm from './components/CustomerForm';
import RequisiteModal from '../../common/components/requisite/RequisteModal';
import { initRequisite, RequisiteFields } from '../../common/utils/data';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';

const CreateCustomer: React.FC = () => {
  const { backHandle } = useLocationHistoryState();
  const [isVisible, setIsVisible] = useState(false);
  const [isShippingMode, setShippingMode] = useState(false);
  const [isSame, setIsSame] = useState(true);
  const [requisites, setRequisites] = useState<RequisiteFields>({ billing: initRequisite, shipping: initRequisite });

  return (
    <Layout subHeader={
      <DetailsHead goBack={() => backHandle()} isCreatePage>
        <div className="createHead">Create new customer</div>
      </DetailsHead>
    }
    >
      <RequisiteModal
        isVisible={isVisible}
        onCancel={() => {
          setIsVisible(false);
          setShippingMode(false);
          const shippingValues = Object.values(requisites.shipping);
          shippingValues.every((v) => !v) && setIsSame(true);
        }}
        isShipping={isShippingMode}
        submitHandle={(values) => {
          if (!isShippingMode) {
            setRequisites({ ...requisites, billing: values });
          } else {
            setRequisites({ ...requisites, shipping: values });
            const shippingValues = Object.values(values);
            shippingValues.every((v) => !v) && setIsSame(true);
          }
          setShippingMode(false);
          setIsVisible(false);
        }}
        isOptionalFields
        requisite={isShippingMode ? requisites.shipping : requisites.billing}
      />
      <CustomerForm
        setIsVisible={setIsVisible}
        setIsSame={setIsSame}
        setShippingMode={setShippingMode}
        isSame={isSame}
        requisites={requisites}
        setRequisites={setRequisites}
      />
    </Layout>
  );
};

export default CreateCustomer;
