import React from 'react';
import {
  Controller, useForm, useFormState,
} from 'react-hook-form';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { zodResolver } from '@hookform/resolvers/zod';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import InputPassword from '../../../../../core/components/input-password/InputPassword';
import { useAppDispatch } from '../../../../../store/hooks';
import { changePasswordThunk } from '../../../../../store/thunks/account/accountThunks';
import { EditPassword, editPasswordDefaults, PasswordSchema } from '../../../../utils/EditPasswordSchema';

type EditPasswordModalProps = {
  userProfileId: number | undefined
  visible: boolean,
  onCancel: VoidFunctionType,
  destroyOnClose?:boolean
}

const EditPasswordModal:React.FC<EditPasswordModalProps> = ({
  userProfileId, visible, onCancel, destroyOnClose,
}) => {
  const dispatch = useAppDispatch();

  const {
    control, handleSubmit, setError, reset,
  } = useForm<EditPassword>({
    resolver: zodResolver(PasswordSchema),
    defaultValues: editPasswordDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const cancelHandle = () => {
    reset();
    onCancel();
  };

  const onSubmit = (values: EditPassword) => {
    userProfileId && dispatch(changePasswordThunk({
      id: userProfileId,
      currentPassword: values.password,
      newPassword: values.newPassword,
      setError,
      commonActions: cancelHandle,
    }));
  };

  return (
    <Modal visible={visible} onCancel={cancelHandle} title="Edit password" destroyOnClose={destroyOnClose}>
      <form onSubmit={handleSubmit(onSubmit)} className="details-form">
        <Controller
          control={control}
          name="password"
          render={({ field }) => (
            <InputPassword
              value={field.value}
              onChange={field.onChange}
              error={errors.password?.message}
              label="Current password"
              className="details-form__field--lg"
              visibilityToggle={false}
            />
          )}
        />
        <Controller
          control={control}
          name="newPassword"
          render={({ field }) => (
            <InputPassword
              value={field.value}
              onChange={field.onChange}
              error={errors?.confirmNewPassword?.type === 'custom' ? 'Entered passwords do not match.' : errors.newPassword?.message}
              label="New password"
              suffix={
                <Tooltip
                  trigger="click"
                  title={
                    <div className="accountFinalization__tooltip">
                      <span>Password should be at least 8 characters long and include:</span>
                      <ul>
                        <li>one uppercase letter [A-Z]</li>
                        <li>one lowercase letter [a-z]</li>
                        <li>one number [0-9]</li>
                      </ul>
                    </div>
                  }
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </Tooltip>
              }
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="confirmNewPassword"
          render={({ field }) => (
            <InputPassword
              value={field.value}
              onChange={field.onChange}
              error={errors.confirmNewPassword?.message}
              label="Confirm new password"
              className="details-form__field--lg"
            />
          )}
        />
        <ButtonActions
          createLabel="Save"
          cancelLabel="Cancel"
          createType="submit"
          cancelClick={cancelHandle}
          className="accountFinalization__actions details-form__field--lg"
        />
      </form>
    </Modal>
  );
};

export default EditPasswordModal;
