import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fitterReturnsFiltersSelector } from '../../store/selectors/reportsSelectors';
import { fitterReturnsReportOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { FitterReturnsEnum } from '../enum/ReportTableOrders';
import { setFitterReturnsReportFilters } from '../../store/slices/reportsSlice';
import { initPaging } from '../../core/types/coreTypes';
import { getPurchasingFitterReturnsReportOrdering } from '../../store/thunks/core/coreOrderingThunks';

export const useFitterReturnsReportDefaultPaging = (shouldFetch = true) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(fitterReturnsFiltersSelector);
  const ordering = useAppSelector(fitterReturnsReportOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    ordering.length === 0
        && shouldFetch
        && dispatch(getPurchasingFitterReturnsReportOrdering());
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === FitterReturnsEnum.DeletionDate)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setFitterReturnsReportFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
    }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: true } : filters.order,
  };
};
