import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import {
  ordersDashFiltersListSelector,
  ordersDashFiltersSelector,
} from '../../../store/selectors/ordersSelector';
import { setDashFilters } from '../../../store/slices/ordersSlice';
import { OrdersFiltersType } from '../types/ordersTypes';
import { getOrderCollections } from '../../../store/thunks/orders/dashOrdersThunks';
import { orderStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import { infiniteScrollInitPaging } from '../../../core/types/coreTypes';
import { OrdersPageParamsEnum } from '../enums/OrdersViewsEnum';

export const useOrdersFilters = () => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector(ordersDashFiltersSelector);
  const filtersList = useAppSelector(ordersDashFiltersListSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const orderStatus = useAppSelector(orderStatusSelector);

  const [params, setSearchParams] = useSearchParams();
  const keywordSearchParam = params.get('keyword');

  const initDropdownSearch = {
    priority: '',
    assignedTo: '',
    jobType: '',
    customerType: '',
    warnings: '',
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [keywordValue, setKeywordValue] = useState<string | undefined>(stateFilters?.keyword);

  const { controller, cancelRequest } = useCancelRequest();

  const getFilteredRes = (filters: OrdersFiltersType) => {
    cancelRequest();
    dispatch(setDashFilters({ ...filters }));
    currentBusinessArea && dispatch(getOrderCollections({
      params: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: orderStatus.map((st) => st.value),
        page: 1,
      },
      signal: controller.current?.signal,
    }));
  };

  const setInitSearchParams = () => {
    setSearchParams({ page: params.get('page') || OrdersPageParamsEnum.DASHBOARD });
  };

  const onClear = () => {
    getFilteredRes(infiniteScrollInitPaging);
    setKeywordValue('');
    if (keywordSearchParam) {
      setInitSearchParams();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    initDropdownSearch,
    filtersList,
    stateFilters,
    getFilteredRes,
    onClear,
    currentBusinessArea,
    setInitSearchParams,
    keywordSearchParam,
  };
};
