import React from 'react';
import JobActivitySection from './JobActivitySection';
import DetailsAndVehicles from './JobDetailsAndVehicles';

const JobDetailsAndActivity: React.FC = () => {
  return (
    <div className="detailsWrap info-grid">
      <DetailsAndVehicles />
      <JobActivitySection />
    </div>
  );
};

export default JobDetailsAndActivity;
