import React, { useEffect } from 'react';
import EmployeesList from './components/EmployeesList';
import EmployeesActions from './components/EmployeesActions';
import './EmployeesListPage.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getEmployeeDashboardThunk } from '../../store/thunks/employee/employeesDashboardThunks';
import { employeesFiltersSelector } from '../../store/selectors/employeesSelector';
import { initDataList, StringFunctionType } from '../../core/types/coreTypes';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { setEmployeeDashboard } from '../../store/slices/employeesSlice';
import { useCancelRequest } from '../../core/hooks/useCancelRequest';

type EmployeesListPageProps = {
  view: string | null,
  setEmployeeStatusParam: StringFunctionType,
  employeeStatusParam: string | null,
}

const EmployeesListPage: React.FC<EmployeesListPageProps> = ({ view, setEmployeeStatusParam, employeeStatusParam }) => {
  const dispatch = useAppDispatch();
  const employeesFilters = useAppSelector(employeesFiltersSelector);
  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    cancelRequest();
    employeesFilters.status && dispatch(getEmployeeDashboardThunk({
      filters: employeesFilters,
      signal: controller.current?.signal,
    }));
    // eslint-disable-next-line
  }, [employeesFilters]);

  useEffect(() => {
    return () => {
      dispatch(setEmployeeDashboard(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <EmployeesActions view={view} setEmployeeStatusParam={setEmployeeStatusParam} employeeStatusParam={employeeStatusParam} />
      <EmployeesList />
    </>
  );
};

export default EmployeesListPage;
