import React from 'react';
import { getReadableBoolean } from '../../../utils/activityLogUtils';

enum attachmentEventsEnum {
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  EditingAttachmentVisibility = 'EditingAttachmentVisibility',
}

type AttachmentEventsProps = {
  eventName: string,
  fileName?: string,
  comment?: string,
  oldValue?: string | number | boolean,
  newValue?: string | number | boolean,
}

const AttachmentEvents: React.FC<AttachmentEventsProps> = ({
  eventName,
  fileName,
  comment,
  oldValue,
  newValue,
}) => {
  const renderAttachmentEventFn = () => {
    switch (eventName) {
      case attachmentEventsEnum.AddingAttachmentWithoutComment:
        return (
          <>
            Attachment
            {' '}
            <b>{fileName}</b>
            {' '}
            was uploaded
          </>
        );
      case attachmentEventsEnum.AddingAttachmentWithComment:
        return (
          <>
            Attachment
            {' '}
            <b>{fileName}</b>
            {' '}
            was uploaded with the following comment:
            {' '}
            <b>{`"${comment}"`}</b>
          </>
        );
      case attachmentEventsEnum.EditingAttachmentComment:
        return (
          <>
            Comment was updated for attachment
            {' '}
            <b>{fileName}</b>
            :
            {' '}
            {!!oldValue && <s>{`"${oldValue}"`}</s>}
            {!!newValue && <b>{` "${newValue}"`}</b>}
          </>
        );
      case attachmentEventsEnum.DeletingAttachment:
        return (
          <>
            Attachment
            {' '}
            <b>{fileName}</b>
            {' '}
            was deleted
          </>
        );
      case attachmentEventsEnum.EditingAttachmentVisibility:
        return (
          <>
            Visibility of
            {' '}
            <b>{fileName}</b>
            {' '}
            attachment was updated:
            <br />
            {/* eslint-disable-next-line react/no-unescaped-entities */}
            "Show in linked jobs"
            {' '}
            =
            {' '}
            {oldValue !== undefined && <s>{`${getReadableBoolean(oldValue)}`}</s>}
            {newValue !== undefined && <b>{` ${getReadableBoolean(newValue)}`}</b>}
          </>
        );

      default: return null;
    }
  };

  return (
    <>{renderAttachmentEventFn()}</>
  );
};

export default AttachmentEvents;
