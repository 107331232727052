import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Tag from '../../../core/components/tag/Tag';
import { setValueInHours } from '../../../core/utils/setValueInHours';
import { JobDictionaryDto } from '../types/jobCalendarTypes';
import { useAppSelector } from '../../../store/hooks';
import { jobStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT } from '../../../core/utils/regex';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';

type ChangeJobsOrderItemProps = {
  item: JobDictionaryDto,
  disabledMove?: boolean,
}

const ChangeJobsOrderItem: React.FC<ChangeJobsOrderItemProps> = ({ item, disabledMove }) => {
  const jobStatuses = useAppSelector(jobStatusesSelector);

  return (
    <div className="calendarJobInfo">
      <FontAwesomeIcon icon={faBars} className={`listIcon ${disabledMove ? 'disabledMove' : ''}`} />
      <div className="calendarJobInfo__content">
        <TruncateTooltip
          value={item.jobNumber}
          className="calendarJobInfo__number"
          overlayClassName="truncate-table-tooltip"
        />
        <TruncateTooltip
          value={item.customerName}
          className="calendarJobInfo__name"
          overlayClassName="truncate-table-tooltip"
        />
      </div>
      <Tag label={jobStatuses.find((status) => status.value === item.status)?.label || ''} />
      <div className="calendarJobInfo__time-wrapper">
        <div className="calendarJobInfo__time">
          <span>Due date</span>
          <b>{item.dueDate ? localDateFormatHandler(DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT, item.dueDate) : '-'}</b>
        </div>
        <div className="calendarJobInfo__time">
          <span>Spent</span>
          <b>{`${setValueInHours(item.spentMinutesByAllAssignees)} h`}</b>
        </div>
        <div className="calendarJobInfo__time">
          <span>Estimated</span>
          <b>{`${setValueInHours(item.estimatedMinutes)} h`}</b>
        </div>
      </div>
    </div>
  );
};

export default ChangeJobsOrderItem;
