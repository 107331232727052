import React, { useEffect, useState } from 'react';
import './JobTimeTracking.scss';
import { useParams } from 'react-router-dom';
import DecimalInputNumber from '../../../../../core/components/input-number/DecimalInputNumber';
import JobTimeTrackingTable from './JobTimeTrackingTable';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { isJobStatusCompletedSelector, jobTimeTrackingSelector } from '../../../../../store/selectors/jobsSelector';
import { changeJobDetailsThunk, getJobTimeTracking } from '../../../../../store/thunks/jobs/jobThunks';
import { setJobTimeTracking } from '../../../../../store/slices/jobsSlice';

const JobTimeTracking: React.FC = () => {
  const allowedToManage = usePermission(PermissionEnum.JobTimeTracking);
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [estimVal, setEstimVal] = useState<string>('0.00');
  const isJobStatusCompleted = useAppSelector(isJobStatusCompletedSelector);
  const jobTimeTracking = useAppSelector(jobTimeTrackingSelector);

  useEffect(() => {
    id && dispatch(getJobTimeTracking({ id: +id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (jobTimeTracking) {
      const { estimated } = jobTimeTracking;
      if (estimated) {
        const inHours = (estimated / 60).toFixed(2).toString();
        setEstimVal(inHours);
      } else {
        setEstimVal('0.00');
      }
    }
    // eslint-disable-next-line
  }, [jobTimeTracking]);

  const changeEstimation = (inMinutes: number) => {
    id && dispatch(changeJobDetailsThunk({
      id: +id,
      patchData: { estimationMinutes: Math.round(inMinutes) },
      refetch: () => id && dispatch(getJobTimeTracking({ id: +id })),
    }));
  };

  useEffect(() => {
    return () => {
      dispatch(setJobTimeTracking(null));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <section className="jobTracking">
      <DecimalInputNumber
        label="Estimated hours"
        value={estimVal}
        onChange={(e) => {
          const v = e.target.value.replace(',', '.');
          setEstimVal(v);
        }}
        onBlur={(e) => {
          const v = e.target.value;
          const inMinutes = +v * 60;
          if (!v) {
            setEstimVal('0.00');
          }
          changeEstimation(inMinutes);
        }}
        max={999.99}
        postfix="h"
        disabled={isJobStatusCompleted || !allowedToManage}
        className="jobTracking__estimateField"
      />
      <JobTimeTrackingTable jobTimeTracking={jobTimeTracking} />
    </section>
  );
};

export default JobTimeTracking;
