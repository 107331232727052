import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum DashboardViewEnum {
  DASHBOARD = 'Dashboard',
  CREATE = 'Create new rectification',
}

export enum RectificationModesEnum {
  DASHBOARD = 'dashboard',
  CREATE = 'create-new-rectification',
}

export const RectificationModes = [
  RectificationModesEnum.DASHBOARD, RectificationModesEnum.CREATE,
];

export const RectificationPageParamsAndPermissions = [
  { page: RectificationModesEnum.DASHBOARD, permission: PermissionEnum.RectificationDashboard },
  { page: RectificationModesEnum.CREATE, permission: PermissionEnum.RectificationCreate },
];

export enum RectificationStatusesEnum {
  New = 'New',
  ReadyForProcessing = 'Ready for processing',
  InProgress = 'In progress',
  MissingInformation = 'Missing information',
  Postponed = 'Postponed',
  DetailsReview = 'Details review',
  Booking = 'Booking',
  ExpensesReview = 'Expenses review',
  Finalised = 'Finalised',
  JobInProgress = 'Job in progress',
  AwaitingSubcontractorWork = 'Awaiting subcontractor work',
  ReadyForPostCheck = 'Ready for post-check',
  PostCheckFailed = 'Post-check failed',
  PostCheckPassed = 'Post-check passed',
  RejectedByCustomer = 'Rejected by customer',
  ReasonReview = 'Reason review',
  AcceptedByCustomer = 'Accepted by customer',
  Invoicing = 'Invoicing',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}

export enum RectificationTypeEnum {
  FREE = 1,
  PAID,
}

export enum RectificationFaultTypesNameEnum {
  CustomerFault = 'Customer fault',
  DamageToVehicles = 'Damage to vehicles',
  FitterTimeExceeded = 'Fitter time exceeded',
  OffsiteFitmentFault = 'Off-site fitment fault',
  OnsiteFitmentFault = 'On-site fitment fault',
  ProductFault = 'Product fault',
  SalesFault = 'Sales fault',
  WoodManufacturingFault = 'Wood manufacturing fault',
}
