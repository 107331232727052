import { useCallback, useEffect } from 'react';
import { debounce } from 'lodash';

export const useScreenHeight = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResize = useCallback(
    debounce(() => {
      document.documentElement.style.setProperty('--doc-height', `${window.innerHeight}px`);
    }, 20),
    [],
  );

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => document.removeEventListener('resize', handleResize);
  }, [handleResize]);
};
