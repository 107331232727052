export const editPartPriceColumns = [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Cost price',
    dataIndex: 'costPrice',
  },
  {
    title: 'Selling price',
    dataIndex: 'sellPrice',
  },
  {
    title: 'Effective start date',
    dataIndex: 'effectiveStartDate',
  },
  {
    title: 'Effective end date',
    dataIndex: 'effectiveEndDate',
  },
];
