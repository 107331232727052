import qs from 'qs';
import { isUndefined } from 'lodash';
import instance from './axiosConfig';
import { CreateVehicleRequestData } from '../vehicles/create-vehicle/types/createVehicleTypes';
import { GetVehiclesResponse, VehiclesDashboardFilters } from '../vehicles/dashboard/types/VehiclesDashdoardTypes';
import {
  SuitableVehicleProfilesFilters,
  VehicleProfileGroupsParams,
  CreateVehicleProfileRequest,
  PutVehicleProfileRestModel,
  VehicleProfilesFilters,
  GetVehicleProfilesResponse,
  VehicleProfileGroupDto,
  VehicleProfileDictionaryFilters,
  GetVehicleProfilesDictionaryResponse,
} from '../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import {
  AxiosCreatedAttachmentsResponse,
  AxiosCreatedIdResponse,
  AxiosCreateUploadUriResponse,
  AxiosDataListResponse,
  AxiosDownloadUriResponse, AxiosGenericResponse,
  AxiosGetAttachmentFileResponse,
  AxiosGetPagedAttachmentsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  EntityLinkType,
  LinksFiltersType,
  PostAttachReqType,
  UploadUriDto,
} from '../core/types/coreTypes';
import {
  GetVehicleResponse,
  PatchVehicleDto,
  SaveVehicleCheckDto, VehicleActivityEventDto,
} from '../vehicles/vehicle-view-page/types/VehicleViewTypes';
import {
  GetVehicleCheckResponse,
  VehicleCheckDto,
  VehicleChecksFilters,
} from '../vehicles/vehicle-view-page/components/vehicle-content/vehicle-inspection/types/VehicleInspectionTypes';
import { ExtendedTimelineFilters } from '../common/types/commonTypes';

export const vehiclesAPI = {
  createVehicle(data: CreateVehicleRequestData): AxiosSingleResponse<{ createdId: number, modelDescription: string }> {
    return instance.post('/vehicle/vehicles', { ...data });
  },
  fetchVehiclesList(
    filters: VehiclesDashboardFilters,
    basicSearchParams?: boolean,
    signal?: AbortSignal,
  ): AxiosGenericResponse<GetVehiclesResponse> {
    return instance.get('/vehicle/vehicles', {
      params: {
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeModelDescription': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeRegNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeChassisNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeVehicleProfileCode': filters.keyword ? !basicSearchParams : undefined,
        'Filter.SearchParameters.IncludeLocation': filters.keyword ? !basicSearchParams : undefined,
        'Filter.SearchParameters.IncludeLinkedEntityNumber': filters.keyword ? !basicSearchParams : undefined,
        'Filter.IsActive': filters.isActive,
        'Filter.OnlyAvailableForRectificationCreation': filters.onlyAvailableForRectificationCreation,
        'Filter.Manufacturers': filters.manufacturers,
        'Filter.CustomerIds': filters.customerIds,
        'Filter.Statuses': filters.statuses,
        'Filter.VehicleFlows': filters.vehicleFlows,
        'Filter.ManufacturerKeyword': filters.manufacturer,
        'Filter.AssigneeIds': filters.assigneeIds,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchVehiclesProfiles(filters: VehicleProfilesFilters, signal?: AbortSignal): AxiosGenericResponse<GetVehicleProfilesResponse> {
    const initIncludeParam = filters.keyword ? true : undefined;
    return instance.post('/vehicle/profiles/get', {
      pagingOptions: {
        page: filters.page,
        itemsPerPage: filters.pageSize,
      },
      orderOptions: filters.order ? {
        field: filters.order.field,
        ascending: filters.order.isAsc,
      } : undefined,
      filter: {
        searchParameters: filters.keyword ? {
          keyword: filters.keyword,
          includeCode: !isUndefined(filters.includeCode) ? filters.includeCode : initIncludeParam,
          includeManufacturer: !isUndefined(filters.includeManufacturer) ? filters.includeManufacturer : initIncludeParam,
          includeModelDescription: !isUndefined(filters.includeModelDescription) ? filters.includeModelDescription : initIncludeParam,
        } : undefined,
        groups: filters.group,
        modelDescription: filters.modelDescription,
        manufacturers: filters.manufacturer,
        types: filters.type,
        createdDateRange: {
          from: filters.createdDate?.min,
          to: filters.createdDate?.max,
        },
        modifiedDateRange: {
          from: filters.modifiedDate?.min,
          to: filters.modifiedDate?.max,
        },
        ids: filters.ids,
        exceptIds: filters.exceptIds,
        isSuitableForAll: filters.isSuitableForAll,
      },
    }, { signal });
  },
  createVehicleProfile(data: CreateVehicleProfileRequest): AxiosSingleResponse<{ createdId: number, code: string }> {
    return instance.post('/vehicle/profiles', { ...data });
  },
  editVehicleProfile(id: number, data: PutVehicleProfileRestModel): AxiosNullableResponse {
    return instance.put(`/vehicle/profiles/${id}`, { ...data });
  },
  deleteVehicleProfile(id: number): AxiosNullableResponse {
    return instance.delete(`/vehicle/profiles/${id}`);
  },
  fetchSuitableVehiclesProfiles(filters: SuitableVehicleProfilesFilters): AxiosGenericResponse<GetVehicleProfilesResponse> {
    return instance.post('/vehicle/profiles/get', {
      orderOptions: filters.order ? {
        field: filters.order.field,
        ascending: filters.order.isAsc,
      } : undefined,
      filter: {
        searchParameters: filters.keyword ? {
          keyword: filters.keyword,
          includeManufacturer: filters.keyword ? true : undefined,
          includeModelDescription: filters.keyword ? true : undefined,
        } : undefined,
      },
    });
  },
  fetchVehicleProfileManufacturers(keyword?: string): AxiosSingleResponse<{ manufacturers: string[] }> {
    return instance.get('/vehicle/profiles/manufacturers', {
      params: {
        Keyword: keyword,
      },
    });
  },
  fetchVehicleProfilesDictionary(filters?: VehicleProfileDictionaryFilters): AxiosGenericResponse<GetVehicleProfilesDictionaryResponse> {
    return instance.get('/vehicle/profiles/views/dictionary', {
      params: {
        'Filter.SearchParameters.Keyword': filters?.keyword,
        'Filter.SearchParameters.IncludeModelDescription': filters?.includeModelDescription,
        'Filter.SearchParameters.IncludeRegNumber': filters?.includeRegNumber,
        'Filter.SearchParameters.IncludeChassisNumber': filters?.includeChassisNumber,
        'Filter.SearchParameters.IncludeLocation': filters?.includeLocation,
        'Filter.SearchParameters.IncludeLinkedEntityNumber': filters?.includeLinkedEntityNumber,
        'Filter.Manufacturers': filters?.manufacturers,
        'Filter.ManufacturerKeyword': filters?.manufacturerKeyword,
        'Filter.CustomerIds': filters?.customerIds,
        'Filter.Statuses': filters?.statuses,
        'Filter.VehicleFlows': filters?.vehicleFlows,
        'Filter.AssigneeIds': filters?.assigneeIds,
        'Filter.IsActive': filters?.isActive,
        'Filter.OnlyAvailableForRectificationCreation': filters?.onlyAvailableForRectificationCreation,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  exportVehicleHandoverCheckToPdf(vehicleId: number, vehicleCheckId: number): AxiosDownloadUriResponse {
    return instance.get(`vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}/pdfFile`);
  },
  fetchVehicleAttachments(vehicleId: number, params: CoreAttachmentsFilters): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/vehicle/vehicles/${vehicleId}/attachments`, {
      params: {
        'Filter.Name': params.name,
        'Filter.MinUploadDate': params.uploadDate?.min,
        'Filter.MaxUploadDate': params.uploadDate?.max,
        'Filter.Comment': params.comment,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchVehicleAttachmentById(vehicleId: number, id: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/vehicle/vehicles/${vehicleId}/attachments/${id}`);
  },
  postVehicleAttachments(vehicleId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/vehicle/vehicles/${vehicleId}/attachments`, { objectId: vehicleId, attachments });
  },
  postVehicleAttachmentUploadUri(
    vehicleId: number,
    uploadUriDtos: UploadUriDto[],
  ): AxiosCreateUploadUriResponse {
    return instance.post(`/vehicle/vehicles/${vehicleId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editVehicleAttachment(vehicleId: number, id: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/vehicle/vehicles/${vehicleId}/attachments/${id}`, { comment });
  },
  deleteVehicleAttachment(vehicleId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/vehicle/vehicles/${vehicleId}/attachments/${id}`);
  },
  deleteMultipleVehicleAttachment(vehicleId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/vehicle/vehicles/${vehicleId}/attachments`, { data: { attachmentIds } });
  },
  fetchVehicleProfileGroups(params?: VehicleProfileGroupsParams): AxiosDataListResponse<VehicleProfileGroupDto[]> {
    return instance.get('/vehicle/profiles/groups', {
      params: {
        'Filter.AreVehiclesSuitableForAll': params?.areVehiclesSuitableForAll,
        'OrderOptions.Field': params?.order?.field,
        'OrderOptions.Ascending': params?.order?.isAsc,
        'PagingOptions.Page': params?.page,
        'PagingOptions.ItemsPerPage': params?.pageSize,
      },
    });
  },
  fetchVehicleById(vehicleId: number): AxiosSingleResponse<GetVehicleResponse> {
    return instance.get(`/vehicle/vehicles/${vehicleId}`);
  },
  patchVehicle(vehicleId: number, data: PatchVehicleDto): AxiosNullableResponse {
    return instance.patch(`/vehicle/vehicles/${vehicleId}`, { vehicle: { ...data } });
  },
  editVehicleStatus(vehicleId: number, statusTransition: number): AxiosNullableResponse {
    return instance.put(`/vehicle/vehicles/${vehicleId}/status`, { statusTransition });
  },
  fetchVehicleLinks(vehicleId: number, filters: LinksFiltersType): AxiosSingleResponse<{ links: EntityLinkType[] | null }> {
    return instance.get(`/vehicle/vehicles/${vehicleId}/links`, {
      params: {
        'Filter.EntityName': filters.entity,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'Filter.ModifiedDate.From': filters.modifiedDate?.min,
        'Filter.ModifiedDate.To': filters.modifiedDate?.max,
        'OrderOptions.Field': filters.order.field,
        'OrderOptions.Ascending': filters.order.isAsc,
      },
    });
  },
  fetchVehicleChecks(id: number, filters: VehicleChecksFilters): AxiosDataListResponse<VehicleCheckDto[]> {
    return instance.get(`/vehicle/vehicles/${id}/checks`, {
      params: {
        'Filter.VehicleCheckTypes': filters.vehicleCheckTypes,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchVehicleCheckById(vehicleId: number, vehicleCheckId: number): AxiosSingleResponse<GetVehicleCheckResponse> {
    return instance.get(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}`);
  },
  createVehicleCheck(vehicleId: number, vehicleCheckData: SaveVehicleCheckDto): AxiosCreatedIdResponse {
    return instance.post(`/vehicle/vehicles/${vehicleId}/checks`, {
      vehicleCheck: vehicleCheckData,
    });
  },
  vehicleCheckAttachmentUploadUri(
    vehicleId: number,
    vehicleCheckId: number,
    uploadUriDtos: UploadUriDto[],
  ): AxiosCreateUploadUriResponse {
    return instance.post(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}/attachments/uploadUri`, { uploadUriDtos });
  },
  vehicleCheckUploadAttachments(
    vehicleId: number,
    vehicleCheckId: number,
    attachments: PostAttachReqType[],
  ): AxiosCreatedAttachmentsResponse {
    return instance.post(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}/attachments`, { attachments });
  },
  fetchVehicleCheckAttachmentsList(
    vehicleId: number,
    vehicleCheckId: number,
    filters?: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}/attachments`, {
      params: {
        'Filter.Name': filters?.name,
        'Filter.MinUploadDate': filters?.uploadDate?.min,
        'Filter.MaxUploadDate': filters?.uploadDate?.max,
        'Filter.Comment': filters?.comment,
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
      },
    });
  },
  fetchVehicleCheckAttachmentById(vehicleId: number, vehicleCheckId: number, attachId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}/attachments/${attachId}`);
  },
  deleteVehicleCheck(vehicleId: number, vehicleCheckId: number): AxiosNullableResponse {
    return instance.delete(`/vehicle/vehicles/${vehicleId}/checks/${vehicleCheckId}`);
  },
  fetchVehicleActivityLog(vehicleId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<VehicleActivityEventDto[]> {
    return instance.get(`/vehicle/vehicles/${vehicleId}/timeline`, {
      params: {
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  createVehicleActivityNote(vehicleId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/vehicle/note', { vehicleId, content });
  },
  putVehicleActivityNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/vehicle/note/${id}`, { id, content });
  },
  deleteVehicleActivityNote(id: number): AxiosNullableResponse {
    return instance.delete(`/vehicle/note/${id}`);
  },
  deleteVehicle(vehicleId: number): AxiosNullableResponse {
    return instance.delete(`/vehicle/vehicles/${vehicleId}`);
  },
};
