import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { accountLeaveRequestSelector } from '../../../../store/selectors/accountSelectors';
import Button from '../../../../core/components/button/Button';
import Pagination from '../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { setAccountLeaveRequestsFilters } from '../../../../store/slices/accountSlice';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';
import AccountLeaveRequestItem from './components/leave-request-item/AccountLeaveRequestItem';
import AccountLeaveRequestModal from './components/leave-request-modal/AccountLeaveRequestModal';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  changeAccountLeaveRequestThunk, createAccountLeaveRequestThunk,
  deleteAccountLeaveRequestThunk,
} from '../../../../store/thunks/account/accountThunks';
import { useLoadAccountLeaveRequestsData } from './hooks/useLoadAccountLeaveRequestsData';

const AccountLeaveRequests: React.FC = () => {
  const dispatch = useAppDispatch();
  const leaveRequest = useAppSelector(accountLeaveRequestSelector);

  const [idToDelete, setIdToDelete] = useState<null | number>(null);
  const [addLeaveRequest, setAddLeaveRequest] = useState(false);

  const { items, totalCount, filters } = useLoadAccountLeaveRequestsData();

  return (
    <>
      <ConfirmationModal
        isVisible={!!idToDelete}
        onCancel={() => setIdToDelete(null)}
        confirmHandler={() => {
          idToDelete && dispatch(deleteAccountLeaveRequestThunk({
            id: idToDelete,
            closeModal: () => setIdToDelete(null),
          }));
        }}
      />
      <AccountLeaveRequestModal
        visible={addLeaveRequest || !!leaveRequest}
        type={leaveRequest ? 'edit' : 'create'}
        setCreateModal={setAddLeaveRequest}
        changeHandle={(id, data, closeModal) => {
          dispatch(changeAccountLeaveRequestThunk({
            id,
            data,
            closeModal,
          }));
        }}
        createHandle={(data, type, closeModal) => {
          dispatch(createAccountLeaveRequestThunk({
            data,
            type,
            closeModal,
          }));
        }}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Button
            label="Create new request"
            onClick={() => setAddLeaveRequest(true)}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            designType="dark"
            reversed
          />
        </div>
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => dispatch(setAccountLeaveRequestsFilters({
            ...filters,
            page,
            pageSize,
          }))}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      {items.length > 0
        ? <ul className="lines-list">
          {items.map((el) => (
            <li key={el.id} className="lines-list__item">
              <AccountLeaveRequestItem
                data={el}
                setIdToDelete={setIdToDelete}
              />
            </li>
          ))}
        </ul>
        : <EmptyContentList icon={faCalendar} text="No leave requests" />}
    </>
  );
};

export default AccountLeaveRequests;
