import React from 'react';
import classNames from 'classnames';
import Button from '../../../core/components/button/Button';
import { DictionaryItem, NumberFunctionType } from '../../../core/types/coreTypes';

type VehicleInfoSourceProps = {
  sources: DictionaryItem[],
  onClick?: NumberFunctionType,
  infoSource: number | undefined,
  disabled?: boolean,
  offsetBottom?: boolean,
  className?: string,
}
const VehicleInfoSource: React.FC<VehicleInfoSourceProps> = ({
  sources, onClick, infoSource, disabled, offsetBottom, className,
}) => {
  return (
    <div className={classNames('vehicleInfoSource', { 'vehicleInfoSource--offset-bottom': offsetBottom }, className)}>
      <span className="vehicleInfoSource__label">Vehicle info source</span>
      <div>
        {sources.map((el) => <Button
          key={el.value}
          label={el.label}
          onClick={() => onClick && onClick(el.value)}
          designType="light-inverse"
          isActivated={infoSource === el.value}
          disabled={disabled}
          offsetRight
        />)}
      </div>
    </div>
  );
};

export default VehicleInfoSource;
