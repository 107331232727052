import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, BooleanFunctionType,
  CoreAttachListElType,
  CoreAttachmentDto,
  CoreAttachmentsFilters, emptyPaging, GetAttachmentsResponse,
  initialCoreAttachmentsFilters, PatchAttachmentModel,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { rectificationsAPI } from '../../../api/rectificationsApi';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { fetchRectificationByIdThunk } from './rectificationViewPageThunks';
import { setRectificationAttachmentsFilters, setRectificationAttachments } from '../../slices/rectificationsSlice';
import { RootState } from '../../store';

export const getRectificationAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{
  rectificationId: number,
  params: CoreAttachmentsFilters,
}
>(
  'get/RectificationAttachments',
  async ({ rectificationId, params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationAttachments(rectificationId, params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationAttachmentByIdThunk = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string} | null>,
{ rectificationId: number, id: number }
>(
  'get/RectificationAttachmentById',
  async ({ rectificationId, id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationAttachmentById(rectificationId, id);
      downloadCsv(response.data.data.uri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createRectificationAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  rectificationId: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}
>('post/RectificationAttachment', async ({
  rectificationId, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { rectifications: { rectificationAttachmentsFilters } } = getState() as RootState;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await rectificationsAPI.postRectificationAttachmentUploadUri(rectificationId, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await rectificationsAPI.postRectificationAttachments(rectificationId, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(fetchRectificationByIdThunk({ rectificationId }));
    dispatch(getRectificationAttachmentsThunk({ rectificationId, params: rectificationAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultipleRectificationAttachmentThunk = createAsyncThunk<null,
{
  rectificationId: number,
  ids: number[],
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleRectificationAttachments',
  async ({
    rectificationId, ids, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications: { rectificationAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await rectificationsAPI.fetchRectificationAttachments(rectificationId, {
        ...rectificationAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await rectificationsAPI.deleteMultipleRectificationAttachment(rectificationId, filteredIds);
      }
      dispatch(setRectificationAttachmentsFilters({ ...rectificationAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(fetchRectificationByIdThunk({ rectificationId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteRectificationAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ rectificationId: number, id: number, closeModal: VoidFunctionType, }>(
  'delete/SingleRectificationAttachment',
  async ({
    rectificationId, id, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.deleteRectificationAttachment(rectificationId, id);
      dispatch(getRectificationAttachmentsThunk({
        rectificationId,
        params: initialCoreAttachmentsFilters,
      }));
      dispatch(setRectificationAttachmentsFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(fetchRectificationByIdThunk({ rectificationId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editRectificationAttachmentThunk = createAsyncThunk<
ResponseSingleResult,
{ rectificationId: number, id: number, data: PatchAttachmentModel, onCancel?: VoidFunctionType, setSwitchLoading?: BooleanFunctionType }
>('patch/RectificationAttachment', async ({
  rectificationId, id, data, onCancel, setSwitchLoading,
}, { getState, dispatch, rejectWithValue }) => {
  const changeLoadingState = (value: boolean) => {
    setSwitchLoading ? setSwitchLoading(value) : dispatch(setLoading(value));
  };
  changeLoadingState(true);
  try {
    const { rectifications: { rectificationAttachments } } = getState() as RootState;
    const response = await rectificationsAPI.editRectificationAttachment(rectificationId, id, data);
    const newArr = rectificationAttachments.items.map((item: CoreAttachmentDto) => {
      if (item.id === id) {
        return {
          ...item,
          comment: data.comment || item.comment,
          showInLinkedJobs: typeof data.showInLinkedJobs !== undefined ? data.showInLinkedJobs as boolean : item.showInLinkedJobs,
        };
      } else return item;
    });
    dispatch(setRectificationAttachments(newArr));
    if (onCancel) {
      onCancel();
    }
    changeLoadingState(false);
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    changeLoadingState(false);
    return rejectWithValue(error.response?.data);
  }
});
