import React, { useRef, useState } from 'react';
import classNames from 'classnames';
import moment, { Moment } from 'moment/moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Button from '../button/Button';
import RangePickerComponent from '../range-picker/RangePickerComponent';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import { DateRangeTableFilterType } from '../../types/coreTypes';
import { DEFAULT_TIMEZONE_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT } from '../../utils/regex';

type SelectableDatepickerProps = {
  label?: string,
  value?: { min?: string, max?: string },
  className?: string,
  disabled?: boolean
  fieldName: string,
  pickerValue?: DateRangeTableFilterType,
  setPickerValue?: (value: DateRangeTableFilterType) => void,
  pickerHandle?: (field: string, values?: {min: string | null, max: string | null}) => void,
  disabledDate?: (current: Moment) => boolean,
}

const SelectableDatepicker: React.FC<SelectableDatepickerProps> = ({
  label, value, fieldName, className, disabled, pickerValue, setPickerValue, pickerHandle, disabledDate,
}) => {
  const [isVisible, setIsVisible] = useState(false);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsVisible(false));

  const getLabel = () => {
    const min = pickerValue?.[fieldName]?.length ? pickerValue?.[fieldName][0] : undefined;
    const max = pickerValue?.[fieldName]?.length ? pickerValue?.[fieldName][1] : undefined;
    if (!min && !max) return label;
    let text = '';
    if (min) {
      text = min.format(DEFAULT_UI_DATE_FORMAT);
    }
    if (min && max) {
      text += ' - ';
    }
    if (max) {
      text += max.format(DEFAULT_UI_DATE_FORMAT);
    }
    return text;
  };

  return (
    <div className={classNames(className, 'selectableDropdown', {
      'selectableDropdown--selected': value,
    })}
    >
      <Button
        label={getLabel()}
        icon={<FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: 12 }} />}
        className={classNames('dropdownButton', {
          'dropdownButton--selected': value,
        })}
        onClick={() => setIsVisible(true)}
        disabled={disabled}
        disableDefaultStyles
      />
      {isVisible && <div
        ref={ref}
        className="dropdownList dropdownList--range-picker"
      >
        <RangePickerComponent
          allowEmpty={[true, true]}
          value={pickerValue ? pickerValue[fieldName] : undefined}
          onChange={(v) => {
            if (v) {
              setPickerValue && setPickerValue({ ...pickerValue, [fieldName]: v });
              const formattedValues = [
                v[0] ? moment(v[0]).startOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT) : v[0],
                v[1] ? moment(v[1]).endOf('date').format(DEFAULT_TIMEZONE_DATE_FORMAT) : v[1],
              ];
              const values = {
                min: formattedValues[0],
                max: formattedValues[1],
              };
              pickerHandle && pickerHandle(fieldName, values);
            } else {
              setPickerValue && setPickerValue({ ...pickerValue, [fieldName]: [null, null] });
              pickerHandle && pickerHandle(fieldName, undefined);
            }
          }}
          disabledDate={disabledDate}
          format={DEFAULT_UI_DATE_FORMAT}
          parentRender
        />
      </div>}
    </div>
  );
};

export default SelectableDatepicker;
