import React, { useEffect, useState } from 'react';
import CreateCategoryModal from './components/modals/CreateCategoryModal';
import './SkillListPage.scss';
import AddSkillItemModal from './components/modals/AddSkillItemModal';
import SkillsCategories from './components/SkillsCategories';
import {
  deleteSkillCategoryThunk,
  deleteSkillsThunk,
  deleteSkillThunk,
  getCategoryAffectedEmployees,
  getSkillAffectedEmployees,
  getSkillCategoryByIdThunk,
  getSkillsCategoriesThunk,
} from '../../store/thunks/employee/skillsThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  affectedEmployeeFiltersSelector,
  skillsFiltersSelector,
  skillsListByCategorySelector,
} from '../../store/selectors/employeesSelector';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import AffectedEmployeeModal from './components/modals/AffectedEmployeeModal';
import {
  setAffectedCategoriesEmployees,
  setAffectedEmployeeFilters,
  setAffectedSkillsEmployees, setSkillsFilters,
} from '../../store/slices/employeesSlice';
import { initDataList } from '../../core/types/coreTypes';
import { AffectedEmployeeOrderingEnum, SkillsOrderingEnum } from './enums/skillsEnum';
import { getAffectedEmployeeOrdering, getSkillsOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { affectedEmployeeOrderingSelector, skillsOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { useSelectAll } from '../../core/hooks/useSelectAll';

const SkillListPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const [createCategory, setCreateCategory] = useState(false);
  const [editCategoryMode, setEditCategoryMode] = useState(false);
  const [categoryToChange, setCategoryToChange] = useState<number | undefined>(undefined);
  const [addSkill, setAddSkill] = useState(false);
  const [editSkillMode, setEditSkillMode] = useState(false);
  const [skillToChange, setSkillToChange] = useState<number | undefined>(undefined);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [isAffected, setAffected] = useState<boolean>(false);
  const affectedEmployeeFilters = useAppSelector(affectedEmployeeFiltersSelector);
  const skillsOrdering = useAppSelector(skillsOrderingSelector);
  const affectedEmployeeOrdering = useAppSelector(affectedEmployeeOrderingSelector);
  const { allItemIds } = useAppSelector(skillsListByCategorySelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const skillsFilters = useAppSelector(skillsFiltersSelector);

  useEffect(() => {
    dispatch(getSkillsCategoriesThunk());
    dispatch(getSkillsOrderingThunk());
    dispatch(getAffectedEmployeeOrdering());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!skillsFilters.order && skillsOrdering.length > 0) {
      const createOrderIndex = skillsOrdering.find((el) => el.label === SkillsOrderingEnum.CreatedDate)?.value;
      if (createOrderIndex) {
        dispatch(setSkillsFilters({ ...skillsFilters, order: { field: createOrderIndex, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [skillsFilters.order, skillsOrdering.length]);

  useEffect(() => {
    if (!affectedEmployeeFilters.order && affectedEmployeeOrdering.length > 0) {
      const createOrderIndex = affectedEmployeeOrdering.find((el) => el.label === AffectedEmployeeOrderingEnum.Email)?.value;
      if (createOrderIndex) {
        dispatch(setAffectedEmployeeFilters({ ...affectedEmployeeFilters, order: { field: createOrderIndex, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [affectedEmployeeOrdering.length, affectedEmployeeFilters.order]);

  useEffect(() => {
    if (skillsFilters.categoryId && skillsFilters.order) {
      dispatch(getSkillCategoryByIdThunk({ filters: skillsFilters }));
    }
    // eslint-disable-next-line
  }, [skillsFilters]);

  const openDeleteModalHandle = (categoryId?: number, skillId?: number) => {
    categoryId && setCategoryToChange(categoryId);
    skillId && setSkillToChange(skillId);
    if (categoryId) {
      dispatch(getCategoryAffectedEmployees({
        categoryId,
        filters: { ...affectedEmployeeFilters },
        confirmAction: () => setDelete(true),
        complexAction: () => setAffected(true),
      }));
    }
    if (skillId) {
      dispatch(getSkillAffectedEmployees({
        skillId: [skillId],
        filters: { ...affectedEmployeeFilters },
        confirmAction: () => setDelete(true),
        complexAction: () => setAffected(true),
      }));
    }
    if (checkedKeys.length > 0 && !skillId) {
      dispatch(getSkillAffectedEmployees({
        skillId: checkedKeys,
        filters: { ...affectedEmployeeFilters },
        confirmAction: () => setDelete(true),
        complexAction: () => setAffected(true),
      }));
    }
  };

  const confirmDelete = () => {
    const cleanUpAffected = () => {
      dispatch(setAffectedEmployeeFilters({ ...affectedEmployeeFilters, page: 1, pageSize: 5 }));
      dispatch(setAffectedSkillsEmployees(initDataList));
      dispatch(setAffectedCategoriesEmployees(initDataList));
      setSkillToChange(undefined);
      setCategoryToChange(undefined);
      setAffected(false);
    };
    if (skillToChange) {
      dispatch(deleteSkillThunk({
        skillId: skillToChange,
        closeModal: () => {
          setDelete(false);
          setSkillToChange(undefined);
          isAffected && cleanUpAffected();
        },
      }));
    }
    if (categoryToChange) {
      dispatch(deleteSkillCategoryThunk({
        id: categoryToChange,
        closeModal: () => {
          setDelete(false);
          setCategoryToChange(undefined);
          isAffected && cleanUpAffected();
        },
      }));
    }
    if (checkedKeys.length > 0 && !skillToChange) {
      dispatch(deleteSkillsThunk({
        ids: checkedKeys,
        closeModal: () => {
          setDelete(false);
          isAffected && cleanUpAffected();
        },
      }));
    }
  };
  return (
    <>
      <CreateCategoryModal
        isVisible={createCategory}
        onCancel={() => {
          editCategoryMode && setEditCategoryMode(false);
          categoryToChange && setCategoryToChange(undefined);
          setCreateCategory(false);
        }}
        categoryToChange={categoryToChange}
        editMode={editCategoryMode}
      />
      <AddSkillItemModal
        isVisible={addSkill}
        onCancel={() => {
          editSkillMode && setEditSkillMode(false);
          skillToChange && setSkillToChange(undefined);
          categoryToChange && setCategoryToChange(undefined);
          setAddSkill(false);
        }}
        editMode={editSkillMode}
        skillToChange={skillToChange}
      />
      <ConfirmationModal
        isVisible={isDelete}
        customTitle={(skillToChange || checkedKeys.length > 0)
          ? 'Delete skill item'
          : 'Delete category'}
        onCancel={() => {
          setDelete(false);
          skillToChange && setSkillToChange(undefined);
          categoryToChange && setCategoryToChange(undefined);
        }}
        confirmHandler={confirmDelete}
      />
      <AffectedEmployeeModal
        isVisible={isAffected}
        onCancel={() => {
          setSkillToChange(undefined);
          setCategoryToChange(undefined);
          setAffected(false);
        }}
        categoryToChange={categoryToChange}
        skillToChange={skillToChange}
        checkedKeys={checkedKeys}
        confirmDelete={confirmDelete}
      />
      <SkillsCategories
        setCreateCategory={setCreateCategory}
        setCategoryToChange={setCategoryToChange}
        checkedKeys={checkedKeys}
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAllHandle}
        checkRows={checkRowHandle}
        setEditCategoryMode={setEditCategoryMode}
        setAddSkill={setAddSkill}
        setEditSkillMode={setEditSkillMode}
        setSkillToChange={setSkillToChange}
        openDeleteModalHandle={openDeleteModalHandle}
      />
    </>
  );
};

export default SkillListPage;
