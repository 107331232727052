import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DataListType, DictionaryItem,
  initDataList,
  initPaging,
  initSmallPaging,
  PagingWithOrdering,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  GetNominalCodesResponse,
  NominalCodeDto,
  NominalCodesFilters,
  NominalCodeTypeCountDto,
} from '../../invoicing/nominal-codes/types/nominalCodesTypes';
import { getNominalCodesThunk } from '../thunks/invoicing/invoicingNominalCodesThunks';
import {
  GetPurchaseOrderResponse,
  InitPurchaseOrderDashFilters,
  PurchaseOrderDashFiltersType,
  PurchaseOrderDataListType,
  PurchaseOrdersInitDataList,
  PurchaseOrdersResponseResult,
} from '../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import {
  GetInvoiceDocumentsResponse, GetInvoiceDocumentsSummaryResponse,
  initInvoiceDocumentsData, initInvoiceDocumentsSummary, InvoiceDocumentDto,
  InvoiceDocumentsData,
  InvoiceDocumentsFilters, InvoiceDocumentsSummary, InvoicesAndCreditNotesFilters, InvoicingPurchaseOrderDetails,
  PurchasesViewOrderLinesFilters,
} from '../../invoicing/invoicing-purchases/types/InvoicingPurchasesTypes';
import {
  getInvoiceDocumentsSummaryThunk,
  getInvoiceNominalCodesThunk, getInvoicesAndCreditNotesThunk,
  getInvoicingPurchaseOrderById, getInvoicingPurchaseOrderLinesList,
  getInvoicingPurchaseOrderLinesSummaryThunk, getInvoicingPurchaseOrderTimeline,
  getInvoicingPurchases, getNominalCodesSummary, getNominalRecordPartSummary, getAssignedNominalRecords,
} from '../thunks/invoicing/invoicingPurchasesThunks';
import {
  GetPurchaseOrderPartsResponse, GetPurchaseOrderPartSummaryResponse, PurchaseOrderEventDtoBase,
  PurchaseOrderSummaryResponseType,
} from '../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import {
  NominalCodesData,
  GetNominalRecordsResponse, initNominalCodesData,
  NominalRecordsFilters, GetNominalRecordsSummaryResponse,
} from '../../invoicing/invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import {
  ExtendedTimelineFilters,
  initExtendedTimelineFilters,
  PartRequestLineDto,
} from '../../common/types/commonTypes';
import {
  InitSalesDashboardFilters, initSalesDataList,
  SalesDataListType, SalesInvoiceOrderLinesFilters, SalesOrderDetails,
} from '../../invoicing/sales/types/salesTypes';
import {
  getSalesOrderById, getSalesOrderPartRequestLinesThunk, getSalesOrdersList,
  getSalesOrderSummaryThunk, getSalesOrderTimelineThunk,
  getSalesPartRequestLineSummary,
} from '../thunks/invoicing/salesThunks';
import { GetPartRequestLineStateSummaryResponse } from '../../stock/stock-part-request-view/types/StockPartrequestViewTypes';
import {
  GetOrderListResponse,
  GetOrderResponse,
  OrderViewsFilters,
} from '../../orders/dashboard/types/ordersTypes';
import { OrderEventDtoBase, OrderSummaryType } from '../../orders/orders-view-page/types/OrderViewPageTypes';
import { getInvoiceDocumentsThunk } from '../thunks/invoicing/commonInvoicesThunks';

interface IInvoicingSlice {
  nominalCodesFilters: NominalCodesFilters,
  nominalCodes: DataListType<NominalCodeDto[]>,
  nominalCodesTypeCounts: NominalCodeTypeCountDto[] | null,
  purchasesFilters: PurchaseOrderDashFiltersType,
  purchasesList: PurchaseOrderDataListType,
  salesDashboardFilters: OrderViewsFilters,
  salesDashboard: SalesDataListType,
  salesOrderDetails: SalesOrderDetails | null,
  salesOrderDetailsSummary: OrderSummaryType | null,
  salesInvoiceOrderLinesFilters?: SalesInvoiceOrderLinesFilters,
  salesOrderPartRequestLines: { items: PartRequestLineDto[], nominalCodes: DictionaryItem[] } | null,
  salesOrderTimeline: DataListType<OrderEventDtoBase[]>,
  salesOrderTimelineFilters: ExtendedTimelineFilters,
  invoicingPurchaseOrder: InvoicingPurchaseOrderDetails | null,
  purchasesViewOrderLinesFilters?: PurchasesViewOrderLinesFilters,
  purchasesViewOrderLines: GetPurchaseOrderPartsResponse | null,
  purchaseViewOrderLinesSummary: PurchaseOrderSummaryResponseType | null,
  assignedNominalCodesFilters: NominalRecordsFilters,
  assignedNominalCodes: NominalCodesData,
  assignedNominalCodesSummary?: GetPurchaseOrderPartSummaryResponse,
  purchasingInvoiceTimeline: DataListType<PurchaseOrderEventDtoBase[]>,
  purchasingInvoiceTimelineFilters: ExtendedTimelineFilters,
  invoiceDocumentsFilters: InvoiceDocumentsFilters,
  invoiceDocuments: InvoiceDocumentsData,
  invoiceDocumentsSummary: InvoiceDocumentsSummary,
  invoicesAndCreditNotesFilters: InvoicesAndCreditNotesFilters,
  invoicesAndCreditNotes: DataListType<InvoiceDocumentDto[]>,
  invoiceNominalCodesFilters: NominalRecordsFilters,
  invoiceNominalCodes: NominalCodesData,
  invoiceNominalCodesSummary: GetNominalRecordsSummaryResponse | null,
  salesPartRequestLineSummary: GetPartRequestLineStateSummaryResponse | null,
}

export const initialInvoicingState: IInvoicingSlice = {
  nominalCodesFilters: initPaging,
  nominalCodes: initDataList,
  nominalCodesTypeCounts: null,
  purchasesFilters: InitPurchaseOrderDashFilters,
  purchasesList: PurchaseOrdersInitDataList,
  salesDashboardFilters: InitSalesDashboardFilters,
  salesDashboard: initSalesDataList,
  salesPartRequestLineSummary: null,
  salesOrderDetails: null,
  salesOrderDetailsSummary: null,
  salesInvoiceOrderLinesFilters: undefined,
  salesOrderPartRequestLines: null,
  salesOrderTimeline: initDataList,
  salesOrderTimelineFilters: { ...initExtendedTimelineFilters, mainOnly: false },
  invoicingPurchaseOrder: null,
  purchasesViewOrderLinesFilters: undefined,
  purchasesViewOrderLines: null,
  purchaseViewOrderLinesSummary: null,
  assignedNominalCodesFilters: initSmallPaging,
  assignedNominalCodes: initNominalCodesData,
  assignedNominalCodesSummary: undefined,
  purchasingInvoiceTimeline: initDataList,
  purchasingInvoiceTimelineFilters: { ...initExtendedTimelineFilters, mainOnly: false },
  invoiceDocumentsFilters: initSmallPaging,
  invoiceDocuments: initInvoiceDocumentsData,
  invoiceDocumentsSummary: initInvoiceDocumentsSummary,
  invoicesAndCreditNotesFilters: initPaging,
  invoicesAndCreditNotes: initDataList,
  invoiceNominalCodesFilters: initSmallPaging,
  invoiceNominalCodes: initNominalCodesData,
  invoiceNominalCodesSummary: null,
};

export const invoicingSlice = createSlice({
  name: 'invoicing',
  initialState: initialInvoicingState,
  reducers: {
    setNominalCodesFilters: (state, action: PayloadAction<NominalCodesFilters>) => {
      state.nominalCodesFilters = action.payload;
    },
    setNominalCodes: (state, action: PayloadAction<DataListType<NominalCodeDto[]>>) => {
      state.nominalCodes = action.payload;
    },
    setPurchasesFilters: (state, action: PayloadAction<PurchaseOrderDashFiltersType>) => {
      state.purchasesFilters = action.payload;
    },
    setPurchasesList: (state, action: PayloadAction<PurchaseOrderDataListType>) => {
      state.purchasesList = action.payload;
    },
    setSalesDashboardFilters: (state, action: PayloadAction<OrderViewsFilters>) => {
      state.salesDashboardFilters = action.payload;
    },
    setSalesDashboard: (state, action: PayloadAction<SalesDataListType>) => {
      state.salesDashboard = action.payload;
    },
    setSalesOrderDetails: (state, action: PayloadAction<SalesOrderDetails | null>) => {
      state.salesOrderDetails = action.payload;
    },
    setSalesOrderDetailsSummary: (state, action: PayloadAction<OrderSummaryType | null>) => {
      state.salesOrderDetailsSummary = action.payload;
    },
    setSalesPartRequestLineSummary: (state, action: PayloadAction<GetPartRequestLineStateSummaryResponse | null>) => {
      state.salesPartRequestLineSummary = action.payload;
    },
    setSalesInvoiceOrderLinesFilters: (state, action: PayloadAction<SalesInvoiceOrderLinesFilters | undefined>) => {
      state.salesInvoiceOrderLinesFilters = action.payload;
    },
    setSalesOrderPartRequestLines: (state, action: PayloadAction<{
      items: PartRequestLineDto[],
      nominalCodes: DictionaryItem[] } | null
    >) => {
      state.salesOrderPartRequestLines = action.payload;
    },
    setSalesOrderTimeline: (state, action: PayloadAction<DataListType<OrderEventDtoBase[]>>) => {
      state.salesOrderTimeline = action.payload;
    },
    setSalesOrderTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.salesOrderTimelineFilters = action.payload;
    },
    setPurchasesViewOrderLinesFilters: (state, action: PayloadAction<PurchasesViewOrderLinesFilters | undefined>) => {
      state.purchasesViewOrderLinesFilters = action.payload;
    },
    setPurchasesViewOrderLines: (state, action: PayloadAction<GetPurchaseOrderPartsResponse | null>) => {
      state.purchasesViewOrderLines = action.payload;
    },
    setAssignedNominalCodes: (state, action: PayloadAction<NominalCodesData>) => {
      state.assignedNominalCodes = action.payload;
    },
    setAssignedNominalCodesFilters: (state, action: PayloadAction<NominalRecordsFilters>) => {
      state.assignedNominalCodesFilters = action.payload;
    },
    setAssignedNominalCodesSummary: (state, action: PayloadAction<GetPurchaseOrderPartSummaryResponse | undefined>) => {
      state.assignedNominalCodesSummary = action.payload;
    },
    setInvoicingPurchaseOrder: (state, action: PayloadAction<InvoicingPurchaseOrderDetails | null>) => {
      state.invoicingPurchaseOrder = action.payload;
    },
    setPurchaseViewOrderLinesSummary: (state, action: PayloadAction<PurchaseOrderSummaryResponseType | null>) => {
      state.purchaseViewOrderLinesSummary = action.payload;
    },
    setPurchasingInvoiceTimeline: (state, action: PayloadAction<DataListType<PurchaseOrderEventDtoBase[]>>) => {
      state.purchasingInvoiceTimeline = action.payload;
    },
    setPurchasingInvoiceTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.purchasingInvoiceTimelineFilters = action.payload;
    },
    setInvoiceDocumentsFilters: (state, action: PayloadAction<InvoiceDocumentsFilters>) => {
      state.invoiceDocumentsFilters = action.payload;
    },
    setInvoiceDocuments: (state, action: PayloadAction<InvoiceDocumentsData>) => {
      state.invoiceDocuments = action.payload;
    },
    setInvoiceDocumentsSummary: (state, action: PayloadAction<InvoiceDocumentsSummary>) => {
      state.invoiceDocumentsSummary = action.payload;
    },
    setInvoicesAndCreditNotesFilters: (state, action: PayloadAction<PagingWithOrdering>) => {
      state.invoicesAndCreditNotesFilters = action.payload;
    },
    setInvoicesAndCreditNotes: (state, action: PayloadAction<DataListType<InvoiceDocumentDto[]>>) => {
      state.invoicesAndCreditNotes = action.payload;
    },
    setInvoiceNominalCodesFilters: (state, action: PayloadAction<NominalRecordsFilters>) => {
      state.invoiceNominalCodesFilters = action.payload;
    },
    setInvoiceNominalCodes: (state, action: PayloadAction<NominalCodesData>) => {
      state.invoiceNominalCodes = action.payload;
    },
    setInvoiceNominalCodesSummary: (state, action: PayloadAction<GetNominalRecordsSummaryResponse | null>) => {
      state.invoiceNominalCodesSummary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getNominalCodesThunk.fulfilled,
      (state, action: PayloadAction<GetNominalCodesResponse>) => {
        const { typeCounts, ...rest } = action.payload.data;
        state.nominalCodes = rest;
        state.nominalCodesTypeCounts = typeCounts;
      },
    );
    builder.addCase(
      getInvoicingPurchases.fulfilled,
      (state, action: PayloadAction<PurchaseOrdersResponseResult>) => {
        state.purchasesList = action.payload.data;
      },
    );
    builder.addCase(
      getInvoicingPurchaseOrderById.fulfilled,
      (state, action: PayloadAction<GetPurchaseOrderResponse>) => {
        const {
          purchaseOrder, maxInvoiceEntitySequenceNumber, maxCreditNoteEntitySequenceNumber, canBeMarkedAsInvoiced,
        } = action.payload.data;
        state.invoicingPurchaseOrder = {
          purchaseOrder, maxInvoiceEntitySequenceNumber, maxCreditNoteEntitySequenceNumber, canBeMarkedAsInvoiced,
        };
      },
    );
    builder.addCase(
      getInvoicingPurchaseOrderLinesSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<PurchaseOrderSummaryResponseType>>) => {
        state.purchaseViewOrderLinesSummary = action.payload.data;
      },
    );
    builder.addCase(
      getInvoicingPurchaseOrderLinesList.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPurchaseOrderPartsResponse>>) => {
        state.purchasesViewOrderLines = action.payload.data;
      },
    );
    builder.addCase(
      getAssignedNominalRecords.fulfilled,
      (state, action: PayloadAction<GetNominalRecordsResponse>) => {
        state.assignedNominalCodes = action.payload.data;
      },
    );
    builder.addCase(
      getNominalRecordPartSummary.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPurchaseOrderPartSummaryResponse>>) => {
        state.assignedNominalCodesSummary = action.payload.data;
      },
    );
    builder.addCase(
      getInvoicingPurchaseOrderTimeline.fulfilled,
      (state, action: PayloadAction<ResponseResult<PurchaseOrderEventDtoBase[]>>) => {
        state.purchasingInvoiceTimeline = action.payload.data;
      },
    );
    builder.addCase(
      getInvoiceDocumentsThunk.fulfilled,
      (state, action: PayloadAction<GetInvoiceDocumentsResponse>) => {
        state.invoiceDocuments = action.payload.data;
      },
    );
    builder.addCase(
      getInvoiceDocumentsSummaryThunk.fulfilled,
      (state, action: PayloadAction<GetInvoiceDocumentsSummaryResponse>) => {
        state.invoiceDocumentsSummary = action.payload.data;
      },
    );
    builder.addCase(
      getInvoicesAndCreditNotesThunk.fulfilled,
      (state, action: PayloadAction<GetInvoiceDocumentsResponse>) => {
        const { items, pages, totalCount } = action.payload.data;
        state.invoicesAndCreditNotes = { items, pages, totalCount };
      },
    );
    builder.addCase(
      getInvoiceNominalCodesThunk.fulfilled,
      (state, action: PayloadAction<GetNominalRecordsResponse>) => {
        state.invoiceNominalCodes = action.payload.data;
      },
    );
    builder.addCase(
      getNominalCodesSummary.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetNominalRecordsSummaryResponse>>) => {
        state.invoiceNominalCodesSummary = action.payload.data;
      },
    );
    builder.addCase(
      getSalesOrderById.fulfilled,
      (state, action: PayloadAction<GetOrderResponse>) => {
        const {
          order, maxCreditNoteEntitySequenceNumber, maxInvoiceEntitySequenceNumber, canBeMarkedAsInvoiced, invoiceDocumentsCount,
        } = action.payload.data;
        state.salesOrderDetails = {
          order, maxCreditNoteEntitySequenceNumber, maxInvoiceEntitySequenceNumber, canBeMarkedAsInvoiced, invoiceDocumentsCount,
        };
      },
    );
    builder.addCase(
      getSalesPartRequestLineSummary.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPartRequestLineStateSummaryResponse>>) => {
        state.salesPartRequestLineSummary = action.payload.data;
      },
    );
    builder.addCase(
      getSalesOrderSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<OrderSummaryType>>) => {
        state.salesOrderDetailsSummary = action.payload.data;
      },
    );
    builder.addCase(
      getSalesOrderPartRequestLinesThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartRequestLineDto[], {
        allItemIds: number[],
        nominalCodes: DictionaryItem[],
      }>>) => {
        state.salesOrderPartRequestLines = { items: action.payload.data.items, nominalCodes: action.payload.data.nominalCodes };
      },
    );
    builder.addCase(
      getSalesOrderTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<OrderEventDtoBase[]>>) => {
        state.salesOrderTimeline = action.payload.data;
      },
    );
    builder.addCase(
      getSalesOrdersList.fulfilled,
      (state, action: PayloadAction<GetOrderListResponse>) => {
        state.salesDashboard = action.payload.data;
      },
    );
  },
});

export const {
  setNominalCodesFilters,
  setNominalCodes,
  setPurchasesFilters,
  setPurchasesViewOrderLinesFilters,
  setPurchasesViewOrderLines,
  setAssignedNominalCodesFilters,
  setAssignedNominalCodes,
  setAssignedNominalCodesSummary,
  setPurchasesList,
  setInvoicingPurchaseOrder,
  setPurchaseViewOrderLinesSummary,
  setPurchasingInvoiceTimeline,
  setPurchasingInvoiceTimelineFilters,
  setInvoiceDocumentsFilters,
  setInvoiceDocuments,
  setInvoiceDocumentsSummary,
  setInvoicesAndCreditNotesFilters,
  setInvoiceNominalCodesFilters,
  setInvoicesAndCreditNotes,
  setInvoiceNominalCodes,
  setInvoiceNominalCodesSummary,
  setSalesDashboardFilters,
  setSalesDashboard,
  setSalesInvoiceOrderLinesFilters,
  setSalesOrderPartRequestLines,
  setSalesPartRequestLineSummary,
  setSalesOrderDetails,
  setSalesOrderDetailsSummary,
  setSalesOrderTimeline,
  setSalesOrderTimelineFilters,
} = invoicingSlice.actions;

export default invoicingSlice.reducer;
