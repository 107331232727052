import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, soldProductsByCustomerDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import ViewPageLink from '../../../../../../common/components/view-page-link/ViewPageLink';
import { EntityType } from '../../../../../../common/types/commonTypes';

const SupplierSpendBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { totalCount, items } = useAppSelector(soldProductsByCustomerDataSelector);

  const hiddenKeys = ['customerId', 'partId'];
  const nowrapKeys = ['quantity', 'costNet', 'salesNet'];

  const renderLink = (entity: EntityType, value: string | number, id: number) => (
    <ViewPageLink entityType={entity} entityId={id}>
      <TruncateTooltip value={value} table />
    </ViewPageLink>
  );

  const renderCellValue = (key: string, value: string | number, customerId: number, partId: number) => {
    if (key === 'customerName') return renderLink('Customer', value, customerId);
    if (key === 'productNumber') return renderLink('Part', value, partId);
    if (key === 'productName') return <TruncateTooltip value={value} table />;
    return value;
  };

  return (
    <Tbody>
      {totalCount > 0
        ? items.map((el, i) => (
          <Tr key={i}>
            {Object.entries(el).map(([key, value]) => {
              if (hiddenKeys.includes(key)) return null;
              return <TdNew key={key} nowrap={nowrapKeys.includes(key)}>
                {renderCellValue(key, value, el.customerId, el.partId)}
              </TdNew>;
            })}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default SupplierSpendBody;
