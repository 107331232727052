import React from 'react';
import VehicleEditEvent from './VehicleEditEvent';
import VehicleAddEvent from './VehicleAddEvent';
import { EnquiryEventDtoBase } from '../../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';

enum VehicleEventsEnum {
  AddingVehicle = 'AddingVehicle',
  EditingVehicleDetails = 'EditingVehicleDetails',
  DeletingVehicle = 'DeletingVehicle',
}

type VehicleEventsProps = {
  event: string,
  item: EnquiryEventDtoBase,
  vehicleName?: string,
}

const VehicleEvents: React.FC<VehicleEventsProps> = ({ event, item, vehicleName = '' }) => {
  const renderVehicleEventFn = () => {
    switch (event) {
      case VehicleEventsEnum.AddingVehicle:
        return <VehicleAddEvent vehicleName={vehicleName} item={item} />;
      case VehicleEventsEnum.EditingVehicleDetails:
        return <VehicleEditEvent
          field={item.field}
          vehicleName={vehicleName}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case VehicleEventsEnum.DeletingVehicle:
        return (
          <>
            Vehicle
            {' '}
            {!!vehicleName && <b>{vehicleName}</b>}
            {' '}
            was deleted
          </>
        );
      default: return null;
    }
  };

  return (
    <>{renderVehicleEventFn()}</>
  );
};

export default VehicleEvents;
