import { createAsyncThunk } from '@reduxjs/toolkit';
import { coreAPI } from '../../api/coreAPI';
import {
  AssigneeDto,
  AxiosErrorResponse,
  CustomDictionaryItem,
  DictionaryResponse,
  DownloadUriResponse,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import { UserAccountRoleDto } from '../../settings/users-dashboard/types/settingsTypes';
import { setLoading } from '../slices/coreSlice';
import {
  DefaultAddressDto,
  FetchRectificationsDictionaryParamsType,
  OrderDictionaryDto,
  OrderDictionaryFiltersType,
  RectificationDictionaryDto,
} from '../../common/types/commonTypes';
import { ordersAPI } from '../../api/ordersApi';
import { rectificationsAPI } from '../../api/rectificationsApi';
import { downloadCsv } from '../../core/utils/downloadFileHandler';

export const getGoogleApiKey = createAsyncThunk<ResponseSingleResult<{ key: string }>>(
  'get/GoogleApiKey',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchGoogleApiKey();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPrivacyPolicyThunk = createAsyncThunk<DownloadUriResponse>(
  'get/privacyPolicy',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPrivacyPolicy();
      dispatch(setLoading(false));
      downloadCsv(response.data.data.downloadUri);
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerAccountTypes = createAsyncThunk<DictionaryResponse>(
  'get/CustomerAccountTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchCustomerAccountTypes();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerTypes = createAsyncThunk<DictionaryResponse>(
  'get/CustomerTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchCustomerTypes();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobTypes = createAsyncThunk<ResponseSingleResult<{ items: CustomDictionaryItem[] }>>(
  'get/JobTypes',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchJobTypes();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPriorityLevels = createAsyncThunk<DictionaryResponse>(
  'get/PriorityLevels',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchPriorityLevels();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquirySources = createAsyncThunk<DictionaryResponse>(
  'get/EnquirySources',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchEnquirySources();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryWarningsThunk = createAsyncThunk<DictionaryResponse>(
  'get/EnquiryWarnings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchEnquiryWarnings();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobWarningsThunk = createAsyncThunk<DictionaryResponse>(
  'get/JobWarnings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchJobWarnings();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderWarningsThunk = createAsyncThunk<DictionaryResponse>(
  'get/OrderWarnings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchOrderWarnings();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationWarningsThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationWarnings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchRectificationWarnings();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getBusinessAreas = createAsyncThunk<ResponseSingleResult<{ items: CustomDictionaryItem[] }>>(
  'get/BusinessAreas',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreAPI.fetchBusinessAreas();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuoteLinesTaxes = createAsyncThunk<DictionaryResponse>(
  'get/QuoteLineTaxes',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchQuoteLineTaxes();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountAccountTypes = createAsyncThunk<DictionaryResponse>(
  'get/accountAccountTypes',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchAccountAccountTypes();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountAccountRoles = createAsyncThunk<DictionaryResponse>(
  'get/accountAccountRoles',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchAccountAccountRoles();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountPermissionRoles = createAsyncThunk<ResponseSingleResult<{ items: UserAccountRoleDto[] }>,
{ accountType?: number }
>(
  'get/accountPermissionRoles',
  async ({ accountType }, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchAccountPermissionsRoles(accountType);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistTypes = createAsyncThunk<DictionaryResponse>(
  'get/PricelistTypes',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchPricelistTypes();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuoteUnitsOfMeasure = createAsyncThunk<DictionaryResponse>(
  'get/QuoteUnitsOfMeasure',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchQuoteUnitsOfMeasure();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuoteInfoSources = createAsyncThunk<DictionaryResponse>(
  'get/QuoteInfoSources',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchQuoteInfoSources();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUsersListCoreDictionary = createAsyncThunk<ResponseSingleResult<{ userAccounts: AssigneeDto[] }>,
{ keyword?: string }
>(
  'get/UsersListCoreDictionary',
  async ({ keyword }, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchUsersCoreDictionary(keyword);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getUsersListHadAJob = createAsyncThunk<ResponseSingleResult<{ userAccounts: AssigneeDto[] }>,
{ keyword?: string }
>(
  'get/UsersListHadAJob',
  async ({ keyword }, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchUsersCoreDictionary(keyword, true);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobCategories = createAsyncThunk<DictionaryResponse>(
  'get/JobCategories',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchJobCategories();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderDictionary = createAsyncThunk<
ResponseSingleResult<{ orders: OrderDictionaryDto[] }> | null,
{
  filters?: OrderDictionaryFiltersType,
  signal?: AbortSignal,
}
>(
  'get/OrderDictionary',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.fetchOrderDictionary(filters, signal);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAttachmentsTypes = createAsyncThunk<DictionaryResponse>(
  'get/AttachmentTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchAttachmentTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getLinksTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/LinksTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchLinksTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationsDictionary = createAsyncThunk<
ResponseSingleResult<{ rectifications: RectificationDictionaryDto[] }> | null,
{
  filters?: FetchRectificationsDictionaryParamsType,
}
>(
  'get/RectificationDictionary',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationsDictionary(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationsTypes = createAsyncThunk<DictionaryResponse>(
  'get/InAppNotificationsTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreAPI.fetchInAppNotificationsTypes();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationsEntityTypes = createAsyncThunk<DictionaryResponse>(
  'get/InAppNotificationsEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreAPI.fetchInAppNotificationsEntityTypes();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLinePriceSources = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestLinePriceSources',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPartRequestLinePriceSources();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationFaultTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationFaultTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchRectificationFaultTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleProfileTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleProfileTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchVehicleProfileTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleFlowsThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleFlows',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchVehicleFlows();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleCheckTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleCheckTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchVehicleCheckTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getFullVehicleCheckDecisionsThunk = createAsyncThunk<DictionaryResponse>(
  'get/FullVehicleCheckDecisions',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchFullVehicleCheckDecisions();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getHandoverVehicleCheckDecisionsThunk = createAsyncThunk<DictionaryResponse>(
  'get/HandoverVehicleCheckDecisions',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchHandoverVehicleCheckDecisions();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleTransportationMethodsThunk = createAsyncThunk<DictionaryResponse>(
  'get/VehicleTransportationMethods',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchVehicleTransportationMethods();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getWeatherConditionsThunk = createAsyncThunk<DictionaryResponse>(
  'get/WeatherConditions',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchWeatherConditions();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLineTypes = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestLineTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPartRequestLineTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getLeaveRequestTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/LeaveRequestTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchLeaveRequestTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getDayPartsThunk = createAsyncThunk<DictionaryResponse>(
  'get/dayParts',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchDayParts();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalCodeTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/nominalCodeTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchNominalCodeTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUnfinishedEntityType = createAsyncThunk<DictionaryResponse>(
  'get/UnfinishedEntityType',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchUnfinishedEntityType();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getFitterReturnsReportEntityType = createAsyncThunk<DictionaryResponse>(
  'get/FitterReturnsReportEntityType',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchFitterReturnsReportEntityType();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesByCustomerEntityTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/SalesByCustomerEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchSalesByCustomerEntityTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOffsiteEntityTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/SalesOffsiteEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchSalesOffsiteEntityTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalRecordStates = createAsyncThunk<DictionaryResponse>(
  'get/NominalRecordStates',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchNominalRecordStates();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalRecordTypes = createAsyncThunk<DictionaryResponse>(
  'get/NominalRecordTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchNominalRecordTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderEventSources = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderEventSources',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPurchaseOrderEventSources();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/InvoiceDocumentTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchInvoiceDocumentTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentOperationTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/InvoiceDocumentOperationTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchInvoiceDocumentOperationTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentStatusTransitionsThunk = createAsyncThunk<DictionaryResponse>(
  'get/InvoiceDocumentStatusTransitions',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchInvoiceDocumentStatusTransitions();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getVehicleSlidingDoorsQuantities = createAsyncThunk<DictionaryResponse>(
  'get/VehicleSlidingDoorsQuantities',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchVehicleSlidingDoorsQuantities();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleInfoSources = createAsyncThunk<DictionaryResponse>(
  'get/VehicleInfoSources',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchVehicleInfoSources();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuoteLinePriceSources = createAsyncThunk<DictionaryResponse>(
  'get/QuoteLinePriceSources',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreAPI.fetchQuoteLinePriceSources();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationTypes',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchRectificationTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderEventSources = createAsyncThunk<DictionaryResponse>(
  'get/OrderEventSources',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchOrderEventSources();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSuitableVehicleSourcesThunk = createAsyncThunk<DictionaryResponse>(
  'get/SuitableVehicleSources',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchSuitableVehicleSources();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVanlinersOfficeLocation = createAsyncThunk<ResponseSingleResult<{defaultAddress: DefaultAddressDto }>>(
  'get/VanlinersOfficeLocation',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchVanlinersOfficeLocation();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLineActivityTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestLineActivityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPartRequestLineActivityTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCancelledLinesReportEntityTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/CancelledLinesReportEntityTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchCancelledLinesReportEntityTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNominalRecordVatTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/NominalRecordVatTypes',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchNominalRecordVatTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderPartAmountInputTypesThunk = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderPartAmountInputType',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreAPI.fetchPurchaseOrderPartAmountInputTypes();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
