import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { leaveRequestsDraftStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import { leaveRequestOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { employeesLeaveRequestsFiltersSelector, employeesLeaveRequestsListSelector } from '../../../store/selectors/employeesSelector';
import { getEmployeesLeaveRequestsThunk } from '../../../store/thunks/employee/employeesLeaveRequestsThunks';
import { LeaveRequestOrdering } from '../../../core/enums/dictionariesEnums';
import { setEmployeesLeaveRequestsFilters } from '../../../store/slices/employeesSlice';
import { useLeaveRequestsComponents } from '../../../common/hooks/useLeaveRequestsComponents';

export const useLoadEmployeesLeaveRequestsData = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const leaveRequestOrdering = useAppSelector(leaveRequestOrderingSelector);
  const draftStatus = useAppSelector(leaveRequestsDraftStatusSelector);
  const filters = useAppSelector(employeesLeaveRequestsFiltersSelector);
  const { items, totalCount } = useAppSelector(employeesLeaveRequestsListSelector);
  const conditionsToSetFilters = !filters.order?.field && !filters.exceptStatuses && leaveRequestOrdering.length && draftStatus;

  useLeaveRequestsComponents();

  useEffect(() => {
    if (filters.order?.field && filters.exceptStatuses) {
      dispatch(getEmployeesLeaveRequestsThunk({ filters }));
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (conditionsToSetFilters) {
      const orderField = leaveRequestOrdering.find((el) => el.label === LeaveRequestOrdering.CreationDate)?.value;
      const exceptStatuses = [draftStatus];
      if (orderField) {
        dispatch(setEmployeesLeaveRequestsFilters({
          ...filters,
          order: { field: orderField, isAsc: false },
          exceptStatuses,
          employeeIds: id ? [+id] : undefined,
        }));
      }
    }
    // eslint-disable-next-line
  }, [leaveRequestOrdering, draftStatus]);

  return { items, totalCount };
};
