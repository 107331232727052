import React from 'react';
import { PartTabsEnum } from '../../../enums/PartTypesEnum';
import PartViewAttachments from './attachments/PartViewAttachments';
import PartViewPartsKits from './parts-kits/PartViewPartsKits';
import PartVehicleAndPrices from './vehicleAndPrices/PartVehicleAndPrices';
import PartViewPurchases from './purchases/PartViewPurchases';
import PartViewPageActivityLog from './activity/PartViewPageActivityLog';

type DynamicContentType = {
  mode: string | null,
}

const DynamicContent:React.FC<DynamicContentType> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case PartTabsEnum.VEHICLESANDPRICES:
        return <PartVehicleAndPrices />;
      case PartTabsEnum.PURCHASES:
        return <PartViewPurchases />;
      case PartTabsEnum.PARTSKITS:
        return <PartViewPartsKits />;
      case PartTabsEnum.ACTIVITY:
        return <PartViewPageActivityLog />;
      default: return <PartViewAttachments />;
    }
  };

  return (
    <>
      {renderContent()}
    </>
  );
};

export default DynamicContent;
