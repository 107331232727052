import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment/moment';
import Input from '../../../../../core/components/input/Input';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isRectificationHasCancelledStatusSelector,
  isRectificationInReadModeSelector, isRectificationXeroModeSelector,
  rectificationDetailsRequisitesSelector, rectificationDetailsReviewStatusSelector,
  rectificationNewStatusSelector,
  rejectionReasonModeSelector,
} from '../../../../../store/selectors/rectificationsSelector';
import { PartialRectificationDto, PatchRectificationDto } from '../../../types/rectificationViewTypes';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { patchRectificationThunk } from '../../../../../store/thunks/rectification/rectificationViewPageThunks';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { RectificationTypeEnum } from '../../../../dashboard/enums/rectificationEnums';
import FaultTypeModal from './modals/FaultTypeModal';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import DatePicker from '../../../../../core/components/date-picker/DatePicker';
import { utcDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';
import {
  allowedJobTypesForUserSelector, jobTypesValuesSelector, jobTypesWithoutSupplyOnlySelector,
  priorityLevelsSelector, rectificationFaultTypesSelector, rectificationTypesSelector,
} from '../../../../../store/selectors/coreSelectors';
import AddressField from '../../../../../common/components/address/AddressField';
import { AddressFields, RequisiteDto } from '../../../../../common/types/commonTypes';
import AddressModal from '../../../../../common/components/address/AddressModal';
import Textarea from '../../../../../core/components/textarea/Textarea';
import RequisiteSection from '../../../../../common/components/requisite/RequisiteSection';
import RequisiteModal from '../../../../../common/components/requisite/RequisteModal';
import { rectificationDetailsSelector } from '../../../../../store/selectors/sharedSelectors';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT, NO_SPACES_REGEX } from '../../../../../core/utils/regex';
import { rectificationDetailsDefaultFields, RectificationDetailsFields } from '../../../utils/data';
import ReasonModal from '../../../../../common/components/reason-modal/ReasonModal';
import { RectificationDto } from '../../../../dashboard/types/rectificationsTypes';

const RectificationDetailsSection: React.FC = () => {
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const rectification = rectificationDetails?.rectification;
  const isRepairFlow = rectification?.isRepairVehicleFlow;
  const allowedToEdit = usePermission(PermissionEnum.RectificationEditFields);
  const dispatch = useAppDispatch();
  const rectificationTypes = useAppSelector(rectificationTypesSelector);
  const rectificationFaultTypes = useAppSelector(rectificationFaultTypesSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(allowedJobTypesForUserSelector);
  const jobTypesWithoutSupplyOnly = useAppSelector(jobTypesWithoutSupplyOnlySelector);
  const { offSite } = useAppSelector(jobTypesValuesSelector);
  const rectificationNewStatus = useAppSelector(rectificationNewStatusSelector);
  const rectificationDetailsReviewStatus = useAppSelector(rectificationDetailsReviewStatusSelector);
  const { billing, shipping, isShippingSame } = useAppSelector(rectificationDetailsRequisitesSelector);
  const isRectificationHasCancelledStatus = useAppSelector(isRectificationHasCancelledStatusSelector);
  const isRectificationInReadMode = useAppSelector(isRectificationInReadModeSelector);
  const isRectificationXeroMode = useAppSelector(isRectificationXeroModeSelector);
  const { rejectionReasonMode, isStatusRejectedByCustomer } = useAppSelector(rejectionReasonModeSelector);

  const [isFaultTypeModal, setIsFaultTypeModal] = useState(false);
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);
  const [reasonModal, setReasonModal] = useState(false);
  const [reasonValue, setReasonValue] = useState('');

  const isStatusNew = rectification?.status === rectificationNewStatus;

  const isDueDateDisabled = ![rectificationNewStatus, rectificationDetailsReviewStatus].includes(rectification?.status);

  const {
    isShippingDetailsManualInput, isBillingDetailsManualInput, shippingCustomerId, billingCustomerId, customerId,
  } = rectification || {};

  const {
    control, setValue, getValues, clearErrors, setError, watch,
  } = useForm<RectificationDetailsFields>({
    defaultValues: rectificationDetailsDefaultFields,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const faultType = watch('faultType');
  const rectificationType = watch('rectificationType');
  const jobType = watch('jobType');

  const handleJobTypeError = (newJobTypeValue: number) => {
    setValue('jobType', rectificationDetails?.rectification.jobType);
    const newJobTypeName = jobTypes.find((type) => type.value === newJobTypeValue)?.label;
    return `Job type cannot be changed to ${newJobTypeName}.`;
  };

  const changeDetails = useCallback((
    data: PatchRectificationDto,
    key: keyof PatchRectificationDto,
    deepCompare?: boolean,
    isOptional?: boolean,
  ) => {
    if (rectification) {
      const handler = () => {
        dispatch(patchRectificationThunk({
          rectificationId: rectification.id,
          data,
          handleJobTypeError,
          partRequestId: rectificationDetails?.rectification.partRequestId,
          openReasonModal: () => setReasonModal(true),
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setInitValue: () => setValue(key, rectification[key as keyof RectificationDto]),
        }));
      };
      compareValuesHandle<PatchRectificationDto>(data, key, rectification, handler, deepCompare, isOptional);
    }
    // eslint-disable-next-line
  }, [handleJobTypeError, rectification, rectificationDetails?.rectification.partRequestId]);
  const changeAddressDetails = (data: PartialRectificationDto, keys: Array<keyof PartialRectificationDto>) => {
    if (rectification) {
      const keysNotEqual = keys.some((key) => rectification[key] !== data[key]);
      if (keysNotEqual) {
        dispatch(patchRectificationThunk({
          rectificationId: rectification.id,
          data,
          onClose: () => setIsAddressModal(false),
        }));
      } else {
        setIsAddressModal(false);
      }
    }
  };

  const clearOtherErrorsHandle = (field: string) => {
    const fieldsToValidate = [
      'faultType', 'rectificationDate', 'description', 'customerReferenceNumber', 'rejectionReason', 'xeroInvoiceId', 'xeroInvoiceUrl',
    ];
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, rectification, setValue);
  };

  useEffect(() => {
    if (rectification) {
      setValue('rectificationType', rectification.rectificationType);
      setValue('faultType', rectification.faultType);
      setValue('rectificationDate', rectification.rectificationDate);
      setValue('priorityLevel', rectification.priorityLevel);
      setValue('jobType', rectification.jobType);
      setValue('dueDate', rectification.dueDate);
      setAddressFields({
        companyName: rectification.jobCompanyName || null,
        addressLine1: rectification.jobAddressLine1 || null,
        addressLine2: rectification.jobAddressLine2 || null,
        cityTown: rectification.jobCityTown || null,
        region: rectification.jobRegion || null,
        postalCode: rectification.jobPostalCode || null,
        telephone: rectification.jobTelephone || null,
      });
      setValue('description', rectification.description);
      setValue('customerReferenceNumber', rectification.customerReferenceNumber);
      setIsSame(!!rectification.isShippingRequisiteSameAsBilling);
      setValue('rejectionReason', rectification.rejectionReason);
      setValue('xeroInvoiceId', rectification.xeroInvoiceId);
      setValue('xeroInvoiceUrl', rectification.xeroInvoiceUrl);
    }
    // eslint-disable-next-line
  }, [rectification]);

  const closeRequisiteModal = () => {
    setIsVisible(false);
    setIsShippingMode(false);
  };

  const editHandle = (requisite: RequisiteDto, isManual: boolean, selectedEntityId?: number) => {
    const rectificationId = rectification?.id;
    const newValues = isShippingMode
      ? {
        shippingRequisite: isManual ? requisite : null,
        shippingCustomerId: selectedEntityId,
        isShippingDetailsManualInput: isManual,
        isShippingRequisiteSameAsBilling: false,
      }
      : {
        billingRequisite: isManual ? requisite : null,
        billingCustomerId: selectedEntityId,
        isBillingDetailsManualInput: isManual,
      };
    rectificationId && dispatch(patchRectificationThunk({
      rectificationId,
      data: newValues,
      onClose: closeRequisiteModal,
    }));
  };

  const checkIsAddressEmpty = (values: AddressFields) => {
    const keysToCheck = ['companyName', 'addressLine1', 'addressLine2', 'cityTown', 'postalCode', 'telephone'];
    const fieldsCheckUp = [];
    Object.entries(values).forEach(([key, v]) => {
      if (keysToCheck.includes(key) && v?.trim()) {
        fieldsCheckUp.push(1);
      }
    });
    return fieldsCheckUp.length !== keysToCheck.length;
  };

  const handleSubmitReason = useCallback(() => {
    const formatted = moment(getValues('rectificationDate')).format(DEFAULT_DATE_FORMAT);
    changeDetails({ rectificationDate: utcDateFormatHandler('', formatted) }, 'rectificationDate');
  }, [changeDetails, getValues]);

  const handleCancelReason = useCallback(() => {
    setReasonModal(false);
    setValue('rectificationDate', moment(rectificationDetails?.rectification.rectificationDate).format(DEFAULT_DATE_FORMAT));
  }, [rectificationDetails?.rectification.rectificationDate, setValue]);

  const disabledManaging = !allowedToEdit || isRectificationInReadMode;
  const disabledToManageDescriptionAndCustomerRefNo = !allowedToEdit || isRectificationHasCancelledStatus;
  const isOptionalAddressFields = isStatusNew || (!isStatusNew && jobType !== offSite);
  return (
    <>
      <FaultTypeModal
        isVisible={isFaultTypeModal}
        rectificationFaultTypes={rectificationFaultTypes}
        onCancel={() => {
          setValue('rectificationType', rectification?.rectificationType);
          setValue('faultType', rectification?.faultType);
          setIsFaultTypeModal(false);
        }}
        saveHandler={(faultType) => {
          changeDetails({ faultType, rectificationType }, 'rectificationType');
          setIsFaultTypeModal(false);
        }}
      />
      <AddressModal
        title="Job location"
        isVisible={isAddressModal}
        onCancel={() => {
          const isSmthEmpty = addressFields ? checkIsAddressEmpty(addressFields) : true;
          if (jobType === offSite && !isStatusNew && isSmthEmpty) {
            setValue('jobType', rectification?.jobType);
          }
          setIsAddressModal(false);
        }}
        addressFields={addressFields}
        onSubmit={(values) => {
          setAddressFields(values);
          changeAddressDetails({
            jobType: jobType === offSite ? offSite : rectification?.jobType,
            jobCompanyName: values.companyName?.trim() || '',
            jobAddressLine1: values.addressLine1?.trim() || '',
            jobAddressLine2: values.addressLine2?.trim() || '',
            jobCityTown: values.cityTown?.trim() || '',
            jobRegion: values.region?.trim() || '',
            jobPostalCode: values.postalCode?.trim() || '',
            jobTelephone: values.telephone?.trim() || '',
          }, [
            'jobType', 'jobCompanyName', 'jobAddressLine1',
            'jobAddressLine2', 'jobCityTown', 'jobRegion', 'jobPostalCode', 'jobTelephone',
          ]);
        }}
        isOptionalFields={isOptionalAddressFields}
      />
      <RequisiteModal
        isVisible={isVisible}
        isOptionalFields={isStatusNew}
        onCancel={() => {
          closeRequisiteModal();
          isShippingSame && setIsSame(true);
        }}
        requisite={isShippingMode ? shipping : billing}
        submitHandle={editHandle}
        entityId={isVisible
          ? (isShippingMode ? shippingCustomerId : billingCustomerId) || customerId
          : undefined}
        entitySourceType="customer"
        isManual={isShippingMode ? !!isShippingDetailsManualInput : !!isBillingDetailsManualInput}
        isShipping={isShippingMode}
      />
      <ReasonModal
        visible={reasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={handleCancelReason}
        onSubmit={handleSubmitReason}
        type="update"
      />
      <section className="info-grid__column rectificationDetailsSection">
        <h2 className="info-grid__title">Details</h2>
        <form className="details-form">
          <Input
            value={rectification?.linkedNumber || '-'}
            label="Linked to"
            disabled
          />
          <Controller
            name="rectificationType"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  field.onChange(v);
                  if (v as number === RectificationTypeEnum.FREE && !faultType) {
                    setIsFaultTypeModal(true);
                  } else {
                    clearOtherErrorsHandle('rectificationType');
                    changeDetails({ rectificationType: v as number }, 'rectificationType');
                  }
                }}
                options={rectificationTypes}
                label="Rectification type"
                disabled={disabledManaging}
              />
            )}
          />
          <Controller
            name="faultType"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value || undefined}
                options={rectificationFaultTypes}
                onChange={(v) => {
                  clearOtherErrorsHandle('faultType');
                  field.onChange(v);
                  changeDetails({ faultType: v as number }, 'faultType');
                }}
                label="Fault type"
                error={errors.faultType?.message}
                disabled={disabledManaging}
                className="details-form__field--lg"
              />
            )}
          />
          <Input
            value={rectification?.costString}
            label="Cost"
            disabled
          />
          <Controller
            name="rectificationDate"
            control={control}
            rules={{
              required: ErrorsEnum.REQUIRED,
            }}
            render={({ field }) => (
              <DatePicker
                value={field.value ? moment(field.value) : undefined}
                onChange={(v) => {
                  if (v) {
                    const formatted = moment(v).format(DEFAULT_DATE_FORMAT);
                    field.onChange(formatted);
                    clearOtherErrorsHandle('rectificationDate');
                    !errors.rectificationDate
                    && changeDetails({ rectificationDate: utcDateFormatHandler('', formatted) }, 'rectificationDate');
                  }
                }}
                label="Rectification date"
                error={errors.rectificationDate?.message}
                format={DEFAULT_UI_DATE_FORMAT}
                disabled={disabledManaging}
                disabledDate={(currentDate) => {
                  const tooEarly = moment(currentDate).add(+1, 'days') <= moment(rectification?.createdDate);
                  const tooLate = currentDate > moment();
                  return tooEarly || tooLate;
                }}
              />
            )}
          />
          <Controller
            name="priorityLevel"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                options={priorityLevels}
                label="Priority level"
                onChange={(v) => {
                  field.onChange(v);
                  clearOtherErrorsHandle('priorityLevel');
                  changeDetails({ priorityLevel: v as number }, 'priorityLevel');
                }}
                disabled={disabledManaging}
              />
            )}
          />
          <Controller
            name="jobType"
            control={control}
            render={({ field }) => (
              <Select
                value={field.value}
                options={isRepairFlow ? jobTypesWithoutSupplyOnly : jobTypes}
                label="Job type"
                onChange={(v) => {
                  const isSmthEmpty = addressFields ? checkIsAddressEmpty(addressFields) : true;
                  if (v === offSite && !isStatusNew && isSmthEmpty) {
                    setIsAddressModal(true);
                    field.onChange(v);
                  } else {
                    field.onChange(v);
                    changeDetails({ jobType: v as number }, 'jobType');
                  }
                  clearOtherErrorsHandle('jobType');
                }}
                disabled={disabledManaging}
              />
            )}
          />
          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <DatePicker
                value={field.value ? moment(field.value) : undefined}
                onChange={(value) => {
                  const formatted = value ? value.format(DEFAULT_DATE_FORMAT) : '';
                  field.onChange(formatted);
                  changeDetails({ dueDate: utcDateFormatHandler('', formatted) }, 'dueDate');
                }}
                disabledDate={(currentDate) => {
                  const entityDate = moment(rectificationDetails?.rectification?.rectificationDate);
                  const tooEarly = currentDate < entityDate;
                  const tooLate = currentDate > entityDate.add(24, 'months');
                  return tooEarly || tooLate;
                }}
                error={errors.dueDate?.message}
                label="Due date"
                format="DD/MM/YYYY"
                disabled={disabledManaging || isDueDateDisabled}
                className="details-form__field--lg"
              />
            )}
          />
          <AddressField
            title="Job location"
            addressFields={addressFields}
            setAddressModal={() => {
              setIsAddressModal(true);
              clearOtherErrorsHandle('jobLocation');
            }}
            disabled={disabledManaging}
          />
          <Controller
            name="description"
            control={control}
            rules={{
              maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
            }}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                label="Description"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('description');
                }}
                onBlur={() => {
                  !errors.description
                    && changeDetails({ description: field.value?.trim() || null }, 'description', false, true);
                }}
                error={errors.description?.message}
                disabled={disabledToManageDescriptionAndCustomerRefNo}
                className="details-form__field--lg"
              />
            )}
          />
          <RequisiteSection
            requisiteDetails={billing || null}
            setIsVisible={() => {
              if (!rectification?.alwaysUseCustomerRequisites) {
                setIsVisible(true);
                clearOtherErrorsHandle('billing');
              }
            }}
            title="Billing details"
            disabled={!!rectification?.alwaysUseCustomerRequisites || disabledManaging}
          />
          <RequisiteSection
            requisiteDetails={shipping || null}
            disabled={!!rectification?.alwaysUseCustomerRequisites || disabledManaging}
            setIsVisible={() => {
              if (!rectification?.alwaysUseCustomerRequisites && !isShippingSame) {
                setIsVisible(true);
                setIsShippingMode(true);
                clearOtherErrorsHandle('shipping');
              }
            }}
            title="Shipping details"
            isSame={isSame}
            isShipping
            onCheck={(val: boolean) => {
              clearOtherErrorsHandle('isSame');
              if (val) {
                rectification?.id && dispatch(patchRectificationThunk({
                  rectificationId: rectification.id,
                  data: { isShippingRequisiteSameAsBilling: true },
                }));
              } else {
                setIsShippingMode(true);
                setIsVisible(true);
              }
              setIsSame(val);
            }}
          />
          <Controller
            name="customerReferenceNumber"
            control={control}
            rules={{
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
            }}
            render={({ field }) => (
              <Input
                value={field.value}
                label="Customer reference No"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('customerReferenceNumber');
                }}
                onBlur={(e) => {
                  !errors.customerReferenceNumber
                  && changeDetails({ customerReferenceNumber: e.target.value.trim() }, 'customerReferenceNumber', false, true);
                }}
                error={errors.customerReferenceNumber?.message}
                disabled={disabledToManageDescriptionAndCustomerRefNo}
              />
            )}
          />
          {rejectionReasonMode && <Controller
            name="rejectionReason"
            control={control}
            rules={{
              required: isStatusRejectedByCustomer && ErrorsEnum.REQUIRED,
              maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
            }}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                label="Rejection reason"
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('rejectionReason');
                }}
                onBlur={(e) => {
                  if (!e.target.value.trim() && isStatusRejectedByCustomer) {
                    setError('rejectionReason', { type: 'required', message: ErrorsEnum.REQUIRED });
                    setValue('rejectionReason', '');
                  } else {
                    !errors.rejectionReason && changeDetails({ rejectionReason: e.target.value.trim() }, 'rejectionReason');
                  }
                }}
                error={errors.rejectionReason?.message}
                disabled={disabledManaging}
                className="details-form__field--lg"
              />
            )}
          />}
          {isRectificationXeroMode && <>
            <Controller
              name="xeroInvoiceId"
              control={control}
              rules={{
                maxLength: { value: 100, message: maxLengthMessageHandle(100) },
              }}
              render={({ field }) => (
                <Input
                  label="Xero invoice ID"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearOtherErrorsHandle('xeroInvoiceId');
                  }}
                  onBlur={(e) => {
                    !errors.xeroInvoiceId && changeDetails({ xeroInvoiceId: e.target.value.trim() }, 'xeroInvoiceId');
                  }}
                  error={errors.xeroInvoiceId?.message}
                  disabled={disabledManaging}
                  className="rectificationDetailsSection__xero-id"
                />
              )}
            />
            <Controller
              name="xeroInvoiceUrl"
              control={control}
              rules={{
                maxLength: { value: 300, message: maxLengthMessageHandle(300) },
                pattern: { value: NO_SPACES_REGEX, message: ErrorsEnum.SPACES_ARE_NOT_ALLOWED },
              }}
              render={({ field }) => (
                <Input
                  label="Xero invoice URL"
                  value={field.value}
                  onChange={(e) => {
                    field.onChange(e.target.value);
                    clearOtherErrorsHandle('xeroInvoiceUrl');
                  }}
                  onBlur={(e) => {
                    !errors.xeroInvoiceUrl && changeDetails({ xeroInvoiceUrl: e.target.value.trim() }, 'xeroInvoiceUrl');
                  }}
                  error={errors.xeroInvoiceUrl?.message}
                  disabled={disabledManaging}
                />
              )}
            />
          </>}
        </form>
      </section>
    </>
  );
};

export default RectificationDetailsSection;
