import React, { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Segmented from '../../../core/components/segmented/Segmented';
import {
  completedJobStatusesSelector,
  workQueueJobStatusesSelector,
} from '../../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  JobListAllStatusEnum,
  JobModesEnum,
  JobsSearchParamsNames,
  QueueCompletedModesEnum,
} from '../../jobs/enums/jobsEnums';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { setJobListFilters } from '../../../store/slices/jobsSlice';
import { JobListFilters } from '../types/jobListTypes';
import { currentUserSelector, isCurrentRoleAdminSelector } from '../../../store/selectors/accountSelectors';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';

const queueOptions = [
  { label: 'Work queue', value: QueueCompletedModesEnum.WORK_QUEUE },
  { label: 'Completed jobs', value: QueueCompletedModesEnum.COMPLETED },
];

type JobListExtraFiltersProps = {
  page: string | null,
  tabType: string | null;
  setJobTabTypeParam: StringFunctionType,
  jobListFilters: JobListFilters,
  setJobStatusParam: StringFunctionType,
  jobStatus: string | null;
}

const JobListExtraFilters: React.FC<JobListExtraFiltersProps> = ({
  page, tabType, setJobTabTypeParam, jobListFilters, jobStatus, setJobStatusParam,
}) => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const currentUser = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);
  const workQueueJobStatuses = useAppSelector(workQueueJobStatusesSelector);
  const completedJobStatuses = useAppSelector(completedJobStatusesSelector);
  const { windowWidth } = useScreenWitdh();

  const finalOptions = useMemo(() => (tabType && tabType === QueueCompletedModesEnum.WORK_QUEUE
    ? workQueueJobStatuses
    : completedJobStatuses), [tabType, workQueueJobStatuses, completedJobStatuses]);

  const setDefaultJobTabParam = () => {
    const isFilterFilled = jobListFilters.jobStatuses && jobListFilters.jobStatuses.length > 0;
    const filterLength = jobListFilters.jobStatuses?.length;
    const firstEl = jobListFilters.jobStatuses ? jobListFilters.jobStatuses[0] : null;
    const isCurrentFilterFromCompletedTab = firstEl
      ? completedJobStatuses?.find((st) => st.value === firstEl)
      : null;

    if (isFilterFilled) {
      if (filterLength && filterLength > 1) {
        params.append(JobsSearchParamsNames.TAB_TYPE, QueueCompletedModesEnum.WORK_QUEUE);
      } else {
        if (isCurrentFilterFromCompletedTab) {
          params.append(JobsSearchParamsNames.TAB_TYPE, QueueCompletedModesEnum.COMPLETED);
        } else {
          params.append(JobsSearchParamsNames.TAB_TYPE, QueueCompletedModesEnum.WORK_QUEUE);
        }
      }
    } else {
      params.append(JobsSearchParamsNames.TAB_TYPE, QueueCompletedModesEnum.WORK_QUEUE);
    }
    navigate({ search: params.toString() });
  };
  const setDefaultStatusParam = () => {
    if (jobListFilters.jobStatuses && jobListFilters.jobStatuses.length > 0) {
      params.append(JobsSearchParamsNames.JOB_STATUS, jobListFilters.jobStatuses[0].toString());
      navigate({ search: params.toString() });
    } else {
      if (tabType === QueueCompletedModesEnum.COMPLETED) {
        const firstV = completedJobStatuses[0].value;
        const filters = {
          ...jobListFilters,
          jobStatuses: [firstV],
        };
        dispatch(setJobListFilters(filters));
        params.append(JobsSearchParamsNames.JOB_STATUS, firstV.toString());
      } else {
        const workQueueIds = workQueueJobStatuses
          .filter((el) => el.value !== JobListAllStatusEnum.ALL)
          .map((el) => el.value);
        const filters = {
          ...jobListFilters,
          jobStatuses: workQueueIds,
        };
        dispatch(setJobListFilters(filters));
        params.append(JobsSearchParamsNames.JOB_STATUS, JobListAllStatusEnum.ALL.toString());
      }
      navigate({ search: params.toString() });
    }
  };
  const changeJobTabParam = (value: string) => {
    setJobTabTypeParam(value as string);
    if (value === QueueCompletedModesEnum.WORK_QUEUE) {
      const filters = {
        ...jobListFilters,
        jobStatuses: undefined,
        page: 1,
      };
      dispatch(setJobListFilters(filters));
    } else {
      const isStatusesLoaded = completedJobStatuses && completedJobStatuses.length > 0;
      const filters = {
        ...jobListFilters,
        jobStatuses: [completedJobStatuses[0].value],
        page: 1,
      };
      isStatusesLoaded && dispatch(setJobListFilters(filters));
    }
  };
  const changeJobStatusHandle = (status: number | string) => {
    setJobStatusParam(status.toString());
    if (+status === JobListAllStatusEnum.ALL) {
      const workQueueIds = workQueueJobStatuses
        .filter((el) => el.value !== JobListAllStatusEnum.ALL)
        .map((el) => el.value);
      const filters = {
        ...jobListFilters,
        jobStatuses: workQueueIds,
        page: 1,
      };
      dispatch(setJobListFilters(filters));
    } else {
      const filters = {
        ...jobListFilters,
        jobStatuses: [+status],
        page: 1,
      };
      dispatch(setJobListFilters(filters));
    }
  };

  useEffect(() => {
    if (currentUser) {
      if (!tabType) {
        const page = params.get(JobsSearchParamsNames.PAGE);
        if (page === JobModesEnum.JOB_LIST) {
          setDefaultJobTabParam();
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, page, tabType, jobListFilters.jobStatuses]);
  // +
  useEffect(() => {
    if (!jobStatus) {
      if (tabType) {
        setDefaultStatusParam();
      }
    }
    // eslint-disable-next-line
  }, [tabType, jobStatus]);
  return (
    <div className={classNames(
      'jobListExtraFilters',
      {
        'desktop-sticky-filters desktop-sticky-filters--top-sm mobile-sticky-filters mobile-sticky-filters--top-sm': !isCurrentRoleAdmin,
      },
    )}
    >
      {/* <section className={classNames('jobListExtraFilters', { 'jobListExtraFilters--usual-user': !isCurrentRoleAdmin })}> */}
      {windowWidth < responsiveEndpointsEnum.LG
        ? <>
          <Selectable
            options={queueOptions || []}
            onChange={(v) => changeJobTabParam(v as QueueCompletedModesEnum)}
            value={tabType || ''}
          />
          <Selectable
            options={finalOptions}
            onChange={(v) => changeJobStatusHandle(v)}
            value={jobStatus ? +jobStatus : ''}
            size="sm"
          />
        </>
        : <>
          <Segmented
            options={queueOptions || []}
            onChange={(v) => changeJobTabParam(v as QueueCompletedModesEnum)}
            value={tabType || ''}
          />
          <Segmented
            options={finalOptions}
            onChange={(v) => changeJobStatusHandle(v as number)}
            value={jobStatus ? +jobStatus : ''}
          />
        </>}
    </div>
  );
};

export default JobListExtraFilters;
