import { StockPageParamEnums, StockViewsEnums } from '../enums/stockEnums';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import {
  StockTabParamEnum,
} from '../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';

export const dashOptions = [
  {
    value: StockPageParamEnums.DASHBOARD,
    label: StockViewsEnums.DASHBOARD,
    restrictedTo: PermissionEnum.StockDashboard,
  },
  {
    value: StockPageParamEnums.SEARCH,
    label: StockViewsEnums.SEARCH,
    restrictedTo: PermissionEnum.StockSearchPage,
  },
  {
    value: StockPageParamEnums.PART_REQUESTS,
    label: StockViewsEnums.PART_REQUESTS,
    restrictedTo: PermissionEnum.StockPartRequests,
  },
  {
    value: StockPageParamEnums.PURCHASES,
    label: StockViewsEnums.PURCHASES,
    restrictedTo: PermissionEnum.StockPurchaseOrders,
  },
  {
    value: StockPageParamEnums.PARTS_KITS,
    label: StockViewsEnums.PARTS_KITS,
    restrictedTo: PermissionEnum.StockPartsKits,
  },
  {
    value: StockPageParamEnums.PART_CATEGORIES,
    label: StockViewsEnums.PART_CATEGORIES,
    restrictedTo: PermissionEnum.StockPartCategories,
  },
  {
    value: StockPageParamEnums.CREATE_PART,
    label: StockViewsEnums.CREATE_PART,
    restrictedTo: PermissionEnum.StockCreateNewPart,
  },
];

export const purchaseTabModesOptions = Object.keys(StockTabParamEnum).map((key) => ({
  // eslint-disable-next-line
  // @ts-ignore
  label: StockTabParamEnum[key].charAt(0).toUpperCase() + StockTabParamEnum[key].slice(1),
  // eslint-disable-next-line
  // @ts-ignore
  value: StockTabParamEnum[key],
}));
