import React, { useEffect, useState } from 'react';
import Thead from '../../../core/components/table-components/Thead';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import PermissionsColumns from './PermissionsColumns';
import { useAppSelector } from '../../../store/hooks';
import {
  permissionTreeSelector, transformedPermissionRoles,
} from '../../../store/selectors/settingsSelectors';
import PermissionBody from './PermissionBody';
import { getPermissionNames } from '../../users-dashboard/utils/permissionHelpers';
import { CustomAny } from '../../../core/types/coreTypes';
import Tbody from '../../../core/components/table-components/Tbody';

export interface RoleTreeNode {
  fullName: string | null,
  role: number,
  roleLevel: number,
  permissions: string[],
  colSpan?: RoleTreeNode[],
}

type PermissionsTableProps = {
  permissionModule?: number;
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({ permissionModule }) => {
  const tree = useAppSelector(permissionTreeSelector);
  const roles = useAppSelector(transformedPermissionRoles);
  const [openedNodes, setOpenedNodes] = useState<string[]>([]);
  useEffect(() => {
    if (tree) {
      const names = getPermissionNames(tree[0]);
      names && setOpenedNodes(names);
    }
  }, [tree]);

  const openNodeHandler = (node: CustomAny) => {
    const { permission } = node;
    if (openedNodes.includes(permission)) {
      const names = getPermissionNames(node);
      const arr = openedNodes.filter((n) => !names.includes(n));
      setOpenedNodes(arr);
    } else {
      setOpenedNodes([...openedNodes, permission]);
    }
  };
  return (
    <TableNew className="permissions-table" disableHover>
      <Thead>
        <PermissionsColumns roles={roles} />
      </Thead>
      <Tbody>
        <PermissionBody
          tree={tree}
          openedNodes={openedNodes}
          roles={roles}
          openNodeHandler={openNodeHandler}
          permissionModule={permissionModule}
        />
      </Tbody>
    </TableNew>
  );
};

export default PermissionsTable;
