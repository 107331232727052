import React from 'react';
import { Rate } from 'antd';

type RateComponentProps = {
  count: number,
  value: number,
  disabled?: boolean,
  desc?: string[],
}

const RateComponent: React.FC<RateComponentProps> = ({
  disabled, count, value, desc,
}) => {
  return (
    <Rate disabled={disabled} value={value} count={count} tooltips={desc} />
  );
};

export default RateComponent;
