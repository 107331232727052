import React from 'react';
import Tbody from '../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../store/hooks';
import { reportIsVisibleSelector } from '../../../../../store/selectors/reportsSelectors';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { FullStockTableLine } from '../../../../types/FullStockReportTypes';

type FullStockBodyProps = {
  data: FullStockTableLine[],
  columnsLength: number,
}

const FullStockBody: React.FC<FullStockBodyProps> = ({ data, columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const nowrapKeys: Array<keyof FullStockTableLine> = [
    'free', 'freeWithoutSellingPrices', 'reserved', 'allocated', 'picked', 'collected', 'dueIn',
  ];

  const renderCellValue = (key: string, value: string | number) => {
    if (nowrapKeys.includes(key as keyof FullStockTableLine)) {
      return value;
    }
    return <TruncateTooltip value={value} table />;
  };

  return (
    <Tbody>
      {data.length > 0
        ? data.map((el) => (
          <Tr key={el.partCode}>
            {Object.entries(el).map(([key, value]) => {
              return <TdNew key={key} className={nowrapKeys.includes(key as keyof FullStockTableLine) ? 'nowrap' : ''}>
                {renderCellValue(key, value)}
              </TdNew>;
            })}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default FullStockBody;
