import { z } from 'zod';
import { OrderingType, ResponseResult } from '../../../core/types/coreTypes';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';

export type NominalCodesFilters = {
  types?: number[],
  code?: string,
  description?: string,
  page?: number,
  pageSize?: number,
  order?: OrderingType,
}

export type NominalCodeDto = {
  id: number,
  code: string,
  description: string,
  type: number,
  createdDate: string,
}

export type NominalCodeTypeCountDto = {
  type: number,
  count: number,
}

export type GetNominalCodesResponse = ResponseResult<NominalCodeDto[], { typeCounts: NominalCodeTypeCountDto[] }>

export type NominalCodeTableLine = {
  id: number,
  code: string,
  description: string,
  typeId: number,
  type: string,
  createdDate: string,
}

const RequiredStringSchema = z.string({ required_error: ErrorsEnum.REQUIRED });

export const NominalCodeFieldsScheme = z.object({
  code: RequiredStringSchema
    .length(4, maxLengthMessageHandle(4, false)),
  description: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(2000, maxLengthMessageHandle(2000)),
  type: z.number(),
});

export type NominalCodeFields = z.infer<typeof NominalCodeFieldsScheme>
