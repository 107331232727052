import qs from 'qs';
import instance from './axiosConfig';
import {
  FetchOrderCollectionsParamsType, GetOrderListResponse, GetOrdersResponseType, OrderViewsFilters,
  GetOrderResponse,
  SaveOrderLayoutSettingDto,
} from '../orders/dashboard/types/ordersTypes';
import {
  PatchOrderDto,
  NewContactReqDataType, OrderSummaryType, OrderContactDto, OrderLinksResType, OrderAffectedEntityDto,
} from '../orders/orders-view-page/types/OrderViewPageTypes';
import {
  AxiosCreatedAttachmentsResponse, AxiosCreatedIdResponse,
  AxiosCreateUploadUriResponse,
  AxiosDataListResponse,
  AxiosDownloadUriResponse, AxiosGenericResponse, AxiosGetAttachmentFileResponse, AxiosGetPagedAttachmentsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters, LinksFiltersType, PatchAttachmentModel, PostAttachReqType, UploadUriDto,
} from '../core/types/coreTypes';
import {
  OrderDictionaryFiltersType,
  ExtendedTimelineFilters, OrderDictionaryDto,
} from '../common/types/commonTypes';

export const ordersAPI = {
  fetchOrderDictionary(filters?: OrderDictionaryFiltersType, signal?: AbortSignal): AxiosSingleResponse<{ orders: OrderDictionaryDto[] }> {
    return instance.get('/order/orders/views/dictionary', {
      params: {
        'Filter.SearchParameters.Keyword': filters?.keyword,
        'Filter.SearchParameters.IncludeOrderNumber': filters?.includeOrderNumber,
        'Filter.SearchParameters.IncludeCustomerName': filters?.includeCustomerName,
        'Filter.SearchParameters.IncludeEnquiryNumbers': filters?.includeEnquiryNumbers,
        'Filter.OnlyInStatusAvailableForJobCreation': filters?.onlyInStatusAvailableForJobCreation,
        'Filter.NotInSupplyOnlyJobType': filters?.notInSupplyOnlyJobType,
        'Filter.OnlyActive': filters?.onlyActive,
      },
      signal,
    });
  },
  fetchOrdersCollections(params: FetchOrderCollectionsParamsType, signal?: AbortSignal): AxiosSingleResponse<GetOrdersResponseType> {
    return instance.get('/order/orders/views/dashboard', {
      params: {
        BusinessArea: params.businessArea,
        ApplyPersonalFilter: params.applyPersonalFilter,
        Statuses: params.statuses,
        'Filter.SearchParameters.Keyword': params.keyword,
        'Filter.SearchParameters.IncludeOrderNumber': params.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeCustomerName': params.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeEnquiryNumbers': params.keyword ? true : undefined,
        'Filter.PriorityLevels': params.priority,
        'Filter.Assignees': params.assignedTo,
        'Filter.JobTypes': params.jobType,
        'Filter.CustomerTypes': params.customerType,
        'Filter.Warnings': params.warnings,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  changeOrdersColumnVisibility(newLayout: Array<SaveOrderLayoutSettingDto>): AxiosNullableResponse {
    return instance.put('/order/orders/layout', { layoutSettings: newLayout });
  },
  changeOrderStatus(id: number, statusTransition: number, rejectionReason?: string): AxiosNullableResponse {
    return instance.put(`/order/orders/${id}/status`, { statusTransition, rejectionReason });
  },
  fetchOrderById(id: number): AxiosGenericResponse<GetOrderResponse> {
    return instance.get(`/order/orders/${id}`);
  },
  exportOrderToPdf(id: number, isProformaExportTemplate: boolean): AxiosDownloadUriResponse {
    return instance.get(`/order/orders/${id}/pdfFile`, {
      params: {
        isProformaExportTemplate,
      },
    });
  },
  fetchOrderAttachments(
    orderId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/order/orders/${orderId}/attachments`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  postOrderAttachments(orderId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/order/orders/${orderId}/attachments`, { objectId: orderId, attachments });
  },
  postOrderAttachmentUploadUri(orderId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/order/orders/${orderId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editOrderAttachment(orderId: number, id: number, data: PatchAttachmentModel): AxiosNullableResponse {
    return instance.patch(`/order/orders/${orderId}/attachments/${id}`, {
      comment: data.comment,
      showInLinkedJobs: data.showInLinkedJobs,
    });
  },
  deleteOrderAttachment(orderId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/order/orders/${orderId}/attachments/${id}`);
  },
  deleteMultipleOrderAttachment(orderId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/order/orders/${orderId}/attachments`, { data: { attachmentIds } });
  },
  getOrderAttachmentById(orderId: number, id: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/order/orders/${orderId}/attachments/${id}`);
  },
  patchOrder(id: number, data: PatchOrderDto): AxiosNullableResponse {
    return instance.patch(`/order/orders/${id}`, { order: { ...data } });
  },
  fetchOrderSummary(id: number): AxiosSingleResponse<OrderSummaryType> {
    return instance.get(`/order/orders/${id}/summary`);
  },
  fetchOrderContacts(
    orderId: number,
    page: number,
    pageSize: number,
    haveEmail?: boolean,
  ): AxiosDataListResponse<OrderContactDto[], { allItemIds: number[] }> {
    return instance.get(
      `/order/orders/${orderId}/contacts`,
      {
        params: {
          'PagingOptions.Page': page,
          'PagingOptions.ItemsPerPage': pageSize,
          'Filter.HaveEmail': haveEmail !== undefined ? haveEmail : null,
        },
      },
    );
  },
  createNewOrderContact(orderId: number, data: NewContactReqDataType): AxiosNullableResponse {
    return instance.post(`/order/orders/${orderId}/contacts/new`, { ...data });
  },
  addExistingOrderContacts(orderId: number, customerContactIds: number[]): AxiosNullableResponse {
    return instance.post(`/order/orders/${orderId}/contacts/existing`, { customerContactIds });
  },
  removeOrderContact(orderId: number, customerContactId: number): AxiosNullableResponse {
    return instance.delete(`/order/orders/${orderId}/contacts`, { data: { customerContactId } });
  },
  fetchOrderLinks(orderId: number, filters: LinksFiltersType): AxiosSingleResponse<OrderLinksResType> {
    return instance.get(`/order/orders/${orderId}/links`, {
      params: {
        'Filter.EntityName': filters.entity,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'Filter.ModifiedDate.From': filters.modifiedDate?.min,
        'Filter.ModifiedDate.To': filters.modifiedDate?.max,
        'OrderOptions.Field': filters.order.field,
        'OrderOptions.Ascending': filters.order.isAsc,
      },
    });
  },
  fetchOrderTimeline(orderId: number, filters: ExtendedTimelineFilters, source: number) {
    return instance.get('/order/orders/timeline', {
      params: {
        OrderId: orderId,
        MainOnly: filters.mainOnly,
        Source: source,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postOrderTimelineNote(orderId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/order/note', {
      orderId,
      content,
    });
  },
  editOrderTimelineNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/order/note/${id}`, { content });
  },
  deleteOrderTimelineNote(id: number): AxiosNullableResponse {
    return instance.delete(`/order/note/${id}`);
  },
  changeOrderCustomer(orderId: number, customerId: number): AxiosNullableResponse {
    return instance.put(`/order/orders/${orderId}/customer`, { customerId });
  },
  fetchOrderAffectedEntities(orderId: number, page: number, pageSize: number): AxiosDataListResponse<OrderAffectedEntityDto[]> {
    return instance.get(`/order/orders/${orderId}/affectedEntities`, {
      params: {
        'PagingOptions.Page': page,
        'PagingOptions.ItemsPerPage': pageSize,
      },
    });
  },
  fetchOrderViewsList(filters: OrderViewsFilters, signal?: AbortSignal): AxiosGenericResponse<GetOrderListResponse> {
    const hasDraftInvoiceDocuments = filters.isActive && filters.hasDraftInvoiceDocuments?.length
      ? filters.hasDraftInvoiceDocuments.map((el) => el === 1)
      : undefined;
    return instance.get('/order/orders/views/list', {
      params: {
        'IsActive': filters.isActive,
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeOrderNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeCustomerName': filters.keyword ? true : undefined,
        'Filter.Statuses': filters.statuses,
        'Filter.HasDraftInvoiceDocuments': hasDraftInvoiceDocuments,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'Filter.BusinessAreas': filters.businessAreas,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
};
