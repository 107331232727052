import React from 'react';
import { useForm, useFormState, Controller } from 'react-hook-form';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import LOGO from '../../assets/images/logo-transparent.png';
import { ErrorsEnum } from '../../core/enums/errorsEnum';
import Button from '../../core/components/button/Button';
import Input from '../../core/components/input/Input';
import InputPassword from '../../core/components/input-password/InputPassword';
import { loginThunk } from '../../store/thunks/account/accountThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { routesPath } from '../../core/enums/pathEnum';
import { isFetchingSelector } from '../../store/selectors/coreSelectors';
import { loginDefaults, LoginFields, LoginSchema } from '../utils/LoginShema';

const LoginForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const { pathname, search, state } = useLocation();
  const navigate = useNavigate();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const { backHandle } = useLocationHistoryState(false);
  const isFetching = useAppSelector(isFetchingSelector);
  const { handleSubmit, control, setError } = useForm<LoginFields>({
    resolver: zodResolver(LoginSchema),
    defaultValues: loginDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const onSubmit = (values: LoginFields) => {
    dispatch(loginThunk({
      ...values,
      setError,
      backHandle: () => {
        state ? navigate(state as string) : backHandle();
      },
    }));
  };
  return (
    <div className="login__form-wrapper">
      <img src={LOGO} alt="" className="login__logo" width={308} height={73} />
      <form onSubmit={handleSubmit(onSubmit)} className="login__form">
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              label="Email"
              error={errors?.email?.message}
              className="login__field"
            />
          )}
        />
        <Controller
          control={control}
          name="password"
          rules={{
            required: ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <InputPassword
              value={field.value}
              onChange={field.onChange}
              label="Password"
              error={errors?.password?.message}
              className="login__field"
            />
          )}
        />
        <Link
          onClick={() => setLocationHistoryHandle({ pathname, search })}
          className="login__forgot-link"
          to={`/${routesPath.FORGOT_PASSWORD}`}
        >
          Forgot password?
        </Link>
        <Button label="Sign In" htmlType="submit" designType="dark" isLoading={isFetching} />
      </form>
    </div>
  );
};

export default LoginForm;
