import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getCustomerAccountTypes,
  getCustomerTypes,
  getEnquirySources,
  getAccountAccountRoles,
  getAccountAccountTypes,
  getJobTypes,
  getPriorityLevels,
  getQuoteLinesTaxes,
  getPricelistTypes,
  getQuoteUnitsOfMeasure,
  getQuoteInfoSources,
  getBusinessAreas,
  getJobCategories,
  getAttachmentsTypes,
  getLinksTypesThunk,
  getUsersListCoreDictionary,
  getInAppNotificationsTypes,
  getInAppNotificationsEntityTypes,
  getPartRequestLinePriceSources,
  getVehicleProfileTypesThunk,
  getVehicleFlowsThunk, getPurchaseOrderEventSources,
  getQuoteLinePriceSources, getOrderEventSources,
} from '../../store/thunks/coreThunk';
import { isAuthSelector } from '../../store/selectors/coreSelectors';
import {
  getPartCategoriesThunk,
  getPartSubcategoriesThunk,
  getVehicleProfilesManufacturers,
  getVehicleProfileGroupsThunk,
} from '../../store/thunks/shared/sharedThunks';
import {
  getAccountAccountStatus,
  getCustomerStatuses,
  getEnquiryStatus,
  getInAppNotificationStatuses,
  getInfoMessageStatuses,
  getJobStatusesThunk,
  getOrderStatus,
  getPartsKitsStatuses,
  getPricelistStatuses, getPurchaseOrderStatus, getQuantitiesStatuses, getQuotePricelistStatuses, getRectificationStatusesThunk,
  getVehicleStatuses,
} from '../../store/thunks/core/coreStatusesThunks';
import {
  getAttachmentsOrdering, getCustomerContactsOrdering, getInAppNotificationsOrdering, getQuantitiesOrdering,
  getStockPartRequestLinesOrdering,
  getVehicleProfilesOrderingThunk, getVehiclesOrderingThunk,
} from '../../store/thunks/core/coreOrderingThunks';

export const useDictionaries = () => {
  const dispatch = useAppDispatch();
  const isAuth = useAppSelector(isAuthSelector);
  useEffect(() => {
    if (isAuth) {
      dispatch(getPurchaseOrderStatus());
      dispatch(getBusinessAreas());
      dispatch(getEnquiryStatus());
      dispatch(getCustomerAccountTypes());
      dispatch(getCustomerTypes());
      dispatch(getCustomerStatuses());
      dispatch(getCustomerContactsOrdering());
      dispatch(getJobTypes());
      dispatch(getPriorityLevels());
      dispatch(getEnquirySources());
      dispatch(getQuoteLinesTaxes());
      dispatch(getAccountAccountTypes());
      dispatch(getAccountAccountRoles());
      dispatch(getAccountAccountStatus());
      dispatch(getQuoteUnitsOfMeasure());
      dispatch(getQuotePricelistStatuses());
      dispatch(getQuoteInfoSources());
      dispatch(getInfoMessageStatuses());
      dispatch(getPricelistStatuses());
      dispatch(getPricelistTypes());
      dispatch(getPartCategoriesThunk({ filters: undefined }));
      dispatch(getPartSubcategoriesThunk());
      dispatch(getPartsKitsStatuses());
      dispatch(getUsersListCoreDictionary({ keyword: undefined }));
      dispatch(getOrderStatus());
      dispatch(getJobCategories());
      dispatch(getAttachmentsTypes());
      dispatch(getAttachmentsOrdering());
      dispatch(getLinksTypesThunk());
      dispatch(getJobStatusesThunk());
      dispatch(getRectificationStatusesThunk());
      dispatch(getVehicleProfileTypesThunk());
      dispatch(getVehicleProfileGroupsThunk({ params: undefined }));
      dispatch(getVehicleProfilesOrderingThunk());
      dispatch(getVehicleStatuses());
      dispatch(getVehicleFlowsThunk());
      dispatch(getQuoteLinePriceSources());
      dispatch(getInAppNotificationsTypes());
      dispatch(getInAppNotificationsEntityTypes());
      dispatch(getInAppNotificationsOrdering());
      dispatch(getInAppNotificationStatuses());
      dispatch(getPartRequestLinePriceSources());
      dispatch(getVehicleProfilesManufacturers({ keyword: undefined }));
      dispatch(getStockPartRequestLinesOrdering());
      dispatch(getVehiclesOrderingThunk());
      dispatch(getQuantitiesStatuses());
      dispatch(getPurchaseOrderEventSources());
      dispatch(getOrderEventSources());
      dispatch(getQuantitiesOrdering());
    }
    // eslint-disable-next-line
  }, [isAuth]);
};
