import React, { useEffect, useState } from 'react';
import { CustomAny, initDataList, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import './AllocatePricelistQtyModal.scss';
import Thead from '../../../../../core/components/table-components/Thead';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../core/components/table-components/Tr';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { allocatePricelistColumns } from '../../../utils/data';
import QuantityComponent from '../../../../../common/components/select-product-source-modal/QuantityComponent';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import {
  AllocatePricelistQuantityDto,
} from '../../../../stock-part-requests/types/stockPartRequestTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  partQtyStatusesValuesSelector,
} from '../../../../../store/selectors/coreStatusesSelectors';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import {
  getAllocatePricelistPartQuantities,
  postAllocatePricelistQuantityThunk,
} from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import {
  isFetchingSelector,
  pcsMeasureSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../store/selectors/coreSelectors';
import {
  setAllocatedPartQuantities, setAllocatePartQuantitiesFilters,
} from '../../../../../store/slices/stockPartRequestSlice';
import { allocatedPartQuantitiesUiSelector } from '../../../../../store/selectors/stockPartRequestsSelector';
import { PartRequestLineDto } from '../../../../../common/types/commonTypes';

type AllocatePricelistQtyModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  sourceId?: number,
  currentLine?: PartRequestLineDto | null,
  isLinesView?: boolean,
}

const AllocatePricelistQtyModal: React.FC<AllocatePricelistQtyModalProps> = ({
  visible, onCancel, currentLine, sourceId, isLinesView,
}) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const allocatedPartQuantities = useAppSelector(allocatedPartQuantitiesUiSelector);
  const { reserved } = useAppSelector(partQtyStatusesValuesSelector);
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const pcsMeasure = useAppSelector(pcsMeasureSelector);
  const [values, setValues] = useState<Array<AllocatePricelistQuantityDto>>([]);

  useEffect(() => {
    const partId = currentLine?.partId || currentLine?.partsKitComponentPartId;
    const partRequestId = currentLine?.partRequestId;
    const isAllConditions = partRequestId && partId && reserved && visible;
    isAllConditions && dispatch(getAllocatePricelistPartQuantities({
      partId,
      filters: {
        statuses: [reserved],
        order: { field: 1, isAsc: true },
        isAvailableForPartRequestId: partRequestId,
      },
    }));
    // eslint-disable-next-line
  }, [currentLine, visible, reserved]);

  useEffect(() => {
    const list = allocatedPartQuantities.items?.map((el) => ({ quantity: 0, partQuantityId: el.id, remaining: el.remaining }));
    setValues(list || []);
  }, [allocatedPartQuantities]);

  const setValuesHandle = (value: string, quantityId: number) => {
    const newList = values.map((el) => {
      if (el.partQuantityId === quantityId) {
        return {
          ...el,
          quantity: +value,
        };
      } else {
        return el;
      }
    });
    setValues(newList);
  };

  const nowrapValues = ['remaining', 'costPrice', 'sellingPrice', 'addedDate'];
  const toBeAllocated = currentLine?.toBeAllocatedQuantity;
  const allocateUnitOfMeasure = unitOfMeasures.find((el) => el.value === currentLine?.unitOfMeasure)?.label;

  const renderCellContent = (key: string, value: CustomAny, quantityId: number) => {
    const currentQty = values.find((el) => el.partQuantityId === quantityId)?.quantity || 0;
    const currentRemaining = values.find((el) => el.partQuantityId === quantityId)?.remaining || 0;
    if (key === 'purchaseOrderNumber') {
      return <TruncateTooltip
        value={value as string}
        table
      />;
    }
    if (key === 'quantity') {
      return <QuantityComponent
        value={currentQty.toString()}
        setValue={(v) => {
          setValuesHandle(v, quantityId);
        }}
        isDecimals={pcsMeasure !== currentLine?.unitOfMeasure}
        disabledPlus={currentQty >= currentRemaining}
        maxInputValue={currentRemaining}
      />;
    }
    if (key === 'remaining') {
      const currentQty = values.find((el) => el.partQuantityId === quantityId)?.quantity || 0;
      const currentRemaining = values.find((el) => el.partQuantityId === quantityId)?.remaining || 0;

      return `${currentRemaining - currentQty} ${allocateUnitOfMeasure || ''}`;
    } else return value;
  };

  const cancelModalHandle = () => {
    onCancel();
    dispatch(setAllocatedPartQuantities(initDataList));
    dispatch(setAllocatePartQuantitiesFilters(undefined));
    setValues([]);
  };

  const allocateHandle = () => {
    const partId = currentLine?.partId || currentLine?.partsKitComponentPartId;
    const partRequestId = currentLine?.partRequestId;
    partRequestId && sourceId && partId && dispatch(postAllocatePricelistQuantityThunk({
      partRequestId,
      lineId: sourceId,
      partId,
      data: values.map((el) => ({ quantity: el.quantity, partQuantityId: el.partQuantityId })),
      closeModal: cancelModalHandle,
      isLinesView,
    }));
  };

  const valuesSum = values.length > 0 ? values.map((el) => el.quantity).reduce((a, b) => a + b) : 0;
  const disabledCreateBtn = !valuesSum || toBeAllocated !== valuesSum;

  return (
    <Modal
      visible={visible}
      onCancel={cancelModalHandle}
      width={900}
      destroyOnClose
      title="Allocate pricelist quantity"
    >
      <div className="allocatePricelistQtyWrap">
        <span>Requested pricelist quantity and cost prices:</span>
        <ul className="allocatePricelistQtyWrap__list">
          {currentLine?.confirmedPricelists?.map((el) => <li key={el.id}>
            {el.quantity}
            {' '}
            {allocateUnitOfMeasure}
            {' '}
            for £
            {' '}
            {el.unitPrice}
          </li>)}
        </ul>
        <span>
          Allocate
          {' '}
          {toBeAllocated}
          {' '}
          {allocateUnitOfMeasure}
          {' '}
          from available reserved quantity:
        </span>
        <TableNew small className="allocatePricelistQtyWrap__table">
          <Thead>
            {allocatePricelistColumns.map((col) => (
              <ThNew alignCenter key={col.dataIndex} className={`allocatePricelistQtyTh--${col.dataIndex}`}>{col.title}</ThNew>
            ))}
          </Thead>
          <Tbody>
            {allocatedPartQuantities.totalCount > 0
              ? allocatedPartQuantities.items?.map((cell: CustomAny, i: number) => <Tr key={i}>
                {Object.entries(cell).map(([key, value]) => {
                  if (key === 'id') {
                    return null;
                  } else {
                    return <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
                      {renderCellContent(key, value, cell.id)}
                    </TdNew>;
                  }
                })}
              </Tr>)
              : <EmptyTableSection text="No records" colSpan={allocatePricelistColumns.length} />}
          </Tbody>
        </TableNew>
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={onCancel}
          createLabel="Allocate"
          createClick={allocateHandle}
          offsetTop
          disabledCreate={disabledCreateBtn}
          isLoading={isFetching}
        />
      </div>
    </Modal>
  );
};

export default AllocatePricelistQtyModal;
