import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen } from '@fortawesome/free-solid-svg-icons';

const EmptyComponentsList: React.FC = () => {
  return (
    <div className="componentsWrap__empty">
      <FontAwesomeIcon icon={faBoxOpen} className="componentsWrap__empty-box" />
      <span className="componentsWrap__empty-text">Please add components to this parts kit</span>
    </div>
  );
};

export default EmptyComponentsList;
