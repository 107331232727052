import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  salesReportByCustomerDateFiltersSelector, salesByCustomerFiltersSelector,
  salesReportByCustomerGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { SalesReportByCustomerOrdering } from '../../../../enum/ReportTableOrders';
import {
  setReportIsVisible,
  setSalesByCustomerData,
  setSalesByCustomerFilters,
} from '../../../../../store/slices/reportsSlice';
import { getSalesByCustomerReportOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { salesByCustomerReportOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { initSalesReportByCustomerData } from '../../../reports-content/sales/sales-report-by-customer/utils/data';
import { DateRangeTableFilterType, PickerValues } from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { getSalesByCustomerEntityTypesThunk } from '../../../../../store/thunks/coreThunk';
import { getSalesByCustomerReportThunk } from '../../../../../store/thunks/reports/reportsThunks';

const SalesReportByCustomerGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const ordering = useAppSelector(salesByCustomerReportOrderingSelector);
  const checkboxFilters = useAppSelector(salesReportByCustomerGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(salesReportByCustomerDateFiltersSelector);
  const filters = useAppSelector(salesByCustomerFiltersSelector);
  const [isExpanded, setIsExpanded] = useState(false);

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters,
    initialOrdering: SalesReportByCustomerOrdering.CompletedDate,
    setFiltersAction: setSalesByCustomerFilters,
    getOrderingThunk: getSalesByCustomerReportOrderingThunk,
    ordering,
  });

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        entityTypes, customerIds, billingCustomerIds, shippingCustomerIds, businessAreas, jobTypes, completedDate,
      } = filters;
      const checkboxes = [entityTypes, customerIds, billingCustomerIds, shippingCustomerIds, businessAreas, jobTypes];
      const hasSelectedCheckboxes = checkboxes.some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = completedDate && (completedDate.min || completedDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker;
    }
    return false;
  }, [filters]);

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getSalesByCustomerEntityTypesThunk());

    return () => {
      dispatch(setSalesByCustomerFilters(defaultPagingAndOrdering));
      dispatch(setSalesByCustomerData(initSalesReportByCustomerData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    completedDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    dispatch(setSalesByCustomerFilters({
      ...filters,
      [field]: values,
    }));
    dispatch(setSalesByCustomerData(initSalesReportByCustomerData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setSalesByCustomerFilters(defaultPagingAndOrdering));
    dispatch(setSalesByCustomerData(initSalesReportByCustomerData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setSalesByCustomerFilters({
      ...filters,
      salesNet: undefined,
      costNet: undefined,
      differenceNet: undefined,
      entityNumber: undefined,
      [field]: values,
    }));
    dispatch(setReportIsVisible(false));
    dispatch(setSalesByCustomerData(initSalesReportByCustomerData));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getSalesByCustomerReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          disabled
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: () => console.log('dispatch excel data'),
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default SalesReportByCustomerGlobalFilters;
