import { useEffect, useState } from 'react';
import { getUsersListCoreDictionary } from '../../store/thunks/coreThunk';
import { useAppDispatch } from '../../store/hooks';

export const useUsersDictionary = () => {
  const dispatch = useAppDispatch();
  const [userSearch, setUserSearch] = useState<string | undefined>(undefined);
  const getUsersFn = (keyword?: string) => {
    dispatch(getUsersListCoreDictionary({ keyword }));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (userSearch) {
      const timeOutId = setTimeout(() => {
        getUsersFn(userSearch.trim());
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      getUsersFn(undefined);
    }
    // eslint-disable-next-line
    }, [userSearch]);

  return {
    userSearch,
    setUserSearch,
    getUsersFn,
  };
};
