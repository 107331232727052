import { z } from 'zod';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';

export const PurchaseDetailsSchema = z.object({
  description: z.nullable(z.string().max(2000, maxLengthMessageHandle(2000))),
  purchaseOrderDate: z.string(),
  dueDate: z.string(),
  fao: z.nullable(z.string().max(100, maxLengthMessageHandle(100))),
  specialInstructions: z.nullable(z.string().max(2000, maxLengthMessageHandle(2000))),
});

export const PurchaseDetailsFields = PurchaseDetailsSchema.pick({
  description: true,
  purchaseOrderDate: true,
  dueDate: true,
  fao: true,
  specialInstructions: true,
});

export type Purchase = z.infer<typeof PurchaseDetailsFields>;

export const purchaseDetailsDefaults: Purchase = {
  description: null,
  purchaseOrderDate: '',
  dueDate: '',
  fao: '',
  specialInstructions: '',
};
