import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Switch from '../../../../../core/components/switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isJobStatusCompletedSelector,
  jobTimelineFiltersSelector,
  jobTimelineSelector,
} from '../../../../../store/selectors/jobsSelector';
import { setJobTimelineFilters } from '../../../../../store/slices/jobsSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import { getJobTimelineThunk, postJobTimelineNoteThunk } from '../../../../../store/thunks/jobs/jobThunks';
import JobEvent from './job-event-description/JobEvent';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

const JobActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, totalCount } = useAppSelector(jobTimelineSelector);
  const timelineFilters = useAppSelector(jobTimelineFiltersSelector);
  const isJobCompleted = useAppSelector(isJobStatusCompletedSelector);
  const hasPermission = usePermission(PermissionEnum.JobViewPage);

  const allowedToAddNote = hasPermission && !isJobCompleted;

  const [value, setValue] = useState('');

  useEffect(() => {
    id && dispatch(getJobTimelineThunk({
      jobId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
  }, [timelineFilters]);

  const addNote = () => {
    id && dispatch(postJobTimelineNoteThunk({
      jobId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <JobEvent item={el} />,
  }));

  return <section className="detailsWrap__activity info-grid__column">
    <div className="info-grid__title-wrapper">
      <h2 className="info-grid__title-inner">Activity</h2>
      <Switch
        checked={!timelineFilters.mainOnly}
        onChange={(value) => {
          dispatch(setJobTimelineFilters({
            ...timelineFilters,
            mainOnly: !value,
            page: 1,
          }));
        }}
        textLeft="Main events"
        textRight="Full log"
        singleBackground
      />
    </div>
    <Pagination
      currentPage={timelineFilters.page}
      totalItems={totalCount}
      pageSize={timelineFilters.pageSize}
      onChange={(page) => {
        dispatch(setJobTimelineFilters({
          ...timelineFilters,
          page,
        }));
      }}
      showSizeChanger={false}
      showTotal={false}
    />
    <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
    <ActivityNoteInput
      value={value}
      setValue={setValue}
      addNote={addNote}
      disabledInput={!allowedToAddNote}
    />
  </section>;
};

export default JobActivitySection;
