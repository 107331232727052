import React, { useCallback, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleLeft, faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';
import { JobCalendarTaskDto } from '../types/jobCalendarTypes';
import JobLineTooltipContent from './JobLineTooltipContent';
import Button from '../../../core/components/button/Button';

type MultipleJobTipContentProps = {
  jobs: JobCalendarTaskDto[],
}

const MultipleJobTipContent: React.FC<MultipleJobTipContentProps> = ({ jobs }) => {
  const [currentEl, setCurrentEl] = useState(0);
  const currentElInfo = useCallback(() => jobs.find((job, index) => index === currentEl), [currentEl, jobs]);
  return (
    <>
      <div className="jobTooltip__buttons-wrap">
        <Button
          icon={<FontAwesomeIcon icon={faArrowCircleLeft} />}
          onClick={() => setCurrentEl(currentEl - 1)}
          disabled={!currentEl}
          type="text"
          designType="light-inverse"
          className="jobTooltip__button"
        />
        <Button
          icon={<FontAwesomeIcon icon={faArrowCircleRight} />}
          onClick={() => setCurrentEl(currentEl + 1)}
          disabled={currentEl === (jobs.length - 1)}
          type="text"
          designType="light-inverse"
          className="jobTooltip__button"
        />
      </div>
      <JobLineTooltipContent
        info={currentElInfo() || null}
        multipleModeText={` (${currentEl + 1} of ${jobs.length})`}
      />
    </>
  );
};

export default MultipleJobTipContent;
