import React from 'react';
import JobParts from '../../../../job-view-page/components/job-content/parts/JobParts';

type JobLineExpandedPartsProps = {
  jobId: number,
}
const JobLineExpandedParts: React.FC<JobLineExpandedPartsProps> = ({ jobId }) => {
  return (
    <div>
      <JobParts childJobId={jobId} />
    </div>
  );
};

export default JobLineExpandedParts;
