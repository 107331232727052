import React from 'react';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { SourceConfirmedDataType } from '../../../../stock-part-requests/types/stockPartRequestTypes';
import { isGlobalLoadingSelector, QuoteUnitsOfMeasureSelector } from '../../../../../store/selectors/coreSelectors';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { deleteStockPartRequestLineThunk } from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';

type ConfirmReturnedPartsModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  partRequestId?: number,
  sourceId?: number,
  sourceConfirmedData: SourceConfirmedDataType | null,
  isLinesView?: boolean,
}

const ConfirmReturnedPartsModal: React.FC<ConfirmReturnedPartsModalProps> = ({
  isVisible, onCancel, sourceId, sourceConfirmedData, partRequestId, isLinesView,
}) => {
  const dispatch = useAppDispatch();
  const unitOfMeasures = useAppSelector(QuoteUnitsOfMeasureSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const getMeasureLabel = () => unitOfMeasures.find((unit) => unit.value === sourceConfirmedData?.unitOfMeasure)?.label;
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      width={800}
      destroyOnClose
      title="Confirm returned parts"
    >
      <>
        <div className="confirmationText">
          <span>Please confirm that the following quantity has been returned back to stock:</span>
          <span>
            Allocated -
            {' '}
            {sourceConfirmedData?.allocatedQuantity}
            {' '}
            {getMeasureLabel()}
          </span>
          <span>
            Ready -
            {' '}
            {sourceConfirmedData?.readyQuantity === '-' ? 0 : sourceConfirmedData?.readyQuantity}
            {' '}
            {getMeasureLabel()}
          </span>
          <span>
            Collected -
            {' '}
            {sourceConfirmedData?.collectedQuantity === '-' ? 0 : sourceConfirmedData?.collectedQuantity}
            {' '}
            {getMeasureLabel()}
          </span>
          <span>
            After confirmation the quantity above will be marked as free and will become available in stock again.
            {' '}
            This part line will be removed from part request.
          </span>
          <span className="confirmationText__proceed">Do you want to proceed?</span>
        </div>
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={onCancel}
          createLabel="Yes, confirm"
          createClick={() => {
            partRequestId && sourceId && dispatch(deleteStockPartRequestLineThunk({
              partRequestId,
              lineId: sourceId,
              closeModal: onCancel,
              isLinesView,
            }));
          }}
          isLoading={isLoading}
        />
      </>
    </Modal>
  );
};

export default ConfirmReturnedPartsModal;
