import { initDataList } from '../../../../../../core/types/coreTypes';
import { FitmentTimeData } from '../../../../../types/FitmentTimeReportTypes';

export const initFitmentTimeData: FitmentTimeData = {
  ...initDataList,
  minSpentByAssigneeMinutes: 0,
  maxSpentByAssigneeMinutes: 0,
  minTotalSpentMinutes: 0,
  maxTotalSpentMinutes: 0,
  minTotalEstimatedMinutes: 0,
  maxTotalEstimatedMinutes: 0,
  minDifferenceMinutes: 0,
  maxDifferenceMinutes: 0,
};

export const FITMENT_TIME_CHART_COUNT_OPTIONS = [15, 20, 25];
