import React, { useEffect } from 'react';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InfiniteScroll from 'react-infinite-scroll-component';
import { UseFormReset } from 'react-hook-form';
import {
  enquiryAcceptedOrRejectedSelector,
  enquiryContactsPagingSelector,
  enquiryContactsSelector,
  enquiryDetailsSelector,
  enquiryExistedContactsPagingSelector,
  existedContactsSelector,
} from '../../../../../store/selectors/enquiriesSelector';
import { useAppSelector } from '../../../../../store/hooks';
import {
  addExistingContactsThunk,
  createNewContactThunk,
  getExistingContactsThunk,
  removeEnquiryContactThunk,
} from '../../../../../store/thunks/enquiry/enquiryContactsThunks';
import ContactLine from '../../../../../common/components/contacts/ContactLine';
import Button from '../../../../../core/components/button/Button';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import AddContactModal from '../../../../../common/components/contacts/AddContactModal';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import {
  setEnquiryContactsPaging,
  setEnquiryExistedContactsPaging,
  setExistedContacts,
} from '../../../../../store/slices/enquiriesSlice';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import { initAddressFields } from '../../../../../common/types/commonTypes';
import AddressModal from '../../../../../common/components/address/AddressModal';
import {
  infiniteScrollInitPaging, initDataListWithIds, PagingType,
} from '../../../../../core/types/coreTypes';
import { createContactUtil } from '../../../../../common/utils/createContactUtils';
import { customerDefaultContactsOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { useAddContact } from '../../../../../common/components/contacts/useAddContact';
import { NewContactFormFields } from '../../../../../common/types/createNewContactSchema';

const EnquiryContacts: React.FC = () => {
  const enquiryContacts = useAppSelector(enquiryContactsSelector);
  const enquiryContactsPaging = useAppSelector(enquiryContactsPagingSelector);
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const enquiryDetails = useAppSelector(enquiryDetailsSelector);
  const enquiryExistedContacts = useAppSelector(existedContactsSelector);
  const enquiryExistedPaging = useAppSelector(enquiryExistedContactsPagingSelector);
  const defaultExistedContactsOrdering = useAppSelector(customerDefaultContactsOrderingSelector);

  const allowedManage = usePermission(PermissionEnum.EnquiryEditFields);

  const {
    id,
    dispatch,
    isRemove,
    setIsRemove,
    lineId,
    setLineId,
    addContact,
    setAddContact,
    isLocation,
    setLocation,
    addressFields,
    setAddressFields,
    mode,
    setMode,
  } = useAddContact();

  const loadMore = () => {
    const newValues = { ...enquiryContactsPaging, page: enquiryContactsPaging.page + 1 };
    dispatch(setEnquiryContactsPaging(newValues));
  };

  const onCancel = () => {
    setMode('');
    dispatch(setEnquiryExistedContactsPaging({ ...enquiryExistedPaging, page: 1 }));
    dispatch(setExistedContacts(initDataListWithIds));
  };
  const closeModal = () => {
    setAddressFields(initAddressFields);
    setAddContact(false);
    onCancel();
  };

  const createNewContact = (values: NewContactFormFields, reset: UseFormReset<NewContactFormFields>) => {
    const { location, valuesCopy } = createContactUtil(addressFields, values);
    id && dispatch(createNewContactThunk({
      data: {
        enquiryId: +id,
        ...location,
        ...valuesCopy,
      },
      closeModal: () => {
        setMode('');
        setAddressFields(initAddressFields);
        reset();
        setAddContact(false);
      },
    }));
  };

  const getExistingContacts = () => {
    enquiryDetails?.enquiry && defaultExistedContactsOrdering && dispatch(getExistingContactsThunk({
      customerId: enquiryDetails.enquiry.customerId,
      filters: { ...enquiryExistedPaging, order: defaultExistedContactsOrdering },
      notAddedToEnquiryId: enquiryDetails.enquiry.id,
    }));
  };

  const addSelectedContacts = (customerContactIds: number[]) => {
    enquiryDetails?.enquiry && dispatch(addExistingContactsThunk({
      enquiryId: enquiryDetails.enquiry.id,
      customerId: enquiryDetails.enquiry.customerId,
      customerContactIds,
      closeModal,
    }));
  };

  const setExistedContactsPaging = (filters: PagingType) => {
    dispatch(setEnquiryExistedContactsPaging(filters));
  };

  useEffect(() => {
    return () => {
      dispatch(setEnquiryContactsPaging({ ...infiniteScrollInitPaging }));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConfirmationModal
        isVisible={isRemove}
        onCancel={() => {
          setIsRemove(false);
          setLineId(undefined);
        }}
        isDelete={false}
        confirmHandler={() => id && lineId && dispatch(removeEnquiryContactThunk({
          enquiryId: +id,
          customerContactId: lineId,
          closeModal: () => {
            setIsRemove(false);
            setLineId(undefined);
          },
        }))}
      />
      <AddContactModal
        isVisible={addContact}
        onCancel={onCancel}
        closeModal={closeModal}
        addressFields={addressFields}
        setLocation={setLocation}
        setAddressFields={setAddressFields}
        companyNameValue={enquiryDetails?.enquiry?.customerShortName || ''}
        createNewContact={createNewContact}
        mode={mode}
        setMode={setMode}
        existedContacts={enquiryExistedContacts}
        getExistingContacts={getExistingContacts}
        existingPaging={enquiryExistedPaging}
        addSelectedContacts={addSelectedContacts}
        setExistedContactsPaging={setExistedContactsPaging}
      />
      <AddressModal
        isVisible={isLocation}
        onCancel={() => {
          setLocation(false);
        }}
        addressFields={addressFields}
        onSubmit={(values) => {
          setAddressFields(values);
          setLocation(false);
        }}
        isExtendedFields={false}
      />
      <section className="contacts">
        {allowedManage && <Button
          label="Add contact"
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          htmlType="button"
          disabled={enquiryAcceptedOrRejected}
          onClick={() => setAddContact(true)}
          designType="dark"
          reversed
        />}
        <div className="contacts__wrapper">
          {enquiryContacts.items?.length > 0
            ? <InfiniteScroll
              dataLength={enquiryContacts.items.length}
              next={loadMore}
              hasMore={enquiryContactsPaging.page < enquiryContacts.pages}
              loader={<span className="contacts__loader">Loading...</span>}
            >
              <ul className="lines-list">
                {enquiryContacts.items.map((line) => (
                  <li
                    className="lines-list__item"
                    key={line.id}
                  >
                    <ContactLine
                      setIsRemove={(id) => {
                        setIsRemove(true);
                        setLineId(id);
                      }}
                      line={line}
                      disabled={enquiryAcceptedOrRejected}
                      deleteContactPermission={PermissionEnum.EnquiryEditFields}
                    />
                  </li>))}
              </ul>
            </InfiniteScroll>

            : <EmptyContentList icon={faAddressCard} text="Please add a contact to this enquiry" />}
        </div>
      </section>
    </>
  );
};

export default EnquiryContacts;
