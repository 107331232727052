export enum SkillsOrderingEnum {
  Item = 'Item',
  CreatedDate = 'CreatedDate',
  ModifiedDate = 'ModifiedDate',
}

export enum AffectedEmployeeOrderingEnum {
  LastName = 'LastName',
  Email = 'Email',
}

export enum EmployeeSkillsModalOrdering {
  Category = 1,
  SkillItem,
}
