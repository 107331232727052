import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  CustomerDto,
  CustomersDashboardFiltersType, GetCustomerResponseType,
} from '../../customers/customers-dashboard/types/customersDashboardTypes';
import { getCustomers } from '../thunks/customers/customersDashboardThunks';
import {
  CoreAttachmentDto,
  CoreAttachmentsFilters, CoreAttachmentsList, DataListType, GetAttachmentsResponse, infiniteScrollInitPaging, initDataListWithIds,
  initDataList, initialCoreAttachmentsFilters, initPaging,
  PagingType, ResponseResult, ResponseSingleResult,
} from '../../core/types/coreTypes';
import {
  getCustomerActivityLog,
  getCustomerById,
} from '../thunks/customers/customersViewPageThunks';
import { ContactDto } from '../../common/types/commonTypes';
import {
  CustomerActivityEventDto,
  CustomerContactFiltersType,
} from '../../customers/customer-view-page/types/customerVewPageTypes';
import { getCustomerAttachments } from '../thunks/customers/customersAttachmentsThunks';
import { getCustomerContactsList } from '../thunks/customers/customerContactsThunks';

export interface ICustomersState {
  filters: CustomersDashboardFiltersType,
  customersList: DataListType<CustomerDto[]>,
  customersDetails: GetCustomerResponseType | null,
  customerAttachments: CoreAttachmentsList,
  customerAttachmentsFilters: CoreAttachmentsFilters,
  customerActivityLog: DataListType<CustomerActivityEventDto[]>,
  customerActivityLogFilters: PagingType,
  customerContactsFilters: CustomerContactFiltersType,
  customerContactsList: DataListType<ContactDto[]>,
}

export const initialCustomersState: ICustomersState = {
  filters: initPaging,
  customersList: initDataList,
  customersDetails: null,
  customerAttachments: initDataListWithIds,
  customerAttachmentsFilters: initialCoreAttachmentsFilters,
  customerActivityLog: initDataList,
  customerActivityLogFilters: initPaging,
  customerContactsFilters: infiniteScrollInitPaging,
  customerContactsList: initDataList,
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState: initialCustomersState,
  reducers: {
    setCustomersDashFilters: (state, action: PayloadAction<CustomersDashboardFiltersType>) => {
      state.filters = action.payload;
    },
    setCustomers: (state, action: PayloadAction<DataListType<CustomerDto[]>>) => {
      state.customersList = action.payload;
    },
    setCustomerDetails: (state, action: PayloadAction<GetCustomerResponseType | null>) => {
      state.customersDetails = action.payload;
    },
    setCustomerAttachments: (state, action: PayloadAction<CoreAttachmentDto[]>) => {
      state.customerAttachments = { ...state.customerAttachments, items: action.payload };
    },
    setCustomerAttachmentsFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.customerAttachmentsFilters = action.payload;
    },
    setCustomerActivityLog: (state, action: PayloadAction<DataListType<CustomerActivityEventDto[]>>) => {
      state.customerActivityLog = action.payload;
    },
    setCustomerActivityLogFilters: (state, action: PayloadAction<PagingType>) => {
      state.customerActivityLogFilters = action.payload;
    },
    setCustomerContactsFilters: (state, action: PayloadAction<CustomerContactFiltersType>) => {
      state.customerContactsFilters = action.payload;
    },
    setCustomerContactsList: (state, action: PayloadAction<DataListType<ContactDto[]>>) => {
      state.customerContactsList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomers.fulfilled, (state, action: PayloadAction<ResponseResult<CustomerDto[] | null>>) => {
      const { items, pages, totalCount } = action.payload.data;
      if (items) {
        state.customersList = { items, pages, totalCount };
      }
    });
    builder.addCase(getCustomerById.fulfilled, (state, action: PayloadAction<ResponseSingleResult<GetCustomerResponseType | null>>) => {
      state.customersDetails = action.payload.data;
    });
    builder.addCase(getCustomerAttachments.fulfilled, (state, action: PayloadAction<GetAttachmentsResponse>) => {
      state.customerAttachments = action.payload.data;
    });
    builder.addCase(getCustomerActivityLog.fulfilled, (state, action: PayloadAction<ResponseResult<CustomerActivityEventDto[]> | null>) => {
      state.customerActivityLog = action.payload ? { ...action.payload.data } : initDataList;
    });
    builder.addCase(getCustomerContactsList.fulfilled, (state, action: PayloadAction<ResponseResult<ContactDto[]> | null>) => {
      if (action.payload) {
        const { items, pages, totalCount } = action.payload.data;
        state.customerContactsList = {
          items: state.customerContactsFilters.page > 1
            ? [...state.customerContactsList.items, ...items]
            : items,
          pages,
          totalCount,
        };
      } else {
        state.customerContactsList = initDataList;
      }
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setCustomersDashFilters,
  setCustomerDetails,
  setCustomerAttachments,
  setCustomerAttachmentsFilters,
  setCustomerActivityLogFilters,
  setCustomerActivityLog,
  setCustomerContactsFilters,
  setCustomers,
  setCustomerContactsList,
} = customersSlice.actions;

export default customersSlice.reducer;
