import React from 'react';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import Button from '../../../core/components/button/Button';

type ForgotPasswordInfoProps = {
  email: string,
  tryAgainClick: VoidFunctionType,
}

const ForgotPasswordInfo:React.FC<ForgotPasswordInfoProps> = ({ email, tryAgainClick }) => {
  return (
    <>
      <p className="forgot-password__info-text">
        <span>
          {'We\'ve sent an email to '}
          <b>{email}</b>
          .
        </span>
        <span>
          Please follow the password reset instructions in the email.
        </span>
      </p>
      <Button
        label="Try again"
        htmlType="button"
        designType="dark"
        onClick={tryAgainClick}
      />
    </>
  );
};

export default ForgotPasswordInfo;
