import React from 'react';
import { DraftLinePartList, DraftLinePartsFiltersType } from '../../types/commonTypes';
import Tbody from '../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import PartsModeTableCells from './PartsModeTableCells';

type PartsModeBodyProps = {
  parts: DraftLinePartList,
  searchValue?: string,
  setProdSourceModal: (id: number, name: string) => void,
  filters: DraftLinePartsFiltersType,
}

const PartsModeBody: React.FC<PartsModeBodyProps> = ({
  parts, setProdSourceModal, searchValue, filters,
}) => {
  return (
    <Tbody>
      {parts.totalCount === 0
        ? <EmptyTableSection
          colSpan={6}
          text={searchValue && parts.totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
        />
        : <PartsModeTableCells
          parts={parts}
          setProdSourceModal={setProdSourceModal}
          filters={filters}
        />}
    </Tbody>
  );
};

export default PartsModeBody;
