export enum VehicleCreateFieldEnum {
  SELECT = 'select',
  INPUT = 'input',
  AUTOCOMPLETE = 'autocomplete',
  INPUT_NUMBER = 'input-number',
}

export enum QuoteLinesOrdering {
  Number = 'Number',
  QuoteCategory = 'QuoteCategory',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  Notes = 'Notes',
  Quantity = 'Quantity',
  UnitPrice = 'UnitPrice',
  Discount = 'Discount',
  NetPrice = 'NetPrice',
  Tax = 'Tax',
  Weight = 'Weight',
}

export enum QuoteLinesTimelineOrdering {
  QuoteCategoryName = 'QuoteCategoryName',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  Notes = 'Notes',
  Quantity = 'Quantity',
  UnitPrice = 'UnitPrice',
  Discount = 'Discount',
  NetPrice = 'NetPrice',
  Tax = 'Tax',
  Weight = 'Weight',
}
