import { VehicleDto } from '../../../core/types/coreTypes';

export const defaultVehicleDetailsFields = {
  customerId: '',
  vehicleInfoSource: '',
  additionalInfo: '',
  slidingDoorsQuantity: undefined,
  grossVehicleWeightKg: '',
  maxPayload: '',
  regNumber: '',
  chassisNumber: '',
  vehicleFlow: undefined,
  companyName: null,
  addressLine1: null,
  addressLine2: null,
  cityTown: null,
  region: null,
  postalCode: null,
  telephone: null,
};

export const vehicleFieldsToValidateKeys = [
  'customerId', 'slidingDoorsQuantity',
  'grossVehicleWeightKg', 'maxPayload', 'modelDescription',
  'regNumber', 'vehicleFlow', 'additionalInfo',
];
export const addressFieldsKeys: Array<keyof VehicleDto> = [
  'companyName', 'addressLine1', 'addressLine2',
  'cityTown', 'region', 'postalCode', 'telephone',
];
