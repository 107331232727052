import React from 'react';
import Tbody from '../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../store/hooks';
import { invoiceNominalCodesSelector } from '../../../../../store/selectors/invoicingSelectors';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../../core/components/tag/Tag';

type AddInvoiceBodyProps = {
  checkedKeys: number[],
  checkRow: NumberFunctionType,
}
const AddInvoiceBody: React.FC<AddInvoiceBodyProps> = ({ checkedKeys, checkRow }) => {
  const { items } = useAppSelector(invoiceNominalCodesSelector);

  const nowrapKeys = ['net', 'vat'];
  const truncateKeys = ['productNumber', 'productName', 'description'];

  const renderCellValue = (key: string, value: string | number) => {
    if (truncateKeys.includes(key)) return <TruncateTooltip value={value} table />;
    if (key === 'state') return <Tag label={value} />;
    return value;
  };

  return (
    <Tbody>
      {!items.length
        ? <EmptyTableSection text="No records" colSpan={9} />
        : items.map((el) => (
          <Tr key={el.id}>
            <TdNew>
              <Checkbox checked={checkedKeys.includes(el.id)} onChange={() => checkRow(el.id)} table />
            </TdNew>
            {Object.entries(el).map(([key, value]) => {
              if (key === 'id') return null;
              return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>
                {renderCellValue(key, value)}
              </TdNew>;
            })}
          </Tr>
        ))}
    </Tbody>
  );
};

export default AddInvoiceBody;
