import React from 'react';
import { Timeline as TimelineComponent } from 'antd';
import classNames from 'classnames';
import './Timeline.scss';

type TimelineProps = {
  timelineList: Array<{ label: React.ReactNode, description: React.ReactNode, color?: string, dot?: React.ReactNode }>;
  mode?: 'left' | 'alternate' | 'right';
  className?: string;
}

const Timeline: React.FC<TimelineProps> = ({
  timelineList,
  mode = 'left',
  className,
}) => {
  return (
    <TimelineComponent mode={mode} className={classNames(className, 'timeline')}>
      {timelineList.map((el, i) => (
        <TimelineComponent.Item
          key={i}
          label={el.label}
          color={el.color}
          dot={el.dot}
          position="left"
        >
          {el.description}
        </TimelineComponent.Item>
      ))}
    </TimelineComponent>
  );
};

export default Timeline;
