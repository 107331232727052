import { useState } from 'react';

export const usePartsKitActions = () => {
  const [isDuplicate, setDuplicate] = useState<boolean>(false);
  const [isConfirm, setConfirm] = useState<boolean>(false);
  const [isSimpleInactivate, setSimpleInactivate] = useState<boolean>(false);

  return {
    isDuplicate,
    setDuplicate,
    isConfirm,
    setConfirm,
    isSimpleInactivate,
    setSimpleInactivate,
  };
};
