import { initDataList } from '../../../../../../core/types/coreTypes';
import { SoldProductsByCustomerData } from '../../../../../types/SoldProductsByCustomerTypes';

export const initSoldProductsByCustomerData: SoldProductsByCustomerData = {
  ...initDataList,
  maxQuantity: 0,
  minQuantity: 0,
  maxCostNet: 0,
  minCostNet: 0,
  maxSalesNet: 0,
  minSalesNet: 0,
};
