import React, { useEffect, useState } from 'react';
import {
  DataListType, initDataList, initSmallPaging, PagingType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Select from '../../../core/components/select/Select';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { getCustomersDictionaryThunk } from '../../../store/thunks/shared/sharedThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { CustomersDictionaryFiltersType } from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import { customersDictionaryLookupSelector } from '../../../store/selectors/sharedSelectors';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import AffectedEntitiesWithLinkTable from '../affected-entities-modal/AffectedEntitiesWithLinkTable';
import { AffectedEntityLineUi } from '../../types/commonTypes';
import { setOrderAffectedEntities } from '../../../store/slices/ordersSlice';
import { getPartRequestStatuses } from '../../../store/thunks/core/coreStatusesThunks';
import Pagination from '../../../core/components/pagination/Pagination';
import { orderAffectedEntitiesColumns } from '../affected-entities-modal/data';
import './ChangeCustomerModal.scss';

type ChangeCustomerModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  note: string,
  changeThunk: (v: number, cancelHandle: VoidFunctionType) => void,
  filters: Partial<CustomersDictionaryFiltersType>,
  affectedItems: DataListType<AffectedEntityLineUi[]>,
  getAffectedItems?: (page: number, pageSize: number) => void,
}

const ChangeCustomerModal: React.FC<ChangeCustomerModalProps> = ({
  visible, onCancel, note, changeThunk, filters, affectedItems, getAffectedItems,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [selectedValue, setSelectedValue] = useState<number | undefined>(undefined);
  const [currentPaging, setCurrentPaging] = useState<PagingType>(initSmallPaging);
  const customersDictionary = useAppSelector(customersDictionaryLookupSelector);

  const changeAffectedPagingHandle = (page: number, pageSize: number) => {
    setCurrentPaging({ page, pageSize });
    getAffectedItems && getAffectedItems(page, pageSize);
  };

  const cancelHandle = () => {
    onCancel();
    setSelectedValue(undefined);
    setSearchValue(undefined);
    affectedItems.totalCount > 0 && dispatch(setOrderAffectedEntities(initDataList));
    setCurrentPaging(initSmallPaging);
  };

  useEffect(() => {
    dispatch(getPartRequestStatuses());
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (visible) {
      if (searchValue) {
        const timeOutId = setTimeout(() => {
          dispatch(getCustomersDictionaryThunk({ filters: { ...filters, keyword: searchValue } }));
        }, 1000);
        return () => clearTimeout(timeOutId);
      } else {
        dispatch(getCustomersDictionaryThunk({ filters: { ...filters } }));
      }
    }
    // eslint-disable-next-line
    }, [searchValue, visible]);
  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Change customer"
      width={affectedItems.totalCount > 0 ? 900 : 700}
      className="changeCustomerModal"
    >
      <div className="changeCustomerModal__content">
        <Select
          label="Customer"
          options={customersDictionary}
          value={selectedValue}
          onChange={(v) => setSelectedValue(v as number)}
          showSearch
          allowClear
          onClear={() => {
            setSelectedValue(undefined);
            searchValue && setSearchValue(undefined);
          }}
          onSearch={(keyword) => {
            if (keyword.length <= 250) {
              setSearchValue(keyword.trim());
            } else {
              dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
            }
          }}
          onSelect={() => {
            setSearchValue('');
            dispatch(getCustomersDictionaryThunk({ filters: { ...filters } }));
          }}
          parentRender
          loading={isLoading}
        />
        <span className="changeCustomerModal__text">
          {note}
        </span>
        {!!affectedItems.totalCount && <>
          <Pagination
            currentPage={currentPaging.page}
            pageSize={currentPaging.pageSize}
            onChange={(page, pageSize) => changeAffectedPagingHandle(page, pageSize)}
            totalItems={affectedItems.totalCount}
            showSizeChanger={false}
          />
          <AffectedEntitiesWithLinkTable
            items={affectedItems.items}
            columns={orderAffectedEntitiesColumns}
          />
        </>}
        <ButtonActions
          createLabel="Save"
          createClick={() => selectedValue && changeThunk(selectedValue, cancelHandle)}
          cancelClick={cancelHandle}
          disabledCreate={!selectedValue}
          isLoading={isFetching}
        />
      </div>

    </Modal>
  );
};

export default ChangeCustomerModal;
