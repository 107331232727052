const numberIntl = ({ maximumFractionDigits = 2, notation = 'compact', ...options }: Intl.NumberFormatOptions, locales = 'en-GB') => {
  return new Intl.NumberFormat(locales, {
    notation,
    maximumFractionDigits,
    ...options,
  });
};

export const numberFormatter = (number: number, options?: Intl.NumberFormatOptions, locales?: string) => {
  return numberIntl({ ...options }, locales).format(number);
};

export const toPoundCurrency = (
  value: number | string | undefined | null,
  placeholder = '-',
  maximumFractionDigits = 2,
  minimumFractionDigits = 2,
) => {
  if (value === null || value === undefined || Number.isNaN(Number(value))) return placeholder;
  return numberFormatter(Number(value), {
    style: 'currency',
    currency: 'GBP',
    notation: 'standard',
    maximumFractionDigits,
    minimumFractionDigits: minimumFractionDigits > maximumFractionDigits ? maximumFractionDigits : minimumFractionDigits,
  }).replace('£', '£ ')
    .replace('-', '- ');
};

export const toOptionalFixed = (num: number, digits = 2) => {
  return Number.parseFloat(num.toFixed(digits));
};
