import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import Modal from '../../../../../../core/components/modal/Modal';
import EditPartPriceTable from './EditPartPriceTable';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import './EditPartPrice.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  getPartPricesThunk,
} from '../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import {
  partPriceTableSelector,
  pricelistDetailsSelector,
} from '../../../../../../store/selectors/pricelistSelectors';
import { ExtendedPricePart, SavePricelistPartPriceDto } from '../../../../types/pricelistViewPageTypes';
import { isFetchingSelector } from '../../../../../../store/selectors/coreSelectors';
import { utcDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { DEFAULT_DATE_FORMAT } from '../../../../../../core/utils/regex';

type EditPartModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  partId?: number,
  pricesCopy: ExtendedPricePart[],
  setPricesCopy: (value: ExtendedPricePart[]) => void,
  getAffectedHandle: (dataToAffect: { partIds: number[], newPrices: SavePricelistPartPriceDto[] }) => void,
}

export type DraftValuesType = {
  costPrice: string | undefined,
  sellPrice: string | undefined,
  startDate: string | undefined,
}

const EditPartModal: React.FC<EditPartModalProps> = ({
  onCancel, isVisible, partId, setPricesCopy, pricesCopy, getAffectedHandle,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const partPrices = useAppSelector(partPriceTableSelector);
  const [error, setError] = useState<string>('');
  const [draftValues, setDraftValues] = useState<DraftValuesType>({
    costPrice: '0.00',
    sellPrice: '0.00',
    startDate: undefined,
  });

  useEffect(() => {
    pricelistDetails && partId && dispatch(getPartPricesThunk({
      partId,
      pricelistId: pricelistDetails.id,
      filters: { order: { field: 4, isAsc: true } },
    }));
    // eslint-disable-next-line
  }, [pricelistDetails, partId]);

  const savePrices = () => {
    const pricesToAffect = pricesCopy.map((price) => ({
      priceId: price.id,
      costPrice: price.costPrice || 0.00,
      sellPrice: price.sellPrice || 0.00,
      effectiveStartDate: utcDateFormatHandler(DEFAULT_DATE_FORMAT, price.effectiveStartDate || undefined),
    }));
    partId && getAffectedHandle({ partIds: [partId], newPrices: pricesToAffect });
  };
  const cancelHandle = () => {
    setDraftValues({
      costPrice: '0.00',
      sellPrice: '0.00',
      startDate: undefined,
    });
    onCancel();
  };

  const isArrayEqual = (x: ExtendedPricePart[], y: ExtendedPricePart[]) => {
    const isSameSize = _.size(x) === _.size(y);
    return isSameSize && _(x).xorWith(y, _.isEqual).isEmpty();
  };

  const equalityResult = useCallback(() => isArrayEqual(
    pricesCopy,
    partPrices.items,
  ), [pricesCopy, partPrices.items]);

  return (
    <Modal
      title="Edit part price"
      visible={isVisible}
      onCancel={cancelHandle}
      width={900}
      destroyOnClose
      className="editPartModal"
    >
      <EditPartPriceTable
        setError={setError}
        error={error}
        draftValues={draftValues}
        setDraftValues={setDraftValues}
        disabledSave={Object.values(draftValues).some((v) => v === undefined)}
        pricesCopy={pricesCopy}
        setPricesCopy={setPricesCopy}
      />
      <ButtonActions
        createLabel="Save"
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        disabledCreate={equalityResult()}
        createClick={savePrices}
        offsetTop
        isLoading={isFetching}
      />
    </Modal>
  );
};

export default EditPartModal;
