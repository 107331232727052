import React from 'react';
import { ContentModeEnum } from '../../enums/ContentModeEnum';
import EnquiryAttachments from './attachments/EnquiryAttachments';
import DetailsWrapper from './details&activity/DetailsWrapper';
import Quote from './qoute/Quote';
import EnquiryContacts from './contacts/EnquiryContacts';
import EnquiryLinks from './links/EnquiryLinks';

type EnquiryContentProps = {
  mode: string | null,
}

const EnquiryContent: React.FC<EnquiryContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case ContentModeEnum.ENQUIRY_DETAILS:
        return <DetailsWrapper />;
      case ContentModeEnum.ENQUIRY_ATTACHMENTS:
        return <EnquiryAttachments />;
      case ContentModeEnum.ENQUIRY_QUOTE:
        return <Quote />;
      case ContentModeEnum.ENQUIRY_CONTACTS:
        return <EnquiryContacts />;
      default: return <EnquiryLinks />;
    }
  };
  return (
    <>
      {renderContent()}
    </>
  );
};

export default EnquiryContent;
