import React, { useEffect, useState } from 'react';
import './StockVehicles.scss';
import EmptyVehiclesList from '../empty-vehicles-list/EmptyVehiclesList';
import { useAppSelector } from '../../../../store/hooks';
import { vehicleProfilesGroupsUiWithoutAllSelector } from '../../../../store/selectors/sharedSelectors';
import { vehiclesAPI } from '../../../../api/vehiclesApi';
import { DictionaryItem } from '../../../../core/types/coreTypes';
import { initSourceTargetProfilesFilters } from '../../../../common/types/commonTypes';

type StockVehiclesProps = {
  profileIds: number[] | null,
  profileGroupIds: number[] | null,
}

const StockVehicles: React.FC<StockVehiclesProps> = ({ profileIds, profileGroupIds }) => {
  const availableVehicleGroups = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);
  const [listToRender, setListToRender] = useState<DictionaryItem[]>([]);

  useEffect(() => {
    const getList = async () => {
      if (profileIds?.length && !profileGroupIds?.length) {
        const { data: { data: { items } } } = await vehiclesAPI.fetchVehiclesProfiles({
          ...initSourceTargetProfilesFilters, ids: profileIds,
        });
        return items.map((el) => ({ label: `${el.code} / ${el.modelDescription}`, value: el.id }));
      } else if (!profileIds?.length && (profileGroupIds?.length !== availableVehicleGroups.items.length)) {
        return availableVehicleGroups.items
          .filter((v) => profileGroupIds?.includes(v.id))
          .map((v) => ({ label: `Group ${v.name} (${v.vehicleProfilesCount})`, value: v.id }));
      }
      return [];
    };
    getList().then((list) => setListToRender(list));
    // eslint-disable-next-line
  }, [profileIds, profileGroupIds]);

  return (
    <div className="details-form__field--lg">
      <span className="stockVehicles__label">
        Suitable for vehicles
      </span>
      <ul className="stockVehicles__vehicles">
        {listToRender && listToRender.length > 0
          ? <>
            {listToRender.map((veh) => <li key={veh.value}>
              {veh.label}
            </li>)}
          </>
          : <EmptyVehiclesList />}
      </ul>
    </div>
  );
};

export default StockVehicles;
