import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import React, { useState } from 'react';
import CheckboxGroup from '../../../../../../core/components/checkbox-group/CheckboxGroup';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import './CopyLineTo.scss';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import { copyEnquiryQuotLineThunk } from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import { useAppDispatch } from '../../../../../../store/hooks';
import { EnquiryQuoteVehicleDto } from '../../../../types/enquiryDetailsTypes';

type CopyLineToProps = {
  vehicles?: EnquiryQuoteVehicleDto[];
  onCloseModal: VoidFunctionType;
  enquiryId: number;
  lineId?: number;
}

const CopyLineTo: React.FC<CopyLineToProps> = ({
  vehicles,
  onCloseModal,
  enquiryId,
  lineId,
}) => {
  const dispatch = useAppDispatch();
  const options = vehicles?.map((el) => ({ label: `${el.tabName}: ${el.regNumber || ''} ${el.modelDescription}`, value: el.id }));
  const [value, setValue] = useState<CheckboxValueType[]>([]);

  const closeHandle = () => {
    onCloseModal();
    setValue([]);
  };

  const copyLineHandle = () => {
    lineId && dispatch(copyEnquiryQuotLineThunk(
      {
        enquiryId,
        lineId,
        quoteVehicleIds: value as number[],
        onClose: closeHandle,
      },
    ));
  };
  return (
    <div>
      <CheckboxGroup
        options={options || []}
        value={value}
        onChange={setValue}
        className="copyLine"
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Save"
        createClick={copyLineHandle}
        cancelClick={closeHandle}
        disabledCreate={value.length === 0}
      />
    </div>
  );
};

export default CopyLineTo;
