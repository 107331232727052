import React, { useEffect, useState } from 'react';
import Modal from '../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Input from '../../../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createPartCategoryThunk, editPartCategoryThunk } from '../../../../store/thunks/stock/categories/partCategoriesThunks';
import { isFetchingSelector } from '../../../../store/selectors/coreSelectors';
import { partCategoriesLookupSelector } from '../../../../store/selectors/sharedSelectors';

type CreateEditPartCategoryModalProps = {
  visible: boolean,
  categoryId: number | undefined,
  onCancel: VoidFunctionType,
  editMode: boolean,
}
const CreateEditPartCategoryModal: React.FC<CreateEditPartCategoryModalProps> = ({
  visible, categoryId, onCancel, editMode,
}) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(partCategoriesLookupSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const [name, setName] = useState('');
  const [error, setError] = useState('');
  const cancelHandle = () => {
    setName('');
    setError('');
    onCancel();
  };

  const createHandle = () => {
    if (!error) {
      if (name.trim().length === 0) {
        setError(ErrorsEnum.REQUIRED);
      } else {
        editMode
          ? categoryId && dispatch(editPartCategoryThunk({
            id: categoryId,
            name,
            onClose: cancelHandle,
            setError,
          }))
          : dispatch(createPartCategoryThunk({
            name,
            onClose: cancelHandle,
            setError,
          }));
      }
    }
  };

  useEffect(() => {
    if (editMode) {
      const currentCategoryName = categories.find((c) => c.value === categoryId)?.label || '';
      setName(currentCategoryName);
    }
    // eslint-disable-next-line
  }, [editMode]);

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title={`${editMode ? 'Edit' : 'Create'} part category`}
      destroyOnClose
    >
      <Input
        label="Name"
        value={name}
        onChange={(e) => {
          const { value } = e.target;
          if (value.length === 0) {
            setError(ErrorsEnum.REQUIRED);
          } else if (value.length > 50) {
            setError(maxLengthMessageHandle(50));
          } else {
            error && setError('');
          }
          setName(value);
        }}
        error={error}
      />
      <ButtonActions
        cancelLabel="Cancel"
        createLabel={editMode ? 'Save' : 'Create'}
        cancelClick={cancelHandle}
        createClick={createHandle}
        isLoading={isFetching}
        offsetTop
      />
    </Modal>
  );
};

export default CreateEditPartCategoryModal;
