import React, { useState } from 'react';
import { capitalize, startCase } from 'lodash';
import { EmployeeActivityEventType } from '../../../../types/employeeViewTypes';
import Button from '../../../../../../core/components/button/Button';
import { EmployeeEventTypesEnum } from '../../../../enums/EmployeeViewEnums';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import EmploymentEventModal from './EmploymentEventModal';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';

type EmploymentEventProps = {
  item: EmployeeActivityEventType,
  event: EmployeeEventTypesEnum,
}

const EmploymentEvent: React.FC<EmploymentEventProps> = ({ item, event }) => {
  const [employmentModal, setEmploymentModal] = useState<boolean>(false);
  const actionText = () => {
    switch (event) {
      case EmployeeEventTypesEnum.EmploymentCreation: {
        return 'created';
      }
      default: return 'deleted';
    }
  };
  const textTransform = (text: string | undefined) => (text ? capitalize(startCase(text)) : '');
  const fieldValueHandle = (value: string | number, field?: string) => {
    const dateFieldNames = ['StartDate', 'EndDate'];
    const infoFieldNames = ['JobTitle', 'SalaryValue', 'AnnualLeaveEntitlement'];

    if (field && dateFieldNames.includes(field)) {
      return localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, value as string);
    }
    if (field && infoFieldNames.includes(field)) {
      return value;
    } else return textTransform(value as string);
  };
  const messageTextRender = () => {
    return event === EmployeeEventTypesEnum.EmploymentFieldUpdateOrAddingValue
      ? <>
        Field
        {' '}
        <b>{textTransform(item.field)}</b>
        {' '}
        from employment record for the period
        {' '}
        <b>
          <span className="nowrap">
            {item.startDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.startDate) : 'N/A'}
            {' '}
            -
          </span>
          {' '}
          <span className="nowrap">{item.endDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.endDate) : 'Present'}</span>
        </b>
        {' '}
        was changed:
        {' '}
        {!!item.oldValue && <s>{`${fieldValueHandle(item.oldValue, item.field)}`}</s>}
        {!!item.newValue && <b>{` ${fieldValueHandle(item.newValue, item.field)}`}</b>}
      </>
      : <>
        Employment record for the period
        {' '}
        <b>
          {item.startDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.startDate) : 'N/A'}
          {' '}
          -
          {' '}
          {item.endDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.endDate) : 'Present'}
        </b>
        {' '}
        was
        {' '}
        {actionText()}
        {' '}
        <Button
          label="(see details)"
          onClick={() => setEmploymentModal(true)}
          type="text"
        />
        .
      </>;
  };
  return (
    <>
      <EmploymentEventModal
        isVisible={employmentModal}
        onCancel={() => setEmploymentModal(false)}
        item={item}
      />
      {messageTextRender()}
    </>
  );
};

export default EmploymentEvent;
