import { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { stockPartRequestLinesSelector, stockPartRequestsLinesViewInitSelector } from '../../../store/selectors/stockPartRequestsSelector';
import {
  getStockPartRequestById,
  putStockPartRequestLineThunk,
} from '../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { stockPartRequestLineStatusTransitionValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { SourceConfirmedDataType } from '../../stock-part-requests/types/stockPartRequestTypes';

export const usePartRequestLinesActions = (
  setAffectedLineId: (v?: number) => void,
  affectedLineId?: number,
  isLinesView?: boolean,
  partRequestId?: number,
) => {
  const dispatch = useAppDispatch();
  const { items: stockPartRequestLines } = useAppSelector(stockPartRequestLinesSelector);
  const { items: stockPartRequestLinesView } = useAppSelector(stockPartRequestsLinesViewInitSelector);
  const { notFromStock } = useAppSelector(stockPartRequestLineStatusTransitionValuesSelector);

  const [fillInModal, setFillInModal] = useState<boolean>(false);
  const [editReadyCollected, setEditReadyCollected] = useState(false);
  const [isAllocatePricelistQty, setIsAllocatePricelistQty] = useState<boolean>(false);
  const [isDistributeQty, setDistributeQty] = useState<boolean>(false);
  const [isConfirmReturnedParts, setConfirmReturnedParts] = useState<boolean>(false);
  const [isMarkNotFromStock, setIsMarkNotFromStock] = useState<boolean>(false);
  const [isViewDetails, setViewDetails] = useState(false);
  const [isConfirmEdit, setIsConfirmEdit] = useState(false);
  const [confirmEditMode, setConfirmEditMode] = useState<'split' | 'allocate' | 'ready' | 'collect' | string>('');
  const [partQtyId, setPartQtyId] = useState<number | undefined>(undefined);

  const currentLine = useMemo(() => {
    const items = isLinesView ? stockPartRequestLinesView : stockPartRequestLines;
    const currentPart = items.find((line) => line.id === affectedLineId);
    const children = items.map((line) => line.childLines).flat();
    const currentChild = children?.find((line) => line?.id === affectedLineId);
    return currentPart || currentChild;
  }, [affectedLineId, isLinesView, stockPartRequestLines, stockPartRequestLinesView]);

  useEffect(() => {
    if (currentLine) {
      dispatch(getStockPartRequestById({ id: currentLine.partRequestId }));
    }
    // eslint-disable-next-line
  }, [currentLine]);

  const finalPartRequestId = partRequestId || currentLine?.partRequestId;

  const affectedLineConfirmedData: SourceConfirmedDataType = {
    readyQuantity: currentLine?.readyQuantity || '-',
    collectedQuantity: currentLine?.collectedQuantity || '-',
    allocatedQuantity: currentLine?.allocatedQuantity || '0',
    unitOfMeasure: currentLine?.unitOfMeasure,
  };

  const markAsNotFromStockHandler = () => {
    finalPartRequestId && affectedLineId && dispatch(putStockPartRequestLineThunk({
      partRequestId: finalPartRequestId,
      lineId: affectedLineId,
      data: { statusTransition: notFromStock },
      closeModal: () => {
        setIsMarkNotFromStock(false);
        setAffectedLineId(undefined);
      },
      isLinesView,
    }));
  };

  return {
    currentLine,
    isAllocatePricelistQty,
    setIsAllocatePricelistQty,
    editReadyCollected,
    setEditReadyCollected,
    isDistributeQty,
    setDistributeQty,
    isMarkNotFromStock,
    setIsMarkNotFromStock,
    isConfirmReturnedParts,
    setConfirmReturnedParts,
    affectedLineConfirmedData,
    isConfirmEdit,
    setIsConfirmEdit,
    confirmEditMode,
    setConfirmEditMode,
    isViewDetails,
    setViewDetails,
    partQtyId,
    setPartQtyId,
    fillInModal,
    setFillInModal,
    markAsNotFromStockHandler,
  };
};
