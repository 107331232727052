import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  setPurchaseOrderAttachments,
  setPurchaseOrderAttachmentsFilter,
  setPurchaseOrderDetails,
  setPurchaseOrderPartsList, setPurchaseOrderPartsListFilters,
  setPurchaseOrderSummary, setPurchaseOrderTimeline, setPurchaseOrderTimelineFilters,
} from '../../../../store/slices/purchaseOrderSlice';
import {
  initDataListWithIds, initDataList, initialCoreAttachmentsFilters,
} from '../../../../core/types/coreTypes';
import { initExtendedTimelineFilters } from '../../../../common/types/commonTypes';

export const purchaseOrderCleanUpHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setPurchaseOrderDetails(null));
  dispatch(setPurchaseOrderPartsList({ purchaseOrderParts: [], allItemIds: [] }));
  dispatch(setPurchaseOrderPartsListFilters(undefined));
  dispatch(setPurchaseOrderAttachmentsFilter(initialCoreAttachmentsFilters));
  dispatch(setPurchaseOrderAttachments(initDataListWithIds));
  dispatch(setPurchaseOrderSummary(null));
  dispatch(setPurchaseOrderTimeline(initDataList));
  dispatch(setPurchaseOrderTimelineFilters(initExtendedTimelineFilters));
};
