import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseSingleResult, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { DuplicateValue, ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { CreatePartsKitFields, CreatePartsKitReqType } from '../../../../stock/parts-kits/create/types/CreatePartsKitTypes';
import { stockApi } from '../../../../api/stockApi';
import { routesPath } from '../../../../core/enums/pathEnum';

export const createPartsKitThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, partsKitName: string | null }> | null,
{
  data: CreatePartsKitReqType,
  reset: VoidFunctionType,
  navigate: NavigateFunction,
  setError: UseFormSetError<CreatePartsKitFields>,
}
>('post/PartsKit', async ({
  data, reset, navigate, setError,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.createPartsKit(data);
    const { createdId, partsKitName } = response.data.data;
    dispatch(setSuccessMessage({ message: `Parts kit ${partsKitName} was successfully created.` }));
    reset();
    navigate(`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${createdId}`, { replace: true });
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    if (errors.find((el) => el.key === 'PartsKitCode')) {
      setError('code', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
    }
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
