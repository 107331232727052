import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { numberOfConvertedVehiclesColumnsSelector } from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import NumberOfConvertedVehiclesColumns from './NumberOfConvertedVehiclesColumns';
import NumberOfConvertedVehiclesBody from './NumberOfConvertedVehiclesBody';
import './NumberOfConvertedVehiclesTable.scss';

import { NumberOfConvertedVehiclesTableLine } from '../../../../../types/NumberOfConvertedVehiclesReportTypes';

type NumberOfConvertedVehiclesTableProps = {
  data: NumberOfConvertedVehiclesTableLine[],
  totalCount: number,
}
const NumberOfConvertedVehiclesTable: React.FC<NumberOfConvertedVehiclesTableProps> = ({ data, totalCount }) => {
  const columns = useAppSelector(numberOfConvertedVehiclesColumnsSelector);

  return (
    <TableNew>
      <NumberOfConvertedVehiclesColumns columns={columns} totalElements={totalCount} />
      <NumberOfConvertedVehiclesBody data={data} columnsLength={columns.length} />
    </TableNew>
  );
};

export default NumberOfConvertedVehiclesTable;
