import React, { useEffect, useState } from 'react';
import {
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import { faCheckCircle, faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import SupplierHead from './components/supplier-head/SupplierHead';
import SupplierContent from './components/supplier-content/SupplierContent';
import SupplierStaticSection from './components/supplier-static-section/SupplierStaticSection';
import { SupplierModes, SupplierViewPageEnums } from './enums/SupplierViewPageEnums';
import {
  supplierDetailsSelector,
  supplierLinkedEntitiesSelector,
} from '../../store/selectors/supplierSelector';
import { tabCountHandle } from '../../core/utils/tabCountHandle';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import {
  changeSupplierStatusThunk,
  deleteSupplierThunk,
  getSupplierByIdThunk,

} from '../../store/thunks/suppliers/suppliersViewPageThunks';
import { clearSuppliersData } from './utils/clearSuppliersData';
import { getSupplierStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';
import { unverifiedSupplierStatusSelector, verifiedSupplierStatusSelector } from '../../store/selectors/coreStatusesSelectors';

const SupplierViewPageContainer: React.FC = () => {
  const [params, setSearchParams] = useSearchParams();
  const { id } = useParams();
  const navigate = useNavigate();
  const { backHandle } = useLocationHistoryState(true);
  const currentUser = useAppSelector(currentUserSelector);
  const details = useAppSelector(supplierDetailsSelector);
  const supplierLinkedEntities = useAppSelector(supplierLinkedEntitiesSelector);
  const verifiedSupplierStatus = useAppSelector(verifiedSupplierStatusSelector);
  const unverifiedSupplierStatus = useAppSelector(unverifiedSupplierStatusSelector);
  const dispatch = useAppDispatch();
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);

  const supplierDetails = details?.supplier || null;

  const changeStatus = (status: number) => {
    id && dispatch(changeSupplierStatusThunk({ id: +id, status }));
  };

  const actions = [
    {
      label: 'Mark as Verified',
      key: 'mark-as-verified',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => verifiedSupplierStatus && changeStatus(verifiedSupplierStatus.value),
      restrictedTo: PermissionEnum.SupplierEditFields,
      disabled: supplierDetails?.supplierStatus === verifiedSupplierStatus?.value,
    },
    {
      label: 'Mark as Unverified',
      key: 'mark-as-unverified',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => unverifiedSupplierStatus && changeStatus(unverifiedSupplierStatus.value),
      restrictedTo: PermissionEnum.SupplierEditFields,
      disabled: supplierDetails?.supplierStatus === unverifiedSupplierStatus?.value,
    },
    {
      label: 'Delete supplier',
      key: 'delete-supplier',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setIsConfirmationModal(true),
      restrictedTo: PermissionEnum.SupplierDelete,
      disabled: supplierLinkedEntities.some((el) => !!el),
    },
  ];
  const options = [
    {
      label: 'Details & activity',
      value: SupplierViewPageEnums.SUPPLIER_DETAILS,
    },
    {
      label: `Contacts ${tabCountHandle(supplierDetails?.contactsCount)}`,
      value: SupplierViewPageEnums.SUPPLIER_CONTACTS,
    },
    {
      label: `Attachments ${tabCountHandle(supplierDetails?.attachmentsCount)}`,
      value: SupplierViewPageEnums.SUPPLIER_ATTACHMENTS,
    },
  ];

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };
  useEffect(() => {
    if (currentUser) {
      const modeParam = params.get('mode');
      const { permissions } = currentUser.userContextRole;
      if (!modeParam && permissions.includes(PermissionEnum.SupplierViewPage)) {
        setSearchParams({ mode: options[0].value }, { replace: true });
      } else {
        if (!SupplierModes.includes(modeParam as SupplierViewPageEnums)) {
          setSearchParams({ mode: SupplierViewPageEnums.SUPPLIER_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, supplierDetails]);

  useEffect(() => {
    id && dispatch(getSupplierByIdThunk({ id: +id }));
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    dispatch(getSupplierStatusesThunk());
    return () => clearSuppliersData(dispatch);
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          goBack={backHandle}
          actions={actions}
          isConfirmationModal={isConfirmationModal}
          setConfirmationModal={setIsConfirmationModal}
          confirmHandler={() => id && dispatch(deleteSupplierThunk({ id: +id, navigate }))}
        >
          <SupplierHead details={supplierDetails} />
        </DetailsHead>
      }
    >
      <SupplierStaticSection
        mode={params.get('mode')}
        setModeParam={setModeParam}
        allowedOptions={options}
      />
      <SupplierContent mode={params.get('mode')} />
    </Layout>
  );
};

export default SupplierViewPageContainer;
