import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment, { Moment } from 'moment';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  stockPartReqActiveStatusesSelector,
  stockPartReqArchivedStatusesSelector, stockPartRequestDashboardSelector,
  stockPartRequestsFiltersSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import { StockTabParamEnum } from '../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { DateRangeTableFilterType, PickerValues, StringFunctionType } from '../../../core/types/coreTypes';
import {
  PartRequestsUrlParamsNames,
  StockPartReqAllStatusEnum,
  StockPartRequestViewEnum,
} from '../enums/stockPartRequestsEnum';
import KeywordFiltersSection from '../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { StockPartRequestFiltersType } from '../types/stockPartRequestTypes';
import { setStockPartRequestsFilters } from '../../../store/slices/stockPartRequestSlice';
import { getStockPartsRequestDashboard } from '../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import {
  useStockPartRequestsFilters,
} from '../hooks/useStockPartRequestsLinesViewFilters';
import ViewOptions from './ViewOptions';

type StockPartRequestsFiltersProps = {
  tabType: string | null;
  partReqStatus: string | null;
  setPartRequestStatus: StringFunctionType;
  currentView: string | null;
}

const StockPartRequestsFilters: React.FC<StockPartRequestsFiltersProps> = ({
  tabType, setPartRequestStatus, partReqStatus, currentView,
}) => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const stockPartRequestsFilters = useAppSelector(stockPartRequestsFiltersSelector);
  const { maxDueDate, minDueDate } = useAppSelector(stockPartRequestDashboardSelector);
  const stockPartReqActiveStatuses = useAppSelector(stockPartReqActiveStatusesSelector);
  const archivedStatuses = useAppSelector(stockPartReqArchivedStatusesSelector);
  const pageAllowed = usePermission(PermissionEnum.StockPartRequests);
  const {
    keywordValue,
    changeKeywordValue,
    initDropdownSearch,
    getFilteredResult,
    clearFilters,
    filters,
    controller,
    cancelRequest,
  } = useStockPartRequestsFilters(currentView);

  const getFilteredList = (newFilters: StockPartRequestFiltersType, notThunkCall?: boolean) => {
    dispatch(setStockPartRequestsFilters(newFilters));
    if (!notThunkCall) {
      cancelRequest();
      dispatch(getStockPartsRequestDashboard({
        filters: newFilters,
        signal: controller.current?.signal,
      }));
    }
  };

  const initialPickerValue: DateRangeTableFilterType = { dueDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);
  const datePickerFilters = [
    {
      label: 'Due date',
      name: 'dueDate',
      value: stockPartRequestsFilters.dueDate,
      disabledDate: (current: Moment) => current.isBefore(moment(minDueDate)) || current.isAfter(moment(maxDueDate).add(1, 'day')),
    },
  ];
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...stockPartRequestsFilters,
      [field]: values,
    };
    getFilteredList(newFilters);
  };

  useEffect(() => {
    if (pageAllowed) {
      const statusParam = params.get(PartRequestsUrlParamsNames.PART_REQ_STATUS);
      if (statusParam) {
        if (currentView === StockPartRequestViewEnum.REQUESTS) {
          if (stockPartRequestsFilters.status) {
            getFilteredList(stockPartRequestsFilters);
          } else {
            const filters = {
              ...stockPartRequestsFilters,
              status: +statusParam === StockPartReqAllStatusEnum.ALL ? undefined : [+statusParam],
            };
            getFilteredList(filters);
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [
    pageAllowed, params, currentView,
    stockPartRequestsFilters.status, stockPartRequestsFilters.page,
    stockPartRequestsFilters.pageSize,
  ]);

  const finalOptions = useMemo(() => (tabType && tabType === StockTabParamEnum.ARCHIVED
    ? archivedStatuses
    : stockPartReqActiveStatuses), [tabType, archivedStatuses, stockPartReqActiveStatuses]);

  const setDefaultStatusParam = () => {
    if (stockPartRequestsFilters.status) {
      params.append(PartRequestsUrlParamsNames.PART_REQ_STATUS, stockPartRequestsFilters.status[0].toString());
    } else {
      if (tabType === StockTabParamEnum.ARCHIVED && archivedStatuses.length > 0) {
        const firstV = archivedStatuses[0].value;
        params.append(PartRequestsUrlParamsNames.PART_REQ_STATUS, firstV.toString());
      } else {
        params.append(PartRequestsUrlParamsNames.PART_REQ_STATUS, StockPartReqAllStatusEnum.ALL.toString());
      }
    }

    navigate({ search: params.toString() });
  };
  const setPartReqStatusHandle = (status: number | string) => {
    setPartRequestStatus(status.toString());
    const newFilters = {
      ...stockPartRequestsFilters,
      status: status === StockPartReqAllStatusEnum.ALL ? undefined : [status as number],
      page: 1,
    };
    getFilteredList(newFilters, true);
  };

  useEffect(() => {
    if (!partReqStatus) {
      if (tabType) {
        setDefaultStatusParam();
      }
    }
    // eslint-disable-next-line
  }, [tabType, partReqStatus, stockPartRequestsFilters.status]);

  return (
    <>
      <ViewOptions>
        <KeywordFiltersSection
          filters={filters}
          maxLength={250}
          isSearch
          searchValue={keywordValue}
          setSearchValue={changeKeywordValue}
          onClear={() => {
            clearFilters();
            setPickerValue(initialPickerValue);
          }}
          initDropdownSearch={initDropdownSearch}
          getFilteredResHandle={getFilteredResult}
          isFullWidth={currentView === StockPartRequestViewEnum.LINES}
          disableMoveSearchToTop={currentView === StockPartRequestViewEnum.LINES}
          dateFilters={currentView === StockPartRequestViewEnum.REQUESTS ? datePickerFilters : undefined}
          pickerValue={currentView === StockPartRequestViewEnum.REQUESTS ? pickerValue : undefined}
          setPickerValue={setPickerValue}
          pickerHandle={pickerHandle}
        />
      </ViewOptions>
      {currentView === StockPartRequestViewEnum.REQUESTS && <div className="sections-and-filters sections-and-filters--offset">
        {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG
          ? <Selectable
            options={finalOptions}
            value={partReqStatus ? +partReqStatus : ''}
            onChange={(v) => setPartReqStatusHandle(+v)}
          />
          : <Segmented
            options={finalOptions}
            value={partReqStatus ? +partReqStatus : ''}
            onChange={(v) => setPartReqStatusHandle(+v)}
          />}
      </div>}
    </>
  );
};

export default StockPartRequestsFilters;
