import React from 'react';
import { GenericVoidFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import TransferComponent from '../../../../core/components/transfer-component/TransferComponent';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import VehicleGroupsSection from './VehicleGroupsSection';
import './SelectVehiclesModal.scss';
import RadioGroup from '../../../../core/components/radio-group/RadioGroup';
import { useAvailableVehicleProfiles } from '../../../../core/hooks/useAvailableVehicleProfiles';

export type AvailableVehiclesData = {
  suitableVehiclesSource: number | null,
  suitableForVehicleIds: number[] | null,
  suitableForVehicleGroupIds: number[] | null,
}

type SelectVehiclesModalProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  saveChanges: GenericVoidFunctionType<AvailableVehiclesData>;
  initSelectedIds: number[] | null,
  initSelectedGroupIds: number[] | null,
  initSelectedSource: number | null,
}

const SelectVehiclesModal: React.FC<SelectVehiclesModalProps> = ({
  isVisible,
  onClose,
  saveChanges,
  initSelectedIds,
  initSelectedGroupIds,
  initSelectedSource,
}) => {
  const {
    sources,
    selectedSource,
    setSelectedSource,
    isCurrentSourceGroups,
    targetIds,
    setTargetIds,
    groupIds,
    setGroupIds,
    sourceSearchValue,
    setSourceSearchValue,
    targetSearchValue,
    setTargetSearchValue,
    getResultData,
    handleReset,
    targetLoading,
    sourceLoading,
  } = useAvailableVehicleProfiles({
    initSelectedSource,
    initSelectedGroupIds,
    initSelectedIds,
    visible: isVisible,
  });

  const handleSubmit = () => {
    const data = getResultData();
    saveChanges(data);
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={onClose}
      title="Select vehicles"
      width={1000}
      afterClose={handleReset}
      destroyOnClose
    >
      <RadioGroup
        optionType="button"
        options={sources}
        value={selectedSource || null}
        onChange={(value) => {
          setSelectedSource(+value);
          setSourceSearchValue('');
          setTargetSearchValue('');
        }}
        className="selectVehiclesModes"
      />
      {isCurrentSourceGroups ? (
        <VehicleGroupsSection
          groupIds={groupIds}
          setGroupIds={setGroupIds}
        />
      ) : (
        <TransferComponent
          selectedSource={selectedSource}
          targetIds={targetIds}
          setTargetIds={setTargetIds}
          sourceSearchValue={sourceSearchValue}
          setSourceSearchValue={setSourceSearchValue}
          targetSearchValue={targetSearchValue}
          setTargetSearchValue={setTargetSearchValue}
          targetLoading={targetLoading}
          sourceLoading={sourceLoading}
        />
      )}
      <ButtonActions
        createLabel="Save"
        cancelLabel="Cancel"
        createClick={handleSubmit}
        cancelClick={onClose}
        offsetTop
        disabledCreate={isCurrentSourceGroups && !groupIds.length}
      />
    </Modal>
  );
};

export default SelectVehiclesModal;
