import React from 'react';
import { JobModesEnum } from '../../../jobs/enums/jobsEnums';
import { JobsResponseType } from '../../../jobs/types/jobsTypes';
import JobColumns from '../../../jobs/components/jobs-columns/JobColumns';
import { useAppSelector } from '../../../../store/hooks';
import { jobsFiltersSelector } from '../../../../store/selectors/jobsSelector';
import JobList from '../../../jobs-list/JobList';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { jobInitUIStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import JobsCalendar from '../../../jobs-calendar/JobsCalendar';

type JobsContentProps = {
  pageParam: string | null,
  cards: JobsResponseType | null,
  setCards: (value: JobsResponseType | null) => void,
  tabType: string | null;
  setJobTabTypeParam: StringFunctionType,
  setJobStatusParam: StringFunctionType,
  jobStatus: string | null,
}

const JobsContent:React.FC<JobsContentProps> = ({
  pageParam, cards, setCards, tabType, setJobTabTypeParam, setJobStatusParam, jobStatus,
}) => {
  const jobInitStatuses = useAppSelector(jobInitUIStatusesSelector);
  const dashboardFilters = useAppSelector(jobsFiltersSelector);
  const contentRenderFn = () => {
    switch (pageParam) {
      case JobModesEnum.DASHBOARD:
        return <JobColumns
          cards={cards}
          setCards={setCards}
          dashboardFilters={dashboardFilters}
          jobStatuses={jobInitStatuses}
        />;
      case JobModesEnum.JOB_LIST:
        return <JobList
          tabType={tabType}
          setJobTabTypeParam={setJobTabTypeParam}
          setJobStatusParam={setJobStatusParam}
          jobStatus={jobStatus}
          pageParam={pageParam}
        />;
      case JobModesEnum.CALENDAR:
        return <JobsCalendar />;
      default: return null;
    }
  };
  return (
    <>{contentRenderFn()}</>
  );
};

export default JobsContent;
