import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import GenerateEmailModal from '../../../../../../common/components/generate-email-modal/GenerateEmailModal';
import { getGenerateEmailRectificationContactsThunk } from '../../../../../../store/thunks/rectification/rectificationContactsThunks';
import { BooleanFunctionType, infiniteScrollInitPaging, initDataListWithIds } from '../../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  setGenerateEmailRectificationContacts,
  setGenerateEmailRectificationContactsPaging,
} from '../../../../../../store/slices/rectificationsSlice';
import {
  rectificationGenerateEmailContactsPagingSelector,
  rectificationGenerateEmailContactsSelector,
} from '../../../../../../store/selectors/rectificationsSelector';
import { generateRectificationEmailPdfThunk } from '../../../../../../store/thunks/rectification/rectificationViewPageThunks';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';

type RectificationGenerateEmailModalProps = {
  visible: boolean,
  setVisible: BooleanFunctionType,
}
const RectificationGenerateEmailModal: React.FC<RectificationGenerateEmailModalProps> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const paging = useAppSelector(rectificationGenerateEmailContactsPagingSelector);
  const contacts = useAppSelector(rectificationGenerateEmailContactsSelector);
  const [emailHref, setEmailHref] = useState('');

  const {
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(contacts.allItemIds);

  const onCancel = () => {
    setVisible(false);
    dispatch(setGenerateEmailRectificationContacts(initDataListWithIds));
    dispatch(setGenerateEmailRectificationContactsPaging(infiniteScrollInitPaging));
  };

  const loadMore = () => {
    dispatch(setGenerateEmailRectificationContactsPaging({ ...paging, page: paging.page + 1 }));
  };

  const selectHandle = () => {
    const selectedContacts = contacts.items
      .filter(({ id }) => checkedKeys.includes(id))
      .map((el) => ({ id: el.id, email: el.email || '', name: `${el.firstName} ${el.lastName}` }));
    id && dispatch(generateRectificationEmailPdfThunk({
      id: +id,
      selectedContacts,
      closeModal: onCancel,
      setEmailHref,
    }));
  };

  useEffect(() => {
    if (visible && id) {
      dispatch(getGenerateEmailRectificationContactsThunk({
        rectificationId: +id,
        ...paging,
        haveEmail: true,
      }));
    }
    // eslint-disable-next-line
  }, [visible, paging]);

  return (
    <GenerateEmailModal
      visible={visible}
      onCancel={onCancel}
      checkedKeys={checkedKeys}
      checkAll={checkAllHandle}
      uncheckAll={uncheckAllHandle}
      checkLine={checkRowHandle}
      loadMore={loadMore}
      selectHandle={selectHandle}
      dataLength={contacts.items.length}
      hasMore={paging.page < contacts.pages}
      contactsList={contacts.items}
      emailHref={emailHref}
      setEmailHref={setEmailHref}
    />
  );
};

export default RectificationGenerateEmailModal;
