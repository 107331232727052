import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetJobDashboardResponse, JobDto, JobsFiltersType,
} from '../../job/jobs/types/jobsTypes';
import {
  GetJobAttachmentsResponse,
  GetJobResponse,
  GetJobTimeTrackingResponse, JobAttachmentsList,
  JobDetailsFiltersType,
  JobDetailsInitFilters, JobEventDtoBase, JobQualityCheckDto,
} from '../../job/job-view-page/types/jobViewTypes';
import {
  CoreAttachmentsFilters,
  DataListType,
  DictionaryItem,
  EntityLinkType,
  initDataList,
  initialCoreAttachmentsFilters,
  initLinksFilters,
  LinksFiltersType,
  ResponseResult,
  ResponseSingleResult,
  AssigneeDto,
  CollectionColumnPaginationType,
  GenericColumnType,
  initPaging,
  infiniteScrollInitPaging,
  initDataListWithIds,
  PagingType, initSmallPaging,
} from '../../core/types/coreTypes';
import {
  getJobAttachmentsThunk,
  getJobByIdThunk,
  getJobPartLinesForQualityCheckModalThunk,
  getJobPartLinesThunk,
  getJobQualityChecksThunk,
  getJobTimelineThunk,
  getJobTimeTracking,
} from '../thunks/jobs/jobThunks';
import { getJobLinksThunk } from '../thunks/jobs/jobLinksThunks';
import {
  JobListFilters,
  JobListResponseResult,
  SharedJobDto,
} from '../../job/jobs-list/types/jobListTypes';
import { getJobListThunk } from '../thunks/jobs/jobListThunks';
import { getJobsDashboardThunk } from '../thunks/jobs/jobsDashboardThunks';
import {
  ExtendedTimelineFilters,
  initExtendedTimelineFilters,
  PartReqLinesFilters, PartRequestLineDto,
} from '../../common/types/commonTypes';
import { JobCalendarFilters, JobCalendarUserDto, JobDictionaryDto } from '../../job/jobs-calendar/types/jobCalendarTypes';
import { getJobCalendarDictionaryThunk, getJobCalendarThunk } from '../thunks/jobs/jobCalendarThunks';

interface IJobsState {
  filters: JobsFiltersType,
  jobAttachmentsFilters: CoreAttachmentsFilters,
  jobAttachments: JobAttachmentsList,
  jobLinksFilters: LinksFiltersType,
  jobDetails: GetJobResponse | null,
  jobDetailsFilters: JobDetailsFiltersType,
  jobLinks: EntityLinkType[],
  jobListFilters: JobListFilters,
  jobList: DataListType<SharedJobDto[]>,
  jobListAssignees: AssigneeDto[],
  jobWarnings: DictionaryItem[],
  jobColumnsPagination: Array<CollectionColumnPaginationType>,
  jobCollections: Array<GenericColumnType<Array<JobDto>>> | null,
  jobAssigneesFilter: AssigneeDto[],
  jobTimeTracking: GetJobTimeTrackingResponse | null,
  jobTimeline: DataListType<JobEventDtoBase[]>,
  jobTimelineFilters: ExtendedTimelineFilters & { notesOnly: boolean },
  jobPartsFilters?: PartReqLinesFilters,
  jobPartsLines: DataListType<PartRequestLineDto[]>,
  jobCalendarFilters: JobCalendarFilters,
  jobCalendar: DataListType<JobCalendarUserDto[]>,
  jobCalendarDictionary: JobDictionaryDto[],
  jobQualityChecks: JobQualityCheckDto[],
  jobQualityChecksPartsLines: DataListType<PartRequestLineDto[]>,
  jobQualityChecksPartsLinesPaging: PagingType,
}

export const initialJobsState: IJobsState = {
  filters: infiniteScrollInitPaging,
  jobAttachmentsFilters: initialCoreAttachmentsFilters,
  jobAttachments: { ...initDataListWithIds, allOwnItemIds: [] },
  jobLinksFilters: initLinksFilters,
  jobDetails: null,
  jobDetailsFilters: JobDetailsInitFilters,
  jobLinks: [],
  jobListFilters: infiniteScrollInitPaging,
  jobList: initDataList,
  jobListAssignees: [],
  jobWarnings: [],
  jobColumnsPagination: [],
  jobCollections: null,
  jobAssigneesFilter: [],
  jobTimeTracking: null,
  jobTimeline: initDataList,
  jobTimelineFilters: { ...initExtendedTimelineFilters, notesOnly: false },
  jobPartsFilters: undefined,
  jobPartsLines: initDataList,
  jobCalendarFilters: { ...initPaging, days: 5 },
  jobCalendar: initDataList,
  jobCalendarDictionary: [],
  jobQualityChecks: [],
  jobQualityChecksPartsLines: initDataList,
  jobQualityChecksPartsLinesPaging: initSmallPaging,
};

export const jobsSlice = createSlice({
  name: 'jobs',
  initialState: initialJobsState,
  reducers: {
    setJobsFilters: (state, action: PayloadAction<JobsFiltersType>) => {
      state.filters = action.payload;
    },
    setJobAttachFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.jobAttachmentsFilters = action.payload;
    },
    setJobAttachments: (state, action: PayloadAction<JobAttachmentsList>) => {
      state.jobAttachments = action.payload;
    },
    setJobLinksFilters: (state, action: PayloadAction<LinksFiltersType>) => {
      state.jobLinksFilters = action.payload;
    },
    setJobDetails: (state, action: PayloadAction<GetJobResponse | null>) => {
      state.jobDetails = action.payload;
    },
    setJobDetailsFilters: (state, action: PayloadAction<JobDetailsFiltersType>) => {
      state.jobDetailsFilters = action.payload;
    },
    setJobLinks: (state, action: PayloadAction<EntityLinkType[]>) => {
      state.jobLinks = action.payload;
    },
    setJobListFilters: (state, action: PayloadAction<JobListFilters>) => {
      state.jobListFilters = action.payload;
    },
    setJobList: (state, action: PayloadAction<{ items: SharedJobDto[], totalCount: number, pages: number }>) => {
      state.jobList = action.payload;
    },
    setJobListAssignees: (state, action: PayloadAction<AssigneeDto[]>) => {
      state.jobListAssignees = action.payload;
    },
    setJobColumnsPagination: (state, action: PayloadAction<Array<CollectionColumnPaginationType>>) => {
      state.jobColumnsPagination = action.payload;
    },
    setJobAssigneesLookup: (state, action: PayloadAction<Array<AssigneeDto>>) => {
      state.jobAssigneesFilter = action.payload;
    },
    setJobsCollections: (state, action: PayloadAction<Array<GenericColumnType<Array<JobDto>>> | null>) => {
      state.jobCollections = action.payload;
    },
    setJobTimeTracking: (state, action: PayloadAction<GetJobTimeTrackingResponse | null>) => {
      state.jobTimeTracking = action.payload;
    },
    setJobTimeline: (state, action: PayloadAction<DataListType<JobEventDtoBase[]>>) => {
      state.jobTimeline = action.payload;
    },
    setJobTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters & { notesOnly: boolean }>) => {
      state.jobTimelineFilters = action.payload;
    },
    setJobPartsFilters: (state, action: PayloadAction<PartReqLinesFilters | undefined>) => {
      state.jobPartsFilters = action.payload;
    },
    setJobPartsLines: (state, action: PayloadAction<DataListType<PartRequestLineDto[]>>) => {
      state.jobPartsLines = action.payload;
    },
    setJobCalendarFilters: (state, action: PayloadAction<JobCalendarFilters>) => {
      state.jobCalendarFilters = action.payload;
    },
    setJobCalendarDictionary: (state, action: PayloadAction<JobDictionaryDto[]>) => {
      state.jobCalendarDictionary = action.payload;
    },
    setJobCalendar: (state, action: PayloadAction<DataListType<JobCalendarUserDto[]>>) => {
      state.jobCalendar = action.payload;
    },
    setJobQualityChecks: (state, action: PayloadAction<JobQualityCheckDto[]>) => {
      state.jobQualityChecks = action.payload;
    },
    setJobQualityChecksPartsLines: (state, action: PayloadAction<DataListType<PartRequestLineDto[]>>) => {
      state.jobQualityChecksPartsLines = action.payload;
    },
    setJobQualityChecksPartsLinesPaging: (state, action: PayloadAction<PagingType>) => {
      state.jobQualityChecksPartsLinesPaging = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getJobByIdThunk.fulfilled, (state, action: PayloadAction<ResponseSingleResult<GetJobResponse> | null>) => {
      state.jobDetails = action.payload ? action.payload.data : null;
    });
    builder.addCase(getJobAttachmentsThunk.fulfilled, (
      state,
      action: PayloadAction<GetJobAttachmentsResponse>,
    ) => {
      state.jobAttachments = action.payload.data;
    });
    builder.addCase(getJobLinksThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<{ jobLinks: EntityLinkType[] }> | null>,
    ) => {
      state.jobLinks = action.payload?.data.jobLinks || [];
    });
    builder.addCase(getJobListThunk.fulfilled, (
      state,
      action: PayloadAction<JobListResponseResult>,
    ) => {
      state.jobListFilters.page > 1
        ? state.jobList = { ...state.jobList, items: [...state.jobList.items, ...action.payload.data.items] }
        : state.jobList = action.payload.data;
      state.jobListAssignees = action.payload.data.assignees;
    });
    builder.addCase(
      getJobsDashboardThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetJobDashboardResponse> | null>) => {
        const assignees = action?.payload?.data.assignees;
        const jobCollections = action?.payload?.data.jobCollections;
        const collectionsList = jobCollections?.map((el) => ({
          status: el.status,
          filteredTotalCount: el.filteredTotalCount,
          totalCount: el.totalCount,
          pages: el.pagesCount,
          isHidden: el.isHidden,
          order: el.ordinalNumber,
          list: el.jobs,
        }));
        state.jobCollections = collectionsList || null;
        state.jobColumnsPagination = jobCollections?.map((col) => ({ status: col.status, pages: col.pagesCount, currentPage: 1 })) || [];
        state.jobAssigneesFilter = assignees || [];
      },
    );
    builder.addCase(getJobTimeTracking.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetJobTimeTrackingResponse> | null>,
    ) => {
      state.jobTimeTracking = action.payload?.data || null;
    });
    builder.addCase(
      getJobTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<JobEventDtoBase[]>>) => {
        state.jobTimeline = action.payload.data;
      },
    );
    builder.addCase(getJobPartLinesThunk.fulfilled, (
      state,
      action: PayloadAction<ResponseResult<PartRequestLineDto[]>>,
    ) => {
      state.jobPartsLines = action.payload.data;
    });
    builder.addCase(getJobCalendarThunk.fulfilled, (state, action: PayloadAction<ResponseResult<JobCalendarUserDto[]>>) => {
      state.jobCalendar = action.payload.data;
    });
    builder.addCase(
      getJobCalendarDictionaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ jobs: JobDictionaryDto[] }>>) => {
        state.jobCalendarDictionary = action.payload.data.jobs;
      },
    );
    builder.addCase(
      getJobQualityChecksThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ qualityChecks: JobQualityCheckDto[] }>>) => {
        state.jobQualityChecks = action.payload.data.qualityChecks;
      },
    );
    builder.addCase(
      getJobPartLinesForQualityCheckModalThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartRequestLineDto[]>>) => {
        state.jobQualityChecksPartsLines = action.payload.data;
      },
    );
  },
});

// Action creators are generated for each case reducer function
export const {
  setJobsFilters,
  setJobAttachFilters,
  setJobLinksFilters,
  setJobList,
  setJobListFilters,
  setJobListAssignees,
  setJobDetails,
  setJobDetailsFilters,
  setJobAttachments,
  setJobLinks,
  setJobColumnsPagination,
  setJobAssigneesLookup,
  setJobsCollections,
  setJobTimeTracking,
  setJobTimeline,
  setJobTimelineFilters,
  setJobPartsFilters,
  setJobPartsLines,
  setJobCalendarFilters,
  setJobCalendarDictionary,
  setJobCalendar,
  setJobQualityChecks,
  setJobQualityChecksPartsLines,
  setJobQualityChecksPartsLinesPaging,
} = jobsSlice.actions;

export default jobsSlice.reducer;
