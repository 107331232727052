import React, { useState } from 'react';
import moment from 'moment/moment';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Tr from '../../../core/components/table-components/Tr';
import { setInfoMessagesListFilters } from '../../../store/slices/settingsSlice';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  infoMessagesColumnsSelector,
  infoMessagesListFiltersSelector,
  infoMessagesListSelector,
} from '../../../store/selectors/settingsSelectors';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import { DateRangeTableFilterType, PickerValues, SearchTableFilterType } from '../../../core/types/coreTypes';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';

const InfoMessagesListColumns: React.FC = () => {
  const dispatch = useAppDispatch();
  const infoMessageListFilters = useAppSelector(infoMessagesListFiltersSelector);
  const infoMessagesList = useAppSelector(infoMessagesListSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    title: infoMessageListFilters.title,
    message: infoMessageListFilters.message,
  });
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    createDate: [
      infoMessageListFilters.createDate?.from ? moment(infoMessageListFilters.createDate.from, DEFAULT_DATE_FORMAT) : null,
      infoMessageListFilters.createDate?.at ? moment(infoMessageListFilters.createDate.at, DEFAULT_DATE_FORMAT) : null,
    ],
  });
  const infoMessageColumns = useAppSelector(infoMessagesColumnsSelector);

  const filters = [
    {
      dataIndex: 'status',
      value: infoMessageListFilters.status || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        page: 1,
        status: checkedValues.length ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'displayOrder',
      value: infoMessageListFilters.displayOrder || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        page: 1,
        displayOrder: checkedValues.length ? checkedValues as number[] : undefined,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (infoMessageListFilters.order?.field === field) {
      dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        order: { ...infoMessageListFilters.order, isAsc: !infoMessageListFilters.order?.isAsc },
      }));
    } else {
      dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        order: { field, isAsc: true },
      }));
    }
  };

  const onChangeSearchHandle = (v: string, dataIndex: string) => {
    if (!v) {
      dispatch(setInfoMessagesListFilters({
        ...infoMessageListFilters,
        [dataIndex]: undefined,
      }));
    }
    setSearchVl({ [dataIndex]: v });
  };
  const searchPressHandle = (dataIndex: string) => {
    dispatch(setInfoMessagesListFilters({
      ...infoMessageListFilters,
      page: 1,
      [dataIndex]: searchVl[dataIndex]?.trim(),
    }));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...infoMessageListFilters,
      page: 1,
      [field]: values ? { from: values?.min, at: values?.max } : undefined,
    };
    dispatch(setInfoMessagesListFilters(newFilters));
  };

  return (
    <Tr>
      {infoMessageColumns.map((col) => (
        <ThWithControls
          key={col.dataIndex}
          col={col}
          totalElements={infoMessagesList.totalCount}
          searchVl={searchVl}
          searchPressHandle={searchPressHandle}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          pickerValue={pickerValue}
          setPickerValue={setPickerValue}
          pickerHandle={pickerHandle}
          filters={filters}
          tableFilters={infoMessageListFilters}
          className={`infoMessagesTh--${col.dataIndex}`}
        />
      ))}
      <ThNew action><span className="sr-only">Actions</span></ThNew>
    </Tr>
  );
};

export default InfoMessagesListColumns;
