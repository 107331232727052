import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { useSearchParams } from 'react-router-dom';
import Button from '../../../../core/components/button/Button';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import { purchaseTabModesOptions } from '../../../../stock/container/utils/data';
import Segmented from '../../../../core/components/segmented/Segmented';
import { salesDashboardFiltersSelector } from '../../../../store/selectors/invoicingSelectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { SalesUrlParamsNames } from '../../../sales/enums/salesEnums';
import {
  StockTabParamEnum,
} from '../../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { setSalesDashboardFilters } from '../../../../store/slices/invoicingSlice';
import { orderStatusesValuesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { InvoicingPageParamsEnums } from '../../enums/InvoicingPageParamsEnums';
import SelectInvoicingEntitiesForExport
  from '../../../common/components/select-entities-for-export/SelectInvoicingEntitiesForExport';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';

const SalesFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const [params, setSearchParams] = useSearchParams();
  const page = params.get(SalesUrlParamsNames.PAGE);
  const tabParam = params.get(SalesUrlParamsNames.TAB_TYPE);
  const salesDashboardFilters = useAppSelector(salesDashboardFiltersSelector);
  const {
    completed, postCheckPassed, acceptedByCustomer, invoicing,
  } = useAppSelector(orderStatusesValuesSelector);
  const [isExportToCsvModal, setIsExportToCsvModal] = useState(false);
  const allowedToExport = usePermission(PermissionEnum.InvoicingExportSalesInvoices);

  const setTabParam = (tab: string) => {
    page && setSearchParams({ page, [SalesUrlParamsNames.TAB_TYPE]: tab });

    const newValues = tab === StockTabParamEnum.ACTIVE
      ? { statuses: [postCheckPassed as number, acceptedByCustomer as number, invoicing as number], isActive: true }
      : { statuses: completed ? [completed] : undefined, isActive: false };

    dispatch(setSalesDashboardFilters({
      ...salesDashboardFilters,
      ...newValues,
      page: 1,
    }));
  };

  useEffect(() => {
    if (page === InvoicingPageParamsEnums.SALES && !tabParam) {
      const tabValue = salesDashboardFilters.isActive
        ? StockTabParamEnum.ACTIVE
        : StockTabParamEnum.ARCHIVED;

      setSearchParams({ page, [SalesUrlParamsNames.TAB_TYPE]: tabValue });
    }
    // eslint-disable-next-line
  }, [page, tabParam]);

  useEffect(() => {
    if (page === InvoicingPageParamsEnums.SALES && tabParam) {
      dispatch(setSalesDashboardFilters({ ...salesDashboardFilters, isActive: tabParam === StockTabParamEnum.ACTIVE, page: 1 }));
    }
    // eslint-disable-next-line
  }, [tabParam]);
  return (
    <>
      <SelectInvoicingEntitiesForExport
        visible={isExportToCsvModal}
        onCancel={() => setIsExportToCsvModal(false)}
        type="sales"
      />
      {salesDashboardFilters.isActive && allowedToExport && (
        <Button
          icon={<FontAwesomeIcon icon={faFileExport} />}
          onClick={() => setIsExportToCsvModal(true)}
          className="invoicingFilters__export-csv"
        />
      )}
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG_L
        ? <Selectable
          options={purchaseTabModesOptions}
          value={tabParam || ''}
          onChange={(value) => setTabParam(value as string)}
          size="sm"
        />
        : <Segmented
          options={purchaseTabModesOptions}
          value={tabParam || ''}
          onChange={(value) => setTabParam(value as string)}
        />}
    </>
  );
};

export default SalesFilters;
