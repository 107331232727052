import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  outstandingOverduePurchasingReportColumnsSelector,
  outstandingOverduePurchasingReportDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import OutstandingOverduePurchaseOrdersBody from './OutstandingOverduePurchaseOrdersBody';
import OutstandingOverduePurchaseOrdersColumns from './OutstandingOverduePurchaseOrdersColumns';
import './OutstandingOverduePurchaseOrdersTable.scss';

const OutstandingOverduePurchaseOrdersTable: React.FC = () => {
  const { totalCount } = useAppSelector(outstandingOverduePurchasingReportDataSelector);
  const columns = useAppSelector(outstandingOverduePurchasingReportColumnsSelector);

  return (
    <TableNew>
      <OutstandingOverduePurchaseOrdersColumns columns={columns} totalCount={totalCount} />
      <OutstandingOverduePurchaseOrdersBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default OutstandingOverduePurchaseOrdersTable;
