import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { nominalCodesOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { nominalCodesFiltersSelector, nominalCodesTableUiSelector } from '../../../store/selectors/invoicingSelectors';
import { getNominalCodesOrderingThunk } from '../../../store/thunks/core/coreOrderingThunks';
import { NominalCodesOrdering } from '../enums/nominalCodesEnums';
import { setNominalCodesFilters } from '../../../store/slices/invoicingSlice';
import { getNominalCodesThunk } from '../../../store/thunks/invoicing/invoicingNominalCodesThunks';

export const useNominalCodesGetData = () => {
  const dispatch = useAppDispatch();
  const nominalCodeOrdering = useAppSelector(nominalCodesOrderingSelector);
  const filters = useAppSelector(nominalCodesFiltersSelector);
  const selectedType = filters.types?.[0];
  const { items, totalCount } = useAppSelector(nominalCodesTableUiSelector);

  useEffect(() => {
    if (!nominalCodeOrdering.length) {
      dispatch(getNominalCodesOrderingThunk());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = nominalCodeOrdering.find((el) => el.label === NominalCodesOrdering.Code)?.value;
      if (defaultOrdering) {
        dispatch(setNominalCodesFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [nominalCodeOrdering]);

  useEffect(() => {
    if (filters.order && selectedType) {
      dispatch(getNominalCodesThunk({ filters }));
    }
    // eslint-disable-next-line
  }, [filters.order, filters.page, selectedType, filters.pageSize, filters.code, filters.description]);

  return { items, totalCount, filters };
};
