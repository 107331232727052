import React from 'react';
import { Tooltip } from 'antd';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faPen, faRotateRight, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import { EmployeeLeaveRequestUi } from '../../../../../leave-request/types/EmployeesLeaveRequestsTypes';
import Tag from '../../../../../../core/components/tag/Tag';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  leaveRequestsApprovedStatusSelector,
  leaveRequestsPendingApprovalStatusSelector,
  leaveRequestsRejectedStatusSelector,
} from '../../../../../../store/selectors/coreStatusesSelectors';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { changeEmployeesLeaveRequestStatusThunk } from '../../../../../../store/thunks/employee/employeesLeaveRequestsThunks';
import { getAccountLeaveRequestThunk } from '../../../../../../store/thunks/account/accountThunks';
import { currentUserSelector } from '../../../../../../store/selectors/accountSelectors';

type EmployeeLeaveRequestLineProps = {
  data: EmployeeLeaveRequestUi,
  setIdToDelete: NumberFunctionType,
  isCurrentUserCurrentEmployee: boolean,
}

const EmployeeLeaveRequestLine: React.FC<EmployeeLeaveRequestLineProps> = ({ data, setIdToDelete, isCurrentUserCurrentEmployee }) => {
  const dispatch = useAppDispatch();
  const pendingApprovalStatus = useAppSelector(leaveRequestsPendingApprovalStatusSelector);
  const approvedStatus = useAppSelector(leaveRequestsApprovedStatusSelector);
  const rejectedStatus = useAppSelector(leaveRequestsRejectedStatusSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const initMenuItems = data.status === pendingApprovalStatus ? [
    {
      label: 'Approve request',
      key: 'approve-request',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => approvedStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: approvedStatus,
      })),
      restrictedTo: PermissionEnum.EmployeeLeaveRequests,
    },
    {
      label: 'Reject request',
      key: 'reject-request',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => rejectedStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: rejectedStatus,
      })),
      restrictedTo: PermissionEnum.EmployeeLeaveRequests,
    },
    {
      label: 'Edit request',
      key: 'edit-request',
      icon: <FontAwesomeIcon icon={faPen} />,
      onClick: () => dispatch(getAccountLeaveRequestThunk({ id: data.id })),
      restrictedTo: isCurrentUserCurrentEmployee ? PermissionEnum.EmployeeViewPage : PermissionEnum.EmployeeLeaveRequests,
    },
    {
      label: 'Delete leave request',
      key: 'delete-leave-request',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setIdToDelete(data.id),
      restrictedTo: isCurrentUserCurrentEmployee ? PermissionEnum.EmployeeViewPage : PermissionEnum.EmployeeLeaveRequests,
    },
  ] : [
    {
      label: 'Mark as Pending approval',
      key: 'mark-as-pending-approval',
      icon: <FontAwesomeIcon icon={faRotateRight} />,
      onClick: () => pendingApprovalStatus && dispatch(changeEmployeesLeaveRequestStatusThunk({
        id: data.id,
        statusTransition: pendingApprovalStatus,
      })),
      restrictedTo: PermissionEnum.EmployeeLeaveRequests,
    },
  ];

  const menuItems = initMenuItems.filter((el) => currentUser?.userContextRole.permissions.includes(el.restrictedTo));

  return (
    <div className="employeeLeaveRequestLine not-link">
      <div className="employeeLeaveRequestLine__type">
        {data.leaveType}
        {data.comment && <Tooltip title={data.comment} overlayClassName="accountLeaveRequestItem__tooltip">
          <InfoCircleOutlined className="employeeLeaveRequestLine__icon" />
        </Tooltip>}
      </div>
      <div className="employeeLeaveRequestLine__info">
        <span>Leave dates</span>
        <b>{`${data.startDate} - ${data.endDate}`}</b>
      </div>
      <div className="employeeLeaveRequestLine__tag-wrapper">
        <Tag
          label={<TruncateTooltip value={data.statusName} overlayClassName="tooltip-sm" />}
          truncate
        />
      </div>
      <div className="employeeLeaveRequestLine__actions-wrapper">
        {!!menuItems.length && <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={menuItems}
        />}
      </div>
    </div>
  );
};

export default EmployeeLeaveRequestLine;
