import { RcFile } from 'antd/lib/upload';
import { ForbiddenFileExtensions } from '../../common/utils/forbiddenFileExtensions';
import { useAppDispatch } from '../../store/hooks';
import { setErrorMessage } from '../../store/slices/coreSlice';

export const useBeforeAttachmentUpload = (fileList: RcFile[], setFileList: (v: RcFile[]) => void) => {
  const dispatch = useAppDispatch();
  const beforeUpload = (files: RcFile[]) => {
    const bigFiles = files.filter((f) => (f.size / 1024) / 1024 > 100);
    const normalFiles = files.filter((f) => (f.size / 1024) / 1024 <= 100);
    if (bigFiles) {
      for (let i = 0; i < bigFiles.length; i++) {
        const toastId = `big-${bigFiles[i].name}-${i}`;
        const message = `File ${bigFiles[i].name} exceeds the maximum file size (100 MB).`;
        dispatch(setErrorMessage({ message, toastId }));
      }
    }
    if (normalFiles.length > 10) {
      const message = 'Maximum 10 files are allowed per one upload.';
      dispatch(setErrorMessage({ message, toastId: 'max-upload' }));
    }
    const firstTen = normalFiles.slice(0, 10 - fileList.length);
    const arr = [];
    for (let i = 0; i < firstTen.length; i++) {
      const currentName = firstTen[i].name;
      const currentExt = currentName.substring(currentName.lastIndexOf('.') + 1);
      if (ForbiddenFileExtensions.includes(`.${currentExt.toUpperCase()}`)) {
        const toastId = `format-${currentName}-${i}`;
        const message = `File ${currentName} has unsupported file format.`;
        dispatch(setErrorMessage({ message, toastId }));
      } else {
        arr.push(firstTen[i]);
      }
    }
    setFileList(fileList ? [...fileList, ...arr] : arr);
  };

  return {
    beforeUpload,
  };
};
