import React from 'react';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useAppSelector } from '../../../store/hooks';
import { isDashboardLoadingSelector } from '../../../store/selectors/coreSelectors';
import { salesDashboardSelector } from '../../../store/selectors/invoicingSelectors';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';
import InvoicingSalesItem from './InvoicingSalesItem';
import InvoicingSkeletonItem from '../../common/components/invoice-dashboard-skeleton/InvoicingSkeletonItem';
import SkeletonLinesList from '../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';

const InvoicingSalesList: React.FC = () => {
  const isLoading = useAppSelector(isDashboardLoadingSelector);
  const { totalCount, items } = useAppSelector(salesDashboardSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <InvoicingSkeletonItem />
        </SkeletonLinesList>
        : totalCount === 0
          ? <EmptyContentList text="No orders" icon={faCheck} />
          : <ul className="lines-list">
            {items.map((item) => (
              <li key={item.id} className="lines-list__item">
                <InvoicingSalesItem item={item} />
              </li>
            ))}
          </ul>}
    </>
  );
};

export default InvoicingSalesList;
