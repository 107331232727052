import React from 'react';
import type { RouteProps } from 'react-router-dom';
import { useSearchParams } from 'react-router-dom';
import { PermissionEnum } from '../core/enums/dictionariesEnums';
import ErrorPage from '../common/components/error-pages/ErrorPage';
import usePermission from '../permissions-handling/permissionHook';
import Spinner from '../core/components/spinner/Spinner';
import { useAppSelector } from '../store/hooks';
import { currentUserSelector } from '../store/selectors/accountSelectors';

interface PrivateRouteParams extends RouteProps {
  restrictedTo: PermissionEnum,
  children: JSX.Element,
  checkSearchParams?: boolean,
  paramsAndPermissions?: Array<{ page: string, permission: PermissionEnum }>,
}

const PrivateRoute: React.FC<PrivateRouteParams> = ({
  restrictedTo,
  children,
  checkSearchParams,
  paramsAndPermissions,
}) => {
  const [params] = useSearchParams();
  const pageParam = params.get('page');
  const allowed = usePermission(restrictedTo);
  const currentUser = useAppSelector(currentUserSelector);

  if (allowed === undefined) {
    return <Spinner />;
  }
  if (!allowed) {
    return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
  }

  if (checkSearchParams && pageParam) {
    const foundMatch = paramsAndPermissions?.find((p) => p.page === pageParam)?.permission;
    if (foundMatch) {
      const allowedTab = currentUser?.userContextRole.permissions.includes(foundMatch);
      if (!allowedTab) {
        return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
      }
    }
  }
  return children;
};

export default PrivateRoute;
