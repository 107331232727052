import React from 'react';
import Button from '../../../core/components/button/Button';
import Modal from '../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import './OpenEmailClientModal.scss';

type OpenEmailClientModalProps = {
  emailHref: string,
  onCancel: VoidFunctionType,
}

const OpenEmailClientModal: React.FC<OpenEmailClientModalProps> = ({ emailHref, onCancel }) => {
  return (
    <Modal visible={!!emailHref} onCancel={onCancel} title="Generate email" className="OpenEmailClientModal">
      <div className="OpenEmailClientModal__content">
        <p className="OpenEmailClientModal__text">Clicking on a button below will redirect you to your local email client.</p>
        <Button href={emailHref} label="Open email client" onClick={onCancel} className="OpenEmailClientModal__button" />
      </div>
    </Modal>
  );
};

export default OpenEmailClientModal;
