export const invoicesAndCreditNotesColumns = [
  { title: 'No', dataIndex: 'number' },
  { title: 'Entity No', dataIndex: 'entityNumber' },
  { title: 'Type', dataIndex: 'type' },
  { title: 'Xero ref', dataIndex: 'xeroRef' },
  { title: 'Invoice date', dataIndex: 'invoiceDate' },
  { title: 'Due date', dataIndex: 'dueDate' },
  { title: 'Net', dataIndex: 'net' },
  { title: 'VAT', dataIndex: 'vat' },
  { title: 'Gross', dataIndex: 'gross' },
  { title: 'Status', dataIndex: 'status' },
];
