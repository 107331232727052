import { useMemo } from 'react';
import { PartTabsEnum, PartModesLabelsEnum } from '../enums/PartTypesEnum';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';
import { useAppSelector } from '../../../store/hooks';
import { stockPartDetailsSelector } from '../../../store/selectors/stockSelectors';

export const useStockPartTabs = () => {
  const partDetails = useAppSelector(stockPartDetailsSelector);

  const tabs = useMemo(() => [
    {
      value: PartTabsEnum.VEHICLESANDPRICES,
      label: PartModesLabelsEnum.VEHICLESANDPRICES,
    },
    {
      value: PartTabsEnum.PURCHASES,
      label: `${PartModesLabelsEnum.PURCHASES} ${tabCountHandle(partDetails?.purchaseOrdersCount)}`,
    },
    {
      value: PartTabsEnum.PARTSKITS,
      label: PartModesLabelsEnum.PARTSKITS,
    },
    {
      value: PartTabsEnum.ACTIVITY,
      label: PartModesLabelsEnum.ACTIVITY,
    },
    {
      value: PartTabsEnum.ATTACHMENTS,
      label: `${PartModesLabelsEnum.ATTACHMENTS} ${tabCountHandle(partDetails?.attachmentsCount)}`,
    },
  ], [partDetails?.attachmentsCount, partDetails?.purchaseOrdersCount]);

  return { tabs };
};
