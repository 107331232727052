import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  partsKitAffectedItemsFiltersSelector,
  partsKitAffectedItemsSelector,
} from '../../../../../store/selectors/partsKitSelectors';
import AffectedEntitiesModal from '../../../../../common/components/affected-entities-modal/AffectedEntitiesModal';
import AffectedEntitiesWithLinkTable from '../../../../../common/components/affected-entities-modal/AffectedEntitiesWithLinkTable';
import { setAffectedItemsPaging, setPartsKitAffectedItems } from '../../../../../store/slices/partsKitSlice';
import { initDataList, initSmallPaging } from '../../../../../core/types/coreTypes';
import { getPartsKitAffectedThunk } from '../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';

type PartsKitsAffectedEntitiesModalProps = {
  partsKitName: string,
}

const PartsKitsAffectedEntitiesModal: React.FC<PartsKitsAffectedEntitiesModalProps> = ({ partsKitName }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const filters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const { items, totalCount } = useAppSelector(partsKitAffectedItemsSelector);
  const visible = items.length > 0;

  useEffect(() => {
    if (visible && id) {
      dispatch(getPartsKitAffectedThunk({
        id: +id,
        filters,
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const handleClose = () => {
    dispatch(setAffectedItemsPaging(initSmallPaging));
    dispatch(setPartsKitAffectedItems(initDataList));
  };

  const handlePagination = (page: number, pageSize: number) => {
    dispatch(setAffectedItemsPaging({ ...filters, page, pageSize }));
  };

  const text = filters.businessAreas
    ? 'Operation cannot be finished, because the following entities require deleted business area value(s):'
    : <>
      Operation cannot be finished, because parts kit
      {' '}
      <b>{`"${partsKitName}"`}</b>
      {' '}
      is used in multiple active entities in the system:
    </>;

  return (
    <AffectedEntitiesModal
      visible={visible}
      handleClose={handleClose}
      text={text}
      handlePagination={handlePagination}
      page={filters.page}
      pageSize={filters.pageSize}
      totalCount={totalCount}
    >
      <AffectedEntitiesWithLinkTable
        items={items}
        isLoading={isLoading}
      />
    </AffectedEntitiesModal>
  );
};

export default PartsKitsAffectedEntitiesModal;
