import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, DictionaryResponse } from '../../../core/types/coreTypes';
import { coreStatusesAPI } from '../../../api/core/coreStatusesApi';
import { setLoading } from '../../slices/coreSlice';

export const getEnquiryStatus = createAsyncThunk<DictionaryResponse>(
  'get/EnquiryStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreStatusesAPI.fetchEnquiryStatus();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerStatuses = createAsyncThunk<DictionaryResponse>(
  'get/CustomerStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await coreStatusesAPI.fetchCustomerStatuses();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountAccountStatus = createAsyncThunk<DictionaryResponse>(
  'get/accountAccountStatus',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchAccountAccountStatus();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInfoMessageStatuses = createAsyncThunk<DictionaryResponse>(
  'get/infoMessagesStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchInfoMessageStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPricelistStatuses = createAsyncThunk<DictionaryResponse>(
  'get/PricelistStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchPricelistStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuotePricelistStatuses = createAsyncThunk<DictionaryResponse>(
  'get/QuotePricelistStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchQuotePricelistStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitsStatuses = createAsyncThunk<DictionaryResponse>(
  'get/partsKitsStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchPartsKitsStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderStatus = createAsyncThunk<DictionaryResponse>(
  'get/OrderStatus',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchOrderStatus();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/RectificationStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchRectificationStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/JobStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchJobStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartRequestLineStatuses = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestLineStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchPartRequestLineStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleStatuses = createAsyncThunk<DictionaryResponse>(
  'get/VehicleStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchVehicleStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRequestSubmissionStatuses = createAsyncThunk<DictionaryResponse>(
  'get/RequestSubmissionStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchRequestSubmissionStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getPartRequestStatuses = createAsyncThunk<DictionaryResponse>(
  'get/PartRequestStatuses',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreStatusesAPI.fetchStockPartRequestStatuses();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationStatuses = createAsyncThunk<DictionaryResponse>(
  'get/InAppNotificationsStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await coreStatusesAPI.fetchInAppNotificationsStatuses();
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderStatus = createAsyncThunk<DictionaryResponse>(
  'get/PurchaseOrderStatus',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchPurchaseOrderStatus();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getQuantitiesStatuses = createAsyncThunk<DictionaryResponse>(
  'get/QuantitiesStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreStatusesAPI.fetchQuantitiesStatuses();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsStatuses = createAsyncThunk<DictionaryResponse>(
  'get/StockPartStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchStockPartsStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartRequestLineStatusTransition = createAsyncThunk<DictionaryResponse>(
  'get/StockPartRequestLineStatusTransition',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreStatusesAPI.fetchStockPartRequestLineStatusTransition();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getLeaveRequestStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/LeaveRequestStatuses',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreStatusesAPI.fetchLeaveRequestStatuses();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInvoiceDocumentStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/InvoiceDocumentStatuses',
  async (_, {
    dispatch,
    rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const res = await coreStatusesAPI.fetchInvoiceDocumentStatuses();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      dispatch(setLoading(false));
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/supplierStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchSupplierStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobQualityCheckStatusesThunk = createAsyncThunk<DictionaryResponse>(
  'get/JobQualityCheckStatuses',
  async (_, { rejectWithValue }) => {
    try {
      const res = await coreStatusesAPI.fetchJobQualityCheckStatuses();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
