import React from 'react';
import { PurchaseOrderDto } from '../../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import { purchaseOrderStatusObjSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { PurchaseOrderStatusUiNames } from '../../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { routesPath } from '../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { toPoundCurrency } from '../../../../common/utils/formatUtils';
import InvoicingDashboardLine from '../../../common/components/dashboard-line/InvoicingDashboardLine';

type InvoicingPurchaseItemProps = {
  item: PurchaseOrderDto,
}

const InvoicingPurchaseItem: React.FC<InvoicingPurchaseItemProps> = ({ item }) => {
  const { partiallyReceived, received, sentToSupplier } = useAppSelector(purchaseOrderStatusObjSelector);

  const getStatusLabel = (status: number) => {
    if (status === sentToSupplier) return PurchaseOrderStatusUiNames.SentToSupplier;
    if (status === partiallyReceived) return PurchaseOrderStatusUiNames.PartiallyReceived;
    if (status === received) return PurchaseOrderStatusUiNames.Received;
    return PurchaseOrderStatusUiNames.Completed;
  };

  const info = [
    { label: 'Purchase order No', value: item.purchaseOrderNumber || '-' },
    { label: 'Supplier', value: item.supplierName || '-' },
  ];
  const price = [
    { label: 'Total gross', value: toPoundCurrency(item.totalGross) },
    { label: 'To be invoiced', value: toPoundCurrency(item.toBeInvoicedGross) },
    { label: 'Invoiced', value: toPoundCurrency(item.invoicedGross) },
  ];
  const entities = [
    { label: 'Draft', value: item.draftInvoiceDocumentsCount },
    { label: 'Exported', value: item.exportedInvoiceDocumentsCount },
  ];

  return (
    <InvoicingDashboardLine
      to={`/${routesPath.INVOICING}/${item.id}`}
      permission={PermissionEnum.InvoicingPurchaseViewPageWithInvoices}
      info={info}
      price={price}
      entities={entities}
      invoicingType="purchases"
      status={getStatusLabel(item.status)}
    />
  );
};

export default InvoicingPurchaseItem;
