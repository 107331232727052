import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import EditNoteEventModal from './EditNoteEventModal';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { BooleanFunctionType, StringFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import './NoteEvent.scss';
import { useAppSelector } from '../../../../store/hooks';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';

type NoteEventProps = {
  content?: string,
  menuItems: Array<ItemType>,
  value: string,
  setValue: StringFunctionType,
  isEditModal: boolean,
  closeEditModal: VoidFunctionType,
  editHandle: VoidFunctionType,
  isConfirmationModal: boolean,
  setIsConfirmationModal: BooleanFunctionType,
  deleteHandle: VoidFunctionType,
  initiator?: string | null,
  permission?: PermissionEnum,
  disabledManageNotes?: boolean,
}

const NoteEvent: React.FC<NoteEventProps> = ({
  content,
  menuItems,
  value,
  setValue,
  isEditModal,
  closeEditModal,
  editHandle,
  isConfirmationModal,
  setIsConfirmationModal,
  deleteHandle,
  initiator,
  permission,
  disabledManageNotes,
}) => {
  const currentUser = useAppSelector(currentUserSelector);
  const currentUserName = `${currentUser?.firstName || ''} ${currentUser?.lastName || ''}`.trim();
  const permissionToManageOtherNotes = usePermission(permission);
  // TODO MDA: this should be refactored due to initiatorId implementation. Do refactor when BE is ready
  const allowedToManageOtherNotes = !disabledManageNotes && (currentUserName === initiator || !!permissionToManageOtherNotes);

  return (
    <>
      <EditNoteEventModal
        isVisible={isEditModal}
        onCancel={closeEditModal}
        editHandle={editHandle}
        value={value}
        setValue={setValue}
      />
      <ConfirmationModal
        isVisible={isConfirmationModal}
        onCancel={() => setIsConfirmationModal(false)}
        confirmHandler={deleteHandle}
      />
      <div className="noteEvent">
        <span>{content || ''}</span>
        {allowedToManageOtherNotes && <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisV} />}
          menuItems={menuItems}
        />}
      </div>
    </>
  );
};

export default NoteEvent;
