export enum EnquiryEventTypesEnum {
  Note = 'Note',
  EnquiryCreation = 'EnquiryCreation',
  EnquiryFieldUpdateOrAddingValue = 'EnquiryFieldUpdateOrAddingValue',
  EnquiryStatusChange = 'EnquiryStatusChange',
  EnquiryDeletion = 'EnquiryDeletion',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  ShippingDetailsUpdates = 'ShippingDetailsUpdates',
  AddingVehicle = 'AddingVehicle',
  EditingVehicleDetails = 'EditingVehicleDetails',
  DeletingVehicle = 'DeletingVehicle',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  QuoteLineCreationChanges = 'QuoteLineCreationChanges',
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
  JobLocationUpdate = 'JobLocationUpdate',
}
