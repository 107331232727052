import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseSingleResult, VoidFunctionType } from '../../../core/types/coreTypes';
import { setLoading } from '../../slices/coreSlice';
import { jobsAPI } from '../../../api/jobsApi';
import { JobListFilters, JobListResponseResult } from '../../../job/jobs-list/types/jobListTypes';
import { RootState } from '../../store';

export const getJobListThunk = createAsyncThunk<JobListResponseResult,
{
  businessArea: number,
  filters: JobListFilters,
}
>(
  'get/JobList',
  async ({ businessArea, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobsList(businessArea, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const playPauseJobThunk = createAsyncThunk<ResponseSingleResult | null,
{
  jobId: number,
  statusTransition: number,
  isCurrentRoleAdmin: boolean,
  closeModal?: VoidFunctionType,
}
>(
  'put/PlayPauseJob',
  async ({
    jobId, statusTransition, isCurrentRoleAdmin, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const {
        core: { currentBusinessArea },
        account: { currentUser },
        jobs: { jobListFilters },
      } = getState() as RootState;
      const response = await jobsAPI.changeJobStatus(jobId, statusTransition);
      currentBusinessArea && dispatch(getJobListThunk({
        businessArea: currentBusinessArea,
        filters: isCurrentRoleAdmin
          ? jobListFilters
          : { ...jobListFilters, assigneeIds: currentUser ? [currentUser?.id] : [] },
      }));
      dispatch(setLoading(false));
      closeModal && closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
