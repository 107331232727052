import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DownloadUriResponse, emptyPaging,
  LinksFiltersType, NumberFunctionType,
  ResponseResult,
  ResponseSingleResult, StringFunctionType,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  setCurrentArea, setErrorMessage,
  setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { ordersAPI } from '../../../api/ordersApi';
import { GetOrderResponse } from '../../../orders/dashboard/types/ordersTypes';
import {
  OrderAffectedEntityDto,
  OrderEventDtoBase,
  OrderLinksResType,
  OrderSummaryType,
  PatchOrderDto,
} from '../../../orders/orders-view-page/types/OrderViewPageTypes';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { RootState } from '../../store';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setOrderTimeline, setOrderTimelineFilters } from '../../slices/ordersSlice';
import { coreAPI } from '../../../api/coreAPI';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { getOrderContactsThunk } from './orderContactsThunks';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { UserEventOrder } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';
import { generateEmailAndNames } from '../../../common/utils/generateEmailAndNames';
import { OrderEventSource } from '../../../orders/orders-view-page/enums/orderViewPageEnums';
import { getOrderPartRequestLines } from './orderPartRequestThunks';
import { stockApi } from '../../../api/stockApi';

export const getOrderTimelineThunk = createAsyncThunk<ResponseResult<OrderEventDtoBase[]>, {
  orderId: number, filters: ExtendedTimelineFilters, source: number,
}>(
  'get/OrderTimeline',
  async ({ orderId, filters, source }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.fetchOrderTimeline(orderId, filters, source);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateOrderPdfThunk = createAsyncThunk<DownloadUriResponse, {
  id: number,
  isProformaExportTemplate: boolean,
  onFinish: VoidFunctionType,
}>(
  'get/Order/PDF',
  async ({ id, isProformaExportTemplate, onFinish }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await ordersAPI.exportOrderToPdf(id, isProformaExportTemplate);
      downloadCsv(res.data.data.downloadUri);
      onFinish();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateOrderEmailPdfThunk = createAsyncThunk<DownloadUriResponse | null, {
  id: number,
  closeModal: VoidFunctionType,
  selectedContacts: { id: number, name: string, email: string }[],
  setEmailHref: StringFunctionType,
}>(
  'get/Order/EmailPDF',
  async ({
    id, closeModal, selectedContacts, setEmailHref,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { data: { data: { allItemIds } } } = await ordersAPI.fetchOrderContacts(id, emptyPaging.page, emptyPaging.pageSize, true);
      const filteredContacts = selectedContacts.filter(({ id }) => allItemIds.includes(id));
      if (!filteredContacts.length) {
        closeModal();
        return null;
      }
      const { selectedNames, selectedEmails } = generateEmailAndNames(filteredContacts);
      const res = await ordersAPI.exportOrderToPdf(id, false);
      const { downloadUri } = res.data.data;
      const { shared: { orderDetails } } = getState() as RootState;
      const orderNumber = orderDetails?.order.orderNumber;
      const subject = 'Vanliners order details';
      const encodedLink = encodeURIComponent(downloadUri.replace(/ /g, '%20'));
      // eslint-disable-next-line max-len
      const emailBody = `Dear ${selectedNames},%0D%0A%0D%0APlease find below a link to your order reference ${orderNumber}:%0D%0A%0D%0A${encodedLink}%0D%0A%0D%0AIf you require anything further, please get in touch.`;
      setEmailHref(`mailto:${selectedEmails}?subject=${subject}&body=${emailBody}&content-type=text/plain`);
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderById = createAsyncThunk<GetOrderResponse,
{ id: number }
>('get/OrderById', async ({ id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const responseBa = await coreAPI.fetchBusinessAreas();
    const baList = responseBa.data.data.items;
    const allowedBA = baList.filter((el) => el.isAllowed);
    const response = await ordersAPI.fetchOrderById(id);
    const { businessArea } = response.data.data.order;
    if (allowedBA.find((ba) => ba.value === businessArea)) {
      dispatch(setCurrentArea(businessArea));
    } else {
      dispatch(setCurrentArea(allowedBA[0].value));
    }
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const getOrderSummaryThunk = createAsyncThunk<
ResponseSingleResult<OrderSummaryType>, { id: number }
>('get/OrderSummary', async ({ id }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await ordersAPI.fetchOrderSummary(id);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const patchOrderThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  data: Partial<PatchOrderDto>,
  partRequestId?: number,
  onClose?: VoidFunctionType,
  handleJobTypeError?: NumberFunctionType,
  openReasonModal?: VoidFunctionType,
  setInitValue?: VoidFunctionType,
}>('patch/Order', async ({
  id, data, partRequestId, onClose, handleJobTypeError, openReasonModal, setInitValue,
}, { dispatch, getState, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    let isReasonRequired = false;
    if ((data.enableDiscounts !== undefined || data.orderDate) && partRequestId && !data.partRequestLineActionReason) {
      const {
        data: { data: { isCostChanged } },
      } = await stockApi.getPartRequestSummaryReview({
        id: partRequestId,
        priceDate: data.orderDate,
        enableDiscounts: data.enableDiscounts,
      });
      isReasonRequired = isCostChanged;
    }
    if (isReasonRequired && openReasonModal) {
      dispatch(setLoading(false));
      openReasonModal();
      return null;
    }
    const response = await ordersAPI.patchOrder(id, data);
    const {
      orders: { orderTimelineFilters, partRequestLinesFilters },
      core: { orderEventSources },
    } = getState() as RootState;
    const orderSource = orderEventSources.find(({ label }) => label === OrderEventSource.Order)?.value;
    dispatch(getOrderById({ id }));
    if (partRequestId && partRequestLinesFilters) {
      dispatch(getOrderPartRequestLines({ partRequestId, filters: partRequestLinesFilters }));
    }

    if (orderTimelineFilters.page === 1) {
      orderSource && dispatch(getOrderTimelineThunk({
        orderId: id,
        filters: orderTimelineFilters,
        source: orderSource,
      }));
    } else {
      dispatch(setOrderTimelineFilters({ ...orderTimelineFilters, page: 1 }));
    }
    dispatch(getOrderSummaryThunk({ id }));
    onClose && onClose();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    const jobTypeError = errors.find((el) => el.key === 'JobType');
    const orderDateUpdateError = errors.find((el) => el.key === 'OrderDateUpdateUiError');

    if (jobTypeError) {
      const message = data.jobType && handleJobTypeError
        ? `${handleJobTypeError(data.jobType)} ${jobTypeError.message}`
        : jobTypeError.message;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
    }

    if (orderDateUpdateError) {
      const { message } = orderDateUpdateError;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
    }

    const priceDateUpdateUiError = errors.find((el) => el.key === 'PriceDateUpdateUiError');
    if (priceDateUpdateUiError) {
      const { message } = priceDateUpdateUiError;
      dispatch(setErrorMessage({ message, toastId: Math.random() }));
      setInitValue && setInitValue();
    }

    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const putOrderStatusThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  statusTransition: number,
  onCloseModal?: VoidFunctionType,
  rejectionReason?: string,
}
>('put/OrderStatus', async ({
  id, statusTransition, onCloseModal, rejectionReason,
}, { dispatch, getState, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await ordersAPI.changeOrderStatus(id, statusTransition, rejectionReason);
    dispatch(setLoading(false));
    const {
      orders: { orderTimelineFilters },
      core: { orderEventSources },
    } = getState() as RootState;
    dispatch(getOrderById({ id }));
    const orderSource = orderEventSources.find(({ label }) => label === OrderEventSource.Order)?.value;
    if (orderTimelineFilters.page === 1) {
      orderSource && dispatch(getOrderTimelineThunk({
        orderId: id,
        filters: orderTimelineFilters,
        source: orderSource,
      }));
    } else {
      dispatch(setOrderTimelineFilters({ ...orderTimelineFilters, page: 1 }));
    }
    onCloseModal && onCloseModal();
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    dispatch(setLoading(false));
    statusTransitionErrorHandle(errors, dispatch);
    onCloseModal && onCloseModal();
    return rejectWithValue(error.response?.data);
  }
});

export const getOrderLinksThunk = createAsyncThunk<
ResponseSingleResult<OrderLinksResType>, { id: number, filters: LinksFiltersType }
>('get/OrderLinks', async ({ id, filters }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await ordersAPI.fetchOrderLinks(id, filters);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const postOrderTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  orderId: number, content: string, setValue: VoidFunctionType
}>(
  'post/OrderTimelineNote',
  async ({
    orderId, content, setValue,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.postOrderTimelineNote(orderId, content);
      const {
        orders: { orderTimelineFilters },
        shared: { orderDetails },
        core: { orderEventSources },
      } = getState() as RootState;
      dispatch(setLoading(false));
      const orderSource = orderEventSources.find(({ label }) => label === OrderEventSource.Order)?.value;
      if (orderTimelineFilters.page === 1 && orderDetails) {
        orderSource && dispatch(getOrderTimelineThunk({
          orderId: orderDetails.order.id,
          filters: orderTimelineFilters,
          source: orderSource,
        }));
      } else {
        dispatch(setOrderTimelineFilters({ ...orderTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      setValue();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editOrderTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, content: string, closeModal: VoidFunctionType,
}>(
  'put/OrderTimelineNote',
  async ({
    id, content, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await ordersAPI.editOrderTimelineNote(id, content);
      const {
        orders: { orderTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventOrder[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = orderTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setOrderTimeline({ ...orderTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteOrderTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType, }
>(
  'delete/OrderTimelineNote',
  async ({
    id, closeModal,
  }, {
    dispatch, getState, rejectWithValue,
  }) => {
    dispatch(setFetching(true));
    try {
      const response = await ordersAPI.deleteOrderTimelineNote(id);
      const {
        orders: { orderTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters, page: 1 }));
      } else {
        dispatch(setOrderTimelineFilters({ ...orderTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeModal();
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderAffectedEntities = createAsyncThunk<
ResponseResult<OrderAffectedEntityDto[]>,
{ orderId: number, page: number, pageSize: number, action: VoidFunctionType, }>(
  'get/OrderAffectedEntities',
  async ({
    orderId, page, pageSize, action,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await ordersAPI.fetchOrderAffectedEntities(orderId, page, pageSize);
      dispatch(setLoading(false));
      action();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeOrderCustomerThunk = createAsyncThunk<
ResponseSingleResult,
{ orderId: number, customerId: number, closeModal: VoidFunctionType, }>(
  'put/OrderCustomer',
  async ({
    orderId, customerId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        orders: { orderTimelineFilters, orderContactsPaging },
        core: { orderEventSources },
      } = getState() as RootState;
      const res = await ordersAPI.changeOrderCustomer(orderId, customerId);
      const orderSource = orderEventSources.find(({ label }) => label === OrderEventSource.Order)?.value;
      closeModal();
      dispatch(setSuccessMessage({ message: 'Customer was successfully changed.', toastId: Math.random() }));
      dispatch(getOrderById({ id: orderId }));
      dispatch(getOrderContactsThunk({ orderId, page: orderContactsPaging.page, pageSize: orderContactsPaging.pageSize }));
      if (orderTimelineFilters.page === 1) {
        orderSource && dispatch(getOrderTimelineThunk({
          orderId,
          filters: orderTimelineFilters,
          source: orderSource,
        }));
      } else {
        dispatch(setOrderTimelineFilters({ ...orderTimelineFilters, page: 1 }));
      }
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
