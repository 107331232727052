import React from 'react';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import CreateUserForm from './components/CreateUserForm';
import { useAppSelector } from '../../store/hooks';
import {
  accountAccountTypesSelector, allowedJobTypesForUserSelector, allowedShortBaForUserSelector,
} from '../../store/selectors/coreSelectors';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';

const CreateUserContainer: React.FC = () => {
  const { backHandle } = useLocationHistoryState();
  const accountTypes = useAppSelector(accountAccountTypesSelector);
  const allowedShortBa = useAppSelector(allowedShortBaForUserSelector);
  const allowedJobTypesForUser = useAppSelector(allowedJobTypesForUserSelector);
  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new user</div>
    </DetailsHead>}
    >
      <CreateUserForm
        businessAreas={allowedShortBa}
        accountTypes={accountTypes}
        jobTypes={allowedJobTypesForUser}
      />
    </Layout>
  );
};

export default CreateUserContainer;
