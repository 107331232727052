import React from 'react';
import { JobEventDtoBase } from '../../../../types/jobViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { JobEventsEnum } from '../../../../enums/JobEventsEnum';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import JobNoteEvent from './JobNoteEvent';
import JobFieldUpdateEvent from './JobFieldUpdateEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { getOldAndNewLocationValues } from '../../../../../../common/utils/getOldAndNewLocationValues';
import JobLocationEvent from '../../../../../../common/components/activity-events/job-location/JobLocationEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import JobWorklogsEvent from './JobWorklogsEvent';
import { jobEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import { jobStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import JobQualityCheckEvent from './JobQualityCheckEvent';

type JobEventProps = {
  item: JobEventDtoBase,
  disabledManageNotes?: boolean,
}
const JobEvent: React.FC<JobEventProps> = ({ item, disabledManageNotes }) => {
  const eventTypes = useAppSelector(jobEventTypesSelector);
  const jobStatuses = useAppSelector(jobStatusesSelector);

  const attachmentEvents = [
    JobEventsEnum.AddingAttachmentWithoutComment,
    JobEventsEnum.AddingAttachmentWithComment,
    JobEventsEnum.EditingAttachmentComment,
    JobEventsEnum.DeletingAttachment,
  ];

  const qualityCheckEvents = [
    JobEventsEnum.JobQualityCheckCreation,
    JobEventsEnum.JobQualityCheckDeletion,
  ];

  const jobEventRenderFn = (item: JobEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.jobEventType)?.label || '';
    switch (true) {
      case (event === JobEventsEnum.Note):
        return <JobNoteEvent id={item.id} content={item.content} initiator={item.initiator} disabledManageNotes={disabledManageNotes} />;
      case (event === JobEventsEnum.JobCreation):
        return <CreationEvent entity="Job" type="number" value={item.jobNumber} />;
      case (event === JobEventsEnum.JobFieldUpdateOrAddingValue):
        return <JobFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (event === JobEventsEnum.JobWorklogsUpdate):
        return <JobWorklogsEvent assignee={item.assignee} oldTimeSpent={item.oldTimeSpent} newTimeSpent={item.newTimeSpent} />;
      case (event === JobEventsEnum.JobStatusChange): {
        const prevStatus = jobStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = jobStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case (event === JobEventsEnum.JobLocationUpdate): {
        const locationValues = getOldAndNewLocationValues(item);
        return <JobLocationEvent {...locationValues} />;
      }
      case (qualityCheckEvents.includes(event as JobEventsEnum)): {
        return <JobQualityCheckEvent event={event as JobEventsEnum} item={item} />;
      }
      case (attachmentEvents.includes(event as JobEventsEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      default: return null;
    }
  };

  return <>{jobEventRenderFn(item)}</>;
};

export default JobEvent;
