import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAppSelector } from '../../../store/hooks';
import { jobStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import {
  JobListTabParams,
  JobModesEnum,
  JobsModes,
  JobsSearchParamsNames,
  QueueCompletedModesEnum,
} from '../../jobs/enums/jobsEnums';

export const useJobsPageParams = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const jobStatuses = useAppSelector(jobStatusesSelector);

  const setPageParam = (mode: string) => {
    if (JobsModes.includes(mode as JobModesEnum)) {
      params.append(JobsSearchParamsNames.PAGE, mode);
    } else {
      params.delete(JobsSearchParamsNames.PAGE);
    }
    navigate({ search: `?${JobsSearchParamsNames.PAGE}=${mode}` }, { replace: true });
  };

  const setJobTabTypeParam = (tabMode: string) => {
    const page = params.get(JobsSearchParamsNames.PAGE);
    const isJobTabIncluded = JobListTabParams.includes(tabMode as QueueCompletedModesEnum);
    if (page === JobModesEnum.JOB_LIST && isJobTabIncluded) {
      if (params.get(JobsSearchParamsNames.TAB_TYPE)) {
        params.set(JobsSearchParamsNames.TAB_TYPE, tabMode);
      } else {
        params.append(JobsSearchParamsNames.TAB_TYPE, tabMode);
      }
      params.delete(JobsSearchParamsNames.JOB_STATUS);
    } else {
      params.delete(JobsSearchParamsNames.TAB_TYPE);
    }
    navigate({ search: params.toString() });
  };

  const setJobStatusParam = (mode: string) => {
    if (jobStatuses.find((st) => st.value.toString() === mode)) {
      if (params.get(JobsSearchParamsNames.JOB_STATUS)) {
        params.set(JobsSearchParamsNames.JOB_STATUS, mode);
      } else {
        params.append(JobsSearchParamsNames.JOB_STATUS, mode);
      }
    } else {
      params.delete(JobsSearchParamsNames.JOB_STATUS);
    }
    navigate({ search: params.toString() });
  };

  return {
    params,
    setPageParam,
    setJobStatusParam,
    setJobTabTypeParam,
  };
};
