import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { DictionaryItem } from '../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  CreatePartsKitFields,
  PartKitDefaults,
  PartsKitCodeSchema,
  PartsKitNameSchema,
} from '../types/CreatePartsKitTypes';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import Input from '../../../../core/components/input/Input';
import Select from '../../../../core/components/select/Select';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import Button from '../../../../core/components/button/Button';
import { partsKitAvailableCategoriesSelector } from '../../../../store/selectors/partsKitSelectors';
import { createPartsKitThunk } from '../../../../store/thunks/stock/parts-kit/partsKitCreateThunks';
import StockVehicles from '../../../common/components/stock-vehicles/StockVehicles';
import { useCreatePartSuitableVehicles } from '../../../common/hooks/useCreatePartSuitableVehicles';
import SelectVehiclesModal from '../../../common/components/select-vehicles-modal/SelectVehiclesModal';

type CreatePartsKitFormProps = {
  businessAreas: DictionaryItem[],
}

const CreatePartsKitForm:React.FC<CreatePartsKitFormProps> = ({ businessAreas }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const availableCategories = useAppSelector(partsKitAvailableCategoriesSelector);

  const {
    control, handleSubmit, reset, setError, setValue, clearErrors,
  } = useForm<CreatePartsKitFields>({
    defaultValues: PartKitDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const {
    isSelectVehicles,
    setSelectVehicles,
    selectedSource,
    selectedIds,
    selectedGroupIds,
    resetSuitableVehicles,
    handleSave,
    groups,
  } = useCreatePartSuitableVehicles();

  const cancel = () => {
    reset();
    clearErrors();
    resetSuitableVehicles();
  };

  const onSubmit = (values: CreatePartsKitFields) => {
    const data = {
      name: values.name.trim(),
      partsKitCode: values.code,
      categoryId: values.categoryId as number,
      businessAreas: values.businessAreas,
      suitableVehiclesSource: selectedSource,
      suitableForVehicleIds: selectedSource === groups ? null : (selectedIds || []),
      suitableForVehicleGroupIds: selectedSource === groups ? (selectedGroupIds || []) : null,
    };
    dispatch(createPartsKitThunk({
      data,
      reset,
      navigate,
      setError,
    }));
  };

  return (
    <>
      <SelectVehiclesModal
        isVisible={isSelectVehicles}
        onClose={() => setSelectVehicles(false)}
        initSelectedSource={selectedSource}
        initSelectedIds={selectedIds}
        initSelectedGroupIds={selectedGroupIds}
        saveChanges={handleSave}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="create-form details-form">
        <Controller
          control={control}
          name="name"
          rules={PartsKitNameSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('name', '');
                  setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              error={errors?.name?.message}
              label="Parts kit name"
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="code"
          rules={PartsKitCodeSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value.toUpperCase());
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('code', '');
                  setError('code', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              error={errors?.code?.message}
              label="Parts kit code"
              className="details-form__field"
            />
          )}
        />
        <Controller
          control={control}
          name="categoryId"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Category"
              options={availableCategories}
              className="details-form__field"
              error={errors?.categoryId?.message}
              showSearch
              showArrow
              isFilterOption
            />
          )}
        />
        <Controller
          control={control}
          name="businessAreas"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Parts kit business areas"
              options={businessAreas}
              className="details-form__field--lg"
              mode="multiple"
              showArrow
              error={errors?.businessAreas && ErrorsEnum.REQUIRED}
            />
          )}
        />
        <StockVehicles
          profileGroupIds={selectedGroupIds}
          profileIds={selectedIds}
        />
        <Button
          label="Select vehicles"
          icon={<FontAwesomeIcon icon={faEdit} />}
          onClick={() => setSelectVehicles(true)}
          className="details-form__field--justify-start"
        />
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          cancelClick={() => cancel()}
          className="details-form__actions"
        />
      </form>
    </>
  );
};

export default CreatePartsKitForm;
