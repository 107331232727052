import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  GetInvoiceDocumentsResponse,
  InvoiceDocumentsFilters,
} from '../../../invoicing/invoicing-purchases/types/InvoicingPurchasesTypes';
import { setFetching } from '../../slices/coreSlice';
import { invoicingAPI } from '../../../api/invoicingApi';
import { AxiosErrorResponse } from '../../../core/types/coreTypes';

export const getInvoiceDocumentsThunk = createAsyncThunk<GetInvoiceDocumentsResponse, {
  filters: InvoiceDocumentsFilters,
  type: 'sales' | 'purchases',
}>(
  'get/InvoiceDocuments',
  async ({ filters, type }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await invoicingAPI.fetchInvoiceDocuments(filters, type);
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
