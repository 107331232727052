import React, { useEffect, useMemo } from 'react';
import Thead from '../../../../core/components/table-components/Thead';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import Tbody from '../../../../core/components/table-components/Tbody';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import { GenericVoidFunctionType } from '../../../../core/types/coreTypes';
import { vehicleProfilesGroupsUiWithoutAllSelector } from '../../../../store/selectors/sharedSelectors';
import { useAppSelector } from '../../../../store/hooks';
import { useSelectAll } from '../../../../core/hooks/useSelectAll';

type VehicleGroupsSectionProps = {
  groupIds: number[],
  setGroupIds: GenericVoidFunctionType<number[]>,
}

const groupsColumns = [
  { title: 'Group name', dataIndex: 'name' },
  { title: 'Number of vehicle profiles', dataIndex: 'vehicleProfilesCount' },
];

const VehicleGroupsSection: React.FC<VehicleGroupsSectionProps> = ({
  groupIds,
  setGroupIds,
}) => {
  const { items } = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);

  const allItemIds = useMemo(() => {
    return items.map(({ id }) => id);
  }, [items]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
    setCheckedKeys,
  } = useSelectAll(allItemIds);

  useEffect(() => {
    setCheckedKeys(groupIds);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setGroupIds(checkedKeys);
    // eslint-disable-next-line
  }, [checkedKeys]);

  return (
    <TableNew>
      <Thead>
        <Tr>
          <ThNew checkbox>
            <Checkbox
              table
              checked={isAll}
              indeterminate={isIndeterminate}
              onChange={checkAllHandle}
            />
          </ThNew>
          {groupsColumns.map(({ title, dataIndex }) => <ThNew
            key={dataIndex}
            className={`vehicleGroupsSectionTh--${dataIndex}`}
          >
            {title}
          </ThNew>)}
        </Tr>
      </Thead>
      <Tbody>
        {items.map((cell) => <Tr key={cell.id}>
          <TdNew>
            <Checkbox
              checked={checkedKeys.includes(cell.id)}
              onChange={() => checkRowHandle(cell.id)}
              table
            />
          </TdNew>
          {Object.entries(cell)
            .map(([key, value]) => {
              if (key === 'id') return null;
              return <TdNew key={key}>
                {key === 'name'
                  ? <TruncateTooltip value={value} table />
                  : value}
              </TdNew>;
            })}
        </Tr>)}
      </Tbody>
    </TableNew>
  );
};

export default VehicleGroupsSection;
