import React from 'react';
import { faCar, faCubes, faTools } from '@fortawesome/free-solid-svg-icons';
import {
  orderNewStatusSelector,
  ordersColumnsPaginationSelector, ordersDashFiltersSelector, sortedOrderCollectionsSelector,
} from '../../../../store/selectors/ordersSelector';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { OrderColumnType, OrderDto } from '../../types/ordersTypes';
import { setOrderCollections } from '../../../../store/slices/ordersSlice';
import { getOrderCollectionsNextPortion } from '../../../../store/thunks/orders/dashOrdersThunks';
import { currentBusinessAreaSelector, isDashboardLoadingSelector, orderWarningsSelector } from '../../../../store/selectors/coreSelectors';
import DraggableCardsArea from '../../../../common/components/draggable-cards-column/DraggableCardsArea';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import usePermission from '../../../../permissions-handling/permissionHook';
import RejectStatusModal from '../../../../common/components/entity-status-change-modals/RejectStatusModal';
import { useOrdersStatusChange } from '../../hooks/useOrdersStatusChange';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { vehicleStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import DashboardSkeleton from '../../../../common/components/skeletons/dashboard-skeleton/DashboardSkeleton';
import { useDragEndDashboardCards } from '../../../../common/hooks/useDragAndDropDashboardCards';

type OrdersCardsColumnsProps = {
  cards: OrderColumnType | null,
  setCards: (value: OrderColumnType | null) => void,
}

const OrdersCardsColumns: React.FC<OrdersCardsColumnsProps> = ({ cards, setCards }) => {
  const allowedToEdit = usePermission(PermissionEnum.OrderEditFields);
  const dispatch = useAppDispatch();
  const sortedOrderCollections = useAppSelector(sortedOrderCollectionsSelector);
  const ordersColumnsPagination = useAppSelector(ordersColumnsPaginationSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const ordersDashFilters = useAppSelector(ordersDashFiltersSelector);
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);
  const warnings = useAppSelector(orderWarningsSelector);
  const statusNew = useAppSelector(orderNewStatusSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  const {
    isRejectStatus,
    setIsRejectStatus,
    isInvoicingConfirmation,
    setInvoicingConfirmation,
    isCancellationConfirmation,
    setCancellationConfirmation,
    setOrderTransit,
    editStatus,
    confirmRejectedStatusChange,
    confirmCancelledStatusChange,
    confirmInvoicingStatusChange,
    rejectedByCustomer,
    orderStatus,
    columnsLoading,
  } = useOrdersStatusChange(setCards);

  const currentFiltersState = [
    ordersDashFilters?.assignedTo,
    ordersDashFilters?.jobType,
    ordersDashFilters?.warnings,
    ordersDashFilters?.customerType,
    ordersDashFilters?.priority,
  ];

  const setNewPortion = (status: number, data: Array<OrderDto>) => {
    const newArr = cards?.map((el) => {
      if (el.status === status) {
        return {
          ...el,
          list: el.list ? [...el.list, ...data] : [...data],
        };
      } else {
        return el;
      }
    });
    newArr && dispatch(setOrderCollections(newArr));
  };
  const getNewPortion = async (page: number, status: number) => {
    currentBusinessArea && dispatch(getOrderCollectionsNextPortion({
      params: {
        ...ordersDashFilters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: [status],
        page,
      },
      currentStatus: status,
      setNewPortion,
    }));
  };
  const reformCardData = (card: OrderDto) => {
    return {
      id: card.id,
      entityNumber: card.orderNumber,
      customerName: card.customerName,
      customerType: card.customerType,
      jobType: card.jobType,
      priorityLevel: card.priorityLevel,
      cost: card.costString,
      assigneeFullName: card.assigneeFullName,
      isAssigneeDeleted: card.isAssigneeDeleted,
      warnings: card.warnings,
      dueDate: card.dueDate,
      vehicleStatus: card.vehicleStatus,
      isPartRequestSubmitted: card.isPartRequestSubmitted,
      readyCollectedPartsCount: 0,
      submittedPartsCount: 0,
      completedJobsCount: card.completedJobsCount,
      totalJobsCount: card.totalJobsCount,
      linkedEntities: [
        {
          label: 'Parts',
          icon: faCubes,
          value: card.isPartRequestSubmitted
            ? null
            : 'Not submitted',
          current: card.collectedNotForStockPartsCount,
          max: card.partRequestLinesCount,
        },
        {
          label: 'Jobs',
          icon: faTools,
          value: null,
          current: card.completedJobsCount,
          max: card.totalJobsCount,
        },
        {
          label: 'Vehicle',
          icon: faCar,
          value: vehicleStatuses.find((el) => el.value === card.vehicleStatus)?.label || '-',
        },
      ],
      showDraftVehicleWarning: card.status !== statusNew,
    };
  };

  const { onDragEnd } = useDragEndDashboardCards<OrderDto>(editStatus);
  return (
    <>
      <RejectStatusModal
        isVisible={isRejectStatus}
        onCancel={() => {
          setIsRejectStatus(false);
          setCards(sortedOrderCollections);
          setOrderTransit(undefined);
        }}
        onConfirm={(status, reason, onCancel) => confirmRejectedStatusChange(status, reason, onCancel)}
        rejectionStatus={rejectedByCustomer}
        customTitle="Move to Rejected by customer"
      />
      <ConfirmationModal
        isVisible={isCancellationConfirmation || isInvoicingConfirmation}
        onCancel={() => {
          isCancellationConfirmation && setCancellationConfirmation(false);
          isInvoicingConfirmation && setInvoicingConfirmation(false);
          setCards(sortedOrderCollections);
          setOrderTransit(undefined);
        }}
        customTitle="Confirm"
        customText={isInvoicingConfirmation
          ? 'You are about to move this order into "Invoicing" status and after this it will be locked for editing. '
              + 'This operation cannot be undone.'
          : 'You are about to mark this order as "Cancelled" and all part lines will be removed from this order. '
              + 'This operation cannot be undone.'}
        customCreateLabel={isInvoicingConfirmation ? 'Move to Invoicing' : 'Mark as cancelled'}
        confirmHandler={isInvoicingConfirmation ? confirmInvoicingStatusChange : confirmCancelledStatusChange}
      />
      {isLoading
        ? <DashboardSkeleton hasTotalCost />
        : <DraggableCardsArea
          columnsPagination={ordersColumnsPagination}
          getNewPortion={getNewPortion}
          onDragEnd={onDragEnd}
          cards={cards}
          setCards={setCards}
          statusDictionary={orderStatus}
          dashFilters={ordersDashFilters}
          currentFiltersState={currentFiltersState}
          reformCardData={(card: OrderDto) => reformCardData(card)}
          permissionToSeeCardPage={PermissionEnum.OrderViewPage}
          isDragDisabled={!allowedToEdit}
          setCardTransit={setOrderTransit}
          warnings={warnings}
          columnsLoading={columnsLoading}
        />}
    </>
  );
};

export default OrdersCardsColumns;
