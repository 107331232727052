import React, { useCallback, useEffect, useMemo } from 'react';
import { ProductSourceEnum } from '../../../../../common/types/commonTypes';
import { GenericObject, VoidFunctionType } from '../../../../../core/types/coreTypes';
import DistributeQtyLine from './DistributeQtyLine';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  integerQuantityMeasureValuesSelector,
  QuoteUnitsOfMeasureSelector,
} from '../../../../../store/selectors/coreSelectors';
import {
  stockPartRequestDetailsSelector,
  stockPartRequestLinesSelector, stockPartRequestsLinesViewInitSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import { StockPartProductSourceTotal } from '../../../../stock-part-requests/types/stockPartRequestTypes';
import {
  getStockPartRequestLineProductSourceTotal,
} from '../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { getPartProductSourceDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { productSourceDictionarySelector } from '../../../../../store/selectors/sharedSelectors';

type DistributeQuantityCellsProps = {
  sourceId?: number,
  checkedCells: string[],
  setCheckedCells: (value: string[]) => void,
  values: GenericObject<string> | null,
  setValues: (val: GenericObject<string> | null) => void,
  sourceTotal: StockPartProductSourceTotal | null,
  visible: boolean,
  isLinesView?: boolean,
}

const DistributeQuantityCells: React.FC<DistributeQuantityCellsProps> = ({
  sourceId, checkedCells, setCheckedCells, values, setValues, sourceTotal, visible, isLinesView,
}) => {
  const dispatch = useAppDispatch();
  const partRequest = useAppSelector(stockPartRequestDetailsSelector);
  const { items: partRequestLines } = useAppSelector(stockPartRequestLinesSelector);
  const { items: partRequestLinesView } = useAppSelector(stockPartRequestsLinesViewInitSelector);
  const sourceDictionary = useAppSelector(productSourceDictionarySelector);

  const quoteUnitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const integerQuantityMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const currentLine = useMemo(() => {
    const items = isLinesView ? partRequestLinesView : partRequestLines;
    const partFound = items.find((line) => line.id === sourceId);
    const children = items.map((line) => line.childLines).flat();
    const childFound = children?.find((line) => line?.id === sourceId);
    return partFound || childFound;
  }, [isLinesView, partRequestLinesView, partRequestLines, sourceId]);

  const unitOfMeasure = currentLine?.unitOfMeasure;

  const isWholeNumbers = useMemo(() => integerQuantityMeasures.includes(unitOfMeasure), [unitOfMeasure, integerQuantityMeasures]);
  const measureLabel = useMemo(() => quoteUnitsOfMeasure?.find((el) => el.value === unitOfMeasure)?.label, [
    unitOfMeasure, quoteUnitsOfMeasure,
  ]);

  const stockPrice = useMemo(() => {
    if (sourceTotal) {
      const maxMinHandle = (minPrice: string, maxPrice: string) => {
        if (minPrice === maxPrice) {
          return `${minPrice}`;
        } else return `${minPrice}-${maxPrice}`;
      };
      const maxSell = sourceTotal?.stockSellPriceMax || '0.00';
      const minSell = sourceTotal?.stockSellPriceMin || '0.00';
      const maxCost = sourceTotal?.stockCostPriceMax || '0.00';
      const minCost = sourceTotal?.stockCostPriceMin || '0.00';
      return {
        stockCostPrice: maxMinHandle(minCost, maxCost),
        stockSellPrice: maxMinHandle(minSell, maxSell),
      };
    } else {
      return {
        stockCostPrice: '0.00',
        stockSellPrice: '0.00',
      };
    }
  }, [sourceTotal]);

  const totalThunkHandle = (stockValue: number | null, pricelistValues: Array<{ pricelistId: number, quantity: number }> | null) => {
    const partRequestId = currentLine?.partRequestId;
    partRequestId && sourceId
        && dispatch(getStockPartRequestLineProductSourceTotal({
          partRequestId,
          lineId: sourceId,
          stockQty: stockValue,
          pricelists: pricelistValues,
        }));
  };
  const productSourceDictionaryThunkHandle = (handle: VoidFunctionType) => {
    const partId = currentLine?.partId || currentLine?.partsKitComponentPartId;
    partRequest && partId && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: partRequest.businessArea,
        customerId: partRequest.customerId,
        priceDate: partRequest.orderDate,
      },
      handle,
    }));
  };

  const getTotal = (v: GenericObject<string>) => {
    const checkedList = checkedCells
      .filter((cell) => cell !== ProductSourceEnum.STOCK)
      .filter((pr) => +v[pr] > 0);
    const pricelistValues = checkedList.length > 0 ? checkedList.map((l) => ({ pricelistId: +l, quantity: +v[l] })) : null;
    const stockValue = +v.stock;
    totalThunkHandle(stockValue, pricelistValues);
  };

  useEffect(() => {
    if (visible && currentLine) {
      const setValuesHandle = () => {
        let listV = {};
        const keys: string[] = [];
        if (currentLine.confirmedStockQuantity) {
          keys.push(ProductSourceEnum.STOCK);
        }
        if (currentLine.confirmedPricelists && currentLine.confirmedPricelists.length > 0) {
          const listKeys = currentLine.confirmedPricelists.map((pr) => pr.id.toString());
          listKeys.forEach((key) => {
            keys.push(key);
            listV = { ...listV, [key]: currentLine.confirmedPricelists?.find((el) => el.id === +key)?.quantity || 0 };
          });
        }
        setValues({
          ...listV,
          stock: currentLine.confirmedStockQuantity ? currentLine.confirmedStockQuantity.toString() : '0',
        });
        setCheckedCells(keys);
      };
      const pricelists = currentLine.confirmedPricelists && currentLine.confirmedPricelists.length > 0
        ? currentLine.confirmedPricelists.map((l) => ({ pricelistId: l.id, quantity: l.quantity }))
        : null;
      productSourceDictionaryThunkHandle(setValuesHandle);
      totalThunkHandle(
        currentLine.confirmedStockQuantity || null,
        pricelists,
      );
    }
    // eslint-disable-next-line
  }, [currentLine, visible]);

  const checkHandle = (value: string) => {
    if (checkedCells.includes(value)) {
      const newArr = checkedCells.filter((el) => el !== value);
      setCheckedCells(newArr);
    } else {
      setCheckedCells([...checkedCells, value]);
    }
  };

  const getPricelistPrice = useCallback(
    (id: number) => {
      const price = sourceTotal?.pricelistTotals?.find((l) => l.pricelistId === id);
      return {
        costPriceString: price?.costPriceString || '0',
        sellPriceString: price?.sellPriceString || '0',
      };
    },
    [sourceTotal],
  );

  const requestedPricelistQty = (pricelistId: number) => {
    return currentLine?.requestedPricelists?.find((el) => el.id === pricelistId);
  };
  return (
    <>
      <DistributeQtyLine
        sourceBoxValue={ProductSourceEnum.STOCK}
        checked={checkedCells.includes(ProductSourceEnum.STOCK)}
        sourceBoxOnChange={() => {
          if (checkedCells.includes(ProductSourceEnum.STOCK)) {
            const newValues = { ...values, stock: '0' };
            setValues(newValues);
            getTotal(newValues);
          }
          checkHandle(ProductSourceEnum.STOCK);
        }}
        disabledQty={!checkedCells.includes(ProductSourceEnum.STOCK)}
        text={`Take from stock (${
          sourceDictionary?.stockQuantityAvailable === undefined ? '-' : sourceDictionary.stockQuantityAvailable
        } ${measureLabel} available)`}
        disabledPlus={!values || +values.stock === sourceDictionary?.stockQuantityAvailable}
        qtyValue={values ? values.stock : '0'}
        requestedQty={currentLine?.requestedStockQuantity ? currentLine.requestedStockQuantity.toString() : '0'}
        setQtyValue={(v: string) => {
          const stockAvailable = sourceDictionary?.stockQuantityAvailable;
          if (stockAvailable !== undefined) {
            setValues({ ...values, stock: v });
            setTimeout(() => {
              if (v && +v > stockAvailable) {
                const newValues = { ...values, stock: stockAvailable.toString() };
                setValues(newValues);
                getTotal(newValues);
              } else {
                getTotal({ ...values, stock: v || '0' });
              }
            }, 100);
          }
        }}
        isDecimals={!isWholeNumbers}
        costPrice={stockPrice.stockCostPrice}
        sellingPrice={stockPrice.stockSellPrice}
      />
      {sourceDictionary?.pricelists?.map((pr) => <DistributeQtyLine
        key={pr.id}
        checked={(pr.id && checkedCells.includes(pr.id.toString())) || false}
        requestedQty={requestedPricelistQty(pr.id)?.quantity.toString() || '0'}
        sourceBoxValue={pr.name || ''}
        sourceBoxOnChange={() => {
          if (checkedCells.includes(pr.id.toString())) {
            const newValues = { ...values, [pr.id]: '0' };
            setValues(newValues);
            getTotal(newValues);
          }
          checkHandle(pr.id.toString());
        }}
        text={`Purchase from supplier - ${pr.name}`}
        disabledQty={!pr.id || !checkedCells.includes(pr.id.toString())}
        disabledMinus={!!values && (!values || !values[pr.id] || +values[pr.id] === 0)}
        qtyValue={values ? values[pr.id] : '0'}
        setQtyValue={(v: string) => {
          setValues({ ...values, [pr.id]: v });
          getTotal({ ...values, [pr.id]: v || '0' });
        }}
        isDecimals={!isWholeNumbers}
        costPrice={getPricelistPrice(pr.id)?.costPriceString}
        sellingPrice={getPricelistPrice(pr.id)?.sellPriceString}
      />)}
      <DistributeQtyLine
        sourceBoxValue={ProductSourceEnum.MANUAL}
        checked={checkedCells.includes(ProductSourceEnum.MANUAL)}
        text="Manual input"
        requestedQty={currentLine?.requestedManualInputQuantity ? currentLine?.requestedManualInputQuantity.toString() : '0'}
        isManual
        sellingPrice={sourceTotal?.manualInputSellPrice || '0.00'}
      />
    </>
  );
};

export default DistributeQuantityCells;
