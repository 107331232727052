import React, { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import { CoreAttachListElType, VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import './EnquiryStatusChange.scss';
import Input from '../../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import DraggerUpload from '../../../core/components/upload/Dragger';
import { useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import { guidGenerator } from '../../../core/utils/guidGenerator';
import { useBeforeAttachmentUpload } from '../../../core/hooks/useBeforeAttachmentUpload';
import { enquiryAcceptedStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

type SentToAcceptedModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  vehiclesNumber: number,
  onConfirm: (
    list: CoreAttachListElType[],
    comment: string,
    cancelHandle: VoidFunctionType,
    status: number,
  ) => void,
  isButtonDisabled?: boolean,
}

const SentToAcceptedModal: React.FC<SentToAcceptedModalProps> = ({
  isVisible, onCancel, vehiclesNumber, onConfirm, isButtonDisabled,
}) => {
  const acceptedStatus = useAppSelector(enquiryAcceptedStatusSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const [comment, setComment] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const { beforeUpload } = useBeforeAttachmentUpload(fileList, setFileList);

  const orderMessage = vehiclesNumber > 1 ? `${vehiclesNumber} orders for each vehicle` : '1 order';

  const cancelHandle = () => {
    onCancel();
    setComment('');
    error && setError('');
    setFileList([]);
  };
  const onConfirmHandle = () => {
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    acceptedStatus && onConfirm(list, comment.trim(), cancelHandle, acceptedStatus?.value);
  };

  return (
    <Modal visible={isVisible} onCancel={cancelHandle} title="Move to accepted" maskClosable={!fileList.length}>
      <div className="acceptWrap">
        <div className="acceptWrap__text">
          <span className="acceptWrap__text-title">
            You are about to move this enquiry to
            {' '}
            {/* eslint-disable-next-line */}
            "Accepted"
            {' '}
            status.
          </span>
          <span className="acceptWrap__text-title">
            This action will trigger
            {' '}
            <span className="boldText">
              automatic creation of
              {' '}
              {orderMessage}
            </span>
            .
          </span>
          <span className="acceptWrap__text-title">
            You can also attach a confirmation email from a customer below with your comment.
          </span>
        </div>
        <DraggerUpload
          name="content"
          multiple
          fileList={fileList}
          setFileList={setFileList}
          beforeUpload={beforeUpload}
          disabled={fileList.length === 10 || isLoading}
        />
        <Input
          label="Comment"
          value={comment}
          onChange={(e) => {
            const val = e.target.value;
            if (val.length > 200) {
              setError(maxLengthMessageHandle(200));
            } else {
              setError('');
            }
            setComment(val);
          }}
          error={error}
          className="acceptWrap__comment"
        />
        <div className="acceptWrap__subtext">
          Please note that this enquiry will no longer be available for editing after moving to
          {' '}
          {/* eslint-disable-next-line */}
          "Accepted"
          {' '}
          status.
          If any further changes are needed, please update a corresponding order record.
        </div>
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Move to accepted"
          cancelClick={cancelHandle}
          createClick={onConfirmHandle}
          createType="button"
          disabledCreate={!!error || isButtonDisabled}
          disabledCancel={isButtonDisabled}
        />
      </div>
    </Modal>
  );
};

export default SentToAcceptedModal;
