import React from 'react';
import Modal from '../modal/Modal';
import ButtonActions from '../button-actions/ButtonActions';
import { VoidFunctionType } from '../../types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type ConfirmationModalProps = {
  isVisible: boolean;
  onCancel: VoidFunctionType;
  confirmHandler: VoidFunctionType;
  isDelete?: boolean;
  customTitle?: string,
  customText?: React.ReactNode,
  customProceedText?: React.ReactNode,
  customCreateLabel?: string,
  className?: string,
  customCancelLabel?: string,
  isProceedQuestion?: boolean,
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  isVisible, onCancel, confirmHandler, isDelete = true, customTitle,
  customText, customProceedText, customCreateLabel, className, customCancelLabel,
  isProceedQuestion,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  return (
    <Modal
      title={customTitle || (isDelete ? 'Delete' : 'Remove')}
      visible={isVisible}
      onCancel={onCancel}
      className={className}
      destroyOnClose
    >
      <p className="confirmationText">
        <span>
          {customText || (isDelete
            ? 'The data will be permanently deleted.'
            : 'The records will be removed.')}
        </span>
        {isProceedQuestion && <span className="confirmationText__proceed">
          {customProceedText || 'Do you want to proceed?'}
        </span>}
      </p>
      <ButtonActions
        createType="button"
        createLabel={customCreateLabel || (isDelete ? 'Yes, delete' : 'Yes, remove')}
        createClick={confirmHandler}
        cancelLabel={customCancelLabel || 'Cancel'}
        cancelClick={onCancel}
        isLoading={isLoading || isFetching}
      />
    </Modal>
  );
};

export default ConfirmationModal;
