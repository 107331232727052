import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  initDataListWithIds, initDataList, initialCoreAttachmentsFilters,
} from '../../../../core/types/coreTypes';
import {
  setPartsKitAttachments,
  setPartsKitAttachmentsFilter, setPartsKitComponents, setPartsKitComponentsFilters, setPartsKitComponentsSummary,
  setPartsKitDetails, setPartsKitTimeline, setPartsKitTimelineFilters,
} from '../../../../store/slices/partsKitSlice';
import { initExtendedTimelineFilters } from '../../../../common/types/commonTypes';

export const clearPartsKitData = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setPartsKitAttachments(initDataListWithIds));
  dispatch(setPartsKitAttachmentsFilter(initialCoreAttachmentsFilters));
  dispatch(setPartsKitDetails(null));
  dispatch(setPartsKitComponentsFilters(null));
  dispatch(setPartsKitComponents({ components: [], allItemIds: [] }));
  dispatch(setPartsKitComponentsSummary(null));
  dispatch(setPartsKitTimelineFilters(initExtendedTimelineFilters));
  dispatch(setPartsKitTimeline(initDataList));
};
