import { z } from 'zod';
import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { NAME_REGEX } from '../../core/utils/regex';
import { maxLengthMessageHandle } from '../../core/utils/errorMessageHandle';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const NameSchema = z.object({
  name: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .max(100, maxLengthMessageHandle(100))
    .regex(
      NAME_REGEX,
      ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE,
    ),
});

export const EditNameSchema = NameSchema.pick({ name: true });

export type EditName = z.infer<typeof EditNameSchema>;

export const editNameDefaults: EditName = {
  name: '',
};
