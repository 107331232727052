import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { RectificationsByProductTableLine } from '../../../../../types/RectificationsByProductTypes';

type RectificationsByProductBodyProps = {
  data: RectificationsByProductTableLine[],
  columnsLength: number,
}

const RectificationsByProductBody: React.FC<RectificationsByProductBodyProps> = ({ data, columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const hiddenKeys = ['partId'];
  const nowrapKeys = ['number', 'usedInRectifications'];

  const renderTruncateTooltip = (value: string | number) => {
    return <TruncateTooltip value={value} table />;
  };

  const renderCellValue = (key: string, value: string | number, el: RectificationsByProductTableLine) => {
    if (nowrapKeys.includes(key)) {
      return value;
    }
    if (key === 'partCode') {
      return <PrivateLineLink
        to={`/${routesPath.STOCK}/part/${el.partId}`}
        permission={PermissionEnum.StockPartViewPage}
        fitContent
      >
        {renderTruncateTooltip(value)}
      </PrivateLineLink>;
    }
    return renderTruncateTooltip(value);
  };

  return (
    <Tbody>
      {data.length > 0 ? data.map((el) => (
        <Tr key={el.number}>
          {Object.entries(el).map(([key, value]) => {
            if (hiddenKeys.includes(key)) return null;
            return <TdNew key={key}>
              {renderCellValue(key, value, el)}
            </TdNew>;
          })}
          <TdNew />
        </Tr>
      ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default RectificationsByProductBody;
