import React from 'react';
import classNames from 'classnames';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import './LinksTable.scss';

type LinksTableProps = {
  children: React.ReactNode,
  className?: string,
}

const LinksTable: React.FC<LinksTableProps> = ({ children, className }) => {
  return (
    <TableNew className={classNames('linksTable', className)}>
      {children}
    </TableNew>
  );
};

export default LinksTable;
