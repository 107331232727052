import React from 'react';
import { SuitableForVehicleChangeEventData } from '../../../types/commonTypes';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import SuitableForVehiclesChangeColumns from './SuitableForVehiclesChangeColumns';
import SuitableForVehiclesChangeBody from './SuitableForVehiclesChangeRows';
import Pagination from '../../../../core/components/pagination/Pagination';
import { useSuitableForVehiclePagination } from './hooks/useSuitableForVehiclePagination';

type NewSuitableForVehiclesProps = {
  data: Omit<SuitableForVehicleChangeEventData, 'oldSuitableForVehicles' | 'oldSuitableForVehicleGroups'>;
}

const NewSuitableForVehicles: React.FC<NewSuitableForVehiclesProps> = ({
  data: { newSuitableForVehicleGroups = [], newSuitableForVehicles = [] },
}) => {
  const isGroup = !!newSuitableForVehicleGroups.length && !newSuitableForVehicles.length;
  const items = isGroup ? newSuitableForVehicleGroups : newSuitableForVehicles;

  const {
    PAGE_SIZE, page, setPage, totalItems, rows,
  } = useSuitableForVehiclePagination(items);

  return (
    <>
      <Pagination
        currentPage={page}
        pageSize={PAGE_SIZE}
        onChange={(page) => setPage(page)}
        totalItems={totalItems}
        showTotal={false}
        showSizeChanger={false}
        offsetBottom
      />
      <TableNew small>
        <SuitableForVehiclesChangeColumns isGroup={isGroup} />
        <SuitableForVehiclesChangeBody rows={rows} />
      </TableNew>
    </>
  );
};

export default NewSuitableForVehicles;
