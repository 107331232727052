import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import {
  CreateVehicleProfileRequest,
  GetVehicleProfilesResponse, GetVehicleProfilesDictionaryResponse, PutVehicleProfileRestModel,
  VehicleProfilesFilters,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { RootState } from '../../store';
import { setVehicleProfilesFilters } from '../../slices/vehiclesSlice';
import { DuplicateValue, ErrorsEnum } from '../../../core/enums/errorsEnum';
import { CreateVehicleProfileFields } from '../../../vehicles/vehicle-profiles/utils/vehicleProfilesDataUtil';

export const getVehicleProfiles = createAsyncThunk<
GetVehicleProfilesResponse,
{
  filters: VehicleProfilesFilters,
}
>(
  'get/VehicleProfiles',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.fetchVehiclesProfiles(filters);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createVehicleProfileThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, code: string }>,
{
  data: CreateVehicleProfileRequest,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<CreateVehicleProfileFields>,
}
>(
  'post/VehicleProfile',
  async ({
    data, closeModal, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { vehicles } = getState() as RootState;
      const { vehicleProfilesFilters } = vehicles;
      const response = await vehiclesAPI.createVehicleProfile(data);
      closeModal();
      const createdCode = response.data.data.code;
      dispatch(setSuccessMessage({ message: `Vehicle profile ${createdCode} was successfully created`, toastId: Math.random() }));
      dispatch(setVehicleProfilesFilters({ order: vehicleProfilesFilters.order, page: 1, pageSize: 10 }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const codeDuplicateErr = errors.find((err) => err.key === 'Code');
      dispatch(setFetching(false));
      if (codeDuplicateErr) {
        setError('code', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editVehicleProfileThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  data: PutVehicleProfileRestModel,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<CreateVehicleProfileFields>,
}
>(
  'put/VehicleProfile',
  async ({
    id, data, closeModal, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { vehicles } = getState() as RootState;
      const { vehicleProfilesFilters } = vehicles;
      const response = await vehiclesAPI.editVehicleProfile(id, data);
      dispatch(getVehicleProfiles({
        filters: { ...vehicleProfilesFilters },
      }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const codeDuplicateErr = errors.find((err) => err.key === 'Code');
      dispatch(setFetching(false));
      if (codeDuplicateErr) {
        setError('code', { type: `${DuplicateValue}`, message: ErrorsEnum.UNIQUE_VALUE });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteVehicleProfileThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  closeModal: VoidFunctionType,
}
>(
  'delete/VehicleProfile',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { vehicles } = getState() as RootState;
      const { vehicleProfilesFilters } = vehicles;
      const response = await vehiclesAPI.deleteVehicleProfile(id);
      dispatch(setVehicleProfilesFilters({ order: vehicleProfilesFilters.order, page: 1, pageSize: 10 }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleProfilesDictionary = createAsyncThunk<GetVehicleProfilesDictionaryResponse>(
  'get/VehicleProfilesDictionary',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleProfilesDictionary();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
