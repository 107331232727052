import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { vehicleLinksFiltersSelector } from '../../../../../store/selectors/vehiclesSelectors';
import LinksColumns from '../../../../../common/components/links-table/LinksColumns';
import { setVehicleLinksFilters } from '../../../../../store/slices/vehiclesSlice';
import { useLinks } from '../../../../../common/hooks/useLinks';

type VehicleLinksColumnsProps = {
  totalCount: number,
}

const VehicleLinksColumns: React.FC<VehicleLinksColumnsProps> = ({ totalCount }) => {
  const vehicleLinksFilters = useAppSelector(vehicleLinksFiltersSelector);

  const {
    onChangeSearchHandle,
    searchPressHandle,
    searchVl,
    pickerValue,
    setPickerValue,
    pickerHandle,
  } = useLinks(vehicleLinksFilters, setVehicleLinksFilters);

  return (
    <LinksColumns
      totalCount={totalCount}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={vehicleLinksFilters}
    />
  );
};

export default VehicleLinksColumns;
