import React, { useEffect, useMemo } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import RequisiteModal from '../../../common/components/requisite/RequisteModal';
import { AddressFields } from '../../../common/types/commonTypes';
import { initRequisite } from '../../../common/utils/data';
import AddressModal from '../../../common/components/address/AddressModal';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { usersFilterLookupSelector } from '../../../store/selectors/coreSelectors';
import { createRectificationThunk } from '../../../store/thunks/rectification/createRectificationThunks';
import { getOrderById } from '../../../store/thunks/orders/viewPageOrderThunks';
import { orderDetailsRequisitesSelector } from '../../../store/selectors/ordersSelector';
import { locationRequisiteFormHandle } from '../../../core/utils/locationRequisiteFormHandle';
import { setOrderDictionaryFilters } from '../../../store/slices/sharedSlice';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import TruncateDropdownLabel from '../../../common/components/truncate-dropdown-label/TruncateDropdownLabel';
import { orderDetailsSelector, ordersDictionarySelector } from '../../../store/selectors/sharedSelectors';
import CreateRectificationChips from './CreateRectificationChips';
import { CreateRectificationFlowEnum } from '../enums/createRectificationEnums';
import CreateRectificationCommonFields from './CreateRectificationCommonFields';
import RectificationFlowBasedFields from './RectificationFlowBasedFields';
import { customerDetailsRequisitesSetSelector } from '../../../store/selectors/customersSelectors';
import { defaultRectificationFields, RectificationFormFields } from '../types/createRectificationFormSchema';
import { useCreateRectification } from '../hooks/useCreateRectification';
import { useUsersDictionary } from '../../../common/hooks/useUsersDictionary';

const CreateRectificationForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const ordersDictionary = useAppSelector(ordersDictionarySelector);
  const usersLookup = useAppSelector(usersFilterLookupSelector);
  const customerDetailsRequisites = useAppSelector(customerDetailsRequisitesSetSelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const orderDetailsRequisites = useAppSelector(orderDetailsRequisitesSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const ordersUi = useMemo(() => ordersDictionary.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [ordersDictionary]);
  const usersUi = useMemo(() => usersLookup.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [usersLookup]);

  const {
    control, handleSubmit, reset, watch, setValue, clearErrors,
  } = useForm<RectificationFormFields>({
    defaultValues: defaultRectificationFields,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const { userSearch, setUserSearch, getUsersFn } = useUsersDictionary();

  const {
    flowValue, searchValue, setSearchValue,
    searchVehicleV, setSearchVehicleV, isAddressModal,
    setAddressModal, setRequisiteModal,
    requisiteModal, setIsShippingMode, isShippingMode,
    clearFields, requisiteSubmitHandler, getFilteredVehicles,
    addressFields,
    requisiteFields,
    setRequisiteFields,
    setAddressFields,
    isSame,
    setIsSame,
    isManual,
    setIsManual,
    requisiteCustomerId,
    setRequisiteCustomerId,
    sameRef,
  } = useCreateRectification(reset, clearErrors);
  const assignee = watch('assigneeId');
  const orderNo = watch('orderId');
  const vehicleId = watch('vehicleId');
  const disabledField = flowValue === CreateRectificationFlowEnum.REPAIR ? !vehicleId : !orderNo;

  const alwaysUseCustomerRequisite = flowValue === CreateRectificationFlowEnum.REPAIR
    ? customerDetailsRequisites.alwaysUseRequisites
    : orderDetailsRequisites.alwaysUseCustomerRequisites;

  const onSubmit = (values: RectificationFormFields) => {
    const { billing, shipping } = requisiteFields;
    const location = locationRequisiteFormHandle<AddressFields>(addressFields);
    const requisiteData = {
      billingDetails: (alwaysUseCustomerRequisite || (requisiteCustomerId.billing && !isManual.billing))
        ? null
        : billing,
      shippingDetails: (alwaysUseCustomerRequisite || (requisiteCustomerId.shipping && !isManual.shipping))
        ? null
        : isSame ? null : shipping,
      isShippingRequisiteSameAsBilling: alwaysUseCustomerRequisite ? null : isSame,
      billingCustomerId: alwaysUseCustomerRequisite
        ? null
        : requisiteCustomerId.billing ?? null,
      shippingCustomerId: alwaysUseCustomerRequisite
        ? null
        : isSame ? null : (requisiteCustomerId.shipping ?? null),
    };
    const finalData = {
      ...values,
      jobType: values.jobType as number,
      dueDate: values.dueDate || null,
      rectificationType: values.rectificationType as number,
      assigneeId: values.assigneeId as number,
      priorityLevel: values.priorityLevel as number,
      faultType: values.faultType,
      faultTypeIsEmpty: !values.faultType,
      description: values.description?.trim() || null,
      jobCompanyName: location.companyName as string | null,
      jobAddressLine1: location.addressLine1,
      jobAddressLine2: location.addressLine2,
      jobCityTown: location.cityTown,
      jobRegion: location.region,
      jobTelephone: location.telephone as string | null,
      jobPostalCode: location.postalCode,
      ...requisiteData,
    };
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      businessArea, vehicleId, orderId, customer, ...restData
    } = finalData;
    dispatch(createRectificationThunk({
      data: flowValue === CreateRectificationFlowEnum.REPAIR
        ? { businessArea, vehicleId, ...restData }
        : { orderId, ...restData },
      navigate,
      reset: () => clearFields(CreateRectificationFlowEnum.USUAL),
    }));
  };

  useEffect(() => {
    orderNo && dispatch(getOrderById({ id: orderNo }));
    // eslint-disable-next-line
  }, [orderNo]);

  useEffect(() => {
    if (orderDetails) {
      const { order } = orderDetails;
      setValue('jobType', order.jobType);
      const addressValues = {
        companyName: order.jobCompanyName,
        addressLine1: order.jobAddressLine1,
        addressLine2: order.jobAddressLine2,
        cityTown: order.jobCityTown,
        region: order.jobRegion,
        postalCode: order.jobPostalCode,
        telephone: order.jobTelephone,
      };
      setAddressFields(addressValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetails]);

  useEffect(() => {
    const {
      billing, shipping, isShippingSame, isShippingDetailsManualInput, isBillingDetailsManualInput, shippingCustomerId, billingCustomerId,
    } = orderDetailsRequisites;
    setIsSame(isShippingSame);
    setRequisiteFields({ billing, shipping });
    setRequisiteCustomerId({ billing: billingCustomerId, shipping: shippingCustomerId });
    setIsManual({ billing: isBillingDetailsManualInput, shipping: isShippingDetailsManualInput });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderDetailsRequisites]);

  useEffect(() => {
    const {
      billing, shipping, isShippingSame, customerId,
    } = customerDetailsRequisites;
    setIsSame(isShippingSame);
    setRequisiteFields({ billing, shipping });
    setRequisiteCustomerId({ billing: customerId, shipping: customerId });
    setIsManual({ billing: false, shipping: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerDetailsRequisites]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const trimmedVal = searchValue?.trim();
    if (trimmedVal) {
      const timeOutId = setTimeout(() => {
        dispatch(setOrderDictionaryFilters({
          keyword: trimmedVal,
          includeOrderNumber: true,
          includeCustomerName: true,
          includeEnquiryNumbers: false,
        }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(setOrderDictionaryFilters(undefined));
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (usersLookup && !assignee && currentUser) {
      const user = usersLookup.find((u) => u.value === currentUser.id);
      user && setValue('assigneeId', user.value);
    }
    // eslint-disable-next-line
  }, [usersLookup, assignee, currentUser]);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const trimmedVal = searchVehicleV?.trim();
    if (trimmedVal) {
      const timeOutId = setTimeout(() => {
        getFilteredVehicles(trimmedVal);
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else getFilteredVehicles(undefined);
    // eslint-disable-next-line
  }, [searchVehicleV]);

  const getRequisiteCustomerId = () => {
    const id = isShippingMode ? requisiteCustomerId.shipping : requisiteCustomerId.billing;
    return id
      || (flowValue === CreateRectificationFlowEnum.USUAL ? orderDetailsRequisites.customerId : customerDetailsRequisites.customerId);
  };

  return (
    <>
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        title="Job location"
        isOptionalFields
        addressFields={addressFields}
      />
      <RequisiteModal
        isVisible={requisiteModal}
        onCancel={() => {
          setRequisiteModal(false);
          setIsShippingMode(false);
          if (sameRef.current) {
            setIsSame(true);
            sameRef.current = false;
          }
        }}
        submitHandle={requisiteSubmitHandler}
        isOptionalFields
        requisite={isShippingMode ? requisiteFields.shipping : requisiteFields.billing}
        isShipping={isShippingMode}
        isManual={isShippingMode ? isManual.shipping : isManual.billing}
        entitySourceType="customer"
        entityId={requisiteModal ? getRequisiteCustomerId() : undefined}
      />
      <form className="formWrap details-form create-form" onSubmit={handleSubmit(onSubmit)}>
        <CreateRectificationChips
          clearFields={(v) => clearFields(v)}
          flowValue={flowValue}
        />
        <RectificationFlowBasedFields
          flowValue={flowValue}
          control={control}
          watch={watch}
          setValue={setValue}
          ordersUi={ordersUi}
          errors={errors}
          setSearchValue={setSearchValue}
          searchValue={searchValue}
          searchVehicleV={searchVehicleV}
          setSearchVehicleV={setSearchVehicleV}
        />
        <CreateRectificationCommonFields
          flowValue={flowValue}
          control={control}
          errors={errors}
          setValue={setValue}
          clearErrors={clearErrors}
          usersUi={usersUi}
          watch={watch}
          searchUserV={userSearch}
          setSearchUserV={setUserSearch}
          getFilteredAssignees={getUsersFn}
          setRequisiteModal={(isShipping: boolean) => {
            const firstFieldFilled = flowValue === CreateRectificationFlowEnum.REPAIR ? vehicleId : orderNo;
            if (firstFieldFilled) {
              if (isShipping) {
                setRequisiteModal(true);
                setIsShippingMode(true);
              } else {
                setRequisiteModal(true);
              }
            }
          }}
          requisiteFields={requisiteFields}
          disabledRequisiteSection={alwaysUseCustomerRequisite}
          isSame={isSame}
          onCheckSameBox={(val) => {
            if (val) {
              setIsShippingMode(false);
              setRequisiteModal(false);
              setRequisiteFields({ ...requisiteFields, shipping: initRequisite });
              setRequisiteCustomerId({ ...requisiteCustomerId, shipping: undefined });
              setIsManual({ ...isManual, shipping: false });
            } else {
              setIsShippingMode(true);
              setRequisiteModal(true);
              sameRef.current = true;
            }
            setIsSame(val);
          }}
          addressFields={addressFields}
          setAddressModal={setAddressModal}
          disabledField={disabledField}
        />
        <ButtonActions
          createLabel="Create"
          cancelLabel="Clear"
          createType="submit"
          cancelClick={() => clearFields(flowValue)}
          disabledCreate={disabledField}
          className="details-form__actions"
        />
      </form>
    </>
  );
};

export default CreateRectificationForm;
