import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  PartsKitComponentDto,
  PartsKitsDto,
  PartsKitsFiltersType,
} from '../../stock/parts-kits/dashboard/types/partsKitsTypes';
import {
  CoreAttachmentsFilters, CoreAttachmentsList,
  DataListType,
  DictionaryItem, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters, initPaging, initSmallPaging,
  ResponseResult,
  ResponseSingleResult,
} from '../../core/types/coreTypes';
import { getPartsKitsList } from '../thunks/stock/parts-kit/partsKitsThunks';
import {
  getPartsKitAffectedThunk,
  getPartsKitAvailableCategoriesThunk,
  getPartsKitById,
  getPartsKitComponents,
  getPartsKitComponentsParts,
  getPartsKitComponentsSummary,
  getPartsKitComponentTax,
  getPartsKitTimelineComponentsGroupAfterThunk,
  getPartsKitTimelineComponentsGroupBeforeThunk,
  getPartsKitTimelineThunk,
} from '../thunks/stock/parts-kit/partsKitsViewPageThunks';
import { ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';
import {
  InitialPartsKitComponentsTimelineGroupFilters, PartsKitAffectedEntityDto, PartsKitAffectedItemsFilters,
  PartsKitComponentGroupsItemDto,
  PartsKitComponentsFiltersType,
  PartsKitComponentsPartsFiltersInit,
  PartsKitComponentsSummaryType,
  PartsKitComponentsTimelineGroupFilters,
  PartsKitEventDtoBase,
} from '../../stock/parts-kits/view-page/types/partsKitViewPageTypes';
import { PartDtoType, StockFiltersType } from '../../stock/search/types/stockSearchTypes';
import { getPartsKitAttachments } from '../thunks/stock/parts-kit/partsKitAttachments';
import { PartCategoryDto } from '../../stock/part-categories/types/partCategoriesTypes';

interface IPartsKitState {
  partsKitDetails: PartsKitsDto | null,
  partsKitsFilters: PartsKitsFiltersType,
  partsKitsLists: DataListType<PartsKitsDto[]>,
  partsKitAffectedItems: DataListType<PartsKitAffectedEntityDto[]>,
  partsKitAffectedItemsFilters: PartsKitAffectedItemsFilters,
  partsKitAttachments: CoreAttachmentsList,
  partsKitAttachmentsFilters: CoreAttachmentsFilters,
  partsKitAvailableCategories: Array<PartCategoryDto> | null,
  partsKitTimelineFilters: ExtendedTimelineFilters,
  partsKitTimeline: DataListType<PartsKitEventDtoBase[]>,
  partsKitTimelineComponentsGroup: {
    after: DataListType<PartsKitComponentGroupsItemDto[]>,
    before: DataListType<PartsKitComponentGroupsItemDto[]>,
  },
  partsKitTimelineComponentsGroupFilters: PartsKitComponentsTimelineGroupFilters,
  partsKitComponentTax: DictionaryItem[] | null,
  partsKitComponents: { components: PartsKitComponentDto[], allItemIds: number[] },
  partsKitComponentsFilters?: PartsKitComponentsFiltersType,
  partsKitComponentsSummary: PartsKitComponentsSummaryType | null,
  partsKitComponentsPartsFilters: StockFiltersType,
  partsKitComponentsParts: DataListType<PartDtoType[], { allItemIds: number[] }>,
}

export const initialPartsKitState: IPartsKitState = {
  partsKitDetails: null,
  partsKitsFilters: initPaging,
  partsKitsLists: initDataList,
  partsKitAffectedItems: initDataList,
  partsKitAffectedItemsFilters: initSmallPaging,
  partsKitAttachments: initDataListWithIds,
  partsKitAttachmentsFilters: initialCoreAttachmentsFilters,
  partsKitAvailableCategories: null,
  partsKitTimelineFilters: initExtendedTimelineFilters,
  partsKitTimeline: initDataList,
  partsKitTimelineComponentsGroup: {
    after: initDataList,
    before: initDataList,
  },
  partsKitTimelineComponentsGroupFilters: InitialPartsKitComponentsTimelineGroupFilters,
  partsKitComponents: { components: [], allItemIds: [] },
  partsKitComponentTax: null,
  partsKitComponentsFilters: undefined,
  partsKitComponentsSummary: null,
  partsKitComponentsPartsFilters: PartsKitComponentsPartsFiltersInit,
  partsKitComponentsParts: initDataListWithIds,
};

export const partsKitSlice = createSlice({
  name: 'partsKit',
  initialState: initialPartsKitState,
  reducers: {
    setPartsKitDetails: (state, action: PayloadAction<PartsKitsDto | null>) => {
      state.partsKitDetails = action.payload;
    },
    setPartsKitsFilters: (state, action: PayloadAction<PartsKitsFiltersType>) => {
      state.partsKitsFilters = action.payload;
    },
    setPartsKitList: (state, action: PayloadAction<DataListType<PartsKitsDto[]>>) => {
      state.partsKitsLists = action.payload;
    },
    setAffectedItemsPaging: (state, action: PayloadAction<PartsKitAffectedItemsFilters>) => {
      state.partsKitAffectedItemsFilters = action.payload;
    },
    setPartsKitAffectedItems: (state, action: PayloadAction<DataListType<PartsKitAffectedEntityDto[]>>) => {
      state.partsKitAffectedItems = action.payload;
    },
    setPartsKitAttachmentsFilter: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.partsKitAttachmentsFilters = action.payload;
    },
    setPartsKitAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.partsKitAttachments = action.payload;
    },
    setPartsKitTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.partsKitTimelineFilters = action.payload;
    },
    setPartsKitTimeline: (state, action: PayloadAction<DataListType<PartsKitEventDtoBase[]>>) => {
      state.partsKitTimeline = action.payload;
    },
    setPartsKitTimelineComponentsGroup: (
      state,
      action: PayloadAction<{
        after: DataListType<PartsKitComponentGroupsItemDto[]>,
        before: DataListType<PartsKitComponentGroupsItemDto[]>,
      }>,
    ) => {
      state.partsKitTimelineComponentsGroup = action.payload;
    },
    setPartsKitTimelineComponentsGroupFilters: (state, action: PayloadAction<PartsKitComponentsTimelineGroupFilters>) => {
      state.partsKitTimelineComponentsGroupFilters = action.payload;
    },
    setPartsKitComponentsFilters: (state, action: PayloadAction<PartsKitComponentsFiltersType | null>) => {
      state.partsKitComponentsFilters = action.payload ? action.payload : undefined;
    },
    setPartsKitComponents: (state, action: PayloadAction<{ components: PartsKitComponentDto[], allItemIds: number[] }>) => {
      state.partsKitComponents = action.payload;
    },
    setPartsKitComponentsSummary: (state, action: PayloadAction<PartsKitComponentsSummaryType | null>) => {
      state.partsKitComponentsSummary = action.payload;
    },
    setPartsKitComponentsPartsFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.partsKitComponentsPartsFilters = action.payload;
    },
    setPartsKitComponentsParts: (
      state,
      action: PayloadAction<DataListType<PartDtoType[], { allItemIds: number[] }>>,
    ) => {
      state.partsKitComponentsParts = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPartsKitById.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ partsKit: PartsKitsDto }> | null>) => {
        state.partsKitDetails = action.payload?.data.partsKit || null;
      },
    );
    builder.addCase(getPartsKitsList.fulfilled, (state, action: PayloadAction<ResponseResult<PartsKitsDto[]> | null>) => {
      state.partsKitsLists = action.payload?.data || initDataList;
    });
    builder.addCase(
      getPartsKitAffectedThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitAffectedEntityDto[]>>) => {
        state.partsKitAffectedItems = action.payload ? action.payload?.data : initDataList;
      },
    );
    builder.addCase(
      getPartsKitAttachments.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.partsKitAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getPartsKitAvailableCategoriesThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartCategoryDto[]> | null>) => {
        state.partsKitAvailableCategories = action.payload?.data.items || null;
      },
    );
    builder.addCase(
      getPartsKitTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitEventDtoBase[]> | null>) => {
        const data = action.payload?.data;
        state.partsKitTimeline = data || initDataList;
      },
    );
    builder.addCase(
      getPartsKitTimelineComponentsGroupBeforeThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitComponentGroupsItemDto[]>>) => {
        const { items, pages, totalCount } = action.payload.data;
        if (items) {
          state.partsKitTimelineComponentsGroup = {
            ...state.partsKitTimelineComponentsGroup,
            before: {
              items: state.partsKitTimelineComponentsGroupFilters.page > 1
                ? [...state.partsKitTimelineComponentsGroup.before.items, ...items]
                : items,
              pages,
              totalCount,
            },
          };
        }
      },
    );
    builder.addCase(
      getPartsKitTimelineComponentsGroupAfterThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitComponentGroupsItemDto[]>>) => {
        const { items, pages, totalCount } = action.payload.data;
        if (items) {
          state.partsKitTimelineComponentsGroup = {
            ...state.partsKitTimelineComponentsGroup,
            after: {
              items: state.partsKitTimelineComponentsGroupFilters.page > 1
                ? [...state.partsKitTimelineComponentsGroup.after.items, ...items]
                : items,
              pages,
              totalCount,
            },
          };
        }
      },
    );
    builder.addCase(
      getPartsKitComponents.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ components: PartsKitComponentDto[], allItemIds: number[] }>>) => {
        state.partsKitComponents = action.payload.data;
      },
    );
    builder.addCase(
      getPartsKitComponentTax.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }> | null>) => {
        state.partsKitComponentTax = action.payload?.data.items || null;
      },
    );
    builder.addCase(
      getPartsKitComponentsSummary.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<PartsKitComponentsSummaryType> | null>) => {
        state.partsKitComponentsSummary = action.payload?.data || null;
      },
    );
    builder.addCase(
      getPartsKitComponentsParts.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[], { allItemIds: number[] }>>) => {
        state.partsKitComponentsParts = action.payload.data;
      },
    );
  },
});

export const {
  setPartsKitDetails,
  setPartsKitsFilters,
  setPartsKitList,
  setAffectedItemsPaging,
  setPartsKitAffectedItems,
  setPartsKitAttachmentsFilter,
  setPartsKitAttachments,
  setPartsKitTimelineFilters,
  setPartsKitTimeline,
  setPartsKitTimelineComponentsGroupFilters,
  setPartsKitTimelineComponentsGroup,
  setPartsKitComponentsFilters,
  setPartsKitComponentsPartsFilters,
  setPartsKitComponentsParts,
  setPartsKitComponents,
  setPartsKitComponentsSummary,
} = partsKitSlice.actions;

export default partsKitSlice.reducer;
