import React from 'react';
import CoreNonCoreColumns from './CoreNonCoreColumns';
import CoreNonCoreBody from './CoreNonCoreBody';
import { NumberFunctionType, VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';

type CoreNonCoreTableProps = {
  checkedKeys: number[],
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkRow: NumberFunctionType,
  mode: AddComponentModeEnum,
}

const CoreNonCoreTable: React.FC<CoreNonCoreTableProps> = ({
  checkedKeys, isAll, isIndeterminate, checkAll, checkRow, mode,
}) => {
  return (
    <TableNew>
      <CoreNonCoreColumns
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAll}
        mode={mode}
      />
      <CoreNonCoreBody
        checkedKeys={checkedKeys}
        checkRow={checkRow}
      />
    </TableNew>
  );
};

export default CoreNonCoreTable;
