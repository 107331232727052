import React from 'react';
import { useAppSelector } from '../../../../store/hooks';
import { invoiceDocumentsSummarySelector } from '../../../../store/selectors/invoicingSelectors';
import { toPoundCurrency } from '../../../../common/utils/formatUtils';
import './SpecificEntitiesSummary.scss';

const SpecificEntitiesSummary: React.FC = () => {
  const {
    draftInvoicesSummary, draftCreditNotesSummary, exportedInvoicesSummary, exportedCreditNotesSummary,
  } = useAppSelector(invoiceDocumentsSummarySelector);

  const invoicesProperties = {
    title: 'Selected for export (invoices)',
    draft: {
      numberOfInvoices: draftInvoicesSummary?.invoiceDocumentsCount || 0,
      totalNet: toPoundCurrency(draftInvoicesSummary?.totalNet),
      totalGross: toPoundCurrency(draftInvoicesSummary?.totalGross),
    },
    exported: {
      numberOfInvoices: exportedInvoicesSummary?.invoiceDocumentsCount || 0,
      totalNet: toPoundCurrency(exportedInvoicesSummary?.totalNet),
      totalGross: toPoundCurrency(exportedInvoicesSummary?.totalGross),
    },
  };
  const creditProperties = {
    title: 'Selected for export (credit notes)',
    draft: {
      numberOfInvoices: draftCreditNotesSummary?.invoiceDocumentsCount || 0,
      totalNet: toPoundCurrency(draftCreditNotesSummary?.totalNet),
      totalGross: toPoundCurrency(draftCreditNotesSummary?.totalGross),
    },
    exported: {
      numberOfInvoices: exportedCreditNotesSummary?.invoiceDocumentsCount || 0,
      totalNet: toPoundCurrency(exportedCreditNotesSummary?.totalNet),
      totalGross: toPoundCurrency(exportedCreditNotesSummary?.totalGross),
    },
  };
  const summaryList = [invoicesProperties, creditProperties];
  return (
    <section className="specificEntitiesSummary">
      {summaryList.map((el) => <div key={el.title} className="specificEntitiesSummary__col">
        <h2 className="specificEntitiesSummary__title">
          {el.title}
        </h2>
        <p className="specificEntitiesSummary__content-line">
          <span>{`Draft (${el.draft.numberOfInvoices})`}</span>
          <span>{el.draft.totalNet}</span>
          <span>{el.draft.totalGross}</span>
        </p>
        <p className="specificEntitiesSummary__content-line">
          <span>{`Exported (${el.exported.numberOfInvoices})`}</span>
          <span>{el.exported.totalNet}</span>
          <span>{el.exported.totalGross}</span>
        </p>
      </div>)}
    </section>
  );
};

export default SpecificEntitiesSummary;
