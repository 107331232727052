import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { UseFormReset } from 'react-hook-form';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { useAppSelector } from '../../../../../store/hooks';
import Button from '../../../../../core/components/button/Button';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import AddContactModal from '../../../../../common/components/contacts/AddContactModal';
import { infiniteScrollInitPaging, initDataListWithIds } from '../../../../../core/types/coreTypes';
import { initAddressFields } from '../../../../../common/types/commonTypes';
import { createContactUtil } from '../../../../../common/utils/createContactUtils';
import ContactLine from '../../../../../common/components/contacts/ContactLine';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import { CustomerContactFiltersType } from '../../../../../customers/customer-view-page/types/customerVewPageTypes';
import AddressModal from '../../../../../common/components/address/AddressModal';
import { customerDefaultContactsOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { useAddContact } from '../../../../../common/components/contacts/useAddContact';
import {
  isRectificationInReadModeSelector,
  rectificationContactsPagingSelector,
  rectificationContactsSelector,
  rectificationExistingContactsPagingSelector,
  rectificationExistingContactsSelector,
} from '../../../../../store/selectors/rectificationsSelector';
import {
  addRectificationExistingContactsThunk,
  createNewRectificationContactThunk, getRectificationContactsThunk,
  getRectificationExistingContactsThunk,
  removeRectificationContactThunk,
} from '../../../../../store/thunks/rectification/rectificationContactsThunks';
import {
  setRectificationContactsPaging,
  setRectificationExistingContacts,
  setRectificationExistingContactsPaging,
} from '../../../../../store/slices/rectificationsSlice';
import { rectificationDetailsSelector } from '../../../../../store/selectors/sharedSelectors';
import Restricted from '../../../../../permissions-handling/Restricted';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { NewContactFormFields } from '../../../../../common/types/createNewContactSchema';

const RectificationContacts:React.FC = () => {
  const rectificationContacts = useAppSelector(rectificationContactsSelector);
  const rectificationContactsPaging = useAppSelector(rectificationContactsPagingSelector);
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const rectificationExistingContacts = useAppSelector(rectificationExistingContactsSelector);
  const rectificationExistingContactsPaging = useAppSelector(rectificationExistingContactsPagingSelector);
  const defaultExistingContactsOrdering = useAppSelector(customerDefaultContactsOrderingSelector);
  const rectification = rectificationDetails?.rectification;
  const isRectificationInReadMode = useAppSelector(isRectificationInReadModeSelector);
  const allowedToManage = usePermission(PermissionEnum.RectificationEditFields);

  const disabledContactsInteractions = !allowedToManage || isRectificationInReadMode;

  const {
    id,
    dispatch,
    isRemove,
    setIsRemove,
    lineId,
    setLineId,
    addContact,
    setAddContact,
    isLocation,
    setLocation,
    addressFields,
    setAddressFields,
    mode,
    setMode,
  } = useAddContact();

  const loadMore = () => {
    const newValues = { ...rectificationContactsPaging, page: rectificationContactsPaging.page + 1 };
    dispatch(setRectificationContactsPaging(newValues));
  };

  useEffect(() => {
    id && dispatch(getRectificationContactsThunk({ rectificationId: +id, ...rectificationContactsPaging, page: 1 }));
    return () => {
      dispatch(setRectificationContactsPaging(infiniteScrollInitPaging));
    };
    // eslint-disable-next-line
  }, [])

  const onCancel = () => {
    setMode('');
    dispatch(setRectificationExistingContactsPaging({ ...rectificationExistingContactsPaging, page: 1 }));
    dispatch(setRectificationExistingContacts(initDataListWithIds));
  };
  const closeModal = () => {
    setAddressFields(initAddressFields);
    setAddContact(false);
    onCancel();
  };

  const createNewContact = (values: NewContactFormFields, reset: UseFormReset<NewContactFormFields>) => {
    const { location, valuesCopy } = createContactUtil(addressFields, values);
    id && dispatch(createNewRectificationContactThunk({
      rectificationId: +id,
      data: {
        ...location,
        ...valuesCopy,
      },
      closeModal: () => {
        setMode('');
        setAddressFields(initAddressFields);
        reset();
        setAddContact(false);
      },
    }));
  };

  const getExistingContacts = () => {
    rectification && defaultExistingContactsOrdering && dispatch(getRectificationExistingContactsThunk({
      customerId: rectification.customerId,
      filters: { ...rectificationExistingContactsPaging, order: defaultExistingContactsOrdering },
      notAddedToRectificationId: rectification.id,
    }));
  };

  const addSelectedContacts = (customerContactIds: number[]) => {
    rectification && dispatch(addRectificationExistingContactsThunk({
      rectificationId: rectification.id,
      customerId: rectification.customerId,
      customerContactIds,
      closeModal,
    }));
  };

  const setExistedContactsPaging = (filters: CustomerContactFiltersType) => {
    dispatch(setRectificationExistingContactsPaging(filters));
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isRemove}
        onCancel={() => {
          setIsRemove(false);
          setLineId(undefined);
        }}
        isDelete={false}
        confirmHandler={() => id && lineId && dispatch(removeRectificationContactThunk({
          rectificationId: +id,
          customerContactId: lineId,
          closeModal: () => {
            setIsRemove(false);
            setLineId(undefined);
          },
        }))}
      />
      <AddContactModal
        isVisible={addContact}
        onCancel={onCancel}
        closeModal={closeModal}
        addressFields={addressFields}
        setLocation={setLocation}
        setAddressFields={setAddressFields}
        companyNameValue={rectification?.customerName || ''}
        createNewContact={createNewContact}
        mode={mode}
        setMode={setMode}
        existedContacts={rectificationExistingContacts}
        getExistingContacts={getExistingContacts}
        existingPaging={rectificationExistingContactsPaging}
        addSelectedContacts={addSelectedContacts}
        setExistedContactsPaging={setExistedContactsPaging}
      />
      <AddressModal
        isVisible={isLocation}
        onCancel={() => {
          setLocation(false);
        }}
        addressFields={addressFields}
        onSubmit={(values) => {
          setAddressFields(values);
          setLocation(false);
        }}
        isExtendedFields={false}
      />
      <section className="contacts">
        <Restricted to={PermissionEnum.RectificationEditFields}>
          <Button
            label="Add contact"
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            htmlType="button"
            onClick={() => setAddContact(true)}
            designType="dark"
            reversed
            disabled={disabledContactsInteractions}
          />
        </Restricted>
      </section>
      <div className="contacts__wrapper">
        {rectificationContacts.items?.length > 0
          ? <InfiniteScroll
            dataLength={rectificationContacts.items.length}
            next={loadMore}
            hasMore={rectificationContactsPaging.page < rectificationContacts.pages}
            loader={<span className="contacts__loader">Loading...</span>}
          >
            <ul className="lines-list">
              {rectificationContacts.items.map((line) => (
                <li
                  className="lines-list__item"
                  key={line.id}
                >
                  <ContactLine
                    setIsRemove={(id) => {
                      setIsRemove(true);
                      setLineId(id);
                    }}
                    line={line}
                    deleteContactPermission={PermissionEnum.RectificationEditFields}
                    disabled={disabledContactsInteractions}
                  />
                </li>))}
            </ul>
          </InfiniteScroll>
          : <EmptyContentList icon={faAddressCard} text="Please add a contact to this rectification" />}
      </div>
    </>
  );
};

export default RectificationContacts;
