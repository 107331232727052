import React from 'react';
import { StringFunctionType } from '../../../../../core/types/coreTypes';
import { responsiveEndpointsEnum } from '../../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../../core/components/selectable/Selectable';
import Segmented from '../../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../../core/hooks/useScreenWidth';
import { PartsKitsViewPageEnums } from '../../enums/PartsKitsViewPageEnums';

type PartsKitContentTabsProps = {
  mode: string | null;
  setModeParam: StringFunctionType;
  allowedTabs: { label: string, value: PartsKitsViewPageEnums }[],
}

const PartsKitContentTabs:React.FC<PartsKitContentTabsProps> = ({
  mode, setModeParam, allowedTabs,
}) => {
  const { windowWidth } = useScreenWitdh();
  return (
    <>
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      {windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
        <div className="desktop-sticky-filters">
          <Segmented
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
    </>
  );
};

export default PartsKitContentTabs;
