import React, { useEffect } from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
import classNames from 'classnames';
import './DatePicker.scss';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';

type DatePickerProps = {
  value?: moment.Moment,
  onChange: (value: moment.Moment | null, dateString: string) => void,
  label?: string,
  error?: string,
  className?: string,
  allowClear?: boolean,
  disabled?: boolean,
  placement?: 'bottomLeft' | 'bottomRight' | 'topLeft' | 'topRight',
  format?: string,
  disabledDate?: (currentDate: moment.Moment) => boolean,
  placeholder?: string,
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
  onChange, label, error, className,
  allowClear, disabled, placement, format,
  disabledDate, value, placeholder,
}) => {
  const { windowWidth } = useScreenWitdh();

  // we need to disable input on mobile since the visible keyboard leads to bugs
  // but it is still possible to open calendar by clicking on input
  useEffect(() => {
    const inputsArray = Array.from(document.querySelectorAll('.pickerWrap input'));
    const disabledAttributeCondition = (windowWidth <= responsiveEndpointsEnum.LG) || disabled;
    if (disabledAttributeCondition) {
      inputsArray.forEach((el) => el.setAttribute('disabled', 'true'));
    } else {
      !disabled && inputsArray.forEach((el) => el.removeAttribute('disabled'));
    }
  }, [disabled, windowWidth]);

  return (
    <div className={classNames(className, 'pickerWrap')}>
      {label && <span className="pickerWrap__label">{label}</span>}
      <DatePicker
        value={value}
        onChange={onChange}
        className="pickerWrap__field"
        allowClear={allowClear}
        disabled={disabled}
        placement={placement}
        format={format}
        disabledDate={disabledDate}
        placeholder={placeholder}
      />
      {error && <span className="pickerWrap__error">{error}</span>}
    </div>
  );
};

export default DatePickerComponent;
