import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { stockPartAffectedEntitiesFiltersSelector, stockPartAffectedListSelector } from '../../../../../store/selectors/stockSelectors';
import {
  initDataList, initSmallPaging, VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import { setPartAffectedEntities, setPartAffectedEntitiesFilters } from '../../../../../store/slices/stockSlice';
import { getStockPartAffectedThunk } from '../../../../../store/thunks/stock/part/partViewPageThunks';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';
import AffectedEntitiesModal from '../../../../../common/components/affected-entities-modal/AffectedEntitiesModal';
import AffectedEntitiesWithLinksTable from '../../../../../common/components/affected-entities-modal/AffectedEntitiesWithLinkTable';

type PartAffectedEntitiesModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  partName: string,
}

const PartAffectedEntitiesModal: React.FC<PartAffectedEntitiesModalProps> = ({ visible, onClose, partName }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const filters = useAppSelector(stockPartAffectedEntitiesFiltersSelector);
  const { items, totalCount } = useAppSelector(stockPartAffectedListSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);

  useEffect(() => {
    if (visible && id) {
      dispatch(getStockPartAffectedThunk({
        id: +id,
        filters,
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const handleClose = () => {
    onClose();
    dispatch(setPartAffectedEntitiesFilters(initSmallPaging));
    dispatch(setPartAffectedEntities(initDataList));
  };

  const handlePagination = (page: number, pageSize: number) => {
    dispatch(setPartAffectedEntitiesFilters({ ...filters, page, pageSize }));
  };

  const text = filters.businessAreas
    ? 'Operation cannot be finished, because the following entities require deleted business area value(s):'
    : <>
      Operation cannot be finished, because part
      {' '}
      <b>{`"${partName}"`}</b>
      {' '}
      is used in multiple active entities in the system:
    </>;

  return (
    <AffectedEntitiesModal
      visible={visible}
      handleClose={handleClose}
      text={text}
      handlePagination={handlePagination}
      page={filters.page}
      pageSize={filters.pageSize}
      totalCount={totalCount}
    >
      <AffectedEntitiesWithLinksTable
        items={items}
        isLoading={isLoading}
      />
    </AffectedEntitiesModal>
  );
};

export default PartAffectedEntitiesModal;
