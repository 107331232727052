import React, { useEffect, useState } from 'react';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import Button from '../../../core/components/button/Button';
import Segmented from '../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import JobLineExpandedData from './JobLineExpandedData';
import { ExpandedViewMode } from '../enums/JobListEnums';
import { getJobByIdThunk } from '../../../store/thunks/jobs/jobThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { jobDetailsExpandedViewSelector } from '../../../store/selectors/jobsSelector';
import Tag from '../../../core/components/tag/Tag';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';
import { jobStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { playPauseJobThunk } from '../../../store/thunks/jobs/jobListThunks';
import { isCurrentRoleAdminSelector } from '../../../store/selectors/accountSelectors';

type JobLineContentProps = {
  jobId: number,
  isJobCompleted: boolean,
}

const JobLineContent: React.FC<JobLineContentProps> = ({ jobId, isJobCompleted }) => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const jobDetails = useAppSelector(jobDetailsExpandedViewSelector);
  const assignees = jobDetails?.assignees;
  const { pending, inProduction, readyForQc } = useAppSelector(jobStatusesValuesSelector);
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);

  const enabledStatusesToMark = [inProduction, pending];

  const [isConfirmation, setIsConfirmation] = useState(false);
  const [currentMode, setCurrentMode] = useState<ExpandedViewMode>(ExpandedViewMode.DETAILS);
  const mockedTabs = [
    { label: 'Details', value: ExpandedViewMode.DETAILS },
    { label: `Parts ${tabCountHandle(jobDetails?.partsCount)}`, value: ExpandedViewMode.PARTS },
    { label: 'Quality check', value: ExpandedViewMode.QUALITY_CHECK },
    { label: `Attachments ${tabCountHandle(jobDetails?.attachmentsCount)}`, value: ExpandedViewMode.ATTACHMENTS },
    { label: `Links ${tabCountHandle(jobDetails?.linksCount)}`, value: ExpandedViewMode.LINKS },
  ];

  useEffect(() => {
    dispatch(getJobByIdThunk({
      id: jobId,
    }));
    // eslint-disable-next-line
  }, [currentMode]);

  const markAsReadyForQcHandle = () => {
    readyForQc && dispatch(playPauseJobThunk({
      jobId,
      statusTransition: readyForQc,
      isCurrentRoleAdmin,
      closeModal: () => setIsConfirmation(false),
    }));
  };
  return (
    <>
      <ConfirmationModal
        customTitle="Mark as Ready for QC"
        customText={'You are about to move this job to "Ready for QC" status.'}
        customCreateLabel="Yes, mark as ready"
        confirmHandler={markAsReadyForQcHandle}
        isVisible={isConfirmation}
        onCancel={() => setIsConfirmation(false)}
      />
      <div className="jobLineContent">
        <div className="jobLineContent__header">
          <div className="jobLineContent__tabs-wrapper">
            {windowWidth > responsiveEndpointsEnum.LG
              ? <Segmented
                options={mockedTabs}
                onChange={(v) => setCurrentMode(v as ExpandedViewMode)}
                value={currentMode}
              />
              : <Selectable
                options={mockedTabs}
                onChange={(v) => setCurrentMode(v as ExpandedViewMode)}
                value={currentMode}
                size="sm"
                parentRender
              />}
          </div>
          <div className="jobLineContent__assignees-wrap">
            <div className="jobLineContent__assignees">
              <Tag
                light
                textSize="md"
                label={assignees && assignees.length > 0
                  ? <TruncateTooltip
                    value={assignees[0].label}
                    className="jobLineContent__assignee-tab-value"
                    overlayClassName="jobLineContent__tooltip"
                  />
                  : 'Unassigned'}
              />
              {assignees && assignees.length > 1 && <Tooltip
                overlayClassName="jobLineContent__tooltip"
                title={
                  <ul className="jobLineContent__extra-list">
                    {assignees.slice(1).map((assignee) => (
                      <li key={assignee.value} className="jobLineContent__extra-item">{assignee.label}</li>
                    ))}
                  </ul>
                }
              >
                <span className="jobLineContent__plus-value">{`+${assignees.length - 1}`}</span>
              </Tooltip> }
            </div>
          </div>
          {!isJobCompleted && <Button
            icon={<FontAwesomeIcon icon={faCheckCircle} />}
            onClick={() => setIsConfirmation(true)}
            label="Mark as Ready for QC"
            disabled={!enabledStatusesToMark.includes(jobDetails.jobStatus)}
            className="jobLineContent__mark-btn"
            designType="dark"
            reversed
          />}
        </div>
        <JobLineExpandedData jobId={jobId} mode={currentMode} isJobCompleted={isJobCompleted} />
      </div>
    </>
  );
};

export default JobLineContent;
