import React, { ReactNode } from 'react';
import { Segmented as SegmentedComponent } from 'antd';
import './Segmented.scss';
import classNames from 'classnames';

type SegmentedProps = {
  options: string[] | number[] | Array<{
    label: ReactNode,
    value: string | number,
    icon?: ReactNode,
    disabled?: boolean,
    className?: string
  }>;
  value: string | number;
  onChange: (value: string | number) => void;
  disabled?: boolean;
  defaultValue?: string | number;
  className?: string;
}

const Segmented: React.FC<SegmentedProps> = ({
  options,
  disabled = false,
  defaultValue,
  value,
  onChange,
  className,
}) => {
  return (
    <SegmentedComponent
      value={value}
      onChange={onChange}
      options={options}
      disabled={disabled}
      defaultValue={defaultValue}
      className={classNames(className, 'segmentedCustom')}
    />
  );
};

export default Segmented;
