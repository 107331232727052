import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faEllipsisVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../core/components/table-components/Tbody';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../core/utils/regex';
import { localDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';
import { NumberFunctionType } from '../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../store/hooks';
import { isJobStatusCompletedSelector, jobQualityChecksSelector } from '../../../../../store/selectors/jobsSelector';
import { jobQualityCheckStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { labelHandle } from '../../../../../core/utils/labelHandle';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import './JobQualityChecksTable.scss';

type JobQualityChecksTableProps = {
  openDeleteConfirmation?: NumberFunctionType,
  openQualityCheckDetails?: NumberFunctionType,
}

const JobQualityChecksTable: React.FC<JobQualityChecksTableProps> = ({ openDeleteConfirmation, openQualityCheckDetails }) => {
  const jobQualityCheckStatuses = useAppSelector(jobQualityCheckStatusesSelector);

  const isJobStatusCompleted = useAppSelector(isJobStatusCompletedSelector);
  const columns = [
    { title: 'No', dataIndex: 'ordinalNumber' },
    { title: 'Check result', dataIndex: 'checkResult' },
    { title: 'Check date', dataIndex: 'checkDate' },
  ];
  const qualityChecks = useAppSelector(jobQualityChecksSelector);
  const tableData = qualityChecks.map((check, i) => ({
    id: check.id,
    ordinalNumber: i + 1,
    checkResult: labelHandle(check.status, jobQualityCheckStatuses),
    checkDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, check.checkDate),
  }));

  const renderCellValue = (
    key: string,
    value: string | number | null,
    id: number | null,
  ) => {
    if (key === 'checkResult') {
      return <span
        onClick={() => id && openQualityCheckDetails && openQualityCheckDetails(id)}
        className="table-clickable-content"
      >
        {value}
      </span>;
    }
    return value;
  };

  const actions = (id: number | null) => [
    {
      label: 'View check details',
      icon: <FontAwesomeIcon icon={faCircleInfo} />,
      key: 'checkDetails',
      onClick: () => id && openQualityCheckDetails && openQualityCheckDetails(id),
    },
    {
      label: 'Delete check',
      key: 'deleteCheck',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      disabled: isJobStatusCompleted || !openDeleteConfirmation,
      onClick: () => id && openDeleteConfirmation && openDeleteConfirmation(id),
    }];
  return (
    <TableNew>
      <Thead>
        <Tr>
          {columns.map(({ title, dataIndex }) => (
            <ThNew
              key={dataIndex}
              className={`jobQualityChecksTh--${dataIndex}`}
            >
              {title}
            </ThNew>
          ))}
          <ThNew action><span className="sr-only">Actions</span></ThNew>
        </Tr>
      </Thead>
      <Tbody>
        {tableData.length > 0
          ? <>
            {tableData.map((el) => <React.Fragment key={el.id}>
              <Tr>
                {Object.entries(el).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key}>
                    {renderCellValue(key, value, el.id)}
                  </TdNew>;
                })}
                <TdNew action>
                  <Dropdown
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    menuItems={actions(el.id)}
                  />
                </TdNew>
              </Tr>
            </React.Fragment>)}
          </>
          : <EmptyTableSection text="No records" colSpan={columns.length + 1} />}
      </Tbody>
    </TableNew>
  );
};

export default JobQualityChecksTable;
