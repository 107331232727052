import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faTruck } from '@fortawesome/free-solid-svg-icons';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { useParams, useSearchParams } from 'react-router-dom';
import Button from '../../../../../core/components/button/Button';
import './Quote.scss';
import VehicleContent from './vehicle-content/VehicleContent';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  createEnquiryQuoteVehicleThunk,
  editEnquiryQuoteVehicleThunk,
  fetchVehicleSummaryThunk,
  getEnquiryQuoteVehicleThunk,
  getEnquiryQuotLinesThunk,
} from '../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import {
  addVehicleProfileFiltersSelector, addVehicleProfilesUISelector,
  enquiryAcceptedOrRejectedSelector,
  openedVehicleIdSelector,
  quoteLinesFiltersSelector, quoteLinesSelector,
  quoteVehiclesListSelector,
} from '../../../../../store/selectors/enquiriesSelector';
import {
  setAddVehicleProfileFilters,
  setOpenedVehicleId, setQuoteLinesFilters,
} from '../../../../../store/slices/enquiriesSlice';
import SummarySection from './summary-section/SummarySection';
import Restricted from '../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import {
  initSmallPaging, VehicleModalEntityType, VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import VehicleModal from '../../../../../common/components/vehicle-modal/VehicleModal';
import { AddVehicleRequest, initVehProfilesList } from '../../../../../common/types/commonTypes';
import { setVehicleProfiles } from '../../../../../store/slices/vehiclesSlice';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { enquiryQuoteLinesOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { getEnquiryQuoteLinesOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { QuoteLinesOrdering } from '../../../enums/QuoteEnums';
import { PutQuoteVehicle } from '../../../types/enquiryDetailsTypes';

const { TabPane } = Tabs;

const Quote: React.FC = () => {
  const { id } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const filters = useAppSelector(addVehicleProfileFiltersSelector);
  const vehicleProfiles = useAppSelector(addVehicleProfilesUISelector);

  const items = useAppSelector(quoteVehiclesListSelector);
  const openedVehicleId = useAppSelector(openedVehicleIdSelector);
  const quoteLinesFilters = useAppSelector(quoteLinesFiltersSelector);
  const quoteLines = useAppSelector(quoteLinesSelector);
  const quoteLinesOrdering = useAppSelector(enquiryQuoteLinesOrderingSelector);
  const [isVisible, setIsVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isEditConfirmation, setIsEditConfirmation] = useState<boolean>(false);

  const setVehTabParamHandle = (id: number) => {
    const modeParam = params.get('mode');
    modeParam && setSearchParams({ mode: modeParam, vehicle: id.toString() }, { replace: true });
  };

  useEffect(() => {
    const key = params.get('vehicle');
    id && dispatch(getEnquiryQuoteVehicleThunk({
      id: +id,
      idToSet: key ? +key : undefined,
      setVehTabParam: setVehTabParamHandle,
    }));
    // eslint-disable-next-line
  }, [id]);

  useDefaultPagingAndOrdering({
    filters: quoteLinesFilters,
    ordering: quoteLinesOrdering,
    getOrderingThunk: getEnquiryQuoteLinesOrderingThunk,
    setFiltersAction: setQuoteLinesFilters,
    initialOrdering: QuoteLinesOrdering.Number,
  });

  useEffect(() => {
    openedVehicleId && quoteLinesFilters.order?.field && dispatch(getEnquiryQuotLinesThunk({
      vehicleId: openedVehicleId,
      params: {
        page: quoteLinesFilters.page,
        pageSize: quoteLinesFilters.pageSize,
        order: quoteLinesFilters.order,
        filters: quoteLinesFilters.filters,
      },
    }));
    openedVehicleId && dispatch(fetchVehicleSummaryThunk({
      quoteVehicleId: openedVehicleId,
    }));
    // eslint-disable-next-line
  }, [openedVehicleId, quoteLinesFilters.order, quoteLinesFilters.filters]);

  const onChange = (key: string) => {
    dispatch(setOpenedVehicleId(+key));
    setVehTabParamHandle(+key);
  };
  const currentVeh: VehicleModalEntityType | undefined = useMemo(() => {
    const found = items?.find((el) => el.id === openedVehicleId);
    return found
      ? { ...found, slidingDoorsQuantity: found.slidingDoorsQuantity || 0 }
      : undefined;
  }, [items, openedVehicleId]);
  const vehicleNumber = useMemo(() => {
    const length = items?.length;
    if (isVisible && length && length > 0) {
      const currentVehicle = items.find((el) => el.id === openedVehicleId);
      const lastItem = items[length - 1];
      const lastItemTabNo = lastItem.tabName?.split('VEH ');
      if (editMode) {
        return currentVehicle?.tabName || 'VEH 1';
      } else {
        return lastItemTabNo ? `VEH ${+lastItemTabNo[1] + 1}` : 'VEH 1';
      }
    } else {
      return 'VEH 1';
    }
  }, [items, isVisible, openedVehicleId, editMode]);

  const cancelVehModal = () => {
    setIsVisible(false);
    setEditMode(false);
    dispatch(setAddVehicleProfileFilters(initSmallPaging));
    dispatch(setVehicleProfiles(initVehProfilesList));
  };
  const createVeh = (data: AddVehicleRequest, resetHandle: VoidFunctionType) => {
    id && dispatch(createEnquiryQuoteVehicleThunk({
      data: { ...data, enquiryId: +id },
      tabName: vehicleNumber,
      onClose: () => cancelVehModal(),
      reset: () => resetHandle(),
    }));
  };
  const editVeh = (data: PutQuoteVehicle, setDefaultHandle: VoidFunctionType) => {
    openedVehicleId && id && dispatch(editEnquiryQuoteVehicleThunk({
      vehicleId: openedVehicleId,
      enquiryId: +id,
      data,
      onClose: () => {
        setDefaultHandle();
        cancelVehModal();
      },
    }));
  };

  return (
    <>
      <VehicleModal
        isVisible={isVisible}
        onCancel={() => cancelVehModal()}
        title={vehicleNumber}
        editMode={editMode}
        width={840}
        isEditConfirmation={isEditConfirmation}
        setIsEditConfirmation={setIsEditConfirmation}
        createVehThunkHandle={(data, resetHandle) => createVeh(data, resetHandle)}
        editVehThunkHandle={(data, setDefaultHandle) => editVeh(data, setDefaultHandle)}
        catalogVehicleProfilesFilters={filters}
        setCatalogVehicleProfilesFilters={(v) => dispatch(setAddVehicleProfileFilters(v))}
        vehicleProfiles={vehicleProfiles}
        currentVeh={currentVeh}
        lineType="quote"
      />
      <div className="quoteWrap">
        <Tabs
          renderTabBar={(props, TabNavList) => (
            <TabNavList {...props} mobile={false} />
          )}
          tabPosition="top"
          activeKey={openedVehicleId ? openedVehicleId.toString() : ''}
          onChange={onChange}
          className={classnames('quoteWrap__tabs', { 'tabsEmpty': items?.length === 0 })}
          tabBarExtraContent={
            <Restricted to={PermissionEnum.EnquiryEditQuoteLineFieldsAndUnlockQuoteLineActions}>
              <Button
                icon={<FontAwesomeIcon icon={faCirclePlus} />}
                label="Add vehicle"
                onClick={() => setIsVisible(true)}
                className="quoteWrap__create"
                designType="dark"
                reversed
                disabled={enquiryAcceptedOrRejected}
              />
            </Restricted>
          }
        >
          {items && items?.length > 0
            ? <>
              {items.map((el, i) => <TabPane tab={el.tabName} key={el.id}>
                <VehicleContent
                  vehicle={items[i]}
                  vehicles={items}
                  openEditModal={() => {
                    setEditMode(true);
                    setIsVisible(true);
                  }}
                />
              </TabPane>)}
            </>
            : <EmptyContentList icon={faTruck} text="Please add a vehicle to this quote" />}
        </Tabs>
        {quoteLines && quoteLines.totalCount > 0 && <SummarySection currentVehicleTabName={currentVeh?.tabName || ''} />}
      </div>
    </>
  );
};

export default Quote;
