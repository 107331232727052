import {
  AssigneeDto,
  initPaging, OrderingType, PagingType, ResponseResult,
  VehicleDto,
} from '../../../core/types/coreTypes';

export type VehiclesDashboardFilters = {
  isActive?: boolean,
  onlyAvailableForRectificationCreation?: boolean,
  keyword?: string,
  manufacturers?: string[],
  customerIds?: number[],
  statuses?: number[],
  vehicleFlows?: number[],
  assigneeIds?: number[],
  order?: OrderingType,
  manufacturer?: string,
} & Partial<PagingType>

export const initVehiclesDashboardFilters: VehiclesDashboardFilters = {
  ...initPaging,
  isActive: true,
};

export enum VehiclesOrdering {
  CreationDate = 'CreationDate',
}

export type VehicleItemUi = {
  id: number,
  vehicleFlow: string,
  manufacturer: string,
  modelDescription: string,
  regNumber: string,
  chassisNumber: string,
  customerName: string,
  status: string,
  address: string,
}

export type GetVehiclesResponse = ResponseResult<VehicleDto[], {
  assignees: AssigneeDto[] | null,
  statuses: number[] | null,
}>
