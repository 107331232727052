import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  unfinishedEntitiesDataSelector,
  unfinishedEntitiesFiltersSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { setUnfinishedEntitiesFilters } from '../../../../../../store/slices/reportsSlice';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import { useUnfinishedEntitiesRange } from '../../../../../hooks/useUnfinishedEntitiesRange';
import { getUnfinishedEntitiesReportThunk } from '../../../../../../store/thunks/reports/reportsThunks';

type UnfinishedEntitiesColumnsProps = {
  columns: ICustomColumn[],
}

const UnfinishedEntitiesColumns: React.FC<UnfinishedEntitiesColumnsProps> = ({ columns }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(unfinishedEntitiesFiltersSelector);
  const { totalCount } = useAppSelector(unfinishedEntitiesDataSelector);
  const { range, setRange, rangePatterns } = useUnfinishedEntitiesRange();

  const sortHandle = (field: number) => {
    if (filters?.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setUnfinishedEntitiesFilters(newFilters));
      dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setUnfinishedEntitiesFilters(newFilters));
      dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
    }
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const [min, max] = value;
    const newFilters = {
      ...filters,
      [field]: { min, max },
    };
    dispatch(setUnfinishedEntitiesFilters(newFilters));
    dispatch(getUnfinishedEntitiesReportThunk({ filters: newFilters }));
  };
  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            setRangeHandle={setRangeHandle}
            range={range}
            rangePatterns={rangePatterns}
            rangeStep={1}
            setRange={setRange}
            totalElements={totalCount}
            className={`unfinishedEntitiesReportTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default UnfinishedEntitiesColumns;
