import React from 'react';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Tfoot from '../../../../../core/components/table-components/Tfoot';
import { GenericObject } from '../../../../../core/types/coreTypes';
import { distributeQtyColumns } from '../../../utils/data';
import DistributeQuantityCells from './DistributeQuantityCells';
import DistributeQtyTotalSection from './DistributeQtyTotalSection';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import { useAppSelector } from '../../../../../store/hooks';
import { stockPartProductSourceTotalSelector } from '../../../../../store/selectors/stockPartRequestsSelector';

type DistributeQtyTableProps = {
  sourceId?: number,
  checkedCells: string[],
  setCheckedCells: (value: string[]) => void,
  values: GenericObject<string> | null,
  setValues: (val: GenericObject<string> | null) => void,
  visible: boolean,
  isLinesView?: boolean,
}

const DistributeQtyTable: React.FC<DistributeQtyTableProps> = ({
  sourceId, checkedCells, setCheckedCells,
  values, setValues, visible, isLinesView,
}) => {
  const sourceTotal = useAppSelector(stockPartProductSourceTotalSelector);
  return (
    <TableNew className="distributeQtyTable" small>
      <Thead>
        <Tr>
          <ThNew className="distributeQtyTh--checkbox"><span className="sr-only">Select</span></ThNew>
          <ThNew className="distributeQtyTh--name"><span className="sr-only">Name</span></ThNew>
          {distributeQtyColumns.map((col) => <ThNew
            key={col.dataIndex}
            alignCenter
          >
            {col.title}
          </ThNew>)}
        </Tr>
      </Thead>
      <Tbody>
        <DistributeQuantityCells
          sourceId={sourceId}
          checkedCells={checkedCells}
          setCheckedCells={setCheckedCells}
          values={values}
          setValues={setValues}
          sourceTotal={sourceTotal}
          visible={visible}
          isLinesView={isLinesView}
        />
      </Tbody>
      <Tfoot className="sourceFoot">
        <Tr>
          <TdNew colSpan={distributeQtyColumns.length + 2}>
            <div className="distributeQtySummary">
              <DistributeQtyTotalSection
                title="Quantity"
                requested={sourceTotal?.requestedQuantity || '0'}
                confirmed={sourceTotal?.confirmedQuantity || '0'}
                difference={sourceTotal?.quantityDifference || '0'}
                noPoundSign
              />
              <DistributeQtyTotalSection
                title="Cost net"
                requested={sourceTotal?.requestedCostNet || '0.00'}
                confirmed={sourceTotal?.confirmedCostNet || '0.00'}
                difference={sourceTotal?.costNetDifference || '0.00'}
              />
              <DistributeQtyTotalSection
                title="Selling net"
                requested={sourceTotal?.requestedSellNet || '0.00'}
                confirmed={sourceTotal?.confirmedSellNet || '0.00'}
                difference={sourceTotal?.sellNetDifference || '0.00'}
              />
            </div>
          </TdNew>
        </Tr>
      </Tfoot>
    </TableNew>
  );
};

export default DistributeQtyTable;
