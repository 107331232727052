import React from 'react';
import './SuppliersSkeletonItem.scss';

const SuppliersSkeletonItem: React.FC = () => {
  return (
    <div className="suppliersSkeletonItem skeleton-item">
      <div className="suppliersSkeletonItem__info">
        <div className="suppliersSkeletonItem__img" />
        <div className="suppliersSkeletonItem__text" />
      </div>
      <div className="suppliersSkeletonItem__list">
        {Array(3).fill(null).map((_, i) => <div key={i} className="suppliersSkeletonItem__list-item" />)}
      </div>
    </div>
  );
};

export default SuppliersSkeletonItem;
