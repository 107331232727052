import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  soldProductsByCustomerDateSelector,
  soldProductsByCustomerFiltersSelector,
  soldProductsByCustomerGlobalFiltersSelector,
  soldProductsByCustomerTreeSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { DateRangeTableFilterType, PickerValues } from '../../../../../core/types/coreTypes';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import {
  setReportIsVisible,
  setSoldProductsByCustomerData,
  setSoldProductsByCustomerFilters,
} from '../../../../../store/slices/reportsSlice';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { customersDictionaryLookupSelector } from '../../../../../store/selectors/sharedSelectors';
import {
  getSoldProductsByCustomerReportExcelThunk,
  getSoldProductsByCustomerReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { useSoldProductsByCustomerReportDefaultOrdering } from '../../../../hooks/useSoldProductsByCustomerReportDefaultOrdering';
import { getSoldProductsByCustomerReportEntityTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';
import { initSoldProductsByCustomerData } from '../../../reports-content/sales/sold-products-by-customer/utils/data';

const SoldProductsByCustomerGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const customers = useAppSelector(customersDictionaryLookupSelector);
  const filters = useAppSelector(soldProductsByCustomerFiltersSelector);
  const checkboxFilters = useAppSelector(soldProductsByCustomerGlobalFiltersSelector);
  const treeFilters = useAppSelector(soldProductsByCustomerTreeSelector);
  const datePickerFilters = useAppSelector(soldProductsByCustomerDateSelector);

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { completedDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const isSelectedGlobalFilters = useMemo(() => {
    const {
      businessAreas, partSubcategoryIds, entityTypes, completedDate,
    } = filters;
    const hasSelectedCheckboxes = [businessAreas, partSubcategoryIds, entityTypes].some((el) => !!el && !!el.length);
    const hasSelectedDatepicker = completedDate && (completedDate.min || completedDate.max);
    return hasSelectedCheckboxes || hasSelectedDatepicker;
    // eslint-disable-next-line
  }, [filters.businessAreas, filters.entityTypes, filters.partSubcategoryIds, filters.completedDate]);

  const defaultPagingAndOrdering = useSoldProductsByCustomerReportDefaultOrdering();

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getSoldProductsByCustomerReportEntityTypesThunk());

    return () => {
      dispatch(setSoldProductsByCustomerFilters(defaultPagingAndOrdering));
      dispatch(setSoldProductsByCustomerData(initSoldProductsByCustomerData));
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!!customers.length && !filters.customerIds?.[0]) {
      dispatch(setSoldProductsByCustomerFilters({
        ...filters,
        customerIds: [customers[0].value],
      }));
    }
    // eslint-disable-next-line
  }, [customers]);

  const resetTableFilters = {
    productNumber: undefined,
    productName: undefined,
    quantity: undefined,
    costNet: undefined,
    salesNet: undefined,
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    dispatch(setSoldProductsByCustomerFilters({
      ...filters,
      ...resetTableFilters,
      [field]: values,
    }));
    dispatch(setSoldProductsByCustomerData(initSoldProductsByCustomerData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    if (field === 'customerIds') {
      values.length && dispatch(setSoldProductsByCustomerFilters({
        ...filters,
        customerIds: values.filter((el) => el !== filters.customerIds?.[0]),
      }));
    } else {
      dispatch(setSoldProductsByCustomerFilters({
        ...filters,
        ...resetTableFilters,
        [field]: values,
      }));
    }
    dispatch(setSoldProductsByCustomerData(initSoldProductsByCustomerData));
    dispatch(setReportIsVisible(false));
  };

  const treeChangeHandle = (checkedKeys: React.Key[]) => {
    const checkedSubcategories = checkedKeys.filter((el) => !el.toString().includes('category'));
    const values = checkedSubcategories.map((s) => +s);
    getFilteredResHandle(values, 'partSubcategoryIds');
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setSoldProductsByCustomerFilters({
      customerIds: filters.customerIds,
      ...defaultPagingAndOrdering,
    }));
    dispatch(setSoldProductsByCustomerData(initSoldProductsByCustomerData));
    dispatch(setReportIsVisible(false));
  };

  const exportToExcel = () => {
    const { order } = defaultPagingAndOrdering;
    const {
      customerIds, businessAreas, entityTypes, partSubcategoryIds, completedDate,
    } = filters;
    dispatch(getSoldProductsByCustomerReportExcelThunk({
      filters: {
        customerIds, businessAreas, entityTypes, partSubcategoryIds, completedDate, order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        treeFilters={treeFilters}
        treeChangeHandle={treeChangeHandle}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getSoldProductsByCustomerReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default SoldProductsByCustomerGlobalFilters;
