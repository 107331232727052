import React, { useEffect, useState } from 'react';
import moment from 'moment';
import AddPriceFields from './AddPriceFields';
import Tfoot from '../../../../../../core/components/table-components/Tfoot';
import Tr from '../../../../../../core/components/table-components/Tr';
import Thead from '../../../../../../core/components/table-components/Thead';
import { editPartPriceColumns } from '../../../../utils/data';
import { partPriceTableSelector } from '../../../../../../store/selectors/pricelistSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { CustomAny, StringFunctionType } from '../../../../../../core/types/coreTypes';
import { DraftValuesType } from './EditPartModal';
import { ExtendedPricePart } from '../../../../types/pricelistViewPageTypes';
import EditPartBody from './EditPartBody';
import { setErrorMessage } from '../../../../../../store/slices/coreSlice';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import { DEFAULT_DATE_FORMAT } from '../../../../../../core/utils/regex';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';

type EditPartPriceTableProps = {
  setError: StringFunctionType,
  error: string,
  draftValues: DraftValuesType,
  setDraftValues: (value: DraftValuesType) => void,
  disabledSave: boolean,
  pricesCopy: ExtendedPricePart[],
  setPricesCopy: (value: ExtendedPricePart[]) => void,
}

const EditPartPriceTable: React.FC<EditPartPriceTableProps> = ({
  error, setError, setDraftValues, draftValues, disabledSave,
  pricesCopy, setPricesCopy,
}) => {
  const dispatch = useAppDispatch();
  const partPrices = useAppSelector(partPriceTableSelector);
  const [isConfirmation, setIsConfirmation] = useState(false);
  useEffect(() => {
    if (partPrices.items) {
      setPricesCopy([...partPrices.items]);
    }
    // eslint-disable-next-line
  }, [partPrices.items]);

  const setDarftPrice = () => {
    const newPrice = {
      id: Date.now(),
      costPrice: draftValues.costPrice ? +draftValues.costPrice : 0.00,
      sellPrice: draftValues.sellPrice ? +draftValues.sellPrice : 0.00,
      effectiveStartDate: moment(draftValues.startDate).format(DEFAULT_DATE_FORMAT),
      effectiveEndDate: null,
      isDraft: true,
    };

    let newArr: CustomAny = [];
    const pricesLength = pricesCopy.length;

    if (pricesCopy.find((el) => moment(el.effectiveStartDate).isSame(newPrice.effectiveStartDate))) {
      setTimeout(() => {
        dispatch(setErrorMessage({ message: 'Price with the same start date already exists.' }));
      }, 500);
      return;
    }

    if (!pricesLength) {
      newArr.push(newPrice);
      setPricesCopy(newArr);
      return;
    }

    if (pricesLength === 1) {
      const itemStartDate = pricesCopy[0].effectiveStartDate;
      if (moment(itemStartDate).isBefore(newPrice.effectiveStartDate)) {
        const effectiveEndDate = moment(newPrice.effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT);
        pricesCopy[0] = {
          ...pricesCopy[0],
          effectiveEndDate,
        };

        pricesCopy.push(newPrice);
        newArr = [...pricesCopy];
      } else {
        pricesCopy.unshift({
          ...newPrice,
          effectiveEndDate: moment(pricesCopy[0].effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT),
        });
        newArr = [...pricesCopy];
      }
      setPricesCopy(newArr);
      return;
    }

    let isAfter = 0;
    let isBefore = 0;

    for (let i = 0; i < pricesCopy.length; i++) {
      const itemStartDate = pricesCopy[i].effectiveStartDate;
      const itemEndDate = pricesCopy[i].effectiveEndDate;
      if (!itemEndDate) break;
      const isDateLessThenStart = moment(newPrice.effectiveStartDate).isBefore(itemStartDate);
      const isDateMoreThenEnd = moment(newPrice.effectiveStartDate).isAfter(itemEndDate);

      if (isDateLessThenStart) isBefore++;
      if (isDateMoreThenEnd) isAfter++;
    }

    if (!isAfter && isBefore === pricesLength - 1) {
      pricesCopy.unshift({
        ...newPrice,
        effectiveEndDate: moment(pricesCopy[0].effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT),
      });
      newArr = [...pricesCopy];
    } else if (!isBefore && isAfter === pricesLength - 1) {
      const effectiveEndDate = moment(newPrice.effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT);
      pricesCopy[pricesLength - 1] = {
        ...pricesCopy[pricesLength - 1],
        effectiveEndDate,
      };

      pricesCopy.push(newPrice);
      newArr = [...pricesCopy];
    } else {
      for (let j = 0; j < pricesCopy.length; j++) {
        const currentStart = pricesCopy[j].effectiveStartDate;
        const currentEndDate = pricesCopy[j].effectiveEndDate;
        const isMoreThanStart = moment(newPrice.effectiveStartDate).isAfter(currentStart);
        const isLessThanEnd = moment(newPrice.effectiveStartDate).isBefore(currentEndDate);
        const isDateEqualsEnd = moment(newPrice.effectiveStartDate).isSame(currentEndDate);

        if (currentEndDate && isMoreThanStart && isLessThanEnd) {
          newArr.push({
            ...pricesCopy[j],
            effectiveEndDate: moment(newPrice.effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT),
          });
          newArr.push({
            ...newPrice,
            effectiveEndDate: currentEndDate,
          });
        } else if (isDateEqualsEnd) {
          newArr.push({
            ...pricesCopy[j],
            effectiveEndDate: moment(newPrice.effectiveStartDate).add(-1, 'day').format(DEFAULT_DATE_FORMAT),
          });
          newArr.push({
            ...newPrice,
            effectiveEndDate: currentEndDate,
          });
        } else {
          newArr.push(pricesCopy[j]);
        }
      }
    }
    setPricesCopy(newArr);
  };

  const deleteDraftPrice = (id: number) => {
    const found = pricesCopy.find((pr) => pr.id === id);
    const foundIndex = pricesCopy.findIndex((el) => el.id === id);
    if (found) {
      if (!found.effectiveEndDate) {
        const arr: ExtendedPricePart[] = [];
        pricesCopy.forEach((el, i) => {
          if (el.id !== id) {
            if (i === pricesCopy.length - 2) {
              arr.push({ ...el, effectiveEndDate: null });
            } else {
              arr.push(el);
            }
          }
        });
        setPricesCopy(arr);
      } else {
        const arr: ExtendedPricePart[] = [];
        pricesCopy.forEach((el, i) => {
          if (el.id !== id) {
            if (i === foundIndex - 1) {
              arr.push({ ...el, effectiveEndDate: found.effectiveEndDate });
            } else {
              arr.push(el);
            }
          }
        });
        setPricesCopy(arr);
      }
    }
  };

  const saveDraftHandle = () => {
    setDarftPrice();
    setDraftValues({
      costPrice: '0.00',
      sellPrice: '0.00',
      startDate: undefined,
    });
  };

  return (
    <>
      <ConfirmationModal
        isVisible={isConfirmation}
        confirmHandler={() => {
          saveDraftHandle();
          setIsConfirmation(false);
        }}
        onCancel={() => setIsConfirmation(false)}
        customTitle="Confirm"
        customText="Entered selling price is less than cost price."
        customCreateLabel="Yes, save"
        isProceedQuestion
      />
      <TableNew className="editPartPriceTable" small hasFooter>
        <Thead>
          <Tr>
            {editPartPriceColumns.map((col) => <ThNew key={col.dataIndex} className={`editPartPriceTh--${col.dataIndex}`}>
              {col.title}
            </ThNew>)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Tr>
        </Thead>
        <EditPartBody pricesCopy={pricesCopy} deleteDraftPrice={deleteDraftPrice} />
        <Tfoot>
          <AddPriceFields
            error={error}
            setError={setError}
            draftValues={draftValues}
            setDraftValues={setDraftValues}
            disabledSave={disabledSave}
            setIsConfirmation={setIsConfirmation}
            setPricesCopy={saveDraftHandle}
          />
        </Tfoot>
      </TableNew>
    </>

  );
};

export default EditPartPriceTable;
