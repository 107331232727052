import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import InvoicingFilters from './components/InvoicingFilters';
import InvoicingContent from './components/InvoicingContent';
import { InvoicingPageParamsEnums } from './enums/InvoicingPageParamsEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { invoiceDocumentOperationTypesValuesSelector } from '../../store/selectors/coreSelectors';
import { getInvoiceDocumentOperationTypesThunk } from '../../store/thunks/coreThunk';
import { setInvoiceDocumentsFilters } from '../../store/slices/invoicingSlice';
import { initSmallPaging } from '../../core/types/coreTypes';
import { getOrderListOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';

const InvoicingContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { sale, purchase } = useAppSelector(invoiceDocumentOperationTypesValuesSelector);
  const [params, setSearchParams] = useSearchParams();
  const page = params.get('page');

  const setPageParam = (page: string) => {
    setSearchParams({ page }, { replace: true });
  };

  useEffect(() => {
    if (!sale || !purchase) {
      dispatch(getInvoiceDocumentOperationTypesThunk());
    }
    dispatch(getOrderListOrderingThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let operationTypes: number[] = [];
    if (page === InvoicingPageParamsEnums.SALES && sale) operationTypes = [sale];
    if (page === InvoicingPageParamsEnums.PURCHASES && purchase) operationTypes = [purchase];
    dispatch(setInvoiceDocumentsFilters({ ...initSmallPaging, operationTypes }));
    // eslint-disable-next-line
  }, [page, sale, purchase]);

  return (
    <Layout>
      <InvoicingFilters
        view={page}
        setView={setPageParam}
      />
      <InvoicingContent view={page} />
    </Layout>
  );
};

export default InvoicingContainer;
