export const assignNominalCodesColumns = [
  { dataIndex: 'number', title: 'No' },
  { dataIndex: 'type', title: 'Type' },
  { dataIndex: 'code', title: 'Code' },
  { dataIndex: 'description', title: 'Description' },
  { dataIndex: 'net', title: 'Net' },
  { dataIndex: 'vat', title: 'VAT' },
  { dataIndex: 'linkedTo', title: 'Linked to' },
  { dataIndex: 'state', title: 'State' },
];

export const assignNominalCodesSummaryColumns = [
  { dataIndex: 'status', title: '' },
  { dataIndex: 'quantity', title: 'Quantity' },
  { dataIndex: 'net', title: 'Net' },
  { dataIndex: 'vat', title: 'VAT' },
  { dataIndex: 'gross', title: 'Gross' },
];

export const purchasingSpecificEntitiesColumns = [
  { title: 'No', dataIndex: 'number' },
  { title: 'Supplier', dataIndex: 'supplier' },
  { title: 'Purchase order No', dataIndex: 'purchaseOrderNumber' },
  { title: 'Entity No', dataIndex: 'entityNumber' },
  { title: 'Net', dataIndex: 'net' },
  { title: 'Gross', dataIndex: 'gross' },
  { title: 'Status', dataIndex: 'status' },
];

export const salesSpecificEntitiesColumns = [
  { title: 'No', dataIndex: 'number' },
  { title: 'Customer', dataIndex: 'customer' },
  { title: 'Order No', dataIndex: 'orderNumber' },
  { title: 'Entity No', dataIndex: 'entityNumber' },
  { title: 'Net', dataIndex: 'net' },
  { title: 'Gross', dataIndex: 'gross' },
  { title: 'Status', dataIndex: 'status' },
];
