import React from 'react';
import { invoicesAndCreditNotesColumns } from '../utils/data';
import Tr from '../../../../../core/components/table-components/Tr';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import Thead from '../../../../../core/components/table-components/Thead';

const InvoicesAndCreditNotesColumns: React.FC = () => {
  return (
    <Thead>
      <Tr>
        {invoicesAndCreditNotesColumns.map((col) => <ThNew
          key={col.dataIndex}
          className={`invoicesAndCreditNotesTh--${col.dataIndex}`}
        >
          {col.title}
        </ThNew>)}
        <ThNew action><span className="sr-only">Actions</span></ThNew>
      </Tr>
    </Thead>
  );
};

export default InvoicesAndCreditNotesColumns;
