export enum InvoiceDocumentOperationTypesEnum {
  Sale = 'Sale',
  Purchase = 'Purchase',
}

export enum InvoiceDocumentStatusesEnum {
  Draft = 'Draft',
  Exported = 'Exported',
}

export enum InvoiceDocumentTypes {
  Invoice = 'Invoice',
  CreditNote = 'Credit note',
}

export enum NominalRecordStates {
  Pending = 'Pending',
  Draft = 'Draft',
  Exported = 'Exported',
}

export enum NominalRecordsOrdering {
  OrdinalNumber = 'OrdinalNumber',
}

export enum InvoiceDocumentsOrdering {
  CreationDate = 'CreationDate',
}
