import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { getPurchaseOrderEventTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { purchaseOrderEventSourcesValuesSelector } from '../../../../../store/selectors/coreSelectors';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import PurchaseOrderEvent
  from '../../../../../stock/purchase-orders/view-page/components/content/details-activity/PurchaseOrderEvent';
import Modal from '../../../../../core/components/modal/Modal';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import { initDataList, VoidFunctionType } from '../../../../../core/types/coreTypes';
import {
  setPurchasingInvoiceTimeline,
  setPurchasingInvoiceTimelineFilters,
} from '../../../../../store/slices/invoicingSlice';
import { initExtendedTimelineFilters } from '../../../../../common/types/commonTypes';
import {
  purchasingInvoiceTimelineFiltersSelector,
  purchasingInvoiceTimelineSelector,
} from '../../../../../store/selectors/invoicingSelectors';
import { getInvoicingPurchaseOrderTimeline } from '../../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import Spinner from '../../../../../core/components/spinner/Spinner';
import { purchaseOrderEventTypesSelector } from '../../../../../store/selectors/coreEventTypesSelectors';

type PurchaseInvoicingActivityLogModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
}

const PurchaseInvoicingActivityLogModal: React.FC<PurchaseInvoicingActivityLogModalProps> = ({ onCancel, isVisible }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { totalCount, items } = useAppSelector(purchasingInvoiceTimelineSelector);
  const timelineFilters = useAppSelector(purchasingInvoiceTimelineFiltersSelector);
  const eventTypes = useAppSelector(purchaseOrderEventTypesSelector);
  const [isLoading, setIsLoading] = useState(false);

  const { invoicingSource } = useAppSelector(purchaseOrderEventSourcesValuesSelector);

  useEffect(() => {
    if (isVisible && !eventTypes.length) {
      dispatch(getPurchaseOrderEventTypesThunk());
    }
    // eslint-disable-next-line
  }, [isVisible]);

  useEffect(() => {
    if (id && isVisible && invoicingSource) {
      dispatch(getInvoicingPurchaseOrderTimeline({
        id: +id, filters: timelineFilters, source: invoicingSource, setIsLoading,
      }));
    }
    // eslint-disable-next-line
  }, [id, isVisible, invoicingSource, timelineFilters.mainOnly, timelineFilters.page]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <PurchaseOrderEvent item={el} />,
  }));

  const cancelHandle = () => {
    onCancel();
    dispatch(setPurchasingInvoiceTimeline(initDataList));
    dispatch(setPurchasingInvoiceTimelineFilters({ ...initExtendedTimelineFilters, mainOnly: false }));
  };
  return (
    <Modal visible={isVisible} title="Activity" onCancel={cancelHandle} width={800} className="timeline-modal">
      <div className="timeline-modal__actions">
        <Pagination
          currentPage={timelineFilters.page}
          pageSize={timelineFilters.pageSize}
          onChange={(page) => {
            dispatch(setPurchasingInvoiceTimelineFilters({
              ...timelineFilters,
              page,
            }));
          }}
          totalItems={totalCount}
          showSizeChanger={false}
          showTotal={false}
        />
      </div>
      <div className="timeline-modal__timeline-wrapper">
        {isLoading && <Spinner global={false} />}
        {!totalCount && <EmptyContentList icon={faWaveSquare} text="No data" size="sm" offsetTop={false} />}
        <Timeline timelineList={timelineList} />
      </div>
    </Modal>
  );
};

export default PurchaseInvoicingActivityLogModal;
