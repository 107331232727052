import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { AddressSuggestionDetailsDto, VoidFunctionType } from '../../../core/types/coreTypes';
import { AddressFields, initAddressFields } from '../../types/commonTypes';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import Modal from '../../../core/components/modal/Modal';
import Input from '../../../core/components/input/Input';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import { PHONE_FAX_REGEX } from '../../../core/utils/regex';
import Button from '../../../core/components/button/Button';
import AddressSuggestionsModal from '../address-suggestions-modal/AddressSuggestionsModal';

type AddressModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  onSubmit: (values: AddressFields) => void,
  addressFields: AddressFields | null,
  title?: string,
  isOptionalFields?: boolean
  isExtendedFields?: boolean,
  isDefaultOptionAvailable?: boolean,
  setDefaultAddressFields?: VoidFunctionType,
}

const AddressModal:React.FC<AddressModalProps> = ({
  isVisible, onCancel, onSubmit, addressFields, title = 'Address',
  isOptionalFields = true, isExtendedFields = true, isDefaultOptionAvailable,
  setDefaultAddressFields,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const {
    control, handleSubmit, setValue, reset, clearErrors,
  } = useForm<AddressFields>({
    defaultValues: initAddressFields,
    mode: 'all',
  });

  const { errors } = useFormState({ control });

  const extendedFieldsToHide = ['companyName', 'telephone'];
  const fields = [
    {
      value: 'companyName',
      label: 'Company name',
      rules: {
        maxLength: { value: 200, message: maxLengthMessageHandle(200) },
      },
    },
    {
      value: 'addressLine1',
      label: 'Address line 1',
      rules: {
        maxLength: { value: 200, message: maxLengthMessageHandle(200) },
        required: !isOptionalFields && ErrorsEnum.REQUIRED,
      },
    },
    {
      value: 'addressLine2',
      label: 'Address line 2',
      rules: {
        maxLength: { value: 200, message: maxLengthMessageHandle(200) },
      },
    },
    {
      value: 'cityTown',
      label: 'City/town',
      rules: {
        maxLength: { value: 100, message: maxLengthMessageHandle(100) },
        required: !isOptionalFields && ErrorsEnum.REQUIRED,
      },
    },
    {
      value: 'region',
      label: 'Region',
      rules: { maxLength: { value: 50, message: maxLengthMessageHandle(50) } },
    },
    {
      value: 'postalCode',
      label: 'Postal code',
      rules: {
        maxLength: { value: 8, message: rangeLengthMessageHandle([5, 8]) },
        minLength: { value: 5, message: rangeLengthMessageHandle([5, 8]) },
        required: !isOptionalFields && ErrorsEnum.REQUIRED,
      },
    },
    {
      value: 'telephone',
      label: 'Telephone',
      rules: {
        maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
        minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
        pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
      },
    },
  ];
  const finalFields = isExtendedFields
    ? [...fields]
    : fields.filter((el) => !extendedFieldsToHide.includes(el.value));

  const [suggestionModal, setSuggestionModal] = useState(false);

  const handleSelectSuggested = (address: AddressSuggestionDetailsDto) => {
    setValue('addressLine1', address.addressLine1);
    setValue('addressLine2', address.addressLine2);
    setValue('companyName', address.companyName);
    setValue('cityTown', address.cityTown);
    setValue('region', address.region);
    setValue('postalCode', address.postalCode);
    setSuggestionModal(false);
  };

  const onSubmitHandle = (values: AddressFields) => {
    onSubmit(values);
  };

  useEffect(() => {
    if (isVisible) {
      if (addressFields) {
        Object.keys(addressFields).forEach((key) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setValue(key, addressFields[key]);
        });
      }
    } else reset();
    // eslint-disable-next-line
  }, [addressFields, isVisible]);

  return (
    <>
      <AddressSuggestionsModal
        visible={suggestionModal}
        onClose={() => setSuggestionModal(false)}
        handleSelectSuggested={handleSelectSuggested}
      />
      <Modal
        visible={isVisible}
        onCancel={onCancel}
        title={title}
        destroyOnClose
      >
        <form className="location-form" onSubmit={handleSubmit(onSubmitHandle)}>
          <div className="location-form__fields">
            {finalFields.map((el) => {
              if (el.value === 'postalCode') {
                return <div key={el.value} className="location-form__postal">
                  <Controller
                    name="postalCode"
                    control={control}
                    rules={{ ...el.rules }}
                    render={({ field }) => (
                      <Input
                        value={field.value}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (val.trim()) {
                            field.onChange(val);
                          } else {
                            field.onChange(null);
                          }
                        }}
                        label={el.label}
                        error={errors.postalCode?.message}
                      />
                    )}
                  />
                  <Button
                    icon={<FontAwesomeIcon icon={faSearch} />}
                    onClick={() => setSuggestionModal(true)}
                  />
                </div>;
              }
              return <Controller
                key={el.value}
                name={`${el.value}` as keyof AddressFields}
                control={control}
                rules={{ ...el.rules }}
                render={({ field }) => (
                  <Input
                    value={field.value}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (val.trim()) {
                        field.onChange(val);
                      } else {
                        field.onChange(null);
                      }
                    }}
                    label={el.label}
                    error={errors[el.value as keyof AddressFields]?.message}
                  />
                )}
              />;
            })}
          </div>
          {isDefaultOptionAvailable && <Button
            onClick={() => {
              setDefaultAddressFields && setDefaultAddressFields();
              clearErrors();
            }}
            label="Fill in with Vanliners details"
            type="link"
            disableDefaultStyles
          />}
          <ButtonActions
            cancelLabel="Cancel"
            createLabel="Save"
            createType="submit"
            cancelClick={() => {
              onCancel();
              reset();
            }}
            className="location-form__actions"
            isLoading={isGlobalLoading}
          />
        </form>
      </Modal>
    </>
  );
};

export default AddressModal;
