import React from 'react';
import classNames from 'classnames';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import ChosenVehicleProfile from './ChosenVehicleProfile';
import {
  VehicleProfileSearchType,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

type ExistedChosenVehicleFormProps = {
  chosenVehicleProfile: VehicleProfileSearchType | null,
  selectAnotherProfile?: VoidFunctionType,
  className?: string,
  readOnly?: boolean,
}

const ExistedChosenVehicleForm: React.FC<ExistedChosenVehicleFormProps> = ({
  chosenVehicleProfile, selectAnotherProfile, className, readOnly,
}) => {
  return (
    <div className={classNames('existedChosenVehicleForm', className)}>
      <ChosenVehicleProfile
        chosenVehicleProfile={chosenVehicleProfile}
        selectAnotherProfile={selectAnotherProfile}
        readOnly={readOnly}
      />
    </div>
  );
};

export default ExistedChosenVehicleForm;
