import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  customerTypesSelector,
  jobCategoriesSelector,
  jobTypesSelector,
  jobTypesWithoutSupplyOnlySelector,
  jobWarningsSelector,
  linksTypesSelector, linkTypesValuesSelector,
  usersFilterLookupSelector,
  priorityLevelsSelector, QuoteUnitsOfMeasureSelector,
} from './coreSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  JobCustomerDto,
  JobDetailsDto,
  JobPartsTableLine,
  JobQualityCheckPartTableLine,
} from '../../job/job-view-page/types/jobViewTypes';
import { linksReformHandler, linksUiViewHandle } from '../../core/utils/linksReformHandler';
import {
  completedNames, JobStatusesUiNames, workQueueNames,
} from '../../job/jobs/enums/jobsEnums';
import { summaryFieldValueHandle } from '../../core/utils/summaryFieldValueHandle';
import { DictionaryItem } from '../../core/types/coreTypes';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { currentUserSelector } from './accountSelectors';
import { JobPartsOrdering } from '../../job/job-view-page/enums/JobViewEnums';
import { partCategoriesLookupSelector } from './sharedSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import {
  allocatedPartReqLinesStatusesSelector,
  jobInitUIStatusesSelector,
  jobStatusesSelector, jobStatusesValuesSelector,
  orderStatusSelector,
  partRequestLineStatusesSelector,
  rectificationStatusesSelector,
} from './coreStatusesSelectors';
import { stockPartRequestLinesOrderingSelector } from './coreOrderingSelectors';
import {
  DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT,
  UNKNOWN_USER,
} from '../../core/utils/regex';
import { PartRequestLineDto } from '../../common/types/commonTypes';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { JobAssigneeDto } from '../../job/jobs/types/jobsTypes';

const jobAssigneesFilterInitSelector = (state: RootState) => state.jobs.jobAssigneesFilter;
export const jobAssigneesFilterSelector = createSelector(jobAssigneesFilterInitSelector, (jobAssignees) => {
  return jobAssignees.map((ass) => {
    return {
      value: ass.id,
      label: `${ass.firstName || ''} ${ass.lastName || ''}`,
    };
  });
});
export const jobsColumnsPaginationSelector = (state: RootState) => state.jobs.jobColumnsPagination;
export const jobCollectionsSelector = (state: RootState) => state.jobs.jobCollections;
export const sortedJobCollectionsSelector = createSelector(jobCollectionsSelector, (collections) => {
  const newArr = collections ? [...collections] : null;
  return newArr?.sort((a, b) => a.order - b.order) || null;
});

export const jobsFiltersSelector = (state: RootState) => state.jobs.filters;
export const jobDashboardFiltersSelector = createSelector(
  jobsFiltersSelector,
  jobCategoriesSelector,
  priorityLevelsSelector,
  jobAssigneesFilterSelector,
  jobTypesWithoutSupplyOnlySelector,
  jobWarningsSelector,
  (
    values,
    jobCategories,
    priorityLevels,
    assignees,
    jobTypes,
    warnings,
  ) => {
    return [
      {
        value: values.jobCategories || [],
        name: 'jobCategories',
        label: 'Job category',
        options: jobCategories,
      },
      {
        value: values.priority || [],
        name: 'priority',
        label: 'Priority',
        options: priorityLevels,
      },
      {
        value: values.assignedTo || [],
        name: 'assignedTo',
        label: 'Assigned to',
        options: assignees,
      },
      {
        value: values.jobType || [],
        name: 'jobType',
        label: 'Job type',
        options: jobTypes,
      },
      {
        value: values.warnings || [],
        name: 'warnings',
        label: 'Warnings',
        options: warnings,
      },
    ];
  },
);

export const jobDetailsSelector = (state: RootState) => state.jobs.jobDetails;
export const jobHeadDetailsSelector = createSelector(jobDetailsSelector, customerTypesSelector, (data, customerTypes) => {
  const details = data?.job;
  const customer: JobCustomerDto | undefined = data?.customer;
  const customerTypeLabel = customerTypes.find((el) => el.value === customer?.type)?.label;
  return [
    { label: 'Job No', value: details?.jobNumber || '-' },
    { label: 'Customer', value: customer?.name || '-' },
    {
      label: 'Due date',
      value: details?.dueDate ? localDateFormatHandler(DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT, details.dueDate) : '-',
    },
    { label: 'Customer type', value: customerTypeLabel || '-' },
    { label: 'Created date', value: details?.createdAt ? localDateFormatHandler('DD-MMM-YYYY', details?.createdAt) : '-' },
    { label: 'Modified date', value: details?.updatedAt ? localDateFormatHandler('DD-MMM-YYYY', details?.updatedAt) : '-' },
  ];
});

export const isJobStatusCompletedSelector = createSelector(jobDetailsSelector, jobStatusesValuesSelector, (details, statusValues) => {
  const { completed } = statusValues;
  return details?.job.jobStatus === completed;
});

export const jobVehicleSelector = createSelector(jobDetailsSelector, (details) => {
  const vehicle = details?.vehicle;
  const vehicleDraftLocation = {
    vehicleCompanyName: vehicle?.vehicleCompanyName,
    vehicleAddressLine1: vehicle?.vehicleAddressLine1,
    vehicleAddressLine2: vehicle?.vehicleAddressLine2,
    vehicleCityTown: vehicle?.vehicleCityTown,
    vehicleRegion: vehicle?.vehicleRegion,
    vehiclePostalCode: vehicle?.vehiclePostalCode,
    vehicleTelephone: vehicle?.vehicleTelephone,
  };
  const vehicleLocationSummary = summaryFieldValueHandle(Object.values(vehicleDraftLocation));
  return { ...vehicle, vehicleLocation: vehicleLocationSummary };
});

export const jobDetailsExpandedViewSelector = createSelector(
  jobDetailsSelector,
  customerTypesSelector,
  priorityLevelsSelector,
  jobTypesSelector,
  (details, customerTypes, priorityLevels, jobTypes) => {
    const job: JobDetailsDto | undefined = details?.job;
    const customer: JobCustomerDto | undefined = details?.customer;
    const customerTypeLabel = customerTypes.find((el) => el.value === customer?.type)?.label;
    const priorityLabel = priorityLevels.find((el) => el.value === job?.priorityLevel)?.label;
    const jobTypeLabel = jobTypes.find((el) => el.value === job?.jobType)?.label;
    const location = {
      jobCompanyName: job?.jobCompanyName,
      jobAddressLine1: job?.jobAddressLine1,
      jobAddressLine2: job?.jobAddressLine2,
      jobCityTown: job?.jobCityTown,
      jobRegion: job?.jobRegion,
      jobPostalCode: job?.jobPostalCode,
      jobTelephone: job?.jobTelephone,
    };
    const setAssigneesDictionary = (assignee: JobAssigneeDto) => ({
      label: assignee.isDeleted ? UNKNOWN_USER : `${assignee.firstName} ${assignee.lastName}`,
      value: assignee.id,
    });
    return {
      jobStatus: job?.jobStatus,
      customerType: customerTypeLabel || '-',
      customerAccountNo: customer?.accountNumber || '-',
      customerName: customer?.name || '-',
      priorityLevel: priorityLabel || '-',
      jobType: jobTypeLabel || '-',
      jobLocation: job ? summaryFieldValueHandle(Object.values(location), '-') : '-',
      description: job?.description || '-',
      vehicle: details?.vehicle,
      assignees: details?.assignees?.map(setAssigneesDictionary) || [],
      previousAssignees: details?.previousAssignees?.map(setAssigneesDictionary) || [],
      partsCount: details?.partsCount,
      attachmentsCount: details?.attachmentsCount,
      linksCount: details?.linksCount,
    };
  },
);
export const jobDetailsFiltersSelector = (state: RootState) => state.jobs.jobDetailsFilters;

const jobListAssigneesInitSelector = (state: RootState) => state.jobs.jobListAssignees;
export const jobListAssigneesSelector = createSelector(jobListAssigneesInitSelector, (assignees) => {
  return assignees.map((el) => ({ label: `${el.firstName} ${el.lastName}`, value: el.id }));
});

export const jobListFiltersSelector = (state: RootState) => state.jobs.jobListFilters;
export const jobsAdminListFiltersSelector = createSelector(
  jobListFiltersSelector,
  jobListAssigneesSelector,
  (
    values,
    assignees,
  ) => {
    return [
      {
        value: values.assigneeIds || [],
        name: 'assigneeIds',
        label: 'Assigned to',
        options: assignees,
      },
    ];
  },
);

export const permittedStatusesSelector = createSelector(
  currentUserSelector,
  jobInitUIStatusesSelector,
  jobStatusesValuesSelector,
  (currentUser, jobInitStatuses, statusValues) => {
    const { completed, cancelled } = statusValues;
    const permissions = currentUser?.userContextRole.permissions;
    let statuses: DictionaryItem[] = jobInitStatuses;
    if (!permissions?.includes(PermissionEnum.JobShowInCancelledStatus)) {
      statuses = statuses.filter((st) => st.value !== cancelled);
    }
    if (!permissions?.includes(PermissionEnum.JobShowInCompletedStatus)) {
      statuses = statuses.filter((st) => st.value !== completed);
    } else statuses = jobInitStatuses;
    return statuses;
  },
);

export const workQueueJobStatusesSelector = createSelector(jobStatusesSelector, (statuses) => {
  return statuses.filter((st) => workQueueNames.includes(st.label as JobStatusesUiNames));
});

export const completedJobStatusesSelector = createSelector(jobStatusesSelector, (statuses) => {
  return statuses.filter((st) => completedNames.includes(st.label as JobStatusesUiNames));
});

export const jobAttachmentsFiltersSelector = (state: RootState) => state.jobs.jobAttachmentsFilters;
export const jobAttachmentsSelector = (state: RootState) => state.jobs.jobAttachments;
export const jobLinksFiltersSelector = (state: RootState) => state.jobs.jobLinksFilters;
export const jobLinksSelector = (state: RootState) => state.jobs.jobLinks;
export const jobLinksRowsSelector = createSelector(
  jobLinksSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
  linksTypesSelector,
  linkTypesValuesSelector,
  (data, statuses, rectificationStatuses, types, linkTypesValues) => {
    const { rectificationLinkType } = linkTypesValues;
    const inheritanceArr = linksReformHandler(data);
    const statusLabel = (status: string | number, type?: number) => {
      if (type === rectificationLinkType) {
        return rectificationStatuses.find((st) => st.value === status)?.label || status;
      } else {
        return statuses.find((st) => st.value === status)?.label || status;
      }
    };
    return linksUiViewHandle(inheritanceArr, statusLabel, types);
  },
);

export const jobListSelector = (state: RootState) => state.jobs.jobList;
export const jobListUiSelector = createSelector(
  jobListSelector,
  jobCategoriesSelector,
  jobStatusesSelector,
  (jobs, categories, statuses) => {
    const categoryLabel = (v: number) => {
      return categories.find((el) => el.value === v)?.label || v;
    };
    const list = jobs?.items.map((j) => ({
      id: j.id,
      jobNumber: j.jobNumber || '-',
      linkedTo: j.linkedNumber,
      jobCategories: j.jobCategories.map((category) => categoryLabel(category)),
      description: j.description || '-',
      isMultipleUserJob: j.isMultipleUserJob,
      workQueueStatus: statuses.find((st) => j.jobStatus === st.value)?.label.toUpperCase() || j.jobStatus,
      isActive: !j.isDeleted,
      jobInitStatus: j.jobStatus,
    }));
    return { items: list || [], totalCount: jobs?.totalCount || 0, pages: jobs?.pages || 0 };
  },
);

export const jobTimeTrackingSelector = (state: RootState) => state.jobs.jobTimeTracking;
const jobTimeTrackingWorkLogsInitSelector = (state: RootState) => state.jobs.jobTimeTracking?.worklogs;
export const jobTimeTrackingWorkLogsSelector = createSelector(jobTimeTrackingWorkLogsInitSelector, (worklogs) => {
  return worklogs?.map((el) => ({
    jobAssignee: el.jobAssignee,
    jobSpentMinutes: el.jobSpentMinutes,
    isAssigneeDeleted: el.isAssigneeDeleted,
  }));
});

export const jobTimelineSelector = (state: RootState) => state.jobs.jobTimeline;
export const jobTimelineFiltersSelector = (state: RootState) => state.jobs.jobTimelineFilters;

export const jobPartsColumnsSelector = createSelector(
  stockPartRequestLinesOrderingSelector,
  partCategoriesLookupSelector,
  partRequestLineStatusesSelector,
  jobDetailsSelector,
  (ordering, categories, statuses, jobDetails) => {
    const enabledNotes = jobDetails?.partRequest?.enableNotes;
    const columns = [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
        sorter: true,
        orderField: getSortIndex(JobPartsOrdering.OrdinalNumber, ordering),
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        filters: categories,
        orderField: getSortIndex(JobPartsOrdering.Category, ordering),
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(JobPartsOrdering.ProductNumber, ordering),
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(JobPartsOrdering.ProductName, ordering),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        filterSearch: true,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
      },
      {
        title: 'Ready',
        dataIndex: 'ready',
      },
      {
        title: 'Collected',
        dataIndex: 'collected',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: true,
        filters: statuses,
        orderField: getSortIndex(JobPartsOrdering.Status, ordering),
      },
    ];
    return enabledNotes ? columns : columns.filter((col) => col.dataIndex !== 'notes');
  },
);

export const jobQualityChecksPartsLinesPagingSelector = (state: RootState) => state.jobs.jobQualityChecksPartsLinesPaging;
export const jobQualityChecksPartsLinesInitSelector = (state: RootState) => state.jobs.jobQualityChecksPartsLines;
export const jobQualityChecksPartsTableLinesSelector = createSelector(
  jobQualityChecksPartsLinesInitSelector,
  QuoteUnitsOfMeasureSelector,
  (data, unitsOfMeasure) => {
    const getMeasureLabel = (measure: number) => unitsOfMeasure.find((el) => el.value === measure)?.label || measure;
    const list: JobQualityCheckPartTableLine[] = data.items.map((el, i) => ({
      id: el.id,
      ordinalNumber: `${getNo(1, 5, i)}`,
      categoryName: el.categoryName || '-',
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      quantity: `${el.confirmedQuantity || 0} ${getMeasureLabel(el.unitOfMeasure)}`,
      status: el.jobQualityCheckStatus,
      childLines: el.childLines?.map((ch, childIndex) => ({
        id: ch.id,
        ordinalNumber: `${getNo(1, 5, i)}.${getNo(1, 5, childIndex)}`,
        categoryName: el.categoryName || '-',
        productNumber: ch.productNumber || '-',
        productName: ch.productName || '-',
        quantity: `${el.confirmedQuantity || 0} ${getMeasureLabel(el.unitOfMeasure)}`,
        status: el.jobQualityCheckStatus,
      })) || null,
    }));
    return {
      ...data,
      items: list,
    };
  },
);

export const jobPartsFiltersSelector = (state: RootState) => state.jobs.jobPartsFilters;
export const jobPartsLinesSelector = (state: RootState) => state.jobs.jobPartsLines;
export const jobPartsTableLinesSelector = createSelector(
  jobPartsLinesSelector,
  partCategoriesLookupSelector,
  partRequestLineStatusesSelector,
  allocatedPartReqLinesStatusesSelector,
  jobDetailsSelector,
  (lines, stockCategories, statuses, allocatedStatuses, jobDetails) => {
    const enabledNotes = jobDetails?.partRequest?.enableNotes;
    const isPartsKitParent = (el: PartRequestLineDto) => el.partsKitId && el.childLines && el.childLines.length > 0;
    const getReadyCollected = (el: PartRequestLineDto, quantity: number) => {
      const isAllocated = !!allocatedStatuses.find((st) => st.value === el.status);
      return isAllocated ? quantity : quantity || '-';
    };
    const list: JobPartsTableLine[] = lines.items.map((el) => ({
      id: el.id,
      ordinalNumber: el.ordinalNumber.toString(),
      categoryId: stockCategories.find((cat) => el.categoryId === cat.value)?.label,
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      notes: el.notes || '-',
      quantity: el.confirmedQuantity || 0,
      readyQuantities: el.readyQuantities,
      readyQuantity: isPartsKitParent(el) ? '' : getReadyCollected(el, el.readyQuantity),
      collectedQuantities: el.collectedQuantities,
      collectedQuantity: isPartsKitParent(el) ? '' : getReadyCollected(el, el.collectedQuantity),
      status: isPartsKitParent(el) ? '' : statuses.find((st) => st.value === el.status)?.label || '-',
      unitOfMeasure: el.unitOfMeasure,
      partsKitId: el.partsKitId,
      partId: el.partId,
      partsKitComponentId: el.partsKitComponentId,
      partsKitComponentPartId: el.partsKitComponentPartId,
      childLines: el.childLines?.map((ch) => ({
        id: ch.id,
        ordinalNumber: `${el.ordinalNumber}.${ch.ordinalNumber}`,
        categoryId: stockCategories.find((cat) => ch.categoryId === cat.value)?.label,
        productNumber: ch.productNumber || '-',
        productName: ch.productName || '-',
        notes: ch.notes || '-',
        quantity: ch.confirmedQuantity || 0,
        readyQuantities: ch.readyQuantities,
        readyQuantity: getReadyCollected(ch, ch.readyQuantity),
        collectedQuantities: ch.collectedQuantities,
        collectedQuantity: getReadyCollected(ch, ch.collectedQuantity),
        status: statuses.find((st) => st.value === ch.status)?.label || '-',
        unitOfMeasure: ch.unitOfMeasure,
        partsKitId: ch.partsKitId,
        partId: ch.partId,
        partsKitComponentId: ch.partsKitComponentId,
        partsKitComponentPartId: ch.partsKitComponentPartId,
      })) || null,
    }));
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return enabledNotes ? list : list.map(({ notes, ...rest }) => ({
      ...rest,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      childLines: rest.childLines?.map(({ notes, ...childRest }) => childRest) || null,
    }));
  },
);

export const jobCalendarFiltersSelector = (state: RootState) => state.jobs.jobCalendarFilters;
export const jobCalendarSelector = (state: RootState) => state.jobs.jobCalendar;
export const jobCalendarDictionarySelector = (state: RootState) => state.jobs.jobCalendarDictionary;
export const jobCalendarStatusesSelector = createSelector(jobStatusesValuesSelector, (statusesValues) => {
  const { inProduction, pending, postponed } = statusesValues;
  return [inProduction, pending, postponed];
});

export const jobsCalendarFiltersArraySelector = createSelector(
  jobCalendarFiltersSelector,
  jobCategoriesSelector,
  priorityLevelsSelector,
  usersFilterLookupSelector,
  jobTypesWithoutSupplyOnlySelector,
  (
    values,
    categories,
    priorityLevels,
    assignees,
    jobTypes,
  ) => {
    return [
      {
        value: values.jobCategories || [],
        name: 'jobCategories',
        label: 'Job category',
        options: categories,
      },
      {
        value: values.priorityLevels || [],
        name: 'priorityLevels',
        label: 'Priority',
        options: priorityLevels,
      },
      {
        value: values.assignees || [],
        name: 'assignees',
        label: 'Assigned to',
        options: assignees,
      },
      {
        value: values.jobTypes || [],
        name: 'jobTypes',
        label: 'Job type',
        options: jobTypes,
      },
    ];
  },
);

export const jobQualityChecksSelector = (state: RootState) => state.jobs.jobQualityChecks;
