import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { IRectificationsFilters } from '../types/rectificationsTypes';
import {
  rectificationsDashFiltersListSelector,
  rectificationsDashFiltersSelector,
} from '../../../store/selectors/rectificationsSelector';
import { setRectificationDashFilters } from '../../../store/slices/rectificationsSlice';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { getRectificationList } from '../../../store/thunks/rectification/rectificationsDashThunks';
import { rectificationStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import { infiniteScrollInitPaging } from '../../../core/types/coreTypes';
import { RectificationModesEnum } from '../enums/rectificationEnums';

export const useRectificationsFilters = () => {
  const dispatch = useAppDispatch();
  const stateFilters = useAppSelector(rectificationsDashFiltersSelector);
  const filtersList = useAppSelector(rectificationsDashFiltersListSelector);
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const rectificationStatuses = useAppSelector(rectificationStatusesSelector);

  const { controller, cancelRequest } = useCancelRequest();

  const initDropdownSearch = {
    priority: '',
    assignedTo: '',
    jobType: '',
    rectificationType: '',
    warnings: '',
  };
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);
  const [keywordValue, setKeywordValue] = useState<string | undefined>(stateFilters.keyword);

  const [params, setSearchParams] = useSearchParams();
  const keywordSearchParam = params.get('keyword');

  const getFilteredRes = (filters: IRectificationsFilters) => {
    cancelRequest();
    dispatch(setRectificationDashFilters({ ...filters, page: 1 }));
    currentBusinessArea && dispatch(getRectificationList({
      params: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: rectificationStatuses.map((st) => st.value),
        page: 1,
      },
      signal: controller.current?.signal,
    }));
  };

  const setInitSearchParams = () => {
    setSearchParams({ page: params.get('page') || RectificationModesEnum.DASHBOARD });
  };

  const onClear = () => {
    getFilteredRes(infiniteScrollInitPaging);
    setKeywordValue('');
    if (keywordSearchParam) {
      setInitSearchParams();
    }
  };

  useEffect(() => {
    return () => {
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    initDropdownSearch,
    filtersList,
    stateFilters,
    getFilteredRes,
    onClear,
    currentBusinessArea,
    keywordSearchParam,
    setInitSearchParams,
  };
};
