import React, { useState } from 'react';
import { RcFile } from 'antd/es/upload';
import Layout from '../core/components/layout/Layout';
import FormsFolders from './components/FormsFolders';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { formsCurrentFolderSelector } from '../store/selectors/formsSelectors';
import ConfirmationModal from '../core/components/confirmation-modal/ConfirmationModal';
import CreateAttachmentModal from '../common/components/create-attachment/CreateAttachmentModal';
import { setLoading } from '../store/slices/coreSlice';
// import { guidGenerator } from '../core/utils/guidGenerator';
import Select from '../core/components/select/Select';
import { setCurrentFolder } from '../store/slices/formsSlice';
import CreateFolderModal from './components/CreateFolderModal';
import ChangeFolderForFilesModal from './components/ChangeFolderForFilesModal';
import './FormsContainer.scss';
import { mockedFolders } from './utils/data';

const FormsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  // const formsTableFilters = useAppSelector(formsTableFiltersSelector);
  const currentFolder = useAppSelector(formsCurrentFolderSelector);

  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState<string>('');
  const [isAll, setIsAll] = useState<boolean>(false);
  const [checkedKeys, setCheckedKeys] = useState<number[]>([]);
  const [isCreateFolder, setCreateFolder] = useState<boolean>(false);
  const [isEditFolder, setIsEditFolder] = useState<boolean>(false);
  const [folderToChange, setFolderToChange] = useState<number | undefined>(undefined);
  const [isCreateModal, setCreateModal] = useState<boolean>(false);
  const [isDeleteModal, setDeleteModal] = useState<boolean>(false);
  const [isMoveToFolder, setMoveToFolder] = useState<boolean>(false);

  const deleteSelected = () => {
    // dispatch(deleteMultipleEnquiryAttachmentThunk({
    //   attachmentIds: checkedKeys,
    //   setCheckedKeys: () => setCheckedKeys([]),
    //   setIsAll: () => setIsAll(false),
    //   closeModal: () => setDeleteModal(false),
    // }));
  };
  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    // const list = fileList.map((el: CustomAny) => ({
    //   fileName: el.name,
    //   uploadGuid: guidGenerator(),
    //   file: el,
    // }));
    //   currentFolder && dispatch(createEnquiryAttachmentThunk({
    //   id: currentFolder,
    //   list,
    //   comment,
    //   onCancel: () => cancelHandle(),
    // }));
  };

  const confirmDeleteHandle = () => {
    if (folderToChange) {
      console.debug('delete folder by id');
    } else deleteSelected();
  };
  return (
    <Layout>
      <CreateFolderModal
        visible={isCreateFolder}
        onCancel={() => {
          if (isEditFolder) {
            setIsEditFolder(false);
            setFolderToChange(undefined);
          }
          setCreateFolder(false);
        }}
        editMode={isEditFolder}
        folderToChange={folderToChange}
      />
      <ChangeFolderForFilesModal
        visible={isMoveToFolder}
        onCancel={() => setMoveToFolder(true)}
        checkedKeys={checkedKeys}
      />
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        uploadHandle={uploadHandle}
        fileList={fileList}
        setFileList={setFileList}
        comment={comment}
        setComment={setComment}
        extraField={<Select
          value={currentFolder}
          onChange={(v) => dispatch(setCurrentFolder(v as number))}
          label="File Folder"
          options={mockedFolders}
          parentRender
        />}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          if (folderToChange) {
            setFolderToChange(undefined);
          }
          setDeleteModal(false);
        }}
        confirmHandler={confirmDeleteHandle}
      />
      <FormsFolders
        setCreateFolder={setCreateFolder}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        setIsAll={setIsAll}
        setCreateModal={setCreateModal}
        setDeleteModal={setDeleteModal}
        setMoveToFolder={setMoveToFolder}
        isAll={isAll}
        setIsEditFolder={setIsEditFolder}
        setFolderToChange={setFolderToChange}
      />
    </Layout>
  );
};

export default FormsContainer;
