import React from 'react';
import { SupplierDto } from '../../types/SuppliersTypes';
import SupplierListItem from './SupplierListItem';
import { useAppSelector } from '../../../../store/hooks';
import SkeletonLinesList from '../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import SuppliersSkeletonItem from '../suppliers-skeleton/SuppliersSkeletonItem';
import { isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';

type SupplierLinesProps = {
  suppliers: {items: SupplierDto[], pages: number, totalCount: number},
}

const SupplierLines: React.FC<SupplierLinesProps> = ({ suppliers }) => {
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <SuppliersSkeletonItem />
        </SkeletonLinesList>
        : <ul className="lines-list">
          {suppliers.items.map((item) => (
            <li className="lines-list__item" key={item.id}>
              <SupplierListItem item={item} />
            </li>
          ))}
        </ul>}
    </>
  );
};

export default SupplierLines;
