import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import Input from '../../../core/components/input/Input';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import { maxCommonDecimal } from '../../../core/utils/regex';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../core/components/select/Select';
import { setErrorMessage, setSuccessMessage } from '../../../store/slices/coreSlice';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  gMeasureSelector,
  integerQuantityMeasureValuesSelector, isFetchingSelector,
  quoteLineTaxesSelector,
  QuoteUnitsOfMeasureSelector, timeMeasureValuesSelector, weightMeasureValuesSelector,
} from '../../../store/selectors/coreSelectors';
import { CustomLinePartDto, CustomLineReqType } from '../../types/commonTypes';
import { partCategoriesLookupSelector } from '../../../store/selectors/sharedSelectors';
import {
  CustomPartModeDefaults,
  CustomPartModeFields,
  ProductNameFieldValidationSchema, QuantityFieldValidationSchema,
} from '../../types/customPartFormSchema';

type CustomModeProps = {
  onCancel: VoidFunctionType,
  editMode?: boolean;
  editedLine?: number;
  editHandle?: (values: CustomLineReqType, closeModal: VoidFunctionType) => void,
  initLine?: CustomLinePartDto,
  createHandle?: (values: CustomLineReqType, closeModal: VoidFunctionType) => void,
  isEnquiry?: boolean,
}

const CustomMode: React.FC<CustomModeProps> = ({
  editMode, editedLine, onCancel, editHandle, initLine,
  createHandle, isEnquiry,
}) => {
  const dispatch = useAppDispatch();
  const taxes = useAppSelector(quoteLineTaxesSelector);
  const quoteUnitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const partCategories = useAppSelector(partCategoriesLookupSelector);
  const gMeasure = useAppSelector(gMeasureSelector);
  const integerQuantitiesMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const timeMeasures = useAppSelector(timeMeasureValuesSelector);
  const weightMeasures = useAppSelector(weightMeasureValuesSelector);
  const [availableCategories, setAvailableCategories] = useState(partCategories);
  const [searchValue, setSearchValue] = useState<string>('');
  const prevQuantityRef = useRef<number | null>(null);
  const isFetching = useAppSelector(isFetchingSelector);
  const {
    handleSubmit, control, watch, setValue, reset, setError,
  } = useForm<CustomPartModeFields>({
    defaultValues: CustomPartModeDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const quantity = watch('quantity');
  const unitPrice = watch('unitPrice');
  const tax = watch('tax');
  const measure = watch('unitOfMeasure');
  const weight = watch('weight');

  const isWeightMeasure = !!(measure && weightMeasures.includes(measure));
  const isTimeMeasure = !!(measure && timeMeasures.includes(measure));
  const isUnitOfMeasureEqualsGram = !!(measure && measure === gMeasure);
  const isIntegerQtyMeasure = !!(measure && integerQuantitiesMeasures.includes(measure));

  const closeModal = () => {
    onCancel();
    setSearchValue('');
  };
  const onSubmit = (values: CustomPartModeFields) => {
    const getWeight = () => {
      if (isTimeMeasure) return 0;
      if (isUnitOfMeasureEqualsGram) return Number((+values.weight / 1000).toFixed(2));
      return +values.weight || 0.00;
    };

    const finalValues = {
      ...values,
      productName: values.productName.trim(),
      weight: getWeight(),
      unitPrice: +values.unitPrice || 0.00,
      quantity: +values.quantity || 0,
      unitOfMeasure: values.unitOfMeasure as number,
      tax: values.tax as number,
      quoteCategoryId: values.quoteCategoryId as number,
    };
    if (editMode) {
      const equalityArr = [
        initLine?.tax === finalValues.tax,
        initLine?.unitOfMeasure === finalValues.unitOfMeasure,
        initLine?.quantity === finalValues.quantity,
        initLine?.weight === finalValues.weight,
        initLine?.productName === finalValues.productName,
        Number(initLine?.unitPrice) === finalValues.unitPrice,
        initLine?.quoteCategoryId === finalValues.quoteCategoryId,
      ];
      if (equalityArr.some((el) => !el)) {
        editHandle && editHandle(finalValues, closeModal);
      } else {
        closeModal();
      }
    } else {
      const close = () => {
        closeModal();
        const lineType = isEnquiry ? 'quote' : 'part request';
        dispatch(setSuccessMessage({
          message: `A custom ${lineType} line was successfully added.`,
        }));
      };
      createHandle && createHandle(finalValues, close);
    }
  };

  const netValue = useMemo(() => {
    const quantityV = +quantity;
    const unitPriceV = +unitPrice;
    return quantityV * unitPriceV;
  }, [quantity, unitPrice]);

  const priceValue = useMemo(() => {
    const quantityV = +quantity;
    const unitPriceV = +unitPrice;
    return quantityV * unitPriceV + (quantityV * unitPriceV * (tax ? tax * 0.01 : 0));
  }, [quantity, unitPrice, tax]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        setAvailableCategories(partCategories.filter((el) => el.label.includes(searchValue)));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      setAvailableCategories(partCategories);
    }
    // eslint-disable-next-line
  }, [searchValue]);

  useEffect(() => {
    if (isIntegerQtyMeasure && quantity) {
      const wholeValue = Math.round(+quantity);
      setValue('quantity', wholeValue.toString());
    }
    // eslint-disable-next-line
  }, [measure, quantity, integerQuantitiesMeasures]);
  useEffect(() => {
    if (isWeightMeasure && +quantity > 0) {
      setValue('weight', quantity);
    }
    // eslint-disable-next-line
  }, [measure]);
  useEffect(() => {
    if (editMode && editedLine) {
      const keysToSet = ['productName', 'quantity', 'unitOfMeasure', 'unitPrice', 'tax', 'quoteCategoryId', 'weight'];
      const keysWithZeroValue = ['quantity', 'unitPrice', 'tax'];
      if (initLine) {
        Object.keys(initLine).forEach((key) => {
          if (keysToSet.includes(key)) {
            if (keysWithZeroValue.includes(key)) {
              if (key === 'unitPrice') {
                setValue('unitPrice', initLine.unitPrice ? initLine.unitPrice : '0.00');
              } else {
                const defaultV = key === 'tax' ? 0 : '0.00';
                const v = key === 'tax' ? initLine[key] : initLine[key as keyof CustomLinePartDto]?.toString();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                setValue(key as keyof CustomLinePartDto, initLine[key] || initLine[key] === 0 ? v : defaultV);
              }
            } else if (key === 'weight') {
              setValue('weight', initLine.weight ? initLine.weight.toString() : '0.00');
            } else {
              setValue(key as keyof CustomLinePartDto, initLine[key as keyof CustomLinePartDto] || '');
            }
          }
        });
      }
    } else {
      reset();
    }
    // eslint-disable-next-line
  }, [editMode, editedLine]);
  const handleQuantityFocus = (value: string) => {
    if (value && +value > 0 && +weight > 0) {
      prevQuantityRef.current = +value;
    }
  };
  const handleQuantityBlur = (value: string) => {
    if (+weight > 0) {
      if (measure && prevQuantityRef.current) {
        const weightResult = ((+weight / prevQuantityRef.current) * +value).toFixed(2);
        const finalResult = +weightResult > maxCommonDecimal
          ? maxCommonDecimal.toString()
          : weightResult;
        setValue('weight', finalResult);
        prevQuantityRef.current = null;
      }
    } else {
      if (isWeightMeasure) {
        setValue('weight', quantity);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate className="customLineForm">
      <div className="customLineForm__section">
        <Controller
          name="productName"
          control={control}
          rules={ProductNameFieldValidationSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={() => {
                if (!field.value.trim()) {
                  setError('productName', { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue('productName', '');
                }
              }}
              error={errors.productName?.message}
              label="Product name"
              className="customLineForm__field--wide"
            />
          )}
        />
        <Controller
          name="quantity"
          control={control}
          rules={QuantityFieldValidationSchema(isIntegerQtyMeasure)}
          render={({ field }) => (
            <>
              {isIntegerQtyMeasure
                ? <RegularInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value;
                    field.onChange(v);
                  }}
                  onFocus={(e) => handleQuantityFocus(e.target.value)}
                  onBlur={(e) => handleQuantityBlur(e.target.value)}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                  placeholder="0"
                />
                : <DecimalInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value.replace(',', '.');
                    field.onChange(v);
                  }}
                  onFocus={(e) => handleQuantityFocus(e.target.value)}
                  onBlur={(e) => handleQuantityBlur(e.target.value)}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                  placeholder="0.00"
                />}
            </>
          )}
        />
        <Controller
          name="unitOfMeasure"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={quoteUnitsOfMeasure}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              error={errors.unitOfMeasure?.message}
              label="Unit of measure"
              parentRender
            />
          )}
        />
        <Controller
          name="unitPrice"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DecimalInputNumber
              value={field.value}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                field.onChange(v);
              }}
              max={maxCommonDecimal}
              error={errors.unitPrice?.message}
              label="Unit price"
              placeholder="0.00"
            />
          )}
        />
        <Controller
          name="tax"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={taxes}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              error={errors.tax?.message}
              label="Tax"
              parentRender
            />
          )}
        />
        <Input
          label="Net"
          prefix="£"
          disabled
          value={netValue.toFixed(2)}
        />
        <Input
          label="Gross"
          prefix="£"
          disabled
          value={priceValue.toFixed(2)}
        />
        <Controller
          name="quoteCategoryId"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={availableCategories}
              value={field.value}
              onChange={field.onChange}
              showSearch
              onSearch={(val) => {
                if (val.length <= 250) {
                  setSearchValue(val);
                } else {
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              }}
              onSelect={() => {
                setAvailableCategories(partCategories);
              }}
              error={errors.quoteCategoryId?.message}
              label="Quote category"
              parentRender
            />
          )}
        />
        {isTimeMeasure
          ? <Input
            label="Weight"
            value="-"
            className="customLineForm__disabled-weight"
            disabled
          />
          : <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <DecimalInputNumber
                value={isUnitOfMeasureEqualsGram ? (+field.value / 1000).toFixed(2) : field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                max={maxCommonDecimal}
                postfix="kg"
                error={errors.weight?.message}
                label="Weight"
                disabled={isWeightMeasure}
                placeholder="0.00"
              />
            )}
          />}
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        createType="submit"
        createLabel={editMode ? 'Save' : 'Add line'}
        disabledCreate={Object.keys(errors).length > 0}
        cancelClick={() => {
          reset();
          closeModal();
        }}
        isLoading={isFetching}
        offsetTop
      />
    </form>
  );
};

export default CustomMode;
