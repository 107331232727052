import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { BooleanFunctionType, NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Button from '../../../core/components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { skillsCategoriesSelector, skillsFiltersSelector } from '../../../store/selectors/employeesSelector';
import { setSkillsFilters } from '../../../store/slices/employeesSlice';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import SkillsTable from './SkillsTable';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';

const { TabPane } = Tabs;

type SkillsCategoriesProps = {
  setCreateCategory: BooleanFunctionType,
  setCategoryToChange: (v?: number) => void,
  checkedKeys: number[],
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkRows: NumberFunctionType,
  setEditCategoryMode: BooleanFunctionType,
  setAddSkill: BooleanFunctionType,
  setEditSkillMode: BooleanFunctionType,
  setSkillToChange: NumberFunctionType,
  openDeleteModalHandle: (categoryId?: number, skillId?: number) => void,
}

const SkillsCategories: React.FC<SkillsCategoriesProps> = ({
  setCreateCategory,
  setCategoryToChange,
  checkedKeys,
  isAll,
  isIndeterminate,
  checkAll,
  checkRows,
  setEditCategoryMode,
  setAddSkill,
  setEditSkillMode,
  setSkillToChange,
  openDeleteModalHandle,
}) => {
  const dispatch = useAppDispatch();
  const skillsFilters = useAppSelector(skillsFiltersSelector);
  const skillsCategories = useAppSelector(skillsCategoriesSelector);

  return (
    <Tabs
      renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile={false} />
      )}
      activeKey={skillsFilters?.categoryId?.toString()}
      tabPosition="top"
      onChange={(value) => {
        dispatch(setSkillsFilters({ ...skillsFilters, categoryId: +value }));
      }}
      className={classnames('skillsCategories scrolling-tabs', { 'scrolling-tabs--empty': skillsCategories.length === 0 })}
      popupClassName="scrolling-tabs__popup"
      tabBarExtraContent={
        <Button
          label="Create category"
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          onClick={() => setCreateCategory(true)}
          designType="dark"
          reversed
        />
      }
      destroyInactiveTabPane
    >
      {skillsCategories.length ? skillsCategories.map((el) => (
        <TabPane
          tab={<span className="scrolling-tabs__tab-button">
            <TruncateTooltip value={el.name} className="scrolling-tabs__tab-label" hideTooltipOnMobile />
            {!!el.skillsCount && <span className="scrolling-tabs__count-value">{`(${el.skillsCount})`}</span>}
          </span>}
          key={el.id}
        >
          <SkillsTable
            checkedKeys={checkedKeys}
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            checkAll={checkAll}
            checkRows={checkRows}
            openEditCategory={(id: number) => {
              setCreateCategory(true);
              setEditCategoryMode(true);
              setCategoryToChange(id);
            }}
            addSkill={() => setAddSkill(true)}
            openDeleteModal={(categoryId?: number, skillId?: number) => {
              openDeleteModalHandle(categoryId, skillId);
            }}
            openEditSkill={(id: number) => {
              setAddSkill(true);
              setEditSkillMode(true);
              setSkillToChange(id);
            }}
          />
        </TabPane>
      ))
        : <EmptyContentList icon={faScrewdriverWrench} text="Please create a skill category" />}
    </Tabs>
  );
};

export default SkillsCategories;
