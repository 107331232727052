import React, { useEffect, useRef } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import Switch from '../../../../core/components/switch/Switch';
import PartReorderLine from './PartReorderLine';
import './PartReorderLevels.scss';
import {
  stockPartReorderLevelsFiltersSelector,
  stockPartReorderLevelsSelector,
  stockPartReorderLevelsTypesSelector,
} from '../../../../store/selectors/stockSelectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { setPartReorderLevels, setPartReorderLevelsFilters } from '../../../../store/slices/stockSlice';
import { InitStockReorderLevelsFilters } from '../../../search/types/stockSearchTypes';
import { getStockSearchReorderLevels } from '../../../../store/thunks/stock/stockThunks';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';
import { initDataList } from '../../../../core/types/coreTypes';
import { getStockPartsOrdering } from '../../../../store/thunks/core/coreOrderingThunks';

const PartReorderLevels = () => {
  const stockPartReorderLevels = useAppSelector(stockPartReorderLevelsSelector);
  const stockPartReorderLevelsFilters = useAppSelector(stockPartReorderLevelsFiltersSelector);
  const reorderLevelsTypes = useAppSelector(stockPartReorderLevelsTypesSelector);
  const dispatch = useAppDispatch();
  const isCritical = stockPartReorderLevelsFilters.order?.field === reorderLevelsTypes.critical;
  const scrollableRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!reorderLevelsTypes.critical) {
      dispatch(getStockPartsOrdering());
    } else {
      dispatch(setPartReorderLevelsFilters({
        ...stockPartReorderLevelsFilters,
        order: { field: reorderLevelsTypes.critical, isAsc: true },
      }));
    }
    // eslint-disable-next-line
  }, [reorderLevelsTypes.critical]);

  useEffect(() => {
    if (stockPartReorderLevelsFilters.order?.field) {
      dispatch(getStockSearchReorderLevels({ filters: stockPartReorderLevelsFilters }));
    }
    // eslint-disable-next-line
  }, [stockPartReorderLevelsFilters]);

  useEffect(() => {
    return () => {
      dispatch(setPartReorderLevelsFilters(InitStockReorderLevelsFilters));
      dispatch(setPartReorderLevels(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const loadNext = () => {
    dispatch(setPartReorderLevelsFilters({ ...stockPartReorderLevelsFilters, page: stockPartReorderLevelsFilters.page + 1 }));
  };

  const handleChangeSwitch = () => {
    const field = stockPartReorderLevelsFilters.order?.field === reorderLevelsTypes.critical
      ? reorderLevelsTypes.warning
      : reorderLevelsTypes.critical;
    if (field) {
      const filters = {
        ...InitStockReorderLevelsFilters,
        order: { field, isAsc: true },
        hasCriticalReorderShortage: !stockPartReorderLevelsFilters.hasCriticalReorderShortage,
        hasWarningReorderShortage: !stockPartReorderLevelsFilters.hasWarningReorderShortage,
      };
      dispatch(setPartReorderLevelsFilters(filters));
      scrollableRef.current?.scrollTo(0, 0);
    }
  };

  return (
    <section className="partReorderLevels info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Part reorder levels</h2>
        <Switch
          checked={!isCritical}
          onChange={handleChangeSwitch}
          textLeft="Critical"
          textRight="Warning"
          singleBackground
        />
      </div>
      <div className="partReorderLevels__content" id="scrollableDiv" ref={scrollableRef}>
        {stockPartReorderLevels.items.length > 0
          ? <InfiniteScroll
            next={loadNext}
            hasMore={stockPartReorderLevelsFilters.page !== stockPartReorderLevels.pages}
            loader={<span className="partReorderLevels__loading">Loading...</span>}
            dataLength={stockPartReorderLevels.items.length}
            scrollableTarget="scrollableDiv"
          >
            <ul className="partReorderLevels__list">
              {stockPartReorderLevels.items.map((el, i) => <li key={i} className="partReorderLevels__item">
                <PartReorderLine data={el} isCritical={isCritical} />
              </li>)}
            </ul>
          </InfiniteScroll>
          : <EmptyContentList className="partReorderLevels__empty" icon={faThumbsUp} text="No issues with reorder levels" />}
      </div>
    </section>
  );
};

export default PartReorderLevels;
