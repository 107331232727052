import qs from 'qs';
import instance from './axiosConfig';
import { GetSupplierResponse, SupplierDto, SuppliersFiltersType } from '../supplier/dashboard/types/SuppliersTypes';
import { CreateSupplierResType } from '../supplier/create-supplier/types/CreateSupplierTypes';
import { ExtendedTimelineFilters } from '../common/types/commonTypes';
import {
  CreateSupplierContactResponse,
  NewSupplierContactReqDataType, SupplierContactDto, SupplierEventDtoBase,
} from '../supplier/supplier-view-page/types/supplierViewPageTypes';
import {
  AxiosCreatedAttachmentsResponse,
  AxiosCreatedIdResponse,
  AxiosCreateUploadUriResponse, AxiosDataListResponse,
  AxiosGenericResponse,
  AxiosGetAttachmentFileResponse,
  AxiosGetPagedAttachmentsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  PagingType,
  PostAttachReqType, SupplierDictionaryDto,
  UploadUriDto,
} from '../core/types/coreTypes';
import { CreateSupplierRequest, PatchSupplierRestModel } from '../supplier/create-supplier/utils/SupplierSchema';

export const SuppliersAPI = {
  fetchSupplierDictionary(filters?: { keyword?: string }): AxiosSingleResponse<{ suppliers: SupplierDictionaryDto[] }> {
    return instance.get('/supplier/supplier/views/dictionary', {
      params: {
        'Filter.SearchParameters.Keyword': filters?.keyword,
        'Filter.SearchParameters.IncludeSupplierAccountNumber': filters?.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeSupplierName': filters?.keyword ? true : undefined,
      },
    });
  },
  fetchSupplierById(id: number): AxiosGenericResponse<GetSupplierResponse> {
    return instance.get(`/supplier/supplier/${id}`);
  },
  deleteSupplier(id: number): AxiosNullableResponse {
    return instance.delete(`/supplier/supplier/${id}`);
  },
  patchSupplier(id: number, data: PatchSupplierRestModel): AxiosNullableResponse {
    return instance.patch(`/supplier/supplier/${id}`, { ...data });
  },
  putSupplierStatus(id: number, supplierStatus: number): AxiosNullableResponse {
    return instance.put(`/supplier/supplier/${id}/status`, { supplierStatus });
  },
  createSupplier(data: CreateSupplierRequest): AxiosSingleResponse<CreateSupplierResType> {
    return instance.post('/supplier/supplier', { ...data });
  },
  fetchSupplierList(filters?: SuppliersFiltersType, signal?: AbortSignal): AxiosDataListResponse<SupplierDto[]> {
    return instance.get('/supplier/supplier', {
      params: {
        'Filter.Keyword': filters?.keyword,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
        'Filter.SupplierStatuses': filters?.statuses,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchSuppliersAttachments(
    supplierId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/supplier/suppliers/${supplierId}/attachments`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  postSupplierAttachment(supplierId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/supplier/suppliers/${supplierId}/attachments`, { objectId: supplierId, attachments });
  },
  postSupplierAttachmentUploadUri(supplierId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/supplier/suppliers/${supplierId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editSupplierAttachment(id: number, supplierId: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/supplier/suppliers/${supplierId}/attachments/${id}`, { comment });
  },
  deleteSupplierAttachment(id: number, supplierId: number): AxiosNullableResponse {
    return instance.delete(`/supplier/suppliers/${supplierId}/attachments/${id}`);
  },
  deleteMultipleSupplierAttachment(supplierId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/supplier/suppliers/${supplierId}/attachments`, { data: { attachmentIds } });
  },
  getSupplierAttachmentById(id: number, supplierId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/supplier/suppliers/${supplierId}/attachments/${id}`);
  },
  fetchSupplierContacts(
    supplierId: number,
    filters: PagingType,
  ): AxiosDataListResponse<SupplierContactDto[]> {
    return instance.get('/supplier/supplierContact', {
      params: {
        'Filter.SupplierId': supplierId,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  deleteSupplierContact(contactId: number): AxiosNullableResponse {
    return instance.delete(`/supplier/supplierContact/${contactId}`);
  },
  createNewSupplierContact(data: NewSupplierContactReqDataType): AxiosSingleResponse<CreateSupplierContactResponse> {
    return instance.post('/supplier/supplierContact', { ...data });
  },
  editSupplierContact(contactId:number, data: NewSupplierContactReqDataType): AxiosNullableResponse {
    return instance.put(`/supplier/supplierContact/${contactId}`, { ...data });
  },
  fetchSupplierTimeline(supplierId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<SupplierEventDtoBase[]> {
    return instance.get('/supplier/supplier/timeline', {
      params: {
        SupplierId: supplierId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postSupplierNote(supplierId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/supplier/note', { content, supplierId });
  },
  editSupplierNote(noteId: number, content: string): AxiosNullableResponse {
    return instance.put(`/supplier/note/${noteId}`, { content });
  },
  deleteSupplierNote(noteId: number): AxiosNullableResponse {
    return instance.delete(`/supplier/note/${noteId}`);
  },
};
