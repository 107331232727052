import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import PurchaseItem from './PurchaseItem';
import './PartViewPurchase.scss';
import {
  partPurchaseOrdersSelector,
  partPurchaseOrdersFiltersSelector, partPurchaseOrdersStatusTabsSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getPartPurchaseOrders } from '../../../../../../store/thunks/stock/part/partViewPageThunks';
import { setPartPurchaseOrdersFilters } from '../../../../../../store/slices/purchaseOrderSlice';
import EmptyContentList from '../../../../../../core/components/empty-content-list/EmptyContentList';
import Segmented from '../../../../../../core/components/segmented/Segmented';
import { StockTabParamEnum } from '../../../../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { useScreenWitdh } from '../../../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../../../core/components/selectable/Selectable';

const PartViewPurchases: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const filters = useAppSelector(partPurchaseOrdersFiltersSelector);
  const partPurchases = useAppSelector(partPurchaseOrdersSelector);
  const { windowWidth } = useScreenWitdh();

  const options = useAppSelector(partPurchaseOrdersStatusTabsSelector);

  useEffect(() => {
    id && dispatch(getPartPurchaseOrders({ filters: { ...filters, partId: +id } }));
    // eslint-disable-next-line
  }, [id, filters]);

  const changeFiltersIsActiveState = (value: string | number) => {
    dispatch(setPartPurchaseOrdersFilters({
      ...filters,
      page: 1,
      isActive: value === StockTabParamEnum.ACTIVE,
    }));
  };

  return (
    <div className="partPurchases">
      {windowWidth && windowWidth < responsiveEndpointsEnum.XL
        ? <Selectable
          options={options}
          value={filters.isActive ? StockTabParamEnum.ACTIVE : StockTabParamEnum.ARCHIVED}
          onChange={changeFiltersIsActiveState}
        />
        : <Segmented
          options={options}
          value={filters.isActive ? StockTabParamEnum.ACTIVE : StockTabParamEnum.ARCHIVED}
          onChange={changeFiltersIsActiveState}
        />}
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        totalItems={partPurchases.totalCount}
        onChange={(page, pageSize) => dispatch(setPartPurchaseOrdersFilters({ ...filters, page, pageSize }))}
        showTotal={false}
        showSizeChanger={false}
        className="partPurchases__pagination"
      />
      <ul className="lines-list">
        {partPurchases.totalCount > 0
          ? <>
            {partPurchases.items?.map((el, i) => (
              <li key={i} className="lines-list__item"><PurchaseItem item={el} /></li>
            ))}
          </>
          : <EmptyContentList
            icon={faShoppingCart}
            text={filters.isActive ? 'No active purchase orders' : 'No archived purchase orders'}
          />}
      </ul>
    </div>
  );
};

export default PartViewPurchases;
