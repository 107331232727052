import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../core/utils/errorMessageHandle';
import { EMAIL_REGEX, NAME_REGEX, PHONE_FAX_REGEX } from '../../core/utils/regex';

export type NewContactFormFields = {
  firstName: string,
  lastName: string,
  position: string | null,
  email: string | null,
  telephone: string | null,
  mobile: string | null,
}

export const newContactFormFieldsDefaults = {
  'firstName': '',
  'lastName': '',
  'position': '',
  'email': null,
  'telephone': null,
  'mobile': null,
};

export const NameFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
  pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
};

export const PositionFieldValidationSchema = {
  maxLength: { value: 200, message: maxLengthMessageHandle(200) },
};

export const EmailFieldValidationSchema = (isConditionTruthy: boolean) => ({
  required: isConditionTruthy && ErrorsEnum.AT_LEAST_ONE_CONTACT,
  pattern: { value: EMAIL_REGEX, message: ErrorsEnum.INVALID_EMAIL },
});

export const PhoneMobileFieldsValidationSchema = (isConditionTruthy: boolean) => ({
  required: isConditionTruthy && ErrorsEnum.AT_LEAST_ONE_CONTACT,
  pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_MOBILE },
  minLength: { value: 7, message: ErrorsEnum.INVALID_MOBILE },
  maxLength: { value: 30, message: ErrorsEnum.INVALID_MOBILE },
});
