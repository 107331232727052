import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { throttle } from 'lodash';
import classNames from 'classnames';
import Popover from '../popover/Popover';
import './TableControl.scss';
import { useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type TableControlProps = {
  content: React.ReactNode,
  icon: React.ReactNode,
  disabled?: boolean,
  overlayClassName?: string,
  maxWidth?: string,
  maxHeight?: string,
  activeControl?: boolean,
  insideModal?: boolean,
  totalItems?: number,
}

const TableControl:React.FC<TableControlProps> = ({
  content, icon, disabled, overlayClassName, maxWidth, maxHeight, activeControl,
  insideModal, totalItems,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const [isVisible, setIsVisible] = useState(false);
  const elementsRef = useRef<{ target: Element | null, tooltip: Element | null, modal: Element | null }>(
    {
      target: null,
      tooltip: null,
      modal: null,
    },
  );

  const handler = () => {
    elementsRef.current.tooltip = document.querySelector('.tableControl');
    const { target, tooltip } = elementsRef.current;
    if (target && tooltip) {
      const targetRect = target.getBoundingClientRect();
      if (tooltip instanceof HTMLDivElement) {
        tooltip.style.top = `${window.scrollY + targetRect.top + targetRect.height + 4}px`;
      }
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandler = useCallback(throttle(handler, 10), []);

  useEffect(() => {
    if (isVisible) {
      if (insideModal) {
        elementsRef.current.modal = document.querySelector('.ant-quality-check-modal-wrap');
        if (elementsRef.current.modal) {
          elementsRef.current.modal.addEventListener('scroll', throttledHandler);
        }
      }
      elementsRef.current.target = document.querySelector('.ant-popover-open');
    }
    return () => {
      if (elementsRef.current.modal) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        elementsRef.current.modal.removeEventListener('scroll', throttledHandler);
      }
      elementsRef.current.target = null;
      elementsRef.current.tooltip = null;
      elementsRef.current.modal = null;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [insideModal, isVisible, totalItems]);

  useEffect(() => {
    handler();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalItems, isGlobalLoading]);

  return (
    <Popover
      content={content}
      icon={icon}
      overlayClassName={classNames('tableControl', overlayClassName, { 'tableControl--not-in-modal': !insideModal })}
      trigger="click"
      placement="bottom"
      disabled={disabled}
      buttonClassName={classNames('table__button', { 'table__button--active': activeControl, 'table__button--disabled': disabled })}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      disablePopoverArrow
      destroyTooltipOnHide
      onVisibleChange={(visible) => setIsVisible(visible)}
    />
  );
};

export default TableControl;
