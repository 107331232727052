import React, { useEffect, useState } from 'react';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import Layout from '../../core/components/layout/Layout';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import CreateVehicleForm from './components/CreateVehicleForm';
import { getCustomersForVehicleCreation } from '../../store/thunks/vehicles/createVehicleThunks';
import { useAppDispatch } from '../../store/hooks';
import { vehicleDictionariesHandle } from '../../common/utils/vehicleDictionariesHandle';

const CreateVehicle: React.FC = () => {
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState();

  const [customerKeyword, setCustomerKeyword] = useState<string | undefined>(undefined);
  const [searchError, setSearchError] = useState<string | undefined>(undefined);

  useEffect(() => {
    vehicleDictionariesHandle(dispatch);
    // eslint-disable-next-line
  }, []);
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (customerKeyword) {
      const timeOutId = setTimeout(() => {
        dispatch(getCustomersForVehicleCreation({ keyword: customerKeyword.trim() }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getCustomersForVehicleCreation({ keyword: undefined }));
    }
    // eslint-disable-next-line
  }, [customerKeyword]);
  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new vehicle</div>
    </DetailsHead>}
    >
      <CreateVehicleForm
        setCustomerKeyword={setCustomerKeyword}
        searchError={searchError}
        setSearchError={setSearchError}
      />
    </Layout>
  );
};

export default CreateVehicle;
