import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShuttleVan } from '@fortawesome/free-solid-svg-icons';

const EmptyTransferList: React.FC = () => {
  return (
    <li className="emptyTransferList">
      <FontAwesomeIcon className="emptyTransferList__icon" icon={faShuttleVan} />
      <span className="emptyTransferList__text">No vehicles</span>
    </li>
  );
};

export default EmptyTransferList;
