import { RcFile } from 'antd/lib/upload';
import { useAppDispatch } from '../../../store/hooks';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { ForbiddenFileExtensions } from '../../../common/utils/forbiddenFileExtensions';

export const useVehicleCheckBeforeAttachmentUpload = (fileList: RcFile[], setFileList: (v: RcFile[]) => void) => {
  const dispatch = useAppDispatch();
  const beforeUpload = (files: RcFile[]) => {
    const bigFiles = files.filter((f) => (f.size / 1024) / 1024 > 50);
    const normalFiles = files.filter((f) => (f.size / 1024) / 1024 <= 50);
    if (bigFiles) {
      for (let i = 0; i < bigFiles.length; i++) {
        const toastId = `big-${bigFiles[i].name}-${i}`;
        const message = `File ${bigFiles[i].name} exceeds the maximum file size (50 MB).`;
        dispatch(setErrorMessage({ message, toastId }));
      }
    }
    if (normalFiles.length > 20) {
      const message = 'Maximum 20 files are allowed per upload.';
      dispatch(setErrorMessage({ message, toastId: 'max-upload' }));
    }
    const arr = [];
    for (let i = 0; i < normalFiles.length; i++) {
      const currentName = normalFiles[i].name;
      const currentExt = currentName.substring(currentName.lastIndexOf('.') + 1);
      if (ForbiddenFileExtensions.includes(`.${currentExt.toUpperCase()}`)) {
        const toastId = `format-${currentName}-${i}`;
        const message = `File ${currentName} has unsupported file format.`;
        dispatch(setErrorMessage({ message, toastId }));
      } else {
        arr.push(normalFiles[i]);
      }
    }
    setFileList(fileList ? [...fileList, ...arr] : arr);
  };

  return {
    beforeUpload,
  };
};
