import React from 'react';
import { JobCalendarSubElementDto, JobElementClassname } from '../types/jobCalendarTypes';

type GapTypeElementProps = {
  subEl: JobCalendarSubElementDto,
  setClassname: (item: JobCalendarSubElementDto, isEl?: boolean, isJobNotSatisfiesFilters?: boolean) => JobElementClassname,
}

const GapTypeElement: React.FC<GapTypeElementProps> = ({
  subEl, setClassname,
}) => {
  return (
    <div
      key={subEl.dayNumber}
      className={setClassname(subEl, true)}
      style={{ gridColumn: `span ${subEl.slotsCount}` }}
    />
  );
};

export default GapTypeElement;
