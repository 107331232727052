import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { vehicleLinksFiltersSelector, vehicleLinksRowSelector } from '../../../../../store/selectors/vehiclesSelectors';
import LinksTable from '../../../../../common/components/links-table/LinksTable';
import VehicleLinksColumns from './VehicleLinksColumns';
import LinksBody from '../../../../../common/components/links-table/LinksBody';
import { getVehicleLinksThunk } from '../../../../../store/thunks/vehicles/vehicleViewPageThunks';

const VehicleLinks: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const filters = useAppSelector(vehicleLinksFiltersSelector);
  const links = useAppSelector(vehicleLinksRowSelector);

  useEffect(() => {
    id && dispatch(getVehicleLinksThunk({ id: +id, filters }));
    // eslint-disable-next-line
  }, [filters, id]);

  return (
    <LinksTable>
      <VehicleLinksColumns totalCount={links.length || 0} />
      <LinksBody links={links} />
    </LinksTable>
  );
};

export default VehicleLinks;
