import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  GenericColumnType, GenericVoidFunctionType,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  setDashboardLoading, setFetching, setLoading,
} from '../../slices/coreSlice';
import { ordersAPI } from '../../../api/ordersApi';
import { FetchOrderCollectionsParamsType, GetOrdersResponseType, OrderDto } from '../../../orders/dashboard/types/ordersTypes';
import { setOrderCollections, setOrderColumnsPagination } from '../../slices/ordersSlice';
import { orderStatusTransitionCollectionsHelper } from '../../../orders/dashboard/utils/orderStatusTransitionCollectionsHelper';
import { RootState } from '../../store';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';

export const getOrderCollections = createAsyncThunk<
ResponseSingleResult<GetOrdersResponseType>,
{ params: FetchOrderCollectionsParamsType, signal?: AbortSignal }
>(
  'get/OrderCollections',
  async ({ params, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    dispatch(setOrderCollections(null));
    try {
      const response = await ordersAPI.fetchOrdersCollections(params, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderCollectionsNextPortion = createAsyncThunk<
ResponseSingleResult<GetOrdersResponseType>,
// eslint-disable-next-line
{ params: FetchOrderCollectionsParamsType, currentStatus: number, setNewPortion:(status: number, data: OrderDto[]) => void }>(
  'get/OrderCollectionsNextPortion',
async ({
  params, currentStatus, setNewPortion,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await ordersAPI.fetchOrdersCollections(params);
    const { orders } = getState() as RootState;
    const { ordersColumnsPagination } = orders;
    const resCollections = response.data.data.orderCollections;
    const list = resCollections?.find((el) => el.status === currentStatus)?.orders || [];
    setNewPortion(currentStatus, list);
    const newPagination = ordersColumnsPagination.map((el) => {
      if (el.status === currentStatus) {
        return {
          ...el, currentPage: params.page,
        };
      } else return el;
    });
    dispatch(setOrderColumnsPagination(newPagination));
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
},
);

export const putColumnVisibilityThunk = createAsyncThunk<
ResponseSingleResult,
{
  data: Array<GenericColumnType<Array<OrderDto>>> | null,
  closeModal: VoidFunctionType,
}
>('put/OrdersColumnVisibility', async ({ data, closeModal }, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const {
      core: { currentBusinessArea },
      coreStatuses: { orderStatus },
      orders: { filters },
    } = getState() as RootState;
    const newLayout = data?.map((l) => ({
      status: l.status,
      order: l.order,
      isVisible: !l.isHidden,
    })) || [];
    const response = await ordersAPI.changeOrdersColumnVisibility(newLayout);
    dispatch(setLoading(false));
    dispatch(setOrderCollections(data));
    currentBusinessArea && dispatch(getOrderCollections({
      params: {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        statuses: orderStatus.map((st) => st.value),
        page: 1,
      },
    }));
    closeModal();
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const putOrderStatusThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  statusTransition: number,
  sourceId: number,
  setColumns: VoidFunctionType,
  rejectionReason?: string,
  onClose?: VoidFunctionType,
  setColumnsLoading: GenericVoidFunctionType<number[]>,
}
>('put/OrdersStatus', async ({
  id, statusTransition, sourceId,
  setColumns, rejectionReason, onClose, setColumnsLoading,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setFetching(true));
  try {
    const { orders, core } = getState() as RootState;
    const { currentBusinessArea } = core;
    const { ordersColumnsPagination, orderCollections, filters } = orders;
    setColumnsLoading([statusTransition, sourceId]);
    const response = await ordersAPI.changeOrderStatus(id, statusTransition, rejectionReason);
    if (currentBusinessArea) {
      const newGetParams = {
        ...filters,
        businessArea: currentBusinessArea,
        applyPersonalFilter: true,
        page: 1,
        pageSize: 50,
        statuses: [statusTransition, sourceId],
      };
      const res = await ordersAPI.fetchOrdersCollections(newGetParams);
      const collections = res.data.data.orderCollections;
      if (orderCollections && collections?.length) {
        const { newPagination, newOrderCollections } = orderStatusTransitionCollectionsHelper(
          orderCollections,
          collections,
          ordersColumnsPagination,
          statusTransition,
          sourceId,
        );
        dispatch(setOrderCollections(newOrderCollections));
        dispatch(setOrderColumnsPagination(newPagination));
      }
    }
    setColumnsLoading([]);
    onClose && onClose();
    dispatch(setFetching(false));
    return response.data;
  } catch (err) {
    setColumnsLoading([]);
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    statusTransitionErrorHandle(errors, dispatch);
    setColumns();
    onClose && onClose();
    dispatch(setFetching(false));
    return rejectWithValue(error.response?.data);
  }
});
