import React from 'react';
import Chart from '../../../../../../core/components/chart/Chart';
import { useAppSelector } from '../../../../../../store/hooks';
import { numberOfConvertedVehiclesChartSelector, reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';

const NumberOfConvertedVehiclesChart: React.FC = () => {
  const { series, categories } = useAppSelector(numberOfConvertedVehiclesChartSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      series={series}
      height={550}
      options={{
        chart: {
          type: 'bar',
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: {
          categories,
        },
        yaxis: {
          title: {
            text: 'Number of converted vehicles',
          },
        },
        fill: {
          opacity: 1,
        },
      }}
    />
  );
};

export default NumberOfConvertedVehiclesChart;
