import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, infiniteScrollInitPaging, PagingType, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { SuppliersAPI } from '../../../api/suppliersApi';
import {
  CreateSupplierContactResponse,
  NewSupplierContactReqDataType,
  SupplierContactDto,
} from '../../../supplier/supplier-view-page/types/supplierViewPageTypes';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { getSupplierByIdThunk } from './suppliersViewPageThunks';
import { setSupplierContactsFilters } from '../../slices/supplierSlice';
import { RootState } from '../../store';

export const getSupplierContactsList = createAsyncThunk<ResponseResult<SupplierContactDto[]> | null,
{
  supplierId: number,
  filters: PagingType,
}
>(
  'get/SupplierContactsList',
  async ({ supplierId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSupplierContacts(supplierId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSupplierContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ supplierId: number, contactId: number, closeModal: VoidFunctionType }>(
  'delete/Supplier/Contact',
  async ({
    contactId, closeModal, supplierId,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier } = getState() as RootState;
      const { supplierContactsFilters } = supplier;
      const response = await SuppliersAPI.deleteSupplierContact(contactId);
      if (supplierContactsFilters.page > 1) {
        window.scrollTo(0, 0);
        dispatch(setSupplierContactsFilters(infiniteScrollInitPaging));
      }
      dispatch(getSupplierContactsList({ supplierId, filters: infiniteScrollInitPaging }));
      dispatch(getSupplierByIdThunk({ id: supplierId }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewSupplierContactThunk = createAsyncThunk<ResponseSingleResult<CreateSupplierContactResponse> | null,
{ data: NewSupplierContactReqDataType, closeModal: VoidFunctionType }>(
  'post/Supplier/NewContact',
  async ({
    data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier } = getState() as RootState;
      const { supplierContactsFilters } = supplier;
      const response = await SuppliersAPI.createNewSupplierContact(data);
      if (supplierContactsFilters.page > 1) {
        window.scrollTo(0, 0);
        dispatch(setSupplierContactsFilters(infiniteScrollInitPaging));
      }
      dispatch(getSupplierContactsList({ supplierId: data.supplierId, filters: infiniteScrollInitPaging }));
      dispatch(getSupplierByIdThunk({ id: data.supplierId }));
      closeModal();
      dispatch(setSuccessMessage({ message: `Contact ${data.firstName} ${data.lastName} was successfully created.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editSupplierContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ contactId: number, data: NewSupplierContactReqDataType, closeModal: VoidFunctionType }>(
  'edit/Supplier/Contact',
  async ({
    data, closeModal, contactId,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.editSupplierContact(contactId, data);
      const { supplier } = getState() as RootState;
      const { supplierContactsFilters } = supplier;
      if (supplierContactsFilters.page > 1) {
        window.scrollTo(0, 0);
        dispatch(setSupplierContactsFilters(infiniteScrollInitPaging));
      }
      dispatch(getSupplierContactsList({ supplierId: data.supplierId, filters: infiniteScrollInitPaging }));
      closeModal();
      dispatch(setSuccessMessage({ message: `Contact ${data.firstName} ${data.lastName} was successfully updated.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
