import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  outstandingOverduePurchasingReportFiltersSelector,
  outstandingOverduePurchasingReportRangesSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const usePurchasingOutstandingOverduePurchaseOrdersRange = () => {
  const filters = useAppSelector(outstandingOverduePurchasingReportFiltersSelector);
  const { costNet, costGross } = useAppSelector(outstandingOverduePurchasingReportRangesSelector);
  const [rangePatterns, setRangePatterns] = useState({
    costNet: { min: costNet.min, max: costNet.max },
    costGross: { min: costGross.min, max: costGross.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    costNet: filters?.costNet
      ? [filters.costNet.min.toString(), filters.costNet.max.toString()]
      : [rangePatterns.costNet.min.toString(), rangePatterns.costNet.max.toString()],
    costGross: filters?.costGross
      ? [filters.costGross.min.toString(), filters.costGross.max.toString()]
      : [rangePatterns.costGross.min.toString(), rangePatterns.costGross.max.toString()],
  });

  useEffect(() => {
    setRangePatterns({
      costNet: { min: costNet.min, max: costNet.max },
      costGross: { min: costGross.min, max: costGross.max },
    });
    setRange({
      costNet: [costNet.min.toString(), costNet.max.toString()],
      costGross: [costGross.min.toString(), costGross.max.toString()],
    });
  }, [costNet.min, costNet.max, costGross.min, costGross.max]);

  useEffect(() => {
    if (!filters?.costNet) {
      setRange((prevState) => (
        { ...prevState, costNet: [rangePatterns.costNet.min.toString(), rangePatterns.costNet.max.toString()] }));
    }
    // eslint-disable-next-line
    }, [filters?.costNet]);

  useEffect(() => {
    if (!filters?.costGross) {
      setRange((prevState) => (
        { ...prevState, costGross: [rangePatterns.costGross.min.toString(), rangePatterns.costGross.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters?.costGross]);

  return { range, setRange, rangePatterns };
};
