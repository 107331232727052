import React, { useCallback, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faList,
} from '@fortawesome/free-solid-svg-icons';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import Input from '../../../core/components/input/Input';
import Tr from '../../../core/components/table-components/Tr';
import { useAppSelector } from '../../../store/hooks';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { QuoteUnitsOfMeasureSelector } from '../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import PriceSourceTag from '../price-source-tag/PriceSourceTag';
import PrivateLineLink from '../private-line-link/PrivateLineLink';
import { routesPath } from '../../../core/enums/pathEnum';
import Tag from '../../../core/components/tag/Tag';
import { ICustomColumn, PartReqLineUIType, PartRequestLineDto } from '../../types/commonTypes';
import { partRequestLineValueRenderer } from '../../utils/partRequestLineValueRenderer';
import {
  allocatedPartReqLinesStatusesSelector,
  partReqLineActionsStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import { currentUserPermissionsSelector } from '../../../store/selectors/accountSelectors';
import Dropdown from '../../../core/components/dropdown/Dropdown';

type PartReqChildLineProps = {
  childLine: Omit<PartReqLineUIType, 'childLines'>,
  columns: ICustomColumn[],
  editQty: (id: number, isChildLine?: boolean) => void,
  isLineActionDisabled: (v: number, isChildLine?: boolean) => boolean,
  permissionToManageLines: PermissionEnum,
  permissionToManageCharge: PermissionEnum,
  editThunkHandle: (
    data: {[p: string]: string | number} | {notes: string},
    lineId: number,
    setFieldToEdit: VoidFunctionType,
  ) => void,
  initLinesList: PartRequestLineDto[],
  openReadyCollectedListModal?: NumberFunctionType,
}

const PartReqChildLine: React.FC<PartReqChildLineProps> = ({
  childLine, columns, editQty, isLineActionDisabled, initLinesList,
  permissionToManageCharge, permissionToManageLines, editThunkHandle,
  openReadyCollectedListModal,
}) => {
  const hiddenCells = [
    'id', 'isProductSuitable', 'rules', 'partId',
    'productWeight', 'partsKitId', 'unitOfMeasure',
    'partsKitComponentId', 'isFree', 'childLines',
    'partsKitComponentPartId',
  ];
  const allowedToManageLines = usePermission(permissionToManageLines);
  const allowedToManageCharge = usePermission(permissionToManageCharge);
  const anyAllowed = allowedToManageLines || allowedToManageCharge;
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const allocatedPartReqLinesStatuses = useAppSelector(allocatedPartReqLinesStatusesSelector);
  const currentUserPermissions = useAppSelector(currentUserPermissionsSelector);

  const {
    partiallyReady, ready, partiallyCollected, collected, archived,
  } = useAppSelector(partReqLineActionsStatusesSelector);
  const readyCollectedActionStatuses = [
    partiallyReady?.value, ready?.value,
    partiallyCollected?.value, collected?.value, archived?.value,
  ];

  const [editMode, setEditMode] = useState(false);
  const [inputV, setInputV] = useState('');
  const [error, setError] = useState(false);

  const measureResult = useCallback(
    (value: number) => unitsOfMeasure.find((unit) => unit.value === value)?.label,
    [unitsOfMeasure],
  );

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip
      value={value}
      table
    />;
  };

  const cellRenderHandle = (key: string, value: unknown) => {
    if (key === 'quantity') {
      return `${childLine.quantity} ${measureResult(childLine.unitOfMeasure)}`;
    }
    if (key === 'notes') {
      return renderTruncateTooltip(value as string | null);
    }
    if (key === 'priceSources') {
      return <div className="priceTags">
        {typeof value === 'string'
          ? value as string
          : (value as number[]).map((el: number) => <PriceSourceTag source={el} />)}
      </div>;
    }
    if ((key === 'tax' && childLine.partsKitId) || (key === 'tax' && childLine.tax === null)) {
      return '-';
    }
    if ((key === 'productName' || key === 'productNumber')) {
      if (childLine.partsKitComponentPartId) {
        return <PrivateLineLink
          to={`/${routesPath.STOCK}/part/${childLine.partsKitComponentPartId}`}
          permission={PermissionEnum.StockPartViewPage}
        >
          {renderTruncateTooltip(key === 'productName' ? childLine.productName : childLine.productNumber)}
        </PrivateLineLink>;
      } else {
        return renderTruncateTooltip(value as string | null);
      }
    }
    if (key === 'status') {
      return <Tag label={value as string} />;
    } else {
      return partRequestLineValueRenderer(key, value as string | number, columns);
    }
  };
  const allowedToOpenSourceModal = ['quantity', 'unitPrice'];

  const isPriceAndQtyStatusDisabled = (key: string) => {
    return allowedToOpenSourceModal.includes(key)
            && childLine.partsKitComponentPartId
            && allocatedPartReqLinesStatuses.find((el) => el.label.toUpperCase() === childLine.status);
  };

  const allowedItems = (id: number) => {
    const children = initLinesList.map((el) => el.childLines || []).flat();
    const currentLine = children?.find((el) => el.id === id);
    const currentLineStatus = currentLine?.status;
    const isReadyCollectedActionAllowed = readyCollectedActionStatuses.includes(currentLineStatus);
    const actionsList = isReadyCollectedActionAllowed
      ? [
        {
          label: 'Ready/collected part list',
          key: 'ready-collected-parts-list',
          icon: <FontAwesomeIcon icon={faList} />,
          onClick: () => openReadyCollectedListModal && openReadyCollectedListModal(id),
          restrictedTo: permissionToManageLines,
          disabled: false,
        },
      ]
      : [];
    return actionsList.filter((action) => currentUserPermissions?.find((el) => el === action.restrictedTo));
  };
  return (
    <Tr
      className={classNames({ 'suitableLine': childLine.isProductSuitable !== null && !childLine.isProductSuitable })}
    >
      {allowedToManageLines && <TdNew />}
      {Object.entries(childLine).map(([key, value]) => {
        if (hiddenCells.includes(key)) return null;
        return <TdNew key={key}>
          {editMode && key === 'notes'
            ? <Input
              value={value === '-' ? '' : inputV}
              autoFocus
              onChange={(e) => {
                const val = e.target.value;
                if (val.length > 100) {
                  setError(true);
                } else {
                  error && setError(false);
                }
                setInputV(e.target.value);
              }}
              onBlur={() => {
                if (!error) {
                  if (inputV !== childLine.notes) {
                    editThunkHandle({ notes: inputV.trim() }, childLine.id, () => setEditMode(false));
                  } else {
                    setError(false);
                    setEditMode(false);
                  }
                } else {
                  setError(false);
                  setEditMode(false);
                }
              }}
              className={classNames('table-input', { 'errorTableInput': error })}
            />
            : <div
              className={classNames(
                'editSection',
                {
                  'editSection--disabled': !allowedToManageLines
                      || isPriceAndQtyStatusDisabled(key)
                      || isLineActionDisabled(childLine.id, true),
                  'table-clickable-content': allowedToOpenSourceModal.includes(key) && childLine.partsKitComponentPartId,
                  'editSection--active': key === 'notes',
                },
              )}
              onClick={() => {
                if (allowedToManageLines && !isLineActionDisabled(childLine.id, true)) {
                  if (allowedToOpenSourceModal.includes(key) && childLine.partsKitComponentPartId && !isPriceAndQtyStatusDisabled(key)) {
                    editQty(childLine.id, true);
                  }
                  if (key === 'notes') {
                    setEditMode(true);
                    setInputV(childLine.notes || '');
                  }
                }
              }}
            >
              {cellRenderHandle(key, value)}
            </div>}
          <span>
            {(key === 'unitPrice' || key === 'netPrice') && childLine.isFree && 'Free'}
          </span>
        </TdNew>;
      })}
      {anyAllowed && allowedItems(childLine.id).length
        ? <TdNew action>
          <Dropdown
            icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
            menuItems={allowedItems(childLine.id)}
          />
        </TdNew>
        : <TdNew />}
    </Tr>
  );
};

export default PartReqChildLine;
