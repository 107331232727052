import React from 'react';
import KitComponentsTable from './KitComponentsTable';
import KitComponentsSummary from './KitComponentsSummary';
import { NumberFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';

type PartsKitComponentsContentProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkRow: NumberFunctionType,
  checkedKeys: number[],
  setIsDelete: NumberFunctionType,
  openCustomEdit: NumberFunctionType,
}

const PartsKitComponentsContent: React.FC<PartsKitComponentsContentProps> = ({
  isAll,
  isIndeterminate,
  checkAll,
  checkRow,
  checkedKeys,
  setIsDelete,
  openCustomEdit,
}) => {
  return (
    <>
      <KitComponentsTable
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAll}
        checkRow={checkRow}
        checkedKeys={checkedKeys}
        setIsDelete={(id: number) => setIsDelete(id)}
        openCustomEdit={openCustomEdit}
      />
      <KitComponentsSummary />
    </>
  );
};

export default PartsKitComponentsContent;
