import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Input from '../input/Input';
import SelectableDropdown from '../selectable-dropdown/SelectableDropdown';
import {
  DateRangeTableFilterType, KeywordFilterDatePickerType, KeywordFilterTreeType,
  KeywordFilterType, SearchTableFilterType, StringFunctionType, VoidFunctionType,
} from '../../types/coreTypes';
import { responsiveEndpointsEnum } from '../../enums/responsiveEndpointsEnum';
import Button from '../button/Button';
import { useScreenWitdh } from '../../hooks/useScreenWidth';
import './KeywordFiltersSection.scss';
import { useAppSelector } from '../../../store/hooks';
import { collapsedSidebarSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import SelectableDatepicker from '../selectable-datepicker/SelectableDatepicker';
import SelectableTree from '../selectable-tree/SelectableTree';

type KeywordFiltersSectionProps = {
  setSearchValue?: StringFunctionType,
  filters?: Array<KeywordFilterType>,
  initDropdownSearch?: SearchTableFilterType,
  getFilteredResHandle?: (values: number[], field: string) => void,
  treeFilters?: KeywordFilterTreeType,
  treeChangeHandle?: (values: React.Key[]) => void,
  dateFilters?: KeywordFilterDatePickerType[],
  pickerValue?: DateRangeTableFilterType,
  setPickerValue?: (value: DateRangeTableFilterType) => void,
  pickerHandle?: (field: string, values?: {min: string | null, max: string | null}) => void,
  onClear: VoidFunctionType,
  placeholder?: string,
  isSearch?: boolean,
  searchValue?: string,
  onPressEnter?: VoidFunctionType,
  maxLength?: number,
  className?: string,
  dropdownSearchValue?: SearchTableFilterType,
  setDropdownSearchValue?: (value: SearchTableFilterType) => void,
  isFullWidth?: boolean,
  disableMoveSearchToTop?: boolean,
  conditionToClean?: boolean,
  clearSearchValue?: boolean,
  offsetBottom?: boolean,
}

const KeywordFiltersSection: React.FC<KeywordFiltersSectionProps> = ({
  searchValue,
  setSearchValue,
  onPressEnter,
  onClear,
  filters,
  initDropdownSearch,
  getFilteredResHandle,
  dateFilters,
  treeFilters,
  treeChangeHandle,
  pickerValue,
  setPickerValue,
  pickerHandle,
  placeholder = 'Enter a keyword...',
  maxLength = 250,
  isSearch = true,
  className,
  dropdownSearchValue,
  setDropdownSearchValue,
  isFullWidth,
  disableMoveSearchToTop,
  conditionToClean,
  clearSearchValue = true,
  offsetBottom,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const collapsedSidebar = useAppSelector(collapsedSidebarSelector);
  const { windowWidth } = useScreenWitdh();
  const [searchV, setSearchV] = useState<SearchTableFilterType | undefined>(initDropdownSearch);
  const isAnyFilterSelected = useMemo(() => {
    return (
      (filters?.some((filter) => filter.value && filter.value.length > 0))
      || (dateFilters?.some((filter) => filter.value?.min || filter.value?.max)));
  }, [filters, dateFilters]);

  useEffect(() => {
    conditionToClean && setSearchV(initDropdownSearch);
    // eslint-disable-next-line
  }, [conditionToClean]);

  const hasFilters = filters && filters.length > 0;
  const hasDateFilters = dateFilters && dateFilters.length > 0;
  const hasAnyFilter = hasFilters || hasDateFilters;

  return (
    <div className={classNames(className, 'keywordFiltersSection', {
      'keywordFiltersSection--search': isSearch,
      'keywordFiltersSection--no-filters': !hasAnyFilter,
      'keywordFiltersSection--full-width': isFullWidth,
      'keywordFiltersSection--disable-move-search': disableMoveSearchToTop,
      'keywordFiltersSection--offset-bottom': offsetBottom,
      'expanded-sidebar': !collapsedSidebar && windowWidth > 1500 && windowWidth < 1700,
    })}
    >
      {isSearch && <Input
        value={searchValue || ''}
        onChange={(e) => {
          const val = e.target.value;
          if (val.length <= maxLength) {
            setSearchValue && setSearchValue(val);
          }
        }}
        onPressEnter={onPressEnter}
        placeholder={placeholder}
        maxLength={maxLength}
        disabled={isGlobalLoading}
        className="keywordFiltersSection__search"
      />}
      {hasAnyFilter && (
        <div
          className="keywordFiltersSection__filters"
          style={{ '--filters-count': (filters?.length || 0) + (dateFilters?.length || 0) } as React.CSSProperties}
        >
          {filters?.map((filter, i) => <SelectableDropdown
            key={i}
            fieldName={filter.name}
            value={filter.value || []}
            onChange={(checkedValues) => getFilteredResHandle && getFilteredResHandle(checkedValues as number[], filter.name)}
            label={filter.label}
            options={filter.options}
            noSearch={filter.noSearch}
            noClear={filter.noClear}
            className={!isSearch ? 'withoutSearchDropdown' : ''}
            searchV={dropdownSearchValue || searchV}
            setSearchV={setDropdownSearchValue || setSearchV}
          />)}
          {treeFilters && <SelectableTree
            treeData={treeFilters.options}
            label={treeFilters.label}
            value={treeFilters.value}
            onChange={(checkedKeys) => {
              treeChangeHandle && treeChangeHandle(checkedKeys);
            }}
          />}
          {dateFilters?.map((filter) => (
            <SelectableDatepicker
              key={filter.name}
              fieldName={filter.name}
              label={filter.label}
              value={filter.value}
              pickerValue={pickerValue}
              setPickerValue={setPickerValue}
              pickerHandle={pickerHandle}
              disabledDate={filter.disabledDate}
            />
          ))}
        </div>
      )}
      <div className={classNames('keywordFiltersSection__clear', { 'keywordFiltersSection__clear--visible': isAnyFilterSelected })}>
        <Button
          onClick={() => {
            onClear();
            setSearchV(initDropdownSearch);
            clearSearchValue && setSearchValue && setSearchValue('');
          }}
          icon={<FontAwesomeIcon icon={faTimes} />}
          className="keywordFiltersSection__clear-btn"
          label={windowWidth && windowWidth <= responsiveEndpointsEnum.LG ? 'Clear all' : ''}
        />
      </div>
    </div>
  );
};

export default KeywordFiltersSection;
