import React from 'react';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Tr from '../../../core/components/table-components/Tr';
import Button from '../../../core/components/button/Button';
import Tfoot from '../../../core/components/table-components/Tfoot';
import { useAppSelector } from '../../../store/hooks';
import { skillsColumnsSelector } from '../../../store/selectors/employeesSelector';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

type SkillsTableFooterProps = {
  checkedKeys: number[],
  addSkill: VoidFunctionType,
  openDeleteModal: (categoryId?: number, skillId?: number) => void,
}

const SkillsTableFooter: React.FC<SkillsTableFooterProps> = ({ checkedKeys, addSkill, openDeleteModal }) => {
  const skillsColumns = useAppSelector(skillsColumnsSelector);
  const allowedToManage = usePermission(PermissionEnum.EmployeeSkillsListPage);

  const addRow = () => {
    addSkill();
  };

  const deleteSelected = () => {
    openDeleteModal();
  };
  const footerActions = [
    {
      label: 'Add skill item',
      icon: faPlus,
      onClick: () => addRow(),
      disabled: !allowedToManage,
    },
    {
      label: `Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`,
      onClick: () => deleteSelected(),
      disabled: checkedKeys.length === 0 || !allowedToManage,
    },
  ];
  return (
    <Tfoot>
      <Tr>
        <TdNew colSpan={allowedToManage ? skillsColumns.length + 2 : skillsColumns.length}>
          {footerActions.map((a) => <Button
            key={a.label}
            label={a.label}
            icon={a.icon && <FontAwesomeIcon icon={a.icon} />}
            onClick={a.onClick}
            disabled={a.disabled}
            type="text"
          />)}
        </TdNew>
      </Tr>
    </Tfoot>
  );
};

export default SkillsTableFooter;
