import React from 'react';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import SupplierForm from './components/SupplierForm';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';

const CreateSupplierContainer:React.FC = () => {
  const { backHandle } = useLocationHistoryState();

  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new supplier</div>
    </DetailsHead>}
    >
      <SupplierForm />
    </Layout>
  );
};

export default CreateSupplierContainer;
