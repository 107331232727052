import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachmentsFilters,
  VoidFunctionType,
  initialCoreAttachmentsFilters,
  ResponseSingleResult,
  CoreAttachListElType,
  PostAttachReqWithType,
  initEntityImageData, AxiosErrorResponse, GetAttachmentsResponse, emptyPaging,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import { RootState } from '../../store';
import { setEmployeeAttachments, setEmployeeAttachmentsFilter, setEmployeeImageData } from '../../slices/employeesSlice';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { getEmployeeByIdThunk } from './employeeViewPageThunk';

export const getEmployeeAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{
  employeeId: number,
  filters: CoreAttachmentsFilters,
}>(
  'get/EmployeeAttachments',
  async ({ employeeId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await employeeAPI.fetchEmployeeAttachments(employeeId, filters);
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  employeeId: number,
  closeModal: VoidFunctionType,
  isEmployeeImage?: boolean,
}>(
  'delete/SingleEmployeeAttachment',
  async ({
    id, employeeId, closeModal, isEmployeeImage,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.deleteEmployeeAttachment(employeeId, id);
      const { employees: { employeeAttachmentsFilters } } = getState() as RootState;
      dispatch(setLoading(false));
      if (isEmployeeImage) {
        dispatch(setEmployeeImageData(initEntityImageData));
      } else {
        if (employeeAttachmentsFilters.page === 1) {
          dispatch(getEmployeeAttachmentsThunk({
            employeeId,
            filters: initialCoreAttachmentsFilters,
          }));
        } else {
          dispatch(setEmployeeAttachmentsFilter(initialCoreAttachmentsFilters));
        }
      }
      dispatch(getEmployeeByIdThunk({ employeeId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeAttachmentByIdThunk = createAsyncThunk<ResponseSingleResult<{ name: string, uri: string }>,
{
  id: number,
  employeeId: number,
  isEmployeeImage?: boolean,
}>(
  'get/EmployeeAttachmentById',
  async ({
    id, employeeId, isEmployeeImage,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.getEmployeeAttachmentById(employeeId, id);
      const { uri } = response.data.data;
      if (isEmployeeImage) {
        dispatch(setEmployeeImageData({ uri }));
      } else {
        downloadCsv(uri);
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEmployeeAttachmentThunk = createAsyncThunk<ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  employeeId: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}>(
  'post/EmployeeAttachment',
  async ({
    employeeId, list, comment, onCancel,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
      const res = await employeeAPI.postEmployeeAttachmentUploadUri(employeeId, uploadUriArr);
      const attachments = await uploadAttachmentsAsyncHandle(
        list,
        comment,
        res,
        dispatch,
      );
      const finalRes = await employeeAPI.postEmployeeAttachments(employeeId, attachments);
      const { employees: { employeeAttachmentsFilters } } = getState() as RootState;
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      dispatch(getEmployeeAttachmentsThunk({ employeeId, filters: employeeAttachmentsFilters }));
      dispatch(getEmployeeByIdThunk({ employeeId }));
      onCancel();
      dispatch(setLoading(false));
      return finalRes.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEmployeeAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  employeeId: number,
  comment: string,
  onCancel: VoidFunctionType,
}>(
  'patch/EmployeeAttachment',
  async ({
    id, employeeId, comment, onCancel,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.editEmployeeAttachment(employeeId, id, comment);
      const { employees: { employeeAttachments } } = getState() as RootState;
      const editedAttachments = employeeAttachments.items.map((item) => {
        if (item.id === id) return { ...item, comment };
        return item;
      });
      dispatch(setEmployeeAttachments(editedAttachments));
      onCancel();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteMultipleEmployeeAttachmentsThunk = createAsyncThunk<null,
{
  ids: number[],
  employeeId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleEmployeeAttachments',
  async ({
    ids, employeeId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { employees: { employeeAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await employeeAPI.fetchEmployeeAttachments(employeeId, {
        ...employeeAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await employeeAPI.deleteMultipleEmployeeAttachment(employeeId, filteredIds);
      }
      dispatch(setEmployeeAttachmentsFilter({ ...employeeAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getEmployeeByIdThunk({ employeeId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addEmployeeImageThunk = createAsyncThunk<ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  employeeId: number,
  image: CoreAttachListElType,
  deletePreviousImage: boolean,
}>(
  'post/EmployeeImage',
  async ({
    employeeId, image, deletePreviousImage,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { employees: { employeeImageData } } = getState() as RootState;
      if (deletePreviousImage && employeeImageData.id) {
        await employeeAPI.deleteEmployeeAttachment(employeeId, employeeImageData.id);
      }
      const uploadUri = { fileName: image.fileName, uploadGuid: image.uploadGuid };
      const res = await employeeAPI.postEmployeeAttachmentUploadUri(employeeId, [uploadUri]);
      const uploadedImages = await uploadAttachmentsAsyncHandle(
        [image],
        '',
        res,
        dispatch,
      );
      const finalRes = await employeeAPI.postEmployeePhoto(employeeId, uploadedImages[0] as PostAttachReqWithType);
      const employeeImageId = finalRes.data.data.createdItems[0].id;
      dispatch(getEmployeeAttachmentByIdThunk({
        id: employeeImageId, employeeId, isEmployeeImage: true,
      }));
      dispatch(setEmployeeImageData({ id: employeeImageId }));
      dispatch(setSuccessMessage({ message: 'The image was successfully added' }));
      dispatch(setLoading(false));
      return finalRes.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
