import React, { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import RootRoutes from './routes/routes';
import { useDictionaries } from './core/hooks/useDictionaries';
import { useOnClickOutside } from './core/hooks/useOutsideClick';

const AppWrapper: React.FC = () => {
  const { pathname } = useLocation();
  const [params] = useSearchParams();
  const page = params.get('page');
  const mode = params.get('mode');
  const toastRef = useRef(null);
  useOnClickOutside(toastRef, () => toast.dismiss());
  useDictionaries();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname, page, mode]);

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        pauseOnHover
        draggable={false}
        theme="light"
        onClick={(e) => {
          e.stopPropagation();
        }}
        containerId="toast-wrap"
        ref={toastRef}
      />
      <RootRoutes />
    </>
  );
};

export default AppWrapper;
