import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { NumberOfConvertedVehiclesTableLine } from '../../../../../types/NumberOfConvertedVehiclesReportTypes';

type NumberOfConvertedVehiclesBodyProps = {
  data: NumberOfConvertedVehiclesTableLine[],
  columnsLength: number,
}

const NumberOfConvertedVehiclesBody: React.FC<NumberOfConvertedVehiclesBodyProps> = ({ data, columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <Tbody>
      {data.length > 0 ? data.map((el) => (
        <Tr key={el.number}>
          {Object.entries(el).map(([key, value]) => <TdNew key={key}>
            {value}
          </TdNew>)}
        </Tr>
      ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength} />}
    </Tbody>
  );
};

export default NumberOfConvertedVehiclesBody;
