import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  salesOffsiteWorkingFiltersSelector,
  salesOffsiteWorkingRangesSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useSalesOffsiteWorkingRanges = () => {
  const filters = useAppSelector(salesOffsiteWorkingFiltersSelector);
  const { distanceFromOfficeMiles } = useAppSelector(salesOffsiteWorkingRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    distanceFromOfficeMiles: { min: distanceFromOfficeMiles.min, max: distanceFromOfficeMiles.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    distanceFromOfficeMiles: filters.distanceFromOfficeMiles
      ? [filters.distanceFromOfficeMiles.min.toString(), filters.distanceFromOfficeMiles.max.toString()]
      : [rangePatterns.distanceFromOfficeMiles.min.toString(), rangePatterns.distanceFromOfficeMiles.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, distanceFromOfficeMiles: { min: distanceFromOfficeMiles.min, max: distanceFromOfficeMiles.max } }));
    setRange((prevState) => (
      { ...prevState, distanceFromOfficeMiles: [distanceFromOfficeMiles.min.toString(), distanceFromOfficeMiles.max.toString()] }));
    // eslint-disable-next-line
  }, [distanceFromOfficeMiles.min, distanceFromOfficeMiles.max]);

  useEffect(() => {
    if (!filters?.distanceFromOfficeMiles) {
      setRange((prevState) => (
        { ...prevState, distanceFromOfficeMiles: [distanceFromOfficeMiles.min.toString(), distanceFromOfficeMiles.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.distanceFromOfficeMiles]);

  return { range, setRange, rangePatterns };
};
