import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Restricted from '../../permissions-handling/Restricted';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import Button from '../../core/components/button/Button';
import Pagination from '../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { BooleanFunctionType } from '../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { formsTableFiltersSelector } from '../../store/selectors/formsSelectors';
import { setFormsTableFilters } from '../../store/slices/formsSlice';

type FormsActionsProps = {
  checkedKeys: number[],
  setIsAll: BooleanFunctionType,
  setCheckedKeys: (v: number[]) => void,
  setCreateModal: BooleanFunctionType,
  setDeleteModal: BooleanFunctionType,
  setMoveToFolder: BooleanFunctionType,
}

const FormsActions: React.FC<FormsActionsProps> = ({
  checkedKeys, setCheckedKeys, setIsAll, setCreateModal, setDeleteModal, setMoveToFolder,
}) => {
  const dispatch = useAppDispatch();
  const formsTableFilters = useAppSelector(formsTableFiltersSelector);
  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Restricted to={PermissionEnum.TestModule}>
          <Button
            label="Upload file"
            onClick={() => setCreateModal(true)}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            designType="dark"
            reversed
            // disabled={enquiryAcceptedOrRejected}
          />
        </Restricted>
        <Restricted to={PermissionEnum.TestModule}>
          <Button
            label="Delete selected"
            onClick={() => setDeleteModal(true)}
            disabled={checkedKeys.length === 0}
            // disabled={checkedKeys.length === 0 || enquiryAcceptedOrRejected}
          />
        </Restricted>
        <Restricted to={PermissionEnum.TestModule}>
          <Button
            label="Change folder"
            onClick={() => setMoveToFolder(true)}
            disabled={checkedKeys.length === 0}
            // disabled={checkedKeys.length === 0 || enquiryAcceptedOrRejected}
          />
        </Restricted>
      </div>
      <Pagination
        currentPage={formsTableFilters.page}
        pageSize={formsTableFilters.pageSize}
        onChange={(page, pageSize) => {
          setIsAll(false);
          setCheckedKeys([]);
          dispatch(setFormsTableFilters({ ...formsTableFilters, page, pageSize }));
        }}
        totalItems={0}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        narrow
        className="actions-and-paging__paging"
      />
    </div>
  );
};

export default FormsActions;
