import React from 'react';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { inactivatePricelistThunk } from '../../../../store/thunks/pricelist/pricelistDetailsThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';

type SimpleInactivationModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  name: string,
  id?: number,
}

const SimpleInactivationModal: React.FC<SimpleInactivationModalProps> = ({
  isVisible, onCancel, name, id,
}) => {
  const dispatch = useAppDispatch();
  return (
    <ConfirmationModal
      isVisible={isVisible}
      onCancel={onCancel}
      customTitle="Inactivate pricelist"
      customCreateLabel="Yes, inactivate"
      confirmHandler={() => id && dispatch(inactivatePricelistThunk({
        id,
        closeModal: () => onCancel(),
      }))}
      customText={<>
        You are about to inactivate
        {' '}
        <b>{`"${name}"`}</b>
        {' '}
        pricelist.
      </>}
    />
  );
};

export default SimpleInactivationModal;
