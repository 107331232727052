import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import Tr from '../../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { ExtendedPricePart } from '../../../../types/pricelistViewPageTypes';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';

type EditPartBodyProps = {
  pricesCopy: ExtendedPricePart[],
  deleteDraftPrice: NumberFunctionType,
}

const EditPartBody: React.FC<EditPartBodyProps> = ({ pricesCopy, deleteDraftPrice }) => {
  const valueHandle = (value: string | number | null, key: string) => {
    const datesKeys = ['effectiveStartDate', 'effectiveEndDate'];
    const costKeys = ['costPrice', 'sellPrice'];
    if (value !== null) {
      if (datesKeys.includes(key)) {
        return localDateFormatHandler('DD/MM/YYYY', value as string);
      }
      if (costKeys.includes(key)) {
        return `£ ${value || '0.00'}`;
      } else return value;
    } else return '∞';
  };
  const hiddenCells = ['id', 'isDraft'];
  return (
    <Tbody>
      {pricesCopy.length === 0
        ? <EmptyTableSection text="No records" colSpan={6} />
        : <>
          {pricesCopy.map((item, i) => <Tr key={i}>
            <TdNew className="editPartPriceTh--number">{i + 1}</TdNew>
            {Object.entries(item).map(([key, value]) => {
              if (hiddenCells.includes(key)) return null;
              return <TdNew
                key={key}
                className="nowrap"
              >
                {valueHandle(value, key)}
              </TdNew>;
            })}
            <TdNew action>
              <Dropdown
                menuItems={[
                  {
                    label: 'Delete price',
                    icon: <FontAwesomeIcon icon={faTrashAlt} />,
                    key: 'deletePrice',
                    onClick: () => deleteDraftPrice(item.id),
                  },
                ]}
                icon={<FontAwesomeIcon icon={faEllipsisV} />}
                disabled={pricesCopy.length === 1}
              />
            </TdNew>
          </Tr>)}
        </>}
    </Tbody>
  );
};

export default EditPartBody;
