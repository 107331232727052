import React from 'react';
import { DownOutlined } from '@ant-design/icons';
import {
  Dropdown as DropdownComponent, Menu, MenuProps, Space,
} from 'antd';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import './Dropdown.scss';

type DropdownProps = {
  menuItems: Array<ItemType>;
  label?: string;
  icon?: React.ReactNode;
  loading?: boolean;
  trigger?: Array<'click' | 'hover' | 'contextMenu'>;
  disabled?: boolean;
  placement?: 'bottom' | 'bottomLeft' | 'bottomRight' | 'top' | 'topLeft' | 'topRight';
  clickHandle?: (key: React.Key) => void;
  className?: string;
  overlayClassName?: string;
  getPopupContainer?: (triggerNode: HTMLElement) => HTMLElement;
  parentRender?: boolean;
}

const Dropdown: React.FC<DropdownProps> = ({
  menuItems,
  label,
  icon = <DownOutlined />,
  loading,
  trigger = ['click'],
  disabled,
  placement = 'bottomLeft',
  clickHandle,
  className,
  overlayClassName,
  getPopupContainer,
  parentRender,
}) => {
  const onClick: MenuProps['onClick'] = ({ key }) => {
    clickHandle && clickHandle(key);
  };

  return (
    <DropdownComponent
      overlay={(
        <Menu
          items={menuItems}
          onClick={onClick}
          disabled={loading}
        />
      )}
      trigger={trigger}
      disabled={disabled}
      placement={placement}
      className={className}
      overlayClassName={overlayClassName}
      getPopupContainer={parentRender ? (trigger) => trigger.parentElement as HTMLElement : getPopupContainer}
    >
      <button type="button" onClick={(e) => e.preventDefault()} className="dropdownTriggerBtn">
        <Space>
          {label}
          {icon}
        </Space>
      </button>
    </DropdownComponent>
  );
};

export default Dropdown;
