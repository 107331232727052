import React, { useEffect } from 'react';
import { useParams, useSearchParams } from 'react-router-dom';
import { getJobByIdThunk } from '../../store/thunks/jobs/jobThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import JobViewPageContainer from './JobViewPageContainer';
import Spinner from '../../core/components/spinner/Spinner';
import ErrorPage from '../../common/components/error-pages/ErrorPage';
import { jobDetailsSelector } from '../../store/selectors/jobsSelector';
import { allowedBaForUserSelector } from '../../store/selectors/coreSelectors';

const JobViewWrapper: React.FC = () => {
  const { id } = useParams();
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const jobDetails = useAppSelector(jobDetailsSelector);
  const allowedBa = useAppSelector(allowedBaForUserSelector);

  useEffect(() => {
    id && dispatch(getJobByIdThunk({ id: +id }));
    // eslint-disable-next-line
  }, [id, params]);

  if (!jobDetails) {
    return <Spinner />;
  }
  const pageIsNotAllowed = jobDetails.job.id !== jobDetails.job.parentJobId
      || !allowedBa.find((el) => el.value === jobDetails.job.businessArea);

  if (pageIsNotAllowed) {
    return <ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />;
  }
  return <JobViewPageContainer />;
};

export default JobViewWrapper;
