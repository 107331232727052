import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { setUserEntityTimeline, setUserEntityTimelineFilters } from '../../../../../store/slices/settingsSlice';
import Spinner from '../../../../../core/components/spinner/Spinner';
import Timeline from '../../../../../core/components/timeline/Timeline';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { userEntityTimelineFiltersSelector, userEntityTimelineSelector } from '../../../../../store/selectors/settingsSelectors';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import UserEntityEvent from '../user-event-description/UserEntityEvent';
import { getUserEntityTimelineThunk, postUserActivityNote } from '../../../../../store/thunks/settings/userViewPageThunks';
import { useCancelRequest } from '../../../../../core/hooks/useCancelRequest';
import { initExtendedTimelineFilters } from '../../../../../common/types/commonTypes';
import { initDataList } from '../../../../../core/types/coreTypes';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { userEntityEventTypesSelector } from '../../../../../store/selectors/coreEventTypesSelectors';
import { getUserEntityEventTypesThunk } from '../../../../../store/thunks/core/coreEventTypesThunks';

const UserEntityTimeline: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const userEntityEventTypes = useAppSelector(userEntityEventTypesSelector);
  const { items, totalCount } = useAppSelector(userEntityTimelineSelector);
  const filters = useAppSelector(userEntityTimelineFiltersSelector);

  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  const allowedToAddNote = usePermission(PermissionEnum.UserViewPage);

  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    cancelRequest();
    id && dispatch(getUserEntityTimelineThunk({
      userId: +id,
      filters,
      setIsLoading,
      signal: controller.current?.signal,
    }));
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (!userEntityEventTypes.length) {
      dispatch(getUserEntityEventTypesThunk());
    }

    return () => {
      dispatch(setUserEntityTimelineFilters(initExtendedTimelineFilters));
      dispatch(setUserEntityTimeline(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <UserEntityEvent item={el} />,
  }));

  const addNote = () => {
    id && dispatch(postUserActivityNote({
      userAccountId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  return (
    <>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page) => {
          dispatch(setUserEntityTimelineFilters({ ...filters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      {isLoading
        ? <Spinner global={false} />
        : <>
          <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
          <ActivityNoteInput
            value={value}
            setValue={setValue}
            addNote={addNote}
            disabledInput={!allowedToAddNote}
          />
        </>}
    </>
  );
};

export default UserEntityTimeline;
