import React from 'react';
import classNames from 'classnames';
import { purchaseOrderSummarySelector } from '../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppSelector } from '../../../../../../store/hooks';
import { toPoundCurrency } from '../../../../../../common/utils/formatUtils';

const PurchaseOrderPartsListSummary: React.FC = () => {
  const purchaseOrderSummary = useAppSelector(purchaseOrderSummarySelector);
  const mockData = [
    { label: 'Total lines', value: purchaseOrderSummary?.totalLinesCount || '-' },
    { label: 'Net', value: toPoundCurrency(purchaseOrderSummary?.totalNet) },
    { label: 'VAT', value: toPoundCurrency(purchaseOrderSummary?.totalVat) },
    { label: 'Gross', value: toPoundCurrency(purchaseOrderSummary?.totalGross) },
  ];
  return (
    <section className="summary-row">
      <h2 className="summary-row__title">Summary</h2>
      <div className="summary-row__sections">
        {mockData.map((el, i) => (
          <div key={i} className={classNames('summary-row__data', { 'summary-row__data--bold': el.label === 'Gross' })}>
            <span>{el.label}</span>
            <span>{el.value}</span>
          </div>
        ))}
      </div>
    </section>
  );
};

export default PurchaseOrderPartsListSummary;
