import React, { useEffect } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import RequisiteSection from '../../../common/components/requisite/RequisiteSection';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  customerAccountTypesSelector,
  customerNonCreditAccountTypeSelector,
  customerTypesSelector,
} from '../../../store/selectors/coreSelectors';
import { initRequisite, RequisiteFields } from '../../../common/utils/data';
import { createCustomerThunk } from '../../../store/thunks/customers/customerCreateThunks';
import { customerFormHandle } from '../utils/customerFormHandle';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import {
  AccountNumberSchema, CompanyRegNoSchema,
  CustomerFields,
  customerDefaults,
  NameSchema,
  VatNumberSchema,
} from '../utils/CustomerSchema';
import { YesNoOptions } from '../../../core/utils/testData';

type CustomerFormProps = {
  setIsVisible: BooleanFunctionType,
  setShippingMode: BooleanFunctionType,
  setIsSame: BooleanFunctionType,
  isSame: boolean,
  requisites: RequisiteFields,
  setRequisites: React.Dispatch<React.SetStateAction<RequisiteFields>>
}

const CustomerForm: React.FC<CustomerFormProps> = ({
  setIsVisible, setIsSame, setShippingMode, isSame, requisites, setRequisites,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const accountTypes = useAppSelector(customerAccountTypesSelector);
  const nonCreditAccountType = useAppSelector(customerNonCreditAccountTypeSelector);
  const customerTypes = useAppSelector(customerTypesSelector);
  const allowedToEditAccountType = usePermission(PermissionEnum.CustomerEditAccountType);
  const {
    control, handleSubmit, reset, setError, setValue, watch,
  } = useForm<CustomerFields>({
    defaultValues: customerDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const accountTypeValue = watch('accountType');

  useEffect(() => {
    const isInitCondition = !accountTypeValue && nonCreditAccountType;
    isInitCondition && setValue('accountType', nonCreditAccountType);
    // eslint-disable-next-line
  }, [nonCreditAccountType, accountTypeValue]);

  const onSubmit = (values: CustomerFields) => {
    const trimmedValues = {
      ...values,
      name: values.name.trim(),
      customerAccountNumber: values.customerAccountNumber.trim(),
      alwaysUseRequisites: values.alwaysUseRequisites === 1,
      accountType: allowedToEditAccountType ? values.accountType : null,
    };
    const { shipping, billing } = customerFormHandle(requisites);
    dispatch(createCustomerThunk({
      data: {
        ...trimmedValues,
        accountType: values.accountType as number,
        customerType: values.customerType as number,
        isShippingRequisiteSameAsBilling: isSame,
        billingDetails: billing,
        shippingDetails: isSame ? null : shipping,
      },
      reset,
      navigate,
      setError,
    }));
  };
  return (
    <form className="formWrap details-form create-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        rules={NameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v.trim()) {
                setValue('name', '');
                setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.name?.message}
            label="Customer name"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        control={control}
        name="customerType"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            label="Customer type"
            options={customerTypes}
            error={errors.customerType?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="customerAccountNumber"
        rules={AccountNumberSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => field.onChange(e.target.value.toUpperCase())}
            error={errors.customerAccountNumber?.message}
            label="Customer account No"
          />
        )}
      />
      <Controller
        control={control}
        name="vatNumber"
        rules={VatNumberSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value.toUpperCase());
            }}
            error={errors.vatNumber?.message}
            label="VAT number"
          />
        )}
      />
      <Controller
        control={control}
        name="companyRegistrationNumber"
        rules={CompanyRegNoSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => field.onChange(e.target.value.toUpperCase())}
            error={errors.companyRegistrationNumber?.message}
            label="Company registration number"
          />
        )}
      />
      <RequisiteSection
        title="Billing details"
        setIsVisible={() => setIsVisible(true)}
        requisiteDetails={requisites.billing || null}
      />
      <RequisiteSection
        title="Shipping details"
        setIsVisible={() => {
          if (!isSame) {
            setShippingMode(true);
            setIsVisible(true);
          }
        }}
        requisiteDetails={requisites.shipping || null}
        isShipping
        isSame={isSame}
        onCheck={(val) => {
          if (val) {
            setShippingMode(false);
            setIsVisible(false);
            setRequisites({ ...requisites, shipping: initRequisite });
          } else {
            setShippingMode(true);
            setIsVisible(true);
          }
          setIsSame(val);
        }}
      />
      <Controller
        control={control}
        name="alwaysUseRequisites"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={YesNoOptions}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
            label="Always use customer's billing/shipping details"
            error={errors.alwaysUseRequisites?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="accountType"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={accountTypes}
            value={field.value}
            onChange={(val) => {
              field.onChange(val);
            }}
            label="Account type"
            disabled={!allowedToEditAccountType}
            error={errors.accountType?.message}
          />
        )}
      />
      <ButtonActions
        createLabel="Create"
        cancelLabel="Clear"
        createType="submit"
        cancelClick={() => {
          reset();
          setRequisites({ billing: initRequisite, shipping: initRequisite });
          setIsSame(true);
        }}
        className="details-form__actions"
      />
    </form>
  );
};

export default CustomerForm;
