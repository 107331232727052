import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import {
  CustomAny,
  RangeTableFiltersType,
  SearchTableFilterType, VoidFunctionType,
} from '../../../../../../core/types/coreTypes';
import Tr from '../../../../../../core/components/table-components/Tr';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  kitComponentsColumnsSelector,
  partsKitComponentsFiltersSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import { setPartsKitComponentsFilters } from '../../../../../../store/slices/partsKitSlice';
import Thead from '../../../../../../core/components/table-components/Thead';
import { commonMinDecimal, maxCommonDecimal } from '../../../../../../core/utils/regex';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';

type KitComponentsColumnsProps = {
  totalElements: number,
  disabled: boolean,
  isAll: boolean;
  isIndeterminate: boolean,
  checkAll: VoidFunctionType;
}

const initRanges: CustomAny = {
  quantity: ['0', '999999999.99'],
  unitPrice: ['0', '999999999.99'],
  netPrice: ['0', '999999999.99'],
  weight: ['0', '99999.99'],
};

const rangePatterns = {
  quantity: { min: commonMinDecimal, max: maxCommonDecimal },
  unitPrice: { min: commonMinDecimal, max: maxCommonDecimal },
  netPrice: { min: commonMinDecimal, max: maxCommonDecimal },
  weight: { min: commonMinDecimal, max: maxCommonDecimal },
};

const KitComponentsColumns: React.FC<KitComponentsColumnsProps> = ({
  totalElements, disabled, isAll, isIndeterminate, checkAll,
}) => {
  const allowed = usePermission(PermissionEnum.StockPartsKitEditComponentFieldsAndUnlockComponentActions);
  const dispatch = useAppDispatch();
  const kitComponentsColumns = useAppSelector(kitComponentsColumnsSelector);
  const filters = useAppSelector(partsKitComponentsFiltersSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partNumber: filters?.partNumber,
    name: filters?.name,
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    quantity: filters?.quantity ? [filters.quantity?.min, filters.quantity?.max] : initRanges.quantity,
    unitPrice: filters?.unitPrice ? [filters.unitPrice?.min, filters.unitPrice?.max] : initRanges.unitPrice,
    netPrice: filters?.netPrice ? [filters.netPrice?.min, filters.netPrice?.max] : initRanges.netPrice,
    weight: filters?.weight ? [filters.weight?.min, filters.weight?.max] : initRanges.weight,
  });

  const filtersList = [
    {
      dataIndex: 'tax',
      value: filters?.taxes || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setPartsKitComponentsFilters({
        ...filters,
        taxes: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'categoryId',
      value: filters?.categoryIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setPartsKitComponentsFilters({
        ...filters,
        categoryIds: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    filters && dispatch(setPartsKitComponentsFilters({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    }));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setPartsKitComponentsFilters({
        ...filters,
        [field]: undefined,
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setPartsKitComponentsFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    dispatch(setPartsKitComponentsFilters({
      ...filters,
      [field]: { min: value[0], max: value[1] },
    }));
  };

  return (
    <Thead>
      <Tr>
        {allowed && (
          <ThNew checkbox>
            <Checkbox
              checked={isAll}
              indeterminate={isIndeterminate}
              onChange={checkAll}
              disabled={totalElements === 0 || disabled}
              table
            />
          </ThNew>
        )}
        {kitComponentsColumns.map((col, i) => <ThWithControls
          key={i}
          col={col}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          setRangeHandle={setRangeHandle}
          range={range}
          rangePatterns={rangePatterns}
          setRange={setRange}
          totalElements={totalElements}
          disabled={disabled || totalElements === 0}
          searchVl={searchVl}
          filters={filtersList}
          tableFilters={filters}
          className={`kitComponentsTh--${col.dataIndex}`}
        />)}
        {allowed && (
          <ThNew action><span className="sr-only">Actions</span></ThNew>
        )}
      </Tr>
    </Thead>
  );
};

export default KitComponentsColumns;
