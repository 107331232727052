import React from 'react';
import './DashboardCardSkeleton.scss';
import { DashboardSkeletonProps } from './DashboardSkeleton';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';

const DashboardCardSkeleton: React.FC<DashboardSkeletonProps> = ({
  hasPrice = true, linkedEntities = 3, hasTrackingInfo = false,
}) => {
  const canSeeTrackingInfo = usePermission(PermissionEnum.JobTimeTrackingSummary);

  return (
    <div className="dashboardCardSkeleton">
      <div className="dashboardCardSkeleton__header">
        <div className="dashboardCardSkeleton__label dashboardCardSkeleton__label--left skeleton-line" />
        <div className="dashboardCardSkeleton__label dashboardCardSkeleton__label--right skeleton-line" />
      </div>
      <div className="dashboardCardSkeleton__name skeleton-line" />
      <div className="dashboardCardSkeleton__info skeleton-line" />
      {hasPrice && <div className="dashboardCardSkeleton__price skeleton-line" />}
      {!!linkedEntities && (
        <div className="dashboardCardSkeleton__entities">
          {Array(linkedEntities).fill(null).map((_, i) => (
            <div className="dashboardCardSkeleton__linked skeleton-line" key={i} />
          ))}
        </div>
      )}
      {hasTrackingInfo && canSeeTrackingInfo && (
        <div className="dashboardCardSkeleton__tracking-wrap">
          {Array(3).fill(null).map((_, i) => (
            <div key={i} className="dashboardCardSkeleton__tracking-item">
              <div className="dashboardCardSkeleton__tracking-title skeleton-line" />
              <div className="dashboardCardSkeleton__tracking-value skeleton-line" />
            </div>
          ))}
        </div>
      )}
      <div className="dashboardCardSkeleton__footer">
        <div className="dashboardCardSkeleton__icon skeleton-line" />
        <div className="dashboardCardSkeleton__icon skeleton-line" />
      </div>
    </div>
  );
};

export default DashboardCardSkeleton;
