import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { useAppDispatch } from '../../../../../../store/hooks';
import { AttachmentsCellProps } from '../../../../../../core/types/coreTypes';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import {
  deleteSupplierAttachmentThunk,
  getSupplierAttachmentById,
} from '../../../../../../store/thunks/suppliers/suppliersAttachmentsThunks';
import AttachmentsRow from '../../../../../../common/components/attachments-table/AttachmentsRow';

const SupplierAttachmentCell:React.FC<AttachmentsCellProps> = ({
  attach,
  openEditModal,
  checked,
  checkRow,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isModal, setModal] = useState(false);
  const allowedManage = usePermission(PermissionEnum.SupplierEditFields);
  const actions = [
    {
      label: 'Edit comment',
      icon: <FontAwesomeIcon icon={faEdit} />,
      key: 'editComment',
      onClick: () => openEditModal(),
    },
    {
      label: 'Delete file',
      key: 'deleteFile',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setModal(true),
    },
  ];

  return (
    <>
      <ConfirmationModal
        isVisible={isModal}
        onCancel={() => setModal(false)}
        confirmHandler={() => id && dispatch(deleteSupplierAttachmentThunk({
          id: attach.id,
          supplierId: +id,
          closeModal: () => setModal(false),
        }))}
      />
      <AttachmentsRow
        allowedManage={!!allowedManage}
        attachment={attach}
        checked={checked}
        checkRows={checkRow}
        onClick={(attachId) => id && dispatch(getSupplierAttachmentById({
          id: attachId,
          supplierId: +id,
        }))}
        actions={actions}
      />
    </>
  );
};

export default SupplierAttachmentCell;
