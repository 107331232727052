import React from 'react';
import { editEnquiryQuotLineThunk } from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { StringFunctionType } from '../../../../../../core/types/coreTypes';
import {
  enquiryQuoteLinesCategoriesSelector,
  quoteLinesSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import EditableCell from '../../../../../../common/components/editable-cell/EditableCell';

type QuoteEditableCellContentProps = {
  initValue: string | number;
  fieldToEdit?: string;
  lineId: number;
  setFieldToEdit: StringFunctionType,
  rules: {
    notes: { maxLength: number, message: string },
    weight: { message: string, max: number },
    quantity: { message: string, max: number },
    discount: { message: string, max: number },
  },
  errors: { notes: string, weight: string, quantity: string, discount: string },
  setErrors: (value: { notes: string, weight: string, quantity: string, discount: string }) => void,
  ref: React.MutableRefObject<null>,
  allowedToManage?: boolean,
}

const QuoteEditableCellContent: React.FC<QuoteEditableCellContentProps> = ({
  initValue, fieldToEdit, setFieldToEdit, lineId, rules, errors, setErrors, ref,
  allowedToManage,
}) => {
  const dispatch = useAppDispatch();
  const quoteLines = useAppSelector(quoteLinesSelector);
  const enquiryQuoteLinesCategories = useAppSelector(enquiryQuoteLinesCategoriesSelector);

  const currentLine = quoteLines.items.find((l) => l.id === lineId);

  const forbiddenKeys = ['quoteCategory', 'tax'];

  return (
    <EditableCell
      initValue={initValue}
      fieldToEdit={fieldToEdit}
      setFieldToEdit={setFieldToEdit}
      errors={errors}
      setErrors={setErrors}
      rules={rules}
      ref={ref}
      allowedToManage={allowedToManage}
      currentLine={currentLine}
      categoryColKey="quoteCategory"
      categoryEntityKey="quoteCategoryId"
      categoriesOptions={enquiryQuoteLinesCategories || []}
      editThunkHandle={(obj) => {
        dispatch(editEnquiryQuotLineThunk({
          lineId,
          data: obj,
          setFieldToEdit: () => setFieldToEdit(''),
        }));
      }}
      forbiddenKeys={forbiddenKeys}
    />
  );
};

export default QuoteEditableCellContent;
