import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse } from '../../../core/types/coreTypes';
import { GetVehiclesResponse, VehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { setDashboardLoading, setLoading } from '../../slices/coreSlice';
import { vehiclesAPI } from '../../../api/vehiclesApi';

export const getVehiclesListThunk = createAsyncThunk<GetVehiclesResponse,
{
  filters: VehiclesDashboardFilters,
  isDashboard?: boolean,
  signal?: AbortSignal,
  basicSearchParams?: boolean,
}>(
  'get/VehiclesList',
  async ({
    filters, isDashboard, signal, basicSearchParams,
  }, { dispatch, rejectWithValue }) => {
    isDashboard ? dispatch(setDashboardLoading(true)) : dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehiclesList(filters, basicSearchParams, signal);
      isDashboard ? dispatch(setDashboardLoading(false)) : dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      !isDashboard && dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
