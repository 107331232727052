import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisV, faEnvelope, faMapMarkerAlt, faMobileRetro, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faEdit } from '@fortawesome/free-solid-svg-icons/faEdit';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { summaryFieldValueHandle } from '../../../../../core/utils/summaryFieldValueHandle';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import USER_ICON from '../../../../../assets/images/user-icon.png';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { ContactDto } from '../../../../../common/types/commonTypes';

type CustomerContactLineProps = {
  line: ContactDto,
  setSelectedContact: (contact: ContactDto) => void
  setEdit: VoidFunctionType
  setDelete: VoidFunctionType
}

const CustomerContactLine: React.FC<CustomerContactLineProps> = ({
  line, setSelectedContact, setEdit, setDelete,
}) => {
  const allowedToManageContact = usePermission(PermissionEnum.CustomerEditFields);

  const entities = [
    { icon: faEnvelope, label: line.email || '' },
    { icon: faPhone, label: line.telephone || '' },
    { icon: faMobileRetro, label: line.mobile || '' },
    {
      icon: faMapMarkerAlt,
      label: summaryFieldValueHandle([line.addressLine1, line.addressLine2, line.cityTown, line.region, line.postalCode]),
    },
  ];

  const actions = [
    {
      label: 'Edit contact',
      key: 'editContact',
      onClick: () => {
        setSelectedContact(line);
        setEdit();
      },
      icon: <FontAwesomeIcon icon={faEdit} />,
    },
    {
      label: 'Delete contact',
      key: 'deleteContact',
      onClick: () => {
        setSelectedContact(line);
        setDelete();
      },
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
    },
  ];
  return (
    <article className="contact">
      <div className="contact__content">
        <div className="contact__user">
          <img src={USER_ICON} alt="" className="contact__icon" />
          <div className="contact__name-wrap">
            <TruncateTooltip value={`${line.firstName || ''} ${line.lastName || ''}`} className="contact__name" />
            <TruncateTooltip value={line.position || '-'} className="contact__position" />
          </div>
        </div>
        {entities.map((ent, i) => ent.label.length > 0 && <div key={i} className="contact__info">
          <FontAwesomeIcon icon={ent.icon} className="contact__info-icon" />
          <TruncateTooltip value={ent.label} className="contact__info-value" />
        </div>)}
      </div>
      <Dropdown
        icon={<FontAwesomeIcon icon={faEllipsisV} />}
        className="contact__dropdown"
        menuItems={actions}
        disabled={!allowedToManageContact}
      />
    </article>
  );
};

export default CustomerContactLine;
