import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { toPoundCurrency } from '../../../../common/utils/formatUtils';
import './InvoicingSummary.scss';

type InvoicingSummaryProps = {
  summary: {
    netDataProperties: { label: string, value: string, tooltipValue?: string }[],
    grossDataProperties: { label: string, value: string, tooltipValue?: string }[],
  } | null
}

const InvoicingSummary: React.FC<InvoicingSummaryProps> = ({ summary }) => {
  const tipTextHandle = (value: string) => (`Invoiced amount excludes credit notes for ${toPoundCurrency(value)}`);
  return (
    <section className="invoicingSummary">
      <div className="invoicingSummary__col">
        <h2 className="invoicingSummary__title">
          Summary (net)
        </h2>
        <ul className="invoicingSummary__data">
          {summary?.netDataProperties.map((d) => <li key={d.label} className="invoicingSummary__line">
            <span>
              {d.label}
              {d.tooltipValue && <Tooltip title={tipTextHandle(d.tooltipValue)} overlayClassName="tooltip-sm">
                <FontAwesomeIcon icon={faInfoCircle} className="invoicingSummary__icon" />
              </Tooltip>}
            </span>
            <span>{d.value}</span>
          </li>)}
        </ul>
      </div>
      <div className="invoicingSummary__col">
        <h2 className="invoicingSummary__title">
          Summary (gross)
        </h2>
        <ul className="invoicingSummary__data">
          {summary?.grossDataProperties.map((d) => <li key={d.label} className="invoicingSummary__line">
            <span>
              {d.label}
              {d.tooltipValue && <Tooltip title={tipTextHandle(d.tooltipValue)} overlayClassName="tooltip-sm">
                <FontAwesomeIcon icon={faInfoCircle} className="invoicingSummary__icon" />
              </Tooltip>}
            </span>
            <span>{d.value}</span>
          </li>)}
        </ul>
      </div>
    </section>
  );
};

export default InvoicingSummary;
