import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Tag from '../../../../core/components/tag/Tag';
import './StatusChangeEvent.scss';
import { TruncateTooltip } from '../../truncate-tooltip/TruncateTooltip';

type StatusChangeEventProps = {
  prevStatus: string,
  nextStatus: string,
}

const StatusChangeEvent: React.FC<StatusChangeEventProps> = ({ prevStatus, nextStatus }) => {
  return (
    <div className="statusChangeEvent">
      <Tag
        light
        textSize="md"
        label={<TruncateTooltip
          value={prevStatus}
          className="statusChangeEvent__tooltip"
          overlayClassName="statusChangeEvent__overlay"
        />}
        className="statusChangeEvent__tag statusChangeEvent__tag--prev"
      />
      <FontAwesomeIcon icon={faArrowRight} className="statusChangeEvent__arrow" />
      <Tag
        light
        textSize="md"
        label={<TruncateTooltip
          value={nextStatus}
          className="statusChangeEvent__tooltip"
          overlayClassName="statusChangeEvent__overlay"
        />}
        className="statusChangeEvent__tag"
      />
    </div>
  );
};

export default StatusChangeEvent;
