import React, { useEffect, useState } from 'react';
import './JobsContainer.scss';
import Layout from '../../core/components/layout/Layout';
import JobsFilters from './components/jobs-filters/JobsFilters';
import {
  JobModesEnum,
  JobsSearchParamsNames,
} from '../jobs/enums/jobsEnums';
import ColumnsConfigurationModal from '../../common/components/columns-configuration-modal/ColumnsConfigurationModal';
import { JobsResponseType } from '../jobs/types/jobsTypes';
import JobsContent from './components/jobs-content/JobsContent';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { jobWarningsSelector } from '../../store/selectors/coreSelectors';
import { useJobsPageParams } from './hooks/useJobsPageParams';
import {
  changeJobsDashboardLayoutThunk,
} from '../../store/thunks/jobs/jobsDashboardThunks';
import {
  permittedStatusesSelector, sortedJobCollectionsSelector,
} from '../../store/selectors/jobsSelector';
import { setJobAssigneesLookup, setJobColumnsPagination, setJobsCollections } from '../../store/slices/jobsSlice';
import { getJobWarningsThunk } from '../../store/thunks/coreThunk';

const JobsContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const [cards, setCards] = useState<JobsResponseType | null>(null);

  const [isEyeModal, setIsEyeModal] = useState<boolean>(false);
  const sortedJobCollections = useAppSelector(sortedJobCollectionsSelector);
  const permittedStatuses = useAppSelector(permittedStatusesSelector);
  const jobWarnings = useAppSelector(jobWarningsSelector);

  const {
    params,
    setPageParam,
    setJobStatusParam,
    setJobTabTypeParam,
  } = useJobsPageParams();

  const page = params.get(JobsSearchParamsNames.PAGE);
  const scrollablePages = [JobModesEnum.DASHBOARD, JobModesEnum.CALENDAR];
  const scrollableMainArea = scrollablePages.includes(page as JobModesEnum);

  useEffect(() => {
    setCards(sortedJobCollections);
  }, [sortedJobCollections]);

  useEffect(() => {
    if (!jobWarnings.length) {
      dispatch(getJobWarningsThunk());
    }

    return () => {
      dispatch(setJobColumnsPagination([]));
      dispatch(setJobsCollections(null));
      dispatch(setJobAssigneesLookup([]));
    };
    // eslint-disable-next-line
    }, []);

  return (
    <Layout scrollableMainArea={scrollableMainArea}>
      <ColumnsConfigurationModal
        isVisible={isEyeModal}
        setIsVisible={() => setIsEyeModal(false)}
        statusOptions={permittedStatuses}
        collections={sortedJobCollections}
        saveHandle={(layoutOptions) => dispatch(changeJobsDashboardLayoutThunk({
          layoutSettings: layoutOptions,
          closeModal: () => setIsEyeModal(false),
        }))}
      />
      <JobsFilters
        page={page}
        setPageParam={setPageParam}
        setIsEyeModal={setIsEyeModal}
      />
      <JobsContent
        pageParam={page}
        cards={cards}
        setCards={setCards}
        tabType={params.get(JobsSearchParamsNames.TAB_TYPE)}
        setJobTabTypeParam={setJobTabTypeParam}
        jobStatus={params.get(JobsSearchParamsNames.JOB_STATUS)}
        setJobStatusParam={setJobStatusParam}
      />
    </Layout>
  );
};

export default JobsContainer;
