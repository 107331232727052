import React, { useEffect } from 'react';
import html2canvas from 'html2canvas';
import { Path, useForm, useFormState } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import CommonVehicleCheckFields from './CommonVehicleCheckFields';
import './VehicleChecks.scss';
import AddressField from '../../../common/components/address/AddressField';
import AddressModal from '../../../common/components/address/AddressModal';
import VehicleEquipment from './VehicleEquipment';
import VehicleCheckDecision from './VehicleCheckDecision';
import AdditionalInfo from './AdditionalInfo';
import { useVehicleCheckBeforeAttachmentUpload } from '../hooks/useVehicleCheckBeforeAttachUpload';
import VisualInspection from './VisualInspection';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { getVehicleByIdThunk } from '../../../store/thunks/vehicles/vehicleViewPageThunks';
import {
  markersListSelector,
  vehicleAddressDetailsSelector,
  vehicleCheckDetailsSelector,
  vehicleDetailsSelector,
} from '../../../store/selectors/vehiclesSelectors';
import {
  getFullVehicleCheckDecisionsThunk, getVehicleCheckTypesThunk,
  getVehicleTransportationMethodsThunk, getWeatherConditionsThunk,
} from '../../../store/thunks/coreThunk';
import {
  fullVehicleCheckDecisionsSelector, isFetchingSelector,
  vehicleCheckTypesValuesSelector,
} from '../../../store/selectors/coreSelectors';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { guidGenerator } from '../../../core/utils/guidGenerator';
import {
  createVehicleCheckThunk, getVehicleCheckAttachmentsListThunk,
  getVehicleCheckByIdThunk,
} from '../../../store/thunks/vehicles/VehicleInspectionThunks';
import { SaveVehicleCheckDto } from '../../vehicle-view-page/types/VehicleViewTypes';
import { useVehicleCheckFormHelper } from '../hooks/useVehicleCheckFormHelper';
import DrawerComponent from '../../../core/components/drawer/DrawerComponent';
import ImageMarkersComponent from '../../../core/components/image-markers-component/ImageMarkersComponent';
import { fullCheckDefaults, FullCheckFields } from '../utils/FullVehicleCheckSchema';

type FullVehicleCheckProps = {
  readMode?: boolean,
}

const FullVehicleCheck: React.FC<FullVehicleCheckProps> = ({ readMode }) => {
  const { id, checkId } = useParams();
  const navigate = useNavigate();
  const isFetching = useAppSelector(isFetchingSelector);
  const dispatch = useAppDispatch();
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const vehicleAddress = useAppSelector(vehicleAddressDetailsSelector);
  const fullVehicleCheckDecisions = useAppSelector(fullVehicleCheckDecisionsSelector);
  const markersList = useAppSelector(markersListSelector);
  const { full } = useAppSelector(vehicleCheckTypesValuesSelector);
  const vehicleCheckDetails = useAppSelector(vehicleCheckDetailsSelector);

  const { backHandle } = useLocationHistoryState(false);
  const {
    handleSubmit, control, reset, clearErrors, setValue,
  } = useForm<FullCheckFields>({
    defaultValues: fullCheckDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const {
    backUrl, checkDate, cleanUpForm, closeDrawer,
    cleanUpMarkersState, openMarkersDetails, isAddressModal,
    setAddressModal, addressFields,
    setAddressFields, addressError,
    setAddressError, fileList, setFileList,
    locationHelper, numericKeys, yesNoKeys,
    yesNoSetValueHelper, setAddressFilledValues, setMarkersFilledList,
    isDrawerReadMode, isDrawerVisible,
  } = useVehicleCheckFormHelper(reset, clearErrors, setValue, readMode, true);

  const { beforeUpload } = useVehicleCheckBeforeAttachmentUpload(fileList, setFileList);

  const captureScheme = document.getElementById('capture');
  const onSubmit = (values: FullCheckFields) => {
    const { areAllRequiredFields, location } = locationHelper();

    if (areAllRequiredFields) {
      setAddressError(ErrorsEnum.REQUIRED);
    } else {
      let base64URL = '';
      captureScheme && html2canvas(captureScheme).then((canvas) => {
        base64URL = canvas.toDataURL('image/jpeg');
        const finalData = {
          vehicleCheckType: full,
          mileageMiles: +values.mileageMiles,
          fuelLevelPercent: values.fuelLevelPercent,
          weatherConditions: values.weatherConditions,
          isExteriorClean: values.isExteriorClean === 1,
          isInteriorClean: values.isInteriorClean === 1,
          numberOfVanKeys: +values.numberOfVanKeys,
          hasTools: values.hasTools === 1,
          hasSpareWheel: values.hasSpareWheel === 1,
          notes: values.notes ? values.notes.trim() : null,
          fullVehicleCheckDecision: values.fullVehicleCheckDecision,
          ...location,
          markers: markersList.map(({ comment }, index) => ({
            comment: comment ? comment.trim() : null,
            ordinalNumber: index + 1,
          })),
          visualInspectionImageBase64: base64URL
            ? base64URL.replace(/^data:image\/[a-z]+;base64,/, '')
            : null,
        };

        const list = fileList.map((el) => ({
          fileName: el.name,
          uploadGuid: guidGenerator(),
          file: el,
        }));
        id && full && dispatch(createVehicleCheckThunk({
          vehicleId: +id,
          vehicleCheckData: finalData as SaveVehicleCheckDto,
          attachmentsList: list,
          navigateToInspectionTab: () => {
            cleanUpForm();
            navigate(`/${backUrl}`);
          },
        }));
      });
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getVehicleByIdThunk({ vehicleId: +id }));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (vehicleAddress && !readMode) {
      setAddressFields(vehicleAddress);
    }
    // eslint-disable-next-line
  }, [vehicleAddress, readMode]);

  useEffect(() => {
    dispatch(getVehicleCheckTypesThunk());
    dispatch(getFullVehicleCheckDecisionsThunk());
    dispatch(getWeatherConditionsThunk());
    dispatch(getVehicleTransportationMethodsThunk());
    return () => {
      cleanUpForm();
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (readMode && checkId && id) {
      dispatch(getVehicleCheckByIdThunk({ vehicleId: +id, vehicleCheckId: +checkId }));
      dispatch(getVehicleCheckAttachmentsListThunk({
        vehicleId: +id,
        vehicleCheckId: +checkId,
        filters: { page: 1, pageSize: 20 },
      }));
    }
    // eslint-disable-next-line
  }, [readMode, id, checkId]);

  useEffect(() => {
    if (readMode && vehicleCheckDetails) {
      Object.entries(vehicleCheckDetails).forEach(([key, value]) => {
        if (yesNoKeys.includes(key)) {
          yesNoSetValueHelper(key, value as (boolean | null));
        } else if (key === 'notes') {
          setValue('notes', vehicleCheckDetails.notes || '');
        } else if (numericKeys.includes(key)) {
          setValue(key as Path<FullCheckFields>, value !== null ? value.toString() : '');
        } else setValue(key as Path<FullCheckFields>, value as (string | number | null | undefined));
      });
      setAddressFilledValues();
      setMarkersFilledList();
    }
    // eslint-disable-next-line
  }, [readMode, vehicleCheckDetails]);
  return (
    <Layout
      subHeader={
        <DetailsHead goBack={() => backHandle(backUrl)} isCreatePage>
          <div className="createHead">{`Full vehicle check${checkDate}`}</div>
        </DetailsHead>
      }
    >
      <DrawerComponent
        isVisible={isDrawerVisible}
        onClose={closeDrawer}
        closable
        title="Markers state"
      >
        <ImageMarkersComponent
          cleanUpMarkersState={cleanUpMarkersState}
          isDrawerReadMode={isDrawerReadMode}
        />
      </DrawerComponent>
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        isOptionalFields={false}
        title="Location"
        isExtendedFields
        addressFields={addressFields}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="create-form checkForm">
        <section className="details-form">
          <h2 className="info-grid__title">Vehicle details</h2>
          <CommonVehicleCheckFields
            control={control}
            errors={errors}
            vehicleDetails={vehicleDetails}
            disabled={readMode}
          />
          <AddressField
            addressFields={addressFields}
            setAddressModal={setAddressModal}
            title="Location"
            addressError={addressError}
            disabled={readMode}
          />
        </section>
        <VisualInspection
          control={control}
          errors={errors}
          markersList={markersList}
          disabled={readMode}
          openMarkersDetails={openMarkersDetails}
        />
        <VehicleEquipment control={control} errors={errors} disabled={readMode} />
        <AdditionalInfo
          control={control}
          errors={errors}
          fileList={fileList}
          setFileList={setFileList}
          beforeUpload={beforeUpload}
          disabled={readMode}
        />
        <VehicleCheckDecision
          control={control}
          errors={errors}
          options={fullVehicleCheckDecisions}
          fieldName="fullVehicleCheckDecision"
          disabled={readMode}
        />
        {!readMode && <ButtonActions
          createLabel="Save"
          createType="submit"
          cancelLabel="Clear"
          cancelClick={() => cleanUpForm(true)}
          isLoading={isFetching}
          offsetTop
        />}
      </form>
    </Layout>
  );
};

export default FullVehicleCheck;
