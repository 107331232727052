import React from 'react';
import { Upload } from 'antd';
import { RcFile, UploadProps } from 'antd/lib/upload';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt, faImage } from '@fortawesome/free-regular-svg-icons';
import { InboxOutlined } from '@ant-design/icons';
import { CustomAny } from '../../types/coreTypes';
import './Upload.scss';

const { Dragger } = Upload;

type DraggerUploadProps = {
  name?: string;
  multiple?: boolean;
  fileList?: CustomAny[];
  setFileList?: CustomAny;
  beforeUpload: (files: RcFile[]) => void;
  disabled?: boolean;
  showUploadList?: boolean;
  isImage?: boolean;
  imagePlaceholder?: string;
  accept?: string,
  onChange?: UploadProps['onChange'],
  className?: string,
  customMaxFilesText?: string,
  customMaxSizeText?: string,
  label?: string,
}

const DraggerUpload: React.FC<DraggerUploadProps> = ({
  name = 'file', multiple, fileList, setFileList, beforeUpload, disabled,
  showUploadList, isImage, accept, onChange, imagePlaceholder = 'Upload an image', className,
  customMaxFilesText, customMaxSizeText, label,
}) => {
  return (
    <div className={classNames('customDragger', className)}>
      {label && <span className="upload-label">{label}</span>}
      <Dragger
        name={name}
        multiple={multiple}
        fileList={fileList}
        disabled={disabled}
        beforeUpload={(_, newFiles) => {
          beforeUpload(newFiles);
          return false;
        }}
        showUploadList={showUploadList}
        itemRender={(originNode, file) => <div className="fileItem">
          {file.name}
          <FontAwesomeIcon
            icon={faTrashAlt}
            className="fileItem-icon"
            onClick={() => setFileList(fileList?.filter((f) => f.uid !== file.uid))}
          />
        </div>}
        accept={accept}
        onChange={onChange}
      >
        <p className="ant-upload-drag-icon">
          {isImage
            ? <FontAwesomeIcon icon={faImage} className="customDragger__image" />
            : <InboxOutlined />}
        </p>
        {isImage
          ? <>
            <p className="ant-upload-text">
              {imagePlaceholder}
            </p>
            <p className="ant-upload-hint">
              Maximum 5 MB
            </p>
          </>
          : <>
            <p className="ant-upload-text">Drag & drop files here or click to upload</p>
            <p className="ant-upload-hint">
              {customMaxFilesText || 'Maximum 10 files per one upload.'}
            </p>
            <p className="ant-upload-hint">
              {customMaxSizeText || 'Maximum 100 MB per one file.'}
            </p>
          </>}
      </Dragger>
    </div>
  );
};

export default DraggerUpload;
