import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  jobCategoriesSelector,
  jobTypesWithoutSupplyOnlySelector,
  priorityLevelsSelector,
  usersFilterLookupSelector,
} from '../../../store/selectors/coreSelectors';
import Button from '../../../core/components/button/Button';
import { LinkedToEnum } from '../enums/createJobEnum';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Select from '../../../core/components/select/Select';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { maxReorderLevelsDecimal } from '../../../core/utils/regex';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { getOrderById } from '../../../store/thunks/orders/viewPageOrderThunks';
import { createJobThunk } from '../../../store/thunks/jobs/jobThunks';
import TruncateDropdownLabel from '../../../common/components/truncate-dropdown-label/TruncateDropdownLabel';
import { FetchRectificationsDictionaryParamsType, OrderDictionaryFiltersType } from '../../../common/types/commonTypes';
import { fetchRectificationByIdThunk } from '../../../store/thunks/rectification/rectificationViewPageThunks';
import {
  orderDetailsSelector,
  ordersDictionarySelector,
  rectificationDetailsSelector,
  rectificationDictionarySelector,
} from '../../../store/selectors/sharedSelectors';
import JobCreateConfirmation from './JobCreateConfirmation';
import {
  serOrderDetails,
  setOrderDictionaryFilters, setRectificationDetails,
  setRectificationDictionaryFilters,
} from '../../../store/slices/sharedSlice';
import {
  orderStatusesValuesSelector,
  orderStatusSelector,
  rectificationReadyForPostCheckStatusSelector,
  rectificationStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import Textarea from '../../../core/components/textarea/Textarea';
import { useUsersDictionary } from '../../../common/hooks/useUsersDictionary';
import { CreateJobDefaults, CreateJobFields, JobDescriptionFieldValidationSchema } from '../types/createJobSchema';

type JobFormProps = {
  orderDictionaryFilters?: OrderDictionaryFiltersType,
  rectificationDictionaryFilters?: FetchRectificationsDictionaryParamsType
}

const JobForm:React.FC<JobFormProps> = ({ orderDictionaryFilters, rectificationDictionaryFilters }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(jobTypesWithoutSupplyOnlySelector);
  const jobCategories = useAppSelector(jobCategoriesSelector);
  const ordersDictionary = useAppSelector(ordersDictionarySelector);
  const rectificationDictionary = useAppSelector(rectificationDictionarySelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const orderStatus = useAppSelector(orderStatusSelector);
  const { readyForPostCheck } = useAppSelector(orderStatusesValuesSelector);
  const rectificationDetails = useAppSelector(rectificationDetailsSelector);
  const rectificationStatuses = useAppSelector(rectificationStatusesSelector);
  const rectificationReadyForPostCheckStatus = useAppSelector(rectificationReadyForPostCheckStatusSelector);

  const usersLookup = useAppSelector(usersFilterLookupSelector);

  const ordersUi = useMemo(() => ordersDictionary.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [ordersDictionary]);
  const rectificationsUi = useMemo(() => rectificationDictionary.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [rectificationDictionary]);
  const usersUi = useMemo(() => usersLookup.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} />,
  })), [usersLookup]);

  const [linkedTo, setLinkedTo] = useState('');
  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const [isConfirmModal, setConfirmModal] = useState(false);

  const { userSearch, setUserSearch } = useUsersDictionary();

  const {
    handleSubmit, control, setValue, watch, reset,
  } = useForm<CreateJobFields>({
    defaultValues: CreateJobDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const linkedToNumber = watch('linkedToNumber');

  useEffect(() => {
    if (linkedToNumber && linkedTo === LinkedToEnum.ORDER) {
      dispatch(getOrderById({ id: linkedToNumber }));
    }
    if (linkedToNumber && linkedTo === LinkedToEnum.RECTIFICATION) {
      dispatch(fetchRectificationByIdThunk({ rectificationId: linkedToNumber }));
    }
    // eslint-disable-next-line
  }, [linkedToNumber, linkedTo]);

  useEffect(() => {
    if (orderDetails && jobTypes && linkedTo === LinkedToEnum.ORDER) {
      const { order } = orderDetails;
      setValue('jobType', order.jobType);
    } else {
      setValue('jobType', undefined);
    }
    // eslint-disable-next-line
  }, [orderDetails, linkedTo]);

  useEffect(() => {
    if (rectificationDetails && jobTypes && linkedTo === LinkedToEnum.RECTIFICATION) {
      const { rectification } = rectificationDetails;
      setValue('jobType', rectification.jobType);
    } else {
      setValue('jobType', undefined);
    }
    // eslint-disable-next-line
  }, [rectificationDetails, linkedTo]);

  const clearFields = (val: string) => {
    setLinkedTo(val);
    setValue('linkedToNumber', undefined);
    setValue('jobType', undefined);
    dispatch(serOrderDetails(null));
    dispatch(setRectificationDetails(null));
  };

  const finalValuesHandle = (values: CreateJobFields) => {
    const { linkedToNumber, ...rest } = values;
    const minutesData = {
      ...rest,
      priorityLevel: values.priorityLevel as number,
      jobType: values.jobType as number,
      estimatedMinutes: Math.round(+values.estimatedMinutes * 60),
    };
    return (linkedTo === LinkedToEnum.ORDER)
      ? {
        ...minutesData,
        orderId: linkedToNumber,
        jobCompanyName: orderDetails?.order.jobCompanyName || null,
        jobAddressLine1: orderDetails?.order.jobAddressLine1 || null,
        jobAddressLine2: orderDetails?.order.jobAddressLine2 || null,
        jobCityTown: orderDetails?.order.jobCityTown || null,
        jobRegion: orderDetails?.order.jobRegion || null,
        jobPostalCode: orderDetails?.order.jobPostalCode || null,
        jobTelephone: orderDetails?.order.jobTelephone || null,
      }
      : {
        ...minutesData,
        rectificationId: linkedToNumber,
        jobCompanyName: rectificationDetails?.rectification.jobCompanyName || null,
        jobAddressLine1: rectificationDetails?.rectification.jobAddressLine1 || null,
        jobAddressLine2: rectificationDetails?.rectification.jobAddressLine2 || null,
        jobCityTown: rectificationDetails?.rectification.jobCityTown || null,
        jobRegion: rectificationDetails?.rectification.jobRegion || null,
        jobPostalCode: rectificationDetails?.rectification.jobPostalCode || null,
        jobTelephone: rectificationDetails?.rectification.jobTelephone || null,
      };
  };
  const onSubmit = (values: CreateJobFields) => {
    const finalValues = finalValuesHandle(values);
    const isOrderRequiredConfirmation = orderDetails?.order.status === readyForPostCheck;
    const isRectificationRequiredConfirmation = rectificationDetails?.rectification.status === rectificationReadyForPostCheckStatus;

    if (isOrderRequiredConfirmation || isRectificationRequiredConfirmation) {
      setConfirmModal(true);
    } else {
      dispatch(createJobThunk({
        data: finalValues,
        navigate,
        reset,
      }));
    }
  };

  const fields = watch();
  const createJobWithConfirmation = () => {
    const finalValues = finalValuesHandle(fields);
    dispatch(createJobThunk({
      data: finalValues,
      navigate,
      reset: () => {
        reset();
        setConfirmModal(false);
      },
    }));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const trimmedVal = searchValue?.trim();
    if (trimmedVal) {
      const timeOutId = setTimeout(() => {
        const values = {
          keyword: trimmedVal,
          includeCustomerName: true,
          includeEnquiryNumbers: false,
        };
        linkedTo === LinkedToEnum.ORDER
          ? dispatch(setOrderDictionaryFilters({ ...values, includeOrderNumber: true }))
          : dispatch(setRectificationDictionaryFilters({ ...values, includeRectificationNumber: true }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      linkedTo === LinkedToEnum.ORDER
        ? dispatch(setOrderDictionaryFilters(undefined))
        : dispatch(setRectificationDictionaryFilters(undefined));
    }
    // eslint-disable-next-line
  }, [searchValue]);

  return (
    <>
      <JobCreateConfirmation
        isVisible={isConfirmModal}
        onCancel={() => setConfirmModal(false)}
        entityType={linkedTo === LinkedToEnum.ORDER ? 'order' : 'rectification'}
        entityNo={linkedTo === LinkedToEnum.ORDER
          ? orderDetails?.order.orderNumber
          : rectificationDetails?.rectification.rectificationNumber}
        entityStatus={linkedTo === LinkedToEnum.ORDER
          ? orderStatus.find((el) => el.value === orderDetails?.order.status)?.label
          : rectificationStatuses.find((el) => el.value === rectificationDetails?.rectification.status)?.label}
        createHandle={createJobWithConfirmation}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="jobForm">
        <span className="jobForm__linkedToLabel">Linked to</span>
        <div className="jobForm__linkedTo">
          <Button
            onClick={() => linkedTo !== LinkedToEnum.ORDER && clearFields(LinkedToEnum.ORDER)}
            label="Order"
            htmlType="button"
            designType="light-inverse"
            isActivated={linkedTo === LinkedToEnum.ORDER}
            offsetRight
          />
          <Button
            onClick={() => linkedTo !== LinkedToEnum.RECTIFICATION && clearFields(LinkedToEnum.RECTIFICATION)}
            label="Rectification"
            htmlType="button"
            designType="light-inverse"
            isActivated={linkedTo === LinkedToEnum.RECTIFICATION}
          />
        </div>
        <div className="create-form details-form">
          <Controller
            name="linkedToNumber"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={linkedTo === LinkedToEnum.RECTIFICATION
                  ? rectificationsUi
                  : ordersUi}
                showSearch
                onSearch={(val) => {
                  if (val.length <= 250) {
                    setSearchValue(val);
                  } else {
                    dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                  }
                }}
                onSelect={() => (linkedTo === LinkedToEnum.ORDER
                  ? orderDictionaryFilters?.keyword && dispatch(setOrderDictionaryFilters(undefined))
                  : rectificationDictionaryFilters?.keyword && dispatch(setRectificationDictionaryFilters(undefined)))}
                searchValue={searchValue}
                error={errors.linkedToNumber?.message}
                label={linkedTo === LinkedToEnum.RECTIFICATION ? 'Rectification No' : 'Order No'}
                disabled={!linkedTo}
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            name="jobType"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={jobTypes}
                error={errors.jobType?.message}
                label="Job type"
                disabled={!linkedTo}
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            name="jobCategories"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={jobCategories}
                error={errors?.jobCategories && ErrorsEnum.REQUIRED}
                label="Job category"
                disabled={!linkedTo}
                mode="multiple"
                showArrow
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            name="priorityLevel"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={priorityLevels}
                error={errors.priorityLevel?.message}
                label="Priority level"
                disabled={!linkedTo}
              />
            )}
          />
          <Controller
            name="estimatedMinutes"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <DecimalInputNumber
                value={field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                postfix="h"
                max={maxReorderLevelsDecimal}
                error={errors.estimatedMinutes?.message}
                label="Estimated hours"
                disabled={!linkedTo}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={JobDescriptionFieldValidationSchema}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                onChange={field.onChange}
                error={errors.description?.message}
                label="Description"
                className="details-form__field--lg"
                disabled={!linkedTo}
              />
            )}
          />
          <Controller
            name="assigneeIds"
            control={control}
            render={({ field }) => (
              <Select
                mode="multiple"
                value={field.value}
                onChange={(val) => field.onChange(val)}
                options={usersUi}
                showSearch
                showArrow
                onSearch={(val) => {
                  if (val.trim()) {
                    if (val.length <= 250) {
                      setUserSearch(val);
                    } else {
                      dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                    }
                  } else {
                    setUserSearch(val);
                  }
                }}
                onSelect={() => {
                  userSearch && setUserSearch(undefined);
                }}
                onBlur={() => {
                  userSearch && setUserSearch(undefined);
                }}
                error={errors.assigneeIds && ErrorsEnum.REQUIRED}
                label="Assigned to"
                className="details-form__field--lg"
                disabled={!linkedTo}
              />
            )}
          />
          <ButtonActions
            createLabel="Create"
            cancelLabel="Clear"
            createType="submit"
            cancelClick={() => {
              clearFields('');
              reset();
            }}
            disabledCancel={!linkedTo}
            disabledCreate={!linkedTo}
            className="details-form__actions"
          />
        </div>
      </form>
    </>
  );
};

export default JobForm;
