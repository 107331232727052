import React from 'react';
import { useParams } from 'react-router-dom';
import classNames from 'classnames';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import Restricted from '../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import Select from '../../../../core/components/select/Select';
import { setErrorMessage } from '../../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { usersFilterLookupSelector } from '../../../../store/selectors/coreSelectors';
import { RectificationDto } from '../../../dashboard/types/rectificationsTypes';
import {
  patchRectificationThunk,
  putRectificationStatusThunk,
} from '../../../../store/thunks/rectification/rectificationViewPageThunks';
import {
  isRectificationInReadModeSelector,
  rectificationStatusTransitionsListSelectors,
} from '../../../../store/selectors/rectificationsSelector';
import { RectificationViewModeParamsEnum } from '../../enums/RectificationViewEnums';
import usePermission from '../../../../permissions-handling/permissionHook';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';
import RectificationPartRequestSubmissionButtons from './RectificationPartRequestSubmissionButtons';
import { rectificationStatusChangeConfirmationStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';
import { UNKNOWN_USER } from '../../../../core/utils/regex';
import { useUsersDictionary } from '../../../../common/hooks/useUsersDictionary';

type RectificationFiltersProps = {
  rectification: RectificationDto | undefined,
  tabs: { label: string, value: RectificationViewModeParamsEnum }[];
  mode: string | null;
  setModeParam: (value: string) => void;
  setIsRejectStatus: BooleanFunctionType;
  setCancellationConfirmation: BooleanFunctionType;
  setCompletedConfirmation: BooleanFunctionType;
}

const RectificationFilters: React.FC<RectificationFiltersProps> = ({
  rectification, tabs, mode, setModeParam, setIsRejectStatus, setCancellationConfirmation, setCompletedConfirmation,
}) => {
  const allowedToEdit = usePermission(PermissionEnum.RectificationEditFields);
  const { id } = useParams();
  const { windowWidth } = useScreenWitdh();
  const dispatch = useAppDispatch();
  const usersFilterLookup = useAppSelector(usersFilterLookupSelector);
  const rectificationStatusTransitionList = useAppSelector(rectificationStatusTransitionsListSelectors);
  const { rejected, cancelled, completed } = useAppSelector(rectificationStatusChangeConfirmationStatusesSelector);
  const isRectificationInReadMode = useAppSelector(isRectificationInReadModeSelector);
  const isRequestActionsAllowed = usePermission(PermissionEnum.RectificationPartRequestEditPartLineFieldsAndUnlockPartLineActions);

  const { userSearch, setUserSearch } = useUsersDictionary();

  const changeAssignee = (value: number) => {
    id && dispatch(patchRectificationThunk({
      rectificationId: +id,
      data: {
        assigneeId: value,
      },
    }));
  };
  const changeStatus = (value: number) => {
    if (value === rejected) {
      setIsRejectStatus(true);
    } else if (value === completed) {
      setCompletedConfirmation(true);
    } else if (value === cancelled) {
      setCancellationConfirmation(true);
    } else {
      id && dispatch(putRectificationStatusThunk({
        rectificationId: +id,
        statusTransition: value,
      }));
    }
  };

  const disabledActions = isRectificationInReadMode || !allowedToEdit;

  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && tabs.length > 0 && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={tabs}
            value={mode}
            onChange={(value) => setModeParam(value as string)}
          />
        </div>
      )}
      <div className={classNames('view-details-tabs-actions  desktop-sticky-filters', {
        'view-details-tabs-actions--large-tabs': tabs.length > 4,
      })}
      >
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={tabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="view-details-tabs-actions__segmented"
          />
        )}
        {mode === RectificationViewModeParamsEnum.RECTIFICATION_PART_REQUEST
          ? <>{isRequestActionsAllowed && <RectificationPartRequestSubmissionButtons />}</>
          : <Restricted to={PermissionEnum.RectificationViewPage}>
            <div className="view-details-tabs-actions__selects-wrapper">
              <Select
                value={rectification?.isAssigneeDeleted ? UNKNOWN_USER : rectification?.assigneeId}
                options={usersFilterLookup}
                onChange={(value) => changeAssignee(+value)}
                showArrow
                showSearch
                onSearch={(val) => {
                  if (val.length <= 250) {
                    setUserSearch(val.trim());
                  } else {
                    dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                  }
                }}
                onSelect={() => {
                  userSearch && setUserSearch(undefined);
                }}
                className="view-details-tabs-actions__select--highlighted"
                parentRender
                disabled={disabledActions}
              />
              <Select
                value={rectification?.status}
                onChange={(value) => changeStatus(+value)}
                options={rectificationStatusTransitionList}
                parentRender
                disabled={disabledActions}
              />
            </div>
          </Restricted>}
      </div>
    </>
  );
};

export default RectificationFilters;
