import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import JobQualityChecksTable from './JobQualityChecksTable';
import Button from '../../../../../core/components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { jobDetailsSelector } from '../../../../../store/selectors/jobsSelector';
import { jobStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import QualityCheckModal from './quality-check-modal/QualityCheckModal';
import { deleteJobQualityCheckThunk, getJobQualityChecksThunk } from '../../../../../store/thunks/jobs/jobThunks';
import { setJobQualityChecks } from '../../../../../store/slices/jobsSlice';

const JobQualityCheck: React.FC = () => {
  const dispatch = useAppDispatch();
  const [isRunCheck, setIsRunCheck] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [interactedId, setInteractedId] = useState<number | undefined>(undefined);
  const [readMode, setReadMode] = useState(false);

  const jobDetails = useAppSelector(jobDetailsSelector);
  const status = jobDetails?.job?.jobStatus;
  const jobId = jobDetails?.job?.id;
  const { jobQc } = useAppSelector(jobStatusesValuesSelector);
  const isJobStatusJobInQc = status === jobQc;

  const cancelDeleteConfirmationHandler = () => {
    setIsDelete(false);
    setInteractedId(undefined);
  };
  const deleteCheckHandler = () => {
    jobId && interactedId && dispatch(deleteJobQualityCheckThunk({
      jobId,
      checkId: interactedId,
      closeModal: cancelDeleteConfirmationHandler,
    }));
  };

  useEffect(() => {
    jobId && dispatch(getJobQualityChecksThunk({ jobId }));
    // eslint-disable-next-line
  }, [jobDetails?.job?.id]);

  useEffect(() => {
    return () => {
      dispatch(setJobQualityChecks([]));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <QualityCheckModal
        isVisible={isRunCheck}
        onCancel={() => {
          setIsRunCheck(false);
          readMode && setReadMode(false);
        }}
        readMode={readMode}
        interactedId={interactedId}
      />
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={cancelDeleteConfirmationHandler}
        confirmHandler={deleteCheckHandler}
      />
      <div className="actions-and-paging__actions">
        <Button
          label="Run quality check"
          onClick={() => setIsRunCheck(true)}
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          disabled={!isJobStatusJobInQc}
          designType="dark"
          reversed
        />
      </div>
      <JobQualityChecksTable
        openDeleteConfirmation={(id) => {
          setInteractedId(id);
          setIsDelete(true);
        }}
        openQualityCheckDetails={(id) => {
          setReadMode(true);
          setIsRunCheck(true);
          setInteractedId(id);
        }}
      />
    </>
  );
};

export default JobQualityCheck;
