import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { StockPartRequestViewEnum } from '../enums/stockPartRequestsEnum';
import {
  stockPartRequestsFiltersSelector,
  stockPartRequestsLinesViewFiltersListSelector,
  stockPartRequestsLinesViewFiltersSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import {
  setStockPartRequestsFilters,
  setStockPartRequestsLinesViewFilters,
} from '../../../store/slices/stockPartRequestSlice';
import { getStockPartsRequestDashboard } from '../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';

export const useStockPartRequestsFilters = (currentView: string | null) => {
  const dispatch = useAppDispatch();
  const stockPartRequestsFilters = useAppSelector(stockPartRequestsFiltersSelector);
  const filters = useAppSelector(stockPartRequestsLinesViewFiltersSelector);
  const filtersList = useAppSelector(stockPartRequestsLinesViewFiltersListSelector);
  const { controller, cancelRequest } = useCancelRequest();

  const isRequestsView = currentView === StockPartRequestViewEnum.REQUESTS;
  const isLinesView = currentView === StockPartRequestViewEnum.LINES;

  const defaultKeywordValue = useMemo(() => (isRequestsView ? stockPartRequestsFilters.keyword : filters.keyword), [
    isRequestsView, stockPartRequestsFilters.keyword, filters.keyword,
  ]);

  // Used to prevent double request on mount when keyword is not empty
  const firstRenderRef = useRef(true);

  const [keywordValue, setKeywordValue] = useState<string | undefined>(defaultKeywordValue);
  const changeKeywordValue = (value: string | undefined) => {
    setKeywordValue(value);
    firstRenderRef.current = false;
  };

  const initDropdownSearch = {
    customer: '',
  };

  useEffect(() => {
    cancelRequest();
    // eslint-disable-next-line
  }, [currentView]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && !firstRenderRef.current) {
      const timeOutId = setTimeout(() => {
        if (isRequestsView) {
          cancelRequest();
          const newFilters = {
            ...stockPartRequestsFilters,
            page: 1,
            keyword: keywordValue.trim(),
          };
          dispatch(setStockPartRequestsFilters(newFilters));
          dispatch(getStockPartsRequestDashboard({
            filters: newFilters,
            signal: controller.current?.signal,
          }));
        } else {
          dispatch(setStockPartRequestsLinesViewFilters({
            ...filters,
            page: 1,
            keyword: keywordValue.trim(),
          }));
        }
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (isRequestsView) {
        cancelRequest();
        const newFilters = {
          ...stockPartRequestsFilters,
          page: 1,
          keyword: undefined,
        };
        dispatch(setStockPartRequestsFilters(newFilters));
        dispatch(getStockPartsRequestDashboard({
          filters: newFilters,
          signal: controller.current?.signal,
        }));
      } else {
        dispatch(setStockPartRequestsLinesViewFilters({
          ...filters,
          page: 1,
          keyword: undefined,
        }));
      }
    }
    // eslint-disable-next-line
    }, [keywordValue]);

  useEffect(() => {
    if (isRequestsView) {
      changeKeywordValue(stockPartRequestsFilters.keyword);
    } else {
      changeKeywordValue(filters.keyword);
    }
    firstRenderRef.current = true;
    // eslint-disable-next-line
    }, [currentView]);

  const getFilteredResult = (values: number[], field: string, notThunkCall?: boolean) => {
    const commonFilters = { [field]: values, page: 1 };
    if (isLinesView) {
      dispatch(setStockPartRequestsLinesViewFilters({ ...filters, ...commonFilters }));
    }
    if (isRequestsView) {
      const newFilters = { ...stockPartRequestsFilters, ...commonFilters };
      dispatch(setStockPartRequestsFilters(newFilters));
      if (!notThunkCall) {
        cancelRequest();
        dispatch(getStockPartsRequestDashboard({
          filters: newFilters,
          signal: controller.current?.signal,
        }));
      }
    }
  };

  const getIsAnyFilterApplied = (keyword: string | undefined, filters: (string[] | number[] | undefined)[]) => {
    return keyword || filters.some((el) => el && el.length > 0);
  };

  const clearFilters = () => {
    if (isRequestsView) {
      const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        status, pageSize, isActive, ...rest
      } = stockPartRequestsFilters;
      stockPartRequestsFilters.keyword && dispatch(setStockPartRequestsFilters({
        ...stockPartRequestsFilters,
        page: 1,
        keyword: undefined,
        dueDate: undefined,
      }));
    } else {
      const linesViewFilters = [filters.statuses];
      const isAnyFilterApplied = getIsAnyFilterApplied(filters.keyword, linesViewFilters);
      isAnyFilterApplied && dispatch(setStockPartRequestsLinesViewFilters({
        ...filters,
        keyword: undefined,
        linkedEntityNumber: undefined,
        linkedEntityStatus: undefined,
        statuses: undefined,
        page: 1,
        pageSize: filters.pageSize,
      }));
    }
  };

  const getFilters = () => {
    if (isLinesView) return filtersList;
    else return undefined;
  };

  return {
    keywordValue,
    changeKeywordValue,
    initDropdownSearch,
    getFilteredResult,
    clearFilters,
    filters: getFilters(),
    controller,
    cancelRequest,
  };
};
