import React, { useEffect, useRef } from 'react';
import { Tabs } from 'antd';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { mockedFolders } from '../../../forms/utils/data';
import Button from '../../../core/components/button/Button';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  stockPartSubcategoriesFiltersSelector,
  stockPartSubcategoriesSelector,
} from '../../../store/selectors/stockSelectors';
import { setPartSubcategoriesFilters } from '../../../store/slices/stockSlice';
import {
  BooleanFunctionType, initPaging, NumberFunctionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import PartCategoryActions from './PartCategoryActions';
import PartSubcategoriesTable from './PartSubcategoriesTable';
import { getStockPartSubcategoriesThunk } from '../../../store/thunks/stock/categories/partCategoriesThunks';
import { partSubcategoryOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { PartSubcategoriesOrderingEnum } from '../types/partCategoriesTypes';
import { partCategoriesSelector } from '../../../store/selectors/sharedSelectors';

const { TabPane } = Tabs;

type PartCategoriesProps = {
  setIsCreateCategory: BooleanFunctionType,
  setIsCreateSubcategory: BooleanFunctionType,
  setIsDeleteModal: (params: { categoryId?: number, subcategoryId?: number }) => void,
  setIsAffectedModal: (params: { categoryId?: number, subcategoryId?: number }) => void,
  openEditCategoryModal: VoidFunctionType,
  openEditSubcategoryModal: NumberFunctionType,
}

const PartCategories: React.FC<PartCategoriesProps> = ({
  setIsCreateCategory,
  setIsCreateSubcategory,
  setIsDeleteModal,
  setIsAffectedModal,
  openEditCategoryModal,
  openEditSubcategoryModal,
}) => {
  const dispatch = useAppDispatch();
  const categories = useAppSelector(partCategoriesSelector);
  const subcategoriesOrdering = useAppSelector(partSubcategoryOrderingSelector);
  const subcategoriesFilters = useAppSelector(stockPartSubcategoriesFiltersSelector);
  const { items: subcategories, totalCount } = useAppSelector(stockPartSubcategoriesSelector);
  const defaultOrderRef = useRef<number | null>(null);

  useEffect(() => {
    if (!subcategoriesFilters.categoryIds?.length && categories && categories.length > 0) {
      dispatch(setPartSubcategoriesFilters({ ...subcategoriesFilters, categoryIds: [categories[0].id] }));
    }
    // eslint-disable-next-line
  }, [categories]);

  useEffect(() => {
    if (!subcategoriesFilters.order?.field && subcategoriesOrdering.length > 0) {
      if (!defaultOrderRef.current) {
        const defaultOrdering = subcategoriesOrdering.find((o) => o.label === PartSubcategoriesOrderingEnum.SubcategoryName)?.value;
        if (defaultOrdering) {
          defaultOrderRef.current = defaultOrdering;
          dispatch(setPartSubcategoriesFilters({ ...subcategoriesFilters, order: { field: defaultOrdering, isAsc: true } }));
        }
      } else {
        dispatch(setPartSubcategoriesFilters({ ...subcategoriesFilters, order: { field: defaultOrderRef.current, isAsc: true } }));
      }
    } else if (subcategoriesFilters.order?.field && subcategoriesFilters.categoryIds?.length) {
      dispatch(getStockPartSubcategoriesThunk({ filters: subcategoriesFilters }));
    }
    // eslint-disable-next-line
  }, [subcategoriesOrdering, subcategoriesFilters]);

  return (
    <Tabs
      renderTabBar={(props, TabNavList) => (
        <TabNavList {...props} mobile={false} />
      )}
      tabPosition="top"
      activeKey={subcategoriesFilters.categoryIds?.[0].toString()}
      onChange={(id) => dispatch(setPartSubcategoriesFilters({
        ...initPaging,
        categoryIds: [+id],
        order: defaultOrderRef.current ? { field: defaultOrderRef.current, isAsc: true } : subcategoriesFilters.order,
      }))}
      className={classnames('formsFolders', 'scrolling-tabs', { 'scrolling-tabs--empty': mockedFolders.length === 0 })}
      popupClassName="scrolling-tabs__popup"
      tabBarExtraContent={
        <Button
          label="Create category"
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          onClick={() => setIsCreateCategory(true)}
          designType="dark"
          reversed
        />
      }
      destroyInactiveTabPane
    >
      {categories.map((el) => (
        <TabPane
          tab={<span className="scrolling-tabs__tab-button">
            <TruncateTooltip value={el.name} className="scrolling-tabs__tab-label" hideTooltipOnMobile />
            {!!el.subcategoriesCount && <span className="scrolling-tabs__count-value">{`(${el.subcategoriesCount})`}</span>}
          </span>}
          key={el.id}
        >
          <>
            <PartCategoryActions
              setIsCreateSubcategory={setIsCreateSubcategory}
              totalItems={totalCount}
            />
            <PartSubcategoriesTable
              data={subcategories}
              totalItems={totalCount}
              setIsDeleteModal={setIsDeleteModal}
              setIsAffectedModal={setIsAffectedModal}
              openEditCategoryModal={openEditCategoryModal}
              openEditSubcategoryModal={openEditSubcategoryModal}
            />
          </>
        </TabPane>
      ))}
    </Tabs>
  );
};

export default PartCategories;
