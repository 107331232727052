import React from 'react';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';
import { fieldNameEventHandle, getReadableBoolean, getValuesFromJsonArray } from '../../../../../../common/utils/activityLogUtils';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { useAppSelector } from '../../../../../../store/hooks';
import { shortBusinessAreasSelector } from '../../../../../../store/selectors/coreSelectors';
import { partSubcategoriesLookupSelector } from '../../../../../../store/selectors/sharedSelectors';

const PartFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const businessAreas = useAppSelector(shortBusinessAreasSelector);
  const initialSubcategoriesList = useAppSelector(partSubcategoriesLookupSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'BusinessAreas':
        return getValuesFromJsonArray(value as string, businessAreas);
      case 'SubcategoryId':
        return initialSubcategoriesList.find((el) => el.value === +value)?.label || value;
      case 'IsCorePart':
        return getReadableBoolean(value);
      default: return value;
    }
  };
  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field, { businessAreas: 'Part', name: 'Part' })}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default PartFieldUpdateEvent;
