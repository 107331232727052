import React from 'react';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Segmented from '../../../../core/components/segmented/Segmented';
import Selectable from '../../../../core/components/selectable/Selectable';
import { leaveRequestsStatusCategories } from '../../../../account/components/account-content/leave-requests/utils/data';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { employeesLeaveRequestsFiltersSelector } from '../../../../store/selectors/employeesSelector';
import { LeaveRequestsStatusCategoriesEnum } from '../../../../core/enums/dictionariesEnums';
import { setEmployeesLeaveRequestsFilters } from '../../../../store/slices/employeesSlice';
import Pagination from '../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import Button from '../../../../core/components/button/Button';
import { VoidFunctionType } from '../../../../core/types/coreTypes';

type EmployeesLeaveRequestsFiltersProps = {
  totalCount: number,
  openCreationForm: VoidFunctionType,
}
const EmployeesLeaveRequestsFilters: React.FC<EmployeesLeaveRequestsFiltersProps> = ({ totalCount, openCreationForm }) => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const filters = useAppSelector(employeesLeaveRequestsFiltersSelector);

  const changeStatusCategory = (value: string | number) => {
    dispatch(setEmployeesLeaveRequestsFilters({
      ...filters,
      page: 1,
      isActive: value === LeaveRequestsStatusCategoriesEnum.ACTIVE,
    }));
  };

  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        {!!windowWidth && windowWidth >= responsiveEndpointsEnum.LG
          ? <Segmented
            options={leaveRequestsStatusCategories}
            onChange={changeStatusCategory}
            value={filters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
          />
          : <Selectable
            options={leaveRequestsStatusCategories}
            onChange={changeStatusCategory}
            value={filters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
          />}
        <Button
          label="Create new leave request"
          onClick={openCreationForm}
        />
      </div>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page, pageSize) => dispatch(setEmployeesLeaveRequestsFilters({ ...filters, page, pageSize }))}
        totalItems={totalCount}
        className="actions-and-paging__paging"
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        narrow
      />
    </div>
  );
};

export default EmployeesLeaveRequestsFilters;
