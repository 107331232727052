import { useEffect, useState } from 'react';
import { useAppSelector } from '../../../store/hooks';
import { suitableVehicleSourcesValuesSelector } from '../../../store/selectors/coreSelectors';
import { AvailableVehiclesData } from '../components/select-vehicles-modal/SelectVehiclesModal';

export const useCreatePartSuitableVehicles = () => {
  const { byCode, groups } = useAppSelector(suitableVehicleSourcesValuesSelector);

  const [isSelectVehicles, setSelectVehicles] = useState(false);
  const [selectedSource, setSelectedSource] = useState(byCode);
  const [selectedIds, setSelectedIds] = useState<number[] | null>(null);
  const [selectedGroupIds, setSelectedGroupIds] = useState<number[] | null>(null);

  const resetSuitableVehicles = () => {
    setSelectedSource(null);
    setSelectedIds(null);
    setSelectedGroupIds(null);
  };

  useEffect(() => {
    !selectedSource && byCode && setSelectedSource(byCode);
    // eslint-disable-next-line
  }, [byCode]);

  const handleSave = (data: AvailableVehiclesData) => {
    setSelectedSource(data.suitableVehiclesSource);
    setSelectedIds(data.suitableForVehicleIds);
    setSelectedGroupIds(data.suitableForVehicleGroupIds);
    setSelectVehicles(false);
  };

  return {
    isSelectVehicles,
    setSelectVehicles,
    selectedSource,
    selectedIds,
    selectedGroupIds,
    resetSuitableVehicles,
    handleSave,
    groups,
  };
};
