import React from 'react';
import { StringFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import QuantityComponent from '../../../../../common/components/select-product-source-modal/QuantityComponent';
import Tr from '../../../../../core/components/table-components/Tr';
import Input from '../../../../../core/components/input/Input';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';

type DistributeQtyLineProps = {
  checked: boolean,
  sourceBoxValue: string,
  sourceBoxOnChange?: VoidFunctionType,
  disabledQty?: boolean,
  disabledPlus?: boolean,
  disabledMinus?: boolean,
  requestedQty: string,
  qtyValue?: string,
  setQtyValue?: StringFunctionType,
  text: string,
  isDecimals?: boolean,
  costPrice?: string | number,
  sellingPrice?: string | null,
  isManual?: boolean,
}

const DistributeQtyLine: React.FC<DistributeQtyLineProps> = ({
  checked,
  sourceBoxValue,
  sourceBoxOnChange,
  disabledMinus,
  disabledPlus,
  disabledQty,
  requestedQty,
  qtyValue,
  setQtyValue,
  text,
  isDecimals,
  costPrice,
  sellingPrice,
  isManual,
}) => {
  return (
    <Tr>
      <TdNew>
        <Checkbox
          value={sourceBoxValue}
          checked={checked}
          onChange={sourceBoxOnChange}
          table
          disabled={isManual}
        />
      </TdNew>
      <TdNew>
        <span>
          {text}
        </span>
      </TdNew>
      <TdNew>
        <Input
          value={requestedQty}
          readOnly
          className="distributeQtyLine__input"
        />
      </TdNew>
      <TdNew>
        {!isManual && <QuantityComponent
          disabled={disabledQty}
          disabledPlus={disabledPlus}
          disabledMinus={disabledMinus}
          value={qtyValue || '0'}
          setValue={(v) => setQtyValue && setQtyValue(v)}
          isDecimals={!!isDecimals}
        />}
      </TdNew>
      <TdNew>
        {!isManual
          ? <div className="valueCell">{`£ ${costPrice || 0}`}</div>
          : <div />}
      </TdNew>
      <TdNew>
        <div className="valueCell">{`£ ${sellingPrice || 0}`}</div>
      </TdNew>
    </Tr>
  );
};

export default DistributeQtyLine;
