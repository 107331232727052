import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, soldProductsByCustomerDataChartSelector } from '../../../../../../store/selectors/reportsSelectors';
import Chart from '../../../../../../core/components/chart/Chart';

const SoldProductsByCustomerChart: React.FC = () => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { series, categories, info } = useAppSelector(soldProductsByCustomerDataChartSelector);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      series={series}
      height={550}
      options={{
        plotOptions: {
          bar: {
            horizontal: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories,
          labels: { trim: true },
        },
        yaxis: {
          labels: {
            formatter: (value) => value.toFixed(0),
          },
        },
        tooltip: {
          enabled: true,
          custom: ({ dataPointIndex }) => {
            const currentInfo = info[dataPointIndex];
            return '<div class="customBarTooltip">'
              + `<span>Quantity: ${currentInfo.quantity} pcs</span>`
              + `<span>Cost net: ${currentInfo.costNet}</span>`
              + `<span>Sales net: ${currentInfo.salesNet}</span>`
              + '</div>';
          },
        },
      }}
    />
  );
};

export default SoldProductsByCustomerChart;
