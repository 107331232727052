import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { purchaseTabModesOptions } from '../../utils/data';
import Selectable from '../../../../core/components/selectable/Selectable';
import {
  PurchaseUrlParamsNames,
  StockTabParamEnum,
} from '../../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { setPurchaseOrdersFilters } from '../../../../store/slices/purchaseOrderSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  archivedOrderStatusSelector,
  purchaseOrdersFiltersSelector,
} from '../../../../store/selectors/purchaseOrderSelectors';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { StockPageParamEnums } from '../../enums/stockEnums';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';

type PurchaseOrdersFiltersContentProps = {
  screenSize?: 'sm' | 'lg',
  purchaseTabType: string | null;
  setPurchaseTabTypeParam: StringFunctionType;
}

const PurchaseOrdersFiltersContent: React.FC<PurchaseOrdersFiltersContentProps> = ({
  screenSize, purchaseTabType, setPurchaseTabTypeParam,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const page = params.get(PurchaseUrlParamsNames.PAGE);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const archivedOrderStatus = useAppSelector(archivedOrderStatusSelector);
  const purchaseOrdersFilters = useAppSelector(purchaseOrdersFiltersSelector);

  const changePurchaseTabParam = (value: string) => {
    setPurchaseTabTypeParam(value as string);
    const newValues = value === StockTabParamEnum.ACTIVE
      ? { statuses: undefined, isActive: true }
      : { statuses: [archivedOrderStatus[0].value], isActive: false };

    dispatch(setPurchaseOrdersFilters({
      ...purchaseOrdersFilters,
      ...newValues,
      page: 1,
    }));
  };

  useEffect(() => {
    if (currentUser) {
      if (!purchaseTabType) {
        if (page === StockPageParamEnums.PURCHASES) {
          const { isActive } = purchaseOrdersFilters;
          const tabValue = isActive ? StockTabParamEnum.ACTIVE : StockTabParamEnum.ARCHIVED;

          setSearchParams({
            page,
            [PurchaseUrlParamsNames.TAB_TYPE]: tabValue,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, page, purchaseTabType]);
  return (
    <Selectable
      options={purchaseTabModesOptions}
      value={purchaseTabType || ''}
      onChange={(value) => changePurchaseTabParam(value as string)}
      size={screenSize}
    />
  );
};

export default PurchaseOrdersFiltersContent;
