import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import Modal from '../../../../../core/components/modal/Modal';
import { AddEditInvoiceFields, defaultAddInvoiceValues } from '../../../types/AddInvoiceSchema';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { initSmallPaging, NumberFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import {
  invoiceNominalCodesFiltersSelector,
  invoiceNominalCodesSelector,
  invoicesAndCreditNotesSelector,
  invoicingPurchaseOrderSelector,
  salesOrderDetailsSelector,
} from '../../../../../store/selectors/invoicingSelectors';
import Input from '../../../../../core/components/input/Input';
import DatePickerComponent from '../../../../../core/components/date-picker/DatePicker';
import { DEFAULT_DATE_FORMAT, SMALL_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import { RequiredFieldSchema } from '../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import Pagination from '../../../../../core/components/pagination/Pagination';
import AddInvoiceTable from './AddInvoiceTable';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import {
  invoiceDocumentTypeValuesSelector,
  isFetchingSelector,
  nominalRecordStatesValuesSelector,
} from '../../../../../store/selectors/coreSelectors';
import './AddInvoiceModal.scss';
import AddInvoiceTotal from './AddInvoiceTotal';
import {
  addInvoicesAndCreditNotesThunk,
  editInvoicesAndCreditNotesThunk,
  getInvoiceNominalCodesThunk,
  getNominalCodesSummary,
} from '../../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import {
  setInvoiceNominalCodes,
  setInvoiceNominalCodesFilters,
  setInvoiceNominalCodesSummary,
} from '../../../../../store/slices/invoicingSlice';
import { useDefaultPagingAndOrdering } from '../../../../../core/hooks/useDefaultPagingAndOrdering';
import { getNominalRecordsOrderingThunk } from '../../../../../store/thunks/core/coreOrderingThunks';
import { nominalRecordsOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { initNominalCodesData } from '../../types/InvoicingViewPageTypes';
import { NominalRecordsOrdering } from '../../../../container/enums/InvoicingSharedEnums';

type AddInvoiceModalProps = {
  visible: boolean,
  onClose: VoidFunctionType,
  type: number | undefined,
  setType: NumberFunctionType,
  idToEdit: number | undefined,
  isSales: boolean,
  isCreditNotesModalType: boolean,
}

const AddInvoiceModal: React.FC<AddInvoiceModalProps> = ({
  visible,
  onClose,
  type,
  setType,
  idToEdit,
  isSales,
  isCreditNotesModalType,
}) => {
  const { id } = useParams();
  const ordering = useAppSelector(nominalRecordsOrderingSelector);
  const dispatch = useAppDispatch();
  const { invoice } = useAppSelector(invoiceDocumentTypeValuesSelector);
  const { pending } = useAppSelector(nominalRecordStatesValuesSelector);
  const purchaseOrderDetails = useAppSelector(invoicingPurchaseOrderSelector);
  const orderDetails = useAppSelector(salesOrderDetailsSelector);
  const filters = useAppSelector(invoiceNominalCodesFiltersSelector);
  const { items } = useAppSelector(invoicesAndCreditNotesSelector);
  const { totalCount, allItemIds } = useAppSelector(invoiceNominalCodesSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const isInvoice = type === invoice;

  const defaultPagingAndOrdering = useDefaultPagingAndOrdering({
    filters,
    ordering,
    initialPaging: initSmallPaging,
    getOrderingThunk: getNominalRecordsOrderingThunk,
    initialOrdering: NominalRecordsOrdering.OrdinalNumber,
    setFiltersAction: setInvoiceNominalCodesFilters,
  });

  const getEntityNumber = () => {
    const DEFAULT_SEQUENCE_NUMBER = 0;
    const maxInvoiceNumber = isSales
      ? orderDetails?.maxInvoiceEntitySequenceNumber
      : purchaseOrderDetails?.maxInvoiceEntitySequenceNumber;
    const maxCreditNoteNumber = isSales
      ? orderDetails?.maxCreditNoteEntitySequenceNumber
      : purchaseOrderDetails?.maxCreditNoteEntitySequenceNumber;
    const sequenceNumber = ((isInvoice ? maxInvoiceNumber : maxCreditNoteNumber) || DEFAULT_SEQUENCE_NUMBER) + 1;
    const entityNumber = (isSales
      ? orderDetails?.order.orderNumber
      : purchaseOrderDetails?.purchaseOrder.purchaseOrderNumber
    ) || '';
    const prefix = isSales
      ? isInvoice ? 'SI' : 'SC'
      : isInvoice ? 'PI' : 'PC';
    return `${prefix}${sequenceNumber}-${entityNumber}`;
  };

  const xeroRef = (isSales
    ? orderDetails?.order.orderNumber
    : purchaseOrderDetails?.purchaseOrder.purchaseOrderNumber
  ) || '';

  const {
    isAll,
    isIndeterminate,
    checkAllHandle,
    checkRowHandle,
    checkedKeys,
    checkedKeysCount,
  } = useSelectAll(allItemIds);

  const {
    control, setValue, reset, handleSubmit, setError,
  } = useForm<AddEditInvoiceFields>({
    defaultValues: defaultAddInvoiceValues,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  useEffect(() => {
    if (id && type && pending) {
      dispatch(setInvoiceNominalCodesFilters({
        ...filters,
        orderIds: isSales ? [+id] : undefined,
        purchaseOrderIds: isSales ? undefined : [+id],
        types: [type],
        states: [pending],
      }));
    }
    // eslint-disable-next-line
  }, [id, type, pending]);

  useEffect(() => {
    const idsLength = isSales ? filters.orderIds?.length : filters.purchaseOrderIds?.length;
    const conditionToFetch = visible
      && !idToEdit
      && !!idsLength
      && filters.states?.length
      && filters.types?.length
      && filters.order?.field;
    conditionToFetch && dispatch(getInvoiceNominalCodesThunk({ filters }));
    // eslint-disable-next-line
  }, [visible, filters]);

  useEffect(() => {
    if (visible) {
      if (!idToEdit) {
        setValue('entityNumber', getEntityNumber());
        setValue('invoiceDate', moment().format(DEFAULT_DATE_FORMAT));
        setValue('dueDate', moment().add(1, 'month').endOf('month').format(DEFAULT_DATE_FORMAT));
        setValue('xeroRef', xeroRef);
      } else {
        const foundItem = items.find(({ id }) => id === idToEdit);
        if (foundItem) {
          setType(foundItem.type);
          setValue('entityNumber', foundItem.entityNumber);
          setValue('invoiceDate', foundItem.invoiceDate);
          setValue('dueDate', foundItem.dueDate);
          setValue('xeroRef', foundItem.xeroRef);
        }
      }
    }
    // eslint-disable-next-line
  }, [visible, isSales, isInvoice, idToEdit]);

  useEffect(() => {
    if (visible && !idToEdit) {
      id && dispatch(getNominalCodesSummary({
        filters: {
          orderId: isSales ? +id : undefined,
          purchaseOrderId: isSales ? undefined : +id,
          nominalRecordIds: checkedKeys,
        },
      }));
    }
    // eslint-disable-next-line
  }, [visible, checkedKeys]);

  const maxDatePickerValue = useMemo(() => {
    return moment().add({ month: 4, day: 1 }).startOf('day');
  }, []);

  const submitHandle = (values: AddEditInvoiceFields) => {
    const {
      dueDate, invoiceDate, entityNumber, xeroRef,
    } = values;
    const data = {
      entityNumber: isSales ? undefined : entityNumber,
      xeroRef,
      dueDate: moment(dueDate).format(DEFAULT_DATE_FORMAT),
      invoiceDate: moment(invoiceDate).format(DEFAULT_DATE_FORMAT),
    };
    const maxDate = maxDatePickerValue.format('DD-MMM-YYYY');

    if (idToEdit) {
      id && dispatch(editInvoicesAndCreditNotesThunk({
        id: idToEdit,
        data,
        setError,
        onFinish: onClose,
        isSales,
        entityId: +id,
        maxDate,
      }));
    } else {
      id && dispatch(addInvoicesAndCreditNotesThunk({
        data: { ...data, pendingNominalRecordIds: checkedKeys },
        id: +id,
        setError,
        onFinish: onClose,
        entityType: isInvoice ? 'Invoice' : 'Credit note',
        isSales,
        maxDate,
      }));
    }
  };

  const modalLabels = useMemo(() => {
    const titleAction = idToEdit ? 'Edit' : 'Add';
    const submitAction = idToEdit ? 'Save' : 'Add';
    const entity = isInvoice ? 'invoice' : 'credit note';
    return {
      title: `${titleAction} ${entity}`,
      submit: `${submitAction} ${entity} ${checkedKeysCount}`,
    };
  }, [idToEdit, isInvoice, checkedKeysCount]);

  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      title={modalLabels.title}
      width={1150}
      destroyOnClose
      className="addInvoiceModal"
      afterClose={() => {
        reset();
        dispatch(setInvoiceNominalCodesFilters(defaultPagingAndOrdering));
        dispatch(setInvoiceNominalCodes(initNominalCodesData));
        dispatch(setInvoiceNominalCodesSummary(null));
      }}
    >
      <div className="addInvoiceModal__fields">
        <Controller
          control={control}
          name="entityNumber"
          rules={{
            ...RequiredFieldSchema,
            minLength: { value: 3, message: rangeLengthMessageHandle([3, 100]) },
            maxLength: { value: 100, message: rangeLengthMessageHandle([3, 100]) },
          }}
          render={({ field }) => (
            <Input
              label={isInvoice ? 'Invoice number' : 'Credit note No'}
              value={field.value}
              onChange={field.onChange}
              error={errors.entityNumber?.message}
              disabled={isSales}
            />
          )}
        />
        <Controller
          control={control}
          name="invoiceDate"
          rules={{ ...RequiredFieldSchema }}
          render={({ field }) => (
            <DatePickerComponent
              label="Invoice date"
              value={field.value ? moment(field.value) : undefined}
              onChange={(v) => {
                if (v) {
                  const formatted = v.format(DEFAULT_DATE_FORMAT);
                  field.onChange(formatted);
                }
              }}
              disabledDate={(currentDate) => currentDate.isSameOrAfter(maxDatePickerValue)}
              format="DD-MMM-YYYY"
              error={errors.invoiceDate?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="dueDate"
          rules={{ ...RequiredFieldSchema }}
          render={({ field }) => (
            <DatePickerComponent
              label="Due date"
              value={field.value ? moment(field.value) : undefined}
              onChange={(v) => {
                if (v) {
                  const formatted = v.format(DEFAULT_DATE_FORMAT);
                  field.onChange(formatted);
                }
              }}
              disabledDate={(currentDate) => currentDate.isSameOrAfter(maxDatePickerValue)}
              format="DD-MMM-YYYY"
              error={errors.dueDate?.message}
            />
          )}
        />
        <Controller
          control={control}
          name="xeroRef"
          rules={{
            ...RequiredFieldSchema,
            maxLength: { value: 100, message: maxLengthMessageHandle(100) },
          }}
          render={({ field }) => (
            <Input
              label="Xero ref"
              value={field.value}
              onChange={field.onChange}
              error={errors.xeroRef?.message}
            />
          )}
        />
      </div>
      {!idToEdit && (
        <>
          <div className="actions-and-paging">
            <Pagination
              currentPage={filters.page}
              pageSize={filters.pageSize}
              totalItems={totalCount}
              pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
              onChange={(page, pageSize) => dispatch(setInvoiceNominalCodesFilters({ ...filters, page, pageSize }))}
              className="actions-and-paging__paging"
              narrow
            />
          </div>
          <AddInvoiceTable
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            checkAll={checkAllHandle}
            checkRow={checkRowHandle}
            checkedKeys={checkedKeys}
          />
          <AddInvoiceTotal isCreditNotesModalType={isCreditNotesModalType} />
        </>
      )}
      <ButtonActions
        createLabel={modalLabels.submit}
        createType="button"
        cancelClick={onClose}
        createClick={handleSubmit(submitHandle)}
        isLoading={isFetching}
        disabledCreate={idToEdit ? false : !checkedKeys.length}
        offsetTop
      />
    </Modal>
  );
};

export default AddInvoiceModal;
