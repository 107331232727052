import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import {
  DateRangeTableFilterType, PickerValues,
} from '../../../../../core/types/coreTypes';
import {
  setReportIsVisible,
  setUnfinishedEntitiesData, setUnfinishedEntitiesFilters,
} from '../../../../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  unfinishedEntitiesDatePickerFiltersListSelector,
  unfinishedEntitiesFiltersSelector, unfinishedEntitiesGlobalFiltersListSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { initUnfinishedEntitiesReportData } from '../../../reports-content/sales/sales-unfinished-entities/utils/data';
import {
  getUnfinishedEntitiesReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { useUnfinishedEntitiesDefaultOrdering } from '../../../../hooks/useUnfinishedEntitiesDefaultOrdering';
import { getUnfinishedEntityType } from '../../../../../store/thunks/coreThunk';

const UnfinishedEntitiesGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(unfinishedEntitiesFiltersSelector);
  const datePickerFilters = useAppSelector(unfinishedEntitiesDatePickerFiltersListSelector);
  const checkboxFilters = useAppSelector(unfinishedEntitiesGlobalFiltersListSelector);

  const [isExpanded, setIsExpanded] = useState(false);
  const initialPickerValue: DateRangeTableFilterType = { creationDate: [null, null] };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const defaultPagingAndOrdering = useUnfinishedEntitiesDefaultOrdering();

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        businessAreas, customers, assigneeIds, creationDate,
      } = filters;
      const hasSelectedCheckboxes = [businessAreas, customers, assigneeIds].some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = creationDate && (creationDate.min || creationDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker;
    }
    return false;
  }, [filters]);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values || undefined,
    };
    dispatch(setUnfinishedEntitiesFilters(newFilters));
    dispatch(setUnfinishedEntitiesData(initUnfinishedEntitiesReportData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setUnfinishedEntitiesFilters({
      ...filters,
      [field]: values,
    }));
    dispatch(setReportIsVisible(false));
    dispatch(setUnfinishedEntitiesData(initUnfinishedEntitiesReportData));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setUnfinishedEntitiesFilters(defaultPagingAndOrdering));
    dispatch(setUnfinishedEntitiesData(initUnfinishedEntitiesReportData));
    dispatch(setReportIsVisible(false));
  };

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    dispatch(getUnfinishedEntityType());

    return () => {
      dispatch(setUnfinishedEntitiesFilters(defaultPagingAndOrdering));
      dispatch(setUnfinishedEntitiesData(initUnfinishedEntitiesReportData));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button reportFilters__action-button--single"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getUnfinishedEntitiesReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
      </div>
    </>
  );
};

export default UnfinishedEntitiesGlobalFilters;
