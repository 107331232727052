import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  infiniteScrollInitPaging, initDataList, initDataListWithIds, initialCoreAttachmentsFilters, initLinksFilters,
} from '../../../core/types/coreTypes';
import {
  setRectificationAttachments,
  setRectificationAttachmentsFilters, setRectificationContacts, setRectificationContactsPaging,
  setRectificationLinks,
  setRectificationLinksFilters,
  setRectificationTimeline,
  setRectificationTimelineFilters,
} from '../../../store/slices/rectificationsSlice';
import { setRectificationDetails } from '../../../store/slices/sharedSlice';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const rectificationViewPageCleanupHandle = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setRectificationDetails(null));
  dispatch(setRectificationTimeline(initDataList));
  dispatch(setRectificationTimelineFilters(initExtendedTimelineFilters));
  dispatch(setRectificationAttachments([]));
  dispatch(setRectificationAttachmentsFilters(initialCoreAttachmentsFilters));
  dispatch(setRectificationLinksFilters(initLinksFilters));
  dispatch(setRectificationLinks([]));
  dispatch(setRectificationContacts(initDataListWithIds));
  dispatch(setRectificationContactsPaging(infiniteScrollInitPaging));
};
