import React, { useEffect } from 'react';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  reportIsVisibleSelector, salesOfficeEmployeeChartPagingSelector, salesOfficeEmployeeDataChartSelector,
  salesOfficeEmployeeDataSelector,
  salesOfficeEmployeeFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import Pagination from '../../../../../core/components/pagination/Pagination';
import SalesOfficeEmployeeTable from './table/SalesOfficeEmployeeTable';
import { setIsTableView, setSalesOfficeEmployeeChartPaging, setSalesOfficeEmployeeFilters } from '../../../../../store/slices/reportsSlice';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import Switch from '../../../../../core/components/switch/Switch';
import {
  getSalesOfficeEmployeeReportChartThunk,
  getSalesOfficeEmployeeReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import SalesOfficeEmployeeChart from './chart/SalesOfficeEmployeeChart';
import Button from '../../../../../core/components/button/Button';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';
import { initPaging } from '../../../../../core/types/coreTypes';

const SalesOfficeEmployeeContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(salesOfficeEmployeeFiltersSelector);
  const { totalCount } = useAppSelector(salesOfficeEmployeeDataSelector);
  const chartPaging = useAppSelector(salesOfficeEmployeeChartPagingSelector);
  const { pages } = useAppSelector(salesOfficeEmployeeDataChartSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const isTableView = useAppSelector(isTableViewSelector);
  const isLoading = useAppSelector(isGlobalLoadingSelector);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orderNumber, salesNet, daysInNewStatus, page, pageSize, order, ...chartFilters
  } = filters;

  useEffect(() => {
    if (reportIsVisible && !isTableView) {
      dispatch(getSalesOfficeEmployeeReportChartThunk({
        filters: {
          ...chartFilters,
          page: chartPaging.page,
          pageSize: chartPaging.pageSize,
        },
      }));
    }
    // eslint-disable-next-line
  }, [chartPaging.page]);

  return (
    <>
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Switch
            checked={!isTableView}
            onChange={(val) => {
              if (val) {
                reportIsVisible && dispatch(getSalesOfficeEmployeeReportChartThunk({ filters: { ...chartFilters, ...initPaging } }));
              } else {
                reportIsVisible && dispatch(getSalesOfficeEmployeeReportThunk({ filters }));
              }
              dispatch(setIsTableView(!val));
            }}
            textLeft="Table view"
            textRight="Chart view"
            singleBackground
          />
        </div>
        {isTableView
          ? <Pagination
            currentPage={filters.page}
            pageSize={filters.pageSize}
            totalItems={totalCount}
            onChange={(page, pageSize) => {
              const newFilters = { ...filters, page, pageSize };
              dispatch(setSalesOfficeEmployeeFilters(newFilters));
              reportIsVisible && dispatch(getSalesOfficeEmployeeReportThunk({ filters: newFilters }));
            }}
            className="actions-and-paging__paging"
            pageSizeOptions={BIG_TABLES_PAGE_SIZES}
            narrow
          />
          : <div>
            <Button
              onClick={() => dispatch(setSalesOfficeEmployeeChartPaging({ ...chartPaging, page: chartPaging.page + 1 }))}
              icon={<FontAwesomeIcon icon={faArrowLeft} />}
              disabled={!reportIsVisible || isLoading || chartPaging.page === pages}
              offsetRight
            />
            <Button
              onClick={() => dispatch(setSalesOfficeEmployeeChartPaging({ ...chartPaging, page: chartPaging.page - 1 }))}
              icon={<FontAwesomeIcon icon={faArrowRight} />}
              disabled={!reportIsVisible || isLoading || chartPaging.page === 1}
            />
          </div>}
      </div>
      {isTableView
        ? <SalesOfficeEmployeeTable />
        : <SalesOfficeEmployeeChart />}
    </>
  );
};

export default SalesOfficeEmployeeContent;
