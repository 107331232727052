import React from 'react';
import FieldUpdateEvent from '../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { fieldNameEventHandle, getReadableBoolean, getValuesFromJsonArray } from '../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../store/hooks';
import { jobTypesSelector, shortBusinessAreasSelector } from '../../../../../store/selectors/coreSelectors';

type UserFieldUpdateEventProps = {
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}
const UserFieldUpdateEvent: React.FC<UserFieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const jobTypes = useAppSelector(jobTypesSelector);
  const businessAreas = useAppSelector(shortBusinessAreasSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'AllowedJobTypes':
        return getValuesFromJsonArray(value as string, jobTypes);
      case 'AllowedBusinessAreas':
        return getValuesFromJsonArray(value as string, businessAreas);
      case 'CanSeeOnlyAssignedEntities':
        return getReadableBoolean(value);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default UserFieldUpdateEvent;
