import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { faEnvelope, faFilePdf } from '@fortawesome/free-regular-svg-icons';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import Layout from '../../core/components/layout/Layout';
import { useOrderParams } from './hooks/useOrderParams';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import { orderDetailsSelector } from '../../store/selectors/sharedSelectors';
import {
  orderAffectedEntitiesCellsSelector,
  orderContactsPagingSelector,
  orderContactsSelector,
  partRequestLinesFiltersSelector,
} from '../../store/selectors/ordersSelector';
import OrderHead from './components/head/OrderHead';
import OrdersContent from './components/content/OrdersContent';
import OrderContentTabs from './components/content-tabs/OrderContentTabs';
import './OrdersViewPageContainer.scss';
import usePermission from '../../permissions-handling/permissionHook';
import { orderViewPageCleanUpHandle } from './utils/orderViewPageCleanUpHandle';
import { orderViewPageDictionariesHandle } from './utils/orderViewPageDictionariesHandle';
import { getOrderPartRequestLines } from '../../store/thunks/orders/orderPartRequestThunks';
import { vehicleDictionariesHandle } from '../../common/utils/vehicleDictionariesHandle';
import RejectStatusModal from '../../common/components/entity-status-change-modals/RejectStatusModal';
import {
  changeOrderCustomerThunk,
  getOrderAffectedEntities,
  putOrderStatusThunk,
} from '../../store/thunks/orders/viewPageOrderThunks';
import { initSmallPaging, VoidFunctionType } from '../../core/types/coreTypes';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import { OrderModes, OrderViewPageEnums } from './enums/orderViewPageEnums';
import { setPartRequestLinesFilters } from '../../store/slices/ordersSlice';
import { orderStatusesValuesSelector } from '../../store/selectors/coreStatusesSelectors';
import { stockPartRequestLinesOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getOrderContactsThunk } from '../../store/thunks/orders/orderContactsThunks';
import OrderGenerateEmailModal from './components/content/details/OrderGenerateEmailModal';
import ChangeCustomerModal from '../../common/components/change-customer/ChangeCustomerModal';
import { setErrorMessage } from '../../store/slices/coreSlice';
import ExportPdfModal from './components/content/export-pdf-modal/ExportPdfModal';

const OrdersViewPageContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const allowedToEdit = usePermission(PermissionEnum.OrderEditFields);
  const linesOrdering = useAppSelector(stockPartRequestLinesOrderingSelector);
  const {
    backHandle,
    options,
    setModeParam,
    params,
    setSearchParams,
  } = useOrderParams();
  const currentUser = useAppSelector(currentUserSelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const partRequestLinesFilters = useAppSelector(partRequestLinesFiltersSelector);
  const {
    rejectedByCustomer, invoicing, completed, cancelled,
  } = useAppSelector(orderStatusesValuesSelector);
  const isCurrentStatusArchived = [invoicing, completed, cancelled].includes(orderDetails?.order?.status);
  const orderContactsPaging = useAppSelector(orderContactsPagingSelector);
  const affectedEntities = useAppSelector(orderAffectedEntitiesCellsSelector);
  const { totalCount, items } = useAppSelector(orderContactsSelector);
  const [generateEmailModal, setGenerateEmailModal] = useState(false);
  const [changeCustomerModal, setChangeCustomerModal] = useState(false);
  const [exportPdfModal, setExportPdfModal] = useState(false);

  const orderId = orderDetails?.order.id;
  const vehicleCustomerId = orderDetails?.order?.vehicleCustomerId;
  const partRequestLinesCount = orderDetails?.order.partRequestLinesCount;
  const disabledGenerateEmailAction = !partRequestLinesCount || (totalCount === 0) || !items.find((el) => el.email);

  const getAffectedItems = (page: number, pageSize: number) => {
    orderId && dispatch(getOrderAffectedEntities({
      orderId,
      page,
      pageSize,
      action: () => setChangeCustomerModal(true),
    }));
  };

  const actions = [
    {
      label: 'Change customer',
      key: 'change-customer',
      icon: <FontAwesomeIcon icon={faPen} />,
      onClick: () => getAffectedItems(initSmallPaging.page, initSmallPaging.pageSize),
      disabled: isCurrentStatusArchived,
      restrictedTo: PermissionEnum.OrderChangeCustomer,
    },
    {
      label: 'Export to PDF',
      key: 'export-to-pdf',
      icon: <FontAwesomeIcon icon={faFilePdf} />,
      onClick: () => setExportPdfModal(true),
      disabled: !partRequestLinesCount,
      restrictedTo: PermissionEnum.OrderExportToPdf,
    },
    {
      label: 'Generate email',
      key: 'generate-email',
      icon: <FontAwesomeIcon icon={faEnvelope} />,
      onClick: () => setGenerateEmailModal(true),
      disabled: disabledGenerateEmailAction,
      restrictedTo: PermissionEnum.OrderExportToPdf,
    },
  ];

  const [isInvoicingConfirmation, setInvoicingConfirmation] = useState(false);
  const [isCancellationConfirmation, setCancellationConfirmation] = useState(false);
  const [isRejectStatus, setIsRejectStatus] = useState(false);

  const confirmRejectedStatusChange = (status: number, reason: string, onClose: VoidFunctionType) => {
    orderDetails?.order?.id && rejectedByCustomer && dispatch(putOrderStatusThunk({
      id: orderDetails?.order?.id,
      statusTransition: status,
      onCloseModal: onClose,
      rejectionReason: reason,
    }));
  };
  const confirmInvoicingStatusChange = () => {
    orderDetails?.order?.id && invoicing && dispatch(putOrderStatusThunk({
      id: orderDetails?.order?.id,
      statusTransition: invoicing,
      onCloseModal: () => setInvoicingConfirmation(false),
    }));
  };
  const confirmCancelledStatusChange = () => {
    orderDetails?.order?.id && cancelled && dispatch(putOrderStatusThunk({
      id: orderDetails?.order?.id,
      statusTransition: cancelled,
      onCloseModal: () => setCancellationConfirmation(false),
    }));
  };

  useEffect(() => {
    if (linesOrdering.length > 0 && !partRequestLinesFilters?.order) {
      dispatch(setPartRequestLinesFilters({
        ...partRequestLinesFilters,
        order: { field: linesOrdering[0].value, isAsc: true },
      }));
    }
    // eslint-disable-next-line
  }, [linesOrdering, partRequestLinesFilters?.order]);

  useEffect(() => {
    const partRequestId = orderDetails?.order?.partRequestId;
    if (partRequestId && partRequestLinesFilters?.order) {
      dispatch(getOrderPartRequestLines({
        partRequestId,
        filters: partRequestLinesFilters,
      }));
    }
    // eslint-disable-next-line
  }, [orderDetails?.order?.partRequestId, partRequestLinesFilters]);

  useEffect(() => {
    if (currentUser) {
      const modeParam = params.get('mode');
      const { permissions } = currentUser.userContextRole;
      if (!modeParam && permissions.includes(PermissionEnum.OrderViewPage)) {
        setSearchParams({ mode: options[0].value }, { replace: true });
      } else {
        if (!OrderModes.includes(modeParam as OrderViewPageEnums)) {
          setSearchParams({ mode: OrderViewPageEnums.ORDER_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, orderDetails]);

  useEffect(() => {
    orderId && dispatch(getOrderContactsThunk({ orderId, ...orderContactsPaging }));
    // eslint-disable-next-line
  }, [orderContactsPaging]);

  useEffect(() => {
    orderViewPageDictionariesHandle(dispatch);
    vehicleDictionariesHandle(dispatch);

    return () => {
      orderViewPageCleanUpHandle(dispatch);
    };
    // eslint-disable-next-line
  }, []);

  const changeCustomerHandle = (customerId: number) => {
    orderId && dispatch(changeOrderCustomerThunk({
      orderId,
      customerId,
      closeModal: () => {
        if (customerId !== vehicleCustomerId) {
          dispatch(setErrorMessage({ message: 'Order customer and vehicle customer do not match.', toastId: Math.random() }));
        }
        setChangeCustomerModal(false);
      },
    }));
  };

  return (
    <Layout
      subHeader={<DetailsHead
        goBack={backHandle}
        actions={actions}
        className="ordersViewPageDetailsHead"
      >
        <OrderHead />
      </DetailsHead>}
    >
      <ExportPdfModal visible={exportPdfModal} onClose={() => setExportPdfModal(false)} />
      <ChangeCustomerModal
        visible={changeCustomerModal}
        onCancel={() => setChangeCustomerModal(false)}
        changeThunk={(customerId) => changeCustomerHandle(customerId)}
        // eslint-disable-next-line max-len
        note="Note that after changing a customer all order contacts and billing/shipping details will be updated. This update will also affect all active linked entities (rectifications, jobs, part requests)."
        filters={{ orderIdCanBeAssigned: orderId }}
        affectedItems={affectedEntities}
        getAffectedItems={getAffectedItems}
      />
      <RejectStatusModal
        isVisible={isRejectStatus}
        onCancel={() => setIsRejectStatus(false)}
        onConfirm={confirmRejectedStatusChange}
        rejectionStatus={rejectedByCustomer}
        customTitle="Move to Rejected by customer"
      />
      <ConfirmationModal
        isVisible={isCancellationConfirmation || isInvoicingConfirmation}
        onCancel={() => {
          isCancellationConfirmation && setCancellationConfirmation(false);
          isInvoicingConfirmation && setInvoicingConfirmation(false);
        }}
        customTitle="Confirm"
        customText={isInvoicingConfirmation
          ? 'You are about to move this order into "Invoicing" status and after this it will be locked for editing. '
        + 'This operation cannot be undone.'
          : 'You are about to mark this order as "Cancelled" and all part lines will be removed from this order. '
            + 'This operation cannot be undone.'}
        customCreateLabel={isInvoicingConfirmation ? 'Move to Invoicing' : 'Mark as cancelled'}
        confirmHandler={isInvoicingConfirmation ? confirmInvoicingStatusChange : confirmCancelledStatusChange}
        className="orderStatusChangeConfirmationModal"
      />
      <OrderGenerateEmailModal
        visible={generateEmailModal}
        setVisible={setGenerateEmailModal}
      />
      <OrderContentTabs
        details={orderDetails?.order}
        mode={params.get('mode')}
        setModeParam={setModeParam}
        allowedTabs={options}
        allowedToEdit={allowedToEdit}
        setIsRejectStatus={setIsRejectStatus}
        setInvoicingConfirmation={setInvoicingConfirmation}
        setCancellationConfirmation={setCancellationConfirmation}
        isCurrentStatusArchived={isCurrentStatusArchived}
      />
      <OrdersContent mode={params.get('mode')} />
    </Layout>
  );
};

export default OrdersViewPageContainer;
