import React, { useState } from 'react';
import { setAssignedNominalCodesFilters, setNominalCodesFilters } from '../../../../store/slices/invoicingSlice';
import AssignNominalCodesModal from '../../../common/components/assign-nominal-codes/AssignNominalCodesModal';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { nominalRecordTypesValuesSelector } from '../../../../store/selectors/coreSelectors';
import {
  assignedNominalCodesFiltersSelector, isInvoicedAmountGreaterThanOrderSelector,
  nominalCodesFiltersSelector, salesNominalCodesTooltipsSelector,
  salesOrderDetailsSelector,
  salesOrderPartRequestLinesSelector,
  salesOrderPartRequestTableLinesSelector, salesPartRequestLineSummarySelector,
} from '../../../../store/selectors/invoicingSelectors';
import { deleteNominalRecordThunk } from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import OrderLinesBody from '../../../common/components/order-lines-common/OrderLinesBody';
import AddNominalCodeModal from '../../../common/components/add-nominal-record/AddNominalCodeModal';

const SalesOrderLinesBody: React.FC = () => {
  const dispatch = useAppDispatch();
  const { invoice } = useAppSelector(nominalRecordTypesValuesSelector);
  const nominalCodesFilters = useAppSelector(nominalCodesFiltersSelector);
  const filters = useAppSelector(assignedNominalCodesFiltersSelector);

  const initLinesData = useAppSelector(salesOrderPartRequestLinesSelector);
  const salesOrderDetails = useAppSelector(salesOrderDetailsSelector);
  const salesPartRequestLineSummary = useAppSelector(salesPartRequestLineSummarySelector);
  const isInvoicedAmountGreaterThanOrder = useAppSelector(isInvoicedAmountGreaterThanOrderSelector);
  const salesNominalCodesTooltips = useAppSelector(salesNominalCodesTooltipsSelector);
  const lines = useAppSelector(salesOrderPartRequestTableLinesSelector);
  const [affectedId, setAffectedId] = useState<number | undefined>(undefined);
  const [isAssignNominalCodes, setIsAssignNominalCodes] = useState(false);
  const [isAddNominalCode, setIsAddNominalCode] = useState(false);
  const [affectedNominalRecord, setAffectedNominalRecord] = useState<number | undefined>(undefined);
  const [isDeleteRecord, setIsDeleteRecord] = useState<boolean>(false);

  const affectedPartParentLine = initLinesData?.items?.find((line) => line.id === affectedId);
  const allChildren = initLinesData?.items
    .filter((el) => el.childLines && el.childLines.length)
    .map((el) => el.childLines)
    .flat();
  const affectedPartChildLine = allChildren?.find((line) => line?.id === affectedId);
  const affectedPartLine = affectedPartParentLine || affectedPartChildLine;
  const affectedPartLineProperties = affectedPartLine
    ? {
      partNumber: affectedPartLine.productNumber,
      partName: affectedPartLine.productName,
      categoryName: affectedPartLine.categoryName,
      subcategoryName: affectedPartLine.subcategoryName,
    }
    : undefined;

  return (
    <>
      <AssignNominalCodesModal
        isSales
        partRequestId={salesOrderDetails?.order.partRequestId}
        isVisible={isAssignNominalCodes}
        onCancel={() => setIsAssignNominalCodes(false)}
        setIsAddNominalCode={(v) => {
          invoice && dispatch(setNominalCodesFilters({ ...nominalCodesFilters, types: [invoice] }));
          setIsAddNominalCode(v);
        }}
        deleteConfirmationHandler={(id) => {
          setIsDeleteRecord(true);
          setAffectedNominalRecord(id);
        }}
        editRecordHandler={(id) => {
          setIsAddNominalCode(true);
          setAffectedNominalRecord(id);
        }}
        affectedLine={affectedPartLineProperties}
        summarySectionData={salesPartRequestLineSummary}
        isInvoicedAmountGreaterThanReceived={isInvoicedAmountGreaterThanOrder}
      />
      <AddNominalCodeModal
        isSales
        isVisible={isAddNominalCode}
        affectedId={affectedId}
        onCancel={() => {
          setIsAddNominalCode(false);
          affectedNominalRecord && setAffectedNominalRecord(undefined);
        }}
        affectedNominalRecord={affectedNominalRecord}
        editMode={!!affectedNominalRecord}
      />
      <ConfirmationModal
        isVisible={isDeleteRecord}
        onCancel={() => {
          setIsDeleteRecord(false);
          setAffectedNominalRecord(undefined);
        }}
        confirmHandler={() => {
          affectedNominalRecord && dispatch(deleteNominalRecordThunk({
            recordId: affectedNominalRecord,
            closeModal: () => {
              setIsDeleteRecord(false);
              setAffectedNominalRecord(undefined);
            },
            isSales: true,
            affectedId,
          }));
        }}
      />
      <OrderLinesBody
        isSales
        lines={lines}
        nominalCodesTooltips={salesNominalCodesTooltips}
        openNominalCodesModal={(id: number) => {
          dispatch(setAssignedNominalCodesFilters({ ...filters, partRequestLineIds: [id] }));
          setIsAssignNominalCodes(true);
          setAffectedId(id);
        }}
      />
    </>
  );
};

export default SalesOrderLinesBody;
