import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseSingleResult, VoidFunctionType } from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import {
  EmployeeEmploymentDto,
} from '../../../employees/employee-view-page/types/employeeViewTypes';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  CreateEmployeeEmploymentRestModel, PatchEmployeeEmploymentRestModel,
} from '../../../employees/employee-view-page/utils/AddEmploymentSchema';

export const getEmployeeEmploymentsThunk = createAsyncThunk<
ResponseSingleResult<{ employments: EmployeeEmploymentDto[] }>,
{ id: number }
>(
  'get/EmployeeEmployments',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeEmployments(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEmployeeEmploymentThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, referenceNumber: number }>,
{
  employeeId: number,
  data: CreateEmployeeEmploymentRestModel,
  closeModal: VoidFunctionType,
}
>(
  'post/EmployeeEmployments',
  async ({
    employeeId, data, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.postEmployeeEmployments(employeeId, data);
      dispatch(getEmployeeEmploymentsThunk({ id: employeeId }));
      closeModal();
      dispatch(setSuccessMessage({ message: 'Employment record was successfully created' }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEmployeeEmploymentThunk = createAsyncThunk<
ResponseSingleResult,
{
  employmentId: number,
  employeeId: number,
  employment: PatchEmployeeEmploymentRestModel,
  closeModal: VoidFunctionType,
}
>(
  'patch/EmployeeEmployments',
  async ({
    employmentId, employeeId, employment, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.patchEmployeeEmployment(employmentId, employment);
      dispatch(getEmployeeEmploymentsThunk({ id: employeeId }));
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeEmploymentThunk = createAsyncThunk<
ResponseSingleResult,
{ employmentId: number, employeeId: number, closeModal: VoidFunctionType }
>(
  'delete/EmployeeEmployments',
  async ({
    employmentId, employeeId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.deleteEmployeeEmployment(employmentId);
      dispatch(getEmployeeEmploymentsThunk({ id: employeeId }));
      dispatch(setFetching(false));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
