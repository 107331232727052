import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { GetNominalCodesResponse, NominalCodeFields, NominalCodesFilters } from '../../../invoicing/nominal-codes/types/nominalCodesTypes';
import { setFetching, setLoading } from '../../slices/coreSlice';
import { invoicingAPI } from '../../../api/invoicingApi';
import { RootState } from '../../store';
import { setNominalCodesFilters } from '../../slices/invoicingSlice';
import { DuplicateValue, ErrorsEnum } from '../../../core/enums/errorsEnum';

export const getNominalCodesThunk = createAsyncThunk<GetNominalCodesResponse,
{ filters: NominalCodesFilters }
>(
  'get/NominalCodes',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await invoicingAPI.fetchNominalCodes(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteNominalCodeThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/NominalCode',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await invoicingAPI.deleteNominalCode(id);
      dispatch(setFetching(false));
      const { invoicing: { nominalCodesFilters } } = getState() as RootState;
      if ((nominalCodesFilters.page || 1) > 1) {
        dispatch(setNominalCodesFilters({ ...nominalCodesFilters, page: 1 }));
      } else {
        dispatch(getNominalCodesThunk({ filters: nominalCodesFilters }));
      }
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const createNominalCodeThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>,
{
  data: NominalCodeFields,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<NominalCodeFields>,
}
>(
  'create/NominalCode',
  async ({
    data, closeModal, setError,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await invoicingAPI.postNominalCode(data);
      dispatch(setFetching(false));
      const { invoicing: { nominalCodesFilters } } = getState() as RootState;
      if ((nominalCodesFilters.page || 1) > 1) {
        dispatch(setNominalCodesFilters({ ...nominalCodesFilters, page: 1 }));
      } else {
        dispatch(getNominalCodesThunk({ filters: nominalCodesFilters }));
      }
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      if (errors.find((el) => el.key === 'DuplicateNominalCode')) {
        setError('code', { type: DuplicateValue, message: ErrorsEnum.UNIQUE_VALUE });
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editNominalCodeThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  data: NominalCodeFields,
  closeModal: VoidFunctionType,
  setError: UseFormSetError<NominalCodeFields>,
}
>(
  'put/NominalCode',
  async ({
    id, data, closeModal, setError,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await invoicingAPI.putNominalCode(id, data);
      dispatch(setFetching(false));
      const { invoicing: { nominalCodesFilters } } = getState() as RootState;
      if ((nominalCodesFilters.page || 1) > 1) {
        dispatch(setNominalCodesFilters({ ...nominalCodesFilters, page: 1 }));
      } else {
        dispatch(getNominalCodesThunk({ filters: nominalCodesFilters }));
      }
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      if (errors.find((el) => el.key === 'DuplicateNominalCode')) {
        setError('code', { type: DuplicateValue, message: ErrorsEnum.UNIQUE_VALUE });
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
