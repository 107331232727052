import React, { useEffect } from 'react';
import { faVanShuttle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../core/components/pagination/Pagination';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  vehiclesDashboardFiltersSelector,
  vehiclesListUiSelector,
} from '../../store/selectors/vehiclesSelectors';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { setVehiclesDashboardFilters, setVehiclesList } from '../../store/slices/vehiclesSlice';
import VehicleItem from './components/VehicleItem';
import EmptyContentList from '../../core/components/empty-content-list/EmptyContentList';
import { vehiclesOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { getVehiclesListThunk } from '../../store/thunks/vehicles/vehiclesDashboardThunks';
import { VehiclesOrdering } from './types/VehiclesDashdoardTypes';
import { getCustomersDictionaryThunk } from '../../store/thunks/shared/sharedThunks';
import SkeletonLinesList from '../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import VehicleSkeletonItem from './components/skeleton/VehicleSkeletonItem';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { initDataList } from '../../core/types/coreTypes';
import { isDashboardLoadingSelector } from '../../store/selectors/coreSelectors';
import { useCancelRequest } from '../../core/hooks/useCancelRequest';

const VehiclesList: React.FC = () => {
  const dispatch = useAppDispatch();
  const vehiclesOrdering = useAppSelector(vehiclesOrderingSelector);
  const filters = useAppSelector(vehiclesDashboardFiltersSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);
  const { items, totalCount } = useAppSelector(vehiclesListUiSelector);

  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    if (filters.order?.field) {
      cancelRequest();
      dispatch(getVehiclesListThunk({
        filters, isDashboard: true, signal: controller.current?.signal,
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = vehiclesOrdering.find((el) => el.label === VehiclesOrdering.CreationDate)?.value;
      defaultOrdering && dispatch(setVehiclesDashboardFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
    }
    // eslint-disable-next-line
  }, [vehiclesOrdering]);

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    return () => {
      dispatch(setVehiclesList(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          totalItems={totalCount}
          pageSize={filters.pageSize}
          currentPage={filters.page}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => dispatch(setVehiclesDashboardFilters({ ...filters, page, pageSize }))}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      {isLoading
        ? <SkeletonLinesList>
          <VehicleSkeletonItem />
        </SkeletonLinesList>
        : items.length > 0
          ? <ul className="lines-list">
            {items.map((el) => (
              <li key={el.id} className="lines-list__item">
                <VehicleItem data={el} />
              </li>
            ))}
          </ul>
          : <EmptyContentList icon={faVanShuttle} text="No vehicles" />}

    </>
  );
};

export default VehiclesList;
