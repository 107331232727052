import { CreateRectificationReqType } from './createRectificationTypes';

export type RectificationFormFields = Pick<CreateRectificationReqType, 'description'> & {
  orderId?: number,
  vehicleId?: number,
  businessArea?: number,
  customer?: string,
  priorityLevel?: number,
  jobType?: number,
  dueDate?: string,
  rectificationType?: number,
  faultType?: number,
  assigneeId?: number,
}

export const defaultRectificationFields: RectificationFormFields = {
  orderId: undefined,
  vehicleId: undefined,
  businessArea: undefined,
  customer: '',
  priorityLevel: undefined,
  jobType: undefined,
  dueDate: undefined,
  description: '',
  rectificationType: undefined,
  faultType: undefined,
  assigneeId: undefined,
};
