import React from 'react';
import { CustomerActivityEventDto } from '../../../../types/customerVewPageTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { customerEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import { CustomerEventEnum } from '../../../../enums/customerEventEnum';
import CustomerNoteEvent from './CustomerNoteEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import ContactEvents from '../../../../../../common/components/activity-events/contact/ContactEvents';
import BillingShippingEvents from '../../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { customerStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import CustomerFieldUpdateEvent from './CustomerFieldUpdateEvent';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';

type CustomerEventProps = {
  item: CustomerActivityEventDto;
}

const CustomerEvent: React.FC<CustomerEventProps> = ({ item }) => {
  const customerEventTypes = useAppSelector(customerEventTypesSelector);
  const customerStatuses = useAppSelector(customerStatusesSelector);

  const attachmentEvents = [
    CustomerEventEnum.AddingAttachmentWithoutComment,
    CustomerEventEnum.AddingAttachmentWithComment,
    CustomerEventEnum.EditingAttachmentComment,
    CustomerEventEnum.DeletingAttachment,
  ];
  const contactEvents = [
    CustomerEventEnum.AddingContact,
    CustomerEventEnum.ContactChange,
    CustomerEventEnum.RemovingContact,
  ];
  const billingShippingEvents = [
    CustomerEventEnum.BillingDetailsUpdates,
    CustomerEventEnum.ShippingDetailsUpdates,
  ];

  const customerEventRenderFn = (item: CustomerActivityEventDto) => {
    const event = customerEventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case event === CustomerEventEnum.Note:
        return <CustomerNoteEvent id={item.objectId} content={item.content} initiator={item.initiator} />;
      case event === CustomerEventEnum.CustomerCreation:
        return <CreationEvent entity="Customer" type="name" value={item.name} />;
      case event === CustomerEventEnum.CustomerDeletion:
        return <DeletionEvent entity="Customer" />;
      case event === CustomerEventEnum.CustomerFieldUpdateOrAddingValue:
        return <CustomerFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === CustomerEventEnum.CustomerStatusChange: {
        const prevStatus = customerStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = customerStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case billingShippingEvents.includes(event as CustomerEventEnum):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case attachmentEvents.includes(event as CustomerEventEnum):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case contactEvents.includes(event as CustomerEventEnum):
        return <ContactEvents
          eventName={event}
          contact={item.contact}
          firstName={event === CustomerEventEnum.AddingContact ? item.contact?.firstName : item.firstName}
          lastName={event === CustomerEventEnum.AddingContact ? item.contact?.lastName : item.lastName}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      default: return null;
    }
  };

  return <>{customerEventRenderFn(item)}</>;
};

export default CustomerEvent;
