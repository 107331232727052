import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading } from '../../slices/coreSlice';
import { jobsAPI } from '../../../api/jobsApi';
import {
  BulkPatchJobDto,
  JobCalendarDictionaryFilters,
  JobCalendarFilters,
  JobCalendarUserDto, JobDictionaryDto,
} from '../../../job/jobs-calendar/types/jobCalendarTypes';
import { RootState } from '../../store';

export const getJobCalendarThunk = createAsyncThunk<ResponseResult<JobCalendarUserDto[]>,
{ filters: JobCalendarFilters }
>(
  'get/JobsCalendar',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobCalendar(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobCalendarDictionaryThunk = createAsyncThunk<ResponseSingleResult<{ jobs: JobDictionaryDto[] }>,
{ filters: JobCalendarDictionaryFilters, openModal: VoidFunctionType, }
>(
  'get/JobCalendarDictionary',
  async ({ filters, openModal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobCalendarDictionary(filters);
      dispatch(setLoading(false));
      openModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchJobsThunk = createAsyncThunk<ResponseSingleResult,
{ jobs: Partial<BulkPatchJobDto>[], closeModal: VoidFunctionType, }
>(
  'patch/Jobs',
  async ({ jobs, closeModal }, { getState, dispatch, rejectWithValue }) => {
    const { jobs: { jobCalendarFilters } } = getState() as RootState;
    dispatch(setFetching(true));
    try {
      const response = await jobsAPI.patchJobs(jobs);
      dispatch(setFetching(false));
      dispatch(getJobCalendarThunk({ filters: jobCalendarFilters }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
