import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import axios from 'axios';
import { setCurrentServerError } from '../store/slices/coreSlice';
import { removeTokenHandler } from '../core/utils/removeTokenHandler';
import { RootState } from '../store/store';

const instance = axios.create({
  baseURL: '/api',
  headers: {},
});

export const configureInterceptors = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>, getState: () => RootState) => {
  instance.interceptors.request.use((config) => {
    const { core: { authToken } } = getState();
    if (config.headers) {
      if (authToken) {
        config.headers.Authorization = `Bearer ${authToken}`;
      } else {
        delete config.headers.authorization;
        return config;
      }
    }
    return config;
  });

  instance.interceptors.response.use(
    (res) => {
      return res;
    },
    // eslint-disable-next-line consistent-return
    (err) => {
      if (axios.isCancel(err)) {
        return Promise.reject(err);
      }
      const httpCode = err.response.status;
      const stringifiedCode = err.response.data.status;
      if (httpCode === 401) {
        removeTokenHandler(dispatch);
      }
      if (httpCode === 400) {
        return Promise.reject(err);
      } else {
        dispatch(setCurrentServerError({ status: stringifiedCode, httpCode }));
        return Promise.reject(err);
      }
    },
  );
};

export default instance;
