import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import Input from '../../../../../core/components/input/Input';
import { BooleanFunctionType } from '../../../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { customerAccountTypesSelector, customerTypesSelector } from '../../../../../store/selectors/coreSelectors';
import RequisiteSection from '../../../../../common/components/requisite/RequisiteSection';
import { patchCustomerThunk } from '../../../../../store/thunks/customers/customersViewPageThunks';
import { CustomerDto, GetCustomerResponseType } from '../../../../customers-dashboard/types/customersDashboardTypes';
import EditCustomerConfirmationModal from './EditCustomerConfirmationModal';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import { setCustomerDetailsValues } from '../../../utils/setCustomerDetailsValues';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { customerDetailsRequisitesSetSelector } from '../../../../../store/selectors/customersSelectors';
import {
  AccountNumberSchema, CompanyRegNoSchema,
  CustomerFields,
  customerDefaults,
  NameSchema, VatNumberSchema,
} from '../../../../create-customer/utils/CustomerSchema';
import { YesNoOptions } from '../../../../../core/utils/testData';

type CustomerDetailsProps = {
  setIsVisible: BooleanFunctionType,
  setIsShippingMode: BooleanFunctionType,
  customerDetails: GetCustomerResponseType | null,
  isSame: boolean,
  setIsSame: BooleanFunctionType,
}

const CustomerDetails: React.FC<CustomerDetailsProps> = ({
  setIsVisible, setIsShippingMode, customerDetails, isSame, setIsSame,
}) => {
  const allowedToEdit = usePermission(PermissionEnum.CustomerEditFields);
  const dispatch = useAppDispatch();
  const customerTypes = useAppSelector(customerTypesSelector);
  const accountTypes = useAppSelector(customerAccountTypesSelector);
  const allowedToEditAccountType = usePermission(PermissionEnum.CustomerEditAccountType);
  const linkedEntities : Record<string, number> = {
    enquiries: customerDetails?.customer.enquiriesAmount || 0,
    jobs: customerDetails?.customer.jobsAmount || 0,
    rectificationsAmount: customerDetails?.customer.rectificationsAmount || 0,
    ordersAmount: customerDetails?.customer.ordersAmount || 0,
  };
  const { billing, shipping } = useAppSelector(customerDetailsRequisitesSetSelector);
  const [isConfirm, setConfirm] = useState(false);
  const [fieldName, setFieldName] = useState<'name' | 'customerType' | 'customerAccountNumber' | undefined>(undefined);
  const {
    control, setValue, watch, setError, clearErrors,
  } = useForm<CustomerFields>({
    defaultValues: customerDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  useEffect(() => {
    if (customerDetails) {
      setCustomerDetailsValues(setValue, customerDetails.customer);
      setIsSame(!!customerDetails.customer.isShippingRequisiteSameAsBilling);
    }
    // eslint-disable-next-line
  }, [customerDetails]);

  const patchCustomerInfo = (data: Partial<CustomerDto>, key: keyof CustomerDto, isOptional?: boolean) => {
    if (customerDetails) {
      const { customer } = customerDetails;
      const handler = () => {
        dispatch(patchCustomerThunk({
          id: customer.id, data, setError,
        }));
      };
      compareValuesHandle<CustomerDto>(data, key, customer, handler, false, isOptional);
    }
  };
  const onCheck = (val: boolean) => {
    if (val) {
      customerDetails && dispatch(patchCustomerThunk({
        id: customerDetails?.customer.id,
        data: { isShippingRequisiteSameAsBilling: true },
      }));
    } else {
      setIsShippingMode(true);
      setIsVisible(true);
    }
    setIsSame(val);
  };

  const fieldsToValidate = ['name', 'customerAccountNumber', 'vatNumber', 'companyRegistrationNumber'];
  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, customerDetails?.customer, setValue);
  };
  const hasLinkedEntities = () => Object.keys(linkedEntities).some((key) => linkedEntities[key] > 0);
  return (
    <>
      <EditCustomerConfirmationModal
        isVisible={isConfirm}
        onClose={() => setConfirm(false)}
        onCancel={() => {
          setConfirm(false);
          setFieldName(undefined);
          setValue('name', customerDetails?.customer.name || '');
          setValue('customerType', customerDetails?.customer.customerType);
          setValue('customerAccountNumber', customerDetails?.customer.customerAccountNumber || '');
        }}
        fieldName={fieldName}
        customerName={customerDetails?.customer.name || ''}
        customerId={customerDetails?.customer.id}
        fieldsSet={{
          name: watch('name'),
          customerType: watch('customerType') as number,
          customerAccountNumber: watch('customerAccountNumber'),
        }}
        setError={setError}
      />
      <section className="info-grid__column">
        <h2 className="info-grid__title">Details</h2>
        <form className="details-form">
          <Controller
            control={control}
            name="name"
            rules={NameSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  clearOtherErrorsHandle('name');
                }}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!errors.name && v !== customerDetails?.customer.name) {
                    if (hasLinkedEntities()) {
                      setFieldName('name');
                      setConfirm(true);
                    } else {
                      if (!v.trim()) {
                        setValue('name', '');
                        setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
                      } else {
                        patchCustomerInfo({ name: v.trim() }, 'name');
                      }
                    }
                  }
                }}
                disabled={!allowedToEdit}
                error={errors.name?.message}
                label="Customer name"
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            control={control}
            name="customerType"
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  clearOtherErrorsHandle('customerType');
                  if (hasLinkedEntities()) {
                    setFieldName('customerType');
                    setConfirm(true);
                  } else {
                    patchCustomerInfo({ customerType: val as number }, 'customerType');
                  }
                }}
                label="Customer type"
                options={customerTypes}
                disabled={!allowedToEdit}
              />
            )}
          />
          <Controller
            control={control}
            name="customerAccountNumber"
            rules={AccountNumberSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('customerAccountNumber');
                }}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!errors.customerAccountNumber && v !== customerDetails?.customer.customerAccountNumber) {
                    if (hasLinkedEntities()) {
                      setFieldName('customerAccountNumber');
                      setConfirm(true);
                    } else {
                      patchCustomerInfo({ customerAccountNumber: v.trim() ? v.trim() : null }, 'customerAccountNumber');
                    }
                  }
                }}
                error={errors.customerAccountNumber?.message}
                disabled={!allowedToEdit}
                label="Customer account No"
              />
            )}
          />
          <Controller
            control={control}
            name="vatNumber"
            rules={VatNumberSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('vatNumber');
                }}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!errors.vatNumber) {
                    patchCustomerInfo({ vatNumber: v.trim() ? v.trim() : '' }, 'vatNumber', true);
                  }
                }}
                error={errors.vatNumber?.message}
                disabled={!allowedToEdit}
                label="VAT number"
              />
            )}
          />
          <Controller
            control={control}
            name="companyRegistrationNumber"
            rules={CompanyRegNoSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                  clearOtherErrorsHandle('companyRegistrationNumber');
                }}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!errors.companyRegistrationNumber) {
                    patchCustomerInfo({ companyRegistrationNumber: v.trim() ? v.trim() : '' }, 'companyRegistrationNumber', true);
                  }
                }}
                error={errors.companyRegistrationNumber?.message}
                disabled={!allowedToEdit}
                label="Company registration number"
              />
            )}
          />
          <RequisiteSection
            title="Billing details"
            setIsVisible={() => {
              setIsVisible(true);
              clearOtherErrorsHandle('billingDetails');
            }}
            requisiteDetails={billing}
            disabled={!allowedToEdit}
          />
          <RequisiteSection
            title="Shipping details"
            setIsVisible={() => {
              if (!isSame) {
                setIsShippingMode(true);
                setIsVisible(true);
                clearOtherErrorsHandle('shippingDetails');
              }
            }}
            requisiteDetails={shipping}
            isShipping
            isSame={isSame}
            onCheck={onCheck}
            disabled={!allowedToEdit}
          />
          <Controller
            control={control}
            name="alwaysUseRequisites"
            render={({ field }) => (
              <Select
                options={YesNoOptions}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  clearOtherErrorsHandle('alwaysUseRequisites');
                  patchCustomerInfo({ alwaysUseRequisites: val === 1 }, 'alwaysUseRequisites');
                }}
                label="Always use customer's billing/shipping details"
                disabled={!allowedToEdit}
                className="details-form__field--align-bottom"
              />
            )}
          />
          <Controller
            control={control}
            name="accountType"
            render={({ field }) => (
              <Select
                options={accountTypes}
                value={field.value}
                onChange={(val) => {
                  field.onChange(val);
                  clearOtherErrorsHandle('accountType');
                  patchCustomerInfo({ accountType: val as number }, 'accountType');
                }}
                label="Account type"
                disabled={!allowedToEditAccountType}
                className="details-form__field--align-bottom"
              />
            )}
          />
        </form>
      </section>
    </>
  );
};

export default CustomerDetails;
