import React, { useEffect } from 'react';
import {
  Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Select from '../../../core/components/select/Select';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { setOrderDictionaryFilters } from '../../../store/slices/sharedSlice';
import { CreateRectificationFlowEnum } from '../enums/createRectificationEnums';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Input from '../../../core/components/input/Input';
import { allowedBaForUserSelector } from '../../../store/selectors/coreSelectors';
import {
  createRectificationInitVehiclesListSelector,
  createRectificationVehiclesListSelector,
} from '../../../store/selectors/rectificationsSelector';
import { getCustomerById } from '../../../store/thunks/customers/customersViewPageThunks';
import { RectificationFormFields } from '../types/createRectificationFormSchema';

type RectificationFlowBasedFieldsProps = {
  flowValue: CreateRectificationFlowEnum,
  control: Control<RectificationFormFields>,
  watch: UseFormWatch<RectificationFormFields>,
  setValue: UseFormSetValue<RectificationFormFields>,
  ordersUi: { value: number, label: JSX.Element }[],
  errors: FieldErrors<RectificationFormFields>,
  setSearchValue: (v?: string) => void,
  searchValue?: string,
  searchVehicleV?: string,
  setSearchVehicleV: (v?: string) => void,
}

const RectificationFlowBasedFields: React.FC<RectificationFlowBasedFieldsProps> = ({
  flowValue, control, ordersUi, errors, searchValue, setSearchValue, watch,
  searchVehicleV, setSearchVehicleV, setValue,
}) => {
  const dispatch = useAppDispatch();
  const allowedBaForUser = useAppSelector(allowedBaForUserSelector);
  const initVehicleList = useAppSelector(createRectificationInitVehiclesListSelector);
  const createRectificationVehiclesList = useAppSelector(createRectificationVehiclesListSelector);
  const vehicleId = watch('vehicleId');
  useEffect(() => {
    if (vehicleId) {
      const matchCustomerName = initVehicleList.find((el) => el.id === vehicleId)?.customerName;
      const matchCustomerId = initVehicleList.find((el) => el.id === vehicleId)?.customerId;

      matchCustomerName && setValue('customer', matchCustomerName);
      matchCustomerId && dispatch(getCustomerById({ id: matchCustomerId }));
    }
    // eslint-disable-next-line
  }, [vehicleId]);
  return (
    <>
      {flowValue === CreateRectificationFlowEnum.USUAL
        ? <Controller
          name="orderId"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              showSearch
              onSearch={(val) => {
                if (val.length <= 250) {
                  setSearchValue(val);
                } else {
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              }}
              onSelect={() => {
                searchValue && dispatch(setOrderDictionaryFilters(undefined));
              }}
              searchValue={searchValue}
              options={ordersUi}
              error={errors.orderId?.message}
              label="Order No"
              className="details-form__field--lg"
            />
          )}
        />
        : <>
          <Controller
            name="vehicleId"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                showSearch
                onSearch={(val) => {
                  if (val.trim()) {
                    if (val.length <= 250) {
                      setSearchVehicleV(val);
                    } else {
                      dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                    }
                  } else {
                    setSearchVehicleV(undefined);
                  }
                }}
                onSelect={() => {
                  searchVehicleV && setSearchVehicleV(undefined);
                }}
                searchValue={searchVehicleV}
                options={createRectificationVehiclesList}
                error={errors.vehicleId?.message}
                label="Vehicle"
                className="details-form__field--lg"
              />
            )}
          />
          <Controller
            name="customer"
            control={control}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={field.onChange}
                label="Customer"
                className="details-form__field--lg"
                readOnly
              />
            )}
          />
          <Controller
            name="businessArea"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={allowedBaForUser}
                error={errors.businessArea?.message}
                label="Business area"
                className="details-form__field--lg"
                disabled={!vehicleId}
              />
            )}
          />
        </>}
    </>
  );
};

export default RectificationFlowBasedFields;
