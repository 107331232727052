import {
  DataListType,
  initDataList, initPaging, OrderingType,
} from '../../../core/types/coreTypes';
import { StatusCountDto } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import {
  TablePartNominalRecord,
} from '../../invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import { PartRequestLineDto } from '../../../common/types/commonTypes';
import { OrderListDto, OrderResponse } from '../../../orders/dashboard/types/ordersTypes';

export type SalesOrderDetails = Omit<OrderResponse,
'billingRequisite' | 'shippingRequisite' | 'requestSubmissionStatus' | 'attachmentsCount' | 'linksCount' | 'partRequestLineStatusCounts'
>;

export const InitSalesDashboardFilters = {
  isActive: true,
  ...initPaging,
};

export type SalesDataListType = DataListType<OrderListDto[], {
  statusCounts: StatusCountDto[],
  minCreatedDate: string | null,
  maxCreatedDate: string | null,
}>

export const initSalesDataList = {
  ...initDataList,
  statusCounts: [],
  minCreatedDate: null,
  maxCreatedDate: null,
};

export type SalesInvoiceOrderLinesFilters = {
  productNumber?: string,
  productName?: string,
  nominalCodeIds?: number[],
  order?: OrderingType,
}

export type SalesInvoiceOrderLineTableType = Pick<
PartRequestLineDto,
'id' | 'ordinalNumber' | 'quantity'
> & {
  partNumber: string,
  partName: string,
  net: string,
  invoicedPending: number,
  nominalCodes: Array<TablePartNominalRecord>,
  unitOfMeasure: string,
  partId: number | null,
  notes: string | null,
  requestedQty: number,
  // SET TO 0 CAUSE THERE IS NO SUCH COLUMN BUT WE DON'T WANT A TS ERROR
  cancelledQty: number,
  creditNominalRecordsNet: number | null,
  // SET TO NULL TO GET RID OF TS ERROR
  receivedNet?: number | null,
  childLines?: SalesInvoiceOrderLineTableType[] | null,
}
