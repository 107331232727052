import React from 'react';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { purchaseOrderHeadSelector } from '../../../../../store/selectors/purchaseOrderSelectors';
import { useAppSelector } from '../../../../../store/hooks';

const PurchaseOrderHead: React.FC = () => {
  const entities = useAppSelector(purchaseOrderHeadSelector);
  return (
    <>
      {entities.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        <TruncateTooltip value={ent.value} className="head__inner-info-item__value" />
      </div>)}
    </>
  );
};

export default PurchaseOrderHead;
