import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { numberOfConvertedVehiclesRangesSelector, numberOfConvertedVehiclesFiltersSelector } from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useNumberOfConvertedVehiclesRanges = () => {
  const filters = useAppSelector(numberOfConvertedVehiclesFiltersSelector);
  const { convertedVehicles } = useAppSelector(numberOfConvertedVehiclesRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    convertedVehicles: { min: convertedVehicles.min, max: convertedVehicles.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    convertedVehicles: filters?.convertedVehicles
      ? [filters.convertedVehicles.min.toString(), filters.convertedVehicles.max.toString()]
      : [rangePatterns.convertedVehicles.min.toString(), rangePatterns.convertedVehicles.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, convertedVehicles: { min: convertedVehicles.min, max: convertedVehicles.max } }));
    setRange((prevState) => (
      { ...prevState, convertedVehicles: [convertedVehicles.min.toString(), convertedVehicles.max.toString()] }));
    // eslint-disable-next-line
  }, [convertedVehicles.min, convertedVehicles.max]);

  useEffect(() => {
    if (!filters?.convertedVehicles) {
      setRange((prevState) => (
        { ...prevState, convertedVehicles: [convertedVehicles.min.toString(), convertedVehicles.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters?.convertedVehicles]);

  return { range, setRange, rangePatterns };
};
