import React from 'react';
import { Button as ButtonComponent } from 'antd';
import classNames from 'classnames';
import { VoidFunctionType } from '../../types/coreTypes';
import './Button.scss';

type ButtonProps = {
  label?: string | React.ReactNode;
  onClick?: VoidFunctionType;
  htmlType?: 'button' | 'submit' | 'reset';
  type?: 'primary' | 'ghost' | 'dashed' | 'link' | 'text' | 'default';
  icon?: React.ReactNode;
  href?: string;
  disabled?: boolean;
  isLoading?: boolean;
  className?: string;
  designType?: 'light-inverse' | 'dark',
  isExpand?: boolean,
  isActivated?: boolean,
  reversed?: boolean,
  disableDefaultStyles?: boolean,
  offsetRight?: boolean,
  countValue?: number,
}

const Button: React.FC<ButtonProps> = ({
  label,
  onClick,
  htmlType = 'button',
  type = 'default',
  icon,
  href,
  disabled,
  isLoading,
  className,
  designType,
  isExpand,
  isActivated,
  reversed,
  disableDefaultStyles = false,
  offsetRight,
  countValue,
}) => (
  <ButtonComponent
    onClick={onClick}
    htmlType={htmlType}
    type={type}
    icon={icon}
    disabled={disabled}
    loading={isLoading}
    href={href}
    style={{ '--count-value': countValue } as React.CSSProperties}
    className={classNames(className, {
      'customButton': !disableDefaultStyles,
      'customButton--light-inverse': designType === 'light-inverse',
      'customButton--dark': designType === 'dark',
      'customButton--expand': isExpand,
      'customButton--reversed': reversed,
      'customButton--is-activated': isActivated,
      'customButton--offset-right': offsetRight,
      'customButton--with-count': countValue && countValue > 0,
    })}
  >
    {label}
  </ButtonComponent>
);

export default Button;
