import React, { useEffect } from 'react';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { allowedShortBaForUserSelector } from '../../../store/selectors/coreSelectors';
import CreatePartsKitForm from './components/CreatePartsKitForm';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { getPartsKitAvailableCategoriesThunk } from '../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';

const CreatePartsKitContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState();
  const allowedShortBa = useAppSelector(allowedShortBaForUserSelector);
  const currentUser = useAppSelector(currentUserSelector);

  useEffect(() => {
    dispatch(getPartsKitAvailableCategoriesThunk({ keyword: undefined }));
    // eslint-disable-next-line
  }, [currentUser]);

  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new parts kit</div>
    </DetailsHead>}
    >
      <CreatePartsKitForm
        businessAreas={allowedShortBa}
      />
    </Layout>
  );
};

export default CreatePartsKitContainer;
