import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { ACCOUNT_NO_REGEX } from '../../../core/utils/regex';

export type CreateEnquiryFields = {
  name: string | number,
  customerType?: number,
  enquirySource?: number,
  priorityLevel?: number,
  customerAccountNumber: string,
  estimatedCost: string,
  jobType?: number,
  description: string | null,
  assignedTo?: number,
}

export const CreateEnquiryDefaults: CreateEnquiryFields = {
  name: '',
  customerType: undefined,
  enquirySource: undefined,
  priorityLevel: undefined,
  customerAccountNumber: '',
  estimatedCost: '0.00',
  jobType: undefined,
  description: '',
  assignedTo: undefined,
};

export const EnquiryCustomerStringName = {
  ...RequiredFieldSchema,
  maxLength: { value: 250, message: maxLengthMessageHandle(250) },
};

export const EnquiryCustomerAccNumberSchema = {
  ...RequiredFieldSchema,
  pattern: { value: ACCOUNT_NO_REGEX, message: ErrorsEnum.ONLY_NUMBERS_LETTERS },
  minLength: { value: 4, message: rangeLengthMessageHandle([4, 6]) },
  maxLength: { value: 6, message: rangeLengthMessageHandle([4, 6]) },
};

export const EnquiryDescriptionSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};
