import React, { useMemo } from 'react';
import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../../../store/hooks';
import { vehicleStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';
import { jobVehicleSelector } from '../../../../../store/selectors/jobsSelector';
import { routesPath } from '../../../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { currentUserPermissionsSelector } from '../../../../../store/selectors/accountSelectors';

type JobVehiclesSectionProps = {
  readOnly?: boolean,
}

const JobVehiclesSection: React.FC<JobVehiclesSectionProps> = ({ readOnly }) => {
  const navigate = useNavigate();
  const jobVehicle = useAppSelector(jobVehicleSelector);
  const vehicleId = jobVehicle?.vehicleId;
  const currentVehicleStatus = jobVehicle?.vehicleStatus;
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);
  const currentUserPermissions = useAppSelector(currentUserPermissionsSelector);
  const foundStatusLabelMatch = vehicleStatuses.find((el) => el.value === jobVehicle?.vehicleStatus);

  const actionOptions = useMemo(() => {
    return currentVehicleStatus
      ? [{
        key: 'openVehiclePage',
        label: 'Open vehicle page',
        icon: <FontAwesomeIcon icon={faCar} />,
        onClick: () => navigate(`/${routesPath.VEHICLES}/${vehicleId}`),
        restrictedTo: PermissionEnum.VehicleViewPage,
      }].filter((option) => currentUserPermissions?.includes(option.restrictedTo))
      : [];
    // eslint-disable-next-line
    }, [currentUserPermissions, currentVehicleStatus]);

  const vehicleData = {
    name: jobVehicle?.manufacturer || '',
    info: jobVehicle?.modelDescription || '',
  };
  return (
    <section className="info-grid__column">
      <h2 className="info-grid__title">Vehicle</h2>
      <VehicleSection
        name={vehicleData.name}
        info={vehicleData.info}
        status={currentVehicleStatus
          ? foundStatusLabelMatch?.label
          : 'DRAFT'}
        regNumber={jobVehicle?.regNumber}
        chassisNumber={jobVehicle?.chassisNumber}
        vehicleLocation={jobVehicle?.vehicleLocation}
        menuItems={actionOptions}
        readonly={readOnly}
      />
    </section>
  );
};

export default JobVehiclesSection;
