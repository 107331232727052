import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getEnquiryLinks } from '../../../../../store/thunks/enquiry/detailsThunks';
import {
  enquiryLinksFiltersSelector,
  enquiryLinksRowsSelector,
} from '../../../../../store/selectors/enquiriesSelector';
import LinksTable from '../../../../../common/components/links-table/LinksTable';
import EnquiryLinksColumns from './EnquiryLinksColumns';
import LinksBody from '../../../../../common/components/links-table/LinksBody';

const EnquiryLinks: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const enquiryLinksFilters = useAppSelector(enquiryLinksFiltersSelector);
  const enquiryLinks = useAppSelector(enquiryLinksRowsSelector);

  useEffect(() => {
    id && dispatch(getEnquiryLinks({ enquiryId: +id, filters: enquiryLinksFilters }));
    // eslint-disable-next-line
  }, [id, enquiryLinksFilters]);

  return (
    <LinksTable>
      <EnquiryLinksColumns totalCount={enquiryLinks.length || 0} />
      <LinksBody links={enquiryLinks} />
    </LinksTable>
  );
};

export default EnquiryLinks;
