import React from 'react';
import { ReportTableBody } from '../../../../../types/RectificationReportsTypes';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector, salesReportByCustomerDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import ViewPageLink from '../../../../../../common/components/view-page-link/ViewPageLink';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { EntityType } from '../../../../../../common/types/commonTypes';

const SalesReportByCustomerBody: React.FC<ReportTableBody> = ({ columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { items } = useAppSelector(salesReportByCustomerDataSelector);

  const hiddenKeys = ['customerId', 'billingCustomerId', 'shippingCustomerId', 'entityId'];
  const nowrapKeys = ['costNet', 'salesNet', 'differenceNet'];
  const customerKeys = ['customerName', 'billingCustomerName', 'shippingCustomerName'];

  const renderCellValue = (key: string, value: string | number | null, el: typeof items[number]) => {
    if (customerKeys.includes(key)) {
      let id: number | null = null;
      if (key === 'customerName') id = el.customerId;
      if (key === 'billingCustomerName') id = el.billingCustomerId;
      if (key === 'shippingCustomerName') id = el.shippingCustomerId;
      return <ViewPageLink entityType="Customer" entityId={id}>
        <TruncateTooltip value={value} table />
      </ViewPageLink>;
    }
    if (key === 'entityNumber' && el.entityType) {
      return <ViewPageLink entityType={el.entityType as EntityType} entityId={el.entityId}>
        <TruncateTooltip value={value} table />
      </ViewPageLink>;
    }
    return value;
  };

  return (
    <Tbody>
      {items.length
        ? items.map((el) => (
          <Tr key={el.number}>
            {Object.entries(el).map(([key, value]) => {
              if (hiddenKeys.includes(key)) return null;
              return <TdNew key={key} nowrap={nowrapKeys.includes(key)}>
                {renderCellValue(key, value, el)}
              </TdNew>;
            })}
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default SalesReportByCustomerBody;
