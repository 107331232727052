import React from 'react';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { VoidFunctionType } from '../../../core/types/coreTypes';

type DiscardSubmittedPartReqChangesProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  confirmHandler: VoidFunctionType,
}

const DiscardSubmittedPartReqChanges: React.FC<DiscardSubmittedPartReqChangesProps> = ({ isVisible, onCancel, confirmHandler }) => {
  return (
    <ConfirmationModal
      isVisible={isVisible}
      onCancel={onCancel}
      customTitle="Discard changes"
      customText="Unsubmitted changes will be deleted and a part request will be reset to its most recent submitted state."
      customCreateLabel="Yes, discard changes"
      confirmHandler={confirmHandler}
    />
  );
};
export default DiscardSubmittedPartReqChanges;
