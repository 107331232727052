import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { salesOfficeEmployeeFiltersSelector, salesOfficeEmployeeRangesSelector } from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useSalesOfficeEmployeeRanges = () => {
  const filters = useAppSelector(salesOfficeEmployeeFiltersSelector);
  const { salesNet, daysInNewStatus } = useAppSelector(salesOfficeEmployeeRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    salesNet: { min: salesNet.min, max: salesNet.max },
    daysInNewStatus: { min: daysInNewStatus.min, max: daysInNewStatus.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    salesNet: filters.salesNet
      ? [filters.salesNet.min.toString(), filters.salesNet.max.toString()]
      : [rangePatterns.salesNet.min.toString(), rangePatterns.salesNet.max.toString()],
    daysInNewStatus: filters.daysInNewStatus
      ? [filters.daysInNewStatus.min.toString(), filters.daysInNewStatus.max.toString()]
      : [rangePatterns.daysInNewStatus.min.toString(), rangePatterns.daysInNewStatus.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, salesNet: { min: salesNet.min, max: salesNet.max } }));
    setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
    // eslint-disable-next-line
  }, [salesNet.min, salesNet.max]);

  useEffect(() => {
    setRangePatterns((prevState) => ({ ...prevState, daysInNewStatus: { min: daysInNewStatus.min, max: daysInNewStatus.max } }));
    setRange((prevState) => ({ ...prevState, daysInNewStatus: [daysInNewStatus.min.toString(), daysInNewStatus.max.toString()] }));
    // eslint-disable-next-line
  }, [daysInNewStatus.min, daysInNewStatus.max]);

  useEffect(() => {
    if (!filters.salesNet) {
      setRange((prevState) => ({ ...prevState, salesNet: [salesNet.min.toString(), salesNet.max.toString()] }));
    }
    if (!filters.daysInNewStatus) {
      setRange((prevState) => ({ ...prevState, daysInNewStatus: [daysInNewStatus.min.toString(), daysInNewStatus.max.toString()] }));
    }
    // eslint-disable-next-line
  }, [filters.salesNet, filters.daysInNewStatus]);

  return { range, setRange, rangePatterns };
};
