export const columns = [
  {
    title: 'No',
    dataIndex: 'ordinalNumber',
  },
  {
    title: 'Customer',
    dataIndex: 'customer',
  },
  {
    title: 'Part request No',
    dataIndex: 'partRequestNumber',
  },
  {
    title: 'Linked to',
    dataIndex: 'linkedEntityNumber',
    filterSearch: true,
  },
  {
    title: 'Linked to status',
    dataIndex: 'linkedEntityStatus',
    filterSearch: true,
  },
  {
    title: 'Category',
    dataIndex: 'category',
  },
  {
    title: 'Product No',
    dataIndex: 'productNumber',
    filterSearch: true,
  },
  {
    title: 'Product name',
    dataIndex: 'productName',
  },
  {
    title: 'Source',
    dataIndex: 'source',
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
  },
  {
    title: 'Ready',
    dataIndex: 'ready',
  },
  {
    title: 'Collected',
    dataIndex: 'collected',
  },
  {
    title: 'Status',
    dataIndex: 'status',
  },
];
export const hiddenKeys = [
  'id',
  'partId',
  'partsKitId',
  'unitOfMeasure',
  'partsKitComponentId',
  'partsKitComponentPartId',
  'notes',
  'canPurchaseOrderBeGenerated',
  'deletionReason',
];
export const nowrapKeys = ['ordinalNumber', 'priceSources', 'quantity', 'readyQuantity', 'collectedQuantity'];
