import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Switch from '../../../../../core/components/switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { pricelistTimelineFiltersSelector, pricelistTimelineSelector } from '../../../../../store/selectors/pricelistSelectors';
import { setPricelistTimelineFilters } from '../../../../../store/slices/pricelistSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { getPricelistTimelineThunk, postPricelistTimelineNoteThunk } from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import PricelistEvent from './pricelist-event-description/PricelistEvent';
import Timeline from '../../../../../core/components/timeline/Timeline';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

const PricelistActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, totalCount } = useAppSelector(pricelistTimelineSelector);
  const timelineFilters = useAppSelector(pricelistTimelineFiltersSelector);

  const [value, setValue] = useState('');

  const allowedToAddNote = usePermission(PermissionEnum.PricelistsViewPage);

  useEffect(() => {
    id && dispatch(getPricelistTimelineThunk({
      pricelistId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
  }, [id, timelineFilters.page, timelineFilters.mainOnly]);

  const addNote = () => {
    id && dispatch(postPricelistTimelineNoteThunk({
      pricelistId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <PricelistEvent item={el} />,
  }));

  return (
    <section className="pricelistTimeline__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(value) => {
            dispatch(setPricelistTimelineFilters({
              ...timelineFilters,
              mainOnly: !value,
              page: 1,
            }));
          }}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        totalItems={totalCount}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setPricelistTimelineFilters({
            ...timelineFilters,
            page,
          }));
        }}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default PricelistActivitySection;
