import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { faCheckCircle, faEllipsisVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import classNames from 'classnames';
import { AssigneeChangeNotification, InAppNotification, MissingSellingPricesNotification } from '../../../account/types/AccountTypes';
import AssigneeChangeMessage from './AssigneeChangeMessage';
import Dropdown from '../dropdown/Dropdown';
import {
  changeOneNotificationStatusThunk,
  deleteOneNotificationThunk,
} from '../../../store/thunks/account/accountThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { VoidFunctionType } from '../../types/coreTypes';
import Button from '../button/Button';
import { inAppNotificationTypeValuesSelector } from '../../../store/selectors/coreSelectors';
import { inAppReadStatusSelector, inAppUnreadStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import MissingSellingPricesMessage from './MissingSellingPricesMessage';

type DrawerMessageProps = {
  message: InAppNotification,
  closeDrawer: VoidFunctionType,
  className?: string,
}

const DrawerMessage: React.FC<DrawerMessageProps> = ({ message, closeDrawer, className }) => {
  const dispatch = useAppDispatch();
  const inAppReadStatus = useAppSelector(inAppReadStatusSelector);
  const inAppUnreadStatus = useAppSelector(inAppUnreadStatusSelector);
  const { assigneeChange, missingSellingPrices } = useAppSelector(inAppNotificationTypeValuesSelector);

  const isAssigneeChange = (message: InAppNotification): message is AssigneeChangeNotification => {
    return 'initiator' in message && message.notificationType === assigneeChange;
  };
  const isMissingSellingPrices = (message: InAppNotification): message is MissingSellingPricesNotification => {
    return 'partQuantities' in message && message.notificationType === missingSellingPrices;
  };

  const changeStatus = () => {
    const isAllDataHere = message.notificationId && inAppUnreadStatus && inAppReadStatus;
    isAllDataHere && dispatch(changeOneNotificationStatusThunk({
      id: message.notificationId,
      status: message.readDate ? inAppUnreadStatus : inAppReadStatus,
      closeDrawer,
    }));
  };

  const messageTypeRender = () => {
    // Do not use switch/case since TS type guards don't work
    if (isAssigneeChange(message)) {
      return <AssigneeChangeMessage message={message} closeDrawer={closeDrawer} />;
    }
    if (isMissingSellingPrices(message)) {
      return <MissingSellingPricesMessage message={message} />;
    }
    return null;
  };

  return (
    <li className={classNames('messageWrap', className)}>
      <Button
        icon={<FontAwesomeIcon icon={message.readDate ? faCheckCircle : faCircle} />}
        type="text"
        onClick={() => changeStatus()}
        className={classNames('messageWrap__check', { 'messageWrap__check--read': message.readDate })}
      />
      <div className="messageWrap__info">
        {messageTypeRender()}
        <span className="messageWrap__timestamp">
          {moment(message.createdDate).format('DD/MM/YYYY')}
          {' '}
          at
          {' '}
          {moment(message.createdDate).format('HH:mm')}
        </span>
      </div>
      <Dropdown
        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
        className="messageWrap__action"
        parentRender
        menuItems={[
          {
            key: 'deleteMessage',
            label: 'Delete',
            icon: <FontAwesomeIcon icon={faTrashAlt} />,
            onClick: () => message.notificationId && dispatch(deleteOneNotificationThunk({
              id: message.notificationId,
              closeDrawer,
            })),
          },
        ]}
      />
    </li>
  );
};

export default DrawerMessage;
