import React, { useState } from 'react';
import './CreateAttachmentModal.scss';
import { RcFile } from 'antd/es/upload';
import { useAppSelector } from '../../../store/hooks';
import DraggerUpload from '../../../core/components/upload/Dragger';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { PromiseVoidFunctionType, StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import Input from '../../../core/components/input/Input';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { useBeforeAttachmentUpload } from '../../../core/hooks/useBeforeAttachmentUpload';

type CreateAttachmentModalProps = {
  isVisible: boolean;
  onCancel: VoidFunctionType;
  uploadHandle: PromiseVoidFunctionType,
  fileList: RcFile[],
  setFileList: (value: RcFile[]) => void,
  comment: string,
  setComment: StringFunctionType,
  extraField?: JSX.Element,
}

const CreateAttachmentModal: React.FC<CreateAttachmentModalProps> = ({
  isVisible, onCancel, uploadHandle, fileList,
  setFileList, setComment, comment, extraField,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const [error, setError] = useState<string>('');
  const { beforeUpload } = useBeforeAttachmentUpload(fileList, setFileList);

  const uploadAction = async () => {
    await uploadHandle();
  };

  const cancelHandle = () => {
    setError('');
    onCancel();
  };

  return (
    <Modal visible={isVisible} onCancel={cancelHandle} title="Upload file" maskClosable={!fileList.length}>
      <DraggerUpload
        name="content"
        multiple
        fileList={fileList}
        setFileList={setFileList}
        beforeUpload={beforeUpload}
        disabled={fileList.length === 10 || isLoading}
      />
      {extraField && extraField}
      <Input
        value={comment}
        onChange={(e) => {
          const val = e.target.value;
          if (val.length > 200) {
            setError(maxLengthMessageHandle(200));
          } else {
            setError('');
          }
          setComment(val);
        }}
        label="Comment"
        error={error}
        className="comment"
        disabled={isLoading}
      />
      <ButtonActions
        createLabel="Upload"
        cancelLabel="Cancel"
        cancelClick={cancelHandle}
        createClick={uploadAction}
        disabledCreate={fileList.length === 0 || !!error}
        isLoading={isLoading}
      />
    </Modal>
  );
};

export default CreateAttachmentModal;
