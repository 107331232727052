import { CollectionColumnPaginationType, GenericColumnType } from '../../../core/types/coreTypes';
import { JobColumnType, JobDto } from '../types/jobsTypes';

export const jobDashboardCollectionsHelper = (
  prevCollections: Array<GenericColumnType<Array<JobDto>>>,
  responseCollections: Array<JobColumnType>,
  oldPagination: Array<CollectionColumnPaginationType>,
  statusTransition: number,
  sourceId: number,
) => {
  const currentOld = responseCollections.find((el) => el.status === sourceId);
  const currentNew = responseCollections.find((el) => el.status === statusTransition);
  const newPagination = oldPagination.map((p) => {
    if (p.status === currentOld?.status) {
      return { ...p, pages: currentOld.pagesCount, currentPage: 1 };
    }
    if (p.status === currentNew?.status) {
      return { ...p, pages: currentNew?.pagesCount, currentPage: 1 };
    } else return p;
  });
  const newJobCollections = prevCollections.map((col) => {
    if (col.status === currentNew?.status) {
      const {
        jobs, ordinalNumber, pagesCount, ...rest
      } = currentNew;
      return {
        ...rest,
        pages: pagesCount,
        order: ordinalNumber,
        list: jobs,
      };
    }
    if (col.status === currentOld?.status) {
      const {
        jobs, ordinalNumber, pagesCount, ...rest
      } = currentOld;
      return {
        ...rest,
        pages: pagesCount,
        order: ordinalNumber,
        list: jobs,
      };
    } else return col;
  });
  return { newPagination, newJobCollections };
};
