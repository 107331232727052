import React from 'react';
import Thead from '../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import {
  numberOfConvertedVehiclesFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import { setNumberOfConvertedVehiclesFilters } from '../../../../../../store/slices/reportsSlice';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import { getNumberOfConvertedVehiclesReportThunk } from '../../../../../../store/thunks/reports/reportsThunks';
import { useNumberOfConvertedVehiclesRanges } from '../../../../../hooks/useNumberOfConvertedVehiclesRanges';

type RectificationsByProductColumnsProps = {
  columns: ICustomColumn[],
  totalElements: number,
}

const NumberOfConvertedVehiclesColumns: React.FC<RectificationsByProductColumnsProps> = ({ columns, totalElements }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(numberOfConvertedVehiclesFiltersSelector);

  const { range, setRange, rangePatterns } = useNumberOfConvertedVehiclesRanges();

  const sortHandle = (field: number) => {
    if (filters?.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setNumberOfConvertedVehiclesFilters(newFilters));
      dispatch(getNumberOfConvertedVehiclesReportThunk({ filters: newFilters }));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setNumberOfConvertedVehiclesFilters(newFilters));
      dispatch(getNumberOfConvertedVehiclesReportThunk({ filters: newFilters }));
    }
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const [min, max] = value;
    const newFilters = {
      ...filters,
      [field]: { min, max },
    };
    dispatch(setNumberOfConvertedVehiclesFilters(newFilters));
    dispatch(getNumberOfConvertedVehiclesReportThunk({ filters: newFilters }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            setRangeHandle={setRangeHandle}
            range={range}
            rangePatterns={rangePatterns}
            rangeStep={1}
            setRange={setRange}
            totalElements={totalElements}
            className={`numberOfConvertedVehiclesReportTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default NumberOfConvertedVehiclesColumns;
