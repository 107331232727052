import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getCustomerActivityLog, postCustomerActivityNote } from '../../../../../store/thunks/customers/customersViewPageThunks';
import Timeline from '../../../../../core/components/timeline/Timeline';
import { customerActivityLogFiltersSelector, customerActivityLogSelector } from '../../../../../store/selectors/customersSelectors';
import CustomerEvent from './customer-events-description/CustomerEvent';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { setCustomerActivityLogFilters } from '../../../../../store/slices/customersSlice';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

const CustomerActivity: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const [value, setValue] = useState('');

  const { items, totalCount } = useAppSelector(customerActivityLogSelector);
  const activityFilters = useAppSelector(customerActivityLogFiltersSelector);
  const allowedToAddNote = usePermission(PermissionEnum.CustomerViewPage);

  useEffect(() => {
    id && dispatch(getCustomerActivityLog({
      customerId: +id,
      filters: activityFilters,
    }));
    // eslint-disable-next-line
  }, [id, activityFilters.page]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <CustomerEvent item={el} />,
  }));

  const addNote = () => {
    id && dispatch(postCustomerActivityNote({
      customerId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  return (
    <section className="detailsActivityWrap__activity info-grid__column">
      <h2 className="info-grid__title">Activity</h2>
      <div className="detailsActivityWrap__activity-inner">
        <Pagination
          currentPage={activityFilters.page}
          pageSize={activityFilters.pageSize}
          onChange={(page) => {
            dispatch(setCustomerActivityLogFilters({
              ...activityFilters,
              page,
            }));
          }}
          totalItems={totalCount}
          showTotal={false}
          showSizeChanger={false}
        />
        <Timeline timelineList={timelineList} className="detailsActivityWrap__activity-inner-log" />
        <ActivityNoteInput
          value={value}
          setValue={setValue}
          addNote={addNote}
          disabledInput={!allowedToAddNote}
        />
      </div>
    </section>
  );
};

export default CustomerActivity;
