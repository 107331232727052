import React, { useEffect, useState } from 'react';
import capitalize from 'lodash/capitalize';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment/moment';
import Modal from '../../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../../core/components/button-actions/ButtonActions';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import RadioGroup from '../../../../../../core/components/radio-group/RadioGroup';
import Textarea from '../../../../../../core/components/textarea/Textarea';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import Select from '../../../../../../core/components/select/Select';
import { RequiredFieldSchema } from '../../../../../../core/enums/errorsEnum';
import Input from '../../../../../../core/components/input/Input';
import DatePickerComponent from '../../../../../../core/components/date-picker/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { accountLeaveRequestSelector } from '../../../../../../store/selectors/accountSelectors';
import {
  dayPartAllDaySelector,
  dayPartsUiSelector,
  isFetchingSelector,
  leaveRequestTypesUiSelector,
} from '../../../../../../store/selectors/coreSelectors';
import './AccountLeaveRequestModal.scss';
import { DEFAULT_DATE_FORMAT } from '../../../../../../core/utils/regex';
import { setAccountLeaveRequest } from '../../../../../../store/slices/accountSlice';
import { employeesTotalDictionaryListSelector } from '../../../../../../store/selectors/employeesSelector';
import { setErrorMessage } from '../../../../../../store/slices/coreSlice';
import { getAllEmployeesListThunk } from '../../../../../../store/thunks/employee/employeesWorkCalendarThunks';
import { AccountLeaveRequestFields, CommentSchema } from '../../utils/AccountLeaveRequestFormSchema';
import { PostLeaveRequestRestModel, PutLeaveRequestRestModel } from '../../types/AccountLeaveRequestsTypes';

type AccountLeaveRequestModalProps = {
  visible: boolean,
  type: 'create' | 'edit',
  setCreateModal?: BooleanFunctionType,
  changeHandle: (id: number, data: PutLeaveRequestRestModel, closeModal: VoidFunctionType) => void,
  createHandle?: (data: PostLeaveRequestRestModel, type: string, closeModal: VoidFunctionType) => void,
  withEmployeeSelection?: boolean,
}
const AccountLeaveRequestModal: React.FC<AccountLeaveRequestModalProps> = ({
  visible,
  type,
  setCreateModal,
  changeHandle,
  createHandle,
  withEmployeeSelection,
}) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);
  const leaveRequestTypes = useAppSelector(leaveRequestTypesUiSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);
  const allDayValue = useAppSelector(dayPartAllDaySelector);
  const leaveRequest = useAppSelector(accountLeaveRequestSelector);
  const employeesDictionary = useAppSelector(employeesTotalDictionaryListSelector);
  const [period, setPeriod] = useState(0);
  const [keyword, setKeyword] = useState<string>('');
  const [employeesSelectLoading, setEmployeesSelectLoading] = useState(false);

  const fetchEmployeesDictionary = (keyword?: string) => {
    dispatch(getAllEmployeesListThunk({
      filters: {
        keyword,
        includeFullName: keyword ? true : undefined,
        includeReferenceNumber: keyword ? false : undefined,
        includeNationalInsuranceNumber: keyword ? false : undefined,
        includeEmail: keyword ? false : undefined,
        hasLinkedAccount: true,
      },
      setEmployeesSelectLoading,
    }));
  };
  const {
    control, handleSubmit, watch, setValue, reset, setError,
  } = useForm<AccountLeaveRequestFields>({
    defaultValues: {
      employeeId: undefined,
      leaveType: undefined,
      startDate: moment().format(DEFAULT_DATE_FORMAT),
      startDateDayPart: allDayValue,
      endDate: moment().format(DEFAULT_DATE_FORMAT),
      endDateDayPart: allDayValue,
      comment: '',
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const startDate = watch('startDate');
  const endDate = watch('endDate');
  const startDateDayPart = watch('startDateDayPart');
  const endDateDayPart = watch('endDateDayPart');

  useEffect(() => {
    if (leaveRequest) {
      withEmployeeSelection && setValue('employeeId', leaveRequest.employeeId);
      setValue('leaveType', leaveRequest.leaveType);
      setValue('startDate', leaveRequest.startDate);
      setValue('endDate', leaveRequest.endDate);
      setValue('startDateDayPart', leaveRequest.startDateDayPart);
      setValue('endDateDayPart', leaveRequest.endDateDayPart);
      setValue('comment', leaveRequest.comment || '');
    }
    // eslint-disable-next-line
  }, [leaveRequest]);

  useEffect(() => {
    setValue('startDateDayPart', allDayValue);
    setValue('endDateDayPart', allDayValue);
    // eslint-disable-next-line
  }, [allDayValue]);

  useEffect(() => {
    const FULL_DAY = 1;
    const HALF_DAY = 0.5;
    if (moment(startDate).isSame(endDate, 'days')) {
      setPeriod(startDateDayPart === allDayValue ? FULL_DAY : HALF_DAY);
    } else {
      let diff = moment(endDate).diff(startDate, 'days') + FULL_DAY;
      if (startDateDayPart !== allDayValue) {
        diff -= HALF_DAY;
      }
      if (endDateDayPart !== allDayValue) {
        diff -= HALF_DAY;
      }
      setPeriod(diff);
    }
  }, [visible, startDate, endDate, startDateDayPart, endDateDayPart, allDayValue]);

  const cancelHandle = () => {
    visible && setCreateModal && setCreateModal(false);
    leaveRequest && dispatch(setAccountLeaveRequest(null));
  };

  const onSubmit = (values: AccountLeaveRequestFields) => {
    const data = {
      leaveType: values.leaveType as number,
      startDate: values.startDate,
      startDateDayPart: values.startDateDayPart as number,
      endDate: values.endDate,
      endDateDayPart: values.endDateDayPart as number,
      comment: values.comment?.trim() || null,
    };
    if (leaveRequest) {
      const oneMonthBeforeDate = moment().subtract(1, 'month');
      if (moment(data.startDate).isBefore(oneMonthBeforeDate, 'day')) {
        setError('startDate', { message: 'Start date is out of range.' });
      } else {
        changeHandle(leaveRequest.id, data, cancelHandle);
      }
    } else {
      const dataWithEmployee = withEmployeeSelection ? { ...data, employeeId: values.employeeId } : data;
      createHandle
      && createHandle(dataWithEmployee, leaveRequestTypes.find((el) => el.value === data.leaveType)?.label || '', cancelHandle);
    }
  };

  const checkIsSameDay = (dayOne: string, dayTwo: string) => moment(dayOne).isSame(dayTwo, 'day');

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keyword) {
      const timeOutId = setTimeout(() => {
        fetchEmployeesDictionary(keyword);
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [keyword]);

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title={`${capitalize(type)} leave request`}
      width={800}
      afterClose={reset}
      destroyOnClose
    >
      <form className="createAccountLeaveRequest" onSubmit={handleSubmit(onSubmit)}>
        <div className="createAccountLeaveRequest__form-content">
          {withEmployeeSelection && <Controller
            control={control}
            name="employeeId"
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                label="Employee"
                options={employeesDictionary}
                value={field.value}
                onChange={field.onChange}
                error={errors.leaveType?.message}
                parentRender
                showSearch
                onSearch={(val) => {
                  if (!val.trim()) {
                    fetchEmployeesDictionary(undefined);
                  }
                  if (val.length <= 250) {
                    setKeyword(val.trim());
                  } else {
                    dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                  }
                }}
                onSelect={() => {
                  keyword && setKeyword('');
                  fetchEmployeesDictionary(undefined);
                }}
                disabled={type === 'edit'}
                loading={employeesSelectLoading}
              />
            )}
          />}
          <Controller
            control={control}
            name="leaveType"
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                label="Leave type"
                options={leaveRequestTypes}
                value={field.value}
                onChange={field.onChange}
                error={errors.leaveType?.message}
                parentRender
              />
            )}
          />
          <div className="createAccountLeaveRequest__date">
            <Controller
              control={control}
              name="startDate"
              render={({ field }) => (
                <DatePickerComponent
                  value={field.value ? moment(field.value) : undefined}
                  onChange={(value) => {
                    if (value) {
                      const formatted = value.format(DEFAULT_DATE_FORMAT);
                      const isStartDateSameOrAfterEnd = value.isSameOrAfter(endDate, 'days');
                      if (isStartDateSameOrAfterEnd) {
                        setValue('endDate', formatted);
                        setValue('endDateDayPart', startDateDayPart);
                      }
                      field.onChange(formatted);
                    }
                  }}
                  label="Start date"
                  error={errors.startDate?.message}
                  format="DD/MM/YYYY"
                  disabledDate={(currentDate) => {
                    const oneMonthBeforeDate = moment().subtract(1, 'months');
                    const tooEarly = moment(currentDate).add(+1, 'days') <= oneMonthBeforeDate;
                    const tooLate = currentDate > moment().add(+6, 'months');
                    return tooEarly || tooLate;
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="startDateDayPart"
              render={({ field }) => (
                <RadioGroup
                  options={dayParts}
                  onChange={(value) => {
                    field.onChange(value);
                    if (checkIsSameDay(startDate, endDate)) {
                      setValue('endDateDayPart', value as number);
                    }
                  }}
                  value={field.value as number}
                  optionType="button"
                  className={errors.startDate?.message ? 'offset-error' : ''}
                />
              )}
            />
          </div>
          <div className="createAccountLeaveRequest__date">
            <Controller
              control={control}
              name="endDate"
              render={({ field }) => (
                <DatePickerComponent
                  value={field.value ? moment(field.value) : undefined}
                  onChange={(value) => {
                    if (value) {
                      const dateString = value.format(DEFAULT_DATE_FORMAT);
                      field.onChange(dateString);
                      if (checkIsSameDay(startDate, dateString)) {
                        setValue('endDateDayPart', startDateDayPart);
                      }
                    }
                  }}
                  label="End date"
                  error={errors.endDate?.message}
                  format="DD/MM/YYYY"
                  disabledDate={(currentDate) => {
                    const tooEarly = currentDate.isBefore(startDate, 'days');
                    const tooLate = currentDate > moment().add(+6, 'months');
                    return tooEarly || tooLate;
                  }}
                />
              )}
            />
            <Controller
              control={control}
              name="endDateDayPart"
              render={({ field }) => (
                <RadioGroup
                  options={dayParts}
                  onChange={field.onChange}
                  value={field.value as number}
                  optionType="button"
                  disabled={checkIsSameDay(startDate, endDate)}
                />
              )}
            />
          </div>
          <Input
            label="Leave period (days)"
            value={period}
            disabled
          />
          <Controller
            control={control}
            name="comment"
            rules={CommentSchema}
            render={({ field }) => (
              <Textarea
                label="Comment"
                value={field.value || ''}
                onChange={field.onChange}
                error={errors.comment?.message}
                rows={2}
              />
            )}
          />
        </div>
        <ButtonActions
          cancelLabel="Cancel"
          createType="submit"
          createLabel={type === 'create' ? 'Create' : 'Save'}
          cancelClick={cancelHandle}
          isLoading={isFetching}
          offsetTop
        />
      </form>
    </Modal>
  );
};

export default AccountLeaveRequestModal;
