import React, { useState } from 'react';
import Modal from '../../../core/components/modal/Modal';
import { StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Textarea from '../../../core/components/textarea/Textarea';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type ReasonModalProps = {
  visible: boolean,
  reasonValue: string,
  setReasonValue: StringFunctionType,
  onCancel: VoidFunction,
  onSubmit: VoidFunctionType,
  type: 'new' | 'update' | 'delete',
}

const ReasonModal: React.FC<ReasonModalProps> = ({
  visible, reasonValue, setReasonValue, onSubmit, onCancel, type,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const [error, setError] = useState('');

  const MAX_REASON_LENGTH = 250;
  const titleOptions = {
    new: 'New part line',
    update: 'Part line update',
    delete: 'Delete',
  };

  const submitOptions = {
    new: 'Add line',
    update: 'Save changes',
    delete: 'Yes, delete',
  };

  const handleSubmit = () => {
    if (reasonValue.trim().length && reasonValue.trim().length <= MAX_REASON_LENGTH) {
      error && setError('');
      onSubmit();
    } else if (reasonValue.trim().length > MAX_REASON_LENGTH) {
      setError(maxLengthMessageHandle(MAX_REASON_LENGTH));
    } else {
      setError(ErrorsEnum.REQUIRED);
    }
  };

  return (
    <Modal
      visible={visible}
      title={titleOptions[type]}
      onCancel={onCancel}
      afterClose={() => {
        setReasonValue('');
        setError('');
      }}
      destroyOnClose
    >
      {type === 'update' && <p className="confirmationText">
        This change affects the total amount.
      </p>}
      <Textarea
        label={`Please enter a ${type === 'delete' ? 'deletion' : ''} reason`}
        value={reasonValue}
        onChange={(e) => {
          const { value } = e.currentTarget;
          if (value.trim().length > MAX_REASON_LENGTH) {
            setError(maxLengthMessageHandle(MAX_REASON_LENGTH));
          } else {
            error && setError('');
            setReasonValue(value);
          }
        }}
        onBlur={(e) => {
          const { value } = e.currentTarget;
          if (!value.trim()) {
            setError(ErrorsEnum.REQUIRED);
            setReasonValue('');
          }
        }}
        error={error}
      />
      {type === 'delete' && <p className="confirmationText mt-2">
        <span>
          The data will be permanently deleted.
        </span>
        <span className="confirmationText__proceed">Do you want to proceed?</span>
      </p>}
      <ButtonActions
        createType="button"
        createLabel={submitOptions[type]}
        createClick={handleSubmit}
        cancelLabel="Cancel"
        cancelClick={onCancel}
        isLoading={isLoading || isFetching}
        offsetTop
      />
    </Modal>
  );
};

export default ReasonModal;
