import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard, faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isTableViewSelector,
  salesOfficeEmployeeFiltersDateSelector, salesOfficeEmployeeFiltersSelector,
  salesOfficeEmployeeGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import {
  setReportIsVisible, setSalesOfficeEmployeeData, setSalesOfficeEmployeeDataChart, setSalesOfficeEmployeeFilters,
} from '../../../../../store/slices/reportsSlice';
import {
  DateRangeTableFilterType, initDataList, initPaging, PickerValues,
} from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import { useSalesOfficeEmployeeDefaultOrdering } from '../../../../hooks/useSalesOfficeEmployeeDefaultOrdering';
import {
  getSalesOfficeEmployeeReportChartThunk, getSalesOfficeEmployeeReportExcelThunk,
  getSalesOfficeEmployeeReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { initSalesOfficeEmployeeReportData } from '../../../reports-content/sales/sales-office-employee/utils/data';
import { SalesOfficeEmployeeReportFilters } from '../../../../types/SalesOfficeEmployeeReportTypes';

const SalesOfficeEmployeeGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkboxFilters = useAppSelector(salesOfficeEmployeeGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(salesOfficeEmployeeFiltersDateSelector);
  const filters = useAppSelector(salesOfficeEmployeeFiltersSelector);
  const isTableView = useAppSelector(isTableViewSelector);

  const {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    orderNumber, salesNet, daysInNewStatus, page, pageSize, order, ...chartFilters
  } = filters;

  const defaultPagingAndOrder = useSalesOfficeEmployeeDefaultOrdering();

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        businessAreas, customers, initialAssignees, creationDate,
      } = filters;
      const hasSelectedCheckboxes = [businessAreas, customers, initialAssignees].some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = creationDate && (creationDate.min || creationDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker;
    }
    return false;
  }, [filters]);

  const [isExpanded, setIsExpanded] = useState(false);

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));

    return () => {
      dispatch(setSalesOfficeEmployeeFilters(defaultPagingAndOrder));
      dispatch(setSalesOfficeEmployeeData(initSalesOfficeEmployeeReportData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    creationDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: SalesOfficeEmployeeReportFilters = {
      ...filters,
      [field]: values || undefined,
    };
    dispatch(setSalesOfficeEmployeeFilters(newFilters));
    dispatch(setSalesOfficeEmployeeData(initSalesOfficeEmployeeReportData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setSalesOfficeEmployeeFilters(defaultPagingAndOrder));
    dispatch(setSalesOfficeEmployeeData(initSalesOfficeEmployeeReportData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setSalesOfficeEmployeeFilters({
      ...filters,
      salesNet: undefined,
      daysInNewStatus: undefined,
      orderNumber: undefined,
      [field]: values,
    }));
    dispatch(setReportIsVisible(false));
    dispatch(setSalesOfficeEmployeeData(initSalesOfficeEmployeeReportData));
    dispatch(setSalesOfficeEmployeeDataChart(initDataList));
  };

  const exportToExcel = () => {
    const { order } = defaultPagingAndOrder;
    const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      page, pageSize, orderNumber, salesNet, daysInNewStatus, ...rest
    } = filters;
    dispatch(getSalesOfficeEmployeeReportExcelThunk({
      filters: {
        ...rest,
        order,
      },
    }));
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch={false}
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            isTableView
              ? dispatch(getSalesOfficeEmployeeReportThunk({ filters }))
              : dispatch(getSalesOfficeEmployeeReportChartThunk({ filters: { ...chartFilters, ...initPaging } }));
            dispatch(setReportIsVisible(true));
          }}
        />
        <Dropdown
          className="reportFilters__action-dropdown"
          icon={<FontAwesomeIcon icon={faChevronDown} />}
          menuItems={[{
            label: 'Export to Excel',
            key: 'export-to-excel',
            icon: <FontAwesomeIcon icon={faFileExcel} />,
            onClick: exportToExcel,
          }]}
          placement="bottomRight"
          parentRender
        />
      </div>
    </>
  );
};

export default SalesOfficeEmployeeGlobalFilters;
