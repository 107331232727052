import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import Input from '../../../../../../../core/components/input/Input';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../../../core/enums/errorsEnum';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import { maxCommonDecimal } from '../../../../../../../core/utils/regex';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../../../../../core/components/select/Select';
import { useAppSelector } from '../../../../../../../store/hooks';
import {
  integerQuantityMeasureValuesSelector, isFetchingSelector,
  quoteLineTaxesSelector,
  QuoteUnitsOfMeasureSelector,
  twentyPercentTaxSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import Checkbox from '../../../../../../../core/components/checkbox/Checkbox';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import {
  PatchPurchaseOrderPartDataType,
  PostPurchaseOrderPartDataType, PurchaseOrderPartDto,
} from '../../../../types/purchaseOrderViewPageTypes';
import { manualInputDefaults, ManualInputFields } from '../../../../utils/ManualInputSchema';

type ManualInputTabContentProps = {
  selectedPartUnitOfMeasure?: number,
  cancelHandle: VoidFunctionType,
  addPartHandle: (values: PostPurchaseOrderPartDataType, closeModal: VoidFunctionType) => void,
  editPartHandle: (partId: number, values: PatchPurchaseOrderPartDataType, closeHandle: VoidFunctionType) => void,
  selectedPartId?: number,
  itemToChange?: PurchaseOrderPartDto,
}

const ManualInputTabContent: React.FC<ManualInputTabContentProps> = ({
  selectedPartUnitOfMeasure, cancelHandle, addPartHandle,
  editPartHandle, selectedPartId, itemToChange,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const integerQuantitiesMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const selectedPartUnitOfMeasureLabel = unitsOfMeasure.find((el) => el.value === selectedPartUnitOfMeasure)?.label;
  const taxes = useAppSelector(quoteLineTaxesSelector);
  const twentyPercentTax = useAppSelector(twentyPercentTaxSelector);
  const [isSetValueLater, setIsSetValueLater] = useState<boolean>(true);
  const itemToChangeDueIn = itemToChange?.dueIn || 0;
  const minItemToChangeQty = itemToChange?.requestedQuantity ? (itemToChange.requestedQuantity - itemToChangeDueIn) : 0;

  const {
    handleSubmit, control, watch, setValue, reset, clearErrors,
  } = useForm<ManualInputFields>({
    defaultValues: manualInputDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const cancelFormClick = () => {
    cancelHandle();
    reset();
    clearErrors();
    setIsSetValueLater(true);
  };

  const onSubmit = (values: ManualInputFields) => {
    if (selectedPartId) {
      const finalValues = {
        partId: selectedPartId,
        pricelistId: null,
        vat: values.vat as number,
        quantity: +values.quantity,
        costPrice: +values.costPrice,
        sellPrice: isSetValueLater ? null : +values.sellPrice,
      };
      if (itemToChange) {
        editPartHandle(itemToChange.id, finalValues, cancelFormClick);
      } else {
        addPartHandle(
          finalValues,
          cancelFormClick,
        );
      }
    }
  };

  const quantity = watch('quantity');
  const costPrice = watch('costPrice');
  const vat = watch('vat');

  useEffect(() => {
    if (!vat?.toString() && twentyPercentTax) {
      setValue('vat', twentyPercentTax);
    }
    // eslint-disable-next-line
  }, [vat, twentyPercentTax]);

  useEffect(() => {
    if (itemToChange) {
      setValue('costPrice', itemToChange.costPrice.toString() || '0');
      setValue('sellPrice', itemToChange.sellPrice !== null ? itemToChange.sellPrice.toString() : '');
      setIsSetValueLater(itemToChange.sellPrice === null);
      setValue('quantity', itemToChange.requestedQuantity.toString() || '0');
      setValue('vat', itemToChange.vatPercent);
    }
    // eslint-disable-next-line
  }, [itemToChange]);

  const netValue = useMemo(() => {
    const quantityV = +quantity;
    const costPriceV = +costPrice;
    return quantityV * costPriceV;
  }, [quantity, costPrice]);

  const grossValue = useMemo(() => {
    const quantityV = +quantity;
    const costPriceV = +costPrice;
    return quantityV * costPriceV + (quantityV * costPriceV * (vat ? vat * 0.01 : 0));
  }, [quantity, costPrice, vat]);

  const disabledCreate = Object.keys(errors).length > 0 || (+quantity === 0) || (+costPrice === 0);

  const setValueLaterHandler = (v: boolean) => {
    setIsSetValueLater(v);
    if (v) {
      setValue('sellPrice', '');
      errors.sellPrice && clearErrors('sellPrice');
    } else {
      itemToChange && setValue('sellPrice', itemToChange?.sellPrice !== null ? itemToChange?.sellPrice.toString() : '');
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="customLineForm">
      <div className="customLineForm__section">
        <Controller
          name="costPrice"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DecimalInputNumber
              value={field.value}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                field.onChange(v);
              }}
              max={maxCommonDecimal}
              error={errors.costPrice?.message}
              label="Cost price"
              maxDecimalPlaceLength={4}
            />
          )}
        />
        <Controller
          name="sellPrice"
          control={control}
          rules={{
            required: !isSetValueLater && ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <div>
              <DecimalInputNumber
                value={field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                max={maxCommonDecimal}
                error={errors.sellPrice?.message}
                label="Selling price"
                disabled={isSetValueLater}
              />
              <Checkbox
                label="Set value later"
                checked={isSetValueLater}
                onChange={(v) => setValueLaterHandler(v)}
                className="customLineForm__checkbox"
              />
            </div>
          )}
        />
        <Controller
          name="quantity"
          control={control}
          rules={{
            ...RequiredFieldSchema,
            min: {
              value: itemToChange ? minItemToChangeQty : 0,
              message: integerQuantitiesMeasures.includes(selectedPartUnitOfMeasure)
                ? `The min allowed value is ${itemToChange ? minItemToChangeQty : 0}`
                : `The min allowed value is ${itemToChange ? minItemToChangeQty : 0.00}`,
            },
          }}
          render={({ field }) => (
            <>
              {integerQuantitiesMeasures.includes(selectedPartUnitOfMeasure)
                ? <RegularInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value;
                    field.onChange(v);
                  }}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                  postfix={selectedPartUnitOfMeasureLabel || ''}
                />
                : <DecimalInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value.replace(',', '.');
                    field.onChange(v);
                  }}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                  postfix={selectedPartUnitOfMeasureLabel || ''}
                />}
            </>
          )}
        />
        <Controller
          name="vat"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              options={taxes}
              value={field.value}
              onChange={(v) => field.onChange(v)}
              error={errors.vat?.message}
              label="VAT"
              parentRender
            />
          )}
        />
        <Input
          label="Net"
          prefix="£"
          value={netValue.toFixed(2)}
          className="customLineForm__field--semi"
          disabled
        />
        <Input
          label="Gross"
          prefix="£"
          value={grossValue.toFixed(2)}
          className="customLineForm__field--semi"
          disabled
        />
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        createType="submit"
        createLabel={itemToChange ? 'Save' : 'Add part'}
        disabledCreate={disabledCreate}
        cancelClick={() => cancelFormClick()}
        isLoading={isFetching}
        offsetTop
      />
    </form>
  );
};

export default ManualInputTabContent;
