import React from 'react';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import moment from 'moment';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import DatePickerComponent from '../../../core/components/date-picker/DatePicker';
import RadioGroup from '../../../core/components/radio-group/RadioGroup';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { useAppSelector } from '../../../store/hooks';
import { employeeDepartmentsSelector, employeesSalaryTypesSelector } from '../../../store/selectors/employeesSelector';
import {
  DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT, maxEightDigitsBeforeDot, maxTwoDigitsBeforeDot,
} from '../../../core/utils/regex';
import { EmployeeFields, JobTitleSchema } from '../../employee-view-page/utils/EmployeeSchema';

type EmployeeDetailsSectionProps = {
  control: Control<EmployeeFields>,
  errors: FieldErrors<EmployeeFields>,
  treatSpacesAsEmptyHandle: (v: string, field: keyof EmployeeFields, required?: boolean,) => void,
}

const EmployeeDetailsSection: React.FC<EmployeeDetailsSectionProps> = ({ control, errors, treatSpacesAsEmptyHandle }) => {
  const employeesSalaryTypes = useAppSelector(employeesSalaryTypesSelector);
  const employeeDepartments = useAppSelector(employeeDepartmentsSelector);

  return (
    <section className="details-form">
      <h2 className="info-grid__title">Employment details</h2>
      <Controller
        name="jobTitle"
        control={control}
        rules={JobTitleSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(event) => {
              const v = event.target.value;
              treatSpacesAsEmptyHandle(v, 'jobTitle');
            }}
            error={errors.jobTitle?.message}
            label="Job title"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="department"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={employeeDepartments}
            error={errors.department?.message}
            label="Department"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="startDate"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <DatePickerComponent
            value={field.value ? moment(field.value) : undefined}
            onChange={(v) => {
              v && field.onChange(v.format(DEFAULT_DATE_FORMAT));
            }}
            label="Start date"
            error={errors.startDate?.message}
            format={DEFAULT_UI_DATE_FORMAT}
            disabledDate={(currentDate) => {
              const amountYearsFromDate = moment().subtract(120, 'years');
              const tooEarly = moment(currentDate).add(+1, 'days') <= amountYearsFromDate;
              const tooLate = currentDate > moment().add(+1, 'month');
              return tooEarly || tooLate;
            }}
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="salaryType"
        control={control}
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <RadioGroup
            value={field.value || ''}
            onChange={(v) => {
              field.onChange(v);
            }}
            options={employeesSalaryTypes}
            optionType="button"
            label="Salary type"
            error={errors.salaryType?.message}
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        name="salaryValue"
        control={control}
        render={({ field }) => (
          <DecimalInputNumber
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value.replace(',', '.'));
            }}
            max={maxEightDigitsBeforeDot}
            label="Salary value"
          />
        )}
      />
      <Controller
        name="annualLeaveEntitlement"
        control={control}
        render={({ field }) => (
          <DecimalInputNumber
            label="Annual leave entitlement"
            value={field.value}
            onChange={(e) => {
              const v = e.target.value.replace(',', '.');
              field.onChange(v);
            }}
            max={maxTwoDigitsBeforeDot}
          />
        )}
      />
    </section>
  );
};

export default EmployeeDetailsSection;
