import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AssigneeDto,
  CoreAttachmentsFilters, CoreAttachmentsList,
  DataListType, DictionaryItem, DictionaryResponse, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters, initSmallPaging, ResponseResult, ResponseSingleResult,
} from '../../core/types/coreTypes';
import { getPurchaseOrderAttachments } from '../thunks/stock/purchase-orders/purchaseOrderAttachmentsThunks';
import {
  GetPurchaseOrderResponse,
  InitPurchaseOrderDashFilters,
  PurchaseOrderDashFiltersType, PurchaseOrderDataListType,
  PurchaseOrdersInitDataList, PurchaseOrdersResponseResult, StockPurchaseOrderDetails,
} from '../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import {
  getPurchaseOrderById,
  getPurchaseOrderPartsList,
  getPurchaseOrderPartState,
  getPurchaseOrderPartSummaryThunk,
  getPurchaseOrderTimeline,
} from '../thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import {
  getPurchaseOrderFailReason, getPurchaseOrderPartVat,
  getPurchaseOrders,
  getPurchaseOrdersOrdering,
} from '../thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import {
  GetPurchaseOrderPartsResponse,
  PurchaseOrderEventDtoBase,
  PurchaseOrderPartDto, PurchaseOrderPartsListFiltersType,
  PurchaseOrderSummaryResponseType,
} from '../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import { PartDtoType, StockFiltersType } from '../../stock/search/types/stockSearchTypes';
import { initPartPurchaseOrdersFilters } from '../../stock/part-view-page/types/types';
import { getPartPurchaseOrders } from '../thunks/stock/part/partViewPageThunks';
import { getMaxPurchaseOrderNumberThunk } from '../thunks/stock/purchase-orders/createPurchaseOrderThunks';
import { ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';

interface IPurchaseOrderState {
  purchaseOrdersFilters: PurchaseOrderDashFiltersType,
  purchaseOrdersOrdering: DictionaryItem[],
  purchaseOrders: PurchaseOrderDataListType,
  purchaseOrdersAssignees: Array<AssigneeDto>,
  purchaseOrderDetails: StockPurchaseOrderDetails | null,
  purchaseOrderSummary: PurchaseOrderSummaryResponseType | null,
  purchaseOrderAttachments: CoreAttachmentsList,
  purchaseOrderAttachmentsFilters: CoreAttachmentsFilters,
  purchaseOrderPartFailReason: DictionaryItem[],
  purchaseOrderPartsList: { purchaseOrderParts: PurchaseOrderPartDto[], allItemIds: number[] },
  purchaseOrderPartsListFilters?: PurchaseOrderPartsListFiltersType,
  partPurchaseOrdersFilters: PurchaseOrderDashFiltersType,
  partPurchaseOrders: PurchaseOrderDataListType,
  purchaseOrderPartSearch: DataListType<PartDtoType[] | undefined>,
  purchaseOrderPartSearchFilters: StockFiltersType,
  purchaseOrderMaxNumber: string | null,
  purchaseOrderPartVat: DictionaryItem[],
  purchaseOrderPartState: DictionaryItem[],
  purchaseOrderTimeline: DataListType<PurchaseOrderEventDtoBase[]>,
  purchaseOrderTimelineFilters: ExtendedTimelineFilters,
}

export const purchaseOrderInitialState: IPurchaseOrderState = {
  purchaseOrdersFilters: InitPurchaseOrderDashFilters,
  purchaseOrdersOrdering: [],
  purchaseOrders: PurchaseOrdersInitDataList,
  purchaseOrdersAssignees: [],
  purchaseOrderDetails: null,
  purchaseOrderSummary: null,
  purchaseOrderAttachments: initDataListWithIds,
  purchaseOrderAttachmentsFilters: initialCoreAttachmentsFilters,
  purchaseOrderPartFailReason: [],
  purchaseOrderPartsList: { purchaseOrderParts: [], allItemIds: [] },
  purchaseOrderPartsListFilters: undefined,
  partPurchaseOrdersFilters: initPartPurchaseOrdersFilters,
  partPurchaseOrders: PurchaseOrdersInitDataList,
  purchaseOrderPartSearch: initDataList,
  purchaseOrderPartSearchFilters: { ...initSmallPaging, isCorePart: [true] },
  purchaseOrderMaxNumber: null,
  purchaseOrderPartVat: [],
  purchaseOrderPartState: [],
  purchaseOrderTimeline: initDataList,
  purchaseOrderTimelineFilters: initExtendedTimelineFilters,
};

export const purchaseOrderSlice = createSlice({
  name: 'purchaseOrder',
  initialState: purchaseOrderInitialState,
  reducers: {
    setPurchaseOrders: (
      state,
      action: PayloadAction<PurchaseOrderDataListType>,
    ) => {
      state.purchaseOrders = action.payload;
    },
    setPurchaseOrdersAssignees: (state, action: PayloadAction<AssigneeDto[]>) => {
      state.purchaseOrdersAssignees = action.payload;
    },
    setPurchaseOrdersFilters: (state, action: PayloadAction<PurchaseOrderDashFiltersType>) => {
      state.purchaseOrdersFilters = action.payload;
    },
    setPurchaseOrderDetails: (state, action: PayloadAction<StockPurchaseOrderDetails | null>) => {
      state.purchaseOrderDetails = action.payload;
    },
    setPurchaseOrderSummary: (state, action: PayloadAction<PurchaseOrderSummaryResponseType | null>) => {
      state.purchaseOrderSummary = action.payload;
    },
    setPurchaseOrderAttachmentsFilter: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.purchaseOrderAttachmentsFilters = action.payload;
    },
    setPurchaseOrderAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.purchaseOrderAttachments = action.payload;
    },
    setPurchaseOrderPartsListFilters: (state, action: PayloadAction<PurchaseOrderPartsListFiltersType | undefined>) => {
      state.purchaseOrderPartsListFilters = action.payload;
    },
    setPurchaseOrderPartsList: (state, action: PayloadAction<{ purchaseOrderParts: PurchaseOrderPartDto[], allItemIds: number[] }>) => {
      state.purchaseOrderPartsList = action.payload;
    },
    setPurchaseOrderPartSearchFilters: (state, action: PayloadAction<StockFiltersType>) => {
      state.purchaseOrderPartSearchFilters = action.payload;
    },
    setPurchaseOrderPartSearch: (state, action: PayloadAction<DataListType<PartDtoType[] | undefined>>) => {
      state.purchaseOrderPartSearch = action.payload;
    },
    setPartPurchaseOrdersFilters: (state, action: PayloadAction<PurchaseOrderDashFiltersType>) => {
      state.partPurchaseOrdersFilters = action.payload;
    },
    setPartPurchaseOrders: (state, action: PayloadAction<PurchaseOrderDataListType>) => {
      state.partPurchaseOrders = action.payload;
    },
    setPurchaseOrderMaxNumber: (state, action: PayloadAction<string | null>) => {
      state.purchaseOrderMaxNumber = action.payload;
    },
    setPurchaseOrderTimeline: (state, action: PayloadAction<DataListType<PurchaseOrderEventDtoBase[]>>) => {
      state.purchaseOrderTimeline = action.payload;
    },
    setPurchaseOrderTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.purchaseOrderTimelineFilters = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      getPurchaseOrders.fulfilled,
      (state, action: PayloadAction<PurchaseOrdersResponseResult>) => {
        state.purchaseOrders = action.payload.data;
        state.purchaseOrdersAssignees = action.payload.data.assignees || [];
      },
    );
    builder.addCase(
      getPurchaseOrdersOrdering.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }>>) => {
        const { items } = action.payload.data;
        state.purchaseOrdersOrdering = items;
        state.purchaseOrdersFilters = { ...state.purchaseOrdersFilters, order: { field: items[0].value, isAsc: true } };
      },
    );
    builder.addCase(
      getPurchaseOrderById.fulfilled,
      (state, action: PayloadAction<GetPurchaseOrderResponse>) => {
        const { purchaseOrder, billingRequisite, areLinesCreatable } = action.payload.data;
        state.purchaseOrderDetails = { purchaseOrder, billingRequisite, areLinesCreatable };
      },
    );
    builder.addCase(
      getPurchaseOrderAttachments.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.purchaseOrderAttachments = action.payload.data;
      },
    );
    builder.addCase(getPurchaseOrderFailReason.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.purchaseOrderPartFailReason = action.payload?.data.items || [];
    });
    builder.addCase(
      getPurchaseOrderPartSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<PurchaseOrderSummaryResponseType | null>>) => {
        state.purchaseOrderSummary = action.payload?.data || null;
      },
    );
    builder.addCase(
      getPurchaseOrderPartsList.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetPurchaseOrderPartsResponse>>) => {
        state.purchaseOrderPartsList = action.payload.data;
      },
    );
    builder.addCase(
      getPartPurchaseOrders.fulfilled,
      (state, action: PayloadAction<PurchaseOrdersResponseResult>) => {
        state.partPurchaseOrders = action.payload.data;
      },
    );
    builder.addCase(
      getMaxPurchaseOrderNumberThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ maxPurchaseOrderNumber: string | null }>>) => {
        state.purchaseOrderMaxNumber = action.payload.data.maxPurchaseOrderNumber;
      },
    );
    builder.addCase(
      getPurchaseOrderPartVat.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }> | null>) => {
        state.purchaseOrderPartVat = action.payload?.data.items || [];
      },
    );
    builder.addCase(
      getPurchaseOrderPartState.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ items: DictionaryItem[] }> | null>) => {
        state.purchaseOrderPartState = action.payload?.data.items || [];
      },
    );
    builder.addCase(
      getPurchaseOrderTimeline.fulfilled,
      (state, action: PayloadAction<ResponseResult<PurchaseOrderEventDtoBase[]>>) => {
        state.purchaseOrderTimeline = action.payload?.data || initDataList;
      },
    );
  },
});

export const {
  setPurchaseOrders,
  setPurchaseOrdersAssignees,
  setPurchaseOrdersFilters,
  setPurchaseOrderDetails,
  setPurchaseOrderAttachmentsFilter,
  setPurchaseOrderAttachments,
  setPurchaseOrderSummary,
  setPurchaseOrderPartsListFilters,
  setPurchaseOrderPartsList,
  setPurchaseOrderPartSearchFilters,
  setPurchaseOrderPartSearch,
  setPartPurchaseOrdersFilters,
  setPartPurchaseOrders,
  setPurchaseOrderMaxNumber,
  setPurchaseOrderTimeline,
  setPurchaseOrderTimelineFilters,
} = purchaseOrderSlice.actions;

export default purchaseOrderSlice.reducer;
