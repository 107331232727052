import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Pagination from '../../../../core/components/pagination/Pagination';
import {
  assignedNominalCodesFiltersSelector,
  assignedNominalCodesSelector,
} from '../../../../store/selectors/invoicingSelectors';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { BooleanFunctionType, PagingType } from '../../../../core/types/coreTypes';
import {
  setAssignedNominalCodesFilters,
} from '../../../../store/slices/invoicingSlice';
import Button from '../../../../core/components/button/Button';

type AssignNominalCodesActionsProps = {
  setIsAddNominalCode: BooleanFunctionType,
}

const AssignNominalCodesActions: React.FC<AssignNominalCodesActionsProps> = ({ setIsAddNominalCode }) => {
  const dispatch = useAppDispatch();
  const { totalCount } = useAppSelector(assignedNominalCodesSelector);
  const filters = useAppSelector(assignedNominalCodesFiltersSelector);
  const changePagingHandle = (value: PagingType) => {
    dispatch(setAssignedNominalCodesFilters({ ...filters, ...value }));
  };
  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Button
          label="Add nominal record"
          onClick={() => setIsAddNominalCode(true)}
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          designType="dark"
          reversed
        />
      </div>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => changePagingHandle({ page, pageSize })}
        showTotal
        showSizeChanger
        totalItems={totalCount}
        narrow
        className="actions-and-paging__paging"
      />
    </div>
  );
};

export default AssignNominalCodesActions;
