import React from 'react';
import { BooleanFunctionType, StringFunctionType } from '../../../../../core/types/coreTypes';
import { useScreenWitdh } from '../../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../../core/components/selectable/Selectable';
import Segmented from '../../../../../core/components/segmented/Segmented';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { PurchaseOrderDto } from '../../../dashboard/types/purchaseOrdersTypes';
import { setErrorMessage } from '../../../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import {
  purchaseOrderStatusTransitionSelector,
} from '../../../../../store/selectors/purchaseOrderSelectors';
import {
  patchPurchaseOrderByIdThunk,
  putPurchaseOrderByIdThunk,
} from '../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { usersFilterLookupSelector } from '../../../../../store/selectors/coreSelectors';
import { PurchaseOrderViewParamEnum } from '../../enums/PurchaseOrderViewEnum';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { purchaseOrderStatusObjSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { useUsersDictionary } from '../../../../../common/hooks/useUsersDictionary';

type PurchaseOrderContentTabsProps = {
  mode: string | null;
  setModeParam: StringFunctionType;
  allowedTabs: { label: string, value: PurchaseOrderViewParamEnum }[],
  purchaseOrderDetails: PurchaseOrderDto | null,
  setStatusModal: BooleanFunctionType,
}

const PurchaseOrderContentTabs: React.FC<PurchaseOrderContentTabsProps> = ({
  mode, setModeParam, allowedTabs, purchaseOrderDetails, setStatusModal,
}) => {
  const allowedToView = usePermission(PermissionEnum.StockPurchaseOrdersViewPage);
  const allowedToManage = usePermission(PermissionEnum.StockPurchaseOrdersEditFields);
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const statusTransition = useAppSelector(purchaseOrderStatusTransitionSelector);
  const usersLookup = useAppSelector(usersFilterLookupSelector);
  const { finalised, sentToSupplier } = useAppSelector(purchaseOrderStatusObjSelector);

  const { setUserSearch } = useUsersDictionary();

  const statusChangeHandle = (v: number) => {
    if (v === sentToSupplier && purchaseOrderDetails?.status === finalised) {
      setStatusModal(true);
    } else {
      purchaseOrderDetails && dispatch(putPurchaseOrderByIdThunk({
        id: purchaseOrderDetails?.id,
        data: { statusTransition: v },
      }));
    }
  };

  return (
    <>
      {windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-lg">
          <Selectable
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            disabled={!allowedToView}
          />
        </div>
      )}
      <div className="purchaseOrderContentTabs desktop-sticky-filters">
        {windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedTabs}
            value={mode || ''}
            onChange={(value) => setModeParam(value as string)}
            className="purchaseOrderContentTabs__segmented"
            disabled={!allowedToView}
          />)}
        <div className="purchaseOrderContentTabs__actions">
          <Select
            options={usersLookup}
            value={purchaseOrderDetails?.assigneeId || ''}
            onChange={(v) => {
              if (purchaseOrderDetails) {
                if (purchaseOrderDetails.assigneeId !== v) {
                  dispatch(patchPurchaseOrderByIdThunk({
                    id: purchaseOrderDetails?.id,
                    data: { assigneeId: v as number },
                  }));
                }
              }
            }}
            showSearch
            onSearch={(val) => {
              if (val.length <= 250) {
                setUserSearch(val);
              } else {
                dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
              }
            }}
            onSelect={() => {
              setUserSearch(undefined);
            }}
            showArrow
            className="purchaseOrderContentTabs__select-highlighted"
            disabled={!allowedToManage}
            parentRender
          />
          <Select
            options={statusTransition}
            value={purchaseOrderDetails?.status || ''}
            onChange={(v) => statusChangeHandle(v as number)}
            disabled={!allowedToManage}
            showArrow
            parentRender
          />
        </div>
      </div>
    </>
  );
};

export default PurchaseOrderContentTabs;
