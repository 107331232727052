import React from 'react';
import SkillsTableHead from './SkillsTableHead';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import SkillsTableBody from './SkillsTableBody';
import { useAppSelector } from '../../../store/hooks';
import { skillsUiListSelector } from '../../../store/selectors/employeesSelector';
import SkillsTableFooter from './SkillsTableFooter';
import TableNew from '../../../core/components/table-components/table-new/TableNew';

type SkillsTableProps = {
  checkedKeys: number[],
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkRows: NumberFunctionType,
  openEditCategory: NumberFunctionType,
  openEditSkill: NumberFunctionType,
  addSkill: VoidFunctionType,
  openDeleteModal: (categoryId?: number, skillId?: number) => void,
}

const SkillsTable: React.FC<SkillsTableProps> = ({
  isAll, isIndeterminate, checkAll, checkRows, checkedKeys, openEditCategory, openEditSkill,
  addSkill, openDeleteModal,
}) => {
  const skillsData = useAppSelector(skillsUiListSelector);

  return (
    <TableNew>
      <SkillsTableHead
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAll}
        totalElements={skillsData.length}
        disabled={skillsData.length === 0}
        openEditCategory={openEditCategory}
        openDeleteModal={openDeleteModal}
      />
      <SkillsTableBody
        disabled={skillsData.length === 0}
        checkRows={checkRows}
        checkedKeys={checkedKeys}
        openEditSkill={openEditSkill}
        openDeleteModal={openDeleteModal}
      />
      <SkillsTableFooter
        checkedKeys={checkedKeys}
        addSkill={addSkill}
        openDeleteModal={openDeleteModal}
      />
    </TableNew>
  );
};

export default SkillsTable;
