import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, emptyPaging, GetCustomerContactsResponse,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import {
  RectificationContactDto,
  NewContactReqDataType,
} from '../../../rectifications/rectification-view-page/types/rectificationViewTypes';
import { rectificationsAPI } from '../../../api/rectificationsApi';
import { SUCCESSFUL_REMOVE } from '../../../core/utils/successMessages';
import { fetchRectificationByIdThunk } from './rectificationViewPageThunks';
import { setRectificationContactsPaging } from '../../slices/rectificationsSlice';
import { CustomerContactFiltersType } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { customersAPI } from '../../../api/customersApi';
import { RootState } from '../../store';

export const getRectificationContactsThunk = createAsyncThunk<ResponseResult<RectificationContactDto[], { allItemIds: number[] }>,
{ rectificationId: number, page: number, pageSize: number }>(
  'get/Rectification/Contacts',
  async ({
    rectificationId, page, pageSize,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationContacts(rectificationId, page, pageSize);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const removeRectificationContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ rectificationId: number, customerContactId: number, closeModal: VoidFunctionType }>(
  'remove/Rectification/Contact',
  async ({
    rectificationId, customerContactId, closeModal,
  }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setLoading(true));
    try {
      const { rectifications: { rectificationContactsPaging } } = getState() as RootState;
      const response = await rectificationsAPI.removeRectificationContact(rectificationId, customerContactId);
      dispatch(fetchRectificationByIdThunk({ rectificationId }));
      dispatch(setRectificationContactsPaging({ ...rectificationContactsPaging, page: 1 }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_REMOVE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationExistingContactsThunk = createAsyncThunk<GetCustomerContactsResponse,
{ customerId: number, notAddedToRectificationId:number, filters: CustomerContactFiltersType }>(
  'get/Rectification/ExistingContacts',
  async ({
    customerId, notAddedToRectificationId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerContacts(customerId, filters, { notAddedToRectificationId });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getGenerateEmailRectificationContactsThunk = createAsyncThunk<
ResponseResult<RectificationContactDto[], { allItemIds: number[] }>,
{ rectificationId: number, page: number, pageSize: number, haveEmail: boolean }>(
  'get/RectificationEmail/Contacts',
  async ({
    rectificationId, page, pageSize, haveEmail,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await rectificationsAPI.fetchRectificationContacts(rectificationId, page, pageSize, haveEmail);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewRectificationContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ rectificationId: number, data: NewContactReqDataType, closeModal: VoidFunctionType }>(
  'post/Rectification/NewContact',
  async ({
    rectificationId, data, closeModal,
  }, { dispatch, rejectWithValue, getState }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications: { rectificationContactsPaging } } = getState() as RootState;
      const response = await rectificationsAPI.createNewRectificationContact(rectificationId, data);
      dispatch(fetchRectificationByIdThunk({ rectificationId }));
      dispatch(setRectificationContactsPaging({ ...rectificationContactsPaging, page: 1 }));
      closeModal();
      const { firstName, lastName } = data;
      dispatch(setSuccessMessage({ message: `Contact ${firstName} ${lastName} was successfully created.` }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addRectificationExistingContactsThunk = createAsyncThunk<null,
{ rectificationId: number, customerId: number, customerContactIds: number[], closeModal: VoidFunctionType }>(
  'post/Rectification/ExistingContacts',
  async ({
    rectificationId, customerId, customerContactIds, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { rectifications: { rectificationExistingContactsPaging, rectificationContactsPaging } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await customersAPI.fetchCustomerContacts(
        customerId,
        { ...rectificationExistingContactsPaging, ...emptyPaging },
        { notAddedToRectificationId: rectificationId },
      );
      const filteredIds = customerContactIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await rectificationsAPI.addExistingRectificationContacts(rectificationId, filteredIds);
      }
      dispatch(fetchRectificationByIdThunk({ rectificationId }));
      dispatch(setRectificationContactsPaging({ ...rectificationContactsPaging, page: 1 }));
      closeModal();
      dispatch(setFetching(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
