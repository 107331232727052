import React, { useEffect } from 'react';
import {
  Controller, FieldErrors, useForm, useFormState,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  allowedShortBaForUserSelector,
  customerPricelistTypeSelector,
  pricelistTypesSelector,
} from '../../../store/selectors/coreSelectors';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { createPricelistThunk } from '../../../store/thunks/pricelist/createPricelistThunk';
import { getCustomers } from '../../../store/thunks/customers/customersDashboardThunks';
import { useCustomerPricelistLookup } from '../../pricelist-view-page/hooks/useCustomerPricelistLookup';
import { usePricelistSupplierLookup } from '../../pricelist-view-page/hooks/usePricelistSupplierLookup';
import { pricelistDefaults, PricelistFields, PricelistNameSchema } from '../utils/PricelistSchema';

const CreatePricelistForm: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const allowedShortBaForUser = useAppSelector(allowedShortBaForUserSelector);
  const pricelistTypes = useAppSelector(pricelistTypesSelector);
  const customerPricelistType = useAppSelector(customerPricelistTypeSelector);

  const {
    customersLookup,
    searchValue,
    setSearchValue,
    searchError,
    setSearchError,
  } = useCustomerPricelistLookup();
  const {
    suppliersLookup,
    setSearchValue: setSuppliersSearchValue,
    searchValue: supplierSearchValue,
    setSearchError: setSupplierSearchError,
    searchError: supplierSearchError,
  } = usePricelistSupplierLookup();

  const {
    control, handleSubmit, reset, setError, setValue, watch,
  } = useForm<PricelistFields>({
    defaultValues: pricelistDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const type = watch('type');

  const onSubmit = (values: PricelistFields) => {
    dispatch(createPricelistThunk({
      data: {
        ...values,
        supplierId: values.supplierId as number,
        type: values.type as number,
        name: values.name.trim(),
      },
      navigate,
      reset,
    }));
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        dispatch(getCustomers({ params: { keyword: searchValue.trim() } }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getCustomers({ params: { keyword: undefined } }));
    }
    // eslint-disable-next-line
    }, [searchValue]);

  console.debug('errors', errors);
  return (
    <form className="create-form details-form" onSubmit={handleSubmit(onSubmit)}>
      <Controller
        control={control}
        name="name"
        rules={PricelistNameSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v.trim()) {
                setValue('name', '');
                setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.name?.message}
            label="Pricelist name"
            className="details-form__field--lg"
          />
        )}
      />
      <Controller
        control={control}
        name="supplierId"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value as number}
            onChange={(val) => field.onChange(val)}
            label="Supplier"
            options={suppliersLookup}
            className="details-form__field--lg"
            error={errors.supplierId?.message || supplierSearchError}
            onSelect={() => setSuppliersSearchValue('')}
            showSearch
            searchValue={supplierSearchValue}
            onSearch={(keyword: string) => {
              if (keyword.length <= 250) {
                setSupplierSearchError('');
                setSuppliersSearchValue(keyword);
              } else {
                setSuppliersSearchValue(keyword.substring(0, 250));
              }
            }}
          />
        )}
      />
      <Controller
        control={control}
        name="businessAreas"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            label="Pricelist business areas"
            options={allowedShortBaForUser}
            className="details-form__field--lg"
            mode="multiple"
            showArrow
            error={(errors.businessAreas as FieldErrors)?.message}
          />
        )}
      />
      <Controller
        control={control}
        name="type"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value as number}
            onChange={(val) => field.onChange(val)}
            label="Pricelist type"
            options={pricelistTypes}
            className="details-form__field--lg"
            error={errors.type?.message}
          />
        )}
      />
      {type === customerPricelistType?.value && <Controller
        control={control}
        name="customerIds"
        rules={{ required: type === customerPricelistType?.value && ErrorsEnum.REQUIRED }}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={(val) => field.onChange(val)}
            onSearch={(val) => {
              if (val.trim()) {
                if (val.length <= 250) {
                  setSearchValue(val);
                } else {
                  setSearchError(maxLengthMessageHandle(250));
                }
              } else {
                setSearchValue(undefined);
              }
            }}
            onSelect={() => {
              setSearchValue(undefined);
              searchError && setSearchError('');
            }}
            onBlur={() => {
              setSearchValue(undefined);
              searchError && setSearchError('');
            }}
            label="Customers"
            disabled={!type}
            options={customersLookup}
            className="details-form__field--lg"
            mode="multiple"
            showArrow
            showSearch
            error={((errors.customerIds as FieldErrors)?.message) || searchError}
          />
        )}
      />}
      <ButtonActions
        createLabel="Create"
        cancelLabel="Clear"
        createType="submit"
        cancelClick={() => reset()}
        className="details-form__actions"
      />
    </form>
  );
};

export default CreatePricelistForm;
