import React, { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { VehicleChecksLineUi } from '../../types/VehicleInspectionTypes';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import VehicleChecksColumns from './VehicleChecksColumns';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import Dropdown from '../../../../../../../core/components/dropdown/Dropdown';
import './VehicleChecksTable.scss';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { vehicleCheckTypesValuesSelector } from '../../../../../../../store/selectors/coreSelectors';
import { NumberFunctionType } from '../../../../../../../core/types/coreTypes';
import PrivateLineLink from '../../../../../../../common/components/private-line-link/PrivateLineLink';
import { PermissionEnum } from '../../../../../../../core/enums/dictionariesEnums';
import { routesPath } from '../../../../../../../core/enums/pathEnum';
import { generateVehicleHandoverCheckPdfThunk } from '../../../../../../../store/thunks/vehicles/vehicleViewPageThunks';

type VehicleChecksTableProps = {
  items: VehicleChecksLineUi[],
  totalCount: number,
  setCheckIdToDelete: NumberFunctionType,
  allowedToEdit?: boolean,
}

const VehicleChecksTable: React.FC<VehicleChecksTableProps> = ({
  items, totalCount, setCheckIdToDelete, allowedToEdit,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { full, weight, handover } = useAppSelector(vehicleCheckTypesValuesSelector);
  const linksPath = useMemo(() => {
    let links: Record<number, string> = {};
    if (full && weight && handover) {
      links = {
        [full]: routesPath.FULL_VEHICLE_CHECK,
        [weight]: routesPath.WEIGHT_VEHICLE_CHECK,
        [handover]: routesPath.HANDOVER_VEHICLE_CHECK,
      };
    }
    return links;
  }, [full, weight, handover]);

  const nowrapValues = ['mileage', 'weight', 'checkDate'];

  const getMenuItems = (checkType: number, vehicleCheckId: number) => {
    switch (checkType) {
      case weight:
        return [{
          label: 'Delete check record',
          key: 'delete-check-record',
          onClick: () => setCheckIdToDelete(vehicleCheckId),
          icon: <FontAwesomeIcon icon={faTrashAlt} />,
          disabled: !allowedToEdit,
        }];
      case handover:
        return [{
          label: 'Export to PDF',
          key: 'export-to-pdf',
          onClick: () => id && dispatch(generateVehicleHandoverCheckPdfThunk({ vehicleId: +id, vehicleCheckId })),
          icon: <FontAwesomeIcon icon={faFilePdf} />,
        }];
      default: return [];
    }
  };

  const renderTruncateTooltip = (value: string | number) => {
    return <TruncateTooltip value={value} table />;
  };

  const renderCellValue = (key: string, value: string | number, vehicleCheckType: number, checkId: number) => {
    if (key === 'type') {
      const linkTo = `/${linksPath[vehicleCheckType]}/${id}/${checkId}`;
      return <PrivateLineLink to={linkTo} permission={PermissionEnum.VehicleViewPage} fitContent>
        {renderTruncateTooltip(value)}
      </PrivateLineLink>;
    }
    if (key === 'checkDecision') {
      return renderTruncateTooltip(value);
    }
    return value;
  };

  return (
    <TableNew>
      <VehicleChecksColumns totalCount={totalCount} />
      <Tbody>
        {items.length > 0
          ? items.map((el, i) => <Tr key={i}>
            {Object.entries(el).map(([key, value]) => {
              if (key === 'id' || key === 'vehicleCheckType') return null;
              return <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
                {renderCellValue(key, value, el.vehicleCheckType, el.id)}
              </TdNew>;
            })}
            <TdNew action>
              {el.vehicleCheckType !== full && (
                <Dropdown
                  icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                  menuItems={getMenuItems(el.vehicleCheckType, el.id)}
                />
              )}
            </TdNew>
          </Tr>)
          : <EmptyTableSection text="No checks" />}
      </Tbody>
    </TableNew>
  );
};

export default VehicleChecksTable;
