import {
  CoreAttachmentDto, DataListType, LinkedVehicleDto, ResponseResult,
} from '../../../core/types/coreTypes';
import { PartQtyType } from '../../../stock/part-view-page/types/types';
import { ActivityEventBasicFieldsType, OldNewLocationEventValues } from '../../../common/types/commonTypes';
import { JobAssigneeDto } from '../../jobs/types/jobsTypes';

export type JobDetailsFields = {
  priorityLevel?: number,
  jobCategories?: number[],
  jobType?: number,
  description?: string,
}

export const JobDetailsDefaultFields: JobDetailsFields = {
  priorityLevel: undefined,
  jobCategories: undefined,
  jobType: undefined,
  description: '',
};

export type JobDetailsDto = {
  isDeleted: boolean,
  orderId: number | null,
  orderNumber: string | null,
  rectificationId: number | null,
  rectificationNumber: string | null,
  id: number,
  businessArea: number,
  jobNumber: string | null,
  priorityLevel: number,
  jobStatus: number,
  jobCategories: number[],
  jobType: number,
  dueDate: string | null,
  jobCompanyName: string | null,
  jobAddressLine1: string | null,
  jobAddressLine2: string | null,
  jobCityTown: string | null,
  jobRegion: string | null,
  jobPostalCode: string | null,
  jobTelephone: string | null,
  estimatedMinutes: number | null,
  spentMinutes: number | null,
  description: string | null,
  ordinalNumber: number,
  linkedNumber: string | null,
  linkedId: number | null,
  parentJobId: number,
  isMultipleUserJob: boolean,
  createdAt: string | null,
  updatedAt: string | null,
  statusTransitions: number[] | null,
}

export type JobCustomerDto = {
  id: number,
  name: string | null,
  accountNumber: string | null,
  type: number,
}

export type GetJobResponse = {
  job: JobDetailsDto,
  vehicle: LinkedVehicleDto,
  customer: JobCustomerDto,
  assignees: JobAssigneeDto[] | null,
  previousAssignees: JobAssigneeDto[] | null,
  partRequest: { id: number, enableNotes: boolean } | null,
  partsCount: number,
  attachmentsCount: number,
  linksCount: number,
}

export type JobDetailsFiltersType = {
  assignees?: number[],
  statusTransition?: number,
};

export const JobDetailsInitFilters = { assignees: undefined, statusTransition: undefined };

export type PatchJobDto = {
  priorityLevel?: number,
  jobType?: number,
  jobCategories?: number[],
  jobCompanyName?: string | null,
  jobAddressLine1?: string | null,
  jobAddressLine2?: string | null,
  jobCityTown?: string | null,
  jobRegion?: string | null,
  jobPostalCode?: string | null,
  jobTelephone?: string | null,
  description?: string | null,
  estimationMinutes?: number | null,
}

export type JobTimeTrackingWorkflowDto = {
  isAssigneeDeleted: boolean | null,
  jobSpentMinutes: number | null,
  jobAssignee: string | null,
}

export type GetJobTimeTrackingResponse = {
  estimated: number | null,
  worklogs: Array<JobTimeTrackingWorkflowDto>,
  remaining: number | null,
}

export type JobEventDtoBase = {
  jobEventType: number,
  // job creation
  jobNumber?: string,
  // note
  id?: number,
  // worklogs
  assignee?: string,
  oldTimeSpent?: string | null,
  newTimeSpent?: string | null,
  status?: number,
  checkDate?: string | null,
} & ActivityEventBasicFieldsType & OldNewLocationEventValues

export type JobPartsTableLine = {
  id: number,
  ordinalNumber: string,
  categoryId: string | undefined,
  productNumber: string,
  productName: string,
  notes?: string,
  quantity: number,
  readyQuantities: PartQtyType[] | null,
  readyQuantity: number | string,
  collectedQuantities: PartQtyType[] | null,
  collectedQuantity: number | string,
  status: string,
  unitOfMeasure: number,
  partsKitId: number | null,
  partId: number | null,
  partsKitComponentId: number | null,
  partsKitComponentPartId:number | null,
  childLines: Omit<JobPartsTableLine, 'childLines'>[] | null,
}

export type JobQualityCheckDto = {
  id: number | null,
  status: number,
  checkDate: string | null,
};

export type PostJobQualityCheckRestModel = { jobQualityChecks: Array<{ partRequestLineId: number, status: number }> }

export type JobQualityCheckPartTableLine = {
  id: number,
  ordinalNumber: string,
  categoryName: string | undefined,
  productNumber: string,
  productName: string,
  quantity: string,
  status: number | null,
  childLines: Omit<JobQualityCheckPartTableLine, 'childLines'>[] | null,
}

export type QualityCheckLines = Array<{ partRequestLineId: number, status: number | null }>

export type JobAttachmentsList = DataListType<CoreAttachmentDto[], { allItemIds: number[], allOwnItemIds: number[] }>;
export type GetJobAttachmentsResponse = ResponseResult<CoreAttachmentDto[], { allItemIds: number[], allOwnItemIds: number[] }>;
