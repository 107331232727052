import React from 'react';
import FormsActions from './FormsActions';
import FormsTable from './FormsTable';
import { BooleanFunctionType, NumberFunctionType } from '../../core/types/coreTypes';

type FormsTabPaneContentProps = {
  checkedKeys: number[],
  setIsAll: BooleanFunctionType,
  setCheckedKeys: (v: number[]) => void,
  setCreateModal: BooleanFunctionType,
  setDeleteModal: BooleanFunctionType,
  setMoveToFolder: BooleanFunctionType,
  isAll: boolean,
  setIsEditFolder: BooleanFunctionType,
  setCreateFolder: BooleanFunctionType,
  setFolderToChange: NumberFunctionType,
}
const FormsTabPaneContent:React.FC<FormsTabPaneContentProps> = ({
  checkedKeys,
  setCheckedKeys,
  setIsAll,
  setCreateModal,
  setDeleteModal,
  setMoveToFolder,
  isAll,
  setIsEditFolder,
  setCreateFolder,
  setFolderToChange,
}) => {
  return (
    <>
      <FormsActions
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        setIsAll={setIsAll}
        setCreateModal={setCreateModal}
        setDeleteModal={setDeleteModal}
        setMoveToFolder={setMoveToFolder}
      />
      <FormsTable
        data={[]}
        // data={attachmentsList.items}
        checkedKeys={checkedKeys}
        setCheckedKeys={setCheckedKeys}
        isAll={isAll}
        setIsAll={setIsAll}
        // disabled={enquiryAcceptedOrRejected}
        openEditFolderModal={(id: number) => {
          setIsEditFolder(true);
          setCreateFolder(true);
          setFolderToChange(id);
        }}
        setDeleteFolderModal={(id: number) => {
          setFolderToChange(id);
          setDeleteModal(true);
        }}
      />
    </>
  );
};

export default FormsTabPaneContent;
