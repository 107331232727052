import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './InputNumber.scss';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import isNil from 'lodash/isNil';
import { AVAILABLE_NUMERIC_KEYS, ONLY_DIGITS } from '../../utils/regex';
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from '../../types/coreTypes';

type RegularInputNumberProps = {
  value?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  label?: string;
  disabled?: boolean;
  error?: string;
  className?: string;
  placeholder?: string,
  autoFocus?: boolean,
  postfix?: string,
  max: number,
  short?: boolean,
  tip?: string,
  multipleLeadingZero?: boolean,
}

const RegularInputNumber: React.FC<RegularInputNumberProps> = ({
  value,
  onChange,
  onBlur,
  onFocus,
  onPressEnter,
  label,
  disabled,
  error,
  className,
  placeholder,
  autoFocus,
  postfix,
  max,
  short,
  tip,
  multipleLeadingZero,
}) => {
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (autoFocus) {
      ref.current?.focus();
      autoFocus && ref.current?.select();
    }
  }, [ref, autoFocus]);
  return (
    <div className={classNames(className, 'regularInputNumber')}>
      {label && <span className="regularInputNumber-label">
        {label}
        {tip && <Tooltip title={tip} overlayClassName="tooltip-sm">
          <FontAwesomeIcon icon={faCircleInfo} className="regularInputNumber-tip" />
        </Tooltip>}
      </span>}
      <div className={classNames('regularInputNumber__numFieldWrap', { 'regularInputNumber__numFieldWrap--short': short })}>
        <input
          type="text"
          inputMode="numeric"
          min={0}
          value={value}
          lang="en"
          className="regularInputNumber__numFieldWrap-field"
          ref={ref}
          onKeyDown={(e) => {
            const key = e.key.toLowerCase();
            const ctrl = e.ctrlKey || e.metaKey
              ? (e.ctrlKey || e.metaKey)
              : (key === '17');
            if (key === 'enter') {
              ref.current?.blur();
              return;
            }
            const isDigitOnly = ONLY_DIGITS.test(key);
            const isAvailableKeys = AVAILABLE_NUMERIC_KEYS.includes(key);
            if (!isAvailableKeys && !isDigitOnly) {
              e.preventDefault();
              return;
            }
            if ((key === 'v' || key === 'c') && !ctrl) {
              e.preventDefault();
              return;
            }
            if (!multipleLeadingZero && !isNil(value) && key === '0' && [0, '0'].includes(value)) {
              e.preventDefault();
            }
          }}
          onChange={(e) => {
            const val = e.target.value;
            const patternMatch = ONLY_DIGITS.test(val);
            if (+val > max) {
              e.preventDefault();
              return;
            }
            if (val && +val < 0) {
              e.preventDefault();
              return;
            }
            if (val && !patternMatch) {
              e.preventDefault();
              return;
            }
            onChange(e);
          }}
          onBlur={(e) => onBlur && onBlur(e)}
          onFocus={(e) => onFocus && onFocus(e)}
          onKeyPress={(e) => {
            if (e.code === 'Enter') {
              onPressEnter && onPressEnter(e);
              ref.current?.blur();
              e.preventDefault();
            }
          }}
          disabled={disabled}
          placeholder={placeholder}
        />
        {postfix && <span className="regularInputNumber__numFieldWrap-postfix">{postfix}</span>}
      </div>
      {error && <span className="regularInputNumber-error">{error}</span>}
    </div>
  );
};

export default RegularInputNumber;
