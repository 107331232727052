import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { PartsKitsDto } from '../types/partsKitsTypes';
import Tag from '../../../../core/components/tag/Tag';
import { useAppSelector } from '../../../../store/hooks';
import { shortBusinessAreasSelector } from '../../../../store/selectors/coreSelectors';
import { DictionaryItem } from '../../../../core/types/coreTypes';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { partCategoriesLookupSelector } from '../../../../store/selectors/sharedSelectors';
import { routesPath } from '../../../../core/enums/pathEnum';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { partsKitsStatusesSelector } from '../../../../store/selectors/coreStatusesSelectors';

type PartsKitsItemProps = {
  item: PartsKitsDto,
}

const PartsKitsItem:React.FC<PartsKitsItemProps> = ({ item }) => {
  const partKitsStatuses = useAppSelector(partsKitsStatusesSelector);
  const shortBusinessAreas = useAppSelector(shortBusinessAreasSelector);
  const stockCategories = useAppSelector(partCategoriesLookupSelector);

  const getLabelHandle = (value: number | number[], selector: DictionaryItem[]) => {
    if (typeof value === 'number') {
      return selector.find((el) => el.value === value)?.label || '-';
    } else {
      const arr: string[] = value.map((el) => getLabelHandle(el, selector));
      return arr.join(', ');
    }
  };

  return (
    <PrivateLineLink
      to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${item.id}`}
      permission={PermissionEnum.StockPartsKitViewPage}
      className="partsKitsLine"
    >
      <span className="partsKitsLine__title">
        <FontAwesomeIcon icon={faBox} className="partsKitsLine__icon" />
        <span className="partsKitsLine__id">{item.partsKitCode}</span>
        <span className="partsKitsLine__name">{item.name}</span>
      </span>
      <Tag textSize="md" className="partsKitsLine__tag" label={getLabelHandle(item.status, partKitsStatuses)} />
      <span className="partsKitsLine__info">
        <TruncateTooltip
          value={getLabelHandle(item.categoryId || 0, stockCategories)}
          className="partsKitsLine__category"
          overlayClassName="truncate-table-tooltip"
        />
        <span className="partsKitsLine__areas">{getLabelHandle(item.businessAreas as number[], shortBusinessAreas)}</span>
        <span className="partsKitsLine__total">
          <span className="partsKitsLine__total-label">Items</span>
          <span className="partsKitsLine__total-value">{item.itemsCount}</span>
        </span>
      </span>
    </PrivateLineLink>
  );
};

export default PartsKitsItem;
