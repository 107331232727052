import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DownloadUriResponse, PagingType,
  ResponseResult,
} from '../../../core/types/coreTypes';
import {
  GetSalesRectificationReportResponse,
  RectificationReportExcelFilters,
  RectificationReportFilters,

} from '../../../reports/types/RectificationReportsTypes';
import { setLoading } from '../../slices/coreSlice';
import { reportsAPI } from '../../../api/reportsApi';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import {
  GetSalesUnfinishedEntitiesReportResponse,
  UnfinishedEntitiesReportFilters,
} from '../../../reports/types/UnfinishedEntitiesReportTypes';
import {
  OutstandingOverduePurchasingExcelFilters,
  OutstandingOverduePurchasingFilters,
  PurchasingOutstandingOverduePurchaseOrdersReportResponse,
} from '../../../reports/types/OutstandingOverdueReportTypes';
import {
  GetPurchasingSupplierSpendReportResponse,
  SupplierSpendFilters,
  SupplierSpendReportExcelFilters,
} from '../../../reports/types/SupplierSpendReportTypes';
import {
  FitterReturnsFilters,
  GetPurchasingFitterReturnsReportDto,
} from '../../../reports/types/FitterReturnsTypes';
import {
  GetSalesSoldProductsByCustomerReportResponse,
  SoldProductsByCustomerExcelFilters,
  SoldProductsByCustomerFilters,
} from '../../../reports/types/SoldProductsByCustomerTypes';
import {
  FitmentTimeReportFilters,
  FitmentTimeReportFiltersChart,
  FitmentTimeReportFiltersExcel, GetProductionFitmentTimeReportTableResponse, ProductionFitmentTimeReportChartParentJobDto,
} from '../../../reports/types/FitmentTimeReportTypes';
import {
  GetSalesRectificationByProductReportResponse,
  RectificationsByProductReportExcelFilters, RectificationsByProductReportFilters,
} from '../../../reports/types/RectificationsByProductTypes';
import {
  GetSalesNumberOfConvertedVehiclesReportResponse,
  NumberOfConvertedVehiclesReportFilters,
} from '../../../reports/types/NumberOfConvertedVehiclesReportTypes';
import {
  GetSalesOfficeEmployeeChartDataReportDto,
  GetSalesOfficeEmployeeReportResponse,
  SalesOfficeEmployeeReportChartFilters,
  SalesOfficeEmployeeReportExcelFilters,
  SalesOfficeEmployeeReportFilters,
} from '../../../reports/types/SalesOfficeEmployeeReportTypes';
import {
  FullStockReportExcelFilters,
  FullStockReportFilters,
  GetStockFullStockReportDto,
} from '../../../reports/types/FullStockReportTypes';
import {
  GetSalesRectificationBySupplierReportResponse, RectificationsBySupplierReportExcelFilters,
  RectificationsBySupplierReportFilters,
} from '../../../reports/types/RectificationsBySupplierReportTypes';
import { GetSalesByCustomerReportResponse, SalesByCustomerReportFilters } from '../../../reports/types/SalesReportByCustomerTypes';
import {
  GetSalesOffsiteWorkingReportResponse,
  SalesOffsiteWorkingReportExcelFilters,
  SalesOffsiteWorkingReportFilters,
} from '../../../reports/types/SalesOffsiteWorkingReportTypes';
import { CancelledLinesReportDto, CancelledLinesReportFilters } from '../../../reports/types/CancelledLinesReportType';

export const getFitmentTimeReportThunk = createAsyncThunk<GetProductionFitmentTimeReportTableResponse,
{ filters: FitmentTimeReportFilters }
>(
  'get/Report/FitmentTime',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchProductionFitmentTimeReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getFitmentTimeReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: FitmentTimeReportFiltersExcel }
>(
  'get/Report/FitmentTimeExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchProductionFitmentTimeReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getFitmentTimeReportChartThunk = createAsyncThunk<ResponseResult<ProductionFitmentTimeReportChartParentJobDto[]>,
{ filters: FitmentTimeReportFiltersChart }
>(
  'get/Report/FitmentTimeChart',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchProductionFitmentTimeReportChart(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesRectificationReportThunk = createAsyncThunk<GetSalesRectificationReportResponse,
{ filters: RectificationReportFilters }
>(
  'get/Report/SalesRectification',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesRectificationReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesRectificationReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: RectificationReportExcelFilters }
>(
  'get/Report/SalesRectificationExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesRectificationReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationsByProductReportThunk = createAsyncThunk<GetSalesRectificationByProductReportResponse,
{ filters: RectificationsByProductReportFilters }
>(
  'get/Report/RectificationsByProduct',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchRectificationsByProductReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationsByProductReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: RectificationsByProductReportExcelFilters }
>(
  'get/Report/RectificationsByProductExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchRectificationsByProductExcelReport(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNumberOfConvertedVehiclesReportThunk = createAsyncThunk<GetSalesNumberOfConvertedVehiclesReportResponse,
{ filters?: NumberOfConvertedVehiclesReportFilters }
>(
  'get/Report/NumberOfConvertedVehicles',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchNumberOfConvertedVehiclesReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOffsiteWorkingReportThunk = createAsyncThunk<GetSalesOffsiteWorkingReportResponse,
{ filters: SalesOffsiteWorkingReportFilters }
>(
  'get/Report/SalesOffsite',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesOffsiteReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOffsiteWorkingReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: SalesOffsiteWorkingReportExcelFilters }
>(
  'get/Report/SalesOfficeEmployeeExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesOffsiteReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOfficeEmployeeReportThunk = createAsyncThunk<GetSalesOfficeEmployeeReportResponse,
{ filters: SalesOfficeEmployeeReportFilters }
>(
  'get/Report/SalesOfficeEmployee',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesOfficeEmployeeReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOfficeEmployeeReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: SalesOfficeEmployeeReportExcelFilters }
>(
  'get/Report/SalesOfficeEmployeeExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesOfficeEmployeeReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesOfficeEmployeeReportChartThunk = createAsyncThunk<ResponseResult<GetSalesOfficeEmployeeChartDataReportDto[]>,
{ filters: SalesOfficeEmployeeReportChartFilters }
>(
  'get/Report/SalesOfficeEmployeeChart',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesOfficeEmployeeReportChart(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSalesByCustomerReportThunk = createAsyncThunk<GetSalesByCustomerReportResponse,
{ filters: SalesByCustomerReportFilters }
>(
  'get/Report/SalesReportByCustomer',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSalesReportByCustomer(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSoldProductsByCustomerReportThunk = createAsyncThunk<GetSalesSoldProductsByCustomerReportResponse,
{ filters: SoldProductsByCustomerFilters }
>(
  'get/Report/SoldProductsByCustomer',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSoldProductsByCustomerReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSoldProductsByCustomerReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: SoldProductsByCustomerExcelFilters }
>(
  'get/Report/SoldProductsByCustomerExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchSoldProductsByCustomerReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getFullStockReportThunk = createAsyncThunk<ResponseResult<GetStockFullStockReportDto[]>,
{ filters: FullStockReportFilters }
>(
  'get/Report/FullStock',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchFullStockReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getFullStockReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: FullStockReportExcelFilters }
>(
  'get/Report/FullStockExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchFullStockReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUnfinishedEntitiesReportThunk = createAsyncThunk<GetSalesUnfinishedEntitiesReportResponse,
{ filters: UnfinishedEntitiesReportFilters }
>(
  'get/Report/UnfinishedEntities',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchUnfinishedEntitiesReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getRectificationsBySupplierReportThunk = createAsyncThunk<GetSalesRectificationBySupplierReportResponse,
{ filters: RectificationsBySupplierReportFilters }
>(
  'get/Report/RectificationsBySupplier',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchRectificationsBySupplierReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getRectificationsBySupplierReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: RectificationsBySupplierReportExcelFilters }
>(
  'get/Report/RectificationsBySupplierExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchRectificationsBySupplierReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierSpendReportThunk = createAsyncThunk<GetPurchasingSupplierSpendReportResponse,
{ filters: SupplierSpendFilters }
>(
  'get/Report/SupplierSpend',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingSupplierSpendReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierSpendReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: SupplierSpendReportExcelFilters }
>(
  'get/Report/SupplierSpendExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingSupplierSpendReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingOutstandingOverduePurchaseOrdersThunk = createAsyncThunk<PurchasingOutstandingOverduePurchaseOrdersReportResponse,
{ filters: OutstandingOverduePurchasingFilters }
>(
  'get/Report/PurchasingOutstandingOverduePurchaseOrders',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingOutstandingOverduePurchaseOrdersReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingOutstandingOverduePurchaseOrdersExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: OutstandingOverduePurchasingExcelFilters }
>(
  'get/Report/PurchasingOutstandingOverduePurchaseOrdersExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingOutstandingOverduePurchaseOrdersReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCancelledLinesReportThunk = createAsyncThunk<ResponseResult<CancelledLinesReportDto[]>,
{ filters: CancelledLinesReportFilters }
>(
  'get/Report/CancelledLinesReport',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchCancelledLinesReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCancelledLinesReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: Omit<FitterReturnsFilters, keyof PagingType> }
>(
  'get/Report/CancelledLinesReportExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchCancelledLinesReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingFitterReturnsReportThunk = createAsyncThunk<ResponseResult<GetPurchasingFitterReturnsReportDto[]>,
{ filters: FitterReturnsFilters }
>(
  'get/Report/PurchasingFitterReturnsReport',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingFitterReturnsReport(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchasingFitterReturnsReportExcelThunk = createAsyncThunk<DownloadUriResponse,
{ filters: Omit<FitterReturnsFilters, keyof PagingType> }
>(
  'get/Report/PurchasingFitterReturnsReportExcel',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await reportsAPI.fetchPurchasingFitterReturnsReportExcel(filters);
      downloadCsv(response.data.data.downloadUri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
