import React from 'react';
import { EmployeeActivityEventType } from '../../../../types/employeeViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import { employeeEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import { EmployeeEventTypesEnum } from '../../../../enums/EmployeeViewEnums';
import { employeesStatusesSelector } from '../../../../../../store/selectors/employeesSelector';
import EmployeeFieldUpdateEvent from './EmployeeFieldUpdateEvent';
import SkillsUpdateEvent from './SkillsUpdateEvent';
import EmploymentEvent from './EmploymentEvent';
import EmployeeNoteEvent from './EmployeeNoteEvent';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';
import LeaveRequestEvent from './LeaveRequestEvent';

type EmployeeEventProps = {
  item: EmployeeActivityEventType,
}

const EmployeeEvent: React.FC<EmployeeEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(employeeEventTypesSelector);
  const employeeStatus = useAppSelector(employeesStatusesSelector);

  const employmentEvents = [
    EmployeeEventTypesEnum.EmploymentCreation,
    EmployeeEventTypesEnum.EmploymentDeletion,
    EmployeeEventTypesEnum.EmploymentFieldUpdateOrAddingValue,
  ];

  const attachmentEvents = [
    EmployeeEventTypesEnum.AddingAttachmentWithoutComment,
    EmployeeEventTypesEnum.AddingAttachmentWithComment,
    EmployeeEventTypesEnum.EditingAttachmentComment,
    EmployeeEventTypesEnum.DeletingAttachment,
  ];

  const leaveRequestEvents = [
    EmployeeEventTypesEnum.LeaveRequestCreation,
    EmployeeEventTypesEnum.LeaveRequestFieldUpdateOrAddingValue,
    EmployeeEventTypesEnum.LeaveRequestStatusChange,
    EmployeeEventTypesEnum.LeaveRequestDeletion,
  ];

  const employeeEventRenderFn = (item: EmployeeActivityEventType) => {
    const event = eventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case event === EmployeeEventTypesEnum.Note:
        return <EmployeeNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === EmployeeEventTypesEnum.EmployeeCreation:
        return <CreationEvent entity="Employee" type="name" value={`${item.firstName} ${item.lastName}`} />;
      case event === EmployeeEventTypesEnum.EmployeeDeletion:
        return <DeletionEvent entity="Employee" />;
      case (event === EmployeeEventTypesEnum.EmployeeFieldUpdateOrAddingValue && item.field !== 'Skills'):
        return <EmployeeFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (event === EmployeeEventTypesEnum.EmployeeFieldUpdateOrAddingValue && item.field === 'Skills'):
        return <SkillsUpdateEvent item={item} />;
      case event === EmployeeEventTypesEnum.EmployeeStatusChange: {
        const prevStatus = employeeStatus.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = employeeStatus.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case employmentEvents.includes(event as EmployeeEventTypesEnum): {
        return <EmploymentEvent item={item} event={event as EmployeeEventTypesEnum} />;
      }
      case (attachmentEvents.includes(event as EmployeeEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (leaveRequestEvents.includes(event as EmployeeEventTypesEnum)):
        return <LeaveRequestEvent event={event as EmployeeEventTypesEnum} item={item} />;
      default: return null;
    }
  };

  return <>{employeeEventRenderFn(item)}</>;
};

export default EmployeeEvent;
