import React from 'react';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import PartSubcategoriesColumns from './PartSubcategoriesColumns';
import { PartSubcategoryDto } from '../types/partCategoriesTypes';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Tbody from '../../../core/components/table-components/Tbody';
import PartSubcategoryCell from './PartSubcategoryCell';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';

type PartSubcategoriesTableProps = {
  data: PartSubcategoryDto[],
  totalItems: number,
  openEditCategoryModal: VoidFunctionType,
  openEditSubcategoryModal: NumberFunctionType,
  setIsDeleteModal: (params: { categoryId?: number, subcategoryId?: number }) => void,
  setIsAffectedModal: (params: { categoryId?: number, subcategoryId?: number }) => void

}
const PartSubcategoriesTable: React.FC<PartSubcategoriesTableProps> = ({
  data,
  totalItems,
  openEditCategoryModal,
  openEditSubcategoryModal,
  setIsDeleteModal,
  setIsAffectedModal,

}) => {
  return (
    <TableNew>
      <PartSubcategoriesColumns
        totalItems={totalItems}
        openEditCategoryModal={openEditCategoryModal}
        setIsDeleteModal={setIsDeleteModal}
        setIsAffectedModal={setIsAffectedModal}
      />
      <Tbody>
        {data.length > 0
          ? data.map((subcategory) => (
            <PartSubcategoryCell
              key={subcategory.id}
              subcategory={subcategory}
              setIsDeleteModal={setIsDeleteModal}
              setIsAffectedModal={setIsAffectedModal}
              openEditSubcategoryModal={openEditSubcategoryModal}
            />))
          : <EmptyTableSection text="No records" colSpan={5} />}
      </Tbody>
    </TableNew>
  );
};

export default PartSubcategoriesTable;
