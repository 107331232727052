import React from 'react';
import { RectificationColumnType } from '../../types/rectificationsTypes';
import { RectificationModesEnum } from '../../enums/rectificationEnums';
import RectificationCardsColumns from '../rectification-cards-columns/RectificationCardsColumns';

type RectificationsContentProps = {
  pageParam: string | null,
  cards: RectificationColumnType | null,
  setCards: (value: RectificationColumnType | null) => void,
}

const RectificationsContent:React.FC<RectificationsContentProps> = ({ pageParam, cards, setCards }) => {
  const contentRenderFn = () => {
    if (pageParam === RectificationModesEnum.DASHBOARD) {
      return <RectificationCardsColumns
        cards={cards}
        setCards={setCards}
      />;
    } else return null;
  };
  return (
    <>{contentRenderFn()}</>
  );
};

export default RectificationsContent;
