export enum OrderWarningsUiEnum {
  InactiveFor10To14Days = 'Inactive for 10-14 days',
  InactiveFor15PlusDays = 'Inactive for 15+ days',
  VehicleIsNotLinked = 'Vehicle is not linked',
  DueDateIsNear = 'Due date is near'
}

export enum OrderStatusUiEnum {
  New = 'New',
  Booking = 'Booking',
  ExpensesReview = 'Expenses review',
  Finalised = 'Finalised',
  JobInProgress = 'Job in progress',
  AwaitingSubcontractorWork = 'Awaiting subcontractor work',
  ReadyForPostCheck = 'Ready for post-check',
  PostCheckFailed = 'Post-check failed',
  PostCheckPassed = 'Post-check passed',
  RejectedByCustomer = 'Rejected by customer',
  ReasonReview = 'Reason review',
  AcceptedByCustomer = 'Accepted by customer',
  Invoicing = 'Invoicing',
  Completed = 'Completed',
  Cancelled = 'Cancelled',
}
