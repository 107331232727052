import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import { PartsKitsViewPageEnums } from '../enums/PartsKitsViewPageEnums';
import { tabCountHandle } from '../../../../core/utils/tabCountHandle';
import { useAppSelector } from '../../../../store/hooks';
import { partsKitDetailsSelector } from '../../../../store/selectors/partsKitSelectors';

export const usePartsKitParams = () => {
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { backHandle } = useLocationHistoryState(true);
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };
  const options = [
    {
      label: 'Details & activity',
      value: PartsKitsViewPageEnums.PARTS_KITS_DETAILS,
    },
    {
      label: 'Components',
      value: PartsKitsViewPageEnums.PARTS_KITS_COMPONENTS,
    },
    {
      label: `Attachments ${tabCountHandle(partsKitDetails?.attachmentsCount)}`,
      value: PartsKitsViewPageEnums.PARTS_KITS_ATTACHMENTS,
    },
  ];

  return {
    params,
    backHandle,
    navigate,
    setModeParam,
    options,
    setSearchParams,
  };
};
