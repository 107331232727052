import React, { useEffect } from 'react';
import {
  Controller, useForm, useFormState,
} from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import Input from '../../../../../core/components/input/Input';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { EditName, editNameDefaults, EditNameSchema } from '../../../../utils/EditNameSchema';

type EditNameModalProps = {
  name?: 'first' | 'last',
  visible: boolean,
  onCancel: VoidFunctionType,
  onConfirm: (name: string, commonActions: VoidFunctionType) => void,
  value?: string,
}
const EditNameModal:React.FC<EditNameModalProps> = ({
  visible, name, onConfirm, onCancel, value,
}) => {
  const {
    control, handleSubmit, reset, setValue,
  } = useForm<EditName>({
    resolver: zodResolver(EditNameSchema),
    defaultValues: editNameDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  useEffect(() => {
    if (visible) {
      value && setValue('name', value);
    }
    // eslint-disable-next-line
  }, [visible]);

  const cancelHandle = () => {
    reset();
    onCancel();
  };

  const onSubmit = (values: EditName) => {
    onConfirm(values.name, cancelHandle);
  };

  return (
    <Modal visible={visible} onCancel={cancelHandle} title={`Edit ${name} name`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              value={field.value}
              label={name === 'first' ? 'First name' : 'Last name'}
              onChange={(e) => field.onChange(e.target.value)}
              error={errors.name?.message}
            />
          )}
        />
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Save"
          createType="submit"
          cancelClick={cancelHandle}
          offsetTop
        />
      </form>
    </Modal>
  );
};

export default EditNameModal;
