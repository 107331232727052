import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faQuestionCircle, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import Tag from '../../../../../../core/components/tag/Tag';
import { purchaseOrderStatusOriginSelector, verifiedSupplierStatusSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { useAppSelector } from '../../../../../../store/hooks';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { PurchaseOrderDto } from '../../../../../purchase-orders/dashboard/types/purchaseOrdersTypes';

type PurchaseItemProps = {
  item: PurchaseOrderDto
}

const PurchaseItem: React.FC<PurchaseItemProps> = ({ item }) => {
  const purchaseOrderStatus = useAppSelector(purchaseOrderStatusOriginSelector);
  const purchaseStatus = purchaseOrderStatus.find((el) => el.value === item.status)?.label;
  const supplierVerified = useAppSelector(verifiedSupplierStatusSelector);
  return (
    <PrivateLineLink
      className="purchaseItem"
      permission={PermissionEnum.StockPurchaseOrdersViewPage}
      to={`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${item.id}`}
    >
      <span className="purchaseItem__title">
        <FontAwesomeIcon
          icon={faShoppingCart}
          className="purchaseItem__icon"
        />
        <span className="purchaseItem__id">{item.purchaseOrderNumber}</span>
      </span>
      <span className="purchaseItem__info">
        <span className="purchaseItem__label">Supplier</span>
        <span className="purchaseItem__supplier">
          <Tooltip title={item.supplierStatus === supplierVerified?.value ? 'Verified' : 'Unverified'}>
            <FontAwesomeIcon
              icon={item.supplierStatus === supplierVerified?.value ? faClipboardCheck : faQuestionCircle}
              className="purchaseItem__supplier-icon"
            />
          </Tooltip>
          <TruncateTooltip value={item.supplierName} className="purchaseItem__supplier-name" />
        </span>
      </span>
      <Tag className="purchaseItem__tag" label={purchaseStatus || '-'} />
    </PrivateLineLink>
  );
};

export default PurchaseItem;
