import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { pricelistFiltersListSelector, pricelistFiltersSelector } from '../../../store/selectors/pricelistSelectors';
import { setPricelistFilters } from '../../../store/slices/pricelistSlice';
import { PricelistDashFiltersType } from '../types/pricelistTypes';
import { initPaging } from '../../../core/types/coreTypes';
import { PricelistsPageParamsEnum } from '../enums/PricelistsViewEnum';

export const usePricelistDashFilters = () => {
  const pricelistFilters = useAppSelector(pricelistFiltersSelector);
  const [searchParams, setSearchParams] = useSearchParams();
  const keywordSearchParam = searchParams.get('keyword');
  const [searchValue, setSearchValue] = useState<string | undefined>(pricelistFilters.keyword);

  const dispatch = useAppDispatch();
  const filters = useAppSelector(pricelistFiltersListSelector);

  const initDropdownSearch = {
    types: '',
    businessAreas: '',
    statuses: '',
  };

  const getFilteredPricelists = (filters: PricelistDashFiltersType) => {
    dispatch(setPricelistFilters(filters));
  };

  const setInitSearchParams = () => {
    setSearchParams({ page: searchParams.get('page') || PricelistsPageParamsEnum.DASHBOARD });
  };

  const onClear = () => {
    getFilteredPricelists({
      order: pricelistFilters.order,
      page: initPaging.page,
      pageSize: initPaging.pageSize,
    });
    if (keywordSearchParam) {
      setInitSearchParams();
    }
  };
  return {
    searchValue,
    setSearchValue,
    filters,
    getFilteredPricelists,
    onClear,
    initDropdownSearch,
    keywordSearchParam,
    setInitSearchParams,
  };
};
