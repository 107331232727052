import React from 'react';
import SalesOrderLinesTable from './SalesOrderLinesTable';
import {
  salesOrderDetailsSummaryUISelector,
} from '../../../../store/selectors/invoicingSelectors';
import { useAppSelector } from '../../../../store/hooks';
import InvoicingSummary from '../../../common/components/invoicing-summary/InvoicingSummary';

const SalesOrderLinesContent: React.FC = () => {
  const summary = useAppSelector(salesOrderDetailsSummaryUISelector);
  return (
    <>
      <SalesOrderLinesTable />
      <InvoicingSummary summary={summary} />
    </>
  );
};

export default SalesOrderLinesContent;
