import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../../core/enums/errorsEnum';
import Select from '../../../../../../core/components/select/Select';
import Textarea from '../../../../../../core/components/textarea/Textarea';
import { BooleanFunctionType } from '../../../../../../core/types/coreTypes';
import { EnquiryDto } from '../../../../../enquiries/types/enquiryTypes';
import { useFiltersItems } from '../../../../../enquiries/hooks/useFiltersItems';
import {
  getDefaultEnquiryJobLocation,
} from '../../../../../../store/thunks/enquiry/detailsThunks';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { JobTypeUiNamesEnum, PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import {
  enquiryFinalStatusesSelector, enquiryNewStatusSelector, enquiryRejectedStatusSelector,
  enquiryRequiredStatusesSelector,
} from '../../../../../../store/selectors/coreStatusesSelectors';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import { AddressFields } from '../../../../../../common/types/commonTypes';
import RequisiteSection from '../../../../../../common/components/requisite/RequisiteSection';
import Input from '../../../../../../core/components/input/Input';
import DatePickerComponent from '../../../../../../core/components/date-picker/DatePicker';
import { utcDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import {
  enquiryAcceptedOrRejectedSelector,
  enquiryDetailsUiSelector,
  enquiryLocationSelector,
  enquiryCurrentStatusSelector,
  enquiryDetailsRequisitesSelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import DecimalInputNumber from '../../../../../../core/components/input-number/DecimalInputNumber';
import { DEFAULT_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT, maxCommonDecimal } from '../../../../../../core/utils/regex';
import {
  CommonDetailsFields,
  CommonEnqDetailsDefaultFields,
} from '../../../../types/enquiryDetailsTypes';
import AddressModal from '../../../../../../common/components/address/AddressModal';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import { jobTypesValuesSelector } from '../../../../../../store/selectors/coreSelectors';
import AddressField from '../../../../../../common/components/address/AddressField';
import { useEditEnquiryDetails } from '../../../../hooks/useEditEnquiryDetails';

type CommonDetailsProps = {
  setIsVisible: BooleanFunctionType;
  setIsShippingMode: BooleanFunctionType;
  details?: EnquiryDto,
  setIsSame: BooleanFunctionType;
  isSame: boolean;
}

const CommonDetails: React.FC<CommonDetailsProps> = ({
  setIsVisible, setIsShippingMode,
  isSame, setIsSame, details,
}) => {
  const {
    billing, shipping, isShippingSame, alwaysUseCustomerRequisites,
  } = useAppSelector(enquiryDetailsRequisitesSelector);
  const detailsValues = useAppSelector(enquiryDetailsUiSelector);
  const allowed = usePermission(PermissionEnum.EnquiryEditFields);
  const locationFields = useAppSelector(enquiryLocationSelector);
  const enquiryStatus = useAppSelector(enquiryCurrentStatusSelector);
  const enquiryNewStatus = useAppSelector(enquiryNewStatusSelector);
  const { onSite } = useAppSelector(jobTypesValuesSelector);

  const [isDefaultAddressForOnSite, setDefaultAddressForOnSite] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { jobTypes, priorityLevels, enquirySources } = useFiltersItems();
  const {
    control, setValue, watch, clearErrors,
  } = useForm<CommonDetailsFields>({
    defaultValues: CommonEnqDetailsDefaultFields,
    mode: 'all',
  });
  const jobType = watch('jobType');
  const { errors } = useFormState({ control });

  const {
    setEnquiryDefaults,
    editField,
    changeJobLocationDetails,
    isLocationModal,
    setIsLocationModal,
    addressFields,
    setAddressFields,
    clearOtherErrorsFn,
  } = useEditEnquiryDetails(setValue, clearErrors, errors);

  useEffect(() => {
    setEnquiryDefaults();
    // eslint-disable-next-line
  }, [details]);

  // eslint-disable-next-line max-len
  const locationRequired = jobTypes.find((el) => el.value === jobType)?.label === JobTypeUiNamesEnum.OffSite && enquiryStatus !== 1;
  const enquiryRequiredStatuses = useAppSelector(enquiryRequiredStatusesSelector);
  const enquiryAcceptedOrRejected = useAppSelector(enquiryAcceptedOrRejectedSelector);
  const enquiryRejectedStatus = useAppSelector(enquiryRejectedStatusSelector);
  const finalStatuses = useAppSelector(enquiryFinalStatusesSelector);
  const statusDisabled = finalStatuses.find((el) => el.value === enquiryStatus);

  const forbiddenToEdit = useMemo(
    () => enquiryAcceptedOrRejected || !allowed,
    [allowed, enquiryAcceptedOrRejected],
  );

  return (
    <>
      <AddressModal
        isVisible={isLocationModal}
        onCancel={() => {
          setIsLocationModal(false);
          setValue('jobType', details?.jobType);
        }}
        title="Job location"
        isOptionalFields={!locationRequired}
        addressFields={addressFields}
        onSubmit={(values) => {
          setAddressFields(values);
          detailsValues && changeJobLocationDetails({
            jobType: jobType || details?.jobType,
            jobCompanyName: values.companyName?.trim() || '',
            jobAddressLine1: values.addressLine1?.trim() || '',
            jobAddressLine2: values.addressLine2?.trim() || '',
            jobCityTown: values.cityTown?.trim() || '',
            jobRegion: values.region?.trim() || '',
            jobPostalCode: values.postalCode?.trim() || '',
            jobTelephone: values.telephone?.trim() || '',
          });
        }}
        isDefaultOptionAvailable
        setDefaultAddressFields={() => dispatch(getDefaultEnquiryJobLocation({
          setAddressFields,
        }))}
        isExtendedFields
      />
      <ConfirmationModal
        isVisible={isDefaultAddressForOnSite}
        onCancel={() => {
          setDefaultAddressForOnSite(false);
          editField({ jobType: onSite as number }, 'jobType');
        }}
        confirmHandler={() => {
          dispatch(getDefaultEnquiryJobLocation({
            setAddressFields,
            editThunk: (values: AddressFields) => {
              onSite && changeJobLocationDetails({
                jobType: onSite,
                jobCompanyName: values.companyName?.trim() || '',
                jobAddressLine1: values.addressLine1?.trim() || '',
                jobAddressLine2: values.addressLine2?.trim() || '',
                jobCityTown: values.cityTown?.trim() || '',
                jobRegion: values.region?.trim() || '',
                jobPostalCode: values.postalCode?.trim() || '',
                jobTelephone: values.telephone?.trim() || '',
              });
            },
          }));
          setDefaultAddressForOnSite(false);
        }}
        customCreateLabel="Yes, set location"
        customTitle="Job location"
        customText="The following default location can be used for on-site enquiries:
            Vanliners LTD, Unit 3, Ormside Close, Hindley Green, Wigan, England, WN2 4HR"
        customProceedText="Do you want to set this address as a job location?"
      />
      <form className="details-form">
        <Controller
          name="enquirySource"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsFn('enquirySource');
                detailsValues && editField({ enquirySource: v as number }, 'enquirySource');
              }}
              options={enquirySources}
              error={errors.enquirySource?.message}
              label="Enquiry source"
              disabled={forbiddenToEdit}
            />
          )}
        />
        <Controller
          name="priorityLevel"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsFn('priorityLevel');
                editField({ priorityLevel: v as number }, 'priorityLevel');
              }}
              options={priorityLevels}
              error={errors.priorityLevel?.message}
              label="Priority level"
              disabled={forbiddenToEdit}
            />
          )}
        />
        <Controller
          name="estimatedCost"
          control={control}
          rules={{
            required: enquiryRequiredStatuses.find((el) => el.value === details?.status) && ErrorsEnum.REQUIRED,
          }}
          render={({ field }) => (
            <DecimalInputNumber
              label="Estimated cost"
              value={field.value || ''}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                field.onChange(v);
                clearOtherErrorsFn('estimatedCost');
              }}
              onBlur={(e) => {
                const v = e.target.value;
                !errors.estimatedCost && editField({ estimatedCost: +v || 0.00 }, 'estimatedCostString');
              }}
              max={maxCommonDecimal}
              disabled={forbiddenToEdit || !!statusDisabled}
              error={errors.estimatedCost?.message}
            />
          )}
        />
        <Controller
          name="jobType"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                const currentValueLabel = jobTypes?.find((el) => el.value === v)?.label;
                if (currentValueLabel === JobTypeUiNamesEnum.OffSite
                      && details?.status !== enquiryNewStatus
                      && locationFields.some((el) => !el)
                ) {
                  setIsLocationModal(true);
                  field.onChange(v);
                } else if (currentValueLabel === JobTypeUiNamesEnum.OnSite) {
                  field.onChange(v);
                  setDefaultAddressForOnSite(true);
                } else {
                  field.onChange(v);
                  editField({ jobType: v as number }, 'jobType');
                }
                clearOtherErrorsFn('jobType');
              }}
              options={jobTypes}
              error={errors.jobType?.message}
              disabled={forbiddenToEdit}
              label="Job type"
            />
          )}
        />
        <AddressField
          title="Job location"
          addressFields={addressFields}
          setAddressModal={() => {
            setIsLocationModal(true);
            clearOtherErrorsFn('jobLocation');
          }}
          disabled={forbiddenToEdit}
        />
        <Controller
          name="description"
          control={control}
          rules={{ maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } }}
          render={({ field }) => (
            // <MentionComponent
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsFn('description');
              }}
              onBlur={(e) => !errors.description && editField(
                { description: e.target.value.trim() || '' },
                'description',
                true,
              )}
              label="Description"
              error={errors.description?.message}
              disabled={forbiddenToEdit}
              className="details-form__field--lg"
            />
          )}
        />
        <RequisiteSection
          requisiteDetails={billing}
          setIsVisible={() => {
            if (!alwaysUseCustomerRequisites) {
              setIsVisible(true);
              clearOtherErrorsFn('billing');
            }
          }}
          title="Billing details"
          disabled={forbiddenToEdit || alwaysUseCustomerRequisites}
        />
        <RequisiteSection
          requisiteDetails={shipping}
          disabled={forbiddenToEdit || alwaysUseCustomerRequisites}
          setIsVisible={() => {
            if (!isShippingSame) {
              setIsVisible(true);
              setIsShippingMode(true);
              clearOtherErrorsFn('shipping');
            }
          }}
          title="Shipping details"
          isSame={isSame}
          isShipping
          onCheck={(val: boolean) => {
            if (val) {
              editField(
                { isShippingRequisiteSameAsBilling: true },
                'isShippingRequisiteSameAsBilling',
                false,
                false,
              );
            } else {
              setIsShippingMode(true);
              setIsVisible(true);
            }
            setIsSame(val);
          }}
        />
        <Controller
          name="quoteDate"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DatePickerComponent
              value={field.value ? moment(field.value) : undefined}
              onChange={(v) => {
                if (v) {
                  const formatted = moment(v).format(DEFAULT_DATE_FORMAT);
                  field.onChange(formatted);
                  clearOtherErrorsFn('quoteDate');
                  !errors.specDescription && editField(
                    { quoteDate: utcDateFormatHandler('', formatted) },
                    'quoteDate',
                    false,
                    false,
                  );
                }
              }}
              label="Quote date"
              error={errors.quoteDate?.message}
              format={DEFAULT_UI_DATE_FORMAT}
              disabled={forbiddenToEdit}
              disabledDate={(currentDate) => {
                const tooEarly = moment(currentDate).add(+1, 'days') <= moment(details?.createdDate);
                const tooLate = currentDate > moment();
                return tooEarly || tooLate;
              }}
            />
          )}
        />
        <Controller
          name="customerReferenceNumber"
          control={control}
          rules={{ maxLength: { value: 100, message: maxLengthMessageHandle(100) } }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                clearOtherErrorsFn('customerReferenceNumber');
              }}
              onBlur={(e) => !errors.customerReferenceNumber && editField(
                { customerReferenceNumber: e.target.value.trim() },
                'customerReferenceNumber',
                true,
                false,
              )}
              disabled={forbiddenToEdit}
              error={errors.customerReferenceNumber?.message}
              label="Customer reference No"
            />
          )}
        />
        <Controller
          name="specDescription"
          control={control}
          rules={{ maxLength: { value: 250, message: maxLengthMessageHandle(250) } }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e);
                clearOtherErrorsFn('specDescription');
              }}
              onBlur={(e) => !errors.specDescription && editField(
                { specDescription: e.target.value.trim() },
                'specDescription',
                true,
                false,
              )}
              disabled={forbiddenToEdit}
              error={errors.specDescription?.message}
              label="Spec description"
              className="details-form__field--lg"
            />
          )}
        />
        {enquiryStatus === enquiryRejectedStatus?.value && <Controller
          name="rejectionReason"
          control={control}
          rules={{
            required: enquiryStatus === enquiryRejectedStatus?.value && ErrorsEnum.REQUIRED,
            maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
          }}
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                clearOtherErrorsFn('rejectionReason');
                field.onChange(e.target.value);
              }}
              onBlur={(e) => !errors.rejectionReason && editField(
                { rejectionReason: e.target.value },
                'rejectionReason',
                enquiryStatus !== enquiryRejectedStatus?.value,
                false,
              )}
              label="Rejection reason"
              error={errors.rejectionReason?.message}
              disabled={forbiddenToEdit}
              className="details-form__field--lg"
            />
          )}
        />}
      </form>
    </>
  );
};

export default CommonDetails;
