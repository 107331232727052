import React, { useState } from 'react';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import Tr from '../../../core/components/table-components/Tr';
import { DraftLinePartsFiltersType } from '../../types/commonTypes';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import { useAppSelector } from '../../../store/hooks';
import { quoteLinePartsColumnsSelector } from '../../../store/selectors/enquiriesSelector';
import { SearchTableFilterType } from '../../../core/types/coreTypes';

type PartsModeColumnsProps = {
  filters: DraftLinePartsFiltersType,
  filtersHandle: (filters: DraftLinePartsFiltersType) => void,
  partsTotalCount: number,
}

const PartsModeColumns: React.FC<PartsModeColumnsProps> = ({
  filters, filtersHandle, partsTotalCount,
}) => {
  const columns = useAppSelector(quoteLinePartsColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: undefined,
    manufacturerCode: undefined,
    name: undefined,
    description: undefined,
  });

  const sortHandle = (field: number) => {
    filtersHandle({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    });
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...filters,
        [field]: undefined,
      };
      filtersHandle(values);
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...filters, [field]: searchVl[field]?.trim() };
    filtersHandle(values);
  };
  return (
    <Tr>
      {columns.map((col) => <ThWithControls
        key={col.dataIndex}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={partsTotalCount}
        disabled={partsTotalCount === 0}
        searchVl={searchVl}
        className={`partsModeTh--${col.dataIndex}`}
        tableFilters={filters}
        insideModal
      />)}
      <ThNew className="partsModeTh--action"><span className="sr-only">Actions</span></ThNew>
    </Tr>
  );
};

export default PartsModeColumns;
