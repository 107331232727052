import React from 'react';
import Tr from '../../../../core/components/table-components/Tr';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import Checkbox from '../../../../core/components/checkbox/Checkbox';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import Thead from '../../../../core/components/table-components/Thead';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  confirmedPartRequestColumnsSelector,
  confirmedPartRequestViewsParamsSelector,
} from '../../../../store/selectors/stockPartRequestsSelector';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { setConfirmedPartRequestViewsParams } from '../../../../store/slices/stockPartRequestSlice';
import { CreatePurchaseOrderSearchFilterType } from '../types/createPurchaseOrderTypes';

type SelectConfirmedPartRequestsTHeadProps = {
  checkAll: VoidFunctionType,
  isAll: boolean,
  isIndeterminate: boolean,
  totalCount: number,
  searchVl: CreatePurchaseOrderSearchFilterType,
  setSearchVl: (v: CreatePurchaseOrderSearchFilterType) => void,
}

const SelectConfirmedPartRequestsTHead: React.FC<SelectConfirmedPartRequestsTHeadProps> = ({
  checkAll, isAll, isIndeterminate, totalCount, searchVl, setSearchVl,
}) => {
  const dispatch = useAppDispatch();
  const columns = useAppSelector(confirmedPartRequestColumnsSelector);
  const filters = useAppSelector(confirmedPartRequestViewsParamsSelector);

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      dispatch(setConfirmedPartRequestViewsParams({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      }));
    } else {
      dispatch(setConfirmedPartRequestViewsParams({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...filters,
        page: 1,
        [field]: undefined,
      };
      dispatch(setConfirmedPartRequestViewsParams(values));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...filters, page: 1, [field]: searchVl[field as keyof CreatePurchaseOrderSearchFilterType]?.trim() };
    dispatch(setConfirmedPartRequestViewsParams(values));
  };

  return (
    <Thead>
      <Tr>
        <ThNew checkbox>
          <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAll} disabled={totalCount === 0} table />
        </ThNew>
        {columns.map((col) => <ThWithControls
          key={col.dataIndex}
          col={col}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          totalElements={totalCount}
          disabled={totalCount === 0}
          searchVl={searchVl}
          className={`createPurchaseOrderTh--${col.dataIndex}`}
          tableFilters={filters}
          insideModal
        />)}
      </Tr>
    </Thead>
  );
};

export default SelectConfirmedPartRequestsTHead;
