import React from 'react';
import { VehicleActivityEventDto } from '../../../../types/VehicleViewTypes';
import { vehicleEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import { useAppSelector } from '../../../../../../store/hooks';
import { vehicleStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { VehicleEventEnum } from '../../../../enums/VehicleViewEnums';
import VehicleCheckEvent from './VehicleCheckEvent';
import VehicleNoteEvent from './VehicleNoteEvent';
import VehicleFieldUpdateEvent from './VehicleFieldUpdateEvent';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';

type CustomerEventProps = {
  item: VehicleActivityEventDto;
}

const VehicleEvent: React.FC<CustomerEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(vehicleEventTypesSelector);
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);

  const attachmentEvents = [
    VehicleEventEnum.AddingAttachmentWithoutComment,
    VehicleEventEnum.AddingAttachmentWithComment,
    VehicleEventEnum.EditingAttachmentComment,
    VehicleEventEnum.DeletingAttachment,
  ];

  const checksEvents = [
    VehicleEventEnum.VehicleCheckCreation,
    VehicleEventEnum.VehicleCheckDeletion,
  ];

  const vehicleEventRenderFn = (item: VehicleActivityEventDto) => {
    const event = eventTypes.find((type) => type.value === item.vehicleEventType)?.label || '';
    switch (true) {
      case event === VehicleEventEnum.Note:
        return <VehicleNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === VehicleEventEnum.VehicleCreation:
        return <CreationEvent entity="Vehicle" type="chassis No" value={item.chassisNumber || ''} />;
      case event === VehicleEventEnum.VehicleDeletion:
        return <DeletionEvent entity="Vehicle" />;
      case event === VehicleEventEnum.VehicleFieldUpdateOrAddingValue:
        return <VehicleFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === VehicleEventEnum.VehicleStatusChange: {
        const prevStatus = vehicleStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = vehicleStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case checksEvents.includes(event as VehicleEventEnum): {
        return <VehicleCheckEvent item={item} event={event as VehicleEventEnum} />;
      }
      case attachmentEvents.includes(event as VehicleEventEnum):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      default: return null;
    }
  };

  return <>{vehicleEventRenderFn(item)}</>;
};

export default VehicleEvent;
