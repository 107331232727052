import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  allowedJobTypesForUserSelector,
  customerTypesSelector,
  priorityLevelsSelector,
  QuoteInfoSourcesSelector,
  quoteLineTaxesSelector,
  linksTypesSelector,
  allowedShortBaForUserSelector,
  vehicleProfilesTypesUiSelector,
  enquiryWarningsSelector,
  QuoteUnitsOfMeasureSelector, vehicleInfoSourcesSelector, quoteLinePriceSourcesSelector,
} from './coreSelectors';
import {
  PermissionEnum,
  QuoteInfoSourcesUiNames,
  VehicleInfoSourcesUiNames,
} from '../../core/enums/dictionariesEnums';
import { DictionaryItem } from '../../core/types/coreTypes';
import { linksReformHandler, linksUiViewHandle } from '../../core/utils/linksReformHandler';
import { currentUserSelector } from './accountSelectors';
import {
  vehicleProfilesGroupsUiSelector,
} from './sharedSelectors';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { vehiclesProfilesSelector } from './vehiclesSelectors';
import {
  enquiryAcceptedStatusSelector,
  enquiryRejectedStatusSelector,
  enquiryStatusSelector,
  orderStatusSelector,
} from './coreStatusesSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import {
  QuoteLinesOrdering,
  QuoteLinesTimelineOrdering,
} from '../../enquiry/enquiry-detailed/enums/QuoteEnums';
import {
  enquiryQuoteLinesOrderingSelector,
  enquiryTimelineQuoteLineGroupOrderingSelector, stockPartsKitsOrderingSelector,
  stockPartsOrderingSelector,
} from './coreOrderingSelectors';
import { StockPartsOrders } from '../../stock/search/enums/StockPartsOrders';
import { PartsKitOrderingEnum } from '../../stock/parts-kits/view-page/enums/PartsKitsViewPageEnums';
import { LineCellType } from '../../enquiry/enquiry-detailed/components/enquiry-content/qoute/table/QuoteLinesCells';
import { getRequisiteInfo } from '../../common/utils/requisiteHelper';

export const assigneesFilterInitSelector = (state: RootState) => state.enquiries.assigneesFilter;
export const assigneesFilterSelector = createSelector(assigneesFilterInitSelector, (filters) => {
  return filters.map((ass) => {
    return {
      value: ass.id,
      label: `${ass.firstName || ''} ${ass.lastName || ''}`,
    };
  });
});

export const enquiriesFiltersSelector = (state: RootState) => state.enquiries.filters;
export const enquiryDashFiltersListSelector = createSelector(
  enquiriesFiltersSelector,
  priorityLevelsSelector,
  assigneesFilterSelector,
  allowedJobTypesForUserSelector,
  customerTypesSelector,
  enquiryWarningsSelector,
  (
    values,
    priorityLevels,
    assignees,
    jobTypes,
    customerTypes,
    warnings,
  ) => {
    return [
      {
        value: values.priority || [],
        name: 'priority',
        label: 'Priority',
        options: priorityLevels,
      },
      {
        value: values.assignedTo || [],
        name: 'assignedTo',
        label: 'Assigned to',
        options: assignees,
      },
      {
        value: values.jobType || [],
        name: 'jobType',
        label: 'Job type',
        options: jobTypes,
      },
      {
        value: values.customerType || [],
        name: 'customerType',
        label: 'Customer type',
        options: customerTypes,
      },
      {
        value: values.warnings || [],
        name: 'warnings',
        label: 'Warnings',
        options: warnings,
      },
    ];
  },
);

export const columnsPaginationSelector = (state: RootState) => state.enquiries.columnsPagination;
export const detailsFiltersSelector = (state: RootState) => state.enquiries.detailsFilters;
export const enquiryCollectionsSelector = (state: RootState) => state.enquiries.enquiryCollections;
export const sortedEnqCollectionsSelector = createSelector(enquiryCollectionsSelector, (collections) => {
  const newArr = collections ? [...collections] : null;
  return newArr?.sort((a, b) => a.order - b.order) || null;
});

export const enquiryDetailsSelector = (state: RootState) => state.enquiries.enquiryDetails;
export const enquiryCurrentStatusSelector = createSelector(enquiryDetailsSelector, (data) => {
  return data?.enquiry?.status;
});
export const enquiryAcceptedOrRejectedSelector = createSelector(
  enquiryDetailsSelector,
  enquiryRejectedStatusSelector,
  enquiryAcceptedStatusSelector,
  (details, rejected, accepted) => {
    return details?.enquiry?.status === rejected?.value || details?.enquiry?.status === accepted?.value;
  },
);

export const enquiryDetailsUiSelector = createSelector(enquiryDetailsSelector, (data) => {
  const details = data?.enquiry;
  return {
    enquirySource: details?.enquirySource,
    priorityLevel: details?.priorityLevel,
    estimatedCost: details?.estimatedCost,
    jobType: details?.jobType,
    description: details?.description,
    jobCompanyName: details?.jobCompanyName,
    jobAddressLine1: details?.jobAddressLine1,
    jobAddressLine2: details?.jobAddressLine2,
    jobCityTown: details?.jobCityTown,
    jobRegion: details?.jobRegion,
    jobPostalCode: details?.jobPostalCode,
    jobTelephone: details?.jobTelephone,
    rejectionReason: details?.rejectionReason,
    specDescription: details?.specDescription,
    quoteDate: details?.quoteDate,
    customerReferenceNumber: details?.customerReferenceNumber,
  };
});

export const enquiryLocationSelector = createSelector(enquiryDetailsSelector, (data) => {
  const details = data?.enquiry;
  return [
    details?.jobCompanyName,
    details?.jobAddressLine1,
    details?.jobAddressLine2,
    details?.jobCityTown,
    details?.jobRegion,
    details?.jobPostalCode,
    details?.jobTelephone,
  ];
});

export const permittedEnqDashStatusesSelector = createSelector(
  currentUserSelector,
  enquiryStatusSelector,
  enquiryAcceptedStatusSelector,
  enquiryRejectedStatusSelector,
  (currentUser, enquiryStatus, enquiryAcceptedStatus, enquiryRejectedStatus) => {
    const permissions = currentUser?.userContextRole.permissions;
    let statuses: DictionaryItem[] = enquiryStatus;
    if (!permissions?.includes(PermissionEnum.EnquiryShowAccepted)) {
      statuses = statuses.filter((st) => st.value !== enquiryAcceptedStatus?.value);
    }
    if (!permissions?.includes(PermissionEnum.EnquiryShowRejected)) {
      statuses = statuses.filter((st) => st.value !== enquiryRejectedStatus?.value);
    } else statuses = enquiryStatus;
    return statuses;
  },
);
export const enquiryDetailsRequisitesSelector = createSelector(enquiryDetailsSelector, (details) => {
  return getRequisiteInfo(details?.billingRequisite, details?.shippingRequisite, details?.enquiry);
});

export const quoteVehiclesListSelector = (state: RootState) => state.enquiries.quoteVehiclesList;
export const openedVehicleIdSelector = (state: RootState) => state.enquiries.openedVehicleId;
export const quoteLinesSelector = (state: RootState) => state.enquiries.quoteLines;
export const quoteLinesTableViewSelector = createSelector(quoteLinesSelector, (quoteLines) => {
  return quoteLines.items.map((l) => ({
    id: l.id,
    name: l.productName || '',
    unitOfMeasure: l.unitOfMeasure,
    partId: l.partId,
    stockQuantity: l.stockQuantity,
    manualInputQuantity: l.manualInputQuantity,
    manualInputUnitPrice: l.manualInputUnitPrice,
    pricelists: l.pricelists,
  }));
});
export const quoteLinesFiltersSelector = (state: RootState) => state.enquiries.quoteLinesFilters;
export const enqAttachmentsSelector = (state: RootState) => state.enquiries.enqAttachments;
export const enqAttachmentsFiltersSelector = (state: RootState) => state.enquiries.enqAttachmentsFilters;
export const enqEnquiryTimelineSelector = (state: RootState) => state.enquiries.enquiryTimeline;
export const enqTimelineFiltersSelector = (state: RootState) => state.enquiries.enquiryTimelineFilters;
export const enquiryTimelineQuoteLinesSelector = (state: RootState) => state.enquiries.enquiryTimelineQuoteLines;
export const enquiryTimelineQuoteLinesFiltersSelector = (state: RootState) => state.enquiries.enquiryTimelineQuoteLinesFilters;
export const enquiryQuoteLinesCategoriesSelector = (state: RootState) => state.enquiries.quoteLinesCategories;

export const quoteLinePartsSelector = (state: RootState) => state.enquiries.quoteLineParts;
export const quoteLinePartsFiltersSelector = (state: RootState) => state.enquiries.quoteLinePartsFilters;
export const quoteLinePartsKitSelector = (state: RootState) => state.enquiries.quoteLinePartsKit;
export const quoteLinePartsKitFiltersSelector = (state: RootState) => state.enquiries.quoteLinePartsKitFilters;
export const quoteVehicleSummarySelector = (state: RootState) => state.enquiries.quoteVehicleSummary;

export const enquiryContactsSelector = (state: RootState) => state.enquiries.enquiryContacts;
export const enquiryContactsPagingSelector = (state: RootState) => state.enquiries.enquiryContactsPaging;
export const existedContactsSelector = (state: RootState) => state.enquiries.existedContacts;
export const enquiryExistedContactsPagingSelector = (state: RootState) => state.enquiries.enquiryExistedContactsPaging;

export const currentSourceSelector = (state: RootState) => state.enquiries.currentInfoSource;

export const currentSourceValueSelector = createSelector(
  QuoteInfoSourcesSelector,
  currentSourceSelector,
  (sources, currentSource) => {
    return {
      isCorePart: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.CorePart,
      isNonCorePart: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.NonCorePart,
      isCustom: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.Custom,
      isPartKit: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.PartsKit,
    };
  },
);

export const isCurrentVehManualSelector = createSelector(
  vehicleInfoSourcesSelector,
  quoteVehiclesListSelector,
  openedVehicleIdSelector,
  (sources, items, id) => {
    const currentVehData = items?.find((el) => el.id === id);
    const manualSource = sources.find((el) => el.label === VehicleInfoSourcesUiNames.ManualInput);
    return manualSource?.value === currentVehData?.vehicleInfoSource;
  },
);

export const currentVehicleSelector = createSelector(
  openedVehicleIdSelector,
  quoteVehiclesListSelector,
  (openedVehicleId, vehiclesList) => {
    return vehiclesList?.find((el) => el.id === openedVehicleId);
  },
);

export const quoteLinesColumnsSelector = createSelector(
  quoteLineTaxesSelector,
  enquiryQuoteLinesCategoriesSelector,
  quoteLinePriceSourcesSelector,
  currentVehicleSelector,
  enquiryQuoteLinesOrderingSelector,
  (taxes, enquiryQuoteLinesCategories, priceSources, currentVehicle, ordering) => {
    let columns = [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Category',
        dataIndex: 'quoteCategory',
        orderField: getSortIndex(QuoteLinesOrdering.QuoteCategory, ordering),
        sorter: true,
        filters: enquiryQuoteLinesCategories,
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        orderField: getSortIndex(QuoteLinesOrdering.ProductNumber, ordering),
        sorter: true,
        filterSearch: true,
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        orderField: getSortIndex(QuoteLinesOrdering.ProductName, ordering),
        sorter: true,
        filterSearch: true,
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        filterSearch: true,
        orderField: getSortIndex(QuoteLinesOrdering.Notes, ordering),
        sorter: true,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
        orderField: getSortIndex(QuoteLinesOrdering.Quantity, ordering),
        sorter: true,
        isFilterRange: true,
      },
      {
        title: 'Unit price',
        dataIndex: 'unitPrice',
        isFilterRange: true,
        orderField: getSortIndex(QuoteLinesOrdering.UnitPrice, ordering),
        sorter: true,
        prefix: '£',
      },
      {
        title: 'Source',
        dataIndex: 'priceSources',
        filters: priceSources,
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        isFilterRange: true,
        sorter: true,
        orderField: getSortIndex(QuoteLinesOrdering.Discount, ordering),
        suffix: '%',
      },
      {
        title: 'Net price',
        dataIndex: 'netPrice',
        isFilterRange: true,
        rangeWithoutInputs: true,
        orderField: getSortIndex(QuoteLinesOrdering.NetPrice, ordering),
        sorter: true,
        prefix: '£',
      },
      {
        title: 'Tax',
        dataIndex: 'tax',
        orderField: getSortIndex(QuoteLinesOrdering.Tax, ordering),
        sorter: true,
        filters: taxes,
        suffix: '%',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        orderField: getSortIndex(QuoteLinesOrdering.Weight, ordering),
        sorter: true,
        isFilterRange: true,
        suffix: 'kg',
      },
    ];
    if (!currentVehicle?.enableDiscounts) {
      columns = columns.filter((col) => col.dataIndex !== 'discount');
    }
    if (!currentVehicle?.enableNotes) {
      columns = columns.filter((col) => col.dataIndex !== 'notes');
    }
    return columns;
  },
);

export const enquiryQuoteTimelineColumnsSelector = createSelector(enquiryTimelineQuoteLineGroupOrderingSelector, (ordering) => [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Category',
    dataIndex: 'quoteCategory',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.QuoteCategoryName, ordering),
    sorter: true,
  },
  {
    title: 'Product No',
    dataIndex: 'productNumber',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.ProductNumber, ordering),
    sorter: true,
  },
  {
    title: 'Product name',
    dataIndex: 'productName',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.ProductName, ordering),
    sorter: true,
  },
  {
    title: 'Notes',
    dataIndex: 'notes',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.Notes, ordering),
    sorter: true,
  },
  {
    title: 'Qty',
    dataIndex: 'quantity',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.Quantity, ordering),
    sorter: true,
  },
  {
    title: 'Unit price',
    dataIndex: 'unitPrice',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.UnitPrice, ordering),
    sorter: true,
  },
  {
    title: 'Source',
    dataIndex: 'priceSources',
  },
  {
    title: 'Discount',
    dataIndex: 'discount',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.Discount, ordering),
    sorter: true,
  },
  {
    title: 'Net price',
    dataIndex: 'netPrice',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.NetPrice, ordering),
    sorter: true,
  },
  {
    title: 'Tax',
    dataIndex: 'tax',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.Tax, ordering),
    sorter: true,
  },
  {
    title: 'Weight',
    dataIndex: 'weight',
    orderField: getSortIndex(QuoteLinesTimelineOrdering.Weight, ordering),
    sorter: true,
  },
]);

export const quoteTableLinesSelector = createSelector(
  quoteLinesSelector,
  enquiryQuoteLinesCategoriesSelector,
  currentVehicleSelector,
  (quoteLines, enquiryQuoteLinesCategories, currentVehicle) => {
    let lines: LineCellType[] = quoteLines.items.map((el) => ({
      id: el.id,
      number: el.number,
      quoteCategory: enquiryQuoteLinesCategories.find((cat) => el.quoteCategoryId === cat.value)?.label,
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      notes: el.notes || '-',
      quantity: el.quantity || 0,
      unitPrice: el.unitPriceString || '0.00',
      priceSources: el.priceSources || '-',
      discount: el.discount || 0,
      netPrice: el.netPriceString || '0.00',
      tax: el.tax === null ? '-' : el.tax,
      productWeight: el.productWeight,
      weight: el.weight || 0,
      isProductSuitable: el.isProductSuitable,
      isUsualLine: el.isUsualLine,
      partsKitId: el.partsKitId,
      partId: el.partId,
      unitOfMeasure: el.unitOfMeasure,
    }));
    if (!currentVehicle?.enableDiscounts) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ discount, ...rest }) => rest);
    }
    if (!currentVehicle?.enableNotes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      lines = lines.map(({ notes, ...rest }) => rest);
    }
    return lines;
  },
);

export const enquiryLinksSelector = (state: RootState) => state.enquiries.enquiryLinks;
export const enquiryLinksRowsSelector = createSelector(
  enquiryLinksSelector,
  orderStatusSelector,
  linksTypesSelector,
  (data, orderStatuses, types) => {
    const inheritanceArr = linksReformHandler(data);
    const statusLabel = (status: string | number) => {
      return orderStatuses.find((st) => st.value === status)?.label || status;
    };
    return linksUiViewHandle(inheritanceArr, statusLabel, types);
  },
);
export const enquiryLinksFiltersSelector = (state: RootState) => state.enquiries.enquiryLinksFilters;
export const generateEmailContactsPagingSelector = (state: RootState) => state.enquiries.generateEmailContactsPaging;
export const generateEmailContactsListSelector = (state: RootState) => state.enquiries.generateEmailContactsList;

export const existingVehicleSpecificationFiltersSelector = (state: RootState) => state.enquiries.existingVehicleSpecificationFilters;
export const existingVehicleSpecificationFiltersListSelector = createSelector(
  existingVehicleSpecificationFiltersSelector,
  allowedShortBaForUserSelector,
  (filters, customerTypes) => {
    return [
      {
        label: 'Business areas',
        name: 'businessAreas',
        value: filters?.businessAreas || [],
        options: customerTypes,
      },
      {
        label: 'Specification vehicle',
        name: 'vehicleSpecifications',
        value: filters?.vehicleSpecifications || [],
        // TODO: ADD OPTIONS
        options: [],
      },
    ];
  },
);

export const addVehicleProfileFiltersSelector = (state: RootState) => state.enquiries.addVehicleProfileFilters;
export const addVehicleProfilesUISelector = createSelector(
  vehiclesProfilesSelector,
  addVehicleProfileFiltersSelector,
  vehicleProfilesGroupsUiSelector,
  vehicleProfilesTypesUiSelector,
  (data, filters, groups, types) => {
    return {
      ...data,
      items: data.items.map((item, index) => ({
        id: item.id,
        number: getNo(filters.page, filters.pageSize, index),
        code: item.code,
        manufacturer: item.manufacturer,
        group: groups.find((el) => el.value === item.group)?.label || item.group,
        modelDescription: item.modelDescription,
        type: types.find((type) => type.value === item.type)?.label || item.type,
        maxPayload: item.maxPayload || 0,
        grossVehicleWeightKg: item.grossVehicleWeightKg || 0,
      })),
    };
  },
);

const missingQuoteLinesSelector = (state: RootState) => state.enquiries.missingQuoteLines;
export const missingQuoteLinesCellsSelector = createSelector(
  missingQuoteLinesSelector,
  QuoteUnitsOfMeasureSelector,
  (data, unitsOfMeasure) => {
    return {
      ...data,
      items: data.items.map((item) => ({
        entity: item.partId ? 'Part' : 'Parts kit',
        productNumber: item.productNumber,
        productName: item.productName,
        quantity: `${item.quantity} ${unitsOfMeasure.find((el) => el.value === item.unitOfMeasure)?.label || ''}`,
      })),
    };
  },
);

export const quoteLinePartsKitColumnsSelector = createSelector(stockPartsKitsOrderingSelector, (ordering) => [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Parts kit code',
    dataIndex: 'partsKitCode',
    orderField: getSortIndex(PartsKitOrderingEnum.PartsKitCode, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Parts kit name',
    dataIndex: 'name',
    orderField: getSortIndex(PartsKitOrderingEnum.Name, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Items',
    dataIndex: 'partsCount',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    orderField: getSortIndex(PartsKitOrderingEnum.Description, ordering),
    sorter: true,
    filterSearch: true,
  },
]);

export const quoteLinePartsColumnsSelector = createSelector(stockPartsOrderingSelector, (ordering) => [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Part code',
    dataIndex: 'partCode',
    orderField: getSortIndex(StockPartsOrders.PartCode, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Manufacturer code',
    dataIndex: 'manufacturerCode',
    orderField: getSortIndex(StockPartsOrders.ManufacturerCode, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Part name',
    dataIndex: 'name',
    orderField: getSortIndex(StockPartsOrders.Name, ordering),
    sorter: true,
    filterSearch: true,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    orderField: getSortIndex(StockPartsOrders.Description, ordering),
    sorter: true,
    filterSearch: true,
  },
]);
