import React from 'react';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import Tag from '../../../core/components/tag/Tag';
import { StockPartRequestDto } from '../../stock-part-requests/types/stockPartRequestTypes';
import { useAppSelector } from '../../../store/hooks';
import { partRequestStatusesUiSelector } from '../../../store/selectors/coreStatusesSelectors';
import { DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT } from '../../../core/utils/regex';

type StockPartReqViewHeadProps = {
  partRequest: StockPartRequestDto | null,
}

const StockPartReqViewHead: React.FC<StockPartReqViewHeadProps> = ({ partRequest }) => {
  const stockPartReqUiStatuses = useAppSelector(partRequestStatusesUiSelector);
  const entries = [
    { label: 'Part request No', value: partRequest?.partRequestNumber },
    { label: 'Linked to', value: partRequest?.linkedEntityNumber },
    { label: 'Customer', value: partRequest?.customerName || '-' },
    {
      label: 'Due date',
      value: partRequest?.dueDate
        ? localDateFormatHandler(DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT, partRequest.dueDate)
        : '-',
    },
    { label: 'Status', value: stockPartReqUiStatuses.find((st) => st.value === partRequest?.status)?.label || '-' },
    {
      label: 'Created date',
      value: partRequest?.creationDate
        ? localDateFormatHandler(DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT, partRequest?.creationDate)
        : '-',
    },
    {
      label: 'Modified date',
      value: partRequest?.modifiedDate
        ? localDateFormatHandler(DEFAULT_UI_DATE_WITH_ALPHABETIC_MONTH_FORMAT, partRequest?.modifiedDate)
        : '-',
    },
  ];

  return (
    <>
      {entries.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Status'
          ? <Tag label={ent.value?.toString() || ''} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default StockPartReqViewHead;
