import React from 'react';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import SpecificEntitiesColumns from './SpecificEntitiesColumns';
import SpecificEntitiesBody from './SpecificEntitiesBody';
import { NumberFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';

type SpecificEntitiesTableProps = {
  checkRow: NumberFunctionType,
  isAll: boolean,
  isIndeterminate: boolean,
  checkAll: VoidFunctionType,
  checkedKeys: number[],
  type: 'purchases' | 'sales',
}

const SpecificEntitiesTable: React.FC<SpecificEntitiesTableProps> = ({
  checkRow, checkedKeys, checkAll, isAll, isIndeterminate, type,
}) => {
  return (
    <TableNew>
      <SpecificEntitiesColumns type={type} isAll={isAll} isIndeterminate={isIndeterminate} checkAll={checkAll} />
      <SpecificEntitiesBody checkedKeys={checkedKeys} checkRow={checkRow} />
    </TableNew>
  );
};

export default SpecificEntitiesTable;
