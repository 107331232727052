import React from 'react';
import { PartResultType } from '../../types/stockSearchTypes';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import Tr from '../../../../core/components/table-components/Tr';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';

type SearchTableRowProps = {
  data: PartResultType,
}

const SearchTableRow: React.FC<SearchTableRowProps> = ({ data }) => {
  const viewPageKeys = ['partCode', 'manufacturerCode', 'name'];

  return (
    <Tr>
      {Object.entries(data).map(([key, value]) => {
        if (key === 'id') return null;
        return <TdNew key={key} className="stockSearchTd" lg>
          {viewPageKeys.includes(key)
            ? <PrivateLineLink to={`part/${data.id}`} permission={PermissionEnum.StockPartViewPage} fitContent>
              <TruncateTooltip value={value as string} table />
            </PrivateLineLink>
            : <TruncateTooltip value={value as string} table />}
        </TdNew>;
      })}
    </Tr>
  );
};

export default SearchTableRow;
