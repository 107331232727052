import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, EntityLinkType, LinksFiltersType, ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { setLoading } from '../../slices/coreSlice';
import { jobsAPI } from '../../../api/jobsApi';

export const getJobLinksThunk = createAsyncThunk<
ResponseSingleResult<{ jobLinks: EntityLinkType[] }>,
{ jobId: number, params: LinksFiltersType }
>(
  'get/JobLinks',
  async ({ jobId, params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await jobsAPI.fetchJobLinks(jobId, params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
