import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CoreAttachmentsFilters, CustomAny, initialCoreAttachmentsFilters } from '../../core/types/coreTypes';

interface IFormsState {
  formsTableFilters: CoreAttachmentsFilters,
  formsFolders: Array<CustomAny>,
  currentFolder: number | undefined,
}

export const initialFormsState: IFormsState = {
  formsTableFilters: initialCoreAttachmentsFilters,
  formsFolders: [],
  currentFolder: undefined,
};

export const formsSlice = createSlice({
  name: 'forms',
  initialState: initialFormsState,
  reducers: {
    setFormsTableFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.formsTableFilters = action.payload;
    },
    setFormFolders: (state, action: PayloadAction<Array<CustomAny>>) => {
      state.formsFolders = action.payload;
    },
    setCurrentFolder: (state, action: PayloadAction<number | undefined>) => {
      state.currentFolder = action.payload;
    },
  },
  // extraReducers: (builder) => {},
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setFormsTableFilters,
  setFormFolders,
  setCurrentFolder,
} = formsSlice.actions;

export default formsSlice.reducer;
