import {
  SalesReportsEnum, ReportsModesEnum, StockReportsEnum, ProductionReportsEnum, PurchasingReportsEnum,
} from '../enum/ReportsEnum';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';

const productionReportPermissions = [
  PermissionEnum.ReportProductionFitmentTimeReport,
  // add permission for every new PRODUCTION report
];

const salesReportPermissions = [
  PermissionEnum.ReportSalesNumberOfConvertedVehiclesReport,
  PermissionEnum.ReportRectificationReport,
  PermissionEnum.ReportRectificationByProductReport,
  PermissionEnum.ReportRectificationBySupplierReport,
  PermissionEnum.ReportSalesOfficeEmployeeReport,
  PermissionEnum.ReportUnfinishedEntitiesReport,
  PermissionEnum.ReportSoldProductsByCustomer,
  PermissionEnum.ReportSalesByCustomerReport,
  PermissionEnum.ReportSalesOffsiteWorkingReport,
  // add permission for every new SALES report
];

const stockReportPermissions = [
  PermissionEnum.ReportStockFullReport,
  // add permission for every new STOCK report
];

const purchasingReportPermissions = [
  PermissionEnum.ReportPurchasingOutstandingOverduePurchaseOrdersReport,
  PermissionEnum.ReportPurchasingSupplierSpendReport,
  PermissionEnum.ReportPurchasingFitterReturnsReport,
  PermissionEnum.ReportPurchasingCancelledLinesReport,
  // add permission for every new PURCHASING report
];

export const reportsModes = [
  {
    label: 'HR',
    value: ReportsModesEnum.HR,
    restrictedTo: PermissionEnum.ReportHRPage,
    permissionsList: [],
  },
  {
    label: 'Management',
    value: ReportsModesEnum.MANAGEMENT,
    restrictedTo: PermissionEnum.ReportManagementPage,
    permissionsList: [],
  },
  {
    label: 'Production',
    value: ReportsModesEnum.PRODUCTION,
    restrictedTo: PermissionEnum.ReportProductionPage,
    permissionsList: productionReportPermissions,
  },
  {
    label: 'Purchasing',
    value: ReportsModesEnum.PURCHASING,
    restrictedTo: PermissionEnum.ReportPurchasingPage,
    permissionsList: purchasingReportPermissions,
  },
  {
    label: 'Sales',
    value: ReportsModesEnum.SALES,
    restrictedTo: PermissionEnum.ReportSalesPage,
    permissionsList: salesReportPermissions,
  },
  {
    label: 'Stock',
    value: ReportsModesEnum.STOCK,
    restrictedTo: PermissionEnum.ReportStockPage,
    permissionsList: stockReportPermissions,
  },
];

export const productionReportTypes = [
  {
    label: 'Fitment time report',
    value: ProductionReportsEnum.FITMENT_TIME_REPORT,
    restrictedTo: PermissionEnum.ReportProductionFitmentTimeReport,
  },
];

export const salesReportTypes = [
  {
    label: 'Loaded spec',
    value: SalesReportsEnum.LOADED_SPEC,
    restrictedTo: PermissionEnum.TestModule,
  },
  {
    label: 'Number of converted vehicles',
    value: SalesReportsEnum.NUMBER_OF_CONVERTED_VEHICLES,
    restrictedTo: PermissionEnum.ReportSalesNumberOfConvertedVehiclesReport,
  },
  {
    label: 'Off-site working',
    value: SalesReportsEnum.OFF_SITE_WORKING,
    restrictedTo: PermissionEnum.ReportSalesOffsiteWorkingReport,
  },
  {
    label: 'Rectification report',
    value: SalesReportsEnum.RECTIFICATION_REPORT,
    restrictedTo: PermissionEnum.ReportRectificationReport,
  },
  {
    label: 'Rectifications by supplier',
    value: SalesReportsEnum.RECTIFICATIONS_BY_SUPPLIER,
    restrictedTo: PermissionEnum.ReportRectificationBySupplierReport,
  },
  {
    label: 'Rectifications by product',
    value: SalesReportsEnum.RECTIFICATIONS_BY_PRODUCT,
    restrictedTo: PermissionEnum.ReportRectificationByProductReport,
  },
  {
    label: 'Sales office employee report',
    value: SalesReportsEnum.SALES_OFFICE_EMPLOYEE_REPORT,
    restrictedTo: PermissionEnum.ReportSalesOfficeEmployeeReport,
  },
  {
    label: 'Sales report by customer',
    value: SalesReportsEnum.SALES_REPORT_BY_CUSTOMER,
    restrictedTo: PermissionEnum.ReportSalesByCustomerReport,
  },
  {
    label: 'Sold products by customer',
    value: SalesReportsEnum.SOLD_PRODUCT_BY_CUSTOMER,
    restrictedTo: PermissionEnum.ReportSoldProductsByCustomer,
  },
  {
    label: 'Unfinished entities',
    value: SalesReportsEnum.UNFINISHED,
    restrictedTo: PermissionEnum.ReportUnfinishedEntitiesReport,
  },
];

export const stockReportTypes = [
  {
    label: 'Full stock report',
    value: StockReportsEnum.FULL_STOCK_REPORT,
    restrictedTo: PermissionEnum.ReportStockFullReport,
  },
];

export const purchasingReportTypes = [
  {
    label: 'Cancelled lines',
    value: PurchasingReportsEnum.CANCELLED_LINES,
    restrictedTo: PermissionEnum.ReportPurchasingCancelledLinesReport,
  },
  {
    label: 'Fitter returns',
    value: PurchasingReportsEnum.FITTER_RETURNS,
    restrictedTo: PermissionEnum.ReportPurchasingFitterReturnsReport,
  },
  {
    label: 'Outstanding / overdue purchase orders',
    value: PurchasingReportsEnum.OUTSTANDING_OVERDUE_PURCHASE_ORDERS_REPORT,
    restrictedTo: PermissionEnum.ReportPurchasingOutstandingOverduePurchaseOrdersReport,
  },
  {
    label: 'Supplier spend',
    value: PurchasingReportsEnum.SUPPLIER_SPEND,
    restrictedTo: PermissionEnum.ReportPurchasingSupplierSpendReport,
  },
];

// All new report types should be added to the array below
export const allReportsValues = [
  ...productionReportTypes,
  ...salesReportTypes,
  ...stockReportTypes,
  ...purchasingReportTypes,
].map(({ value }) => value.toString());
