import qs from 'qs';
import instance from './axiosConfig';
import { CreateRectificationReqType } from '../rectifications/create-rectification/types/createRectificationTypes';
import {
  FetchRectificationsCollectionsParamsType, GetRectificationResponseType, GetRectificationsResponseType,
  RectificationDto, RectificationSummaryResponse,
  SaveRectificationsLayoutSettingDto,
} from '../rectifications/dashboard/types/rectificationsTypes';
import {
  CoreAttachmentsFilters,
  LinksFiltersType,
  PostAttachReqType,
  UploadUriDto,
  AxiosSingleResponse,
  AxiosNullableResponse,
  AxiosDownloadUriResponse,
  AxiosGetPagedAttachmentsResponse,
  AxiosGetAttachmentFileResponse,
  AxiosCreateUploadUriResponse,
  AxiosCreatedAttachmentsResponse,
  AxiosDataListResponse,
  AxiosCreatedIdResponse, PatchAttachmentModel,
} from '../core/types/coreTypes';
import {
  NewContactReqDataType, RectificationContactDto, RectificationEventDtoBase, RectificationLinksResType,
} from '../rectifications/rectification-view-page/types/rectificationViewTypes';
import {
  ExtendedTimelineFilters,
  FetchRectificationsDictionaryParamsType,
  RectificationDictionaryDto,
} from '../common/types/commonTypes';

export const rectificationsAPI = {
  createRectification(data: CreateRectificationReqType): AxiosSingleResponse<{
    createdId: number,
    rectificationNumber: string | null,
  }> {
    return instance.post('/rectification/rectifications', { ...data });
  },
  patchRectification(id: number, data: Partial<RectificationDto>): AxiosNullableResponse {
    return instance.patch(`/rectification/rectifications/${id}`, { rectification: { ...data } });
  },
  fetchRectifications(
    params: FetchRectificationsCollectionsParamsType,
    signal?: AbortSignal,
  ): AxiosSingleResponse<GetRectificationsResponseType> {
    return instance.get('/rectification/rectifications/views/dashboard', {
      params: {
        'BusinessArea': params.businessArea,
        'Filter.Keyword': params.keyword,
        'Filter.PriorityLevels': params.priority,
        'Filter.Assignees': params.assignedTo,
        'Filter.JobTypes': params.jobType,
        'Filter.RectificationTypes': params.rectificationType,
        'Filter.Warnings': params.warnings,
        'ApplyPersonalFilter': params.applyPersonalFilter,
        'Statuses': params.statuses,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationsDictionary(
    filters?: FetchRectificationsDictionaryParamsType,
  ): AxiosSingleResponse<{ rectifications: RectificationDictionaryDto[] }> {
    return instance.get('/rectification/rectifications/views/dictionary', {
      params: {
        'Filter.SearchParameters.Keyword': filters?.keyword,
        'Filter.SearchParameters.IncludeRectificationNumber': filters?.includeRectificationNumber,
        'Filter.SearchParameters.IncludeCustomerName': filters?.includeCustomerName,
        'Filter.SearchParameters.IncludeEnquiryNumbers': filters?.includeEnquiryNumbers,
        'Filter.OnlyAvailableForJobCreation': filters?.onlyAvailableForJobCreation,
      },
    });
  },
  changeRectificationsLayout(newLayout: Array<SaveRectificationsLayoutSettingDto>): AxiosNullableResponse {
    return instance.put('/rectification/rectifications/views/dashboard/layout', { layoutSettings: newLayout });
  },
  changeRectificationStatus(id: number, statusTransition: number, rejectionReason?: string): AxiosNullableResponse {
    return instance.put(`/rectification/rectifications/${id}/status`, { statusTransition, rejectionReason });
  },
  fetchRectificationById(id: number): AxiosSingleResponse<GetRectificationResponseType> {
    return instance.get(`/rectification/rectifications/${id}`);
  },
  exportRectificationToPdf(id: number): AxiosDownloadUriResponse {
    return instance.get(`/rectification/rectifications/${id}/pdfFile`);
  },
  fetchRectificationAttachments(rectificationId: number, params: CoreAttachmentsFilters): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/rectification/rectifications/${rectificationId}/attachments`, {
      params: {
        'Filter.Name': params.name,
        'Filter.MinUploadDate': params.uploadDate?.min,
        'Filter.MaxUploadDate': params.uploadDate?.max,
        'Filter.Comment': params.comment,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchRectificationAttachmentById(rectificationId: number, id: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/rectification/rectifications/${rectificationId}/attachments/${id}`);
  },
  postRectificationAttachments(rectificationId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/rectification/rectifications/${rectificationId}/attachments`, { objectId: rectificationId, attachments });
  },
  postRectificationAttachmentUploadUri(rectificationId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/rectification/rectifications/${rectificationId}/attachments/uploadUri`, { uploadUriDtos });
  },
  editRectificationAttachment(rectificationId: number, id: number, data: PatchAttachmentModel): AxiosNullableResponse {
    return instance.patch(`/rectification/rectifications/${rectificationId}/attachments/${id}`, {
      comment: data.comment,
      showInLinkedJobs: data.showInLinkedJobs,
    });
  },
  deleteRectificationAttachment(rectificationId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/rectification/rectifications/${rectificationId}/attachments/${id}`);
  },
  deleteMultipleRectificationAttachment(rectificationId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/rectification/rectifications/${rectificationId}/attachments`, { data: { attachmentIds } });
  },
  fetchRectificationLinks(rectificationId: number, filters: LinksFiltersType): AxiosSingleResponse<RectificationLinksResType> {
    return instance.get(`/rectification/rectifications/${rectificationId}/links`, {
      params: {
        'Filter.EntityName': filters.entity,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'Filter.ModifiedDate.From': filters.modifiedDate?.min,
        'Filter.ModifiedDate.To': filters.modifiedDate?.max,
        'OrderOptions.Field': filters.order.field,
        'OrderOptions.Ascending': filters.order.isAsc,
      },
    });
  },
  fetchRectificationContacts(
    rectificationId: number,
    page: number,
    pageSize: number,
    haveEmail?: boolean,
  ): AxiosDataListResponse<RectificationContactDto[], { allItemIds: number[] }> {
    return instance.get(`/rectification/rectifications/${rectificationId}/contacts`, {
      params: {
        'PagingOptions.Page': page,
        'PagingOptions.ItemsPerPage': pageSize,
        'Filter.HaveEmail': haveEmail !== undefined ? haveEmail : null,
      },
    });
  },
  createNewRectificationContact(rectificationId: number, data: NewContactReqDataType): AxiosNullableResponse {
    return instance.post(`/rectification/rectifications/${rectificationId}/contacts/new`, { ...data });
  },
  addExistingRectificationContacts(rectificationId: number, customerContactIds: number[]): AxiosNullableResponse {
    return instance.post(`/rectification/rectifications/${rectificationId}/contacts/existing`, { customerContactIds });
  },
  removeRectificationContact(rectificationId: number, customerContactId: number): AxiosNullableResponse {
    return instance.delete(`/rectification/rectifications/${rectificationId}/contacts`, { data: { customerContactId } });
  },
  fetchRectificationTimeline(
    rectificationId: number,
    filters: ExtendedTimelineFilters,
  ): AxiosDataListResponse<RectificationEventDtoBase[]> {
    return instance.get('/rectification/rectifications/timeline', {
      params: {
        'RectificationId': rectificationId,
        'MainOnly': filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postRectificationTimelineNote(rectificationId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/rectification/note', {
      rectificationId,
      content,
    });
  },
  editRectificationTimelineNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/rectification/note/${id}`, { content });
  },
  deleteRectificationTimelineNote(id: number): AxiosNullableResponse {
    return instance.delete(`/rectification/note/${id}`);
  },
  fetchRectificationSummary(id: number): AxiosSingleResponse<RectificationSummaryResponse> {
    return instance.get(`/rectification/rectifications/${id}/summary`);
  },
};
