export enum SupplierViewPageEnums {
  'SUPPLIER_DETAILS' = 'details-and-activity',
  'SUPPLIER_CONTACTS' = 'contacts',
  'SUPPLIER_ATTACHMENTS' = 'attachments',
}

export const SupplierModes = [
  SupplierViewPageEnums.SUPPLIER_DETAILS,
  SupplierViewPageEnums.SUPPLIER_CONTACTS,
  SupplierViewPageEnums.SUPPLIER_ATTACHMENTS,
];

export enum SupplierEventTypesEnum {
  SupplierCreation = 'SupplierCreation',
  SupplierFieldUpdateOrAddingValue = 'SupplierFieldUpdateOrAddingValue',
  SupplierStatusChange = 'SupplierStatusChange',
  SupplierDeletion = 'SupplierDeletion',
  Note = 'Note',
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
}
