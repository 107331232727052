import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPencil } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { VehicleProfileSearchType } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';

type ChosenVehicleProfileProps = {
  chosenVehicleProfile: VehicleProfileSearchType | null,
  selectAnotherProfile?: VoidFunctionType,
  readOnly?: boolean,
  noCode?: boolean,
  extraInfo?: boolean,
  columnsCount?: number,
}

const ChosenVehicleProfile: React.FC<ChosenVehicleProfileProps> = ({
  chosenVehicleProfile, selectAnotherProfile, readOnly, noCode, extraInfo, columnsCount = 4,
}) => {
  const info = [
    { label: 'Code', value: chosenVehicleProfile?.code },
    { label: 'Manufacturer', value: chosenVehicleProfile?.manufacturer },
    { label: 'Type', value: chosenVehicleProfile?.type },
    { label: 'Group', value: chosenVehicleProfile?.group },
    { label: 'Model description', value: chosenVehicleProfile?.modelDescription },
  ];

  const extraInfoTop = [
    { label: 'Number of sliding doors', value: chosenVehicleProfile?.slidingDoorsQuantity },
    { label: 'Gross vehicle weight (GVW), kg', value: chosenVehicleProfile?.grossVehicleWeightKg },
    { label: 'Maximum payload, kg', value: chosenVehicleProfile?.maxPayload },
  ];
  const extraInfoBottom = [
    { label: 'Chassis No', value: chosenVehicleProfile?.chassisNumber },
    { label: 'Reg No', value: chosenVehicleProfile?.regNumber },
  ];

  const styles = { '--columns-count': `${columnsCount}` } as React.CSSProperties;

  return (
    <div className={classNames('chosenVehicleProfile', { 'chosenVehicleProfile--read-only': readOnly })} style={styles}>
      {!readOnly && <Dropdown
        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
        menuItems={[{
          key: 'selectAnotherVeh',
          label: 'Select vehicle profile',
          icon: <FontAwesomeIcon icon={faPencil} />,
          onClick: () => selectAnotherProfile && selectAnotherProfile(),
        }]}
        className="chosenVehicleProfile__button"
      />}
      <ul className="chosenVehicleProfile__info">
        {info.map((el, i) => {
          if (noCode && el.label === 'Code') return null;
          return <li
            key={i}
            className={classNames('chosenVehicleProfile__item', { 'chosenVehicleProfile__item--wide': el.label === 'Model description' })}
          >
            <span className="chosenVehicleProfile__label">{el.label}</span>
            {el.label === 'Manufacturer'
              ? <TruncateTooltip value={el.value} table />
              : <span>{el.value}</span>}
          </li>;
        })}
      </ul>
      {extraInfo && (
        <>
          <ul className="chosenVehicleProfile__info">
            {extraInfoTop.map((el, i) => <li key={i} className="chosenVehicleProfile__item">
              <span className="chosenVehicleProfile__label">{el.label}</span>
              <span>{el.value}</span>
            </li>)}
          </ul>
          <ul className="chosenVehicleProfile__info">
            {extraInfoBottom.map((el, i) => <li key={i} className="chosenVehicleProfile__item">
              <span className="chosenVehicleProfile__label">{el.label}</span>
              <span>{el.value}</span>
            </li>)}
          </ul>
        </>
      )}
    </div>
  );
};

export default ChosenVehicleProfile;
