import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import { orderLinksFiltersSelector } from '../../../../../store/selectors/ordersSelector';
import { setOrderLinksFilters } from '../../../../../store/slices/ordersSlice';
import LinksColumns from '../../../../../common/components/links-table/LinksColumns';
import { useLinks } from '../../../../../common/hooks/useLinks';

type OrderLinksColumnsProps = {
  totalCount: number,
}

const OrderLinksColumns: React.FC<OrderLinksColumnsProps> = ({ totalCount }) => {
  const linksFilters = useAppSelector(orderLinksFiltersSelector);

  const {
    onChangeSearchHandle,
    searchPressHandle,
    searchVl,
    pickerValue,
    setPickerValue,
    pickerHandle,
  } = useLinks(linksFilters, setOrderLinksFilters);

  return (
    <LinksColumns
      totalCount={totalCount}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={linksFilters}
    />
  );
};

export default OrderLinksColumns;
