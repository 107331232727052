import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import {
  employeesDashboardSelector,
  employeesDashStatusesSelector,
  employeesFiltersSelector,
} from '../../../store/selectors/employeesSelector';
import { setDashboardFilters } from '../../../store/slices/employeesSlice';
import { employeesFiltersType } from '../types/employeesDashTypes';
import Pagination from '../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { EmployeesPageParamEnums, EmployeeUrlParamsNames } from '../../container/enums/EmployeesPageParamsEnums';

type EmployeesActionsProps = {
  view: string | null,
  setEmployeeStatusParam: StringFunctionType,
  employeeStatusParam: string | null,
}

const EmployeesActions: React.FC<EmployeesActionsProps> = ({ view, setEmployeeStatusParam, employeeStatusParam }) => {
  const [params] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const employeesFilters = useAppSelector(employeesFiltersSelector);
  const employeesDashStatuses = useAppSelector(employeesDashStatusesSelector);
  const employeesDashboard = useAppSelector(employeesDashboardSelector);
  const { windowWidth } = useScreenWitdh();
  const setFilters = (filters: employeesFiltersType) => {
    filters.status && setEmployeeStatusParam(filters.status.toString());
    dispatch(setDashboardFilters({ ...filters }));
  };

  useEffect(() => {
    if (!employeeStatusParam && view === EmployeesPageParamEnums.DASHBOARD) {
      if (employeesDashStatuses.length > 0) {
        params.append(EmployeeUrlParamsNames.STATUS, employeesDashStatuses[0].value.toString());
        navigate({ search: params.toString() });
      }
    }
    // eslint-disable-next-line
    }, [employeesDashStatuses, employeeStatusParam, view]);
  return (
    <div className="actions-and-paging">
      <div className="actions-and-paging__actions">
        {windowWidth < responsiveEndpointsEnum.LG
          ? <Selectable
            options={employeesDashStatuses}
            onChange={(v) => setFilters({ ...employeesFilters, status: +v, page: 1 })}
            value={employeesFilters.status}
            size="sm"
          />
          : <Segmented
            options={employeesDashStatuses}
            onChange={(v) => setFilters({ ...employeesFilters, status: +v, page: 1 })}
            value={employeesFilters.status || ''}
          />}
      </div>
      <Pagination
        currentPage={employeesFilters.page}
        pageSize={employeesFilters.pageSize}
        pageSizeOptions={BIG_TABLES_PAGE_SIZES}
        onChange={(page, pageSize) => setFilters(({ ...employeesFilters, page, pageSize }))}
        totalItems={employeesDashboard.totalCount}
        narrow
        className="actions-and-paging__paging"
      />
    </div>
  );
};

export default EmployeesActions;
