import React from 'react';
import { SalesViewTabEnum } from '../enums/salesEnums';
import SalesOrderLinesContent from './order-lines/SalesOrderLinesContent';
import InvoicesAndCreditNotesContent
  from '../../common/components/invoices-and-credit-notes/InvoicesAndCreditNotesContent';

type SalesViewDynamicSectionProps = {
  tabParam: string | null,
}

const SalesViewDynamicSection: React.FC<SalesViewDynamicSectionProps> = ({ tabParam }) => {
  const renderContent = () => {
    switch (tabParam) {
      case SalesViewTabEnum.ORDER_LINES:
        return <SalesOrderLinesContent />;
      case SalesViewTabEnum.INVOICES_AND_CREDIT_NOTES:
        return <InvoicesAndCreditNotesContent />;
      default: return null;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default SalesViewDynamicSection;
