import React, { ErrorInfo } from 'react';

/* eslint-disable  @typescript-eslint/no-explicit-any */
class ErrorBoundary extends React.Component< any, { hasError: boolean }> {
  constructor(props: unknown) {
    super(props);
    /* eslint-disable  react/no-unused-state */
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): {hasError: boolean} {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    console.log('error', error.message);
    console.log('errorInfo', errorInfo);
  }

  render(): React.ReactNode {
    /* eslint-disable  react/destructuring-assignment */
    return this.props.children;
  }
}

export default ErrorBoundary;
