import { createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, StringFunctionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { accountAPI } from '../../../api/accountApi';
import { ErrorCodesEnum } from '../../../common/enums/ErrorCodesEnum';
import { UserContextDto } from '../../../settings/users-dashboard/types/settingsTypes';
import {
  setAccountLeaveRequestsFilters,
  setInAppNotifications,
  setInAppNotificationsFilters,
} from '../../slices/accountSlice';
import {
  InAppNotification,
  inAppNotificationsFiltersType,
  UserProfileDto,
  UserProfilePatchRequest,
} from '../../../account/types/AccountTypes';
import {
  setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { RootState } from '../../store';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';
import { EmployeeLeaveRequestFilters, LeaveRequestDto } from '../../../common/types/commonTypes';
import { employeeAPI } from '../../../api/employeeApi';
import {
  PostLeaveRequestRestModel,
  PutLeaveRequestRestModel,
} from '../../../account/components/account-content/leave-requests/types/AccountLeaveRequestsTypes';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { addTokenHandler } from '../../../core/utils/removeTokenHandler';
import { EditPassword } from '../../../account/utils/EditPasswordSchema';
import { LoginFields } from '../../../login/utils/LoginShema';
import { ForgotPasswordFields } from '../../../password/forgot/utils/ForgotPasswordSchema';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';

export const userInfoThunk = createAsyncThunk<ResponseSingleResult<{ userInfo: UserContextDto }>>(
  'get/UserInfo',
  async (_, { rejectWithValue }) => {
    try {
      const response = await accountAPI.userInfo();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getNewTokenThunk = createAsyncThunk<ResponseSingleResult<{ authToken: string }>>(
  'get/GetToken',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await accountAPI.getNewToken();
      const { authToken } = response.data.data;
      addTokenHandler(dispatch, authToken);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const loginThunk = createAsyncThunk<ResponseSingleResult<{ authToken: string }>,
{ email: string, password: string, setError: UseFormSetError<LoginFields>, backHandle: VoidFunctionType }>(
  'post/Login',
  async ({
    email, password, setError, backHandle,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await accountAPI.login(email, password);
      const { authToken } = response.data.data;
      dispatch(setFetching(false));
      addTokenHandler(dispatch, authToken);
      dispatch(userInfoThunk());
      backHandle();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      if (error.response?.data.status === ErrorCodesEnum.BAD_REQUEST) {
        setError('email', { type: 'Invalid value', message: 'Incorrect email or password' });
        setError('password', { type: 'Invalid value', message: 'Incorrect email or password' });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const resetPasswordThunk = createAsyncThunk<ResponseSingleResult,
{ email: string, setError: UseFormSetError<ForgotPasswordFields>, commonActions: VoidFunctionType }>(
  'post/ResetPassword',
  async ({
    email, setError, commonActions,
  }, { rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const response = await accountAPI.resetPassword(email);
      dispatch(setFetching(false));
      commonActions();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const Email = 'Email';
      if (errors.find((el) => el.key === Email)) {
        setError('email', { type: `${Email}`, message: ErrorsEnum.INVALID_EMAIL });
      }
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changePasswordThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  currentPassword: string,
  newPassword: string,
  setError: UseFormSetError<EditPassword>,
  commonActions: VoidFunctionType,
}>(
  'post/ChangePassword',
  async ({
    id, currentPassword, newPassword, setError, commonActions,
  }, { rejectWithValue, dispatch }) => {
    try {
      const response = await accountAPI.changePassword(id, currentPassword, newPassword);
      commonActions();
      dispatch(setSuccessMessage({ message: 'Password was successfully updated.' }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const PasswordMismatch = 'PasswordMismatch';
      if (errors.find((el) => el.key === PasswordMismatch)) {
        setError('password', { type: `${PasswordMismatch}`, message: ErrorsEnum.INCORRECT_PASSWORD });
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getUserProfileThunk = createAsyncThunk<ResponseSingleResult<{ userProfile: UserProfileDto }>,
{ id:number }>(
  'get/UserProfile',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await accountAPI.userProfile(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchUserProfileThunk = createAsyncThunk<ResponseSingleResult,
{
  id:number,
  nameType: string,
  data: Partial<UserProfilePatchRequest>,
  onClose?: VoidFunctionType,
}>(
  'patch/UserProfile',
  async ({
    id, nameType, data, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await accountAPI.patchUserProfile(id, data);
      await dispatch(getNewTokenThunk());
      dispatch(getUserProfileThunk({ id }));
      dispatch(userInfoThunk());
      onClose && onClose();
      dispatch(setSuccessMessage({ message: `${nameType === 'first' ? 'First' : 'Last'} name was successfully updated.` }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const validateResetTokenThunk = createAsyncThunk<ResponseSingleResult<{ email: string }> | null,
{ token: string, setEmail: StringFunctionType }
>(
  'post/ValidateReset',
  async ({ token, setEmail }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await accountAPI.validateResetToken(token);
      setEmail(res.data.data.email);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const resetPasswordConfirmThunk = createAsyncThunk<ResponseSingleResult,
{ token: string, email: string, newPassword: string, navigate: VoidFunctionType }
>(
  'post/ResetPasswordConfirm',
  async ({
    token, email, newPassword, navigate,
  }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await accountAPI.resetPasswordConfirm(token, newPassword);
      const resp = await accountAPI.login(email, newPassword);
      const { authToken } = resp.data.data;
      addTokenHandler(dispatch, authToken);
      dispatch(userInfoThunk());
      navigate();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationsThunk = createAsyncThunk<ResponseResult<InAppNotification[]>,
{ filters: inAppNotificationsFiltersType }
>(
  'get/InAppNotifications',
  async ({ filters }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const res = await accountAPI.fetchInAppNotifications(filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInAppNotificationsCountThunk = createAsyncThunk<ResponseSingleResult<{ count: number }>,
{ status: number }
>(
  'get/InAppNotificationsCount',
  async ({ status }, { rejectWithValue }) => {
    try {
      const res = await accountAPI.fetchInAppNotificationsCount(status);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeAllNotificationsStatusThunk = createAsyncThunk<ResponseSingleResult,
{ status: number, closeDrawer: VoidFunctionType }
>(
  'put/All/InAppNotifications',
  async ({ status, closeDrawer }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { account } = getState() as RootState;
      const { inAppNotificationsFilters } = account;
      const res = await accountAPI.changeAllNotificationsStatuses(status);
      if (inAppNotificationsFilters.page > 1) {
        dispatch(setInAppNotificationsFilters({ ...inAppNotificationsFilters, page: 1 }));
      } else {
        dispatch(getInAppNotificationsThunk({ filters: { ...inAppNotificationsFilters, page: 1 } }));
      }
      window.scrollTo(0, 0);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeDrawer();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeOneNotificationStatusThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, status: number, closeDrawer: VoidFunctionType }
>(
  'put/InAppNotification/status',
  async ({
    id, status, closeDrawer,
  }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { account, coreStatuses } = getState() as RootState;
      const { inAppNotifications } = account;
      const { inAppNotificationStatuses } = coreStatuses;
      const readStatus = inAppNotificationStatuses.find((st) => st.label === 'Read');
      const res = await accountAPI.changeOneNotificationStatus(id, status);
      const newList = {
        ...inAppNotifications,
        items: inAppNotifications.items.map((el) => {
          if (el.notificationId === id) {
            return {
              ...el,
              readDate: status === readStatus?.value ? moment().format(DEFAULT_DATE_FORMAT) : null,
            };
          } else return el;
        }),
      };
      dispatch(setInAppNotifications(newList));
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeDrawer();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteOneNotificationThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeDrawer: VoidFunctionType }
>(
  'delete/InAppNotification',
  async ({ id, closeDrawer }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { account } = getState() as RootState;
      const { inAppNotificationsFilters } = account;
      const res = await accountAPI.deleteOneNotification(id);
      if (inAppNotificationsFilters.page > 1) {
        dispatch(setInAppNotificationsFilters({ ...inAppNotificationsFilters, page: 1 }));
      } else {
        dispatch(getInAppNotificationsThunk({ filters: { ...inAppNotificationsFilters, page: 1 } }));
      }
      window.scrollTo(0, 0);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeDrawer();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteAllNotificationThunk = createAsyncThunk<ResponseSingleResult,
{ closeModal: VoidFunctionType, closeDrawer: VoidFunctionType }
>(
  'delete/All/InAppNotifications',
  async ({ closeModal, closeDrawer }, { getState, rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const { account } = getState() as RootState;
      const { inAppNotificationsFilters } = account;
      const res = await accountAPI.deleteAllNotifications();
      if (inAppNotificationsFilters.page > 1) {
        dispatch(setInAppNotificationsFilters({ ...inAppNotificationsFilters, page: 1 }));
      } else {
        dispatch(getInAppNotificationsThunk({ filters: { ...inAppNotificationsFilters, page: 1 } }));
      }
      window.scrollTo(0, 0);
      closeModal();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      closeDrawer();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountLeaveRequestsThunk = createAsyncThunk<ResponseResult<LeaveRequestDto[]>,
{ filters: EmployeeLeaveRequestFilters }
>(
  'get/AccountLeaveRequests',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await employeeAPI.fetchEmployeeLeaveRequests(filters);
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createAccountLeaveRequestThunk = createAsyncThunk<ResponseSingleResult<{createdId: number}>,
{ data: PostLeaveRequestRestModel, type: string, closeModal: VoidFunctionType }
>(
  'post/AccountLeaveRequest',
  async ({
    data, type, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.postEmployeeLeaveRequest(data);
      const { account: { accountLeaveRequestsFilters } } = getState() as RootState;
      if (accountLeaveRequestsFilters.page > 1 || !accountLeaveRequestsFilters.isActive) {
        dispatch(setAccountLeaveRequestsFilters({ ...accountLeaveRequestsFilters, isActive: true, page: 1 }));
      } else {
        dispatch(getAccountLeaveRequestsThunk({ filters: accountLeaveRequestsFilters }));
      }
      dispatch(setSuccessMessage({ message: `Leave request "${type}" was successfully created.`, toastId: Math.random() }));
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteAccountLeaveRequestThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/AccountLeaveRequest',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.deleteEmployeeLeaveRequest(id);
      dispatch(setFetching(false));
      const { account: { accountLeaveRequestsFilters } } = getState() as RootState;
      if (accountLeaveRequestsFilters.page > 1) {
        dispatch(setAccountLeaveRequestsFilters({ ...accountLeaveRequestsFilters, page: 1 }));
      } else {
        dispatch(getAccountLeaveRequestsThunk({ filters: accountLeaveRequestsFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeAccountLeaveRequestThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, data: PutLeaveRequestRestModel, closeModal: VoidFunctionType }
>(
  'put/AccountLeaveRequest',
  async ({
    id, data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.putEmployeeLeaveRequest(id, data);
      dispatch(setFetching(false));
      const { account: { accountLeaveRequestsFilters } } = getState() as RootState;
      dispatch(getAccountLeaveRequestsThunk({ filters: accountLeaveRequestsFilters }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAccountLeaveRequestThunk = createAsyncThunk<ResponseSingleResult<{ leaveRequest: LeaveRequestDto }>,
{ id: number }
>(
  'get/AccountLeaveRequest',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeLeaveRequest(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeAccountLeaveRequestStatusThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, statusTransition: number }
>(
  'put/AccountLeaveRequestStatus',
  async ({ id, statusTransition }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.changeEmployeeLeaveRequestStatus(id, statusTransition);
      dispatch(setLoading(false));
      const { account: { accountLeaveRequestsFilters } } = getState() as RootState;
      dispatch(getAccountLeaveRequestsThunk({ filters: accountLeaveRequestsFilters }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
