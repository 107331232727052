import { UserAccountRolePermissionDto } from '../../permissions/types/permissionTypes';
import { CustomAny } from '../../../core/types/coreTypes';
import { RoleTreeNode } from '../../permissions/components/PermissionsTable';

export const transformRoles = (input: UserAccountRolePermissionDto[] | null): RoleTreeNode[] => {
  const roles: CustomAny = {};

  input?.forEach((i: UserAccountRolePermissionDto) => {
    const colSpan = {
      fullName: i.role.fullName,
      roleLevel: i.role.roleLevel,
      role: i.role.role,
      permissions: i.permissions,
    };

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (roles[i.role.role] && roles[i.role.roleLevel]) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const current = roles[i.role.role];

      current.colSpan.push(colSpan);
    } else {
      const firstChild = {
        fullName: i.role.fullName,
        role: i.role.role,
        roleLevel: i.role.roleLevel,
        permissions: i.permissions,
      };

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      roles[i.role.role] = {
        fullName: i.role.fullName,
        role: i.role.role,
        roleLevel: i.role.roleLevel,
        permissions: i.permissions,
        colSpan: [firstChild],
      };
    }
  });

  return Object.values(roles);
};

// eslint-disable-next-line consistent-return
export const hasKids = (list: CustomAny, permission: string): CustomAny => {
  // eslint-disable-next-line consistent-return,array-callback-return,no-restricted-syntax
  let node;
  for (let i = 0; i < list.length; i++) {
    const tr = list[i];
    if (tr.permission === permission) {
      return tr;
    } else {
      if (tr.children) {
        node = hasKids(tr.children, permission);
        if (node) return node;
      } else {
        // eslint-disable-next-line no-continue
        continue;
      }
    }
  }
  return node;
};
// eslint-disable-next-line consistent-return
export const getPermissionNames = (node: CustomAny) => {
  let names: string[] = [node.permission];
  if (node.children) {
    node.children.forEach((el: CustomAny) => {
      names = names.concat(getPermissionNames(el));
    });
  }
  return names;
};
