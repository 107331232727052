import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  emptyPaging,
  GetCustomerContactsResponse,
  infiniteScrollInitPaging,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { CustomerContactFiltersType } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import { ordersAPI } from '../../../api/ordersApi';
import { NewContactReqDataType, OrderContactDto } from '../../../orders/orders-view-page/types/OrderViewPageTypes';
import { SUCCESSFUL_REMOVE } from '../../../core/utils/successMessages';
import { getOrderById } from './viewPageOrderThunks';
import { setOrderContactsPaging } from '../../slices/ordersSlice';
import { RootState } from '../../store';

export const getOrderContactsThunk = createAsyncThunk<ResponseResult<OrderContactDto[], { allItemIds: number[] }>,
{ orderId: number, page: number, pageSize: number }>(
  'get/Order/Contacts',
  async ({
    orderId, page, pageSize,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.fetchOrderContacts(orderId, page, pageSize);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getGenerateEmailOrderContactsThunk = createAsyncThunk<ResponseResult<OrderContactDto[], { allItemIds: number[] }>,
{ orderId: number, page: number, pageSize: number, haveEmail: boolean }>(
  'get/OrderEmail/Contacts',
  async ({
    orderId, page, pageSize, haveEmail,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.fetchOrderContacts(orderId, page, pageSize, haveEmail);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const removeOrderContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ orderId: number, customerContactId: number, closeModal: VoidFunctionType }>(
  'remove/Order/Contact',
  async ({
    orderId, customerContactId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { orders: { orderContactsPaging: { page, pageSize } } } = getState() as RootState;
      const response = await ordersAPI.removeOrderContact(orderId, customerContactId);
      dispatch(getOrderById({ id: orderId }));
      if (page > 1) {
        dispatch(setOrderContactsPaging(infiniteScrollInitPaging));
      } else {
        dispatch(getOrderContactsThunk({ orderId, page, pageSize }));
      }
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_REMOVE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getOrderExistingContactsThunk = createAsyncThunk<GetCustomerContactsResponse,
{ customerId: number, notAddedToOrderId:number, filters: CustomerContactFiltersType }>(
  'get/Order/ExistingContacts',
  async ({
    customerId, notAddedToOrderId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerContacts(customerId, filters, { notAddedToOrderId });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewOrderContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ orderId: number, data: NewContactReqDataType, closeModal: VoidFunctionType }>(
  'post/Order/NewContact',
  async ({
    orderId, data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { orders: { orderContactsPaging: { page, pageSize } } } = getState() as RootState;
      const response = await ordersAPI.createNewOrderContact(orderId, data);
      dispatch(getOrderById({ id: orderId }));
      if (page > 1) {
        dispatch(setOrderContactsPaging(infiniteScrollInitPaging));
      } else {
        dispatch(getOrderContactsThunk({ orderId, page, pageSize }));
      }
      closeModal();
      const { firstName, lastName } = data;
      dispatch(setSuccessMessage({ message: `Contact ${firstName} ${lastName} was successfully created.` }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addOrderExistingContactsThunk = createAsyncThunk<null,
{ orderId: number, customerId: number, customerContactIds: number[], closeModal: VoidFunctionType }>(
  'post/Order/ExistingContacts',
  async ({
    orderId, customerId, customerContactIds, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        orders: {
          orderContactsPaging: { page, pageSize },
          orderExistingContactsPaging,
        },
      } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await customersAPI.fetchCustomerContacts(
        customerId,
        { ...orderExistingContactsPaging, ...emptyPaging },
        { notAddedToOrderId: orderId },
      );
      const filteredIds = customerContactIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await ordersAPI.addExistingOrderContacts(orderId, filteredIds);
      }
      dispatch(getOrderById({ id: orderId }));
      if (page > 1) {
        dispatch(setOrderContactsPaging(infiniteScrollInitPaging));
      } else {
        dispatch(getOrderContactsThunk({ orderId, page, pageSize }));
      }
      closeModal();
      dispatch(setFetching(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
