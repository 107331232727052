import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Input from '../../../../../../core/components/input/Input';
import AddressField from '../../../../../../common/components/address/AddressField';
import AddressModal from '../../../../../../common/components/address/AddressModal';
import { AddressFields, initAddressFields } from '../../../../../../common/types/commonTypes';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { ErrorsEnum } from '../../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../../core/utils/errorMessageHandle';
import { NAME_REGEX, PHONE_FAX_REGEX } from '../../../../../../core/utils/regex';
import {
  defaultICEContactFieldValues,
  EmployeeIceContactResponseDto,
} from '../../../../types/employeeViewTypes';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { getEmployeeIceContactThunk, patchEmployeeThunk } from '../../../../../../store/thunks/employee/employeeViewPageThunk';
import { employeeIceContactSelector } from '../../../../../../store/selectors/employeesSelector';
import { clearOtherFieldsErrorFn } from '../../../../../../core/utils/clearOtherFieldsErrorFn';
import { compareValuesHandle } from '../../../../../../core/utils/getDifferenceBetweenObjects';

const EmployeeIceContact:React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const allowedToEdit = usePermission(PermissionEnum.EmployeeEditFields);

  const employeeIceContact = useAppSelector(employeeIceContactSelector);

  const {
    control, setValue, setError, clearErrors,
  } = useForm({
    defaultValues: defaultICEContactFieldValues,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  useEffect(() => {
    id && dispatch(getEmployeeIceContactThunk({ employeeId: +id }));
    // eslint-disable-next-line
  }, [])

  const clearOtherErrorsHandle = (field: string) => {
    const fieldsToValidate = ['iceContactFullName', 'relationship', 'iceContactTelephone'];
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, employeeIceContact, setValue);
  };

  useEffect(() => {
    clearErrors();
    if (employeeIceContact) {
      setValue('iceContactFullName', employeeIceContact.iceContactFullName);
      setValue('relationship', employeeIceContact.relationship);
      setValue('iceContactTelephone', employeeIceContact.iceContactTelephone);
      setAddressFields({
        addressLine1: employeeIceContact.iceContactAddressLine1,
        addressLine2: employeeIceContact.iceContactAddressLine2,
        cityTown: employeeIceContact.iceContactCityTown,
        region: employeeIceContact.iceContactRegion,
        postalCode: employeeIceContact.iceContactPostalCode,
      });
    }
    // eslint-disable-next-line
  }, [employeeIceContact]);

  const changeDetails = (data: Partial<EmployeeIceContactResponseDto>, key: keyof Partial<EmployeeIceContactResponseDto>) => {
    if (employeeIceContact && id) {
      const handler = () => {
        dispatch(patchEmployeeThunk({
          employeeId: +id,
          data,
          onClose: () => dispatch(getEmployeeIceContactThunk({ employeeId: +id })),
        }));
      };
      compareValuesHandle<Partial<EmployeeIceContactResponseDto>>(data, key, employeeIceContact, handler);
    }
  };
  const changeAddressDetails = (data: Partial<EmployeeIceContactResponseDto>) => {
    if (employeeIceContact && id) {
      const keysNotEqual = Object.keys(data)
        .some((key) => {
          return employeeIceContact[key as keyof EmployeeIceContactResponseDto] !== data[key as keyof EmployeeIceContactResponseDto];
        });
      if (keysNotEqual) {
        dispatch(patchEmployeeThunk({
          employeeId: +id,
          data,
          onClose: () => {
            setIsAddressModal(false);
            dispatch(getEmployeeIceContactThunk({ employeeId: +id }));
          },
        }));
      } else {
        setIsAddressModal(false);
      }
    }
  };

  return (
    <>
      <AddressModal
        title="Address"
        isVisible={isAddressModal}
        onCancel={() => setIsAddressModal(false)}
        addressFields={addressFields}
        onSubmit={(values) => {
          changeAddressDetails({
            iceContactAddressLine1: values.addressLine1?.trim() || '',
            iceContactAddressLine2: values.addressLine2?.trim() || '',
            iceContactCityTown: values.cityTown?.trim() || '',
            iceContactPostalCode: values.postalCode?.trim() || '',
            iceContactRegion: values.region?.trim() || '',
          });
        }}
        isOptionalFields={false}
        isExtendedFields={false}
      />
      <div className="info-grid__column">
        <h2 className="info-grid__title">ICE contact</h2>
        <form className="details-form">
          <Controller
            name="iceContactFullName"
            control={control}
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 200, message: maxLengthMessageHandle(200) },
              pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
            }}
            render={({ field }) => (
              <Input
                label="Full name"
                value={field.value}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                  clearOtherErrorsHandle(field.name);
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!value.trim()) {
                    setError(field.name, { type: 'required', message: ErrorsEnum.REQUIRED });
                    setValue(field.name, '');
                  } else {
                    !errors.iceContactFullName && changeDetails({ iceContactFullName: value.trim() }, field.name);
                  }
                }}
                error={errors.iceContactFullName?.message}
                className="details-form__field--lg"
                disabled={!allowedToEdit}
              />
            )}
          />
          <Controller
            name="relationship"
            control={control}
            rules={{
              required: ErrorsEnum.REQUIRED,
              maxLength: { value: 100, message: maxLengthMessageHandle(100) },
            }}
            render={({ field }) => (
              <Input
                label="Relationship"
                value={field.value}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                  clearOtherErrorsHandle(field.name);
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  if (!value.trim()) {
                    setError(field.name, { type: 'required', message: ErrorsEnum.REQUIRED });
                    setValue(field.name, '');
                  } else {
                    !errors.relationship && changeDetails({ relationship: value.trim() }, field.name);
                  }
                }}
                error={errors.relationship?.message}
                disabled={!allowedToEdit}
              />
            )}
          />
          <Controller
            name="iceContactTelephone"
            control={control}
            rules={{
              pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
              maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
              minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
            }}
            render={({ field }) => (
              <Input
                label="Telephone"
                value={field.value}
                onChange={(e) => {
                  const { value } = e.target;
                  field.onChange(value);
                  clearOtherErrorsHandle(field.name);
                }}
                onBlur={(e) => {
                  const { value } = e.target;
                  !errors.iceContactTelephone && changeDetails({ iceContactTelephone: value.trim() }, field.name);
                }}
                error={errors.iceContactTelephone?.message}
                disabled={!allowedToEdit}
              />
            )}
          />
          <AddressField
            title="Address"
            addressFields={addressFields}
            setAddressModal={() => {
              setIsAddressModal(true);
              clearOtherErrorsHandle('address');
            }}
            isIconVisible={Object.values(addressFields).some((el) => el)}
            disabled={!allowedToEdit}
          />
        </form>
      </div>
    </>
  );
};

export default EmployeeIceContact;
