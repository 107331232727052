import React, { useEffect, useState } from 'react';
import './EmploymentsContent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import EmploymentsList from './EmploymentsList';
import Button from '../../../../../../core/components/button/Button';
import AddEmployment from './AddEmployment';
import { useAppDispatch } from '../../../../../../store/hooks';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  deleteEmployeeEmploymentThunk,
  getEmployeeEmploymentsThunk,
} from '../../../../../../store/thunks/employee/employeeEmploymentsThunks';

const EmploymentsContent: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const allowedToManage = usePermission(PermissionEnum.EmployeeEditFields);
  const [isAdd, setIsAdd] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [lineToChange, setLineToChange] = useState<number | undefined>(undefined);

  useEffect(() => {
    id && dispatch(getEmployeeEmploymentsThunk({ id: +id }));
    // eslint-disable-next-line
  }, [id]);
  return (
    <>
      <AddEmployment
        visible={isAdd}
        onCancel={() => {
          setIsAdd(false);
          setEditMode(false);
          setLineToChange(undefined);
        }}
        editMode={editMode}
        lineToChange={lineToChange}
      />
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setIsDelete(false);
          setLineToChange(undefined);
        }}
        confirmHandler={() => {
          lineToChange && id && dispatch(deleteEmployeeEmploymentThunk({
            employmentId: lineToChange,
            employeeId: +id,
            closeModal: () => {
              setIsDelete(false);
              setLineToChange(undefined);
            },
          }));
        }}
      />
      <div className="employments">
        {allowedToManage && (
          <Button
            label="Add record"
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            className="employments__addButton"
            onClick={() => setIsAdd(true)}
            designType="dark"
            reversed
          />
        )}
        <EmploymentsList
          allowedToManage={allowedToManage}
          deleteLine={(id) => {
            setLineToChange(id);
            setIsDelete(true);
          }}
          editLine={(id) => {
            setLineToChange(id);
            setIsAdd(true);
            setEditMode(true);
          }}
        />
      </div>
    </>
  );
};

export default EmploymentsContent;
