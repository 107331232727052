import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import SalesOfficeEmployeeColumns from './SalesOfficeEmployeeColumns';
import { useAppSelector } from '../../../../../../store/hooks';
import { salesOfficeEmployeeColumnsSelector, salesOfficeEmployeeDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import SalesOfficeEmployeeBody from './SalesOfficeEmployeeBody';
import './SalesOfficeEmployeeTable.scss';

const SalesOfficeEmployeeTable: React.FC = () => {
  const { items, totalCount } = useAppSelector(salesOfficeEmployeeDataSelector);
  const columns = useAppSelector(salesOfficeEmployeeColumnsSelector);

  return (
    <TableNew>
      <SalesOfficeEmployeeColumns columns={columns} totalCount={totalCount} />
      <SalesOfficeEmployeeBody data={items} columnsLength={columns.length} />
    </TableNew>
  );
};

export default SalesOfficeEmployeeTable;
