import React from 'react';
import { EnquiryResponseDto } from '../../../enquiries/types/enquiryTypes';
import Tag from '../../../../core/components/tag/Tag';
import { useAppSelector } from '../../../../store/hooks';
import { customerTypesSelector } from '../../../../store/selectors/coreSelectors';
import { customerTypeDetailsFn } from '../../utils/detailsHandlers';
import { localDateFormatHandler } from '../../../../core/utils/utcDateFormatHandler';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';

type EnquiryHeadProps = {
  enquiry?: EnquiryResponseDto
}

const EnquiryHead: React.FC<EnquiryHeadProps> = ({ enquiry }) => {
  const customerTypes = useAppSelector(customerTypesSelector);

  const entries = [
    { label: 'Enquiry No:', value: enquiry?.enquiry?.enquiryNumber },
    { label: 'Customer:', value: enquiry?.enquiry?.customerShortName },
    { label: 'Customer type:', value: customerTypeDetailsFn(customerTypes, enquiry?.enquiry?.customerType) },
    { label: 'Created date:', value: localDateFormatHandler('DD-MMM-YYYY', enquiry?.enquiry?.createdDate) },
    { label: 'Modified date:', value: localDateFormatHandler('DD-MMM-YYYY', enquiry?.enquiry?.modifiedDate) },
  ];

  return (
    <>
      {entries.map((ent, i) => <div key={i} className="head__inner-info-item">
        <TruncateTooltip value={ent.label} className="head__inner-info-item__label" />
        {ent.label === 'Customer type:'
          ? <Tag label={ent.value?.toString() || 'Customer'} />
          : <TruncateTooltip value={ent.value ? ent.value.toString() : '-'} className="head__inner-info-item__value" />}
      </div>)}
    </>
  );
};

export default EnquiryHead;
