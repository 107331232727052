import React from 'react';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '../../../../../../core/components/button/Button';
import { BooleanFunctionType, initSmallPaging, VoidFunctionType } from '../../../../../../core/types/coreTypes';
import Restricted from '../../../../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { purchaseOrderDetailsSelector } from '../../../../../../store/selectors/purchaseOrderSelectors';
import { purchaseOrderStatusObjSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { setPurchaseOrderPartSearchFilters } from '../../../../../../store/slices/purchaseOrderSlice';

type PartsListFiltersProps = {
  setIsAdd: BooleanFunctionType,
  checkedKeys: number[],
  setIsDelete: VoidFunctionType,
}

const PartsListFilters: React.FC<PartsListFiltersProps> = ({ setIsAdd, checkedKeys, setIsDelete }) => {
  const purchaseOrderDetails = useAppSelector(purchaseOrderDetailsSelector);
  const { completed } = useAppSelector(purchaseOrderStatusObjSelector);
  const dispatch = useAppDispatch();

  const forbiddenToCreate = !purchaseOrderDetails?.areLinesCreatable;
  const forbiddenToDelete = purchaseOrderDetails?.purchaseOrder.status === completed;
  return (
    <section className="actions-and-paging">
      <div className="actions-and-paging__actions">
        <Restricted to={PermissionEnum.StockPurchaseOrdersEditPartLineFieldsAndUnlockPartLineActions}>
          <>
            <Button
              label="Add part"
              icon={<FontAwesomeIcon icon={faPlusCircle} />}
              htmlType="button"
              onClick={() => {
                dispatch(setPurchaseOrderPartSearchFilters({ ...initSmallPaging, isCorePart: [true] }));
                setIsAdd(true);
              }}
              disabled={forbiddenToCreate}
              designType="dark"
              reversed
            />
            <Button
              label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
              htmlType="button"
              onClick={setIsDelete}
              disabled={checkedKeys.length === 0 || forbiddenToDelete}
            />
          </>
        </Restricted>
      </div>
    </section>
  );
};

export default PartsListFilters;
