import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PartsKitDuplicate.scss';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import { duplicatePartsKitById } from '../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import Modal from '../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import Input from '../../../../../core/components/input/Input';
import {
  DuplicatePartsKitDefaults,
  DuplicatePartsKitFields,
  PartsKitCodeSchema,
} from '../../utils/PartsKitDetailsSchema';

type DuplicatePartsKitModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  id?: number,
}

const DuplicatePartsKitModal: React.FC<DuplicatePartsKitModalProps> = ({ onCancel, isVisible, id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const {
    control, handleSubmit, setError, setValue, reset,
  } = useForm<DuplicatePartsKitFields>({
    defaultValues: DuplicatePartsKitDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const cancelHandle = () => {
    reset();
    onCancel();
  };

  const duplicateHandle = (values: DuplicatePartsKitFields) => {
    id && dispatch(duplicatePartsKitById({
      id,
      code: values.code.trim(),
      navigate,
      closeModal: () => cancelHandle(),
      setError,
    }));
  };

  return (
    <Modal title="Duplicate" visible={isVisible} onCancel={cancelHandle} destroyOnClose>
      <form onSubmit={handleSubmit(duplicateHandle)} className="partsKitDuplicate">
        <div className="partsKitDuplicate__text">
          <span>You are about to create a duplicate of displayed parts kit.</span>
          <span>
            Please enter a unique
            {' '}
            <b>parts kit code</b>
            {' '}
            for a new parts kit:
          </span>
          <Controller
            control={control}
            name="code"
            rules={PartsKitCodeSchema}
            render={({ field }) => (
              <Input
                value={field.value}
                onChange={(e) => {
                  field.onChange(e.target.value.toUpperCase());
                }}
                onBlur={(e) => {
                  const v = e.target.value;
                  if (!v.trim()) {
                    setValue('code', '');
                    setError('code', { type: 'required', message: ErrorsEnum.REQUIRED });
                  }
                }}
                error={errors?.code?.message}
              />
            )}
          />
          <span>Do you want to proceed?</span>
        </div>
        <ButtonActions
          createLabel="Yes, duplicate"
          cancelLabel="Cancel"
          createType="submit"
          cancelClick={cancelHandle}
        />
      </form>
    </Modal>
  );
};

export default DuplicatePartsKitModal;
