import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { PartSubcategoryDto } from '../types/partCategoriesTypes';
import Tr from '../../../core/components/table-components/Tr';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPartSubcategoryAffectedThunk } from '../../../store/thunks/stock/categories/partCategoriesThunks';
import { stockPartAffectedCategoriesFiltersSelector } from '../../../store/selectors/stockSelectors';

type PartSubcategoryCellProps = {
  subcategory: PartSubcategoryDto,
  openEditSubcategoryModal: NumberFunctionType,
  setIsDeleteModal: (params: { categoryId?: number, subcategoryId?: number }) => void,
  setIsAffectedModal: (params: { categoryId?: number, subcategoryId?: number }) => void
}
const PartSubcategoryCell: React.FC<PartSubcategoryCellProps> = ({
  subcategory,
  openEditSubcategoryModal,
  setIsDeleteModal,
  setIsAffectedModal,
}) => {
  const dispatch = useAppDispatch();
  const affectedFilters = useAppSelector(stockPartAffectedCategoriesFiltersSelector);

  const subcategoryData = {
    createdDate: localDateFormatHandler('DD/MM/YYYY HH:mm', subcategory.createdAt || undefined),
    modifiedDate: localDateFormatHandler('DD/MM/YYYY HH:mm', subcategory.updatedAt || undefined),
  };

  const handleDeleteSubcategoryClick = () => {
    dispatch(getPartSubcategoryAffectedThunk({
      subcategoryId: subcategory.id,
      filters: affectedFilters,
      confirmAction: () => setIsDeleteModal({ subcategoryId: subcategory.id }),
      complexAction: () => setIsAffectedModal({ subcategoryId: subcategory.id }),
    }));
  };

  const subcategoryActions = [
    {
      label: 'Edit subcategory',
      key: 'edit-subcategory',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: () => openEditSubcategoryModal(subcategory.id),
    },
    {
      label: 'Delete subcategory',
      key: 'delete-subcategory',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: handleDeleteSubcategoryClick,
    },
  ];

  return (
    <Tr>
      <TdNew>
        <TruncateTooltip value={subcategory.name} table />
      </TdNew>
      <TdNew action>
        <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={subcategoryActions}
          overlayClassName="partSubcategoryCell__action-overlay"
        />
      </TdNew>
      {Object.entries(subcategoryData).map(([key, value]) => (
        <TdNew key={key}>
          {value}
        </TdNew>
      ))}
      <TdNew />
    </Tr>
  );
};

export default PartSubcategoryCell;
