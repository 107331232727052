import React, { useEffect } from 'react';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './PartsKitComponents.scss';
import { useParams } from 'react-router-dom';
import Button from '../../../../../../core/components/button/Button';
import {
  partsKitAffectedItemsFiltersSelector,
  partsKitComponentsFiltersSelector,
  partsKitComponentsUiSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import AddComponentModal from './add-component/AddComponentModal';
import {
  getPartsKitAffectedThunk,
  getPartsKitComponents,
  getPartsKitComponentsSummary,
  getPartsKitComponentTax,
} from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import {
  setPartsKitComponents,
  setPartsKitComponentsFilters,
  setPartsKitComponentsSummary,
} from '../../../../../../store/slices/partsKitSlice';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import PartsKitComponentsContent from './PartsKitComponentsContent';
import EmptyComponentsList from './EmptyComponentsList';
import { AddComponentModeEnum } from '../../../enums/PartsKitsViewPageEnums';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import { usePartsKitAffected } from '../../../hooks/usePartsKitAffected';
import AddComponentsWithoutPriceModal from './add-component/AddComponentsWithoutPriceModal';
import Restricted from '../../../../../../permissions-handling/Restricted';
import { getSuitableVehicleProfiles } from '../../../../../../store/thunks/shared/sharedThunks';
import { vehicleProfilesModelDescrOrderSelector } from '../../../../../../store/selectors/coreOrderingSelectors';
import { setSuitableVehicleProfiles } from '../../../../../../store/slices/sharedSlice';
import { initVehProfilesList } from '../../../../../../common/types/commonTypes';
import { getStockPartsOrdering } from '../../../../../../store/thunks/core/coreOrderingThunks';

const PartsKitComponents: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const allowedChange = usePermission(PermissionEnum.StockPartsKitEditComponentFieldsAndUnlockComponentActions);
  const partsKitComponents = useAppSelector(partsKitComponentsUiSelector);
  const partsKitComponentsFilters = useAppSelector(partsKitComponentsFiltersSelector);
  const affectedItemsFilters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const vehModelDescrOrder = useAppSelector(vehicleProfilesModelDescrOrderSelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
    checkedAddKeys,
    setCheckedAddKeys,
    isDelete,
    setIsDelete,
    isAdd,
    setIsAdd,
    isAddWithoutPrice,
    setIsAddWithoutPrice,
    currentMode,
    setCurrentMode,
    itemToChange,
    setItemToChange,
    confirmDelete,
    onCancelAddModal,
  } = usePartsKitAffected();

  useEffect(() => {
    dispatch(getPartsKitComponentTax());
    dispatch(getStockPartsOrdering());
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    vehModelDescrOrder && dispatch(getSuitableVehicleProfiles({
      filters: { order: { field: vehModelDescrOrder, isAsc: true } },
    }));
    // eslint-disable-next-line
  }, [vehModelDescrOrder]);

  useEffect(() => {
    if (id) {
      dispatch(getPartsKitComponents({ id: +id, filters: partsKitComponentsFilters }));
    }
    // eslint-disable-next-line
  }, [id, partsKitComponentsFilters]);

  useEffect(() => {
    id && dispatch(getPartsKitComponentsSummary({ id: +id }));
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    return () => {
      dispatch(setPartsKitComponentsFilters(null));
      dispatch(setPartsKitComponents({ components: [], allItemIds: [] }));
      dispatch(setPartsKitComponentsSummary(null));
      dispatch(setSuitableVehicleProfiles(initVehProfilesList));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      {id && <AddComponentsWithoutPriceModal
        isVisible={isAddWithoutPrice}
        onCancel={() => setIsAddWithoutPrice(false)}
        onConfirm={() => setIsAdd(false)}
        checkedAddKeys={checkedAddKeys}
        partsKitId={+id}
      />}
      {id && <AddComponentModal
        isVisible={isAdd}
        onCancel={onCancelAddModal}
        partsKitId={+id}
        currentMode={currentMode}
        setCurrentMode={setCurrentMode}
        itemToChange={itemToChange}
        setCheckedAddKeys={setCheckedAddKeys}
        setIsAddWithoutPrice={setIsAddWithoutPrice}
      />}
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setIsDelete(false);
          setItemToChange(undefined);
        }}
        confirmHandler={confirmDelete}
      />
      <div className="componentsWrap">
        <section className="actions-and-paging">
          <Restricted to={PermissionEnum.StockPartsKitEditComponentFieldsAndUnlockComponentActions}>
            <div className="actions-and-paging__actions">
              <Button
                label="Add component"
                icon={<FontAwesomeIcon icon={faCirclePlus} />}
                onClick={() => {
                  setIsAdd(true);
                }}
                designType="dark"
                reversed
              />
              {!!partsKitComponents?.length && (
                <Button
                  label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
                  onClick={() => id && dispatch(getPartsKitAffectedThunk({
                    id: +id,
                    filters: affectedItemsFilters,
                    confirmAction: () => setIsDelete(true),
                  }))}
                  disabled={checkedKeys.length === 0 || !allowedChange}
                  reversed
                />
              )}
            </div>
          </Restricted>
        </section>
        {partsKitComponents?.length === 0 && !partsKitComponentsFilters
          ? <EmptyComponentsList />
          : <PartsKitComponentsContent
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            checkedKeys={checkedKeys}
            checkAll={checkAllHandle}
            checkRow={checkRowHandle}
            setIsDelete={(id) => {
              setIsDelete(true);
              setItemToChange(id);
            }}
            openCustomEdit={(id: number) => {
              setItemToChange(id);
              setCurrentMode(AddComponentModeEnum.CUSTOM);
              setIsAdd(true);
            }}
          />}
      </div>
    </>
  );
};

export default PartsKitComponents;
