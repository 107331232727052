import React from 'react';
import { PurchaseOrderEventDtoBase } from '../../../types/purchaseOrderViewPageTypes';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import { useAppSelector } from '../../../../../../store/hooks';
import BillingShippingEvents
  from '../../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import InvoicesAndCreditNotesEvents
  from '../../../../../../common/components/activity-events/invoices-and-credit-notes/InvoicesAndCreditNotesEvents';
import { PurchaseOrderEventTypesEnum } from '../../../enums/PurchaseOrderViewEnum';
import PurchaseOrderNoteEvent from './purchase-order-events/PurchaseOrderNoteEvent';
import PurchaseOrderFieldUpdateEvent from './purchase-order-events/PurchaseOrderFieldUpdateEvent';
import { purchaseOrderStatusSelector } from '../../../../../../store/selectors/purchaseOrderSelectors';
import PurchaseOrderPartEvents from './purchase-order-events/PurchaseOrderPartEvents';
import { purchaseOrderEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import DeletionEvent from '../../../../../../common/components/activity-events/deletion/DeletionEvent';
import { invoiceDocumentTypesSelector } from '../../../../../../store/selectors/coreSelectors';
import NominalRecordEvents from '../../../../../../invoicing/common/components/activity-log-common/NominalRecordEvents';

type PurchaseOrderEventProps = {
  item: PurchaseOrderEventDtoBase,
}

const PurchaseOrderEvent: React.FC<PurchaseOrderEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(purchaseOrderEventTypesSelector);
  const statuses = useAppSelector(purchaseOrderStatusSelector);
  const invoiceDocumentTypes = useAppSelector(invoiceDocumentTypesSelector);

  const attachmentEvents = [
    PurchaseOrderEventTypesEnum.AddingAttachmentWithoutComment,
    PurchaseOrderEventTypesEnum.AddingAttachmentWithComment,
    PurchaseOrderEventTypesEnum.EditingAttachmentComment,
    PurchaseOrderEventTypesEnum.DeletingAttachment,
  ];
  const billingShippingEvents = [PurchaseOrderEventTypesEnum.BillingDetailsUpdates];

  const partEvents = [
    PurchaseOrderEventTypesEnum.PurchaseOrderPartCreation,
    PurchaseOrderEventTypesEnum.PurchaseOrderPartDeletion,
    PurchaseOrderEventTypesEnum.PurchaseOrderPartFieldUpdateOrAddingValue,
    PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityCreation,
    PurchaseOrderEventTypesEnum.PurchaseOrderPartFailedQuantityDeletion,
  ];

  const invoicesAndCreditNotesEvents = [
    PurchaseOrderEventTypesEnum.InvoiceDocumentCreation,
    PurchaseOrderEventTypesEnum.InvoiceDocumentFieldUpdate,
    PurchaseOrderEventTypesEnum.InvoiceDocumentDeletion,
  ];

  const nominalRecordsEvents = [
    PurchaseOrderEventTypesEnum.NominalRecordCreation,
    PurchaseOrderEventTypesEnum.NominalRecordFieldUpdate,
    PurchaseOrderEventTypesEnum.NominalRecordDeletion,
  ];

  const eventRenderFn = (item: PurchaseOrderEventDtoBase) => {
    const event = eventTypes.find((type) => type.value === item.purchaseOrderEventType)?.label || '';
    switch (true) {
      case event === PurchaseOrderEventTypesEnum.Note:
        return <PurchaseOrderNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === PurchaseOrderEventTypesEnum.PurchaseOrderCreation:
        return <CreationEvent entity="Purchase order" type="number" value={item.purchaseOrderNumber} />;
      case event === PurchaseOrderEventTypesEnum.PurchaseOrderDelete:
        return <DeletionEvent entity="Purchase order" />;
      case event === PurchaseOrderEventTypesEnum.PurchaseOrderFieldUpdateOrAddingValue:
        return <PurchaseOrderFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === PurchaseOrderEventTypesEnum.PurchaseOrderStatusChange: {
        const prevStatus = statuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = statuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case (billingShippingEvents.includes(event as PurchaseOrderEventTypesEnum)):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (partEvents.includes(event as PurchaseOrderEventTypesEnum)):
        return <PurchaseOrderPartEvents eventName={event} item={item} />;
      case (attachmentEvents.includes(event as PurchaseOrderEventTypesEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (invoicesAndCreditNotesEvents.includes(event as PurchaseOrderEventTypesEnum)): {
        const entityType = invoiceDocumentTypes.find((types) => types.value === item.invoiceDocumentType)?.label || '';
        return <InvoicesAndCreditNotesEvents
          eventName={event}
          entityType={entityType}
          entityNumber={item.invoiceDocumentEntityNumber || ''}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      }
      case (nominalRecordsEvents.includes(event as PurchaseOrderEventTypesEnum)): {
        return <NominalRecordEvents
          eventName={event}
          lineNumber={item.lineNumber || ''}
          productName={item.productName || ''}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      }
      default: return null;
    }
  };

  return <>{eventRenderFn(item)}</>;
};

export default PurchaseOrderEvent;
