import React, { useEffect, useMemo, useState } from 'react';
import { orderBy } from 'lodash';
import {
  faCirclePlus, faEllipsisVertical, faTools, faTrashAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../../core/components/modal/Modal';
import { OrderingType, VoidFunctionType } from '../../../core/types/coreTypes';
import Button from '../../../core/components/button/Button';
import Thead from '../../../core/components/table-components/Thead';
import Tbody from '../../../core/components/table-components/Tbody';
import Tr from '../../../core/components/table-components/Tr';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import './SkillsModal.scss';
import Select from '../../../core/components/select/Select';
import {
  employeeSkillsDictionarySelector,
  employeeSkillsUiDictionarySelector,
  employeesSkillLevelsSelector,
} from '../../../store/selectors/employeesSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getEmployeeSkillsDictionaryThunk } from '../../../store/thunks/employee/createEmployeeThunks';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { DraftSkillLine } from '../../types/commonTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import TruncateDropdownLabel from '../truncate-dropdown-label/TruncateDropdownLabel';
import { skillsModalColumns } from '../../../employees/skill-list/utils/dataUtil';
import { useSelectAll } from '../../../core/hooks/useSelectAll';
import { EmployeeSkillsModalOrdering } from '../../../employees/skill-list/enums/skillsEnum';

type SkillsModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  setSkills: (v: DraftSkillLine[]) => void,
  skills: DraftSkillLine[],
  saveSkills?: (list: DraftSkillLine[]) => void,
}

const SkillsModal: React.FC<SkillsModalProps> = ({
  visible, onCancel, skills, setSkills, saveSkills,
}) => {
  const dispatch = useAppDispatch();
  const skillLevels = useAppSelector(employeesSkillLevelsSelector);
  const initDictionary = useAppSelector(employeeSkillsDictionarySelector);
  const employeeSkillsDictionary = useAppSelector(employeeSkillsUiDictionarySelector);
  const [keyword, setKeyword] = useState<string>('');
  const [skillValue, setSkillValue] = useState<number | undefined>(undefined);
  const [levelValue, setLevelValue] = useState<number | undefined>(undefined);
  const [draftList, setDraftList] = useState<DraftSkillLine[]>([]);
  const [ordering, setOrdering] = useState<OrderingType>({ field: undefined, isAsc: true });

  const allAvailableIds = useMemo(() => draftList.map(({ id }) => id), [draftList]);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allAvailableIds);

  const skillsOptions = useMemo(() => {
    return employeeSkillsDictionary.filter((skill) => !draftList.find((d) => d.id === skill.value));
  }, [draftList, employeeSkillsDictionary]);

  const skillsUi = useMemo(() => skillsOptions.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label || ''} />,
  })), [skillsOptions]);

  const [skillsSelectLoading, setSkillsSelectLoading] = useState(false);

  const fetchSkillsItems = (keyword?: string) => {
    dispatch(getEmployeeSkillsDictionaryThunk({ keyword, setSkillsSelectLoading }));
  };
  useEffect(() => {
    visible && fetchSkillsItems();
    // eslint-disable-next-line
  }, [visible]);

  useEffect(() => {
    if (skills.length > 0 && visible) {
      setDraftList(skills);
    }
    // eslint-disable-next-line
  }, [skills.length, visible]);

  const sortHandle = (field: number) => {
    const fieldName = field === EmployeeSkillsModalOrdering.Category ? 'categoryName' : 'name';
    let newOrdering = { ...ordering };
    if (ordering.field === field) {
      newOrdering = { ...newOrdering, isAsc: !ordering.isAsc };
    } else {
      newOrdering = { field, isAsc: true };
    }
    setOrdering(newOrdering);
    const copy = orderBy(draftList, fieldName, newOrdering.isAsc ? 'asc' : 'desc');
    const indexedList = copy.map((sk, i) => ({
      id: sk.id,
      number: i + 1,
      categoryName: sk.categoryName,
      name: sk.name,
      level: sk.level,
    }));
    setDraftList(indexedList);
  };
  const cleanUpData = () => {
    setLevelValue(undefined);
    setSkillValue(undefined);
  };
  const cancelHandle = () => {
    onCancel();
    cleanUpData();
    setDraftList([]);
    setOrdering({ field: undefined, isAsc: true });
  };
  const addHandle = () => {
    if (skillValue && levelValue) {
      const currentSkill = initDictionary.find((el) => el.id === skillValue);
      currentSkill && setDraftList([
        ...draftList,
        {
          id: skillValue,
          number: draftList.length > 0 ? (draftList.length + 1) : 1,
          categoryName: currentSkill.categoryName,
          name: currentSkill.name,
          level: levelValue,
        },
      ]);
      cleanUpData();
    }
  };

  const setNewLevelHandle = (v: number, id: number) => {
    const newList = draftList.map((el) => {
      if (el.id === id) {
        return {
          ...el,
          level: v,
        };
      } else {
        return el;
      }
    });
    setDraftList(newList);
  };

  const deleteSkillLineHandle = (id: number) => {
    const newList = draftList.filter((sk) => sk.id !== id);
    const indexedList = newList.map((sk, i) => ({
      id: sk.id,
      number: i + 1,
      categoryName: sk.categoryName,
      name: sk.name,
      level: sk.level,
    }));
    setDraftList(indexedList);
  };
  const deleteSelected = () => {
    const newList = draftList.filter((el) => !checkedKeys.includes(el.id));
    if (newList.length > 0) {
      const indexedList = newList.map((sk, i) => ({
        id: sk.id,
        number: i + 1,
        categoryName: sk.categoryName,
        name: sk.name,
        level: sk.level,
      }));
      setDraftList(indexedList);
    } else {
      setDraftList([]);
    }
  };

  const saveHandle = () => {
    setSkills(draftList);
    setDraftList([]);
    cancelHandle();
    if (saveSkills) {
      saveSkills(draftList);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keyword) {
      const timeOutId = setTimeout(() => {
        fetchSkillsItems(keyword);
      }, 1000);
      return () => clearTimeout(timeOutId);
    }
    // eslint-disable-next-line
  }, [keyword]);

  const renderCellValue = (key: string, value: string | number | null, id: number) => {
    switch (key) {
      case 'level':
        return (
          <Select
            value={value as number}
            options={skillLevels}
            onChange={(v) => setNewLevelHandle(v as number, id)}
            parentRender
            className="skillsModal__level-edit"
          />
        );
      case 'name':
      case 'categoryName':
        return <TruncateTooltip value={value} table />;
      default:
        return value;
    }
  };
  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title="Select skills and levels"
      destroyOnClose
      width={1000}
    >
      <Button
        label={`Delete selected ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
        onClick={deleteSelected}
        htmlType="button"
        disabled={checkedKeys.length === 0}
      />
      <TableNew className="skillsModal" hasFooter>
        <Thead>
          <Tr>
            <ThNew checkbox>
              <Checkbox disabled={!draftList.length} checked={isAll} indeterminate={isIndeterminate} onChange={checkAllHandle} table />
            </ThNew>
            {skillsModalColumns.map((col, i) => <ThWithControls
              key={i}
              col={col}
              sortHandle={sortHandle}
              totalElements={draftList.length}
              tableFilters={{ order: { ...ordering } }}
              className={`skillsModalTh--${col.dataIndex}`}
            />)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Tr>
        </Thead>
        <Tbody>
          {draftList.length > 0
            ? draftList.map((d, i) => <Tr key={i}>
              <>
                <TdNew>
                  <Checkbox checked={checkedKeys.includes(d.id)} onChange={() => checkRowHandle(d.id)} table />
                </TdNew>
                {Object.entries(d).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key}>
                    {renderCellValue(key, value, d.id)}
                  </TdNew>;
                })}
                <TdNew action>
                  <Dropdown
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    menuItems={[
                      {
                        key: 'deleteSkillLine',
                        icon: <FontAwesomeIcon icon={faTrashAlt} />,
                        label: 'Delete skill item',
                        onClick: () => deleteSkillLineHandle(d.id),
                      },
                    ]}
                    parentRender
                  />
                </TdNew>
              </>
            </Tr>)
            : <EmptyTableSection icon={faTools} text="No skills" colSpan={6} />}
        </Tbody>
      </TableNew>
      <div className="skillsModal__footer">
        <Select
          placeholder="Select skill item"
          value={skillValue}
          options={skillsUi}
          onChange={(v) => setSkillValue(v as number)}
          parentRender
          showSearch
          onSearch={(val) => {
            if (!val.trim()) {
              fetchSkillsItems(undefined);
            }
            if (val.length <= 250) {
              setKeyword(val.trim());
            } else {
              dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
            }
          }}
          onSelect={() => {
            keyword && setKeyword('');
            fetchSkillsItems(undefined);
          }}
          loading={skillsSelectLoading}
          className="skillsModal__skill-select"
        />
        <Select
          placeholder="Select level"
          value={levelValue}
          options={skillLevels}
          onChange={(v) => setLevelValue(v as number)}
          parentRender
          className="skillsModal__level-select"
        />
        <Button
          label="Add skill"
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          onClick={addHandle}
          disabled={!skillValue || !levelValue}
          className="skillsModal__add-skill-btn"
          designType="dark"
          reversed
        />
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        createLabel="Save"
        cancelClick={cancelHandle}
        createClick={saveHandle}
      />
    </Modal>
  );
};

export default SkillsModal;
