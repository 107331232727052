import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Tr from '../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import Thead from '../../../../core/components/table-components/Thead';
import { setPurchasesViewOrderLinesFilters } from '../../../../store/slices/invoicingSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  purchasesViewOrderLinesColumnsSelector,
  purchasesViewOrderLinesFiltersSelector, purchasesViewOrderLinesSelector,
} from '../../../../store/selectors/invoicingSelectors';
import { SearchTableFilterType } from '../../../../core/types/coreTypes';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';

const InvoicingOrderLinesColumns: React.FC = () => {
  const dispatch = useAppDispatch();

  const columns = useAppSelector(purchasesViewOrderLinesColumnsSelector);
  const filters = useAppSelector(purchasesViewOrderLinesFiltersSelector);
  const data = useAppSelector(purchasesViewOrderLinesSelector);
  const totalCount = data?.purchaseOrderParts?.length || 0;

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: filters?.productNumber,
    productName: filters?.productName,
  });

  const sortHandle = (field: number) => {
    const newFilters = {
      ...filters,
      order: filters?.order?.field === field
        ? { ...filters.order, isAsc: !filters.order?.isAsc }
        : { field, isAsc: true },
    };
    dispatch(setPurchasesViewOrderLinesFilters(newFilters));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setPurchasesViewOrderLinesFilters({
        ...filters,
        [field]: '',
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setPurchasesViewOrderLinesFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const filtersList = [
    {
      dataIndex: 'nominalCodes',
      value: filters?.nominalCodes || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const newValues = {
          ...filters,
          nominalCodes: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        };
        dispatch(setPurchasesViewOrderLinesFilters(newValues));
      },
    },
  ];

  return <Thead>
    <Tr>
      {columns.map((col) => (
        <ThWithControls
          key={col.dataIndex}
          col={col}
          totalElements={totalCount}
          sortHandle={sortHandle}
          onChangeSearchHandle={onChangeSearchHandle}
          searchPressHandle={searchPressHandle}
          disabled={totalCount === 0}
          tableFilters={filters}
          filters={filtersList}
          disabledFilter={totalCount === 0}
          className={`invoicingOrderLinesTh--${col.dataIndex}`}
        />
      ))}
      <ThNew action>
        <span className="sr-only">Actions</span>
      </ThNew>
    </Tr>
  </Thead>;
};

export default InvoicingOrderLinesColumns;
