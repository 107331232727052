import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import { TruncateTooltip } from '../../truncate-tooltip/TruncateTooltip';
import { summaryFieldValueHandle } from '../../../../core/utils/summaryFieldValueHandle';
import './ContactEventModal.scss';
import { ContactEvent } from '../../../types/commonTypes';

type ContactEventModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  contact?: ContactEvent,
}

const ContactEventModal: React.FC<ContactEventModalProps> = ({ isVisible, onCancel, contact }) => {
  const locationFields = {
    addressLine1: contact?.addressLine1,
    addressLine2: contact?.addressLine2,
    cityTown: contact?.cityTown,
    region: contact?.region,
    postalCode: contact?.postalCode,
  };
  const location = summaryFieldValueHandle(Object.values(locationFields), ' ');
  const commonInfoFields = [
    { label: 'Company name', value: contact?.customerName || contact?.companyName || '', disabled: true },
    { label: 'First name', value: contact?.firstName || '' },
    { label: 'Last name', value: contact?.lastName || '' },
    { label: 'Position', value: contact?.position || '' },
    { label: 'Email', value: contact?.email || '' },
    { label: 'Telephone', value: contact?.telephone || '' },
    { label: 'Mobile', value: contact?.mobile || '' },
  ];
  return (
    <Modal visible={isVisible} width={600} onCancel={onCancel} title="Contact details" className="contactEventModal">
      <div className="details-form">
        {commonInfoFields.map((el) => (
          <div
            key={el.label}
            className={classNames('contactEventModal__field', 'details-form__field', { 'details-form__field--lg': el.disabled })}
          >
            <span className="contactEventModal__label">{el.label}</span>
            <TruncateTooltip
              value={el.value}
              className={classNames('contactEventModal__value', { 'contactEventModal__value--disabled': el.disabled })}
              overlayClassName="contactEventModal__tooltip"
            />
          </div>
        ))}
        <div className="location-field details-form__field--lg">
          <span className="location-field__label">Address</span>
          <div className="location-field__input">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="location-field__icon" />
            <TruncateTooltip value={location} className="contactEventModal__job-location" overlayClassName="contactEventModal__tooltip" />
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ContactEventModal;
