import React from 'react';
import JobDetailsSection from './JobDetailsSection';
import JobVehiclesSection from './JobVehiclesSection';

const JobDetailsAndVehicles: React.FC = () => {
  return (
    <div className="jobDetailsAndVehicles">
      <JobVehiclesSection />
      <JobDetailsSection />
    </div>
  );
};

export default JobDetailsAndVehicles;
