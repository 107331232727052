import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse,
  ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { settingsAPI } from '../../../api/settingsApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { CreateUserAccountRequest, CreateUserFormFields } from '../../../settings/create-user/utils/UserShema';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';

export const createUserThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }> | null,
{
  data: CreateUserAccountRequest,
  setError: UseFormSetError<CreateUserFormFields>,
  navigate: NavigateFunction,
}
>(
  'create/User',
  async ({
    data, setError, navigate,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.createUser(data);
      dispatch(setLoading(false));
      navigate(`/${routesPath.USER_PROFILE}/${res.data.data.createdId}`);
      const message = `An invitation was sent to user "${data.firstName} ${data.lastName}"`;
      dispatch(setSuccessMessage({ message }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      const duplicate = errors.find((el) => el.key === 'Email');
      if (duplicate) {
        setError('email', { type: 'duplicate', message: ErrorsEnum.UNIQUE_VALUE });
      }
      return rejectWithValue(error.response?.data);
    }
  },
);
