import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  BooleanFunctionType,
  ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  AddInfoMessageType,
  IInfoMessage,
  InfoMessageMaxDisplayOrderDto,
  InfoMessagesFiltersType,
} from '../../../settings/info-messages/types/infoMessagesTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { settingsAPI } from '../../../api/settingsApi';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';

export const getInfoMessageMaxDisplayOrder = createAsyncThunk<ResponseSingleResult<InfoMessageMaxDisplayOrderDto> | null>(
  'get/infoMessageMaxDisplayOrder',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const res = await settingsAPI.getInfoMessageMaxDisplayOrder();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getInfoMessagesList = createAsyncThunk<ResponseResult<IInfoMessage[]> | null,
{ filters: InfoMessagesFiltersType }
>(
  'get/infoMessagesList',
  async ({ filters }, { rejectWithValue }) => {
    try {
      const res = await settingsAPI.fetchInfoMessages(filters);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addInfoMessageThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }> | null,
{ data: AddInfoMessageType, onClose: VoidFunctionType, updateState: VoidFunctionType }
>(
  'add/infoMessage',
  async ({
    data, onClose, updateState,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.addInfoMessage(data);
      dispatch(setLoading(false));
      onClose();
      updateState();
      const message = `Info message "${data.title}" was successfully created.`;
      dispatch(setSuccessMessage({ message }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteInfoMessageThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, updateState: BooleanFunctionType, onClose: VoidFunctionType }
>(
  'delete/infoMessage',
  async ({
    id, updateState, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.deleteInfoMessage(id);
      dispatch(setLoading(false));
      onClose();
      updateState(true);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const publishInfoMessageThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, updateState: VoidFunctionType }
>(
  'publish/infoMessage',
  async ({ id, updateState }, { rejectWithValue }) => {
    try {
      const res = await settingsAPI.publishInfoMessage(id);
      updateState();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const unpublishInfoMessageThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, updateState: VoidFunctionType }
>(
  'unpublish/infoMessage',
  async ({ id, updateState }, { rejectWithValue }) => {
    try {
      const res = await settingsAPI.unpublishInfoMessage(id);
      updateState();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editInfoMessageThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, data: AddInfoMessageType, onClose: VoidFunctionType, updateState: VoidFunctionType }
>(
  'edit/infoMessage',
  async ({
    id, data, onClose, updateState,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await settingsAPI.editInfoMessage(id, data);
      dispatch(setLoading(false));
      onClose();
      updateState();
      const message = `Info message "${data.title}" was successfully edited.`;
      dispatch(setSuccessMessage({ message }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
