import React, { useMemo } from 'react';
import Tr from '../../../../../../core/components/table-components/Tr';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import { getNo } from '../../../../../../core/utils/entityNumberingHandle';
import { useAppSelector } from '../../../../../../store/hooks';
import { addPartFiltersSelector } from '../../../../../../store/selectors/pricelistSelectors';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { PartDtoType } from '../../../../../../stock/search/types/stockSearchTypes';

type AddPartCellsProps = {
  stockParts: PartDtoType[],
  checkedKeys: number[],
  checkRow: NumberFunctionType,
}

const AddPartCells: React.FC<AddPartCellsProps> = ({
  stockParts, checkedKeys, checkRow,
}) => {
  const addPartFilters = useAppSelector(addPartFiltersSelector);
  const remasteredItems = useMemo(() => stockParts.map((el, i) => ({
    id: el.id,
    number: getNo(addPartFilters.page, addPartFilters.pageSize, i),
    partCode: el.partCode || '-',
    manufacturerCode: el.manufacturerCode,
    name: el.name,
    description: el.description,
  })), [addPartFilters.page, addPartFilters.pageSize, stockParts]);

  return (
    <>
      {remasteredItems.map((el, i) => <Tr key={i}>
        <TdNew>
          <Checkbox
            checked={checkedKeys.includes(el.id)}
            onChange={() => checkRow(el.id)}
            table
          />
        </TdNew>
        <>
          {Object.entries(el).map(([key, value]) => {
            if (key === 'id') return null;
            return <TdNew key={key}>
              <TruncateTooltip
                value={value}
                table
              />
            </TdNew>;
          })}
        </>
      </Tr>)}
    </>
  );
};

export default AddPartCells;
