import React, { useState } from 'react';
import moment from 'moment';
import { DateRangeTableFilterType, NumberFunctionType, PickerValues } from '../../../core/types/coreTypes';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import {
  vehicleProfilesColumnsSelector,
  vehicleProfilesTableViewSelector,
  vehiclesProfilesFiltersSelector,
} from '../../../store/selectors/vehiclesSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setVehicleProfilesFilters } from '../../../store/slices/vehiclesSlice';
import VehiclesProfilesBody from './VehiclesProfilesBody';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';

type VehicleProfilesListProps = {
  openEditModal: NumberFunctionType,
  openDeleteModal: NumberFunctionType,
}

const VehicleProfilesList: React.FC<VehicleProfilesListProps> = ({ openEditModal, openDeleteModal }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(vehiclesProfilesFiltersSelector);
  const { totalCount } = useAppSelector(vehicleProfilesTableViewSelector);
  const columns = useAppSelector(vehicleProfilesColumnsSelector);
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    createdDate: [
      filters.createdDate?.min ? moment(filters.createdDate.min, DEFAULT_DATE_FORMAT) : null,
      filters.createdDate?.max ? moment(filters.createdDate.max, DEFAULT_DATE_FORMAT) : null,
    ],
    modifiedDate: [
      filters.modifiedDate?.min ? moment(filters.modifiedDate.min, DEFAULT_DATE_FORMAT) : null,
      filters.modifiedDate?.max ? moment(filters.modifiedDate.max, DEFAULT_DATE_FORMAT) : null,
    ],
  });
  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      dispatch(setVehicleProfilesFilters({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      }));
    } else {
      dispatch(setVehicleProfilesFilters({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };
  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...filters,
      [field]: values || undefined,
      page: 1,
    };
    dispatch(setVehicleProfilesFilters(newFilters));
  };
  return (
    <TableNew>
      <Thead>
        <Tr>
          {columns.map((col) => (
            <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalCount}
              sortHandle={sortHandle}
              disabled={totalCount === 0}
              tableFilters={filters}
              pickerValue={pickerValue}
              setPickerValue={setPickerValue}
              pickerHandle={pickerHandle}
              className={`vehicleProfilesListTh--${col.dataIndex}`}
            />
          ))}
          <ThNew action>
            <span className="sr-only">Actions</span>
          </ThNew>
        </Tr>
      </Thead>
      <VehiclesProfilesBody
        openEditModal={openEditModal}
        openDeleteModal={openDeleteModal}
      />
    </TableNew>
  );
};

export default VehicleProfilesList;
