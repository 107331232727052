import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import { fitmentTimeColumnsSelector, fitmentTimeDataSelector } from '../../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import FitmentTimeColumns from './FitmentTimeColumns';
import FitmentTimeBody from './FitmentTimeBody';
import './FitmentTimeTable.scss';

const FitmentTimeTable: React.FC = () => {
  const columns = useAppSelector(fitmentTimeColumnsSelector);
  const { totalCount } = useAppSelector(fitmentTimeDataSelector);

  return (
    <TableNew>
      <FitmentTimeColumns columns={columns} totalElements={totalCount} />
      <FitmentTimeBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default FitmentTimeTable;
