import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, emptyPaging, infiniteScrollInitPaging, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { enquiryAPI } from '../../../api/enquiryApi';
import {
  EnquiryContactDto,
  NewContactReqDataType,
} from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { getEnquiryDetails } from './detailsThunks';
import { SUCCESSFUL_REMOVE } from '../../../core/utils/successMessages';
import { CustomerContactFiltersType } from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { customersAPI } from '../../../api/customersApi';
import { setEnquiryContactsPaging } from '../../slices/enquiriesSlice';
import { ContactDto } from '../../../common/types/commonTypes';
import { RootState } from '../../store';

export const getEnquiryContacts = createAsyncThunk<ResponseResult<EnquiryContactDto[], { allItemIds: number[] }>,
{ id: number, page: number, pageSize: number }>(
  'get/Enquiry/Contacts',
  async ({
    id, page, pageSize,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.fetchEnquiryContacts(id, page, pageSize);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getGenerateEmailEnquiryContacts = createAsyncThunk<ResponseResult<EnquiryContactDto[], { allItemIds: number[] }>,
{ id: number, page: number, pageSize: number, haveEmail?: boolean }>(
  'get/EnquiryEmail/Contacts',
  async ({
    id, page, pageSize, haveEmail,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await enquiryAPI.fetchEnquiryContacts(id, page, pageSize, haveEmail);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const removeEnquiryContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ enquiryId: number, customerContactId: number, closeModal: VoidFunctionType }>(
  'remove/Enquiry/Contact',
  async ({
    enquiryId, customerContactId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries } = getState() as RootState;
      const { enquiryContactsPaging } = enquiries;
      const response = await enquiryAPI.removeEnquiryContact(enquiryId, customerContactId);
      if (enquiryContactsPaging.page > 1) {
        window.scrollTo(0, 0);
      }
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(setEnquiryContactsPaging({ ...infiniteScrollInitPaging }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_REMOVE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createNewContactThunk = createAsyncThunk<ResponseSingleResult | null,
{ data: NewContactReqDataType, closeModal: VoidFunctionType }>(
  'post/Enquiry/NewContact',
  async ({
    data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { enquiries } = getState() as RootState;
      const { enquiryContactsPaging } = enquiries;
      const response = await enquiryAPI.createNewContact(data);
      if (enquiryContactsPaging.page > 1) {
        window.scrollTo(0, 0);
      }
      dispatch(getEnquiryDetails({ id: data.enquiryId }));
      dispatch(setEnquiryContactsPaging({ ...infiniteScrollInitPaging }));
      closeModal();
      const { firstName, lastName } = data;
      dispatch(setSuccessMessage({ message: `Contact ${firstName} ${lastName} was successfully created.` }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getExistingContactsThunk = createAsyncThunk<ResponseResult<ContactDto[], { allItemIds: number[] }>,
{ customerId: number, notAddedToEnquiryId: number, filters: CustomerContactFiltersType }>(
  'get/Enquiry/ExistingContacts',
  async ({
    customerId, notAddedToEnquiryId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerContacts(customerId, filters, { notAddedToEnquiryId });
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addExistingContactsThunk = createAsyncThunk<null,
{ enquiryId: number, customerId: number, customerContactIds: number[], closeModal: VoidFunctionType }>(
  'post/Enquiry/ExistedContact',
  async ({
    enquiryId, customerId, customerContactIds, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { enquiries: { enquiryContactsPaging, enquiryExistedContactsPaging } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await customersAPI.fetchCustomerContacts(
        customerId,
        { ...enquiryExistedContactsPaging, ...emptyPaging },
        { notAddedToEnquiryId: enquiryId },
      );
      const filteredIds = customerContactIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await enquiryAPI.addExistedContacts(enquiryId, filteredIds);
      }
      if (enquiryContactsPaging.page > 1) {
        window.scrollTo(0, 0);
      }
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(setEnquiryContactsPaging({ ...infiniteScrollInitPaging }));
      closeModal();
      dispatch(setFetching(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
