import React from 'react';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { maxCommonDecimal } from '../../../core/utils/regex';

type ManualInputUnitPriceProps = {
  disabled?: boolean,
  value: string,
  onChange: StringFunctionType,
}

const ManualInputUnitPrice: React.FC<ManualInputUnitPriceProps> = ({ disabled, value, onChange }) => {
  return (
    <DecimalInputNumber
      value={value}
      onChange={(e) => onChange(e.target.value.replace(',', '.'))}
      disabled={disabled}
      max={maxCommonDecimal}
      className="manualInputUnitPrice"
    />
  );
};

export default ManualInputUnitPrice;
