import {
  PagingWithOrdering,
  StringRangeType,
  OrderingType,
  NumericRangeType, ResponseResult, ResponseSingleResult, DataListType, initDataList,
} from '../../../core/types/coreTypes';
import { PurchaseOrderDetails } from '../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';

export type InvoicingPurchaseOrderDetails = Omit<PurchaseOrderDetails, 'billingRequisite' | 'areLinesCreatable'>;

export type PurchasesViewOrderLinesFilters = {
  productNumber?: string,
  productName?: string,
  net?: NumericRangeType,
  toBeInvoicedPrice?: NumericRangeType,
  nominalCodes?: number[],
  order?: OrderingType,
}

export type InvoiceDocumentDto = {
  entityNumber: string,
  type: number,
  invoiceDate: string,
  dueDate: string,
  xeroRef: string,
  status: number,
  id: number,
  supplierName: string | null,
  customerName: string | null,
  orderNumber: string | null,
  purchaseOrderNumber: string | null,
  net: number,
  vatValue: number,
  gross: number,
}

export type InvoiceDocumentsFilters = {
  purchaseOrderIds?: number[],
  orderIds?: number[],
  operationTypes?: number[],
  supplierIds?: number[],
  customerIds?: number[],
  types?: number[],
  invoiceDate?: StringRangeType,
  dueDate?: StringRangeType,
  statuses?: number[],
  keyword?: string,
} & PagingWithOrdering

export type GetInvoiceDocumentsResponse = ResponseResult<InvoiceDocumentDto[], {
  minInvoiceDate: string,
  maxInvoiceDate: string,
  minDueDate: string,
  maxDueDate: string,
  allInvoiceIds: number[],
  allCreditNoteIds: number[],
  allItemIds: number[],
}>

export type InvoiceDocumentsData = DataListType<InvoiceDocumentDto[], {
  minInvoiceDate: string | null,
  maxInvoiceDate: string | null,
  minDueDate: string | null,
  maxDueDate: string | null,
  allInvoiceIds: number[],
  allCreditNoteIds: number[],
  allItemIds: number[],
}>

export const initInvoiceDocumentsData = {
  ...initDataList,
  minInvoiceDate: null,
  maxInvoiceDate: null,
  minDueDate: null,
  maxDueDate: null,
  allInvoiceIds: [],
  allCreditNoteIds: [],
  allItemIds: [],
};

export type InvoiceDocumentTypeSummaryDto = {
  invoiceDocumentsCount: number,
  totalNet: number,
  totalNetString: string,
  totalGross: number,
  totalGrossString: string,
}

export type InvoiceDocumentsSummary = {
  draftInvoicesSummary: InvoiceDocumentTypeSummaryDto | null,
  exportedInvoicesSummary: InvoiceDocumentTypeSummaryDto | null,
  draftCreditNotesSummary: InvoiceDocumentTypeSummaryDto | null,
  exportedCreditNotesSummary: InvoiceDocumentTypeSummaryDto | null,
}

export const initInvoiceDocumentsSummary = {
  draftInvoicesSummary: null,
  exportedInvoicesSummary: null,
  draftCreditNotesSummary: null,
  exportedCreditNotesSummary: null,
};

export type GetInvoiceDocumentsSummaryResponse = ResponseSingleResult<InvoiceDocumentsSummary>;

export type InvoicesAndCreditNotesFilters = Pick<InvoiceDocumentsFilters, 'purchaseOrderIds' | 'orderIds' | keyof PagingWithOrdering>
