import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { routesPath } from '../core/enums/pathEnum';
import EnquiriesContainer from '../enquiry/enquiries/EnquiriesContainer';
import HomePage from '../greetings/HomePage';
import CreateEnquiry from '../enquiry/create-enquiry/CreateEnquiry';
import JobsContainer from '../job/container/JobsContainer';
import SettingsContainer from '../settings/users-dashboard/SettingsContainer';
import CustomersDashboardContainer from '../customers/customers-dashboard/CustomersDashboardContainer';
import CustomerViewPageContainer from '../customers/customer-view-page/CustomerViewPageContainer';
import CreateCustomer from '../customers/create-customer/CreateCustomer';
import LoginContainer from '../login/LoginContainer';
import CreateUserContainer from '../settings/create-user/CreateUserContainer';
import UserProfileContainer from '../settings/user-profile/UserProfileContainer';
import AccountFinalizationContainer from '../settings/account-finalization/AccountFinalizationContainer';
import ErrorPage from '../common/components/error-pages/ErrorPage';
import PrivateRoute from './PrivateRoute';
import { PermissionEnum } from '../core/enums/dictionariesEnums';
import PricelistsContainer from '../pricelists/dashboard/PricelistsContainer';
import CreatePricelistContainer from '../pricelists/create-pricelist/CreatePricelistContainer';
import PricelistViewPageContainer from '../pricelists/pricelist-view-page/PricelistViewPageContainer';
import StockContainer from '../stock/container/StockContainer';
import PartViewPage from '../stock/part-view-page/PartViewPage';
import PartsKitsViewPageContainer from '../stock/parts-kits/view-page/PartsKitsViewPageContainer';
import CreatePartsKitContainer from '../stock/parts-kits/create/CreatePartsKitContainer';
import SuppliersContainer from '../supplier/dashboard/SuppliersContainer';
import CreateSupplierContainer from '../supplier/create-supplier/CreateSupplierContainer';
import SupplierViewPageContainer from '../supplier/supplier-view-page/SupplierViewPageContainer';
import CreatePartContainer from '../stock/create-part/CreatePartContainer';
import CreatePurchaseOrderContainer from '../stock/purchase-orders/create/CreatePurchaseOrderContainer';
import PurchaseOrderViewPageContainer from '../stock/purchase-orders/view-page/PurchaseOrderViewPageContainer';
import ForgotPasswordContainer from '../password/forgot/ForgotPasswordContainer';
import ResetPasswordContainer from '../password/reset/ResetPasswordContainer';
import AccountContainer from '../account/AccountContainer';
import OrdersContainer from '../orders/dashboard/OrdersContainer';
import RectificationsContainer from '../rectifications/dashboard/RectificationsContainer';
import CreateRectificationContainer from '../rectifications/create-rectification/CreateRectificationContainer';
import CreateJobContainer from '../job/create-job/CreateJobContainer';
import EmployeesContainer from '../employees/container/EmployeesContainer';
import JobViewWrapper from '../job/job-view-page/JobViewWrapper';
import FormsContainer from '../forms/FormsContainer';
import CreateEmployee from '../employees/create-employee/components/CreateEmployee';
import EmployeeViewPageContainer from '../employees/employee-view-page/EmployeeViewPageContainer';
import { StockPageParamsAndPermissions } from '../stock/container/enums/stockEnums';
import { SettingsPageParamsAndPermissions } from '../settings/users-dashboard/enums/settingsEnums';
import { JobsPageParamsAndPermissions } from '../job/jobs/enums/jobsEnums';
import { RectificationPageParamsAndPermissions } from '../rectifications/dashboard/enums/rectificationEnums';
import { OrdersPageParamsAndPermissions } from '../orders/dashboard/enums/OrdersViewsEnum';
import { EnquiryPageParamsAndPermissions } from '../enquiry/enquiries/enums/dashboardViewsEnum';
import { EmployeesPageParamsAndPermissions } from '../employees/container/enums/EmployeesPageParamsEnums';
import { vehiclePageParams } from '../vehicles/container/enums/VehiclesPageParamsEnum';
import EnquiryViewPageWrapper from '../enquiry/enquiry-detailed/EnquiryViewPageWrapper';
import OrderViewPageWrapper from '../orders/orders-view-page/OrderViewPageWrapper';
import RectificationViewPageWrapper from '../rectifications/rectification-view-page/RectificationViewPageWrapper';
import StockPartRequestViewPage from '../stock/stock-part-request-view/StockPartReqViewPage';
import ReportsContainer from '../reports/ReportsContainer';
import VehiclesContainer from '../vehicles/container/VehiclesContainer';
import CreateVehicle from '../vehicles/create-vehicle/CreateVehicle';
import VehicleViewPageContainer from '../vehicles/vehicle-view-page/VehicleViewPageContainer';
import FullVehicleCheck from '../vehicles/vehicle-checks/components/FullVehicleCheck';
import WeightVehicleCheck from '../vehicles/vehicle-checks/components/WeightVehicleCheck';
import HandoverVehicleCheck from '../vehicles/vehicle-checks/components/HandoverVehicleCheck';
import InvoicingContainer from '../invoicing/container/InvoicingContainer';
import {
  InvoicingPageParamsAndPermissions,
  InvoicingPageParamsEnums,
} from '../invoicing/container/enums/InvoicingPageParamsEnums';
import InvoicingViewPage from '../invoicing/invoicing-purchases/view-page/InvoicingViewPage';
import SalesViewPage from '../invoicing/sales/view-page/SalesViewPage';

function RootRoutes() {
  return (
    <Routes>
      <Route
        path={routesPath.ENQUIRIES}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.EnquiryDashboardPage}
          checkSearchParams
          paramsAndPermissions={EnquiryPageParamsAndPermissions}
        >
          <EnquiriesContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_ENQUIRY}
        element={<PrivateRoute restrictedTo={PermissionEnum.EnquiryCreatePage}>
          <CreateEnquiry />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.ENQUIRIES}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.EnquiryViewPage}>
          <EnquiryViewPageWrapper />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.ORDERS}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.OrderDashboard}
          checkSearchParams
          paramsAndPermissions={OrdersPageParamsAndPermissions}
        >
          <OrdersContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.ORDERS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.OrderViewPage}>
          <OrderViewPageWrapper />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.RECTIFICATIONS}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.RectificationDashboard}
          checkSearchParams
          paramsAndPermissions={RectificationPageParamsAndPermissions}
        >
          <RectificationsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.RECTIFICATIONS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.RectificationViewPage}>
          <RectificationViewPageWrapper />
        </PrivateRoute>}
      />
      <Route
        path="/create-rectification"
        element={<PrivateRoute restrictedTo={PermissionEnum.RectificationCreate}>
          <CreateRectificationContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.JOBS}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.JobListView}
          checkSearchParams
          paramsAndPermissions={JobsPageParamsAndPermissions}
        >
          <JobsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.JOBS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.JobViewPage}>
          <JobViewWrapper />
        </PrivateRoute>}
      />
      <Route
        path="/create-job"
        element={<PrivateRoute restrictedTo={PermissionEnum.JobCreateNewJob}>
          <CreateJobContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CUSTOMERS}
        element={<PrivateRoute restrictedTo={PermissionEnum.CustomerDashboardPage}>
          <CustomersDashboardContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.CUSTOMERS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.CustomerViewPage}>
          <CustomerViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_CUSTOMER}
        element={<PrivateRoute restrictedTo={PermissionEnum.CustomerCreatePage}>
          <CreateCustomer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.SETTINGS}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.SettingsModule}
          paramsAndPermissions={SettingsPageParamsAndPermissions}
          checkSearchParams
        >
          <SettingsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.USER_PROFILE}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.UserViewPage}>
          <UserProfileContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_USER}
        element={<PrivateRoute restrictedTo={PermissionEnum.UserCreatePage}>
          <CreateUserContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.PRICELISTS}
        element={<PrivateRoute restrictedTo={PermissionEnum.PricelistsDashboardPage}>
          <PricelistsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.PRICELISTS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.PricelistsViewPage}>
          <PricelistViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_PRICELIST}
        element={<PrivateRoute restrictedTo={PermissionEnum.PricelistCreatePage}>
          <CreatePricelistContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.STOCK}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.StockModule}
          checkSearchParams
          paramsAndPermissions={StockPageParamsAndPermissions}
        >
          <StockContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.STOCK}/part/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockPartViewPage}>
          <PartViewPage />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_PART}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockCreateNewPart}>
          <CreatePartContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_PARTS_KIT}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockCreateNewPartsKit}>
          <CreatePartsKitContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockPartsKitViewPage}>
          <PartsKitsViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_PURCHASE_ORDER}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockPurchaseOrdersCreate}>
          <CreatePurchaseOrderContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockPurchaseOrdersViewPage}>
          <PurchaseOrderViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.STOCK}/part-request/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.StockPartRequestViewPage}>
          <StockPartRequestViewPage />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.SUPPLIERS}`}
        element={<PrivateRoute restrictedTo={PermissionEnum.SupplierDashboardPage}>
          <SuppliersContainer />
        </PrivateRoute>}
      />
      <Route
        path={routesPath.CREATE_SUPPLIERS}
        element={<PrivateRoute restrictedTo={PermissionEnum.SupplierCreatePage}>
          <CreateSupplierContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.SUPPLIERS}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.SupplierViewPage}>
          <SupplierViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.INVOICING}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.InvoicingModule}
          paramsAndPermissions={InvoicingPageParamsAndPermissions}
          checkSearchParams
        >
          <InvoicingContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.INVOICING}/:id`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.InvoicingPurchaseViewPageWithInvoices}
        >
          <InvoicingViewPage />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.INVOICING}/${InvoicingPageParamsEnums.SALES}/:id`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.InvoicingSalesViewPageWithInvoices}
        >
          <SalesViewPage />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.EMPLOYEES}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.EmployeeDashboard}
          checkSearchParams
          paramsAndPermissions={EmployeesPageParamsAndPermissions}
        >
          <EmployeesContainer />
        </PrivateRoute>}
      />
      <Route
        path={`/${routesPath.EMPLOYEES}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.EmployeeViewPage}>
          <EmployeeViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.CREATE_EMPLOYEE}`}
        element={<PrivateRoute restrictedTo={PermissionEnum.EmployeeCreatePage}>
          <CreateEmployee />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.FORMS}`}
        element={<PrivateRoute restrictedTo={PermissionEnum.TestModule}>
          <FormsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.REPORTS}`}
        element={<PrivateRoute restrictedTo={PermissionEnum.ReportModule}>
          <ReportsContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.VEHICLES}`}
        element={<PrivateRoute
          restrictedTo={PermissionEnum.VehicleModule}
          checkSearchParams
          paramsAndPermissions={vehiclePageParams}
        >
          <VehiclesContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.VEHICLES}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <VehicleViewPageContainer />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.FULL_VEHICLE_CHECK}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <FullVehicleCheck />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.HANDOVER_VEHICLE_CHECK}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <HandoverVehicleCheck />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.WEIGHT_VEHICLE_CHECK}/:id`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <WeightVehicleCheck />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.FULL_VEHICLE_CHECK}/:id/:checkId`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <FullVehicleCheck readMode />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.HANDOVER_VEHICLE_CHECK}/:id/:checkId`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <HandoverVehicleCheck readMode />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.WEIGHT_VEHICLE_CHECK}/:id/:checkId`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleViewPage}>
          <WeightVehicleCheck readMode />
        </PrivateRoute>}
      />
      <Route
        path={`${routesPath.CREATE_VEHICLE}`}
        element={<PrivateRoute restrictedTo={PermissionEnum.VehicleCreate}>
          <CreateVehicle />
        </PrivateRoute>}
      />
      <Route path={`/${routesPath.RESET_PASSWORD}/:token`} element={<ResetPasswordContainer />} />
      <Route path={routesPath.ACCOUNT} element={<AccountContainer />} />
      <Route path={`/${routesPath.ACCOUNT_FINALISATION}/:token`} element={<AccountFinalizationContainer />} />
      <Route path={routesPath.HOME} element={<HomePage />} />
      <Route path={routesPath.LOGIN} element={<LoginContainer />} />
      <Route path={routesPath.FORGOT_PASSWORD} element={<ForgotPasswordContainer />} />
      <Route path="*" element={<ErrorPage errorStatusCode={{ status: '404', httpCode: 404 }} />} />
    </Routes>
  );
}

export default RootRoutes;
