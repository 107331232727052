import { faBoxOpen, faChevronDown, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Divider } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { useOnClickOutside } from '../../hooks/useOutsideClick';
import Button from '../button/Button';
import './SelectableDropdown.scss';
import Input from '../input/Input';
import Spinner from '../spinner/Spinner';
import Checkbox from '../checkbox/Checkbox';
import { SearchTableFilterType } from '../../types/coreTypes';

type OptionType = {
  value: CheckboxValueType;
  label: string;
};

type SelectableDropdownProps = {
  label: string;
  fieldName: string,
  options?: OptionType[];
  searchV?: SearchTableFilterType,
  setSearchV: (value: SearchTableFilterType) => void,
  value?: CheckboxValueType[];
  onChange: (checkedValues: CheckboxValueType[] | undefined) => void;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  noSearch?: boolean;
  noClear?: boolean,
}

const SelectableDropdown: React.FC<SelectableDropdownProps> = ({
  label,
  fieldName,
  options,
  searchV,
  setSearchV,
  value,
  onChange,
  placeholder = 'Search...',
  disabled,
  className,
  noSearch,
  noClear,
}) => {
  const [buttonLabel, setButtonLabel] = useState<string | React.ReactNode>(label);
  const [isVisible, setIsVisible] = useState(false);
  const [optionsList, setOptionsList] = useState<OptionType[] | undefined>(undefined);
  const [isFetching, setIsFetching] = useState<boolean>(false);

  useEffect(() => {
    if (!optionsList && options && options?.length > 0) {
      setOptionsList(options);
    }
  }, [options, optionsList]);

  useEffect(() => {
    if (searchV) {
      !searchV[fieldName] && optionsList && setOptionsList(options);
    }
  }, [searchV, optionsList, fieldName, options]);

  const ref = useRef(null);
  useOnClickOutside(ref, () => setIsVisible(false));

  const searchHandle = (value: string) => {
    setSearchV({ ...searchV, [fieldName]: value });
    if (value) {
      setIsFetching(true);
      setTimeout(() => {
        const newArr = options?.filter((el) => el.label.toLowerCase().includes(value.toLowerCase().trim()));
        newArr && setOptionsList(newArr);
        setIsFetching(false);
      }, 1000);
    } else {
      setOptionsList(options || []);
    }
  };

  useEffect(() => {
    if (value && value?.length > 0) {
      const firstEl = options?.find((el) => el.value === value[0]);
      const length = value.length === 1 ? '' : ` (+${value.length - 1})`;
      const labelNode = <span className="selectableDropdown__selected-value">
        <span>{firstEl?.label}</span>
        <span>{length}</span>
      </span>;
      setButtonLabel(labelNode);
    } else {
      setButtonLabel(label);
    }
  }, [value, label, options]);

  return (
    <div className={classNames(className, 'selectableDropdown', {
      'selectableDropdown--selected': value && value?.length > 0,
    })}
    >
      <Button
        label={buttonLabel}
        icon={<FontAwesomeIcon icon={faChevronDown} style={{ marginLeft: 12 }} />}
        className={classNames('dropdownButton', {
          'dropdownButton--selected': value && value?.length > 0,
        })}
        onClick={() => setIsVisible(true)}
        disabled={disabled}
        disableDefaultStyles
      />
      {isVisible && <div
        ref={ref}
        className="dropdownList"
      >
        {!noSearch && <Input
          value={searchV ? searchV[fieldName] : ''}
          onChange={(e) => searchHandle(e.target.value)}
          placeholder={placeholder}
          className="dropdownSearch"
        />}
        <div className="dropdownList__content-wrap">
          {isFetching && <Spinner global={false} />}
          <div className="dropdownList__options">
            {optionsList && optionsList.length > 0
              ? <>
                {optionsList.map((opt, i) => <Checkbox
                  key={i}
                  value={opt.value}
                  label={opt.label}
                  checked={value?.includes(opt.value)}
                  onChange={(v) => {
                    if (v) {
                      onChange(value ? [...value, opt.value] : [opt.value]);
                    } else {
                      const newList = value?.filter((el) => el !== opt.value);
                      onChange(newList);
                    }
                  }}
                  truncateLabel
                />)}
              </>
              : <span className="dropdownList__noResult">
                <FontAwesomeIcon icon={faBoxOpen} />
                No search result
              </span>}
          </div>
          {!noClear && <>
            <Divider />
            <div className="dropdownList__clear">
              <Button
                icon={<FontAwesomeIcon icon={faTimes} />}
                label="Clear"
                onClick={() => {
                  value && value.length > 0 && onChange(undefined);
                  searchV && setSearchV({ ...searchV, [fieldName]: '' });
                  setOptionsList(options || []);
                }}
                type="text"
              />
            </div>
          </>}
        </div>
      </div>}
    </div>
  );
};

export default SelectableDropdown;
