import React, {
  useCallback, useEffect, useMemo,
} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Segmented from '../../../../core/components/segmented/Segmented';
import Selectable from '../../../../core/components/selectable/Selectable';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Pagination from '../../../../core/components/pagination/Pagination';
import { CustomersDashboardFiltersType } from '../../types/customersDashboardTypes';
import { setCustomersDashFilters } from '../../../../store/slices/customersSlice';
import { routesPath } from '../../../../core/enums/pathEnum';
import { DashboardViewsEnum } from '../../enums/DashboardViewsEnum';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import { useLocationHistoryState } from '../../../../core/hooks/useLocationHistoryState';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import { useCustomerDashFilters } from '../../hooks/useCustomerDashFilters';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';

type CustomersFiltersProps = {
  view: string | number;
  setView: (value: string | number) => void;
  filters: CustomersDashboardFiltersType;
  totalCount: number;
}

const CustomersFilters: React.FC<CustomersFiltersProps> = ({
  view, setView, filters, totalCount,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);

  const {
    filtersList,
    keywordValue,
    initDropdownSearch,
    setKeywordValue,
    onClear,
    setFiltersHandle,
  } = useCustomerDashFilters();

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue) {
      const timeOutId = setTimeout(() => {
        setFiltersHandle({ ...filters, keyword: keywordValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!keywordValue?.trim() && filters.keyword) {
        setFiltersHandle({ ...filters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [keywordValue]);

  const viewHandle = (value: string) => {
    if (value === 'Dashboard') {
      navigate(`/${routesPath.CUSTOMERS}`, { replace: true });
    } else {
      navigate('/create-customer');
    }
    setView(value);
    setLocationHistoryHandle({
      pathname: location.pathname,
      search: location.search,
    });
  };
  const options = useMemo(() => [
    { label: DashboardViewsEnum.Dashboard, value: DashboardViewsEnum.Dashboard, restrictedTo: PermissionEnum.CustomerDashboardPage },
    { label: DashboardViewsEnum.Create, value: DashboardViewsEnum.Create, restrictedTo: PermissionEnum.CustomerCreatePage },
  ], []);
  const tabAllowed = useCallback((permission: PermissionEnum) => {
    return !!currentUser?.userContextRole?.permissions.includes(permission);
  }, [currentUser]);

  const allowedItems = useMemo(() => options.filter((el) => tabAllowed(el.restrictedTo)), [options, tabAllowed]);
  const { windowWidth } = useScreenWitdh();
  return (
    <>
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG && (
        <div className="mobile-sticky-filters mobile-sticky-filters--top-sm">
          <Selectable
            options={allowedItems}
            value={view}
            onChange={(value) => viewHandle(value as string)}

          />
        </div>
      )}
      <section className="sections-and-filters sections-and-filters--mobile-offset desktop-sticky-filters desktop-sticky-filters--top-sm">
        {!!windowWidth && windowWidth > responsiveEndpointsEnum.LG && (
          <Segmented
            options={allowedItems}
            value={view}
            onChange={(value) => viewHandle(value as string)}
          />
        )}
        <KeywordFiltersSection
          searchValue={keywordValue}
          initDropdownSearch={initDropdownSearch}
          setSearchValue={(v) => {
            setKeywordValue(v);
          }}
          filters={filtersList}
          getFilteredResHandle={(value, field) => setFiltersHandle({
            ...filters, [field]: value,
          })}
          onClear={onClear}
        />
      </section>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          onChange={(page, pageSize) => dispatch(setCustomersDashFilters({ ...filters, page, pageSize }))}
          onShowSizeChange={(page, pageSize) => dispatch(setCustomersDashFilters({ ...filters, page, pageSize }))}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
    </>
  );
};

export default CustomersFilters;
