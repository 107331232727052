import { RequisiteDto } from '../types/commonTypes';
import { initRequisite } from './data';

export const requisiteHelper = (requisite: RequisiteDto) => {
  const requisiteCopy: RequisiteDto = { ...requisite };

  const keys = Object.keys(requisite) as (keyof RequisiteDto)[];
  keys.forEach((key) => {
    requisiteCopy[key] = requisite[key]?.trim() || '';
  });

  return requisiteCopy;
};

type RequisiteInfo = {
  isShippingRequisiteSameAsBilling: boolean | null,
  isShippingDetailsManualInput: boolean | null,
  isBillingDetailsManualInput: boolean | null,
  shippingCustomerId: number | null,
  billingCustomerId: number | null,
  alwaysUseCustomerRequisites: boolean,
  customerId: number,
}

export const getRequisiteInfo = (
  billingRequisite: RequisiteDto | undefined | null,
  shippingRequisite: RequisiteDto | undefined | null,
  info: RequisiteInfo | undefined,
) => {
  return {
    billing: billingRequisite ?? initRequisite,
    shipping: shippingRequisite ?? initRequisite,
    isShippingSame: info?.isShippingRequisiteSameAsBilling ?? true,
    isShippingDetailsManualInput: info?.isShippingDetailsManualInput ?? false,
    isBillingDetailsManualInput: info?.isBillingDetailsManualInput ?? false,
    shippingCustomerId: info?.shippingCustomerId ?? undefined,
    billingCustomerId: info?.billingCustomerId ?? undefined,
    alwaysUseCustomerRequisites: info?.alwaysUseCustomerRequisites ?? false,
    customerId: info?.customerId,
  };
};
