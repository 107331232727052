import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { purchaseTabModesOptions } from '../../utils/data';
import Selectable from '../../../../core/components/selectable/Selectable';
import {
  StockTabParamEnum,
} from '../../../purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { setStockPartRequestsFilters } from '../../../../store/slices/stockPartRequestSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  stockPartReqArchivedStatusesSelector,
  stockPartRequestsFiltersSelector,
} from '../../../../store/selectors/stockPartRequestsSelector';
import { StringFunctionType } from '../../../../core/types/coreTypes';
import { StockPageParamEnums } from '../../enums/stockEnums';
import { PartRequestsUrlParamsNames } from '../../../stock-part-requests/enums/stockPartRequestsEnum';
import { currentUserSelector } from '../../../../store/selectors/accountSelectors';

type PartRequestsFiltersContentProps = {
  screenSize?: 'sm' | 'lg',
  partReqTabType: string | null;
  setStockPartReqTabTypeParam: StringFunctionType;
}

const PartRequestsFiltersContent: React.FC<PartRequestsFiltersContentProps> = ({
  partReqTabType, screenSize, setStockPartReqTabTypeParam,
}) => {
  const [params, setSearchParams] = useSearchParams();
  const page = params.get(PartRequestsUrlParamsNames.PAGE);

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const stockPartRequestsFilters = useAppSelector(stockPartRequestsFiltersSelector);
  const stockPartReqArchivedStatuses = useAppSelector(stockPartReqArchivedStatusesSelector);

  const changeStockPartReqTabParam = (value: string) => {
    setStockPartReqTabTypeParam(value as string);
    const newValues = value === StockTabParamEnum.ACTIVE
      ? { isActive: true, status: undefined }
      : { isActive: false, status: [stockPartReqArchivedStatuses[0].value] };
    dispatch(setStockPartRequestsFilters({
      ...stockPartRequestsFilters,
      ...newValues,
      page: 1,
    }));
  };

  useEffect(() => {
    if (currentUser) {
      if (!partReqTabType) {
        if (page === StockPageParamEnums.PART_REQUESTS) {
          const { isActive } = stockPartRequestsFilters;
          const tabValue = isActive ? StockTabParamEnum.ACTIVE : StockTabParamEnum.ARCHIVED;

          setSearchParams({
            page,
            [PartRequestsUrlParamsNames.TAB_TYPE]: tabValue,
          });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, page, partReqTabType]);

  return <Selectable
    options={purchaseTabModesOptions}
    value={partReqTabType || ''}
    onChange={(value) => changeStockPartReqTabParam(value as string)}
    size={screenSize}
  />;
};

export default PartRequestsFiltersContent;
