import moment from 'moment';

// send date
export const utcDateFormatHandler = (format: string, date?: string) => {
  return date ? moment.utc(date).format(format) : 'N/A';
};

// display date
export const localDateFormatHandler = (format: string, date?: string | null) => {
  return date ? moment.utc(date).local().format(format) : 'N/A';
};
