import React from 'react';
import { useVehicleDictionaries } from '../../../hooks/useVehicleDictionaries';
import { labelHandle } from '../../../../core/utils/labelHandle';
import { fieldNameEventHandle } from '../../../utils/activityLogUtils';

type VehicleEditEvent = {
  field?: string,
  vehicleName?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const VehicleEditEvent: React.FC<VehicleEditEvent> = ({
  field, vehicleName, oldValue, newValue,
}) => {
  const {
    vehicleSlidingDoorsQuantities,
    vehicleInfoSources,
    vehicleManufacturers,
    vehicleProfilesTypes,
    vehicleProfilesGroups,
  } = useVehicleDictionaries();

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'VehicleInfoSource':
        return labelHandle(+value, vehicleInfoSources);
      case 'Manufacturer':
        return labelHandle(value, vehicleManufacturers);
      case 'Type':
        return labelHandle(+value, vehicleProfilesTypes);
      case 'Group':
        return labelHandle(+value, vehicleProfilesGroups);
      case 'Sliding doors quantity':
        return labelHandle(+value, vehicleSlidingDoorsQuantities);
      default: return value;
    }
  };

  return (
    <>
      Field
      {' '}
      {!!field && <b>{fieldNameEventHandle(field)}</b>}
      {' '}
      of
      {' '}
      {!!vehicleName && <b>{vehicleName}</b>}
      {' '}
      was changed
      {' '}
      {!!oldValue && <s>{valueHandle(oldValue)}</s>}
      {!!newValue && <b>{` ${valueHandle(newValue)}`}</b>}
    </>
  );
};

export default VehicleEditEvent;
