import React, { ComponentProps } from 'react';
import classNames from 'classnames';

type TrProps = {
  children: React.ReactNode;
  className?: string,
} & ComponentProps<'tr'>

const Tr: React.FC<TrProps> = ({ children, className, ...props }) => {
  return (
    <tr {...props} className={classNames(className, 'table-tr')}>{children}</tr>
  );
};

export default Tr;
