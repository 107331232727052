import React from 'react';
import {
  Control, Controller, FieldErrors, UseFormSetValue, UseFormWatch,
} from 'react-hook-form';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import {
  jobTypesSelector,
  permissionUiRolesSelector,
  shortBusinessAreasSelector,
} from '../../../store/selectors/coreSelectors';
import { useAppSelector } from '../../../store/hooks';
import RadioGroup from '../../../core/components/radio-group/RadioGroup';
import { YesNoOptions } from '../../../core/utils/testData';
import { EmployeeFields } from '../../employee-view-page/utils/EmployeeSchema';

type EmployeeUserAccountSectionProps = {
  control: Control<EmployeeFields>,
  errors: FieldErrors<EmployeeFields>,
  watch: UseFormWatch<EmployeeFields>,
  isCreateUser?: number,
  setIsCreateUser: (v?: number) => void,
  setUserCreateChoiceErr: StringFunctionType,
  userCreateChoiceErr: string,
  setValue: UseFormSetValue<EmployeeFields>,
}

const EmployeeUserAccountSection: React.FC<EmployeeUserAccountSectionProps> = ({
  control, errors, isCreateUser, setIsCreateUser, setUserCreateChoiceErr, userCreateChoiceErr,
  setValue, watch,
}) => {
  const jobTypes = useAppSelector(jobTypesSelector);
  const shortBusinessAreas = useAppSelector(shortBusinessAreasSelector);
  const permissionUiRoles = useAppSelector(permissionUiRolesSelector);

  const email = watch('email');

  const cleanUpUserFields = () => {
    setValue('userRole', '');
    setValue('userJobType', []);
    setValue('userBusinessAreas', []);
    setValue('userAssignedEntitiesOption', undefined);
  };

  return (
    <section className="details-form">
      <h2 className="info-grid__title">User account</h2>
      <RadioGroup
        options={YesNoOptions}
        value={isCreateUser ?? ''}
        label="Create user account"
        onChange={(v) => {
          if (v === 0) {
            cleanUpUserFields();
          }
          setIsCreateUser(v as number);
          userCreateChoiceErr && setUserCreateChoiceErr('');
        }}
        optionType="button"
        tip="An invitation link will be sent to employee's email address."
        error={userCreateChoiceErr}
        className="details-form__field--lg"
      />
      {isCreateUser === 1 && <>
        <Input
          value={email}
          label="Email"
          disabled
        />
        <Input
          value="Internal"
          label="Account type"
          disabled
        />
        <Controller
          name="userRole"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              options={permissionUiRoles}
              error={errors.userRole?.message}
              label="Role"
              showSearch
              isFilterOption
            />
          )}
        />
        <Controller
          name="userJobType"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              mode="multiple"
              value={field.value}
              onChange={field.onChange}
              options={jobTypes}
              error={(errors.userJobType as FieldErrors)?.message}
              showArrow
              label="Allowed job types"
            />
          )}
        />
        <Controller
          name="userBusinessAreas"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              mode="multiple"
              value={field.value}
              onChange={field.onChange}
              options={shortBusinessAreas}
              error={(errors.userBusinessAreas as FieldErrors)?.message}
              showArrow
              label="Allowed business areas"
            />
          )}
        />
        <Controller
          name="userAssignedEntitiesOption"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              options={YesNoOptions}
              error={errors.userAssignedEntitiesOption?.message}
              label="Can see only assigned entities"
            />
          )}
        />
      </>}
    </section>
  );
};

export default EmployeeUserAccountSection;
