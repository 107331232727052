import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { settingsAPI } from '../../../api/settingsApi';
import {
  UserAccountPermissionDto,
  UserAccountRolePermissionDto,
} from '../../../settings/permissions/types/permissionTypes';
import { PatchRolePermissionsReqType } from '../../../settings/users-dashboard/types/settingsTypes';
import { setLoading } from '../../slices/coreSlice';
import { RootState } from '../../store';
import { setRolePermissions } from '../../slices/settingsSlice';

export const getPermission = createAsyncThunk<ResponseSingleResult<{ items: UserAccountPermissionDto[] }> | null,
{ module: number, onFinish?: VoidFunctionType }
>(
  'get/Permissions',
  async ({ module, onFinish }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const res = await settingsAPI.fetchPermissions(module);
      dispatch(setLoading(false));
      onFinish && onFinish();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPermissionRoles = createAsyncThunk<ResponseSingleResult<{ items: UserAccountRolePermissionDto[] }> | null,
{ module?: number, onFinish?: VoidFunctionType }
>(
  'get/PermissionRoles',
  async ({ module, onFinish }, { dispatch, rejectWithValue }) => {
    try {
      dispatch(setLoading(true));
      const res = await settingsAPI.fetchPermissionRoles(module);
      dispatch(setLoading(false));
      onFinish && onFinish();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchRolePermissions = createAsyncThunk<ResponseSingleResult,
{ roles: PatchRolePermissionsReqType[], module?: number }
>(
  'patch/RolePermissions',
  async ({ roles, module }, { getState, dispatch, rejectWithValue }) => {
    try {
      const { settings } = getState() as RootState;
      const { rolePermissions } = settings;
      dispatch(setLoading(true));
      const res = await settingsAPI.patchPermissions(roles);
      const getRes = await settingsAPI.fetchPermissionRoles(module);
      const firstRoleToChange = roles[0];
      const updatedPermissions = rolePermissions?.map((r) => {
        const isMatchInPrevData = r.role.role === firstRoleToChange.role && r.role.roleLevel === firstRoleToChange.roleLevel;
        if (isMatchInPrevData) {
          const getResItems: UserAccountRolePermissionDto[] = getRes?.data?.data.items;
          const found = getResItems?.find((el) => el.role.role === firstRoleToChange.role
                && el.role.roleLevel === firstRoleToChange.roleLevel);
          return {
            ...r,
            permissions: found?.permissions || [],
          };
        } else {
          return r;
        }
      });
      dispatch(setRolePermissions(updatedPermissions || null));
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
