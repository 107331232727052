import React from 'react';
import PurchaseDetailsAndActivity from './details-activity/PurchaseDetailsAndActivity';
import { PurchaseOrderViewParamEnum } from '../../enums/PurchaseOrderViewEnum';
import PurchaseOrderPartsList from './parts-list/PurchaseOrderPartsList';
import PurchaseOrderAttachments from './attachments/PurchaseOrderAttachments';
import { BooleanFunctionType } from '../../../../../core/types/coreTypes';
import { AddressFields } from '../../../../../common/types/commonTypes';

type PurchaseOrderContentProps = {
  mode: string | null,
  setRequisiteModal: BooleanFunctionType,
  setLocationModal: BooleanFunctionType,
  setAddressFields: React.Dispatch<React.SetStateAction<AddressFields | null>>
}

const PurchaseOrderContent: React.FC<PurchaseOrderContentProps> = ({
  mode, setRequisiteModal, setLocationModal, setAddressFields,
}) => {
  const renderHandle = () => {
    switch (mode) {
      case PurchaseOrderViewParamEnum.DETAILS:
        return <PurchaseDetailsAndActivity
          setRequisiteModal={setRequisiteModal}
          setLocationModal={setLocationModal}
          setAddressFields={setAddressFields}
        />;
      case PurchaseOrderViewParamEnum.PART_LINES:
        return <PurchaseOrderPartsList />;
      case PurchaseOrderViewParamEnum.ATTACHMENTS:
        return <PurchaseOrderAttachments />;
      default: return <></>;
    }
  };
  return (
    <>{renderHandle()}</>
  );
};

export default PurchaseOrderContent;
