export const jobTrackingColumns = [
  {
    title: 'Assignee',
    dataIndex: 'jobAssignee',
  },
  {
    title: 'Spent',
    dataIndex: 'jobSpentMinutes',
  },
  {
    title: 'Estimated',
    dataIndex: 'estimated',
  },
  {
    title: 'Remaining',
    dataIndex: 'remaining',
  },
];
