export enum JobViewModeParamsEnum {
  JOB_DETAILS = 'details',
  JOB_PARTS = 'job-parts',
  JOB_QUALITY_CHECK = 'job-quality-check',
  JOB_TIME_TRACKING = 'time-tracking',
  JOB_ATTACHMENTS = 'attachments',
  JOB_LINKS = 'links',
}

export const JobContentModes = [
  JobViewModeParamsEnum.JOB_DETAILS,
  JobViewModeParamsEnum.JOB_PARTS,
  JobViewModeParamsEnum.JOB_QUALITY_CHECK,
  JobViewModeParamsEnum.JOB_TIME_TRACKING,
  JobViewModeParamsEnum.JOB_ATTACHMENTS,
  JobViewModeParamsEnum.JOB_LINKS,
];

export enum JobStatusesUiNames {
  New = 'New',
  Pending = 'Pending',
  InProduction = 'In production',
  Postponed = 'Postponed',
  ReadyForQc = 'Ready for QC',
  JobQc = 'Job QC',
  Failed = 'Failed',
  Cancelled = 'Cancelled',
  Completed = 'Completed',
}

export enum JobPartsOrdering {
  OrdinalNumber = 'OrdinalNumber',
  Category = 'Category',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  Status = 'Status',
}
