export type ManualInputFields = {
  costPrice: string,
  sellPrice: string,
  quantity: string,
  vat?: number,
}

export const manualInputDefaults: ManualInputFields = {
  costPrice: '0.0000',
  sellPrice: '',
  quantity: '0',
  vat: undefined,
};
