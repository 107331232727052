import React, { useEffect, useState } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {
  EmailSchema,
  ErrorsEnum,
  MobileSchema,
  RequiredFieldSchema,
  TelephoneSchema,
} from '../../../../../../core/enums/errorsEnum';
import {
  DEFAULT_DATE_FORMAT,
  DEFAULT_UI_DATE_FORMAT,
} from '../../../../../../core/utils/regex';
import Input from '../../../../../../core/components/input/Input';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import DatePickerComponent from '../../../../../../core/components/date-picker/DatePicker';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { employeeDetailsSelector, employeesGendersSelector } from '../../../../../../store/selectors/employeesSelector';
import Select from '../../../../../../core/components/select/Select';
import { clearOtherFieldsErrorFn } from '../../../../../../core/utils/clearOtherFieldsErrorFn';
import {
  EmployeeResponseDto,
  PartialEmployeeResponseDto,
} from '../../../../types/employeeViewTypes';
import { patchEmployeeThunk } from '../../../../../../store/thunks/employee/employeeViewPageThunk';
import { compareValuesHandle } from '../../../../../../core/utils/getDifferenceBetweenObjects';
import { utcDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { AddressFields, initAddressFields } from '../../../../../../common/types/commonTypes';
import AddressField from '../../../../../../common/components/address/AddressField';
import AddressModal from '../../../../../../common/components/address/AddressModal';
import { useFetchCurrentTabData } from '../../../../hooks/useFetchCurrentTabData';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import {
  employeeDefaults, EmployeeFields,
  NameSchema,
  NationalInsuranceNumberSchema,
  TitleSchema,
} from '../../../../utils/EmployeeSchema';

const EmployeeDetailsForm: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const employeesGenders = useAppSelector(employeesGendersSelector);
  const allowedToEdit = usePermission(PermissionEnum.EmployeeEditFields);
  const employeeDetails = useAppSelector(employeeDetailsSelector);
  const employee = employeeDetails?.employee;
  const isEmployeeLinkedToAnUser = employee?.linkedAccountId;
  const [isAddressModal, setIsAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [isConfirmation, setIsConfirmation] = useState(false);

  const {
    control, setValue, setError, clearErrors, watch,
  } = useForm<EmployeeFields>({
    defaultValues: employeeDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const telephone = watch('telephone');
  const mobile = watch('mobile');
  const { firstName, lastName } = watch();

  useEffect(() => {
    if (employee) {
      clearErrors();
      setValue('firstName', employee.firstName || '');
      setValue('lastName', employee.lastName || '');
      setValue('title', employee.title || '');
      setValue('birthday', employee.birthday);
      setValue('gender', employee.gender);
      setValue('nationalInsuranceNumber', employee.nationalInsuranceNumber || '');
      setValue('email', employee.email || '');
      setValue('telephone', employee.telephone);
      setValue('mobile', employee.mobile || '');
      setAddressFields({
        addressLine1: employee.addressLine1,
        addressLine2: employee.addressLine2,
        cityTown: employee.cityTown,
        region: employee.region,
        postalCode: employee.postalCode,
      });
    }
    // eslint-disable-next-line
  }, [employee]);

  const { fetchCurrentTabData, tab } = useFetchCurrentTabData();

  useEffect(() => {
    clearErrors();
    // eslint-disable-next-line
  }, [tab]);

  const changeDetails = (
    data: PartialEmployeeResponseDto,
    key: keyof PartialEmployeeResponseDto,
    deepCompare?: boolean,
    isOptional?: boolean,
  ) => {
    if (employee && id) {
      const handler = () => {
        dispatch(patchEmployeeThunk({
          employeeId: +id,
          data,
          onClose: fetchCurrentTabData,
          setError,
        }));
      };
      compareValuesHandle<PartialEmployeeResponseDto>(data, key, employee, handler, deepCompare, isOptional);
    }
  };

  const changeAddressDetails = (data: Partial<EmployeeResponseDto>) => {
    if (employee && id) {
      const keysNotEqual = Object.keys(data)
        .some((key) => {
          return employee[key as keyof EmployeeResponseDto] !== data[key as keyof EmployeeResponseDto];
        });
      if (keysNotEqual) {
        dispatch(patchEmployeeThunk({
          employeeId: +id,
          data,
          onClose: () => {
            setIsAddressModal(false);
            fetchCurrentTabData();
          },
        }));
      } else {
        setIsAddressModal(false);
      }
    }
  };

  const confirmNameChange = () => {
    let data = null;

    if (firstName !== employee?.firstName) {
      data = { firstName: firstName.trim() };
    }
    if (lastName !== employee?.lastName) {
      data = { lastName: lastName.trim() };
    }
    id && data && dispatch(patchEmployeeThunk({
      employeeId: +id,
      data,
      onClose: () => {
        fetchCurrentTabData();
        setIsConfirmation(false);
      },
      setError,
    }));
  };

  const clearOtherErrorsHandle = (field: string) => {
    const fieldsToValidate = ['firstName', 'lastName', 'birthday', 'gender', 'nationalInsuranceNumber', 'email', 'telephone', 'mobile'];
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, employee, setValue);
  };

  return (
    <>
      <AddressModal
        title="Address"
        isVisible={isAddressModal}
        onCancel={() => setIsAddressModal(false)}
        addressFields={addressFields}
        onSubmit={(values) => {
          changeAddressDetails({
            addressLine1: values.addressLine1?.trim() || '',
            addressLine2: values.addressLine2?.trim() || '',
            cityTown: values.cityTown?.trim() || '',
            postalCode: values.postalCode?.trim() || '',
            region: values.region?.trim() || '',
          });
        }}
        isOptionalFields={false}
        isExtendedFields={false}
      />
      <ConfirmationModal
        isVisible={isConfirmation}
        onCancel={() => {
          setIsConfirmation(false);
          setValue('firstName', employee?.firstName || '');
          setValue('lastName', employee?.lastName || '');
        }}
        confirmHandler={confirmNameChange}
        customText="A new value will be saved in both user and employee records."
        customProceedText="Do you want to save a new value?"
        customTitle="Confirm"
        customCreateLabel="Save new value"
      />
      <form className="details-form info-grid__column">
        <Controller
          name="firstName"
          control={control}
          rules={NameSchema}
          render={({ field }) => (
            <Input
              label="First name"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value);
                clearOtherErrorsHandle(field.name);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value.trim()) {
                  setError(field.name, { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue(field.name, '');
                } else {
                  if (!errors.firstName) {
                    if (isEmployeeLinkedToAnUser) {
                      const areValuesDifferent = value.trim() !== employeeDetails?.employee.firstName;
                      areValuesDifferent && setIsConfirmation(true);
                    } else {
                      changeDetails({ firstName: value.trim() }, field.name);
                    }
                  }
                }
              }}
              error={errors.firstName?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={NameSchema}
          render={({ field }) => (
            <Input
              label="Last name"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value);
                clearOtherErrorsHandle(field.name);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value.trim()) {
                  setError(field.name, { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue(field.name, '');
                } else {
                  if (!errors.lastName) {
                    if (isEmployeeLinkedToAnUser) {
                      const areValuesDifferent = value.trim() !== employeeDetails?.employee.lastName;
                      areValuesDifferent && setIsConfirmation(true);
                    } else {
                      changeDetails({ lastName: value.trim() }, field.name);
                    }
                  }
                }
              }}
              error={errors.lastName?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="title"
          control={control}
          rules={TitleSchema}
          render={({ field }) => (
            <Input
              label="Title"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value);
                clearOtherErrorsHandle(field.name);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                !errors.title && changeDetails({ title: value.trim() }, field.name, false, true);
              }}
              error={errors.title?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="birthday"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DatePickerComponent
              label="Date of birth"
              value={field.value ? moment(field.value) : undefined}
              onChange={(value) => {
                if (value) {
                  const formatted = moment(value).format(DEFAULT_DATE_FORMAT);
                  field.onChange(formatted);
                  clearOtherErrorsHandle(field.name);
                  !errors.birthday && changeDetails({ birthday: utcDateFormatHandler('', formatted) }, field.name);
                }
              }}
              error={errors.birthday?.message}
              format={DEFAULT_UI_DATE_FORMAT}
              disabledDate={(currentDate) => {
                const amountYearsFromDate = moment().subtract(120, 'years');
                const tooEarly = moment(currentDate).add(+1, 'days') <= amountYearsFromDate;
                const tooLate = currentDate > moment().subtract(10, 'years');
                return tooEarly || tooLate;
              }}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="gender"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              label="Gender"
              value={field.value}
              onChange={(value) => {
                field.onChange(value);
                clearOtherErrorsHandle(field.name);
                !errors.gender && changeDetails({ gender: value as number }, field.name);
              }}
              options={employeesGenders}
              error={errors.gender?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="nationalInsuranceNumber"
          control={control}
          rules={NationalInsuranceNumberSchema}
          render={({ field }) => (
            <Input
              label="N.I. number"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value.toUpperCase());
                clearOtherErrorsHandle(field.name);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                !errors.nationalInsuranceNumber && changeDetails({ nationalInsuranceNumber: value.trim() }, field.name);
              }}
              error={errors.nationalInsuranceNumber?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <AddressField
          title="Address"
          addressFields={addressFields}
          setAddressModal={() => {
            setIsAddressModal(true);
            clearOtherErrorsHandle('address');
          }}
          isIconVisible={Object.values(addressFields).some((el) => el)}
          disabled={!allowedToEdit}
        />
        <Controller
          name="email"
          control={control}
          rules={EmailSchema}
          render={({ field }) => (
            <Input
              label="Email"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                field.onChange(value);
                clearOtherErrorsHandle(field.name);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                if (!value.trim()) {
                  setError(field.name, { type: 'required', message: ErrorsEnum.REQUIRED });
                  setValue(field.name, '');
                } else {
                  !errors.email && changeDetails({ email: value.trim() }, field.name);
                }
              }}
              error={errors.email?.message}
              className="details-form__field--lg"
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="telephone"
          control={control}
          rules={TelephoneSchema}
          render={({ field }) => (
            <Input
              label="Telephone"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                clearOtherErrorsHandle('telephone');
                field.onChange(value);
              }}
              onBlur={(e) => {
                const { value } = e.target;
                !errors.telephone && changeDetails(
                  { telephone: value.trim() },
                  field.name,
                  false,
                  !!mobile,
                );
              }}
              error={errors.telephone?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
        <Controller
          name="mobile"
          control={control}
          rules={MobileSchema}
          render={({ field }) => (
            <Input
              label="Mobile"
              value={field.value}
              onChange={(e) => {
                const { value } = e.target;
                clearOtherErrorsHandle('mobile');
                field.onChange(value);
              }}
              onBlur={(e) => {
                const value = e.target.value.trim();
                if (!value) {
                  setValue('mobile', '');
                  setError('mobile', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  !errors.mobile && changeDetails(
                    { mobile: value },
                    field.name,
                    false,
                    !!telephone,
                  );
                }
              }}
              error={errors.mobile?.message}
              disabled={!allowedToEdit}
            />
          )}
        />
      </form>
    </>
  );
};

export default EmployeeDetailsForm;
