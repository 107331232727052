import { useParams } from 'react-router-dom';
import { useMemo, useState } from 'react';
import {
  setOrderPartReqDraftLinesFilters,
  setOrderPartRequestParts,
  setOrderPartsKitDraftLinesFilters, setOrderPartsKitRequestParts,
} from '../../../store/slices/ordersSlice';
import {
  CustomLineReqType,
  DraftLinePartsFiltersType, DraftLinePartsKitFiltersType,
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters,
} from '../../../common/types/commonTypes';
import { initDataList, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  addCustomLineToOrderThunk,
  addPartsKitLineToOrderThunk,
  getPartsKitsToOrderThunk,
  getStockPartsToOrderThunk,
} from '../../../store/thunks/orders/orderPartRequestThunks';
import {
  isOrderVehManualSelector,
  orderDetailsIsJobTypeSupplySelector,
  orderDetailsVehicleSelector, partRequestLinesSelector,
} from '../../../store/selectors/ordersSelector';
import { activePartsKitStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import { orderDetailsSelector } from '../../../store/selectors/sharedSelectors';
import { currentBusinessAreaSelector } from '../../../store/selectors/coreSelectors';
import { getPartProductSourceDictionaryThunk } from '../../../store/thunks/shared/sharedThunks';

export const useAddPartRequestLine = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [editedLine, setEditedLine] = useState<number | undefined>(undefined);
  const [sourceName, setSourceName] = useState<string>('');
  const [sourceId, setSourceId] = useState<number | undefined>(undefined);
  const [prodSourceModal, setProdSourceModal] = useState(false);
  const [childLineMode, setChildLineMode] = useState(false);

  const [newLineReasonModal, setNewLineReasonModal] = useState(false);
  const [newLineReasonData, setNewLineReasonData] = useState<{
    partsKitId?: number,
    customLineValues?: CustomLineReqType,
    closeModal: VoidFunctionType,
  } | null>(null);

  const currentBA = useAppSelector(currentBusinessAreaSelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const { items: partRequestLines } = useAppSelector(partRequestLinesSelector);
  const isOrderVehManual = useAppSelector(isOrderVehManualSelector);
  const isJobTypeSupply = useAppSelector(orderDetailsIsJobTypeSupplySelector);
  const orderDetailsVehicle = useAppSelector(orderDetailsVehicleSelector);
  const activePartsKitStatus = useAppSelector(activePartsKitStatusSelector);

  const partRequestId = orderDetails?.order?.partRequestId;

  const currentInitLine = useMemo(() => partRequestLines.find((el) => el.id === editedLine), [editedLine, partRequestLines]);

  const setInitFiltersAndTableData = () => {
    dispatch(setOrderPartReqDraftLinesFilters(initDraftLinePartsFilters));
    dispatch(setOrderPartRequestParts(initDataList));
    dispatch(setOrderPartsKitDraftLinesFilters(initDraftLinePartsKitFilters));
    dispatch(setOrderPartsKitRequestParts(initDataList));
  };

  const setProdSourceModalHandle = (partId: number, name: string) => {
    setSourceName(name);
    setSourceId(partId);
    orderDetails?.order && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: orderDetails.order.businessArea,
        customerId: orderDetails.order.customerId,
        priceDate: orderDetails.order.orderDate,
      },
      openModal: () => setProdSourceModal(true),
    }));
  };

  const fetchParts = (filters: DraftLinePartsFiltersType, isShow: boolean) => {
    let vehicleIds: number[] | undefined;
    if (!isOrderVehManual && !isJobTypeSupply && orderDetailsVehicle?.vehicleProfileId) {
      vehicleIds = [orderDetailsVehicle?.vehicleProfileId];
    } else if (!isOrderVehManual && isJobTypeSupply) {
      vehicleIds = (isShow && orderDetailsVehicle?.vehicleProfileId) ? [orderDetailsVehicle?.vehicleProfileId] : undefined;
    } else {
      vehicleIds = undefined;
    }
    const values = {
      keyword: filters.keyword,
      isCorePart: filters.isCorePart,
      page: filters.page,
      pageSize: filters.pageSize,
      order: filters.order,
      partCode: filters.partCode,
      manufacturerCode: filters.manufacturerCode,
      name: filters.name,
      description: filters.description,
      businessAreas: currentBA ? [currentBA] : undefined,
      vehicleIds,
    };
    dispatch(getStockPartsToOrderThunk({ filters: values }));
  };

  const fetchPartsKitParts = (values: DraftLinePartsKitFiltersType, isShow: boolean) => {
    let vehicleIds: number[] | undefined;
    if (!isOrderVehManual && !isJobTypeSupply && orderDetailsVehicle?.vehicleProfileId) {
      vehicleIds = [orderDetailsVehicle?.vehicleProfileId];
    } else if (!isOrderVehManual && isJobTypeSupply) {
      vehicleIds = (isShow && orderDetailsVehicle?.vehicleProfileId) ? [orderDetailsVehicle?.vehicleProfileId] : undefined;
    } else {
      vehicleIds = undefined;
    }
    const newValues = {
      page: values.page,
      pageSize: values.pageSize,
      order: values.order,
      keyword: values.keyword,
      statuses: activePartsKitStatus ? [activePartsKitStatus] : undefined,
      description: values.description,
      partsKitCode: values.partsKitCode,
      name: values.name,
      businessAreas: currentBA ? [currentBA] : undefined,
      vehicleIds,
    };
    dispatch(getPartsKitsToOrderThunk({ filters: newValues }));
  };

  const createPartsKitLineThunk = (partsKitId: number, closeModal: VoidFunctionType, reason: string) => {
    if (!newLineReasonModal) {
      setNewLineReasonData({ partsKitId, closeModal });
      setNewLineReasonModal(true);
    } else {
      partRequestId && id && dispatch(addPartsKitLineToOrderThunk({
        partRequestId,
        data: { partsKitId, orderId: +id, reason: reason.trim() || null },
        closeModal: () => {
          closeModal();
          setNewLineReasonModal(false);
          setNewLineReasonData(null);
        },
      }));
    }
  };
  const createCustomHandle = (values: CustomLineReqType, closeModal: VoidFunctionType, reason: string) => {
    if (!newLineReasonModal) {
      setNewLineReasonData({ customLineValues: values, closeModal });
      setNewLineReasonModal(true);
    } else {
      partRequestId && dispatch(addCustomLineToOrderThunk({
        partRequestId,
        data: { ...values, reason: reason.trim() || null },
        closeModal: () => {
          closeModal();
          setNewLineReasonModal(false);
          setNewLineReasonData(null);
        },
      }));
    }
  };

  const initCustomLine = currentInitLine
    ? {
      productName: currentInitLine.productName,
      quantity: currentInitLine.quantity,
      unitOfMeasure: currentInitLine.unitOfMeasure,
      unitPrice: currentInitLine.unitPriceString,
      tax: currentInitLine.tax,
      quoteCategoryId: currentInitLine.categoryId,
      weight: currentInitLine.weight,
    }
    : undefined;

  return {
    editedLine,
    setEditedLine,
    sourceName,
    setSourceName,
    sourceId,
    setSourceId,
    prodSourceModal,
    setProdSourceModal,
    setProdSourceModalHandle,
    initCustomLine,
    setInitFiltersAndTableData,
    isOrderVehManual,
    isJobTypeSupply,
    fetchParts,
    fetchPartsKitParts,
    createPartsKitLineThunk,
    createCustomHandle,
    childLineMode,
    setChildLineMode,
    newLineReasonModal,
    setNewLineReasonModal,
    newLineReasonData,
  };
};
