import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType,
  CoreAttachmentDto,
  CoreAttachmentsFilters,
  initialCoreAttachmentsFilters,
  ResponseSingleResult,
  VoidFunctionType,
  GetAttachmentFileResponse,
  AxiosErrorResponse,
  GetAttachmentsResponse, emptyPaging,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { enquiryAPI } from '../../../api/enquiryApi';
import {
  setAttachmentsTableFilters,
  setEnqAttachments,
} from '../../slices/enquiriesSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { getEnquiryDetails } from './detailsThunks';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { RootState } from '../../store';

export const getEnquiryAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{ enquiryId: number, filters: CoreAttachmentsFilters }
>(
  'get/Enquiry/EnquiryAttachments',
  async ({ enquiryId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.getEnquiryAttachments(enquiryId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryAttachmentByIdThunk = createAsyncThunk<ResponseSingleResult<GetAttachmentFileResponse>,
{ enquiryId: number, attachmentId: number }>(
  'get/Enquiry/EnquiryAttachmentById',
  async ({ enquiryId, attachmentId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.getEnquiryAttachmentById(enquiryId, attachmentId);
      dispatch(setLoading(false));
      downloadCsv(response.data.data.uri);
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createEnquiryAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{
  enquiryId: number,
  list: CoreAttachListElType[],
  comment: string,
  onCancel: VoidFunctionType,
}
>('post/EnquiryAttachment', async ({
  enquiryId, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { enquiries } = getState() as RootState;
    const { enqAttachmentsFilters } = enquiries;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));

    const res = await enquiryAPI.postEnquiryAttachUploadUri(enquiryId, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const response = await enquiryAPI.postEnquiryAttach(enquiryId, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getEnquiryAttachmentsThunk({ enquiryId, filters: enqAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editEnquiryAttachmentCommentThunk = createAsyncThunk<ResponseSingleResult,
{ enquiryId: number, attachmentId: number, comment: string, onCancel: VoidFunctionType }
>(
  'patch/Enquiry/EnquiryAttachmentComment',
  async ({
    enquiryId, attachmentId, comment, onCancel,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.editEnquiryAttachmentComment(enquiryId, attachmentId, comment);
      const { enquiries: { enqAttachments } } = getState() as RootState;
      const newArr = enqAttachments.items.map((item: CoreAttachmentDto) => {
        if (item.id === attachmentId) {
          return { ...item, comment };
        } else return item;
      });
      dispatch(setEnqAttachments(newArr));
      onCancel();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEnquiryAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ enquiryId: number, attachmentId: number, closeModal: VoidFunctionType}>(
  'delete/Enquiry/EnquiryAttachment',
  async ({
    enquiryId, attachmentId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.deleteEnquiryAttachment(enquiryId, attachmentId);
      closeModal();
      dispatch(getEnquiryAttachmentsThunk({ enquiryId, filters: initialCoreAttachmentsFilters }));
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(setAttachmentsTableFilters(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteMultipleEnquiryAttachmentThunk = createAsyncThunk<null, {
  attachmentIds: number[],
  enquiryId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/Enquiry/MultipleEnquiryAttachment',
  async ({ attachmentIds, enquiryId, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries: { enqAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await enquiryAPI.getEnquiryAttachments(enquiryId, {
        ...enqAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = attachmentIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await enquiryAPI.deleteMultipleEnquiryAttachment(enquiryId, filteredIds);
      }
      dispatch(setAttachmentsTableFilters({ ...enqAttachmentsFilters, page: 1 }));
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      closeModal();
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
