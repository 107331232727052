import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  allowedJobTypesForUserSelector,
  customerTypesSelector,
  linksTypesSelector,
  linkTypesValuesSelector,
  partRequestLinePriceSourcesSelector,
  priorityLevelsSelector,
  QuoteInfoSourcesSelector,
  rectificationTypesSelector,
  rectificationWarningsSelector,
  supplyOnlyJobTypesSelector,
  vehicleInfoSourcesSelector,
} from './coreSelectors';
import { RectificationStatusesEnum } from '../../rectifications/dashboard/enums/rectificationEnums';
import { QuoteInfoSourcesUiNames, VehicleInfoSourcesUiNames } from '../../core/enums/dictionariesEnums';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import { linksReformHandler, linksUiViewHandle } from '../../core/utils/linksReformHandler';
import { partCategoriesLookupSelector, rectificationDetailsSelector } from './sharedSelectors';
import { RectificationPartReqLinesOrdering } from '../../rectifications/rectification-view-page/enums/RectificationViewEnums';
import { summaryFieldValueHandle } from '../../core/utils/summaryFieldValueHandle';
import {
  forbiddenStatusesToLinkVehicleSelector,
  jobInitUIStatusesSelector,
  jobStatusesSelector, orderStatusSelector,
  partRequestLineDeletedStatusSelector, partRequestLineStatusesSelector,
  rectificationStatusesSelector, vehicleStatusesSelector,
} from './coreStatusesSelectors';
import { stockPartRequestLinesOrderingSelector } from './coreOrderingSelectors';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { PartReqLineUIType } from '../../common/types/commonTypes';
import { initRequisite } from '../../common/utils/data';

export const rectificationDetailsVehicleSelector = createSelector(rectificationDetailsSelector, (details) => {
  const rectification = details?.rectification;
  return {
    vehicleStatus: rectification?.vehicleStatus,
    vehicleInfoSource: rectification?.vehicleInfoSource,
    manufacturer: rectification?.manufacturer,
    modelDescription: rectification?.modelDescription,
    slidingDoorsQuantity: rectification?.slidingDoorsQuantity,
    grossVehicleWeightKg: rectification?.grossVehicleWeightKg,
    maxPayload: rectification?.maxPayload,
    regNumber: rectification?.regNumber,
    chassisNumber: rectification?.chassisNumber,
    vehicleId: rectification?.vehicleId,
    vehicleCompanyName: rectification?.vehicleCompanyName,
    vehicleAddressLine1: rectification?.vehicleAddressLine1,
    vehicleAddressLine2: rectification?.vehicleAddressLine2,
    vehicleCityTown: rectification?.vehicleCityTown,
    vehicleRegion: rectification?.vehicleRegion,
    vehiclePostalCode: rectification?.vehiclePostalCode,
    vehicleTelephone: rectification?.vehicleTelephone,
    vehicleProfileId: rectification?.vehicleProfileId,
  };
});

export const isRectificationVehManualSelector = createSelector(
  vehicleInfoSourcesSelector,
  rectificationDetailsVehicleSelector,
  (sources, veh) => {
    const manualSource = sources.find((el) => el.label === VehicleInfoSourcesUiNames.ManualInput);
    return manualSource?.value === veh.vehicleInfoSource;
  },
);
export const rectificationVehUiDataSelector = createSelector(
  rectificationDetailsVehicleSelector,
  (vehicle) => {
    const manufacturer = vehicle.manufacturer ? `${vehicle.manufacturer} ` : '';
    const modelDescription = vehicle.modelDescription ? `${vehicle.modelDescription} ` : '';

    const {
      vehicleStatus,
      regNumber,
      chassisNumber,
    } = vehicle;
    const vehicleLocationDraft = {
      vehicleCompanyName: vehicle.vehicleCompanyName,
      vehicleAddressLine1: vehicle.vehicleAddressLine1,
      vehicleAddressLine2: vehicle.vehicleAddressLine2,
      vehicleCityTown: vehicle.vehicleCityTown,
      vehicleRegion: vehicle.vehicleRegion,
      vehiclePostalCode: vehicle.vehiclePostalCode,
      vehicleTelephone: vehicle.vehicleTelephone,
    };

    return {
      vehicleId: vehicle.vehicleId,
      name: manufacturer,
      info: modelDescription,
      vehicleStatus,
      regNumber,
      chassisNumber,
      vehicleLocation: summaryFieldValueHandle(Object.values(vehicleLocationDraft)),
    };
  },
);

export const isRectificationJobTypeSupplySelector = createSelector(
  rectificationDetailsSelector,
  supplyOnlyJobTypesSelector,
  (details, supplyOnlyJobTypes) => {
    return supplyOnlyJobTypes.includes(details?.rectification?.jobType);
  },
);

const rectificationDashAssigneesInitSelector = (state: RootState) => state.rectifications.rectificationsAssignees;
export const rectificationDashAssigneesSelector = createSelector(rectificationDashAssigneesInitSelector, (dashAssignees) => {
  return dashAssignees.map((el) => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  }));
});

export const rectificationNewStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.New)?.value;
});

export const rectificationDetailsReviewStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.DetailsReview)?.value;
});
export const rectificationRejectedByCustomerStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.RejectedByCustomer)?.value;
});
export const rectificationReasonReviewStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.ReasonReview)?.value;
});
export const rectificationAcceptedByCustomerStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.AcceptedByCustomer)?.value;
});
export const rectificationInvoicingStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((status) => status.label === RectificationStatusesEnum.Invoicing)?.value;
});
export const rectificationCompletedStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((st) => st.label === RectificationStatusesEnum.Completed)?.value;
});
export const rectificationCancelledStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((st) => st.label === RectificationStatusesEnum.Cancelled)?.value;
});

export const isRectificationInReadModeSelector = createSelector(
  rectificationCancelledStatusSelector,
  rectificationCompletedStatusSelector,
  rectificationDetailsSelector,
  (cancelled, completed, details) => {
    const rectification = details?.rectification;
    const status = rectification?.status;
    return (status === cancelled) || (status === completed);
  },
);

export const isRectificationHasCancelledStatusSelector = createSelector(
  rectificationCancelledStatusSelector,
  rectificationDetailsSelector,
  (cancelled, details) => {
    const rectification = details?.rectification;
    const status = rectification?.status;
    return status === cancelled;
  },
);

export const isRectificationXeroModeSelector = createSelector(
  rectificationAcceptedByCustomerStatusSelector,
  rectificationInvoicingStatusSelector,
  rectificationCompletedStatusSelector,
  rectificationDetailsSelector,
  (acceptedByCustomer, invoicing, completed, details) => {
    const rectification = details?.rectification;
    const xeroStatuses = [acceptedByCustomer, invoicing, completed];
    return rectification?.status && xeroStatuses.includes(rectification.status);
  },
);

export const rejectionReasonModeSelector = createSelector(
  rectificationRejectedByCustomerStatusSelector,
  rectificationReasonReviewStatusSelector,
  rectificationCancelledStatusSelector,
  rectificationDetailsSelector,
  (rejectedByCustomer, reasonReview, cancelled, details) => {
    const rectification = details?.rectification;
    const isStatusRejectedByCustomer = rectification?.status === rejectedByCustomer;
    const isStatusReasonReview = rectification?.status === reasonReview;
    const isStatusCancelled = rectification?.status === cancelled;
    return {
      rejectionReasonMode: rectification?.status && (isStatusRejectedByCustomer || isStatusReasonReview || isStatusCancelled),
      isStatusRejectedByCustomer,
    };
  },
);

export const rectificationJobPartsStatusesListSelector = createSelector(
  rectificationDetailsSelector,
  jobInitUIStatusesSelector,
  partRequestLineStatusesSelector,
  partRequestLineDeletedStatusSelector,
  (details, jobStatuses, partReqStatuses, deletedStatusValue) => {
    const rectification = details?.rectification;
    const jobStatusesWithValue = rectification?.jobStatusCounts?.filter((el) => el.count);
    const jobStatusesUi = jobStatusesWithValue?.map((item) => ({
      label: jobStatuses.find((jobStatus) => item.status === jobStatus.value)?.label || '-',
      value: item.count,
    })) || [];
    const partRequestStatusesList = details?.partRequestLineStatusCounts ?? [];
    const statusesListWithoutDeleted = partRequestStatusesList.filter((el) => el.status !== deletedStatusValue);
    const statusesWithValue = statusesListWithoutDeleted.filter((el) => el.count);
    const partsReqStatusesUi = statusesWithValue.map((item) => ({
      label: partReqStatuses.find((partReqStatus) => item.status === partReqStatus.value)?.label || '-',
      value: item.count,
    }));
    return {
      jobStatusesUi,
      partsReqStatusesUi,
    };
  },
);

export const rectificationsDashFiltersSelector = (state: RootState) => state.rectifications.filters;
export const rectificationsDashFiltersListSelector = createSelector(
  rectificationsDashFiltersSelector,
  priorityLevelsSelector,
  rectificationDashAssigneesSelector,
  allowedJobTypesForUserSelector,
  rectificationTypesSelector,
  rectificationWarningsSelector,
  (
    values,
    priorityLevels,
    users,
    jobTypes,
    rectificationTypes,
    warnings,
  ) => {
    return [
      {
        value: values.priority || [],
        name: 'priority',
        label: 'Priority',
        options: priorityLevels,
      },
      {
        value: values.assignedTo || [],
        name: 'assignedTo',
        label: 'Assigned to',
        options: users,
      },
      {
        value: values.jobType || [],
        name: 'jobType',
        label: 'Job type',
        options: jobTypes,
      },
      {
        value: values.rectificationType || [],
        name: 'rectificationType',
        label: 'Rectification type',
        options: rectificationTypes,
      },
      {
        value: values.warnings || [],
        name: 'warnings',
        label: 'Warnings',
        options: warnings,
      },
    ];
  },
);

export const rectificationColumnsPaginationSelector = (state: RootState) => state.rectifications.columnsPagination;
export const rectificationCollectionsSelector = (state: RootState) => state.rectifications.rectificationCollections;
export const sortedRectificationCollectionsSelector = createSelector(rectificationCollectionsSelector, (collections) => {
  const newArr = collections ? [...collections] : null;
  return newArr?.sort((a, b) => a.order - b.order) || null;
});

export const rectificationDetailsRequisitesSelector = createSelector(
  rectificationDetailsSelector,
  (details) => ({
    billing: details?.billingRequisite ?? initRequisite,
    shipping: details?.shippingRequisite ?? initRequisite,
    isShippingSame: details?.rectification.isShippingRequisiteSameAsBilling ?? false,
  }),
);
export const rectificationDetailsHeadSelector = createSelector(
  rectificationDetailsSelector,
  customerTypesSelector,
  (details, customerTypes) => {
    const rectification = details?.rectification;
    const customerType = customerTypes.find((el) => el.value === rectification?.customerType)?.label;
    return [
      { label: 'Rectification No', value: rectification?.rectificationNumber || '-' },
      { label: 'Customer', value: rectification?.customerName },
      { label: 'Customer account No', value: rectification?.customerAccountNo || '-' },
      { label: 'Customer type', value: customerType },
      { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', rectification?.createdDate || '') },
      { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', rectification?.modifiedDate || '') },
    ];
  },
);

export const rectificationStatusTransitionsListSelectors = createSelector(
  rectificationStatusesSelector,
  rectificationDetailsSelector,
  (statuses, rectificationDetails) => {
    const details = rectificationDetails?.rectification;
    const currentStatus: number | undefined = details?.status;
    const currentStatusLabel = statuses?.find((el) => el.value === currentStatus)?.label || '';
    const currentStatusItem = { label: currentStatusLabel, value: currentStatus || '' };
    const availableStatuses = statuses.filter((st) => details?.statusTransitions?.includes(st.value));
    return (availableStatuses && currentStatus)
      ? [...availableStatuses, currentStatusItem]
      : [];
  },
);

export const rectificationAttachmentsSelector = (state: RootState) => state.rectifications.rectificationAttachments;
export const rectificationAttachmentsFiltersSelector = (state: RootState) => state.rectifications.rectificationAttachmentsFilters;

export const rectificationLinksFiltersSelector = (state: RootState) => state.rectifications.rectificationLinksFilters;
export const rectificationLinksSelector = (state: RootState) => state.rectifications.rectificationLinks;

export const rectificationsLinksRowSelector = createSelector(
  rectificationLinksSelector,
  linksTypesSelector,
  orderStatusSelector,
  jobStatusesSelector,
  vehicleStatusesSelector,
  linkTypesValuesSelector,
  (
    data,
    types,
    orderStatuses,
    jobStatuses,
    vehicleStatuses,
    linkTypes,
  ) => {
    const { orderLinkType, vehicleLinkType } = linkTypes;
    const inheritanceArr = linksReformHandler(data);
    const statusLabel = (status: string | number, type?: number) => {
      if (type === orderLinkType) {
        return orderStatuses.find((st) => st.value === status)?.label || status;
      } if (type === vehicleLinkType) {
        return vehicleStatuses.find((st) => st.value === status)?.label || status;
      } else return jobStatuses.find((st) => st.value === status)?.label || status;
    };
    return linksUiViewHandle(inheritanceArr, statusLabel, types);
  },
);

export const rectificationContactsSelector = (state: RootState) => state.rectifications.rectificationContacts;
export const rectificationContactsPagingSelector = (state: RootState) => state.rectifications.rectificationContactsPaging;
export const rectificationGenerateEmailContactsSelector = (state: RootState) => state.rectifications.rectificationGenerateEmailContacts;
export const rectificationGenerateEmailContactsPagingSelector = (state: RootState) => {
  return state.rectifications.rectificationGenerateEmailContactsPaging;
};
export const rectificationExistingContactsSelector = (state: RootState) => state.rectifications.rectificationExistingContacts;
export const rectificationExistingContactsPagingSelector = (state: RootState) => state.rectifications.rectificationExistingContactsPaging;

export const rectificationTimelineSelector = (state: RootState) => state.rectifications.rectificationTimeline;
export const rectificationTimelineFiltersSelector = (state: RootState) => state.rectifications.rectificationTimelineFilters;

export const currentRectificationPartReqSourceSelector = (state: RootState) => state.rectifications.currentPartReqSource;
export const currentSourceTypeSelector = createSelector(
  QuoteInfoSourcesSelector,
  currentRectificationPartReqSourceSelector,
  (sources, currentSource) => {
    return {
      isCore: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.CorePart,
      isNonCore: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.NonCorePart,
      isCustom: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.Custom,
      isPartsKit: sources?.find((el) => el.value === currentSource)?.label === QuoteInfoSourcesUiNames.PartsKit,
    };
  },
);

export const isRectificationInNewStatusSelector = createSelector(
  rectificationDetailsSelector,
  rectificationStatusesSelector,
  (details, statuses) => {
    const currentStatus = details?.rectification?.status;
    const newStatus = statuses.find((el) => el.label === RectificationStatusesEnum.New)?.value;

    return currentStatus === newStatus;
  },
);

export const blockedSubmitRectificationAndLinesActionsStatusesSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  const readyForPostCheck = statuses.find((el) => el.label === RectificationStatusesEnum.ReadyForPostCheck)?.value;
  const postCheckPassed = statuses.find((el) => el.label === RectificationStatusesEnum.PostCheckPassed)?.value;
  const postCheckFailed = statuses.find((el) => el.label === RectificationStatusesEnum.PostCheckFailed)?.value;
  const acceptedByCustomer = statuses.find((el) => el.label === RectificationStatusesEnum.AcceptedByCustomer)?.value;
  const rejectedByCustomer = statuses.find((el) => el.label === RectificationStatusesEnum.RejectedByCustomer)?.value;
  const invoicing = statuses.find((el) => el.label === RectificationStatusesEnum.Invoicing)?.value;
  const completed = statuses.find((el) => el.label === RectificationStatusesEnum.Completed)?.value;
  const cancelled = statuses.find((el) => el.label === RectificationStatusesEnum.Cancelled)?.value;

  return [
    readyForPostCheck, postCheckPassed, postCheckFailed,
    acceptedByCustomer, rejectedByCustomer, invoicing,
    completed, cancelled,
  ];
});

export const blockedDeletionOfRectificationUsualLinesStatusesSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  const listToCheck = [
    RectificationStatusesEnum.ReadyForPostCheck,
    RectificationStatusesEnum.PostCheckPassed,
    RectificationStatusesEnum.PostCheckFailed,
    RectificationStatusesEnum.AcceptedByCustomer,
    RectificationStatusesEnum.RejectedByCustomer,
    RectificationStatusesEnum.Invoicing,
    RectificationStatusesEnum.Completed,
    RectificationStatusesEnum.Cancelled,
  ];
  return statuses.filter((st) => listToCheck.includes(st.label as RectificationStatusesEnum)).map((el) => el.value);
});

export const blockedDeletionOfRectificationCustomLinesStatusesSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  const listToCheck = [
    RectificationStatusesEnum.Completed,
    RectificationStatusesEnum.Cancelled,
  ];
  return statuses.filter((st) => listToCheck.includes(st.label as RectificationStatusesEnum)).map((el) => el.value);
});

export const rectificationPartReqLinesColumnsSelector = createSelector(
  stockPartRequestLinesOrderingSelector,
  partCategoriesLookupSelector,
  partRequestLinePriceSourcesSelector,
  partRequestLineStatusesSelector,
  rectificationDetailsSelector,
  (ordering, categories, priceSources, statuses, details) => {
    let newCols = [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
        sorter: true,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.OrdinalNumber, ordering),
      },
      {
        title: 'Category',
        dataIndex: 'categoryId',
        sorter: true,
        filters: categories,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.Category, ordering),
      },
      {
        title: 'Product No',
        dataIndex: 'productNumber',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.ProductNumber, ordering),
      },
      {
        title: 'Product name',
        dataIndex: 'productName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.ProductName, ordering),
      },
      {
        title: 'Notes',
        dataIndex: 'notes',
        filterSearch: true,
      },
      {
        title: 'Qty',
        dataIndex: 'quantity',
      },
      {
        title: 'Unit price',
        dataIndex: 'unitPrice',
        prefix: '£',
      },
      {
        title: 'Source',
        dataIndex: 'priceSources',
        filters: priceSources,
      },
      {
        title: 'Discount',
        dataIndex: 'discount',
        suffix: '%',
      },
      {
        title: 'Net price',
        dataIndex: 'netPrice',
        sorter: true,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.NetPrice, ordering),
        prefix: '£',
      },
      {
        title: 'Tax',
        dataIndex: 'tax',
        suffix: '%',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
        suffix: 'kg',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        sorter: true,
        filters: statuses,
        orderField: getSortIndex(RectificationPartReqLinesOrdering.Status, ordering),
      },
    ];
    if (!details?.rectification?.enableDiscounts) {
      newCols = newCols.filter((col) => col.dataIndex !== 'discount');
    }
    if (!details?.rectification?.enableNotes) {
      newCols = newCols.filter((col) => col.dataIndex !== 'notes');
    }
    return newCols;
  },
);
export const rectificationPartRequestLinesSelector = (state: RootState) => state.rectifications.partRequestLines;

export const rectificationPartReqUiLinesSelector = createSelector(
  rectificationPartRequestLinesSelector,
  partCategoriesLookupSelector,
  rectificationDetailsSelector,
  partRequestLineStatusesSelector,
  (lines, partCategories, details, statuses) => {
    let list: PartReqLineUIType[] = lines.items.map((el) => ({
      id: el.id,
      ordinalNumber: el.ordinalNumber,
      categoryId: partCategories?.find((cat) => cat.value === el.categoryId)?.label || el.categoryId,
      productNumber: el.productNumber || '-',
      productName: el.productName || '-',
      notes: el.notes || '-',
      quantity: el.quantity,
      unitPrice: el.unitPriceString || '0.00',
      priceSources: el.partsKitId ? [] : (el.priceSources || '-'),
      discount: el.discount || 0,
      netPrice: el.netPriceString || '0.00',
      tax: el.tax === null ? '-' : el.tax,
      weight: el.weight || 0,
      status: (el.partsKitId && el.childLines && el.childLines.length > 0)
        ? ' '
        : statuses.find((st) => el.status === st.value)?.label.toUpperCase() || el.status,
      partsKitId: el.partsKitId,
      partId: el.partId,
      partsKitComponentId: el.partsKitComponentId,
      partsKitComponentPartId: el.partsKitComponentPartId,
      unitOfMeasure: el.unitOfMeasure,
      isProductSuitable: el.isProductSuitable,
      isFree: el.isFree,
      childLines: el.childLines
        ? el.childLines.map((ch) => ({
          id: ch.id,
          ordinalNumber: `${el.ordinalNumber}.${ch.ordinalNumber}`,
          categoryId: partCategories?.find((cat) => cat.value === ch.categoryId)?.label || ch.categoryId,
          productNumber: ch.productNumber || '-',
          productName: ch.productName || '-',
          notes: ch.notes || '-',
          quantity: ch.quantity,
          unitPrice: ch.unitPriceString || '0.00',
          priceSources: ch.priceSources || '-',
          discount: ch.discount || 0,
          netPrice: ch.netPriceString || '0.00',
          tax: ch.tax === null ? '-' : ch.tax,
          weight: ch.weight || 0,
          status: statuses.find((st) => ch.status === st.value)?.label.toUpperCase() || ch.status,
          partsKitId: ch.partsKitId,
          partId: ch.partId,
          partsKitComponentId: ch.partsKitComponentId,
          partsKitComponentPartId: ch.partsKitComponentPartId,
          unitOfMeasure: ch.unitOfMeasure,
          isProductSuitable: ch.isProductSuitable,
          isFree: ch.isFree,
        }))
        : [],
    }));
    if (!details?.rectification?.enableDiscounts) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      list = list.map(({ discount, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ discount, ...rest }) => rest) };
      });
    }
    if (!details?.rectification?.enableNotes) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      list = list.map(({ notes, childLines, ...rest }) => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        return { ...rest, childLines: childLines.map(({ notes, ...rest }) => rest) };
      });
    }
    return list;
  },
);
export const rectificationPartRequestLinesFiltersSelector = (state: RootState) => state.rectifications.partRequestLinesFilters;

export const rectificationPartRequestPartsSelector = (state: RootState) => {
  return state.rectifications.rectificationDraftPartRequestParts;
};
export const rectificationPartReqDraftLinesFiltersSelector = (state: RootState) => {
  return state.rectifications.rectificationPartReqDraftLinesFilters;
};

export const rectificationDraftPartRequestPartsKitSelector = (state: RootState) => {
  return state.rectifications.rectificationDraftPartRequestPartsKit;
};
export const rectificationDraftPartRequestPartsKitFiltersSelector = (state: RootState) => {
  return state.rectifications.rectificationDraftPartRequestPartsKitFilters;
};

const rectificationSummaryInitSelector = (state: RootState) => state.rectifications.rectificationSummary;
export const rectificationSummarySectionDataSelector = createSelector(rectificationSummaryInitSelector, (summary) => {
  const leftPartData = [
    {
      value: summary?.grossVehicleWeightKg,
      label: 'Gross vehicle weight',
    },
    { value: summary?.conversionWeightKg, label: 'Conversion weight' },
    {
      value: summary?.maximumPayloadKg,
      label: 'Maximum payload',
    },
    {
      value: summary?.remainingPayloadKg,
      label: 'Remaining payload',
    },
  ];

  const rightPartData = [
    { value: summary?.net, label: 'Net' },
    { value: summary?.vat, label: 'VAT' },
    {
      value: summary?.discount,
      label: 'Discount',
    },
    { value: summary?.gross, label: 'Gross' },
  ];
  return { leftPartData, rightPartData };
});

export const createRectificationInitVehiclesListSelector = (state: RootState) => state.rectifications.createRectificationVehiclesList;
export const createRectificationVehiclesListSelector = createSelector(createRectificationInitVehiclesListSelector, (items) => {
  return items.map((item) => ({
    // eslint-disable-next-line max-len
    label: `${item.manufacturer || 'N/A'} / ${item.modelDescription || 'N/A'} / ${item.regNumber || 'N/A'} / ${item.chassisNumber || 'N/A'}`,
    value: item.id,
  }));
});

export const isRectificationStatusIsNotAllowedToLinkVehicle = createSelector(
  rectificationDetailsSelector,
  forbiddenStatusesToLinkVehicleSelector,
  (details, list) => {
    const currentStatus = details?.rectification?.status;
    return list.includes(currentStatus);
  },
);
