import React from 'react';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import {
  DateRangeTableFilterType, LinksFiltersType, PickerValues, SearchTableFilterType, StringFunctionType,
} from '../../../core/types/coreTypes';

type LinksColumnsProps = {
  totalCount: number,
  onChangeSearchHandle: (value: string, field: string) => void,
  searchPressHandle: StringFunctionType,
  searchVl: SearchTableFilterType,
  pickerValue: DateRangeTableFilterType,
  setPickerValue: (value: DateRangeTableFilterType) => void,
  pickerHandle: (field: string, values?: PickerValues) => void,
  tableFilters: LinksFiltersType,
}
const LinksColumns: React.FC<LinksColumnsProps> = ({
  totalCount,
  onChangeSearchHandle,
  searchPressHandle,
  searchVl,
  pickerValue,
  setPickerValue,
  pickerHandle,
  tableFilters,
}) => {
  const columns = [
    {
      title: 'Linked entity',
      dataIndex: 'entity',
      filterSearch: true,
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      isDateRange: true,
    },
    {
      title: 'Modified date',
      dataIndex: 'modifiedDate',
      isDateRange: true,
    },
  ];

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            pickerValue={pickerValue}
            setPickerValue={setPickerValue}
            pickerHandle={pickerHandle}
            totalElements={totalCount}
            disabled={totalCount === 0}
            tableFilters={tableFilters}
            className={`linksTableTh--${col.dataIndex}`}
          />
        ))}
      </Tr>
    </Thead>
  );
};

export default LinksColumns;
