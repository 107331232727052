import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ServerErrorType } from '../types/coreTypes';
import { setErrorMessage } from '../../store/slices/coreSlice';

export const postalCodeErrorHandle = (errors: ServerErrorType[], dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  const LIMIT_ERROR = 'PostalCodeLookupServiceReachedLimit';
  const UNAVAILABLE_ERROR = 'PostalCodeLookupServiceUnavailable';
  const errorKeys = [LIMIT_ERROR, UNAVAILABLE_ERROR];
  const postalCodeError = errors.find(({ key }) => errorKeys.includes(key));

  if (postalCodeError) {
    let message = '';
    if (postalCodeError.key === LIMIT_ERROR) {
      message = 'Your postal code lookup subscription has reached it\'s limit.';
    } else if (postalCodeError.key === UNAVAILABLE_ERROR) {
      message = 'Postal code lookup service is unavailable. Please try again later.';
    }
    dispatch(setErrorMessage({ message }));
  }
};
