import React from 'react';
import moment from 'moment';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const EmployeeFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'Birthday':
        return moment(value).format(DEFAULT_UI_DATE_FORMAT);
      default: return value;
    }
  };
  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default EmployeeFieldUpdateEvent;
