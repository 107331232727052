import { z } from 'zod';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const CommonVehicleCheck = z.object({
  mileageMiles: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED),
  fuelLevelPercent: z.number(),
});

export const CommonVehicleCheckSchema = CommonVehicleCheck.pick({
  mileageMiles: true,
  fuelLevelPercent: true,
});

export type CommonVehicleCheckFields = z.infer<typeof CommonVehicleCheckSchema>;

export const commonVehicleCheckDefaults: CommonVehicleCheckFields = {
  mileageMiles: '',
  fuelLevelPercent: 0,
};
