import React from 'react';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import './StockSearchTable.scss';
import StockSearchColumns from './StockSearchColumns';
import { StockFiltersType } from '../../types/stockSearchTypes';
import StockSearchBody from './StockSearchBody';
import { CustomAny } from '../../../../core/types/coreTypes';

type StockSearchTableProps = {
  filters: StockFiltersType,
  stockSearchResult: {items: CustomAny[] | undefined, pages: number, totalCount: number},
}

const StockSearchTable: React.FC<StockSearchTableProps> = ({ filters, stockSearchResult }) => {
  return (
    <TableNew>
      <StockSearchColumns filters={filters} resultTotal={stockSearchResult.totalCount} />
      <StockSearchBody stockSearchResult={stockSearchResult} />
    </TableNew>
  );
};

export default StockSearchTable;
