import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AffectedScheduleDateDto,
  EmployeeHolidaysFilters, EmployeeWorkCalendarFilters,
  GetHolidaysResponse, GetWorkCalendarResponse,
  PostHolidayRestModel, AffectedDatesAndPutEmployeeScheduleParams, GetDepartmentsResponse, GetJobTitlesResponse,
} from '../../../employees/work-calendar/types/EmployeeWorkCalendarTypes';
import { setFetching, setLoading } from '../../slices/coreSlice';
import {
  AxiosErrorResponse, BooleanFunctionType, ResponseResult, ResponseSingleResult, StringFunctionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { employeeAPI } from '../../../api/employeeApi';
import { RootState } from '../../store';
import { setEmployeeHolidaysFilters } from '../../slices/employeesSlice';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { EmployeeListItemDto, employeesFiltersType } from '../../../employees/dashboard/types/employeesDashTypes';

export const getEmployeeWorkCalendarThunk = createAsyncThunk<GetWorkCalendarResponse,
{ filters: EmployeeWorkCalendarFilters, scrollTop?: VoidFunctionType }
>(
  'get/EmployeeWorkCalendar',
  async ({ filters, scrollTop }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      scrollTop && scrollTop();
      const response = await employeeAPI.fetchEmployeeWorkCalendar(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeHolidays = createAsyncThunk<GetHolidaysResponse,
{ filters: EmployeeHolidaysFilters }
>(
  'get/EmployeeHolidays',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeHolidays(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
export const addEmployeeHoliday = createAsyncThunk<ResponseSingleResult<{ createdId: number }>,
{
  data: PostHolidayRestModel,
  clearInputs: VoidFunctionType,
  yearOfHoliday: number,
  setDateError: StringFunctionType,
}
>(
  'post/EmployeeHoliday',
  async ({
    data, clearInputs, yearOfHoliday, setDateError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.postEmployeeHoliday(data);
      clearInputs();
      const { employees: { employeeHolidaysFilters, workCalendarFilters } } = getState() as RootState;
      if (yearOfHoliday !== employeeHolidaysFilters.year) {
        dispatch(setEmployeeHolidaysFilters({ ...employeeHolidaysFilters, year: yearOfHoliday }));
      } else {
        dispatch(getEmployeeHolidays({ filters: employeeHolidaysFilters }));
      }
      dispatch(getEmployeeWorkCalendarThunk({ filters: workCalendarFilters }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      const dateError = errors.find((err) => err.key === 'Date');
      if (dateError) {
        setDateError(ErrorsEnum.UNIQUE_VALUE);
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEmployeeHoliday = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/EmployeeHoliday',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.deleteEmployeeHoliday(id);
      const { employees: { employeeHolidaysFilters, employeeHolidaysList, workCalendarFilters } } = getState() as RootState;
      dispatch(setEmployeeHolidaysFilters({
        ...employeeHolidaysFilters,
        year: employeeHolidaysList.length === 1 ? new Date().getFullYear() : employeeHolidaysFilters.year,
      }));
      dispatch(getEmployeeWorkCalendarThunk({ filters: workCalendarFilters }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEmployeeScheduleThunk = createAsyncThunk<ResponseSingleResult,
{ data: AffectedDatesAndPutEmployeeScheduleParams, closeModal: VoidFunctionType }
>(
  'put/EmployeeSchedules',
  async ({ data, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await employeeAPI.putEmployeeSchedules(data);
      dispatch(setFetching(false));
      const { employees: { workCalendarFilters } } = getState() as RootState;
      dispatch(getEmployeeWorkCalendarThunk({ filters: workCalendarFilters }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeesDepartmentsWithEmployeesCount = createAsyncThunk<ResponseSingleResult<GetDepartmentsResponse>>(
  'get/EmployeesDepartmentsWithEmployeesCount',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeesDepartmentsWithEmployeesCount();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeesJobTitlesWithEmployeesCount = createAsyncThunk<ResponseSingleResult<GetJobTitlesResponse>>(
  'get/EmployeesJobTitlesWithEmployeesCount',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeesJobTitlesWithEmployeesCount();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeAffectedDatesThunk = createAsyncThunk<ResponseSingleResult<
{ affectedScheduleDates: AffectedScheduleDateDto[] | null }>,
{ params: AffectedDatesAndPutEmployeeScheduleParams }
>(
  'get/EmployeeAffectedDates',
  async ({ params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeAffectedDates(params);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getAllEmployeesListThunk = createAsyncThunk<ResponseResult<EmployeeListItemDto[]>,
{ filters?: employeesFiltersType, setEmployeesSelectLoading?: BooleanFunctionType }
>(
  'get/AllEmployeesList',
  async ({ filters, setEmployeesSelectLoading }, { dispatch, rejectWithValue }) => {
    if (setEmployeesSelectLoading) {
      setEmployeesSelectLoading(true);
    } else dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeDashboard(filters);
      if (setEmployeesSelectLoading) {
        setEmployeesSelectLoading(false);
      } else dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      if (setEmployeesSelectLoading) {
        setEmployeesSelectLoading(false);
      } else dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
