import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, CoreAttachListElType, CoreAttachmentsFilters, emptyPaging, GetAttachmentsResponse,
  initialCoreAttachmentsFilters, ResponseSingleResult, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import {
  setPartsKitAttachments,
  setPartsKitAttachmentsFilter,
  setPartsKitDetails,
} from '../../../slices/partsKitSlice';
import { downloadCsv } from '../../../../core/utils/downloadFileHandler';
import { uploadAttachmentsAsyncHandle } from '../../../../common/utils/attachmentsHandlers';
import { RootState } from '../../../store';
import { getPartsKitById } from './partsKitsViewPageThunks';

export const getPartsKitAttachments = createAsyncThunk<GetAttachmentsResponse,
{
  partsKitId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/PartsKitAttachments',
  async ({ partsKitId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartsKitAttachments(partsKitId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartsKitAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, partsKitId: number, closeModal: VoidFunctionType }>(
  'delete/SinglePartsKitAttachment',
  async ({
    id, partsKitId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit } = getState() as RootState;
      const { partsKitDetails } = partsKit;
      const response = await stockApi.deletePartsKitAttachment(id, partsKitId);
      dispatch(getPartsKitAttachments({
        partsKitId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setPartsKitAttachmentsFilter(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      partsKitDetails && dispatch(setPartsKitDetails({
        ...partsKitDetails,
        attachmentsCount: partsKitDetails.attachmentsCount ? partsKitDetails.attachmentsCount - 1 : 0,
      }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartsKitAttachmentById = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, partsKitId: number }
>('get/PartsKitAttachmentById', async ({ id, partsKitId }, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await stockApi.getPartsKitAttachmentById(id, partsKitId);
    downloadCsv(response.data.data.uri);
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const createPartsKitAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{ id: number, list: CoreAttachListElType[], comment: string, onCancel: VoidFunctionType }
>('post/PartsKitAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { partsKit } = getState() as RootState;
    const { partsKitAttachmentsFilters, partsKitDetails } = partsKit;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await stockApi.postPartsKitAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await stockApi.postPartsKitAttachment(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    partsKitDetails && dispatch(setPartsKitDetails({
      ...partsKitDetails,
      attachmentsCount: (partsKitDetails.attachmentsCount || 0) + finalRes.data.data.createdItems.length,
    }));
    id && dispatch(getPartsKitAttachments({ partsKitId: +id, filters: partsKitAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const editPartsKitAttachment = createAsyncThunk<
ResponseSingleResult,
{ id: number, partsKitId: number, comment: string, onCancel: VoidFunctionType }
>('patch/PartsKitAttachment', async ({
  id, partsKitId, comment, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { partsKit: { partsKitAttachments } } = getState() as RootState;
    const response = await stockApi.editPartsKitAttachment(id, partsKitId, comment);
    const newArr = partsKitAttachments.items.map((item) => {
      if (item.id === id) {
        return { ...item, comment };
      } else return item;
    });
    dispatch(setPartsKitAttachments({ ...partsKitAttachments, items: newArr }));
    onCancel();
    dispatch(setLoading(false));
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultiplePartsKitAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  partsKitId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultiplePartsKitAttachments',
  async ({
    ids, partsKitId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { partsKit: { partsKitAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPartsKitAttachments(partsKitId, {
        ...partsKitAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await stockApi.deleteMultiplePartsKitAttachment(filteredIds, partsKitId);
      }
      dispatch(setPartsKitAttachmentsFilter({ ...partsKitAttachmentsFilters, page: 1 }));
      dispatch(getPartsKitById({ id: partsKitId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
