import React from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import Input from '../../../core/components/input/Input';
import Button from '../../../core/components/button/Button';
import { StringFunctionType } from '../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { resetPasswordThunk } from '../../../store/thunks/account/accountThunks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import { forgotPasswordDefaults, ForgotPasswordFields, ForgotPasswordSchema } from '../utils/ForgotPasswordSchema';

type ForgotPasswordFormProps = {
  setInfoView: VoidFunction;
  setEmail: StringFunctionType,
}

const ForgotPasswordForm:React.FC<ForgotPasswordFormProps> = ({ setInfoView, setEmail }) => {
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);

  const {
    handleSubmit, control, setError, reset,
  } = useForm<ForgotPasswordFields>({
    resolver: zodResolver(ForgotPasswordSchema),
    defaultValues: forgotPasswordDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const onSubmit = (values: ForgotPasswordFields) => {
    dispatch(resetPasswordThunk({
      setError,
      email: values.email,
      commonActions: () => {
        setInfoView();
        reset();
        setEmail(values.email);
      },
    }));
  };
  return (
    <>
      <p className="forgot-password__text">
        Please enter your email address and click on
        &quot;Reset&quot;
        button below to receive an email with password reset instructions.
      </p>
      <form onSubmit={handleSubmit(onSubmit)} className="forgot-password__form">
        <Controller
          control={control}
          name="email"
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              label="Email"
              error={errors.email?.message}
              className="forgot-password__field"
            />
          )}
        />
        <Button label="Reset" htmlType="submit" designType="dark" isLoading={isFetching} />
      </form>
    </>
  );
};

export default ForgotPasswordForm;
