import React from 'react';

type DistributeQtyTotalSectionProps = {
  title: string,
  requested: string,
  confirmed: string,
  difference: string,
  noPoundSign?: boolean,
}

const DistributeQtyTotalSection: React.FC<DistributeQtyTotalSectionProps> = ({
  title, requested, difference, confirmed, noPoundSign,
}) => {
  const valueRender = (value: string) => {
    return <span>
      {!noPoundSign && '£'}
      {value}
    </span>;
  };
  return (
    <div className="distributeQtyTotal">
      <span className="distributeQtyTotal__title">{title}</span>
      <ul className="distributeQtyTotal__list">
        <li className="distributeQtyTotal__list-line">
          <span>Requested</span>
          {valueRender(requested)}
        </li>
        <li className="distributeQtyTotal__list-line">
          <span>Confirmed</span>
          {valueRender(confirmed)}
        </li>
        <li className="distributeQtyTotal__list-line">
          <span>Difference</span>
          <b>
            {difference}
          </b>
        </li>
      </ul>
    </div>
  );
};

export default DistributeQtyTotalSection;
