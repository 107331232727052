import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DictionaryItem, DictionaryResponse } from '../../core/types/coreTypes';
import {
  getAffectedEmployeeOrdering,
  getAttachmentsOrdering,
  getConfirmedPartRequestsOrdering,
  getCustomerContactsOrdering,
  getHolidaysOrdering,
  getInAppNotificationsOrdering,
  getLeaveRequestOrderingThunk,
  getNominalCodesOrderingThunk,
  getNumberOfConvertedVehiclesReportOrderingThunk,
  getPartAffectedCategoryOrderingThunk,
  getPartsKitComponentsOrdering,
  getPartSubcategoryOrderingThunk,
  getPricelistAvailablePartsOrdering,
  getPricelistPartsOrdering,
  getPricelistPricesOrdering,
  getPurchaseOrderPartsOrdering,
  getQuantitiesOrdering,
  getRectificationByProductOrderingThunk,
  getRectificationReportOrderingThunk,
  getSkillsOrderingThunk,
  getStockPartReqQuantitiesOrdering,
  getStockPartRequestLinesOrdering,
  getStockPartsOrdering,
  getVehicleChecksOrderingThunk,
  getVehicleProfilesOrderingThunk,
  getVehiclesOrderingThunk,
  getSalesOfficeEmployeeReportOrderingThunk,
  getFullStockReportOrderingThunk,
  getRectificationsBySupplierOrderingThunk,
  getUnfinishedEntitiesOrdering,
  getFitmentTimeReportOrderingThunk,
  getPurchasingOutstandingOverduePurchaseOrdersReportOrdering,
  getSupplierSpendReportOrderingThunk,
  getPurchasingFitterReturnsReportOrdering,
  getSoldProductsByCustomerReportOrderingThunk,
  getInfoMessagesOrderingThunk,
  getUserAccountsOrderingThunk,
  getEnquiryQuoteLinesOrderingThunk,
  getEnquiryTimelineQuoteLineGroupOrderingThunk,
  getPricelistsOrderingThunk,
  getNominalRecordsOrderingThunk,
  getInvoiceDocumentsOrderingThunk,
  getStockPartsKitsOrdering,
  getSalesByCustomerReportOrderingThunk,
  getOrderListOrderingThunk,
  getSalesOffsiteReportOrderingThunk, getPurchasingCancelledLinesReportOrdering,
} from '../thunks/core/coreOrderingThunks';

interface ICoreOrderingState {
  customerContactsOrdering: DictionaryItem[],
  attachmentsOrdering: DictionaryItem[],
  inAppNotificationsOrdering: DictionaryItem[],
  stockPartRequestLinesOrdering: DictionaryItem[],
  vehicleProfilesOrdering: DictionaryItem[],
  vehiclesOrdering: DictionaryItem[],
  fitmentTimeReportOrdering: DictionaryItem[],
  rectificationReportOrdering: DictionaryItem[]
  rectificationsByProductOrdering: DictionaryItem[],
  rectificationsBySupplierOrdering: DictionaryItem[],
  numberOfConvertedVehiclesReportOrdering: DictionaryItem[],
  salesOffsiteReportOrdering: DictionaryItem[],
  salesOfficeEmployeeReportOrdering: DictionaryItem[],
  salesByCustomerReportOrdering: DictionaryItem[],
  soldProductsByCustomerReportOrdering: DictionaryItem[],
  fullStockReportOrdering: DictionaryItem[],
  supplierSpendReportOrdering: DictionaryItem[],
  partSubcategoryOrdering: DictionaryItem[],
  partAffectedCategoryOrdering: DictionaryItem[],
  affectedEmployeeOrdering: DictionaryItem[],
  nominalCodesOrdering: DictionaryItem[],
  skillsOrdering: DictionaryItem[],
  pricelistPartsOrdering: DictionaryItem[],
  pricelistPartsAvailableOrdering: DictionaryItem[],
  partPricesOrdering: Array<DictionaryItem>,
  adjustPartQtyOrdering: DictionaryItem[],
  stockPartsOrdering: DictionaryItem[],
  stockPartsKitsOrdering: DictionaryItem[],
  editReadyCollectedModalOrdering: DictionaryItem[],
  purchaseOrderPartsOrdering: DictionaryItem[],
  partsKitComponentsOrdering: DictionaryItem[],
  leaveRequestOrdering: DictionaryItem[],
  holidaysOrdering: DictionaryItem[],
  vehicleChecksOrdering: DictionaryItem[],
  confirmedPartRequestsOrdering: DictionaryItem[],
  unfinishedEntitiesOrdering: DictionaryItem[],
  purchasingOutstandingOverduePurchaseOrdersReportOrdering: DictionaryItem[],
  cancelledLinesReportOrdering: DictionaryItem[],
  fitterReturnsReportOrdering: DictionaryItem[],
  infoMessagesOrdering: DictionaryItem[],
  userAccountsOrdering: DictionaryItem[],
  enquiryQuoteLinesOrdering: DictionaryItem[],
  enquiryTimelineQuoteLineGroupOrdering: DictionaryItem[],
  pricelistsOrdering: DictionaryItem[],
  nominalRecordsOrdering: DictionaryItem[],
  invoiceDocumentsOrdering: DictionaryItem[],
  orderListOrdering: DictionaryItem[],
}

export const initialCoreOrderingState: ICoreOrderingState = {
  customerContactsOrdering: [],
  attachmentsOrdering: [],
  inAppNotificationsOrdering: [],
  stockPartRequestLinesOrdering: [],
  vehicleProfilesOrdering: [],
  vehiclesOrdering: [],
  fitmentTimeReportOrdering: [],
  rectificationReportOrdering: [],
  rectificationsByProductOrdering: [],
  rectificationsBySupplierOrdering: [],
  numberOfConvertedVehiclesReportOrdering: [],
  salesOffsiteReportOrdering: [],
  salesOfficeEmployeeReportOrdering: [],
  salesByCustomerReportOrdering: [],
  soldProductsByCustomerReportOrdering: [],
  fullStockReportOrdering: [],
  supplierSpendReportOrdering: [],
  partSubcategoryOrdering: [],
  partAffectedCategoryOrdering: [],
  skillsOrdering: [],
  affectedEmployeeOrdering: [],
  nominalCodesOrdering: [],
  pricelistPartsOrdering: [],
  pricelistPartsAvailableOrdering: [],
  partPricesOrdering: [],
  adjustPartQtyOrdering: [],
  stockPartsOrdering: [],
  stockPartsKitsOrdering: [],
  editReadyCollectedModalOrdering: [],
  purchaseOrderPartsOrdering: [],
  partsKitComponentsOrdering: [],
  leaveRequestOrdering: [],
  holidaysOrdering: [],
  vehicleChecksOrdering: [],
  confirmedPartRequestsOrdering: [],
  unfinishedEntitiesOrdering: [],
  purchasingOutstandingOverduePurchaseOrdersReportOrdering: [],
  cancelledLinesReportOrdering: [],
  fitterReturnsReportOrdering: [],
  infoMessagesOrdering: [],
  userAccountsOrdering: [],
  enquiryQuoteLinesOrdering: [],
  enquiryTimelineQuoteLineGroupOrdering: [],
  pricelistsOrdering: [],
  nominalRecordsOrdering: [],
  invoiceDocumentsOrdering: [],
  orderListOrdering: [],
};

export const coreOrderingSlice = createSlice({
  name: 'coreOrdering',
  initialState: initialCoreOrderingState,
  reducers: {},
  // Later, dispatch the thunk as needed in the app
  extraReducers: (builder) => {
    builder.addCase(getCustomerContactsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.customerContactsOrdering = action.payload.data.items;
    });
    builder.addCase(getAttachmentsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.attachmentsOrdering = action.payload.data.items;
    });
    builder.addCase(getInAppNotificationsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.inAppNotificationsOrdering = action.payload.data.items;
    });
    builder.addCase(getStockPartRequestLinesOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartRequestLinesOrdering = action.payload.data.items;
    });
    builder.addCase(getVehicleProfilesOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleProfilesOrdering = action.payload.data.items;
    });
    builder.addCase(getVehiclesOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehiclesOrdering = action.payload.data.items;
    });
    builder.addCase(getVehicleChecksOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.vehicleChecksOrdering = action.payload.data.items;
    });
    builder.addCase(getFitmentTimeReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.fitmentTimeReportOrdering = action.payload.data.items;
    });
    builder.addCase(getRectificationReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationReportOrdering = action.payload.data.items;
    });
    builder.addCase(getNumberOfConvertedVehiclesReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.numberOfConvertedVehiclesReportOrdering = action.payload.data.items;
    });
    builder.addCase(getSalesOffsiteReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.salesOffsiteReportOrdering = action.payload.data.items;
    });
    builder.addCase(getSalesOfficeEmployeeReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.salesOfficeEmployeeReportOrdering = action.payload.data.items;
    });
    builder.addCase(getSalesByCustomerReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.salesByCustomerReportOrdering = action.payload.data.items;
    });
    builder.addCase(getSoldProductsByCustomerReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.soldProductsByCustomerReportOrdering = action.payload.data.items;
    });
    builder.addCase(getRectificationByProductOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationsByProductOrdering = action.payload.data.items;
    });
    builder.addCase(getRectificationsBySupplierOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.rectificationsBySupplierOrdering = action.payload.data.items;
    });
    builder.addCase(getFullStockReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.fullStockReportOrdering = action.payload.data.items;
    });
    builder.addCase(getSupplierSpendReportOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.supplierSpendReportOrdering = action.payload.data.items;
    });
    builder.addCase(getPartSubcategoryOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partSubcategoryOrdering = action.payload.data.items;
    });
    builder.addCase(getPartAffectedCategoryOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partAffectedCategoryOrdering = action.payload.data.items;
    });
    builder.addCase(getSkillsOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.skillsOrdering = action.payload.data.items;
    });
    builder.addCase(getAffectedEmployeeOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.affectedEmployeeOrdering = action.payload.data.items;
    });
    builder.addCase(getNominalCodesOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.nominalCodesOrdering = action.payload.data.items;
    });
    builder.addCase(getPricelistPartsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistPartsOrdering = action.payload.data.items;
    });
    builder.addCase(getPricelistAvailablePartsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistPartsAvailableOrdering = action.payload.data.items;
    });
    builder.addCase(getPricelistPricesOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partPricesOrdering = action.payload.data.items;
    });
    builder.addCase(getStockPartsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartsOrdering = action.payload.data.items;
    });
    builder.addCase(getStockPartsKitsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.stockPartsKitsOrdering = action.payload.data.items;
    });
    builder.addCase(getQuantitiesOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.adjustPartQtyOrdering = action.payload.data.items;
    });
    builder.addCase(getStockPartReqQuantitiesOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.editReadyCollectedModalOrdering = action.payload.data.items;
    });
    builder.addCase(getPurchaseOrderPartsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.purchaseOrderPartsOrdering = action.payload.data.items;
    });
    builder.addCase(getPartsKitComponentsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.partsKitComponentsOrdering = action.payload.data.items;
    });
    builder.addCase(getLeaveRequestOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.leaveRequestOrdering = action.payload.data.items;
    });
    builder.addCase(getHolidaysOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.holidaysOrdering = action.payload.data.items;
    });
    builder.addCase(getConfirmedPartRequestsOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.confirmedPartRequestsOrdering = action.payload.data.items;
    });
    builder.addCase(getUnfinishedEntitiesOrdering.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.unfinishedEntitiesOrdering = action.payload.data.items;
    });
    builder.addCase(
      getPurchasingOutstandingOverduePurchaseOrdersReportOrdering.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.purchasingOutstandingOverduePurchaseOrdersReportOrdering = action.payload.data.items;
      },
    );
    builder.addCase(
      getPurchasingCancelledLinesReportOrdering.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.cancelledLinesReportOrdering = action.payload.data.items;
      },
    );
    builder.addCase(
      getPurchasingFitterReturnsReportOrdering.fulfilled,
      (state, action: PayloadAction<DictionaryResponse>) => {
        state.fitterReturnsReportOrdering = action.payload.data.items;
      },
    );
    builder.addCase(getInfoMessagesOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.infoMessagesOrdering = action.payload.data.items;
    });
    builder.addCase(getUserAccountsOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.userAccountsOrdering = action.payload.data.items;
    });
    builder.addCase(getEnquiryQuoteLinesOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquiryQuoteLinesOrdering = action.payload.data.items;
    });
    builder.addCase(getEnquiryTimelineQuoteLineGroupOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.enquiryTimelineQuoteLineGroupOrdering = action.payload.data.items;
    });
    builder.addCase(getPricelistsOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.pricelistsOrdering = action.payload.data.items;
    });
    builder.addCase(getNominalRecordsOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.nominalRecordsOrdering = action.payload.data.items;
    });
    builder.addCase(getInvoiceDocumentsOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.invoiceDocumentsOrdering = action.payload.data.items;
    });
    builder.addCase(getOrderListOrderingThunk.fulfilled, (state, action: PayloadAction<DictionaryResponse>) => {
      state.orderListOrdering = action.payload.data.items;
    });
  },
});

export default coreOrderingSlice.reducer;
