import {
  DataListType,
  PagingType,
  StringFunctionType,
  VoidFunctionType,
  NumericRangeType,
  PagingWithOrdering,
  ResponseResult,
  DictionaryItem,
} from '../../../core/types/coreTypes';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import {
  ActivityEventBasicFieldsType, AddVehicleRequest,
  CommonCreatePartLineReqDto,
  ContactDto,
  ContactEvent,
  OldNewLocationEventValues, OrderDiffVersion, RequisiteDto,
} from '../../../common/types/commonTypes';
import { EnquiryDto } from '../../enquiries/types/enquiryTypes';

export type CommonDetailsFields = {
  enquirySource?: number,
  priorityLevel?: number,
  estimatedCost?: string,
  jobType?: number,
  description?: string,
  specDescription?: string,
  quoteDate: string | null,
  rejectionReason?: string,
  customerReferenceNumber?: string,
}

export const CommonEnqDetailsDefaultFields = {
  enquirySource: undefined,
  priorityLevel: undefined,
  estimatedCost: undefined,
  jobType: undefined,
  description: '',
  specDescription: '',
  quoteDate: null,
  rejectionReason: '',
  customerReferenceNumber: '',
};

export type DetailsFiltersType = {
  assignedTo?: number | string,
  statusTransition?: number,
};

export type EnquiryQuoteVehicleDto = Omit<AddVehicleRequest, 'slidingDoorsQuantity'> & {
  id: number;
  enquiryId: number;
  tabName: string | null;
  code: string | null;
  slidingDoorsQuantity: number | null;
  enableNotes: boolean;
  enableDiscounts: boolean;
}

export type PutQuoteVehicle = Omit<AddVehicleRequest, 'grossVehicleWeightKg' | 'maxPayload'> & {
  grossVehicleWeightKg: number | null;
  maxPayload: number | null;
}

export type EnquiryQuoteLineDto = {
  id: number;
  infoSource: number,
  isUsualLine: boolean,
  quoteVehicleId: number;
  partId: number | null;
  partsKitId: number | null;
  customPartName: string | null;
  number: number;
  quoteCategoryId: number | null;
  productNumber: string | null;
  productName: string | null;
  quantity: number;
  unitOfMeasure: number
  priceSources: number[] | null;
  pricelistUnitPrice: number | null;
  tax: number | null;
  weight: number | null;
  productWeight: number | null;
  isProductSuitable: boolean | null;
  notes: string | null;
  discount: number | null;
  stockQuantity: number | null;
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  pricelists: Array<{ id: number, quantity: number }> | null,
  unitPriceString: string | null,
  netPriceString: string | null,
}

export type GetEnquiryQuoteLinesResponse = ResponseResult<EnquiryQuoteLineDto[], {
  categories: DictionaryItem[],
  maxNetPrice: string,
  allItemIds: number[],
}>

export type QuoteLineFiltersInnerType = {
  categoryIds?: number[],
  productName?: string,
  productNumber?: string,
  notes?: string,
  quantity?: NumericRangeType,
  unitPrice?: NumericRangeType,
  netPrice?: NumericRangeType,
  discount?: NumericRangeType,
  weight?: NumericRangeType,
  taxes?: number[],
  priceSources?: number[],
  canBeCopied?: boolean,
}

export type QuoteLinesFilters = {
  filters?: QuoteLineFiltersInnerType,
} & PagingWithOrdering;

export type EnquiryEventDtoBase = {
  enquiryEventType: number,
  enquiryNumber?: string,
  tabName?: string,
  id?: number,
  content?: string, // notes
  body?: number,
  chassisNumber?: string,
  drivetrain?: number,
  grossVehicleWeightKg?: number,
  make?: number,
  model?: string,
  modification?: string,
  slidingDoorsQuantity?: number,
  rearDoors?: number,
  regNumber?: string,
  roofHeight?: number,
  wheelbase?: number,
  maxPayload?: number,
  manufacturingStartDate?: string,
  manufacturingEndDate?: string | null,
  vehicleTabName?: string,
  groupId?: number,
  vehicleInfoSource?: number,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  companyName: string | null,
  email: string | null,
  firstName: string | null,
  lastName: string | null,
  postalCode: string | null,
  region: string | null,
  mobile: string | null,
  position?: string | null,
  telephone: string | null,
  partsKitName?: string | null,
  contact?: ContactEvent,
  pricelistName?: string | null,
} & ActivityEventBasicFieldsType & OldNewLocationEventValues

export type QuoteLineTimelineType = {
  number: number | null,
  quoteCategoryName: string | null,
  productNumber: string | null,
  productName: string | null,
  notes: string | null,
  quantity: number,
  discount: number | null,
  tax: number | null,
  weight: number | null,
  priceSources: number[] | null,
  unitPriceString: string | null,
  netPriceString: string | null,
}
export type GroupedActivities = {
  [key: string]: {
    [key: string]: EnquiryEventDtoBase[]
  }
}

export type vehicleFieldType = {
  name: string,
  required?: ErrorsEnum,
  label: string,
  component: string,
  options?: { value: number | string, label: string }[],
  showSearch?: boolean,
  min?: number,
  max?: number,
  disabled?: boolean,
  clearFields?: VoidFunctionType,
  onSearch?: StringFunctionType,
  uppercased?: boolean,
  gridSize?: number,
}

export type QuoteVehicleSummaryDto = {
  quoteVehicleId: number,
  enableDiscounts: boolean,
  grossVehicleWeightKg: number | null,
  maximumPayloadKg: number | null,
  conversionWeightKg: number,
  remainingPayloadKg: number | null,
  netString: string | null,
  discountString: string | null,
  vatString: string | null,
  grossString: string | null,
}

export type QuoteTotalSummaryDto = {
  netString: string | null,
  discountString: string | null,
  vatString: string | null,
  grossString: string | null,
}

export type QuoteLinesOptionsType = {
  enableNotes: boolean | null,
  enableDiscounts: boolean | null,
  pricelistId: number | null,
}

export interface CreatePartLineReqDto extends CommonCreatePartLineReqDto {
  quoteVehicleId: number,
}

export type EnquiryContactDto = Omit<ContactDto, 'customerId'>

export type NewContactReqDataType = {
  enquiryId: number,
  firstName: string,
  lastName: string,
  position: string | null,
  email: string | null,
  telephone: string | null,
  mobile: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  postalCode: string | null,
  region: string | null,
}

export type ExistingVehSpecificationsFiltersType = {
  keyword?: string,
  businessAreas?: number[],
  vehicleSpecifications?: number[],
} & PagingType;

export type PutEnquiryLocationType = {
  jobType?: number,
  jobCompanyName: string,
  jobAddressLine1: string,
  jobAddressLine2: string,
  jobCityTown: string,
  jobRegion: string,
  jobPostalCode: string,
  jobTelephone: string,
}

export type PickPatchedEnquiryFields = Pick<
EnquiryDto,
keyof PutEnquiryLocationType |
'assignedTo' |
'priorityLevel' |
'enquirySource' |
'description' |
'specDescription' |
'quoteDate' |
'rejectionReason' |
'customerReferenceNumber' |
'estimatedCost' |
'isShippingRequisiteSameAsBilling' |
'isBillingDetailsManualInput' |
'isShippingDetailsManualInput' |
'billingCustomerId' |
'shippingCustomerId'
>

export type PatchEnquiryRestModel = Partial<PickPatchedEnquiryFields & {
  billingRequisite: RequisiteDto | null,
  shippingRequisite: RequisiteDto | null,
}>

export type MissingQuoteLinesParams = {
  enquiryId: number,
  canBeCopied?: boolean,
} & PagingWithOrdering

export type UpdateEnquiryStatusResponse = {
  createdOrders: Array<OrderDiffVersion> | null,
  createdOrderAttachments: Array<{ id: number }> | null,
}

export type EnquiryContactsList = DataListType<EnquiryContactDto[], { allItemIds: number[] }>;

export type PatchEnquiryQuoteLineDto = {
  notes: string | null,
  quantity: number | null,
  unitPrice: number | null,
  discount: number | null,
  tax: number | null,
  weight: number | null,
  quoteCategoryId: number | null,
  productName: string | null,
  unitOfMeasure: number,
  stockQuantity: number | null,
  pricelists: Array<{
    pricelistId: number,
    quantity: number,
  }>,
  manualInputQuantity: number | null,
  manualInputUnitPrice: number | null,
  takeFromStock: boolean | null,
  useManualInput: boolean | null,
}

export type AddEnquiryVehicleRequest = AddVehicleRequest & { enquiryId: number }
