export enum ReportsModesEnum {
  HR = 'hr',
  MANAGEMENT = 'management',
  PRODUCTION = 'production',
  PURCHASING = 'purchasing',
  SALES = 'sales',
  STOCK = 'stock',
}

export enum ProductionReportsEnum {
  FITMENT_TIME_REPORT = 'fitment-time-report',
}

export enum SalesReportsEnum {
  LOADED_SPEC = 'loaded-spec',
  NUMBER_OF_CONVERTED_VEHICLES = 'number-of-converted-vehicles',
  OFF_SITE_WORKING = 'off-site-working',
  RECTIFICATION_REPORT = 'rectification-report',
  RECTIFICATIONS_BY_SUPPLIER = 'rectifications-by-supplier',
  RECTIFICATIONS_BY_PRODUCT = 'rectification-by-product',
  SALES_OFFICE_EMPLOYEE_REPORT = 'sales-office-employee-report',
  SALES_REPORT_BY_CUSTOMER = 'sales-report-by-customer',
  SOLD_PRODUCT_BY_CUSTOMER = 'sold-products-by-customer',
  UNFINISHED = 'unfinished',
}

export enum StockReportsEnum {
  FULL_STOCK_REPORT = 'full-stock-report',
}

export enum PurchasingReportsEnum {
  CANCELLED_LINES = 'cancelled-lines',
  FITTER_RETURNS = 'fitter-returns',
  OUTSTANDING_OVERDUE_PURCHASE_ORDERS_REPORT = 'outstanding-overdue-purchase-orders-report',
  SUPPLIER_SPEND = 'supplier-spend',
}

export enum UnfinishedEntityTypesEnum {
  Rectification = 'Rectification',
  Enquiry = 'Enquiry',
  Order = 'Order',
}
