import React from 'react';
import { useAppSelector } from '../../../../../store/hooks';
import {
  fullStockColumnsSelector, fullStockReportDataSelector,
} from '../../../../../store/selectors/reportsSelectors';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import FullStockColumns from './FullStockColumns';
import FullStockBody from './FullStockBody';
import './FullStockTable.scss';

const FullStockTable: React.FC = () => {
  const columns = useAppSelector(fullStockColumnsSelector);
  const { items, totalCount } = useAppSelector(fullStockReportDataSelector);

  return (
    <TableNew>
      <FullStockColumns columns={columns} totalElements={totalCount} />
      <FullStockBody data={items} columnsLength={columns.length} />
    </TableNew>
  );
};

export default FullStockTable;
