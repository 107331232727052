import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import PurchaseOrdersList from './components/purchase-list/PurchaseOrdersList';
import PurchaseOrdersFilters from './components/purchase-filters/PurchaseOrdersFilters';
import './PurchaseOrders.scss';
import PurchaseActions from './components/purchase-actions/PurchaseActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  purchaseOrdersFiltersSelector, purchaseOrdersOrderingSelector,
  purchaseOrdersSelector,
} from '../../../store/selectors/purchaseOrderSelectors';
import {
  setPurchaseOrders,
  setPurchaseOrdersAssignees, setPurchaseOrdersFilters,
} from '../../../store/slices/purchaseOrderSlice';
import { PurchaseOrdersInitDataList } from './types/purchaseOrdersTypes';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import CreatePurchaseOrderModesModal from '../create/CreatePurchaseOrderModesModal';

import { getPurchaseOrdersOrdering } from '../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import { getSupplierStatusesThunk } from '../../../store/thunks/core/coreStatusesThunks';
import { supplierStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';

type PurchaseOrdersProps = {
  tabType: string | null;
  purchaseStatus: string | null;
  setPurchaseStatus: (value: string) => void;
}

const PurchaseOrders: React.FC<PurchaseOrdersProps> = ({ tabType, purchaseStatus, setPurchaseStatus }) => {
  const dispatch = useAppDispatch();
  const supplierStatuses = useAppSelector(supplierStatusesSelector);
  const purchaseOrderOrdering = useAppSelector(purchaseOrdersOrderingSelector);
  const purchaseOrders = useAppSelector(purchaseOrdersSelector);
  const purchaseOrdersFilters = useAppSelector(purchaseOrdersFiltersSelector);
  const [createModeModal, setCreateModeModal] = useState(false);

  const [searchParams] = useSearchParams();
  const keywordParam = searchParams.get('keyword');

  useEffect(() => {
    if (keywordParam && !purchaseOrdersFilters.keyword) {
      dispatch(setPurchaseOrdersFilters({ ...purchaseOrdersFilters, keyword: keywordParam }));
    }
    // eslint-disable-next-line
  }, [keywordParam, purchaseOrdersFilters]);

  useEffect(() => {
    if (!supplierStatuses.length) {
      dispatch(getSupplierStatusesThunk());
    }
    if (!purchaseOrderOrdering.length) {
      dispatch(getPurchaseOrdersOrdering());
    }

    return () => {
      dispatch(setPurchaseOrders(PurchaseOrdersInitDataList));
      dispatch(setPurchaseOrdersAssignees([]));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CreatePurchaseOrderModesModal
        visible={createModeModal}
        onCancel={() => {
          setCreateModeModal(false);
        }}
      />
      <PurchaseOrdersFilters
        purchaseStatus={purchaseStatus}
        setPurchaseStatus={setPurchaseStatus}
        tabType={tabType}
        purchaseOrdersFilters={purchaseOrdersFilters}
        keywordParam={keywordParam}
      />
      <PurchaseActions
        purchaseOrdersFilters={purchaseOrdersFilters}
        purchaseOrders={purchaseOrders}
        setCreateModeModal={setCreateModeModal}
      />
      <PurchaseOrdersList purchaseOrders={purchaseOrders} />
    </>
  );
};

export default PurchaseOrders;
