import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisVertical, faPen, faTrashAlt, faBriefcase,
} from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { employeeEmploymentsUISelector } from '../../../../../../store/selectors/employeesSelector';
import { useAppSelector } from '../../../../../../store/hooks';
import EmptyContentList from '../../../../../../core/components/empty-content-list/EmptyContentList';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';

type EmploymentsListProps = {
  allowedToManage?: boolean,
  deleteLine: (v?: number) => void,
  editLine: (v?: number) => void,
}

const EmploymentsList: React.FC<EmploymentsListProps> = ({ allowedToManage, deleteLine, editLine }) => {
  const employeeEmploymentsUI = useAppSelector(employeeEmploymentsUISelector);
  return (
    employeeEmploymentsUI && employeeEmploymentsUI.length > 0
      ? <ul className="lines-list">
        {employeeEmploymentsUI?.map((el, i) => (
          <li key={i} className="lines-list__item">
            <div className="employmentLine not-link">
              <div className="employmentLine__time">
                <span>{`${el.startDate} -`}</span>
                <span>{` ${el.endDate}`}</span>
              </div>
              <div className="employmentLine__info">
                <TruncateTooltip value={el.jobTitle} className="employmentLine__job-title" overlayClassName="employmentLine__tooltip" />
                <div className="employmentLine__info-desc">
                  <span className="employmentLine__salary">
                    <b>
                      {el.salaryValue !== 'N/A' ? '£' : ''}
                      {' '}
                      {el.salaryValue}
                    </b>
                    {' '}
                    {el.salaryType}
                    {' /'}
                  </span>
                  <span>
                    <b>{el.annualLeaveEntitlement}</b>
                    {' '}
                    leave days
                  </span>
                </div>
              </div>
              <Tooltip title={el.department} overlayClassName="employmentLine__tooltip">
                <span className="employmentLine__tag">{el.department?.charAt(0).toUpperCase()}</span>
              </Tooltip>
              {allowedToManage && (
                <Dropdown
                  menuItems={[
                    {
                      key: 'edit-record',
                      label: 'Edit record',
                      icon: <FontAwesomeIcon icon={faPen} />,
                      onClick: () => el.id && editLine(el.id),
                    },
                    {
                      key: 'delete-record',
                      label: 'Delete record',
                      icon: <FontAwesomeIcon icon={faTrashAlt} />,
                      onClick: () => el.id && deleteLine(el.id),
                    },
                  ]}
                  disabled={!allowedToManage}
                  icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                  className="employmentLine__actions"
                />
              )}
            </div>
          </li>
        ))}
      </ul>
      : <EmptyContentList text="No employment records" icon={faBriefcase} />
  );
};

export default EmploymentsList;
