import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  rectificationsBySupplierColumnsSelector,
  rectificationsBySupplierDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import RectificationsBySupplierColumns from './RectificationsBySupplierColumn';
import RectificationsBySupplierBody from './RectificationsBySupplierBody';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import './RectificationsBySupplierTable.scss';

const RectificationsBySupplierTable: React.FC = () => {
  const columns = useAppSelector(rectificationsBySupplierColumnsSelector);
  const { items, totalCount } = useAppSelector(rectificationsBySupplierDataSelector);

  return (
    <TableNew>
      <RectificationsBySupplierColumns columns={columns} totalElements={totalCount} />
      <RectificationsBySupplierBody data={items} columnsLength={columns.length} />
    </TableNew>
  );
};

export default RectificationsBySupplierTable;
