import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  BooleanFunctionType,
  initSmallPaging,
  NumberFunctionType,
  VoidFunctionType,
} from '../../../../core/types/coreTypes';
import Modal from '../../../../core/components/modal/Modal';
import './AssignNominalCodeModal.scss';
import AssignNominalCodesActions from './AssignNominalCodesActions';
import AssignNominalCodesTable from './AssignNominalCodesTable';
import AssignNominalCodesSummary from './AssignNominalCodesSummary';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  setAssignedNominalCodes, setAssignedNominalCodesFilters,
  setAssignedNominalCodesSummary,
} from '../../../../store/slices/invoicingSlice';
import {
  assignedNominalCodesFiltersSelector,
} from '../../../../store/selectors/invoicingSelectors';
import {
  getNominalRecordPartSummary,
  getAssignedNominalRecords,
} from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import {
  initNominalCodesData,
} from '../../../invoicing-purchases/view-page/types/InvoicingViewPageTypes';
import { AffectedInvoiceOrderLine, AssignNominalCodesSummaryType } from '../../types/invoicingCommonTypes';
import { getSalesPartRequestLineSummary } from '../../../../store/thunks/invoicing/salesThunks';

type AssignNominalCodesModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  setIsAddNominalCode: BooleanFunctionType,
  deleteConfirmationHandler: NumberFunctionType,
  editRecordHandler: NumberFunctionType,
  affectedLine?: AffectedInvoiceOrderLine,
  partRequestId?: number,
  summarySectionData?: AssignNominalCodesSummaryType,
  isInvoicedAmountGreaterThanReceived: boolean,
  isSales?: boolean,
}

const AssignNominalCodesModal: React.FC<AssignNominalCodesModalProps> = ({
  onCancel, isVisible, setIsAddNominalCode, partRequestId,
  deleteConfirmationHandler, editRecordHandler, affectedLine,
  summarySectionData, isInvoicedAmountGreaterThanReceived, isSales,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const filters = useAppSelector(assignedNominalCodesFiltersSelector);
  const itemProperties = [
    { label: 'Product No', value: affectedLine?.partNumber || '-' },
    { label: 'Product name', value: affectedLine?.partName || '-' },
    {
      label: 'Category & subcategory',
      value: [affectedLine?.categoryName, affectedLine?.subcategoryName].filter(Boolean).join(' / ') || '-',
    },
  ];

  useEffect(() => {
    if (isVisible) {
      dispatch(getAssignedNominalRecords({ filters }));
      if (isSales) {
        if (filters.partRequestLineIds?.length && partRequestId) {
          dispatch(getSalesPartRequestLineSummary({
            partRequestId,
            lineId: filters.partRequestLineIds[0],
          }));
        }
      } else {
        if (filters.purchaseOrderPartIds?.length && id) {
          dispatch(getNominalRecordPartSummary({
            purchaseOrderId: +id,
            purchaseOrderPartId: filters.purchaseOrderPartIds[0],
          }));
        }
      }
    }
    // eslint-disable-next-line
  }, [isVisible, filters]);

  const cancelHandle = () => {
    onCancel();
    dispatch(setAssignedNominalCodesFilters(initSmallPaging));
    dispatch(setAssignedNominalCodes(initNominalCodesData));
    dispatch(setAssignedNominalCodesSummary(undefined));
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title="Assign nominal codes"
      width={950}
    >
      <ul className="itemProperties">
        {itemProperties.map(({ label, value }) => <li key={label} className="itemProperties__item">
          <b>{label}</b>
          <TruncateTooltip value={value} table />
        </li>)}
      </ul>
      <AssignNominalCodesActions setIsAddNominalCode={setIsAddNominalCode} />
      <AssignNominalCodesTable
        deleteConfirmationHandler={deleteConfirmationHandler}
        editRecordHandler={editRecordHandler}
      />
      <AssignNominalCodesSummary
        isInvoicedAmountGreaterThanReceived={isInvoicedAmountGreaterThanReceived}
        summarySectionData={summarySectionData}
      />
    </Modal>
  );
};

export default AssignNominalCodesModal;
