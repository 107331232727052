import React from 'react';
import FieldUpdateEvent from '../../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { fieldNameEventHandle, getValuesFromJsonArray } from '../../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../../store/hooks';
import { shortBusinessAreasSelector } from '../../../../../../../store/selectors/coreSelectors';
import { FieldUpdateEventProps } from '../../../../../../../core/types/coreTypes';

const PartsKitFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const businessAreas = useAppSelector(shortBusinessAreasSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'BusinessAreas':
        return getValuesFromJsonArray(value as string, businessAreas);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field, { businessAreas: 'Parts kit', name: 'Parts kit' })}
      oldValue={valueHandle(oldValue) || ''}
      newValue={valueHandle(newValue) || ''}
    />
  );
};

export default PartsKitFieldUpdateEvent;
