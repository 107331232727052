import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Switch from '../../../../../core/components/switch/Switch';
import {
  getRectificationTimelineThunk,
  postRectificationTimelineNoteThunk,
} from '../../../../../store/thunks/rectification/rectificationViewPageThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  isRectificationInReadModeSelector,
  rectificationTimelineFiltersSelector,
  rectificationTimelineSelector,
} from '../../../../../store/selectors/rectificationsSelector';
import { setRectificationTimelineFilters } from '../../../../../store/slices/rectificationsSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import RectificationEvent from './rectification-events-description/RectificationEvent';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';
import { getPartRequestLineActivityTypesThunk } from '../../../../../store/thunks/coreThunk';
import { partRequestLineActivityTypesSelector } from '../../../../../store/selectors/coreSelectors';

const RectificationActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const allowedToManageNotes = usePermission(PermissionEnum.RectificationViewPage);
  const { items, totalCount } = useAppSelector(rectificationTimelineSelector);
  const timelineFilters = useAppSelector(rectificationTimelineFiltersSelector);
  const isRectificationInReadMode = useAppSelector(isRectificationInReadModeSelector);
  const partRequestLineActivityTypes = useAppSelector(partRequestLineActivityTypesSelector);

  const [value, setValue] = useState('');

  useEffect(() => {
    if (!partRequestLineActivityTypes.length) {
      dispatch(getPartRequestLineActivityTypesThunk());
    }
    // eslint-disable-next-line
  }, [partRequestLineActivityTypes]);

  useEffect(() => {
    id && dispatch(getRectificationTimelineThunk({
      rectificationId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
  }, [id, timelineFilters.page, timelineFilters.mainOnly]);

  const addNote = () => {
    id && dispatch(postRectificationTimelineNoteThunk({
      rectificationId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <RectificationEvent item={el} />,
  }));

  return (
    <section className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(value) => {
            dispatch(setRectificationTimelineFilters({
              ...timelineFilters,
              mainOnly: !value,
              page: 1,
            }));
          }}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        totalItems={totalCount}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setRectificationTimelineFilters({
            ...timelineFilters,
            page,
          }));
        }}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToManageNotes || isRectificationInReadMode}
        disabledButton={isRectificationInReadMode}
      />
    </section>
  );
};

export default RectificationActivitySection;
