import React from 'react';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Tooltip } from 'antd';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import { PartDtoType } from '../../../search/types/stockSearchTypes';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';

type PartReorderLineProps = {
  data: PartDtoType,
  isCritical: boolean,
}
const PartReorderLine:React.FC<PartReorderLineProps> = ({ data, isCritical }) => {
  return (
    <PrivateLineLink
      to={`part/${data.id}`}
      className="partReorderLine"
      permission={PermissionEnum.StockPartViewPage}
    >
      <TruncateTooltip value={data.partCode} className="partReorderLine__code" />
      <TruncateTooltip value={data.name} className="partReorderLine__name" />
      <span className="partReorderLine__count">
        {`${data.free} free`}
        <Tooltip
          title={`${isCritical ? data.criticalReorderLevel : data.warningReorderLevel} required`}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
        >
          <InfoCircleOutlined onClick={(e) => e.preventDefault()} />
        </Tooltip>
      </span>
    </PrivateLineLink>
  );
};

export default PartReorderLine;
