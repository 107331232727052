import React from 'react';
import classNames from 'classnames';

type TfootProps = {
  children: React.ReactNode;
  className?: string;
}

const Tfoot: React.FC<TfootProps> = ({ children, className }) => {
  return (
    <tfoot className={classNames(className, 'table-footer')}>{children}</tfoot>
  );
};

export default Tfoot;
