import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { customerFiltersListSelector, customersDashFiltersSelector } from '../../../store/selectors/customersSelectors';
import { CustomersDashboardFiltersType } from '../types/customersDashboardTypes';
import { setCustomersDashFilters } from '../../../store/slices/customersSlice';

export const useCustomerDashFilters = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(customersDashFiltersSelector);
  const filtersList = useAppSelector(customerFiltersListSelector);

  const initDropdownSearch = {
    customerType: '',
    status: '',
  };

  const [keywordValue, setKeywordValue] = useState<string | undefined>(filters.keyword);
  const setFiltersHandle = (value: CustomersDashboardFiltersType) => {
    dispatch(setCustomersDashFilters({ ...value, page: 1 }));
  };
  const onClear = () => {
    setFiltersHandle({
      page: 1,
      pageSize: 10,
    });
  };
  return {
    filtersList,
    initDropdownSearch,
    keywordValue,
    setKeywordValue,
    onClear,
    setFiltersHandle,
  };
};
