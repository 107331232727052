import React from 'react';
import {
  DragDropContext, Draggable, Droppable, DroppableProvided, DropResult,
} from 'react-beautiful-dnd';
import classNames from 'classnames';
import Button from '../../../core/components/button/Button';
import { CustomAny, DictionaryItem } from '../../../core/types/coreTypes';
import DraggableCard from './DraggableCard';
import { DraggableCardType } from '../../types/commonTypes';
import './drag-and-drop-area.scss';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { toPoundCurrency } from '../../utils/formatUtils';
import Spinner from '../../../core/components/spinner/Spinner';

type DraggableCardsColumnProps<C, F> = {
  cards: C | null;
  setCards: (value: C | null) => void;
  columnsPagination: {status: number, currentPage: number, pages: number}[];
  statusDictionary: DictionaryItem[];
  dashFilters: F;
  currentFiltersState: (number[] | undefined)[];
  setCardTransit?: React.Dispatch<React.SetStateAction<{id: number, initStatus: number} | undefined>>;
  setTransitVehicleCount?: React.Dispatch<React.SetStateAction<number | undefined>>;
  getNewPortion: (page: number, status: number) => Promise<void>;
  reformCardData: (card: CustomAny) => DraggableCardType,
  onDragEnd: (
    result: DropResult,
    columns: C | null,
    setColumns: (value: (C | null)) => void
  ) => Promise<void>;
  noLinkedEntities?: boolean,
  permissionToSeeCardPage: PermissionEnum,
  permissionToSeeEstimates?: PermissionEnum,
  isDragDisabled?: boolean,
  warnings: DictionaryItem[],
  columnsLoading?: number[],
}

const DraggableCardsArea = <C, F>({
  cards,
  setCards,
  columnsPagination,
  statusDictionary,
  onDragEnd,
  getNewPortion,
  setCardTransit,
  setTransitVehicleCount,
  dashFilters,
  currentFiltersState,
  reformCardData,
  noLinkedEntities = false,
  permissionToSeeCardPage,
  permissionToSeeEstimates,
  isDragDisabled,
  warnings,
  columnsLoading,
}: DraggableCardsColumnProps<C, F>): JSX.Element => {
  const labelHandler = (length: number, totalCount: number) => {
    if (Object.values(dashFilters as ArrayLike<CustomAny>).some((v: CustomAny) => v && v.length > 0) && totalCount > 0) {
      return <>
        <span className="totalPerFilter">{`${length}`}</span>
        <span>{` of ${totalCount}`}</span>
      </>;
    } else {
      return <span>{totalCount}</span>;
    }
  };
  const columnLabel = (value: number) => {
    return statusDictionary?.find((el) => el.value === value)?.label || '';
  };

  const currentStatusPagination = (column: CustomAny) => {
    const current = columnsPagination.find((el) => el.status === column.status);
    const currentPage = current?.currentPage || 1;
    const currentTotal = current?.pages;
    return currentPage === currentTotal;
  };

  const isFilterSelected = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return currentFiltersState.some((f) => f && f.length > 0) || dashFilters?.keyword;
  };

  return (
    <DragDropContext
      onDragStart={(initial) => {
        const sourceCol = (cards as CustomAny)?.find((c: CustomAny) => c.status === +initial.source.droppableId);
        const sourceColKids = sourceCol?.list;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const draggableCard = sourceColKids?.find((k: C) => k.id === +initial.draggableId);
        const quoteVehicleCount = draggableCard?.quoteVehicleCount;
        setCardTransit && setCardTransit({ id: +initial.draggableId, initStatus: +initial.source.droppableId });
        setTransitVehicleCount && setTransitVehicleCount(quoteVehicleCount);
      }}
      onDragEnd={(result) => onDragEnd(result, cards, setCards)}
    >
      <section className="drag-and-drop-area">
        <div className="drag-and-drop-area__content">
          {(cards as CustomAny)?.filter((c: CustomAny) => !c.isHidden)?.map((c: CustomAny) => <article
            key={c?.status}
            className="drag-and-drop-area__column"
          >
            <header className="drag-and-drop-area__heading">
              <TruncateTooltip
                value={columnLabel(c?.status)}
                className="drag-and-drop-area__column-head__label"
                overlayClassName="drag-and-drop-area__column-head__tooltip-overlay"
              />
              <span className="drag-and-drop-area__column-head__total">
                {labelHandler(c?.filteredTotalCount, c?.totalCount)}
                {' '}
                item(s)
              </span>
              {c.totalCost !== undefined && <span className="drag-and-drop-area__column-head__cost">
                {toPoundCurrency(c.totalCost)}
              </span>}
              {columnsLoading?.includes(c?.status) && <Spinner global={false} size="small" />}
            </header>
            <Droppable droppableId={c?.status.toString()} key={c?.status} isDropDisabled={!!columnsLoading?.length}>
              {(provided: DroppableProvided, snapshot) => {
                return (
                  <div
                    className={classNames('drag-and-drop-area__column-content', {
                      'dragginOver': snapshot.isDraggingOver,
                    })}
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {c.list?.map((card: CustomAny, i: number) => (
                      <Draggable isDragDisabled={isDragDisabled} draggableId={card.id.toString()} index={i} key={card.id}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <DraggableCard
                              card={reformCardData(card)}
                              warnings={warnings}
                              noLinkedEntities={noLinkedEntities}
                              permissionToSeeCardPage={permissionToSeeCardPage}
                              permissionToSeeEstimates={permissionToSeeEstimates}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                );
              }}
            </Droppable>
            {c.pages > 1 && <Button
              label="Load More..."
              htmlType="button"
              className={classNames('drag-and-drop-area__column-loadMore', {
                'loadMoreHidden': c.totalCount <= 50
                                    || currentStatusPagination(c)
                                    || (c.filteredTotalCount <= 50 && isFilterSelected()),
              })}
              onClick={() => {
                const current = columnsPagination.find((el) => el.status === c.status);
                const currentPage = current?.currentPage || 1;
                const currentTotal = current?.pages;
                currentPage !== currentTotal && getNewPortion(currentPage + 1, c.status);
              }}
              designType="dark"
            />}
          </article>)}
        </div>
      </section>
    </DragDropContext>
  );
};

export default DraggableCardsArea;
