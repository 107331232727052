import React from 'react';
import { RectificationEventDtoBase } from '../../../../types/rectificationViewTypes';
import RectificationNoteEvent from './RectificationNoteEvent';
import StatusChangeEvent from '../../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { RectificationEventEnum } from '../../../../enums/RectificationEventEnum';
import RectificationFieldUpdateEvent from './RectificationFieldUpdateEvent';
import AttachmentEvents from '../../../../../../common/components/activity-events/attachment/AttachmentEvents';
import ContactEvents from '../../../../../../common/components/activity-events/contact/ContactEvents';
import JobLocationEvent from '../../../../../../common/components/activity-events/job-location/JobLocationEvent';
import BillingShippingEvents from '../../../../../../common/components/activity-events/billing-shipping/BillingShippingEvents';
import CreationEvent from '../../../../../../common/components/activity-events/creation/CreationEvent';
import { getOldAndNewLocationValues } from '../../../../../../common/utils/getOldAndNewLocationValues';
import { rectificationStatusesSelector } from '../../../../../../store/selectors/coreStatusesSelectors';
import { rectificationEventTypesSelector } from '../../../../../../store/selectors/coreEventTypesSelectors';
import PartRequestLineCreationEvent
  from '../../../../../../common/components/activity-events/part-request-lines-creation-event/PartRequestLineCreationEvent';
import { getPartRequestLineChangeGroupsThunk } from '../../../../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';

type RectificationEventProps = {
  item: RectificationEventDtoBase,
}

const RectificationEvent: React.FC<RectificationEventProps> = ({ item }) => {
  const dispatch = useAppDispatch();
  const rectificationEventTypes = useAppSelector(rectificationEventTypesSelector);
  const rectificationStatuses = useAppSelector(rectificationStatusesSelector);

  const attachmentEvents = [
    RectificationEventEnum.AddingAttachmentWithoutComment,
    RectificationEventEnum.AddingAttachmentWithComment,
    RectificationEventEnum.EditingAttachmentComment,
    RectificationEventEnum.DeletingAttachment,
    RectificationEventEnum.EditingAttachmentVisibility,
  ];
  const contactEvents = [
    RectificationEventEnum.AddingContact,
    RectificationEventEnum.ContactChange,
    RectificationEventEnum.RemovingContact,
  ];
  const billingShippingEvents = [
    RectificationEventEnum.BillingDetailsUpdates,
    RectificationEventEnum.ShippingDetailsUpdates,
  ];

  const rectificationEventRenderFn = (item: RectificationEventDtoBase) => {
    const event = rectificationEventTypes.find((type) => type.value === item.rectificationEventType)?.label || '';
    switch (true) {
      case (event === RectificationEventEnum.Note):
        return <RectificationNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case (event === RectificationEventEnum.RectificationCreation):
        return <CreationEvent entity="Rectification" type="number" value={item.rectificationNumber} />;
      case (event === RectificationEventEnum.RectificationFieldUpdateOrAddingValue):
        return <RectificationFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case (event === RectificationEventEnum.RectificationStatusChange): {
        const prevStatus = rectificationStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = rectificationStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />; }
      case (event === RectificationEventEnum.JobLocationUpdate): {
        const locationValues = getOldAndNewLocationValues(item);
        return <JobLocationEvent {...locationValues} />;
      }
      case (billingShippingEvents.includes(event as RectificationEventEnum)):
        return <BillingShippingEvents
          eventName={event}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (attachmentEvents.includes(event as RectificationEventEnum)):
        return <AttachmentEvents
          eventName={event}
          fileName={item.fileName}
          comment={item.comment}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (contactEvents.includes(event as RectificationEventEnum)):
        return <ContactEvents
          eventName={event}
          contact={item.contact}
          firstName={event === RectificationEventEnum.AddingContact ? item.contact?.firstName : item.firstName}
          lastName={event === RectificationEventEnum.AddingContact ? item.contact?.lastName : item.lastName}
          field={item.field}
          oldValue={item.oldValue}
          newValue={item.newValue}
        />;
      case (event === RectificationEventEnum.PartRequestLineCreationChanges):
        return <PartRequestLineCreationEvent
          getChangesHandle={() => {
            item.groupId && dispatch(getPartRequestLineChangeGroupsThunk({ groupId: item.groupId }));
          }}
        />;
      default: return null;
    }
  };

  return <>{rectificationEventRenderFn(item)}</>;
};

export default RectificationEvent;
