import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import { AttachmentsTableProps } from '../../../../../../core/types/coreTypes';
import EditCommentModal from '../../../../../../common/components/edit-attachment-comment/EditCommentModal';
import { useAppDispatch } from '../../../../../../store/hooks';
import SupplierAttachmentsColumns from './SupplierAttachmentsColumns';
import SupplierAttachmentCell from './SupplierAttachmentCell';
import { editSupplierAttachment } from '../../../../../../store/thunks/suppliers/suppliersAttachmentsThunks';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import AttachmentsTable from '../../../../../../common/components/attachments-table/AttachmentsTable';

const SupplierAttachmentsTable: React.FC<AttachmentsTableProps> = ({
  attachments,
  checkedKeys,
  isAll,
  indeterminate,
  checkAllHandle,
  checkRow,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [isEditModal, setEditModal] = useState(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState('');

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };

  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => id && attachToEdit && dispatch(editSupplierAttachment({
          id: attachToEdit,
          supplierId: +id,
          comment: editedComment.trim(),
          onCancel: cancelHandle,
        }))}
      />
      <AttachmentsTable>
        <SupplierAttachmentsColumns
          isAll={isAll}
          indeterminate={indeterminate}
          checkAll={checkAllHandle}
          totalElements={attachments.length}
        />
        <Tbody>
          {attachments.length > 0
            ? attachments.map((attachment) => {
              return (
                <SupplierAttachmentCell
                  key={attachment.id}
                  attach={attachment}
                  openEditModal={() => {
                    setAttachToEdit(attachment.id);
                    setEditedComment(attachment.comment || '');
                    setEditModal(true);
                  }}
                  checked={checkedKeys.includes(attachment.id)}
                  checkRow={() => checkRow(attachment.id)}
                />
              );
            })
            : <EmptyTableSection text="No records" colSpan={6} />}
        </Tbody>
      </AttachmentsTable>
    </>
  );
};

export default SupplierAttachmentsTable;
