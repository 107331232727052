import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Switch from '../../../../../core/components/switch/Switch';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { getOrderTimelineThunk, postOrderTimelineNoteThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import { orderTimelineFiltersSelector, orderTimelineSelector } from '../../../../../store/selectors/ordersSelector';
import { setOrderTimelineFilters } from '../../../../../store/slices/ordersSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import OrderEvent from './order-details-description/OrderEvent';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';
import { orderEventSourcesValuesSelector, partRequestLineActivityTypesSelector } from '../../../../../store/selectors/coreSelectors';
import { getPartRequestLineActivityTypesThunk } from '../../../../../store/thunks/coreThunk';

const OrderActivitySection: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, totalCount } = useAppSelector(orderTimelineSelector);
  const timelineFilters = useAppSelector(orderTimelineFiltersSelector);
  const { orderSource } = useAppSelector(orderEventSourcesValuesSelector);
  const partRequestLineActivityTypes = useAppSelector(partRequestLineActivityTypesSelector);

  const [value, setValue] = useState('');
  const allowedToAddNote = usePermission(PermissionEnum.OrderViewPage);

  useEffect(() => {
    if (!partRequestLineActivityTypes.length) {
      dispatch(getPartRequestLineActivityTypesThunk());
    }
    // eslint-disable-next-line
  }, [partRequestLineActivityTypes]);

  useEffect(() => {
    id && orderSource && dispatch(getOrderTimelineThunk({
      orderId: +id,
      filters: timelineFilters,
      source: orderSource,
    }));
    // eslint-disable-next-line
  }, [id, timelineFilters]);

  const addNote = () => {
    id && dispatch(postOrderTimelineNoteThunk({
      orderId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <OrderEvent item={el} />,
  }));

  return (
    <section className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(value) => {
            dispatch(setOrderTimelineFilters({
              ...timelineFilters,
              mainOnly: !value,
              page: 1,
            }));
          }}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        totalItems={totalCount}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setOrderTimelineFilters({
            ...timelineFilters,
            page,
          }));
        }}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default OrderActivitySection;
