import { useAppSelector } from '../../../store/hooks';
import {
  permissionModulesSelector,
  usersListFiltersSelector,
} from '../../../store/selectors/settingsSelectors';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';

export const useUserListDictionaries = () => {
  const usersListFilters = useAppSelector(usersListFiltersSelector);
  const permissionModules = useAppSelector(permissionModulesSelector);
  const currentUser = useAppSelector(currentUserSelector);

  return {
    usersListFilters,
    permissionModules,
    currentUser,
  };
};
