import instance from './axiosConfig';
import {
  AssigneeDto,
  AxiosDataListResponse,
  AxiosDictionaryResponse,
  AxiosDownloadUriResponse,
  AxiosSingleResponse,
  CustomDictionaryItem,
  GetAddressSuggestionResponse,
  GetAddressSuggestionsResponse,
} from '../core/types/coreTypes';
import { IUser, UserAccountRoleDto } from '../settings/users-dashboard/types/settingsTypes';
import { DefaultAddressDto } from '../common/types/commonTypes';

export const coreAPI = {
  fetchCustomerAccountTypes(): AxiosDictionaryResponse {
    return instance.get('/customer/app/accountTypes');
  },
  fetchCustomerTypes(): AxiosDictionaryResponse {
    return instance.get('/customer/app/customerTypes');
  },
  fetchJobTypes(): AxiosSingleResponse<{ items: CustomDictionaryItem[] }> {
    return instance.get('/job/app/jobTypes');
  },
  fetchJobCategories(): AxiosDictionaryResponse {
    return instance.get('/job/app/jobCategories');
  },
  fetchPriorityLevels(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/priorityLevels');
  },
  fetchEnquirySources(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/enquirySources');
  },
  fetchEnquiryWarnings(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/warnings');
  },
  fetchJobWarnings(): AxiosDictionaryResponse {
    return instance.get('/job/app/warnings');
  },
  fetchOrderWarnings(): AxiosDictionaryResponse {
    return instance.get('/order/app/warnings');
  },
  fetchRectificationWarnings(): AxiosDictionaryResponse {
    return instance.get('/rectification/app/warnings');
  },
  fetchBusinessAreas(): AxiosSingleResponse<{ items: CustomDictionaryItem[] }> {
    return instance.get('/enquiry/app/businessAreas');
  },
  fetchQuoteLineTaxes(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/quoteLineTaxes');
  },
  fetchAccountAccountTypes(): AxiosDictionaryResponse {
    return instance.get('/account/app/accountType');
  },
  fetchAccountAccountRoles(): AxiosDictionaryResponse {
    return instance.get('/account/app/accountRole');
  },
  fetchAccountPermissionsRoles(accountType?: number): AxiosSingleResponse<{ items: UserAccountRoleDto[] }> {
    return instance.get('/account/userAccountPermission/roles', {
      params: {
        'Filter.AccountType': accountType,
      },
    });
  },
  fetchQuoteUnitsOfMeasure(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/unitsOfMeasure');
  },
  fetchQuoteInfoSources(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/quoteLineInfoSources');
  },
  fetchPricelistTypes(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistTypes');
  },
  fetchUsersCoreDictionary(keyword?: string, haveEverHadAJob?: boolean): AxiosSingleResponse<{ userAccounts: AssigneeDto[] }> {
    return instance.get('/account/userAccount/views/dictionary', {
      params: {
        'Filter.Keyword': keyword,
        'Filter.HaveEverHadAJob': haveEverHadAJob,
      },
    });
  },
  fetchUsersLookup(keyword?: string): AxiosDataListResponse<IUser[]> {
    return instance.get('/account/userAccount', {
      params: {
        'Filter.Keyword': keyword,
      },
    });
  },
  fetchAttachmentTypes(): AxiosDictionaryResponse {
    return instance.get('/attachment/app/attachmentTypes');
  },
  fetchLinksTypes(): AxiosDictionaryResponse {
    return instance.get('/job/app/linkTypes');
  },
  fetchInAppNotificationsTypes(): AxiosDictionaryResponse {
    return instance.get('/account/app/notificationTypes');
  },
  fetchInAppNotificationsEntityTypes(): AxiosDictionaryResponse {
    return instance.get('/account/app/notificationEntityTypes');
  },
  fetchPartRequestLinePriceSources(): AxiosDictionaryResponse {
    return instance.get('/order/app/partRequestLinePriceSources');
  },
  fetchRectificationFaultTypes(): AxiosDictionaryResponse {
    return instance.get('/rectification/app/faultTypes');
  },
  fetchVehicleProfileTypes(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleProfileTypes');
  },
  fetchVehicleFlows(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleFlows');
  },
  fetchVehicleCheckTypes(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleCheckTypes');
  },
  fetchFullVehicleCheckDecisions(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/fullVehicleCheckDecisions');
  },
  fetchHandoverVehicleCheckDecisions(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/handoverVehicleCheckDecisions');
  },
  fetchWeatherConditions(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/weatherConditions');
  },
  fetchVehicleTransportationMethods(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/transportationMethods');
  },
  fetchPartRequestLineTypes(): AxiosDictionaryResponse {
    return instance.get('/order/app/partRequestLineTypes');
  },
  fetchLeaveRequestTypes(): AxiosDictionaryResponse {
    return instance.get('/employee/app/leaveTypes');
  },
  fetchDayParts(): AxiosDictionaryResponse {
    return instance.get('/employee/app/dayParts');
  },
  fetchNominalCodeTypes(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalCodeTypes');
  },
  fetchUnfinishedEntityType(): AxiosDictionaryResponse {
    return instance.get('/report/app/unfinishedEntityType');
  },
  fetchFitterReturnsReportEntityType(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingFitterReturnsReportEntityType');
  },
  fetchSalesByCustomerEntityTypes(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesByCustomerEntityTypes');
  },
  fetchSalesOffsiteEntityTypes(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesOffsiteEntityTypes');
  },
  fetchNominalRecordStates(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalRecordStates');
  },
  fetchNominalRecordTypes(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalRecordTypes');
  },
  fetchPurchaseOrderEventSources(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderEventSources');
  },
  fetchInvoiceDocumentTypes(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/invoiceDocumentTypes');
  },
  fetchInvoiceDocumentOperationTypes(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/invoiceDocumentOperationTypes');
  },
  fetchInvoiceDocumentStatusTransitions(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/invoiceDocumentStatusTransitions');
  },
  fetchVehicleSlidingDoorsQuantities(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/vehicleSlidingDoorsQuantities');
  },
  fetchVehicleInfoSources(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/vehicleInfoSources');
  },
  fetchQuoteLinePriceSources(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/quoteLinePriceSources');
  },
  fetchRectificationTypes(): AxiosDictionaryResponse {
    return instance.get('/rectification/app/rectificationTypes');
  },
  fetchOrderEventSources(): AxiosDictionaryResponse {
    return instance.get('/order/app/orderEventSource');
  },
  fetchSuitableVehicleSources(): AxiosDictionaryResponse {
    return instance.get('/stock/app/suitableVehiclesSources');
  },
  fetchAddressSuggestions(keyword: string, itemsCounts = 6): AxiosSingleResponse<GetAddressSuggestionsResponse> {
    return instance.get('/address/addressSuggestions', {
      params: {
        Keyword: keyword,
        ItemsCount: itemsCounts,
      },
    });
  },
  fetchAddressSuggestionsById(id: string): AxiosSingleResponse<GetAddressSuggestionResponse> {
    return instance.get(`/address/addressSuggestions/${id}`);
  },
  fetchVanlinersOfficeLocation(): AxiosSingleResponse<{defaultAddress: DefaultAddressDto}> {
    return instance.get('address/defaultAddress/vanlinersOfficeLocation');
  },
  fetchPartRequestLineActivityTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestLineActivityTypes');
  },
  fetchCancelledLinesReportEntityTypes(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingCancelledLinesReportEntityTypes');
  },
  fetchNominalRecordVatTypes(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalRecordVatTypes');
  },
  fetchPurchaseOrderPartAmountInputTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderPartAmountInputTypes');
  },
  fetchPrivacyPolicy(): AxiosDownloadUriResponse {
    return instance.get('/policy/privacy');
  },
  fetchGoogleApiKey(): AxiosSingleResponse<{ key: string }> {
    return instance.get('/apiKeys/googleGeocoding');
  },
};
