import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Switch from '../../../../../core/components/switch/Switch';
import { vehicleTimelineSelector, vehicleTimelineFiltersSelector } from '../../../../../store/selectors/vehiclesSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setVehicleTimelineFilters } from '../../../../../store/slices/vehiclesSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../core/components/timeline/Timeline';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import {
  getVehicleActivityLog,
  postVehicleActivityNote,
} from '../../../../../store/thunks/vehicles/vehicleViewPageThunks';
import VehicleEvent from './vehicle-events/VehicleEvent';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../common/components/activity-events/ActivityNoteInput';

const VehicleActivityLog: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const timelineFilters = useAppSelector(vehicleTimelineFiltersSelector);
  const { totalCount, items } = useAppSelector(vehicleTimelineSelector);
  const allowedToAddNote = usePermission(PermissionEnum.VehicleViewPage);

  const [value, setValue] = useState('');

  useEffect(() => {
    id && dispatch(getVehicleActivityLog({
      vehicleId: +id,
      filters: timelineFilters,
    }));
    // eslint-disable-next-line
    }, [id, timelineFilters]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <VehicleEvent item={el} />,
  }));

  const addNote = () => {
    id && dispatch(postVehicleActivityNote({
      vehicleId: +id,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };
  return (
    <section className="detailsWrap__activity info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(val) => dispatch(setVehicleTimelineFilters({ ...timelineFilters, mainOnly: !val, page: 1 }))}
          textLeft="Main events"
          textRight="Full log"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setVehicleTimelineFilters({ ...timelineFilters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default VehicleActivityLog;
