import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, DictionaryItem, DictionaryStringValueItem, ResponseResult, ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { setDashboardLoading, setLoading } from '../../slices/coreSlice';
import { employeeAPI } from '../../../api/employeeApi';
import { EmployeeListItemDto, employeesFiltersType } from '../../../employees/dashboard/types/employeesDashTypes';
import { coreStatusesAPI } from '../../../api/core/coreStatusesApi';

export const getEmployeeDashStatusesThunk = createAsyncThunk<
ResponseSingleResult<{ items: DictionaryItem[] }>>(
  'get/EmployeeDashStatuses',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await coreStatusesAPI.fetchEmployeeStatuses();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeDashTitlesThunk = createAsyncThunk<
ResponseSingleResult<{ items: DictionaryStringValueItem[] }>>(
  'get/EmployeeDashTitles',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeesDashTitles();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEmployeeDashboardThunk = createAsyncThunk<
ResponseResult<EmployeeListItemDto[]>,
{ filters: employeesFiltersType, signal?: AbortSignal }
>(
  'get/EmployeeDashboard',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    try {
      const response = await employeeAPI.fetchEmployeeDashboard(filters, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
