export enum HolidaysOrderingEnum {
  Date = 'Date',
}

export enum SelectEmployeesOptionsEnum {
  BY_DEPARTMENT = 'by-department',
  BY_JOB_TITLE = 'by-job-title',
  SPECIFIC_EMPLOYEE = 'specific-employee',
}

export enum AffectedPeriodOptionsEnum {
  DATE_PERIOD = 'date-period',
  SPECIFIC_DATES = 'specific-dates',
}
