import qs from 'qs';
import instance from './axiosConfig';
import {
  PartDtoType, PatchPartsKitDataType, StockFiltersType,
} from '../stock/search/types/stockSearchTypes';
import {
  PartsKitComponentDto,
  PartsKitsDto,
  PartsKitsFiltersType,
} from '../stock/parts-kits/dashboard/types/partsKitsTypes';
import {
  CustomPartDataType,
  PartsKitAffectedEntityDto,
  PartsKitAffectedItemsFilters, PartsKitComponentGroupsItemDto,
  PartsKitComponentsFiltersType,
  PartsKitComponentsSummaryType,
  PartsKitComponentsTimelineGroupFilters, PartsKitEventDtoBase,
} from '../stock/parts-kits/view-page/types/partsKitViewPageTypes';
import { CreatePartsKitReqType } from '../stock/parts-kits/create/types/CreatePartsKitTypes';
import {
  GetPartQuantitiesDataType, GetPartQuantitiesParams,
  GetPartResponse,
  PartAffectedEntitiesFilters, PartAffectedEntityDto,
  PartQtyBaseModelType,
  PartReorderLevels, PartViewEventDtoBase,
  PatchPartQtyType,
} from '../stock/part-view-page/types/types';
import { CreatePartReqType, CreatePartResType } from '../stock/create-part/types/createPartTypes';
import {
  CreatePurchaseOrderDataType,
  CreatePurchaseOrderResponseType,
} from '../stock/purchase-orders/create/types/createPurchaseOrderTypes';
import {
  GetPurchaseOrderPartsResponse, GetPurchaseOrderPartSummaryResponse,
  PartFailedQtyRestModel,
  PatchPurchaseOrderPartDataType,
  PatchPurchaseOrderRestModel,
  PostPurchaseOrderPartDataType,
  PurchaseOrderEventDtoBase,
  PurchaseOrderPartsListFiltersType,
  PurchaseOrderSummaryResponseType,
  PutPurchaseOrderRestModel,
} from '../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import {
  GetPurchaseOrderDictionaryResponse,
  GetPurchaseOrderResponse,
  PurchaseOrderDashFiltersType,
  PurchaseOrdersResponseResult,
} from '../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import {
  AxiosCreatedAttachmentsResponse, AxiosCreatedIdResponse,
  AxiosCreateUploadUriResponse,
  AxiosDataListResponse,
  AxiosDictionaryResponse, AxiosDownloadUriResponse, AxiosGenericResponse,
  AxiosGetAttachmentFileResponse,
  AxiosGetPagedAttachmentsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters, DictionaryItem, GenericObject,
  PagingType,
  PostAttachReqType,
  UploadUriDto,
} from '../core/types/coreTypes';
import {
  AffectedEntityType,
  BulkPatchReqLinesDataType,
  CommonCreatePartLineReqDto,
  CreatePartRequestPartsKitLine,
  CustomLineReqType, DefaultAddressDto,
  ExtendedTimelineFilters, PartProductDictionaryType, PartProductSourceTotalFetchParams, PartProductTotalType,
  PartReqLinesFilters, PartRequestLineDto,
  PatchPartReqLineDto, PartProductSourceDictionaryParams, PartRequestLineChangeGroupLineDto, GetPartRequestSummaryReviewParams,
} from '../common/types/commonTypes';
import {
  AllocatePartQuantityFilters,
  AllocatePricelistQuantityDto,
  ConfirmedPartRequestDto,
  ConfirmedPartRequestViewsParams,
  GetPartRequestDashboardResponse,
  PartReqPartQuantityFilters,
  PartRequestByLineFilters,
  PutPartRequestLineStatusRestModel,
  StockPartProductSourceTotal,
  StockPartRequestFiltersType,
} from '../stock/stock-part-requests/types/stockPartRequestTypes';
import {
  GetPartRequestLineStateSummaryResponse,
  GetStockPartRequestResponse, StockPartRequestEventDtoBase,
} from '../stock/stock-part-request-view/types/StockPartrequestViewTypes';
import {
  AffectedCategoriesFiltersType,
  PartCategoriesFilters, PartCategoryDto,
  PartSubcategoriesFilters, PartSubcategoryDto,
} from '../stock/part-categories/types/partCategoriesTypes';
import {
  GetPartRequestStatisticsResponse,
  GetPartStatisticsResponse,
  GetPurchaseOrderStatisticsResponse,
} from '../stock/dashboard/types/types';

export const stockApi = {
  fetchPurchaseOrderPartVat(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderPartVat');
  },
  fetchPurchaseOrderFailReason(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderFailReason');
  },
  fetchPartsKitsList(
    filters: PartsKitsFiltersType,
    includeName?: boolean,
    includePartsKitCode?: boolean,
    includeDescription?: boolean,
    signal?: AbortSignal,
  ): AxiosDataListResponse<PartsKitsDto[]> {
    return instance.get('/stock/partsKit', {
      params: {
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludeName': includeName,
        'Filter.SearchParameters.IncludePartsKitCode': includePartsKitCode,
        'Filter.SearchParameters.IncludeDescription': includeDescription,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'Filter.PartId': filters.partId,
        'Filter.PartsKitCode': filters.partsKitCode,
        'Filter.Name': filters.name,
        'Filter.Description': filters.description,
        'Filter.CategoryIds': filters.categories,
        'Filter.BusinessAreas': filters.businessAreas,
        'Filter.Statuses': filters.statuses,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPartsKitById(id: number): AxiosSingleResponse<{ partsKit: PartsKitsDto }> {
    return instance.get(`/stock/partsKit/${id}`);
  },
  getPartsKitAffected(id: number, filters: PartsKitAffectedItemsFilters): AxiosDataListResponse<PartsKitAffectedEntityDto[]> {
    return instance.get(`/stock/partsKit/${id}/affectedEntity`, {
      params: {
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        BusinessAreas: filters.businessAreas,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  duplicatePartsKit(id: number, code: string): AxiosSingleResponse<{ createdId: number, partsKitName: string | null }> {
    return instance.post(`/stock/partsKit/${id}`, {
      'newPartsKitCode': code,
    });
  },
  activatePartsKit(id: number): AxiosNullableResponse {
    return instance.post(`/stock/partsKit/${id}/activate`);
  },
  inactivatePartsKit(id: number): AxiosNullableResponse {
    return instance.post(`/stock/partsKit/${id}/inactivate`);
  },
  deletePartsKit(id: number): AxiosNullableResponse {
    return instance.delete(`/stock/partsKit/${id}`);
  },
  fetchPartsKitAttachments(
    partsKitId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/stock/partsKits/${partsKitId}/attachments`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  deletePartsKitAttachment(id: number, partsKitId: number): AxiosNullableResponse {
    return instance.delete(`/stock/partsKits/${partsKitId}/attachments/${id}`);
  },
  getPartsKitAttachmentById(id: number, partsKitId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/stock/partsKits/${partsKitId}/attachments/${id}`);
  },
  postPartsKitAttachmentUploadUri(partsKitId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/stock/partsKits/${partsKitId}/attachments/uploadUri`, { uploadUriDtos });
  },
  postPartsKitAttachment(partsKitId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/stock/partsKits/${partsKitId}/attachments`, { objectId: partsKitId, attachments });
  },
  editPartsKitAttachment(id: number, partsKitId: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/stock/partsKits/${partsKitId}/attachments/${id}`, { comment });
  },
  deleteMultiplePartsKitAttachment(attachmentIds: number[], partsKitId: number): AxiosNullableResponse {
    return instance.delete(`/stock/partsKits/${partsKitId}/attachments`, { data: { attachmentIds } });
  },
  fetchPartTypes(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partTypes');
  },
  fetchStockParts(filters: StockFiltersType, signal?: AbortSignal): AxiosDataListResponse<PartDtoType[], { allItemIds: number[] }> {
    return instance.get('/stock/parts', {
      params: {
        'Filter.Keyword': filters.keyword,
        'Filter.CategoryIds': filters.categoryIds,
        'Filter.SubcategoryIds': filters.subcategoryIds,
        'Filter.Types': filters.types,
        'Filter.IsCorePart': filters.isCorePart,
        'Filter.BusinessAreas': filters.businessAreas,
        'Filter.ExceptBusinessAreas': filters.exceptBusinessAreas,
        'Filter.Statuses': filters.statuses,
        'Filter.VehicleIds': filters.vehicleIds,
        'Filter.PartCode': filters.partCode,
        'Filter.ManufacturerCode': filters.manufacturerCode,
        'Filter.PartName': filters.name,
        'Filter.HasWarningReorderShortage': filters.hasWarningReorderShortage,
        'Filter.HasCriticalReorderShortage': filters.hasCriticalReorderShortage,
        'Filter.PricelistId': filters.pricelistId,
        'Filter.CanBeAddedToPricelistId': filters.canBeAddedToPricelistId,
        'Filter.OnlySuitableForQuoteVehicleId': filters.onlySuitableForQuoteVehicleId,
        'Filter.Description': filters.description,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  patchStockPartsKit(id: number, data: Partial<PatchPartsKitDataType>): AxiosNullableResponse {
    return instance.patch(`/stock/partsKit/${id}`, { ...data });
  },
  createPartsKit(data: CreatePartsKitReqType): AxiosSingleResponse<{ createdId: number, partsKitName: string | null }> {
    return instance.post('/stock/partsKit', { ...data });
  },
  fetchPartsKitComponentTax(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsKitComponentTax');
  },
  fetchPartsKitComponents(
    id: number,
    filters?: PartsKitComponentsFiltersType,
  ): AxiosSingleResponse<{ components: PartsKitComponentDto[], allItemIds: number[] }> {
    return instance.get(`/stock/partsKit/${id}/component`, {
      params: {
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'Filter.CategoryIds': filters?.categoryIds,
        'Filter.PartNumber': filters?.partNumber,
        'Filter.PartName': filters?.name,
        'Filter.MinQuantity': filters?.quantity?.min,
        'Filter.MaxQuantity': filters?.quantity?.max,
        'Filter.MinUnitPrice': filters?.unitPrice?.min,
        'Filter.MaxUnitPrice': filters?.unitPrice?.max,
        'Filter.MinNetPrice': filters?.netPrice?.min,
        'Filter.MaxNetPrice': filters?.netPrice?.max,
        'Filter.Taxes': filters?.taxes,
        'Filter.MinWeight': filters?.weight?.min,
        'Filter.MaxWeight': filters?.weight?.max,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  createPartComponent(id: number, partIds: number[]): AxiosSingleResponse<{ createdIds: number[] }> {
    return instance.post(`/stock/partsKit/${id}/partComponent`, { 'partIds': partIds });
  },
  createCustomComponent(id: number, customPartData: CustomPartDataType): AxiosSingleResponse<{ createdId: number }> {
    return instance.post(`/stock/partsKit/${id}/customComponent`, { ...customPartData });
  },
  deleteComponent(partsKitId: number, componentId: number): AxiosNullableResponse {
    return instance.delete(`/stock/partsKit/${partsKitId}/component/${componentId}`);
  },
  bulkDeleteComponents(partsKitId: number, componentIds?: number[]): AxiosNullableResponse {
    return instance.delete(`/stock/partsKit/${partsKitId}/component`, { data: { componentIds } });
  },
  patchPartsKitComponent(id: number, data: Partial<CustomPartDataType>): AxiosNullableResponse {
    return instance.patch(`/stock/partsKit/component/${id}`, { ...data });
  },
  fetchStockComponentsSummary(id: number): AxiosSingleResponse<PartsKitComponentsSummaryType> {
    return instance.get(`/stock/partsKit/${id}/component/summary`);
  },
  fetchPartsStatistic(): AxiosSingleResponse<GetPartStatisticsResponse> {
    return instance.get('/stock/parts/statistic');
  },
  fetchPurchaseOrderStatistic(): AxiosSingleResponse<GetPurchaseOrderStatisticsResponse> {
    return instance.get('/stock/purchaseOrder/statistic');
  },
  fetchPartRequestStatistic(): AxiosSingleResponse<GetPartRequestStatisticsResponse> {
    return instance.get('/stock/partRequests/statistic');
  },
  fetchStockPartById(id: number): AxiosSingleResponse<GetPartResponse> {
    return instance.get(`/stock/parts/${id}`);
  },
  getStockPartAffected(id: number, filters: PartAffectedEntitiesFilters): AxiosDataListResponse<PartAffectedEntityDto[]> {
    return instance.get(`/stock/parts/${id}/affectedEntities`, {
      params: {
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        BusinessAreas: filters.businessAreas,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  patchStockPart(id: number, data: Partial<PartDtoType>): AxiosNullableResponse {
    return instance.patch(`/stock/parts/${id}`, { ...data });
  },
  createPart(data: CreatePartReqType): AxiosSingleResponse<CreatePartResType> {
    return instance.post('/stock/parts', { ...data });
  },
  deletePart(id: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/${id}`);
  },
  reorderLevels(id: number, data: PartReorderLevels): AxiosSingleResponse<PartReorderLevels> {
    return instance.put(`/stock/parts/${id}/reorderlevels`, { ...data });
  },
  addPartImage(id: number, data: UploadUriDto): AxiosCreatedAttachmentsResponse {
    return instance.post(`/stock/parts/${id}/image`, { ...data });
  },
  fetchPartViewPartsKits(id: number, filters: PagingType): AxiosDataListResponse<PartsKitsDto[]> {
    return instance.get(`/stock/parts/${id}/partkits`, {
      params: {
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  fetchPartAttachments(
    partId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/stock/parts/${partId}/attachments`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  deletePartAttachment(id: number, partId: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/${partId}/attachments/${id}`);
  },
  getPartAttachmentById(id: number, partId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/stock/parts/${partId}/attachments/${id}`);
  },
  postPartAttachmentUploadUri(partId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/stock/parts/${partId}/attachments/uploadUri`, { uploadUriDtos });
  },
  postPartAttachment(partId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`stock/parts/${partId}/attachments`, { objectId: partId, attachments });
  },
  editPartAttachment(id: number, partId: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/stock/parts/${partId}/attachments/${id}`, { comment });
  },
  deleteMultiplePartAttachment(attachmentIds: number[], partId: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/${partId}/attachments`, { data: { attachmentIds } });
  },
  fetchMaxPurchaseOrderNumber(): AxiosSingleResponse<{ maxPurchaseOrderNumber: string | null }> {
    return instance.get('/stock/purchaseOrder/maxPurchaseOrderNumber');
  },
  createPurchaseOrder(values: CreatePurchaseOrderDataType): AxiosSingleResponse<{
    createdPurchaseOrders: CreatePurchaseOrderResponseType[],
  }> {
    return instance.post('/stock/purchaseOrder', { ...values });
  },
  fetchPurchaseOrdersOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrdersOrdering');
  },
  fetchPurchaseOrders(
    filters: PurchaseOrderDashFiltersType,
    signal?: AbortSignal,
    isStockModule?: boolean,
  ): AxiosGenericResponse<PurchaseOrdersResponseResult> {
    const hasDraftInvoiceDocuments = filters.isActive && filters.hasDraftInvoiceDocuments?.length
      ? filters.hasDraftInvoiceDocuments.map((el) => el === 1)
      : undefined;
    return instance.get('/stock/purchaseOrder', {
      params: {
        'IsActive': filters.isActive,
        'Filter.PartId': filters.partId,
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludePurchaseOrderNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeSupplierName': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeSupplierAccountNumber': filters.keyword ? !!isStockModule : undefined,
        'Filter.AssigneeIds': filters.assigneesIds,
        'Filter.Statuses': filters.statuses,
        'Filter.HasDraftInvoiceDocuments': hasDraftInvoiceDocuments,
        'Filter.DueDate.From': filters.dueDate?.min,
        'Filter.DueDate.To': filters.dueDate?.max,
        'Filter.CreatedDate.From': filters.createdDate?.min,
        'Filter.CreatedDate.To': filters.createdDate?.max,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchPurchaseOrderAttachments(
    purchaseOrderId: number,
    filters: CoreAttachmentsFilters,
  ): AxiosGetPagedAttachmentsResponse {
    return instance.get(
      `/stock/purchaseOrder/${purchaseOrderId}/attachment`,
      {
        params: {
          'Filter.Name': filters.name,
          'Filter.MinUploadDate': filters.uploadDate?.min,
          'Filter.MaxUploadDate': filters.uploadDate?.max,
          'Filter.Comment': filters.comment,
          'PagingOptions.Page': filters.page,
          'PagingOptions.ItemsPerPage': filters.pageSize,
          'OrderOptions.Field': filters.order?.field,
          'OrderOptions.Ascending': filters.order?.isAsc,
        },
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  postPurchaseOrderAttachment(purchaseOrderId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/stock/purchaseOrder/${purchaseOrderId}/attachment`, { objectId: purchaseOrderId, attachments });
  },
  postPurchaseOrderAttachmentUploadUri(
    purchaseOrderId: number,
    uploadUriDtos: UploadUriDto[],
  ): AxiosCreateUploadUriResponse {
    return instance.post(`/stock/purchaseOrder/${purchaseOrderId}/attachment/uploadUri`, { uploadUriDtos });
  },
  editPurchaseOrderAttachment(id: number, purchaseOrderId: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/stock/purchaseOrder/${purchaseOrderId}/attachment/${id}`, { comment });
  },
  deletePurchaseOrderAttachment(id: number, purchaseOrderId: number): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${purchaseOrderId}/attachment/${id}`);
  },
  deleteMultiplePurchaseOrderAttachment(purchaseOrderId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${purchaseOrderId}/attachment`, { data: { attachmentIds } });
  },
  getPurchaseOrderAttachmentById(id: number, purchaseOrderId: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/stock/purchaseOrder/${purchaseOrderId}/attachment/${id}`);
  },
  fetchPurchaseOrderById(id: number): AxiosGenericResponse<GetPurchaseOrderResponse> {
    return instance.get(`/stock/purchaseOrder/${id}`);
  },
  patchPurchaseOrderById(id: number, data: PatchPurchaseOrderRestModel): AxiosNullableResponse {
    return instance.patch(`/stock/purchaseOrder/${id}`, { ...data });
  },
  putPurchaseOrderById(id: number, data: PutPurchaseOrderRestModel): AxiosNullableResponse {
    return instance.put(`/stock/purchaseOrder/${id}/status`, { ...data });
  },
  fetchPurchaseOrderTimeline(
    id: number,
    filters: ExtendedTimelineFilters,
    source: number,
  ): AxiosDataListResponse<PurchaseOrderEventDtoBase[]> {
    return instance.get('/stock/purchaseOrder/timeline', {
      params: {
        PurchaseOrderId: id,
        MainOnly: filters.mainOnly,
        Source: source,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  createPurchaseOrderNote(content: string, purchaseOrderId: number, eventSource: number): AxiosCreatedIdResponse {
    return instance.post('/purchaseOrder/note', { content, purchaseOrderId, eventSource });
  },
  editPurchaseOrderNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/purchaseOrder/note/${id}`, { content });
  },
  deletePurchaseOrderNote(id: number): AxiosNullableResponse {
    return instance.delete(`/purchaseOrder/note/${id}`);
  },
  deletePurchaseOrder(id: number): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${id}`);
  },
  fetchPurchaseOrderPartsList(
    purchaseOrderId: number,
    filters?: PurchaseOrderPartsListFiltersType,
  ): AxiosSingleResponse<GetPurchaseOrderPartsResponse> {
    return instance.get(`/stock/purchaseOrder/${purchaseOrderId}/part`, {
      params: {
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'Filter.PartNumber': filters?.partNumber,
        'Filter.PartName': filters?.partName,
        'Filter.RequestedQuantity.From': filters?.quantity?.min,
        'Filter.RequestedQuantity.To': filters?.quantity?.max,
        'Filter.States': filters?.states,
        'Filter.NominalCodeIds': filters?.nominalCodes,
        'Filter.RequestedNet.From': filters?.net?.min,
        'Filter.RequestedNet.To': filters?.net?.max,
        'Filter.UnassignedNet.From': filters?.unassignedNet?.min,
        'Filter.UnassignedNet.To': filters?.unassignedNet?.max,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  addPartToPurchaseOrder(purchaseOrderId: number, values: PostPurchaseOrderPartDataType): AxiosCreatedIdResponse {
    return instance.post(`/stock/purchaseOrder/${purchaseOrderId}/part`, { ...values });
  },
  patchPartToPurchaseOrder(
    purchaseOrderId: number,
    partId: number,
    values: PatchPurchaseOrderPartDataType,
  ): AxiosSingleResponse<{ warnings: GenericObject<string> }> {
    return instance.patch(`/stock/purchaseOrder/${purchaseOrderId}/part/${partId}`, { ...values });
  },
  deletePurchaseOrderPartBulk(purchaseOrderId: number, purchaseOrderPartIds: number[]): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${purchaseOrderId}/part`, { data: { purchaseOrderPartIds } });
  },
  deletePurchaseOrderPart(purchaseOrderId: number, partId: number): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${purchaseOrderId}/part/${partId}`);
  },
  fetchPurchaseOrderSummary(purchaseOrderId: number): AxiosSingleResponse<PurchaseOrderSummaryResponseType> {
    return instance.get(`/stock/purchaseOrder/${purchaseOrderId}/summary`);
  },
  fetchPurchaseOrderPartState(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderPartState');
  },
  addFailedPartQuantity(purchaseOrderId: number, partId: number, data: PartFailedQtyRestModel): AxiosCreatedIdResponse {
    return instance.post(`/stock/purchaseOrder/${purchaseOrderId}/part/${partId}/failedQuantity`, { ...data });
  },
  deleteFailedPartQtyLine(purchaseOrderId: number, partId: number, failedQuantityId: number): AxiosNullableResponse {
    return instance.delete(`/stock/purchaseOrder/${purchaseOrderId}/part/${partId}/failedQuantity/${failedQuantityId}`);
  },
  fetchPartQuantities(params: GetPartQuantitiesParams): AxiosSingleResponse<GetPartQuantitiesDataType> {
    return instance.get(`/stock/parts/${params.partId}/quantities`, {
      params: {
        'Filter.Statuses': params.statuses,
        'Filter.PurchaseOrderIds': params.purchaseOrderIds,
        'Filter.HasSellingPrice': params.hasSellingPrice,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'Filter.OwnerIds': params.ownerIds,
        'Filter.PartRequestLineId': params.partRequestLineId,
        'Filter.IsAvailableForPartRequestId': params.isAvailableForPartRequestId,
        'Filter.LinkedTo': params.linkedTo,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchEditReadyCollectedPartQuantities(
    partId: number,
    lineId: number,
    filters: PartReqPartQuantityFilters,
  ): AxiosSingleResponse<GetPartQuantitiesDataType> {
    return instance.get(`/stock/parts/${partId}/quantities`, {
      params: {
        'Filter.Statuses': filters.statuses,
        'Filter.PurchaseOrderIds': filters.purchaseOrderIds,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
        'Filter.OwnerIds': filters.ownerIds,
        'Filter.PartRequestLineId': lineId,
        'Filter.IsAvailableForPartRequestId': filters.isAvailableForPartRequestId,
        'Filter.LinkedTo': filters.linkedTo,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchAllocatedPartQuantities(
    partId: number,
    filters?: AllocatePartQuantityFilters,
  ): AxiosSingleResponse<GetPartQuantitiesDataType> {
    return instance.get(`/stock/parts/${partId}/quantities`, {
      params: {
        'Filter.Statuses': filters?.statuses,
        'OrderOptions.Field': filters?.order?.field,
        'OrderOptions.Ascending': filters?.order?.isAsc,
        'Filter.IsAvailableForPartRequestId': filters?.isAvailableForPartRequestId,
        'Filter.HasSellingPrice': true,
        'Filter.LinkedTo': filters?.linkedTo,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  deletePartQuantities(partId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/${partId}/quantities/${id}`);
  },
  patchPartQuantities(partId: number, id: number, data: PatchPartQtyType): AxiosNullableResponse {
    return instance.patch(`/stock/parts/${partId}/quantities/${id}`, { ...data });
  },
  postPartQuantities(partId: number, data: PartQtyBaseModelType): AxiosSingleResponse<{ partId: number, id: number }> {
    return instance.post(`/stock/parts/${partId}/quantities`, { partQuantityModel: { ...data } });
  },
  splitPartQuantities(partId: number, partQuantityId: number, quantities: [number, number]): AxiosNullableResponse {
    return instance.post(`/stock/parts/${partId}/quantities/${partQuantityId}/split`, { quantities });
  },
  exportPurchaseOrderToPdf(id: number): AxiosDownloadUriResponse {
    return instance.get(`/stock/purchaseOrder/${id}/pdfFile`);
  },
  fetchPartsKitTimeline(partsKitId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<PartsKitEventDtoBase[]> {
    return instance.get('/stock/partsKit/timeline', {
      params: {
        PartsKitId: partsKitId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  addPartsKitTimelineNote(content: string, partsKitId: number): AxiosCreatedIdResponse {
    return instance.post('/partsKit/note', { content, partsKitId });
  },
  editPartsKitTimelineNote(content: string, partsKitId: number): AxiosNullableResponse {
    return instance.put(`/partsKit/note/${partsKitId}`, { content });
  },
  deletePartsKitTimelineNote(noteId: number): AxiosNullableResponse {
    return instance.delete(`/partsKit/note/${noteId}`);
  },
  getPartsKitTimelineComponentsGroupBefore(
    lineGroupId: number,
    filters: PartsKitComponentsTimelineGroupFilters,
  ): AxiosDataListResponse<PartsKitComponentGroupsItemDto[]> {
    return instance.get('/stock/partsKit/partsKitComponentsGroupBefore', {
      params: {
        PartsKitGroupId: lineGroupId,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
    });
  },
  getPartsKitTimelineComponentsGroupAfter(
    lineGroupId: number,
    filters: PartsKitComponentsTimelineGroupFilters,
  ): AxiosDataListResponse<PartsKitComponentGroupsItemDto[]> {
    return instance.get('/stock/partsKit/partsKitComponentsGroupAfter', {
      params: {
        PartsKitGroupId: lineGroupId,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
    });
  },
  fetchStockPartRequestLinkedEntityType(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestLinkedEntityType');
  },
  fetchStockPartsRequestDashboard(
    filters: StockPartRequestFiltersType,
    signal?: AbortSignal,
  ): AxiosGenericResponse<GetPartRequestDashboardResponse> {
    return instance.get('/stock/partRequests/views/dashboard', {
      params: {
        'Filter.SearchParameters.Keyword': filters.keyword,
        'Filter.SearchParameters.IncludePartRequestNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeLinkedEntityNumber': filters.keyword ? true : undefined,
        'Filter.SearchParameters.IncludeCustomerName': filters.keyword ? true : undefined,
        'Filter.Statuses': [filters.status],
        'Filter.DueDate.From': filters.dueDate?.min,
        'Filter.DueDate.To': filters.dueDate?.max,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      signal,
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  fetchStockPartRequestById(id: number): AxiosSingleResponse<GetStockPartRequestResponse> {
    return instance.get(`/stock/partRequests/${id}`);
  },
  fetchStockPartQuantitySources(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partQuantitySources');
  },
  fetchStockPartRequestTimeline(
    partRequestId: number,
    filters: ExtendedTimelineFilters,
  ): AxiosDataListResponse<StockPartRequestEventDtoBase[]> {
    return instance.get('/stock/partRequests/timeline', {
      params: {
        'PartRequestId': partRequestId,
        'MainOnly': filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  postStockPartRequestTimelineNote(partRequestId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/partRequest/note', {
      partRequestId,
      content,
    });
  },
  editStockPartRequestTimelineNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/partRequest/note/${id}`, { content });
  },
  deleteStockPartRequestTimelineNote(id: number): AxiosNullableResponse {
    return instance.delete(`/partRequest/note/${id}`);
  },
  putStockPartRequestLine(partRequestId: number, lineId: number, data: PutPartRequestLineStatusRestModel): AxiosNullableResponse {
    return instance.put(`/stock/partRequests/${partRequestId}/lines/${lineId}/status`, { ...data });
  },
  fetchStockPartRequestLineProductSourceTotal(
    partRequestId: number,
    lineId: number,
    stockQty: number | null,
    pricelists: Array<{ pricelistId: number, quantity: number }> | null,
    partId?: number,
  ): AxiosSingleResponse<StockPartProductSourceTotal> {
    const listString = pricelists
      ? pricelists
      // eslint-disable-next-line max-len
        .map((l, i) => (`Pricelists[${i}].pricelistId=${l.pricelistId}&Pricelists[${i}].quantity=${l.quantity}${i === pricelists.length - 1 ? '' : '&'}`))
        .join('')
      : 'Pricelists=null&';
    return instance.get(`/stock/partRequests/${partRequestId}/lines/${lineId}/partProductSourceTotal?${listString}`, {
      params: {
        StockQuantity: stockQty,
        PartId: partId,
      },
    });
  },
  fetchPartProductSourceTotal(
    partId: number,
    params: PartProductSourceTotalFetchParams,
  ): AxiosSingleResponse<PartProductTotalType> {
    const {
      stockQuantity, pricelists, manualInputQuantity, manualInputUnitPrice, priceDate,
    } = params;
    const listString = pricelists
      ? pricelists
        .map((l, i) => (`Pricelists[${i}].pricelistId=${l.pricelistId}&Pricelists[${i}].quantity=${l.quantity}${i === pricelists.length - 1
          ? ''
          : '&'}`))
        .join('')
      : 'Pricelists=null&';
    return instance.get(`/stock/parts/${partId}/productSourceTotal?${listString}`, {
      params: {
        StockQuantity: stockQuantity,
        ManualInputQuantity: manualInputQuantity,
        ManualInputUnitPrice: manualInputUnitPrice,
        PriceDate: priceDate,
      },
    });
  },
  postAllocatePricelistQuantity(
    partRequestId: number,
    lineId: number,
    data: Array<AllocatePricelistQuantityDto>,
  ): AxiosNullableResponse {
    return instance.post(`/stock/partRequests/${partRequestId}/lines/${lineId}/pricelistQuantity`, { quantities: data });
  },
  fetchPartCategories(filters?: PartCategoriesFilters): AxiosDataListResponse<PartCategoryDto[]> {
    return instance.get('/stock/parts/categories', {
      params: {
        'Filter.Keyword': filters?.keyword,
        'PagingOptions.Page': filters?.page,
        'PagingOptions.ItemsPerPage': filters?.pageSize,
      },
    });
  },
  postPartCategory(name: string): AxiosCreatedIdResponse {
    return instance.post('/stock/parts/categories', { name });
  },
  putPartCategory(id: number, name: string): AxiosNullableResponse {
    return instance.put(`/stock/parts/categories/${id}`, { name });
  },
  deletePartCategory(id: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/categories/${id}`);
  },
  fetchAffectedPartCategories(categoryId: number, params: AffectedCategoriesFiltersType): AxiosDataListResponse<AffectedEntityType[]> {
    return instance.get(`/stock/parts/categories/${categoryId}/affectedEntities`, {
      params: {
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
        'OrderOptions.Field': params?.order?.field,
        'OrderOptions.Ascending': params?.order?.isAsc,
      },
    });
  },
  fetchPartSubcategories(params?: PartSubcategoriesFilters): AxiosDataListResponse<PartSubcategoryDto[]> {
    return instance.get('/stock/parts/categories/subcategories', {
      params: {
        'Filter.CategoryIds': params?.categoryIds,
        'Filter.Keyword': params?.keyword,
        'Filter.CreatedAt.From': params?.createdAt?.min,
        'Filter.CreatedAt.To': params?.createdAt?.max,
        'Filter.UpdatedAt.From': params?.updatedAt?.min,
        'Filter.UpdatedAt.To': params?.updatedAt?.max,
        'OrderOptions.Field': params?.order?.field,
        'OrderOptions.Ascending': params?.order?.isAsc,
        'PagingOptions.Page': params?.page,
        'PagingOptions.ItemsPerPage': params?.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
    });
  },
  postPartSubcategory(categoryId: number, name: string): AxiosCreatedIdResponse {
    return instance.post('/stock/parts/categories/subcategories', { categoryId, name });
  },
  putPartSubcategory(id: number, name: string): AxiosNullableResponse {
    return instance.put(`/stock/parts/categories/subcategories/${id}`, { name });
  },
  deletePartSubcategory(id: number): AxiosNullableResponse {
    return instance.delete(`/stock/parts/categories/subcategories/${id}`);
  },
  fetchAffectedPartSubcategories(
    subcategoryId: number,
    params: AffectedCategoriesFiltersType,
  ): AxiosDataListResponse<AffectedEntityType[]> {
    return instance.get(`/stock/parts/categories/subcategories/${subcategoryId}/affectedEntities`, {
      params: {
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
        'OrderOptions.Field': params?.order?.field,
        'OrderOptions.Ascending': params?.order?.isAsc,
      },
    });
  },
  fetchPartRequestLines(
    params: PartReqLinesFilters,
    showSubmittedLines?: boolean,
    signal?: AbortSignal,
  ): AxiosDataListResponse<PartRequestLineDto[], {
      allItemIds: number[],
      nominalCodes: DictionaryItem[],
    }> {
    return instance.get('/stock/partRequests/lines', {
      params: {
        'Filter.PartRequestIds': params.partRequestIds,
        'ShowSubmittedLines': !!showSubmittedLines,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'Filter.IsCustomLine': params.isCustomLine,
        'Filter.CategoryIds': params.categories,
        'Filter.ProductNumber': params.productNumber,
        'Filter.ProductName': params.productName,
        'Filter.Notes': params.notes,
        'Filter.PriceSources': params.priceSources,
        'Filter.Statuses': params.statuses,
        'Filter.PurchaseOrderNumber': params.purchaseOrderNumber,
        'Filter.OnlyAvailableForPurchaseOrderGeneration': params.onlyAvailableForPurchaseOrderGeneration,
        'Filter.NominalCodeIds': params?.nominalCodeIds,
        'AdditionalEvaluationsParameters.NeedToEvaluateJobQualityCheckStatus': params?.needToEvaluateJobQualityCheckStatus,
        'AdditionalEvaluationsParameters.JobQualityCheckId': params?.jobQualityCheckId,
        'AdditionalEvaluationsParameters.ShowAsPlainList': params?.showAsPlainList,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      signal,
    });
  },
  addCustomLineToPartRequest(partRequestId: number, data: CustomLineReqType): AxiosCreatedIdResponse {
    return instance.post(`/stock/partRequests/${partRequestId}/lines/customLine`, { ...data });
  },
  addPartLineToPartRequest(partRequestId: number, data: CommonCreatePartLineReqDto): AxiosCreatedIdResponse {
    return instance.post(`/stock/partRequests/${partRequestId}/lines/partLine`, { ...data });
  },
  addPartsKitLineToPartRequest(partRequestId: number, data: CreatePartRequestPartsKitLine): AxiosCreatedIdResponse {
    return instance.post(`/stock/partRequests/${partRequestId}/lines/partsKitLine`, { ...data });
  },
  patchPartRequestLine(partRequestId: number, lineId: number, data: PatchPartReqLineDto): AxiosNullableResponse {
    return instance.patch(`/stock/partRequests/${partRequestId}/lines/${lineId}`, { ...data });
  },
  bulkPatchPartReqLines(partRequestId: number, data: BulkPatchReqLinesDataType): AxiosNullableResponse {
    return instance.patch(`/stock/partRequests/${partRequestId}/lines`, { ...data });
  },
  deletePartReqLine(lineId: number, deletionReason?: string): AxiosNullableResponse {
    return instance.delete(`/stock/partRequests/lines/${lineId}`, { data: { deletionReason } });
  },
  bulkDeletePartReqLines(partRequestLineIds: number[], reason: string): AxiosNullableResponse {
    return instance.delete('/stock/partRequests/lines', { data: { partRequestLineIds, reason } });
  },
  fetchPartProductSourceDictionary(
    partId: number,
    params: PartProductSourceDictionaryParams,
  ): AxiosSingleResponse<PartProductDictionaryType> {
    return instance.get(`/stock/parts/${partId}/productSourceDictionary`, {
      params: {
        BusinessArea: params.businessArea,
        CustomerId: params.customerId,
        PriceDate: params.priceDate,
      },
    });
  },
  submitPartReqLines(partRequestId: number, customLineIds: number[] | null): AxiosNullableResponse {
    return instance.put(`/stock/partRequests/${partRequestId}/lines/submission`, { customLineIds });
  },
  discardPartReqLinesChanges(partRequestId: number): AxiosNullableResponse {
    return instance.put(`/stock/partRequests/${partRequestId}/lines/discarding`);
  },
  fetchPartViewTimeline(partId: number, filters: ExtendedTimelineFilters): AxiosDataListResponse<PartViewEventDtoBase[]> {
    return instance.get('/stock/parts/timeline', {
      params: {
        PartId: partId,
        MainOnly: filters.mainOnly,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  createPartNote(partId: number, content: string): AxiosCreatedIdResponse {
    return instance.post('/part/note', { content, partId });
  },
  editPartViewNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/part/note/${id}`, { content });
  },
  deletePartViewNote(id: number): AxiosNullableResponse {
    return instance.delete(`/part/note/${id}`);
  },
  fetchDefaultPurchaseOrderDeliverAddress(): AxiosSingleResponse<{ defaultAddress: DefaultAddressDto }> {
    return instance.get('/address/defaultAddress/purchaseOrderDeliverTo');
  },
  generatePurchaseOrder(partRequestLineIds: number[]): AxiosSingleResponse<{
    createdPurchaseOrders: CreatePurchaseOrderResponseType[],
  }> {
    return instance.post('/stock/partRequests/purchaseOrders', { partRequestLineIds });
  },
  createPurchaseOrderBasedOnConfirmedPartRequests(partRequestLineProductIds: number[]): AxiosSingleResponse<{
    createdPurchaseOrders: CreatePurchaseOrderResponseType[],
  }> {
    return instance.post('/stock/partRequests/purchaseOrders', { partRequestLineProductIds });
  },
  fetchPartRequestsViewsConfirmed(
    params: ConfirmedPartRequestViewsParams,
  ): AxiosDataListResponse<ConfirmedPartRequestDto[], { allItemIds: string[] }> {
    return instance.get('/stock/partRequests/views/confirmed', {
      params: {
        'Filter.SupplierName': params.supplierName,
        'Filter.PartNumber': params.partNumber,
        'Filter.PartRequestNumber': params.partRequestNumbers,
        'Filter.LinkedNumber': params.linkedNumbers,
        'OrderOptions.Field': params.order?.field,
        'OrderOptions.Ascending': params.order?.isAsc,
        'PagingOptions.Page': params.page,
        'PagingOptions.ItemsPerPage': params.pageSize,
      },
    });
  },
  fetchPurchaseOrderPartSummary(
    purchaseOrderId: number,
    purchaseOrderPartId: number,
  ): AxiosSingleResponse<GetPurchaseOrderPartSummaryResponse> {
    return instance.get(`/stock/purchaseOrder/${purchaseOrderId}/part/${purchaseOrderPartId}/summary`);
  },
  fetchPartRequestLineSummary(
    partRequestId: number,
    lineId: number,
  ): AxiosSingleResponse<GetPartRequestLineStateSummaryResponse> {
    return instance.get(`/stock/partRequests/${partRequestId}/lines/${lineId}/summary`);
  },
  fetchPurchaseOrderViewsDictionary(partRequestLineId: number): AxiosSingleResponse<GetPurchaseOrderDictionaryResponse> {
    return instance.get('/stock/purchaseOrder/views/dictionary', {
      params: {
        'Filter.PartRequestLineId': partRequestLineId,
      },
    });
  },
  fetchPartRequestLineChangeGroups(groupId: number): AxiosSingleResponse<{ lines: PartRequestLineChangeGroupLineDto[] }> {
    return instance.get(`/stock/partRequests/partRequestLineChangeGroups/${groupId}`);
  },
  getPartRequestSummaryReview(params: GetPartRequestSummaryReviewParams): AxiosSingleResponse<{ isCostChanged: boolean }> {
    const {
      id, partRequestLineIds, data, enableDiscounts, priceDate,
    } = params;
    return instance.post(`/stock/partRequests/${id}/summaryPreview/get`, {
      patchPartRequestLineIds: partRequestLineIds,
      patchPartRequestLineDto: data ? { ...data } : undefined,
      enableDiscounts,
      priceDate,
    });
  },
  fetchPartRequestByLine(filters: PartRequestByLineFilters, signal?: AbortSignal): AxiosDataListResponse<PartRequestLineDto[], {
    allAvailableForPurchaseOrderGenerationIds: number[]
  }> {
    return instance.get('/stock/partRequests/lines/views/partRequestByLine', {
      params: {
        'Filter.Keyword': filters.keyword,
        'Filter.ProductNumber': filters.productNumber,
        'Filter.LinkedEntityNumber': filters.linkedEntityNumber,
        'Filter.LinkedEntityStatus': filters.linkedEntityStatus,
        'Filter.Statuses': filters.statuses,
        'Filter.IsActive': filters.isActive,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
      paramsSerializer: (params) => {
        return qs.stringify(params, { arrayFormat: 'repeat' });
      },
      signal,
    });
  },
};
