import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, DownloadUriResponse, EntityLinkType, LinksFiltersType, ResponseResult,
  ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  GetVehicleResponse,
  PatchVehicleDto,
  VehicleActivityEventDto,
} from '../../../vehicles/vehicle-view-page/types/VehicleViewTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { RootState } from '../../store';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setVehiclesDashboardFilters, setVehicleTimeline, setVehicleTimelineFilters } from '../../slices/vehiclesSlice';
import { initVehiclesDashboardFilters } from '../../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';
import { UserEventVehicle } from '../../../settings/user-profile/types/activityTypes';

export const getVehicleByIdThunk = createAsyncThunk<ResponseSingleResult<GetVehicleResponse>, {
  vehicleId: number,
}>(
  'get/VehicleById',
  async ({ vehicleId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleById(vehicleId);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleActivityLog = createAsyncThunk<ResponseResult<VehicleActivityEventDto[]>,
{ vehicleId: number, filters: ExtendedTimelineFilters }>(
  'get/VehicleActivityLog',
  async ({ vehicleId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleActivityLog(vehicleId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchVehicleThunk = createAsyncThunk<ResponseSingleResult, {
  vehicleId: number, data: PatchVehicleDto, closeModal?: VoidFunctionType, setPrevFlowValue?: VoidFunctionType,
}>(
  'patch/Vehicle',
  async ({
    vehicleId, data, closeModal, setPrevFlowValue,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { vehicles: { vehicleTimelineFilters } } = getState() as RootState;
      const response = await vehiclesAPI.patchVehicle(vehicleId, data);
      dispatch(setLoading(false));
      dispatch(getVehicleByIdThunk({ vehicleId }));
      if (vehicleTimelineFilters.page === 1) {
        dispatch(getVehicleActivityLog({
          vehicleId,
          filters: vehicleTimelineFilters,
        }));
      } else {
        dispatch(setVehicleTimelineFilters({ ...vehicleTimelineFilters, page: 1 }));
      }
      if (closeModal) {
        closeModal();
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errorMessages = error.response?.data.errors;
      const isAssigneeIdError = errorMessages?.find((el) => el.key === 'AssigneeId')?.message;
      if (isAssigneeIdError) {
        dispatch(setErrorMessage({ message: isAssigneeIdError, toastId: Math.random() }));
        if (setPrevFlowValue) {
          setPrevFlowValue();
        }
        if (closeModal) {
          closeModal();
        }
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editVehicleStatusThunk = createAsyncThunk<ResponseSingleResult, {
  vehicleId: number, status: number,
}>(
  'put/VehicleStatus',
  async ({ vehicleId, status }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { vehicles: { vehicleTimelineFilters } } = getState() as RootState;
      const response = await vehiclesAPI.editVehicleStatus(vehicleId, status);
      dispatch(setLoading(false));
      dispatch(getVehicleByIdThunk({ vehicleId }));
      if (vehicleTimelineFilters.page === 1) {
        dispatch(getVehicleActivityLog({
          vehicleId,
          filters: vehicleTimelineFilters,
        }));
      } else {
        dispatch(setVehicleTimelineFilters({ ...vehicleTimelineFilters, page: 1 }));
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateVehicleHandoverCheckPdfThunk = createAsyncThunk<DownloadUriResponse, {
  vehicleId: number,
  vehicleCheckId: number,
}>(
  'get/Vehicle/HandoverCheckPDF',
  async ({ vehicleId, vehicleCheckId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await vehiclesAPI.exportVehicleHandoverCheckToPdf(vehicleId, vehicleCheckId);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleLinksThunk = createAsyncThunk<ResponseSingleResult<{ links: EntityLinkType[] | null }>,
{ id: number, filters: LinksFiltersType }
>(
  'get/VehicleLinks',
  async ({
    id, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleLinks(id, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postVehicleActivityNote = createAsyncThunk<ResponseSingleResult<{ createdId: number }> | null,
{ vehicleId: number, content: string, setValue: VoidFunctionType, }>(
  'post/VehicleActivityNote',
  async ({
    vehicleId, content, setValue,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.createVehicleActivityNote(vehicleId, content);
      const { vehicles: { vehicleTimelineFilters } } = getState() as RootState;
      setValue();
      if (vehicleTimelineFilters.page === 1) {
        dispatch(getVehicleActivityLog({
          vehicleId,
          filters: vehicleTimelineFilters,
        }));
      } else {
        dispatch(setVehicleTimelineFilters({ ...vehicleTimelineFilters, page: 1 }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editVehicleActivityNote = createAsyncThunk<ResponseSingleResult | null,
{ id: number, content: string, closeModal: VoidFunctionType, }>(
  'put/VehicleActivityNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.putVehicleActivityNote(id, content);
      const {
        vehicles: { vehicleTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventVehicle[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = vehicleTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setVehicleTimeline({ ...vehicleTimeline, items }));
      }
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteVehicleActivityNote = createAsyncThunk<ResponseSingleResult | null,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/VehicleActivityNote',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.deleteVehicleActivityNote(id);
      const {
        vehicles: { vehicleTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setVehicleTimelineFilters({ ...vehicleTimelineFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteVehicleThunk = createAsyncThunk<ResponseSingleResult | null,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/Vehicle',
  async ({ id, closeModal }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.deleteVehicle(id);
      dispatch(setVehiclesDashboardFilters(initVehiclesDashboardFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
