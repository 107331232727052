export enum PricelistEventsEnum {
  PricelistCreation = 'PricelistCreation',
  PricelistFieldUpdateOrAddingValue = 'PricelistFieldUpdateOrAddingValue',
  PricelistStatusChange = 'PricelistStatusChange',
  PricelistDeletion = 'PricelistDeletion',
  Note = 'Note',
  PricelistProductCreation = 'PricelistProductCreation',
  PricelistProductFieldUpdateOrAddingValue = 'PricelistProductFieldUpdateOrAddingValue',
  PricelistProductDeletion = 'PricelistProductDeletion',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
}
