import { RequisiteDto } from '../../../../common/types/commonTypes';
import { CreatePurchase } from '../utils/CreatePurchaseOrderSchema';

export type CreatePurchaseOrderDataType = CreatePurchase & {
  supplierId: number,
  assigneeId: number,
  deliveryAddressLine1: string | null,
  deliveryAddressLine2: string | null,
  deliveryCityTown: string | null,
  deliveryRegion: string | null,
  deliveryPostalCode: string | null,
  billingDetails: RequisiteDto | null,
  billingSupplierId: number | null,
}
export type CreatePurchaseOrderResponseType = {
  id: number,
  purchaseOrderNumber: string | null,
}

export type CreatePurchaseOrderSearchFilterType = {
  supplierName?: string,
  partNumber?: string,
  partRequestNumbers?: string,
  linkedNumbers?: string,
}

export const InitCreatePurchaseOrderSearchFilter = {
  supplierName: undefined,
  partNumber: undefined,
  partRequestNumbers: undefined,
  linkedNumbers: undefined,
};
