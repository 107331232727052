import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import PartViewPartsKitsList from './parts-kits-list/PartViewPartsKitsList';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  partViewPartsKitsFiltersSelector,
  partViewPartsKitsList,
} from '../../../../../../store/selectors/stockSelectors';
import { getStockPartViewPartsKits } from '../../../../../../store/thunks/stock/part/partViewPageThunks';
import { setPartViewPartsKitsFilters } from '../../../../../../store/slices/stockSlice';
import SkeletonPartsKits from '../../../../../../core/components/skeleton/part-view-parts-kits/SkeletonPartsKits';

const PartViewPartsKits:React.FC = () => {
  const { id } = useParams();
  const partsKitsList = useAppSelector(partViewPartsKitsList);
  const filters = useAppSelector(partViewPartsKitsFiltersSelector);
  const dispatch = useAppDispatch();
  const [isFetching, setIsFetching] = useState(true);
  useEffect(() => {
    if (id) {
      dispatch(getStockPartViewPartsKits({
        id: +id, filters, setIsFetching,
      }));
    }
    // eslint-disable-next-line
  }, [id, filters.page]);

  return <>
    <Pagination
      currentPage={filters.page}
      totalItems={partsKitsList?.totalCount || 0}
      onChange={(page) => dispatch(setPartViewPartsKitsFilters({ ...filters, page }))}
      pageSize={filters.pageSize}
      showTotal={false}
      showSizeChanger={false}
    />
    {isFetching
      ? <SkeletonPartsKits />
      : <PartViewPartsKitsList items={partsKitsList?.items} />}
  </>;
};

export default PartViewPartsKits;
