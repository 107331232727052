import React, { useEffect, useState } from 'react';
import Layout from '../../core/components/layout/Layout';
import EmployeesFilters from './components/EmployeesFilters';
import { useEmployeesPageParams } from './hooks/useEmployeesPageParams';
import EmployeesContent from './components/EmployeesContent';
import { useAppDispatch } from '../../store/hooks';
import { getEmployeeDepartmentsThunk } from '../../store/thunks/employee/createEmployeeThunks';
import {
  getEmployeeDashStatusesThunk,
  getEmployeeDashTitlesThunk,
} from '../../store/thunks/employee/employeesDashboardThunks';
import { EmployeesPageParamEnums } from './enums/EmployeesPageParamsEnums';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import EmployeesHolidaysModal from '../work-calendar/components/modals/EmployeesHolidaysModal';
import EmployeesWorkingHoursModal, { EmployeeSchedule } from '../work-calendar/components/modals/EmployeesWorkingHoursModal';
import { deleteEmployeeHoliday } from '../../store/thunks/employee/employeesWorkCalendarThunks';

const EmployeesContainer: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    page,
    status,
    setPageParam,
    setEmployeeStatusParam,
  } = useEmployeesPageParams();
  useEffect(() => {
    dispatch(getEmployeeDepartmentsThunk());
    dispatch(getEmployeeDashStatusesThunk());
    dispatch(getEmployeeDashTitlesThunk());
    // eslint-disable-next-line
  }, []);

  const [holidayIdToDelete, setHolidayIdToDelete] = useState<number | null>(null);
  const [isHolidayModal, setIsHolidayModal] = useState(false);
  const [isHoursModal, setIsHoursModal] = useState(false);
  const [employeeSchedule, setEmployeeSchedule] = useState<EmployeeSchedule | null>(null);

  return (
    <Layout scrollableMainArea={page === EmployeesPageParamEnums.WORK_CALENDAR}>
      <ConfirmationModal
        isVisible={!!holidayIdToDelete}
        onCancel={() => setHolidayIdToDelete(null)}
        confirmHandler={() => holidayIdToDelete && dispatch(deleteEmployeeHoliday({
          id: holidayIdToDelete,
          closeModal: () => setHolidayIdToDelete(null),
        }))}
      />
      <EmployeesHolidaysModal
        visible={isHolidayModal}
        onCancel={() => setIsHolidayModal(false)}
        setHolidayIdToDelete={setHolidayIdToDelete}
      />
      <EmployeesWorkingHoursModal
        visible={isHoursModal || !!employeeSchedule}
        onCancel={() => {
          setIsHoursModal(false);
          setEmployeeSchedule(null);
        }}
        employeeSchedule={employeeSchedule}
      />
      <EmployeesFilters
        view={page}
        setView={setPageParam}
        setIsHolidayModal={setIsHolidayModal}
        setIsHoursModal={setIsHoursModal}
      />
      <EmployeesContent
        view={page}
        setEmployeeStatusParam={setEmployeeStatusParam}
        employeeStatusParam={status}
        setEmployeeSchedule={setEmployeeSchedule}
      />
    </Layout>
  );
};

export default EmployeesContainer;
