import React, { useEffect, useMemo, useState } from 'react';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { ordersDictionarySelector } from '../../../../../store/selectors/sharedSelectors';
import { patchPartQuantities } from '../../../../../store/thunks/stock/stockThunks';
import { partQuantitiesSelector, stockPartDetailsSelector } from '../../../../../store/selectors/stockSelectors';
import { partQtyStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';

interface EditLinkedToModalProps {
  visible: boolean,
  onCancel: VoidFunctionType,
  qtyId?: number,
  setQtyId: (v?: number) => void,
}

const EditLinkedToModal = ({
  visible, onCancel, qtyId, setQtyId,
}: EditLinkedToModalProps) => {
  const dispatch = useAppDispatch();
  const currentPart = useAppSelector(stockPartDetailsSelector);
  const currentPartId = currentPart?.part?.id;
  const ordersDictionary = useAppSelector(ordersDictionarySelector);
  const { reserved } = useAppSelector(partQtyStatusesValuesSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const { items } = useAppSelector(partQuantitiesSelector);

  const currentQty = useMemo(() => items?.find((el) => el.id === qtyId), [qtyId, items]);

  const [orderId, setOrderId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (currentQty) {
      setOrderId(currentQty.orderId || undefined);
    }
  }, [currentQty]);

  const cancelHandle = () => {
    onCancel();
    setOrderId(undefined);
    setQtyId(undefined);
  };

  const saveHandle = () => {
    if (currentPartId && qtyId) {
      if (orderId !== currentQty?.orderId) {
        dispatch(patchPartQuantities({
          partId: currentPartId,
          id: qtyId,
          data: { orderId: orderId || 0 },
          statusToRefetch: reserved,
          onClose: cancelHandle,
        }));
      } else cancelHandle();
    }
  };

  return <Modal visible={visible} onCancel={cancelHandle} title="Edit linked to" destroyOnClose>
    <Select
      options={ordersDictionary}
      value={orderId}
      onChange={(v) => setOrderId(v as number)}
      label="Linked to"
      parentRender
      showSearch
      isFilterOption
      loading={isFetching}
      allowClear
    />
    <ButtonActions
      createLabel="Save"
      cancelLabel="Cancel"
      createClick={saveHandle}
      cancelClick={cancelHandle}
      offsetTop
      isLoading={isFetching}
    />
  </Modal>;
};

export default EditLinkedToModal;
