import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  initDataList,
  NumberFunctionType,
  RangeTableFiltersType,
  VoidFunctionType,
  NumericRangeType,
} from '../../../../../../../core/types/coreTypes';
import Modal from '../../../../../../../core/components/modal/Modal';
import Button from '../../../../../../../core/components/button/Button';
import './AddPartToOrderModal.scss';
import KeywordFiltersSection from '../../../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useAddPartToOrderFilters } from '../../../../hooks/useAddPartToOrderFilters';
import AddPartToOrderTable from './AddPartToOrderTable';
import Pagination from '../../../../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../../../core/utils/regex';
import InputWithButton from '../../../../../../../core/components/input-with-button/InputWithButton';
import { purchaseOrderPartSearchTableModeResultSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { maxLengthMessageHandle } from '../../../../../../../core/utils/errorMessageHandle';
import { ErrorsEnum } from '../../../../../../../core/enums/errorsEnum';
import {
  setPurchaseOrderPartSearch,
  setPurchaseOrderPartSearchFilters,
} from '../../../../../../../store/slices/purchaseOrderSlice';
import { getStockPartsOrdering } from '../../../../../../../store/thunks/core/coreOrderingThunks';
import { getSuitableVehicleProfiles } from '../../../../../../../store/thunks/shared/sharedThunks';
import { vehicleProfilesModelDescrOrderSelector } from '../../../../../../../store/selectors/coreOrderingSelectors';
import CustomPartLineForm from './CustomPartLineForm';

type AddPartToOrderModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  setIsSelectPrice: NumberFunctionType,
  setQtyMinMax:(v: NumericRangeType) => void,
  setRange: (v: RangeTableFiltersType) => void,
  editedCustomLine?: number,
  forbiddenToAddUsualLines: boolean,
}

const AddPartToOrderModal: React.FC<AddPartToOrderModalProps> = ({
  isVisible, onCancel, setIsSelectPrice, setQtyMinMax, setRange, editedCustomLine, forbiddenToAddUsualLines,
}) => {
  const dispatch = useAppDispatch();
  const searchResult = useAppSelector(purchaseOrderPartSearchTableModeResultSelector);
  const vehModelDescrOrder = useAppSelector(vehicleProfilesModelDescrOrderSelector);

  const {
    error,
    setError,
    initDropdownSearch,
    filtersList,
    filters,
    searchTriggerFn,
    getFilteredRes,
    getPaginatedRes,
    changeCoreMode,
    onClear,
  } = useAddPartToOrderFilters();

  useEffect(() => {
    if (isVisible) {
      dispatch(getStockPartsOrdering());
      vehModelDescrOrder && dispatch(getSuitableVehicleProfiles({
        filters: { order: { field: vehModelDescrOrder, isAsc: true } },
      }));
    }
    // eslint-disable-next-line
  }, [isVisible, vehModelDescrOrder]);

  const cancelHandle = () => {
    onCancel();
    setError('');
  };

  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={editedCustomLine ? 'Edit custom line' : 'Select part'}
      width={1100}
      destroyOnClose
    >
      {!editedCustomLine
        && <div className="addPartToOrderBtns">
          <Button
            label="Core parts"
            onClick={() => changeCoreMode(true)}
            designType="light-inverse"
            isActivated={filters.isCorePart?.includes(true)}
            offsetRight
            disabled={forbiddenToAddUsualLines}
          />
          <Button
            label="Non-core parts"
            onClick={() => changeCoreMode(false)}
            designType="light-inverse"
            isActivated={filters.isCorePart?.includes(false)}
            offsetRight
            disabled={forbiddenToAddUsualLines}
          />
          <Button
            label="Custom line"
            onClick={() => changeCoreMode(undefined)}
            designType="light-inverse"
            isActivated={!filters.isCorePart}
          />
        </div>}
      {filters.isCorePart?.length && <>
        <KeywordFiltersSection
          isSearch={false}
          filters={filtersList}
          initDropdownSearch={initDropdownSearch}
          getFilteredResHandle={(value, field) => getFilteredRes(value, field)}
          onClear={onClear}
          className="addPartToOrderFilters"
          isFullWidth
          conditionToClean={!!filters.isCorePart}
        />
        <InputWithButton
          value={filters.keyword}
          onChange={(e) => {
            const val = e.target.value;
            if (!val.trim()) {
              dispatch(setPurchaseOrderPartSearch(initDataList));
            }
            if (val.length > 100) {
              setError(maxLengthMessageHandle(100));
            } else {
              error && setError('');
            }
            dispatch(setPurchaseOrderPartSearchFilters({ ...filters, keyword: val }));
          }}
          onPressEnter={(e) => {
            const searchValue = e.currentTarget.value;
            if (!searchValue?.trim()) {
              setError(ErrorsEnum.REQUIRED);
              dispatch(setPurchaseOrderPartSearchFilters({ ...filters, keyword: undefined }));
            } else {
              searchValue.trim() && !error && searchTriggerFn();
            }
          }}
          error={error}
          icon={<FontAwesomeIcon icon={faSearch} />}
          onClick={() => {
            const searchValue = filters.keyword;
            searchValue?.trim() && !error && searchTriggerFn();
          }}
          className="addPartToOrderSearch"
          placeholder="Search by part code, manufacturer code, part name or description"
          buttonDisabled={!filters.keyword}
        />
        <Pagination
          currentPage={filters.page}
          totalItems={searchResult.totalCount}
          pageSize={filters.pageSize}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => getPaginatedRes(page, pageSize)}
          className="addPartToOrderPaging"
        />
      </>}
      {filters.isCorePart?.length
        ? <AddPartToOrderTable
          setIsSelectPrice={setIsSelectPrice}
          filters={filters}
          getFilteredRes={getFilteredRes}
          conditionToClean={filters.isCorePart}
        />
        : <CustomPartLineForm
          cancelHandle={cancelHandle}
          setQtyMinMax={setQtyMinMax}
          setRange={setRange}
          editedCustomLine={editedCustomLine}
          isVisible={isVisible}
        />}
    </Modal>
  );
};

export default AddPartToOrderModal;
