import React, { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { nominalCodeTypesSelector } from '../../../../store/selectors/coreSelectors';
import { nominalCodesFiltersSelector, nominalCodeTypesWithCountsSelector } from '../../../../store/selectors/invoicingSelectors';
import { InvoicingPageParamsEnums } from '../../enums/InvoicingPageParamsEnums';
import { setNominalCodesFilters } from '../../../../store/slices/invoicingSlice';
import { getNominalCodeTypesThunk } from '../../../../store/thunks/coreThunk';

const NominalCodesTypeFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const nominalCodeTypes = useAppSelector(nominalCodeTypesSelector);
  const nominalCodeTypesWithCount = useAppSelector(nominalCodeTypesWithCountsSelector);
  const nominalCodesFilters = useAppSelector(nominalCodesFiltersSelector);

  const [params, setSearchParams] = useSearchParams();
  const page = params.get('page');
  const type = params.get('type');

  const { windowWidth } = useScreenWitdh();

  useEffect(() => {
    if (!nominalCodeTypes.length) {
      dispatch(getNominalCodeTypesThunk());
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (page === InvoicingPageParamsEnums.NOMINAL_CODES && !type) {
      if (nominalCodesFilters.types) {
        setSearchParams({ page, type: nominalCodesFilters.types[0].toString() });
      } else if (nominalCodeTypes.length) {
        setSearchParams({ page, type: nominalCodeTypes[0].value.toString() });
      }
    }
    // eslint-disable-next-line
  }, [nominalCodeTypes, page]);

  useEffect(() => {
    if (page === InvoicingPageParamsEnums.NOMINAL_CODES && type && nominalCodeTypes.length) {
      if (nominalCodeTypes.find((el) => el.value === +type)) {
        dispatch(setNominalCodesFilters({ ...nominalCodesFilters, types: [+type], page: 1 }));
      } else {
        setSearchParams({ page, type: nominalCodeTypes[0].value.toString() });
      }
    }
    // eslint-disable-next-line
  }, [type, nominalCodeTypes]);

  return (
    <>
      {!!nominalCodeTypes.length && type && (
        windowWidth <= responsiveEndpointsEnum.LG
          ? <Selectable
            options={nominalCodeTypesWithCount}
            onChange={(value) => {
              page && setSearchParams({ page, type: value.toString() });
            }}
            value={+type}
          />
          : <Segmented
            options={nominalCodeTypesWithCount}
            onChange={(value) => {
              page && setSearchParams({ page, type: value.toString() });
            }}
            value={+type}
          />
      )}
    </>
  );
};

export default NominalCodesTypeFilter;
