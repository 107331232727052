export enum RectificationViewModeParamsEnum {
  RECTIFICATION_DETAILS = 'details',
  RECTIFICATION_PART_REQUEST = 'part-request',
  RECTIFICATION_CONTACTS = 'contacts',
  RECTIFICATION_ATTACHMENTS = 'attachments',
  RECTIFICATION_LINKS = 'links',
}

export const RectificationContentModes = [
  RectificationViewModeParamsEnum.RECTIFICATION_DETAILS,
  RectificationViewModeParamsEnum.RECTIFICATION_PART_REQUEST,
  RectificationViewModeParamsEnum.RECTIFICATION_CONTACTS,
  RectificationViewModeParamsEnum.RECTIFICATION_ATTACHMENTS,
  RectificationViewModeParamsEnum.RECTIFICATION_LINKS,
];

export enum RectificationPartReqLinesOrdering {
  OrdinalNumber = 'OrdinalNumber',
  Category = 'Category',
  ProductNumber = 'ProductNumber',
  ProductName = 'ProductName',
  NetPrice = 'NetPrice',
  Status = 'Status',
}
