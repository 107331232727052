import React, { useEffect, useState } from 'react';
import { useForm, useFormState } from 'react-hook-form';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useVehicleDictionaries } from '../../hooks/useVehicleDictionaries';
import { useAppSelector } from '../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import VehicleManualFields from './VehicleManualFields';
import { BooleanFunctionType, VehicleModalEntityType, VoidFunctionType } from '../../../core/types/coreTypes';
import VehicleCatalogSection from './VehicleCatalogSection';
import {
  VehicleProfileSearchType,
  VehicleProfilesFilters,
} from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { AddVehicleRequest, VehicleProfilesUiList } from '../../types/commonTypes';
import VehicleInfoSource from './VehicleInfoSource';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import CommonVehicleFields from './CommonVehicleFields';
import { CreateVehicleFields } from '../../../vehicles/create-vehicle/types/createVehicleFormSchema';

type VehicleBlankSectionProps = {
  setVehicleSelectMode: (v: 'blank' | 'existing' | undefined) => void,
  editMode: boolean,
  currentVeh?: VehicleModalEntityType,
  createVehThunkHandle?: (data: AddVehicleRequest, resetHandle: VoidFunctionType) => void,
  editVehThunkHandle: (data: AddVehicleRequest, setDefaultHandle: VoidFunctionType) => void,
  nonDraftLinesLength?: number,
  isEditConfirmation?: boolean,
  setIsEditConfirmation?: BooleanFunctionType,
  catalogVehicleProfilesFilters: VehicleProfilesFilters,
  setCatalogVehicleProfilesFilters: (v: VehicleProfilesFilters) => void,
  vehicleProfiles: VehicleProfilesUiList,
  lineType: string,
  cancelModalHandle: VoidFunctionType,
  disabledToEdit?: boolean,
  disabledToEditRegNoAndChassisNo?: boolean,
}

const VehicleBlankSection: React.FC<VehicleBlankSectionProps> = ({
  setVehicleSelectMode, editMode, currentVeh,
  createVehThunkHandle, editVehThunkHandle, nonDraftLinesLength,
  isEditConfirmation, setIsEditConfirmation, catalogVehicleProfilesFilters,
  setCatalogVehicleProfilesFilters, vehicleProfiles, lineType, cancelModalHandle,
  disabledToEdit, disabledToEditRegNoAndChassisNo,
}) => {
  const [infoSource, setInfoSource] = useState<number | undefined>(undefined);
  const [catalogMode, setCatalogMode] = useState<'search' | 'found' | undefined>(undefined);
  const [chosenVehicleProfile, setChosenVehicleProfile] = useState<VehicleProfileSearchType | null>(null);

  const {
    vehicleInfoSources,
    vehicleCatalogSource,
    vehicleManualInput,
    vehicleProfilesTypes,
    vehicleProfilesGroups,
  } = useVehicleDictionaries();
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const cancelClick = () => {
    cancelModalHandle();
    setVehicleSelectMode(undefined);
  };

  useEffect(() => {
    if (vehicleCatalogSource && !infoSource && !editMode) {
      setInfoSource(vehicleCatalogSource.value);
    }
  }, [vehicleCatalogSource, infoSource, editMode]);

  const {
    handleSubmit, control, setValue,
    reset, setError, clearErrors, getValues,
  } = useForm<CreateVehicleFields>({
    defaultValues: {
      manufacturer: '',
      type: undefined,
      group: undefined,
      modelDescription: null,
      slidingDoorsQuantity: 0,
      grossVehicleWeightKg: '0',
      maxPayload: '0',
      regNumber: null,
      chassisNumber: null,
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const setEditableChosenProfile = () => {
    if (currentVeh && currentVeh.vehicleProfileId) {
      const chosenProfile = {
        id: currentVeh.vehicleProfileId,
        number: 1,
        code: currentVeh.code,
        manufacturer: currentVeh.manufacturer,
        group: vehicleProfilesGroups.find((el) => el.value === currentVeh.group)?.label || currentVeh.group || '-',
        modelDescription: currentVeh.modelDescription,
        type: vehicleProfilesTypes.find((el) => el.value === currentVeh.type)?.label || currentVeh.type || '-',
        maxPayload: currentVeh.maxPayload,
        grossVehicleWeightKg: currentVeh.grossVehicleWeightKg,
      };
      setChosenVehicleProfile(chosenProfile);
      setValue('slidingDoorsQuantity', currentVeh.slidingDoorsQuantity);
      setValue('grossVehicleWeightKg', (currentVeh.grossVehicleWeightKg || 0).toString());
      setValue('maxPayload', (currentVeh.maxPayload || 0).toString());
      setValue('regNumber', currentVeh.regNumber);
      setValue('chassisNumber', currentVeh.chassisNumber);
    }
  };

  useEffect(() => {
    if (editMode && currentVeh) {
      if (currentVeh.vehicleProfileId) {
        setInfoSource(vehicleCatalogSource?.value);
        setCatalogMode('found');
        setEditableChosenProfile();
      } else {
        setInfoSource(vehicleManualInput?.value);
        const forbiddenKeys = [
          'id',
          'tabName',
          'vehicleInfoSource',
          'enquiryId',
          'code',
          'enableDiscounts',
          'enableNotes',
          'vehicleProfileId',
        ];
        const numericKeys = ['maxPayload', 'grossVehicleWeightKg'];
        Object.keys(currentVeh).forEach((key) => {
          if (!forbiddenKeys.includes(key)) {
            const value = numericKeys.includes(key)
              ? currentVeh[key as keyof VehicleModalEntityType]?.toString()
              : currentVeh[key as keyof VehicleModalEntityType];
            setValue(key as keyof CreateVehicleFields, value);
          }
        });
      }
    }
    // eslint-disable-next-line
  }, [vehicleInfoSources, editMode, currentVeh]);

  const setDefaultValues = (value: number | undefined, clearWholeForm?: boolean) => {
    setInfoSource(value);
    clearErrors();
    if (clearWholeForm) {
      reset();
      setChosenVehicleProfile(null);
      setCatalogMode(undefined);
    } else {
      if (editMode && currentVeh) {
        const isValueAndVehSourceManual = value === vehicleManualInput?.value
            && currentVeh.vehicleInfoSource === vehicleManualInput?.value;
        const isValueAndVehSourceCatalog = value === vehicleCatalogSource?.value
            && currentVeh.vehicleInfoSource === vehicleCatalogSource?.value;
        if (isValueAndVehSourceManual) {
          setValue('manufacturer', currentVeh.manufacturer);
          setValue('type', currentVeh.type || undefined);
          setValue('group', currentVeh.group || undefined);
          setValue('modelDescription', currentVeh.modelDescription);
        }
        if (isValueAndVehSourceCatalog) {
          setCatalogMode('found');
          setEditableChosenProfile();
        }
      } else {
        setValue('manufacturer', '');
        setValue('type', undefined);
        setValue('group', undefined);
        setValue('modelDescription', null);
        setChosenVehicleProfile(null);
        setCatalogMode(undefined);
      }
    }
  };

  const onSubmit = (values: CreateVehicleFields) => {
    const weightFields = {
      maxPayload: values.maxPayload ? +values.maxPayload : 0,
      grossVehicleWeightKg: values.grossVehicleWeightKg ? +values.grossVehicleWeightKg : 0,
    };
    const regChassisFields = {
      regNumber: values.regNumber && values.regNumber.trim() ? values.regNumber.trim() : null,
      chassisNumber: values.chassisNumber ? values.chassisNumber.trim() : null,
    };
    let finalValues: AddVehicleRequest;
    if (infoSource === vehicleCatalogSource?.value && chosenVehicleProfile) {
      finalValues = {
        ...values,
        vehicleProfileId: chosenVehicleProfile?.id,
        vehicleInfoSource: infoSource as number,
        manufacturer: null,
        type: null,
        group: null,
        modelDescription: null,
        ...regChassisFields,
        ...weightFields,
      };
    } else {
      finalValues = {
        ...values,
        vehicleProfileId: null,
        vehicleInfoSource: infoSource as number,
        modelDescription: values.modelDescription ? values.modelDescription.trim() : null,
        type: values.type as number,
        group: values.group as number,
        ...regChassisFields,
        ...weightFields,
      };
    }
    if (editMode && currentVeh) {
      const isCurrentSourceCatalog = infoSource === vehicleCatalogSource?.value;
      const isEquals = [
        values.manufacturer === currentVeh?.manufacturer,
        values.type === currentVeh?.type,
        values.group === currentVeh?.group,
        values.modelDescription === currentVeh?.modelDescription,
      ];
      const isSomeCoreFieldWasChanged = isEquals.some((el) => !el);
      if (nonDraftLinesLength && nonDraftLinesLength > 0 && isCurrentSourceCatalog && isSomeCoreFieldWasChanged) {
        setIsEditConfirmation && setIsEditConfirmation(true);
      } else {
        editVehThunkHandle(finalValues, () => setDefaultValues(undefined, true));
      }
    } else {
      createVehThunkHandle && createVehThunkHandle(
        finalValues,
        () => setDefaultValues(undefined, true),
      );
    }
  };

  const confirmVehEditingHandle = () => {
    const values = getValues();
    const weightFields = {
      maxPayload: values.maxPayload ? +values.maxPayload : 0,
      grossVehicleWeightKg: values.grossVehicleWeightKg ? +values.grossVehicleWeightKg : 0,
    };
    const regChassisFields = {
      regNumber: values.regNumber && (values.regNumber as string).trim() ? (values.regNumber as string).trim() : null,
      chassisNumber: values.chassisNumber ? (values.chassisNumber as string).trim() : null,
    };
    const newValues = {
      ...values,
      vehicleProfileId: chosenVehicleProfile?.id || null,
      vehicleInfoSource: infoSource as number,
      manufacturer: null,
      type: null,
      group: null,
      modelDescription: null,
      ...regChassisFields,
      ...weightFields,
    };
    const onClose = () => {
      cancelClick();
      setIsEditConfirmation && setIsEditConfirmation(false);
      setDefaultValues(undefined, true);
    };
    editVehThunkHandle(newValues, () => onClose());
  };

  const cancelVehEditing = () => {
    setIsEditConfirmation && setIsEditConfirmation(false);
    setEditableChosenProfile();
  };

  const commonFieldsRenderConditions = (infoSource === vehicleCatalogSource?.value && chosenVehicleProfile)
      || (infoSource === vehicleManualInput?.value);

  return (
    <>
      <ConfirmationModal
        isVisible={!!isEditConfirmation}
        customTitle="Save changes"
        onCancel={cancelVehEditing}
        confirmHandler={confirmVehEditingHandle}
        customText={<>
          You are about to change the core vehicle parameters.
          <br />
          Some parts in vehicle
          {` ${lineType} `}
          lines might not be suitable for this vehicle after your updates.
        </>}
        customProceedText="Do you want to save the changes?"
        customCreateLabel="Yes, save"
      />
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <VehicleInfoSource
          sources={vehicleInfoSources}
          onClick={(value) => {
            value !== infoSource && setDefaultValues(value, value === vehicleCatalogSource?.value && !editMode);
          }}
          infoSource={infoSource}
          offsetBottom
          disabled={disabledToEdit}
        />
        {infoSource && <>
          {infoSource === vehicleCatalogSource?.value
            ? <VehicleCatalogSection
              setCatalogMode={setCatalogMode}
              catalogMode={catalogMode}
              chosenVehicleProfile={chosenVehicleProfile}
              setChosenVehicleProfile={(profile) => {
                setChosenVehicleProfile(profile);
                setValue('grossVehicleWeightKg', profile?.grossVehicleWeightKg ? profile.grossVehicleWeightKg.toString() : '0');
                setValue('maxPayload', profile?.maxPayload ? profile.maxPayload.toString() : '0');
              }}
              catalogVehicleProfilesFilters={catalogVehicleProfilesFilters}
              setCatalogVehicleProfilesFilters={setCatalogVehicleProfilesFilters}
              vehicleProfiles={vehicleProfiles}
              disabled={disabledToEdit}
            />
            : <VehicleManualFields
              control={control}
              errors={errors}
              setError={setError}
              setValue={setValue}
              disabled={disabledToEdit}
            />}
          {commonFieldsRenderConditions && <CommonVehicleFields
            control={control}
            errors={errors}
            setValue={setValue}
            clearErrors={clearErrors}
            offsetTop
            disabled={disabledToEdit}
            disabledToEditRegNoAndChassisNo={disabledToEditRegNoAndChassisNo}
          />}
        </>}
        <ButtonActions
          createLabel="Save"
          cancelLabel="Cancel"
          cancelClick={cancelClick}
          createType="submit"
          isLoading={isGlobalLoading}
          offsetTop
          disabledCreate={infoSource === vehicleCatalogSource?.value && !chosenVehicleProfile}
        />
      </form>
    </>
  );
};

export default VehicleBlankSection;
