import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import { EnquiryQuoteVehicleDto } from '../../../../types/enquiryDetailsTypes';
import VehicleInfo from './VehicleInfo';
import DuplicateVehicle from '../duplicate-vehicle/DuplicateVehicle';
import { useAppDispatch } from '../../../../../../store/hooks';
import {
  deleteEnquiryQuoteVehicleThunk,
  duplicateEnquiryQuoteVehicleThunk,
} from '../../../../../../store/thunks/enquiry/quoteConfiguratorThunks';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import QuoteLinesTable from '../table/QuoteLinesTable';
import CopyVehicleLineToModal from '../copy-line/CopyVehicleLineToModal';

type VehicleContentProps = {
  openEditModal: VoidFunctionType;
  vehicle: EnquiryQuoteVehicleDto;
  vehicles?: EnquiryQuoteVehicleDto[];
}

const VehicleContent: React.FC<VehicleContentProps> = ({
  openEditModal,
  vehicle,
  vehicles,
}) => {
  const { id } = useParams();

  const dispatch = useAppDispatch();
  const [isCopyLineModal, setCopyLineModal] = useState(false);
  const [isDuplicateModal, setDuplicateModal] = useState(false);
  const [isConfirmationModal, setConfirmationModal] = useState(false);

  // quotes number to copy
  const [lineId, setLineId] = useState<number | undefined>(undefined);
  const [value, setValue] = useState<string>('');
  const [isCopy, setCopy] = useState(false);

  const duplicateHandle = () => {
    value && dispatch(duplicateEnquiryQuoteVehicleThunk({
      enquiryId: vehicle.enquiryId,
      vehicleId: vehicle.id,
      data: { amount: +value, copyLines: isCopy },
      onClose: () => {
        setCopy(false);
        setValue('');
        setDuplicateModal(false);
      },
    }));
  };

  const deleteVehHandle = () => {
    id && dispatch(deleteEnquiryQuoteVehicleThunk({
      vehicleId: vehicle?.id,
      enquiryId: +id,
      closeModal: () => setConfirmationModal(false),
    }));
  };
  return (
    <>
      <CopyVehicleLineToModal
        visible={isCopyLineModal}
        onCancel={() => setCopyLineModal(false)}
        vehicle={vehicle}
        lineId={lineId}
        vehicles={vehicles}
      />
      <DuplicateVehicle
        isDuplicateModal={isDuplicateModal}
        onCancel={() => {
          setCopy(false);
          setValue('');
          setDuplicateModal(false);
        }}
        value={value}
        setValue={setValue}
        isCopy={isCopy}
        setCopy={setCopy}
        duplicateHandle={duplicateHandle}
      />
      <ConfirmationModal
        isVisible={isConfirmationModal}
        onCancel={() => setConfirmationModal(false)}
        confirmHandler={deleteVehHandle}
        isDelete
      />
      <div className="vehicleContent">
        <VehicleInfo
          info={vehicle}
          openDuplicateModal={() => setDuplicateModal(true)}
          openConfirmModal={() => setConfirmationModal(true)}
          openEditModal={openEditModal}
        />
        <QuoteLinesTable
          vehicle={vehicle}
          setLineId={setLineId}
          setCopyLineModal={setCopyLineModal}
        />
      </div>
    </>
  );
};

export default VehicleContent;
