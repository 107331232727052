import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum StockViewsEnums {
  DASHBOARD = 'Dashboard',
  SEARCH = 'Search',
  PART_REQUESTS = 'Part requests',
  PURCHASES = 'Purchase orders',
  PARTS_KITS = 'Parts kits',
  PART_CATEGORIES = 'Part categories',
  CREATE_PART = 'Create new part'
}

export enum StockPageParamEnums {
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  PART_REQUESTS = 'part-requests',
  PURCHASES = 'purchase-orders',
  PARTS_KITS = 'parts-kits',
  PART_CATEGORIES = 'part-categories',
  CREATE_PART = 'create-part'
}

export const StockPageParams = [
  StockPageParamEnums.DASHBOARD,
  StockPageParamEnums.SEARCH,
  StockPageParamEnums.PART_REQUESTS,
  StockPageParamEnums.PURCHASES,
  StockPageParamEnums.PARTS_KITS,
  StockPageParamEnums.PART_CATEGORIES,
  StockPageParamEnums.CREATE_PART,
];

export const StockPageParamsAndPermissions = [
  { page: StockPageParamEnums.DASHBOARD, permission: PermissionEnum.StockDashboard },
  { page: StockPageParamEnums.SEARCH, permission: PermissionEnum.StockSearchPage },
  { page: StockPageParamEnums.PART_REQUESTS, permission: PermissionEnum.StockPartRequests },
  { page: StockPageParamEnums.PURCHASES, permission: PermissionEnum.StockPurchaseOrders },
  { page: StockPageParamEnums.PARTS_KITS, permission: PermissionEnum.StockPartsKits },
  { page: StockPageParamEnums.PART_CATEGORIES, permission: PermissionEnum.StockPartCategories },
  { page: StockPageParamEnums.CREATE_PART, permission: PermissionEnum.StockCreateNewPart },
];
