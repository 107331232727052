import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  rectificationsByProductDataSelector,
  rectificationsByProductFiltersSelector, reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setRectificationsByProductFilters } from '../../../../../store/slices/reportsSlice';
import { getRectificationsByProductReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import Pagination from '../../../../../core/components/pagination/Pagination';
import RectificationsByProductTable from './table/RectificationsByProductTable';

const RectificationsByProductContent: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(rectificationsByProductFiltersSelector);
  const tableData = useAppSelector(rectificationsByProductDataSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return (
    <>
      <div className="actions-and-paging">
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={tableData.totalCount}
          onChange={(page, pageSize) => {
            const newFilters = { ...filters, page, pageSize };
            dispatch(setRectificationsByProductFilters(newFilters));
            reportIsVisible && dispatch(getRectificationsByProductReportThunk({ filters: newFilters }));
          }}
          className="actions-and-paging__paging"
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          narrow
        />
      </div>
      <RectificationsByProductTable />
    </>
  );
};

export default RectificationsByProductContent;
