import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router-dom';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../../core/components/table-components/Tr';
import { receivedQtyColumns } from '../../../../utils/data';
import Tbody from '../../../../../../../core/components/table-components/Tbody';
import Tfoot from '../../../../../../../core/components/table-components/Tfoot';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../../../../../core/components/select/Select';
import Button from '../../../../../../../core/components/button/Button';
import Dropdown from '../../../../../../../core/components/dropdown/Dropdown';
import EmptyTableSection from '../../../../../../../core/components/table-components/EmptyTableSection';
import { purchaseOrderPartFailReasonSelector } from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { PurchaseOrderPartDto, PurchaseOrderPartFailedQuantityDto } from '../../../../types/purchaseOrderViewPageTypes';
import { integerQuantityMeasureValuesSelector, isFetchingSelector } from '../../../../../../../store/selectors/coreSelectors';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import {
  addFailedPartQuantityThunk,
  deleteFailedPartQuantityThunk,
} from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { StringFunctionType, VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import TableNew from '../../../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../../../core/components/table-components/table-new/TdNew';

type ReceivedQtyTableProps = {
  failedQuantities?: PurchaseOrderPartFailedQuantityDto[] | null,
  currentPart?: PurchaseOrderPartDto,
  measure?: string,
  failReason?: number,
  setFailReason: (v?: number) => void,
  setQtyV: StringFunctionType,
  qtyV: string,
  cleanUpHandle: VoidFunctionType,
  disabled: boolean,
}

const ReceivedQtyTable: React.FC<ReceivedQtyTableProps> = ({
  failedQuantities, currentPart, measure, qtyV, setQtyV, setFailReason, failReason, cleanUpHandle, disabled,
}) => {
  const { id } = useParams();
  const purchaseOrderPartFailReason = useAppSelector(purchaseOrderPartFailReasonSelector);
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const dispatch = useAppDispatch();
  const isFetching = useAppSelector(isFetchingSelector);

  const failedQtyList = failedQuantities?.map((el, i) => ({
    id: el.id,
    number: i + 1,
    quantity: el.quantity,
    reason: purchaseOrderPartFailReason.find((reason) => reason.value === el.reason)?.label,
  }));

  const addRecordHandle = () => {
    id && currentPart && qtyV && failReason && dispatch(addFailedPartQuantityThunk({
      purchaseOrderId: +id,
      partId: currentPart?.id,
      data: {
        quantity: +qtyV,
        reason: failReason,
      },
      cleanUp: () => cleanUpHandle(),
    }));
  };

  const deleteRecordHandle = (failedQuantityId: number) => {
    id && currentPart && dispatch(deleteFailedPartQuantityThunk({
      purchaseOrderId: +id,
      partId: currentPart?.id,
      failedQuantityId,
    }));
  };
  return (
    <section className="receivedQtyWrap__table">
      <div className="receivedQtyWrap__table-title">Returned to supplier or cancelled</div>
      <TableNew className="receivedQtyWrap__table-list" small hasFooter>
        <Thead>
          <Tr>
            {receivedQtyColumns.map(({ dataIndex, title }) => <ThNew key={dataIndex}>{title}</ThNew>)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Tr>
        </Thead>
        <Tbody>
          {failedQtyList && failedQtyList.length > 0
            ? failedQtyList.map((el, i) => <Tr key={i}>
              <>
                {Object.entries(el).map(([key, val]) => {
                  if (key === 'id') return null;
                  return <TdNew>{val}</TdNew>;
                })}
                <TdNew action>
                  <Dropdown
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    menuItems={[
                      {
                        label: 'Delete',
                        key: 'deletePartLine',
                        onClick: () => deleteRecordHandle(el.id),
                        icon: <FontAwesomeIcon icon={faTrashAlt} />,
                        disabled,
                      },
                    ]}
                  />
                </TdNew>
              </>
            </Tr>)
            : <EmptyTableSection colSpan={receivedQtyColumns.length} text="No records" />}
        </Tbody>
        <Tfoot>
          <Tr>
            <TdNew colSpan={4}>
              <div className="receivedQtyWrap__actions">
                {integerMeasures.includes(currentPart?.unitOfMeasure)
                  ? <RegularInputNumber
                    value={qtyV}
                    onChange={(e) => setQtyV(e.target.value)}
                    max={currentPart?.dueIn || 0}
                    label="Quantity"
                    postfix={measure}
                    disabled={disabled}
                  />
                  : <DecimalInputNumber
                    value={qtyV}
                    onChange={(e) => setQtyV(e.target.value.replace(',', '.'))}
                    max={currentPart?.dueIn || 0}
                    label="Quantity"
                    postfix={measure}
                    disabled={disabled}
                  />}
                <Select
                  options={purchaseOrderPartFailReason || []}
                  value={failReason}
                  onChange={(v) => setFailReason(v as number)}
                  label="Reason"
                  disabled={+qtyV === 0 || disabled}
                  parentRender
                />
                <Button
                  icon={<FontAwesomeIcon icon={faPlusCircle} />}
                  label="Add record"
                  disabled={+qtyV === 0 || !failReason || disabled}
                  onClick={addRecordHandle}
                  designType="dark"
                  reversed
                  isLoading={isFetching}
                />
              </div>
            </TdNew>
          </Tr>
        </Tfoot>
      </TableNew>
    </section>
  );
};

export default ReceivedQtyTable;
