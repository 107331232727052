import { useState } from 'react';
import { usersDashFiltersListSelector } from '../../../store/selectors/settingsSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { UserListFiltersType } from '../types/settingsTypes';
import { setListFilters } from '../../../store/slices/settingsSlice';
import { initPaging } from '../../../core/types/coreTypes';

export const useUsersFiltersHandle = () => {
  const dispatch = useAppDispatch();
  const filtersList = useAppSelector(usersDashFiltersListSelector);
  const [searchValue, setSearchValue] = useState<string>('');

  const getFilteredList = (filters: UserListFiltersType) => {
    dispatch(setListFilters({ ...filters, page: 1 }));
  };
  const initDropdownSearch = {
    accountType: '',
    status: '',
    businessArea: '',
    jobType: '',
    role: '',
  };

  const onClear = () => {
    getFilteredList(initPaging);
    setSearchValue('');
  };

  return {
    searchValue,
    setSearchValue,
    filtersList,
    getFilteredList,
    onClear,
    initDropdownSearch,
  };
};
