import React from 'react';
import Tag from '../../../../../core/components/tag/Tag';
import './JobLineExpandedDetails.scss';
import { useAppSelector } from '../../../../../store/hooks';
import { jobDetailsExpandedViewSelector, jobVehicleSelector } from '../../../../../store/selectors/jobsSelector';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import JobLineExpandedDetailsField from './JobLineExpandedDetailsField';
import { vehicleStatusesSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';

const JobLineExpandedDetails: React.FC = () => {
  const jobDetails = useAppSelector(jobDetailsExpandedViewSelector);
  const vehicleStatuses = useAppSelector(vehicleStatusesSelector);
  const jobVehicle = useAppSelector(jobVehicleSelector);
  const vehStatus = jobVehicle?.vehicleStatus;

  const vehicleBasicData = {
    name: jobVehicle?.manufacturer || '',
    info: jobVehicle?.modelDescription || '',
  };

  const detailsFields = [
    { label: 'Priority level', value: jobDetails.priorityLevel, isWide: false },
    { label: 'Job type', value: jobDetails.jobType, isWide: false },
    { label: 'Job location', value: jobDetails.jobLocation, isWide: true },
    { label: 'Job description', value: jobDetails.description, isWide: true },
  ];

  return (
    <div className="expandedDetails">
      <div className="expandedDetails__main">
        <div className="expandedDetails__info">
          <div className="expandedDetails__info-data">
            <span className="expandedDetails__account-number">{jobDetails?.customerAccountNo}</span>
            <TruncateTooltip value={jobDetails?.customerName} className="expandedDetails__customer-name" />
          </div>
          <Tag textSize="md" label={jobDetails?.customerType} />
        </div>
        <VehicleSection
          name={vehicleBasicData.name}
          info={vehicleBasicData.info}
          status={vehStatus
            ? vehicleStatuses.find((el) => el.value === vehStatus)?.label
            : 'DRAFT'}
          regNumber={jobVehicle?.regNumber}
          chassisNumber={jobVehicle?.chassisNumber}
          vehicleLocation={jobVehicle?.vehicleLocation}
          edge
          readonly
        />
      </div>
      <div className="expandedDetails__fields">
        {detailsFields.map(({ label, value, isWide }) => (
          <JobLineExpandedDetailsField key={label} label={label} value={value} isWide={isWide} />
        ))}
      </div>
    </div>
  );
};

export default JobLineExpandedDetails;
