import React from 'react';
import classNames from 'classnames';
import './WorkCalendarLineSkeleton.scss';

const WorkCalendarLineSkeleton: React.FC = () => {
  return (
    <>
      {Array(10).fill(null).map((_, i) => (
        <React.Fragment key={i}>
          <div
            className={classNames('workCalendarLine__col col-fixed-left')}
          >
            <div className="workCalendarLineSkeleton__name skeleton-box" />
            <div className="workCalendarLineSkeleton__role skeleton-box" />
          </div>
          {Array(7).fill(null).map((_, i) => <div key={i} className={classNames('workCalendarLine__col')}>
            <div className="workCalendarLineSkeleton__card skeleton-box" />
          </div>)}
          <div />
        </React.Fragment>
      ))}
    </>
  );
};

export default WorkCalendarLineSkeleton;
