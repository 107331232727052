import {
  FieldErrors, FieldValues, Path, UseFormClearErrors, UseFormSetValue,
} from 'react-hook-form';

export const clearOtherFieldsErrorFn = <T, D extends FieldValues>(
  fieldsToValidate: string[],
  field: string,
  clearErrors: UseFormClearErrors<D>,
  errors: FieldErrors<D>,
  initData: T | undefined | null,
  setValue: UseFormSetValue<D>,
) => {
  const fieldsToClear = fieldsToValidate.filter((f) => f !== field);
  fieldsToClear.forEach((f) => {
    if (errors[f]) {
      clearErrors(f as Path<D>);
      if (initData) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        setValue(f, initData[f]);
      }
    }
  });
};
