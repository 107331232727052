import React, { useCallback, useEffect, useState } from 'react';
import { Divider } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCubes, faEdit, faEllipsisVertical, faTag,
} from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useParams } from 'react-router-dom';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Modal from '../../../../../core/components/modal/Modal';
import {
  BooleanFunctionType,
  initDataList,
  SearchTableFilterType,
  VoidFunctionType,
} from '../../../../../core/types/coreTypes';
import './AdjustQtyModal.scss';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import Thead from '../../../../../core/components/table-components/Thead';
import { getPartQuantities, patchPartQuantities } from '../../../../../store/thunks/stock/stockThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  adjustQtyColumnsSelector,
  partQuantitiesAvailablePurchaseOrdersSelector,
  partQuantitiesPagingSelector,
  partQuantitiesSelector,
  partQuantitiesTableViewSelector,
} from '../../../../../store/selectors/stockSelectors';
import Tr from '../../../../../core/components/table-components/Tr';
import Tbody from '../../../../../core/components/table-components/Tbody';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';
import {
  setPartQuantities,
  setPartQuantitiesAvailablePurchaseOrders,
  setPartQuantitiesPaging,
} from '../../../../../store/slices/stockSlice';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import {
  partQtyStatusesValuesSelector,
  qtyStatusesSelector,
} from '../../../../../store/selectors/coreStatusesSelectors';
import { adjustPartQtyOrderingSelector } from '../../../../../store/selectors/coreOrderingSelectors';
import { initAdjustPartQuantityFilters } from '../../../utils/data';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import TableLoader from '../../../../../core/components/table-components/TableLoader';
import { useAdjustQtyFooterActions } from './useAdjustQtyFooterActions';
import AdjustQtyFooterActions from './AdjustQtyFooterActions';
import AdjustQtyHeaderActions from './AdjustQtyHeaderActions';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';

type AdjustPartQtyModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  currentStatus?: number
  setCurrentStatus: (value?: number) => void,
  setIsDeleteQty: BooleanFunctionType,
  setQtyToChange: (value?: number) => void,
  setIsEditQtyModal: BooleanFunctionType,
  setIsEditLinkedToModal: BooleanFunctionType,
}

const AdjustPartQtyModal: React.FC<AdjustPartQtyModalProps> = ({
  isVisible, onCancel, currentStatus, setCurrentStatus, setIsDeleteQty,
  setQtyToChange, setIsEditQtyModal, setIsEditLinkedToModal,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const qtyStatuses = useAppSelector(qtyStatusesSelector);
  const { free, reserved } = useAppSelector(partQtyStatusesValuesSelector);

  const { setFields, initAdjustQtyFields } = useAdjustQtyFooterActions(currentStatus);

  const { items: initItems } = useAppSelector(partQuantitiesSelector);
  const { items, totalCount } = useAppSelector(partQuantitiesTableViewSelector);

  const adjustPartQtyOrdering = useAppSelector(adjustPartQtyOrderingSelector);
  const pagingOrdering = useAppSelector(partQuantitiesPagingSelector);
  const adjustQtyColumns = useAppSelector(adjustQtyColumnsSelector);
  const partQuantitiesAvailablePurchaseOrders = useAppSelector(partQuantitiesAvailablePurchaseOrdersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    linkedTo: undefined,
  });

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...pagingOrdering,
        [field]: undefined,
      };
      dispatch(setPartQuantitiesPaging(values));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...pagingOrdering, [field]: searchVl[field]?.trim() };
    dispatch(setPartQuantitiesPaging(values));
  };

  const [purchases, setPurchases] = useState<(number | string)[]>([]);

  const defaultHiddenKeys = ['id', 'orderId'];
  const hiddenKeys = currentStatus === reserved ? defaultHiddenKeys : [...defaultHiddenKeys, 'orderNumber'];

  useEffect(() => {
    if (qtyStatuses && qtyStatuses.length > 0 && !currentStatus) {
      setCurrentStatus(qtyStatuses[0].value);
    }
    // eslint-disable-next-line
  }, [qtyStatuses, currentStatus]);

  useEffect(() => {
    if (adjustPartQtyOrdering.length > 0 && !pagingOrdering.order) {
      dispatch(setPartQuantitiesPaging({ ...pagingOrdering, order: { field: adjustPartQtyOrdering[0].value, isAsc: true } }));
    }
    // eslint-disable-next-line
  }, [adjustPartQtyOrdering.length, pagingOrdering.order]);

  useEffect(() => {
    if (isVisible && id) {
      const values = purchases.map((v) => {
        if (v === 'null') {
          return null;
        } else return v;
      });
      if (currentStatus && pagingOrdering.order) {
        dispatch(getPartQuantities({
          params: {
            partId: +id,
            statuses: [currentStatus],
            purchaseOrderIds: values.length > 0 ? values : undefined,
            hasSellingPrice: pagingOrdering.hasSellingPrice,
            page: pagingOrdering.page,
            pageSize: pagingOrdering.pageSize,
            order: pagingOrdering.order,
            linkedTo: pagingOrdering.linkedTo,
          },
        }));
      }
    }
    // eslint-disable-next-line
  }, [isVisible, id, currentStatus, pagingOrdering, purchases]);

  const closeModalHandle = () => {
    onCancel();
    setCurrentStatus(undefined);
    dispatch(setPartQuantitiesPaging(initAdjustPartQuantityFilters));
    dispatch(setPartQuantities(initDataList));
    setFields(initAdjustQtyFields);
    dispatch(setPartQuantitiesAvailablePurchaseOrders(null));
  };

  const markAsReservedHandle = (qtyId: number) => {
    const initQuantity = initItems?.find((el) => el.id === qtyId)?.quantity;
    const isAllowedToPatch = id && currentStatus && reserved && initQuantity;
    if (isAllowedToPatch) {
      dispatch(patchPartQuantities({
        partId: +id,
        id: qtyId,
        data: { quantity: initQuantity, status: reserved },
        statusToRefetch: free,
      }));
    }
  };
  const markAsFreeHandle = (qtyId: number) => {
    const initQuantity = initItems?.find((el) => el.id === qtyId)?.quantity;
    const isAllowedToPatch = id && currentStatus && free && initQuantity;
    if (isAllowedToPatch) {
      dispatch(patchPartQuantities({
        partId: +id,
        id: qtyId,
        data: { quantity: initQuantity, status: free },
        statusToRefetch: reserved,
      }));
    }
  };
  const actions = useCallback((qtyId: number) => {
    const basicActions = [
      {
        key: 'changeStatus',
        label: currentStatus === free ? 'Mark as Reserved' : 'Mark as Free',
        icon: <FontAwesomeIcon icon={currentStatus === free ? faTag : faCubes} />,
        onClick: () => {
          if (currentStatus === free) {
            markAsReservedHandle(qtyId);
          } else markAsFreeHandle(qtyId);
        },
      },
      {
        key: 'editPrice',
        label: 'Edit price',
        icon: <FontAwesomeIcon icon={faEdit} />,
        onClick: () => {
          setIsEditQtyModal(true);
          setQtyToChange(qtyId);
        },
      },
      {
        key: 'deleteQty',
        label: 'Delete',
        icon: <FontAwesomeIcon icon={faTrashAlt} />,
        onClick: () => {
          setIsDeleteQty(true);
          setQtyToChange(qtyId);
        },
      },
    ];

    const actionsToBeExtended = [...basicActions];

    const editLinkedToAction = [{
      key: 'editLinkedTo',
      label: 'Edit linked to',
      icon: <FontAwesomeIcon icon={faEdit} />,
      onClick: () => {
        setQtyToChange(qtyId);
        setIsEditLinkedToModal(true);
      },
    }];
    actionsToBeExtended.splice(1, 0, ...editLinkedToAction);

    return currentStatus === reserved
      ? actionsToBeExtended
      : basicActions;
    // eslint-disable-next-line
  }, [currentStatus, id, initItems]);

  const filters = [
    {
      dataIndex: 'purchaseOrderNo',
      value: purchases,
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        dispatch(setPartQuantitiesPaging({ ...pagingOrdering, page: 1 }));
        setPurchases(checkedValues as number[]);
      },
    },
  ];

  const sortHandle = (field: number) => {
    if (pagingOrdering.order?.field === field) {
      dispatch(setPartQuantitiesPaging({
        ...pagingOrdering,
        order: { ...pagingOrdering.order, isAsc: !pagingOrdering.order.isAsc },
      }));
    } else {
      dispatch(setPartQuantitiesPaging({
        ...pagingOrdering,
        order: { field, isAsc: true },
      }));
    }
  };
  return (
    <Modal
      visible={isVisible}
      onCancel={closeModalHandle}
      title="Adjust quantity & prices"
      destroyOnClose
      width={1100}
    >
      <div className="adjustQty">
        <AdjustQtyHeaderActions currentStatus={currentStatus} setCurrentStatus={setCurrentStatus} />
        <TableNew>
          <Thead>
            {adjustQtyColumns.map((col) => <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalCount}
              className={`partQtyTh--${col.dataIndex}`}
              filters={filters}
              sortHandle={sortHandle}
              tableFilters={pagingOrdering}
              disabledFilter={!partQuantitiesAvailablePurchaseOrders?.length}
              insideModal
              searchVl={searchVl}
              onChangeSearchHandle={onChangeSearchHandle}
              searchPressHandle={searchPressHandle}
            />)}
            <ThNew action><span className="sr-only">Actions</span></ThNew>
          </Thead>
          {isLoading
            ? <TableLoader colspan={adjustQtyColumns.length + 1} />
            : <Tbody>
              {totalCount > 0
                ? <>
                  {items?.map((qty, i) => <Tr key={i}>
                    {Object.entries(qty).map(([key, value]) => {
                      if (hiddenKeys.includes(key)) return null;
                      return <TdNew key={key}>
                        {key === 'linkedTo'
                          ? <TruncateTooltip value={value || '-'} table />
                          : value || '-'}
                      </TdNew>;
                    })}
                    <TdNew action>
                      <Dropdown
                        menuItems={qty.id ? actions(qty.id) : []}
                        icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                      />
                    </TdNew>
                  </Tr>)}
                </>
                : <EmptyTableSection text="No records" colSpan={adjustQtyColumns.length + 1} />}
            </Tbody>}
        </TableNew>
        <Divider />
        <AdjustQtyFooterActions currentStatus={currentStatus} />
      </div>
    </Modal>
  );
};

export default AdjustPartQtyModal;
