import React from 'react';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { useAppSelector } from '../../../../../store/hooks';
import { isFetchingSelector } from '../../../../../store/selectors/coreSelectors';

type DeleteLinkedUserModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  deleteUserClick: BooleanFunctionType,
}

const DeleteLinkedUserModal: React.FC<DeleteLinkedUserModalProps> = ({
  isVisible, onCancel, deleteUserClick,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      width={600}
      title="Delete user"
    >
      <p className="confirmationText">
        <span>This user has a linked employee record in the system.</span>
        <span className="confirmationText__proceed">Do you want to delete employee record as well?</span>
      </p>
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={onCancel}
        middleActionLabel="No, delete user only"
        middleActionClick={() => deleteUserClick(false)}
        createLabel="Yes, delete all"
        createClick={() => deleteUserClick(true)}
        isLoading={isFetching}
      />
    </Modal>
  );
};

export default DeleteLinkedUserModal;
