import React, { useCallback, useState } from 'react';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { useAppSelector } from '../../store/hooks';
import {
  QuoteUnitsOfMeasureSelector,
  timeMeasureValuesSelector,
} from '../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../components/truncate-tooltip/TruncateTooltip';
import { NumberFunctionType } from '../../core/types/coreTypes';
import PriceSourceTag from '../components/price-source-tag/PriceSourceTag';
import PrivateLineLink from '../components/private-line-link/PrivateLineLink';
import { routesPath } from '../../core/enums/pathEnum';
import Tag from '../../core/components/tag/Tag';
import { PartReqLineUIType } from '../types/commonTypes';
import { allocatedPartReqLinesStatusesSelector } from '../../store/selectors/coreStatusesSelectors';

export const usePartReqCellInteractions = (
  editQty: (id: number, isChildLine?: boolean) => void,
  openCustomModal: NumberFunctionType,
  data: Omit<PartReqLineUIType, 'childLines'>,
  valueRenderer: (key: string, value: string | number) => string | number | JSX.Element,
  allowedToEdit?: boolean,
) => {
  const forbiddenToEdit = [
    'ordinalNumber', 'categoryId', 'productNumber', 'productName', 'quantity', 'unitPrice', 'priceSources', 'netPrice', 'status',
  ];
  const hiddenCells = [
    'id', 'isProductSuitable', 'rules', 'partId',
    'productWeight', 'partsKitId', 'unitOfMeasure',
    'partsKitComponentId', 'isFree', 'childLines',
    'partsKitComponentPartId',
  ];

  const [fieldToEdit, setFieldToEdit] = useState<string>('');
  const [editMode, setEditMode] = useState<boolean>(false);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const timeMeasures = useAppSelector(timeMeasureValuesSelector);
  const allocatedPartReqLinesStatuses = useAppSelector(allocatedPartReqLinesStatusesSelector);

  const measureResult = useCallback(
    (value: number) => unitsOfMeasure.find((unit) => unit.value === value)?.label,
    [unitsOfMeasure],
  );

  const isPartLine = !!data.partId;
  const isPartsKitParentLine = !!data.partsKitId;
  const isPartsKitChildLine = !!data.partsKitComponentId;
  const isPartsKitChildUsualLine = isPartsKitChildLine && !!data.partsKitComponentPartId;
  const isCustomLine = !isPartLine && !isPartsKitParentLine && !isPartsKitChildUsualLine;

  const isUsualLineAllocated = (isPartLine || isPartsKitChildUsualLine)
      && allocatedPartReqLinesStatuses.find((el) => el.label.toUpperCase() === data.status);
  const isPriceAndQtyStatusDisabled = (key: string) => {
    return (key === 'quantity' || key === 'unitPrice') && isUsualLineAllocated;
  };

  const cellClickHandle = (key: string) => {
    if (allowedToEdit) {
      if (isCustomLine) {
        const modalEditFields = ['categoryId', 'productName', 'quantity', 'unitPrice', 'tax', 'weight'];
        const inlineEditFields = ['notes', 'discount'];
        if (modalEditFields.includes(key)) {
          openCustomModal(data.id);
        }
        if (inlineEditFields.includes(key)) {
          setFieldToEdit(key);
          setEditMode(true);
        }
      } else if (isPartLine) {
        const modalEditFields = ['quantity', 'unitPrice'];
        const inlineEditFields = ['notes', 'discount', 'price', 'weight', 'tax'];
        if (modalEditFields.includes(key)) {
          editQty(data.id);
        }
        if (inlineEditFields.includes(key)) {
          setFieldToEdit(key);
          setEditMode(true);
        }
      } else if (isPartsKitParentLine) {
        const inlineEditFields = ['notes', 'quantity', 'discount'];
        if (inlineEditFields.includes(key)) {
          setFieldToEdit(key);
          setEditMode(true);
        }
      }
    }
  };

  const renderTruncateTooltip = (value: string | null) => {
    return <TruncateTooltip
      value={value}
      table
    />;
  };

  const cellRenderHandle = (key: string, value: unknown) => {
    switch (key) {
      case 'categoryId':
        return renderTruncateTooltip(value as string | null);
      case 'quantity':
        return `${data.quantity} ${measureResult(data.unitOfMeasure)}`;
      case 'notes':
        return renderTruncateTooltip(value as string | null);
      case 'weight':
        if (timeMeasures.includes(data.unitOfMeasure)) {
          return '-';
        } else return valueRenderer(key, value as string | number);
      case 'priceSources':
        return <div className="priceTags">
          {typeof value === 'string'
            ? value as string
            : (value as number[]).map((el: number) => <PriceSourceTag source={el} />)}
        </div>;
      case 'tax':
        if (data.partsKitId || data.tax === null) {
          return '-';
        } else return valueRenderer(key, value as string | number);
      case 'productNumber':
      case 'productName':
        if (data.partId) {
          return <PrivateLineLink to={`/${routesPath.STOCK}/part/${data.partId}`} permission={PermissionEnum.StockPartViewPage}>
            {renderTruncateTooltip(key === 'productName' ? data.productName : data.productNumber)}
          </PrivateLineLink>;
        }
        if (data.partsKitId) {
          return <PrivateLineLink
            to={`/${routesPath.STOCK}/${routesPath.PARTS_KITS}/${data.partsKitId}`}
            permission={PermissionEnum.StockPartsKitViewPage}
          >
            {renderTruncateTooltip(key === 'productName' ? data.productName : data.productNumber)}
          </PrivateLineLink>;
        } else {
          return renderTruncateTooltip(key === 'productName' ? data.productName : data.productNumber);
        }
      case 'status':
        return <Tag label={value as string | number} />;
      default: return valueRenderer(key, value as string | number);
    }
  };

  return {
    fieldToEdit,
    setFieldToEdit,
    editMode,
    setEditMode,
    cellClickHandle,
    forbiddenToEdit,
    hiddenCells,
    cellRenderHandle,
    isPriceAndQtyStatusDisabled,
  };
};
