import React from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { NumberFunctionType, StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';
import Modal from '../../../core/components/modal/Modal';
import Button from '../../../core/components/button/Button';
import ContactLine from '../contacts/ContactLine';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { ContactDto } from '../../types/commonTypes';
import OpenEmailClientModal from './OpenEmailClientModal';

type GenerateEmailModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  checkedKeys: number[],
  checkAll: VoidFunctionType,
  uncheckAll: VoidFunctionType,
  checkLine: NumberFunctionType,
  loadMore: VoidFunctionType,
  selectHandle: VoidFunctionType,
  dataLength: number,
  hasMore: boolean,
  contactsList: Omit<ContactDto, 'customerId'>[],
  emailHref: string,
  setEmailHref: StringFunctionType,
}

const GenerateEmailModal: React.FC<GenerateEmailModalProps> = ({
  visible,
  onCancel,
  checkedKeys,
  checkAll,
  uncheckAll,
  checkLine,
  loadMore,
  selectHandle,
  dataLength,
  hasMore,
  contactsList,
  emailHref,
  setEmailHref,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);

  return (
    <>
      <OpenEmailClientModal emailHref={emailHref} onCancel={() => setEmailHref('')} />
      <Modal
        visible={visible}
        onCancel={onCancel}
        destroyOnClose
        width={800}
        title="Select contacts for email"
      >
        <section className="contacts">
          <Button
            label="Select all"
            onClick={checkAll}
            disabled={isFetching}
            className="generateEmailSelectAll"
          />
          <Button
            label="Deselect all"
            onClick={uncheckAll}
            disabled={isFetching}
          />
          <div className="contacts__wrapper generateContactsWrapper" id="contactsWrapper">
            <InfiniteScroll
              dataLength={dataLength}
              next={loadMore}
              scrollableTarget="contactsWrapper"
              hasMore={hasMore}
              loader={<span className="contacts__loader">Loading...</span>}
            >
              <ul className="lines-list">
                {contactsList.map((line) => (
                  <li
                    className="lines-list__item"
                    key={line.id}
                  >
                    <ContactLine
                      line={line}
                      checkedKeys={checkedKeys}
                      checkLine={checkLine}
                      isFetching={isFetching}
                    />
                  </li>))}
              </ul>
            </InfiniteScroll>
          </div>
          <ButtonActions
            cancelLabel="Cancel"
            cancelClick={onCancel}
            createLabel={`Select contacts ${checkedKeys.length ? `(${checkedKeys.length})` : ''}`}
            createClick={selectHandle}
            disabledCreate={checkedKeys.length === 0}
            isLoading={isFetching}
          />
        </section>
      </Modal>
    </>
  );
};

export default GenerateEmailModal;
