import React from 'react';
import { Input } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import classNames from 'classnames';
import { ChangeEventHandler } from '../../types/coreTypes';
import './InputPassword.scss';

type InputPasswordProps = {
  value?: string | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  placeholder?: string;
  disabled?: boolean;
  className?: string;
  error?: string;
  label?: string;
  suffix?: React.ReactNode;
  visibilityToggle?: boolean;
}

const InputPassword: React.FC<InputPasswordProps> = ({
  value, onChange, disabled, className, error, placeholder, label, suffix, visibilityToggle,
}) => {
  return (
    <div className={classNames(className, 'passwordInput')}>
      {label && <span className="passwordInput-label">
        {label}
        {' '}
        {suffix}
      </span>}
      <Input.Password
        value={value || undefined}
        onChange={onChange}
        disabled={disabled}
        className="passwordInput-field"
        placeholder={placeholder}
        visibilityToggle={visibilityToggle}
        iconRender={(visible) => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
      />
      {error && <span className="passwordInput-error">{error || ' '}</span>}
    </div>
  );
};

export default InputPassword;
