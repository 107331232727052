import React from 'react';
import PricelistDetailsSection from './PricelistDetailsSection';
import PricelistActivitySection from './PricelistActivitySection';
import { BooleanFunctionType } from '../../../../../core/types/coreTypes';

type PricelistDetailsAndActivityProps = {
  setAffected: BooleanFunctionType,
  setAffectedParts: BooleanFunctionType,
}

const PricelistDetailsAndActivity: React.FC<PricelistDetailsAndActivityProps> = ({ setAffected, setAffectedParts }) => {
  return (
    <div className="pricelistTimeline info-grid">
      <PricelistDetailsSection setAffected={setAffected} setAffectedParts={setAffectedParts} />
      <PricelistActivitySection />
    </div>
  );
};

export default PricelistDetailsAndActivity;
