import React from 'react';
import { Link } from 'react-router-dom';
import { DictionaryItem, VoidFunctionType } from '../../../../core/types/coreTypes';

type StockCardCounterProps = {
  data: DictionaryItem,
  allowed: boolean,
  to: string,
  onClick?: VoidFunctionType,
}
const StockCardCounter:React.FC<StockCardCounterProps> = ({
  data, allowed, to, onClick,
}) => {
  const content = <>
    <span className="stockCardCounter__value">{data.value}</span>
    <span className="stockCardCounter__text">{data.label}</span>
  </>;

  return (
    allowed
      ? <Link
        to={to}
        className="stockCardCounter stockCardCounter--link"
        onClick={onClick}
      >
        {content}
      </Link>
      : <div className="stockCardCounter">
        {content}
      </div>
  );
};

export default StockCardCounter;
