export enum ContentModeEnum {
  ENQUIRY_DETAILS = 'details',
  ENQUIRY_QUOTE = 'quote',
  ENQUIRY_CONTACTS = 'contacts',
  ENQUIRY_ATTACHMENTS = 'attachments',
  ENQUIRY_LINKS = 'links',
}

export const EnquiryContentModes = [
  ContentModeEnum.ENQUIRY_DETAILS,
  ContentModeEnum.ENQUIRY_QUOTE,
  ContentModeEnum.ENQUIRY_CONTACTS,
  ContentModeEnum.ENQUIRY_ATTACHMENTS,
  ContentModeEnum.ENQUIRY_LINKS,
];
