import {
  allowedBaForUserSelector, allowedJobTypesForUserSelector,
  businessAreasSelector,
  customerTypesSelector, enquirySourcesSelector,
  jobTypesSelector, priorityLevelsSelector, quoteLineTaxesSelector,
} from '../../../store/selectors/coreSelectors';
import { useAppSelector } from '../../../store/hooks';
import { enquiryStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

export const useFiltersItems = () => {
  const enquiryStatus = useAppSelector(enquiryStatusSelector);
  const customerTypes = useAppSelector(customerTypesSelector);
  const jobTypes = useAppSelector(jobTypesSelector);
  const allowedJobTypesForUser = useAppSelector(allowedJobTypesForUserSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const enquirySources = useAppSelector(enquirySourcesSelector);
  const businessAreas = useAppSelector(businessAreasSelector);
  const allowedBaForUser = useAppSelector(allowedBaForUserSelector);
  const quoteLineTaxes = useAppSelector(quoteLineTaxesSelector);

  return {
    enquiryStatus,
    customerTypes,
    jobTypes,
    allowedJobTypesForUser,
    priorityLevels,
    enquirySources,
    businessAreas,
    allowedBaForUser,
    quoteLineTaxes,
  };
};
