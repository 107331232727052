import React from 'react';
import OrderLinksColumns from './OrderLinksColumns';
import { orderLinksRowsSelector } from '../../../../../store/selectors/ordersSelector';
import { useAppSelector } from '../../../../../store/hooks';
import LinksTable from '../../../../../common/components/links-table/LinksTable';
import LinksBody from '../../../../../common/components/links-table/LinksBody';

const OrderLinks: React.FC = () => {
  const orderLinks = useAppSelector(orderLinksRowsSelector);
  return (
    <LinksTable>
      <OrderLinksColumns totalCount={orderLinks.length || 0} />
      <LinksBody links={orderLinks} />
    </LinksTable>
  );
};

export default OrderLinks;
