import { CoreAttachmentDto } from '../../../core/types/coreTypes';
import { ActivityEventBasicFieldsType } from '../../../common/types/commonTypes';

export type EmployeeResponseDto = {
  id: number,
  firstName: string | null,
  lastName: string | null,
  title: string | null,
  attachmentCount: number | null,
  birthday: string | null,
  gender: number,
  nationalInsuranceNumber: string | null,
  email: string | null,
  telephone: string | null,
  mobile: string | null,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  postalCode: string | null,
  region: string | null,
  jobTitle: string | null,
  linkedAccountId: number | null,
  linkedAccountRole: number,
  department: number,
  status: number,
  createdDate: string | null,
  modifiedDate: string | null,
  referenceNumber: number | null,
}

export type GetEmployeeResponse = {
  images: CoreAttachmentDto[] | null,
  employee: EmployeeResponseDto,
}

export type PartialEmployeeResponseDto = Partial<EmployeeResponseDto>

export type EmployeeIceContactResponseDto = {
  iceContactFullName: string | null,
  relationship: string | null,
  iceContactTelephone: string | null,
  iceContactAddressLine1: string | null,
  iceContactAddressLine2: string | null,
  iceContactCityTown: string | null,
  iceContactPostalCode: string | null,
  iceContactRegion: string | null,
}

export type GetEmployeeIceContactResponse = {
  employeeIceContact: EmployeeIceContactResponseDto,
}

export type EmployeeSkillSaveDto = {
  skillId: number,
  level: number,
}

export type PatchSkillsType = {
  skills: EmployeeSkillSaveDto[],
}

export type PatchEmployeeDto = Partial<Omit<EmployeeResponseDto, 'id'> & EmployeeIceContactResponseDto & PatchSkillsType>

export const defaultICEContactFieldValues: EmployeeIceContactResponseDto = {
  iceContactFullName: '',
  relationship: '',
  iceContactTelephone: '',
  iceContactAddressLine1: '',
  iceContactAddressLine2: '',
  iceContactCityTown: '',
  iceContactPostalCode: '',
  iceContactRegion: '',
};

export interface EmployeeEmploymentDto {
  id: number | null,
  jobTitle: string | null,
  department: number,
  startDate: string | null,
  endDate: string | null,
  annualLeaveEntitlement: number | null,
  salaryType: number,
  salaryValue: number | null,
}

export type SkillEventDataType = {
  Id: number,
  CategoryId: number,
  Name: string,
  CategoryName: string,
  SkillLevel: number,
}

export type EmployeeActivityEventType = {
  eventType: number,
  firstName?: string | null,
  lastName?: string | null,
  id?: number,
  startDate?: string,
  endDate?: string | null,
  jobTitle?: string,
  department?: number,
  salaryValue?: number,
  annualLeaveEntitlement?: number,
  salaryType?: number
  oldDayPart?: number | null,
  newDayPart?: number | null,
} & ActivityEventBasicFieldsType
