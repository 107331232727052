import React from 'react';
import { faTruck } from '@fortawesome/free-solid-svg-icons';
import Tbody from '../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import ExistedVehicleCatalogCells from './ExistedVehicleCatalogCells';
import { VehicleProfileSearchType } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

type ExistedVehicleCatalogBodyProps = {
  vehicleList: VehicleProfileSearchType[],
  totalCount: number,
  searchValue: string,
  setChosenVehicleProfile: (v: VehicleProfileSearchType | null) => void,
}

const ExistedVehicleCatalogBody: React.FC<ExistedVehicleCatalogBodyProps> = ({
  vehicleList, totalCount, searchValue, setChosenVehicleProfile,
}) => {
  return (
    <Tbody>
      {totalCount === 0
        ? <EmptyTableSection
          colSpan={6}
          icon={faTruck}
          text={searchValue && totalCount === 0 ? 'No records' : 'Please enter a search parameter'}
        />
        : <ExistedVehicleCatalogCells
          vehicleList={vehicleList}
          setChosenVehicleProfile={setChosenVehicleProfile}
        />}
    </Tbody>
  );
};

export default ExistedVehicleCatalogBody;
