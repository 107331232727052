import React from 'react';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import './TruncateDropdownLabel.scss';

type TruncateDropdownItemProps = {
  value: string,
  icon?: IconDefinition,
}
const TruncateDropdownLabel:React.FC<TruncateDropdownItemProps> = ({ value, icon }) => {
  return (
    <div className="truncateDropdownLabel">
      {icon && <FontAwesomeIcon icon={icon} />}
      <TruncateTooltip
        value={value}
        className="truncateDropdownLabel__text"
        overlayClassName="truncateDropdownLabel__overlay"
        hideTooltipOnMobile
      />
    </div>
  );
};

export default TruncateDropdownLabel;
