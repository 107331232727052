import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCartArrowDown, faCheck } from '@fortawesome/free-solid-svg-icons';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { DictionaryItem, DictionaryStringValueItem } from '../../../../core/types/coreTypes';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../core/components/tag/Tag';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import './InvoicingDashboardLine.scss';

type InvoicingDashboardLineProps = {
  to: string,
  permission: PermissionEnum,
  info: DictionaryStringValueItem[],
  price: DictionaryStringValueItem[],
  entities: DictionaryItem[],
  invoicingType: 'sales' | 'purchases',
  status: string,
}

const InvoicingDashboardLine: React.FC<InvoicingDashboardLineProps> = ({
  to,
  permission,
  info,
  price,
  entities,
  status,
  invoicingType,
}) => {
  const className = invoicingType === 'sales' ? 'invoicingSalesDashboardItem' : 'invoicingDashboardItem';
  return (
    <PrivateLineLink
      to={to}
      permission={permission}
      className={className}
    >
      <span className={`${className}__icon`}>
        <FontAwesomeIcon icon={invoicingType === 'sales' ? faCheck : faCartArrowDown} />
      </span>
      <ul className={`${className}__info`}>
        {info.map(({ label, value }) => <li key={label}>
          <TruncateTooltip value={label} className={`${className}__label`} />
          <TruncateTooltip value={value} className={`${className}__value`} />
        </li>)}
      </ul>
      <ul className={`${className}__price`}>
        {price.map(({ label, value }) => <li key={label}>
          <TruncateTooltip value={label} className={`${className}__label`} />
          <TruncateTooltip value={`${value}`} className={`${className}__value`} />
        </li>)}
      </ul>
      <ul className={`${className}__entities`}>
        {entities.map(({ label, value }) => <li key={label}>
          <TruncateTooltip value={label} className={`${className}__label`} />
          <TruncateTooltip value={value} className={`${className}__value`} />
        </li>)}
      </ul>
      <span className={`${className}__tag-wrapper`}>
        <Tag
          label={<TruncateTooltip value={status} overlayClassName="tooltip-sm" />}
          truncate
        />
      </span>
    </PrivateLineLink>
  );
};

export default InvoicingDashboardLine;
