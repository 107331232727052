export enum PurchaseOrderViewParamEnum {
  DETAILS = 'details-and-activity',
  PART_LINES = 'part-lines',
  ATTACHMENTS = 'attachments',
}

export const PurchaseOrderModes = [
  PurchaseOrderViewParamEnum.DETAILS,
  PurchaseOrderViewParamEnum.PART_LINES,
  PurchaseOrderViewParamEnum.ATTACHMENTS,
];

export enum PurchaseOrderPartStateEnum {
  InProgress = 'In progress',
  Processed = 'Processed',
}

export enum PurchaseOrderEventTypesEnum {
  Note = 'Note',
  PurchaseOrderCreation = 'PurchaseOrderCreation',
  PurchaseOrderFieldUpdateOrAddingValue = 'PurchaseOrderFieldUpdateOrAddingValue',
  PurchaseOrderStatusChange = 'PurchaseOrderStatusChange',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  PurchaseOrderPartFieldUpdateOrAddingValue = 'PurchaseOrderPartFieldUpdateOrAddingValue',
  PurchaseOrderPartCreation = 'PurchaseOrderPartCreation',
  PurchaseOrderPartDeletion = 'PurchaseOrderPartDeletion',
  PurchaseOrderPartFailedQuantityCreation = 'PurchaseOrderPartFailedQuantityCreation',
  PurchaseOrderPartFailedQuantityDeletion = 'PurchaseOrderPartFailedQuantityDeletion',
  PurchaseOrderDelete = 'PurchaseOrderDelete',
  InvoiceDocumentCreation = 'InvoiceDocumentCreation',
  InvoiceDocumentFieldUpdate = 'InvoiceDocumentFieldUpdate',
  InvoiceDocumentDeletion = 'InvoiceDocumentDeletion',
  NominalRecordCreation = 'NominalRecordCreation',
  NominalRecordFieldUpdate = 'NominalRecordFieldUpdate',
  NominalRecordDeletion = 'NominalRecordDeletion',
}

export enum EnterPriceQtyModes {
  PRICELIST = 'pricelist',
  MANUAL = 'manual',
}

export enum PurchaseOrderEventSourceEnum {
  Stock = 'Stock',
  Invoicing = 'Invoicing',
}
