import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  salesByCustomerFiltersSelector,
  salesReportByCustomerDataChartSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import Chart from '../../../../../../core/components/chart/Chart';

const SalesReportByCustomerChart: React.FC = () => {
  const { categories, series, entityNumbers } = useAppSelector(salesReportByCustomerDataChartSelector);
  const { pageSize } = useAppSelector(salesByCustomerFiltersSelector);
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);

  return <Chart
    reportIsVisible={reportIsVisible}
    type="bar"
    height={550}
    series={series.slice(0, pageSize)}
    options={{
      chart: {
        type: 'bar',
        toolbar: {
          tools: {
            download: true,
            selection: false,
            zoom: false,
            zoomin: false,
            zoomout: false,
            pan: false,
          },
        },
        zoom: {
          enabled: false,
        },
      },
      legend: {
        fontFamily: '"Montserrat", "Arial", sans-serif',
        fontSize: '15px',
      },
      tooltip: {
        x: {
          formatter(_, a) {
            return entityNumbers[a.dataPointIndex];
          },
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        tickPlacement: 'on',
        categories: categories.slice(0, pageSize),
        labels: {
          trim: true,
          minHeight: 150,
          rotate: -60,
          rotateAlways: true,
        },
      },
    }}
  />;
};

export default SalesReportByCustomerChart;
