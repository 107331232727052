import React from 'react';
import { Tooltip } from 'antd';
import MultipleJobTipContent from './MultipleJobTipContent';
import JobLineTooltipContent from './JobLineTooltipContent';
import JobLineProgressBar from './JobLineProgressBar';
import Tag from '../../../core/components/tag/Tag';
import { JobCalendarElementDtoBase, JobCalendarSubElementDto, JobElementClassname } from '../types/jobCalendarTypes';
import { useAppSelector } from '../../../store/hooks';
import { jobStatusesSelector, jobStatusesValuesSelector } from '../../../store/selectors/coreStatusesSelectors';

type JobTypeElementProps = {
  jobElement: JobCalendarElementDtoBase,
  getJobSlotsSum: (subElements: JobCalendarSubElementDto[]) => number,
  setClassname: (item: JobCalendarSubElementDto, isEl?: boolean, isJobNotSatisfiesFilters?: boolean) => JobElementClassname,
}

const JobTypeElement: React.FC<JobTypeElementProps> = ({ jobElement, getJobSlotsSum, setClassname }) => {
  const jobStatuses = useAppSelector(jobStatusesSelector);
  const { postponed } = useAppSelector(jobStatusesValuesSelector);

  const jobsList = jobElement.jobs;
  const jobsSatisfiedFilter = jobsList.filter((item) => item.isSatisfiesFilter);
  const jobsSatisfiedFilterLength = jobsSatisfiedFilter.length;
  const firstSatisfiedFiltersJob = jobsSatisfiedFilter[0];
  const jobsLength = jobElement.jobs.length;
  const jobSubElements = jobElement.subElements;
  const jobSlotsCount = getJobSlotsSum(jobSubElements || []);
  return (
    <Tooltip
      title={jobsSatisfiedFilter && jobsSatisfiedFilterLength > 1
        ? <MultipleJobTipContent jobs={jobsSatisfiedFilter} />
        : <JobLineTooltipContent info={jobsSatisfiedFilter[0]} />}
      placement="bottom"
      overlayClassName="jobTooltip"
      trigger="click"
    >
      <div className="job" style={{ gridColumn: `span ${jobSlotsCount}` }}>
        {jobsLength > 1 && jobsSatisfiedFilterLength > 1 && <div className="jobCountsPoint">{jobsSatisfiedFilterLength}</div>}
        <div className="job__info">
          <JobLineProgressBar job={firstSatisfiedFiltersJob} />
          {jobSlotsCount > 6 && <>
            <span className="jobName">{firstSatisfiedFiltersJob.jobNumber}</span>
            <Tag label={jobsSatisfiedFilter
              ? jobStatuses.find((st) => st.value === firstSatisfiedFiltersJob.childStatus)?.label
              : 'N/A'}
            />
          </>}
        </div>
        {/* Weekends have count of slots of single day */}
        {jobSubElements.map((item) => <div
          // eslint-disable-next-line max-len
          className={`dayContent ${setClassname(item)} ${jobsLength === 1 && firstSatisfiedFiltersJob.childStatus === postponed ? 'postponed' : ''}`}
          style={{ gridColumn: !item.isNonWorkingDay ? `span ${item.slotsCount}` : '' }}
        />)}
      </div>
    </Tooltip>
  );
};

export default JobTypeElement;
