import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import {
  vehicleProfilesTypesUiSelector,
  vehicleFlowsUiSelector,
  vehicleCheckTypesSelector,
  linksTypesSelector,
  linkTypesValuesSelector,
  vehicleCheckTypesValuesSelector,
  fullVehicleCheckDecisionsSelector,
  handoverVehicleCheckDecisionsSelector,
  vehicleFlowValuesSelector,
} from './coreSelectors';
import { getNo } from '../../core/utils/entityNumberingHandle';
import { getSortIndex } from '../../core/utils/getSortIndex';
import { VehicleProfilesOrderingEnum } from '../../vehicles/vehicle-profiles/enums/VehicleProfilesEnums';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  customersDictionaryLookupSelector,
  vehicleManufacturersDictionarySelector,
  vehicleProfilesGroupsUiSelector,
} from './sharedSelectors';
import { VehicleStatusEnum } from '../../core/enums/dictionariesEnums';
import { DictionaryItem } from '../../core/types/coreTypes';
import { archivedVehicleStatusesList, offSiteStatusesList, onSiteStatusesList } from '../../vehicles/dashboard/utils/data';
import {
  customerStatusesSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
  vehicleStatusesSelector,
} from './coreStatusesSelectors';
import { vehicleChecksOrderingSelector, vehicleProfilesOrderingSelector } from './coreOrderingSelectors';
import {
  VehicleChecksOrderingEnum,
} from '../../vehicles/vehicle-view-page/components/vehicle-content/vehicle-inspection/enums/VehicleInspectionEnum';
import { linksReformHandler, linksUiViewHandle } from '../../core/utils/linksReformHandler';
import { summaryFieldValueHandle } from '../../core/utils/summaryFieldValueHandle';

export const vehiclesDashboardFiltersSelector = (state: RootState) => state.vehicles.vehiclesDashboardFilters;

const vehiclesDashboardAssigneesInitSelector = (state: RootState) => state.vehicles.vehiclesDashboardAssignees;
export const vehiclesDashboardAssigneesLookupSelector = createSelector(
  vehiclesDashboardAssigneesInitSelector,
  (assignees) => assignees.map((el) => ({
    label: `${el.firstName} ${el.lastName}`,
    value: el.id,
  })),
);

const vehiclesListUniqueStatusesInitSelector = (state: RootState) => state.vehicles.vehiclesListUniqueStatuses;
export const vehiclesListUniqueStatusesOptionsSelector = createSelector(
  vehiclesListUniqueStatusesInitSelector,
  vehicleStatusesSelector,
  (list, statuses) => {
    return list.map((el) => ({
      label: statuses.find((st) => st.value === el)?.label || 'N/A',
      value: el,
    }));
  },
);

export const vehicleStatusesOnSiteSelector = createSelector(
  vehicleStatusesSelector,
  (statuses) => {
    return statuses.filter((el) => onSiteStatusesList.includes(el.label as VehicleStatusEnum));
  },
);

export const vehicleStatusesOffSiteSelector = createSelector(
  vehicleStatusesSelector,
  (statuses) => {
    return statuses.filter((el) => offSiteStatusesList.includes(el.label as VehicleStatusEnum));
  },
);

export const vehiclesDashboardsSearchFiltersSelector = createSelector(
  vehiclesDashboardFiltersSelector,
  vehicleManufacturersDictionarySelector,
  customersDictionaryLookupSelector,
  vehicleStatusesSelector,
  vehicleStatusesOnSiteSelector,
  vehicleStatusesOffSiteSelector,
  vehicleFlowsUiSelector,
  vehicleFlowValuesSelector,
  vehiclesDashboardAssigneesLookupSelector,
  (
    filters,
    manufacturers,
    customers,
    statuses,
    onSiteStatuses,
    offSiteStatuses,
    vehicleFlows,
    flowValues,
    assignees,
  ) => {
    const getStatuses = () => {
      const { offSite, onSite } = flowValues;
      let statusesBySelectedFlow: DictionaryItem[] = [...statuses];

      const areConditionsVerified = onSite && offSite && filters.vehicleFlows && filters.vehicleFlows.length > 0;
      if (areConditionsVerified) {
        if (filters.vehicleFlows?.includes(onSite) && !filters.vehicleFlows.includes(offSite)) {
          statusesBySelectedFlow = onSiteStatuses;
        } else if (!filters.vehicleFlows?.includes(onSite) && filters.vehicleFlows?.includes(offSite)) {
          statusesBySelectedFlow = offSiteStatuses;
        }
      }

      return filters.isActive
        ? statusesBySelectedFlow.filter((st) => !archivedVehicleStatusesList.includes(st.label as VehicleStatusEnum))
        : statusesBySelectedFlow.filter((st) => archivedVehicleStatusesList.includes(st.label as VehicleStatusEnum));
    };

    return [
      {
        label: 'Manufacturer',
        name: 'manufacturers',
        value: filters.manufacturers,
        options: manufacturers,
      },
      {
        label: 'Customer',
        name: 'customerIds',
        value: filters.customerIds,
        options: customers,
      },
      {
        label: 'Status',
        name: 'statuses',
        value: filters.statuses,
        noSearch: true,
        options: getStatuses(),
      },
      {
        label: 'Vehicle flow',
        name: 'vehicleFlows',
        value: filters.vehicleFlows,
        noSearch: true,
        options: vehicleFlows,
      },
      {
        label: 'Assigned to',
        name: 'assigneeIds',
        value: filters.assigneeIds,
        options: assignees,
      },
    ];
  },
);

export const vehiclesListInitSelector = (state: RootState) => state.vehicles.vehiclesList;
export const vehiclesListUiSelector = createSelector(
  vehiclesListInitSelector,
  vehicleFlowsUiSelector,
  vehicleStatusesSelector,
  (data, vehicleFlows, statuses) => {
    const { items, pages, totalCount } = data;
    return {
      pages,
      totalCount,
      items: items.map((el) => ({
        id: el.id,
        manufacturer: el.manufacturer || '-',
        modelDescription: el.modelDescription || '-',
        regNumber: el.regNumber || '-',
        chassisNumber: el.chassisNumber || '-',
        customerName: el.customerName || '-',
        vehicleFlow: vehicleFlows.find((flow) => el.vehicleFlow === flow.value)?.label || '-',
        status: statuses.find((st) => el.status === st.value)?.label || '',
        address: summaryFieldValueHandle([el.addressLine1, el.addressLine2, el.cityTown, el.region, el.postalCode]),
      })),
    };
  },
);

export const vehiclesListLinkVehicleTableSelector = createSelector(
  vehiclesListInitSelector,
  vehicleStatusesSelector,
  (data, statuses) => {
    const { items, pages, totalCount } = data;
    return {
      pages,
      totalCount,
      items: items?.map((el) => ({
        id: el.id,
        manufacturer: el.manufacturer || '-',
        modelDescription: el.modelDescription || '-',
        regNumber: el.regNumber || '-',
        chassisNumber: el.chassisNumber || '-',
        status: statuses.find((st) => el.status === st.value)?.label || '',
      })) || [],
    };
  },
);

export const vehicleProfilesColumnsSelector = createSelector(vehicleProfilesOrderingSelector, (ordering) => {
  return [
    {
      title: 'Code',
      dataIndex: 'code',
      sorter: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.Code, ordering),
    },
    {
      title: 'Manufacturer',
      dataIndex: 'manufacturer',
      sorter: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.Manufacturer, ordering),
    },
    {
      title: 'Model description',
      dataIndex: 'modelDescription',
      sorter: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.ModelDescription, ordering),
    },
    {
      title: 'Type',
      dataIndex: 'type',
      sorter: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.Type, ordering),
    },
    {
      title: 'Group',
      dataIndex: 'group',
      sorter: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.Group, ordering),
    },
    {
      title: 'Created date',
      dataIndex: 'createdDate',
      sorter: true,
      isDateRange: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.CreatedDate, ordering),
    },
    {
      title: 'Modified date',
      dataIndex: 'modifiedDate',
      sorter: true,
      isDateRange: true,
      orderField: getSortIndex(VehicleProfilesOrderingEnum.ModifiedDate, ordering),
    },
  ];
});
export const vehiclesProfilesSelector = (state: RootState) => state.vehicles.vehiclesProfiles;
export const vehicleProfilesTableViewSelector = createSelector(
  vehiclesProfilesSelector,
  vehicleProfilesTypesUiSelector,
  vehicleProfilesGroupsUiSelector,
  (data, types, groups) => {
    return {
      ...data,
      items: data.items.map((el) => ({
        id: el.id,
        code: el.code,
        manufacturer: el.manufacturer,
        modelDescription: el.modelDescription,
        type: types.find((type) => type.value === el.type)?.label || el.type,
        group: groups.find((group) => group.value === el.group)?.label || el.group,
        createdDate: el.createdDate ? localDateFormatHandler('DD/MM/YYYY HH:mm', el.createdDate) : '-',
        modifiedDate: el.modifiedDate ? localDateFormatHandler('DD/MM/YYYY HH:mm', el.createdDate) : '-',
      })),
    };
  },
);

export const vehiclesProfilesFiltersSelector = (state: RootState) => state.vehicles.vehicleProfilesFilters;
export const vehiclesProfilesSearchFiltersSelector = createSelector(
  vehiclesProfilesFiltersSelector,
  vehicleProfilesTypesUiSelector,
  vehicleProfilesGroupsUiSelector,
  vehicleManufacturersDictionarySelector,
  (filters, types, groups, manufacturers) => [
    {
      label: 'Manufacturer',
      name: 'manufacturer',
      value: filters.manufacturer,
      options: manufacturers,
    },
    {
      label: 'Type',
      name: 'type',
      value: filters.type,
      options: types,
    },
    {
      label: 'Group',
      name: 'group',
      value: filters.group,
      options: groups,
    },
  ],
);

const vehicleProfilesDictionaryInitSelector = (state: RootState) => state.vehicles.vehicleProfilesDictionary;
export const vehicleProfilesDictionary = createSelector(vehicleProfilesDictionaryInitSelector, (data) => {
  return data.map((el) => ({
    label: el.modelDescription,
    value: el.id,
  }));
});

export const vehiclesCustomersListSelector = (state: RootState) => state.vehicles.vehiclesCustomersList;
export const vehiclesCustomersSortedListSelector = createSelector(
  vehiclesCustomersListSelector,
  customerStatusesSelector,
  (data, statuses) => {
    const list = data.items;
    const listCopy = [...list];
    return data.totalCount > 0
      ? listCopy
        .sort((a, b) => (a.name || '').localeCompare((b.name || '')))
        .map((el) => ({
          id: el.id,
          customerStatus: statuses.find((st) => st.value === el.customerStatus)?.label,
          customerAccountNumber: el.customerAccountNumber,
          name: el.name,
        }))
      : [];
  },
);
export const createVehicleProfilesFiltersSelector = (state: RootState) => state.vehicles.createVehicleProfilesFilters;
export const createVehicleProfilesUISelector = createSelector(
  vehiclesProfilesSelector,
  createVehicleProfilesFiltersSelector,
  vehicleProfilesGroupsUiSelector,
  vehicleProfilesTypesUiSelector,
  (data, filters, groups, types) => {
    return {
      ...data,
      items: data.items.map((item, index) => ({
        id: item.id,
        number: getNo(filters.page, filters.pageSize, index),
        code: item.code,
        manufacturer: item.manufacturer,
        group: groups.find((el) => el.value === item.group)?.label || item.group,
        modelDescription: item.modelDescription,
        type: types.find((type) => type.value === item.type)?.label || item.type,
        maxPayload: item.maxPayload || 0,
        grossVehicleWeightKg: item.grossVehicleWeightKg || 0,
      })),
    };
  },
);

export const vehicleSearchProfilesColumnsSelector = createSelector(
  vehicleProfilesGroupsUiSelector,
  vehicleProfilesOrderingSelector,
  (groups, ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'ordinalNumber',
      },
      {
        title: 'Code',
        dataIndex: 'code',
        sorter: true,
        orderField: getSortIndex(VehicleProfilesOrderingEnum.Code, ordering),
      },
      {
        title: 'Manufacturer',
        dataIndex: 'manufacturer',
        sorter: true,
        orderField: getSortIndex(VehicleProfilesOrderingEnum.Manufacturer, ordering),
      },
      {
        title: 'Group',
        dataIndex: 'group',
        sorter: true,
        orderField: getSortIndex(VehicleProfilesOrderingEnum.Group, ordering),
      },
      {
        title: 'Model description',
        dataIndex: 'modelDescription',
        filterSearch: true,
      },
    ];
  },
);

export const vehicleDetailsSelector = (state: RootState) => state.vehicles.vehicleDetails;
export const vehicleAddressDetailsSelector = createSelector(vehicleDetailsSelector, (details) => {
  return {
    companyName: details?.companyName || null,
    addressLine1: details?.addressLine1 || null,
    addressLine2: details?.addressLine2 || null,
    cityTown: details?.cityTown || null,
    region: details?.region || null,
    postalCode: details?.postalCode || null,
    telephone: details?.telephone || null,
  };
});

export const vehicleDetailsHeadSelector = createSelector(
  vehicleDetailsSelector,
  (details) => {
    return [
      { label: 'Model description', value: details?.modelDescription || '-' },
      { label: 'Reg No', value: details?.regNumber || '-' },
      { label: 'Chassis No', value: details?.chassisNumber || '-' },
      { label: 'Created date', value: localDateFormatHandler('DD-MMM-YYYY', details?.creationDate || '') },
      { label: 'Modified date', value: localDateFormatHandler('DD-MMM-YYYY', details?.modifiedDate || '') },
    ];
  },
);

export const vehicleStatusTransitionsUiSelector = createSelector(
  vehicleDetailsSelector,
  vehicleStatusesSelector,
  (details, statuses) => {
    if (details?.statusTransitions) {
      const { status, statusTransitions } = details;
      const currentStatusLabel = statuses.find((el) => el.value === status)?.label || '';
      const currentStatusItem = { label: currentStatusLabel, value: status };
      const availableStatuses = statuses.filter((el) => statusTransitions.includes(el.value));
      return [...availableStatuses, currentStatusItem];
    } else {
      return [];
    }
  },
);

export const vehicleAttachmentsSelector = (state: RootState) => state.vehicles.vehicleAttachments;
export const vehicleAttachmentsFiltersSelector = (state: RootState) => state.vehicles.vehicleAttachmentsFilters;

export const vehicleChecksFiltersSelector = (state: RootState) => state.vehicles.vehicleChecksFilters;
const vehicleChecksInitSelector = (state: RootState) => state.vehicles.vehicleChecks;
export const vehicleChecksSelector = createSelector(
  vehicleChecksInitSelector,
  vehicleCheckTypesSelector,
  vehicleCheckTypesValuesSelector,
  fullVehicleCheckDecisionsSelector,
  handoverVehicleCheckDecisionsSelector,
  (
    data,
    checkTypes,
    checkTypesValues,
    fullCheckDecisions,
    handoverCheckDecisions,
  ) => {
    const { pages, totalCount, items } = data;
    const { full, handover } = checkTypesValues;
    const getCheckDecision = (checkType: number, fullCheckDecision: number | null, handoverCheckDecision: number | null): string => {
      switch (checkType) {
        case full:
          return fullCheckDecisions.find((el) => el.value === fullCheckDecision)?.label || '-';
        case handover:
          return handoverCheckDecisions.find((el) => el.value === handoverCheckDecision)?.label || '-';
        default: return '-';
      }
    };

    return {
      pages,
      totalCount,
      items: items.map((el) => ({
        id: el.id,
        vehicleCheckType: el.vehicleCheckType,
        type: checkTypes.find((type) => el.vehicleCheckType === type.value)?.label || '-',
        mileage: el.mileageMiles,
        weight: el.vehicleWeightKg ?? '-',
        checkDecision: getCheckDecision(el.vehicleCheckType, el.fullVehicleCheckDecision, el.handoverVehicleCheckDecision),
        checkDate: localDateFormatHandler('DD/MM/YYYY HH:mm', el.checkDate),
      })),
    };
  },
);
export const vehicleChecksColumnsSelector = createSelector(
  vehicleChecksOrderingSelector,
  vehicleCheckTypesSelector,
  (ordering, types) => {
    return [
      {
        title: 'Type',
        dataIndex: 'vehicleCheckTypes',
        sorter: true,
        orderField: getSortIndex(VehicleChecksOrderingEnum.VehicleCheckType, ordering),
        filters: types,
      },
      {
        title: 'Mileage',
        dataIndex: 'mileage',
      },
      {
        title: 'Weight',
        dataIndex: 'weight',
      },
      {
        title: 'Check decision',
        dataIndex: 'checkDecision',
      },
      {
        title: 'Check date',
        dataIndex: 'checkDate',
        sorter: true,
        orderField: getSortIndex(VehicleChecksOrderingEnum.CheckDate, ordering),
      },
    ];
  },
);

export const vehicleCheckDetailsSelector = (state: RootState) => state.vehicles.vehicleCheckDetails;
export const vehicleCheckSchemaUrlSelector = (state: RootState) => state.vehicles.vehicleCheckSchemaUrl;
export const vehicleCheckAttachmentsSelector = (state: RootState) => state.vehicles.vehicleCheckAttachments;

export const vehicleLinksFiltersSelector = (state: RootState) => state.vehicles.vehicleLinksFilters;
const vehicleLinksSelector = (state: RootState) => state.vehicles.vehicleLinks;
export const vehicleLinksRowSelector = createSelector(
  vehicleLinksSelector,
  linksTypesSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
  linkTypesValuesSelector,
  (
    data,
    types,
    orderStatuses,
    rectificationStatuses,
    linkTypes,
  ) => {
    const { orderLinkType } = linkTypes;
    const inheritanceArr = linksReformHandler(data);
    const statusLabel = (status: string | number, type?: number) => {
      if (type === orderLinkType) {
        return orderStatuses.find((st) => st.value === status)?.label || status;
      } else return rectificationStatuses.find((st) => st.value === status)?.label || status;
    };
    return linksUiViewHandle(inheritanceArr, statusLabel, types);
  },
);

export const vehicleTimelineFiltersSelector = (state: RootState) => state.vehicles.vehicleTimelineFilters;
export const vehicleTimelineSelector = (state: RootState) => state.vehicles.vehicleTimeline;

export const markersListSelector = (state: RootState) => state.vehicles.markersList;
