import React, { useEffect } from 'react';
import {
  Control, Controller, UseFormClearErrors, UseFormSetError, UseFormSetValue, useFormState,
} from 'react-hook-form';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import Input from '../../../../../core/components/input/Input';
import { patchPricelistById } from '../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import Select from '../../../../../core/components/select/Select';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  customerPricelistTypeSelector,
  pricelistTypesSelector, shortBusinessAreasSelector,
} from '../../../../../store/selectors/coreSelectors';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { pricelistDetailsSelector } from '../../../../../store/selectors/pricelistSelectors';
import { useCustomerPricelistLookup } from '../../../hooks/useCustomerPricelistLookup';
import { PricelistDto } from '../../../../dashboard/types/pricelistTypes';
import { usePricelistSupplierLookup } from '../../../hooks/usePricelistSupplierLookup';
import { compareValuesHandle } from '../../../../../core/utils/getDifferenceBetweenObjects';
import { clearOtherFieldsErrorFn } from '../../../../../core/utils/clearOtherFieldsErrorFn';
import { getCustomers } from '../../../../../store/thunks/customers/customersDashboardThunks';
import Textarea from '../../../../../core/components/textarea/Textarea';
import { PricelistFields, PricelistNameSchema } from '../../../../create-pricelist/utils/PricelistSchema';

type PricelistDetailsFormProps = {
  control: Control<PricelistFields>,
  clearErrors: UseFormClearErrors<PricelistFields>,
  setValue: UseFormSetValue<PricelistFields>,
  setError: UseFormSetError<PricelistFields>,
  checkIfAffected: (dataToAffect: {
    customerIdsToRemove?: number[],
    customerIds?: number[],
    businessAreasToRemove?: number[],
    businessAreas?: number[]
  }) => void,
}

const PricelistDetailsForm: React.FC<PricelistDetailsFormProps> = ({
  control,
  clearErrors,
  setValue,
  setError,
  checkIfAffected,
}) => {
  const dispatch = useAppDispatch();
  const editAllowed = usePermission(PermissionEnum.PricelistEditFields);
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const pricelistTypes = useAppSelector(pricelistTypesSelector);
  const baList = useAppSelector(shortBusinessAreasSelector);
  const customerPricelistType = useAppSelector(customerPricelistTypeSelector);

  const {
    customersLookup,
    setSearchValue,
    searchValue,
    searchError,
    setSearchError,
  } = useCustomerPricelistLookup();
  const {
    suppliersLookup,
    setSearchValue: setSuppliersSearchValue,
    searchValue: supplierSearchValue,
    setSearchError: setSupplierSearchError,
    searchError: supplierSearchError,
  } = usePricelistSupplierLookup();

  const { errors } = useFormState({ control });

  const fieldsToValidate = ['name', 'description', 'businessAreas', 'customerIds', 'supplierId'];
  const clearOtherErrorsHandle = (field: string) => {
    clearOtherFieldsErrorFn(fieldsToValidate, field, clearErrors, errors, pricelistDetails, setValue);
  };

  const patchHandle = (id: number, data: Partial<PricelistDto>) => {
    dispatch(patchPricelistById({
      id,
      data,
    }));
  };
  const patchPricelistInfo = (data: Partial<PricelistDto>, key: keyof PricelistDto, deepCompare?: boolean, isOptional?: boolean) => {
    if (pricelistDetails) {
      const handler = () => {
        patchHandle(pricelistDetails.id, data);
      };
      compareValuesHandle<PricelistDto>(data, key, pricelistDetails, handler, deepCompare, isOptional);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        dispatch(getCustomers({ params: { keyword: searchValue.trim() } }));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      dispatch(getCustomers({ params: { keyword: undefined } }));
    }
    // eslint-disable-next-line
  }, [searchValue]);
  return (
    <section className="info-grid__column">
      <h2 className="info-grid__title">Details</h2>
      <form className="details-form">
        <Controller
          name="name"
          control={control}
          rules={PricelistNameSchema}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('name');
              }}
              onBlur={(e) => {
                const v = e.target.value;
                if (!v.trim()) {
                  setValue('name', '');
                  setError('name', { type: 'required', message: ErrorsEnum.REQUIRED });
                } else {
                  if (!errors?.name) {
                    patchPricelistInfo({ name: v.trim() }, 'name');
                  }
                }
              }}
              error={errors.name?.message}
              label="Pricelist name"
              disabled={!editAllowed}
              className="details-form__field--lg"
            />
          )}
        />
        <Controller
          control={control}
          name="supplierId"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value as number}
              onChange={(val) => {
                field.onChange(val);
                clearOtherErrorsHandle('supplierId');
                pricelistDetails && patchHandle(pricelistDetails?.id, { supplierId: Number(val) });
              }}
              label="Supplier"
              options={suppliersLookup}
              className="details-form__field--lg"
              error={errors.supplierId?.message || supplierSearchError}
              onSelect={() => setSuppliersSearchValue('')}
              showSearch
              searchValue={supplierSearchValue}
              onSearch={(keyword: string) => {
                if (keyword.length <= 250) {
                  setSupplierSearchError('');
                  setSuppliersSearchValue(keyword);
                } else {
                  setSuppliersSearchValue(keyword.substring(0, 250));
                }
              }}
              disabled={!pricelistDetails?.enableSupplierEditing || !editAllowed}
            />
          )}
        />
        <Controller
          name="businessAreas"
          control={control}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('businessAreas');
              }}
              onBlur={() => {
                if (!errors.businessAreas && field.value.length > 0) {
                  const businessAreasToRemove = pricelistDetails?.businessAreas?.filter((area) => !field.value.includes(area));
                  if (businessAreasToRemove?.length === 0) {
                    patchPricelistInfo({ businessAreas: field.value }, 'businessAreas', true);
                  } else {
                    checkIfAffected({ businessAreasToRemove, businessAreas: field.value });
                  }
                }
              }}
              onDeselect={() => {
                if (field.value.length === 1) {
                  setError('businessAreas', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              mode="multiple"
              options={baList}
              error={errors.businessAreas && ErrorsEnum.REQUIRED}
              disabled={!editAllowed}
              label="Pricelist business areas"
              className="details-form__field--lg"
              showArrow
            />
          )}
        />
        <Controller
          control={control}
          name="type"
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value as number}
              onChange={(val) => field.onChange(val)}
              label="Pricelist type"
              options={pricelistTypes}
              disabled
              className="details-form__field--lg"
              error={errors.type?.message}
            />
          )}
        />
        {pricelistDetails?.type === customerPricelistType?.value && <Controller
          name="customerIds"
          control={control}
          rules={{ required: pricelistDetails?.type === customerPricelistType?.value && ErrorsEnum.REQUIRED }}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(v) => {
                field.onChange(v);
                clearOtherErrorsHandle('customerIds');
              }}
              onBlur={() => {
                if (!errors.customerIds && field.value.length > 0) {
                  const customerIdsToRemove = pricelistDetails?.customerIds?.filter((cust) => !field.value.includes(cust));
                  if (customerIdsToRemove?.length === 0) {
                    patchPricelistInfo({ customerIds: field.value }, 'customerIds', true);
                  } else {
                    checkIfAffected({ customerIdsToRemove, customerIds: field.value });
                  }
                  setSearchValue(undefined);
                  searchError && setSearchError('');
                }
              }}
              onSearch={(val) => {
                if (val.trim()) {
                  if (val.length <= 250) {
                    setSearchValue(val);
                  } else {
                    setSearchError(maxLengthMessageHandle(250));
                  }
                } else {
                  setSearchValue(undefined);
                }
              }}
              onSelect={() => {
                setSearchValue(undefined);
                searchError && setSearchError('');
              }}
              onDeselect={(v) => {
                const newIds = pricelistDetails?.customerIds?.filter((cust) => cust !== v);
                if (newIds?.length === 0) {
                  setError('customerIds', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              showSearch
              options={customersLookup}
              error={(errors.customerIds && ErrorsEnum.REQUIRED) || searchError}
              label="Customers"
              disabled={!editAllowed}
              mode="multiple"
              className="details-form__field--lg"
              showArrow
            />
          )}
        />}
        <Controller
          name="description"
          control={control}
          rules={{ maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } }}
          render={({ field }) => (
            <Textarea
              value={field.value || ''}
              onChange={(e) => {
                field.onChange(e.target.value);
                clearOtherErrorsHandle('description');
              }}
              onBlur={() => !errors?.description && patchPricelistInfo(
                { description: field.value?.trim() || '' },
                'description',
                false,
                true,
              )}
              label="Description"
              disabled={!editAllowed}
              error={errors?.description?.message}
              className="details-form__field--lg"
            />
          )}
        />
      </form>
    </section>
  );
};

export default PricelistDetailsForm;
