import React from 'react';
import ChartComponent, { Props } from 'react-apexcharts';
import './Chart.scss';
import { ApexOptions } from 'apexcharts';

type ChartProps = Props & {
  reportIsVisible: boolean,
}

const Chart: React.FC<ChartProps> = ({
  options,
  reportIsVisible,
  ...props
}) => {
  const chartOptions: ApexOptions = {
    ...options,
    noData: {
      text: `${reportIsVisible ? 'No data' : 'Please run a report'}`,
      offsetX: 0,
      offsetY: -70,
      style: {
        color: '#3A4557',
        fontSize: '18px',
        fontFamily: '"Montserrat", "Arial", sans-serif',
      },
    },
  };

  return (
    <div className="Chart">
      <ChartComponent {...props} options={chartOptions} />
    </div>
  );
};

export default Chart;
