import React, { useState } from 'react';
import Tr from '../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import { generatePurchaseOrderColumnsSelector } from '../../../../../store/selectors/stockPartRequestsSelector';
import { useAppSelector } from '../../../../../store/hooks';
import Checkbox from '../../../../../core/components/checkbox/Checkbox';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import { SearchTableFilterType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import { PartReqLinesFilters } from '../../../../../common/types/commonTypes';

type GeneratePurchaseOrderColumnsProps = {
  filters: PartReqLinesFilters,
  filtersHandle: (filters: PartReqLinesFilters) => void,
  totalCount: number,
  isAll: boolean,
  isIndeterminate: boolean,
  totalElements: number,
  checkAll: VoidFunctionType,
}

const GeneratePurchaseOrderColumns: React.FC<GeneratePurchaseOrderColumnsProps> = ({
  filters, filtersHandle, totalCount, isAll, isIndeterminate, totalElements, checkAll,
}) => {
  const generatePurchaseOrderColumns = useAppSelector(generatePurchaseOrderColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: undefined,
    productName: undefined,
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      filtersHandle({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      });
    } else {
      filtersHandle({
        ...filters,
        order: { field, isAsc: true },
      });
    }
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      const values = {
        ...filters,
        [field]: undefined,
      };
      filtersHandle(values);
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    const values = { ...filters, [field]: searchVl[field]?.trim() };
    filtersHandle(values);
  };
  return (
    <Tr>
      <ThNew checkbox>
        <Checkbox checked={isAll} indeterminate={isIndeterminate} onChange={checkAll} disabled={totalElements === 0} table />
      </ThNew>
      {generatePurchaseOrderColumns.map((col) => <ThWithControls
        key={col.dataIndex}
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={totalCount}
        disabled={totalCount === 0}
        searchVl={searchVl}
        className={`generatePurchaseOrderTh--${col.dataIndex}`}
        tableFilters={filters}
        insideModal
      />)}
    </Tr>
  );
};

export default GeneratePurchaseOrderColumns;
