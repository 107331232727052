import { CollectionColumnPaginationType, GenericColumnType } from '../../../core/types/coreTypes';
import { OrderDto, PagedOrderCollectionDto } from '../types/ordersTypes';

export const orderStatusTransitionCollectionsHelper = (
  prevCollections: Array<GenericColumnType<Array<OrderDto>>>,
  responseCollections: PagedOrderCollectionDto[],
  oldPagination: Array<CollectionColumnPaginationType>,
  statusTransition: number,
  sourceId: number,
) => {
  const currentOld = responseCollections.find((el) => el.status === sourceId);
  const currentNew = responseCollections.find((el) => el.status === statusTransition);
  const newPagination = oldPagination.map((p) => {
    if (p.status === currentOld?.status) {
      return { ...p, pages: currentOld.pages, currentPage: 1 };
    }
    if (p.status === currentNew?.status) {
      return { ...p, pages: currentNew?.pages, currentPage: 1 };
    } else return p;
  });
  const newOrderCollections = prevCollections.map((col) => {
    if (col.status === currentNew?.status) {
      const { orders, ...rest } = currentNew;
      return { ...rest, list: orders };
    }
    if (col.status === currentOld?.status) {
      const { orders, ...rest } = currentOld;
      return { ...rest, list: orders };
    } else return col;
  });
  return { newPagination, newOrderCollections };
};
