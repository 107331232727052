export enum PartsKitComponentsOrders {
  Category = 'Category',
  PartName = 'PartName',
  PartNumber = 'PartNumber',
  Quantity = 'Quantity',
  UnitPrice = 'UnitPrice',
  NetPrice = 'NetPrice',
  Tax = 'Tax',
  Weight = 'Weight',
}
