import React from 'react';
import Tbody from '../../../../core/components/table-components/Tbody';
import { PartResultType } from '../../types/stockSearchTypes';
import SearchTableRow from './SearchTableRow';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';

type StockSearchBodyProps = {
  stockSearchResult: {items: PartResultType[] | undefined, pages: number, totalCount: number},
}

const StockSearchBody: React.FC<StockSearchBodyProps> = ({ stockSearchResult }) => {
  const { items } = stockSearchResult;
  return (
    <Tbody>
      {(!items || items.length === 0)
        ? <EmptyTableSection
          text={items && items.length === 0 ? 'No records' : 'Please enter a search parameter'}
          colSpan={8}
          size="lg"
        />
        : <>
          {items.map((item) => <SearchTableRow key={item.id} data={item} />)}
        </>}
    </Tbody>
  );
};

export default StockSearchBody;
