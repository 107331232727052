import React from 'react';
import classNames from 'classnames';
import isNil from 'lodash/isNil';
import { Tooltip } from 'antd';
import Tag from '../../../../core/components/tag/Tag';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../store/hooks';
import {
  dayPartAllDaySelector,
  dayPartsUiSelector,
  leaveRequestTypesUiSelector,
  leaveRequestTypesUiShortSelector,
} from '../../../../store/selectors/coreSelectors';

type WorkingHoursCardProps = {
  totalWorkingHours: number | null,
  scheduledWorkingHours: number | null,
  nonWorkingHours: number | null,
  leaveRequest: number | null,
  dayPart: number | null,
  onClick: VoidFunctionType,
}
const WorkingHoursCard: React.FC<WorkingHoursCardProps> = ({
  totalWorkingHours, scheduledWorkingHours, nonWorkingHours, leaveRequest, dayPart, onClick,
}) => {
  const leaveRequestsTypes = useAppSelector(leaveRequestTypesUiSelector);
  const leaveRequestsShortTypes = useAppSelector(leaveRequestTypesUiShortSelector);
  const dayParts = useAppSelector(dayPartsUiSelector);
  const allDayValue = useAppSelector(dayPartAllDaySelector);

  const na = isNil(scheduledWorkingHours) && isNil(nonWorkingHours) && isNil(totalWorkingHours);
  const dark = !isNil(scheduledWorkingHours) && !isNil(nonWorkingHours) && (totalWorkingHours === 0);
  const striped = !isNil(scheduledWorkingHours)
    && !isNil(nonWorkingHours)
    && (nonWorkingHours > 0)
    && (scheduledWorkingHours > nonWorkingHours);

  const leaveRequestString = leaveRequestsTypes.find((type) => type.value === leaveRequest)?.label || '';
  const shortLeaveRequestString = leaveRequestsShortTypes.find((type) => type.value === leaveRequest)?.label || '';
  const dayPartString = (dayPart && dayPart !== allDayValue && `(${dayParts.find((part) => part.value === dayPart)?.label})`) || '';

  return (
    <button
      type="button"
      onClick={(e) => {
        if (e.target instanceof Element && !e.target.classList.contains('ant-tooltip-inner')) {
          onClick();
        }
      }}
      className={classNames(
        'workingHoursCard',
        {
          'workingHoursCard--na': na,
          'workingHoursCard--striped': striped,
          'workingHoursCard--dark': dark,
        },
      )}
    >
      {leaveRequest && (
        <Tag
          label={<Tooltip
            title={`${leaveRequestString} ${dayPartString}`}
            overlayClassName="workingHoursCard__tooltip"
          >
            {`${shortLeaveRequestString} ${dayPartString}`}
          </Tooltip>}
          className="workingHoursCard__tag"
        />)}
      {na ? 'N/A' : `${totalWorkingHours} h`}
    </button>
  );
};

export default WorkingHoursCard;
