import React from 'react';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { fieldNameEventHandle } from '../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../store/hooks';
import { vehicleFlowsUiSelector } from '../../../../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../../../../core/utils/labelHandle';

type VehicleFieldUpdateEventProps = {
  field?: string,
  oldValue?: string | number,
  newValue?: string | number,
}

const VehicleFieldUpdateEvent: React.FC<VehicleFieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const vehicleFlowTypes = useAppSelector(vehicleFlowsUiSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'VehicleFlow':
        return labelHandle(+value, vehicleFlowTypes);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default VehicleFieldUpdateEvent;
