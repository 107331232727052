const AUTH_TOKEN_KEY = 'authToken';

export const storage = {
  getAuthToken: () => {
    return localStorage.getItem(AUTH_TOKEN_KEY);
  },
  addAuthToken: (token: string) => {
    localStorage.setItem(AUTH_TOKEN_KEY, token);
  },
  removeAuthToken: () => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
  },
};
