import React, { useEffect, useRef, useState } from 'react';
import {
  Controller, FieldErrors, useForm, useFormState,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Select from '../../../core/components/select/Select';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { useFiltersItems } from '../../enquiries/hooks/useFiltersItems';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import Button from '../../../core/components/button/Button';
import RequisiteModal from '../../../common/components/requisite/RequisteModal';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { CustomerDto } from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import CustomerFields from './СustomerFields';
import {
  currentBusinessAreaSelector,
  isFetchingSelector,
  jobTypesSelector,
  usersFilterLookupSelector,
} from '../../../store/selectors/coreSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setCurrentArea, setErrorMessage } from '../../../store/slices/coreSlice';
import { ConsumerTypeEnum } from '../enums/createEnquiryEnum';
import { createEnquiryThunk, createEnquiryWithoutCustomerThunk } from '../../../store/thunks/enquiry/createEnquiryThunk';
import { initRequisite, RequisiteFields } from '../../../common/utils/data';
import RequisiteSection from '../../../common/components/requisite/RequisiteSection';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import Restricted from '../../../permissions-handling/Restricted';
import { JobTypeUiNamesEnum, PermissionEnum } from '../../../core/enums/dictionariesEnums';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../core/utils/regex';
import { getCustomerById } from '../../../store/thunks/customers/customersViewPageThunks';
import { setCustomerDetails } from '../../../store/slices/customersSlice';
import { customerDetailsRequisitesSetSelector } from '../../../store/selectors/customersSelectors';
import Textarea from '../../../core/components/textarea/Textarea';
import { getDefaultEnquiryJobLocation } from '../../../store/thunks/enquiry/detailsThunks';
import AddressModal from '../../../common/components/address/AddressModal';
import { AddressFields, initAddressFields, RequisiteDto } from '../../../common/types/commonTypes';
import AddressField from '../../../common/components/address/AddressField';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import { CreateEnquiryDefaults, CreateEnquiryFields, EnquiryDescriptionSchema } from '../utils/CreateEnquirySchema';
import { useCreateEnquiry } from '../utils/createEnquiryFinalDataHelper';
import { useUsersDictionary } from '../../../common/hooks/useUsersDictionary';

type CreateEnquiryFormProps = {
  customers: CustomerDto[],
  setKeyword: (value?: string) => void,
  keyword?: string,
}

const CreateEnquiryForm: React.FC<CreateEnquiryFormProps> = ({
  customers, setKeyword, keyword,
}) => {
  const currentBusinessArea = useAppSelector(currentBusinessAreaSelector);
  const {
    billing, shipping, isShippingSame, alwaysUseRequisites, customerId,
  } = useAppSelector(customerDetailsRequisitesSetSelector);
  const enquiryAssignees = useAppSelector(usersFilterLookupSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [customerVal, setCustomerVal] = useState(ConsumerTypeEnum.EXISTED);
  const [locationModal, setLocationModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);
  const [isDefaultAddressForOnSite, setDefaultAddressForOnSite] = useState(false);
  const isFetchingCreate = useAppSelector(isFetchingSelector);
  const jobTypes = useAppSelector(jobTypesSelector);

  useEffect(() => {
    if (currentUser && !currentUser.userContextRole.permissions.includes(PermissionEnum.EnquiryCreateCustomerEnquiry)) {
      setCustomerVal(ConsumerTypeEnum.EXISTED);
    }
  }, [currentUser]);

  const { setUserSearch, getUsersFn } = useUsersDictionary();

  const [requisiteModal, setRequisiteModal] = useState(false);
  const [requisiteFields, setRequisiteFields] = useState<RequisiteFields>({
    billing: initRequisite,
    shipping: initRequisite,
  });
  const [isManual, setIsManual] = useState({
    billing: false,
    shipping: false,
  });
  const [requisiteCustomerId, setRequisiteCustomerId] = useState<{ billing?: number, shipping?: number }>({
    billing: undefined,
    shipping: undefined,
  });
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [isSame, setIsSame] = useState(true);
  const sameRef = useRef(false);

  const {
    enquirySources, priorityLevels, allowedJobTypesForUser, customerTypes, allowedBaForUser,
  } = useFiltersItems();
  const {
    handleSubmit, control, setValue, reset, watch, setError,
  } = useForm<CreateEnquiryFields>({
    defaultValues: CreateEnquiryDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const customerName = watch('name');

  useEffect(() => {
    if (currentUser) {
      setValue('assignedTo', currentUser.id);
    }
    // eslint-disable-next-line
  }, [currentUser]);

  useEffect(() => {
    if (customerName && customerVal === ConsumerTypeEnum.EXISTED) {
      dispatch(getCustomerById({ id: customerName as unknown as number }));
    }
    // eslint-disable-next-line
  }, [customerName, customerVal]);

  useEffect(() => {
    setRequisiteFields({ billing, shipping });
    setIsSame(isShippingSame);
  }, [billing, shipping, isShippingSame]);

  const clearFields = (val: ConsumerTypeEnum) => {
    setCustomerVal(val);
    reset();
    setValue('assignedTo', currentUser?.id);
    setAddressFields(initAddressFields);
    setRequisiteFields({ billing: initRequisite, shipping: initRequisite });
    setIsManual({ billing: false, shipping: false });
    setRequisiteCustomerId({ billing: undefined, shipping: undefined });
    setIsSame(true);
    sameRef.current = false;
    dispatch(setCustomerDetails(null));
  };

  const submitRequisiteModalHandle = (values: RequisiteDto, isManualInput: boolean, selectedCustomerId: number | undefined) => {
    const field = isShippingMode ? 'shipping' : 'billing';
    setIsManual({ ...isManual, [field]: isManualInput });
    setRequisiteCustomerId({ ...requisiteCustomerId, [field]: selectedCustomerId });
    const data = { ...requisiteFields, [field]: { ...values } };
    setRequisiteFields(data);
    setRequisiteModal(false);
    setIsShippingMode(false);
    if (customerVal === ConsumerTypeEnum.NEW && isShippingMode) {
      const shippingValues = Object.values(values);
      shippingValues.every((v) => !v) && setIsSame(true);
    }
    sameRef.current = false;
  };

  const { createEnquiryFinalDataHelper } = useCreateEnquiry();

  const onSubmit = (values: CreateEnquiryFields) => {
    const { finalNewData, finalExistingData } = createEnquiryFinalDataHelper(
      values,
      customerVal,
      isSame,
      addressFields,
      requisiteFields,
      requisiteCustomerId,
      isManual,
    );
    if (customerVal === ConsumerTypeEnum.NEW) {
      dispatch(createEnquiryThunk({
        data: finalNewData,
        navigate,
        setError,
        clearForm: () => clearFields(ConsumerTypeEnum.EXISTED),
      }));
    } else {
      dispatch(createEnquiryWithoutCustomerThunk({
        data: finalExistingData,
        navigate,
        clearForm: () => clearFields(ConsumerTypeEnum.EXISTED),
      }));
    }
  };

  const existingDisabledCondition = customerVal === ConsumerTypeEnum.EXISTED && !customerName;
  return (
    <>
      <AddressModal
        isVisible={locationModal}
        onCancel={() => setLocationModal(false)}
        title="Job location"
        isOptionalFields
        addressFields={addressFields}
        onSubmit={(values) => {
          setAddressFields(values);
          setLocationModal(false);
        }}
        isDefaultOptionAvailable
        setDefaultAddressFields={() => dispatch(getDefaultEnquiryJobLocation({
          setAddressFields,
        }))}
        isExtendedFields
      />
      <ConfirmationModal
        isVisible={isDefaultAddressForOnSite}
        onCancel={() => {
          setDefaultAddressForOnSite(false);
        }}
        confirmHandler={() => {
          dispatch(getDefaultEnquiryJobLocation({
            setAddressFields,
          }));
          setDefaultAddressForOnSite(false);
        }}
        customCreateLabel="Yes, set location"
        customTitle="Job location"
        customText="The following default location can be used for on-site enquiries:
            Vanliners LTD, Unit 3, Ormside Close, Hindley Green, Wigan, England, WN2 4HR"
        customProceedText="Do you want to set this address as a job location?"
      />
      <RequisiteModal
        isVisible={requisiteModal}
        onCancel={() => {
          setRequisiteModal(false);
          setIsShippingMode(false);
          if (sameRef.current) {
            setIsSame(true);
            sameRef.current = false;
          }
          if (customerVal === ConsumerTypeEnum.NEW) {
            const shippingValues = Object.values(requisiteFields.shipping);
            shippingValues.every((v) => !v) && setIsSame(true);
          }
        }}
        submitHandle={submitRequisiteModalHandle}
        isOptionalFields
        requisite={isShippingMode ? requisiteFields.shipping : requisiteFields.billing}
        isShipping={isShippingMode}
        isManual={isShippingMode ? isManual.shipping : isManual.billing}
        entitySourceType={customerVal === ConsumerTypeEnum.EXISTED ? 'customer' : undefined}
        entityId={(isShippingMode ? requisiteCustomerId.shipping : requisiteCustomerId.billing)
          || customerId}
      />
      <form onSubmit={handleSubmit(onSubmit)} className="enquiryForm">
        <span className="enquiryForm__customerLabel">Customer</span>
        <div>
          <Button
            onClick={() => customerVal !== ConsumerTypeEnum.EXISTED && clearFields(ConsumerTypeEnum.EXISTED)}
            label="Existing"
            htmlType="button"
            designType="light-inverse"
            isActivated={customerVal === ConsumerTypeEnum.EXISTED}
            offsetRight
          />
          <Restricted to={PermissionEnum.EnquiryCreateCustomerEnquiry}>
            <Button
              onClick={() => customerVal !== ConsumerTypeEnum.NEW && clearFields(ConsumerTypeEnum.NEW)}
              label="New"
              htmlType="button"
              designType="light-inverse"
              isActivated={customerVal === ConsumerTypeEnum.NEW}
            />
          </Restricted>
        </div>
        <div className="create-form details-form">
          <Select
            value={currentBusinessArea}
            onChange={(value) => dispatch(setCurrentArea(value as number))}
            options={allowedBaForUser}
            label="Business area"
            className="details-form__field--lg"
          />
          <CustomerFields
            control={control}
            errors={errors}
            customers={customers}
            customerTypes={customerTypes}
            customerVal={customerVal}
            watch={watch}
            setValue={setValue}
            setKeyword={setKeyword}
            keyword={keyword}
            clearFields={() => {
              reset();
              setKeyword('');
              setAddressFields(initAddressFields);
              setRequisiteFields({ billing: initRequisite, shipping: initRequisite });
              setRequisiteCustomerId({ billing: undefined, shipping: undefined });
            }}
            setRequisiteFields={setRequisiteFields}
            setRequisiteCustomerId={setRequisiteCustomerId}
          />
          <Controller
            name="enquirySource"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={enquirySources}
                error={errors.enquirySource?.message}
                label="Enquiry source"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <Controller
            name="priorityLevel"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                options={priorityLevels}
                error={errors.priorityLevel?.message}
                label="Priority level"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <Controller
            name="estimatedCost"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <DecimalInputNumber
                value={field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                max={maxCommonDecimal}
                error={errors.estimatedCost?.message}
                label="Estimated cost"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <Controller
            name="jobType"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={(v) => {
                  const currentValueLabel = jobTypes?.find((el) => el.value === v)?.label;
                  if (currentValueLabel === JobTypeUiNamesEnum.OnSite) {
                    setDefaultAddressForOnSite(true);
                  }
                  field.onChange(v);
                }}
                options={allowedJobTypesForUser}
                error={errors.jobType?.message}
                label="Job type"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <AddressField
            addressFields={addressFields}
            setAddressModal={() => setLocationModal(true)}
            title="Job Location"
            disabled={existingDisabledCondition}
          />
          <Controller
            name="assignedTo"
            control={control}
            rules={RequiredFieldSchema}
            render={({ field }) => (
              <Select
                value={field.value}
                onChange={field.onChange}
                onSearch={(keyword: string) => {
                  if (keyword.length <= 250) {
                    setUserSearch(keyword.trim());
                  } else {
                    dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                  }
                }}
                onSelect={() => {
                  setUserSearch('');
                  getUsersFn(undefined);
                }}
                options={enquiryAssignees}
                showSearch
                error={(errors.assignedTo as FieldErrors)?.message}
                label="Assigned to"
                className="details-form__field--lg"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <Controller
            name="description"
            control={control}
            rules={EnquiryDescriptionSchema}
            render={({ field }) => (
              <Textarea
                value={field.value || ''}
                onChange={field.onChange}
                error={errors?.description?.message}
                label="Description"
                className="details-form__field--lg"
                disabled={existingDisabledCondition}
              />
            )}
          />
          <RequisiteSection
            requisiteDetails={requisiteFields.billing}
            setIsVisible={() => !alwaysUseRequisites && setRequisiteModal(true)}
            title="Billing details"
            disabled={existingDisabledCondition || alwaysUseRequisites}
          />
          <RequisiteSection
            requisiteDetails={requisiteFields.shipping}
            setIsVisible={() => {
              if (!alwaysUseRequisites && !existingDisabledCondition && !isSame) {
                setRequisiteModal(true);
                setIsShippingMode(true);
              }
            }}
            isShipping
            title="Shipping details"
            onCheck={(val) => {
              if (val) {
                setIsShippingMode(false);
                setRequisiteModal(false);
                setRequisiteFields({ ...requisiteFields, shipping: initRequisite });
                setRequisiteCustomerId({ ...requisiteCustomerId, shipping: undefined });
                setIsManual({ ...isManual, shipping: false });
                sameRef.current = true;
              } else {
                setIsShippingMode(true);
                setRequisiteModal(true);
                sameRef.current = true;
                customerVal === ConsumerTypeEnum.EXISTED && setRequisiteCustomerId({ ...requisiteCustomerId, shipping: undefined });
              }
              setIsSame(val);
            }}
            isSame={isSame}
            disabled={alwaysUseRequisites || existingDisabledCondition}
          />
          <ButtonActions
            createLabel="Create"
            cancelLabel="Clear"
            createType="submit"
            cancelClick={() => clearFields(ConsumerTypeEnum.EXISTED)}
            disabledCancel={existingDisabledCondition}
            disabledCreate={existingDisabledCondition}
            className="details-form__actions"
            isLoading={isFetchingCreate}
          />
        </div>
      </form>
    </>
  );
};

export default CreateEnquiryForm;
