import React, { useEffect } from 'react';
import {
  useNavigate, useParams, useSearchParams,
} from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setPricelistAffectedFilters, setPricelistAffectedItems } from '../../store/slices/pricelistSlice';
import {
  pricelistDetailsSelector, pricesFiltersSelector,
} from '../../store/selectors/pricelistSelectors';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import PricelistHead from './components/pricelist-head/PricelistHead';
import {
  deletePricelistThunk,
  getPricelistById, getPricesPartsThunk,
} from '../../store/thunks/pricelist/pricelistDetailsThunks';
import { currentUserSelector } from '../../store/selectors/accountSelectors';
import PricelistContentTabs from './components/pricelist-content-tabs/PricelistContentTabs';
import PricelistContent from './components/pricelist-content/PricelistContent';
import { PricelistModeEnum, PricelistModes } from './enums/PricelistModeEnum';
import DuplicatePricelistModal from './components/pricelist-modals/DuplicatePricelistModal';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import SimpleInactivationModal from './components/pricelist-modals/SimpleInactivationModal';
import { usePricelistActions } from './hooks/usePricelistActions';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { usePricelistDetailsTabsAndActions } from './hooks/usePricelistDetailsTabsAndActions';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { initDataList, initSmallPaging } from '../../core/types/coreTypes';
import PricelistAffectedEntitiesModal from './components/pricelist-content/modals/affected/PricelistAffectedEntitiesModal';
import PricelistAffectedPartsModal from './components/pricelist-content/modals/affected/PricelistAffectedPartsModal';

const PricelistViewPageContainer: React.FC = () => {
  const { id } = useParams();
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { backHandle } = useLocationHistoryState(true);
  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const pricelistDetails = useAppSelector(pricelistDetailsSelector);
  const pricesFilters = useAppSelector(pricesFiltersSelector);

  const {
    isDuplicate,
    setDuplicate,
    isAffected,
    setAffected,
    isAffectedParts,
    setAffectedParts,
    isConfirm,
    setConfirm,
    isSimpleInactivate,
    setSimpleInactivate,
    getPricelistDictionaries,
    unmountHandle,
  } = usePricelistActions();

  const {
    options, actions,
  } = usePricelistDetailsTabsAndActions(
    setAffected,
    setConfirm,
    setSimpleInactivate,
    setDuplicate,
  );

  useEffect(() => {
    id && dispatch(getPricelistById({ id: +id }));
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    id && dispatch(getPricesPartsThunk({ id: +id, filters: pricesFilters }));
    // eslint-disable-next-line
  }, [id]);

  const setModeParam = (mode: string) => {
    setSearchParams({ mode }, { replace: true });
  };

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      const modeParam = params.get('mode');
      if (!modeParam && permissions.includes(PermissionEnum.PricelistsViewPage)) {
        setSearchParams({ mode: options[0].value }, { replace: true });
      } else {
        if (!PricelistModes.includes(modeParam as PricelistModeEnum)) {
          setSearchParams({ mode: PricelistModeEnum.PRICELIST_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, pricelistDetails]);

  useEffect(() => {
    getPricelistDictionaries();
    return () => unmountHandle();
    // eslint-disable-next-line
  }, []);

  const clearAffectedState = () => {
    dispatch(setPricelistAffectedFilters(initSmallPaging));
    dispatch(setPricelistAffectedItems(initDataList));
  };

  return (
    <Layout
      subHeader={<DetailsHead
        goBack={backHandle}
        actions={actions}
      >
        <PricelistHead details={pricelistDetails} />
      </DetailsHead>}
    >
      <ConfirmationModal
        isVisible={isConfirm}
        onCancel={() => {
          setConfirm(false);
          clearAffectedState();
        }}
        isDelete
        confirmHandler={() => pricelistDetails?.id && dispatch(deletePricelistThunk({
          id: pricelistDetails?.id,
          closeModal: () => {
            setConfirm(false);
            clearAffectedState();
          },
          navigate,
        }))}
      />
      <SimpleInactivationModal
        isVisible={isSimpleInactivate}
        onCancel={() => {
          setSimpleInactivate(false);
          clearAffectedState();
        }}
        id={pricelistDetails?.id}
        name={pricelistDetails?.name || ''}
      />
      <DuplicatePricelistModal id={pricelistDetails?.id} isVisible={isDuplicate} onCancel={() => setDuplicate(false)} />
      <PricelistAffectedPartsModal
        visible={isAffectedParts}
        onClose={() => setAffectedParts(false)}
      />
      <PricelistAffectedEntitiesModal
        visible={isAffected}
        onClose={() => setAffected(false)}
        pricelistName={pricelistDetails?.name || ''}
      />
      <PricelistContentTabs
        setModeParam={setModeParam}
        mode={params.get('mode')}
        allowedTabs={options}
      />
      <PricelistContent
        mode={params.get('mode')}
        setAffected={setAffected}
        setAffectedParts={setAffectedParts}
      />
    </Layout>
  );
};

export default PricelistViewPageContainer;
