import React from 'react';
import { faUsers } from '@fortawesome/free-solid-svg-icons';
import EmployeeItem from './EmployeeItem';
import { employeeDashboardUiListSelector } from '../../../store/selectors/employeesSelector';
import { useAppSelector } from '../../../store/hooks';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';
import SkeletonLinesList from '../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import EmployeesSkeletonItem from './employees-skeleton/EmployeesSkeletonItem';
import { isDashboardLoadingSelector } from '../../../store/selectors/coreSelectors';

const EmployeesList: React.FC = () => {
  const employeesDashboard = useAppSelector(employeeDashboardUiListSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    isLoading
      ? <SkeletonLinesList>
        <EmployeesSkeletonItem />
      </SkeletonLinesList>
      : <ul className="lines-list">
        {employeesDashboard.totalCount > 0
          ? employeesDashboard.items.map((employee) => (
            <li key={employee.id} className="lines-list__item">
              <EmployeeItem employee={employee} />
            </li>
          ))
          : <EmptyContentList text="No records" icon={faUsers} />}
      </ul>
  );
};

export default EmployeesList;
