import React, { useState } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  BooleanFunctionType,
  DateRangeTableFilterType,
  NumberFunctionType,
  PickerValues,
  SearchTableFilterType,
} from '../../core/types/coreTypes';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { formsCurrentFolderSelector, formsTableFiltersSelector } from '../../store/selectors/formsSelectors';
import { setAttachmentsTableFilters } from '../../store/slices/enquiriesSlice';
import { setFormsTableFilters } from '../../store/slices/formsSlice';
import AttachmentColumns from '../../common/components/attachments-table/AttachmentColumns';
import usePermission from '../../permissions-handling/permissionHook';
import { DEFAULT_DATE_FORMAT } from '../../core/utils/regex';

type FormsColumnsProps = {
  isAll: boolean;
  checkAll: BooleanFunctionType;
  totalElements: number;
  openEditFolderModal: NumberFunctionType;
  setDeleteFolderModal: NumberFunctionType;
}

const FormsColumns: React.FC<FormsColumnsProps> = ({
  totalElements, isAll, checkAll, openEditFolderModal, setDeleteFolderModal,
}) => {
  const allowedManage = usePermission(PermissionEnum.TestModule);
  const dispatch = useAppDispatch();
  const formsTableFilters = useAppSelector(formsTableFiltersSelector);
  const formsCurrentFolder = useAppSelector(formsCurrentFolderSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({ name: formsTableFilters.name, comment: formsTableFilters.comment });
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    uploadDate: [
      formsTableFilters.uploadDate?.min ? moment(formsTableFilters.uploadDate.min, DEFAULT_DATE_FORMAT) : null,
      formsTableFilters.uploadDate?.max ? moment(formsTableFilters.uploadDate.max, DEFAULT_DATE_FORMAT) : null,
    ],
  });

  const columnActions = [
    {
      key: 'renameFolder',
      label: 'Rename folder',
      icon: <FontAwesomeIcon icon={faPencil} />,
      onClick: () => formsCurrentFolder && openEditFolderModal(formsCurrentFolder),
    },
    {
      key: 'deleteFolder',
      label: 'Delete folder',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => formsCurrentFolder && setDeleteFolderModal(formsCurrentFolder),
    },
  ];

  const sortHandle = (field: number) => {
    if (formsTableFilters.order?.field === field) {
      dispatch(setFormsTableFilters({
        ...formsTableFilters,
        order: { ...formsTableFilters.order, isAsc: !formsTableFilters.order.isAsc },
      }));
    } else {
      dispatch(setFormsTableFilters({
        ...formsTableFilters,
        order: { field, isAsc: true },
      }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setFormsTableFilters({
        ...formsTableFilters,
        [field]: '',
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setFormsTableFilters({
      ...formsTableFilters,
      // eslint-disable-next-line
      // @ts-ignore
      [field]: searchVl[field].trim(),
    }));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters = {
      ...formsTableFilters,
      [field]: values || undefined,
    };
    dispatch(setAttachmentsTableFilters(newFilters));
  };
  return (
    <AttachmentColumns
      allowedManage={!!allowedManage}
      isAll={isAll}
      checkAll={checkAll}
      sortHandle={sortHandle}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      totalElements={totalElements}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={formsTableFilters}
      extraActions={columnActions}
    />
  );
};

export default FormsColumns;
