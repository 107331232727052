import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../../../core/utils/errorMessageHandle';
import Input from '../../../../../../../core/components/input/Input';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import DecimalInputNumber from '../../../../../../../core/components/input-number/DecimalInputNumber';
import { maxCommonDecimal } from '../../../../../../../core/utils/regex';
import Select from '../../../../../../../core/components/select/Select';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import { partCategoriesLookupSelector } from '../../../../../../../store/selectors/sharedSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  gMeasureSelector,
  integerQuantityMeasureValuesSelector,
  QuoteUnitsOfMeasureSelector, timeMeasureValuesSelector, weightMeasureValuesSelector,
} from '../../../../../../../store/selectors/coreSelectors';
import RegularInputNumber from '../../../../../../../core/components/input-number/RegularInputNumber';
import { setErrorMessage, setSuccessMessage } from '../../../../../../../store/slices/coreSlice';
import {
  addCustomPartComponent, getPartsKitAffectedThunk,
  patchPartsKitComponentThunk,
} from '../../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { CustomPartDataType } from '../../../../types/partsKitViewPageTypes';
import {
  partsKitAffectedItemsFiltersSelector,
  partsKitComponentsSelector, partsKitComponentTaxSelector,
} from '../../../../../../../store/selectors/partsKitSelectors';
import {
  CustomComponentFields,
  customComponentFormFieldsDefaults,
  ProductNameFieldValidationSchema, QuantityFiledValidationSchema,
} from '../../../../types/customPartsKitComponentFormSchema';

type CustomComponentFormProps = {
  onCancel: VoidFunctionType,
  partsKitId: number,
  isVisible: boolean,
  itemToChange?: number,
  isLoading?: boolean,
}

const CustomComponentForm: React.FC<CustomComponentFormProps> = ({
  onCancel, partsKitId, isVisible, itemToChange, isLoading,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const { components: partsKitComponents } = useAppSelector(partsKitComponentsSelector);
  const componentTax = useAppSelector(partsKitComponentTaxSelector);
  const quoteUnitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const gMeasure = useAppSelector(gMeasureSelector);
  const integerQuantitiesMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const timeMeasures = useAppSelector(timeMeasureValuesSelector);
  const weightMeasures = useAppSelector(weightMeasureValuesSelector);
  const partCategories = useAppSelector(partCategoriesLookupSelector);
  const prevQuantityRef = useRef<number | null>(null);

  const [availableCategories, setAvailableCategories] = useState(partCategories);

  const [searchValue, setSearchValue] = useState<string>('');

  const {
    control, handleSubmit, setError, setValue, watch, reset, clearErrors,
  } = useForm<CustomComponentFields>({
    defaultValues: customComponentFormFieldsDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });

  const quantity = watch('quantity');
  const unitPrice = watch('unitPrice');
  const tax = watch('tax');
  const measure = watch('unitOfMeasure');
  const weight = watch('weight');
  const affectedItemsPaging = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const netValue = useMemo(() => +quantity * +unitPrice, [quantity, unitPrice]);
  const priceValue = useMemo(() => +quantity * +unitPrice + (+quantity * +unitPrice * (tax ? tax * 0.01 : 0)), [quantity, unitPrice, tax]);

  const isIntegerQuantitiesMeasure = !!(measure && integerQuantitiesMeasures.includes(+measure));
  const isWeightMeasure = !!(measure && weightMeasures.includes(+measure));
  const isGramMeasure = !!(measure && +measure === gMeasure);
  const isTimeMeasure = !!(measure && timeMeasures.includes(+measure));

  const cancelClick = () => {
    reset();
    onCancel();
    clearErrors();
    setSearchValue('');
  };

  const confirmChange = (itemToChange: number, finalValues: Partial<CustomPartDataType>) => {
    id && dispatch(patchPartsKitComponentThunk({
      id: itemToChange,
      partsKitId: +id,
      data: { ...finalValues },
      closeModal: cancelClick,
    }));
  };

  const confirmSubmit = (customPartData: CustomPartDataType) => {
    dispatch(addCustomPartComponent({
      id: partsKitId,
      customPartData,
      closeModal: () => {
        cancelClick();
        dispatch(setSuccessMessage({ message: 'A custom component line was successfully added.' }));
      },
    }));
  };

  const onSubmit = (values: CustomComponentFields) => {
    const getWeight = () => {
      const { unitOfMeasure } = values;
      if (timeMeasures.includes(unitOfMeasure)) return 0;
      if (unitOfMeasure === gMeasure) return Number((+values.weight / 1000).toFixed(2));
      return +values.weight || 0.00;
    };

    const finalValues = {
      tax: values.tax as number,
      categoryId: values.categoryId as number,
      unitOfMeasure: values.unitOfMeasure as number,
      productName: values.productName.trim(),
      weight: getWeight(),
      unitPrice: +values.unitPrice || 0.00,
      quantity: +values.quantity || 0,
    };

    if (itemToChange) {
      const component = partsKitComponents.find((el) => el.id === itemToChange);
      const equalityArr = [
        component?.tax === finalValues.tax,
        component?.unitOfMeasure === finalValues.unitOfMeasure,
        component?.quantity === finalValues.quantity,
        component?.weight === finalValues.weight,
        component?.partName === finalValues.productName,
        Number(component?.maxUnitPriceString) === finalValues.unitPrice,
        component?.categoryId === finalValues.categoryId,
      ];
      if (equalityArr.some((el) => !el)) {
        partsKitId && dispatch(getPartsKitAffectedThunk({
          id: partsKitId,
          filters: affectedItemsPaging,
          confirmAction: () => confirmChange(itemToChange, finalValues),
        }));
      } else {
        cancelClick();
      }
    } else {
      partsKitId && dispatch(getPartsKitAffectedThunk({
        id: partsKitId,
        filters: affectedItemsPaging,
        confirmAction: () => confirmSubmit(finalValues),
      }));
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        setAvailableCategories(partCategories.filter((el) => el.label.includes(searchValue)));
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      setAvailableCategories(partCategories);
    }
    // eslint-disable-next-line
    }, [searchValue]);

  useEffect(() => {
    if (isIntegerQuantitiesMeasure && quantity) {
      const wholeValue = Math.round(+quantity);
      setValue('quantity', wholeValue.toString());
    }
    // eslint-disable-next-line
    }, [measure, quantity, isIntegerQuantitiesMeasure]);
  useEffect(() => {
    if (isWeightMeasure && +quantity > 0) {
      setValue('weight', quantity);
    }
    // eslint-disable-next-line
  }, [measure]);

  useEffect(() => {
    if (isVisible) {
      if (itemToChange) {
        const component = partsKitComponents.find((el) => el.id === itemToChange);
        if (component) {
          setValue('productName', component.partName || '');
          setValue('quantity', component.quantity.toString() || '0');
          setValue('unitOfMeasure', component.unitOfMeasure || undefined);
          setValue('unitPrice', component.maxUnitPriceString || '0.00');
          setValue('tax', component.tax === null ? undefined : component.tax);
          setValue('categoryId', component.categoryId || undefined);
          setValue('weight', component.weight?.toString() || '0.00');
        }
      }
    } else {
      cancelClick();
    }
    // eslint-disable-next-line
  }, [itemToChange, partsKitComponents, isVisible]);
  const handleQuantityFocus = (value: string) => {
    if (value && +value > 0 && +weight > 0) {
      prevQuantityRef.current = +value;
    }
  };
  const handleQuantityBlur = (value: string) => {
    if (+weight > 0) {
      if (measure && +measure && prevQuantityRef.current) {
        setValue('weight', ((+weight / prevQuantityRef.current) * +value).toFixed(2));
        prevQuantityRef.current = null;
      }
    } else {
      if (isWeightMeasure) {
        setValue('weight', quantity);
      }
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="customComponentForm">
      <Controller
        control={control}
        name="productName"
        rules={ProductNameFieldValidationSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v.trim()) {
                setValue('productName', '');
                setError('productName', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.productName?.message}
            label="Product name"
            className="customComponentForm__input-wrapper"
          />
        )}
      />
      <div className="customComponentForm__input-section customComponentForm__input-section--4">
        <Controller
          name="quantity"
          control={control}
          rules={QuantityFiledValidationSchema(isIntegerQuantitiesMeasure)}
          render={({ field }) => (
            <>
              {isIntegerQuantitiesMeasure
                ? <RegularInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value;
                    field.onChange(v);
                  }}
                  onFocus={(e) => handleQuantityFocus(e.target.value)}
                  onBlur={(e) => handleQuantityBlur(e.target.value)}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                />
                : <DecimalInputNumber
                  value={field.value}
                  onChange={(e) => {
                    const v = e.target.value.replace(',', '.');
                    field.onChange(v);
                  }}
                  onFocus={(e) => handleQuantityFocus(e.target.value)}
                  onBlur={(e) => handleQuantityBlur(e.target.value)}
                  max={maxCommonDecimal}
                  error={errors.quantity?.message}
                  label="Quantity"
                />}
            </>
          )}
        />
        <Controller
          name="unitOfMeasure"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              options={quoteUnitsOfMeasure}
              error={errors.unitOfMeasure?.message}
              label="Unit of measure"
              parentRender
            />
          )}
        />
        <Controller
          name="unitPrice"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <DecimalInputNumber
              value={field.value}
              onChange={(e) => {
                const v = e.target.value.replace(',', '.');
                field.onChange(v);
              }}
              max={maxCommonDecimal}
              error={errors.unitPrice?.message}
              label="Unit price"
            />
          )}
        />
        <Controller
          name="tax"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              options={componentTax}
              error={errors.tax?.message}
              label="Tax"
              parentRender
            />
          )}
        />
      </div>
      <div className="customComponentForm__input-section customComponentForm__input-section--2">
        <Input
          value={netValue.toFixed(2)}
          label="Net"
          disabled
          prefix="£"
        />
        <Input
          value={priceValue.toFixed(2)}
          label="Gross"
          disabled
          prefix="£"
        />
      </div>
      <div className="customComponentForm__input-section customComponentForm__input-section--2">
        <Controller
          name="categoryId"
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={field.onChange}
              options={availableCategories}
              showSearch
              onSearch={(val) => {
                if (val.length <= 250) {
                  setSearchValue(val.trim());
                } else {
                  dispatch(setErrorMessage({ message: maxLengthMessageHandle(250) }));
                }
              }}
              onSelect={() => {
                setAvailableCategories(partCategories);
              }}
              error={errors.categoryId?.message}
              label="Component category"
              parentRender
            />
          )}
        />
        {isTimeMeasure
          ? <Input
            label="Weight"
            value="-"
            className="customComponentForm__disabled-weight"
            disabled
          />
          : <Controller
            name="weight"
            control={control}
            render={({ field }) => (
              <DecimalInputNumber
                value={isGramMeasure ? (+field.value / 1000).toFixed(2) : field.value}
                onChange={(e) => {
                  const v = e.target.value.replace(',', '.');
                  field.onChange(v);
                }}
                max={maxCommonDecimal}
                label="Weight"
                postfix="kg"
                disabled={isWeightMeasure}
              />
            )}
          />}
      </div>
      <ButtonActions
        cancelLabel="Cancel"
        createLabel={itemToChange ? 'Save changes' : 'Add component'}
        cancelClick={cancelClick}
        createType="submit"
        isLoading={isLoading}
        offsetTop
      />
    </form>
  );
};

export default CustomComponentForm;
