import React from 'react';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import { NumberFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import AddInvoiceColumns from './AddInvoiceColumns';
import AddInvoiceBody from './AddInvoiceBody';
import { useAppSelector } from '../../../../../store/hooks';
import { invoiceNominalCodesSelector } from '../../../../../store/selectors/invoicingSelectors';

type AddInvoiceTableProps = {
  isAll: boolean,
  isIndeterminate: boolean,
  checkedKeys: number[],
  checkRow: NumberFunctionType,
  checkAll: VoidFunctionType,
}

const AddInvoiceTable: React.FC<AddInvoiceTableProps> = ({
  isAll,
  isIndeterminate,
  checkedKeys,
  checkAll,
  checkRow,
}) => {
  const { totalCount } = useAppSelector(invoiceNominalCodesSelector);

  return (
    <TableNew>
      <AddInvoiceColumns
        isAll={isAll}
        isIndeterminate={isIndeterminate}
        checkAll={checkAll}
        totalCount={totalCount}
      />
      <AddInvoiceBody checkedKeys={checkedKeys} checkRow={checkRow} />
    </TableNew>
  );
};

export default AddInvoiceTable;
