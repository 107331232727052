import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import Button from '../../../../../core/components/button/Button';
import Pagination from '../../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../core/utils/regex';
import VehicleChecksTable from './components/checks-table/VehicleChecksTable';
import ChecksListModal from './components/checks-list-modal/ChecksListModal';
import { useLoadInspectionData } from './hooks/useLoadInspectionData';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import { deleteVehicleCheckThunk } from '../../../../../store/thunks/vehicles/VehicleInspectionThunks';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';

const VehicleInspection: React.FC = () => {
  const {
    id,
    dispatch,
    filters,
    items,
    totalCount,
    handlePaginationChange,
  } = useLoadInspectionData();
  const allowedToEdit = usePermission(PermissionEnum.VehicleEditFields);

  const [checksModal, setChecksModal] = useState(false);
  const [checkIdToDelete, setCheckIdToDelete] = useState<number | null>(null);

  return (
    <>
      <ConfirmationModal
        isVisible={!!checkIdToDelete}
        onCancel={() => setCheckIdToDelete(null)}
        confirmHandler={() => {
          id && checkIdToDelete && dispatch(deleteVehicleCheckThunk({
            vehicleId: +id,
            vehicleCheckId: checkIdToDelete,
            closeModal: () => setCheckIdToDelete(null),
          }));
        }}
      />
      <ChecksListModal
        visible={checksModal}
        onCancel={() => setChecksModal(false)}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Button
            label="Run check"
            onClick={() => setChecksModal(true)}
            icon={<FontAwesomeIcon icon={faCirclePlus} />}
            designType="dark"
            reversed
            disabled={!allowedToEdit}
          />
        </div>
        {totalCount > 0 && <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          onChange={handlePaginationChange}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />}
      </div>
      <VehicleChecksTable
        items={items}
        totalCount={totalCount}
        setCheckIdToDelete={setCheckIdToDelete}
        allowedToEdit={allowedToEdit}
      />
    </>
  );
};

export default VehicleInspection;
