import React from 'react';
import classNames from 'classnames';
import Tr from '../../../core/components/table-components/Tr';
import { RoleTreeNode } from './PermissionsTable';

type PermissionsColumnsProps = {
  roles: RoleTreeNode[],
}

const PermissionsColumns: React.FC<PermissionsColumnsProps> = ({ roles }) => {
  const levelThNameHandle = (string: string | null) => {
    if (string) {
      const splitArr = string.split(' (');
      return splitArr[1].replace(')', '');
    } else return '-';
  };
  return (
    <>
      <Tr>
        <th className="thRole" />
        {roles.map((role: RoleTreeNode, i: number) => (
          <th
            key={`${role.role}-${role.roleLevel}-${i}`}
            className="thRole"
            colSpan={role.colSpan?.length}
          >
            {role.fullName?.split(' (')[0]}
          </th>
        ))}
      </Tr>
      <Tr>
        <th className="thRoleLevel" />
        <th className="thRoleLevel" />
        {roles.slice(1).map((role: RoleTreeNode, i) => <>
          {role.colSpan?.map((c) => {
            const levelName = c && levelThNameHandle(c.fullName);
            return <th
              key={`${c.role}-${c.roleLevel}-${i}`}
              className={classNames('thRoleLevel', {
                'thRoleLevel--1': levelName.includes('1'),
              })}
            >
              {levelName}
            </th>;
          })}
        </>)}
      </Tr>
    </>
  );
};

export default PermissionsColumns;
