import {
  initPaging, PagingWithOrdering,
} from '../../../core/types/coreTypes';
import { SuitableVehicleProfileDto } from '../../../common/types/commonTypes';

export type StockFiltersType = {
  keyword?: string,
  categoryIds?: number[]
  subcategoryIds?: number[],
  types?: number[],
  description?: string,
  isCorePart?: boolean[],
  businessAreas?: number[],
  exceptBusinessAreas?: number[],
  statuses?: number[],
  vehicleIds?: number[],
  partCode?: string,
  manufacturerCode?: string,
  name?: string,
  hasWarningReorderShortage?: boolean,
  hasCriticalReorderShortage?: boolean,
  pricelistId?: number,
  canBeAddedToPricelistId?: number,
  onlySuitableForQuoteVehicleId?: number,
} & PagingWithOrdering

export const InitStockReorderLevelsFilters: StockFiltersType = {
  ...initPaging,
  hasCriticalReorderShortage: true,
  hasWarningReorderShortage: false,
};

export type PartDtoType = {
  id: number,
  type: number,
  categoryId: number | null,
  subcategoryId: number | null,
  isCore: boolean | null,
  isCorePart: boolean | null,
  partCode: string | null,
  manufacturerCode: string | null,
  name: string | null,
  description: string | null,
  unitOfMeasure: number,
  weight: number | null,
  status: number,
  createdAt: string | null,
  updatedAt: string | null,
  businessAreas: number[] | null,
  suitableForVehicleGroupIds: number[] | null,
  suitableForVehicles: Array<SuitableVehicleProfileDto> | null,
  suitableVehiclesSource: number | null,
  free: number,
  allocated: number,
  picked: number,
  collected: number,
  dueIn: number,
  warningReorderLevel: number | null,
  criticalReorderLevel: number | null,
  withoutSellingPrice: number | null,
  hasPrice: boolean,
}

export type PartResultType = {
  id: number,
  number: number,
  categoryId: string | number | null,
  subcategoryId: string | number | null,
  partCode: string | null,
  manufacturerCode: string | null,
  name: string | null,
  status: string | number,
  businessAreas: string | number[] | null,
}

export type PatchPartsKitDataType = {
  partsKitId: number,
  name: string | null,
  partsKitCode: string | null,
  categoryId: number | null,
  description: string | null,
  businessAreas: number[] | null,
  suitableForVehicleIds: number[] | null,
}
