import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { indexOf } from 'lodash';
import Button from '../button/Button';
import Input from '../input/Input';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { VoidFunctionType } from '../../types/coreTypes';
import { setMarkersList } from '../../../store/slices/vehiclesSlice';
import { markersListSelector } from '../../../store/selectors/vehiclesSelectors';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';

type ImageMarkersComponentProps = {
  isDrawerReadMode: boolean,
  cleanUpMarkersState: VoidFunctionType,
}

const ImageMarkersComponent: React.FC<ImageMarkersComponentProps> = ({ cleanUpMarkersState, isDrawerReadMode }) => {
  const dispatch = useAppDispatch();
  const markersList = useAppSelector(markersListSelector);

  const removeMarker = (i: number) => {
    const newList = markersList.filter((marker) => indexOf(markersList, marker) !== i);
    dispatch(setMarkersList(newList));
    if (newList.length === 0) {
      cleanUpMarkersState();
    }
  };
  const setMarkerComment = (v: string, i: number) => {
    const newList = markersList.map((marker, index) => {
      if (i === index) {
        return {
          ...marker,
          comment: v,
        };
      } else return marker;
    });
    dispatch(setMarkersList(newList));
  };
  return (
    <>
      <div className="drawerActions">
        <Button
          label="Remove all markers"
          type="text"
          onClick={cleanUpMarkersState}
          disabled={isDrawerReadMode}
        />
      </div>
      <div className="drawer-list-wrapper">
        <ul className="drawer-list">
          {markersList.map((marker, i) => <li key={i} className="drawer-list__item">
            <span>{`Marker ${i + 1}`}</span>
            <div className="markerContentWrap">
              {isDrawerReadMode ? <TruncateTooltip
                value={marker.comment}
                className="markerCommentDisabled"
                overlayClassName="markerCommentTooltip"
              />
                : <Input
                  value={marker.comment}
                  maxLength={50}
                  onChange={(e) => {
                    const commentV = e.target.value;
                    if (commentV.length <= 50) {
                      setMarkerComment(e.target.value, i);
                    }
                  }}
                  className="markerComment"
                />}
              <Button
                icon={<FontAwesomeIcon icon={faTrashAlt} />}
                onClick={() => removeMarker(i)}
                disabled={isDrawerReadMode}
              />
            </div>
          </li>)}
        </ul>
      </div>
    </>
  );
};

export default ImageMarkersComponent;
