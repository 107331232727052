import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import { stockApi } from '../../../../api/stockApi';
import {
  CreatePurchaseOrderDataType,
  CreatePurchaseOrderResponseType,
} from '../../../../stock/purchase-orders/create/types/createPurchaseOrderTypes';
import { routesPath } from '../../../../core/enums/pathEnum';
import { GetSupplierResponse } from '../../../../supplier/dashboard/types/SuppliersTypes';
import { SuppliersAPI } from '../../../../api/suppliersApi';

export const getPurchaseOrderSupplierRequisiteThunk = createAsyncThunk<GetSupplierResponse,
{id: number}>(
  'get/PurchaseOrderSupplierRequisite',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSupplierById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getMaxPurchaseOrderNumberThunk = createAsyncThunk<ResponseSingleResult<{ maxPurchaseOrderNumber: string | null }>>(
  'get/MaxPurchaseOrderNumber',
  async (_, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchMaxPurchaseOrderNumber();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPurchaseOrderThunk = createAsyncThunk<ResponseSingleResult<{
  createdPurchaseOrders: CreatePurchaseOrderResponseType[],
}> | null,
{
  data: CreatePurchaseOrderDataType,
  navigate: NavigateFunction,
  onClose: VoidFunctionType,
}
>(
  'post/PurchaseOrder',
  async ({
    data, navigate, onClose,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.createPurchaseOrder(data);
      onClose();
      const { createdPurchaseOrders } = response.data.data;
      const firstEl = createdPurchaseOrders[0];

      navigate(`/${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}/${firstEl.id}`);
      firstEl.purchaseOrderNumber && dispatch(setSuccessMessage({
        message: `Purchase order ${firstEl.purchaseOrderNumber} was successfully created.`,
        toastId: firstEl.purchaseOrderNumber,
      }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
