import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import './TransferComponent.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { StringFunctionType } from '../../types/coreTypes';
import TransferList from './TransferList';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  sourceAvailableVehicleProfilesFiltersSelector,
  sourceAvailableVehicleProfilesSelector, targetAvailableVehicleProfilesFiltersSelector,
  targetAvailableVehicleProfilesSelector, vehicleManufacturersDictionarySelector,
} from '../../../store/selectors/sharedSelectors';
import {
  setSourceAvailableVehicleProfiles,
  setSourceAvailableVehiclesProfilesFilters, setTargetAvailableVehiclesProfilesFilters,
} from '../../../store/slices/sharedSlice';
import ButtonWithBadge from '../button-with-badge/ButtonWithBadge';
import { suitableVehicleSourcesValuesSelector } from '../../../store/selectors/coreSelectors';
import { initAvailableVehicleProfilesData } from '../../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';

type TransferComponentProps = {
  selectedSource: number | null,
  targetIds: number[],
  setTargetIds: (ids: number[]) => void,
  sourceSearchValue: string,
  setSourceSearchValue: StringFunctionType,
  targetSearchValue: string,
  setTargetSearchValue: StringFunctionType,
  sourceLoading: boolean,
  targetLoading: boolean,
}

const TransferComponent: React.FC<TransferComponentProps> = ({
  selectedSource,
  targetIds,
  setTargetIds,
  sourceSearchValue,
  setSourceSearchValue,
  targetSearchValue,
  setTargetSearchValue,
  sourceLoading,
  targetLoading,
}) => {
  const dispatch = useAppDispatch();
  const { byCode, byModel } = useAppSelector(suitableVehicleSourcesValuesSelector);

  const sourceFilters = useAppSelector(sourceAvailableVehicleProfilesFiltersSelector);
  const targetFilters = useAppSelector(targetAvailableVehicleProfilesFiltersSelector);

  const manufacturers = useAppSelector(vehicleManufacturersDictionarySelector);

  const initSearchData = {
    keyword: undefined,
    includeCode: undefined,
    includeManufacturer: undefined,
    includeModelDescription: undefined,
  };
  const filledSearchData = useMemo(() => ({
    includeManufacturer: true,
    includeCode: selectedSource === byCode,
    includeModelDescription: selectedSource === byModel,
  }), [byCode, byModel, selectedSource]);

  const {
    items: sourceItems,
    allItemIds: sourceAllItemIds,
    totalCountWithoutKeywordSearch: sourceUnfilteredTotalCount,
  } = useAppSelector(sourceAvailableVehicleProfilesSelector);

  const {
    items: targetItems,
    allItemIds: targetAllItemsIds,
    totalCountWithoutKeywordSearch: targetUnfilteredTotalCount,
  } = useAppSelector(targetAvailableVehicleProfilesSelector);

  const [sourceIdsToTransfer, setSourceIdsToTransfer] = useState<number[]>([]);
  const [targetIdsToTransfer, setTargetIdsToTransfer] = useState<number[]>([]);

  useEffect(() => {
    dispatch(setSourceAvailableVehicleProfiles(initAvailableVehicleProfilesData));

    const manufacturer = selectedSource === byModel
      ? [manufacturers[0]?.value || '']
      : undefined;

    dispatch(setSourceAvailableVehiclesProfilesFilters({
      ...sourceFilters,
      ...initSearchData,
      manufacturer,
    }));
    dispatch(setTargetAvailableVehiclesProfilesFilters({
      ...targetFilters,
      ...initSearchData,
    }));
    // eslint-disable-next-line
  }, [selectedSource]);

  const transferTo = (to: 'source' | 'target') => {
    if (to === 'target') setTargetIds([...targetIds, ...sourceIdsToTransfer]);
    if (to === 'source') setTargetIds(targetIds.filter((id) => !targetIdsToTransfer.includes(id)));
  };

  const handleManufacturerChange = (value: string) => {
    dispatch(setSourceAvailableVehiclesProfilesFilters({ ...sourceFilters, manufacturer: [value] }));
  };

  const searchPlaceholder = selectedSource === byCode ? 'Search by vehicle code' : 'Search by model description';

  const handleSearchSource = useCallback((value: string) => {
    const shouldSetFilters = Boolean(value.length || (!value.length && sourceFilters.keyword));
    shouldSetFilters && dispatch(setSourceAvailableVehiclesProfilesFilters({
      ...sourceFilters,
      keyword: value,
      ...filledSearchData,
    }));
  }, [dispatch, filledSearchData, sourceFilters]);

  const handleSearchTarget = useCallback((value: string) => {
    const shouldSetFilters = Boolean(value.length || (!value.length && targetFilters.keyword));
    shouldSetFilters && dispatch(setTargetAvailableVehiclesProfilesFilters({
      ...targetFilters,
      keyword: value,
      ...filledSearchData,
    }));
  }, [dispatch, filledSearchData, targetFilters]);

  return (
    <section className="transferSection">
      <TransferList
        searchValue={sourceSearchValue}
        setSearchValue={setSourceSearchValue}
        isLoading={sourceLoading}
        checkboxLabel={`${sourceFilters.keyword
          ? `${sourceAllItemIds.length} of ${sourceUnfilteredTotalCount}`
          : sourceAllItemIds.length} item(s)`}
        list={sourceItems}
        allItemIds={sourceAllItemIds}
        setIdsToTransfer={setSourceIdsToTransfer}
        handleManufacturerChange={selectedSource === byModel ? handleManufacturerChange : undefined}
        searchPlaceholder={searchPlaceholder}
        searchHandle={handleSearchSource}
      />
      <div className="transferSection__btns">
        <ButtonWithBadge
          icon={<FontAwesomeIcon icon={faChevronRight} />}
          className="transferSection__btns-item"
          onClick={() => transferTo('target')}
          disabled={!sourceIdsToTransfer.length || sourceLoading || targetLoading}
          badge={sourceIdsToTransfer.length}
        />
        <ButtonWithBadge
          icon={<FontAwesomeIcon icon={faChevronLeft} />}
          className="transferSection__btns-item"
          onClick={() => transferTo('source')}
          disabled={!targetIdsToTransfer.length || sourceLoading || targetLoading}
          badge={targetIdsToTransfer.length}
        />
      </div>
      <TransferList
        searchValue={targetSearchValue}
        setSearchValue={setTargetSearchValue}
        isLoading={targetLoading}
        isTarget
        checkboxLabel={`${targetFilters.keyword
          ? `${targetAllItemsIds.length} of ${targetUnfilteredTotalCount}`
          : targetAllItemsIds.length} item(s)`}
        list={targetItems}
        allItemIds={targetAllItemsIds}
        setIdsToTransfer={setTargetIdsToTransfer}
        searchPlaceholder={searchPlaceholder}
        disabledSearch={!targetUnfilteredTotalCount}
        searchHandle={handleSearchTarget}
      />
    </section>
  );
};

export default TransferComponent;
