import { DashboardViewEnum, JobModesEnum, QueueCompletedModesEnum } from '../../jobs/enums/jobsEnums';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export const jobsPageOptions = [
  {
    value: JobModesEnum.DASHBOARD,
    label: DashboardViewEnum.DASHBOARD,
    restrictedTo: PermissionEnum.JobDashboard,
  },
  {
    value: JobModesEnum.JOB_LIST,
    label: DashboardViewEnum.JOB_LIST,
    restrictedTo: PermissionEnum.JobListView,
  },
  {
    value: JobModesEnum.CALENDAR,
    label: DashboardViewEnum.CALENDAR,
    restrictedTo: PermissionEnum.JobCalendar,
  },
  {
    value: JobModesEnum.CREATE,
    label: DashboardViewEnum.CREATE,
    restrictedTo: PermissionEnum.JobCreateNewJob,
  },
];

export const jobsListPageOptions = [
  {
    value: JobModesEnum.JOB_LIST,
    label: DashboardViewEnum.JOB_LIST,
    restrictedTo: PermissionEnum.JobListView,
  },
];

export const jobListTabModesOptions = Object.keys(QueueCompletedModesEnum).map((key) => ({
  // eslint-disable-next-line
  // @ts-ignore
  label: QueueCompletedModesEnum[key].charAt(0).toUpperCase() + QueueCompletedModesEnum[key].slice(1),
  // eslint-disable-next-line
  // @ts-ignore
  value: QueueCompletedModesEnum[key],
}));
