import React from 'react';
import './AttachmentsTable.scss';
import TableNew from '../../../core/components/table-components/table-new/TableNew';

type AttachmentsTableProps = {
  children: React.ReactNode,
}

const AttachmentsTable: React.FC<AttachmentsTableProps> = ({ children }) => {
  return (
    <TableNew className="attachmentsTableWrap">
      {children}
    </TableNew>
  );
};

export default AttachmentsTable;
