import React, { ReactNode, useEffect, useRef } from 'react';
import { Input as TextField } from 'antd';
import classnames from 'classnames';
import {
  ChangeEventHandler, FocusEventHandler, KeyboardEventHandler, VoidFunctionType,
} from '../../types/coreTypes';
import './Input.scss';

type InputProps = {
  value?: string | number | null;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onClick?: VoidFunctionType;
  placeholder?: string;
  type?: 'file' | 'text' | 'number';
  allowClear?: boolean | {clearIcon: ReactNode};
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  error?: string;
  label?: string;
  readOnly?: boolean;
  maxLength?: number;
  fieldRef?: React.MutableRefObject<null>;
  addonAfter?: React.ReactNode,
  autoFocus?: boolean,
  alignBottom?: boolean,
}

const Input: React.FC<InputProps> = ({
  value,
  onChange,
  onPressEnter,
  onClick,
  onBlur,
  placeholder,
  type = 'text',
  allowClear,
  prefix,
  suffix,
  disabled,
  className,
  error,
  label,
  readOnly,
  maxLength,
  fieldRef,
  addonAfter,
  autoFocus,
  alignBottom,
}) => {
  const ref = useRef(null);
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    fieldRef?.current?.focus();
  }, [fieldRef]);

  return (
    <div className={classnames(className, 'input', { 'input--align-bottom': alignBottom })}>
      {label && <span className="input-label">{label}</span>}
      <TextField
        ref={fieldRef || ref}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        value={value || undefined}
        onPressEnter={(e) => {
          onPressEnter && onPressEnter(e);
          // eslint-disable-next-line
          // @ts-ignore
          fieldRef ? fieldRef.current?.blur() : ref.current?.blur();
        }}
        autoFocus={autoFocus}
        onClick={onClick}
        onBlur={onBlur}
        placeholder={placeholder}
        type={type}
        step={0.01}
        allowClear={allowClear}
        suffix={suffix}
        prefix={prefix}
        disabled={disabled}
        readOnly={readOnly}
        maxLength={maxLength}
        addonAfter={addonAfter}
        className="input-field"
      />
      {error && <span className="input-error">{error || ' '}</span>}
    </div>
  );
};

export default Input;
