import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faPen } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../core/components/table-components/Tbody';
import { useAppSelector } from '../../../../store/hooks';
import {
  assignNominalCodesTableSelector,
} from '../../../../store/selectors/invoicingSelectors';
import TableNew from '../../../../core/components/table-components/table-new/TableNew';
import TdNew from '../../../../core/components/table-components/table-new/TdNew';
import Dropdown from '../../../../core/components/dropdown/Dropdown';
import Tr from '../../../../core/components/table-components/Tr';
import { TruncateTooltip } from '../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tag from '../../../../core/components/tag/Tag';
import Thead from '../../../../core/components/table-components/Thead';
import { assignNominalCodesColumns } from '../../../invoicing-purchases/utils/data';
import { NumberFunctionType } from '../../../../core/types/coreTypes';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';

type AssignNominalCodesTableProps = {
  deleteConfirmationHandler: NumberFunctionType,
  editRecordHandler: NumberFunctionType,
}

const AssignNominalCodesTable: React.FC<AssignNominalCodesTableProps> = ({ deleteConfirmationHandler, editRecordHandler }) => {
  const { totalCount, items } = useAppSelector(assignNominalCodesTableSelector);

  const nowrapKeys = ['net', 'vat'];

  const renderValueHandle = (key: string, value: string | number) => {
    switch (key) {
      case 'description':
      case 'invoiceDocumentNumber':
        return <TruncateTooltip value={value} table />;
      case 'state': return <Tag label={value} />;
      default: return value;
    }
  };
  const actions = (id: number) => [
    {
      label: 'Edit record',
      icon: <FontAwesomeIcon icon={faPen} />,
      key: 'editRecord',
      onClick: () => editRecordHandler(id),
    },
    {
      label: 'Delete record',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      key: 'deleteRecord',
      onClick: () => deleteConfirmationHandler(id),
    },
  ];
  return <TableNew small offsetBottom scrollOffset={[24, 16]}>
    <Thead>
      <Tr>
        {assignNominalCodesColumns.map((col) => (
          <ThNew
            key={col.dataIndex}
            className={`assignNominalCodesTh--${col.dataIndex}`}
          >
            {col.title}
          </ThNew>
        ))}
        <ThNew action><span className="sr-only">Actions</span></ThNew>
      </Tr>
    </Thead>
    <Tbody>
      {totalCount > 0
        ? items.map((item) => <Tr key={item.id}>
          {Object.entries(item).map(([key, value]) => {
            if (key === 'id') return null;
            return <TdNew key={key} className={nowrapKeys.includes(key) ? 'nowrap' : ''}>{renderValueHandle(key, value)}</TdNew>;
          })}
          <TdNew action>
            <Dropdown
              icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
              menuItems={actions(item.id)}
              disabled={item.state !== 'Pending'}
            />
          </TdNew>
        </Tr>)
        : <EmptyTableSection text="No records" colSpan={assignNominalCodesColumns.length + 1} />}
    </Tbody>
  </TableNew>;
};

export default AssignNominalCodesTable;
