import React from 'react';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import Modal from '../../../core/components/modal/Modal';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import './EnquiryStatusChange.scss';
import { useAppSelector } from '../../../store/hooks';
import { enquirySentToCustomerStatusSelector } from '../../../store/selectors/coreStatusesSelectors';

type FinalizedSentModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  onConfirm: NumberFunctionType,
  isButtonDisabled?: boolean,
}

const FinalizedSentModal: React.FC<FinalizedSentModalProps> = ({
  isVisible, onCancel, onConfirm, isButtonDisabled,
}) => {
  const enquirySentToCustomerStatus = useAppSelector(enquirySentToCustomerStatusSelector);
  return (
    <Modal visible={isVisible} onCancel={onCancel} title="Confirm">
      <div className="sentWrap">
        <span className="sentWrap__title">Please confirm, that you have sent an email with a quote to a customer.</span>
        <ButtonActions
          cancelLabel="Cancel"
          createLabel="Confirm"
          cancelClick={onCancel}
          createClick={() => enquirySentToCustomerStatus?.value && onConfirm(enquirySentToCustomerStatus?.value)}
          createType="button"
          disabledCreate={isButtonDisabled}
          disabledCancel={isButtonDisabled}
        />
      </div>
    </Modal>
  );
};

export default FinalizedSentModal;
