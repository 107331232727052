import { routesPath } from '../../core/enums/pathEnum';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import { EntityType } from '../types/commonTypes';

export const viewPageLinksAndPermissions: {name: EntityType, to: routesPath | string, permission: PermissionEnum}[] = [
  {
    name: 'Customer',
    to: routesPath.CUSTOMERS,
    permission: PermissionEnum.CustomerViewPage,
  },
  {
    name: 'Order',
    to: routesPath.ORDERS,
    permission: PermissionEnum.OrderViewPage,
  },
  {
    name: 'Rectification',
    to: routesPath.RECTIFICATIONS,
    permission: PermissionEnum.RectificationViewPage,
  },
  {
    name: 'Job',
    to: routesPath.JOBS,
    permission: PermissionEnum.JobViewPage,
  },
  {
    name: 'Employee',
    to: routesPath.EMPLOYEES,
    permission: PermissionEnum.EmployeeViewPage,
  },
  {
    name: 'Part',
    to: `${routesPath.STOCK}/part`,
    permission: PermissionEnum.StockPartViewPage,
  },
  {
    name: 'Parts kit',
    to: `${routesPath.STOCK}/${routesPath.PARTS_KITS}`,
    permission: PermissionEnum.StockPartsKitViewPage,
  },
  {
    name: 'Stock purchase order',
    to: `${routesPath.STOCK}/${routesPath.PURCHASE_ORDER}`,
    permission: PermissionEnum.StockPurchaseOrdersViewPage,
  },
  {
    name: 'Invoicing purchase order',
    to: `${routesPath.INVOICING}`,
    permission: PermissionEnum.StockPurchaseOrdersViewPage,
  },
  {
    name: 'Pricelist',
    to: routesPath.PRICELISTS,
    permission: PermissionEnum.PricelistsViewPage,
  },
  {
    name: 'Part request',
    to: `${routesPath.STOCK}/part-request`,
    permission: PermissionEnum.StockPartRequestViewPage,
  },
  {
    name: 'Enquiry',
    to: routesPath.ENQUIRIES,
    permission: PermissionEnum.EnquiryViewPage,
  },
  {
    name: 'Supplier',
    to: routesPath.SUPPLIERS,
    permission: PermissionEnum.SupplierViewPage,
  },
  {
    name: 'Vehicle',
    to: routesPath.VEHICLES,
    permission: PermissionEnum.VehicleViewPage,
  },
  {
    name: 'User account',
    to: routesPath.USER_PROFILE,
    permission: PermissionEnum.UserViewPage,
  },
];
