import qs from 'qs';
import instance from './axiosConfig';
import {
  CustomerDto,
  CustomersDictionaryFiltersType,
  CustomersListFetchType, GetCustomerResponseType,
  PatchCustomerRestModel,
} from '../customers/customers-dashboard/types/customersDashboardTypes';
import {
  CreateCustomerContactResponse,
  CustomerActivityEventDto,
  CustomerContactFiltersType, CustomerNoteResponse, NewCustomerContactReqDataType,
} from '../customers/customer-view-page/types/customerVewPageTypes';
import { CreateCustomerReqType, CreateCustomerResType } from '../customers/create-customer/types/createCustomerTypes';
import {
  AxiosCreatedAttachmentsResponse,
  AxiosCreateUploadUriResponse,
  AxiosDataListResponse,
  AxiosGetAttachmentFileResponse,
  AxiosGetPagedAttachmentsResponse, AxiosGetCustomerContactsResponse,
  AxiosNullableResponse,
  AxiosSingleResponse,
  CoreAttachmentsFilters,
  CustomerDictionaryDto,
  PagingType,
  PostAttachReqType,
  UploadUriDto,
} from '../core/types/coreTypes';

export const customersAPI = {
  fetchCustomersList(filters?: CustomersListFetchType, signal?: AbortSignal): AxiosDataListResponse<CustomerDto[]> {
    return instance.get(
      '/customer/customer',
      {
        params: {
          'Filter.Keyword': filters?.keyword,
          'Filter.ExcludeCompanyRegistrationNumberSearch': filters?.excludeCompany,
          'Filter.CustomerTypes': filters?.customerType,
          'Filter.CustomerStatuses': filters?.customerStatus,
          'PagingOptions.Page': filters?.page,
          'PagingOptions.ItemsPerPage': filters?.pageSize,
        },
        signal,
        paramsSerializer: (params) => {
          return qs.stringify(params, { arrayFormat: 'repeat' });
        },
      },
    );
  },
  fetchCustomerById(id: number): AxiosSingleResponse<GetCustomerResponseType> {
    return instance.get(`/customer/customer/${id}`);
  },
  deleteCustomer(id: number): AxiosNullableResponse {
    return instance.delete(`/customer/customer/${id}`);
  },
  patchCustomer(id: number, data: PatchCustomerRestModel): AxiosNullableResponse {
    return instance.patch(`/customer/customer/${id}`, { ...data });
  },
  fetchCustomerAttachments(customerId: number, filters: CoreAttachmentsFilters): AxiosGetPagedAttachmentsResponse {
    return instance.get(`/customer/customers/${customerId}/attachments`, {
      params: {
        'Filter.Name': filters.name,
        'Filter.MinUploadDate': filters.uploadDate?.min,
        'Filter.MaxUploadDate': filters.uploadDate?.max,
        'Filter.Comment': filters.comment,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
    });
  },
  getCustomerAttachmentById(customerId: number, id: number): AxiosGetAttachmentFileResponse {
    return instance.get(`/customer/customers/${customerId}/attachments/${id}`);
  },
  postCustomerAttachUploadUri(customerId: number, uploadUriDtos: UploadUriDto[]): AxiosCreateUploadUriResponse {
    return instance.post(`/customer/customers/${customerId}/attachments/uploadUri`, { uploadUriDtos });
  },
  postCustomerAttach(customerId: number, attachments: PostAttachReqType[]): AxiosCreatedAttachmentsResponse {
    return instance.post(`/customer/customers/${customerId}/attachments`, { objectId: customerId, attachments });
  },
  editCustomerAttachment(customerId: number, id: number, comment: string): AxiosNullableResponse {
    return instance.patch(`/customer/customers/${customerId}/attachments/${id}`, { comment });
  },
  deleteCustomerAttachment(customerId: number, id: number): AxiosNullableResponse {
    return instance.delete(`/customer/customers/${customerId}/attachments/${id}`);
  },
  deleteMultipleCustomerAttachment(customerId: number, attachmentIds: number[]): AxiosNullableResponse {
    return instance.delete(`/customer/customers/${customerId}/attachments`, { data: { attachmentIds } });
  },
  createCustomer(data: CreateCustomerReqType): AxiosSingleResponse<CreateCustomerResType> {
    return instance.post('/customer/customer', { ...data });
  },
  fetchCustomerActivity(customerId: number, filters: PagingType): AxiosDataListResponse<CustomerActivityEventDto[]> {
    return instance.get('/customer/customerActivity', {
      params: {
        'Filter.CustomerId': customerId,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
      },
    });
  },
  createCustomerActivityNote(customerId: number, content: string): AxiosSingleResponse<CustomerNoteResponse> {
    return instance.post('/customer/note', { customerId, content });
  },
  putCustomerActivityNote(id: number, content: string): AxiosNullableResponse {
    return instance.put(`/customer/note/${id}`, { id, content });
  },
  deleteCustomerActivityNote(id: number): AxiosNullableResponse {
    return instance.delete(`/customer/note/${id}`);
  },
  fetchCustomerContacts(
    customerId: number,
    filters: CustomerContactFiltersType,
    options?: {
      notAddedToEnquiryId?: number,
      notAddedToOrderId?: number,
      notAddedToRectificationId?: number,
    },
  ): AxiosGetCustomerContactsResponse {
    return instance.get('/customer/customerContact', {
      params: {
        'Filter.CustomerId': customerId,
        'Filter.NotAddedToEnquiryId': options?.notAddedToEnquiryId,
        'Filter.NotAddedToOrderId': options?.notAddedToOrderId,
        'Filter.NotAddedToRectificationId': options?.notAddedToRectificationId,
        'PagingOptions.Page': filters.page,
        'PagingOptions.ItemsPerPage': filters.pageSize,
        'OrderOptions.Field': filters.order?.field,
        'OrderOptions.Ascending': filters.order?.isAsc,
      },
    });
  },
  deleteCustomerContact(customerContactId: number): AxiosNullableResponse {
    return instance.delete(`/customer/customerContact/${customerContactId}`);
  },
  createNewCustomerContact(data: NewCustomerContactReqDataType): AxiosSingleResponse<CreateCustomerContactResponse> {
    return instance.post('/customer/customerContact', { ...data });
  },
  editCustomerContact(contactId:number, data: NewCustomerContactReqDataType): AxiosNullableResponse {
    return instance.put(`/customer/customerContact/${contactId}`, { ...data });
  },
  fetchCustomersDictionary(filters?: CustomersDictionaryFiltersType): AxiosSingleResponse<{ customers: CustomerDictionaryDto[] }> {
    return instance.get('/customer/customer/views/dictionary', {
      params: {
        'Filter.Keyword': filters?.keyword,
        'Filter.EnquiryIdCanBeAssigned': filters?.enquiryIdCanBeAssigned,
        'Filter.OrderIdCanBeAssigned': filters?.orderIdCanBeAssigned,
      },
    });
  },
};
