import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { ErrorsEnum, RequiredFieldSchema } from '../../../../core/enums/errorsEnum';
import { ACCOUNT_NO_REGEX } from '../../../../core/utils/regex';

export type PartsKitFields = {
  name: string,
  partsKitCode: string,
  categoryId?: number,
  description: string | null,
  businessAreas: number[],
  suitableForVehicleIds: number[] | null,
}

export const PartsKitDescriptionSchema = { maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } };

export const PartsKitDefaults: PartsKitFields = {
  name: '',
  partsKitCode: '',
  categoryId: undefined,
  description: null,
  businessAreas: [],
  suitableForVehicleIds: null,
};

export type DuplicatePartsKitFields = { code: string }

export const DuplicatePartsKitDefaults = {
  code: '',
};

export const PartsKitCodeSchema = {
  ...RequiredFieldSchema,
  minLength: { value: 4, message: rangeLengthMessageHandle([4, 6]) },
  maxLength: { value: 6, message: rangeLengthMessageHandle([4, 6]) },
  pattern: { value: ACCOUNT_NO_REGEX, message: ErrorsEnum.ONLY_NUMBERS_LETTERS },
};
