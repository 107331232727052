import React, { useEffect } from 'react';
import Layout from '../../core/components/layout/Layout';
import CreatePricelistForm from './components/CreatePricelistForm';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { useAppDispatch } from '../../store/hooks';
import { getCustomers } from '../../store/thunks/customers/customersDashboardThunks';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { getPricelistSuppliers } from '../../store/thunks/pricelist/pricelistThunks';

import { getSupplierStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';

const CreatePricelistContainer: React.FC = () => {
  const dispatch = useAppDispatch();

  const { backHandle } = useLocationHistoryState();
  useEffect(() => {
    dispatch(getCustomers({ params: undefined }));
    dispatch(getPricelistSuppliers({ keyword: undefined }));
    dispatch(getSupplierStatusesThunk());
    // eslint-disable-next-line
  }, []);
  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createListTitle createHead">Create new pricelist</div>
    </DetailsHead>}
    >
      <CreatePricelistForm />
    </Layout>
  );
};

export default CreatePricelistContainer;
