import React from 'react';
import Thead from '../../../core/components/table-components/Thead';
import { CustomAny, VoidFunctionType } from '../../../core/types/coreTypes';
import Tbody from '../../../core/components/table-components/Tbody';
import Tfoot from '../../../core/components/table-components/Tfoot';
import Tr from '../../../core/components/table-components/Tr';
import { sourceProductColumns } from './data';
import { PartProductDictionaryType, PartProductTotalType, ProductSourceLine } from '../../types/commonTypes';
import SelectProductSourceCells from './SelectProductSourceCells';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../core/components/table-components/table-new/TdNew';

type SelectProductSourceTableProps = {
  sourceId?: number,
  checkedCells: string[],
  setCheckedCells: (value: string[]) => void,
  values: CustomAny,
  setValues: (val: CustomAny) => void,
  editMode?: boolean,
  editedLine?: number,
  productSourceTotal: PartProductTotalType | null,
  getProductSourceDictionary: (partId: number, openModal: VoidFunctionType) => void,
  getProductSourceTotal: (
    partId: number,
    stockQty: number | null,
    manualQty: number | null,
    manualUnitPrice: number | null,
    pricelists: Array<{ pricelistId: number, quantity: number }> | null,
  ) => void,
  lines: ProductSourceLine[],
  linesParts: { id: number, unitOfMeasure: number }[],
  productSourceDictionary: PartProductDictionaryType | null,
  hideManualInput?: boolean,
}

const SelectProductSourceTable: React.FC<SelectProductSourceTableProps> = ({
  sourceId, checkedCells, setCheckedCells, values, setValues,
  editedLine, editMode, productSourceTotal, getProductSourceDictionary,
  getProductSourceTotal, lines, linesParts, productSourceDictionary, hideManualInput,
}) => {
  return (
    <TableNew small>
      <Thead>
        <Tr>
          <ThNew className="productSourceTh--checkbox"><span className="sr-only">Select</span></ThNew>
          <ThNew className="productSourceTh--name"><span className="sr-only">Name</span></ThNew>
          {sourceProductColumns.map((col) => <ThNew key={col.dataIndex} alignCenter>{col.title}</ThNew>)}
        </Tr>
      </Thead>
      <Tbody>
        <SelectProductSourceCells
          sourceId={sourceId}
          productSourceTotal={productSourceTotal}
          checkedCells={checkedCells}
          setCheckedCells={setCheckedCells}
          values={values}
          setValues={setValues}
          editMode={editMode}
          editedLine={editedLine}
          getProductSourceDictionary={getProductSourceDictionary}
          getProductSourceTotal={getProductSourceTotal}
          lines={lines}
          linesParts={linesParts}
          productSourceDictionary={productSourceDictionary}
          hideManualInput={hideManualInput}
        />
      </Tbody>
      <Tfoot>
        <Tr>
          <TdNew colSpan={2}>Total</TdNew>
          <TdNew alignCenter>{productSourceTotal?.totalQuantity || 0}</TdNew>
          <TdNew alignCenter>{`£ ${productSourceTotal?.totalUnitPrice || 0.00}`}</TdNew>
          <TdNew alignCenter>{`£ ${productSourceTotal?.totalNetPrice || 0.00}`}</TdNew>
        </Tr>
      </Tfoot>
    </TableNew>
  );
};

export default SelectProductSourceTable;
