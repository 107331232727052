import React from 'react';
import { PermissionEnum } from '../core/enums/dictionariesEnums';
import usePermission from './permissionHook';
import Spinner from '../core/components/spinner/Spinner';

type RestrictedProps = {
  to: PermissionEnum;
  children: JSX.Element;
  isSidebar?: boolean;
};

// This component is meant to be used everywhere a restriction based on user permission is needed
const Restricted: React.FunctionComponent<RestrictedProps> = ({ to, isSidebar, children }) => {
  // We "connect" to the provider thanks to the PermissionContext
  const allowed = usePermission(to);

  if (allowed === undefined && !isSidebar) {
    return <Spinner />;
  }

  // If the user has that permission, render the children
  if (allowed) {
    return <>{children}</>;
  }

  // Otherwise, do not render anything
  return null;
};

export default Restricted;
