import React, { useRef } from 'react';
import { Select as SelectComponent } from 'antd';
import { LabeledValue } from 'antd/lib/select';
import './Select.scss';
import classnames from 'classnames';
import {
  CustomAny, FocusEventHandler, StringFunctionType, VoidFunctionType,
} from '../../types/coreTypes';

const { Option } = SelectComponent;
type SelectValueType = string | string[] | number | number[] | LabeledValue | LabeledValue[] | boolean;

type SelectProps = {
  value?: SelectValueType;
  onChange?: (value: SelectValueType) => void;
  options?: { label: React.ReactNode, value: string | number | boolean, disabled?: boolean }[],
  placeholder?: string;
  mode?: 'multiple' | 'tags';
  showArrow?: boolean;
  allowClear?: boolean;
  showSearch?: boolean;
  isFilterOption?: boolean,
  onBlur?: FocusEventHandler<HTMLElement>,
  onSearch?: StringFunctionType,
  searchValue?: string,
  onClear?: VoidFunctionType,
  onSelect?: VoidFunctionType,
  className?: string;
  label?: string;
  error?: string;
  disabled?: boolean;
  status?: 'error' | 'warning',
  fieldRef?: React.MutableRefObject<null>;
  dark?: boolean;
  notFoundContent?: React.ReactNode;
  defaultOpen?: boolean;
  onDeselect?: (value: string | number | boolean | LabeledValue) => void,
  onDropdownVisibleChange?: (open: boolean) => void,
  getPopupContainer?: (props: CustomAny) => HTMLElement,
  parentRender?: boolean,
  maxTagCount?: number | 'responsive',
  loading?: boolean,
}

const Select: React.FC<SelectProps> = ({
  value,
  onChange,
  options,
  placeholder,
  mode,
  showArrow,
  allowClear,
  showSearch,
  onSearch,
  searchValue,
  onBlur,
  isFilterOption,
  onSelect,
  onClear,
  className,
  label,
  error,
  disabled,
  status,
  fieldRef,
  dark,
  notFoundContent,
  defaultOpen,
  onDeselect,
  onDropdownVisibleChange,
  getPopupContainer,
  parentRender,
  maxTagCount,
  loading,
}) => {
  const ref = useRef(null);
  return (
    <div className={classnames(className, 'select', { 'select--dark': dark })}>
      {label && <span className="select-label">{label}</span>}
      <SelectComponent
        ref={fieldRef || ref}
        status={status}
        mode={mode}
        showArrow={showArrow}
        allowClear={allowClear}
        showSearch={showSearch}
        notFoundContent={notFoundContent}
        defaultOpen={defaultOpen}
        autoFocus={!!defaultOpen}
        filterOption={isFilterOption
        // eslint-disable-next-line
            // @ts-ignore
          ? (input, option) => (option?.children.toLowerCase() as unknown as string).includes(input.toLowerCase().trim())
          : false}
        style={{ width: '100%' }}
        placeholder={placeholder}
        value={value}
        onChange={(v) => {
          onChange && onChange(v);
          // eslint-disable-next-line
          // @ts-ignore
          mode !== 'multiple' && !defaultOpen && ref.current?.blur();
        }}
        onDeselect={(v: string | number | boolean | LabeledValue) => {
          // eslint-disable-next-line
          // @ts-ignore
          ref.current?.focus();
          onDeselect && onDeselect(v as boolean);
        }}
        onDropdownVisibleChange={onDropdownVisibleChange}
        onBlur={onBlur}
        onSelect={onSelect}
        onClear={onClear}
        onSearch={onSearch}
        className="select-field"
        disabled={disabled}
        searchValue={searchValue}
        getPopupContainer={parentRender ? (trigger) => trigger.parentElement : getPopupContainer}
        maxTagCount={maxTagCount}
        loading={loading}
      >
        {options?.map((o, i) => <Option key={`${o.value}-${o.label}-${i}`} value={o.value} disabled={o.disabled}>{o.label}</Option>)}
      </SelectComponent>
      {error && <span className="select-error">{error}</span>}
    </div>
  );
};

export default Select;
