export type AddNominalRecordFields = {
  recordType?: number,
  nominalCode?: number,
  net: string,
  vatPercent: number,
  vatAmount: string,
}

export const AddNominalRecordDefaults = {
  recordType: undefined,
  nominalCode: undefined,
  net: '0.00',
  vatPercent: 20,
  vatAmount: '0.00',
};
