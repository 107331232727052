import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const PricelistNameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 250, message: maxLengthMessageHandle(250) },
};

export type PricelistFields = {
  name: string,
  type: number | null,
  supplierId: number | string | null,
  customerIds: number[],
  businessAreas: number[],
  description?: string,
}

export const pricelistDefaults: PricelistFields = {
  name: '',
  type: null,
  supplierId: null,
  customerIds: [],
  businessAreas: [],
  description: undefined,
};

type Pricelist = Pick<PricelistFields, 'name' | 'businessAreas'>
export type CreatePricelistRequest = Pricelist & {
  type: number,
  supplierId: number,
  customerIds: number[] | null,
}
