import React, { useState } from 'react';
import CustomerDetails from './CustomerDetails';
import CustomerActivity from './CustomerActivity';
import './CustomerDetailsActivity.scss';
import RequisiteModal from '../../../../../common/components/requisite/RequisteModal';
import { useAppSelector } from '../../../../../store/hooks';
import {
  customerDetailsRequisitesSetSelector, customerDetailsSelector,
} from '../../../../../store/selectors/customersSelectors';
import { useCustomerRequisite } from '../../../hooks/useCustomerRequisite';

const CustomerDetailsAndActivity: React.FC = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [isShippingMode, setIsShippingMode] = useState(false);
  const [isSame, setIsSame] = useState(true);
  const customerDetails = useAppSelector(customerDetailsSelector);
  const { billing, shipping, isShippingSame } = useAppSelector(customerDetailsRequisitesSetSelector);

  const cancelHandle = () => {
    setIsVisible(false);
    setIsShippingMode(false);
    isShippingSame && setIsSame(true);
  };

  const { editRequisiteHandle } = useCustomerRequisite(
    isShippingMode,
    cancelHandle,
    customerDetails?.customer.id,
  );

  return (
    <>
      <RequisiteModal
        isVisible={isVisible}
        onCancel={cancelHandle}
        isShipping={isShippingMode}
        requisite={isShippingMode ? shipping : billing}
        submitHandle={editRequisiteHandle}
        isOptionalFields
      />
      <div className="detailsActivityWrap info-grid">
        <CustomerDetails
          setIsVisible={setIsVisible}
          setIsShippingMode={setIsShippingMode}
          isSame={isSame}
          setIsSame={setIsSame}
          customerDetails={customerDetails}
        />
        <CustomerActivity />
      </div>
    </>
  );
};

export default CustomerDetailsAndActivity;
