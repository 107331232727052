import React, { useState } from 'react';
import { EnquiryEventDtoBase } from '../../../../types/enquiryDetailsTypes';
import QuoteLinesGroupsModal from '../modals/QuoteLinesGroupsModal';
import Button from '../../../../../../core/components/button/Button';

type QuoteLineCreationEventProps = {
  item: EnquiryEventDtoBase;
}

const QuoteLineCreationEvent: React.FC<QuoteLineCreationEventProps> = ({ item }) => {
  const [linesModal, setLinesModal] = useState(false);
  return (
    <>
      <QuoteLinesGroupsModal isVisible={linesModal} setIsVisible={setLinesModal} item={item} />
      Quote lines for
      {' '}
      <b>{item.vehicleTabName}</b>
      {' '}
      were updated
      {' '}
      <Button
        label="(see details)"
        onClick={() => setLinesModal(true)}
        type="text"
      />
    </>
  );
};

export default QuoteLineCreationEvent;
