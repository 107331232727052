import { useSearchParams } from 'react-router-dom';
import { StockPartRequestViewEnum } from '../enums/stockPartRequestsEnum';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  stockPartRequestDashboardSelector,
  stockPartRequestsFiltersSelector,
  stockPartRequestsLinesViewFiltersSelector,
  stockPartRequestsLinesViewTableSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import {
  setStockPartRequestsFilters,
  setStockPartRequestsLinesViewFilters,
} from '../../../store/slices/stockPartRequestSlice';

export const useStockPartRequestsPaging = () => {
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const currentView = params.get('view');
  const stockPartRequestsFilters = useAppSelector(stockPartRequestsFiltersSelector);
  const linesViewFilters = useAppSelector(stockPartRequestsLinesViewFiltersSelector);
  const { totalCount } = useAppSelector(stockPartRequestDashboardSelector);
  const { totalCount: linesViewTotalCount } = useAppSelector(stockPartRequestsLinesViewTableSelector);

  const isRequestsView = currentView === StockPartRequestViewEnum.REQUESTS;

  const currentPaging = isRequestsView
    ? { page: stockPartRequestsFilters.page, pageSize: stockPartRequestsFilters.pageSize }
    : { page: linesViewFilters.page, pageSize: linesViewFilters.pageSize };

  const currentTotalCount = isRequestsView ? totalCount : linesViewTotalCount;

  const pageChangeHandle = (page: number, pageSize: number) => {
    if (isRequestsView) {
      dispatch(setStockPartRequestsFilters({ ...stockPartRequestsFilters, page, pageSize }));
    } else {
      dispatch(setStockPartRequestsLinesViewFilters({ ...linesViewFilters, page, pageSize }));
    }
  };

  return {
    currentPaging,
    currentTotalCount,
    pageChangeHandle,
  };
};
