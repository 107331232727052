import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faDollyFlatbed,
  faEllipsisVertical,
  faGripHorizontal, faTag,
} from '@fortawesome/free-solid-svg-icons';
import { faMap, faObjectUngroup } from '@fortawesome/free-regular-svg-icons';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import {
  editReadyCollectedColumnsSelector,
  editReadyCollectedModalFiltersSelector, editReadyCollectedModalQuantitiesSelector,
  editReadyCollectedTableLinesSelector,
} from '../../../../../store/selectors/stockPartRequestsSelector';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import { TruncateTooltip } from '../../../../../common/components/truncate-tooltip/TruncateTooltip';
import Tbody from '../../../../../core/components/table-components/Tbody';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import { setEditReadyCollectedModalFilters } from '../../../../../store/slices/stockPartRequestSlice';
import { PartRequestLinePriceSources } from '../../../../../orders/orders-view-page/enums/orderViewPageEnums';
import { partQtyStatusesValuesSelector } from '../../../../../store/selectors/coreStatusesSelectors';

type ActionMode = 'split' | 'allocate' | 'ready' | 'collect' | 'edit-location' | 'reserved' | string;

type EditReadyCollectedTableProps = {
  setConfirmEditMode: (v: ActionMode, partQuantityId: number) => void,
  className?: string,
  currentUnitOfMeasure: string,
}

const EditReadyCollectedTable: React.FC<EditReadyCollectedTableProps> = ({ setConfirmEditMode, className, currentUnitOfMeasure }) => {
  const dispatch = useAppDispatch();
  const editReadyCollectedColumns = useAppSelector(editReadyCollectedColumnsSelector);
  const editReadyCollectedModalQuantities = useAppSelector(editReadyCollectedModalQuantitiesSelector);
  const editReadyCollectedLines = useAppSelector(editReadyCollectedTableLinesSelector);
  const filters = useAppSelector(editReadyCollectedModalFiltersSelector);
  const { allocated, picked, collected } = useAppSelector(partQtyStatusesValuesSelector);

  const totalLinesCount = editReadyCollectedModalQuantities?.partQuantities?.totalCount;

  const actionHandle = (v: ActionMode, partQuantityId: number) => {
    setConfirmEditMode(v, partQuantityId);
  };

  const actions = (partQuantityId: number) => {
    const currentLine = editReadyCollectedLines.find((el) => el.id === partQuantityId);
    const currentLineSource = currentLine?.priceSource;
    const isSourcePricelist = currentLineSource === PartRequestLinePriceSources.Pricelist;

    const list = [
      {
        label: 'Split quantity',
        key: 'splitQty',
        icon: <FontAwesomeIcon icon={faObjectUngroup} />,
        onClick: () => actionHandle('split', partQuantityId),
        allowedStatuses: [allocated, picked, collected],
        disabled: editReadyCollectedLines.find((el) => el.id === partQuantityId)?.quantity === 1,
      },
      {
        label: 'Edit location',
        key: 'editLocationComment',
        icon: <FontAwesomeIcon icon={faMap} />,
        onClick: () => actionHandle('edit-location', partQuantityId),
        allowedStatuses: [picked, collected],
      },
      {
        label: 'Mark as Allocated',
        icon: <FontAwesomeIcon icon={faGripHorizontal} />,
        key: 'markAsAllocated',
        onClick: () => actionHandle('allocate', partQuantityId),
        allowedStatuses: [picked, collected],
      },
      {
        label: 'Mark as Ready',
        icon: <FontAwesomeIcon icon={faCheckCircle} />,
        key: 'markAsReady',
        onClick: () => actionHandle('ready', partQuantityId),
        allowedStatuses: [allocated, collected],
      },
      {
        label: 'Mark as Collected',
        icon: <FontAwesomeIcon icon={faDollyFlatbed} />,
        key: 'markAsCollected',
        onClick: () => actionHandle('collect', partQuantityId),
        allowedStatuses: [allocated, picked],
      },
      {
        label: 'Mark as Reserved',
        icon: <FontAwesomeIcon icon={faTag} />,
        key: 'markAsReserved',
        onClick: () => actionHandle('reserved', partQuantityId),
        allowedStatuses: [allocated],
      },
    ];
    const filteredListByStatus = list.filter((act) => filters.statuses?.length && act.allowedStatuses.includes(filters.statuses[0]));
    const listWithoutPricelistAction = filteredListByStatus.filter((el) => el.key !== 'markAsReserved');
    return !!filters.statuses?.find((el) => el === allocated) && isSourcePricelist
      ? filteredListByStatus
      : listWithoutPricelistAction;
  };
  const truncateValues = ['purchaseOrderNumber', 'locationComment', 'owner'];
  const nowrapValues = ['number', 'priceSource', 'quantity', 'costPrice', 'addedDate', 'purchaseOrderNumber'];
  const renderTdData = (key: string, value: number | string) => {
    if (truncateValues.includes(key)) {
      return <TruncateTooltip
        value={value as string}
        table
      />;
    }
    if (key === 'quantity') {
      return `${value} ${currentUnitOfMeasure}`;
    } else return value;
  };

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      dispatch(setEditReadyCollectedModalFilters({
        ...filters,
        order: { ...filters.order, isAsc: !filters.order?.isAsc },
      }));
    } else {
      dispatch(setEditReadyCollectedModalFilters({
        ...filters,
        order: { field, isAsc: true },
      }));
    }
  };
  const columnsFilters = [
    {
      dataIndex: 'purchaseOrderNumber',
      value: filters.purchaseOrderIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setEditReadyCollectedModalFilters({
        ...filters,
        purchaseOrderIds: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
    {
      dataIndex: 'owner',
      value: filters.ownerIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setEditReadyCollectedModalFilters({
        ...filters,
        ownerIds: checkedValues.length > 0 ? checkedValues as number[] : undefined,
      })),
    },
  ];
  return (
    <TableNew className={className}>
      <Thead>
        <Tr>
          {editReadyCollectedColumns.map((col) => (
            <ThWithControls
              key={col.dataIndex}
              col={col}
              totalElements={totalLinesCount || 0}
              className={`editReadyCollectedTh--${col.dataIndex}`}
              sortHandle={sortHandle}
              disabled={!totalLinesCount && totalLinesCount === 0}
              tableFilters={filters}
              filters={columnsFilters}
              insideModal
            />
          ))}
          <ThNew action><span className="sr-only">Actions</span></ThNew>
        </Tr>
      </Thead>
      <Tbody>
        {editReadyCollectedLines.length
          ? <>
            {
              editReadyCollectedLines.map((cell, i: number) => <Tr key={i}>
                {Object.entries(cell).map(([key, value]) => {
                  if (key === 'id') return null;
                  return <TdNew key={key} className={nowrapValues.includes(key) ? 'nowrap' : ''}>
                    {renderTdData(key, value)}
                  </TdNew>;
                })}
                <TdNew action>
                  <Dropdown
                    icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                    menuItems={actions(cell.id)}
                  />
                </TdNew>
              </Tr>)
            }
          </>
          : <EmptyTableSection colSpan={editReadyCollectedColumns.length + 1} text="No records" />}
      </Tbody>
    </TableNew>
  );
};

export default EditReadyCollectedTable;
