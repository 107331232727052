import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  BooleanFunctionType, infiniteScrollInitPaging, initDataListWithIds,
} from '../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setGenerateEmailOrderContacts, setGenerateEmailOrderContactsPaging } from '../../../../../store/slices/ordersSlice';
import {
  orderGenerateEmailContactsPagingSelector,
  orderGenerateEmailContactsSelector,
} from '../../../../../store/selectors/ordersSelector';
import { getGenerateEmailOrderContactsThunk } from '../../../../../store/thunks/orders/orderContactsThunks';
import GenerateEmailModal from '../../../../../common/components/generate-email-modal/GenerateEmailModal';
import { generateOrderEmailPdfThunk } from '../../../../../store/thunks/orders/viewPageOrderThunks';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

type OrderGenerateEmailModalProps = {
  visible: boolean,
  setVisible: BooleanFunctionType,
}
const OrderGenerateEmailModal: React.FC<OrderGenerateEmailModalProps> = ({ visible, setVisible }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const paging = useAppSelector(orderGenerateEmailContactsPagingSelector);
  const contacts = useAppSelector(orderGenerateEmailContactsSelector);
  const [emailHref, setEmailHref] = useState('');

  const {
    checkedKeys,
    checkAllHandle,
    uncheckAllHandle,
    checkRowHandle,
  } = useSelectAll(contacts.allItemIds);

  const onCancel = () => {
    setVisible(false);
    dispatch(setGenerateEmailOrderContacts(initDataListWithIds));
    dispatch(setGenerateEmailOrderContactsPaging(infiniteScrollInitPaging));
  };

  const loadMore = () => {
    dispatch(setGenerateEmailOrderContactsPaging({ ...paging, page: paging.page + 1 }));
  };

  const selectHandle = () => {
    const selectedContacts = contacts.items
      .filter(({ id }) => checkedKeys.includes(id))
      .map((el) => ({ id: el.id, email: el.email || '', name: `${el.firstName} ${el.lastName}` }));
    id && dispatch(generateOrderEmailPdfThunk({
      id: +id,
      selectedContacts,
      closeModal: onCancel,
      setEmailHref,
    }));
  };

  useEffect(() => {
    if (visible && id) {
      dispatch(getGenerateEmailOrderContactsThunk({
        orderId: +id,
        ...paging,
        haveEmail: true,
      }));
    }
    // eslint-disable-next-line
  }, [visible, paging]);

  return (
    <GenerateEmailModal
      visible={visible}
      onCancel={onCancel}
      checkedKeys={checkedKeys}
      checkAll={checkAllHandle}
      uncheckAll={uncheckAllHandle}
      checkLine={checkRowHandle}
      loadMore={loadMore}
      selectHandle={selectHandle}
      dataLength={contacts.items.length}
      hasMore={paging.page < contacts.pages}
      contactsList={contacts.items}
      emailHref={emailHref}
      setEmailHref={setEmailHref}
    />
  );
};

export default OrderGenerateEmailModal;
