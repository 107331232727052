import React, { useState } from 'react';
import { initDataList, initSmallPaging } from '../../../core/types/coreTypes';
import ExistedVehicleCatalogSection from '../../../common/components/vehicle-modal/ExistedVehicleCatalogSection';
import ExistedChosenVehicleForm from '../../../common/components/vehicle-modal/ExistedChosenVehicleForm';
import Modal from '../../../core/components/modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  createVehicleProfilesFiltersSelector, createVehicleProfilesUISelector,
} from '../../../store/selectors/vehiclesSelectors';
import { setCreateVehicleProfilesFilters, setVehicleProfiles } from '../../../store/slices/vehiclesSlice';
import { VehicleProfileSearchType } from '../../vehicle-profiles/types/vehicleProfilesTypes';
import { getVehicleProfiles } from '../../../store/thunks/vehicles/vehiclesProfilesThunk';
import EmptySelectedVehicleProfile from '../../../common/components/vehicle-modal/EmptySelectedVehicleProfile';
import { CreateCatalogMode } from '../types/createVehicleTypes';

type CreateVehicleCatalogFlowProps = {
  disabled?: boolean,
  setChosenVehicleProfile: (v: VehicleProfileSearchType | null) => void,
  chosenVehicleProfile: VehicleProfileSearchType | null,
  catalogMode: CreateCatalogMode,
  setCatalogMode: (v: CreateCatalogMode) => void,
}

const CreateVehicleCatalogFlow: React.FC<CreateVehicleCatalogFlowProps> = ({
  disabled, setChosenVehicleProfile, chosenVehicleProfile, catalogMode, setCatalogMode,
}) => {
  const dispatch = useAppDispatch();
  const [isSearchMode, setSearchMode] = useState(false);
  const vehicleProfiles = useAppSelector(createVehicleProfilesUISelector);
  const createVehicleProfilesFilters = useAppSelector(createVehicleProfilesFiltersSelector);

  const selectAnotherProfileHandle = () => {
    setSearchMode(true);
  };

  return (
    <>
      <Modal
        visible={isSearchMode}
        onCancel={() => {
          setSearchMode(false);
          dispatch(setVehicleProfiles({ ...initDataList, groups: null }));
          dispatch(setCreateVehicleProfilesFilters({ ...initSmallPaging }));
        }}
        title="Select vehicle profile"
        width={950}
        destroyOnClose
      >
        <ExistedVehicleCatalogSection
          dataList={vehicleProfiles.items}
          groups={vehicleProfiles.groups || []}
          totalCount={vehicleProfiles.totalCount}
          filters={createVehicleProfilesFilters}
          setFilters={(v) => dispatch(setCreateVehicleProfilesFilters(v))}
          setDefaultList={() => dispatch(setVehicleProfiles({ ...initDataList, groups: null }))}
          fetchProfiles={(filters) => dispatch(getVehicleProfiles({ filters }))}
          setChosenVehicleProfile={(veh) => {
            setChosenVehicleProfile(veh);
            setCatalogMode('found');
            setSearchMode(false);
            dispatch(setVehicleProfiles({ ...initDataList, groups: null }));
            dispatch(setCreateVehicleProfilesFilters({ ...initSmallPaging }));
          }}
        />
      </Modal>
      {!catalogMode
        ? <EmptySelectedVehicleProfile
          onClick={() => setSearchMode(true)}
          className="details-form__field--lg"
          bordered
          disabled={disabled}
        />
        : <ExistedChosenVehicleForm
          chosenVehicleProfile={chosenVehicleProfile}
          selectAnotherProfile={selectAnotherProfileHandle}
          className="details-form__field--lg"
        />}
    </>
  );
};

export default CreateVehicleCatalogFlow;
