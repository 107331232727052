import React from 'react';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Segmented from '../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { useReportsParams } from '../../hooks/useReportsParams';
import ReportsGlobalFiltersContent from './ReportsGlobalFiltersContent';

const ReportsFilters: React.FC = () => {
  const { windowWidth } = useScreenWitdh();

  const {
    mode,
    setModeParam,
    allowedModes,
    report,
    setReportParam,
    allowedReports,
  } = useReportsParams();

  return (
    <div className="desktop-sticky-filters desktop-sticky-filters--top-sm mobile-sticky-filters mobile-sticky-filters--top-sm">
      {allowedModes.length > 0 && (
        <>
          <div className="reportFilters__head">
            {windowWidth && windowWidth <= responsiveEndpointsEnum.LG
              ? <Selectable
                options={allowedModes}
                value={mode}
                onChange={(value) => setModeParam(value as string)}
                parentRender={false}
              />
              : <Segmented
                options={allowedModes}
                value={mode || ''}
                onChange={(value) => setModeParam(value as string)}
              />}
            <Selectable
              options={allowedReports}
              value={report}
              onChange={(value) => setReportParam(value as string)}
              className="reportFilters__report-select"
            />
          </div>
          <div className="reportFilters__filters-wrapper">
            <ReportsGlobalFiltersContent />
          </div>
        </>
      )}
    </div>
  );
};

export default ReportsFilters;
