import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faUsersLine, faPhoneAlt } from '@fortawesome/free-solid-svg-icons';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { EmployeeListItemDto } from '../types/employeesDashTypes';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../core/enums/pathEnum';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

type EmployeeItemProps = {
  employee: EmployeeListItemDto,
}

const EmployeeItem: React.FC<EmployeeItemProps> = ({ employee }) => {
  return (
    <PrivateLineLink
      to={`/${routesPath.EMPLOYEES}/${employee.id}`}
      permission={PermissionEnum.EmployeeViewPage}
      className="employeeItem"
    >
      <span className="employeeItem__title">
        <FontAwesomeIcon
          icon={faUserCircle}
          className="employeeItem__icon"
        />
        <TruncateTooltip
          value={`${employee.title ? `${employee.title} ` : ''}${employee.fullName}`}
          className="employeeItem__name"
        />
        <TruncateTooltip value={employee.jobTitle} className="employeeItem__position" />
      </span>
      <span className="employeeItem__data">
        <FontAwesomeIcon
          icon={faEnvelope}
          className="employeeItem__data-icon"
        />
        <TruncateTooltip className="employeeItem__data-value" value={employee.email} />
      </span>
      <span className="employeeItem__data">
        <FontAwesomeIcon
          icon={faPhoneAlt}
          className="employeeItem__data-icon"
        />
        <TruncateTooltip className="employeeItem__data-value" value={employee.contactInfo} />
      </span>
      <span className="employeeItem__department">
        <FontAwesomeIcon
          icon={faUsersLine}
          className="employeeItem__data-icon employeeItem__data-icon--department"
        />
        <span>{employee.department}</span>
      </span>
    </PrivateLineLink>
  );
};

export default EmployeeItem;
