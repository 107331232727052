import React from 'react';
import Modal from '../../../../../core/components/modal/Modal';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import Button from '../../../../../core/components/button/Button';
import './DeleteBlockedModal.scss';

type DeleteBlockedModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  partName?: string | null,
  total?: number | null,
  unitOfMeasure?: string,
}

const DeleteBlockedModal:React.FC<DeleteBlockedModalProps> = ({
  visible, onCancel, partName, total, unitOfMeasure,
}) => {
  return (
    <Modal
      title="Delete action is blocked"
      visible={visible}
      onCancel={onCancel}
      className="deleteBlockedModal"
      destroyOnClose
    >
      <div className="deleteBlockedModal__content">
        <p className="deleteBlockedModal__text">
          <span className="deleteBlockedModal__name">{`"${partName || '-'}" `}</span>
          {`part record cannot be deleted, because there are still some items in stock (${total} ${unitOfMeasure}).`}
        </p>
        <Button
          htmlType="button"
          onClick={onCancel}
          label="Close"
          designType="light-inverse"
        />
      </div>
    </Modal>
  );
};

export default DeleteBlockedModal;
