import React, { useState } from 'react';
import InfoCircleOutlined from '@ant-design/icons/lib/icons/InfoCircleOutlined';
import { Tooltip } from 'antd';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import SubmitCustomLinesModal from './SubmitCustomLinesModal';
import DiscardSubmittedPartReqChanges from './DiscardSubmittedPartReqChanges';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import Button from '../../../core/components/button/Button';
import { PartReqLineUIType } from '../../types/commonTypes';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector } from '../../../store/selectors/coreSelectors';

export type CustomPartReqLineType = Omit<PartReqLineUIType, 'childLines'>;

type PartRequestSubmissionButtonsProps = {
  submitRequestHandle: (checkedCells: number[] | null, onCancelHandle: VoidFunctionType) => void,
  discardRequestChangesHandle: (cancelHandle: VoidFunctionType) => void,
  isPartReqSubmitted?: boolean,
  lastSubmittedDate?: string | null,
  isSubmittedStatus: boolean,
  isSubmittedWithChangesStatus?: boolean,
  isSubmitBtnDisabled: boolean,
  submissionStatusLabel?: string,
  customLines?: CustomPartReqLineType[],
}

const PartRequestSubmissionButtons: React.FC<PartRequestSubmissionButtonsProps> = ({
  discardRequestChangesHandle, submitRequestHandle, isPartReqSubmitted,
  isSubmitBtnDisabled, isSubmittedStatus, isSubmittedWithChangesStatus,
  submissionStatusLabel, lastSubmittedDate, customLines,
}) => {
  const isFetching = useAppSelector(isFetchingSelector);
  const [isCustomLinesSubmitModal, setCustomLinesSubmitModal] = useState(false);
  const [isDiscardModal, setDiscardModal] = useState(false);

  return (
    <>
      <SubmitCustomLinesModal
        visible={isCustomLinesSubmitModal}
        onCancel={() => setCustomLinesSubmitModal(false)}
        allCustomLines={customLines || []}
        submitRequestHandle={submitRequestHandle}
      />
      <DiscardSubmittedPartReqChanges
        isVisible={isDiscardModal}
        onCancel={() => setDiscardModal(false)}
        confirmHandler={() => discardRequestChangesHandle(() => setDiscardModal(false))}
      />
      <div className="partRequestSubmissionButtons">
        {isPartReqSubmitted && <Tooltip
          title={`${submissionStatusLabel} on ${localDateFormatHandler('DD-MMM-YYYY', (lastSubmittedDate || undefined))}`}
          mouseEnterDelay={0}
          mouseLeaveDelay={0}
          overlayClassName="partRequestSubmissionButtons__info-tooltip"
        >
          <InfoCircleOutlined className="partRequestSubmissionButtons__info-icon" />
        </Tooltip>}
        {!isSubmittedStatus && <Button
          label={isSubmittedWithChangesStatus ? 'Submit changes' : 'Submit request'}
          onClick={() => {
            if (!customLines || customLines.length === 0) {
              submitRequestHandle(null, () => setCustomLinesSubmitModal(false));
            } else {
              setCustomLinesSubmitModal(true);
            }
          }}
          disabled={isSubmitBtnDisabled}
          designType="dark"
          isLoading={isFetching}
        />}
        {isSubmittedWithChangesStatus && <Button
          label="Discard changes"
          onClick={() => setDiscardModal(true)}
          disabled={isSubmitBtnDisabled}
          isLoading={isFetching}
        />}
      </div>
    </>
  );
};

export default PartRequestSubmissionButtons;
