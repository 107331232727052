import React, { useEffect, useMemo, useState } from 'react';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import CreatePurchaseOrderForm from './components/CreatePurchaseOrderForm';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getSupplierList } from '../../../store/thunks/suppliers/supplierThunks';
import { suppliersLookupSelector } from '../../../store/selectors/supplierSelector';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { initRequisite } from '../../../common/utils/data';
import { AddressFields, initAddressFields, RequisiteDto } from '../../../common/types/commonTypes';
import RequisiteModal from '../../../common/components/requisite/RequisteModal';
import AddressModal from '../../../common/components/address/AddressModal';
import { usersFilterLookupSelector } from '../../../store/selectors/coreSelectors';
import TruncateDropdownLabel from '../../../common/components/truncate-dropdown-label/TruncateDropdownLabel';
import { getMaxPurchaseOrderNumberThunk } from '../../../store/thunks/stock/purchase-orders/createPurchaseOrderThunks';
import { setPurchaseOrderMaxNumber } from '../../../store/slices/purchaseOrderSlice';
import { purchaseOrderMaxNumberSelector } from '../../../store/selectors/purchaseOrderSelectors';
import { getDefaultPurchaseOrderDeliverAddress } from '../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { useUsersDictionary } from '../../../common/hooks/useUsersDictionary';
import { getSupplierStatusesThunk } from '../../../store/thunks/core/coreStatusesThunks';

const CreatePurchaseOrderContainer: React.FC = () => {
  const { backHandle } = useLocationHistoryState();
  const dispatch = useAppDispatch();
  const suppliersList = useAppSelector(suppliersLookupSelector);
  const usersLookup = useAppSelector(usersFilterLookupSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const purchaseOrderMaxNumber = useAppSelector(purchaseOrderMaxNumberSelector);

  const { setUserSearch } = useUsersDictionary();

  const [requisiteModal, setRequisiteModal] = useState(false);
  const [requisiteFields, setRequisiteFields] = useState<RequisiteDto>(initRequisite);
  const [isManual, setIsManual] = useState(false);
  const [requisiteSupplierId, setRequisiteSupplierId] = useState<number | undefined>(undefined);
  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields | null>(null);

  const suppliersUi = useMemo(() => suppliersList.map((el) => ({
    value: el.value,
    label: <TruncateDropdownLabel value={el.label} icon={el.icon} />,
  })), [suppliersList]);

  useEffect(() => {
    dispatch(getSupplierList({ filters: undefined }));
    dispatch(getSupplierStatusesThunk());
    dispatch(getMaxPurchaseOrderNumberThunk());
    return () => {
      dispatch(setPurchaseOrderMaxNumber(null));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout subHeader={<DetailsHead goBack={() => backHandle()} isCreatePage>
      <div className="createHead">Create new purchase order</div>
    </DetailsHead>}
    >
      <RequisiteModal
        isVisible={requisiteModal}
        onCancel={() => setRequisiteModal(false)}
        submitHandle={(values, isManual, selectedEntityId) => {
          setRequisiteFields(values);
          setIsManual(isManual);
          setRequisiteSupplierId(selectedEntityId);
          setRequisiteModal(false);
        }}
        isOptionalFields
        requisite={requisiteFields}
        isManual={isManual}
        entitySourceType="supplier"
        entityId={requisiteSupplierId}
      />
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
          setAddressFields(initAddressFields);
        }}
        onSubmit={(values: AddressFields) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        addressFields={addressFields}
        title="Deliver to"
        isDefaultOptionAvailable
        setDefaultAddressFields={() => dispatch(getDefaultPurchaseOrderDeliverAddress({
          setAddressFields,
        }))}
      />
      <CreatePurchaseOrderForm
        suppliersUi={suppliersUi}
        usersLookup={usersLookup}
        currentUser={currentUser}
        requisiteFields={requisiteFields}
        setRequisiteFields={setRequisiteFields}
        setRequisiteModal={setRequisiteModal}
        setUserSearch={setUserSearch}
        setAddressModal={setAddressModal}
        addressFields={addressFields}
        setAddressFields={setAddressFields}
        purchaseOrderNumber={purchaseOrderMaxNumber}
        isManual={isManual}
        requisiteSupplierId={requisiteSupplierId}
        setRequisiteSupplierId={setRequisiteSupplierId}
      />
    </Layout>
  );
};

export default CreatePurchaseOrderContainer;
