import React from 'react';
import classNames from 'classnames';
import Button from '../../../core/components/button/Button';
import { useAppDispatch } from '../../../store/hooks';
import { getPrivacyPolicyThunk } from '../../../store/thunks/coreThunk';
import './PrivacyPolicyButton.scss';

type PrivacyPolicyButtonProps = {
  className?: string,
}

const PrivacyPolicyButton: React.FC<PrivacyPolicyButtonProps> = ({ className }) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(getPrivacyPolicyThunk());
  };

  return <Button
    label="Privacy policy"
    type="link"
    onClick={handleClick}
    className={classNames('privacyPolicyButton', className)}
  />;
};

export default PrivacyPolicyButton;
