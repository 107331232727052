import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../store/hooks';
import ResetPasswordForm from './components/ResetPasswordForm';
import { validateResetTokenThunk } from '../../store/thunks/account/accountThunks';

const ResetPasswordContainer:React.FC = () => {
  const { token } = useParams();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState<string>('');
  useEffect(() => {
    token && dispatch(validateResetTokenThunk({ token, setEmail }));
    // eslint-disable-next-line
  }, [token]);
  return (
    <>
      {email && <ResetPasswordForm
        token={token}
        email={email}
      />}
    </>
  );
};

export default ResetPasswordContainer;
