import React from 'react';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  reportIsVisibleSelector,
  unfinishedEntitiesChartDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import Chart from '../../../../../../core/components/chart/Chart';

const SalesUnfinishedEntitiesChart: React.FC = () => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const { series, categories, entities } = useAppSelector(unfinishedEntitiesChartDataSelector);

  return (
    <Chart
      reportIsVisible={reportIsVisible}
      type="bar"
      series={series}
      height={550}
      options={{
        chart: { type: 'bar' },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '70%',
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent'],
        },
        xaxis: { categories, position: 'bottom' },
        fill: {
          opacity: 1,
        },
        tooltip: {
          enabled: true,
          custom: ({
            series, seriesIndex, dataPointIndex, w,
          }) => {
            return '<div class="customBarTooltip">'
                  + `<span class="customBarTooltip__entity">
                      ${entities.find((ent) => ent.customerName === w.globals.labels[dataPointIndex])?.entityNo || ''}
                    </span>`
                  + `<span>Sales net: ${
                    series[seriesIndex][dataPointIndex]
                  }</span>`
                  + '</div>';
          },
        },
      }}
    />
  );
};

export default SalesUnfinishedEntitiesChart;
