import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum InvoicingUrlParamsNames {
  PAGE = 'page',
  TYPE = 'type',
}

export enum InvoicingViewsEnums {
  SALES = 'Sales',
  PURCHASES = 'Purchases',
  NOMINAL_CODES = 'Nominal codes',
}

export enum InvoicingPageParamsEnums {
  SALES = 'sales',
  PURCHASES = 'purchases',
  NOMINAL_CODES = 'nominal-codes',
}

export const InvoicingPageParams = [
  InvoicingPageParamsEnums.SALES,
  InvoicingPageParamsEnums.PURCHASES,
  InvoicingPageParamsEnums.NOMINAL_CODES,
];

export const InvoicingPageParamsAndPermissions = [
  { page: InvoicingPageParamsEnums.SALES, permission: PermissionEnum.InvoicingSales },
  { page: InvoicingPageParamsEnums.PURCHASES, permission: PermissionEnum.InvoicingPurchases },
  { page: InvoicingPageParamsEnums.NOMINAL_CODES, permission: PermissionEnum.InvoicingNominalCodes },
];
