import React from 'react';
import { InvoicingPageParamsEnums } from '../enums/InvoicingPageParamsEnums';
import NominalCodesPage from '../../nominal-codes/NominalCodesPage';
import InvoicingPurchases from '../../invoicing-purchases/dashboard/components/InvoicingPurchases';
import InvoicingSalesDashboard from '../../sales/dashboard/InvoicingSalesDashboard';

type InvoicingContentProps = {
  view: string | null,
}
const InvoicingContent: React.FC<InvoicingContentProps> = ({ view }) => {
  const contentRenderFn = () => {
    switch (view) {
      case InvoicingPageParamsEnums.SALES:
        return <InvoicingSalesDashboard />;
      case InvoicingPageParamsEnums.PURCHASES:
        return <InvoicingPurchases />;
      case InvoicingPageParamsEnums.NOMINAL_CODES:
        return <NominalCodesPage />;
      default: return null;
    }
  };
  return (
    <>
      {contentRenderFn()}
    </>
  );
};

export default InvoicingContent;
