import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import JobAttachmentsColumns from './JobAttachmentsColumns';
import { AttachmentsTableProps } from '../../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../../store/hooks';
import AttachmentsTable from '../../../../../common/components/attachments-table/AttachmentsTable';
import EditCommentModal from '../../../../../common/components/edit-attachment-comment/EditCommentModal';
import { editJobAttachment } from '../../../../../store/thunks/jobs/jobThunks';
import Tbody from '../../../../../core/components/table-components/Tbody';
import JobAttachmentCell from './JobAttachmentCell';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';

const JobAttachmentsTable: React.FC<AttachmentsTableProps> = ({
  attachments,
  checkedKeys,
  isAll,
  indeterminate,
  checkAllHandle,
  checkRow,
  readOnly,
  getAttachment,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isEditModal, setEditModal] = useState(false);
  const [attachToEdit, setAttachToEdit] = useState<number | undefined>(undefined);
  const [editedComment, setEditedComment] = useState('');

  const cancelHandle = () => {
    setEditModal(false);
    setEditedComment('');
    setAttachToEdit(undefined);
  };

  return (
    <>
      <EditCommentModal
        isVisible={isEditModal}
        onCancel={cancelHandle}
        editedComment={editedComment}
        setEditedComment={setEditedComment}
        editHandle={() => id && attachToEdit && dispatch(editJobAttachment({
          id: attachToEdit,
          jobId: +id,
          comment: editedComment,
          onCancel: cancelHandle,
        }))}
      />
      <AttachmentsTable>
        <JobAttachmentsColumns
          isAll={isAll}
          indeterminate={indeterminate}
          checkAll={checkAllHandle}
          totalElements={attachments.length}
          readOnly={readOnly}
          disabledActions={attachments.every(((el) => el.isFromLinkedEntity))}
        />
        <Tbody>
          {attachments.length > 0
            ? attachments.map((attachment) => <JobAttachmentCell
              key={attachment.id}
              attach={attachment}
              openEditModal={() => {
                setAttachToEdit(attachment.id);
                setEditedComment(attachment.comment || '');
                setEditModal(true);
              }}
              checked={checkedKeys?.includes(attachment.id)}
              checkRow={() => checkRow(attachment.id)}
              readOnly={readOnly}
              getAttachment={getAttachment}
            />)
            : <EmptyTableSection text="No records" colSpan={readOnly ? 4 : 6} />}
        </Tbody>
      </AttachmentsTable>
    </>
  );
};

export default JobAttachmentsTable;
