import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  CoreAttachListElType,
  CoreAttachmentsFilters,
  initialCoreAttachmentsFilters,
  ResponseSingleResult,
  VoidFunctionType,
  AxiosErrorResponse,
  GetAttachmentsResponse,
  emptyPaging,
  PatchAttachmentModel, BooleanFunctionType,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { ordersAPI } from '../../../api/ordersApi';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { setOrderAttachments, setOrdersAttachmentsFilter } from '../../slices/ordersSlice';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { getOrderById } from './viewPageOrderThunks';
import { RootState } from '../../store';

export const getOrderAttachmentsThunk = createAsyncThunk<GetAttachmentsResponse,
{
  orderId: number,
  filters: CoreAttachmentsFilters,
}
>(
  'get/OrderAttachments',
  async ({ orderId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.fetchOrderAttachments(orderId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createOrderAttachmentThunk = createAsyncThunk<
ResponseSingleResult<{ createdItems: Array<{ id: number }> }>,
{ id: number, list: CoreAttachListElType[], comment: string, onCancel: VoidFunctionType }
>('post/OrderAttachment', async ({
  id, comment, list, onCancel,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const { orders } = getState() as RootState;
    const { orderAttachmentsFilters } = orders;
    const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));
    const res = await ordersAPI.postOrderAttachmentUploadUri(id, uploadUriArr);
    const attachments = await uploadAttachmentsAsyncHandle(
      list,
      comment,
      res,
      dispatch,
    );
    const finalRes = await ordersAPI.postOrderAttachments(id, attachments);
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(getOrderById({ id }));
    dispatch(getOrderAttachmentsThunk({ orderId: id, filters: orderAttachmentsFilters }));
    onCancel();
    dispatch(setLoading(false));
    return finalRes.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});

export const deleteMultipleOrderAttachmentThunk = createAsyncThunk<null,
{
  ids: number[],
  orderId: number,
  closeModal: VoidFunctionType,
}>(
  'delete/MultipleOrderAttachments',
  async ({
    ids, orderId, closeModal,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { orders: { orderAttachmentsFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await ordersAPI.fetchOrderAttachments(orderId, {
        ...orderAttachmentsFilters, ...emptyPaging,
      });
      const filteredIds = ids.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await ordersAPI.deleteMultipleOrderAttachment(orderId, filteredIds);
      }
      dispatch(setOrdersAttachmentsFilter({ ...orderAttachmentsFilters, page: 1 }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getOrderById({ id: orderId }));
      closeModal();
      dispatch(setLoading(false));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteOrderAttachmentThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, orderId: number, closeModal: VoidFunctionType }>(
  'delete/SingleOrderAttachment',
  async ({
    id, orderId, closeModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.deleteOrderAttachment(orderId, id);
      dispatch(getOrderAttachmentsThunk({
        orderId,
        filters: initialCoreAttachmentsFilters,
      }));
      dispatch(setOrdersAttachmentsFilter(initialCoreAttachmentsFilters));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(getOrderById({ id: orderId }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editOrderAttachment = createAsyncThunk<
ResponseSingleResult,
{ id: number, orderId: number, data: PatchAttachmentModel, onCancel?: VoidFunctionType, setSwitchLoading?: BooleanFunctionType }
>('patch/OrderAttachment', async ({
  id, orderId, data, onCancel, setSwitchLoading,
}, { getState, dispatch, rejectWithValue }) => {
  const changeLoadingState = (value: boolean) => {
    setSwitchLoading ? setSwitchLoading(value) : dispatch(setLoading(value));
  };
  changeLoadingState(true);
  try {
    const { orders } = getState() as RootState;
    const { orderAttachments } = orders;
    const response = await ordersAPI.editOrderAttachment(orderId, id, data);
    const newArr = orderAttachments.items.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          comment: data.comment || item.comment,
          showInLinkedJobs: typeof data.showInLinkedJobs !== undefined ? data.showInLinkedJobs as boolean : item.showInLinkedJobs,
        };
      } else return item;
    });
    dispatch(setOrderAttachments(newArr));
    if (onCancel) {
      onCancel();
    }
    changeLoadingState(false);
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    changeLoadingState(false);
    return rejectWithValue(error.response?.data);
  }
});

export const getOrderAttachmentById = createAsyncThunk<
ResponseSingleResult<{ name: string, uri: string } | null>,
{ id: number, orderId: number }
>(
  'get/OrderAttachmentById',
  async ({ id, orderId }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await ordersAPI.getOrderAttachmentById(orderId, id);
      downloadCsv(response.data.data.uri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
