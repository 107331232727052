import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetSupplierResponse, SupplierDetails, SupplierDto, SuppliersFiltersType,
} from '../../supplier/dashboard/types/SuppliersTypes';
import {
  SupplierContactDto, SupplierEventDtoBase,
} from '../../supplier/supplier-view-page/types/supplierViewPageTypes';
import {
  CoreAttachmentsFilters,
  DataListType,
  PagingType,
  initDataList,
  initialCoreAttachmentsFilters,
  ResponseResult,
  infiniteScrollInitPaging, initPaging, CoreAttachmentsList, initDataListWithIds, GetAttachmentsResponse,
} from '../../core/types/coreTypes';
import {
  getSupplierByIdThunk,
  getSupplierTimelineThunk,
} from '../thunks/suppliers/suppliersViewPageThunks';
import { ExtendedTimelineFilters, initExtendedTimelineFilters } from '../../common/types/commonTypes';
import { getSupplierList } from '../thunks/suppliers/supplierThunks';
import { getSuppliersAttachments } from '../thunks/suppliers/suppliersAttachmentsThunks';
import { getSupplierContactsList } from '../thunks/suppliers/supplierContactsThunks';

interface ISupplierState {
  supplierFilers: SuppliersFiltersType,
  supplierList: DataListType<SupplierDto[]>,
  supplierDetails: SupplierDetails | null,
  supplierAttachments: CoreAttachmentsList,
  supplierAttachmentsFilters: CoreAttachmentsFilters,
  supplierContactsFilters: PagingType,
  supplierContactsList: DataListType<SupplierContactDto[]>,
  supplierActivityLogFilters: ExtendedTimelineFilters,
  supplierTimeline: DataListType<SupplierEventDtoBase[]>,
}

export const initialSupplierState: ISupplierState = {
  supplierFilers: initPaging,
  supplierList: initDataList,
  supplierDetails: null,
  supplierAttachments: initDataListWithIds,
  supplierAttachmentsFilters: initialCoreAttachmentsFilters,
  supplierContactsFilters: infiniteScrollInitPaging,
  supplierContactsList: initDataList,
  supplierActivityLogFilters: initExtendedTimelineFilters,
  supplierTimeline: initDataList,
};

export const supplierSlice = createSlice({
  name: 'supplier',
  initialState: initialSupplierState,
  reducers: {
    setSupplierDetails: (state, action:PayloadAction<SupplierDetails | null>) => {
      state.supplierDetails = action.payload;
    },
    setSupplierFilters: (state, action:PayloadAction<SuppliersFiltersType>) => {
      state.supplierFilers = action.payload;
    },
    setSuppliersList: (state, action: PayloadAction<DataListType<SupplierDto[]>>) => {
      state.supplierList = action.payload;
    },
    setSupplierAttachmentsFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.supplierAttachmentsFilters = action.payload;
    },
    setSupplierAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.supplierAttachments = action.payload;
    },
    setSupplierContactsFilters: (state, action: PayloadAction<PagingType>) => {
      state.supplierContactsFilters = action.payload;
    },
    setSuppliersContactsList: (state, action: PayloadAction<DataListType<SupplierContactDto[]>>) => {
      state.supplierContactsList = action.payload;
    },
    setSupplierActivityLogFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.supplierActivityLogFilters = action.payload;
    },
    setSupplierTimeline: (state, action: PayloadAction<DataListType<SupplierEventDtoBase[]>>) => {
      state.supplierTimeline = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getSupplierList.fulfilled, (state, action: PayloadAction<ResponseResult<SupplierDto[]>>) => {
      state.supplierList = action.payload?.data || initDataList;
    });
    // eslint-disable-next-line max-len
    builder.addCase(getSupplierByIdThunk.fulfilled, (state, action: PayloadAction<GetSupplierResponse>) => {
      state.supplierDetails = action.payload.data;
    });
    builder.addCase(
      getSuppliersAttachments.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.supplierAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getSupplierContactsList.fulfilled,
      (state, action: PayloadAction<ResponseResult<SupplierContactDto[]> | null>) => {
        if (action.payload) {
          const { items, pages, totalCount } = action.payload.data;
          state.supplierContactsList = {
            items: state.supplierContactsFilters.page > 1
              ? [...state.supplierContactsList.items, ...items]
              : items,
            pages,
            totalCount,
          };
        } else {
          state.supplierContactsList = initDataList;
        }
      },
    );
    builder.addCase(
      getSupplierTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<SupplierEventDtoBase[]> | null>) => {
        state.supplierTimeline = action.payload ? action.payload?.data : initDataList;
      },
    );
  },
});

export const {
  setSupplierDetails,
  setSupplierFilters,
  setSupplierAttachmentsFilters,
  setSupplierAttachments,
  setSupplierContactsFilters,
  setSuppliersList,
  setSuppliersContactsList,
  setSupplierActivityLogFilters,
  setSupplierTimeline,
} = supplierSlice.actions;

export default supplierSlice.reducer;
