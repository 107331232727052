import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { CreateCatalogMode } from '../types/createVehicleTypes';
import { GenericVoidFunctionType, NumberFunctionType } from '../../../core/types/coreTypes';
import { VehicleProfileSearchType } from '../../vehicle-profiles/types/vehicleProfilesTypes';
import { CreateVehicleFields } from '../types/createVehicleFormSchema';
import { useAppSelector } from '../../../store/hooks';
import { vehicleProfilesGroupsUiSelector } from '../../../store/selectors/sharedSelectors';
import { vehicleProfilesTypesUiSelector } from '../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../core/utils/labelHandle';

type LocationState = {
  orderId: number,
  customerId: number,
  infoSource: number,
  manufacturer: string | null,
  type: number,
  group: number,
  modelDescription: string | null,
  grossVehicleWeightKg: number | null,
  maxPayload: number | null,
  chassisNumber: string | null,
  regNumber: string | null,
  slidingDoorsQuantity: number,
  vehicleProfileId: number | null,
  vehicleProfileCode: string | null,
}

type UseCreateVehicleFromOrderParams = {
  setValue: UseFormSetValue<CreateVehicleFields>,
  setChosenVehicleProfile: GenericVoidFunctionType<VehicleProfileSearchType>
  setInfoSource: NumberFunctionType,
  setCatalogMode: GenericVoidFunctionType<CreateCatalogMode>,
}

export const useCreateVehicleFromOrder = (params: UseCreateVehicleFromOrderParams) => {
  const {
    setValue,
    setChosenVehicleProfile,
    setInfoSource,
    setCatalogMode,
  } = params;

  const location = useLocation();
  const state = location.state as LocationState | null;

  const vehicleProfilesGroups = useAppSelector(vehicleProfilesGroupsUiSelector);
  const vehicleProfilesTypes = useAppSelector(vehicleProfilesTypesUiSelector);

  useEffect(() => {
    if (state) {
      setInfoSource(state.infoSource);
      setValue('customerId', state.customerId);
      setValue('slidingDoorsQuantity', state.slidingDoorsQuantity);
      setValue('grossVehicleWeightKg', `${state.grossVehicleWeightKg}`);
      setValue('maxPayload', `${state.maxPayload}`);
      setValue('chassisNumber', state.chassisNumber || '');
      setValue('regNumber', state.regNumber);
      if (state.vehicleProfileId) {
        setCatalogMode('found');
        setChosenVehicleProfile({
          id: state.vehicleProfileId,
          code: state.vehicleProfileCode,
          manufacturer: state.manufacturer,
          group: labelHandle(state.group, vehicleProfilesGroups),
          modelDescription: state.modelDescription,
          type: labelHandle(state.type, vehicleProfilesTypes),
          maxPayload: state.maxPayload || 0,
          grossVehicleWeightKg: state.grossVehicleWeightKg || 0,
        });
      } else {
        setValue('manufacturer', state.manufacturer);
        setValue('type', state.type);
        setValue('group', state.group);
        setValue('modelDescription', state.modelDescription);
      }
      window.history.replaceState(null, '');
    }
    // eslint-disable-next-line
  }, [state]);

  return { orderId: state?.orderId };
};
