import { PermissionEnum } from '../../../core/enums/dictionariesEnums';

export enum DashboardViewsEnum {
  DASHBOARD = 'Dashboard',
  SEARCH = 'Search',
  SPECIFICATION = 'Specification',
  CREATE_ENQUIRY = 'Create new enquiry',
}

export enum EnquiryModesEnum {
  DASHBOARD = 'dashboard',
  SEARCH = 'search',
  SPECIFICATION = 'specification',
  CREATE_ENQUIRY = 'create-new-enquiry',
}

export const EnquiryModes = [
  EnquiryModesEnum.DASHBOARD, EnquiryModesEnum.SEARCH, EnquiryModesEnum.SPECIFICATION,
];

export const EnquiryPageParamsAndPermissions = [
  { page: EnquiryModesEnum.DASHBOARD, permission: PermissionEnum.EnquiryDashboardPage },
  { page: EnquiryModesEnum.SEARCH, permission: PermissionEnum.TestModule },
  { page: EnquiryModesEnum.SPECIFICATION, permission: PermissionEnum.TestModule },
];
