import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { responsiveEndpointsEnum } from '../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../core/components/selectable/Selectable';
import { purchaseTabModesOptions } from '../../../../stock/container/utils/data';
import Segmented from '../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../core/hooks/useScreenWidth';
import { InvoicingPageParamsEnums } from '../../enums/InvoicingPageParamsEnums';
import {
  PurchaseUrlParamsNames,
  StockTabParamEnum,
} from '../../../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { purchasesFiltersSelector } from '../../../../store/selectors/invoicingSelectors';
import { setPurchasesFilters } from '../../../../store/slices/invoicingSlice';
import {
  archivedOrderStatusSelector,
} from '../../../../store/selectors/purchaseOrderSelectors';
import Button from '../../../../core/components/button/Button';
import usePermission from '../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import SelectInvoicingEntitiesForExport
  from '../../../common/components/select-entities-for-export/SelectInvoicingEntitiesForExport';

const PurchasesFilter: React.FC = () => {
  const dispatch = useAppDispatch();
  const { windowWidth } = useScreenWitdh();
  const [params, setSearchParams] = useSearchParams();
  const page = params.get(PurchaseUrlParamsNames.PAGE);
  const tabParam = params.get(PurchaseUrlParamsNames.TAB_TYPE);
  const purchasesFilters = useAppSelector(purchasesFiltersSelector);
  const archivedOrderStatus = useAppSelector(archivedOrderStatusSelector);
  const [isExportToCsvModal, setIsExportToCsvModal] = useState(false);
  const allowedToExport = usePermission(PermissionEnum.InvoicingExportPurchaseInvoices);

  const setTabParam = (tab: string) => {
    page && setSearchParams({ page, [PurchaseUrlParamsNames.TAB_TYPE]: tab });
    if (tab === StockTabParamEnum.ACTIVE) {
      dispatch(setPurchasesFilters({
        ...purchasesFilters, statuses: undefined, page: 1, isActive: true,
      }));
    } else {
      dispatch(setPurchasesFilters({
        ...purchasesFilters, isActive: false, statuses: [archivedOrderStatus[0].value], page: 1,
      }));
    }
  };

  useEffect(() => {
    if (page === InvoicingPageParamsEnums.PURCHASES && !tabParam) {
      if (purchasesFilters.isActive) {
        setSearchParams({ page, [PurchaseUrlParamsNames.TAB_TYPE]: StockTabParamEnum.ACTIVE });
      } else {
        setSearchParams({ page, [PurchaseUrlParamsNames.TAB_TYPE]: StockTabParamEnum.ARCHIVED });
      }
    }
    // eslint-disable-next-line
  }, [page, tabParam]);

  useEffect(() => {
    if (tabParam) {
      dispatch(setPurchasesFilters({ ...purchasesFilters, isActive: tabParam === StockTabParamEnum.ACTIVE, page: 1 }));
    }
    // eslint-disable-next-line
  }, [tabParam]);

  return (
    <>
      <SelectInvoicingEntitiesForExport
        visible={isExportToCsvModal}
        onCancel={() => setIsExportToCsvModal(false)}
        type="purchases"
      />
      {purchasesFilters.isActive && allowedToExport && (
        <Button
          icon={<FontAwesomeIcon icon={faFileExport} />}
          onClick={() => setIsExportToCsvModal(true)}
          className="invoicingFilters__export-csv"
        />
      )}
      {!!windowWidth && windowWidth <= responsiveEndpointsEnum.LG_L
        ? <Selectable
          options={purchaseTabModesOptions}
          value={tabParam || ''}
          onChange={(value) => setTabParam(value as string)}
          size="sm"
        />
        : <Segmented
          options={purchaseTabModesOptions}
          value={tabParam || ''}
          onChange={(value) => setTabParam(value as string)}
        />}
    </>
  );
};

export default PurchasesFilter;
