export enum PartRequestsUrlParamsNames {
  PAGE = 'page',
  TAB_TYPE = 'tab_type',
  PART_REQ_STATUS = 'part_request_status',
}

export enum StockPartReqAllStatusEnum { ALL = 100 }

export enum StockPartReqStatusesEnum {
  New = 'New',
  InProgress = 'In progress',
  Collected = 'Collected',
  Archived = 'Archived',
}

export enum StockPartRequestViewEnum {
  REQUESTS = 'requests',
  LINES = 'lines',
}
