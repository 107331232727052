import React from 'react';
import VehicleSection from '../../../../../common/components/vehicle-section/VehicleSection';
import { useAppSelector } from '../../../../../store/hooks';
import { rectificationVehUiDataSelector } from '../../../../../store/selectors/rectificationsSelector';

const RectificationPartReqVehicle: React.FC = () => {
  const {
    name, info, regNumber, chassisNumber, vehicleLocation,
  } = useAppSelector(rectificationVehUiDataSelector);
  const specificInfo = { regNumber, chassisNumber, location: vehicleLocation };
  return (
    <VehicleSection
      name={name}
      info={info}
      regNumber={specificInfo.regNumber}
      chassisNumber={specificInfo.chassisNumber}
      vehicleLocation={specificInfo.location}
      className="orderPartReqVehicle"
      bordered
      horizontal
      readonly
    />
  );
};

export default RectificationPartReqVehicle;
