import { useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';
import { DateObject } from 'react-multi-date-picker';
import moment from 'moment/moment';
import { AffectedPeriodOptionsEnum, SelectEmployeesOptionsEnum } from '../enums/workCalendarEnums';
import { SetWorkingHoursFields } from '../utils/SetWorkingHoursSchema';
import { DEFAULT_DATE_FORMAT } from '../../../core/utils/regex';

export const useEmployeeWorkingHoursModal = () => {
  const [datesValues, setDatesValues] = useState<DateObject[]>([]);
  const [datesError, setDatesError] = useState<string>('');
  const [selectEmployeeMode, setSelectEmployeeMode] = useState<SelectEmployeesOptionsEnum>(SelectEmployeesOptionsEnum.BY_DEPARTMENT);
  const [affectedPeriodMode, setAffectedPeriodMode] = useState<AffectedPeriodOptionsEnum>(AffectedPeriodOptionsEnum.DATE_PERIOD);
  const selectEmployeesOptions = [
    { label: 'By department', value: SelectEmployeesOptionsEnum.BY_DEPARTMENT },
    { label: 'By job title', value: SelectEmployeesOptionsEnum.BY_JOB_TITLE },
    { label: 'Specific employees', value: SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE },
  ];

  const affectedPeriodOptions = [
    { label: 'Apply to a date period', value: AffectedPeriodOptionsEnum.DATE_PERIOD },
    { label: 'Apply to specific dates', value: AffectedPeriodOptionsEnum.SPECIFIC_DATES },
  ];

  const cleanUpEmployeeModeContent = (value: SelectEmployeesOptionsEnum, setValue: UseFormSetValue<SetWorkingHoursFields>) => {
    setSelectEmployeeMode(value);
    if (value === SelectEmployeesOptionsEnum.BY_DEPARTMENT) {
      setValue('jobTitles', []);
      setValue('employeeIds', []);
    }
    if (value === SelectEmployeesOptionsEnum.BY_JOB_TITLE) {
      setValue('departments', []);
      setValue('employeeIds', []);
    }
    if (value === SelectEmployeesOptionsEnum.SPECIFIC_EMPLOYEE) {
      setValue('departments', []);
      setValue('jobTitles', []);
    }
  };

  const cleanUpAffectedModeContent = (value: AffectedPeriodOptionsEnum, setValue: UseFormSetValue<SetWorkingHoursFields>) => {
    setAffectedPeriodMode(value);
    if (value === AffectedPeriodOptionsEnum.DATE_PERIOD) {
      setDatesValues([]);
      datesError && setDatesError('');
    }
    if (value === AffectedPeriodOptionsEnum.SPECIFIC_DATES) {
      setValue('startDate', moment().format(DEFAULT_DATE_FORMAT));
      setValue('endDate', moment().format(DEFAULT_DATE_FORMAT));
    }
  };

  return {
    selectEmployeeMode,
    setSelectEmployeeMode,
    selectEmployeesOptions,
    cleanUpEmployeeModeContent,
    cleanUpAffectedModeContent,
    affectedPeriodMode,
    setAffectedPeriodMode,
    affectedPeriodOptions,
    datesValues,
    setDatesValues,
    datesError,
    setDatesError,
  };
};
