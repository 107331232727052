import React, { useState } from 'react';
import PartViewPricesParentRow from './PartViewPricesParentRow';
import PartViewPricesChildRow from './PartViewPricesChildRow';
import { PartQuantityDto, PricelistDto } from '../../../../../types/types';

type ChildrenData = PartQuantityDto | PricelistDto
type PartViewPricesSectionProps = {
  title: string,
  childrenRows: ChildrenData[],
}

const PartViewPricesSection:React.FC<PartViewPricesSectionProps> = ({ title, childrenRows }) => {
  const [isHidden, setIsHidden] = useState(false);

  return (
    <>
      <PartViewPricesParentRow title={title} isHidden={isHidden} handleClick={() => setIsHidden(!isHidden)} />
      {childrenRows.map((row, i) => <PartViewPricesChildRow key={i} isHidden={isHidden} data={row} />)}
    </>
  );
};

export default PartViewPricesSection;
