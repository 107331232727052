import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {
  faEllipsisV, faMobileRetro, faMapMarkerAlt, faPhone, faEnvelope,
} from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import { summaryFieldValueHandle } from '../../../core/utils/summaryFieldValueHandle';
import Restricted from '../../../permissions-handling/Restricted';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { NumberFunctionType } from '../../../core/types/coreTypes';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import USER_ICON from '../../../assets/images/user-icon.png';
import { ContactDto } from '../../types/commonTypes';

type ContactLineProps = {
  line: Omit<ContactDto, 'customerId'>,
  setIsRemove?: NumberFunctionType,
  checkedKeys?: number[],
  checkLine?: NumberFunctionType,
  disabled?: boolean,
  deleteContactPermission?: PermissionEnum,
  isFetching?: boolean,
}

const ContactLine: React.FC<ContactLineProps> = ({
  line, setIsRemove, checkLine, checkedKeys,
  disabled, deleteContactPermission, isFetching,
}) => {
  const entities = [
    { icon: faEnvelope, label: line.email || '' },
    { icon: faPhone, label: line.telephone || '' },
    { icon: faMobileRetro, label: line.mobile || '' },
    {
      icon: faMapMarkerAlt,
      label: summaryFieldValueHandle([line.addressLine1, line.addressLine2, line.cityTown, line.region, line.postalCode]),
      hideInModal: true,
    },
  ];

  return (
    <article className={classNames('contact', { 'contact--selectable': checkedKeys })}>
      {checkedKeys && <Checkbox
        checked={checkedKeys?.includes(line.id)}
        className="contact__checkbox"
        value={line.id}
        disabled={isFetching || disabled}
        onChange={() => line.id && checkLine && checkLine(line.id)}
      />}
      <div className="contact__content">
        <div className="contact__user">
          <img src={USER_ICON} alt="" className="contact__icon" />
          <div className="contact__name-wrap">
            <TruncateTooltip value={`${line.firstName || ''} ${line.lastName || ''}`} className="contact__name" />
            <TruncateTooltip value={line.position || '-'} className="contact__position" />
          </div>
        </div>
        {entities.map((ent, i) => (
          <div
            key={i}
            className={classNames('contact__info', { 'hiddenContactInfo': !ent.label || (checkedKeys && ent.icon === faMapMarkerAlt) })}
          >
            <FontAwesomeIcon icon={ent.icon} className="contact__info-icon" />
            <TruncateTooltip value={ent.label} className="contact__info-value" />
          </div>))}
      </div>
      {deleteContactPermission && <Restricted to={deleteContactPermission}>
        <>
          {setIsRemove && <Dropdown
            menuItems={[
              {
                label: 'Remove contact',
                icon: <FontAwesomeIcon icon={faTrashAlt} />,
                key: 'removeContact',
                onClick: () => line.id && !disabled && setIsRemove(line.id),
              },
            ]}
            icon={<FontAwesomeIcon icon={faEllipsisV} />}
            className="contact__dropdown"
            disabled={disabled}
          />}
        </>
      </Restricted>}
    </article>
  );
};

export default ContactLine;
