import React, { useEffect } from 'react';
import './JobList.scss';
import { useSearchParams } from 'react-router-dom';
import JobListExtraFilters from './components/JobListExtraFilters';
import JobListComponent from './components/JobListComponent';
import { getJobListThunk } from '../../store/thunks/jobs/jobListThunks';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  adminRoleSelector,
  currentBusinessAreaSelector,
} from '../../store/selectors/coreSelectors';
import { jobListFiltersSelector, workQueueJobStatusesSelector } from '../../store/selectors/jobsSelector';
import { currentUserSelector, isCurrentRoleAdminSelector } from '../../store/selectors/accountSelectors';
import { initDataList, StringFunctionType } from '../../core/types/coreTypes';
import { JobListAllStatusEnum, JobsSearchParamsNames } from '../jobs/enums/jobsEnums';
import {
  setJobList, setJobListAssignees, setJobListFilters, setJobTimelineFilters,
} from '../../store/slices/jobsSlice';
import { JobListFilters } from './types/jobListTypes';
import { getJobEventTypesThunk } from '../../store/thunks/core/coreEventTypesThunks';
import { initExtendedTimelineFilters } from '../../common/types/commonTypes';
import { getJobQualityCheckStatusesThunk } from '../../store/thunks/core/coreStatusesThunks';

type JobListProps = {
  pageParam: string | null;
  tabType: string | null;
  setJobTabTypeParam: StringFunctionType,
  setJobStatusParam: StringFunctionType,
  jobStatus: string | null,
}

const JobList: React.FC<JobListProps> = ({
  tabType, setJobTabTypeParam, setJobStatusParam, jobStatus, pageParam,
}) => {
  const [params] = useSearchParams();
  const dispatch = useAppDispatch();
  const ba = useAppSelector(currentBusinessAreaSelector);
  const adminRole = useAppSelector(adminRoleSelector);
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const jobListFilters = useAppSelector(jobListFiltersSelector);
  const workQueueJobStatuses = useAppSelector(workQueueJobStatusesSelector);

  const getJobList = (filters: JobListFilters) => {
    if (ba) {
      if (isCurrentRoleAdmin) {
        dispatch(getJobListThunk({
          businessArea: ba,
          filters,
        }));
      } else {
        currentUser && dispatch(getJobListThunk({
          businessArea: ba,
          filters: { ...filters, assigneeIds: [currentUser?.id] },
        }));
      }
    }
  };

  useEffect(() => {
    const isStatusParam = params.get(JobsSearchParamsNames.JOB_STATUS);
    const tabParam = params.get(JobsSearchParamsNames.TAB_TYPE);
    const isRequiredBasicFieldsFilled = currentUser && ba && adminRole && isStatusParam && tabParam;
    if (isRequiredBasicFieldsFilled) {
      if (jobListFilters.jobStatuses && jobListFilters.jobStatuses.length > 0) {
        getJobList(jobListFilters);
      } else {
        const isExtraRequiredFieldsFilled = isRequiredBasicFieldsFilled && workQueueJobStatuses.length > 0;
        const workQueueIds = workQueueJobStatuses
          .filter((el) => el.value !== JobListAllStatusEnum.ALL)
          .map((el) => el.value);
        if (isExtraRequiredFieldsFilled) {
          let statuses: number[] | undefined;
          if (+isStatusParam === JobListAllStatusEnum.ALL) {
            statuses = workQueueIds;
          } else {
            statuses = [+isStatusParam];
          }
          const filters = { ...jobListFilters, jobStatuses: statuses };
          dispatch(setJobListFilters(filters));
          filters.jobStatuses && filters.jobStatuses.length > 0 && getJobList(filters);
        }
      }
    }
    // eslint-disable-next-line
  }, [ba, currentUser, adminRole, params, workQueueJobStatuses.length, jobListFilters.page]);

  useEffect(() => {
    dispatch(getJobEventTypesThunk());
    dispatch(setJobTimelineFilters({ ...initExtendedTimelineFilters, notesOnly: true }));
    dispatch(getJobQualityCheckStatusesThunk());

    return () => {
      dispatch(setJobList(initDataList));
      dispatch(setJobListAssignees([]));
      dispatch(setJobTimelineFilters({ ...initExtendedTimelineFilters, notesOnly: false }));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <>
      <JobListExtraFilters
        tabType={tabType}
        page={pageParam}
        setJobTabTypeParam={setJobTabTypeParam}
        jobListFilters={jobListFilters}
        setJobStatusParam={setJobStatusParam}
        jobStatus={jobStatus}
      />
      <JobListComponent />
    </>
  );
};

export default JobList;
