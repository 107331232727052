import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const PartNameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 250, message: maxLengthMessageHandle(250) },
};

export const PartCodeSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
};

export type PartFields = {
  name: string,
  partCode: string,
  manufacturerCode: string,
  type: number | null,
  corePart: number,
  businessAreas: number[],
  unitOfMeasure: number | null,
  weight: string,
  categoryId: number | null,
  subcategoryId: number | null,
}

export type PartPick = Pick<PartFields, 'name' | 'partCode' | 'manufacturerCode' | 'businessAreas'>

export const partDefaults: PartFields = {
  name: '',
  partCode: '',
  manufacturerCode: '',
  type: null,
  corePart: 1,
  businessAreas: [],
  unitOfMeasure: null,
  weight: '0.00',
  categoryId: null,
  subcategoryId: null,
};
