import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { stockPartsOrderingSelector } from '../../../../store/selectors/coreOrderingSelectors';
import {
  setPurchaseOrderPartSearch,
  setPurchaseOrderPartSearchFilters,
} from '../../../../store/slices/purchaseOrderSlice';
import { getStockSearch } from '../../../../store/thunks/stock/stockThunks';
import { initDataList } from '../../../../core/types/coreTypes';
import { StockPartsOrders } from '../../../search/enums/StockPartsOrders';
import {
  purchaseOrdersAddPartColumnsSelector,
  purchaseOrderSearchPartsFiltersSelector,
} from '../../../../store/selectors/purchaseOrderSelectors';
import { setSuitableVehicleProfiles } from '../../../../store/slices/sharedSlice';
import { initVehProfilesList } from '../../../../common/types/commonTypes';

export const useAddPartToOrderFilters = () => {
  const dispatch = useAppDispatch();

  const initDropdownSearch = {
    categoryIds: '',
    subcategoryIds: '',
    vehicleIds: '',
  };

  const stockPartsOrdering = useAppSelector(stockPartsOrderingSelector);
  const filters = useAppSelector(purchaseOrderSearchPartsFiltersSelector);
  const filtersList = useAppSelector(purchaseOrdersAddPartColumnsSelector);
  const defaultOrdering = {
    field: stockPartsOrdering
      .find((el) => el.label === StockPartsOrders.CategoryThenSubcategoryThenName)?.value,
    isAsc: true,
  };

  const [error, setError] = useState<string>('');

  const getFilteredRes = (value: number[] | string, field: string) => {
    const newV = {
      ...filters,
      [field]: value,
      page: 1,
      order: filters.order || defaultOrdering,
    };
    dispatch(setPurchaseOrderPartSearchFilters(newV));
    filters.keyword && dispatch(getStockSearch({
      filters: newV,
      isPurchaseOrderPage: true,
    }));
  };

  const getPaginatedRes = (page: number, pageSize: number) => {
    const newV = {
      ...filters, page, pageSize, order: filters.order || defaultOrdering,
    };
    dispatch(setPurchaseOrderPartSearchFilters(newV));
    filters.keyword && dispatch(getStockSearch({
      filters: newV,
      isPurchaseOrderPage: true,
    }));
  };

  const searchTriggerFn = () => {
    dispatch(getStockSearch({
      filters: { ...filters, keyword: filters.keyword?.trim(), order: filters.order || defaultOrdering },
      isPurchaseOrderPage: true,
    }));
  };

  const onClear = () => {
    const newV = {
      page: 1,
      pageSize: 5,
      isCorePart: filters.isCorePart,
      keyword: filters.keyword,
      order: filters.order || defaultOrdering,
    };
    dispatch(setPurchaseOrderPartSearchFilters(newV));
    filters.keyword && dispatch(getStockSearch({
      filters: newV,
      isPurchaseOrderPage: true,
    }));
  };

  const changeCoreMode = (isCore?: boolean) => {
    dispatch(setPurchaseOrderPartSearchFilters({ page: 1, pageSize: 5, isCorePart: isCore !== undefined ? [isCore] : undefined }));
    dispatch(setPurchaseOrderPartSearch(initDataList));
    dispatch(setSuitableVehicleProfiles(initVehProfilesList));
  };

  return {
    error,
    setError,
    initDropdownSearch,
    filtersList,
    filters,
    searchTriggerFn,
    getFilteredRes,
    getPaginatedRes,
    changeCoreMode,
    onClear,
  };
};
