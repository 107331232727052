import React, { useState } from 'react';
import Button from '../../../../../../core/components/button/Button';
import SkillsUpdateModal from './SkillsUpdateModal';
import { EmployeeActivityEventType } from '../../../../types/employeeViewTypes';

type SkillsUpdateEventProps = {
  item: EmployeeActivityEventType;
}

const SkillsUpdateEvent: React.FC<SkillsUpdateEventProps> = ({ item }) => {
  const [skillsModal, setSkillsModal] = useState(false);
  return (
    <>
      <SkillsUpdateModal
        isVisible={skillsModal}
        setIsVisible={setSkillsModal}
        item={item}
      />
      <b>Skills and levels</b>
      {' '}
      were changed
      {' '}
      <Button
        label="(see details)"
        onClick={() => setSkillsModal(true)}
        type="text"
      />
    </>
  );
};

export default SkillsUpdateEvent;
