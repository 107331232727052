import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { faCalendarDays, faClock } from '@fortawesome/free-regular-svg-icons';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { employeesContainerOptions } from '../utils/data';
import { EmployeesPageParamEnums } from '../enums/EmployeesPageParamsEnums';
import KeywordFiltersSection from '../../../core/components/keyword-filters-section/KeywordFiltersSection';
import DashboardFilters from '../../../common/components/dashboard-filters/DashboardFilters';
import { useEmployeesFilters } from '../hooks/useEmployeesFilters';
import { BooleanFunctionType } from '../../../core/types/coreTypes';

type EmployeesFiltersProps = {
  view: string | null;
  setView: (value: string) => void;
  setIsHolidayModal: BooleanFunctionType,
  setIsHoursModal: BooleanFunctionType,
}

const EmployeesFilters: React.FC<EmployeesFiltersProps> = ({
  view,
  setView,
  setIsHolidayModal,
  setIsHoursModal,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();

  const pagesWithFilter = [
    EmployeesPageParamEnums.DASHBOARD,
    EmployeesPageParamEnums.WORK_CALENDAR,
    EmployeesPageParamEnums.LEAVE_REQUESTS,
  ];
  const isWorkCalendarView = view === EmployeesPageParamEnums.WORK_CALENDAR;

  const {
    keywordValue,
    changeKeywordValue,
    clearFilters,
    filters,
    initDropdownSearch,
    getFilteredResult,
  } = useEmployeesFilters(view);

  const setViewHandle = (value: string) => {
    if (value === EmployeesPageParamEnums.CREATE_NEW_EMPLOYEE) {
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
      navigate('/create-employee');
    } else {
      setView(value);
    }
  };

  const customButtons = [
    {
      icon: faCalendarDays,
      onClick: () => setIsHolidayModal(true),
    },
    {
      icon: faClock,
      onClick: () => setIsHoursModal(true),
    },
  ];

  const [isFilterOpen, setIsFilterOpen] = useState(false);
  return (
    <DashboardFilters
      page={view}
      setPageParamHandle={setViewHandle}
      options={employeesContainerOptions}
      expandableFilters={isWorkCalendarView}
      isFiltersOpen={isFilterOpen}
      setIsFiltersOpen={setIsFilterOpen}
      customButtons={isWorkCalendarView ? customButtons : undefined}
    >
      {pagesWithFilter.includes(view as EmployeesPageParamEnums) && (
        <KeywordFiltersSection
          filters={filters}
          searchValue={keywordValue}
          setSearchValue={changeKeywordValue}
          getFilteredResHandle={getFilteredResult}
          onClear={clearFilters}
          initDropdownSearch={initDropdownSearch}
          isFullWidth={isWorkCalendarView}
          disableMoveSearchToTop={isWorkCalendarView}
        />
      )}
    </DashboardFilters>
  );
};

export default EmployeesFilters;
