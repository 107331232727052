import React from 'react';
import { Checkbox as CheckboxComponent } from 'antd';
import { CheckboxValueType } from 'antd/lib/checkbox/Group';

type CheckboxGroupProps = {
  value: CheckboxValueType[]
  onChange: (checkedValues: CheckboxValueType[]) => void;
  options: Array<{ value: string | number | boolean, label: string, disabled?: boolean }>;
  className?: string;
  name?: string;
  disabled?: boolean;
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({
  value,
  onChange,
  options,
  className,
  name,
  disabled,
}) => {
  return (
    <CheckboxComponent.Group
      style={{ width: '100%' }}
      value={value}
      onChange={onChange}
      className={className}
      name={name}
      disabled={disabled}
      options={options}
    />
  );
};

export default CheckboxGroup;
