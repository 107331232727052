import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Modal from '../../../core/components/modal/Modal';
import { initSmallPaging, PagingType, VoidFunctionType } from '../../../core/types/coreTypes';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import Tbody from '../../../core/components/table-components/Tbody';
import TdNew from '../../../core/components/table-components/table-new/TdNew';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import { missingQuoteLinesColumns } from '../utils/testData';
import { missingQuoteLinesCellsSelector } from '../../../store/selectors/enquiriesSelector';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Pagination from '../../../core/components/pagination/Pagination';
import './MissingQuoteLinesModal.scss';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import { getQuoteLinesCanNotBeCopiedThunk } from '../../../store/thunks/enquiry/quoteConfiguratorThunks';

type MissingQuoteLinesModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const MissingQuoteLinesModal: React.FC<MissingQuoteLinesModalProps> = ({ visible, onCancel }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, totalCount } = useAppSelector(missingQuoteLinesCellsSelector);
  const [currentPaging, setCurrentPaging] = useState<PagingType>(initSmallPaging);
  const changePaging = (value: PagingType) => {
    setCurrentPaging(value);
    id && dispatch(getQuoteLinesCanNotBeCopiedThunk({
      filters: {
        enquiryId: +id,
        page: value.page,
        pageSize: value.pageSize,
        canBeCopied: false,
      },
    }));
  };
  const cancelHandle = () => {
    onCancel();
    setCurrentPaging(initSmallPaging);
  };

  return (
    <Modal visible={visible} onCancel={cancelHandle} title="Missing quote line(s)" width={800} className="missingQuoteLinesModal">
      <div className="missingQuoteLinesModal__content">
        <span className="missingQuoteLinesModal__text">
          The following quote line(s) could not be copied from an original enquiry:
        </span>
        <Pagination
          currentPage={currentPaging.page}
          pageSize={currentPaging.pageSize}
          totalItems={totalCount}
          onChange={(page, pageSize) => changePaging({ page, pageSize })}
          showSizeChanger={false}
        />
        <TableNew small>
          <Thead>
            <Tr>
              {missingQuoteLinesColumns.map((col) => (
                <ThNew key={col.dataIndex} className={`missingQuoteLinesTh--${col.dataIndex}`}>
                  {col.title}
                </ThNew>))}
            </Tr>
          </Thead>
          <Tbody>
            {items.map((el, i) => (
              <Tr key={i}>
                {Object.entries(el).map(([key, value]) => {
                  return <TdNew key={key}>
                    <TruncateTooltip value={value} table />
                  </TdNew>;
                })}
              </Tr>))}
          </Tbody>
        </TableNew>
      </div>
    </Modal>
  );
};

export default MissingQuoteLinesModal;
