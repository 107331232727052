import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { setLoading } from '../../../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../../../core/utils/guidGenerator';
import {
  createPartsKitAttachmentThunk, deleteMultiplePartsKitAttachmentThunk,
  getPartsKitAttachments,
} from '../../../../../../store/thunks/stock/parts-kit/partsKitAttachments';
import {
  partsKitAttachmentsFiltersSelector,
  partsKitAttachmentsSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import CreateAttachmentModal from '../../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import PartsKitAttachmentFilters from './filters/PartsKitAttachmentFilters';
import PartsKitAttachmentTable from './table/PartsKitAttachmentTable';
import { useSelectAll } from '../../../../../../core/hooks/useSelectAll';

const PartsKitsAttachments: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(partsKitAttachmentsSelector);
  const filters = useAppSelector(partsKitAttachmentsFiltersSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  useEffect(() => {
    id && dispatch(getPartsKitAttachments({ partsKitId: +id, filters }));
    // eslint-disable-next-line
    }, [id, filters]);

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createPartsKitAttachmentThunk({
      id: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultiplePartsKitAttachmentThunk({
      ids: checkedKeys,
      partsKitId: +id,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <div className="partskitsAttachWrap">
        <PartsKitAttachmentFilters
          filters={filters}
          totalItems={totalCount}
          setCreateModal={setCreateModal}
          setDeleteModal={() => setDeleteModal(true)}
          checkedKeys={checkedKeys}
        />
        <PartsKitAttachmentTable
          attachments={items}
          checkedKeys={checkedKeys}
          isAll={isAll}
          indeterminate={isIndeterminate}
          checkAllHandle={checkAllHandle}
          checkRow={checkRowHandle}
        />
      </div>
    </>
  );
};

export default PartsKitsAttachments;
