import React from 'react';
import { EmployeeViewModeParamsEnum } from '../../enums/EmployeeViewEnums';
import EmployeeViewAttachments from './components/attachments/EmployeeViewAttachments';
import EmployeeIceContact from './components/ice-contact/EmployeeIceContact';
import EmployeeSkillsContent from './components/skills/EmployeeSkillsContent';
import EmploymentsContent from './components/employments/EmploymentsContent';
import EmployeeActivityLog from './components/activity-log/EmployeeActivityLog';
import EmployeeLeaveRequests from './components/leave-requests/EmployeeLeaveRequests';

type DynamicContentType = {
  mode: string | null,
}
const EmployeeContent: React.FC<DynamicContentType> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case EmployeeViewModeParamsEnum.EMPLOYEE_SKILLS:
        return <EmployeeSkillsContent />;
      case EmployeeViewModeParamsEnum.EMPLOYEE_ICE_CONTACT:
        return <EmployeeIceContact />;
      case EmployeeViewModeParamsEnum.EMPLOYEE_EMPLOYMENT:
        return <EmploymentsContent />;
      case EmployeeViewModeParamsEnum.EMPLOYEE_LEAVE_REQUESTS:
        return <EmployeeLeaveRequests />;
      case EmployeeViewModeParamsEnum.EMPLOYEE_ACTIVITY:
        return <EmployeeActivityLog />;
      case EmployeeViewModeParamsEnum.EMPLOYEE_ATTACHMENTS:
        return <EmployeeViewAttachments />;
      default: return null;
    }
  };

  return (
    <>{renderContent()}</>
  );
};

export default EmployeeContent;
