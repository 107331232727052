import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileArrowUp, faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import Layout from '../../core/components/layout/Layout';
import DetailsHead from '../../core/components/details-head/DetailsHead';
import { routesPath } from '../../core/enums/pathEnum';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { StockPageParamEnums } from '../container/enums/stockEnums';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';
import StockPartReqViewHead from './components/StockPartReqViewHead';
import StockPartReqTable from './components/StockPartReqTable';
import EditReadyCollectedModal from './components/modals/edit-ready-collected/EditReadyCollectedModal';
import EditReadyCollectedConfirmModal
  from './components/modals/edit-ready-collected-confirm-modal/EditReadyCollectedConfirmModal';
import AllocatePricelistQtyModal from './components/modals/allocate-pricelist-qty/AllocatePricelistQtyModal';
import SelectProductSourceDistributeQty
  from './components/modals/select-product-source-distribute-qty/SelectProductSourceDistributeQty';
import './StockPartReqViewPage.scss';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  getStockPartQuantitySources,
  getStockPartRequestById,
  getStockPartRequestLines,
} from '../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import {
  stockPartRequestDetailsSelector,
  stockPartRequestLinesFiltersSelector,
  stockPartRequestVehicleUiSelector,
} from '../../store/selectors/stockPartRequestsSelector';
import VehicleSection from '../../common/components/vehicle-section/VehicleSection';
import {
  getPartRequestLineStatuses,
  getPartRequestStatuses,
} from '../../store/thunks/core/coreStatusesThunks';
import ConfirmReturnedPartsModal from './components/modals/confirm-returned-parts/ConfirmReturnedPartsModal';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import {
  setStockPartRequestDetails,
  setStockPartRequestLines,
  setStockPartRequestLinesFilters, setStockPartsToFillPartRequest,
  setStockPartsToFillPartRequestFilters,
} from '../../store/slices/stockPartRequestSlice';
import { initDraftLinePartsFilters } from '../../common/types/commonTypes';
import { initDataList, initDataListWithIds } from '../../core/types/coreTypes';
import FillInDraftLineModal from '../../common/components/fill-in-draft-line-modal/FillInDraftLineModal';
import SelectProductSourceModal from '../../common/components/select-product-source-modal/SelectProductSourceModal';
import { StockPartReqLineOrderingEnum } from './enums/StockPartRequestViewPageEnums';
import { stockPartRequestLinesOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import PartRequestActivityModal from './components/modals/activity/PartRequestActivityModal';
import GeneratePurchaseOrderModal from './components/modals/generate-purchase-order/GeneratePurchaseOrderModal';
import { setProductSourceDictionary, setProductSourceTotal } from '../../store/slices/sharedSlice';
import { getVehicleInfoSources } from '../../store/thunks/coreThunk';
import { usePartRequestLinesActions } from '../common/hooks/usePartRequestLinesActions';
import { useFillInDraftLineAndSelectProductSource } from '../stock-part-requests/hooks/useFillInDraftLineAndSelectProductSource';

const StockPartRequestViewPage: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { backHandle } = useLocationHistoryState(true);
  const stockPartRequestDetails = useAppSelector(stockPartRequestDetailsSelector);

  const partRequestLinesOrdering = useAppSelector(stockPartRequestLinesOrderingSelector);

  const [activityModal, setActivityModal] = useState(false);
  const [generatePurchaseModal, setGeneratePurchaseModal] = useState(false);
  const [sourceId, setSourceId] = useState<number | undefined>(undefined);

  const activityLogAction = [
    {
      label: 'Activity log',
      key: 'stockPartReqActivityLog',
      icon: <FontAwesomeIcon icon={faWaveSquare} />,
      onClick: () => setActivityModal(true),
      restrictedTo: PermissionEnum.StockPartRequestViewPage,
    },
  ];

  const generateAction = [
    {
      label: 'Generate purchase order',
      key: 'generatePurchaseOrder',
      icon: <FontAwesomeIcon icon={faFileArrowUp} />,
      onClick: () => setGeneratePurchaseModal(true),
      restrictedTo: PermissionEnum.StockPartRequestViewPage,
    },
  ];

  const actions = stockPartRequestDetails?.canPurchaseOrderBeGenerated ? [...activityLogAction, ...generateAction] : activityLogAction;

  const partRequestDetails = useAppSelector(stockPartRequestDetailsSelector);
  const stockPartRequestVehicle = useAppSelector(stockPartRequestVehicleUiSelector);
  const filters = useAppSelector(stockPartRequestLinesFiltersSelector);
  useEffect(() => {
    if (id) {
      dispatch(getStockPartRequestById({ id: +id }));
    }
    // eslint-disable-next-line
  }, [id]);

  useEffect(() => {
    if (!filters?.order) {
      const defaultOrder = partRequestLinesOrdering?.find((el) => el.label === StockPartReqLineOrderingEnum.OrdinalNumber)?.value;
      if (defaultOrder) {
        dispatch(setStockPartRequestLinesFilters({ ...filters, order: { field: defaultOrder, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
  }, [partRequestLinesOrdering]);

  useEffect(() => {
    if (stockPartRequestDetails?.id && filters?.order) {
      dispatch(getStockPartRequestLines({
        partRequestId: stockPartRequestDetails.id,
        filters,
      }));
    }
    // eslint-disable-next-line
  }, [stockPartRequestDetails?.id, filters]);

  const {
    currentLine,
    isAllocatePricelistQty, setIsAllocatePricelistQty,
    editReadyCollected, setEditReadyCollected,
    isDistributeQty, setDistributeQty,
    affectedLineConfirmedData,
    isConfirmReturnedParts, setConfirmReturnedParts,
    isMarkNotFromStock, setIsMarkNotFromStock, markAsNotFromStockHandler,
    isConfirmEdit, setIsConfirmEdit, confirmEditMode, setConfirmEditMode,
    partQtyId, setPartQtyId, fillInModal, setFillInModal,
  } = usePartRequestLinesActions(setSourceId, sourceId, false, stockPartRequestDetails?.id);

  const {
    coreInfoSource, nonCoreInfoSources, infoSources,
    fillPartRequestFilters, fillPartRequestParts,
    productSourceTotal, productSourceDictionary,
    sourceModalLines, fetchPartsToFillPartReq,
    getRemapProductSourceDictionaryHandle, getRemapProductSourceTotalHandle,
    createPriceSourceHandle, cancelProductSourceModal,
    sourceName, setSourceName, filledPartId, setFilledPartId,
    prodSourceModal, setProdSourceModal, infoSourceV, setInfoSourceV,
  } = useFillInDraftLineAndSelectProductSource(sourceId);

  useEffect(() => {
    dispatch(getPartRequestStatuses());
    dispatch(getStockPartQuantitySources());
    dispatch(getPartRequestLineStatuses());
    dispatch(getVehicleInfoSources());
    return () => {
      dispatch(setStockPartRequestDetails(null));
      dispatch(setStockPartRequestLines({ ...initDataListWithIds }));
      dispatch(setStockPartRequestLinesFilters(undefined));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={
        <DetailsHead
          goBack={() => backHandle(`/${routesPath.STOCK}?page=${StockPageParamEnums.PART_REQUESTS}`)}
          actions={actions}
          className="partReqViewPageHead"
        >
          <StockPartReqViewHead partRequest={partRequestDetails} />
        </DetailsHead>
      }
    >
      <PartRequestActivityModal
        visible={activityModal}
        onClose={() => setActivityModal(false)}
        id={stockPartRequestDetails?.id}
      />
      <AllocatePricelistQtyModal
        visible={isAllocatePricelistQty}
        onCancel={() => setIsAllocatePricelistQty(false)}
        sourceId={sourceId}
        currentLine={currentLine}
      />
      <EditReadyCollectedModal
        visible={editReadyCollected}
        onCancel={() => {
          setEditReadyCollected(false);
          setSourceId(undefined);
        }}
        setConfirmEditMode={(v, partQuantityId) => {
          setConfirmEditMode(v);
          setIsConfirmEdit(true);
          setPartQtyId(partQuantityId);
        }}
        openAllocatePricelist={() => setIsAllocatePricelistQty(true)}
        currentLine={currentLine}
      />
      <EditReadyCollectedConfirmModal
        visible={isConfirmEdit}
        onCancel={() => {
          setIsConfirmEdit(false);
          setConfirmEditMode('');
          setPartQtyId(undefined);
        }}
        confirmEditMode={confirmEditMode}
        sourceId={sourceId}
        partQuantityId={partQtyId}
      />
      <SelectProductSourceDistributeQty
        onCancel={() => {
          setDistributeQty(false);
          setSourceId(undefined);
        }}
        visible={isDistributeQty}
        sourceName={currentLine?.productName || '-'}
        sourceId={sourceId}
        sourcePartRequestId={id ? +id : undefined}
      />
      <ConfirmReturnedPartsModal
        isVisible={isConfirmReturnedParts}
        onCancel={() => {
          setConfirmReturnedParts(false);
          setSourceId(undefined);
        }}
        partRequestId={partRequestDetails?.id}
        sourceConfirmedData={affectedLineConfirmedData}
        sourceId={sourceId}
      />
      <ConfirmationModal
        customTitle="Mark as Not for stock"
        customText="This part line will be marked as not for stock and will be hidden in submitted part request in Stock module."
        customCreateLabel="Yes, proceed"
        isVisible={isMarkNotFromStock}
        onCancel={() => {
          setIsMarkNotFromStock(false);
          setSourceId(undefined);
        }}
        confirmHandler={markAsNotFromStockHandler}
      />
      <FillInDraftLineModal
        isVisible={fillInModal}
        onCancel={() => {
          setFillInModal(false);
          setSourceId(undefined);
        }}
        title="Fill in part request line"
        infoSources={infoSources}
        chooseInfoSource={(v) => setInfoSourceV(v)}
        currentInfoSource={infoSourceV}
        setInitFiltersAndTableData={() => {
          dispatch(setStockPartsToFillPartRequestFilters(initDraftLinePartsFilters));
          dispatch(setStockPartsToFillPartRequest(initDataList));
        }}
        isCorePart={coreInfoSource?.value === infoSourceV}
        isNonCorePart={nonCoreInfoSources?.value === infoSourceV}
        editedLine={sourceId}
        setProdSourceModal={(partId: number, name: string) => {
          setSourceName(name);
          setFilledPartId(partId);
          getRemapProductSourceDictionaryHandle(partId, () => setProdSourceModal(true));
        }}
        parts={fillPartRequestParts}
        setParts={(data) => dispatch(setStockPartsToFillPartRequest(data))}
        filters={fillPartRequestFilters}
        setFilters={(values) => dispatch(setStockPartsToFillPartRequestFilters(values))}
        fetchParts={(values, isShow) => fetchPartsToFillPartReq(values, isShow)}
      />
      <SelectProductSourceModal
        isVisible={prodSourceModal}
        onCancel={() => cancelProductSourceModal()}
        closePartsModal={() => setFillInModal(false)}
        sourceName={sourceName}
        sourceId={filledPartId}
        editedLine={sourceId}
        setProductSourceTotal={(v) => dispatch(setProductSourceTotal(v))}
        setProductSourceDictionary={(v) => dispatch(setProductSourceDictionary(v))}
        lines={sourceModalLines}
        linesParts={fillPartRequestParts.items}
        productSourceDictionary={productSourceDictionary}
        createThunk={(data, closeModal) => createPriceSourceHandle(data, closeModal)}
        productSourceTotal={productSourceTotal}
        getProductSourceDictionary={(partId, openModal) => getRemapProductSourceDictionaryHandle(partId, openModal)}
        getProductSourceTotal={(partId, stockQty, manualQty, manualUnitPrice, pricelists) => {
          const params = {
            stockQuantity: stockQty,
            pricelists,
            manualInputQuantity: manualQty,
            manualInputUnitPrice: manualUnitPrice,
          };
          getRemapProductSourceTotalHandle(partId, params);
        }}
        hideManualInput
      />
      <GeneratePurchaseOrderModal
        visible={generatePurchaseModal}
        onCancel={() => setGeneratePurchaseModal(false)}
      />
      <VehicleSection
        name={stockPartRequestVehicle.name}
        info={stockPartRequestVehicle.info}
        regNumber={stockPartRequestVehicle.specificInfo.regNumber}
        chassisNumber={stockPartRequestVehicle.specificInfo.chassisNumber}
        vehicleLocation={stockPartRequestVehicle.specificInfo.location}
        className="stockPartReqVehicleSection"
        horizontal
        bordered
      />
      <StockPartReqTable
        setEditReadyCollected={(sourceId) => {
          setEditReadyCollected(true);
          setSourceId(sourceId);
        }}
        setRemapToExistingPart={(id: number) => {
          setFillInModal(true);
          setSourceId(id);
        }}
        setDistributeQtyHandle={(sourceId: number) => {
          setSourceId(sourceId);
          setDistributeQty(true);
        }}
        setIsMarkNotFromStock={(sourceId: number) => {
          setSourceId(sourceId);
          setIsMarkNotFromStock(true);
        }}
        setConfirmReturnedPartsHandle={(sourceId) => {
          setSourceId(sourceId);
          setConfirmReturnedParts(true);
        }}
      />
    </Layout>
  );
};

export default StockPartRequestViewPage;
