import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosErrorResponse, ResponseResult } from '../../../../core/types/coreTypes';
import { stockApi } from '../../../../api/stockApi';
import { PartsKitsDto, PartsKitsFiltersType } from '../../../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { setDashboardLoading } from '../../../slices/coreSlice';

export const getPartsKitsList = createAsyncThunk<ResponseResult<PartsKitsDto[]>, {
  filters: PartsKitsFiltersType,
  signal?: AbortSignal,
}>(
  'get/partsKitsList',
  async ({ filters, signal }, { rejectWithValue, dispatch }) => {
    dispatch(setDashboardLoading(true));
    try {
      const response = await stockApi.fetchPartsKitsList(
        filters,
        !filters.keyword ? undefined : true,
        !filters.keyword ? undefined : true,
        !filters.keyword ? undefined : true,
        signal,
      );
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
