import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';
import moment from 'moment';
import { RootState } from '../store';
import { getSortIndex } from '../../core/utils/getSortIndex';
import {
  CancelledLinesOrdering,
  FitmentTimeReportOrdering, FitterReturnsEnum,
  FullStockReportOrdering,
  NumberOfConvertedVehiclesReportOrdering,
  OutstandingOverduePurchaseOrdersReportOrdering,
  RectificationReportOrdering,
  RectificationsByProductReportOrdering,
  RectificationsBySupplierReportOrdering,
  SalesOfficeEmployeeReportOrdering, SalesOffsiteWorkingReportOrdering, SalesReportByCustomerOrdering,
  SoldProductsByCustomerReportOrdering,
  SupplierSpendReportOrdering,
  UnfinishedEntitiesReportOrdering,
} from '../../reports/enum/ReportTableOrders';
import { getNo } from '../../core/utils/entityNumberingHandle';
import {
  cancelledLinesReportEntityTypesSelector,
  fitterReturnsEntityTypeSelector,
  jobCategoriesSelector,
  jobTypesSelector,
  jobTypesWithoutSupplyOnlySelector, partRequestLineActivityTypesSelector,
  rectificationFaultTypesSelector, rectificationTypesSelector, salesByCustomerEntityTypesSelector, salesOffsiteEntityTypesSelector,
  shortBusinessAreasSelector,
  shortBusinessAreasWithoutVLRSelector,
  unfinishedEntityTypeSelector,
  usersFilterLookupSelector,
  usersListHadAJobLookupSelector,
} from './coreSelectors';
import {
  customersDictionaryLookupSelector,
  partCategoriesTreeSelector,
  supplierDictionaryLookupSelector,
} from './sharedSelectors';
import { localDateFormatHandler } from '../../core/utils/utcDateFormatHandler';
import {
  fitmentTimeReportsOrderingSelector, fitterReturnsReportOrderingSelector,
  fullStockReportOrderingSelector,
  numberOfConvertedVehiclesOrderingSelector,
  purchasingOutstandingOverduePurchaseOrdersReportOrderingSelector,
  rectificationReportsOrderingSelector,
  rectificationsByProductOrderingSelector,
  rectificationsBySupplierOrderingSelector,
  salesOfficeEmployeeOrderingSelector, salesByCustomerReportOrderingSelector,
  soldProductsByCustomerOrderingSelector,
  supplierSpendReportOrderingSelector,
  unfinishedEntitiesOrderingSelector, salesOffsiteReportOrderingSelector, cancelledLinesReportOrderingSelector,
} from './coreOrderingSelectors';
import {
  enquiryStatusSelector,
  jobInitUIStatusesSelector, jobStatusesSelector,
  orderStatusSelector,
  rectificationStatusesSelector,
} from './coreStatusesSelectors';
import { vehicleProfilesDictionary } from './vehiclesSelectors';
import { UnfinishedEntityTypesEnum } from '../../reports/enum/ReportsEnum';
import { toOptionalFixed, toPoundCurrency } from '../../common/utils/formatUtils';
import { DEFAULT_UI_DATE_FORMAT, DEFAULT_UI_DATE_FORMAT_WITH_TIME } from '../../core/utils/regex';
import { soldProductsByCustomerReportEntityTypesSelector } from './coreEventTypesSelectors';
import { YesNoOptions } from '../../core/utils/testData';
import { RectificationsByProductTableLine } from '../../reports/types/RectificationsByProductTypes';
import { RectificationBySupplierTableLine } from '../../reports/types/RectificationsBySupplierReportTypes';

export const reportIsVisibleSelector = (state: RootState) => state.reports.reportIsVisible;
export const isTableViewSelector = (state: RootState) => state.reports.isTableView;

export const fitmentTimeReportFiltersSelector = (state: RootState) => state.reports.fitmentTimeFilters;

export const fitmentTimeReportGlobalFiltersSelector = createSelector(
  fitmentTimeReportFiltersSelector,
  customersDictionaryLookupSelector,
  jobCategoriesSelector,
  jobTypesWithoutSupplyOnlySelector,
  jobInitUIStatusesSelector,
  usersListHadAJobLookupSelector,
  (
    filters,
    customers,
    jobCategories,
    jobTypes,
    jobStatuses,
    assignees,
  ) => [
    {
      label: 'Customer',
      name: 'customerIds',
      value: filters.customerIds,
      options: customers,
    },
    {
      label: 'Job category',
      name: 'jobCategories',
      value: filters.jobCategories,
      noSearch: true,
      options: jobCategories,
    },
    {
      label: 'Job type',
      name: 'jobTypes',
      value: filters.jobTypes,
      noSearch: true,
      options: jobTypes,
    },
    {
      label: 'Job status',
      name: 'jobStatuses',
      value: filters.jobStatuses,
      noSearch: true,
      options: jobStatuses,
    },
    {
      label: 'Assignee',
      name: 'assigneeIds',
      value: filters.assigneeIds,
      options: assignees,
    },
  ],
);

export const fitmentTimeReportGlobalFiltersDateSelector = createSelector(
  fitmentTimeReportFiltersSelector,
  (filters) => [
    {
      label: 'Creation date',
      name: 'creationDate',
      value: filters.creationDate,
    },
  ],
);

export const fitmentTimeColumnsSelector = createSelector(
  fitmentTimeReportsOrderingSelector,
  (ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Job No',
        dataIndex: 'jobNumber',
        sorter: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.JobNumber, ordering),
      },
      {
        title: 'Job category',
        dataIndex: 'jobCategory',
      },
      {
        title: 'Job type',
        dataIndex: 'jobType',
        sorter: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.JobType, ordering),
      },
      {
        title: 'Job status',
        dataIndex: 'jobStatus',
        sorter: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.JobStatus, ordering),
      },
      {
        title: 'Assignee',
        dataIndex: 'assignee',
        sorter: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.Assignee, ordering),
      },
      {
        title: 'Spent by assignee',
        dataIndex: 'spentByAssigneeMinutes',
        sorter: true,
        isFilterRange: true,
        isTime: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.SpentByAssignee, ordering),
      },
      {
        title: 'Total spent',
        dataIndex: 'totalSpentMinutes',
        sorter: true,
        isFilterRange: true,
        isTime: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.TotalSpent, ordering),
      },
      {
        title: 'Total estimated',
        dataIndex: 'totalEstimatedMinutes',
        sorter: true,
        isFilterRange: true,
        isTime: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.TotalEstimated, ordering),
      },
      {
        title: 'Difference',
        dataIndex: 'differenceMinutes',
        sorter: true,
        isFilterRange: true,
        isTime: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.Difference, ordering),
      },
      {
        title: 'Creation date',
        dataIndex: 'creationDate',
        sorter: true,
        orderField: getSortIndex(FitmentTimeReportOrdering.CreationDate, ordering),
      },
    ];
  },
);

const fitmentTimeReportInitData = (state: RootState) => state.reports.fitmentTimeData;

export const fitmentTimeRangesSelector = createSelector(fitmentTimeReportInitData, (data) => ({
  spentByAssigneeMinutes: { min: data.minSpentByAssigneeMinutes, max: data.maxSpentByAssigneeMinutes },
  totalSpentMinutes: { min: data.minTotalSpentMinutes, max: data.maxTotalSpentMinutes },
  totalEstimatedMinutes: { min: data.minTotalEstimatedMinutes, max: data.maxTotalEstimatedMinutes },
  differenceMinutes: { min: data.minDifferenceMinutes, max: data.maxDifferenceMinutes },
}));

export const fitmentTimeDataSelector = createSelector(
  fitmentTimeReportFiltersSelector,
  fitmentTimeReportInitData,
  jobCategoriesSelector,
  jobTypesSelector,
  jobStatusesSelector,
  (
    filters,
    data,
    jobCategories,
    jobTypes,
    jobStatuses,
  ) => {
    const { page, pageSize } = filters;
    const { pages, totalCount, items } = data;
    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        jobNumber: el.jobNumber,
        jobCategories: el.jobCategories.map((category) => jobCategories.find(({ value }) => value === category)?.label).join(', '),
        jobType: jobTypes.find(({ value }) => value === el.jobType)?.label || '',
        jobStatus: jobStatuses.find(({ value }) => value === el.jobStatus)?.label || '',
        assignee: el.assigneeFullName,
        spentByAssignee: `${toOptionalFixed((+el.spentByAssigneeMinutes / 60), 2)} h`,
        totalSpent: `${toOptionalFixed((+el.totalSpentMinutes / 60), 2)} h`,
        totalEstimated: `${toOptionalFixed((+el.totalEstimatedMinutes / 60), 2)} h`,
        difference: `${toOptionalFixed((+el.differenceMinutes / 60), 2)} h`,
        creationDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.creationDate),
      })),
    };
  },
);

const fitmentTimeDataChartInitSelector = (state: RootState) => state.reports.fitmentTimeDataChart;

export const fitmentTimeDataChartNewSelector = createSelector(fitmentTimeDataChartInitSelector, (data) => {
  const { items } = data;
  const series = [
    {
      name: 'Spent',
      data: items.map(({ totalSpentMinutes }) => ({
        x: 'Spent',
        y: toOptionalFixed(totalSpentMinutes / 60),
      })),
    },
    {
      name: 'Estimated',
      data: items.map(({ totalEstimatedMinutes, totalSpentMinutes }) => ({
        x: 'estimated',
        y: toOptionalFixed(totalEstimatedMinutes / 60),
        fillColor: totalEstimatedMinutes >= totalSpentMinutes ? '#01B050' : '#FF0000',
      })),
    },
  ];
  const categories = items.map(({ customerName }) => customerName);
  const jobNumbers = items.map(({ jobNumber }) => jobNumber);
  const subJobs = items.map(({ subJobs }) => subJobs.map(({ assigneeFullName, spentByAssigneeMinutes }) => ({
    name: assigneeFullName,
    spent: toOptionalFixed(spentByAssigneeMinutes / 60),
  })));

  return {
    series, categories, jobNumbers, subJobs,
  };
});

export const cancelledLinesFiltersSelector = (state: RootState) => state.reports.cancelledLinesFilters;
export const cancelledLinesGlobalFiltersSelector = createSelector(
  cancelledLinesFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasWithoutVLRSelector,
  cancelledLinesReportEntityTypesSelector,
  partRequestLineActivityTypesSelector,
  usersFilterLookupSelector,
  (
    filters,
    customers,
    ba,
    entityTypes,
    activityTypes,
    users,
  ) => [
    {
      label: 'Customer',
      name: 'customerIds',
      value: filters.customerIds,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: ba,
    },
    {
      label: 'Entity type',
      name: 'entityTypes',
      value: filters.entityTypes,
      noSearch: true,
      options: entityTypes,
    },
    {
      label: 'Action initiator',
      name: 'actionInitiatorIds',
      value: filters.actionInitiatorIds,
      noSearch: true,
      options: users,
    },
    {
      label: 'Activity',
      name: 'activityTypes',
      value: filters.activityTypes,
      noSearch: true,
      options: activityTypes,
    },
  ],
);
export const cancelledLinesFiltersDateSelector = createSelector(
  cancelledLinesFiltersSelector,
  (filters) => [
    {
      label: 'Action date',
      name: 'actionDate',
      value: filters.actionDate,
    },
  ],
);

export const cancelledLinesColumnsSelector = createSelector(
  cancelledLinesReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.CustomerName, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(CancelledLinesOrdering.EntityNumber, ordering),
    },
    {
      title: 'Category',
      dataIndex: 'categoryName',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.CategoryName, ordering),
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(CancelledLinesOrdering.PartCode, ordering),
    },
    {
      title: 'Part name',
      dataIndex: 'partName',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(CancelledLinesOrdering.PartName, ordering),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.Quantity, ordering),
    },
    {
      title: 'Activity',
      dataIndex: 'activityType',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.ActivityType, ordering),
    },
    {
      title: 'Action initiator',
      dataIndex: 'actionInitiator',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.ActionInitiator, ordering),
    },
    {
      title: 'Reason',
      dataIndex: 'actionReason',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.ActionReason, ordering),
    },
    {
      title: 'Action date',
      dataIndex: 'actionDate',
      sorter: true,
      orderField: getSortIndex(CancelledLinesOrdering.ActionDate, ordering),
    },
  ],
);

const cancelledLinesDataInitSelector = (state: RootState) => state.reports.cancelledLinesData;
export const cancelledLinesDataSelector = createSelector(
  cancelledLinesDataInitSelector,
  cancelledLinesFiltersSelector,
  cancelledLinesReportEntityTypesSelector,
  ({ items, pages, totalCount }, { page, pageSize }, entityTypes) => ({
    pages,
    totalCount,
    items: items.map((el, i) => ({
      number: getNo(page, pageSize, i),
      customerId: el.customerId,
      customerName: el.customerName,
      entityId: el.entityId,
      entityType: entityTypes.find(({ value }) => value === el.entityType)?.label || '-',
      entityNumber: el.entityNumber,
      categoryName: el.categoryName,
      partId: el.partId,
      partCode: el.partCode,
      partName: el.partName,
      quantity: el.quantity,
      activityType: el.activityType,
      actionInitiator: el.actionInitiatorFullName,
      reason: el.actionReason,
      actionDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.actionDate),
    })),
  }),
);

export const fitterReturnsFiltersSelector = (state: RootState) => state.reports.fitterReturnsFilters;
export const fitterReturnsGlobalFiltersSelector = createSelector(
  fitterReturnsFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasWithoutVLRSelector,
  jobTypesWithoutSupplyOnlySelector,
  fitterReturnsEntityTypeSelector,
  usersFilterLookupSelector,
  (filters, customers, ba, jobTypesWithoutSupplyOnly, entityTypes, users) => {
    return [
      {
        label: 'Customers',
        name: 'customerIds',
        value: filters.customerIds,
        options: customers,
      },
      {
        label: 'Business area',
        name: 'businessAreas',
        value: filters.businessAreas,
        noSearch: true,
        options: ba,
      },
      {
        label: 'Entity type',
        name: 'entityType',
        value: filters.entityType,
        noSearch: true,
        options: entityTypes,
      },
      {
        label: 'Job type',
        name: 'jobType',
        value: filters.jobType,
        noSearch: true,
        options: jobTypesWithoutSupplyOnly,
      },
      {
        label: 'Entity assignee',
        name: 'assignees',
        value: filters.assignees,
        options: users,
      },
      {
        label: 'Deletion initiator',
        name: 'deletionInitiators',
        value: filters.deletionInitiators,
        options: users,
      },
      {
        label: 'Deletion confirmed',
        name: 'deletionConfirmed',
        value: filters.deletionConfirmed,
        noSearch: true,
        options: YesNoOptions,
      },
    ];
  },
);
export const fitterReturnsFiltersDateSelector = createSelector(
  fitterReturnsFiltersSelector,
  (filters) => [
    {
      label: 'Deletion date',
      name: 'deletionDate',
      value: filters.deletionDate,
    },
  ],
);

export const fitterReturnsReportColumnsSelector = createSelector(
  fitterReturnsReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.Customer, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.EntityNumber, ordering),
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.Category, ordering),
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(FitterReturnsEnum.PartCode, ordering),
    },
    {
      title: 'Part name',
      dataIndex: 'partName',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(FitterReturnsEnum.PartName, ordering),
    },
    {
      title: 'Qty',
      dataIndex: 'quantity',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.Quantity, ordering),
    },
    {
      title: 'Deletion initiator',
      dataIndex: 'deletionInitiator',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.DeletionInitiator, ordering),
    },
    {
      title: 'Deletion reason',
      dataIndex: 'deletionReason',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.DeletionReason, ordering),
    },
    {
      title: 'Deletion date',
      dataIndex: 'deletionDate',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.DeletionDate, ordering),
    },
    {
      title: 'Deletion confirmed',
      dataIndex: 'deletionConfirmed',
      sorter: true,
      orderField: getSortIndex(FitterReturnsEnum.DeletionConfirmed, ordering),
    },
  ],
);

export const initFitterReturnsReportDataSelector = (state: RootState) => state.reports.fitterReturnsData;
export const fitterReturnsReportDataSelector = createSelector(
  initFitterReturnsReportDataSelector,
  fitterReturnsEntityTypeSelector,
  fitterReturnsFiltersSelector,
  (data, entityTypes, { page, pageSize }) => {
    return {
      totalCount: data.totalCount,
      pages: data.pages,
      items: data.items.map((item, i) => ({
        number: getNo(page, pageSize, i),
        customerName: item.customer || '-',
        customerId: item.customerId,
        entityNumber: item.entityNumber || '-',
        entityType: entityTypes.find(({ value }) => item.entityType === value)?.label || '',
        entityId: item.entityId,
        category: item.categoryName,
        partCode: item.partCode || '-',
        partId: item.partId,
        partName: item.partName || '-',
        quantity: `${item.quantity || 0}`,
        deletionInitiator: item.deletionInitiatorFullName,
        deletionReason: item.deletionReason || '-',
        deletionDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, item.deletionDate),
        deletionConfirmed: item.deletionConfirmed,
      })),
    };
  },
);

export const outstandingOverduePurchasingReportFiltersSelector = (
  state: RootState,
) => state.reports.outstandingOverduePurchasingReportFilters;
export const outstandingOverduePurchasingReportGlobalFiltersSelector = createSelector(
  outstandingOverduePurchasingReportFiltersSelector,
  usersFilterLookupSelector,
  supplierDictionaryLookupSelector,
  (filters, assignees, suppliers) => {
    return [
      {
        label: 'Supplier',
        name: 'supplierIds',
        value: filters?.supplierIds,
        options: suppliers,
      },
      {
        label: 'Assignee',
        name: 'assigneeIds',
        value: filters?.assigneeIds,
        options: assignees,
      },
    ];
  },
);

export const outstandingOverduePurchasingReportColumnsSelector = createSelector(
  purchasingOutstandingOverduePurchaseOrdersReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Supplier',
      dataIndex: 'supplierName',
      sorter: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.Supplier, ordering),
    },
    {
      title: 'Purchase order No',
      dataIndex: 'purchaseOrderNumber',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.PurchaseOrderNumber, ordering),
    },
    {
      title: 'Purchase order date',
      dataIndex: 'purchaseOrderDate',
      sorter: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.PurchaseOrderDate, ordering),
    },
    {
      title: 'Cost net',
      dataIndex: 'costNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.CostNet, ordering),
    },
    {
      title: 'Cost gross',
      dataIndex: 'costGross',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.CostGross, ordering),
    },
    {
      title: 'Due date',
      dataIndex: 'dueDate',
      sorter: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.DueDate, ordering),
    },
    {
      title: 'Assignee',
      dataIndex: 'assigneeFullName',
      sorter: true,
      orderField: getSortIndex(OutstandingOverduePurchaseOrdersReportOrdering.Assignee, ordering),
    },
  ],
);

export const outstandingOverduePurchasingReportGlobalFiltersDateSelector = createSelector(
  outstandingOverduePurchasingReportFiltersSelector,
  (filters) => [
    {
      label: 'Purchase order date',
      name: 'purchaseOrderDate',
      value: filters?.purchaseOrderDate,
    },
    {
      label: 'Due date',
      name: 'dueDate',
      value: filters?.dueDate,
    },
  ],
);

export const initOutstandingOverduePurchasingReportDataSelector = (
  state: RootState,
) => state.reports.outstandingOverduePurchasingReportData;
export const outstandingOverduePurchasingReportDataSelector = createSelector(
  initOutstandingOverduePurchasingReportDataSelector,
  outstandingOverduePurchasingReportFiltersSelector,
  (data, { page, pageSize }) => {
    return {
      totalCount: data.totalCount,
      pages: data.pages,
      items: data.items.map((item, i) => ({
        number: getNo(page, pageSize, i),
        supplierName: item.supplierName || '-',
        purchaseOrderNumber: item.purchaseOrderNumber || '-',
        purchaseOrderDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, item.purchaseOrderDate),
        costNet: toPoundCurrency(item.costNet),
        costGross: toPoundCurrency(item.costGross),
        dueDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, item.dueDate),
        assigneeFullName: item.assigneeFullName || '-',
      })),
    };
  },
);

export const outstandingOverduePurchasingReportRangesSelector = createSelector(
  initOutstandingOverduePurchasingReportDataSelector,
  (data) => {
    return {
      costNet: {
        min: +data.minCostNet.toFixed(2), max: +data.maxCostNet.toFixed(2),
      },
      costGross: {
        min: +data.minCostGross.toFixed(2), max: +data.maxCostGross.toFixed(2),
      },
    };
  },
);

export const rectificationReportFiltersSelector = (state: RootState) => state.reports.rectificationReportFilters;

export const rectificationReportGlobalFiltersSelector = createSelector(
  rectificationReportFiltersSelector,
  rectificationTypesSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasSelector,
  rectificationFaultTypesSelector,
  usersFilterLookupSelector,
  (
    filters,
    rectificationTypes,
    customers,
    businessAreas,
    faultTypes,
    assignees,
  ) => [
    {
      label: 'Rectification type',
      name: 'rectificationTypes',
      value: filters.rectificationTypes,
      noSearch: true,
      options: rectificationTypes,
    },
    {
      label: 'Customer',
      name: 'customers',
      value: filters.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Fault type',
      name: 'faultTypes',
      value: filters.faultTypes,
      noSearch: true,
      options: faultTypes,
    },
    {
      label: 'Rectification assignee',
      name: 'assignees',
      value: filters.assignees,
      options: assignees,
    },
    {
      label: 'Job assignee',
      name: 'jobAssignees',
      value: filters.jobAssignees,
      options: assignees,
    },
  ],
);

export const rectificationReportsGlobalFiltersDateSelector = createSelector(
  rectificationReportFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters.completedDate,
    },
  ],
);

export const rectificationReportsColumnsSelector = createSelector(
  rectificationReportsOrderingSelector,
  (ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Linked to',
        dataIndex: 'linkedTo',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.LinkedTo, ordering),
      },
      {
        title: 'Rectification No',
        dataIndex: 'rectificationNo',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.RectificationNo, ordering),
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.Customer, ordering),
      },
      {
        title: 'Rectification type',
        dataIndex: 'rectificationType',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.RectificationType, ordering),
      },
      {
        title: 'Fault type',
        dataIndex: 'faultType',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.FaultType, ordering),
      },
      {
        title: 'Cost net',
        dataIndex: 'costNet',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(RectificationReportOrdering.CostNet, ordering),
      },
      {
        title: 'Sales net',
        dataIndex: 'salesNet',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(RectificationReportOrdering.SalesNet, ordering),
      },
      {
        title: 'Completed date',
        dataIndex: 'completedDate',
        sorter: true,
        orderField: getSortIndex(RectificationReportOrdering.CompletedDate, ordering),
      },
    ];
  },
);

const initRectificationReportDataSelector = (state: RootState) => state.reports.rectificationReportData;
export const rectificationReportRangesSelector = createSelector(initRectificationReportDataSelector, (data) => ({
  salesNet: { min: data.minSalesNet, max: data.maxSalesNet },
  costNet: { min: data.minCostNet, max: data.maxCostNet },
}));

export const rectificationReportDataSelector = createSelector(
  initRectificationReportDataSelector,
  rectificationReportFiltersSelector,
  rectificationTypesSelector,
  rectificationFaultTypesSelector,
  (
    data,
    filters,
    rectificationTypes,
    faultTypes,
  ) => {
    const { items, pages, totalCount } = data;
    const { page, pageSize } = filters;
    return {
      pages,
      totalCount,
      items: items?.map((el, i) => ({
        number: getNo(page, pageSize, i),
        orderId: el.orderId,
        vehicleId: el.vehicleId,
        linkedNumber: el.linkedNumber,
        id: el.id,
        rectificationNumber: el.rectificationNumber,
        customer: el.customerName || '-',
        customerId: el.customerId,
        rectificationType: rectificationTypes.find((r) => r.value === el.rectificationType)?.label || '-',
        faultType: faultTypes.find((f) => f.value === el.faultType)?.label || '-',
        costNet: toPoundCurrency(el.costNet),
        salesNet: toPoundCurrency(el.salesNet),
        completedDate: el.completedDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.completedDate) : '-',
      })) || [],
    };
  },
);

export const rectificationReportDataChartSelector = createSelector(
  initRectificationReportDataSelector,
  (data) => data.items.map((el) => ({
    rectificationNumber: el.rectificationNumber,
    customer: el.customerName,
    costNet: el.costNet || 0,
    salesNet: el.salesNet || 0,
  })),
);

export const rectificationsByProductFiltersSelector = (state: RootState) => state.reports.rectificationsByProductFilters;

export const rectificationsByProductGlobalFiltersSelector = createSelector(
  rectificationsByProductFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasSelector,
  rectificationFaultTypesSelector,
  usersFilterLookupSelector,
  (
    filters,
    customers,
    businessAreas,
    faultTypes,
    assignees,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Fault type',
      name: 'faultTypes',
      value: filters.faultTypes,
      noSearch: true,
      options: faultTypes,
    },
    {
      label: 'Rectification assignee',
      name: 'assignees',
      value: filters.assignees,
      options: assignees,
    },
  ],
);

export const rectificationsByProductTreeSelector = createSelector(
  partCategoriesTreeSelector,
  rectificationsByProductFiltersSelector,
  (tree, filters) => {
    return {
      label: 'Category/Subcategory',
      name: 'subcategories',
      options: tree,
      value: filters.subcategories,
    };
  },
);

export const rectificationsByProductFiltersDateSelector = createSelector(
  rectificationsByProductFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters.completedDate,
    },
  ],
);

export const rectificationsByProductColumnsSelector = createSelector(
  rectificationsByProductOrderingSelector,
  (ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Category',
        dataIndex: 'category',
        sorter: true,
        orderField: getSortIndex(RectificationsByProductReportOrdering.Category, ordering),
      },
      {
        title: 'Subcategory',
        dataIndex: 'subcategory',
        sorter: true,
        orderField: getSortIndex(RectificationsByProductReportOrdering.Subcategory, ordering),
      },
      {
        title: 'Part code',
        dataIndex: 'partCode',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationsByProductReportOrdering.PartCode, ordering),
      },
      {
        title: 'Part name',
        dataIndex: 'partName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationsByProductReportOrdering.PartName, ordering),
      },
      {
        title: 'Used in rectifications',
        dataIndex: 'usedInRectifications',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(RectificationsByProductReportOrdering.UsedInRectifications, ordering),
      },
    ];
  },
);

const rectificationsByProductInitDataSelector = (state: RootState) => state.reports.rectificationsByProductData;
export const rectificationsByProductRangesSelector = createSelector(rectificationsByProductInitDataSelector, (data) => ({
  usedInRectifications: { min: data.minUsedInRectifications, max: data.maxUsedInRectifications },
}));

export const rectificationsByProductDataSelector = createSelector(
  rectificationsByProductInitDataSelector,
  rectificationsByProductFiltersSelector,
  (data, filters) => {
    const { items, pages, totalCount } = data;
    const { page, pageSize } = filters;
    const newItems: RectificationsByProductTableLine[] = items.map((el, i) => ({
      number: getNo(page, pageSize, i),
      category: el.categoryName,
      subcategory: el.subcategoryName,
      partId: el.partId,
      partCode: el.partCode,
      partName: el.partName,
      usedInRectifications: el.usedInRectifications,
    }));
    return { pages, totalCount, items: newItems };
  },
);

export const unfinishedEntitiesFiltersSelector = (state: RootState) => state.reports.unfinishedEntitiesFilters;
const unfinishedEntitiesInitDataSelector = (state: RootState) => state.reports.unfinishedEntitiesData;
export const unfinishedEntitiesSalesNetRangeSelector = createSelector(
  unfinishedEntitiesInitDataSelector,
  (data) => {
    return {
      salesNet: {
        min: +data.minSalesNet.toFixed(2), max: +data.maxSalesNet.toFixed(2),
      },
    };
  },
);

export const unfinishedEntitiesDataSelector = createSelector(
  unfinishedEntitiesFiltersSelector,
  unfinishedEntitiesInitDataSelector,
  unfinishedEntityTypeSelector,
  rectificationStatusesSelector,
  enquiryStatusSelector,
  orderStatusSelector,
  (filters, data, entityTypes, rectificationStatuses, enquiryStatuses, orderStatuses) => {
    const { page, pageSize } = filters;
    const setStatusLabelHandle = (type: number, status: number) => {
      const typeLabel = entityTypes.find((t) => t.value === type)?.label || '';
      if (typeLabel) {
        if (typeLabel === UnfinishedEntityTypesEnum.Enquiry) {
          return enquiryStatuses.find((el) => el.value === status)?.label || '-';
        }
        if (typeLabel === UnfinishedEntityTypesEnum.Order) {
          return orderStatuses.find((el) => el.value === status)?.label || '-';
        } else {
          return rectificationStatuses.find((el) => el.value === status)?.label || '-';
        }
      } else {
        return '-';
      }
    };
    return {
      ...data,
      items: data.items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        entityType: entityTypes.find((type) => type.value === el.entityType)?.label || '',
        entityId: el.entityId,
        entityNumber: el.entityNo,
        customerId: el.customerId,
        customerName: el.customerName || '-',
        entityStatus: setStatusLabelHandle(el.entityType, el.entityStatus),
        salesNet: el.salesNet.toFixed(2),
        creationDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.creationDate),
      })),
    };
  },
);

export const unfinishedEntitiesChartDataSelector = createSelector(unfinishedEntitiesInitDataSelector, (data) => {
  const { items, pages } = data;
  const series = [
    {
      name: 'Sales net value',
      data: items.map(({ salesNet }) => +salesNet.toFixed(2)),
    },
  ];
  const categories = items.map(({ customerName }) => customerName || 'N/A');

  const entities = items.map(({ customerName, entityNo }) => ({ customerName, entityNo }));

  return {
    series, categories, pages, entities,
  };
});

export const unfinishedEntitiesColumnsSelector = createSelector(
  unfinishedEntitiesOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Entity type',
      dataIndex: 'entityType',
      sorter: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.EntityType, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
      sorter: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.EntityNo, ordering),
    },
    {
      title: 'Customer',
      dataIndex: 'customerName',
      sorter: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.Customer, ordering),
    },
    {
      title: 'Entity status',
      dataIndex: 'entityStatus',
      sorter: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.EntityStatus, ordering),
    },
    {
      title: 'Sales net',
      dataIndex: 'salesNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.SalesNet, ordering),
    },
    {
      title: 'Creation date',
      dataIndex: 'creationDate',
      sorter: true,
      orderField: getSortIndex(UnfinishedEntitiesReportOrdering.CreationDate, ordering),
    },
  ],
);

export const unfinishedEntitiesGlobalFiltersListSelector = createSelector(
  unfinishedEntitiesFiltersSelector,
  shortBusinessAreasSelector,
  usersFilterLookupSelector,
  unfinishedEntityTypeSelector,
  customersDictionaryLookupSelector,
  (
    filters,
    businessAreas,
    users,
    entityTypes,
    customers,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters?.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters?.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Entity assignee',
      name: 'assigneeIds',
      value: filters?.assigneeIds,
      options: users,
    },
    {
      label: 'Entity type',
      name: 'entityType',
      value: filters?.entityType,
      options: entityTypes,
    },
  ],
);

export const unfinishedEntitiesDatePickerFiltersListSelector = createSelector(
  unfinishedEntitiesFiltersSelector,
  (filters) => [
    {
      label: 'Creation date',
      name: 'creationDate',
      value: filters.creationDate,
      disabledDate: (current: moment.Moment) => current > moment().endOf('day'),
    },
  ],
);

export const salesOffsiteWorkingFiltersSelector = (state: RootState) => state.reports.salesOffsiteWorkingFilters;

export const salesOffsiteWorkingGlobalFiltersSelector = createSelector(
  salesOffsiteWorkingFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasWithoutVLRSelector,
  salesOffsiteEntityTypesSelector,
  usersFilterLookupSelector,
  (
    filters,
    customers,
    businessAreas,
    entityTypes,
    users,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Entity type',
      name: 'entityTypes',
      value: filters.entityTypes,
      noSearch: true,
      options: entityTypes,
    },
    {
      label: 'Entity assignee',
      name: 'assignees',
      value: filters.assignees,
      noSearch: true,
      options: users,
    },
  ],
);

export const salesOffsiteWorkingFiltersDateSelector = createSelector(
  salesOffsiteWorkingFiltersSelector,
  (filters) => [
    {
      label: 'Creation date',
      name: 'creationDate',
      value: filters?.creationDate,
    },
  ],
);

export const salesOffsiteWorkingColumnsSelector = createSelector(
  salesOffsiteReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Entity type',
      dataIndex: 'entityType',
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.EntityType, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.EntityNumber, ordering),
    },
    {
      title: 'Customer',
      dataIndex: 'customers',
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.Customer, ordering),
    },
    {
      title: 'Address',
      dataIndex: 'address',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.Address, ordering),
    },
    {
      title: 'City/Town',
      dataIndex: 'cityTown',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.CityTown, ordering),
    },
    {
      title: 'Postal code',
      dataIndex: 'postalCode',
      filterSearch: true,
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.PostalCode, ordering),
    },
    {
      title: 'Distance from office',
      dataIndex: 'distanceFromOfficeMiles',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.DistanceFromOfficeMiles, ordering),
    },
    {
      title: 'Entity status',
      dataIndex: 'entityStatus',
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.EntityStatus, ordering),
    },
    {
      title: 'Creation date',
      dataIndex: 'creationDate',
      sorter: true,
      orderField: getSortIndex(SalesOffsiteWorkingReportOrdering.CreationDate, ordering),
    },
  ],
);

const salesOffsiteWorkingInitDataSelector = (state: RootState) => state.reports.salesOffsiteWorkingData;

export const salesOffsiteWorkingRangesSelector = createSelector(salesOffsiteWorkingInitDataSelector, (data) => ({
  distanceFromOfficeMiles: { min: data.minDistanceFromOfficeMiles, max: data.maxDistanceFromOfficeMiles },
}));

export const salesOffsiteWorkingDataSelector = createSelector(
  salesOffsiteWorkingFiltersSelector,
  salesOffsiteWorkingInitDataSelector,
  salesOffsiteEntityTypesSelector,
  (
    { page, pageSize },
    { items, pages, totalCount },
    entityTypes,
  ) => {
    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        entityType: entityTypes.find(({ value }) => value === el.entityType)?.label || '',
        entityId: el.entityId,
        entityNumber: el.entityNumber || '-',
        customerId: el.customerId,
        customerName: el.customerName || '-',
        address: el.address || '-',
        cityTown: el.cityTown || '-',
        postalCode: el.postalCode || '-',
        distance: el.distanceFromOfficeMiles || '0.00',
        status: el.entityStatus || '-',
        creationDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.creationDate),
        lat: el.jobAddressLatitude,
        lng: el.jobAddressLongitude,
      })),
    };
  },
);

export const salesOffsiteWorkingDataWithLocationSelector = createSelector(salesOffsiteWorkingInitDataSelector, (data) => {
  return data.items
    .filter(({ jobAddressLatitude, jobAddressLongitude }) => jobAddressLatitude && jobAddressLongitude)
    .map((el) => ({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lat: el.jobAddressLatitude!,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lng: el.jobAddressLongitude!,
      entityNumber: el.entityNumber || '-',
      address: `${el.address}${el.cityTown ? `, ${el.cityTown}` : ''}${el.postalCode ? `, ${el.postalCode}` : ''}`,
      distance: `${el.distanceFromOfficeMiles} mile(s)`,
    }));
});

export const numberOfConvertedVehiclesFiltersSelector = (state: RootState) => state.reports.numberOfConvertedVehiclesFilters;

export const numberOfConvertedVehiclesGlobalFiltersSelector = createSelector(
  numberOfConvertedVehiclesFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasSelector,
  jobTypesSelector,
  (
    filters,
    customers,
    businessAreas,
    jobTypes,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters?.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters?.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Job types',
      name: 'jobTypes',
      value: filters?.jobTypes,
      noSearch: true,
      options: jobTypes,
    },
  ],
);

export const numberOfConvertedVehiclesFiltersDateSelector = createSelector(
  numberOfConvertedVehiclesFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters?.completedDate,
    },
  ],
);

export const numberOfConvertedVehiclesColumnsSelector = createSelector(
  numberOfConvertedVehiclesOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Business area',
      dataIndex: 'businessArea',
      sorter: true,
      orderField: getSortIndex(NumberOfConvertedVehiclesReportOrdering.BusinessAreaThenJobType, ordering),
    },
    {
      title: 'Job type',
      dataIndex: 'jobType',
    },
    {
      title: 'Converted vehicles',
      dataIndex: 'convertedVehicles',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(NumberOfConvertedVehiclesReportOrdering.ConvertedVehicles, ordering),
    },
  ],
);

const numberOfConvertedVehiclesInitDataSelector = (state: RootState) => state.reports.numberOfConvertedVehiclesData;

export const numberOfConvertedVehiclesDataSelector = createSelector(
  numberOfConvertedVehiclesInitDataSelector,
  shortBusinessAreasSelector,
  jobTypesSelector,
  (
    data,
    businessAreas,
    jobTypes,
  ) => {
    return data.items.map((el, i) => ({
      number: i + 1,
      businessArea: businessAreas.find((ba) => ba.value === el.businessArea)?.label || '-',
      jobType: jobTypes.find((type) => type.value === el.jobType)?.label || '-',
      convertedVehicles: el.convertedVehicles,
    }));
  },
);

export const numberOfConvertedVehiclesChartSelector = createSelector(
  numberOfConvertedVehiclesInitDataSelector,
  shortBusinessAreasSelector,
  jobTypesSelector,
  (
    data,
    businessAreas,
    jobTypes,
  ) => {
    const jobTypesList = uniqBy(data.items, 'jobType').map(({ jobType }) => jobType);
    const businessAreasList = uniqBy(data.items, 'businessArea').map(({ businessArea }) => businessArea);

    const series = jobTypesList.map((jobType) => ({
      name: jobTypes.find((j) => j.value === jobType)?.label as string,
      data: data.items
        .filter((item) => item.jobType === jobType)
        .map(({ convertedVehicles }) => convertedVehicles),
    }));
    const categories = businessAreasList.map((ba) => businessAreas.find((el) => el.value === ba)?.label as string);

    return { series, categories };
  },
);

export const numberOfConvertedVehiclesRangesSelector = createSelector(numberOfConvertedVehiclesInitDataSelector, (data) => ({
  convertedVehicles: { min: data.minConvertedVehicles, max: data.maxConvertedVehicles },
}));

export const salesOfficeEmployeeFiltersSelector = (state: RootState) => state.reports.salesOfficeEmployeeFilters;

export const salesOfficeEmployeeGlobalFiltersSelector = createSelector(
  salesOfficeEmployeeFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasSelector,
  usersFilterLookupSelector,
  (
    filters,
    customers,
    businessAreas,
    users,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters.customers,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Initial assignee',
      name: 'initialAssignees',
      value: filters.initialAssignees,
      options: users,
    },
    {
      label: 'Cancellation initiator',
      name: 'cancellationInitiators',
      value: filters.cancellationInitiators,
      options: users,
    },
  ],
);

export const salesOfficeEmployeeFiltersDateSelector = createSelector(
  salesOfficeEmployeeFiltersSelector,
  (filters) => [
    {
      label: 'Creation date',
      name: 'creationDate',
      value: filters.creationDate,
      disabledDate: (current: moment.Moment) => current > moment().endOf('day'),
    },
  ],
);

export const salesOfficeEmployeeColumnsSelector = createSelector(
  salesOfficeEmployeeOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Order No',
      dataIndex: 'orderNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.OrderNumber, ordering),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.Customer, ordering),
    },
    {
      title: 'Sales net',
      dataIndex: 'salesNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.SalesNet, ordering),
    },
    {
      title: 'Days in New status',
      dataIndex: 'daysInNewStatus',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.DaysInNewStatus, ordering),
    },
    {
      title: 'Initial assignee',
      dataIndex: 'initialAssignee',
      sorter: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.InitialAssignee, ordering),
    },
    {
      title: 'Order status',
      dataIndex: 'orderStatus',
      sorter: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.OrderStatus, ordering),
    },
    {
      title: 'Creation date',
      dataIndex: 'creationDate',
      sorter: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.CreationDate, ordering),
    },
    {
      title: 'Cancellation date',
      dataIndex: 'cancellationDate',
      sorter: true,
      orderField: getSortIndex(SalesOfficeEmployeeReportOrdering.CancellationDate, ordering),
    },
  ],
);

const salesOfficeEmployeeDataInitSelector = (state: RootState) => state.reports.salesOfficeEmployeeData;
export const salesOfficeEmployeeRangesSelector = createSelector(salesOfficeEmployeeDataInitSelector, (data) => ({
  salesNet: { min: data.minSalesNet, max: data.maxSalesNet },
  daysInNewStatus: { min: data.minDaysInNewStatus, max: data.maxDaysInNewStatus },
}));

export const salesOfficeEmployeeDataSelector = createSelector(
  salesOfficeEmployeeDataInitSelector,
  salesOfficeEmployeeFiltersSelector,
  orderStatusSelector,
  (data, filters, orderStatuses) => {
    const { pages, totalCount, items } = data;
    const { page, pageSize } = filters;
    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        orderNumber: el.orderNumber,
        customer: el.customerName,
        salesNet: el.salesNet,
        daysInNewStatus: el.daysInNewStatus,
        initialAssignee: el.initialAssigneeFullName,
        orderStatus: orderStatuses.find((status) => status.value === el.orderStatus)?.label || '-',
        creationDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.creationDate),
        cancellationDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.cancellationDate),
      })),
    };
  },
);

export const salesOfficeEmployeeChartPagingSelector = (state: RootState) => state.reports.salesOfficeEmployeeChartPaging;

const salesOfficeEmployeeDataChartInitSelector = (state: RootState) => state.reports.salesOfficeEmployeeDataChart;

export const salesOfficeEmployeeDataChartSelector = createSelector(salesOfficeEmployeeDataChartInitSelector, (data) => {
  const { items, pages } = data;
  const series = [
    {
      name: 'Created orders',
      data: items.map(({ orderCreationsCount }) => orderCreationsCount),
    },
    {
      name: 'Cancelled orders',
      data: items.map(({ orderCancellationsCount }) => orderCancellationsCount),
    },
  ];
  const categories = items.map((el) => localDateFormatHandler('DD-MMM-YY', el.date));

  return { series, categories, pages };
});

export const salesByCustomerFiltersSelector = (state: RootState) => state.reports.salesByCustomerFilters;

export const salesReportByCustomerGlobalFiltersSelector = createSelector(
  salesByCustomerFiltersSelector,
  salesByCustomerEntityTypesSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasSelector,
  jobTypesSelector,
  (
    filters,
    entityTypes,
    customers,
    businessAreas,
    jobTypes,
  ) => [
    {
      label: 'Entity type',
      name: 'entityTypes',
      value: filters.entityTypes,
      noSearch: true,
      options: entityTypes,
    },
    {
      label: 'Customer',
      name: 'customerIds',
      value: filters.customerIds,
      options: customers,
    },
    {
      label: 'Billing customer',
      name: 'billingCustomerIds',
      value: filters.billingCustomerIds,
      options: customers,
    },
    {
      label: 'Shipping customer',
      name: 'shippingCustomerIds',
      value: filters.shippingCustomerIds,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Job type',
      name: 'jobTypes',
      value: filters.jobTypes,
      noSearch: true,
      options: jobTypes,
    },
  ],
);

export const salesReportByCustomerDateFiltersSelector = createSelector(
  salesByCustomerFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters.completedDate,
    },
  ],
);

export const salesReportByCustomerColumnsSelector = createSelector(
  salesByCustomerReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Entity type',
      dataIndex: 'entityType',
      sorter: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.EntityType, ordering),
    },
    {
      title: 'Entity No',
      dataIndex: 'entityNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.EntityNumber, ordering),
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
      sorter: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.Customer, ordering),
    },
    {
      title: 'Billing customer',
      dataIndex: 'billingCustomer',
      sorter: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.BillingCustomer, ordering),
    },
    {
      title: 'Shipping customer',
      dataIndex: 'shippingCustomer',
      sorter: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.ShippingCustomer, ordering),
    },
    {
      title: 'Cost net',
      dataIndex: 'costNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.CostNet, ordering),
    },
    {
      title: 'Sales net',
      dataIndex: 'salesNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.SalesNet, ordering),
    },
    {
      title: 'Difference',
      dataIndex: 'differenceNet',
      isFilterRange: true,
    },
    {
      title: 'Completed date',
      dataIndex: 'completedDate',
      sorter: true,
      orderField: getSortIndex(SalesReportByCustomerOrdering.CompletedDate, ordering),
    },
  ],
);

const salesReportByCustomerDataInitSelector = (state: RootState) => state.reports.salesReportByCustomerData;

export const salesReportByCustomerRangesSelector = createSelector(salesReportByCustomerDataInitSelector, (data) => ({
  costNet: { min: data.minCostNet, max: data.maxCostNet },
  salesNet: { min: data.minSalesNet, max: data.maxSalesNet },
  differenceNet: { min: data.minDifferenceNet, max: data.maxDifferenceNet },
}));

export const salesReportByCustomerDataSelector = createSelector(
  salesByCustomerFiltersSelector,
  salesReportByCustomerDataInitSelector,
  salesByCustomerEntityTypesSelector,
  (
    { page, pageSize },
    { items, pages, totalCount },
    entityTypes,
  ) => {
    return {
      pages,
      totalCount,
      items: items.slice(0, pageSize).map((el, i) => ({
        number: getNo(page, pageSize, i),
        entityType: entityTypes.find(({ value }) => value === el.entityType)?.label || '-',
        entityNumber: el.entityNumber,
        entityId: el.entityId,
        customerId: el.customerId,
        customerName: el.customerName,
        billingCustomerId: el.billingCustomerId,
        billingCustomerName: el.billingCustomerName,
        shippingCustomerId: el.shippingCustomerId,
        shippingCustomerName: el.shippingCustomerName,
        costNet: toPoundCurrency(el.costNet),
        salesNet: toPoundCurrency(el.salesNet),
        differenceNet: toPoundCurrency(el.differenceNet),
        completedDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT_WITH_TIME, el.completedDate),
      })),
    };
  },
);

export const salesReportByCustomerDataChartSelector = createSelector(
  salesByCustomerFiltersSelector,
  salesReportByCustomerDataInitSelector,
  ({ pageSize }, { items }) => {
    const categories = items.map(({ customerName }) => customerName || '-');
    const series = [
      {
        name: 'Cost net',
        data: items.map(({ costNet }) => costNet).slice(0, pageSize),
      },
      {
        name: 'Sales net',
        data: items.map(({ salesNet }) => salesNet).slice(0, pageSize),
      },
    ];
    const entityNumbers = items.map(({ entityNumber }) => entityNumber || '').slice(0, pageSize);

    return { categories, series, entityNumbers };
  },
);

export const soldProductsByCustomerFiltersSelector = (state: RootState) => state.reports.soldProductsBySupplierFilters;

export const soldProductsByCustomerGlobalFiltersSelector = createSelector(
  soldProductsByCustomerFiltersSelector,
  customersDictionaryLookupSelector,
  shortBusinessAreasWithoutVLRSelector,
  soldProductsByCustomerReportEntityTypesSelector,
  (
    filters,
    customers,
    businessAreas,
    entityTypes,
  ) => [
    {
      label: 'Customer',
      name: 'customerIds',
      value: filters.customerIds,
      noClear: true,
      options: customers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Entity type',
      name: 'entityTypes',
      value: filters.entityTypes,
      noSearch: true,
      options: entityTypes,
    },
  ],
);

export const soldProductsByCustomerTreeSelector = createSelector(
  soldProductsByCustomerFiltersSelector,
  partCategoriesTreeSelector,
  (filters, tree) => {
    return {
      label: 'Categories/Subcategories',
      name: 'partSubcategoryIds',
      options: tree,
      value: filters.partSubcategoryIds,
    };
  },
);

export const soldProductsByCustomerDateSelector = createSelector(
  soldProductsByCustomerFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters.completedDate,
    },
  ],
);

export const soldProductsByCustomerColumnsSelector = createSelector(
  soldProductsByCustomerOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Customer',
      dataIndex: 'customer',
    },
    {
      title: 'Product No',
      dataIndex: 'productNumber',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(SoldProductsByCustomerReportOrdering.ProductNumber, ordering),
    },
    {
      title: 'Product Name',
      dataIndex: 'productName',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(SoldProductsByCustomerReportOrdering.ProductName, ordering),
    },
    {
      title: 'Quantity',
      dataIndex: 'quantity',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SoldProductsByCustomerReportOrdering.Quantity, ordering),
    },
    {
      title: 'Cost net',
      dataIndex: 'costNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SoldProductsByCustomerReportOrdering.CostNet, ordering),
    },
    {
      title: 'Sales net',
      dataIndex: 'salesNet',
      sorter: true,
      isFilterRange: true,
      orderField: getSortIndex(SoldProductsByCustomerReportOrdering.SalesNet, ordering),
    },
  ],
);

const soldProductsByCustomerDataInitSelector = (state: RootState) => state.reports.soldProductsBySupplierData;

export const soldProductsByCustomerRangesSelector = createSelector(soldProductsByCustomerDataInitSelector, (data) => ({
  quantity: { min: data.minQuantity, max: data.maxQuantity },
  costNet: { min: data.minCostNet, max: data.maxCostNet },
  salesNet: { min: data.minSalesNet, max: data.maxSalesNet },
}));

export const soldProductsByCustomerDataSelector = createSelector(
  soldProductsByCustomerFiltersSelector,
  soldProductsByCustomerDataInitSelector,
  (filters, data) => {
    const { page, pageSize } = filters;
    const { items, pages, totalCount } = data;

    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        customerId: el.customerId,
        customerName: el.customerName,
        partId: el.partId,
        productNumber: el.productNumber,
        productName: el.productName,
        quantity: el.quantity,
        costNet: el.costNet,
        salesNet: el.salesNet,
      })),
    };
  },
);

export const soldProductsByCustomerDataChartSelector = createSelector(
  soldProductsByCustomerFiltersSelector,
  soldProductsByCustomerDataInitSelector,
  soldProductsByCustomerOrderingSelector,
  (filters, data, ordering) => {
    const { items } = data;
    const currentOrdering = ordering.find(({ value }) => value === filters.order?.field)?.label;
    const seriesData = items.map(({ quantity, costNet, salesNet }) => {
      switch (currentOrdering) {
        case SoldProductsByCustomerReportOrdering.Quantity:
          return quantity;
        case SoldProductsByCustomerReportOrdering.CostNet:
          return costNet;
        case SoldProductsByCustomerReportOrdering.SalesNet:
          return salesNet;
        default: return null;
      }
    });

    const series = [{ name: 'Sold', data: seriesData }];
    const categories = items.map(({ productName }) => productName);
    const info = items.map(({ quantity, costNet, salesNet }) => ({ quantity, costNet, salesNet }));

    return { series, categories, info };
  },
);

export const soldProductsByCustomerChartOrderingSelector = createSelector(
  soldProductsByCustomerOrderingSelector,
  (ordering) => {
    const options = [
      {
        label: 'Quantity',
        value: ordering.find((el) => el.label === SoldProductsByCustomerReportOrdering.Quantity)?.value,
      },
      {
        label: 'Cost net',
        value: ordering.find((el) => el.label === SoldProductsByCustomerReportOrdering.CostNet)?.value,
      },
      {
        label: 'Sales net',
        value: ordering.find((el) => el.label === SoldProductsByCustomerReportOrdering.SalesNet)?.value,
      },
    ];
    return options.filter(({ value }) => !!value);
  },
);

export const fullStockColumnsSelector = createSelector(
  fullStockReportOrderingSelector,
  (ordering) => [
    {
      title: 'No',
      dataIndex: 'number',
    },
    {
      title: 'Category',
      dataIndex: 'category',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Category, ordering),
    },
    {
      title: 'Subcategory',
      dataIndex: 'subcategory',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Subcategory, ordering),
    },
    {
      title: 'Part code',
      dataIndex: 'partCode',
      sorter: true,
      filterSearch: true,
      orderField: getSortIndex(FullStockReportOrdering.PartCode, ordering),
    },
    {
      title: 'Part name',
      dataIndex: 'partName',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.PartName, ordering),
    },
    {
      title: 'Free',
      dataIndex: 'free',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Free, ordering),
    },
    {
      title: 'Free (without selling prices)',
      dataIndex: 'freeWithoutSellingPrices',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.FreeWithoutSellingPrices, ordering),
    },
    {
      title: 'Reserved',
      dataIndex: 'reserved',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Reserved, ordering),
    },
    {
      title: 'Allocated',
      dataIndex: 'allocated',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Allocated, ordering),
    },
    {
      title: 'Picked',
      dataIndex: 'picked',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Picked, ordering),
    },
    {
      title: 'Collected',
      dataIndex: 'collected',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.Collected, ordering),
    },
    {
      title: 'Due in',
      dataIndex: 'dueIn',
      sorter: true,
      orderField: getSortIndex(FullStockReportOrdering.DueIn, ordering),
    },
  ],
);

export const fullStockReportFiltersSelector = (state: RootState) => state.reports.fullStockReportFilters;

export const fullStockReportGlobalFiltersSelector = createSelector(
  fullStockReportFiltersSelector,
  shortBusinessAreasSelector,
  vehicleProfilesDictionary,
  (
    filters,
    businessAreas,
    vehicleProfiles,
  ) => [
    {
      label: 'Core part',
      name: 'corePart',
      value: filters.corePart,
      noSearch: true,
      options: [
        { value: 'true', label: 'Yes' },
        { value: 'false', label: 'No' },
      ],
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Suitable for vehicles',
      name: 'suitableForVehicles',
      value: filters.suitableForVehicles,
      options: vehicleProfiles,
    },
  ],
);

export const fullStockReportTreeSelector = createSelector(
  fullStockReportFiltersSelector,
  partCategoriesTreeSelector,
  (filters, tree) => {
    return {
      label: 'Categories/Subcategories',
      name: 'subcategories',
      options: tree,
      value: filters.subcategories,
    };
  },
);

const fullStockReportInitDataSelector = (state: RootState) => state.reports.fullStockReportData;
export const fullStockReportDataSelector = createSelector(
  fullStockReportInitDataSelector,
  fullStockReportFiltersSelector,
  (data, filters) => {
    const { pages, totalCount, items } = data;
    const { page, pageSize } = filters;
    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        categoryName: el.categoryName,
        subcategoryName: el.subcategoryName,
        partCode: el.partCode,
        partName: el.partName,
        free: el.free,
        freeWithoutSellingPrices: el.freeWithoutSellingPrices,
        reserved: el.reserved,
        allocated: el.allocated,
        picked: el.picked,
        collected: el.collected,
        dueIn: el.dueIn,
      })),
    };
  },
);

export const supplierSpendFiltersSelector = (state: RootState) => state.reports.supplierSpendFilters;

export const supplierSpendGlobalFiltersSelector = createSelector(
  supplierSpendFiltersSelector,
  supplierDictionaryLookupSelector,
  (filters, suppliers) => [
    {
      label: 'Supplier',
      name: 'suppliers',
      value: filters.suppliers,
      options: suppliers,
    },
  ],
);

export const supplierSpendFiltersTreeSelector = createSelector(
  partCategoriesTreeSelector,
  supplierSpendFiltersSelector,
  (tree, filters) => {
    return {
      label: 'Category/Subcategory',
      name: 'subcategories',
      options: tree,
      value: filters.subcategories,
    };
  },
);

export const supplierSpendFiltersDateSelector = createSelector(
  supplierSpendFiltersSelector,
  (filters) => [
    {
      label: 'Purchase order date',
      name: 'purchaseOrderDate',
      value: filters.purchaseOrderDate,
    },
  ],
);

export const supplierSpendColumnsSelector = createSelector(supplierSpendReportOrderingSelector, (ordering) => [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    sorter: true,
    orderField: getSortIndex(SupplierSpendReportOrdering.Supplier, ordering),
  },
  {
    title: 'Purchase order No',
    dataIndex: 'purchaseOrderNumber',
    filterSearch: true,
    sorter: true,
    orderField: getSortIndex(SupplierSpendReportOrdering.PurchaseOrderNumber, ordering),
  },
  {
    title: 'Categories',
    dataIndex: 'category',
  },
  {
    title: 'Subcategories',
    dataIndex: 'subcategory',
  },
  {
    title: 'Cost net',
    dataIndex: 'costNet',
    isFilterRange: true,
    sorter: true,
    orderField: getSortIndex(SupplierSpendReportOrdering.CostNet, ordering),
  },
  {
    title: 'Cost gross',
    dataIndex: 'costGross',
    isFilterRange: true,
    sorter: true,
    orderField: getSortIndex(SupplierSpendReportOrdering.CostGross, ordering),
  },
  {
    title: 'Purchase order date',
    dataIndex: 'purchaseOrderDate',
    sorter: true,
    orderField: getSortIndex(SupplierSpendReportOrdering.PurchaseOrderDate, ordering),
  },
]);

const supplierSpendInitData = (state: RootState) => state.reports.supplierSpendData;

export const supplierSpendRangesSelector = createSelector(supplierSpendInitData, (data) => ({
  costNet: { min: data.minCostNet, max: data.maxCostNet },
  costGross: { min: data.minCostGross, max: data.maxCostGross },
}));

export const supplierSpendDataSelector = createSelector(
  supplierSpendFiltersSelector,
  supplierSpendInitData,
  (filters, data) => {
    const { page, pageSize } = filters;
    const { items, pages, totalCount } = data;

    return {
      pages,
      totalCount,
      items: items.map((el, i) => ({
        number: getNo(page, pageSize, i),
        supplier: el.supplierName || '-',
        purchaseOrderNumber: el.purchaseOrderNumber || '-',
        categories: el.categories.join(', '),
        subcategories: el.subcategories.join(', '),
        costNet: el.costNet,
        costGross: el.costGross,
        purchaseOrderDate: localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, el.purchaseOrderDate),
      })),
    };
  },
);

export const rectificationsBySupplierFiltersSelector = (state: RootState) => state.reports.rectificationsBySupplierFilters;

export const rectificationsBySupplierGlobalFiltersSelector = createSelector(
  rectificationsBySupplierFiltersSelector,
  customersDictionaryLookupSelector,
  supplierDictionaryLookupSelector,
  shortBusinessAreasSelector,
  rectificationFaultTypesSelector,
  usersFilterLookupSelector,
  (
    filters,
    customers,
    suppliers,
    businessAreas,
    faultTypes,
    assignees,
  ) => [
    {
      label: 'Customer',
      name: 'customers',
      value: filters.customers,
      options: customers,
    },
    {
      label: 'Supplier',
      name: 'suppliers',
      value: filters.suppliers,
      options: suppliers,
    },
    {
      label: 'Business area',
      name: 'businessAreas',
      value: filters.businessAreas,
      noSearch: true,
      options: businessAreas,
    },
    {
      label: 'Fault type',
      name: 'faultTypes',
      value: filters.faultTypes,
      noSearch: true,
      options: faultTypes,
    },
    {
      label: 'Rectification assignee',
      name: 'assignees',
      value: filters.assignees,
      options: assignees,
    },
  ],
);

export const rectificationsBySupplierTreeSelector = createSelector(
  partCategoriesTreeSelector,
  rectificationsBySupplierFiltersSelector,
  (tree, filters) => {
    return {
      label: 'Category/Subcategory',
      name: 'subcategories',
      options: tree,
      value: filters.subcategories,
    };
  },
);

export const rectificationsBySupplierFiltersDateSelector = createSelector(
  rectificationsBySupplierFiltersSelector,
  (filters) => [
    {
      label: 'Completed date',
      name: 'completedDate',
      value: filters.completedDate,
    },
  ],
);

export const rectificationsBySupplierColumnsSelector = createSelector(
  rectificationsBySupplierOrderingSelector,
  (ordering) => {
    return [
      {
        title: 'No',
        dataIndex: 'number',
      },
      {
        title: 'Supplier',
        dataIndex: 'supplier',
        sorter: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.Supplier, ordering),
      },
      {
        title: 'Category',
        dataIndex: 'category',
        sorter: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.Category, ordering),
      },
      {
        title: 'Subcategory',
        dataIndex: 'subcategory',
        sorter: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.Subcategory, ordering),
      },
      {
        title: 'Part code',
        dataIndex: 'partCode',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.PartCode, ordering),
      },
      {
        title: 'Part name',
        dataIndex: 'partName',
        sorter: true,
        filterSearch: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.PartName, ordering),
      },
      {
        title: 'Used in rectifications',
        dataIndex: 'usedInRectifications',
        sorter: true,
        isFilterRange: true,
        orderField: getSortIndex(RectificationsBySupplierReportOrdering.UsedInRectifications, ordering),
      },
    ];
  },
);

const rectificationsBySupplierInitDataSelector = (state: RootState) => state.reports.rectificationsBySupplierData;
export const rectificationsBySupplierRangesSelector = createSelector(rectificationsBySupplierInitDataSelector, (data) => ({
  usedInRectifications: { min: data.minUsedInRectifications, max: data.maxUsedInRectifications },
}));

export const rectificationsBySupplierDataSelector = createSelector(
  rectificationsBySupplierInitDataSelector,
  rectificationsBySupplierFiltersSelector,
  (data, filters) => {
    const { items, pages, totalCount } = data;
    const { page, pageSize } = filters;
    const newItems: RectificationBySupplierTableLine[] = items.map((el, i) => ({
      number: getNo(page, pageSize, i),
      supplierName: el.supplierName,
      category: el.categoryName,
      subcategory: el.subcategoryName,
      partId: el.partId,
      partCode: el.partCode,
      partName: el.partName,
      usedInRectifications: el.usedInRectifications,
    }));
    return { pages, totalCount, items: newItems };
  },
);
