import { useAppSelector } from '../../store/hooks';
import {
  vehicleManufacturersDictionarySelector,
  vehicleProfilesGroupsUiSelector,
} from '../../store/selectors/sharedSelectors';
import {
  vehicleCatalogSourceSelector,
  vehicleFlowsUiSelector, vehicleFlowValuesSelector, vehicleInfoSourcesSelector, vehicleManualInputSelector,
  vehicleProfilesTypesUiSelector, vehicleSlidingDoorsQuantitiesSelector,
} from '../../store/selectors/coreSelectors';
import { vehicleStatusValuesSelector } from '../../store/selectors/coreStatusesSelectors';

export const useVehicleDictionaries = () => {
  const vehicleSlidingDoorsQuantities = useAppSelector(vehicleSlidingDoorsQuantitiesSelector);
  const vehicleInfoSources = useAppSelector(vehicleInfoSourcesSelector);
  const vehicleCatalogSource = useAppSelector(vehicleCatalogSourceSelector);
  const vehicleManualInput = useAppSelector(vehicleManualInputSelector);
  const vehicleProfilesTypes = useAppSelector(vehicleProfilesTypesUiSelector);
  const vehicleProfilesGroups = useAppSelector(vehicleProfilesGroupsUiSelector);
  const vehicleFlows = useAppSelector(vehicleFlowsUiSelector);
  const { onSite, offSite } = useAppSelector(vehicleFlowValuesSelector);
  const vehicleManufacturers = useAppSelector(vehicleManufacturersDictionarySelector);
  const { inRepair } = useAppSelector(vehicleStatusValuesSelector);

  return {
    vehicleSlidingDoorsQuantities,
    vehicleInfoSources,
    vehicleCatalogSource,
    vehicleProfilesTypes,
    vehicleProfilesGroups,
    vehicleFlows,
    vehicleManualInput,
    vehicleManufacturers,
    vehicleInRepairStatus: inRepair,
    offSiteVehicleFlow: offSite,
    onSiteVehicleFlow: onSite,
  };
};
