import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import Pagination from '../../../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../../../core/utils/regex';
import Button from '../../../../core/components/button/Button';
import InvoicesAndCreditNotesTable from './table/InvoicesAndCreditNotesTable';
import { setInvoicesAndCreditNotesFilters } from '../../../../store/slices/invoicingSlice';
import AddInvoiceModal from '../../../invoicing-purchases/view-page/modals/add-invoice/AddInvoiceModal';
import { getInvoiceDocumentOperationTypesThunk } from '../../../../store/thunks/coreThunk';
import {
  deleteInvoicesAndCreditNotesThunk,
  getInvoicesAndCreditNotesThunk,
} from '../../../../store/thunks/invoicing/invoicingPurchasesThunks';
import {
  invoicesAndCreditNotesFiltersSelector,
  invoicesAndCreditNotesSelector,
  purchaseViewCreditNotesSummarySelector, salesViewCreditNotesSummarySelector,
} from '../../../../store/selectors/invoicingSelectors';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';
import { invoiceDocumentTypeValuesSelector } from '../../../../store/selectors/coreSelectors';
import { useDefaultPagingAndOrdering } from '../../../../core/hooks/useDefaultPagingAndOrdering';
import { getInvoiceDocumentsOrderingThunk } from '../../../../store/thunks/core/coreOrderingThunks';
import { invoiceDocumentsOrderingSelector } from '../../../../store/selectors/coreOrderingSelectors';
import { InvoiceDocumentsOrdering } from '../../../container/enums/InvoicingSharedEnums';
import InvoicingSummary from '../invoicing-summary/InvoicingSummary';

const InvoicesAndCreditNotesContent: React.FC = () => {
  const { id } = useParams();
  const { pathname } = useLocation();
  const dispatch = useAppDispatch();
  const ordering = useAppSelector(invoiceDocumentsOrderingSelector);
  const { invoice, creditNote } = useAppSelector(invoiceDocumentTypeValuesSelector);
  const filters = useAppSelector(invoicesAndCreditNotesFiltersSelector);
  const { totalCount } = useAppSelector(invoicesAndCreditNotesSelector);
  const purchaseSummary = useAppSelector(purchaseViewCreditNotesSummarySelector);
  const salesSummary = useAppSelector(salesViewCreditNotesSummarySelector);
  const [modalType, setModalType] = useState<number | undefined>(undefined);
  const [idToEdit, setIdToEdit] = useState<number | undefined>(undefined);
  const [idToDelete, setIdToDelete] = useState<number | undefined>(undefined);

  const isSales = pathname.includes('sales');

  const modalButtons = [
    { label: 'Add invoice', value: invoice },
    { label: 'Add credit note', value: creditNote },
  ];

  useDefaultPagingAndOrdering({
    filters,
    ordering,
    getOrderingThunk: getInvoiceDocumentsOrderingThunk,
    initialOrdering: InvoiceDocumentsOrdering.CreationDate,
    setFiltersAction: setInvoicesAndCreditNotesFilters,
  });

  useEffect(() => {
    dispatch(getInvoiceDocumentOperationTypesThunk());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (filters.order?.field && id) {
      dispatch(getInvoicesAndCreditNotesThunk({
        filters: {
          ...filters,
          orderIds: isSales ? [+id] : undefined,
          purchaseOrderIds: isSales ? undefined : [+id],
        },
      }));
    }
    // eslint-disable-next-line
  }, [filters]);

  const deleteHandle = () => {
    idToDelete && id && dispatch(deleteInvoicesAndCreditNotesThunk({
      id: idToDelete,
      onFinish: () => setIdToDelete(undefined),
      isSales,
      entityId: +id,
    }));
  };

  return (
    <>
      <ConfirmationModal
        isVisible={!!idToDelete}
        onCancel={() => setIdToDelete(undefined)}
        confirmHandler={deleteHandle}
      />
      <AddInvoiceModal
        visible={!!modalType || !!idToEdit}
        onClose={() => {
          setModalType(undefined);
          setIdToEdit(undefined);
        }}
        type={modalType}
        setType={setModalType}
        idToEdit={idToEdit}
        isSales={isSales}
        isCreditNotesModalType={modalType === creditNote}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          {modalButtons.map(({ label, value }) => (
            <Button
              key={label}
              label={label}
              onClick={() => setModalType(value)}
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              designType="dark"
              reversed
              disabled={!value}
            />
          ))}
        </div>
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => dispatch(setInvoicesAndCreditNotesFilters({ ...filters, page, pageSize }))}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      <InvoicesAndCreditNotesTable setIdToEdit={setIdToEdit} setIdToDelete={setIdToDelete} />
      <InvoicingSummary summary={isSales ? salesSummary : purchaseSummary} />
    </>
  );
};

export default InvoicesAndCreditNotesContent;
