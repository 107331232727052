import React, { useMemo, useState } from 'react';
import { faCalendar } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  employeeDetailsSelector,
  employeesLeaveRequestsFiltersSelector,
} from '../../../../../../store/selectors/employeesSelector';
import { useLoadEmployeesLeaveRequestsData } from '../../../../../leave-request/hooks/useLoadEmployeesLeaveRequestsData';
import EmptyContentList from '../../../../../../core/components/empty-content-list/EmptyContentList';
import { setEmployeesLeaveRequestsFilters } from '../../../../../../store/slices/employeesSlice';
import EmployeeLeaveRequestLine from './EmployeeLeaveRequestLine';
import Selectable from '../../../../../../core/components/selectable/Selectable';
import { leaveRequestsStatusCategories } from '../../../../../../account/components/account-content/leave-requests/utils/data';
import { LeaveRequestsStatusCategoriesEnum } from '../../../../../../core/enums/dictionariesEnums';
import { responsiveEndpointsEnum } from '../../../../../../core/enums/responsiveEndpointsEnum';
import Segmented from '../../../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../../../core/hooks/useScreenWidth';
import './EmployeeLeaveRequests.scss';
import AccountLeaveRequestModal
  from '../../../../../../account/components/account-content/leave-requests/components/leave-request-modal/AccountLeaveRequestModal';
import {
  changeEmployeeLeaveRequestThunk,
  deleteEmployeeLeaveRequestThunk,
} from '../../../../../../store/thunks/employee/employeesLeaveRequestsThunks';
import ConfirmationModal from '../../../../../../core/components/confirmation-modal/ConfirmationModal';
import { accountLeaveRequestSelector, currentUserSelector } from '../../../../../../store/selectors/accountSelectors';
import { BIG_TABLES_PAGE_SIZES } from '../../../../../../core/utils/regex';
import Pagination from '../../../../../../core/components/pagination/Pagination';

const EmployeeLeaveRequests: React.FC = () => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(employeesLeaveRequestsFiltersSelector);
  const leaveRequest = useAppSelector(accountLeaveRequestSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const employeeDetails = useAppSelector(employeeDetailsSelector);

  const isCurrentUserCurrentEmployee = useMemo(() => {
    if (currentUser?.employeeReferenceNumber && employeeDetails?.employee.referenceNumber) {
      return currentUser.employeeReferenceNumber.toString() === employeeDetails.employee.referenceNumber.toString();
    }
    return false;
  }, [currentUser?.employeeReferenceNumber, employeeDetails?.employee.referenceNumber]);

  const { items, totalCount } = useLoadEmployeesLeaveRequestsData();

  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const { windowWidth } = useScreenWitdh();

  const changeStatusCategory = (value: string | number) => {
    dispatch(setEmployeesLeaveRequestsFilters({
      ...filters,
      page: 1,
      isActive: value === LeaveRequestsStatusCategoriesEnum.ACTIVE,
    }));
  };

  return <>
    <AccountLeaveRequestModal
      visible={!!leaveRequest}
      type="edit"
      changeHandle={(id, data, closeModal) => {
        dispatch(changeEmployeeLeaveRequestThunk({
          id,
          data,
          closeModal,
        }));
      }}
    />
    <ConfirmationModal
      isVisible={!!idToDelete}
      onCancel={() => setIdToDelete(null)}
      confirmHandler={() => {
        idToDelete && dispatch(deleteEmployeeLeaveRequestThunk({
          id: idToDelete,
          closeModal: () => setIdToDelete(null),
        }));
      }}
    />
    {!!windowWidth && windowWidth >= responsiveEndpointsEnum.LG
      ? <Segmented
        options={leaveRequestsStatusCategories}
        onChange={changeStatusCategory}
        value={filters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
      />
      : <Selectable
        options={leaveRequestsStatusCategories}
        onChange={changeStatusCategory}
        value={filters.isActive ? LeaveRequestsStatusCategoriesEnum.ACTIVE : LeaveRequestsStatusCategoriesEnum.ARCHIVED}
      />}
    <Pagination
      currentPage={filters.page}
      pageSize={filters.pageSize}
      totalItems={totalCount}
      pageSizeOptions={BIG_TABLES_PAGE_SIZES}
      onChange={(page, pageSize) => dispatch(setEmployeesLeaveRequestsFilters({
        ...filters,
        page,
        pageSize,
      }))}
      className="actions-and-paging__paging"
      offsetBottom
    />
    {items.length > 0
      ? <ul className="lines-list">
        {items.map((el) => (
          <li key={el.id} className="lines-list__item">
            <EmployeeLeaveRequestLine
              data={el}
              setIdToDelete={setIdToDelete}
              isCurrentUserCurrentEmployee={isCurrentUserCurrentEmployee}
            />
          </li>
        ))}
      </ul>
      : <EmptyContentList icon={faCalendar} text="No leave requests" />}
  </>;
};

export default EmployeeLeaveRequests;
