import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import { NAME_REGEX, NI_NUMBER } from '../../../core/utils/regex';

export const NameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
  pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
};

export const TitleSchema = {
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
  pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
};

export const NationalInsuranceNumberSchema = {
  ...RequiredFieldSchema,
  pattern: { value: NI_NUMBER, message: ErrorsEnum.INVALID_NI_NUMBER },
};

export const IceContactFullNameSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 200, message: maxLengthMessageHandle(200) },
  pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
};

export const IceContactRelationshipSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
};

export const JobTitleSchema = {
  ...RequiredFieldSchema,
  maxLength: { value: 100, message: maxLengthMessageHandle(100) },
};

export type EmployeeFields = {
  firstName: string,
  lastName: string,
  title: string | null,
  birthday: string | null,
  gender: string | number,
  nationalInsuranceNumber: string,
  email: string,
  telephone: string | null,
  mobile: string,
  iceContactFullName: string,
  relationship: string,
  iceContactTelephone: string | null,
  jobTitle: string,
  department?: number,
  startDate: string,
  salaryType?: number,
  salaryValue: string,
  annualLeaveEntitlement: string,
  userRole: string,
  userJobType: number[] | undefined,
  userBusinessAreas: number[] | undefined,
  userAssignedEntitiesOption: number | undefined,
};

export const employeeDefaults: EmployeeFields = {
  firstName: '',
  lastName: '',
  title: null,
  birthday: null,
  gender: '',
  nationalInsuranceNumber: '',
  email: '',
  telephone: null,
  mobile: '',
  iceContactFullName: '',
  relationship: '',
  iceContactTelephone: '',
  jobTitle: '',
  department: undefined,
  startDate: '',
  salaryType: undefined,
  salaryValue: '0.00',
  annualLeaveEntitlement: '0.00',
  userRole: '',
  userJobType: undefined,
  userBusinessAreas: undefined,
  userAssignedEntitiesOption: undefined,
};

type Employee = Pick<
EmployeeFields,
'firstName' | 'lastName' | 'title' | 'birthday' | 'nationalInsuranceNumber'
| 'email' | 'telephone' | 'mobile' | 'iceContactFullName' | 'relationship'
| 'iceContactTelephone' | 'jobTitle'
>

type SkillsRestModel = Array<{
  skillId: number,
  level: number,
}>

export type PostEmployeeRestModel = Employee & {
  birthday: string,
  gender: number,
  addressLine1: string | null,
  addressLine2: string | null,
  cityTown: string | null,
  postalCode: string | null,
  region: string | null,
  uploadGuid: string | null,
  fileName: string | null,
  iceContactAddressLine1: string | null,
  iceContactAddressLine2: string | null,
  iceContactCityTown: string | null,
  iceContactPostalCode: string | null,
  iceContactRegion: string | null,
  department: number,
  startDate: string,
  salaryType: number,
  salaryValue: number,
  annualLeaveEntitlement: number,
  skills: SkillsRestModel | null,
}
