import React from 'react';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { useAppSelector } from '../../../../../../store/hooks';
import { reportIsVisibleSelector } from '../../../../../../store/selectors/reportsSelectors';
import Tr from '../../../../../../core/components/table-components/Tr';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import { SalesOfficeEmployeeTableLine } from '../../../../../types/SalesOfficeEmployeeReportTypes';

type SalesOfficeEmployeeBodyProps = {
  data: SalesOfficeEmployeeTableLine[],
  columnsLength: number,
}

const SalesOfficeEmployeeBody: React.FC<SalesOfficeEmployeeBodyProps> = ({ data, columnsLength }) => {
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const truncateKeys = ['orderNumber', 'customer', 'initialAssignee'];

  const renderCellValue = (key: string, value: string | number) => {
    if (truncateKeys.includes(key)) {
      return <TruncateTooltip value={value} table />;
    }
    return value;
  };

  return (
    <Tbody>
      {data.length
        ? data.map((el) => (
          <Tr key={el.number}>
            {Object.entries(el).map(([key, value]) => (
              <TdNew key={key}>
                {renderCellValue(key, value)}
              </TdNew>
            ))}
            <TdNew />
          </Tr>
        ))
        : <EmptyTableSection text={reportIsVisible ? 'No data' : 'Please run a report'} colSpan={columnsLength + 1} />}
    </Tbody>
  );
};

export default SalesOfficeEmployeeBody;
