import { useMemo, useState } from 'react';
import {
  deleteUsersThunk,
  inviteUsersThunk,
  resetUsersPasswordsThunk,
} from '../../../store/thunks/settings/settingsDashboardThunks';
import { setErrorMessage } from '../../../store/slices/coreSlice';
import {
  FORBIDDEN_DELETE_USERS_MESSAGE,
  FORBIDDEN_INVITATION_MESSAGE,
  FORBIDDEN_RESET_PASS_MESSAGE,
} from '../utils/data';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { usersListSelector } from '../../../store/selectors/settingsSelectors';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import {
  ineligibleInviteUsersStatusesSelector,
  ineligibleResetUsersStatusesSelector,
} from '../../../store/selectors/coreStatusesSelectors';
import usePermission from '../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { VoidFunctionType } from '../../../core/types/coreTypes';

export const useUsersListInteractions = (
  checkedKeys: number[],
  uncheckAll: VoidFunctionType,
) => {
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(usersListSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const ineligibleResetUsersStatuses = useAppSelector(ineligibleResetUsersStatusesSelector);
  const ineligibleInviteUsersStatuses = useAppSelector(ineligibleInviteUsersStatusesSelector);
  const allowedToEdit = usePermission(PermissionEnum.UserEditFields);
  const allowedToDelete = usePermission(PermissionEnum.UserDelete);
  const allowedToDeleteEmployee = usePermission(PermissionEnum.EmployeeDelete);

  const [isInvite, setInvite] = useState(false);
  const [isReset, setReset] = useState(false);
  const [isDelete, setDelete] = useState(false);
  const [isDeleteLinkedUser, setIsDeleteLinkedUser] = useState(false);

  const handleDeleteClick = () => {
    if (allowedToDeleteEmployee) {
      const allowedKeys = checkedKeys.filter((key) => key !== currentUser?.id);
      const linkedEmployeeAccountIds = items.filter((el) => allowedKeys.includes(el.id) && el.employeeId);
      linkedEmployeeAccountIds.length > 0 ? setIsDeleteLinkedUser(true) : setDelete(true);
    } else {
      setDelete(true);
    }
  };

  const handleDeleteLinkedUser = (deleteLinkedAccount: boolean) => {
    if (currentUser) {
      const allowedToDeleteKeys = checkedKeys.filter((key) => key !== currentUser.id);
      if (allowedToDeleteKeys.length > 0) {
        if (deleteLinkedAccount) {
          const linkedEmployeeAccountIds = items
            .filter((el) => allowedToDeleteKeys.includes(el.id) && el.employeeId)
            .map((el) => el.employeeId);
          dispatch(deleteUsersThunk({
            userAccountIds: allowedToDeleteKeys,
            deleteLinkedAccount,
            linkedEmployeeAccountIds: linkedEmployeeAccountIds as number[],
            closeModal: () => {
              setIsDeleteLinkedUser(false);
            },
          }));
        } else {
          dispatch(deleteUsersThunk({
            userAccountIds: allowedToDeleteKeys,
            closeModal: () => {
              setIsDeleteLinkedUser(false);
            },
          }));
        }
      } else {
        setDelete(false);
        setTimeout(() => {
          dispatch(setErrorMessage({ message: FORBIDDEN_DELETE_USERS_MESSAGE }));
        }, 1000);
      }
    }
  };
  const deleteUsersHandler = () => {
    if (currentUser) {
      const allowedToDeleteKeys = checkedKeys.filter((key) => key !== currentUser.id);
      if (allowedToDeleteKeys.length > 0) {
        dispatch(deleteUsersThunk({
          userAccountIds: allowedToDeleteKeys,
          closeModal: () => {
            setDelete(false);
          },
        }));
      } else {
        setDelete(false);
        setTimeout(() => {
          dispatch(setErrorMessage({ message: FORBIDDEN_DELETE_USERS_MESSAGE }));
        }, 1000);
      }
    }
  };

  const inviteUsersHandler = () => {
    const checkedUsers = items.filter((u) => checkedKeys.includes(u.id));
    const ids = checkedUsers.filter((user) => !ineligibleInviteUsersStatuses.find((el) => el.value === user.status));
    if (ids.length > 0) {
      dispatch(inviteUsersThunk({
        userAccountIds: ids.map((el) => el.id),
        closeModal: () => {
          uncheckAll();
          setInvite(false);
        },
      }));
    } else {
      setInvite(false);
      setTimeout(() => {
        dispatch(setErrorMessage({ message: FORBIDDEN_INVITATION_MESSAGE }));
      }, 1000);
    }
  };
  const resetUsersPasswordsHandler = () => {
    const checkedUsers = items.filter((u) => checkedKeys.includes(u.id));
    const ids = checkedUsers.filter((user) => !ineligibleResetUsersStatuses.find((el) => el.value === user.status));
    if (ids.length > 0) {
      dispatch(resetUsersPasswordsThunk({
        userAccountIds: ids.map((el) => el.id),
        closeModal: () => {
          uncheckAll();
          setReset(false);
        },
      }));
    } else {
      setReset(false);
      setTimeout(() => dispatch(setErrorMessage({ message: FORBIDDEN_RESET_PASS_MESSAGE })), 1000);
    }
  };

  const actions = [
    {
      label: 'Invite selected',
      onClick: () => setInvite(true),
      disabled: checkedKeys.length === 0,
      renderIf: allowedToEdit,
    },
    {
      label: 'Reset selected',
      onClick: () => setReset(true),
      disabled: checkedKeys.length === 0,
      renderIf: allowedToEdit,
    },
    {
      label: 'Delete selected',
      onClick: handleDeleteClick,
      disabled: checkedKeys.length === 0,
      renderIf: allowedToDelete,
    },
  ];

  const checkedUsers = useMemo(() => items.filter((u) => checkedKeys.includes(u.id)), [checkedKeys, items]);
  const ineligibleResetUsers = useMemo(
    () => checkedUsers.filter((user) => ineligibleResetUsersStatuses.find((el) => el.value === user.status)),
    [checkedUsers, ineligibleResetUsersStatuses],
  );

  const ineligibleInviteUsers = useMemo(
    () => checkedUsers.filter((user) => ineligibleInviteUsersStatuses.find((el) => el.value === user.status)),
    [checkedUsers, ineligibleInviteUsersStatuses],
  );
  // extract current user, if selected
  const numberOfInviteEligibleUsers = useMemo(() => {
    let currentMinusQty = 0;
    if (currentUser && checkedKeys.includes(currentUser?.id)) {
      currentMinusQty += 1;
    }
    if (ineligibleInviteUsers.find((el) => el.id === currentUser?.id)) {
      currentMinusQty -= 1;
    }
    return checkedKeys.length - ineligibleInviteUsers.length - currentMinusQty;
  }, [checkedKeys, ineligibleInviteUsers, currentUser]);
  const numberOfResetEligibleUsers = useMemo(
    () => checkedKeys.length - ineligibleResetUsers.length - (currentUser && checkedKeys.includes(currentUser?.id) ? 1 : 0),
    [checkedKeys, ineligibleResetUsers, currentUser],
  );

  return {
    handleDeleteLinkedUser,
    deleteUsersHandler,
    inviteUsersHandler,
    resetUsersPasswordsHandler,
    actions,
    isInvite,
    setInvite,
    isReset,
    setReset,
    isDelete,
    setDelete,
    isDeleteLinkedUser,
    setIsDeleteLinkedUser,
    numberOfInviteEligibleUsers,
    numberOfResetEligibleUsers,
  };
};
