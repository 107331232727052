import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import Modal from '../../../../../../../core/components/modal/Modal';
import Input from '../../../../../../../core/components/input/Input';
import './ReceivedQuantityModal.scss';
import ReceivedQtyTable from './ReceivedQtyTable';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import {
  purchaseOrderDetailsSelector,
  purchaseOrderPartsListSelector,
} from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { QuoteUnitsOfMeasureSelector } from '../../../../../../../store/selectors/coreSelectors';
import { patchPurchaseOrderPartThunk } from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { FREE_TIP, RESERVED_TIP, SUM_ERROR } from '../../../../utils/data';
import ReceivedNumericField from './ReceivedNumericField';
import { setErrorMessage } from '../../../../../../../store/slices/coreSlice';
import { purchaseOrderStatusObjSelector } from '../../../../../../../store/selectors/coreStatusesSelectors';
import Button from '../../../../../../../core/components/button/Button';

type RecievedQuantityModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  itemToChange?: number,
  setIsLinkToOrder: VoidFunctionType,
}

const RecievedQuantityModal: React.FC<RecievedQuantityModalProps> = ({
  onCancel, isVisible, itemToChange, setIsLinkToOrder,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [failReason, setFailReason] = useState<number | undefined>(undefined);
  const [qtyV, setQtyV] = useState<string>('0');
  const [freeQty, setFreeQty] = useState<string>('0');
  const [freeErr, setFreeErr] = useState<string>('');
  const [reservedQty, setReservedQty] = useState<string>('0');
  const [reservedErr, setReservedErr] = useState<string>('');

  const [totalDue, setTotalDue] = useState<number>(0);
  const [totalReceived, setTotalReceived] = useState<number>(0);
  const [totalReturned, setTotalReturned] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(false);

  const cleanUpHandle = () => {
    setQtyV('0');
    setFailReason(undefined);
  };

  const cancelModalHandle = () => {
    onCancel();
    cleanUpHandle();
    setFreeQty('0');
    setReservedQty('0');
    setFreeErr('');
    setReservedErr('');
    setTotalReceived(0);
    setTotalDue(0);
    setTotalReturned(0);
  };

  const { purchaseOrderParts } = useAppSelector(purchaseOrderPartsListSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);
  const { completed } = useAppSelector(purchaseOrderStatusObjSelector);
  const details = useAppSelector(purchaseOrderDetailsSelector);
  const currentPart = useMemo(() => purchaseOrderParts.find((el) => el.id === itemToChange), [purchaseOrderParts, itemToChange]);
  const measure = useMemo(() => unitsOfMeasure.find((el) => el.value === currentPart?.unitOfMeasure)?.label, [unitsOfMeasure, currentPart]);
  const failedQuantities = currentPart?.failedQuantities;

  const purchaseOrderDetails = details?.purchaseOrder;

  useEffect(() => {
    if (currentPart && isVisible) {
      freeQty === '0' && currentPart?.receivedFreeQuantity && setFreeQty(currentPart?.receivedFreeQuantity.toString());
      reservedQty === '0' && currentPart?.receivedReservedQuantity && setReservedQty(currentPart?.receivedReservedQuantity.toString());
      freeQty === '0' && reservedQty === '0' && currentPart?.totalReceivedQuantity && setTotalReceived(currentPart?.totalReceivedQuantity);
    }
    // eslint-disable-next-line
  }, [currentPart, isVisible]);

  useEffect(() => {
    if (failedQuantities && failedQuantities.length > 0) {
      const result = failedQuantities.reduce((acc, obj) => {
        return acc + obj.quantity;
      }, 0);
      setTotalReturned(result);
    } else setTotalReturned(0);
  }, [failedQuantities]);

  useEffect(() => {
    if (currentPart && currentPart.requestedQuantity > 0) {
      const val = currentPart.requestedQuantity - totalReceived - totalReturned;
      setTotalDue(val);
    }
  }, [totalReceived, totalReturned, currentPart]);

  const patchFreeReservedQty = () => {
    const sumCheck = +freeQty + +reservedQty + (currentPart?.cancelledQuantity || 0);
    if (currentPart?.requestedQuantity && (sumCheck > currentPart?.requestedQuantity)) {
      setTimeout(() => dispatch(setErrorMessage({
        message: SUM_ERROR,
      })), 500);
    } else {
      setIsLoading(true);
      id && itemToChange && dispatch(patchPurchaseOrderPartThunk({
        purchaseOrderId: +id,
        partId: itemToChange,
        values: {
          receivedFreeQuantity: +freeQty,
          receivedReservedQuantity: +reservedQty,
        },
        closeModal: () => {
          setIsLoading(false);
          cancelModalHandle();
        },
        setInit: () => {
          setFreeQty(currentPart?.receivedFreeQuantity?.toString() || '0');
          setReservedQty(currentPart?.receivedReservedQuantity?.toString() || '0');
          setTotalReceived(currentPart?.totalReceivedQuantity || 0);
        },
      }));
    }
  };

  const disabledSave = (currentPart?.dueIn === undefined) || !freeQty || !reservedQty || completed === purchaseOrderDetails?.status;
  return (
    <Modal
      onCancel={cancelModalHandle}
      visible={isVisible}
      title="Enter received quantity"
      width={700}
      destroyOnClose
    >
      <div className="receivedQtyWrap">
        <Input
          label="Purchased quantity"
          value={currentPart?.requestedQuantity.toString() || '0'}
          disabled
          suffix={measure}
          className="receivedQtyWrap__field"
        />
        <section className="receivedQtyWrap__section">
          <Input
            label="Total received"
            value={totalReceived.toFixed(2) || '0'}
            disabled
            suffix={measure}
          />
          <Input
            label="Total returned/not received"
            value={totalReturned.toFixed(2) || '0'}
            disabled
            suffix={measure}
          />
          <Input
            label="Total due in"
            disabled
            suffix={measure}
            value={totalDue.toFixed(2)}
          />
        </section>
        <section className="receivedQtyWrap__sectionDouble">
          <div className="receivedQtyWrap__sectionDouble-wrap">
            <ReceivedNumericField
              currentPart={currentPart}
              value={freeQty}
              setValue={(v) => {
                setFreeQty(v);
                setTotalReceived((+reservedQty) + (+v));
              }}
              err={freeErr}
              setError={setFreeErr}
              label="Received free quantity"
              tip={FREE_TIP}
              disabled={completed === purchaseOrderDetails?.status}
            />
          </div>
          <div className="receivedQtyWrap__sectionDouble-wrap">
            <ReceivedNumericField
              currentPart={currentPart}
              value={reservedQty}
              setValue={(v) => {
                setReservedQty(v);
                setTotalReceived((+freeQty) + (+v));
              }}
              err={reservedErr}
              setError={setReservedErr}
              label="Received reserved quantity"
              tip={RESERVED_TIP}
              disabled={completed === purchaseOrderDetails?.status}
            />
            <Button
              label="Link reserved to orders"
              disabled={!currentPart?.receivedReservedQuantity}
              onClick={setIsLinkToOrder}
            />
          </div>
        </section>
        <ReceivedQtyTable
          currentPart={currentPart}
          measure={measure}
          failedQuantities={failedQuantities}
          failReason={failReason}
          setFailReason={setFailReason}
          setQtyV={setQtyV}
          qtyV={qtyV}
          cleanUpHandle={cleanUpHandle}
          disabled={completed === purchaseOrderDetails?.status}
        />
        <ButtonActions
          createLabel="Save"
          cancelLabel="Cancel"
          createType="button"
          cancelClick={cancelModalHandle}
          createClick={() => patchFreeReservedQty()}
          disabledCreate={disabledSave}
          className="receivedQtyWrap__modal-buttons"
          isLoading={isLoading}
        />
      </div>
    </Modal>
  );
};

export default RecievedQuantityModal;
