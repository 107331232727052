import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  outstandingOverduePurchasingReportFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import { SearchTableFilterType } from '../../../../../../core/types/coreTypes';
import {
  setOutstandingOverduePurchasingReportFilters,
} from '../../../../../../store/slices/reportsSlice';
import Tr from '../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../../core/components/table-components/table-new/ThNew';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import Thead from '../../../../../../core/components/table-components/Thead';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import {
  getPurchasingOutstandingOverduePurchaseOrdersExcelThunk,
  getPurchasingOutstandingOverduePurchaseOrdersThunk,
} from '../../../../../../store/thunks/reports/reportsThunks';
import {
  usePurchasingOutstandingOverduePurchaseOrdersRange,
} from '../../../../../hooks/usePurchasingOutstandingOverduePurchaseOrdersRange';

type PurchasingReportColumnsProps = {
  columns: ICustomColumn[],
  totalCount: number,
}

const OutstandingOverduePurchaseOrdersColumns: React.FC<PurchasingReportColumnsProps> = ({ columns, totalCount }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(outstandingOverduePurchasingReportFiltersSelector);

  const { range, setRange, rangePatterns } = usePurchasingOutstandingOverduePurchaseOrdersRange();
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    purchaseOrderNumber: filters.purchaseOrderNumber,
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
      dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
      dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = { ...filters, [field]: undefined, page: 1 };
      dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
      dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = { ...filters, [field]: searchVl[field]?.trim(), page: 1 };
    dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
    dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const [min, max] = value;
    const newFilters = {
      ...filters,
      [field]: { min, max },
    };
    dispatch(setOutstandingOverduePurchasingReportFilters(newFilters));
    dispatch(getPurchasingOutstandingOverduePurchaseOrdersThunk({ filters: newFilters }));
  };

  const exportToExcel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, pageSize, ...rest } = filters;
    dispatch(getPurchasingOutstandingOverduePurchaseOrdersExcelThunk({ filters: rest }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            totalElements={totalCount}
            className={`outstandingOverduePurchaseOrdersTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
            range={range}
            rangePatterns={rangePatterns}
            rangeStep={1}
            setRange={setRange}
            setRangeHandle={setRangeHandle}
          />
        ))}
        <ThNew action>
          <Dropdown
            disabled={!totalCount}
            menuItems={[
              {
                label: 'Export to Excel',
                icon: <FontAwesomeIcon icon={faFileExcel} />,
                key: 'export-to-excel',
                onClick: exportToExcel,
              },
            ]}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default OutstandingOverduePurchaseOrdersColumns;
