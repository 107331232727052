import { useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { suppliersFiltersListSelector, suppliersFiltersSelector } from '../../../store/selectors/supplierSelector';
import { setSupplierFilters } from '../../../store/slices/supplierSlice';
import { SuppliersFiltersType } from '../types/SuppliersTypes';
import { initPaging } from '../../../core/types/coreTypes';

export const useSuppliersFilters = () => {
  const dispatch = useAppDispatch();
  const filtersList = useAppSelector(suppliersFiltersListSelector);
  const suppliersFilters = useAppSelector(suppliersFiltersSelector);
  const [searchValue, setSearchValue] = useState<string | undefined>(suppliersFilters.keyword);

  const initDropdownSearch = {
    statuses: '',
  };

  const filteredSuppliersHandle = (filters: SuppliersFiltersType) => {
    dispatch(setSupplierFilters({ ...filters, page: 1 }));
  };
  const onClear = () => {
    filteredSuppliersHandle(initPaging);
  };
  return {
    filtersList,
    setSearchValue,
    searchValue,
    initDropdownSearch,
    filteredSuppliersHandle,
    onClear,
  };
};
