import React from 'react';
import { responsiveEndpointsEnum } from '../../../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../../../core/components/selectable/Selectable';
import Segmented from '../../../../../core/components/segmented/Segmented';
import { useScreenWitdh } from '../../../../../core/hooks/useScreenWidth';
import { PartTabsEnum } from '../../../enums/PartTypesEnum';

type PartViewPageTabsProps = {
  tabs: { label: string, value: PartTabsEnum }[],
  partTab: string,
  setModeParams: (value: string) => void,
}

const PartViewPageTabs:React.FC<PartViewPageTabsProps> = ({ tabs, partTab, setModeParams }) => {
  const { windowWidth } = useScreenWitdh();

  return (
    <div className="partViewPageTabs">
      {windowWidth && windowWidth < responsiveEndpointsEnum.XL
        ? <Selectable
          options={tabs}
          value={partTab}
          onChange={(value) => setModeParams(value as string)}
        />
        : <Segmented
          options={tabs}
          value={partTab}
          onChange={(value) => setModeParams(value as string)}
          className={tabs.length === 1 ? 'partViewPageTabs--single' : ''}
        />}
    </div>
  );
};

export default PartViewPageTabs;
