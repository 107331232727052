import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from '../../core/enums/pathEnum';
import Layout from '../../core/components/layout/Layout';
import SupplierFilters from './components/supplier-filters/SupplierFilters';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { SuppliersViewEnum } from './enums/SuppliersViewEnum';
import { suppliersFiltersSelector, suppliersListSelector } from '../../store/selectors/supplierSelector';
import Pagination from '../../core/components/pagination/Pagination';
import { setSupplierFilters, setSuppliersList } from '../../store/slices/supplierSlice';
import SupplierLines from './components/supplier-lines/SupplierLines';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { getSupplierList } from '../../store/thunks/suppliers/supplierThunks';
import { initDataList } from '../../core/types/coreTypes';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { useCancelRequest } from '../../core/hooks/useCancelRequest';

const SuppliersContainer:React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const supplierFilters = useAppSelector(suppliersFiltersSelector);
  const suppliersList = useAppSelector(suppliersListSelector);
  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    cancelRequest();
    dispatch(getSupplierList({
      filters: supplierFilters, isDashboard: true, signal: controller.current?.signal,
    }));
    // eslint-disable-next-line
  }, [supplierFilters]);

  const [view, setView] = useState<string | number>(SuppliersViewEnum.DASHBOARD);
  const viewHandle = (key: string | number) => {
    if (key === SuppliersViewEnum.DASHBOARD) {
      navigate(`/${routesPath.SUPPLIERS}`);
    } else {
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
      navigate('/create-supplier');
    }
    setView(key);
  };

  useEffect(() => {
    return () => {
      dispatch(setSuppliersList(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <SupplierFilters view={view} setView={viewHandle} suppliersFilters={supplierFilters} />
      <div className="actions-and-paging">
        <Pagination
          currentPage={supplierFilters.page}
          pageSize={supplierFilters.pageSize}
          totalItems={suppliersList.totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => dispatch(setSupplierFilters({ ...supplierFilters, page, pageSize }))}
          className="actions-and-paging__paging"
          narrow
        />
      </div>
      <SupplierLines suppliers={suppliersList} />
    </Layout>
  );
};

export default SuppliersContainer;
