import { initDataList } from '../../../../../../core/types/coreTypes';
import { SupplierSpendData } from '../../../../../types/SupplierSpendReportTypes';

export const initSupplierSpendData: SupplierSpendData = {
  ...initDataList,
  maxCostNet: 0,
  minCostNet: 0,
  maxCostGross: 0,
  minCostGross: 0,
};
