import React from 'react';
import { useNavigate } from 'react-router-dom';
import { VoidFunctionType } from '../../../../core/types/coreTypes';
import { useAppDispatch } from '../../../../store/hooks';
import { duplicatePricelistById } from '../../../../store/thunks/pricelist/pricelistDetailsThunks';
import ConfirmationModal from '../../../../core/components/confirmation-modal/ConfirmationModal';

type DuplicatePricelistModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  id?: number,
}

const DuplicatePricelistModal: React.FC<DuplicatePricelistModalProps> = ({ onCancel, isVisible, id }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const duplicateHandle = () => {
    id && dispatch(duplicatePricelistById({
      id,
      navigate,
      closeModal: () => onCancel(),
    }));
  };
  return (
    <ConfirmationModal
      isVisible={isVisible}
      customTitle="Duplicate"
      onCancel={onCancel}
      confirmHandler={duplicateHandle}
      customCreateLabel="Yes, duplicate"
      customText="You are about to create a duplicate of displayed pricelist."
    />
  );
};

export default DuplicatePricelistModal;
