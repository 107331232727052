import React, { useState } from 'react';
import Button from '../../../../core/components/button/Button';
import VehicleAddEventModal from './VehicleAddEventModal';
import { EnquiryEventDtoBase } from '../../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';

type VehicleAddEventProps = {
  vehicleName?: string,
  item?: EnquiryEventDtoBase,
}
const VehicleAddEvent: React.FC<VehicleAddEventProps> = ({ vehicleName, item }) => {
  const [isVehicleAddModal, setIsVehicleAddModal] = useState(false);

  return (
    <>
      <VehicleAddEventModal
        isVisible={isVehicleAddModal}
        onClose={() => setIsVehicleAddModal(false)}
        item={item}
        vehicleName={vehicleName}
      />
      Vehicle
      {' '}
      {!!vehicleName && <b>{vehicleName}</b>}
      {' '}
      was created
      {' '}
      <Button
        label="(see details)"
        onClick={() => setIsVehicleAddModal(true)}
        type="text"
      />
    </>
  );
};

export default VehicleAddEvent;
