export const partAffectedEntitiesColumns = [
  {
    title: 'Entity type',
    dataIndex: 'entityType',
  },
  {
    title: 'Entity No',
    dataIndex: 'entityNumber',
  },
  {
    title: 'Created date',
    dataIndex: 'createdAt',
  },
  {
    title: 'Entity status',
    dataIndex: 'entityStatus',
  },
  {
    title: 'Business area',
    dataIndex: 'businessArea',
  },
  {
    title: 'Part lines',
    dataIndex: 'partLinesCount',
  },
];

export const orderAffectedEntitiesColumns = [
  {
    title: 'Entity type',
    dataIndex: 'entityType',
  },
  {
    title: 'Entity No',
    dataIndex: 'entityNumber',
  },
  {
    title: 'Created date',
    dataIndex: 'createdAt',
  },
  {
    title: 'Entity status',
    dataIndex: 'entityStatus',
  },
];
