import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboardCheck, faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { SupplierDto } from '../../types/SuppliersTypes';
import { useAppSelector } from '../../../../store/hooks';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import './SupplierLine.scss';
import PrivateLineLink from '../../../../common/components/private-line-link/PrivateLineLink';
import { verifiedSupplierStatusSelector } from '../../../../store/selectors/coreStatusesSelectors';

type SupplierListItemProps = {
  item: SupplierDto,
}

const SupplierListItem:React.FC<SupplierListItemProps> = ({
  item,
}) => {
  const infoProps = [
    { label: 'Active purchases', value: item.activePurchaseOrdersCount || 0 },
    { label: 'Completed purchases', value: item.completedPurchaseOrdersCount || 0 },
    { label: 'Pricelists', value: item.pricelistsCount || 0 },
  ];
  const verifiedSupplierStatus = useAppSelector(verifiedSupplierStatusSelector);

  return (
    <PrivateLineLink
      to={`${item.id}`}
      permission={PermissionEnum.SupplierViewPage}
      className="supplierLine"
    >
      <span className="supplierLine__title">
        <FontAwesomeIcon
          icon={item.supplierStatus === verifiedSupplierStatus?.value ? faClipboardCheck : faQuestionCircle}
          className="supplierLine__icon"
        />
        <span className="supplierLine__id">{item.supplierAccountNumber}</span>
        <span className="supplierLine__name">{item.name}</span>
      </span>
      <ul className="supplierLine__info-list">
        {infoProps.map((prop, i) => <li key={i} className="supplierLine__info-item">
          <span className="supplierLine__info-label">{prop.label}</span>
          <span className="supplierLine__info-value">{prop.value}</span>
        </li>)}
      </ul>
    </PrivateLineLink>
  );
};

export default SupplierListItem;
