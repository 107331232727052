import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import RectificationReportColumns from './RectificationReportColumns';
import RectificationReportBody from './RectificationReportBody';
import { RectificationReportTableLine } from '../../../../../types/RectificationReportsTypes';
import './RectificationReportTable.scss';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';

type RectificationReportTableProps = {
  columns: ICustomColumn[],
  data: RectificationReportTableLine[],
  totalCount: number,
}
const RectificationReportTable: React.FC<RectificationReportTableProps> = ({ columns, data, totalCount }) => {
  return (
    <TableNew>
      <RectificationReportColumns
        columns={columns}
        totalElements={totalCount}
      />
      <RectificationReportBody
        data={data}
        columnsLength={columns.length}
      />
    </TableNew>
  );
};

export default RectificationReportTable;
