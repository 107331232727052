import { UseFormSetError } from 'react-hook-form';
import { DuplicateValue, ErrorsEnum } from '../enums/errorsEnum';
import { maxDateMessageHandle } from './errorMessageHandle';
import { ServerErrorType } from '../types/coreTypes';
import { AddEditInvoiceFields } from '../../invoicing/invoicing-purchases/types/AddInvoiceSchema';

export const invoicesAndCreditNotesErrorHandle = (
  errors: ServerErrorType[],
  setError: UseFormSetError<AddEditInvoiceFields>,
  maxDate: string,
) => {
  const entityNumberError = errors.find((err) => err.key === 'EntityNumberExists');
  const invoiceDateError = errors.find((err) => err.key === 'InvoiceDate');
  const dueDateError = errors.find((err) => err.key === 'DueDate');
  if (entityNumberError) setError('entityNumber', { type: DuplicateValue, message: ErrorsEnum.UNIQUE_VALUE });
  if (invoiceDateError) setError('invoiceDate', { message: maxDateMessageHandle(maxDate) });
  if (dueDateError) setError('dueDate', { message: maxDateMessageHandle(maxDate) });
};
