import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { AddressFields, initAddressFields } from '../../types/commonTypes';

export const useAddContact = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [isRemove, setIsRemove] = useState(false);
  const [lineId, setLineId] = useState<number | undefined>(undefined);
  const [addContact, setAddContact] = useState(false);
  const [isLocation, setLocation] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);
  const [mode, setMode] = useState<'new' | 'existed' | string>('');

  return {
    id,
    dispatch,
    isRemove,
    setIsRemove,
    lineId,
    setLineId,
    addContact,
    setAddContact,
    isLocation,
    setLocation,
    addressFields,
    setAddressFields,
    mode,
    setMode,
  };
};
