import React from 'react';
import { UserEntityTimelineEvent } from '../../../types/activityTypes';
import { useAppSelector } from '../../../../../store/hooks';
import { userEntityEventTypesSelector } from '../../../../../store/selectors/coreEventTypesSelectors';
import { UserEventTypesEnum } from '../../../enums/UserTimeline';
import CreationEvent from '../../../../../common/components/activity-events/creation/CreationEvent';
import { accountAccountStatusSelector } from '../../../../../store/selectors/coreStatusesSelectors';
import StatusChangeEvent from '../../../../../common/components/activity-events/status-change/StatusChangeEvent';
import UserFieldUpdateEvent from './UserFieldUpdateEvent';
import UserNoteEvent from './UserNoteEvent';
import DeletionEvent from '../../../../../common/components/activity-events/deletion/DeletionEvent';

type UserEntityEventProps = {
  item: UserEntityTimelineEvent,
}

const UserEntityEvent: React.FC<UserEntityEventProps> = ({ item }) => {
  const eventTypes = useAppSelector(userEntityEventTypesSelector);
  const accountStatuses = useAppSelector(accountAccountStatusSelector);

  const eventRenderFn = () => {
    const event = eventTypes.find((type) => type.value === item.eventType)?.label || '';
    switch (true) {
      case event === UserEventTypesEnum.Note:
        return <UserNoteEvent id={item.id} content={item.content} initiator={item.initiator} />;
      case event === UserEventTypesEnum.UserAccountCreation:
        return <CreationEvent entity="User" type="name" value={`${item.firstName} ${item.lastName}`} />;
      case event === UserEventTypesEnum.UserAccountFieldUpdateOrAddingValue:
        return <UserFieldUpdateEvent field={item.field} oldValue={item.oldValue} newValue={item.newValue} />;
      case event === UserEventTypesEnum.UserAccountStatusChange: {
        const prevStatus = accountStatuses.find((status) => status.value === item.oldValue)?.label || '';
        const nextStatus = accountStatuses.find((status) => status.value === item.newValue)?.label || '';
        return <StatusChangeEvent prevStatus={prevStatus} nextStatus={nextStatus} />;
      }
      case event === UserEventTypesEnum.UserAccountDeletion:
        return <DeletionEvent entity="User" />;
      default: return null;
    }
  };

  return <>{eventRenderFn()}</>;
};

export default UserEntityEvent;
