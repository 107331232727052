import { useEffect, useRef } from 'react';

export const useCancelRequest = () => {
  const controller = useRef<AbortController | null>(null);

  const cancelRequest = () => {
    controller.current?.abort();
    controller.current = new AbortController();
  };

  useEffect(() => {
    return () => {
      controller.current?.abort();
    };
  }, []);

  return { controller, cancelRequest };
};
