import React, { useEffect } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { PermissionEnum } from '../../../../core/enums/dictionariesEnums';
import { OrdersPageParamsEnum, OrdersViewsEnum } from '../../enums/OrdersViewsEnum';
import { useOrdersFilters } from '../../hooks/useOrdersFilters';
import { BooleanFunctionType } from '../../../../core/types/coreTypes';
import DashboardFilters from '../../../../common/components/dashboard-filters/DashboardFilters';

type OrdersFiltersProps = {
  pageParam: string | null,
  setPageParam: (value: string) => void,
  setIsEyeModal: BooleanFunctionType,
}

const OrdersFilters: React.FC<OrdersFiltersProps> = ({
  setPageParam, pageParam, setIsEyeModal,
}) => {
  const options = [
    {
      value: OrdersPageParamsEnum.DASHBOARD,
      label: OrdersViewsEnum.DASHBOARD,
      restrictedTo: PermissionEnum.OrderDashboard,
    },
  ];

  const setPageParamHandle = (value: string) => {
    setPageParam(value as string);
  };

  const {
    isFiltersOpen,
    setIsFiltersOpen,
    keywordValue,
    setKeywordValue,
    initDropdownSearch,
    filtersList,
    stateFilters,
    getFilteredRes,
    onClear,
    currentBusinessArea,
    setInitSearchParams,
    keywordSearchParam,
  } = useOrdersFilters();

  useEffect(() => {
    if (currentBusinessArea && pageParam === OrdersPageParamsEnum.DASHBOARD) {
      getFilteredRes(stateFilters);
    }
    // eslint-disable-next-line
  }, [currentBusinessArea, pageParam]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && keywordValue !== stateFilters.keyword) {
      const timeOutId = setTimeout(() => {
        getFilteredRes({ ...stateFilters, keyword: keywordValue.trim() });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (stateFilters.keyword && !keywordValue?.trim()) {
        getFilteredRes({ ...stateFilters, keyword: undefined });
      }
    }
    // eslint-disable-next-line
  }, [keywordValue, stateFilters.keyword]);

  return (
    <DashboardFilters
      page={pageParam}
      isFiltersOpen={isFiltersOpen}
      setIsFiltersOpen={setIsFiltersOpen}
      setPageParamHandle={setPageParamHandle}
      options={options}
      customButtons={[{ icon: faEye, onClick: () => setIsEyeModal(true) }]}
    >
      <KeywordFiltersSection
        searchValue={keywordValue}
        setSearchValue={(v) => {
          setKeywordValue(v);
          if (keywordSearchParam) {
            setInitSearchParams();
          }
        }}
        getFilteredResHandle={(values, field) => getFilteredRes({ ...stateFilters, [field]: values as number[] })}
        onClear={onClear}
        initDropdownSearch={initDropdownSearch}
        filters={filtersList}
        isFullWidth
      />
    </DashboardFilters>
  );
};

export default OrdersFilters;
