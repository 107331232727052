import React, { useState } from 'react';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { useAppSelector } from '../../../store/hooks';
import { quoteLineTaxesSelector } from '../../../store/selectors/coreSelectors';
import { DictionaryItem, StringFunctionType } from '../../../core/types/coreTypes';
import Input from '../../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import Select from '../../../core/components/select/Select';
import { maxCommonDecimal, maxCommonWhole } from '../../../core/utils/regex';
import { EnquiryQuoteLineDto } from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { PartRequestLineDto } from '../../types/commonTypes';

type CurrentLineType = EnquiryQuoteLineDto | PartRequestLineDto;

type EditableCellProps = {
  initValue: string | number;
  fieldToEdit?: string;
  setFieldToEdit: StringFunctionType,
  rules: {
    notes: { maxLength: number, message: string },
    weight: { message: string, max: number },
    quantity: { message: string, max: number },
    discount: { message: string, max: number },
  },
  errors: { notes: string, weight: string, quantity: string, discount: string },
  setErrors: (value: { notes: string, weight: string, quantity: string, discount: string }) => void,
  ref: React.MutableRefObject<null>,
  allowedToManage?: boolean,
  editThunkHandle: (v: {[p: string]: string | number} | {notes: string}) => void,
  currentLine?: CurrentLineType,
  categoriesOptions: DictionaryItem[],
  forbiddenKeys: string[],
  categoryColKey: string,
  categoryEntityKey: string,
}

const EditableCell: React.FC<EditableCellProps> = ({
  initValue,
  fieldToEdit,
  setFieldToEdit,
  errors,
  setErrors,
  ref,
  allowedToManage,
  rules,
  editThunkHandle,
  currentLine,
  categoriesOptions,
  forbiddenKeys,
  categoryColKey,
  categoryEntityKey,
}) => {
  const taxes = useAppSelector(quoteLineTaxesSelector);
  const [inputV, setInputV] = useState<string>(initValue as string);
  const [selectValue, setSelectValue] = useState<number>(initValue as number);
  const [categoryValue, setCategoryValue] = useState<number>(initValue as number);

  const changeHandle = (v: string | number) => setInputV(v as string);
  const editRow = (key: string, val: number | string) => {
    const defaultNumVal = key === 'quantity' ? 1 : 0;
    const obj = key !== 'notes'
      ? { [key]: val || defaultNumVal }
      : { [key]: typeof val === 'string' ? val.trim() : '' };
    editThunkHandle(obj);
  };

  const inputTypeRenderer = () => {
    if (typeof initValue === 'string') {
      return <Input
        fieldRef={ref}
        value={inputV as string}
        autoFocus
        disabled={!allowedToManage}
        onChange={(e) => {
          const val = e.target.value;
          // eslint-disable-next-line
            // @ts-ignore
          const rule = fieldToEdit && rules[fieldToEdit].maxLength;
          if (val.length > rule) {
            // eslint-disable-next-line
              // @ts-ignore
            setErrors({ ...errors, [fieldToEdit]: maxLengthMessageHandle(rule) });
          } else {
            // eslint-disable-next-line
              // @ts-ignore
            errors[fieldToEdit] && setErrors({ ...errors, [fieldToEdit]: '' });
          }
          changeHandle(e.target.value);
        }}
        onBlur={() => {
          // eslint-disable-next-line
            // @ts-ignore
          if (!errors[fieldToEdit]) {
            // eslint-disable-next-line
              // @ts-ignore
            if (currentLine && inputV !== currentLine[fieldToEdit]) {
              fieldToEdit && editRow(fieldToEdit, inputV);
            } else {
              changeHandle('');
              setFieldToEdit('');
            }
          } else {
            // eslint-disable-next-line
              // @ts-ignore
            setErrors({ ...errors, [fieldToEdit]: '' });
            setFieldToEdit('');
          }
        }}
        className={classNames('table-input', { 'errorTableInput': fieldToEdit === 'notes' && errors[fieldToEdit] })}
      />;
    } else {
      if (fieldToEdit === 'quantity') {
        return <RegularInputNumber
          value={inputV}
          onChange={(e) => {
            const v = e.target.value;
            changeHandle(v);
          }}
          disabled={!allowedToManage}
          max={rules[fieldToEdit].max || maxCommonWhole}
          autoFocus
          onBlur={() => {
            if (fieldToEdit && !errors[fieldToEdit]) {
              if (+inputV !== currentLine?.quantity) {
                editRow(fieldToEdit, +inputV || 1);
              } else {
                setInputV('');
                setFieldToEdit('');
              }
            }
          }}
          className={classNames('table-inputNumber', {
            // eslint-disable-next-line
              // @ts-ignore
            'errorTableInputNum': fieldToEdit && errors[fieldToEdit],
          })}
        />;
      } else {
        return <DecimalInputNumber
          value={inputV}
          onChange={(e) => {
            const v = e.target.value.replace(',', '.');
            changeHandle(v);
          }}
          // eslint-disable-next-line
            // @ts-ignore
          max={rules[fieldToEdit].max || maxCommonDecimal}
          disabled={!allowedToManage}
          autoFocus
          onBlur={() => {
            // eslint-disable-next-line
              // @ts-ignore
            if (fieldToEdit && !errors[fieldToEdit]) {
              if (currentLine && currentLine[fieldToEdit as keyof CurrentLineType] !== +inputV) {
                editRow(fieldToEdit, +inputV || 0.00);
              } else {
                setInputV('');
                setFieldToEdit('');
              }
            }
          }}
          className={classNames('table-inputNumber', {
            // eslint-disable-next-line
              // @ts-ignore
            'errorTableInputNum': fieldToEdit && errors[fieldToEdit],
          })}
        />;
      }
    }
  };

  return (
  // eslint-disable-next-line
      // @ts-ignore
    <Tooltip title={fieldToEdit && errors[fieldToEdit] ? errors[fieldToEdit] : ''}>
      <div>
        {fieldToEdit === categoryColKey && <Select
          fieldRef={ref}
          value={categoryValue}
          options={categoriesOptions}
          onBlur={() => {
            if (currentLine && selectValue === currentLine[categoryEntityKey as keyof CurrentLineType]) {
              setSelectValue(initValue as number);
              setFieldToEdit('');
            }
          }}
          disabled={!allowedToManage}
          defaultOpen
          onChange={(v) => {
            setCategoryValue(v as number);
            fieldToEdit && editRow(fieldToEdit, v as number);
          }}
        />}
        {fieldToEdit === 'tax' && <Select
          fieldRef={ref}
          value={selectValue}
          options={taxes}
          defaultOpen
          disabled={!allowedToManage}
          onBlur={() => {
            if (currentLine && selectValue === currentLine.tax) {
              setSelectValue(initValue as number);
              setFieldToEdit('');
            }
          }}
          onChange={(v) => {
            setSelectValue(v as number);
            fieldToEdit && editRow(fieldToEdit, v as number);
          }}
        />}
        {fieldToEdit && !forbiddenKeys.includes(fieldToEdit) && inputTypeRenderer()}
      </div>
    </Tooltip>
  );
};

export default EditableCell;
