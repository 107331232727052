import React from 'react';
import { StringFunctionType } from '../../../core/types/coreTypes';
import DashboardFilters from '../../../common/components/dashboard-filters/DashboardFilters';
import { InvoicingPageParamsEnums } from '../enums/InvoicingPageParamsEnums';
import NominalCodesTypeFilter from './filters/NominalCodesTypeFilter';
import { invoicingContainerOptions } from '../utils/data';
import PurchasesFilter from './filters/PurchasesFilter';
import './InvoicingFilters.scss';
import SalesFilters from './filters/SalesFilters';

type InvoicingFiltersProps = {
  view: string | null,
  setView: StringFunctionType,
}

const InvoicingFilters: React.FC<InvoicingFiltersProps> = ({
  view, setView,
}) => {
  return (
    <DashboardFilters
      page={view}
      setPageParamHandle={setView}
      options={invoicingContainerOptions}
      expandableFilters={false}
    >
      {view === InvoicingPageParamsEnums.NOMINAL_CODES && (
        <NominalCodesTypeFilter />
      )}
      {view === InvoicingPageParamsEnums.PURCHASES && <PurchasesFilter />}
      {view === InvoicingPageParamsEnums.SALES && <SalesFilters />}
    </DashboardFilters>
  );
};

export default InvoicingFilters;
