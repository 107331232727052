export enum RectificationEventEnum {
  Note = 'Note',
  RectificationCreation = 'RectificationCreation',
  RectificationFieldUpdateOrAddingValue = 'RectificationFieldUpdateOrAddingValue',
  RectificationStatusChange = 'RectificationStatusChange',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  ShippingDetailsUpdates = 'ShippingDetailsUpdates',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
  JobLocationUpdate = 'JobLocationUpdate',
  PartRequestLineCreationChanges = 'PartRequestLineCreationChanges',
  EditingAttachmentVisibility = 'EditingAttachmentVisibility',
}
