import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../core/components/layout/Layout';
import './PricelistsContainer.scss';
import PricelistsFilters from './components/pricelists-filters/PricelistsFilters';
import { PricelistsViewEnum } from './enums/PricelistsViewEnum';
import { routesPath } from '../../core/enums/pathEnum';
import { pricelistFiltersSelector, pricelistsSelector } from '../../store/selectors/pricelistSelectors';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { getPricelists } from '../../store/thunks/pricelist/pricelistDashboardThunks';
import Pagination from '../../core/components/pagination/Pagination';
import { setPricelistFilters, setPricelists } from '../../store/slices/pricelistSlice';
import PricelistLines from './components/pricelist-lines/PricelistLines';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { initDataList } from '../../core/types/coreTypes';
import { setDashboardLoading } from '../../store/slices/coreSlice';
import { useCancelRequest } from '../../core/hooks/useCancelRequest';
import { getPricelistsOrderingThunk } from '../../store/thunks/core/coreOrderingThunks';
import { useDefaultPagingAndOrdering } from '../../core/hooks/useDefaultPagingAndOrdering';
import { pricelistsOrderingSelector } from '../../store/selectors/coreOrderingSelectors';
import { PricelistsOrderingEnum } from './enums/PricelistsOrderingEnum';

const PricelistsContainer: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const dispatch = useAppDispatch();
  const pricelistFilters = useAppSelector(pricelistFiltersSelector);
  const pricelists = useAppSelector(pricelistsSelector);
  const ordering = useAppSelector(pricelistsOrderingSelector);
  const [view, setView] = useState<string | number>(PricelistsViewEnum.DASHBOARD);
  const { controller, cancelRequest } = useCancelRequest();
  const [searchParams] = useSearchParams();
  const keywordParam = searchParams.get('keyword');

  useEffect(() => {
    if (keywordParam && !pricelistFilters.keyword) {
      dispatch(setPricelistFilters({ ...pricelistFilters, keyword: keywordParam }));
    }
    // eslint-disable-next-line
  }, [keywordParam, pricelistFilters]);

  const setViewHandle = (key: string | number) => {
    setView(key);
    if (key === PricelistsViewEnum.DASHBOARD) {
      navigate(`/${routesPath.PRICELISTS}`);
    } else {
      navigate('/create-pricelist');
      setLocationHistoryHandle({
        pathname: location.pathname,
        search: location.search,
      });
    }
  };

  useDefaultPagingAndOrdering({
    filters: pricelistFilters,
    ordering,
    getOrderingThunk: getPricelistsOrderingThunk,
    setFiltersAction: setPricelistFilters,
    initialOrdering: PricelistsOrderingEnum.Name,
  });

  useEffect(() => {
    if (pricelistFilters.order?.field) {
      cancelRequest();
      dispatch(getPricelists({ filters: pricelistFilters, signal: controller.current?.signal }));
    }
    // eslint-disable-next-line
  }, [pricelistFilters]);
  useEffect(() => {
    return () => {
      dispatch(setPricelists(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);
  return (
    <Layout>
      <PricelistsFilters view={view} setView={setViewHandle} pricelistFilters={pricelistFilters} />
      <div className="actions-and-paging">
        <Pagination
          currentPage={pricelistFilters.page}
          pageSize={pricelistFilters.pageSize}
          totalItems={pricelists.totalCount}
          hideOnSinglePage={false}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          onChange={(page, pageSize) => dispatch(setPricelistFilters({ ...pricelistFilters, page, pageSize }))}
          narrow
          className="actions-and-paging__paging"
        />
      </div>
      <PricelistLines pricelists={pricelists} />
    </Layout>
  );
};

export default PricelistsContainer;
