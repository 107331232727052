import React from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import {
  vehicleChecksColumnsSelector,
  vehicleChecksFiltersSelector,
} from '../../../../../../../store/selectors/vehiclesSelectors';
import { setVehicleChecksFilters } from '../../../../../../../store/slices/vehiclesSlice';
import Thead from '../../../../../../../core/components/table-components/Thead';
import Tr from '../../../../../../../core/components/table-components/Tr';
import ThWithControls from '../../../../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../../../../core/components/table-components/table-new/ThNew';

type VehicleChecksColumnsProps = {
  totalCount: number,
}

const VehicleChecksColumns: React.FC<VehicleChecksColumnsProps> = ({ totalCount }) => {
  const dispatch = useAppDispatch();
  const checksFilters = useAppSelector(vehicleChecksFiltersSelector);
  const columns = useAppSelector(vehicleChecksColumnsSelector);

  const filters = [
    {
      dataIndex: 'vehicleCheckTypes',
      value: checksFilters.vehicleCheckTypes || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => dispatch(setVehicleChecksFilters({
        ...checksFilters,
        vehicleCheckTypes: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        page: 1,
      })),
    },
  ];

  const sortHandle = (field: number) => {
    if (checksFilters.order?.field === field) {
      dispatch(setVehicleChecksFilters({
        ...checksFilters,
        order: { ...checksFilters.order, isAsc: !checksFilters.order?.isAsc },
      }));
    } else {
      dispatch(setVehicleChecksFilters({
        ...checksFilters,
        order: { field, isAsc: true },
      }));
    }
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            totalElements={totalCount}
            className={`vehicleChecksTh--${col.dataIndex}`}
            tableFilters={checksFilters}
            sortHandle={sortHandle}
            filters={filters}
          />
        ))}
        <ThNew action><span className="sr-only">Actions</span></ThNew>
      </Tr>
    </Thead>
  );
};

export default VehicleChecksColumns;
