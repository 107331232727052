import React, { useEffect, useState } from 'react';
import './ForgotPasswordContainer.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import ForgotPasswordForm from './components/ForgotPasswordForm';
import { useLocationHistoryState } from '../../core/hooks/useLocationHistoryState';
import Button from '../../core/components/button/Button';
import { ForgotPasswordEnum } from './types/forgotPasswordTypes';
import ForgotPasswordInfo from './components/ForgotPasswordInfo';
import { useAppSelector } from '../../store/hooks';
import { authTokenSelector } from '../../store/selectors/coreSelectors';

const ForgotPasswordContainer:React.FC = () => {
  const token = useAppSelector(authTokenSelector);
  const { backHandle, setLocationHistoryHandle } = useLocationHistoryState();
  const [view, setView] = useState<ForgotPasswordEnum.FORM | ForgotPasswordEnum.INFO>(ForgotPasswordEnum.FORM);
  const [email, setEmail] = useState<string>('');
  useEffect(() => {
    if (token) {
      backHandle();
    }
    // eslint-disable-next-line
  }, [token]);
  return (
    <div className="forgot-password">
      <div className="forgot-password__wrapper">
        <div className="forgot-password__header">
          <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={() => {
              setLocationHistoryHandle(null);
              backHandle('/login');
            }}
            className="forgot-password__back"
          />
          <h1 className="forgot-password__title">Forgot password</h1>
        </div>
        {
          view === ForgotPasswordEnum.FORM
            ? <ForgotPasswordForm
              setInfoView={() => setView(ForgotPasswordEnum.INFO)}
              setEmail={setEmail}
            />
            : <ForgotPasswordInfo
              email={email}
              tryAgainClick={() => setView(ForgotPasswordEnum.FORM)}
            />
        }
      </div>
    </div>
  );
};

export default ForgotPasswordContainer;
