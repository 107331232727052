export enum PartsKitsViewPageEnums {
  PARTS_KITS_DETAILS = 'details-and-activity',
  PARTS_KITS_COMPONENTS = 'components',
  PARTS_KITS_ATTACHMENTS = 'attachments',
}

export const PartsKitModes = [
  PartsKitsViewPageEnums.PARTS_KITS_DETAILS,
  PartsKitsViewPageEnums.PARTS_KITS_ATTACHMENTS,
  PartsKitsViewPageEnums.PARTS_KITS_COMPONENTS,
];

export enum AddComponentModeEnum {
  CORE = 'core',
  NON_CORE = 'non-core',
  CUSTOM = 'custom',
}

export enum PartsKitComponentsTaxUiEnum {
  NoTax = '0%',
  FivePercent = '5%',
  TwentyPercent = '20%',
}

export enum PartsKitEventTypesEnum {
  PartsKitCreation = 'PartsKitCreation',
  PartsKitFieldUpdateOrAddingValue = 'PartsKitFieldUpdateOrAddingValue',
  PartsKitStatusChange = 'PartsKitStatusChange',
  PartsKitDeletion = 'PartsKitDeletion',
  Note = 'Note',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  PartsKitComponentCreationChanges = 'PartsKitComponentCreationChanges',
  PartsKitSuitableForVehiclesUpdateOrAddingValue = 'PartsKitSuitableForVehiclesUpdateOrAddingValue',
}

export enum PartsKitOrderingEnum {
  Category = 'Category',
  Name = 'Name',
  PartsKitCode = 'PartsKitCode',
  ManufacturerCode = 'ManufacturerCode',
  Description = 'Description',
  UnitOfMeasure = 'UnitOfMeasure',
  Status = 'Status',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
}
