import React from 'react';
import JobLineExpandedDetails from './expanded-view/details/JobLineExpandedDetails';
import { ExpandedViewMode } from '../enums/JobListEnums';
import JobLineExpandedAttachments from './expanded-view/attachments/JobLineExpandedAttachments';
import JobLineExpandedLinks from './expanded-view/links/JobLineExpandedLinks';
import JobLineExpandedParts from './expanded-view/parts/JobLineExpandedParts';
import JobLineExpandedQualityCheck from './expanded-view/quality-check/JobLineExpandedQualityCheck';
import JobLineNotes from './JobLineNotes';

type JobLineExpandedDataProps = {
  jobId: number,
  mode: ExpandedViewMode,
  isJobCompleted: boolean,
}

const JobLineExpandedData: React.FC<JobLineExpandedDataProps> = ({ jobId, mode, isJobCompleted }) => {
  const renderExpandedData = () => {
    switch (mode) {
      case ExpandedViewMode.DETAILS:
        return <JobLineExpandedDetails />;
      case ExpandedViewMode.PARTS:
        return <JobLineExpandedParts jobId={jobId} />;
      case ExpandedViewMode.QUALITY_CHECK:
        return <JobLineExpandedQualityCheck jobId={jobId} />;
      case ExpandedViewMode.ATTACHMENTS:
        return <JobLineExpandedAttachments jobId={jobId} narrowPagination={false} />;
      case ExpandedViewMode.LINKS:
        return <JobLineExpandedLinks jobId={jobId} />;
      default: return <div>Tab is under construction</div>;
    }
  };
  return (
    <div className="jobLineExpandedData">
      <>{renderExpandedData()}</>
      <JobLineNotes jobId={jobId} mode={mode} isJobCompleted={isJobCompleted} />
    </div>
  );
};

export default JobLineExpandedData;
