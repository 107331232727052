export enum JobEventsEnum {
  JobCreation = 'JobCreation',
  JobFieldUpdateOrAddingValue = 'JobFieldUpdateOrAddingValue',
  JobStatusChange = 'JobStatusChange',
  JobLocationUpdate = 'JobLocationUpdate',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  Note = 'Note',
  JobWorklogsUpdate = 'JobWorklogsUpdate',
  JobQualityCheckCreation = 'JobQualityCheckCreation',
  JobQualityCheckDeletion = 'JobQualityCheckDeletion',
}
