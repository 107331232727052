import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JobLinksColumns from './JobLinksColumns';
import { getJobLinksThunk } from '../../../../../store/thunks/jobs/jobLinksThunks';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { jobLinksFiltersSelector, jobLinksRowsSelector } from '../../../../../store/selectors/jobsSelector';
import LinksTable from '../../../../../common/components/links-table/LinksTable';
import LinksBody from '../../../../../common/components/links-table/LinksBody';

const JobLinks: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const jobLinksFilters = useAppSelector(jobLinksFiltersSelector);
  const jobLinks = useAppSelector(jobLinksRowsSelector);
  useEffect(() => {
    id && dispatch(getJobLinksThunk({ jobId: +id, params: jobLinksFilters }));
    // eslint-disable-next-line
  }, [id, jobLinksFilters]);
  return (
    <LinksTable>
      <JobLinksColumns totalCount={jobLinks.length || 0} />
      <LinksBody links={jobLinks} />
    </LinksTable>
  );
};

export default JobLinks;
