import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  addComponentCoreFiltersListSelector,
  partsKitComponentsPartsFiltersSelector,
} from '../../../../store/selectors/partsKitSelectors';
import { setPartsKitComponentsPartsFilters } from '../../../../store/slices/partsKitSlice';
import { getPartsKitComponentsParts } from '../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { StockFiltersType } from '../../../search/types/stockSearchTypes';

export const useAddComponentFilters = () => {
  const dispatch = useAppDispatch();
  const filtersValues = useAppSelector(partsKitComponentsPartsFiltersSelector);
  const filtersList = useAppSelector(addComponentCoreFiltersListSelector);

  const initDropdownSearch = {
    vehicleIds: '',
    categoryIds: '',
    subcategoryIds: '',
  };

  const getFilteredResult = (filters: StockFiltersType) => {
    dispatch(setPartsKitComponentsPartsFilters({ ...filters, page: 1 }));
    filters.keyword && dispatch(getPartsKitComponentsParts({
      filters: { ...filters, keyword: filters.keyword?.trim(), page: 1 },
    }));
  };

  const onClear = () => {
    getFilteredResult({
      ...filtersValues,
      categoryIds: undefined,
      subcategoryIds: undefined,
      businessAreas: undefined,
      vehicleIds: undefined,
    });
  };

  return {
    initDropdownSearch,
    filtersList,
    getFilteredResult,
    onClear,
  };
};
