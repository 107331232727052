import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinusCircle, faPlusCircle, faTools } from '@fortawesome/free-solid-svg-icons';
import { sortBy } from 'lodash';
import Thead from '../../../core/components/table-components/Thead';
import Tr from '../../../core/components/table-components/Tr';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import Tbody from '../../../core/components/table-components/Tbody';
import Button from '../../../core/components/button/Button';
import RateComponent from '../../../core/components/rate/RateComponent';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import EmptyTableSection from '../../../core/components/table-components/EmptyTableSection';
import { DraftSkillLine } from '../../types/commonTypes';
import { SearchTableFilterType } from '../../../core/types/coreTypes';
import { skillsSectionColumns } from '../../../employees/skill-list/utils/dataUtil';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import TdNew from '../../../core/components/table-components/table-new/TdNew';

type SkillsListSectionProps = {
  totalElements: number,
  skills: DraftSkillLine[]
}

type ParentSkillListType = Array<{ categoryName: string, children: DraftSkillLine[] }>;

const SkillsListSection: React.FC<SkillsListSectionProps> = ({ totalElements, skills }) => {
  const hiddenKeys = ['id', 'number', 'categoryName'];
  const [parentList, setParentList] = useState<ParentSkillListType>([]);
  const [parentInitList, setParentInitList] = useState<ParentSkillListType | undefined>(undefined);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({ categoryName: '' });
  const [visibleParents, setVisibleParents] = useState<(string | number)[]>([]);

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      parentInitList && setParentList(parentInitList);
      parentInitList && setVisibleParents(parentInitList.map((n) => n.categoryName));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = () => {
    const searchParam = searchVl.categoryName?.trim().toLowerCase() || '';
    const newList = parentList
      .filter((el) => el.children.some((ch) => ch.name?.toLowerCase().includes(searchParam)))
      .map((el) => ({
        ...el,
        children: el.children.filter((ch) => ch.name?.toLowerCase().includes(searchParam)),
      }));
    setParentList(newList);
    setVisibleParents(newList.map((n) => n.categoryName));
  };

  const setParentsHandle = (name: string | number) => {
    if (visibleParents.includes(name)) {
      const newList = visibleParents.filter((p) => p !== name);
      setVisibleParents(newList);
    } else {
      setVisibleParents([...visibleParents, name]);
    }
  };

  useEffect(() => {
    let list: ParentSkillListType = [];
    if (skills.length > 0) {
      skills.forEach((sk) => {
        if (list.find((el) => el.categoryName === sk.categoryName)) {
          list = list.map((l) => {
            if (l.categoryName === sk.categoryName) {
              return { ...l, children: [...l.children, sk] };
            } else {
              return l;
            }
          });
        } else {
          list.push({
            categoryName: sk.categoryName || '',
            children: [{ ...sk }],
          });
        }
      });
      const listCopy = list;
      const sortedListByCategory = listCopy.sort((a, b) => a.categoryName.localeCompare(b.categoryName));
      const sortedChildrenList = sortedListByCategory.map((el) => {
        const childrenCopy = el.children;
        return {
          ...el,
          children: sortBy(childrenCopy, (el) => el.name),
        };
      });
      setParentInitList(sortedChildrenList);
      setParentList(sortedChildrenList);
      setVisibleParents(list.map((el) => el.categoryName));
    } else {
      setParentList([]);
      setVisibleParents([]);
    }
    // eslint-disable-next-line
  }, [skills]);
  return (
    <TableNew className="employeeSkillListTable details-form__field--lg">
      <Thead>
        <Tr>
          {skillsSectionColumns.map((col, i) => <ThWithControls
            key={i}
            col={col}
            totalElements={totalElements}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            disabledFilter={skills.length === 0}
            className={`skillSectionTh--${col.dataIndex}`}
          />)}
        </Tr>
      </Thead>
      <Tbody>
        {parentList.length > 0
          ? parentList.map((p, i) => <React.Fragment key={i}>
            <Tr className={p.children.length === 0 ? 'trHidden' : ''}>
              <TdNew colSpan={4}>
                <div>
                  <Button
                    icon={<FontAwesomeIcon icon={visibleParents.includes(p.categoryName) ? faMinusCircle : faPlusCircle} />}
                    onClick={() => setParentsHandle(p.categoryName)}
                    isExpand
                  />
                  <b>{p.categoryName}</b>
                </div>
              </TdNew>
            </Tr>
            {p.children.length > 0 && p.children.map((ch, i) => <Tr
              key={i}
              className={!visibleParents.includes(p.categoryName) ? 'trHidden' : ''}
            >
              {/* eslint-disable-next-line array-callback-return,consistent-return */}
              {Object.entries(ch).map(([key, value]) => {
                if (!hiddenKeys.includes(key)) {
                  return <TdNew key={key} className="childSkillTd">
                    {key === 'level'
                      ? <RateComponent
                        count={3}
                        value={ch.level}
                        disabled
                        desc={['Novice', 'Proficient', 'Expert']}
                      />
                      : <TruncateTooltip value={value} table />}
                  </TdNew>;
                }
              })}
            </Tr>)}
          </React.Fragment>)
          : <EmptyTableSection text="No skills" icon={faTools} colSpan={2} />}
      </Tbody>
    </TableNew>
  );
};

export default SkillsListSection;
