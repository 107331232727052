import React, { useEffect } from 'react';
import Modal from '../../../../../../../core/components/modal/Modal';
import { BooleanFunctionType, initDataListWithIds, VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';
import Button from '../../../../../../../core/components/button/Button';
import './AddComponentModal.scss';
import AddComponentContent from './AddComponentContent';
import { useAppDispatch } from '../../../../../../../store/hooks';
import {
  setPartsKitComponentsParts,
  setPartsKitComponentsPartsFilters,
} from '../../../../../../../store/slices/partsKitSlice';
import { PartsKitComponentsPartsFiltersInit } from '../../../../types/partsKitViewPageTypes';

type AddComponentModalProps = {
  partsKitId: number,
  isVisible: boolean,
  onCancel: VoidFunctionType,
  currentMode: AddComponentModeEnum,
  setCurrentMode: (value: AddComponentModeEnum) => void,
  setCheckedAddKeys: (val: number[]) => void,
  itemToChange?: number,
  setIsAddWithoutPrice: BooleanFunctionType,
}

const AddComponentModal: React.FC<AddComponentModalProps> = ({
  partsKitId, onCancel, isVisible, currentMode, setCurrentMode, itemToChange,
  setCheckedAddKeys, setIsAddWithoutPrice,
}) => {
  const dispatch = useAppDispatch();
  const modes = [
    { label: 'Core parts', value: AddComponentModeEnum.CORE },
    { label: 'Non-core parts', value: AddComponentModeEnum.NON_CORE },
    { label: 'Custom line', value: AddComponentModeEnum.CUSTOM },
  ];

  const sourceCleanUp = (mode?: AddComponentModeEnum) => {
    if (mode === AddComponentModeEnum.CORE) {
      dispatch(setPartsKitComponentsPartsFilters({ ...PartsKitComponentsPartsFiltersInit, isCorePart: [true] }));
    }
    if (mode === AddComponentModeEnum.NON_CORE) {
      dispatch(setPartsKitComponentsPartsFilters({ ...PartsKitComponentsPartsFiltersInit, isCorePart: [false] }));
    }
    dispatch(setPartsKitComponentsParts(initDataListWithIds));
  };

  useEffect(() => {
    if (!isVisible) {
      dispatch(setPartsKitComponentsPartsFilters(PartsKitComponentsPartsFiltersInit));
      dispatch(setPartsKitComponentsParts(initDataListWithIds));
    }
    // eslint-disable-next-line
  }, [isVisible]);
  return (
    <Modal
      visible={isVisible}
      onCancel={() => {
        onCancel();
        sourceCleanUp();
      }}
      title={itemToChange ? 'Edit component' : 'Add component'}
      width={1100}
      destroyOnClose
    >
      <div className="addComponent">
        {!itemToChange && <section className="addComponent__chips">
          {modes.map((mode, i) => <Button
            key={i}
            label={mode.label}
            onClick={() => {
              sourceCleanUp(mode.value);
              setCurrentMode(mode.value);
            }}
            designType="light-inverse"
            isActivated={currentMode === mode.value}
          />)}
        </section>}
        <AddComponentContent
          mode={currentMode}
          partsKitId={partsKitId}
          onCancel={onCancel}
          isVisible={isVisible}
          itemToChange={itemToChange}
          setCheckedAddKeys={setCheckedAddKeys}
          setIsAddWithoutPrice={setIsAddWithoutPrice}
        />
      </div>
    </Modal>
  );
};

export default AddComponentModal;
