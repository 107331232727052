import React, { useCallback, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useParams } from 'react-router-dom';
import Tbody from '../../../../../core/components/table-components/Tbody';
import EmptyTableSection from '../../../../../core/components/table-components/EmptyTableSection';
import Tfoot from '../../../../../core/components/table-components/Tfoot';
import Tr from '../../../../../core/components/table-components/Tr';
import Button from '../../../../../core/components/button/Button';
import OrderPartReqColumns from './OrderPartReqColumns';
import { useOrderPartRequestsLines } from '../../../hooks/useOrderPartRequestsLines';
import { VoidFunctionType } from '../../../../../core/types/coreTypes';
import {
  PartProductSourceTotalParams,
  PatchPartReqLineDto,
  PatchProductSourceType,
} from '../../../../../common/types/commonTypes';
import SelectProductSourceModal
  from '../../../../../common/components/select-product-source-modal/SelectProductSourceModal';
import AddDiscountModal from '../../../../../common/components/add-discount-modal/AddDiscountModal';
import MarkAsFreeOfChargeModal from '../../../../../common/components/common-part-request-components/MarkAsFreeForChargeModal';
import FillInDraftLineModal from '../../../../../common/components/fill-in-draft-line-modal/FillInDraftLineModal';
import {
  setCurrentPartReqSource,
  setOrderPartReqDraftLinesFilters,
  setOrderPartRequestParts,
  setOrderPartsKitDraftLinesFilters,
  setOrderPartsKitRequestParts,
} from '../../../../../store/slices/ordersSlice';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  currentOrderInfoSourceValueSelector,
  currentPartReqSourceSelector,
  orderPartReqDraftLinesFiltersSelector,
  orderPartRequestPartsKitFiltersSelector,
  orderPartRequestPartsKitSelector,
  orderPartRequestPartsSelector,
  partRequestLinesSelector,
} from '../../../../../store/selectors/ordersSelector';
import {
  addPartLineToOrderThunk, bulkDeleteOrderPartReqLinesThunk, deleteOrderPartReqSingleLineThunk,
  patchBulkPartReqLines,
  patchOrderPartReqLineThunk,
} from '../../../../../store/thunks/orders/orderPartRequestThunks';
import { useAddPartRequestLine } from '../../../hooks/useAddPartRequestLine';
import { setErrorMessage } from '../../../../../store/slices/coreSlice';
import TableNew from '../../../../../core/components/table-components/table-new/TableNew';
import TdNew from '../../../../../core/components/table-components/table-new/TdNew';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import {
  orderDetailsSelector,
  productSourceDictionarySelector,
  productSourceTotalInitCopySelector,
  productSourceTotalSelector,
} from '../../../../../store/selectors/sharedSelectors';
import PartRequestBodyCells from '../../../../../common/components/common-part-request-components/PartRequestBodyCells';
import { partRequestLineUiHandle } from '../../../../../common/utils/partRequestLineValueRenderer';
import { getPartProductSourceDictionaryThunk, getPartProductSourceTotalThunk } from '../../../../../store/thunks/shared/sharedThunks';
import { setProductSourceDictionary, setProductSourceTotal, setProductSourceTotalInitCopy } from '../../../../../store/slices/sharedSlice';
import { PatchEnquiryQuoteLineDto } from '../../../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import ReadyCollectedPartsListModal
  from '../../../../../common/components/ready-collected-parts-list-modal/ReadyCollectedPartsListModal';
import ReasonModal from '../../../../../common/components/reason-modal/ReasonModal';

const OrderPartRequestTable: React.FC = () => {
  const {
    columns, linesArr, footerActions,
    checkAll, isAll,
    isIndeterminate,
    checkedKeys,
    checkRowHandle,
    setDiscountModal, discountModal,
    partsModal, setPartsModal,
    isLineActionDisabled, availableInfoSourcesByStatus,
    isOrderReadMode, isPartsKitLineHasAllocatedChild,
    discountReasonModal,
    setDiscountReasonModal,
    patchReasonModal,
    setPatchReasonModal,
    dataToPatch,
    setDataToPatch,
    deleteReasonModal,
    setDeleteReasonModal,
  } = useOrderPartRequestsLines();

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const currentPartReqSource = useAppSelector(currentPartReqSourceSelector);
  const orderPartRequestParts = useAppSelector(orderPartRequestPartsSelector);
  const orderPartReqDraftLinesFilters = useAppSelector(orderPartReqDraftLinesFiltersSelector);
  const { items: partRequestLines } = useAppSelector(partRequestLinesSelector);
  const lines = partRequestLineUiHandle(partRequestLines);
  const [isReadyCollectedModal, setIsReadyCollectedModal] = useState(false);

  const {
    editedLine, setEditedLine,
    childLineMode, setChildLineMode,
    sourceName, setSourceName,
    sourceId, setSourceId,
    prodSourceModal, setProdSourceModal,
    setProdSourceModalHandle, initCustomLine,
    setInitFiltersAndTableData,
    isOrderVehManual, isJobTypeSupply,
    fetchParts, fetchPartsKitParts, createPartsKitLineThunk,
    createCustomHandle,
    newLineReasonModal,
    setNewLineReasonModal,
    newLineReasonData,
  } = useAddPartRequestLine();

  const {
    isCore, isNonCore, isCustom, isPartsKit,
  } = useAppSelector(currentOrderInfoSourceValueSelector);
  const orderPartRequestPartsKitFilters = useAppSelector(orderPartRequestPartsKitFiltersSelector);
  const orderPartRequestPartsKit = useAppSelector(orderPartRequestPartsKitSelector);
  const productSourceDictionary = useAppSelector(productSourceDictionarySelector);
  const productSourceTotal = useAppSelector(productSourceTotalSelector);
  const orderDetails = useAppSelector(orderDetailsSelector);
  const partRequestId = orderDetails?.order?.partRequestId;
  const productSourceTotalInitCopy = useAppSelector(productSourceTotalInitCopySelector);

  const [editMode, setEditMode] = useState(false);
  const [isMarkAsFree, setIsMarkAsFree] = useState<boolean>(false);
  const allowedToManage = usePermission(PermissionEnum.OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions);

  const [reasonValue, setReasonValue] = useState('');

  const deleteConfirmationHandle = () => {
    if (editedLine) {
      id && dispatch(deleteOrderPartReqSingleLineThunk({
        orderId: +id,
        lineId: editedLine,
        closeModal: () => {
          setDeleteReasonModal(false);
          setEditedLine(undefined);
        },
        deletionReason: reasonValue.trim(),
      }));
    } else {
      checkedKeys.length > 0 && id && partRequestId && dispatch(bulkDeleteOrderPartReqLinesThunk({
        orderId: +id,
        partRequestId,
        partRequestLineIds: checkedKeys,
        reason: reasonValue.trim(),
        closeModal: () => {
          setDeleteReasonModal(false);
        },
      }));
    }
  };
  const createPriceSourceHandle = (data: Partial<PatchProductSourceType>, closeModal: VoidFunctionType) => {
    partRequestId && sourceId && dispatch(addPartLineToOrderThunk({
      partRequestId,
      data: {
        partId: sourceId,
        stockQuantity: data.stockQuantity || null,
        pricelists: data.pricelists || null,
        manualInputQuantity: data.manualInputQuantity || null,
        manualInputUnitPrice: data.manualInputUnitPrice || null,
        reason: data.reason?.trim() ? data.reason.trim() : undefined,
      },
      closeModal,
    }));
  };
  const getProductSourceDictionaryHandle = (partId: number, openModal: VoidFunctionType) => {
    orderDetails?.order && dispatch(getPartProductSourceDictionaryThunk({
      partId,
      params: {
        businessArea: orderDetails.order.businessArea,
        customerId: orderDetails.order.customerId,
        priceDate: orderDetails.order.orderDate,
      },
      openModal,
    }));
  };
  const getProductSourceTotalHandle = (partId: number, params: PartProductSourceTotalParams) => {
    orderDetails?.order && dispatch(getPartProductSourceTotalThunk({
      partId,
      params: {
        ...params,
        priceDate: orderDetails.order.orderDate,
      },
    }));
  };
  const cancelProductSourceModal = () => {
    setProdSourceModal(false);
    setSourceName('');
    setSourceId(undefined);
    editMode && setEditMode(false);
    editedLine && setEditedLine(undefined);
    childLineMode && setChildLineMode(false);
    dispatch(setProductSourceTotalInitCopy(null));
    dispatch(setProductSourceTotal(null));
    dispatch(setProductSourceDictionary(null));
  };

  const patchLineHandle = useCallback((
    lineId: number,
    data: PatchPartReqLineDto,
    setFieldToEdit?: VoidFunctionType,
    closeModal?: VoidFunctionType,
  ) => {
    partRequestId && dispatch(patchOrderPartReqLineThunk({
      partRequestId,
      lineId,
      data: { ...data, reason: data.reason?.trim() || null },
      setFieldToEdit,
      closeModal: () => {
        patchReasonModal && setPatchReasonModal(false);
        closeModal && closeModal();
      },
      openReasonModal: () => {
        setDataToPatch({
          lineId, data, setFieldToEdit, closeModal,
        });
        setPatchReasonModal(true);
      },
    }));
    // eslint-disable-next-line
  }, [dataToPatch, partRequestId, patchReasonModal, setDataToPatch, setPatchReasonModal]);

  const handlePatchLineWithReason = useCallback(() => {
    if (dataToPatch) {
      const {
        lineId, data, setFieldToEdit, closeModal,
      } = dataToPatch;
      patchLineHandle(lineId, { ...data, reason: reasonValue.trim() }, setFieldToEdit, closeModal);
    }
  }, [dataToPatch, patchLineHandle, reasonValue]);

  const editPriceSourceHandle = (data: Partial<PatchEnquiryQuoteLineDto>, closeModal: VoidFunctionType, totalAmount?: number) => {
    const isChildLineQtyCheck = childLineMode
        && totalAmount
        && productSourceTotalInitCopy?.totalQuantity
        && (totalAmount > productSourceTotalInitCopy?.totalQuantity);

    if (isChildLineQtyCheck) {
      // setTimeout is used to prevent notification premature closure
      setTimeout(() => dispatch(setErrorMessage({
        message: `Total quantity for this parts kit component should be equal to ${productSourceTotalInitCopy?.totalQuantity}.`,
        toastId: Math.random(),
      })), 1000);
    } else {
      if (childLineMode) {
        const children = lines.map((line) => line.childLines).flat();
        const exactChild = children?.find((line) => line?.id === editedLine);
        exactChild?.id && patchLineHandle(exactChild?.id, data, undefined, closeModal);
      } else {
        editedLine && patchLineHandle(editedLine, data, undefined, closeModal);
      }
    }
  };
  const setDiscountHandle = (discountVal: number, closeModal: VoidFunctionType, reason: string) => {
    id && partRequestId && dispatch(patchBulkPartReqLines({
      partRequestId,
      orderId: +id,
      data: {
        partRequestLineIds: checkedKeys,
        patchPartRequestLineDto: {
          discount: discountVal,
          reason: reason.trim() || null,
        },
      },
      closeModal,
      openReasonModal: () => setDiscountReasonModal(true),
    }));
  };

  const openDeleteModalHandle = (id: number) => {
    setDeleteReasonModal(true);
    setEditedLine(id);
  };

  const editedLineData = useMemo(() => {
    const children = partRequestLines.map((line) => line.childLines).flat();
    const exactChild = children?.find((line) => line?.id === editedLine);
    const exactParent = partRequestLines.find((el) => el.id === editedLine);
    return exactChild || exactParent;
  }, [editedLine, partRequestLines]);
  return (
    <>
      <AddDiscountModal
        isVisible={discountModal}
        onCancel={() => setDiscountModal(false)}
        setDiscount={(discountVal, closeModal) => setDiscountHandle(discountVal, closeModal, reasonValue)}
        discountReasonModal={discountReasonModal}
        setDiscountReasonModal={setDiscountReasonModal}
        discountReasonValue={reasonValue}
        setDiscountReasonValue={setReasonValue}

      />
      <FillInDraftLineModal
        isVisible={partsModal}
        onCancel={() => {
          setPartsModal(false);
          setEditedLine(undefined);
          editMode && setEditMode(false);
        }}
        title="Fill in order line"
        infoSources={availableInfoSourcesByStatus}
        chooseInfoSource={(v) => dispatch(setCurrentPartReqSource(v))}
        currentInfoSource={currentPartReqSource}
        setInitFiltersAndTableData={setInitFiltersAndTableData}
        disabledSourceBtnLabel="Custom line"
        editMode={editMode}
        editedLine={editedLine}
        isCorePart={isCore}
        isCustom={isCustom}
        isNonCorePart={isNonCore}
        isPartKit={isPartsKit}
        setProdSourceModal={(partId: number, name: string) => setProdSourceModalHandle(partId, name)}
        parts={orderPartRequestParts}
        setParts={(data) => dispatch(setOrderPartRequestParts(data))}
        filters={orderPartReqDraftLinesFilters}
        setFilters={(values) => dispatch(setOrderPartReqDraftLinesFilters(values))}
        isSuitableForCheckboxShown={!isOrderVehManual && isJobTypeSupply}
        fetchParts={(filters, isShow) => fetchParts(filters, isShow)}
        partsKitFilters={orderPartRequestPartsKitFilters}
        setPartsKitFilters={(filters) => dispatch(setOrderPartsKitDraftLinesFilters({ ...filters }))}
        partsKitParts={orderPartRequestPartsKit}
        setPartsKitParts={(list) => dispatch(setOrderPartsKitRequestParts(list))}
        fetchPartsKitParts={(values, isShow) => fetchPartsKitParts(values, isShow)}
        createPartsKitLineThunk={(partsKitId, closeModal) => createPartsKitLineThunk(partsKitId, closeModal, reasonValue)}
        editCustomHandle={(values, closeModal) => editedLine && patchLineHandle(editedLine, values, closeModal)}
        createCustomHandle={(values, closeModal) => createCustomHandle(values, closeModal, reasonValue)}
        initCustomLine={initCustomLine}
        originName="an order"
      />
      <SelectProductSourceModal
        isVisible={prodSourceModal}
        onCancel={() => cancelProductSourceModal()}
        closePartsModal={() => setPartsModal(false)}
        sourceName={sourceName}
        sourceId={sourceId}
        editMode={editMode}
        editedLine={editedLine}
        childLineMode={childLineMode}
        setProductSourceTotal={(v) => dispatch(setProductSourceTotal(v))}
        setProductSourceDictionary={(v) => dispatch(setProductSourceDictionary(v))}
        lines={lines}
        linesParts={orderPartRequestParts.items}
        productSourceDictionary={productSourceDictionary}
        editThunk={(data, closeModal, totalAmount) => editPriceSourceHandle(data, closeModal, totalAmount)}
        createThunk={(data, closeModal) => createPriceSourceHandle(data, closeModal)}
        productSourceTotal={productSourceTotal}
        getProductSourceDictionary={(partId, openModal) => getProductSourceDictionaryHandle(partId, openModal)}
        getProductSourceTotal={(partId, stockQty, manualQty, manualUnitPrice, pricelists) => {
          const params = {
            stockQuantity: stockQty,
            pricelists,
            manualInputQuantity: manualQty,
            manualInputUnitPrice: manualUnitPrice,
          };
          getProductSourceTotalHandle(partId, params);
        }}
        reasonRequired
      />
      <ReadyCollectedPartsListModal
        visible={isReadyCollectedModal}
        onCancel={() => {
          setIsReadyCollectedModal(false);
          setEditedLine(undefined);
        }}
        editedLineData={editedLineData}
      />
      <ReasonModal
        visible={patchReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => setPatchReasonModal(false)}
        onSubmit={handlePatchLineWithReason}
        type="update"
      />
      <ReasonModal
        visible={deleteReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => {
          setEditedLine(undefined);
          setDeleteReasonModal(false);
        }}
        onSubmit={deleteConfirmationHandle}
        type="delete"
      />
      <ReasonModal
        visible={newLineReasonModal}
        reasonValue={reasonValue}
        setReasonValue={setReasonValue}
        onCancel={() => setNewLineReasonModal(false)}
        onSubmit={() => {
          const { partsKitId, customLineValues, closeModal } = newLineReasonData || {};
          if (partsKitId && closeModal) {
            createPartsKitLineThunk(partsKitId, closeModal, reasonValue);
          }
          if (customLineValues && closeModal) {
            createCustomHandle(customLineValues, closeModal, reasonValue);
          }
        }}
        type="new"
      />
      <MarkAsFreeOfChargeModal
        visible={isMarkAsFree}
        onCancel={() => {
          setIsMarkAsFree(false);
          setEditedLine(undefined);
        }}
        currentLineId={editedLine}
        partRequestLines={partRequestLines}
        entityName="order"
        confirmThunk={(data) => {
          const closeHandle = () => {
            setIsMarkAsFree(false);
            setPatchReasonModal(false);
            setEditedLine(undefined);
          };
          editedLine && patchLineHandle(editedLine, data, undefined, closeHandle);
        }}
      />
      <TableNew className="orderPartRequestTable table-with-editable-cells" hasFooter={allowedToManage}>
        <OrderPartReqColumns
          columns={columns}
          isAll={isAll}
          isIndeterminate={isIndeterminate}
          checkAll={checkAll}
          totalElements={linesArr?.length || 0}
          disabled={!linesArr || linesArr.length === 0 || isOrderReadMode}
        />
        <Tbody>
          {linesArr?.length === 0
            ? <EmptyTableSection text="No records" colSpan={allowedToManage ? columns.length + 2 : columns.length} />
            : <PartRequestBodyCells
              checkedKeys={checkedKeys}
              checkRows={checkRowHandle}
              columns={columns}
              isLineActionDisabled={isLineActionDisabled}
              isPartsKitLineHasAllocatedChild={isPartsKitLineHasAllocatedChild}
              editQty={(id: number, isChildLine?: boolean) => {
                setProdSourceModal(true);
                setEditMode(true);
                setEditedLine(id);
                isChildLine && setChildLineMode(true);
              }}
              openCustomModal={(num: number) => {
                setEditedLine(num);
                setEditMode(true);
                setPartsModal(true);
              }}
              openDeleteModal={(id: number) => {
                openDeleteModalHandle(id);
              }}
              openMarkAsFree={(id: number) => {
                setEditedLine(id);
                setIsMarkAsFree(true);
              }}
              openReadyCollectedListModal={(id) => {
                setEditedLine(id);
                setIsReadyCollectedModal(true);
              }}
              permissionToManageLines={PermissionEnum.OrderEditPartRequestLineFieldsAndUnlockPartRequestLineActions}
              permissionToManageCharge={PermissionEnum.OrderMarkPartLineAsFreeOfChargeBilled}
              linesArr={linesArr}
              initLinesList={partRequestLines}
              editInlineThunkHandle={(data, lineId, setFieldToEdit) => {
                lineId && patchLineHandle(lineId, data, setFieldToEdit);
              }}
            />}
        </Tbody>
        {allowedToManage && (
          <Tfoot>
            <Tr>
              <TdNew colSpan={columns.length + 1}>
                {footerActions.map((a) => a.isVisible && <Button
                  type="text"
                  key={a.label}
                  label={a.label}
                  icon={a.icon && <FontAwesomeIcon icon={a.icon} />}
                  onClick={a.onClick}
                  disabled={a.disabled || isOrderReadMode}
                />)}
              </TdNew>
            </Tr>
          </Tfoot>
        )}
      </TableNew>
    </>
  );
};

export default OrderPartRequestTable;
