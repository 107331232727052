import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { VehicleContentModes, VehicleViewModeLabelsEnum, VehicleViewModeParamsEnum } from '../enums/VehicleViewEnums';
import { useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { vehicleDetailsSelector } from '../../../store/selectors/vehiclesSelectors';
import { tabCountHandle } from '../../../core/utils/tabCountHandle';

export const useVehicleParams = () => {
  const [params, setSearchParams] = useSearchParams();
  const { backHandle } = useLocationHistoryState(true);
  const currentUser = useAppSelector(currentUserSelector);
  const vehicleDetails = useAppSelector(vehicleDetailsSelector);
  const mode = params.get('mode');

  const setModeParam = (mode: VehicleViewModeParamsEnum) => {
    setSearchParams({ mode });
  };

  const tabs = [
    {
      label: VehicleViewModeLabelsEnum.VEHICLE_DETAILS,
      value: VehicleViewModeParamsEnum.VEHICLE_DETAILS,
    },
    {
      label: VehicleViewModeLabelsEnum.VEHICLE_INSPECTION,
      value: VehicleViewModeParamsEnum.VEHICLE_INSPECTION,
    },
    {
      label: `${VehicleViewModeLabelsEnum.VEHICLE_ATTACHMENTS} ${tabCountHandle(vehicleDetails?.attachmentsCount)}`,
      value: VehicleViewModeParamsEnum.VEHICLE_ATTACHMENTS,
    },
    {
      label: `${VehicleViewModeLabelsEnum.VEHICLE_LINKS} ${tabCountHandle(vehicleDetails?.linksCount)}`,
      value: VehicleViewModeParamsEnum.VEHICLE_LINKS,
    },
  ];

  useEffect(() => {
    if (currentUser) {
      const { permissions } = currentUser.userContextRole;
      if (!mode && permissions.includes(PermissionEnum.VehicleViewPage)) {
        setSearchParams({ mode: tabs[0].value }, { replace: true });
      } else if (!VehicleContentModes.includes(mode as VehicleViewModeParamsEnum)) {
        setSearchParams({ mode: tabs[0].value }, { replace: true });
      }
    }
    // eslint-disable-next-line
  }, [currentUser]);

  return {
    backHandle,
    mode,
    setModeParam,
    tabs,
  };
};
