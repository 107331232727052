import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AssigneeDto, AxiosErrorResponse,
  CoreAttachListElType,
  DictionaryItem,
  GenericColumnType, GenericVoidFunctionType, infiniteScrollInitPaging,
  NumberFunctionType,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { enquiryAPI } from '../../../api/enquiryApi';
import {
  EnquiryColumnType,
  EnquiryDto, EnquiryFiltersExtended,
} from '../../../enquiry/enquiries/types/enquiryTypes';
import {
  setDashboardLoading, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { setColumnsPagination, setEnquiryCollections } from '../../slices/enquiriesSlice';
import { SUCCESSFUL_CREATE } from '../../../core/utils/successMessages';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { enquiryDashboardCollectionsHelper } from '../../../enquiry/enquiries/utils/enquiryDashboardCollectionsHelper';
import { RootState } from '../../store';
import {
  UpdateEnquiryStatusResponse,
} from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';

export const getEnquiriesNew = createAsyncThunk<
ResponseSingleResult<{ enquiryCollections: Array<EnquiryColumnType> | null, assignees: Array<AssigneeDto> | null }>,
{
  filters: EnquiryFiltersExtended,
  signal?: AbortSignal,
}
>(
  'get/NewEnquiries',
  async ({
    filters, signal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    dispatch(setEnquiryCollections(null));
    try {
      const response = await enquiryAPI.fetchNewEnquiriesList(filters, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiriesNewPortion = createAsyncThunk<
ResponseSingleResult<{ enquiryCollections: Array<EnquiryColumnType> | null, assignees: Array<AssigneeDto> | null }>,
{
  filters: EnquiryFiltersExtended,
  currentStatus: number,
  // eslint-disable-next-line
  setNewPortion:(status: number, data: EnquiryDto[]) => void,
  // eslint-disable-next-line
}>(
    'get/EnquiriesNewPortion',
    async (
      {
        filters, currentStatus, setNewPortion,
      },
      { getState, dispatch, rejectWithValue },
    ) => {
      dispatch(setLoading(true));
      try {
        const response = await enquiryAPI.fetchNewEnquiriesList(filters);
        const { enquiries } = getState() as RootState;
        const { columnsPagination } = enquiries;
        const resCollections = response.data.data.enquiryCollections;
        const list = resCollections?.find((el) => el.status === currentStatus)?.enquiries;
        setNewPortion(currentStatus, list || []);
        const newPagination = columnsPagination.map((el) => {
          if (el.status === currentStatus) {
            return {
              ...el, currentPage: filters.page,
            };
          } else return el;
        });
        dispatch(setColumnsPagination(newPagination));
        dispatch(setLoading(false));
        return response.data;
      } catch (err) {
        const error = err as AxiosErrorResponse;
        dispatch(setLoading(false));
        return rejectWithValue(error.response?.data);
      }
    },
    );

export const editEnquiryThunk = createAsyncThunk<
ResponseSingleResult<UpdateEnquiryStatusResponse>,
{
  id: number,
  statusTransition: number,
  sourceId: number,
  setColumns: VoidFunctionType,
  rejectionReason?: string,
  onClose?: VoidFunctionType,
  setColumnsLoading: GenericVoidFunctionType<number[]>,
}>(
  'put/EnquiryStatusTransition',
  async ({
    id, statusTransition, sourceId, setColumns, rejectionReason, onClose, setColumnsLoading,
  }, { getState, dispatch, rejectWithValue }) => {
    try {
      const { enquiries, core } = getState() as RootState;
      const { currentBusinessArea } = core;
      const { columnsPagination, enquiryCollections, filters } = enquiries;
      setColumnsLoading([statusTransition, sourceId]);
      const response = await enquiryAPI.editEnquiryStatus(id, statusTransition, rejectionReason);
      if (currentBusinessArea) {
        const res = await enquiryAPI.fetchNewEnquiriesList(
          {
            ...filters,
            ...infiniteScrollInitPaging,
            applyPersonalFilter: true,
            businessArea: currentBusinessArea,
            statuses: [statusTransition, sourceId],
          },
        );
        const collections = res.data.data.enquiryCollections || [];
        if (enquiryCollections) {
          const { newPagination, newEnquiryCollections } = enquiryDashboardCollectionsHelper(
            enquiryCollections,
            collections,
            columnsPagination,
            statusTransition,
            sourceId,
          );
          dispatch(setEnquiryCollections(newEnquiryCollections));
          dispatch(setColumnsPagination(newPagination));
        }
      }
      onClose && onClose();
      setColumnsLoading([]);
      return response.data;
    } catch (err) {
      setColumnsLoading([]);
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      setColumns();
      onClose && onClose();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editStatusToAcceptedThunk = createAsyncThunk<
ResponseSingleResult<UpdateEnquiryStatusResponse>,
{
  id: number,
  statusTransition: number,
  setColumns?: VoidFunctionType,
  list: CoreAttachListElType[],
  comment: string,
  cancelHandle: VoidFunctionType,
  navigateToCreatedOrder: NumberFunctionType,
}>(
  'patch/EnquiryStatusToAcceptedTransition',
  async ({
    id, statusTransition, setColumns,
    list, comment, cancelHandle, navigateToCreatedOrder,
  }, { dispatch, rejectWithValue }) => {
    try {
      // 1.ATTACHMENTS UPLOAD
      if (list.length > 0) {
        const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));

        const resUploadUri = await enquiryAPI.postEnquiryAttachUploadUri(id, uploadUriArr);
        const attachments = await uploadAttachmentsAsyncHandle(
          list,
          comment,
          resUploadUri,
          dispatch,
        );

        await enquiryAPI.postEnquiryAttach(id, attachments);
        dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      }

      // 2.STATUS TRANSITION
      const response = await enquiryAPI.editEnquiryStatus(id, statusTransition);
      const { createdOrders } = response.data.data;
      if (createdOrders && createdOrders.length > 0) {
        createdOrders.forEach(({ orderNumber }) => {
          dispatch(setSuccessMessage({ message: `Order ${orderNumber} was successfully created.`, toastId: Math.random() }));
        });
        const firstOrderId = createdOrders[0].id;
        navigateToCreatedOrder(firstOrderId);
      }
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      setColumns && setColumns();
      cancelHandle();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEnquiryLayoutThunk = createAsyncThunk<
ResponseSingleResult<{ createdIds: number[], updatedIds: number[] } | null>,
{
  businessArea: number,
  layoutOptions: Array<GenericColumnType<Array<EnquiryDto>>> | null,
  closeModal: VoidFunctionType;
  permittedStatuses: DictionaryItem[],
}
>(
  'put/Enquiry/UserLayoutSettings',
  async ({
    businessArea, layoutOptions, closeModal, permittedStatuses,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(false));
    try {
      const layout = layoutOptions?.map((l) => ({
        enquiryStatus: l.status,
        order: l.order,
        isVisible: !l.isHidden,
      })) || [];
      const { enquiries: { filters } } = getState() as RootState;
      const response = await enquiryAPI.editEnquiryUserLayout(businessArea, layout);
      dispatch(setEnquiryCollections(layoutOptions));
      const currentFilters = {
        ...filters,
        businessArea,
        applyPersonalFilter: true,
        statuses: permittedStatuses.map((el) => el.value),
        page: 1,
      };
      dispatch(getEnquiriesNew({ filters: currentFilters }));
      dispatch(setLoading(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
