import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  purchaseOrderTimelineFiltersSelector,
  purchaseOrderTimelineSelector,
} from '../../../../../../store/selectors/purchaseOrderSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import Switch from '../../../../../../core/components/switch/Switch';
import { setPurchaseOrderTimelineFilters } from '../../../../../../store/slices/purchaseOrderSlice';
import Pagination from '../../../../../../core/components/pagination/Pagination';
import Timeline from '../../../../../../core/components/timeline/Timeline';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import PurchaseOrderEvent from './PurchaseOrderEvent';
import {
  createPurchaseOrderNoteThunk,
  getPurchaseOrderTimeline,
} from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { getPurchaseOrderEventTypesThunk } from '../../../../../../store/thunks/core/coreEventTypesThunks';
import { getPurchaseOrderFailReason } from '../../../../../../store/thunks/stock/purchase-orders/purchaseOrdersDashThunks';
import ActivityLabel from '../../../../../../common/components/activity-events/ActivityLabel';
import ActivityNoteInput from '../../../../../../common/components/activity-events/ActivityNoteInput';
import { purchaseOrderEventSourcesValuesSelector } from '../../../../../../store/selectors/coreSelectors';

const PurchaseActivity: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { totalCount, items } = useAppSelector(purchaseOrderTimelineSelector);
  const timelineFilters = useAppSelector(purchaseOrderTimelineFiltersSelector);
  const allowedToAddNote = usePermission(PermissionEnum.StockPurchaseOrdersViewPage);
  const { stockSource } = useAppSelector(purchaseOrderEventSourcesValuesSelector);
  const [value, setValue] = useState('');

  useEffect(() => {
    dispatch(getPurchaseOrderEventTypesThunk());
    dispatch(getPurchaseOrderFailReason());
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (id && stockSource) {
      dispatch(getPurchaseOrderTimeline({ id: +id, filters: timelineFilters, source: stockSource }));
    }
    // eslint-disable-next-line
  }, [id, timelineFilters.mainOnly, timelineFilters.page, stockSource]);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <PurchaseOrderEvent item={el} />,
  }));

  const addNote = () => {
    id && stockSource && dispatch(createPurchaseOrderNoteThunk({
      purchaseOrderId: +id,
      content: value.trim(),
      eventSource: stockSource,
      setValue: () => setValue(''),
    }));
  };
  return (
    <section className="info-grid__column">
      <div className="info-grid__title-wrapper">
        <h2 className="info-grid__title-inner">Activity</h2>
        <Switch
          checked={!timelineFilters.mainOnly}
          onChange={(val) => dispatch(setPurchaseOrderTimelineFilters({ ...timelineFilters, mainOnly: !val, page: 1 }))}
          textLeft="Main events"
          textRight="Full log"
          className="detailsWrap__activity-titleWrap__switch"
          singleBackground
        />
      </div>
      <Pagination
        currentPage={timelineFilters.page}
        pageSize={timelineFilters.pageSize}
        onChange={(page) => {
          dispatch(setPurchaseOrderTimelineFilters({ ...timelineFilters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />
      <ActivityNoteInput
        value={value}
        setValue={setValue}
        addNote={addNote}
        disabledInput={!allowedToAddNote}
      />
    </section>
  );
};

export default PurchaseActivity;
