import React from 'react';
import OrderLineElement from './OrderLineElement';
import EmptyTableSection from '../../../../core/components/table-components/EmptyTableSection';
import Tbody from '../../../../core/components/table-components/Tbody';
import { InvoiceOrderLineTableType, NominalCodesTooltip } from '../../types/invoicingCommonTypes';
import { NumberFunctionType } from '../../../../core/types/coreTypes';

type OrderLinesBodyProps = {
  lines?: InvoiceOrderLineTableType[],
  nominalCodesTooltips?: NominalCodesTooltip[],
  isSales?: boolean,
  openNominalCodesModal: NumberFunctionType,
  onPatchComplete?: NumberFunctionType,
}

const OrderLinesBody: React.FC<OrderLinesBodyProps> = ({
  lines, nominalCodesTooltips, isSales, openNominalCodesModal, onPatchComplete,
}) => {
  return (
    <Tbody>
      {lines?.length && lines.length > 0
        ? lines.map((item, i) => <React.Fragment key={i}>
          <OrderLineElement
            key={i}
            item={item}
            openNominalCodesModal={(id) => openNominalCodesModal(id)}
            isSales={isSales}
            isAssignNominalCodesEnabled={!item.childLines || !item.childLines?.length}
            nominalCodesTooltips={nominalCodesTooltips}
            onPatchComplete={onPatchComplete}
          />
          {item.childLines && item.childLines.length > 0 && item.childLines.map((child, index) => <OrderLineElement
            key={index}
            item={child}
            openNominalCodesModal={(id) => openNominalCodesModal(id)}
            isSales={isSales}
            isChild
            isAssignNominalCodesEnabled
            nominalCodesTooltips={nominalCodesTooltips}
          />)}
        </React.Fragment>)
        : <EmptyTableSection text="No records" colSpan={10} />}
    </Tbody>
  );
};

export default OrderLinesBody;
