export const finalSummaryValueHandle = (min: string | null, max: string | null) => {
  if (min !== null && max !== null && +min !== +max) {
    return `£ ${min} - £ ${max}`;
  }
  if (min !== null && max !== null && +min === +max) {
    return `£ ${min}`;
  }
  if (min === null && max !== null) {
    return `£ ${max}`;
  }
  if (min !== null && max === null) {
    return `£ ${min}`;
  } else {
    return '-';
  }
};
