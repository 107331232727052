import React, { useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  numberOfConvertedVehiclesFiltersDateSelector,
  numberOfConvertedVehiclesFiltersSelector,
  numberOfConvertedVehiclesGlobalFiltersSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { getCustomersDictionaryThunk } from '../../../../../store/thunks/shared/sharedThunks';
import {
  setNumberOfConvertedVehiclesData,
  setNumberOfConvertedVehiclesFilters,
  setReportIsVisible,
} from '../../../../../store/slices/reportsSlice';
import {
  DateRangeTableFilterType, PickerValues,
} from '../../../../../core/types/coreTypes';
import KeywordFiltersSection from '../../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import Button from '../../../../../core/components/button/Button';
import { useNumberOfConvertedVehiclesDefaultOrdering } from '../../../../hooks/useNumberOfConvertedVehiclesDefaultOrdering';
import { getNumberOfConvertedVehiclesReportThunk } from '../../../../../store/thunks/reports/reportsThunks';
import { NumberOfConvertedVehiclesReportFilters } from '../../../../types/NumberOfConvertedVehiclesReportTypes';
import { initNumberOfConvertedVehiclesData } from '../../../reports-content/sales/number-of-converted-vehicles/utils/data';

const NumberOfConvertedVehiclesGlobalFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const checkboxFilters = useAppSelector(numberOfConvertedVehiclesGlobalFiltersSelector);
  const datePickerFilters = useAppSelector(numberOfConvertedVehiclesFiltersDateSelector);
  const filters = useAppSelector(numberOfConvertedVehiclesFiltersSelector);

  const isSelectedGlobalFilters = useMemo(() => {
    if (filters) {
      const {
        vehicleProfileCode, businessAreas, customers, jobTypes, completedDate,
      } = filters;
      const hasSelectedCheckboxes = [businessAreas, customers, jobTypes].some((el) => !!el && !!el.length);
      const hasSelectedDatepicker = completedDate && (completedDate.min || completedDate.max);
      return hasSelectedCheckboxes || hasSelectedDatepicker || vehicleProfileCode;
    }
    return false;
  }, [filters]);

  const [isExpanded, setIsExpanded] = useState(false);

  const defaultOrdering = useNumberOfConvertedVehiclesDefaultOrdering();

  useEffect(() => {
    dispatch(getCustomersDictionaryThunk({ filters: undefined }));

    return () => {
      dispatch(setNumberOfConvertedVehiclesFilters(defaultOrdering));
      dispatch(setNumberOfConvertedVehiclesData(initNumberOfConvertedVehiclesData));
    };
    // eslint-disable-next-line
  }, []);

  const initialPickerValue: DateRangeTableFilterType = {
    completedDate: [null, null],
  };
  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>(initialPickerValue);

  const pickerHandle = (field: string, values?: PickerValues) => {
    const newFilters: NumberOfConvertedVehiclesReportFilters = {
      ...filters,
      [field]: values || undefined,
    };
    dispatch(setNumberOfConvertedVehiclesFilters(newFilters));
    dispatch(setNumberOfConvertedVehiclesData(initNumberOfConvertedVehiclesData));
    dispatch(setReportIsVisible(false));
  };

  const clearFilters = () => {
    setPickerValue(initialPickerValue);
    dispatch(setNumberOfConvertedVehiclesFilters(defaultOrdering));
    dispatch(setNumberOfConvertedVehiclesData(initNumberOfConvertedVehiclesData));
    dispatch(setReportIsVisible(false));
  };

  const getFilteredResHandle = (values: number[], field: string) => {
    dispatch(setNumberOfConvertedVehiclesFilters({
      ...filters,
      convertedVehicles: undefined,
      [field]: values,
    }));
    dispatch(setNumberOfConvertedVehiclesData(initNumberOfConvertedVehiclesData));
    dispatch(setReportIsVisible(false));
  };

  const setSearchValue = (value: string) => {
    dispatch(setNumberOfConvertedVehiclesFilters({ ...filters, vehicleProfileCode: value }));
  };

  const pressEnterHandle = () => {
    if (!filters?.vehicleProfileCode?.trim().length) {
      setSearchValue('');
    } else {
      dispatch(getNumberOfConvertedVehiclesReportThunk({ filters }));
      dispatch(setReportIsVisible(true));
    }
  };

  return (
    <>
      <KeywordFiltersSection
        isSearch
        searchValue={filters?.vehicleProfileCode}
        setSearchValue={setSearchValue}
        onPressEnter={pressEnterHandle}
        placeholder="Enter a vehicle profile code"
        filters={checkboxFilters}
        dateFilters={datePickerFilters}
        pickerValue={pickerValue}
        setPickerValue={setPickerValue}
        pickerHandle={pickerHandle}
        isFullWidth
        onClear={clearFilters}
        className={classNames('reportFilters__filters', { 'reportFilters__filters--expanded': isExpanded })}
        getFilteredResHandle={getFilteredResHandle}
        clearSearchValue={false}
      />
      <div className="reportFilters__actions">
        <Button
          label={`${isExpanded ? 'Hide' : 'Show'} filters`}
          onClick={() => setIsExpanded(!isExpanded)}
          className={classNames('reportFilters__expand-btn', { 'reportFilters__expand-btn--active': isSelectedGlobalFilters })}
        />
        <Button
          label="Show report"
          className="reportFilters__action-button reportFilters__action-button--single"
          icon={<FontAwesomeIcon icon={faClipboard} />}
          onClick={() => {
            dispatch(getNumberOfConvertedVehiclesReportThunk({ filters }));
            dispatch(setReportIsVisible(true));
          }}
        />
      </div>
    </>
  );
};

export default NumberOfConvertedVehiclesGlobalFilters;
