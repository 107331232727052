import React, { useEffect, useMemo } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../core/types/coreTypes';
import Modal from '../../../../../core/components/modal/Modal';
import { ErrorsEnum } from '../../../../../core/enums/errorsEnum';
import { maxLengthMessageHandle } from '../../../../../core/utils/errorMessageHandle';
import Input from '../../../../../core/components/input/Input';
import ButtonActions from '../../../../../core/components/button-actions/ButtonActions';
import { EMAIL_REGEX, NAME_REGEX, PHONE_FAX_REGEX } from '../../../../../core/utils/regex';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { createContactUtil } from '../../../../../common/utils/createContactUtils';
import { AddContactFields, AddressFields, initAddressFields } from '../../../../../common/types/commonTypes';
import { SupplierContactDto } from '../../../types/supplierViewPageTypes';
import { supplierDetailsSelector } from '../../../../../store/selectors/supplierSelector';
import {
  createNewSupplierContactThunk,
  editSupplierContactThunk,
} from '../../../../../store/thunks/suppliers/supplierContactsThunks';
import AddressField from '../../../../../common/components/address/AddressField';
import { isGlobalLoadingSelector } from '../../../../../store/selectors/coreSelectors';

type SupplierContactModalProps = {
  isVisible: boolean,
  onClose: VoidFunctionType,
  addressFields: AddressFields,
  setAddress: BooleanFunctionType,
  setAddressFields: (values: AddressFields) => void,
  contact?: SupplierContactDto,
  setSelectedContact: (contact: SupplierContactDto | undefined) => void
}

const SupplierContactModal:React.FC<SupplierContactModalProps> = ({
  isVisible, onClose,
  addressFields,
  setAddress,
  setAddressFields,
  contact,
  setSelectedContact,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const supplierDetails = useAppSelector(supplierDetailsSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);

  const {
    control, handleSubmit, reset, setError, setValue, watch,
  } = useForm<AddContactFields>({
    defaultValues: {
      firstName: '',
      lastName: '',
      position: '',
      email: '',
      telephone: '',
      mobile: '',
    },
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const email = watch('email');
  const telephone = watch('telephone');
  const mobile = watch('mobile');
  const contacts = useMemo(() => [email, telephone, mobile], [email, telephone, mobile]);

  const onCloseHandler = () => {
    reset();
    setSelectedContact(undefined);
    setAddressFields(initAddressFields);
    onClose();
  };

  const onSubmit = (values: AddContactFields) => {
    const { location, valuesCopy } = createContactUtil(addressFields, values);
    if (contact) {
      id && dispatch(editSupplierContactThunk({
        contactId: contact.id,
        data: {
          supplierId: +id,
          ...location,
          ...valuesCopy,
        },
        closeModal: onCloseHandler,
      }));
    } else {
      id && dispatch(createNewSupplierContactThunk({
        data: {
          supplierId: +id,
          ...location,
          ...valuesCopy,
        },
        closeModal: onCloseHandler,
      }));
    }
  };

  useEffect(() => {
    if (isVisible) {
      if (contact) {
        setValue('firstName', contact.firstName || '');
        setValue('lastName', contact.lastName || '');
        setValue('position', contact.position || '');
        setValue('email', contact.email || '');
        setValue('telephone', contact.telephone || '');
        setValue('mobile', contact.mobile || '');
      }
    } else reset();
    // eslint-disable-next-line
  }, [contact, isVisible]);

  return (
    <Modal
      title={contact ? 'Edit contact' : 'Create contact'}
      visible={isVisible}
      onCancel={onCloseHandler}
      width={700}
      destroyOnClose
    >
      <form className="addContact__newForm details-form" onSubmit={handleSubmit(onSubmit)}>
        <Input
          value={supplierDetails?.supplier.name || ''}
          disabled
          label="Company name"
          className="details-form__field--lg"
        />
        <Controller
          name="firstName"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            maxLength: { value: 100, message: maxLengthMessageHandle(100) },
            pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim()) {
                  setValue('firstName', '');
                  setError('firstName', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              error={errors.firstName?.message}
              label="First name"
            />
          )}
        />
        <Controller
          name="lastName"
          control={control}
          rules={{
            required: ErrorsEnum.REQUIRED,
            maxLength: { value: 100, message: maxLengthMessageHandle(100) },
            pattern: { value: NAME_REGEX, message: ErrorsEnum.ONLY_TEXT_AND_DASH_APOSTROPHE },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim()) {
                  setValue('lastName', '');
                  setError('lastName', { type: 'required', message: ErrorsEnum.REQUIRED });
                }
              }}
              error={errors.lastName?.message}
              label="Last name"
            />
          )}
        />
        <Controller
          name="position"
          control={control}
          rules={{
            maxLength: { value: 200, message: maxLengthMessageHandle(200) },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={field.onChange}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim()) {
                  setValue('position', '');
                }
              }}
              error={errors.position?.message}
              label="Position"
            />
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{
            required: contacts.every((el) => !el?.trim()) && ErrorsEnum.AT_LEAST_ONE_CONTACT,
            pattern: { value: EMAIL_REGEX, message: ErrorsEnum.INVALID_EMAIL },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                errors.telephone?.type === 'required' && setError('telephone', { type: 'required', message: undefined });
                errors.mobile?.type === 'required' && setError('mobile', { type: 'required', message: undefined });
              }}
              error={errors.email?.message}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim() && contacts.every((el) => !el?.trim())) {
                  setValue('email', '');
                  setError('email', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
                }
              }}
              label="Email"
            />
          )}
        />
        <Controller
          name="telephone"
          control={control}
          rules={{
            required: contacts.every((el) => !el?.trim()) && ErrorsEnum.AT_LEAST_ONE_CONTACT,
            pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
            minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
            maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                errors.email?.type === 'required' && setError('email', { type: 'required', message: undefined });
                errors.mobile?.type === 'required' && setError('mobile', { type: 'required', message: undefined });
              }}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim() && contacts.every((el) => !el?.trim())) {
                  setValue('telephone', '');
                  setError('telephone', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
                }
              }}
              error={errors.telephone?.message}
              label="Telephone"
            />
          )}
        />
        <Controller
          name="mobile"
          control={control}
          rules={{
            required: contacts.every((el) => !el?.trim()) && ErrorsEnum.AT_LEAST_ONE_CONTACT,
            pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_MOBILE },
            minLength: { value: 7, message: ErrorsEnum.INVALID_MOBILE },
            maxLength: { value: 30, message: ErrorsEnum.INVALID_MOBILE },
          }}
          render={({ field }) => (
            <Input
              value={field.value}
              onChange={(e) => {
                field.onChange(e.target.value);
                errors.telephone?.type === 'required' && setError('telephone', { type: 'required', message: undefined });
                errors.email?.type === 'required' && setError('email', { type: 'required', message: undefined });
              }}
              error={errors.mobile?.message}
              onBlur={(e) => {
                const val = e.target.value;
                if (!val.trim() && contacts.every((el) => !el?.trim())) {
                  setValue('mobile', '');
                  setError('mobile', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
                }
              }}
              label="Mobile"
            />
          )}
        />
        <AddressField addressFields={addressFields} setAddressModal={setAddress} />
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={onCloseHandler}
          createType="submit"
          createLabel="Save"
          className="details-form__actions"
          isLoading={isGlobalLoading}
        />
      </form>
    </Modal>
  );
};

export default SupplierContactModal;
