import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DictionaryItem, DownloadUriResponse,
  GenericObject, GenericVoidFunctionType,
  RangeTableFiltersType, ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
  NumericRangeType, StringFunctionType, BooleanFunctionType,
} from '../../../../core/types/coreTypes';
import { stockApi } from '../../../../api/stockApi';
import { GetPurchaseOrderResponse, PurchaseOrderDto } from '../../../../stock/purchase-orders/dashboard/types/purchaseOrdersTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../../slices/coreSlice';
import { SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import {
  GetPurchaseOrderPartsResponse,
  PartFailedQtyRestModel,
  PatchPurchaseOrderPartDataType, PatchPurchaseOrderRestModel,
  PostPurchaseOrderPartDataType, PurchaseOrderEventDtoBase,
  PurchaseOrderPartDto,
  PurchaseOrderPartsListFiltersType,
  PurchaseOrderSummaryResponseType,
  PutPurchaseOrderRestModel,
} from '../../../../stock/purchase-orders/view-page/types/purchaseOrderViewPageTypes';
import {
  setPurchaseOrderPartsListFilters,
  setPurchaseOrderTimeline,
  setPurchaseOrderTimelineFilters,
} from '../../../slices/purchaseOrderSlice';
import { maxCommonDecimal } from '../../../../core/utils/regex';
import { downloadCsv } from '../../../../core/utils/downloadFileHandler';
import {
  AddressFields,
  DefaultAddressDto,
  ExtendedTimelineFilters,
} from '../../../../common/types/commonTypes';
import { RootState } from '../../../store';
import { statusTransitionErrorHandle } from '../../../../core/utils/statusTransitionErrorHandle';
import { UserEventPurchaseOrder } from '../../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../../slices/settingsSlice';
import { PurchaseOrderEventSourceEnum } from '../../../../stock/purchase-orders/view-page/enums/PurchaseOrderViewEnum';

export const getPurchaseOrderPartSummaryThunk = createAsyncThunk<ResponseSingleResult<PurchaseOrderSummaryResponseType>,
{ purchaseOrderId: number }>(
  'get/PurchaseOrderSummary',
  async ({ purchaseOrderId }, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderSummary(purchaseOrderId);
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderById = createAsyncThunk<GetPurchaseOrderResponse,
{ id: number }
>(
  'get/PurchaseOrderById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrderById(id);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderTimeline = createAsyncThunk<ResponseResult<PurchaseOrderEventDtoBase[]>,
{ id: number, filters: ExtendedTimelineFilters, source: number }
>(
  'get/PurchaseOrderTimeline',
  async ({ id, filters, source }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPurchaseOrderTimeline(id, filters, source);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPurchaseOrderByIdThunk = createAsyncThunk<ResponseSingleResult<{ purchaseOrder: PurchaseOrderDto } | null>,
{
  id: number,
  data: PatchPurchaseOrderRestModel,
  setInitFormValues?: VoidFunctionType,
  onCloseModal?: VoidFunctionType,
}
>(
  'patch/PurchaseOrderById',
  async ({
    id, data, setInitFormValues, onCloseModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const {
        purchaseOrder: { purchaseOrderTimelineFilters },
        core: { purchaseOrderEventSources },
      } = getState() as RootState;
      const res = await stockApi.patchPurchaseOrderById(id, data);
      dispatch(getPurchaseOrderById({ id }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId: id }));
      if (purchaseOrderTimelineFilters.page === 1) {
        const stockSource = purchaseOrderEventSources.find((el) => el.label === PurchaseOrderEventSourceEnum.Stock)?.value;
        stockSource && dispatch(getPurchaseOrderTimeline({
          id,
          filters: purchaseOrderTimelineFilters,
          source: stockSource,
        }));
      } else {
        dispatch(setPurchaseOrderTimelineFilters({ ...purchaseOrderTimelineFilters, page: 1 }));
      }
      onCloseModal && onCloseModal();
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const partWithoutPrices = err.response?.data.data.partNamesWithoutPrice;
      const errKey = 'DeliverTo';
      const deliverErr = errors.find((el) => el.key === errKey);
      if (partWithoutPrices.length > 0) {
        for (let i = 0; i < partWithoutPrices.length; i++) {
          setInitFormValues && setInitFormValues();
          dispatch(setErrorMessage({
            message: `${partWithoutPrices[i]} does not have a price on selected purchase order date.`,
            toastId: `${partWithoutPrices[i]}-${i}`,
          }));
        }
      }
      if (deliverErr) {
        dispatch(setErrorMessage({ message: deliverErr.message }));
        setInitFormValues && setInitFormValues();
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderPartsList = createAsyncThunk<ResponseSingleResult<GetPurchaseOrderPartsResponse>, {
  purchaseOrderId: number,
  filters?: PurchaseOrderPartsListFiltersType,
  withoutRangeFilterUpdate?: boolean,
  setQtyMinMax?: GenericVoidFunctionType<NumericRangeType>
  setRange?: GenericVoidFunctionType<RangeTableFiltersType>
}>(
  'get/PurchaseOrderPartsList',
  async ({
    purchaseOrderId, filters,
    withoutRangeFilterUpdate, setQtyMinMax, setRange,
  }, { rejectWithValue, dispatch }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPurchaseOrderPartsList(purchaseOrderId, filters);
      if (!withoutRangeFilterUpdate) {
        const purchaseOrderPartsList: PurchaseOrderPartDto[] = response.data.data.purchaseOrderParts;
        const minP = purchaseOrderPartsList && purchaseOrderPartsList.length > 0
          ? purchaseOrderPartsList?.reduce((p, v) => (p.requestedQuantity < v.requestedQuantity ? p : v))
          : undefined;
        const maxP = purchaseOrderPartsList && purchaseOrderPartsList.length > 0
          ? purchaseOrderPartsList?.reduce((p, v) => (p.requestedQuantity > v.requestedQuantity ? p : v))
          : undefined;
        const min = minP?.requestedQuantity || 0;
        const max = maxP?.requestedQuantity || maxCommonDecimal;
        setQtyMinMax && setQtyMinMax({ min, max });
        setRange && setRange({ quantity: [min.toString(), max.toString()] });
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const putPurchaseOrderByIdThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, data: PutPurchaseOrderRestModel, closeModal?: VoidFunctionType }
>(
  'put/PurchaseOrderStatus',
  async ({
    id, data, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    try {
      const {
        purchaseOrder: { purchaseOrderTimelineFilters, purchaseOrderPartsListFilters },
        core: { purchaseOrderEventSources },
      } = getState() as RootState;
      const res = await stockApi.putPurchaseOrderById(id, data);
      dispatch(getPurchaseOrderById({ id }));
      const stockSource = purchaseOrderEventSources.find((el) => el.label === PurchaseOrderEventSourceEnum.Stock)?.value;
      stockSource && dispatch(getPurchaseOrderTimeline({
        id,
        filters: { ...purchaseOrderTimelineFilters, page: 1 },
        source: stockSource,
      }));
      dispatch(getPurchaseOrderPartsList({ purchaseOrderId: id, filters: purchaseOrderPartsListFilters }));
      closeModal && closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      closeModal && closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePurchaseOrderThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType, navigate: VoidFunctionType }
>(
  'delete/PurchaseOrder',
  async ({
    id, closeModal, navigate,
  }, { dispatch, rejectWithValue }) => {
    try {
      const res = await stockApi.deletePurchaseOrder(id);
      navigate();
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postPurchaseOrderPart = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  purchaseOrderId: number,
  values: PostPurchaseOrderPartDataType,
  closeModal: VoidFunctionType,
  setQtyMinMax?: GenericVoidFunctionType<NumericRangeType>,
  setRange?: GenericVoidFunctionType<RangeTableFiltersType>,
  successMessage?: string,
}>(
  'post/PurchaseOrderPart',
  async ({
    purchaseOrderId, values, closeModal, setQtyMinMax, setRange, successMessage,
  }, { rejectWithValue, dispatch }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.addPartToPurchaseOrder(purchaseOrderId, values);
      closeModal();
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      dispatch(setPurchaseOrderPartsListFilters(undefined));
      dispatch(getPurchaseOrderPartsList({
        purchaseOrderId, setQtyMinMax, setRange,
      }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      dispatch(setSuccessMessage({ message: successMessage || 'A part line was successfully added.' }));
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPurchaseOrderPartThunk = createAsyncThunk<ResponseSingleResult<{ warnings: GenericObject<string> }>, {
  purchaseOrderId: number,
  partId: number,
  values: PatchPurchaseOrderPartDataType,
  closeModal: VoidFunctionType,
  setInit?: VoidFunctionType,
  setQtyMinMax?: GenericVoidFunctionType<NumericRangeType>,
  setRange?: GenericVoidFunctionType<RangeTableFiltersType>,
  onPatchComplete?: VoidFunctionType,
  setIsLoading?: BooleanFunctionType,
}>(
  'patch/PurchaseOrderPart',
  async ({
    purchaseOrderId, partId, values, closeModal, setInit, setQtyMinMax, setRange, onPatchComplete, setIsLoading,
  }, { rejectWithValue, dispatch }) => {
    setIsLoading && setIsLoading(true);
    dispatch(setFetching(true));
    try {
      const response = await stockApi.patchPartToPurchaseOrder(purchaseOrderId, partId, values);
      const { warnings } = response.data.data;
      if (Object.keys(warnings).length > 0) {
        Object.values(warnings).forEach((warn, i) => dispatch(setErrorMessage({ message: warn, toastId: `${warn}-${i}` })));
        setInit && setInit();
      } else {
        closeModal();
      }
      if (onPatchComplete) {
        onPatchComplete();
      } else {
        dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
        dispatch(getPurchaseOrderPartsList({
          purchaseOrderId, setQtyMinMax, setRange,
        }));
        dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      }
      setIsLoading && setIsLoading(false);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      dispatch(setFetching(false));
      setIsLoading && setIsLoading(false);
      const error = err as AxiosErrorResponse;
      const AmountInputTypeUiError = error.response?.data.errors.find((el) => el.key === 'AmountInputTypeUiError');
      const receivedPartsUsedError = error.response?.data.errors.find((el) => el.key === 'ReceivedPartsUsed');
      const quantityError = error.response?.data.errors.find((el) => el.key === 'Quantity');
      if (AmountInputTypeUiError) {
        dispatch(setErrorMessage({
          message: AmountInputTypeUiError.message,
          toastId: Math.random(),
        }));
      }
      if (receivedPartsUsedError) {
        dispatch(setErrorMessage({
          message: receivedPartsUsedError.message,
          toastId: Math.random(),
        }));
      }
      if (quantityError) {
        dispatch(setErrorMessage({
          message: "Can't set Quantity value less than due in.",
          toastId: Math.random(),
        }));
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePurchaseOrderPartThunk = createAsyncThunk<ResponseSingleResult,
{
  purchaseOrderId: number,
  partId: number,
  closeModal: VoidFunctionType,
  setQtyMinMax?: GenericVoidFunctionType<NumericRangeType>,
  setRange?: GenericVoidFunctionType<RangeTableFiltersType>,
}>(
  'delete/PurchaseOrderPart',
  async ({
    purchaseOrderId, partId, closeModal, setRange, setQtyMinMax,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.deletePurchaseOrderPart(purchaseOrderId, partId);
      dispatch(setLoading(false));
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      dispatch(setPurchaseOrderPartsListFilters(undefined));
      dispatch(getPurchaseOrderPartsList({
        purchaseOrderId, setRange, setQtyMinMax,
      }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePurchaseOrderPartBulkThunk = createAsyncThunk<null,
{
  purchaseOrderId: number,
  purchaseOrderPartIds: number[],
  closeModal: VoidFunctionType,
  setQtyMinMax?: GenericVoidFunctionType<NumericRangeType>,
  setRange?: GenericVoidFunctionType<RangeTableFiltersType>,
}>(
  'delete/PurchaseOrderPartBulk',
  async ({
    purchaseOrderId, purchaseOrderPartIds, closeModal, setRange, setQtyMinMax,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { purchaseOrder: { purchaseOrderPartsListFilters } } = getState() as RootState;
      const { data: { data: { allItemIds } } } = await stockApi.fetchPurchaseOrderPartsList(purchaseOrderId, purchaseOrderPartsListFilters);
      const filteredIds = purchaseOrderPartIds.filter((id) => allItemIds.includes(id));
      if (filteredIds.length) {
        await stockApi.deletePurchaseOrderPartBulk(purchaseOrderId, filteredIds);
      }
      dispatch(setLoading(false));
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      dispatch(setPurchaseOrderPartsListFilters(undefined));
      dispatch(getPurchaseOrderPartsList({
        purchaseOrderId, setRange, setQtyMinMax,
      }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      closeModal();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      return null;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPurchaseOrderPartState = createAsyncThunk<ResponseSingleResult<{ items: DictionaryItem[] }>>(
  'get/PurchaseOrderPartState',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderPartState();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addFailedPartQuantityThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>,
{
  purchaseOrderId: number,
  partId: number,
  data: PartFailedQtyRestModel,
  cleanUp: VoidFunctionType,
}>(
  'post/FailedPartQuantity',
  async ({
    partId, purchaseOrderId, data, cleanUp,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.addFailedPartQuantity(purchaseOrderId, partId, data);
      dispatch(setFetching(false));
      cleanUp();
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      dispatch(getPurchaseOrderPartsList({ purchaseOrderId }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteFailedPartQuantityThunk = createAsyncThunk<ResponseSingleResult,
{
  purchaseOrderId: number,
  partId: number,
  failedQuantityId: number,
}>(
  'delete/FailedPartQuantity',
  async ({
    partId, purchaseOrderId, failedQuantityId,
  }, { dispatch, rejectWithValue }) => {
    try {
      const res = await stockApi.deleteFailedPartQtyLine(purchaseOrderId, partId, failedQuantityId);
      dispatch(getPurchaseOrderById({ id: purchaseOrderId }));
      dispatch(getPurchaseOrderPartsList({ purchaseOrderId }));
      dispatch(getPurchaseOrderPartSummaryThunk({ purchaseOrderId }));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generatePurchaseOrderPdfThunk = createAsyncThunk<DownloadUriResponse, {
  id: number,
}>(
  'get/PurchaseOrder/PDF',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.exportPurchaseOrderToPdf(id);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generatePurchaseOrderEmailThunk = createAsyncThunk<DownloadUriResponse, {
  id: number, setEmailHref: StringFunctionType,
}>(
  'get/PurchaseOrder/Email',
  async ({ id, setEmailHref }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.exportPurchaseOrderToPdf(id);
      const { downloadUri } = res.data.data;
      const { purchaseOrder: { purchaseOrderDetails } } = getState() as RootState;
      const purchaseOrderNumber = purchaseOrderDetails?.purchaseOrder.purchaseOrderNumber || '';
      const email = purchaseOrderDetails?.billingRequisite.email || '';
      const subject = `Purchase Order ${purchaseOrderNumber}`;
      const encodedLink = encodeURIComponent(downloadUri.replace(/ /g, '%20'));
      // eslint-disable-next-line max-len
      const emailBody = `Dear Sir/Madam,%0D%0A%0D%0APlease find below a link to purchase order ${purchaseOrderNumber}:%0D%0A%0D%0A${encodedLink}%0D%0A%0D%0AIf you require anything further, please get in touch.`;
      setEmailHref(`mailto:${email}?subject=${subject}&body=${emailBody}&content-type=text/plain`);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPurchaseOrderNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number } | null>, {
  content: string,
  purchaseOrderId: number,
  eventSource: number,
  setValue: VoidFunctionType,
}>(
  'post/PurchaseOrder/note',
  async ({
    content, purchaseOrderId, eventSource, setValue,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const {
        purchaseOrder: { purchaseOrderTimelineFilters, purchaseOrderDetails },
        core: { purchaseOrderEventSources },
      } = getState() as RootState;
      const res = await stockApi.createPurchaseOrderNote(content, purchaseOrderId, eventSource);
      dispatch(setLoading(false));
      setValue();

      const stockSource = purchaseOrderEventSources.find((el) => el.label === PurchaseOrderEventSourceEnum.Stock)?.value;
      if (purchaseOrderTimelineFilters.page === 1) {
        purchaseOrderDetails && stockSource && dispatch(getPurchaseOrderTimeline({
          id: purchaseOrderDetails.purchaseOrder.id,
          filters: purchaseOrderTimelineFilters,
          source: stockSource,
        }));
      } else {
        dispatch(setPurchaseOrderTimelineFilters({ ...purchaseOrderTimelineFilters, page: 1 }));
      }
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      setValue();
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editPurchaseOrderNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/PurchaseOrder/note',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.editPurchaseOrderNote(id, content);
      const {
        purchaseOrder: { purchaseOrderTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventPurchaseOrder[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = purchaseOrderTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setPurchaseOrderTimeline({ ...purchaseOrderTimeline, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePurchaseOrderNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  closeModal: VoidFunctionType,
}>(
  'delete/PurchaseOrder/note',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await stockApi.deletePurchaseOrderNote(id);
      const {
        purchaseOrder: { purchaseOrderTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setPurchaseOrderTimelineFilters({ ...purchaseOrderTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getDefaultPurchaseOrderDeliverAddress = createAsyncThunk<
ResponseSingleResult<{ defaultAddress: DefaultAddressDto }>,
{ setAddressFields: GenericVoidFunctionType<AddressFields | null> }>(
  'get/PurchaseOrder/DefaultAddress',
  async ({ setAddressFields }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchDefaultPurchaseOrderDeliverAddress();
      const values = res.data.data.defaultAddress;
      const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id, jobAddressLatitude, jobAddressLongitude, ...rest
      } = values;
      setAddressFields(rest);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
