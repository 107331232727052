import React from 'react';
import Input from '../../../../../../core/components/input/Input';
import { VoidFunctionType } from '../../../../../../core/types/coreTypes';
import RadioGroup from '../../../../../../core/components/radio-group/RadioGroup';
import Modal from '../../../../../../core/components/modal/Modal';
import { EmployeeActivityEventType } from '../../../../types/employeeViewTypes';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  employeeDepartmentsSelector,
  employeesSalaryTypesSelector,
} from '../../../../../../store/selectors/employeesSelector';
import { localDateFormatHandler } from '../../../../../../core/utils/utcDateFormatHandler';
import { DEFAULT_UI_DATE_FORMAT } from '../../../../../../core/utils/regex';

type EmploymentEventModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  item: EmployeeActivityEventType,
}

const EmploymentEventModal: React.FC<EmploymentEventModalProps> = ({ isVisible, onCancel, item }) => {
  const employeesSalaryTypes = useAppSelector(employeesSalaryTypesSelector);
  const employeeDepartments = useAppSelector(employeeDepartmentsSelector);
  return (
    <Modal
      visible={isVisible}
      onCancel={onCancel}
      destroyOnClose
      title="Employment record"
      width={700}
    >
      <div className="addEmployment">
        <Input
          label="Job title"
          value={item.jobTitle}
          className="addEmployment__field--wide"
          readOnly
        />
        <Input
          label="Department"
          value={employeeDepartments.find((el) => item.department === el.value)?.label}
          className="addEmployment__field--wide"
          readOnly
        />
        <Input
          label="Start date"
          value={localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.startDate)}
          readOnly
        />
        <Input
          label="End date"
          value={item.endDate ? localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, item.endDate) : 'Present'}
          readOnly
        />
        <RadioGroup
          value={item.salaryType || ''}
          options={employeesSalaryTypes}
          optionType="button"
          label="Salary type"
          className="addEmployment__field--wide"
          disabled
        />
        <Input
          label="Salary value"
          value={item.salaryValue ? item.salaryValue.toString() : '0.00'}
          readOnly
        />
        <Input
          label="Annual leave entitlement"
          value={item.annualLeaveEntitlement ? item.annualLeaveEntitlement.toString() : '0.00'}
          readOnly
        />
      </div>
    </Modal>
  );
};

export default EmploymentEventModal;
