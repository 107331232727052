import React, { useEffect, useState } from 'react';
import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getJobTimelineThunk, postJobTimelineNoteThunk } from '../../../store/thunks/jobs/jobThunks';
import {
  jobDetailsExpandedViewSelector,
  jobTimelineFiltersSelector,
  jobTimelineSelector,
} from '../../../store/selectors/jobsSelector';
import ActivityLabel from '../../../common/components/activity-events/ActivityLabel';
import JobEvent from '../../job-view-page/components/job-content/details/job-event-description/JobEvent';
import Timeline from '../../../core/components/timeline/Timeline';
import ActivityNoteInput from '../../../common/components/activity-events/ActivityNoteInput';
import { ExpandedViewMode } from '../enums/JobListEnums';
import { setJobTimelineFilters } from '../../../store/slices/jobsSlice';
import Pagination from '../../../core/components/pagination/Pagination';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';

type JobLineNotesProps = {
  jobId: number,
  mode: ExpandedViewMode,
  isJobCompleted: boolean,
}

const JobLineNotes: React.FC<JobLineNotesProps> = ({ jobId, mode, isJobCompleted }) => {
  const { assignees, previousAssignees } = useAppSelector(jobDetailsExpandedViewSelector);
  const dispatch = useAppDispatch();
  const filters = useAppSelector(jobTimelineFiltersSelector);
  const { items, totalCount } = useAppSelector(jobTimelineSelector);
  const [value, setValue] = useState('');
  const currentUser = useAppSelector(currentUserSelector);
  const isCurrentUserAssigned = [...assignees, ...previousAssignees].some(({ value }) => value === currentUser?.id);

  const addNote = () => {
    dispatch(postJobTimelineNoteThunk({
      jobId,
      content: value.trim(),
      setValue: () => setValue(''),
    }));
  };

  const timelineList = items.map((el) => {
    const disabledManageNotes = isJobCompleted || el.initiatorId !== currentUser?.id || !isCurrentUserAssigned;
    return {
      label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
      description: <JobEvent item={el} disabledManageNotes={disabledManageNotes} />,
    };
  });

  useEffect(() => {
    dispatch(getJobTimelineThunk({ jobId, filters }));
    // eslint-disable-next-line
  }, [mode, filters]);

  return (
    <div>
      <Pagination
        currentPage={filters.page}
        totalItems={totalCount}
        pageSize={filters.pageSize}
        onChange={(page) => {
          dispatch(setJobTimelineFilters({
            ...filters,
            page,
          }));
        }}
        showSizeChanger={false}
        showTotal={false}
        hideOnSinglePage
      />
      <div className="jobLineNotes">
        {totalCount
          ? <Timeline timelineList={timelineList} />
          : <EmptyContentList icon={faWaveSquare} text="No notes" size="sm" offsetTop={false} />}
      </div>
      {isCurrentUserAssigned && (
        <ActivityNoteInput
          value={value}
          setValue={setValue}
          addNote={addNote}
          disabledInput={isJobCompleted}
          disabledButton={isJobCompleted}
        />
      )}
    </div>
  );
};

export default JobLineNotes;
