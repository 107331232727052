import React, { useEffect, useMemo, useState } from 'react';
import classnames from 'classnames';
import './Layout.scss';
import { useLocation } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  collapsedSidebarSelector,
  isGlobalLoadingSelector,
} from '../../../store/selectors/coreSelectors';
import Spinner from '../spinner/Spinner';
import Header from '../header/Header';
import SideBar from '../sidebar/SideBar';
import { routesPath } from '../../enums/pathEnum';
import DrawerComponent from '../drawer/DrawerComponent';
import { setInAppNotificationsFilters } from '../../../store/slices/accountSlice';
import { inAppNotificationsFiltersSelector } from '../../../store/selectors/accountSelectors';
import ConfirmationModal from '../confirmation-modal/ConfirmationModal';
import {
  deleteAllNotificationThunk,
  getInAppNotificationsCountThunk,
  getInAppNotificationsThunk,
} from '../../../store/thunks/account/accountThunks';
import { inAppNotificationsOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import { inAppUnreadStatusSelector } from '../../../store/selectors/coreStatusesSelectors';
import InAppNotificationsWrapper from '../in-app-notifications/InAppNotificationsWrapper';

type LayoutProps = {
  children: JSX.Element | JSX.Element[];
  subHeader?: JSX.Element;
  scrollableMainArea?: boolean,
  mainClassName?: string,
};

const Layout: React.FC<LayoutProps> = ({
  children, subHeader, scrollableMainArea, mainClassName,
}) => {
  const dispatch = useAppDispatch();
  const collapsed = useAppSelector(collapsedSidebarSelector);
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const inAppNotificationsOrdering = useAppSelector(inAppNotificationsOrderingSelector);
  const inAppNotificationsFilters = useAppSelector(inAppNotificationsFiltersSelector);
  const inAppUnreadStatus = useAppSelector(inAppUnreadStatusSelector);
  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [isClearAllMessages, setIsClearAllMessages] = useState(false);

  const { pathname } = useLocation();
  const renderParts = useMemo(() => !(pathname === '/login')
        && !pathname.includes(`/${routesPath.ACCOUNT_FINALISATION}`)
        && !pathname.includes(`/${routesPath.RESET_PASSWORD}`), [pathname]);

  useEffect(() => {
    if (inAppNotificationsOrdering && inAppNotificationsOrdering.length > 0) {
      const defaultOrdering = { field: inAppNotificationsOrdering[0].value, isAsc: false };
      dispatch(setInAppNotificationsFilters({ ...inAppNotificationsFilters, order: defaultOrdering }));
    }
    // eslint-disable-next-line
  }, [inAppNotificationsOrdering]);

  useEffect(() => {
    if (isDrawerVisible) {
      if (inAppNotificationsFilters.order) {
        dispatch(getInAppNotificationsThunk({ filters: inAppNotificationsFilters }));
      }
    }
    // eslint-disable-next-line
  }, [inAppNotificationsFilters, isDrawerVisible]);

  // INITIAL CALL
  useEffect(() => {
    inAppUnreadStatus && dispatch(getInAppNotificationsCountThunk({ status: inAppUnreadStatus }));
    // eslint-disable-next-line
  }, [inAppUnreadStatus]);

  // INTERVAL UPDATE
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const ptr = setInterval(() => {
      inAppUnreadStatus && dispatch(getInAppNotificationsCountThunk({ status: inAppUnreadStatus }));
    }, 10000);
    return () => clearInterval(ptr);
    // eslint-disable-next-line
  }, []);

  const closeDrawerHandle = () => {
    setDrawerVisible(false);
    dispatch(setInAppNotificationsFilters({ ...inAppNotificationsFilters, page: 1 }));
  };
  return (
    <>
      <ConfirmationModal
        isVisible={isClearAllMessages}
        onCancel={() => {
          setIsClearAllMessages(false);
        }}
        confirmHandler={() => {
          dispatch(deleteAllNotificationThunk({
            closeModal: () => setIsClearAllMessages(false),
            closeDrawer: closeDrawerHandle,
          }));
        }}
      />
      <div className="layout">
        {renderParts && <SideBar />}
        {isGlobalLoading && <Spinner />}
        {renderParts && <Header openDrawer={() => setDrawerVisible(true)} />}
        {subHeader}
        <main className={classnames(mainClassName, 'layoutMain', {
          'layoutNotCollapsed': collapsed,
          'scrollableMainArea': scrollableMainArea,
          'layoutWithoutParts': !renderParts,
        })}
        >
          {children}
        </main>
        <DrawerComponent
          isVisible={isDrawerVisible}
          onClose={closeDrawerHandle}
          closable
          title="Notifications"
        >
          <InAppNotificationsWrapper setIsClearAllMessages={setIsClearAllMessages} onClose={closeDrawerHandle} />
        </DrawerComponent>
      </div>
    </>
  );
};

export default Layout;
