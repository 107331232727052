import React, { useEffect, useState } from 'react';
import moment from 'moment';
import './JobsCalendar.scss';
import classNames from 'classnames';
import { jobCalendarFiltersSelector, jobCalendarSelector } from '../../store/selectors/jobsSelector';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setJobCalendar, setJobCalendarFilters } from '../../store/slices/jobsSlice';
import { getJobCalendarThunk } from '../../store/thunks/jobs/jobCalendarThunks';
import JobCalendarLine from './components/JobCalendarLine';
import { getAccountPermissionRoles } from '../../store/thunks/coreThunk';
import { initDataList } from '../../core/types/coreTypes';
import JobCalendarPaginationCol from './components/JobCalendarPaginationCol';

const JobsCalendar: React.FC = () => {
  const dispatch = useAppDispatch();
  const jobCalendarFilters = useAppSelector(jobCalendarFiltersSelector);
  const { totalCount, items, pages } = useAppSelector(jobCalendarSelector);
  const { page, days } = jobCalendarFilters;
  const daysCount = items[0]?.workSchedules?.length || days;

  const onPageChange = (value: number) => {
    dispatch(setJobCalendarFilters({ ...jobCalendarFilters, page: page + value }));
  };

  useEffect(() => {
    dispatch(getJobCalendarThunk({ filters: jobCalendarFilters }));
    // eslint-disable-next-line
  }, [jobCalendarFilters]);

  useEffect(() => {
    dispatch(getAccountPermissionRoles({ accountType: undefined }));
    return () => {
      dispatch(setJobCalendar(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const [datesList, setDatesList] = useState<{ date: string; weekday: string; }[]>([]);
  const daysSeparators = Array(days - 1).fill(null);
  useEffect(() => {
    const dates = [];
    for (let i = 0; i < daysCount; i++) {
      if (i === 0) {
        const currentDayName = moment().format('ddd');
        dates.push({ date: 'Today', weekday: currentDayName });
      } else {
        const currentDay = moment().add(i, 'day');
        const currentDate = currentDay.format('DD MMM');
        const weekdayName = currentDay.format('ddd');
        dates.push({ date: currentDate, weekday: weekdayName });
      }
    }
    setDatesList(dates);
  }, [daysCount]);

  const styles = { '--days-count': daysCount } as React.CSSProperties;

  return (
    <div className="calendarWrap">
      <div className={classNames('calendarWrapper', { 'calendarWrapper--with-pagination': !!totalCount })} style={styles}>
        <div className="date sticky-left" />
        {datesList.map((day) => <div className="date">
          <b>{day.date}</b>
          <span className="date__weekday">{day.weekday}</span>
        </div>)}
        {items.map((el, i) => <JobCalendarLine key={i} item={el} />)}
        {!!totalCount && <JobCalendarPaginationCol onPageChange={onPageChange} totalPages={pages} currentPage={page} />}
        {daysSeparators.map((el, i) => <div key={i} className={`date-separator date-separator--${i + 1}`} />)}
      </div>
    </div>
  );
};

export default JobsCalendar;
