export const NOT_ALLOCATED_COLUMNS = ['locationComment', 'owner', 'collectionDate'];
export const NOT_READY_COLUMNS = ['owner', 'collectionDate'];
export const NOT_COLLECTED_COLUMNS = ['addedDate', 'purchaseOrderNumber'];

export const allocatePricelistColumns = [
  {
    title: 'Allocated quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Remaining',
    dataIndex: 'remaining',
  },
  {
    title: 'Cost price',
    dataIndex: 'costPrice',
  },
  {
    title: 'Selling price',
    dataIndex: 'sellingPrice',
  },
  {
    title: 'Added date',
    dataIndex: 'addedDate',
  },
  {
    title: 'Purchase order No',
    dataIndex: 'purchaseOrderNumber',
  },
];

export const distributeQtyColumns = [
  {
    title: 'Requested quantity',
    dataIndex: 'requestedQuantity',
  },
  {
    title: 'Confirmed quantity',
    dataIndex: 'confirmedQuantity',
  },
  {
    title: 'Cost price',
    dataIndex: 'costPrice',
  },
  {
    title: 'Selling price',
    dataIndex: 'sellingPrice',
  },
];
