import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import { useAppDispatch } from '../../store/hooks';
import Button from '../../core/components/button/Button';
import Pagination from '../../core/components/pagination/Pagination';
import { BIG_TABLES_PAGE_SIZES } from '../../core/utils/regex';
import { setNominalCodesFilters } from '../../store/slices/invoicingSlice';
import NominalCodesTable from './components/table/NominalCodesTable';
import './NominalCodesPage.scss';
import ConfirmationModal from '../../core/components/confirmation-modal/ConfirmationModal';
import { deleteNominalCodeThunk } from '../../store/thunks/invoicing/invoicingNominalCodesThunks';
import NominalCodeModal from './components/modal/NominalCodeModal';
import { useNominalCodesGetData } from './hook/useNominalCodesGetData';
import Restricted from '../../permissions-handling/Restricted';
import { PermissionEnum } from '../../core/enums/dictionariesEnums';

const NominalCodesPage: React.FC = () => {
  const dispatch = useAppDispatch();
  const {
    items,
    totalCount,
    filters,
  } = useNominalCodesGetData();

  const [createModal, setCreateModal] = useState(false);
  const [idToEdit, setIdToEdit] = useState<number | null>(null);
  const [idToDelete, setIdToDelete] = useState<number | null>(null);

  const deleteHandle = () => {
    idToDelete && dispatch(deleteNominalCodeThunk({
      id: idToDelete,
      closeModal: () => setIdToDelete(null),
    }));
  };

  return (
    <>
      <ConfirmationModal
        isVisible={!!idToDelete}
        onCancel={() => setIdToDelete(null)}
        confirmHandler={deleteHandle}
      />
      <NominalCodeModal
        visible={createModal || !!idToEdit}
        onClose={() => {
          setCreateModal(false);
          setIdToEdit(null);
        }}
        idToEdit={idToEdit}
      />
      <div className="actions-and-paging">
        <div className="actions-and-paging__actions">
          <Restricted to={PermissionEnum.InvoicingManageNominalCodes}>
            <Button
              label="Create new nominal code"
              icon={<FontAwesomeIcon icon={faCirclePlus} />}
              onClick={() => {
                setCreateModal(true);
              }}
              reversed
            />
          </Restricted>
        </div>
        {totalCount > 0 && <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          onChange={(page, pageSize) => {
            dispatch(setNominalCodesFilters({ ...filters, page, pageSize }));
          }}
          totalItems={totalCount}
          pageSizeOptions={BIG_TABLES_PAGE_SIZES}
          className="actions-and-paging__paging"
          narrow
        />}
      </div>
      <NominalCodesTable
        data={items}
        totalElements={totalCount}
        setIdToEdit={setIdToEdit}
        setIdToDelete={setIdToDelete}
      />
    </>
  );
};

export default NominalCodesPage;
