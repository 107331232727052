import React, { useMemo } from 'react';
import './SummarySection.scss';
import classNames from 'classnames';
import {
  quoteVehicleSummarySelector,
} from '../../../../../../store/selectors/enquiriesSelector';
import { useAppSelector } from '../../../../../../store/hooks';

type SummarySectionProps = {
  currentVehicleTabName?: string | null,
}

const SummarySection: React.FC<SummarySectionProps> = ({ currentVehicleTabName }) => {
  const quoteVehicleSummary = useAppSelector(quoteVehicleSummarySelector);

  const leftPartData = useMemo(() => (quoteVehicleSummary ? [
    {
      value: quoteVehicleSummary?.quoteVehicleSummary.grossVehicleWeightKg !== null
        ? quoteVehicleSummary?.quoteVehicleSummary.grossVehicleWeightKg.toFixed(2)
        : 0,
      label: 'Gross vehicle weight',
    },
    {
      value: quoteVehicleSummary?.quoteVehicleSummary.maximumPayloadKg !== null
        ? quoteVehicleSummary?.quoteVehicleSummary.maximumPayloadKg.toFixed(2)
        : 0,
      label: 'Maximum payload',
    },
    { value: quoteVehicleSummary?.quoteVehicleSummary.conversionWeightKg, label: 'Conversion weight' },
    {
      value: quoteVehicleSummary?.quoteVehicleSummary.remainingPayloadKg !== null
        ? quoteVehicleSummary?.quoteVehicleSummary.remainingPayloadKg.toFixed(2)
        : 0,
      label: 'Remaining payload',
    },
  ] : []), [quoteVehicleSummary]);

  const rightPartData = useMemo(() => (quoteVehicleSummary ? [
    { value: quoteVehicleSummary?.quoteVehicleSummary.netString, label: 'Net' },
    { value: quoteVehicleSummary?.quoteVehicleSummary.discountString, label: 'Discount' },
    { value: quoteVehicleSummary?.quoteVehicleSummary.vatString, label: 'VAT' },
    { value: quoteVehicleSummary?.quoteVehicleSummary.grossString, label: 'Gross' },
  ] : []), [quoteVehicleSummary]);

  const totalSummary = useMemo(() => (quoteVehicleSummary ? [
    { value: quoteVehicleSummary.totalSummary.netString, label: 'Net' },
    { value: quoteVehicleSummary.totalSummary.discountString, label: 'Discount' },
    { value: quoteVehicleSummary.totalSummary.vatString, label: 'VAT' },
    { value: quoteVehicleSummary.totalSummary.grossString, label: 'Gross' },
  ] : []), [quoteVehicleSummary]);

  return (
    <div className="summarySection">
      <div className="summarySection__item">
        <div className="summarySection__item-title">
          Summary (
          {currentVehicleTabName}
          )
        </div>
        <div className="summarySection__item-linesWrap">
          <div className="vehSum">
            {leftPartData.map((d, i) => <div
              key={i}
              className="sumLine"
            >
              <span className="sumLabel">{d.label}</span>
              <span className="sumValue">
                {d.value}
                {' '}
                kg
              </span>
            </div>)}
          </div>
          <div className="vehSum">
            {rightPartData.map((d, i) => <div
              key={i}
              className={classNames('sumLine', { 'sumLine--bold': d.label === 'Gross' })}
            >
              <span className="sumLabel">{d.label}</span>
              <span className="sumValue">
                {d.value ? '£ ' : ''}
                {d.value || '-'}
              </span>
            </div>)}
          </div>
        </div>
      </div>
      <div className="summarySection__item">
        <div className="summarySection__item-title">Summary (all vehicles)</div>
        <div className="summarySection__item-lines">
          {totalSummary.map((d, i) => <div
            key={i}
            className={classNames('sumLine', { 'sumLine--bold': d.label === 'Gross' })}
          >
            <span className="sumLabel">{d.label}</span>
            <span className="sumValue">{`£ ${d.value}`}</span>
          </div>)}
        </div>
      </div>
    </div>
  );
};

export default SummarySection;
