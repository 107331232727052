import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { setUserTimeline, setUserTimelineFilters } from '../../../../../store/slices/settingsSlice';
import Pagination from '../../../../../core/components/pagination/Pagination';
import Spinner from '../../../../../core/components/spinner/Spinner';
import Timeline from '../../../../../core/components/timeline/Timeline';
import ActivityLabel from '../../../../../common/components/activity-events/ActivityLabel';
import UserEvent from '../user-event-description/UserEvent';
import { useCancelRequest } from '../../../../../core/hooks/useCancelRequest';
import { useUserTimelineEventTypes } from '../../../hooks/useUserTimelineEventTypes';
import { getUserTimelineThunk } from '../../../../../store/thunks/settings/userViewPageThunks';
import { initExtendedTimelineFilters } from '../../../../../common/types/commonTypes';
import { initDataList } from '../../../../../core/types/coreTypes';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { userTimelineFiltersSelector, userTimelineSelector } from '../../../../../store/selectors/settingsSelectors';

const UserTimeline: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { items, totalCount } = useAppSelector(userTimelineSelector);
  const filters = useAppSelector(userTimelineFiltersSelector);

  const [isLoading, setIsLoading] = useState(true);

  const { controller, cancelRequest } = useCancelRequest();
  useUserTimelineEventTypes();

  useEffect(() => {
    cancelRequest();
    id && dispatch(getUserTimelineThunk({
      userId: +id,
      filters,
      setIsLoading,
      signal: controller.current?.signal,
    }));
    // eslint-disable-next-line
  }, [filters]);

  useEffect(() => {
    return () => {
      dispatch(setUserTimelineFilters(initExtendedTimelineFilters));
      dispatch(setUserTimeline(initDataList));
    };
    // eslint-disable-next-line
  }, []);

  const timelineList = items.map((el) => ({
    label: <ActivityLabel time={el.dateTime} initiator={el.initiator} />,
    description: <UserEvent item={el} />,
  }));

  return (
    <>
      <Pagination
        currentPage={filters.page}
        pageSize={filters.pageSize}
        onChange={(page) => {
          dispatch(setUserTimelineFilters({ ...filters, page }));
        }}
        totalItems={totalCount}
        showSizeChanger={false}
        showTotal={false}
      />
      {isLoading
        ? <Spinner global={false} />
        : <Timeline timelineList={timelineList} className="detailsWrap__activity-log" />}
    </>
  );
};

export default UserTimeline;
