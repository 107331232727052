import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';

export const PurchaseDescriptionSchema = { maxLength: { value: 2000, message: maxLengthMessageHandle(2000) } };

export type CreatePurchaseOrderFields = {
  supplierId?: number,
  description: string | null,
  assigneeId?: number,
};

export type CreatePurchase = Pick<CreatePurchaseOrderFields, 'description'>

export const createPurchaseOrderDefaults: CreatePurchaseOrderFields = {
  supplierId: undefined,
  description: '',
  assigneeId: undefined,
};
