import { combineReducers, configureStore, PayloadAction } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import coreReducer from './slices/coreSlice';
import ordersReducer from './slices/ordersSlice';
import enquiriesReducer from './slices/enquiriesSlice';
import jobsReducer from './slices/jobsSlice';
import customersReducer from './slices/customersSlice';
import settingsReducer from './slices/settingsSlice';
import accountReducer from './slices/accountSlice';
import pricelistReducer from './slices/pricelistSlice';
import stockReducer from './slices/stockSlice';
import stockPartRequestReducer from './slices/stockPartRequestSlice';
import purchaseOrderReducer from './slices/purchaseOrderSlice';
import supplierReducer from './slices/supplierSlice';
import rectificationReducer from './slices/rectificationsSlice';
import employeesReducer from './slices/employeesSlice';
import invoicingReducer from './slices/invoicingSlice';
import sharedReducer from './slices/sharedSlice';
import formsReducer from './slices/formsSlice';
import partsKitReducer from './slices/partsKitSlice';
import vehiclesReducer from './slices/vehiclesSlice';
import reportsReducer from './slices/reportsSlice';
import { CustomAny } from '../core/types/coreTypes';
import coreEventTypesReducer from './slices/coreEventTypesSlice';
import coreOrderingReducer from './slices/coreOrderingSlice';
import coreStatusesReducer from './slices/coreStatusesSlice';
import { configureInterceptors } from '../api/axiosConfig';

const appReducer = combineReducers({
  account: accountReducer,
  core: coreReducer,
  coreEventTypes: coreEventTypesReducer,
  coreOrdering: coreOrderingReducer,
  coreStatuses: coreStatusesReducer,
  enquiries: enquiriesReducer,
  orders: ordersReducer,
  jobs: jobsReducer,
  customers: customersReducer,
  settings: settingsReducer,
  pricelist: pricelistReducer,
  stock: stockReducer,
  stockPartRequest: stockPartRequestReducer,
  purchaseOrder: purchaseOrderReducer,
  partsKit: partsKitReducer,
  supplier: supplierReducer,
  rectifications: rectificationReducer,
  employees: employeesReducer,
  invoicing: invoicingReducer,
  forms: formsReducer,
  reports: reportsReducer,
  shared: sharedReducer,
  vehicles: vehiclesReducer,
});

const reducerProxy = (state: CustomAny, action: PayloadAction<boolean>) => {
  if (action.type === 'core/setIsAuth' && !action.payload) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export const store = configureStore({
  reducer: reducerProxy,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

configureInterceptors(store.dispatch, store.getState);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;

export function getStoreWithState(preloadedState?: RootState) {
  return configureStore({ reducer: reducerProxy, preloadedState });
}
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
