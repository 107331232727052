import React from 'react';
import {
  Control, Controller, ControllerRenderProps, FieldErrors, UseFormClearErrors, UseFormSetValue,
} from 'react-hook-form';
import classNames from 'classnames';
import { VehicleCreateFieldEnum } from '../../../enquiry/enquiry-detailed/enums/QuoteEnums';
import { maxWeightWhole } from '../../../core/utils/regex';
import { VehicleDto } from '../../../core/types/coreTypes';
import { vehicleFieldType } from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import Select from '../../../core/components/select/Select';
import Input from '../../../core/components/input/Input';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import { useVehicleDictionaries } from '../../hooks/useVehicleDictionaries';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import {
  ChassisNumberFieldValidationSchema,
  CreateVehicleFields,
  RegNoFieldValidationSchema,
} from '../../../vehicles/create-vehicle/types/createVehicleFormSchema';

type CommonVehicleFieldsProps = {
  control: Control<CreateVehicleFields>,
  errors: FieldErrors<CreateVehicleFields>,
  disabled?: boolean,
  setValue: UseFormSetValue<CreateVehicleFields>,
  clearErrors: UseFormClearErrors<CreateVehicleFields>,
  isChassisRequired?: boolean,
  offsetTop?: boolean,
  areWeightFieldsRequired?: boolean,
  editHandler?: (v: Partial<VehicleDto>, key: string) => void,
  clearOtherErrorsFn?: (key: string) => void,
  disabledToEditRegNoAndChassisNo?: boolean,
}

const CommonVehicleFields: React.FC<CommonVehicleFieldsProps> = ({
  control, errors, disabled, setValue, isChassisRequired, offsetTop,
  areWeightFieldsRequired, editHandler, clearOtherErrorsFn, clearErrors, disabledToEditRegNoAndChassisNo,
}) => {
  const { vehicleSlidingDoorsQuantities } = useVehicleDictionaries();
  const vehicleFields = [
    {
      name: 'slidingDoorsQuantity',
      rules: RequiredFieldSchema,
      label: 'Number of sliding doors',
      component: VehicleCreateFieldEnum.SELECT,
      options: vehicleSlidingDoorsQuantities,
      disabled,
      gridSize: 2,
    },
    {
      name: 'grossVehicleWeightKg',
      label: 'Gross vehicle weight, kg',
      rules: { required: areWeightFieldsRequired && ErrorsEnum.REQUIRED },
      component: VehicleCreateFieldEnum.INPUT_NUMBER,
      disabled,
      gridSize: 2,
    },
    {
      name: 'maxPayload',
      rules: { required: areWeightFieldsRequired && ErrorsEnum.REQUIRED },
      label: 'Maximum payload',
      component: VehicleCreateFieldEnum.INPUT_NUMBER,
      disabled,
      gridSize: 2,
    },
    {
      name: 'chassisNumber',
      rules: {
        required: isChassisRequired && ErrorsEnum.REQUIRED,
        ...ChassisNumberFieldValidationSchema,
      },
      label: 'Chassis No',
      component: VehicleCreateFieldEnum.INPUT,
      uppercased: true,
      disabled: disabledToEditRegNoAndChassisNo ?? disabled,
      gridSize: 3,
    },
    {
      name: 'regNumber',
      rules: RegNoFieldValidationSchema,
      label: 'Reg No',
      component: VehicleCreateFieldEnum.INPUT,
      uppercased: true,
      disabled: disabledToEditRegNoAndChassisNo ?? disabled,
      gridSize: 3,
    },
  ];
  const componentRender = (field: ControllerRenderProps<CreateVehicleFields, keyof CreateVehicleFields>, el: vehicleFieldType) => {
    if (el.component === VehicleCreateFieldEnum.SELECT) {
      return <Select
        value={field.value as (number | undefined)}
        onChange={(v) => {
          field.onChange(v);
          clearOtherErrorsFn && clearOtherErrorsFn(el.name);
          editHandler && !errors[el.name as keyof CreateVehicleFields] && editHandler({ [el.name]: v }, el.name);
        }}
        options={el.options || []}
        error={errors[el.name as keyof CreateVehicleFields]?.message}
        label={el.label}
        disabled={el.disabled}
        parentRender
        className={`commonVehicleFields__field--${el.gridSize}`}
      />;
    }

    if (el.component === VehicleCreateFieldEnum.INPUT) {
      return <Input
        label={el.label}
        value={field.value}
        onChange={(e) => {
          if (el.uppercased) {
            field.onChange(e.target.value.toUpperCase());
          } else {
            field.onChange(e.target.value);
          }
          clearOtherErrorsFn && clearOtherErrorsFn(el.name);
        }}
        onBlur={() => {
          editHandler
          && !errors[el.name as keyof CreateVehicleFields]
          && editHandler({ [el.name]: (field.value as string | null)?.trim() }, el.name);
        }}
        error={errors[el.name as keyof CreateVehicleFields]?.message}
        alignBottom
        disabled={el.disabled}
        className={`commonVehicleFields__field--${el.gridSize}`}
      />;
    }

    return <RegularInputNumber
      label={el.label}
      value={field.value as string || ''}
      max={maxWeightWhole}
      onChange={(e) => {
        const v = e.target.value;
        field.onChange(v);
        clearOtherErrorsFn && clearOtherErrorsFn(el.name);
      }}
      onBlur={(event) => {
        const v = event.target.value;
        if (!v) {
          setValue(el.name as keyof CreateVehicleFields, '0');
          clearErrors && clearErrors(el.name as keyof CreateVehicleFields);
          editHandler && editHandler({ [el.name]: '0' }, el.name);
        } else {
          editHandler && editHandler({ [el.name]: v }, el.name);
        }
      }}
      error={errors[el.name as keyof CreateVehicleFields]?.message}
      disabled={el.disabled}
      className={`commonVehicleFields__field--${el.gridSize}`}
    />;
  };
  return (
    <div className={classNames('commonVehicleFields', { 'commonVehicleFields--offset-top': offsetTop })}>
      {vehicleFields.map((el, i) => <Controller
        key={i}
        name={el.name as keyof CreateVehicleFields}
        control={control}
        rules={el.rules}
        render={({ field }) => componentRender(field, el)}
      />)}
    </div>
  );
};

export default CommonVehicleFields;
