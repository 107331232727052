import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import { faAddressCard } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import usePermission from '../../../../../permissions-handling/permissionHook';
import { PermissionEnum } from '../../../../../core/enums/dictionariesEnums';
import { AddressFields, initAddressFields } from '../../../../../common/types/commonTypes';
import {
  supplierContactsFilterSelector,
  supplierContactsSelector,
} from '../../../../../store/selectors/supplierSelector';
import {
  deleteSupplierContactThunk,
  getSupplierContactsList,
} from '../../../../../store/thunks/suppliers/supplierContactsThunks';
import { setSupplierContactsFilters } from '../../../../../store/slices/supplierSlice';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import AddressModal from '../../../../../common/components/address/AddressModal';
import Button from '../../../../../core/components/button/Button';
import EmptyContentList from '../../../../../core/components/empty-content-list/EmptyContentList';
import SupplierContactLine from './SupplierContactLine';
import { SupplierContactDto } from '../../../types/supplierViewPageTypes';
import SupplierContactModal from './SupplierContactModal';
import { infiniteScrollInitPaging } from '../../../../../core/types/coreTypes';

const SupplierContacts:React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const contactList = useAppSelector(supplierContactsSelector);
  const filters = useAppSelector(supplierContactsFilterSelector);
  const allowedCreate = usePermission(PermissionEnum.SupplierEditFields);

  const [isContactModal, setContactModal] = useState<boolean>(false);
  const [isDelete, setDelete] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<SupplierContactDto>();
  const [isAddressModal, setAddressModal] = useState(false);
  const [addressFields, setAddressFields] = useState<AddressFields>(initAddressFields);

  useEffect(() => {
    id && dispatch(getSupplierContactsList({ supplierId: +id, filters: infiniteScrollInitPaging }));
    // eslint-disable-next-line
  }, [id]);

  const loadMore = () => {
    const newValues = { ...filters, page: filters.page + 1 };
    dispatch(setSupplierContactsFilters(newValues));
    id && dispatch(getSupplierContactsList({ supplierId: +id, filters: newValues }));
  };

  useEffect(() => {
    if (selectedContact) {
      setAddressFields({
        addressLine1: selectedContact.addressLine1,
        addressLine2: selectedContact.addressLine2,
        cityTown: selectedContact.cityTown,
        region: selectedContact.region,
        postalCode: selectedContact.postalCode,
      });
    } else {
      setAddressFields(initAddressFields);
    }
  }, [selectedContact]);

  useEffect(() => {
    return () => {
      dispatch(setSupplierContactsFilters(infiniteScrollInitPaging));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <ConfirmationModal
        isVisible={isDelete}
        onCancel={() => {
          setDelete(false);
          setSelectedContact(undefined);
        }}
        confirmHandler={() => selectedContact && id && dispatch(deleteSupplierContactThunk({
          supplierId: selectedContact.supplierId || +id,
          contactId: selectedContact.id,
          closeModal: () => {
            setDelete(false);
            setSelectedContact(undefined);
          },
        }))}
      />
      <SupplierContactModal
        isVisible={isContactModal}
        onClose={() => setContactModal(false)}
        addressFields={addressFields}
        setAddress={setAddressModal}
        setAddressFields={setAddressFields}
        contact={selectedContact}
        setSelectedContact={(contact: SupplierContactDto | undefined) => setSelectedContact(contact)}
      />
      <AddressModal
        isVisible={isAddressModal}
        onCancel={() => {
          setAddressModal(false);
        }}
        onSubmit={(values) => {
          setAddressFields(values);
          setAddressModal(false);
        }}
        addressFields={addressFields}
        isExtendedFields={false}
      />
      <section className="contacts">
        {allowedCreate && <Button
          label="Add contact"
          onClick={() => setContactModal(true)}
          icon={<FontAwesomeIcon icon={faCirclePlus} />}
          htmlType="button"
          designType="dark"
          reversed
        />}
        <div className="contacts__wrapper">
          {contactList.items && contactList.items?.length > 0
            ? <InfiniteScroll
              dataLength={contactList.items.length}
              next={loadMore}
              hasMore={filters.page < contactList.pages}
              loader={<span className="contacts__loader">Loading...</span>}
            >
              <ul className="lines-list">
                {contactList.items.map((line) => (
                  <li
                    className="lines-list__item"
                    key={line.id}
                  >
                    <SupplierContactLine
                      line={line}
                      setDelete={() => setDelete(true)}
                      setEdit={() => setContactModal(true)}
                      setSelectedContact={(contact: SupplierContactDto) => setSelectedContact(contact)}
                    />
                  </li>))}
              </ul>
            </InfiniteScroll>
            : <EmptyContentList icon={faAddressCard} text="Please add a contact to this supplier" />}
        </div>
      </section>
    </>
  );
};

export default SupplierContacts;
