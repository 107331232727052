import React from 'react';
import './PricelistSkeletonItem.scss';

const PricelistSkeletonItem: React.FC = () => {
  return (
    <div className="pricelistSkeletonItem skeleton-item">
      <div className="pricelistSkeletonItem__info" />
      <div className="pricelistSkeletonItem__tag skeleton-line" />
      <div className="pricelistSkeletonItem__areas" />
      <div className="pricelistSkeletonItem__count" />
    </div>
  );
};

export default PricelistSkeletonItem;
