import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormSetError } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse, ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { GetSupplierResponse } from '../../../supplier/dashboard/types/SuppliersTypes';
import {
  setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { SuppliersAPI } from '../../../api/suppliersApi';
import { routesPath } from '../../../core/enums/pathEnum';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { setSupplierActivityLogFilters, setSupplierTimeline } from '../../slices/supplierSlice';
import { PleaseRefreshThePageError, SimultaneouslyStatusErrorEnum } from '../../../core/enums/errorsEnum';
import { SupplierEventDtoBase } from '../../../supplier/supplier-view-page/types/supplierViewPageTypes';
import { RootState } from '../../store';
import { duplicateServerErrorsHelper } from '../../../core/utils/checkIsDuplicateError';
import { PatchSupplierRestModel, SupplierFields } from '../../../supplier/create-supplier/utils/SupplierSchema';
import { UserEventSupplier } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';

export const getSupplierTimelineThunk = createAsyncThunk<
ResponseResult<SupplierEventDtoBase[]>, { supplierId: number, filters: ExtendedTimelineFilters }
>(
  'get/SupplierTimeline',
  async ({ supplierId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const result = await SuppliersAPI.fetchSupplierTimeline(supplierId, filters);
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getSupplierByIdThunk = createAsyncThunk<GetSupplierResponse,
{ id: number }
>(
  'get/SupplierById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.fetchSupplierById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSupplierThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  navigate: NavigateFunction,
}
>(
  'delete/Supplier',
  async ({ id, navigate }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await SuppliersAPI.deleteSupplier(id);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      navigate(`/${routesPath.SUPPLIERS}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const status = error.response?.data.status || '';
      dispatch(setLoading(false));
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchSupplierThunk = createAsyncThunk<ResponseSingleResult,
{
  id: number,
  data: PatchSupplierRestModel,
  onClose?: VoidFunctionType,
  setError?: UseFormSetError<SupplierFields>,
}
>(
  'patch/Supplier',
  async ({
    id, data, onClose, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier: { supplierActivityLogFilters } } = getState() as RootState;
      const response = await SuppliersAPI.patchSupplier(id, data);
      dispatch(getSupplierByIdThunk({ id }));
      if (supplierActivityLogFilters.page === 1) {
        dispatch(getSupplierTimelineThunk({
          supplierId: id,
          filters: supplierActivityLogFilters,
        }));
      } else {
        dispatch(setSupplierActivityLogFilters({ ...supplierActivityLogFilters, page: 1 }));
      }
      dispatch(setLoading(false));
      onClose && onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      const status = error.response?.data.status || '';
      if (status === SimultaneouslyStatusErrorEnum.AlreadyModified) {
        dispatch(setErrorMessage({ message: PleaseRefreshThePageError, toastId: Math.random() }));
      } else {
        duplicateServerErrorsHelper(errors, setError, onClose);
      }
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeSupplierStatusThunk = createAsyncThunk<
ResponseSingleResult | null,
{
  id: number,
  status: number,
}
>(
  'put/SupplierStatus',
  async ({ id, status }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier } = getState() as RootState;
      const { supplierActivityLogFilters } = supplier;
      const response = await SuppliersAPI.putSupplierStatus(id, status);
      dispatch(getSupplierByIdThunk({ id }));
      if (supplierActivityLogFilters.page === 1) {
        dispatch(getSupplierTimelineThunk({
          supplierId: id,
          filters: supplierActivityLogFilters,
        }));
      } else {
        dispatch(setSupplierActivityLogFilters({ ...supplierActivityLogFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: 'Supplier status was successfully changed' }));
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addSupplierTimelineNoteThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number }>, {
  supplierId: number, content: string, setValue: VoidFunctionType,
}
>(
  'post/SupplierTimelineNote',
  async ({
    supplierId, content, setValue,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { supplier: { supplierActivityLogFilters } } = getState() as RootState;
      setValue();
      const result = await SuppliersAPI.postSupplierNote(supplierId, content);
      if (supplierActivityLogFilters.page === 1) {
        dispatch(getSupplierTimelineThunk({
          supplierId,
          filters: supplierActivityLogFilters,
        }));
      } else {
        dispatch(setSupplierActivityLogFilters({ ...supplierActivityLogFilters, page: 1 }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      dispatch(setLoading(false));
      return result.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editSupplierTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/SupplierNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await SuppliersAPI.editSupplierNote(id, content);
      const {
        supplier: { supplierTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventSupplier[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = supplierTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setSupplierTimeline({ ...supplierTimeline, items }));
      }
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteSupplierTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType, }>(
  'delete/SupplierNote',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await SuppliersAPI.deleteSupplierNote(id);
      const {
        supplier: { supplierActivityLogFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setSupplierActivityLogFilters({ ...supplierActivityLogFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
