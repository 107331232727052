import React, { useState } from 'react';
import classNames from 'classnames';
import './Carousel.scss';

type CarouselProps = {
  slidesContent: React.ReactNode[];
  className?: string;
}

const Carousel: React.FC<CarouselProps> = ({ slidesContent, className }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handlePrevSlide = () => {
    const slideToShow = currentSlide === 0 ? slidesContent.length - 1 : currentSlide - 1;
    setCurrentSlide(slideToShow);
  };
  const handleNextSlide = () => {
    const slideToShow = currentSlide === slidesContent.length - 1 ? 0 : currentSlide + 1;
    setCurrentSlide(slideToShow);
  };

  return (
    <section className={classNames(className, 'carousel')}>
      <ol className="carousel__viewport">
        <li className="carouselSlide">
          {slidesContent.map((el, i) => <span
            key={i}
            className={classNames('carouselSlide-text', {
              'activeContent': currentSlide === i,
            })}
          >
            {el}
          </span>)}
          <div className="carouselSnapper">
            <div
              onClick={handlePrevSlide}
              className="carousel__prev"
              tabIndex={0}
              role="button"
            >
              Go to previous slide
            </div>
            <div
              onClick={handleNextSlide}
              className="carousel__next"
              tabIndex={0}
              role="button"
            >
              Go to next slide
            </div>
          </div>
        </li>
      </ol>
    </section>
  );
};

export default Carousel;
