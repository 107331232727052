import React from 'react';
import { VehicleViewModeParamsEnum } from '../../enums/VehicleViewEnums';
import VehicleAttachments from './vehicle-attachments/VehicleAttachments';
import VehicleDetailsAndActivity from './vehicle-details-and-activity-section/VehicleDetailsAndActivity';
import VehicleInspection from './vehicle-inspection/VehicleInspection';
import VehicleLinks from './vehicle-links/VehicleLinks';

type VehicleContentProps = {
  mode: string | null
}
const VehicleContent: React.FC<VehicleContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case VehicleViewModeParamsEnum.VEHICLE_DETAILS:
        return <VehicleDetailsAndActivity />;
      case VehicleViewModeParamsEnum.VEHICLE_INSPECTION:
        return <VehicleInspection />;
      case VehicleViewModeParamsEnum.VEHICLE_ATTACHMENTS:
        return <VehicleAttachments />;
      case VehicleViewModeParamsEnum.VEHICLE_LINKS:
        return <VehicleLinks />;
      default: return null;
    }
  };

  return <>{renderContent()}</>;
};

export default VehicleContent;
