import React from 'react';
import Tr from '../../../../../../core/components/table-components/Tr';
import { ICustomColumn } from '../../../../../../common/types/commonTypes';
import ThWithControls from '../../../../../../core/components/table-components/ThWithControls';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { enquiryTimelineQuoteLinesFiltersSelector } from '../../../../../../store/selectors/enquiriesSelector';
import { setEnquiryTimelineQuoteLinesFilters } from '../../../../../../store/slices/enquiriesSlice';

type QuoteLinesGroupColumnsProps = {
  columns: ICustomColumn[];
  totalElements: number,
}

const QuoteLinesGroupColumns: React.FC<QuoteLinesGroupColumnsProps> = ({ columns, totalElements }) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(enquiryTimelineQuoteLinesFiltersSelector);

  const sortHandle = (field: number) => {
    const newFilters = field === filters.order?.field
      ? { ...filters, order: { field, isAsc: !filters.order.isAsc } }
      : { ...filters, order: { field, isAsc: true } };
    dispatch(setEnquiryTimelineQuoteLinesFilters(newFilters));
  };

  return (
    <Tr>
      {columns.map((col) => <ThWithControls
        key={col.dataIndex}
        col={col}
        sortHandle={sortHandle}
        tableFilters={filters}
        totalElements={totalElements}
        className={`quoteLinesGroupTh--${col.dataIndex}`}
      />)}
    </Tr>
  );
};

export default QuoteLinesGroupColumns;
