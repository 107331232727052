import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  CoreAttachListElType, CoreAttachmentsFilters,
  GetAttachmentFileResponse, GetAttachmentsResponse,
  ResponseResult, ResponseSingleResult, VoidFunctionType,
} from '../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { vehiclesAPI } from '../../../api/vehiclesApi';
import {
  GetVehicleCheckResponse,
  VehicleCheckDto, VehicleChecksFilters,
} from '../../../vehicles/vehicle-view-page/components/vehicle-content/vehicle-inspection/types/VehicleInspectionTypes';
import { RootState } from '../../store';
import { setVehicleChecksFilters } from '../../slices/vehiclesSlice';
import { SaveVehicleCheckDto } from '../../../vehicles/vehicle-view-page/types/VehicleViewTypes';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';

export const getVehicleChecksThunk = createAsyncThunk<ResponseResult<VehicleCheckDto[]>, {
  id: number, filters: VehicleChecksFilters,
}>(
  'get/VehicleChecks',
  async ({ id, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleChecks(id, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleCheckByIdThunk = createAsyncThunk<ResponseSingleResult<GetVehicleCheckResponse>, {
  vehicleId: number, vehicleCheckId: number,
}>(
  'get/VehicleCheckById',
  async ({
    vehicleId, vehicleCheckId,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.fetchVehicleCheckById(vehicleId, vehicleCheckId);
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createVehicleCheckThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  vehicleId: number,
  vehicleCheckData: SaveVehicleCheckDto,
  attachmentsList: CoreAttachListElType[],
  navigateToInspectionTab: VoidFunctionType,
}>(
  'post/VehicleCheckById',
  async ({
    vehicleId, vehicleCheckData, attachmentsList, navigateToInspectionTab,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.createVehicleCheck(vehicleId, vehicleCheckData);
      const { createdId } = response.data.data;
      // ATTACHMENTS UPLOAD
      if (attachmentsList.length > 0 && createdId) {
        const uploadUriArr = attachmentsList.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));

        const resUploadUri = await vehiclesAPI.vehicleCheckAttachmentUploadUri(vehicleId, createdId, uploadUriArr);
        const attachments = await uploadAttachmentsAsyncHandle(
          attachmentsList,
          '',
          resUploadUri,
          dispatch,
        );

        await vehiclesAPI.vehicleCheckUploadAttachments(vehicleId, createdId, attachments);
      }
      dispatch(setFetching(false));
      dispatch(setSuccessMessage(({ message: 'Vehicle check was successfully saved.', toastId: Math.random() })));
      navigateToInspectionTab();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteVehicleCheckThunk = createAsyncThunk<ResponseSingleResult, {
  vehicleId: number, vehicleCheckId: number, closeModal: VoidFunctionType,
}>(
  'delete/VehicleCheck',
  async ({
    vehicleId, vehicleCheckId, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await vehiclesAPI.deleteVehicleCheck(vehicleId, vehicleCheckId);
      const { vehicles: { vehicleChecksFilters } } = getState() as RootState;
      dispatch(setFetching(false));
      if (vehicleChecksFilters.page > 1) {
        dispatch(setVehicleChecksFilters({ ...vehicleChecksFilters, page: 1 }));
      } else {
        dispatch(getVehicleChecksThunk({ id: vehicleId, filters: vehicleChecksFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId: Math.random() }));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      closeModal();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleCheckAttachmentsListThunk = createAsyncThunk<GetAttachmentsResponse, {
  vehicleId: number,
  vehicleCheckId: number,
  filters?: CoreAttachmentsFilters,
}>(
  'get/VehicleCheckAttachmentsList',
  async ({
    vehicleId, vehicleCheckId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleCheckAttachmentsList(vehicleId, vehicleCheckId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getVehicleCheckAttachmentByIdThunk = createAsyncThunk<ResponseSingleResult<GetAttachmentFileResponse | null>, {
  vehicleId: number, vehicleCheckId: number, attachId: number,
}>(
  'get/VehicleCheckAttachmentById',
  async ({
    vehicleId, vehicleCheckId, attachId,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await vehiclesAPI.fetchVehicleCheckAttachmentById(vehicleId, vehicleCheckId, attachId);
      downloadCsv(response.data.data.uri);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
