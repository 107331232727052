import React, { useEffect, useState } from 'react';
import { Slider as SliderComponent } from 'antd';
import DecimalInputNumber from '../input-number/DecimalInputNumber';
import './Slider.scss';
import { toOptionalFixed } from '../../../common/utils/formatUtils';

type SliderProps = {
  value?: [string, string];
  disabled?: boolean;
  step?: number;
  onChange: (value: [string, string]) => void;
  onAfterChange: (value: [number, number]) => void;
  min: number;
  max: number;
  isTime?: boolean,
}

const Slider: React.FC<SliderProps> = ({
  value,
  disabled,
  step = 1,
  onChange,
  onAfterChange,
  min,
  max,
  isTime,
}) => {
  const [minV, setMinV] = useState<string>('');
  const [maxV, setMaxV] = useState<string>('');
  const onInputMinChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(',', '.');

    const extraMax = value ? value[1] : 0.00;
    if (+val > extraMax) {
      e.preventDefault();
      return;
    }
    if (val) {
      const splitVal = val.split('');
      if (splitVal[0] === '0' && splitVal[1] !== '.') {
        const parsed = parseFloat(val).toString();
        setMinV(parsed);
        value && onChange([parsed || min.toString(), value[1]]);
      } else {
        setMinV(val);
        value && onChange([val || min.toString(), value[1]]);
      }
    } else {
      setMinV('');
      value && onChange([val || min.toString(), value[1]]);
      value && onAfterChange([0.00, +value[1]]);
    }
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      value && minV && onAfterChange([+minV, +value[1]]);
    }, 1000);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [minV]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const timeOutId = setTimeout(() => {
      value && maxV && onAfterChange([+value[0], +maxV]);
    }, 1000);
    return () => clearTimeout(timeOutId);
    // eslint-disable-next-line
  }, [maxV]);

  const onInputMaxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value.replace(',', '.');
    const min = value ? value[0] : 0.00;

    if (+val < min) {
      e.preventDefault();
      return;
    }

    if (val) {
      const splitVal = val.split('');
      if (splitVal[0] === '0' && splitVal[1] !== '.') {
        const parsed = parseFloat(val).toString();
        setMaxV(parsed);
        value && onChange([value[0], parsed]);
      } else {
        setMaxV(val);
        value && onChange([value[0], val]);
      }
    } else {
      setMaxV('');
      value && onChange([value[0], '0.00']);
      value && onAfterChange([+value[0], 0.00]);
    }
  };
  return (
    <>
      <DecimalInputNumber
        max={max || 0.00}
        value={value ? isTime ? `${toOptionalFixed(+value[0] / 60)} h` : value[0] : '0.00'}
        onChange={(e) => onInputMinChange(e)}
        disabled={isTime}
      />
      <SliderComponent
        tipFormatter={(value) => (isTime ? `${value ? toOptionalFixed(value / 60) : value} h` : value)}
        range
        disabled={disabled}
        step={isTime ? 1 : step}
        value={value ? [+value[0], +value[1]] : [0, 0]}
        onChange={(v) => {
          const first = v[0].toString();
          const second = v[1].toString();
          setMinV(first);
          setMaxV(second);
          onChange([first, second]);
        }}
        min={min}
        max={max}
      />
      <DecimalInputNumber
        max={max || 0.00}
        value={value ? isTime ? `${toOptionalFixed(+value[1] / 60)} h` : value[1] : '0.00'}
        onChange={(e) => onInputMaxChange(e)}
        disabled={isTime}
      />
    </>
  );
};

export default Slider;
