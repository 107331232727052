import { ActionCreatorWithPayload } from '@reduxjs/toolkit';
import { useState } from 'react';
import moment from 'moment/moment';
import {
  CoreAttachmentsFilters, DateRangeTableFilterType, PickerValues, SearchTableFilterType,
} from '../types/coreTypes';
import { useAppDispatch } from '../../store/hooks';
import { DEFAULT_DATE_FORMAT } from '../utils/regex';

export const useCoreAttachmentsColumns = (
  filters: CoreAttachmentsFilters,
  setFilters: ActionCreatorWithPayload<CoreAttachmentsFilters>,
) => {
  const dispatch = useAppDispatch();

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    name: filters.name,
    comment: filters.comment,
  });

  const [pickerValue, setPickerValue] = useState<DateRangeTableFilterType>({
    uploadDate: [
      filters.uploadDate?.min ? moment(filters.uploadDate.min, DEFAULT_DATE_FORMAT) : null,
      filters.uploadDate?.max ? moment(filters.uploadDate.max, DEFAULT_DATE_FORMAT) : null,
    ],
  });

  const sortHandle = (field: number) => {
    dispatch(setFilters({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    }));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setFilters({
        ...filters,
        [field]: undefined,
        page: 1,
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };

  const searchPressHandle = (field: string) => {
    dispatch(setFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
      page: 1,
    }));
  };

  const pickerHandle = (field: string, values?: PickerValues) => {
    dispatch(setFilters({
      ...filters,
      [field]: values,
      page: 1,
    }));
  };

  return {
    searchVl,
    pickerValue,
    setPickerValue,
    sortHandle,
    onChangeSearchHandle,
    searchPressHandle,
    pickerHandle,
  };
};
