import React from 'react';
import { AddComponentModeEnum } from '../../../../enums/PartsKitsViewPageEnums';
import CustomComponentForm from './CustomComponentForm';
import CoreNonCoreComponent from './CoreNonCoreComponent';
import { BooleanFunctionType, VoidFunctionType } from '../../../../../../../core/types/coreTypes';
import { useAppSelector } from '../../../../../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../../../../../store/selectors/coreSelectors';

type AddComponentContentProps = {
  mode: AddComponentModeEnum,
  partsKitId: number,
  onCancel: VoidFunctionType,
  isVisible: boolean,
  itemToChange?: number,
  setCheckedAddKeys: (val: number[]) => void,
  setIsAddWithoutPrice: BooleanFunctionType,
}

const AddComponentContent: React.FC<AddComponentContentProps> = ({
  mode, partsKitId, onCancel, isVisible, itemToChange, setCheckedAddKeys, setIsAddWithoutPrice,
}) => {
  const isGlobalLoading = useAppSelector(isGlobalLoadingSelector);
  const renderContent = () => {
    if (mode === AddComponentModeEnum.CUSTOM) {
      return <CustomComponentForm
        onCancel={onCancel}
        partsKitId={partsKitId}
        itemToChange={itemToChange}
        isVisible={isVisible}
        isLoading={isGlobalLoading}
      />;
    } else {
      return <CoreNonCoreComponent
        partsKitId={partsKitId}
        onCancel={onCancel}
        setCheckedAddKeys={setCheckedAddKeys}
        mode={mode}
        isLoading={isGlobalLoading}
        setIsAddWithoutPrice={setIsAddWithoutPrice}
      />;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default AddComponentContent;
