import { useState } from 'react';
import { useAppDispatch } from '../../../../store/hooks';
import {
  deletePurchaseOrderPartBulkThunk,
  deletePurchaseOrderPartThunk,
} from '../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { RangeTableFiltersType, NumericRangeType } from '../../../../core/types/coreTypes';

export const usePurchaseOrderPartsTable = (
  checkedKeys: number[],
  setQtyMinMax:(v: NumericRangeType) => void,
  setRange: (v: RangeTableFiltersType) => void,
) => {
  const [isDelete, setIsDelete] = useState(false);
  const [itemToChange, setItemToChange] = useState<number | undefined>(undefined);

  const dispatch = useAppDispatch();

  const deleteHandle = (id: number) => {
    if (itemToChange) {
      id && dispatch(deletePurchaseOrderPartThunk({
        purchaseOrderId: +id,
        partId: itemToChange,
        closeModal: () => {
          setIsDelete(false);
          setItemToChange(undefined);
        },
        setQtyMinMax,
        setRange,
      }));
    } else {
      id && dispatch(deletePurchaseOrderPartBulkThunk({
        purchaseOrderId: +id,
        purchaseOrderPartIds: checkedKeys,
        closeModal: () => {
          setIsDelete(false);
        },
        setQtyMinMax,
        setRange,
      }));
    }
  };
  return {
    isDelete,
    setIsDelete,
    itemToChange,
    setItemToChange,
    deleteHandle,
  };
};
