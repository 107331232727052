import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RcFile } from 'antd/es/upload';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  pricelistAttachmentsFiltersSelector,
  pricelistAttachmentsSelector,
} from '../../../../../store/selectors/pricelistSelectors';
import {
  createPricelistAttachmentThunk, deleteMultiplePricelistAttachmentThunk,
  getPricelistAttachments,
} from '../../../../../store/thunks/pricelist/pricelistAttachmentsThunks';
import CreateAttachmentModal from '../../../../../common/components/create-attachment/CreateAttachmentModal';
import ConfirmationModal from '../../../../../core/components/confirmation-modal/ConfirmationModal';
import PricelistAttachmentFilters from './filters/PricelistAttachmentFilters';
import PricelistAttachmentTable from './table/PricelistAttachmentTable';
import { setLoading } from '../../../../../store/slices/coreSlice';
import { guidGenerator } from '../../../../../core/utils/guidGenerator';
import { useSelectAll } from '../../../../../core/hooks/useSelectAll';

const PricelistAttachments: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { items, allItemIds, totalCount } = useAppSelector(pricelistAttachmentsSelector);
  const filters = useAppSelector(pricelistAttachmentsFiltersSelector);

  const [isCreateModal, setCreateModal] = useState(false);
  const [isDeleteModal, setDeleteModal] = useState(false);
  const [fileList, setFileList] = useState<RcFile[]>([]);
  const [comment, setComment] = useState('');

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
  } = useSelectAll(allItemIds);

  const cancelHandle = () => {
    setCreateModal(false);
    setFileList([]);
    setComment('');
  };

  useEffect(() => {
    id && dispatch(getPricelistAttachments({ pricelistId: +id, filters }));
    // eslint-disable-next-line
    }, [id, filters]);

  const uploadHandle = async () => {
    dispatch(setLoading(true));
    const list = fileList.map((el) => ({
      fileName: el.name,
      uploadGuid: guidGenerator(),
      file: el,
    }));
    id && dispatch(createPricelistAttachmentThunk({
      id: +id,
      list,
      comment: comment.trim(),
      onCancel: () => cancelHandle(),
    }));
  };

  const deleteSelected = () => {
    id && dispatch(deleteMultiplePricelistAttachmentThunk({
      ids: checkedKeys,
      pricelistId: +id,
      closeModal: () => setDeleteModal(false),
    }));
  };

  return (
    <>
      <CreateAttachmentModal
        isVisible={isCreateModal}
        onCancel={cancelHandle}
        fileList={fileList}
        comment={comment}
        setComment={setComment}
        setFileList={setFileList}
        uploadHandle={uploadHandle}
      />
      <ConfirmationModal
        isVisible={isDeleteModal}
        onCancel={() => {
          setDeleteModal(false);
        }}
        confirmHandler={deleteSelected}
      />
      <div className="pricelistAttachWrap">
        <PricelistAttachmentFilters
          filters={filters}
          totalItems={totalCount}
          setCreateModal={setCreateModal}
          setDeleteModal={() => setDeleteModal(true)}
          checkedKeys={checkedKeys}
        />
        <PricelistAttachmentTable
          attachments={items}
          checkedKeys={checkedKeys}
          isAll={isAll}
          indeterminate={isIndeterminate}
          checkAllHandle={checkAllHandle}
          checkRow={checkRowHandle}
        />
      </div>
    </>
  );
};

export default PricelistAttachments;
