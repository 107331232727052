import React, { useRef } from 'react';
import { Input } from 'antd';
import classnames from 'classnames';
import { ChangeEventHandler, FocusEventHandler, KeyboardEventHandler } from '../../types/coreTypes';
import './Textarea.scss';

const { TextArea } = Input;

type TextareaProps = {
  value: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onPressEnter?: KeyboardEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
  disabled?: boolean;
  allowClear?: boolean;
  rows?: number;
  placeholder?: string;
  error?: string;
  label?: string;
  className?: string;
}

const Textarea: React.FC<TextareaProps> = ({
  value,
  onChange,
  onPressEnter,
  onBlur,
  disabled,
  allowClear,
  rows = 4,
  placeholder,
  label,
  error,
  className,
}) => {
  const ref = useRef<HTMLTextAreaElement>(null);
  return (
    <div className={classnames(className, 'textarea')}>
      {label && <span className="textarea-label">{label}</span>}
      <TextArea
        ref={ref}
        value={value}
        onChange={onChange}
        onPressEnter={(e) => {
          onPressEnter && onPressEnter(e);
          ref.current?.blur();
        }}
        onBlur={onBlur}
        rows={rows}
        disabled={disabled}
        allowClear={allowClear}
        placeholder={placeholder}
        className="textarea-field"
      />
      {error && <span className="textarea-error">{error}</span>}
    </div>
  );
};

export default Textarea;
