import React from 'react';
import { Tooltip } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../../../core/components/checkbox/Checkbox';
import QuantityComponent from './QuantityComponent';
import Tr from '../../../core/components/table-components/Tr';
import { StringFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import ManualInputUnitPrice from './ManualInputUnitPrice';
import TdNew from '../../../core/components/table-components/table-new/TdNew';

type SourceQtyLineProps = {
  checked: boolean,
  sourceBoxValue: string,
  sourceBoxOnChange: VoidFunctionType,
  disabledQty: boolean,
  disabledPlus?: boolean,
  disabledMinus?: boolean,
  qtyValue: string,
  setQtyValue: StringFunctionType,
  text: string,
  availableTooltip?: string,
  isDecimals: boolean,
  unitPrice?: string | number,
  netPrice?: string | null,
  isManual?: boolean,
  manualDisabled?: boolean,
  manualValue?: string,
  manualUnitPriceChange?: StringFunctionType,
}

const SourceQtyLine: React.FC<SourceQtyLineProps> = ({
  checked, sourceBoxValue, sourceBoxOnChange, disabledQty, disabledPlus, setQtyValue,
  qtyValue, disabledMinus, text, availableTooltip = '', isDecimals, unitPrice, netPrice,
  isManual, manualDisabled, manualUnitPriceChange, manualValue,
}) => {
  return (
    <Tr>
      <TdNew>
        <Checkbox
          value={sourceBoxValue}
          checked={checked}
          onChange={sourceBoxOnChange}
          table
        />
      </TdNew>
      <TdNew>
        <span className="sourceQtyLine__text">
          {text}
        </span>
        {availableTooltip && (
          <Tooltip title={availableTooltip} className="sourceQtyLine__tooltip" overlayClassName="sourceQtyLine__tooltip-overlay">
            <FontAwesomeIcon icon={faCircleInfo} />
          </Tooltip>
        )}
      </TdNew>
      <TdNew>
        <QuantityComponent
          disabled={disabledQty}
          disabledPlus={disabledPlus}
          disabledMinus={disabledMinus}
          value={qtyValue}
          setValue={(v) => setQtyValue(v)}
          isDecimals={isDecimals}
        />
      </TdNew>
      <TdNew>
        {isManual
          ? <ManualInputUnitPrice
            disabled={manualDisabled}
            value={manualValue || ''}
            onChange={(v) => manualUnitPriceChange && manualUnitPriceChange(v)}
          />
          : <div className="valueCell">{`£ ${unitPrice || 0}`}</div>}
      </TdNew>
      <TdNew>
        <div className="valueCell">{`£ ${netPrice || 0}`}</div>
      </TdNew>
    </Tr>
  );
};

export default SourceQtyLine;
