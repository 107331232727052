import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import {
  initialCoreAttachmentsFilters,
  initDataList,
  infiniteScrollInitPaging, initDataListWithIds,
} from '../../../core/types/coreTypes';
import {
  setSupplierActivityLogFilters,
  setSupplierAttachments, setSupplierAttachmentsFilters,
  setSupplierContactsFilters,
  setSupplierDetails, setSuppliersContactsList, setSupplierTimeline,
} from '../../../store/slices/supplierSlice';
import { initExtendedTimelineFilters } from '../../../common/types/commonTypes';

export const clearSuppliersData = (dispatch: ThunkDispatch<unknown, unknown, AnyAction>) => {
  dispatch(setSupplierDetails(null));
  dispatch(setSupplierAttachments(initDataListWithIds));
  dispatch(setSupplierAttachmentsFilters(initialCoreAttachmentsFilters));
  dispatch(setSupplierContactsFilters(infiniteScrollInitPaging));
  dispatch(setSuppliersContactsList(initDataList));
  dispatch(setSupplierTimeline(initDataList));
  dispatch(setSupplierActivityLogFilters(initExtendedTimelineFilters));
};
