import React, { useEffect, useMemo, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import {
  RangeTableFiltersType, StringFunctionType, VoidFunctionType, NumericRangeType,
} from '../../../../../../../core/types/coreTypes';
import Modal from '../../../../../../../core/components/modal/Modal';
import Button from '../../../../../../../core/components/button/Button';
import './SelectPartPricelistModal.scss';
import Pagination from '../../../../../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../../../../../core/utils/regex';
import PartPricelistMeasures from './PartPricelistMeasures';
import ButtonActions from '../../../../../../../core/components/button-actions/ButtonActions';
import SelectPricelistTable from './SelectPricelistTable';
import {
  partSelectPricelistResultTableSelector,
  selectPricelistAndQtyFiltersSelector,
} from '../../../../../../../store/selectors/stockSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { partPricesDefaultOrderingSelector } from '../../../../../../../store/selectors/coreOrderingSelectors';
import { setSelectPricelistAndQtyFilters } from '../../../../../../../store/slices/stockSlice';
import { getPartPricesThunk } from '../../../../../../../store/thunks/pricelist/pricelistDetailsThunks';
import { PurchaseOrderPartVatEnum } from '../../../../enums/purchaseOrderEnums';
import {
  patchPurchaseOrderPartThunk,
  postPurchaseOrderPart,
} from '../../../../../../../store/thunks/stock/purchase-orders/purchaseOrderDetailsThunks';
import { integerQuantityMeasureValuesSelector, isFetchingSelector } from '../../../../../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import {
  purchaseOrderDetailsSelector,
  purchaseOrderPartsListSelector, purchaseOrderPartVatSelector, purchaseOrderSearchPartsSelector,
} from '../../../../../../../store/selectors/purchaseOrderSelectors';
import { EnterPriceQtyModes } from '../../../../enums/PurchaseOrderViewEnum';
import ManualInputTabContent from './ManualInputTabContent';
import {
  PatchPurchaseOrderPartDataType,
  PostPurchaseOrderPartDataType,
} from '../../../../types/purchaseOrderViewPageTypes';

type SelectPartPricelistModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  enterPriceQtyMode: string,
  setEnterPriceQtyMode: StringFunctionType,
  selectedPartId?: number,
  closeAddPartModal: VoidFunctionType,
  itemToChange?: number,
  setQtyMinMax:(v: NumericRangeType) => void,
  setRange: (v: RangeTableFiltersType) => void,
}

const SelectPartPricelistModal: React.FC<SelectPartPricelistModalProps> = ({
  isVisible, onCancel, enterPriceQtyMode, selectedPartId, setEnterPriceQtyMode,
  closeAddPartModal, itemToChange, setQtyMinMax, setRange,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const [selectedPricelistId, setSelectedPricelistId] = useState<number | undefined>(undefined);
  const [vatVal, setVatVal] = useState<number | undefined>(undefined);
  const [quantityVal, setQuantityVal] = useState<string>('');

  const { purchaseOrderParts } = useAppSelector(purchaseOrderPartsListSelector);
  const purchaseOrderPartVat = useAppSelector(purchaseOrderPartVatSelector);
  const selectPricelistAndQtyFilters = useAppSelector(selectPricelistAndQtyFiltersSelector);
  const partSelectPricelistResultTable = useAppSelector(partSelectPricelistResultTableSelector);
  const searchResult = useAppSelector(purchaseOrderSearchPartsSelector);
  const partPricesDefaultOrdering = useAppSelector(partPricesDefaultOrderingSelector);
  const purchaseOrderDetails = useAppSelector(purchaseOrderDetailsSelector);
  const integerMeasures = useAppSelector(integerQuantityMeasureValuesSelector);
  const isFetching = useAppSelector(isFetchingSelector);

  const foundItemToChange = purchaseOrderParts.find((el) => el.id === itemToChange);
  const itemToChangeDueIn = foundItemToChange?.dueIn || 0;
  const minItemToChangeQty = foundItemToChange?.requestedQuantity ? (foundItemToChange.requestedQuantity - itemToChangeDueIn) : 0;

  const selectedPartName = useMemo(() => {
    if (foundItemToChange) {
      return foundItemToChange.partName || '-';
    } else {
      return searchResult.items?.find((el) => el.id === selectedPartId)?.name;
    }
  }, [foundItemToChange, searchResult.items, selectedPartId]);
  const selectedPartUnitOfMeasure = useMemo(() => {
    if (foundItemToChange) {
      return foundItemToChange.unitOfMeasure;
    } else {
      return searchResult.items?.find((el) => el.id === selectedPartId)?.unitOfMeasure;
    }
  }, [
    searchResult.items,
    foundItemToChange,
    selectedPartId,
  ]);

  useEffect(() => {
    if (isVisible && !quantityVal) {
      const initQty = integerMeasures.includes(selectedPartUnitOfMeasure) ? '0' : '0.00';
      setQuantityVal(initQty);
    }
    // eslint-disable-next-line
  }, [selectedPartId, selectedPartUnitOfMeasure, integerMeasures, isVisible]);

  useEffect(() => {
    const found = purchaseOrderPartVat.find((el) => el.label === PurchaseOrderPartVatEnum.TwentyPercent);
    if (vatVal === undefined && found) {
      setVatVal(found.value);
    }
  }, [purchaseOrderPartVat, vatVal]);

  const paginatedHandle = (page: number, pageSize: number) => {
    const newFilters = {
      ...selectPricelistAndQtyFilters,
      priceDate: purchaseOrderDetails?.purchaseOrder.purchaseOrderDate,
      supplierId: purchaseOrderDetails?.purchaseOrder.supplierId,
      activeOnly: true,
      page,
      pageSize,
      order: selectPricelistAndQtyFilters?.order || { field: partPricesDefaultOrdering, isAsc: true },
    };
    dispatch(setSelectPricelistAndQtyFilters(newFilters));
    selectedPartId && dispatch(getPartPricesThunk({
      pricelistId: undefined,
      partId: selectedPartId,
      filters: newFilters,
    }));
  };

  const cancelHandle = () => {
    onCancel();
    setQuantityVal('');
    setVatVal(undefined);
    setSelectedPricelistId(undefined);
  };

  const disabledAddConditions = !selectedPricelistId
      || (vatVal === undefined)
      || !quantityVal
      || +quantityVal === 0
      || +quantityVal < minItemToChangeQty;
  const addPartHandle = (values: PostPurchaseOrderPartDataType, closeModal: VoidFunctionType) => {
    id && dispatch(postPurchaseOrderPart({
      purchaseOrderId: +id,
      values,
      closeModal: () => {
        closeModal();
        closeAddPartModal();
      },
      setRange,
      setQtyMinMax,
    }));
  };
  const editPartHandle = (partId: number, values: PatchPurchaseOrderPartDataType, closeHandle: VoidFunctionType) => {
    id && itemToChange && dispatch(patchPurchaseOrderPartThunk({
      purchaseOrderId: +id,
      partId,
      values,
      closeModal: () => {
        closeHandle();
        closeAddPartModal();
      },
      setRange,
      setQtyMinMax,
    }));
  };

  useEffect(() => {
    if (partSelectPricelistResultTable.totalCount === 1) {
      const firstEl = partSelectPricelistResultTable.items[0].id;
      setSelectedPricelistId(firstEl);
    }
  }, [partSelectPricelistResultTable]);

  useEffect(() => {
    if (foundItemToChange && isVisible) {
      if (foundItemToChange.pricelistId) {
        setEnterPriceQtyMode(EnterPriceQtyModes.PRICELIST);
        setVatVal(foundItemToChange.vatPercent);
        setQuantityVal(foundItemToChange.requestedQuantity.toString() || '0');
        setSelectedPricelistId(foundItemToChange.pricelistId);
      } else {
        setEnterPriceQtyMode(EnterPriceQtyModes.MANUAL);
      }
    }
    // eslint-disable-next-line
  }, [isVisible, selectedPartId, purchaseOrderParts, itemToChange]);
  return (
    <Modal
      visible={isVisible}
      onCancel={cancelHandle}
      title={itemToChange ? 'Edit price and quantity' : 'Enter price and quantity'}
      width={1000}
      destroyOnClose
    >
      <div className="partPricelistWrap">
        <section className="partPricelistWrap__head">
          {!itemToChange && <Button
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            className="partPricelistWrap__head-btn"
            onClick={onCancel}
          />}
          <TruncateTooltip value={selectedPartName || '-'} className="partPricelistWrap__head-title" />
        </section>
        <div className="partPricelistWrap__buttons">
          <Button
            label="Pricelist"
            onClick={() => setEnterPriceQtyMode(EnterPriceQtyModes.PRICELIST)}
            designType="light-inverse"
            isActivated={enterPriceQtyMode === EnterPriceQtyModes.PRICELIST}
            offsetRight
          />
          <Button
            label="Manual input"
            onClick={() => setEnterPriceQtyMode(EnterPriceQtyModes.MANUAL)}
            designType="light-inverse"
            isActivated={enterPriceQtyMode === EnterPriceQtyModes.MANUAL}
          />
        </div>
        {enterPriceQtyMode === EnterPriceQtyModes.PRICELIST
          ? <>
            <Pagination
              currentPage={selectPricelistAndQtyFilters?.page}
              totalItems={partSelectPricelistResultTable.totalCount}
              pageSize={selectPricelistAndQtyFilters?.pageSize}
              pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
              onChange={(page, pageSize) => paginatedHandle(page, pageSize)}
              className="partPricelistWrap__paging"
            />
            <SelectPricelistTable
              selectPricelistAndQtyFilters={selectPricelistAndQtyFilters}
              selectedPartId={selectedPartId}
              setSelectedPricelistId={setSelectedPricelistId}
              selectedPricelistId={selectedPricelistId}
            />
            <PartPricelistMeasures
              quantityVal={quantityVal}
              setQuantityVal={setQuantityVal}
              vatVal={vatVal}
              setVatVal={setVatVal}
              selectedPricelistId={selectedPricelistId}
              selectedPartUnitOfMeasure={selectedPartUnitOfMeasure}
              minItemToChangeQty={minItemToChangeQty}
              itemToChange={itemToChange}
            />
          </>
          : <ManualInputTabContent
            selectedPartUnitOfMeasure={selectedPartUnitOfMeasure}
            cancelHandle={cancelHandle}
            addPartHandle={addPartHandle}
            editPartHandle={editPartHandle}
            selectedPartId={selectedPartId}
            itemToChange={foundItemToChange}
          />}
        {enterPriceQtyMode === EnterPriceQtyModes.PRICELIST && <ButtonActions
          cancelClick={cancelHandle}
          cancelLabel="Cancel"
          createLabel={itemToChange ? 'Save' : 'Add part'}
          createType="button"
          createClick={() => {
            if (itemToChange) {
              itemToChange && editPartHandle(
                itemToChange,
                {
                  pricelistId: selectedPricelistId,
                  quantity: +quantityVal,
                  vat: vatVal || 0,
                },
                () => cancelHandle(),
              );
            } else {
              selectedPartId && addPartHandle({
                partId: selectedPartId,
                pricelistId: selectedPricelistId || null,
                quantity: +quantityVal,
                vat: vatVal || 0,
              }, () => {
                cancelHandle();
                closeAddPartModal();
              });
            }
          }}
          disabledCreate={disabledAddConditions}
          isLoading={isFetching}
        />}
      </div>
    </Modal>
  );
};

export default SelectPartPricelistModal;
