import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { setUiNamesHandler } from '../../core/utils/setUiNamesHandler';
import {
  AccountUserStatusUiNames,
  CustomersStatusesEnum,
  EnquiryStatusUiNamesEnum,
  ineligibleActivateStatusesList,
  ineligibleInactivateStatusesList,
  ineligibleInviteStatusesList,
  ineligibleResetStatusesList, InfoMessageStatusUiNameEnum, JobCheckDecision, JobCheckStatusesEnum,
  LeaveRequestsStatusesUiEnum, PartRequestLineStatusTransitionEnum,
  PartRequestSubmissionStatusesEnum,
  PricelistStatusUiNames,
  QuotePricelistStatusUiNames,
  StockPartStatusesEnum,
  SupplierStatusUiNames,
  VehicleStatusEnum,
} from '../../core/enums/dictionariesEnums';
import { OrderStatusUiEnum } from '../../orders/dashboard/enums/ordersEnums';
import { RectificationStatusesEnum } from '../../rectifications/dashboard/enums/rectificationEnums';
import { JobListAllStatusEnum, JobStatusesUiNames } from '../../job/jobs/enums/jobsEnums';
import { OrderPartRequestLineStatusEnum } from '../../orders/orders-view-page/enums/orderViewPageEnums';
import { StockPartReqStatusesEnum } from '../../stock/stock-part-requests/enums/stockPartRequestsEnum';
import { PurchaseOrderStatusUiNames } from '../../stock/purchase-orders/dashboard/enums/PurchaseOrderStatusEnum';
import { PartQtyStatusEnum } from '../../stock/part-view-page/enums/PartTypesEnum';
import { InvoiceDocumentStatusesEnum } from '../../invoicing/container/enums/InvoicingSharedEnums';

const enquiryStatusInitSelector = (state: RootState) => state.coreStatuses.enquiryStatus;
export const enquiryStatusSelector = createSelector(enquiryStatusInitSelector, (statuses) => {
  return setUiNamesHandler(statuses, EnquiryStatusUiNamesEnum);
});

export const enquiryNewStatusSelector = createSelector(enquiryStatusSelector, (statuses) => {
  return statuses.find((el) => el.label === EnquiryStatusUiNamesEnum.New)?.value;
});

export const enquiryFinalStatusesSelector = createSelector(enquiryStatusSelector, (items) => {
  const arr = [
    EnquiryStatusUiNamesEnum.Finalised,
    EnquiryStatusUiNamesEnum.SentToCustomer,
    EnquiryStatusUiNamesEnum.Accepted,
    EnquiryStatusUiNamesEnum.Rejected,
  ];
  return items.filter((el) => arr.includes(el.label as EnquiryStatusUiNamesEnum));
});
export const enquiryRequiredStatusesSelector = createSelector(enquiryStatusSelector, (items) => {
  const arr = [
    EnquiryStatusUiNamesEnum.New,
    EnquiryStatusUiNamesEnum.ReadyForProcessing,
    EnquiryStatusUiNamesEnum.InProgress,
    EnquiryStatusUiNamesEnum.MissingInformation,
    EnquiryStatusUiNamesEnum.Postponed,
  ];
  return items.filter((el) => arr.includes(el.label as EnquiryStatusUiNamesEnum));
});

export const enquiryAcceptedStatusSelector = createSelector(enquiryStatusSelector, (items) => {
  return items.find((el) => el.label === EnquiryStatusUiNamesEnum.Accepted);
});

export const enquiryRejectedStatusSelector = createSelector(enquiryStatusSelector, (items) => {
  return items.find((el) => el.label === EnquiryStatusUiNamesEnum.Rejected);
});

export const enquirySentToCustomerStatusSelector = createSelector(enquiryStatusSelector, (items) => {
  return items.find((el) => el.label === EnquiryStatusUiNamesEnum.SentToCustomer);
});

export const customerStatusesSelector = (state: RootState) => state.coreStatuses.customerStatuses;
export const customerCompleteStatusSelector = createSelector(customerStatusesSelector, (statuses) => {
  return statuses.find((el) => el.label === CustomersStatusesEnum.Complete)?.value;
});

const accountAccountStatusInitSelector = (state: RootState) => state.coreStatuses.accountAccountStatus;
export const accountAccountStatusSelector = createSelector(accountAccountStatusInitSelector, (accountStatus) => {
  return setUiNamesHandler(accountStatus, AccountUserStatusUiNames);
});
export const ineligibleResetUsersStatusesSelector = createSelector(accountAccountStatusSelector, (items) => {
  return items.filter((item) => ineligibleResetStatusesList.includes(item.label as AccountUserStatusUiNames));
});
export const ineligibleInviteUsersStatusesSelector = createSelector(accountAccountStatusSelector, (items) => {
  return items.filter((item) => ineligibleInviteStatusesList.includes(item.label as AccountUserStatusUiNames));
});

export const ineligibleActivateStatusesSelector = createSelector(accountAccountStatusSelector, (items) => {
  return items.filter((item) => ineligibleActivateStatusesList.includes(item.label as AccountUserStatusUiNames));
});

export const ineligibleInactivateStatusesSelector = createSelector(accountAccountStatusSelector, (items) => {
  return items.filter((item) => ineligibleInactivateStatusesList.includes(item.label as AccountUserStatusUiNames));
});

export const inactiveStatusSelector = createSelector(accountAccountStatusSelector, (items) => {
  return items.find((item) => item.label === AccountUserStatusUiNames.Inactive);
});

export const infoMessageStatusesSelector = (state: RootState) => state.coreStatuses.infoMessageStatuses;
export const infoMessagesStatusValuesSelector = createSelector(infoMessageStatusesSelector, (statuses) => ({
  published: statuses.find(({ label }) => label === InfoMessageStatusUiNameEnum.Published)?.value,
  unpublished: statuses.find(({ label }) => label === InfoMessageStatusUiNameEnum.Unpublished)?.value,
}));
export const pricelistStatusesSelector = (state: RootState) => state.coreStatuses.pricelistStatuses;
export const activePricelistStatusSelector = createSelector(pricelistStatusesSelector, (items) => {
  return items.find((item) => item.label === PricelistStatusUiNames.Active);
});

const quotePricelistStatusesInitSelector = (state: RootState) => state.coreStatuses.quotePricelistStatuses;
export const QuotePricelistStatusesSelector = createSelector(quotePricelistStatusesInitSelector, (list) => {
  return setUiNamesHandler(list, QuotePricelistStatusUiNames);
});

export const partsKitsStatusesSelector = (state: RootState) => state.coreStatuses.partsKitsStatuses;
export const activePartsKitStatusSelector = createSelector(partsKitsStatusesSelector, (statuses) => {
  return statuses.find((st) => st.label === 'Active')?.value;
});

const orderStatusInitSelector = (state: RootState) => state.coreStatuses.orderStatus;
export const orderStatusSelector = createSelector(orderStatusInitSelector, (list) => {
  return setUiNamesHandler(list, OrderStatusUiEnum);
});

export const orderStatusesValuesSelector = createSelector(orderStatusSelector, (statuses) => {
  return {
    newStatus: statuses.find((el) => el.label === OrderStatusUiEnum.New)?.value,
    booking: statuses.find((el) => el.label === OrderStatusUiEnum.Booking)?.value,
    expensesReview: statuses.find((el) => el.label === OrderStatusUiEnum.ExpensesReview)?.value,
    finalised: statuses.find((el) => el.label === OrderStatusUiEnum.Finalised)?.value,
    jobInProgress: statuses.find((el) => el.label === OrderStatusUiEnum.JobInProgress)?.value,
    awaitingSubcontractorWork: statuses.find((el) => el.label === OrderStatusUiEnum.AwaitingSubcontractorWork)?.value,
    readyForPostCheck: statuses.find((el) => el.label === OrderStatusUiEnum.ReadyForPostCheck)?.value,
    postCheckPassed: statuses.find((el) => el.label === OrderStatusUiEnum.PostCheckPassed)?.value,
    postCheckFailed: statuses.find((el) => el.label === OrderStatusUiEnum.PostCheckFailed)?.value,
    acceptedByCustomer: statuses.find((el) => el.label === OrderStatusUiEnum.AcceptedByCustomer)?.value,
    rejectedByCustomer: statuses.find((el) => el.label === OrderStatusUiEnum.RejectedByCustomer)?.value,
    reasonReview: statuses.find((el) => el.label === OrderStatusUiEnum.ReasonReview)?.value,
    invoicing: statuses.find((el) => el.label === OrderStatusUiEnum.Invoicing)?.value,
    completed: statuses.find((el) => el.label === OrderStatusUiEnum.Completed)?.value,
    cancelled: statuses.find((el) => el.label === OrderStatusUiEnum.Cancelled)?.value,
  };
});

export const orderForbiddenStatusesToLinkVehicleSelector = createSelector(orderStatusesValuesSelector, (values) => {
  const {
    readyForPostCheck, postCheckPassed, postCheckFailed, acceptedByCustomer,
    invoicing, rejectedByCustomer, completed, cancelled,
  } = values;
  return [
    readyForPostCheck, postCheckPassed, postCheckFailed, acceptedByCustomer,
    invoicing, rejectedByCustomer, completed, cancelled,
  ];
});

export const orderForbiddenStatusesToEditDraftVehicleSelector = createSelector(orderStatusesValuesSelector, (values) => {
  const {
    readyForPostCheck, postCheckPassed, postCheckFailed, acceptedByCustomer,
    rejectedByCustomer, cancelled, invoicing, completed,
  } = values;
  return [
    readyForPostCheck, postCheckPassed, postCheckFailed,
    acceptedByCustomer, rejectedByCustomer, cancelled, invoicing, completed,
  ];
});

const rectificationStatusesInitSelector = (state: RootState) => state.coreStatuses.rectificationStatuses;
export const rectificationStatusesSelector = createSelector(rectificationStatusesInitSelector, (list) => {
  return setUiNamesHandler(list, RectificationStatusesEnum);
});

export const rectificationReadyForPostCheckStatusSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  return statuses.find((el) => el.label === RectificationStatusesEnum.ReadyForPostCheck)?.value;
});
export const rectificationStatusChangeConfirmationStatusesSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  const rejected = statuses.find((el) => el.label === RectificationStatusesEnum.RejectedByCustomer)?.value;
  const completed = statuses.find((el) => el.label === RectificationStatusesEnum.Completed)?.value;
  const cancelled = statuses.find((el) => el.label === RectificationStatusesEnum.Cancelled)?.value;

  return { rejected, completed, cancelled };
});

export const forbiddenStatusesToLinkVehicleSelector = createSelector(rectificationStatusesSelector, (statuses) => {
  const readyForPostCheck = statuses.find((el) => el.label === RectificationStatusesEnum.ReadyForPostCheck)?.value;
  const postCheckFailed = statuses.find((el) => el.label === RectificationStatusesEnum.PostCheckFailed)?.value;
  const postCheckPassed = statuses.find((el) => el.label === RectificationStatusesEnum.PostCheckPassed)?.value;
  const acceptedByCustomer = statuses.find((el) => el.label === RectificationStatusesEnum.AcceptedByCustomer)?.value;
  const rejectedByCustomer = statuses.find((el) => el.label === RectificationStatusesEnum.RejectedByCustomer)?.value;
  const invoicing = statuses.find((el) => el.label === RectificationStatusesEnum.Invoicing)?.value;
  const completed = statuses.find((el) => el.label === RectificationStatusesEnum.Completed)?.value;
  const cancelled = statuses.find((el) => el.label === RectificationStatusesEnum.Cancelled)?.value;

  return [readyForPostCheck, postCheckFailed, postCheckPassed, acceptedByCustomer, rejectedByCustomer, invoicing, completed, cancelled];
});

const jobInitStatusesSelector = (state: RootState) => state.coreStatuses.jobStatuses;
export const jobInitUIStatusesSelector = createSelector(jobInitStatusesSelector, (list) => {
  return setUiNamesHandler(list, JobStatusesUiNames);
});
export const jobStatusesSelector = createSelector(jobInitUIStatusesSelector, (data) => {
  const uiItems = setUiNamesHandler(data, JobStatusesUiNames);
  return [
    { label: 'All', value: JobListAllStatusEnum.ALL },
    ...uiItems,
  ];
});

export const jobStatusesValuesSelector = createSelector(jobInitUIStatusesSelector, (list) => {
  return {
    newStatus: list.find((el) => el.label === JobStatusesUiNames.New)?.value,
    jobQc: list.find((el) => el.label === JobStatusesUiNames.JobQc)?.value,
    inProduction: list.find((el) => el.label === JobStatusesUiNames.InProduction)?.value,
    pending: list.find((el) => el.label === JobStatusesUiNames.Pending)?.value,
    readyForQc: list.find((el) => el.label === JobStatusesUiNames.ReadyForQc)?.value,
    postponed: list.find((el) => el.label === JobStatusesUiNames.Postponed)?.value,
    failed: list.find((el) => el.label === JobStatusesUiNames.Failed)?.value,
    cancelled: list.find((el) => el.label === JobStatusesUiNames.Cancelled)?.value,
    completed: list.find((el) => el.label === JobStatusesUiNames.Completed)?.value,
  };
});

export const jobPostponedStatusSelector = createSelector(jobInitUIStatusesSelector, (list) => {
  return list.find((el) => el.label === JobStatusesUiNames.Postponed)?.value;
});

const partRequestLineStatusesInitSelector = (state: RootState) => state.coreStatuses.partRequestLineStatuses;
export const partRequestLineStatusesSelector = createSelector(partRequestLineStatusesInitSelector, (list) => {
  return setUiNamesHandler(list, OrderPartRequestLineStatusEnum);
});

export const partReqLineActionsStatusesSelector = createSelector(partRequestLineStatusesSelector, (list) => {
  return {
    unknownPart: list.find((el) => el.label === OrderPartRequestLineStatusEnum.UnknownPart),
    confirmed: list.find((el) => el.label === OrderPartRequestLineStatusEnum.Confirmed),
    ready: list.find((el) => el.label === OrderPartRequestLineStatusEnum.Ready),
    partiallyReady: list.find((el) => el.label === OrderPartRequestLineStatusEnum.PartiallyReady),
    partiallyCollected: list.find((el) => el.label === OrderPartRequestLineStatusEnum.PartiallyCollected),
    collected: list.find((el) => el.label === OrderPartRequestLineStatusEnum.Collected),
    deleted: list.find((el) => el.label === OrderPartRequestLineStatusEnum.Deleted),
    archived: list.find((el) => el.label === OrderPartRequestLineStatusEnum.Archived),
    pendingSource: list.find((el) => el.label === OrderPartRequestLineStatusEnum.PendingSource),
    toBeReviewed: list.find((el) => el.label === OrderPartRequestLineStatusEnum.ToBeReviewed),
  };
});

export const partRequestLineDeletedStatusSelector = createSelector(partRequestLineStatusesInitSelector, (list) => {
  return list.find((el) => el.label === OrderPartRequestLineStatusEnum.Deleted)?.value;
});

export const allocatedPartReqLinesStatusesSelector = createSelector(partRequestLineStatusesSelector, (statuses) => {
  const listToCheck = [
    OrderPartRequestLineStatusEnum.Confirmed,
    OrderPartRequestLineStatusEnum.PartiallyReady,
    OrderPartRequestLineStatusEnum.Ready,
    OrderPartRequestLineStatusEnum.PartiallyCollected,
    OrderPartRequestLineStatusEnum.Collected,
  ];
  return statuses.filter((st) => listToCheck.includes(st.label as OrderPartRequestLineStatusEnum));
});

export const inAppNotificationStatusesSelector = (state: RootState) => state.coreStatuses.inAppNotificationStatuses;
export const inAppReadStatusSelector = createSelector(inAppNotificationStatusesSelector, (statuses) => {
  return statuses.find((el) => el.label === 'Read')?.value;
});
export const inAppUnreadStatusSelector = createSelector(inAppNotificationStatusesSelector, (statuses) => {
  return statuses.find((el) => el.label === 'Unread')?.value;
});

const vehicleStatusesInitSelector = (state: RootState) => state.coreStatuses.vehicleStatuses;
export const vehicleStatusesSelector = createSelector(vehicleStatusesInitSelector, (items) => {
  return setUiNamesHandler(items, VehicleStatusEnum);
});
export const vehicleStatusValuesSelector = createSelector(vehicleStatusesSelector, (items) => {
  return {
    new: items.find((el) => el.label === VehicleStatusEnum.New)?.value,
    bookedIn: items.find((el) => el.label === VehicleStatusEnum.BookedIn)?.value,
    preCheck: items.find((el) => el.label === VehicleStatusEnum.PreCheck)?.value,
    preCheckPassed: items.find((el) => el.label === VehicleStatusEnum.PreCheckPassed)?.value,
    preCheckFailed: items.find((el) => el.label === VehicleStatusEnum.PreCheckFailed)?.value,
    inRepair: items.find((el) => el.label === VehicleStatusEnum.InRepair)?.value,
    allocated: items.find((el) => el.label === VehicleStatusEnum.Allocated)?.value,
    inWorkshop: items.find((el) => el.label === VehicleStatusEnum.InWorkshop)?.value,
    weightCheck: items.find((el) => el.label === VehicleStatusEnum.WeightCheck)?.value,
    ready: items.find((el) => el.label === VehicleStatusEnum.Ready)?.value,
    postCheck: items.find((el) => el.label === VehicleStatusEnum.PostCheck)?.value,
    postCheckFailed: items.find((el) => el.label === VehicleStatusEnum.PostCheckFailed)?.value,
    awaitingSubcontractorWork: items.find((el) => el.label === VehicleStatusEnum.AwaitingSubcontractorWork)?.value,
    bookedOut: items.find((el) => el.label === VehicleStatusEnum.BookedOut)?.value,
    jobBlocked: items.find((el) => el.label === VehicleStatusEnum.JobBlocked)?.value,
    jobInProgress: items.find((el) => el.label === VehicleStatusEnum.JobInProgress)?.value,
    handedOver: items.find((el) => el.label === VehicleStatusEnum.HandedOver)?.value,
  };
});

const partRequestSubmissionStatusesInitSelector = (state: RootState) => state.coreStatuses.requestSubmissionStatuses;
export const partRequestSubmissionStatusesUiSelector = createSelector(partRequestSubmissionStatusesInitSelector, (statuses) => {
  return setUiNamesHandler(statuses, PartRequestSubmissionStatusesEnum);
});

const leaveRequestStatusesStatusesSelector = (state: RootState) => state.coreStatuses.leaveRequestStatuses;
export const leaveRequestStatusesUiSelector = createSelector(leaveRequestStatusesStatusesSelector, (statuses) => {
  return setUiNamesHandler(statuses, LeaveRequestsStatusesUiEnum);
});
export const leaveRequestsDraftStatusSelector = createSelector(leaveRequestStatusesUiSelector, (statuses) => {
  return statuses.find((status) => status.label === LeaveRequestsStatusesUiEnum.Draft)?.value;
});
export const leaveRequestsPendingApprovalStatusSelector = createSelector(leaveRequestStatusesUiSelector, (statuses) => {
  return statuses.find((status) => status.label === LeaveRequestsStatusesUiEnum.PendingApproval)?.value;
});
export const leaveRequestsApprovedStatusSelector = createSelector(leaveRequestStatusesUiSelector, (statuses) => {
  return statuses.find((status) => status.label === LeaveRequestsStatusesUiEnum.Approved)?.value;
});
export const leaveRequestsRejectedStatusSelector = createSelector(leaveRequestStatusesUiSelector, (statuses) => {
  return statuses.find((status) => status.label === LeaveRequestsStatusesUiEnum.Rejected)?.value;
});

const partRequestStatusesInitSelector = (state: RootState) => state.coreStatuses.partRequestStatuses;
export const partRequestStatusesUiSelector = createSelector(partRequestStatusesInitSelector, (statuses) => {
  return setUiNamesHandler(statuses, StockPartReqStatusesEnum);
});

export const partRequestStatusObjSelector = createSelector(partRequestStatusesUiSelector, (statuses) => {
  const statusNew = statuses.find((el) => el.label === StockPartReqStatusesEnum.New)?.value;
  const inProgress = statuses.find((el) => el.label === StockPartReqStatusesEnum.InProgress)?.value;
  const collected = statuses.find((el) => el.label === StockPartReqStatusesEnum.Collected)?.value;
  const archived = statuses.find((el) => el.label === StockPartReqStatusesEnum.Archived)?.value;

  return {
    statusNew, inProgress, collected, archived,
  };
});

const purchaseOrderStatusOriginInitSelector = (state: RootState) => state.coreStatuses.purchaseOrderStatus;
export const purchaseOrderStatusOriginSelector = createSelector(purchaseOrderStatusOriginInitSelector, (list) => {
  return setUiNamesHandler(list, PurchaseOrderStatusUiNames);
});

export const purchaseOrderStatusObjSelector = createSelector(purchaseOrderStatusOriginSelector, (statuses) => {
  const statusNew = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.New)?.value;
  const finalised = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.Finalised)?.value;
  const sentToSupplier = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.SentToSupplier)?.value;
  const partiallyReceived = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.PartiallyReceived)?.value;
  const received = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.Received)?.value;
  const completed = statuses.find((st) => st.label === PurchaseOrderStatusUiNames.Completed)?.value;

  return {
    statusNew, finalised, sentToSupplier, partiallyReceived, received, completed,
  };
});

const qtyStatusesInitSelector = (state: RootState) => state.coreStatuses.qtyStatuses;
export const qtyStatusesSelector = createSelector(qtyStatusesInitSelector, (list) => {
  return setUiNamesHandler(list, PartQtyStatusEnum);
});

export const partQtyStatusesValuesSelector = createSelector(qtyStatusesSelector, (statuses) => {
  return {
    free: statuses.find((st) => st.label === PartQtyStatusEnum.Free)?.value,
    allocated: statuses.find((st) => st.label === PartQtyStatusEnum.Allocated)?.value,
    picked: statuses.find((st) => st.label === PartQtyStatusEnum.Picked)?.value,
    collected: statuses.find((st) => st.label === PartQtyStatusEnum.Collected)?.value,
    reserved: statuses.find((st) => st.label === PartQtyStatusEnum.Reserved)?.value,
    archived: statuses.find((st) => st.label === PartQtyStatusEnum.Archived)?.value,
  };
});

const stockPartStatusesInitSelector = (state: RootState) => state.coreStatuses.stockPartStatuses;
export const stockPartStatusesSelector = createSelector(stockPartStatusesInitSelector, (statuses) => {
  return setUiNamesHandler(statuses, StockPartStatusesEnum);
});
export const stockPartStatusesValuesSelector = createSelector(stockPartStatusesSelector, (statuses) => ({
  inStock: statuses.find((status) => status.label === StockPartStatusesEnum.InStock)?.value,
  outOfStock: statuses.find((status) => status.label === StockPartStatusesEnum.OutOfStock)?.value,
}));

const stockPartRequestLineStatusTransitionInitSelector = (state: RootState) => {
  return state.coreStatuses.stockPartRequestLineStatusTransition;
};

export const stockPartRequestLineStatusTransitionValuesSelector = createSelector(
  stockPartRequestLineStatusTransitionInitSelector,
  (statuses) => {
    return {
      notFromStock: statuses.find((el) => el.label === PartRequestLineStatusTransitionEnum.NotForStock)?.value,
      confirm: statuses.find((el) => el.label === PartRequestLineStatusTransitionEnum.Confirm)?.value,
    };
  },
);

export const invoiceDocumentStatusesSelector = (state: RootState) => state.coreStatuses.invoiceDocumentStatuses;
export const invoiceDocumentStatusValuesSelector = createSelector(invoiceDocumentStatusesSelector, (statuses) => {
  return {
    draft: statuses.find((el) => el.label === InvoiceDocumentStatusesEnum.Draft)?.value,
    exported: statuses.find((el) => el.label === InvoiceDocumentStatusesEnum.Exported)?.value,
  };
});

export const supplierStatusesSelector = (state: RootState) => state.coreStatuses.supplierStatuses;
export const verifiedSupplierStatusSelector = createSelector(supplierStatusesSelector, (items) => {
  return items.find((el) => el.label === SupplierStatusUiNames.Verified);
});
export const unverifiedSupplierStatusSelector = createSelector(supplierStatusesSelector, (items) => {
  return items.find((el) => el.label === SupplierStatusUiNames.Unverified);
});

const initJobQualityCheckStatusesSelector = (state: RootState) => state.coreStatuses.jobQualityCheckStatuses;
export const jobQualityCheckStatusesSelector = createSelector(initJobQualityCheckStatusesSelector, (values) => {
  return setUiNamesHandler(values, JobCheckStatusesEnum);
});

export const jobQualityCheckDecisionSelector = createSelector(initJobQualityCheckStatusesSelector, (values) => {
  return setUiNamesHandler(values, JobCheckDecision);
});
