import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export const NotesSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};

export type FullCheckFields = {
  mileageMiles: string,
  fuelLevelPercent: number,
  weatherConditions: number | undefined,
  isExteriorClean: number | undefined,
  isInteriorClean: number | undefined,
  numberOfVanKeys: string,
  hasTools: number | undefined,
  hasSpareWheel: number | undefined,
  notes: string | null,
  fullVehicleCheckDecision: number | undefined,
}

export const fullCheckDefaults: FullCheckFields = {
  mileageMiles: '',
  fuelLevelPercent: 0,
  weatherConditions: undefined,
  isExteriorClean: undefined,
  isInteriorClean: undefined,
  numberOfVanKeys: '',
  hasTools: undefined,
  hasSpareWheel: undefined,
  notes: '',
  fullVehicleCheckDecision: undefined,
};
