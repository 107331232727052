import { z } from 'zod';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { ACCOUNT_NO_REGEX } from '../../../core/utils/regex';
import { RequisiteDto } from '../../../common/types/commonTypes';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

const NameFieldSchema = RequiredStringSchema.trim()
  .min(1, ErrorsEnum.REQUIRED)
  .max(250, maxLengthMessageHandle(250));

const SupplierAccountNumberSchema = RequiredStringSchema.trim()
  .min(4, rangeLengthMessageHandle([4, 6]))
  .max(6, rangeLengthMessageHandle([4, 6]))
  .regex(
    ACCOUNT_NO_REGEX,
    ErrorsEnum.ONLY_NUMBERS_LETTERS,
  );

const VatNumberSchema = z.nullable(z.string()
  .min(8, rangeLengthMessageHandle([8, 12]))
  .max(12, rangeLengthMessageHandle([8, 12]))
  .regex(
    ACCOUNT_NO_REGEX,
    ErrorsEnum.ONLY_NUMBERS_LETTERS,
  ));

const CompanyRegistrationNumberSchema = z.nullable(z.string()
  .min(8, rangeLengthMessageHandle([8, 12]))
  .max(10, rangeLengthMessageHandle([8, 10]))
  .regex(
    ACCOUNT_NO_REGEX,
    ErrorsEnum.ONLY_NUMBERS_LETTERS,
  ));

export const SupplierSchema = z.object({
  name: NameFieldSchema,
  supplierAccountNumber: SupplierAccountNumberSchema,
  vatNumber: VatNumberSchema,
  companyRegistrationNumber: CompanyRegistrationNumberSchema,
});

export type SupplierFields = z.infer<typeof SupplierSchema>;

export const supplierDefaults: SupplierFields = {
  name: '',
  supplierAccountNumber: '',
  vatNumber: null,
  companyRegistrationNumber: null,
};
export const CreateSupplierSchema = SupplierSchema.pick({
  name: true,
  supplierAccountNumber: true,
  vatNumber: true,
  companyRegistrationNumber: true,
});

export type CreateSupplierRequest = z.infer<typeof CreateSupplierSchema> & { billingDetails: RequisiteDto };
export type PatchSupplierRestModel = Partial<SupplierFields & { billingRequisite: RequisiteDto }>;
