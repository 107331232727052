import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetVehiclesResponse,
  initVehiclesDashboardFilters,
  VehiclesDashboardFilters,
} from '../../vehicles/dashboard/types/VehiclesDashdoardTypes';
import {
  AssigneeDto,
  CoreAttachmentsFilters, CoreAttachmentsList,
  DataListType, EntityLinkType, GetAttachmentsResponse, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters, initLinksFilters,
  initPaging,
  initSmallPaging,
  LinksFiltersType,
  ResponseResult,
  ResponseSingleResult, VehicleDto,
} from '../../core/types/coreTypes';
import { getVehiclesListThunk } from '../thunks/vehicles/vehiclesDashboardThunks';
import {
  GetVehicleProfilesResponse, GetVehicleProfilesDictionaryResponse, VehicleProfileDictionaryDto,
  VehicleProfilesDataList,
  VehicleProfilesFilters,
} from '../../vehicles/vehicle-profiles/types/vehicleProfilesTypes';
import { CustomerDto } from '../../customers/customers-dashboard/types/customersDashboardTypes';
import { getCustomersForVehicleCreation } from '../thunks/vehicles/createVehicleThunks';
import {
  ExtendedTimelineFilters,
  initExtendedTimelineFilters,
  initVehProfilesList,
} from '../../common/types/commonTypes';
import { getVehicleProfiles, getVehicleProfilesDictionary } from '../thunks/vehicles/vehiclesProfilesThunk';
import { GetVehicleResponse, VehicleActivityEventDto } from '../../vehicles/vehicle-view-page/types/VehicleViewTypes';
import { getVehicleAttachmentsThunk } from '../thunks/vehicles/vehicleAttachmentsThunks';
import {
  getVehicleActivityLog,
  getVehicleByIdThunk,
  getVehicleLinksThunk,
} from '../thunks/vehicles/vehicleViewPageThunks';
import {
  VehicleChecksFilters,
  VehicleCheckDto, GetVehicleCheckResponse, MarkersList,
} from '../../vehicles/vehicle-view-page/components/vehicle-content/vehicle-inspection/types/VehicleInspectionTypes';
import {
  getVehicleCheckAttachmentsListThunk,
  getVehicleCheckByIdThunk,
  getVehicleChecksThunk,
} from '../thunks/vehicles/VehicleInspectionThunks';

interface IVehiclesState {
  vehiclesDashboardFilters: VehiclesDashboardFilters,
  vehiclesDashboardAssignees: AssigneeDto[],
  vehiclesListUniqueStatuses: number[],
  vehiclesList: DataListType<VehicleDto[]>,
  vehicleProfilesFilters: VehicleProfilesFilters,
  vehicleDetails: VehicleDto | null,
  vehicleAttachments: CoreAttachmentsList,
  vehicleAttachmentsFilters: CoreAttachmentsFilters,
  createVehicleProfilesFilters: VehicleProfilesFilters,
  vehiclesCustomersList: DataListType<CustomerDto[]>,
  vehiclesProfiles: VehicleProfilesDataList,
  vehicleProfilesDictionary: VehicleProfileDictionaryDto[],
  vehicleChecksFilters: VehicleChecksFilters,
  vehicleChecks: DataListType<VehicleCheckDto[]>,
  vehicleLinksFilters: LinksFiltersType,
  vehicleLinks: EntityLinkType[],
  vehicleCheckDetails: VehicleCheckDto | null,
  vehicleCheckSchemaUrl: string,
  vehicleCheckAttachments: CoreAttachmentsList,
  vehicleTimelineFilters: ExtendedTimelineFilters,
  vehicleTimeline: DataListType<VehicleActivityEventDto[]>,
  markersList: MarkersList,
}

export const initialVehiclesState: IVehiclesState = {
  vehiclesDashboardFilters: initVehiclesDashboardFilters,
  vehiclesDashboardAssignees: [],
  vehiclesListUniqueStatuses: [],
  vehiclesList: initDataList,
  createVehicleProfilesFilters: initSmallPaging,
  vehicleProfilesFilters: initPaging,
  vehicleDetails: null,
  vehicleCheckSchemaUrl: '',
  vehicleAttachments: initDataListWithIds,
  vehicleAttachmentsFilters: initialCoreAttachmentsFilters,
  vehiclesCustomersList: initDataList,
  vehiclesProfiles: initVehProfilesList,
  vehicleProfilesDictionary: [],
  vehicleChecksFilters: initPaging,
  vehicleChecks: initDataList,
  vehicleLinksFilters: initLinksFilters,
  vehicleLinks: [],
  vehicleCheckDetails: null,
  vehicleCheckAttachments: initDataListWithIds,
  vehicleTimelineFilters: initExtendedTimelineFilters,
  vehicleTimeline: initDataList,
  markersList: [],
};

export const vehiclesSlice = createSlice({
  name: 'vehicles',
  initialState: initialVehiclesState,
  reducers: {
    setVehiclesDashboardFilters: (state, action: PayloadAction<VehiclesDashboardFilters>) => {
      state.vehiclesDashboardFilters = action.payload;
    },
    setVehiclesList: (state, action: PayloadAction<DataListType<VehicleDto[]>>) => {
      state.vehiclesList = action.payload;
    },
    setVehiclesListUniqueStatuses: (state, action: PayloadAction<number[]>) => {
      state.vehiclesListUniqueStatuses = action.payload;
    },
    setVehicleAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.vehicleAttachments = action.payload;
    },
    setVehicleAttachmentsFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.vehicleAttachmentsFilters = action.payload;
    },
    setVehicleProfilesFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.vehicleProfilesFilters = action.payload;
    },
    setCreateVehicleProfilesFilters: (state, action: PayloadAction<VehicleProfilesFilters>) => {
      state.createVehicleProfilesFilters = action.payload;
    },
    setVehicleCustomers: (state, action: PayloadAction<DataListType<CustomerDto[]>>) => {
      state.vehiclesCustomersList = action.payload;
    },
    setVehicleProfiles: (state, action: PayloadAction<VehicleProfilesDataList>) => {
      state.vehiclesProfiles = action.payload;
    },
    setVehicleDetails: (state, action: PayloadAction<VehicleDto | null>) => {
      state.vehicleDetails = action.payload;
    },
    setVehicleChecksFilters: (state, action: PayloadAction<VehicleChecksFilters>) => {
      state.vehicleChecksFilters = action.payload;
    },
    setVehicleChecks: (state, action: PayloadAction<DataListType<VehicleCheckDto[]>>) => {
      state.vehicleChecks = action.payload;
    },
    setVehicleLinksFilters: (state, action: PayloadAction<LinksFiltersType>) => {
      state.vehicleLinksFilters = action.payload;
    },
    setVehicleLinks: (state, action: PayloadAction<EntityLinkType[]>) => {
      state.vehicleLinks = action.payload;
    },
    setVehicleCheckDetails: (state, action: PayloadAction<VehicleCheckDto | null>) => {
      state.vehicleCheckDetails = action.payload;
    },
    setVehicleCheckSchemaUrl: (state, action: PayloadAction<string>) => {
      state.vehicleCheckSchemaUrl = action.payload;
    },
    setVehicleCheckAttachments: (state, action: PayloadAction<CoreAttachmentsList>) => {
      state.vehicleCheckAttachments = action.payload;
    },
    setVehicleTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.vehicleTimelineFilters = action.payload;
    },
    setVehicleTimeline: (state, action: PayloadAction<DataListType<VehicleActivityEventDto[]>>) => {
      state.vehicleTimeline = action.payload;
    },
    setMarkersList: (state, action: PayloadAction<MarkersList>) => {
      state.markersList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getVehiclesListThunk.fulfilled, (
      state,
      action: PayloadAction<GetVehiclesResponse>,
    ) => {
      const { assignees, statuses, ...rest } = action.payload.data;
      state.vehiclesDashboardAssignees = assignees || [];
      state.vehiclesListUniqueStatuses = statuses || [];
      state.vehiclesList = rest;
    });
    builder.addCase(
      getCustomersForVehicleCreation.fulfilled,
      (state, action: PayloadAction<ResponseResult<CustomerDto[]> | null>) => {
        state.vehiclesCustomersList = action.payload ? action.payload?.data : initDataList;
      },
    );
    builder.addCase(
      getVehicleProfiles.fulfilled,
      (state, action: PayloadAction<GetVehicleProfilesResponse | null>) => {
        state.vehiclesProfiles = action.payload ? action.payload?.data : initVehProfilesList;
      },
    );
    builder.addCase(
      getVehicleProfilesDictionary.fulfilled,
      (state, action: PayloadAction<GetVehicleProfilesDictionaryResponse>) => {
        state.vehicleProfilesDictionary = action.payload.data.items;
      },
    );
    builder.addCase(
      getVehicleByIdThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetVehicleResponse>>) => {
        state.vehicleDetails = action.payload.data.vehicle;
      },
    );
    builder.addCase(
      getVehicleAttachmentsThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.vehicleAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getVehicleLinksThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<{ links: EntityLinkType[] | null }>>) => {
        state.vehicleLinks = action.payload.data.links || [];
      },
    );
    builder.addCase(
      getVehicleChecksThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<VehicleCheckDto[]>>) => {
        state.vehicleChecks = action.payload.data;
      },
    );
    builder.addCase(
      getVehicleCheckByIdThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<GetVehicleCheckResponse>>) => {
        state.vehicleCheckDetails = action.payload.data.vehicleCheck;
        state.vehicleCheckSchemaUrl = action.payload.data.visualInspectionImageBase64 || '';
      },
    );
    builder.addCase(
      getVehicleCheckAttachmentsListThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.vehicleCheckAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getVehicleActivityLog.fulfilled,
      (state, action: PayloadAction<ResponseResult<VehicleActivityEventDto[]>>) => {
        state.vehicleTimeline = action.payload.data;
      },
    );
  },
});

// Action creators are generated for each case reducer function
// eslint-disable-next-line no-empty-pattern
export const {
  setVehiclesDashboardFilters,
  setVehiclesList,
  setVehiclesListUniqueStatuses,
  setVehicleAttachments,
  setVehicleAttachmentsFilters,
  setVehicleProfilesFilters,
  setCreateVehicleProfilesFilters,
  setVehicleCustomers,
  setVehicleProfiles,
  setVehicleDetails,
  setVehicleChecksFilters,
  setVehicleChecks,
  setVehicleLinksFilters,
  setVehicleLinks,
  setVehicleCheckDetails,
  setVehicleCheckSchemaUrl,
  setVehicleCheckAttachments,
  setVehicleTimeline,
  setVehicleTimelineFilters,
  setMarkersList,
} = vehiclesSlice.actions;

export default vehiclesSlice.reducer;
