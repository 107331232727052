import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../../store/hooks';
import { RequisiteDto } from '../../../common/types/commonTypes';
import { editEnquiryByIdThunk } from '../../../store/thunks/enquiry/detailsThunks';
import { VoidFunctionType } from '../../../core/types/coreTypes';

export const useEditEnquiryRequisite = (isShippingMode: boolean, onClose: VoidFunctionType) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  return (requisite: RequisiteDto, isManual: boolean, selectedEntityId?: number) => {
    const newValues = isShippingMode
      ? {
        shippingRequisite: isManual ? requisite : null,
        shippingCustomerId: selectedEntityId,
        isShippingDetailsManualInput: isManual,
        isShippingRequisiteSameAsBilling: false,
      }
      : {
        billingRequisite: isManual ? requisite : null,
        billingCustomerId: selectedEntityId,
        isBillingDetailsManualInput: isManual,
      };

    id && dispatch(editEnquiryByIdThunk({
      id: +id,
      newValues,
      onClose,
    }));
  };
};
