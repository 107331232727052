import React from 'react';
import { faCartPlus } from '@fortawesome/free-solid-svg-icons';
import InvoicingPurchaseItem from './InvoicingPurchaseItem';
import { useAppSelector } from '../../../../store/hooks';
import { purchasesListSelector } from '../../../../store/selectors/invoicingSelectors';
import { isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';
import SkeletonLinesList from '../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import InvoicingSkeletonItem from '../../../common/components/invoice-dashboard-skeleton/InvoicingSkeletonItem';
import EmptyContentList from '../../../../core/components/empty-content-list/EmptyContentList';

const InvoicingPurchasesList: React.FC = () => {
  const { totalCount, items } = useAppSelector(purchasesListSelector);
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <InvoicingSkeletonItem />
        </SkeletonLinesList>
        : totalCount === 0
          ? <EmptyContentList text="No purchase orders" icon={faCartPlus} />
          : <ul className="lines-list">
            {items.map((item) => (
              <li key={item.id} className="lines-list__item">
                <InvoicingPurchaseItem item={item} />
              </li>
            ))}
          </ul>}
    </>
  );
};

export default InvoicingPurchasesList;
