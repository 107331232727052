import React from 'react';
import { useFiltersItems } from '../../../../../enquiries/hooks/useFiltersItems';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import { fieldNameEventHandle, getReadableBoolean } from '../../../../../../common/utils/activityLogUtils';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const EnquiryFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const { enquirySources, priorityLevels, jobTypes } = useFiltersItems();

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'EnquirySource':
        return labelHandle(+value, enquirySources);
      case 'PriorityLevel':
        return labelHandle(+value, priorityLevels);
      case 'JobType':
        return labelHandle(+value, jobTypes);
      case 'IsShippingRequisiteSameAsBilling':
        return getReadableBoolean(value);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default EnquiryFieldUpdateEvent;
