import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetRectificationsResponseType,
  IRectificationsFilters, RectificationDto, RectificationSummaryResponse,
} from '../../rectifications/dashboard/types/rectificationsTypes';
import {
  AssigneeDto,
  CollectionColumnPaginationType,
  CoreAttachmentDto, CoreAttachmentsFilters, CoreAttachmentsList, CoreCustomerContactsList,
  DataListType,
  DictionaryItem,
  EntityLinkType,
  GenericColumnType, GetAttachmentsResponse, GetCustomerContactsResponse, infiniteScrollInitPaging, initDataListWithIds,
  initDataList,
  initialCoreAttachmentsFilters,
  initLinksFilters,
  LinksFiltersType,
  PagingType,
  ResponseResult,
  ResponseSingleResult, VehicleDto,
} from '../../core/types/coreTypes';
import {
  getCreateRectificationVehiclesListThunk,

} from '../thunks/rectification/createRectificationThunks';
import {
  getRectificationList,
} from '../thunks/rectification/rectificationsDashThunks';
import {
  getPartsKitsForRectificationThunk,
  getRectificationLinksThunk,
  getRectificationPartRequestLines,
  getRectificationSummaryThunk,
  getRectificationTimelineThunk,
  getStockPartsForRectificationThunk,
} from '../thunks/rectification/rectificationViewPageThunks';
import { getRectificationAttachmentsThunk } from '../thunks/rectification/rectificationAttachmentsThunks';
import {
  RectificationContactDto, RectificationContactsList,
  RectificationEventDtoBase,
  RectificationLinksResType,
} from '../../rectifications/rectification-view-page/types/rectificationViewTypes';
import {
  getGenerateEmailRectificationContactsThunk,
  getRectificationContactsThunk,
  getRectificationExistingContactsThunk,
} from '../thunks/rectification/rectificationContactsThunks';
import { CustomerContactFiltersType } from '../../customers/customer-view-page/types/customerVewPageTypes';
import {
  DraftLinePartList,
  DraftLinePartsFiltersType,
  DraftLinePartsKitFiltersType,
  DraftLinePartsKitList,
  ExtendedTimelineFilters,
  initDraftLinePartsFilters,
  initDraftLinePartsKitFilters,
  initExtendedTimelineFilters,
  PartReqLinesFilters,
  PartRequestLineDto,
} from '../../common/types/commonTypes';
import { PartDtoType } from '../../stock/search/types/stockSearchTypes';
import { PartsKitsDto } from '../../stock/parts-kits/dashboard/types/partsKitsTypes';
import { GetVehiclesResponse } from '../../vehicles/dashboard/types/VehiclesDashdoardTypes';

interface IRectificationState {
  rectificationSummary: RectificationSummaryResponse | null,
  rectificationTimeline: DataListType<RectificationEventDtoBase[]>
  rectificationTimelineFilters: ExtendedTimelineFilters,
  filters: IRectificationsFilters,
  columnsPagination: Array<CollectionColumnPaginationType>,
  rectificationCollections: Array<GenericColumnType<Array<RectificationDto>>> | null,
  rectificationsAssignees: AssigneeDto[],
  rectificationWarnings: DictionaryItem[],
  rectificationAttachments: CoreAttachmentsList,
  rectificationAttachmentsFilters: CoreAttachmentsFilters,
  rectificationLinksFilters: LinksFiltersType,
  rectificationLinks: EntityLinkType[],
  rectificationContacts: RectificationContactsList,
  rectificationContactsPaging: PagingType,
  rectificationGenerateEmailContacts: RectificationContactsList,
  rectificationGenerateEmailContactsPaging: PagingType,
  rectificationExistingContacts: CoreCustomerContactsList,
  rectificationExistingContactsPaging: CustomerContactFiltersType,
  currentPartReqSource?: number,
  partRequestLines: { items: PartRequestLineDto[], allItemIds: number[] },
  partRequestLinesFilters: PartReqLinesFilters | null,
  rectificationPartReqDraftLinesFilters: DraftLinePartsFiltersType,
  rectificationDraftPartRequestParts: DraftLinePartList,
  rectificationDraftPartRequestPartsKit: DraftLinePartsKitList,
  rectificationDraftPartRequestPartsKitFilters: DraftLinePartsKitFiltersType,
  createRectificationVehiclesList: VehicleDto[],
}

export const initialRectificationState: IRectificationState = {
  rectificationSummary: null,
  rectificationTimeline: initDataList,
  rectificationTimelineFilters: initExtendedTimelineFilters,
  filters: infiniteScrollInitPaging,
  columnsPagination: [],
  rectificationCollections: null,
  rectificationsAssignees: [],
  rectificationWarnings: [],
  rectificationAttachments: initDataListWithIds,
  rectificationAttachmentsFilters: initialCoreAttachmentsFilters,
  rectificationLinksFilters: initLinksFilters,
  rectificationLinks: [],
  rectificationContacts: initDataListWithIds,
  rectificationContactsPaging: infiniteScrollInitPaging,
  rectificationGenerateEmailContacts: initDataListWithIds,
  rectificationGenerateEmailContactsPaging: infiniteScrollInitPaging,
  rectificationExistingContacts: initDataListWithIds,
  rectificationExistingContactsPaging: infiniteScrollInitPaging,
  currentPartReqSource: undefined,
  partRequestLines: { items: [], allItemIds: [] },
  partRequestLinesFilters: null,
  rectificationPartReqDraftLinesFilters: initDraftLinePartsFilters,
  rectificationDraftPartRequestParts: initDataList,
  rectificationDraftPartRequestPartsKit: initDataList,
  rectificationDraftPartRequestPartsKitFilters: initDraftLinePartsKitFilters,
  createRectificationVehiclesList: [],
};

export const rectificationsSlice = createSlice({
  name: 'rectifications',
  initialState: initialRectificationState,
  reducers: {
    setRectificationDashFilters: (state, action: PayloadAction<IRectificationsFilters>) => {
      state.filters = action.payload;
    },
    setColumnsPagination: (state, action: PayloadAction<Array<CollectionColumnPaginationType>>) => {
      state.columnsPagination = action.payload;
    },
    setRectificationCollections: (state, action: PayloadAction<Array<GenericColumnType<Array<RectificationDto>>> | null>) => {
      state.rectificationCollections = action.payload;
    },
    setRectificationAttachments: (state, action: PayloadAction<CoreAttachmentDto[]>) => {
      state.rectificationAttachments = { ...state.rectificationAttachments, items: action.payload };
    },
    setRectificationAttachmentsFilters: (state, action: PayloadAction<CoreAttachmentsFilters>) => {
      state.rectificationAttachmentsFilters = action.payload;
    },
    setRectificationLinksFilters: (state, action: PayloadAction<LinksFiltersType>) => {
      state.rectificationLinksFilters = action.payload;
    },
    setRectificationLinks: (state, action: PayloadAction<EntityLinkType[]>) => {
      state.rectificationLinks = action.payload;
    },
    setRectificationContacts: (state, action: PayloadAction<RectificationContactsList>) => {
      state.rectificationContacts = action.payload;
    },
    setRectificationContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.rectificationContactsPaging = action.payload;
    },
    setGenerateEmailRectificationContacts: (state, action: PayloadAction<RectificationContactsList>) => {
      state.rectificationGenerateEmailContacts = action.payload;
    },
    setGenerateEmailRectificationContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.rectificationGenerateEmailContactsPaging = action.payload;
    },
    setRectificationExistingContacts: (state, action: PayloadAction<CoreCustomerContactsList>) => {
      state.rectificationExistingContacts = action.payload;
    },
    setRectificationExistingContactsPaging: (state, action: PayloadAction<PagingType>) => {
      state.rectificationExistingContactsPaging = action.payload;
    },
    setRectificationTimeline: (state, action: PayloadAction<DataListType<RectificationEventDtoBase[]>>) => {
      state.rectificationTimeline = action.payload;
    },
    setRectificationTimelineFilters: (state, action: PayloadAction<ExtendedTimelineFilters>) => {
      state.rectificationTimelineFilters = action.payload;
    },
    setPartRequestLinesFilters: (state, action: PayloadAction<PartReqLinesFilters | null>) => {
      state.partRequestLinesFilters = action.payload;
    },
    setPartRequestLines: (state, action: PayloadAction<{ items: PartRequestLineDto[], allItemIds: number[] }>) => {
      state.partRequestLines = action.payload;
    },
    setRectificationPartReqDraftLinesFilters: (state, action: PayloadAction<DraftLinePartsFiltersType>) => {
      state.rectificationPartReqDraftLinesFilters = action.payload;
    },
    setRectificationPartRequestDraftParts: (state, action: PayloadAction<DraftLinePartList>) => {
      state.rectificationDraftPartRequestParts = action.payload;
    },
    setRectificationPartsKitDraftLinesFilters: (state, action: PayloadAction<DraftLinePartsKitFiltersType>) => {
      state.rectificationDraftPartRequestPartsKitFilters = action.payload;
    },
    setRectificationPartsKitRequestParts: (state, action: PayloadAction<DraftLinePartsKitList>) => {
      state.rectificationDraftPartRequestPartsKit = action.payload;
    },
    setRectificationCurrentPartReqSource: (state, action: PayloadAction<number | undefined>) => {
      state.currentPartReqSource = action.payload;
    },
    setRectificationSummary: (state, action: PayloadAction<RectificationSummaryResponse | null>) => {
      state.rectificationSummary = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getRectificationList.fulfilled, (
      state,
      action: PayloadAction<ResponseSingleResult<GetRectificationsResponseType> | null>,
    ) => {
      const rectificationCollections = action.payload?.data?.rectificationCollections;
      const assignees = action.payload?.data?.assignees;
      const collectionsList = rectificationCollections?.map((el) => ({
        status: el.status,
        filteredTotalCount: el.filteredTotalCount,
        totalCount: el.totalCount,
        totalCost: el.totalCost,
        pages: el.pagesCount,
        isHidden: el.isHidden,
        order: el.ordinalNumber,
        list: el.rectifications,
      }));
      state.rectificationsAssignees = assignees || [];
      state.rectificationCollections = collectionsList || null;
      state.columnsPagination = rectificationCollections?.map((col) => ({
        status: col.status,
        pages: col.pagesCount,
        currentPage: 1,
      })) || [];
    });
    builder.addCase(
      getRectificationAttachmentsThunk.fulfilled,
      (state, action: PayloadAction<GetAttachmentsResponse>) => {
        state.rectificationAttachments = action.payload.data;
      },
    );
    builder.addCase(
      getRectificationLinksThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<RectificationLinksResType>>) => {
        state.rectificationLinks = action.payload.data.links;
      },
    );
    builder.addCase(
      getRectificationContactsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<RectificationContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.rectificationContacts = {
          items: state.rectificationContactsPaging.page > 1
            ? [...state.rectificationContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getRectificationExistingContactsThunk.fulfilled,
      (state, action: PayloadAction<GetCustomerContactsResponse>) => {
        const {
          items, ...rest
        } = action.payload.data;
        state.rectificationExistingContacts = {
          items: state.rectificationExistingContactsPaging.page > 1
            ? [...state.rectificationExistingContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getGenerateEmailRectificationContactsThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<RectificationContactDto[], { allItemIds: number[] }>>) => {
        const { items, ...rest } = action.payload.data;
        state.rectificationGenerateEmailContacts = {
          items: state.rectificationGenerateEmailContactsPaging.page > 1
            ? [...state.rectificationGenerateEmailContacts.items, ...items]
            : items,
          ...rest,
        };
      },
    );
    builder.addCase(
      getRectificationTimelineThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<RectificationEventDtoBase[]>>) => {
        state.rectificationTimeline = action.payload.data;
      },
    );
    builder.addCase(
      getRectificationPartRequestLines.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartRequestLineDto[], { allItemIds: number[] }>>) => {
        state.partRequestLines = action.payload.data;
      },
    );
    builder.addCase(
      getStockPartsForRectificationThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartDtoType[]> | null>) => {
        const data = action.payload?.data;
        if (data) {
          state.rectificationDraftPartRequestParts = data;
        } else {
          state.rectificationDraftPartRequestParts = initDataList;
        }
      },
    );
    builder.addCase(
      getPartsKitsForRectificationThunk.fulfilled,
      (state, action: PayloadAction<ResponseResult<PartsKitsDto[]> | null>) => {
        const data = action.payload?.data;
        if (data) {
          state.rectificationDraftPartRequestPartsKit = data;
        } else {
          state.rectificationDraftPartRequestPartsKit = initDataList;
        }
      },
    );
    builder.addCase(
      getRectificationSummaryThunk.fulfilled,
      (state, action: PayloadAction<ResponseSingleResult<RectificationSummaryResponse> | null>) => {
        state.rectificationSummary = action.payload?.data || null;
      },
    );
    builder.addCase(getCreateRectificationVehiclesListThunk.fulfilled, (
      state,
      action: PayloadAction<GetVehiclesResponse>,
    ) => {
      state.createRectificationVehiclesList = action.payload.data.items;
    });
  },
});

// Action creators are generated for each case reducer function
export const {
  setRectificationDashFilters,
  setColumnsPagination,
  setRectificationCollections,
  setRectificationAttachments,
  setRectificationAttachmentsFilters,
  setRectificationLinksFilters,
  setRectificationLinks,
  setRectificationContacts,
  setRectificationContactsPaging,
  setGenerateEmailRectificationContacts,
  setGenerateEmailRectificationContactsPaging,
  setRectificationExistingContacts,
  setRectificationExistingContactsPaging,
  setRectificationTimeline,
  setRectificationTimelineFilters,
  setPartRequestLinesFilters,
  setPartRequestLines,
  setRectificationPartReqDraftLinesFilters,
  setRectificationPartRequestDraftParts,
  setRectificationPartsKitDraftLinesFilters,
  setRectificationPartsKitRequestParts,
  setRectificationCurrentPartReqSource,
  setRectificationSummary,
} = rectificationsSlice.actions;

export default rectificationsSlice.reducer;
