import React, { useMemo } from 'react';
import { AssigneeChangeNotification } from '../../../account/types/AccountTypes';
import PrivateLineLink from '../../../common/components/private-line-link/PrivateLineLink';
import { PermissionEnum } from '../../enums/dictionariesEnums';
import { routesPath } from '../../enums/pathEnum';
import { inAppNotificationsEntityTypesSelector } from '../../../store/selectors/coreSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentUserSelector, isCurrentRoleAdminSelector } from '../../../store/selectors/accountSelectors';
import { VoidFunctionType } from '../../types/coreTypes';
import { setCurrentServerError } from '../../../store/slices/coreSlice';

type AssigneeChangeMessageProps = {
  message: AssigneeChangeNotification,
  closeDrawer: VoidFunctionType,
}

const AssigneeChangeMessage: React.FC<AssigneeChangeMessageProps> = ({ message, closeDrawer }) => {
  const dispatch = useAppDispatch();
  const inAppNotificationsEntityTypes = useAppSelector(inAppNotificationsEntityTypesSelector);
  const isCurrentRoleAdmin = useAppSelector(isCurrentRoleAdminSelector);
  const currentUser = useAppSelector(currentUserSelector);

  const permissionsToBecomeAdmin = [
    PermissionEnum.JobDashboard,
    PermissionEnum.JobCreateNewJob,
    PermissionEnum.JobCalendar,
    PermissionEnum.JobViewPage,
  ];
  const isUserHasAdminPowers = useMemo(() => {
    const hasAtLeastOnePermission = permissionsToBecomeAdmin.filter((p) => currentUser?.userContextRole?.permissions.includes(p));
    return isCurrentRoleAdmin || (!isCurrentRoleAdmin && hasAtLeastOnePermission.length > 0);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentUser?.userContextRole?.permissions, isCurrentRoleAdmin]);

  const entityPermission = (entityType?: number) => {
    const entityTypeLabel = inAppNotificationsEntityTypes.find((el) => el.value === entityType)?.label;
    switch (entityTypeLabel) {
      case 'Enquiry': {
        return PermissionEnum.EnquiryViewPage;
      }
      case 'Order': {
        return PermissionEnum.OrderViewPage;
      }
      case 'Job': {
        if (isUserHasAdminPowers) {
          return PermissionEnum.JobViewPage;
        } else return PermissionEnum.TestModule;
      }
      case 'Rectification': {
        return PermissionEnum.RectificationViewPage;
      }
      case 'PurchaseOrder': {
        return PermissionEnum.StockPurchaseOrdersViewPage;
      }
      default: return PermissionEnum.TestModule;
    }
  };
  const entityTypeHandle = (entityType?: number) => {
    const entityTypeLabel = inAppNotificationsEntityTypes.find((el) => el.value === entityType)?.label;
    const finalLabel = entityTypeLabel === 'PurchaseOrder' ? 'Purchase order' : entityTypeLabel;
    return finalLabel || entityType;
  };
  const entityLinkHandle = (entityType?: number, entityId?: number) => {
    const entityTypeLabel = inAppNotificationsEntityTypes.find((el) => el.value === entityType)?.label;
    switch (entityTypeLabel) {
      case 'Enquiry': {
        return `/${routesPath.ENQUIRIES}/${entityId}`;
      }
      case 'Order': {
        return `/${routesPath.ORDERS}/${entityId}`;
      }
      case 'Job': {
        return `/${routesPath.JOBS}/${entityId}`;
      }
      case 'Rectification': {
        return `/${routesPath.RECTIFICATIONS}/${entityId}`;
      }
      case 'PurchaseOrder': {
        return `/stock/${routesPath.PURCHASE_ORDER}/${entityId}`;
      }
      default: return '';
    }
  };

  return (
    <span className="messageWrap__text">
      {message.initiator?.firstName || ''}
      {' '}
      {message.initiator?.lastName || ''}
      {' '}
      assigned
      {' '}
      {entityTypeHandle(message.entityType)}
      {' '}
      <PrivateLineLink
        to={entityLinkHandle(message.entityType, message.entityId)}
        permission={message.entityType ? entityPermission(message.entityType) : PermissionEnum.TestModule}
        customClick={() => {
          closeDrawer();
          dispatch(setCurrentServerError(undefined));
        }}
      >
        {message.entityNumber}
      </PrivateLineLink>
      {' '}
      to
      {' '}
      {message.newAssignees && message.newAssignees.length > 0
        ? message.newAssignees.map((el) => `${el.firstName} ${el.lastName}`).join(', ')
        : 'Unassigned'}
    </span>
  );
};

export default AssigneeChangeMessage;
