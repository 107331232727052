export enum StockPartsOrders {
  PartCode = 'PartCode',
  ManufacturerCode = 'ManufacturerCode',
  Name = 'Name',
  Description = 'Description',
  Category = 'Category',
  Subcategory = 'Subcategory',
  UnitOfMeasure = 'UnitOfMeasure',
  Status = 'Status',
  CreatedAt = 'CreatedAt',
  UpdatedAt = 'UpdatedAt',
  CategoryThenSubcategoryThenName = 'CategoryThenSubcategoryThenName',
  WarningReorderShortage = 'WarningReorderShortage',
  CriticalReorderShortage = 'CriticalReorderShortage',
}
