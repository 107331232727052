import { useState } from 'react';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import { utcDateFormatHandler } from '../../../../../core/utils/utcDateFormatHandler';
import { DEFAULT_DATE_FORMAT } from '../../../../../core/utils/regex';
import { addPartQuantities } from '../../../../../store/thunks/stock/stockThunks';
import { useAppDispatch } from '../../../../../store/hooks';

type AdjustQtyFields = {
  quantity: string,
  costPrice: string,
  sellingPrice: string,
  addedDate: string | null,
}

export const useAdjustQtyFooterActions = (currentStatus?: number) => {
  const initAdjustQtyFields = {
    quantity: '',
    costPrice: '',
    sellingPrice: '',
    addedDate: moment().format(DEFAULT_DATE_FORMAT),
  };

  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [fields, setFields] = useState<AdjustQtyFields>(initAdjustQtyFields);
  const setFieldsHandle = (field: string, value: string | undefined) => {
    setFields({ ...fields, [field]: value });
  };
  const disabledAddAction = Object.values(fields).some((el) => !el)
        || +fields.quantity === 0
        || +fields.costPrice === 0
        || +fields.sellingPrice === 0;
  const addQtyHandle = () => {
    if (id && currentStatus) {
      const finalData = {
        quantity: +fields.quantity,
        costPrice: +fields.costPrice,
        sellingPrice: +fields.sellingPrice,
        addedDate: utcDateFormatHandler(DEFAULT_DATE_FORMAT, fields.addedDate as string),
        status: currentStatus,
      };
      dispatch(addPartQuantities({
        partId: +id,
        data: { ...finalData },
        onFinish: () => setFields(initAdjustQtyFields),
      }));
    }
  };

  return {
    fields,
    setFields,
    setFieldsHandle,
    initAdjustQtyFields,
    disabledAddAction,
    addQtyHandle,
  };
};
