import { useEffect, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  fitmentTimeRangesSelector, fitmentTimeReportFiltersSelector,
} from '../../store/selectors/reportsSelectors';
import { RangeTableFiltersType } from '../../core/types/coreTypes';

export const useRectificationReportRanges = () => {
  const filters = useAppSelector(fitmentTimeReportFiltersSelector);
  const {
    spentByAssigneeMinutes, totalSpentMinutes, totalEstimatedMinutes, differenceMinutes,
  } = useAppSelector(fitmentTimeRangesSelector);

  const [rangePatterns, setRangePatterns] = useState({
    spentByAssigneeMinutes: { min: spentByAssigneeMinutes.min, max: spentByAssigneeMinutes.max },
    totalSpentMinutes: { min: totalSpentMinutes.min, max: totalSpentMinutes.max },
    totalEstimatedMinutes: { min: totalEstimatedMinutes.min, max: totalEstimatedMinutes.max },
    differenceMinutes: { min: differenceMinutes.min, max: differenceMinutes.max },
  });

  const [range, setRange] = useState<RangeTableFiltersType>({
    spentByAssigneeMinutes: filters.spentByAssigneeMinutes
      ? [filters.spentByAssigneeMinutes.min.toString(), filters.spentByAssigneeMinutes.max.toString()]
      : [rangePatterns.spentByAssigneeMinutes.min.toString(), rangePatterns.spentByAssigneeMinutes.max.toString()],
    totalSpentMinutes: filters.totalSpentMinutes
      ? [filters.totalSpentMinutes.min.toString(), filters.totalSpentMinutes.max.toString()]
      : [rangePatterns.totalSpentMinutes.min.toString(), rangePatterns.totalSpentMinutes.max.toString()],
    totalEstimatedMinutes: filters.totalEstimatedMinutes
      ? [filters.totalEstimatedMinutes.min.toString(), filters.totalEstimatedMinutes.max.toString()]
      : [rangePatterns.totalEstimatedMinutes.min.toString(), rangePatterns.totalEstimatedMinutes.max.toString()],
    differenceMinutes: filters.differenceMinutes
      ? [filters.differenceMinutes.min.toString(), filters.differenceMinutes.max.toString()]
      : [rangePatterns.differenceMinutes.min.toString(), rangePatterns.differenceMinutes.max.toString()],
  });

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, spentByAssigneeMinutes: { min: spentByAssigneeMinutes.min, max: spentByAssigneeMinutes.max } }));
    setRange((prevState) => (
      { ...prevState, spentByAssigneeMinutes: [spentByAssigneeMinutes.min.toString(), spentByAssigneeMinutes.max.toString()] }));
  }, [spentByAssigneeMinutes.min, spentByAssigneeMinutes.max]);

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, totalSpentMinutes: { min: totalSpentMinutes.min, max: totalSpentMinutes.max } }));
    setRange((prevState) => (
      { ...prevState, totalSpentMinutes: [totalSpentMinutes.min.toString(), totalSpentMinutes.max.toString()] }));
  }, [totalSpentMinutes.min, totalSpentMinutes.max]);

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, totalEstimatedMinutes: { min: totalEstimatedMinutes.min, max: totalEstimatedMinutes.max } }));
    setRange((prevState) => (
      { ...prevState, totalEstimatedMinutes: [totalEstimatedMinutes.min.toString(), totalEstimatedMinutes.max.toString()] }));
  }, [totalEstimatedMinutes.min, totalEstimatedMinutes.max]);

  useEffect(() => {
    setRangePatterns((prevState) => (
      { ...prevState, differenceMinutes: { min: differenceMinutes.min, max: differenceMinutes.max } }));
    setRange((prevState) => (
      { ...prevState, differenceMinutes: [differenceMinutes.min.toString(), differenceMinutes.max.toString()] }));
  }, [differenceMinutes.min, differenceMinutes.max]);

  useEffect(() => {
    if (!filters?.spentByAssigneeMinutes) {
      setRange((prevState) => (
        { ...prevState, spentByAssigneeMinutes: [spentByAssigneeMinutes.min.toString(), spentByAssigneeMinutes.max.toString()] }));
    }
    if (!filters?.totalSpentMinutes) {
      setRange((prevState) => (
        { ...prevState, totalSpentMinutes: [totalSpentMinutes.min.toString(), totalSpentMinutes.max.toString()] }));
    }
    if (!filters?.totalEstimatedMinutes) {
      setRange((prevState) => (
        { ...prevState, totalEstimatedMinutes: [totalEstimatedMinutes.min.toString(), totalEstimatedMinutes.max.toString()] }));
    }
    if (!filters?.differenceMinutes) {
      setRange((prevState) => (
        { ...prevState, differenceMinutes: [differenceMinutes.min.toString(), differenceMinutes.max.toString()] }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters.spentByAssigneeMinutes, filters.totalSpentMinutes, filters.totalEstimatedMinutes, filters.differenceMinutes]);

  return { range, setRange, rangePatterns };
};
