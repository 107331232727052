import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { LATIN_LETTERS_NUMBERS_SPACES_SPECIAL_CHARS, NO_SPACES_REGEX } from '../../../core/utils/regex';
import { maxLengthMessageHandle, rangeLengthMessageHandle } from '../../../core/utils/errorMessageHandle';
import { CreateVehicleProfileRequest } from '../types/vehicleProfilesTypes';

type VehicleProfilePickedFieldsSet = Pick<CreateVehicleProfileRequest, 'code' | 'manufacturer' | 'modelDescription'>;

export type CreateVehicleProfileFields = VehicleProfilePickedFieldsSet & {
  type?: number,
  groupId?: number,
  grossVehicleWeightKg: string,
  maxPayload: string,
}

export const CreateVehicleProfileDefaults: CreateVehicleProfileFields = {
  code: '',
  manufacturer: '',
  modelDescription: '',
  type: undefined,
  groupId: undefined,
  grossVehicleWeightKg: '0',
  maxPayload: '0',
};

export const CodeFieldsValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  pattern: { value: NO_SPACES_REGEX, message: ErrorsEnum.SPACES_ARE_NOT_ALLOWED },
  maxLength: { value: 20, message: rangeLengthMessageHandle([6, 20]) },
  minLength: { value: 6, message: rangeLengthMessageHandle([6, 20]) },
};

export const ManufacturerFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 50, message: maxLengthMessageHandle(50) },
  pattern: { value: LATIN_LETTERS_NUMBERS_SPACES_SPECIAL_CHARS, message: ErrorsEnum.NO_NON_LATIN_LETTERS },
};

export const ModelDescriptionFieldValidationSchema = {
  required: ErrorsEnum.REQUIRED,
  maxLength: { value: 200, message: rangeLengthMessageHandle([5, 200]) },
  minLength: { value: 5, message: rangeLengthMessageHandle([5, 200]) },
};
