import React from 'react';
import {
  Control, Controller, FieldErrors, FieldValues, Path,
} from 'react-hook-form';
import { RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import RegularInputNumber from '../../../core/components/input-number/RegularInputNumber';
import Select from '../../../core/components/select/Select';
import { YesNoOptions } from '../../../core/utils/testData';

type VehicleEquipmentProps<T extends FieldValues> = {
  control: Control<T>,
  errors: FieldErrors<T>,
  disabled?: boolean,
}

const VehicleEquipment = <T extends FieldValues>({ control, errors, disabled }: VehicleEquipmentProps<T>) => {
  return (
    <>
      <section className="details-form vehicleCheck-section">
        <h2 className="info-grid__title">Vehicle equipment</h2>
        <Controller
          name={'numberOfVanKeys' as Path<T>}
          control={control}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <RegularInputNumber
              value={field.value}
              onChange={(e) => {
                const v = e.target.value;
                field.onChange(v);
              }}
              max={10}
              error={errors.numberOfVanKeys?.message}
              label="Number of van keys"
              disabled={disabled}
            />
          )}
        />
        <Controller
          control={control}
          name={'hasTools' as Path<T>}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Tools"
              options={YesNoOptions}
              error={errors.hasTools?.message}
              disabled={disabled}
            />
          )}
        />
        <Controller
          control={control}
          name={'hasSpareWheel' as Path<T>}
          rules={RequiredFieldSchema}
          render={({ field }) => (
            <Select
              value={field.value}
              onChange={(val) => field.onChange(val)}
              label="Spare wheel"
              options={YesNoOptions}
              error={errors.hasSpareWheel?.message}
              disabled={disabled}
            />
          )}
        />
      </section>
    </>
  );
};

export default VehicleEquipment;
