import { DictionaryItem } from '../../../core/types/coreTypes';
import { CustomerTypeEnum } from '../../../core/enums/dictionariesEnums';

export const customerTypeDetailsFn = (customerTypes: Array<DictionaryItem>, type?: number) => {
  const currentValue = customerTypes.find((c) => c.value === type);
  if (currentValue?.label === CustomerTypeEnum.INDIVIDUAL) {
    return CustomerTypeEnum.INDIVIDUAL.toUpperCase();
  } else {
    return CustomerTypeEnum.CORPORATE.toUpperCase();
  }
};
