import React from 'react';
import { faCubes } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { vehicleCheckAttachmentsSelector } from '../../../store/selectors/vehiclesSelectors';
import EmptyContentList from '../../../core/components/empty-content-list/EmptyContentList';
import { getVehicleCheckAttachmentByIdThunk } from '../../../store/thunks/vehicles/VehicleInspectionThunks';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';

const AdditionalInfoAttachmentsSection: React.FC = () => {
  const { id, checkId } = useParams();
  const dispatch = useAppDispatch();
  const { items } = useAppSelector(vehicleCheckAttachmentsSelector);
  const downloadFile = (attachId: number) => {
    id && checkId && dispatch(getVehicleCheckAttachmentByIdThunk({
      vehicleId: +id,
      vehicleCheckId: +checkId,
      attachId,
    }));
  };

  return (
    <div className="details-form__field--lg additionalInfoAttachments">
      <span className="additionalInfoAttachments__label">Attachments</span>
      {items.length > 0
        ? <ul className="additionalInfoAttachments__list">
          {items.map((el) => <li
            className="additionalInfoAttachments__item"
            key={el.id}
          >
            <div
              onClick={() => downloadFile(el.id)}
              role="button"
              tabIndex={0}
              className="additionalInfoAttachments__attach"
            >
              <TruncateTooltip value={el.name} table />
            </div>
          </li>)}
        </ul>
        : <EmptyContentList
          size="sm"
          text="No records"
          icon={faCubes}
          offsetTop={false}
        />}
    </div>
  );
};

export default AdditionalInfoAttachmentsSection;
