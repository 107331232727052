import React from 'react';
import classNames from 'classnames';
import { StockFiltersType } from '../../types/stockSearchTypes';
import { useAppDispatch } from '../../../../store/hooks';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import {
  initDataList,
  KeywordFilterTreeType,
  KeywordFilterType,
  SearchTableFilterType,
  VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setStockSearchResult } from '../../../../store/slices/stockSlice';

type SearchAdvancedFiltersProps = {
  filters: StockFiltersType,
  filtersList: KeywordFilterType[],
  onClear: VoidFunctionType,
  initDropdownSearch: SearchTableFilterType,
  getFilteredResHandle: (filters: StockFiltersType) => void,
  dropdownSearchValue?:SearchTableFilterType,
  setDropdownSearchValue?:(value: SearchTableFilterType) => void,
  isAdvanced: boolean,
  treeFiltersList: KeywordFilterTreeType,
}

const SearchAdvancedFilters: React.FC<SearchAdvancedFiltersProps> = ({
  filters,
  filtersList,
  onClear,
  initDropdownSearch,
  getFilteredResHandle,
  dropdownSearchValue,
  setDropdownSearchValue,
  isAdvanced,
  treeFiltersList,
}) => {
  const dispatch = useAppDispatch();
  const treeChangeHandle = (checkedKeys: React.Key[]) => {
    const checkedSubcategories = checkedKeys.filter((el) => !el.toString().includes('category'));
    const values = checkedSubcategories.map((s) => +s);
    if (values.length) {
      getFilteredResHandle({ ...filters, subcategoryIds: values });
    } else {
      getFilteredResHandle({ ...filters, subcategoryIds: undefined });
      dispatch(setStockSearchResult(initDataList));
    }
  };

  return (
    <KeywordFiltersSection
      filters={filtersList}
      treeFilters={treeFiltersList}
      treeChangeHandle={treeChangeHandle}
      initDropdownSearch={initDropdownSearch}
      onClear={onClear}
      isSearch={false}
      getFilteredResHandle={(checkedValues, fieldName) => getFilteredResHandle({
        ...filters,
        [fieldName]: checkedValues,
        page: 1,
      })}
      className={classNames('stockSearch__advanced', { 'advancedHidden': !isAdvanced })}
      dropdownSearchValue={dropdownSearchValue}
      setDropdownSearchValue={setDropdownSearchValue}
      isFullWidth
    />
  );
};

export default SearchAdvancedFilters;
