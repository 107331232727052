import React from 'react';
import { NumberFunctionType, VoidFunctionType } from '../../../core/types/coreTypes';
import { ICustomColumn, PartReqLineUIType, PartRequestLineDto } from '../../types/commonTypes';
import PartRequestLine from './PartRequestLine';
import { PermissionEnum } from '../../../core/enums/dictionariesEnums';
import PartReqChildLine from './PartReqChildLine';

type PartRequestBodyCellsProps = {
  checkedKeys: number[],
  checkRows: NumberFunctionType,
  columns: ICustomColumn[],
  editQty: (id: number, isChildLine?: boolean) => void,
  openCustomModal: NumberFunctionType,
  openDeleteModal: NumberFunctionType,
  openMarkAsFree: NumberFunctionType,
  isLineActionDisabled: (v: number, isChildLine?: boolean) => boolean,
  isPartsKitLineHasAllocatedChild: (v: number) => boolean,
  editInlineThunkHandle: (
    data: {[p: string]: string | number} | {notes: string},
    lineId: number,
    setFieldToEdit: VoidFunctionType,
  ) => void,
  permissionToManageLines: PermissionEnum,
  permissionToManageCharge: PermissionEnum,
  linesArr: PartReqLineUIType[],
  initLinesList: PartRequestLineDto[],
  openReadyCollectedListModal?: NumberFunctionType,
}

const PartRequestBodyCells: React.FC<PartRequestBodyCellsProps> = ({
  checkedKeys, checkRows, openCustomModal, columns, editQty,
  openDeleteModal, openMarkAsFree, isLineActionDisabled, editInlineThunkHandle, openReadyCollectedListModal,
  permissionToManageCharge, permissionToManageLines, linesArr, initLinesList, isPartsKitLineHasAllocatedChild,
}) => {
  return (
    <>
      {linesArr.map((line, i) => <>
        <PartRequestLine
          key={i}
          line={line}
          checkedKeys={checkedKeys}
          checkRows={checkRows}
          openCustomModal={openCustomModal}
          columns={columns}
          editQty={editQty}
          openDeleteModal={openDeleteModal}
          openMarkAsFree={openMarkAsFree}
          isLineActionDisabled={isLineActionDisabled}
          isPartsKitLineHasAllocatedChild={isPartsKitLineHasAllocatedChild}
          initLinesList={initLinesList}
          permissionToManageLines={permissionToManageLines}
          permissionToManageCharge={permissionToManageCharge}
          editInlineThunkHandle={editInlineThunkHandle}
          openReadyCollectedListModal={openReadyCollectedListModal}
        />
        {line.childLines && line.childLines.length > 0
          && line.childLines.map((ch, i) => <PartReqChildLine
            key={i}
            childLine={ch}
            columns={columns}
            editQty={editQty}
            isLineActionDisabled={isLineActionDisabled}
            permissionToManageLines={permissionToManageLines}
            permissionToManageCharge={permissionToManageCharge}
            editThunkHandle={editInlineThunkHandle}
            initLinesList={initLinesList}
            openReadyCollectedListModal={openReadyCollectedListModal}
          />)}
      </>)}
    </>
  );
};

export default PartRequestBodyCells;
