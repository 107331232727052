import React from 'react';
import { PartsKitsDto } from '../types/partsKitsTypes';
import PartsKitsItem from './PartsKitsItem';
import { useAppSelector } from '../../../../store/hooks';
import { isDashboardLoadingSelector } from '../../../../store/selectors/coreSelectors';
import SkeletonLinesList from '../../../../common/components/skeletons/skeleton-lines-list/SkeletonLinesList';
import PartsKitsSkeletonItem from './parts-kits-skeleton/PartsKitsSkeletonItem';

type PartsKitsLinesProps = {
  partsKitsList: {items: PartsKitsDto[], pages: number, totalCount: number};
}

const PartsKitsLines: React.FC<PartsKitsLinesProps> = ({ partsKitsList }) => {
  const isLoading = useAppSelector(isDashboardLoadingSelector);

  return (
    <>
      {isLoading
        ? <SkeletonLinesList>
          <PartsKitsSkeletonItem />
        </SkeletonLinesList>
        : <ul className="lines-list">
          {partsKitsList.items.map((item) => (
            <li key={item.id} className="lines-list__item">
              <PartsKitsItem item={item} />
            </li>
          ))}
        </ul>}
    </>

  );
};

export default PartsKitsLines;
