import React, { useEffect } from 'react';
import Button from '../../../core/components/button/Button';
import { QuoteInfoSourcesUiNames } from '../../../core/enums/dictionariesEnums';
import Modal from '../../../core/components/modal/Modal';
import { DictionaryItem, VoidFunctionType } from '../../../core/types/coreTypes';
import PartsMode from './PartsMode';
import {
  CustomLinePartDto, CustomLineReqType,
  DraftLinePartList,
  DraftLinePartsFiltersType,
  DraftLinePartsKitFiltersType,
  DraftLinePartsKitList,
} from '../../types/commonTypes';
import PartsKitMode from './PartsKitMode';
import CustomMode from './CustomMode';
import './FillInDraftLineModal.scss';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  stockPartsKitsOrderingSelector,
  stockPartsOrderingSelector,
} from '../../../store/selectors/coreOrderingSelectors';
import { getStockPartsKitsOrdering, getStockPartsOrdering } from '../../../store/thunks/core/coreOrderingThunks';

type FillInDraftLineModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  title: string,
  infoSources: DictionaryItem[],
  chooseInfoSource: (v: number | undefined) => void,
  setInitFiltersAndTableData: VoidFunctionType,
  currentInfoSource?: number,
  disabledSourceBtnLabel?: string,
  editMode?: boolean,
  editedLine?: number,
  isCorePart: boolean,
  isNonCorePart: boolean,
  isCustom?: boolean,
  isPartKit?: boolean,
  setProdSourceModal: (id: number, name: string) => void,
  filters: DraftLinePartsFiltersType,
  setFilters: (v: DraftLinePartsFiltersType) => void,
  parts: DraftLinePartList,
  setParts: (v: DraftLinePartList) => void,
  fetchParts: (filters: DraftLinePartsFiltersType, isShow: boolean) => void,
  partsKitFilters?: DraftLinePartsKitFiltersType,
  setPartsKitFilters?: (v: DraftLinePartsKitFiltersType) => void,
  partsKitParts?: DraftLinePartsKitList,
  setPartsKitParts?: (v: DraftLinePartsKitList) => void,
  fetchPartsKitParts?: (filters: DraftLinePartsKitFiltersType, isShow: boolean) => void,
  createPartsKitLineThunk?: (partsKitId: number, closeModal: VoidFunctionType) => void,
  editCustomHandle?: (values: CustomLineReqType, closeModal: VoidFunctionType) => void,
  initCustomLine?: CustomLinePartDto,
  createCustomHandle?: (values: CustomLineReqType, closeModal: VoidFunctionType) => void,
  originName?: string,
  isSuitableForCheckboxShown?: boolean,
  isEnquiry?: boolean,
}

const FillInDraftLineModal: React.FC<FillInDraftLineModalProps> = ({
  isVisible, onCancel, title, infoSources, editedLine,
  chooseInfoSource, setInitFiltersAndTableData, currentInfoSource, setProdSourceModal,
  disabledSourceBtnLabel, editMode, isCustom, isNonCorePart, isCorePart, isPartKit,
  filters, setFilters, parts, setParts, fetchParts, createPartsKitLineThunk,
  partsKitFilters, fetchPartsKitParts, setPartsKitParts, setPartsKitFilters, partsKitParts,
  editCustomHandle, createCustomHandle, initCustomLine, originName, isSuitableForCheckboxShown, isEnquiry,
}) => {
  const dispatch = useAppDispatch();
  const partsKitsOrdering = useAppSelector(stockPartsKitsOrderingSelector);
  const partsOrdering = useAppSelector(stockPartsOrderingSelector);
  useEffect(() => {
    if (isVisible) {
      if (!partsKitsOrdering.length) {
        dispatch(getStockPartsKitsOrdering());
      }
      if (!partsOrdering.length) {
        dispatch(getStockPartsOrdering());
      }
    }
    // eslint-disable-next-line
  }, [isVisible, partsKitsOrdering, partsOrdering]);

  useEffect(() => {
    if (isVisible && infoSources.length > 0 && !currentInfoSource && !editMode) {
      chooseInfoSource(infoSources[0].value);
    }
    // eslint-disable-next-line
  }, [infoSources, currentInfoSource, editMode, isVisible]);

  useEffect(() => {
    if (editMode) {
      const customSource = infoSources?.find((el) => el.label === QuoteInfoSourcesUiNames.Custom);
      customSource && chooseInfoSource(customSource.value);
    }
    // eslint-disable-next-line
  }, [editMode, infoSources]);

  useEffect(() => {
    if (isCorePart) {
      setFilters({ ...filters, isCorePart: [true] });
    }
    // eslint-disable-next-line
  }, [isCorePart]);
  useEffect(() => {
    if (isNonCorePart) {
      setFilters({ ...filters, isCorePart: [false] });
    }
    // eslint-disable-next-line
  }, [isNonCorePart]);

  const closeModal = () => {
    onCancel();
    chooseInfoSource(undefined);
    setInitFiltersAndTableData();
  };
  const createPartsKitLineHandle = (partsKitId: number) => {
    createPartsKitLineThunk && createPartsKitLineThunk(partsKitId, closeModal);
  };
  return (
    <Modal visible={isVisible} onCancel={closeModal} title={title} width={1100} destroyOnClose>
      <div className="partsKit">
        <div className="partsKit__buttons">
          {infoSources?.map((source, i) => <Button
            key={i}
            label={source.label}
            onClick={() => {
              chooseInfoSource(source.value);
              setInitFiltersAndTableData();
            }}
            designType="light-inverse"
            isActivated={currentInfoSource === source.value}
            disabled={editMode && source.label !== disabledSourceBtnLabel}
          />)}
        </div>
        { (isCorePart || isNonCorePart) && <PartsMode
          currentSource={currentInfoSource}
          setProdSourceModal={setProdSourceModal}
          isSuitableForCheckboxShown={isSuitableForCheckboxShown}
          filters={filters}
          setFilters={setFilters}
          parts={parts}
          setParts={setParts}
          fetchParts={fetchParts}
          originName={originName}
        />}
        {isCustom && <CustomMode
          onCancel={closeModal}
          editMode={editMode}
          editedLine={editedLine}
          editHandle={editCustomHandle}
          createHandle={createCustomHandle}
          initLine={initCustomLine}
          isEnquiry={isEnquiry}
        />}
        {isPartKit && <PartsKitMode
          currentSource={currentInfoSource}
          createPartsKitLine={createPartsKitLineHandle}
          partsKitFilters={partsKitFilters}
          setPartsKitFilters={setPartsKitFilters}
          partsKitParts={partsKitParts}
          setPartsKitParts={setPartsKitParts}
          fetchPartsKitParts={fetchPartsKitParts}
          originName={originName}
          isSuitableForCheckboxShown={isSuitableForCheckboxShown}
        />}
      </div>
    </Modal>
  );
};

export default FillInDraftLineModal;
