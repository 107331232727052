import { createAsyncThunk } from '@reduxjs/toolkit';
import { UseFormReset, UseFormSetError } from 'react-hook-form';
import { NavigateFunction } from 'react-router-dom';
import {
  AxiosErrorResponse, ResponseSingleResult,
} from '../../../core/types/coreTypes';
import { setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { routesPath } from '../../../core/enums/pathEnum';
import {
  CreateSupplierResType,
} from '../../../supplier/create-supplier/types/CreateSupplierTypes';
import { SuppliersAPI } from '../../../api/suppliersApi';
import { duplicateServerErrorsHelper } from '../../../core/utils/checkIsDuplicateError';
import { CreateSupplierRequest, SupplierFields } from '../../../supplier/create-supplier/utils/SupplierSchema';

export const createSupplierThunk = createAsyncThunk<ResponseSingleResult<CreateSupplierResType>,
{
  data: CreateSupplierRequest,
  reset: UseFormReset<SupplierFields>,
  navigate: NavigateFunction,
  setError: UseFormSetError<SupplierFields>,
}
>('post/Supplier', async ({
  data, reset, navigate, setError,
}, { dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const response = await SuppliersAPI.createSupplier(data);
    dispatch(setLoading(false));
    const { createdId, createdName } = response.data.data;
    dispatch(setSuccessMessage({ message: `Supplier ${createdName} was successfully created.` }));
    reset();
    navigate(`/${routesPath.SUPPLIERS}/${createdId}`, { replace: true });
    return response.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    const errors = error.response?.data.errors || [];
    duplicateServerErrorsHelper(errors, setError);
    dispatch(setLoading(false));
    return rejectWithValue(error.response?.data);
  }
});
