import React, { useEffect } from 'react';
import {
  Controller, useForm, useFormState,
} from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { DictionaryItem } from '../../../core/types/coreTypes';
import { ErrorsEnum, RequiredFieldSchema } from '../../../core/enums/errorsEnum';
import Input from '../../../core/components/input/Input';
import Select from '../../../core/components/select/Select';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createUserThunk } from '../../../store/thunks/settings/createUserThunk';
import { getAccountPermissionRoles } from '../../../store/thunks/coreThunk';
import { permissionUiRolesSelector } from '../../../store/selectors/coreSelectors';
import { setPermissionRoles } from '../../../store/slices/coreSlice';
import {
  CreateUserFormFields,
  EmailFieldSchema, NameFieldSchema, userDefaults,
} from '../utils/UserShema';
import { YesNoOptions } from '../../../core/utils/testData';

type CreateUSerFormProps = {
  businessAreas: DictionaryItem[],
  accountTypes: DictionaryItem[],
  jobTypes: DictionaryItem[],
}

const CreateUserForm: React.FC<CreateUSerFormProps> = ({
  businessAreas, accountTypes, jobTypes,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const permissionAccountRoles = useAppSelector(permissionUiRolesSelector);
  const {
    control, handleSubmit, reset, setError, setValue, watch,
  } = useForm<CreateUserFormFields>({
    defaultValues: userDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const accountType = watch('accountType');
  const role = watch('role');
  const onSubmit = (values: CreateUserFormFields) => {
    const userRole = values.role?.split('-');
    const finalData = {
      firstName: values.firstName.trim(),
      lastName: values.lastName.trim(),
      email: values.email,
      accountType: values.accountType,
      allowedJobTypes: values.allowedJobTypes,
      allowedBusinessAreas: values.allowedBusinessAreas,
      canSeeOnlyAssignedEntities: values.canSeeOnlyAssignedEntities === 1,
      userAccountRole: {
        role: +(userRole as string[])[0],
        roleLevel: +(userRole as string[])[1],
      },
    };
    userRole && dispatch(createUserThunk({
      data: finalData,
      setError,
      navigate,
    }));
  };

  useEffect(() => {
    if (accountType) {
      dispatch(getAccountPermissionRoles({ accountType: accountType as number }));
      role && setValue('role', '');
    }
    // eslint-disable-next-line
  }, [accountType]);
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="create-form details-form">
      <Controller
        control={control}
        name="firstName"
        rules={NameFieldSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v.trim()) {
                setValue('firstName', '');
                setError('firstName', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.firstName?.message}
            label="First name"
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        rules={NameFieldSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const v = e.target.value;
              if (!v.trim()) {
                setValue('lastName', '');
                setError('lastName', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.lastName?.message}
            label="Last name"
          />
        )}
      />
      <Controller
        control={control}
        name="email"
        rules={EmailFieldSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            error={errors?.email?.message}
            label="Email"
          />
        )}
      />
      <Controller
        control={control}
        name="accountType"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={accountTypes}
            value={field.value as number}
            onChange={field.onChange}
            error={errors.accountType?.message}
            label="Account type"
          />
        )}
      />
      <Controller
        control={control}
        name="role"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={permissionAccountRoles}
            showSearch
            isFilterOption
            error={errors.role?.message}
            label="Role"
            disabled={!accountType}
          />
        )}
      />
      <Controller
        control={control}
        name="allowedJobTypes"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={jobTypes}
            mode="multiple"
            showArrow
            value={field.value}
            onChange={field.onChange}
            error={errors.allowedJobTypes && ErrorsEnum.REQUIRED}
            label="Allowed job types"
          />
        )}
      />
      <Controller
        control={control}
        name="allowedBusinessAreas"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            value={field.value}
            onChange={field.onChange}
            options={businessAreas}
            mode="multiple"
            showArrow
            error={errors.allowedBusinessAreas && ErrorsEnum.REQUIRED}
            label="Allowed business areas"
          />
        )}
      />
      <Controller
        control={control}
        name="canSeeOnlyAssignedEntities"
        rules={RequiredFieldSchema}
        render={({ field }) => (
          <Select
            options={YesNoOptions}
            value={field.value}
            onChange={field.onChange}
            error={errors.canSeeOnlyAssignedEntities?.message}
            label="Can see only assigned entities"
          />
        )}
      />
      <ButtonActions
        createLabel="Send invite"
        cancelLabel="Clear"
        createType="submit"
        cancelClick={() => {
          reset();
          dispatch(setPermissionRoles([]));
        }}
        className="details-form__actions"
      />
    </form>
  );
};

export default CreateUserForm;
