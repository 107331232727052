import { useEffect, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
  employeesFiltersListSelector,
  employeesFiltersSelector,
  employeesLeaveRequestsFiltersSelector,
  employeesLeaveRequestsSearchFiltersSelector,
  employeesWorkCalendarFiltersSelector, employeeWorkCalendarSearchFiltersSelector,
} from '../../../store/selectors/employeesSelector';
import {
  setDashboardFilters,
  setEmployeesLeaveRequestsFilters,
  setEmployeesWorkCalendarFilters,
} from '../../../store/slices/employeesSlice';
import { EmployeesPageParamEnums } from '../enums/EmployeesPageParamsEnums';

export const useEmployeesFilters = (page: string | null) => {
  const dispatch = useAppDispatch();
  const dashboardFilters = useAppSelector(employeesFiltersSelector);
  const dashboardSearchFiltersList = useAppSelector(employeesFiltersListSelector);

  const leaveRequestsFilters = useAppSelector(employeesLeaveRequestsFiltersSelector);
  const leaveRequestsSearchFilters = useAppSelector(employeesLeaveRequestsSearchFiltersSelector);

  const workCalendarFilters = useAppSelector(employeesWorkCalendarFiltersSelector);
  const workCalendarSearchFilters = useAppSelector(employeeWorkCalendarSearchFiltersSelector);

  const isDashboardPage = page === EmployeesPageParamEnums.DASHBOARD;
  const isLeaveRequestPage = page === EmployeesPageParamEnums.LEAVE_REQUESTS;
  const isWorkCalendarPage = page === EmployeesPageParamEnums.WORK_CALENDAR;

  const defaultKeywordValue = isDashboardPage ? dashboardFilters.keyword : leaveRequestsFilters.keyword;

  // Used to prevent double request on mount when keyword is not empty
  const firstRenderRef = useRef(true);

  const [keywordValue, setKeywordValue] = useState<string | undefined>(defaultKeywordValue);
  const changeKeywordValue = (value: string | undefined) => {
    setKeywordValue(value);
    firstRenderRef.current = false;
  };

  const initDropdownSearch = { jobTitle: '' };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (keywordValue && !firstRenderRef.current) {
      const timeOutId = setTimeout(() => {
        if (isDashboardPage) {
          dispatch(setDashboardFilters({
            ...dashboardFilters,
            page: 1,
            keyword: keywordValue.trim(),
            includeFullName: true,
            includeReferenceNumber: true,
            includeNationalInsuranceNumber: true,
            includeEmail: true,
          }));
        } else if (isLeaveRequestPage) {
          dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, page: 1, keyword: keywordValue.trim() }));
        } else if (isWorkCalendarPage) {
          dispatch(setEmployeesWorkCalendarFilters({ ...workCalendarFilters, page: 1, keyword: keywordValue.trim() }));
        }
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (isDashboardPage) {
        if (dashboardFilters.keyword && !keywordValue?.trim()) {
          dispatch(setDashboardFilters({
            ...dashboardFilters,
            page: 1,
            keyword: undefined,
            includeFullName: undefined,
            includeReferenceNumber: undefined,
            includeNationalInsuranceNumber: undefined,
            includeEmail: undefined,
          }));
        }
      } else if (isLeaveRequestPage) {
        if (leaveRequestsFilters.keyword && !keywordValue?.trim()) {
          dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, page: 1, keyword: undefined }));
        }
      } else if (isWorkCalendarPage) {
        if (workCalendarFilters.keyword && !keywordValue?.trim()) {
          dispatch(setEmployeesWorkCalendarFilters({ ...workCalendarFilters, page: 1, keyword: undefined }));
        }
      }
    }
    // eslint-disable-next-line
  }, [keywordValue]);

  useEffect(() => {
    if (isDashboardPage) {
      changeKeywordValue(dashboardFilters.keyword);
    } else if (isLeaveRequestPage) {
      changeKeywordValue(leaveRequestsFilters.keyword);
    } else if (isWorkCalendarPage) {
      changeKeywordValue(workCalendarFilters.keyword);
    }
    firstRenderRef.current = true;
    // eslint-disable-next-line
  }, [page]);

  const getFilteredResult = (values: number[], field: string) => {
    const commonFilters = { [field]: values, page: 1 };
    if (isDashboardPage) {
      dispatch(setDashboardFilters({ ...dashboardFilters, ...commonFilters }));
    } else if (isLeaveRequestPage) {
      dispatch(setEmployeesLeaveRequestsFilters({ ...leaveRequestsFilters, ...commonFilters }));
    } else if (isWorkCalendarPage) {
      dispatch(setEmployeesWorkCalendarFilters({ ...workCalendarFilters, ...commonFilters }));
    }
  };

  const getIsAnyFilterApplied = (keyword: string | undefined, filters: (string[] | number[] | undefined)[]) => {
    return keyword || filters.some((el) => el && el.length > 0);
  };

  const clearFilters = () => {
    if (isDashboardPage) {
      const dashboardSelectableFilters = [dashboardFilters.department, dashboardFilters.jobTitle];
      const isAnyFilterApplied = getIsAnyFilterApplied(dashboardFilters.keyword, dashboardSelectableFilters);
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { status, pageSize, ...rest } = dashboardFilters;
      isAnyFilterApplied && dispatch(setDashboardFilters({
        status,
        pageSize,
        page: 1,
      }));
    } else if (isLeaveRequestPage) {
      const leaveRequestsSelectableFilters = [
        leaveRequestsFilters.leaveTypes,
        leaveRequestsFilters.departments,
        leaveRequestsFilters.jobTitles,
      ];
      const isAnyFilterApplied = getIsAnyFilterApplied(leaveRequestsFilters.keyword, leaveRequestsSelectableFilters);
      isAnyFilterApplied && dispatch(setEmployeesLeaveRequestsFilters({
        ...leaveRequestsFilters,
        page: 1,
        keyword: undefined,
        leaveTypes: undefined,
        departments: undefined,
        jobTitles: undefined,
      }));
    } else if (isWorkCalendarPage) {
      const workCalendarSelectableFilters = [workCalendarFilters.departments, workCalendarFilters.jobTitles];
      const isAnyFilterApplied = getIsAnyFilterApplied(workCalendarFilters.keyword, workCalendarSelectableFilters);
      isAnyFilterApplied && dispatch(setEmployeesWorkCalendarFilters({
        ...workCalendarFilters,
        page: 1,
        keyword: undefined,
        departments: undefined,
        jobTitles: undefined,
      }));
    }
  };

  const getFilters = () => {
    if (isDashboardPage) return dashboardSearchFiltersList;
    if (isLeaveRequestPage) return leaveRequestsSearchFilters;
    if (isWorkCalendarPage) return workCalendarSearchFilters;
    else return undefined;
  };

  return {
    keywordValue,
    changeKeywordValue,
    initDropdownSearch,
    getFilteredResult,
    clearFilters,
    filters: getFilters(),
  };
};
