import React, { useEffect } from 'react';
import PartsKitsLines from './components/PartsKitsLines';
import './PartsKits.scss';
import PartsKitsListActions from './components/PartsKitsListActions';
import { partsKitsFiltersSelector, partsKitsListSelector } from '../../../store/selectors/partsKitSelectors';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getPartsKitsList } from '../../../store/thunks/stock/parts-kit/partsKitsThunks';
import { initDataList } from '../../../core/types/coreTypes';
import { setPartsKitList } from '../../../store/slices/partsKitSlice';
import { setDashboardLoading } from '../../../store/slices/coreSlice';
import { useCancelRequest } from '../../../core/hooks/useCancelRequest';

const PartsKits: React.FC = () => {
  const dispatch = useAppDispatch();
  const partsKitsList = useAppSelector(partsKitsListSelector);
  const partsKitsFilters = useAppSelector(partsKitsFiltersSelector);
  const { controller, cancelRequest } = useCancelRequest();

  useEffect(() => {
    cancelRequest();
    dispatch(getPartsKitsList({ filters: partsKitsFilters, signal: controller.current?.signal }));
    // eslint-disable-next-line
  }, [partsKitsFilters]);

  useEffect(() => {
    return () => {
      dispatch(setPartsKitList(initDataList));
      dispatch(setDashboardLoading(true));
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PartsKitsListActions />
      <PartsKitsLines
        partsKitsList={partsKitsList}
      />
    </>
  );
};

export default PartsKits;
