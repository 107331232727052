import { UseFormSetValue } from 'react-hook-form';
import { CustomerDto } from '../../customers-dashboard/types/customersDashboardTypes';
import { CustomerFields } from '../../create-customer/utils/CustomerSchema';

export const setCustomerDetailsValues = (setValue: UseFormSetValue<CustomerFields>, customer: CustomerDto) => {
  const keysToSet = [
    'name',
    'customerType',
    'customerAccountNumber',
    'vatNumber',
    'companyRegistrationNumber',
    'accountType',
  ];

  Object.keys(customer).forEach((key) => {
    if (keysToSet.includes(key)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      setValue(key, customer[key]);
    }
  });
  setValue('alwaysUseRequisites', customer.alwaysUseRequisites ? 1 : 0);
};
