import { createAsyncThunk } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';
import { UseFormSetError } from 'react-hook-form';
import {
  AxiosErrorResponse,
  PagingType,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  GetCustomerResponseType, PatchCustomerRestModel,
} from '../../../customers/customers-dashboard/types/customersDashboardTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import { customersAPI } from '../../../api/customersApi';
import {
  setCustomerActivityLog,
  setCustomerActivityLogFilters,
} from '../../slices/customersSlice';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import {
  CustomerActivityEventDto,
  CustomerNoteResponse,
} from '../../../customers/customer-view-page/types/customerVewPageTypes';
import { routesPath } from '../../../core/enums/pathEnum';
import { RootState } from '../../store';
import { duplicateServerErrorsHelper } from '../../../core/utils/checkIsDuplicateError';
import { CustomerFields } from '../../../customers/create-customer/utils/CustomerSchema';
import { UserEventCustomer } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';

export const getCustomerActivityLog = createAsyncThunk<ResponseResult<CustomerActivityEventDto[]>,
{ customerId: number, filters: PagingType }>(
  'get/CustomerActivityLog',
  async ({
    customerId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerActivity(customerId, filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getCustomerById = createAsyncThunk<ResponseSingleResult<GetCustomerResponseType | null>,
{ id: number }>(
  'get/CustomersById',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.fetchCustomerById(id);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteCustomerThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, navigate: NavigateFunction }>(
  'delete/Customer',
  async ({ id, navigate }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.deleteCustomer(id);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      navigate(`/${routesPath.CUSTOMERS}`);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchCustomerThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, data: PatchCustomerRestModel, onClose?: VoidFunctionType, setError?: UseFormSetError<CustomerFields> }>(
  'patch/Customer',
  async ({
    id, data, onClose, setError,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { customers } = getState() as RootState;
      const { customerActivityLogFilters } = customers;
      const response = await customersAPI.patchCustomer(id, data);
      dispatch(getCustomerById({ id }));
      if (customerActivityLogFilters.page === 1) {
        dispatch(getCustomerActivityLog({
          customerId: id,
          filters: customerActivityLogFilters,
        }));
      } else {
        dispatch(setCustomerActivityLogFilters({ ...customerActivityLogFilters, page: 1 }));
      }
      onClose && onClose();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      onClose && onClose();
      duplicateServerErrorsHelper(errors, setError);
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postCustomerActivityNote = createAsyncThunk<ResponseSingleResult<CustomerNoteResponse>,
{ customerId: number, content: string, setValue: VoidFunctionType }>(
  'post/CustomerActivityNote',
  async ({
    customerId, content, setValue,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await customersAPI.createCustomerActivityNote(customerId, content);
      const { customers: { customerActivityLogFilters } } = getState() as RootState;
      setValue();
      if (customerActivityLogFilters.page === 1) {
        dispatch(getCustomerActivityLog({
          customerId,
          filters: customerActivityLogFilters,
        }));
      } else {
        dispatch(setCustomerActivityLogFilters({ ...customerActivityLogFilters, page: 1 }));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editCustomerActivityNote = createAsyncThunk<ResponseSingleResult | null,
{ id: number, content: string, closeModal: VoidFunctionType }>(
  'put/CustomerActivityNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await customersAPI.putCustomerActivityNote(id, content);
      const {
        customers: { customerActivityLog },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventCustomer[];
        const items = timeline.map((el) => (el.objectId === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = customerActivityLog.items.map((el) => (el.objectId === id ? { ...el, content } : el));
        dispatch(setCustomerActivityLog({ ...customerActivityLog, items }));
      }
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteCustomerActivityNote = createAsyncThunk<ResponseSingleResult | null,
{ id: number, closeModal: VoidFunctionType }
>(
  'delete/CustomerActivityNote',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await customersAPI.deleteCustomerActivityNote(id);
      const {
        customers: { customerActivityLogFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setCustomerActivityLogFilters({ ...customerActivityLogFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
