import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { CustomerContactsOrderingEnum } from '../../customers/customers-dashboard/enums/CustomerContactsOrderingEnum';
import { AttachmentsOrdering } from '../../core/enums/dictionariesEnums';
import { VehicleProfilesOrderingEnum } from '../../vehicles/vehicle-profiles/enums/VehicleProfilesEnums';
import { PricelistPricesOrderingEnum } from '../../pricelists/dashboard/enums/pricelistPricesOrderingEnum';
import { getSortIndex } from '../../core/utils/getSortIndex';

const customerContactsOrderingSelector = (state: RootState) => state.coreOrdering.customerContactsOrdering;
export const customerDefaultContactsOrderingSelector = createSelector(customerContactsOrderingSelector, (ordering) => {
  const field = ordering.find((order) => order.label === CustomerContactsOrderingEnum.FullName)?.value;
  if (field) {
    return { field, isAsc: true };
  }
  return undefined;
});

export const attachmentsOrderingSelector = (state: RootState) => state.coreOrdering.attachmentsOrdering;
export const attachColumnsSelector = createSelector(attachmentsOrderingSelector, (ordering) => {
  return [
    {
      title: 'Name',
      dataIndex: 'name',
      sorter: true,
      orderField: getSortIndex(AttachmentsOrdering.Name, ordering),
      filterSearch: true,
    },
    {
      title: 'Size',
      dataIndex: 'size',
      sorter: true,
      orderField: getSortIndex(AttachmentsOrdering.Size, ordering),
    },
    {
      title: 'Upload date',
      dataIndex: 'uploadDate',
      orderField: getSortIndex(AttachmentsOrdering.UploadDate, ordering),
      sorter: true,
      isDateRange: true,
    },
    {
      title: 'Show in linked jobs',
      dataIndex: 'showInLinkedJobs',
    },
    {
      title: 'Comment',
      dataIndex: 'comment',
      filterSearch: true,
    },
  ];
});

export const inAppNotificationsOrderingSelector = (state: RootState) => state.coreOrdering.inAppNotificationsOrdering;

export const stockPartRequestLinesOrderingSelector = (state: RootState) => state.coreOrdering.stockPartRequestLinesOrdering;

export const vehicleProfilesOrderingSelector = (state: RootState) => state.coreOrdering.vehicleProfilesOrdering;
export const vehicleProfilesModelDescrOrderSelector = createSelector(vehicleProfilesOrderingSelector, (ordering) => {
  return ordering?.find((el) => el.label === VehicleProfilesOrderingEnum.ModelDescription)?.value;
});

export const vehiclesOrderingSelector = (state: RootState) => state.coreOrdering.vehiclesOrdering;

export const vehicleChecksOrderingSelector = (state: RootState) => state.coreOrdering.vehicleChecksOrdering;

export const fitmentTimeReportsOrderingSelector = (state: RootState) => state.coreOrdering.fitmentTimeReportOrdering;
export const rectificationReportsOrderingSelector = (state: RootState) => state.coreOrdering.rectificationReportOrdering;
export const rectificationsByProductOrderingSelector = (state: RootState) => state.coreOrdering.rectificationsByProductOrdering;
export const rectificationsBySupplierOrderingSelector = (state: RootState) => state.coreOrdering.rectificationsBySupplierOrdering;
export const numberOfConvertedVehiclesOrderingSelector = (state: RootState) => state.coreOrdering.numberOfConvertedVehiclesReportOrdering;
export const salesOffsiteReportOrderingSelector = (state: RootState) => state.coreOrdering.salesOffsiteReportOrdering;
export const salesOfficeEmployeeOrderingSelector = (state: RootState) => state.coreOrdering.salesOfficeEmployeeReportOrdering;
export const salesByCustomerReportOrderingSelector = (state: RootState) => state.coreOrdering.salesByCustomerReportOrdering;
export const soldProductsByCustomerOrderingSelector = (state: RootState) => state.coreOrdering.soldProductsByCustomerReportOrdering;
export const fullStockReportOrderingSelector = (state: RootState) => state.coreOrdering.fullStockReportOrdering;

export const partSubcategoryOrderingSelector = (state: RootState) => state.coreOrdering.partSubcategoryOrdering;
export const partCategoryAffectedOrderingSelector = (state: RootState) => state.coreOrdering.partAffectedCategoryOrdering;

export const skillsOrderingSelector = (state: RootState) => state.coreOrdering.skillsOrdering;
export const affectedEmployeeOrderingSelector = (state: RootState) => state.coreOrdering.affectedEmployeeOrdering;

export const nominalCodesOrderingSelector = (state: RootState) => state.coreOrdering.nominalCodesOrdering;

export const pricelistPartsOrderingSelector = (state: RootState) => state.coreOrdering.pricelistPartsOrdering;
export const pricelistPartsAvailableOrderingSelector = (state: RootState) => state.coreOrdering.pricelistPartsAvailableOrdering;

export const partPricesOrderingSelector = (state: RootState) => state.coreOrdering.partPricesOrdering;
export const partPricesDefaultOrderingSelector = createSelector(partPricesOrderingSelector, (ordering) => {
  return ordering.find((ord) => ord.label === PricelistPricesOrderingEnum.StartDate)?.value;
});

export const stockPartsOrderingSelector = (state: RootState) => state.coreOrdering.stockPartsOrdering;
export const stockPartsKitsOrderingSelector = (state: RootState) => state.coreOrdering.stockPartsKitsOrdering;
export const adjustPartQtyOrderingSelector = (state: RootState) => state.coreOrdering.adjustPartQtyOrdering;

export const editReadyCollectedModalOrderingSelector = (state: RootState) => state.coreOrdering.editReadyCollectedModalOrdering;

export const purchaseOrderPartsOrderingSelector = (state: RootState) => state.coreOrdering.purchaseOrderPartsOrdering;

export const partsKitComponentsOrderingSelector = (state: RootState) => state.coreOrdering.partsKitComponentsOrdering;

export const leaveRequestOrderingSelector = (state: RootState) => state.coreOrdering.leaveRequestOrdering;

export const holidaysOrderingSelector = (state: RootState) => state.coreOrdering.holidaysOrdering;

export const confirmedPartRequestsOrderingSelector = (state: RootState) => state.coreOrdering.confirmedPartRequestsOrdering;

export const unfinishedEntitiesOrderingSelector = (state: RootState) => state.coreOrdering.unfinishedEntitiesOrdering;

export const supplierSpendReportOrderingSelector = (state: RootState) => state.coreOrdering.supplierSpendReportOrdering;

export const purchasingOutstandingOverduePurchaseOrdersReportOrderingSelector = (
  state: RootState,
) => state.coreOrdering.purchasingOutstandingOverduePurchaseOrdersReportOrdering;

export const cancelledLinesReportOrderingSelector = (state: RootState) => state.coreOrdering.cancelledLinesReportOrdering;
export const fitterReturnsReportOrderingSelector = (state: RootState) => state.coreOrdering.fitterReturnsReportOrdering;
export const infoMessagesOrderingSelector = (state: RootState) => state.coreOrdering.infoMessagesOrdering;
export const userAccountsOrderingSelector = (state: RootState) => state.coreOrdering.userAccountsOrdering;
export const enquiryQuoteLinesOrderingSelector = (state: RootState) => state.coreOrdering.enquiryQuoteLinesOrdering;
export const enquiryTimelineQuoteLineGroupOrderingSelector = (state: RootState) => state.coreOrdering.enquiryTimelineQuoteLineGroupOrdering;
export const pricelistsOrderingSelector = (state: RootState) => state.coreOrdering.pricelistsOrdering;
export const nominalRecordsOrderingSelector = (state: RootState) => state.coreOrdering.nominalRecordsOrdering;
export const invoiceDocumentsOrderingSelector = (state: RootState) => state.coreOrdering.invoiceDocumentsOrdering;
export const orderListOrderingSelector = (state: RootState) => state.coreOrdering.orderListOrdering;
