import React from 'react';
import { PartsKitsViewPageEnums } from '../../enums/PartsKitsViewPageEnums';
import PartsKitsAttachments from './attachments/PartsKitsAttachments';
import PartsKitDetailsAndActivity from './details-activity/PartsKitDetailsAndActivity';
import PartsKitComponents from './parts-kit-components/PartsKitComponents';

type PricelistContentProps = {
  mode: string | null,
}

const PartsKitContent: React.FC<PricelistContentProps> = ({ mode }) => {
  const renderContent = () => {
    switch (mode) {
      case PartsKitsViewPageEnums.PARTS_KITS_DETAILS:
        return <PartsKitDetailsAndActivity />;
      case PartsKitsViewPageEnums.PARTS_KITS_COMPONENTS:
        return <PartsKitComponents />;
      default: return <PartsKitsAttachments />;
    }
  };
  return (
    <>{renderContent()}</>
  );
};

export default PartsKitContent;
