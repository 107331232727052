import React from 'react';
import { RcFile } from 'antd/es/upload';
import Modal from '../../../../core/components/modal/Modal';
import { StringFunctionType, VoidFunctionType } from '../../../../core/types/coreTypes';
import ButtonActions from '../../../../core/components/button-actions/ButtonActions';
import DraggerUpload from '../../../../core/components/upload/Dragger';
import Input from '../../../../core/components/input/Input';
import { maxLengthMessageHandle } from '../../../../core/utils/errorMessageHandle';
import { useBeforeAttachmentUpload } from '../../../../core/hooks/useBeforeAttachmentUpload';
import { useAppSelector } from '../../../../store/hooks';
import { isGlobalLoadingSelector } from '../../../../store/selectors/coreSelectors';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';

type JobStatusChangeConfirmationModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  title?: string,
  text: string,
  cancelClick: VoidFunctionType,
  confirmLabel: string,
  confirmClick: VoidFunctionType,
  isFileAttach: boolean,
  comment: string,
  setComment: StringFunctionType,
  error: string,
  setError: StringFunctionType,
  fileList: RcFile[],
  setFileList: (v: RcFile[]) => void,
  extraQuestion: boolean,
}

const JobStatusChangeConfirmationModal: React.FC<JobStatusChangeConfirmationModalProps> = ({
  visible,
  onCancel,
  title = 'Confirm',
  text,
  confirmLabel,
  cancelClick,
  confirmClick,
  isFileAttach,
  comment,
  setComment,
  error,
  setError,
  setFileList,
  fileList,
  extraQuestion,
}) => {
  const { beforeUpload } = useBeforeAttachmentUpload(fileList, setFileList);
  const isLoading = useAppSelector(isGlobalLoadingSelector);

  return (
    <Modal
      visible={visible}
      onCancel={onCancel}
      title={title}
    >
      <>
        <p className="confirmationText">
          <span>{text}</span>
          {extraQuestion && <span className="confirmationText__proceed">Do you want to proceed?</span>}
          {isFileAttach
            && <span className="confirmationText__proceed">Please attach file(s) with supporting evidence and add your comment.</span>}
        </p>
        {isFileAttach && <>
          <DraggerUpload
            name="content"
            multiple
            fileList={fileList}
            setFileList={setFileList}
            beforeUpload={beforeUpload}
            disabled={fileList.length === 10 || isLoading}
          />
          <Input
            label="Comment"
            value={comment}
            onChange={(e) => {
              const val = e.target.value;
              if (error === ErrorsEnum.REQUIRED) {
                setError('');
              }
              if (val.length > 200) {
                setError(maxLengthMessageHandle(200));
              } else {
                setError('');
              }
              setComment(val);
            }}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim()) {
                setError(ErrorsEnum.REQUIRED);
                setComment('');
              }
            }}
            error={error}
            className="acceptWrap__comment"
          />
        </>}
        <ButtonActions
          cancelLabel="Cancel"
          cancelClick={cancelClick}
          createClick={confirmClick}
          createLabel={confirmLabel}
          disabledCreate={isFileAttach && (!comment.trim() || !!error || fileList.length === 0)}
          isLoading={isLoading}
        />
      </>
    </Modal>
  );
};

export default JobStatusChangeConfirmationModal;
