import React, { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionEnum, WarningsUiNamesEnum } from '../../../core/enums/dictionariesEnums';
import HOURGLASS_ICON from '../../../assets/icons/hourglass.svg';
import HOURGLASS_ICON_END from '../../../assets/icons/solid-hour-glass-end.svg';
import NO_VEHICLE from '../../../assets/icons/no-vehicle.svg';
import FLAG_CHECKERED from '../../../assets/icons/flag-checkered.svg';
import { jobTypeIconFn, priorityLevelHandle } from '../../../core/utils/cardHandlers';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { useAppSelector } from '../../../store/hooks';
import {
  customerTypesSelector,
  jobTypesSelector,
  priorityLevelsSelector,
} from '../../../store/selectors/coreSelectors';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { DraggableCardType } from '../../types/commonTypes';
import { TruncateTooltip } from '../truncate-tooltip/TruncateTooltip';
import POUND_CROSSED_ICON from '../../../assets/icons/icon_pound-crossed-out.svg';
import Tag from '../../../core/components/tag/Tag';
import usePermission from '../../../permissions-handling/permissionHook';
import { DictionaryItem } from '../../../core/types/coreTypes';
import { DEFAULT_UI_DATE_FORMAT, UNKNOWN_USER } from '../../../core/utils/regex';
import { localDateFormatHandler } from '../../../core/utils/utcDateFormatHandler';
import { toPoundCurrency } from '../../utils/formatUtils';

type DraggableCardProps = {
  card: DraggableCardType,
  noLinkedEntities?: boolean,
  permissionToSeeCardPage: PermissionEnum,
  permissionToSeeEstimates?: PermissionEnum,
  warnings: DictionaryItem[],
}

const DraggableCard: React.FC<DraggableCardProps> = ({
  card, noLinkedEntities, permissionToSeeCardPage, permissionToSeeEstimates, warnings,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const customerTypes = useAppSelector(customerTypesSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(jobTypesSelector);
  const currentUser = useAppSelector(currentUserSelector);
  const timeTrackingAllowed = usePermission(permissionToSeeEstimates);

  const getWarningData = (warning: number) => {
    const currentWarning = warnings.find(({ value }) => value === warning)?.label;
    switch (currentWarning) {
      case WarningsUiNamesEnum.InactiveFor10To14Days: return [currentWarning, HOURGLASS_ICON];
      case WarningsUiNamesEnum.InactiveFor15PlusDays: return [currentWarning, HOURGLASS_ICON_END];
      case WarningsUiNamesEnum.VehicleIsNotLinked: return card.showDraftVehicleWarning ? [currentWarning, NO_VEHICLE] : null;
      case WarningsUiNamesEnum.DueDateIsNear: return [card.dueDate
        ? `Due date is ${localDateFormatHandler(DEFAULT_UI_DATE_FORMAT, card.dueDate)}`
        : currentWarning,
      FLAG_CHECKERED];
      default: return null;
    }
  };
  // eslint-disable-next-line consistent-return
  const navigateHandler = useCallback((id: string) => {
    if (!currentUser?.userContextRole.permissions.includes(permissionToSeeCardPage)) {
      return null;
    } else {
      const locationObj = {
        pathname: location.pathname,
        search: location.search,
      };
      navigate(id);
      setLocationHistoryHandle(locationObj);
    }
    // eslint-disable-next-line
  }, [currentUser?.userContextRole.permissions]);

  const getCustomerTypeLabel = () => {
    const label = customerTypes.find((el) => el.value === card.customerType)?.label;
    if (label) {
      return label === 'Individual' ? 'IND' : 'CORP';
    } else return '-';
  };
  return (
    <div className="draggableCard" onClick={() => navigateHandler(`${card.id}`)}>
      <div className="customer">
        {card.customerType && <Tag
          className="draggableCard__type"
          label={getCustomerTypeLabel()}
        />}
        {card.jobCategory && <Tag
          className="draggableCard__category"
          label={typeof card.jobCategory !== 'string' && card.jobCategory.length > 1
            ? <Tooltip
              title={<ul>{card.jobCategory.map((name) => <li key={name}>{name}</li>)}</ul>}
              overlayClassName="card-tooltip-overlay"
              className="customer__list-wrapper"
            >
              <span className="customer__name">{card.jobCategory[0]}</span>
              <span>{`+${card.jobCategory.length - 1}`}</span>
            </Tooltip>
            : <TruncateTooltip
              value={card.jobCategory as string}
              className="draggableCard__category-value"
              overlayClassName="card-tooltip-overlay"
              hideTooltipOnMobile
            />}
        />}
        <Tag
          light
          label={<>
            {!card.assigneesList && <TruncateTooltip
              value={card.isAssigneeDeleted ? UNKNOWN_USER : (card.assigneeFullName || '')}
              className="customer__name"
              overlayClassName="card-tooltip-overlay"
              hideTooltipOnMobile
            />}
            {card.assigneesList && card.assigneesList.length > 0
              && <Tooltip
                title={<ul>{card.assigneesList.map((name) => <li key={name}>{name}</li>)}</ul>}
                overlayClassName="card-tooltip-overlay"
                className="customer__list-wrapper"
              >
                <span className="customer__name">{card.assigneeFullName}</span>
                <span>{`+${card.assigneesList.length - 1}`}</span>
              </Tooltip>}
          </>}
          icon={<FontAwesomeIcon icon={faUserCircle} className="customer__icon" />}
          className="customer__tag"
        />
      </div>
      <div className="cardContent">
        <span className="entityLink">{card.entityNumber}</span>
        <div className="clientName">{card.customerName}</div>
        {!card.jobCategory && <div className="costValue">
          {toPoundCurrency(card.cost ?? 0)}
        </div>}
        {!noLinkedEntities && <div className="linkedEntities">
          {card.linkedEntities?.map((el) => <div
            className="linkedEntities__line"
            key={`${el.label}${el.value}`}
          >
            <div className="linkedEntities__line-left">
              <FontAwesomeIcon className="linkedEntities__line-left-icon" icon={el.icon} />
              <span>
                {' '}
                {el.label}
              </span>
            </div>
            <TruncateTooltip
              value={el.value ? el.value : `${el.current} of ${el.max}`}
              className="linkedEntities__line-right"
              overlayClassName="card-tooltip-overlay"
            />
          </div>)}
        </div>}
        {card.timeTracking && timeTrackingAllowed && <ul className="timeTracking">
          {card.timeTracking.map((el, i) => <li
            className="timeTracking__item"
            key={i}
          >
            <span>
              {el.label}
            </span>
            <span className="timeTracking__item-value">
              {el.value}
            </span>
          </li>)}
        </ul>}
        <div className="icons">
          {card.isFreeRectification && <Tooltip title="Free" overlayClassName="card-tooltip-overlay">
            <img src={POUND_CROSSED_ICON} className="icons__item" alt="" />
          </Tooltip>}
          {card.warnings && card.warnings.length > 0 && card.warnings.map((el) => {
            const data = getWarningData(el);
            if (!data) return null;
            const [title, src] = data;
            return <Tooltip key={title} title={title} overlayClassName="card-tooltip-overlay">
              <img
                src={src}
                alt=""
                className="icons__item-icon"
              />
            </Tooltip>;
          })}
          <Tooltip title={priorityLevels.find((p) => p.value === card.priorityLevel)?.label} overlayClassName="card-tooltip-overlay">
            <span className="itemPriority">
              <FontAwesomeIcon
                icon={priorityLevelHandle(priorityLevels, card.priorityLevel)}
                className="itemPriority-icon"
              />
            </span>
          </Tooltip>
          <Tooltip title={jobTypes.find((j) => j.value === card.jobType)?.label} overlayClassName="card-tooltip-overlay">
            <span className="icons__item">
              <img src={jobTypeIconFn(jobTypes, card.jobType)} alt="" className="icons__item-icon" />
            </span>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default DraggableCard;
