import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import Modal from '../../../core/components/modal/Modal';
import {
  initDataListWithIds, initSmallPaging, VoidFunctionType,
} from '../../../core/types/coreTypes';
import Button from '../../../core/components/button/Button';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { routesPath } from '../../../core/enums/pathEnum';
import { getConfirmedPartRequestsOrdering } from '../../../store/thunks/core/coreOrderingThunks';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { confirmedPartRequestsOrderingSelector } from '../../../store/selectors/coreOrderingSelectors';
import {
  setConfirmedPartRequestViewsParams,
  setStockPartRequestsViewsConfirmed,
} from '../../../store/slices/stockPartRequestSlice';
import {
  confirmedPartRequestViewsParamsSelector,
  stockPartRequestsViewsConfirmedSelector,
} from '../../../store/selectors/stockPartRequestsSelector';
import Pagination from '../../../core/components/pagination/Pagination';
import { SMALL_TABLES_PAGE_SIZES } from '../../../core/utils/regex';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import TableNew from '../../../core/components/table-components/table-new/TableNew';
import TableLoader from '../../../core/components/table-components/TableLoader';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';
import {
  createPurchaseOrderBasedOnConfirmedPartRequestsThunk,
  getPartRequestsViewsConfirmed,
} from '../../../store/thunks/stock/stock-part-requests/stockPartRequestsThunks';
import SelectConfirmedPartRequestsBody from './components/SelectConfirmedPartRequestsBody';
import SelectConfirmedPartRequestsTHead from './components/SelectConfirmedPartRequestsTHead';
import {
  CreatePurchaseOrderSearchFilterType,
  InitCreatePurchaseOrderSearchFilter,
} from './types/createPurchaseOrderTypes';
import './CreatePurchaseOrderModesModal.scss';
import { useSelectAll } from '../../../core/hooks/useSelectAll';

type CreatePurchaseOrderModesModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
}

const CreatePurchaseOrderModesModal: React.FC<CreatePurchaseOrderModesModalProps> = ({ visible, onCancel }) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const confirmedPartRequestsOrdering = useAppSelector(confirmedPartRequestsOrderingSelector);
  const filters = useAppSelector(confirmedPartRequestViewsParamsSelector);
  const { totalCount, allItemIds } = useAppSelector(stockPartRequestsViewsConfirmedSelector);

  const {
    isAll,
    isIndeterminate,
    checkedKeys,
    checkAllHandle,
    checkRowHandle,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  } = useSelectAll(allItemIds);

  const createBlankEntityHandle = () => {
    setLocationHistoryHandle({
      pathname: location.pathname,
      search: location.search,
    });
    navigate(`/${routesPath.CREATE_PURCHASE_ORDER}`, { replace: true });
  };

  const [mode, setMode] = useState<string>('');

  const [searchVl, setSearchVl] = useState<CreatePurchaseOrderSearchFilterType>(InitCreatePurchaseOrderSearchFilter);

  const cancelHandle = () => {
    mode && setMode('');
    onCancel();
    dispatch(setConfirmedPartRequestViewsParams(initSmallPaging));
    dispatch(setStockPartRequestsViewsConfirmed(initDataListWithIds));
    setSearchVl(InitCreatePurchaseOrderSearchFilter);
  };
  const createHandle = () => {
    dispatch(createPurchaseOrderBasedOnConfirmedPartRequestsThunk({
      partRequestLineIds: checkedKeys as unknown as string[],
      navigate,
      closeModal: cancelHandle,
    }));
  };

  useEffect(() => {
    if (!confirmedPartRequestsOrdering.length && visible) {
      dispatch(getConfirmedPartRequestsOrdering());
    }
    // eslint-disable-next-line
  }, [confirmedPartRequestsOrdering.length, visible]);

  useEffect(() => {
    mode && dispatch(getPartRequestsViewsConfirmed({ params: filters }));
    // eslint-disable-next-line
  }, [visible, mode, filters]);

  return (
    <Modal
      visible={visible}
      onCancel={cancelHandle}
      title={mode ? 'Select confirmed part requests' : ''}
      width={mode ? 900 : 700}
      className="createPurchaseOrderModesModal"
    >
      {!mode && <div className="createPurchaseOrderModesModal__choice">
        <Button
          label="Create a blank purchase order"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          reversed
          onClick={createBlankEntityHandle}
          className="createPurchaseOrderModesModal__choice-item "
        />
        <Button
          label="Select confirmed part requests"
          icon={<FontAwesomeIcon icon={faArrowRight} />}
          reversed
          onClick={() => setMode('select')}
          className="createPurchaseOrderModesModal__choice-item "
        />
      </div>}
      {mode && <>
        <Pagination
          currentPage={filters.page}
          pageSize={filters.pageSize}
          pageSizeOptions={SMALL_TABLES_PAGE_SIZES}
          showSizeChanger
          totalItems={totalCount}
          onChange={(page, pageSize) => dispatch(setConfirmedPartRequestViewsParams({ ...filters, page, pageSize }))}
          offsetBottom
        />
        <TableNew>
          <SelectConfirmedPartRequestsTHead
            checkAll={checkAllHandle}
            isAll={isAll}
            isIndeterminate={isIndeterminate}
            totalCount={totalCount}
            searchVl={searchVl}
            setSearchVl={setSearchVl}
          />
          {isLoading
            ? <TableLoader colspan={6} />
            : <SelectConfirmedPartRequestsBody
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              checkHandle={checkRowHandle}
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              checkedKeys={checkedKeys}
            />}
        </TableNew>
        <ButtonActions
          createLabel="Create purchase order(s)"
          createType="button"
          disabledCreate={!totalCount || checkedKeys.length === 0}
          createClick={createHandle}
          cancelClick={cancelHandle}
          isLoading={isFetching}
          offsetTop
        />
      </>}
    </Modal>
  );
};

export default CreatePurchaseOrderModesModal;
