import React from 'react';
import { fieldNameEventHandle, getValuesFromJsonArray } from '../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../store/hooks';
import { shortBusinessAreasSelector } from '../../../../../../store/selectors/coreSelectors';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const PricelistFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const businessAreas = useAppSelector(shortBusinessAreasSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'BusinessAreas': {
        return getValuesFromJsonArray(value as string, businessAreas);
      }
      case 'CustomerNames': {
        if (typeof value === 'number') return value;
        return value
          .replace(/"/g, '')
          .replace(/,/g, ', ')
          .slice(1, -1);
      }
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field, { businessAreas: 'Pricelist', name: 'Pricelist' })}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default PricelistFieldUpdateEvent;
