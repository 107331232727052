import React from 'react';
import TableNew from '../../../../../../core/components/table-components/table-new/TableNew';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  fitterReturnsReportColumnsSelector,
  fitterReturnsReportDataSelector,
} from '../../../../../../store/selectors/reportsSelectors';
import FitterReturnsColumns from './FitterReturnsColumns';
import FitterReturnsBody from './FitterReturnsBody';
import './FitterReturnsTable.scss';

const FitterReturnsTable: React.FC = () => {
  const columns = useAppSelector(fitterReturnsReportColumnsSelector);
  const { totalCount } = useAppSelector(fitterReturnsReportDataSelector);
  return (
    <TableNew>
      <FitterReturnsColumns columns={columns} totalCount={totalCount} />
      <FitterReturnsBody columnsLength={columns.length} />
    </TableNew>
  );
};

export default FitterReturnsTable;
