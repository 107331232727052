import React from 'react';
import { PricelistEventsEnum } from '../../../../../enums/PricelistEventsEnum';
import PriceChangeEvent from './PriceChangeEvent';
import { PricelistPartEventPriceDto } from '../../../../../types/pricelistViewPageTypes';

type PriceEventProps = {
  eventName: string,
  productName?: string,
  oldPrices?: PricelistPartEventPriceDto[],
  newPrices?: PricelistPartEventPriceDto[],
}

const PriceEvent: React.FC<PriceEventProps> = ({
  eventName, productName, oldPrices, newPrices,
}) => {
  const renderPriceEventFn = () => {
    switch (eventName) {
      case PricelistEventsEnum.PricelistProductCreation:
        return (
          <>
            Pricelist line
            {' '}
            <b>{productName}</b>
            {' '}
            was added
          </>
        );
      case PricelistEventsEnum.PricelistProductDeletion:
        return (
          <>
            Pricelist line
            {' '}
            <b>{productName}</b>
            {' '}
            was deleted
          </>
        );
      case PricelistEventsEnum.PricelistProductFieldUpdateOrAddingValue:
        return (
          <PriceChangeEvent
            productName={productName}
            oldPrices={oldPrices}
            newPrices={newPrices}
          />
        );
      default: return null;
    }
  };

  return (
    <>{renderPriceEventFn()}</>
  );
};

export default PriceEvent;
