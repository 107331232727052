import { CreateJobReqType } from './createJobTypes';
import { maxLengthMessageHandle } from '../../../core/utils/errorMessageHandle';

export type CreateJobFields = Pick<CreateJobReqType, 'assigneeIds' | 'description'> & {
  linkedToNumber?: number,
  jobType?: number,
  jobCategories: [],
  priorityLevel?: number,
  estimatedMinutes: string,
};

export const CreateJobDefaults: CreateJobFields = {
  linkedToNumber: undefined,
  jobType: undefined,
  jobCategories: [],
  priorityLevel: undefined,
  estimatedMinutes: '0.00',
  description: '',
  assigneeIds: [],
};

export const JobDescriptionFieldValidationSchema = {
  maxLength: { value: 2000, message: maxLengthMessageHandle(2000) },
};
