export const holidayColumns = [
  { name: 'No', dataIndex: 'number' },
  { name: 'Holiday name', dataIndex: 'name' },
  { name: 'Date', dataIndex: 'date' },
];

export const initEmployeeHolidaysFilters = {
  page: 1,
  pageSize: 100,
};
