export enum CustomerEventEnum {
  CustomerCreation = 'CustomerCreation',
  CustomerFieldUpdateOrAddingValue = 'CustomerFieldUpdateOrAddingValue',
  CustomerStatusChange = 'CustomerStatusChange',
  Note = 'Note',
  AddingContact = 'AddingContact',
  ContactChange = 'ContactChange',
  RemovingContact = 'RemovingContact',
  BillingDetailsUpdates = 'BillingDetailsUpdates',
  ShippingDetailsUpdates = 'ShippingDetailsUpdates',
  AddingAttachmentWithoutComment = 'AddingAttachmentWithoutComment',
  AddingAttachmentWithComment = 'AddingAttachmentWithComment',
  EditingAttachmentComment = 'EditingAttachmentComment',
  DeletingAttachment = 'DeletingAttachment',
  CustomerDeletion = 'CustomerDeletion'
}
