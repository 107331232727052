import React, { useEffect } from 'react';
import KeywordFiltersSection from '../../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { useStockPartsKitFilters } from '../../hooks/useStockPartsKitFilters';
import { useAppSelector } from '../../../../store/hooks';
import { partsKitsFiltersSelector } from '../../../../store/selectors/partsKitSelectors';

const PartsKitsFiltersContent: React.FC = () => {
  const filtersValues = useAppSelector(partsKitsFiltersSelector);

  const {
    filters,
    setSearchValue,
    searchValue,
    onClear,
    initDropdownSearch,
    getFilteredPartsKit,
  } = useStockPartsKitFilters();
  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (searchValue) {
      const timeOutId = setTimeout(() => {
        getFilteredPartsKit({ ...filtersValues, keyword: searchValue, page: 1 });
      }, 1000);
      return () => clearTimeout(timeOutId);
    } else {
      if (!searchValue?.trim() && filtersValues.keyword) {
        getFilteredPartsKit({ ...filtersValues, keyword: undefined, page: 1 });
      }
    }
    // eslint-disable-next-line
    }, [searchValue]);

  return <KeywordFiltersSection
    searchValue={searchValue}
    initDropdownSearch={initDropdownSearch}
    setSearchValue={(v) => {
      setSearchValue(v);
    }}
    filters={filters}
    getFilteredResHandle={(value, field) => getFilteredPartsKit({
      ...filtersValues, [field]: value, page: 1,
    })}
    onClear={onClear}
    className="stockFiltersTabs__filters"
  />;
};

export default PartsKitsFiltersContent;
