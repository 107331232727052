import React, { useState } from 'react';
import Button from '../../../../../../../core/components/button/Button';
import PriceChangeEventModal from './PriceChangeEventModal';
import { PricelistPartEventPriceDto } from '../../../../../types/pricelistViewPageTypes';

type PriceChangeEventProps = {
  productName?: string,
  oldPrices?: PricelistPartEventPriceDto[],
  newPrices?: PricelistPartEventPriceDto[],
}

const PriceChangeEvent: React.FC<PriceChangeEventProps> = ({ productName, oldPrices, newPrices }) => {
  const [isModal, setIsModal] = useState(false);

  return (
    <>
      <PriceChangeEventModal
        visible={isModal}
        onCancel={() => setIsModal(false)}
        oldPrices={oldPrices || []}
        newPrices={newPrices || []}
      />
      <>
        Prices of
        {' '}
        <b>{productName}</b>
        {' '}
        were updated
        {' '}
        <Button
          label="(see details)"
          onClick={() => setIsModal(true)}
          type="text"
        />
      </>
    </>
  );
};

export default PriceChangeEvent;
