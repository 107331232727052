import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, DictionaryItem, DownloadUriResponse, emptyPaging,
  EntityLinkType, GenericVoidFunctionType,
  initLinksFilters, LinksFiltersType,
  NumberFunctionType,
  ResponseResult,
  ResponseSingleResult, StringFunctionType,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { EnquiryResponseDto } from '../../../enquiry/enquiries/types/enquiryTypes';
import {
  setCurrentArea, setErrorMessage, setFetching, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { enquiryAPI } from '../../../api/enquiryApi';
import {
  setEnqTimeline, setEnqTimelineFilters,
} from '../../slices/enquiriesSlice';
import {
  EnquiryEventDtoBase, PatchEnquiryRestModel,
  QuoteLinesFilters,
  QuoteLineTimelineType, UpdateEnquiryStatusResponse,
} from '../../../enquiry/enquiry-detailed/types/enquiryDetailsTypes';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { downloadCsv } from '../../../core/utils/downloadFileHandler';
import { getEnquiryQuotLinesThunk } from './quoteConfiguratorThunks';
import { AddressFields, DefaultAddressDto, ExtendedTimelineFilters } from '../../../common/types/commonTypes';
import { RootState } from '../../store';
import { coreAPI } from '../../../api/coreAPI';
import { getEnquiryContacts } from './enquiryContactsThunks';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { UserEventEnquiry } from '../../../settings/user-profile/types/activityTypes';
import { setUserTimeline, setUserTimelineFilters } from '../../slices/settingsSlice';
import { generateEmailAndNames } from '../../../common/utils/generateEmailAndNames';

export const getEnquiryTimelineThunk = createAsyncThunk<ResponseResult<EnquiryEventDtoBase[]>, {
  enquiryId: number, filters: ExtendedTimelineFilters,
}>(
  'get/Enquiry/EnquiryTimeline',
  async ({
    enquiryId, filters,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.getEnquiryTimeline(enquiryId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryDetails = createAsyncThunk<ResponseSingleResult<EnquiryResponseDto | null>,
{ id: number }>(
  'get/Enquiry/EnquiryDetails',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const responseBa = await coreAPI.fetchBusinessAreas();
      const response = await enquiryAPI.getEnquiryById(id);
      const baList = responseBa.data.data.items;
      const allowedBA = baList.filter((el) => el.isAllowed);
      const businessArea = response.data.data?.enquiry?.businessArea;
      if (allowedBA.find((ba: DictionaryItem) => ba.value === businessArea)) {
        dispatch(setCurrentArea(businessArea));
      } else {
        dispatch(setCurrentArea(allowedBA[0].value));
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryLinks = createAsyncThunk<ResponseSingleResult<{ orderLinks: Array<EntityLinkType> }> | null, {
  enquiryId: number,
  filters: LinksFiltersType,
}>(
  'get/EnquiryLinks',
  async ({ enquiryId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.fetchEnquiryLinks(enquiryId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEnquiryStatusThunk = createAsyncThunk<ResponseSingleResult<UpdateEnquiryStatusResponse>, {
  id: number,
  statusTransition: number,
  rejectionReason?: string,
  onClose?: VoidFunctionType,
  isUpdateQuoteLines?: boolean,
}>(
  'put/EnquiryStatus',
  async ({
    id, statusTransition, rejectionReason, onClose, isUpdateQuoteLines,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries } = getState() as RootState;
      const { enquiryTimelineFilters, openedVehicleId, quoteLinesFilters } = enquiries;
      const response = await enquiryAPI.editEnquiryStatus(id, statusTransition, rejectionReason);
      dispatch(getEnquiryDetails({ id }));
      if (enquiryTimelineFilters.page === 1) {
        dispatch(getEnquiryTimelineThunk({
          enquiryId: id,
          filters: enquiryTimelineFilters,
        }));
      } else {
        dispatch(setEnqTimelineFilters({ ...enquiryTimelineFilters, page: 1 }));
      }
      dispatch(getEnquiryLinks({ enquiryId: id, filters: initLinksFilters }));
      isUpdateQuoteLines && openedVehicleId && dispatch(getEnquiryQuotLinesThunk({
        vehicleId: openedVehicleId,
        params: {
          page: quoteLinesFilters.page,
          pageSize: quoteLinesFilters.pageSize,
          order: quoteLinesFilters.order
            ? { field: quoteLinesFilters.order?.field, isAsc: quoteLinesFilters.order?.isAsc }
            : undefined,
          filters: quoteLinesFilters.filters
            ? { ...quoteLinesFilters.filters }
            : undefined,
        },
      }));
      onClose && onClose();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      onClose && onClose();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editEnquiryByIdThunk = createAsyncThunk<ResponseSingleResult<null>, {
  id: number,
  newValues: PatchEnquiryRestModel,
  onClose?: VoidFunctionType,
  isUpdateQuoteLines?: boolean,
}>(
  'patch/EnquiryById',
  async ({
    id, newValues, onClose, isUpdateQuoteLines,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { enquiries } = getState() as RootState;
      const { enquiryTimelineFilters, openedVehicleId, quoteLinesFilters } = enquiries;
      const response = await enquiryAPI.editEnquiryById(id, newValues);
      dispatch(getEnquiryDetails({ id }));
      if (enquiryTimelineFilters.page === 1) {
        dispatch(getEnquiryTimelineThunk({
          enquiryId: id,
          filters: enquiryTimelineFilters,
        }));
      } else {
        dispatch(setEnqTimelineFilters({ ...enquiryTimelineFilters, page: 1 }));
      }
      isUpdateQuoteLines && openedVehicleId && dispatch(getEnquiryQuotLinesThunk({
        vehicleId: openedVehicleId,
        params: {
          page: quoteLinesFilters.page,
          pageSize: quoteLinesFilters.pageSize,
          order: quoteLinesFilters.order
            ? { field: quoteLinesFilters.order?.field, isAsc: quoteLinesFilters.order?.isAsc }
            : undefined,
          filters: quoteLinesFilters.filters
            ? { ...quoteLinesFilters.filters }
            : undefined,
        },
      }));
      onClose && onClose();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      const errors = error.response?.data.errors || [];
      statusTransitionErrorHandle(errors, dispatch);
      const quoteDateError = errors.find((err) => err.key === 'QuoteDateUpdateUiError');
      if (quoteDateError) {
        dispatch(setErrorMessage({ message: quoteDateError.message, toastId: Math.random() }));
      }
      onClose && onClose();
      return rejectWithValue(error.response?.data);
    }
  },
);

export const duplicateEnquiryThunk = createAsyncThunk<
ResponseSingleResult<{ createdId: number, enquiryNumber: string | null }>,
{ id: number, navigateTo: NumberFunctionType, closeModal: VoidFunctionType}
>(
  'post/Enquiry/DuplicateEnquiry',
  async ({ id, navigateTo, closeModal }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await enquiryAPI.duplicateEnquiry(id);
      const { createdId, enquiryNumber } = response.data.data;
      navigateTo(createdId);
      dispatch(setSuccessMessage({ message: `Enquiry "${enquiryNumber}" was successfully created.`, toastId: Math.random() }));
      closeModal();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEnquiryThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, navigateTo: VoidFunctionType }>(
  'delete/Enquiry/DeleteEnquiry',
  async ({ id, navigateTo }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await enquiryAPI.deleteEnquiry(id);
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setLoading(false));
      navigateTo();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const postEnquiryTimelineNoteThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  enquiryId: number, content: string, setValue: VoidFunctionType,
}>('post/Enquiry/EnquiryNote', async ({
  enquiryId, content, setValue,
}, { getState, dispatch, rejectWithValue }) => {
  dispatch(setLoading(true));
  try {
    const res = await enquiryAPI.postEnquiryNote(enquiryId, content);
    const { enquiries: { enquiryTimelineFilters } } = getState() as RootState;
    setValue();
    if (enquiryTimelineFilters.page === 1) {
      dispatch(getEnquiryTimelineThunk({
        enquiryId,
        filters: enquiryTimelineFilters,
      }));
    } else {
      dispatch(setEnqTimelineFilters({ ...enquiryTimelineFilters, page: 1 }));
    }
    dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
    dispatch(setLoading(false));
    return res.data;
  } catch (err) {
    const error = err as AxiosErrorResponse;
    dispatch(setLoading(false));
    setValue();
    return rejectWithValue(error.response?.data);
  }
});

export const editEnquiryTimelineNoteThunk = createAsyncThunk<ResponseSingleResult, {
  id: number,
  content: string,
  closeModal: VoidFunctionType,
}>(
  'put/Enquiry/EnquiryNote',
  async ({
    id, content, closeModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await enquiryAPI.putEnquiryNote(id, content);
      const {
        enquiries: { enquiryTimeline },
        settings: { userProfile, userTimeline },
      } = getState() as RootState;
      if (userProfile?.id) {
        const timeline = userTimeline.items as UserEventEnquiry[];
        const items = timeline.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setUserTimeline({ ...userTimeline, items }));
      } else {
        const items = enquiryTimeline.items.map((el) => (el.id === id ? { ...el, content } : el));
        dispatch(setEnqTimeline({ ...enquiryTimeline, items }));
      }
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deleteEnquiryTimelineNoteThunk = createAsyncThunk<ResponseSingleResult,
{ id: number, closeModal: VoidFunctionType, }>(
  'delete/Enquiry/EnquiryNote',
  async ({ id, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const res = await enquiryAPI.deleteEnquiryNote(id);
      const {
        enquiries: { enquiryTimelineFilters },
        settings: { userTimelineFilters, userProfile },
      } = getState() as RootState;
      if (userProfile?.id) {
        dispatch(setUserTimelineFilters({ ...userTimelineFilters }));
      } else {
        dispatch(setEnqTimelineFilters({ ...enquiryTimelineFilters }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      closeModal();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryTimelineQuoteLineBeforeThunk = createAsyncThunk<ResponseResult<QuoteLineTimelineType[]>, {
  lineGroupId: number,
  filters?: QuoteLinesFilters,
}>(
  'get/Enquiry/EnquiryTimelineQuoteLineBefore',
  async ({ lineGroupId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.getEnquiryTimelineQuoteLineBefore(lineGroupId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getEnquiryTimelineQuoteLineAfterThunk = createAsyncThunk<ResponseResult<QuoteLineTimelineType[]>, {
  lineGroupId: number,
  filters?: QuoteLinesFilters,
}>(
  'get/Enquiry/EnquiryTimelineQuoteLineAfter',
  async ({ lineGroupId, filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.getEnquiryTimelineQuoteLineAfter(lineGroupId, filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateEnquiryExcelThunk = createAsyncThunk<DownloadUriResponse, {
  id: number,
}>(
  'get/Enquiry/Excel',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.generateEnquiryExcel(id);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateEnquiryPdfThunk = createAsyncThunk<DownloadUriResponse, {
  id: number,
}>(
  'get/Enquiry/PDF',
  async ({ id }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.exportEnquiryPdf(id);
      downloadCsv(res.data.data.downloadUri);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const generateEnquiryEmailPdfThunk = createAsyncThunk<DownloadUriResponse | null, {
  id: number,
  closeModal: VoidFunctionType,
  selectedContacts: { id: number, name: string, email: string }[],
  setEmailHref: StringFunctionType,
}>(
  'get/Enquiry/EmailPDF',
  async ({
    id, closeModal, selectedContacts, setEmailHref,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const {
        data: { data: { allItemIds } },
      } = await enquiryAPI.fetchEnquiryContacts(id, emptyPaging.page, emptyPaging.pageSize, true);
      const filteredContacts = selectedContacts.filter(({ id }) => allItemIds.includes(id));
      if (!filteredContacts.length) {
        closeModal();
        return null;
      }
      const { selectedNames, selectedEmails } = generateEmailAndNames(filteredContacts);
      const res = await enquiryAPI.exportEnquiryPdf(id);
      const { downloadUri } = res.data.data;
      const { enquiries: { enquiryDetails } } = getState() as RootState;
      const enquiryNumber = enquiryDetails?.enquiry.enquiryNumber;
      const subject = 'Vanliners enquiry details';
      const encodedLink = encodeURIComponent(downloadUri.replace(/ /g, '%20'));
      // eslint-disable-next-line max-len
      const emailBody = `Dear ${selectedNames},%0D%0A%0D%0APlease find below a link to your Enquiry reference ${enquiryNumber}:%0D%0A%0D%0A${encodedLink}%0D%0A%0D%0AIf you require anything further, please get in touch.`;
      setEmailHref(`mailto:${selectedEmails}?subject=${subject}&body=${emailBody}&content-type=text/plain`);
      dispatch(setFetching(false));
      closeModal();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getDefaultEnquiryJobLocation = createAsyncThunk<ResponseSingleResult<{ defaultAddress: DefaultAddressDto }>,
{
  setAddressFields: GenericVoidFunctionType<AddressFields | null>,
  editThunk?: GenericVoidFunctionType<AddressFields>
}>(
  'get/Enquiry/DefaultAddress',
  async ({ setAddressFields, editThunk }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await enquiryAPI.fetchDefaultEnquiryJobLocation();
      const values = res.data.data.defaultAddress;
      const {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        id, jobAddressLatitude, jobAddressLongitude, ...rest
      } = values;
      setAddressFields(rest);
      editThunk && editThunk(rest);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const changeEnquiryCustomerThunk = createAsyncThunk<
ResponseSingleResult,
{ enquiryId: number, customerId: number, closeModal: VoidFunctionType, }>(
  'put/EnquiryCustomer',
  async ({ enquiryId, customerId, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { enquiries: { enquiryTimelineFilters, enquiryContactsPaging } } = getState() as RootState;
      const res = await enquiryAPI.changeEnquiryCustomer(enquiryId, customerId);
      closeModal();
      dispatch(setSuccessMessage({ message: 'Customer was successfully changed.', toastId: Math.random() }));
      dispatch(getEnquiryDetails({ id: enquiryId }));
      dispatch(getEnquiryContacts({
        id: enquiryId,
        page: enquiryContactsPaging.page,
        pageSize: enquiryContactsPaging.pageSize,
      }));
      if (enquiryTimelineFilters.page === 1) {
        dispatch(getEnquiryTimelineThunk({
          enquiryId,
          filters: enquiryTimelineFilters,
        }));
      } else {
        dispatch(setEnqTimelineFilters({ ...enquiryTimelineFilters, page: 1 }));
      }
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
