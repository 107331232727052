import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse, initPaging, PagingType,
  ResponseResult, ResponseSingleResult, StringFunctionType, VoidFunctionType,
} from '../../../../core/types/coreTypes';
import { setFetching, setLoading, setSuccessMessage } from '../../../slices/coreSlice';
import {
  AffectedCategoriesFiltersType,
  PartSubcategoriesFilters,
  PartSubcategoryDto,
} from '../../../../stock/part-categories/types/partCategoriesTypes';
import { stockApi } from '../../../../api/stockApi';
import { RootState } from '../../../store';
import { setPartSubcategoriesFilters } from '../../../slices/stockSlice';
import { SUCCESSFUL_CREATE, SUCCESSFUL_DELETE } from '../../../../core/utils/successMessages';
import { ErrorsEnum } from '../../../../core/enums/errorsEnum';
import { AffectedEntityType } from '../../../../common/types/commonTypes';
import { getPartCategoriesThunk, getPartSubcategoriesThunk } from '../../shared/sharedThunks';
import { setPartCategories } from '../../../slices/sharedSlice';

export const getStockPartSubcategoriesThunk = createAsyncThunk<ResponseResult<PartSubcategoryDto[]>, {
  filters: PartSubcategoriesFilters,
}>(
  'get/StockPartSubcategories',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchPartSubcategories(filters);
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPartSubcategoryThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  categoryId: number, name: string, onClose: VoidFunctionType, setError: StringFunctionType,
}>(
  'post/PartSubcategories',
  async ({
    categoryId, name, onClose, setError,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.postPartSubcategory(categoryId, name);
      const { stock: { stockPartSubcategoriesFilters } } = getState() as RootState;
      dispatch(getPartCategoriesThunk({ filters: undefined }));
      dispatch(getPartSubcategoriesThunk());
      dispatch(getStockPartSubcategoriesThunk({ filters: stockPartSubcategoriesFilters }));
      onClose();
      dispatch(setFetching(false));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setFetching(false));
      if (errors.find((el) => el.key === 'Name')) {
        setError(ErrorsEnum.UNIQUE_VALUE);
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editPartSubcategoryThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, name: string, onClose: VoidFunctionType, setError: StringFunctionType,
}>(
  'put/PartSubcategories',
  async ({
    id, name, onClose, setError,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.putPartSubcategory(id, name);
      const { stock: { stockPartSubcategoriesFilters } } = getState() as RootState;
      dispatch(getPartSubcategoriesThunk());
      dispatch(getStockPartSubcategoriesThunk({ filters: stockPartSubcategoriesFilters }));
      onClose();
      dispatch(setFetching(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setFetching(false));
      if (errors.find((el) => el.key === 'Name')) {
        setError(ErrorsEnum.UNIQUE_VALUE);
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartSubcategoryThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, closeModal: VoidFunctionType,
}>(
  'delete/PartSubcategory',
  async ({ id, closeModal }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.deletePartSubcategory(id);
      const { stock: { stockPartSubcategoriesFilters } } = getState() as RootState;
      dispatch(getPartCategoriesThunk({ filters: undefined }));
      dispatch(getPartSubcategoriesThunk());
      dispatch(getStockPartSubcategoriesThunk({ filters: stockPartSubcategoriesFilters }));
      dispatch(setFetching(false));
      closeModal();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartSubcategoryAffectedThunk = createAsyncThunk<ResponseResult<AffectedEntityType[]>, {
  subcategoryId: number,
  filters: PagingType,
  confirmAction?: VoidFunctionType,
  complexAction?: VoidFunctionType,
}>(
  'get/PartSubcategoryAffected',
  async ({
    subcategoryId, filters, confirmAction, complexAction,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchAffectedPartSubcategories(subcategoryId, filters);
      if (response.data.data.totalCount === 0) {
        confirmAction && confirmAction();
      } else {
        complexAction && complexAction();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const createPartCategoryThunk = createAsyncThunk<ResponseSingleResult<{ createdId: number }>, {
  name: string, onClose: VoidFunctionType, setError: StringFunctionType,
}>(
  'post/PartCategories',
  async ({
    name, onClose, setError,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.postPartCategory(name);
      dispatch(getPartCategoriesThunk({ filters: undefined }));
      dispatch(setPartSubcategoriesFilters({ ...initPaging, categoryIds: [response.data.data.createdId] }));
      onClose();
      dispatch(setFetching(false));
      dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setFetching(false));
      if (errors.find((el) => el.key === 'Name')) {
        setError(ErrorsEnum.UNIQUE_VALUE);
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editPartCategoryThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, name: string, onClose: VoidFunctionType, setError: StringFunctionType,
}>(
  'put/PartCategories',
  async ({
    id, name, onClose, setError,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.putPartCategory(id, name);
      const { stock: { stockPartSubcategoriesFilters } } = getState() as RootState;
      dispatch(getPartCategoriesThunk({ filters: undefined }));
      dispatch(getStockPartSubcategoriesThunk({ filters: stockPartSubcategoriesFilters }));
      dispatch(setFetching(false));
      onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setFetching(false));
      if (errors.find((el) => el.key === 'Name')) {
        setError(ErrorsEnum.UNIQUE_VALUE);
      }
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartCategoryThunk = createAsyncThunk<ResponseSingleResult, {
  id: number, onClose: VoidFunctionType,
}>(
  'delete/PartCategories',
  async ({
    id, onClose,
  }, { dispatch, getState, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const response = await stockApi.deletePartCategory(id);
      const categoriesResult = await stockApi.fetchPartCategories();
      dispatch(setPartCategories(categoriesResult.data.data));
      dispatch(getPartSubcategoriesThunk());
      const { shared: { partCategories } } = getState() as RootState;
      if (partCategories.items.length > 0) {
        dispatch(setPartSubcategoriesFilters({ ...initPaging, categoryIds: [partCategories.items[0].id] }));
      }
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE }));
      dispatch(setFetching(false));
      onClose();
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartCategoryAffectedThunk = createAsyncThunk<ResponseResult<AffectedEntityType[]>, {
  categoryId: number,
  filters: AffectedCategoriesFiltersType,
  openDeleteModal?: VoidFunctionType,
  openAffectedModal?: VoidFunctionType,
}>(
  'get/PartCategoryAffected',
  async ({
    categoryId, filters, openDeleteModal, openAffectedModal,
  }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const response = await stockApi.fetchAffectedPartCategories(categoryId, filters);
      if (response.data.data.totalCount === 0) {
        openDeleteModal && openDeleteModal();
      } else {
        openAffectedModal && openAffectedModal();
      }
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
