import { z } from 'zod';
import { ErrorsEnum } from '../../core/enums/errorsEnum';
import { EMAIL_REGEX } from '../../core/utils/regex';

const RequiredStringSchema = z.string({
  required_error: ErrorsEnum.REQUIRED,
});

export const LoginSchema = z.object({
  email: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
    .regex(EMAIL_REGEX, 'Please enter a valid email address.'),
  password: RequiredStringSchema.trim()
    .min(1, ErrorsEnum.REQUIRED)
  ,
});

export type LoginFields = z.infer<typeof LoginSchema>;

export const loginDefaults: LoginFields = {
  email: '',
  password: '',
};
