import React from 'react';
import { fieldNameEventHandle, getReadableBoolean } from '../../../../../../common/utils/activityLogUtils';
import { useAppSelector } from '../../../../../../store/hooks';
import {
  jobTypesSelector,
  priorityLevelsSelector,
  rectificationFaultTypesSelector,
  rectificationTypesSelector,
} from '../../../../../../store/selectors/coreSelectors';
import { labelHandle } from '../../../../../../core/utils/labelHandle';
import FieldUpdateEvent from '../../../../../../common/components/activity-events/field-update/FieldUpdateEvent';
import { FieldUpdateEventProps } from '../../../../../../core/types/coreTypes';

const RectificationFieldUpdateEvent: React.FC<FieldUpdateEventProps> = ({ field, oldValue, newValue }) => {
  const rectificationTypes = useAppSelector(rectificationTypesSelector);
  const rectificationFaultTypes = useAppSelector(rectificationFaultTypesSelector);
  const priorityLevels = useAppSelector(priorityLevelsSelector);
  const jobTypes = useAppSelector(jobTypesSelector);

  const valueHandle = (value: string | number | undefined) => {
    if (!value) return '';
    switch (field) {
      case 'RectificationType':
        return labelHandle(+value, rectificationTypes);
      case 'FaultType':
        return labelHandle(+value, rectificationFaultTypes);
      case 'PriorityLevel':
        return labelHandle(+value, priorityLevels);
      case 'JobType':
        return labelHandle(+value, jobTypes);
      case 'IsShippingRequisiteSameAsBilling':
        return getReadableBoolean(value);
      default: return value;
    }
  };

  return (
    <FieldUpdateEvent
      field={fieldNameEventHandle(field)}
      oldValue={valueHandle(oldValue)}
      newValue={valueHandle(newValue)}
    />
  );
};

export default RectificationFieldUpdateEvent;
