import React from 'react';
import OrderDetailsSection from './OrderDetailsSection';
import OrderActivitySection from './OrderActivitySection';
import OrderVehicleSection from './OrderVehicleSection';
import OrderJobsAndPartsSection from './OrderJobsAndPartsSection';
import './OrderDetailsContent.scss';
import { OrderDetails } from '../../../../dashboard/types/ordersTypes';

type OrderDetailsProps = {
  orderDetails: OrderDetails | null,
  isCurrentStatusArchived: boolean,
  isCurrentStatusCancelled: boolean,
  isCurrentStatusForbiddenToLinkVeh: boolean,
  isCurrentStatusForbiddenToEditVeh: boolean,
  isStatusAllowToEditVehRegNoAndChassisNo: boolean,
}

const OrderDetailsContent: React.FC<OrderDetailsProps> = ({
  orderDetails,
  isCurrentStatusArchived,
  isCurrentStatusCancelled,
  isCurrentStatusForbiddenToLinkVeh,
  isCurrentStatusForbiddenToEditVeh,
  isStatusAllowToEditVehRegNoAndChassisNo,
}) => {
  return (
    <>
      <div className="detailsWrap info-grid upper-details">
        <OrderVehicleSection
          isCurrentStatusForbiddenToLinkVeh={isCurrentStatusForbiddenToLinkVeh}
          isCurrentStatusForbiddenToEditVeh={isCurrentStatusForbiddenToEditVeh}
          isStatusAllowToEditVehRegNoAndChassisNo={isStatusAllowToEditVehRegNoAndChassisNo}
        />
        <OrderJobsAndPartsSection />
      </div>
      <div className="detailsWrap info-grid upper-details">
        <OrderDetailsSection
          orderDetails={orderDetails}
          isCurrentStatusArchived={isCurrentStatusArchived}
          isCurrentStatusCancelled={isCurrentStatusCancelled}
        />
        <OrderActivitySection />
      </div>
    </>
  );
};

export default OrderDetailsContent;
