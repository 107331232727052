import { useAppDispatch } from '../../../store/hooks';
import { patchCustomerThunk } from '../../../store/thunks/customers/customersViewPageThunks';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { RequisiteDto } from '../../../common/types/commonTypes';

export const useCustomerRequisite = (
  isShippingMode: boolean,
  onCancel: VoidFunctionType,
  customerId?: number,
) => {
  const dispatch = useAppDispatch();
  const editRequisiteHandle = (requisite: RequisiteDto) => {
    const isShippingEmpty = Object.keys(requisite).every((key) => !requisite[key as keyof RequisiteDto]);
    const data = isShippingMode
      ? {
        shippingRequisite: isShippingEmpty ? null : requisite,
        isShippingRequisiteSameAsBilling: isShippingEmpty,
      }
      : { billingRequisite: requisite };

    customerId && dispatch(patchCustomerThunk({
      id: customerId,
      data,
      onClose: onCancel,
    }));
  };

  return { editRequisiteHandle };
};
