import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import {
  rectificationLinksFiltersSelector, rectificationsLinksRowSelector,
} from '../../../../../store/selectors/rectificationsSelector';
import { getRectificationLinksThunk } from '../../../../../store/thunks/rectification/rectificationViewPageThunks';
import RectificationLinksColumns from './RectificationLinksColumns';
import LinksTable from '../../../../../common/components/links-table/LinksTable';
import LinksBody from '../../../../../common/components/links-table/LinksBody';

const RectificationLinks: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const rectificationLinksFilters = useAppSelector(rectificationLinksFiltersSelector);
  const rectificationLinks = useAppSelector(rectificationsLinksRowSelector);

  useEffect(() => {
    id && dispatch(getRectificationLinksThunk({
      id: +id,
      filters: rectificationLinksFilters,
    }));
    // eslint-disable-next-line
  }, [id, rectificationLinksFilters]);
  return (
    <LinksTable>
      <RectificationLinksColumns totalCount={rectificationLinks.length || 0} />
      <LinksBody links={rectificationLinks} />
    </LinksTable>
  );
};

export default RectificationLinks;
