import React, { useState } from 'react';
import DecimalInputNumber from '../../../core/components/input-number/DecimalInputNumber';
import {
  BooleanFunctionType, StringFunctionType, VoidFunctionType,
} from '../../../core/types/coreTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { maxDiscount } from '../../../core/utils/regex';
import Modal from '../../../core/components/modal/Modal';
import ReasonModal from '../reason-modal/ReasonModal';
import { useAppSelector } from '../../../store/hooks';
import { isFetchingSelector, isGlobalLoadingSelector } from '../../../store/selectors/coreSelectors';

type AddDiscountModalProps = {
  isVisible: boolean,
  onCancel: VoidFunctionType,
  setDiscount: (discount: number, closeModal: VoidFunctionType) => void,
  discountReasonModal?: boolean,
  setDiscountReasonModal?: BooleanFunctionType,
  discountReasonValue?: string,
  setDiscountReasonValue?: StringFunctionType,
}

const AddDiscountModal: React.FC<AddDiscountModalProps> = ({
  isVisible,
  onCancel,
  setDiscount,
  discountReasonModal,
  setDiscountReasonModal,
  discountReasonValue,
  setDiscountReasonValue,
}) => {
  const isLoading = useAppSelector(isGlobalLoadingSelector);
  const isFetching = useAppSelector(isFetchingSelector);
  const [discountVal, setDiscountVal] = useState<string>('0.00');
  const closeModal = () => {
    setDiscountVal('0.00');
    discountReasonModal && setDiscountReasonModal && setDiscountReasonModal(false);
    onCancel();
  };
  const setDiscountHandle = () => setDiscount(+discountVal, closeModal);

  const renderReasonModal = discountReasonModal !== undefined
    && discountReasonValue !== undefined
    && setDiscountReasonValue !== undefined;

  return (
    <>
      {renderReasonModal && (
        <ReasonModal
          visible={discountReasonModal}
          reasonValue={discountReasonValue}
          setReasonValue={setDiscountReasonValue}
          type="update"
          onSubmit={setDiscountHandle}
          onCancel={() => setDiscountReasonModal && setDiscountReasonModal(false)}
        />
      )}
      <Modal visible={isVisible} onCancel={closeModal} title="Set discount for selected quote lines" destroyOnClose>
        <div className="discountModalWrap">
          <DecimalInputNumber
            value={discountVal}
            onChange={(e) => {
              const v = e.target.value.replace(',', '.');
              setDiscountVal(v);
            }}
            max={maxDiscount}
            label="Discount"
          />
          <ButtonActions
            cancelLabel="Cancel"
            createLabel="Set discount"
            createType="button"
            cancelClick={closeModal}
            createClick={setDiscountHandle}
            disabledCreate={!discountVal}
            isLoading={isLoading || isFetching}
            offsetTop
          />
        </div>
      </Modal>
    </>

  );
};

export default AddDiscountModal;
