import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-regular-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';
import Thead from '../../../../../core/components/table-components/Thead';
import Tr from '../../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import ThWithControls from '../../../../../core/components/table-components/ThWithControls';
import { SearchTableFilterType } from '../../../../../core/types/coreTypes';
import {
  fullStockReportFiltersSelector,
  reportIsVisibleSelector,
} from '../../../../../store/selectors/reportsSelectors';
import { setFullStockReportFilters } from '../../../../../store/slices/reportsSlice';
import {
  getFullStockReportExcelThunk,
  getFullStockReportThunk,
} from '../../../../../store/thunks/reports/reportsThunks';
import { ICustomColumn } from '../../../../../common/types/commonTypes';
import Dropdown from '../../../../../core/components/dropdown/Dropdown';
import ThNew from '../../../../../core/components/table-components/table-new/ThNew';

type RectificationsByProductColumnsProps = {
  columns: ICustomColumn[],
  totalElements: number,
}

const FullStockColumns: React.FC<RectificationsByProductColumnsProps> = ({ columns, totalElements }) => {
  const dispatch = useAppDispatch();
  const reportIsVisible = useAppSelector(reportIsVisibleSelector);
  const filters = useAppSelector(fullStockReportFiltersSelector);

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partCode: filters.partCode,
  });

  const sortHandle = (field: number) => {
    if (filters.order?.field === field) {
      const newFilters = {
        ...filters,
        order: { ...filters.order, isAsc: !filters.order.isAsc },
      };
      dispatch(setFullStockReportFilters(newFilters));
      dispatch(getFullStockReportThunk({ filters: newFilters }));
    } else {
      const newFilters = {
        ...filters,
        order: { field, isAsc: true },
      };
      dispatch(setFullStockReportFilters(newFilters));
      dispatch(getFullStockReportThunk({ filters: newFilters }));
    }
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    setSearchVl({ ...searchVl, [field]: v });
    if (!v) {
      const newFilters = { ...filters, [field]: undefined };
      dispatch(setFullStockReportFilters(newFilters));
      dispatch(getFullStockReportThunk({ filters: newFilters }));
    }
  };
  const searchPressHandle = (field: string) => {
    const newFilters = { ...filters, [field]: searchVl[field]?.trim() };
    dispatch(setFullStockReportFilters(newFilters));
    dispatch(getFullStockReportThunk({ filters: newFilters }));
  };

  const setRangeHandle = (field: string, value: [number, number]) => {
    const [min, max] = value;
    const newFilters = {
      ...filters,
      [field]: { min, max },
    };
    dispatch(setFullStockReportFilters(newFilters));
    dispatch(getFullStockReportThunk({ filters: newFilters }));
  };

  const exportToExcel = () => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { page, pageSize, ...rest } = filters;
    dispatch(getFullStockReportExcelThunk({ filters: rest }));
  };

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            tableFilters={filters}
            sortHandle={sortHandle}
            setRangeHandle={setRangeHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            searchVl={searchVl}
            totalElements={totalElements}
            className={`fullStockReportTh--${col.dataIndex}`}
            disabledFilter={!reportIsVisible}
          />
        ))}
        <ThNew action>
          <Dropdown
            disabled={!totalElements}
            menuItems={[
              {
                label: 'Export to Excel',
                icon: <FontAwesomeIcon icon={faFileExcel} />,
                key: 'export-to-excel',
                onClick: exportToExcel,
              },
            ]}
            icon={<FontAwesomeIcon icon={faGear} />}
          />
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default FullStockColumns;
