import { useEffect } from 'react';
import { useAppDispatch } from '../../../store/hooks';
import {
  getStockPartRequestsStatistic,
  getStockPartsStatistic,
  getStockPurchaseOrdersStatistic,
} from '../../../store/thunks/stock/stockThunks';
import { setPartRequestsStatistic, setPartsStatistic, setPurchaseOrdersStatistic } from '../../../store/slices/stockSlice';
import { getPartRequestStatuses } from '../../../store/thunks/core/coreStatusesThunks';

export const useLoadData = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getStockPartsStatistic());
    dispatch(getStockPurchaseOrdersStatistic());
    dispatch(getStockPartRequestsStatistic());
    dispatch(getPartRequestStatuses());

    return () => {
      dispatch(setPartsStatistic(null));
      dispatch(setPurchaseOrdersStatistic(null));
      dispatch(setPartRequestsStatistic(null));
    };
    // eslint-disable-next-line
  }, []);
};
