import React from 'react';
import { setEnquiryLinksFilters } from '../../../../../store/slices/enquiriesSlice';
import { useAppSelector } from '../../../../../store/hooks';
import { enquiryLinksFiltersSelector } from '../../../../../store/selectors/enquiriesSelector';
import LinksColumns from '../../../../../common/components/links-table/LinksColumns';
import { useLinks } from '../../../../../common/hooks/useLinks';

type EnquiryLinksColumnsProps = {
  totalCount: number,
}
const EnquiryLinksColumns: React.FC<EnquiryLinksColumnsProps> = ({ totalCount }) => {
  const enquiryLinksFilters = useAppSelector(enquiryLinksFiltersSelector);

  const {
    onChangeSearchHandle,
    searchPressHandle,
    searchVl,
    pickerValue,
    setPickerValue,
    pickerHandle,
  } = useLinks(enquiryLinksFilters, setEnquiryLinksFilters);

  return (
    <LinksColumns
      totalCount={totalCount}
      onChangeSearchHandle={onChangeSearchHandle}
      searchPressHandle={searchPressHandle}
      searchVl={searchVl}
      pickerValue={pickerValue}
      setPickerValue={setPickerValue}
      pickerHandle={pickerHandle}
      tableFilters={enquiryLinksFilters}
    />
  );
};

export default EnquiryLinksColumns;
