export const receivedQtyColumns = [
  {
    title: 'No',
    dataIndex: 'number',
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
  },
  {
    title: 'Reason',
    dataIndex: 'reason',
  },
];

export const FREE_TIP = 'Entered quantity will be saved as "Free" and can be used in the system without any restrictions.';
// eslint-disable-next-line max-len
export const RESERVED_TIP = 'Entered quantity will be saved as "Reserved" and will be available only in parts requests with pricelist source. Reserved parts will not be added to part stock quantity.';
export const CANCELLED_TIP = 'Cancelled quantity is not included into summary calculations and PDF export';
export const SUM_ERROR = 'Sum of received quantity, returned/cancelled quantity and due in quantity must be equal to purchased quantity.';
