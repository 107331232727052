import { useEffect, useState } from 'react';
import { OutstandingOverduePurchaseOrdersReportOrdering } from '../enum/ReportTableOrders';
import {
  setOutstandingOverduePurchasingReportFilters,
} from '../../store/slices/reportsSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
  outstandingOverduePurchasingReportFiltersSelector,
} from '../../store/selectors/reportsSelectors';
import {
  purchasingOutstandingOverduePurchaseOrdersReportOrderingSelector,
} from '../../store/selectors/coreOrderingSelectors';
import {
  getPurchasingOutstandingOverduePurchaseOrdersReportOrdering,
} from '../../store/thunks/core/coreOrderingThunks';
import { initPaging } from '../../core/types/coreTypes';

export const usePurchasingOutstandingOverdueReportDefaultPaging = (shouldFetch = true) => {
  const dispatch = useAppDispatch();
  const filters = useAppSelector(outstandingOverduePurchasingReportFiltersSelector);
  const ordering = useAppSelector(purchasingOutstandingOverduePurchaseOrdersReportOrderingSelector);
  const [defaultOrder, setDefaultOrder] = useState<number | null>(null);

  useEffect(() => {
    ordering.length === 0
    && shouldFetch
    && dispatch(getPurchasingOutstandingOverduePurchaseOrdersReportOrdering());
    // eslint-disable-next-line
    }, []);

  useEffect(() => {
    if (!filters.order?.field) {
      const defaultOrdering = ordering.find((el) => el.label === OutstandingOverduePurchaseOrdersReportOrdering.DueDate)?.value;
      if (defaultOrdering) {
        setDefaultOrder(defaultOrdering);
        dispatch(setOutstandingOverduePurchasingReportFilters({ ...filters, order: { field: defaultOrdering, isAsc: true } }));
      }
    }
    // eslint-disable-next-line
    }, [ordering]);

  return {
    ...initPaging,
    order: defaultOrder ? { field: defaultOrder, isAsc: true } : filters.order,
  };
};
