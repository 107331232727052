export const modalTitleHandle = (isFailed: boolean, isCompleted: boolean) => {
  if (isFailed) return 'Move to failed';
  if (isCompleted) return 'Move to completed';
  else return 'Confirm';
};

export const modalTextHandle = (
  isPostponed : boolean,
  isReadyForQC: boolean,
  isPending: boolean,
  isCancelled: boolean,
  isCompleted: boolean,
) => {
  if (isPostponed) {
    return 'This job will be automatically stopped for all current assignees and moved into "Postponed" status.';
  }
  if (isReadyForQC) {
    return 'This job will be automatically marked as finished for all current assignees and moved into "Ready for QC" status.';
  }
  if (isPending) {
    return 'This job will be automatically reset for all current assignees and moved into "Pending" status.';
  }
  if (isCancelled) {
    return 'This job will be automatically cancelled for all current assignees and moved into "Cancelled" status.';
  }
  if (isCompleted) {
    // eslint-disable-next-line max-len
    return 'You are about to move this job to "Completed" status. Please note that this job will no longer be available for editing after moving to "Completed" status.';
  } else return 'You are about to move this job to "Failed" status.';
};

export const modalConfirmLabelHandle = (
  isPostponed : boolean,
  isReadyForQC: boolean,
  isPending: boolean,
  isCancelled: boolean,
  isCompleted: boolean,
) => {
  if (isPostponed) return 'Postpone';
  if (isReadyForQC) return 'Mark as ready';
  if (isPending) return 'Mark as pending';
  if (isCancelled) return 'Mark as cancelled';
  if (isCompleted) return 'Mark as completed';
  else return 'Move to failed';
};
