import instance from '../axiosConfig';
import { AxiosDictionaryResponse } from '../../core/types/coreTypes';

export const coreOrderingAPI = {
  fetchCustomerContactsOrdering(): AxiosDictionaryResponse {
    return instance.get('/customer/app/customerContactsOrdering');
  },
  fetchAttachmentsOrdering(): AxiosDictionaryResponse {
    return instance.get('/attachment/app/attachmentsOrdering');
  },
  fetchInAppNotificationsOrdering(): AxiosDictionaryResponse {
    return instance.get('/account/app/notificationsOrdering');
  },
  fetchVehicleProfileOrdering(): AxiosDictionaryResponse {
    return instance.get('/vehicle/app/vehicleProfilesOrdering');
  },
  fetchVehiclesOrdering(): AxiosDictionaryResponse {
    return instance.get('vehicle/app/vehiclesOrdering');
  },
  fetchVehicleChecksOrdering(): AxiosDictionaryResponse {
    return instance.get('vehicle/app/vehicleChecksOrdering');
  },
  fetchFitmentTimeReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/productionFitmentTimeReportTableOrdering');
  },
  fetchRectificationReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/rectificationReportOrdering');
  },
  fetchRectificationByProductReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/rectificationByProductReportOrdering');
  },
  fetchRectificationBySupplierReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/rectificationBySupplierReportOrdering');
  },
  fetchNumberOfConvertedVehiclesReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesNumberOfConvertedVehiclesReportOrdering');
  },
  fetchSalesOffsiteReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesOffsiteReportOrdering');
  },
  fetchSalesOfficeEmployeeReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesOfficeEmployeeReportOrdering');
  },
  fetchSalesByCustomerReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesByCustomerReportOrdering');
  },
  fetchSoldProductsByCustomerReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/salesSoldProductsByCustomerReportOrdering');
  },
  fetchSupplierSpendReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingSupplierSpendReportOrdering');
  },
  fetchFullStockReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/stockFullStockReportOrdering');
  },
  fetchPartSubcategoryOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partSubcategoryOrdering');
  },
  fetchPartAffectedCategoryOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/affectedEntityOrdering');
  },
  fetchStockPartRequestLinesOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partRequestLinesOrdering');
  },
  fetchSkillsOrdering(): AxiosDictionaryResponse {
    return instance.get('/employee/app/skillsOrdering');
  },
  fetchAffectedEmployeeOrdering(): AxiosDictionaryResponse {
    return instance.get('/employee/app/affectedEmployeeOrdering');
  },
  fetchNominalCodesOrdering(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalCodesOrdering');
  },
  fetchPricelistPartsOrdering(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistPartsOrdering');
  },
  fetchPricelistAvailablePartsOrdering(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistAvailablePartsOrdering');
  },
  fetchPricelistPricesOrdering(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/pricelistPricesOrdering');
  },
  fetchStockPartsOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsOrdering');
  },
  fetchStockPartsKitOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsKitsOrdering');
  },
  fetchPartsKitComponentsOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partsKitComponentsOrdering');
  },
  fetchPurchaseOrderPartsOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/purchaseOrderPartsOrdering');
  },
  fetchQuantitiesOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/partQuantitiesOrdering');
  },
  fetchLeaveRequestOrdering(): AxiosDictionaryResponse {
    return instance.get('/employee/app/leaveRequestsOrdering');
  },
  fetchHolidaysOrdering(): AxiosDictionaryResponse {
    return instance.get('/employee/app/holidaysOrdering');
  },
  fetchConfirmedPartRequestsOrdering(): AxiosDictionaryResponse {
    return instance.get('/stock/app/confirmedPartRequestsOrdering');
  },
  fetchUnfinishedEntitiesReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/unfinishedEntitiesReportOrdering');
  },
  fetchPurchasingOutstandingOverduePurchaseOrdersReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingOutstandingOverduePurchaseOrdersReportOrdering');
  },
  fetchCancelledLinesReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingCancelledLinesReportOrdering');
  },
  fetchFitterReturnsReportOrdering(): AxiosDictionaryResponse {
    return instance.get('/report/app/purchasingFitterReturnsReportOrdering');
  },
  fetchInfoMessagesOrdering(): AxiosDictionaryResponse {
    return instance.get('/account/app/getInfoMessagesOrdering');
  },
  fetchUserAccountsOrdering(): AxiosDictionaryResponse {
    return instance.get('/account/app/getUserAccountsOrdering');
  },
  fetchEnquiryQuoteLinesOrdering(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/getEnquiryQuoteLinesOrdering');
  },
  fetchEnquiryTimelineQuoteLineGroupOrdering(): AxiosDictionaryResponse {
    return instance.get('/enquiry/app/getEnquiryTimelineQuoteLineGroupOrdering');
  },
  fetchPricelistsOrdering(): AxiosDictionaryResponse {
    return instance.get('/pricelist/app/getPricelistsOrdering');
  },
  fetchNominalRecordsOrdering(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/nominalRecordsOrdering');
  },
  fetchInvoiceDocumentsOrdering(): AxiosDictionaryResponse {
    return instance.get('/invoicing/app/invoiceDocumentsOrdering');
  },
  fetchOrderListOrdering(): AxiosDictionaryResponse {
    return instance.get('/order/app/orderListOrdering');
  },
};
