import React, { useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import DashboardFilters from '../../../common/components/dashboard-filters/DashboardFilters';
import KeywordFiltersSection from '../../../core/components/keyword-filters-section/KeywordFiltersSection';
import { vehiclesContainerOptions, vehiclesDashboardStatusesOptions } from '../utils/data';
import Segmented from '../../../core/components/segmented/Segmented';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { setVehiclesDashboardFilters } from '../../../store/slices/vehiclesSlice';
import { VehiclesPageParamsEnum, VehiclesDashboardStatusesEnum } from '../enums/VehiclesPageParamsEnum';
import { initPaging } from '../../../core/types/coreTypes';
import { vehiclesDashboardFiltersSelector } from '../../../store/selectors/vehiclesSelectors';
import { useLocationHistoryState } from '../../../core/hooks/useLocationHistoryState';
import { useScreenWitdh } from '../../../core/hooks/useScreenWidth';
import { responsiveEndpointsEnum } from '../../../core/enums/responsiveEndpointsEnum';
import Selectable from '../../../core/components/selectable/Selectable';
import Button from '../../../core/components/button/Button';
import { useVehiclesFilters } from '../hooks/useVehiclesFilters';
import { routesPath } from '../../../core/enums/pathEnum';

const VehiclesFilters: React.FC = () => {
  const dispatch = useAppDispatch();
  const [params, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { setLocationHistoryHandle } = useLocationHistoryState();
  const dashboardFilters = useAppSelector(vehiclesDashboardFiltersSelector);
  const { windowWidth } = useScreenWitdh();
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);

  const page = params.get('page');

  const {
    clearFilters,
    filters,
    getFilteredResHandle,
    keywordValue,
    changeKeywordValue,
    initDropdownSearch,
    isAnyDashboardFilterSelected,
  } = useVehiclesFilters(page);

  const setPageParam = (page: string) => {
    setSearchParams({ page });
  };

  const setPageParamsHandle = (value: string) => {
    if (value === VehiclesPageParamsEnum.CREATE_NEW_VEHICLE) {
      setLocationHistoryHandle({ pathname, search });
      navigate(`/${routesPath.CREATE_VEHICLE}`);
    } else {
      setPageParam(value);
    }
  };

  return (
    <DashboardFilters
      page={page}
      setPageParamHandle={setPageParamsHandle}
      options={vehiclesContainerOptions}
      expandableFilters={false}
    >
      {page === VehiclesPageParamsEnum.DASHBOARD && (
        <>
          {windowWidth <= responsiveEndpointsEnum.LG
            ? <>
              <Selectable
                options={vehiclesDashboardStatusesOptions}
                value={dashboardFilters.isActive ? VehiclesDashboardStatusesEnum.ACTIVE : VehiclesDashboardStatusesEnum.ARCHIVED}
                onChange={(value) => dispatch(setVehiclesDashboardFilters({
                  ...dashboardFilters,
                  ...initPaging,
                  isActive: value === VehiclesDashboardStatusesEnum.ACTIVE,
                  statuses: [],
                }))}
                className="vehiclesFilters__status-selectable"
                overlayClassName="vehiclesFilters__status-overlay"
              />
              <Button
                icon={<FontAwesomeIcon
                  icon={faFilter}
                />}
                onClick={() => setIsFiltersOpen(!isFiltersOpen)}
                className={classNames('dashboardFilters__action', { 'dashboardFilters__action--active': isAnyDashboardFilterSelected })}
                isActivated={isFiltersOpen}
              />
            </>
            : <Segmented
              options={vehiclesDashboardStatusesOptions}
              value={dashboardFilters.isActive ? VehiclesDashboardStatusesEnum.ACTIVE : VehiclesDashboardStatusesEnum.ARCHIVED}
              onChange={(value) => dispatch(setVehiclesDashboardFilters({
                ...dashboardFilters,
                ...initPaging,
                isActive: value === VehiclesDashboardStatusesEnum.ACTIVE,
                statuses: [],
              }))}
            />}
        </>
      )}
      <KeywordFiltersSection
        isFullWidth={page === VehiclesPageParamsEnum.DASHBOARD}
        filters={filters}
        searchValue={keywordValue}
        setSearchValue={changeKeywordValue}
        getFilteredResHandle={getFilteredResHandle}
        onClear={clearFilters}
        className={classNames('vehiclesFilters__filters', {
          'mobile-hidden': !isFiltersOpen && page === VehiclesPageParamsEnum.DASHBOARD,
        })}
        initDropdownSearch={initDropdownSearch}
      />
    </DashboardFilters>
  );
};

export default VehiclesFilters;
