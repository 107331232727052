import React from 'react';
import { Modal as ModalWindow } from 'antd';
import { VoidFunctionType } from '../../types/coreTypes';
import './Modal.scss';

type ModalProps = {
  visible: boolean;
  onCancel: VoidFunctionType;
  footer?: React.ReactNode | null;
  title?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  width?: string | number;
  maskClosable?: boolean;
  keyboard?: boolean,
  destroyOnClose?: boolean;
  afterClose?: VoidFunctionType;
}

const Modal: React.FC<ModalProps> = ({
  visible,
  onCancel,
  title,
  footer = null,
  children,
  className,
  width = 520,
  maskClosable,
  keyboard,
  destroyOnClose,
  afterClose,
}) => (
  <ModalWindow
    title={title}
    visible={visible}
    onCancel={onCancel}
    footer={footer}
    className={className}
    width={width}
    maskClosable={maskClosable}
    keyboard={keyboard}
    destroyOnClose={destroyOnClose}
    afterClose={afterClose}
  >
    {children}
  </ModalWindow>
);

export default Modal;
