import React, { useState } from 'react';
import { CheckboxValueType } from 'antd/es/checkbox/Group';
import Thead from '../../../../core/components/table-components/Thead';
import ThWithControls from '../../../../core/components/table-components/ThWithControls';
import ThNew from '../../../../core/components/table-components/table-new/ThNew';
import Tr from '../../../../core/components/table-components/Tr';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  salesInvoiceOrderLinesColumnsSelector,
  salesOrderPartRequestLinesFiltersSelector, salesOrderPartRequestLinesSelector,
} from '../../../../store/selectors/invoicingSelectors';
import { SearchTableFilterType } from '../../../../core/types/coreTypes';
import {
  setSalesInvoiceOrderLinesFilters,
} from '../../../../store/slices/invoicingSlice';

const SalesOrderLinesColumns: React.FC = () => {
  const dispatch = useAppDispatch();

  const columns = useAppSelector(salesInvoiceOrderLinesColumnsSelector);
  const filters = useAppSelector(salesOrderPartRequestLinesFiltersSelector);
  const data = useAppSelector(salesOrderPartRequestLinesSelector);
  const totalCount = data?.items?.length || 0;

  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    productNumber: filters?.productNumber,
    productName: filters?.productName,
  });

  const sortHandle = (field: number) => {
    const newFilters = {
      ...filters,
      order: filters?.order?.field === field
        ? { ...filters.order, isAsc: !filters.order?.isAsc }
        : { field, isAsc: true },
    };
    dispatch(setSalesInvoiceOrderLinesFilters(newFilters));
  };

  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      dispatch(setSalesInvoiceOrderLinesFilters({
        ...filters,
        [field]: '',
      }));
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    dispatch(setSalesInvoiceOrderLinesFilters({
      ...filters,
      [field]: searchVl[field]?.trim(),
    }));
  };

  const filtersList = [
    {
      dataIndex: 'nominalCodes',
      value: filters?.nominalCodeIds || [],
      onChangeHandle: (checkedValues: CheckboxValueType[]) => {
        const newValues = {
          ...filters,
          nominalCodeIds: checkedValues.length > 0 ? checkedValues as number[] : undefined,
        };
        dispatch(setSalesInvoiceOrderLinesFilters(newValues));
      },
    },
  ];

  return (
    <Thead>
      <Tr>
        {columns.map((col) => (
          <ThWithControls
            key={col.dataIndex}
            col={col}
            totalElements={totalCount}
            sortHandle={sortHandle}
            onChangeSearchHandle={onChangeSearchHandle}
            searchPressHandle={searchPressHandle}
            disabled={totalCount === 0}
            tableFilters={filters}
            filters={filtersList}
            disabledFilter={totalCount === 0}
            className={`invoicingOrderLinesTh--${col.dataIndex}`}
          />
        ))}
        <ThNew action>
          <span className="sr-only">Actions</span>
        </ThNew>
      </Tr>
    </Thead>
  );
};

export default SalesOrderLinesColumns;
