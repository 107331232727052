import React, { useState } from 'react';
import ThWithControls from '../../../core/components/table-components/ThWithControls';
import Tr from '../../../core/components/table-components/Tr';
import { DraftLinePartsKitFiltersType } from '../../types/commonTypes';
import ThNew from '../../../core/components/table-components/table-new/ThNew';
import { useAppSelector } from '../../../store/hooks';
import { quoteLinePartsKitColumnsSelector } from '../../../store/selectors/enquiriesSelector';
import { SearchTableFilterType } from '../../../core/types/coreTypes';

type PartsKitModeColumnsProps = {
  filters?: DraftLinePartsKitFiltersType,
  filtersHandle: (filters: DraftLinePartsKitFiltersType) => void,
  partsKitTotal: number,
}

const PartsKitModeColumns: React.FC<PartsKitModeColumnsProps> = ({ filters, filtersHandle, partsKitTotal }) => {
  const columns = useAppSelector(quoteLinePartsKitColumnsSelector);
  const [searchVl, setSearchVl] = useState<SearchTableFilterType>({
    partsKitCode: '',
    name: '',
    description: '',
  });
  const sortHandle = (field: number) => {
    filters && filtersHandle({
      ...filters,
      order: filters.order?.field === field
        ? { ...filters.order, isAsc: !filters.order.isAsc }
        : { field, isAsc: true },
    });
  };
  const onChangeSearchHandle = (v: string, field: string) => {
    if (!v) {
      filters && filtersHandle({
        ...filters,
        [field]: undefined,
      });
    }
    setSearchVl({ ...searchVl, [field]: v });
  };
  const searchPressHandle = (field: string) => {
    filters && filtersHandle({
      ...filters,
      [field]: searchVl[field]?.trim(),
    });
  };
  return (
    <Tr>
      {columns.map((col) => <ThWithControls
        col={col}
        sortHandle={sortHandle}
        onChangeSearchHandle={onChangeSearchHandle}
        searchPressHandle={searchPressHandle}
        totalElements={partsKitTotal}
        disabled={partsKitTotal === 0}
        searchVl={searchVl}
        className={`partsKitModeTh--${col.dataIndex}`}
        tableFilters={filters}
        insideModal
      />)}
      <ThNew className="partsKitModeTh--action"><span className="sr-only">Actions</span></ThNew>
    </Tr>
  );
};

export default PartsKitModeColumns;
