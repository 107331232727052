import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import {
  partsKitDetailsSelector,
} from '../../../../../../store/selectors/partsKitSelectors';
import PartKitsVehicles from './PartKitsVehicles';
import PartKitsInfo from './PartKitsInfo';
import SelectVehiclesModal, { AvailableVehiclesData } from '../../../../../common/components/select-vehicles-modal/SelectVehiclesModal';
import {
  vehicleProfilesGroupsUiWithoutAllSelector,
} from '../../../../../../store/selectors/sharedSelectors';
import { patchPartsKitThunk } from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import { suitableVehicleSourcesValuesSelector } from '../../../../../../store/selectors/coreSelectors';

const PartsKitDetailsSection: React.FC = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);
  const [isSelectVehicles, setSelectVehicles] = useState<boolean>(false);
  const availableVehicleGroups = useAppSelector(vehicleProfilesGroupsUiWithoutAllSelector);
  const { groups } = useAppSelector(suitableVehicleSourcesValuesSelector);

  const saveChangesNew = (data: AvailableVehiclesData) => {
    id && dispatch(patchPartsKitThunk({
      id: +id,
      data: {
        partsKitId: +id,
        ...data,
      },
      closeModal: () => setSelectVehicles(false),
    }));
  };

  const initSelectedIds = useMemo(() => {
    if (partsKitDetails?.suitableVehiclesSource === groups) return null;
    return partsKitDetails?.suitableForVehicles?.map(({ id }) => id) || null;
  }, [partsKitDetails?.suitableForVehicles, partsKitDetails?.suitableVehiclesSource, groups]);

  const initSelectedGroupIds = useMemo(() => {
    if (partsKitDetails?.suitableVehiclesSource !== groups) {
      return null;
    } else if (partsKitDetails?.suitableForVehicleGroupIds?.length === 0) {
      return availableVehicleGroups.items.map(({ id }) => id);
    }
    return partsKitDetails?.suitableForVehicleGroupIds;
  }, [partsKitDetails?.suitableForVehicleGroupIds, partsKitDetails?.suitableVehiclesSource, groups, availableVehicleGroups]);

  return (
    <>
      <SelectVehiclesModal
        isVisible={isSelectVehicles}
        onClose={() => setSelectVehicles(false)}
        saveChanges={saveChangesNew}
        initSelectedIds={initSelectedIds}
        initSelectedGroupIds={initSelectedGroupIds}
        initSelectedSource={partsKitDetails?.suitableVehiclesSource || null}
      />
      <div>
        <PartKitsInfo partsKitDetails={partsKitDetails} />
        <PartKitsVehicles openModal={() => setSelectVehicles(true)} />
      </div>
    </>
  );
};

export default PartsKitDetailsSection;
