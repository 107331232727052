import { useNavigate, useParams } from 'react-router-dom';
import { useState } from 'react';
import { routesPath } from '../../../core/enums/pathEnum';
import { useAppDispatch } from '../../../store/hooks';
import { duplicateEnquiryThunk } from '../../../store/thunks/enquiry/detailsThunks';
import { BooleanFunctionType, initSmallPaging } from '../../../core/types/coreTypes';
import { getQuoteLinesCanNotBeCopiedThunk } from '../../../store/thunks/enquiry/quoteConfiguratorThunks';

export const useEnquiryDetailsActions = (
  setConfirmationModal: BooleanFunctionType,
  setIsDuplicateConfirmation: BooleanFunctionType,
) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isMissingLines, setIsMissingLines] = useState<boolean>(false);

  const [changeCustomerModal, setChangeCustomerModal] = useState(false);
  const checkMissingLinesAndDuplicate = () => {
    id && dispatch(getQuoteLinesCanNotBeCopiedThunk({
      filters: {
        enquiryId: +id,
        page: initSmallPaging.page,
        pageSize: initSmallPaging.pageSize,
        canBeCopied: false,
      },
      openModal: () => setIsMissingLines(true),
    }));
  };

  const duplicate = () => {
    id && dispatch(duplicateEnquiryThunk({
      id: +id,
      navigateTo: (iD) => navigate(`/${routesPath.ENQUIRIES}/${iD}`),
      closeModal: () => {
        setIsDuplicateConfirmation(false);
        checkMissingLinesAndDuplicate();
      },
    }));
  };
  const deleteHandler = () => {
    setConfirmationModal(true);
  };

  return {
    duplicate,
    isMissingLines,
    setIsMissingLines,
    deleteHandler,
    changeCustomerModal,
    setChangeCustomerModal,
  };
};
