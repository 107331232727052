import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle, faCopy, faTimesCircle, faTrashAlt,
} from '@fortawesome/free-regular-svg-icons';
import DetailsHead from '../../../core/components/details-head/DetailsHead';
import Layout from '../../../core/components/layout/Layout';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { currentUserSelector } from '../../../store/selectors/accountSelectors';
import { PartsKitsStatusUiNames, PermissionEnum } from '../../../core/enums/dictionariesEnums';
import { usePartsKitActions } from './hooks/usePartsKitActions';
import {
  partsKitAffectedItemsFiltersSelector,
  partsKitDetailsSelector,
} from '../../../store/selectors/partsKitSelectors';
import {
  activatePartsKitThunk, deletePartsKitThunk,
  getPartsKitAffectedThunk, getPartsKitAvailableCategoriesThunk,
  getPartsKitById, getPartsKitComponentsSummary,
} from '../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';
import PartsKitHead from './components/head/PartsKitHead';
import PartsKitContentTabs from './components/content-tabs/PartsKitContentTabs';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';
import SimpleInactivationModal from './components/modals/SimpleInactivationModal';
import DuplicatePartsKitModal from './components/modals/DuplicatePartsKitModal';
import PartsKitsContent from './components/content/PartsKitsContent';
import './PartsKitsViewPageContainer.scss';
import { partsKitsStatusesSelector } from '../../../store/selectors/coreStatusesSelectors';
import { usePartsKitParams } from './hooks/usePartsKitParams';
import { clearPartsKitData } from './utils/clearPartsKitData';
import { PartsKitModes, PartsKitsViewPageEnums } from './enums/PartsKitsViewPageEnums';
import { getPartsKitComponentsOrdering } from '../../../store/thunks/core/coreOrderingThunks';
import PartsKitsAffectedEntitiesModal from './components/modals/PartsKitsAffectedEntitiesModal';

const PartsKitsViewPageContainer:React.FC = () => {
  const { id } = useParams();
  const {
    params,
    backHandle,
    navigate,
    setModeParam,
    options,
    setSearchParams,
  } = usePartsKitParams();

  const dispatch = useAppDispatch();
  const currentUser = useAppSelector(currentUserSelector);
  const partsKitDetails = useAppSelector(partsKitDetailsSelector);
  const affectedItemsFilters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const partsKitsStatuses = useAppSelector(partsKitsStatusesSelector);
  const checkStatusHandler = (statusId: number | undefined, statusUiName: string) : boolean => {
    const currentStatus = partsKitsStatuses.find((el) => el.value === statusId);
    return currentStatus?.label === statusUiName;
  };

  const {
    isDuplicate,
    setDuplicate,
    isConfirm,
    setConfirm,
    isSimpleInactivate,
    setSimpleInactivate,
  } = usePartsKitActions();

  const actions = [
    {
      label: 'Duplicate parts kit',
      key: 'duplicatePartsKit',
      icon: <FontAwesomeIcon icon={faCopy} />,
      onClick: () => setDuplicate(true),
      restrictedTo: PermissionEnum.StockPartsKitEditFields,
    },
    {
      label: 'Activate parts kit',
      key: 'activatePartsKit',
      icon: <FontAwesomeIcon icon={faCheckCircle} />,
      onClick: () => id && dispatch(activatePartsKitThunk({ id: +id })),
      restrictedTo: PermissionEnum.StockPartsKitEditFields,
      disabled: checkStatusHandler(partsKitDetails?.status, PartsKitsStatusUiNames.Active),
    },
    {
      label: 'Inactivate parts kit',
      key: 'inactivatePartsKit',
      icon: <FontAwesomeIcon icon={faTimesCircle} />,
      onClick: () => id && dispatch(getPartsKitAffectedThunk({
        id: +id,
        filters: affectedItemsFilters,
        confirmAction: () => setSimpleInactivate(true),
      })),
      restrictedTo: PermissionEnum.StockPartsKitEditFields,
      disabled: checkStatusHandler(partsKitDetails?.status, PartsKitsStatusUiNames.Inactive),
    },
    {
      label: 'Delete parts kit',
      key: 'deletePartsKit',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => id && dispatch(getPartsKitAffectedThunk({
        id: +id,
        filters: affectedItemsFilters,
        confirmAction: () => setConfirm(true),
      })),
      restrictedTo: PermissionEnum.StockPartsKitDelete,
    },
  ];
  useEffect(() => {
    if (currentUser) {
      const modeParam = params.get('mode');
      const { permissions } = currentUser.userContextRole;
      if (!modeParam && permissions.includes(PermissionEnum.StockPartsKitViewPage)) {
        setSearchParams({ mode: options[0].value }, { replace: true });
      } else {
        if (!PartsKitModes.includes(modeParam as PartsKitsViewPageEnums)) {
          setSearchParams({ mode: PartsKitsViewPageEnums.PARTS_KITS_DETAILS }, { replace: true });
        }
      }
    }
    // eslint-disable-next-line
  }, [currentUser, partsKitDetails]);

  useEffect(() => {
    if (id) {
      dispatch(getPartsKitById({ id: +id }));
      dispatch(getPartsKitComponentsSummary({ id: +id }));
    }
    // eslint-disable-next-line
  }, [id, params]);

  useEffect(() => {
    dispatch(getPartsKitAvailableCategoriesThunk({ keyword: undefined }));
    dispatch(getPartsKitComponentsOrdering());
    return () => {
      clearPartsKitData(dispatch);
    };
    // eslint-disable-next-line
  }, []);

  return (
    <Layout
      subHeader={<DetailsHead
        goBack={backHandle}
        actions={actions}
        className="partskitsViewPageDetailsHead"
      >
        <PartsKitHead />
      </DetailsHead>}
    >
      <PartsKitsAffectedEntitiesModal
        partsKitName={partsKitDetails?.name || '-'}
      />
      <ConfirmationModal
        isVisible={isConfirm}
        onCancel={() => {
          setConfirm(false);
        }}
        isDelete
        confirmHandler={() => partsKitDetails?.id && dispatch(deletePartsKitThunk({
          id: partsKitDetails?.id,
          closeModal: () => setConfirm(false),
          showNotification: true,
          navigate,
        }))}
      />
      <DuplicatePartsKitModal
        id={partsKitDetails?.id}
        isVisible={isDuplicate}
        onCancel={() => setDuplicate(false)}
      />
      <SimpleInactivationModal
        isVisible={isSimpleInactivate}
        onCancel={() => {
          setSimpleInactivate(false);
        }}
        id={partsKitDetails?.id}
        name={partsKitDetails?.name || ''}
      />
      <PartsKitContentTabs
        mode={params.get('mode')}
        setModeParam={setModeParam}
        allowedTabs={options}
      />
      <PartsKitsContent mode={params.get('mode')} />
    </Layout>
  );
};

export default PartsKitsViewPageContainer;
