import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  CoreAttachListElType,
  GenericColumnType, infiniteScrollInitPaging,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import {
  setDashboardLoading, setLoading, setSuccessMessage,
} from '../../slices/coreSlice';
import { jobsAPI } from '../../../api/jobsApi';
import { GetJobDashboardResponse, JobDto, JobsFiltersType } from '../../../job/jobs/types/jobsTypes';
import { setJobColumnsPagination, setJobsCollections } from '../../slices/jobsSlice';
import { jobDashboardCollectionsHelper } from '../../../job/jobs/utils/jobDashboardCollectionsHelper';
import { uploadAttachmentsAsyncHandle } from '../../../common/utils/attachmentsHandlers';
import { SUCCESSFUL_CREATE } from '../../../core/utils/successMessages';
import { RootState } from '../../store';
import { statusTransitionErrorHandle } from '../../../core/utils/statusTransitionErrorHandle';
import { getJobByIdThunk } from './jobThunks';

export const getJobsDashboardThunk = createAsyncThunk<ResponseSingleResult<GetJobDashboardResponse> | null,
{ filters: JobsFiltersType, signal?: AbortSignal }
>(
  'get/JobsDashboard',
  async ({ filters, signal }, { dispatch, rejectWithValue }) => {
    dispatch(setDashboardLoading(true));
    dispatch(setJobsCollections(null));
    try {
      const response = await jobsAPI.fetchJobsDashboard(filters, signal);
      dispatch(setDashboardLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getJobsDashboardNewPortion = createAsyncThunk<ResponseSingleResult<GetJobDashboardResponse> | null,
{
  filters: JobsFiltersType
  currentStatus: number,
  // eslint-disable-next-line
  setNewPortion:(status: number, data: JobDto[]) => void,
  // eslint-disable-next-line
    }>(
    'get/JobsDashboardNewPortion',
    async ({
      filters, currentStatus, setNewPortion,
    }, { getState, dispatch, rejectWithValue }) => {
      dispatch(setLoading(true));
      try {
        const response = await jobsAPI.fetchJobsDashboard(filters);
        const { jobs } = getState() as RootState;
        const { jobColumnsPagination } = jobs;
        const resCollections = response.data.data.jobCollections;
        const list = resCollections?.find((el) => el.status === currentStatus)?.jobs || [];
        setNewPortion(currentStatus, list);
        const newPagination = jobColumnsPagination.map((el) => {
          if (el.status === currentStatus) {
            return {
              ...el, currentPage: filters.page,
            };
          } else return el;
        });
        dispatch(setJobColumnsPagination(newPagination));
        dispatch(setLoading(false));
        return response.data;
      } catch (err) {
        const error = err as AxiosErrorResponse;
        dispatch(setLoading(false));
        return rejectWithValue(error.response?.data);
      }
    },
    );

export const changeJobsDashboardLayoutThunk = createAsyncThunk<ResponseSingleResult,
{
  layoutSettings: Array<GenericColumnType<Array<JobDto>>> | null,
  closeModal: VoidFunctionType,
}
>(
  'put/JobsDashboardLayout',
  async ({ layoutSettings, closeModal }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const {
        core: { currentBusinessArea },
        jobs: { filters },
      } = getState() as RootState;
      const layout = layoutSettings?.map((l) => ({
        status: l.status,
        ordinalNumber: l.order,
        isVisible: !l.isHidden,
      })) || [];
      const response = await jobsAPI.changeJobDashboardLayout(layout);
      dispatch(setJobsCollections(layoutSettings));
      currentBusinessArea && dispatch(getJobsDashboardThunk({
        filters: {
          ...filters,
          businessArea: currentBusinessArea,
          page: 1,
          applyPersonalFilter: true,
        },
      }));
      closeModal();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const editJobDashStatusThunk = createAsyncThunk<
ResponseSingleResult,
{
  id: number,
  statusTransition: number,
  sourceId: number,
  setColumns: VoidFunctionType,
  list?: CoreAttachListElType[],
  comment?: string,
  onClose?: VoidFunctionType,
  triggerProceedToQualityCheckModal?: VoidFunctionType,
}>(
  'patch/JobDashStatusTransition',
  async ({
    id, statusTransition, sourceId, setColumns, onClose,
    list, comment, triggerProceedToQualityCheckModal,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const { jobs, core } = getState() as RootState;
      const { currentBusinessArea } = core;
      const { jobColumnsPagination, jobCollections, filters } = jobs;
      if (list && list.length > 0) {
        const uploadUriArr = list.map((el) => ({ fileName: el.fileName, uploadGuid: el.uploadGuid }));

        const resUploadUri = await jobsAPI.postJobAttachmentUploadUri(id, uploadUriArr);
        const attachments = await uploadAttachmentsAsyncHandle(
          list,
          comment || '',
          resUploadUri,
          dispatch,
        );

        await jobsAPI.postJobAttachments(id, attachments);
        dispatch(setSuccessMessage({ message: SUCCESSFUL_CREATE }));
      }
      const response = await jobsAPI.changeJobStatus(id, statusTransition);
      if (triggerProceedToQualityCheckModal) {
        triggerProceedToQualityCheckModal();
        dispatch(getJobByIdThunk({ id }));
      }
      const res = await jobsAPI.fetchJobsDashboard(
        {
          ...filters,
          ...infiniteScrollInitPaging,
          businessArea: currentBusinessArea,
          applyPersonalFilter: true,
          statuses: [statusTransition, sourceId],
        },
      );
      const collections = res.data.data.jobCollections;
      if (jobCollections && collections?.length) {
        const { newPagination, newJobCollections } = jobDashboardCollectionsHelper(
          jobCollections,
          collections,
          jobColumnsPagination,
          statusTransition,
          sourceId,
        );
        dispatch(setJobsCollections(newJobCollections));
        dispatch(setJobColumnsPagination(newPagination));
      }
      onClose && onClose();
      dispatch(setLoading(false));
      return response.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      const errors = error.response?.data.errors || [];
      dispatch(setLoading(false));
      statusTransitionErrorHandle(errors, dispatch);
      setColumns();
      onClose && onClose();
      return rejectWithValue(error.response?.data);
    }
  },
);
