import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { useAppDispatch } from '../../store/hooks';

export const useNoteActivityLog = (content: string) => {
  const dispatch = useAppDispatch();
  const [value, setValue] = useState('');
  const [isEditModal, setIsEditModal] = useState(false);
  const [isConfirmationModal, setIsConfirmationModal] = useState(false);

  const closeHandle = () => {
    setIsEditModal(false);
    setValue('');
  };

  const menuItems = [
    {
      label: 'Edit note',
      key: 'edit-note',
      icon: <FontAwesomeIcon icon={faEdit} />,
      onClick: () => {
        setValue(content);
        setIsEditModal(true);
      },
    },
    {
      label: 'Delete note',
      key: 'delete-note',
      icon: <FontAwesomeIcon icon={faTrashAlt} />,
      onClick: () => setIsConfirmationModal(true),
    },
  ];

  return {
    dispatch,
    value,
    setValue,
    isEditModal,
    isConfirmationModal,
    setIsConfirmationModal,
    closeHandle,
    menuItems,
  };
};
