import React from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faQuestionCircle, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Tooltip } from 'antd';
import Tbody from '../../../../../../core/components/table-components/Tbody';
import Tr from '../../../../../../core/components/table-components/Tr';
import { PermissionEnum } from '../../../../../../core/enums/dictionariesEnums';
import Checkbox from '../../../../../../core/components/checkbox/Checkbox';
import Dropdown from '../../../../../../core/components/dropdown/Dropdown';
import { partsKitAffectedItemsFiltersSelector, partsKitComponentsUiSelector } from '../../../../../../store/selectors/partsKitSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import EmptyTableSection from '../../../../../../core/components/table-components/EmptyTableSection';
import { NumberFunctionType } from '../../../../../../core/types/coreTypes';
import EditableComponentCell from './EditableComponentCell';
import usePermission from '../../../../../../permissions-handling/permissionHook';
import { QuoteUnitsOfMeasureSelector } from '../../../../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../../../../../../common/components/truncate-tooltip/TruncateTooltip';
import PrivateLineLink from '../../../../../../common/components/private-line-link/PrivateLineLink';
import { routesPath } from '../../../../../../core/enums/pathEnum';
import TdNew from '../../../../../../core/components/table-components/table-new/TdNew';
import { getPartsKitAffectedThunk } from '../../../../../../store/thunks/stock/parts-kit/partsKitsViewPageThunks';

type KitComponentsCellsProps = {
  checkedKeys: number[],
  checkHandle: NumberFunctionType,
  setIsDelete: NumberFunctionType,
  openCustomEdit: NumberFunctionType,
}

const KitComponentsCells: React.FC<KitComponentsCellsProps> = ({
  checkedKeys, checkHandle, setIsDelete, openCustomEdit,
}) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const affectedFilters = useAppSelector(partsKitAffectedItemsFiltersSelector);
  const allowedChange = usePermission(PermissionEnum.StockPartsKitEditComponentFieldsAndUnlockComponentActions);
  const partsKitComponents = useAppSelector(partsKitComponentsUiSelector);
  const unitsOfMeasure = useAppSelector(QuoteUnitsOfMeasureSelector);

  const usualLineEditableKeys = [
    'quantity',
    'tax',
  ];

  const hiddenKeys = ['id', 'partId', 'isSuitableForAnyPartsKitVehicle', 'unitOfMeasure', 'hasPrice'];
  const renderValue = (
    key: string,
    value: string | number | boolean | null,
    partId: number | null,
    hasPrice: boolean,
    measure?: number,
  ) => {
    if (key === 'quantity') {
      const unitOfMeasureLabel = unitsOfMeasure.find((m) => m.value === measure)?.label || '';
      return `${value} ${unitOfMeasureLabel}`;
    }
    if (key === 'tax') {
      if (value === null) {
        return '-';
      } else {
        return `${value} %`;
      }
    }
    if (key === 'partNumber' || key === 'partName') {
      const truncatedContent = (
        <TruncateTooltip
          value={value as string}
          table
          hideTooltipOnMobile
        />
      );
      return partId
        ? <PrivateLineLink to={`/${routesPath.STOCK}/part/${partId}`} permission={PermissionEnum.StockPartViewPage}>
          {truncatedContent}
        </PrivateLineLink>
        : truncatedContent;
    }
    if ((key === 'netPrice' || key === 'unitPrice') && !hasPrice) {
      return <div>
        <span
          style={{ marginRight: '3px' }}
        >
          -
        </span>
        {' '}
        <Tooltip
          title='This part does not have any prices as of today. "0" price will be used by default.'
          overlayClassName="truncate-table-tooltip"
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </Tooltip>
      </div>;
    } else {
      return value || '-';
    }
  };
  return (
    <Tbody>
      {partsKitComponents && partsKitComponents.length > 0
        ? <>
          {partsKitComponents?.map((d, i) => <Tr
            key={i}
            className={d.isSuitableForAnyPartsKitVehicle !== null && !d.isSuitableForAnyPartsKitVehicle ? 'notSuitableForAllVeh' : ''}
          >
            {allowedChange && (
              <TdNew>
                <Checkbox
                  checked={checkedKeys.includes(d.id)}
                  onChange={() => checkHandle(d.id)}
                  table
                />
              </TdNew>
            )}
            {Object.entries(d).map(([key, value]) => {
              if (hiddenKeys.includes(key)) return null;
              return usualLineEditableKeys.includes(key) && allowedChange && d.partNumber
                ? <EditableComponentCell
                  value={value as string}
                  cellKey={key}
                  data={d}
                />
                : <TdNew key={key}>
                  <div
                    onClick={() => allowedChange && !d.partNumber && key !== 'number' && openCustomEdit(d.id)}
                    className={allowedChange && !d.partNumber && key !== 'number' ? 'editableComponentCell' : ''}
                  >
                    {renderValue(key, value, d.partId, d.hasPrice, d.unitOfMeasure)}
                  </div>
                </TdNew>;
            })}
            {allowedChange && (
              <TdNew action>
                <Dropdown
                  menuItems={[
                    {
                      label: 'Delete component',
                      key: 'deleteComponent',
                      icon: <FontAwesomeIcon icon={faTrashAlt} />,
                      onClick: () => id && dispatch(getPartsKitAffectedThunk({
                        id: +id,
                        filters: affectedFilters,
                        confirmAction: () => setIsDelete(d.id),
                      })),
                    },
                  ]}
                  icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
                />
              </TdNew>
            )}
          </Tr>)}
        </>
        : <EmptyTableSection colSpan={10} text="No records" />}
    </Tbody>
  );
};

export default KitComponentsCells;
