import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  AxiosErrorResponse,
  DictionaryResponse, initSmallPaging,
  initUndefinedList,
  ResponseResult,
  ResponseSingleResult,
  VoidFunctionType,
} from '../../../core/types/coreTypes';
import { stockApi } from '../../../api/stockApi';
import { PartDtoType, StockFiltersType } from '../../../stock/search/types/stockSearchTypes';
import { setPartQuantitiesPaging } from '../../slices/stockSlice';
import {
  GetPartRequestStatisticsResponse,
  GetPartStatisticsResponse,
  GetPurchaseOrderStatisticsResponse,
} from '../../../stock/dashboard/types/types';
import { setFetching, setLoading, setSuccessMessage } from '../../slices/coreSlice';
import {
  GetPartQuantitiesDataType, GetPartQuantitiesParams,
  PartQtyBaseModelType,
  PatchPartQtyType,
} from '../../../stock/part-view-page/types/types';
import { SUCCESSFUL_DELETE } from '../../../core/utils/successMessages';
import { getPartViewTimeline, getStockPartByIdThunk } from './part/partViewPageThunks';
import { RootState } from '../../store';
import { setPurchaseOrderPartSearch } from '../../slices/purchaseOrderSlice';

export const getPartTypes = createAsyncThunk<DictionaryResponse>(
  'get/PartTypes',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPartTypes();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockSearch = createAsyncThunk<ResponseResult<PartDtoType[] | undefined>, {
  filters: StockFiltersType, isPurchaseOrderPage?: boolean,
}>(
  'get/StockSearch',
  async ({ filters, isPurchaseOrderPage }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      if (isPurchaseOrderPage) {
        dispatch(setPurchaseOrderPartSearch(res.data.data));
        return {
          data: initUndefinedList, errors: [], status: '200', success: true,
        };
      } else return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockPartsStatistic = createAsyncThunk<ResponseSingleResult<GetPartStatisticsResponse>>(
  'get/StockPartsStatistics',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPartsStatistic();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getStockPurchaseOrdersStatistic = createAsyncThunk<ResponseSingleResult<GetPurchaseOrderStatisticsResponse>>(
  'get/StockPurchaseOrderStatistic',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPurchaseOrderStatistic();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);
export const getStockPartRequestsStatistic = createAsyncThunk<ResponseSingleResult<GetPartRequestStatisticsResponse>>(
  'get/StockPartRequestStatistic',
  async (_, { rejectWithValue }) => {
    try {
      const res = await stockApi.fetchPartRequestStatistic();
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getStockSearchReorderLevels = createAsyncThunk<ResponseResult<PartDtoType[]>, {
  filters: StockFiltersType,
}>(
  'get/StockSearchReorderLevels',
  async ({ filters }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchStockParts(filters);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const getPartQuantities = createAsyncThunk<ResponseSingleResult<GetPartQuantitiesDataType>, {
  params: GetPartQuantitiesParams,
}>(
  'get/PartQuantities',
  async ({ params }, { dispatch, rejectWithValue }) => {
    dispatch(setLoading(true));
    try {
      const res = await stockApi.fetchPartQuantities(params);
      dispatch(setLoading(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setLoading(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const addPartQuantities = createAsyncThunk<ResponseSingleResult<{ partId: number, id: number }>, {
  partId: number, data: PartQtyBaseModelType, onFinish: VoidFunctionType,
}>(
  'post/PartQuantities',
  async ({
    partId, data, onFinish,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { stock } = getState() as RootState;
      const { partQuantitiesPaging, partViewPageTimelineFilters } = stock;
      const res = await stockApi.postPartQuantities(partId, data);
      partQuantitiesPaging.order && dispatch(getPartQuantities({
        params: {
          partId,
          statuses: [data.status],
          page: partQuantitiesPaging.page,
          pageSize: partQuantitiesPaging.pageSize,
          order: partQuantitiesPaging.order,
        },
      }));
      dispatch(getStockPartByIdThunk({ id: partId }));
      dispatch(getPartViewTimeline({ partId, filters: { ...partViewPageTimelineFilters, page: 1 } }));
      const toastId = Math.random();
      dispatch(setSuccessMessage({ message: 'Part quantity was successfully added.', toastId }));
      onFinish();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const deletePartQuantities = createAsyncThunk<ResponseSingleResult, {
  partId: number, id: number, onFinish: VoidFunctionType, statusToRefetch?: number,
}>(
  'delete/PartQuantities',
  async ({
    partId, id, onFinish, statusToRefetch,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { stock } = getState() as RootState;
      const { partQuantitiesPaging } = stock;
      const res = await stockApi.deletePartQuantities(partId, id);
      dispatch(setPartQuantitiesPaging({ ...partQuantitiesPaging, ...initSmallPaging }));
      statusToRefetch && partQuantitiesPaging.order && dispatch(getPartQuantities({
        params: {
          partId,
          statuses: [statusToRefetch],
          page: 1,
          pageSize: 5,
          order: partQuantitiesPaging.order,
        },
      }));
      dispatch(getStockPartByIdThunk({ id: partId }));
      const toastId = Math.random();
      dispatch(setSuccessMessage({ message: SUCCESSFUL_DELETE, toastId }));
      onFinish();
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);

export const patchPartQuantities = createAsyncThunk<ResponseSingleResult, {
  partId: number, id: number, data: PatchPartQtyType, statusToRefetch?: number, onClose?: VoidFunctionType,
}>(
  'patch/PartQuantities',
  async ({
    partId, id, data, statusToRefetch, onClose,
  }, { getState, dispatch, rejectWithValue }) => {
    dispatch(setFetching(true));
    try {
      const { stock } = getState() as RootState;
      const { partQuantitiesPaging, partViewPageTimelineFilters } = stock;
      const res = await stockApi.patchPartQuantities(partId, id, data);
      onClose && onClose();
      dispatch(setPartQuantitiesPaging({ ...partQuantitiesPaging, page: 1, pageSize: 5 }));
      if (statusToRefetch && partQuantitiesPaging.order) {
        dispatch(getPartQuantities({
          params: {
            partId,
            statuses: [statusToRefetch],
            page: 1,
            pageSize: 5,
            order: partQuantitiesPaging.order,
          },
        }));
      }
      dispatch(getStockPartByIdThunk({ id: partId }));
      dispatch(getPartViewTimeline({ partId, filters: { ...partViewPageTimelineFilters, page: 1 } }));
      dispatch(setFetching(false));
      return res.data;
    } catch (err) {
      const error = err as AxiosErrorResponse;
      dispatch(setFetching(false));
      return rejectWithValue(error.response?.data);
    }
  },
);
