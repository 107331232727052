export enum EmployeeSalaryTypeEnum {
  PerHour = 'Per hour',
  PerAnnum = 'Per annum',
}

export enum EmployeeDepartmentUiNamesEnum {
  Management = 'Management',
  HR = 'HR',
  Sales = 'Sales',
  Production = 'Production',
  WoodManufacturing = 'Wood manufacturing',
}
