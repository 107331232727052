import React, { useMemo } from 'react';
import { Controller, useForm, useFormState } from 'react-hook-form';
import { UseFormReset } from 'react-hook-form/dist/types/form';
import AddressField from '../address/AddressField';
import { BooleanFunctionType } from '../../../core/types/coreTypes';
import ButtonActions from '../../../core/components/button-actions/ButtonActions';
import { ErrorsEnum } from '../../../core/enums/errorsEnum';
import { AddressFields, initAddressFields } from '../../types/commonTypes';
import Input from '../../../core/components/input/Input';
import {
  EmailFieldValidationSchema,
  NameFieldValidationSchema,
  NewContactFormFields,
  newContactFormFieldsDefaults, PhoneMobileFieldsValidationSchema, PositionFieldValidationSchema,
} from '../../types/createNewContactSchema';

type CreateNewContactFormProps = {
  setMode: (value: 'new' | 'existed' | string) => void,
  addressFields: AddressFields,
  setLocation: BooleanFunctionType,
  setAddressFields: (values: AddressFields) => void,
  companyNameValue: string,
  submitHandle: (values: NewContactFormFields, reset: UseFormReset<NewContactFormFields>) => void,
  isLoading?: boolean,
}

const CreateNewContactForm: React.FC<CreateNewContactFormProps> = ({
  setMode, addressFields, setLocation, setAddressFields, companyNameValue, submitHandle, isLoading,
}) => {
  const {
    control, handleSubmit, reset, watch, setError, setValue,
  } = useForm<NewContactFormFields>({
    defaultValues: newContactFormFieldsDefaults,
    mode: 'all',
  });
  const { errors } = useFormState({ control });
  const email = watch('email');
  const telephone = watch('telephone');
  const mobile = watch('mobile');
  const contacts = useMemo(() => [email, telephone, mobile], [email, telephone, mobile]);

  const onSubmit = (values: NewContactFormFields) => {
    submitHandle(values, reset);
  };
  return (
    <form className="addContact__newForm details-form" onSubmit={handleSubmit(onSubmit)}>
      <Input
        value={companyNameValue}
        disabled
        label="Company name"
        className="details-form__field--lg"
      />
      <Controller
        name="firstName"
        control={control}
        rules={NameFieldValidationSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim()) {
                setValue('firstName', '');
                setError('firstName', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.firstName?.message}
            label="First name"
          />
        )}
      />
      <Controller
        name="lastName"
        control={control}
        rules={NameFieldValidationSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim()) {
                setValue('lastName', '');
                setError('lastName', { type: 'required', message: ErrorsEnum.REQUIRED });
              }
            }}
            error={errors.lastName?.message}
            label="Last name"
          />
        )}
      />
      <Controller
        name="position"
        control={control}
        rules={PositionFieldValidationSchema}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={field.onChange}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim()) {
                setValue('position', '');
              }
            }}
            error={errors.position?.message}
            label="Position"
          />
        )}
      />
      <Controller
        name="email"
        control={control}
        rules={EmailFieldValidationSchema(contacts.every((el) => !el?.trim()))}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              errors.telephone?.type === 'required' && setError('telephone', { type: 'required', message: undefined });
              errors.mobile?.type === 'required' && setError('mobile', { type: 'required', message: undefined });
            }}
            error={errors.email?.message}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim() && contacts.every((el) => !el?.trim())) {
                setValue('email', '');
                setError('email', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
              }
            }}
            label="Email"
          />
        )}
      />
      <Controller
        name="telephone"
        control={control}
        rules={PhoneMobileFieldsValidationSchema(contacts.every((el) => !el?.trim()))}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              errors.email?.type === 'required' && setError('email', { type: 'required', message: undefined });
              errors.mobile?.type === 'required' && setError('mobile', { type: 'required', message: undefined });
            }}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim() && contacts.every((el) => !el?.trim())) {
                setValue('telephone', '');
                setError('telephone', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
              }
            }}
            error={errors.telephone?.message}
            label="Telephone"
          />
        )}
      />
      <Controller
        name="mobile"
        control={control}
        rules={PhoneMobileFieldsValidationSchema(contacts.every((el) => !el?.trim()))}
        render={({ field }) => (
          <Input
            value={field.value}
            onChange={(e) => {
              field.onChange(e.target.value);
              errors.telephone?.type === 'required' && setError('telephone', { type: 'required', message: undefined });
              errors.email?.type === 'required' && setError('email', { type: 'required', message: undefined });
            }}
            error={errors.mobile?.message}
            onBlur={(e) => {
              const val = e.target.value;
              if (!val.trim() && contacts.every((el) => !el?.trim())) {
                setValue('mobile', '');
                setError('mobile', { type: 'required', message: ErrorsEnum.AT_LEAST_ONE_CONTACT });
              }
            }}
            label="Mobile"
          />
        )}
      />
      <AddressField addressFields={addressFields} setAddressModal={setLocation} />
      <ButtonActions
        cancelLabel="Cancel"
        cancelClick={() => {
          setMode('');
          reset();
          setAddressFields(initAddressFields);
        }}
        createType="submit"
        createLabel="Save"
        className="details-form__actions"
        isLoading={isLoading}
      />
    </form>
  );
};

export default CreateNewContactForm;
