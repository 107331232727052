import { useNavigate, useSearchParams } from 'react-router-dom';
import { EmployeesPageParamEnums, EmployeesPageParams, EmployeeUrlParamsNames } from '../enums/EmployeesPageParamsEnums';
import { employeesDashStatusesSelector } from '../../../store/selectors/employeesSelector';
import { useAppSelector } from '../../../store/hooks';

export const useEmployeesPageParams = () => {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  const employeesDashStatuses = useAppSelector(employeesDashStatusesSelector);
  const page = params.get(EmployeeUrlParamsNames.PAGE);
  const status = params.get(EmployeeUrlParamsNames.STATUS);

  const setPageParam = (mode: string) => {
    if (EmployeesPageParams.includes(mode as EmployeesPageParamEnums)) {
      params.append(EmployeeUrlParamsNames.PAGE, mode);
    } else {
      params.delete(EmployeeUrlParamsNames.PAGE);
    }
    params.delete(EmployeeUrlParamsNames.STATUS);
    navigate({ search: `?${EmployeeUrlParamsNames.PAGE}=${mode}` }, { replace: true });
  };

  const setEmployeeStatusParam = (status: string) => {
    if (employeesDashStatuses.find((st) => st.value.toString() === status)) {
      if (params.get(EmployeeUrlParamsNames.STATUS)) {
        params.set(EmployeeUrlParamsNames.STATUS, status);
      } else {
        params.append(EmployeeUrlParamsNames.STATUS, status);
      }
    } else {
      params.delete(EmployeeUrlParamsNames.STATUS);
    }
    navigate({ search: params.toString() });
  };

  return {
    page,
    status,
    setPageParam,
    setEmployeeStatusParam,
  };
};
