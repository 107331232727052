import React from 'react';
import { VoidFunctionType } from '../../../core/types/coreTypes';
import { PartRequestLineDto, PatchPartReqLineDto } from '../../types/commonTypes';
import ConfirmationModal from '../../../core/components/confirmation-modal/ConfirmationModal';

type MarkAsFreeOfChargeModalProps = {
  visible: boolean,
  onCancel: VoidFunctionType,
  currentLineId?: number,
  partRequestLines: PartRequestLineDto[],
  confirmThunk: (data: PatchPartReqLineDto) => void,
  entityName: string,
}

const MarkAsFreeOfChargeModal: React.FC<MarkAsFreeOfChargeModalProps> = ({
  visible, onCancel, currentLineId, partRequestLines, confirmThunk, entityName,
}) => {
  const currentEl = partRequestLines.find((el) => el.id === currentLineId);
  const isAlreadyFree = currentEl?.isFree;
  const title = isAlreadyFree ? 'Mark as Billed' : 'Mark as Free of charge';
  const operationName = isAlreadyFree ? '"Billed"' : '"Free of charge"';
  const verbForm = isAlreadyFree ? '' : 'not';
  const confirmHandle = () => confirmThunk({ isFree: !isAlreadyFree });
  return (
    <ConfirmationModal
      isVisible={visible}
      customTitle={title}
      customText={<>
        <b>{`"${currentEl?.productName}"`}</b>
        <br />
        {`This part request line will be marked as
          ${operationName}
          and line amounts will
          ${verbForm}
          be included into total
          ${entityName}
          net and gross calculations.`}
      </>}
      confirmHandler={confirmHandle}
      onCancel={onCancel}
      customCreateLabel="Yes"
    />
  );
};

export default MarkAsFreeOfChargeModal;
