import { ACCOUNT_NO_REGEX, EMAIL_REGEX, PHONE_FAX_REGEX } from '../utils/regex';

export enum ErrorsEnum {
  REQUIRED = 'This field is required',
  ONLY_TEXT_AND_DASH_APOSTROPHE = 'Entered value should not include numbers or special characters except for "-" or "\'"',
  ONLY_NUMBERS_LETTERS = 'Entered value should not have spaces or special characters.',
  UNIQUE_VALUE = 'Entered value is already being used in the system',
  INVALID_TELEPHONE = 'Please enter a valid telephone number',
  INVALID_MOBILE = 'Please enter a valid mobile number',
  INVALID_EMAIL = 'Please enter a valid email address',
  AT_LEAST_ONE_CONTACT = 'At least one contact is required',
  INVALID_NI_NUMBER = 'Please enter a valid N.I. number',
  SPACES_ARE_NOT_ALLOWED = 'Entered value should not have spaces',
  NO_NON_LATIN_LETTERS = 'Entered value should not have non-latin letters.',
  GREATER_THAN_ZERO = 'Entered value should be greater than 0',
  INCORRECT_PASSWORD = 'Incorrect password'
}

export const RequiredFieldSchema = { required: ErrorsEnum.REQUIRED };
export const OnlyNumbersAndLettersFieldSchema = { pattern: { value: ACCOUNT_NO_REGEX, message: ErrorsEnum.ONLY_NUMBERS_LETTERS } };
export const EmailSchema = {
  ...RequiredFieldSchema,
  pattern: { value: EMAIL_REGEX, message: ErrorsEnum.INVALID_EMAIL },
};

export const TelephoneSchema = {
  pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
  maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
  minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
};

export const MobileSchema = {
  ...RequiredFieldSchema,
  pattern: { value: PHONE_FAX_REGEX, message: ErrorsEnum.INVALID_TELEPHONE },
  maxLength: { value: 30, message: ErrorsEnum.INVALID_TELEPHONE },
  minLength: { value: 7, message: ErrorsEnum.INVALID_TELEPHONE },
};

export const StatusTransitionError = 'StatusTransitionError';
export const DuplicateValue = 'Duplicate value';
export const PleaseRefreshThePageError = 'Please refresh the page';
export enum SimultaneouslyStatusErrorEnum {
  NeedConfirmation = 'need_confirmation',
  AlreadyModified = 'already_modified',
}
