import React from 'react';
import Button from '../../../core/components/button/Button';
import { CreateRectificationFlowEnum } from '../enums/createRectificationEnums';
import './CreateRectificationChips.scss';

type CreateRectificationChipsProps = {
  clearFields: (v: CreateRectificationFlowEnum) => void,
  flowValue: CreateRectificationFlowEnum,
}

const CreateRectificationChips: React.FC<CreateRectificationChipsProps> = ({ clearFields, flowValue }) => {
  return (
    <div className="createRectificationChips details-form__field--lg">
      <span className="createRectificationChips__label">Rectification flow</span>
      <div>
        <Button
          onClick={() => flowValue !== CreateRectificationFlowEnum.USUAL && clearFields(CreateRectificationFlowEnum.USUAL)}
          label="Usual"
          htmlType="button"
          designType="light-inverse"
          isActivated={flowValue === CreateRectificationFlowEnum.USUAL}
          offsetRight
        />
        <Button
          onClick={() => flowValue !== CreateRectificationFlowEnum.REPAIR && clearFields(CreateRectificationFlowEnum.REPAIR)}
          label="Repair vehicle"
          htmlType="button"
          designType="light-inverse"
          isActivated={flowValue === CreateRectificationFlowEnum.REPAIR}
        />
      </div>
    </div>
  );
};

export default CreateRectificationChips;
