import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical, faListNumeric } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';
import { JobCalendarElementDtoBase, JobCalendarSubElementDto, JobCalendarUserDto } from '../types/jobCalendarTypes';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Dropdown from '../../../core/components/dropdown/Dropdown';
import ChangeJobsOrderModal from './ChangeJobsOrderModal';
import { setJobCalendarDictionary } from '../../../store/slices/jobsSlice';
import { getJobCalendarDictionaryThunk } from '../../../store/thunks/jobs/jobCalendarThunks';
import { jobCalendarStatusesSelector } from '../../../store/selectors/jobsSelector';
import JobTypeElement from './JobTypeElement';
import GapTypeElement from './GapTypeElement';
import { uniqueAccountRolesWithoutLevels } from '../../../store/selectors/coreSelectors';
import { TruncateTooltip } from '../../../common/components/truncate-tooltip/TruncateTooltip';
import HiddenJobTypeElement from './HiddenJobTypeElement';

type JobCalendarLineProps = {
  item: JobCalendarUserDto,
}

const JobCalendarLine: React.FC<JobCalendarLineProps> = ({ item }) => {
  const jobCalendarStatuses = useAppSelector(jobCalendarStatusesSelector);
  const accountRoles = useAppSelector(uniqueAccountRolesWithoutLevels);
  const [isChangeOrderModal, setChangeOrderModal] = useState(false);
  const dispatch = useAppDispatch();

  const getJobSlotsSum = (subElements: JobCalendarSubElementDto[]) => {
    return subElements.length > 0
      ? subElements
        .map((el) => el.slotsCount)
        .reduce((a, b) => a + b)
      : 24;
  };

  const setClassname = (item: JobCalendarSubElementDto, isEl?: boolean) => {
    if (item.isNonWorkingDay) return 'weekend';
    if (item.isPartiallyNonWorkingDay) return 'stripe';
    else return isEl ? 'transparentGap' : '';
  };

  const isGapSpace = (el: JobCalendarElementDtoBase) => {
    return el.isGap;
  };

  const isHiddenJobSpace = (el: JobCalendarElementDtoBase) => {
    return el.isJob && el.jobs.every((job) => !job.isSatisfiesFilter);
  };

  const isJobElement = (el: JobCalendarElementDtoBase) => {
    if (el.isJob && el.jobs.length > 1) {
      return el.isJob && el.jobs.some((job) => job.isSatisfiesFilter);
    } else {
      return el.isJob && el.jobs.every((job) => job.isSatisfiesFilter);
    }
  };

  return <>
    <ChangeJobsOrderModal
      isVisible={isChangeOrderModal}
      onCancel={() => {
        setChangeOrderModal(false);
        dispatch(setJobCalendarDictionary([]));
      }}
      userName={`${item.firstName} ${item.lastName}`}
    />
    <div className="calendarLine">
      <div className="calendarLine__employee sticky-left">
        <div className="calendarLine__employee-info">
          <TruncateTooltip
            value={`${item.firstName} ${item.lastName}`}
            className="calendarLine__employee-name"
            overlayClassName="calendarLine__employee-tooltip"
            hideTooltipOnMobile={false}
          />
          <TruncateTooltip
            value={accountRoles.find((role) => role.value === item.role)?.label || 'N/A'}
            className="calendarLine__employee-role"
            overlayClassName="calendarLine__employee-tooltip"
            hideTooltipOnMobile={false}
          />
        </div>
        <Dropdown
          icon={<FontAwesomeIcon icon={faEllipsisVertical} />}
          menuItems={[{
            label: 'Change job order',
            key: 'change-job-order',
            icon: <FontAwesomeIcon icon={faListNumeric} />,
            onClick: () => jobCalendarStatuses && dispatch(getJobCalendarDictionaryThunk({
              filters: {
                assignees: [item.id],
                jobStatuses: jobCalendarStatuses as number[],
              },
              openModal: () => setChangeOrderModal(true),
            })),
          }]}
        />
      </div>
      <div className={classNames('calendarLine__content', { 'calendarLine--last-truncated': item.isLastJobElementTruncated })}>
        {item.elements.map((el, i) => <>
          {isGapSpace(el) && el.subElements.map((sub) => <GapTypeElement
            key={sub.dayNumber}
            subEl={sub}
            setClassname={setClassname}
          />)}
          {isHiddenJobSpace(el) && <HiddenJobTypeElement slotsCount={getJobSlotsSum(el.subElements)} />}
          {isJobElement(el) && <JobTypeElement
            key={i}
            jobElement={el}
            setClassname={setClassname}
            getJobSlotsSum={getJobSlotsSum}
          />}
        </>)}
      </div>
    </div>
  </>;
};

export default JobCalendarLine;
